import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import BaseModal from "../../Components/BaseModel/BaseModel";
import FormStructure from "../../Components/FromStructure/FormStructure";
import SelectForm from "../../Components/SelectForm/SelectForm";
import InputForm from "../../Components/InputForm/InputForm";
import "./JobProfileDashboard.css";
import Collapse from "react-bootstrap/Collapse";
import { APICall } from "../../Helpers/APICalls";
import {
  getJobDashboardData,
  getDropdowns,
} from "../../Helpers/APIEndPoints/EndPoints";
import { Tooltip } from "@mui/material";
import { LoaderContext } from "../../Helpers/Context/Context";
import { createFilter } from "react-select";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import notify from "../../Helpers/ToastNotification";
import { useDebounce } from "../../utils/useDebounce";

const JobProfileDashboard = (props) => {
  const [sectionData, setSectionData] = useState({});
  // const [tabs, setTabs] = useState([]);
  // const [activetab, setActivetab] = useState<string>(null);
  const [filteredData, setFilteredData] = useState([]);
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [gridData, setGridData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortDirection, setSortDirection] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [PageSize, setPageSize] = useState(10);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  let { userDetails, currentRoleId }: any = useUserContext();
  let CNTRY = [
    { value: "India", code: "CODECNTRY1", label: "India" },
    { value: "United States", code: "CODECNTRY7", label: "United States" },
    { value: "United Kingdom", code: "CODECNTRY51", label: "United Kingdom" },
  ];
  //-----------------added on 11-08-2023-------------------------------
  const [jobLevelVal, setJobLevelVal] = useState<any>([]);
  const [jobBandVal, setJobBandVal] = useState<any>([]);
  const [jobLegalEntVal, setJobLegalEntVal] = useState<any>([]);
  const [jobDivision, setJobDivisionVal] = useState<any>([]);
  const [skillVal, setSkillVal] = useState<any>([]);
  const [competency, setCompetency] = useState<any>([]);
  const [commonDdValue, setCommonDdValue] = useState<any>({});
  const [ddValue, setDdValue] = useState({});

  const debouncedValue = useDebounce(searchText);

  const getDropdownsData = async (AC, PID = null, cb) => {
    await showLoader();
    const dropdownData = await APICall(getDropdowns, "POST", {
      AC,
      PID,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });
    if (
      dropdownData.data !== null &&
      dropdownData.data !== undefined &&
      dropdownData.data.length > 0
    ) {
      setDdValue((prev) => ({ ...prev, [AC]: dropdownData.data }));
    } else {
      setDdValue((prev) => ({ ...prev, [AC]: [] }));
    }
    //setCommonDdValue((prev)=>({...prev, [AC]:[]}));
    await hideLoader();
  };

  useEffect(() => {
    getInterfaceDetails();
  }, []);
  //-------------------------------------------------
  const getInterfaceDetails = async () => {
    showLoader();
    // ;
    const gridDataResponse = await APICall(
      "/api/Interface/GetInterfaceDetailsForGrid",
      "POST",
      {
        MN: "JobProfile",
        IN: "JobProfile_Dashboard",
        TN: "JobProfile_Dashboard",
      }
    );
    if (gridDataResponse?.data?.d?.fa !== null) {
      for await (const cFilter of gridDataResponse?.data?.d?.fa) {
        if (cFilter.filt === "multidropdown") {
          await getDropdownsData(cFilter.filAN, null, null);
        }
      }
    }
    if (gridDataResponse?.data?.d?.afa !== null) {
      for await (const cFilter of gridDataResponse?.data?.d?.afa) {
        if (cFilter.filt === "multidropdown") {
          await getDropdownsData(cFilter.filAN, null, null);
        }
      }
    }
    setGridData(gridDataResponse);
    await getGridStructureTwo();
    hideLoader();
  };

  useEffect(() => {
    if (gridData?.data?.d?.s[0]?.columnData.length > 0) {
      getGridStructureTwo();
    }
  }, [
    page,
    debouncedValue,
    sortDirection,
    sortColumn,
    // jobLevelVal,
    // jobBandVal,
    // skillVal,
    // competency,
    // jobLegalEntVal,
  ]);
  const onSubmitFilter = () => {
    if (
      skillVal.length === 0 &&
      jobLegalEntVal.length === 0
      //&& competency.length === 0
    ) {
      notify(1, "Select atleast one filter");
    } else {
      setPage(0);
      if (gridData?.data?.d?.s[0]?.columnData.length > 0) {
        getGridStructureTwo();
      }
    }
  };

  const resetFilters = () => {
    setSearchText("");
    setSkillVal([]);
    setJobLegalEntVal([]);
    //setCompetency([]);
    if (gridData?.data?.d?.s[0]?.columnData.length > 0) {
      getGridStructureTwo();
    }
  };

  const getGridStructureTwo = async () => {
    showLoader();
    //;
    const GetDashboardData = await APICall(getJobDashboardData, "POST", {
      DashboardName: "Job",
      PageNumber: page + 1,
      PageSize: PageSize,
      SortColumn: sortColumn,
      SortOrder: sortDirection,
      SearchText: searchText,
      JBLEGEN: jobLegalEntVal.map((s) => s.label).join(","),
      // JPJOBND: jobBandVal.map((s) => s.label).join(","),
      // JPJOLVL: jobLevelVal.map((s) => s.label).join(","),
      JOBRSKL: skillVal.map((s) => s.label).join(","),
      JRCCOPCY: competency.map((s) => s.label).join(","),
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });
    if (GetDashboardData.data !== null && GetDashboardData.data.length > 0) {
      setCount(GetDashboardData.data[0].count);
      // ;
      setRowData(GetDashboardData.data);
    } else {
      setRowData([]);
      setCount(0);
    }
    hideLoader();
  };

  // useEffect(() => {
  //   (async () => {
  //     const tabs = await APICall("/api/Tabs/getTabs", "POST", {
  //       MN: "EmployeeCentral",
  //       IN: "Form",
  //     });

  //     if (tabs.data !== null && tabs.data.length > 0) {
  //       setTabs(tabs.data);
  //       setActivetab(tabs.data[0].TN);
  //     } else {
  //       setTabs([]);
  //     }
  //   })();
  // }, []);

  const view = (value, tableMeta) => {
    const url = value;
    // window.location.href = url;
    window.open(url);
  };

  const edit = (value, tableMeta, url) => {
    debugger;
    const TId = tableMeta.rowData[0];
    // navigate(url[1].editUrl);
    navigate("/job", { state: { TId, COU: userDetails.COU } });
  };

  const copy = (value, tableMeta) => {
    alert("copy");
  };

  const modal = (value, tableMeta) => {
    alert("modal");
  };

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: PageSize,
    page: page,
    serverSide: true,
    onChangePage: async (page) => {
      setPage(page);
    },
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",
    onColumnSortChange: async (sortColumn, sortDirection) => {
      setPage(0);
      if (sortDirection === "asc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
    },
  };
  //option which is from backend if needed we use it
  // const getOption = (attribute) => {
  //   const faData = data?.fa;
  //   if (faData && attribute.filn === "city") {
  //     return cityData.map((city) => ({ value: city.label, label: city.label }));
  //   } else {
  //     return [];
  //   }
  // };

  const columnsWithCustomRender = gridData?.data?.d?.s[0]?.columnData.map(
    (column) => {
      if (column.name === "action") {
        return {
          ...column,
          options: {
            ...column.options,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <>
                  <div className="centerStyles">
                    {/* <i
                    style={{ marginRight: "15px" }}
                    onClick={() => view(value, tableMeta)}
                    className="fas fa-eye"
                  ></i>
                  <i
                    style={{ marginRight: "15px" }}
                    onClick={() => copy(value, tableMeta)}
                    className="fas fa-copy"
                  ></i> */}
                    <Tooltip title="Edit">
                      <i
                        style={{ marginRight: "0px", cursor: "pointer" }}
                        onClick={() => {
                          // navigate("/gridEdit")
                          edit(value, tableMeta, column.url);
                        }}
                        className="fas fa-edit"
                      ></i>
                    </Tooltip>
                    {/* <BaseModal
                    buttonText={<i className="fa-solid fa-paper-plane"></i>}
                    content={<FormStructure />}
                  /> */}
                  </div>
                </>
              );
            },
          },
        };
      }
      return column;
    }
  );

  const onFilterChange = (event, type) => {
    // if(commonDdValue[type].length > 0) {
    //   if (commonDdValue[type].includes(event[0])){
    //     const updatedArray = commonDdValue[type].filter(item => item !== event[0]);
    //     setCommonDdValue((prev)=>({...prev, [type]:updatedArray}));
    //   }
    //   else{
    //     commonDdValue[type].push(event[0]);
    //   }
    // }
    // else{
    //   setCommonDdValue((prev)=>({...prev, [type]:event}));
    // }
    // if (type === "JPJOBND") {
    //   setJobBandVal(event);
    // }
    if (type === "JOBRSKL") {
      setSkillVal(event);
    }
    // if (type === "JPJOLVL") {
    //   setJobLevelVal(event);
    // }
    if (type === "JBLEGEN") {
      setJobLegalEntVal(event);
      // const commaSeparatedLegalEntityId =
      //   event && event.length > 0 && event.map((e) => e.value).join(",");
      // if (commaSeparatedLegalEntityId) {
      //   getDropdownsData("JBDIVSN", commaSeparatedLegalEntityId, null);
      // }
      // //reset cascading
      // setJobDivisionVal([]);
    }
    // if (type === "JRCCOPCY") {
    //   setCompetency(event);
    // }
  };
  const onSearchFilter = (event, type) => {
    if (type === "Search Job Title") {
      setPage(0);
      setSearchText(event.target.value);
    }
  };

  return (
    <div className="container-fluid">
      <div className="d-flex align-items-center justify-content-end mb-3">
        {props.permissionName === "Edit" && (
          <button
            onClick={() => {
              navigate("/job", { state: { TId: 0, COU: userDetails.COU } });
            }}
            className="btn btn-primary mt-3"
          >
            <i className="fa fa-plus mr-2"></i>
            Add Job Profile
          </button>
        )}
      </div>

      <div className="grid-wrapper grid_mob">
        <div className="row mx-auto filtBox">
          {gridData &&
            gridData?.data?.d?.fa?.map((cFilter, index) => (
              <>
                {cFilter.filt === "multidropdown" ? (
                  <>
                    <div className="col-lg-3 col-md-6 col-sm-12 ">
                      <div className="form-group filtInp">
                        <span
                          className="mr-2 text-white"
                          style={{ float: "left" }}
                        >
                          <label className="col-form-label">
                            {cFilter.filn}
                          </label>
                        </span>
                        <SelectForm
                          isClearable
                          options={ddValue[cFilter.filAN]}
                          placeholder={cFilter.filn}
                          isDisabled={false}
                          onChange={(event) => {
                            onFilterChange(event, cFilter.filAN);
                            //onFilterChange(event, cFilter.filn);
                          }}
                          isMulti={true}
                          noIndicator={false}
                          noSeparator={false}
                          value={
                            cFilter.filAN === "JOBRSKL"
                              ? skillVal
                              : cFilter.filAN === "JRCCOPCY"
                              ? competency
                              : cFilter.filAN === "JBLEGEN"
                              ? jobLegalEntVal
                              : null
                          }
                        />
                      </div>
                    </div>
                  </>
                ) : cFilter.filt === "text" ? (
                  <div className="col-lg-3 col-md-6 col-sm-12  ">
                    <div className="form-group filtInp">
                      <InputForm
                        value={
                          cFilter.filn === "Search Job Title" ? searchText : ""
                        }
                        placeholder={cFilter.filn}
                        isDisabled={false}
                        textArea={false}
                        onChange={(e) => {
                          onSearchFilter(e, cFilter.filn);
                        }}
                      />
                    </div>
                  </div>
                ) : null}
              </>
            ))}
          <div className="col-lg-1 col-md-1 col-sm-12 filtrt">
            <ul className=" filter-icon-wel">
              <li>
                <a
                  href="javascript:void(0);"
                  onClick={() => setOpen(!open)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                  className="filter-btn"
                >
                  <i className="fa fa-filter"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="col-lg-12 col-md-10 col-sm-12 "></div>

      <>
        <Collapse in={open}>
          <div id="example-collapse-text grid-wrapper">
            <div className="row mx-auto myInlineStyle">
              {gridData &&
                gridData?.data?.d?.afa.map((cFilter, index) => (
                  <>
                    {cFilter.filt === "multidropdown" ? (
                      <>
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                          <label className="col-form-label text-white">
                            {cFilter.filn}
                          </label>
                          <SelectForm
                            isClearable
                            options={ddValue[cFilter.filAN]}
                            placeholder={cFilter.filn}
                            isDisabled={false}
                            onChange={(event) => {
                              onFilterChange(event, cFilter.filAN);
                              //onFilterChange(event, cFilter.filn);
                            }}
                            isMulti={true}
                            noIndicator={false}
                            noSeparator={false}
                            value={
                              cFilter.filAN === "JOBRSKL"
                                ? skillVal
                                : // : cFilter.filAN === "JRCCOPCY"
                                // ? competency
                                cFilter.filAN === "JBLEGEN"
                                ? jobLegalEntVal
                                : null
                            }
                          />
                        </div>
                      </>
                    ) : cFilter.filt === "text" ? (
                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                        <div className="form-group filtInp">
                          <InputForm
                            value={
                              cFilter.filn === "Search Job Title"
                                ? searchText
                                : ""
                            }
                            placeholder={cFilter.filn}
                            isDisabled={false}
                            textArea={false}
                            onChange={(e) => {
                              onSearchFilter(e, cFilter.filn);
                            }}
                          />
                        </div>
                      </div>
                    ) : null}
                  </>
                ))}
              <div className="col-lg-12 d-flex mt-2 justify-content-end">
                <button
                  onClick={resetFilters}
                  className="btn btn-secondary mr-2"
                >
                  <i className="fa fa-undo" aria-hidden="true"></i> Reset{" "}
                </button>
                <button
                  className="btn btn-filter-submit"
                  onClick={onSubmitFilter}
                >
                  <i className="fa fa-check" aria-hidden="true"></i> Submit{" "}
                </button>
              </div>
            </div>
          </div>
        </Collapse>
      </>
      {}
      <div className="mb-3 pt-3">
        <DynamicGrid
          data={rowData}
          columns={columnsWithCustomRender}
          options={options}
        />
      </div>
    </div>
  );
};

export default JobProfileDashboard;
