import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { APICall } from "../../Helpers/APICalls";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { decryptUsingAES256 } from "../../Helpers/Cryptography/CryptoJs";
import {
  GetRolesURL,
  getUserContextBySSOURL,
  logoutUserById,
} from "../../Helpers/APIEndPoints/EndPoints";
import notify from "../../Helpers/ToastNotification";
function SSO() {
  const navigate = useNavigate();
  const location = useLocation();
  const passedData = location.state && location.state.data;
  var url = new URL(passedData);
  var encryloginId = url.searchParams.get("Uid");
  encryloginId = encryloginId.replaceAll(" ", "+");
  const loginId = decryptUsingAES256(encryloginId);
  const { addUserDetails }: any = useUserContext();

  useEffect(() => {
    getUserContextBySSO();
  }, []);

  const GetRolesAsync = async () => {
    try {
      let arr = [];
      const response = await APICall(GetRolesURL, "POST", {});

      if (response?.status == 0 && response?.data?.length > 0) {
        const data = response?.data;

        arr = data;
      } else {
      }

      return arr;
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const getUserContextBySSO = async () => {
    let lId = "";
    /*Remove when publishing */
    // if (loginId.LoginId === "Aniruddha_M") {
    //   lId = "epm2@prosaressolutions.onmicrosoft.com";
    // } else {
    lId = loginId.LoginId; //only this line is commented
    //  }
    const GetData = await APICall(getUserContextBySSOURL, "POST", {
      LoginId: lId,
      //LoginId: "0166760",
    });
    if (GetData.data !== null && GetData.data?.valid !== false) {
      const userData = decryptUsingAES256(GetData.data); //decrypt data
      const Roles = await GetRolesAsync();
      userData["Roles"] = Roles;
      addUserDetails(userData);

      navigate("/home");
    } else if (GetData.data !== null && GetData.data?.active === false) {
      notify(1, "User Not Found!");

      navigate("/");
    }
    // else if (GetData.message === "Concurrent") {
    //   if (
    //     window.confirm(
    //       "Unauthorized, Concurrent login not allowed. Please click Ok to logout from all devices and login again!"
    //     )
    //   ) {
    //     (async () => {
    //       const apiRes = await APICall(logoutUserById, "POST", {
    //         LoginId: lId,
    //       });
    //     })();
    //     setTimeout(() => {
    //       window.location.replace(`${window.location.origin}`);
    //     }, 3000);
    //   } else {
    //     window.location.replace(`${window.location.origin}`);
    //   }
    // }
  };
  return <div>loading...</div>;
}

export default SSO;
