import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import defaultProfileImgPic from "../../Assets/Images/thumbnail.jpg";
import SelectForm from "../../Components/SelectForm/SelectForm";
import InputForm from "../../Components/InputForm/InputForm";
import {
  GetDroupdownList,
  InsertUpdateTrainingCentersData,
  DeleteThumbnailUrls,
  SetThumbnailDetails,
  GetTrainingCentersDetails,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import { APICall } from "../../Helpers/APICalls";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import notify from "../../Helpers/ToastNotification";
import { Modal } from "react-bootstrap";
import { LoaderContext } from "../../Helpers/Context/Context";

const TrainingCentreMaster = () => {
  const navigate = useNavigate();
  const [profileImg, setProfileImg] = useState(null);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [isDisabledCheckedBox, setisDisabledCheckBox] = useState<any>(false);
  const [trinerGroup, settrainerGroup] = useState("Internal");
  const [officeLocationId, setOffcielocation] = useState<any>([]);
  const [officeLocationOption, setOffcielocationOption] = useState([]);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [Address, setAddress] = useState("");
  const [RoomID, setRoomID] = useState<any>([]);
  const [RoomOption, setRoomOption] = useState([]);
  const [TrainingFacility, setTrainingFacility] = useState<any>([]);
  const [TrainFacilityOption, setFacilityTrainigOption] = useState([]);
  const [Capacity, setCapacity] = useState("");
  const { state } = useLocation();
  const [isDisable, setIsDisable] = useState(
    state && state?.isDisable ? true : false
  );

  let { userDetails, currentRoleId }: any = useUserContext();
  const [profileImgToUpload, setProfileImgToUpload] = useState<any>(null);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [Name, setName] = useState("");
  let facilityArray;
  useEffect(() => {
    (async () => {
      await GetOfficeLocationType();

      await getRoom();
      await getFacility();
      await onLoadEditData();
    })();
  }, []);

  type FormErrors = {
    OfficeLocation?: string;
    Address?: string;
    Room?: string;
    TrainingFacility?: string;
    Capacity?: string;
    Name?: string;
    // Add more fields if necessary
  };

  const EmptyField = () => {
    setAddress("");
    setCapacity("");
    setTrainingFacility([]);
    setName("");
  };
  const onInternalExternalTrainerList = async (event) => {
    if (event === "Internal") {
      settrainerGroup(event);
      EmptyField();
      formErrors.TrainingFacility = "";
      formErrors.Address = "";
      formErrors.Capacity = "";
      formErrors.Room = "";
      formErrors.OfficeLocation = "";
    } else if (event === "External") {
      settrainerGroup(event);
      EmptyField();
      formErrors.TrainingFacility = "";
      formErrors.Address = "";
      formErrors.Capacity = "";
      formErrors.Name = "";
    }
  };

  const GetOfficeLocationType = async () => {
    let UserId;
    const { data } = await APICall(GetDroupdownList, "POST", {
      Mode: "OFFICELOCATION",
    });

    if (data !== null && data.length > 0) {
      let role = data.map((element) => ({
        value: element.value,
        label: element.label,
      }));
      setOffcielocationOption(role);
    }
  };

  const getRoom = async () => {
    let UserId;
    const { data } = await APICall(GetDroupdownList, "POST", {
      Mode: "ROOM",
    });

    if (data !== null && data.length > 0) {
      let role = data.map((element) => ({
        value: element.value,
        label: element.label,
      }));

      setRoomOption(role);
    }
  };

  const getFacility = async () => {
    let UserId;
    const { data } = await APICall(GetDroupdownList, "POST", {
      Mode: "FACILITY",
    });

    if (data !== null && data.length > 0) {
      let role = data.map((element) => ({
        value: element.value,
        label: element.label,
      }));
      console.log(role, "Role");
      facilityArray = role;
      console.log(facilityArray, "facility");
      setFacilityTrainigOption(role);
    }
  };

  const onResetData = () => {
    setOffcielocation([]);
    setCapacity("");
    setAddress("");
    setRoomID([]);
    setTrainingFacility([]);
  };

  const onLoadEditData = async () => {
    showLoader();
    // if (state != null && state.id != 0) {

    // console.log(state.id,"StateId")

    // const {data} = await APICall(GetTrainingCentersDetails, "POST", {
    //     Id: state.id,
    //   });
    //   console.log(data,"Edit");
    //   let facility = data?.facility
    //   let array_result = facility?.split(",");
    //   console.log(array_result);

    //   const filteredArray = facilityArray?.filter(item => array_result.includes(item.value));
    //   setCapacity(data.capacity);
    //   setAddress(data.address);
    //   setRoomID({
    //     label: data.room,
    //     value: data.roomId,
    //   })

    //   setOffcielocation({
    //     label: data.trainingCenterLocation,
    //     value: data.officeLocationID,
    //   })

    //   if (data.thumbnailDataObject != null) {
    //     setProfileImgToUpload({
    //       Name: "Edit.jpg",
    //       Data: data.thumbnailDataObject,
    //     });
    //     setProfileImg(`data:image;base64,${data.thumbnailDataObject}`);
    //   } else {
    //     setProfileImg(null);
    //   }
    //   settrainerGroup(data.trainingCenterType);
    //   setisDisabledCheckBox(true);

    //   console.log(filteredArray,"filteredArray")
    //   setTrainingFacility(filteredArray);
    //   hideLoader();
    // }
    try {
      if (state != null && state.id != 0) {
        console.log(state.id, "StateId");

        const { data } = await APICall(GetTrainingCentersDetails, "POST", {
          Id: state.id,
        });
        console.log(data, "Edit");
        let facility = data?.facility;
        let array_result =
          facility !== null && facility !== "" ? facility?.split(",") : null;
        console.log(array_result);
        let filteredArray = [];
        if (array_result) {
          filteredArray = facilityArray?.filter((item) =>
            array_result.includes(item.value)
          );
        }

        setCapacity(data?.capacity);
        setAddress(data?.address);
        setRoomID({
          label: data?.room,
          value: data?.roomId,
        });

        setOffcielocation({
          label: data?.trainingCenterLocation,
          value: data?.officeLocationID,
        });

        setName(data?.trainingCentreName);

        if (data?.thumbnailDataObject != null) {
          setProfileImgToUpload({
            Name: "Edit.jpg",
            Data: data?.thumbnailDataObject,
          });
          setProfileImg(`data:image;base64,${data?.thumbnailDataObject}`);
        } else {
          setProfileImg(null);
        }
        settrainerGroup(data?.trainingCenterType);
        setisDisabledCheckBox(true);

        console.log(filteredArray, "filteredArray");
        if (filteredArray.length > 0) {
          setTrainingFacility(filteredArray);
        } else {
          setTrainingFacility([]);
        }
      }
    } catch (error) {
      console.error("Error:", error);
      // Display error message or handle error accordingly
      // You can replace the following line with your preferred way of displaying an error message
      notify(
        0,
        "An error occurred while fetching data. Please try again later."
      );
    } finally {
      hideLoader();
    }

    hideLoader();
  };
  const MapArr = (arr) => {
    let s = [];
    if (arr[0] === null || arr.length < 0) {
      return "";
    }
    arr.map((i) => s.push(i.value));
    return s;
  };

  let TrainingFacilityIDs = MapArr(TrainingFacility);

  const validateForm = () => {
    let errors: FormErrors = {};
    let isValid = true;

    if (trinerGroup === "Internal") {
      if (!officeLocationId || !officeLocationId.value) {
        errors.OfficeLocation = "OfficeLocation is required";
        isValid = false;
      }

      if (!Address.trim()) {
        errors.Address = "Address is required";
        isValid = false;
      }

      if (!RoomID || !RoomID.value) {
        errors.Room = "Room is required";
        isValid = false;
      }

      // if (TrainingFacility.length === 0) {
      //   errors.TrainingFacility = "Training Facility is required";
      //   isValid = false;
      // }

      // if(Capacity===""){
      //     errors.Capacity = "Capacity is required";
      //     isValid = false;
      // }
    } else {
      // if (TrainingFacility.length === 0) {
      //   errors.TrainingFacility = "Training Facility is required";
      //   isValid = false;
      // }

      // if(!Capacity.trim()){
      //     errors.Capacity = "Capacity is required";
      //     isValid = false;
      // }
      if (Name == "") {
        errors.Name = "Name is required";
        isValid = false;
      }

      if (!Address.trim()) {
        errors.Address = "Address is required";
        isValid = false;
      }
    }

    // Add additional validations as needed

    setFormErrors(errors);
    return isValid;
  };

  const onSubmitData = async () => {
    if (trinerGroup === "Internal") {
      if (!validateForm()) {
        return; // Stop the submission if there are validation errors
      }
      let requestParams = {
        Id: state === null ? 0 : state.id,
        Mode: "SAVE",
        TrainingCenterType: trinerGroup,
        OfficeLocationID: officeLocationId?.value,
        TrainingCentreName: Name ? Name : "",
        RoomId: RoomID?.value,
        ThumbnailUrl: null,
        Address: Address,
        Capacity: Capacity != "" ? Capacity : null,
        IsActive: true,
        CreatedBy: userDetails?.Id,
        Facility:
          TrainingFacility.length > 0 ? TrainingFacilityIDs.toString() : "",
      };

      console.log(requestParams);

      const data = await APICall(
        InsertUpdateTrainingCentersData,
        "POST",
        requestParams
      );

      if (data.id != 0 && profileImg != null) {
        await handleImageUpload(data.id);
      }
      if (data != null) {
        console.log(data, "data is Created");
        if (data.status === 0) {
          notify(0, data.message);
          navigate("/TrainingCentreDashboard");
        } else {
          notify(1, data.message);
        }
      } else {
        notify(1, "Something went wrong");
      }
    }
    if (trinerGroup === "External") {
      if (!validateForm()) {
        return; // Stop the submission if there are validation errors
      }
      let requestParams = {
        Id: state === null ? 0 : state.id,
        Mode: "SAVE",
        TrainingCenterType: trinerGroup,
        TrainingCentreName: Name ? Name : "",
        OfficeLocationID: "",
        RoomId: "",
        ThumbnailUrl: null,
        Address: Address,
        Capacity: Capacity != "" ? Capacity : null,
        IsActive: true,
        CreatedBy: userDetails?.Id,
        Facility:
          TrainingFacility.length > 0 ? TrainingFacilityIDs.toString() : "",
      };

      console.log(requestParams);

      const data = await APICall(
        InsertUpdateTrainingCentersData,
        "POST",
        requestParams
      );

      if (data.id != 0 && profileImg != null) {
        await handleImageUpload(data.id);
      }
      if (data != null) {
        console.log(data, "data is Created");
        if (data.status === 0) {
          notify(0, data.message);
          navigate("/TrainingCentreDashboard");
        } else {
          notify(1, data.message);
        }
      } else {
        notify(1, "Something went wrong");
      }
    }
  };

  const fileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleImageSelect = async (e) => {
    const file = e.target.files[0];
    let fileBase64: { Name: string; Data: string } = null;
    const base64 = (await fileToBase64(file)) as string;
    const base64WithoutPrefix = base64.split(",")[1];
    fileBase64 = { Name: file.name, Data: base64WithoutPrefix };

    const validExtensions = ["png", "jpg", "jpeg"];
    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();
      const fileSize = file.size;
      const maxFileSizeBytes = 5 * 1024 * 1024;

      // Check if the file extension is allowed
      if (
        validExtensions.includes(fileExtension) &&
        fileSize <= maxFileSizeBytes
      ) {
        // file to upload
        setProfileImgToUpload(fileBase64);
        setProfileImg(`data:image;base64,${fileBase64.Data}`);
      } else {
        notify(1, "Only file with png,jpg,jpeg allowed and size under 5MB");
        e.target.value = "";
        setProfileImgToUpload(null);
      }
    }
  };

  const deleteProfileImage = async (id: any) => {
    if (profileImg != null) {
      showLoader();
      const doc = await APICall(DeleteThumbnailUrls, "POST", {
        id,
        CreatedBy: userDetails?.Id,
      });

      console.log(doc, "doc");
      if (doc.status === 0) {
        setProfileImg(null);
        setShowProfileModal(false);
        setProfileImgToUpload(null);
        hideLoader();
      } else {
        notify(1, "File not Deleted, Something Went Wrong.");
        hideLoader();
      }
    }
  };

  const handleImageUpload = async (id: any) => {
    if (profileImgToUpload) {
      showLoader();
      let reqObj = {
        file: {
          // AC: "ProfilePic",
          Name: profileImgToUpload.Name,
          Data: profileImgToUpload.Data,
        },
        PId: id,
        SectionName: "Training Center",
        UserId: userDetails?.UserId,
      };

      try {
        const response = await APICall(SetThumbnailDetails, "POST", reqObj);
        if (response && response.data) {
          setProfileImg(`data:image;base64,${response.data.base64}`);
          setShowProfileModal(false);
          hideLoader();
        } else {
          console.error("Image upload failed");
          notify(1, "Image upload failed.");
          hideLoader();
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        hideLoader();
      }
    }
    // else {
    //   notify(1, "Please Select Image to Upload.");
    // }
  };

  return (
    <>
      <div className="breadcrumb modified-bredcrumb">
        <span>
          <ul>
            <li>
              <a href="/home">Home</a>
            </li>
            <li>
              {" "}
              <a href="/TrainerMaster">Training Centre</a>
            </li>
            <li>Manage Training Centre</li>
          </ul>
        </span>
        <button
          onClick={() => {
            navigate("/TrainingCentreDashboard");
          }}
          disabled={false}
          className="btn btn-secondary float-right">
          <i className="fa-solid fa-arrow-left"></i> Back
        </button>
      </div>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-md-3 d-flex justify-content-center align-items-start">
            <div className="card Prof_img">
              <img
                src={profileImg != null ? profileImg : defaultProfileImgPic}
                className="card-img-top"
                alt="Thumbnail Image"
              />
              {!isDisable && (
                <a
                  className="edit-profile"
                  onClick={() => setShowProfileModal(true)}>
                  <i className="fas fa-edit"></i>
                </a>
              )}
            </div>
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-lg-2 col-md-2 col-sm-12 mb-3">
                <label className="col-form-label">Training Type</label>

                <div>
                  <input
                    className="ml-2 mr-1"
                    disabled={isDisabledCheckedBox}
                    id={`trainerGroup_1`}
                    type="radio"
                    name="targetGroup"
                    onChange={() => onInternalExternalTrainerList("Internal")}
                    checked={trinerGroup === "Internal"}
                  />
                  <label htmlFor={`targetGroup_1`}>Internal</label>
                </div>
              </div>

              <div className="col-lg-2 col-md-2 col-sm-12 mb-3">
                <br />

                <div className="mt-3">
                  <input
                    className="ml-2 mr-1"
                    disabled={isDisabledCheckedBox}
                    id={`trainerGroup_1`}
                    type="radio"
                    name="targetGroup"
                    onChange={() => onInternalExternalTrainerList("External")}
                    checked={trinerGroup === "External"}
                  />
                  <label htmlFor={`targetGroup_1`}>External</label>
                </div>
              </div>

              {trinerGroup == "Internal" && (
                <div className="col-md-4">
                  <label className="col-form-label">Office Location</label>
                  <sup>*</sup>
                  <SelectForm
                    isClearable
                    isDisabled={isDisable}
                    options={officeLocationOption}
                    placeholder={"Select Office Location"}
                    onChange={(event) => {
                      setOffcielocation(event);
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                    value={officeLocationId}
                  />
                  {formErrors.OfficeLocation && (
                    <p style={{ color: "red" }}>{formErrors.OfficeLocation}</p>
                  )}
                </div>
              )}

              {trinerGroup == "External" && (
                <div className="col-md-4">
                  <label className="col-form-label">Name</label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control"
                    placeholder="Please enter the Name"
                    isDisabled={isDisable}
                    onChange={(e) => setName(e.target.value)}
                    textArea={false}
                    value={Name}
                    maxLength="255"
                  />
                  {formErrors.Name && (
                    <p style={{ color: "red" }}>{formErrors.Name}</p>
                  )}
                </div>
              )}

              <div className="col-md-4">
                <label className="col-form-label">Address</label>
                <sup>*</sup>
                <InputForm
                  className="form-control"
                  placeholder="Please Enter the Address"
                  isDisabled={isDisable}
                  onChange={(e) => setAddress(e.target.value)}
                  textArea={true}
                  value={Address}
                  maxLength="255"
                />
                {formErrors.Address && (
                  <p style={{ color: "red" }}>{formErrors.Address}</p>
                )}
              </div>

              {trinerGroup == "Internal" && (
                <div className="col-md-4">
                  <label className="col-form-label">Room</label>
                  <sup>*</sup>
                  <SelectForm
                    isClearable
                    options={RoomOption}
                    isDisabled={isDisable}
                    placeholder={"Select Room"}
                    onChange={(event) => {
                      setRoomID(event);
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                    value={RoomID}
                  />
                  {formErrors.Room && (
                    <p style={{ color: "red" }}>{formErrors.Room}</p>
                  )}
                </div>
              )}

              <div className="dropdown col-md-4">
                <label className="col-form-label">Training Facility</label>
                <SelectForm
                  isClearable
                  isDisabled={isDisable}
                  options={TrainFacilityOption}
                  placeholder={"Select Training Facility"}
                  onChange={(event) => {
                    setTrainingFacility(event);
                  }}
                  isMulti={true}
                  noIndicator={false}
                  noSeparator={false}
                  value={TrainingFacility}
                />
                {/* {formErrors.TrainingFacility && (
                  <p style={{ color: "red" }}>{formErrors.TrainingFacility}</p>
                )} */}
              </div>

              <div className="col-md-4">
                <label className="col-form-label">Capacity</label>
                <sup></sup>
                <InputForm
                  className="form-control"
                  placeholder="Please Enter Capacity"
                  isDisabled={isDisable}
                  onChange={(e) =>
                    setCapacity(e.target.value.replace(/[^0-9]/g, ""))
                  }
                  textArea={false}
                  value={Capacity}
                  maxLength="5"
                />
                {formErrors.Capacity && (
                  <p style={{ color: "red" }}>{formErrors.Capacity}</p>
                )}
              </div>
            </div>

            {!isDisable && (
              <div className="SectionSubmit mb-4 mt-4 clearfix">
                <button
                  onClick={() => {
                    onResetData();
                  }}
                  disabled={false}
                  className="btn btn-secondary float-right ml-2">
                  <i className="fa-solid fa-refresh"></i> Reset
                </button>

                <button
                  onClick={() => {
                    onSubmitData();
                  }}
                  disabled={false}
                  className="btn btn-filter-submit float-right ml-2">
                  <i className="fa-solid fa-check"></i> Save
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        show={showProfileModal}
        onHide={() => setShowProfileModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">Upload Picture!</div>
        </div>
        <Modal.Body>
          <>
            <input
              type="file"
              multiple={false}
              accept={".png,.jpg,.jpeg"}
              onChange={handleImageSelect}
            />
            <div>
              <b>Acceptable image formats:</b> .png, .jpg and .jpeg{" "}
            </div>
            <div>
              {" "}
              <b>Maximum File Size:</b> 5MB{" "}
            </div>
            <div>
              <b>Resolution:</b> 3245 X 3245{" "}
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          {profileImg != null && (
            <>
              {state != null && state.id != 0 ? (
                // Add any additional JSX you want to render when state is not null and state.id is not 0
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: 5 }}
                  onClick={() => {
                    deleteProfileImage(state.id);
                  }}>
                  Remove
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: 5 }}
                  onClick={() => {
                    setShowProfileModal(false);
                    setProfileImg(null);
                    setProfileImgToUpload(null);
                  }}>
                  Remove
                </button>
              )}
            </>
          )}

          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => setShowProfileModal(false)}>
            Upload
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowProfileModal(false);
            }}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TrainingCentreMaster;
