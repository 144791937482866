import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import "../../Pages/Progression/ChangeLog.css";
import { Table } from "react-bootstrap";
import "./ManageSequence.css";
import { APICall } from "../../Helpers/APICalls";
import { LoaderContext } from "../../Helpers/Context/Context";
import {
  getManagedSequencedHistory,
  getPositionSequencedHistory,
} from "../../Helpers/APIEndPoints/EndPoints";
function ManageSequenceHistory({ tabsactive, setDataHistory, editid }) {
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [leftHistory, SetLeftHistory] = useState([]);
  const [selectedChange, setSelectedChange] = useState(
    leftHistory ? leftHistory[0] : null
  );
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    if (
      tabsactive === "employee_history" ||
      tabsactive === "position_history"
    ) {
      setShowModal(true);
    }
  }, [tabsactive]);
  const handleModalClose = () => {
    setShowModal(false);
    setDataHistory("");
  };
  const handleClickSelectChange = (change) => {
    setSelectedChange(change);
  };
  const getManagedSequencedHistoryfunc = async () => {
    showLoader();
    try {
      let apicall;
      if (tabsactive === "employee_history") {
        apicall = getManagedSequencedHistory;
      } else {
        apicall = getPositionSequencedHistory;
      }
      const response = await APICall(apicall, "POST", {
        ID: editid,
      });
      const { data, status } = response || {};
      SetLeftHistory(status === 0 && data ? data : null);
      return data;
    } catch (error) {
      console.error("Error in Manage Sequence History data", error);
    } finally {
      hideLoader();
    }
  };
  useEffect(() => {
    if (leftHistory.length > 0) {
      handleClickSelectChange(leftHistory[0]);
    }
  }, [leftHistory]);
  useEffect(() => {
    getManagedSequencedHistoryfunc();
  }, []);
  return (
    <Modal
      className="customized-history-modal"
      show={showModal}
      onHide={handleModalClose}
      backdrop="static"
      keyboard={false}
      //  size="sm"
      centered
    >
      <div
        className="bg-secondary text-white"
        style={{
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        <div className="col-lg-12">History</div>
      </div>
      <Modal.Body>
        <div className="row">
          {!leftHistory.length ? (
            <h3>No History Found!</h3>
          ) : (
            <>
              <div className="col-lg-3 customized-history-body">
                {leftHistory.map((change, index) => (
                  <div
                    className={`border m-2 p-2 ${
                      selectedChange === change ? "selectedChange" : ""
                    }`}
                    key={index}
                    onClick={() => handleClickSelectChange(change)}
                  >
                    <p>Changed By: {change.userName}</p>
                    <p>Changed On: {change.modifiedDate}</p>
                  </div>
                ))}
              </div>
              <div className="col-lg-9 customized-history-body">
                {selectedChange ? (
                  <div className=" border m-2 p-2">
                    <p>Changed By: {selectedChange.userName}</p>
                    <p>Changed On: {selectedChange.modifiedDate}</p>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th className="text-dark">Field</th>
                          <th className="text-dark">Old Value</th>
                          <th className="text-dark">New Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedChange.externalGroupList.map((item, index) => (
                          <tr key={index}>
                            <td>{item.attribute}</td>
                            <td>{item.oldAttributeValue}</td>
                            <td>{item.newAttributeValue}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : null}
              </div>
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          disabled={false}
          className="btn btn-secondary ml-3"
          onClick={handleModalClose}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
export default ManageSequenceHistory;
