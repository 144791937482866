import React, { memo, useEffect, useState } from "react";
import InputForm from "../../Components/InputForm/InputForm";
import { Badge } from "@mui/material";
import { useLocation } from "react-router-dom";
import { APICall } from "../../Helpers/APICalls";
import { manageUserAssessment } from "../../Helpers/APIEndPoints/LMS_EndPoints";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";

const AssessmentAnswer = memo(
  ({ selectedQuestion, answers, getUserAnswer, CorrectAnswer }: any) => {
    const { state } = useLocation();
    // console.log(selectedQuestion);

    const questionCode = selectedQuestion.questionCode;
    const questionId = selectedQuestion.questionId;
    let { userDetails, currentRoleId }: any = useUserContext();

    const Btns = ({
      saveFunc = null,
      clearFunc = null,
      errorMessage = null,
      successMessage = null,
    }) => {
      return (
        <div className="row">
          <div className="col-lg-12 my-2 d-flex justify-content-end align-items-center">
            {errorMessage && (
              <p className="mx-3" style={{ color: "red" }}>
                {errorMessage}
              </p>
            )}
            {successMessage && (
              <p className="mx-3" style={{ color: "green" }}>
                {successMessage}
              </p>
            )}
            <button
              className="btn btn-primary"
              style={{ marginLeft: 5 }}
              disabled={state?.preview || false}
              onClick={() => {
                if (saveFunc) {
                  saveFunc();
                }
              }}>
              Save
            </button>
            <button
              className="btn btn-cancel"
              style={{ marginLeft: 5 }}
              onClick={() => {
                if (clearFunc) {
                  clearFunc();
                }
              }}>
              Clear
            </button>
          </div>
        </div>
      );
    };

    const SingleAnswer = () => {
      const [answerData, setAnswerData] = useState(
        selectedQuestion.userAnswer || null
      );

      const [errorMessage, setErrorMessage] = useState(null);

      const [successMessage, setSuccessMessage] = useState(null);

      const clearFunc = () => {
        setAnswerData(null);
        setErrorMessage(null);
        setSuccessMessage(null);
      };

      const saveFunc = async () => {
        if (!answerData) {
          setErrorMessage("Required!");
          return null;
        } else {
          setErrorMessage(null);
        }

        const res = await APICall(manageUserAssessment, "POST", {
          Mode: "SAVE",
          AssessmentId: state.assessmentId,
          UserAssessmentId: state.userAssessmentId,
          Answer: answerData,
          UserId: userDetails?.Id,
          RoleId: currentRoleId?.value,
          QuestionCode: questionCode,
          QuestionId: questionId,
        });

        if (res.status === 0) {
          setSuccessMessage("Your response submitted");
          const ques = await APICall(manageUserAssessment, "POST", {
            Mode: "UPDATE",
            AssessmentId: state.assessmentId,
            UserAssessmentId: state.userAssessmentId,
            QuestionId: questionId,
          });

          getUserAnswer();
        }
      };

      return (
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2">Answer</div>
            <div className="col-lg-10">
              {answers.map((ans, aIndex) => (
                <div key={aIndex} className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id={ans.answerId}
                    name="radios"
                    checked={
                      !answerData
                        ? false
                        : answerData === ans.answer
                        ? true
                        : false
                    }
                    value={ans.answer}
                    onChange={(e) => {
                      setAnswerData(e.target.value);
                    }}
                  />
                  <label className="form-check-label" htmlFor={ans.answerId}>
                    {ans.answer}
                  </label>
                </div>
              ))}
            </div>
          </div>
          {/* <div className="row my-2">
            <div className="col-lg-12">
            {successMessage && (
                <p style={{ color: "green" }}>{successMessage}</p>
              )}
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            </div>
          </div> */}
          <CorrectAnswer />
          <Btns
            clearFunc={clearFunc}
            saveFunc={saveFunc}
            errorMessage={errorMessage}
            successMessage={successMessage}
          />
        </div>
      );
    };

    const MultiAnswer = () => {
      let selectedAnswers = [];
      if (selectedQuestion.userAnswer) {
        const allAnswers = selectedQuestion.answers;
        const userAnswers = selectedQuestion.userAnswer.split(",");

        userAnswers.forEach((answer) => {
          const foundAnswer = allAnswers.find((a) => a.answer === answer);
          if (foundAnswer !== undefined) {
            selectedAnswers.push(foundAnswer);
          }
        });
      }

      const [answerData, setAnswerData] = useState(selectedAnswers || []);

      const [errorMessage, setErrorMessage] = useState(null);

      const [successMessage, setSuccessMessage] = useState(null);

      const clearFunc = () => {
        setAnswerData([]);
        setErrorMessage(null);
        setSuccessMessage(null);
      };

      const saveFunc = async () => {
        if (answerData.length === 0) {
          setErrorMessage("Required!");
          return null;
        } else {
          setErrorMessage(null);
        }

        const res = await APICall(manageUserAssessment, "POST", {
          Mode: "SAVE",
          AssessmentId: state.assessmentId,
          UserAssessmentId: state.userAssessmentId,
          UserId: userDetails?.Id,
          RoleId: currentRoleId?.value,
          QuestionCode: questionCode,
          QuestionId: questionId,
          Answer: answerData.map((a) => a.answer).join(","),
        });
        if (res.status === 0) {
          setSuccessMessage("Your response submitted");

          const ques = await APICall(manageUserAssessment, "POST", {
            Mode: "UPDATE",
            AssessmentId: state.assessmentId,
            UserAssessmentId: state.userAssessmentId,
            QuestionId: questionId,
          });

          getUserAnswer();
        }
      };

      return (
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2">Answer</div>
            <div className="col-lg-10">
              {answers.map((ans, aIndex) => (
                <div key={aIndex} className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={ans.answerId}
                    checked={
                      !answerData
                        ? false
                        : answerData.find(
                            (dataAns) => dataAns.answer === ans.answer
                          ) !== undefined
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      setAnswerData((prev) => {
                        if (e.target.checked) {
                          return [
                            ...prev,
                            {
                              answerSequence: ans.answerSequence,
                              answer: ans.answer,
                            },
                          ].sort((a, b) => a.answerSequence - b.answerSequence);
                        } else {
                          return prev
                            .filter((dataAns) => dataAns.answer !== ans.answer)
                            .sort(
                              (a, b) => a.answerSequence - b.answerSequence
                            );
                        }
                      });
                    }}
                  />
                  <label className="form-check-label" htmlFor={ans.answerId}>
                    {ans.answer}
                  </label>
                </div>
              ))}
            </div>
          </div>
          {/* <div className="row my-2">
            <div className="col-lg-12">
              {successMessage && (
                <p style={{ color: "green" }}>{successMessage}</p>
              )}

              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            </div>
          </div> */}
          <CorrectAnswer />
          <Btns
            clearFunc={clearFunc}
            saveFunc={saveFunc}
            errorMessage={errorMessage}
            successMessage={successMessage}
          />
        </div>
      );
    };

    const MatchAnswer = () => {
      const [answerData, setAnswerData] = useState([]);
      const [errorMessage, setErrorMessage] = useState(null);
      const [successMessage, setSuccessMessage] = useState(null);

      let userAnswer = [];
      let userLeftSideParis = null;
      let userRightSideParis = null;
      const userShuffledData = [];
      let prefillData = [];
      if (selectedQuestion.userAnswer) {
        userAnswer = selectedQuestion.userAnswer.split(",");

        userLeftSideParis =
          userAnswer.length > 0 &&
          userAnswer
            .map((ans) => ans.split("::")[0])
            .map((ans, index) => ({
              name: ans,
              sequence: index + 1,
              disabled: true,
              count: index + 1,
              matched: true,
            }));

        userRightSideParis =
          userAnswer.length > 0 &&
          userAnswer
            .map((ans) => ans.split("::")[1])
            .map((ans, index) => ({
              name: ans,
              sequence: index + 1,
              disabled: true,
              count: index + 1,
              matched: true,
            }))
            .sort(() => Math.random() - 0.5);

        userLeftSideParis.map((left, index) => {
          userShuffledData.push(left);
          userShuffledData.push(userRightSideParis[index]);

          // prefill data

          prefillData.push({
            sequence: left.sequence,
            answer: `${left.name}::${userRightSideParis[index].name}`,
          });
        });

        prefillData.sort((a, b) => a.sequence - b.sequence);
      }

      useEffect(() => {
        if (prefillData.length > 0) {
          setAnswerData(prefillData.length > 0 ? prefillData : []);
        }
      }, []);

      const shuffledData = [];

      const leftSidePairs = answers
        .map((ans) => ans.answer.split("::")[0])
        .map((ans, index) => ({ name: ans, sequence: index + 1 }));
      const rightSidePairs = answers
        .map((ans) => ans.answer.split("::")[1])
        .map((ans, index) => ({
          name: ans,
          sequence: index + 1,
          disabled: true,
        }))
        .sort(() => Math.random() - 0.5);

      leftSidePairs.map((left, index) => {
        shuffledData.push(left);
        shuffledData.push(rightSidePairs[index]);
      });

      const [pairsArray, setPairsArray] = useState(
        selectedQuestion.userAnswer && userShuffledData.length > 0
          ? userShuffledData
          : shuffledData
      );

      const [choiceOne, setChoiceOne] = useState(null);
      const [choiceTwo, setChoiceTwo] = useState(null);

      const handleClick = (pair) => {
        choiceOne ? setChoiceTwo(pair) : setChoiceOne(pair);
      };

      useEffect(() => {
        if (choiceOne && !choiceTwo) {
          setPairsArray((prev) => {
            return prev.map((element, eIndex) => {
              if (element.name === choiceOne.name) {
                return {
                  ...element,
                  disabled: true,
                  count: element.sequence,
                };
              } else if (element.name !== choiceOne.name && eIndex % 2 === 0) {
                return {
                  ...element,
                  disabled: true,
                };
              } else if (eIndex % 2 !== 0 && !element.matched) {
                return {
                  ...element,
                  disabled: false,
                };
              } else {
                return element;
              }
            });
          });
        }

        if (choiceOne && choiceTwo) {
          setPairsArray((prev) => {
            return prev.map((element, eIndex) => {
              if (element.name === choiceOne.name) {
                return {
                  ...element,
                  disabled: true,
                  matched: true,
                };
              } else if (element.name === choiceTwo.name && eIndex % 2 !== 0) {
                return {
                  ...element,
                  disabled: true,
                  matched: true,
                  count: choiceOne.sequence,
                };
              } else if (
                element.name !== choiceOne.name &&
                eIndex % 2 === 0 &&
                !element.matched
              ) {
                return {
                  ...element,
                  disabled: false,
                };
              } else {
                return { ...element, disabled: true };
              }
            });
          });
          setAnswerData((prev) =>
            [
              ...prev,
              {
                sequence: choiceOne.sequence,
                answer: `${choiceOne.name}::${choiceTwo.name}`,
              },
            ].sort((a, b) => a.sequence - b.sequence)
          );
          resetChoice();
        }
      }, [choiceOne, choiceTwo]);

      const resetChoice = () => {
        setChoiceOne(null);
        setChoiceTwo(null);
      };

      const clearFunc = () => {
        setPairsArray(shuffledData);
        setChoiceOne(null);
        setChoiceTwo(null);
        setAnswerData([]);
        setErrorMessage(null);
        setSuccessMessage(null);
      };

      const saveFunc = async () => {
        if (answerData.length === 0) {
          setErrorMessage("Required!");
          return null;
        } else {
          setErrorMessage(null);
        }

        const res = await APICall(manageUserAssessment, "POST", {
          Mode: "SAVE",
          AssessmentId: state.assessmentId,
          UserAssessmentId: state.userAssessmentId,
          Answer: answerData
            .map((ans) => ans.answer)
            .join(",")
            .toString(),
          UserId: userDetails?.Id,
          RoleId: currentRoleId?.value,
          QuestionCode: questionCode,
          QuestionId: questionId,
        });

        if (res.status === 0) {
          setSuccessMessage("Your response submitted");

          const ques = await APICall(manageUserAssessment, "POST", {
            Mode: "UPDATE",
            AssessmentId: state.assessmentId,
            UserAssessmentId: state.userAssessmentId,
            QuestionId: questionId,
          });

          getUserAnswer();
        }
      };

      return (
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2">Answer</div>
            <div className="col-lg-10">
              <div className="container-fluid">
                <div className="row">
                  {pairsArray.map((pair, pairIndex) => (
                    <div
                      key={pairIndex}
                      className="col-lg-6 text-center my-1 border p-2">
                      <Badge
                        className="d-block"
                        badgeContent={pair?.count}
                        color="primary"
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}>
                        <button
                          key={pairIndex}
                          disabled={
                            (pair?.matched && pair?.disabled) || pair.disabled
                              ? pair.disabled
                              : false
                          }
                          onClick={() => {
                            handleClick(pair);
                          }}
                          className={"btn col-lg-6"}>
                          {pair.name}
                        </button>
                      </Badge>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row my-2">
          <div className="col-lg-2">Your Answer</div>
          <div className="col-lg-10">
            {answerData
              .map((ans) => ans.answer)
              .join(",")
              .toString()}
          </div>
        </div> */}
          {/* <div className="row my-2">
            <div className="col-lg-12">
              {successMessage && (
                <p style={{ color: "green" }}>{successMessage}</p>
              )}

              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            </div>
          </div> */}
          <CorrectAnswer />
          <Btns
            clearFunc={clearFunc}
            saveFunc={saveFunc}
            errorMessage={errorMessage}
            successMessage={successMessage}
          />
        </div>
      );
    };

    const BoolAnswer = () => {
      const [answerData, setAnswerData] = useState(
        selectedQuestion.userAnswer || null
      );

      const [errorMessage, setErrorMessage] = useState(null);
      const [successMessage, setSuccessMessage] = useState(null);

      const clearFunc = () => {
        setAnswerData(null);
        setErrorMessage(null);
        setSuccessMessage(null);
      };

      const saveFunc = async () => {
        if (!answerData) {
          setErrorMessage("Required!");
          return null;
        } else {
          setErrorMessage(null);
        }

        const res = await APICall(manageUserAssessment, "POST", {
          Mode: "SAVE",
          AssessmentId: state.assessmentId,
          UserAssessmentId: state.userAssessmentId,
          Answer: answerData,
          UserId: userDetails?.Id,
          RoleId: currentRoleId?.value,
          QuestionCode: questionCode,
          QuestionId: questionId,
        });

        if (res.status === 0) {
          setSuccessMessage("Your response submitted");

          const ques = await APICall(manageUserAssessment, "POST", {
            Mode: "UPDATE",
            AssessmentId: state.assessmentId,
            UserAssessmentId: state.userAssessmentId,
            QuestionId: questionId,
          });

          getUserAnswer();
        }
      };

      return (
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2">Answer</div>
            <div className="col-lg-10">
              {answers.map((ans, aIndex) => (
                <div key={aIndex} className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id={ans.answerId}
                    name="radios"
                    checked={
                      !answerData
                        ? false
                        : answerData === ans.answer
                        ? true
                        : false
                    }
                    value={ans.answer}
                    onChange={(e) => {
                      setAnswerData(e.target.value);
                    }}
                  />
                  <label className="form-check-label" htmlFor={ans.answerId}>
                    {ans.answer}
                  </label>
                </div>
              ))}
            </div>
          </div>
          {/* <div className="row my-2">
            <div className="col-lg-12">
              {successMessage && (
                <p style={{ color: "green" }}>{successMessage}</p>
              )}

              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            </div>
          </div> */}
          <CorrectAnswer />
          <Btns
            clearFunc={clearFunc}
            saveFunc={saveFunc}
            errorMessage={errorMessage}
            successMessage={successMessage}
          />
        </div>
      );
    };

    const ShortAnswer = () => {
      const [answerData, setAnswerData] = useState(
        selectedQuestion.userAnswer === null
          ? ""
          : selectedQuestion.userAnswer || ""
      );

      const [errorMessage, setErrorMessage] = useState(null);
      const [successMessage, setSuccessMessage] = useState(null);

      const clearFunc = () => {
        setAnswerData("");
        setErrorMessage(null);
        setSuccessMessage(null);
      };

      const saveFunc = async () => {
        if (!answerData && answerData.trim() === "") {
          setErrorMessage("Required!");
          return null;
        } else {
          setErrorMessage(null);
        }

        const res = await APICall(manageUserAssessment, "POST", {
          Mode: "SAVE",
          AssessmentId: state.assessmentId,
          UserAssessmentId: state.userAssessmentId,
          Answer: answerData,
          UserId: userDetails?.Id,
          RoleId: currentRoleId?.value,
          QuestionCode: questionCode,
          QuestionId: questionId,
        });

        if (res.status === 0) {
          setSuccessMessage("Your response submitted");

          const ques = await APICall(manageUserAssessment, "POST", {
            Mode: "UPDATE",
            AssessmentId: state.assessmentId,
            UserAssessmentId: state.userAssessmentId,
            QuestionId: questionId,
          });

          getUserAnswer();
        }
      };

      return (
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2">Answer</div>
            <div className="col-lg-10">
              <InputForm
                className="form-control"
                placeholder={""}
                isDisabled={false}
                textArea={true}
                value={answerData}
                onChange={(e) => {
                  setAnswerData(e.target.value);
                }}
                maxLength="255"
              />
            </div>
          </div>
          {/* <div className="row my-2">
            <div className="col-lg-12">
              {successMessage && (
                <p style={{ color: "green" }}>{successMessage}</p>
              )}

              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            </div>
          </div> */}
          <Btns
            clearFunc={clearFunc}
            saveFunc={saveFunc}
            errorMessage={errorMessage}
            successMessage={successMessage}
          />
        </div>
      );
    };

    switch (questionCode) {
      case "SINGLE":
        return <SingleAnswer />;
      case "MULTI":
        return <MultiAnswer />;
      case "MATCH":
        return <MatchAnswer />;
      case "BOOL":
        return <BoolAnswer />;
      case "SHORT":
        return <ShortAnswer />;
      default:
        return <>No Data!</>;
    }
  }
);

export default AssessmentAnswer;
