import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { LoaderContext } from "../../Helpers/Context/Context";
import { APICall } from "../../Helpers/APICalls";
import {
  GetContractorDetailsDasboardData,
  GetContractorDetailsDropDown,
} from "../../Helpers/APIEndPoints/AssociateEndPoints";
import { Collapse, Tooltip } from "@mui/material";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import InputForm from "../../Components/InputForm/InputForm";
import SelectForm from "../../Components/SelectForm/SelectForm";

interface IOptions {
  value?: number | string;
  label?: string;
  code?: string;
}

interface IFormData {
  contractorCategory?: IOptions | string | any;
}

const ContractorDashboard = () => {
  const navigate = useNavigate();
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  const { showLoader, hideLoader } = useContext(LoaderContext);

  const [formOptions, setFormOptions] = useState<IFormData>({});

  const [formData, setFormData] = useState<IFormData>({});

  const [open, setOpen] = useState(false);

  const [id, setId] = useState(0);

  const [count, setCount] = useState(0);
  const [gridData, setGridData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sortDirection, setSortDirection] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [searchText, setSearchText] = useState("");

  const [reSet, setreSet] = useState(false);

  useEffect(() => {
    const responseData = async () => {
      const parameters = {
        Mode: "VendorCategory",
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      };

      const res = await APICall(
        GetContractorDetailsDropDown,
        "POST",
        parameters
      );

      if (res?.status === 0 && res?.data?.length > 0) {
        setFormOptions((env) => {
          return {
            ...env,
            contractorCategory: res?.data,
          };
        });
      }
    };

    showLoader();

    responseData();

    hideLoader();
  }, []);

  const gridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: pageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      setPageNumber(page * pageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };
  const gridColumns = [
    {
      name: "Id",
      label: "Id",
      options: {
        display: false,
      },
    },

    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "contractorName",
      label: "Contractor Name",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "address",
      label: "Address",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "contractorType",
      label: "Type",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "clNumber",
      label: "CL Number",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "clDate",
      label: "CL No. Reg Date",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "contractorCategory",
      label: "Contractor Category",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "newEffectiveDate",
      label: "Effective Date",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "maxLicenseStrength",
      label: "Max License Strength",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta) => {
          let id = tableMeta.tableData[tableMeta.rowIndex].id;

          return (
            <div style={{ width: "100px" }}>
              <>
                <Tooltip title="Edit">
                  <a
                    className="mr-2"
                    style={{ fontSize: "15px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/ContractorCreation", {
                        state: { id: id },
                      });
                    }}
                  >
                    <i className="fas fa-edit"></i>
                  </a>
                </Tooltip>
              </>
            </div>
          );
        },
      },
    },
  ];

  useEffect(() => {
    getContractorDetailsDasboardDataAPICall();
  }, [
    pageNumber,
    pageSize,
    sortColumn,
    sortDirection,
    searchText,
    reSet,
    formData?.contractorCategory,
  ]);

  const getContractorDetailsDasboardDataAPICall = async () => {
    try {
      showLoader();

      const requestParams = {
        PageNumber: pageNumber,
        PageSize: pageSize,
        SortColumn: sortColumn,
        SortOrder: sortDirection,
        SearchText: searchText,
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
        ContractorCategoryIds: formData?.contractorCategory
          ?.map((val) => val?.value)
          .join(","),
      };

      const responseData = await APICall(
        GetContractorDetailsDasboardData,
        "POST",
        requestParams
      );

      if (
        responseData &&
        responseData.data !== null &&
        responseData.data.length > 0
      ) {
        setGridData(responseData.data);
        setCount(responseData.data[0].totalCount);
      } else {
        setGridData([]);
        setCount(0);
      }
    } catch (error) {
      console.error("GetContractorUserDasboardDetails", error);
    } finally {
      hideLoader();
    }
  };
  return (
    <>
      {/* <div className="form-main">
        <div className="page-title w-100">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <h4>
                  {" "}
                  <i
                    className="fa-solid fa-home pr-1"
                    style={{
                      color: "white",
                      cursor: "pointer",
                      fontSize: "13px",
                      verticalAlign: "middle",
                    }}
                    onClick={() => {
                      navigate("/Bluecollar");
                    }}
                  ></i>
                  Contractor Dashboard
                </h4>
              </div>
              <div className="offset-lg-6 "></div>
              <button
                onClick={() => {
                  navigate("/Contentedit", { state: { contentId: 0 } });
                }}
                className="btn btn-primary float-right mt-2 mb-2"
              >
                <i className="fa fa-plus"></i>
                Create Content
              </button>
              
            </div>
          </div>
        </div>
      </div> */}

      <div className="breadcrumb modified-bredcrumb">
        <h4>Contractor Dashboard</h4>
        <div className="SectionSubmit mb-2 clearfix">
          <button
            onClick={() => {
              navigate("/Home");
            }}
            disabled={false}
            className="btn btn-secondary float-right ml-2 mt-2"
          >
            <i className="fa-solid fa-arrow-left"></i> Back
          </button>

          <button
            onClick={() => {
              navigate("/ContractorCreation", {
                state: { id: 0 },
              });
            }}
            disabled={false}
            className="btn btn-primary float-right ml-2 mt-2"
          >
            <i className="fa fa-plus mr-2"></i>
            Add New
          </button>
        </div>
      </div>

      <div className="container-fluid mt-2" id="blue-collar-pages">
        <div className="grid-wrapper grid_mob">
          <div className="row mx-auto filtBox">
            <div className="col-lg-3 col-md-4 col-sm-12">
              <div className="form-group filtInp">
                <span className="mr-2 text-white" style={{ float: "left" }}>
                  <label className="col-form-label">{`Contractor Category`}</label>
                </span>
                <SelectForm
                  isClearable
                  options={formOptions?.contractorCategory}
                  placeholder={"Select"}
                  isDisabled={false}
                  onChange={(event) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        contractorCategory: event,
                      };
                    });
                  }}
                  isMulti={true}
                  noIndicator={false}
                  noSeparator={false}
                  value={formData?.contractorCategory}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="form-group filtInp">
                <InputForm
                  value={searchText}
                  placeholder={"Search Keywords"}
                  isDisabled={false}
                  textArea={false}
                  onChange={(e) => {
                    setPageNumber(0);
                    setSearchText(e.target.value);
                  }}
                />
              </div>
            </div>
            {/* <div className="col-lg-1 col-md-1 col-sm-12 filtrt">
              <ul className=" filter-icon-wel">
                <li>
                  <a
                    href="javascript:void(0);"
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                    className="filter-btn"
                  >
                    <i className="fa fa-filter"></i>
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>

        <>
          <Collapse in={open}>
            <div id="example-collapse-text grid-wrapper">
              <div className="row mx-auto myInlineStyle">
                <>
                  {/* <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">
                      Content ID
                    </label>
                    <SelectForm
                      isClearable
                      options={contentIdOptions}
                      placeholder={"Content ID"}
                      isDisabled={false}
                      onChange={(event) => {
                        setContentId(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={contentId}
                    />
                  </div> */}
                </>
                <div className="col-lg-12 d-flex mt-2 justify-content-end">
                  <button
                    onClick={() => {
                      //   resetFilters();
                    }}
                    className="btn btn-secondary mr-2"
                  >
                    <i className="fa fa-undo" aria-hidden="true"></i> Reset{" "}
                  </button>
                  <button
                    className="btn btn-filter-submit"
                    onClick={() => {
                      //   onSubmitFilter();
                    }}
                  >
                    <i className="fa fa-search" aria-hidden="true"></i> Search{" "}
                  </button>
                </div>
              </div>
            </div>
          </Collapse>
        </>
        <div className="mb-3 pt-3">
          <DynamicGrid
            data={gridData}
            columns={gridColumns}
            options={gridOptions}
          />
        </div>
      </div>
    </>
  );
};

export default ContractorDashboard;
