import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./GenericMaster.css";
import InputForm from "../../Components/InputForm/InputForm";
import SelectForm from "../../Components/SelectForm/SelectForm";
import { APICall } from "../../Helpers/APICalls";
import moment from "moment";
import {
  getMasterTypeDD,
  getGenericParentDDd,
  SaveGenericMasterData,
  PostForParentMasterMap,
} from "../../Helpers/APIEndPoints/EndPoints";
import InputRadioCheck from "../../Components/CheckBox/InputRadioCheck";
import ButtonForm from "../../Components/ButtonForm/ButtonForm";
import { getDate } from "date-fns";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { LoaderContext } from "../../Helpers/Context/Context";
import notify from "../../Helpers/ToastNotification";
import { Button } from "react-bootstrap";
import DateForm from "../../Components/DateForm/DateForm";
import { Description } from "@mui/icons-material";
import { isDisabled } from "@testing-library/user-event/dist/utils";

const PicklistAndMasterMapForm = ({}) => {
  const [description, setDescription] = useState<any>('');
  const [status, setStatus] = useState<any>(null);
  const [masterType, setMasterType] = useState<any>(null);
  const [masterTypeOption, setMasterTypeOption] = useState([]);
  const [Parent, setParent] = useState<any>(null);
  const [ParentOption, setParentOption] = useState([]);
  const [effectiveDate, setEffectiveDate] = useState(null);
  const [stateId, setstateId] = useState(0);
  const { userDetails, currentRoleId }: any = useUserContext();
  const Navigate = useNavigate();
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [formErrors, setFormErrors] = useState<any>({});
  let formErrorObj: any = {};
  const [IsParentDDShowNReq, setParentDDReq] = useState(null);
  const [IsActive, setIsActive] = useState(true);
  const [submitDisable, setSubmitDisable] = useState(false);

  useEffect(() => {
    console.log(stateId, "ddd");
    (async()=>{
      showLoader();
      await GetMasterDataDropdown();
      hideLoader();
    })();
  }, []);

  const GetMasterDataDropdown = async () => {
    try {
      const MTData = await APICall(getMasterTypeDD, "POST", {
        AC: "",
        PID: "",
      });

      if (MTData.data !== null && MTData.data.length > 0) {
        console.log("Master Type Data found!", MTData.data);
        // let temp: any[] = [];
        // MTData.data.map((d: any) => {
        //   let obj = {
        //     label: d.label,
        //     value: d.value,
        //     code: d.code,
        //   };
        //   temp.push(obj);
        // });
        setMasterTypeOption(MTData.data);
        setParentOption(MTData.data);
      } else {
        console.log("No Master Type data found!", MTData);
        //setTabs([]);
      }
    } catch (error) {
      console.error("", error);
    } finally {
    }
  };

  const handleOnChangeMT = async(event) => {
    setMasterType(event);

    const spreadArr = [...masterTypeOption];
    const tempArray = spreadArr.filter((item)=> item.value != event.value);
    setParentOption(tempArray);

    if(event.isParentRelation==true && event.parentMasterType!= null){
      setParentDDReq({ value:1, label: 'Yes', code: 'yes'});
      const forParentSet = spreadArr.filter((item)=> item.value == event.parentMasterType);
      setParent(forParentSet?.[0]);
    } else {
      setParentDDReq(null);
      setParent(null);
    }
  };

  const ParenthandleOnChange = (event) => {
    setParent(event);
  };

  const CheckValidation = async () => {
    let objError: any = {};
    let error = false;
    await setFormErrors({});

    if (
      masterType === "" ||
      masterType == undefined ||
      masterType == null
    ) {
      error = true;
      objError["masterType_isEmpty"] = "Please select Master Type";
    }

    if (
      (IsParentDDShowNReq!= null && IsParentDDShowNReq.code === 'yes') &&
      (Parent === "" || Parent == undefined || Parent == null)
    ) {
      error = true;
      objError["parent_isEmpty"] = "Please select Parent Master Type";
    }

    if (
        IsParentDDShowNReq === "" || IsParentDDShowNReq == undefined || IsParentDDShowNReq == null
      ) {
        error = true;
        objError["parentMapYesNo"] = "Please select Parent is available or not";
      }

    if (
        effectiveDate === "" || effectiveDate == undefined || effectiveDate == null
    ) {
      error = true;
      objError["error_EffectiveDate"] = "Please select Effective Date";
    }

    formErrorObj = objError;
    await setFormErrors(objError);
    return error;
  };

  const InsertUpdateActivityDetails = async () => {
    ////showLoader();
    setSubmitDisable(true);
    let IsInValid = await CheckValidation();

    let requestParams = {
      Id: stateId,
      EffectiveDate: effectiveDate,
      Description: description,
      MasterTypeId: masterType ? masterType.value : 0,
      IsParentAvailable: IsParentDDShowNReq ? IsParentDDShowNReq.value : 0,
      ParentId: Parent ? Parent.value : 0,
      IsActive: IsActive ? 1 : 0,
      CreatedBy: userDetails?.Id,
    };

    if (IsInValid == false) {
      console.log('requestParams::::',requestParams);
      const response = await APICall(
        PostForParentMasterMap,
        "POST",
        requestParams
      );

      if (response.data !== null) {
        if (response.data == 0 && response.message == "Ok") {
          notify(0, "Data saved successfully!");
          Navigate(-1);
          setSubmitDisable(false);
        } else {
          notify(1, response.message);
          setSubmitDisable(false);
        }
      } else {
        notify(1, response.message);
        setSubmitDisable(false);
      }
    }
  };

  return (
    <>
      <div className="col-lg-12">
        <div className="row"
          style={{
            backgroundColor:"#dce6ff",
            margin: 1,
            marginTop: 15,
            borderRadius: 4,
            boxShadow: "0 3px 5px #ccc",
          }}
        >
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Effective Date<sup>*</sup>
              </label>
              <DateForm
                isDisabled={false}
                value={effectiveDate}
                onChange={(date) => {
                  if (date != null) {
                    setEffectiveDate(moment(date).format("DD-MMM-YYYY"));
                  }
                }}
              />
              <span style={{ color: "red" }}>
                {formErrors["error_EffectiveDate"]}
              </span>
            </div>
          </div>

          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Master Type<sup>*</sup>
              </label>
              <SelectForm
                options={masterTypeOption}
                value={masterType}
                placeholder={"Select Master Type"}
                onChange={handleOnChangeMT}
                noIndicator={false}
                noSeparator={false}
              />
              <p style={{ color: "red" }}>{formErrors["masterType_isEmpty"]}</p>
            </div>
          </div>

          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Is Parental Mapping Required?<sup>*</sup>
              </label>
              <SelectForm
                options={[
                    { value:1, label: 'Yes', code: 'yes'},
                    { value:0, label: 'No', code: 'no'},
                ]}
                value={IsParentDDShowNReq}
                placeholder={"Select Master Type"}
                onChange={async(e)=>{
                    setParentDDReq(e);
                }}
                noIndicator={false}
                noSeparator={false}
              />
              <p style={{ color: "red" }}>{formErrors["parentMapYesNo"]}</p>
            </div>
          </div>

          {IsParentDDShowNReq!= null && IsParentDDShowNReq.code === 'yes' && (
            <div className="col-lg-3 col-sm-3 col-xs-4">
              <div className="mb-1">
                <label className="col-form-label" id="lblParent">
                  Parent Master Type<sup>*</sup>
                </label>
                <SelectForm
                  options={ParentOption}
                  value={Parent}
                  placeholder={"Select Parent Master Type"}
                  onChange={ParenthandleOnChange}
                  noIndicator={false}
                  noSeparator={false}
                />
                <p style={{ color: "red" }}>{formErrors["parent_isEmpty"]}</p>
              </div>
            </div>
          )}

          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                IsActive<sup>*</sup>
              </label>
              <div>
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={IsActive}
                  style={{ marginLeft: "0px" }}
                  onChange={()=>{}}
                  disabled
                />
              </div>
            </div>
          </div> 
          
          {/* col-xs-4 */}
          <div className="col-lg-6 col-sm-12 mobile-view top-m"> 
            <div className="mb-1">
              <label className="col-form-label" id="lblParent">
                Description
              </label>
              <InputForm
                value={description ? description : ''}
                placeholder={"Type Description"}
                onChange={(e)=>{
                  setDescription(e.target.value)
                }}
                isDisabled={false}
                textArea={true}
              />
              {/* <p style={{ color: "red" }}>{formErrors["parent_isEmpty"]}</p> */}
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-sm-12 col-xs-12">
        <div className="mb-1">
          <div style={{ textAlign: "end" }}>
            <Button
              className="btn btn-filter-submit float-right ml-2 btnBlue"
              onClick={
                () => {
                  InsertUpdateActivityDetails();
                }
              }
              disabled = {submitDisable}
            >
              <i className="fa-solid fa-check"></i> Submit
            </Button>

            <button
              onClick={() => {
                Navigate(-1);
              }}
              disabled={false}
              className="btn btn-secondary float-right"
            >
              <i className="fa-solid fa-arrow-left"></i> Back
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PicklistAndMasterMapForm;
