import React, { useContext, useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import QR from "../QRComponent/QR";
import defaultProfileImgPic from "../../Assets/Images/adhars.jpg";
import "./PlantGateExit.css";
import { LoaderContext } from "../../Helpers/Context/Context";
import { APICall } from "../../Helpers/APICalls";
import { UpdateTATForTransitionStages } from "../../Helpers/APIEndPoints/AssociateEndPoints";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import notify from "../../Helpers/ToastNotification";
import { Modal } from "react-bootstrap";
import UserDetails from "../MainGateEntry/UserDetailsEntry";; 

const PlantGateExit = () => {
  const [startScan, setStartScan] = useState<boolean>(false);
  const [scanData, setScanData] = useState(null);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [isActive, setisActive] = useState<any>(true);
  const [modalForGunScanner, setModalForGunScanner] = useState<boolean>(false);
  const [barcode, setBarcode] = useState<string>("");
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  const [showUserDetails, setShowUserDetails] = useState<boolean>(false); 
  const navigate = useNavigate();

  useEffect(() => {
    let interval: any;
    let str = "";
    const handleKeyDown = (evt: any) => {
      if (!modalForGunScanner) {
        return;
      }
      if (interval) clearInterval(interval);
      if (evt.code === "Enter") {
        if (barcode) handleBarcode(barcode);
        str = "";
        return;
      }
      if (evt.key !== "Shift") setBarcode((prev) => prev + evt.key);
      interval = setInterval(() => {
        str = "";
      }, 20);
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [barcode, modalForGunScanner]);
  const handleScanData = async (data) => {
    console.log(data, "Handle Scan Data");
    setScanData(JSON.parse(data));
    const response = JSON.parse(data);
    // console.log(response, "response");

    showLoader();
    const otherData = await APICall(UpdateTATForTransitionStages, "POST", {
      TokenId: response?.CandidateId,
      WorkflowStageId: 9,
      IsActive: isActive,
      CreatedBy: userDetails?.Id,
    });

    if (otherData?.status === 0) {
    //  notify(0, "OUT Time Entered Successfully");
      // hideLoader();
      setShowUserDetails(true); 
      hideLoader();
    }
    hideLoader();

    setStartScan(false);
  };

  const handleBarcode = async(scannedBarcode: any) => {
    const object = JSON.parse(scannedBarcode);
    // console.log(object);
    // candidateProfile(object?.CandidateId);
    setScanData(object || null);
  
    showLoader();
    const otherData = await APICall(UpdateTATForTransitionStages, "POST", {
      TokenId: object?.CandidateId,
      WorkflowStageId: 9,
      IsActive: isActive,
      CreatedBy: userDetails?.Id,
    });
    console.log(otherData, "otherData");
  
    if (otherData?.status === 0) {
    //  notify(0, "IN Time Entered Successfully");
      setShowUserDetails(true); 
      hideLoader(); // Adjust the delay (in milliseconds) as needed
    }
    hideLoader();
    setModalForGunScanner(false);
  };
  return (
    <>
      <div className="form-main">
        <div className="page-title w-100">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-4 col-md-4">
              <div className="row">
    <i className="fa-solid fa-home mt-3 mr-3 ml-3" style={{color:"white", cursor:'pointer'}} onClick={() => {
      navigate('/Bluecollar');
    }}></i>
                <h4>PlantGate Exit</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>

      <div className="container-fluid mt-2 " id="blue-collar-pages">
        <div className="col-md-12 d-flex justify-content-center align-items-start">
          <div className="p-3 text-center">
            {startScan ? (
              <QR startScanOps={startScan} onScan={handleScanData} />
            ) : (
              <img
                src={defaultProfileImgPic}
                className="card-img-top1"
                alt="Thumbnail Image"
              />
            )}
            <div className="SectionSubmit clearfix mt-2">

            <button
                onClick={() => {
                  setModalForGunScanner(true);
                }}
                disabled={false}
                className="btn btn-primary mr-2"
              >
                {<i className="fa-solid fa-qrcode mr-2"></i>}
                QR-Scan gun
              </button>
              <button
                onClick={() => {
                  setStartScan((prev) => !prev);
                }}
                disabled={false}
                className="btn btn-primary ml-2 "
              >
                <i className="fa-solid fa-camera mr-2"></i> QR-Camera
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={modalForGunScanner}
        onHide={() => {
          setModalForGunScanner(false);
        }}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Scan data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>{`Scan Data using scanner gun.`}</h4>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      {showUserDetails && <UserDetails id={scanData?.CandidateId} stageId={9}  alreadyScanned = {false}/>}
    </>
  );
};

export default PlantGateExit;
