import React, { useContext, useEffect, useRef, useState } from "react";
import { LoaderContext } from "../../Helpers/Context/Context";
import {
  updateAttachmentDetailsInTable,
  uploadSupportingDocForProgressionOnSendback,
  deleteSupportingDocForProgression,
  downloadSupportingDocForProgression,
  getLOPForLRProgression,
  getWorkflowData,
  updateSectionDocInProgression,
  uploadSectionDocForProgression,
} from "../../Helpers/APIEndPoints/EndPoints";
import { APICall } from "../../Helpers/APICalls";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  getDropdowns,
  handleApprovalForProgressionModel,
  entryUpdateInActionHistory,
  getDependentDataOfPos,
  getCommonDdForProgression,
  getUsermasterIdBasedOnEid,
  getRelationsForEmployee,
  getReporteesForEmployee,
  getEmpRlBasedOnId,
  getEmpRlMapForEmployee,
  getAllRoles,
  updateRepMgMapNewJson,
  updateEmpRlMapNewJson,
  getRepMgMapForEmployee,
  updateProgressionOnSave,
  createTaskForApprovers,
  getApproverAndProgressionDetailsByWorkflowTaskId,
  getDdForProgression,
  getSearchDdForProgression,
  getAttrForProgression,
  getSectionDetailsV2,
  getGlobalValues,
  getAttributeValueDetailsV2,
} from "../../Helpers/APIEndPoints/EndPoints";

import notify from "../../Helpers/ToastNotification";
import moment from "moment";
import { ExpandMore } from "@mui/icons-material";

import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import uuid from "react-uuid";
import SelectForm from "../../Components/SelectForm/SelectForm";
import InputForm from "../../Components/InputForm/InputForm";
import DateForm from "../../Components/DateForm/DateForm";
import { useLocation } from "react-router-dom";

import EmpProgSectionComponent from "./EmpProgSectComponent";
import useDataChangeStore from "../../Helpers/ZustandStore/DataChangeSectionStore";
import ChangeLog from "./ChangeLog";
import { json } from "stream/consumers";
import "./Request.css";
import useDocumentStore from "../../Helpers/ZustandStore/DocumentForProgressionStore";
import { set } from "date-fns";
import PreviousConversation from "./PreviousConversation";

const EmployeeSendback = ({ TaskId }) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [workflowTaskId, setWorkflowTaskId] = useState(TaskId ? TaskId : 0);
  const [accordionReqDet, setAccordionReqDet] = useState(true);
  const [accordionWrkfl, setAccordionWrkfl] = useState(false);
  const [accordianEvent, setAccordianEvent] = useState(false);
  const [formErrors, setFormErrors] = useState<any>({});
  const [dynamicformErrors, setDynamicformErrors] = useState<any>({});
  const [optionsObj, setOptionsObj] = useState<any>({});
  const [TId, setTId] = useState(0);
  const [modalIsOpen, setModal] = useState(false);
  const [disableSubmitBtn, setdisableSubmitBtn] = useState(true);
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  const FullName = `${userDetails.FirstName} ${userDetails.LastName}`;
  const [inputValues, setInputValues] = useState({}); //hold an object
  const [approvers, setApprovers] = useState<any>([]);
  const [approverOpt, setapproverOpt] = useState<any>({});
  const [allApprovers, setAllApprovers] = useState<any>({});
  const [dynamicAccordians, setDynamicAccordian] = useState<any>({});
  const [btnHide, setBtnHide] = useState(true);
  const [sectionTabHide, setSectionTabHide] = useState(true);
  const [sectionValuesArray, setSectionValuesArray] = useState<any>([]);
  const [formData, setFormData] = useState<any>({});
  const [finalformData, setFinalFormData] = useState<any>({});
  const [globalValues, setGlobalValues] = useState([]);

  const [WorkflowId, setWorkflowId] = useState(0);
  const [workflowData, setWorkflowData] = useState<any>({});

  const [getData, setGetData] = useState([]);
  const [submitFunc, setSubmitFunc] = useState(false);

  const [mapTypeVal, setMapTypeVal] = useState(0);
  const [repMgMap, setRepMgMap] = useState<any>([]);
  const [reportees, setReportees] = useState<any>([]);
  const [directReportee, setDirectReportee] = useState<any>(null);
  const [managerForReportee, setManagerForReportee] = useState<any>(null);
  const [reporteeManagerMap, setReporteeManagerMap] = useState<any>([]);
  const [managerDisable, setManagerDisable] = useState(false);

  const [waveOffReasonDisable, setWaveOffReasonDisable] = useState(false);
  const [probDateDisable, setProbDateDisable] = useState(true);
  const [trainDateDisable, setTrainDateDisable] = useState(true);

  const [rlMapTypeVal, setRlMapTypeVal] = useState(0);
  const [directReporteeRel, setDirectReporteeRel] = useState<any>(null);
  const [relationForReportee, setRelationForReportee] = useState<any>(null);
  const [relReportees, setRelReportees] = useState<any>([]);
  const [userRelationMap, setUserRelationMap] = useState<any>([]);
  const [relationDisable, setRelationDisable] = useState(false);
  const [empRlMap, setEmpRlMap] = useState<any>([]);

  const [oldProgressionJson, setOldProgressionJson] = useState("");
  const [showRepMgMap, setShowRepMgMap] = useState(false);
  const [showEmpRlMap, setShowEmpRlMap] = useState(false);
  const [optForRoles, setOptForRoles] = useState<any>([]);
  const [prevConversation, setPrevConversation] = useState<any>();

  // value Object for section
  const [attributesData, setAttributesData] = useState<any>({});

  const [attributeValidation, setAttributeValidation] = useState<any>({});

  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [allSectionData, setAllSectionsData] = useState([]);
  const [initiatorUserId, setInitiatorUserId] = useState(0);
  const [initiatorRoleId, setInitiatorRoleId] = useState(0);
  const [btnDisable, setBtnDisable] = useState(false);
  const [changeLogAccordian, setChangeLogAccordian] = useState(false);
  const [progressionId, setProgressionId] = useState(0);
  const [sectionEssId, setSectionEssId] = useState<any>(null);

  //-----
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [ogOptionsForCascade, setOgOptForCascade] = useState<any>({});
  const [ogoptsetonce, setogoptsetonce] = useState(false);
  ///////

  const [skillcategoryHide, setSkillCategoryHide] = useState(true);

  const { sendBackJson, updateSectionData }: any = useDataChangeStore();

  const { documents, insertDocument, updateDocument }: any = useDocumentStore();
  const [hideDivForEss, setHideDivForEss] = useState(true);

  const [linksForDeletedFiles, setLinksForDeletedFiles] = useState<any>([]);

  //transfer-----
  // const [customNameForTransfer, setCustomNameForTransfer] = useState<any>(null);
  const [originalNameForTransfer, setOriginalNameForTransfer] = useState<any>(null);
  const [codeCheckForTansfer, setCodeCheckForTransfer] = useState<any>([]);
  //--------------------
  const [preConvJson, SetPreconvjson] = useState<any>(null);

  const parseJSON = (jsonString) => {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      return null;
    }
  };

  const typeOptions = [
    { value: 1, label: "Transfer all to upper position.", code: "TATUP" },
    { value: 2, label: "Transfer all to other manager.", code: "TATOM" },
    { value: 3, label: "Individual transfer to other manager.", code: "ITTOM" },
  ];
  const typeOptionsRel = [
    { value: 1, label: "Transfer all to upper position.", code: "RLTATUP" },
    { value: 2, label: "Transfer all to other manager.", code: "RLTATOM" },
    {
      value: 3,
      label: "Individual transfer to other manager.",
      code: "RLITTOM",
    },
    { value: 4, label: "Terminate all Relations.", code: "RLTAR" },
  ];

  useEffect(() => {
    if (
      attributesData["REQTYPOUPD"] !== null &&
      attributesData["REQTYPOUPD"] !== undefined &&
      attributesData["REQRSNOUP"] !== null &&
      attributesData["REQRSNOUP"] !== undefined &&
      attributesData["REQEMPLID"] !== null &&
      attributesData["REQEMPLID"] !== undefined 
      // && attributesData["REQCNTRY"] !==null &&
      // attributesData["REQCNTRY"] !== undefined
    ) {
      (async () => {
        const workflowRes = await APICall(getWorkflowData, "POST", {
          WorkflowTypeId: attributesData["REQTYPOUPD"].value,
          WorkflowSubTypeId: attributesData["REQRSNOUP"].value,
          EmployeeId: attributesData["REQEMPLID"].value,
          InitiatorId: userDetails.Id,
          InitiatorRoleId: currentRoleId?.value,
          CountryId:  attributesData["REQCNTRY"] ? attributesData["REQCNTRY"].value : null,
          PayrollLocationId: attributesData["REQPLOCN"]
          ? attributesData["REQPLOCN"]?.value
          : null,
          SectionId: sectionEssId ? sectionEssId : null,
        });
        //console.log(workflowRes);
        if (workflowRes.data.length > 0) {
          setApprovers(workflowRes.data);
          let temp = workflowRes.data;
          if (temp.length == 1) {
            setBtnHide(false);
          }
          if (temp.length > 1) {
            temp.map((eachapprover, index) => {
              index > 0 &&
                setapproverOpt((prev) => ({
                  ...prev,
                  [`${"Approver"} ${index}`]: eachapprover.approvers,
                }));
              setAllApprovers((prev) => ({
                ...prev,
                [`${"Approver"} ${index}`]: eachapprover.approvers,
              }));
              if (
                eachapprover.approvers !== null &&
                eachapprover.approvers.length > 0
              ) {
                setBtnHide(false);
              }
            });
          }
          setWorkflowId(workflowRes.data[0].workFlowId);
          const attrData = await APICall(getAttrForProgression, "POST", {
            WorkFlowId: workflowRes.data[0].workFlowId,
            COU: attributesData["REQEMPLID"].cou,
            userid: userDetails.Id,
            roleid: currentRoleId?.value,
            IsNewHire: true,
            IsProg: true,
            EmployeeId: parseInt(attributesData["REQEMPLID"].value),
          });

          setProgressionId(workflowData?.ProgressionId);
          const NewJson = parseJSON(workflowData.RequestNewJson) || [];

          if (attrData.data !== null && attrData.data.length > 0) {
            attrData.data.map(async (eachAttr) => {
              if (eachAttr.attributeType == "Attribute") {
                if (eachAttr.attributeDatatype === "DropdownSingle") {
                  const resultObj = NewJson.find((obj) =>
                    obj.hasOwnProperty(eachAttr.attributeCode)
                  );

                  if (resultObj) {
                    const valForDD = eachAttr.options.find(
                      (item) => item.value === resultObj[eachAttr.attributeCode]
                    );
                    eachAttr.valueOnDd = valForDD || null;

                    // set extra attributes when demotion
                    if (attributesData["REQTYPOUPD"].code == "Demotion") {
                      for (const attribute of NewJson) {
                        if (eachAttr.attributeCode === attribute.Code) {
                          eachAttr.valueOnDd =
                            attribute.Main[0][attribute.Code];
                        }
                      }
                    }

                    if (eachAttr.attributeCode == "WOA") {
                      if (valForDD?.code == "CODEWOAPPR2") {
                        setWaveOffReasonDisable(true);
                      } else {
                        setWaveOffReasonDisable(false);
                      }
                    }

                    //customization for confirmation
                    if (
                      attributesData?.["REQRSNOUP"]?.code == "PROB" &&
                      eachAttr.attributeCode == "PC" &&
                      valForDD &&
                      valForDD?.code == "Yes001"
                    ) {
                      setProbDateDisable(false);
                    } else if (
                      attributesData?.["REQRSNOUP"]?.code == "PROB" &&
                      eachAttr.attributeCode == "PC" &&
                      valForDD &&
                      valForDD?.code == "No002"
                    ) {
                      setProbDateDisable(true);
                    }

                    if (
                      attributesData?.["REQRSNOUP"]?.code == "TRNG" &&
                      eachAttr.attributeCode == "TC" &&
                      valForDD &&
                      valForDD?.code == "P001"
                    ) {
                      setTrainDateDisable(false);
                    } else if (
                      attributesData?.["REQRSNOUP"]?.code == "TRNG" &&
                      eachAttr.attributeCode == "TC" &&
                      valForDD &&
                      valForDD?.code == "P002"
                    ) {
                      setTrainDateDisable(true);
                    }
                    //----------------------------------------------------------

                    //CUSTOMIZATION FOR Promotion(India)
                    if (
                      attributesData?.["REQCNTRY"]?.code == "CODECNTRY1" &&
                      attributesData?.["REQTYPOUPD"]?.code == "Promotion" &&
                      eachAttr.attributeCode == "EC" &&
                      valForDD &&
                      valForDD?.code == "Worker"
                    ) {
                      setSkillCategoryHide(false);
                    } else if (
                      attributesData?.["REQCNTRY"]?.code == "CODECNTRY1" &&
                      attributesData?.["REQTYPOUPD"]?.code == "Promotion" &&
                      eachAttr.attributeCode == "EC" &&
                      valForDD &&
                      valForDD?.code != "Worker"
                    ) {
                      setSkillCategoryHide(true);
                    }
                    //----------------------------------------------------------
                  } else {
                    const valForDD = eachAttr.options.find(
                      (item) => item.value === eachAttr.value
                    );
                    eachAttr.valueOnDd = valForDD || null;

                    console.log(
                      attributesData?.["REQCNTRY"]?.code,
                      attributesData?.["REQTYPOUPD"]?.code,
                      eachAttr.attributeCode,
                      valForDD
                    );

                    //CUSTOMIZATION FOR Promotion(India)
                    if (
                      attributesData?.["REQCNTRY"]?.code == "CODECNTRY1" &&
                      attributesData?.["REQTYPOUPD"]?.code == "Promotion" &&
                      eachAttr.attributeCode == "EC" &&
                      valForDD &&
                      valForDD?.code == "Worker"
                    ) {
                      setSkillCategoryHide(false);
                    } else if (
                      attributesData?.["REQCNTRY"]?.code == "CODECNTRY1" &&
                      attributesData?.["REQTYPOUPD"]?.code == "Promotion" &&
                      eachAttr.attributeCode == "EC" &&
                      valForDD &&
                      valForDD?.code != "Worker"
                    ) {
                      setSkillCategoryHide(true);
                    }
                    //----------------------------------------------------------

                    // set extra attributes when demotion
                    if (attributesData["REQTYPOUPD"].code == "Demotion") {
                      for (const attribute of NewJson) {
                        if (eachAttr.attributeCode === attribute.Code) {
                          eachAttr.valueOnDd =
                            attribute.Main[0][attribute.Code];
                        }
                      }
                    }

                    if (eachAttr.attributeCode == "WOA") {
                      if (valForDD?.code == "CODEWOAPPR2") {
                        setWaveOffReasonDisable(true);
                      } else {
                        setWaveOffReasonDisable(false);
                      }
                    }
                  }
                } else if (
                  eachAttr.attributeDatatype === "Text" ||
                  eachAttr.attributeDatatype === "Number"
                ) {
                  const resultObj = NewJson.find((obj) =>
                    obj.hasOwnProperty(eachAttr.attributeCode)
                  );
                  eachAttr.value =
                    resultObj[eachAttr.attributeCode] != null &&
                    resultObj[eachAttr.attributeCode] != undefined
                      ? resultObj[eachAttr.attributeCode]
                      : "";
                  eachAttr.valueOnDd =
                    eachAttr.value != null && eachAttr.value != undefined
                      ? eachAttr.value
                      : "";
                } else if (eachAttr.attributeDatatype === "Date") {
                  const resultObj = NewJson.find((obj) =>
                    obj.hasOwnProperty(eachAttr.attributeCode)
                  );

                  const parsedDateNew = moment(
                    resultObj[eachAttr.attributeCode],
                    "YYYY-MM-DD"
                  );

                  const formattedDateNew = parsedDateNew.format("DD-MMM-YYYY");
                  eachAttr.value = formattedDateNew || null;
                  eachAttr.valueOnDd = formattedDateNew || null;
                } else if (
                  eachAttr.attributeDatatype === "SearchableDropdownSingle"
                ) {
                  const resultObj = NewJson.find((obj) =>
                    obj.hasOwnProperty(eachAttr.attributeCode)
                  );
                  const obj = await getSearchableDropdownAPI(
                    "",
                    eachAttr.attributeCode,
                    null,
                    resultObj[eachAttr.attributeCode],
                    "attribute",
                    0
                  );
                  eachAttr.valueOnDd = obj;
                }
              }
              if (eachAttr.attributeType == "AttributeSet") {
                let tabcode = "";
                if (eachAttr.attributeCode == "SA_CompensationSet") {
                  tabcode = "Employment";
                } else if (eachAttr.attributeCode == "SA_DisciplinaryActions") {
                  tabcode = "Employment";
                }
                setSectionValuesArray([]); //imp

                (async () => {
                  await showLoader();
                  const section = await APICall(getSectionDetailsV2, "POST", {
                    MN: "EmployeeCentral",
                    IN: "EC_Form",
                    TN: tabcode,
                    UID: userDetails.Id,
                    TID: parseInt(attributesData["REQEMPLID"].value),
                    RoleId: currentRoleId?.value,
                    //COU:TId==0?COU:userDetails.COU,
                    COU: userDetails.COU,
                    IsNewHire: true,
                  });

                  console.log("Sections: ", section);
                  if (
                    section.data !== null &&
                    section.data.t !== undefined &&
                    section.data.t.tn === tabcode
                  ) {
                    let setFinalSections = [];
                    if (eachAttr.attributeCode == "SA_CompensationSet") {
                      const sect = section.data.t.S?.find(
                        (item) => item.SN == "Compensation"
                      );
                      setFinalSections.push(sect);

                      setFinalFormData(setFinalSections);
                    } else if (
                      eachAttr.attributeCode == "SA_DisciplinaryActions"
                    ) {
                      const sect = section.data.t.S?.find(
                        (item) => item.SN == "DisciplinaryActions"
                      );
                      console.log(sect);
                      setFinalSections.push(sect);
                      setFinalFormData(setFinalSections);
                    }
                  } else {
                    setFinalFormData([]);
                  }
                  await hideLoader();
                })();
                (async () => {
                  showLoader();
                  let bodyObj = {
                    mn: "EmployeeCentral",
                    in: "EC_Form",
                    tn: tabcode,
                    SN: "Basic",
                    TID: parseInt(attributesData["REQEMPLID"].value),
                    ISH: "true",
                    UserId: userDetails?.Id,
                    RoleId: currentRoleId?.value,
                    COU: userDetails.COU,
                  };

                  const response = await APICall(
                    getAttributeValueDetailsV2,
                    "POST",
                    bodyObj
                  );
                  if (
                    response.data.sections &&
                    response.data.sections.length > 0
                  ) {
                    setSectionValuesArray(response.data.sections);
                  } else {
                    setSectionValuesArray([]);
                  }
                  hideLoader();
                })();
                if (attributesData["REQEMPLID"] != null) {
                  (async () => {
                    await showLoader();
                    const globalValues = await APICall(
                      getGlobalValues,
                      "POST",
                      {
                        TID: attributesData["REQEMPLID"].value,
                      }
                    );

                    if (
                      globalValues.data !== null &&
                      globalValues.data.length > 0
                    ) {
                      setGlobalValues(globalValues.data);
                    }
                    await hideLoader();
                  })();
                }
              }
            });
            if (attrData.data.length > 1) {
              setDynamicAccordian(attrData.data);
            } else if (
              attrData.data.length == 1 &&
              attrData.data[0].attributeType == "Attribute"
            ) {
              setDynamicAccordian(attrData.data);
            }
          }
          // console.log("Attributes: ", attrData.data);
        }
      })();
    }
  }, [
    attributesData["REQTYPOUPD"],
    attributesData["REQRSNOUP"],
    attributesData["REQEMPLID"],
  ]);
  
  useEffect(() => {
    if (Object.keys(formData).length > 0 && allSectionData) {
      (async () => {
        await showLoader();

        let setFinalSections = [];
        let sectArr = [];
        attributesData["REQSECT"]?.map((eachSect) => {
          sectArr.push(eachSect?.value);
        });

        sectArr.map((eachId) => {
          const settingVal = formData?.S?.find((item) => item?.SId == eachId);
          setFinalSections.push(settingVal);
        });
        if (finalformData != null || finalformData != undefined) {
          setFinalFormData(setFinalSections);
        }
        await hideLoader();
      })();
    }
  }, [formData, allSectionData]);

  const getDdData = async (AC, PID) => {
    await showLoader();
    const GenId = currentRoleId?.value;
    const dropdownData = await APICall(getDdForProgression, "POST", {
      AC,
      PID,
      GenId,
      UserId:userDetails.Id
    });

    if (
      dropdownData.data !== null &&
      dropdownData.data !== undefined &&
      dropdownData.data.length > 0
    ) {
      await hideLoader();
      return dropdownData.data;
    } else {
      await hideLoader();
      return [];
    }
  };

  const getSearchableDropdownAPI = async (
    searchString,
    AC,
    cb,
    valueForDropdown,
    type,
    gridId
  ) => {
    if (
      searchString === "" &&
      cb === null &&
      valueForDropdown !== "" &&
      AC !== ""
    ) {
      const response = await APICall(getSearchDdForProgression, "POST", {
        searchString,
        AC,
        valueForDropdown,
      });

      if (response.data !== null && response.data.length > 0) {
        if (response.data.length === 1) {
          return response.data[0];
        }
      }
    } else if (searchString.length > 1) {
      const response = await APICall(getSearchDdForProgression, "POST", {
        searchString,
        AC,
      });
      if (response.data !== null && response.data.length > 0) {
        cb(response.data);
        setOptionsObj((prev) => ({ ...prev, ["REQEMPLID"]: response.data }));
      } else {
        cb([]);
        setOptionsObj((prev) => ({ ...prev, ["REQEMPLID"]: [] }));
      }
    }
  };

  const getCommonDdData = async (AC, PID) => {
    await showLoader();
    // const userid = attributesData?.["REQEMPLID"]?.value;
    // const dropdownData = await APICall(getCommonDdForProgression, "POST", {
    //   AC,
    //   PID,
    //   UserId: userid,
    // });
    const TId = attributesData?.["REQEMPLID"]?.value;
    const dropdownData = await APICall(getDropdowns, "POST", {
      AC,
      PID,
      TId,
      UserId: proxyUserDetails?.EmployeeId
        ? proxyUserDetails?.EmployeeId
        : userDetails?.Id,
      RoleId: currentRoleId?.value,
      RoleTypeId: 3,
      IsNewHire: true,
      IsProg: true,
    });

    if (
      dropdownData.data !== null &&
      dropdownData.data !== undefined &&
      dropdownData.data.length > 0
    ) {
      await hideLoader();
      return dropdownData.data;
    } else {
      await hideLoader();
      return [];
    }
  };

  //CALL A USE-EFFECT TO GET DATA FROM DATABASE
  useEffect(() => {
    setAttributesData((prev) => ({
      ...prev,
      ["REQTYPOUPD"]: null,
      ["REQRSNOUP"]: null,
      ["REQTABS"]: null,
      ["REQSECT"]: null,
      ["REQEMPLID"]: null,
      ["REQEMPLNM"]: "",
      ["REQEFCTVDT"]: null,
      ["REQSUPDOC"]: "",
      ["REQDREMK"]: "",
      ["WRKFLINID"]: userDetails.Id,
      ["WRKFLINNM"]: FullName,
    }));
    setAttributeValidation((prev) => ({
      ["REQEFCTVDT"]: "Please Select a date",
    }));
    (async () => {
      const optForCt = await getDdData("REQCNTRY", "");
      const optForPayroll = await getDdData("REQPLOCN", "");
      const optForType = await getDdData("REQTYPOUPD", "");
      const optForSubType = await getDdData("REQRSNOUP", "");
      const optForTab = await getDdData("REQTABS", "");

      const forRoles = await APICall(getAllRoles, "POST", {});
      setOptForRoles(forRoles.data);

      //const optForSect = await getDdData("REQSECT", "");
      setOptionsObj((prev) => ({
        ...prev,
        ["REQCNTRY"]: optForCt,
        ["REQPLOCN"]: optForPayroll,
        ["REQTYPOUPD"]: optForType,
        ["REQRSNOUP"]: optForSubType,
        ["REQTABS"]: optForTab,
        ["REQSECT"]: [],
      }));
      await GetDataOnWorkflowTaskId(
        optForType,
        optForSubType,
        optForCt,
        optForPayroll
      );
    })();
  }, []);

  const parsePreviousConversation = (previousConversation) => {
    let conversation = "";
    const remarksArray = parseJSON(previousConversation);

    if (remarksArray) {
      remarksArray.map((item) => {
        const Date = item.ModifiedDateUtc
          ? moment.utc(item.ModifiedDateUtc).local().format("DD-MMM-YYYY")
          : "";
        const ApproverName = item.UserName || "";
        const RoleName = item.RoleName || "";
        const Remarks = item.Remarks || "";

        if (Remarks?.trim().length > 0) {
          conversation += `${Date} ${ApproverName} ${RoleName}\n`;
          conversation += `\t\t${Remarks}\n\n`;
        }
      });
    }
    return conversation;
  };

  function parseJsonViseVersa(json) {
    if (Array.isArray(json)) {
      const result = {};

      json.forEach((item) => {
        const sectionId = item.sectionId;
        const code = item.Code;
        const attribute = item.Main[0];

        if (!result[sectionId]) {
          result[sectionId] = {};
        }

        if (item.IsAttributeSet) {
          if (!result[sectionId][code]) {
            result[sectionId][code] = [];
          }
          result[sectionId][code].push(attribute);
        } else {
          result[sectionId][code] = attribute[code];
        }
      });

      return result;
    }
  }

  const GetDataOnWorkflowTaskId = async (
    optForType,
    optForSubType,
    optForCt,
    optForPayroll
  ) => {
    try {
      await showLoader();

      const Id = workflowTaskId;
      const workflowData = await APICall(
        getApproverAndProgressionDetailsByWorkflowTaskId,
        "POST",
        { Id }
      );

      if (!workflowData || !workflowData.data || !optionsObj) {
        await hideLoader();
        alert("No WFL");
        return;
      }

      //add supporting doc
      if (
        workflowData.data.supportingDoc !== "" &&
        workflowData.data.supportingDoc !== null
      ) {
        let supportingDoc = JSON.parse(workflowData.data.supportingDoc);

        supportingDoc = supportingDoc.map((doc) => ({
          ...doc,
          IsValidFile: true,
        }));

        setSelectedFilesForFlat(supportingDoc);
      }

      //add section doc for data change
      if (
        workflowData.data.sectionDoc !== "" &&
        workflowData.data.sectionDoc !== null
      ) {
        let sectionDoc = JSON.parse(workflowData.data.sectionDoc);
        sectionDoc = sectionDoc.map((doc) =>
          doc.IsActive ? { ...doc, IsValidFile: true } : doc
        );
        updateDocument(sectionDoc);
      } else {
        updateDocument([]);
      }

      const {
        EmployeeId,
        WorkflowTypeId,
        WorkflowSubTypeId,
        EmployeeName,
        EffectiveDate,
        ProgressionRemarks,
        InitiatorId,
        InitiatorName,
        ProgressionId,
        AuditLogOldJson,
        ProgressionCountryId,
        ProgressionPayrollLocationId,
      } = workflowData.data;

      setProgressionId(ProgressionId);
      setSectionEssId(workflowData.data.WorkflowSectionId);

      const previousConversation = parsePreviousConversation(
        workflowData.data.PreviousConversation
      );

      SetPreconvjson( workflowData.data.PreviousConversation);
      setPrevConversation(previousConversation);

      if (AuditLogOldJson) {
        const newAuditLogOldJson = JSON.parse(AuditLogOldJson) || {};
        setOldProgressionJson(newAuditLogOldJson);
      }

      const retEmpObj = await getSearchableDropdownAPI(
        "",
        "IOPPOS",
        null,
        EmployeeId,
        "attribute",
        0
      );
      const wflType = optForType.find(
        (eachOpt) => eachOpt.value === WorkflowTypeId
      );
      const wflSubType = optForSubType.find(
        (eachOpt) => eachOpt.value === WorkflowSubTypeId
      );
      const cntry = optForCt.find(
        (eachOpt) => eachOpt.value == ProgressionCountryId
      );
      const payroll = optForPayroll.find(
        (eachOpt) => eachOpt.value == ProgressionPayrollLocationId
      );

      //for Transfer Event
      const newLabel = workflowData.data.reasonNewLabel;
      setOriginalNameForTransfer(wflSubType.label);
      if (wflType?.code == "Transfer" && newLabel){
        wflSubType.label = newLabel;
        const arrtemp = newLabel.split('_');
        const filteredArr = arrtemp.filter((item)=> item!='Transfer');
        setCodeCheckForTransfer(()=>[...filteredArr]);
      }
      //------------------

      setWorkflowData(workflowData.data);
      setProgressionId(ProgressionId || 0);
      setAttributesData((prev) => ({
        ...prev,
        REQCNTRY: cntry,
        REQPLOCN: payroll,
        REQTYPOUPD: wflType,
        REQRSNOUP: wflSubType,
        REQTABS: null,
        REQSECT: null,
        REQEMPLID: retEmpObj,
        REQEMPLNM: EmployeeName,
        REQEFCTVDT: EffectiveDate,
        REQSUPDOC: "",
        REQDREMK: ProgressionRemarks || "",
        WRKFLINID: InitiatorId,
        WRKFLINNM: InitiatorName,
      }));

      if (wflType?.code === "Separation" || wflType?.code === "Termination") {
        const RepMgData = await APICall(getRepMgMapForEmployee, "POST", {
          progressionId: ProgressionId,
        });

        setMapTypeVal(RepMgData.data.id);
        setRepMgMap(parseJSON(RepMgData.data.value));

        const EmpRlData = await APICall(getEmpRlMapForEmployee, "POST", {
          progressionId: ProgressionId,
        });

        setRlMapTypeVal(EmpRlData.data.id);
        setEmpRlMap(parseJSON(EmpRlData.data.value));
      } else if (wflType.code == "DataChange" && wflSubType.code == "DTACHG") {
        setTId(EmployeeId);
        let newJson = [];
        let result: any = {};
        const responseData: any = workflowData.data;

        newJson = JSON.parse(responseData?.RequestNewJson);

        let arr = newJson.map((item) => {
          const sectionId = item.sectionId;
          const code = item.Code;
          const attribute = item.Main[0];

          if (!result[sectionId]) {
            result[sectionId] = {};
          }

          if (item.IsAttributeSet) {
            if (!result[sectionId][code]) {
              result[sectionId][code] = [];
            }
            result[sectionId][code].push(attribute);
          } else {
            result[sectionId][code] = attribute[code];
          }
        });

        setInitiatorUserId(responseData?.InitiatorId);
        setInitiatorRoleId(responseData?.InitiatorRoleId);

        updateSectionData(result, true);
        setAllSectionsData(result);

        let tabSectionJson = JSON.parse(responseData?.TabSectionMappingJson);

        setAttributesData((prev) => {
          return {
            ...prev,
            ["REQTABS"]: tabSectionJson.tab || null,
            ["REQSECT"]: tabSectionJson.section || null,
          };
        });
      } else if (
        wflType.code == "Compensation" ||
        wflType.code == "Suspension" ||
        wflType.code == "Demotion"
      ) {
        setTId(EmployeeId);
        let newJson = [];
        let result: any = {};
        const responseData: any = workflowData.data;

        newJson = JSON.parse(responseData?.RequestNewJson);

        let arr = newJson.map((item) => {
          const sectionId = item.sectionId;
          const code = item.Code;
          const attribute = item.Main[0];

          if (!result[sectionId]) {
            result[sectionId] = {};
          }

          if (item.IsAttributeSet) {
            if (!result[sectionId][code]) {
              result[sectionId][code] = [];
            }
            result[sectionId][code].push(attribute);
          } else {
            result[sectionId][code] = attribute[code];
          }
        });

        setInitiatorUserId(responseData?.InitiatorId);
        setInitiatorRoleId(responseData?.InitiatorRoleId);

        updateSectionData(result, true);
        setAllSectionsData(result);

        let tabSectionJson = JSON.parse(responseData?.TabSectionMappingJson);
      }

      await hideLoader();
    } catch (error) {
      console.error("Error:", error);
      await hideLoader();
      alert("An error occurred.");
    }
  };

  useEffect(() => {
    if (mapTypeVal != null && repMgMap != null && repMgMap.length > 0) {
      (async () => {
        setDirectReportee(typeOptions.find((item) => item.value == mapTypeVal));
        if (mapTypeVal == 1 || mapTypeVal == 2) {
          const mgId = await getSearchableDropdownAPI(
            "",
            "MG",
            null,
            repMgMap[0].ManagerId,
            "attribute",
            0
          );
          setManagerForReportee(mgId);

          let repMg = [];
          repMgMap.map(async (eachRep) => {
            let Reportee = await getSearchableDropdownAPI(
              "",
              "MG",
              null,
              eachRep.EmpId,
              "attribute",
              0
            );
            Reportee.mg = mgId;
            repMg.push(Reportee);
          });
          setReporteeManagerMap(repMg);
          setShowRepMgMap(false);
        }
        if (mapTypeVal == 3) {
          let repMg = [];
          repMgMap.map(async (eachRep) => {
            let Reportee = await getSearchableDropdownAPI(
              "",
              "MG",
              null,
              eachRep.EmpId,
              "attribute",
              0
            );
            const Manager = await getSearchableDropdownAPI(
              "",
              "MG",
              null,
              eachRep.ManagerId,
              "attribute",
              0
            );
            Reportee.mg = Manager;
            repMg.push(Reportee);
          });
          setReporteeManagerMap(repMg);
          setShowRepMgMap(true);
        }
      })();
      if (mapTypeVal == 1 || mapTypeVal == 3) {
        setManagerDisable(true);
      } else if (mapTypeVal == 2) {
        setManagerDisable(false);
      }
    }
  }, [mapTypeVal, repMgMap]);

  useEffect(() => {
    if (rlMapTypeVal != null && empRlMap != null && empRlMap.length > 0) {
      (async () => {
        setDirectReporteeRel(
          typeOptionsRel.find((item) => item.value == rlMapTypeVal)
        );
        // console.log('Relations Type: ',rlMapTypeVal, 'Realtions: ',empRlMap);
        if (rlMapTypeVal == 1 || rlMapTypeVal == 2) {
          const relId = await getSearchableDropdownAPI(
            "",
            "RELNME", //Change this with "RELNME" once kiran sir's update is done
            null,
            empRlMap[0].RelationId,
            "attribute",
            0
          );
          setRelationForReportee(relId);
          let empRl = [];
          empRlMap.map(async (eachRep) => {
            // Send Id and Get Details
            const RelData = await APICall(getEmpRlBasedOnId, "POST", {
              Id: eachRep.Id,
            });

            const userid = await getSearchableDropdownAPI(
              "",
              "MG",
              null,
              RelData.data.userid,
              "attribute",
              0
            );
            const relationtype = optForRoles.find(
              (item) => item.value == RelData.data.relationtype
            );

            const RelDataNew = {
              id: eachRep.Id,
              userid: userid,
              relationtype: relationtype,
              relationid: relId,
            };
            empRl.push(RelDataNew);
          });
          setUserRelationMap(empRl);
          setShowEmpRlMap(false);
        } else if (rlMapTypeVal == 4) {
          const relId = null;
          setRelationForReportee(relId);
          let empRl = [];
          empRlMap.map(async (eachRep) => {
            // Send Id and Get Details
            const RelData = await APICall(getEmpRlBasedOnId, "POST", {
              Id: eachRep.Id,
            });

            const userid = await getSearchableDropdownAPI(
              "",
              "MG",
              null,
              RelData.data.userid,
              "attribute",
              0
            );
            const relationtype = optForRoles.find(
              (item) => item.value == RelData.data.relationtype
            );

            const RelDataNew = {
              id: eachRep.Id,
              userid: userid,
              relationtype: relationtype,
              relationid: relId,
            };
            empRl.push(RelDataNew);
          });
          setUserRelationMap(empRl);
          setShowEmpRlMap(false);
        } else if (rlMapTypeVal == 3) {
          let empRl = [];
          empRlMap.map(async (eachRep) => {
            // Send Id and Get Details
            const RelData = await APICall(getEmpRlBasedOnId, "POST", {
              Id: eachRep.Id,
            });

            const userid = await getSearchableDropdownAPI(
              "",
              "MG",
              null,
              RelData.data.userid,
              "attribute",
              0
            );
            const relationtype = optForRoles.find(
              (item) => item.value == RelData.data.relationtype
            );
            const relationid = await getSearchableDropdownAPI(
              "",
              "RELNME", //Change this with "RELNME" once kiran sir's update is done
              null,
              eachRep.RelationId,
              "attribute",
              0
            );

            const RelDataNew = {
              id: eachRep.Id,
              userid: userid,
              relationtype: relationtype,
              relationid: relationid,
            };
            empRl.push(RelDataNew);
          });
          setUserRelationMap(empRl);
          setShowEmpRlMap(true);
        }
      })();
      if (rlMapTypeVal == 1 || rlMapTypeVal == 3 || rlMapTypeVal == 4) {
        setRelationDisable(true);
      } else if (rlMapTypeVal == 2) {
        setRelationDisable(false);
      }
    }
  }, [rlMapTypeVal, empRlMap]);

  useEffect(() => {
    (async () => {
      await showLoader();
      if (
        submitFunc == true &&
        getData.length > 0 &&
        attributesData["REQSECT"]?.length == getData?.length &&
        attributesData["REQTYPOUPD"].code == "DataChange" &&
        attributesData["REQRSNOUP"].code == "DTACHG"
      ) {
        //
        let viceVarsaParsedOldJson = parseJsonViseVersa(oldProgressionJson);

        let comparedAndAddedKeysJson = compareAndAddKeys(
          viceVarsaParsedOldJson,
          sendBackJson
        );

        let parsedOldJson: any = jsonParse(comparedAndAddedKeysJson);

        let newJsonArr: any = jsonParse(sendBackJson);

        newJsonArr = JSON.stringify(newJsonArr);
        parsedOldJson = JSON.stringify(parsedOldJson);
        const SaveData = await APICall(updateProgressionOnSave, "POST", {
          Id: workflowData.ProgressionId,
          ProgressionRemarks: attributesData["REQDREMK"],
          EffectiveDate: attributesData["REQEFCTVDT"],
          RequestNewJson: newJsonArr,
          AuditLogOldJson: parsedOldJson,
          AuditLogNewJson: newJsonArr,
          CreatedBy: userDetails?.Id,
          UserName: FullName,
          UserEmail: userDetails?.EmailId,
          IsDataChange: true,
          EmployeeId: parseInt(attributesData["REQEMPLID"].value),
        });

        //Entry in Action History
        const actHistObj = await APICall(entryUpdateInActionHistory, "POST", {
          Status: 'Resubmit After Sendback',
          Remarks: attributesData["REQDREMK"],
          ProgId: workflowData.ProgressionId,
          CreatedBy: userDetails.Id,
        });
        //--

        let obj = {
          CreatedBy: attributesData["WRKFLINID"],
          CreatedByRoleId: currentRoleId?.value,
          WorkflowTaskId: workflowTaskId,
          DecisionId: 1,
          IsAcknowledged: true,
          EmployeeId: parseInt(attributesData["REQEMPLID"].value),
          WorkflowTasksRemarks: attributesData["REQDREMK"],
          ProgressionId: workflowData.ProgressionId,
          InitiatorId: attributesData["WRKFLINID"],
          ProxyCreatedBy: proxyUserDetails==null ? null : userDetails.UserId,
        };
        console.log('Final Object::::',obj);

        const TaskCreated = await APICall(handleApprovalForProgressionModel, "POST", obj);

        // const TaskCreated = await APICall(createTaskForApprovers, "POST", {
        //   WorkflowInstanceId: workflowData.WorkflowInstanceId,
        //   RequestId: workflowData.RequestId,
        //   InitiatorId: attributesData["WRKFLINID"],
        //   ProgressionId: workflowData.ProgressionId,
        //   NewJsonReq: newJsonArr,
        // });

        if (documents.length > 0) {
          await showLoader();
          //upload new documents in sharepoint
          let originalDocs = documents.map((doc, index) => ({
            ...doc,
            tempId: index,
          }));
          let newDocs = originalDocs.filter(
            (doc) =>
              doc.IsActive === true &&
              doc.IsNew === true &&
              doc.IsValidFile === false
          );

          const docsRes = await APICall(
            uploadSectionDocForProgression,
            "POST",
            {
              TId: parseInt(attributesData["REQEMPLID"].value),
              progressionFiles: newDocs,
            }
          );

          //loop to change Data in existing currentDocs

          if (docsRes.data !== null && docsRes.message === "Success") {
            let docRes = docsRes.data;
            newDocs = newDocs.map((doc, index) => {
              let docFromApi = docRes[index];
              return { ...doc, Data: docFromApi.Data };
            });

            originalDocs = originalDocs.map((doc) => {
              const updatedDoc = newDocs.find(
                (updated) => updated.tempId === doc.tempId
              );
              if (updatedDoc !== undefined) {
                return { ...updatedDoc };
              } else {
                return doc;
              }
            });

            const propertyToRemove = "tempId";

            originalDocs = originalDocs.map((item) => {
              const { [propertyToRemove]: _, ...rest } = item;
              return rest;
            });

            const sectionDocsRes = await APICall(
              updateSectionDocInProgression,
              "POST",
              {
                Id: workflowData.ProgressionId,
                SupportingDoc: JSON.stringify(originalDocs),
              }
            );
          } else {
            await hideLoader();
            notify(1, "Something went wrong. Section in documents!");
          }
        }
        await hideLoader();

        setBtnDisable(false);
        if (TaskCreated?.status == 0) {
          notify(0, "Task Created Successfully.");
          navigate("/home");
        } else {
          notify(1, TaskCreated?.message);
        }
      } else if (
        submitFunc == true &&
        getData.length > 0 &&
        (attributesData["REQTYPOUPD"].code == "Compensation" ||
          attributesData["REQTYPOUPD"].code == "Suspension" ||
          attributesData["REQTYPOUPD"].code == "Demotion")
      ) {
        //

        let viceVarsaParsedOldJson = parseJsonViseVersa(oldProgressionJson);

        let comparedAndAddedKeysJson = compareAndAddKeys(
          viceVarsaParsedOldJson,
          sendBackJson
        );

        let parsedOldJson: any = jsonParse(comparedAndAddedKeysJson);

        let newJsonArr: any = jsonParse(sendBackJson);

        parsedOldJson = JSON.stringify(parsedOldJson);

        // set extra attributes when demotion on sendback

        if (attributesData["REQTYPOUPD"].code == "Demotion") {
          if (dynamicAccordians.length > 0) {
            dynamicAccordians.map((eachAttr) => {
              newJsonArr = newJsonArr.map((newJson) => {
                if (
                  eachAttr.attributeCode == newJson.Code &&
                  eachAttr.attributeCode != "SA_CompensationSet"
                ) {
                  return {
                    ...newJson,
                    Attribute: [
                      {
                        [eachAttr.attributeCode]:
                          eachAttr.valueOnDd == null ||
                          eachAttr.valueOnDd == undefined
                            ? ""
                            : eachAttr.valueOnDd.label,
                      },
                    ],
                    AttributeValue: [
                      {
                        [eachAttr.attributeCode]:
                          eachAttr.valueOnDd == null ||
                          eachAttr.valueOnDd == undefined
                            ? ""
                            : eachAttr.valueOnDd.value,
                      },
                    ],
                    Main: [
                      {
                        [eachAttr.attributeCode]:
                          eachAttr.valueOnDd == null ||
                          eachAttr.valueOnDd == undefined
                            ? ""
                            : {
                                label: eachAttr.valueOnDd.label,
                                value: eachAttr.valueOnDd.value,
                              },
                      },
                    ],
                  };
                } else {
                  return newJson;
                }
              });
            });
          }
        }

        newJsonArr = JSON.stringify(newJsonArr);

        const SaveData = await APICall(updateProgressionOnSave, "POST", {
          Id: workflowData.ProgressionId,
          ProgressionRemarks: attributesData["REQDREMK"],
          EffectiveDate: attributesData["REQEFCTVDT"],
          RequestNewJson: newJsonArr,
          AuditLogOldJson: parsedOldJson,
          AuditLogNewJson: newJsonArr,
          CreatedBy: userDetails?.Id,
          UserName: FullName,
          UserEmail: userDetails?.EmailId,
          IsDataChange: true,
          EmployeeId: parseInt(attributesData["REQEMPLID"].value),
        });

        //Entry in Action History
        const actHistObj = await APICall(entryUpdateInActionHistory, "POST", {
          Status: 'Resubmit After Sendback',
          Remarks: attributesData["REQDREMK"],
          ProgId: workflowData.ProgressionId,
          CreatedBy: userDetails.Id,
        });
        //--
        
        let obj = {
          CreatedBy: attributesData["WRKFLINID"],
          CreatedByRoleId: currentRoleId?.value,
          WorkflowTaskId: workflowTaskId,
          DecisionId: 1,
          IsAcknowledged: true,
          EmployeeId: parseInt(attributesData["REQEMPLID"].value),
          WorkflowTasksRemarks: attributesData["REQDREMK"],
          ProgressionId: workflowData.ProgressionId,
          InitiatorId: attributesData["WRKFLINID"],
          ProxyCreatedBy: proxyUserDetails==null ? null : userDetails.UserId,
        };
        console.log('Final Object::::',obj);

        const TaskCreated = await APICall(handleApprovalForProgressionModel, "POST", obj);

        // const TaskCreated = await APICall(createTaskForApprovers, "POST", {
        //   WorkflowInstanceId: workflowData.WorkflowInstanceId,
        //   RequestId: workflowData.RequestId,
        //   InitiatorId: attributesData["WRKFLINID"],
        //   ProgressionId: workflowData.ProgressionId,
        //   NewJsonReq: newJsonArr,
        // });

        // if (TaskCreated != null && TaskCreated.data != null) {
        //   if (TaskCreated.data.Created != 0) {
        //     notify(0, "Record Saved Successfully.");
        //     navigate("/home");
        //   } else {
        //     notify(1, "Task Creation Failed");
        //   }
        // }
        setBtnDisable(false);
        await hideLoader();
        if (TaskCreated?.status == 0) {
          notify(0, "Task Created Successfully.");
          navigate("/home");
        } else {
          notify(1, TaskCreated?.message);
        }
      }
      // setIsSave(false);
      await hideLoader();
      setBtnDisable(false);
      setSubmitFunc(false);
    })();
  }, [submitFunc, getData]);

  const documentUploadOnSubmit = async() => {
    let jsonDoc='';
    let docIdExist=[];

    if (linksForDeletedFiles.length > 0) {
      const obj = {
        TId: parseInt(attributesData["REQEMPLID"].value),
        files: linksForDeletedFiles.map((doc) => {
          return { ...doc, Id: progressionId };
        }),
      };
      const docRes = await APICall(
        deleteSupportingDocForProgression,
        "POST",
        obj
      );
      if(docRes.message == 'Success'){
        notify(0, 'Old Documents Deleted!');
      } else {
        notify(1, docRes.message);
      }
    }
    
    if (selectedFilesForFlat.length > 0) {
      docIdExist = selectedFilesForFlat.filter((doc)=> doc.DocId != 0);
      const docIdNotExist = selectedFilesForFlat.filter((doc)=> doc.DocId == 0);

      const obj = {
        TId: parseInt(attributesData["REQEMPLID"].value),
        files: docIdNotExist.map((doc) => {
          return { ...doc, Id: progressionId };
        }),
      };

      console.log('submit object for files::::', obj);
      const docRes = await APICall(
        uploadSupportingDocForProgressionOnSendback,
        "POST",
        obj
      );
      
      if(docRes.message == 'Success'){
        notify(0, 'New Files Successfully Uploaded!');
      } else {
        notify(1, 'Failed to Upload New Files!');
      }
      docIdExist = docIdExist.concat(docRes.data);
      console.log('ID EXIST Var::::', docIdExist);
    } 
    if (docIdExist.length>0){
      jsonDoc = JSON.stringify(docIdExist);
    }
    console.log('Json Doc::::', jsonDoc);
    //api to insert new files in Table;
    const updObj={
      Id: progressionId,
      SupportingDoc: jsonDoc,
    }
    const updateInAttachment = await APICall(
      updateAttachmentDetailsInTable,
      "POST",
      updObj
    );
    if (updateInAttachment.message=='Success'){
      notify(0, 'Updloaded Document has been updated in Attachment of Progression table in Database!');
      // navigate("/home");
    } else {
      notify(1, 'Could not update in Attachment of Progression table in Database!');
    }
  }

  function compareAndAddKeys(OldJson, NewJson) {
    //Loop over Old Json
    for (const sectionCode in OldJson) {
      //per section data
      let sectionOldJson = OldJson[sectionCode];

      let sectionNewJson = NewJson[sectionCode];

      //Loop over new section json for particular section id
      for (const key in sectionNewJson) {
        // if flat and Property exists in
        // new json but not in old json
        //then add in old json with empty string
        if (!sectionOldJson.hasOwnProperty(key)) {
          sectionOldJson[key] = ""; //
        } else if (Array.isArray(sectionNewJson[key])) {
          // if SEt
          //get new  records in new Json
          let newAddedRecords = sectionNewJson[key].filter(
            (row) => !sectionOldJson[key].some((oldRow) => oldRow.Id == row.Id)
          );

          newAddedRecords = newAddedRecords.map((row) => {
            if (row && Object.keys(row).length > 0) {
              let newRow = {};
              for (const column in row) {
                if (column != "Id" && column != "IsActive" && column != "EID") {
                  newRow[column] = "";
                } else {
                  newRow[column] = row[column];
                }
              }
              return newRow;
            }
          });

          // add both old records and new records in json
          let newOldJson = [...newAddedRecords, ...sectionOldJson[key]];

          OldJson[sectionCode][key] = newOldJson;

          let x = 0;
        }
      }
    }

    return OldJson;
  }

  function jsonParse(inputData) {
    const result = [];
    Object.keys(inputData).forEach((sectionId) => {
      const sectionData = inputData[sectionId];
      const sectionIdInt = parseInt(sectionId);

      // Create an array of objects for each key-value pair
      Object.keys(sectionData).forEach((key) => {
        const keyValueObject = {
          sectionId: sectionIdInt,
        };
        const value = sectionData[key];

        if (Array.isArray(value)) {
          value.map((i) => {
            let object = {};
            object["IsAttributeSet"] = true;
            object["Code"] = key;
            object["RecordId"] = i?.Id;
            object["sectionId"] = sectionIdInt;

            let newObj = {};
            let newObj2 = {};
            let newObj3 = {};

            Object.keys(i).map((key) => {
              newObj["Id"] = i?.Id;
              newObj[key] = i[key]?.label || i[key];
              object["Attribute"] = [newObj];

              newObj2["Id"] = i?.Id;
              newObj2[key] = i[key]?.value || i[key];
              object["AttributeValue"] = [newObj2];

              newObj2["Id"] = i?.Id;
              newObj3[key] = i[key];
              object["Main"] = [newObj3];
            });
            result.push(object);
          });
        } else {
          keyValueObject["IsAttributeSet"] = false;
          keyValueObject["Code"] = key;
          let Attribute = [];
          Attribute.push({ [key]: value?.label || value });

          let AttributeValue = [];
          AttributeValue.push({ [key]: value?.value || value });

          keyValueObject["Attribute"] = Attribute;
          keyValueObject["AttributeValue"] = AttributeValue;
          keyValueObject["Main"] = [{ [key]: value }];
          result.push(keyValueObject);
        }
      });
    });

    return result;
  }

  const GetNPEndDate = (date) => {
    if (dynamicAccordians.length > 0) {
      let NPSD = date;
      let NPIM = dynamicAccordians.filter((dc) => {
        return dc.attributeCode === "NPIM";
      })[0].valueOnDd;

      if (NPSD != undefined && NPSD != null && NPSD != "") {
        if (NPIM != undefined && NPIM != null && NPIM != "") {
          const startDateNP = moment(NPSD);
          let NPDays = Number(NPIM) * 30.44;
          let EndDateNP = startDateNP.add(NPDays, "d");
          GetShortDays("NPEDE", EndDateNP);
          return EndDateNP;
        } else {
          return "";
        }
      } else {
        return "";
      }
    }
  };
  const GetShortDays = (attribute, date) => {
    if (dynamicAccordians.length > 0) {
      let NPEDE = "";
      let LWD = "";

      NPEDE = dynamicAccordians.filter((dc) => {
        return dc.attributeCode === "NPEDE";
      })[0].valueOnDd;

      LWD = dynamicAccordians.filter((dc) => {
        return dc.attributeCode === "LWD";
      })[0].valueOnDd;
      //}
      if (
        LWD != undefined &&
        LWD != null &&
        NPEDE != undefined &&
        NPEDE != null
      ) {
        let daysDiff;
        if (moment(LWD) <= moment(NPEDE)) {
          daysDiff = moment(NPEDE).diff(moment(LWD), "days");
        } else {
          daysDiff = 0;
        }
        // let tempAcc = [...dynamicAccordians];
        //  let tempAtt = tempAcc.find(
        //                         (item) =>
        //                           item.attributeCode ==
        //                           "NPSDE"
        //                       );
        //                       tempAtt.valueOnDd = daysDiff;
        //                       setDynamicAccordian(tempAcc);
        return daysDiff > 0 ? daysDiff - 1 : daysDiff;
      } else {
        return "";
      }
    }
  };

  useEffect(() => {
    if (
      attributesData["REQTYPOUPD"] != null &&
      attributesData["REQRSNOUP"] != null
    ) {
      if (
        attributesData["REQTYPOUPD"].code == "DataChange" &&
        attributesData["REQRSNOUP"].code == "DTACHG"
      ) {
        setSectionTabHide(false);
      }
    } else {
      setSectionTabHide(true);
    }
  }, [attributesData["REQTYPOUPD"], attributesData["REQRSNOUP"]]);

  //section api here
  useEffect(() => {
    // Extract attributes for better readability
    const { REQTABS } = attributesData;

    // Check if both attributes are available
    if (TId && REQTABS != null) {
      // Initialize values array
      setSectionValuesArray([]);
      // Fetch section data
      (async () => {
        await showLoader();
        try {
          const section = await APICall(getSectionDetailsV2, "POST", {
            MN: "EmployeeCentral",
            IN: "EC_Form",
            TN: REQTABS.code,
            UID: userDetails.Id,
            TID: TId,
            RoleId: currentRoleId?.value,
            COU: userDetails.COU,
            IsNewHire: true,
          });

          if (
            section.data !== null &&
            section.data.t !== undefined &&
            section.data.t.tn === REQTABS.code
          ) {
            setFormData(section.data.t);
          } else {
            setFormData({});
          }
        } finally {
          hideLoader();
        }
      })();

      // Fetch section values array
      (async () => {
        await showLoader();
        try {
          const bodyObj = {
            mn: "EmployeeCentral",
            in: "EC_Form",
            tn: REQTABS.code,
            SN: "Basic",
            TID: TId,
            ISH: "true",
            UserId: initiatorUserId,
            RoleId: initiatorRoleId,
            COU: userDetails.COU,
          };

          const response = await APICall(
            getAttributeValueDetailsV2,
            "POST",
            bodyObj
          );

          if (response.data.sections && response.data.sections.length > 0) {
            setSectionValuesArray(response.data.sections);
          } else {
            setSectionValuesArray([]);
          }
        } finally {
          hideLoader();
        }
      })();
    }

    // Fetch global values if REQEMPLID is available
    if (TId != null) {
      (async () => {
        await showLoader();
        try {
          const globalValues = await APICall(getGlobalValues, "POST", {
            TID: TId,
          });

          if (globalValues.data !== null && globalValues.data.length > 0) {
            setGlobalValues(globalValues.data);
          }
        } finally {
          hideLoader();
        }
      })();
    }
  }, [TId, attributesData["REQTABS"]]);

  useEffect(() => {
    if (allSectionData) {
      updateSectionData(allSectionData, true);
    }
  }, [allSectionData]);

  useEffect(() => {
    if (
      attributesData["REQEMPLID"] != null &&
      attributesData["REQTABS"] != null &&
      attributesData["REQSECT"] != null &&
      formData != null &&
      Object.keys(formData).length > 0 &&
      TId != 0 &&
      Object.keys(sendBackJson).length > 0
    ) {
      (async () => {
        await showLoader();
        //section.data.t.S is array jisme filter krna hai

        let setFinalSections = [];
        let sectArr = [];
        attributesData["REQSECT"]?.map((eachSect) => {
          sectArr.push(eachSect.value);
        });
        console.log(sectArr);
        sectArr.map((eachId) => {
          const settingVal = formData?.S?.find((item) => item?.SId == eachId);
          setFinalSections.push(settingVal);
        });
        console.log(setFinalSections);
        if (finalformData != null || finalformData != undefined) {
          setFinalFormData(setFinalSections);
        }
        await hideLoader();
      })();
    }
  }, [formData, attributesData["REQSECT"]]);

  const fileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleDownloadFileFlat = async (fileAttribute, fileIndex) => {
    showLoader();
    if (
      fileAttribute.DocId != undefined &&
      fileAttribute.DocId != null &&
      fileAttribute.DocId != 0
    ) {
      const doc = await APICall(downloadSupportingDocForProgression, "POST", {
        AC: fileAttribute.Data,
      });
      if (doc && doc.data !== null) {
        downloadBase64File(doc.data, fileAttribute.Name);
        hideLoader();
      } else {
        notify(1, "Something Went Wrong.");
        hideLoader();
      }
    } else {
      downloadBase64File(fileAttribute.Data, fileAttribute.Name);
      hideLoader();
    }
  };
  function downloadBase64File(base64String: string, fileName: string) {
    const mimeType = inferMimeType(base64String);
    const blob = base64toBlob(base64String, mimeType);

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  }
  function inferMimeType(base64String: string): string {
    const dataUrl = `data:application/octet-stream;base64,${base64String}`;
    const typeInfo = /^data:(.*?);/.exec(dataUrl);

    if (typeInfo && typeInfo[1]) {
      return typeInfo[1];
    }

    return "application/octet-stream";
  }
  function base64toBlob(base64String: string, mimeType: string): Blob {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], { type: mimeType });
  }

  const [selectedFilesForFlat, setSelectedFilesForFlat] = useState<
    {
      AC: string;
      Name: string;
      Data: string;
      IsValidFile: boolean;
      DocId: Number;
    }[]
  >([]);

  const handleFileChangeForFlat = async (e, attribute) => {
    const { FS, FX, FC } = JSON.parse(attribute.V);

    const files = Array.prototype.slice.call(e.target.files);

    const isValid = true;
    if (isValid) {
      if (files && files.length > 0) {
        const base64Array: { Name: string; Data: string }[] = [];
        for (let i = 0; i < files.length; i++) {
          const base64 = (await fileToBase64(files[i])) as string;
          const base64WithoutPrefix = base64.split(",")[1];
          base64Array.push({
            Name: files[i].name,
            Data: base64WithoutPrefix,
          });
        }

        //check if AC present and file count matched

        const fileCount = selectedFilesForFlat.reduce((total, item) => {
          if (item.AC === attribute.AC) {
            return total + 1;
          }
          return total;
        }, 0);

        if (fileCount == FC) {
          notify(1, `Only ${FC} files allowed!`);
        } else {
          base64Array.forEach((file) => {
            setSelectedFilesForFlat((prevSelectedFiles) => [
              ...prevSelectedFiles,
              {
                AC: attribute.AC,
                Name: file.Name,
                Data: file.Data,
                IsValidFile: false,
                DocId: 0,
              },
            ]);
          });
        }
      }
    } else {
      e.target.value = "";
    }
  };

  const handleSubmitButton = async (event) => {
    event.preventDefault();
    await showLoader();
    setBtnDisable(true);
    setFormErrors({});
    setDynamicformErrors({});

    if (
      (attributesData["REQTYPOUPD"].code == "DataChange" &&
        attributesData["REQRSNOUP"].code == "DTACHG") ||
      attributesData["REQTYPOUPD"].code == "Suspension" ||
      attributesData["REQTYPOUPD"].code == "Compensation" ||
      attributesData["REQTYPOUPD"].code == "Demotion"
    ) {
      await hideLoader();
      setSubmitFunc(true);
    } else {
      let isValidDynamic = true;
      if (dynamicAccordians.length > 0) {
        isValidDynamic = await checkDynamicAccValidation();
      }

      if (isValidDynamic) {
        let repMgMapJson = "";
        let empRlMapJson = "";
        if (
          attributesData["REQTYPOUPD"].code == "Separation" ||
          attributesData["REQTYPOUPD"].code == "Termination"
        ) {
          repMgMapJson = await repMgStringfy();

          if (directReportee != null && reporteeManagerMap.length > 0) {
            const SaveMg = await APICall(updateRepMgMapNewJson, "POST", {
              Id: progressionId,
              RequestId: directReportee.value,
              RequestNewJson: repMgMapJson,
              CreatedBy: attributesData["WRKFLINID"],
            });
          }

          empRlMapJson = await empRlStringfy();

          if (directReporteeRel != null && userRelationMap.length > 0) {
            const SaveRl = await APICall(updateEmpRlMapNewJson, "POST", {
              Id: progressionId,
              RequestId: directReporteeRel.value,
              RequestNewJson: empRlMapJson,
              CreatedBy: attributesData["WRKFLINID"],
            });
          }
        }

        let customName = originalNameForTransfer;
        if (attributesData["REQTYPOUPD"].code == "Transfer"){
          let arrForName = [];
          dynamicAccordians.map((eachAttr) => {
            arrForName.push(eachAttr.attributeCode);
          });
          let arrForNameNew = [];
          if(arrForName.length>0){
            arrForName.map((item)=>{
              if (codeCheckForTansfer.includes(item)){
                arrForNameNew.push(item);
              }
            });
          }
          const extendedName = arrForNameNew.join('_');
          customName = customName + '_' + extendedName;
        }
        
        const [stringifiedJson, stringifiedAuditJson] = await jsonStringify();
        const SaveData = await APICall(updateProgressionOnSave, "POST", {
          Id: workflowData.ProgressionId,
          ProgressionRemarks: attributesData["REQDREMK"],
          EffectiveDate: attributesData["REQEFCTVDT"],
          RequestNewJson: stringifiedJson,
          RequestNewAuditJson: stringifiedAuditJson,
          AuditLogNewJson: stringifiedAuditJson,
          CreatedBy: attributesData["WRKFLINID"],
          UserName: FullName,
          UserEmail: userDetails?.EmailId,
          ProxyCreatedBy: proxyUserDetails == null ? null : userDetails.UserId,
          EmployeeId: parseInt(attributesData["REQEMPLID"].value),
          ReasonNewLabel: customName ? customName : null,
        });

        //Entry in Action History
        const actHistObj = await APICall(entryUpdateInActionHistory, "POST", {
          Status: 'Resubmit After Sendback',
          Remarks: attributesData["REQDREMK"],
          ProgId: workflowData.ProgressionId,
          CreatedBy: userDetails.Id,
        });
        //--

        //function call to upload docs
        await documentUploadOnSubmit();

        let obj = {
          CreatedBy: attributesData["WRKFLINID"],
          CreatedByRoleId: currentRoleId?.value,
          WorkflowTaskId: workflowTaskId,
          DecisionId: 1,
          IsAcknowledged: true,
          EmployeeId: parseInt(attributesData["REQEMPLID"].value),
          WorkflowTasksRemarks: attributesData["REQDREMK"],
          ProgressionId: workflowData.ProgressionId,
          InitiatorId: attributesData["WRKFLINID"],
          ProxyCreatedBy: proxyUserDetails==null ? null : userDetails.UserId,
        };
        console.log('Final Object::::',obj);

        const TaskCreated = await APICall(handleApprovalForProgressionModel, "POST", obj);

        // const TaskCreated = await APICall(createTaskForApprovers, "POST", {
        //   WorkflowInstanceId: workflowData.WorkflowInstanceId,
        //   RequestId: workflowData.RequestId,
        //   InitiatorId: attributesData["WRKFLINID"],
        //   ProgressionId: workflowData.ProgressionId,
        //   NewJsonReq: newJsonArr,
        // });

        // if (TaskCreated != null && TaskCreated.data != null) {
        //   if (TaskCreated.data.Created != 0) {
        //     notify(0, "Record Saved Successfully.");
        //     navigate("/home");
        //   } else {
        //     notify(1, "Task Creation Failed");
        //   }
        // }
        setBtnDisable(false);
        await hideLoader();
        if (TaskCreated?.status == 0) {
          notify(0, "Task Created Successfully.");
          navigate("/home");
        } else {
          notify(1, TaskCreated?.message);
        }
      } else {
        console.log("NOT VALID");
        setBtnDisable(false);
        await hideLoader();
        notify(1, "Please Fill required Data.");
      }
    }
  };

  const jsonStringify = async () => {
    let newJsonObj = [];
    let newForAuditLog = [];
    Object.keys(dynamicAccordians).length > 0 &&
      dynamicAccordians.map((eachAttr) => {
        if (eachAttr.attributeDatatype == "DropdownSingle") {
          const tempNewObj = {
            [eachAttr.attributeCode]:
              eachAttr.valueOnDd == undefined || eachAttr.valueOnDd == null
                ? ""
                : parseInt(eachAttr.valueOnDd.value),
          };
          newJsonObj.push(tempNewObj);
          const tempNewAuditObj = {
            sectionId: eachAttr.attributeSectionId,
            IsAttributeSet: false,
            Code: eachAttr.attributeCode,
            Attribute: [
              {
                [eachAttr.attributeCode]:
                  eachAttr.valueOnDd == undefined || eachAttr.valueOnDd == null
                    ? ""
                    : eachAttr.valueOnDd.label,
              },
            ],
          };
          newForAuditLog.push(tempNewAuditObj);
        }
        if (eachAttr.attributeDatatype == "Text") {
          const tempNewObj = {
            [eachAttr.attributeCode]:
              eachAttr.valueOnDd == undefined || eachAttr.valueOnDd == null
                ? ""
                : eachAttr.valueOnDd,
          };
          newJsonObj.push(tempNewObj);
          const tempNewAuditObj = {
            sectionId: eachAttr.attributeSectionId,
            IsAttributeSet: false,
            Code: eachAttr.attributeCode,
            Attribute: [
              {
                [eachAttr.attributeCode]:
                  eachAttr.valueOnDd == undefined || eachAttr.valueOnDd == null
                    ? ""
                    : eachAttr.valueOnDd,
              },
            ],
          };
          newForAuditLog.push(tempNewAuditObj);
        }
        if (eachAttr.attributeDatatype == "Number") {
          const tempNewObj = {
            [eachAttr.attributeCode]:
              eachAttr.valueOnDd == undefined || eachAttr.valueOnDd == null
                ? ""
                : eachAttr.attributeCode === "LOPHR"
                ? eachAttr.valueOnDd
                : parseInt(eachAttr.valueOnDd),
          };
          newJsonObj.push(tempNewObj);
          const tempNewAuditObj = {
            sectionId: eachAttr.attributeSectionId,
            IsAttributeSet: false,
            Code: eachAttr.attributeCode,
            Attribute: [
              {
                [eachAttr.attributeCode]:
                  eachAttr.valueOnDd == undefined || eachAttr.valueOnDd == null
                    ? ""
                    : eachAttr.attributeCode === "LOPHR"
                    ? eachAttr.valueOnDd
                    : parseInt(eachAttr.valueOnDd),
              },
            ],
          };
          newForAuditLog.push(tempNewAuditObj);
        }
        if (eachAttr.attributeDatatype == "Date") {
          const parsedDateOld = moment(eachAttr.valueOnDd, "DD-MMM-YYYY");
          const formattedDateOld = parsedDateOld.format("YYYY-MM-DD");
          const tempNewObj = {
            [eachAttr.attributeCode]:
              eachAttr.valueOnDd == undefined ||
              eachAttr.valueOnDd == null ||
              formattedDateOld == "Invalid date"
                ? ""
                : formattedDateOld,
          };
          newJsonObj.push(tempNewObj);
          const tempNewAuditObj = {
            sectionId: eachAttr.attributeSectionId,
            IsAttributeSet: false,
            Code: eachAttr.attributeCode,
            Attribute: [
              {
                [eachAttr.attributeCode]:
                  eachAttr.valueOnDd == undefined ||
                  eachAttr.valueOnDd == null ||
                  formattedDateOld == "Invalid date"
                    ? ""
                    : formattedDateOld,
              },
            ],
          };
          newForAuditLog.push(tempNewAuditObj);
        }
        if (eachAttr.attributeDatatype == "SearchableDropdownSingle") {
          const tempNewObj = {
            [eachAttr.attributeCode]:
              eachAttr.valueOnDd == undefined || eachAttr.valueOnDd == null
                ? ""
                : parseInt(eachAttr.valueOnDd.value),
          };
          newJsonObj.push(tempNewObj);
          const tempNewAuditObj = {
            sectionId: eachAttr.attributeSectionId,
            IsAttributeSet: false,
            Code: eachAttr.attributeCode,
            Attribute: [
              {
                [eachAttr.attributeCode]:
                  eachAttr.valueOnDd == undefined || eachAttr.valueOnDd == null
                    ? ""
                    : eachAttr.valueOnDd.label,
              },
            ],
          };
          newForAuditLog.push(tempNewAuditObj);
        }
      });
    const newJson = JSON.stringify(newJsonObj);
    const newAuditJson = JSON.stringify(newForAuditLog);
    // console.log("New Json", newJsonObj);
    // console.log("Stringified: ", newJson);
    // console.log("New Json", newForAuditLog);
    // console.log("Stringified: ", newAuditJson);
    return [newJson, newAuditJson];
  };

  const repMgStringfy = async () => {
    let repMgMap = [];
    if (
      directReportee != null &&
      (directReportee.code == "TATUP" || directReportee.code == "TATOM")
    ) {
      if (reporteeManagerMap != null && reporteeManagerMap.length > 0) {
        reporteeManagerMap.map((eachRep) => {
          const tempObj = {
            EmpId: eachRep.value,
            ManagerId: managerForReportee.value,
          };
          repMgMap.push(tempObj);
        });
      }
    }
    if (directReportee != null && directReportee.code == "ITTOM") {
      if (reporteeManagerMap != null && reporteeManagerMap.length > 0) {
        reporteeManagerMap.map((eachRep) => {
          const tempObj = {
            EmpId: eachRep.value,
            ManagerId: eachRep.mg.value,
          };
          repMgMap.push(tempObj);
        });
      }
    }
    return JSON.stringify(repMgMap);
  };

  const empRlStringfy = async () => {
    // relationship map
    let userRlMap = [];
    if (
      directReporteeRel != null &&
      (directReporteeRel.code == "RLTATUP" ||
        directReporteeRel.code == "RLTATOM")
    ) {
      if (userRelationMap != null && userRelationMap.length > 0) {
        userRelationMap.map((eachRep) => {
          const tempObj = {
            Id: eachRep.id,
            RelationId: relationForReportee.value,
          };
          userRlMap.push(tempObj);
        });
      }
    }
    if (directReporteeRel != null && directReporteeRel.code == "RLITTOM") {
      if (userRelationMap != null && userRelationMap.length > 0) {
        userRelationMap.map((eachRep) => {
          const tempObj = {
            Id: eachRep.id,
            RelationId: eachRep.relationid.value,
          };
          userRlMap.push(tempObj);
        });
      }
    }
    if (directReporteeRel != null && directReporteeRel.code == "RLTAR") {
      if (userRelationMap != null && userRelationMap.length > 0) {
        userRelationMap.map((eachRep) => {
          const tempObj = {
            Id: eachRep.id,
            RelationId: 0,
          };
          userRlMap.push(tempObj);
        });
      }
    }
    return JSON.stringify(userRlMap);
  };

  ///////////////////////////////////////////////////////////////////////////

  const checkDynamicAccValidation = async () => {
    let formCount = 0;
    dynamicAccordians.map((eachAttr) => {
      if (eachAttr.attributeDatatype == "DropdownSingle") {
        if (
          eachAttr.attributeCode != "OTR" &&
          eachAttr.attributeCode != "WOA" &&
          eachAttr.attributeCode != "WOAR" &&
          eachAttr.attributeCode != "SC"
        ) {
          if (eachAttr.valueOnDd == null || eachAttr.valueOnDd == undefined) {
            formCount += 1;
            setDynamicformErrors((prev) => ({
              ...prev,
              [eachAttr.attributeCode]: "Mandatory",
            }));
          }
        }
      } else if (
        eachAttr.attributeDatatype == "Text" ||
        eachAttr.attributeDatatype == "Number"
      ) {
        if (
          eachAttr.attributeCode != "OTR" &&
          eachAttr.attributeCode != "WOA" &&
          eachAttr.attributeCode != "WOAR"
        ) {
          if (eachAttr.valueOnDd == null || eachAttr.valueOnDd == undefined) {
            formCount += 1;
            setDynamicformErrors((prev) => ({
              ...prev,
              [eachAttr.attributeCode]: "Mandatory",
            }));
          }
        }
      } else if (eachAttr.attributeDatatype == "Date") {
        if (
          eachAttr.valueOnDd == null ||
          eachAttr.valueOnDd == undefined ||
          eachAttr.valueOnDd == ""
        ) {
          formCount += 1;
          setDynamicformErrors((prev) => ({
            ...prev,
            [eachAttr.attributeCode]: "Mandatory",
          }));
        }
      } else if (eachAttr.attributeDatatype == "SearchableDropdownSingle") {
        if (
          eachAttr.valueOnDd == null ||
          eachAttr.valueOnDd == undefined ||
          eachAttr.valueOnDd == ""
        ) {
          formCount += 1;
          setDynamicformErrors((prev) => ({
            ...prev,
            [eachAttr.attributeCode]: "Mandatory",
          }));
        }
      }
    });
    //console.log('Validation stage: ', dynamicAccordians, dynamicformErrors);
    return formCount != 0 ? false : true;
  };

  useEffect(() => {
    if (!ogoptsetonce) {
      if (Object.keys(dynamicAccordians).length > 0) {
        dynamicAccordians.map(async (eachAttr) => {
          if (eachAttr.attributeType === "Attribute") {
            if (eachAttr.attributeDatatype == "DropdownSingle") {
              setOgOptForCascade((prev) => ({
                ...prev,
                [eachAttr.attributeCode]: eachAttr.options,
              }));
            }
          }
        });
        setogoptsetonce(true);
      }
    }
  }, [dynamicAccordians]);

  // USA - LR - ETF-LR -- GET LOP
  const [fetchLOP, setFetchLOP] = useState(false);
  useEffect(() => {
    if (
      attributesData["REQCNTRY"] &&
      attributesData["REQCNTRY"].code === "CODECNTRY7" &&
      attributesData["REQPLOCN"] &&
      attributesData["REQPLOCN"].code === "LR" &&
      attributesData["REQTYPOUPD"] &&
      attributesData["REQTYPOUPD"].code === "ETF_LR" &&
      attributesData["REQRSNOUP"] &&
      attributesData["REQRSNOUP"].code === "PRC_HRC" &&
      attributesData["REQEMPLID"] &&
      dynamicAccordians.length > 0 &&
      fetchLOP
    ) {
      const EC =
        dynamicAccordians.find((attri) => attri.attributeCode === "EC")
          ?.valueOnDd?.value || null;

      const JL =
        dynamicAccordians.find((attri) => attri.attributeCode === "JL")
          ?.valueOnDd?.value || null;

      if (EC && JL) {
        (async () => {
          const obj = {
            EmployeeId: attributesData["REQEMPLID"].value,
            EC,
            JL,
          };

          const lopRes = await APICall(getLOPForLRProgression, "POST", obj);
          if (lopRes.data !== null && lopRes.status == 0) {
            let tempAcc = [...dynamicAccordians];
            let tempAtt = tempAcc?.find(
              (item) => item.attributeCode == "LOPHR"
            );
            tempAtt.valueOnDd = lopRes.data.lop;
            setDynamicAccordian(tempAcc);
          } else {
            let tempAcc = [...dynamicAccordians];
            let tempAtt = tempAcc?.find(
              (item) => item.attributeCode == "LOPHR"
            );
            tempAtt.valueOnDd = "0";
            setDynamicAccordian(tempAcc);
          }
        })();
        setFetchLOP(() => false);
      }
    }
  }, [dynamicAccordians, fetchLOP]);

  const supportingDocRef = useRef(null);

  const handleSupportingDoc = async (e, AC) => {
    const [FS, FX, FC] = [
      "5",
      ".docx,.doc,.pdf,.png,.jpg,.jpeg,.txt,.ppt,.pptx,.xlsx",
      2,
    ];

    const files = Array.prototype.slice.call(e.target.files);

    const isValid = handleFileValidation(files);
    if (isValid) {
      if (files && files.length > 0) {
        const base64Array: { Name: string; Data: string }[] = [];
        for (let i = 0; i < files.length; i++) {
          const base64 = (await fileToBase64(files[i])) as string;
          const base64WithoutPrefix = base64.split(",")[1];
          base64Array.push({
            Name: files[i].name,
            Data: base64WithoutPrefix,
          });
        }

        //check if AC present and file count matched

        const fileCount = selectedFilesForFlat.reduce((total, item) => {
          if (item.AC === AC) {
            return total + 1;
          }
          return total;
        }, 0);

        if (fileCount == FC) {
          notify(1, `Only ${FC} files allowed!`);
        } else {
          base64Array.forEach((file) => {
            //API To Convert Base64 to link
            console.log(file);
            setSelectedFilesForFlat((prevSelectedFiles) => [
              ...prevSelectedFiles,
              {
                AC: AC,
                Name: file.Name,
                Data: file.Data,
                IsValidFile: false,
                DocId: 0,
              },
            ]);
          });
        }
      }
    } else {
      e.target.value = null;
    }
  };

  const handleFileValidation = (files) => {
    const [FS, FX, FC] = [
      5,
      ".docx,.doc,.pdf,.png,.jpg,.jpeg,.txt,.ppt,.pptx,.xlsx",
      2,
    ];

    // Split the validExtensionsString into an array
    const validExtensions = FX.split(",");

    let isValid = [];

    // validate file count
    if (files.length > FC) {
      notify(1, `Only ${FC} files allowed!`);
      return false;
    }
    // check extension of each file
    files.forEach((file) => {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();

      // Check if the file extension is allowed
      if (!validExtensions.includes("." + fileExtension)) {
        isValid.push(false);
      } else {
        isValid.push(true); //valid
      }
    });

    let returnValue = isValid.some((v) => v === false);

    if (returnValue) {
      notify(1, `Please upload file within this ${FX} extentsion`);
      return false;
    }

    files.forEach((file) => {
      const fileSize = file.size;
      const maxFileSizeBytes = FS * 1024 * 1024;
      if (fileSize <= maxFileSizeBytes) {
        isValid.push(true); //valid
      } else {
        isValid.push(false);
      }
    });

    returnValue = isValid.some((v) => v === false);

    if (returnValue) {
      notify(1, `File size exceeds ${FS}MB. Please select a smaller file.`);
      return false;
    } else {
      return true;
    }
  };

  const handleDeleteFileForSuppDoc = async(fileAttribute, fileIndex)=>{
    if (
      fileAttribute.DocId != undefined &&
      fileAttribute.DocId != null &&
      fileAttribute.DocId != 0
    ) {
      let tempLinks = [...linksForDeletedFiles];
      const obj = {
        TId: parseInt(attributesData["REQEMPLID"].value), //EmpID
        AC: fileAttribute.AC,
        Data: fileAttribute.Data,
        UserId: proxyUserDetails?.EmployeeId
          ? proxyUserDetails?.EmployeeId
          : userDetails.UserId,
      }
      tempLinks.push(obj);
      setLinksForDeletedFiles(tempLinks);
    }
    setSelectedFilesForFlat((prevSelectedFiles) => {
      return prevSelectedFiles.filter(
        (item, attIndex) => fileIndex !== attIndex
      );
    });
  }

  return (
    <>
      <div className="mb-4 clearfix">
        <button
          onClick={(event) => handleSubmitButton(event)}
          disabled={btnDisable}
          className="btn btn-filter-submit float-right ml-2">
          <i className="fa-solid fa-check"></i> Submit
        </button>
      </div>
      <div className="col-lg-12">
        <Accordion
          elevation={0}
          className="mb-3"
          expanded={accordionReqDet}
          onChange={() => setAccordionReqDet((prev) => !prev)}>
          <AccordionSummary
            id="profile-accordion"
            style={{ background: "#3C5464" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}>
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <p style={{ fontSize: "16px" }}>Requisition Details</p>
              </div>
              <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
            </div>
          </AccordionSummary>

          <AccordionDetails className="page_heading">
            <div className="row">
              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Effective From Date
                    <sup>*</sup>
                  </label>
                  <DateForm
                    isDisabled={true}
                    value={
                      attributesData["REQEFCTVDT"]
                        ? attributesData["REQEFCTVDT"]
                        : null
                    }
                    onChange={(date) => {
                      setAttributesData((prev) => ({
                        ...prev,
                        ["REQEFCTVDT"]: moment(date).format("DD-MMM-YYYY"),
                      }));
                    }}
                  />
                </div>
                {formErrors["REQEFCTVDT"] && (
                  <p style={{ color: "red" }}>{formErrors["REQEFCTVDT"]}</p>
                )}
              </div>

              <div className={"col-lg-3 col-sm-3 col-xs-4"} hidden={hideDivForEss}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Country
                    <sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={true}
                    value={
                      attributesData["REQCNTRY"]
                        ? attributesData["REQCNTRY"]
                        : null
                    }
                    placeholder={"Country"}
                    options={
                      optionsObj["REQCNTRY"] ? optionsObj["REQCNTRY"] : []
                    }
                    onChange={async (e) => {}}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
              </div>

              <div
                className={"col-lg-3 col-sm-3 col-xs-4"}
                // hidden={
                //   attributesData["REQCNTRY"] &&
                //   attributesData["REQCNTRY"].code == "CODECNTRY7"
                //     ? false
                //     : true
                // }
                hidden={hideDivForEss}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Payroll Location
                    {attributesData["REQCNTRY"] &&
                      attributesData["REQCNTRY"].code == "CODECNTRY7" && (
                        <sup>*</sup>
                      )}
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={true}
                    value={
                      attributesData["REQPLOCN"]
                        ? attributesData["REQPLOCN"]
                        : null
                    }
                    placeholder={"Payroll Location"}
                    options={[]}
                    onChange={async (e) => {}}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
                {formErrors["REQPLOCN"] && (
                  <p style={{ color: "red" }}>{formErrors["REQPLOCN"]}</p>
                )}
              </div>

              <div className={"col-lg-3 col-sm-3 col-xs-4"} hidden={hideDivForEss}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Event
                    <sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={true}
                    value={
                      attributesData["REQTYPOUPD"]
                        ? attributesData["REQTYPOUPD"]
                        : null
                    }
                    placeholder={"Type Of Update"}
                    options={
                      optionsObj["REQTYPOUPD"] ? optionsObj["REQTYPOUPD"] : []
                    }
                    onChange={async (e) => {
                      setAttributesData((prev) => ({
                        ...prev,
                        ["REQTYPOUPD"]: e,
                        ["REQRSNOUP"]: null,
                        ["REQTABS"]: null,
                        ["REQSECT"]: null,
                      }));
                      setFinalFormData({});
                      if (e) {
                        const optForReason = await getDdData(
                          "REQRSNOUP",
                          e.code
                        );
                        setOptionsObj((prev) => ({
                          ...prev,
                          ["REQRSNOUP"]: optForReason,
                        }));
                        setapproverOpt({});
                        setApprovers([]);
                        setBtnHide(true);
                        setDynamicAccordian({});
                        setDynamicformErrors({});
                      } else {
                        setOptionsObj((prev) => ({
                          ...prev,
                          ["REQRSNOUP"]: [],
                        }));
                        setapproverOpt({});
                        setApprovers([]);
                        setBtnHide(true);
                        setDynamicAccordian({});
                        setDynamicformErrors({});
                      }
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
                {formErrors["REQTYPOUPD"] && (
                  <p style={{ color: "red" }}>{formErrors["REQTYPOUPD"]}</p>
                )}
              </div>
              <div className={"col-lg-3 col-sm-3 col-xs-4"} hidden={hideDivForEss}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Event Reason
                    <sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={true}
                    value={
                      attributesData["REQRSNOUP"]
                        ? attributesData["REQRSNOUP"]
                        : null
                    }
                    placeholder={"Reason Of Update"}
                    options={
                      optionsObj["REQRSNOUP"] ? optionsObj["REQRSNOUP"] : []
                    }
                    onChange={(e) => {
                      setAttributesData((prev) => ({
                        ...prev,
                        ["REQRSNOUP"]: e,
                        ["REQTABS"]: null,
                        ["REQSECT"]: null,
                      }));
                      setApprovers([]);
                      setapproverOpt({});
                      setBtnHide(true);
                      setDynamicAccordian({});
                      setDynamicformErrors({});
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
                {formErrors["REQRSNOUP"] && (
                  <p style={{ color: "red" }}>{formErrors["REQRSNOUP"]}</p>
                )}
              </div>

              <div
                className={"col-lg-3 col-sm-3 col-xs-4"}
                // hidden={sectionTabHide}
                hidden={hideDivForEss}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Tab
                    <sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={true}
                    value={
                      attributesData["REQTABS"]
                        ? attributesData["REQTABS"]
                        : null
                    }
                    placeholder={"Tab"}
                    options={optionsObj["REQTABS"] ? optionsObj["REQTABS"] : []}
                    onChange={async (e) => {
                      setAttributesData((prev) => ({
                        ...prev,
                        ["REQTABS"]: e,
                        ["REQSECT"]: null,
                      }));
                      if (e) {
                        const optForSect = await getDdData("REQSECT", e.value);
                        setOptionsObj((prev) => ({
                          ...prev,
                          ["REQSECT"]: optForSect,
                        }));
                      } else {
                        setOptionsObj((prev) => ({
                          ...prev,
                          ["REQSECT"]: [],
                        }));
                      }
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
                {/* {formErrors["REQRSNOUP"] && (
                    <p style={{ color: "red" }}>{formErrors["REQRSNOUP"]}</p>
                  )} */}
              </div>
              <div
                className={"col-lg-3 col-sm-3 col-xs-4"}
                // hidden={sectionTabHide}
                hidden={hideDivForEss}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Section
                    <sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={true}
                    value={
                      attributesData["REQSECT"]
                        ? attributesData["REQSECT"]
                        : null
                    }
                    placeholder={"Section"}
                    options={optionsObj["REQSECT"] ? optionsObj["REQSECT"] : []}
                    onChange={(e) => {
                      setAttributesData((prev) => ({
                        ...prev,
                        ["REQSECT"]: e,
                      }));
                    }}
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
                {/* {formErrors["REQRSNOUP"] && (
                    <p style={{ color: "red" }}>{formErrors["REQRSNOUP"]}</p>
                  )} */}
              </div>

              <div className={"col-lg-3 col-sm-3 col-xs-4"} hidden={hideDivForEss}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Employee ID
                    <sup>*</sup>
                  </label>
                  {/* searchable item  */}
                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={true}
                    value={
                      attributesData["REQEMPLID"]
                        ? attributesData["REQEMPLID"]
                        : null
                    }
                    placeholder={"Employee ID"}
                    async
                    options={(searchString, cb) =>
                      getSearchableDropdownAPI(
                        searchString,
                        "IOPPOS",
                        cb,
                        "",
                        "attribute",
                        0
                      )
                    }
                    onChange={(e) => {
                      if (e) {
                        setAttributesData((prev) => ({
                          ...prev,
                          ["REQEMPLID"]: e,
                          ["REQEMPLNM"]: e.label,
                        }));
                        setTId(parseInt(e.value));
                        setApprovers([]);
                        setapproverOpt({});
                        setBtnHide(true);
                        setDynamicAccordian({});
                        setDynamicformErrors({});
                      } else {
                        setAttributesData((prev) => ({
                          ...prev,
                          ["REQEMPLID"]: e,
                          ["REQEMPLNM"]: "",
                        }));
                        setApprovers([]);
                        setapproverOpt({});
                        setBtnHide(true);
                        setDynamicAccordian({});
                        setDynamicformErrors({});
                      }
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
                {formErrors["REQEMPLID"] && (
                  <p style={{ color: "red" }}>{formErrors["REQEMPLID"]}</p>
                )}
              </div>
              <div className={"col-lg-3 col-sm-3 col-xs-4"} hidden={hideDivForEss}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Employee Name
                    <sup>*</sup>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Employee Name"}
                    isDisabled={true}
                    textArea={false}
                    value={
                      attributesData["REQEMPLNM"]
                        ? attributesData["REQEMPLNM"]
                        : ""
                    }
                    onChange={(e) => {
                      setAttributesData((prev) => ({
                        ...prev,
                        ["REQEMPLNM"]: e.target.value,
                      }));
                    }}
                  />
                </div>
                {formErrors["REQEMPLNM"] && (
                  <p style={{ color: "red" }}>{formErrors["REQEMPLNM"]}</p>
                )}
              </div>
              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">Supporting Documents</label>

                  {/* {selectedFilesForFlat.length === 0 && (
                    <InputForm
                      className="form-control"
                      placeholder={"Document"}
                      isDisabled={true}
                      textArea={false}
                      value={""}
                      onChange={(e) => {}}
                    />
                  )} */}
                  <div className="box position-relative">
                    <input
                      id={"REQSUPDOC"}
                      className="form-control inputfile inputfile-6 multiple-inputfile"
                      multiple={true}
                      type="file"
                      ref={supportingDocRef}
                      onChange={(e) => {
                        handleSupportingDoc(e, "REQSUPDOC");
                      }}
                      accept={
                        ".docx,.doc,.pdf,.png,.jpg,.jpeg,.txt,.ppt,.pptx,.xlsx"
                      }
                      disabled={false}
                    />
                    <label
                      htmlFor={"REQSUPDOC"}
                      className="form-control"
                      style={{ width: 0, border: "none" }}>
                      <strong
                        style={{
                          padding: "6px 16px",
                          backgroundColor: "#3c5464",
                          borderRadius: "5px",
                        }}>
                        <i
                          className="fa fa-upload rotate90"
                          aria-hidden="true"></i>
                        {"  "}
                        Upload
                      </strong>{" "}
                    </label>
                  </div>

                  <div className="file-added-list">
                    <ul className="list-unstyle">
                      {selectedFilesForFlat &&
                        selectedFilesForFlat.length > 0 &&
                        selectedFilesForFlat.map(
                          (fileAttribute, attributeIndex) => (
                            <>
                              <li className="list mt-1" key={attributeIndex}>
                                <div className="media">
                                  <div className="media-body text-truncate">
                                    <span className="view-more">
                                      {fileAttribute.Name}
                                    </span>
                                  </div>

                                  {
                                    fileAttribute.DocId != 0 && 
                                    <div className="media-right ml-2">
                                      <i
                                        className="fa-solid fa-download"
                                        aria-hidden="true"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleDownloadFileFlat(
                                            fileAttribute,
                                            attributeIndex
                                          )
                                        }></i>
                                    </div>
                                  }
                                  <div className="media-right ml-2">
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                      onClick={() =>
                                        handleDeleteFileForSuppDoc(
                                          fileAttribute,
                                          attributeIndex
                                        )
                                      }></i>
                                  </div>
                                </div>
                              </li>
                            </>
                          )
                        )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className={"col-lg-6 col-sm-12 mobile-view top-m"}>
                <div className="mb-1">
                  <label className="col-form-label">Remarks</label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control"
                    placeholder={"Remarks, if any."}
                    isDisabled={false}
                    textArea={true}
                    value={
                      attributesData["REQDREMK"]
                        ? attributesData["REQDREMK"]
                        : ""
                    }
                    onChange={(e) => {
                      e.preventDefault();
                      setAttributesData((prev) => ({
                        ...prev,
                        ["REQDREMK"]: e.target.value,
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className={"col-lg-12 col-md-12 col-sm-12 mobile-view top-m"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Previous Conversation
                  </label>
                  {/* <InputForm
                    className="form-control"
                    placeholder={"Previous Conversation"}
                    isDisabled={true}
                    textArea={true}
                    value={prevConversation ? prevConversation : ""}
                    onChange={(e) => {}}
                    rows={7}
                  /> */}
                  <PreviousConversation PrevConv={preConvJson}/>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        {progressionId > 0 && (
          <Accordion
            elevation={0}
            className="mb-3"
            expanded={changeLogAccordian}
            onChange={() => setChangeLogAccordian((prev) => !prev)}>
            <AccordionSummary
              id="profile-accordion"
              style={{ background: "#3C5464" }}
              className="text-white acc_close"
              expandIcon={<ExpandMore />}>
              <div className="row d-flex align-items-center acc-heading">
                <div className="col-lg-10 col-md-10 col-sm-12">
                  <p style={{ fontSize: "16px" }}>Change Log</p>
                </div>
                <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
              </div>
            </AccordionSummary>

            <AccordionDetails className="page_heading">
              <div className="">
                <ChangeLog TId={progressionId} />
              </div>
            </AccordionDetails>
          </Accordion>
        )}

        {dynamicAccordians !== null && dynamicAccordians.length > 0 && (
          <>
            <Accordion
              elevation={0}
              className="mb-3"
              expanded={accordianEvent}
              onChange={() => setAccordianEvent((prev) => !prev)}>
              <AccordionSummary
                id="profile-accordion"
                style={{ background: "#3C5464" }}
                className="text-white acc_close"
                expandIcon={<ExpandMore />}>
                <div className="row d-flex align-items-center acc-heading">
                  <div className="col-lg-10 col-md-10 col-sm-12">
                    <p style={{ fontSize: "16px" }}>
                      {attributesData["REQTYPOUPD"]
                        ? attributesData["REQTYPOUPD"].label
                        : "Event"}
                    </p>
                  </div>
                  <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
                </div>
              </AccordionSummary>

              <AccordionDetails className="page_heading">
                <div className="row">
                  {dynamicAccordians !== null &&
                    dynamicAccordians.length > 0 &&
                    dynamicAccordians.map((attribute) =>
                      attribute.attributeDatatype === "DropdownSingle" ? (
                        <div
                          className="col-lg-3 col-sm-3 col-xs-4"
                          hidden={
                            attributesData["REQCNTRY"]?.code == "CODECNTRY1" &&
                            attributesData["REQTYPOUPD"]?.code == "Promotion" &&
                            attribute.attributeCode == "SC"
                              ? skillcategoryHide
                              : false
                          }>
                          <div className="mb-1">
                            <label className="col-form-label">
                              {attribute.attributeName}
                            </label>
                            {attribute.attributeCode != "WOA" &&
                              attribute.attributeCode != "WOAR" && <sup>*</sup>}
                            <SelectForm
                              isSearchable
                              isDisabled={false}
                              value={attribute.valueOnDd}
                              options={attribute.options}
                              placeholder={attribute.attributeName}
                              onChange={(e) => {
                                if (e) {

                                  //For Transfer only
                                  if(attributesData["REQTYPOUPD"]?.code == "Transfer"){
                                    let tempCodeCheckArr = [...codeCheckForTansfer];
                                    const check = tempCodeCheckArr.includes(attribute.attributeCode);
                                    if(!check){
                                      tempCodeCheckArr.push(attribute.attributeCode);
                                      setCodeCheckForTransfer(()=>([...tempCodeCheckArr]));
                                    }
                                  }
                                  //-----------------
                                  // getLOP when USA
                                  if (
                                    attribute.attributeCode === "EC" ||
                                    attribute.attributeCode === "JL"
                                  ) {
                                    setFetchLOP(true);
                                  }

                                  let tempAcc = [...dynamicAccordians];
                                  let tempAtt = tempAcc.find(
                                    (item) =>
                                      item.attributeCode ==
                                      attribute.attributeCode
                                  );
                                  tempAtt.valueOnDd = e;

                                  //-----------Probation/Training Date Enable Disable----------------------//
                                  if (attribute.attributeCode == "PC") {
                                    if (e.code == "No002") {
                                      setProbDateDisable(true);
                                      let tempAtt6 = tempAcc?.find(
                                        (item) => item.attributeCode == "PEDE"
                                      );

                                      tempAtt6.valueOnDd = null;
                                    } else {
                                      setProbDateDisable(false);
                                    }
                                  } else if (attribute.attributeCode == "TC") {
                                    if (e.code == "P002") {
                                      setTrainDateDisable(true);
                                      let tempAtt6 = tempAcc?.find(
                                        (item) => item.attributeCode == "TCD"
                                      );

                                      tempAtt6.valueOnDd = null;
                                    } else {
                                      setTrainDateDisable(false);
                                    }
                                  }
                                  ////////////////////////////////////////////////////////

                                  //-----------Set Client Facing Title on Change of Desgination----------------------//
                                  if (
                                    attribute.attributeCode == "SD" 
                                  ) {
                                    let tempAttCFT = tempAcc?.find(
                                      (item) => item.attributeCode == "PT"
                                    );
                                    if(tempAttCFT!=null && tempAttCFT!=undefined){
                                      tempAttCFT.valueOnDd = e.label;
                                    }
                                  }
                                  ////////////////////////////////////////////////////////

                                  setDynamicAccordian(tempAcc);

                                  //////////////////////////CASCADE TEST/////////////////////////////
                                  if (
                                    attribute.attributeCascade !== null &&
                                    attribute.attributeCascade !== ""
                                  ) {
                                    const CC =
                                      attribute.attributeCascade.split(",");

                                    CC.forEach(async (childDropdown) => {
                                      if (
                                        childDropdown != "CUSBIS" &&
                                        childDropdown != "EC" &&
                                        ((attributesData["REQTYPOUPD"] &&
                                          attributesData["REQTYPOUPD"].code ==
                                            "Transfer" &&
                                          childDropdown != "JB") ||
                                          (attributesData["REQTYPOUPD"] &&
                                            attributesData["REQTYPOUPD"].code !=
                                              "Transfer"))
                                      ) {
                                        // hard code to get CUSBIS dropdown data
                                        let PId = "";
                                        if (
                                          (attribute.attributeCode === "BIS" ||
                                            attribute.attributeCode === "SBU" ||
                                            attribute.attributeCode ===
                                              "LEN") &&
                                          childDropdown === "CUSBIS"
                                        ) {
                                          let BIS =
                                            attribute.attributeCode === "BIS"
                                              ? e
                                                ? e.value.toString()
                                                : null
                                              : dynamicAccordians.find(
                                                  (item) =>
                                                    item.attributeCode === "BIS"
                                                ) !== null
                                              ? dynamicAccordians
                                                  .find(
                                                    (item) =>
                                                      item.attributeCode ===
                                                      "BIS"
                                                  )
                                                  ?.value?.toString()
                                              : null;
                                          let SBU =
                                            attribute.attributeCode === "SBU"
                                              ? e
                                                ? e.value.toString()
                                                : null
                                              : dynamicAccordians.find(
                                                  (item) =>
                                                    item.attributeCode === "SBU"
                                                ) !== null
                                              ? dynamicAccordians
                                                  .find(
                                                    (item) =>
                                                      item.attributeCode ===
                                                      "SBU"
                                                  )
                                                  ?.value?.toString()
                                              : null;
                                          let LEN =
                                            attribute.attributeCode === "LEN"
                                              ? e
                                                ? e.value.toString()
                                                : null
                                              : dynamicAccordians.find(
                                                  (item) =>
                                                    item.attributeCode === "LEN"
                                                ) !== null
                                              ? dynamicAccordians
                                                  .find(
                                                    (item) =>
                                                      item.attributeCode ===
                                                      "LEN"
                                                  )
                                                  ?.value?.toString()
                                              : null;
                                          if (BIS && SBU && LEN) {
                                            PId = `${BIS},${SBU},${LEN}`;
                                          }
                                        }
                                        //-------------------------------
                                        if (
                                          (attribute.attributeCode === "EC" ||
                                            attribute.attributeCode ===
                                              "LEN") &&
                                          childDropdown === "JB"
                                        ) {
                                          let LEN =
                                            attribute.attributeCode === "LEN"
                                              ? e
                                                ? e.value.toString()
                                                : null
                                              : dynamicAccordians.find(
                                                  (item) =>
                                                    item.attributeCode === "LEN"
                                                ) !== null
                                              ? dynamicAccordians
                                                  .find(
                                                    (item) =>
                                                      item.attributeCode ===
                                                      "LEN"
                                                  )
                                                  ?.value?.toString()
                                              : null;
                                          let BIS =
                                            attribute.attributeCode === "EC"
                                              ? e
                                                ? e.value.toString()
                                                : null
                                              : dynamicAccordians.find(
                                                  (item) =>
                                                    item.attributeCode === "EC"
                                                ) !== null
                                              ? dynamicAccordians
                                                  .find(
                                                    (item) =>
                                                      item.attributeCode ===
                                                      "EC"
                                                  )
                                                  ?.value?.toString()
                                              : null;

                                          if (BIS && LEN) {
                                            PId = `${LEN},${BIS}`;
                                          }
                                        }
                                        const options = await getCommonDdData(
                                          childDropdown,
                                          (e && childDropdown === "CUSBIS") ||
                                            (e && childDropdown === "JB")
                                            ? PId
                                            : e
                                            ? e.value
                                            : ""
                                        );
                                        let tempAcc1 = [...dynamicAccordians];
                                        let tempAtt1 = tempAcc1?.find(
                                          (item) =>
                                            item.attributeCode == childDropdown
                                        );
                                        tempAtt1.valueOnDd = null;
                                        tempAtt1.options = options;

                                        setDynamicAccordian(tempAcc1);
                                      }
                                    });
                                  }
                                  //////////////////////////////////////////////////////////////////

                                  //-----------Set Wave Off Reason----------------------//
                                  if (attribute.attributeCode == "WOA") {
                                    if (e.code == "CODEWOAPPR2") {
                                      setWaveOffReasonDisable(true);
                                    } else {
                                      setWaveOffReasonDisable(false);
                                    }
                                  }
                                  ////////////////////////////////////////////////////////

                                  //----------Promotion Worker pe Skill Category Show---------//
                                  if (
                                    attributesData["REQCNTRY"]?.code ==
                                      "CODECNTRY1" &&
                                    attributesData["REQTYPOUPD"]?.code ==
                                      "Promotion" &&
                                    attribute.attributeCode == "EC" &&
                                    e.code == "Worker"
                                  ) {
                                    setSkillCategoryHide(false);
                                  } else if (
                                    attributesData["REQCNTRY"]?.code ==
                                      "CODECNTRY1" &&
                                    attributesData["REQTYPOUPD"]?.code ==
                                      "Promotion" &&
                                    attribute.attributeCode == "EC" &&
                                    e.code != "Worker"
                                  ) {
                                    setSkillCategoryHide(true);
                                  }
                                  ////////////////////////////////////////////////////////
                                } else {
                                  notify(1, "Cannot Clear the Field");
                                }
                              }}
                              isMulti={false}
                              noIndicator={false}
                              noSeparator={false}
                            />
                          </div>
                          {dynamicformErrors[attribute.attributeCode] && (
                            <p style={{ color: "red" }}>
                              {dynamicformErrors[attribute.attributeCode]}
                            </p>
                          )}
                        </div>
                      ) : attribute.attributeDatatype === "Text" ||
                        attribute.attributeDatatype === "Number" ? (
                        <div className="col-lg-3 col-sm-3 col-xs-4">
                          <div className="mb-1">
                            <label className="col-form-label">
                              {attribute.attributeName}
                            </label>
                            {attribute.attributeCode != "WOA" &&
                              attribute.attributeCode != "WOAR" && <sup>*</sup>}
                            <InputForm
                              className="form-control"
                              isDisabled={
                                attribute.attributeCode === "WOAR"
                                  ? waveOffReasonDisable
                                  : attribute.attributeCode === "LOPHR"
                                  ? true
                                  : false
                              }
                              value={attribute.valueOnDd}
                              placeholder={attribute.attributeName}
                              textArea={false}
                              onChange={(e) => {
                                let tempAcc = [...dynamicAccordians];
                                let tempAtt = tempAcc.find(
                                  (item) =>
                                    item.attributeCode ==
                                    attribute.attributeCode
                                );
                                tempAtt.valueOnDd = e.target.value;
                                setDynamicAccordian(tempAcc);
                              }}
                            />
                          </div>
                        </div>
                      ) : attribute.attributeDatatype === "Date" ? (
                        <div className="col-lg-3 col-sm-3 col-xs-4">
                          <div className="mb-1">
                            <label className="col-form-label">
                              {attribute.attributeName}
                            </label>
                            <sup>*</sup>
                            <DateForm
                              isDisabled={
                                attributesData?.["REQRSNOUP"]?.code ===
                                  "PROB" && attribute.attributeCode === "PEDE"
                                  ? probDateDisable
                                  : attributesData?.["REQRSNOUP"]?.code ===
                                      "TRNG" &&
                                    attribute.attributeCode === "TCD"
                                  ? trainDateDisable
                                  : false
                              }
                              value={attribute.valueOnDd}
                              onChange={(date) => {
                                let tempAcc = [...dynamicAccordians];
                                let tempAtt = tempAcc.find(
                                  (item) =>
                                    item.attributeCode ==
                                    attribute.attributeCode
                                );
                                tempAtt.valueOnDd =
                                  date != null
                                    ? moment(date).format("DD-MMM-YYYY")
                                    : null;
                                if (
                                  attribute.attributeCode === "DOR" &&
                                  date != null
                                ) {
                                  let tempAtt1 = tempAcc.find(
                                    (item) => item.attributeCode == "NPSD"
                                  );
                                  tempAtt1.valueOnDd =
                                    date != null
                                      ? moment(date).format("DD-MMM-YYYY")
                                      : null;
                                  let tempAtt2 = tempAcc.find(
                                    (item) => item.attributeCode == "NPEDE"
                                  );
                                  tempAtt2.valueOnDd =
                                    date != null
                                      ? moment(GetNPEndDate(date)).format(
                                          "DD-MMM-YYYY"
                                        )
                                      : null;
                                  let tempAtt3 = tempAcc.find(
                                    (item) => item.attributeCode == "NPSDE"
                                  );
                                  tempAtt3.valueOnDd = GetShortDays(
                                    attribute.attributeCode,
                                    date
                                  );
                                }
                                if (
                                  attribute.attributeCode === "LWD" &&
                                  date != null
                                ) {
                                  const tempDate = moment(date);
                                  const newDate = tempDate.add(1, "days");
                                  if (
                                    attributesData["REQTYPOUPD"] &&
                                    attributesData["REQTYPOUPD"].code ==
                                      "Termination"
                                  ) {
                                    setAttributesData((prev) => ({
                                      ...prev,
                                      ["REQEFCTVDT"]:
                                        moment(newDate).format("DD-MMM-YYYY"),
                                    }));
                                  }
                                  let tempAtt4 = tempAcc.find(
                                    (item) => item.attributeCode == "NPSDE"
                                  );
                                  tempAtt4.valueOnDd = GetShortDays(
                                    attribute.attributeCode,
                                    date
                                  );
                                  let tempAtt5 = tempAcc.find(
                                    (item) => item.attributeCode == "ETD"
                                  );
                                  if(tempAtt5 != null && tempAtt5 != undefined){
                                    tempAtt5.valueOnDd =
                                    moment(newDate).format("DD-MMM-YYYY");
                                  }
                                }
                                setDynamicAccordian(tempAcc);
                              }}
                            />
                          </div>
                        </div>
                      ) : attribute.attributeDatatype ===
                        "SearchableDropdownSingle" ? (
                        <div className="col-lg-3 col-sm-3 col-xs-4">
                          <div className="mb-1">
                            <label className="col-form-label">
                              {attribute.attributeName}
                            </label>
                            <sup>*</sup>
                            <SelectForm
                              isClearable
                              isSearchable
                              async
                              isDisabled={false}
                              options={(searchString, cb) =>
                                getSearchableDropdownAPI(
                                  searchString,
                                  attribute.attributeCode,
                                  cb,
                                  "",
                                  "attribute",
                                  0
                                )
                              }
                              placeholder={attribute.attributeName}
                              value={
                                attribute.valueOnDd ? attribute.valueOnDd : null
                              }
                              onChange={async (e) => {
                                if (e) {
                                  let tempAcc = [...dynamicAccordians];
                                  let tempAtt = tempAcc.find(
                                    (item) =>
                                      item.attributeCode ==
                                      attribute.attributeCode
                                  );
                                  tempAtt.valueOnDd = e;

                                  //------------hardcode to set BIS,SBU,LEN,DV,FUNC,PLOCN,CC on change of POS-------------------//
                                  if (
                                    attributesData["REQTYPOUPD"]?.code ===
                                      "Transfer" &&
                                    attribute.attributeCode == "POS"
                                  ) {
                                    //api call to get data
                                    const ddata = await APICall(
                                      getDependentDataOfPos,
                                      "POST",
                                      {
                                        AC: attribute.attributeCode,
                                        PID: e.value,
                                      }
                                    );
                                    if (
                                      ddata != null &&
                                      ddata != undefined &&
                                      ddata.data &&
                                      ddata.data != null &&
                                      ddata?.data?.length > 0
                                    ) {
                                      ddata?.data?.map((item) => {
                                        let selectedItem = null;
                                        switch (item?.key) {
                                          case "PMBUSN":
                                            let tempAttbis = tempAcc?.find(
                                              (att) =>
                                                att.attributeCode == "BIS"
                                            );
                                            const selectedbis =
                                              ogOptionsForCascade?.[
                                                "BIS"
                                              ]?.find(
                                                (val) => val.value == item.value
                                              );
                                            tempAttbis.valueOnDd = selectedbis
                                              ? selectedbis
                                              : null;
                                            break;
                                          case "PMSBU":
                                            let tempAttsbu = tempAcc?.find(
                                              (att) =>
                                                att.attributeCode == "SBU"
                                            );
                                            const selectedsbu =
                                              ogOptionsForCascade?.["SBU"].find(
                                                (val) => val.value == item.value
                                              );
                                            tempAttsbu.valueOnDd = selectedsbu
                                              ? selectedsbu
                                              : null;
                                            break;
                                          case "PMLEGEN":
                                            let tempAttlen = tempAcc?.find(
                                              (att) =>
                                                att.attributeCode == "LEN"
                                            );
                                            const selectedlen =
                                              ogOptionsForCascade?.["LEN"].find(
                                                (val) => val.value == item.value
                                              );
                                            tempAttlen.valueOnDd = selectedlen
                                              ? selectedlen
                                              : null;
                                            break;
                                          case "PMDIVN":
                                            let tempAttdv = tempAcc?.find(
                                              (att) => att.attributeCode == "DV"
                                            );
                                            selectedItem =
                                              ogOptionsForCascade?.["DV"].find(
                                                (val) => val.value == item.value
                                              );
                                            tempAttdv.valueOnDd = selectedItem
                                              ? selectedItem
                                              : null;
                                            break;
                                          case "PMFUNC":
                                            let tempAttfunc = tempAcc?.find(
                                              (att) =>
                                                att.attributeCode == "FUNC"
                                            );
                                            selectedItem =
                                              ogOptionsForCascade?.[
                                                "FUNC"
                                              ].find(
                                                (val) => val.value == item.value
                                              );
                                            tempAttfunc.valueOnDd = selectedItem
                                              ? selectedItem
                                              : null;
                                            break;
                                          case "PMDEPT":
                                            let tempAttdpt = tempAcc?.find(
                                              (att) =>
                                                att.attributeCode == "DPT"
                                            );
                                            selectedItem =
                                              ogOptionsForCascade?.["DPT"].find(
                                                (val) => val.value == item.value
                                              );
                                            tempAttdpt.valueOnDd = selectedItem
                                              ? selectedItem
                                              : null;
                                            break;
                                          case "PMLOCN":
                                            let tempAttploc = tempAcc?.find(
                                              (att) =>
                                                att.attributeCode == "PLOC"
                                            );
                                            selectedItem =
                                              ogOptionsForCascade?.[
                                                "PLOC"
                                              ].find(
                                                (val) => val.value == item.value
                                              );
                                            tempAttploc.valueOnDd = selectedItem
                                              ? selectedItem
                                              : null;
                                            break;
                                          case "PMCOCEN":
                                            let tempAttcc = tempAcc?.find(
                                              (att) => att.attributeCode == "CC"
                                            );
                                            selectedItem =
                                              ogOptionsForCascade?.["CC"].find(
                                                (val) => val.value == item.value
                                              );
                                            tempAttcc.valueOnDd = selectedItem
                                              ? selectedItem
                                              : null;
                                            break;
                                        }
                                      });
                                    }
                                  }
                                  //-------------------------------------------------------------------------------------------

                                  setDynamicAccordian(tempAcc);
                                } else {
                                  notify(1, "Cannot Clear the Field");
                                }
                              }}
                              isMulti={false}
                              noIndicator={false}
                              noSeparator={false}
                            />
                            {dynamicformErrors[attribute.attributeCode] && (
                              <p style={{ color: "red" }}>
                                {dynamicformErrors[attribute.attributeCode]}
                              </p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )
                    )}
                </div>
              </AccordionDetails>
            </Accordion>
          </>
        )}

        {
          //For Section and tab render
          ((finalformData != null &&
            finalformData.length > 0 &&
            attributesData["REQEMPLID"] != null &&
            attributesData["REQEFCTVDT"] != null &&
            sectionValuesArray.length > 0) ||
            (finalformData != null &&
              finalformData.length > 0 &&
              attributesData["REQEMPLID"] != null &&
              attributesData["REQEFCTVDT"] != null &&
              sectionValuesArray.length > 0 &&
              submitFunc)) &&
            finalformData.map((eachSection, index) => (
              <React.Fragment key={`${sectionEssId}-${eachSection?.SN}`}>
                <EmpProgSectionComponent
                  formData={finalformData}
                  setFormData={setFinalFormData}
                  section={eachSection}
                  sectionIndex={sectionEssId}
                  TId={TId}
                  setTId={setTId}
                  sectionValuesArray={sectionValuesArray}
                  SetSectionValuesArray={setSectionValuesArray}
                  Globalv={globalValues}
                  COU={userDetails.COU}
                  submitFunc={submitFunc}
                  setGetData={setGetData}
                  IsSendBack={true}
                  payrolldd={attributesData["REQPLOCN"]}
                  countrydd={attributesData["REQCNTRY"]}
                  effectiveDate = {attributesData["REQEFCTVDT"]}
                />
              </React.Fragment>
            ))
        }

        <Accordion
          elevation={0}
          className="mb-3"
          expanded={accordionWrkfl}
          onChange={() => setAccordionWrkfl((prev) => !prev)}>
          <AccordionSummary
            id="profile-accordion"
            style={{ background: "#3C5464" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}>
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <p style={{ fontSize: "16px" }}>Workflow</p>
              </div>
              <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
            </div>
          </AccordionSummary>

          <AccordionDetails className="page_heading">
            <div className="row">
              {approvers && approvers.length >= 1 && (
                <div className="col-lg-3 col-sm-3 col-xs-4">
                  <div className="mb-1">
                    <label className="col-form-label">Initiator</label>
                    <sup>*</sup>
                    <InputForm
                      value={attributesData["WRKFLINNM"]}
                      placeholder={"Initiator"}
                      isDisabled={true}
                      textArea={false}
                      onChange={() => {}}
                    />
                  </div>
                </div>
              )}
              {/* For Approver noOfApprover */}
              {approvers &&
                approvers.length > 1 &&
                approvers.map(
                  (eachApprover, index) =>
                    index > 0 && (
                      <>
                        <div className="col-lg-3 col-sm-3 col-xs-4">
                          <div className="dropdown mb-1">
                            <label className="col-form-label">
                            {eachApprover?.roleName 
                              ? eachApprover?.roleName 
                              : `${"Approver"} ${index}`}
                            </label>
                            <sup>*</sup>
                            <SelectForm
                              isClearable
                              isSearchable
                              isDisabled={true}
                              value={allApprovers[`${"Approver"} ${index}`]}
                              options={approverOpt[`${"Approver"} ${index}`]}
                              placeholder={`No Employee Found`}
                              onChange={(e) => {
                                alert("Change");
                              }}
                              isMulti={true}
                              noIndicator={false}
                              noSeparator={false}
                            />
                            <div className="dropdown-content">
                              {approverOpt[`${"Approver"} ${index}`] != null &&
                                approverOpt[`${"Approver"} ${index}`].length >
                                  0 &&
                                approverOpt[`${"Approver"} ${index}`].map(
                                  (eachApp) => <p>{eachApp.label}</p>
                                )}
                            </div>
                          </div>
                        </div>
                      </>
                    )
                )}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};

export default EmployeeSendback;
