import React, { useEffect, useState } from "react";
import "./ChangeLog.css";
import { getAuditLogsV2 } from "../../Helpers/APIEndPoints/EndPoints";
import { APICall } from "../../Helpers/APICalls";
import moment from "moment";
import { Table } from "react-bootstrap";

// Get the local timezone offset in minutes
const localOffsetMinutes = moment().utcOffset();

function ChangeLog({ TId }) {
  const [auditLogsObject, setAuditLogsObject] = useState<any>({});
  const [rhsData, setRhsData] = useState<any>([]);
  const [tableData, setTableData] = useState([]);
  const [eventCode, setEventCode] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const [noChange, setNoChange] = useState(false);

  useEffect(() => {
    GetLogData();
  }, []);

  const GetLogData = async () => {
    const auditLogsRes = await APICall(getAuditLogsV2, "POST", {
      TransactionId: TId,
    });
    console.log('Audit Log::::',auditLogsRes);
    if (auditLogsRes?.data?.length > 0) {
      setEventCode(auditLogsRes?.data?.[0]?.eventCode);
      if(auditLogsRes?.data?.[0]?.eventCode === 'Separation'){
        const groupedByUserId = auditLogsRes?.data.reduce((acc, obj) => {
          const type = obj.userId;
          if (!acc[type]) {
            acc[type] = [];
          }
          acc[type].push(obj);
          return acc;
        }, {});
        
        const valuesArrayOnUserId = Object.values(groupedByUserId);

        setRhsData(valuesArrayOnUserId[0]);
        setAuditLogsObject(groupedByUserId);
      } else {
        const groupedByType = auditLogsRes?.data.reduce((acc, obj) => {
          const type = obj.recordId;
          if (!acc[type]) {
            acc[type] = [];
          }
          acc[type].push(obj);
          return acc;
        }, {});
  
        const valuesArray = Object.values(groupedByType);
        setRhsData(valuesArray[0]);
        // setAuditLogsObject(groupedByType);

        const transactionGroup = auditLogsRes?.data;
        const recordsArray = [];

        const recordsMap = {};
        transactionGroup.forEach((item) => {
          const recordId = item.recordId;
          if (!recordsMap[recordId]) {
            recordsMap[recordId] = [];
          }
          recordsMap[recordId].push(item);
        });

        for (const recordId in recordsMap) {
          if (recordsMap.hasOwnProperty(recordId)) {
            recordsArray.push(recordsMap[recordId]);
          }
        }

        const finalAuditData = [{
          [TId] : recordsArray[0],
        }];
        
        setAuditLogsObject(finalAuditData);
        console.log('recordsArray::::', recordsArray);
        setTableData(recordsArray);
      }
    } else {
      setAuditLogsObject({});
      setNoChange(true);
    }
  };

  const selectLog = async (log, index) => {
    setActiveTab(index);
    if (eventCode === 'Separation'){
      setRhsData(log);
    }
    else{
      setRhsData(log[0]);
    }
  };
  return (
    <div>
      {
        !noChange ?
          <div className="main-box row">
            <div className="lhs-outer-box row">
              {Object.keys(auditLogsObject).map((type, index) => (
                <div
                  id="rhs-box"
                  key={index}
                  className={index == activeTab ? "lhs-box bg-clr" : "lhs-box"}
                  onClick={() => Object.keys(auditLogsObject).length > 1 ? selectLog(auditLogsObject[type], index) : {}}>
                  <Log auditLogsType={eventCode === 'Separation' ? auditLogsObject[type] : auditLogsObject[type]?.[TId]} />
                </div>
              ))}
            </div>
              
            <div className="rhs-box" id="rhs-box">
              {
                <>
                  <div className="row">
                    <div className={"col-lg-6 col-sm-6"}>
                      <span className="text-bold">Event Name:</span>{" "}
                      {rhsData?.[0]?.eventName}
                    </div>{" "}
                    <div className={"col-lg-6 col-sm-6"}>
                      <span className="text-bold">Event Reason:</span>{" "}
                      {rhsData?.[0]?.eventReasonName}
                    </div>{" "}
                  </div>

                  <div className="row">
                    <div className={"col-lg-6 col-sm-6"}>
                      <span className="text-bold">Change Date:</span>{" "}
                      {/* {moment(eachLog?.createdDate).format("DD-MMM-YYYY")} */}
                      {moment.utc(rhsData?.[0]?.createdDate).utcOffset(localOffsetMinutes)
                      .format("DD-MMM-YYYY HH:mm:ss")}
                    </div>{" "}
                    <div className={"col-lg-6 col-sm-6"}>
                      <span className="text-bold">Effective Date:</span>{" "}
                      {moment(rhsData?.[0]?.effectiveDate).format("DD-MMM-YYYY")}
                    </div>{" "}
                    <div className={"col-lg-6 col-sm-6"}>
                      <span className="text-bold">Changed By:</span>{" "}
                      {rhsData?.[0]?.userName}
                    </div>
                  </div>
                  <div className="row">
                    <div className={"col-lg-6 col-sm-6"}>
                      <span className="text-bold">Remarks:</span> {rhsData?.[0]?.remarks}
                    </div>{" "}
                  </div>
                  <br />
                  <br />
                </>
              }
              {
                eventCode === 'Separation' ? (
                  <Table striped bordered hover>
                    {rhsData?.map((eachLog, index) => (
                      <>
                        {index == 0 && (
                          <thead>
                            <tr>
                              <th className="text-dark col-lg-2 col-md-2 col-sm-6 mobile-view top-m" colSpan={3}>
                                <center>{`${eachLog?.tabName} - ${eachLog?.sectionName}`}</center>
                              </th>
                            </tr>
                            <tr>
                              <th className="text-dark col-lg-2 col-md-2 col-sm-6 mobile-view top-m">Attribute</th>
                              <th className="text-dark col-lg-2 col-md-2 col-sm-3 mobile-view top-m">Old Value</th>
                              <th className="text-dark col-lg-2 col-md-2 col-sm-3 mobile-view top-m">New Value</th>
                            </tr>
                          </thead>
                        )}
                        <tbody>
                          <tr>
                            <td>{eachLog?.attribute}</td>
                            <td>{eachLog?.oldAttributeValue}</td>
                            <td>{eachLog?.newAttributeValue}</td>
                          </tr>
                        </tbody>
                      </>
                    ))}
                  </Table>
                ) : (
                  <>
                    {tableData?.map((eachLog, index) => (
                      <>
                        <Table striped bordered hover>
                          {
                            eachLog &&
                            eachLog.length > 0 &&
                            eachLog.map((log, indexl) => (
                              <>
                              {indexl == 0 && (
                                <thead>
                                  <tr>
                                    <th className="text-dark col-lg-2 col-md-2 col-sm-6 mobile-view top-m" colSpan={3}>
                                      <center>{`${log?.tabName} - ${log?.sectionName}`}</center>
                                    </th>
                                  </tr>
                                  <tr>
                                    <th className="text-dark col-lg-2 col-md-2 col-sm-6 mobile-view top-m">Attribute</th>
                                    <th className="text-dark col-lg-2 col-md-2 col-sm-3 mobile-view top-m">Old Value</th>
                                    <th className="text-dark col-lg-2 col-md-2 col-sm-3 mobile-view top-m">New Value</th>
                                  </tr>
                                </thead>
                              )}
                              <tbody>
                               <tr>
                                 <td>{log?.attribute}</td>
                                 <td>{log?.oldAttributeValue}</td>
                                 <td>{log?.newAttributeValue}</td>
                               </tr>
                              </tbody>
                            </>
                            ))
                          }
                        </Table>
                      </>
                    ))}
                  </>
                )
              }
            </div>
          </div>
        : <div className="text-bold error-msg-changelog">
            No Modifications Found!
          </div>
      }
      
    </div>
  );
}

const Log = ({ auditLogsType }) => {
  return (
    <>
      {console.log('auditLogsType::::',auditLogsType)}
      <span>
        <span className="text-bold">Event:</span> {auditLogsType[0].eventName}
      </span>
      <br />
      <span>
        <span className="text-bold">Changed Date:</span>{" "}
        {/* {moment(auditLogsType[0].createdDate).format("DD-MMM-YYYY")} */}
        {moment.utc(auditLogsType[0].createdDate).utcOffset(localOffsetMinutes)
        .format("DD-MMM-YYYY HH:mm:ss")}
      </span>
      <br />
      <span>
        <span className="text-bold">Approved Date:</span>{" "}
        {
          auditLogsType[0].approvedDate ? 
            moment.utc(auditLogsType[0].approvedDate).utcOffset(localOffsetMinutes)
            .format("DD-MMM-YYYY HH:mm:ss")
          : 'Not Approved yet!'
        }
      </span>
      <br />
      <span>
        <span className="text-bold">Changed By:</span>{" "}
        {auditLogsType[0].userName}
      </span>
      <br />
    </>
  );
};
export default ChangeLog;
