import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import { LoaderContext } from "./Helpers/Context/Context";
import Loader from "./Helpers/Loader/Loader";
import Router from "./Routes/router";
import FormStructure from "./Components/FromStructure/FormStructure";
import GridStructure from "./Components/GridStructure/GridStructure";
import Header from "./Layout/Header";
import Sidebar from "./Layout/Sidebar";
import Footer from "./Layout/Footer";
import AuthContext from "./Helpers/AuthContext/AuthContext";
import { Route, Routes } from "react-router-dom";
import RTE from "./Components/RTE/RTE";
import { APICall } from "./Helpers/APICalls";
import Login from "./Pages/Login/Login";
import TestLogin from "./Pages/TestLogin/TestLogin";
import MainLogin from "./Pages/MainLogin/MainLogin";
import useUserContext from "./Helpers/ZustandStore/UserContextStore";
import "./App.css";

function App() {
  const [loader, setLoader] = useState<boolean>(false);
  const showLoader = () => setLoader(() => true);
  const hideLoader = () => setLoader(() => false);

  const [rcm_salaryNegoLoader, setRCM_SalaryNegoLoader] = useState(false);

  const rcm_showLoader = () => {
    setRCM_SalaryNegoLoader(true);
  };
  const rcm_hideLoader = () => {
    setRCM_SalaryNegoLoader(false);
  };

  const [asidebar, setAsidebar] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const secret = "secret";

  const mobileResMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const [rte, setRte] = useState(null);

  useEffect(() => {
    //clear cache in developement
    if (process.env.NODE_ENV === "development") {
      (async () => {
        const res = await APICall("/api/Cache/ClearAllCache", "POST", {});
        if (res.data) {
          console.log("cache cleared");
        } else {
          console.log("cache not cleared");
        }
      })();
    }
  }, []);

  return (
    <>
      {/* <Routes>
        <Route path="/" element={<MainLogin />} />
      </Routes> */}
      <LoaderContext.Provider
        value={{ showLoader, hideLoader, rcm_showLoader, rcm_hideLoader }}
      >
        <Router />
        {/* {window.location.pathname !== "/" && (
          <div className="header-fixed">
            <Header
              menuOpenFunc={mobileResMenu}
              setAsidebar={setAsidebar}
              aisebar={asidebar}
            />
            <div id="base">
              <div id="content">
                <div className="main-content">
                  <Routes>
                    <Route path="/dashboard" element={<GridStructure />} />
                    <Route path="/" element={<TestLogin />} />
                    <Route path="/profile" element={<FormStructure />} />
                  </Routes>
                  <AuthContext />
                  <RTE content={rte} onblur={(e) => setRte(e)} />
                </div>
              </div>
              <Sidebar menuOpen={menuOpen} />
              <Footer />
            </div>
          </div>
        )} */}

        <ToastContainer
          position="top-center"
          autoClose={false}
          hideProgressBar={true}
          newestOnTop
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          icon={true}
          pauseOnHover
        />
        {loader && <Loader />}
        {rcm_salaryNegoLoader && <Loader />}
      </LoaderContext.Provider>
    </>
  );
}

export default App;
