import React, { useContext, useEffect, useState } from "react";
import { GetCandidateQueueData } from "../../Helpers/APIEndPoints/AssociateEndPoints";
import { APICall } from "../../Helpers/APICalls";
import { LoaderContext } from "../../Helpers/Context/Context";
import SelectForm from "../../Components/SelectForm/SelectForm";
import InputForm from "../../Components/InputForm/InputForm";
import DateForm from "../../Components/DateForm/DateForm";
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { GetAadhaarDetails, GetAadhaarOtp, InsertPreBookingRecruitmentDetails, UpdateReferenceNoForPreBooking, getDropdowns } from "../../Helpers/APIEndPoints/EndPoints";
import moment from "moment";
import { Modal } from "react-bootstrap";
import notify from "../../Helpers/ToastNotification";


const PreBookingRecruitment = ({ setShowHeader }) => {
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [captchaText, setCaptchaText] = useState('');
  const [aadharNumberValue, setAadharNumberValue] = useState('');
  const [nameValue, setNameValue] = useState('');
  const [dobValue, setdobValue] = useState(null);
  const [genderValue, setGenderValue] = useState<any>(null);
  const [mobileNumberValue, setmobileNumberValue] = useState('');
  const [visitDateValue, setvisitDateValue] = useState(null);
  const [formErrors, setFormErrors] = useState<any>({});
  const [genderOptions, setGenderOptions] = useState<any>({});
  const [modalIsOpen, setIsOpen] = useState(false);
  const [aadhaarDetails, setAadhaarDetails] = useState<any>({});
  const [showOtpSection, setShowOtpSection] = useState(false);
  const [isOTPDisabled, setIsOTPDisabled] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [aadharOTP, setAadharOTP] = useState('');
  const [confirmationNumber, setConfirmationNumber] = useState('');
  const [refid, setrefid] = useState(null);
  const [validatedOTP, setValidatedOTP] = useState(null);
  const [disableAutoFields, setDisableAutoFields] = useState(false);
  const { userDetails, currentRoleId, proxyUserDetails }: any =
    useUserContext();

    type FormErrors = {
      CategoryVal?: string;
      Rejection?: string;
      AdhaarNumber?: string;
      OtpNumber?: string;
      MobileNumber?: string;
      Decision?: string;
      relationshipWithMember?: string;
      FatherName?: string;
      maritalStatus?: any;
      Remark?: string;
      category?: string;
      positionId?: string;
      workArea?: string;
    };    

  useEffect(() => {
    loadCaptchaEnginge(7, 'gray');
    setShowHeader(false);
  }, []);

  useEffect(() => {
    (async () => {
      setGenderOptions(await getDropdownsData('GenMasterData', 'Gender'));
    })();

  }, []);

  const getDropdownsData = async (AC, PID) => {
    await showLoader();
    const dropdownData = await APICall(getDropdowns, "POST", {
      AC,
      PID,
      UserId: proxyUserDetails?.EmployeeId
        ? proxyUserDetails?.EmployeeId
        : userDetails?.Id,
      RoleId: currentRoleId?.value,
      RoleTypeId: 2,
    });

    if (
      dropdownData.data !== null &&
      dropdownData.data !== undefined &&
      dropdownData.data.length > 0
    ) {
      await hideLoader();
      return dropdownData.data;
    } else {
      await hideLoader();
      return [];
    }
  };

  const setMobileNoFn = (event) => {
    const inputText = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const truncatedText = inputText.substring(0, 10);
    setmobileNumberValue(truncatedText);
  };

  const fieldValidations = () => {

    let isError = {};
      if (aadharNumberValue.length === 0) {
        isError["AADHARNO"] = "Aadhar number is Required";
      } else{
        if(aadharNumberValue.length > 0){
          const aadharRegex = /^\d{12}$/;
          if (!aadharRegex.test(aadharNumberValue)){
            isError["AADHARNO"] = "Aadhar number is not in valid format";
          }
        }
      }

      if (nameValue.length === 0) {
        isError["NAME"] = "Name is Required";
      }

      if (dobValue === null) {
        isError["DOB"] = "Date of Birth is Required";
      } else{
        if(dobValue > moment() || dobValue === moment()) {
          isError["DOB"] = "Current or Future Date is not allowed"
        }       
      }

      if (genderValue === null) {
        isError["GEN"] = "Gender is Required";
      }

      if (mobileNumberValue.length === 0) {
        isError["MOB"] = "Mobile Number is Required";
      } else{
        if(mobileNumberValue.length > 0){
          const mobileNumberRegex = /^\d{10}$/;
          if (!mobileNumberRegex.test(mobileNumberValue)){
            isError["MOB"] = "Mobile number is not in valid format";
          }
        }
      }

      if (visitDateValue === null) {
        isError["VISITDATE"] = "Visit Date is Required";
      }

      if (captchaText.length === 0){
        isError["captcha"] = "Captcha is Required";
      }

      setFormErrors(isError);
      if (Object.keys(isError).length > 0) return isError;
  }

  const verifyAadhaarNumber = async () => {
    try {
      showLoader();
      let isValid = false;
    let errors: FormErrors = {};

    if (aadharNumberValue == "" || aadharNumberValue == null) {
      errors["AADHARNO"] = "Please Enter Adhaar Number";
      isValid = false;
    } else {
      const aadharRegex = /^\d{12}$/;
          if (!aadharRegex.test(aadharNumberValue)){
            errors["AADHARNO"] = "Aadhar number is not in valid format";
            isValid = false;
          } else{
            isValid = true;
          }
    }

    setFormErrors(errors);

    if(isValid){
      const responseFromAadhar = await APICall(GetAadhaarOtp, "POST", {
        AadharNumber: aadharNumberValue,
      });

      if (responseFromAadhar?.status == 0 && responseFromAadhar?.data) {
        console.log(responseFromAadhar);
        setrefid(responseFromAadhar?.data?.message.ref_id || 0);
        setShowOtpSection((prev) => !prev);
        setIsOTPDisabled(false);
      }
    }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  const submit = async () => {

    let errors = fieldValidations();

    if(!errors){
      showLoader();
      const response = await APICall(InsertPreBookingRecruitmentDetails, "POST", {
        AadhaarNo: aadharNumberValue,
        Name : nameValue,
        BirthDate: dobValue,
        Gender: genderValue.value,
        MobileNumber: mobileNumberValue,
        VisitDate: moment(visitDateValue).add(1, 'day'),
        SplitAddress: JSON.stringify(aadhaarDetails.message.split_address),
        CareOf: aadhaarDetails.message.care_of,
        Address: aadhaarDetails.message.address,
        CreatedBy: proxyUserDetails?.EmployeeId
        ? proxyUserDetails?.EmployeeId
        : userDetails?.Id
      });

      if(response.status === 0){
        let number: number = response?.data?.id;
        let width: number = 4;
        let paddedNumber: string = number.toString().padStart(width, '0');

        const res = await APICall(UpdateReferenceNoForPreBooking, "POST", {
          RefNo: 'WLL0000' + paddedNumber,
          Id : number,
        });

        setConfirmationNumber(paddedNumber);
        setShowSuccessModal(true);
        setDisableAutoFields(false);
      }
      hideLoader();
    }
  }

  const setAadharOTPFn = (event) => {
    const inputText = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const truncatedTextOTp = inputText.substring(0, 6);
    setAadharOTP(truncatedTextOTp); // add validation
  };

  const setAadharNoFn = (event) => {
    const inputText = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const truncatedText = inputText.substring(0, 12);
    setAadharNumberValue(truncatedText);
  };

  const verifyAadhaarOTP = async () => {

    let isValid = false;
    let errors: FormErrors = {};

    if (aadharOTP == "" || aadharOTP == null) {
      errors["OTPNO"] = "Please Enter OTP";
      isValid = false;
    } else{
      isValid = true;
    }

    setFormErrors(errors);

    if(isValid){
      const responseFromAadharOTP = await APICall(GetAadhaarDetails, "POST", {
        OTP: aadharOTP,
        RefId: refid,
      });
  
      if (responseFromAadharOTP?.status == 0 && responseFromAadharOTP?.data) {

        if(responseFromAadharOTP.data.message.message === 'OTP entered is invalid'){
          setIsOTPDisabled(true);
          errors["OTPNO"] = "The OTP you have entered is not valid";
          setFormErrors(errors);
          setAadharOTP('');
          setDisableAutoFields(false);
          setTimeout(() => {
            setShowOtpSection(false);
          }, 3000);

        } else{
          setAadhaarDetails(responseFromAadharOTP?.data);
        setValidatedOTP(true);
        setShowOtpSection(false);
        setAadharOTP('');
        setNameValue(responseFromAadharOTP?.data.message?.name);
        let dateString = responseFromAadharOTP.data.message?.dob;

        const isValidDate = moment(
        dateString,
        "DD-MM-YYYY",
        true
        ).isValid();

        if (isValidDate) {
        const parsedDate = moment(dateString, "DD-MM-YYYY");
        const formattedDate = parsedDate?.format("DD-MMM-YYYY");
        setdobValue(formattedDate);
        }

        let gender = [];
        if(responseFromAadharOTP.data.message.gender === 'F'){
          gender = genderOptions.find(x => x.code === 'Female');
        } else if(responseFromAadharOTP.data.message.gender === 'M'){
          gender = genderOptions.find(x => x.code === 'Male');
        } else if(responseFromAadharOTP.data.message.gender === 'O'){
          gender = genderOptions.find(x => x.code === 'Transgender');
        }
        setGenderValue(gender);
        setDisableAutoFields(true);
        }
      }
    }
  };

  const onResetData = () => {
    setAadharNumberValue('');
    setNameValue('');
    setdobValue(null);
    setGenderValue(null);
    setmobileNumberValue('');
    setvisitDateValue(null);
    setCaptchaText('');
    setFormErrors({});
  }

  return (
    <>
      <div className="form-main">
        <div className="page-title w-100">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <h4>Pre Booking For Recruitment</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-2 " id="blue-collar-pages">
        <div className="col-lg-12 mt-2">
            <div className="row">
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Enter Aadhar Number 
                  </label>
                  <sup>*</sup>
                  <div className="row">
                    <div className="col-9">
                  <InputForm
                      className="form-control"
                      placeholder="Enter Aadhar Number"
                      isDisabled={false}
                      textArea={false}
                      value={aadharNumberValue}
                      onChange={(event: any) => {
                        setAadharNoFn(event);
                      }}
                      />
                    </div>
                    <div className="col-3">
                      <button
                  onClick={() => verifyAadhaarNumber()}
                  disabled={false}
                  title="Validate AADHAR"
                  className="btn btn-filter-submit"
                >
                  {<i className="fa-solid fa-check"></i>}
                </button>
                    </div>
                  </div>
                  {formErrors["AADHARNO"] && (
                                  <p style={{ color: "red" }}>{formErrors["AADHARNO"]}</p>
                                )}
        <Modal
        show={showOtpSection}
        onHide={() => {
          setShowOtpSection(false)
          setAadharOTP('');
        }
        }
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <Modal.Header>
          <Modal.Title>{showOtpSection ? 'Enter OTP' : 'Enter Aadhaar'}</Modal.Title>
        </Modal.Header>
        {!showOtpSection && (
          <Modal.Body>
            <div className="col-md-6">
              <label className="col-form-label">Aadhaar Number is not Valid</label>
            </div>
          </Modal.Body>
        )}
        {showOtpSection && (
          <Modal.Body>
            <div className="col-md-6">
              <label className="col-form-label">Please Enter OTP</label>
              <sup></sup>
              <InputForm
                className="form-control"
                placeholder={"Please Enter OTP"}
                isDisabled={isOTPDisabled ? true : false}
                textArea={false}
                value={aadharOTP}
                onChange={(event) => setAadharOTPFn(event)}
                maxLength="255"
                type="Password"
              />
              {formErrors.OTPNO && (
                <p style={{ color: "red" }}>{formErrors.OTPNO}</p>
              )}
            </div>
          </Modal.Body>
        )}
        <Modal.Footer>
          {showOtpSection && (
            <button
            className="btn btn-secondary"
            style={{
              marginLeft: 5,
              backgroundColor: "#3335a3",
            }}
            onClick={verifyAadhaarOTP}
            >
            Validate
          </button>
            )}
          {/* {showOtpSection && (
            <button
              className="btn"
              style={{
                marginLeft: 5,
                color: "#2c2b7c !important",
                borderColor: "#ACACAC",
              }}
              onClick={() => {setDisableAutoFields(false);
                setValidatedOTP(true);
                setShowOtpSection(false);
                setNameValue('');
                setdobValue(null);
                setGenderValue(null);
                setAadharNumberValue('');
                setAadharOTP('');
              }}
            >
              Skip
            </button>
          )} */}
          <button
            className="btn btn-secondary"
            style={{
              marginLeft: 5,
              color: "black !important",
              borderColor: "#ACACAC",
            }}
            onClick={() => {
              setShowOtpSection(false);
              setAadharOTP('');
            }
          }
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <Modal.Header>
          <Modal.Title>Pre Booking Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="col-md-12">
              <label 
              className="col-form-label" 
              style={{
                fontSize: 'larger'
              }}>Congratulations, your pre booking process is completed
              Please note the below Reference number. You will need to provide this number at the gate for entry   
              <span style={{
                color: 'red'
              }}> WLL0000{confirmationNumber}</span>. 
              The date of visit is {moment(visitDateValue)?.format("DD-MMM-YYYY")}.</label>
            </div>
          </Modal.Body>
        <Modal.Footer>
        <button
            className="btn btn-secondary"
            style={{
              marginLeft: 5,
              backgroundColor: "#3335a3",
            }}
            onClick={() => {
              setShowSuccessModal(false);
              onResetData();
            }}
            >Ok</button>
        </Modal.Footer>
      </Modal>
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Enter Name 
                  </label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control"
                    placeholder="Enter Name"
                    isDisabled={disableAutoFields ? true : false}
                    textArea={false}
                    value={nameValue}
                    onChange={(event: any) => {
                      setNameValue(event.target.value);
                    }}
                  />
                  {formErrors["NAME"] && (
                                  <p style={{ color: "red" }}>{formErrors["NAME"]}</p>
                                )}
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">Enter Date of Birth</label>
                  <sup>*</sup>
                  <DateForm 
                    isDisabled={disableAutoFields ? true : false}
                    value={dobValue}
                    onChange={(event: any) => {
                      setdobValue(event);
                    }}
                  />
                  {formErrors["DOB"] && (
                                  <p style={{ color: "red" }}>{formErrors["DOB"]}</p>
                                )}
                </div>
              </div>
              
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Select Gender 
                  </label>
                  <sup>*</sup>
                  <SelectForm
                    isClearable
                    options={genderOptions}
                    placeholder="Select Gender"
                    onChange={(event: any) => {
                      setGenderValue(event);
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                    value={genderValue}
                    isDisabled={disableAutoFields ? true : false}
                  />
                  {formErrors["GEN"] && (
                                  <p style={{ color: "red" }}>{formErrors["GEN"]}</p>
                                )}
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Enter Mobile Number 
                  </label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control"
                    placeholder={"Enter Mobile Number "}
                    isDisabled={false}
                    textArea={false}
                    value={mobileNumberValue}
                    onChange={(event: any) => {
                      setMobileNoFn(event);
                    }}
                  />
                  {formErrors["MOB"] && (
                                  <p style={{ color: "red" }}>{formErrors["MOB"]}</p>
                                )}
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Enter Visit Date
                    </label><sup>*</sup>
                  <DateForm 
                    isDisabled={false}
                    value={visitDateValue}
                    //maxDate={new Date()}
                    minDate={new Date()}
                    onChange={(event: any) => {setvisitDateValue(event)}}
                  />
                  {formErrors["VISITDATE"] && (
                                  <p style={{ color: "red" }}>{formErrors["VISITDATE"]}</p>
                                )}
                </div>

              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Captcha 
                  </label>
                  <LoadCanvasTemplate 
                  style={{ color: "red" }}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Enter Captcha
                  </label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control"
                    placeholder={"Enter Captcha"}
                    isDisabled={false}
                    textArea={false}
                    value={captchaText}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      setCaptchaText(value);
                      if(value.length  === 7){
                        if (validateCaptcha(value, false)!==true) {
                          setFormErrors((err) => ({
                            "captcha": "Captcha Does Not Match!"
                          }));
                        } else{
                          setFormErrors((err) => ({
                            "captcha": ""
                          }));
                        }
                      }
                    }}
                  />
                  {formErrors["captcha"] && (
                                  <p style={{ color: "red" }}>{formErrors["captcha"]}</p>
                                )}
                </div>
              </div>
            </div>
            <div className="SectionSubmit clearfix mt-4">
              <button
                onClick={() => {
                  onResetData()
                }}
                disabled={false}
                className="btn btn-secondary float-right ml-2"
              >
                <i className="fa-solid fa-times2"></i> Clear
              </button>

              <button
                onClick={() => {
                  submit();
                }}
                disabled={validatedOTP ? false : true}
                className="btn btn-filter-submit float-right ml-2"
              >
                <i className="fa-solid fa-check mr-2"></i> Proceed
              </button>
            </div>
            
          </div>
      </div>
    </>
  );
};

export default PreBookingRecruitment;
