import React, { useContext, useEffect, useRef, useState } from "react";
import { CustomAccordian } from "./CustomAccordtion";
import { LoaderContext } from "../../../Helpers/Context/Context";
import { Tooltip } from "@mui/material";
import { FormFloating, Modal } from "react-bootstrap";
import { RCMPdfViewer } from "../../../Components/PDFViewerRCM/PDFViewerRCM";
import fileDownload from "js-file-download";
import { APICall } from "../../../Helpers/APICalls";
import InputForm from "../../../Components/InputForm/InputForm";
import notify from "../../../Helpers/ToastNotification";
import {
  base64ToArrayBuffer,
  getBase64FromFilePromise,
} from "../../../Helpers/Common";
import {
  DeleteAttachment,
  UploadSalaryNegoDataByCandidate,
  GetSavedSalaryNegoDocument,
} from "../../../Helpers/APIEndPoints/EndPoints";
import useUserContext from "../../../Helpers/ZustandStore/UserContextStore";

interface IDocument {
  AC?: string;
  Name?: string;
  Data?: string;
  IsValidFile?: boolean;
  DocId?: Number;
  Id?: number;
  RCM_Type?: string;
  RCM_Status?: string;
}

interface IFormErrors {
  lastThreeMonthsSalary?: string;
  currentLastSalarySlip?: string;
  appointmentLetter?: string;
  bonusLetterSlip?: string;
  remarks?: string;
}

interface IFormDetails {
  lastThreeMonthsSalary?: IDocument[];
  currentLastSalarySlip?: IDocument[];
  appointmentLetter?: IDocument[];
  bonusLetterSlip?: IDocument[];
  remarks?: string;
}

const SalaryNegotiation = ({
  disabled,
  MRFId,
  CandidateId,
  CandidateWorkflowTaskId,
  setCwtId = null,
  hideAccordian = false,
  setHasDocument = null,
}) => {
  const [formDetails, setFormDetails] = useState<IFormDetails>({});
  const [formErrors, setFormErrors] = useState<IFormErrors>({});

  const { rcm_showLoader, rcm_hideLoader } = useContext(LoaderContext);
  const { userDetails, currentRoleId }: any = useUserContext();
  const [hideRemarks, setHideRemarks] = useState(true);
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);

  async function handleFileChangeV2(
    event: any,
    key: string,
    AC: string,
    AN = "",
    extensions = ["pdf", "docx", "doc"]
  ) {
    try {
      let fileSize = 5;

      if (!isEmpty(formDetails[key]) && formDetails[key]?.length == fileSize) {
        notify(1, `Only ${fileSize} files are allowed.`);
        return;
      }

      const fileSizeLimitMB = 5;
      const file = event?.target?.files[0] ?? null;

      if (!file) {
        return;
      }

      const fileSizeBytes = fileSizeLimitMB * 1024 * 1024;
      if (file.size > fileSizeBytes) {
        notify(1, `File size cannot be greater than ${fileSizeLimitMB}MB.`);

        return;
      }

      const fileName = file?.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();

      if (!extensions.includes(fileExtension)) {
        notify(
          1,
          "Please upload the file with the following extensions: " +
            extensions.join(", ")
        );
        return;
      }

      if (!isEmpty(formDetails[key])) {
        const isSameFileExists = formDetails[key]?.some(
          (item) =>
            String(item?.Name).toLowerCase().trim() ==
            String(fileName).toLowerCase().trim()
        );

        if (isSameFileExists) {
          notify(1, "File already exists.");
          return;
        }
      }

      let base64: any = await getBase64FromFilePromise(file);
      base64 = String(base64).split("base64,")[1];

      const obj: IDocument = {
        AC: AC,
        Name: fileName?.trim(),
        Data: base64,
        IsValidFile: true,
        DocId: 0,
      };

      if (isEmpty(formDetails[key])) {
        setFormDetails((prev) => {
          return { ...prev, [key]: [obj] };
        });
      } else {
        setFormDetails((prev) => {
          return { ...prev, [key]: [...prev[key], obj] };
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      event.target.value = null;
    }
  }

  async function handleFileChange(
    event: any,
    key: string,
    AC: string,
    AN = "",
    extensions = ["pdf", "docx", "doc"]
  ) {
    try {
      let fileSize = 5;
      const fileSizeLimitMB = 5;
      const files = event?.target?.files ?? null;
      const fileSizeBytes = fileSizeLimitMB * 1024 * 1024;

      if (isEmpty(files)) {
        return;
      }

      //Checking Length
      if (!isEmpty(formDetails[key]) && formDetails[key]?.length == fileSize) {
        notify(1, `Only ${fileSize} files are allowed.`);
        return;
      }

      if (!isEmpty(formDetails[key]) && !isEmpty(files)) {
        const alreadyFileLength = formDetails[key]?.length ?? 0;
        const newLength = files?.length;
        const totalFilesLength = alreadyFileLength + newLength;

        if (totalFilesLength > fileSize) {
          notify(1, `Only ${fileSize} files are allowed.`);
          return;
        }
      }

      let arr = [];
      for (const file of files) {
        const fileName = file?.name;
        const fileExtension = fileName.split(".").pop().toLowerCase();

        // CHECKING FILE SIZE
        if (file.size > fileSizeBytes) {
          notify(
            1,
            `File ${fileName}, size  cannot be greater than ${fileSizeLimitMB}MB.`
          );
          continue;
        }

        //CHECKING FILE EXTENSTION
        if (!extensions.includes(fileExtension)) {
          notify(
            1,
            `Extenstion of the File: ${fileName} is invalid, valid extensions are :` +
              extensions.join(", ")
          );
          continue;
        }

        //CHECKING IF ALREADY EXISTS
        if (!isEmpty(formDetails[key])) {
          const isSameFileExists = formDetails[key]?.some(
            (item) =>
              String(item?.Name).toLowerCase().trim() ==
              String(fileName).toLowerCase().trim()
          );

          if (isSameFileExists) {
            notify(1, `File ${fileName} already exists.`);
            continue;
          }
        }

        let base64: any = await getBase64FromFilePromise(file);
        base64 = String(base64).split("base64,")[1];

        const obj: IDocument = {
          AC: AC,
          Name: fileName?.trim(),
          Data: base64,
          IsValidFile: true,
          DocId: 0,
        };

        arr.push(obj);
      }

      if (isEmpty(formDetails[key])) {
        setFormDetails((prev) => {
          return { ...prev, [key]: [...arr] };
        });
      } else {
        setFormDetails((prev) => {
          return { ...prev, [key]: [...prev[key], ...arr] };
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      event.target.value = null;
    }
  }
  useEffect(() => {
    const helper = async () => {
      rcm_showLoader();
      await getSavedSalaryNegoDocumentAsync().finally(() => {
        rcm_hideLoader();
      });
    };

    helper();
  }, []);

  useEffect(() => {
    const helper = () => {
      if (setHasDocument != null) {
        if (
          !isEmpty(formDetails?.appointmentLetter) ||
          !isEmpty(formDetails?.lastThreeMonthsSalary) ||
          !isEmpty(formDetails?.bonusLetterSlip)
        ) {
          setHasDocument(true);
        } else {
          setHasDocument(false);
        }
      }
    };

    helper();
  }, [formDetails]);

  const helper = () => {
    if (setHasDocument != null) {
      if (
        !isEmpty(formDetails?.appointmentLetter) ||
        !isEmpty(formDetails?.lastThreeMonthsSalary) ||
        !isEmpty(formDetails?.bonusLetterSlip)
      ) {
        setHasDocument(true);
      } else {
        setHasDocument(false);
      }
    }
  };

  async function getSavedSalaryNegoDocumentAsync() {
    try {
      setDisabledSubmitBtn(true);

      rcm_showLoader();
      const { status, message, data } = await APICall(
        GetSavedSalaryNegoDocument,
        "POST",
        {
          CandidateWorkflowTaskId: CandidateWorkflowTaskId,
          MRFId: MRFId,
          CandidateId: CandidateId,
        }
      );

      if (status == 0) {
        const { appointmentLetter, bonusLetterSlip, currentLastSalarySlip } =
          data;

        if (!isEmpty(appointmentLetter)) {
          let arr = GetFilesInOrder(appointmentLetter) ?? [];
          setFormDetails((prev) => {
            return { ...prev, appointmentLetter: arr };
          });
        }

        if (!isEmpty(bonusLetterSlip)) {
          let arr = GetFilesInOrder(bonusLetterSlip) ?? [];
          setFormDetails((prev) => {
            return { ...prev, bonusLetterSlip: arr };
          });
        }

        if (!isEmpty(currentLastSalarySlip)) {
          let arr = GetFilesInOrder(currentLastSalarySlip) ?? [];
          setFormDetails((prev) => {
            return { ...prev, lastThreeMonthsSalary: arr };
          });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      rcm_hideLoader();
      setDisabledSubmitBtn(false);
    }
  }

  const GetFilesInOrder = (files = []) => {
    try {
      const arr = files.map((file) => {
        return {
          AC: file?.ac,
          Name: file?.name,
          Data: file?.data,
          IsValidFile: true,
          DocId: file?.docId,
          Id: file?.id,
          RCM_Type: file?.rcM_Type,
          RCM_Status: "",
        };
      });

      return arr;
    } catch (error) {}
  };

  async function handleFileDelete(key, index) {
    try {
      const file: IDocument = formDetails[key][index];

      if (!file.Id) {
        setFormDetails((prev) => {
          return { ...prev, [key]: prev[key]?.filter((i, j) => j != index) };
        });
        return;
      }
      rcm_showLoader();
      const { status, message } = await APICall(DeleteAttachment, "POST", {
        Id: file.Id,
      });

      if (status == 0) {
        setFormDetails((prev) => {
          return { ...prev, [key]: prev[key]?.filter((i, j) => j != index) };
        });
        notify(0, "File deleted successfully.");
      } else {
        console.error("Error in deleting file", message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      rcm_hideLoader();
    }
  }

  function validationError() {
    try {
    } catch {}
  }

  async function handleSubmit() {
    try {
      const obj = {
        LastThreeMonthsSalary: isEmpty(formDetails?.lastThreeMonthsSalary)
          ? []
          : GetFilesForUpload(formDetails?.lastThreeMonthsSalary),
        AppointmentLetter: isEmpty(formDetails?.appointmentLetter)
          ? []
          : GetFilesForUpload(formDetails?.appointmentLetter),
        BonusLetterSlip: isEmpty(formDetails?.bonusLetterSlip)
          ? []
          : GetFilesForUpload(formDetails?.bonusLetterSlip),
        Remarks: formDetails?.remarks,
        UserId: userDetails?.Id ?? 0,
        CandidateWorkflowTaskId: CandidateWorkflowTaskId,
        MRFId: MRFId,
        CandidateId: CandidateId,
      };

      rcm_showLoader();
      setDisabledSubmitBtn(true);
      const { status, message, data } = await APICall(
        UploadSalaryNegoDataByCandidate,
        "POST",
        obj
      );

      if (status === 0) {
        setCwtId(0);
        notify(0, "Document Submitted Sucessfully");
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    } finally {
      rcm_hideLoader();
      setDisabledSubmitBtn(false);
    }
  }

  const GetFilesForUpload = (files = []) => {
    let arr = files.filter((file) => isEmpty(file.DocId));
    return arr;
  };

  return (
    <CustomAccordian
      header={"Document Collection"}
      disabled={disabled}
      submitCallback={undefined}
      clearFunc={undefined}
      hideBtn={true}
      defaultAccordianValue={true}
      hideAccordian={hideAccordian}
    >
      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-12 mobile-view top-m">
          <div className="form-group">
            <label className="col-form-label">
              Last Three Months Salary Slip
            </label>

            <FileComponent
              onChange={async (event) => {
                await handleFileChange(
                  event,
                  "lastThreeMonthsSalary",
                  "CandidateLastThreeMonthsSalary",
                  "Last Three Months Salary Slip"
                );
              }}
              id={"lastThreeMonthsSalary"}
              files={formDetails?.lastThreeMonthsSalary || null}
              handleFileDelete={handleFileDelete}
              showView={true}
              disabled={disabled}
              accept={".docx,.doc,.pdf"}
              viewName={"Last Three Months Salary Slip"}
              hideAccordian={hideAccordian}
            />
            <span style={{ color: "red" }}>
              {formErrors.lastThreeMonthsSalary}
            </span>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-sm-12 mobile-view top-m">
          <div className="form-group">
            <label className="col-form-label">
              Current/Last Appointment Letter
            </label>

            <FileComponent
              onChange={async (event) => {
                await handleFileChange(
                  event,
                  "appointmentLetter",
                  "CandidateAppointmentLetter",
                  "Current/Last Appointment Letter"
                );
              }}
              id={"appointmentLetter"}
              files={formDetails?.appointmentLetter}
              handleFileDelete={handleFileDelete}
              showView={true}
              disabled={disabled}
              accept={".docx,.doc,.pdf"}
              viewName={"Current/Last Appointment Letter"}
              hideAccordian={hideAccordian}
            />
            <span style={{ color: "red" }}>
              {formErrors?.appointmentLetter}
            </span>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-sm-12 mobile-view top-m">
          <div className="form-group">
            <label className="col-form-label">
              Additional Bonus/Incentive Letter, If any
            </label>
            <FileComponent
              onChange={async (event) => {
                await handleFileChange(
                  event,
                  "bonusLetterSlip",
                  "CandidateBonusLetterSlip",
                  "Additional Bonus/Incentive Letter"
                );
              }}
              id={"bonusLetterSlip"}
              files={formDetails?.bonusLetterSlip}
              handleFileDelete={handleFileDelete}
              showView={true}
              disabled={disabled}
              accept={".docx,.doc,.pdf"}
              viewName={"Additional Bonus/Incentive Letter"}
              hideAccordian={hideAccordian}
            />
            <span style={{ color: "red" }}>{formErrors?.bonusLetterSlip}</span>
          </div>
        </div>

        {!hideRemarks && (
          <>
            <div className="col-lg-6 col-md-6 col-sm-12 mobile-view top-m">
              <div className="form-group">
                <label className="col-form-label">Remarks</label>

                <InputForm
                  value={formDetails?.remarks}
                  placeholder={"Remarks"}
                  isDisabled={disabled}
                  textArea={true}
                  onChange={(event) => {
                    setFormDetails((prev) => {
                      return { ...prev, remarks: event.target.value };
                    });
                  }}
                  maxLength={500}
                  rows={5}
                />
                <span style={{ color: "red" }}>{formErrors?.remarks}</span>
              </div>
            </div>
          </>
        )}

        <div className="col-lg-12 d-flex justify-content-end">
          <div className="">
            {!disabled && (
              <button
                className="btn btn-primary"
                type="button"
                onClick={handleSubmit}
                disabled={disabledSubmitBtn || disabled}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </CustomAccordian>
  );
};

export default SalaryNegotiation;

const FileComponent = ({
  onChange,
  files,
  handleFileDelete,
  disabled = false,
  id,
  accept = "",
  showView = false,
  viewName = "",
  hideAccordian = false,
}) => {
  const fileRef = useRef();
  const [viewResume, setViewResume] = useState(false);
  const [base64ForDocument, setBase64ForDocument] = useState(null);
  const { rcm_showLoader, rcm_hideLoader } = useContext(LoaderContext);

  async function downloadFile(file) {
    try {
      const doc: IDocument = file;

      const fileName = doc?.Name || "";
      const base64 = doc?.Data;

      let arrayBuffer = await base64ToArrayBuffer(base64);
      var byteArray = new Uint8Array(arrayBuffer);

      let blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const newFile = new File([blob], "", {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        lastModified: new Date().getTime(),
      });

      fileDownload(newFile, fileName);
    } catch (error) {
      console.error(error);
    }
  }

  async function viewDocument(file) {
    try {
      const doc: IDocument = file;
      const fileExtension = doc.Name.split(".").pop().toLowerCase();

      if (fileExtension == "pdf") {
        setBase64ForDocument(doc.Data);
        setViewResume(true);
        return;
      }
    } catch (error) {
      console.error(error);
    } finally {
      rcm_hideLoader();
    }
  }

  const SingleFile = ({ file, index }) => {
    const fileName = file?.Name;
    const fileExtension = fileName.split(".").pop().toLowerCase();
    return (
      <>
        <li className="list mt-1">
          <div className="media" style={{ width: "60%" }}>
            <Tooltip title={file?.Name ?? ""}>
              <div className="media-body text-truncate">
                <span className="view-more">{file?.Name}</span>
              </div>
            </Tooltip>

            <div className="media-right ml-2">
              <i
                className="fa-solid fa-download"
                aria-hidden="true"
                onClick={async (event) => {
                  await downloadFile(file);
                }}
              ></i>
            </div>

            {showView && fileExtension == "pdf" && (
              <div className="media-right ml-2">
                <i
                  className="fa-solid fa-eye"
                  aria-hidden="true"
                  onClick={async (event) => {
                    await viewDocument(file);
                  }}
                ></i>
              </div>
            )}

            {!disabled && (
              <div className="media-right ml-2">
                <i
                  className="fa fa-trash"
                  aria-hidden="true"
                  onClick={async (event) => {
                    await handleFileDelete(id, index);
                  }}
                ></i>
              </div>
            )}
          </div>
        </li>
      </>
    );
  };

  return (
    <>
      <div className="box position-relative">
        <input
          id={id}
          className="form-control inputfile inputfile-6 multiple-inputfile"
          data-multiple-caption="{count} files selected"
          multiple={true}
          type="file"
          ref={fileRef}
          onChange={onChange}
          accept={accept}
          disabled={disabled}
        />
        <label
          htmlFor={id}
          className="form-control"
          style={{ width: 0, border: "none" }}
        >
          <strong
            style={{
              padding: "6px 16px",
              backgroundColor: "#3c5464",
              borderRadius: "5px",
            }}
          >
            {hideAccordian ? (
              <>
                <i className="fa fa-file rotate90 mr-2" aria-hidden="true" />
                Document
              </>
            ) : (
              <>
                <i className="fa fa-upload rotate90 mr-2" aria-hidden="true" />
                Upload
              </>
            )}
          </strong>
        </label>
      </div>
      <div className="file-added-list">
        <ul className="list-unstyle">
          {files && files?.length > 0 && (
            <>
              {files?.map((file, key) => {
                return (
                  <SingleFile
                    key={`${file?.name}_key`}
                    index={key}
                    file={file}
                  />
                );
              })}
            </>
          )}
        </ul>
      </div>

      <DocumentViewer
        modal={viewResume}
        setModal={setViewResume}
        header={viewName}
        base64={base64ForDocument}
        handleOkClick={() => {
          setViewResume(false);
          setBase64ForDocument(null);
        }}
      />
    </>
  );
};

export function isEmpty(value, acceptZero = false) {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value === "object") {
    // Check for empty object
    if (Object.keys(value).length === 0) {
      return true;
    }
    // Check for empty array
    if (Array.isArray(value) && value.length === 0) {
      return true;
    }
  }

  if (typeof value === "string") {
    // Check for empty string after trimming
    if (value.trim() === "" || (value.trim() === "0" && !acceptZero)) {
      return true;
    }
  }

  if (typeof value === "number") {
    // Check for zero or NaN
    if ((value === 0 && !acceptZero) || Number.isNaN(value)) {
      return true;
    }
  }

  return false;
}

const DocumentViewer = ({
  modal,
  setModal,
  header,
  base64 = "",
  handleOkClick,
}) => {
  return (
    <>
      <Modal
        show={modal}
        onHide={() => setModal(false)}
        backdrop="static"
        keyboard={false}
        size="xl"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">{header}</div>
        </div>
        <Modal.Body>
          <div
            className=""
            style={{
              height: "60vh",
              overflowX: "hidden",
            }}
          >
            <RCMPdfViewer pdfURL={base64 || ""} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn"
            style={{
              marginLeft: 5,
              color: "black !important",
              borderColor: "#acacac",
            }}
            onClick={() => {
              setModal(false);
            }}
          >
            Ok
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
