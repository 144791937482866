import React, { useState } from "react";
import DeclartionFormV2 from "./DeclartionForm";
import ResumeSection from "./ResumeSection";

const CandidateForm = ({
  MRFId = 0,
  CandidateId = 0,
  isIJP,
  isDisableApplication,
  source,
  isRecruiterEdit = false,
  setHideShowAddCandidateTabFunc = null,
  setActiveTabFunc = null,
}) => {
  const [isDeclartionFormSubmited, setDeclartionFormSubmited] = useState(false);
  const [declartionFormData, setDeclartionData] = useState({});

  // MRFId = 162;
  // CandidateId = 227;

  return (
    <>
      <DeclartionFormV2
        MRFId={MRFId}
        CandidateId={CandidateId}
        source={source}
        isIJP={isIJP || false}
        isDisableApplication={isDisableApplication || false}
        setDeclartionFormSubmited={setDeclartionFormSubmited}
        isDeclartionFormSubmited={isDeclartionFormSubmited}
        setDeclartionFormData={setDeclartionData}
      />

      <ResumeSection
        CandidateId={CandidateId}
        MRFId={MRFId}
        source={source}
        isDisableApplication={isDisableApplication}
        setDeclartionFormSubmited={setDeclartionFormSubmited}
        isDeclartionFormSubmited={isDeclartionFormSubmited}
        declartionFormData={declartionFormData}
        isRecruiterEdit={isRecruiterEdit}
        isIJP={isIJP || false}
        setHideShowAddCandidateTabFunc={setHideShowAddCandidateTabFunc}
        setActiveTabFunc={setActiveTabFunc}
      />
    </>
  );
};

export default CandidateForm;
