import React, { useEffect } from "react";

import $ from "jquery";
const CryptoJS = require("crypto-js");

async function SSORedirection(userDetails, application) {
  const applicationNameMapping = {
    Pro : 'ProPerform',
    Samay : 'Samay',
    Compliance : 'Compliance',
    HRTechTicketing : 'HRTool',
  };
  
  // let applicationName = application === "Pro" ? "ProPerform" : "Samay";
  let applicationName = applicationNameMapping[application];

  try {
    // Your encryption logic
    const key = CryptoJS.enc.Utf8.parse("4512631236589784");
    const iv = CryptoJS.enc.Utf8.parse("4512631236589784");
    const encryptedUser = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(userDetails?.EmployeeCode),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    // Your API request
    const USERNAME = "SamayWelProSSO";
    const PASSWORD = "$amayC*07!eCj";
    
    const response = await $.ajax({
      type: "GET",
      url: "https://app.welspun.com/webdata/api/Samay/GetToken",
      processData: true,
      crossDomain: true,
      headers: {
        Authorization: "Basic " + btoa(USERNAME + ":" + PASSWORD),
      },
      data: { EmployeeId: userDetails?.EmployeeCode },
    });

    // Handle the response
    // console.log(encryptedUser);
    // console.log(response);

    // Redirect to the specified URL
    window.open(
      `https://app.welspun.com/${applicationName}/AuthSSO/Login?EmployeeId=${encryptedUser.toString()}&Token=${response}`,
      "_blank"
    );
  } catch (error) {
    console.error("Error:", error);
  }
}
//   useEffect(() => {
//     debugger;

//     // Call the fetchData function
//     fetchData();
//   }, []); //   return <div></div>;

export default SSORedirection;
