import React, { useState, useEffect, useContext } from "react";
import DynamicGrid from "../DynamicGrid/DynamicGrid";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import BaseModal from "../BaseModel/BaseModel";
import FormStructure from "../FromStructure/FormStructure";
import SelectForm from "../SelectForm/SelectForm";
import "./ContractLabour.css";
import Collapse from "react-bootstrap/Collapse";

import { APICall } from "../../Helpers/APICalls";
import {
  getDashboardContractLabourDashboardGridData,
  getDropdowns,
  getDropdownsOUFilters,
  getEmployeeHistoryData,
  updateEmployeeStatus,
  updateEntryInActionHistoryForNewHire,
} from "../../Helpers/APIEndPoints/EndPoints";
import { Form } from "react-bootstrap";
import InputForm from "../InputForm/InputForm";
import { Tooltip } from "@mui/material";
import { LoaderContext } from "../../Helpers/Context/Context";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import notify from "../../Helpers/ToastNotification";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Stats } from "fs";
import moment from "moment";
import { useDebounce } from "../../utils/useDebounce";

const ContractLabour = (props) => {
  const [sectionData, setSectionData] = useState({});
  // const [tabs, setTabs] = useState([]);
  // const [activetab, setActivetab] = useState<string>(null);
  const [filteredData, setFilteredData] = useState([]);
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [rowData2, setRowData2] = useState([]);
  const [count2, setCount2] = useState(0);
  const [page2, setPage2] = useState(0);
  const [sortDirection2, setSortDirection2] = useState("");
  const [sortColumn2, setSortColumn2] = useState("");
  const [gridData, setGridData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [PageSize, setPageSize] = useState(10);
  const [UpdatedPageSize, setUpdatedPageSize] = useState(6);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  let { userDetails, proxyUserDetails,interfacePermissionRecord, currentRoleId }: any = useUserContext();
  const [sortDirection, setSortDirection] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [phyLoc, setPhyLoc] = useState<any>([]);
  const [bussiness, setBussiness] = useState<any>([]);
  const [sbuVal, setSbuVal] = useState<any>([]);
  const [legalEntity, setLegalEntity] = useState<any>([]);
  const [cubis, setCubis] = useState<any>([]);

  const [competency, setCompetency] = useState<any>([]);
  const [commonDdValue, setCommonDdValue] = useState<any>({});
  const [ddValue, setDdValue] = useState({});
  const [status, setStatus] = useState<any>({ label: "Active", value: 1 });

  const debouncedValue = useDebounce(searchText);
  let CNTRY = [
    { value: "India", code: "CODECNTRY1", label: "India",id:564},
    { value: "United States", code: "CODECNTRY7", label: "United States",id: 570},
    { value: "United Kingdom", code: "CODECNTRY51", label: "United Kingdom",id: 614 },
    // Added a new Country as per requirement shared by ankit, Raj Potdar, Date : 06-03-2024
    { value: "Saudi Arabia", code: "CODECNTRY6", label: "Saudi Arabia",id:569 },
  ];
  const [showModal, setShowModal] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [employeeName, setEmployeeName] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [draftAction, setDraftAction] = useState("");
  const [textAreaValue, setTextAreaValue] = useState("");
  const [formErrors, setFormErrors] = useState(false);
  const [storeTableMeta,setstoreTableMeta] = useState("");
  const user = proxyUserDetails != null ? proxyUserDetails : userDetails;
  const createdBy = user ? (proxyUserDetails ? user.value : user.Id) : null;
  let matchedCountry;
  let condition1 = (currentRoleId?.code === "HRHead" || currentRoleId?.code === "SystemAdmin");
  let condition2 = (currentRoleId?.code !== "HRHead"||currentRoleId?.code !=="SystemAdmin") && (currentRoleId?.oudetails?.cou || currentRoleId?.oudetails?.COU);
  let condition3;
  let condition4 = (currentRoleId?.oudetails?.cou?.length || currentRoleId?.oudetails?.COU?.length) ? (currentRoleId?.oudetails?.cou?.length || currentRoleId?.oudetails?.COU?.length) : 0
  if (condition1) {
    condition3 = condition1;
  } else {
      condition3 = (currentRoleId?.code !== "HRHead"||currentRoleId?.code !=="SystemAdmin") && (currentRoleId?.oudetails?.cou || currentRoleId?.oudetails?.COU);
  }

  let ouCountries = CNTRY.filter(country => 
    (currentRoleId?.oudetails?.cou 
      ? currentRoleId?.oudetails?.cou?.includes(country.id.toString()) 
      : currentRoleId?.oudetails?.COU?.includes(country.id.toString())
    )
  );

  console.log('ouCountries: ', ouCountries); //Comment after deployement

  useEffect(() => {
    getInterfaceDetails();
  }, []);

  const getDropdownsData = async (AC, PID = null, cb) => {
    let dropdownData;
    await showLoader();
    // if (AC === "BIS" || AC === "SBU" || AC === "LEN" || AC === "DV") {
    //   dropdownData = await APICall(getDropdownsOUFilters, "POST", {
    //     AC,
    //     UserID: userDetails.Id,
    //     RoleId: userDetails.UserRoles[0].RoleId,
    //     PID: null,
    //   });
    // } else
    // {
    dropdownData = await APICall(getDropdowns, "POST", {
      AC,
      PID,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });
    // }
    // debugger;
    if (AC === "CUBISDash") {
      //  setCubis(dropdownData.data);
      setDdValue((prev) => ({ ...prev, ["CUBIS"]: dropdownData.data }));
    } else {
      if (
        dropdownData.data !== null &&
        dropdownData.data !== undefined &&
        dropdownData.data.length > 0
      ) {
        setDdValue((prev) => ({ ...prev, [AC]: dropdownData.data }));
      } else {
        setDdValue((prev) => ({ ...prev, [AC]: [] }));
      }
    }

    //setCommonDdValue((prev)=>({...prev, [AC]:[]}));
    await hideLoader();
  };

  const getInterfaceDetails = async () => {
    showLoader();

    const gridDataResponse = await APICall(
      "/api/Interface/GetInterfaceDetailsForGrid",
      "POST",
      {
        MN: "EmployeeCentral",
        IN: "EC_Dashboard",
        TN: "GridDemo",
      }
    );

    if (
      gridDataResponse &&
      Object.keys(gridDataResponse).length > 0 &&
      gridDataResponse?.data?.d?.afa !== null
    ) {
      for await (const cFilter of gridDataResponse?.data?.d?.afa) {
        if (cFilter.filt === "multidropdown") {
          await getDropdownsData(cFilter.filAN, null, null);
        }
      }
    }
    await setGridData(gridDataResponse);
    await getGridStructureTwo();
    hideLoader();
  };

  useEffect(() => {
    if (gridData?.data?.d?.s[0]?.columnData.length > 0) {
      getGridStructureTwo();
    }
  }, [
    page,
    debouncedValue,
    sortDirection,
    sortColumn,
    //  legalEntity,
    //  bussiness,
    //  sbuVal,
    //  phyLoc,
    status,
    //empStatus,
  ]);
  const onSubmitFilter = () => {
    if (
      bussiness.length === 0 &&
      sbuVal.length === 0 &&
      legalEntity.length === 0 &&
      phyLoc.length === 0
    ) {
      notify(1, "Select atleast one filter");
    } else {
      setPage(0);
      if (gridData?.data?.d?.s[0]?.columnData.length > 0) {
        getGridStructureTwo();
      }
    }
  };

  const resetFilters = () => {
   
    setSearchText("");
    setBussiness([]);
    setSbuVal([]);
    setLegalEntity([]);
    setPhyLoc([]);
    setStatus([]);
    setCubis([]);
  };

  const getGridStructureTwo = async () => {
    showLoader();
    const GetDashboardData = await APICall(getDashboardContractLabourDashboardGridData, "POST", {
      DashboardName: "EmployeeDetails",
      Page: page + 1,
      PageSize: PageSize,
      SearchText: searchText,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      SortColumn: sortColumn,
      SortOrder: sortDirection,
      BIS: bussiness.map((s) => s.label).join(","),
      SBU: sbuVal.map((s) => s.label).join(","),
      LEN: legalEntity.map((s) => s.label).join(","),
      CUBIS: cubis.map((s) => s.label).join(","),
      PHLOC: phyLoc.map((s) => s.label).join(","),
      Status: status?.label?.toLowerCase(),
      CountryValue : condition1 ? "" : (condition2 ? ((currentRoleId?.oudetails?.cou || currentRoleId?.oudetails?.COU)?.map((s) => s).join(",")) : ""),
      // CountryValue: condition3 ? condition3 && currentRoleId?.oudetails?.COU?.map((s)=>s).join(","):"",
    });
    if (
      GetDashboardData &&
      GetDashboardData.data !== null &&
      GetDashboardData.data.length > 0
    ) {
      setCount(GetDashboardData.data[0].count);
      //
      setRowData(GetDashboardData.data);
    } else {
      setRowData([]);
      setCount(0);
    }
    hideLoader();
  };

  // useEffect(() => {
  //   (async () => {
  //     const tabs = await APICall("/api/Tabs/getTabs", "POST", {
  //       MN: "EmployeeCentral",
  //       IN: "Form",
  //     });

  //     if (tabs.data !== null && tabs.data.length > 0) {
  //       setTabs(tabs.data);
  //       setActivetab(tabs.data[0].TN);
  //     } else {
  //       setTabs([]);
  //     }
  //   })();
  // }, []);

  const view = (value, tableMeta) => {
    const url = value;
    // window.location.href = url;
    window.open(url);
  };

  const edit = (value, tableMeta, url) => {
    const TId = tableMeta.rowData[0];
    const NewHireStatus = tableMeta.rowData[11];
    const Country = tableMeta.rowData[12];
    const NewEmployeeStatus = tableMeta.rowData[18];
    const BID = tableMeta.rowData[19]; //Blue Colar Id Col in grid
    // navigate(url[1].editUrl);
    navigate("/clmprofile", {
      state: {
        TId: TId,
        COU: Country,
        CT: Country,
        NewHireStatus,
        NewEmployeeStatus,
        BID,
      },
    });
  };
  const handleModalClose = () => {
    setShowModal(false);
    setTextAreaValue("");
    setFormErrors(false);
  };
  const handleDeleteModalClose = async (data, empid, remark) => {
    showLoader();
    const UpdateEmployeeStatus = await APICall(updateEmployeeStatus, "POST", {
      Id: empid,
      employeestatus: data,
      Remarks: remark,
      CreatedBy: createdBy,
    });
    const response = UpdateEmployeeStatus.message;
    setShowModal(false);

    if (response) {
      getGridStructureTwo();
      //Entry in Action History
      const actHistObj = await APICall(
        updateEntryInActionHistoryForNewHire,
        "POST",
        {
          Status: data,
          Remarks: remark,
          IsDashboard: 1,
          RequestId: empid,
          CreatedBy: userDetails.Id,
        }
      );
      //---
      if (response === "draftdelete") {
        notify(0, "Record Deleted Successfully!");
        return;
      }
      if (response === "draftinactive") {
        notify(0, "Record Inactivated Successfully!");
        return;
      }
      if (response === "draftactive") {
        notify(0, "Record Activated Successfully!");
        return;
      }
    } else {
      console.error("API call failed");
    }
    hideLoader();
  };
  const handleShow = (tableMeta, draft) => {
    const name = tableMeta.rowData[2];
    const id = tableMeta.rowData[0];
    setEmployeeName(name);
    setEmployeeId(id);
    setDraftAction(draft);
    setShowModal(true);
  };

  const copy = (value, tableMeta) => {
    alert("copy");
  };

  const modal = (value, tableMeta) => {
    alert("modal");
  };

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: PageSize,
    page: page,
    serverSide: true,
    onChangePage: async (page) => {
      setPage(page);
    },
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",
    onColumnSortChange: async (sortColumn, sortDirection) => {
      setPage(0);
      if (sortDirection === "asc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
    },
  };
  //option which is from backend if needed we use it
  // const getOption = (attribute) => {
  //   const faData = data?.fa;
  //   if (faData && attribute.filn === "city") {
  //     return cityData.map((city) => ({ value: city.label, label: city.label }));
  //   } else {
  //     return [];
  //   }
  // };

  const statusLabel = status?.label || "Unknown";
  const columnsWithCustomRender = gridData?.data?.d?.s[0]?.columnData.map(
    (column) => {
      if (
         //Now Action button Open to all
      //   interfacePermissionRecord?.some(
      //     (e) => e.interfaceName === "Employee_NewHire" && (e.name === "View" || e.name === "Edit")
      // ) &&
        statusLabel === "Draft" &&
        currentRoleId.label === "System Admin" &&
        column.name === "action"
      ) {
        return {
          ...column,
          options: {
            ...column.options,
            customBodyRender: (value, tableMeta, updateValue) => {
              const draftStatus = tableMeta.rowData[18];
              const isactive = draftStatus === "Draft-Active";
              const newDraftStatus = tableMeta.rowData[18] === "Draft-Inactive";
              const iconClass = newDraftStatus
                ? "fas fa-eye mr-2"
                : "fas fa-edit mr-2";
              const handleButtonClick = () => {
                  // if (!(textAreaValue !== "" || draftAction === "draftactive"))
                  if (!(textAreaValue !== "")) {
                  setFormErrors(true);
                  notify(1, "please fill the required data");
                } else {
                  handleDeleteModalClose(
                    draftAction,
                    employeeId,
                    textAreaValue
                  );
                  setFormErrors(false);
                  setTextAreaValue("");
                }
              };
              return (
                <>
                  <div className="centerStyles">
                  <Tooltip title="Edit">
                      <i
                        style={{ marginRight: "0px", cursor: "pointer" }}
                        onClick={() => {
                          edit(value, tableMeta, column.url);
                        }}
                        className={iconClass}
                      ></i>
                    </Tooltip>
                    <Tooltip title="History">
                      <i
                        style={{ marginRight: "0px", cursor: "pointer" }}
                        onClick={() => {
                          setShowHistoryModal(true);
                          getHistoryData(tableMeta);
                        }}
                        className="fas fa-history"
                      ></i>
                    </Tooltip>
                          <Modal
                      className="customized-history-modal-modified"
                      show={showHistoryModal}
                      onHide={() => setShowHistoryModal(false)}
                      //size="xl"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      backdrop="static"
                      keyboard={false}
                    >
                      <Modal.Body>
                        <div>
                          <div className="row">
                            <div className="col-md-12">
                              <DynamicGrid
                                data={rowData2}
                                columns={columns2}
                                options={options2}
                              />
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          className="btn customButtonCancel"
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            setShowHistoryModal(false);
                            setstoreTableMeta("");
                          }}
                        >
                          Close
                        </button>
                      </Modal.Footer>
                    </Modal>
                    <Tooltip
                      title={isactive ? "Draft-InActive" : "Draft-Active"}
                    >
                      <i
                        style={{
                          fontSize: "large",
                          marginRight: "0px",
                          cursor: "pointer",
                          color: isactive ? "green": "red",
                        }}
                        onClick={() =>
                          handleShow(
                            tableMeta,
                            isactive ? "draftinactive" : "draftactive"
                          )
                        }
                        className="fas fa-circle mx-2"
                      ></i>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <i
                        style={{
                          fontSize: "large",
                          marginRight: "0px",
                          cursor: "pointer",
                          color: "red",
                        }}
                        onClick={() => {
                          handleShow(tableMeta, "draftdelete");
                        }}
                        className="fas fa-close"
                      ></i>
                    </Tooltip>
                    <Modal
                      show={showModal}
                      onHide={handleModalClose}
                      backdrop="static"
                      keyboard={false}
                      size="sm"
                      centered
                    >
                      <Modal.Body>
                        <p className="col-form-label">
                          Employee Name : {employeeName}
                        </p>
                        {/* <p className="col-form-label">
                          Employee ID : {employeeId}
                        </p> */}
                        <p className="col-form-label">
                          {draftAction === "draftdelete"
                            ? "Are you sure you want to delete the record?"
                            : draftAction === "draftinactive"
                            ? "Are you sure you want to inactivate the record?"
                            : draftAction === "draftactive"
                            ? "Are you sure you want to activate the record?"
                            : ""}
                          {draftAction === "draftdelete" ||
                           draftAction === "draftinactive" ||
                           draftAction === "draftactive" ? (
                            <span className="modified-asterick">*</span>
                          ) : null}
                        </p>
                        {(draftAction === "draftdelete" ||
                          draftAction === "draftinactive"||
                          draftAction === "draftactive") && (
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder={
                              draftAction === "draftdelete"
                              ? "Deletion Reason/Remark"
                              : draftAction === "draftinactive"
                              ? "Inactivation Reason/Remark"
                              : draftAction === "draftactive"
                              ? "Activation Reason/Remark"
                              :""
                            }
                            onChange={(e) => {
                              setTextAreaValue(e.target.value);
                            }}
                          />
                        )}
                        {formErrors && (
                          <p className="modified-asterick">Required</p>
                        )}
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          className="btn customButtonCancel"
                          style={{ marginLeft: 5 }}
                          onClick={handleModalClose}
                        >
                          Cancel
                        </button>
                        <button
                          // disabled={
                          //   !(
                          //     textAreaValue !== "" ||
                          //     draftAction === "draftactive"
                          //   )
                          // }
                          className="btn customButtonOk"
                          style={{ marginLeft: 5 }}
                          onClick={handleButtonClick}
                        >
                          Ok
                        </button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </>
              );
            },
          },
        };
      }
      //Now Action button Open to all
    //   if (interfacePermissionRecord?.some(
    //     (e) => e.interfaceName === "Employee_NewHire" && (e.name === "View" || e.name === "Edit")

    // )  &&
    if( column.name === "action"){
        return {
          ...column,
          options: {
            ...column.options,
            customBodyRender: (value, tableMeta, updateValue) => {
              const newDraftStatus = tableMeta.rowData[18] === "Draft-Inactive";
              const iconClass = newDraftStatus
                ? "fas fa-eye mr-2"
                : "fas fa-edit mr-2";
              return (
                <>
                  <div className="centerStyles">
                    <Tooltip title="Edit">
                      <i
                        style={{ marginRight: "0px", cursor: "pointer" }}
                        onClick={() => {
                          edit(value, tableMeta, column.url);
                        }}
                        className={iconClass}
                      ></i>
                    </Tooltip>
                    <Tooltip title="History">
                      <i
                        style={{ marginRight: "0px", cursor: "pointer" }}
                        onClick={() => {
                          setShowHistoryModal(true);
                          getHistoryData(tableMeta);
                        }}
                        className="fas fa-history"
                      ></i>
                    </Tooltip>
                          <Modal
                              className="customized-history-modal-modified"
                      show={showHistoryModal}
                      onHide={() => setShowHistoryModal(false)}
                      //size="xl"
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      backdrop="static"
                      keyboard={false}
                    >
                      <Modal.Body>
                        <div>
                          <div className="row">
                            <div className="col-md-12">
                              <DynamicGrid
                                data={rowData2}
                                columns={columns2}
                                options={options2}
                              />
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          className="btn customButtonCancel"
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            setShowHistoryModal(false);
                            setstoreTableMeta("");
                            setPage2(0);
                          }}
                        >
                          Close
                        </button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </>
              );
            },
          },
        };
      }
      if (statusLabel === "Draft") {
        if (
          column.name === "cubis" ||
          column.name === "bussinessEmail" ||
          column.name === "employeeCode"
        ) {
          return {
            ...column,
            options: {
              ...column.options,
              display: false,
            },
          };
        }
      }

      if (
        statusLabel === "Draft" && //column.name === "personalemail" ||
        (column.name === "personalemail" ||
          column.name === "ploc" ||
          column.name === "lastcreatedBy" ||
          column.name === "lastModifiedBy" ||
          column.name === "lastModifiedOn" ||
          column.name === "createdByUtc")
      ) {
        return {
          ...column,
          options: {
            ...column.options,
            display: true,
          },
        };
      }
      if (statusLabel === "Draft" && column.name === "employeestatus") {
        // console.log(column);
        return {
          ...column,
          options: {
            ...column.options,
            display: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              const draftstatus = tableMeta.rowData[18];
              const statusColor = draftstatus === "Draft-Active";
              return (
                <span
                // style={{
                //   color: statusColor ? "green" : "",
                // }}
                >
                  {value}
                </span>
              );
            },
          },
        };
      }
       //Now Action button Open to all
    //   if( interfacePermissionRecord?.some(
    //     (e) => e.interfaceName !== "Employee_NewHire"
    // ) &&  column.name === "action")
    //   {
    //     return {
    //       ...column,
    //       options: {
    //         ...column.options,
    //         display: false,
    //       },
    //     };
    //   }
      return column;
    }
  );

  const getHistoryData = async (tableMeta) => {
    showLoader();
    setstoreTableMeta(tableMeta);
    const RequestId = tableMeta?.rowData?.[0];
    try {
      const GetDashboardDataTwo = await APICall(
        getEmployeeHistoryData,
        "POST",
        {
          EId: RequestId,
          sortColumn: sortColumn2,
          sortOrder: sortDirection2,
          pageNumber: page2,
          PageSize: UpdatedPageSize,
        }
      );

      if (GetDashboardDataTwo.data && GetDashboardDataTwo.data.length > 0) {
        GetDashboardDataTwo.data.map((item) => {
          let modifiedDateUTC = item?.modifiedDate;
          const dateRS = moment.utc(modifiedDateUTC).local();
          const formattedDateRS = moment(dateRS).format("DD MMM YYYY");
          item.modifiedDate = formattedDateRS;

          const dateAo = moment.utc(modifiedDateUTC).local();
          const formattedDateAO = moment(dateAo).format("hh:mm A");
          item.modifiedTime = formattedDateAO;

          const dateED = moment.utc(item?.effectiveDate).local();
          //const formattedDateED = moment(dateED).format("DD MMM YYYY hh:mm A");
          const formattedDateED = moment(dateED).format("DD MMM YYYY");
          item.effectiveDate = formattedDateED;
        });

        setRowData2(GetDashboardDataTwo.data);
        setCount2(GetDashboardDataTwo.data[0].count);
      } else {
        setRowData2([]);
        //setRowInx(0);
      }
    } catch (error) {
      console.error("Error fetching grid data:", error);
      hideLoader();
    }
    hideLoader();

    //setShowFormModal(true);
  };

  const options2 = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count2,
    rowsPerPage: UpdatedPageSize,
    //page: page2,
    serverSide: true,
    onChangePage: async (page) => {
      setPage2(page);
    },
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",
    onColumnSortChange: async (sortColumn2, sortDirection2) => {
      setPage2(0);
      if (sortDirection2 === "asc") {
        await setSortColumn2(sortColumn2);
        await setSortDirection2(sortDirection2);
      }
      if (sortDirection === "desc") {
        await setSortColumn2(sortColumn2);
        await setSortDirection2(sortDirection2);
      }
    },
  };

  const columns2 = [
    { name: "id", label: "id", options: { display: false } }, // 0
    {
      name: "modifiedDate", //1
      label: "Modified Date",
        options: { display: true, sort: false },
    },
    {
      name: "modifiedTime", //2
      label: "Modified Time",
        options: { display: true, sort: false },
    },
    {
      name: "event", //3
      label: "Event",
      options: { display: true, sort: false },
    },
      { name: "eventReason", label: "Event Reason", options: { display: true, sort: false } }, //4
    {
      name: "section", //5
      label: "Sections",
      options: { display: true, sort: false },
    },
      { name: "status", label: "Status", options: { display: true, sort: false } }, //6
    {
      name: "modifiedByUser", //7
      label: "Modified By",
      options: { display: true, sort: false },
    },
    {
      name: "effectiveDate",
      label: "Effective Date",
        options: { display: true, sort: false },
    }, //8
    {
      name: "remarks", //7
      label: "Remarks",
      options: { display: true, sort: false },
    },
    // {
    //   name: "action", //10
    //   label: "Action",
    //   options: {
    //     sort: false,
    //     filter: false,
    //     customBodyRender: (value, tableMeta) => {
    //       return (
    //         <>
    //           <Tooltip title="Edit">
    //             <i
    //               style={{ marginRight: "0px", cursor: "pointer" }}
    //               onClick={() => {
    //                 edit2(value, tableMeta);
    //                 setIsEdit(true);

    //                 setEId(tableMeta.rowData[0]);
    //               }}
    //               className="fas fa-edit"
    //             ></i>
    //           </Tooltip>
    //         </>
    //       );
    //     },
    //   },
    // },
      { name: "rowIndex", label: "roxInx", options: { display: false, sort: false } }, //11
  ];

  const onFilterChange = (event, type) => {
    if (type === "city") {
      const selectedCityLabel = event?.label;
      if (selectedCityLabel) {
        const newData = gridData?.data.d?.s[0]?.rowData.filter(
          (item) => item.city === selectedCityLabel
        );
        setFilteredData(newData);
      } else {
        setFilteredData(gridData?.data?.s[0]?.rowData);
      }
    }
    if (type === "BIS") {
      setBussiness(event);
      const commaSeparatedBISIds =
        event && event.length > 0 && event.map((e) => e.value).join(",");

      //cascading
      if (commaSeparatedBISIds) {
        getDropdownsData("SBU", commaSeparatedBISIds, null);
      }
      //reset cascading
      setSbuVal([]);
      setLegalEntity([]);
      setCubis([]);
    }
    if (type === "SBU") {
      setSbuVal(event);
      //cascading
      const commaSeparatedSBUIds =
        event && event.length > 0 && event.map((e) => e.value).join(",");
      if (commaSeparatedSBUIds) {
        getDropdownsData("LEN", commaSeparatedSBUIds, null);
      }
      //reset cascading
      setLegalEntity([]);
      setCubis([]);
    }
    if (type === "LEN") {
      //  let PId = `${},${SBU},${LEN}`;
      setLegalEntity(event);
      
      let BIS = bussiness.map((e) => e.value).join(",");
      let SBU = sbuVal.map((e) => e.value).join(",");
      let LEN = event.map((e) => e.value).join(",");

      let PId = `${BIS}-${SBU}-${LEN}`;
      if (PId) {
        getDropdownsData("CUBISDash", PId, null);
      }
      //reset cascading
      setCubis([]);
    }
    if (type === "CUBIS") {
      setCubis(event);
    }
    if (type === "PHLOC") {
      setPhyLoc(event);
    }
    if (type === "Status") {
      setPage(0);
      setStatus(event);
    }
    // if (type === "EMPSTATUSTEST") {
    //   setPage(0);
    //   setEmpStatus(event);
    // }
  };

  const popover = (
    <Popover className="custom-popover">
      <Popover.Body>
        <ul>
          {
            condition2 && condition4 > 0 && 

            //New Condition
            ouCountries.map(country => (
              <li key={country.code}>
                <div style={{ display: "inline-block" }}>
                  <a
                    style={{
                      textDecoration: "none",
                    }}
                    onClick={(event) => {
                    
                      navigate("/profile", {
                        state: { TId: 0, COU: country.code },
                      });
                    }}
                  >
                    <span>{country.label}</span>
                  </a>
                </div>
              </li>
            ))

              //Old Condition
              //  (currentRoleId?.oudetails?.cou || currentRoleId?.oudetails?.COU)?.map(countryIdString => {
              //     const countryId = parseInt(countryIdString);
              //     console.log('countryId::::', countryId);
              //     matchedCountry = CNTRY.find(country => country.id === countryId);
              //     console.log('matchedCountry::::', matchedCountry);
              //     return matchedCountry && (
              //       <li key={matchedCountry.code}>
              //         <div style={{ display: "inline-block" }}>
              //           <a
              //             style={{
              //               textDecoration: "none",
              //             }}
              //             onClick={(event) => {
              //               console.log(event);
              //               console.log('matchedCountry::::', matchedCountry);
              //               navigate("/profile", {
              //                 state: { TId: 0, COU: matchedCountry.code },
              //               });
              //             }}
              //           >
              //             <span>{matchedCountry.label}</span>
              //           </a>
              //         </div>
              //       </li>
              //     );
              //   })
          }
          {(condition1 || condition4 === 0) && CNTRY.map(country => (
              <li key={country.code}>
                <div style={{ display: "inline-block" }}>
                  <a
                    style={{
                      textDecoration: "none",
                    }}
                    onClick={(event) => {
                    
                      navigate("/profile", {
                        state: { TId: 0, COU: country.code },
                      });
                    }}
                  >
                    <span>{country.label}</span>
                  </a>
                </div>
              </li>
            ))
          }
        </ul>
      </Popover.Body>
    </Popover>
  );
   //console.log("user",user)
    useEffect(()=>{
      if(storeTableMeta !== '')getHistoryData(storeTableMeta);
 },[page2])
  return (
    <div className="container-fluid">
      <div className="d-flex align-items-center justify-content-start my-3">
        {/* {props.permissionName === "Edit" && (
            <>
            </>
          // <button
          //   onClick={() => {
          //     // navigate("/profile", { state: { TId: 0 , COU:"CODECNTRY1"} });
          //   }}
          //   className="btn btn-primary mt-3">
          //   <i className="fa fa-plus mr-2"></i>
          //   New Add
          // </button>
        //   <div className="">
        //   {interfacePermissionRecord?.some(
        //     (e) => e.interfaceName === "Employee_NewHire" &&  (e.name === "View" || e.name === "Edit")
        // ) && (
            
        //   <ul className="right-head float-right ">
        //     <li>
        //       <OverlayTrigger
        //         trigger="click"
        //         placement="bottom"
        //         overlay={popover}
        //         rootClose
        //       >
        //         <div
        //           className="btn btn-primary mt-2"
        //           style={{ cursor: "pointer" }}
        //         >
        //           <div data-toggle="dropdown">
        //             <a
        //               className="dropdown-toggleec text-white"
        //               role="button"
        //               id="dropdownMenuLink"
        //               data-toggle="dropdown"
        //               aria-haspopup="true"
        //               aria-expanded="false"
        //             >
        //               <span className="">
        //                 {" "}
        //                 <i className="fa fa-plus mr-2"></i> New Hire{" "}
        //               </span>
        //             </a>
        //           </div>
        //         </div>
        //       </OverlayTrigger>
        //     </li>
        //   </ul>
       
        // )}
        //    </div>
        )} */}
        <button
              className="btn btn-primary mr-2"
              onClick={() => {
                  navigate(-1);
                }
              }
            >
              <i className="fa-solid fa-arrow-left mr-2"></i>
              Back
            </button>
      </div>

      {/* <div className="row">
        <div className="col-lg-3 col-md-3 col-sm-12">
          <div className="">
            <label></label>
            <input/>
          </div>
        </div>

        <div className="col-lg-3 col-md-3 col-sm-12">
          <div className="">
            <label></label>
            <input/>
          </div>
        </div>
      </div> */}
      <div className="grid-wrapper grid_mob">
        <div className="row mx-auto filtBox">
          {gridData &&
            gridData?.data?.d?.fa.map((cFilter, index) => (
              <>
                {cFilter.filt === "multidropdown" ? (
                  <>
                    <div className="col-lg-3 col-md-4 col-sm-12">
                      <div className="form-group filtInp">
                        <span
                          className="mr-2 text-white"
                          style={{ float: "left" }}
                        >
                          <label className="col-form-label">
                            {cFilter.filn}
                          </label>
                        </span>
                        <SelectForm
                          isClearable
                          options={
                            // cFilter.filAN === "Status"
                            [
                              { label: "Active", value: 1 },
                              { label: "Inactive", value: 0 },
                              { label: "Draft", value: 2 },
                            ]
                          }
                          placeholder={cFilter.filn}
                          isDisabled={false}
                          onChange={(event) => {
                            // cFilter.filAN === "Status"
                            onFilterChange(event, "Status");
                            //: null;

                            //onFilterChange(event, cFilter.filn);
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={cFilter.filAN === "Status" ? status : null}
                        />
                      </div>
                    </div>
                  </>
                ) : cFilter.filt === "text" ? (
                  <div className="col-lg-3 col-md-3 col-sm-12">
                    <div className="form-group filtInp">
                      <InputForm
                        value={searchText}
                        placeholder={cFilter.filn}
                        isDisabled={false}
                        textArea={false}
                        onChange={(e) => {
                          setPage(0);
                          setSearchText(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                ) : null}
              </>
            ))}
          <div className="col-lg-1 col-md-1 col-sm-12 filtrt_icon">
            <ul className=" filter-icon-wel">
              <li>
                <a
                  href="javascript:void(0);"
                  onClick={() => setOpen(!open)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                  className="filter-btn"
                >
                  <i className="fa fa-filter"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="col-lg-12 col-md-10 col-sm-12 ">
        {/* <div className="tabBtn">
              <ul>
                {tabs.length > 0 &&
                  tabs.map((eachTab, index) => (
                    <li key={`${index}-${eachTab.TN}`}>
                      <button
                        onClick={() => setActivetab(eachTab.TN)}
                        className={
                          eachTab.TN === activetab ? "bttn active" : "bttn"
                        }
                      >
                        {eachTab.TDN}
                      </button>
                    </li>
                  ))}
              </ul>
            </div>  */}
      </div>
      <>
        {/* <div className="filters grid-wrapper">
        <div className="d-flex justify-content-end">
          {gridData &&
            gridData?.data?.d?.fa.map((cFilter, index) => (
              <div className="row">
                {cFilter.filt === "dropdown" ? (
                  <>
                    <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12 ml-0 mobile-view top-m">
                      <div className="form-group">
                        <span
                          className="mr-2 text-white"
                          style={{ float: "left" }}
                        >
                          <label className="col-form-label">
                            {cFilter.filn}
                          </label>
                        </span>
                        <SelectForm
                          isClearable
                          options={[]}
                          // options={getOption(cFilter)}
                          placeholder={cFilter.filn}
                          isDisabled={false}
                          value={
                            sectionData[cFilter.code] !== undefined
                              ? {
                                  value: sectionData[cFilter.code],
                                  label: sectionData[cFilter.code],
                                }
                              : null
                          }
                          onChange={(selectedCity) => {
                            const newSectionData = {
                              ...sectionData,
                              [cFilter.code]: selectedCity
                                ? selectedCity.value
                                : null,
                            };
                            setSectionData(newSectionData);
                            onFilterChange(selectedCity, cFilter.filn);
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                        />
                      </div>
                    </div>
                  </>
                ) : cFilter.filt === "text" ? (
                  <div className="col-lg-3 col-md-4 col-sm-12 col-xs-10">
                    <div className="mobile-view top-m  searchInput">
                      <div className="form-group">
                        <InputForm
                          value={searchText}
                          placeholder={cFilter.filn}
                          isDisabled={false}
                          textArea={false}
                          onChange={(e) => {
                            setSearchText(e.target.value);
                          }}
                        />
                      </div>
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </div>
                  </div>
                ) : null}
              </div>
            ))}

          <ul className="float-right filter-icon-wel ml-2">
            <li>
              <a
                href="javascript:void(0);"
                onClick={() => setOpen(!open)}
                aria-controls="example-collapse-text"
                aria-expanded={open}
                className="filter-btn"
              >
                <i className="fa fa-filter"></i>
              </a>
            </li>
          </ul>
        </div>
      </div> */}
      </>
      <>
        {/* <Collapse in={open}>
          <div id="example-collapse-text grid-wrapper">
            <div className="row mx-auto myInlineStyle">
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="form-group">
                  <InputForm
                    value={null}
                    placeholder={"Search"}
                    isDisabled={false}
                    textArea={false}
                    onChange={(e) => {}}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="form-group">
                  <InputForm
                    value={""}
                    placeholder={"Surname"}
                    isDisabled={false}
                    textArea={false}
                    onChange={(e) => {
                      {
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Collapse> */}
      </>
      <>
        <Collapse in={open}>
          <div id="example-collapse-text grid-wrapper">
            <div className="row mx-auto myInlineStyle">
              {gridData &&
                gridData?.data?.d?.afa.map((cFilter, index) => (
                  <>
                    {cFilter.filt === "multidropdown" ? (
                      <>
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                          <label className="col-form-label text-white">
                            {cFilter.filn}
                          </label>
                          <SelectForm
                            isClearable
                            options={ddValue[cFilter.filAN]}
                            placeholder={cFilter.filn}
                            isDisabled={false}
                            onChange={(event) => {
                              onFilterChange(event, cFilter.filAN);
                              //onFilterChange(event, cFilter.filn);
                            }}
                            isMulti={true}
                            noIndicator={false}
                            noSeparator={false}
                            value={
                              cFilter.filAN === "BIS"
                                ? bussiness
                                : cFilter.filAN === "SBU"
                                ? sbuVal
                                : cFilter.filAN === "LEN"
                                ? legalEntity
                                : cFilter.filAN === "CUBIS"
                                ? cubis
                                : cFilter.filAN === "PHLOC"
                                ? phyLoc
                                : null
                            }
                          />
                        </div>
                      </>
                    ) : null}
                  </>
                ))}
              <div className="col-lg-12 d-flex mt-2 justify-content-end">
                <button
                  onClick={resetFilters}
                  className="btn btn-secondary mr-2"
                >
                  <i className="fa fa-undo" aria-hidden="true"></i> Reset{" "}
                </button>
                <button
                  className="btn btn-filter-submit"
                  onClick={onSubmitFilter}
                >
                  <i className="fa fa-check" aria-hidden="true"></i> Submit{" "}
                </button>
              </div>
            </div>
          </div>
        </Collapse>
      </>
      {}
      <div className="mb-3 pt-3">
        <DynamicGrid
          data={rowData}
          columns={columnsWithCustomRender}
          options={options}
        />
      </div>
    </div>
  );
};

export default ContractLabour;
