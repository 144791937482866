import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Badge, Card, ProgressBar } from "react-bootstrap";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { APICall } from "../../Helpers/APICalls";
import {
  GetCalendarData,
  GetMyCourseWishlist,
  GetMyJourneyCatalogueData,
  GetUpcomingCatalogueData,
  ManageWishListDetails,
  getHistoricDataDasboardDetails,
  getUserAssessments,
  getUserCertificateDashboardData,
  getUserFeedbackDashboardData,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import Badges from "@mui/material/Badge";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import moment from "moment";
import "./LMSHome.css";
import ImageColumnComponent from "../../Components/LMS/ImageColumnComponent/ImageColumnComponent";
import { useNavigate } from "react-router-dom";
import { Rating, SvgIcon, Tooltip } from "@mui/material";
import Carousel from "nuka-carousel";
import { LoaderContext } from "../../Helpers/Context/Context";
import notify from "../../Helpers/ToastNotification";
import CalendarSvg from "../../Assets/Images/Iconakar-calendar.svg";
import VideoSvg from "../../Assets/Images/Iconakar-video.svg";
import ReviewsOutlinedIcon from "@mui/icons-material/ReviewsOutlined";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
const UpComingCarousel = (props) => {
  const {
    upComingDataChange,
    managerId,
    selectedRadio,
    selectedUserId,
    selectedDate,
  } = props;
  const { showLoader, hideLoader } = useContext(LoaderContext);
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  const [upComingData, setUpComingData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(3);
  const [totalRecords, setTotalRecords] = useState(0);
  const navigate = useNavigate();

  const getUpComingData = async (userId) => {
    showLoader();
    const res = await APICall(GetUpcomingCatalogueData, "POST", {
      UserId: userId,
      RoleId: currentRoleId.value,
      Offset: currentPage,
      PageSize: itemsPerPage,
      IsManager: selectedRadio === "team" && managerId > 0 ? true : false,
      SelectedDate: selectedDate
        ? moment(selectedDate).format("DD-MMM-YYYY")
        : null,
      IsAdmin:
        currentRoleId?.code == "LMSAdmin" ||
        currentRoleId.label.toLowerCase() === "lm admin" ||
        currentRoleId.label.toLowerCase() === "lm super admin" ||
        currentRoleId?.code == "LMSSuperAdmin" ||
        currentRoleId?.code == "SystemAdmin" ||
        currentRoleId.label.toLowerCase() === "system admin"
          ? true
          : false,
    });

    if (res.status === 0 && res.data.length > 0) {
      const upComingData = res.data;
      setUpComingData(upComingData);
      setTotalRecords(upComingData[0].totalCount);
    } else {
      setUpComingData([]);
      setTotalRecords(0);
    }
    hideLoader();
  };

  useEffect(() => {
    if (upComingDataChange) {
      getUpComingData(upComingDataChange);
    }
  }, [upComingDataChange, currentPage, selectedDate]);

  const handleNext = () => {
    // if (currentPage < Math.ceil(totalRecords / itemsPerPage) - 1) {
    setCurrentPage(currentPage + 1);
    // }
  };

  const handlePrev = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleWishListFormChange = async (
    programId,
    courseScheduleId,
    isProgramLaunch,
    newValue
  ) => {
    const parameters = {
      ProgramId: isProgramLaunch ? programId : 0,
      CourseScheduleId: isProgramLaunch ? 0 : courseScheduleId,
      IsWishlist: newValue === 1 ? true : false,
      UserId: upComingDataChange,
    };

    showLoader();
    const res = await APICall(ManageWishListDetails, "POST", parameters);

    if (res.status === 0) {
      notify(res.status, "Course has been added to the wishlist successfully.");
      getUpComingData(upComingDataChange);
    } else if (res.status === 1) {
      notify(res.status, res.message);
    }
    hideLoader();
  };

  return (
    <div>
      {upComingData.length > 0 ? (
        <Carousel
          animation="fade"
          slidesToShow={itemsPerPage}
          slidesToScroll={1}
          wrapAround={false}
          disableEdgeSwiping
          renderBottomCenterControls={null}
          afterSlide={(newIndex) => setCurrentPage(newIndex)}
          renderCenterLeftControls={({ previousSlide }) => (
            <>
              {totalRecords > itemsPerPage && currentPage !== 0 ? (
                <button
                  className="btn btn-outline-dark arrow_set"
                  onClick={handlePrev}
                  disabled={currentPage === 0}>
                  <i className="fa fa-circle-arrow-left" aria-hidden="true"></i>
                </button>
              ) : null}
            </>
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <>
              {totalRecords > itemsPerPage &&
              currentPage + itemsPerPage !== totalRecords ? (
                <button
                  className="btn btn-outline-dark arrow_set"
                  onClick={handleNext}
                  disabled={
                    // currentPage === Math.ceil(totalRecords / itemsPerPage) - 1
                    currentPage + itemsPerPage === totalRecords ? true : false
                  }>
                  <i
                    className="fa fa-circle-arrow-right"
                    aria-hidden="true"></i>
                </button>
              ) : null}
            </>
          )}>
          {upComingData &&
            upComingData.length > 0 &&
            upComingData.map((data) => (
              <div key={data} className="SlideCont learn_dashb">
                <div className="slide_bg">
                  <div className="vidImg">
                    <ImageColumnComponent
                      link={data.thumbnailUrl}
                      width="auto"
                    />
                    {data.isLaunched && (
                      <Rating
                        name="size-large"
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "20px",
                        }}
                        value={data?.isWishlist ? 1 : 0}
                        onChange={(event, newValue) => {
                          const programId = data.programId;
                          const courseScheduleId = data.id;
                          const isProgramLaunch = data.isProgramLaunch;
                          handleWishListFormChange(
                            programId,
                            courseScheduleId,
                            isProgramLaunch,
                            newValue
                          );
                        }}
                        max={1}
                        disabled={
                          currentRoleId?.code == "LMSAdmin" ||
                          currentRoleId.label.toLowerCase() === "lm admin" ||
                          currentRoleId.label.toLowerCase() ===
                            "lm super admin" ||
                          currentRoleId?.code == "LMSSuperAdmin" ||
                          currentRoleId?.code == "SystemAdmin" ||
                          currentRoleId.label.toLowerCase() === "system admin"
                            ? true
                            : managerId > 0 && selectedRadio === "team"
                            ? true
                            : false
                        }
                        size="large"
                      />
                    )}
                  </div>
                  <div className="cont catBtn">
                    <div className="Cname">
                      <img className="name_icon" src={VideoSvg}></img>
                      <h4>{data.title}</h4>
                    </div>
                    <div className="Cdate">
                      <img className="name_icon" src={CalendarSvg}></img>
                      <span className="date">
                        {data.startDateString} {data.startTime}
                      </span>
                    </div>
                    <div
                      className={
                        data.isLaunched
                          ? "date_btn"
                          : "date_btn opt_view icn_all"
                      }>
                      {data.isLaunched ? (
                        <>
                          {data?.isHold ? (
                            <button
                              className="btn btn-filter-submit hold"
                              onClick={(e) => {
                                if (data.isProgramLaunch) {
                                  // redirect to program registration but disable
                                  navigate("/JourneyRegistrationRequest", {
                                    state: {
                                      programId: data.programId,
                                      launchId: data.launchId,
                                      action: "view",
                                      isManager:
                                        selectedRadio === "team" &&
                                        managerId > 0
                                          ? true
                                          : false,
                                      userId:
                                        selectedRadio === "team" &&
                                        managerId > 0
                                          ? selectedUserId.value
                                          : userDetails.Id,
                                    },
                                  });
                                } else if (!data.isProgramLaunch) {
                                  // redirect to course registration but disable
                                  navigate("/RegistrationRequest", {
                                    state: {
                                      courseScheduleId: data.id,
                                      launchId: data.launchId,
                                      action: "view",
                                      requestId: 0,
                                      type: "IN",
                                      isManager:
                                        selectedRadio === "team" &&
                                        managerId > 0
                                          ? true
                                          : false,
                                      userId:
                                        selectedRadio === "team" &&
                                        managerId > 0
                                          ? selectedUserId.value
                                          : userDetails.Id,
                                    },
                                  });
                                }
                              }}>
                              <i className="fas fa-exclamation-triangle"></i> On
                              Hold
                            </button>
                          ) : (
                            <>
                              {data?.canRegister ? (
                                <span>
                                  <Tooltip
                                    title={
                                      (currentRoleId.code === "LMSAdmin" ||
                                        currentRoleId.code ===
                                          "LMSSuperAdmin" ||
                                        currentRoleId.label.toLowerCase() ===
                                          "lm admin" ||
                                        currentRoleId.label.toLowerCase() ===
                                          "lm super admin") &&
                                      selectedUserId &&
                                      selectedUserId.value
                                        ? "Action cannot be performed"
                                        : null
                                    }>
                                    <button
                                      disabled={
                                        (currentRoleId.code === "LMSAdmin" ||
                                          currentRoleId.code ===
                                            "LMSSuperAdmin" ||
                                          currentRoleId.label.toLowerCase() ===
                                            "lm admin" ||
                                          currentRoleId.label.toLowerCase() ===
                                            "lm super admin") &&
                                        selectedUserId &&
                                        selectedUserId.value
                                          ? true
                                          : false
                                      }
                                      onClick={() => {
                                        if (
                                          data.enableRegister &&
                                          data.isProgramLaunch
                                        ) {
                                          navigate(
                                            "/JourneyRegistrationRequest",
                                            {
                                              state: {
                                                programId: data.programId,
                                                launchId: data.launchId,
                                                isManager:
                                                  selectedRadio === "team" &&
                                                  managerId > 0
                                                    ? true
                                                    : false,
                                                userId:
                                                  selectedRadio === "team" &&
                                                  managerId > 0
                                                    ? selectedUserId.value
                                                    : userDetails.Id,
                                              },
                                            }
                                          );

                                          return null;
                                        }

                                        if (
                                          data.enableRegister &&
                                          !data.isProgramLaunch
                                        ) {
                                          navigate("/RegistrationRequest", {
                                            state: {
                                              courseScheduleId: data.id,
                                              launchId: data.launchId,
                                              action: "edit",
                                              requestId: 0,
                                              type: "IN",
                                              isManager:
                                                selectedRadio === "team" &&
                                                managerId > 0
                                                  ? true
                                                  : false,
                                              userId:
                                                selectedRadio === "team" &&
                                                managerId > 0
                                                  ? selectedUserId.value
                                                  : userDetails.Id,
                                            },
                                          });
                                        } else {
                                          if (
                                            !data.enableRegister &&
                                            data.isProgramLaunch
                                          ) {
                                            // redirect to program registration but disable
                                            navigate(
                                              "/JourneyRegistrationRequest",
                                              {
                                                state: {
                                                  programId: data.programId,
                                                  launchId: data.launchId,
                                                  action: "view",
                                                  isManager:
                                                    selectedRadio === "team" &&
                                                    managerId > 0
                                                      ? true
                                                      : false,
                                                  userId:
                                                    selectedRadio === "team" &&
                                                    managerId > 0
                                                      ? selectedUserId.value
                                                      : userDetails.Id,
                                                },
                                              }
                                            );
                                          }

                                          if (
                                            !data.enableRegister &&
                                            !data.isProgramLaunch
                                          ) {
                                            // redirect to course registration but disable
                                            navigate("/RegistrationRequest", {
                                              state: {
                                                courseScheduleId: data.id,
                                                launchId: data.launchId,
                                                action: "view",
                                                requestId: 0,
                                                type: "IN",
                                                isManager:
                                                  selectedRadio === "team" &&
                                                  managerId > 0
                                                    ? true
                                                    : false,
                                                userId:
                                                  selectedRadio === "team" &&
                                                  managerId > 0
                                                    ? selectedUserId.value
                                                    : userDetails.Id,
                                              },
                                            });
                                          }
                                        }
                                      }}
                                      className="btn btn-filter-submit regs">
                                      {selectedRadio === "team" &&
                                        managerId > 0 &&
                                        selectedUserId &&
                                        selectedUserId.value && (
                                          <i
                                            className="fa-regular fa-check-circle"
                                            aria-hidden="true"></i>
                                        )}

                                      {!data.enableRegister
                                        ? "View"
                                        : selectedRadio === "team" &&
                                          managerId > 0 &&
                                          selectedUserId &&
                                          selectedUserId.value
                                        ? "Assign"
                                        : "Register"}
                                    </button>
                                  </Tooltip>
                                </span>
                              ) : (
                                <button
                                  className="btn btn-filter-submit inprogress"
                                  onClick={(e) => {
                                    e.preventDefault();

                                    navigate("/RegistrationRequest", {
                                      state: {
                                        requestId: data?.requestId,
                                        courseScheduleId:
                                          data?.courseScheduleId,
                                        launchId: data?.launchId,
                                        action: "view",
                                      },
                                    });
                                  }}>
                                  <i className="fas fa-share"></i> In Progress
                                </button>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <span>
                            <Tooltip title={"View"}>
                              <button
                                style={null}
                                onClick={() =>
                                  navigate("/ContentViewer", {
                                    state: {
                                      courseScheduleId: data?.courseScheduleId,
                                      launchId: data.launchId,
                                      from: "/LMSHome",
                                    },
                                  })
                                }
                                className="btn btn-filter-submit">
                                <ReviewsOutlinedIcon className="icn_all_mat" />
                                View
                              </button>
                            </Tooltip>
                          </span>
                          <span>Not Launched</span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </Carousel>
      ) : (
        <div className="noCourse">
          <img className="name_icon" src={VideoSvg}></img>
          <h5>No course available</h5>
        </div>
      )}
    </div>
  );
};

const MyJourneyCarousel = (props) => {
  const {
    myJourneyDataChange,
    managerId,
    selectedRadio,
    selectedUserId,
    selectedDate,
  } = props;
  const { showLoader, hideLoader } = useContext(LoaderContext);
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  const [myJourneyData, setMyJourneyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(3);
  const [totalRecords, setTotalRecords] = useState(0);
  const navigate = useNavigate();

  const getMyJourneyData = async (userId) => {
    showLoader();
    const res = await APICall(GetMyJourneyCatalogueData, "POST", {
      UserId: userId,
      Offset: currentPage,
      PageSize: itemsPerPage,
      SelectedDate: selectedDate
        ? moment(selectedDate).format("DD-MMM-YYYY")
        : null,
    });
    if (res.status === 0 && res.data.length > 0) {
      const myJourneyData = res.data;
      setMyJourneyData(myJourneyData);
      setTotalRecords(myJourneyData[0].totalCount);
    } else {
      setMyJourneyData([]);
      setTotalRecords(0);
    }
    hideLoader();
  };

  useEffect(() => {
    if (myJourneyDataChange) {
      getMyJourneyData(myJourneyDataChange);
    }
  }, [myJourneyDataChange, currentPage, selectedDate]);

  const handleNext = () => {
    // if (currentPage < Math.ceil(totalRecords / itemsPerPage) - 1) {
    setCurrentPage(currentPage + 1);
    // }
  };

  const handlePrev = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      {myJourneyData.length > 0 ? (
        <Carousel
          key={currentPage}
          animation="fade"
          slidesToShow={itemsPerPage}
          slidesToScroll={1}
          wrapAround={false}
          disableEdgeSwiping
          renderBottomCenterControls={null}
          afterSlide={(newIndex) => setCurrentPage(newIndex)}
          renderCenterLeftControls={({ previousSlide }) => (
            <>
              {totalRecords > itemsPerPage && currentPage !== 0 ? (
                <button
                  className="btn btn-outline-dark arrow_set "
                  onClick={handlePrev}
                  disabled={currentPage === 0}>
                  <i className="fa fa-circle-arrow-left" aria-hidden="true"></i>
                </button>
              ) : null}
            </>
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <>
              {totalRecords > itemsPerPage &&
              currentPage + itemsPerPage !== totalRecords ? (
                <button
                  className="btn btn-outline-dark arrow_set "
                  onClick={handleNext}
                  disabled={
                    // currentPage === Math.ceil(totalRecords / itemsPerPage) - 1
                    currentPage + itemsPerPage === totalRecords ? true : false
                  }>
                  <i
                    className="fa-solid fa-circle-arrow-right"
                    aria-hidden="true"></i>
                </button>
              ) : null}
            </>
          )}>
          {myJourneyData &&
            myJourneyData.length > 0 &&
            myJourneyData.map((data) => (
              <div key={data} className="SlideCont learndashb">
                <div className="slide_bg">
                  <div className="vidImg">
                    <ImageColumnComponent
                      link={data.thumbnailUrl}
                      width="auto"
                    />
                  </div>
                  <div className="cont catBtn">
                    <div className="Cname">
                      <img className="name_icon" src={VideoSvg}></img>
                      {/* <h4>{data.title}</h4> */}
                      <Tooltip title={`${data.title}`}>
                        <h4>{data.title}</h4>
                      </Tooltip>
                    </div>
                    <div className="Cdate">
                      <img className="name_icon" src={CalendarSvg}></img>
                      <span className="date">
                        {data.startDateString} {data.startTime}
                      </span>
                    </div>
                    <div
                      className={
                        data.isHold ? "date_btn" : "date_btn opt_view icn_all"
                      }>
                      {data?.isHold ? (
                        <button
                          className="btn btn-filter-submit hold"
                          onClick={(e) => {
                            //  content view but on hold
                            navigate("/ContentViewer", {
                              state: {
                                courseScheduleId: data.id,
                                launchId: data.launchId,
                                isHold: data.isHold,
                                from: "/LMSHome",
                              },
                            });
                          }}>
                          <i className="fas fa-exclamation-triangle"></i> On
                          Hold
                        </button>
                      ) : (
                        <>
                          {data.canView && (
                            <>
                              <span>
                                <Tooltip
                                  title={
                                    (currentRoleId.code === "LMSAdmin" ||
                                      currentRoleId.code === "LMSSuperAdmin" ||
                                      currentRoleId.label.toLowerCase() ===
                                        "lm admin" ||
                                      currentRoleId.label.toLowerCase() ===
                                        "lm super admin" ||
                                      managerId > 0) &&
                                    selectedUserId &&
                                    selectedUserId.value
                                      ? "Action cannot be performed"
                                      : null
                                  }>
                                  <button
                                    disabled={
                                      (currentRoleId.code === "LMSAdmin" ||
                                        currentRoleId.code ===
                                          "LMSSuperAdmin" ||
                                        currentRoleId.label.toLowerCase() ===
                                          "lm admin" ||
                                        currentRoleId.label.toLowerCase() ===
                                          "lm super admin" ||
                                        (managerId && managerId > 0)) &&
                                      selectedUserId &&
                                      selectedUserId.value
                                        ? true
                                        : false || data.enableRegister
                                    }
                                    onClick={() =>
                                      navigate("/ContentViewer", {
                                        state: {
                                          courseScheduleId: data.id,
                                          launchId: data.launchId,
                                          from: "/LMSHome",
                                        },
                                      })
                                    }
                                    className="btn btn-filter-submit">
                                    <ReviewsOutlinedIcon className="icn_all_mat" />
                                    View
                                  </button>
                                </Tooltip>
                              </span>
                              <span>{data.progressText}</span>
                            </>
                          )}
                          {data.canOpt && (
                            <span>
                              <Tooltip
                                title={
                                  (currentRoleId.code === "LMSAdmin" ||
                                    currentRoleId.code === "LMSSuperAdmin" ||
                                    currentRoleId.label.toLowerCase() ===
                                      "lm admin" ||
                                    currentRoleId.label.toLowerCase() ===
                                      "lm super admin" ||
                                    managerId > 0) &&
                                  selectedUserId &&
                                  selectedUserId.value
                                    ? "Action cannot be performed"
                                    : null
                                }>
                                <button
                                  disabled={
                                    (currentRoleId.code === "LMSAdmin" ||
                                      currentRoleId.code === "LMSSuperAdmin" ||
                                      currentRoleId.label.toLowerCase() ===
                                        "lm admin" ||
                                      currentRoleId.label.toLowerCase() ===
                                        "lm super admin" ||
                                      managerId > 0) &&
                                    selectedUserId &&
                                    selectedUserId.value
                                      ? true
                                      : false
                                  }
                                  onClick={() =>
                                    navigate("/RegistrationRequest", {
                                      state: {
                                        courseScheduleId: data.id,
                                        launchId: data.launchId,
                                        action: "edit",
                                        requestId: 0,
                                        type: "OUT",
                                      },
                                    })
                                  }
                                  className="btn btn-filter-submit">
                                  <i className="fa-solid fa-arrow-right-from-bracket"></i>
                                  Opt-out
                                </button>
                              </Tooltip>
                            </span>
                          )}
                        </>
                      )}
                    </div>
                    {/* <div className="Cname">
                      <img className="name_icon" src={VideoSvg}></img>
                      <span className="date">{"Course Status"}</span>{" "}
                      <span className="selfTeam">{`100%`}</span>
                    </div> */}
                    <div className="progBar">
                      <ProgressBar
                        now={data?.progressPercentage}
                        variant={
                          data?.progressPercentage <= 30
                            ? "danger"
                            : data?.progressPercentage > 30 &&
                              data?.progressPercentage <= 90
                            ? "warning"
                            : data?.progressPercentage > 90
                            ? "success"
                            : ""
                        }
                        // label={`${data?.progressPercentage}%`}
                        // className={`mt-1`}
                      />
                      <span>{`${data?.progressPercentage}%`}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </Carousel>
      ) : (
        <div className="noCourse">
          <img className="name_icon" src={VideoSvg}></img>
          <h5>No course available</h5>
        </div>
      )}
    </div>
  );
};

const LMSHome = () => {
  let navigate = useNavigate();
  const { showLoader, hideLoader } = useContext(LoaderContext);

  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();

  type ValuePiece = Date | null;

  type Value = ValuePiece | [ValuePiece, ValuePiece];

  const [date, setDate] = useState<Value>(new Date());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());
  const [calendarData, setCalendarData] = useState([]);

  // console.log("mydate", date);

  useEffect(() => {
    const getCalendarData = async () => {
      try {
        const response = await APICall(GetCalendarData, "POST", {
          UserId: userDetails?.Id,
          InputMonth: month,
          InputYear: year,
          // InputMonth: date instanceof Date ? date.getMonth() + 1 : null,
          // InputYear: date instanceof Date ? date.getFullYear() : null,
        });

        if (response.status === 0 && response.data.length > 0) {
          setCalendarData(response.data);
        } else {
          setCalendarData([]);
        }
      } catch (error) {
        console.error("Error fetching calendar data:", error);
        setCalendarData([]);
      }
    };

    getCalendarData();
  }, [month, year]);

  const tileContent = ({ activeStartDate, date, view }) => {
    const day = date.getDate();
    const myCalendarData = [...calendarData];
    const foundData = myCalendarData.find(
      (calendar) => moment(calendar.startDate).toDate().getDate() === day
    );

    if (view === "month" && foundData !== undefined) {
      return (
        <>
          <Badges
            badgeContent={foundData?.count}
            style={{ float: "right", marginRight: "4px" }}
            color="primary"></Badges>
          <div className="" style={{ width: "100%", height: "55px" }}>
            <ImageColumnComponent link={foundData.thumbnailUrl} />
          </div>
        </>
      );
    } else {
      return <div style={{ width: "100%", height: "55px" }}></div>;
    }
  };

  const onMonthChange = async ({ action, activeStartDate, value, view }) => {
    // if (view === "month") {
    let selectedMonth = moment(activeStartDate).month() + 1;
    let selectedYear = moment(activeStartDate).year();
    setMonth(() => selectedMonth);
    setYear(() => selectedYear);
    // }
  };

  // catalogue

  const [upComingDataChange, setUpComingDataChange] = useState(userDetails.Id);
  const [myJourneyDataChange, setMyJourneyDataChange] = useState(
    userDetails.Id
  );

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [pageSize, setPageSize] = useState(3);

  const [pendingAssessmentCount, setPendingAssessmentCount] = useState(0);
  const [pendingFeedbackCount, setPendingFeedbackCount] = useState(0);
  const [wishListCount, setWishListCount] = useState(0);

  const getWishListCount = async () => {
    const res = await APICall(GetMyCourseWishlist, "POST", {
      UserId: userDetails?.Id,
      Offset: 0,
      PageSize: 10,
      IsManager: false,
    });

    if (res.status === 0 && res.data !== null && res.data.length > 0) {
      const wishCount = res.data[0].totalCount;
      setWishListCount(wishCount);
    } else {
      setWishListCount(0);
    }
  };

  const getUserAssessmentDashboardData = async () => {
    showLoader();
    const res = await APICall(getUserAssessments, "POST", {
      PageNumber: 0,
      PageSize: 10,
      SortColumn: "",
      SortOrder: "",
      CourseIds: null,
      JourneyIds: null,
      AssessmentIds: null,
      SearchText: "",
      PassingStatus: null,
      GetStatus: 0, //pending
      UserId: userDetails.Id,
      RoleId: currentRoleId.value,
    });

    if (res.status === 0 && res.data !== null && res.data.length > 0) {
      setPendingAssessmentCount(res.data[0].totalCount);
    } else {
      setPendingAssessmentCount(0);
    }
    hideLoader();
  };

  const getUserFeedbackDashboardDataAPI = async () => {
    showLoader();
    const res = await APICall(getUserFeedbackDashboardData, "POST", {
      SearchText: "",
      UserId: userDetails.Id,
      FeedbackStatus: 0,
      FeedbackNameIds: null,
      JourneyIds: null,
      CourseIds: null,
      PageNumber: 0,
      PageSize: 10,
      SortColumn: "",
      SortOrder: "",
    });
    if (res.status === 0 && res.data !== null && res.data.length > 0) {
      setPendingFeedbackCount(res.data[0].totalCount);
    } else {
      setPendingFeedbackCount(0);
    }
    hideLoader();
  };

  const [UserCertificateCount, setUserCertificateCount] = useState(0);

  const getUserCertificateData = async () => {
    const response = await APICall(getUserCertificateDashboardData, "POST", {
      UserId: userDetails?.Id,
    });

    if (
      response?.status === 0 &&
      response.data !== null &&
      response.data.length > 0
    ) {
      setUserCertificateCount(response?.data.length);
    } else if (response?.status === 0 && response.data === null) {
      setUserCertificateCount(0);
    } else {
      setUserCertificateCount(0);
      console.log("err", response);
    }
  };

  useEffect(() => {
    getUserAssessmentDashboardData();
    getUserFeedbackDashboardDataAPI();
    getWishListCount();
    getUserCertificateData();
  }, []);

  useEffect(() => {
    getHistoricDataDasboardDetailsAPICall();
  }, [page, pageSize]);

  const getHistoricDataDasboardDetailsAPICall = async () => {
    showLoader();
    const GetDashboardData = await APICall(
      getHistoricDataDasboardDetails,
      "POST",
      {
        Page: page,
        PageSize: pageSize,
        SortColumn: "",
        SortOrder: "",
        SearchText: "",
        CourseTypeIds: "",
        EntityTypeIds: "",
        EntityTitleIds: "",
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      }
    );
    if (
      GetDashboardData &&
      GetDashboardData.data !== null &&
      GetDashboardData.data.length > 0
    ) {
      setCount(pageSize);

      setRowData(GetDashboardData.data);
    } else {
      setRowData([]);
      setCount(0);
    }
    hideLoader();
  };

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: pageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        // await setSortColumn(sortColumn);
        // await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        // await setSortColumn(sortColumn);
        // await setSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      // setPage(page * pageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const gridColumns = [
    {
      name: "Id",
      label: "Id",
      options: {
        display: false,
      },
    },

    {
      name: "employeeCode",
      label: "Employee Code",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        display: false,
      },
    },
    {
      name: "courseType",
      label: "Type",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        display: false,
      },
    },
    {
      name: "entityTittle",
      label: "Title",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "entityType",
      label: "Type",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "completionDate",
      label: "Completed",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        customBodyRender: (value, tableMeta) => {
          return <p>{moment(value).format("DD MMM YYYY")}</p>;
        },
      },
    },
    {
      name: "completionStatus",
      label: "Status",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <div className="status_complete">
                <i className="fa-solid fa-circle-check"></i> Complete
              </div>
              {/* <div className="status_incomplete">
                <i className="fa-solid fa-arrow-rotate-right"></i> Incomplete
              </div> */}
            </>
          );
        },
      },
    },

    {
      name: "totalHours",
      label: "Total Hours",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        display: false,
      },
    },
    {
      name: "manHours",
      label: "Learning Hrs",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
  ];

  return (
    <>
      <div className="breadcrumb modified-bredcrumb">
        <span>
          <ul>
            <li>
              <a href="/home">Home</a>
            </li>
            <li>Learning dashboard</li>
          </ul>
        </span>
      </div>
      <div className="conatiner-fluid">
        <div className="row">
          <div className="col-lg-12 col-sm-12 col-xs-12">
            <img
              className="banner-img"
              alt="banner"
              src={require("../../Assets/Images/learning_banner.jpg")}></img>
          </div>
        </div>
        <div className="row p-3">
          <div className="col-lg-6 col-md-12 col-sm-6 col-xs-12 pd_right pd_tab">
            <Card>
              <Card.Header className="bg_dark_blue text-white learningcardHead">
                Group Learning & Cultural Calendar
              </Card.Header>
              <Card.Body>
                <Calendar
                  className={"lmsCalendar"}
                  onChange={setDate}
                  onClickDay={(value, event) =>
                    navigate("/Catalogue", { state: { selectedDate: value } })
                  }
                  value={date}
                  tileContent={tileContent}
                  showNeighboringMonth={false}
                  minDetail="century"
                  onActiveStartDateChange={onMonthChange}
                />
              </Card.Body>
            </Card>
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 pd_left">
            <Card className="leaderboardslider">
              <Card.Header className="bg_dark_blue text-white learningcardHead">
                My Learning Zone
                <Badge
                  onClick={() => navigate("/Catalogue")}
                  pill
                  className="p-2 float-right view_all"
                  style={{ cursor: "pointer" }}>
                  View All{"  "}
                  <i
                    className="fa fa-circle-arrow-right"
                    aria-hidden="true"></i>
                </Badge>
              </Card.Header>
              <Card.Body className="sliderHeight">
                <div className="mb-2 upcom">
                  <p
                    className="text-primary learnhead"
                    style={{ marginTop: "-6px" }}>
                    Upcoming Learning
                  </p>
                  <UpComingCarousel upComingDataChange={upComingDataChange} />
                </div>
                <hr style={{ marginBottom: "-12px", marginTop: "10px" }} />
                <div className="mb-2 myjourn">
                  <p className="text-primary learnhead">My Learning</p>
                  <MyJourneyCarousel
                    myJourneyDataChange={myJourneyDataChange}
                  />
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>

        <div className="row py-2 px-3">
          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 pd_right">
            <Card className="mb-3 actStat">
              <Card.Header className="bg_dark_blue text-white learningcardHead">
                Learning Activity Status
                <Badge
                  onClick={() => navigate("/HistoricData")}
                  pill
                  className="p-2 float-right view_all"
                  style={{ cursor: "pointer" }}>
                  View All{"  "}
                  <i
                    className="fa fa-circle-arrow-right"
                    aria-hidden="true"></i>
                </Badge>
              </Card.Header>
              <Card.Body className="learnignActivityTtbl ">
                <DynamicGrid
                  data={rowData}
                  columns={gridColumns}
                  options={options}
                  style={{ padding: "0" }}
                />
                {/* <p className="over_lay">Coming Soon</p> */}
              </Card.Body>
            </Card>
            <div className="row feedbook">
              <div className="col-lg-8 col-md-7 col-sm-8 col-xs-12 pd_right ">
                <Card className="mb-3">
                  <Card.Header className="bg_dark_blue text-white learningcardHead">
                    Feedback
                  </Card.Header>
                  <Card.Body className="card_body">
                    <div className="feedbacks">
                      <Tooltip title={"Click to open"}>
                        <div
                          // style={{ opacity: "0.5" }}
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate("/userFeedback")}
                          className="feed">
                          {pendingFeedbackCount > 0 && (
                            <Badges
                              badgeContent={pendingFeedbackCount}
                              style={{
                                position: "absolute",
                                left: "190px",
                                top: "65px",
                              }}
                              color="primary"></Badges>
                          )}
                          <img
                            src={require("../../Assets/Images/feedback.png")}></img>
                          <h4>Feedback Zone</h4>
                        </div>
                      </Tooltip>
                      <div className="divider"></div>
                      <Tooltip title={"Click to open"}>
                        <div
                          // style={{ opacity: "0.5" }}
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate("/UserAssessments")}
                          className="feed">
                          {pendingAssessmentCount > 0 && (
                            <Badges
                              badgeContent={pendingAssessmentCount}
                              style={{
                                position: "absolute",
                                right: "20px",
                                top: "65px",
                              }}
                              color="primary"></Badges>
                          )}
                          <img
                            src={require("../../Assets/Images/comments.png")}></img>
                          <h4>
                            Assessment<br></br>(Training wise)
                          </h4>
                        </div>
                      </Tooltip>
                      {/* <p className="over_lay">Coming Soon</p> */}
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-lg-4 col-md-5 col-sm-4 col-xs-12 pd_left">
                <Card className="mb-3">
                  <Card.Header className="bg_dark_blue text-white learningcardHead">
                    Bookmarks
                  </Card.Header>
                  <Card.Body className="card_body">
                    <div className="comingSoon small_overlay come">
                      <Tooltip title={"Click to open"}>
                        <div
                          className="feed bookf"
                          onClick={() => navigate("/MyWishlist")}>
                          {wishListCount > 0 && (
                            <Badges
                              badgeContent={wishListCount}
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "5px",
                              }}
                              color="primary"></Badges>
                          )}
                          <img
                            src={require("../../Assets/Images/saved.png")}></img>
                          <h4>My Bookmarks</h4>
                        </div>
                      </Tooltip>
                      {/* <p className="over_lay">Coming Soon</p> */}
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-6 col-xs-12 pd_left">
            <Card className="mb-3 learnReport">
              <Card.Header className="bg_dark_blue text-white learningcardHead">
                Learning Report
                <Badge
                  onClick={() => navigate("/Catalogue")}
                  pill
                  className="p-2 float-right view_all"
                  style={{ cursor: "pointer" }}>
                  View All{"  "}
                  <i
                    className="fa fa-circle-arrow-right"
                    aria-hidden="true"></i>
                </Badge>
              </Card.Header>
              <Card.Body>
                <div className="graph">
                  <img src={require("../../Assets/Images/graph.png")}></img>
                  <p>Coming Soon</p>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="row px-3 pt-0 pb-4">
          <div className="col-lg-6 col-sm-6 col-xs-12 pd_right mob-bott">
            <Card>
              <Card.Header className="bg_dark_blue text-white learningcardHead">
                Learning Testimonials
              </Card.Header>
              <Card.Body>
                <div className="row mar_row">
                  <div
                    style={{ opacity: "0.5" }}
                    className="col-xl-4 col-lg-4 col-md-6 col-sm-12 grid_pad">
                    <a href="#" target="_blank" rel="noreferrer">
                      <div
                        className="quicklinks_grid learnTest"
                        data-toggle="tooltip"
                        title="Happay">
                        <div className="imgText">
                          <div className="test_count">
                            <img
                              className="img-grid img-grid-fit"
                              src={require("../../Assets/Images/award.png")}
                              alt="Coursera"
                            />
                            <span>10</span>
                          </div>
                          <p className="text-grid">Coursera</p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 grid_pad">
                    <Tooltip title={"Click to open"}>
                      <div
                        // style={{ opacity: "0.5" }}
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate("/usercertificate")}
                        className="quicklinks_grid learnTest"
                        data-toggle="tooltip"
                        title="Happay">
                        {UserCertificateCount > 0 && (
                          <Badges
                            badgeContent={UserCertificateCount}
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "5px",
                            }}
                            color="primary"></Badges>
                        )}
                        <div className="imgText">
                          <div className="test_count">
                            <img
                              className="img-grid img-grid-fit"
                              src={require("../../Assets/Images/certification.png")}
                              alt="Certificates"
                            />
                            {/* <span>15</span> */}
                          </div>
                          <p className="text-grid">Certificates</p>
                        </div>
                      </div>
                    </Tooltip>
                  </div>
                  <div
                    style={{ opacity: "0.5" }}
                    className="col-xl-4 col-lg-4 col-md-6 col-sm-12 grid_pad bott_mob">
                    <a href="#" target="_blank" rel="noreferrer">
                      <div
                        className="quicklinks_grid learnTest"
                        data-toggle="tooltip"
                        title="Happay">
                        <div className="imgText">
                          <div className="test_count">
                            <img
                              className="img-grid img-grid-fit"
                              src={require("../../Assets/Images/star_medal.png")}
                              alt="GMI"
                            />
                            <span>20</span>
                          </div>
                          <p className="text-grid">Badges</p>
                        </div>
                      </div>
                    </a>
                  </div>
                  {/* <p className="over_lay">Coming Soon</p> */}
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col-lg-6 col-sm-6 col-xs-12 pd_left">
            <Card>
              <Card.Header className="bg_dark_blue text-white learningcardHead">
                Learning Highlights
              </Card.Header>
              <Card.Body>
                <div className="row mar_row comingSoon">
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 grid_pad">
                    <a href="#" target="_blank" rel="noreferrer">
                      <div
                        className="quicklinks_grid learnHigh"
                        data-toggle="tooltip"
                        title="Happay">
                        <div className="imgText">
                          <img
                            className="img-grid img-grid-fit"
                            src={require("../../Assets/Images/worklearn.png")}
                            alt="Certificates"
                          />
                          <p className="text-grid">Certificates</p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 grid_pad">
                    <a href="#" target="_blank" rel="noreferrer">
                      <div
                        className="quicklinks_grid learnHigh"
                        data-toggle="tooltip"
                        title="Happay">
                        <div className="imgText">
                          <img
                            className="img-grid img-grid-fit"
                            src={require("../../Assets/Images/coursera1.png")}
                            alt="Coursera"
                          />
                          <p className="text-grid">Coursera</p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 grid_pad bott_mob">
                    <a href="#" target="_blank" rel="noreferrer">
                      <div
                        className="quicklinks_grid learnHigh"
                        data-toggle="tooltip"
                        title="Happay">
                        <div className="imgText">
                          <img
                            className="img-grid img-grid-fit"
                            src={require("../../Assets/Images/GMI-logo.png")}
                            alt="GMI"
                          />
                          <p className="text-grid">GMI</p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <p className="over_lay">Coming Soon</p>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default LMSHome;
