import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import InputForm from "../../Components/InputForm/InputForm";
import SelectForm from "../../Components/SelectForm/SelectForm";
import DateForm from "../../Components/DateForm/DateForm";
import { ExpandMore } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { LoaderContext } from "../../Helpers/Context/Context";
import { APICall } from "../../Helpers/APICalls";
import { Table } from "react-bootstrap";
import {
  GetConfigValueByKey,
  OfferLetterGeneratedDocDounload,
  documentUpload,
  enterRecordInActionHistory,
  getDocuments,
  getEmployeeDetailsById,
  handleApprovalForProgressionModel,
  postApprovalForHire,
  checkHiringLimit,
} from "../../Helpers/APIEndPoints/EndPoints";
import notify from "../../Helpers/ToastNotification";
import moment from "moment";

const ApprovalSectionClm = ({ approverResponse = null }) => {
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  const [attributesData, setAttributesData] = useState<any>({});
  const navigate = useNavigate();
  const { state } = useLocation();
  const [accordion, setAccordion] = useState(true);
  const [formErrors, setFormErrors] = useState<any>({});
  const [optionsObj, setOptionsObj] = useState<any>({});
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [workflowTaskId, setWorkflowTaskId] = useState(state ? state : 0);
  const [decisionOptionsArr, setDecisionOptionsArr] = useState([]);
  const [disableSubmitBtn, setDisabledSubmitBtn] = useState(false);
  const [newPreviousConversation,setNewPreviousConversation] = useState([]);
  const handleFetchApprovalData = async (approverResponse) => {
    try {
      showLoader();

      //   const [optForType, optForTab, response] = await Promise.all([
      //     getDdData("REQTYPOUPD", ""),
      //     getDdData("REQTABS", ""),
      //     APICall(getApproverAndProgressionDetailsByWorkflowTaskId, "POST", {
      //       Id: workflowTaskId,
      //     }),
      //   ]);

      const responseData = approverResponse;
      if (responseData) {
        //console.log("Approver And Progression Data", responseData);

        const parseJSON = (jsonString) => {
          try {
            return JSON.parse(jsonString);
          } catch (error) {
            return null;
          }
        };

        const parsePreviousConversation = (previousConversation) => {
          let conversation = "";
          const remarksArray = parseJSON(previousConversation);
          let previousConversationToTable = [];
          if (remarksArray) {
            remarksArray.map((item) => {
              const Date = item.ModifiedDateUtc
              ?item.ModifiedDateUtc
                // ? moment.utc(item.ModifiedDateUtc).local().format("DD-MMM-YYYY")
                : "";
              const ApproverName = item.UserName || "";
              const RoleName = item.RoleName || "";
              const Remarks = item.Remarks || "";

              previousConversationToTable.push({
                Date: Date,
                ApproverName: ApproverName,
                RoleName: RoleName,
                Remarks: Remarks,
              });

              if (Remarks?.trim().length > 0) {
                conversation += `${Date} ${ApproverName} ${RoleName}\n`;
                conversation += `\t\t${Remarks}\n\n`;
              }
            });
          }
          setNewPreviousConversation(previousConversationToTable);
          return conversation;
        };

        const NewJson = parseJSON(responseData?.RequestNewJson) || [];
        //console.log(NewJson);

        const additionalDetails =
          parseJSON(responseData.AdditionalDetails) || [];

        setDecisionOptionsArr(additionalDetails);

        const isStatus2 = responseData.WorkflowTaskStatusId === 2;

        const decision =
          (isStatus2 &&
            additionalDetails.find(
              (i) =>
                String(i?.label).trim().toLowerCase() ===
                String(responseData.Outcome).toLowerCase().trim()
            )) ||
          null;

        const previousConversation = parsePreviousConversation(
          responseData.PreviousConversation
        );

        const approverObject = {
          ApproverName: responseData.ApproverName || "",
          RoleName: responseData.ApproverRoleName || "",
          Date: responseData.modifiedDateUtc || moment().format("DD-MMM-YYYY"),
          EmployeeId: responseData.TId,
          IsAcknowledged: responseData.IsAcknowledged,
          IsAcknolwdgedRequired: responseData.IsAcknolwdgedRequired || false,
          Outcome: responseData.Outcome || "",
          PreviousConversation: previousConversation,
          Decision: decision,
          Remarks: responseData.WorkflowTasksRemarks || "",
          WorkflowTaskStatusId: responseData.WorkflowTaskStatusId || 0,
        };

        const isDisabled = approverObject.WorkflowTaskStatusId === 2;

        const setAttributes = {
          PROGRVWR: approverObject.ApproverName,
          PROGROLE: approverObject.RoleName,
          PROGEFFDT: approverObject.Date,
          PROGDEC: approverObject.Decision,
          PROGRMKS: approverObject.Remarks,
          PROGACK: approverObject.IsAcknowledged,
          PROGPRCONVO: approverObject.PreviousConversation,
          EmployeeId: approverObject.EmployeeId,
          IsAcknolwdgedRequired: approverObject.IsAcknolwdgedRequired,
          IsDisabled: isDisabled,
        };

        setDisabledSubmitBtn(isDisabled);

        setAttributesData(setAttributes);
        // setProgAttributesData(setProgAttributes);

        // if (optForType?.length > 0 && optForTab.length > 0) {
        //   let optForTypeObj = optForType?.find(
        //     (i) => i.value == responseData.WorkflowTypeId
        //   );
        //   const optForReason = await getDdData(
        //     "REQRSNOUP",
        //     optForTypeObj.code
        //   );

        //   let optForReasonObj = optForReason?.find(
        //     (i) => i?.value == responseData.WorkflowSubTypeId
        //   );

        //   if (
        //     optForTypeObj?.code == "DataChange" &&
        //     optForReasonObj?.code == "DTACHG"
        //   ) {
        //     setSectionTabHide(false);
        //   }
        // }

        // if (
        //   responseData.WorkflowTypeId &&
        //   responseData.EmployeeId &&
        //   responseData.WorkflowSubTypeId
        // ) {
        //   await handleFetchDynamic({
        //     WorkflowTypeId: responseData.WorkflowTypeId,
        //     EmployeeId: responseData.EmployeeId,
        //     WorkflowSubTypeId: responseData.WorkflowSubTypeId,
        //     COU: responseData?.EmployeeDetails?.cou || 0,
        //     WorkflowTypeName: responseData.WorkflowTypeName,
        //     NewJson,
        //   });
        // }
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    (async () => {
      if (approverResponse) {
        await handleFetchApprovalData(approverResponse);
        await setEventDetails(approverResponse);
      }
    })();
  }, [approverResponse]);

  const [eventValue, setEventValue] = useState(null);
  const [eventReasonValue, setEventReasonValue] = useState(null);

  const setEventDetails = async (approverResponse) => {
    setEventValue({
      label: approverResponse.WorkflowTypeName,
      value: approverResponse.WorkflowTypeId,
    });
    setEventReasonValue({
      label: approverResponse.WorkflowSubTypeName,
      value: approverResponse.WorkflowSubTypeId,
    });
  };

  const handleSubmitForApprover = async () => {
    try {
      let isError = {};
      showLoader();
      if (!attributesData["PROGDEC"]) {
        isError["PROGDEC"] = "Decision Required.";
      }
      if (!attributesData["PROGRMKS"]) {
        isError["PROGRMKS"] = "Remarks Required.";
      }
      // if (
      //   attributesData["IsAcknolwdgedRequired"] != attributesData["PROGACK"]
      // ) {
      //   isError["PROGACK"] = "Acknowledgement Required.";
      // }

      // if (
      //   attributesData["IsAcknolwdgedRequired"] &&
      //   !attributesData["PROGACK"]
      // ) {
      //   isError["PROGACK"] = "Acknowledgement Required.";
      // }

      setFormErrors(isError);
      if (Object.keys(isError).length > 0) return;

      //Check hiring limit STARTS
      // const checkHiringLimitResp = await APICall(
      //   checkHiringLimit,
      //   "POST",
      //   {
      //     Tid: attributesData?.EmployeeId,
      //   }
      // );
      // if (checkHiringLimitResp.data != null &&  checkHiringLimitResp.remainingLimit <= 0)
      // {
      //   notify(1, `Hiring limit reached.`);
      //   return;
      // }
      //Check hiring limit ENDS
      
      let obj = {
        CreatedBy: userDetails?.Id,
        CreatedByRoleId: currentRoleId?.value,
        WorkflowTaskId: workflowTaskId,
        DecisionId: attributesData["PROGDEC"]?.value || 0,
        IsAcknowledged: attributesData["PROGACK"] || false,
        EmployeeId: attributesData?.EmployeeId,
        WorkflowTasksRemarks: attributesData["PROGRMKS"],
      };

      const response = await APICall(postApprovalForHire, "POST", obj);

      // Get generated document 


      const res = await APICall(GetConfigValueByKey, "POST", {
        Key: "WelspunDMSAPILink",
      });
  
      let dmsUrl = null;
      if (res?.status === 0) {
        dmsUrl = res.data;
      }

      const reqId = await APICall(getEmployeeDetailsById, "POST", {
        TId: attributesData?.EmployeeId,
        COU: userDetails?.CT
      });

      if (reqId?.data?.docId !== null) {
        const urlDocGenrated = await APICall(`${dmsUrl}/Status`, "POST", {
          requestid: reqId?.data?.docId,
          doctype: "signedpdf",
        });
        if (urlDocGenrated?.status == 200) {
          
            let relativeurl = urlDocGenrated.data?.split("/sites")[1];
            const GeneratedDocument = await APICall(
              OfferLetterGeneratedDocDounload,
              "POST",
              {
                DocURL: `/sites${relativeurl}`,
              }
            );
      
            let docname = urlDocGenrated.data?.split("/");
            let name = docname[docname.length - 1];
            
            let tempDoc = [];
            
            tempDoc = [
              {
                AC: 'BCD',
                Name: name,
                Data: GeneratedDocument?.data,
                IsValidFile: false,
                DocId: 0,
                Type: 'Personal File',
                Context: 'Mail merge',
                // AC: 'BCD',
                // Name: name,
                // Data: GeneratedDocument?.data,    
              },
            ];

            let obj = {
              TId: attributesData?.EmployeeId,
              files: tempDoc,
              ModuleName: "EmployeeCentral",
              ModuleId: 1,
              UserId: proxyUserDetails?.value  ? proxyUserDetails?.value : userDetails.UserId,
              ProxyUserId: proxyUserDetails === null ? null: proxyUserDetails?.value,
            };

            const resp = await APICall(documentUpload, "POST", obj);
           
        }
      }

      const actHistObj = await APICall(enterRecordInActionHistory, "POST", {
        Status: attributesData["PROGDEC"]?.label,
        Remarks: attributesData["PROGRMKS"],
        CreatedBy: userDetails.Id,
        RequestId: attributesData?.EmployeeId,
        workflowTaskId: workflowTaskId,
      });

       if (response?.status == 0) {
         await handleFetchApprovalData(approverResponse);
         notify(0, "Record Saved Successfully.");
         navigate("/home");
       } else {
         notify(1, "Something went wrong.");
       }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };
  //CALL A USE-EFFECT TO GET DATA FROM DATABASE
  useEffect(() => {
    setAttributesData((prev) => ({
      ["PROGRVWR"]: "",
      ["PROGROLE"]: "",
      ["PROGEFFDT"]: null,
      ["PROGDEC"]: null,
      ["PROGRMKS"]: "",
      ["PROGACK"]: false,
      ["PROGPRCONVO"]: "",
    }));
    // setProgAttributesData((prev) => ({
    //   ["REQTYPOUPD"]: null,
    //   ["REQRSNOUP"]: null,
    //   ["REQEMPLID"]: null,
    //   ["REQEMPLNM"]: "",
    //   ["REQEFCTVDT"]: null,
    //   ["REQSUPDOC"]: false,
    //   ["REQDREMK"]: "",
    //   ["WRKFLNM"]: "",
    // }));
  }, []);
  console.log("workflowTaskId",workflowTaskId);
  return (
    <>
      <div className="SectionSubmit mb-4 clearfix">
        <button
          onClick={async () => {
            setAccordion((prev) => !prev);
            await handleSubmitForApprover();
          }}
          disabled={attributesData["IsDisabled"]}
          className="btn btn-filter-submit float-right ml-2"
        >
          <i className="fa-solid fa-check"></i> Submit
        </button>
      </div>
      <Accordion
        elevation={0}
        className="mb-3"
        expanded={accordion}
        onChange={() => setAccordion((prev) => !prev)}
      >
        <AccordionSummary
          id="profile-accordion"
          style={{ background: "#3C5464" }}
          className="text-white acc_close"
          expandIcon={<ExpandMore />}
        >
          <div className="row d-flex align-items-center acc-heading">
            <div className="col-lg-10 col-md-10 col-sm-12">
              <p style={{ fontSize: "16px" }}>{`${"Approver"}`}</p>
            </div>
            <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
          </div>
        </AccordionSummary>

        <AccordionDetails className="page_heading">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
              <div className="form-group">
                <label className="col-form-label">Event</label>
                <sup>*</sup>
                <SelectForm
                  isClearable
                  isSearchable
                  options={[]}
                  placeholder="Select Event"
                  isDisabled={true}
                  value={eventValue}
                  onChange={(event) => {}}
                  isMulti={false}
                  noIndicator={false}
                  noSeparator={false}
                />
                <span style={{ color: "red" }}>
                  {formErrors["error_Event"]}
                </span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
              <div className="form-group">
                <label className="col-form-label">Event Reason</label>
                <sup>*</sup>
                <SelectForm
                  isClearable
                  isSearchable
                  options={[]}
                  placeholder="Select Event Reason"
                  isDisabled={true}
                  value={eventReasonValue}
                  onChange={(event) => {}}
                  isMulti={false}
                  noIndicator={false}
                  noSeparator={false}
                />
                <span style={{ color: "red" }}>
                  {formErrors["error_EventReasion"]}
                </span>
              </div>
            </div>
            <div className={"col-lg-3 col-sm-3 col-xs-4"}>
              <div className="mb-1">
                <label className="col-form-label">
                  Approver
                  <sup>*</sup>
                </label>
                <InputForm
                  className="form-control"
                  placeholder={"Approver"}
                  isDisabled={true}
                  textArea={false}
                  value={
                    attributesData["PROGRVWR"] ? attributesData["PROGRVWR"] : ""
                  }
                  onChange={(e) => {
                    setAttributesData((prev) => ({
                      ...prev,
                      ["PROGRVWR"]: e.target.value,
                    }));
                  }}
                />
              </div>
              {formErrors["PROGRVWR"] && (
                <p style={{ color: "red" }}>{formErrors["PROGRVWR"]}</p>
              )}
            </div>
            <div className={"col-lg-3 col-sm-3 col-xs-4"}>
              <div className="mb-1">
                <label className="col-form-label">
                  Role
                  <sup>*</sup>
                </label>
                <InputForm
                  className="form-control"
                  placeholder={"Role"}
                  isDisabled={true}
                  textArea={false}
                  value={
                    attributesData["PROGROLE"] ? attributesData["PROGROLE"] : ""
                  }
                  onChange={(e) => {
                    setAttributesData((prev) => ({
                      ...prev,
                      ["PROGROLE"]: e.target.value,
                    }));
                  }}
                />
              </div>
              {formErrors["PROGROLE"] && (
                <p style={{ color: "red" }}>{formErrors["PROGROLE"]}</p>
              )}
            </div>
            <div className={"col-lg-3 col-sm-3 col-xs-4"}>
              <div className="mb-1">
                <label className="col-form-label">
                  Date
                  <sup>*</sup>
                </label>
                <DateForm
                  isDisabled={true}
                  value={
                    attributesData["PROGEFFDT"]
                      ? attributesData["PROGEFFDT"]
                      : null
                  }
                  onChange={(date) => {
                    setAttributesData((prev) => ({
                      ...prev,
                      ["PROGEFFDT"]: moment(date).format("DD-MMM-YYYY"),
                    }));
                  }}
                />
              </div>
              {formErrors["PROGEFFDT"] && (
                <p style={{ color: "red" }}>{formErrors["PROGEFFDT"]}</p>
              )}
            </div>
            <div className={"col-lg-3 col-sm-3 col-xs-4"}>
              <div className="mb-1">
                <label className="col-form-label">
                  Decision
                  <sup>*</sup>
                </label>
                <SelectForm
                  isClearable
                  isSearchable
                  isDisabled={attributesData["IsDisabled"]}
                  value={
                    attributesData["PROGDEC"] ? attributesData["PROGDEC"] : null
                  }
                  placeholder={"Decision"}
                  options={decisionOptionsArr}
                  onChange={(e) => {
                    setAttributesData((prev) => ({
                      ...prev,
                      ["PROGDEC"]: e,
                    }));

                    let obj = formErrors;
                    delete obj["PROGDEC"];
                    setFormErrors(obj);
                  }}
                  isMulti={false}
                  noIndicator={false}
                  noSeparator={false}
                />
              </div>
              {formErrors["PROGDEC"] && (
                <p style={{ color: "red" }}>{formErrors["PROGDEC"]}</p>
              )}
            </div>
          </div>
          <div className="row">
            <div className={"col-lg-6 col-sm-3 col-xs-4"}>
              <div className="mb-1">
                <label className="col-form-label">
                  Remarks
                  <sup>*</sup>
                  </label>
                <InputForm
                  className="form-control"
                  placeholder={"Remarks"}
                  isDisabled={attributesData["IsDisabled"]}
                  textArea={true}
                  value={
                    attributesData["PROGRMKS"] ? attributesData["PROGRMKS"] : ""
                  }
                  onChange={(e) => {
                    setAttributesData((prev) => ({
                      ...prev,
                      ["PROGRMKS"]: e.target.value,
                    }));
                    let obj = formErrors;
                  delete obj["PROGRMKS"];
                  setFormErrors(obj);
                }}
                  
                  rows={4}
                />
              </div>
              {formErrors["PROGRMKS"] && (
                <p style={{ color: "red" }}>{formErrors["PROGRMKS"]}</p>
              )}
            </div>
          </div>
          <div className="row mt-3">
            <div className={"col-lg-12 col-md-12 col-sm-12 mobile-view top-m"}>
              <div className="mb-1">
              <div>
        <Table striped bordered hover>
          {newPreviousConversation?.map((eachApp, index) => (
            <>
              {index == 0 && (
                <thead>
                  <tr>
                    <th className="text-dark col-lg-2 col-md-2 col-sm-2 mobile-view top-m">Date-Time</th>
                    <th className="text-dark col-lg-2 col-md-2 col-sm-2 mobile-view top-m">User Name</th>
                    <th className="text-dark col-lg-2 col-md-2 col-sm-2 mobile-view top-m">Role</th>
                    <th className="text-dark col-lg-6 col-md-6 col-sm-6 mobile-view top-m">Remarks</th>
                  </tr>
                </thead>
              )}
              <tbody>
                <tr>
                  <td>{eachApp?.Date}</td>
                  <td>{eachApp?.ApproverName}</td>
                  <td>{eachApp?.RoleName}</td>
                  <td>{eachApp?.Remarks}</td>
                </tr>
              </tbody>
            </>
          ))}
        </Table>
    </div>
                {/* <label className="col-form-label">Previous Conversation</label>
                <InputForm
                  className="form-control"
                  placeholder={"Previous Conversation"}
                  isDisabled={true}
                  textArea={true}
                  value={
                    attributesData["PROGPRCONVO"]
                      ? attributesData["PROGPRCONVO"]
                      : ""
                  }
                  onChange={(e) => {
                    setAttributesData((prev) => ({
                      ...prev,
                      ["PROGPRCONVO"]: e.target.value,
                    }));
                  }}
                  rows={7}
                /> */}
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ApprovalSectionClm;
