import React, { useState, useEffect, useContext } from "react";
import InputForm from "../../Components/InputForm/InputForm";
import SelectForm from "../../Components/SelectForm/SelectForm";
import { useNavigate } from "react-router-dom";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { Tabs, Tooltip } from "@mui/material";
import { APICall } from "../../Helpers/APICalls";
import { LoaderContext } from "../../Helpers/Context/Context";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import MangeForm from "./MangeForm";
import ManageSequenceHistory from "./ManageSequenceHistory";
import Collapse from "react-bootstrap/Collapse";
import "./ManageSequence.css";
import {
  getSequenceConfigDetails,
  getSequenceCountries,
  getSequencedLegalEntities,
  getSequencedEmployeeClass,
  getSequencedSequenceNumber,
  getPositionSequenceConfigDetails,
  getPositionSequencedSequenceNumberForDD,
} from "../../Helpers/APIEndPoints/EndPoints";
import notify from "../../Helpers/ToastNotification";
import { start } from "repl";
export const defaultTheme = createTheme({});
const { breakpoints } = defaultTheme;

const tabsTheme = {
  ...defaultTheme,
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: "0px !important",
          marginLeft: "-42px",
          [breakpoints.down("md")]: {
            minHeight: "0px !important",
            marginLeft: "0px",
          },
        },
      },
    },
  },
};
function ManageSequence() {
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [activeTab, setActiveTab] = useState("employee_code");
  const [manageSequenceSearch, setManageSequenceSearch] = useState("");
  const [resetGridData,setResetGridData] = useState(false);
  const [multiCountrySelect,setMultiCountrySelect] = useState(null);
  const [multiEmployeeClassSelect,setMultiEmployeeClassSelect] = useState(null);
  const [multiLegalEntitySelect,setMultiLegalEntitySelect] = useState(null);
  const [open, setOpen] = useState(false);
  const [activeType, setactiveType] = useState("");
  const [statusGridData, setStatusGridData] = useState({
    count: 0,
    start: 0,
    pageSize: 10,
    sortColumn: "",
    sortDirection: "",
    data: [],
  });
  const [editid, seteditid] = useState(null);
  const [selectCountry, setSelectCountry] = useState([]);
  const [selectLegalEntity, setLegalEntity] = useState([]);
  const [selectEmployeeClass, setSelectEmployeeClass] = useState([]);
  const [selectSequenceNumber, setSelectSequenceNumber] = useState([]);
  const [datahistory, setDataHistory] = useState("");
  const [selectedValues, setSelectedValues] = useState({
    country: null,
    legal: null,
    employeeclass: null,
    lastsequence: null,
    newsequence: null,
    sequencenumber: null,
    lastsequencenumber: null,
    sequence: null,
  });
  const isPositionTab = activeTab === "position_code";
  const handleHistory = (tablemeta) => {
    if (activeTab === "employee_code") {
      setDataHistory("employee_history");
    }
    if (activeTab === "position_code") {
      setDataHistory("position_history");
    }
    seteditid(tablemeta.rowData[0]);
  };
  const handleEdit = (tablemeta) => {
    if (activeTab === "employee_code") {
      setactiveType("employee_edit");
    }
    if (activeTab === "position_code") {
      setactiveType("position_edit");
    }
    // setActiveTab("edit");
    seteditid(tablemeta.rowData[0]);
    const originalValue =
      activeTab === "position_code"
        ? tablemeta.rowData[7]
        : tablemeta.rowData[5];
    //const originalValue = tablemeta.rowData[4];
    const updatednewsequence =
      (originalValue.startsWith("0") ? "0" : "") + (Number(originalValue) + 1);
    setSelectedValues({
      ...selectedValues,
      country: { label: tablemeta.rowData[1], value: null },
      legal: { label: tablemeta.rowData[2], value: null },
      employeeclass: { label: tablemeta.rowData[3], value: null },
      lastsequence: originalValue,
      newsequence: updatednewsequence,
      sequence: { label: tablemeta.rowData[4], value: null },
    });
  };
  const statusGridOptions = {
    count: statusGridData.count,
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    rowsPerPage: statusGridData.pageSize,
    rowsPerPageOptions: [],
    page: statusGridData.start,
    onChangePage: async (page) => {
      setStatusGridData((prev) => {
        return { ...prev, start: page };
      });
    },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setStatusGridData((prev) => {
          return {
            ...prev,
            sortColumn: sortColumn,
            sortDirection: sortDirection,
          };
        });
      }
      if (sortDirection === "desc") {
        await setStatusGridData((prev) => {
          return {
            ...prev,
            sortColumn: sortColumn,
            sortDirection: sortDirection,
          };
        });
      }
    },
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",
    serverSide: true,
  };
  const statusGridColumns = [
    {
      name: "id",
      label: "Sr No.",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "country",
      label: "Country",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "legalEntity",
      label: "Legal Entity",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "employeeClass",
      label: "Employee Class",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "sequenceNumber",
      label: "Sequence Number",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "lastSequenceNumber",
      label: "Last Sequence Number",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "nextSequenceNumber",
      label: "Next Sequence Number",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "positionLastSequenceNumber",
      label: "Position Last Sequence Number",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "positionNextSequenceNumber",
      label: "Position Next Sequence Number",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "lastModifiedBy",
      label: "Last Modified By",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Action",
      label: "Action",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="d-flex justify-content-start">
              {
                <>
                  <Tooltip title="Edit">
                    <i
                      style={{
                        marginRight: "10px",
                        cursor: "pointer",
                        fontSize: "medium",
                      }}
                      onClick={() => handleEdit(tableMeta)}
                      className="fas fa-edit"
                    ></i>
                  </Tooltip>{" "}
                  <Tooltip title="History">
                    <i
                      style={{
                        marginRight: "0px",
                        cursor: "pointer",
                        fontSize: "medium",
                      }}
                      onClick={() => handleHistory(tableMeta)}
                      className="fas fa-history"
                    ></i>
                  </Tooltip>
                </>
              }
            </div>
          );
        },
      },
    },
  ];
  const updatedStatusGridColumns = statusGridColumns.map((column) => {
    if (
      column.name === "legalEntity" ||
      // column.name === "employeeClass" ||
      column.name === "lastSequenceNumber" ||
      column.name === "nextSequenceNumber"

    ) {
      // Set display to false if it's the 'position' tab, true otherwise
      column.options.display = !isPositionTab;
    }
    if (column.name === "positionLastSequenceNumber"|| column.name ==="positionNextSequenceNumber") {
      column.options.display = isPositionTab;
    }
    return column;
  });
  const handleOnChanges = (selectedOption, fieldName) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [fieldName]: selectedOption,
    }));
  };
  // const handleAddNew = (selectedOption, fieldName) => {
  //   setSelectedValued(prevValues => ({
  //     ...prevValues,
  //     [fieldName]: selectedOption,
  //   }));
  // };
  const getContryDropdown = async () => {
    showLoader();

    try {
      const response = await APICall(getSequenceCountries, "POST", {});

      const { data, status } = response || {};
      setSelectCountry(status === 0 && data ? data : null);
    } catch (error) {
      console.error("Error in fetching Country data", error);
    } finally {
      hideLoader();
    }
  };
  const getLegalDropdown = async () => {
    showLoader();

    try {
      const response = await APICall(getSequencedLegalEntities, "POST", {});

      const { data, status } = response || {};
      setLegalEntity(status === 0 && data ? data : null);
    } catch (error) {
      console.error("Error in fetching Legal Dropdown data", error);
    } finally {
      hideLoader();
    }
  };
  const getEmployeeClassDropdown = async () => {
    showLoader();

    try {
      const response = await APICall(getSequencedEmployeeClass, "POST", {});
      const { data, status } = response || {};
      setSelectEmployeeClass(status === 0 && data ? data : null);
    } catch (error) {
      console.error("Error in fetching Employee Class Dropdown data", error);
    } finally {
      hideLoader();
    }
  };
  const getSequencedSequenceNumberDropdown = async () => {
    showLoader();
    try {
      let apifun;
      if (activeTab === "position_code") {
        apifun = getPositionSequencedSequenceNumberForDD;
      } else {
        apifun = getSequencedSequenceNumber;
      }
      const response = await APICall(apifun, "POST", {});
      const { data, status } = response || {};
      setSelectSequenceNumber(status === 0 && data ? data : null);

      return data;
    } catch (error) {
      console.error("Error in fetching Sequence Dropdown data", error);
    } finally {
      hideLoader();
    }
  };
  const getGridStructure = async () => {
    const getCommaSeparatedValuesForEmployee = (data) => {
      if (!data) return "";
      return data.map((item) => item.value).join(",");
    };
    showLoader();
    try {
      let apiFunction;
      let apiCallObject;
      if (activeTab === "position_code") {
        apiFunction = getPositionSequenceConfigDetails;
        apiCallObject = {  PageNumber: statusGridData.start,
          PageSize: statusGridData.pageSize,
          SortColumn: statusGridData.sortColumn,
          SortOrder: statusGridData.sortDirection,
          SearchText: manageSequenceSearch?manageSequenceSearch:"",
          Country: (multiCountrySelect && multiCountrySelect.length>0)
          ? getCommaSeparatedValuesForEmployee(multiCountrySelect)
          : "", 
        }
      } else {
        apiFunction = getSequenceConfigDetails;
        apiCallObject = {  PageNumber: statusGridData.start,
          PageSize: statusGridData.pageSize,
          SortColumn: statusGridData.sortColumn,
          SortOrder: statusGridData.sortDirection,
          SearchText: manageSequenceSearch?manageSequenceSearch:"",
          Country: (multiCountrySelect && multiCountrySelect.length>0)
          ? getCommaSeparatedValuesForEmployee(multiCountrySelect)
          : "", 
          LegalEntity :(multiLegalEntitySelect && multiLegalEntitySelect.length>0)
          ? getCommaSeparatedValuesForEmployee(multiLegalEntitySelect)
          : "", 
          EmployeeClass :(multiEmployeeClassSelect && multiEmployeeClassSelect.length>0)
          ? getCommaSeparatedValuesForEmployee(multiEmployeeClassSelect)
          : "", 
        }
      }

      const dashboardData = await APICall(apiFunction, "POST",apiCallObject);
      const { data } = dashboardData || {};

      setStatusGridData((prev) => ({
        ...prev,
        count: data && data.length > 0 ? data[0].count : 0,
        data: data || [],
      }));
    } catch (error) {
      console.error("Error in Loading Dahboard Data", error);
    } finally {
      hideLoader();
    }
  };
  const handlesetTab = (active) =>{
    setActiveTab(active);
    resetFilters();
  }
  const onSubmitFilter = ()=>{
    if(multiCountrySelect && multiCountrySelect.length > 0 ||
      multiLegalEntitySelect && multiLegalEntitySelect.length > 0 ||
      multiEmployeeClassSelect && multiEmployeeClassSelect.length > 0){
        getGridStructure();
        setOpen(false);
      }
      else{
        notify(1,"Please fill any one data");
      }
         
  }
  const handleActiveAddNew = () => {
    if (activeTab === "employee_code") {
      setactiveType("employee_addNew");
      setSelectedValues({
        ...selectedValues,
        country: null,
        legal: null,
        employeeclass: null,
        lastsequence: null,
        newsequence: null,
        sequencenumber: null,
        lastsequencenumber: null,
        sequence: null,
      });
    }
    if (activeTab === "position_code") {
      setactiveType("position_addNew");
      setSelectedValues({
        ...selectedValues,
        country: null,
        legal: null,
        employeeclass: null,
        lastsequence: null,
        newsequence: null,
        sequencenumber: null,
        lastsequencenumber: null,
        sequence: null,
      });
    }
  };
  const resetFilters = ()=>{
    setManageSequenceSearch('');
    setMultiCountrySelect(null);
  setMultiEmployeeClassSelect(null);
  setMultiLegalEntitySelect(null);
  setResetGridData(!resetGridData);
  };
  useEffect(() => {
    getContryDropdown();
    getLegalDropdown();
    getEmployeeClassDropdown();
    getSequencedSequenceNumberDropdown();
    setManageSequenceSearch("");
  }, [activeTab, activeType]);
  useEffect(() => {
    getGridStructure();
    setOpen(false);
  }, [
    activeTab,
    activeType,
    statusGridData.start,
    statusGridData.sortColumn,
    statusGridData.sortDirection,
    manageSequenceSearch,
    resetGridData,
  ]);
  return (
    <div>
      <div className="row mt-3 mx-3">
        <div className="col-lg-8 mt-3">
          <ThemeProvider theme={tabsTheme}>
            <Tabs
              value={activeTab}
              className="profile-tabs"
              onChange={() => {}}
              variant="scrollable"
              TabScrollButtonProps={{
                style: {
                  color: "white",
                },
              }}
              scrollButtons
              TabIndicatorProps={{
                style: { display: "none" },
              }}
              allowScrollButtonsMobile
              aria-label="scrollable tabs"
            >
              <div className="tabBtn">
                <ul>
                  <li>
                    <button
                      onClick={() => handlesetTab("employee_code")}
                      //onClick={() => setActiveTab("employee_code")}
                      className={
                        activeTab === "employee_code" ? "bttn active" : "bttn"
                      }
                    >
                      Employee Code
                    </button>
                    <button
                     onClick={() => handlesetTab("position_code")}
                      //onClick={() => setActiveTab("position_code")}
                      className={
                        activeTab === "position_code" ? "bttn active" : "bttn"
                      }
                    >
                      Position Code
                    </button>
                  </li>
                </ul>
              </div>
            </Tabs>
          </ThemeProvider>
        </div>
        <div className="col-lg-4 my-1">
          <div className="d-flex align-items-center justify-content-end">
            {activeType !== "employee_edit" &&
              activeType !== "position_edit" &&
              activeType !== "employee_addNew" &&
              activeType !== "position_addNew" && (
                <button
                  className="btn btn-primary mr-2"
                  onClick={handleActiveAddNew}
                >
                  <i className="fa fa-plus mr-2"></i>
                  New
                </button>
              )}

            <button
              className="btn btn-primary mr-2"
              onClick={() => {
                if (
                  activeType === "employee_addNew" ||
                  activeType === "employee_edit"
                ) {

                    

                  handlesetTab("employee_code");
                  setactiveType("");
                } else if (
                  activeType === "position_addNew" ||
                  activeType === "position_edit"
                ) {
                  handlesetTab("position_code");
                  setactiveType("");
                } else {
                  navigate(-1);
                }
              }}
            >
              <i className="fa-solid fa-arrow-left mr-2"></i>
              Back
            </button>
          </div>
        </div>
      </div>
      <div className="customized-Grid-Wrapper grid-wrapper grid_mob"  style={{ marginLeft: '15px', marginRight: '15px' }}>
        <div className="row mx-auto filtBox">
        {(activeTab === "employee_code" || activeTab === "position_code") &&
          activeType !== "employee_addNew" &&
          activeType !== "position_addNew" &&
          activeType !== "employee_edit" &&
          activeType !== "position_edit" && (
            <>
        <div className="col-lg-3 col-md-3 col-sm-12">
                  <div className="form-group filtInp">
                    <InputForm
                      value={manageSequenceSearch}
                      placeholder={"Search"}
                      isDisabled={false}
                      textArea={false}
                      onChange={(event) =>{ 
                        setStatusGridData((prev) => {
                          return { ...prev, start: 0 };
                        });
                        setManageSequenceSearch(event.target.value);}
                       
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-1 col-md-1 col-sm-12 filtrt">
            <ul className=" filter-icon-wel">
              <li>
                <a
                  href="javascript:void(0);"
                  onClick={() => setOpen(!open)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                  className="filter-btn"
                >
                  <i className="fa fa-filter"></i>
                </a>
              </li>
            </ul>
          </div></>)}
        </div>
      </div>
      <div className="col-lg-12 col-md-10 col-sm-12 ">
        <Collapse in={open}>
          <div id="example-collapse-text grid-wrapper">
            <div className="row mx-auto myInlineStyle">
            <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1">
            <label className="col-form-label" style={{color:"#fff"}}>
              Country
            </label>
            <div className="">
              <SelectForm
                isClearable
                options={selectCountry}
                placeholder={"Select Country"}
                isDisabled={false}
                onChange={(e) =>
                  {setMultiCountrySelect(e)}
                }
                isMulti={true}
                noIndicator={false}
                noSeparator={false}
                value={multiCountrySelect}
              />
            </div>
          </div>

        </div>
        {activeTab !== "position_code" && (
        <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1 ">
              <label className="col-form-label" style={{color:"#fff"}}>
                Legal Entity
              </label>
              <div className="">
                <SelectForm
                  isClearable
                  options={selectLegalEntity}
                  placeholder={"Select Legal Entity"}
                  isDisabled={false}
                  onChange={(e) =>
                    {setMultiLegalEntitySelect(e)}
                  }
                  isMulti={true}
                  noIndicator={false}
                  noSeparator={false}
                  value={multiLegalEntitySelect}
                />
              </div>
            </div>
          </div>
        )}
        {activeTab !== "position_code" && (
          <div className="col-lg-3 col-sm-3 col-xs-4" >
            <div className="mb-1 ">
              <label className="col-form-label" style={{color:"#fff"}}>
                Employee Class
              </label>
              <SelectForm
                isClearable
                options={selectEmployeeClass}
                placeholder={"Select Employee Class"}
                isDisabled={false}
                onChange={(e) =>
                  {setMultiEmployeeClassSelect(e)}
                }
                isMulti={true}
                noIndicator={false}
                noSeparator={false}
                value={multiEmployeeClassSelect}
              />

            </div>
          </div>
        )}
              <div className="col-lg-12 d-flex mt-2 justify-content-end">
                <button
                  onClick={resetFilters}
                  className="btn btn-secondary mr-2"
                >
                  <i className="fa fa-undo" aria-hidden="true"></i> Reset{" "}
                </button>
                <button
                  className="btn btn-filter-submit"
                  onClick={onSubmitFilter}
                >
                  <i className="fa fa-check" aria-hidden="true"></i> Submit{" "}
                </button>
              </div>
            </div>
          </div>
        </Collapse>
      
      </div>
      <div className="mt-2">
        {(activeTab === "employee_code" || activeTab === "position_code") &&
          activeType !== "employee_addNew" &&
          activeType !== "position_addNew" &&
          activeType !== "employee_edit" &&
          activeType !== "position_edit" && (
            <DynamicGrid
              options={statusGridOptions}
              data={statusGridData.data || []}
              columns={updatedStatusGridColumns}
            />
          )}
      </div>
      <div className="mt-2">
        {/* {activeTab === "position_code" && (
          <DynamicGrid
            options={statusGridOptions}
            data={statusGridData || []}
            columns={updatedStatusGridColumns}
          />
        )} */}
        {/* employee_addNew position_addNew */}
        {(activeType === "employee_addNew" ||
          activeType === "position_addNew" ||
          activeType === "employee_edit" ||
          activeType === "position_edit") && (
          <MangeForm
            selectCountry={selectCountry}
            selectLegalEntity={selectLegalEntity}
            handleOnChange={handleOnChanges}
            selectedValue={selectedValues}
            selectEmployeeClass={selectEmployeeClass}
            selectSequenceNumber={selectSequenceNumber}
            data={activeType}
            dropdown={getSequencedSequenceNumberDropdown}
            editid={editid}
            setSelectedValue={setSelectedValues}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            setactiveType={setactiveType}
          />
        )}
        {/* {activeTab === "add_new" && (
          <MangeForm
            selectCountry={selectCountry}
            selectLegalEntity={selectLegalEntity}
            handleOnChange={handleOnChange}
            selectedValue={selectedValue}
            selectEmployeeClass={selectEmployeeClass}
            selectSequenceNumber={selectSequenceNumber}
            data={activeType}
          />
        )} */}
        {(datahistory === "employee_history" ||
          datahistory === "position_history") && (
          <ManageSequenceHistory
            key={datahistory}
            tabsactive={datahistory}
            editid={editid}
            setDataHistory={setDataHistory}
          />
        )}
      </div>
    </div>
  );
}

export default ManageSequence;
