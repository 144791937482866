import React, { useState, useEffect, useContext } from "react";
import SelectForm from "../../Components/SelectForm/SelectForm";
import InputForm from "../../Components/InputForm/InputForm";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { Tabs, Tooltip } from "@mui/material";
import EmployeeConsentTemplate from "./EmployeeConsentTemplate";
import EmployeeConsentDelete from "./EmployeeConsentDelete";
import { APICall } from "../../Helpers/APICalls";
import { useNavigate } from "react-router-dom";
import { getTemplateDashboard,
  employeeExportToExcelDashboard
} from "../../Helpers/APIEndPoints/EndPoints";
import { LoaderContext } from "../../Helpers/Context/Context";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import notify from "../../Helpers/ToastNotification";
import moment from "moment";
import fileDownload from "js-file-download";
import { base64ToArrayBuffer } from "../../Helpers/Common";
function EmployeeConsent() {
  const navigate = useNavigate();
  let { userDetails, proxyUserDetails, currentRoleId }: any = useUserContext();
  const [templateNameSearch, setTemplateNameSearch] = useState(null);
  const [showModalEmpCon, setShowModalEmpCon] = useState(false);
  const [eventactive, setEventactive] = useState(null);
  const [deletestate, setDeletestate] = useState(null);
  const [editid, seteditid] = useState(null);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [statusValue, setStatusValue] = useState(null);
  const [templatePage, setTemplatePage] = useState(false);
  const [tableMetaData, settableMetaData] = useState(null);
  const [statusGridData, setStatusGridData] = useState({
    count: 0,
    start: 0,
    pageSize: 10,
    sortColumn: "",
    sortDirection: "",
    data: [],
  });
  const employeeConsentColumns = [
    {
      name: "id",
      label: "Sr No.",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: "templateId",
      label: "Template Id",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "templateName",
      label: "Template Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "country",
      label: "Country",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const countrydata = tableMeta.tableData?.[tableMeta.rowIndex][3];
          if (countrydata) {
            const countries = countrydata
              .split(",")
              .map((country) => country.trim());
            const formattedCountry =
              countries[0].charAt(0).toUpperCase() +
              countries[0].slice(1).toLowerCase();
            if (countries.length > 1) {
              return (
                <Tooltip title={countrydata} arrow>
                  <div>{formattedCountry},...</div>
                </Tooltip>
              );
            } else {
              return (
                <Tooltip title={countrydata} arrow>
                  <div>{formattedCountry}</div>
                </Tooltip>
              );
            }
          } else {
            return null;
          }
        },
      },
    },
    {
      name: "payroll",
      label: "Payroll",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const payrolldata = tableMeta.tableData?.[tableMeta.rowIndex][4];
          if (payrolldata) {
            const payrolls = payrolldata
              .split(",")
              .map((payroll) => payroll.trim());
            const formattedpayrolldata =
              payrolls[0].charAt(0).toUpperCase() +
              payrolls[0].slice(1).toLowerCase();
            if (payrolls.length > 1) {
              return (
                <Tooltip title={payrolldata} arrow>
                  <div>{formattedpayrolldata},...</div>
                </Tooltip>
              );
            } else {
              return (
                <Tooltip title={payrolldata} arrow>
                  <div>{formattedpayrolldata}</div>
                </Tooltip>
              );
            }
          } else {
            return null;
          }
        },
      },
    },
    {
      name: "createdByName",
      label: "Created By",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "createdDate",
      label: "Created On",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "launchByName",
      label: "Launched By",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "launchOn",
      label: "Launched On",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "templateStatus",
      label: "Status",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="d-flex justify-content-start">
              {
                <>
                  <Tooltip title="Edit">
                    <i
                      style={{
                        marginRight: "10px",
                        cursor: "pointer",
                        fontSize: "medium",
                      }}
                      onClick={() => handleEditEmployeeConsent(tableMeta)}
                      className="fas fa-edit"
                    ></i>
                  </Tooltip>{" "}
                  {tableMeta.tableData[tableMeta.rowIndex][9] === "Inactive" ?
                (<></>)
                :(
                  <Tooltip title="Inactive">
                    <i
                      style={{
                        marginRight: "0px",
                        cursor: "pointer",
                        fontSize: "medium",
                      }}
                      onClick={() =>
                        handleDeleteEmployeeConsent(
                          tableMeta.tableData[tableMeta.rowIndex]
                        )
                      }
                      className="fas fa-ban"
                    ></i>
                  </Tooltip>
                )}
                  
                </>
              }
            </div>
          );
        },
      },
    },
  ];
  const employeeConsentOptions = {
    count: statusGridData.count,
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    rowsPerPage: statusGridData.pageSize,
    rowsPerPageOptions: [],
    page: statusGridData.start,
    onChangePage: async (page) => {
      setStatusGridData((prev) => {
        return { ...prev, start: page };
      });
    },
    // onColumnSortChange: async (sortColumn, sortDirection) => {
    //   if (sortDirection === "asc") {
    //     await setStatusGridData((prev) => {
    //       return {
    //         ...prev,
    //         sortColumn: sortColumn,
    //         sortDirection: sortDirection,
    //       };
    //     });
    //   }
    //   if (sortDirection === "desc") {
    //     await setStatusGridData((prev) => {
    //       return {
    //         ...prev,
    //         sortColumn: sortColumn,
    //         sortDirection: sortDirection,
    //       };
    //     });
    //   }
    // },
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",
    serverSide: true,
  };
  
  const handleCreateTemplateEmployeeConsent = () => {
    setTemplatePage(true);
    setEventactive("employeeConsentCreate");
  };
  const handleChanges = (event, fieldname) => {
    if (fieldname === "Status") {
      setStatusValue(event);
      setStatusGridData((prev) => {
        return { ...prev, start: 0 };
      });
    }
    if (fieldname === "TemplateNameSearch") {
      setTemplateNameSearch(event);
    }
  };
  const handleLaunchAgain = () => {
    setShowModalEmpCon(true);
  };
  const handleEditEmployeeConsent = (tableMeta) => {
    setTemplatePage(true);
    setEventactive("employeeConsentEdit");
    settableMetaData(tableMeta);
  };
  const handleDeleteEmployeeConsent = (tableMeta) => {
    setDeletestate(true);
    settableMetaData(tableMeta);
  };
  const getTemplateDashboardFunc = async () => {
    showLoader();
    try {
      const responsedata = await APICall(getTemplateDashboard, "POST", {
        SearchText: templateNameSearch ? templateNameSearch : "",
        Start: statusGridData.start,
        PageSize: statusGridData.pageSize,
        SortColumn: statusGridData.sortColumn,
        SortDirection: statusGridData.sortDirection,
        Status: statusValue ? statusValue.value : null,
      });

      if (responsedata?.status === 0 && responsedata?.data) {
        setStatusGridData((prevState) => ({
          ...prevState,
          count:
            responsedata.data && responsedata.data.length > 0
              ? responsedata.data[0].totalCount
              : 0,
          data: responsedata.data || [],
        }));
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error in Dashboard Loading:", error);
      return null;
    } finally {
      hideLoader();
    }
  };
     
  const handleExportToExcel= async ()=>{
    showLoader();
    try{
      
      const response = await APICall(
        employeeExportToExcelDashboard,
        "POST",
        {
          SearchText: templateNameSearch ? templateNameSearch : "",
          Status: statusValue ? statusValue.value : null,
        }
      );

      if (response?.status == 0) {
        const { excelFileBase64 } = response?.data;

        if (excelFileBase64) {
          const fileName = `EmployeeConsent_${moment().format(
            "DD-MM-YYYY HH:mm:ss"
          )}.xlsx`;
          const base64 = excelFileBase64;

          let arrayBuffer = await base64ToArrayBuffer(base64);
          var byteArray = new Uint8Array(arrayBuffer);

          let blob = new Blob([byteArray], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          const newFile = new File([blob], "", {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            lastModified: new Date().getTime(),
          });

          fileDownload(newFile, fileName);
        }
      } else {
        notify(1, "Something went wrong, Please Try again Later.");
      }
    }
    catch (error) {
      console.error("Error in Export To Excel Loading:", error);
      return null;
    } finally {
      hideLoader();
    }
  };
  const handleTemplatePageFalse = () => {
    setTemplatePage(false);
    setShowModalEmpCon(false);
  };
  useEffect(() => {
    getTemplateDashboardFunc();
  }, [
    deletestate,
    templatePage,
    statusGridData.start,
    statusValue,
    templateNameSearch,
  ]);
  useEffect(() => {
    getTemplateDashboardFunc();
  }, []);
  useEffect(() => {
    setStatusGridData((prev) => {
      return { ...prev, start: 0 };
    });
  }, [templatePage]);
  return (
    <div>
      <div className="container-fluid">
        <div className="row mt-3 px-3">
          <div className="col-lg-12 my-1 p-0">
            <div className="d-flex justify-content-between align-items-center mb-1">
              <button
                onClick={() => navigate("/home")}
                className="btn btn-primary">
                <i className="fa-solid fa-arrow-left mr-1"></i> Back
              </button>
              <div className="d-flex align-items-center justify-content-end">
                {!templatePage ? (
                  <>
                    <button
                      className="btn btn-primary mr-3"
                      onClick={handleCreateTemplateEmployeeConsent}
                    >
                      <i className="fa fa-plus mr-2"></i>
                      {/* Create Template */}
                      Manage Template
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={handleExportToExcel}
                    >
                      Export to excel
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="btn btn-primary mr-3"
                      onClick={handleTemplatePageFalse}
                    >
                      <i className="fa-solid fa-arrow-left mr-2"></i>
                      Back
                    </button>
                    <button
                      className="btn btn-primary mr-3"
                      onClick={handleLaunchAgain}
                    >
                      <i className="fa fa-plus mr-2"></i>
                      {eventactive === "employeeConsentEdit" ? (

                        <span>Action</span>
                        // <span>Launch Again</span>
                      ) : (
                        <span>Launch</span>
                      )}
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="customized-Grid-Wrapper grid-wrapper grid_mob my-2">
          <div className="row mx-auto filtBox">
            {!templatePage ? (
              <>
                <div className="col-lg-3 col-md-4 col-sm-12">
                  <div className="form-group filtInp">
                    <span className="mr-2 text-white" style={{ float: "left" }}>
                      <label className="col-form-label">Status</label>
                    </span>
                    <SelectForm
                      isClearable
                      options={[
                        { label: "Active", value: 1 },
                        { label: "Inactive", value: 0 },
                      ]}
                      placeholder={"Status"}
                      isDisabled={false}
                      onChange={(event) => handleChanges(event, "Status")}
                      isMulti={false}
                      noIndicator={false}
                      noSeparator={false}
                      value={statusValue}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12">
                  <div className="form-group filtInp">
                    <InputForm
                      value={templateNameSearch}
                      placeholder={"Search Template"}
                      isDisabled={false}
                      textArea={false}
                      onChange={(event) =>
                        handleChanges(event.target.value, "TemplateNameSearch")
                      }
                    />
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        {templatePage ? (
          <EmployeeConsentTemplate
            eventactive={eventactive}
            showModalEmpCon={showModalEmpCon}
            setShowModalEmpCon={setShowModalEmpCon}
            tableMetaData={tableMetaData}
            setTemplatePage={setTemplatePage}
          />
        ) : (
          <>
            <div className="mt-2 pt-2">
              <DynamicGrid
                options={employeeConsentOptions}
                data={statusGridData.data || []}
                columns={employeeConsentColumns}
              />
            </div>
          </>
        )}
        {deletestate && (
          <EmployeeConsentDelete
            deletestate={deletestate}
            tableMetaData={tableMetaData}
            setDeletestate={setDeletestate}
            settableMetaData={settableMetaData}
          />
        )}
      </div>
    </div>
  );
}
export default EmployeeConsent;
