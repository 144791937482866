import React from "react";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import "./DynamicGrid.css";
declare module "@mui/material/styles" {
  interface Components {
    [key: string]: any;
  }
}

const DynamicGrid = (props: any, ...rest: any) => {
  const muiCache = createCache({
    key: "mui",
    prepend: true,
  });

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              border: "1px solid #dee2e6",
              outline: "none",
              boxShadow: "none",
              overflowX: "auto",
            },
          },
        },

        MUIDataTableToolbar: {
          styleOverrides: {
            root: {
              backgroundColor: "#efefef",
              color: "black",
            },
            icon: {
              color: "black",
              "&:hover": {
                color: "black",
              },
            },
            iconActive: {
              color: "black",
            },
          },
        },
        MUIDataTableSearch: {
          styleOverrides: {
            searchIcon: {
              color: "white",
            },
            searchText: {
              "& input": {
                color: "white",
              },
            },
            clearIcon: {
              color: "white",
            },
          },
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              backgroundColor: "#efefef",
              color: "black",
              paddingTop: "4px",
              paddingBottom: "4px",
              fontFamily: "Segoe UI",
              fontSize: "12px",
            },
            sortActive: {
              color: "black",
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              fontFamily: "Segoe UI",
              fontSize: "12px",
              paddingTop: "8px",
              paddingBottom: "8px",
            },
          },
        },

        MuiTableBody: {
          styleOverrides: {
            root: {
              "&.emptyTitle": { fontFamily: "Segoe UI", fontSize: "12px" },
            },
          },
        },

        MUIDataTableSelectCell: {
          styleOverrides: {
            headerCell: {
              backgroundColor: "#3c3c3c",
              color: "white",
              fontFamily: "Segoe UI",
              fontSize: "12px",
            },
            sortActive: {
              color: "black",
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              fontFamily: "Segoe UI",
              fontSize: "12px",
            },
          },
        },
        MuiButtonBase: {
          styleOverrides: {
            root: {
              "& .MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon": {
                color: "black",
              },
            },
          },
        },
        MUIDataTableBodyRow: {
          styleOverrides: {
            root: {
              ":nth-of-type(0)": {
                borderBottom: props.showBorderLine ? "5px solid #dbd9d9" : "",
              },
              ":nth-of-type(1)": {
                borderBottom: props.showBorderLine ? "5px solid #dbd9d9" : "",
              },
              ":nth-of-type(2)": {
                borderBottom: props.showBorderLine ? "5px solid #dbd9d9" : "",
              },
              ":nth-of-type(12)": {
                borderBottom: props.showBorderLine ? "5px solid #dbd9d9" : "",
              },
              ":nth-of-type(24)": {
                borderBottom: props.showBorderLine ? "5px solid #dbd9d9" : "",
              },
            },
          },
        },
      },
    });

  // const data = [
  //   { name: "Joe James", company: "Test Corp", city: "Yonkers", state: "NY" },
  //   { name: "John Walsh", company: "Test Corp", city: "Hartford", state: "CT" },
  //   { name: "Bob Herm", company: "Test Corp", city: "Tampa", state: "FL" },
  //   {
  //     name: "James Houston",
  //     company: "Test Corp",
  //     city: "Dallas",
  //     state: "TX",
  //   },
  // ];

  // const columns = [
  //   {
  //     name: "Id",
  //     label: "Id",
  //     options: {
  //       filter: false,
  //       sort: false,
  //       display: false,
  //     },
  //   },
  //   {
  //     name: "name",
  //     label: "Name",
  //     options: {
  //       filter: false,
  //       sort: true,
  //       sortDescFirst: true,
  //     },
  //   },
  //   {
  //     name: "company",
  //     label: "ABR",
  //     options: {
  //       filter: false,
  //       sort: true,
  //       sortDescFirst: true,
  //     },
  //   },
  //   {
  //     name: "",
  //     label: "Action",
  //     options: {
  //       filter: false,
  //       sort: false,
  //       customBodyRender: (value, tableMeta) => {
  //         let EncryptedParam =
  //           tableMeta.tableData[tableMeta.rowIndex].EncryptedParam;
  //         let SPHostUrl =
  //           "https://prosaressolutions.sharepoint.com/sites/LegaDoxV2/";
  //         let actionUrl = `/Organization/Edit?PSId=${EncryptedParam}&SPHostUrl=${SPHostUrl}`;

  //         return (
  //           <>
  //             <a href={actionUrl}>
  //               <i className="fa fa-pencil" aria-hidden="true"></i>
  //             </a>
  //           </>
  //         );
  //       },
  //     },
  //   },
  // ];

  return (
    <div className="px-3">
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            className="text-align-center table_grid"
            title={props.title}
            data={props.data}
            columns={props.columns}
            options={props.options}
            components={props.components}
          />
        </ThemeProvider>
      </CacheProvider>
    </div>
  );
};

export default DynamicGrid;
