import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import "../../Pages/Progression/ChangeLog.css";
import { Table } from "react-bootstrap";
import "./EmployeeConsent.css";
import { APICall } from "../../Helpers/APICalls";
import { LoaderContext } from "../../Helpers/Context/Context";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import notify from "../../Helpers/ToastNotification";
import { deleteTemplateConsent } from "../../Helpers/APIEndPoints/EndPoints";

function EmployeeConsentDelete({
  deletestate,
  tableMetaData,
  setDeletestate,
  settableMetaData,
}) {
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [showModal, setShowModal] = useState(false);
  const handleModalClose = () => {
    setShowModal(false);
    setDeletestate(false);
    settableMetaData(null);
  };
  const handleDelete = async (empid) => {
    showLoader();
    try {
      const UpdateTemplateStatus = await APICall(
        deleteTemplateConsent,
        "POST",
        {
          TemplateId: empid,
        }
      );
      const response = UpdateTemplateStatus;
      setShowModal(false);
      setDeletestate(false);
      settableMetaData(null);
      if (response?.status === 0 && response?.data) {
        notify(0, "Record Deleted Successfully!");
        return;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error in Deleting Template:", error);
      return null;
    } finally {
      hideLoader();
    }
  };
  useEffect(() => {
    if (deletestate) {
      showLoader();
      setShowModal(true);
      hideLoader();
    }
  }, [deletestate, showLoader, setShowModal]);
  return (
    <Modal
      show={showModal}
      onHide={handleModalClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      centered
    >
      <div
        className="bg-secondary text-white"
        style={{
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        <div className="col-lg-12">Inactive Template</div>
      </div>
      <Modal.Body>
        <p className="col-form-label">Template Name: {tableMetaData[2]}</p>
        <p className="col-form-label">
          Are you sure you want inactive this template
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          disabled={false}
          className="btn btn-secondary ml-3"
          onClick={handleModalClose}
        >
          Close
        </button>
        <button
          type="button"
          disabled={false}
          className="btn btn-secondary ml-3"
          onClick={() => {
            handleDelete(tableMetaData[0]);
          }}
        >
          Submit
        </button>
      </Modal.Footer>
    </Modal>
  );
}
export default EmployeeConsentDelete;
