import React, { useContext, useEffect, useState } from "react";
import { LoaderContext } from "../../Helpers/Context/Context";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { APICall } from "../../Helpers/APICalls";
import {
  getSessionScheduleAttendance,
  saveSessionScheduleAttendance,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import notify from "../../Helpers/ToastNotification";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import { ExpandMore, WidthFull } from "@mui/icons-material";

const SessionScheduleGrid = ({
  checkSessionScheduleCheckboxes,
  sessionScheduleData,
  courseData,
  setCourseData,
}) => {
  const [dashboard, setdashboard] = useState([]);
  const [dashboardPageSize, setDashboardPageSize] = useState(10);
  const [dashboardStart, setDashboardStart] = useState(0);
  const [dashboardSortColumn, setDashboardSortColumn] = useState<any>("");
  const [dashboardSortDirection, setDashboardSortDirection] = useState("");
  const [dashboardCount, setDashboardCount] = useState(0);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();

  const [sessionAccordian, setSessionAccordian] = useState(true);
  const [isAllCheck, setIsAllCheck] = useState(false);

  useEffect(() => {
    if (sessionAccordian) {
      getSessionScheduleAttendanceApiCall(
        sessionScheduleData?.SessionScheduleId
      );
    }
  }, [sessionAccordian]);

  const getSessionScheduleAttendanceApiCall = async (SessionScheduleId) => {
    showLoader();
    // if (sessionScheduleData?.SessionScheduleId > 0) {
    const response = await APICall(getSessionScheduleAttendance, "POST", {
      SessionScheduleId: SessionScheduleId,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    if (response?.status === 0 && response?.data.length > 0) {
      setIsAllCheck(sessionScheduleData?.SessionScheduleChecked);
      const participantsArray = [];

      const dashboardDataArray = response?.data?.map((data) => {
        // add existing present users in array
        if (data?.isChecked) {
          participantsArray.push(data.userId);
        }

        return {
          ...data,
          isChecked:
            data?.isChecked || sessionScheduleData?.SessionScheduleChecked,
        };
      });

      setdashboard(dashboardDataArray);
      setDashboardCount(dashboardDataArray.length);

      setCourseData((prev) => {
        return prev.map((course) => {
          const updatedCourse = {
            ...course,
            CS: course?.CS?.map((courseSchedule) => {
              const updatedCourseSchedule = {
                ...courseSchedule,
                SS: courseSchedule?.SS?.map((sessionSchedule) => {
                  if (
                    sessionSchedule?.SessionScheduleId === SessionScheduleId
                  ) {
                    return {
                      ...sessionSchedule,
                      Participants: participantsArray,
                    };
                  } else {
                    return {
                      ...sessionSchedule,
                    };
                  }
                }),
              };
              return updatedCourseSchedule;
            }),
          };
          return updatedCourse;
        });
      });
    } else if (response?.status === 1) {
      setdashboard([]);
      notify(response?.status, response?.message);
    }
    // }
    hideLoader();
  };

  const markParticipantAttendanceApiCall = async (
    sessionScheduleId,
    userId,
    isChecked
  ) => {
    const response = await APICall(saveSessionScheduleAttendance, "POST", {
      SessionScheduleId: sessionScheduleId,
      ParticipanteUserId: userId,
      IsChecked: isChecked,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    if (response?.status === 0) {
      await getSessionScheduleAttendanceApiCall(sessionScheduleId);

      notify(0, "Attendance status updated sucessfully");
    } else if (response?.status === 1) {
      notify(response?.status, response?.message);
    }
  };

  const handleSelectAllParticipantsCheckbox = (
    isChecked,
    sessionScheduleId,
    userId,
    checkOption
  ) => {
    debugger;

    let participantsArray = [];

    if (checkOption === "singleCheck") {
      setdashboard((prev) => {
        return prev?.map((data) => {
          if (
            data?.sessionScheduleId === sessionScheduleId &&
            data?.userId === userId
          ) {
            participantsArray.push(userId);
            return {
              ...data,
              isChecked: isChecked, // Corrected variable name
            };
          }
          // else {
          //   if (data?.isChecked) {
          //     // if (Array.isArray(participantsArray)) {
          //     const isDataExist = participantsArray?.some(
          //       (ele) => ele === data?.userId
          //     );

          //     if (!isDataExist) {
          //       participantsArray.push(data?.userId);
          //     }
          //     // }

          //     // else {
          //     //   participantsArray.push(userId);
          //     // }
          //   }
          // }
          return data;
        });
      });

      setCourseData((prev) => {
        return prev.map((course) => {
          const updatedCourse = {
            ...course,
            CS: course?.CS?.map((courseSchedule) => {
              const updatedCourseSchedule = {
                ...courseSchedule,
                SS: courseSchedule?.SS?.map((sessionSchedule) => {
                  if (
                    sessionSchedule?.SessionScheduleId === sessionScheduleId
                  ) {
                    if (Array.isArray(sessionSchedule?.Participants)) {
                      const isDataExist = sessionSchedule?.Participants?.some(
                        (ele) => ele === userId
                      );
                      if (isChecked) {
                        if (!isDataExist) {
                          sessionSchedule?.Participants.push(userId);

                          participantsArray = sessionSchedule?.Participants;
                        }
                      } else {
                        if (sessionSchedule?.Participants.includes(userId)) {
                          const index =
                            sessionSchedule?.Participants.indexOf(userId);

                          const x = sessionSchedule?.Participants.splice(
                            index,
                            1
                          );

                          participantsArray = sessionSchedule?.Participants;
                        }
                      }
                    }

                    return {
                      ...sessionSchedule,
                      Participants: participantsArray,
                    };
                  } else {
                    return {
                      ...sessionSchedule,
                    };
                  }
                }),
              };
              return updatedCourseSchedule;
            }),
          };
          return updatedCourse;
        });
      });
    } else {
      setdashboard((prev) => {
        return prev?.map((data) => {
          if (isChecked) {
            participantsArray.push(data.userId);
          } else {
            participantsArray = [];
          }
          return {
            ...data,
            isChecked: isChecked,
          };
        });
      });

      setCourseData((prev) => {
        return prev.map((course) => {
          const updatedCourse = {
            ...course,
            CS: course?.CS?.map((courseSchedule) => {
              const updatedCourseSchedule = {
                ...courseSchedule,
                SS: courseSchedule?.SS?.map((sessionSchedule) => {
                  if (
                    sessionSchedule?.SessionScheduleId === sessionScheduleId
                  ) {
                    return {
                      ...sessionSchedule,
                      Participants: participantsArray,
                    };
                  } else {
                    return {
                      ...sessionSchedule,
                    };
                  }
                }),
              };
              return updatedCourseSchedule;
            }),
          };
          return updatedCourse;
        });
      });
    }
  };

  const dashboardOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: dashboardCount,
    rowsPerPage: dashboardPageSize,
    serverSide: false,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",
    // onTableChange: (action, state) => {
    //   console.log(action);
    //   console.dir(state);
    // },
    // selectableRowsHeader: true,
    // selectableRowsOnClick: true,

    //standard | vertical | simple
    onChangeRowsPerPage: (num) => {
      //   setLimit(num);
      //   setNxtPgInfo("");
      //   setPrevPgInfo("");
      //   setIsPrevOrNext("");
    },
    onSearchChange: (searchText) => {
      if (searchText !== null) {
        //setDashboardSearchText(searchText);
      } else {
        //setDashboardSearchText("");
      }
    },
    // onColumnSortChange: async (sortColumn, sortDirection) => {
    //   if (sortDirection === "asc") {
    //     await setDashboardSortColumn(sortColumn);
    //     await setDashboardSortDirection(sortDirection);
    //   }
    //   if (sortDirection === "desc") {
    //     await setDashboardSortColumn(sortColumn);
    //     await setDashboardSortDirection(sortDirection);
    //   }
    // },
    // onChangePage: async (page) => {
    //   setDashboardStart(page * dashboardPageSize);
    // },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };
  const gridColumns = [
    {
      name: "isChecked",
      label: (
        <div className="endStyle">
          <input
            type="checkbox"
            onChange={(e) => {
              setIsAllCheck(e.target.checked);
              handleSelectAllParticipantsCheckbox(
                e.target.checked,
                sessionScheduleData?.SessionScheduleId,
                0,
                "multiCheck"
              );
            }}
            checked={isAllCheck}
          />
        </div>
      ),
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),

        customBodyRender: (value, tableMeta, updateValue) => {
          const { rowIndex, tableData } = tableMeta;
          let sessionScheduleId =
            tableMeta.tableData[tableMeta.rowIndex].sessionScheduleId;
          let userId = tableMeta.tableData[tableMeta.rowIndex].userId;

          return (
            <>
              <div className="">
                <input
                  type="checkbox"
                  checked={value}
                  onChange={(e) => {
                    debugger;
                    handleSelectAllParticipantsCheckbox(
                      e.target.checked,
                      sessionScheduleId,
                      userId,
                      "singleCheck"
                    );
                  }}
                />
              </div>
            </>
          );
        },
      },
    },
    {
      name: "rowNum",
      label: "Sr.no",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "isChecked",
      label: "Participation Status",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          const { rowIndex, tableData } = tableMeta;
          const sessionScheduleId =
            tableMeta.tableData[tableMeta.rowIndex].sessionScheduleId;
          const userId = tableMeta.tableData[tableMeta.rowIndex].userId;
          const participantionStatus =
            tableMeta.tableData[tableMeta.rowIndex].participantionStatus;

          debugger;

          return (
            <div>
              <button
                onClick={() => {
                  markParticipantAttendanceApiCall(
                    sessionScheduleId,
                    userId,
                    !value
                  );
                }}
                style={{
                  backgroundColor:
                    participantionStatus === "P" ? "green" : "red",
                  color: "white",
                  border: "none",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                }}
              >
                {participantionStatus === "P" ? "Present" : "Absent"}
              </button>
            </div>
          );
        },
      },
    },
    {
      name: "participantionDateAndTime",
      label: "Date & Time",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "employeeCode",
      label: "Employee ID",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "fullName",
      label: "Display Name",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "businessUnit",
      label: "Business",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "sbu",
      label: "SBU",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "legalEntity",
      label: "Legal Entity",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "customBusiness",
      label: "Custom Business",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "function",
      label: "Function",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "devision",
      label: "Division",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "department",
      label: "Department",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "employeeClass",
      label: "Employee Class",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "jobBand",
      label: "Job Band",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "jobLevel",
      label: "Job Level",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "designation",
      label: "Designation",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "payrollLocation",
      label: "Payroll Location",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "physicalLocation",
      label: "Physical Location",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "groupDateOfJoining",
      label: "Group Joining Date",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
        setCellProps: () => ({
          style: { minWidth: "120px", maxWidth: "120px" },
        }),
      },
    },

    {
      name: "businessDateOfJoining",
      label: "Business Joining Date",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
        setCellProps: () => ({
          style: { minWidth: "120px", maxWidth: "120px" },
        }),
      },
    },
  ];

  return (
    <>
      <Accordion
        elevation={0}
        className="mb-3"
        expanded={sessionAccordian}
        onChange={() => setSessionAccordian((prev) => !prev)}
      >
        <AccordionSummary
          id="profile-accordion"
          style={{
            background: "#3C5464",
          }}
          className="text-white acc_close"
          expandIcon={<ExpandMore />}
        >
          <div className="row d-flex align-items-center acc-heading">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <p
                style={{
                  fontSize: "16px",
                }}
              >
                <Tooltip title={""}>
                  <span>
                    <input
                      disabled={false}
                      type="checkbox"
                      onChange={(e) => {
                        e.stopPropagation();
                        checkSessionScheduleCheckboxes(
                          e.target.checked,
                          sessionScheduleData
                        );
                      }}
                      checked={sessionScheduleData?.SessionScheduleChecked}
                    />
                  </span>
                </Tooltip>

                <span>
                  {"  "}
                  {sessionScheduleData?.SessionScheduleName}
                </span>
              </p>
            </div>
          </div>
        </AccordionSummary>
        {sessionAccordian && (
          <AccordionDetails className="">
            <div className="mb-3 pt-3">
              <DynamicGrid
                data={dashboard}
                columns={gridColumns}
                options={dashboardOptions}
              />
            </div>
          </AccordionDetails>
        )}
      </Accordion>
    </>
  );
};

export default SessionScheduleGrid;
