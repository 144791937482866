import React, { useContext, useState, useEffect } from "react";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { LoaderContext } from "../../Helpers/Context/Context";
import { APICall } from "../../Helpers/APICalls";
import { useNavigate } from "react-router-dom";
import { GetMedicalDashboard } from "../../Helpers/APIEndPoints/AssociateEndPoints";

const MedicalDashboard = () => {
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [dashboard, setDashboard] = useState([]);
  const [dashboardCount, setDashboardCount] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [dashboardStart, setDashboardStart] = useState(0);
  const [dashboardSortColumn, setDashboardSortColumn] = useState("");
  const [dashboardSortDirection, setDashboardSortDirection] = useState("");
  const [dashboardPageSize, setDashboardPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      await getCandidateStatus();
    };
    fetchData();

    const interval = setInterval(() => {
      setDashboardStart((prevStart) => {
        const nextStart = prevStart + dashboardPageSize;
        console.log("nextStart1:" + nextStart);
        if (nextStart >= dashboardCount) {
          setCurrentPage(0); // Reset the page to the first one
          return 0; // Reset to the first page when reaching the end
        }
        setCurrentPage((prevPage) => prevPage + 1); // Update the current page state
        return nextStart;
      });
    }, 30000); // 10 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [
    refresh,
    dashboardStart,
    dashboardSortColumn,
    dashboardSortDirection,
    dashboardPageSize,
    dashboardCount,
  ]);

  const getCandidateStatus = async () => {
    try {
      showLoader();
      console.log("Fetching data with parameters:", {
        PageNumber: dashboardStart,
        PageSize: dashboardPageSize,
        SortColumn: dashboardSortColumn,
        SortOrder: dashboardSortDirection,
        IsExcel: false,
      });

      const response = await APICall(GetMedicalDashboard, "POST", {
        PageNumber: dashboardStart,
        PageSize: dashboardPageSize,
        SortColumn: dashboardSortColumn,
        SortOrder: dashboardSortDirection,
        IsExcel: false,
      });

      console.log("API Response:", response);

      if (response?.status === 0 && response?.data.length > 0) {
        setDashboard(response.data);
        setDashboardCount(response.data[0].count);
      } else {
        setDashboard([]);
        setDashboardCount(0);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      hideLoader();
    }
  };

  const gridColumns = [
    {
      name: "sequence",
      label: "Sr.No",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "candidateToken",
      label: "Token Id",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "isProcessing",
      label: "Status",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        customBodyRender: (value) => {
          const text = value === false ? "In Queue" : "In Processing";
          const color = value === false ? "red" : "green";
          return <span style={{ color }}>{text}</span>;
        },
      },
    },
  ];

  const dashboardOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: dashboardCount,
    rowsPerPage: dashboardPageSize,
    page: currentPage,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",
    onChangePage: async (page) => {
      setCurrentPage(page);
      const newStart = page * dashboardPageSize;
      console.log("nextStart2:" + newStart);
      setDashboardStart(newStart);
      await getCandidateStatus(); // Fetch data for the new page
    },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      setDashboardSortColumn(sortColumn);
      setDashboardSortDirection(sortDirection);
      setDashboardStart(0); // Reset to the first page on sort change
      setCurrentPage(0);
      await getCandidateStatus();
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  return (
    <>
      <div className="form-main">
        <div className="page-title w-100">
          <div className="col-lg-8">
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <div className="row">
                  <i
                    className="fa-solid fa-home mt-3 mr-3 ml-3"
                    style={{ color: "white", cursor: "pointer" }}
                    onClick={() => {
                      navigate("/Bluecollar");
                    }}
                  ></i>
                  <h4>Medical Dashboard</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-2" id="blue-collar-pages">
        <div className="col-lg-12 col-md-12 col-sm-12" id="dashboard-page">
          <DynamicGrid
            data={dashboard}
            columns={gridColumns}
            options={dashboardOptions}
          />
        </div>
      </div>
    </>
  );
};

export default MedicalDashboard;
