import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import { APICall } from "../../Helpers/APICalls";
import { LoaderContext } from "../../Helpers/Context/Context";
import SelectForm from "../../Components/SelectForm/SelectForm";
import "./FormStructure.css";
import { getpreviewTabsSectionsList,
    getBonafiedDocumentsList,
    getReplaceBonafiedData,
    insertIntoBonafiedRequests,
    GetConfigValueByKey
 } from "../../Helpers/APIEndPoints/EndPoints";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { isEmpty } from "../../utils/isEmpty";
import notify from "../../Helpers/ToastNotification";
function BonafideGenerateModal({
  bonafidemodal,
  setBonafidemodal,
  TId
}) {
  let { userDetails, proxyUserDetails, currentRoleId }: any = useUserContext();
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [documentsValue, setDocumentsValue] = useState(null);
  const [formError, setFormError] = useState<any>({});
  const [optionData, setOptionData] = useState(null);
  
  const handleModalClose = () => {
    function customizedAsync(){
    setDocumentsValue(null);
    setFormError({});
    setOptionData(null);
    setBonafidemodal(!bonafidemodal);
}
    showLoader();
      const timer = setTimeout(() => {
        hideLoader();
        customizedAsync();
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
  };

  const getApiFunCallForDMSbonafide = async (data) => {
    showLoader();
    try{
      const responseURL = await APICall(
        GetConfigValueByKey,
         "POST", {
        Key: "WelspunDMSAPILink",
      });
       if(responseURL?.status === 0){
        if(responseURL?.data){
            const urlDocGenerated = await APICall(
             `${responseURL.data}/GenerateBonafiedDocument`,
             "POST",
             {
               documentNames:isEmpty(documentsValue)?"":documentsValue.map((item) => item.documentCode).join(","),
               attributes:data,
             },
            );
            if (urlDocGenerated?.status === 200 &&
              urlDocGenerated?.data != null && urlDocGenerated?.data != undefined) {
            // Insert into BonafiedDocumentRequests Details 
              const insert = await APICall( insertIntoBonafiedRequests,
              "POST",
              {
                EId:TId,
                IsSync:0,
                DocumentRequestId: urlDocGenerated?.data,
                RequestBy:userDetails.Id,
                IsActive: 1,
              });
              if(insert.data != null ){
                notify(0,"Bonafied document will be sent to you over mail.");
              }
              else{
                notify(1,"Something went wrong. Please try again later")
              }
            }
            else{
              notify(1,"Something went wrong. Please try again later")
            } 
         
        }
       }
        else {
          return null;
        }
    }
    catch (error) {
      console.error("Error in Dms link for Bonafide and its functionality:", error);
      return null;
    } finally {
      await hideLoader();
      
    }
  }

  const getApiFuncCallForDocumentDownload = async()=> {
    showLoader();
    try {
      const response = await APICall(
        getReplaceBonafiedData,
        "POST",
        {
          EID : TId,
        },
      );
      if (response?.status === 0 && response?.data != null) {
        if (response?.data) {
         getApiFunCallForDMSbonafide(response?.data);
        } else {
          console.error("Error in Fetching Legal Entity and Values for Bonafide");
          await hideLoader();
        }
      } else {
        console.error("Error in Fetching Legal Entity and Values for Bonafide:");
        await hideLoader();
      }
    } catch (error) {
      console.error("Error in Fetching Legal Entity and Values for Bonafide:", error);
      await hideLoader();
      return null;
    } finally {
      //await hideLoader();
    }
  }

  const handleModalSubmit = () => {
    debugger
    function customizedAsync(){
    let errorObj = {};
    let isError = false;
    setFormError({});
    const fieldsToCheck = [
      { value: documentsValue, errorKey: "error_documentsValue" }
    ];
    fieldsToCheck.forEach(({ value, errorKey }) => {
      if (!value || value.length === 0) {
        errorObj[errorKey] = "Required";
        isError = true;
      }  else {
        delete errorObj[errorKey];
      }
    });
    const isEmpty = Object.values(errorObj).every(
      (s) => s === null || s === "" || s === undefined
    );
    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      setFormError((err) => ({
        ...err,
        ...errorObj,
      }));
      if (isError) {
        let errorMessage = [];
        if (errorObj["error_documentsValue"]) errorMessage.push("Documents");
        if (errorMessage.length > 0) {
          notify(1, `Please fill ${errorMessage.join(",")} Properly.`);
        }
      }
    } else {
    //   setPrintValueSubmit({
    //   tabsValue: tabsValue,
    //   });
    getApiFuncCallForDocumentDownload();
      handleModalClose();

    }
  }
  showLoader();
      const timer = setTimeout(() => {
        hideLoader();
        customizedAsync();
      }, 100);

      return () => {
        clearTimeout(timer);
      };
  };
  const handleChanges = (event, fieldname) => {
    if (fieldname === "Documents") {
      setDocumentsValue(event);
    }
  };

  const getTabsOptionValue = async () => {
    showLoader();
    try {
      const response = await APICall(getBonafiedDocumentsList, "POST", {
        RoleId: currentRoleId?.value ? currentRoleId.value : null,
        UserId: proxyUserDetails?.value
          ? proxyUserDetails.value
          : userDetails?.UserId,
      });
      if (response?.status === 0) {
        if (response?.data) {
          setOptionData(response?.data);
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error in Bonafied Documents List Dropdown:", error);
      return null;
    } finally {
      hideLoader();
    }
  };
  useEffect(() => {
    debugger
    if (bonafidemodal) {
      getTabsOptionValue();
    }
  }, [bonafidemodal]);
  return (
    <Modal
      show={bonafidemodal}
      onHide={handleModalClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      centered
    >
      <div
        className="bg-secondary text-white"
        style={{
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        <div className="col-lg-12"> Generate Bonafide Letter</div>
      </div>
      <Modal.Body>
        <div className="row">
          <div className="col-lg-9 col-md-9 col-sm-12">
            <div className="mb-1">
              <label className="col-form-label">Please select the letters you wish to download</label>
              <sup>*</sup>
              <div className="">
                <SelectForm
                  isClearable
                  options={optionData}
                  placeholder={"Documents"}
                  onChange={(event) => handleChanges(event, "Documents")}
                  isMulti={true}
                  noIndicator={false}
                  noSeparator={false}
                  value={documentsValue}
                />
                <span style={{ color: "red" }}>
                  {formError["error_documentsValue"]}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
      <button
          type="button"
        //   disabled={isDisabledButton}
          className="btn btn-primary ml-3"
          onClick={handleModalSubmit}
        >
          Submit
        </button>
        <button
          type="button"
          className="btn btn-secondary ml-3"
          onClick={handleModalClose}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
export default BonafideGenerateModal;
