import React, { useContext, useEffect, useRef, useState } from "react";
import { LoaderContext } from "../../Helpers/Context/Context";
import { APICall } from "../../Helpers/APICalls";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import {
  entryUpdateInActionHistory,
  anyProgressionRevoke,
  checkIfEntryInAuditLog,
  getRoleDetailsProgression,
  checkIfSeparationIsActive,
  getDropdowns,
  getLOPForLRProgression,
  getWorkflowData,
  updateSectionDocInProgression,
  uploadSectionDocForProgression,
  uploadSupportingDocForProgression,
  validateSections,
  getDependentDataOfPos,
  getReporteesForEmployee,
  getUsermasterIdBasedOnEid,
  getRelationsForEmployee,
  getAllRoles,
  getCommonDdForProgression,
  getDdForProgression,
  getSearchDdForProgression,
  getOptForApproval,
  getAttrForProgression,
  postDataForProgression,
  getSectionDetailsV2,
  getGlobalValues,
  viewDocument,
  downloadDocument,
  deleteDocument,
  getDocuments,
  getSearchableDropdowns,
  getParenPBasedonIncm,
  postAttributeValueDetailsV2,
  getAttributeValueDetailsV2,
  documentUpload,
  getAccountValidation,
} from "../../Helpers/APIEndPoints/EndPoints";
import { NationalIdCardType } from "../../Components/FromStructure/NationalIdCardTypeRegex";
import notify from "../../Helpers/ToastNotification";
import moment from "moment";
import { DomainVerification, ExpandMore } from "@mui/icons-material";
import { Modal } from "react-bootstrap";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import uuid from "react-uuid";
import SelectForm from "../../Components/SelectForm/SelectForm";
import InputForm from "../../Components/InputForm/InputForm";
import DateForm from "../../Components/DateForm/DateForm";
import { useLocation } from "react-router-dom";
import async from "react-select/dist/declarations/src/async/index";

import { Prev } from "react-bootstrap/esm/PageItem";
import ChangeLog from "./ChangeLog";
import { execArgv } from "process";
import useDataChangeStore from "../../Helpers/ZustandStore/DataChangeSectionStore";
import SectionComponent from "./SectionComponent";
import "./Request.css";
import useDocumentStore from "../../Helpers/ZustandStore/DocumentForProgressionStore";
import { isEmpty } from "../../utils/isEmpty";

const ProgRequisitionDetatils = () => {
  const { documents, updateDocument }: any = useDocumentStore();
  let FlatDocRef = useRef(null);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [accordionReqDet, setAccordionReqDet] = useState(true);
  const [accordionWrkfl, setAccordionWrkfl] = useState(false);
  const [accordianEvent, setAccordianEvent] = useState(false);
  const [formErrors, setFormErrors] = useState<any>({});
  const [dynamicformErrors, setDynamicformErrors] = useState<any>({});
  const [optionsObj, setOptionsObj] = useState<any>({});
  const [TId, setTId] = useState(0);
  const [modalIsOpen, setModal] = useState(false);
  const [disableSubmitBtn, setdisableSubmitBtn] = useState(true);
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  const FullName = `${userDetails.FirstName} ${userDetails.LastName}`;
  const [inputValues, setInputValues] = useState({}); //hold an object
  const [approvers, setApprovers] = useState<any>([]);
  const [approverOpt, setapproverOpt] = useState<any>({});
  const [allApprovers, setAllApprovers] = useState<any>({});
  const [dynamicAccordians, setDynamicAccordian] = useState<any>({});
  const [oldAuditJsonState, setOldAuditJsonState] = useState<any>();
  const [isoldauditjsononce, setIsoldauditjsononce] = useState(false);
  const [btnHide, setBtnHide] = useState(true);
  const [sectionTabHide, setSectionTabHide] = useState(true);
  const [sectionValuesArray, setSectionValuesArray] = useState<any>([]);
  const [formData, setFormData] = useState<any>({});
  const [finalformData, setFinalFormData] = useState<any>([]);
  const [globalValues, setGlobalValues] = useState([]);
  const [submitValidation, setSubmitValidation] = useState(0);
  const [empIsDisable, setEmpIsDisable] = useState(false);
  const [displayGrid, setDisplayGrid] = useState([]);
  const [gridColumns, setGridColumns] = useState([]);
  const [PageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(0);
  const [getData, setGetData] = useState([]);
  const [submitFunc, setSubmitFunc] = useState(false);
  const [submitBtnForSet, setSubmitBtnForSet] = useState(false);
  const [getDataForSet, setGetDataForSet] = useState([]);
  const [currentGridId, setCurrentGridId] = useState(null);
  // value Object for section
  const [attributesData, setAttributesData] = useState<any>({});
  const [IsSet, setIsSet] = useState(false);
  const [isValidateOnce, setIsValidateOnce] = useState(true);
  const [attributeValidation, setAttributeValidation] = useState<any>({});
  const [dynamicValidations, setDynamicValidations] = useState<any>({});
  const [dataFilled, setDataFilled] = useState(false);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [checkValidationTrigger, setCheckValidationTrigger] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [once, setOnce] = useState(false);

  // For Termination---------------------
  const [directReportee, setDirectReportee] = useState<any>(null);
  const [managerForReportee, setManagerForReportee] = useState<any>(null);
  const [reportees, setReportees] = useState<any>([]);
  const [reporteeManagerMap, setReporteeManagerMap] = useState<any>([]);
  const [managerDisable, setManagerDisable] = useState(false);

  const [waveOffReasonDisable, setWaveOffReasonDisable] = useState(false);
  const [probDateDisable, setProbDateDisable] = useState(true);
  const [trainDateDisable, setTrainDateDisable] = useState(true);

  const [optForRoles, setOptForRoles] = useState<any>([]);
  const [directReporteeRel, setDirectReporteeRel] = useState<any>(null);
  const [relationForReportee, setRelationForReportee] = useState<any>(null);
  const [relReportees, setRelReportees] = useState<any>([]);
  const [userRelationMap, setUserRelationMap] = useState<any>([]);
  const [relationDisable, setRelationDisable] = useState(false);
  const [mappingHide, setMappingHide] = useState(true);

  //-----
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isPositionSync, setIsPositionSync] = useState(false);
  const [ogOptionsForCascade, setOgOptForCascade] = useState<any>({});
  const [ogoptsetonce, setogoptsetonce] = useState(false);
  ///////

  const [countryDisable, setCountryDisable] = useState(false);
  const [skillcategoryHide, setSkillCategoryHide] = useState(true);
  //----FOR USA
  const [reqDetDisable, setReqDetDisable] = useState<any>(false);
  const [payrollDisable, setpayrollDisable] = useState<any>(true);
  const [emptyArrForSet, setEmptyArrForSet] = useState<any>(null);
  const [grpJoiningDate, setGrpJoiningDate] = useState<any>(null);

  const [scnDisabled, setScnDisabled] = useState<any>(true);

  const [disableEffectriveDate, setDisableEffectiveDate] = useState(false);

  const [completeworkflowtypeoptions, setcompleteworkflowtypeoptions] =
    useState<any>([]);
  
  const [roleSystemName, setRoleSystemName] = useState<any>(null);
  // const [disableForTextileWorkerWfl, setDisableForTextileWorkerWfl] = useState<any>(false);
  const [enableForTextileWorkerWfl, setEnableForTextileWorkerWfl] = useState<any>(false);
  //-----------
  //---For Transfer
  // const [customNameForTransfer, setCustomNameForTransfer] = useState<any>(null);
  // const [codeCheckForTansfer, setCodeCheckForTransfer] = useState<any>([]);
  //---------------
  const [selectedFilesForFlat, setSelectedFilesForFlat] = useState<
    {
      AC: string;
      Name: string;
      Data: string;
      IsValidFile: boolean;
      DocId: Number;
      Type: string;
      Context: string;
      DCVALUE: string;
      DVTILL: string;
      DSTATUS: string;
      DPSINCE: string;
      DPWITH: string;
    }[]
  >([]);

  const typeOptions = [
    { value: 1, label: "Transfer all to upper position.", code: "TATUP" },
    { value: 2, label: "Transfer all to other manager.", code: "TATOM" },
    { value: 3, label: "Individual transfer to other manager.", code: "ITTOM" },
  ];
  const typeOptionsRel = [
    { value: 1, label: "Transfer all to upper position.", code: "RLTATUP" },
    { value: 2, label: "Transfer all to other manager.", code: "RLTATOM" },
    {
      value: 3,
      label: "Individual transfer to other manager.",
      code: "RLITTOM",
    },
    { value: 4, label: "Terminate all Relations.", code: "RLTAR" },
  ];
  //--------------------------------------------

  const supportingDocRef = useRef(null);
  const [proSupportingDoc, setProSupportingDoc] = useState<
    {
      AC: string;
      Name: string;
      Data: string;
      IsValidFile: boolean;
      DocId: Number;
    }[]
  >([]);
  const [IsDateChangeUploaded, setIsDataChangeUploaded] = useState(false);

  const sectionsData = useDataChangeStore((state: any) => state.sectionsData);
  const emptySectionData = useDataChangeStore(
    (state: any) => state.emptySectionData
  );
  const oldJsonSectionData = useDataChangeStore(
    (state: any) => state.oldJsonSectionData
  );
  const tempOldSectionData = useDataChangeStore(
    (state: any) => state.tempOldSectionData
  );

  const setOldJson = useDataChangeStore((state: any) => state.setOldJson);
  const emptySectionAndJsonData = useDataChangeStore(
    (state: any) => state.emptySectionAndJsonData
  );

  useEffect(() => {
    if (
      attributesData["REQTYPOUPD"] !== null &&
      attributesData["REQTYPOUPD"] !== undefined &&
      attributesData["REQRSNOUP"] !== null &&
      attributesData["REQRSNOUP"] !== undefined &&
      attributesData["REQEMPLID"] !== null &&
      attributesData["REQEMPLID"] !== undefined
    ) {
      (async () => {
        await showLoader();
        const workflowRes = await APICall(getWorkflowData, "POST", {
          WorkflowTypeId: attributesData["REQTYPOUPD"].value,
          WorkflowSubTypeId: attributesData["REQRSNOUP"].value,
          EmployeeId: attributesData["REQEMPLID"].value,
          InitiatorId: userDetails.Id,
          InitiatorRoleId: currentRoleId?.value,
          CountryId: attributesData["REQCNTRY"].value,
          PayrollLocationId: attributesData["REQPLOCN"]
            ? attributesData["REQPLOCN"]?.value
            : null,
        });
        if (workflowRes.data.length > 0) {
          setApprovers(workflowRes.data);
          let approvercount = 0;
          let temp = workflowRes.data;
          if (temp.length == 1) {
            setBtnHide(false);
          }
          if (temp.length > 1) {
            temp.map((eachapprover, index) => {
              if (index > 0) {
                if (eachapprover.approvers == null) {
                  approvercount = approvercount + 1;
                }
                setapproverOpt((prev) => ({
                  ...prev,
                  [`${"Approver"} ${index}`]: eachapprover.approvers,
                }));
              }
              setAllApprovers((prev) => ({
                ...prev,
                [`${"Approver"} ${index}`]: eachapprover.approvers,
              }));

              if (
                eachapprover.approvers !== null &&
                eachapprover.approvers.length > 0
              ) {
                setBtnHide(false);
              }
            });

            if (temp.length - 1 == approvercount) {
              notify(1, "No Approvers are there for the workflow!");
              navigate("/home");
            }
          }

          // &&  currentRoleId?.value != 3

          //const tempCou = 570;
          const attrData = await APICall(getAttrForProgression, "POST", {
            WorkFlowId: workflowRes.data[0].workFlowId,
            COU: attributesData["REQEMPLID"].cou,
            //COU: tempCou,
            userid: userDetails.Id,
            roleid: currentRoleId?.value,
            IsNewHire: true,
            IsProg: true,
            EmployeeId: parseInt(attributesData["REQEMPLID"].value),
          });

          if (attrData.data !== null && attrData.data.length > 0) {
            let attrValidations = {};
            attrData.data.map(async (eachAttr) => {
              //Attribute
              if (eachAttr.attributeType == "Attribute") {
                if (eachAttr.attributeDatatype === "DropdownSingle") {
                  const valForDD = eachAttr.options?.find(
                    (item) => item.value == eachAttr.value
                  );
                  //customization for termination
                  if (eachAttr.attributeCode == "OTR") {
                    if (
                      attributesData &&
                      attributesData?.["REQRSNOUP"] != null
                    ) {
                      if (
                        attributesData["REQRSNOUP"].code == "BLS" ||
                        // attributesData["REQRSNOUP"].code == "Termination" ||
                        // attributesData["REQRSNOUP"].code == "Absconding" ||
                        attributesData["REQRSNOUP"].code == "Death" ||
                        attributesData["REQRSNOUP"].code == "DeathCovid"
                      ) {
                        eachAttr.valueOnDd = eachAttr.options?.find(
                          (item) => item.code == "no"
                        );
                      } else {
                        if (valForDD !== undefined && valForDD !== null) {
                          eachAttr.valueOnDd = valForDD;
                        } else {
                          eachAttr.valueOnDd = eachAttr.options?.find(
                            (item) => item.code == "yes"
                          );
                        }
                      }
                    }
                  }
                  //otherwise it will run
                  else {
                    if (valForDD !== undefined || valForDD !== null) {
                      eachAttr.valueOnDd = valForDD;
                    } else {
                      eachAttr.valueOnDd = null;
                    }
                  }
                  //customization for confirmation
                  if (
                    attributesData?.["REQRSNOUP"]?.code == "PROB" &&
                    eachAttr.attributeCode == "PC" &&
                    valForDD &&
                    valForDD?.code == "Yes001"
                  ) {
                    setProbDateDisable(false);
                  } else {
                    setProbDateDisable(true);
                  }

                  if (
                    attributesData?.["REQRSNOUP"]?.code == "TRNG" &&
                    eachAttr.attributeCode == "TC" &&
                    valForDD &&
                    valForDD?.code == "P001"
                  ) {
                    setTrainDateDisable(false);
                  } else {
                    setTrainDateDisable(true);
                  }
                  //----------------------------------------------------------
                  //Customization for Promotion (India)
                  if (
                    attributesData?.["REQCNTRY"]?.code == "CODECNTRY1" &&
                    attributesData?.["REQTYPOUPD"]?.code == "Promotion" &&
                    eachAttr.attributeCode == "EC" &&
                    valForDD &&
                    valForDD?.code == "Worker"
                  ) {
                    setSkillCategoryHide(false);
                  } else if (
                    attributesData?.["REQCNTRY"]?.code == "CODECNTRY1" &&
                    attributesData?.["REQTYPOUPD"]?.code == "Promotion" &&
                    eachAttr.attributeCode == "EC" &&
                    valForDD &&
                    valForDD?.code != "Worker"
                  ) {
                    setSkillCategoryHide(true);
                  }
                  //----------------------------------------------------------
                } else if (
                  eachAttr.attributeDatatype === "Text" ||
                  eachAttr.attributeDatatype === "Number"
                ) {
                  eachAttr.valueOnDd = eachAttr.value ? eachAttr.value : "";
                } else if (eachAttr.attributeDatatype === "Date") {
                  eachAttr.valueOnDd = eachAttr.value
                    ? eachAttr.value.toString()
                    : "";
                  let valueForField = eachAttr.value
                    ? eachAttr.value.toString()
                    : "";
                  const dateString = valueForField;
                  const allowedFormat = ["YYYY-MM-DD"];

                  const isValidDate = moment(
                    dateString,
                    allowedFormat,
                    true
                  ).isValid();
                  if (
                    isValidDate &&
                    dateString !== "0001-01-01" &&
                    dateString !== "0001/01/01"
                  ) {
                    const parsedDate = moment(valueForField, allowedFormat[0]);
                    const formattedDate = parsedDate.format("DD-MMM-YYYY");
                    eachAttr.valueOnDd = formattedDate;
                    eachAttr.value = formattedDate;
                  } else if (
                    dateString === "0001-01-01" ||
                    dateString === "0001/01/01"
                  ) {
                    eachAttr.valueOnDd = null;
                    eachAttr.value = null;
                  }
                } else if (
                  eachAttr.attributeDatatype === "SearchableDropdownSingle"
                ) {
                  eachAttr.valueOnDd = null;
                  if (
                    eachAttr.value != null &&
                    eachAttr.value != undefined &&
                    eachAttr.value != ""
                  ) {
                    const obj = await getSearchableDropdownAPI(
                      "",
                      eachAttr.attributeCode,
                      null,
                      eachAttr.value,
                      "attribute",
                      0
                    );
                    eachAttr.valueOnDd = obj;
                  }
                }
                setDynamicValidations((prev) => ({
                  ...prev,
                  [eachAttr.attributeCode]: JSON.parse(
                    eachAttr.attributeValidations
                  ),
                }));
              }
              if (eachAttr.attributeType == "AttributeSet") {
                let tabcode = "";
                if (eachAttr.attributeCode == "SA_CompensationSet") {
                  tabcode = "Employment";
                } else if (eachAttr.attributeCode == "SA_DisciplinaryActions") {
                  tabcode = "Employment";
                } else if (eachAttr.attributeCode == 'SA_OneTimePaymentDeductions') {
                  tabcode = "Employment";
                }
                setSectionValuesArray([]); //imp

                (async () => {
                  await showLoader();
                  const section = await APICall(getSectionDetailsV2, "POST", {
                    MN: "EmployeeCentral",
                    IN: "EC_Form",
                    TN: tabcode,
                    UID: userDetails.Id,
                    TID: parseInt(attributesData["REQEMPLID"].value),
                    RoleId: currentRoleId?.value,
                    //COU:TId==0?COU:userDetails.COU,
                    COU: attributesData["REQEMPLID"].cou,
                    IsNewHire: true,
                  });

                  if (
                    section.data !== null &&
                    section.data.t !== undefined &&
                    section.data.t.tn === tabcode
                  ) {
                    let setFinalSections = [];
                    if (eachAttr.attributeCode == "SA_CompensationSet") {
                      const sect = section.data.t.S?.find(
                        (item) => item.SN == "Compensation"
                      );
                      setFinalSections.push(sect);

                      setFinalFormData(setFinalSections);
                    } else if (
                      eachAttr.attributeCode == "SA_DisciplinaryActions"
                    ) {
                      const sect = section.data.t.S?.find(
                        (item) => item.SN == "DisciplinaryActions"
                      );
                      setFinalSections.push(sect);
                      setFinalFormData(setFinalSections);
                    } else if (eachAttr.attributeCode == "SA_OneTimePaymentDeductions") {
                      const sect = section.data.t.S?.find(
                        (item) => item.SN == "OneTimePaymentDeductions"
                      );
                      setFinalSections.push(sect);
                      setFinalFormData(setFinalSections);
                    }
                  } else {
                    setFinalFormData([]);
                  }
                  await hideLoader();
                })();
                (async () => {
                  showLoader();
                  let bodyObj = {
                    mn: "EmployeeCentral",
                    in: "EC_Form",
                    tn: tabcode,
                    SN: "Basic",
                    TID: parseInt(attributesData["REQEMPLID"].value),
                    ISH: "true",
                    UserId: userDetails?.Id,
                    RoleId: currentRoleId?.value,
                    COU: attributesData["REQEMPLID"].cou,
                  };

                  const response = await APICall(
                    getAttributeValueDetailsV2,
                    "POST",
                    bodyObj
                  );
                  if (
                    response.data.sections &&
                    response.data.sections.length > 0
                  ) {
                    setSectionValuesArray(response.data.sections);
                  } else {
                    setSectionValuesArray([]);
                  }
                  hideLoader();
                })();
                if (attributesData["REQEMPLID"] != null) {
                  (async () => {
                    await showLoader();
                    const globalValues = await APICall(
                      getGlobalValues,
                      "POST",
                      {
                        TID: attributesData["REQEMPLID"].value,
                      }
                    );

                    if (
                      globalValues.data !== null &&
                      globalValues.data.length > 0
                    ) {
                      setGlobalValues(globalValues.data);
                    }
                    await hideLoader();
                  })();
                }
              }
              //Compensation
              //DisciplinaryActions
            });
            if (attrData.data.length > 1) {
              setIsoldauditjsononce(false);
              setDynamicAccordian(attrData.data);
            } else if (
              attrData.data.length == 1 &&
              attrData.data[0].attributeType == "Attribute"
            ) {
              setIsoldauditjsononce(false);
              setDynamicAccordian(attrData.data);
            }
            //attributeValidation
          }
        }
        await hideLoader();
      })();
    }
  }, [
    attributesData["REQTYPOUPD"],
    attributesData["REQRSNOUP"],
    attributesData["REQEMPLID"],
  ]);

  useEffect(() => {
    if (IsDateChangeUploaded) {
      setBtnDisable(false);
      setIsDataChangeUploaded(false);
    }
  }, [IsDateChangeUploaded]);

  const getDdData = async (AC, PID) => {
    await showLoader();
    const GenId = currentRoleId?.value; //Role Id of the User
    const CountryId = attributesData?.["REQCNTRY"]?.value; //Country of User
    //console.log("Country:::", CountryId);
    const dropdownData = await APICall(getDdForProgression, "POST", {
      AC,
      PID,
      GenId,
      CountryId,
      UserId: proxyUserDetails?.value
        ? proxyUserDetails?.value
        : userDetails?.Id,
      TId: AC==="REQSECT" ? 1 : null,
    });

    if (
      dropdownData.data !== null &&
      dropdownData.data !== undefined &&
      dropdownData.data.length > 0
    ) {
      await hideLoader();
      return dropdownData.data;
    } else {
      await hideLoader();
      return [];
    }
  };
  const getCommonDdData = async (AC, PID) => {
    await showLoader();
    // const userid = attributesData?.["REQEMPLID"]?.value;
    // const dropdownData = await APICall(getCommonDdForProgression, "POST", {
    //   AC,
    //   PID,
    //   UserId: userid,
    // });

    const TId = attributesData?.["REQEMPLID"]?.value;
    const dropdownData = await APICall(getDropdowns, "POST", {
      AC,
      PID,
      TId,
      UserId: proxyUserDetails?.EmployeeId
        ? proxyUserDetails?.EmployeeId
        : userDetails?.Id,
      RoleId: currentRoleId?.value,
      RoleTypeId: 3,
      IsNewHire: true,
      IsProg: true,
    });

    if (
      dropdownData.data !== null &&
      dropdownData.data !== undefined &&
      dropdownData.data.length > 0
    ) {
      await hideLoader();
      return dropdownData.data;
    } else {
      await hideLoader();
      return [];
    }
  };

  const getSearchableDropdownAPI = async (
    searchString,
    AC,
    cb,
    valueForDropdown,
    type,
    gridId
  ) => {
    const CountryId = attributesData?.["REQCNTRY"]?.value; //Country of User
    const CountryCode = attributesData?.["REQCNTRY"]?.code; //Country of User
    const PayrollId = attributesData?.["REQPLOCN"]?.value; //Country of User
    const IsBlacklistEmpSearch = attributesData?.["REQTYPOUPD"]?.code == 'Blacklisted' ? true : false; //Blacklisted check
    console.log('attributesData?.["REQTYPOUPD"]',attributesData?.["REQTYPOUPD"]);
    console.log('IsBlacklistEmpSearch::',IsBlacklistEmpSearch);
    if (
      searchString === "" &&
      cb === null &&
      valueForDropdown !== "" &&
      AC !== ""
    ) {
      const response = await APICall(getSearchDdForProgression, "POST", {
        searchString,
        AC,
        valueForDropdown,
        CountryId,
        CountryCode,
        PayrollId,
      });

      if (response.data !== null && response.data.length > 0) {
        if (response.data.length === 1) {
          return response.data[0];
        }
      }
    } else if (searchString.length > 1) {
      const response = await APICall(getSearchDdForProgression, "POST", {
        searchString,
        AC,
        InitiatorId: parseInt(userDetails.Id),
        InitiatorRoleId: currentRoleId?.value,
        CountryId,
        CountryCode,
        PayrollId,
        IsBlacklistEmpSearch,
      });
      if (response.data !== null && response.data.length > 0) {
        cb(response.data);
        setOptionsObj((prev) => ({ ...prev, ["REQEMPLID"]: response.data }));
      } else {
        cb([]);
        setOptionsObj((prev) => ({ ...prev, ["REQEMPLID"]: [] }));
      }
    }
  };

  //CALL A USE-EFFECT TO GET DATA FROM DATABASE
  useEffect(() => {
    setAttributeValidation((prev) => ({
      ["REQEFCTVDT"]: "Please Select a date",
      ["REQDREMK"]: "Remarks are Mandatory.",
      ["REQSUPDOC"]: "Supporting Document is Mandatory.",
    }));
    (async () => {
      const roleMaster = await APICall(getRoleDetailsProgression, "POST", {
        Id: currentRoleId.value,
      });
      if(roleMaster.data != null ){
        setRoleSystemName(roleMaster.data.value);
      }
    })();
    (async () => {
      const forRoles = await APICall(getAllRoles, "POST", {});
      setOptForRoles(forRoles.data);

      let optForCountry = await getDdData("REQCNTRY", "");

      // const optForTab = await getDdData("REQTABS", "");
      // const setOptForTab = optForTab?.filter(
      //   (item) => item.code == "Personal" || item.code == "Employment"
      // );
      // const tabSet = optForTab?.find((item) => item.code == "Personal");
      const setOptForTab = await getDdData("REQTABS", "");
      const tabSet = setOptForTab?.find((item) => item.code == "Personal");

      const optForSect = await getDdData("REQSECT", tabSet.value);

      //---------------------------------------
      let tempoptForCountry = [];
      // const oudetails = JSON.parse(userDetails.OUDetails);
      const oudetails = currentRoleId.oudetails;
      if (
        oudetails != null &&
        oudetails?.["COU"] != null &&
        oudetails?.["COU"]?.length > 0
      ) {
        tempoptForCountry = optForCountry.filter((item) =>
          oudetails?.["COU"].includes(item.value.toString())
        );
      }
      // console.log('Final Options: ',tempoptForCountry);

      let setcountry = null;
      if (tempoptForCountry.length == 0) {
        optForCountry = optForCountry;
        setcountry = null;
      } else if (tempoptForCountry.length == 1) {
        setCountryDisable(true);
        setcountry = tempoptForCountry[0];
        optForCountry = tempoptForCountry;
      } else {
        setCountryDisable(false);
        optForCountry = tempoptForCountry;
        setcountry = null;
      }
      // console.log('Final Country Set: ',setcountry);

      setOptionsObj((prev) => ({
        ...prev,
        ["REQCNTRY"]: optForCountry,
        ["REQPLOCN"]: [],
        ["REQTYPOUPD"]: [],
        ["REQRSNOUP"]: [],
        ["REQTABS"]: setOptForTab,
        ["REQSECT"]: optForSect,
      }));

      setAttributesData((prev) => ({
        ...prev,
        ["REQCNTRY"]: setcountry,
        ["REQPLOCN"]: null,
        ["REQTYPOUPD"]: null,
        ["REQRSNOUP"]: null,
        ["REQTABS"]: tabSet,
        ["REQSECT"]: null,
        ["REQEMPLID"]: null,
        ["REQEMPLNM"]: "",
        ["REQEFCTVDT"]: null,
        ["REQSUPDOC"]: "",
        ["REQDREMK"]: "",
        ["WRKFLINID"]: userDetails.Id,
        ["WRKFLINNM"]: FullName,
      }));
    })();
  }, []);

  useEffect(() => {
    if (
      attributesData["REQTYPOUPD"] != null &&
      attributesData["REQRSNOUP"] != null
    ) {
      if (
        attributesData["REQTYPOUPD"].code == "DataChange" &&
        attributesData["REQRSNOUP"].code == "DTACHG"
      ) {
        setSectionTabHide(false);
      }
    } else {
      setSectionTabHide(true);
    }
  }, [attributesData["REQTYPOUPD"], attributesData["REQRSNOUP"]]);

  useEffect(() => {
    if (attributesData["REQCNTRY"] != null) {
      (async () => {
        await showLoader();
        if (attributesData["REQCNTRY"].code === "CODECNTRY7") {
          setReqDetDisable(true);
          setpayrollDisable(false);
          //-------------PAYROLL LOCATION--------------------
          const optForPlocn = await getDdData("REQPLOCN", "");
          if (optForPlocn != null && optForPlocn.length > 0) {
            setOptionsObj((prev) => ({
              ...prev,
              ["REQPLOCN"]: optForPlocn,
            }));

            //SET PAYROLL LOCATION---------------------------------------
            let setpayroll = null;
            // const oudetails = JSON.parse(userDetails.OUDetails);
            const oudetails = currentRoleId.oudetails;
            if (
              oudetails != null &&
              oudetails?.["PLOC"] != null &&
              oudetails?.["PLOC"]?.length > 0
            ) {
              if (oudetails?.["PLOC"]?.length == 1) {
                //find and set
                setpayroll = optForPlocn.find((item) =>
                  oudetails?.["PLOC"].includes(item.value)
                );
              }
            }
            console.log("Final Payroll Set: ", setpayroll);

            let optForType = await getDdData("REQTYPOUPD", "");
            if (optForType != null && optForType.length > 0) {
              const sepObj = optForType.filter(
                (item) => item.code != "Separation" && item.code != "ResignationWithdraw"
              );
              console.log(sepObj);
              if (sepObj != null) {
                setcompleteworkflowtypeoptions(sepObj);
              }
            }
            setAttributesData((prev) => ({
              ...prev,
              ["REQPLOCN"]: setpayroll,
            }));
          } else {
            setOptionsObj((prev) => ({
              ...prev,
              ["REQTYPOUPD"]: [],
            }));
          }
          //-------------------------------------------------
        } else {
          setReqDetDisable(false);
          setpayrollDisable(true);
          //-------------TYPE--------------------------------
          let optForType = await getDdData("REQTYPOUPD", "");
          if (optForType != null && optForType.length > 0) {
            const sepObj = optForType.filter(
              (item) => item.code != "Separation" && item.code != "ResignationWithdraw"
            );
            // console.log(sepObj);
            if (sepObj != null) {
              setcompleteworkflowtypeoptions(sepObj);
              setOptionsObj((prev) => ({
                ...prev,
                ["REQTYPOUPD"]: sepObj,
                ["REQPLOCN"]: [],
              }));
            }
          } else {
            setOptionsObj((prev) => ({
              ...prev,
              ["REQTYPOUPD"]: [],
              ["REQPLOCN"]: [],
            }));
          }
          //-------------------------------------------------
        }
        await hideLoader();
      })();
    }
  }, [attributesData["REQCNTRY"]]);

  // 5617	LittleRock	LR
  // 5635	OhioDistributionCenter	OHDC
  useEffect(() => {
    // console.log("PAYROLL LOCN:::::", attributesData["REQPLOCN"]);
    if (attributesData["REQPLOCN"] != null) {
      console.log("WFL Type Op: ", completeworkflowtypeoptions);
      const finalsetwfltyp = completeworkflowtypeoptions.filter(
        (item) => item.payrollLocationId == attributesData["REQPLOCN"].value
      );
      setOptionsObj((prev) => ({
        ...prev,
        ["REQTYPOUPD"]: finalsetwfltyp,
      }));
      setReqDetDisable(false);
    }
  }, [attributesData["REQPLOCN"]]);

  //section api here
  useEffect(() => {
    //get section

    if (
      attributesData["REQEMPLID"] != null &&
      attributesData["REQTABS"] != null &&
      attributesData["REQTYPOUPD"] != null &&
      attributesData["REQRSNOUP"] != null
      //&& attributesData["REQSECT"] !== null
    ) {
      if (
        attributesData["REQTYPOUPD"].code == "DataChange" &&
        attributesData["REQRSNOUP"].code == "DTACHG"
      ) {
        setSectionValuesArray([]);//imp
        setFormData({});
        setIsSet(false);
        emptySectionAndJsonData();
        //clear global doc state
        updateDocument([]);
        (async () => {
          await showLoader();
          const section = await APICall(getSectionDetailsV2, "POST", {
            MN: "EmployeeCentral",
            IN: "EC_Form",
            TN: attributesData["REQTABS"].code,
            UID: userDetails.Id,
            TID: parseInt(attributesData["REQEMPLID"].value),
            RoleId: currentRoleId?.value,
            //COU:TId==0?COU:userDetails.COU,
            // COU: userDetails.COU,
            COU: attributesData["REQEMPLID"].cou,
            IsNewHire: true,
          });

          if (
            section.data !== null &&
            section.data.t !== undefined &&
            section.data.t.tn === attributesData["REQTABS"].code
          ) {
            setFormData(section.data.t);
          } else {
            setFormData({});
          }

          await hideLoader();
        })();
        (async () => {
          showLoader();
          let bodyObj = {
            mn: "EmployeeCentral",
            in: "EC_Form",
            tn: attributesData["REQTABS"].code,
            SN: "Basic",
            TID: TId,
            ISH: "true",
            UserId: userDetails?.Id,
            RoleId: currentRoleId?.value,
            // COU: userDetails.COU,
            COU: attributesData["REQEMPLID"].cou,
          };

          const response = await APICall(
            getAttributeValueDetailsV2,
            "POST",
            bodyObj
          );

          if (
            response?.data?.sections &&
            response?.data?.sections?.length > 0
          ) {
            setSectionValuesArray(response.data.sections);
          } else {
            setSectionValuesArray([]);
          }
          hideLoader();
        })();
      }
    }
    if (attributesData["REQEMPLID"] != null) {
      (async () => {
        await showLoader();
        const globalValues = await APICall(getGlobalValues, "POST", {
          TID: attributesData["REQEMPLID"].value,
        });

        if (globalValues.data !== null && globalValues.data.length > 0) {
          setGlobalValues(globalValues.data);
        }
        await hideLoader();
      })();
    }
  }, [
    attributesData["REQEMPLID"],
    attributesData["REQTABS"],
    attributesData["REQSECT"],
  ]);

  //Get Reportees and Work Association data
  useEffect(() => {
    if (
      attributesData["REQEMPLID"] != null &&
      attributesData["REQEMPLID"] != undefined &&
      attributesData["REQTYPOUPD"] != null &&
      attributesData["REQTYPOUPD"] != undefined 
    ) {
      if (attributesData["REQTYPOUPD"].code !== "Termination"){
        return;
      }
      (async () => {
        // For Reportee
        const valueForDropdown = attributesData["REQEMPLID"].value;
        const ReporteeObj = await APICall(getReporteesForEmployee, "POST", {
          valueForDropdown,
        });

        if (
          ReporteeObj.data !== null &&
          ReporteeObj.data !== undefined &&
          ReporteeObj.data.length > 0
        ) {
          await hideLoader();
          setReportees(ReporteeObj.data);
        } else {
          await hideLoader();
          setReportees([]);
        }

        //For Job Relationship
        const RelationObj = await APICall(getRelationsForEmployee, "POST", {
          valueForDropdown,
        });
        if (
          RelationObj.data !== null &&
          RelationObj.data !== undefined &&
          RelationObj.data.length > 0
        ) {
          await hideLoader();
          setRelReportees(RelationObj.data);
        } else {
          await hideLoader();
          setRelReportees([]);
        }
      })();
    }
  }, [attributesData["REQEMPLID"]
    ,attributesData["REQTYPOUPD"]]);

  useEffect(() => {
    if (directReporteeRel != null && directReporteeRel != undefined) {
      directReporteeRel.code == "RLITTOM"
        ? setMappingHide(false)
        : setMappingHide(true);
    }
  }, [directReporteeRel]);

  useEffect(() => {
    let repMg = [];
    reportees.map((eachRep) => {
      const tempObj = {
        value: eachRep.value,
        label: eachRep.label,
        mg: null,
      };
      repMg.push(tempObj);
    });
    setReporteeManagerMap(repMg);
  }, [reportees]);

  useEffect(() => {
    let repRl = [];
    relReportees.map(async (eachRep) => {
      const user = await getSearchableDropdownAPI(
        "",
        "MG",
        null,
        eachRep.userid,
        "attribute",
        0
      );
      const role = optForRoles.find(
        (item) => item.value == eachRep.relationtype
      );
      //const relation = await getSearchableDropdownAPI("", "MG", null, eachRep.relationId, "attribute", 0);
      const tempObj = {
        id: eachRep.id,
        repName: user,
        repType: role,
        //repRel: relation,
        repRel: null,
      };
      repRl.push(tempObj);
    });
    setUserRelationMap(repRl);
  }, [relReportees]);

  useEffect(() => {
    if (
      attributesData["REQEMPLID"] != null &&
      attributesData["REQTABS"] != null &&
      attributesData["REQSECT"] != null &&
      formData != null
    ) {
      (async () => {
        await showLoader();
        //section.data.t.S is array jisme filter krna hai

        let setFinalSections = [];
        let sectArr = [];
        attributesData["REQSECT"].map((eachSect) => {
          sectArr.push(eachSect.value);
        });

        sectArr.map((eachId) => {
          const settingVal = formData.S?.find((item) => item.SId == eachId);
          if (settingVal){
            setFinalSections.push(settingVal);
          }
        });

        setFinalFormData(setFinalSections);

        console.log('Final Sections Data::::', setFinalSections);
        //Get workflowDetails here
        if(attributesData["REQEMPLID"]?.bisCode=='11000007' 
        && attributesData["REQEMPLID"]?.ecCode=='Worker' 
        && setFinalSections?.length == 1 
        && setFinalSections[0].SN=='JobOrganisationInformation' 
        && roleSystemName == "BUHR"){
          setEnableForTextileWorkerWfl(true);
          const workflowRes = await APICall(getWorkflowData, "POST", {
            WorkflowTypeId: attributesData["REQTYPOUPD"].value,
            WorkflowSubTypeId: attributesData["REQRSNOUP"].value,
            EmployeeId: attributesData["REQEMPLID"].value,
            InitiatorId: userDetails.Id,
            InitiatorRoleId: currentRoleId?.value,
            CountryId: attributesData["REQCNTRY"].value,
            PayrollLocationId: attributesData["REQPLOCN"]
              ? attributesData["REQPLOCN"]?.value
              : null,
            CustomWflConfig: 1,
          });
          if (workflowRes.data.length > 0) {
            setApprovers(workflowRes.data);
            let approvercount = 0;
            let temp = workflowRes.data;
            if (temp.length == 1) {
              setBtnHide(false);
            }
            if (temp.length > 1) {
              temp.map((eachapprover, index) => {
                if (index > 0) {
                  if (eachapprover.approvers == null) {
                    approvercount = approvercount + 1;
                  }
                  setapproverOpt((prev) => ({
                    ...prev,
                    [`${"Approver"} ${index}`]: eachapprover.approvers,
                  }));
                }
                setAllApprovers((prev) => ({
                  ...prev,
                  [`${"Approver"} ${index}`]: eachapprover.approvers,
                }));
  
                if (
                  eachapprover.approvers !== null &&
                  eachapprover.approvers.length > 0
                ) {
                  setBtnHide(false);
                }
              });
  
              if (temp.length - 1 == approvercount) {
                notify(1, "No Approvers are there for the workflow!");
                navigate("/home");
              }
            }
          }
        } else if (roleSystemName == "BUHR") {
          setEnableForTextileWorkerWfl(false);
          const workflowRes = await APICall(getWorkflowData, "POST", {
            WorkflowTypeId: attributesData["REQTYPOUPD"].value,
            WorkflowSubTypeId: attributesData["REQRSNOUP"].value,
            EmployeeId: attributesData["REQEMPLID"].value,
            InitiatorId: userDetails.Id,
            InitiatorRoleId: currentRoleId?.value,
            CountryId: attributesData["REQCNTRY"].value,
            PayrollLocationId: attributesData["REQPLOCN"]
              ? attributesData["REQPLOCN"]?.value
              : null,
            CustomWflConfig: null,
          });
          if (workflowRes.data.length > 0) {
            setApprovers(workflowRes.data);
            let approvercount = 0;
            let temp = workflowRes.data;
            if (temp.length == 1) {
              setBtnHide(false);
            }
            if (temp.length > 1) {
              temp.map((eachapprover, index) => {
                if (index > 0) {
                  if (eachapprover.approvers == null) {
                    approvercount = approvercount + 1;
                  }
                  setapproverOpt((prev) => ({
                    ...prev,
                    [`${"Approver"} ${index}`]: eachapprover.approvers,
                  }));
                }
                setAllApprovers((prev) => ({
                  ...prev,
                  [`${"Approver"} ${index}`]: eachapprover.approvers,
                }));
  
                if (
                  eachapprover.approvers !== null &&
                  eachapprover.approvers.length > 0
                ) {
                  setBtnHide(false);
                }
              });
  
              if (temp.length - 1 == approvercount) {
                notify(1, "No Approvers are there for the workflow!");
                navigate("/home");
              }
            }
          }
        } else if (attributesData["REQEMPLID"]?.bisCode=='11000007' 
        && attributesData["REQEMPLID"]?.ecCode=='Worker' 
        && setFinalSections?.length == 1 
        && setFinalSections[0].SN=='JobOrganisationInformation' 
        && roleSystemName == "HRHead"){
          setEnableForTextileWorkerWfl(true);
        } else if (roleSystemName == "HRHead"){
          setEnableForTextileWorkerWfl(false);
        }
        await hideLoader();
      })();
    }
  }, [formData, attributesData["REQSECT"]]);

  useEffect(() => {
    let arr = jsonParse(tempOldSectionData);
    setOldJson(arr);
  }, [tempOldSectionData]);

  useEffect(() => {
    (async () => {
      if (
        submitFunc == true &&
        getData.length > 0 &&
        attributesData["REQSECT"]?.length == getData?.length &&
        attributesData["REQTYPOUPD"].code == "DataChange" &&
        attributesData["REQRSNOUP"].code == "DTACHG"
      ) {
        let newJsonArr: any = jsonParse(sectionsData);
console.log('YSsectionsData::::', sectionsData);
console.log('YSnewJsonArr::::', newJsonArr);
        let tempOldJson = parseJsonViseVersa(oldJsonSectionData);
console.log('YSoldJsonSectionData::::', oldJsonSectionData);
console.log('YStempOldJson::::', tempOldJson);
        tempOldJson = compareAndAddKeys(tempOldJson, sectionsData);

        tempOldJson = jsonParse(tempOldJson);

        console.log('tempOldJsont::::',tempOldJson);
        console.log('newJsonArr::::',newJsonArr);
        const oldJson = JSON.stringify(tempOldJson);
        const newJson = JSON.stringify(newJsonArr);

        const oldAuditJson = oldJson;
        const newAuditJson = newJson;

        let tabSectionJson = {
          tab: attributesData["REQTABS"],
          section: attributesData["REQSECT"],
        };

        await showLoader();

        const finalObj = {
          WorkflowId: approvers[0].workFlowId,
          CountryId: attributesData["REQCNTRY"].value,
          PayrollLocationId: attributesData["REQPLOCN"]
            ? attributesData["REQPLOCN"]?.value
            : null,
          WorkflowTypeId: attributesData["REQTYPOUPD"].value,
          WorkflowSubTypeId: attributesData["REQRSNOUP"].value,
          EmployeeId: parseInt(attributesData["REQEMPLID"].value),
          EffectiveDate: attributesData["REQEFCTVDT"],
          Remarks: attributesData["REQDREMK"],
          Attachment: attributesData["REQSUPDOC"],
          BIS: attributesData["REQEMPLID"].bis,
          SBU: attributesData["REQEMPLID"].sbu,
          LEN: attributesData["REQEMPLID"].len,
          DV: attributesData["REQEMPLID"].dv,
          RequestOldJson: oldJson,
          RequestNewJson: newJson,
          AuditLogOldJson: oldAuditJson,
          AuditLogNewJson: newAuditJson,
          InitiatorId: parseInt(userDetails.Id),
          InitiatorRoleId: currentRoleId?.value,
          isSave: isSave,
          TabSectionMappingJson: JSON.stringify(tabSectionJson),
          CreatedBy: userDetails.Id,
          CreatedByRoleId: currentRoleId?.value,
          UserName: FullName,
          UserEmail: userDetails?.EmailId || "",
          UserId: userDetails.Id,
          ProxyCreatedBy: proxyUserDetails == null ? null : userDetails.UserId,
          PosSync: isPositionSync,
        };

        const validateSectionRes = await APICall(validateSections, "POST", {
          sectionsData: getData,
        });

        console.log("Final Obj:", finalObj);

        if (validateSectionRes.status === 1) {
          await hideLoader();
          setSubmitFunc(false);
          notify(validateSectionRes.status, validateSectionRes.message);
        } else {
          await hideLoader();
          const postRes = await APICall(
            postDataForProgression,
            "POST",
            finalObj
          );

          if (postRes?.status == 0) {
            //Check if entry in change log
            let changelogchangespresent = false;
            const changelogentrycheck = await APICall(
              checkIfEntryInAuditLog,
              "POST",
              {Id: postRes.data.progressionId}
            );
            if (changelogentrycheck.status === 0 && changelogentrycheck.data === true) {
              changelogchangespresent = true;
            } else if (changelogentrycheck.status === 0 && changelogentrycheck.data === false) {
              changelogchangespresent = false;
            } else {
              hideLoader();
              changelogchangespresent = true;
              notify(1, "Check entry in change log exists failed!");
            }
            if(!changelogchangespresent){
              const progRevoke = await APICall(
                anyProgressionRevoke,
                "POST",
                {Id: postRes.data.progressionId}
              );
              if (progRevoke.status===0){
                notify(1, 'No Changes were made so No Request is Created. Please do some changes in the Request.');
                // navigate("/home");
              } else {
                notify(1, 'No changes where made but something went wrong while removing the request. Please create a new Request.');
                // navigate("/home");
              }
              return;
            }
            //----
            //Entry in Action History
            const actHistObj = await APICall(entryUpdateInActionHistory, "POST", {
                Status: `${attributesData["REQTYPOUPD"]?.label} Initiated.`,
                Remarks: attributesData["REQDREMK"],
                ProgId: postRes.data.progressionId,
                CreatedBy: userDetails.Id,
              } 
            );
            //---
            if (proSupportingDoc.length > 0) {
              await showLoader();
              const obj = {
                TId: parseInt(attributesData["REQEMPLID"].value),
                files: proSupportingDoc.map((doc) => {
                  return { ...doc, Id: postRes.data.progressionId };
                }),
              };

              const docRes = await APICall(
                uploadSupportingDocForProgression,
                "POST",
                obj
              );
            }

            if (documents.length > 0) {
              await showLoader();
              //upload new documents in sharepoint
              let originalDocs = documents.map((doc, index) => ({
                ...doc,
                tempId: index,
              }));
              let newDocs = originalDocs.filter(
                (doc) => doc.IsActive === true && doc.IsNew === true
              );

              const docsRes = await APICall(
                uploadSectionDocForProgression,
                "POST",
                {
                  TId: parseInt(attributesData["REQEMPLID"].value),
                  progressionFiles: newDocs,
                }
              );

              //loop to change Data in existing currentDocs

              if (docsRes.data !== null && docsRes.message === "Success") {
                let docRes = docsRes.data;
                newDocs = newDocs.map((doc, index) => {
                  let docFromApi = docRes[index];
                  return { ...doc, Data: docFromApi.Data };
                });

                originalDocs = originalDocs.map((doc) => {
                  const updatedDoc = newDocs.find(
                    (updated) => updated.tempId === doc.tempId
                  );
                  if (updatedDoc !== undefined) {
                    return { ...updatedDoc };
                  } else {
                    return doc;
                  }
                });

                const propertyToRemove = "tempId";

                originalDocs = originalDocs.map((item) => {
                  const { [propertyToRemove]: _, ...rest } = item;
                  return rest;
                });

                const sectionDocsRes = await APICall(
                  updateSectionDocInProgression,
                  "POST",
                  {
                    Id: postRes.data.progressionId,
                    SupportingDoc: JSON.stringify(originalDocs),
                  }
                );
              } else {
                await hideLoader();
                navigate("/home");
                notify(1, "Something went wrong. Section in documents!");
              }
            }
            await hideLoader();
            navigate("/home");
            notify(postRes.status, postRes.message);
            setIsDataChangeUploaded(true);
          } else {
            await hideLoader();
            navigate("/home");
            notify(1, "Something went wrong.");
          }
        }
      } else if (
        submitFunc == true &&
        getData.length > 0 &&
        (attributesData["REQTYPOUPD"].code == "Suspension" ||
          attributesData["REQTYPOUPD"].code == "Compensation" ||
          attributesData["REQTYPOUPD"].code == "Demotion" || 
          attributesData["REQTYPOUPD"].code == "Onetimepay")         
      ) {

        let newJsonArr: any = jsonParse(sectionsData);

        let tempOldJson = parseJsonViseVersa(oldJsonSectionData);

        tempOldJson = compareAndAddKeys(tempOldJson, sectionsData);

        tempOldJson = jsonParse(tempOldJson);

        const oldJson = JSON.stringify(tempOldJson);
        const newJson = JSON.stringify(newJsonArr);

        const oldAuditJson = oldJson;
        const newAuditJson = newJson;

        let tabSectionJson = {
          tab: attributesData["REQTABS"],
          section: attributesData["REQSECT"],
        };

        await showLoader();

        let isValidDynamic = true;
        //for validating demotion extra attributes
        if (attributesData["REQTYPOUPD"].code == "Demotion") {
          if (dynamicAccordians.length > 0) {
            isValidDynamic = await checkDynamicAccValidation();
          }
        }
        if (isValidDynamic) {
          const finalObj = {
            WorkflowId: approvers[0].workFlowId,
            CountryId: attributesData["REQCNTRY"].value,
            PayrollLocationId: attributesData["REQPLOCN"]
              ? attributesData["REQPLOCN"]?.value
              : null,
            WorkflowTypeId: attributesData["REQTYPOUPD"].value,
            WorkflowSubTypeId: attributesData["REQRSNOUP"].value,
            EmployeeId: parseInt(attributesData["REQEMPLID"].value),
            EffectiveDate: attributesData["REQEFCTVDT"],
            Remarks: attributesData["REQDREMK"],
            Attachment: attributesData["REQSUPDOC"],
            BIS: attributesData["REQEMPLID"].bis,
            SBU: attributesData["REQEMPLID"].sbu,
            LEN: attributesData["REQEMPLID"].len,
            DV: attributesData["REQEMPLID"].dv,
            RequestOldJson: oldJson,
            RequestNewJson: newJson,
            AuditLogOldJson: oldAuditJson,
            AuditLogNewJson: newAuditJson,
            InitiatorId: parseInt(userDetails.Id),
            InitiatorRoleId: currentRoleId?.value,
            isSave: isSave,
            TabSectionMappingJson: JSON.stringify(tabSectionJson),
            CreatedBy: userDetails.Id,
            CreatedByRoleId: currentRoleId?.value,
            UserName: FullName,
            UserEmail: userDetails?.EmailId || "",
            UserId: userDetails.Id,
            ProxyCreatedBy:
              proxyUserDetails == null ? null : userDetails.UserId,
          };

          // add extra attributes in demotion json format
          if (attributesData["REQTYPOUPD"].code == "Demotion") {
            let oldJsonObj = [];
            let newJsonObj = [];
            let newForAuditLog = [];

            Object.keys(dynamicAccordians).length > 0 &&
              dynamicAccordians.map(async (eachAttr) => {
                if (eachAttr.attributeType === "Attribute") {
                  if (eachAttr.attributeDatatype == "DropdownSingle") {
                    const tempOldObj = {
                      [eachAttr.attributeCode]:
                        eachAttr.value == null || eachAttr.value == undefined
                          ? ""
                          : parseInt(eachAttr.value),
                    };
                    oldJsonObj.push(tempOldObj);
                    const tempNewObj = {
                      [eachAttr.attributeCode]:
                        eachAttr.valueOnDd == undefined ||
                        eachAttr.valueOnDd == null
                          ? ""
                          : parseInt(eachAttr.valueOnDd.value),
                    };
                    newJsonObj.push(tempNewObj);
                    const tempNewAuditObj = {
                      sectionId: eachAttr.attributeSectionId,
                      IsAttributeSet: false,
                      Code: eachAttr.attributeCode,
                      Attribute: [
                        {
                          [eachAttr.attributeCode]:
                            eachAttr.valueOnDd == undefined ||
                            eachAttr.valueOnDd == null
                              ? ""
                              : eachAttr.valueOnDd.label,
                        },
                      ],
                      AttributeValue: [
                        {
                          [eachAttr.attributeCode]:
                            eachAttr.valueOnDd == undefined ||
                            eachAttr.valueOnDd == null
                              ? ""
                              : eachAttr.valueOnDd.value,
                        },
                      ],
                      Main: [
                        {
                          [eachAttr.attributeCode]:
                            eachAttr.valueOnDd == undefined ||
                            eachAttr.valueOnDd == null
                              ? ""
                              : {
                                  label: eachAttr.valueOnDd.label,
                                  value: eachAttr.valueOnDd.value,
                                },
                        },
                      ],
                    };
                    newForAuditLog.push(tempNewAuditObj);
                  }
                  if (eachAttr.attributeDatatype == "Text") {
                    const tempOldObj = {
                      [eachAttr.attributeCode]:
                        eachAttr.value == null || eachAttr.value == undefined
                          ? ""
                          : eachAttr.value,
                    };
                    oldJsonObj.push(tempOldObj);
                    const tempNewObj = {
                      [eachAttr.attributeCode]:
                        eachAttr.valueOnDd == undefined ||
                        eachAttr.valueOnDd == null
                          ? ""
                          : eachAttr.valueOnDd,
                    };
                    newJsonObj.push(tempNewObj);
                    const tempNewAuditObj = {
                      sectionId: eachAttr.attributeSectionId,
                      IsAttributeSet: false,
                      Code: eachAttr.attributeCode,
                      Attribute: [
                        {
                          [eachAttr.attributeCode]:
                            eachAttr.valueOnDd == undefined ||
                            eachAttr.valueOnDd == null
                              ? ""
                              : eachAttr.valueOnDd,
                        },
                      ],
                    };
                    newForAuditLog.push(tempNewAuditObj);
                  }
                  if (eachAttr.attributeDatatype == "Number") {
                    const tempOldObj = {
                      [eachAttr.attributeCode]:
                        eachAttr.value == null || eachAttr.value == undefined
                          ? ""
                          : parseInt(eachAttr.value),
                    };
                    oldJsonObj.push(tempOldObj);
                    const tempNewObj = {
                      [eachAttr.attributeCode]:
                        eachAttr.valueOnDd == undefined ||
                        eachAttr.valueOnDd == null
                          ? ""
                          : parseInt(eachAttr.valueOnDd),
                    };
                    newJsonObj.push(tempNewObj);
                    const tempNewAuditObj = {
                      sectionId: eachAttr.attributeSectionId,
                      IsAttributeSet: false,
                      Code: eachAttr.attributeCode,
                      Attribute: [
                        {
                          [eachAttr.attributeCode]:
                            eachAttr.valueOnDd == undefined ||
                            eachAttr.valueOnDd == null
                              ? ""
                              : parseInt(eachAttr.valueOnDd),
                        },
                      ],
                    };
                    newForAuditLog.push(tempNewAuditObj);
                  }
                  if (eachAttr.attributeDatatype == "Date") {
                    const parsedDateOld = moment(eachAttr.value, "DD-MMM-YYYY");
                    const formattedDateOld = parsedDateOld.format("YYYY-MM-DD");
                    const tempOldObj = {
                      [eachAttr.attributeCode]:
                        eachAttr.value == null ||
                        eachAttr.value == undefined ||
                        formattedDateOld == "Invalid date"
                          ? ""
                          : formattedDateOld,
                    };
                    oldJsonObj.push(tempOldObj);

                    const parsedDateNew = moment(
                      eachAttr.valueOnDd,
                      "DD-MMM-YYYY"
                    );
                    const formattedDateNew = parsedDateNew.format("YYYY-MM-DD");
                    const tempNewObj = {
                      [eachAttr.attributeCode]:
                        eachAttr.valueOnDd == undefined ||
                        eachAttr.valueOnDd == null ||
                        formattedDateNew == "Invalid date"
                          ? ""
                          : formattedDateNew,
                    };
                    newJsonObj.push(tempNewObj);
                    const tempNewAuditObj = {
                      sectionId: eachAttr.attributeSectionId,
                      IsAttributeSet: false,
                      Code: eachAttr.attributeCode,
                      Attribute: [
                        {
                          [eachAttr.attributeCode]:
                            eachAttr.valueOnDd == undefined ||
                            eachAttr.valueOnDd == null ||
                            formattedDateNew == "Invalid date"
                              ? ""
                              : formattedDateNew,
                        },
                      ],
                    };
                    newForAuditLog.push(tempNewAuditObj);
                  }
                  if (
                    eachAttr.attributeDatatype == "SearchableDropdownSingle"
                  ) {
                    const tempOldObj = {
                      [eachAttr.attributeCode]:
                        eachAttr.value == null || eachAttr.value == undefined
                          ? ""
                          : parseInt(eachAttr.value),
                    };
                    oldJsonObj.push(tempOldObj);
                    const tempNewObj = {
                      [eachAttr.attributeCode]:
                        eachAttr.valueOnDd == undefined ||
                        eachAttr.valueOnDd == null
                          ? ""
                          : parseInt(eachAttr.valueOnDd.value),
                    };
                    newJsonObj.push(tempNewObj);

                    const tempNewAuditObj = {
                      sectionId: eachAttr.attributeSectionId,
                      IsAttributeSet: false,
                      Code: eachAttr.attributeCode,
                      Attribute: [
                        {
                          [eachAttr.attributeCode]:
                            eachAttr.valueOnDd == undefined ||
                            eachAttr.valueOnDd == null
                              ? ""
                              : parseInt(eachAttr.valueOnDd.label),
                        },
                      ],
                    };
                    newForAuditLog.push(tempNewAuditObj);
                  }
                } else if (eachAttr.attributeType === "AttributeSet") {
                }
              });

            const oldJSONparsed = JSON.parse(oldAuditJsonState);
            let tempArr = [];
            tempArr = JSON.parse(finalObj["RequestOldJson"]);
            finalObj["RequestOldJson"] = JSON.stringify([
              ...tempArr,
              ...oldJSONparsed,
            ]);
            tempArr = JSON.parse(finalObj["AuditLogOldJson"]);
            finalObj["AuditLogOldJson"] = JSON.stringify([
              ...tempArr,
              ...oldJSONparsed,
            ]);
            tempArr = JSON.parse(finalObj["RequestNewJson"]);
            finalObj["RequestNewJson"] = JSON.stringify([
              ...tempArr,
              ...newForAuditLog,
            ]);
            tempArr = JSON.parse(finalObj["AuditLogNewJson"]);
            finalObj["AuditLogNewJson"] = JSON.stringify([
              ...tempArr,
              ...newForAuditLog,
            ]);
          }

          console.log("Final Obj:", finalObj);

          if (attributesData["REQTYPOUPD"].code == "Onetimepay"){
            const validateSectionRes = await APICall(validateSections, "POST", {
              sectionsData: getData,
            });
  
            if (validateSectionRes.status === 1) {
              await hideLoader();
              setSubmitFunc(false);
              notify(validateSectionRes.status, validateSectionRes.message);
              return null;
            }
          } 
          const postRes = await APICall(
            postDataForProgression,
            "POST",
            finalObj
          );
          if (postRes?.status == 0) {
            //Check if entry in change log
            let changelogchangespresent = false;
            const changelogentrycheck = await APICall(
              checkIfEntryInAuditLog,
              "POST",
              {Id: postRes.data.progressionId}
            );
            if (changelogentrycheck.status === 0 && changelogentrycheck.data === true) {
              changelogchangespresent = true;
            } else if (changelogentrycheck.status === 0 && changelogentrycheck.data === false) {
              changelogchangespresent = false;
            } else {
              hideLoader();
              changelogchangespresent = true;
              notify(1, "Check entry in change log exists failed!");
            }
            if(!changelogchangespresent){
              const progRevoke = await APICall(
                anyProgressionRevoke,
                "POST",
                {Id: postRes.data.progressionId}
              );
              if (progRevoke.status===0){
                notify(1, 'No Changes were made so No Request is Created. Please do some changes in the Request.');
                // navigate("/home");
              } else {
                notify(1, 'No changes where made but something went wrong while removing the request. Please create a new Request.');
                // navigate("/home");
              }
              return;
            }
            //---
            //Entry in Action History
            const actHistObj = await APICall(entryUpdateInActionHistory, "POST", {
                Status: `${attributesData["REQTYPOUPD"]?.label} Initiated.`,
                Remarks: attributesData["REQDREMK"],
                ProgId: postRes.data.progressionId,
                CreatedBy: userDetails.Id,
              }
            );
            //--
            if (proSupportingDoc.length > 0) {
              const obj = {
                TId: parseInt(attributesData["REQEMPLID"].value),
                files: proSupportingDoc.map((doc) => {
                  return { ...doc, Id: postRes.data.progressionId };
                }),
              };

              const docRes = await APICall(
                uploadSupportingDocForProgression,
                "POST",
                obj
              );
            }
            await hideLoader();
            navigate("/home");
            notify(0, "Record Saved Successfully.");
            setIsDataChangeUploaded(true);
          } else {
            await hideLoader();
            navigate("/home");
            notify(1, "Something went wrong.");
          }
          await hideLoader();
        } else {
          await hideLoader();
          console.error("NOT VALID");
          notify(1, "Please Fill required Data.");
        }
      }
      setIsSave(false);
      setIsDataChangeUploaded(false);
      setBtnDisable(false);
      setSubmitFunc(false);
    })();
  }, [submitFunc, getData]);

  function compareAndAddKeys(OldJson, NewJson) {
    const objKeysOld = Object.keys(OldJson);
    const objKeysNew = Object.keys(NewJson);
    if (objKeysOld.length != objKeysNew.length){
      for (const tempKey in objKeysNew){
        if(!objKeysOld.includes(objKeysNew[tempKey])){
          console.log(objKeysNew[tempKey]);
          // const temporaryKey = NewJson[objKeysNew[tempKey]];
          OldJson = {...OldJson, [objKeysNew[tempKey]]: null}
        }
      }
    }
    //Loop over Old Json
    for (const sectionCode in OldJson) {
      //per section data
      let sectionOldJson = OldJson[sectionCode];

      let sectionNewJson = NewJson[sectionCode];

      //Loop over new section json for particular section id
      for (const key in sectionNewJson) {

        if (OldJson[sectionCode] == null){
          OldJson = {
            ...OldJson, 
            [sectionCode]: {[key]: {}}
          }
        }
        // console.log(OldJson);

        // if flat and Property exists in
        // new json but not in old json
        //then add in old json with empty string
        if (
          // sectionOldJson &&
          !sectionOldJson?.hasOwnProperty(key) &&
          !Array.isArray(sectionNewJson[key])
        ) {
          sectionOldJson[key] = ""; //
        } else if (Array.isArray(sectionNewJson[key])) {
          // if SEt
          //get new  records in new Json
          let newAddedRecords = sectionNewJson[key].filter(
            (row) => !Number.isInteger(row?.Id)
          );

          newAddedRecords = newAddedRecords.map((row) => {
            if (row && Object.keys(row).length > 0) {
              let newRow = {};
              for (const column in row) {
                if (column != "Id" && column != "IsActive" && column != "EID") {
                  newRow[column] = "";
                } else {
                  newRow[column] = row[column];
                }
              }
              return newRow;
            }
          });

          // add both old records and new records in json
          let newOldJson = null;
          if (sectionOldJson?.[key]) {
            newOldJson = [...newAddedRecords, ...sectionOldJson[key]];
          } else {
            newOldJson = [...newAddedRecords];
          }

          OldJson[sectionCode][key] = newOldJson;

          let x = 0;
        }
      }
    }

    return OldJson;
  }

  function jsonParse(inputData) {
    const result = [];
    Object.keys(inputData).forEach((sectionId) => {
      const sectionData = inputData[sectionId];
      const sectionIdInt = parseInt(sectionId);

      // Create an array of objects for each key-value pair
      Object.keys(sectionData).forEach((key) => {
        const keyValueObject = {
          sectionId: sectionIdInt,
        };
        const value = sectionData[key];

        if (Array.isArray(value)) {
          value.map((i) => {
            let object = {};
            object["IsAttributeSet"] = true;
            object["Code"] = key;
            object["RecordId"] = i?.Id;
            object["sectionId"] = sectionIdInt;

            let newObj = {};
            let newObj2 = {};
            let newObj3 = {};

            Object.keys(i).map((key) => {
              newObj["Id"] = i?.Id;
              newObj[key] = i[key]?.label != null && i[key]?.label != undefined ? i[key]?.label : i[key];
              object["Attribute"] = [newObj];

              newObj2["Id"] = i?.Id;
              newObj2[key] = i[key]?.value || i[key];
              object["AttributeValue"] = [newObj2];

              newObj2["Id"] = i?.Id;
              newObj3[key] = i[key];
              object["Main"] = [newObj3];
            });
            result.push(object);
          });
        } else {
          keyValueObject["IsAttributeSet"] = false;
          keyValueObject["Code"] = key;
          let Attribute = [];
          Attribute.push({ [key]: value?.label != null && value?.label != undefined ? value?.label : value });

          let AttributeValue = [];
          AttributeValue.push({ [key]: value?.value || value });

          keyValueObject["Attribute"] = Attribute;
          keyValueObject["AttributeValue"] = AttributeValue;
          keyValueObject["Main"] = [{ [key]: value }];
          result.push(keyValueObject);
        }
      });
    });

    return result;
  }

  function test() {
    const oldJsonNotParsed = parseJsonViseVersa(oldJsonSectionData);

    const oldJsonWithAddedNewKeys = compareAndAddKeys(
      oldJsonNotParsed,
      sectionsData
    );

    const newJson = jsonParse(sectionsData);
    const oldJson = jsonParse(oldJsonNotParsed);
  }

  const fileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileValidation = (files) => {
    const [FS, FX, FC] = [
      5,
      ".docx,.doc,.pdf,.png,.jpg,.jpeg,.txt,.ppt,.pptx,.xlsx",
      2,
    ];

    // Split the validExtensionsString into an array
    const validExtensions = FX.split(",");

    let isValid = [];

    // validate file count
    if (files.length > FC) {
      notify(1, `Only ${FC} files allowed!`);
      return false;
    }
    // check extension of each file
    files.forEach((file) => {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();

      // Check if the file extension is allowed
      if (!validExtensions.includes("." + fileExtension)) {
        isValid.push(false);
      } else {
        isValid.push(true); //valid
      }
    });

    let returnValue = isValid.some((v) => v === false);

    if (returnValue) {
      notify(1, `Please upload file within this ${FX} extentsion`);
      return false;
    }

    files.forEach((file) => {
      const fileSize = file.size;
      const maxFileSizeBytes = FS * 1024 * 1024;
      if (fileSize <= maxFileSizeBytes) {
        isValid.push(true); //valid
      } else {
        isValid.push(false);
      }
    });

    returnValue = isValid.some((v) => v === false);

    if (returnValue) {
      notify(1, `File size exceeds ${FS}MB. Please select a smaller file.`);
      return false;
    } else {
      return true;
    }
  };

  const handleSupportingDoc = async (e, AC) => {
    const [FS, FX, FC] = [
      "5",
      ".docx,.doc,.pdf,.png,.jpg,.jpeg,.txt,.ppt,.pptx,.xlsx",
      2,
    ];

    const files = Array.prototype.slice.call(e.target.files);

    const isValid = handleFileValidation(files);
    if (isValid) {
      if (files && files.length > 0) {
        const base64Array: { Name: string; Data: string }[] = [];
        for (let i = 0; i < files.length; i++) {
          const base64 = (await fileToBase64(files[i])) as string;
          const base64WithoutPrefix = base64.split(",")[1];
          base64Array.push({
            Name: files[i].name,
            Data: base64WithoutPrefix,
          });
        }

        //check if AC present and file count matched

        const fileCount = proSupportingDoc.reduce((total, item) => {
          if (item.AC === AC) {
            return total + 1;
          }
          return total;
        }, 0);

        if (fileCount == FC) {
          notify(1, `Only ${FC} files allowed!`);
        } else {
          base64Array.forEach((file) => {
            setProSupportingDoc((prevSelectedFiles) => [
              ...prevSelectedFiles,
              {
                AC: AC,
                Name: file.Name,
                Data: file.Data,
                IsValidFile: false,
                DocId: 0,
              },
            ]);
          });
        }
      }
    } else {
      e.target.value = null;
    }
  };

  // console.log(
  //   "User Details:::::",
  //   userDetails,
  //   "Proxy User Details::::: ",
  //   proxyUserDetails
  // );

  const handleFileChangeForFlat = async (e, attribute) => {
    const { FS, FX, FC } = JSON.parse(attribute.attributeValidations);

    const files = Array.prototype.slice.call(e.target.files);

    const isValid = handleFileValidation(files);
    if (isValid) {
      if (files && files.length > 0) {
        const base64Array: { Name: string; Data: string }[] = [];
        for (let i = 0; i < files.length; i++) {
          const base64 = (await fileToBase64(files[i])) as string;
          const base64WithoutPrefix = base64.split(",")[1];
          base64Array.push({ Name: files[i].name, Data: base64WithoutPrefix });
        }

        //check if AC present and file count matched

        const fileCount = selectedFilesForFlat.reduce((total, item) => {
          if (item.AC === attribute.AC) {
            return total + 1;
          }
          return total;
        }, 0);

        if (fileCount == FC) {
          notify(1, `Only ${FC} files allowed!`);
        } else {
          // let type =
          //   JSON.parse(attribute.AD).CON === "National ID Information"
          //     ? attributeSetData["IDCATY"]?.label
          //     : JSON.parse(attribute.AD).TYP
          //     ? JSON.parse(attribute.AD).TYP
          //     : "";
          console.log('prevSelectedFiles', attribute);
          base64Array.forEach((file) => {
            setSelectedFilesForFlat((prevSelectedFiles) => [
              ...prevSelectedFiles,
              {
                AC: attribute.attributeCode, //==="CONFDOC"?attributesData["DOCTYPE"]?.code :attribute.AC,
                Name: file.Name,
                Data: file.Data,
                IsValidFile: false,
                DocId: 0,
                Type:JSON.parse(attribute.attributeAdditionalDetails).TYP,
                Context:JSON.parse(attribute.attributeAdditionalDetails).CON,
                DCVALUE: "",
                DVTILL: "",
                DSTATUS: "Verified",
                DPSINCE: "",
                DPWITH: "",
              },
            ]);
          });
        }
      }
    } else {
      e.target.value = null;
    }
  };

  console.log('selectedFlatFiles', selectedFilesForFlat);

  const handleDownloadFileFlat = async (fileAttribute, fileIndex) => {
    showLoader();
    if (
      fileAttribute.DocId != undefined &&
      fileAttribute.DocId != null &&
      fileAttribute.DocId != 0
    ) {
      const doc = await APICall(downloadDocument, "POST", {
        TId,
        AC: fileAttribute.AC,
        DocId: fileAttribute.DocId,
      });
      if (doc && doc.data !== null) {
        downloadBase64File(doc.data, fileAttribute.Name);
        hideLoader();
      } else {
        notify(1, "Something Went Wrong.");
        hideLoader();
      }
    } else {
      downloadBase64File(fileAttribute.Data, fileAttribute.Name);
      hideLoader();
    }
  };

  function downloadBase64File(base64String: string, fileName: string) {
    const mimeType = inferMimeType(base64String);
    const blob = base64toBlob(base64String, mimeType);

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  }

  function inferMimeType(base64String: string): string {
    const dataUrl = `data:application/octet-stream;base64,${base64String}`;
    const typeInfo = /^data:(.*?);/.exec(dataUrl);

    if (typeInfo && typeInfo[1]) {
      return typeInfo[1];
    }

    return "application/octet-stream";
  }

  function base64toBlob(base64String: string, mimeType: string): Blob {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], { type: mimeType });
  }

  const handleDeleteFileForFlat = async (fileAttribute, fileIndex) => {
    if (
      fileAttribute.DocId != undefined &&
      fileAttribute.DocId != null &&
      fileAttribute.DocId != 0
    ) {
      showLoader();
      const doc = await APICall(deleteDocument, "POST", {
        TId,
        AC: fileAttribute.AC,
        DocId: fileAttribute.DocId,
        UserId: proxyUserDetails?.EmployeeId
          ? proxyUserDetails?.EmployeeId
          : userDetails.UserId,
      });
      if (doc.message === "Success") {
        FlatDocRef.current.value = null;
        let remainingDocs = selectedFilesForFlat.filter(function (obj) {
          return obj.DocId !== fileAttribute.DocId;
        });
        setSelectedFilesForFlat(remainingDocs);
        hideLoader();
      } else {
        notify(1, "File not Deleted, Something Went Wrong.");
        hideLoader();
      }
    } else {
      FlatDocRef.current.value = null;
      setSelectedFilesForFlat((prevSelectedFiles) => {
        return prevSelectedFiles.filter(
          (item, attIndex) => fileIndex !== attIndex
        );
      });
    }
  };

  

  ///////////////////////////////////////////////////////////////////////////
  const handleSubmitButton = async (posSync = false) => {
    setBtnDisable(true);
    setDynamicformErrors({});
    if (
      (attributesData["REQTYPOUPD"].code == "DataChange" &&
        attributesData["REQRSNOUP"].code == "DTACHG") ||
      attributesData["REQTYPOUPD"].code == "Suspension" ||
      attributesData["REQTYPOUPD"].code == "Compensation" ||
      attributesData["REQTYPOUPD"].code == "Demotion" || 
      attributesData["REQTYPOUPD"].code == "Onetimepay"
    ) {
      setFormErrors({});
      setDynamicformErrors({});

      let isvalid = await checkValidation();

      if (!isvalid) {
        setBtnDisable(false);
        return;
      }
      setIsSave(false);
      setSubmitFunc(true);
      setGetData([]);
    } else {
      showLoader();
      // setBtnDisable(true);

      try {
        setFormErrors({});
        // setBtnDisable(true);
        setDynamicformErrors({});
        let isvalid = await checkValidation();
        let isValidDynamic = true;
        if (dynamicAccordians.length > 0) {
          isValidDynamic = await checkDynamicAccValidation();
        }

        if (isvalid && isValidDynamic) {
          // manager map
          let repMgMap = [];
          // relationship map
          let userRlMap = [];
          if (attributesData["REQTYPOUPD"].code == "Termination") {
            if (
              directReportee != null &&
              (directReportee.code == "TATUP" || directReportee.code == "TATOM")
            ) {
              if (reporteeManagerMap != null && reporteeManagerMap.length > 0) {
                reporteeManagerMap.map((eachRep) => {
                  const tempObj = {
                    EmpId: eachRep.value,
                    ManagerId: managerForReportee.value,
                  };
                  repMgMap.push(tempObj);
                });
              }
            } else if (
              directReportee != null &&
              directReportee.code == "ITTOM"
            ) {
              if (reporteeManagerMap != null && reporteeManagerMap.length > 0) {
                reporteeManagerMap.map((eachRep) => {
                  const tempObj = {
                    EmpId: eachRep.value,
                    ManagerId: eachRep.mg.value,
                  };
                  repMgMap.push(tempObj);
                });
              }
            }

            if (
              directReporteeRel != null &&
              (directReporteeRel.code == "RLTATUP" ||
                directReporteeRel.code == "RLTATOM")
            ) {
              if (userRelationMap != null && userRelationMap.length > 0) {
                userRelationMap.map((eachRep) => {
                  const tempObj = {
                    Id: eachRep.id,
                    RelationId: relationForReportee.value,
                  };
                  userRlMap.push(tempObj);
                });
              }
            } else if (
              directReporteeRel != null &&
              directReporteeRel.code == "RLITTOM"
            ) {
              if (userRelationMap != null && userRelationMap.length > 0) {
                userRelationMap.map((eachRep) => {
                  const tempObj = {
                    Id: eachRep.id,
                    RelationId: eachRep.repRel.value,
                  };
                  userRlMap.push(tempObj);
                });
              }
            } else if (
              directReporteeRel != null &&
              directReporteeRel.code == "RLTAR"
            ) {
              if (userRelationMap != null && userRelationMap.length > 0) {
                userRelationMap.map((eachRep) => {
                  const tempObj = {
                    Id: eachRep.id,
                    RelationId: 0,
                  };
                  userRlMap.push(tempObj);
                });
              }
            }
          }
          const repMgMapJson = JSON.stringify(repMgMap);

          const userRlMapJson = JSON.stringify(userRlMap);

          let customName = attributesData["REQRSNOUP"].label;
          if (attributesData["REQTYPOUPD"].code == "Transfer") {
            let arrForName = [];
            dynamicAccordians.map(async (eachAttr) => {
              if (eachAttr?.value != eachAttr?.valueOnDd?.value 
                && eachAttr.attributeCode != 'SCN' 
                && eachAttr.attributeCode != 'SSCN' 
                && eachAttr.attributeCode != 'SSSCN'
              ){
                arrForName.push(eachAttr.attributeCode);
              }
            });
            const extendedName = arrForName.join("_");
            customName = customName + "_" + extendedName;
          }

          const isSave = false;
          let oldJsonObj = [];
          let newJsonObj = [];
          let newForAuditLog = [];

          Object.keys(dynamicAccordians).length > 0 &&
            dynamicAccordians.map(async (eachAttr) => {
              if (eachAttr.attributeType === "Attribute") {
                if (eachAttr.attributeDatatype == "DropdownSingle") {
                  const tempOldObj = {
                    [eachAttr.attributeCode]:
                      eachAttr.value == null || eachAttr.value == undefined
                        ? ""
                        : parseInt(eachAttr.value),
                  };
                  oldJsonObj.push(tempOldObj);
                  const tempNewObj = {
                    [eachAttr.attributeCode]:
                      eachAttr.valueOnDd == undefined ||
                      eachAttr.valueOnDd == null
                        ? ""
                        : parseInt(eachAttr.valueOnDd.value),
                  };
                  newJsonObj.push(tempNewObj);
                  const tempNewAuditObj = {
                    sectionId: eachAttr.attributeSectionId,
                    IsAttributeSet: false,
                    Code: eachAttr.attributeCode,
                    Attribute: [
                      {
                        [eachAttr.attributeCode]:
                          eachAttr.valueOnDd == undefined ||
                          eachAttr.valueOnDd == null
                            ? ""
                            : eachAttr.valueOnDd.label,
                      },
                    ],
                  };
                  newForAuditLog.push(tempNewAuditObj);
                }
                if (eachAttr.attributeDatatype == "Text") {
                  const tempOldObj = {
                    [eachAttr.attributeCode]:
                      eachAttr.value == null || eachAttr.value == undefined
                        ? ""
                        : eachAttr.value,
                  };
                  oldJsonObj.push(tempOldObj);
                  const tempNewObj = {
                    [eachAttr.attributeCode]:
                      eachAttr.valueOnDd == undefined ||
                      eachAttr.valueOnDd == null
                        ? ""
                        : eachAttr.valueOnDd,
                  };
                  newJsonObj.push(tempNewObj);
                  const tempNewAuditObj = {
                    sectionId: eachAttr.attributeSectionId,
                    IsAttributeSet: false,
                    Code: eachAttr.attributeCode,
                    Attribute: [
                      {
                        [eachAttr.attributeCode]:
                          eachAttr.valueOnDd == undefined ||
                          eachAttr.valueOnDd == null
                            ? ""
                            : eachAttr.valueOnDd,
                      },
                    ],
                  };
                  newForAuditLog.push(tempNewAuditObj);
                }
                if (eachAttr.attributeDatatype == "Number") {
                  const tempOldObj = {
                    [eachAttr.attributeCode]:
                      eachAttr.value == null || eachAttr.value == undefined
                        ? ""
                        : eachAttr.attributeCode === "LOPHR"
                        ? eachAttr.value
                        : parseInt(eachAttr.value),
                  };
                  oldJsonObj.push(tempOldObj);
                  const tempNewObj = {
                    [eachAttr.attributeCode]:
                      eachAttr.valueOnDd == undefined ||
                      eachAttr.valueOnDd == null
                        ? ""
                        : eachAttr.attributeCode === "LOPHR"
                        ? eachAttr.valueOnDd
                        : parseInt(eachAttr.valueOnDd),
                  };
                  newJsonObj.push(tempNewObj);
                  const tempNewAuditObj = {
                    sectionId: eachAttr.attributeSectionId,
                    IsAttributeSet: false,
                    Code: eachAttr.attributeCode,
                    Attribute: [
                      {
                        [eachAttr.attributeCode]:
                          eachAttr.valueOnDd == undefined ||
                          eachAttr.valueOnDd == null
                            ? ""
                            : eachAttr.attributeCode === "LOPHR"
                            ? eachAttr.valueOnDd
                            : parseInt(eachAttr.valueOnDd),
                      },
                    ],
                  };
                  newForAuditLog.push(tempNewAuditObj);
                }
                if (eachAttr.attributeDatatype == "Date") {
                  const parsedDateOld = moment(eachAttr.value, "DD-MMM-YYYY");
                  const formattedDateOld = parsedDateOld.format("YYYY-MM-DD");
                  const tempOldObj = {
                    [eachAttr.attributeCode]:
                      eachAttr.value == null ||
                      eachAttr.value == undefined ||
                      formattedDateOld == "Invalid date"
                        ? ""
                        : formattedDateOld,
                  };
                  oldJsonObj.push(tempOldObj);

                  const parsedDateNew = moment(
                    eachAttr.valueOnDd,
                    "DD-MMM-YYYY"
                  );
                  const formattedDateNew = parsedDateNew.format("YYYY-MM-DD");
                  const tempNewObj = {
                    [eachAttr.attributeCode]:
                      eachAttr.valueOnDd == undefined ||
                      eachAttr.valueOnDd == null ||
                      formattedDateNew == "Invalid date"
                        ? ""
                        : formattedDateNew,
                  };
                  newJsonObj.push(tempNewObj);
                  const tempNewAuditObj = {
                    sectionId: eachAttr.attributeSectionId,
                    IsAttributeSet: false,
                    Code: eachAttr.attributeCode,
                    Attribute: [
                      {
                        [eachAttr.attributeCode]:
                          eachAttr.valueOnDd == undefined ||
                          eachAttr.valueOnDd == null ||
                          formattedDateNew == "Invalid date"
                            ? ""
                            : formattedDateNew,
                      },
                    ],
                  };
                  newForAuditLog.push(tempNewAuditObj);
                }
                if (eachAttr.attributeDatatype == "SearchableDropdownSingle") {
                  const tempOldObj = {
                    [eachAttr.attributeCode]:
                      eachAttr.value == null || eachAttr.value == undefined
                        ? ""
                        : parseInt(eachAttr.value),
                  };
                  oldJsonObj.push(tempOldObj);
                  const tempNewObj = {
                    [eachAttr.attributeCode]:
                      eachAttr.valueOnDd == undefined ||
                      eachAttr.valueOnDd == null
                        ? ""
                        : parseInt(eachAttr.valueOnDd.value),
                  };
                  newJsonObj.push(tempNewObj);

                  const tempNewAuditObj = {
                    sectionId: eachAttr.attributeSectionId,
                    IsAttributeSet: false,
                    Code: eachAttr.attributeCode,
                    Attribute: [
                      {
                        [eachAttr.attributeCode]:
                          eachAttr.valueOnDd == undefined ||
                          eachAttr.valueOnDd == null
                            ? ""
                            : parseInt(eachAttr.valueOnDd.label),
                      },
                    ],
                  };
                  newForAuditLog.push(tempNewAuditObj);
                }
              } else if (eachAttr.attributeType === "AttributeSet") {
              }
            });

          const oldJson = JSON.stringify(oldJsonObj);
          const newJson = JSON.stringify(newJsonObj);

          const newAuditJson = JSON.stringify(newForAuditLog);

          const finalObj = {
            WorkflowId: approvers[0].workFlowId,
            CountryId: attributesData["REQCNTRY"].value,
            PayrollLocationId: attributesData["REQPLOCN"]
              ? attributesData["REQPLOCN"]?.value
              : null,
            WorkflowTypeId: attributesData["REQTYPOUPD"].value,
            WorkflowSubTypeId: attributesData["REQRSNOUP"].value,
            EmployeeId: parseInt(attributesData["REQEMPLID"].value),
            EffectiveDate: attributesData["REQEFCTVDT"],
            Remarks: attributesData["REQDREMK"],
            Attachment: attributesData["REQSUPDOC"],
            BIS: attributesData["REQEMPLID"].bis,
            SBU: attributesData["REQEMPLID"].sbu,
            LEN: attributesData["REQEMPLID"].len,
            DV: attributesData["REQEMPLID"].dv,
            RequestOldJson: oldJson,
            RequestNewJson: newJson,
            AuditLogOldJson: oldAuditJsonState,
            AuditLogNewJson: newAuditJson,
            RpMgMapType: directReportee ? directReportee.value : null,
            RpMgMap:
              attributesData["REQTYPOUPD"].code == "Termination"
                ? repMgMapJson
                : null,
            JobRlMapType: directReporteeRel ? directReporteeRel.value : null,
            JobRlMap:
              attributesData["REQTYPOUPD"].code == "Termination"
                ? userRlMapJson
                : null,
            InitiatorId: parseInt(userDetails.Id),
            InitiatorRoleId: currentRoleId?.value,
            UserId: userDetails.Id,
            UserName: FullName,
            UserEmail: userDetails.EmailId,
            PosSync: posSync,
            isSave: isSave,
            CreatedBy: userDetails.Id,
            CreatedByRoleId: currentRoleId?.value,
            ProxyCreatedBy:
              proxyUserDetails == null ? null : userDetails.UserId,
            ReasonNewLabel: customName ? customName : null,
          };

          console.log(finalObj);

          // check if separeation is active for employee
          let checkSeparationIsActive = false;
          if (attributesData["REQTYPOUPD"].code == "Termination") {
            const sepRes = await APICall(checkIfSeparationIsActive, "POST", {
              EmployeeId: parseInt(attributesData["REQEMPLID"].value),
              roleid: currentRoleId?.value,
            });

            if (sepRes.status === 0 && sepRes.data === true) {
              checkSeparationIsActive = true;
            } else if (sepRes.status === 0 && sepRes.data === false) {
              checkSeparationIsActive = false;
            } else {
              hideLoader();
              checkSeparationIsActive = true;
              notify(1, "Check separation exists failed!");
              return null;
            }
          }

          if (checkSeparationIsActive) {
            hideLoader();
            notify(1, "Separation for selected Employee already exists!");
            return null;
          }

          const postRes = await APICall(
            postDataForProgression,
            "POST",
            finalObj
          );

          if (postRes.data !== null) {
            console.log('postRes', postRes);
            let newFiles = selectedFilesForFlat.filter((sf) => {
              return sf.DocId === 0;
            });

            console.log('newFiles', newFiles);

            try {
              const response = await APICall(
                documentUpload,
                "POST",
                {
                  ModuleName: "Progression",
                  files: newFiles,
                  TId: parseInt(attributesData["REQEMPLID"].value),
                  ModuleId: 10007,
                  UserId: proxyUserDetails?.value
                    ? proxyUserDetails?.value
                    : userDetails.UserId,
                  ProxyUserId:
                    proxyUserDetails === null
                      ? null
                      : proxyUserDetails?.value,
                  isProgressionFlat : true,
                  ProgId : postRes?.data?.progressionId,
                }
              );

              if (
                response.data === null ||
                response.data === undefined
              ) {
                notify(1, "Failed to upload documents.");
              } else {
                setSelectedFilesForFlat([
                  response.data.files,
                ]);
              }
            } catch (error) {
              throw new Error(
                "Error uploading documents: " + error.message
              );
            }

            //Check if entry in change log
            let changelogchangespresent = false;
            const changelogentrycheck = await APICall(
              checkIfEntryInAuditLog,
              "POST",
              {Id: postRes.data.progressionId}
            );
            if (changelogentrycheck.status === 0 && changelogentrycheck.data === true) {
              changelogchangespresent = true;
            } else if (changelogentrycheck.status === 0 && changelogentrycheck.data === false) {
              changelogchangespresent = false;
            } else {
              hideLoader();
              changelogchangespresent = true;
              notify(1, "Check entry in change log exists failed!");
            }
            if(!changelogchangespresent){
              const progRevoke = await APICall(
                anyProgressionRevoke,
                "POST",
                {Id: postRes.data.progressionId}
              );
              if (progRevoke.status===0){
                notify(1, 'No Changes were made so No Request is Created. Please do some changes in the Request.');
                // navigate("/home");
              } else {
                notify(1, 'No changes where made but something went wrong while removing the request. Please create a new Request.');
                // navigate("/home");
              }
              return;
            }
            //----
            //Entry in Action History
            const actHistObj = await APICall(entryUpdateInActionHistory, "POST", {
                Status: `${attributesData["REQTYPOUPD"]?.label} Initiated.`,
                Remarks: attributesData["REQDREMK"],
                ProgId: postRes.data.progressionId,
                CreatedBy: userDetails.Id,
              }
            );
            //---
            if (proSupportingDoc.length > 0) {
              const obj = {
                TId: parseInt(attributesData["REQEMPLID"].value),
                files: proSupportingDoc.map((doc) => {
                  return { ...doc, Id: postRes.data.progressionId };
                }),
              };

              const docRes = await APICall(
                uploadSupportingDocForProgression,
                "POST",
                obj
              );
            }
            notify(postRes.status, postRes.message);
            navigate("/home");
            
          } else {
            notify(1, "Insertion Fail.");
          }
        } else {
          console.error("NOT VALID");

          notify(1, "Please Fill required Data.");
        }
      } catch (error) {
        console.error(error);
      } finally {
        if (
          !(
            (attributesData["REQTYPOUPD"].code == "DataChange" &&
              attributesData["REQRSNOUP"].code == "DTACHG") ||
            attributesData["REQTYPOUPD"].code == "Suspension" ||
            attributesData["REQTYPOUPD"].code == "Compensation" ||
            attributesData["REQTYPOUPD"].code == "Demotion" || 
            attributesData["REQTYPOUPD"].code == "Onetimepay"
          )
        ) {
          setBtnDisable(false);
        }
        hideLoader();
      }
    }
  };

  const checkValidation = async () => {
    let formCount = 0;
    if (
      attributesData["REQDREMK"] == null ||
      attributesData["REQDREMK"] == "" ||
      !attributesData["REQDREMK"]
    ) {
      formCount += 1;
      setFormErrors((prev) => ({
        ...prev,
        ["REQDREMK"]: attributeValidation["REQDREMK"],
      }));
    }
    
    if(attributesData?.["REQTYPOUPD"]?.code == 'Blacklisted' && proSupportingDoc.length === 0){
      formCount += 1;
      setFormErrors((prev) => ({
        ...prev,
        ["REQSUPDOC"]: attributeValidation["REQSUPDOC"],
      }));
    }

    const parsedEffectiveDate = moment(attributesData["REQEFCTVDT"]);
    const parsedGroupJoiningDate = moment(grpJoiningDate);

    const effYear= parsedEffectiveDate.year();
    const effMonth= parsedEffectiveDate.month();
    const effDay = parsedEffectiveDate.date();

    const gjdYear= parsedGroupJoiningDate.year();
    const gjdMonth= parsedGroupJoiningDate.month();
    const gjdDay = parsedGroupJoiningDate.date();

    if (attributesData["REQEFCTVDT"] == null) {
      formCount += 1;
      setFormErrors((prev) => ({
        ...prev,
        ["REQEFCTVDT"]: attributeValidation["REQEFCTVDT"],
      }));
    } else if ( (effYear < gjdYear) 
      || (effYear == gjdYear && effMonth < gjdMonth) 
      || (effYear == gjdYear && effMonth == gjdMonth && effDay < gjdDay) ){
      formCount += 1;
      setFormErrors((prev) => ({
        ...prev,
        ["REQEFCTVDT"]: "Effective Date cannot be less than Group Joining Date which is: " + grpJoiningDate.toString(),
      }));
    } else if (
      attributesData["REQRSNOUP"].code == "Pro_ANN_PMS" &&
      attributesData["REQEFCTVDT"]
    ) {
      //validation
      //SET DATE AS MENTIONED
      const parsedDate = moment(attributesData["REQEFCTVDT"]);
      const mon = parsedDate.month() === 6;
      const day = parsedDate.date() === 1;

      if (!day || !mon) {
        formCount += 1;
        setFormErrors((prev) => ({
          ...prev,
          ["REQEFCTVDT"]: "Please select date of 01-July",
        }));
      }
    } else if (attributesData["REQRSNOUP"].code == "Transfer" && roleSystemName!="HRHead") {
      const currentDate = moment();
      const dayOfCurrentDate = currentDate.date();
      const monthOfCurrentDate = currentDate.month();
      const effectiveDate = moment(attributesData["REQEFCTVDT"]);
      const dayOfEffectiveDate = effectiveDate.date();
      const monthOfEffectiveDate = effectiveDate.month();

      if (monthOfEffectiveDate < monthOfCurrentDate) {
        formCount += 1;
        setFormErrors((prev) => ({
          ...prev,
          ["REQEFCTVDT"]: "Cannot select Past Month.",
        }));
      } else if (
        dayOfCurrentDate >= 23 &&
        dayOfEffectiveDate != 1 &&
        monthOfEffectiveDate != monthOfCurrentDate + 1
      ) {
        formCount += 1;
        setFormErrors((prev) => ({
          ...prev,
          ["REQEFCTVDT"]: "Effective Date should be 1st of next month.",
        }));
      } else if (dayOfCurrentDate < 23 && dayOfEffectiveDate != 1) {
        formCount += 1;
        setFormErrors((prev) => ({
          ...prev,
          ["REQEFCTVDT"]: "Effective Date should be 1st day of the month.",
        }));
      }
    }
    return formCount != 0 ? false : true;
  };

  const checkDynamicAccValidation = async () => {
    let formCount = 0;
    dynamicAccordians.map((eachAttr) => {
      if (eachAttr.attributeDatatype == "DropdownSingle") {
        if (
          (
            (attributesData?.["REQTYPOUPD"]?.code === 'Blacklisted' && eachAttr.attributeCode === 'OTR') ||
            (attributesData?.["REQTYPOUPD"]?.code !== 'Blacklisted' && eachAttr.attributeCode != "OTR")
          ) &&
          eachAttr.attributeCode != "WOA" &&
          eachAttr.attributeCode != "WOAR" &&
          eachAttr.attributeCode != "SC" &&
          eachAttr.attributeCode != 'SCN' &&
          eachAttr.attributeCode != 'SSCN' &&
          eachAttr.attributeCode != 'SSSCN' 
        ) {
          if (eachAttr.valueOnDd == null || eachAttr.valueOnDd == undefined) {
            formCount += 1;
            setDynamicformErrors((prev) => ({
              ...prev,
              [eachAttr.attributeCode]: "Mandatory",
            }));
          }
        }
      } else if (eachAttr.attributeDatatype == "Text") {
        if (
          eachAttr.attributeCode != "OTR" &&
          eachAttr.attributeCode != "WOA" &&
          eachAttr.attributeCode != "WOAR"
        ) {
          if (
            eachAttr.valueOnDd == null ||
            eachAttr.valueOnDd == undefined ||
            eachAttr.valueOnDd == ""
          ) {
            formCount += 1;
            setDynamicformErrors((prev) => ({
              ...prev,
              [eachAttr.attributeCode]: "Mandatory",
            }));
          }
        }
      } else if (eachAttr.attributeDatatype == "Number") {
        if (
          eachAttr.attributeCode != "OTR" &&
          eachAttr.attributeCode != "WOA" &&
          eachAttr.attributeCode != "WOAR"
        ) {
          if (eachAttr.valueOnDd == null || eachAttr.valueOnDd == undefined) {
            formCount += 1;
            setDynamicformErrors((prev) => ({
              ...prev,
              [eachAttr.attributeCode]: "Mandatory",
            }));
          }
        }
      } else if (eachAttr.attributeDatatype == "Date") {
        if (
          eachAttr.attributeCode != "TCD" &&
          eachAttr.attributeCode != "PEDE"
        ) {
          if (
            eachAttr.valueOnDd == null ||
            eachAttr.valueOnDd == undefined ||
            eachAttr.valueOnDd == ""
          ) {
            formCount += 1;
            setDynamicformErrors((prev) => ({
              ...prev,
              [eachAttr.attributeCode]: "Mandatory",
            }));
          }
          else if (attributesData["REQTYPOUPD"].code == "Termination" 
            && (eachAttr.attributeCode == "DOR" || eachAttr.attributeCode == "LWD")
          ){
            const parsedDate = moment(eachAttr.valueOnDd);
            const parsedGroupJoiningDate = moment(grpJoiningDate);
  
            const dateYear= parsedDate.year();
            const dateMonth= parsedDate.month();
            const dateDay = parsedDate.date();
  
            const gjdYear= parsedGroupJoiningDate.year();
            const gjdMonth= parsedGroupJoiningDate.month();
            const gjdDay = parsedGroupJoiningDate.date();
  
            if( eachAttr.attributeCode === "DOR"
            && (
              (dateYear < gjdYear) 
              || (dateYear == gjdYear && dateMonth < gjdMonth) 
              || (dateYear == gjdYear && dateMonth == gjdMonth && dateDay < gjdDay)
            )
            ) {
              formCount += 1;
              setDynamicformErrors((prev) => ({
                ...prev,
                [eachAttr?.attributeCode]: eachAttr?.attributeName + " cannot be less than Group Joining Date which is: " + grpJoiningDate.toString(),
              }));
            } else if (attributesData["REQTYPOUPD"].code == "Termination"
              && eachAttr.attributeCode === "LWD"
              && (
                (dateYear < gjdYear) 
                || (dateYear == gjdYear && dateMonth < gjdMonth) 
                || (dateYear == gjdYear && dateMonth == gjdMonth && dateDay < gjdDay)
              )
            ) {
              formCount += 1;
              setDynamicformErrors((prev) => ({
                ...prev,
                [eachAttr?.attributeCode]: eachAttr?.attributeName + " cannot be less than Group Joining Date which is: " + grpJoiningDate.toString(),
              }));
            } else if (attributesData["REQTYPOUPD"].code == "Termination"
              && eachAttr.attributeCode == "LWD") {
              const Dor = dynamicAccordians.find(
                (item) => item.attributeCode == "DOR"
              );
              const effectiveDt = moment(Dor.valueOnDd);
              const parsedDate = moment(eachAttr.valueOnDd);
    
              if (parsedDate.year() < effectiveDt.year()) {
                formCount += 1;
                setDynamicformErrors((prev) => ({
                  ...prev,
                  [eachAttr.attributeCode]:
                    "Please select date after Date of Resignation",
                }));
              } else if (parsedDate.year() == effectiveDt.year()) {
                if (
                  parsedDate.month() < effectiveDt.month() ||
                  (parsedDate.month() == effectiveDt.month() &&
                    parsedDate.date() < effectiveDt.date())
                ) {
                  formCount += 1;
                  setDynamicformErrors((prev) => ({
                    ...prev,
                    [eachAttr.attributeCode]:
                      "Please select date after Date of Resignation",
                  }));
                }
              }
            }
          }
        }
      } else if (eachAttr.attributeDatatype == "SearchableDropdownSingle") {
        if (
          eachAttr.valueOnDd == null ||
          eachAttr.valueOnDd == undefined ||
          eachAttr.valueOnDd == ""
        ) {
          formCount += 1;
          setDynamicformErrors((prev) => ({
            ...prev,
            [eachAttr.attributeCode]: "Mandatory",
          }));
        }
      } else if (eachAttr.attributeDatatype == "Document") {
        if (
          eachAttr.attributeCode === "PRGATTACHMENTS"
        ) {
          console.log('eachAttr?.valueOnDd', eachAttr?.valueOnDd, eachAttr);
          if (isEmpty(selectedFilesForFlat)) {
            formCount += 1;
            setDynamicformErrors((prev) => ({
              ...prev,
              [eachAttr.attributeCode]: "Mandatory",
            }));
          }
        }
      }
    });
    return formCount != 0 ? false : true;
  };

  console.log('dyanmaicFormErrors', dynamicformErrors);

  const gridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    // count: gridData.length,
    rowsPerPage: PageSize,
    page: page,
    serverSide: false,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",

    //standard | vertical | simple
    onChangeRowsPerPage: (num) => {
      //   setLimit(num);
      //   setNxtPgInfo("");
      //   setPrevPgInfo("");
      //   setIsPrevOrNext("");
    },
    // onSearchChange: (searchText) => {
    //   if (searchText !== null) {
    //     setSearchText(searchText);
    //   } else {
    //     setSearchText("");
    //   }
    // },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        // await setDashboardSortColumn(sortColumn);
        // await setDashboardSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        // await setDashboardSortColumn(sortColumn);
        // await setDashboardSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      // await setPage(page);
      // await setDashboardStart(page * dashboardPageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  function parseJsonViseVersa(json) {
    if (Array.isArray(json)) {
      const result = {};

      json.forEach((item) => {
        const sectionId = item.sectionId;
        const code = item.Code;
        const attribute = item.Main[0];

        if (!result[sectionId]) {
          result[sectionId] = {};
        }

        if (item.IsAttributeSet) {
          if (!result[sectionId][code]) {
            result[sectionId][code] = [];
          }
          result[sectionId][code].push(attribute);
        } else {
          result[sectionId][code] = attribute[code];
        }
      });

      return result;
    }
  }

  useEffect(() => {
    if (!isoldauditjsononce) {
      (async()=>{
      let oldForAuditLog = [];
      if (Object.keys(dynamicAccordians).length > 0) {
        for(const eachAttr of dynamicAccordians){
          if (eachAttr.attributeType === "Attribute") {
            if (eachAttr.attributeDatatype == "DropdownSingle") {
              const opObj = eachAttr.options?.find(
                (item) => item.value == eachAttr.value
              );
              const tempOldAuditObj = {
                sectionId: eachAttr.attributeSectionId,
                IsAttributeSet: false,
                Code: eachAttr.attributeCode,
                Attribute: [
                  {
                    [eachAttr.attributeCode]:
                      opObj == null || opObj == undefined ? "" : opObj.label,
                  },
                ],
                ...(attributesData["REQTYPOUPD"].code == "Demotion" && {
                  AttributeValue: [
                    {
                      [eachAttr.attributeCode]:
                        opObj == null || opObj == undefined ? "" : opObj.value,
                    },
                  ],
                  Main: [
                    {
                      [eachAttr.attributeCode]:
                        opObj == null || opObj == undefined
                          ? ""
                          : {
                              label: opObj.label,
                              value: opObj.value,
                            },
                    },
                  ],
                }),
              };
              oldForAuditLog.push(tempOldAuditObj);
            }
            if (eachAttr.attributeDatatype == "Text") {
              const tempOldAuditObj = {
                sectionId: eachAttr.attributeSectionId,
                IsAttributeSet: false,
                Code: eachAttr.attributeCode,
                Attribute: [
                  {
                    [eachAttr.attributeCode]:
                      eachAttr.value == null || eachAttr.value == undefined
                        ? ""
                        : eachAttr.value,
                  },
                ],
              };
              oldForAuditLog.push(tempOldAuditObj);
            }
            if (eachAttr.attributeDatatype == "Number") {
              const tempOldAuditObj = {
                sectionId: eachAttr.attributeSectionId,
                IsAttributeSet: false,
                Code: eachAttr.attributeCode,
                Attribute: [
                  {
                    [eachAttr.attributeCode]:
                      eachAttr.value == null || eachAttr.value == undefined
                        ? ""
                        : parseInt(eachAttr.value),
                  },
                ],
              };
              oldForAuditLog.push(tempOldAuditObj);
            }
            if (eachAttr.attributeDatatype == "Date") {
              const parsedDateOld = moment(eachAttr.value, "DD-MMM-YYYY");
              const formattedDateOld = parsedDateOld.format("YYYY-MM-DD");
              const tempOldAuditObj = {
                sectionId: eachAttr.attributeSectionId,
                IsAttributeSet: false,
                Code: eachAttr.attributeCode,
                Attribute: [
                  {
                    [eachAttr.attributeCode]:
                      eachAttr.value == null ||
                      eachAttr.value == undefined ||
                      formattedDateOld == "Invalid date"
                        ? ""
                        : formattedDateOld,
                  },
                ],
              };
              oldForAuditLog.push(tempOldAuditObj);
            }
            if (eachAttr.attributeDatatype == "SearchableDropdownSingle") {
              if (
                eachAttr.value != null &&
                eachAttr.value != undefined &&
                eachAttr.value != ""
              ) {
                const obj = await getSearchableDropdownAPI(
                  "",
                  eachAttr.attributeCode,
                  null,
                  eachAttr.value,
                  "attribute",
                  0
                );
                const tempOldAuditObj = {
                  sectionId: eachAttr.attributeSectionId,
                  IsAttributeSet: false,
                  Code: eachAttr.attributeCode,
                  Attribute: [
                    {
                      [eachAttr.attributeCode]:
                        obj == null || obj == undefined ? "" : obj.label,
                    },
                  ],
                };
                oldForAuditLog.push(tempOldAuditObj);
              }
            }
          }
        }
        setOldAuditJsonState(JSON.stringify(oldForAuditLog));
        setIsoldauditjsononce(true);
      }
      })();
    }
    if (!ogoptsetonce) {
      if (Object.keys(dynamicAccordians).length > 0) {
        dynamicAccordians.map(async (eachAttr) => {
          if (eachAttr.attributeType === "Attribute") {
            if (eachAttr.attributeDatatype == "DropdownSingle") {
              setOgOptForCascade((prev) => ({
                ...prev,
                [eachAttr.attributeCode]: eachAttr.options,
              }));
            }
          }
        });
        setogoptsetonce(true);
      }
    }
  }, [dynamicAccordians]);

  const GetNPEndDate = (date) => {
    if (dynamicAccordians.length > 0) {
      // let NPSD = dynamicAccordians.filter((dc)=>{
      //   return dc.attributeCode === "NPSD"
      // })[0].valueOnDd
      let NPSD = date;
      let NPIM = dynamicAccordians.filter((dc) => {
        return dc.attributeCode === "NPIM";
      })[0].valueOnDd;

      if (NPSD != undefined && NPSD != null && NPSD != "") {
        if (NPIM != undefined && NPIM != null && NPIM != "") {
          const startDateNP = moment(NPSD);
          let NPDays = Number(NPIM) * 30.44;
          let EndDateNP = startDateNP.add(NPDays, "d");
          GetShortDays("NPEDE", EndDateNP);
          return EndDateNP;
        } else {
          return "";
        }
      } else {
        return "";
      }
    }
  };
  const GetShortDays = (attribute, date) => {
    if (dynamicAccordians.length > 0) {
      let NPEDE = "";
      let LWD = "";
      // if(attribute === "NPEDE"){
      //    NPEDE = date
      // }
      // else{
      NPEDE = dynamicAccordians.filter((dc) => {
        return dc.attributeCode === "NPEDE";
      })[0].valueOnDd;
      //}
      //   if(attribute === "LWD"){
      //     LWD = date
      //  }
      //  else{
      LWD = dynamicAccordians.filter((dc) => {
        return dc.attributeCode === "LWD";
      })[0].valueOnDd;
      //}
      if (
        LWD != undefined &&
        LWD != null &&
        NPEDE != undefined &&
        NPEDE != null
      ) {
        let daysDiff;
        if (moment(LWD) < moment(NPEDE)) {
          daysDiff = moment(NPEDE).diff(moment(LWD), "days");
        } else {
          daysDiff = 0;
        }
        // let tempAcc = [...dynamicAccordians];
        //  let tempAtt = tempAcc.find(
        //                         (item) =>
        //                           item.attributeCode ==
        //                           "NPSDE"
        //                       );
        //                       tempAtt.valueOnDd = daysDiff;
        //                       setDynamicAccordian(tempAcc);
        return daysDiff > 0 ? daysDiff - 1 : daysDiff;
      } else {
        return "";
      }
    }
  };

  const closeConfirmationModal = async (confirmation) => {
    setShowConfirmationModal(false);
    handleSubmitButton(confirmation);
  };

  // USA - LR - ETF-LR -- GET LOP
  const [fetchLOP, setFetchLOP] = useState(false);

  useEffect(() => {
    if (
      attributesData["REQCNTRY"] &&
      attributesData["REQCNTRY"].code === "CODECNTRY7" &&
      attributesData["REQPLOCN"] &&
      attributesData["REQPLOCN"].code === "LR" &&
      attributesData["REQTYPOUPD"] &&
      attributesData["REQTYPOUPD"].code === "ETF_LR" &&
      attributesData["REQRSNOUP"] &&
      attributesData["REQRSNOUP"].code === "PRC_HRC" &&
      attributesData["REQEMPLID"] &&
      dynamicAccordians.length > 0 &&
      fetchLOP
    ) {
      const EC =
        dynamicAccordians.find((attri) => attri.attributeCode === "EC")
          ?.valueOnDd?.value || null;

      const JL =
        dynamicAccordians.find((attri) => attri.attributeCode === "JL")
          ?.valueOnDd?.value || null;

      if (EC && JL) {
        (async () => {
          const obj = {
            EmployeeId: attributesData["REQEMPLID"].value,
            EC,
            JL,
          };

          const lopRes = await APICall(getLOPForLRProgression, "POST", obj);
          if (lopRes.data !== null && lopRes.status == 0) {
            let tempAcc = [...dynamicAccordians];
            let tempAtt = tempAcc?.find(
              (item) => item.attributeCode == "LOPHR"
            );
            tempAtt.valueOnDd = lopRes.data.lop;
            setDynamicAccordian(tempAcc);

            // setDynamicAccordian((prevData) =>
            //   prevData.map((prev) =>
            //     prev.attributeCode === "LOPHR"
            //       ? { ...prev, ["valueOnDd"]: lopRes.data.lop }
            //       : prev
            //   )
            // );
          } else {
            let tempAcc = [...dynamicAccordians];
            let tempAtt = tempAcc?.find(
              (item) => item.attributeCode == "LOPHR"
            );
            tempAtt.valueOnDd = "0";
            setDynamicAccordian(tempAcc);
          }
        })();
        setFetchLOP(() => false);
      }
    }
  }, [dynamicAccordians, fetchLOP]);

  return (
    <>
      <div className="my-3 clearfix d-flex justify-content-end">
        <button
          onClick={() => navigate("/home")}
          className="btn btn-filter-submit ml-2">
          <i className="fa-solid fa-arrow-left mr-1"></i> Back
        </button>
        <button
          onClick={(event) => {
            if (
              attributesData["REQTYPOUPD"] &&
              (attributesData?.["REQTYPOUPD"]?.code == "Transfer" 
                || attributesData?.["REQTYPOUPD"]?.code == "Promotion")
            ) {
              setShowConfirmationModal(true);
            } else if (
              attributesData["REQTYPOUPD"] &&
              attributesData?.["REQTYPOUPD"]?.code == "DataChange" &&
              attributesData["REQSECT"] && 
              attributesData["REQSECT"].some(e => e.code === "JobOrganisationInformation")
            ) {
              setShowConfirmationModal(true);
            } else {
              handleSubmitButton();
            }
          }}
          disabled={
            attributesData["REQTYPOUPD"] == null ||
            attributesData["REQRSNOUP"] == null ||
            attributesData["REQEMPLID"] == null
              ? true
              : btnDisable
          }
          className="btn btn-filter-submit float-right ml-2 mr-2">
          <i className="fa-solid fa-check"></i> Submit
        </button>
        {/* <button
          onClick={(event) => handleSaveButton(event)}
          disabled={
            attributesData["REQTYPOUPD"] == null ||
            attributesData["REQRSNOUP"] == null ||
            attributesData["REQEMPLID"] == null
              ? true
              : btnDisable
          }
          className="btn btn-primary float-right ml-2">
          <i className="fa-solid fa-floppy-disk"></i> Save
        </button> */}
        <button
          onClick={test}
          className="test"
          id="test"
          style={{ display: "none" }}>
          Test
        </button>
      </div>
      <div className="col-lg-12">
        <Accordion
          elevation={0}
          className="mb-3"
          expanded={accordionReqDet}
          onChange={() => setAccordionReqDet((prev) => !prev)}>
          <AccordionSummary
            id="profile-accordion"
            style={{ background: "#3C5464" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}>
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <p style={{ fontSize: "16px" }}>Request Details</p>
              </div>
              <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
            </div>
          </AccordionSummary>

          <AccordionDetails className="page_heading">
            <div className="row">
              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Effective From Date
                    <sup>*</sup>
                  </label>
                  <DateForm
                    isDisabled={
                      attributesData["REQTYPOUPD"]
                        ? attributesData["REQTYPOUPD"].code == "Termination"
                          ? true
                          : disableEffectriveDate
                        : disableEffectriveDate
                    }
                    value={
                      attributesData["REQEFCTVDT"]
                        ? attributesData["REQEFCTVDT"]
                        : null
                    }
                    onChange={(date) => {
                      setAttributesData((prev) => ({
                        ...prev,
                        ["REQEFCTVDT"]: moment(date).format("DD-MMM-YYYY"),
                      }));
                      if (attributesData["REQTYPOUPD"]?.code !== "Transfer" 
                        && attributesData["REQTYPOUPD"]?.code !== "Promotion"){
                        setDisableEffectiveDate(true);
                      } else if (date && attributesData["REQTYPOUPD"] === null){
                        setDisableEffectiveDate(true);
                      } else {
                        setDisableEffectiveDate(false);
                      }
                    }}
                  />
                </div>
                {formErrors["REQEFCTVDT"] && (
                  <p style={{ color: "red" }}>{formErrors["REQEFCTVDT"]}</p>
                )}
              </div>

              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Country
                    <sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={countryDisable}
                    value={
                      attributesData["REQCNTRY"]
                        ? attributesData["REQCNTRY"]
                        : null
                    }
                    placeholder={"Country"}
                    options={
                      optionsObj["REQCNTRY"] ? optionsObj["REQCNTRY"] : []
                    }
                    onChange={async (e) => {
                      setAttributesData((prev) => ({
                        ...prev,
                        ["REQCNTRY"]: e,
                        ["REQPLOCN"]: null,
                        ["REQTYPOUPD"]: null,
                        ["REQRSNOUP"]: null,
                        ["REQSECT"]: null,
                        ["REQEMPLID"]: null,
                        ["REQEMPLNM"]: "",
                      }));
                      setOptionsObj((prev) => ({
                        ...prev,
                        ["REQPLOCN"]: [],
                        ["REQTYPOUPD"]: [],
                        ["REQRSNOUP"]: [],
                      }));
                      setFinalFormData({});
                      setapproverOpt({});
                      setApprovers([]);
                      setBtnHide(true);
                      setFormErrors({});
                      setDynamicAccordian({});
                      setDynamicformErrors({});
                      emptySectionData();
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
                {formErrors["REQCNTRY"] && (
                  <p style={{ color: "red" }}>{formErrors["REQCNTRY"]}</p>
                )}
              </div>

              <div
                className={"col-lg-3 col-sm-3 col-xs-4"}
                hidden={payrollDisable}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Payroll Location
                    {attributesData["REQCNTRY"] &&
                      attributesData["REQCNTRY"].code == "CODECNTRY7" && (
                        <sup>*</sup>
                      )}
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={payrollDisable}
                    value={
                      attributesData["REQPLOCN"]
                        ? attributesData["REQPLOCN"]
                        : null
                    }
                    placeholder={"Payroll Location"}
                    options={
                      optionsObj["REQPLOCN"] ? optionsObj["REQPLOCN"] : []
                    }
                    onChange={async (e) => {
                      setAttributesData((prev) => ({
                        ...prev,
                        ["REQPLOCN"]: e,
                        ["REQTYPOUPD"]: null,
                        ["REQRSNOUP"]: null,
                        ["REQSECT"]: null,
                        ["REQEMPLID"]: null,
                        ["REQEMPLNM"]: "",
                      }));
                      setFinalFormData({});
                      setapproverOpt({});
                      setApprovers([]);
                      setBtnHide(true);
                      setFormErrors({});
                      setDynamicAccordian({});
                      setDynamicformErrors({});
                      emptySectionData();
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
                {formErrors["REQPLOCN"] && (
                  <p style={{ color: "red" }}>{formErrors["REQPLOCN"]}</p>
                )}
              </div>

              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Event
                    <sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={
                      attributesData["REQCNTRY"] &&
                      attributesData["REQCNTRY"].code == "CODECNTRY7"
                        ? reqDetDisable
                        : false
                    }
                    value={
                      attributesData["REQTYPOUPD"]
                        ? attributesData["REQTYPOUPD"]
                        : null
                    }
                    placeholder={"Type Of Update"}
                    options={
                      optionsObj["REQTYPOUPD"] ? optionsObj["REQTYPOUPD"] : []
                    }
                    onChange={async (e) => {
                      emptySectionData();
                      if (attributesData["REQTYPOUPD"] != null) {
                        if (attributesData["REQTYPOUPD"].code == "Separation") {
                          setAttributesData((prev) => ({
                            ...prev,
                            ["REQTYPOUPD"]: e,
                            ["REQRSNOUP"]: null,
                            //["REQTABS"]: null,
                            ["REQSECT"]: null,
                            ["REQEMPLID"]: null,
                            ["REQEMPLNM"]: "",
                          }));
                        } else {
                          setAttributesData((prev) => ({
                            ...prev,
                            ["REQTYPOUPD"]: e,
                            ["REQRSNOUP"]: null,
                            //["REQTABS"]: null,
                            ["REQSECT"]: null,
                          }));
                        }
                      } else {
                        if (e.code == "Blacklisted"){
                          setAttributesData((prev) => ({
                            ...prev,
                            ["REQTYPOUPD"]: e,
                            ["REQRSNOUP"]: null,
                            //["REQTABS"]: null,
                            ["REQSECT"]: null,
                            ["REQEMPLID"]: null,
                            ["REQEMPLNM"]: "",
                          }));
                        } else {
                          setAttributesData((prev) => ({
                            ...prev,
                            ["REQTYPOUPD"]: e,
                            ["REQRSNOUP"]: null,
                            //["REQTABS"]: null,
                            ["REQSECT"]: null,
                          }));
                        }
                      }
                      setEmpIsDisable(false);
                      setFinalFormData({});
                      if (e) {
                        if (e.code !== "Transfer" && e.code !== "Promotion" && attributesData["REQEFCTVDT"]){
                          setDisableEffectiveDate(true);
                        } else {
                          setDisableEffectiveDate(false);
                        }
                        let optForReason = await getDdData("REQRSNOUP", e.code);
                        if (
                          attributesData["REQCNTRY"] &&
                          attributesData["REQPLOCN"] &&
                          attributesData["REQCNTRY"].code == "CODECNTRY7"
                        ) {
                          optForReason = optForReason.filter(
                            (item) =>
                              item.payrollLocationId ==
                              attributesData["REQPLOCN"].value
                          );
                        }
                        setOptionsObj((prev) => ({
                          ...prev,
                          ["REQRSNOUP"]: optForReason,
                        }));
                        setapproverOpt({});
                        setApprovers([]);
                        setBtnHide(true);
                        setFormErrors({});
                        setDynamicAccordian({});
                        setDynamicformErrors({});
                      } else {
                        setOptionsObj((prev) => ({
                          ...prev,
                          ["REQRSNOUP"]: [],
                        }));
                        setapproverOpt({});
                        setApprovers([]);
                        setBtnHide(true);
                        setFormErrors({});
                        setDynamicAccordian({});
                        setDynamicformErrors({});
                        setDisableEffectiveDate(false);
                      }
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
                {formErrors["REQTYPOUPD"] && (
                  <p style={{ color: "red" }}>{formErrors["REQTYPOUPD"]}</p>
                )}
              </div>
              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Event Reason
                    <sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={false}
                    value={
                      attributesData["REQRSNOUP"]
                        ? attributesData["REQRSNOUP"]
                        : null
                    }
                    placeholder={"Reason Of Update"}
                    options={
                      optionsObj["REQRSNOUP"] ? optionsObj["REQRSNOUP"] : []
                    }
                    onChange={async (e) => {
                      emptySectionData();
                      if (e) {
                        setEmpIsDisable(false);
                        setAttributesData((prev) => ({
                          ...prev,
                          ["REQRSNOUP"]: e,
                          //["REQTABS"]: null,
                          ["REQSECT"]: null,
                        }));
                      } else {
                        setAttributesData((prev) => ({
                          ...prev,
                          ["REQRSNOUP"]: e,
                          //["REQTABS"]: null,
                          ["REQSECT"]: null,
                          ["REQEMPLID"]: null,
                          ["REQEMPLNM"]: "",
                        }));
                      }
                      setApprovers([]);
                      setapproverOpt({});
                      setBtnHide(true);
                      setSkillCategoryHide(true);
                      setDynamicAccordian({});
                      setDynamicformErrors({});
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
                {formErrors["REQRSNOUP"] && (
                  <p style={{ color: "red" }}>{formErrors["REQRSNOUP"]}</p>
                )}
              </div>

              <div
                className={"col-lg-3 col-sm-3 col-xs-4"}
                hidden={sectionTabHide}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Tab
                    <sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={false}
                    value={
                      attributesData["REQTABS"]
                        ? attributesData["REQTABS"]
                        : null
                    }
                    placeholder={"Tab"}
                    options={optionsObj["REQTABS"] ? optionsObj["REQTABS"] : []}
                    onChange={async (e) => {
                      setAttributesData((prev) => ({
                        ...prev,
                        ["REQTABS"]: e,
                        ["REQSECT"]: null,
                      }));
                      setFinalFormData([]);
                      emptySectionData();
                      if (e) {
                        const optForSect = await getDdData("REQSECT", e.value);
                        // const optForEmpTab = optForSect?.filter(
                        //   (item) =>
                        //     item.code == "JobOrganisationInformation" ||
                        //     item.code == "JobRelationships"
                        // );

                        // setOptionsObj((prev) => ({
                        //   ...prev,
                        //   ["REQSECT"]:
                        //     e.code == "Employment" ? optForEmpTab : optForSect,
                        // }));

                        setOptionsObj((prev) => ({
                          ...prev,
                          ["REQSECT"]: optForSect,
                        }));
                      } else {
                        setOptionsObj((prev) => ({
                          ...prev,
                          ["REQSECT"]: [],
                        }));
                      }
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
                {/* {formErrors["REQRSNOUP"] && (
                    <p style={{ color: "red" }}>{formErrors["REQRSNOUP"]}</p>
                  )} */}
              </div>
              <div
                className={"col-lg-3 col-sm-3 col-xs-4"}
                hidden={sectionTabHide}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Section
                    <sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={false}
                    value={
                      attributesData["REQSECT"]
                        ? attributesData["REQSECT"]
                        : null
                    }
                    placeholder={"Section"}
                    options={optionsObj["REQSECT"] ? optionsObj["REQSECT"] : []}
                    onChange={(e) => {
                      setAttributesData((prev) => ({
                        ...prev,
                        ["REQSECT"]: e,
                      }));
                    }}
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
              </div>

              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Employee ID
                    <sup>*</sup>
                  </label>
                  {/* searchable item  */}
                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={empIsDisable}
                    value={
                      attributesData["REQEMPLID"]
                        ? attributesData["REQEMPLID"]
                        : null
                    }
                    placeholder={"Employee ID"}
                    async
                    options={(searchString, cb) =>
                      getSearchableDropdownAPI(
                        searchString,
                        "IOPPOS",
                        cb,
                        "",
                        "attribute",
                        0
                      )
                    }
                    onChange={(e) => {
                      if(attributesData["REQEFCTVDT"] == null || 
                      attributesData["REQEFCTVDT"] === undefined ||
                      attributesData["REQEFCTVDT"] === ''){
                        notify(1, 'Please select Effective From Date.');
                        return;
                      }
                      if (e) {
                        setAttributesData((prev) => ({
                          ...prev,
                          ["REQEMPLID"]: e,
                          ["REQEMPLNM"]: e.label,
                        }));
                        setTId(parseInt(e.value));
                        setApprovers([]);
                        setapproverOpt({});
                        setBtnHide(true);
                        setDynamicAccordian({});
                        setDynamicformErrors({});

                        //Group Joining Date set------------------------------
                        const gjdatestring = e.gjd
                          ? e.gjd.toString()
                          : null;
                        
                        setGrpJoiningDate(gjdatestring);
                        //////////////////////////////////////////////////////
                      } else {
                        setAttributesData((prev) => ({
                          ...prev,
                          ["REQEMPLID"]: e,
                          ["REQEMPLNM"]: "",
                        }));
                        setApprovers([]);
                        setapproverOpt({});
                        setBtnHide(true);
                        setDynamicAccordian({});
                        setDynamicformErrors({});
                        setGrpJoiningDate(null);
                      }
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
                {formErrors["REQEMPLID"] && (
                  <p style={{ color: "red" }}>{formErrors["REQEMPLID"]}</p>
                )}
              </div>
              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Employee Name
                    <sup>*</sup>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Employee Name"}
                    isDisabled={true}
                    textArea={false}
                    value={
                      attributesData["REQEMPLNM"]
                        ? attributesData["REQEMPLNM"]
                        : ""
                    }
                    onChange={(e) => {
                      setAttributesData((prev) => ({
                        ...prev,
                        ["REQEMPLNM"]: e.target.value,
                      }));
                    }}
                  />
                </div>
                {formErrors["REQEMPLNM"] && (
                  <p style={{ color: "red" }}>{formErrors["REQEMPLNM"]}</p>
                )}
              </div>
              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">Supporting Documents
                  {
                    attributesData?.["REQTYPOUPD"]?.code == 'Blacklisted' && <sup>*</sup>
                  }
                  </label>
                  <div className="box position-relative">
                    <input
                      id={"REQSUPDOC"}
                      className="form-control inputfile inputfile-6 multiple-inputfile"
                      multiple={true}
                      type="file"
                      ref={supportingDocRef}
                      onChange={(e) => {
                        handleSupportingDoc(e, "REQSUPDOC");
                      }}
                      accept={
                        ".docx,.doc,.pdf,.png,.jpg,.jpeg,.txt,.ppt,.pptx,.xlsx"
                      }
                      disabled={false}
                    />
                    <label
                      htmlFor={"REQSUPDOC"}
                      className="form-control"
                      style={{ width: 0, border: "none" }}>
                      <strong
                        style={{
                          padding: "6px 16px",
                          backgroundColor: "#3c5464",
                          borderRadius: "5px",
                        }}>
                        <i
                          className="fa fa-upload rotate90"
                          aria-hidden="true"></i>
                        {"  "}
                        Upload
                      </strong>{" "}
                    </label>
                  </div>
                  <div className="file-added-list">
                    <ul className="list-unstyle">
                      {proSupportingDoc &&
                        proSupportingDoc.length > 0 &&
                        proSupportingDoc.map(
                          (fileAttribute, attributeIndex) => (
                            <>
                              {/* {fileAttribute.AC ===
                                                          subAttribute.AC &&
                                                          (fileAttribute.Id ===
                                                            0 ||
                                                            fileAttribute.Id ==
                                                              currentGridId) && ( */}
                              <li className="list mt-1" key={attributeIndex}>
                                <div className="media">
                                  <div className="media-body text-truncate">
                                    <span className="view-more">
                                      {fileAttribute.Name}
                                    </span>
                                  </div>
                                  {/*Here code for view same div of trash should be used. ISDOCVIEW/ISDOCDOWN*/}
                                  {/* {
                                                                  // section?.ISDOCVIEW &&
                                                                  fileAttribute.DocId !==
                                                                    0 && (
                                                                    <div className="media-right ml-2">
                                                                      <i
                                                                        className="fa fa-eye"
                                                                        aria-hidden="true"
                                                                        onClick={() =>
                                                                          handleViewForFlat(
                                                                            fileAttribute,
                                                                            attributeIndex
                                                                          )
                                                                        }></i>
                                                                    </div>
                                                                  )
                                                                } */}

                                  {
                                    // section?.ISDOCDOWN &&
                                    // <div className="media-right ml-2">
                                    //   <i
                                    //     className="fa-solid fa-download"
                                    //     aria-hidden="true"
                                    //     onClick={() =>
                                    //       // handleDownloadFileFlat(
                                    //       //   fileAttribute,
                                    //       //   attributeIndex
                                    //       // )
                                    //       {}
                                    //     }></i>
                                    // </div>
                                  }
                                  {/* {section?.ISEDIT &&
                                                                  viewOnly ===
                                                                    false &&
                                                                  fileAttribute.DocId !==
                                                                    0 && ( */}
                                  <div className="media-right ml-2">
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                      onClick={() =>
                                        // handleDeleteFileForSet(
                                        //   fileAttribute,
                                        //   attributeIndex
                                        // )
                                        {
                                          setProSupportingDoc([]);
                                          supportingDocRef.current.value = null;
                                        }
                                      }></i>
                                  </div>
                                  {/* )} */}
                                </div>
                              </li>
                              {/* )} */}
                            </>
                          )
                        )}
                    </ul>
                  </div>
                  {formErrors["REQSUPDOC"] && (
                    <p style={{ color: "red" }}>{formErrors["REQSUPDOC"]}
                    </p>
                  )}
                </div>
              </div>
              <div className={"col-lg-6 col-sm-12 mobile-view top-m"}>
                <div className="mb-1">
                  <label className="col-form-label">Remarks</label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control"
                    placeholder={"Remarks, if any."}
                    isDisabled={false}
                    textArea={true}
                    value={
                      attributesData["REQDREMK"]
                        ? attributesData["REQDREMK"]
                        : ""
                    }
                    onChange={(e) => {
                      e.preventDefault();
                      setAttributesData((prev) => ({
                        ...prev,
                        ["REQDREMK"]: e.target.value,
                      }));
                    }}
                  />
                </div>
                {formErrors["REQDREMK"] && (
                  <p style={{ color: "red" }}>{formErrors["REQDREMK"]}</p>
                )}
              </div>
            </div>
            {/* <div className="row">
                
                
              </div> */}
          </AccordionDetails>
        </Accordion>

        {dynamicAccordians !== null && dynamicAccordians.length > 0 && (
          <Accordion
            elevation={0}
            className="mb-3"
            expanded={accordianEvent}
            onChange={() => setAccordianEvent((prev) => !prev)}>
            <AccordionSummary
              id="profile-accordion"
              style={{ background: "#3C5464" }}
              className="text-white acc_close"
              expandIcon={<ExpandMore />}>
              <div className="row d-flex align-items-center acc-heading">
                <div className="col-lg-10 col-md-10 col-sm-12">
                  <p style={{ fontSize: "16px" }}>
                    {attributesData["REQTYPOUPD"]
                      ? attributesData["REQTYPOUPD"].label
                      : "Event"}
                  </p>
                </div>
                <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
              </div>
            </AccordionSummary>

            <AccordionDetails className="page_heading">
              <div className="row">
                {dynamicAccordians !== null &&
                  dynamicAccordians.length > 0 &&
                  dynamicAccordians.map((attribute, key) => (
                    <React.Fragment key={key}>
                      {attribute.attributeType === "Attribute" ? (
                        attribute.attributeDatatype === "DropdownSingle" ? (
                          <div
                            className="col-lg-3 col-sm-3 col-xs-4"
                            hidden={
                              attributesData["REQCNTRY"]?.code ==
                                "CODECNTRY1" &&
                              attributesData["REQTYPOUPD"]?.code ==
                                "Promotion" &&
                              attribute.attributeCode == "SC"
                                ? skillcategoryHide
                                : false
                            }>
                            <div className="mb-1">
                              <label className="col-form-label">
                                {attribute.attributeName}
                              </label>
                              {attribute.attributeCode != "WOA" &&
                                attribute.attributeCode != "WOAR" && 
                                attribute.attributeCode != 'SCN' &&
                                attribute.attributeCode != 'SSCN' &&
                                attribute.attributeCode != 'SSSCN' && (
                                  <sup>*</sup>
                                )}
                              {/* {dynamicValidations[
                                    attribute.attributeCode
                                  ]?.ISM?.toLowerCase() === "true" && (
                                    <sup>*</sup>
                              )} */}
                              <SelectForm
                                isSearchable
                                isDisabled={
                                  //Blacklisted check
                                  attributesData?.["REQTYPOUPD"]?.code === 'Blacklisted' &&
                                    (attribute.attributeCode !== 'ST' 
                                    && attribute.attributeCode !== 'SR' 
                                    && attribute.attributeCode !== 'OTR')
                                  ? true : 
                                  //Blacklisted check
                                  (attribute.attributeCode == 'SCN' 
                                    || attribute.attributeCode == 'SSCN' 
                                    || attribute.attributeCode == 'SSSCN')
                                  ? scnDisabled 
                                  : false
                                }
                                value={
                                  attribute.valueOnDd
                                    ? attribute.valueOnDd
                                    : null
                                }
                                options={attribute.attributeCode === 'CEXD' ? attribute?.options?.filter((val) => val.code === "CODECOEXRSN1") : attribute.options}
                                placeholder={attribute.attributeName}
                                onChange={async (e) => {
                                  if (e) {
                                    //Enable Scn, SScn, SSScn
                                    if (attribute.attributeCode === "DPT"){
                                      if(e.value != attribute.value){
                                        setScnDisabled(false);
                                      } else {
                                        setScnDisabled(true);
                                      }
                                    }
                                    // getLOP when USA
                                    if (
                                      attribute.attributeCode === "EC" ||
                                      attribute.attributeCode === "JL"
                                    ) {
                                      setFetchLOP(true);
                                    }
                                    let tempAcc = [...dynamicAccordians];

                                    let tempAtt = tempAcc?.find(
                                      (item) =>
                                        item.attributeCode ==
                                        attribute.attributeCode
                                    );

                                    tempAtt.valueOnDd = e;

                                    //-----------Probation/Training Date Enable Disable----------------------//
                                    if (attribute.attributeCode == "PC") {
                                      if (e.code == "No002") {
                                        setProbDateDisable(true);
                                        let tempAtt6 = tempAcc?.find(
                                          (item) => item.attributeCode == "PEDE"
                                        );

                                        tempAtt6.valueOnDd = null;
                                      } else {
                                        setProbDateDisable(false);
                                      }
                                    } else if (
                                      attribute.attributeCode == "TC"
                                    ) {
                                      if (e.code == "P002") {
                                        setTrainDateDisable(true);
                                        let tempAtt6 = tempAcc?.find(
                                          (item) => item.attributeCode == "TCD"
                                        );

                                        tempAtt6.valueOnDd = null;
                                      } else {
                                        setTrainDateDisable(false);
                                      }
                                    }
                                    ////////////////////////////////////////////////////////

                                    //-----------Set Client Facing Title on Change of Desgination----------------------//
                                    if (attribute.attributeCode == "SD") {
                                      let tempAttCFT = tempAcc?.find(
                                        (item) => item.attributeCode == "PT"
                                      );
                                      if (
                                        tempAttCFT != null &&
                                        tempAttCFT != undefined
                                      ) {
                                        tempAttCFT.valueOnDd = e.label;
                                      }
                                    }
                                    ////////////////////////////////////////////////////////

                                    setDynamicAccordian(tempAcc);

                                    //////////////////////////CASCADE TEST/////////////////////////////

                                    if (
                                      attribute.attributeCascade !== null &&
                                      attribute.attributeCascade !== ""
                                    ) {
                                      const CC =
                                        attribute.attributeCascade.split(",");

                                      CC.forEach(async (childDropdown) => {
                                        if (
                                          childDropdown != "CUSBIS" &&
                                          childDropdown != "EC" &&
                                          ((attributesData["REQTYPOUPD"] &&
                                            attributesData["REQTYPOUPD"].code ==
                                              "Transfer" &&
                                            childDropdown != "JB") ||
                                            (attributesData["REQTYPOUPD"] &&
                                              attributesData["REQTYPOUPD"]
                                                .code != "Transfer"))
                                        ) {
                                          // hard code to get CUSBIS dropdown data
                                          let PId = "";

                                          const options = await getCommonDdData(
                                            childDropdown,
                                            e.value
                                          );
                                          let tempAcc1 = [...dynamicAccordians];

                                          let tempAtt1 = tempAcc1?.find(
                                            (item) =>
                                              item.attributeCode ==
                                              childDropdown
                                          );

                                          tempAtt1.valueOnDd = null;
                                          tempAtt1.options = options;

                                          setDynamicAccordian(tempAcc1);
                                        }
                                      });
                                    }
                                    //////////////////////////////////////////////////////////////////

                                    //-----------Set Wave Off Reason----------------------//
                                    if (attribute.attributeCode == "WOA") {
                                      if (e.code == "CODEWOAPPR2") {
                                        setWaveOffReasonDisable(true);
                                      } else {
                                        setWaveOffReasonDisable(false);
                                      }
                                    }
                                    ////////////////////////////////////////////////////////

                                    //----------Promotion Worker pe Skill Category Show---------//
                                    if (
                                      attributesData["REQCNTRY"]?.code ==
                                        "CODECNTRY1" &&
                                      attributesData["REQTYPOUPD"]?.code ==
                                        "Promotion" &&
                                      attribute.attributeCode == "EC" &&
                                      e.code == "Worker"
                                    ) {
                                      setSkillCategoryHide(false);
                                    } else if (
                                      attributesData["REQCNTRY"]?.code ==
                                        "CODECNTRY1" &&
                                      attributesData["REQTYPOUPD"]?.code ==
                                        "Promotion" &&
                                      attribute.attributeCode == "EC" &&
                                      e.code != "Worker"
                                    ) {
                                      setSkillCategoryHide(true);
                                    }
                                    ////////////////////////////////////////////////////////
                                  } else {
                                    notify(1, "Cannot Clear the Field");
                                  }
                                }}
                                isMulti={false}
                                noIndicator={false}
                                noSeparator={false}
                              />
                              {dynamicformErrors[attribute.attributeCode] && (
                                <p style={{ color: "red" }}>
                                  {dynamicformErrors[attribute.attributeCode]}
                                </p>
                              )}
                            </div>
                          </div>
                        ) : attribute.attributeDatatype === "Text" ||
                          attribute.attributeDatatype === "Number" ? (
                          <div className="col-lg-3 col-sm-3 col-xs-4">
                            <div className="mb-1">
                              <label className="col-form-label">
                                {attribute.attributeName}
                              </label>
                              {attribute.attributeCode != "OTR" &&
                                attribute.attributeCode != "WOA" &&
                                attribute.attributeCode != "WOAR" && (
                                  <sup>*</sup>
                                )}
                              <InputForm
                                className="form-control"
                                isDisabled={
                                  //Blacklisted check
                                  attributesData?.["REQTYPOUPD"]?.code === 'Blacklisted' &&
                                    (attribute.attributeCode !== 'ST' 
                                    && attribute.attributeCode !== 'SR' 
                                    && attribute.attributeCode !== 'OTR')
                                  ? true : 
                                  //Blacklisted check
                                  attribute.attributeCode === "WOAR"
                                    ? waveOffReasonDisable
                                    : attribute.attributeCode === "LOPHR"
                                    ? true
                                    : false
                                }
                                value={attribute.valueOnDd}
                                placeholder={attribute.attributeName}
                                textArea={false}
                                onChange={(e) => {
                                  let tempAcc = [...dynamicAccordians];
                                  let tempAtt = tempAcc?.find(
                                    (item) =>
                                      item.attributeCode ==
                                      attribute.attributeCode
                                  );
                                  tempAtt.valueOnDd = e.target.value;
                                  setDynamicAccordian(tempAcc);
                                }}
                              />
                              {dynamicformErrors[attribute.attributeCode] && (
                                <p style={{ color: "red" }}>
                                  {dynamicformErrors[attribute.attributeCode]}
                                </p>
                              )}
                            </div>
                          </div>
                        ) : attribute.attributeDatatype === "Date" ? (
                          <div className="col-lg-3 col-sm-3 col-xs-4">
                            <div className="mb-1">
                              <label className="col-form-label">
                                {attribute.attributeName}
                              </label>
                              <sup>*</sup>
                              <DateForm
                                isDisabled={
                                  //Blacklisted check
                                  attributesData?.["REQTYPOUPD"]?.code === 'Blacklisted' &&
                                    (attribute.attributeCode !== 'ST' 
                                    && attribute.attributeCode !== 'SR' 
                                    && attribute.attributeCode !== 'OTR')
                                  ? true : 
                                  //Blacklisted check
                                  attributesData?.["REQRSNOUP"]?.code ===
                                    "PROB" && attribute.attributeCode === "PEDE"
                                    ? probDateDisable
                                    : attributesData?.["REQRSNOUP"]?.code ===
                                        "TRNG" &&
                                      attribute.attributeCode === "TCD"
                                    ? trainDateDisable
                                    : false
                                }
                                value={
                                  attribute.valueOnDd
                                    ? attribute.valueOnDd
                                    : null
                                }
                                onChange={(date) => {
                                  let tempAcc = [...dynamicAccordians];
                                  let tempAtt = tempAcc?.find(
                                    (item) =>
                                      item.attributeCode ==
                                      attribute.attributeCode
                                  );
                                  tempAtt.valueOnDd =
                                    date != null
                                      ? moment(date).format("DD-MMM-YYYY")
                                      : null;
                                  if (
                                    attribute.attributeCode === "DOR" &&
                                    date != null
                                  ) {
                                    let tempAtt1 = tempAcc.find(
                                      (item) => item.attributeCode == "NPSD"
                                    );
                                    tempAtt1.valueOnDd =
                                      date != null
                                        ? moment(date).format("DD-MMM-YYYY")
                                        : null;
                                    let tempAtt2 = tempAcc.find(
                                      (item) => item.attributeCode == "NPEDE"
                                    );
                                    tempAtt2.valueOnDd =
                                      date != null
                                        ? moment(GetNPEndDate(date)).format(
                                            "DD-MMM-YYYY"
                                          )
                                        : null;
                                    let tempAtt3 = tempAcc.find(
                                      (item) => item.attributeCode == "NPSDE"
                                    );
                                    tempAtt3.valueOnDd = GetShortDays(
                                      attribute.attributeCode,
                                      date
                                    );
                                  }
                                  if (
                                    attribute.attributeCode === "LWD" &&
                                    date != null
                                  ) {
                                    const tempDate = moment(date);
                                    const newDate = tempDate.add(1, "days");
                                    if (
                                      attributesData["REQTYPOUPD"] &&
                                      attributesData["REQTYPOUPD"].code ==
                                        "Termination"
                                    ) {
                                      setAttributesData((prev) => ({
                                        ...prev,
                                        ["REQEFCTVDT"]:
                                          moment(newDate).format("DD-MMM-YYYY"),
                                      }));
                                    }
                                    let tempAtt4 = tempAcc.find(
                                      (item) => item.attributeCode == "NPSDE"
                                    );
                                    tempAtt4.valueOnDd = GetShortDays(
                                      attribute.attributeCode,
                                      date
                                    );
                                    let tempAtt5 = tempAcc.find(
                                      (item) => item.attributeCode == "ETD"
                                    );
                                    tempAtt5.valueOnDd =
                                      moment(newDate).format("DD-MMM-YYYY");
                                  }
                                  setDynamicAccordian(tempAcc);
                                }}
                              />
                              {dynamicformErrors[attribute.attributeCode] && (
                                <p style={{ color: "red" }}>
                                  {dynamicformErrors[attribute.attributeCode]}
                                </p>
                              )}
                            </div>
                          </div>
                        ) : attribute.attributeDatatype ===
                          "SearchableDropdownSingle" ? (
                          <div className="col-lg-3 col-sm-3 col-xs-4">
                            <div className="mb-1">
                              <label className="col-form-label">
                                {attribute.attributeName}
                              </label>
                              <sup>*</sup>
                              <SelectForm
                                isDisabled={false}
                                isClearable
                                isSearchable
                                async
                                options={(searchString, cb) =>
                                  getSearchableDropdownAPI(
                                    searchString,
                                    attribute.attributeCode,
                                    cb,
                                    "",
                                    "attribute",
                                    0
                                  )
                                }
                                placeholder={attribute.attributeName}
                                value={
                                  attribute.valueOnDd
                                    ? attribute.valueOnDd
                                    : null
                                }
                                onChange={async (e) => {
                                  if (e) {
                                    let tempAcc = [...dynamicAccordians];
                                    let tempAtt = tempAcc?.find(
                                      (item) =>
                                        item.attributeCode ==
                                        attribute.attributeCode
                                    );
                                    tempAtt.valueOnDd = e;

                                    //------------hardcode to set BIS,SBU,LEN,DV,FUNC,PLOCN,CC on change of POS-------------------//
                                    if (
                                      attributesData["REQTYPOUPD"]?.code ===
                                        "Transfer" &&
                                      attribute.attributeCode == "POS"
                                    ) {
                                      //api call to get data
                                      const ddata = await APICall(
                                        getDependentDataOfPos,
                                        "POST",
                                        {
                                          AC: attribute.attributeCode,
                                          PID: e.value,
                                        }
                                      );
                                      if (
                                        ddata != null &&
                                        ddata != undefined &&
                                        ddata.data &&
                                        ddata.data != null &&
                                        ddata?.data?.length > 0
                                      ) {
                                        // ddata?.data?.map((item) => {
                                        for (const item of ddata?.data) {
                                          let opt = {data:[]};
                                          let selectedItem = null;
                                          switch (item?.key) {
                                            case "PMBUSN":
                                              let tempAttbis = tempAcc?.find(
                                                (att) =>
                                                  att.attributeCode == "BIS"
                                              );
                                              const selectedbis =
                                                ogOptionsForCascade?.[
                                                  "BIS"
                                                ]?.find(
                                                  (val) =>
                                                    val.value == item.value
                                                );
                                              tempAttbis.valueOnDd = selectedbis
                                                ? selectedbis
                                                : null;
                                              break;
                                            case "PMSBU":
                                              let tempAttsbu = tempAcc?.find(
                                                (att) =>
                                                  att.attributeCode == "SBU"
                                              );
                                              opt = await APICall(getDropdowns, "POST", {
                                                AC: "SBU",
                                                PID: '',
                                                TId: e.value,
                                                IsPos: true,
                                                UserId: proxyUserDetails?.EmployeeId
                                                  ? proxyUserDetails?.EmployeeId
                                                  : userDetails?.Id,
                                                RoleId: currentRoleId?.value,
                                                RoleTypeId: 3,
                                                IsNewHire: true,
                                              });
                                              console.log('opt:::',opt);
                                              const selectedsbu =
                                                // ogOptionsForCascade?.[
                                                //   "SBU"
                                                // ].find(
                                                //   (val) =>
                                                //     val.value == item.value
                                                // );
                                                opt?.data?.find(
                                                    (val) =>
                                                      val.value == item.value
                                                  );
                                              tempAttsbu.valueOnDd = selectedsbu
                                                ? selectedsbu
                                                : null;
                                              tempAttsbu.options = opt?.data;
                                              break;
                                            case "PMLEGEN":
                                              let tempAttlen = tempAcc?.find(
                                                (att) =>
                                                  att.attributeCode == "LEN"
                                              );
                                              opt = await APICall(getDropdowns, "POST", {
                                                AC: "LEN",
                                                PID: '',
                                                TId: e.value,
                                                IsPos: true,
                                                UserId: proxyUserDetails?.EmployeeId
                                                  ? proxyUserDetails?.EmployeeId
                                                  : userDetails?.Id,
                                                RoleId: currentRoleId?.value,
                                                RoleTypeId: 3,
                                                IsNewHire: true,
                                              });
                                              const selectedlen =
                                                // ogOptionsForCascade?.[
                                                //   "LEN"
                                                // ].find(
                                                //   (val) =>
                                                //     val.value == item.value
                                                // );
                                                opt?.data?.find(
                                                  (val) =>
                                                    val.value == item.value
                                                );
                                              tempAttlen.valueOnDd = selectedlen
                                                ? selectedlen
                                                : null;
                                              tempAttlen.options = opt?.data;
                                              break;
                                            case "PMDIVN":
                                              let tempAttdv = tempAcc?.find(
                                                (att) =>
                                                  att.attributeCode == "DV"
                                              );
                                              opt = await APICall(getDropdowns, "POST", {
                                                AC: "DV",
                                                PID: '',
                                                TId: e.value,
                                                IsPos: true,
                                                UserId: proxyUserDetails?.EmployeeId
                                                  ? proxyUserDetails?.EmployeeId
                                                  : userDetails?.Id,
                                                RoleId: currentRoleId?.value,
                                                RoleTypeId: 3,
                                                IsNewHire: true,
                                              });
                                              selectedItem =
                                                // ogOptionsForCascade?.[
                                                //   "DV"
                                                // ].find(
                                                //   (val) =>
                                                //     val.value == item.value
                                                // );
                                                opt?.data?.find(
                                                  (val) =>
                                                    val.value == item.value
                                                );
                                              tempAttdv.valueOnDd = selectedItem
                                                ? selectedItem
                                                : null;
                                              tempAttdv.options = opt?.data;
                                              break;
                                            case "PMFUNC":
                                              let tempAttfunc = tempAcc?.find(
                                                (att) =>
                                                  att.attributeCode == "FUNC"
                                              );
                                              opt = await APICall(getDropdowns, "POST", {
                                                AC: "FUNC",
                                                PID: '',
                                                TId: e.value,
                                                IsPos: true,
                                                UserId: proxyUserDetails?.EmployeeId
                                                  ? proxyUserDetails?.EmployeeId
                                                  : userDetails?.Id,
                                                RoleId: currentRoleId?.value,
                                                RoleTypeId: 3,
                                                IsNewHire: true,
                                              });
                                              selectedItem =
                                                // ogOptionsForCascade?.[
                                                //   "FUNC"
                                                // ].find(
                                                //   (val) =>
                                                //     val.value == item.value
                                                // );
                                                opt?.data?.find(
                                                  (val) =>
                                                    val.value == item.value
                                                );
                                              tempAttfunc.valueOnDd =
                                                selectedItem
                                                  ? selectedItem
                                                  : null;
                                              tempAttfunc.options = opt?.data;
                                              break;
                                            case "PMDEPT":
                                              let tempAttdpt = tempAcc?.find(
                                                (att) =>
                                                  att.attributeCode == "DPT"
                                              );
                                              opt = await APICall(getDropdowns, "POST", {
                                                AC: "DPT",
                                                PID: '',
                                                TId: e.value,
                                                IsPos: true,
                                                UserId: proxyUserDetails?.EmployeeId
                                                  ? proxyUserDetails?.EmployeeId
                                                  : userDetails?.Id,
                                                RoleId: currentRoleId?.value,
                                                RoleTypeId: 3,
                                                IsNewHire: true,
                                              });
                                              selectedItem =
                                                // ogOptionsForCascade?.[
                                                //   "DPT"
                                                // ].find(
                                                //   (val) =>
                                                //     val.value == item.value
                                                // );
                                                opt?.data.find(
                                                  (val) =>
                                                    val.value == item.value
                                                );
                                              tempAttdpt.valueOnDd =
                                                selectedItem
                                                  ? selectedItem
                                                  : null;
                                              tempAttdpt.options = opt?.data;
                                              break;
                                            case "PMLOCN":
                                              let tempAttploc = tempAcc?.find(
                                                (att) =>
                                                  att.attributeCode == "PLOC"
                                              );
                                              opt = await APICall(getDropdowns, "POST", {
                                                AC: "PLOC",
                                                PID: '',
                                                TId: e.value,
                                                IsPos: true,
                                                UserId: proxyUserDetails?.EmployeeId
                                                  ? proxyUserDetails?.EmployeeId
                                                  : userDetails?.Id,
                                                RoleId: currentRoleId?.value,
                                                RoleTypeId: 3,
                                                IsNewHire: true,
                                              });
                                              selectedItem =
                                                // ogOptionsForCascade?.[
                                                //   "PLOC"
                                                // ].find(
                                                //   (val) =>
                                                //     val.value == item.value
                                                // );
                                                opt?.data?.find(
                                                  (val) =>
                                                    val.value == item.value
                                                );
                                              tempAttploc.valueOnDd =
                                                selectedItem
                                                  ? selectedItem
                                                  : null;
                                              tempAttploc.options = opt?.data;
                                              break;
                                            case "PMCOCEN":
                                              let tempAttcc = tempAcc?.find(
                                                (att) =>
                                                  att.attributeCode == "CC"
                                              );
                                              opt = await APICall(getDropdowns, "POST", {
                                                AC: "CC",
                                                PID: '',
                                                TId: e.value,
                                                IsPos: true,
                                                UserId: proxyUserDetails?.EmployeeId
                                                  ? proxyUserDetails?.EmployeeId
                                                  : userDetails?.Id,
                                                RoleId: currentRoleId?.value,
                                                RoleTypeId: 3,
                                                IsNewHire: true,
                                              });
                                              selectedItem =
                                                // ogOptionsForCascade?.[
                                                //   "CC"
                                                // ].find(
                                                //   (val) =>
                                                //     val.value == item.value
                                                // );
                                                opt?.data?.find(
                                                  (val) =>
                                                    val.value == item.value
                                                );
                                              tempAttcc.valueOnDd = selectedItem
                                                ? selectedItem
                                                : null;
                                              tempAttcc.options = opt?.data;
                                              break;
                                          }
                                        }
                                        // );
                                      }
                                    }
                                    //-------------------------------------------------------------------------------------------

                                    setDynamicAccordian(tempAcc);
                                  } else {
                                    notify(1, "Cannot Clear the Field");
                                  }
                                }}
                                isMulti={false}
                                noIndicator={false}
                                noSeparator={false}
                              />
                              {dynamicformErrors[attribute.attributeCode] && (
                                <p style={{ color: "red" }}>
                                  {dynamicformErrors[attribute.attributeCode]}
                                </p>
                              )}
                            </div>
                          </div>
                        ) : attribute.attributeDatatype === "Document" ? (
                          <>
                            <div
                              className={"col-lg-3 col-sm-3 col-xs-4"}
                            >
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {attribute.attributeName}
                                    <sup>*</sup>
                                </label>
  
                                <div className="box position-relative">
                                  <input
                                    id={`files-${key}`}
                                    className="form-control inputfile inputfile-6 multiple-inputfile"
                                    data-multiple-caption="{count} files selected"
                                    multiple={true}
                                    ref={FlatDocRef}
                                    accept={JSON.parse(attribute.attributeValidations).FX}
                                    type="file"
                                    onChange={(e) => {
                                      handleFileChangeForFlat(e, attribute);
                                    }}
                                    value={""}
                                  />
                                  <label
                                    htmlFor={`files-${key}`}
                                    className="form-control"
                                    style={{ width: 0, border: "none" }}
                                  >
                                    <strong
                                      style={{
                                        padding: "6px 16px",
                                        backgroundColor: "#3c5464",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      <i
                                        className="fa fa-upload rotate90"
                                        aria-hidden="true"
                                      ></i>
                                      {"  "}
                                      Upload
                                    </strong>{" "}
                                  </label>
                                </div>
                                {formErrors[attribute.attributeCode] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[attribute.attributeCode]}
                                  </p>
                                )}
                                <div className="file-added-list">
                                  <ul className="list-unstyle">
                                    {selectedFilesForFlat &&
                                      selectedFilesForFlat.length > 0 &&
                                      selectedFilesForFlat.map(
                                        (fileAttribute, attributeIndex) => (
                                          <>
                                            {fileAttribute?.AC ===
                                              attribute.attributeCode && (
                                              <li
                                                className="list mt-1"
                                                key={attributeIndex}
                                              >
                                                <div className="media">
                                                  <div className="media-body text-truncate">
                                                    <span className="view-more">
                                                      {fileAttribute.Name}
                                                    </span>
                                                  </div>
                                                  {/*Here code for view same div of trash should be used. ISDOCVIEW/ISDOCDOWN*/}
                                                  {/* {
                                                    // section?.ISDOCVIEW &&
                                                    fileAttribute.DocId !==
                                                      0 && (
                                                      <div className="media-right ml-2">
                                                        <i
                                                          className="fa fa-eye"
                                                          aria-hidden="true"
                                                          onClick={() =>
                                                            handleViewForFlat(
                                                              fileAttribute,
                                                              attributeIndex
                                                            )
                                                          }></i>
                                                      </div>
                                                    )
                                                  } */}
                                                  {
                                                    //  section?.ISDOCDOWN &&
                                                    <div className="media-right ml-2">
                                                      <i
                                                        className="fa-solid fa-download"
                                                        aria-hidden="true"
                                                        onClick={() =>
                                                          handleDownloadFileFlat(
                                                            fileAttribute,
                                                            attributeIndex
                                                          )
                                                        }
                                                      ></i>
                                                    </div>
                                                  }
                                                  <div className="media-right ml-2">
                                                        <i
                                                          className="fa fa-trash"
                                                          aria-hidden="true"
                                                          onClick={() =>
                                                            handleDeleteFileForFlat(
                                                              fileAttribute,
                                                              attributeIndex
                                                            )
                                                          }
                                                        ></i>
                                                    </div>
                                                </div>
                                              </li>
                                            )}
                                          </>
                                        )
                                      )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        ) //Attribute Set
                      ) :  (
                        <></>
                      )}
                    </React.Fragment>
                  ))}
              </div>

              {attributesData["REQTYPOUPD"] &&
                attributesData["REQTYPOUPD"].code == "Termination" && (
                  <>
                    {/* Row for Hardcoded Values of Manager Transfer */}
                    <div className="row">
                      {dynamicAccordians !== null &&
                        dynamicAccordians.length > 0 && (
                          <>
                            <div
                              className="col-lg-3 col-sm-3 col-xs-4"
                              hidden={reportees.length > 0 ? false : true}>
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {"TRANSFER DIRECT REPORTEE"}
                                </label>
                                <sup>*</sup>
                                <SelectForm
                                  isSearchable
                                  isClearable
                                  isDisabled={false}
                                  value={directReportee ? directReportee : null}
                                  options={typeOptions}
                                  placeholder={"Select a Value"}
                                  onChange={async (e) => {
                                    setDirectReportee(e);
                                    if (e) {
                                      if (e.code == "TATUP") {
                                        if (
                                          attributesData["REQEMPLID"].mg != null
                                        ) {
                                          const mgObj =
                                            await getSearchableDropdownAPI(
                                              "",
                                              "MG",
                                              null,
                                              attributesData["REQEMPLID"].mg,
                                              "attribute",
                                              0
                                            );
                                          setManagerForReportee(mgObj);
                                        }
                                        setManagerDisable(true); //Set Upper position manager
                                      } else if (e.code == "ITTOM") {
                                        //managerForReportee
                                        setManagerForReportee(null);
                                        setManagerDisable(true);
                                      } else {
                                        setManagerForReportee(null);
                                        setManagerDisable(false);
                                      }
                                    } else {
                                      setManagerForReportee(null);
                                      setManagerDisable(false);
                                    }
                                  }}
                                  isMulti={false}
                                  noIndicator={false}
                                  noSeparator={false}
                                />
                                {/* {dynamicformErrors[attribute.attributeCode] && (
                              <p style={{ color: "red" }}>
                                {dynamicformErrors[attribute.attributeCode]}
                              </p>
                            )} */}
                              </div>
                            </div>
                            <div
                              className="col-lg-3 col-sm-3 col-xs-4"
                              hidden={reportees.length > 0 ? false : true}>
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {"Manager Name"}
                                </label>
                                <sup>*</sup>
                                <SelectForm
                                  isClearable
                                  isSearchable
                                  isDisabled={managerDisable}
                                  async
                                  options={(searchString, cb) =>
                                    getSearchableDropdownAPI(
                                      searchString,
                                      "MG",
                                      cb,
                                      "",
                                      "attribute",
                                      0
                                    )
                                  }
                                  placeholder={"Select a Manager"}
                                  value={managerForReportee}
                                  onChange={(e) => {
                                    setManagerForReportee(e);
                                  }}
                                  isMulti={false}
                                  noIndicator={false}
                                  noSeparator={false}
                                />
                              </div>
                            </div>
                          </>
                        )}
                    </div>

                    {/* Attributes for Individual Setting of Managers */}
                    {directReportee != null &&
                      reporteeManagerMap != null &&
                      reporteeManagerMap.length > 0 && (
                        <div
                          className="row"
                          hidden={
                            directReportee.code == "ITTOM" ? false : true
                          }>
                          {reporteeManagerMap.map((eachRep) => (
                            <>
                              <div className="col-lg-3 col-sm-3 col-xs-4">
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    {"Reportee Name"}
                                  </label>
                                  <sup>*</sup>
                                  <SelectForm
                                    isClearable
                                    isSearchable
                                    isDisabled={true}
                                    options={[]}
                                    placeholder={"Select a Manager"}
                                    value={eachRep}
                                    onChange={(e) => {}}
                                    isMulti={false}
                                    noIndicator={false}
                                    noSeparator={false}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-sm-3 col-xs-4">
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    {"Manager Name"}
                                  </label>
                                  <sup>*</sup>
                                  <SelectForm
                                    isClearable
                                    isSearchable
                                    isDisabled={false}
                                    async
                                    options={(searchString, cb) =>
                                      getSearchableDropdownAPI(
                                        searchString,
                                        "MG",
                                        cb,
                                        "",
                                        "attribute",
                                        0
                                      )
                                    }
                                    placeholder={"Select a Manager"}
                                    value={eachRep.mg ? eachRep.mg : null}
                                    onChange={(e) => {
                                      let tempAcc = [...reporteeManagerMap];
                                      let tempAtt = tempAcc.find(
                                        (item) => item.value == eachRep.value
                                      );
                                      tempAtt.mg = e;
                                      setReporteeManagerMap(tempAcc);
                                    }}
                                    isMulti={false}
                                    noIndicator={false}
                                    noSeparator={false}
                                  />
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                      )}

                    {/* Row for Hardcoded Values of Job Relation Transfer */}
                    <div className="row">
                      {dynamicAccordians !== null &&
                        dynamicAccordians.length > 0 && (
                          <>
                            <div
                              className="col-lg-3 col-sm-3 col-xs-4"
                              hidden={relReportees.length > 0 ? false : true}>
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {"Transfer Job Relationships"}
                                </label>
                                <sup>*</sup>
                                <SelectForm
                                  isSearchable
                                  isClearable
                                  isDisabled={false}
                                  value={
                                    directReporteeRel ? directReporteeRel : null
                                  }
                                  options={typeOptionsRel}
                                  placeholder={"Select a Value"}
                                  onChange={async (e) => {
                                    setDirectReporteeRel(e);
                                    if (e) {
                                      if (e.code == "RLTATUP") {
                                        if (
                                          attributesData["REQEMPLID"].mg != null
                                        ) {
                                          // code for get usermaster id using employeeid
                                          const umid = await APICall(
                                            getUsermasterIdBasedOnEid,
                                            "POST",
                                            {
                                              userid:
                                                attributesData["REQEMPLID"].mg,
                                            }
                                          );
                                          const mgObj =
                                            await getSearchableDropdownAPI(
                                              "",
                                              "RELNME",
                                              null,
                                              umid.data.relationId,
                                              "attribute",
                                              0
                                            );
                                          setRelationForReportee(mgObj);
                                        }
                                        setRelationDisable(true); //Set Upper position manager
                                      } else if (e.code == "RLITTOM") {
                                        //relationForReportee
                                        setRelationForReportee(null);
                                        setRelationDisable(true);
                                      } else if (e.code == "RLTAR") {
                                        //noRelationForReportee
                                        setRelationForReportee(null);
                                        setRelationDisable(true);
                                      } else {
                                        setRelationForReportee(null);
                                        setRelationDisable(false);
                                      }
                                    } else {
                                      setRelationForReportee(null);
                                      setRelationDisable(false);
                                    }
                                  }}
                                  isMulti={false}
                                  noIndicator={false}
                                  noSeparator={false}
                                />
                              </div>
                            </div>
                            <div
                              className="col-lg-3 col-sm-3 col-xs-4"
                              hidden={relReportees.length > 0 ? false : true}>
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {"Search Users"}
                                </label>
                                <sup>*</sup>
                                <SelectForm
                                  isClearable
                                  isSearchable
                                  isDisabled={relationDisable}
                                  async
                                  options={(searchString, cb) =>
                                    getSearchableDropdownAPI(
                                      searchString,
                                      "RELNME", //Change this with "RELNME" once kiran sir's update is done
                                      cb,
                                      "",
                                      "attribute",
                                      0
                                    )
                                  }
                                  placeholder={"Select a Manager"}
                                  value={relationForReportee}
                                  onChange={(e) => {
                                    setRelationForReportee(e);
                                  }}
                                  isMulti={false}
                                  noIndicator={false}
                                  noSeparator={false}
                                />
                              </div>
                            </div>
                          </>
                        )}
                    </div>
                    {/* Attributes for Individual Job Relationship */}
                    {directReporteeRel != null &&
                      !mappingHide &&
                      userRelationMap != null &&
                      userRelationMap.length > 0 &&
                      userRelationMap.map((eachRep) => (
                        <div className="row">
                          {/* reportee name  */}
                          <div className="col-lg-3 col-sm-3 col-xs-4">
                            <div className="mb-1">
                              <label className="col-form-label">
                                {"Reportee Name"}
                              </label>
                              <sup>*</sup>
                              <SelectForm
                                isClearable
                                isSearchable
                                isDisabled={true}
                                options={[]}
                                placeholder={"Select a Reportee"}
                                value={eachRep.repName}
                                onChange={(e) => {}}
                                isMulti={false}
                                noIndicator={false}
                                noSeparator={false}
                              />
                            </div>
                          </div>
                          {/* relationship type */}
                          <div className="col-lg-3 col-sm-3 col-xs-4">
                            <div className="mb-1">
                              <label className="col-form-label">
                                {"Relationship Type"}
                              </label>
                              <sup>*</sup>
                              <SelectForm
                                isClearable
                                isSearchable
                                isDisabled={true}
                                options={optForRoles}
                                placeholder={"Select Relationship Type"}
                                value={eachRep.repType}
                                onChange={(e) => {}}
                                isMulti={false}
                                noIndicator={false}
                                noSeparator={false}
                              />
                            </div>
                          </div>
                          {/* user name */}
                          <div className="col-lg-3 col-sm-3 col-xs-4">
                            <div className="mb-1">
                              <label className="col-form-label">
                                {"Search User"}
                              </label>
                              <sup>*</sup>
                              <SelectForm
                                isClearable
                                isSearchable
                                isDisabled={false}
                                async
                                options={(searchString, cb) =>
                                  getSearchableDropdownAPI(
                                    searchString,
                                    "RELNME", //Change this with "RELNME" once kiran sir's update is done
                                    cb,
                                    "",
                                    "attribute",
                                    0
                                  )
                                }
                                placeholder={"Select a User"}
                                value={eachRep.repRel ? eachRep.repRel : null}
                                onChange={(e) => {
                                  let tempAcc = [...userRelationMap];
                                  let tempAtt = tempAcc.find(
                                    (item) => item.id == eachRep.id
                                  );
                                  tempAtt.repRel = e;
                                  setUserRelationMap(tempAcc);
                                }}
                                isMulti={false}
                                noIndicator={false}
                                noSeparator={false}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                  </>
                )}
            </AccordionDetails>
          </Accordion>
        )}

        {
          //For Section and tab render
          ((finalformData != null &&
            finalformData.length > 0 &&
            attributesData["REQEMPLID"] != null &&
            sectionValuesArray.length > 0) ||
            (finalformData != null &&
              finalformData.length > 0 &&
              attributesData["REQEMPLID"] != null &&
              sectionValuesArray.length > 0 &&
              submitFunc) ||
            (finalformData != null &&
              finalformData.length > 0 &&
              attributesData["REQEMPLID"] != null &&
              sectionValuesArray.length > 0 &&
              submitBtnForSet)) &&
            finalformData.map((eachSection, index) => (
              <React.Fragment key={`${index}-${eachSection.SN}`}>
                <SectionComponent
                  key={`${index}-${eachSection.SN}_Sec`}
                  formData={finalformData}
                  setFormData={setFinalFormData}
                  section={eachSection}
                  sectionIndex={index}
                  TId={TId}
                  setTId={setTId}
                  sectionValuesArray={sectionValuesArray}
                  SetSectionValuesArray={setSectionValuesArray}
                  Globalv={globalValues}
                  COU={attributesData["REQEMPLID"].cou}
                  submitFunc={submitFunc}
                  setGetData={setGetData}
                  payrolldd={attributesData["REQPLOCN"]}
                  countrydd={attributesData["REQCNTRY"]}
                  enableForTextileWorkerWfl = {enableForTextileWorkerWfl}
                  effectiveDate = {attributesData["REQEFCTVDT"]}
                />
              </React.Fragment>
            ))
        }

        <Accordion
          elevation={0}
          className="mb-3"
          expanded={accordionWrkfl}
          onChange={() => setAccordionWrkfl((prev) => !prev)}>
          <AccordionSummary
            id="profile-accordion"
            style={{ background: "#3C5464" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}>
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <p style={{ fontSize: "16px" }}>Workflow</p>
              </div>
              <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
            </div>
          </AccordionSummary>

          <AccordionDetails className="page_heading">
            <div className="row">
              {approvers && approvers.length >= 1 && (
                <div className="col-lg-3 col-sm-3 col-xs-4">
                  <div className="mb-1">
                    <label className="col-form-label">Initiator</label>
                    <sup>*</sup>
                    <InputForm
                      value={
                        proxyUserDetails == null
                          ? attributesData["WRKFLINNM"]
                          : `${proxyUserDetails.FirstName} ${proxyUserDetails.LastName}`
                      }
                      placeholder={"Initiator"}
                      isDisabled={true}
                      textArea={false}
                      onChange={() => {}}
                    />
                  </div>
                </div>
              )}
              {/* For Approver noOfApprover */}
              {approvers &&
                approvers.length > 1 &&
                approvers.map(
                  (eachApprover, index) =>
                    index > 0 && (
                      <React.Fragment key={index}>
                        <div className="col-lg-3 col-sm-3 col-xs-4">
                          <div className="dropdown mb-1">
                            <label className="col-form-label">
                              {eachApprover?.roleName 
                              ? eachApprover?.roleName 
                              : `${"Approver"} ${index}`}
                            </label>
                            <sup>*</sup>
                            <SelectForm
                              isClearable
                              isSearchable
                              isDisabled={true}
                              value={allApprovers[`${"Approver"} ${index}`]}
                              options={approverOpt[`${"Approver"} ${index}`]}
                              placeholder={`No Employee Found`}
                              onChange={(e) => {
                                alert("Change");
                              }}
                              isMulti={true}
                              noIndicator={false}
                              noSeparator={false}
                            />
                            <div className="dropdown-content">
                              {approverOpt[`${"Approver"} ${index}`] != null &&
                                approverOpt[`${"Approver"} ${index}`].length >
                                  0 &&
                                approverOpt[`${"Approver"} ${index}`].map(
                                  (eachApp) => <p>{eachApp.label}</p>
                                )}
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    )
                )}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <Modal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white row mx-0"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px"
          }}>
          <div className="col-lg-9">Confirm!</div>
          <div className="col-lg-3" style={{ display : 'flex', justifyContent : 'flex-end' }}>
            <div className="fa fa-times" onClick={() => setShowConfirmationModal(false)} style={{ fontSize : 20, cursor : 'pointer' }} />
          </div>
        </div>
        <Modal.Body>
          <h5>{"Would you like to synchronize this data in the position?"}</h5>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-success"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setIsPositionSync(true);
              closeConfirmationModal(true);
            }}>
            Yes
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setIsPositionSync(false);
              closeConfirmationModal(false);
            }}>
            No
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProgRequisitionDetatils;
