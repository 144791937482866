import React, { useState, useEffect, useContext, CSSProperties  } from "react";
import "./FormStructure.css"; 
import defaultProfileImgPic from "../../Assets/Images/profile.png";
function ProfilePrint({
    headerValues,
    profileImg
}){
   const customizedstyle :{ [key: string]: CSSProperties } = {
    containerfluid:{
      "width":"100%",
      "paddingRight":"15px",
      "paddingLeft":"15px",
      "marginRight":"auto",
      "marginLeft":"auto"
    },
    row:{
      "display":"flex",
      "flexWrap":"wrap",
      "marginRight":"-15px",
      "marginLeft":"-15px"
    },
    prof_bg:{
      "backgroundColor":"#00abe6",
      "padding":"20px 20px 0 20px"
    },
    mb_3:{
      "marginBottom":"1rem !important",
    },
    border_bottom:{
      "borderBottom":"1px solid #dee2e6!important"
    },
    botpad:{
      "paddingBottom":"20px",
    },
    col_lg_4:{
      "position":"relative",
      "width":"100%",
      "paddingRight":"15px",
      "paddingLeft":"15px",
      "flex":"0 0 33.333333%",
    },
    col_lg_6:{
      "position":"relative",
      "width":"100%",
      "paddingRight":"15px",
      "paddingLeft":"15px",
      "flex":"0 0 50%",
    },
    col_lg_8:{
      "position":"relative",
      "width":"100%",
      "paddingRight":"15px",
      "paddingLeft":"15px",
      "flex":"0 0 66.666667%",
    },
    prof_img:{
      "width":"165px",
      "height":"165px",
      "borderRadius":"50%",
      "objectFit":"cover",
      "overflow":"hidden",
      "marginRight":"20px"
    },
    profData:{
      "color":"#fff",
    },
    nameh4:{
      "fontSize":"22px",
      "lineHeight":"30px",
      "margin":"0 0 15px 0",
      "fontWeight":"500",
    },
    margin_li:{
      "marginBottom": "10px",
    },
    img_custom:{
    "width": "100%",
    "height": "165px",
    "position": "relative",
    }
   }
  //  const {containerfluid,row}= customizedstyle;
        return (
            <div style={customizedstyle.containerfluid}>
            <div 
                style={{
                  ...customizedstyle.row,
                  ...customizedstyle.prof_bg,
                  ...customizedstyle.mb_3,
                  ...customizedstyle.border_bottom,
                   backgroundColor: headerValues ? "#00abe6" : "white" }}>
            <div style={customizedstyle.containerfluid}>
             {headerValues && (
              <div 
              style={{
                ...customizedstyle.row,
                ...customizedstyle.botpad,
              }}
              >
                <div
                     style={{
                      ...customizedstyle.col_lg_4,
                     }}
                >
                  <div  
                  style={{
                      ...customizedstyle.prof_img,
                     }}>
                    <img
                      src={
                        profileImg != null ? profileImg : defaultProfileImgPic
                      }
                      style={{
                        ...customizedstyle.img_custom,
                      }}
                      alt="Profile"
                    />
                  </div>
                </div>

                <div style={{
                  ...customizedstyle.col_lg_8,
                  ...customizedstyle.profData
                }}>
                  <div>
                    <h4 style={{
                      ...customizedstyle.nameh4
                    }}>
                      {headerValues &&
                        `${headerValues?.FN} ${headerValues?.LN}`}
                    </h4>
                  </div>
                  <div  style={{
                ...customizedstyle.row,
              }}>
                    <div style={{
                ...customizedstyle.col_lg_6,
              }}    
                   >
                      <ul>
                        <li
                        style={{
                          ...customizedstyle.margin_li,
                        }} >                   
                            <span className="header">
                              <i className="fa-regular fa-address-card"></i>{" "}
                              {headerValues && <>{headerValues?.Id}</>}
                            </span>
                         
                        </li>
                        <li style={{
                          ...customizedstyle.margin_li,
                        }}>
                        
                            <span className="header">
                              <i className="fa-solid fa-user"></i>{" "}
                              {headerValues && headerValues?.SD !== null && (
                                <>{headerValues?.SD}</>
                              )}
                            </span>
                        
                        </li>
                        <li style={{
                          ...customizedstyle.margin_li,
                        }}>
                         
                            <span className="header">
                              <i className="fa-solid fa-briefcase"></i>{" "}
                              {headerValues && headerValues?.LEN !== null && (
                                <>{headerValues?.LEN}</>
                              )}
                            </span>
                         
                        </li>
                        <li style={{
                          ...customizedstyle.margin_li,
                        }}>
                        
                            <span className="header">
                              <i className="fa-solid fa-location-dot"></i>{" "}
                              {headerValues && headerValues?.PHLOC !== null && (
                                <>{headerValues?.PHLOC}</>
                              )}
                            </span>
                        
                        </li>
                        <li style={{
                          ...customizedstyle.margin_li,
                        }}>
                        
                            <span className="header">
                              <i className="fa-solid fa-briefcase"></i>{" "}
                              {headerValues && headerValues?.FUNC !== null && (
                                <>{headerValues?.FUNC}</>
                              )}
                            </span>
                        
                        </li>
                        <li style={{
                          ...customizedstyle.margin_li,
                        }}>
                         
                            <span className="header">
                              <i className="fa-solid fa-briefcase"></i>{" "}
                              {headerValues && headerValues?.JT !== null && (
                                <>{headerValues?.JT}</>
                              )}
                            </span>
                        
                        </li>
                      </ul>
                    </div>
                    <div style={{
                ...customizedstyle.col_lg_6,
              }}   >
                      <ul>
                        <li style={{
                          ...customizedstyle.margin_li,
                        }}>
                        
                            <span className="header">
                              <i className="fa-solid fa-phone"></i>{" "}
                              {headerValues && headerValues.PN != "" && (
                                <>{headerValues.PN}</>
                              )}
                            </span>
                        
                        </li>
                        <li style={{
                          ...customizedstyle.margin_li,
                        }}>
                        
                            <span className="header">
                              <i className="fa-solid fa-envelope"></i>{" "}
                              {headerValues && headerValues.EA != "" && (
                                <>{headerValues.EA}</>
                              )}
                            </span>
                        
                        </li>
                        <li style={{
                          ...customizedstyle.margin_li,
                        }}>
                        
                            <span className="header">
                              <i className="fas fa-network-wired"></i>{" "}
                              {headerValues && headerValues?.MG !== "" && (
                                <>{headerValues?.MG}</>
                              )}
                            </span>
                        
                        </li>
                        <li style={{
                          ...customizedstyle.margin_li,
                        }}>
                        
                            <span className="header">
                              <i className="fa-solid fa-briefcase"></i>{" "}
                              {headerValues && headerValues?.DPT !== "" && (
                                <>{headerValues?.DPT}</>
                              )}
                            </span>
                        
                        </li>
                        <li style={{
                          ...customizedstyle.margin_li,
                        }}>
                        
                            <span className="header">
                              <i className="fas fa-tag"></i>{" "}
                              {headerValues && headerValues?.EmployeeStatus && (
                                <>{headerValues?.EmployeeStatus}</>
                              )}
                            
                            </span>
                        
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                

              </div>
            )}
</div>
            </div>
            </div>
)
}
export default ProfilePrint;