import React, { useContext, useEffect, useState } from "react";
import notify from "../../Helpers/ToastNotification";
import { APICall, APICallWithSignal } from "../../Helpers/APICalls";
import { Tabs, Tooltip } from "@mui/material";
import {
  GetThumbnailDetails,
  SetThumbnailDetails,
  getCourseData,
  GetUpCommingAndOnGoingScheduleByCourseId,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { Modal } from "react-bootstrap";
import { LoaderContext } from "../../Helpers/Context/Context";
import defaultProfileImgPic from "../../Assets/Images/thumbnail.jpg";
import { useLocation } from "react-router-dom";
import { deleteDocument } from "../../Helpers/APIEndPoints/EndPoints";

const CourseHeader = () => {
  const [headerValues, setHeaderValues] = useState<any>({ FN: "hello" });

  const [profileImgToUpload, setProfileImgToUpload] = useState<any>(null);

  const [showProfileModal, setShowProfileModal] = useState(false);

  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();

  const [profileImg, setProfileImg] = useState(null);

  const { showLoader, hideLoader } = useContext(LoaderContext);

  const { state } = useLocation();

  const [isDisable, setIsDisable] = useState(
    state && state?.isDisable ? true : false
  );

  const fileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  function downloadBase64File(base64String: string, fileName: string) {
    const mimeType = inferMimeType(base64String);
    const blob = base64toBlob(base64String, mimeType);

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  }
  function inferMimeType(base64String: string): string {
    const dataUrl = `data:application/octet-stream;base64,${base64String}`;
    const typeInfo = /^data:(.*?);/.exec(dataUrl);

    if (typeInfo && typeInfo[1]) {
      return typeInfo[1];
    }

    return "application/octet-stream";
  }
  function base64toBlob(base64String: string, mimeType: string): Blob {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], { type: mimeType });
  }

  const handleImageSelect = async (e) => {
    const file = e.target.files[0];
    let fileBase64: { Name: string; Data: string } = null;
    const base64 = (await fileToBase64(file)) as string;
    const base64WithoutPrefix = base64.split(",")[1];
    fileBase64 = { Name: file.name, Data: base64WithoutPrefix };

    const validExtensions = ["png", "jpg", "jpeg"];
    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();
      const fileSize = file.size;
      const maxFileSizeBytes = 5 * 1024 * 1024;

      // Check if the file extension is allowed
      if (
        validExtensions.includes(fileExtension) &&
        fileSize <= maxFileSizeBytes
      ) {
        // file to upload
        setProfileImgToUpload(fileBase64);
        //setProfileImg(`data:image;base64,${fileBase64.Data}`);
      } else {
        notify(1, "Only file with png,jpg,jpeg allowed and size under 5MB");
        e.target.value = "";
        setProfileImgToUpload(null);
      }
    }
  };
  //Api to delete Profile Image
  const deleteProfileImage = async () => {
    if (profileImg != null) {
      showLoader();
      const doc = await APICall(deleteDocument, "POST", {
        TId: 0,
        AC: "ProfilePic",
        DocId: "0",
        UserId: proxyUserDetails?.value
          ? proxyUserDetails?.value
          : userDetails.UserId,
      });
      if (doc.message === "Success") {
        setProfileImg(null);
        setShowProfileModal(false);
        setProfileImgToUpload(null);
        hideLoader();
      } else {
        notify(1, "File not Deleted, Something Went Wrong.");
        hideLoader();
      }
    }
  };

  // API call to upload the image
  const handleImageUpload = async () => {
    debugger;
    if (profileImgToUpload) {
      showLoader();
      let reqObj = {
        Mode: "Course",
        SectionName: "Course",
        PId: state?.courseId,
        Id: state?.courseId,
        CreatedBy: userDetails.Id,
        file: {
          Name: profileImgToUpload.Name,
          Data: profileImgToUpload.Data,
        },
      };

      try {
        const response = await APICall(SetThumbnailDetails, "POST", reqObj);
        if (response && response.data) {
          setProfileImg(`data:image;base64,${response.data.base64}`);
          setShowProfileModal(false);
          hideLoader();
        } else {
          console.error("Image upload failed");
          hideLoader();
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        hideLoader();
      }
    } else {
      notify(1, "Please Select Image to Upload.");
    }
  };

  useEffect(() => {
    (async () => {
      const responseData = await APICall(getCourseData, "POST", {
        Id: state?.courseId,
      });

      console.log("responseData", responseData);
      if (responseData.status === 0 && responseData.data !== null) {
        const { createDate } = responseData?.data;
        setHeaderValues(responseData.data);
        if (responseData.data.thumbnailUrl !== null) {
          const res = await APICall(GetThumbnailDetails, "POST", {
            ThumbnailUrl: responseData.data.thumbnailUrl,
          });

          if (res.status === 0) {
            setProfileImg(`data:image;base64,${res.data.thumbnailDataObject}`);
          }
        }
      }
    })();
  }, [state?.courseId]);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchData = async () => {
      await handleFetchUpcommingScheduleAndOngoingSchedule(signal);
    };

    fetchData();

    return () => {
      // Cleanup: Abort the POST request if the component is unmounted
      abortController.abort();
    };
  }, [state?.courseId]);

  async function handleFetchUpcommingScheduleAndOngoingSchedule(signal) {
    try {
      showLoader();
      const response = await APICallWithSignal(
        GetUpCommingAndOnGoingScheduleByCourseId,
        "POST",
        {
          courseId: state?.courseId,
        },
        true,
        null,
        signal
      );

      debugger;

      if (response?.status == 0) {
        const { upcommingSchedule, ongoingSchedule } = response?.data;
        setHeaderValues((prev) => {
          return {
            ...prev,
            upCommingSchedule: upcommingSchedule || "No upcomming schedule",
            onGoingSchedule: ongoingSchedule || "No ongoing schedule",
          };
        });
      } else {
        setHeaderValues((prev) => {
          return {
            ...prev,
            upCommingSchedule: "No upcomming schedule",
            onGoingSchedule: "No ongoing schedule",
          };
        });
        console.error(response?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  return (
    <>
      {headerValues && (
        <div className="row botpad">
          <div className="col-lg-2 col-md-2 col-sm-12">
            <div className="card Prof_img">
              <img
                src={profileImg != null ? profileImg : defaultProfileImgPic}
                className="card-img-top"
                alt="Thumbnail Image"
              />
            </div>
            {!isDisable && (
              <a
                className="edit-profile"
                onClick={() => setShowProfileModal(true)}
              >
                <i className="fas fa-edit"></i>
              </a>
            )}
          </div>

          <div className="col-lg-10 col-md-10 col-sm-12 profData">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12 profDetail">
                <ul>
                  <li>
                    {/* <Tooltip title="Course ID"> */}
                    <span className="header">
                      <i className="fas fa-sort-numeric-down mr-2"></i>
                      Course ID :{" "}
                      {headerValues && <>{headerValues?.courseCode}</>}
                    </span>
                    {/* </Tooltip> */}
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 profDetail">
                <ul>
                  <li>
                    {/* <Tooltip title="Upcomming Schedule"> */}
                    <span className="header">
                      <i className="fas fa-clock mr-2" />
                      Upcomming Schedule :{" "}
                      {headerValues && <>{headerValues?.upCommingSchedule}</>}
                    </span>
                    {/* </Tooltip> */}
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12 profDetail">
                <ul>
                  <li>
                    {/* <Tooltip title="Course Title"> */}
                    <span className="header">
                      <i className="fa fa-book mr-2" />
                      Course Title :{" "}
                      {headerValues && <>{headerValues?.courseTitle}</>}
                    </span>
                    {/* </Tooltip> */}
                  </li>
                </ul>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 profDetail">
                <ul>
                  <li>
                    {/* <Tooltip title="Ongoining Schedule"> */}
                    <span className="header">
                      <i className="far fa-calendar mr-2" />
                      Ongoining Schedule :{" "}
                      {headerValues && <>{headerValues?.onGoingSchedule}</>}
                    </span>
                    {/* </Tooltip> */}
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12 profDetail">
                <ul>
                  <li>
                    {/* <Tooltip title="Created By"> */}
                    <span className="header">
                      <i className="fas fa-user mr-2" />
                      Created By :{" "}
                      {headerValues && <>{headerValues?.createdByName}</>}
                    </span>
                    {/* </Tooltip> */}
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 profDetail">
                <ul>
                  <li>
                    {/* <Tooltip title="Created Date"> */}
                    <span className="header">
                      <i className="far fa-calendar-alt mr-2" />
                      Created Date :{" "}
                      {headerValues && <>{headerValues?.createdDate}</>}
                    </span>
                    {/* </Tooltip> */}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal
        show={showProfileModal}
        onHide={() => setShowProfileModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">Upload Course Thumbnail!</div>
        </div>
        <Modal.Body>
          <>
            <input
              type="file"
              multiple={false}
              accept={".png,.jpg,.jpeg"}
              onChange={handleImageSelect}
            />
            <div>
              <b>Acceptable image formats:</b> .png, .jpg and .jpeg{" "}
            </div>
            <div>
              {" "}
              <b>Maximum File Size:</b> 5MB{" "}
            </div>
            <div>
              <b>Resolution:</b> 3245 X 3245{" "}
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          {profileImg != null && (
            <button
              className="btn btn-primary"
              style={{ marginLeft: 5 }}
              onClick={() => deleteProfileImage()}
            >
              Remove
            </button>
          )}
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => handleImageUpload()}
          >
            Upload
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowProfileModal(false);
              setProfileImgToUpload(null);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CourseHeader;
