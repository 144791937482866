import React, { useContext, useEffect, useState } from "react";
import { apiResp, cityData, stateData } from "./formData";
import ButtonForm from "../ButtonForm/ButtonForm";
import { useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tabs,
  Tooltip,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { ExpandMore } from "@mui/icons-material";
import SelectForm from "../SelectForm/SelectForm";
import InputForm from "../InputForm/InputForm";
import DynamicGrid from "../DynamicGrid/DynamicGrid";
import uuid from "react-uuid";
import DateForm from "../DateForm/DateForm";
import moment from "moment";
import "./FormStructure.css";
import profileImg from "../../Assets/Images/icons_464923.png";
import { Table } from "react-bootstrap";
import { APICall } from "../../Helpers/APICalls";
import { LoaderContext } from "../../Helpers/Context/Context";
import {
  checkIfEntryInAuditLogForPosition, 
  requestRevokeForPosition,
  getProfilePicImg,
  getAttributeValueDetailsV2,
  getDropdowns,
  getSearchableDropdowns,
  getSectionDetails,
  getSectionDetailsV2,
  getTabsData,
  postAttributeValueDetailsV2,
  getParenPBasedonIncm,
  getWorkflowData,
  getApproverAndProgressionDetailsByWorkflowTaskId,
  getAttrForProgression,
  handleApprovalForPositionModel,
  getDdForProgression,
  getSearchDdForProgression,
  InsertAuditLog,
  getPositionIncumbentGridData
} from "../../Helpers/APIEndPoints/EndPoints";
import notify from "../../Helpers/ToastNotification";
import { useLocation } from "react-router-dom";
import RTE from "../RTE/RTE";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { Modal } from "react-bootstrap";
//import ViewHistory from "../FromStructure/ViewHistory";
import ViewHistoryPosition from "./ViewHistoryPosition";
//import usePMStore from "../../Helpers/ZustandStore/PMStore";

const SectionComponent = ({
  TId,
  setTId,
  formData,
  setFormData,
  section: ogSection,
  sectionIndex,
  sectionValuesArray,
  COU,
  appaction,
  setIsBudget,
  setBIS,
  setSBU,
  setLEN,
  setPLOC,
  viewSectionHistory,
  allApprovers,
  setDpProfileImg,
  wflStatus,
  setwflStatus ,
  PositionHolder ,
  setPositionHolder
  // counterForIncChange,
}) => {
  const [hasValue, setHasValue] = useState(
    sectionValuesArray.filter((es) => es.sectionSystemName == ogSection.SN) ||
      null
  );
  //new hire event state

  const [section, setSection] = useState(ogSection);
  const [accordion, setAccordion] = useState(sectionIndex === 0 ? true : false);
  const [formErrors, setFormErrors] = useState<any>({});
  const [optionsObj, setOptionsObj] = useState<any>({});
  // const [depOptionsObj, setDepOptionsObj] = useState<any>({});
  const [gridData, setGridData] = useState([]);
  const [displayGrid, setDisplayGrid] = useState([]);
  const [IncdisplayGrid, setIncDisplayGrid] = useState([]);
  const [gridColumns, setGridColumns] = useState([]);
  const [ogAttributeSet, setOgAttributeSet] = useState({});
  const [ogFilledAttributeSet, setOgFilledAttributeSet] = useState([]);
  const [currentGridId, setCurrentGridId] = useState(null);
  const [deleteGridId, setDeleteGridId] = useState(null);
  
  // audit log state
  const [oldAuditLog, setOldAuditLog] = useState([]);
  const [newAuditLog, setNewAuditLog] = useState([]);
  const [postAuditLog, setPostAuditLog] = useState(false);

  // value Object for section
  const [attributesData, setAttributesData] = useState<any>({});
  const [attributeSetData, setAttributeSetData] = useState<any>({});

  const [attributeSetCode, setAttributeSetCode] = useState(null);
  const [isOnce, setIsOnce] = useState(true);
  const [isValidateOnce, setIsValidateOnce] = useState(true);

  const [attributeValidation, setAttributeValidation] = useState<any>({});
  const [attributeSetValidation, setAttributeSetValidation] = useState<any>({});

  const [dataFilled, setDataFilled] = useState(false);

  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isSync, setIsSync] = useState(false);
  const [confirmationPromise, setConfirmationPromise] = useState(null);
  const [disableState, setDisableState] = useState(false);
  const [workflowStatus, setWorkflowStatus] = useState<any>(null);
  const [fillDDValuesForGrid, setFillDDValuesForGrid] = useState([]);
  let { userDetails, currentRoleId , proxyUserDetails}: any = useUserContext();
  const [attributesAreAvailableForEdit, setAttributesAreAvailableForEdit] = useState(
    currentRoleId?.code === 'SystemAdmin' ? true : false
  );
  const navigate = useNavigate();
  // const { changeIncmName, updateIncmChange  }: any = usePMStore();
  // let { incmName, setIncmChange }: any = usePMStore();

  const getDropdownsData = async (AC, PID) => {
    await showLoader();
    const dropdownData = await APICall(getDropdowns, "POST", {
      AC,
      PID,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    if (
      dropdownData.data !== null &&
      dropdownData.data !== undefined &&
      dropdownData.data.length > 0
    ) {
      await hideLoader();
      return dropdownData.data;
    } else {
      await hideLoader();
      return [];
    }
  };

  const transformDynamicKey = (object) => {
    for (const key in object) {
      if (
        object[key] &&
        typeof object[key] === "object" &&
        object[key].hasOwnProperty("label")
      ) {
        object[key] = object[key].label;
      }
    }
    return object;
  };

  const [fileDemo, setFileDemo] = useState(null);
  useEffect(() => {
    //add attribute in attributesData
    let attributes = {};
    let attributeSet = {};
    const gridColumnsArr = [];
    let dropdownOptionsObj = {};
    section?.Attribute?.forEach(async (eachAttribute) => {
      if (eachAttribute.AT === "Attribute") {
        //change value according to dt

        setAttributesData((prev) => ({
          ...prev,
          [eachAttribute.AC]:
            eachAttribute.DT === "DropdownSingle" ||
            eachAttribute.DT === "Date" ||
            eachAttribute.DT === "SearchableDropdownSingle"
              ? null
              : eachAttribute.DT === "Text" ||
                eachAttribute.DT === "Number" ||
                eachAttribute.DT === "RichText"
              ? ""
              : eachAttribute.DT === "Checkbox"
              ? false
              : "",
        }));

        attributes = {
          ...attributes,
          [eachAttribute.AC]:
            eachAttribute.DT === "DropdownSingle" ||
            eachAttribute.DT === "Date" ||
            eachAttribute.DT === "SearchableDropdownSingle"
              ? null
              : eachAttribute.DT === "Text" ||
                eachAttribute.DT === "Number" ||
                eachAttribute.DT === "RichText"
              ? ""
              : eachAttribute.DT === "Checkbox"
              ? false
              : "",
        };

        //attributeValidation
        setAttributeValidation((prev) => ({
          ...prev,
          [eachAttribute.AC]: JSON.parse(eachAttribute.V),
        }));

        setAttributeValidation((prev) => ({
          ...prev,
          [eachAttribute.AC]: {
            ...prev[eachAttribute.AC],
            DD: eachAttribute.DD !== null ? JSON.parse(eachAttribute.DD) : null,
            isDisable: false,
          },
        }));

        if (eachAttribute.DT === "DropdownSingle") {
          // api call for each dropdown option

          // const options = await getDropdownsData(eachAttribute.AC, null);

          dropdownOptionsObj = {
            ...dropdownOptionsObj,
            [eachAttribute.AC]: eachAttribute.OPT,
          };
          if (eachAttribute.AC === "STATSP") {
            if (TId === 0) {
              let filtredOPT = eachAttribute.OPT?.filter((o) => {
                if (o.label.toLowerCase() != "inactive") {
                  return o;
                }
              });
              if (filtredOPT.length > 0) {
                setOptionsObj((prev) => ({
                  ...prev,
                  [eachAttribute.AC]: filtredOPT,
                }));
              }
            } else {
              let filtredOPT = eachAttribute.OPT?.filter((o) => {
                if (o.label.toLowerCase() != "draft") {
                  return o;
                }
              });
              if (filtredOPT.length > 0) {
                setOptionsObj((prev) => ({
                  ...prev,
                  [eachAttribute.AC]: filtredOPT,
                }));
              }
            }
          } else {
            setOptionsObj((prev) => ({
              ...prev,
              [eachAttribute.AC]: eachAttribute.OPT,
            }));
          }

          if (JSON.parse(eachAttribute.AD).DF !== undefined) {
            let filtredOPT = eachAttribute.OPT?.filter((o) => {
              if (o.label.toLowerCase() === JSON.parse(eachAttribute.AD).DF) {
                return o;
              }
            });
            if (filtredOPT.length > 0) {
              setAttributesData((prev) => ({
                ...prev,
                [eachAttribute.AC]: filtredOPT[0],
              }));
              if (eachAttribute.AC === "ISBDGT") {
                filtredOPT[0].label.toLowerCase() === "yes"
                  ? setIsBudget(true)
                  : setIsBudget(false);
              }
            }
          }
          if (eachAttribute.AC === "VCANSTAT" && TId === 0) {
            if (eachAttribute.OPT?.length > 0) {
              setAttributesData((prev) => ({
                ...prev,
                [eachAttribute.AC]: eachAttribute.OPT?.filter(
                  (x) => x.label.toLowerCase() === "vacant"
                )[0],
              }));

              attributeSet = {
                ...attributeSet,
                [eachAttribute.AC]: eachAttribute.OPT?.filter(
                  (x) => x.label.toLowerCase() === "vacant"
                )[0],
              };
            }
          }
          if (eachAttribute.AC === "CRTCLTY" && TId === 0) {
            if (eachAttribute.OPT?.length > 0) {
              setAttributesData((prev) => ({
                ...prev,
                [eachAttribute.AC]: eachAttribute.OPT?.filter(
                  (x) => x.label.toLowerCase() === "no"
                )[0],
              }));

              attributeSet = {
                ...attributeSet,
                [eachAttribute.AC]: eachAttribute.OPT?.filter(
                  (x) => x.label.toLowerCase() === "no"
                )[0],
              };
            }
          } 
        } else if (eachAttribute.DT === "SearchableDropdownSingle") {
          dropdownOptionsObj = {
            ...dropdownOptionsObj,
            [eachAttribute.AC]: "SearchableDropdownSingle",
          };

          setOptionsObj((prev) => ({
            ...prev,
            [eachAttribute.AC]: "SearchableDropdownSingle",
          }));
        } else if (eachAttribute.DT === "Number") {
          if (JSON.parse(eachAttribute.AD).DF !== undefined) {
            let value = JSON.parse(eachAttribute.AD).DF;
            setAttributesData((prev) => ({
              ...prev,
              [eachAttribute.AC]: value,
            }));
          }
        }
      } else if (eachAttribute.AT === "AttributeSet") {
        setAttributeSetCode(eachAttribute.AC);

        // Id for grid
        gridColumnsArr.push({
          name: "Id",
          label: "Id",
          options: { display: false },
        });

        eachAttribute.Attribute.forEach(async (subAttribute) => {
          let display = JSON.parse(subAttribute.AD);
          display = display.ISOG.toLowerCase() === "true" ? true : false;

          //grid columns
          gridColumnsArr.push({
            name: subAttribute.AC,
            label: subAttribute.AN,
            options: { sort: false, display },
          });

          //attributeSetValidation
          setAttributeSetValidation((prev) => ({
            ...prev,
            [subAttribute.AC]: JSON.parse(subAttribute.V),
          }));

          //change value according to dt

          setAttributeSetData((prev) => ({
            ...prev,
            [subAttribute.AC]:
              subAttribute.DT === "DropdownSingle" ||
              subAttribute.DT === "Date" ||
              subAttribute.DT === "SearchableDropdownSingle"
                ? null
                : subAttribute.DT === "Text" ||
                  subAttribute.DT === "Number" ||
                  eachAttribute.DT === "RichText"
                ? ""
                : subAttribute.DT === "Checkbox"
                ? false
                : "",
          }));

          attributeSet = {
            ...attributeSet,
            [subAttribute.AC]:
              subAttribute.DT === "DropdownSingle" ||
              subAttribute.DT === "Date" ||
              subAttribute.DT === "SearchableDropdownSingle"
                ? null
                : subAttribute.DT === "Text" ||
                  subAttribute.DT === "Number" ||
                  eachAttribute.DT === "RichText"
                ? ""
                : subAttribute.DT === "Checkbox"
                ? false
                : "",
          };

          if (subAttribute.DT === "DropdownSingle") {
            // const options = await getDropdownsData(subAttribute.AC, null);

            dropdownOptionsObj = {
              ...dropdownOptionsObj,
              [subAttribute.AC]: subAttribute.OPT,
            };

            setOptionsObj((prev) => ({
              ...prev,
              [subAttribute.AC]: subAttribute.OPT,
            }));
            if (JSON.parse(eachAttribute.AD).DF !== undefined) {
              let filtredOPT = eachAttribute.OPT?.filter((o) => {
                if (o.label.toLowerCase() === JSON.parse(eachAttribute.AD).DF) {
                  return o;
                }
              });
              if (filtredOPT.length > 0) {
                setAttributesData((prev) => ({
                  ...prev,
                  [eachAttribute.AC]: filtredOPT[0],
                }));
              }
            }
          } else if (subAttribute.DT === "SearchableDropdownSingle") {
            dropdownOptionsObj = {
              ...dropdownOptionsObj,
              [subAttribute.AC]: "SearchableDropdownSingle",
            };

            setOptionsObj((prev) => ({
              ...prev,
              [subAttribute.AC]: "SearchableDropdownSingle",
            }));
          }
        });
      }
    });

    // setAttributesData((prev) => ({
    //   ...prev,
    //   ...attributes,
    // }));

    // setAttributeSetData((prev) => ({
    //   ...prev,
    //   ...attributeSet,
    // }));

    setOgAttributeSet(attributeSet);

    setGridColumns((prev) => [...prev, ...gridColumnsArr, action]);

    // setOptionsObj((prev) => ({ ...prev, ...dropdownOptionsObj }));

    //audit
    const dataForAuditLog = [];
    if (Object.keys(attributes).length > 0) {
      Object.entries(attributes).forEach((data) => {
        const [key, value]: any[] = data;

        const auditLogObj = {
          sectionId: section.SId.toString(),
          IsAttributeSet: false,
          Code: key,
          Attribute: [
            {
              [key]: value?.label || value,
            },
          ],
        };
        dataForAuditLog.push(auditLogObj);
      });
    }

    setOldAuditLog((prev) => [...prev, ...dataForAuditLog]);

    const fillData = async () => {
      await showLoader();
      if (isOnce && TId !== 0) {
        //fetch attributes/set data

        //check if not first time then call api
        let attributesRes = null;
        if (hasValue.length > 0 && hasValue[0].attributes.length > 0) {
          if(hasValue[0].sectionSystemName === 'EmployeePosition' && hasValue[0].workflowStatus ){
            setwflStatus(hasValue[0].workflowStatus);
            setWorkflowStatus(hasValue[0].workflowStatus);
          }

          if(appaction === "reviewer"){
            setDisableState(true);
          } else if (
            hasValue[0].workflowStatus === "Pending" &&
            appaction === "view"
          ) {
            setDisableState(true);
          } else if (
            hasValue[0].workflowStatus === "Pending" &&
            appaction === "sendback"
          ) {
            setDisableState(false);
          } else if (hasValue[0].workflowStatus === "Pending") {
            setDisableState(true);
          } else if (hasValue[0].workflowStatus === "Rejected") {
            setDisableState(true);
          } else {
            setDisableState(false);
          }
          attributesRes = hasValue[0].attributes;
          // handle data
          if (attributesRes !== null && attributesRes.length > 0) {
            let newAttributeData: any = { ...attributes };
//             attributesRes.map(async (eachAttributeRes) => {
//               if (eachAttributeRes.type === "Attribute") {
//                 //Attributes
//                 if (
//                   Object.keys(attributes).length > 0 &&
//                   eachAttributeRes.details === null
//                 ) {
//                   let newAttributeData = { ...attributes };
//                   let dropdownOptions = { ...dropdownOptionsObj };

//                   Object.keys(newAttributeData).map(async (attribute) => {
//                     if (
//                       Object.keys(dropdownOptions).find(
//                         (option) =>
//                           option.toLowerCase() === attribute.toLowerCase()
//                       ) &&
//                       dropdownOptions[attribute] !== "SearchableDropdownSingle"
//                     ) {
//                       const valueForDropdown = attributesRes.find(
//                         (attri) => attri.key === attribute
//                       )?.value;
//                       const options = dropdownOptions[attribute];

//                       if (options !== undefined && options.length > 0) {
//                         const option = options.find(
//                           (attri) => attri.value == valueForDropdown
//                         );

//                         // if(attribute==="VCANSTAT" && TId!==0 && (valueForDropdown=== "0" ) && newAttributeData["INCMPAPO"]!==null ){
//                         //   const option = options.find(
//                         //     (attri) => attri.value == "Occupied"
//                         //   );
//                         //   newAttributeData = {
//                         //     ...newAttributeData,
//                         //     [attribute]: option === undefined ? null : option,
//                         //   };
//                         // }

//                         newAttributeData = {
//                           ...newAttributeData,
//                           [attribute]: option === undefined ? null : option,
//                         };
//                       }
//                     } else if (
//                       Object.keys(dropdownOptions).find(
//                         (option) =>
//                           option.toLowerCase() === attribute.toLowerCase()
//                       ) &&
//                       dropdownOptions[attribute] === "SearchableDropdownSingle"
//                     ) {
//                       const valueForDropdown = attributesRes.find(
//                         (attri) => attri.key === attribute
//                       )?.value;
//                       //for filling searchable dropdown on edit
//                       if (valueForDropdown !== undefined) {
//                         const DDResponse = await Promise.all([
//                           getSearchableDropdownAPI(
//                             "",
//                             attribute,
//                             null,
//                             valueForDropdown,
//                             "attribute",
//                             0
//                           ),
//                         ]);
//                         // const DDResponse = await getSearchableDropdownAPI(
//                         //   "",
//                         //   attribute,
//                         //   null,
//                         //   valueForDropdown,
//                         //   "attribute",
//                         //   0
//                         // );
//                         newAttributeData = {
//                           ...newAttributeData,
//                           [attribute]:
//                             DDResponse === undefined || DDResponse === null
//                               ? {
//                                   label: 'Undefinned',
//                                   value: 9999,
//                                 }
//                               : DDResponse
//                               // {
//                               //     label: DDResponse.label,
//                               //     value: DDResponse.value,
//                               //   },
//                         };

//                       }
//                       // let value =await getSearchableDropdownAPI(
//                       //   "",
//                       //   attribute,
//                       //   null,
//                       //   valueForDropdown
//                       // );

//                     } else {
//                       let valueForField = attributesRes.find(
//                         (attri) => attri.key === attribute
//                       )?.value;
//                       //check type later when actual data

//                       valueForField =
//                         typeof valueForField === "string" &&
//                         valueForField.toLowerCase() === "true"
//                           ? true
//                           : typeof valueForField === "string" &&
//                             valueForField.toLowerCase() === "false"
//                           ? false
//                           : valueForField !== null
//                           ? valueForField?.toString()
//                           : valueForField;

//                       // format date

//                       const dateString = valueForField;
//                       const allowedFormat = [
//                         "DD-MM-YYYY hh.mm.ss A",
//                         "DD/MM/YYYY hh.mm.ss A",
//                       ];

//                       const isValidDate = moment(
//                         dateString,
//                         allowedFormat,
//                         true
//                       ).isValid();
//                       if (
//                         isValidDate &&
//                         dateString !== "01-01-0001 12.00.00 AM" &&
//                         dateString !== "1/1/0001 12:00:00 AM"
//                       ) {
//                         const parsedDate = moment(
//                           valueForField,
//                           allowedFormat[0]
//                         );
//                         const formattedDate = parsedDate.format("DD-MMM-YYYY");
//                         valueForField = formattedDate;
//                       } else if (
//                         dateString === "01-01-0001 12.00.00 AM" ||
//                         dateString === "1/1/0001 12:00:00 AM"
//                       ) {
//                         valueForField = null;
//                       }
//                       if (
//                         attribute === "FTE" &&
//                         valueForField === undefined &&
//                         newAttributeData["INCMPAPO"] !== undefined
//                       ) {
//                         valueForField = "1";
//                       }

//                       newAttributeData = {
//                         ...newAttributeData,
//                         [attribute]: valueForField,
//                       };
//                     }
//                   });

//                   if (newAttributeData["PMBUSN"] != null) {
//                     setBIS(newAttributeData["PMBUSN"].value);
//                   } else if (newAttributeData["PMSBU"] != null) {
//                     setSBU(newAttributeData["PMSBU"].value);
//                   } else if (newAttributeData["PMLEGEN"] != null) {
//                     setLEN(newAttributeData["PMLEGEN"].value);
//                   } else if (newAttributeData["PMLOC"] != null) {
//                     setPLOC(newAttributeData["PMLOC"].value);
//                   }
                  
//                   setAttributesData(newAttributeData);
                  
//                   //audit
//                   const dataForAuditLog = [];
//                   if (Object.keys(newAttributeData).length > 0) {
//                     Object.entries(newAttributeData).forEach((data) => {
//                       const [key, value]: any[] = data;

//                       const auditLogObj = {
//                         sectionId: section.SId.toString(),
//                         IsAttributeSet: false,
//                         Code: key,
//                         Attribute: [
//                           {
//                             [key]: value?.label || value,
//                           },
//                         ],
//                       };
//                       dataForAuditLog.push(auditLogObj);
//                     });
//                   }
//                   //audit old data for flat
//                   setOldAuditLog((prev) => [...dataForAuditLog]);
//                 }
//                 // setIsOnce(false);
//               } else if (eachAttributeRes.type === "AttributeSet") {
//                 //AttributesSet
//                 if (
//                   Object.keys(attributeSet).length > 0 &&
//                   eachAttributeRes.details !== null &&
//                   eachAttributeRes.details.length > 0
//                 ) {
//                   const setCode = eachAttributeRes.key;
//                   setAttributeSetCode(eachAttributeRes.key);

//                   const details = eachAttributeRes.details;

//                   if (details.length > 0) {
//                     let newAttributeSetData = { ...attributeSet };
//                     let newAttributeSetDataForGrid = { ...attributeSet };
//                     let dropdownOptions = { ...dropdownOptionsObj };

//                     const filledData = [];
//                     const gridData = [];
//                     details.forEach(async (detail) => {
//                       Object.entries(detail).forEach(async (attribute) => {
//                         const [Code, Value]: any = attribute;

//                         if (
//                           Object.keys(dropdownOptions).find(
//                             (option) =>
//                               option.toLowerCase() === Code.toLowerCase()
//                           )
//                         ) {
//                           const options = dropdownOptions[Code];
//                           if (options !== undefined && options.length > 0) {
//                             const option = options.find(
//                               (attri) => attri.value == Value
//                             );

//                             newAttributeSetDataForGrid = {
//                               ...newAttributeSetDataForGrid,
//                               [Code]:
//                                 option === undefined ? null : option?.label,
//                             };

//                             newAttributeSetData = {
//                               ...newAttributeSetData,
//                               [Code]: option === undefined ? null : option,
//                             };
//                           }
//                         } else {
//                           //check type later when actual data

//                           let valueForGrid =
//                             typeof Value === "string" &&
//                             Value.toLowerCase() === "true"
//                               ? "true"
//                               : typeof Value === "string" &&
//                                 Value.toLowerCase() === "false"
//                               ? "false"
//                               : Value !== null
//                               ? Value.toString()
//                               : Value;

//                           // format date

//                           const dateString = Value;
//                           const allowedFormat = [
//                             "DD-MM-YYYY hh.mm.ss A",
//                             "DD/MM/YYYY hh.mm.ss A",
//                           ];

//                           const isValidDate = moment(
//                             dateString,
//                             allowedFormat,
//                             true
//                           ).isValid();
//                           if (
//                             isValidDate &&
//                             dateString !== "01-01-0001 12.00.00 AM" &&
//                             dateString !== "1/1/0001 12:00:00 AM"
//                           ) {
//                             const parsedDate = moment(Value, allowedFormat[0]);
//                             const formattedDate =
//                               parsedDate.format("DD-MMM-YYYY");
//                             valueForGrid = formattedDate;
//                           } else if (
//                             dateString === "01-01-0001 12.00.00 AM" ||
//                             dateString === "1/1/0001 12:00:00 AM"
//                           ) {
//                             valueForGrid = null;
//                           }

//                           let valueForField =
//                             typeof Value === "string" &&
//                             Value.toLowerCase() === "true"
//                               ? true
//                               : typeof Value === "string" &&
//                                 Value.toLowerCase() === "false"
//                               ? false
//                               : typeof Value === "boolean"
//                               ? Value
//                               : Value !== null
//                               ? Value.toString()
//                               : Value;

//                           if (
//                             isValidDate &&
//                             dateString !== "01-01-0001 12.00.00 AM" &&
//                             dateString !== "1/1/0001 12:00:00 AM"
//                           ) {
//                             const parsedDate = moment(Value, allowedFormat[0]);
//                             const formattedDate =
//                               parsedDate.format("DD-MMM-YYYY");
//                             valueForField = formattedDate;
//                           } else if (
//                             dateString === "01-01-0001 12.00.00 AM" ||
//                             dateString === "1/1/0001 12:00:00 AM"
//                           ) {
//                             valueForField = null;
//                           }

//                           newAttributeSetDataForGrid = {
//                             ...newAttributeSetDataForGrid,
//                             [Code]: valueForGrid,
//                           };

//                           newAttributeSetData = {
//                             ...newAttributeSetData,
//                             [Code]: valueForField,
//                           };
//                         }
//                       });
//                       gridData.push(newAttributeSetDataForGrid);
//                       filledData.push(newAttributeSetData);
//                     });

//                     //audit
//                     const oldAuditLog = [];

//                     for (const attribute of filledData) {
//                       const shadowObj = { ...attribute };
//                       const auditObj = transformDynamicKey(shadowObj);

//                       const obj = {
//                         IsAttributeSet: true,
//                         Code: setCode,
//                         RecordId: attribute.Id,
//                         sectionId: section.SId.toString(),
//                         Attribute: [
//                           {
//                             ...auditObj,
//                           },
//                         ],
//                       };

//                       oldAuditLog.push(obj);
//                     }

//                     setOldAuditLog((prev) => [...prev, ...oldAuditLog]);

//                     setDisplayGrid(gridData);
//                     setGridData(gridData);
//                     setOgFilledAttributeSet(filledData);
//                   }
//                   // setIsOnce(false);
//                 }
//               }
//             });
              for (const eachAttribute of attributesRes) {
                if (eachAttribute.type === "Attribute") {
                  let { key, value: valueForField } = eachAttribute;

                  if (
                    dropdownOptionsObj[key] !== undefined &&
                    dropdownOptionsObj[key] !== "SearchableDropdownSingle"
                  ) {
                    //for filling normal dropdown on edit
                    const options = dropdownOptionsObj[key];

                    if (options !== undefined && options?.length > 0) {
                      const option = options?.find(
                        (attri) => attri.value == valueForField
                      );

                      newAttributeData = {
                        ...newAttributeData,
                        [key]: option === undefined ? null : option,
                      };

                      // var tempObj = section?.Attribute?.find(att => att.AC == key);
                      // if(tempObj.IsCascade) {
                      //   setOptionsObj((prev) => ({
                      //   ...prev,
                      //   [key]: [option],
                      //   }));
                      // }
                    }
                  } else if (
                    dropdownOptionsObj[key] !== undefined &&
                    dropdownOptionsObj[key] === "SearchableDropdownSingle"
                  ) {
                    //for filling searchable dropdown on edit
                    if (valueForField !== undefined) {
                      const DDResponse = await getSearchableDropdownAPI(
                        "",
                        key,
                        null,
                        valueForField,
                        "attribute",
                        0
                      );
                      newAttributeData = {
                        ...newAttributeData,
                        [key]:
                          DDResponse === undefined
                            ? null
                            : {
                                label: DDResponse.label,
                                value: DDResponse.value,
                              },
                      };
                      if(key=="INCMID"){
                        setPositionHolder(DDResponse === undefined
                          ?"":DDResponse.label);
                      }
                    }
                  } else {
                    //filling other data

                    valueForField =
                      typeof valueForField === "string" &&
                      valueForField.toLowerCase() === "true"
                        ? true
                        : typeof valueForField === "string" &&
                          valueForField.toLowerCase() === "false"
                        ? false
                        : valueForField !== null && valueForField !== undefined
                        ? valueForField.toString()
                        : valueForField;

                    // format date

                    const dateString = valueForField;
                    const allowedFormat = [
                      "DD-MM-YYYY hh.mm.ss A",
                      "DD/MM/YYYY hh.mm.ss A",
                    ];

                    const isValidDate = moment(
                      dateString,
                      allowedFormat,
                      true
                    ).isValid();

                    const isValidBeforeDate = moment(
                      dateString,
                      "DD-MMM-YYYY",
                      true
                    ).isValid();
                    if (isValidBeforeDate && dateString === "01-Jan-1900") {
                      valueForField = null;
                    }

                    if (
                      isValidDate &&
                      dateString !== "01-01-0001 12.00.00 AM" &&
                      dateString !== "1/1/0001 12:00:00 AM"
                    ) {
                      const parsedDate = moment(valueForField, allowedFormat[0]);
                      const formattedDate = parsedDate.format("DD-MMM-YYYY");
                      valueForField = formattedDate;
                    } else if (
                      dateString === "01-01-0001 12.00.00 AM" ||
                      dateString === "1/1/0001 12:00:00 AM"
                    ) {
                      valueForField = null;
                    }

                    if (
                      key === "FTE" &&
                      valueForField === undefined &&
                      newAttributeData["INCMPAPO"] !== undefined
                    ) {
                      valueForField = "1";
                    }

                    if(key === "PMRMK" && appaction !== 'reviewer'){
                      valueForField = "";
                    }
                    newAttributeData = {
                      ...newAttributeData,
                      [key]: valueForField,
                    };
                  }
                }
              }

              //Set Values on Sendback
              if (newAttributeData["ISBDGT"] != null) {
                newAttributeData["ISBDGT"]?.label?.toLowerCase() === "yes"
                  ? setIsBudget(true)
                  : setIsBudget(false);
              } if (newAttributeData["PMBUSN"] != null) {
                setBIS(newAttributeData["PMBUSN"].value);
              } if (newAttributeData["PMSBU"] != null) {
                setSBU(newAttributeData["PMSBU"].value);
              } if (newAttributeData["PMLEGEN"] != null) {
                setLEN(newAttributeData["PMLEGEN"].value);
              } if (newAttributeData["PMLOCN"] != null) {
                setPLOC(newAttributeData["PMLOCN"].value);
              }
              setAttributesData(newAttributeData);

              // if (hasValue[0].workflowStatus === "Approved" 
              // && newAttributeData["VCANSTAT"].label !=="Vacant"
              // //&& (newAttributeData["INCMID"] != null || newAttributeData["INCMID"] != undefined )
              // ) {
              //   setDisableState(true);
              // } 
              if (newAttributeData["INCMID"] != null && newAttributeData["INCMID"] != undefined) {

                //get profile pic yawar
                (async () => {
                  await showLoader();
                  const getPhoto = await APICall(getProfilePicImg, "POST", {
                    TId: newAttributeData["INCMID"].value,
                    AC: "ProfilePic",
                  });
          
                  if (getPhoto && getPhoto.data !== null) {
                    setDpProfileImg(`data:image;base64,${getPhoto.data}`);
                  } 
                  // else {
                  //   setDpProfileImg(null);
                  // }
                  await hideLoader();
                })();
              }

            //audit
            const dataForAuditLog = [];
            if (Object.keys(newAttributeData).length > 0) {
              Object.entries(newAttributeData).forEach((data) => {
                const [key, value]: any[] = data;

                const auditLogObj = {
                  sectionId: section.SId.toString(),
                  IsAttributeSet: false,
                  Code: key,
                  Attribute: [
                    {
                      [key]: value?.label || value,
                    },
                  ],
                };
                dataForAuditLog.push(auditLogObj);
              });
            }
            //audit old data for flat
            setOldAuditLog((prev) => [...dataForAuditLog]);
          }
        }
        setIsOnce(false);
      }
      await hideLoader();
    };

    const fillTimer = setTimeout(() => {
      clearTimeout(fillTimer);
      fillData();
    }, 2000);

    //save in state
    return () => {
      clearTimeout(fillTimer);
    };
  }, []);

  useEffect(() => {
    if (
      attributesData !== undefined &&
      Object.keys(attributesData).length > 0 &&
      attributeValidation !== undefined &&
      Object.keys(attributeValidation).length > 0
    ) {
      if (
        (isValidateOnce && isOnce === false && TId !== 0) ||
        (isValidateOnce && isOnce === true && TId === 0)
      ) {

        let attributeValues = { ...attributesData }; // for checking values
        let attributeValObj = { ...attributeValidation };
        Object.keys(attributeValObj).forEach(async (attribute) => {
          if (
            attributeValObj[attribute].DD !== null &&
            attributeValObj[attribute].DD.length > 0
          ) {
            let ad = attributeValObj[attribute].DD;

            ad.forEach(async (record) => {
              let CCode = record.CCode;
              let PValue = record.PValue;
              let Condition = record.Condition;
              let Flag = record.Flag;
              let PDT = record.PDT;
              let CDT = record.CDT;
              let values = PValue.toLowerCase().toString().split(',');
              // if (
              //   attributeValues[attribute]?.toString().toLowerCase() ===
              //     PValue.toLowerCase() ||
              //   attributeValues[attribute].toString().toLowerCase() === ""
              // ) {
              //   if (Flag === "disable") {
              //     attributeValObj[CCode].isDisable = true;
              //     attributeValObj[CCode].ISM = "false";
              //   }
              // } else if (
              //   attributeValues[attribute].toString().toLowerCase() !==
              //   PValue.toLowerCase()
              // ) {
              //   if (Flag === "disable") {
              //     attributeValObj[CCode].isDisable = false;
              //     attributeValObj[CCode].ISM = "true";
              //   }
              // }

              let targetValue = attributeValues[attribute]?.code.toString();
              if(targetValue === 'Worker'){
                CCode = 'PWA';
              } else if(targetValue === 'CLM'){
                CCode = "CONTNME";
              } else if(targetValue === 'SchemeTrainee' || targetValue === 'SchemeApprentice' || targetValue == 'ExperienceApprentice'){
                CCode = "SCHNME";
              }

              if (PDT === "DropdownSingle") {
                if (values.some(x => x === attributeValues[attribute]?.code.toString().toLowerCase())
                ) {
                  //check condition
                  if (Flag === "disable") {
                    attributeValObj[CCode].isDisable = (CCode === "PWA") ? false : true;
                    if(CCode === 'PWA'){
                      attributeValObj['CONTNME'].isDisable = true;
                      attributeValObj['SCHNME'].isDisable = true;
                    } else if(CCode === 'CONTNME'){
                      attributeValObj['CONTNME'].isDisable = false; 
                      attributeValObj['SCHNME'].isDisable = true;
                      attributeValObj['PWA'].isDisable = false;
                    } else if(CCode === 'SCHNME'){
                      attributeValObj['SCHNME'].isDisable = false;
                      attributeValObj['PWA'].isDisable = false;
                      attributeValObj['CONTNME'].isDisable = true;
                    }
                    attributeValObj[CCode].ISM = "false";
                  }
                } else {
                  let workarea = (CCode === "PWA" && (targetValue !== 'Worker'));
                  let contractorsName = (CCode === "CONTNME" && (targetValue !== 'CLM'));
                  let schemeName = (CCode === "SCHNME" && (targetValue !== 'SchemeTrainee' && targetValue !== 'SchemeApprentice' && targetValue !== 'ExperienceApprentice'));

                  attributeValObj[CCode].isDisable = (workarea || contractorsName || schemeName) ? true : false;
                  if(CCode === 'PWA'){
                    //attributeValObj['PWA'].isDisable = false;
                    attributeValObj['CONTNME'].isDisable = true;
                    attributeValObj['SCHNME'].isDisable = true; 

                    attributeValObj['CONTNME'].ISM = 'false';
                    attributeValObj['SCHNME'].ISM = 'false'; 
                  } else if(CCode === 'CONTNME'){
                    attributeValObj['PWA'].isDisable = false;
                    attributeValObj['CONTNME'].isDisable = false;
                    attributeValObj['SCHNME'].isDisable = true; 

                    attributeValObj['CONTNME'].ISM = 'true';
                    attributeValObj['SCHNME'].ISM = 'false'; 
                  } else if(CCode === 'SCHNME'){
                    attributeValObj['PWA'].isDisable = false;
                    attributeValObj['CONTNME'].isDisable = true;
                    attributeValObj['SCHNME'].isDisable = false; 

                    attributeValObj['CONTNME'].ISM = 'false';
                    attributeValObj['SCHNME'].ISM = 'true'; 
                  }
                  // attributeValObj[CCode].ISM = (CCode === "PWA" || CCode === "CONTNME" || CCode === "SCHNME")
                  // ? "false"
                  // : "true";

                  // if(CCode === 'PWA'){
                  //   attributeValObj['CONTNME'].ISM = (CCode === "PWA" || CCode === "CONTNME" || CCode === "SCHNME")
                  // ? "false"
                  // : "true";
                  // attributeValObj['SCHNME'].ISM = (CCode === "PWA" || CCode === "CONTNME" || CCode === "SCHNME")
                  // ? "false"
                  // : "true";
                  // }
                }
              } else {
                if (attributeValues[attribute]?.code.toString().toLowerCase() === PValue.toLowerCase()) {
                  //check condition
                  if (Flag === "disable") {
                    attributeValObj[CCode].isDisable = true;
                    attributeValObj[CCode].ISM = "false";
                  }
                } else {
                  attributeValObj[CCode].isDisable = false;
                  attributeValObj[CCode].ISM = "true";
                }
              }
            });
          }
        });
        setAttributeValidation((prev) => ({ ...prev, ...attributeValObj }));
        setIsValidateOnce(false);
      }
    }
  }, [attributesData, isOnce]);


  const action = {
    name: "Id",
    label: "Action",
    options: {
      filter: false,
      sort: false,
      display: true,
      setCellProps: () => ({
        style: { textAlign: "center" },
      }),
      setCellHeaderProps: () => ({
        style: { textAlign: "center" },
      }),
      customBodyRender: (value, tableMeta) => {
        let Id = tableMeta.tableData[tableMeta.rowIndex].Id;

        return (
          <div className="d-flex justify-content-center">
            {section.ISEDIT && (
              <Tooltip title="edit">
                <a
                  className="mr-2"
                  onClick={(e) => {
                    e.preventDefault();
                    setFormErrors({});
                    setCurrentGridId(Id);
                  }}>
                  <i className="fas fa-edit"></i>
                </a>
              </Tooltip>
            )}
            {section.ISDEL && (
              <Tooltip title="delete">
                <a
                  className="mr-2"
                  onClick={(e) => {
                    e.preventDefault();
                    setFormErrors({});
                    setDeleteGridId(Id);
                  }}>
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </a>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  };

  useEffect(() => {
    if (currentGridId) {
      let attributeSetData = ogFilledAttributeSet.find(
        (record) => record.Id === currentGridId
      );

      setAttributeSetData(attributeSetData);
    }
  }, [currentGridId]);

  useEffect(() => {
    if (deleteGridId && currentGridId === null) {
      //to disable grid delete button when edit is active
      setGridData((prev) => {
        const newState = prev.map((record) => {
          if (record.Id === deleteGridId) {
            return {
              ...record,
              IsActive: false,
            };
          }
          return record;
        });
        return newState;
      });

      setOgFilledAttributeSet((prev) => {
        const newState = prev.map((record) => {
          if (record.Id === deleteGridId) {
            return {
              ...record,
              IsActive: false,
            };
          }
          return record;
        });
        return newState;
      });

      setDisplayGrid((prev) =>
        prev.filter((record) => record.Id !== deleteGridId)
      );
    }
    setDeleteGridId(null);
  }, [deleteGridId]);

  const gridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    // count: gridData.length,
    rowsPerPage: gridData.length,
    // page: page,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",

    //standard | vertical | simple
    onChangeRowsPerPage: (num) => {
      //   setLimit(num);
      //   setNxtPgInfo("");
      //   setPrevPgInfo("");
      //   setIsPrevOrNext("");
    },
    // onSearchChange: (searchText) => {
    //   if (searchText !== null) {
    //     setSearchText(searchText);
    //   } else {
    //     setSearchText("");
    //   }
    // },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        // await setDashboardSortColumn(sortColumn);
        // await setDashboardSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        // await setDashboardSortColumn(sortColumn);
        // await setDashboardSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      // await setPage(page);
      // await setDashboardStart(page * dashboardPageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const IncgridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    // count: gridData.length,
    rowsPerPage: IncdisplayGrid.length,
    // page: page,
    serverSide: false,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",

    //standard | vertical | simple
    onChangeRowsPerPage: (num) => {
      //   setLimit(num);
      //   setNxtPgInfo("");
      //   setPrevPgInfo("");
      //   setIsPrevOrNext("");
    },
    // onSearchChange: (searchText) => {
    //   if (searchText !== null) {
    //     setSearchText(searchText);
    //   } else {
    //     setSearchText("");
    //   }
    // },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        // await setDashboardSortColumn(sortColumn);
        // await setDashboardSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        // await setDashboardSortColumn(sortColumn);
        // await setDashboardSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      // await setPage(page);
      // await setDashboardStart(page * dashboardPageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const dependentValidations = (targetValue, targetAttributeCode) => {
    let attributeValues = { ...attributesData }; // for checking values
    let attributeValObj = { ...attributeValidation };

    if (
      attributeValObj[targetAttributeCode].DD !== null &&
      attributeValObj[targetAttributeCode].DD.length > 0
    ) {
      let ad = attributeValObj[targetAttributeCode].DD;

      ad.forEach(async (record) => {
        let CCode = record.CCode;
        let PValue = record.PValue;
        let Condition = record.Condition;
        let Flag = record.Flag;
        let PDT = record.PDT;
        let CDT = record.CDT;
        let values = PValue.toLowerCase().toString().split(',');

        // if(targetValue.label === 'Worker' || targetValue.label === 'Associates'){
        if(targetValue.code === 'Worker'){
          CCode = 'PWA';
        } else if(targetValue.code === 'CLM'){
          CCode = "CONTNME";
        } else if(targetValue.code === 'SchemeTrainee' || targetValue.code === 'SchemeApprentice' || targetValue.code === 'ExperienceApprentice'){
          CCode = "SCHNME";
        }

        if (PDT === "DropdownSingle") {
          if (values.some(x => x === targetValue?.label.toString().toLowerCase())
          ) {
            //check condition
            if (Flag === "disable") {
              attributeValObj[CCode].isDisable = (CCode === "PWA") ? false : true;
              if(CCode === 'PWA'){
                attributeValObj['CONTNME'].isDisable = true;
                attributeValObj['SCHNME'].isDisable = true;
              } else if(CCode === 'CONTNME'){
                attributeValObj[CCode].isDisable = false; 
                attributeValObj['SCHNME'].isDisable = true;
                attributeValObj['PWA'].isDisable = false;
              } else if(CCode === 'SCHNME'){
                attributeValObj[CCode].isDisable = false;
                attributeValObj['PWA'].isDisable = false;
                attributeValObj['CONTNME'].isDisable = true;
              }
              attributeValObj[CCode].ISM = "false";
            }
          } else {
            let workarea = (CCode === "PWA" && (targetValue.code !== 'Worker'));
            let contractorsName = (CCode === "CONTNME" && (targetValue.code !== 'CLM' ));
            let schemeName = (CCode === "SCHNME" && (targetValue.code !== 'SchemeTrainee' && targetValue.code !== 'SchemeApprentice' && targetValue.code !== 'ExperienceApprentice'));

            attributeValObj[CCode].isDisable = (workarea || contractorsName || schemeName) ? true : false;
            if(CCode === 'PWA'){
              //attributeValObj['PWA'].isDisable = false;
              attributeValObj['CONTNME'].isDisable = true;
              attributeValObj['SCHNME'].isDisable = true; 

              attributeValObj['CONTNME'].ISM = 'false';
              attributeValObj['SCHNME'].ISM = 'false'; 
            } else if(CCode === 'CONTNME'){
              attributeValObj['PWA'].isDisable = false;
              attributeValObj['CONTNME'].isDisable = false;
              attributeValObj['SCHNME'].isDisable = true; 

              attributeValObj['CONTNME'].ISM = 'true';
              attributeValObj['SCHNME'].ISM = 'false'; 
            } else if(CCode === 'SCHNME'){
              attributeValObj['PWA'].isDisable = false;
              attributeValObj['CONTNME'].isDisable = true;
              attributeValObj['SCHNME'].isDisable = false; 

              attributeValObj['CONTNME'].ISM = 'false';
              attributeValObj['SCHNME'].ISM = 'true'; 
            }
            // attributeValObj[CCode].ISM = (CCode === "PWA" || CCode === "CONTNME" || CCode === "SCHNME")
            // ? "false"
            // : "true";
            // if(CCode === 'PWA'){
            //   attributeValObj['CONTNME'].ISM = (CCode === "PWA" || CCode === "CONTNME" || CCode === "SCHNME")
            // ? "false"
            // : "true";
            // attributeValObj['SCHNME'].ISM = (CCode === "PWA" || CCode === "CONTNME" || CCode === "SCHNME")
            // ? "false"
            // : "true";
            // }
          }
        } else {
          if (targetValue.toString().toLowerCase() === PValue.toLowerCase()) {
            //check condition
            if (Flag === "disable") {
              attributeValObj[CCode].isDisable = true;
              attributeValObj[CCode].ISM = "false";
            }
          } else {
            attributeValObj[CCode].isDisable = false;
            attributeValObj[CCode].ISM = "true";
          }
        }

        

        //clear values based to CDT
        attributeValues[CCode] =
          CDT === "DropdownSingle" ||
          CDT === "Date" ||
          CDT === "SearchableDropdownSingle"
            ? null
            : CDT === "Text" || CDT === "Number"
            ? ""
            : CDT === "Checkbox"
            ? false
            : "";
      });

      attributeValues[targetAttributeCode] = targetValue; //imp
      setFormErrors({});
      setAttributeValidation((prev) => ({ ...prev, ...attributeValObj }));
      setAttributesData((prev) => ({
        ...prev,
        ...attributeValues,
      }));
    }
  };

  const validateData = (Attribute) => {
    let errorObj = {};
    let isError = false;
    Attribute.forEach(async (subAttribute) => {
      if (subAttribute.AT === "Attribute") {
        const validation = JSON.parse(subAttribute.V);

        if (validation.ISM.toLowerCase() === "true") {
          if (subAttribute.DT === "Text") {
            if (attributeSetData[subAttribute.AC] === "") {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else if (
              attributeSetData[subAttribute.AC]?.length > validation.MaxC
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: `Max ${validation.MaxC} characters allowed`,
              };
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          } else if (
            subAttribute.DT === "DropdownSingle" ||
            subAttribute.DT === "SearchableDropdownSingle"
          ) {
            if (attributeSetData[subAttribute.AC] === null) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          } else if (subAttribute.DT === "Date") {
            if (attributeSetData[subAttribute.AC] === null) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else if (
              moment(
                validation.MaxV === "CurrentDate" &&
                  attributeSetData[subAttribute.AC]
              ).isAfter(new Date())
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Only till current date allowed",
              };
            } else if (
              moment(
                validation.MinV === "CurrentDate" &&
                  attributeSetData[subAttribute.AC]
              ).isBefore()
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Only after current date allowed",
              };
            } else if (
              validation.MinV !== undefined &&
              validation.MinV !== "CurrentDate" &&
              validation.MinV !== ""
            ) {
              let parentValue = attributeSetData[validation.MinV];
              if (
                parentValue !== null &&
                parentValue !== "" &&
                attributeSetData[subAttribute.AC] !== null &&
                attributeSetData[subAttribute.AC] !== "" &&
                moment(attributeSetData[subAttribute.AC]).isBefore(parentValue)
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Past date not allowed",
                };
              } else if (
                (parentValue === null || parentValue === "") &&
                attributeSetData[subAttribute.AC] !== null &&
                attributeSetData[subAttribute.AC] !== ""
              ) {
                errorObj = {
                  ...errorObj,
                  [validation.MinV]: "Required",
                };
              }
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          } else if (subAttribute.DT === "Number") {
            //change with regex
            if (attributeSetData[subAttribute.AC] === "") {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else if (
              // isNaN(
              //   attributeSetData[subAttribute.AC]
              // )
              !/^([0-9]+|0)$/.test(attributeSetData[subAttribute.AC])
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Only numbers are allowed",
              };
            } else if (
              Number(attributeSetData[subAttribute.AC]) > validation.MaxV
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: `Max ${validation.MaxV} allowed`,
              };
            } else if (
              Number(attributeSetData[subAttribute.AC]) < validation.MinV
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: `Min ${validation.MinV} required`,
              };
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          }
        } else {
          // for num and date

          if (subAttribute.DT === "Number") {
            if (attributeSetData[subAttribute.AC] !== "") {
              //change with regex
              if (
                // isNaN(
                //   attributeSetData[
                //     subAttribute.AC
                //   ]
                // )
                !/^([0-9]+|0)$/.test(attributeSetData[subAttribute.AC])
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Only numbers are allowed",
                };
              } else if (
                Number(attributeSetData[subAttribute.AC]) > validation.MaxV
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: `Max ${validation.MaxV} allowed`,
                };
              } else if (
                Number(attributeSetData[subAttribute.AC]) < validation.MinV
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: `Min ${validation.MinV} required`,
                };
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }
            }
          } else if (subAttribute.DT === "Date") {
            if (attributeSetData[subAttribute.AC] !== null) {
              if (
                moment(
                  validation.MaxV === "CurrentDate" &&
                    attributeSetData[subAttribute.AC]
                ).isAfter(new Date())
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Only till current date allowed",
                };
              } else if (
                moment(
                  validation.MinV === "CurrentDate" &&
                    attributeSetData[subAttribute.AC]
                ).isBefore()
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Only after current date allowed",
                };
              } else if (
                validation.MinV !== undefined &&
                validation.MinV !== "CurrentDate" &&
                validation.MinV !== ""
              ) {
                let parentValue = attributeSetData[validation.MinV];
                if (
                  parentValue !== null &&
                  parentValue !== "" &&
                  attributeSetData[subAttribute.AC] !== null &&
                  attributeSetData[subAttribute.AC] !== "" &&
                  moment(attributeSetData[subAttribute.AC]).isBefore(
                    parentValue
                  )
                ) {
                  errorObj = {
                    ...errorObj,
                    [subAttribute.AC]: "Past date not allowed",
                  };
                } else if (
                  (parentValue === null || parentValue === "") &&
                  attributeSetData[subAttribute.AC] !== null &&
                  attributeSetData[subAttribute.AC] !== ""
                ) {
                  errorObj = {
                    ...errorObj,
                    [validation.MinV]: "Required",
                  };
                }
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }
            }
          }
        }
      }
    });

    const isEmpty = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  
  const getSearchableDropdownAPI = async (
    searchString,
    AC,
    cb,
    valueForDropdown,
    type,
    gridId
  ) => {
    if (
      searchString === "" &&
      cb === null &&
      valueForDropdown !== "" &&
      AC !== ""
    ) {
      const response = await APICall(getSearchableDropdowns, "POST", {
        searchString,
        AC,
        valueForDropdown,
      });

      if (response.data !== null && response.data.length > 0) {
        if (response.data.length === 1) {
          if (gridId !== 0) {
            let ddObj = {
              gridId,
              AC,
              DDResponse: response.data[0],
            };
            setFillDDValuesForGrid((prev) => [...prev, ddObj]);
          } else {
            return response.data[0];
            //fillSearchableDDValuesOnEdit(response.data[0], AC);
          }
        }
      }
    } else if (searchString.length > 1) {
      const response = await APICall(getSearchableDropdowns, "POST", {
        searchString,
        AC,
      });
      if (response.data !== null && response.data.length > 0) {
        cb(response.data);
        setOptionsObj((prev) => ({ ...prev, [AC]: response.data }));
      } else {
        cb([]);
        setOptionsObj((prev) => ({ ...prev, [AC]: [] }));
      }
    }
  };

  // const getSearchableDropdownAPI = async (
  //   searchString,
  //   AC,
  //   cb,
  //   valueForDropdown
  // ) => {
  //   if (
  //     searchString === "" &&
  //     cb === null &&
  //     valueForDropdown !== "" &&
  //     AC !== ""
  //   ) {
  //     const response = await APICall(getSearchableDropdowns, "POST", {
  //       searchString,
  //       AC,
  //       valueForDropdown,
  //     });
  //     if (response.data !== null && response.data.length > 0) {
  //       if (response.data.length === 1) {
  //         await fillSearchableDDValuesOnEdit(response.data[0], AC);
  //       }
  //     }
  //   } else if (searchString.length > 1) {
  //     const response = await APICall(getSearchableDropdowns, "POST", {
  //       searchString,
  //       AC,
  //     });
  //     if (response.data !== null && response.data.length > 0) {
  //       cb(response.data);
  //     } else {
  //       cb([]);
  //     }
  //   }
  // };

  const getPPonIncm = async (AC, GenId) => {
    const response = await APICall(getParenPBasedonIncm, "POST", {
      AC,
      GenId,
    });
    if (response.data !== null) {
      return response.data;
    }
  };
  const fillSearchableDDValuesOnEdit = (DDResponse, AC) => {
    setAttributesData((prev) => ({
      ...prev,
      [AC]: { label: DDResponse.label, value: DDResponse.value },
    }));
  };
  const searchableDDValues = async(event, AC) => {
    let DD = event.DD;
    if (DD.length > 0) {
      let newAttributeData = { ...attributesData };
      let dropdownOptions = { ...optionsObj };

      // DD.forEach((attribute) => {
      //   const Code = attribute.Key;

      //   // check DT

      //   if (attribute.DT === "DropdownSingle") {
      //     const options = dropdownOptions[Code];

      //     if (options !== undefined && options.length > 0) {
      //       const option = options.find(
      //         (attri) => attri.value == attribute.Value
      //       );

      //       newAttributeData = {
      //         ...newAttributeData,
      //         [Code]: option === undefined ? null : option,
      //       };
      //     }
      //   } else {
      //     //check all DT
      //     newAttributeData = {
      //       ...newAttributeData,
      //       [Code]: attribute.Value,
      //     };
      //   }
      // });
      for (const attribute of DD) {
        const Code = attribute.Key;

        if (
          attribute.DT === "DropdownSingle" ||
          attribute.DT === "DropdownMultiple"
        ) {
          const options = await getDropdownsData(
            Code,
            ""
          );

          setOptionsObj((prev) => ({
            ...prev,
            [Code]: options,
          }));

          if (options !== undefined && options.length > 0) {
            const option = options.find(
              (attri) => attri.value == attribute.Value
            );

            newAttributeData = {
              ...newAttributeData,
              [Code]: option === undefined ? null : option,
            };
          }
        } else {
          //check all DT
          newAttributeData = {
            ...newAttributeData,
            [Code]: attribute.Value,
          };
        }
      }
      newAttributeData[AC] = event; //imp

      setAttributesData(newAttributeData);
    }
  };

  const closeConfirmationModal = async (confirmation) => {
    if (confirmationPromise) {
      if (confirmation) {
        setIsSync(confirmation)
        confirmationPromise.resolve(true);
      } else {
        setIsSync(confirmation)
        confirmationPromise.resolve(false);
      }
    }
    setConfirmationPromise(null);
    setShowConfirmationModal(false);
  };
  useEffect(()=>{
    if(TId!==null && TId!==0 ){
      (async()=>{const IncData = await APICall(getPositionIncumbentGridData,"POST",{POSId:TId})
      if(IncData?.data.length > 0){
        setIncDisplayGrid(IncData?.data);
      }})();
    }
  },[TId]);
  
  const IncgridColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        display: false,
      },
    },
    {
      name: "incmnm",
      label: "Incumbent",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        customBodyRender: (value: any, tableMeta: any) => {
          const { incmnm, incmid, employeeCode } = tableMeta?.tableData?.[tableMeta.rowIndex] || {};
          return `${employeeCode} - ${incmnm}`;
        }
        // setCellProps: () => ({ align: "center" }),
        // setCellHeaderProps: () => ({ style: { textAlign: "center" } }),
      },
    },
    {
      name: "incmfmdt",
      label: "From Date",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return(
            value !== null ?moment(value).format("DD-MM-YYYY") :""
          )
        }
        // setCellProps: () => ({ align: "center" }),
        // setCellHeaderProps: () => ({ style: { textAlign: "center" } }),
      },
    },
    {
      name: "incmtodt",
      label: "To Date",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return(
            <div style={{ textAlign : 'justify' }}>
              {value !== null ?moment(value).format("DD-MM-YYYY") :"-"}
            </div>
          )
        }
        // setCellProps: () => ({ align: "center" }),
        // setCellHeaderProps: () => ({ style: { textAlign: "center" } }),
      },
    },
  ];
  //   if (postAuditLog) {
  //     let oldAudit = [...oldAuditLog];
  //     let newAudit = [...newAuditLog];

  //     if (attributeSetCode && ogFilledAttributeSet.length > 0) {
  //       const newAuditLog = [];

  //       for (const attribute of ogFilledAttributeSet) {
  //         const shadowObj = { ...attribute };
  //         const auditObj = transformDynamicKey(shadowObj);

  //         const obj = {
  //           IsAttributeSet: true,
  //           Code: attributeSetCode,
  //           RecordId: attribute.Id,
  //           sectionId: section.SId.toString(),
  //           Attribute: [
  //             {
  //               ...auditObj,
  //             },
  //           ],
  //         };

  //         newAuditLog.push(obj);
  //       }

  //       newAudit = [...newAudit, ...newAuditLog];

  //       newAudit.forEach((newObj) => {
  //         if (newObj.hasOwnProperty("RecordId")) {
  //           const matchingObjIndex = oldAudit.findIndex(
  //             (obj1) => obj1.RecordId == newObj.RecordId
  //           );

  //           if (matchingObjIndex !== -1) {
  //           } else {
  //             // If not present, add the object to oldAudit arr
  //             let oldObj = JSON.parse(JSON.stringify(newObj));
  //             let mergedObj = oldObj.Attribute[0];
  //             oldObj.Attribute[0] = {
  //               ...mergedObj,
  //               ...ogAttributeSet,
  //               Id: 0,
  //               IsActive: false,
  //             };

  //             oldAudit.push({
  //               ...oldObj,
  //             });
  //           }
  //         }
  //       });
  //     }

  //     (async () => {
  //       const auditRes = await APICall(InsertAuditLog, "POST", {
  //         UserId: proxyUserDetails?.EmployeeId
  //           ? proxyUserDetails?.EmployeeId
  //           : userDetails.UserId,
  //         UserEmail: userDetails.EmailId,
  //         UserName: `${userDetails.FirstName} ${userDetails.LastName}`,
  //         OldValue: JSON.stringify(oldAudit),
  //         NewValue: JSON.stringify(newAudit),
  //         EId: TId,
  //       });
  //       await setOldAuditLog(newAudit);
  //       await setPostAuditLog(false);
  //       await navigate("/PositionManagementDashboard");
  //     })();
  //   }
  // }, [postAuditLog]);



  const handleOnChange = async (event,eachAttribute, sectionAttribute, index, attributes, options, ccflag = 0) => {
    let dynAttrData = {...attributes};
    let dynOptData = {...options};
    // setAttributesData((prev) => ({
    //   ...prev,
    //   [eachAttribute.AC]: event,
    // }));

    dynAttrData = {
      ...dynAttrData,
      [eachAttribute.AC]: event,
    }

    if (
      event !== null &&
      eachAttribute.CC !== null &&
      eachAttribute.CC !== ""
    ) {
      const CC = eachAttribute.CC.split(",");

      // CC.forEach(async (childDropdown) => {
      for (const childDropdown of CC){
        // hard code to get CUSBIS dropdown data
        let PId = "";
        if (
          (eachAttribute.AC === "PMBUSN" ||
            eachAttribute.AC === "PMSBU" ||
            eachAttribute.AC === "PMLEGEN") &&
          childDropdown === "PMCUSTB"
        ) {
          let BIS =
            eachAttribute.AC === "PMBUSN"
              ? event
                ? event.value.toString()
                : null
              // : attributesData["PMBUSN"] !==
              //   null
              // ? attributesData[
              //     "PMBUSN"
              //   ].value.toString()
              : dynAttrData["PMBUSN"] !==
                null
              ? dynAttrData[
                  "PMBUSN"
                ].value.toString()
              : null;
          let SBU =
            eachAttribute.AC === "PMSBU"
              ? event
                ? event.value.toString()
                : null
              // : attributesData["PMSBU"] !== null
              // ? attributesData[
              //     "PMSBU"
              //   ].value.toString()
              : dynAttrData["PMSBU"] !== null
              ? dynAttrData[
                  "PMSBU"
                ].value.toString()
              : null;
          let LEN =
            eachAttribute.AC === "PMLEGEN"
              ? event
                ? event.value.toString()
                : null
              // : attributesData["PMLEGEN"] !==
              //   null
              // ? attributesData[
              //     "PMLEGEN"
              //   ].value.toString()
              : dynAttrData["PMLEGEN"] !==
                null
              ? dynAttrData[
                  "PMLEGEN"
                ].value.toString()
              : null;
          if (BIS && SBU && LEN) {
            PId = `${BIS},${SBU},${LEN}`;
          }
        }
        if (
          eachAttribute.AC === "PMLEGEN" &&
          childDropdown === "PMLOCN"
        ) {
          let LEN =
            eachAttribute.AC === "PMLEGEN"
              ? event
                ? event.value.toString()
                : null
              // : attributesData["PMLEGEN"] !==
              //   null
              // ? attributesData[
              //     "PMLEGEN"
              //   ].value.toString()
              : dynAttrData["PMLEGEN"] !==
                null
              ? dynAttrData[
                  "PMLEGEN"
                ].value.toString()
              : null;

          if (LEN) {
            PId = `${LEN}`;
          }
        }
        /*Hardcoded for Job band */
        if (
          (eachAttribute.AC === "EMPLYCLS" ||
            eachAttribute.AC === "PMLEGEN") &&
          childDropdown === "PMJOBND"
        ) {
          let LEN =
            eachAttribute.AC === "PMLEGEN"
              ? event
                ? event.value.toString()
                : null
              // : attributesData["PMLEGEN"] !==
              //   null
              // ? attributesData[
              //     "PMLEGEN"
              //   ].value.toString()
              : dynAttrData["PMLEGEN"] !==
                null
              ? dynAttrData[
                  "PMLEGEN"
                ].value.toString()
              : null;
          let BIS =
            eachAttribute.AC === "EMPLYCLS"
              ? event
                ? event.value.toString()
                : null
              // : attributesData["EMPLYCLS"] !==
              //   null
              // ? attributesData[
              //     "EMPLYCLS"
              //   ].value.toString()
              : dynAttrData["EMPLYCLS"] !==
                null
              ? dynAttrData[
                  "EMPLYCLS"
                ].value.toString()
              : null;

          if (BIS && LEN) {
            PId = `${LEN},${BIS}`;
          }
        }

        // hard code to get work area dropdown options based on 
        //Business, Sub Business, Legal Entity and Payroll Location.
        if (
          (eachAttribute.AC === "PMBUSN" ||
            eachAttribute.AC === "PMSBU" ||
            eachAttribute.AC === "PMLEGEN" || 
            eachAttribute.AC === "PMLOCN") &&
          childDropdown === "PWA"
        ) {
          let BIS =
            eachAttribute.AC === "PMBUSN"
              ? event
                ? event.value.toString()
                : null
              // : attributesData["PMBUSN"] !==
              //   null
              // ? attributesData[
              //     "PMBUSN"
              //   ].value.toString()
              : dynAttrData["PMBUSN"] !==
                null
              ? dynAttrData[
                  "PMBUSN"
                ].value.toString()
              : null;
          let SBU =
            eachAttribute.AC === "PMSBU"
              ? event
                ? event.value.toString()
                : null
              // : attributesData["PMSBU"] !== null
              // ? attributesData[
              //     "PMSBU"
              //   ].value.toString()
              : dynAttrData["PMSBU"] !== null
              ? dynAttrData[
                  "PMSBU"
                ].value.toString()
              : null;
          let LEN =
            eachAttribute.AC === "PMLEGEN"
              ? event
                ? event.value.toString()
                : null
              // : attributesData["PMLEGEN"] !==
              //   null
              // ? attributesData[
              //     "PMLEGEN"
              //   ].value.toString()
              : dynAttrData["PMLEGEN"] !==
                null
              ? dynAttrData[
                  "PMLEGEN"
                ].value.toString()
              : null;
          let PLOC =
              eachAttribute.AC === "PMLOCN"
                ? event
                  ? event.value.toString()
                  : null
                // : attributesData["PMLOCN"] !==
                //   null
                // ? attributesData[
                //     "PMLOCN"
                //   ].value.toString()
                : dynAttrData["PMLOCN"] !==
                  null
                ? dynAttrData[
                    "PMLOCN"
                  ].value.toString()
                : null;    
          if (BIS && SBU && LEN & PLOC) {
            PId = `${BIS},${SBU},${LEN},${PLOC}`;
          }
        }
        
        // setAttributesData((prev) => ({
        //   ...prev,
        //   [childDropdown]: null,
        // }));
        dynAttrData = {
          ...dynAttrData,
          [childDropdown]: null,
        }

        if(childDropdown === "PMSBU" ||
          childDropdown === "PMLEGEN" ||
          childDropdown === "PMLOCN") 
        {
          childDropdown === "PMSBU"
          ? setSBU(null)
          : childDropdown === "PMLEGEN"
          ? setLEN(null)
          : setPLOC(null);
        }

        const options = await getDropdownsData(
          childDropdown,

          (event && childDropdown === "PMCUSTB") ||
          (event && childDropdown === "PMJOBND") ||
          (event && childDropdown === "PMLOCN")|| 
          (event && childDropdown === "PWA")
            ? PId
            : event
            ? event.value
            : ""
        );

        if (options.length === 1) {
          // setAttributesData((prev) => ({
          //   ...prev,
          //   [childDropdown]: options[0],
          // }));
          dynAttrData = {
            ...dynAttrData,
            [childDropdown]: options[0],
          }

          //If option is only one and there is a child attribute of the particular attribute
          //EX: EC = BrandStaff, JB = Band-F, JL = F3, SD = Assistant Foreman, CFT = Assistant Foreman
          const childAttr = section.Attribute.find((item)=> item?.AC === childDropdown);
          [dynAttrData, dynOptData] = await handleOnChange(options[0],childAttr, sectionAttribute, 0, dynAttrData, dynOptData, 1);
        }
        // setOptionsObj((prev) => ({
        //   ...prev,
        //   [childDropdown]: options,
        // }));

        dynOptData = {
          ...dynOptData,
          [childDropdown]: options,
        }
      }
      // );
    }

    if (
      eachAttribute.AC === "DESIGNTN" &&
      event != null
    ) {
      // setAttributesData((prev) => ({
      //   ...prev,
      //   ["CLNTFCNG"]: event.label,
      // }));
      dynAttrData = {
        ...dynAttrData,
        ["CLNTFCNG"]:  event.label,
      }
    } else if (
      eachAttribute.AC === "DESIGNTN" &&
      event == null
    ) {
      // setAttributesData((prev) => ({
      //   ...prev,
      //   ["CLNTFCNG"]: "",
      // }));
      dynAttrData = {
        ...dynAttrData,
        ["CLNTFCNG"]:  "",
      }
    } else if (
      eachAttribute.AC === "ISBDGT" &&
      event != null
    ) {
      event.label.toLowerCase() === "yes"
        ? setIsBudget(true)
        : setIsBudget(false);
    } else if (
      (eachAttribute.AC === "PMBUSN" ||
        eachAttribute.AC === "PMSBU" ||
        eachAttribute.AC === "PMLEGEN" ||
        eachAttribute.AC === "PMLOCN") &&
      event != null
    ) {
      eachAttribute.AC === "PMBUSN"
        ? setBIS(event.value)
        : eachAttribute.AC === "PMSBU"
        ? setSBU(event.value)
        : eachAttribute.AC === "PMLEGEN"
        ? setLEN(event.value)
        : setPLOC(event.value);
    } else if (
      (eachAttribute.AC === "PMBUSN" ||
        eachAttribute.AC === "PMSBU" ||
        eachAttribute.AC === "PMLEGEN" ||
        eachAttribute.AC === "PMLOCN") &&
      event === null
    ) {
      eachAttribute.AC === "PMBUSN"
        ? setBIS(null)
        : eachAttribute.AC === "PMSBU"
        ? setSBU(null)
        : eachAttribute.AC === "PMLEGEN"
        ? setLEN(null)
        : setPLOC(null);
    }

    dependentValidations(
      event,
      eachAttribute.AC
    );

    if (ccflag === 1){
      return [dynAttrData, dynOptData];
    }
    else if(ccflag === 0){
      setAttributesData((prev) => ({
        ...prev,
        ...dynAttrData,
      }));
      setOptionsObj((prev) => ({
        ...prev,
        ...dynOptData,
      }));
    }
  };

  return (
    <div className="col-lg-12">
      {section?.Attribute?.length > 0 && (
        <Accordion
          elevation={0}
          className="mb-3"
          expanded={accordion}
          onChange={() => setAccordion((prev) => !prev)}>
          <AccordionSummary
            id="profile-accordion"
            style={{ background: "#3C5464" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}>
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <p style={{ fontSize: "16px" }}>{section.SDN}</p>
              </div>
              {section.SN !== "incumbent" && 
              <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12">
                {/* <Tooltip title="clear">
                  <button
                    className="btn ml-lg-0 ml-md-0 ml-1"
                    disabled={!section.ISEDIT}
                    onClick={(e) => {
                      e.stopPropagation();
                      //clear data

                      section.Attribute.forEach(async (eachAttribute) => {
                        if (eachAttribute.AT === "Attribute") {
                          //change value according to dt

                          setAttributesData((prev) => ({
                            ...prev,
                            [eachAttribute.AC]:
                              eachAttribute.DT === "DropdownSingle" ||
                              eachAttribute.DT === "Date" ||
                              eachAttribute.DT === "SearchableDropdownSingle"
                                ? null
                                : eachAttribute.DT === "Text" ||
                                  eachAttribute.DT === "Number"
                                ? ""
                                : eachAttribute.DT === "Checkbox"
                                ? false
                                : "",
                          }));
                        } else if (eachAttribute.AT === "AttributeSet") {
                          setAttributeSetData(ogAttributeSet);
                        }
                      });

                      //clear error
                      setFormErrors({});
                    }}>
                    <i className="fas fa-times-circle"></i>
                  </button>
                </Tooltip> */}
                {section?.ISHISTORY && (
                  <Tooltip title="History">
                    <button
                      className="btn"
                      disabled={TId == 0 ? true : false}
                      onClick={(e) => {
                        e.stopPropagation();
                        viewSectionHistory({
                          TId,
                          sectionDetails: section,
                        });
                      }}>
                      <i className="fas fa-history"></i>
                    </button>
                  </Tooltip>
                )}
                {/*<Tooltip title="History">
                  <button
                    className="btn"
                    disabled={TId == 0 ? true : false}
                    onClick={(e) => {
                      e.stopPropagation();
                      viewSectionHistory({
                        TId,
                        sectionDetails: section,
                      });
                    }}>
                    <i className="fas fa-history"></i>
                  </button>
                </Tooltip>*/}
                <Tooltip title="submit">
                  <button
                    className="btn"
                    disabled={
                      disableState
                        ? disableState
                        : currentGridId !== null
                        ? true
                        : false || !section.ISEDIT
                    }
                    onClick={async (e) => {
                      e.stopPropagation();

                      if (TId === 0 && section.SN !== "EmployeePosition") {
                        notify(1, "Save Position Data First");
                      } else {
                        let errorObj = {};
                        section.Attribute.map((eachAttribute) => {
                          if (eachAttribute.AT === "Attribute") {
                            const validation =
                              attributeValidation[eachAttribute.AC];

                            if (validation.ISM.toLowerCase() === "true") {
                              if (eachAttribute.DT === "Text") {
                                if (attributesData[eachAttribute.AC] === "") {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "Required",
                                  };
                                } else if (
                                  attributesData[eachAttribute.AC]?.length >
                                  validation.MaxC
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: `Max ${validation.MaxC} characters allowed`,
                                  };
                                } else {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "",
                                  };
                                }
                              } else if (
                                eachAttribute.DT === "DropdownSingle" ||
                                eachAttribute.DT === "SearchableDropdownSingle"
                              ) {
                                if (attributesData[eachAttribute.AC] === null) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "Required",
                                  };
                                } else {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "",
                                  };
                                }
                              } else if (eachAttribute.DT === "Date") {
                                if (attributesData[eachAttribute.AC] === null) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "Required",
                                  };
                                } else if (
                                  moment(
                                    validation.MaxV === "CurrentDate" &&
                                      attributesData[eachAttribute.AC]
                                  ).isAfter(new Date())
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]:
                                      "Only till current date allowed",
                                  };
                                } else if (
                                  moment(
                                    validation.MinV === "CurrentDate" &&
                                      attributesData[eachAttribute.AC]
                                  ).isBefore()
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]:
                                      "Only after current date allowed",
                                  };
                                } else if (
                                  validation.MinV !== undefined &&
                                  validation.MinV !== "CurrentDate" &&
                                  validation.MinV !== ""
                                ) {
                                  let parentValue =
                                    attributesData[validation.MinV];
                                  if (
                                    parentValue !== null &&
                                    parentValue !== "" &&
                                    attributesData[eachAttribute.AC] !== null &&
                                    attributesData[eachAttribute.AC] !== "" &&
                                    moment(
                                      attributesData[eachAttribute.AC]
                                    ).isBefore(parentValue)
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]:
                                        "Past date not allowed",
                                    };
                                  } else if (
                                    (parentValue === null ||
                                      parentValue === "") &&
                                    attributesData[eachAttribute.AC] !== null &&
                                    attributesData[eachAttribute.AC] !== ""
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [validation.MinV]: "Required",
                                    };
                                  }
                                } 
                                // else if (
                                //   eachAttribute.AC === "PMEFFSTRTDTE" &&
                                //   attributesData["PMEFFSTRTDTE"] != null
                                //    && TId === 0
                                // ) {
                                //   const startDate = moment()
                                //     .month(3)
                                //     .date(1)
                                //     .startOf("day");
                                //   const endDate = moment().endOf("month");
                                //   if (
                                //     !moment(
                                //       attributesData[eachAttribute.AC]
                                //     ).isBetween(startDate, endDate, null, "[]")
                                //   ) {
                                //     errorObj = {
                                //       ...errorObj,
                                //       [eachAttribute.AC]: `Date should be between ${moment(
                                //         startDate
                                //       ).format("DD-MMM-YYYY")} and ${moment(
                                //         endDate
                                //       ).format("DD-MMM-YYYY")} `,
                                //     };
                                //   } else {
                                //     errorObj = {
                                //       ...errorObj,
                                //       [eachAttribute.AC]: "",
                                //     };
                                //   }
                                // } 
                                else {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "",
                                  };
                                }
                              } else if (eachAttribute.DT === "Number") {
                                //change with regex

                                if (attributesData[eachAttribute.AC] === "") {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "Required",
                                  };
                                } else if (
                                  // isNaN(attributesData[eachAttribute.AC])
                                  !/^([0-9]+|0)$/.test(
                                    attributesData[eachAttribute.AC]
                                  )
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]:
                                      "Only numbers are allowed",
                                  };
                                } else if (
                                  Number(attributesData[eachAttribute.AC]) >
                                  validation.MaxV
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: `Max ${validation.MaxV} allowed`,
                                  };
                                } else if (
                                  Number(attributesData[eachAttribute.AC]) <
                                  validation.MinV
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: `Min ${validation.MinV} required`,
                                  };
                                } else {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "",
                                  };
                                }
                              }
                            } else {
                              // for num and date

                              if (eachAttribute.DT === "Number") {
                                if (attributesData[eachAttribute.AC] !== "") {
                                  //change with regex
                                  if (
                                    // isNaN(attributesData[eachAttribute.AC])
                                    !/^([0-9]+|0)$/.test(
                                      attributesData[eachAttribute.AC]
                                    )
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]:
                                        "Only numbers are allowed",
                                    };
                                  } else if (
                                    Number(attributesData[eachAttribute.AC]) >
                                    validation.MaxV
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: `Max ${validation.MaxV} allowed`,
                                    };
                                  } else if (
                                    Number(attributesData[eachAttribute.AC]) <
                                    validation.MinV
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: `Min ${validation.MinV} required`,
                                    };
                                  } else {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "",
                                    };
                                  }
                                }
                              } else if (eachAttribute.DT === "Date") {
                                if (attributesData[eachAttribute.AC] !== null) {
                                  if (
                                    moment(
                                      validation.MaxV === "CurrentDate" &&
                                        attributesData[eachAttribute.AC]
                                    ).isAfter(new Date())
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]:
                                        "Only till current date allowed",
                                    };
                                  } else if (
                                    moment(
                                      validation.MinV === "CurrentDate" &&
                                        attributesData[eachAttribute.AC]
                                    ).isBefore()
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]:
                                        "Only after current date allowed",
                                    };
                                  } else if (
                                    validation.MinV !== undefined &&
                                    validation.MinV !== "CurrentDate" &&
                                    validation.MinV !== ""
                                  ) {
                                    let parentValue =
                                      attributesData[validation.MinV];
                                    if (
                                      parentValue !== null &&
                                      parentValue !== "" &&
                                      attributesData[eachAttribute.AC] !==
                                        null &&
                                      attributesData[eachAttribute.AC] !== "" &&
                                      moment(
                                        attributesData[eachAttribute.AC]
                                      ).isBefore(parentValue)
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]:
                                          "Past date not allowed",
                                      };
                                    } else if (
                                      (parentValue === null ||
                                        parentValue === "") &&
                                      attributesData[eachAttribute.AC] !==
                                        null &&
                                      attributesData[eachAttribute.AC] !== ""
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [validation.MinV]: "Required",
                                      };
                                    }
                                  } else {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "",
                                    };
                                  }
                                }
                              }
                            }
                          } else {
                            //submit attributeSet
                          }
                        });

                        const isEmpty = Object.values(errorObj).every(
                          (s) => s === ""
                        );
                        
                        if(TId === 0){ 
                          //old uncomment for UAT
                          // if(
                          //   (Object.keys(allApprovers).length > 1) || 
                          //   ( ['CHDL','LR','OHDC'].includes(attributesData?.["PMLOCN"]?.code) ) 
                          // ){ 
                          //old uncomment for UAT

                          //New uncomment for PROD
                          if(
                            (Object.keys(allApprovers).length >= 0) || 
                            (['CHDL','LR','OHDC'].includes(attributesData?.["PMLOCN"]?.code))
                          ){ 
                          //New uncomment for PROD
                          } else {
                            notify(1,"No approvers found.");
                            return null;
                          }
                        }

                        if (isEmpty && attributeSetCode === null) {
                            // post data attribute
                            const postAttributes = [];

                            Object.entries(attributesData).forEach(
                              async (attributeData) => {
                                const [Code, Value]: any[] = attributeData;

                                let attribute = {
                                  Type: "Attribute",
                                  Code,
                                  Value:
                                    typeof Value === "object"
                                      ? Value?.value.toString() || null
                                      : typeof Value === "string" ||
                                        typeof Value === "boolean"
                                      ? Value
                                      : null,
                                };
                                postAttributes.push(attribute);
                              }
                            );
                            debugger;
                            let sync = true;
                            if (
                              section.SN === "EmployeePosition" &&
                              TId != 0 &&
                              workflowStatus === "Approved" 
                              && PositionHolder != null
                              && attributesData["VCANSTAT"].label !=="Vacant" 
                            ) {
                              sync = await new Promise((resolve, reject) => {
                                setShowConfirmationModal(true);
                                setConfirmationPromise({ resolve, reject });
                              });
                            }

                            if(sync){
                              
                              await showLoader();
                              let postObj = {
                                MN: "PositionManagement",
                                IN: "PositionManagement_Form",
                                TN: formData.tn,
                                SN: section.SN,
                                TId: TId || 0,
                                Attributes: postAttributes,
                                UserId: userDetails.Id,
                                UserEmailId: userDetails.EmailId,
                                UserName: userDetails.FirstName,
                                RoleId: currentRoleId?.value,
                                COU: COU,
                                IsSync: sync,
                                InitiatorId: parseInt(userDetails.Id),
                                InitiatorRoleId: currentRoleId?.value,
                                EmployeeId: userDetails.EmployeeId,
                                ProxyUserId:
                                proxyUserDetails === null
                                ? null
                                : proxyUserDetails?.value,
                              };
                              
                              const postRes = await APICall(
                                postAttributeValueDetailsV2,
                                "POST",
                                postObj
                              );

                              if (
                                postRes.data !== null &&
                                section.SN === "EmployeePosition" &&
                                postRes.data.positionId !== undefined
                              ) {
                                setTId(postRes.data.positionId);
                              }
                              
                              //audit
                              let PosRemarkForActionHistory = '';
                              const dataForAuditLog = [];
                              if (Object.keys(attributesData).length > 0) {
                                Object.entries(attributesData).forEach((data) => {
                                  const [key, value]: any[] = data;

                                  const auditLogObj = {
                                    sectionId: section.SId.toString(),
                                    IsAttributeSet: false,
                                    Code: key,
                                    Attribute: [
                                      {
                                        [key]: value?.label || value,
                                      },
                                    ],
                                  };
                                  dataForAuditLog.push(auditLogObj);

                                  if (key === 'PMRMK'){
                                    PosRemarkForActionHistory = value;
                                  }
                                });
                              }

                              setNewAuditLog((prev) => [...dataForAuditLog]);

                              //post audit log & newHire event
                              if (postRes.status === 0) {
                                setPostAuditLog(true);
                                let oldAudit = [...oldAuditLog];
                                let newAudit = [...dataForAuditLog];

                                if (attributeSetCode && ogFilledAttributeSet.length > 0) {
                                  const newAuditLog = [];

                                  for (const attribute of ogFilledAttributeSet) {
                                    const shadowObj = { ...attribute };
                                    const auditObj = transformDynamicKey(shadowObj);

                                    const obj = {
                                      IsAttributeSet: true,
                                      Code: attributeSetCode,
                                      RecordId: attribute.Id,
                                      sectionId: section.SId.toString(),
                                      Attribute: [
                                        {
                                          ...auditObj,
                                        },
                                      ],
                                    };

                                    newAuditLog.push(obj);
                                  }

                                  newAudit = [...newAudit, ...newAuditLog];

                                  newAudit.forEach((newObj) => {
                                    if (newObj.hasOwnProperty("RecordId")) {
                                      const matchingObjIndex = oldAudit.findIndex(
                                        (obj1) => obj1.RecordId == newObj.RecordId
                                      );

                                      if (matchingObjIndex !== -1) {
                                      } else {
                                        // If not present, add the object to oldAudit arr
                                        let oldObj = JSON.parse(JSON.stringify(newObj));
                                        let mergedObj = oldObj.Attribute[0];
                                        oldObj.Attribute[0] = {
                                          ...mergedObj,
                                          ...ogAttributeSet,
                                          Id: 0,
                                          IsActive: false,
                                        };

                                        oldAudit.push({
                                          ...oldObj,
                                        });
                                      }
                                    }
                                  });
                                }
                                const auditRes = await APICall(InsertAuditLog, "POST", {
                                  UserId: proxyUserDetails?.EmployeeId
                                    ? proxyUserDetails?.EmployeeId
                                    : userDetails.UserId,
                                  UserEmail: userDetails.EmailId,
                                  UserName: `${userDetails.FirstName} ${userDetails.LastName}`,
                                  OldValue: JSON.stringify(oldAudit),
                                  NewValue: JSON.stringify(newAudit),
                                  TransactionId:0, //Request Details Id 
                                  PId: TId!==0?TId:postRes.data.positionId,
                                  PosRemarkForActionHistory,
                                });
                                await setOldAuditLog(newAudit);
                                await setPostAuditLog(false);
                                //await navigate("/PositionManagementDashboard");
                              }
                              await hideLoader();
                              debugger;
                              if(postRes.status == 0){
                                //Check if entry in change log
                                let changelogchangespresent = false;
                                const changelogentrycheck = await APICall(
                                  checkIfEntryInAuditLogForPosition,
                                  "POST",
                                  { Id: postRes.data.positionId }
                                );
                                if (changelogentrycheck.status === 0 && changelogentrycheck.data === true) {
                                  changelogchangespresent = true;
                                  notify(postRes.status,`Position Information is Submitted Successfully for ${postRes.data.fn}`);
                                  await navigate("/PositionManagementDashboard");
                                } else if (changelogentrycheck.status === 0 && changelogentrycheck.data === false) {
                                  changelogchangespresent = false;
                                  notify(1, 'No Changes found.');
                                } else {
                                  hideLoader();
                                  changelogchangespresent = true;
                                  notify(postRes.status,"Position Information is Submitted Successfully");
                                  notify(1, "Check entry in change log exists failed!");
                                  await navigate("/PositionManagementDashboard");
                                }
                              }
                              else{
                                notify(postRes.status, postRes.message);
                                await navigate("/PositionManagementDashboard");
                              }                              
                            }
                        } else if (isEmpty && attributeSetCode !== null) {
                            await showLoader();
                            // post data attributeSet

                            let details = [...ogFilledAttributeSet];

                            const finalDetails = [];
                            details.forEach(async (attributes) => {
                              let attributeObj = {};
                              Object.keys(attributes).forEach(
                                async (attribute) => {
                                  attributeObj = {
                                    ...attributeObj,
                                    [attribute]:
                                      typeof attributes[attribute] === "object"
                                        ? attributes[
                                            attribute
                                          ]?.value.toString() || null
                                        : typeof attributes[attribute] ===
                                            "string" ||
                                          typeof attributes[attribute] ===
                                            "boolean"
                                        ? attributes[attribute]
                                        : null,
                                  };

                                  if (
                                    attribute === "Id" &&
                                    isNaN(Number(attributes[attribute]))
                                  ) {
                                    attributeObj = {
                                      ...attributeObj,
                                      [attribute]: 0,
                                    };
                                  }
                                }
                              );
                              finalDetails.push(attributeObj);
                            });

                            let postObj = {
                              MN: "PositionManagement",
                              IN: "PositionManagement_Form",
                              TN: formData.tn,
                              SN: section.SN,
                              TId: TId || 0,
                              Attributes: [
                                {
                                  Code: attributeSetCode,
                                  Value: "",
                                  Type: "AttributeSet",
                                  Details: finalDetails,
                                },
                              ],
                              UserId: userDetails.Id,
                              UserEmailId: userDetails.EmailId,
                              UserName: userDetails.FirstName,
                              RoleId: currentRoleId?.value,
                              COU: COU,
                              ProxyUserId:
                              proxyUserDetails === null
                                ? null
                                : proxyUserDetails?.value,
                            };

                            const postRes = await APICall(
                              postAttributeValueDetailsV2,
                              "POST",
                              postObj
                            );

                            await hideLoader();
                            notify(postRes.status, postRes.message);
                            navigate("/PositionManagementDashboard");
                        }
                      
                        setFormErrors((err) => ({
                          ...err,
                          ...errorObj,
                        }));

                      }
                    }}>
                    <i className="fa fa-save"></i>
                  </button>
                </Tooltip>
              </div>}
            </div>
          </AccordionSummary>

          <AccordionDetails className="page_heading">
            <div className="row">
              {section.Attribute.length > 0 &&
                section.Attribute.map((eachAttribute, index) => (
                  <React.Fragment key={`${index}-${eachAttribute.AC}`}>
                    {eachAttribute.AT === "Attribute" ? (
                      <>
                        {eachAttribute.DT === "DropdownSingle" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }>
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <SelectForm
                                  isClearable
                                  isSearchable
                                  options={
                                    optionsObj[eachAttribute.AC]
                                      ? optionsObj[eachAttribute.AC]
                                      : []
                                  }
                                  placeholder={eachAttribute.AN}
                                  isDisabled={
                                    disableState
                                      ? disableState
                                      : attributesAreAvailableForEdit ? false
                                      :eachAttribute.AC ==="POSKMP" && currentRoleId.code !=="HRHead" ? true
                                      : attributeValidation[eachAttribute.AC]
                                          ?.isDisable ||
                                        !section.ISEDIT ||
                                        eachAttribute.AC === "PARENPOS" ||
                                        eachAttribute.AC === "VCANSTAT" ||
                                        eachAttribute.AC === "PMCUSTB"
                                  }
                                  value={
                                    attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : null
                                  }
                                  onChange={(event) => {
                                    handleOnChange(event,eachAttribute, section.Attribute, index, attributesData, optionsObj,0);
                                    // setAttributesData((prev) => ({
                                    //   ...prev,
                                    //   [eachAttribute.AC]: event,
                                    // }));

                                    // if (
                                    //   event !== null &&
                                    //   eachAttribute.CC !== null &&
                                    //   eachAttribute.CC !== ""
                                    // ) {
                                    //   const CC = eachAttribute.CC.split(",");

                                    //   CC.forEach(async (childDropdown) => {
                                    //     // hard code to get CUSBIS dropdown data
                                    //     let PId = "";
                                    //     if (
                                    //       (eachAttribute.AC === "PMBUSN" ||
                                    //         eachAttribute.AC === "PMSBU" ||
                                    //         eachAttribute.AC === "PMLEGEN") &&
                                    //       childDropdown === "PMCUSTB"
                                    //     ) {
                                    //       let BIS =
                                    //         eachAttribute.AC === "PMBUSN"
                                    //           ? event
                                    //             ? event.value.toString()
                                    //             : null
                                    //           : attributesData["PMBUSN"] !==
                                    //             null
                                    //           ? attributesData[
                                    //               "PMBUSN"
                                    //             ].value.toString()
                                    //           : null;
                                    //       let SBU =
                                    //         eachAttribute.AC === "PMSBU"
                                    //           ? event
                                    //             ? event.value.toString()
                                    //             : null
                                    //           : attributesData["PMSBU"] !== null
                                    //           ? attributesData[
                                    //               "PMSBU"
                                    //             ].value.toString()
                                    //           : null;
                                    //       let LEN =
                                    //         eachAttribute.AC === "PMLEGEN"
                                    //           ? event
                                    //             ? event.value.toString()
                                    //             : null
                                    //           : attributesData["PMLEGEN"] !==
                                    //             null
                                    //           ? attributesData[
                                    //               "PMLEGEN"
                                    //             ].value.toString()
                                    //           : null;
                                    //       if (BIS && SBU && LEN) {
                                    //         PId = `${BIS},${SBU},${LEN}`;
                                    //       }
                                    //     }
                                    //     if (
                                    //       eachAttribute.AC === "PMLEGEN" &&
                                    //       childDropdown === "PMLOC"
                                    //     ) {
                                    //       let LEN =
                                    //         eachAttribute.AC === "PMLEGEN"
                                    //           ? event
                                    //             ? event.value.toString()
                                    //             : null
                                    //           : attributesData["PMLEGEN"] !==
                                    //             null
                                    //           ? attributesData[
                                    //               "PMLEGEN"
                                    //             ].value.toString()
                                    //           : null;

                                    //       if (LEN) {
                                    //         PId = `${LEN}`;
                                    //       }
                                    //     }
                                    //     /*Hardcoded for Job band */
                                    //     if (
                                    //       (eachAttribute.AC === "EMPLYCLS" ||
                                    //         eachAttribute.AC === "PMLEGEN") &&
                                    //       childDropdown === "PMJOBND"
                                    //     ) {
                                    //       let LEN =
                                    //         eachAttribute.AC === "PMLEGEN"
                                    //           ? event
                                    //             ? event.value.toString()
                                    //             : null
                                    //           : attributesData["PMLEGEN"] !==
                                    //             null
                                    //           ? attributesData[
                                    //               "PMLEGEN"
                                    //             ].value.toString()
                                    //           : null;
                                    //       let BIS =
                                    //         eachAttribute.AC === "EMPLYCLS"
                                    //           ? event
                                    //             ? event.value.toString()
                                    //             : null
                                    //           : attributesData["EMPLYCLS"] !==
                                    //             null
                                    //           ? attributesData[
                                    //               "EMPLYCLS"
                                    //             ].value.toString()
                                    //           : null;

                                    //       if (BIS && LEN) {
                                    //         PId = `${LEN},${BIS}`;
                                    //       }
                                    //     }
                                    //     setAttributesData((prev) => ({
                                    //       ...prev,
                                    //       [childDropdown]: null,
                                    //     }));

                                    //     const options = await getDropdownsData(
                                    //       childDropdown,

                                    //       (event &&
                                    //         childDropdown === "PMCUSTB") ||
                                    //         (event &&
                                    //           childDropdown === "PMJOBND") ||
                                    //         (event && childDropdown === "PMLOC")
                                    //         ? PId
                                    //         : event
                                    //         ? event.value
                                    //         : ""
                                    //     );

                                    //     if (options.length === 1) {
                                    //       setAttributesData((prev) => ({
                                    //         ...prev,
                                    //         [childDropdown]: options[0],
                                    //       }));
                                    //     }
                                    //     setOptionsObj((prev) => ({
                                    //       ...prev,
                                    //       [childDropdown]: options,
                                    //     }));
                                    //   });
                                    // }

                                    // if (
                                    //   eachAttribute.AC === "DESIGNTN" &&
                                    //   event != null
                                    // ) {
                                    //   setAttributesData((prev) => ({
                                    //     ...prev,
                                    //     ["CLNTFCNG"]: event.label,
                                    //   }));
                                    // } else if (
                                    //   eachAttribute.AC === "DESIGNTN" &&
                                    //   event == null
                                    // ) {
                                    //   setAttributesData((prev) => ({
                                    //     ...prev,
                                    //     ["CLNTFCNG"]: "",
                                    //   }));
                                    // } else if (
                                    //   eachAttribute.AC === "ISBDGT" &&
                                    //   event != null
                                    // ) {
                                    //   event.label.toLowerCase() === "yes"
                                    //     ? setIsBudget(true)
                                    //     : setIsBudget(false);
                                    // } else if (
                                    //   (eachAttribute.AC === "PMBUSN" ||
                                    //     eachAttribute.AC === "PMSBU" ||
                                    //     eachAttribute.AC === "PMLEGEN" ||
                                    //     eachAttribute.AC === "PMLOCN") &&
                                    //   event != null
                                    // ) {
                                    //   eachAttribute.AC === "PMBUSN"
                                    //     ? setBIS(event.value)
                                    //     : eachAttribute.AC === "PMSBU"
                                    //     ? setSBU(event.value)
                                    //     : eachAttribute.AC === "PMLEGEN"
                                    //     ? setLEN(event.value)
                                    //     : setPLOC(event.value);
                                    // }

                                    // dependentValidations(
                                    //   event,
                                    //   eachAttribute.AC
                                    // );
                                  }}
                                  isMulti={false}
                                  noIndicator={false}
                                  noSeparator={false}
                                />
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "Text" ||
                          eachAttribute.DT === "Number" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }>
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <InputForm
                                  className="form-control"
                                  placeholder={eachAttribute.AN}
                                  isDisabled={
                                    disableState
                                      ? disableState
                                      : attributesAreAvailableForEdit ? false
                                      : attributeValidation[eachAttribute.AC]
                                          ?.isDisable ||
                                        !section.ISEDIT ||
                                        eachAttribute.AC === "INCMNM" ||
                                        eachAttribute.AC === "PMJOTTL" ||
                                        eachAttribute.AC === "FTE"
                                      ? true
                                      : false
                                  }
                                  textArea={false}
                                  value={
                                    attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : ""
                                  }
                                  onChange={(e) => {
                                    setAttributesData((prev) => ({
                                      ...prev,
                                      [eachAttribute.AC]: e.target.value,
                                    }));

                                    dependentValidations(
                                      e.target.value,
                                      eachAttribute.AC
                                    );
                                  }}
                                  maxLength="255"
                                />
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "Date" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }>
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <DateForm
                                  isDisabled={
                                    disableState
                                      ? disableState
                                      : attributesAreAvailableForEdit ? false
                                      : eachAttribute.AC === "PMEFFSTRTDTE" && TId > 0 ? true  
                                      :  eachAttribute.AC === "PMEFDT" && TId === 0 ? true  
                                      : attributeValidation[eachAttribute.AC]
                                          ?.isDisable ||
                                        !section.ISEDIT ||
                                        eachAttribute.AC === "INCMFMDT" ||
                                        eachAttribute.AC === "INCMTODT" 
                                        
                                  }
                                  value={
                                    attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : null
                                  }
                                  onChange={(date) => {
                                    //------New -> 
                                    // to update effective date also when creation date is changed
                                    if(eachAttribute.AC === "PMEFFSTRTDTE"){
                                      setAttributesData((prev) => ({
                                        ...prev,
                                        [eachAttribute.AC]:
                                          moment(date).format("DD-MMM-YYYY"),
                                        ["PMEFDT"]:
                                          moment(date).format("DD-MMM-YYYY"),
                                      }));
                                    } else {
                                      if(eachAttribute.AC === "PMEFDT"){
                                        const CreationDate = moment(attributesData["PMEFFSTRTDTE"]);
                                        if (moment(date).isBefore(CreationDate)){
                                          const createObj = section.Attribute.find(eachAttr  => eachAttr.AC === 'PMEFFSTRTDTE');
                                          notify(1,`${eachAttribute.AN} cannot be less ${createObj.AN}.`);
                                          return;
                                        }
                                      }
                                      setAttributesData((prev) => ({
                                        ...prev,
                                        [eachAttribute.AC]:
                                          moment(date).format("DD-MMM-YYYY"),
                                      }));
                                    }
                                    //------New

                                    //------OLD
                                    // setAttributesData((prev) => ({
                                    //   ...prev,
                                    //   [eachAttribute.AC]:
                                    //     moment(date).format("DD-MMM-YYYY"),
                                    // }));
                                    //------OLD

                                    dependentValidations(
                                      moment(date).format("DD-MMM-YYYY"),
                                      eachAttribute.AC
                                    );
                                  }}
                                />
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "Checkbox" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }>
                              <div className="mb-1">
                                <label
                                  htmlFor={eachAttribute.AC}
                                  className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <div>
                                  <input
                                    disabled={
                                      disableState
                                        ? disableState
                                        : attributesAreAvailableForEdit ? false
                                        : attributeValidation[eachAttribute.AC]
                                            ?.isDisable || !section.ISEDIT
                                    }
                                    type="checkbox"
                                    onChange={(e) => {
                                      setAttributesData((prev) => ({
                                        ...prev,
                                        [eachAttribute.AC]: e.target.checked,
                                      }));

                                      dependentValidations(
                                        e.target.checked,
                                        eachAttribute.AC
                                      );
                                    }}
                                    id={eachAttribute.AC}
                                    checked={
                                      attributesData[eachAttribute.AC]
                                        ? attributesData[eachAttribute.AC]
                                        : false
                                    }
                                  />
                                </div>
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "Textarea" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }>
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <InputForm
                                  className="form-control"
                                  placeholder={eachAttribute.AN}
                                  isDisabled={
                                    disableState
                                      ? disableState
                                      : attributesAreAvailableForEdit ? false
                                      : attributeValidation[eachAttribute.AC]
                                          ?.isDisable || !section.ISEDIT
                                  }
                                  textArea={true}
                                  value={
                                    attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : ""
                                  }
                                  onChange={(e) => {
                                    setAttributesData((prev) => ({
                                      ...prev,
                                      [eachAttribute.AC]: e.target.value,
                                    }));

                                    dependentValidations(
                                      e.target.value,
                                      eachAttribute.AC
                                    );
                                  }}
                                  maxLength="255"
                                />
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "Document1" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }>
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <div className="box position-relative">
                                  <input
                                    id="files-8"
                                    className="form-control inputfile inputfile-6 multiple-inputfile"
                                    data-multiple-caption="{count} files selected"
                                    multiple
                                    type="file"
                                  />
                                  <label
                                    htmlFor="files-8"
                                    className="form-control">
                                    <span></span>{" "}
                                    <strong>
                                      <i
                                        className="fa fa-paperclip rotate90"
                                        aria-hidden="true"></i>{" "}
                                      Browse
                                    </strong>{" "}
                                  </label>
                                </div>
                                <div className="file-added-list">
                                  <ul className="list-unstyle">
                                    <li className="list mt-1">
                                      <div className="media">
                                        <div className="media-body text-truncate">
                                          <a
                                            href="javascript:void(0);"
                                            className="view-more">
                                            Archiv1_b.webp
                                          </a>
                                        </div>
                                        <div className="media-right ml-2">
                                          <i
                                            className="fa fa-trash"
                                            aria-hidden="true"></i>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="list mt-1">
                                      <div className="media">
                                        <div className="media-body text-truncate">
                                          <a
                                            href="javascript:void(0);"
                                            className="view-more">
                                            04.png
                                          </a>
                                        </div>
                                        <div className="media-right ml-2">
                                          <i
                                            className="fa fa-trash"
                                            aria-hidden="true"></i>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "RichText" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }>
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>
                                <RTE
                                  content={
                                    attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : ""
                                  }
                                  disabled={
                                    disableState
                                      ? disableState
                                      : attributesAreAvailableForEdit ? false
                                      : !section.ISEDIT
                                  }
                                  onblur={(e) => {
                                    setAttributesData((prev) => ({
                                      ...prev,
                                      [eachAttribute.AC]: e.target?.value,
                                    }));

                                    dependentValidations(
                                      e.target?.value,
                                      eachAttribute.AC
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "SearchableDropdownSingle" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                )?.IFR?.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }>
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM?.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <SelectForm
                                  isClearable
                                  isSearchable
                                  async
                                  options={(searchString, cb) =>
                                    // getSearchableDropdownAPI(
                                    //   searchString,
                                    //   eachAttribute.AC,
                                    //   cb,
                                    //   ""
                                    // )
                                    getSearchableDropdownAPI(
                                      searchString,
                                      eachAttribute.AC,
                                      cb,
                                      "",
                                      "attribute",
                                      0
                                    )
                                  }
                                  placeholder={eachAttribute.AN}
                                  isDisabled={
                                    disableState
                                      ? disableState
                                      : attributesAreAvailableForEdit ? false
                                      : attributeValidation[eachAttribute.AC]
                                          ?.isDisable ||
                                        !section.ISEDIT ||
                                        eachAttribute.AC === "INCMPAPO" ||
                                        eachAttribute.AC === "INCMID"
                                    //   ?.isDisable ||
                                    // !section.ISEDIT ||
                                    // eachAttribute.AC === "INCMPAPO"
                                  }
                                  value={
                                    attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : null
                                  }
                                  onChange={async (event) => {
                                    setAttributesData((prev) => ({
                                      ...prev,
                                      [eachAttribute.AC]: event,
                                    }));

                                    /*Commented below code bcoz now we are getting INCMPAPO on Basis of PARENPOS*/
                                    // if (
                                    //   eachAttribute.AC === "INCMPAPO" &&
                                    //   event !== null
                                    // ) {
                                    //   const ddEvent = await getPPonIncm(
                                    //     eachAttribute.AC,
                                    //     event.value
                                    //   );
                                    //   if (ddEvent !== undefined) {
                                    //     if (
                                    //       ddEvent.value !== null &&
                                    //       ddEvent.label !== null
                                    //     ) {
                                    //       const ac = "PARENPOS";
                                    //       setAttributesData((prev) => ({
                                    //         ...prev,
                                    //         [ac]: ddEvent,
                                    //       }));
                                    //       // changeIncmName(event.label);
                                    //       // updateIncmChange(1);
                                    //     }
                                    //   }
                                    // }
                                    // if (
                                    //   eachAttribute.AC === "INCMPAPO" &&
                                    //   event === null
                                    // ) {
                                    //   const ac = "PARENPOS";
                                    //   setAttributesData((prev) => ({
                                    //     ...prev,
                                    //     [ac]: event,
                                    //   }));
                                    // }

                                    if (
                                      eachAttribute.AC === "PMJOCD" &&
                                      event === null
                                    ) {
                                      setAttributesData((prev) => ({
                                        ...prev,
                                        ["PMJOTTL"]: "",
                                      }));
                                    }

                                    if (
                                      eachAttribute.AC === "PARENPOS" &&
                                      event !== null
                                    ) {
                                      const ddEvent = await getPPonIncm(
                                        eachAttribute.AC,
                                        event.value
                                      );
                                      if (ddEvent !== undefined) {
                                        if (
                                          ddEvent.value !== null &&
                                          ddEvent.label !== null
                                        ) {
                                          const ac = "INCMPAPO";
                                          setAttributesData((prev) => ({
                                            ...prev,
                                            [ac]: ddEvent,
                                          }));
                                          // if(attributesData["FTE"] !== null && attributesData["FTE"] !== undefined ){
                                          //   setAttributesData((prev) => ({
                                          //     ...prev,
                                          //     ["FTE"]: "1",
                                          //   }));
                                          // }
                                          // changeIncmName(event.label);
                                          // updateIncmChange(1);
                                        }
                                      }
                                    } else if (
                                      eachAttribute.AC === "PARENPOS" &&
                                      event === null
                                    ) {
                                      const ac = "INCMPAPO";
                                      setAttributesData((prev) => ({
                                        ...prev,
                                        [ac]: event,
                                      }));
                                    }

                                    if (
                                      eachAttribute.AC === "INCMID" &&
                                      event !== null
                                    ) {
                                      const ac = "INCMNM";
                                      setAttributesData((prev) => ({
                                        ...prev,
                                        [ac]: event.label,
                                      }));
                                    } else if (
                                      eachAttribute.AC === "INCMID" &&
                                      event === null
                                    ) {
                                      const ac = "INCMNM";
                                      setAttributesData((prev) => ({
                                        ...prev,
                                        [ac]: "",
                                      }));
                                    }

                                    if (
                                      event !== null &&
                                      eachAttribute.CC !== null &&
                                      eachAttribute.CC !== ""
                                    ) {
                                      const CC = eachAttribute.CC.split(",");

                                      CC.forEach(async (childDropdown) => {
                                        setAttributesData((prev) => ({
                                          ...prev,
                                          [childDropdown]: null,
                                        }));

                                        const options = await getDropdownsData(
                                          childDropdown,
                                          event.value
                                        );

                                        setOptionsObj((prev) => ({
                                          ...prev,
                                          [childDropdown]: options,
                                        }));
                                      });
                                    }

                                    dependentValidations(
                                      event,
                                      eachAttribute.AC
                                    );

                                    //searchable DD
                                    if (event) {
                                      searchableDDValues(
                                        event,
                                        eachAttribute.AC
                                      );
                                    }
                                  }}
                                  isMulti={false}
                                  noIndicator={false}
                                  noSeparator={false}
                                />
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : eachAttribute.AT === "AttributeSet" &&
                      eachAttribute.DT === "Custom" ? (
                      <div className="col-lg-12">
                        <div className="row">
                          {eachAttribute.Attribute.length > 0 &&
                            eachAttribute.Attribute.map(
                              (subAttribute, index) => (
                                <React.Fragment
                                  key={`${index}-${subAttribute.AC}`}>
                                  {subAttribute.DT === "DropdownSingle" ? (
                                    <>
                                      <div
                                        className={
                                          JSON.parse(
                                            subAttribute.AD
                                          ).IFR.toLowerCase() === "true"
                                            ? "col-lg-12 col-sm-12 col-xs-12 "
                                            : "col-lg-3 col-sm-3 col-xs-4"
                                        }>
                                        <div className="mb-1">
                                          <label className="col-form-label">
                                            {subAttribute.AN}
                                            {JSON.parse(
                                              subAttribute.V
                                            ).ISM.toLowerCase() === "true" && (
                                              <sup>*</sup>
                                            )}
                                          </label>

                                          <SelectForm
                                            isClearable
                                            isSearchable
                                            options={
                                              optionsObj[subAttribute.AC]
                                                ? optionsObj[subAttribute.AC]
                                                : []
                                            }
                                            placeholder={subAttribute.AN}
                                            isDisabled={!section.ISEDIT}
                                            value={
                                              attributeSetData[subAttribute.AC]
                                                ? attributeSetData[
                                                    subAttribute.AC
                                                  ]
                                                : null
                                            }
                                            onChange={(event) => {
                                              setAttributeSetData((prev) => ({
                                                ...prev,
                                                [subAttribute.AC]: event,
                                              }));

                                              if (
                                                event !== null &&
                                                subAttribute.CC !== null &&
                                                subAttribute.CC !== ""
                                              ) {
                                                const CC =
                                                  subAttribute.CC.split(",");

                                                CC.forEach(
                                                  async (childDropdown) => {
                                                    setAttributeSetData(
                                                      (prev) => ({
                                                        ...prev,
                                                        [childDropdown]: null,
                                                      })
                                                    );

                                                    const options =
                                                      await getDropdownsData(
                                                        childDropdown,
                                                        event.value
                                                      );

                                                    setOptionsObj((prev) => ({
                                                      ...prev,
                                                      [childDropdown]: options,
                                                    }));
                                                  }
                                                );
                                              }
                                            }}
                                            isMulti={false}
                                            noIndicator={false}
                                            noSeparator={false}
                                          />
                                          {formErrors[subAttribute.AC] && (
                                            <p style={{ color: "red" }}>
                                              {formErrors[subAttribute.AC]}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  ) : subAttribute.DT === "Text" ||
                                    subAttribute.DT === "Number" ? (
                                    <>
                                      <div
                                        className={
                                          JSON.parse(
                                            subAttribute.AD
                                          ).IFR.toLowerCase() === "true"
                                            ? "col-lg-12 col-sm-12 col-xs-12 "
                                            : "col-lg-3 col-sm-3 col-xs-4"
                                        }>
                                        <div className="mb-1">
                                          <label className="col-form-label">
                                            {subAttribute.AN}
                                            {JSON.parse(
                                              subAttribute.V
                                            ).ISM.toLowerCase() === "true" && (
                                              <sup>*</sup>
                                            )}
                                          </label>

                                          <InputForm
                                            className="form-control"
                                            placeholder={subAttribute.AN}
                                            isDisabled={!section.ISEDIT}
                                            textArea={false}
                                            value={
                                              attributeSetData[subAttribute.AC]
                                                ? attributeSetData[
                                                    subAttribute.AC
                                                  ]
                                                : ""
                                            }
                                            onChange={(e) => {
                                              setAttributeSetData((prev) => ({
                                                ...prev,
                                                [subAttribute.AC]:
                                                  e.target.value,
                                              }));
                                            }}
                                            maxLength="255"
                                          />
                                          {formErrors[subAttribute.AC] && (
                                            <p style={{ color: "red" }}>
                                              {formErrors[subAttribute.AC]}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  ) : subAttribute.DT === "Date" ? (
                                    <>
                                      <div
                                        className={
                                          JSON.parse(
                                            subAttribute.AD
                                          ).IFR.toLowerCase() === "true"
                                            ? "col-lg-12 col-sm-12 col-xs-12 "
                                            : "col-lg-3 col-sm-3 col-xs-4"
                                        }>
                                        <div className="mb-1">
                                          <label className="col-form-label">
                                            {subAttribute.AN}
                                            {JSON.parse(
                                              subAttribute.V
                                            ).ISM.toLowerCase() === "true" && (
                                              <sup>*</sup>
                                            )}
                                          </label>

                                          <DateForm
                                            isDisabled={!section.ISEDIT}
                                            value={
                                              attributeSetData[subAttribute.AC]
                                                ? attributeSetData[
                                                    subAttribute.AC
                                                  ]
                                                : null
                                            }
                                            onChange={(date) => {
                                              setAttributeSetData((prev) => ({
                                                ...prev,
                                                [subAttribute.AC]:
                                                  moment(date).format(
                                                    "DD-MMM-YYYY"
                                                  ),
                                              }));
                                            }}
                                          />
                                          {formErrors[subAttribute.AC] && (
                                            <p style={{ color: "red" }}>
                                              {formErrors[subAttribute.AC]}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  ) : subAttribute.DT === "Checkbox" ? (
                                    <>
                                      <div
                                        className={
                                          JSON.parse(
                                            subAttribute.AD
                                          ).IFR.toLowerCase() === "true"
                                            ? "col-lg-12 col-sm-12 col-xs-12 "
                                            : "col-lg-3 col-sm-3 col-xs-4"
                                        }>
                                        <div className="mb-1">
                                          <label
                                            htmlFor={subAttribute.AC}
                                            className="col-form-label">
                                            {subAttribute.AN}
                                            {JSON.parse(
                                              subAttribute.V
                                            ).ISM.toLowerCase() === "true" && (
                                              <sup>*</sup>
                                            )}
                                          </label>

                                          <div>
                                            <input
                                              disabled={!section.ISEDIT}
                                              type="checkbox"
                                              onChange={(e) => {
                                                setAttributeSetData((prev) => ({
                                                  ...prev,
                                                  [subAttribute.AC]:
                                                    e.target.checked,
                                                }));
                                              }}
                                              id={subAttribute.AC}
                                              checked={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : false
                                              }
                                            />
                                          </div>
                                          {formErrors[subAttribute.AC] && (
                                            <p style={{ color: "red" }}>
                                              {formErrors[subAttribute.AC]}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  ) : subAttribute.DT === "RichText" ? (
                                    <>
                                      <div
                                        className={
                                          JSON.parse(
                                            subAttribute.AD
                                          ).IFR.toLowerCase() === "true"
                                            ? "col-lg-12 col-sm-12 col-xs-12 "
                                            : "col-lg-3 col-sm-3 col-xs-4"
                                        }>
                                        <div className="mb-1">
                                          <label className="col-form-label">
                                            {subAttribute.AN}
                                            {attributeValidation[
                                              subAttribute.AC
                                            ]?.ISM.toLowerCase() === "true" && (
                                              <sup>*</sup>
                                            )}
                                          </label>
                                          <RTE
                                            content={
                                              attributeSetData[subAttribute.AC]
                                                ? attributeSetData[
                                                    subAttribute.AC
                                                  ]
                                                : ""
                                            }
                                            disabled={!section.ISEDIT}
                                            onblur={(e) => {
                                              setAttributeSetData((prev) => ({
                                                ...prev,
                                                [subAttribute.AC]:
                                                  e.target?.value,
                                              }));
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </React.Fragment>
                              )
                            )}
                        </div>

                        <div className="row">
                          <div className="col-lg-3 mt-2 mb-3">
                            <button
                              id={currentGridId === null ? "0" : "1"}
                              className="btn btn-primary"
                              disabled={!section.ISEDIT}
                              onClick={(e) => {
                                const targetId = e.currentTarget.id;

                                if (targetId === "0") {
                                  //insert
                                  const uniqueId = uuid();

                                  //validation for empty data before adding in grid

                                  const isError = validateData(
                                    eachAttribute.Attribute
                                  );

                                  if (!isError) {
                                    //add record in grid

                                    setOgFilledAttributeSet((prev) => [
                                      ...prev,
                                      {
                                        ...attributeSetData,
                                        Id: uniqueId,
                                        IsActive: true,
                                      },
                                    ]);

                                    let gridData = {};

                                    eachAttribute.Attribute.forEach(
                                      async (subAttribute) => {
                                        if (
                                          typeof attributeSetData[
                                            subAttribute.AC
                                          ] === "object"
                                        ) {
                                          gridData = {
                                            ...gridData,
                                            [subAttribute.AC]:
                                              attributeSetData[subAttribute.AC]
                                                ?.label || null, //for date and dropdown
                                          };
                                        } else if (
                                          typeof attributeSetData[
                                            subAttribute.AC
                                          ] === "boolean"
                                        ) {
                                          gridData = {
                                            ...gridData,
                                            [subAttribute.AC]:
                                              attributeSetData[
                                                subAttribute.AC
                                              ] === true
                                                ? "true"
                                                : "false",
                                          };
                                        } else {
                                          gridData = {
                                            ...gridData,
                                            [subAttribute.AC]:
                                              attributeSetData[subAttribute.AC],
                                          };
                                        }
                                      }
                                    );

                                    //reset
                                    setAttributeSetData((prev) => ({
                                      ...prev,
                                      ...ogAttributeSet,
                                    }));

                                    setFormErrors({});

                                    setGridData((prev) => [
                                      ...prev,
                                      {
                                        Id: uniqueId,
                                        ...gridData,
                                        IsActive: true,
                                      },
                                    ]);

                                    setDisplayGrid((prev) => [
                                      ...prev,
                                      {
                                        Id: uniqueId,
                                        ...gridData,
                                        IsActive: true,
                                      },
                                    ]);
                                  }
                                } else {
                                  //update

                                  //validation for empty data before updating in grid

                                  const isError = validateData(
                                    eachAttribute.Attribute
                                  );

                                  if (!isError) {
                                    //update

                                    setOgFilledAttributeSet((prev) => {
                                      const newState = prev.map((record) => {
                                        if (record.Id === currentGridId) {
                                          return { ...attributeSetData };
                                        }
                                        return record;
                                      });
                                      return newState;
                                    });

                                    let gridData = {};

                                    eachAttribute.Attribute.forEach(
                                      async (subAttribute) => {
                                        if (
                                          typeof attributeSetData[
                                            subAttribute.AC
                                          ] === "object"
                                        ) {
                                          gridData = {
                                            ...gridData,
                                            [subAttribute.AC]:
                                              attributeSetData[subAttribute.AC]
                                                ?.label || null, //for date and dropdown
                                          };
                                        } else if (
                                          typeof attributeSetData[
                                            subAttribute.AC
                                          ] === "boolean"
                                        ) {
                                          gridData = {
                                            ...gridData,
                                            [subAttribute.AC]:
                                              attributeSetData[
                                                subAttribute.AC
                                              ] === true
                                                ? "true"
                                                : "false",
                                          };
                                        } else {
                                          gridData = {
                                            ...gridData,
                                            [subAttribute.AC]:
                                              attributeSetData[subAttribute.AC],
                                          };
                                        }
                                      }
                                    );

                                    //reset
                                    setAttributeSetData((prev) => ({
                                      ...prev,
                                      ...ogAttributeSet,
                                    }));

                                    setFormErrors({});

                                    setGridData((prev) => {
                                      const newState = prev.map((record) => {
                                        if (record.Id === currentGridId) {
                                          return {
                                            Id: currentGridId,
                                            ...gridData,
                                          };
                                        }
                                        return record;
                                      });
                                      return newState;
                                    });

                                    setDisplayGrid((prev) => {
                                      const newState = prev.map((record) => {
                                        if (record.Id === currentGridId) {
                                          return {
                                            Id: currentGridId,
                                            ...gridData,
                                          };
                                        }
                                        return record;
                                      });
                                      return newState;
                                    });

                                    setCurrentGridId(null);
                                  }
                                }
                              }}>
                              <i
                                className={
                                  currentGridId === null
                                    ? "fa fa-plus"
                                    : "fas fa-edit"
                                }></i>{" "}
                              {currentGridId === null
                                ? "Add Another Record"
                                : "Update Record"}
                            </button>
                          </div>

                          <div className="col-lg-12 p-0 mb-3">
                            <DynamicGrid
                              options={gridOptions}
                              data={displayGrid}
                              columns={gridColumns}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </React.Fragment>

                ))}
              
            </div>
            {section.SN === "incumbent" && TId !==null && TId !==0 && wflStatus === "Approved" 
            ?
                  <div className="row mt-3" >
                          <div className="col-lg-12 p-0 mb-3">
                            <DynamicGrid
                              options={IncgridOptions}
                              data={IncdisplayGrid}
                              columns={IncgridColumns} // inc grid colms
                            />
                          </div>
                    </div>
                  :null}
          </AccordionDetails>
        </Accordion>
      )}

      <Modal
        show={showConfirmationModal}
        onHide={() => closeConfirmationModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">Confirm!</div>
        </div>
        <Modal.Body>
          <h5>{`Employee ${PositionHolder} holds this position; any changes in position will not automatically update in Employee EP. Do you still want to make changes?`}</h5>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-success"
            style={{ marginLeft: 5 }}
            onClick={() => closeConfirmationModal(true)}>
            Yes
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => closeConfirmationModal(false)}>
            No
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const PositionManageForm = () => {
  const [formData, setFormData] = useState<any>({});
  const [tabs, setTabs] = useState([]);
  const [activetab, setActivetab] = useState<string>(null);
  const [activetabIndex, setActivetabIndex] = useState<number>(0);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { state } = useLocation();
  const [TId, setTId] = useState((state !== null && state.TId) || 0);
  //const [TId, setTId] = useState(2);
  const [headerValues, setHeaderValues] = useState<any>(null);
  const [sectionValuesArray, setSectionValuesArray] = useState<any>([]);
  let { userDetails, currentRoleId }: any = useUserContext();
  const [COU, setCOU] = useState((state !== null && state.COU) || "");
  const [accordionWrkfl, setAccordionWrkfl] = useState(false);
  const [approvers, setApprovers] = useState<any>([]);
  const [approverOpt, setapproverOpt] = useState<any>({});
  const [allApprovers, setAllApprovers] = useState<any>({});
  const FullName = `${userDetails.FirstName} ${userDetails.LastName}`;
  /*Approval tab */
  const [accordion, setAccordion] = useState(true);
  const [attributesApprovalData, setAttributesApprovalData] = useState<any>({});
  const [workflowTaskId, setWorkflowTaskId] = useState(state?.Id ? state?.Id : 0);
  const [decisionOptionsArr, setDecisionOptionsArr] = useState([]);
  const [disableSubmitBtn, setDisabledSubmitBtn] = useState(false);
  const [formErrors, setFormErrors] = useState<any>({});
  const navigate = useNavigate();
  const [progAttributesData, setProgAttributesData] = useState<any>({});
  const [dynamicAccordian, setDynamicAccordian] = useState(null);
  const [optionsObj, setOptionsObj] = useState<any>({});
  const [accordionReqDet, setAccordionReqDet] = useState(false);
  const [sectionTabHide, setSectionTabHide] = useState(true);
  const [accordianEvent, setAccordianEvent] = useState(false);
  const [initId, setInitId] = useState<any>(null);
  const [initRoleId, setInitRoleId] = useState<any>(null);
  
  const [showSectionHistoryModal, setShowSectionHistoryModal] = useState(false);

  const [currentSectionId, setCurrentSectionId] = useState(0);

  //let { currentRoleId }: any = useUserContext();
  const [IsBudget, setIsBudget] = useState(null);
  const [BIS, setBIS] = useState(null);
  const [SBU, setSBU] = useState(null);
  const [LEN, setLEN] = useState(null);
  const [PLOC, setPLOC] = useState(null);
  const [dpProfileImg, setDpProfileImg] = useState(null); 
  const [wflStatus, setwflStatus] = useState(null); 
  const [PositionHolder, setPositionHolder] = useState(null);
  const [newPrevConPost,setNewPrevConvPost] = useState([]); 
  /*Approval tab */

  useEffect(() => {
    (async () => {
      showLoader();
      //incmName=null;
      const tabs = await APICall(getTabsData, "POST", {
        MN: "PositionManagement",
        IN: "PositionManagement_Form",
      });

      if (tabs.data !== null && tabs.data.length > 0) {
        setTabs(tabs.data);
        if (
          workflowTaskId != 0 &&
          state.type === "edit" &&
          state.action === "reviewer"
        ) {
          setActivetab(tabs.data[1].TN);
        } else if (
          workflowTaskId != 0 &&
          state.type === "edit" &&
          state.action === "sendback"
        ) {
          setActivetab(tabs.data[0].TN);
        } else {
          setActivetab(tabs.data[0].TN);
        }
      } else {
        setTabs([]);
      }
      hideLoader();
    })();
  }, []);

  //tab api here
  useEffect(() => {
    //get section
    if (activetab !== null) {
      (async () => {
        showLoader();

        const section = await APICall(getSectionDetailsV2, "POST", {
          MN: "PositionManagement",
          IN: "PositionManagement_Form",
          TN: activetab,
          UID: workflowTaskId != 0 && state.type === "edit" && state.action === "reviewer" && initId !== null ? initId : userDetails.Id,
          TID: TId,
          RoleId: workflowTaskId != 0 && state.type === "edit" && state.action === "reviewer" && initRoleId !== null ? initRoleId : currentRoleId?.value,
          COU: COU,
          IsPos: true,
        });

        if (
          section.data !== null &&
          section.data.t !== undefined &&
          section.data.t.tn === activetab
        ) {
          setFormData(section.data.t);
        } else {
          setFormData({});
        }

        hideLoader();
      })();

      if (TId !== 0 && headerValues === null) {
        (async () => {
          let bodyObj = {
            mn: "PositionManagement",
            in: "PositionManagement_Form",
            tn: "PositionManagement",
            TID: TId,
            ISH: "true",
            COU: COU,
            IsPos: true,
          };

          const response = await APICall(
            getAttributeValueDetailsV2,
            "POST",
            bodyObj
          );

          if (response.data !== null && response.data.headerValues !== null) {
            setHeaderValues(response.data.headerValues);
          } else {
            setHeaderValues(null);
          }
          if (response.data.sections && response.data.sections.length > 0) {
            setSectionValuesArray(response.data.sections);
          } else {
            setSectionValuesArray([]);
          }
        })();
      }
    }
    //setCounterForIncChange(counterForIncChange+1);
  }, [
    activetab,
    TId,
    initId,
    initRoleId,
    //,setIncmChange
  ]);
  useEffect(() => {
    if (IsBudget != null && IsBudget != undefined) {
      (async () => {
        const workflowRes = await APICall(getWorkflowData, "POST", {
          WorkflowTypeId: null,
          WorkflowSubTypeId: null,
          IsBudget: IsBudget,
          InitiatorId: currentRoleId?.value,
          POUDetails: {
            bis: BIS,
            sbu: SBU,
            len: LEN,
            PLOC: PLOC,
          },
          EmployeeId: userDetails.EmployeeId,
          CountryId: COU,
        });

        if (workflowRes.data?.length > 0) {
          setApprovers(workflowRes.data);
          if (workflowRes.data.length > 1) {
            workflowRes.data.map((eachapprover, index) => {
              eachapprover.sequeceNo > 1 &&
                setapproverOpt((prev) => ({
                  ...prev,
                  [`${"Approver"} ${index}`]: eachapprover.approvers,
                }));
              setAllApprovers((prev) => ({
                ...prev,
                [`${"Approver"} ${index}`]: eachapprover.approvers,
              }));
            });
          }
        }
      })();
    }
  }, [IsBudget, BIS, SBU, LEN, PLOC]);

  useEffect(() => {
    setAttributesApprovalData((prev) => ({
      ["PROGRVWR"]: "",
      ["PROGROLE"]: "",
      ["PROGEFFDT"]: null,
      ["PROGDEC"]: null,
      ["PROGRMKS"]: "",
      ["PROGACK"]: false,
      ["PROGPRCONVO"]: "",
    }));
  }, []);

  useEffect(() => {
    if (state?.type != undefined) {
      (async () => await handleFetchProgressionData())();
    }
  }, []);

  const defaultTheme = createTheme({});
  const { breakpoints } = defaultTheme;

  const tabsTheme = {
    ...defaultTheme,
    components: {
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: "0px !important",
            marginLeft: "-42px",
            [breakpoints.down("md")]: {
              minHeight: "0px !important",
              marginLeft: "0px",
            },
          },
        },
      },
    },
  };

  const handleSubmitForApprover = async () => {
    try {
      let isError = {};
      showLoader();
      if (!attributesApprovalData["PROGDEC"]) {
        isError["PROGDEC"] = "Decision Required.";
      }

      if (!attributesApprovalData["PROGACK"]) {
        isError["PROGACK"] = "Acknowledgement Required.";
      }

      if (!attributesApprovalData["PROGRMKS"]) {
        isError["PROGRMKS"] = "Remarks are Required.";
      }
      setFormErrors(isError);
      if (Object.keys(isError).length > 0) return;

      let obj = {
        CreatedBy: userDetails?.Id,
        CreatedByRoleId: currentRoleId?.value,
        WorkflowTaskId: workflowTaskId,
        DecisionId: attributesApprovalData["PROGDEC"]?.value || 0,
        IsAcknowledged: attributesApprovalData["PROGACK"] || false,
        EmployeeId: userDetails.Id,
        WorkflowTasksRemarks: attributesApprovalData["PROGRMKS"],
      };

      const response = await APICall(
        handleApprovalForPositionModel,
        "POST",
        obj
      );

      if (response?.status == 0) {
        await handleFetchProgressionData();
        notify(0, "Record Saved Successfully.");
        navigate("/home");
        setAccordion((prev) => !prev);
      } else {
        notify(1, "Something went wrong.");
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };

  const handleFetchProgressionData = async () => {
    try {
      showLoader();

      const [response] = await Promise.all([
        APICall(getApproverAndProgressionDetailsByWorkflowTaskId, "POST", {
          Id: workflowTaskId,
        }),
      ]);

      const responseData = response?.data;
      if (response?.status === 0 && responseData) {
        setTId(responseData.ProgressionId ? responseData.ProgressionId : 0);
        const parseJSON = (jsonString) => {
          try {
            return JSON.parse(jsonString);
          } catch (error) {
            return null;
          }
        };
        setInitId(responseData.InitiatorId);
        setInitRoleId(responseData.InitiatorRoleId);

        const parsePreviousConversation = (previousConversation) => {
          let conversation = "";
          const remarksArray = parseJSON(previousConversation);
          let previousConversationToTablePosition = [];
          if (remarksArray) {
            remarksArray.map((item) => {
              const Date = item.ModifiedDateUtc
                ? moment.utc(item.ModifiedDateUtc).local().format("DD-MMM-YYYY")
                : "";
              const ApproverName = item.UserName || "";
              const RoleName = item.RoleName || "";
              const Remarks = item.Remarks || "";
              previousConversationToTablePosition.push({
                Date: Date,
                ApproverName: ApproverName,
                RoleName: RoleName,
                Remarks: Remarks,
              });
              if (Remarks?.trim().length > 0) {
                conversation += `${Date} ${ApproverName} ${RoleName}\n`;
                conversation += `\t\t${Remarks}\n\n`;
              }
            });
          }
          setNewPrevConvPost(previousConversationToTablePosition);
          return conversation;
        };

        const NewJson = parseJSON(responseData?.RequestNewJson) || [];

        const additionalDetails =
          parseJSON(responseData.AdditionalDetails) || [];

        setDecisionOptionsArr(additionalDetails);

        const isStatus2 = responseData.WorkflowTaskStatusId === 2;

        const decision =
          (isStatus2 &&
            additionalDetails.find(
              (i) =>
                String(i?.label).trim().toLowerCase() ===
                String(responseData.Outcome).toLowerCase().trim()
            )) ||
          null;

        const previousConversation = parsePreviousConversation(
          responseData.PreviousConversation
        );

        const approverObject = {
          ApproverName: responseData.ApproverName || "",
          RoleName: responseData.ApproverRoleName || "",
          Date: responseData.modifiedDateUtc || moment().format("DD-MMM-YYYY"),
          EmployeeId: responseData.EmployeeId,
          IsAcknowledged: responseData.IsAcknowledged,
          IsAcknolwdgedRequired: responseData.IsAcknolwdgedRequired || false,
          Outcome: responseData.Outcome || "",
          PreviousConversation: previousConversation,
          Decision: decision,
          Remarks: responseData.WorkflowTasksRemarks || "",
          WorkflowTaskStatusId: responseData.WorkflowTaskStatusId || 0,
        };

        const isDisabled = approverObject.WorkflowTaskStatusId === 2;

        const setAttributes = {
          PROGRVWR: approverObject.ApproverName,
          PROGROLE: approverObject.RoleName,
          PROGEFFDT: approverObject.Date,
          PROGDEC: approverObject.Decision,
          PROGRMKS: approverObject.Remarks,
          PROGACK: approverObject.IsAcknowledged,
          PROGPRCONVO: approverObject.PreviousConversation,
          EmployeeId: approverObject.EmployeeId,
          IsAcknolwdgedRequired: approverObject.IsAcknolwdgedRequired,
          IsDisabled: isDisabled,
        };

        setDisabledSubmitBtn(isDisabled);
        setAttributesApprovalData(setAttributes);
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };
  //set

  const viewSectionHistory = (data) => {
    setShowSectionHistoryModal(true);
    setCurrentSectionId(data.sectionDetails.SId);
  };
  console.log('state::::', state);
  return (
    <>
      <div className="container-fluid">
        <div
          className="row prof_bg mb-3 border-bottom"
          style={{ backgroundColor: headerValues ? "#00abe6" : "white" }}>
          <div className="container-fluid">
            {headerValues && (
              <div className="row botpad">
                <div className="col-lg-2 col-md-2 col-sm-12">
                  <div className="prof_img">
                    {/* {headerValues && (
                      <img className="pos_img_head" src={profileImg} />
                    )} */}
                    {
                      dpProfileImg 
                      ? <img src={ dpProfileImg } alt="Profile" /> 
                      : <img className="pos_img_head" src={profileImg} />
                    }
                  </div>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-12 profData">
                  <div className="name">
                    <h4>
                      {headerValues &&
                        headerValues?.POSITID !== "" &&
                        `${headerValues?.POSITID}`}
                    </h4>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12 profDetail">
                      <ul>
                        {/* <li>
                          <a>
                            {headerValues && headerValues?.POSIT !== "" && (
                              <>
                                <i className="fa-regular fa-address-card"></i>{" "}
                                {headerValues?.POSIT}
                              </>
                            )}
                          </a>
                        </li> */}
                        <li>
                          <i className="fa-solid fa-user"></i>{" "}
                          {headerValues && headerValues?.DESIGNTN !== "" && (
                            <>{headerValues?.DESIGNTN}</>
                          )}
                        </li>
                        <li>
                          <i className="fa-solid fa-briefcase"></i>{" "}
                          {headerValues && headerValues?.PMBUSN !== null && (
                            <>
                              {headerValues?.PMBUSN}
                              {" | "}
                              {headerValues?.PMSBU}
                              {" | "}
                              {headerValues?.PMLEGEN}
                            </>
                          )}
                        </li>
                        {/* <li>
                          <a>
                            {headerValues && headerValues?.PMSBU !== null && (
                              <>
                                <i className="fa-solid fa-briefcase"></i>{" "}
                                {headerValues?.PMSBU}
                              </>
                            )}
                          </a>
                        </li>
                        <li>
                          <a>
                            {headerValues && headerValues?.PMLEGEN !== null && (
                              <>
                                <i className="fa-solid fa-briefcase"></i>{" "}
                                {headerValues?.PMLEGEN}
                              </>
                            )}
                          </a>
                        </li> */}
                        <li>
                          <i className="fa-solid fa-location-dot"></i>{" "}
                          {headerValues && headerValues?.PMLOCN !== null && (
                            <>
                              {headerValues?.PMLOCN}{" "}
                              {headerValues?.PMFUNC &&
                                `| ${headerValues?.PMFUNC}`}
                            </>
                          )}
                        </li>

                        {/* NEW ADD */}
                        <li>
                          <i className="fas fa-tag"></i>{" "}
                          {headerValues && headerValues?.VCANSTAT != null && (
                            <>{headerValues?.VCANSTAT}</>
                          )}
                        </li>
                        <li>
                          <i className="fas fa-address-card"></i>{" "}
                          {headerValues && headerValues?.INCMNM != null && (
                            <>{headerValues?.INCMNM}</>
                          )}
                        </li>
                      </ul>
                    </div>
                    {/* <div className="col-lg-4 col-md-4 col-sm-12 profDetail">
                      <ul>
                        <li>
                          <a>
                            {headerValues && headerValues?.PMJOTTL !== "" && (
                              <>
                                <i className="fa-solid fa-id-card-clip"></i>{" "}
                                {headerValues?.PMJOTTL}
                              </>
                            )}
                          </a>
                        </li>

                        <li>
                          <a>
                            {headerValues && headerValues?.VCANSTAT != null && (
                              <>
                                <i className="fas fa-tag"></i>{" "}
                                {headerValues?.VCANSTAT}
                              </>
                            )}
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-lg-2 col-md-12 col-sm-12 "></div>
              <div className="col-lg-10 col-md-12 col-sm-12 ">
                <ThemeProvider theme={tabsTheme}>
                  <Tabs
                    value={activetabIndex}
                    className="profile-tabs"
                    onChange={() => {}}
                    variant="scrollable"
                    TabScrollButtonProps={{
                      style: {
                        color: "white",
                      },
                    }}
                    scrollButtons
                    TabIndicatorProps={{
                      style: { display: "none" },
                    }}
                    allowScrollButtonsMobile
                    aria-label="scrollable tabs">
                    <div className="tabBtn">
                      <ul>
                        {tabs.length > 0 &&
                          tabs.map((eachTab, index) =>
                            state?.type === "view" ||
                            state?.type === undefined ? (
                              eachTab.TN != "Approval" && (
                                <li key={`${index}-${eachTab.TN}`}>
                                  <button
                                    onClick={() => setActivetab(eachTab.TN)}
                                    className={
                                      eachTab.TN === activetab
                                        ? "bttn active"
                                        : "bttn border border-primary border-bottom-0"
                                    }>
                                    {eachTab.TDN}
                                  </button>
                                </li>
                              )
                            ) : (
                              <li key={`${index}-${eachTab.TN}`}>
                                <button
                                  onClick={() => setActivetab(eachTab.TN)}
                                  className={
                                    eachTab.TN === activetab
                                      ? "bttn active"
                                      : "bttn border border-primary border-bottom-0"
                                  }>
                                  {eachTab.TDN}
                                </button>
                              </li>
                            )
                          )}
                      </ul>
                    </div>
                  </Tabs>
                </ThemeProvider>
              </div>
            </div>
          </div>
        </div>
        {/* Back Button */}
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
            <div className="text-right my-1">
              <button
                onClick={() => {
                  navigate(-1);
                }}
                disabled={false}
                className="btn btn-secondary float-right ml-2">
                <i className="fa-solid fa-arrow-left"></i> Back
              </button>

              {activetab === "Approval" && (
                <button
                  onClick={async () => {
                    await handleSubmitForApprover();
                  }}
                  disabled={
                    state.action === "sendback"
                      ? true
                      : attributesApprovalData["IsDisabled"]
                  }
                  className="btn btn-filter-submit float-right ml-2">
                  <i className="fa-solid fa-check"></i> Submit
                </button>
              )}
            </div>
          </div>
        </div>
        {/* section row */}
        <div className="row accordion_grid">
          {formData.S !== undefined &&
            formData.S.length > 0 &&
            ((((state !== null && state?.TId === 0) || state === null) &&
              sectionValuesArray.length === 0) ||
              (state !== null &&
                state?.TId !== 0 &&
                sectionValuesArray.length > 0)) &&
            formData.S.map((eachSection, index) =>
              state?.TId === 0 ? (
                eachSection.SN != "incumbent" && (
                  <React.Fragment key={`${index}-${eachSection.SN}`}>
                    <SectionComponent
                      formData={formData}
                      setFormData={setFormData}
                      section={eachSection}
                      sectionIndex={index}
                      TId={TId}
                      setTId={setTId}
                      sectionValuesArray={sectionValuesArray}
                      COU={COU}
                      appaction={state?.action ? state?.action : ""}
                      setIsBudget={setIsBudget}
                      setBIS={setBIS}
                      setSBU={setSBU}
                      setLEN={setLEN}
                      setPLOC={setPLOC}
                      viewSectionHistory={viewSectionHistory}
                      allApprovers={allApprovers}
                      setDpProfileImg = {setDpProfileImg}
                      wflStatus = {wflStatus}
                      setwflStatus = {setwflStatus}
                      PositionHolder ={PositionHolder}
                      setPositionHolder={setPositionHolder}
                      
                      // counterForIncChange={counterForIncChange}
                      // showLoader={showLoader}
                      // hideLoader={hideLoader}
                    />
                  </React.Fragment>
                )
              ) : (
                <>
                  <React.Fragment key={`${index}-${eachSection.SN}`}>
                    <SectionComponent
                      formData={formData}
                      setFormData={setFormData}
                      section={eachSection}
                      sectionIndex={index}
                      TId={TId}
                      setTId={setTId}
                      sectionValuesArray={sectionValuesArray}
                      COU={COU}
                      appaction={state?.action ? state?.action : ""}
                      setIsBudget={setIsBudget}
                      setBIS={setBIS}
                      setSBU={setSBU}
                      setLEN={setLEN}
                      setPLOC={setPLOC}
                      viewSectionHistory={viewSectionHistory}
                      allApprovers={allApprovers}
                      setDpProfileImg = {setDpProfileImg}
                      wflStatus = {wflStatus}
                      setwflStatus = {setwflStatus}
                      PositionHolder ={PositionHolder}
                      setPositionHolder={setPositionHolder}
                      // counterForIncChange={counterForIncChange}
                      // showLoader={showLoader}
                      // hideLoader={hideLoader}
                    />
                  </React.Fragment>
                </>
              )
            )}
        </div>
    
        {/*Moksha Approver */}
        {activetab === "PositionManagement" &&
          (state.TId === null || state.TId === 0 || state.action === "sendback" || state.IsDraft === true ) && (
            <Accordion
              elevation={0}
              className="mb-3"
              expanded={accordionWrkfl}
              onChange={() => setAccordionWrkfl((prev) => !prev)}>
              <AccordionSummary
                id="profile-accordion"
                style={{ background: "#3C5464" }}
                className="text-white acc_close"
                expandIcon={<ExpandMore />}>
                <div className="row d-flex align-items-center acc-heading">
                  <div className="col-lg-10 col-md-10 col-sm-12">
                    <p style={{ fontSize: "16px" }}>Approver</p>
                  </div>
                  <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
                </div>
              </AccordionSummary>

              <AccordionDetails className="page_heading">
                <div className="row">
                  {/* For Approver noOfApprover */}
                  {approvers &&
                    approvers.length > 1 &&
                    approvers.map(
                      (eachApprover, index) =>
                        eachApprover.sequeceNo > 1 && (
                          <>
                            <div className="col-lg-3 col-sm-3 col-xs-4">
                              <div className="mb-1">
                                <Tooltip
                                  title={
                                    eachApprover.approvers?.length > 0
                                      ? eachApprover.approvers
                                          .map((obj) => obj.label)
                                          .join(",")
                                      : ""
                                  }>
                                  <label className="col-form-label">
                                    {`${eachApprover.roleName}`}
                                  </label>
                                </Tooltip>

                                {eachApprover.roleCode === "PlantHead" || eachApprover.roleCode === "POS_PlantHead" ? <b>{` (if any)`}</b> : <sup>*</sup> }
                                <SelectForm
                                  isClearable
                                  isSearchable
                                  isDisabled={true}
                                  value={allApprovers[`${"Approver"} ${index}`]}
                                  options={
                                    approverOpt[`${"Approver"} ${index}`]
                                  }
                                  placeholder={`${"Approver"} ${index}`}
                                  onChange={(e) => {
                                    alert("Change");
                                  }}
                                  isMulti={true}
                                  noIndicator={false}
                                  noSeparator={false}
                                />
                                <div 
                                  className="dropdown-content"
                                >
                                {allApprovers[`${"Approver"} ${index}`]?.length >
                                  1 && (
                                  <>
                                    {allApprovers[`${"Approver"} ${index}`]?.map(
                                      (i) => (
                                        <p>{i?.label || ""}</p>
                                      )
                                    )}
                                  </>
                                )}
                            </div>
                              </div>
                            </div>
                          </>
                        )
                    )}
                </div>
              </AccordionDetails>
            </Accordion>
          )}
        {/*Moksha Approver */}
      </div>
      {/*Approval Tab*/}
      {activetab === "Approval" && (
        <div className="col-lg-12">
          {/* <div className="SectionSubmit mb-4 clearfix">
          <button
            onClick={async () => {
              setAccordion((prev) => !prev);
              await handleSubmitForApprover();
            }}
            disabled={state.action === "sendback"?true: attributesApprovalData["IsDisabled"]}
            className="btn btn-filter-submit float-right ml-2">
            <i className="fa-solid fa-check"></i> Submit
          </button>
        </div> */}
          <Accordion
            elevation={0}
            className="mb-3"
            expanded={accordion}
            onChange={() => setAccordion((prev) => !prev)}>
            <AccordionSummary
              id="profile-accordion"
              style={{ background: "#3C5464" }}
              className="text-white acc_close"
              expandIcon={<ExpandMore />}>
              <div className="row d-flex align-items-center acc-heading">
                <div className="col-lg-10 col-md-10 col-sm-12">
                  <p style={{ fontSize: "16px" }}>{`${"Approver"}`}</p>
                </div>
                <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
              </div>
            </AccordionSummary>

            <AccordionDetails className="page_heading">
              <div className="row">
                <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                  <div className="mb-1">
                    <label className="col-form-label">
                      Approver
                      <sup>*</sup>
                    </label>
                    <InputForm
                      className="form-control"
                      placeholder={"Approver"}
                      isDisabled={true}
                      textArea={false}
                      value={
                        attributesApprovalData["PROGRVWR"]
                          ? attributesApprovalData["PROGRVWR"]
                          : ""
                      }
                      onChange={(e) => {
                        setAttributesApprovalData((prev) => ({
                          ...prev,
                          ["PROGRVWR"]: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  {formErrors["PROGRVWR"] && (
                    <p style={{ color: "red" }}>{formErrors["PROGRVWR"]}</p>
                  )}
                </div>
                <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                  <div className="mb-1">
                    <label className="col-form-label">
                      Role
                      <sup>*</sup>
                    </label>
                    <InputForm
                      className="form-control"
                      placeholder={"Role"}
                      isDisabled={true}
                      textArea={false}
                      value={
                        attributesApprovalData["PROGROLE"]
                          ? attributesApprovalData["PROGROLE"]
                          : ""
                      }
                      onChange={(e) => {
                        setAttributesApprovalData((prev) => ({
                          ...prev,
                          ["PROGROLE"]: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  {formErrors["PROGROLE"] && (
                    <p style={{ color: "red" }}>{formErrors["PROGROLE"]}</p>
                  )}
                </div>
                <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                  <div className="mb-1">
                    <label className="col-form-label">
                      Date
                      <sup>*</sup>
                    </label>
                    <DateForm
                      isDisabled={true}
                      value={
                        attributesApprovalData["PROGEFFDT"]
                          ? attributesApprovalData["PROGEFFDT"]
                          : null
                      }
                      onChange={(date) => {
                        setAttributesApprovalData((prev) => ({
                          ...prev,
                          ["PROGEFFDT"]: moment(date).format("DD-MMM-YYYY"),
                        }));
                      }}
                    />
                  </div>
                  {formErrors["PROGEFFDT"] && (
                    <p style={{ color: "red" }}>{formErrors["PROGEFFDT"]}</p>
                  )}
                </div>
                <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                  <div className="mb-1">
                    <label className="col-form-label">
                      Decision
                      <sup>*</sup>
                    </label>
                    <SelectForm
                      isClearable
                      isSearchable
                      isDisabled={
                        state.action === "sendback"
                          ? true
                          : attributesApprovalData["IsDisabled"]
                      }
                      value={
                        attributesApprovalData["PROGDEC"]
                          ? attributesApprovalData["PROGDEC"]
                          : null
                      }
                      placeholder={"Decision"}
                      options={decisionOptionsArr}
                      onChange={(e) => {
                        setAttributesApprovalData((prev) => ({
                          ...prev,
                          ["PROGDEC"]: e,
                        }));

                        let obj = formErrors;
                        delete obj["PROGDEC"];
                        setFormErrors(obj);
                      }}
                      isMulti={false}
                      noIndicator={false}
                      noSeparator={false}
                    />
                  </div>
                  {formErrors["PROGDEC"] && (
                    <p style={{ color: "red" }}>{formErrors["PROGDEC"]}</p>
                  )}
                </div>
              </div>
              <div className="row">
                <div className={"col-lg-6 col-sm-3 col-xs-4"}>
                  <div className="mb-1">
                    <label className="col-form-label">Remarks<sup>*</sup></label>
                    <InputForm
                      className="form-control"
                      placeholder={"Remarks"}
                      isDisabled={
                        state.action === "sendback"
                          ? true
                          : attributesApprovalData["IsDisabled"]
                      }
                      textArea={false}
                      value={
                        attributesApprovalData["PROGRMKS"]
                          ? attributesApprovalData["PROGRMKS"]
                          : ""
                      }
                      onChange={(e) => {
                        setAttributesApprovalData((prev) => ({
                          ...prev,
                          ["PROGRMKS"]: e.target.value,
                        }));

                        let obj = formErrors;
                        delete obj["PROGRMKS"];
                        setFormErrors(obj);
                      }}
                    />
                  </div>
                  {formErrors["PROGRMKS"] && (
                    <p style={{ color: "red" }}>{formErrors["PROGRMKS"]}</p>
                  )}
                </div>
                <div className={"col-lg-6 col-sm-3 col-xs-4"}>
                  <div className="mb-1">
                    <label className="col-form-label">
                      I Acknowledge that this request has been reviewed by me.
                      {attributesApprovalData["IsAcknolwdgedRequired"] ==
                        true && <sup>*</sup>}
                    </label>
                    <div>
                      <input
                        disabled={
                          state.action === "sendback"
                            ? true
                            : attributesApprovalData["IsDisabled"]
                        }
                        type="checkbox"
                        onChange={(e) => {
                          setAttributesApprovalData((prev) => ({
                            ...prev,
                            ["PROGACK"]: e.target.checked,
                          }));

                          let obj = formErrors;
                          delete obj["PROGACK"];
                          setFormErrors(obj);
                        }}
                        id={"PROGACK"}
                        checked={attributesApprovalData["PROGACK"]}
                      />
                    </div>
                  </div>
                  {formErrors["PROGACK"] && (
                    <p style={{ color: "red" }}>{formErrors["PROGACK"]}</p>
                  )}
                </div>
              </div>
              <div className="row mt-3">
                <div
                  className={"col-lg-12 col-md-12 col-sm-12 mobile-view top-m"}>
                  <div className="mb-1">
                  <div>
        <Table striped bordered hover>
          {newPrevConPost?.map((eachApp, index) => (
            <>
              {index == 0 && (
                <thead>
                  <tr>
                    <th className="text-dark col-lg-2 col-md-2 col-sm-2 mobile-view top-m">Date-Time</th>
                    <th className="text-dark col-lg-2 col-md-2 col-sm-2 mobile-view top-m">User Name</th>
                    <th className="text-dark col-lg-2 col-md-2 col-sm-2 mobile-view top-m">Role</th>
                    <th className="text-dark col-lg-6 col-md-6 col-sm-6 mobile-view top-m">Remarks</th>
                  </tr>
                </thead>
              )}
              <tbody>
                <tr>
                  <td>{eachApp?.Date}</td>
                  <td>{eachApp?.ApproverName}</td>
                  <td>{eachApp?.RoleName}</td>
                  <td>{eachApp?.Remarks}</td>
                </tr>
              </tbody>
            </>
          ))}
        </Table>
    </div>
                    {/* <label className="col-form-label">
                      Previous Conversation
                    </label>
                    <InputForm
                      className="form-control"
                      placeholder={"Previous Conversation"}
                      isDisabled={true}
                      textArea={true}
                      value={
                        attributesApprovalData["PROGPRCONVO"]
                          ? attributesApprovalData["PROGPRCONVO"]
                          : ""
                      }
                      onChange={(e) => {
                        setAttributesApprovalData((prev) => ({
                          ...prev,
                          ["PROGPRCONVO"]: e.target.value,
                        }));
                      }}
                      rows={7}
                    /> */}
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      )}
      {/*Approval Tab*/}

       {/* section history modal */}
      <Modal
          show={showSectionHistoryModal}
          onHide={() => setShowSectionHistoryModal(false)}
          dialogClassName="modal-90w"
          backdrop="static"
          keyboard={false}
          centered>
          <div
            className="bg-secondary text-white"
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
            }}>
            <div className="col-lg-12">History</div>
          </div>
          <Modal.Body>
            <ViewHistoryPosition TId={TId} sectionId={currentSectionId} />
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-cancel"
              style={{ marginLeft: 5 }}
              onClick={() => {
                setShowSectionHistoryModal(false);
              }}>
              Close
            </button>
          </Modal.Footer>
      </Modal>
      {/* section history modal */}
    </>
  );
};

export default PositionManageForm;
