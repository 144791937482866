import { create } from "zustand";

const useMrfAnonymousCandidateDeclarationStore = create((set) => ({
  declaration: null,
  question: null,
  addDeclaration: (data) => set({ declaration: data }),
  addQuesiton: (data) => set({ question: data }),
}));

export default useMrfAnonymousCandidateDeclarationStore;
