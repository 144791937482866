import React, { useContext, useRef, useState, useEffect } from "react";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import notify from "../../Helpers/ToastNotification";
import { LoaderContext } from "../../Helpers/Context/Context";
import { APICall } from "../../Helpers/APICalls";
import InputForm from "../../Components/InputForm/InputForm";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import DateForm from "../../Components/DateForm/DateForm";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  DownloadAssociateStageStatus,
  GetCandidateDropdownData,
  GetAssociateStageStatus,
} from "../../Helpers/APIEndPoints/AssociateEndPoints";
import SelectForm from "../../Components/SelectForm/SelectForm";
import moment from "moment";
import { base64ToArrayBuffer } from "../../Helpers/Common";
import fileDownload from "js-file-download";
import { useNavigate } from "react-router-dom";

const Candidate = () => {
  const [dashboard, setdashboard] = useState([]);
  const [dashboardPageSize, setDashboardPageSize] = useState(10);
  const [dashboardStart, setDashboardStart] = useState(0);
  const [dashboardSortColumn, setDashboardSortColumn] = useState<any>("");
  const [dashboardSortDirection, setDashboardSortDirection] = useState("");
  const [dashboardCount, setDashboardCount] = useState(0);
  const [selectedDateandTime, setselectedDateandTime] = React.useState(null);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [formData, setFormData] = useState<IFormData>({});
  const [refresh, setRefresh] = useState<any>(false);
  const navigate = useNavigate();

  interface IFormData {
    fromdate?: Date | string | boolean | any;
    todate?: Date | string | boolean | any;
    candidateId?: IOptions | boolean | any;
  }

  interface IOptions {
    label?: string;
    value?: number | string;
    code?: string;
  }

  useEffect(() => {
    (async () => {
      await getCandidateStatus(true);
    })();
  }, [refresh, dashboardStart, dashboardSortColumn, dashboardSortDirection]);

  const handleExporttoExcel = async () => {
    const response = await APICall(DownloadAssociateStageStatus, "POST", {
      AssociateId: formData?.candidateId?.id,
      FromDate: formData?.fromdate
        ? moment(formData?.fromdate).format("yyyy-MM-DD")
        : null,
      ToDate: formData?.todate
        ? moment(formData?.todate).format("yyyy-MM-DD")
        : null,
      PageNumber: dashboardStart,
      PageSize: dashboardPageSize,
      SortColumn: dashboardSortColumn,
      SortOrder: dashboardSortColumn,
      IsExcel: false,
    });
    console.log(response?.data);
    if (response?.status == 0) {
      const { base64 } = response?.data;
      const fileName = `Candidate_Status${moment().format(
        "DD-MM-YYYY HH:mm:ss"
      )}.xlsx`;

      let arrayBuffer = await base64ToArrayBuffer(base64);
      var byteArray = new Uint8Array(arrayBuffer);

      let blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const newFile = new File([blob], "", {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        lastModified: new Date().getTime(),
      });

      fileDownload(newFile, fileName);
      notify(0, "File Downloaded Successfully.");
    }
  };

  const onResetData = async () => {
    setFormData((prev) => {
      return { ...prev, candidateId: null };
    });

    setFormData((prev) => {
      return { ...prev, fromdate: null };
    });

    setFormData((prev) => {
      return { ...prev, todate: null };
    });

    setRefresh(true);
  };

  const gridColumns = [
    {
      name: "associateId",
      label: "Token Id",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "documentTest",
      label: "Candidate Authentication",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        customBodyRender: (value) => {
          console.log(value);
          if (value === "approved") {
            return (
              <div
                style={{
                  width: "24px",
                  textAlign: "center",
                  marginLeft: "15px",
                }}
              >
                <i
                  className="fas fa-check"
                  style={{ color: "green", fontSize: "24px" }}
                ></i>
              </div>
            );
          } else if (value === "reject") {
            return (
              <div
                style={{
                  width: "24px",
                  textAlign: "center",
                  marginLeft: "15px",
                }}
              >
                <i
                  className="fas fa-times"
                  style={{ color: "red", fontSize: "24px" }}
                ></i>
              </div>
            );
          } else {
            return "";
          }
        },
      },
    },
    {
      name: "medicalTest",
      label: "Medical Check Up",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        customBodyRender: (value) => {
          if (value === "approved") {
            return (
              <div
                style={{
                  width: "24px",
                  textAlign: "center",
                  marginLeft: "15px",
                }}
              >
                <i
                  className="fas fa-check"
                  style={{ color: "green", fontSize: "24px" }}
                ></i>
              </div>
            );
          } else if (value === "reject") {
            return (
              <div
                style={{
                  width: "24px",
                  textAlign: "center",
                  marginLeft: "15px",
                }}
              >
                <i
                  className="fas fa-times"
                  style={{ color: "red", fontSize: "24px" }}
                ></i>
              </div>
            );
          } else {
            return "";
          }
        },
      },
    },
    {
      name: "skillTest",
      label: "Skill Test",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        customBodyRender: (value) => {
          if (value === "approved") {
            return (
              <div
                style={{
                  width: "24px",
                  textAlign: "center",
                  marginLeft: "15px",
                }}
              >
                <i
                  className="fas fa-check"
                  style={{ color: "green", fontSize: "24px" }}
                ></i>
              </div>
            );
          } else if (value === "reject") {
            return (
              <div
                style={{
                  width: "24px",
                  textAlign: "center",
                  marginLeft: "15px",
                }}
              >
                <i
                  className="fas fa-times"
                  style={{ color: "red", fontSize: "24px" }}
                ></i>
              </div>
            );
          } else {
            return "";
          }
        },
      },
    },
    {
      name: "uan",
      label: "UAN",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        customBodyRender: (value) => {
          if (value === "approved") {
            return (
              <div
                style={{
                  width: "24px",
                  textAlign: "center",
                  marginLeft: "15px",
                }}
              >
                <i
                  className="fas fa-check"
                  style={{ color: "green", fontSize: "24px" }}
                ></i>
              </div>
            );
          } else if (value === "reject") {
            return (
              <div
                style={{
                  width: "24px",
                  textAlign: "center",
                  marginLeft: "15px",
                }}
              >
                <i
                  className="fas fa-times"
                  style={{ color: "red", fontSize: "24px" }}
                ></i>
              </div>
            );
          } else {
            return "";
          }
        },
      },
    },
    {
      name: "appointment",
      label: "Appointment",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        customBodyRender: (value) => {
          if (value === "approved") {
            return (
              <div
                style={{
                  width: "24px",
                  textAlign: "center",
                  marginLeft: "15px",
                }}
              >
                <i
                  className="fas fa-check"
                  style={{ color: "green", fontSize: "24px" }}
                ></i>
              </div>
            );
          } else if (value === "reject") {
            return (
              <div
                style={{
                  width: "24px",
                  textAlign: "center",
                  marginLeft: "15px",
                }}
              >
                <i
                  className="fas fa-times"
                  style={{ color: "red", fontSize: "24px" }}
                ></i>
              </div>
            );
          } else {
            return "";
          }
        },
      },
    },
  ];

  const dashboardOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: dashboardCount,
    rowsPerPage: dashboardPageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    onChangeRowsPerPage: (num) => {
      //   setLimit(num);
      //   setNxtPgInfo("");
      //   setPrevPgInfo("");
      //   setIsPrevOrNext("");
    },
    onSearchChange: (searchText) => {
      if (searchText !== null) {
        //setDashboardSearchText(searchText);
      } else {
        //setDashboardSearchText("");
      }
    },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setDashboardSortColumn(sortColumn);
        await setDashboardSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setDashboardSortColumn(sortColumn);
        await setDashboardSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      setDashboardStart(page * dashboardPageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const validateStatus = () => {
    let isValid = true;
    if (!formData?.candidateId && !formData?.fromdate && !formData?.todate) {
      isValid = false;
    }
    return isValid;
  };

  const getCandidateStatus = async (validateflag: any) => {
    try {
      showLoader();
      const response = await APICall(GetAssociateStageStatus, "POST", {
        AssociateId: formData?.candidateId?.id,
        FromDate: formData?.fromdate
          ? moment(formData?.fromdate).format("yyyy-MM-DD")
          : null,
        ToDate: formData?.todate
          ? moment(formData?.todate).format("yyyy-MM-DD")
          : null,
        PageNumber: dashboardStart,
        PageSize: dashboardPageSize,
        SortColumn: dashboardSortColumn,
        SortOrder: dashboardSortColumn,
        IsExcel: false,
      });

      if (response?.status === 0 && response?.data.length > 0) {
        console.log(response?.data, "Associate");
        setdashboard(response?.data);
        setDashboardCount(response?.data[0].count);
      } else {
        setdashboard([]);
        setDashboardCount(0);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  async function fetchDropdown(searchText = "", code = "", value = null) {
    try {
      const response = await APICall(
        GetCandidateDropdownData,
        "POST",
        {
          SearchText: searchText,
        },
        true
      );

      if (response?.status === 0) {
        if (response?.data) {
          return response?.data;
        } else {
          return [];
        }
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  async function handleSearchForAsyncDropdown(event, code, callback) {
    try {
      if (event?.length >= 1) {
        const data = await fetchDropdown(event, code);
        callback(data);
      } else {
        callback([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function onChange(event, code) {
    try {
      setFormData((prev) => {
        return { ...prev, [code]: event };
      });
    } catch (error) {
    } finally {
    }
  }
  const blueStripStyle: React.CSSProperties = {
    backgroundColor: "#00a9ff",
    padding: "10px",
  };

  const SearchFilter = async () => {
    if (!validateStatus()) {
      notify(1, "Please select alteast one from the below control");
      return;
    }
    await getCandidateStatus(true);
  };

  return (
    <>
      <div className="form-main">
        <div className="page-title w-100">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-4 col-md-4">
              <div className="row">
                <i className="fa-solid fa-home mt-3 mr-3 ml-3" style={{color:"white", cursor:'pointer'}} onClick={() => {
                  navigate('/Bluecollar');
                }}></i>
                <h4>Candidate Satus</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-2" id="blue-collar-pages">
        <div className="row">
          <div className="col-md-3 col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                From Date <span style={{ color: "red" }}></span>
              </label>
              <DateForm
                placeholder={"Birth Date"}
                isDisabled={false}
                value={formData?.fromdate || null}
                onChange={(val: any) => {
                  onChange(val, "fromdate");
                }}
              />
            </div>
          </div>

          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                To Date <span style={{ color: "red" }}></span>
              </label>
              <DateForm
                placeholder={"Birth Date"}
                isDisabled={false}
                value={formData?.todate || null}
                onChange={(val: any) => {
                  onChange(val, "todate");
                }}
              />
            </div>
          </div>
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Candidate Id / Aadhaar No <span style={{ color: "red" }}></span>
              </label>
              <SelectForm
                isClearable
                async
                options={(searchString, cb) => {
                  handleSearchForAsyncDropdown(searchString, "candidateId", cb);
                }}
                placeholder={"Select candidate Id"}
                onChange={(val: any) => {
                  onChange(val, "candidateId");
                }}
                isMulti={false}
                noIndicator={false}
                noSeparator={false}
                value={formData?.candidateId}
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mobile-view">
            <label className="col-form-label">&nbsp;</label>
            <div className="form-group">
              <button
                onClick={() => {
                  onResetData();
                }}
                disabled={false}
                className="btn btn-secondary mr-2"
              >
                <i className="fa-solid fa-circle-xmark mr-2"></i> Clear
              </button>
              <button
                onClick={() => {
                  //getCandidateStatus(true);
                  SearchFilter();
                }}
                disabled={false}
                className="btn btn-filter-submit mr-2"
              >
                <i className="fa-solid fa-magnifying-glass mr-2"></i> Search
              </button>
            </div>
          </div>
        </div>

        <div className="SectionSubmit clearfix my-3 text-right">
          <button
            onClick={() => {
              handleExporttoExcel();
            }}
            disabled={false}
            className="btn btn-primary "
          >
            <i className="fa fa-file-alt mr-2"></i> Export to Excel
          </button>
        </div>
        <div className="mb-3">
          <DynamicGrid
            data={dashboard}
            columns={gridColumns}
            options={dashboardOptions}
          />
        </div>
      </div>
    </>
  );
};

export default Candidate;
