import React, { useState, useContext } from "react";
import InputForm from "../../Components/InputForm/InputForm";

import SelectForm from "../../Components/SelectForm/SelectForm";
import { event } from "jquery";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import { ExpandMore, HideImageOutlined } from "@mui/icons-material";
import defaultProfileImgPic from "../../Assets/Images/thumbnail.jpg";
import { Modal } from "react-bootstrap";

import { APICall } from "../../Helpers/APICalls";
import { LoaderContext } from "../../Helpers/Context/Context";
import {
  GetAadhaarDetails,
  GetAadhaarOtp,
} from "../../Helpers/APIEndPoints/EndPoints";

function SecurityScreening() {
  const [aadharNumber, setAadharNumber] = useState("");
  const [aadharOTP, setAadharOTP] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [refid, setrefid] = useState(null);
  const [showOtpSection, setShowOtpSection] = useState(false);
  const [aadhaarDetails, setAadhaarDetails] = useState<any>({});

  const { showLoader, hideLoader } = useContext(LoaderContext);

  const setAadharNumberFn = (event) => {
    setAadharNumber(event.target.value); // add validation
  };
  const setAadharOTPFn = (event) => {
    setAadharOTP(event.target.value); // add validation
  };

  const calculateAgeFromDOB = (birthDate) => {
    // Parse the birthDate string into a Date object
    var dob = new Date(birthDate);

    // Get the current date
    var currentDate = new Date();

    // Calculate the difference in years
    var age = currentDate.getFullYear() - dob.getFullYear();

    // Adjust the age if the current date has not passed the birth month yet
    if (
      currentDate.getMonth() < dob.getMonth() ||
      (currentDate.getMonth() === dob.getMonth() &&
        currentDate.getDate() < dob.getDate())
    ) {
      age--;
    }

    return age;
  };
  const verifyAadhaarNumber = async () => {
    const responseFromAadhar = await APICall(GetAadhaarOtp, "POST", {
      AadharNumber: aadharNumber,
    });

    if (responseFromAadhar?.status == 0 && responseFromAadhar?.data) {
      console.log(responseFromAadhar);
      setrefid(responseFromAadhar?.data?.ref_id || 0);
      setShowOtpSection(true);
    }
  };

  const verifyAadhaarOTP = async () => {
    const responseFromAadharOTP = await APICall(GetAadhaarDetails, "POST", {
      OTP: aadharOTP,
      RefId: refid,
    });
    ////

    if (responseFromAadharOTP?.status == 0 && responseFromAadharOTP?.data) {
      console.log(responseFromAadharOTP);
      setAadhaarDetails(responseFromAadharOTP?.data);
      setIsOpen(false);
      setShowOtpSection(false);
    }
  };
  return (
    <div>
      <div className="form-main px-3">
        <div className="page-title w-100">
          <div className="col-lg-12 p-0">
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <h4>Security Screening</h4>
              </div>
            </div>
            <hr
              style={{
                borderTop: "2px solid #3C5464",
                width: "100%",
                fontWeight: "bold",
              }}
            />
          </div>
        </div>
      </div>{" "}
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 d-flex justify-content-center align-items-start">
            <div className="card Prof_img">
              <img src={"QR"} className="card-img-top" alt="Thumbnail Image" />

              <div className="SectionSubmit mb-4 clearfix mt-2">
                <button
                  onClick={() => setIsOpen(true)}
                  disabled={false}
                  className="btn btn-secondary float-right ml-2"
                >
                  {/* <i className="fa-solid fa-refresh"></i> */}
                  Validate
                </button>

                <button
                  onClick={verifyAadhaarNumber}
                  disabled={false}
                  className="btn btn-filter-submit float-right ml-2 "
                >
                  <i className="fas fa-qrcode"></i> Scan Aadhar
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-3 d-flex justify-content-center align-items-start">
            <div className="card Prof_img" style={{ height: "295px" }}>
              <img
                src={defaultProfileImgPic}
                className="card-img-top"
                alt="Thumbnail Image"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-6">
                <label className="col-form-label">Name</label>
                <sup></sup>
                <InputForm
                  className="form-control"
                  placeholder="Name"
                  isDisabled={true}
                  onChange={""}
                  textArea={false}
                  value={aadhaarDetails?.name}
                  maxLength="255"
                />
              </div>

              <div className="col-md-6">
                <label className="col-form-label">Mobile Number</label>
                <sup></sup>
                <InputForm
                  className="form-control"
                  placeholder="Mobile Number"
                  isDisabled={true}
                  onChange={""}
                  textArea={false}
                  value={""}
                  maxLength="255"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12 mt-3">
          <Accordion className="mb-3">
            <AccordionSummary
              id=""
              style={{ background: "#3C5464" }}
              className="text-white acc_close"
              expandIcon={<ExpandMore />}
            >
              <div className="row d-flex align-items-center acc-heading">
                <div className="col-lg-10 col-md-10 col-sm-12">
                  <p style={{ fontSize: "16px" }}>Aadhaar Card Details</p>
                </div>
                <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
              </div>
            </AccordionSummary>
            <AccordionDetails className="page_heading">
              <div className="row">
                <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                  <label className="col-form-label">Name</label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control"
                    placeholder="Enter Name"
                    isDisabled={aadhaarDetails?.name ? true : false}
                    onChange={""}
                    textArea={false}
                    value={aadhaarDetails?.name}
                    maxLength="255"
                  />
                </div>
                <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                  <label className="col-form-label">Age</label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control"
                    placeholder="Enter Age"
                    isDisabled={aadhaarDetails?.dob ? true : false}
                    onChange={""}
                    textArea={false}
                    value={calculateAgeFromDOB(aadhaarDetails?.dob)}
                    maxLength="255"
                  />
                </div>
                <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                  <label className="col-form-label">Date of Birth</label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control"
                    placeholder="Enter Date of Birth"
                    isDisabled={aadhaarDetails?.dob ? true : false}
                    onChange={""}
                    textArea={false}
                    value={aadhaarDetails?.dob}
                    maxLength="255"
                  />
                </div>
                <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                  <label className="col-form-label">Address</label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control"
                    placeholder="Enter Address"
                    isDisabled={aadhaarDetails?.address ? true : false}
                    onChange={""}
                    textArea={true}
                    value={aadhaarDetails?.address}
                    maxLength="255"
                  />
                </div>
                <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                  <label className="col-form-label">Mobile number</label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control"
                    placeholder="Enter Mobile number"
                    isDisabled={false}
                    onChange={""}
                    textArea={false}
                    value={""}
                    maxLength="255"
                  />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion className="mb-3">
            <AccordionSummary
              id=""
              style={{ background: "#3C5464" }}
              className="text-white acc_close"
              expandIcon={<ExpandMore />}
            >
              <div className="row d-flex align-items-center acc-heading">
                <div className="col-lg-10 col-md-10 col-sm-12">
                  <p style={{ fontSize: "16px" }}>Decision</p>
                </div>
                <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
              </div>
            </AccordionSummary>
            <AccordionDetails className="page_heading">
              <div className="row">
                <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                  <label className="col-form-label">Decision</label>
                  <sup>*</sup>
                  <SelectForm
                    isClearable
                    options={[]}
                    placeholder={"Select Decision"}
                    isDisabled={false}
                    onChange={""}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                    value={""}
                  />
                </div>

                <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                  <label className="col-form-label">Reason of Rejection</label>
                  <sup>*</sup>
                  <SelectForm
                    isClearable
                    options={[]}
                    placeholder={"Select Reason of Rejection"}
                    isDisabled={false}
                    onChange={""}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                    value={""}
                  />
                </div>

                <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                  <label className="col-form-label">Remark</label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control"
                    placeholder="Enter Remark"
                    isDisabled={true}
                    onChange={""}
                    textArea={true}
                    value={""}
                    maxLength="255"
                  />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      {/* <InputForm
        className="form-control"
        placeholder={"Enter Aadhar Number"}
        isDisabled={false}
        textArea={false}
        value={aadharNumber}
        onChange={(event) => setAadharNumberFn(event)}
        maxLength="255"
      />
      <strong>aadharNumber:{aadharNumber}</strong>
      <button
        className="btn ml-lg-0 ml-md-0 ml-1"
        onClick={(event) => verifyAadharDetails(event)}>
        Verify
      </button> */}
      <Modal
        show={modalIsOpen}
        onHide={() => setIsOpen(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">Enter Aadhaar Number!</div>
        </div>
        {!showOtpSection && (
          <Modal.Body>
            <div className="col-md-6">
              <label className="col-form-label">Aadhaar Number</label>
              <sup></sup>
              <InputForm
                className="form-control"
                placeholder={"Enter Aadhar Number"}
                isDisabled={false}
                textArea={false}
                value={aadharNumber}
                onChange={(event) => setAadharNumberFn(event)}
                maxLength="255"
              />
            </div>
          </Modal.Body>
        )}
        {showOtpSection && (
          <Modal.Body>
            <div className="col-md-6">
              <label className="col-form-label">Please Enter OTP</label>
              <sup></sup>
              <InputForm
                className="form-control"
                placeholder={"Please Enter OTP"}
                isDisabled={false}
                textArea={false}
                value={aadharOTP}
                onChange={(event) => setAadharOTPFn(event)}
                maxLength="255"
              />
            </div>
          </Modal.Body>
        )}
        <Modal.Footer>
          <button
            className="btn"
            style={{
              marginLeft: 5,
              color: "black !important",
              borderColor: "#ACACAC",
            }}
            onClick={showOtpSection ? verifyAadhaarOTP : verifyAadhaarNumber}
          >
            Confirm
          </button>
          <button
            className="btn btn-cancel"
            style={{
              marginLeft: 5,
              color: "black !important",
              borderColor: "#ACACAC",
            }}
            onClick={() => setIsOpen(false)}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SecurityScreening;
