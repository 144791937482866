import React, { useState, useEffect, useContext, useReducer } from "react";
import InputForm from "../../../Components/InputForm/InputForm";
import SelectForm from "../../../Components/SelectForm/SelectForm";
import DateForm from "../../../Components/DateForm/DateForm";
import AccordianCustom from "./AccordianCustom";

import calenderClose from "../../../Assets/Images/calendar-regular-close.png";
import calenderRefresh from "../../../Assets/Images/calendar-regular-refresh.png";

import {
  GetCommonDropdownForScheduling,
  GetCourseById,
  InsertUpdateCourseSchedule,
  GetScheduleDetailsByCourseIdAndScheduleId,
  CourseScheduleById,
  InsertUpdateSessionSchedule,
  validateSessionSchedule,
  getLMSDomainDropDownData,
  duplicateCourseSchedule,
  manageSessionSchedules,
  meetingActionApi,
} from "../../../Helpers/APIEndPoints/LMS_EndPoints";
import {
  APICall,
  APICallWithSignal,
  Decrypt,
  Encrypt,
} from "../../../Helpers/APICalls";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LoaderContext } from "../../../Helpers/Context/Context";
import Courses from "../Courses";
import useUserContext from "../../../Helpers/ZustandStore/UserContextStore";
import notify from "../../../Helpers/ToastNotification";
import moment from "moment";
import { event } from "jquery";
import { start } from "repl";
import session from "redux-persist/lib/storage/session";
import SessionComponent from "../components/SessionComponent";
import async from "react-select/dist/declarations/src/async/index";
import { Modal } from "react-bootstrap";
import { Tooltip } from "@mui/material";

interface IOptions {
  label?: string;
  value?: number | string;
  code?: string;
}

interface IFormData {
  id?: number | any;
  course_name?: string;
  training_days_id?: IOptions | boolean | any;
  training_days_centre?: IOptions[] | boolean | any;
  target_learner_group?: IOptions | boolean | any;
  instructor?: IOptions | boolean | any;
  co_ordinator?: IOptions | boolean | any;
  start_date?: Date | string | boolean | any;
  end_date?: Date | string | boolean | any;
  time_zone?: Date | string | boolean | any;
  hours_per_day?: number | boolean | any;
  start_datetime?: Date | string | any;
  end_datetime?: Date | string | any;
  journey_by_courseid?: IOptions | string | number | any;
  course_scheduleName?: string | number | any;
  calendarBlocking?: IOptions | boolean | any;
  guests?: IOptions | boolean | any;
  meetingDescription?: string | number | any;
  meetingId?: string | number | null;
  meetingAction?: boolean | null;
}

const AddScheduleCourse = () => {
  const { state } = useLocation();
  const [isDisable, setIsDisable] = useState(false);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { userDetails, currentRoleId }: any = useUserContext();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<IFormData>({});
  const [disableAttribute, setDisableAttribute] = useState<IFormData>({});
  const [formErrors, setFormErrors] = useState<IFormData>({});
  const [options, setOptions] = useState<IFormData>({});
  const [sessionsData, setSessionsData] = useState<IFormData[]>([]);
  const [courseId, setSetCourseId] = useState(0);
  const [courseScheduleId, setCourseScheduleId] = useState(0);
  const [sessionOptions, setSessionOptions] = useState([]);
  const [triggerSave, setTriggerSave] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [isCourseScheduleSave, setIsCourseScheduleSave] = useState(false);
  const [canDuplicate, setCanDuplicate] = useState(false);
  const [canRestore, setCanRestore] = useState(false);
  const [showSessionScheduleModal, setShowSessionScheduleModal] =
    useState(false);
  const [modalAction, setModalAction] = useState("");
  const [currentSessionScheduleId, setCurrentSessionScheduleId] = useState(0);
  const [domainOption, setDomainOption] = useState<any>([]);
  const [domain, setDomain] = useState(null);

  const [isTrainerMandetory, setIsTrainerMandetory] = useState(false);

  const [hideAdditionalControls, setHideAdditionalMeetControls] =
    useState(true);

  const [meetControlsDisabled, setMeetControlsDisabled] = useState(false);

  const [disableMeetInSession, setDisableMeetInSession] = useState(false);

  useEffect(() => {
    setDisableAttribute((prev: any) => {
      return { ...prev, course_name: true };
    });

    const helper = async () => {
      if (!state?.courseId && !state?.courseScheduleId) {
        const urlSearchString = window.location.search;
        const params = new URLSearchParams(urlSearchString);

        const encryptedCourseId = params.get("courseId")?.replaceAll(" ", "+");
        const encryptedCourseScheduleId = params
          .get("courseScheduleId")
          ?.replaceAll(" ", "+");

        const encryprtesIsCourseScheduleSave = params
          .get("isCourseScheduleSave")
          ?.replaceAll(" ", "+");

        const decryptedCourseId = await Decrypt(encryptedCourseId);
        const decryptedCourseScheduleId = await Decrypt(
          encryptedCourseScheduleId
        );
        const decryptedIsCourseScheduleSave = await Decrypt(
          encryprtesIsCourseScheduleSave
        );

        setCourseScheduleId(parseInt(decryptedCourseScheduleId));
        setSetCourseId(parseInt(decryptedCourseId));

        if (decryptedIsCourseScheduleSave === "true") {
          setIsCourseScheduleSave(true);
        }

        //to disable incase of view
        const isDisable = params.get("isDisable")?.replaceAll(" ", "+");

        if (isDisable !== undefined) {
          setIsDisable(Boolean(isDisable));
        }

        // To duplicate schedule
        const canDuplicate = params.get("canDuplicate")?.replaceAll(" ", "+");

        if (canDuplicate !== undefined) {
          setCanDuplicate(Boolean(canDuplicate));
        }
      }
    };
    helper();
    getLMSDomainDropDownDataApiCall();
  }, []);

  useEffect(() => {
    if (state?.courseId && state?.courseScheduleId) {
      setCourseScheduleId(state?.courseScheduleId);
      setSetCourseId(state?.courseId);
    }
  }, [state]);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const fetchData = async () => {
      await handleFetchCoursesById(signal);
    };

    fetchData();

    return () => {
      abortController.abort();
    };
  }, [courseId, state]);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchData = async () => {
      await fetchCommonDropdownOptions(signal);
    };

    fetchData();

    return () => {
      abortController.abort();
    };
  }, [courseId]);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    if (courseScheduleId > 0 && options?.journey_by_courseid?.length > 0) {
      const fetchData = async () => {
        await GetScheduleDetailsByCourseIdAndScheduleIdAsync(signal);
        await CourseScheduleByIdAsync(signal);
      };
      fetchData();
    }

    return () => {
      abortController.abort();
    };
  }, [courseScheduleId, options, triggerSave, state]);

  const getLMSDomainDropDownDataApiCall = async () => {
    const response = await APICall(getLMSDomainDropDownData, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      GetAll: courseScheduleId > 0 ? true : false,
    });

    if (response?.status === 0 && response?.data.length > 0) {
      setDomainOption(response?.data);
    }
  };

  const currentDayValidation = (inputDate) => {
    const currentDate = moment();
    const inputMoment = moment(inputDate);

    return inputMoment.isSameOrAfter(currentDate, "day");
  };

  function validateCourseSchedule() {
    try {
      let ErrorObj = {};

      //Basic validations

      if (
        formData?.training_days_id === null ||
        formData?.training_days_id === undefined ||
        formData?.training_days_id == 0
      ) {
        ErrorObj["training_days_id"] = "Training days required.";
      } else {
        delete ErrorObj["training_days_id"];
      }

      if (isTrainerMandetory) {
        if (
          formData?.instructor === null ||
          formData?.instructor === undefined ||
          formData?.instructor?.length === 0 ||
          formData?.instructor.join(",").trim().length === 0
        ) {
          ErrorObj["instructor"] = "Trainer required.";
        } else {
          delete ErrorObj["instructor"];
        }
      } else {
        delete ErrorObj["instructor"];
      }

      if (
        formData?.co_ordinator === null ||
        formData?.co_ordinator === undefined ||
        formData?.co_ordinator?.length === 0 ||
        formData?.co_ordinator.join(",").trim().length === 0
      ) {
        ErrorObj["co_ordinator"] = "Co-ordinator required.";
      } else {
        delete ErrorObj["co_ordinator"];
      }

      if (
        formData?.course_scheduleName == null ||
        formData?.course_scheduleName == undefined ||
        String(formData?.course_scheduleName).trim().length == 0
      ) {
        ErrorObj["course_scheduleName"] = "Course Schedule Name required.";
      }

      if (
        formData?.journey_by_courseid == null ||
        formData?.journey_by_courseid == undefined ||
        String(formData?.journey_by_courseid).trim().length == 0
      ) {
        ErrorObj["journey_by_courseid"] = "Journey required.";
      }

      if (
        formData?.start_date === null ||
        formData?.start_date === undefined ||
        !moment(formData.start_date).isValid()
      ) {
        ErrorObj["start_date"] = "Start Date required.";
      }
      // else if (
      //   courseScheduleId == 0 &&
      //   moment(formData?.start_date).isBefore(new Date())
      // ) {
      //   ErrorObj["start_date"] =
      //     "Start Date should be greather or equal to current date.";
      // }
      else {
        delete ErrorObj["start_date"];
      }

      if (
        formData?.end_date === null ||
        formData?.end_date === undefined ||
        !moment(formData.end_date).isValid()
      ) {
        ErrorObj["end_date"] = "End Date required.";
      } else {
        delete ErrorObj["end_date"];
      }

      if (
        formData?.time_zone === null ||
        formData?.time_zone === undefined ||
        !formData?.time_zone?.value
      ) {
        ErrorObj["time_zone"] = "Time Zone required.";
      } else {
        delete ErrorObj["time_zone"];
      }

      if (
        formData?.hours_per_day === null ||
        formData?.hours_per_day === undefined ||
        formData?.hours_per_day == 0
      ) {
        ErrorObj["hours_per_day"] = "Hours per day required.";
      } else if (Number.isNaN(Number(formData?.hours_per_day))) {
        ErrorObj["hours_per_day"] = "Please enter Number only.";
      } else if (Number(formData.hours_per_day) > 12) {
        ErrorObj["hours_per_day"] = "Hours cannot be greather than 12";
      } else {
        delete ErrorObj["hours_per_day"];
      }

      //Checking Start date is greather End Date
      if (
        ErrorObj["end_date"] === undefined &&
        ErrorObj["start_date"] === undefined
      ) {
        if (moment(formData.start_date).isAfter(formData.end_date)) {
          ErrorObj["start_date"] = "Start date must be smaller than End date.";
        } else {
          delete ErrorObj["start_date"];
        }
      }

      if (!meetControlsDisabled) {
        if (
          formData?.calendarBlocking === null ||
          formData?.calendarBlocking === undefined ||
          !formData?.calendarBlocking?.value
        ) {
          ErrorObj["calendarBlocking"] = "Calendar Blocking required.";
        } else {
          delete ErrorObj["calendarBlocking"];
        }

        // if (
        //   !hideAdditionalControls &&
        //   (formData?.meetingDescription == null ||
        //     formData?.meetingDescription == undefined ||
        //     String(formData?.meetingDescription).trim().length == 0)
        // ) {
        //   ErrorObj["meetingDescription"] = "Meeting Description required.";
        // } else
        if (
          !hideAdditionalControls &&
          formData?.meetingDescription.trim().length > 5000
        ) {
          ErrorObj["meetingDescription"] = "Maximum 5000 characters allowed";
        } else {
          delete ErrorObj["meetingDescription"];
        }
      }

      setFormErrors(ErrorObj);

      const isFormError = Object.keys(ErrorObj).length > 0;
      return isFormError;
    } catch (error) {
      console.error(error);
    }
  }

  async function handleCourseScheduleSubmit(event) {
    try {
      event.stopPropagation();

      let formError = validateCourseSchedule();

      if (formError) return;
      setBtnDisable(true);
      const paramOptions = {
        Id: !Number.isNaN(Number(courseScheduleId)) ? courseScheduleId : 0,
        TrainingDays: formData?.training_days_id?.value,
        TargetLearnerGroupId: formData?.target_learner_group?.value,
        TrainingCentreId:
          formData?.training_days_centre?.map((i) => i?.value).join(",") || "",
        InstructorIds:
          formData?.instructor?.map((i) => i?.value)?.join(",") || "",
        CoordinatorIds:
          formData?.co_ordinator?.map((i) => i?.value)?.join(",") || "",
        StartDate: moment(formData?.start_date).format("MM-DD-YYYY HH:mm"),
        EndDate: moment(formData?.end_date).format("MM-DD-YYYY HH:mm") || null,
        TimeZone: formData?.time_zone?.value || null,
        HoursPerDay: formData?.hours_per_day,
        CourseId: courseId,
        UserId: userDetails?.Id,
        JourneyId: formData?.journey_by_courseid?.value,
        CourseScheduleName: formData?.course_scheduleName,
        DomainIds:
          domain !== null && domain.length > 0
            ? domain?.map((event) => event?.value).join(",")
            : null,
        CalendarBlockingId: formData.calendarBlocking.value,
        GuestIds: formData?.guests?.map((i) => i?.value)?.join(",") || null,
        MeetingDescription:
          formData.meetingDescription !== ""
            ? formData.meetingDescription
            : null,
      };

      debugger;

      showLoader();
      const response = await APICall(
        InsertUpdateCourseSchedule,
        "POST",
        paramOptions
      );

      if (response?.status === 0 && response?.data) {
        // navigate("/addschedule", {
        //   state: {
        //     courseId: courseId,
        //     courseScheduleId: courseScheduleId,
        //     isCourseScheduleSave: true,
        //   },
        // });
        setCourseScheduleId(response?.data?.id);
        setTriggerSave((prev) => !prev);
        setIsCourseScheduleSave(true);
        notify(
          0,
          "Course schedule saved successfully, Proceed with session schedule."
        );
      } else if (response?.status == 5) {
        notify(1, response?.message);
      } else {
        console.error(response?.message);
        notify(1, "Something went wrong, Please try again later.");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setBtnDisable(false);
      hideLoader();
    }
  }

  function onChange(event, code) {
    try {
      setFormData((prev) => {
        return { ...prev, [code]: event };
      });
    } catch (error) {
    } finally {
    }
  }

  function handleClear(event) {
    event.stopPropagation();
    setFormData((prev) => {
      return {
        ...prev,
        training_days_id: null,
        training_days_centre: null,
        instructor: null,
        co_ordinator: null,
        start_date: null,
        end_date: null,
        time_zone: null,
        hours_per_day: "",
      };
    });

    setFormErrors({});
  }

  async function handleFetchCoursesById(signal) {
    try {
      showLoader();

      const response = await APICallWithSignal(
        GetCourseById,
        "POST",
        {
          Id: courseId,
        },
        true,
        null,
        signal
      );

      if (response?.status === 0) {
        const responseData = response?.data;

        setIsTrainerMandetory(responseData?.isTrainerMandetory);
        setFormData((prev) => {
          return { ...prev, course_name: responseData?.courseTitle || "" };
        });
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  }

  async function fetchCommonDropdownOptions(signal) {
    try {
      let newOptions = {};
      let keys = {
        training_days_id: [],
        training_days_centre: [],
        journey_by_courseid: [],
        instructor: [],
        calendarBlocking: [],
      };
      for await (const key of Object.keys(keys)) {
        const response = await APICallWithSignal(
          GetCommonDropdownForScheduling,
          "POST",
          {
            SearchText: "",
            DropdownValues: null,
            Code: key,
            CourseId: courseId,
          },
          true,
          null,
          signal
        );

        if (key === "journey_by_courseid") {
          if (response?.data.length === 1 && Number(courseScheduleId) === 0) {
            setFormData((prev) => {
              return { ...prev, ["journey_by_courseid"]: response?.data[0] };
            });
          }
        }

        if (response?.status === 0) {
          newOptions[key] = response?.data;
        } else {
          newOptions[key] = null;
        }
      }
      setOptions(newOptions);
      return newOptions;
    } catch (error) {
    } finally {
      hideLoader();
    }
  }

  async function GetScheduleDetailsByCourseIdAndScheduleIdAsync(signal) {
    try {
      const response = await APICallWithSignal(
        GetScheduleDetailsByCourseIdAndScheduleId,
        "POST",
        {
          courseId,
          courseScheduleId,
        },
        true,
        null,
        signal
      );

      if (response?.status == 0 && response?.data?.length > 0) {
        const responseData = response?.data;

        const arr = [];
        for await (const i of responseData) {
          let instructors = [];

          if (i.instructorIds) {
            instructors = await fetchDropdown(
              "",
              "instructor",
              i.instructorIds
            );
          }

          let co_ordinator = [];
          if (i.coordinatorIds) {
            co_ordinator = await fetchDropdown(
              "",
              "co_ordinator",
              i.coordinatorIds
            );
          }

          let guests = [];
          if (i.guestIds) {
            guests = await fetchDropdown("", "co_ordinator", i.guestIds);
          }

          let calendarBlocking = null;
          if (i.calendarBlockingId) {
            calendarBlocking = options.calendarBlocking?.find(
              (j) => j?.value == i.calendarBlockingId
            );
          }

          let training_days_centre = [];

          if (i.trainingCentreId) {
            training_days_centre = options.training_days_centre?.find(
              (j) => j?.value == i.trainingCentreId
            );
          }

          let obj = {
            id: i?.id,
            sessionId: i.sessionId,
            start_datetime: i.startDate,
            end_datetime: i.endDate,
            //   start_datetime: moment(i.startDate, "DD-MM-YYYY HH:mm").isValid()
            //   ? moment(i.startDate, "DD-MM-YYYY HH:mm")
            //   : null,
            // end_datetime: moment(i.endDate, "DD-MM-YYYY HH:mm").isValid()
            //   ? moment(i.endDate, "DD-MM-YYYY HH:mm")
            //   : null,
            training_days_centre: training_days_centre,
            instructor: instructors,
            co_ordinator: co_ordinator,
            time_zone: formData?.time_zone,
            session_title: i?.sessionTitle,
            courserScheduleId: courseScheduleId,
            isTrainerMandetory: i?.isTrainerMandetory,
            courseId,
            calendarBlocking:
              calendarBlocking !== null ? calendarBlocking : null,
            guests: guests.length > 0 ? guests : null,
            meetingDescription: i?.meetingDescription,
            meetingId: i?.meetingId,
            meetingAction: i?.meetingAction,
          };
          arr.push(obj);
        }
        setSessionsData(arr);
      } else {
        setSessionsData([]);
      }
    } catch (error) {}
  }

  async function CourseScheduleByIdAsync(signal) {
    try {
      const response = await APICallWithSignal(
        CourseScheduleById,
        "POST",
        {
          courseScheduleId,
        },
        true,
        null,
        signal
      );

      if (response?.status === 0) {
        const responseData = response?.data;
        setCanRestore(response?.data.canRestore);

        const domainIds =
          responseData?.domainIds !== ""
            ? responseData?.domainIds?.split(",")
            : null;
        const domainArray = [];

        if (domainIds) {
          domainIds?.map((value) => {
            const domainData = domainOption?.find(
              (event) => event.value === Number(value)
            );
            domainArray.push(domainData);
          });
        }

        debugger;
        setDomain(domainArray.length > 0 ? domainArray : null);
        const journey_by_courseId = options.journey_by_courseid?.find(
          (option) => option?.value == response?.data?.journeyId
        );

        const training_days_id = options.training_days_id?.find(
          (i) => i?.value == responseData.trainingDays
        );
        const trainingCentreId =
          responseData?.trainingCentreId?.split(",")?.map((i) => Number(i)) ||
          [];
        const training_days_centre = options.training_days_centre?.filter((i) =>
          trainingCentreId?.some((j) => j == i?.value)
        );
        const target_learner_group = options.target_learner_group?.find(
          (i) => i?.value == responseData?.targetLearnerGroupId
        );

        const instructorIds =
          responseData?.instructorIds?.split(",")?.map((i) => Number(i)) || [];
        const instructor = options.instructor?.filter((i) =>
          instructorIds?.some((j) => j == i?.value)
        );

        const co_ordinator = await fetchDropdown(
          "",
          "co_ordinator",
          responseData?.coordinatorIds
        );

        const calendarBlockingId = options.calendarBlocking?.find(
          (i) => i?.value == responseData.calendarBlockingId
        );

        if (
          calendarBlockingId !== undefined &&
          (calendarBlockingId.code.toLowerCase() === "online" ||
            calendarBlockingId.code.toLowerCase() === "offline")
        ) {
          setHideAdditionalMeetControls(false);
          setDisableMeetInSession(true);
        } else {
          setHideAdditionalMeetControls(true);
          setDisableMeetInSession(false);
        }

        setMeetControlsDisabled(responseData?.meetControlsIsDisabled);

        const guestIds =
          responseData?.guestIds !== null
            ? await fetchDropdown("", "co_ordinator", responseData?.guestIds)
            : null;

        const startDate = responseData?.startDate;

        const endDate = responseData?.endDate;

        // const startDate = moment(
        //   responseData?.startDate,
        //   "DD-MM-YYYY HH:mm:ss"
        // ).isValid()
        //   ? moment(responseData?.startDate, "DD-MM-YYYY HH:mm:ss")
        //   : null;

        // const endDate = moment(
        //   responseData?.endDate,
        //   "DD-MM-YYYY HH:mm:ss"
        // ).isValid()
        //   ? moment(responseData?.endDate, "DD-MM-YYYY HH:mm:ss")
        //   : null;

        let time_zone = await fetchDropdown(
          "",
          "time_zone",
          responseData?.timeZone
        );

        setIsTrainerMandetory(responseData?.isTrainerMandetory);

        setSessionOptions((prev) => {
          return {
            ...prev,
            training_days_centre,
            time_zone,
            instructor,
            co_ordinator,
            calendarBlocking: options.calendarBlocking,
          };
        });

        if (time_zone?.length > 0) {
          time_zone = time_zone[0];
        }

        setFormData((prev) => {
          return {
            ...prev,
            training_days_id,
            training_days_centre: training_days_centre,
            target_learner_group: target_learner_group,
            instructor: instructor,
            co_ordinator: co_ordinator,
            start_date: startDate,
            end_date: endDate,
            time_zone,
            hours_per_day: responseData?.hoursPerDay,
            journey_by_courseid: journey_by_courseId,
            course_scheduleName: responseData?.courseScheduleName,
            calendarBlocking: calendarBlockingId,
            guests: guestIds,
            meetingDescription: responseData?.meetingDescription,
            meetingId: responseData?.meetingId,
            meetingAction: responseData?.meetingAction,
          };
        });
      }
    } catch (error) {}
  }

  async function fetchDropdown(searchText = "", code = "", value = null) {
    try {
      const response = await APICall(
        GetCommonDropdownForScheduling,
        "POST",
        {
          SearchText: searchText,
          DropdownValues: value,
          Code: code,
          UserId: userDetails?.Id,
          RoleId: currentRoleId?.value,
        },
        true
      );

      if (response?.status === 0) {
        return response?.data;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  async function handleSearchForAsyncDropdown(event, code, callback) {
    try {
      if (event?.length >= 3) {
        const data = await fetchDropdown(event, code);
        callback(data);
      } else {
        callback([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleSubmitButton = async () => {
    if (isCourseScheduleSave && courseScheduleId > 0) {
      const res = await APICall(validateSessionSchedule, "POST", {
        CourseScheduleId: courseScheduleId,
      });

      if (res?.status === 0) {
        if (res?.data?.isError) {
          notify(1, res?.data?.errorMessage);
        } else {
          notify(res?.status, "All Schedule save sucessfully");
        }
      } else if (res?.status === 1) {
        notify(res?.status, res?.message);
      }
    } else {
      notify(1, "Course Schedule is not completed");
    }
  };

  const handleDuplicate = async () => {
    if (courseScheduleId > 0 && canDuplicate) {
      const res = await APICall(duplicateCourseSchedule, "POST", {
        CourseScheduleId: courseScheduleId,
      });

      if (res?.status === 0) {
        if (res?.data?.courseScheduleId > 0) {
          const encryptedCourseScheduleId = Encrypt(
            String(res?.data?.courseScheduleId)
          );
          const isCourseScheduleSave = Encrypt(String(true));
          const encryptedCourseId = Encrypt(String(courseId));

          navigate(
            `/addschedule?courseId=${encryptedCourseId}&courseScheduleId=${encryptedCourseScheduleId}&isCourseScheduleSave=${isCourseScheduleSave}`
          );

          notify(0, "Course duplicated sucessfully");
          // reload when new Id is returned
          window.location.reload();
        } else {
          console.log(res);
          notify(1, "Something went wrong");
        }
      } else if (res?.status === 1) {
        console.log(res);
        notify(1, "Something went wrong");
      }
    } else {
      notify(1, "Course Schedule is not completed");
    }
  };

  const handleScheduleRestore = async (event) => {
    event.stopPropagation();
    setShowSessionScheduleModal(true);
    setModalAction("restore");
  };

  const handleScheduleCopy = async (event, sessionScheduleId) => {
    event.stopPropagation();
    setShowSessionScheduleModal(true);
    setModalAction("copy");
    setCurrentSessionScheduleId(sessionScheduleId);
  };

  const handleScheduleDelete = async (event, sessionScheduleId) => {
    event.stopPropagation();
    setShowSessionScheduleModal(true);
    setModalAction("delete");
    setCurrentSessionScheduleId(sessionScheduleId);
  };

  const sessionScheduleModalAction = async (modalAction) => {
    try {
      await showLoader();
      switch (modalAction) {
        case "restore":
          const res = await APICall(manageSessionSchedules, "POST", {
            Mode: "RESTORE",
            UserId: userDetails.Id,
            RoleId: currentRoleId.value,
            CourseScheduleId: courseScheduleId,
          });

          if (res.status === 0) {
            setTriggerSave((prev) => !prev);
            notify(0, "Session Schedule restored successfully");
          } else {
            notify(1, "Reset failed, try again later");
            console.error("Reset went wrong", res);
          }
          break;
        case "copy":
          const res1 = await APICall(manageSessionSchedules, "POST", {
            Mode: "COPY",
            UserId: userDetails.Id,
            RoleId: currentRoleId.value,
            CourseScheduleId: courseScheduleId,
            SessionScheduleId: currentSessionScheduleId,
          });

          if (res1.status === 0) {
            setTriggerSave((prev) => !prev);
            notify(0, "Session Schedule copied successfully");
          } else {
            notify(1, "Copy failed, try again later");
            console.error("Copy went wrong", res1);
          }
          break;
        case "delete":
          const res2 = await APICall(manageSessionSchedules, "POST", {
            Mode: "DELETE",
            UserId: userDetails.Id,
            RoleId: currentRoleId.value,
            CourseScheduleId: courseScheduleId,
            SessionScheduleId: currentSessionScheduleId,
          });

          if (res2.status === 0) {
            setTriggerSave((prev) => !prev);
            notify(0, "Session Schedule deleted successfully");
          } else {
            notify(1, "Delete failed, try again later");
            console.error("Delete went wrong", res2);
          }
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setShowSessionScheduleModal(false);
      setModalAction("");
      setCurrentSessionScheduleId(0);
      await hideLoader();
    }
  };

  const meetingAction = async () => {
    try {
      showLoader();

      if (
        formData.meetingId !== null &&
        formData.meetingId != "" &&
        formData.meetingId != -1
      ) {
        const res = await APICall(meetingActionApi, "POST", {
          Mode: "CANCEL",
          CourseScheduleId: courseScheduleId,
          SessionScheduleId: 0,
        });
        if (res.status === 0) {
          setFormData((prev) => ({ ...prev, meetingAction: true }));
          notify(0, "Meeting cancelation has been queued");
        } else {
          setFormData((prev) => ({ ...prev, meetingAction: false }));
          console.error(res);
          console.error(1, "Something went wrong!");
        }
      } else if (
        formData.meetingId !== null &&
        formData.meetingId != "" &&
        formData.meetingId == -1
      ) {
        const res = await APICall(meetingActionApi, "POST", {
          Mode: "REINVITE",
          CourseScheduleId: courseScheduleId,
          SessionScheduleId: 0,
        });
        if (res.status === 0) {
          setFormData((prev) => ({ ...prev, meetingAction: true }));
          notify(0, "Meeting re-scheduling has been queued");
        } else {
          setFormData((prev) => ({ ...prev, meetingAction: false }));
          console.error(res);
          console.error(1, "Something went wrong!");
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="mt-4">
          <>
            <div className="breadcrumb modified-bredcrumb">
              <span>
                <ul>
                  <li>
                    <a href="/home">Home</a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        navigate("/Courses", {
                          state: { courseId: courseId, tab: "Schedule" },
                        });
                      }}
                    >
                      Schedule
                    </a>
                  </li>
                  <li>Manage Course Schedule</li>
                </ul>
              </span>
              <button
                onClick={() => {
                  window.close();
                }}
                disabled={false}
                className="btn btn-secondary float-right"
              >
                <i className="fa-solid fa-arrow-left"></i> Back
              </button>
            </div>
            <div className="row"></div>
          </>
        </div>
        <AccordianCustom
          header={"Course Schedule"}
          submitFunc={handleCourseScheduleSubmit}
          clearFunc={handleClear}
          btnDisable={isDisable ? true : btnDisable}
        >
          <div className="row">
            <div className="col-lg-3 col-sm-3 col-xs-4">
              <div className="mb-1">
                <label className="col-form-label">
                  Course <span style={{ color: "red" }}>*</span>
                </label>
                <InputForm
                  className="form-control"
                  placeholder={"Course"}
                  isDisabled={isDisable ? true : disableAttribute?.course_name}
                  textArea={false}
                  value={formData.course_name}
                  onChange={(val: any) => {
                    let value = val.target.value;
                    onChange(value, "course_name");
                  }}
                />
                <p style={{ color: "red" }}>{formErrors.course_name}</p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-3 col-xs-4">
              <div className="mb-1">
                <label className="col-form-label">
                  Course Schedule Name <span style={{ color: "red" }}>*</span>
                </label>
                <InputForm
                  className="form-control"
                  placeholder={"Course Schedule Name"}
                  isDisabled={isDisable ? true : false}
                  textArea={false}
                  value={formData?.course_scheduleName}
                  onChange={(val: any) => {
                    let value = val.target.value;
                    onChange(value, "course_scheduleName");
                  }}
                />
                <p style={{ color: "red" }}>{formErrors.course_scheduleName}</p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-3 col-xs-4">
              <div className="mb-1">
                <label className="col-form-label">
                  Journey <span style={{ color: "red" }}>*</span>
                </label>
                <SelectForm
                  value={formData?.journey_by_courseid}
                  placeholder={"Journey"}
                  options={options.journey_by_courseid}
                  onChange={(event) => {
                    onChange(event, "journey_by_courseid");
                  }}
                  isDisabled={
                    isDisable ? true : disableAttribute?.journey_by_courseid
                  }
                  isMulti={false}
                  noIndicator={false}
                  noSeparator={false}
                />
                <p style={{ color: "red" }}>
                  {formErrors?.journey_by_courseid}
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-3 col-xs-4">
              <div className="mb-1">
                <label className="col-form-label">
                  Training Days <span style={{ color: "red" }}>*</span>
                </label>
                <SelectForm
                  value={formData?.training_days_id}
                  placeholder={"Training Days"}
                  options={options.training_days_id}
                  onChange={(event) => {
                    onChange(event, "training_days_id");
                  }}
                  isDisabled={
                    isDisable ? true : disableAttribute?.training_days_id
                  }
                  noIndicator={false}
                  noSeparator={false}
                />
                <p style={{ color: "red" }}>{formErrors?.training_days_id}</p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-3 col-xs-4">
              <div className="dropdown mb-1">
                <label className="col-form-label">Training Centre</label>
                <SelectForm
                  value={formData?.training_days_centre}
                  placeholder={"Training Centre"}
                  options={options?.training_days_centre}
                  onChange={(event) => {
                    onChange(event, "training_days_centre");
                  }}
                  isMulti={true}
                  isDisabled={
                    isDisable ? true : disableAttribute?.training_days_centre
                  }
                  noIndicator={false}
                  noSeparator={false}
                />
                <p style={{ color: "red" }}>
                  {formErrors?.training_days_centre}
                </p>

                <div className="dropdown-content">
                  {formData?.training_days_centre?.length > 1 && (
                    <>
                      {formData?.training_days_centre?.map((i) => (
                        <p>{i?.label || ""}</p>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-3 col-xs-4">
              <div className="dropdown mb-1">
                <label className="col-form-label">
                  Trainer{" "}
                  {isTrainerMandetory && (
                    <>
                      <span style={{ color: "red" }}>*</span>
                    </>
                  )}
                </label>
                <SelectForm
                  isClearable
                  isSearchable
                  options={options?.instructor}
                  placeholder={"Trainer"}
                  isDisabled={isDisable ? true : disableAttribute?.instructor}
                  value={formData?.instructor}
                  onChange={(val: any) => {
                    onChange(val, "instructor");
                  }}
                  isMulti={true}
                  noIndicator={false}
                  noSeparator={false}
                />
                <p style={{ color: "red" }}>{formErrors?.instructor}</p>

                <div className="dropdown-content">
                  {formData?.instructor?.length > 1 && (
                    <>
                      {formData?.instructor?.map((i) => (
                        <p>{i?.label || ""}</p>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-3 col-xs-4">
              <div className="dropdown mb-1">
                <label className="col-form-label">
                  Co-ordinator <span style={{ color: "red" }}>*</span>
                </label>
                <SelectForm
                  isClearable
                  isSearchable
                  async
                  options={(searchString, cb) => {
                    handleSearchForAsyncDropdown(
                      searchString,
                      "co_ordinator",
                      cb
                    );
                  }}
                  placeholder={"Type to search"}
                  isDisabled={isDisable ? true : disableAttribute?.co_ordinator}
                  value={formData?.co_ordinator}
                  onChange={(val: any) => {
                    onChange(val, "co_ordinator");
                  }}
                  isMulti={true}
                  noIndicator={false}
                  noSeparator={false}
                />
                <p style={{ color: "red" }}>{formErrors?.co_ordinator}</p>

                <div className="dropdown-content">
                  {formData?.co_ordinator?.length > 1 && (
                    <>
                      {formData?.co_ordinator?.map((i) => (
                        <p>{i?.label || ""}</p>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-3 col-xs-4">
              <div className="mb-1">
                <label className="col-form-label">
                  Start Date <span style={{ color: "red" }}>*</span>
                </label>
                <DateForm
                  placeholder={"Start Date"}
                  isDisabled={isDisable ? true : disableAttribute?.start_date}
                  value={formData?.start_date || null}
                  onChange={(val: any) => {
                    onChange(val, "start_date");
                  }}
                />
                <p style={{ color: "red" }}>{formErrors?.start_date}</p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-3 col-xs-4">
              <div className="mb-1">
                <label className="col-form-label">
                  End Date <span style={{ color: "red" }}>*</span>
                </label>
                <DateForm
                  placeholder={"End Date"}
                  isDisabled={isDisable ? true : disableAttribute?.end_date}
                  value={formData?.end_date || null}
                  onChange={(val: any) => {
                    onChange(val, "end_date");
                  }}
                />
                <p style={{ color: "red" }}>{formErrors?.end_date}</p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-3 col-xs-4">
              <div className="mb-1">
                <label className="col-form-label">
                  Time Zone <span style={{ color: "red" }}>*</span>
                </label>
                <SelectForm
                  placeholder={"Type to search"}
                  async={true}
                  isDisabled={isDisable ? true : disableAttribute?.time_zone}
                  textArea={false}
                  value={
                    formData?.time_zone
                      ? formData?.time_zone
                      : setFormData({
                          ...formData,
                          time_zone: {
                            value: 9257,
                            label: "IST(UTC+5:30)",
                            code: "CODETIMEZONE111",
                          },
                        })
                  }
                  onChange={(val: any) => {
                    onChange(val, "time_zone");
                  }}
                  options={(searchString, cb) => {
                    handleSearchForAsyncDropdown(searchString, "time_zone", cb);
                  }}
                  noIndicator={false}
                  noSeparator={false}
                />
                <p style={{ color: "red" }}>{formErrors?.time_zone}</p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-3 col-xs-4">
              <div className="mb-1">
                <label className="col-form-label">
                  Hours Per Day <span style={{ color: "red" }}>*</span>
                </label>
                <InputForm
                  value={formData?.hours_per_day}
                  placeholder={"Hours Per Day"}
                  onChange={(event) => {
                    const value = event?.target?.value || "";
                    onChange(value, "hours_per_day");
                  }}
                  isDisabled={
                    isDisable ? true : disableAttribute?.hours_per_day
                  }
                  textArea={false}
                />
                <p style={{ color: "red" }}>{formErrors?.hours_per_day}</p>
              </div>
            </div>
            <div className="dropdown col-lg-3 col-sm-3 col-xs-4">
              <label className="col-form-label">Domains</label>
              {/* <sup>*</sup> */}
              <SelectForm
                isClearable
                options={domainOption}
                isOptionDisabled={(option) => !option?.isEnabled}
                placeholder={"Domains"}
                isDisabled={isDisable ? true : false}
                onChange={(e) => setDomain(e)}
                isMulti={true}
                noIndicator={false}
                noSeparator={false}
                value={domain}
              />
              {formErrors["domain"] && (
                <p style={{ color: "red" }}>{formErrors["domain"]}</p>
              )}
            </div>

            <div className="col-lg-3 col-sm-3 col-xs-4">
              <div className="row">
                <div
                  className={
                    formData.meetingId
                      ? "col-lg-10 col-sm-10 col-xs-10"
                      : "col-lg-12 col-sm-12 col-xs-12"
                  }
                >
                  <div className="mb-1">
                    <label className="col-form-label">
                      Calendar Blocking <span style={{ color: "red" }}>*</span>
                    </label>
                    <SelectForm
                      isClearable
                      isSearchable
                      options={options.calendarBlocking}
                      placeholder={"Select"}
                      isDisabled={isDisable ? true : meetControlsDisabled}
                      value={formData?.calendarBlocking}
                      onChange={(val: any) => {
                        if (
                          val &&
                          (val.code.toLowerCase() === "online" ||
                            val.code.toLowerCase() === "offline")
                        ) {
                          setHideAdditionalMeetControls(false);
                          setDisableMeetInSession(true);
                        } else {
                          setHideAdditionalMeetControls(true);
                          setDisableMeetInSession(false);
                          setFormData((prev) => ({
                            ...prev,
                            guests: null,
                            meetingDescription: "",
                          }));
                        }
                        onChange(val, "calendarBlocking");
                      }}
                      isMulti={false}
                      noIndicator={false}
                      noSeparator={false}
                    />

                    <p style={{ color: "red" }}>
                      {formErrors?.calendarBlocking}
                    </p>
                  </div>
                </div>
                {formData.meetingId && (
                  <div className={"col-lg-2 col-sm-2 col-xs-2"}>
                    <label className="col-form-label"></label>{" "}
                    <div className="mt-3">
                      <Tooltip
                        title={
                          formData.meetingId != -1
                            ? "Cancel meeting"
                            : "Re-schedule meeting"
                        }
                      >
                        <button
                          className="pngIcon"
                          disabled={formData.meetingAction}
                          onClick={() => {
                            meetingAction();
                          }}
                        >
                          {formData.meetingId != -1 ? (
                            // <
                            // className="fa-solid fa-stop-circle"
                            // className="fa-solid fa-times"
                            // aria-hidden="true"
                            // >
                            <img
                              className="name_icon"
                              src={calenderClose}
                            ></img>
                          ) : (
                            // </i>
                            // <i
                            // className="fa-solid fa-refresh"
                            // >
                            <img
                              className="name_icon"
                              src={calenderRefresh}
                            ></img>
                            // </i>
                          )}
                        </button>
                      </Tooltip>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {!hideAdditionalControls && (
              <>
                <div className="col-lg-3 col-sm-3 col-xs-4">
                  <div className="dropdown mb-1">
                    <label className="col-form-label">Guests</label>
                    <SelectForm
                      isClearable
                      isSearchable
                      async
                      options={(searchString, cb) => {
                        handleSearchForAsyncDropdown(
                          searchString,
                          "co_ordinator",
                          cb
                        );
                      }}
                      placeholder={"Type to search"}
                      isDisabled={isDisable ? true : meetControlsDisabled}
                      value={formData?.guests}
                      onChange={(val: any) => {
                        onChange(val, "guests");
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                    />
                    <p style={{ color: "red" }}>{formErrors?.guests}</p>

                    <div className="dropdown-content">
                      {formData?.guests?.length > 1 && (
                        <>
                          {formData?.guests?.map((i) => (
                            <p>{i?.label || ""}</p>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-3 col-xs-4">
                  <div className="mb-1">
                    <label className="col-form-label">
                      Meeting Description{" "}
                      {/* <span style={{ color: "red" }}>*</span> */}
                    </label>
                    <InputForm
                      className="form-control"
                      placeholder={"Meeting Description"}
                      isDisabled={isDisable ? true : meetControlsDisabled}
                      textArea={true}
                      value={formData?.meetingDescription}
                      rows={4}
                      onChange={(val: any) => {
                        let value = val.target.value;
                        onChange(value, "meetingDescription");
                      }}
                    />
                    <p style={{ color: "red" }}>
                      {formErrors.meetingDescription}
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </AccordianCustom>
        <>
          {courseScheduleId > 0 && (
            <AccordianCustom
              header={"Session Schedule"}
              submitFunc={() => {}}
              clearFunc={() => {}}
              btnDisable={false}
              hideBtn={true}
              canRestore={canRestore}
              handleScheduleRestore={handleScheduleRestore}
            >
              <div className="p-4">
                {sessionsData.length > 0 &&
                  sessionsData?.map((session, index) => {
                    return (
                      <React.Fragment key={index}>
                        <SessionComponent
                          data={session}
                          options={sessionOptions}
                          timeZone={formData?.time_zone}
                          courseScheduleData={formData}
                          isDisable={isDisable}
                          handleScheduleCopy={handleScheduleCopy}
                          handleScheduleDelete={handleScheduleDelete}
                          disableMeetInSession={disableMeetInSession}
                          setMeetControlsDisabled={setMeetControlsDisabled}
                        />
                      </React.Fragment>
                    );
                  })}
              </div>
            </AccordianCustom>
          )}
        </>
        <>
          {!isDisable && (
            <>
              {courseScheduleId > 0 && (
                <div className="SectionSubmit mb-4 clearfix">
                  <button
                    onClick={() => {
                      handleSubmitButton();
                    }}
                    disabled={false}
                    className="btn btn-filter-submit float-right ml-2"
                  >
                    <i className="fa-solid fa-check"></i> Submit
                  </button>
                </div>
              )}
            </>
          )}
          {isDisable && canDuplicate && courseScheduleId > 0 && (
            <div className="SectionSubmit mb-4 clearfix">
              <button
                onClick={() => {
                  handleDuplicate();
                }}
                disabled={false}
                className="btn btn-filter-submit float-right ml-2"
              >
                <i className="fa fa-clone" aria-hidden="true"></i> Duplicate
              </button>
            </div>
          )}
        </>
      </div>

      <Modal
        show={showSessionScheduleModal}
        onHide={() => setShowSessionScheduleModal(false)}
        backdrop="static"
        keyboard={false}
        size="xl"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">Confirmation!</div>
        </div>
        <Modal.Body>
          <p>
            Are you sure, you want to{" "}
            {modalAction === "restore"
              ? "reset session schedule"
              : modalAction === "copy"
              ? "copy session schedule"
              : "delete session schedule"}
            ?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => {
              sessionScheduleModalAction(modalAction);
            }}
          >
            Yes
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowSessionScheduleModal(false);
              setModalAction("");
              setCurrentSessionScheduleId(0);
            }}
          >
            No
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddScheduleCourse;
