export const documentsAC = [
  "ProofBD",
  "POLYDOCL",
  "CRTIFCTE",
  "ATT",
  "ATTE",
  "HELATTCH",
  "ATTP",
  "WIPATTACH",
  "BNKATTCH",
  "ATTPE",
  "ATTPP",
  "SEVATTCH",
  "COATTCH",
  "PHYCATTCH",
];
