import React, { useContext, useEffect, useRef, useState } from "react";
import SelectForm from "../../Components/SelectForm/SelectForm";
import { LoaderContext } from "../../Helpers/Context/Context";
import { useNavigate } from "react-router-dom";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { APICall } from "../../Helpers/APICalls";
import {
  GetCommonDropdownForScheduling,
  GetThumbnailDetails,
  SetThumbnailDetails,
  deleteSignatureData,
  getSignatureDasboardDetails,
  getSignatureData,
  getUserAndRoleWiseAccess,
  saveSignatureData,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { Tooltip } from "@mui/material";
import notify from "../../Helpers/ToastNotification";
import { Modal } from "react-bootstrap";

const Signature = () => {
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [formData, setFormData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  let { userDetails, currentRoleId }: any = useUserContext();
  const [sortDirection, setSortDirection] = useState("");
  const [sortColumn, setSortColumn] = useState("");

  const [reSet, setReset] = useState(false);

  const [signatureFile, setSignatureFile] = useState(null);
  const signatureFileRef = useRef(null);

  const [signatureImage, setSignatureImage] = useState(null);

  const [showSignatureModel, setShowSignatureModel] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [formErrors, setFormErrors] = useState({});

  const [employeeName, setEmployeeName] = useState(null);

  const [signatureId, setSignatureId] = useState(0);

  async function handleSearchForAsyncDropdown(event, code, callback) {
    try {
      if (event?.length >= 3) {
        const data = await fetchDropdown(event, code);
        callback(data);
      } else {
        callback([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchDropdown(searchText = "", code = "", value = null) {
    try {
      const response = await APICall(
        GetCommonDropdownForScheduling,
        "POST",
        {
          SearchText: searchText,
          DropdownValues: value,
          Code: code,
          UserId: userDetails?.Id,
          RoleId: currentRoleId?.value,
        },
        true
      );

      if (response?.status === 0) {
        return response?.data;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  interface userAndRoleWiseAccess {
    isView?: boolean;
    isEdit?: boolean;
    isDelete?: boolean;
  }

  const [userAndRoleWiseData, setUserAndRoleWiseData] =
    useState<userAndRoleWiseAccess>({});

  const getUserAndRoleWiseAccessApiCall = async () => {
    const response = await APICall(getUserAndRoleWiseAccess, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      InterfaceName: "Session_Dashboard",
    });

    if (response?.status === 0) {
      setUserAndRoleWiseData(response?.data);
    } else {
      notify(response?.status, response?.message);
    }
  };

  useEffect(() => {
    getUserAndRoleWiseAccessApiCall();
  }, []);

  useEffect(() => {
    getSignatureDasboardDetailsAPICall();
  }, [page, pageSize, sortColumn, sortDirection, searchText, reSet]);

  const getSignatureDasboardDetailsAPICall = async () => {
    showLoader();

    try {
      const parameters = {
        PageNumber: page,
        PageSize: pageSize,
        SortColumn: sortColumn,
        SortOrder: sortDirection,
        SearchText: searchText,
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      };
      const response = await APICall(
        getSignatureDasboardDetails,
        "POST",
        parameters
      );
      if (response && response.data !== null && response.data.length > 0) {
        setFormData(response.data);
        setCount(response.data[0].count);
      } else if (response?.status === 1) {
        notify(1, response?.message);
        setFormData([]);
        setCount(0);
      } else {
        setFormData([]);
        setCount(0);
      }
    } catch (error) {
      console.error(error);
    }

    hideLoader();
  };

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: pageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      setPage(page * pageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const gridColumns = [
    {
      name: "Id",
      label: "Id",
      options: {
        display: false,
      },
    },

    {
      name: "employeeCode",
      label: "Employee Code",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "employeeName",
      label: "Employee Name",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "createdByName",
      label: "Created By",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta) => {
          let id = tableMeta.tableData[tableMeta.rowIndex].id;
          let signatureUrl =
            tableMeta.tableData[tableMeta.rowIndex].signatureUrl;
          let isActive = tableMeta.tableData[tableMeta.rowIndex].isActive;
          let color = isActive ? "green" : "red";
          let activate = isActive ? "Active" : "Inactive";

          return (
            <div style={{ width: "100px" }}>
              {/* {userAndRoleWiseData?.isEdit && (
                <>
                  <Tooltip title="Edit">
                    <a
                      className="mr-2"
                      style={{ fontSize: "15px" }}
                      onClick={(e) => {
                        e.preventDefault();
                        // navigate("/SessionEdit", {
                        //   state: { SessionId: value, isDisable: false },
                        // });
                        getSignatureDataApiCall(id);
                      }}
                    >
                      <i className="fas fa-edit"></i>
                    </a>
                  </Tooltip>
                </>
              )} */}
              {/* {!userAndRoleWiseData?.isEdit && userAndRoleWiseData?.isView && ( */}
              <Tooltip title="View">
                <a
                  className="mr-2"
                  style={{ fontSize: "15px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    showSignatureImage(signatureUrl);
                  }}>
                  <i className="fas fa-eye"></i>
                </a>
              </Tooltip>
              <Tooltip title="Delete">
                <a
                  className="mr-2"
                  style={{ fontSize: "15px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    setSignatureId(id);
                    setShowDeleteModal(true);
                  }}>
                  <i className="fas fa-trash"></i>
                </a>
              </Tooltip>
              {/* )} */}
            </div>
          );
        },
      },
    },
  ];

  const handleDeleteSignature = async () => {
    try {
      showLoader();

      const response = await APICall(deleteSignatureData, "POST", {
        Id: signatureId,
      });

      if (response.status === 0) {
        setReset(!reSet);
        setSignatureId(0);
        setShowDeleteModal(false);
        notify(response.status, response.message);
      } else if (response.status === 1) {
        notify(response.status, response.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const validateForm = () => {
    let errorObj = {};
    let isError = false;

    if (employeeName === null || employeeName === undefined) {
      errorObj = {
        ...errorObj,
        ["employeeName"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["employeeName"]: "",
      };
    }

    if (signatureFile === null || signatureFile === undefined) {
      errorObj = {
        ...errorObj,
        ["signatureFile"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["signatureFile"]: "",
      };
    }

    const isEmpty = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  const handleSubmitFunction = async () => {
    showLoader();

    if (validateForm()) {
      hideLoader();
      return;
    }

    const requestParams = {
      Id: signatureId,
      EmployeeId: employeeName?.value,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    };
    const response = await APICall(saveSignatureData, "POST", requestParams);

    if (response?.status === 0) {
      const newSignatureId = response?.data?.id;

      await handleImageUpload(newSignatureId);

      notify(response?.status, response?.message);
      setSignatureId(0);

      setEmployeeName(null);

      setSignatureFile(null);

      setReset(!reSet);
    } else {
      notify(response?.status, response?.message);
    }

    hideLoader();
  };

  const showSignatureImage = async (signatureUrl) => {
    showLoader();
    if (signatureUrl) {
      const getSignatureData = await APICall(GetThumbnailDetails, "POST", {
        ThumbnailUrl: signatureUrl,
      });
      debugger;

      if (getSignatureData && getSignatureData.data !== null) {
        setSignatureImage(
          `data:image;base64,${getSignatureData?.data?.thumbnailDataObject}`
        );
      }

      setShowSignatureModel(true);
    } else {
      notify(1, "Signature Url not found");
    }

    hideLoader();
  };

  const getSignatureDataApiCall = async (signatureId) => {
    try {
      showLoader();

      const response = await APICall(getSignatureData, "POST", {
        Id: signatureId,
      });

      if (response.status === 0) {
        setSignatureId(signatureId);
        const employeeId = response?.data?.employeeId;

        const employeeData = await fetchDropdown(
          "",
          "employeeListForSignature",
          employeeId
        );

        setEmployeeName(employeeData[0]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const handleResetFunction = () => {
    setEmployeeName(null);
    setSignatureFile(null);

    setSignatureId(0);

    setFormErrors({});
  };

  const fileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleImageSelect = async (e) => {
    debugger;
    const file = e.target.files[0];
    let fileBase64: { Name: string; Data: string } = null;
    const base64 = (await fileToBase64(file)) as string;
    const base64WithoutPrefix = base64.split(",")[1];
    fileBase64 = { Name: file.name, Data: base64WithoutPrefix };

    const validExtensions = ["png", "jpg", "jpeg"];
    if (file) {
      let isFileValid = false;
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();
      const fileSize = file.size;
      const maxFileSizeBytes = 5 * 1024 * 1024;

      // Check if the file extension is allowed
      if (
        validExtensions.includes(fileExtension) &&
        fileSize <= maxFileSizeBytes
      ) {
        // file to upload
        // setSignatureFile(fileBase64);
        isFileValid = true;
      } else {
        notify(1, "Only file with png,jpg,jpeg allowed and size under 5MB");
        e.target.value = "";
        setSignatureFile(null);
        isFileValid = false;
      }

      if (isFileValid) {
        // Load the image to check its dimensions
        const img = new Image();
        const reader = new FileReader();

        reader.onload = function (event) {
          img.src = event.target.result as string;
        };

        img.onload = function () {
          const { width, height } = img;

          // Set the desired dimensions (e.g., 100x100)
          const desiredWidth = 1024;
          const desiredHeight = 300;

          // Validate dimensions
          if (width <= desiredWidth && height <= desiredHeight) {
            setSignatureFile(fileBase64);
          } else {
            notify(
              1,
              `Image dimensions should be ${desiredWidth}x${desiredHeight}.`
            );
            e.target.value = "";
            setSignatureFile(null);
          }
        };

        // Read the file as a data URL
        reader.readAsDataURL(file);
      }
    }
  };

  const handleImageUpload = async (id: any) => {
    if (signatureFile) {
      showLoader();
      let reqObj = {
        file: {
          Name: signatureFile.Name,
          Data: signatureFile.Data,
        },
        PId: id,
        SectionName: "Signature",
        UserId: userDetails?.UserId,
        CreatedBy: userDetails?.UserId,
      };

      try {
        const response = await APICall(SetThumbnailDetails, "POST", reqObj);
        if (response && response.data) {
          // setProfileImg(`data:image;base64,${response.data.base64}`);
          // setShowProfileModal(false);
          hideLoader();
        } else {
          console.error("Image upload failed");
          notify(1, "Image upload failed.");
          hideLoader();
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        hideLoader();
      }
    }
    // else {
    //   notify(1, "Please Select Image to Upload.");
    // }
  };

  return (
    <>
      <div className="breadcrumb modified-bredcrumb">
        <span>
          <ul>
            <li>
              <a href="/home">Home</a>
            </li>

            <li>Signature</li>
          </ul>
        </span>
        <button
          // onClick={() => {
          //   navigate(-1);
          // }}
          onClick={() => {
            window.close();
          }}
          disabled={false}
          className="btn btn-secondary float-right">
          <i className="fa-solid fa-arrow-left"></i> Back
        </button>
      </div>
      <div className="container-fluid">
        <>
          <>
            <div className="row">
              <div className="col-lg-4 col-sm-4 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">Employee Name</label>{" "}
                  <sup>*</sup>
                  <SelectForm
                    isClearable
                    isSearchable
                    async
                    options={(searchString, cb) => {
                      handleSearchForAsyncDropdown(
                        searchString,
                        "employeeListForSignature",
                        cb
                      );
                    }}
                    placeholder={"Type to search"}
                    isDisabled={false}
                    value={employeeName}
                    onChange={(event: any) => {
                      setEmployeeName(event);
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  {formErrors["employeeName"] && (
                    <p style={{ color: "red" }}>{formErrors["employeeName"]}</p>
                  )}
                </div>
              </div>

              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mt-2">
                  <label className="col-form-label"></label>
                  <>
                    <div className="box position-relative">
                      <input
                        id="file"
                        className="form-control inputfile inputfile-6 multiple-inputfile"
                        data-multiple-caption="{count} files selected"
                        multiple={false}
                        type="file"
                        onChange={(e) => {
                          handleImageSelect(e);
                        }}
                        accept={".png,.jpg, .jpeg"}
                        ref={signatureFileRef}
                        value={""}
                        disabled={false}
                      />
                      <label
                        htmlFor={`file`}
                        className="form-control"
                        style={{ width: 0, border: "none" }}>
                        <strong
                          style={{
                            padding: "6px 16px",
                            backgroundColor: "#3c5464",
                            borderRadius: "5px",
                          }}>
                          <i
                            className="fa fa-upload rotate90"
                            aria-hidden="true"></i>{" "}
                          Upload
                        </strong>
                      </label>
                    </div>
                  </>
                  <div className="file-added-list">
                    <ul className="list-unstyle">
                      <li className="list mt-1">
                        <div className="media">
                          <div className="media-body text-truncate">
                            <span className="view-more">
                              {signatureFile?.Name}
                            </span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  {formErrors["signatureFile"] && (
                    <p style={{ color: "red" }}>
                      {formErrors["signatureFile"]}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </>

          <br />
          <div className="SectionSubmit mb-4 clearfix">
            <button
              onClick={(e) => {
                e.preventDefault();
                handleSubmitFunction();
              }}
              disabled={false}
              className="btn btn-filter-submit float-right ml-2">
              <i className="fa fa-plus mr-2"></i>
              Add Signature
            </button>

            <button
              onClick={() => {
                handleResetFunction();
              }}
              disabled={false}
              className="btn btn-secondary float-right ml-2">
              <i className="fa-solid fa-refresh"></i> Reset
            </button>
          </div>
        </>
      </div>
      <div className="mb-3 pt-3">
        <DynamicGrid data={formData} columns={gridColumns} options={options} />
      </div>
      {/* Signature Model*/}
      <Modal
        show={showSignatureModel}
        onHide={() => setShowSignatureModel(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">{`Signature Image`}</div>
        </div>
        <Modal.Body>
          <>
            <div className="row">
              <div className={"col-lg-12 text-center"}>
                <div className="cont_img">
                  <img src={signatureImage} />
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowSignatureModel(false);
              setSignatureImage(null);
            }}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      {/* Delete Signature List Model */}
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">{"Confirmation"}</div>
        </div>
        <Modal.Body>
          <>
            <div className="col-lg-12">
              {"Are you sure you want to delete this record?"}
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => handleDeleteSignature()}>
            Yes
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowDeleteModal(false);
            }}>
            No
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Signature;
