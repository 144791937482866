// import "./Home.css";
import React, { useState, useEffect, useContext, useRef } from "react";
import "./OrganizationUnit.css";
import SelectForm from "../../Components/SelectForm/SelectForm";
import { Modal } from "react-bootstrap";
import InputForm from "../../Components/InputForm/InputForm";
import moment from "moment";
import DateForm from "../../Components/DateForm/DateForm";
import { APICall } from "../../Helpers/APICalls";
import { 
    InsertUpdateOUMappings, 
    InsertUpdateOrganizationUnit, 
    getDropdowns, 
    getOUCodes, 
    getOUCombination, 
    getOUDetails, 
    getOUMappingDetails,
    getOuProgressionData,
    getOuMappingProgressionData,
    deleteOuProgressionData,
    deleteOuMappingProgressionData,
    getEmployeesMappedToOuData
} from "../../Helpers/APIEndPoints/EndPoints";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import notify from "../../Helpers/ToastNotification";
import { useLocation, useNavigate } from "react-router-dom";
import { LoaderContext } from "../../Helpers/Context/Context";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import parseWithOptions from "date-fns/esm/fp/parseWithOptions/index";
import ViewHistoryOu from "./ViewHistoryOu";
import {
    Accordion, AccordionDetails, AccordionSummary,
    Tabs, Tooltip, Typography,
  } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import ExcelJS from "exceljs";

const OrganizationUnitForm = () => {
    const [OUName, setOUName] = useState('');
    const [OUOldName, setOUOldName] = useState('');
    const [effectiveDate, setEffectiveDate] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    let validAccount = useRef(false);
    const [confirmationPromise, setConfirmationPromise] = useState(null);
    const [showInactiveConfirmationModal, setShowInactiveConfirmationModal] = useState(false);
    let validInactiveConfirmation = useRef(false);
    const [inactiveConfirmationPromise, setInactiveConfirmationPromise] = useState(null);
    const [empMappedCount, setEmpMappedCount] = useState(0);
    const [remarks, setRemarks] = useState('');
    const [OUCode, setOUCode] = useState('');
    const [OUText, setOUText] = useState('');
    const [OUText1, setOUText1] = useState('');
    const [OUText2, setOUText2] = useState('');
    const [OUDate, setOUDate] = useState(null);
    const [OU1Name, setOU1Name] = useState<any>([]);
    const [OU2Name, setOU2Name] = useState<any>([]);
    const [OU3Name, setOU3Name] = useState<any>([]);
    const [OU4Name, setOU4Name] = useState<any>([]);
    const [OU5Name, setOU5Name] = useState<any>([]);
    const [OU6Name, setOU6Name] = useState<any>([]);
    const [OU7Name, setOU7Name] = useState<any>([]);
    const [OUEndDate, setOUEndDate] = useState<any>([]);
    const [LegalEntityDdValue, setLegalEntityDdValue] = useState<any>([]);
    const [JobBandDdValue, setJobBandDdValue] = useState<any>([]);
    const [SDDdValue, setSDDdValue] = useState<any>([]);
    const [SkillCategoryDdValue, setSkillCategoryDdValue] = useState<any>([]);
    const [ProficiencyDdValue, setProficiencyDdValue] = useState<any>([]);
    const [JobLevelDdValue, setJobLevelDdValue] = useState<any>([]);
    const [BusinessDdValue, setBusinessDdValue] = useState<any>([]);
    const [CUSBISDdValue, setCUSBISDdValue] = useState<any>([]);
    const [SBUDdValue, setSBUDdValue] = useState<any>([]);
    const [PLOCDdValue, setPLOCDdValue] = useState<any>([]);
    const [WorkAreaDdValue, setWorkAreaDdValue] = useState<any>([]);
    const [WorkAreaStageDdValue, setWorkAreaStageDdValue] = useState<any>([]);
    const [EmployeeClassDdValue, setEmployeeClassDdValue] = useState<any>([]);
    const [OUIsActive, setOUIsActive] = useState<any>([]);
    const navigate = useNavigate();
    const [OUMappingIsActive, setOUMappingIsActive] = useState(true);
    const [IsEditMode, setIsEditMode] = useState(false);
    let { userDetails, currentRoleId }: any = useUserContext();
    const [gridData, setGridData] = useState(null);
    const { state } = useLocation();
    const [Id, setId] = useState((state !== null && state.Id) || 0);
    const [progId, setProgId] = useState((state !== null && state.ProgId) || 0);
    const [MasterId] = useState((state !== null && state.MasterId) || 0);
    const [MasterName, setMasterName] = useState((state !== null && state.MasterName || 0 ));
    const [MasterCode, setMasterCode] = useState((state !== null && state.MasterCode || 0 ));
    const [ouForBack, setOuForBack] = useState(state?.ouType);
    const [isViewOu, setIsViewOu] = useState(state?.isViewOu ? true : false);
    const [OUType, setOUType] = useState<any>([]);
    const [isMappingAvailable , setMappingAvailable]= useState(false);
    const [mappingValues, setMappingValues] = useState<any>([]);
    const [oldMappingValues, setOldMappingValues] = useState<any>([]);
    const [LENMappingValues, setLENMappingValues] = useState<any>([]);
    const [PLOCMappingValues, setPLOCMappingValues] = useState<any>([]);
    const [SDMappingValues, setSDMappingValues] = useState<any>([]);
    const [SkillCategoryMappingValues, setSkillCategoryMappingValues] = useState<any>([]);
    const [ProficiencyMappingValues, setProficiencyMappingValues] = useState<any>([]);
    const [BUMappingValues, setBUMappingValues] = useState<any>([]);
    const [CUSBISMappingValues, setCUSBISMappingValues] = useState<any>([]);
    const [WorkAreaMappingValues, setWorkAreaMappingValues] = useState<any>([]);
    const [WorkAreaStageMappingValues, setWorkAreaStageMappingValues] = useState<any>([]);
    const [JobLevelMappingValues, setJobLveleMappingValues] = useState<any>([]);
    const [JobBandMappingValues, setJobBandMappingValues] = useState<any>([]);
    const [SBUMappingValues, setSBUMappingValues] = useState<any>([]);
    const [EmployeeClassMappingValues, setEmployeeClassMappingValues] = useState<any>([]);
    const [selectedMappings, setSelectedMappingsValues] = useState(null);
    const [mappingMasterType , setMappingMasterType]= useState("");
    const [ddValue, setDdValue] = useState({});
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const [rowData, setRowData] = useState([]);
    const [histAcc, setHistAcc] = useState(false);

    const columnForMappingDisplay = [ 
        {name: 'code', label: 'Code'}, 
        {name: 'label', label: 'Name'}, 
        {name: 'createdbyname', label: 'Created By', 
            options: {
                customBodyRender: (value) => (
                    <div style={{ textAlign: 'left' }}>{value}</div>
                ),
            },
        }, 
    ]
    
    useEffect(() => {  
        if(Id != 0){
            getOUData();
        } else{
            setOUIsActive(true);
            setIsEditMode(false);           
        }
        setOUType({ code: MasterCode, label: MasterName , value: MasterId });

        if(MasterCode === 'SBU'){
            setMappingMasterType('Business Association');
            setMappingAvailable(true);
            getMappingValue('Business');
        } else if(MasterCode === 'Business'){
            setMappingAvailable(true);
        } else if(MasterCode === 'StandardDesignation'){
            setMappingMasterType('Job Level Association');
            setMappingAvailable(true);
            getMappingValue('JobLevel');
        } else if(MasterCode === 'Country'){
            setMappingAvailable(true);
        } else if(MasterCode === 'PhysicalLocation'){
            setMappingAvailable(true);
        } else if(MasterCode === 'LegalEntity'){
            setMappingMasterType('SBU Association');
            setMappingAvailable(true);
            getMappingValue('SBU');
        } else if(MasterCode  === 'Division'){
            setMappingMasterType('Legal Entity Association');
            setMappingAvailable(true);
            getMappingValue('LegalEntity');
        } else if(MasterCode === 'Function'){
            setMappingMasterType('Division Association');
            setMappingAvailable(true);
            getMappingValue('Division');
        } else if(MasterCode  === 'Department'){
            setMappingMasterType('Function Association');
            setMappingAvailable(true);
            getMappingValue('Function');
        } else if(MasterCode === 'Section'){
            setMappingMasterType('Department Association');
            setMappingAvailable(true);
            getMappingValue('Department');
        } else if(MasterCode  === 'SubSection'){
            setMappingMasterType('Section Association');
            setMappingAvailable(true);
            getMappingValue('Section');
        } else if(MasterCode === 'SubSubSection'){
            setMappingMasterType('Sub-Section Association');
            setMappingAvailable(true);
            getMappingValue('SubSection');
        } else if(MasterCode  === 'EmployeeClass'){
            setMappingMasterType('Legal Entity Association');
            setMappingAvailable(true);
            getMappingValue('LegalEntity');
        } else if(MasterCode === 'JobLevel'){
            setMappingMasterType('Job Band Association');
            setMappingAvailable(true);
            getMappingValue('JobBand');
        } else if(MasterCode  === 'Designation'){
            setMappingMasterType('Job Level Association');
            setMappingAvailable(true);
            getMappingValue('JobLevel');
        } else if(MasterCode === 'CostCenter'){
            setMappingMasterType('Department Association');
            setMappingAvailable(true);
            getMappingValue('Department');
        } else if(MasterCode  === 'PayrollLocation'){
            setMappingMasterType('Legal Entity Association');
            setMappingAvailable(true);
            getMappingValue('LegalEntity');
        } else if(MasterId === 'M10'){
            getMappingValue('LegalEntity');
            getMappingValue('EmployeeClass');
            getMappingValue('JobBand');
        } else if(MasterId === 'M3'){
            getMappingValue('CustomBusiness');
            getMappingValue('LegalEntity');
            getMappingValue('Business');
            getMappingValue('SBU');
        } else if(MasterId === 'M16'){
            getMappingValue('CustomBusiness');
            getMappingValue('LegalEntity');    
            getMappingValue('Business');
            getMappingValue('SBU');
            getMappingValue('JobLevel');
        } else if(MasterId === 'M17'){
            getMappingValue('LegalEntity');
            getMappingValue('PayrollLocation');
        } else if(MasterId === 'M18'){
            getMappingValue('SBU');
            getMappingValue('LegalEntity');
        } else if(MasterId === 'M19'){
            getMappingValue('LegalEntity');
            getMappingValue('CustomBusiness');
            getMappingValue('JobLevel');
            getMappingValue('StandardDesignation');
        } else if(MasterId === 'M20'){
            getMappingValue('LegalEntity');
            getMappingValue('EmployeeClass');
            getMappingValue('JobLevel');
        } else if(MasterId === 'M21'){
            getMappingValue('WorkArea');
            getMappingValue('LegalEntity');
            getMappingValue('Business');
            getMappingValue('SBU');
            getMappingValue('PayrollLocation');
        } else if(MasterId === 'M22'){
            getMappingValue('WorkArea');
            getMappingValue('WorkAreaMasterStage');
        } else if(MasterId === 'M23'){
            getMappingValue('StandardDesignation');
            getMappingValue('SkillCategory');
        } else if(MasterId === 'M24'){
            getMappingValue('Business');
            getMappingValue('SBU');
            getMappingValue('LegalEntity');
            getMappingValue('PayrollLocation');
            getMappingValue('StandardDesignation');
            getMappingValue('SkillCategory');
            getMappingValue('RCMBProficiency');
        } else if(MasterId === 'M25'){
            getMappingValue('StandardDesignation');
        } else if(MasterId === 'M26'){
            getMappingValue('Business');
            getMappingValue('SBU');
            getMappingValue('LegalEntity');
            getMappingValue('PayrollLocation');
            getMappingValue('EmployeeClass');
        }
        else {
            setMappingAvailable(false);
        }
    }, []);

    const getMappingValue = async (masterType) => {
        await getDropdownsData('OUMappingValues', masterType, null);
    }

    const getDropdownsData = async (AC, PID = null, cb) => {
        await showLoader();
        const dropdownData = await APICall(getDropdowns, "POST", {
          AC,
          PID,
          UserId: userDetails?.Id,
          RoleId: currentRoleId?.value,
        });
        if (
          dropdownData.data !== null &&
          dropdownData.data !== undefined &&
          dropdownData.data.length > 0 
        ) {
          if(PID === 'LegalEntity'){
            setLegalEntityDdValue(dropdownData.data);
          } else if (PID === 'EmployeeClass'){
            setEmployeeClassDdValue(dropdownData.data);
          } else if (PID === 'Business'){
            setBusinessDdValue(dropdownData.data);
          } else if (PID === 'SBU'){
            setSBUDdValue(dropdownData.data);
          } else if (PID === 'CustomBusiness'){
            setCUSBISDdValue(dropdownData.data);
          } else if (PID === 'JobBand'){
            setJobBandDdValue(dropdownData.data);
          } else if (PID === 'JobLevel') {
            setJobLevelDdValue(dropdownData.data);
          } else if(PID === 'PayrollLocation') {
            setPLOCDdValue(dropdownData.data);
          } else if(PID === 'StandardDesignation'){
            setSDDdValue(dropdownData.data);
          } else if(PID === 'WorkArea'){
            setWorkAreaDdValue(dropdownData.data);
          } else if(PID === 'WorkAreaMasterStage'){
            setWorkAreaStageDdValue(dropdownData.data);
          } else if(PID === 'SkillCategory'){
            setSkillCategoryDdValue(dropdownData.data);
          } else if(PID === 'RCMBProficiency'){
            setProficiencyDdValue(dropdownData.data);
          }
          setDdValue((prev) => ({ ...prev, [AC]: dropdownData.data }));
        } else {
          setDdValue((prev) => ({ ...prev, [AC]: [] }));
        }
        await hideLoader();
    };

    const onOptionChange = (event, type) => {
        if(type === 'mappingValue'){
            setMappingValues(event);
        } else if(type === 'BUMapping'){
            setBUMappingValues(event);
        } else if(type === 'SBUMapping'){
            setSBUMappingValues(event);
        } else if (type ===  'LENMapping'){
            setLENMappingValues(event);
        } else if (type === 'ECMapping'){
            setEmployeeClassMappingValues(event);
        } else if(type === 'CUSBISMapping'){
            setCUSBISMappingValues(event);
        } else if(type === 'JobBandMapping'){
            setJobBandMappingValues(event);
        } else if(type === 'JobLevelMapping'){
            setJobLveleMappingValues(event);
        } else if(type === 'PLOCMapping'){
            setPLOCMappingValues(event);
        } else if (type === 'SDMapping'){
            setSDMappingValues(event);
        } else if (type === 'WorkAreaMapping'){
            setWorkAreaMappingValues(event);
        } else if (type === 'WorkAreaStageMapping'){
            setWorkAreaStageMappingValues(event);
        } else if (type === 'SkillCategoryMapping'){
            setSkillCategoryMappingValues(event);
        } else if (type === 'ProficiencyMapping'){
            setProficiencyMappingValues(event);
        }
    };

    const getOUData = async () => {
        const OUDetails = await APICall(getOUDetails, //from dashbord id to update data
            "POST",
            {
                "Id": Id, 
                "Code": MasterId
            }
        );
        if(OUDetails.data != null){
            if(MasterId === 'M3' || MasterId === 'M10' || MasterId === 'M16' || 
               MasterId === 'M17' || MasterId === 'M18' || MasterId === 'M19' || 
               MasterId === 'M20' || MasterId === 'M21' || MasterId === 'M22' || 
               MasterId === 'M23' || MasterId === 'M24' || MasterId === 'M25' || 
               MasterId === 'M26' ){
                setOU1Name(OUDetails.data[0].ouData);
                setOU2Name(OUDetails.data[0].oU1Data);
                if(MasterId === 'M3'){
                    setOU3Name(OUDetails.data[0].oU2Data);
                    setOU4Name(OUDetails.data[0].oU3Data)
                } else if(MasterId === 'M10'){
                    setOU3Name(OUDetails.data[0].oU2Data);
                } else if(MasterId === 'M16'){
                    setOU3Name(OUDetails.data[0].oU2Data);
                    setOU4Name(OUDetails.data[0].oU3Data);
                    setOU5Name(OUDetails.data[0].oU4Data);
                    setOUText(OUDetails.data[0].noticePeriodInDays);
                    setOUText1(OUDetails.data[0].noticePeriodInMonths);
                } else if(MasterId === 'M17'){
                    setOUText(OUDetails.data[0].tanNumber);
                } else if (MasterId === 'M18'){
                    setOUText(OUDetails.data[0].companyCode);
                } else if (MasterId === 'M19'){
                    setOU3Name(OUDetails.data[0].oU2Data);
                    setOU4Name(OUDetails.data[0].oU3Data);
                    setOUText(OUDetails.data[0].probationPeriodDays);
                } else if (MasterId === 'M20'){
                    setOU3Name(OUDetails.data[0].oU2Data);
                    setOUText(OUDetails.data[0].lopRate);
                } else if (MasterId === 'M21'){
                    setOU3Name(OUDetails.data[0].oU2Data);
                    setOU4Name(OUDetails.data[0].oU3Data);
                    setOU5Name(OUDetails.data[0].oU4Data);
                } else if (MasterId === 'M24'){
                    setOU3Name(OUDetails.data[0].oU2Data);
                    setOU4Name(OUDetails.data[0].oU3Data);
                    setOU5Name(OUDetails.data[0].oU4Data);
                    setOU6Name(OUDetails.data[0].oU5Data);
                    setOU7Name(OUDetails.data[0].oU6Data);
                    setOUText(OUDetails.data[0].wage);
                } else if (MasterId === 'M26'){
                    setOU3Name(OUDetails.data[0].oU2Data);
                    setOU4Name(OUDetails.data[0].oU3Data);
                    setOU5Name(OUDetails.data[0].oU4Data);
                    // setOUText(OUDetails.data[0].wage);
                }
                
                setOUIsActive(OUDetails.data[0].isActive === 1 ? true : false);
                setIsEditMode(true);
                setOUMappingIsActive(false);
            } 
            else if (MasterId === 'M25'){
                setOU1Name(OUDetails.data[0].ouData);
                setOUText(OUDetails.data[0].sla);
                setOUIsActive(OUDetails.data[0].isActive === 1 ? true : false);
                setIsEditMode(true);
                setOUMappingIsActive(false);
            }
            else {
                setOUName(OUDetails.data[0].name);
                setOUCode(OUDetails.data[0].code);
                setOUIsActive(OUDetails.data[0].isActive === 1 ? true : false);
                setIsEditMode(true);
                setOUMappingIsActive(true);

                setOUOldName(OUDetails.data[0].name);   //For Audit Log
            }
        }

        if(MasterId !== 'M3' && MasterId !== 'M10' && MasterId !== 'M16' &&
            MasterId !== 'M17' && MasterId !== 'M18' && MasterId !== 'M19' && 
            MasterId !== 'M20' && MasterId !== 'M21' && MasterId !== 'M22' && 
            MasterId !== 'M23' && MasterId !== 'M24' && MasterId !== 'M25' && 
            MasterId !== 'M26'){
                const OUMappingDetails = await APICall(getOUMappingDetails, //from dashbord id to update data
                "POST",
                {
                    "OUMasterName": MasterCode,
                    "OUCode" : OUDetails.data[0].code
                }
            );
            if(OUMappingDetails.data !== null){
                setRowData(OUMappingDetails.data);
                const newData = OUMappingDetails.data.map((eachOu)=> ({ ...eachOu, label : `${eachOu.code} - ${eachOu.label}`}));
                setMappingValues(newData);

                setOldMappingValues(newData);   //For Audit Log
            }
        }
        
        if(isViewOu){
            setEffectiveDate(moment(OUDetails.data[0].createdDateUtc).format("DD-MMM-YYYY"))
        }
    };

    const insertUpdateOUData = async () => {
        showLoader();
        const checkOUCodeExist = await APICall(getOUCodes,  //check where same or not for code 
            "POST",
            {}
        );  

        let isValidUpdation = false;
        let isValidName = true;
        let msg = '';

        let OUValue = '';
        let OU1Value = '';
        let OU2Value = '';
        let OU3Value = '';
        let OU4Value = '';
        let OU5Value = '';
        let OU6Value = '';
        let detailsJson = [];
        if(MasterId === 'M10'){
            detailsJson.push({
                'Type': "LegalEntity",
                'VariableName': "OUCode",
                'Value': LENMappingValues.value
            });
            detailsJson.push({
                'Type': "EmployeeClass",
                'VariableName': "OU1Code",
                'Value': EmployeeClassMappingValues.value
            });
            detailsJson.push({
                'Type': "JobBand",
                'VariableName': "OU2Code",
                'Value': JobBandMappingValues.value
            });
            OUValue = LENMappingValues.value
            OU1Value = EmployeeClassMappingValues.value
            OU2Value = JobBandMappingValues.value
        } else if (MasterId === 'M3') {
            detailsJson.push({
                'Type': "Business",
                'VariableName': "OUCode",
                'Value': BUMappingValues.value
            });
            detailsJson.push({
                'Type': "SBU",
                'VariableName': "OU1Code",
                'Value': SBUMappingValues.value
            });
            detailsJson.push({
                'Type': "LegalEntity",
                'VariableName': "OU2Code",
                'Value': LENMappingValues.value
            });
            detailsJson.push({
                'Type': "CustomBusiness",
                'VariableName': "OU3Code",
                'Value': CUSBISMappingValues.value
            });
            OUValue = BUMappingValues.value
            OU1Value = SBUMappingValues.value
            OU2Value = LENMappingValues.value
            OU3Value = CUSBISMappingValues.value
        } else if (MasterId === 'M16')  {
            detailsJson.push({
                'Type': "Business",
                'VariableName': "OUCode",
                'Value': BUMappingValues.value
            });
            detailsJson.push({
                'Type': "SBU",
                'VariableName': "OU1Code",
                'Value': SBUMappingValues.value
            });
            detailsJson.push({
                'Type': "LegalEntity",
                'VariableName': "OU2Code",
                'Value': LENMappingValues.value
            });
            detailsJson.push({
                'Type': "CustomBusiness",
                'VariableName': "OU3Code",
                'Value': CUSBISMappingValues.value
            });
            detailsJson.push({
                'Type': "JobLevel",
                'VariableName': "OU4Code",
                'Value': JobLevelMappingValues.value
            });
            detailsJson.push({
                'Type': "NoticePeriodInDays",
                'VariableName': "OUText",
                'Value': OUText
            });
            detailsJson.push({
                'Type': "NoticePeriodDaysInMonths",
                'VariableName': "OUText1",
                'Value': OUText1
            });
            OUValue = BUMappingValues.value
            OU1Value = SBUMappingValues.value
            OU2Value = LENMappingValues.value
            OU3Value = CUSBISMappingValues.value
            OU4Value = JobLevelMappingValues.value
        } else if (MasterId === 'M17'){
            detailsJson.push({
                'Type': "LegalEntity",
                'VariableName': "OUCode",
                'Value': LENMappingValues.value
            });
            detailsJson.push({
                'Type': "PayrollLocation",
                'VariableName': "OU1Code",
                'Value': PLOCMappingValues.value
            });
            detailsJson.push({
                'Type': "TANNumber",
                'VariableName': "OUText",
                'Value': OUText
            });
            OUValue = LENMappingValues.value
            OU1Value = PLOCMappingValues.value
        } else if(MasterId === 'M18'){
            detailsJson.push({
                'Type': "SBU",
                'VariableName': "OUCode",
                'Value': SBUMappingValues.value
            });
            detailsJson.push({
                'Type': "LegalEntity",
                'VariableName': "OU1Code",
                'Value': LENMappingValues.value
            });
            detailsJson.push({
                'Type': "CompanyCode",
                'VariableName': "OUText",
                'Value': OUText
            });
            OUValue = SBUMappingValues.value
            OU1Value = LENMappingValues.value
        } else if(MasterId === 'M19'){
            detailsJson.push({
                'Type': "LegalEntity",
                'VariableName': "OUCode",
                'Value': LENMappingValues.value
            });
            detailsJson.push({
                'Type': "CustomBusiness",
                'VariableName': "OU1Code",
                'Value': CUSBISMappingValues.value
            });detailsJson.push({
                'Type': "JobLevel",
                'VariableName': "OU2Code",
                'Value': JobLevelMappingValues.value
            });
            detailsJson.push({
                'Type': "StandardDesignation",
                'VariableName': "OU3Code",
                'Value': SDMappingValues.value
            });
            detailsJson.push({
                'Type': "ProbationPeriodDays",
                'VariableName': "OUText",
                'Value': OUText
            });
            OUValue = LENMappingValues.value
            OU1Value = CUSBISMappingValues.value
            OU2Value = JobLevelMappingValues.value
            OU3Value = SDMappingValues.value
        } else if(MasterId === 'M20'){
            detailsJson.push({
                'Type': "LegalEntity",
                'VariableName': "OUCode",
                'Value': LENMappingValues.value
            });
            detailsJson.push({
                'Type': "EmployeeClass",
                'VariableName': "OU1Code",
                'Value': EmployeeClassMappingValues.value
            });detailsJson.push({
                'Type': "JobLevel",
                'VariableName': "OU2Code",
                'Value': JobLevelMappingValues.value
            });
            detailsJson.push({
                'Type': "LOPRate",
                'VariableName': "OUText",
                'Value': OUText
            });
            OUValue = LENMappingValues.value
            OU1Value = EmployeeClassMappingValues.value
            OU2Value = JobLevelMappingValues.value
        } else if (MasterId === 'M21') {
            detailsJson.push({
                'Type': "Business",
                'VariableName': "OUCode",
                'Value': BUMappingValues.value
            });
            detailsJson.push({
                'Type': "SBU",
                'VariableName': "OU1Code",
                'Value': SBUMappingValues.value
            });
            detailsJson.push({
                'Type': "LegalEntity",
                'VariableName': "OU2Code",
                'Value': LENMappingValues.value
            });
            detailsJson.push({
                'Type': "PayrollLocation",
                'VariableName': "OU3Code",
                'Value': PLOCMappingValues.value
            });
            detailsJson.push({
                'Type': "WorkArea",
                'VariableName': "OU4Code",
                'Value': WorkAreaMappingValues.value
            });
            OUValue = BUMappingValues.value
            OU1Value = SBUMappingValues.value
            OU2Value = LENMappingValues.value
            OU3Value = PLOCMappingValues.value
            OU4Value = WorkAreaMappingValues.value
        } else if (MasterId === 'M22') {
            detailsJson.push({
                'Type': "WorkArea",
                'VariableName': "OUCode",
                'Value': WorkAreaMappingValues.value
            });
            detailsJson.push({
                'Type': "WorkAreaMasterStage",
                'VariableName': "OU1Code",
                'Value': WorkAreaStageMappingValues.value
            });
            OUValue = WorkAreaMappingValues.value
            OU1Value = WorkAreaStageMappingValues.value
        } else if (MasterId === 'M23') {
            detailsJson.push({
                'Type': "StandardDesignation",
                'VariableName': "OUCode",
                'Value': SDMappingValues.value
            });
            detailsJson.push({
                'Type': "SkillCategory",
                'VariableName': "OU1Code",
                'Value': SkillCategoryMappingValues.value
            });
            OUValue = SDMappingValues.value
            OU1Value = SkillCategoryMappingValues.value
        } else if (MasterId === 'M24') {
            detailsJson.push({
                'Type': "Business",
                'VariableName': "OUCode",
                'Value': BUMappingValues.value
            });
            detailsJson.push({
                'Type': "SBU",
                'VariableName': "OU1Code",
                'Value': SBUMappingValues.value
            });
            detailsJson.push({
                'Type': "LegalEntity",
                'VariableName': "OU2Code",
                'Value': LENMappingValues.value
            });
            detailsJson.push({
                'Type': "PayrollLocation",
                'VariableName': "OU3Code",
                'Value': PLOCMappingValues.value
            });
            detailsJson.push({
                'Type': "StandardDesignation",
                'VariableName': "OU4Code",
                'Value': SDMappingValues.value
            });
            detailsJson.push({
                'Type': "SkillCategory",
                'VariableName': "OU5Code",
                'Value': SkillCategoryMappingValues.value
            });
            detailsJson.push({
                'Type': "RCMBProficiency",
                'VariableName': "OU6Code",
                'Value': ProficiencyMappingValues.value
            });
            detailsJson.push({
                'Type': "Wage",
                'VariableName': "OUText",
                'Value': OUText
            });
            OUValue = BUMappingValues.value
            OU1Value = SBUMappingValues.value
            OU2Value = LENMappingValues.value
            OU3Value = PLOCMappingValues.value
            OU4Value = SDMappingValues.value
            OU5Value = SkillCategoryMappingValues.value
            OU6Value = ProficiencyMappingValues.value
        } else if (MasterId === 'M25') {
            detailsJson.push({
                'Type': "StandardDesignation",
                'VariableName': "OUCode",
                'Value': SDMappingValues.value
            });
            detailsJson.push({
                'Type': "SLA",
                'VariableName': "OUText",
                'Value': OUText
            });
        } else if (MasterId === 'M26') {
            detailsJson.push({
                'Type': "Business",
                'VariableName': "OUCode",
                'Value': BUMappingValues.value
            });
            detailsJson.push({
                'Type': "SBU",
                'VariableName': "OU1Code",
                'Value': SBUMappingValues.value
            });
            detailsJson.push({
                'Type': "LegalEntity",
                'VariableName': "OU2Code",
                'Value': LENMappingValues.value
            });
            detailsJson.push({
                'Type': "PayrollLocation",
                'VariableName': "OU3Code",
                'Value': PLOCMappingValues.value
            });
            detailsJson.push({
                'Type': "EmployeeClass",
                'VariableName': "OU4Code",
                'Value': EmployeeClassMappingValues.value
            });
            detailsJson.push({
                'Type': "ApprovedHeadCount",
                'VariableName': "OUText",
                'Value': OUText
            });
            detailsJson.push({
                'Type': "PermissiblePercentage",
                'VariableName': "OUText1",
                'Value': OUText1
            });
            detailsJson.push({
                'Type': "RecruitableHeadCount",
                'VariableName': "OUText2",
                'Value': OUText2
            });
            detailsJson.push({
                'Type': "EndDate",
                'VariableName': "OUDate",
                'Value': moment(OUDate).format('YYYY-MM-DD')
            });
            OUValue = BUMappingValues.value
            OU1Value = SBUMappingValues.value
            OU2Value = LENMappingValues.value
            OU3Value = PLOCMappingValues.value
            OU4Value = EmployeeClassMappingValues.value
        }
        if(['M10','M3','M16','M17','M18','M19','M20','M21','M22','M23','M24','M25','M26'].includes(MasterId)){
            detailsJson.push({
                'Type': "IsActive",
                'VariableName': "IsActive",
                'Value': OUIsActive ? 1 : 0
            });
        }

        if(MasterId !== 'M3' && MasterId !== 'M10' && MasterId !== 'M16' &&
           MasterId !== 'M17' && MasterId !== 'M18' && MasterId !== 'M19' && 
           MasterId !== 'M20' && MasterId !== 'M21' && MasterId !== 'M22' && 
           MasterId !== 'M23' && MasterId !== 'M24' && MasterId !== 'M25' && 
           MasterId !== 'M26'){
            //Validation Open-----------------------------------------------------------------------
            if(effectiveDate === '' || effectiveDate=== null){
                notify(1, 'Effective Date is Mandatory.');  
                hideLoader();
                return null;
            }

            if(Id == 0){
                msg = "Data Inserted Successfully";
                if(OUCode === ''){
                    notify(1, 'Organization Unit Code is Mandatory');
                    hideLoader();  
                    return null;
                } 
                // if (OUCode.length > 8){
                //     notify(1, 'Organization Unit Code should be of 8 digits.');
                //     hideLoader();  
                //     return null;
                // }
                if(OUName === ''){
                    notify(1, 'Organization Unit Name is Mandatory');
                    hideLoader();  
                    return null;
                } 
                isValidUpdation = checkOUCodeExist.data.some(x => x.code === OUCode) ? false : true;
                
                isValidName = checkOUCodeExist.data.some(x => x.name === OUName && x.type == MasterId) ? false : true;
                if(!isValidName){
                    notify(1, 'Name already in use for same Organization Unit Type.');
                    hideLoader();
                    return;
                }
            } else {
                if(checkOUCodeExist.data.some(x => x.code === OUCode && x.id === Id)){
                    isValidUpdation = true;
                } else{
                    if(checkOUCodeExist.data.some(x => x.id === Id && x.code !== OUCode)){
                        if(checkOUCodeExist.data.some(x => x.code === OUCode)){
                            isValidUpdation = false;
                        } else{
                            isValidUpdation = true;
                        }
                    }
                }
                
                isValidName = checkOUCodeExist.data.some(x => x.id !== Id && x.name === OUName && x.type == MasterId) ? false : true;
                if(!isValidName){
                    notify(1, 'Name already in use for same Organization Unit Type.');
                    hideLoader();
                    return;
                }
                msg = "Data Updated Successfully";
            }
            if (isMappingAvailable && (MasterCode !== 'Business') && (MasterCode !== 'PhysicalLocation') &&(MasterCode !== 'Country')
            ){
                if(mappingValues === "" || mappingValues === null || mappingValues === undefined || mappingValues.length === 0){
                    notify(1, `${mappingMasterType} is Mandatory.`);
                    hideLoader();
                    return null;
                }
            }
            if(remarks === '' || remarks=== null){
                notify(1, 'Remarks are Mandatory.');
                hideLoader();  
                return null;
            }
            //Validation Close-----------------------------------------------------------------------

            if(OUName !== '' && OUCode !== ''){
                if(!isValidUpdation){
                    notify(1, "Organization unit code already exist, Please try changing code"); 
                } else {
                    //CHECK
                    hideLoader();
                    await validateOuInProgression();

                    if(validAccount.current === false){
                        return null;
                    }
                    showLoader();
                    //Delete Existing Progression entry
                    const delObj = await APICall(deleteOuProgressionData,
                        "POST",
                        {Id : Id}
                    );
                    //Insert for only Name add in OU.
                    const postObj = {
                        Id: progId,
                        Name: OUName,
                        Code: OUCode,
                        OuId: Id,
                        IsActive: OUIsActive ? 1 : 0,
                        MasterTypeId: MasterId,
                        OUMasterCode: MasterCode,
                        OUMasterName: MasterName,
                        EffectiveDate: effectiveDate,
                        Remarks: remarks,
                        CreatedBy: userDetails?.Id,
                        MappingValues: mappingValues.map((s) => s.value).join(","),
                    }
                    
                    const InsertOrUpdateData = await APICall(InsertUpdateOrganizationUnit, //when we click add new to insert and update data
                        "POST",
                        postObj
                    );
                    if(InsertOrUpdateData.message == "Ok"){
                        notify(0, msg);
                        navigate('/organizationUnitDashboard', {state:{ouType:ouForBack}});
                    } else {
                        notify(1, "Some error occurred. Please try again");
                    }
                }
            }
        } else {
            //Validation Open-----------------------------------------------------------------------
            if(effectiveDate === '' || effectiveDate=== null){
                notify(1, 'Effective Date is Mandatory.');  
                hideLoader();
                return null;
            }
            let validInsertion = false;
            if(IsEditMode){
                isValidUpdation = true;
                msg = "Data Updated Successfully";
                validInsertion = true;
            } else {
                if(MasterId === 'M3' || MasterId === 'M10' || MasterId === 'M16' ||
                MasterId === 'M17' || MasterId === 'M18' || MasterId === 'M19' || 
                MasterId === 'M20' || MasterId === 'M21' || MasterId === 'M22' || 
                MasterId === 'M23' || MasterId === 'M24' || MasterId === 'M25' || 
                MasterId === 'M26'){
                    
                    const checkOUCombinationExist = await APICall(getOUCombination,  //check where same or not for OU combination 
                        "POST",
                        {
                            OUMasterName: MasterId,
                            OUCode: OUValue,
                            OU1Code: OU1Value,
                            OU2Code: OU2Value,
                            OU3Code: OU3Value,
                            OU4Code: OU4Value,
                            OU5Code: OU5Value,
                            OU6Code: OU6Value,
                            OUText: OUText,
                            OUText1: OUText1,
                            OUText2: OUText2,
                        }
                    );
        
                    if(checkOUCombinationExist.data.length > 0){
                        validInsertion = false;
                    } else {
                        validInsertion = true;
                        msg = 'Data Inserted Successfully';
                    }
                }
            }

            if(remarks === '' || remarks=== null){
                notify(1, 'Remarks are Mandatory.');
                hideLoader();  
                return null;
            }
            //Validation Close-----------------------------------------------------------------------

            if(validInsertion){
                //CHECK
                hideLoader();
                await validateOuMappingInProgression();

                if(validAccount.current === false){
                    return null;
                }
                showLoader();
                //Delete Existing Progression entry
                const delObj = await APICall(deleteOuMappingProgressionData,
                    "POST",
                    {Id : Id}
                );
                //Insert Ou Mapping Data
                const InsertUpdateMappings = await APICall(InsertUpdateOUMappings, //when we click add new to insert and update data
                        "POST",
                        {
                            Id: progId,
                            OuMappingId: Id,
                            MappingValues: MasterId,
                            Detailsjson: JSON.stringify(detailsJson),
                            EffectiveDate: effectiveDate,
                            Remarks: remarks,
                            IsActive: 1,
                            CreatedBy: userDetails?.Id
                        }
                      );
                      if(InsertUpdateMappings.message == "Ok"){
                          notify(0, msg);
                          navigate('/organizationUnitDashboard', {state:{ouType:ouForBack}});
                      } else{
                          notify(1, "Some error occurred. Please try again");
                      }
            } else {
                notify(1, 'This mapping combination already exist');
            }
        }
        hideLoader();
    };

    const onSubmitData = () => {
        insertUpdateOUData(); 
    };

    const resetForm = () => {
        setOUType({ label: MasterName , value: MasterId });
        if(!IsEditMode){
            setBUMappingValues([]);
            setSBUMappingValues([]);
            setLENMappingValues([]);
            setCUSBISMappingValues([]);
            setJobBandMappingValues([]);
            setJobLveleMappingValues([]);
            setPLOCMappingValues([]);
            setSDMappingValues([]);
            setEmployeeClassMappingValues([]);
            setOUText("");
            setOUText1("");
            setOUIsActive(true);
            setOUCode("");
        }
        setMappingValues([]);
        if(OUMappingIsActive){
            setOUName("");
            setOU1Name("");
            setOU2Name("");
            setOU3Name("");
        }
        
    };

    useEffect(() => {
        if (confirmationPromise) {
          if (validAccount.current) {
            confirmationPromise.resolve(true);
          } else {
            confirmationPromise.reject(false); // Reject the promise
          }
        }
        setConfirmationPromise(null);
    }, [validAccount.current]);

    const closeConfirmationModal = async (confirmation) => {
        if (confirmationPromise) {
          if (confirmation) {
            validAccount.current = true;
          } else {
            validAccount.current = false;
          }
    
          setShowConfirmationModal(false);
        }
    };

    useEffect(() => {
        if (inactiveConfirmationPromise) {
          if (validInactiveConfirmation.current) {
            inactiveConfirmationPromise.resolve(true);
          } else {
            inactiveConfirmationPromise.reject(false); // Reject the promise
          }
        }
        setInactiveConfirmationPromise(null);
    }, [validInactiveConfirmation.current]);

    const closeInactiveConfirmationModal = async (confirmation) => {
        if (inactiveConfirmationPromise) {
          if (confirmation) {
            validInactiveConfirmation.current = true;
          } else {
            validInactiveConfirmation.current = false;
          }
          setShowInactiveConfirmationModal(false);
        }
    };

    const validateOuInProgression = async () => {
        return new Promise(async (resolve, reject) => {
            const response = await APICall(getOuProgressionData, "POST", {
              Id: Id,
            });
            if (response.data !== null && response.data != undefined && response.data.length > 0){
              setShowConfirmationModal(true);
              setConfirmationPromise({ resolve, reject });
            } else {
              // New value is added
              validAccount.current = true;
              resolve(true);
            }
        });
    };

    const validateInactiveConfirmation = async (confData) => {
        return new Promise(async (resolve, reject) => {
            showLoader();
            const response = await APICall(getEmployeesMappedToOuData, "POST", {
                Code: OUCode,
                OUMasterCode: MasterCode,
                Name: 'count',
            });
            if(response!= null && response != undefined){
                setEmpMappedCount(
                    response.data?.length > 0 ? response.data[0]?.id : 0
                );
            }
            hideLoader();
            if (confData == false){
              setShowInactiveConfirmationModal(true);
              setInactiveConfirmationPromise({ resolve, reject });
            } else {
              // New value is added
              validInactiveConfirmation.current = true;
              resolve(true);
            }
        });
    };

    const validateOuMappingInProgression = async () => {
        return new Promise(async (resolve, reject) => {
            const response = await APICall(getOuMappingProgressionData, "POST", {
              Id: Id,
            });
            if (response.data !== null && response.data != undefined && response.data.length > 0){
              setShowConfirmationModal(true);
              setConfirmationPromise({ resolve, reject });
            } else {
              // New value is added
              validAccount.current = true;
              resolve(true);
            }
        });
    };

    const downloadexcel = async() =>{
        showLoader();
        const response = await APICall(getEmployeesMappedToOuData, "POST", {
            Code: OUCode,
            OUMasterCode: MasterCode,
            Name: 'excel',
        });
        const responseData = response.data;

        if (!responseData || responseData.length === 0) {
          notify(1, "There is No data.");
          hideLoader();
          return;
        }

        let filteredHeaders = [
            'Employee Code', 'Employee Name', 
            'Position Number', 'Business', 'SBU', 'Legal Entity', 'Custom Business',
            'Division', 'Function', 'Department', 'Section', 'Sub-Section', 'Sub Sub-Section',
            'Payroll Location', 'Physical Location', 'Cost Center', 'Employee Class',
            'Job Band', 'Job Level', 'Standard Designation', 'Client Facing Title'
        ];
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Master");

        const convertCamelCaseToWords = (camelCaseString) => {
            return camelCaseString
            .replace(/([a-z])([A-Z])/g, '$1 $2').split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
        };

        const headerRow = worksheet.addRow(filteredHeaders.map(header => convertCamelCaseToWords(header)));
        headerRow.font = { bold: true };
        responseData.forEach((row) => {
            const dataRow = [];
            dataRow.push(row['employeecode']);
            dataRow.push(row['displayname']);
            dataRow.push(row['positionnumber']);
            dataRow.push(row['business']);
            dataRow.push(row['sbu']);
            dataRow.push(row['legalentity']);
            dataRow.push(row['custombusiness']);
            dataRow.push(row['division']);
            dataRow.push(row['function']);
            dataRow.push(row['department']);
            dataRow.push(row['section']);
            dataRow.push(row['subsection']);
            dataRow.push(row['subsubsection']);
            dataRow.push(row['payrolllocation']);
            dataRow.push(row['physicallocation']);
            dataRow.push(row['costcenter']);
            dataRow.push(row['employeeclass']);
            dataRow.push(row['jobband']);
            dataRow.push(row['joblevel']);
            dataRow.push(row['standarddesignation']);
            dataRow.push(row['clientfacingtitle']);
            worksheet.addRow(dataRow);
        });

        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = URL.createObjectURL(blob);

          const a = document.createElement("a");
          a.href = url;
          a.download = `Employees Mapped To ${OUName} OU.xlsx`;
          a.click();
          URL.revokeObjectURL(url);
        });

        hideLoader();
    }

    let columnsWithCustomRender = '';

    columnsWithCustomRender = gridData?.map(
        column => {
          return column;
        }
    );


    const options = {
        showEmptyDataSourceMessage: true,
        selectableRows: "none",
        // count: count,
        // page: page,
        rowsPerPage: 10000,
        // serverSide: true,
        // onChangePage: async (page) => {
        //   await setPage(page);
        //   setStart(page * PageSize);
        // },
        // onColumnSortChange: async (sortColumn, sortDirection) => {
        //   if (sortDirection === "asc") {
        //     await setDashboardSortColumn(sortColumn);
        //     await setDashboardSortDirection(sortDirection);
        //   }
        //   if (sortDirection === "desc") {
        //     await setDashboardSortColumn(sortColumn);
        //     await setDashboardSortDirection(sortDirection);
        //   }
        // },
        rowsPerPageOptions: [],
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        search: false,
        responsive: "vertical"
    };

    return(
        <>
            <div className="row acc-heading m-0">
                <div className="col-lg-3 col-md-6 col-sm-12">
                    <label className="col-form-label">Effective Date</label><sup>*</sup>
                    <DateForm
                        isDisabled={
                            isViewOu
                            ? isViewOu
                            : false
                        }
                        value={effectiveDate}
                        onChange={(date) => {
                            setEffectiveDate(moment(date).format("DD-MMM-YYYY"));
                        }}
                    />            
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                    <label className="col-form-label">Organization Unit Type</label>
                        <SelectForm
                            id=""
                            label=""
                            value={OUType}
                            onChange={(event) => {
                                onOptionChange(event, 'OUType');
                              }}
                            placeholder="Type"
                            options={[]}
                            isMulti={false}                     
                            isSearchable={true}
                            isClearable
                            isDisabled={true}
                          />
                </div>

                {((isMappingAvailable) || (MasterCode === 'JobBand' || MasterCode === 'CustomBusiness' || MasterCode === 'WorkArea')) && (
                <>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <label className="col-form-label">Code</label>
                      <sup>*</sup>
                      <InputForm
                        className="form-control"
                        placeholder="Code"
                        isDisabled={
                          isViewOu
                          ? isViewOu
                          : IsEditMode ? true : false
                        }       
                        onChange={(e) => setOUCode(e.target.value)}
                        textArea={false}
                        value={OUCode}
                        maxLength="255"
                      />
                    </div>


                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <label className="col-form-label">Name</label>
                      <sup>*</sup>
                      <InputForm
                        className="form-control"
                        placeholder="Name"
                        isDisabled={
                          isViewOu
                          ? isViewOu
                          : false
                        }
                        onChange={(e) => setOUName(e.target.value)}
                        textArea={false}
                        value={OUName}
                        maxLength="255"
                      />
                    </div>
                </>
                )}

                {isMappingAvailable && (MasterCode !== 'Business') && (MasterCode !== 'PhysicalLocation') &&
                (MasterCode !== 'Country') && (
                <>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="dropdown mb-1">
                            <label className="col-form-label">{mappingMasterType}</label>
                            <sup>*</sup>
                            <SelectForm
                                isClearable
                                value={mappingValues}
                                onChange={(event) => {
                                    onOptionChange(event, 'mappingValue');
                                  }}
                                placeholder="Mapping Type"
                                options={ddValue['OUMappingValues']}
                                isMulti={true}                     
                                isSearchable={true}   
                                isDisabled={
                                    isViewOu
                                    ? isViewOu
                                    : false
                                }                        
                            />
                            <div className="dropdown-content">
                              {mappingValues != null &&
                                mappingValues.length > 0 &&
                                    mappingValues.map(
                                      (obj) => <p>{obj.label}</p>
                                    )
                              }
                            </div>
                        </div>
                    </div>
                </>
                )}


                {MasterId === 'M3' && (
                <>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                                <label className="col-form-label">Custom Business</label>
                                <SelectForm
                                    isClearable
                                    value={CUSBISMappingValues}
                                    onChange={(event) => {
                                        onOptionChange(event, 'CUSBISMapping');
                                      }}
                                    placeholder={IsEditMode ? OU4Name : 'Select Custom Business'}
                                    options={CUSBISDdValue}
                                    isMulti={false}                     
                                    isSearchable={true}           
                                    isDisabled={
                                        isViewOu
                                        ? isViewOu
                                        : OUMappingIsActive === true ? false : true
                                    }     
                                    />
                    </div>           
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Business</label>
                        <SelectForm
                            isClearable
                            value={BUMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'BUMapping');
                              }}
                              placeholder={IsEditMode ? OU1Name : 'Select Business Association'}
                            options={BusinessDdValue}
                            isMulti={false}                     
                            isSearchable={true}           
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true
                            }                
                          />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">SBU</label>
                        <SelectForm
                            isClearable
                            value={SBUMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'SBUMapping');
                              }}
                              placeholder={IsEditMode ? OU2Name : 'Select SBU Association'}
                            options={SBUDdValue}
                            isMulti={false}                     
                            isSearchable={true}                    
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}                   
                          />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Legal Entity</label>
                        <SelectForm
                            isClearable
                            value={LENMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'LENMapping');
                              }}
                              placeholder={IsEditMode ? OU3Name : 'Select Legal Entity Association'}
                            options={LegalEntityDdValue}
                            isMulti={false}                     
                            isSearchable={true}                           
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}
                          />
                    </div>
                </>
                )}

                {MasterId === 'M10' && (
                <>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Job Band</label>
                        <SelectForm
                            isClearable
                            value={JobBandMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'JobBandMapping');
                              }}
                              placeholder={IsEditMode ? OU3Name : 'Select Job Band Association'}
                            options={JobBandDdValue}
                            isMulti={false}                     
                            isSearchable={true}            
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}               
                          />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Legal Entity</label>
                        <SelectForm
                            isClearable
                            value={LENMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'LENMapping');
                              }}
                            placeholder={IsEditMode ? OU1Name : 'Select Legal Entity Association'}
                            options={LegalEntityDdValue}
                            isMulti={false}                     
                            isSearchable={true}        
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}                   
                          />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Employee Class</label>
                        <SelectForm
                            isClearable
                            value={EmployeeClassMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'ECMapping');
                              }}
                            placeholder={IsEditMode ? OU2Name : 'Select Employee Class Association'}
                            options={EmployeeClassDdValue}
                            isMulti={false}                     
                            isSearchable={true}            
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}               
                          />
                    </div>
                </>
                )}

                {MasterId === 'M16' && (
                <>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <label className="col-form-label">Notice Period In Days</label>
                      <sup>*</sup>
                      <InputForm
                                  className="form-control"
                                  placeholder="Notice Period In Days"
                                  isDisabled={
                                    isViewOu
                                    ? isViewOu
                                    : OUMappingIsActive === true ? false : true}  
                                  onChange={(e) => setOUText(e.target.value)}
                                  textArea={false}
                                  value={OUText}
                                  maxLength="255"
                        />
            
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <label className="col-form-label">Notice Period Days In Months</label>
                      <sup>*</sup>
                      <InputForm
                                  className="form-control"
                                  placeholder="Notice Period In Months"
                                  isDisabled={
                                    isViewOu
                                    ? isViewOu
                                    : OUMappingIsActive === true ? false : true}  
                                  onChange={(e) => setOUText1(e.target.value)}
                                  textArea={false}
                                  value={OUText1}
                                  maxLength="255"
                        />
            
                    </div>  
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Business</label>
                        <SelectForm
                            isClearable
                            value={BUMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'BUMapping');
                              }}
                            placeholder={IsEditMode ? OU1Name : 'Select Business Association'}
                            options={BusinessDdValue}
                            isMulti={false}                     
                            isSearchable={true}           
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}                
                          />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">SBU</label>
                        <SelectForm
                            isClearable
                            value={SBUMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'SBUMapping');
                              }}
                              placeholder={IsEditMode ? OU2Name : 'Select SBU Association'}
                            options={SBUDdValue}
                            isMulti={false}                     
                            isSearchable={true}                    
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}                   
                          />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Legal Entity</label>
                        <SelectForm
                            isClearable
                            value={LENMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'LENMapping');
                              }}
                              placeholder={IsEditMode ? OU3Name : 'Select Legal Entity Association'}
                            options={LegalEntityDdValue}
                            isMulti={false}                     
                            isSearchable={true}                           
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}
                          />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                                <label className="col-form-label">Custom Business</label>
                                <SelectForm
                                    isClearable
                                    value={CUSBISMappingValues}
                                    onChange={(event) => {
                                        onOptionChange(event, 'CUSBISMapping');
                                      }}
                                    placeholder={IsEditMode ? OU4Name : 'Select Custom Business'}
                                    options={CUSBISDdValue}
                                    isMulti={false}                     
                                    isSearchable={true}           
                                    isDisabled={
                                        isViewOu
                                        ? isViewOu
                                        : OUMappingIsActive === true ? false : true}     
                                    />
                    </div>   
                    <div className="col-lg-3 col-md-6 col-sm-12">
                                <label className="col-form-label">Job Level</label>
                                <SelectForm
                                    isClearable
                                    value={JobLevelMappingValues}
                                    onChange={(event) => {
                                        onOptionChange(event, 'JobLevelMapping');
                                      }}
                                    placeholder={IsEditMode ? OU5Name : 'Select Job Level'}
                                    options={JobLevelDdValue}
                                    isMulti={false}                     
                                    isSearchable={true}           
                                    isDisabled={
                                        isViewOu
                                        ? isViewOu
                                        : OUMappingIsActive === true ? false : true}     
                                    />
                    </div>   
                </>
                )}

                {MasterId === 'M17' && (
                <>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <label className="col-form-label">TAN Number</label>
                      <sup>*</sup>
                      <InputForm
                                  className="form-control"
                                  placeholder="TAN Number"
                                  isDisabled={
                                    isViewOu
                                    ? isViewOu
                                    : OUMappingIsActive === true ? false : true}  
                                  onChange={(e) => setOUText(e.target.value)}
                                  textArea={false}
                                  value={OUText}
                                  maxLength="255"
                        />
            
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Legal Entity</label>
                        <SelectForm
                            isClearable
                            value={LENMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'LENMapping');
                              }}
                              placeholder={IsEditMode ? OU1Name : 'Select Legal Entity Association'}
                            options={LegalEntityDdValue}
                            isMulti={false}                     
                            isSearchable={true}                           
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}
                          />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                                <label className="col-form-label">Payroll Location</label>
                                <SelectForm
                                    isClearable
                                    value={PLOCMappingValues}
                                    onChange={(event) => {
                                        onOptionChange(event, 'PLOCMapping');
                                      }}
                                    placeholder={IsEditMode ? OU2Name : 'Select Payroll Location'}
                                    options={PLOCDdValue}
                                    isMulti={false}                     
                                    isSearchable={true}           
                                    isDisabled={
                                        isViewOu
                                        ? isViewOu
                                        : OUMappingIsActive === true ? false : true}     
                                    />
                    </div>   
                </>
                )}

                {MasterId === 'M18' && (
                <>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <label className="col-form-label">Company Code</label>
                      <sup>*</sup>
                      <InputForm
                                  className="form-control"
                                  placeholder="Company Code"
                                  isDisabled={
                                    isViewOu
                                    ? isViewOu
                                    : OUMappingIsActive === true ? false : true}  
                                  onChange={(e) => setOUText(e.target.value)}
                                  textArea={false}
                                  value={OUText}
                                  maxLength="255"
                        />
            
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                                <label className="col-form-label">SBU</label>
                                <SelectForm
                                    isClearable
                                    value={SBUMappingValues}
                                    onChange={(event) => {
                                        onOptionChange(event, 'SBUMapping');
                                      }}
                                    placeholder={IsEditMode ? OU1Name : 'Select SBU'}
                                    options={SBUDdValue}
                                    isMulti={false}                     
                                    isSearchable={true}           
                                    isDisabled={
                                        isViewOu
                                        ? isViewOu
                                        : OUMappingIsActive === true ? false : true}     
                                    />
                    </div>   
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Legal Entity</label>
                        <SelectForm
                            isClearable
                            value={LENMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'LENMapping');
                              }}
                              placeholder={IsEditMode ? OU2Name : 'Select Legal Entity Association'}
                            options={LegalEntityDdValue}
                            isMulti={false}                     
                            isSearchable={true}                           
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}
                          />
                    </div>
                </>
                )}

                {MasterId === 'M19' && (
                <>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <label className="col-form-label">Probation Period Days</label>
                      <sup>*</sup>
                      <InputForm
                                  className="form-control"
                                  placeholder="Probation Period Days"
                                  isDisabled={
                                    isViewOu
                                    ? isViewOu
                                    : OUMappingIsActive === true ? false : true}  
                                  onChange={(e) => setOUText(e.target.value)}
                                  textArea={false}
                                  value={OUText}
                                  maxLength="255"
                        />
            
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Legal Entity</label>
                        <SelectForm
                            isClearable
                            value={LENMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'LENMapping');
                              }}
                              placeholder={IsEditMode ? OU1Name : 'Select Legal Entity Association'}
                            options={LegalEntityDdValue}
                            isMulti={false}                     
                            isSearchable={true}                           
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}
                          />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                                <label className="col-form-label">Custom Business</label>
                                <SelectForm
                                    isClearable
                                    value={CUSBISMappingValues}
                                    onChange={(event) => {
                                        onOptionChange(event, 'CUSBISMapping');
                                      }}
                                    placeholder={IsEditMode ? OU2Name : 'Select Custom Business'}
                                    options={CUSBISDdValue}
                                    isMulti={false}                     
                                    isSearchable={true}           
                                    isDisabled={
                                        isViewOu
                                        ? isViewOu
                                        : OUMappingIsActive === true ? false : true}     
                                    />
                    </div>           
                    <div className="col-lg-3 col-md-6 col-sm-12">
                                <label className="col-form-label">Job Level</label>
                                <SelectForm
                                    isClearable
                                    value={JobLevelMappingValues}
                                    onChange={(event) => {
                                        onOptionChange(event, 'JobLevelMapping');
                                      }}
                                    placeholder={IsEditMode ? OU3Name : 'Select Job Level'}
                                    options={JobLevelDdValue}
                                    isMulti={false}                     
                                    isSearchable={true}           
                                    isDisabled={
                                        isViewOu
                                        ? isViewOu
                                        : OUMappingIsActive === true ? false : true}     
                                    />
                    </div>   
                    <div className="col-lg-3 col-md-6 col-sm-12">
                                <label className="col-form-label">Standard Designation</label>
                                <SelectForm
                                    isClearable
                                    value={SDMappingValues}
                                    onChange={(event) => {
                                        onOptionChange(event, 'SDMapping');
                                      }}
                                    placeholder={IsEditMode ? OU4Name : 'Select Standard Designation'}
                                    options={SDDdValue}
                                    isMulti={false}                     
                                    isSearchable={true}           
                                    isDisabled={
                                        isViewOu
                                        ? isViewOu
                                        : OUMappingIsActive === true ? false : true}     
                                    />
                    </div>   
                    
                </>
                )}

                {MasterId === 'M20' && (
                <>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <label className="col-form-label">LOP Rate</label>
                      <sup>*</sup>
                      <InputForm
                                  className="form-control"
                                  placeholder="LOP Rate"
                                  isDisabled={
                                    isViewOu
                                    ? isViewOu
                                    : OUMappingIsActive === true ? false : true}  
                                  onChange={(e) => setOUText(e.target.value)}
                                  textArea={false}
                                  value={OUText}
                                  maxLength="255"
                        />
            
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Legal Entity</label>
                        <SelectForm
                            isClearable
                            value={LENMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'LENMapping');
                              }}
                            placeholder={IsEditMode ? OU1Name : 'Select Legal Entity Association'}
                            options={LegalEntityDdValue}
                            isMulti={false}                     
                            isSearchable={true}        
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}                   
                          />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Employee Class</label>
                        <SelectForm
                            isClearable
                            value={EmployeeClassMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'ECMapping');
                              }}
                            placeholder={IsEditMode ? OU2Name : 'Select Employee Class Association'}
                            options={EmployeeClassDdValue}
                            isMulti={false}                     
                            isSearchable={true}            
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}               
                          />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                                <label className="col-form-label">Job Level</label>
                                <SelectForm
                                    isClearable
                                    value={JobLevelMappingValues}
                                    onChange={(event) => {
                                        onOptionChange(event, 'JobLevelMapping');
                                      }}
                                    placeholder={IsEditMode ? OU3Name : 'Select Job Level'}
                                    options={JobLevelDdValue}
                                    isMulti={false}                     
                                    isSearchable={true}           
                                    isDisabled={
                                        isViewOu
                                        ? isViewOu
                                        : OUMappingIsActive === true ? false : true}     
                                    />
                    </div>
                </>
                )}

                {MasterId === 'M21' && (
                <>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Work Area</label>
                        <SelectForm
                            isClearable
                            value={WorkAreaMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'WorkAreaMapping');
                              }}
                            placeholder={IsEditMode ? OU5Name : 'Select Work Area Association'}
                            options={WorkAreaDdValue}
                            isMulti={false}                     
                            isSearchable={true}           
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}     
                            />
                    </div>           
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Business</label>
                        <SelectForm
                            isClearable
                            value={BUMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'BUMapping');
                              }}
                              placeholder={IsEditMode ? OU1Name : 'Select Business Association'}
                            options={BusinessDdValue}
                            isMulti={false}                     
                            isSearchable={true}           
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}                
                          />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">SBU</label>
                        <SelectForm
                            isClearable
                            value={SBUMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'SBUMapping');
                              }}
                              placeholder={IsEditMode ? OU2Name : 'Select SBU Association'}
                            options={SBUDdValue}
                            isMulti={false}                     
                            isSearchable={true}                    
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}                   
                          />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Legal Entity</label>
                        <SelectForm
                            isClearable
                            value={LENMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'LENMapping');
                              }}
                              placeholder={IsEditMode ? OU3Name : 'Select Legal Entity Association'}
                            options={LegalEntityDdValue}
                            isMulti={false}                     
                            isSearchable={true}                           
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}
                          />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Payroll Location</label>
                        <SelectForm
                            isClearable
                            value={PLOCMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'PLOCMapping');
                              }}
                              placeholder={IsEditMode ? OU4Name : 'Select Payroll Association'}
                            options={PLOCDdValue}
                            isMulti={false}                     
                            isSearchable={true}                           
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}
                          />
                    </div>
                </>
                )}

                {MasterId === 'M22' && (
                <>           
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Work Area Stage</label>
                        <SelectForm
                            isClearable
                            value={WorkAreaStageMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'WorkAreaStageMapping');
                              }}
                              placeholder={IsEditMode ? OU2Name : 'Select Work Area Stage Association'}
                            options={WorkAreaStageDdValue}
                            isMulti={false}                     
                            isSearchable={true}           
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}                
                          />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Work Area</label>
                        <SelectForm
                            isClearable
                            value={WorkAreaMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'WorkAreaMapping');
                              }}
                            placeholder={IsEditMode ? OU1Name : 'Select Work Area Association'}
                            options={WorkAreaDdValue}
                            isMulti={false}                     
                            isSearchable={true}           
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}     
                            />
                    </div> 
                </>
                )}

                {MasterId === 'M23' && (
                <>           
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Skill Category</label>
                        <SelectForm
                            isClearable
                            value={SkillCategoryMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'SkillCategoryMapping');
                              }}
                            placeholder={IsEditMode ? OU2Name : 'Select Skill Category Association'}
                            options={SkillCategoryDdValue}
                            isMulti={false}                     
                            isSearchable={true}           
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}                
                          />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Standard Designation</label>
                        <SelectForm
                            isClearable
                            value={SDMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'SDMapping');
                              }}
                            placeholder={IsEditMode ? OU1Name : 'Select Standard Designation Association'}
                            options={SDDdValue}
                            isMulti={false}                     
                            isSearchable={true}           
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}     
                            />
                    </div> 
                </>
                )}

                {MasterId === 'M24' && (
                <>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Wage</label>
                        <InputForm
                                  className="form-control"
                                  placeholder="Wage"
                                  isDisabled={
                                    isViewOu
                                    ? isViewOu
                                    : OUMappingIsActive === true ? false : true}  
                                  onChange={(e) => setOUText(e.target.value)}
                                  textArea={false}
                                  value={OUText}
                                  maxLength="255"
                        />
                    </div>           
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Business</label>
                        <SelectForm
                            isClearable
                            value={BUMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'BUMapping');
                              }}
                              placeholder={IsEditMode ? OU1Name : 'Select Business Association'}
                            options={BusinessDdValue}
                            isMulti={false}                     
                            isSearchable={true}           
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}                
                          />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">SBU</label>
                        <SelectForm
                            isClearable
                            value={SBUMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'SBUMapping');
                              }}
                              placeholder={IsEditMode ? OU2Name : 'Select SBU Association'}
                            options={SBUDdValue}
                            isMulti={false}                     
                            isSearchable={true}                    
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}                   
                          />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Legal Entity</label>
                        <SelectForm
                            isClearable
                            value={LENMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'LENMapping');
                              }}
                              placeholder={IsEditMode ? OU3Name : 'Select Legal Entity Association'}
                            options={LegalEntityDdValue}
                            isMulti={false}                     
                            isSearchable={true}                           
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}
                          />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Payroll Location</label>
                        <SelectForm
                            isClearable
                            value={PLOCMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'PLOCMapping');
                              }}
                              placeholder={IsEditMode ? OU4Name : 'Select Payroll Location Association'}
                            options={PLOCDdValue}
                            isMulti={false}                     
                            isSearchable={true}                           
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}
                          />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                                <label className="col-form-label">Standard Designation</label>
                                <SelectForm
                                    isClearable
                                    value={SDMappingValues}
                                    onChange={(event) => {
                                        onOptionChange(event, 'SDMapping');
                                      }}
                                    placeholder={IsEditMode ? OU5Name : 'Select Standard Designation'}
                                    options={SDDdValue}
                                    isMulti={false}                     
                                    isSearchable={true}           
                                    isDisabled={
                                        isViewOu
                                        ? isViewOu
                                        : OUMappingIsActive === true ? false : true}     
                                    />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Skill Category</label>
                        <SelectForm
                            isClearable
                            value={SkillCategoryMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'SkillCategoryMapping');
                              }}
                            placeholder={IsEditMode ? OU6Name : 'Select Skill Category Association'}
                            options={SkillCategoryDdValue}
                            isMulti={false}                     
                            isSearchable={true}           
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}                
                          />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Proficiency</label>
                        <SelectForm
                            isClearable
                            value={ProficiencyMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'ProficiencyMapping');
                              }}
                            placeholder={IsEditMode ? OU7Name : 'Select Proficiency Association'}
                            options={ProficiencyDdValue}
                            isMulti={false}                     
                            isSearchable={true}           
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}                
                          />
                    </div>
                </>
                )}

                {MasterId === 'M25' && (
                <>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">SLA</label>
                        <InputForm
                                  className="form-control"
                                  placeholder="SLA Days"
                                  isDisabled={
                                    isViewOu
                                    ? isViewOu
                                    : OUMappingIsActive === true ? false : true}  
                                  onChange={(e) => setOUText(e.target.value)}
                                  textArea={false}
                                  value={OUText}
                                  maxLength="255"
                        />
                    </div>           
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Standard Designation</label>
                        <SelectForm
                            isClearable
                            value={SDMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'SDMapping');
                            }}
                            placeholder={IsEditMode ? OU1Name : 'Select Standard Designation'}
                            options={SDDdValue}
                            isMulti={false}                     
                            isSearchable={true}           
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}     
                        />
                    </div>
                </>
                )}

                {MasterId === 'M26' && (
                <>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Approved Head Count</label><sup>*</sup>
                        <InputForm
                            className="form-control"
                            placeholder="Approved Head Count"
                            isDisabled={
                              isViewOu
                              ? isViewOu
                              : OUMappingIsActive === true ? false : true}  
                            onChange={(e) => setOUText(e.target.value)}
                            textArea={false}
                            value={OUText}
                            maxLength="255"
                        />
                    </div>  
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Permissible Percentage</label><sup>*</sup>
                        <InputForm
                            className="form-control"
                            placeholder="Permissible Percentage"
                            isDisabled={
                              isViewOu
                              ? isViewOu
                              : OUMappingIsActive === true ? false : true}  
                            onChange={(e) => setOUText1(e.target.value)}
                            textArea={false}
                            value={OUText1}
                            maxLength="255"
                        />
                    </div> 
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Recruitable Head Count</label><sup>*</sup>
                        <InputForm
                            className="form-control"
                            placeholder="Recruitable Head Count"
                            isDisabled={
                              isViewOu
                              ? isViewOu
                              : OUMappingIsActive === true ? false : true}  
                            onChange={(e) => setOUText2(e.target.value)}
                            textArea={false}
                            value={OUText2}
                            maxLength="255"
                        />
                    </div> 
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">End Date</label><sup>*</sup>
                        <DateForm
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : false
                            }
                            value={OUDate}
                            onChange={(date) => {
                                setOUDate(moment(date).format("DD-MMM-YYYY"));
                            }}
                        />            
                    </div>         
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Business</label><sup>*</sup>
                        <SelectForm
                            isClearable
                            value={BUMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'BUMapping');
                              }}
                              placeholder={IsEditMode ? OU1Name : 'Select Business Association'}
                            options={BusinessDdValue}
                            isMulti={false}                     
                            isSearchable={true}           
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}                
                          />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">SBU</label><sup>*</sup>
                        <SelectForm
                            isClearable
                            value={SBUMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'SBUMapping');
                              }}
                              placeholder={IsEditMode ? OU2Name : 'Select SBU Association'}
                            options={SBUDdValue}
                            isMulti={false}                     
                            isSearchable={true}                    
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}                   
                          />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Legal Entity</label><sup>*</sup>
                        <SelectForm
                            isClearable
                            value={LENMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'LENMapping');
                              }}
                              placeholder={IsEditMode ? OU3Name : 'Select Legal Entity Association'}
                            options={LegalEntityDdValue}
                            isMulti={false}                     
                            isSearchable={true}                           
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}
                          />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Payroll Location</label><sup>*</sup>
                        <SelectForm
                            isClearable
                            value={PLOCMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'PLOCMapping');
                              }}
                              placeholder={IsEditMode ? OU4Name : 'Select Payroll Location Association'}
                            options={PLOCDdValue}
                            isMulti={false}                     
                            isSearchable={true}                           
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}
                          />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label">Employee Class</label><sup>*</sup>
                        <SelectForm
                            isClearable
                            value={EmployeeClassMappingValues}
                            onChange={(event) => {
                                onOptionChange(event, 'ECMapping');
                              }}
                            placeholder={IsEditMode ? OU5Name : 'Select Employee Class Association'}
                            options={EmployeeClassDdValue}
                            isMulti={false}                     
                            isSearchable={true}            
                            isDisabled={
                                isViewOu
                                ? isViewOu
                                : OUMappingIsActive === true ? false : true}               
                          />
                    </div>
                </>
                )}

                { !isViewOu && <div className="col-lg-3 col-md-6 col-sm-12">
                    <label className="col-form-label">Remarks</label><sup>*</sup>
                    <InputForm
                        className="form-control"
                        placeholder="Remarks"
                        isDisabled={
                            isViewOu
                            ? isViewOu
                            : false}       
                        onChange={(e) => setRemarks(e.target.value)}
                        value={remarks}
                        textArea={true}
                    />       
                </div>}

                <div className="col-lg-3 col-md-6 col-sm-12">
                    <label className="col-form-label">IsActive<sup>*</sup></label>
                    <div>
                        <input
                              disabled={
                                isViewOu
                                ? isViewOu
                                : IsEditMode ? false : true}
                              type="checkbox"
                              id="IsActive"
                              onChange={async(e) => {
                                    const sts = e.target.checked;
                                    await validateInactiveConfirmation(e.target.checked);
                                    if(validInactiveConfirmation.current === false){
                                        return null;
                                    }
                                    setOUIsActive(sts);
                                }
                              }
                              checked={ OUIsActive ? true: false}
                            /> 
                    </div>
                </div>
            </div>

            
            <div className="col-lg-12 acc-heading">
                <div className="SectionSubmit my-2 clearfix">
                    {!isViewOu
                        && <button
                            onClick={onSubmitData}
                            disabled={false}
                            className="btn btn-filter-submit float-right ml-2">
                            <i className="fa-solid fa-check"></i> Submit
                        </button>
                    }
                    <button
                        onClick={() => {
                            navigate('/organizationUnitDashboard', {state:{ouType:ouForBack}});
                        }}
                        disabled={false}
                        className="btn btn-secondary float-right">
                        <i className="fa-solid fa-arrow-rotate-right"></i> Back
                    </button>
                </div>
            </div>
                

            {/* Grid View */}
            { IsEditMode && ((MasterCode !== 'CustomBusiness') &&(MasterCode !== 'Business') && (MasterCode !== 'PhysicalLocation') &&
                (MasterCode !== 'Country') && (MasterCode !== 'JobBand') && (MasterCode !== 'WorkArea') && (MasterId !== 'M3') &&
                (MasterId !== 'M10') && (MasterId !== 'M16') && (MasterId !== 'M17') && (MasterId !== 'M18') && (MasterId !== 'M19') &&
                (MasterId !== 'M20') && (MasterId !== 'M21') && (MasterId !== 'M22') && (MasterId !== 'M23') && (MasterId !== 'M24') && 
                (MasterId !== 'M25') && (MasterId !== 'M26')) &&
                (<>
                    <div className="container-fluid">
                        <div className="mb-3 pt-3">
                            <DynamicGrid
                                data={rowData}
                                columns={columnForMappingDisplay}
                                options={options}
                            />
                        </div>
                    </div>
                </>)
            }

            {/* History  */}
            {
                Id != 0 &&
                <Accordion
                  className="mx-3 my-3"
                  expanded={histAcc}
                  onChange={() => setHistAcc((prev) => !prev)}
                >
                  <AccordionSummary
                    id="panel1d-header"
                    style={{ background: "#3C5464" }}
                    className="text-white acc_close"
                    expandIcon={<ExpandMore />}
                  >
                    <div className="row d-flex align-items-center acc-heading">
                      <div className="col-lg-10 col-md-10 col-sm-12">
                        {" "}
                        <p style={{ fontSize: "16px" }}>History</p>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ViewHistoryOu OuId = {Id} OuCode = {MasterCode}/>
                  </AccordionDetails>
                </Accordion>
            }

            {/* Deactivate ou confirmation Modal */}
            <Modal
                show={showInactiveConfirmationModal}
                onHide={() => closeInactiveConfirmationModal(false)}
                backdrop="static"
                keyboard={false}
                // size="sm"
                centered>
                <div
                  className="bg-secondary text-white"
                  style={{
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}>
                  <div className="col-lg-12">Confirm!</div>
                </div>
                <Modal.Body>
                  <h5>
                    <p>Deactivating the Organization Unit will affect <u style={{color:"red"}}>{`${empMappedCount} Employees`}</u> associated with the {`${OUName}`} Organization Unit.</p>
                    <button
                      type="button"
                      onClick={() => { downloadexcel() }}
                      className="btn btn-primary float-right">
                      <i className="fa fa-download mr-2"></i>
                    </button>
                  </h5>
                  <h5>{"Do you want to continue?"}</h5>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    className="btn btn-success"
                    style={{ marginLeft: 5 }}
                    onClick={() => {
                        closeInactiveConfirmationModal(true);
                    }}>
                    Yes
                  </button>
                  <button
                    className="btn btn-cancel"
                    style={{ marginLeft: 5 }}
                    onClick={() => {
                        closeInactiveConfirmationModal(false);
                    }}>
                    No
                  </button>
                </Modal.Footer>
            </Modal>

            {/* Ou is in Progression modal */}
            <Modal
                show={showConfirmationModal}
                onHide={() => closeConfirmationModal(false)}
                backdrop="static"
                keyboard={false}
                // size="sm"
                centered>
                <div
                  className="bg-secondary text-white"
                  style={{
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}>
                  <div className="col-lg-12">Confirm!</div>
                </div>
                <Modal.Body>
                  <h5>{"The changes made will override the existing progression changes for this Organization Unit."}</h5>
                  <h5>{"Do you want to continue?"}</h5>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    className="btn btn-success"
                    style={{ marginLeft: 5 }}
                    onClick={() => {
                        closeConfirmationModal(true);
                    }}>
                    Yes
                  </button>
                  <button
                    className="btn btn-cancel"
                    style={{ marginLeft: 5 }}
                    onClick={() => {
                        closeConfirmationModal(false);
                    }}>
                    No
                  </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}


export default OrganizationUnitForm;