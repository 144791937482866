import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  TextField,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

const EmptyAccordian = ({ header, children }) => {
  const [accordion, setAccordion] = useState<boolean>(true);

  return (
    <Accordion
      elevation={0}
      className="mb-3"
      expanded={accordion}
      onChange={() => {
        setAccordion((prev) => !prev);
      }}
    >
      <AccordionSummary
        id="profile-accordion"
        style={{ background: "#3C5464" }}
        className="text-white acc_close"
        expandIcon={<ExpandMore />}
      >
        <div className="row d-flex align-items-center acc-heading">
          <div className="col-lg-10 col-md-10 col-sm-12">
            <p style={{ fontSize: "16px" }}>{header}</p>
          </div>
        </div>
      </AccordionSummary>

      <AccordionDetails className="page_heading">{children}</AccordionDetails>
    </Accordion>
  );
};

export default EmptyAccordian;
