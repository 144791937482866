import React, { useState, useEffect, useContext ,useRef} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import InputForm from "../../Components/InputForm/InputForm";
import SelectForm from "../../Components/SelectForm/SelectForm";
import ButtonForm from "../../Components/ButtonForm/ButtonForm";
import { APICall } from "../../Helpers/APICalls";
import ExcelJS from "exceljs";
import { getOnboardingCheckListDetails,getOnBoardingApprovalResponsiblePerson,UpdateOnBoardingWorkFlowStatus,documentUpload,downloadDocument, getDocuments, deleteDocument } from "../../Helpers/APIEndPoints/EndPoints";
import { Tooltip } from "@mui/material";
import { LoaderContext } from "../../Helpers/Context/Context";
import notify from "../../Helpers/ToastNotification";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import moment from "moment";
import "./OnBoardingCheckList.css";
import DateForm from "../../Components/DateForm/DateForm";
import { AlternateEmailTwoTone, ContactSupportOutlined } from "@mui/icons-material";

const OnBoardingApprovalDashboard = () => {
  let navigate = useNavigate();
  const { state } = useLocation();
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [onBoardingData, setOnBoardingData] = useState(
    "Onboarding ONB # : 123456, 03 Jun 2023"
  );
  const [name, setName] = useState("Firstname, Lastname");
  const [employeeClass, setEmployeeClass] = useState("Employee Class");
  const [designation, setDesignation] = useState("Designation");
  const [email, setEmail] = useState("Email Id");
  const [phone, setPhone] = useState("Mobile Number");
  const [jobTitle, setJobTitle] = useState("Job Title");
  const [legalEntity, setLegalEntity] = useState("Legal Entity");
  const [location, setLocation] = useState("LocationMU | FunctionBU");
  const [dateOfJoining, setDateOfJoining] = useState("10 july 2023");
  const [textstatus, setTextStatus] = useState("In porgress");
  const [status, setStatus] = useState([]);

  const [taskGroup, setTaskGroup] = useState();
  const [task, setTask] = useState();
  const [responsible, setResponsible] = useState();
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [statusOption, setStatusOption] = useState([

    // { "value": 2, 'label': 'Reject' },
  ]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [dueDate, setDueDate] = useState<any>(null);
  const [remark, setRemark] = useState("");
  const [attCode, setAttCode] = useState("");
  let {userDetails, currentRoleId }: any = useUserContext();
  const [formErrors, setFormErrors] = useState({});
  const uploadingDocRef = useRef(null);
  const [documentUploadDoc, setDocumentUploadDoc] = useState<
    {
      AC: string;
      Name: string;
      Data: string;
      IsValidFile: boolean;
      DocId: Number;
    }[]
  >([]);
  let formErrorObj = {};

  useEffect(() => {
    // if (state !== null && state && state > 0) {
    // }
    getHeaderData();
    getApprovalDetail();
    getUploadDocument();
  }, []);
 
  console.log('state::', state);

  const getHeaderData = async () => {
    await showLoader();
    let obj = {
        Id: typeof(state)==='object'? state.Id :state, //1097,//state
    };
     
    const resp = await APICall(getOnboardingCheckListDetails, "POST", obj); 
    if (resp?.data?.length > 0) {
      let onBoardingNo =
        resp?.data[0][0]?.legalEntity[0].onBoardingNo != null ||
        resp?.data[0][0]?.legalEntity[0].onBoardingNo != ""
          ? resp?.data[0][0]?.legalEntity[0].onBoardingNo
          : "";
      let dn =
        resp?.data[0][0]?.legalEntity[0].dn != null ||
        resp?.data[0][0]?.legalEntity[0].dn != ""
          ? resp?.data[0][0]?.legalEntity[0].dn
          : "";
      let jobTitle =
        resp?.data[0][0]?.legalEntity[0].jobTitle != null ||
        resp?.data[0][0]?.legalEntity[0].jobTitle != ""
          ? resp?.data[0][0]?.legalEntity[0].jobTitle
          : "";
      let legalEntity =
        resp?.data[0][0]?.legalEntity[0].legalEntity != null ||
        resp?.data[0][0]?.legalEntity[0].legalEntity != ""
          ? resp?.data[0][0]?.legalEntity[0].legalEntity
                : "";
        let status =

        resp?.data[0][0]?.legalEntity[0].status != null ||
        resp?.data[0][0]?.legalEntity[0].status != ""
          ? resp?.data[0][0]?.legalEntity[0].status
                : "";     
      let location =
        resp?.data[0][0]?.legalEntity[0].location != null ||
        resp?.data[0][0]?.legalEntity[0].location != ""
          ? resp?.data[0][0]?.legalEntity[0].location
          : "";
          let dateOfJoining =
          resp?.data[0][0]?.legalEntity[0]?.dateOfJoining !==null
          ? moment(resp?.data[0][0]?.legalEntity[0]?.dateOfJoining)
              .format("DD MMM YYYY")
          : "";

          let EmployeeClass =
          resp?.data[0][0]?.legalEntity[0].dn != null ||
          resp?.data[0][0]?.legalEntity[0].dn != ""
            ? resp?.data[0][0]?.legalEntity[0].employeeClass
            : "";
        
        let Designation =
          resp?.data[0][0]?.legalEntity[0].dn != null ||
          resp?.data[0][0]?.legalEntity[0].dn != ""
            ? resp?.data[0][0]?.legalEntity[0].designation
            : "";
        let EmailId =
          resp?.data[0][0]?.legalEntity[0].dn != null ||
          resp?.data[0][0]?.legalEntity[0].dn != ""
            ? resp?.data[0][0]?.legalEntity[0].email
            : "";
        let PhoneNum =
          resp?.data[0][0]?.legalEntity[0].dn != null ||
          resp?.data[0][0]?.legalEntity[0].dn != ""
            ? resp?.data[0][0]?.legalEntity[0].phone
            : "";
  
        setEmployeeClass(EmployeeClass);
        setDesignation(Designation);
        setEmail(EmailId);
        setPhone(PhoneNum);
        
      setOnBoardingData(onBoardingNo);
      setName(dn);
      setJobTitle(jobTitle);
      setLegalEntity(legalEntity);
      setStatus(status);
      setLocation(location);
      setDateOfJoining(dateOfJoining);

      hideLoader();
    }
    await hideLoader();
  };
  const getApprovalDetail = async () => {
    await showLoader();
    let obj = {
        WorkflowTaskId: typeof(state)==='object'? state.Id :state,//1736, 
      // WorkflowInstanceId:451,
      // WorkflowTaskId: 1097
    };
   
    const resp = await APICall(getOnBoardingApprovalResponsiblePerson, "POST", obj );
    console.log(resp);
     const onboardingApprovalData =resp?.data[0] 
    if (resp?.data?.length > 0) {
      const parseData = JSON.parse(onboardingApprovalData.additionalDetails);
      setTaskGroup(onboardingApprovalData.taskGroup);
      setTask(onboardingApprovalData.task);
      let datepro;
      if (moment(onboardingApprovalData.dueDate)?.isValid()) {
        datepro = moment(onboardingApprovalData.dueDate).format("DD-MMM-YYYY");
      }
      setDueDate(datepro);
      // setDueDate(onboardingApprovalData.dueDate.moment().valid() ? onboardingApprovalData.dueDate.moment().format('DD-MMM-YYYY') :  "")
      setResponsible(onboardingApprovalData.responsible);
      setRemark("");
      setStatusOption(parseData);
      setSelectedValue(parseData?.find(x => x.label === onboardingApprovalData.outcome));
      setRemark(onboardingApprovalData.remarks);
      await hideLoader();
    }
    await hideLoader();
  };
  const handleSelectChange = async(event) => {
    await showLoader();
    setSelectedValue(event);
    await hideLoader();
  };
  const getUploadDocument =  async() =>{
    await showLoader();
    const resp = await APICall(getDocuments, "POST",{
      TId: typeof(state)==='object'? state.Id :state,
      AC: attCode,
    });
    //console.log(resp);
    
    if (resp && resp.data !== null && resp.data !== undefined) {
      setDocumentUploadDoc(resp.data.files);
    } else {
      setDocumentUploadDoc([]);
    }
    await hideLoader();
  }
  // const handleStatusChange = (selected: any) => {
  //   showLoader();
  //   setStatus(selected);
  //   hideLoader();
  // };
  const fileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileValidation = (files) => {
    const [FS, FX, FC] = [
      5,
      ".docx,.doc,.pdf,.png,.jpg,.jpeg,.txt,.ppt,.pptx,.xlsx",
      1,
    ];

    // Split the validExtensionsString into an array
    const validExtensions = FX.split(",");

    let isValid = [];

    // validate file count
    if (files.length > FC) {
      notify(1, `Only ${FC} files allowed!`);
      return false;
    }
    // check extension of each file
    files.forEach((file) => {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();

      // Check if the file extension is allowed
      if (!validExtensions.includes("." + fileExtension)) {
        isValid.push(false);
      } else {
        isValid.push(true); //valid
      }
    });

    let returnValue = isValid.some((v) => v === false);

    if (returnValue) {
      notify(1, `Please upload file within this ${FX} extentsion`);
      return false;
    }

    files.forEach((file) => {
      const fileSize = file.size;
      const maxFileSizeBytes = FS * 1024 * 1024;
      if (fileSize <= maxFileSizeBytes) {
        isValid.push(true); //valid
      } else {
        isValid.push(false);
      }
    });

    returnValue = isValid.some((v) => v === false);

    if (returnValue) {
      notify(1, `File size exceeds ${FS}MB. Please select a smaller file.`);
      return false;
    } else {
      return true;
    }
  };
  const handleSupportingDoc = async (e) => {
    let AC = '';
    if(task === 'Joining Report'){  
      AC = 'JR'
    } else if(task === 'Consent on Biometric System '){
      AC = 'COBS'
    } else if(task === 'Group Term Life Insurance Form '){
      AC = 'GTIF'
    } else if(task === 'Group Personal Accident  Nomination '){
      AC = 'GPAN'
    } else if(task === 'Email ID Creation '){
      AC = 'EIC'
    } else if(task === 'Wage Declaration Form '){
      AC = 'WDF'
    } else if(task === 'Form 11 (EPFO) '){
      AC = 'FEPFO'
    } else if(task === 'Form F (Gratuity) '){
      AC = 'FGRAT'
    } else if(task === 'POSH - Prevention of Sexual Harassment '){
      AC = 'POSH'
    }

    setAttCode(AC);

    const [FS, FX, FC] = [
      "5",
      ".docx,.doc,.pdf,.png,.jpg,.jpeg,.txt,.ppt,.pptx,.xlsx",
      1,
    ];

    const files = Array.prototype.slice.call(e.target.files);

    const isValid = handleFileValidation(files);
    if (isValid) {
       if (files && files.length > 0) {
         const base64Array: { Name: string; Data: string }[] = [];
         for (let i = 0; i < files.length; i++) {
           const base64 = (await fileToBase64(files[i])) as string;
           const base64WithoutPrefix = base64.split(",")[1];  
           base64Array.push({
             Name: files[i].name,
             Data: base64WithoutPrefix,
           });
         }

        //check if AC present and file count matched

        const fileCount = documentUploadDoc.reduce((total, item) => {
          if (item.AC === AC) {
            return total + 1;
          }
          return total;
        }, 0);

        if (fileCount == FC) {
          notify(1, `Only ${FC} files allowed!`);
        } else {
          base64Array.forEach((file) => {
            setDocumentUploadDoc((prevSelectedFiles) => [
              ...prevSelectedFiles,
              {
                AC: AC,
                Name: file.Name,
                Data: file.Data,
                IsValidFile: false,
                DocId: 0,
                Type: "",
                Context: "",
                DCVALUE: "",
                DVTILL: "",
                DSTATUS: "",
                DPSINCE: "",
                DPWITH: "",
              },
            ]);
          });
        }
      }
    } else {
      e.target.value = null;
    }
  };
  const onSubmitValidation = () => {
    setFormErrors({});
    formErrorObj = {};
    if (!selectedValue || selectedValue === "") {
      formErrorObj["Status_isEmpty"] = "Please select status";
    }
    if (!remark || remark === "") {
      formErrorObj["Remark_isEmpty"] = "Remark can not be empty";
    } else if (remark.length >= 255) {
      formErrorObj["Remark_isEmpty"] =
        "Remark should be less than 255 charaters";
    }

    setFormErrors(formErrorObj);
  };
  console.log(documentUploadDoc);
  const onClickFunction = async (action: any) => {
    if (action == "Submit") {
      onSubmitValidation();
      let isEmpty = Object.values(formErrorObj).every((f) => {
        return f === "" || f === null || f === undefined;
      });
      if (isEmpty === true) {
        const modifiedby = userDetails.ModifiedBy;
        const actionbyuser = userDetails.Id;
        const actionbyrole = currentRoleId.value;
        let param = {
          // Id: 10, //state.id != null || state.id != 0 ? state.id : 0,
          Status: selectedValue.label === 'Completed' ? 2 : 1,
          Outcome: selectedValue.label,
          Remarks: remark,
          WorkFlowTaskId: state.Id == undefined? state:state.Id,
          ActionByUserId:actionbyuser,
          ActionByRoleId:actionbyrole,
          ModifiedBy:actionbyuser,
        };
        console.log(param);
         const response = await APICall(UpdateOnBoardingWorkFlowStatus, "POST", param);
         console.log(response)
         let obj = {
          TId: state,//1736, 
          files:documentUploadDoc,
          ModuleName: 'OnBoarding',
          ModuleId: 10014,
          UserId: userDetails.Id
      };
         if(response.status==0 && documentUploadDoc.length >0){
          showLoader();
           const resp = await APICall(documentUpload, "POST",obj )
           console.log(resp)
           hideLoader();
           notify(0, "Data saved sucessfully");
          navigate("/home");
         }
         else if(response.status==0){
          notify(0, "Data saved sucessfully");
          navigate("/home");
         }
         else{
          console.log('error');
         }
        
        // if (resp?.status == 0) {
        //   notify(resp?.status, "Data save sucessfully");
       
        //   navigate("/home");
        // } else {
        //   notify(resp?.status, resp?.message);
        //   setWorkflowSubmitDissable(false);
        // }
        // navigate("/home");
      }
      
    } else if (action == "Reset") {
      resetForm();
    } else {
      navigate(-1);
    }
  };
  const handleDownloadFile = async (fileAttribute, fileIndex) => {
    showLoader();
    if (
      fileAttribute.DocId != undefined &&
      fileAttribute.DocId != null &&
      fileAttribute.DocId != 0
    ) {
      const doc = await APICall(downloadDocument, "POST", {
        TId:state,
        AC: fileAttribute.AC,
        DocId: fileAttribute.DocId,
      });
      if (doc && doc.data !== null) {
        downloadBase64File(doc.data, fileAttribute.Name);
        hideLoader();
      } else {
        notify(1, "Something Went Wrong.");
        hideLoader();
      }
    } else {
      downloadBase64File(fileAttribute.Data, fileAttribute.Name);
      hideLoader();
    }
  };
  function downloadBase64File(base64String: string, fileName: string) {
    const mimeType = inferMimeType(base64String);
    const blob = base64toBlob(base64String, mimeType);
 
    const url = URL.createObjectURL(blob);
 
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
 
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
 
    URL.revokeObjectURL(url);
  }
  function inferMimeType(base64String: string): string {
    const dataUrl = `data:application/octet-stream;base64,${base64String}`;
    const typeInfo = /^data:(.*?);/.exec(dataUrl);
 
    if (typeInfo && typeInfo[1]) {
      return typeInfo[1];
    }
 
    return "application/octet-stream";
  }
  function base64toBlob(base64String: string, mimeType: string): Blob {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
  
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
  
    const byteArray = new Uint8Array(byteNumbers);
  
    return new Blob([byteArray], { type: mimeType });
    
  }

//  const handleDownloadFile = async (fileAttribute, fileIndex) => {

//   if (
//     fileAttribute.DocId != undefined &&
//     fileAttribute.DocId != null &&
//     fileAttribute.DocId != 0
//   ) {
//     const doc = await APICall(downloadDocument, "POST", {
//       TId:state,
//       AC: fileAttribute.AC,
//       DocId: fileAttribute.DocId,
//     })}}
  const resetForm = () => {
    showLoader();
    setFormErrors({});
    setSelectedValue(null);
    setRemark("");
    setDocumentUploadDoc([]);
    hideLoader();
  };

  const handleDeleteFileForSet = async (fileAttribute, fileIndex) => {
    if (
      fileAttribute.DocId != undefined &&
      fileAttribute.DocId != null &&
      fileAttribute.DocId != 0
    ) {
      showLoader();
      const doc = await APICall(deleteDocument, "POST", {
        TId: state,
        AC: fileAttribute.AC,
        DocId: fileAttribute.DocId,
        UserId: userDetails.Id
      });
      if (doc.message === "Success") {
        uploadingDocRef.current.value = null;
        let remainingDocs = documentUploadDoc.filter(function (obj) {
          return obj.DocId !== fileAttribute.DocId;
        });
        setDocumentUploadDoc(remainingDocs);
        hideLoader();
      } else {
        notify(1, "File not Deleted, Something Went Wrong.");
        hideLoader();
      }
    } else {
      uploadingDocRef.current.value = null;
      setDocumentUploadDoc((prevSelectedFiles) => {
        return prevSelectedFiles.filter(
          (item, attIndex) => fileIndex !== attIndex
        );
      });
    }
  };
  
  return (
    <>
      <div className="main-section">
        <div
          className="row"
          style={{ padding: "20px", color: "white", fontSize: "14px" }}
        >
          <div className="col-sm-4 col-md-4">
            <table>
              <tr>
                <td>Onboarding ONB # : </td>
                <td>{onBoardingData}</td>
              </tr>
              <tr>
                <td>Name : </td>
                <td>{name}</td>
              </tr>
              <tr>
                <td>Job Title : </td>
                <td>{jobTitle}</td>
              </tr>
              <tr>
                <td>Legal Entity : </td>
                <td>{legalEntity}</td>
              </tr>
              <tr>
                <td>Designation : </td>
                <td>{designation}</td>
              </tr>
              <tr>
                <td>Employee Class : </td>
                <td>{employeeClass}</td>
              </tr>
              <tr>
                <td>Location : </td>
                <td>{location}</td>
              </tr>
            </table>
          </div>
          <div className="col-sm-4 col-md-4"></div>
          <div className="col-sm-4 col-md-4">
            <table>
              <tr>
                <td>Email : </td>
                <td>{email}</td>
              </tr>
              <tr>
                <td>Mobile Number : </td>
                <td>{phone}</td>
              </tr>
              <tr>
                <td>Date of Joining : </td>
                <td>{dateOfJoining}</td>
              </tr>
              <tr>
                <td>Status : </td>
                <td>{status}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="grid-wrapper-onboard">
          <p className="ChecklistTitle">Onboarding Task Approval Dashboard</p>
        </div>
        <div className="row">
          <div className="col-sm-3 col-md-3">
            <div className="form-group">
              <label className="col-form-label">
                Task Group <sup>*</sup>
              </label>
              <InputForm
                value={taskGroup}
                placeholder={""}
                isDisabled={isDisabled}
                textArea={false}
                onChange={(e) => console.log(e.target.value)}
              />
            </div>
          </div>
          <div className="col-sm-3 col-md-3">
            <div className="form-group">
              <label className="col-form-label">
                Task <sup>*</sup>
              </label>
              <InputForm
                value={task}
                placeholder={""}
                isDisabled={isDisabled}
                textArea={false}
                onChange={(e) => console.log(e.target.value)}
              />
            </div>
          </div>
          <div className="col-sm-3 col-md-3">
            <span>
              <label className="col-form-label">
                Due Date <sup>*</sup>
              </label>
            </span>
            <DateForm
              isDisabled={isDisabled}
              value={dueDate}
              onChange={(date) => {
                setDueDate(date);
              }}
            />
          </div>
          <div className="col-sm-3 col-md-3">
            <div className="form-group">
              <label className="col-form-label">
                Responsible <sup>*</sup>
              </label>
              <InputForm
                value={responsible}
                placeholder={""}
                isDisabled={isDisabled}
                textArea={false}
                onChange={"onSearchFilter"}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3 col-md-3">
            <div className="form-group">
              <label className="col-form-label">
                Status <sup>*</sup>
              </label>
              <SelectForm
                isClearable
                isSearchable
                isMulti={false}
                noIndicator={false}
                noSeparator={false}
                id="dropdown1"
                value={selectedValue ? selectedValue : null}
                placeholder="Select an option"
                options={statusOption}
                onChange={(event)=>handleSelectChange(event)}
                isDisabled={typeof(state)==='object'&& state.IsTaskCompleted !==undefined ? state.IsTaskCompleted:false}
              />
              <p style={{ color: "red" }}>{formErrors["Status_isEmpty"]}</p>
            </div>
          </div>
          {taskGroup === 'Document Collection' && (
            <>
              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">Document</label>

                  <div className="box position-relative">
                    <input
                      id={"ONBOARDUPLOADDOC"}
                      className="form-control inputfile inputfile-6 multiple-inputfile"
                      multiple={false}
                      type="file"
                      ref={uploadingDocRef}
                      onChange={(e) => {
                        handleSupportingDoc(e);
                      }}
                      accept={
                        ".docx,.doc,.pdf,.png,.jpg,.jpeg,.txt,.ppt,.pptx,.xlsx"
                      }
                      disabled={false}
                    />
                    <label 
                      htmlFor={"ONBOARDUPLOADDOC"}
                      className="form-control"
                      style={{ width: 0, border: "none" }}>
                      <strong
                        style={{
                          padding: "6px 16px",
                          backgroundColor: "#3c5464",
                          borderRadius: "5px",
                        }}>
                        <i
                          className="fa fa-upload rotate90"
                          aria-hidden="true"></i>
                        {"  "}
                        Upload
                      </strong>{" "}
                    </label>
                    
                  </div>
                  <div>
                   <a
                   style={{cursor:"pointer"}}
                      className="link-primary"
                      href={require(`./Template.docx`)}
                      download={`Template`}
                      //target="_blank"
                    >
                    <p className="policylinks">  Empty Template </p>
                    </a>
                  </div>
                  <div className="file-added-list">
                    <ul className="list-unstyle">
                      <li className="list mt-1" key={"a"}> 
                        <div className="media">
                          <div className="media-body text-truncate">
                          <span className="view-more">
                            Empty Template
                          </span>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <ul className="list-unstyle">
                      {documentUploadDoc &&
                      
                      documentUploadDoc.length > 0 &&
                      documentUploadDoc.map(
                          (fileAttribute, attributeIndex) => (
                            <>
                              {/* {fileAttribute.AC ===
                                                          subAttribute.AC &&
                                                          (fileAttribute.Id ===
                                                            0 ||
                                                            fileAttribute.Id ==
                                                              currentGridId) && ( */}
                              <li className="list mt-1" key={attributeIndex}>
                                
                                <div className="media">
                                  <div className="media-body text-truncate">
                                    <span className="view-more">
                                      {fileAttribute.Name}
                                    </span>
                                  </div>
                                  {/*Here code for view same div of trash should be used. ISDOCVIEW/ISDOCDOWN*/}
                                  {/* {
                                                                  // section?.ISDOCVIEW &&
                                                                  fileAttribute.DocId !==
                                                                    0 && (
                                                                    <div className="media-right ml-2">
                                                                      <i
                                                                        className="fa fa-eye"
                                                                        aria-hidden="true"
                                                                        onClick={() =>
                                                                          handleViewForFlat(
                                                                            fileAttribute,
                                                                            attributeIndex
                                                                          )
                                                                        }></i>
                                                                    </div>
                                                                  )
                                                                } */}

                                  {
                                    // section?.ISDOCDOWN &&
                                    // <div className="media-right ml-2">
                                    //   <i
                                    //     className="fa-solid fa-download"
                                    //     aria-hidden="true"
                                    //     onClick={() =>
                                    //       // handleDownloadFileFlat(
                                    //       //   fileAttribute,
                                    //       //   attributeIndex
                                    //       // )
                                    //       {}
                                    //     }></i>
                                    // </div>
                                  }
                                  {/* {section?.ISEDIT &&
                                                                  viewOnly ===
                                                                    false &&
                                                                  fileAttribute.DocId !==
                                                                    0 && ( */}
                                  <div className="media-right ml-2">
                                  <i
                                        className="fa-solid fa-download mr-2"
                                        aria-hidden="true"
                                        onClick={() =>
                                               handleDownloadFile(
                                              fileAttribute,
                                              attributeIndex
                                              )}
                                        ></i>
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                      onClick={() =>
                                        handleDeleteFileForSet(
                                          fileAttribute,
                                          attributeIndex
                                        )
                                      }></i>
                                       
                                  </div>
                                  {/* )} */}
                                </div>
                              </li>
                              {/* )} */}
                            </>
                          )
                        )}
                    </ul>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="col-sm-6 col-md-6">
            <div className="form-group">
              <label className="col-form-label">
                Remark <sup>*</sup>
              </label>
              <InputForm
                value={remark}
                placeholder={"Remark"}
                isDisabled={typeof(state)==='object'&& state.IsTaskCompleted !==undefined ?state.IsTaskCompleted:false}
                textArea={true}
                onChange={(e) => setRemark(e.target.value)}
              />
              <p style={{ color: "red" }}>{formErrors["Remark_isEmpty"]}</p>
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-end mt-3"
          style={{ marginRight: "2rem" }}
        >
          <div>
            <button
              className="btn btn-secondary mr-2"
              onClick={() => onClickFunction("Cancel")}
              //disabled = {state.IsEdit}
            >
              <i className="fa-solid fa-arrow-left" aria-hidden="true"></i> Back
            </button>
            <button
              className="btn btn-secondary mr-2"
              onClick={() => onClickFunction("Reset")}
              disabled = {state.IsEdit}
            >
              <i className="fa fa-undo" aria-hidden="true"></i> Reset
            </button>

           { (state?.IsTaskCompleted!==true ) ? 
           <button
              style={{ marginLeft: 5 }}
              className="btn btn-filter-submit"
              onClick={() => onClickFunction("Submit")}
              disabled = {state.IsEdit == false? true :false}
            >
              <i className="fa fa-check" aria-hidden="true"></i> Submit{" "}
            </button>
          //   :
          //   state?.IsTaskCompleted===undefined
          //   ?<button
          //   style={{ marginLeft: 5 }}
          //   className="btn btn-filter-submit"
          //   onClick={() => onClickFunction("Submit")}
          // >
          //   <i className="fa fa-check" aria-hidden="true"></i> Submit{" "}
          // </button>
          :null
          }
            
          </div>
        </div>
      </div>
    </>
  );
};

export default OnBoardingApprovalDashboard;
