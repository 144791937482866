import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import InputForm from "../../Components/InputForm/InputForm";
import ParameterDetail from "./ParameterDetail";
import { Modal } from "react-bootstrap";

const ActivityDetail = ({
  activity,
  stIndex,
  acIndex,
  jobIndex,
  jobData,
  setJobData,
  isReadOnly,
}) => {
  const newParameter = {
    paSeqNo: "",
    name: null,
    uom: null,
    paId: "",
    uomValue: "",
    paValue: "",
  };
  const [accordionState, setAccordionState] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationModalReturnValue, setConfirmationModalReturnValue] =
    useState(false);

  const closeConfirmationModal = (confirmation) => {
    if (confirmation) {
      setConfirmationModalReturnValue(confirmation);
    }
    setShowConfirmationModal(false);
  };

  useEffect(() => {
    if (confirmationModalReturnValue) {
      let newArr = [...jobData];

      if (
        newArr[jobIndex].structureDetails[stIndex].hasOwnProperty(
          "activityDetails"
        )
      ) {
        newArr[jobIndex].structureDetails[stIndex].activityDetails = newArr[
          jobIndex
        ].structureDetails[stIndex].activityDetails.filter(
          (activity, ax) => ax !== acIndex
        );
      }

      setJobData(newArr);
    }
    return () => {
      setShowConfirmationModal(false);
      setConfirmationModalReturnValue(false);
    };
  }, [confirmationModalReturnValue]);

  return (
    <>
      <Accordion
        className="mb-2"
        expanded={accordionState}
        onChange={() => setAccordionState((prev) => !prev)}>
        <AccordionSummary
          className="text-white"
          style={{ background: "#AAAAAA", height: "20px" }}
          expandIcon={<ExpandMore />}
          id={`activity-${acIndex}`}>
          <div className="d-flex align-items-center">
            <Typography>
              {activity.acSeqNo && `${activity.acSeqNo} `}
              <strong>Activity</strong> {`${activity.name}`}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails
          style={{
            background: "#eee",
          }}>
          <div className="container-fluid">
            <div
              style={{ padding: 15 }}
              className="row align-items-center justify-content-between">
              <div className="row">
                <div className="col-lg-4 col-md-2 col-sx-2">
                  <div className="form-group">
                    <label className="col-form-label">Sequence</label>
                    <sup>*</sup>
                    <InputForm
                      className="form-control form-control-lg"
                      placeholder={""}
                      name="acSeqNo"
                      isDisabled={isReadOnly}
                      textArea={false}
                      value={activity.acSeqNo}
                      onChange={(e) => {
                        let newArr = [...jobData];
                        newArr[jobIndex].structureDetails[
                          stIndex
                        ].activityDetails[acIndex][e.target.name] =
                          e.target.value;
                        setJobData(newArr);
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-2 col-sx-2">
                  <div className="form-group">
                    <label className="col-form-label">Name</label>
                    <sup>*</sup>
                    <InputForm
                      className="form-control form-control-lg"
                      placeholder={""}
                      name="name"
                      isDisabled={isReadOnly}
                      textArea={false}
                      value={activity.name}
                      onChange={(e) => {
                        let newArr = [...jobData];
                        newArr[jobIndex].structureDetails[
                          stIndex
                        ].activityDetails[acIndex][e.target.name] =
                          e.target.value;
                        setJobData(newArr);
                      }}
                    />
                  </div>
                </div>
              </div>
              {!isReadOnly && (
                <div>
                  <button
                    className="btn btn-danger"
                    style={{ marginLeft: 5 }}
                    onClick={(e) => setShowConfirmationModal(true)}>
                    <i className="fa fa-trash"></i> Delete
                  </button>
                  <button
                    className="btn btn-info"
                    style={{ marginLeft: 5 }}
                    onClick={(e) => {
                      let newArr = [...jobData];
                      if (
                        newArr[jobIndex].structureDetails[
                          stIndex
                        ].activityDetails[acIndex].hasOwnProperty(
                          "parameterDetails"
                        )
                      ) {
                        let prevPa =
                          newArr[jobIndex].structureDetails[stIndex]
                            .activityDetails[acIndex].parameterDetails;

                        prevPa = [...prevPa, newParameter];

                        newArr[jobIndex].structureDetails[
                          stIndex
                        ].activityDetails[acIndex].parameterDetails = prevPa;
                      } else {
                        newArr[jobIndex].structureDetails[
                          stIndex
                        ].activityDetails[acIndex].parameterDetails = [
                          newParameter,
                        ];
                      }
                      setJobData(newArr);
                    }}>
                    <i className="fa fa-sitemap"></i> Add Parameter
                  </button>
                </div>
              )}
            </div>
            <div>
              {activity.hasOwnProperty("parameterDetails") &&
                activity.parameterDetails.length > 0 &&
                activity.parameterDetails.map((parameter, paIndex) => (
                  <React.Fragment key={paIndex}>
                    <ParameterDetail
                      parameter={parameter}
                      jobData={jobData}
                      jobIndex={jobIndex}
                      setJobData={setJobData}
                      stIndex={stIndex}
                      acIndex={acIndex}
                      paIndex={paIndex}
                      isReadOnly={isReadOnly}
                    />
                  </React.Fragment>
                ))}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Modal
        show={showConfirmationModal}
        onHide={() => closeConfirmationModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">Delete Activity</div>
        </div>
        <Modal.Body>
          <h5>Are you sure?</h5>
          <div>
            <strong>(Note: This will also delete related Parameter)</strong>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-success"
            style={{ marginLeft: 5 }}
            onClick={() => closeConfirmationModal(true)}>
            Yes
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => closeConfirmationModal(false)}>
            No
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ActivityDetail;
