import React, { useContext, useEffect, useState } from "react";
import { APICall } from "../../Helpers/APICalls";
import SelectForm from "../../Components/SelectForm/SelectForm";
import notify from "../../Helpers/ToastNotification";
import { LoaderContext } from "../../Helpers/Context/Context";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { ExpandMore } from "@mui/icons-material";
import {
  getDropdowns,
  GetModuleDropdown,
  getAllRoles,
  InsertUpdateTaskChecklistData,
  GetTaskChecklistData,
  GetTaskChecklistCountryData,
  CheckIfTaskExist,
  GetTaskChecklistLEData,
  GetTaskChecklistPLOCData
} from "../../Helpers/APIEndPoints/EndPoints";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import InputForm from "../../Components/InputForm/InputForm";
import { useLocation, useNavigate } from "react-router-dom";

const TaskChecklist = () => {
  const [moduleNameDropdownOptions, setModuleNameDropdownOptions] = useState([]);
  const [rolesDropdownOptions, setRolesDropdownOptions] = useState([]);
  const [emailTemplateDropdownOptions, setEmailTemplateDropdownOptions] = useState([]);
  const [moduleNameDropdownValue, setModuleNameDropdownValue] = useState<any>(null);
  const [countryDropdownOptions, setCountryDropdownOptions] = useState([]);
  const [taskGroupDropdownOptions, setTaskGroupDropdownOptions] = useState([]);
  const [taskTypeDropdownOptions, setTaskTypeDropdownOptions] = useState([]);
  const [legalEntityDropdownOptions, setLegalEntityDropdownOptions] = useState([]);
  const [payrollLocationDropdownOptions, setPayrollLocationDropdownOptions] = useState([]);
  const [SLABDropdownOptions, setSLABDropdownOptions] = useState([{'label': 'Date of Joining','value':1}, {'label': 'Date of Initiation','value':2}]);
  const [countryValue, setCountryValue] = useState<any>(null);
  const [LegalEntityValue, setLegalEntityValue] = useState<any>(null);
  const [PLOCValue, setPLOCValue] = useState<any>(null);
  const [taskTypeValue, setTaskTypeValue] = useState<any>(null);
  const [taskGroupValue, setTaskGroupValue] = useState<any>();
  const [emailTemplateValue, setEmailTemplateValue] = useState<any>(null);
  const [responsibleRoleValue, setResponsibleValue] = useState<any>(null);
  const [SLABDValue, setSLABDValue] = useState<any>();
  const [taskName, setTaskName] = useState("");
  const [Sequence, setSequence] = useState("");
  const [SLADays, setSLADays] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [isMandatoryValue, setIsMandatoryValue] = useState(true);
  const [taskClosureValue, setTaskClosureValue] = useState(true);
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { userDetails, currentRoleId }: any = useUserContext();
  const { state } = useLocation();
  const [Id, setId] = useState((state !== null && state.Id) || 0);
  const [IsEditMode, setIsEditMode] = useState(false);
  
  useEffect(() => {
    GetDropdownValuesForCountry();
    GetDropdownValuesForTaskGroup();
    GetDropdownValuesForTaskType();
    GetDropdownValuesForLegalEntity();
    GetDropdownValuesForPayrollLocation();
    GetDropdownValuesForEmailTemplate();
    fetchModuleNames();
    fetchRoleDetails();
    if(Id === 0){
      setIsEditMode(false);
    } else{
      setIsEditMode(true);
      getTaskChecklistData();
    }
  }, []);


  const getTaskChecklistData = async () => {
    showLoader();
    const TaskChecklistDetails = await APICall(GetTaskChecklistData, //from dashbord id to update data
        "POST",
        {
            "Id": Id, 
        }
    );
    if(TaskChecklistDetails.data != null){
      setTaskName(TaskChecklistDetails.data[0].task);
      // setModuleNameDropdownValue(Number(TaskChecklistDetails.data[0].module));
      setResponsibleValue(Number(TaskChecklistDetails.data[0].responsibleRole));
      setEmailTemplateValue(Number(TaskChecklistDetails.data[0].emailTemplate) === 0 ? null : Number(TaskChecklistDetails.data[0].emailTemplate));
      setTaskGroupValue(Number(TaskChecklistDetails.data[0].taskGroup));
      setTaskTypeValue(Number(TaskChecklistDetails.data[0].taskType));
      setSLABDValue(Number(TaskChecklistDetails.data[0].slaBDate));
      setIsMandatoryValue(TaskChecklistDetails.data[0].isMandatory === 'True' ? true : false);
      setTaskClosureValue(TaskChecklistDetails.data[0].trackClosure === 'True' ? true : false);
      setIsActive(Boolean(TaskChecklistDetails.data[0].isTaskActive=== 'True' ? true : false));
      setSLADays(TaskChecklistDetails.data[0].slaDays);
      setSequence(TaskChecklistDetails.data[0].sequence);

    }
    
    const TaskChecklistCountryDetails = await APICall(GetTaskChecklistCountryData, //from dashbord id to update data
    "POST",
    {
      "TCId": Id, 
    }
    );
    
    if(TaskChecklistCountryDetails.data != null){
      setCountryValue(TaskChecklistCountryDetails.data);
    }

    const TaskChecklistLEDetails = await APICall(GetTaskChecklistLEData, //from dashbord id to update data
    "POST",
    {
      "TCId": Id, 
    }
    );
    
    if(TaskChecklistLEDetails.data != null){
      setLegalEntityValue(TaskChecklistLEDetails.data);
    }

    const TaskChecklistPLOCDetails = await APICall(GetTaskChecklistPLOCData, //from dashbord id to update data
    "POST",
    {
      "TCId": Id, 
    }
    );
    
    if(TaskChecklistPLOCDetails.data != null){
      setPLOCValue(TaskChecklistPLOCDetails.data);
    }
    hideLoader();
};

  //Api call for Module Name
  const fetchModuleNames = async () => {
    try {
      const response = await APICall(GetModuleDropdown, "POST", {
        
      });
      setModuleNameDropdownOptions(response.data);
      setModuleNameDropdownValue(response.data.find(x => x.code === 'OnBoarding').value);
    } catch (error) {
      console.error("Error fetching Module data:", error);
    }
  };

  //Api call for Roles
  const fetchRoleDetails = async () => {
    try {
      const response = await APICall(getAllRoles, "POST", {
        
      });
      setRolesDropdownOptions(response.data);
    } catch (error) {
      console.error("Error fetching Role data:", error);
    }
  };

  //Call api for all Dropdown
  const getDDData = async (AC, PID) => {
    const dropdowndataResponse = await APICall(getDropdowns, "POST", {
      AC,
      PID
    });
    const dropdownData = dropdowndataResponse.data;
    return dropdownData;
  };

  // country dropdown
  const GetDropdownValuesForCountry = async () => {
    let PID = null;
    let dropdownDataForCountry = await getDDData("GenMasterData", 'Country');
    setCountryDropdownOptions(dropdownDataForCountry);
  };

  const GetDropdownValuesForTaskGroup = async () => {
    let dropdownDataForTaskGroup = await getDDData("GenMasterData", 'TaskGroup');
    setTaskGroupDropdownOptions(dropdownDataForTaskGroup);
  };

  const GetDropdownValuesForTaskType = async () => {
    let dropdownDataForTaskType = await getDDData("GenMasterData", 'TaskType');
    setTaskTypeDropdownOptions(dropdownDataForTaskType);
  };

  const GetDropdownValuesForLegalEntity = async () => {
    let dropdownDataForLegalEntity = await getDDData("OrgMasterData", 'LegalEntity');
    setLegalEntityDropdownOptions(dropdownDataForLegalEntity);
  };

  const GetDropdownValuesForPayrollLocation = async () => {
    let dropdownDataForPayrollLocation = await getDDData("OrgMasterData", 'PayrollLocation');
    setPayrollLocationDropdownOptions(dropdownDataForPayrollLocation);
  };

  const GetDropdownValuesForEmailTemplate = async () => {
    let dropdownDataForEmailTemplate = await getDDData("EmailTemplateList", null);
    setEmailTemplateDropdownOptions(dropdownDataForEmailTemplate);
  };

  const OnSubmit = () => {
    if(moduleNameDropdownValue === null || moduleNameDropdownValue === undefined){
      notify(1, 'Module Name is Mandatory');
    } else if(responsibleRoleValue === null || responsibleRoleValue === undefined){
      notify(1, 'Responsible Role is Mandatory');
    } else if(taskTypeValue === null || taskTypeValue === undefined){
      notify(1, 'Task Type is Mandatory');
    } else if(countryValue === null || countryValue === undefined){  
      notify(1, 'Country is Mandatory');
    } else if (taskName === ''){
      notify(1, 'Task is Mandatory');
    }
    else if (Sequence === ''){
      notify(1, 'Sequence is Mandatory');
    }
     else if (LegalEntityValue === ''){
      notify(1, 'Legal Entity is Mandatory');
    } else if (PLOCValue === ''){
      notify(1, 'Payroll Location is Mandatory');
    } else{
      if(responsibleRoleValue && taskTypeValue && countryValue && taskName && LegalEntityValue && PLOCValue && Sequence){
        insertUpdateOUData(); 
      }
    }
  };

  const insertUpdateOUData = async () => {
    showLoader();

    const CheckIfTaskExistForModule = await APICall(CheckIfTaskExist,
      'POST',
      {
        ModuleId: moduleNameDropdownValue,
        TaskName: taskName
      });

      let isValidUpdation = false;
      let msg = '';

      if(Id === 0){
        msg = "Data Inserted Successfully";
        isValidUpdation = CheckIfTaskExistForModule.data.length > 0 ? false : true;
      } else{
        if(CheckIfTaskExistForModule.data.length > 0){
          if(CheckIfTaskExistForModule.data.some(x => x.task === taskName && x.id === Id)){
            isValidUpdation = true;
          } else{
            if(CheckIfTaskExistForModule.data.some(x => x.id = Id && x.task !== taskName)){
              if(CheckIfTaskExistForModule.data.some(x => x.task === taskName)){
                isValidUpdation = false;
              } else{
                isValidUpdation = true;
              }
            }
          }
        } else{   
          isValidUpdation = true;
        }
        msg = "Data Updated Successfully";
      }

      if(!isValidUpdation){
        notify(1, "This task already exist");
      } else{
        const InsertOrUpdateData = await APICall(InsertUpdateTaskChecklistData, //when we click add new to insert and update data
        "POST",
        {
          ModuleId: moduleNameDropdownValue,
          CountryId: countryValue?.map(s => s.value)?.join(','),
          PLOCId: PLOCValue?.map(s => s.value)?.join(','),
          LEId: LegalEntityValue?.map(s => s.value)?.join(','),
          Id: Id,
          CreatedBy: userDetails?.Id,
          IsActive: isActive === true ? 1 : 0,
          ResponsibleRole: responsibleRoleValue,
          isMandatory: isMandatoryValue === true ? 1 : 0,
          TrackClosure: taskClosureValue === true ? 1 : 0,
          TaskGroup: taskGroupValue,
          TaskType: taskTypeValue,
          TaskName: taskName,
          SLABDate: SLABDValue,
          EmailTemplateId: emailTemplateValue,
          SLADays: SLADays,
          Sequence: Number(Sequence),

        }
      );
      if(InsertOrUpdateData.message == "Ok"){
          notify(0, msg);
          navigate(-1);
      } else{
          notify(1, "Some error occurred. Please try again");
      }
      }
  
    hideLoader();
};


  const OnOptionChange = async (DDName, event) => {
    if (DDName === "Module") {
      setModuleNameDropdownValue((event && event.value) || null);
    } else if(DDName === 'Country'){
      setCountryValue(event);
    } else if(DDName === 'Task'){
      setTaskName(event.target.value);  
    } else if(DDName === 'TaskType'){
      setTaskTypeValue((event && event.value) || null);
    } else if(DDName === 'TaskGroup'){
      setTaskGroupValue((event && event.value) || null);
    } else if(DDName === 'ResponsibleRole'){
      setResponsibleValue((event && event.value) || null);
    } else if(DDName === 'SLABD'){
      setSLABDValue((event && event.value) || null);
    } else if(DDName === 'SLADays'){
      setSLADays(event.target.value);
    } else if(DDName === 'IsActive'){
      setIsActive(event.target.checked);
    } else if(DDName === 'MandatoryYes'){
      setIsMandatoryValue(true);
    } else if(DDName === 'MandatoryNo'){
      setIsMandatoryValue(false);
    } else if(DDName === 'TaskClosureYes'){
      setTaskClosureValue(true);
    } else if(DDName === 'TaskClosureNo'){
      setTaskClosureValue(false);
    } else if(DDName === 'EmailTemplate'){
      setEmailTemplateValue(event && event.value || null);
    } else if(DDName === 'LegalEntity'){
      setLegalEntityValue(event);
    } else if(DDName === 'PLOC'){
      setPLOCValue(event);
    }
    else if(DDName === 'Sequence'){
      setSequence(event.target.value);
    }
  };

  //Form reset
  const resetForm = () => {
    // setModuleNameDropdownValue(null);
    setResponsibleValue(null);
    setCountryValue(null);
    setLegalEntityValue(null);
    setPLOCValue(null);
    setEmailTemplateValue(null);
    setTaskGroupValue(null);
    setTaskTypeValue(null);
    setSLADays('');
    setSLABDValue(null)
    setTaskName('');
    setTaskClosureValue(true);
    setIsMandatoryValue(true);
    setSequence('');
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <div className="container-fluid">
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          style={{ background: "#3C5464" }}
          className="text-white acc_close"
          expandIcon={<ExpandMore />}
        >
          <Typography>Task Checklist</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="row">
            {/* Module Name */}
            {/* <div className="col-md-3">
              <div className="form-group">
                <label className="col-form-label" htmlFor="dropdown1">
                  Module<sup>*</sup>
                </label>
                <SelectForm
                  isClearable
                  id="dropdown1"
                  value={moduleNameDropdownValue ? [{'label': moduleNameDropdownOptions?.find(x => x.value === moduleNameDropdownValue)?.label,'value':moduleNameDropdownValue}] :[]}
                  placeholder="Select an option"
                  options={moduleNameDropdownOptions}
                  onChange={(e) => {
                    OnOptionChange("Module", e);
                  }}
                  isMulti={false}
                />
              </div>
            </div> */}

            <div className="col-md-3">
              <div className="form-group">
                <label className="col-form-label" htmlFor="dropdown3">
                  Task<sup>*</sup>
                </label>
                <InputForm
                                  className="form-control"
                                  placeholder="Task"
                                  isDisabled={false}  
                                  onChange={(e) => {
                                    OnOptionChange("Task", e);
                                  }}
                                  textArea={false}
                                  value={taskName}
                                  maxLength="255"
                        />
              </div>
            </div>

            
            <div className="col-md-3">
              <div className="form-group">
                <label className="col-form-label" htmlFor="dropdown2">
                  Country<sup>*</sup>
                </label>
                <SelectForm
                  isClearable
                  id="dropdown2"
                  value={countryValue}
                  placeholder="Select an option"
                  options={countryDropdownOptions}
                  onChange={(e) => {
                    OnOptionChange("Country", e);
                  }}
                  isMulti={true}
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="col-form-label" htmlFor="dropdown21">
                  Applicable Legal Entity<sup>*</sup>
                </label>
                <SelectForm
                  isClearable
                  id="dropdown21"
                  value={LegalEntityValue}
                  placeholder="Select an option"
                  options={legalEntityDropdownOptions}
                  onChange={(e) => {
                    OnOptionChange("LegalEntity", e);
                  }}
                  isMulti={true}
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="col-form-label" htmlFor="dropdown20">
                  Applicable Payroll Location<sup>*</sup>
                </label>
                <SelectForm
                  isClearable
                  id="dropdown20"
                  value={PLOCValue}
                  placeholder="Select an option"
                  options={payrollLocationDropdownOptions}
                  onChange={(e) => {
                    OnOptionChange("PLOC", e);
                  }}
                  isMulti={true}
                />
              </div>
            </div>
            
            <div className="col-md-3">
              <div className="form-group">
                <label className="col-form-label" htmlFor="dropdown4">
                  Task Type<sup>*</sup>
                </label>
                <SelectForm
                  isClearable
                  id="dropdown4"
                  value={taskTypeValue && taskTypeDropdownOptions?.some(x => x.value === taskTypeValue) ? [{'label': taskTypeDropdownOptions?.find(x => x.value === taskTypeValue)?.label,'value':taskTypeValue}] :[]}
                  placeholder="Select an option"
                  options={taskTypeDropdownOptions}
                  onChange={(e) => {
                    OnOptionChange("TaskType", e);
                  }}
                  isMulti={false}
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="col-form-label" htmlFor="dropdown5">
                  Task Group
                </label>
                <SelectForm
                  isClearable
                  id="dropdown5"
                  value={taskGroupValue && taskGroupDropdownOptions?.some(x => x.value === taskGroupValue) ? [{'label': taskGroupDropdownOptions?.find(x => x.value === taskGroupValue)?.label,'value':taskGroupValue}] :[]}
                  placeholder="Select an option"
                  options={taskGroupDropdownOptions}
                  onChange={(e) => {
                    OnOptionChange("TaskGroup", e);
                  }}
                  isMulti={false}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label className="col-form-label" htmlFor="dropdown3">
                  SequenceNo<sup>*</sup>
                </label>
                <InputForm
                                  className="form-control"
                                  placeholder="Sequence"
                                  type = "text"
                                  isDisabled={false}  
                                  onChange={(e) => {
                                    OnOptionChange("Sequence", e);
                                  }}
                                  textArea={false}
                                  value={Sequence}
                                  maxLength="255"
                        />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label className="col-form-label" htmlFor="dropdown10">
                  System Event / Template
                </label>
                <SelectForm
                  isClearable
                  id="dropdown10"
                  value={emailTemplateValue && emailTemplateDropdownOptions?.some(x => x.value === emailTemplateValue) ? [{'label': emailTemplateDropdownOptions?.find(x => x.value === emailTemplateValue)?.label,'value':emailTemplateValue}] :null}
                  placeholder="Select an option"
                  options={emailTemplateDropdownOptions}
                  onChange={(e) => {
                    OnOptionChange("EmailTemplate", e);
                  }}
                  isMulti={false}
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="col-form-label" htmlFor="dropdown6">
                  Responsible Role<sup>*</sup>
                </label>
                <SelectForm
                  isClearable
                  id="dropdown6"
                  value={responsibleRoleValue && rolesDropdownOptions?.some(x => x.value === responsibleRoleValue) ? [{'label': rolesDropdownOptions?.find(x => x.value === responsibleRoleValue)?.label,'value':responsibleRoleValue}] :[]}
                  placeholder="Select an option"
                  options={rolesDropdownOptions}
                  onChange={(e) => {
                    OnOptionChange("ResponsibleRole", e);
                  }}
                  isMulti={false}
                />
              </div>
            </div>

            <div className="col-md-3">
                <div className="form-group">
                    <div className="mb-1">
                        <label className="col-form-label">Mandatory</label>                                      
                        <div>
                          <input
                              disabled={false}
                              type="radio"
                              className="mr-1"
                              onChange={(e) => {
                                OnOptionChange("MandatoryYes", e);
                              }}
                              checked={isMandatoryValue}
                              name="mandatory"
                              value = {isMandatoryValue? 1:0}
                          />
                          <label>Yes</label>
                            
                          <input
                              disabled={false}
                              type="radio"
                              className="ml-2 mr-1"
                              value={""}
                              onChange={(e) => {
                                OnOptionChange("MandatoryNo", e);
                              }}
                              checked={!isMandatoryValue}
                              name="mandatory"
                          />                                            
                          <label>No</label>
                        </div>
                   </div>
                </div>
          </div>

            <div className="col-md-3">
                <div className="form-group">
                    <div className="mb-1">
                        <label className="col-form-label">Track Closure</label>                                      
                        <div>
                          <input
                              disabled={false}
                              type="radio"
                              className="mr-1"
                              onChange={(e) => {
                                OnOptionChange("TaskClosureYes", e);
                              }}
                              checked={taskClosureValue}
                              name="taskClosure"
                          />
                          <label>Yes</label>
                            
                          <input
                              disabled={false}
                              type="radio"
                              className="ml-2 mr-1"
                              value={""}
                              onChange={(e) => {
                                OnOptionChange("TaskClosureNo", e);
                              }}
                              checked={!taskClosureValue}
                              name="taskClosure"
                          />                                            
                          <label>No</label>
                        </div>
                   </div>
                </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="col-form-label" htmlFor="dropdown6">
                SLA (Days)
                </label>
                <InputForm
                                  className="form-control"
                                  placeholder="SLA (Days)"
                                  isDisabled={false}  
                                  onChange={(e) => {
                                    OnOptionChange("SLADays", e);
                                  }}
                                  textArea={false}
                                  value={SLADays}
                                  maxLength="255"
                        />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="col-form-label" htmlFor="dropdown6">
                SLA Baselinene Date
                </label>
                <SelectForm
                  isClearable
                  id="dropdown6"
                  value={SLABDValue && SLABDropdownOptions?.some(x => x.value === SLABDValue) ? [{'label': SLABDropdownOptions?.find(x => x.value === SLABDValue)?.label,'value':SLABDValue}] :[]}
                  placeholder="Select an option"
                  options={SLABDropdownOptions}
                  onChange={(e) => {
                    OnOptionChange("SLABD", e);
                  }}
                  isMulti={false}
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
              <label className="col-form-label">IsActive</label>
              <div>
                        <input
                                      disabled={IsEditMode ? false : true}
                                      type="checkbox"
                                      id="IsActive"
                                      onChange={(e) => {
                                        OnOptionChange("IsActive", e);
                                      }}
                                      checked={isActive}
                                    /> 
                    </div>
            </div>                               

            </div>

          </div>

          {/* Buttons */}
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-3"></div>
            <div className="col-md-3"></div>

            {/* Reset & Submit */}
            <div className="col-md-3">
              <button
                type="button"
                onClick={OnSubmit}
                className="btn btn-primary mr-2"
              >
                <i className="fa-solid fa-check fa mr-2"></i>
                Submit
              </button>
              <button
                type="button"
                onClick={resetForm}
                className="btn btn-primary"
              >
                <i className="fa fa-undo" aria-hidden="true"></i>
                Reset
              </button>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

    </div>
    
  );
};

export default TaskChecklist;
