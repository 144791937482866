import React, { useContext, useEffect, useState } from "react";
import { CustomAccordian } from "./CustomAccordtion";

import { GetCandidateDeclartionForm } from "../../../Helpers/APIEndPoints/EndPoints";
import { LoaderContext } from "../../../Helpers/Context/Context";
import useUserContext from "../../../Helpers/ZustandStore/UserContextStore";
import { Modal } from "react-bootstrap";
import { APICall } from "../../../Helpers/APICalls";
import InputForm from "../../../Components/InputForm/InputForm";
import notify from "../../../Helpers/ToastNotification";
import { Try } from "@mui/icons-material";

interface BaseEntity {
  id?: number;
  isActive?: boolean;
  createdDateUtc?: string;
  createdBy?: number;
  modifiedDateUtc?: string | null;
  modifiedBy?: number | null;
  proxyUserId?: number | null;
  userId?: number | null;
  roleId?: number | null;
}

interface DeclartionAnswer extends BaseEntity {
  requiredTextBox?: boolean;
  answerId?: number;
  correctDisredAnswer?: boolean;
  answer?: string;
  textLabelAnswers?: string | null;
  isSelected?: boolean;
}

interface DeclartionQuestion extends BaseEntity {
  question?: string;
  questionId?: string;
  textLabel?: string;
  questionType?: string;
  answers?: DeclartionAnswer[];
}

const DeclartionFormV2 = ({
  MRFId = 0,
  CandidateId = 0,
  isDisableApplication = false,
  source = "",
  isIJP = false,
  isScreeningSubmitted = false,
  isDeclartionFormSubmited,
  setDeclartionFormSubmited,
  setDeclartionFormData,
}) => {
  const [confirmCheckboxValue, setConfirmCheckboxValue] = useState(false);
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(isDisableApplication);
  const [errorMessage, setErrorMessage] = useState("");

  const { showLoader, hideLoader } = useContext(LoaderContext);
  let { userDetails, currentRoleId }: any = useUserContext();
  const [showDeclareModal, setShowDeclareModal] = useState(false);
  const [declartionData, setDeclartionData] = useState<DeclartionQuestion[]>(
    []
  );

  const [showTextBox, setShowTextBox] = useState(false);
  const [textBoxData, setTextBoxData] = useState<string>("");
  const [disableDeclartionForm, setDisableDeclartionForm] = useState(isDisableApplication ?? false);

  useEffect(() => {
    const fetchData = async () => {
      await fetchDeclartionQuestions();
    };
    fetchData();
  }, []);

  async function fetchDeclartionQuestions() {
    try {
      showLoader();

      const response = await APICall(GetCandidateDeclartionForm, "POST", {
        CandidateId: CandidateId ?? 0,
        MRFId: MRFId ?? 0,
      });

      if (response?.status == 0) {
        const data = response?.data || [];

        let errorObj = {};

        for (const question of data) {
          const isAnswered = question.answers?.some(
            (i) => i?.isSelected == true
          );
          if (isAnswered) {
            errorObj[question.questionId] = true;
          }
        }

        if (Object.keys(errorObj).length > 0) {
          setDisableDeclartionForm(true);
          setDisabledSubmitBtn(true);
          setDeclartionFormSubmited(true);
        }

        setDeclartionData(data);
        if (CandidateId > 0) {
          setDeclartionFormData(data);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  function handleChangeRadioButton(questionId, answerId) {
    try {
      setTextBoxData("");
      setDeclartionData((prev) => {
        let arr = prev?.map((item) => {
          if (item.questionId == questionId) {
            item.answers.map((radioObj) => {
              if (radioObj.answerId == answerId) {
                radioObj.isSelected = true;
                if (radioObj?.requiredTextBox) {
                  setShowTextBox(true);
                } else {
                  setShowTextBox(false);
                }
              } else {
                radioObj.isSelected = false;
                radioObj.textLabelAnswers = "";
              }

              if (
                item?.questionType === "ExceptionWarning" &&
                radioObj?.correctDisredAnswer != true &&
                radioObj.isSelected == true
              ) {
                notify(1, "Exception Warning");
              }

              return radioObj;
            });
          }
          return item;
        });
        return arr;
      });
    } catch (error) {
      console.error(error);
    }
  }

  function handleChangeTextChange(questionId, text) {
    try {
      setDeclartionData((prev) => {
        return prev.map((item) => {
          if (item.questionId == questionId) {
            item.answers.map((radioObj) => {
              if (radioObj.isSelected) {
                radioObj.textLabelAnswers = text;
              }
              return radioObj;
            });
          }
          return item;
        });
      });
    } catch (error) {
      console.error(error);
    }
  }

  function checkValidation() {
    try {
      let errorObj = {};

      for (const question of declartionData) {
        const isAnswered = question.answers?.some((i) => i?.isSelected == true);
        if (!isAnswered) {
          errorObj[question.questionId] = true;
        }
      }

      if (Object.keys(errorObj).length > 0 && !isIJP) {
        notify(1, "Answer all the question");
        return;
      }

      if (!confirmCheckboxValue) {
        setErrorMessage(
          "Please check the box to confirm the accuracy of your answers."
        );
        return;
      }
      setErrorMessage("");
      setShowDeclareModal(true);
    } catch (error) {
      console.error(error);
    }
  }

  async function handleSubmitDeclartionForm() {
    try {
      showLoader();
      notify(0, "Data saved successfully.");
      setShowDeclareModal(false);
      setDisabledSubmitBtn(true);
      setDisableDeclartionForm(true);
      setDeclartionFormSubmited(true);
      setDeclartionFormData(declartionData);
    } catch (error) {
    } finally {
      hideLoader();
    }
  }

  return (
    <>
      <CustomAccordian
        header={"Declaration"}
        disabled={undefined}
        submitCallback={undefined}
        clearFunc={undefined}
        hideBtn={true}
        defaultAccordianValue={true}
      >
        <>
          <div className="row">
            <>
              {!isIJP && (
                <>
                  {declartionData?.map((item, key) => {
                    return (
                      <>
                        <div className="col-lg-12 my-1" key={`question_${key}`}>
                          <div className="form-group">
                            <label className="form-label font-weight-bold">
                              {item?.question ?? ""}
                            </label>

                            {item?.answers?.map((radioObj, key_2) => (
                              <React.Fragment key={`radion_${key_2}`}>
                                <div className="form-check my-2 ml-4">
                                  <input
                                    name={item?.questionId}
                                    value={radioObj.answerId}
                                    className="form-check-input"
                                    type="radio"
                                    id={`${item.questionId}${key_2}`}
                                    checked={radioObj?.isSelected || false}
                                    onChange={(event) => {
                                      handleChangeRadioButton(
                                        item.questionId,
                                        radioObj.answerId
                                      );
                                    }}
                                    disabled={disableDeclartionForm}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`${item?.questionId}${key_2}`}
                                  >
                                    {radioObj?.answer ?? ""}
                                  </label>
                                </div>

                                {radioObj.requiredTextBox &&
                                  radioObj?.isSelected && (
                                    <>
                                      <div className="form-group col-lg-4">
                                        <label className="form-label ml-2 font-weight-bold">
                                          {item?.textLabel}
                                        </label>
                                        <InputForm
                                          className="form-control ml-1"
                                          placeholder={""}
                                          isDisabled={disableDeclartionForm}
                                          textArea={false}
                                          value={radioObj?.textLabelAnswers}
                                          name={item.questionId}
                                          onChange={(e) => {
                                            handleChangeTextChange(
                                              item.questionId,
                                              e.target.value
                                            );
                                          }}
                                          maxLength="255"
                                        />
                                      </div>
                                    </>
                                  )}
                              </React.Fragment>
                            ))}
                          </div>
                        </div>
                      </>
                    );
                  })}
                </>
              )}
            </>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      disableDeclartionForm || confirmCheckboxValue 
                    }
                    id="confirmCheckboxValue"
                    onChange={(e) => {
                      setConfirmCheckboxValue(e.target.checked);
                      setErrorMessage("");
                    }}
                    disabled={disableDeclartionForm }
                  />
                  <label
                    className="form-check-label"
                    htmlFor="confirmCheckboxValue"
                  >
                    {isIJP
                      ? "I have read & understood the WeRise guidelines & qualifying all the required parameters."
                      : `I confirm the answer provided above are correct and accurate
                    to the best of my knowledge.`}
                    <sup>*</sup>
                  </label>
                </div>
                <span style={{ color: "red" }} className="form-check-label">
                  {errorMessage}
                </span>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="float-right col-sm-offset-2">
                <button
                  type="button"
                  disabled={disabledSubmitBtn}
                  className="btn btn-create btn btn-primary"
                  onClick={(e) => {
                    checkValidation();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </>
      </CustomAccordian>

      <Modal
        show={showDeclareModal}
        onHide={() => setShowDeclareModal(false)}
        size="lg"
        backdrop="static"
        keyboard={false}
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">Confirm</div>
        </div>
        <Modal.Body>
          <div className="col-lg-6">
            <p>Do you want to submit?</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            disabled={disabledSubmitBtn}
            style={{ marginLeft: 5 }}
            onClick={() => {
              handleSubmitDeclartionForm();
              setShowDeclareModal(false);
            }}
          >
            Yes
          </button>
          <button
            className="btn btn-danger"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowDeclareModal(false);
            }}
          >
            No
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeclartionFormV2;
