import React, { useState, useEffect, useContext, useRef } from "react";
import { Modal } from "react-bootstrap";
import { LoaderContext } from "../../Helpers/Context/Context";
import "./FormStructure.css";
import ProfilePrint from "./ProfilePrint";
import PrintPreviewSectionComponent from "./PrintPreviewSectionComponent";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import moment from "moment";
function PrintPreviewModal({
  printpreviewModal,
  setPrintpreviewModal,
  printValueSubmit,
  setPrintValueSubmit,
  headerValues,
  profileImg,
  printPreviewValueForApiCall,
  allSectionsLoaded,
  setAllSectionsLoaded,
}) {
  const { userDetails, proxyUserDetails,currentRoleId }: any = useUserContext();
  const user = proxyUserDetails != null ? proxyUserDetails : userDetails;
  const { EmployeeCode, EmployeeId, FirstName, LastName, Id } = user || {};
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [showModal, setShowModal] = useState(false);
  const [printChangesAccordion, setPrintChangesAccordion] = useState(false)
  const [isDisabledButtonPrint, setIsDisableButtonPrint] = useState(true);
  const handleModalClose = () => {
    function customizedAsync(){
    setShowModal(false);
    setPrintpreviewModal(false);
    setPrintValueSubmit(null);
    setIsDisableButtonPrint(true);
    setAllSectionsLoaded(false);
    }
    showLoader();
      const timer = setTimeout(() => {
        hideLoader();
        customizedAsync();
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
  };
  function handlePrint(divName) {
    let printContents = document.getElementById(divName).innerHTML;
		let tempContainers = document.createElement('div');
    tempContainers.innerHTML = printContents;
    tempContainers.classList.add('print-container'); 
      document.title=`WelPro_EmployeeProfile_${EmployeeCode}${FirstName}${LastName}_${moment().format("DDMMYYYY_H:MM:SS_A")}`;
      document.body.appendChild(tempContainers);
      const bodyElements = document.body.children;
    for (let i = 0; i < bodyElements.length; i++) {
        if (bodyElements[i] !== tempContainers) {
            bodyElements[i].classList.add('hide-on-print');
        }
    }
			  window.print();
        for (let i = 0; i < bodyElements.length; i++) {
          if (bodyElements[i] !== tempContainers) {
              bodyElements[i].classList.remove('hide-on-print');
          }
      }
        tempContainers.remove();
        document.title='WelPro';
        handleModalClose();
};
  useEffect(() => {
    if (allSectionsLoaded) {
      showLoader();
      const timer = setTimeout(() => {
        hideLoader();
        setIsDisableButtonPrint(false);
      }, 20000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [allSectionsLoaded]);
  useEffect(() => {
    function customizedAsync(){
    if (printpreviewModal) {
      setShowModal(() => true);
    } else {
      setShowModal(() => false);
      setPrintChangesAccordion(() => false);
      setPrintValueSubmit(() => null);
    }
  }
  showLoader();
      const timer = setTimeout(() => {
        hideLoader();
        customizedAsync();
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
  }, [printpreviewModal]);
 
  return (
    <Modal
      className="customized-print-preview-modal"
      show={showModal}
      onHide={handleModalClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <div
        className="bg-secondary text-white"
        style={{
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        <div className="row">
          <div className="col-lg-6"> Do you want to Print</div>
          <div className="col-lg-6 text-end">
            {!allSectionsLoaded ? " This might take some time" : ""}
          </div>
        </div>
      </div>
      <Modal.Body>
        <div id="printableContents">
          <ProfilePrint headerValues={headerValues} profileImg={profileImg} />
          <PrintPreviewSectionComponent
            printPreviewValueForApiCall={printPreviewValueForApiCall}
            printValueSubmit={printValueSubmit}
            printChangesAccordion={printChangesAccordion}
            allSectionsLoaded={allSectionsLoaded}
            setAllSectionsLoaded={setAllSectionsLoaded}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary ml-3"
          onClick={() => {
            handleModalClose();
          }}
        >
          Close
        </button>
        {allSectionsLoaded && (
          <button
            type="button"
            disabled={isDisabledButtonPrint}
            className="btn btn-primary ml-3"
            onClick={()=>handlePrint('printableContents')}
          >
            Print
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
export default PrintPreviewModal;
