import moment from "moment";

export function validateScheduleDateTime(startDateTime, endDateTime) {
  // Parse the input strings into Moment objects
  const startMoment = moment(startDateTime);
  const endMoment = moment(endDateTime);

  // Check if both inputs are valid Moment objects
  if (!startMoment.isValid() || !endMoment.isValid()) {
    return false; // One or both inputs are invalid
  }

  if (
    moment(startDateTime).isBefore(new Date()) ||
    moment(endDateTime).isBefore(new Date())
  ) {
    return false;
  }

  // Check if the startDateTime is greater than the endDateTime
  if (startMoment.isAfter(endMoment)) {
    return false; // Invalid: startDateTime is after endDateTime
  }

  // Check if both dates are the same
  if (!startMoment.isSame(endMoment, "day")) {
    return false; // Invalid: Dates are not the same
  }

  // If all validations pass, return true
  return true;
}

export interface IDasboardColumns {
  Id?: number;
  Stage?: number;
  ActivityName?: string;
  ModeName?: string;
  ScheduledByName?: string;
  ScheduledDate?: string;
  DecisionName?: string;
  DecisionSystemName?: string;
  StaticStagesSystemName?: string;
  ResponsiblePersonNames?: string;
  MinimumEvaluators?: string;
  DecisionOptions?: string;
  FeedbackOptions?: string;
  CandidateName?: string;
  CurrentStage?: string;
  FeedbackUpload?: string;
  ResponsiblePersonIds?: string;
  Feeback?: string;
  Remarks?: string;
  CurrencyId?: number;
  CurrentCTC?: number;
  CTCExpectedbyCandidate?: number;
  OfferedCTC?: number;
  FixedPay?: number;
  VariablePay?: number;
  NoticePay?: number;
  RelocationAllowance: number;
  PercentageHike?: number;
  noticePeriodDays?: number;
  NoticePeriodDays?: number;
  DateofJoining?: string;
  OfferAcceptanceDueDate?: string;
  EmployeeClassId?: number;
  JobBandId?: number;
  JobLevelId?: number;
  DesignationId?: number;
  ClientFacingTitle: string;
  Evaluators?: string;
  Curreny?: any;
  EmployeeClass?: any;
  JobBand?: any;
  JobLevel?: any;
  Designation?: any;
  OutComeOptions?: any;
  DecisionId?: number;
  ActivityStatusId?: number;
  ActivityStatusName: string;
  ActivityStatusSystemName?: string;
  ActivityStatusOptions?: string;
  CandidateFeedBackOptions?: any;
  CandidateFeedbackId?: number;
  PendingUserIdForCurrentStage?: string;
  RecruiterIds?: string;
  ResponsiblePersonNameJSON?: string;
  PendingUserIdForCurrentStageEvaluation?: string;
  ScheduledEndDateandTime?: string;
  CalendarBlocking?: number;
  Guests?: string;
  MeetingDescription?: string;
  MeetingSubject?: string;
  MeetingId?: string;
}

export enum StagesName {
  BackgroungVerification = "BGVERFN",
  RefrenceCheck = "REFCHK",
  PsychometricTest = "PSYMTRICRPT",
}

export enum CandidateWorkflowStages {
  Recommendation = 2,
  RecommedationReview = 3,
  FirstInterview = 4,
  SecondInterview = 5,
  FinalInterivew = 6,
  SalaryNegotiation = 7,
  Negotiation = 7.1,
  BackgroundVerification = 7.2,
  ReferenceCheck = 7.3,
  PsychometricTest = 7.4,
  OfferApproval = 8,
  OfferLetterReview = 9,
  OfferAcceptanceCandidate = 10,
  InitiateOnBoarding = 11,
  Joining = 12,
}

export interface ICandidateDetailsOfferLetterInfo {
  fileName?: string;
  base64?: string;
  showDocument?: boolean;
  isPdf?: boolean;
}

export enum CandidateWorkflowDecisions {
  Pending = "pending",
  InProgress = "inprogress",
  Rescheduled = "rescheduled",
  Initiated = "initiated",
  PendingSchedule = "pendingschedule",
}

export const CALENDAR_BLOCKING_OPTIONS = [
  { label: "Not Required", value: 1 },
  { label: "Scheduled Meeting", value: 2 },
  { label: "Meeting Invitation (MS Teams)", value: 3 },
];
