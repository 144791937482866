import React, { useContext, useEffect, useRef, useState } from "react";
import "./ContentViewer.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  SvgIcon,
  Tooltip,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { MultipleStop } from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LoaderContext } from "../../Helpers/Context/Context";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import {
  GetConfigDataCallForScromProject,
  GetSessionContent,
  GetThumbnailDetails,
  contentCompletionStatus,
  generateUserCertificate,
  getContentCompletionStatus,
  getContentUrlDetails,
  getCourseData,
  getCourseScheduleDetails,
  getUserCertificateFile,
  isAssessmentPending,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import { APICall, Encrypt } from "../../Helpers/APICalls";
import moment from "moment";
import defaultProfileImgPic from "../../Assets/Images/thumbnail.jpg";
import { Badge, Collapse, Modal, ProgressBar, Table } from "react-bootstrap";
import PdfViewer from "../../Components/PdfViewer/PdfViewer";
import ReactPlayer from "react-player";
import notify from "../../Helpers/ToastNotification";
import fileDownload from "js-file-download";
import { base64ToArrayBuffer } from "../../Helpers/Common";
import LMSDynamicGrid from "../../Components/LMSDynamicGrid/LMSDynamicGrid";
import axios from "axios";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";

const contentObj = {
  Scrome: "SCROME",
  Audio: "AUDIO",
  Pdf: "PDF",
  Link: "LINK",
  VideoLink: "VIDEOLINK",
  MP4: "MP4",
};

const ContentGrid = ({
  contents,
  currentSession,
  viewerData,
  profileImg,
  viewScromProgressHistory,
}) => {
  const { state } = useLocation();

  const [contentGridData, setContentGridData] = useState<any>(contents);

  const contentColumns = [
    {
      name: "thumbnailUrl",
      label: "Thumbnail",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={{ maxWidth: "100px" }} className="card">
              <img
                src={value != null ? value : defaultProfileImgPic}
                className="card-img-top"
                alt="Thumbnail Image"
              />
            </div>
          );
        },
      },
    },
    {
      name: "contentTitle",
      label: "Title",
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: "contentType",
      label: "Type",
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: "progressPercentage",
      label: "Content Status",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <ProgressBar
              now={value}
              variant={
                value <= 30
                  ? "danger"
                  : value > 30 && value <= 90
                  ? "warning"
                  : value > 90
                  ? "success"
                  : ""
              }
              label={`${value}%`}
              className={`mt-1`}
            />
          );
        },
      },
    },

    {
      name: "",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const contentUrl =
            tableMeta.tableData[tableMeta.rowIndex]?.contentUrl;
          const contentTypeCode =
            tableMeta.tableData[tableMeta.rowIndex]?.contentTypeCode;
          const contentId = tableMeta.tableData[tableMeta.rowIndex]?.id;
          const scromCourseId =
            tableMeta.tableData[tableMeta.rowIndex]?.scromCourseId;

          const sessionScheduleId = currentSession?.sessionScheduleId;

          return (
            <>
              {state && state.launchId === 0 ? (
                // admin view only
                <>
                  <Tooltip title="view">
                    <a
                      className="mr-2"
                      style={{ fontSize: "15px" }}
                      onClick={(e) => {
                        e.preventDefault();

                        // props?.startFromTenSeconds();
                        viewerData(
                          contentUrl,
                          contentTypeCode,
                          true,
                          profileImg,
                          sessionScheduleId,
                          contentId,
                          scromCourseId
                        );
                      }}
                    >
                      <i className="fas fa-eye" style={{ color: "black" }}></i>
                    </a>
                  </Tooltip>

                  {contentTypeCode === contentObj.Scrome && (
                    <Tooltip title="View Scrom Progress History">
                      <a
                        className="mr-2"
                        style={{ fontSize: "15px" }}
                        onClick={(e) => {
                          e.preventDefault();

                          viewScromProgressHistory(
                            scromCourseId,
                            sessionScheduleId
                          );
                        }}
                      >
                        <i
                          className="fas fa-history"
                          style={{ color: "black" }}
                        ></i>
                      </a>
                    </Tooltip>
                  )}
                </>
              ) : (
                <>
                  {state && state.isHold ? (
                    <Tooltip title="Course is on hold">
                      <a
                        className="mr-2"
                        style={{ fontSize: "15px" }}
                        onClick={(e) => {}}
                      >
                        <i
                          className="fas fa-eye"
                          style={{ color: "black" }}
                        ></i>
                      </a>
                    </Tooltip>
                  ) : currentSession?.canView ? (
                    <>
                      <Tooltip title="view">
                        <a
                          className="mr-2"
                          style={{ fontSize: "15px" }}
                          onClick={(e) => {
                            e.preventDefault();

                            viewerData(
                              contentUrl,
                              contentTypeCode,
                              true,
                              profileImg,
                              sessionScheduleId,
                              contentId,
                              scromCourseId
                            );
                          }}
                        >
                          <i
                            className="fas fa-eye"
                            style={{ color: "black" }}
                          ></i>
                        </a>
                      </Tooltip>

                      {contentTypeCode === contentObj.Scrome && (
                        <Tooltip title="View Scrom Progress History">
                          <a
                            className="mr-2"
                            style={{ fontSize: "15px" }}
                            onClick={(e) => {
                              e.preventDefault();

                              viewScromProgressHistory(
                                scromCourseId,
                                sessionScheduleId
                              );
                            }}
                          >
                            <i
                              className="fas fa-history"
                              style={{ color: "black" }}
                            ></i>
                          </a>
                        </Tooltip>
                      )}
                    </>
                  ) : (
                    <Tooltip title="Session not started yet">
                      <a
                        className="mr-2"
                        style={{ fontSize: "15px" }}
                        onClick={(e) => {}}
                      >
                        <i
                          className="fas fa-eye"
                          style={{ color: "black" }}
                        ></i>
                      </a>
                    </Tooltip>
                  )}
                </>
              )}
            </>
          );
        },
      },
    },
  ];

  return <LMSDynamicGrid data={contentGridData} columns={contentColumns} />;
};

const SessionAccordion = (props) => {
  const [accordion, setAccordion] = useState(false);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { state } = useLocation();

  const [profileImg, setProfileImg] = useState(null);

  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();

  const [contents, setContents] = useState([]);

  const getContent = async (sessionId) => {
    showLoader();
    const res = await APICall(GetSessionContent, "POST", {
      SessionId: sessionId,
      Mode: "LISTCONTENTADDED",
      UserId: userDetails?.Id,
      LaunchId: state.launchId,
      SessionScheduleId: props?.currentSession?.sessionScheduleId,
    });

    if (res.status === 0 && res.data.length > 0) {
      const contentData = res.data;

      for (let index = 0; index < contentData.length; index++) {
        const content = contentData[index];

        content.thumbnailUrl =
          content.thumbnailUrl !== null
            ? await getThumbnail(content.thumbnailUrl)
            : null;
        if (content?.contentTypeCode === contentObj?.Scrome) {
          if (content?.contentUrl) {
            // const contentUrl = content?.contentUrl.split(".");
            // const contentData = `${contentUrl[0]}/stiry.html`;

            content.contentUrl = content?.contentUrl;
          }
        } else if (
          content?.contentTypeCode === contentObj?.Pdf ||
          content?.contentTypeCode === contentObj?.Audio
          // ||content?.contentTypeCode === contentObj?.MP4
        ) {
          if (content?.contentUrl) {
            const getContentUrl = await APICall(getContentUrlDetails, "POST", {
              ContentUrl: content?.contentUrl,
            });

            const data = getContentUrl?.data?.contentUrlDataObject;
            const name = getContentUrl?.data?.fileName;

            if (data) {
              // let fileBase64: { Name: string; Data: string } = null;
              // fileBase64 = { Name: name, Data: data };

              content.contentUrl = data;
            }
          }
        } else if (
          content?.contentTypeCode === contentObj?.Link ||
          content?.contentTypeCode === contentObj?.VideoLink ||
          content?.contentTypeCode === contentObj?.MP4
        ) {
        }
      }

      setContents(contentData);
    }

    hideLoader();
  };

  const getThumbnail = async (thumbnailUrl) => {
    const res = await APICall(GetThumbnailDetails, "POST", {
      ThumbnailUrl: thumbnailUrl,
    });

    if (res.status === 0) {
      return `data:image;base64,${res.data.thumbnailDataObject}`;
    }
  };

  useEffect(() => {
    if (accordion) {
      getContent(props.currentSession.sessionId);

      if (props.currentSession.thumbnailUrl) {
        (async () => {
          const res = await getThumbnail(props.currentSession.thumbnailUrl);

          setProfileImg(res);
        })();
      }
    }
  }, [accordion]);

  const [currentSessionGridData, setCurrentSessionGridData] = useState([]);

  const sessionColumns = [
    {
      name: "sessionId",
      label: "Session ID",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "sessionTitle",
      label: "Session Title",
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: "sessionType",
      label: "Session Type",
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: "sessionStatus",
      label: "Session Status",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Badge
              style={{
                padding: "8px 14px",
              }}
              pill
              text="white"
              bg={
                value === "0%"
                  ? "danger"
                  : value === "100%"
                  ? "success"
                  : "warning"
              }
            >
              {value}
            </Badge>
          );
        },
      },
    },

    {
      name: "duration",
      label: "Duration",
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: "sessionObjective",
      label: "Session Objective",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <span
              dangerouslySetInnerHTML={{
                __html: value,
              }}
            ></span>
          );
        },
      },
    },

    {
      name: "attendanceStatus",
      label: "Attendance Status",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Badge
              style={{
                padding: "8px 14px",
              }}
              pill
              text="white"
              bg={value === "Present" ? "success" : "danger"}
            >
              {value}
            </Badge>
          );
        },
      },
    },
  ];

  useEffect(() => {
    if (props.currentSession) {
      const gridData = [
        {
          sessionId: props.currentSession.sessionCode,
          sessionTitle: props.currentSession.sessionTitle,
          sessionType: props.currentSession.sessionType,
          sessionStatus: `${props.currentSession?.progressPercentage}%`,
          duration: `${
            props.currentSession.totalDurationHours ?? "00"
          }  hrs : ${props.currentSession.totalDurationMinutes ?? "00"} min`,
          sessionObjective: props.currentSession.sessionObjective,
          attendanceStatus: props?.currentSession?.sessionAttendance,
        },
      ];

      console.log("gridData500", gridData);

      setCurrentSessionGridData(gridData);
    }
  }, [props.currentSession]);

  return (
    <Accordion
      elevation={0}
      className="mb-3"
      expanded={accordion}
      onChange={() => setAccordion((prev) => !prev)}
    >
      <AccordionSummary
        id="sessionName"
        style={{ background: "#3C5464" }}
        className="text-white acc_close"
        expandIcon={<ExpandMore />}
      >
        <div className="row d-flex align-items-center acc-heading">
          <div className="col-lg-10 col-md-10 col-sm-12">
            <p style={{ fontSize: "16px" }}>
              {props?.currentSession?.sessionTitle}{" "}
              {/* -{" "}
              {props?.currentSession?.sessionCode} */}
              {!props?.resize && (
                <>
                  -{" "}
                  {/* {moment(props?.currentSession?.sessionStartDate).format(
                    "DD-MMM-YYYY hh:mm"
                  )}{" "} */}
                  {
                    // moment(props?.currentSession?.sessionStartDate).format(
                    //   "DD-MMM-YYYY hh:mm"
                    // )
                    props?.currentSession?.newSessionStartDate
                  }
                  -{" "}
                  {/* {moment(props?.currentSession?.sessionEndDate).year() === 9999
                    ? "N/A"
                    : moment(props?.currentSession?.sessionEndDate).format(
                        "DD-MMM-YYYY hh:mm"
                      )} */}
                  {moment(props?.currentSession?.sessionEndDate).year() === 9999
                    ? "N/A"
                    : // moment(props?.currentSession?.sessionEndDate).format(
                      //     "DD-MMM-YYYY hh:mm"
                      //   )
                      props?.currentSession?.newSessionEndDate}
                </>
              )}
            </p>
          </div>
          <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
        </div>
      </AccordionSummary>

      <AccordionDetails className="page_heading">
        <div className="row d-flex align-items-center">
          <div className="col-lg-4">
            <div className="card main_card">
              <img
                src={profileImg != null ? profileImg : defaultProfileImgPic}
              ></img>
            </div>
          </div>
          <div className="col-lg-8">
            <b style={{ fontSize: "16px", color: "#2C2B7C" }}>
              {props?.currentSession?.sessionTitle}{" "}
              {!props?.resize && (
                <>
                  - {props?.currentSession?.newSessionStartDate}-{" "}
                  {moment(props?.currentSession?.sessionEndDate).year() === 9999
                    ? "N/A"
                    : props?.currentSession?.newSessionEndDate}
                </>
              )}
            </b>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-lg-12 p-0">
            {/* <div className={props?.resize ? "w-100" : "w-50"}>
                <p>
                  <span>Session ID : </span>
                  {props.currentSession.sessionCode}
                </p>
              </div>
              <div className={props?.resize ? "w-100" : "w-50"}>
                <p>
                  <span>Session Title : </span>
                  {props.currentSession.sessionTitle}
                </p>
              </div>
              <div className={props?.resize ? "w-100" : "w-50"}>
                <p>
                  <span>Session Type : </span>
                  {props.currentSession.sessionType}
                </p>
              </div>
              <div className={props?.resize ? "w-100" : "w-50"}>
                <p>
                  <span>Session Status : </span>
                  {`${props.currentSession?.progressPercentage}%`}
                </p>
              </div>
              <div className={props?.resize ? "w-100" : "w-50"}>
                <p>
                  <span>Duration : </span>
                  {props.currentSession.totalDurationHours} hrs :{" "}
                  {props.currentSession.totalDurationMinutes ?? "00"} min
                </p>
              </div>
              <div className={props?.resize ? "w-100 desr" : "w-100 desr"}>
                <p>
                  <span className="wid_obj">Session Objective : </span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: props.currentSession.sessionObjective,
                    }}></span>
                </p>
              </div>

              <div className={props?.resize ? "w-100" : "w-50"}>
                <p>
                  <span>Attendance Status : </span>
                  {props?.currentSession?.sessionAttendance}
                </p>
              </div> */}
            <LMSDynamicGrid
              data={currentSessionGridData}
              columns={sessionColumns}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            {contents.length > 0 ? (
              <>
                <p className="mt-3 mb-3" style={{ color: "#2C2B7C" }}>
                  <b
                    className="rounded px-3 py-2"
                    style={{ backgroundColor: "#e7e7e7" }}
                  >
                    Content
                  </b>
                </p>
                {/* <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th className="text-dark">Thumbnail</th>
                      <th className="text-dark">Title</th>
                      <th className="text-dark">Type</th>
                      <th className="text-dark">Content Status</th>
                      <th className="text-dark">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contents.map((content) => (
                      <tr>
                        <td>
                          <div style={{ maxWidth: "100px" }} className="card">
                            <img
                              src={
                                content?.thumbnailUrl != null
                                  ? content?.thumbnailUrl
                                  : defaultProfileImgPic
                              }
                              className="card-img-top"
                              alt="Thumbnail Image"
                            />
                          </div>
                        </td>
                        <td>{content?.contentTitle}</td>
                        <td>{content?.contentType}</td>
                        <td>
                          {
                            <ProgressBar
                              now={content?.progressPercentage}
                              variant={
                                content?.progressPercentage <= 30
                                  ? "danger"
                                  : content?.progressPercentage > 30 &&
                                    content?.progressPercentage <= 90
                                  ? "warning"
                                  : content?.progressPercentage > 90
                                  ? "success"
                                  : ""
                              }
                              label={`${content?.progressPercentage}%`}
                              className={`mt-1`}
                            />
                          }
                        </td>
                        <td>
                          {state && state.launchId === 0 ? (
                            // admin view only
                            <>
                              <Tooltip title="view">
                                <a
                                  className="mr-2"
                                  style={{ fontSize: "15px" }}
                                  onClick={(e) => {
                                    const contentUrl = content?.contentUrl;
                                    const contentTypeCode =
                                      content?.contentTypeCode;
                                    e.preventDefault();

                                    const sessionScheduleId =
                                      props?.currentSession?.sessionScheduleId;
                                    const contentId = content?.id;

                                    props.viewerData(
                                      contentUrl,
                                      contentTypeCode,
                                      true,
                                      profileImg,
                                      sessionScheduleId,
                                      contentId
                                    );
                                  }}>
                                  <i
                                    className="fas fa-eye"
                                    style={{ color: "black" }}></i>
                                </a>
                              </Tooltip>
                            </>
                          ) : (
                            <>
                              {state && state.isHold ? (
                                <Tooltip title="Course is on hold">
                                  <a
                                    className="mr-2"
                                    style={{ fontSize: "15px" }}
                                    onClick={(e) => {}}>
                                    <i
                                      className="fas fa-eye"
                                      style={{ color: "black" }}></i>
                                  </a>
                                </Tooltip>
                              ) : props?.currentSession?.canView ? (
                                <Tooltip title="view">
                                  <a
                                    className="mr-2"
                                    style={{ fontSize: "15px" }}
                                    onClick={(e) => {
                                      const contentUrl = content?.contentUrl;
                                      const contentTypeCode =
                                        content?.contentTypeCode;
                                      e.preventDefault();

                                      const sessionScheduleId =
                                        props?.currentSession
                                          ?.sessionScheduleId;
                                      const contentId = content?.id;

                                      // props?.startFromTenSeconds();
                                      props.viewerData(
                                        contentUrl,
                                        contentTypeCode,
                                        true,
                                        profileImg,
                                        sessionScheduleId,
                                        contentId
                                      );
                                    }}>
                                    <i
                                      className="fas fa-eye"
                                      style={{ color: "black" }}></i>
                                  </a>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Session not started yet">
                                  <a
                                    className="mr-2"
                                    style={{ fontSize: "15px" }}
                                    onClick={(e) => {}}>
                                    <i
                                      className="fas fa-eye"
                                      style={{ color: "black" }}></i>
                                  </a>
                                </Tooltip>
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table> */}
                <div className="row">
                  <div className="col-lg-12 p-0">
                    <ContentGrid
                      contents={contents}
                      currentSession={props.currentSession}
                      viewerData={props.viewerData}
                      profileImg={profileImg}
                      viewScromProgressHistory={props.viewScromProgressHistory}
                    />
                  </div>
                </div>
              </>
            ) : props.currentSession.sessionType.toLowerCase() ===
              "classroom" ? (
              <h4>You have successfully Enrolled for Classroom sessions.</h4>
            ) : (
              <h4>No content available</h4>
            )}

            {/* <p className="mt-3 mb-2">Content</p>
            <table className="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th className="text-dark">Thumbnail</th>
                  <th className="text-dark">Title</th>
                  <th className="text-dark">Type</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="card">
                      <img
                        src="/static/media/thumbnail.e83e00e04a871eaf1317.jpg"
                        className="card-img-top"
                        alt="Thumbnail Image"
                      />
                    </div>
                  </td>
                  <td>Test title</td>
                  <td>Audio</td>
                </tr>
              </tbody>
            </table> */}
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const ContentViewer = () => {
  const [resize, setResize] = useState(false);

  const navigate = useNavigate();

  const [accordion, setAccordion] = useState(true);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { state } = useLocation();

  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();

  const [courseId, setCourseId] = useState(0);

  const [courseData, setCourseData] = useState<any>(null);

  const [refresh, setRefresh] = useState(false);

  const [sessionsArr, setSessionsArr] = useState([]);

  const [contentName, setContentName] = useState("defaultImage");
  const contentNameRef = useRef("defaultImage");

  const [contentUrl, setContentUrl] = useState("");

  const [profileImg, setProfileImg] = useState("");

  const [sessionScheduleId, setSessionScheduleId] = useState(0);

  const sessionScheduleIdRef = useRef(0);

  const [contentId, setContentId] = useState(0);

  const contentIdRef = useRef(0);

  const [trackPdfData, setTrackPdfData] = useState(null);

  const [currentProgressDuration, setCurrentProgressDuration] = useState(0);

  const currentProgressDurationRef = useRef(0);

  const [isCourseCompleted, setIsCourseCompleted] = useState(false);
  const isCourseCompletedRef = useRef(false);

  const [totalDuration, setTotalDuration] = useState<any>(0);

  const totalDurationRef = useRef<any>(0);

  const [progressDuration, setProgressDuration] = useState<any>(0);

  const progressDurationRef = useRef<any>(0);

  const [isZoomIn, setIsZoomIn] = useState(false);
  const [isZoomOut, setIsZoomOut] = useState(true);

  const [SCROMUserId, setSCROMUserId] = useState(null);
  const SCROMUserIdRef = useRef<any>("");

  const [SCROMPassword, setSCROMPassword] = useState(null);
  const SCROMPasswordRef = useRef<any>("");

  const [SCROMAPIUrl, setSCROMAPIUrl] = useState(null);
  const SCROMAPIUrlRef = useRef<any>("");

  const [showScromModal, setShowScromModal] = useState(false);

  const [scromCourseId, setScromCourseId] = useState(null);
  const scromCourseIdRef = useRef(0);

  const [showScromProgressHistoryModel, setShowScromProgressHistoryModel] =
    useState(false);

  const [scromProgressHistoryData, setScromProgressHistoryData] = useState([]);

  const [scromProgressHistoryCount, setScromProgressHistoryCount] = useState(0);

  useEffect(() => {
    (async () => {
      await GetConfigDataCallForScromProjectApiCall();
    })();
  }, []);

  const GetConfigDataCallForScromProjectApiCall = async () => {
    try {
      showLoader();
      const response = await APICall(GetConfigDataCallForScromProject, "POST", {
        UserId: userDetails?.Id,
        RoleID: currentRoleId?.value,
      });

      if (response.status === 0) {
        setSCROMUserId(response?.data?.scromUserId);
        SCROMUserIdRef.current = response?.data?.scromUserId;

        setSCROMPassword(response?.data?.scromPassword);
        SCROMPasswordRef.current = response?.data?.scromPassword;

        setSCROMAPIUrl(response?.data?.scromapiUrl);
        SCROMAPIUrlRef.current = response?.data?.scromapiUrl;
      } else {
        hideLoader();
      }
    } catch (error) {
      console.error("Error :", error);
    } finally {
      hideLoader();
    }
  };

  const viewerData = (
    contentUrl,
    contentName,
    resize,
    profileImg,
    sessionScheduleId,
    contentId,
    scromCourseId
  ) => {
    if (contentName === contentObj.Scrome) {
      setContentName(contentName);
      contentNameRef.current = contentName;

      setSessionScheduleId(sessionScheduleId);
      sessionScheduleIdRef.current = sessionScheduleId;

      setContentId(contentId);
      contentIdRef.current = contentId;

      setScromCourseId(scromCourseId);
      scromCourseIdRef.current = scromCourseId;

      setProfileImg(profileImg);

      OpenScrominNewTab(scromCourseId, sessionScheduleId);

      // setResize(resize);

      // if (isZoomIn) {
      //   setIsZoomIn(false);
      //   setIsZoomOut(true);
      // } else {
      //   setIsZoomIn(true);
      //   setIsZoomOut(false);
      // }
    } else {
      getContentCompletionStatusApiCall(contentId, sessionScheduleId);

      setContentName(contentName);
      contentNameRef.current = contentName;

      setContentUrl(contentUrl);

      setResize(resize);

      if (isZoomIn) {
        setIsZoomIn(false);
        setIsZoomOut(true);
      } else {
        setIsZoomIn(true);
        setIsZoomOut(false);
      }

      setProfileImg(profileImg);

      setSessionScheduleId(sessionScheduleId);
      sessionScheduleIdRef.current = sessionScheduleId;

      setContentId(contentId);
      contentIdRef.current = contentId;
    }
  };

  const getJWTokenApiCall = async () => {
    try {
      let headersList = {
        Accept: "*/*",
        // "User-Agent": "Thunder Client (https://www.thunderclient.com)",
        "Content-Type": "application/json",
      };

      let bodyContent = JSON.stringify({
        Name: SCROMUserIdRef.current,
        password: SCROMPasswordRef.current,
      });

      console.log("SCROMUserId 101", SCROMUserIdRef.current);
      console.log("SCROMPassword 102", SCROMPasswordRef.current);

      let reqOptions = {
        url: `${SCROMAPIUrlRef.current}/APILogin/GenerateJWToken`,
        method: "POST",
        headers: headersList,
        data: bodyContent,
      };

      let response = await axios.request(reqOptions);

      let { Data, Status, Message } = response.data;
      if (Status === 0 && (Data?.Token !== null || Data?.Token !== "")) {
        return Data?.Token;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error : ", error);
      return null;
    }
  };

  const OpenScrominNewTab = async (scromCourseId, sessionScheduleId) => {
    try {
      showLoader();
      const userAuthToken = await getJWTokenApiCall();

      if (userAuthToken === null) {
        // notify(1, "AuthToken is null");
        console.log("AuthToken is null");
      } else {
        try {
          let formData = new FormData();

          formData.append("ScromCourseId", scromCourseId);
          formData.append("CourseScheduleId", sessionScheduleId);
          formData.append(
            "EmailId",
            proxyUserDetails === null
              ? userDetails?.EmailId
              : proxyUserDetails?.EmailId
          );
          formData.append("UserId", userDetails?.Id);
          formData.append(
            "EmployeeCode",
            proxyUserDetails === null
              ? userDetails?.EmployeeCode
              : proxyUserDetails?.EmployeeId
          );

          let headersList = {
            Accept: "*/*",
            // "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            Authorization: "Bearer " + userAuthToken,
          };

          let bodyContent = formData;

          let reqOptions = {
            url: `${SCROMAPIUrlRef.current}/UserMaster/AddCourseToUserAndGetUrl`,
            method: "POST",
            headers: headersList,
            data: bodyContent,
          };

          let response = await axios.request(reqOptions);

          let { Data, Status, Message } = response.data;
          if (Status === 0) {
            const url = Data?.Url;

            setShowScromModal(true);
            setContentUrl(url);

            // window.open(url, "_blank");
          } else {
            notify(1, Message);
          }
        } catch (error) {
          console.error("Error : ", error);
        } finally {
          hideLoader();
        }
      }
    } catch (error) {
      console.error("Error : ", error);
    } finally {
      hideLoader();
    }
  };

  const getSessionDataApiCall = async (scromCourseId, sessionScheduleId) => {
    try {
      showLoader();
      const userAuthToken = await getJWTokenApiCall();

      if (userAuthToken === null) {
        notify(1, "AuthToken is null");
      } else {
        try {
          let formData = new FormData();

          formData.append("ScromCourseId", scromCourseId);
          formData.append("CourseScheduleId", sessionScheduleId);
          formData.append(
            "EmailId",
            proxyUserDetails === null
              ? userDetails?.EmailId
              : proxyUserDetails?.EmailId
          );
          formData.append("UserId", userDetails?.Id);
          formData.append(
            "EmployeeCode",
            proxyUserDetails === null
              ? userDetails?.EmployeeCode
              : proxyUserDetails?.EmployeeId
          );

          let headersList = {
            Accept: "*/*",
            // "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            Authorization: "Bearer " + userAuthToken,
          };

          let bodyContent = formData;

          let reqOptions = {
            url: `${SCROMAPIUrlRef.current}/UserMaster/GetSessionData`,
            method: "POST",
            headers: headersList,
            data: bodyContent,
          };

          let response = await axios.request(reqOptions);

          let { Data, Status, Message } = response.data;
          if (Status === 0) {
            console.log("Response 1100:", Data);

            await updateSessionDataApiCall(Data);
          } else {
            notify(1, Message);
          }
        } catch (error) {
          console.error("Error : ", error);
        } finally {
          hideLoader();
        }
      }
    } catch (error) {
      console.error("Error : ", error);
    } finally {
      hideLoader();
    }
  };

  const updateSessionDataApiCall = async (data) => {
    try {
      showLoader();
      let headersList = {
        Accept: "*/*",
        //"User-Agent": "Thunder Client (https://www.thunderclient.com)",
        "Content-Type": "application/json",
      };

      let bodyContent = JSON.stringify(data);

      let reqOptions = {
        url: `${SCROMAPIUrlRef.current}/LMSFinish`,
        method: "POST",
        headers: headersList,
        data: bodyContent,
      };

      let response = await axios.request(reqOptions);
      console.log("response :", response);
      setShowScromModal(false);
      // let { Data, Status, Message } = response.data;
      // if (Status === 0) {
      // } else {
      //   notify(1, Message);
      // }
    } catch (error) {
      console.error("Error : ", error);
    } finally {
      hideLoader();
    }
  };

  const getetCourseStatusDataApiCall = async (
    scromCourseId,
    sessionScheduleId
  ) => {
    try {
      showLoader();
      const userAuthToken = await getJWTokenApiCall();

      console.log("userAuthToken 104", userAuthToken);
      console.log("SCROMAPIUrlRef 106", SCROMAPIUrlRef.current);

      if (userAuthToken === null) {
        notify(1, "AuthToken is null");
      } else {
        try {
          let formData = new FormData();

          formData.append("ScromCourseId", scromCourseId);
          formData.append("CourseScheduleId", sessionScheduleId);
          formData.append(
            "EmailId",
            proxyUserDetails === null
              ? userDetails?.EmailId
              : proxyUserDetails?.EmailId
          );
          formData.append("UserId", userDetails?.Id);
          formData.append(
            "EmployeeCode",
            proxyUserDetails === null
              ? userDetails?.EmployeeCode
              : proxyUserDetails?.EmployeeId
          );

          let headersList = {
            Accept: "*/*",
            // "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            Authorization: "Bearer " + userAuthToken,
          };

          let bodyContent = formData;

          let reqOptions = {
            url: `${SCROMAPIUrlRef.current}/UserMaster/GetCourseStatusData`,
            method: "POST",
            headers: headersList,
            data: bodyContent,
          };

          let response = await axios.request(reqOptions);

          let { Data, Status, Message } = response.data;
          if (Status === 0) {
            console.log("Data 1000", Data);
            let ScromProgressData = 0;
            if (Data.lesson_status === "incomplete") {
              ScromProgressData = 0;
            } else if (Data.lesson_status === "completed") {
              ScromProgressData = 100;
            } else {
              ScromProgressData = 0;
            }
            await updateScromProgress(ScromProgressData);
          } else {
            notify(1, Message);
          }
        } catch (error) {
          console.error("Error : ", error);
        } finally {
          hideLoader();
        }
      }
    } catch (error) {
      console.error("Error : ", error);
    } finally {
      hideLoader();
    }
  };

  const updateScromProgress = async (progressDuration) => {
    try {
      showLoader();

      const paramaters = {
        ContentId: contentIdRef.current,
        SessionScheduleId: sessionScheduleIdRef.current,
        UserId: userDetails?.Id,
        TotalDuration: 100,
        ProgressDuration: progressDuration,
        LaunchId: state.launchId,
      };

      const responseData = await APICall(
        contentCompletionStatus,
        "POST",
        paramaters
      );

      if (responseData?.status === 0) {
        console.log("Duration updated sucessfully.");
        // window.location.reload();
      } else {
        console.error(responseData?.message);
      }
    } catch (error) {
      console.error("Error :", error);
    } finally {
      hideLoader();
    }
  };

  const viewScromProgressHistory = async (scromCourseId, sessionScheduleId) => {
    await GetScromCourseProgressData(scromCourseId, sessionScheduleId);
  };

  const GetScromCourseProgressData = async (
    scromCourseId,
    sessionScheduleId
  ) => {
    try {
      showLoader();
      const userAuthToken = await getJWTokenApiCall();

      if (userAuthToken === null) {
        // notify(1, "AuthToken is null");
        console.log("AuthToken is null");
      } else {
        try {
          let formData = new FormData();

          formData.append("ScromCourseId", scromCourseId);
          formData.append("CourseScheduleId", sessionScheduleId);
          formData.append(
            "EmailId",
            proxyUserDetails === null
              ? userDetails?.EmailId
              : proxyUserDetails?.EmailId
          );
          formData.append("UserId", userDetails?.Id);
          formData.append(
            "EmployeeCode",
            proxyUserDetails === null
              ? userDetails?.EmployeeCode
              : proxyUserDetails?.EmployeeId
          );

          let headersList = {
            Accept: "*/*",
            // "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            Authorization: "Bearer " + userAuthToken,
          };

          let bodyContent = formData;

          let reqOptions = {
            url: `${SCROMAPIUrlRef.current}/UserMaster/GetScromCourseProgressData`,
            method: "POST",
            headers: headersList,
            data: bodyContent,
          };

          let response = await axios.request(reqOptions);

          let { Data, Status, Message } = response.data;
          if (Status === 0) {
            setShowScromProgressHistoryModel(true);

            if (Data?.length > 0) {
              setScromProgressHistoryCount(Data?.length);
              setScromProgressHistoryData(Data);
            } else {
              setScromProgressHistoryData([]);
              setScromProgressHistoryCount(0);
            }
          } else {
            notify(1, Message);
          }
        } catch (error) {
          console.error("Error : ", error);
        } finally {
          hideLoader();
        }
      }
    } catch (error) {
      console.error("Error : ", error);
    } finally {
      hideLoader();
    }
  };

  const scromProgressHistoryOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: scromProgressHistoryCount,
    rowsPerPage: scromProgressHistoryCount,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    onColumnSortChange: async (sortColumn, sortDirection) => {
      // if (sortDirection === "asc") {
      //   await setSortColumn(sortColumn);
      //   await setSortDirection(sortDirection);
      // }
      // if (sortDirection === "desc") {
      //   await setSortColumn(sortColumn);
      //   await setSortDirection(sortDirection);
      // }
    },
    onChangePage: async (page) => {
      // setPage(page * PageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };
  const scromProgressHistoryGridColumns = [
    {
      name: "LessonStatus",
      label: "Course Status",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: false,
      },
    },
    // {
    //   name: "SessionTime",
    //   label: "Session Time",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     sortDescFirst: false,
    //   },
    // },
    {
      name: "StartDateTime",
      label: "Start DateTime",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: false,
      },
    },
    {
      name: "EndDateTime",
      label: "End DateTime",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: false,
      },
    },

    {
      name: "ScoreMax",
      label: "Total Score",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: false,
      },
    },

    {
      name: "ScoreRaw",
      label: "Obtained Score",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: false,
      },
    },

    // {
    //   name: "ScoreMin",
    //   label: "Score Min",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     sortDescFirst: false,
    //   },
    // },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      // (async () => {
      // const currentTotalDuration = Number(totalDuration);
      // const currentProgressDuration = Number(progressDuration);
      if (
        Number(sessionScheduleIdRef.current) > 0 &&
        Number(contentIdRef.current) > 0 &&
        Number(totalDurationRef.current) > 0 &&
        Number(progressDurationRef.current) > 0 &&
        !isCourseCompletedRef.current
      ) {
        ContentCompletionStatusApiCall();
      }
      // else if (
      //   Number(sessionScheduleIdRef.current) > 0 &&
      //   Number(contentIdRef.current) > 0 &&
      //   Number(scromCourseIdRef.current) > 0 &&
      //   contentNameRef.current === contentObj?.Scrome &&
      //   !isCourseCompletedRef.current
      // ) {
      //   // ContentCompletionStatusApiCall();

      //   getetCourseStatusDataApiCall(
      //     scromCourseIdRef.current,
      //     sessionScheduleIdRef.current
      //   );
      // }

      // ContentCompletionStatusApiCall();
      // })();
    }, 30000); // Update every minute

    return () => {
      clearInterval(interval);
    };
  }, []);
  // }, [sessionScheduleId, contentId, totalDuration, progressDuration]);

  const ContentCompletionStatusApiCall = async () => {
    showLoader();

    let addDuration = 0;
    if (contentNameRef.current === contentObj?.Scrome) {
      addDuration = Number(progressDurationRef.current) + 30;
    } else {
      addDuration = Number(progressDurationRef.current);
    }

    const paramaters = {
      ContentId: contentIdRef.current,
      SessionScheduleId: sessionScheduleIdRef.current,
      UserId: userDetails?.Id,
      TotalDuration: totalDurationRef.current,
      ProgressDuration: addDuration,
      LaunchId: state.launchId,
    };

    const responseData = await APICall(
      contentCompletionStatus,
      "POST",
      paramaters
    );

    if (responseData?.status === 0) {
      console.log("Duration updated sucessfully.");

      // if (contentNameRef.current === contentObj?.Scrome) {
      //   getContentCompletionStatusForScromeApiCall(
      //     contentIdRef.current,
      //     sessionScheduleIdRef.current
      //   );
      // }
    } else {
      console.error(responseData?.message);
    }

    hideLoader();
  };

  const getContentCompletionStatusForScromeApiCall = async (
    contentId,
    sessionScheduleId
  ) => {
    showLoader();

    const paramaters = {
      ContentId: contentId,
      SessionScheduleId: sessionScheduleId,
      UserId: userDetails?.Id,
    };

    const responseData = await APICall(
      getContentCompletionStatus,
      "POST",
      paramaters
    );

    if (responseData?.status === 0 && responseData?.data !== null) {
      const currentProgressDurationData = responseData?.data?.progressDuration;

      setProgressDuration(currentProgressDurationData);

      progressDurationRef.current = currentProgressDurationData;
    } else {
      console.error(responseData?.message);
    }

    hideLoader();
  };

  useEffect(() => {
    if (
      Number(sessionScheduleIdRef.current) > 0 &&
      Number(contentIdRef.current) > 0 &&
      Number(trackPdfData?.pageNumber) > 0 &&
      Number(trackPdfData?.numPages) > 0
    ) {
      (async () => {
        const pageNumber = trackPdfData?.pageNumber;
        const totalNumbersOfPage = trackPdfData?.numPages;
        await tractPdfStatus(pageNumber, totalNumbersOfPage);
      })();
    }
  }, [trackPdfData]);

  const handletractPdfStatusFunction = (totalPdfNumber) => {
    if (totalPdfNumber === 1) {
      tractPdfStatus(totalPdfNumber, totalPdfNumber);
    }
  };

  const tractPdfStatus = async (pageNumber, totalNumbersOfPage) => {
    showLoader();
    const paramaters = {
      ContentId: contentIdRef.current,
      SessionScheduleId: sessionScheduleIdRef.current,
      UserId: userDetails?.Id,
      TotalDuration: totalNumbersOfPage,
      ProgressDuration: pageNumber,
      LaunchId: state.launchId,
    };

    const responseData = await APICall(
      contentCompletionStatus,
      "POST",
      paramaters
    );

    if (responseData?.status === 0) {
      console.log("Duration updated sucessfully.");
    } else {
      console.error(responseData?.message);
    }

    hideLoader();
  };

  const getContentCompletionStatusApiCall = async (
    contentId,
    sessionScheduleId
  ) => {
    showLoader();

    const paramaters = {
      ContentId: contentId,
      SessionScheduleId: sessionScheduleId,
      UserId: userDetails?.Id,
    };

    const responseData = await APICall(
      getContentCompletionStatus,
      "POST",
      paramaters
    );

    if (responseData?.status === 0 && responseData?.data !== null) {
      const currentProgressDurationData = responseData?.data?.progressDuration;

      setIsCourseCompleted(responseData?.data?.isCompleted);
      isCourseCompletedRef.current = responseData?.data?.isCompleted;
      setCurrentProgressDuration(currentProgressDurationData);

      currentProgressDurationRef.current = currentProgressDurationData;

      if (contentNameRef.current === contentObj?.Scrome) {
        setProgressDuration(currentProgressDurationData);

        progressDurationRef.current = currentProgressDurationData;
      }
    } else {
      console.error(responseData?.message);
    }

    hideLoader();
  };

  useEffect(() => {
    if (courseId > 0) {
      getLMCourceMasterDataByIdApiCall();
    }
  }, [courseId]);

  const getLMCourceMasterDataByIdApiCall = async () => {
    showLoader();
    const responseData = await APICall(getCourseData, "POST", {
      Id: courseId,
      LaunchId: state.launchId,
      UserId: userDetails?.Id,
    });

    if (responseData?.status === 0) {
      // const journyIds = responseData?.data?.programIds?.split(",") || null;

      // const journyArray = [];

      const LMSCourceMasterData = {
        courseId: responseData?.data?.courseCode,
        courseTitle: responseData?.data?.courseTitle,
        learningObjective: responseData?.data?.learningObjective,
        courseCategory: responseData?.data?.courseCategory,
        journeyTitle: responseData?.data?.programTitle,
        // journey: journyArray.length > 0 ? journyArray : null,
        minParticipants: responseData?.data?.minimumParticipants,
        maxParticipants: responseData?.data?.maximumParticipants,
        requestBefore: responseData?.data?.requestBeforeDays,
        optoutBefore: responseData?.data?.optoutBeforeDays,
        requestApprovalRequired: null,
        optOutApprovalRequired: null,
        optoutAllowed: !responseData?.data?.requestRequired,
        requestRequired: !responseData?.data?.optoutAllowed,
        registrationByManager: responseData?.data?.registerationByManager,
        scheduleRequired: responseData?.data?.launched,
        mandatoryCourse: responseData?.data?.mandetoryCourse,
        progressPercentage: responseData?.data?.progressPercentage,
      };

      setCourseData(LMSCourceMasterData);
    }

    hideLoader();
  };

  const [courseScheduleData, setCourseScheduleData] = useState([]);

  const getCourseScheduleData = async () => {
    const res = await APICall(getCourseScheduleDetails, "POST", {
      CourseScheduleId: state?.courseScheduleId,
      LaunchId: state.launchId,
      UserId: userDetails?.Id,
    });

    if (res.status === 0 && res.data !== null && res.data.length > 0) {
      setCourseId(res.data[0].courseId);
      setCourseScheduleData(res.data);
      setSessionsArr(res.data);
    }
  };

  const getUserCertificate = async (data) => {
    showLoader();
    const response = await APICall(
      data !== null && data !== ""
        ? getUserCertificateFile
        : generateUserCertificate,
      "POST",
      {
        UserId: userDetails.Id,
        CourseScheduleId: state?.courseScheduleId,
        ThumbnailUrl: data,
        IsPreview: false,
      }
    );

    if (response.status === 0 && response.data !== null) {
      const base64 = response?.data;
      const fileName = `Certificate.pdf`;

      let arrayBuffer = await base64ToArrayBuffer(base64);
      var byteArray = new Uint8Array(arrayBuffer);

      let blob = new Blob([byteArray], {
        type: "application/pdf",
      });
      const newFile = new File([blob], "", {
        type: "application/pdf",
        lastModified: new Date().getTime(),
      });
      fileDownload(newFile, fileName);
    } else {
      notify(1, "Something went wrong try again later");
    }
    hideLoader();

    // if (data.filePath === null || data.filePath === "") {
    //   setRefresh((prev) => !prev);
    // }
  };

  useEffect(() => {
    getCourseScheduleData();
  }, []);

  const playerRef = useRef(null);

  const seekToTime = () => {
    if (playerRef?.current) {
      playerRef?.current?.seekTo(
        Number(currentProgressDurationRef.current).toFixed(),
        "seconds"
      );
      // playerRef?.current?.seekTo(Number(30).toFixed(), "seconds");
    }
  };

  const [pendingAssessmentModal, setPendingAssessmentModal] = useState(false);
  const [pendingAssessmentData, setPendingAssessmentData] = useState(null);
  const [pendingMandatoryData, setPendingMandatoryData] = useState(null);

  // check if pre-assessment completed
  const checkIfPreAssessmentCompleted = async () => {
    showLoader();

    const res = await APICall(isAssessmentPending, "POST", {
      CourseScheduleId: state?.courseScheduleId,
      LaunchId: state.launchId,
      UserId: userDetails?.Id,
    });

    if (res.status === 0 && res.data !== null && res.data.length > 0) {
      const pendingData = res.data.filter(
        (pending) =>
          pending.isMandatory === true && pending.assessmentType === "PRE"
      );
      if (pendingData.length > 0) {
        setPendingMandatoryData(pendingData);
      } else {
        setPendingMandatoryData(null);
        setPendingAssessmentData(res.data);
      }
      setPendingAssessmentModal(true);
    }

    hideLoader();
  };

  useEffect(() => {
    checkIfPreAssessmentCompleted();
  }, []);

  useEffect(() => {
    if (!pendingAssessmentModal) {
      setPendingAssessmentData(null);
    }
  }, [pendingAssessmentModal]);

  const [courseScheduleGridData, setCourseScheduleGridData] = useState([]);

  const courseScheduleColumns = [
    {
      name: "courseId",
      label: "Course ID",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "courseTitle",
      label: "Course Title",
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: "courseCategory",
      label: "Category",
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: "courseStatus",
      label: "Course Status",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Badge
              style={{
                padding: "8px 14px",
              }}
              pill
              text="white"
              bg={
                value === "0%"
                  ? "danger"
                  : value === "100%"
                  ? "success"
                  : "warning"
              }
            >
              {value}
            </Badge>
          );
        },
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <span
              dangerouslySetInnerHTML={{
                __html: value,
              }}
            ></span>
          );
        },
      },
    },

    {
      name: "programTitle",
      label: "Journey Title",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "courseScheduleAttendance",
      label: "Attendance Status",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Badge
              style={{
                padding: "8px 14px",
              }}
              pill
              text="white"
              bg={value === "Present" ? "success" : "danger"}
            >
              {value}
            </Badge>
          );
        },
      },
    },
    {
      name: "pendingAssessment",
      label: "Pending Assessment",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <a
              onClick={async () => {
                if (value > 0) {
                  navigate("/UserAssessments");
                }
              }}
            >
              <Tooltip
                title={
                  value > 0
                    ? courseScheduleData[0]?.pendingAssessmentMessage
                    : ""
                }
              >
                <Badge
                  style={{
                    padding: "8px 14px",
                  }}
                  pill
                  text="white"
                  bg="warning"
                >
                  {value}
                </Badge>
              </Tooltip>
            </a>
          );
        },
      },
    },
    {
      name: "pendingFeedback",
      label: "Pending Feedback",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <a
              onClick={async () => {
                if (value > 0) {
                  navigate("/UserFeedback");
                }
              }}
            >
              <Tooltip
                title={
                  value > 0 ? courseScheduleData[0]?.pendingFeedbackMessage : ""
                }
              >
                <Badge
                  style={{
                    padding: "8px 14px",
                  }}
                  pill
                  text="white"
                  bg="primary"
                >
                  {value}
                </Badge>
              </Tooltip>
            </a>
          );
        },
      },
    },

    {
      name: "certificateUrl",
      label: "Certificate",
      options: {
        filter: false,
        sort: false,
        display: courseScheduleData[0]?.hasCertificate > 0 ? true : false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Tooltip title="Download">
              <p style={{ width: "170px", maxWidth: "170px" }}>
                <button
                  className="mt-2 btn btn-filter-submit"
                  onClick={() => getUserCertificate(value)}
                >
                  Download Certificate
                </button>
              </p>
            </Tooltip>
          );
        },
      },
    },
  ];

  useEffect(() => {
    if (courseData && courseScheduleData.length > 0) {
      const gridData = [
        {
          courseId: courseData.courseId,
          courseTitle: courseData.courseTitle,
          courseCategory: courseData.courseCategory,
          courseStatus: `${courseData?.progressPercentage}%`,
          description: courseData.learningObjective,
          programTitle: courseData.journeyTitle,
          courseScheduleAttendance:
            courseScheduleData[0]?.courseScheduleAttendance,
          pendingAssessment: courseScheduleData[0]?.pendingAssessment,
          pendingFeedback: courseScheduleData[0]?.pendingFeedback,
          certificateUrl: courseScheduleData[0]?.certificateUrl,
        },
      ];

      setCourseScheduleGridData(gridData);
    }
  }, [courseData, courseScheduleData]);

  return (
    <>
      <div className="Container-fluid">
        <div className="col-lg-12 text-right mt-2">
          {/* <button
            onClick={() => {
              getetCourseStatusDataApiCall(
                scromCourseIdRef.current,
                sessionScheduleIdRef.current
              );
              window.location.reload();
            }}
            disabled={false}
            // className="btn btn-primary ml-2 mt-4"
            className="btn btn-primary ml-2 float-right"
          >
            <i className="fa-solid fa-refresh"></i> Refresh
          </button> */}

          <button
            className="expand_btn"
            disabled={contentUrl !== "" ? false : true}
            onClick={() => {
              setResize((prev) => !prev);

              if (isZoomIn) {
                setIsZoomIn(false);
                setIsZoomOut(true);
              } else {
                setIsZoomIn(true);
                setIsZoomOut(false);
              }
            }}
          >
            <MultipleStop></MultipleStop>
          </button>

          <button
            onClick={() => {
              if (isZoomIn) {
                setResize((prev) => !prev);
                setIsZoomIn(false);
                setIsZoomOut(true);
              } else {
                navigate(state?.from ? state.from : "/Catalogue");
              }
            }}
            disabled={false}
            className="btn btn-secondary ml-2 float-right"
          >
            <i className="fa-solid fa-arrow-left"></i> Back
          </button>
        </div>

        <div className="contView row">
          <div
            className={
              resize ? "col-lg-12 text-center" : "col-lg-4 text-center"
            }
          >
            {/* {contentName === contentObj?.Scrome && (
              // <div className="cont_img">
              <div className="scrom-modal-body">
                <iframe
                  src={contentUrl}
                  width="100%"
                  style={{ height: "100vh", border: "none" }}
                  // height={resize ? "120vh" : "auto"}
                  // style={{
                  //   height: "75vh",
                  //   border: "none",
                  //   padding: "10px 0",
                  // }}
                ></iframe>
              </div>
            )} */}

            {contentName === "defaultImage" && (
              <div className="cont_img">
                <img src={require("../../Assets/Images/thumbnail.jpg")} />
              </div>
            )}
            {contentName === contentObj?.Pdf && (
              <div className="pdf">
                <PdfViewer
                  contentUrl={contentUrl}
                  setTrackPdfData={setTrackPdfData}
                  currentPageNumber={currentProgressDurationRef.current}
                  handletractPdfStatusFunction={handletractPdfStatusFunction}
                />
              </div>
            )}

            {contentName === contentObj?.Audio && (
              // <>
              //   <div className="audio_img">
              //     <audio controls>
              //       <source src={`data:audio/wav;base64,${contentUrl}`} />
              //     </audio>
              //   </div>
              // </>
              <>
                {profileImg ? (
                  <div className="audio_img">
                    <img
                      style={resize ? { width: "30%" } : { width: "100%" }}
                      src={profileImg}
                    />
                  </div>
                ) : (
                  <div className="audio_img">
                    <img
                      style={resize ? { width: "30%" } : { width: "100%" }}
                      src={require("./assets/audi_def.jpg")}
                    />
                  </div>
                )}

                {/* <audio
                  // controls="controls"
                  // autobuffer="autobuffer"
                  // autoplay="autoplay"

                  controls
                >
                  <source src={`data:audio/wav;base64,${contentUrl}`} />
                </audio> */}

                <ReactPlayer
                  ref={playerRef}
                  url={`data:audio/wav;base64,${contentUrl}`}
                  className="react-player"
                  controls
                  onDuration={(e) => {
                    console.log("Duration", Number(e).toFixed());
                    setTotalDuration(Number(e).toFixed());

                    totalDurationRef.current = Number(e).toFixed();
                  }}
                  onProgress={(e) => {
                    console.log("InProgress Audio:", e?.playedSeconds);

                    setProgressDuration(Number(e?.playedSeconds).toFixed());

                    progressDurationRef.current = Number(
                      e?.playedSeconds
                    ).toFixed();
                  }}
                  onEnded={() => {
                    ContentCompletionStatusApiCall();
                  }}
                  onSeek={(e: number) => {
                    setProgressDuration(Number(e).toFixed());
                    progressDurationRef.current = Number(e).toFixed();
                  }}
                  // onReady={handlePlayerReady}
                  // onPlay={seekToTime}
                  onStart={seekToTime}
                />
              </>
            )}

            {contentName === contentObj?.Link && (
              <div>
                <iframe
                  src={`${contentUrl}`}
                  width="100%"
                  height="auto"
                  style={{ height: "100vh" }}
                ></iframe>
              </div>
            )}

            {contentName === contentObj?.VideoLink && (
              <>
                <div className="cont_img">
                  <ReactPlayer
                    ref={playerRef}
                    // className="react-player"
                    url={contentUrl}
                    controls
                    onDuration={(e) => {
                      // setTotalDuration(Number(e).toFixed());

                      setTotalDuration(Number(e).toFixed());

                      totalDurationRef.current = Number(e).toFixed();
                    }}
                    onProgress={(e) => {
                      console.log("Progress", e);

                      // setProgressDuration(Number(e?.playedSeconds).toFixed());

                      setProgressDuration(Number(e?.playedSeconds).toFixed());

                      progressDurationRef.current = Number(
                        e?.playedSeconds
                      ).toFixed();
                    }}
                    onEnded={() => {
                      ContentCompletionStatusApiCall();
                    }}
                    onStart={seekToTime}
                    // onPlay={seekToTime}
                    onSeek={(e: number) => {
                      setProgressDuration(Number(e).toFixed());
                      progressDurationRef.current = Number(e).toFixed();
                    }}
                    width="866px"
                    height="504px"
                  />
                </div>
              </>
            )}

            {/* added by mahesh  */}
            {contentName === contentObj?.MP4 && (
              <>
                {/* <video controls>
                    <source src={`data:video/mp4;base64,${contentUrl}`} />
                                         
                    </video> */}
                <div className="cont_img">
                  <ReactPlayer
                    ref={playerRef}
                    url={contentUrl}
                    controls
                    config={{
                      vimeo: {
                        playerOptions: {
                          vimeo_logo: false,
                          dnt: true,
                          title: false,
                          byline: false,
                          portrait: false,
                          autoplay: false,
                          badge: false,
                          share: false,
                          like: false,
                          watchlater: false,
                        },
                      },
                    }}
                    onDuration={(e) => {
                      console.log("Duration", Number(e).toFixed());
                      setTotalDuration(Number(e).toFixed());

                      totalDurationRef.current = Number(e).toFixed();
                    }}
                    onProgress={(e) => {
                      console.log("InProgress mp4 video:", e?.playedSeconds);

                      setProgressDuration(Number(e?.playedSeconds).toFixed());

                      progressDurationRef.current = Number(
                        e?.playedSeconds
                      ).toFixed();
                    }}
                    onEnded={() => {
                      ContentCompletionStatusApiCall();
                    }}
                    onSeek={(e: number) => {
                      setProgressDuration(Number(e).toFixed());
                      progressDurationRef.current = Number(e).toFixed();
                    }}
                    // onReady={handlePlayerReady}
                    // onPlay={seekToTime}
                    onStart={seekToTime}
                    width="866px"
                    height="504px"
                  />
                </div>
              </>
            )}

            {/* end  */}

            {/* {contentName === contentObj?.Link && ( */}
            {/* <div>
              <a
                href="https://fontawesome.com/v4/icon/eye"
                // target="_blank"
                rel="noopener noreferrer"
              ></a>
            </div> */}
            {/* )} */}
          </div>

          <Collapse in={!resize} dimension="width">
            <div className={resize ? "col-lg-4" : "col-lg-8"}>
              <div className="courseDetl">
                <h2 className="ctitle">
                  <p style={{ fontSize: "16px" }}>
                    {courseData && courseData.courseTitle} -{" "}
                    {/* -{" "}
                    {courseData && courseData.courseId} -{" "} */}
                    {courseScheduleData.length > 0 &&
                      moment(courseScheduleData[0].courseStartDate).format(
                        "DD-MMM-YYYY"
                      )}{" "}
                    -{" "}
                    {courseScheduleData.length > 0 &&
                    moment(courseScheduleData[0]?.courseEndDate).year() === 9999
                      ? "N/A"
                      : moment(courseScheduleData[0]?.courseEndDate).format(
                          "DD-MMM-YYYY"
                        )}
                  </p>
                </h2>
                <div>
                  {/* <div className={resize ? "w-100" : "w-50"}>
                    <p>
                      <span>Course Code : </span>
                      {courseData && courseData.courseId}
                    </p>
                  </div>
                  <div className={resize ? "w-100" : "w-50"}>
                    <p>
                      <span>Course Title : </span>
                      {courseData && courseData.courseTitle}
                    </p>
                  </div>
                  <div className={resize ? "w-100" : "w-50"}>
                    <p>
                      <span>Category : </span>
                      {courseData && courseData.courseCategory}
                    </p>
                  </div>
                  <div className={resize ? "w-100" : "w-50"}>
                    <p>
                      <span>Course Status : </span>
                      {courseData && `${courseData?.progressPercentage}%`}
                    </p>
                  </div>
                  <div className={resize ? "w-100 desr" : "w-100 desr"}>
                    <p>
                      <span className="wid_desc">Description : </span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: courseData && courseData.learningObjective,
                        }}></span>
                    </p>
                  </div>
                  <div className={resize ? "w-100" : "w-50"}>
                    <p>
                      <span>Journey Title : </span>
                      {courseData && courseData.courseTitle}
                    </p>
                  </div>

                  <div className={resize ? "w-100" : "w-50"}>
                    <p>
                      <span>Attendance Status : </span>
                      {courseScheduleData[0]?.courseScheduleAttendance}
                    </p>
                  </div>
                  <div className={resize ? "w-100" : "w-50"}>
                    <p>
                      <span>Pending Assessment : </span>
                    </p>
                  </div>
                  <div className={resize ? "w-100" : "w-50"}>
                    <p>
                      <span>Pending Feedback : </span>
                    </p>
                  </div>
                  {courseScheduleData[0]?.hasCertificate > 0 && (
                    <div className={resize ? "w-100" : "w-50"}></div>
                  )} */}

                  <LMSDynamicGrid
                    data={courseScheduleGridData}
                    columns={courseScheduleColumns}
                  />
                </div>
                <div className="row mt-3">
                  <div className="col-lg-12 ">
                    {sessionsArr.length > 0 &&
                      sessionsArr.map((currentSession, sessionIndex) => (
                        <SessionAccordion
                          currentSession={currentSession}
                          sessionIndex={sessionIndex}
                          sessionsArr={sessionsArr}
                          setSessionsArr={setSessionsArr}
                          setRefresh={setRefresh}
                          courseId={courseId}
                          resize={resize}
                          viewerData={viewerData}
                          viewScromProgressHistory={viewScromProgressHistory}
                        />
                      ))}
                    {/* <Accordion className="mb-3">
         <AccordionSummary
           id="sessionName"
           style={{ background: "#3C5464" }}
           className="text-white acc_close"
           expandIcon={<ExpandMore />}
         >
           <div className="row d-flex align-items-center acc-heading">
             <div className="col-lg-10 col-md-10 col-sm-12">
               <p style={{ fontSize: "16px" }}>Session 1</p>
             </div>
             <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
           </div>
         </AccordionSummary>

         <AccordionDetails className="page_heading">
           <div className=" row">
             <div className="col-lg-3">
               <div className="card main_card">
                 <img src={require("./assets/thumbnail.jpg")}></img>
               </div>
             </div>
             <div className="col-lg-9">
               <div className="c_detl s_detl">
                 <div className={resize ? "w-100" : "w-50"}>
                   <p>
                     <span>Session ID: </span>LM/Session/000010
                   </p>
                 </div>
                 <div className={resize ? "w-100" : "w-50"}>
                   <p>
                     <span>Session Title: </span>Test Session title
                   </p>
                 </div>
                 <div className={resize ? "w-100" : "w-50"}>
                   <p>
                     <span>Session Type: </span>Webinar
                   </p>
                 </div>
                 <div className={resize ? "w-100" : "w-50"}>
                   <p>
                     <span>Duration: </span>10 hrs : 20 min
                   </p>
                 </div>
                 <div
                   className={resize ? "w-100 desr" : "w-100 desr"}
                 >
                   <p>
                     <span>Session Objective: </span>Test Object
                   </p>
                 </div>
               </div>
             </div>
             <div className="col-lg-12">
               <p className="mt-3 mb-2">Content</p>
               <table className="table table-striped table-bordered table-hover">
                 <thead>
                   <tr>
                     <th className="text-dark">Thumbnail</th>
                     <th className="text-dark">Title</th>
                     <th className="text-dark">Type</th>
                   </tr>
                 </thead>
                 <tbody>
                   <tr>
                     <td>
                       <div className="card">
                         <img
                           src="/static/media/thumbnail.e83e00e04a871eaf1317.jpg"
                           className="card-img-top"
                           alt="Thumbnail Image"
                         />
                       </div>
                     </td>
                     <td>Test title</td>
                     <td>Audio</td>
                   </tr>
                 </tbody>
               </table>
             </div>
           </div>
         </AccordionDetails>
       </Accordion>
       <Accordion className="mb-3">
         <AccordionSummary
           id="sessionName"
           style={{ background: "#3C5464" }}
           className="text-white acc_close"
           expandIcon={<ExpandMore />}
         >
           <div className="row d-flex align-items-center acc-heading">
             <div className="col-lg-10 col-md-10 col-sm-12">
               <p style={{ fontSize: "16px" }}>Session 2</p>
             </div>
             <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
           </div>
         </AccordionSummary>

         <AccordionDetails className="page_heading">
           <div className=" row">
             <div className="col-lg-3">
               <div className="card main_card">
                 <img src={require("./assets/thumbnail.jpg")}></img>
               </div>
             </div>
             <div className="col-lg-9">
               <div className="c_detl s_detl">
                 <div className={resize ? "w-100" : "w-50"}>
                   <p>
                     <span>Session ID: </span>LM/Session/000010
                   </p>
                 </div>
                 <div className={resize ? "w-100" : "w-50"}>
                   <p>
                     <span>Session Title: </span>Test Session title
                   </p>
                 </div>
                 <div className={resize ? "w-100" : "w-50"}>
                   <p>
                     <span>Session Type: </span>Webinar
                   </p>
                 </div>
                 <div className={resize ? "w-100" : "w-50"}>
                   <p>
                     <span>Duration: </span>10 hrs : 20 min
                   </p>
                 </div>
                 <div
                   className={resize ? "w-100 desr" : "w-100 desr"}
                 >
                   <p>
                     <span>Session Objective: </span>Test Object
                   </p>
                 </div>
               </div>
             </div>
             <div className="col-lg-12">
               <p className="mt-3 mb-2">Content</p>
               <table className="table table-striped table-bordered table-hover">
                 <thead>
                   <tr>
                     <th className="text-dark">Thumbnail</th>
                     <th className="text-dark">Title</th>
                     <th className="text-dark">Type</th>
                   </tr>
                 </thead>
                 <tbody>
                   <tr>
                     <td>
                       <div className="card">
                         <img
                           src="/static/media/thumbnail.e83e00e04a871eaf1317.jpg"
                           className="card-img-top"
                           alt="Thumbnail Image"
                         />
                       </div>
                     </td>
                     <td>Test title</td>
                     <td>Audio</td>
                   </tr>
                 </tbody>
               </table>
             </div>
           </div>
         </AccordionDetails>
       </Accordion>
       <Accordion className="mb-3">
         <AccordionSummary
           id="sessionName"
           style={{ background: "#3C5464" }}
           className="text-white acc_close"
           expandIcon={<ExpandMore />}
         >
           <div className="row d-flex align-items-center acc-heading">
             <div className="col-lg-10 col-md-10 col-sm-12">
               <p style={{ fontSize: "16px" }}>Session 3</p>
             </div>
             <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
           </div>
         </AccordionSummary>

         <AccordionDetails className="page_heading">
           <div className=" row">
             <div className="col-lg-3">
               <div className="card main_card">
                 <img src={require("./assets/thumbnail.jpg")}></img>
               </div>
             </div>
             <div className="col-lg-9">
               <div className="c_detl s_detl">
                 <div className={resize ? "w-100" : "w-50"}>
                   <p>
                     <span>Session ID: </span>LM/Session/000010
                   </p>
                 </div>
                 <div className={resize ? "w-100" : "w-50"}>
                   <p>
                     <span>Session Title: </span>Test Session title
                   </p>
                 </div>
                 <div className={resize ? "w-100" : "w-50"}>
                   <p>
                     <span>Session Type: </span>Webinar
                   </p>
                 </div>
                 <div className={resize ? "w-100" : "w-50"}>
                   <p>
                     <span>Duration: </span>10 hrs : 20 min
                   </p>
                 </div>
                 <div
                   className={resize ? "w-100 desr" : "w-100 desr"}
                 >
                   <p>
                     <span>Session Objective: </span>Test Object
                   </p>
                 </div>
               </div>
             </div>
             <div className="col-lg-12">
               <p className="mt-3 mb-2">Content</p>
               <table className="table table-striped table-bordered table-hover">
                 <thead>
                   <tr>
                     <th className="text-dark">Thumbnail</th>
                     <th className="text-dark">Title</th>
                     <th className="text-dark">Type</th>
                   </tr>
                 </thead>
                 <tbody>
                   <tr>
                     <td>
                       <div className="card">
                         <img
                           src="/static/media/thumbnail.e83e00e04a871eaf1317.jpg"
                           className="card-img-top"
                           alt="Thumbnail Image"
                         />
                       </div>
                     </td>
                     <td>Test title</td>
                     <td>Audio</td>
                   </tr>
                 </tbody>
               </table>
             </div>
           </div>
         </AccordionDetails>
       </Accordion> */}
                  </div>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      </div>

      <Modal
        show={pendingAssessmentModal}
        onHide={() => setPendingAssessmentModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <div
          className="text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
            backgroundColor: "#2c2b7c",
          }}
        >
          <div className="col-lg-12">Pending Assessment</div>
        </div>
        <Modal.Body>
          <div className="container-fluid">
            {pendingMandatoryData && pendingMandatoryData.length > 0 ? (
              <>
                <p className="mb-2">
                  <b>{pendingMandatoryData[0].errorMessage}</b>
                </p>

                {pendingMandatoryData && pendingMandatoryData.length > 0 && (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th className="text-dark">Sr. No.</th>
                        <th className="text-dark">Assessment Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pendingMandatoryData.map((pending, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{pending.assessmentName}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </>
            ) : (
              <>
                {pendingAssessmentData && pendingAssessmentData.length > 0 && (
                  <p className="mb-2">
                    <b>{pendingAssessmentData[0].errorMessage}</b>
                  </p>
                )}

                {pendingAssessmentData && pendingAssessmentData.length > 0 && (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th className="text-dark">Sr. No.</th>
                        <th className="text-dark">Assessment Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pendingAssessmentData.map((pending, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{pending.assessmentName}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => {
              if (pendingMandatoryData) {
                navigate("/UserAssessments");
              } else {
                setPendingAssessmentModal(false);
              }
            }}
          >
            {pendingMandatoryData ? "Pending Assessment" : "Continue"}
          </button>
        </Modal.Footer>
      </Modal>

      {/* Scrom Model*/}
      <Modal
        show={showScromModal}
        dialogClassName="modal-scrom"
        onHide={() => setShowScromModal(false)}
        backdrop="static"
        keyboard={false}
        // size="xl"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="row">
            {/* <div className="col-lg-6">{`SCROM`}</div> */}
            <div className="pr-4 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-12 col-md-12 col-sm-12">
              <Tooltip title="">
                <button
                  className="btn"
                  disabled={false}
                  onClick={() => {
                    getSessionDataApiCall(
                      scromCourseIdRef.current,
                      sessionScheduleIdRef.current
                    );

                    getetCourseStatusDataApiCall(
                      scromCourseIdRef.current,
                      sessionScheduleIdRef.current
                    );
                  }}
                >
                  <i className="fa fa-close"></i>
                </button>
              </Tooltip>
            </div>
          </div>
        </div>
        <Modal.Body>
          <>
            <div className="scrom-modal-body">
              {contentName === contentObj?.Scrome && (
                <div className="">
                  <iframe
                    src={contentUrl}
                    width="100%"
                    style={{ height: "100vh", border: "none" }}
                  ></iframe>
                </div>
              )}
            </div>
          </>
        </Modal.Body>
        {/* <Modal.Footer>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowScromModal(false);
              getetCourseStatusDataApiCall(scromCourseId);
            }}
          >
            Cancel
          </button>
        </Modal.Footer> */}
      </Modal>

      {/* Scrom Progress History Model*/}
      <Modal
        show={showScromProgressHistoryModel}
        dialogClassName="modal-90w"
        onHide={() => setShowScromProgressHistoryModel(false)}
        backdrop="static"
        keyboard={false}
        // size="xl"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">Digital Content Progress</div>
        </div>
        <Modal.Body>
          <>
            <div>
              <DynamicGrid
                data={scromProgressHistoryData}
                columns={scromProgressHistoryGridColumns}
                options={scromProgressHistoryOptions}
              />
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowScromProgressHistoryModel(false);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ContentViewer;
