import React from "react";
import { useState } from "react";
import useUserContext from "../ZustandStore/UserContextStore";
import AccessDenied from "../../Pages/AccessDenied/AccessDenied";

const RestrictedPageForCandidateOrAgency = ({
  permittedPages,
  pageContext,
  children,
}) => {
  let {
    userDetails,
    addUserDetails,
    deleteUserDetails,
    updateCurrentRoleId,
    currentRoleId,
    updateInterfacePermissionRecord,
    interfacePermissionRecord,
    proxyUserDetails,
    updateProxyUserDetails,
    proxyUserRoleDetails,
    updateProxyUserRoleDetails,
    proxyUserRoleOptions,
    updateProxyUserRoleOptions,
  }: any = useUserContext();

  const [env, setEnv] = useState<any>(
    process.env.REACT_APP_PUBLISH_PATH ? process.env.REACT_APP_PUBLISH_PATH : ""
  );

  const context = pageContext;
  var results = false;
  let permissionName = "";
  const Roles = userDetails?.Roles || [];

  const Candidate = Roles.find(
    (i) => i?.name?.toLocaleLowerCase()?.trim() == "candidate"
  );

  const IsCandidate = currentRoleId?.value == Candidate?.id;

  if (context != undefined) {
    let interfaceContext = context?.InterfacePermissionRecord.filter(
      (e) => e.InterfaceName === permittedPages
    );

    const values = ["Edit", "View"];

    interfaceContext = interfaceContext?.map((permission) => permission.Name);

    const isBoth = values.every((permission) => {
      return interfaceContext?.includes(permission);
    });

    if (isBoth) {
      results = true;
      permissionName = "Edit";
    } else {
      const values = ["Edit"];
      const isOnlyEdit = values.every((permission) => {
        return interfaceContext.includes(permission);
      });
      if (isOnlyEdit) {
        results = true;
        permissionName = "Edit";
      } else {
        const values = ["View"];
        const isOnlyView = values.every((permission) => {
          return interfaceContext.includes(permission);
        });
        if (isOnlyView) {
          results = true;
          permissionName = "View";
        }
      }
    }
  }

  if (userDetails?.IsAgency || IsCandidate) {
    return <AccessDenied />;
  } else {
    const childrenWithProps = React.Children.map(children, (child) => {
      return React.cloneElement(child, { permissionName });
    });

    return childrenWithProps;
  }
};

export default RestrictedPageForCandidateOrAgency;
