import React, { useContext, useEffect, useState } from "react";
import { LoaderContext } from "../../Helpers/Context/Context";
import { useNavigate } from "react-router-dom";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { Collapse, Tooltip } from "@mui/material";
import SelectForm from "../../Components/SelectForm/SelectForm";
import InputForm from "../../Components/InputForm/InputForm";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import notify from "../../Helpers/ToastNotification";
import { APICall } from "../../Helpers/APICalls";
import {
  DeactivateProgramDetailById,
  HoldProgramDetails,
  deactivateSessionDetailById,
  getLaunchHubDashboardData,
  getLaunchHubFilterOptions,
  getSessionDasboardDetails,
  getUserAndRoleWiseAccess,
  sessionDropdownData,
  updateLaunchHubActionById,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import { Modal } from "react-bootstrap";
import AssessmantAndFeedback from "./AssessmantAndFeedback";
import ReminderMail from "./ReminderMail";

const LaunchHub = () => {
  const [open, setOpen] = useState(false);
  const [start, setStart] = useState(0);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  let { userDetails, currentRoleId }: any = useUserContext();
  const [sortDirection, setSortDirection] = useState("");
  const [sortColumn, setSortColumn] = useState("");

  const [isDisable, setIsDisable] = useState(false);

  const [reSet, setreSet] = useState(false);

  const [journeyId, setJourneyId] = useState([]);
  const [journeyOptions, setJourneyOptions] = useState([]);

  const [courseId, setCourseId] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);

  const [createdBy, setCreatedBy] = useState([]);
  const [createdByOptions, setCreatedByOptions] = useState([]);

  const [launchCode, setLaunchCode] = useState(null);
  const [launchCodeOptions, setLaunchCodeOptions] = useState([]);

  const [status, setStatus] = useState(null);
  const [statusOptions, setStatusOptions] = useState([
    { value: 1, label: "Hold" },
    { value: 0, label: "Release" },
  ]);

  const [remarks, setRemarks] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showAssessmentandFeedbackModal, setShowAssessmentandFeedbackModal] =
    useState(false);

  const [showReminderMailModel, setShowReminderMailModel] = useState(false);
  const [modalAssessmentandFeedbackTitle, setModalAssessmentandFeedbackTitle] =
    useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [Id, setId] = useState(null);
  const [IsActive, setIsActive] = useState(false);
  const [hodeModal, sethodeModal] = useState(false);
  const [ishold, setIshold] = useState(false);

  const [launchType, setlaunchType] = useState(false);

  const [newLaunchHubId, setNewLaunchHubId] = useState(0);
  const [newProgramId, setNewProgramId] = useState(0);
  const [newLaunchForCode, setNewLaunchForCode] = useState("");

  const [isCourseMandatory, setIsCourseMandatory] = useState(false);

  interface userAndRoleWiseAccess {
    isView?: boolean;
    isEdit?: boolean;
    isDelete?: boolean;
  }

  const [userAndRoleWiseData, setUserAndRoleWiseData] =
    useState<userAndRoleWiseAccess>({});

  const getUserAndRoleWiseAccessApiCall = async () => {
    const response = await APICall(getUserAndRoleWiseAccess, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      InterfaceName: "LaunchHub_Dashboard",
    });

    if (response?.status === 0) {
      setUserAndRoleWiseData(response?.data);
    } else {
      notify(response?.status, response?.message);
    }
  };

  useEffect(() => {
    const responseData = async () => {
      const dropdownObj = {
        mode: "JOURNEY",
        userId: userDetails?.Id,
        roleId: currentRoleId?.value,
      };
      const res = await APICall(getLaunchHubFilterOptions, "POST", dropdownObj);
      if (res.data && res.data.length > 0) {
        setJourneyOptions(res.data);
      }

      dropdownObj.mode = "COURSE";

      const res1 = await APICall(
        getLaunchHubFilterOptions,
        "POST",
        dropdownObj
      );
      if (res1.data && res1.data.length > 0) {
        setCourseOptions(res1.data);
      }

      dropdownObj.mode = "CREATEDBY";

      const res2 = await APICall(
        getLaunchHubFilterOptions,
        "POST",
        dropdownObj
      );
      if (res2.data && res2.data.length > 0) {
        setCreatedByOptions(res2.data);
      }

      dropdownObj.mode = "LAUNCHCODE";

      const res3 = await APICall(
        getLaunchHubFilterOptions,
        "POST",
        dropdownObj
      );
      if (res3.data && res3.data.length > 0) {
        setLaunchCodeOptions(res3.data);
      }
    };

    responseData();
    getUserAndRoleWiseAccessApiCall();
  }, []);

  useEffect(() => {
    getLaunchHubData();
  }, [start, pageSize, sortColumn, sortDirection, searchText, reSet]);

  const getLaunchHubData = async () => {
    showLoader();
    const GetDashboardData = await APICall(getLaunchHubDashboardData, "POST", {
      Page: start,
      PageSize: pageSize,
      SortColumn: sortColumn,
      SortOrder: sortDirection,
      SearchText: searchText,
      JouryneyIds: journeyId?.map((s) => s.value).join(","),
      CourseIds: courseId?.map((s) => s.value).join(","),
      LaunchCreatedBy: createdBy?.map((s) => s.value).join(","),
      LaunchCodeIds: launchCode?.map((s) => s.value).join(","),
      Status: status !== null ? status?.value : null,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });
    if (
      GetDashboardData &&
      GetDashboardData.data !== null &&
      GetDashboardData.data.length > 0
    ) {
      setCount(GetDashboardData.data[0].totalCount);
      setRowData(GetDashboardData.data);
    } else if (
      GetDashboardData.data !== null &&
      GetDashboardData.data.length === 0
    ) {
      setRowData([]);
      setCount(0);
    } else {
      setRowData([]);
      setCount(0);
      notify(1, GetDashboardData?.message);
    }
    hideLoader();
  };

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    page: page,
    rowsPerPage: pageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      setStart(page * pageSize);
      setPage(page);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const gridColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        display: false,
      },
    },
    {
      name: "launchCode",
      label: "Launch Hub ID",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "programTitle",
      label: "Journey Title",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "courseTitle",
      label: "Course Title",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "launchFor",
      label: "Launch For",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "launchType",
      label: "Launch Type",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "launchDate",
      label: "Launch Date",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    // {
    //   name: "isRelaunched",
    //   label: "Is Relaunched",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     sortDescFirst: true,
    //   },
    // },
    {
      name: "createdByName",
      label: "Created By",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "createdOnDate",
      label: "Created Date",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta) => {
          let id = tableMeta.tableData[tableMeta.rowIndex].id;
          let isActive = tableMeta.tableData[tableMeta.rowIndex].isActive;
          let hold = tableMeta.tableData[tableMeta.rowIndex].isHold;
          let launchForCode =
            tableMeta.tableData[tableMeta.rowIndex].launchForCode;
          let programId = tableMeta.tableData[tableMeta.rowIndex].programId;
          let courseId = tableMeta.tableData[tableMeta.rowIndex].courseId;
          let canHold = tableMeta.tableData[tableMeta.rowIndex].canHold;
          let color = isActive ? "green" : "red";
          let activate = isActive ? "Active" : "Inactive";
          let hold1 = hold ? "fa fa-play" : "fa fa-pause";
          let color1 = hold ? "green" : "red";
          let status = hold ? "Release" : "Hold";
          // let launched = tableMeta.tableData[tableMeta.rowIndex].launched;
          // const courseCount =
          //   tableMeta.tableData[tableMeta.rowIndex].courceCount;
          return (
            <div style={{ width: "100px" }}>
              {userAndRoleWiseData?.isView && (
                <>
                  <Tooltip title="View">
                    <a
                      className="mr-2"
                      style={{ fontSize: "15px" }}
                      onClick={(e) => {
                        e.preventDefault();

                        if (launchForCode === "COURSE") {
                          navigate("/Courses", {
                            state: {
                              courseId: courseId,
                              launchHubId: id,
                              isDisable: true,
                              from: "/LaunchHub",
                            },
                          });
                        } else if (launchForCode === "PROGRAM") {
                          navigate("/journey", {
                            state: {
                              id: programId,
                              launchHubId: id,
                              isDisable: true,
                              isLaunchHub: true,
                              from: "/LaunchHub",
                            },
                          });
                        }
                      }}>
                      <i className="fas fa-eye"></i>
                    </a>
                  </Tooltip>

                  <Tooltip title={`Assessment & Feedback`}>
                    <a
                      className="mr-2"
                      style={{ fontSize: "15px" }}
                      onClick={() => {
                        handleAssessmenrAndFeedback(launchForCode, id);
                      }}>
                      <i className="fas fa-address-book"></i>
                    </a>
                  </Tooltip>

                  <Tooltip title={`Reminder Mail`}>
                    <a
                      className="mr-2"
                      style={{ fontSize: "15px" }}
                      onClick={() => {
                        setShowReminderMailModel(true);
                        setNewLaunchHubId(id);
                      }}>
                      <i className="fas fa-envelope"></i>
                    </a>
                  </Tooltip>
                </>
              )}

              {isActive && userAndRoleWiseData?.isEdit && (
                <Tooltip title={status}>
                  <a
                    className="mr-2"
                    style={{ fontSize: "15px" }}
                    onClick={() => {
                      holduserbyIdmodal(id, !hold);
                    }}>
                    <i className={hold1} style={{ color: color1 }}></i>
                  </a>
                </Tooltip>
              )}

              {hold === 1 && userAndRoleWiseData?.isEdit && (
                <Tooltip title={activate}>
                  <a
                    className="mr-2"
                    style={{ fontSize: "15px" }}
                    onClick={() => {
                      showActivateandDeactivateModal(id, isActive);
                    }}>
                    <i
                      className="fa-solid fa-circle"
                      style={{ color: color }}></i>
                  </a>
                </Tooltip>
              )}
            </div>
          );
        },
      },
    },
  ];

  const handleAssessmenrAndFeedback = (launchForCode, launchHubId) => {
    setShowAssessmentandFeedbackModal(true);
    setNewLaunchForCode(launchForCode);
    setNewLaunchHubId(launchHubId);

    // if (launchForCode === "PROGRAM") {
    //   setIsCourseMandatory(true);
    // } else {
    //   setIsCourseMandatory(false);
    // }
  };

  const onSubmitFilter = () => {
    if (
      journeyId?.length === 0 &&
      courseId?.length === 0 &&
      createdBy?.length === 0 &&
      launchCode?.length === 0 &&
      status === null
    ) {
      notify(
        1,
        "Please select at least one filter option  to perform the search action."
      );
    } else {
      setPage(0);
      setStart(0);
      setreSet(!reSet);
    }
  };

  const resetFilters = () => {
    setSearchText("");
    setJourneyId([]);
    setCourseId([]);
    setCreatedBy([]);
    setStatus(null);
    setLaunchCode(null);

    setreSet(!reSet);
  };

  const holduserbyIdmodal = async (id: any, hold: any) => {
    sethodeModal(true);
    if (!hold) {
      setModalTitle("Do you want to release the Launch?");
    } else {
      setModalTitle("Do you want to hold the Launch?");
    }
    setId(id);
    setIshold(hold);
  };

  const showActivateandDeactivateModal = async (id: any, isActive: any) => {
    setShowModal(true);
    setId(id);
    if (isActive) {
      setModalTitle("Do you want to Inactive the Launch?");
    } else {
      setModalTitle("Do you want to active the Launch?");
    }
    setIsActive(isActive);
  };

  const holduserbyId = async (id: any, hold: any) => {
    showLoader();
    if (remarks === "" || remarks === null) {
      notify(1, "Please enter remarks");
    } else {
      const { status } = await APICall(updateLaunchHubActionById, "POST", {
        Id: id,
        Mode: 1,
        Action: hold,
        Remarks: remarks,
        UserId: userDetails?.Id,
      });
      sethodeModal(false);

      setRemarks("");

      if (status === 0) {
        if (!hold) {
          notify(0, "Launch released Successfully");
        } else {
          notify(0, "Launch hold Successfully");
        }
        setreSet(!reSet);
        hideLoader();
      } else {
        notify(1, "Something Went Wrong");
      }
    }

    hideLoader();
  };

  const activateandDeactivateFunction = async (id: any, isActive: any) => {
    if (remarks === "" || remarks === null) {
      notify(1, "Please enter remarks");
    } else {
      showLoader();
      const { status } = await APICall(updateLaunchHubActionById, "POST", {
        Id: id,
        Mode: 2,
        Action: !isActive,
        Remarks: remarks,
        UserId: userDetails?.Id,
      });

      setShowModal(false);
      setRemarks("");
      if (status === 0) {
        if (!isActive) {
          notify(0, "Launch has been successfully Activated");
        } else {
          notify(0, "Launch has been successfully Deactivated");
        }
        setreSet(!reSet);
        hideLoader();
      } else {
        notify(1, "Something went wrong");
      }
      hideLoader();
    }
  };

  return (
    <>
      <div className="breadcrumb modified-bredcrumb">
        <span>
          <ul>
            <li>
              <a href="/home">Home</a>
            </li>

            <li>Launch Hub</li>
          </ul>
        </span>
      </div>
      <div className="container-fluid">
        <div className="grid-wrapper grid_mob">
          <div className="row mx-auto filtBox">
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="form-group filtInp">
                <InputForm
                  value={searchText}
                  placeholder={"Search Keywords"}
                  isDisabled={false}
                  textArea={false}
                  onChange={(e) => {
                    setStart(0);
                    setPage(0);
                    setSearchText(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-12 filtrt">
              <ul className=" filter-icon-wel">
                <li>
                  <a
                    href={null}
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                    className="filter-btn">
                    <i className="fa fa-filter"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <>
          <Collapse in={open}>
            <div id="example-collapse-text grid-wrapper">
              <div className="row mx-auto myInlineStyle">
                <>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">
                      Launch Hub ID
                    </label>
                    <SelectForm
                      isClearable
                      options={launchCodeOptions}
                      placeholder={"Select Launch Hub ID"}
                      isDisabled={false}
                      onChange={(event) => {
                        setLaunchCode(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={launchCode}
                    />
                  </div>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">Journey</label>
                    <SelectForm
                      isClearable
                      options={journeyOptions}
                      placeholder={"Select Journey"}
                      isDisabled={false}
                      onChange={(event) => {
                        setJourneyId(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={journeyId}
                    />
                  </div>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">Course</label>
                    <SelectForm
                      isClearable
                      options={courseOptions}
                      placeholder={"Select Course"}
                      isDisabled={false}
                      onChange={(event) => {
                        setCourseId(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={courseId}
                    />
                  </div>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">
                      Created By
                    </label>
                    <SelectForm
                      isClearable
                      options={createdByOptions}
                      placeholder={"Created By"}
                      isDisabled={false}
                      onChange={(event) => {
                        setCreatedBy(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={createdBy}
                    />
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">Status</label>
                    <SelectForm
                      isClearable
                      options={statusOptions}
                      placeholder={"Status"}
                      isDisabled={false}
                      onChange={(event) => {
                        setStatus(event);
                      }}
                      isMulti={false}
                      noIndicator={false}
                      noSeparator={false}
                      value={status}
                    />
                  </div>
                </>
                <div className="col-lg-12 d-flex mt-2 justify-content-end">
                  <button
                    onClick={() => resetFilters()}
                    className="btn btn-secondary mr-2">
                    <i className="fa fa-undo" aria-hidden="true"></i> Reset{" "}
                  </button>
                  <button
                    className="btn btn-filter-submit"
                    onClick={() => onSubmitFilter()}>
                    <i className="fa fa-search" aria-hidden="true"></i> Search{" "}
                  </button>
                </div>
              </div>
            </div>
          </Collapse>
        </>
        <div className="mb-3 pt-3">
          <DynamicGrid data={rowData} columns={gridColumns} options={options} />
        </div>
      </div>

      <Modal
        show={hodeModal}
        onHide={() => sethodeModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">{`${modalTitle}`}</div>
        </div>
        <Modal.Body>
          <>
            <div className="row">
              <div className="col-md-8">
                <label className="col-form-label">Remarks</label>
                <sup>*</sup>
                <InputForm
                  value={remarks}
                  placeholder={"Remarks"}
                  isDisabled={false}
                  textArea={true}
                  onChange={(e) => {
                    setRemarks(e.target.value);
                  }}
                />
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => holduserbyId(Id, ishold)}>
            Submit
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              sethodeModal(false);
            }}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">{`${modalTitle}`}</div>
        </div>
        <Modal.Body>
          <>
            <div className="row">
              {/* <div className="col-md-12">
                <b>{`${modalTitle}`}</b>
              </div> */}
              <div className="col-md-8">
                <label className="col-form-label">Remarks</label>
                <sup>*</sup>
                <InputForm
                  value={remarks}
                  placeholder={"Remarks"}
                  isDisabled={false}
                  textArea={true}
                  onChange={(e) => {
                    setRemarks(e.target.value);
                  }}
                />
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => activateandDeactivateFunction(Id, IsActive)}>
            Submit
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowModal(false);
            }}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      <AssessmantAndFeedback
        showAssessmentandFeedbackModal={showAssessmentandFeedbackModal}
        setShowAssessmentandFeedbackModal={setShowAssessmentandFeedbackModal}
        newLaunchHubId={newLaunchHubId}
        isCourseMandatory={isCourseMandatory}
      />

      <ReminderMail
        showReminderMailModel={showReminderMailModel}
        setShowReminderMailModel={setShowReminderMailModel}
        newLaunchHubId={newLaunchHubId}
      />
    </>
  );
};

export default LaunchHub;
