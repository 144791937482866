import React, { useCallback, useContext, useEffect, useState } from "react";
import { LoaderContext } from "../../Helpers/Context/Context";
import { useLocation, useNavigate } from "react-router-dom";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { APICall } from "../../Helpers/APICalls";
import {
  getAssessmentDataById,
  getAssessmentHeader,
  manageUserAssessment,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import { Button, Card } from "react-bootstrap";
import AssessmentQuestion from "./AssessmentQuestion";
import notify from "../../Helpers/ToastNotification";

const Assessment = () => {
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { state } = useLocation();
  const navigate = useNavigate();
  let { userDetails, currentRoleId }: any = useUserContext();
  const [assessmentData, setAssessmentData] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [allQuestions, setAllQuestions] = useState([]);
  const [assessmentTime, setAssessmentTime] = useState(0);
  const [startAssessment, setStartAssessment] = useState(false);
  const [showTimer, setShowTimer] = useState(
    !state.preview && state.isUser ? true : false
  );
  const [actualAssessment, setActualAssessment] = useState(
    !state.preview && state.isUser ? true : false
  );
  const [canRevealAnswer, setCanRevealAnswer] = useState(false);

  const [questionsToAttempt, setQuestionsToAttempt] = useState([]);

  const [disableNextButton, setDisableNextButton] = useState(false);

  const [isAnswerAttemptedId, setIsAnswerAttemptedId] = useState(null);

  const handleQuestionChange = useCallback((question) => {
    setSelectedQuestion(question);
  }, []);

  useEffect(() => {
    if (
      selectedQuestion &&
      !selectedQuestion.isSkippable &&
      !selectedQuestion.userAnswer &&
      assessmentData?.isForwardOnly
    ) {
      setDisableNextButton(true);
    } else {
      setDisableNextButton(false);
    }
  }, [selectedQuestion]);

  const getUserAnswer = useCallback(async () => {
    const res = await APICall(getAssessmentDataById, "POST", {
      AssessmentId: state.assessmentId,
      Preview: state.preview,
      IsUser: state.isUser || false,
      Reattempt: false,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      UserAssessmentId: state.userAssessmentId,
    });
    if (res.data != null && res.status === 0) {
      setAllQuestions(res.data.questions);
      let qIndex = res.data.currentQuestionId;
      if (res.data.currentQuestionId > 0) {
        qIndex = res.data.questions.findIndex(
          (ques) => ques.questionId === res.data.currentQuestionId
        );
      }

      const currentQues = res.data.questions[qIndex];

      if (
        res.data.currentQuestionId === currentQues.questionId &&
        currentQues.userAnswer &&
        currentQues.userAnswer !== "" &&
        currentQues.isAttempted
      ) {
        setIsAnswerAttemptedId(currentQues.questionId);
      } else {
        setIsAnswerAttemptedId(null);
      }

      if (
        !currentQues.isSkippable &&
        !currentQues.userAnswer &&
        assessmentData?.isForwardOnly
      ) {
        setDisableNextButton(true);
      } else {
        setDisableNextButton(false);
      }
    }
  }, []);

  useEffect(() => {
    const getAssessmentData = async () => {
      showLoader();
      const res = await APICall(getAssessmentDataById, "POST", {
        AssessmentId: state.assessmentId,
        Preview: state.preview,
        IsUser: state.isUser || false,
        Reattempt: false,
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
        UserAssessmentId: state.userAssessmentId,
      });
      if (res.data != null && res.status === 0) {
        setAssessmentData(res.data);
        setAllQuestions(res.data.questions);
        let qIndex = res.data.currentQuestionId;
        if (res.data.currentQuestionId > 0) {
          qIndex = res.data.questions.findIndex(
            (ques) => ques.questionId === res.data.currentQuestionId
          );
        }
        handleQuestionChange(res.data.questions[qIndex]);
      } else {
        setAssessmentData(null);
      }

      if (state.isUser) {
        const res = await APICall(getAssessmentHeader, "POST", {
          AssessmentId: state.assessmentId,
          UserAssessmentId: state.userAssessmentId,
          UserId: userDetails?.Id,
        });
        if (res.data != null && res.status === 0) {
          setAssessmentData(res.data);

          setCanRevealAnswer(
            state.preview && state.isUser && res.data.canReveal ? true : false
          );

          if (
            actualAssessment &&
            (res.data.pendingTime === null || res.data.pendingTime === 0) &&
            res.assessmentTime !== 0
          ) {
            // start assessment fresh
            const res1 = await APICall(manageUserAssessment, "POST", {
              Mode: "START",
              AssessmentId: state.assessmentId,
              UserAssessmentId: state.userAssessmentId,
              UserId: userDetails?.Id,
              RoleId: currentRoleId?.value,
            });
            setStartAssessment(true);
            setAssessmentTime(res.data.assessmentTime);
          } else if (
            res.data.assessmentTime !== null &&
            res.data.pendingTime === 0
          ) {
            // submit assessment
            setStartAssessment(true);
          } else if (
            res.data.pendingTime !== null ||
            res.data.pendingTime !== 0
          ) {
            // resume assessment
            setStartAssessment(true);
            setAssessmentTime(res.data.pendingTime);
          } else if (
            res.data.assessmentTime === 0 &&
            res.data.pendingTime === 0
          ) {
            //  no timer
            setShowTimer(false);
          }
        }
      }
      hideLoader();
    };

    getAssessmentData();
  }, []);

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    if (hours === 0) {
      return `${minutes} min ${seconds} sec`;
    }
    return `${hours} hr ${minutes} min ${seconds} sec`;
  };

  useEffect(() => {
    if (showTimer) {
      let interval;
      if (startAssessment && assessmentTime > 0) {
        interval = setInterval(() => {
          setAssessmentTime((prevTime) => prevTime - 1);
        }, 1000);
      }

      if (actualAssessment && startAssessment && assessmentTime === 0) {
        // submit assessment
        setStartAssessment(false);
        clearInterval(interval);
        notify(
          1,
          "Your current attempt for assessment time has ended! Try your luck next time."
        );
        handleSubmit(true);
      }
      return () => clearInterval(interval);
    }
  }, [startAssessment, assessmentTime]);

  useEffect(() => {
    // check if user has given answer to non-skippable questions
    if (allQuestions.length > 0) {
      let questionsToAttempt = [];
      allQuestions.map((ques) => {
        if (!ques.isSkippable && !ques.userAnswer) {
          questionsToAttempt.push(ques.questionSequence);
        }
      });

      setQuestionsToAttempt(questionsToAttempt);
    }
  }, [allQuestions]);

  const handleSubmit = async (forced) => {
    if (!forced) {
      if (questionsToAttempt.length > 0 && !assessmentData?.isForwardOnly) {
        notify(
          1,
          `Required to attempt question number ${questionsToAttempt.join(
            ","
          )} before submitting`
        );
        return null;
      }
    }

    const res = await APICall(manageUserAssessment, "POST", {
      Mode: "SUBMIT",
      AssessmentId: state.assessmentId,
      UserAssessmentId: state.userAssessmentId,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    if (res.status === 0) {
      if (!forced) {
        notify(0, "Assessment Submited Successfully!");
      }
      navigate("/UserAssessments");
    } else {
      console.log("err", res);
      notify(1, "Someting went wrong!");
    }
  };

  if (assessmentData === null) {
    return <>"No Data found!"</>;
  }

  return (
    <>
      <div className="breadcrumb modified-bredcrumb">
        <span>
          <ul>
            <li>
              <a href="/home">Home</a>
            </li>
            <li>
              {" "}
              <a
                href={
                  state.isUser ? "/userAssessments" : "/assessmentDashboard"
                }>
                {state.isUser ? "User Assessment" : "Assessment"}
              </a>
            </li>
            <li>{state.isUser ? "Assessment" : "Manage Assessment"}</li>
          </ul>
        </span>
        {!actualAssessment && (
          <button
            onClick={() => {
              if (!state.isUser) {
                navigate(-1);
                return null;
              }
              navigate("/userAssessments", {
                state: { from: state.from },
              });
            }}
            disabled={false}
            className="btn btn-secondary float-right">
            <i className="fa-solid fa-arrow-left"></i> Back
          </button>
        )}
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-9 col-sm-9 col-xs-9">
                <Card>
                  <Card.Header className="bg_dark_blue text-white learningcardHead">
                    Employee Assessment
                  </Card.Header>
                  <Card.Body>
                    <div className="col-lg-12">
                      <div className="row">
                        {/* <div className="col-lg-4">
                          <b className="wid_obj">
                            <div className="lable_width">Assessment Code</div> :
                          </b>{" "}
                          {assessmentData.assessmentCode}
                        </div> */}
                        <div className="col-lg-6">
                          <b className="wid_obj">
                            <div className="lable_width">Assessment Name</div> :
                          </b>{" "}
                          {assessmentData.assessmentName}
                        </div>

                        {state.isUser && (
                          <>
                            <div className="col-lg-6">
                              <b className="wid_obj">
                                <div className="lable_width">Total Marks</div> :
                              </b>{" "}
                              {assessmentData.totalMarks}
                            </div>

                            <div className="col-lg-6">
                              <b className="wid_obj">
                                <div className="lable_width">
                                  Assessment Date
                                </div>{" "}
                                :
                              </b>{" "}
                              {assessmentData.assessmentDate}
                            </div>

                            <div className="col-lg-6">
                              <b className="wid_obj">
                                <div className="lable_width">Passing</div> :
                              </b>{" "}
                              {assessmentData.passingScore}
                            </div>

                            <div className="col-lg-6">
                              <b className="wid_obj">
                                <div className="lable_width">Time Limit</div> :
                              </b>{" "}
                              {assessmentData.assessmentTime / 60} min
                            </div>
                          </>
                        )}
                      </div>
                      {actualAssessment && (
                        <div className="row d-flex justify-content-end align-items-center">
                          <b className="wid_obj">
                            <i className="far fa-clock"></i> Time left :{" "}
                          </b>
                          {formatTime(assessmentTime)}
                        </div>
                      )}
                      <AssessmentQuestion
                        selectedQuestion={selectedQuestion}
                        getUserAnswer={getUserAnswer}
                        canRevealAnswer={canRevealAnswer}
                      />
                      <div className="row my-3 p-2 rounded bg-light text-center d-flex justify-content-between align-items-center">
                        <button
                          disabled={
                            selectedQuestion.questionSequence === 1 ||
                            (actualAssessment &&
                              assessmentData &&
                              assessmentData?.isForwardOnly)
                          }
                          onClick={() => {
                            const prevQuestionSequence =
                              selectedQuestion.questionSequence - 1;

                            let prevQuestion = [...allQuestions];

                            prevQuestion = prevQuestion.filter(
                              (q) => q.questionSequence === prevQuestionSequence
                            )[0];
                            handleQuestionChange(prevQuestion);
                          }}
                          className="btn btn-primary">
                          Prev
                        </button>
                        <div>
                          {selectedQuestion.questionSequence} of{" "}
                          {allQuestions.length}
                        </div>
                        <button
                          disabled={
                            actualAssessment && disableNextButton
                              ? true
                              : selectedQuestion.questionSequence ===
                                allQuestions.length
                            //   ||
                            // (actualAssessment &&
                            // !selectedQuestion.isSkippable &&
                            // selectedQuestion.userAnswer
                            //   ? false
                            //   : actualAssessment &&
                            //     !selectedQuestion.isSkippable
                            //   ? true
                            //   : false)
                          }
                          onClick={() => {
                            const nextQuestionSequence =
                              selectedQuestion.questionSequence + 1;

                            let nextQuestion = [...allQuestions];

                            nextQuestion = nextQuestion.filter(
                              (q) => q.questionSequence === nextQuestionSequence
                            )[0];
                            handleQuestionChange(nextQuestion);
                          }}
                          className="btn btn-primary">
                          Next
                        </button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>

              <div className="col-lg-3 col-sm-3 col-xs-3">
                <Card>
                  <Card.Header className="bg_dark_blue text-white learningcardHead">
                    View All Questions
                  </Card.Header>
                  <Card.Body>
                    <div className="row">
                      {allQuestions.length > 0 &&
                        allQuestions.map((question, qIndex) => (
                          <Button
                            variant="Link"
                            key={qIndex}
                            disabled={
                              false
                              //   ||
                              // (actualAssessment &&
                              //   !selectedQuestion.isSkippable &&
                              //   selectedQuestion.userAnswer)
                              //   ? false
                              //   : true
                            }
                            onClick={() => {
                              if (
                                actualAssessment &&
                                assessmentData &&
                                assessmentData?.isForwardOnly
                              ) {
                                return null;
                              } else {
                                handleQuestionChange(question);
                              }
                            }}
                            className={
                              "rounded-circle text-center text-success bg-light mx-1"
                            }
                            style={{
                              width: "30px",
                              height: "30px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderWidth: "1px",
                              borderStyle: "solid",
                              borderColor:
                                question.isAttempted ||
                                isAnswerAttemptedId === question.questionId
                                  ? "orange"
                                  : "grey",
                            }}>
                            {question.questionSequence}
                          </Button>
                        ))}
                    </div>
                  </Card.Body>
                </Card>
              </div>

              <div className="col-lg-12 my-2 d-flex justify-content-end">
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: 5 }}
                  disabled={state?.preview || false}
                  onClick={() => {
                    //submit
                    handleSubmit(false);
                  }}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Assessment;
