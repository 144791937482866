import CryptoJS from "crypto-js";
import { logoutUserById, url } from "./APIEndPoints/EndPoints";
import useUserContext from "./ZustandStore/UserContextStore";

export function Encrypt(value: any) {
  const encryptedString = CryptoJS.AES.encrypt(value, "REACTJS").toString();
  return encryptedString;
}

export async function Decrypt(value: any) {
  const decryptedWordArray = CryptoJS.AES.decrypt(value, "REACTJS");
  const decryptedString = await decryptedWordArray.toString(CryptoJS.enc.Utf8);
  return decryptedString;
}

export async function APICall(
  apiUrl: any,
  methodType: any,
  inputParam: any,
  enableEncryption: boolean = true,
  userAuthToken: string = ""
) {
  if (enableEncryption) {
    try {
      let response = await fetch(apiUrl, {
        method: methodType,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "*",
          // Authorization: "Bearer " + userAuthToken,
        },
        body: JSON.stringify(inputParam),
      });
      const apiRes = await response.json();

      // if (apiRes.status === 4) {
      //   window.alert("Session expired, please login again");
      //   (async () => {
      //     const apiRes = await APICall(logoutUserById, "POST", {
      //       LoginId: useUserContext.getState().userDetails.LoginId,
      //     });
      //   })();
      //   setTimeout(() => {
      //     useUserContext.getState().deleteUserDetails();
      //     window.location.replace(`${window.location.origin}`);
      //   }, 3000);
      //   return null;
      // }

      return apiRes;
    } catch (error) {
      return JSON.stringify(error);
    }
  }
}

export async function APICallWithSignal(
  apiUrl: any,
  methodType: any,
  inputParam: any,
  enableEncryption: boolean = true,
  userAuthToken: string = "",
  signal: null
) {
  if (enableEncryption) {
    try {
      let response = await fetch(apiUrl, {
        method: methodType,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "*",
          // Authorization: "Bearer " + userAuthToken,
        },
        signal,
        body: JSON.stringify(inputParam),
      });

      return await response.json();
    } catch (error) {
      return JSON.stringify(error);
    }
  }
}

export async function APICallWithSignalV2({
  apiUrl = "",
  methodType = "",
  inputParam = {},
  enableEncryption = true,
  userAuthToken = "",
  signal = null,
}) {
  if (enableEncryption) {
    try {
      let response = await fetch(apiUrl, {
        method: methodType,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "*",
          // Authorization: "Bearer " + userAuthToken,
        },
        signal,
        body: JSON.stringify(inputParam),
      });

      return await response.json();
    } catch (error) {
      return JSON.stringify(error);
    }
  }
}
