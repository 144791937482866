import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import DynamicGrid from "../../../Components/DynamicGrid/DynamicGrid";
import InputForm from "../../../Components/InputForm/InputForm";
import SelectForm from "../../../Components/SelectForm/SelectForm";
import DateForm from "../../../Components/DateForm/DateForm";
import { APICall } from "../../../Helpers/APICalls";
import {
  GetCandidateDetails,
  insertRequestId,
  SubmitForStagesAndResponsiblity,
  getDropdowns,
  getStagesData,
  getEvaluatorFeedbackForCurrentStage,
  downloadDocument,
  deleteDocument,
  documentUpload,
  getDocuments,
  getCandidateDetailsById,
  getManpowerRequisitionDetailsById,
  updateNegoDetailsById,
  updateFileDetailsById,
  GetRolesURL,
  getTAInputData,
  GetConfigValueByKey,
  OfferLetterGeneratedDocDounload,
  getIJPCandidateDetails,
  GetCandidateDetailsByUserId,
  GetEvaluatorsForStage,
  GetCandidateOfferLetterByCandidateId,
  GetMRFApproversMappingByMRFIdURL,
  CheckUserAvailablityForMeeting,
  CancelMeetingByMeetingId,
  ScheduleMeetingCandidateAndEvaluaotrs,
  GetRecruitersByMRFId,
} from "../../../Helpers/APIEndPoints/EndPoints";
import { LoaderContext } from "../../../Helpers/Context/Context";
import uuid from "react-uuid";
import moment from "moment";
import useUserContext from "../../../Helpers/ZustandStore/UserContextStore";
import notify from "../../../Helpers/ToastNotification";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal, Table } from "react-bootstrap";
import FeedbackForm from "./FeedbackForm";
import InterviewQuestion from "./InterviewQuestion";
import FeedbackFormView from "./FeedbackFormView";
import fileDownload from "js-file-download";
import { base64ToArrayBuffer } from "../../../Helpers/Common";
import { Document, Page, Outline } from "react-pdf/dist/esm/entry.webpack";
import EmptyAccordian from "./EmptyAccordian";
import DateTimeComponent from "./DateTimeComponent";
import {
  validateScheduleDateTime,
  IDasboardColumns,
  StagesName,
  ICandidateDetailsOfferLetterInfo,
  CandidateWorkflowDecisions,
  CALENDAR_BLOCKING_OPTIONS,
} from "./rcm_helper";
import RTE from "../../../Components/RTE/RTE";
import CandidateForm from "../../AnonymousCandidate/Components/CandidateForm";
import SalaryNegotiation from "../../AnonymousCandidate/Components/SalaryNegotiation";
import { ConfirmationModal } from "./ApproverHelper";

const CANDIDATE_STAGES = {
  ResumeEvalution: 1,
  Recommendation: 2,
  RecommedationReview: 3,
  FirstInterview: 4,
  SecondInterview: 5,
  FinalInterview: 6,
  Negotiation: 7,
  BackgroundVerification: 7.1,
  ReferenceCheck: 7.2,
  PsychometricTest: 7.3,
  OfferApproval: 8,
  OfferLetterReview: 9,
  OfferAcceptance: 10,
  InitiateOnboarding: 11,
  Joining: 12,
};

const Stages = (props) => {
  const { userDetails, currentRoleId }: any = useUserContext();
  const { state } = useLocation();

  const [mrfId, setMrfId] = useState(state?.TId || 0);
  const [candidateId, setCandidateId] = useState(state?.CandidateId || 0);
  const [gridData, setGridData] = useState([]);
  const [typeofActivityOptions, setTypeofActivityOptions] = useState<any>([]);
  const [conductedByOptions, setConductedByOptions] = useState<any>([]);
  const [activityStatusOptions, setActivityStatusOptions] = useState<any>([]);
  const [decisionOptions, setDecisionOptions] = useState<any>([]);
  const [currentGridId, setCurrentGridId] = useState(null);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [ShowAttrSet, setShowAttrSet] = useState(false);
  const [isStatic, setIsStatic] = useState(true);
  const [currencyOption, setCurrencyOption] = useState([]);
  const [isView, setIsView] = useState(false);
  const [showInterviewQuestionsModal, setShowInterviewQuestionsModal] =
    useState(false);
  const [showFeedbackFormModal, setShowFeedbackFormModal] = useState(false);
  const [onNegotiationHideandShow, setOnNegotiationHideandShow] =
    useState(false);
  const [negoReadOnly, setNegoReadOnly] = useState(false);
  const [showOfferDocument, setShowOfferDocument] = useState(false);
  const [offerDocGeneratedLink, setOfferDocGeneratedLink] = useState(null);
  const [negoDiffNote, setNegoDiffNote] = useState([]);
  const [invalidTime, setInvalidTime] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [hideDecisionOptions, setHideDecisionOptions] = useState(false);
  const [feedBackUploadData, setfeedBackUploadData] = useState(null);
  const [employeeClassOptions, setEmployeeClassOptions] = useState([]);
  const [jobBandOptions, setJobBandOptions] = useState([]);
  const [jobLeveloptions, setJobLeveloptions] = useState([]);
  const [designationOptions, setDesignationOptions] = useState([]);
  const [formErrors, setFormErrors] = React.useState<any>({});
  const [attributeName, setAttributeName] = useState("");
  const [attributesData, setAttributesData] = React.useState<any>({
    id: "",
    candidateId: "",
    candidateName: "",
    candidateHistory: "",
    stage: "",
    typeofActivity: null,
    mode: null,
    responsiblePersons: null,
    scheduledDateandTime: null,
    scheduledEndDateandTime: null,
    conductedBy: null,
    decision: null,
    feedback: null,
    remarks: "",
    currency: "",
    currentCTC: 0,
    cTCExpectedByCandidate: 0,
    offeredCTC: 0,
    fixedPay: 0,
    variablePay: 0,
    noticePay: 0,
    relocationAllowance: 0,
    percentageHike: 0,
    noticePeriodDays: 0,
    dateofJoining: null,
    offerAcceptanceDueDate: null,
    previousConversation: "",
    joiningStatus: null,
    employeeClass: null,
    jobBand: null,
    jobLevel: null,
    designation: null,
    clientFacingTitle: "",
    source: "",
    outcome: null,
    candidateFeedback: null,
    status: null,
    activityStatus: null,
    calendarBlocking: null,
    guest: null,
    meetingDescription: "",
    meetingSubject: "",
    meetingId: "",
  });
  const [showFeedbackUpload, setShowFeedbackUpload] = useState(false);
  const [showViewFeedback, setShowViewFeedback] = useState(false);
  const [showFeedbackDropdown, setShowFeedbackDropdown] = useState(false);
  const [feedbackDropdownOptions, setFeedbackDropdownOptions] = useState([]);
  const [sourceName, setsourceName] = useState("");
  const [DMSURL, setDMSURL] = useState(null);
  const [offerDocReqId, setOfferDocReqId] = useState(null);
  const [offerDocCodeType, setOfferDocCodeType] = useState("OFR_WORLD");
  const [dashboardGridData, setDashboardGridData] = useState<
    IDasboardColumns[]
  >([]);
  const [showScheduleDateAndTime, setShowScheduleDateAndTime] = useState(false);
  const [disableScheduleDateTime, setDisableScheduleDateTime] = useState(false);
  const [isOpenModalPopup, setIsOpenModalPopup] = useState(false);
  const [mrfDetails, setmrfDetails] = useState({
    employeeClassId: null,
    jobBandId: null,
    jobLevelId: null,
    designationId: null,
    clientFacingTitle: "",
  });
  const [wholeOutComeOptions, setWholeOutComeOptions] = useState([]);
  const [roles, setRoles] = useState([]);
  const documentRef = useRef(null);
  const [selectedFilesForSet, setSelectedFilesForSet] = useState<
    {
      AC: string;
      Name: string;
      Data: string;
      IsValidFile: boolean;
      DocId: Number;
    }[]
  >([]);
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);
  const [cwtId, setCwtId] = useState(0);
  const [disableEvaluatorOptions, setDisableEvaluatorOptions] = useState(true);
  const [isEvaluationTask, setIsEvaluatorByWorkflowTask] = useState(
    state?.isEvaluator ? true : false
  );
  const [isEdit, setIsEdit] = useState(true);
  const [showNegoConfirmationModal, setShowNegoConfirmationModal] =
    useState(false);
  const [employeeId, setEmployeeId] = useState(0);
  const [ijpData, setIjpData] = useState([]);
  const navigate = useNavigate();
  const [tempFeedbackModelDetails, setFeedbackModelDetails] = useState<any>({});
  const [model, setModel] = useState(false);
  const [isOnce, setIsOnce] = useState(false);
  const [isWorkflowTaskCompleted, setIsWorkflowTaskCompleted] = useState(false);
  const [candidateOfferLetterDetails, setCandidateOfferLetterDetails] =
    useState<ICandidateDetailsOfferLetterInfo>({});
  const [hiringManagerDetails, setHiringManagerDetails] = useState<any[]>([]);
  const [disableHiringManager, setDisableHiringManager] = useState(true);
  const [offerLetterModelOpen, setOfferLetterModelOpen] =
    useState<boolean>(false);
  const [hideHiringManager, setHideHiringManager] = useState<boolean>(true);
  const [cancelMeetingModal, setCancelMeetingModal] = useState(false);
  const [timeSlotData, setTimeSlotData] = useState([]);
  const [isInterviewStage, setIsInterviewStage] = useState(false);
  const [decisionSystemName, setDecisionSystemName] = useState("");
  const [isRecruiterForCurrentMRF, setIsRecruiterForCurrentMRF] =
    useState(false);
  const [haveSalaryDocument, setSalaryDocumentData] = useState(false);
  const [salaryNotificationModal, setSalaryNotificationModal] = useState(false);
  const [negotiationId, setNegotiationId] = useState(0);

  useEffect(() => {
    /**
     *  since no api call depends on each other,
     *  so they don't have to wait next first api call to get execute,
     *  they can execute all in pararell.
     */
    const fetchData = async () => {
      await Promise.all([
        getDocConfigUrl(),
        getDropdownsDataForTypeofActivityApiCall("SARACT", ""),
        getDropdownsDataForCurrencyAPICall("BNKCURR", ""),
        getDropdownsDataForConductedByApiCall(),
        getDropdownsDataForEmployeeClassByApiCall("POSEMPCLS", ""),
        GetRolesAsync(),
        getManpowerRequisitionDetailsByIdAPICall(),
        GetCandidateOfferLetterForAcceptanceStage(),
        GetMRFApproversMappingByMRFId(),
      ]);

      setIsEdit(state?.isWorkflowTask ? state?.type === "edit" : true);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const helper = async () => {
      await getCandidateDetailsByIdAPICall();
    };

    helper();
  }, [DMSURL]);

  /**
   *
   */
  useEffect(() => {
    const helper = async () => {
      if (state?.CandidateId > 0) {
        await fetchCandidateCandidateWorkflowInstanceByCandidateId();
      }
      if (state?.type == "edit") {
        setIsWorkflowTaskCompleted(false);
      } else {
        setIsWorkflowTaskCompleted(true);
      }
    };
    helper();
  }, []);

  /**
   * Reseting Candidate Feedback
   */
  useEffect(() => {
    if (showFeedbackDropdown === false) {
      setAttributesData((prev) => ({ ...prev, candidateFeedback: null }));
    }
  }, [showFeedbackDropdown]);

  const defaultAttributesData = {
    id: currentGridId,
    stage: 0,
    typeofActivity: null,
    responsiblePersons: null,
    decision: null,
    candidateId: state.CandidateId,
    candidateName: "",
    source: sourceName,
    scheduledDateandTime: null,
    scheduledEndDateandTime: null,
    calendarBlocking: null,
    guest: null,
    meetingDescription: "",
    remarks: "",
    currency: null,
    currentCTC: 0,
    cTCExpectedByCandidate: 0,
    offeredCTC: 0,
    fixedPay: 0,
    variablePay: 0,
    noticePay: 0,
    relocationAllowance: 0,
    percentageHike: 0,
    noticePeriodDays: 0,
    dateofJoining: null,
    offerAcceptanceDueDate: null,
    previousConversation: "",
    joiningStatus: null,
    employeeClass: null,
    jobBand: null,
    jobLevel: null,
    designation: null,
    clientFacingTitle: "",
    activityStatus: null,
    candidateFeedback: null,
    meetingSubject: "",
    meetingId: "",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!currentGridId) {
          setAttributesData(defaultAttributesData);
          setShowAttrSet(false);
          return;
        }
        let filterData = dashboardGridData?.find((i) => i?.Id == currentGridId);
        if (!filterData) return;

        //? Creating a deep copy,which won't affect dashboardGridData;
        if (filterData) filterData = JSON.parse(JSON.stringify(filterData));
        const {
          Stage,
          RecruiterIds,
          isCurrentUserTaskPendingForCurrentStage,
          Evaluators,
          ResponsiblePerson,
          activityStatusOptions,
          DecisionOptions,
          filterDecisionOptions,
          feedbackOptions,
          activityStatusSystemName,
          activityStatusName,
          IsInterviewStage,
          isPendingSchedule,
          isRescheduled,
          isDecisionPending,
          RecruiterRoleId,
          isEvaluator,
          currentUserRoleIdIsRecruiter,
          isNegotiationStage,
          isOfferReviewStage,
          checkIsRecruiterForThisMRF,
          isEvaluationPendingForThisStage,
          isOfferAcceptanceStage,
          CalendarBlock,
          Guest,
          DecisionSystemName,
        } = extractFilterData(filterData);
        setIsInterviewStage(IsInterviewStage);
        setWholeOutComeOptions(DecisionOptions);
        setActivityStatusOptions(activityStatusOptions);
        setDecisionOptions(filterDecisionOptions);
        setFeedbackDropdownOptions(feedbackOptions);
        setHideDecisionOptions(isPendingSchedule);
        setShowScheduleDateAndTime(IsInterviewStage);
        setDecisionSystemName(DecisionSystemName);
        setDisableScheduleDateTime(
          !(
            isPendingSchedule &&
            currentUserRoleIdIsRecruiter &&
            isCurrentUserTaskPendingForCurrentStage
          )
        );
        setShowOfferDocument(isOfferReviewStage);
        setOnNegotiationHideandShow(isNegotiationStage);

        /**
         * Setting Date According to different stages.
         */
        setCandidateOfferLetterDetails((prev) => {
          return { ...prev, showDocument: isOfferAcceptanceStage };
        });

        if (Number(Stage) === CANDIDATE_STAGES.Recommendation) {
          if (
            currentRoleId?.value == RecruiterRoleId &&
            checkIsRecruiterForThisMRF &&
            isCurrentUserTaskPendingForCurrentStage
          ) {
            setDisabledSubmitBtn(false);
            setDisableHiringManager(false);
          }
          setHideHiringManager(false);
          setNegotiationId(0);
        } else if (IsInterviewStage) {
          /*
              if current stage is pendingschedule and if user is recruiter for this 
              mrf with recruiterRoleid 
              then he/she can edit stages.
          */
          setHideHiringManager(true);
          setNegotiationId(0);
          setDisableHiringManager(true);
          if (isPendingSchedule) {
            setShowFeedbackUpload(false);
            setHideDecisionOptions(true);
            setShowViewFeedback(false);
            if (
              currentRoleId?.value == RecruiterRoleId &&
              checkIsRecruiterForThisMRF &&
              isCurrentUserTaskPendingForCurrentStage
            ) {
              setDisabledSubmitBtn(false);
              setDisableEvaluatorOptions(false);
            } else {
              setDisabledSubmitBtn(true);
              setDisableEvaluatorOptions(true);
            }
          } else {
            if (isEvaluator && isEvaluationTask) {
              //setShowFeedbackUpload(true);
              //setShowViewFeedback(false);
              setHideDecisionOptions(true);
              setDisabledSubmitBtn(true);

              if (isEvaluationPendingForThisStage && isEvaluationTask) {
                setShowFeedbackUpload(true);
                setShowViewFeedback(false);
              } else {
                setShowFeedbackUpload(false);
                setShowViewFeedback(true);
              }

              if (isRescheduled) {
                setShowFeedbackUpload(false);
                setShowViewFeedback(false);
              }
            } else {
              setShowFeedbackUpload(false);
              setShowViewFeedback(true);
              setHideDecisionOptions(false);
              setDisabledSubmitBtn(false);

              if (
                isCurrentUserTaskPendingForCurrentStage &&
                currentRoleId?.value == RecruiterRoleId &&
                checkIsRecruiterForThisMRF &&
                isDecisionPending &&
                !isEvaluationTask
              ) {
              }
            }
          }

          if (
            isCurrentUserTaskPendingForCurrentStage &&
            currentRoleId?.value == RecruiterRoleId &&
            checkIsRecruiterForThisMRF &&
            isDecisionPending &&
            !isEvaluationTask
          ) {
            setDisableEvaluatorOptions(false);
          }
        } else if (isNegotiationStage) {
          setHideHiringManager(true);
          setDisableHiringManager(true);
          const negoIds = dashboardGridData
            .map((data) => {
              if (data.StaticStagesSystemName === "Negotiation") {
                return data.Id;
              }
            })
            .filter((data) => data !== undefined);
          const sortedArr = negoIds.sort((a, b) => b - a);
          const latestNegoId =
            sortedArr.length === 1 ? sortedArr[0] : sortedArr[1];

          if (latestNegoId) {
            const negoData = dashboardGridData?.find(
              (i) => i.Id == latestNegoId
            );

            if (negoData !== undefined) {
              filterData.CurrencyId = negoData.CurrencyId;
              filterData.CurrentCTC = handleConvertInt(negoData.CurrentCTC);
              filterData.CTCExpectedbyCandidate = handleConvertInt(
                negoData.CTCExpectedbyCandidate
              );
              filterData.OfferedCTC = handleConvertInt(negoData.OfferedCTC);
              filterData.FixedPay = handleConvertInt(negoData.FixedPay);
              filterData.VariablePay = handleConvertInt(negoData.VariablePay);
              filterData.NoticePay = handleConvertInt(negoData.NoticePay);
              filterData.RelocationAllowance = handleConvertInt(
                negoData.RelocationAllowance
              );
              filterData.PercentageHike = handleConvertInt(
                negoData.PercentageHike
              );
              filterData.noticePeriodDays = handleConvertInt(
                negoData.noticePeriodDays
              );
              filterData.DateofJoining = moment(
                negoData.DateofJoining
              )?.isValid()
                ? moment(negoData.DateofJoining).format("DD-MMM-YYYY")
                : null;
              filterData.OfferAcceptanceDueDate =
                negoData.OfferAcceptanceDueDate;
              filterData.EmployeeClassId =
                sortedArr.length === 1
                  ? mrfDetails.employeeClassId
                  : negoData.EmployeeClassId;
              filterData.JobBandId =
                sortedArr.length === 1
                  ? mrfDetails.jobBandId
                  : negoData.JobBandId;
              filterData.JobLevelId =
                sortedArr.length === 1
                  ? mrfDetails.jobLevelId
                  : negoData.JobLevelId;
              filterData.DesignationId =
                sortedArr.length === 1
                  ? mrfDetails.designationId
                  : negoData.DesignationId;
              filterData.ClientFacingTitle =
                sortedArr.length === 1
                  ? mrfDetails.clientFacingTitle
                  : negoData.ClientFacingTitle;
            }

            // contruct diff for note

            const diff = [];

            if (mrfDetails.employeeClassId != filterData.EmployeeClassId) {
              const oldData = employeeClassOptions?.find(
                (val) => val?.value === mrfDetails.employeeClassId
              );

              const newData = employeeClassOptions?.find(
                (val) => val?.value === filterData.EmployeeClassId
              );

              diff.push({ empClass: { oldData, newData } });
            } else {
              const oldData = employeeClassOptions?.find(
                (val) => val?.value === mrfDetails.employeeClassId
              );
              diff.push({ empClass: { oldData, newData: null } });
            }

            if (mrfDetails.jobBandId != filterData.JobBandId) {
              let jobBandOPT = await getDropdownsDataForJobBandByApiCall(
                "MRJBBAND",
                mrfDetails.employeeClassId
              );

              const oldData = jobBandOPT?.find(
                (val) => val?.value === mrfDetails.jobBandId
              );

              jobBandOPT = await getDropdownsDataForJobBandByApiCall(
                "MRJBBAND",
                filterData.EmployeeClassId
              );

              const newData = jobBandOPT?.find(
                (val) => val?.value === filterData.JobBandId
              );

              diff.push({ jobBand: { oldData, newData } });
            } else {
              let jobBandOPT = await getDropdownsDataForJobBandByApiCall(
                "MRJBBAND",
                mrfDetails.employeeClassId
              );

              const oldData = jobBandOPT?.find(
                (val) => val?.value === mrfDetails.jobBandId
              );
              diff.push({ jobBand: { oldData, newData: null } });
            }

            if (mrfDetails.jobLevelId != filterData.JobLevelId) {
              let jobLevelOPT = await getDropdownsDataForJobLevelByApiCall(
                "MRJBLVL",
                mrfDetails.jobBandId
              );

              const oldData = jobLevelOPT?.find(
                (val) => val?.value === mrfDetails.jobLevelId
              );

              jobLevelOPT = await getDropdownsDataForJobLevelByApiCall(
                "MRJBLVL",
                filterData.JobBandId
              );

              const newData = jobLevelOPT?.find(
                (val) => val?.value === filterData.JobLevelId
              );

              diff.push({ jobLevel: { oldData, newData } });
            } else {
              let jobLevelOPT = await getDropdownsDataForJobLevelByApiCall(
                "MRJBLVL",
                mrfDetails.jobBandId
              );

              const oldData = jobLevelOPT?.find(
                (val) => val?.value === mrfDetails.jobLevelId
              );
              diff.push({ jobLevel: { oldData, newData: null } });
            }

            if (mrfDetails.designationId != filterData.DesignationId) {
              let designationOPT =
                await getDropdownsDataForDesignationByApiCall(
                  "POSDESGN",
                  mrfDetails.jobLevelId
                );
              const oldData = designationOPT?.find(
                (val) => val?.value === mrfDetails.designationId
              );

              designationOPT = await getDropdownsDataForDesignationByApiCall(
                "POSDESGN",
                filterData.JobLevelId
              );

              const newData = designationOPT?.find(
                (val) => val?.value === filterData.DesignationId
              );

              diff.push({ designation: { oldData, newData } });
            } else {
              let designationOPT =
                await getDropdownsDataForDesignationByApiCall(
                  "POSDESGN",
                  mrfDetails.jobLevelId
                );
              const oldData = designationOPT?.find(
                (val) => val?.value === mrfDetails.designationId
              );

              diff.push({ designation: { oldData, newData: null } });
            }

            if (mrfDetails.clientFacingTitle != filterData.ClientFacingTitle) {
              diff.push({
                clientFT: {
                  oldData: mrfDetails.clientFacingTitle,
                  newData: filterData.ClientFacingTitle,
                },
              });
            } else {
              diff.push({
                clientFT: {
                  oldData: mrfDetails.clientFacingTitle,
                  newData: null,
                },
              });
            }
            setNegoDiffNote(diff);
          }
          setOnNegotiationHideandShow(true);
          setShowFeedbackUpload(false);
          setNegotiationId(currentGridId);
        } else if (CANDIDATE_STAGES.OfferApproval === Number(Stage)) {
          setHideHiringManager(true);
          setDisableHiringManager(true);
          const negoIds = dashboardGridData
            .map((data) => {
              if (data.StaticStagesSystemName == "Negotiation") {
                return data.Id;
              }
            })
            .filter((data) => data !== undefined);

          const latestNegoId = Math.max(...negoIds);
          const negoData = dashboardGridData?.find((i) => i.Id == latestNegoId);

          if (negoData !== undefined) {
            filterData.CurrencyId = negoData.CurrencyId;
            filterData.CurrentCTC = handleConvertInt(negoData.CurrentCTC);
            filterData.CTCExpectedbyCandidate = handleConvertInt(
              negoData.CTCExpectedbyCandidate
            );
            filterData.OfferedCTC = handleConvertInt(negoData.OfferedCTC);
            filterData.FixedPay = handleConvertInt(negoData.FixedPay);
            filterData.VariablePay = handleConvertInt(negoData.VariablePay);
            filterData.NoticePay = handleConvertInt(negoData.NoticePay);
            filterData.RelocationAllowance = handleConvertInt(
              negoData.RelocationAllowance
            );
            filterData.PercentageHike = handleConvertInt(
              negoData.PercentageHike
            );
            filterData.noticePeriodDays = handleConvertInt(
              negoData.noticePeriodDays
            );
            filterData.DateofJoining = moment(negoData.DateofJoining)?.isValid()
              ? moment(negoData.DateofJoining).format("DD-MMM-YYYY")
              : null;
            filterData.OfferAcceptanceDueDate = negoData.OfferAcceptanceDueDate;
            filterData.EmployeeClassId = negoData.EmployeeClassId;
            filterData.JobBandId = negoData.JobBandId;
            filterData.JobLevelId = negoData.JobBandId;
            filterData.JobLevelId = negoData.JobLevelId;
            filterData.DesignationId = negoData.DesignationId;
            filterData.ClientFacingTitle = negoData.ClientFacingTitle;
          }

          // contruct diff for note

          const diff = [];

          if (mrfDetails.employeeClassId != filterData.EmployeeClassId) {
            const oldData = employeeClassOptions?.find(
              (val) => val?.value === mrfDetails.employeeClassId
            );

            const newData = employeeClassOptions?.find(
              (val) => val?.value === filterData.EmployeeClassId
            );

            diff.push({ empClass: { oldData, newData } });
          }

          if (mrfDetails.jobBandId != filterData.JobBandId) {
            let jobBandOPT = await getDropdownsDataForJobBandByApiCall(
              "MRJBBAND",
              mrfDetails.employeeClassId
            );

            const oldData = jobBandOPT?.find(
              (val) => val?.value === mrfDetails.jobBandId
            );

            jobBandOPT = await getDropdownsDataForJobBandByApiCall(
              "MRJBBAND",
              filterData.EmployeeClassId
            );

            const newData = jobBandOPT?.find(
              (val) => val?.value === filterData.JobBandId
            );

            diff.push({ jobBand: { oldData, newData } });
          }

          if (mrfDetails.jobLevelId != filterData.JobLevelId) {
            let jobLevelOPT = await getDropdownsDataForJobLevelByApiCall(
              "MRJBLVL",
              mrfDetails.jobBandId
            );

            const oldData = jobLevelOPT?.find(
              (val) => val?.value === mrfDetails.jobLevelId
            );

            jobLevelOPT = await getDropdownsDataForJobLevelByApiCall(
              "MRJBLVL",
              filterData.JobBandId
            );

            const newData = jobLevelOPT?.find(
              (val) => val?.value === filterData.JobLevelId
            );

            diff.push({ jobLevel: { oldData, newData } });
          }

          if (mrfDetails.designationId != filterData.DesignationId) {
            let designationOPT = await getDropdownsDataForDesignationByApiCall(
              "POSDESGN",
              mrfDetails.jobLevelId
            );
            const oldData = designationOPT?.find(
              (val) => val?.value === mrfDetails.designationId
            );

            designationOPT = await getDropdownsDataForDesignationByApiCall(
              "POSDESGN",
              filterData.JobLevelId
            );

            const newData = designationOPT?.find(
              (val) => val?.value === filterData.DesignationId
            );

            diff.push({ designation: { oldData, newData } });
          }

          if (mrfDetails.clientFacingTitle != filterData.ClientFacingTitle) {
            diff.push({
              clientFT: {
                oldData: mrfDetails.clientFacingTitle,
                newData: filterData.ClientFacingTitle,
              },
            });
          }

          setOnNegotiationHideandShow(true);
          setNegoReadOnly(true);
          setNegoDiffNote(diff);
          setShowFeedbackUpload(false);
          setShowViewFeedback(false);

          const newNegoId = getNegoId(currentGridId);
          setNegotiationId(newNegoId);
        } else if (isOfferReviewStage) {
          setDisableHiringManager(true);
          getCandidateDetailsByIdAPICall();
          setNegotiationId(0);
        } else {
          setHideHiringManager(true);
          setShowScheduleDateAndTime(false);
          setOnNegotiationHideandShow(false);
          setNegoDiffNote([]);
          setShowOfferDocument(false);
          setShowFeedbackUpload(false);
          setShowViewFeedback(false);
          setDisableHiringManager(true);
          setNegotiationId(0);
        }

        if (
          filterData?.FeedbackUpload == "Mandatory" ||
          filterData?.FeedbackUpload == "Optional"
        ) {
          setShowFeedbackUpload(true);
        }

        const jobBandOPT = await getDropdownsDataForJobBandByApiCall(
          "MRJBBAND",
          filterData.EmployeeClassId
        );

        const jobLevelOPT = await getDropdownsDataForJobLevelByApiCall(
          "MRJBLVL",
          filterData.JobBandId
        );

        const designationOPT = await getDropdownsDataForDesignationByApiCall(
          "POSDESGN",
          filterData.JobLevelId
        );

        const mrfData = {
          employeeClass: employeeClassOptions?.find(
            (val) => val?.value === filterData.EmployeeClassId
          ),
          jobBand: jobBandOPT?.find(
            (val) => val?.value === filterData.JobBandId
          ),
          jobLevel: jobLevelOPT?.find(
            (val) => val?.value === filterData.JobLevelId
          ),
          designation: designationOPT?.find(
            (val) => val?.value === filterData.DesignationId
          ),
          currency: currencyOption.find(
            (val) => val.value === filterData.CurrencyId
          ),
        };

        if (
          !mrfData.currency ||
          mrfData.currency == null ||
          mrfData.currency == undefined ||
          Object.keys(mrfData.currency).length == 0
        ) {
          let INR = currencyOption?.find(
            (i) => String(i?.label).toLocaleUpperCase() === "INR"
          );
          mrfData.currency = INR;
        }

        /* Document Setting For Stage */
        let attributeNameForDocument = "";
        if (
          Stage == CANDIDATE_STAGES.BackgroundVerification ||
          Stage == CANDIDATE_STAGES.ReferenceCheck ||
          Stage == CANDIDATE_STAGES.PsychometricTest
        ) {
          if (Stage == CANDIDATE_STAGES.BackgroundVerification) {
            attributeNameForDocument = StagesName.BackgroungVerification;
            setAttributeName(StagesName.BackgroungVerification);
          } else if (Stage == CANDIDATE_STAGES.ReferenceCheck) {
            attributeNameForDocument = StagesName.RefrenceCheck;
            setAttributeName(StagesName.RefrenceCheck);
          } else if (Stage == CANDIDATE_STAGES.PsychometricTest) {
            attributeNameForDocument = StagesName.PsychometricTest;
            setAttributeName(StagesName.PsychometricTest);
          }
          await getDocumentsDetails(attributeNameForDocument);
        }

        if (
          [
            CANDIDATE_STAGES.InitiateOnboarding,
            CANDIDATE_STAGES.Joining,
          ].includes(Number(Stage))
        ) {
          const negoIds = dashboardGridData
            .map((data) => {
              if (data.StaticStagesSystemName == "Negotiation") {
                return data.Id;
              }
            })
            .filter((data) => data !== undefined);

          const latestNegoId = Math.max(...negoIds);
          const negoData = dashboardGridData?.find((i) => i.Id == latestNegoId);
          if (negoData != undefined) {
            filterData.DateofJoining = negoData.DateofJoining;
          }
        }

        const decisionObj: any = getDecisionObjectForUseEffect(
          DecisionOptions,
          activityStatusSystemName,
          activityStatusName
        );

        let obj = {
          id: currentGridId,
          stage: Number.isNaN(filterData.Stage) ? 0 : Number(filterData?.Stage),
          typeofActivity: { value: "", label: filterData?.ActivityName },
          responsiblePersons: ResponsiblePerson,
          decision: {
            value: decisionObj?.DecisionId || filterData?.DecisionId,
            label: decisionObj?.DecisionName || filterData?.DecisionName,
            code:
              decisionObj?.DecisionSystemName || filterData.DecisionSystemName,
          },
          candidateId: state.CandidateId,
          candidateName: filterData.CandidateName,
          source:
            String(sourceName).toLocaleLowerCase().trim() == "ijp"
              ? "WeRise"
              : sourceName,
          scheduledDateandTime: filterData?.ScheduledDate || null,
          meetingDescription: filterData?.MeetingDescription || "",
          scheduledEndDateandTime: filterData?.ScheduledEndDateandTime || null,
          guest: Guest || null,
          calendarBlocking: CalendarBlock,
          meetingSubject: filterData?.MeetingSubject || "",
          //meetingDescription: filterData?.MeetingDescription,
          remarks: filterData?.Remarks || "",
          currency: mrfData.currency || null,
          currentCTC: handleConvertInt(filterData.CurrentCTC),
          cTCExpectedByCandidate: handleConvertInt(
            filterData.CTCExpectedbyCandidate
          ),
          offeredCTC: handleConvertInt(filterData.OfferedCTC),
          fixedPay: handleConvertInt(filterData.FixedPay),
          variablePay: handleConvertInt(filterData.VariablePay),
          noticePay: handleConvertInt(filterData.NoticePay),
          relocationAllowance: handleConvertInt(filterData.RelocationAllowance),
          percentageHike: handleConvertInt(filterData.PercentageHike),
          noticePeriodDays: handleConvertInt(filterData.noticePeriodDays),
          dateofJoining: filterData.DateofJoining,
          offerAcceptanceDueDate: filterData.OfferAcceptanceDueDate || null,
          previousConversation: "",
          joiningStatus: null,
          employeeClass: mrfData.employeeClass || null,
          jobBand: mrfData.jobBand || null,
          jobLevel: mrfData.jobLevel || null,
          designation: mrfData.designation || null,
          clientFacingTitle: filterData?.ClientFacingTitle || "",
          activityStatus: {
            label: filterData?.ActivityStatusName,
            value: filterData?.ActivityStatusId,
            code: filterData?.ActivityStatusSystemName,
          },
          candidateFeedback:
            feedbackOptions?.find(
              (i) => i?.value == filterData?.CandidateFeedbackId
            ) || null,
          meetingId: filterData?.MeetingId,
        };

        setAttributesData(obj);
        if (props?.isMRFAdmin) {
          setDisabledSubmitBtn(true);
          setDisableEvaluatorOptions(true);
          setShowFeedbackUpload(false);
          //setShowViewFeedback(false);
          if (!IsInterviewStage) setShowViewFeedback(false);
          setDisableEvaluatorOptions(true);
        }
      } catch (error) {
      } finally {
      }
    };
    fetchData();
  }, [currentGridId]);

  function extractFilterData(filterData) {
    const Stage = Number(filterData?.Stage) || 0;

    const RecruiterIds = filterData?.RecruiterIds;

    const isCurrentUserTaskPendingForCurrentStage = isEdit
      ? filterData?.PendingUserIdForCurrentStage?.split(",")?.some(
          (i) => i == userDetails?.Id
        )
      : false;

    const Evaluators = parseJSONWithErrorHandling(filterData?.Evaluators || "");
    const ResponsiblePerson = parseJSONWithErrorHandling(
      filterData?.ResponsiblePersonNameJSON
    );
    const activityStatusOptions = parseJSONWithErrorHandling(
      filterData?.ActivityStatusOptions
    );
    const DecisionOptions = parseJSONWithErrorHandling(
      filterData?.DecisionOptions
    );
    const filterDecisionOptions = FilterOutcomeOptions(
      filterData?.ActivityStatusName,
      DecisionOptions
    );
    const feedbackOptions = parseJSONWithErrorHandling(
      filterData?.CandidateFeedBackOptions
    );

    const Guest = parseJSONWithErrorHandling(filterData?.Guest);

    const CalendarBlock =
      CALENDAR_BLOCKING_OPTIONS?.find(
        (i) => i?.value == filterData?.CalendarBlocking
      ) || null;

    const activityStatusSystemName = String(
      filterData?.ActivityStatusSystemName
    )
      .toLocaleLowerCase()
      .trim();

    const activityStatusName = String(filterData?.ActivityStatusName)
      .toLocaleLowerCase()
      .trim();

    const IsInterviewStage = [
      CANDIDATE_STAGES.FirstInterview,
      CANDIDATE_STAGES.SecondInterview,
      CANDIDATE_STAGES.FinalInterview,
    ].includes(Number(Stage));

    const isPendingSchedule =
      filterData?.DecisionSystemName?.trim()?.toLocaleLowerCase() ===
      CandidateWorkflowDecisions.PendingSchedule;

    const isRescheduled =
      filterData?.DecisionSystemName?.trim()?.toLocaleLowerCase() ===
      CandidateWorkflowDecisions.Rescheduled;

    const isDecisionPending =
      filterData?.DecisionSystemName?.trim()?.toLocaleLowerCase() ===
      CandidateWorkflowDecisions.InProgress;

    const RecruiterRoleId =
      roles?.find(
        (i) => String(i?.name).trim().toLocaleLowerCase().trim() === "recruiter"
      )?.id || 0;

    const isEvaluator = Evaluators?.some((i) => i?.value === userDetails?.Id);

    const currentUserRoleIdIsRecruiter =
      currentRoleId?.value == RecruiterRoleId;

    const isNegotiationStage = [CANDIDATE_STAGES.Negotiation].includes(
      Number(Stage)
    );
    const isOfferReviewStage = [CANDIDATE_STAGES.OfferLetterReview].includes(
      Number(Stage)
    );

    const isOfferAcceptanceStage = [CANDIDATE_STAGES.OfferAcceptance].includes(
      Number(Stage)
    );

    let checkIsRecruiterForThisMRF = RecruiterIds?.trim()
      .split(",")
      ?.some((recruiterUserId) => recruiterUserId == userDetails?.Id);

    const PendingUserIdForCurrentStageEvaluation =
      filterData?.PendingUserIdForCurrentStageEvaluation;

    const isEvaluationPendingForThisStage =
      PendingUserIdForCurrentStageEvaluation?.split(",").some(
        (i) => i == userDetails?.Id
      );

    const DecisionSystemName = filterData?.DecisionName;

    return {
      Stage,
      RecruiterIds,
      isCurrentUserTaskPendingForCurrentStage,
      Evaluators,
      ResponsiblePerson,
      activityStatusOptions,
      DecisionOptions,
      filterDecisionOptions,
      feedbackOptions,
      activityStatusSystemName,
      activityStatusName,
      IsInterviewStage,
      isPendingSchedule,
      isRescheduled,
      isDecisionPending,
      RecruiterRoleId,
      isEvaluator,
      currentUserRoleIdIsRecruiter,
      isNegotiationStage,
      isOfferReviewStage,
      checkIsRecruiterForThisMRF,
      isEvaluationPendingForThisStage,
      isOfferAcceptanceStage,
      Guest,
      CalendarBlock,
      DecisionSystemName,
    };
  }

  function getNegoId(OfferLetterApprovalId = 0) {
    let id = 0;
    try {
      const stages = dashboardGridData?.filter(
        (stage) => stage.Id < OfferLetterApprovalId
      );
      const negoIds = stages
        .map((data) => {
          if (data.StaticStagesSystemName === "Negotiation") {
            return data.Id;
          }
        })
        .filter((data) => data !== undefined);

      const sortedArr = negoIds.sort((a, b) => b - a);
      const latestNegoId = sortedArr[0];

      id = latestNegoId;
    } catch (error) {
      console.error(error);
    }

    return id;
  }

  function getDecisionObjectForUseEffect(
    DecisionOptions,
    activityStatusSystemName,
    activityStatusName
  ) {
    let option: any = {};
    const decisionObj: any = {};
    const findDecisionOption = (code) =>
      DecisionOptions?.find(
        (i) => String(i?.code)?.toLocaleLowerCase().trim() === code
      );
    if (
      activityStatusSystemName === CandidateWorkflowDecisions.Pending ||
      activityStatusSystemName === CandidateWorkflowDecisions.Initiated
    ) {
      option = findDecisionOption(CandidateWorkflowDecisions.InProgress);
    } else if (activityStatusName === CandidateWorkflowDecisions.Rescheduled) {
      option = findDecisionOption(CandidateWorkflowDecisions.Rescheduled);
    }

    if (option) {
      decisionObj["DecisionId"] = option.value;
      decisionObj["DecisionName"] = option.label;
      decisionObj["DecisionSystemName"] = option.code;
    }

    return decisionObj;
  }

  useEffect(() => {
    if (cwtId && isEvaluationTask) {
      const fetchData = async () => {
        let filterData = dashboardGridData?.find((i) => i?.Id == cwtId);

        //? Creating a deep copy,which won't affect dashboardGridData;
        if (filterData) {
          filterData = JSON.parse(JSON.stringify(filterData));
        }

        const Stage = Number(filterData?.Stage) || 0;
        const Evaluators = parseJSONWithErrorHandling(
          filterData?.Evaluators || ""
        );
        const isEvaluator = Evaluators?.some(
          (i) => i?.value == userDetails?.Id
        );
        const RecruiterIds = filterData?.RecruiterIds;
        const isRecruiter = RecruiterIds?.split(",")?.some(
          (i) => (i) => userDetails?.Id
        );
        const feedbackObj = await GetFeedbackForDynamicStageAsync(
          Stage,
          filterData.Id
        );
        let object = {
          candidateWorkflowTaskId: currentGridId,
          candidateId: state.CandidateId,
          candidateName: filterData?.CandidateName,
          typeofActivity: { label: filterData?.ActivityName, value: 0 },
          stage: Stage,
          evaluators: parseJSONWithErrorHandling(filterData?.Evaluators || ""),
          feedbackObj,
          isEvaluator,
          isRecruiter,
        };
        setfeedBackUploadData(object);

        if (isOpenModalPopup) {
          setIsOpenModalPopup(false);
          setShowFeedbackFormModal(true);
        }
      };

      fetchData();
    }
  }, [cwtId, isOpenModalPopup]);

  useEffect(() => {
    async function helper() {
      if (cwtId && model && !isOnce) {
        let filterData = dashboardGridData?.find((i) => i?.Id == cwtId);

        //? Creating a deep copy,which won't affect dashboardGridData;
        if (filterData) {
          filterData = JSON.parse(JSON.stringify(filterData));
        }

        const Stage = Number(filterData?.Stage) || 0;
        // if (!isEvaluationTask) {
        const evaluators = await handleFetchEvaluatorsForStage(Stage);

        setFeedbackModelDetails({
          stage: Stage,
          evaluators: evaluators,
          candidateName: filterData?.CandidateName || "",
        });
        //}
        setIsOnce(true);
      } else {
        setFeedbackModelDetails({
          stage: null,
          evaluators: [],
          candidateName: "",
        });
        setIsOnce(false);
      }

      if (!model) {
        setCwtId(0);
        setIsOnce(false);
      }
    }
    helper();
  }, [model]);

  useEffect(() => {
    if (String(sourceName).toLocaleLowerCase().trim() == "ijp") {
      (async () => {
        await getCandidateIdbyUserId();
      })();
    }
  }, [sourceName]);

  useEffect(() => {
    if (sourceName !== "" && sourceName?.toLocaleLowerCase().trim() === "ijp") {
      // api to get ijp candidateDetails

      (async () => {
        const res = await APICall(getIJPCandidateDetails, "POST", {
          CandidateId: candidateId,
        });
        if (res.data && res.data.length > 0) {
          setIjpData(res.data);
        }
      })();
    }
  }, [sourceName]);

  useEffect(() => {
    if (
      !IsEmpty(attributesData?.currentCTC) &&
      !IsEmpty(attributesData?.offeredCTC)
    ) {
      const hike =
        ((attributesData?.offeredCTC - attributesData?.currentCTC) /
          attributesData?.currentCTC) *
        100;
      setAttributesData({
        ...attributesData,
        percentageHike: Math.round(hike),
      });
    } else {
      setAttributesData({ ...attributesData, percentageHike: 0 });
    }
  }, [attributesData.currentCTC, attributesData.offeredCTC]);

  const getCandidateIdbyUserId = async () => {
    try {
      showLoader();
      const response = await APICall(GetCandidateDetailsByUserId, "POST", {
        Id: userDetails?.Id,
      });

      if (response?.status == 0) {
        const responseData = response?.data;
        setEmployeeId(responseData?.employeeId || 0);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const GetRolesAsync = async () => {
    try {
      showLoader();
      let arr = [];
      const response = await APICall(GetRolesURL, "POST", {});

      if (response?.status == 0 && response?.data?.length > 0) {
        const data = response?.data;
        setRoles(data);
        arr = data;
      } else {
        setRoles([]);
      }

      return arr;
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const getManpowerRequisitionDetailsByIdAPICall = async () => {
    const response = await APICall(getManpowerRequisitionDetailsById, "POST", {
      MRFId: state?.TId,
    });

    if (response?.status === 0) {
      const responseData = response?.data;

      await getDropdownsDataForJobBandByApiCall(
        "MRJBBAND",
        responseData?.employeeClassId
      );

      await getDropdownsDataForJobLevelByApiCall(
        "MRJBLVL",
        responseData?.jobBandId
      );

      await getDropdownsDataForDesignationByApiCall(
        "POSDESGN",
        responseData?.jobLevelId
      );

      setmrfDetails(responseData);
    }
  };

  async function GetCandidateOfferLetterForAcceptanceStage() {
    try {
      showLoader();
      const { status, data, message } = await APICall(
        GetCandidateOfferLetterByCandidateId,
        "POST",
        {
          Id: state?.CandidateId,
        }
      );

      if (status == 0) {
        const { base64, fileName } = data;

        if (base64 && fileName) {
          const isPDF = isPDFBase64(base64);

          setCandidateOfferLetterDetails((prev) => {
            return {
              ...prev,
              fileName: fileName,
              base64: base64,
              isPdf: isPDF,
            };
          });
        }
      } else {
        console.error(message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  async function GetUsersScheduleById() {
    try {
    } catch (error) {}
  }

  function isPDFBase64(base64String) {
    // Check if base64String is empty
    if (!base64String) {
      console.error("Base64 string is empty.");
      return false;
    }

    // Decode the base64 string
    var binaryString = atob(base64String);

    // Extract the first few bytes to check for PDF signature
    var signature = binaryString.substr(0, 4);

    // Check if the signature matches the PDF format
    if (signature === "%PDF") {
      console.log("File is a PDF.");
      return true;
    } else {
      console.log("File is not a PDF.");
      return false;
    }
  }

  async function downloadCandiateOfferLetter() {
    try {
      if (
        !candidateOfferLetterDetails?.base64 ||
        String(candidateOfferLetterDetails?.base64).length === 0
      ) {
        return;
      }

      let arrayBuffer = await base64ToArrayBuffer(
        candidateOfferLetterDetails?.base64
      );
      var byteArray = new Uint8Array(arrayBuffer);

      let blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const newFile = new File([blob], "", {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        lastModified: new Date().getTime(),
      });
      fileDownload(newFile, candidateOfferLetterDetails?.fileName);
    } catch (error) {
      console.error(error);
    }
  }

  const FilterOutcomeOptions = (SystemName = "", OutcomeOptions = []) => {
    let arr = [];
    try {
      SystemName = String(SystemName)?.toLocaleLowerCase().trim();
      switch (SystemName) {
        case "pending":
        case "initiated":
          arr = OutcomeOptions?.filter(
            (i) => String(i?.code).toLocaleLowerCase().trim() === "inprogress"
          );
          break;
        case "rescheduled":
          arr = OutcomeOptions?.filter(
            (i) => String(i?.code).toLocaleLowerCase().trim() === "rescheduled"
          );
          break;
        default:
          arr = OutcomeOptions?.filter(
            (i) =>
              String(i?.code).toLocaleLowerCase().trim() !== "rescheduled" &&
              String(i?.code).toLocaleLowerCase().trim() !== "inprogress" &&
              String(i?.code).toLocaleLowerCase().trim() !== "initiated"
          );
          break;
      }
    } catch (error) {
      console.error(error);
    }

    return arr;
  };

  const onChange = async (key, event) => {
    setAttributesData({ ...attributesData, [key]: event });

    if (key === "designation") {
      setAttributesData({
        ...attributesData,
        [key]: event,
        clientFacingTitle: event ? event.label : "",
      });

      // diff
      setNegoDiffNote((prev) => {
        return prev.map((data) => {
          if (data.hasOwnProperty("designation")) {
            if (event && data?.designation?.oldData?.value != event.value) {
              return {
                designation: {
                  oldData: data.designation.oldData,
                  newData: event,
                },
              };
            } else {
              return {
                designation: {
                  oldData: data.designation.oldData,
                  newData: null,
                },
              };
            }
          } else if (data.hasOwnProperty("clientFT")) {
            if (event && data?.clientFT?.oldData != event.label) {
              return {
                clientFT: {
                  oldData: data.clientFT.oldData,
                  newData: event.label,
                },
              };
            } else {
              return {
                clientFT: { oldData: data.clientFT.oldData, newData: null },
              };
            }
          } else {
            return data;
          }
        });
      });
    }

    if (key === "clientFacingTitle") {
      // diff
      setNegoDiffNote((prev) => {
        return prev.map((data) => {
          if (data.hasOwnProperty("clientFT")) {
            if (event && data?.clientFT?.oldData != event) {
              return {
                clientFT: { oldData: data.clientFT.oldData, newData: event },
              };
            } else {
              return {
                clientFT: { oldData: data.clientFT.oldData, newData: null },
              };
            }
          } else {
            return data;
          }
        });
      });
    }

    switch (key) {
      case "employeeClass":
        // diff
        setNegoDiffNote((prev) => {
          return prev.map((data) => {
            if (data.hasOwnProperty("empClass")) {
              if (event && data?.empClass?.oldData?.value != event.value) {
                return {
                  empClass: { oldData: data.empClass.oldData, newData: event },
                };
              } else {
                return {
                  empClass: { oldData: data.empClass.oldData, newData: null },
                };
              }
            } else {
              return data;
            }
          });
        });
        setJobBandOptions([]);
        setJobLeveloptions([]);
        setDesignationOptions([]);
        const jobBandObj = { jobBand: null, jobLevel: null, designation: null };
        setAttributesData((prev) => ({ ...prev, ...jobBandObj }));
        if (event !== null) {
          await getDropdownsDataForJobBandByApiCall("MRJBBAND", event?.value);
        }
        break;

      case "jobBand":
        // diff
        setNegoDiffNote((prev) => {
          return prev.map((data) => {
            if (data.hasOwnProperty("jobBand")) {
              if (event && data?.jobBand?.oldData?.value != event.value) {
                return {
                  jobBand: { oldData: data.jobBand.oldData, newData: event },
                };
              } else {
                return {
                  jobBand: { oldData: data.jobBand.oldData, newData: null },
                };
              }
            } else {
              return data;
            }
          });
        });
        setJobLeveloptions([]);
        setDesignationOptions([]);
        const jobLevelObj = { jobLevel: null, designation: null };
        setAttributesData((prev) => ({ ...prev, ...jobLevelObj }));
        if (event !== null) {
          await getDropdownsDataForJobLevelByApiCall("MRJBLVL", event?.value);
        }
        break;

      case "jobLevel":
        // diff
        setNegoDiffNote((prev) => {
          return prev.map((data) => {
            if (data.hasOwnProperty("jobLevel")) {
              if (event && data?.jobLevel?.oldData?.value != event.value) {
                return {
                  jobLevel: { oldData: data.jobLevel.oldData, newData: event },
                };
              } else {
                return {
                  jobLevel: { oldData: data.jobLevel.oldData, newData: null },
                };
              }
            } else {
              return data;
            }
          });
        });
        setDesignationOptions([]);
        const designationObj = { designation: null };
        setAttributesData((prev) => ({ ...prev, ...designationObj }));

        if (event !== null) {
          await getDropdownsDataForDesignationByApiCall(
            "POSDESGN",
            event?.value
          );
        }
        break;

      case "activityStatus":
        if (event?.code === "Rescheduled") {
          setDisableScheduleDateTime(false);
        } else {
          setDisableScheduleDateTime(true);
        }

        if (
          String(event?.code)?.toLocaleLowerCase().trim() == "pending" ||
          String(event?.code)?.toLocaleLowerCase().trim() == "initiated"
        ) {
          const outcome =
            wholeOutComeOptions?.find(
              (i) => String(i?.code)?.toLocaleLowerCase().trim() == "inprogress"
            ) || null;
          setAttributesData((prev) => {
            return { ...prev, decision: outcome };
          });
        } else if (
          String(event?.code)?.toLocaleLowerCase().trim() == "rescheduled"
        ) {
          let outcome =
            wholeOutComeOptions?.find(
              (i) =>
                String(i?.code)?.toLocaleLowerCase().trim() == "rescheduled"
            ) || null;

          if (outcome != null && outcome != undefined) {
            outcome = wholeOutComeOptions?.find(
              (i) =>
                String(i?.code)?.toLocaleLowerCase().trim() == "rescheduled"
            );
          } else {
            outcome = wholeOutComeOptions?.find(
              (i) =>
                String(i?.code)?.toLocaleLowerCase().trim() == "reschedulejoin"
            );
          }

          setAttributesData((prev) => {
            return { ...prev, decision: outcome };
          });
        } else {
          setAttributesData((prev) => {
            return { ...prev, decision: null };
          });
        }
        const arr = FilterOutcomeOptions(event?.code, wholeOutComeOptions);
        setDecisionOptions(arr);
        break;

      case "outcome":
        break;

      case "decision":
        if (String(event?.code)?.toLocaleLowerCase().trim() == "notsuitable") {
          setShowFeedbackDropdown(true);
        } else {
          setShowFeedbackDropdown(false);
        }
        break;

      default:
        // Handle default case if necessary
        break;
    }
  };

  const getDropdownsDataForTypeofActivityApiCall = async (AC, PID) => {
    await showLoader();
    const dropdownData = await APICall(getDropdowns, "POST", {
      AC,
      PID,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      IsMRF: true,
    });

    if (
      dropdownData.data !== null &&
      dropdownData.data !== undefined &&
      dropdownData.data.length > 0
    ) {
      await hideLoader();
      setTypeofActivityOptions(dropdownData.data);
    } else {
      await hideLoader();
      setTypeofActivityOptions([]);
    }
  };

  const getDocConfigUrl = async () => {
    const response = await APICall(GetConfigValueByKey, "POST", {
      Key: "WelspunDMSAPILink",
    });

    if (response?.status === 0) {
      setDMSURL(response.data);
    } else {
      setDMSURL(null);
    }
  };

  const getCandidateDetailsByIdAPICall = async () => {
    showLoader();

    const response = await APICall(getCandidateDetailsById, "POST", {
      Id: state?.CandidateId,
      MRFId: mrfId,
    });

    if (response?.status === 0) {
      const source = response?.data.source;
      const OfferDocReqId = response?.data.offerDocRequestId;
      const OfferDocCode = response?.data.offerDocCodeType;
      setOfferDocReqId(OfferDocReqId);
      if (OfferDocCode != undefined && OfferDocCode != null) {
        setOfferDocCodeType(OfferDocCode);
      }
      setsourceName(source);

      if (OfferDocReqId != null && OfferDocReqId != undefined) {
        //status apicall
        const urlDocGenrated = await APICall(`${DMSURL}/Status`, "POST", {
          requestid: OfferDocReqId,
          doctype: "draft",
        });
        if (urlDocGenrated?.status == 200) {
          setOfferDocGeneratedLink(urlDocGenrated.data);
        }
      }
    } else {
      setsourceName("");
    }

    hideLoader();
  };

  const getDropdownsDataForCurrencyAPICall = async (AC, PID) => {
    await showLoader();
    const dropdownData = await APICall(getDropdowns, "POST", {
      AC,
      PID,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      IsMRF: true,
    });

    if (
      dropdownData.data !== null &&
      dropdownData.data !== undefined &&
      dropdownData.data.length > 0
    ) {
      await hideLoader();
      let CurrencyData = dropdownData?.data?.filter((currency) => {
        return (
          currency.label === "INR" ||
          currency.label === "USD" ||
          currency.label === "GBP"
        );
      });
      setCurrencyOption(CurrencyData);

      // currencyOption.filter((e) => e.label === "INR")[0];
    } else {
      await hideLoader();
      setCurrencyOption([]);
    }
  };

  const getDropdownsDataForConductedByApiCall = async () => {
    try {
      showLoader();

      const dropdownData = await APICall(GetRecruitersByMRFId, "POST", {
        MRFId: mrfId,
      });

      if (
        dropdownData.data !== null &&
        dropdownData.data !== undefined &&
        dropdownData.data.length > 0
      ) {
        const data = dropdownData?.data;

        const bool = data?.some((rc) => rc?.value == userDetails?.Id);
        setIsRecruiterForCurrentMRF(bool);

        setConductedByOptions(dropdownData.data);
      } else {
        setConductedByOptions([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const getDropdownsDataForEmployeeClassByApiCall = async (AC, PID) => {
    await showLoader();
    const dropdownData = await APICall(getDropdowns, "POST", {
      AC,
      PID,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      IsMRF: true,
    });

    if (
      dropdownData.data !== null &&
      dropdownData.data !== undefined &&
      dropdownData.data.length > 0
    ) {
      await hideLoader();
      setEmployeeClassOptions(dropdownData.data);
    } else {
      await hideLoader();
      setEmployeeClassOptions([]);
    }
  };

  const getDropdownsDataForJobBandByApiCall = async (AC, PID) => {
    await showLoader();
    const dropdownData = await APICall(getDropdowns, "POST", {
      AC,
      PID,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      IsMRF: true,
    });

    if (
      dropdownData.data !== null &&
      dropdownData.data !== undefined &&
      dropdownData.data.length > 0
    ) {
      await hideLoader();
      setJobBandOptions(dropdownData.data);
      return dropdownData.data;
    } else {
      await hideLoader();
      setJobBandOptions([]);
    }
  };

  const getDropdownsDataForJobLevelByApiCall = async (AC, PID) => {
    await showLoader();
    const dropdownData = await APICall(getDropdowns, "POST", {
      AC,
      PID,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      IsMRF: true,
    });

    if (
      dropdownData.data !== null &&
      dropdownData.data !== undefined &&
      dropdownData.data.length > 0
    ) {
      await hideLoader();
      setJobLeveloptions(dropdownData.data);
      return dropdownData.data;
    } else {
      await hideLoader();
      setJobLeveloptions([]);
    }
  };

  const getDropdownsDataForDesignationByApiCall = async (AC, PID) => {
    await showLoader();
    const dropdownData = await APICall(getDropdowns, "POST", {
      AC,
      PID,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      IsMRF: true,
    });

    if (
      dropdownData.data !== null &&
      dropdownData.data !== undefined &&
      dropdownData.data.length > 0
    ) {
      await hideLoader();
      setDesignationOptions(dropdownData.data);
      return dropdownData.data;
    } else {
      await hideLoader();
      setDesignationOptions([]);
    }
  };

  function removeHTMLTags(str) {
    try {
      const doc = new DOMParser().parseFromString(str, "text/html");
      return doc.body.textContent || "";
    } catch (error) {
      return "";
    }
  }

  const validateData = () => {
    let errorObj = {};
    let isError = false;
    setFormErrors({});

    const filterData = dashboardGridData?.find((i) => i?.Id == currentGridId);
    const Stage = Number(filterData?.Stage) || 0;
    const IsInterviewStage = [
      CANDIDATE_STAGES.FirstInterview,
      CANDIDATE_STAGES.SecondInterview,
      CANDIDATE_STAGES.FinalInterview,
    ].includes(Number(Stage));

    const IsOfferLetterReviewStage = [
      CANDIDATE_STAGES.OfferLetterReview,
    ].includes(Number(Stage));

    const isPendingSchedule =
      filterData?.DecisionSystemName?.trim()?.toLocaleLowerCase() ==
      "pendingschedule";

    const ActivityStatus = String(attributesData?.activityStatus?.code)
      .trim()
      .toLocaleLowerCase();

    const Decision = String(attributesData?.decision?.code)
      .toLocaleLowerCase()
      .trim();

    if (Number(Stage) == CANDIDATE_STAGES.Recommendation) {
      if (!hiringManagerDetails || hiringManagerDetails?.length === 0) {
        errorObj["hiringManager"] = "Hiring Manager Required.";
      }
    }

    if (!isPendingSchedule) {
      if (!attributesData?.activityStatus?.value) {
        errorObj["decision"] = "Activity Status Required.";
      } else if (ActivityStatus == "pending") {
        errorObj["decision"] = "Activity Status cannot be pending.";
      } else if (ActivityStatus == "initiated") {
        errorObj["decision"] = "Activity Status cannot be initiated.";
      }

      if (!attributesData?.decision?.value) {
        errorObj["outcome"] = "Decision Required.";
      } else if (Decision == "inprogress") {
        errorObj["outcome"] = "Decision cannot be inprogress.";
      } else if (Decision == "pending") {
        errorObj["outcome"] = "Decision cannot be pending.";
      }
    }

    if (IsInterviewStage) {
      if (
        !attributesData.responsiblePersons ||
        attributesData.responsiblePersons?.length == 0
      ) {
        errorObj["error_ResponsiblePersons"] = "Evaluator Required.";
      }

      //# CHECK IF REQUIRED FIELDS ARE EMPTY
      if (
        !attributesData.scheduledDateandTime ||
        attributesData?.scheduledDateandTime?.length == 0 ||
        !moment(attributesData?.scheduledDateandTime).isValid()
      ) {
        errorObj["error_ScheduleDateTime"] =
          "Scheduled start date and time required.";
      }

      if (
        !attributesData.scheduledEndDateandTime ||
        attributesData?.scheduledEndDateandTime?.length == 0 ||
        !moment(attributesData?.scheduledEndDateandTime).isValid()
      ) {
        errorObj["error_scheduledEndDateandTime"] =
          "Scheduled end date and time required.";
      }

      if (!attributesData?.calendarBlocking) {
        errorObj["error_calendarBlocking"] = "Calendar blocking required.";
      }

      if (
        !attributesData?.meetingSubject ||
        String(attributesData?.meetingSubject)?.length == 0
      ) {
        errorObj["error_meetingSubject"] = "Meeting subject required.";
      }

      if (
        !attributesData?.meetingDescription ||
        String(removeHTMLTags(attributesData?.meetingDescription))?.length == 0
      ) {
        errorObj["error_meetingDescription"] = "Meeting description required.";
      }

      if (
        attributesData?.calendarBlocking &&
        attributesData?.calendarBlocking?.value != 1 &&
        moment(attributesData?.scheduledDateandTime).isValid() &&
        moment(attributesData?.scheduledEndDateandTime).isValid()
      ) {
        if (moment(attributesData.scheduledDateandTime).isBefore(new Date())) {
          errorObj["error_ScheduleDateTime"] = "Past time not allowed";
        } else if (
          moment(attributesData.scheduledEndDateandTime).isBefore(new Date())
        ) {
          errorObj["error_scheduledEndDateandTime"] = "Past time not allowed";
        } else if (
          !moment(attributesData?.scheduledDateandTime).isSame(
            moment(attributesData?.scheduledEndDateandTime),
            "day"
          )
        ) {
          errorObj["error_scheduledEndDateandTime"] =
            "Start datetime and end datetime should be same.";
        }
      }
    } else if (onNegotiationHideandShow && Decision !== "notsuitable") {
      if (IsEmpty(attributesData.currentCTC, true)) {
        errorObj = {
          ...errorObj,
          ["currentCTC"]: "Required",
        };
      }

      if (IsEmpty(attributesData.cTCExpectedByCandidate)) {
        errorObj = {
          ...errorObj,
          ["cTCExpectedByCandidate"]: "Required",
        };
      }

      if (IsEmpty(attributesData.offeredCTC)) {
        errorObj = {
          ...errorObj,
          ["offeredCTC"]: "Required",
        };
      }

      if (IsEmpty(attributesData.fixedPay)) {
        errorObj = {
          ...errorObj,
          ["fixedPay"]: "Required",
        };
      }

      if (IsEmpty(attributesData.variablePay, true)) {
        errorObj = {
          ...errorObj,
          ["variablePay"]: "Required",
        };
      }

      if (IsEmpty(attributesData.noticePay, true)) {
        errorObj = {
          ...errorObj,
          ["noticePay"]: "Required",
        };
      }

      if (IsEmpty(attributesData.relocationAllowance, true)) {
        errorObj = {
          ...errorObj,
          ["relocationAllowance"]: "Required",
        };
      }

      if (IsEmpty(attributesData.noticePeriodDays, true)) {
        errorObj = {
          ...errorObj,
          ["noticePeriodDays"]: "Required",
        };
      }

      if (IsEmpty(attributesData.dateofJoining)) {
        errorObj = {
          ...errorObj,
          ["dateofJoining"]: "Required",
        };
      }

      if (IsEmpty(attributesData.offerAcceptanceDueDate)) {
        errorObj = {
          ...errorObj,
          ["offerAcceptanceDueDate"]: "Required",
        };
      }

      if (IsEmpty(attributesData.jobLevel)) {
        errorObj = {
          ...errorObj,
          ["jobLevel"]: "Required",
        };
      }
      if (IsEmpty(attributesData.designation)) {
        errorObj = {
          ...errorObj,
          ["designation"]: "Required",
        };
      }
      if (IsEmpty(attributesData.clientFacingTitle)) {
        errorObj = {
          ...errorObj,
          ["clientFacingTitle"]: "Required",
        };
      }
    } else if (IsOfferLetterReviewStage) {
      if (selectedFilesForSet.find((item) => item.AC === "OFR") == undefined) {
        errorObj["offerLetter"] = "Required";
      }
    }

    // check remarks
    if (!hideDecisionOptions && IsEmpty(attributesData.remarks)) {
      errorObj = {
        ...errorObj,
        ["remarks"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["remarks"]: "",
      };
    }

    // check candidate feedback
    if (showFeedbackDropdown && IsEmpty(attributesData.candidateFeedback)) {
      errorObj = {
        ["candidateFeedback"]: "Required", //clear rest all required and add this only
      };
    } else {
      errorObj = {
        ...errorObj,
        ["candidateFeedback"]: "",
      };
    }

    // check past time
    if (invalidTime) {
      errorObj["error_ScheduleDateTime"] = "Past time not allowed";
    }

    const isEmpty = Object.values(errorObj).every(
      (s) => s === null || s === "" || s === undefined
    );

    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  const handleInputChangeForApp = async (e, cb, isGuest = false) => {
    try {
      if (e?.length > 3) {
        let filterString = "";
        if (
          isGuest &&
          attributesData.responsiblePersons &&
          attributesData.responsiblePersons?.length > 0
        ) {
          let selectedUsersId = [...attributesData.responsiblePersons].map(
            (i) => i.value
          );

          filterString = selectedUsersId.join(",");
        }
        showLoader();
        const response = await APICall(getTAInputData, "POST", {
          textInput: e,
          FilterString: filterString,
          IsAdhocApprover: true,
        });

        if (response?.data?.length > 0) {
          cb(response?.data);
        } else {
          throw new Error("API request failed.");
        }
      }
    } catch (error) {
      console.error("Error fetching API data:", error);
    } finally {
      await hideLoader();
    }
  };

  const GetFeedbackForDynamicStageAsync = async (
    CurrentStage,
    CandidateWorkflowTaskId
  ) => {
    try {
      showLoader();

      const response = await APICall(
        getEvaluatorFeedbackForCurrentStage,
        "POST",
        {
          MRFId: mrfId,
          CandidateId: candidateId,
          Stage: CurrentStage,
          CandidateWorkflowTaskId,
        }
      );

      if (response?.status == 0) {
        return [...response?.data];
      }

      return {};
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const gridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    rowsPerPage: gridData.length,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "scroll",

    //standard | vertical | simple
    onChangeRowsPerPage: (num) => {},

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
      }
      if (sortDirection === "desc") {
      }
    },
    onChangePage: async (page) => {},
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const getRequestParamsForWorkflow = () => {
    const filterData = dashboardGridData?.find((i) => i?.Id == currentGridId);
    const Stage = Number(filterData?.Stage) || 0;

    const IsInterviewStage = [
      Number(CANDIDATE_STAGES.FirstInterview),
      Number(CANDIDATE_STAGES.SecondInterview),
      Number(CANDIDATE_STAGES.FinalInterview),
    ].includes(Number(Stage));

    const isPendingSchedule =
      filterData?.DecisionSystemName?.trim() == "PendingSchedule";

    const EvaluatorIds = attributesData?.responsiblePersons
      ?.map((i) => i?.value)
      ?.join(",");

    let requestParams = {
      CandidateWorkflowTaskId: currentGridId,
      CandidateId: candidateId,
      MRFId: mrfId,
      ScheduledDate: attributesData?.scheduledDateandTime
        ? moment(attributesData.scheduledDateandTime).toISOString()
        : null,
      SchdeduleEndDateTime: attributesData?.scheduledEndDateandTime
        ? moment(attributesData.scheduledEndDateandTime).toISOString()
        : null,
      DecisionId:
        attributesData.decision !== null ? attributesData.decision?.value : 0,
      CandidateFeedbackId:
        attributesData.candidateFeedback !== null
          ? attributesData.candidateFeedback?.value
          : 0,
      Remarks: attributesData.remarks,
      ConductedById: userDetails.Id,
      CreatedBy: userDetails.Id,
      RoleId: currentRoleId?.value || 0,
      StageId: attributesData?.stage || 0,
      UserId: userDetails?.Id,
      WorkflowTaskId: state?.Id,
      DateofJoining: attributesData.dateofJoining || null,
      ActivityStatusId:
        attributesData?.activityStatus !== null
          ? attributesData?.activityStatus?.value
          : 0,
      IsPendingSchedule: IsInterviewStage && isPendingSchedule,
      EvaluatorsId: EvaluatorIds,
      DecisionSysName:
        attributesData.decision !== null ? attributesData.decision?.code : null,
      HiringManagerIds: hiringManagerDetails
        .map((user) => user?.value)
        .join(","),
      CalendarBlocking: attributesData?.calendarBlocking?.value || 0,
      Guests: attributesData?.guest?.map((user) => user?.value).join(",") || "",
      MeetingDescription: attributesData?.meetingDescription,
      MeetingSubject: attributesData?.meetingSubject,
    };

    return requestParams;
  };

  const handleConvertInt = (num = 0) => {
    if (IsEmpty(num)) {
      return 0;
    }

    if (isNaN(Number(num))) {
      return 0;
    }

    return Number(num);
  };

  const handleNegoDataSave = async ({ requestParams }) => {
    try {
      if (requestParams.StageId == CANDIDATE_STAGES.Negotiation) {
        const negoObj = {
          Id: currentGridId,
          CurrencyId: attributesData?.currency?.value || null,
          CurrentCTC: handleConvertInt(attributesData?.currentCTC),
          CTCExpectedbyCandidate: handleConvertInt(
            attributesData?.cTCExpectedByCandidate
          ),
          OfferedCTC: handleConvertInt(attributesData?.offeredCTC),
          FixedPay: handleConvertInt(attributesData?.fixedPay),
          VariablePay: handleConvertInt(attributesData?.variablePay),
          NoticePay: handleConvertInt(attributesData?.noticePay),
          RelocationAllowance: handleConvertInt(
            attributesData?.relocationAllowance
          ),
          PercentageHike: handleConvertInt(attributesData?.percentageHike),
          NoticePeriodDays: handleConvertInt(attributesData?.noticePeriodDays),
          DateofJoining: attributesData.dateofJoining || null,
          OfferAcceptanceDueDate: attributesData.offerAcceptanceDueDate || null,
          EmployeeClassId: attributesData?.employeeClass?.value || null,
          JobBandId: attributesData?.jobBand?.value || null,
          JobLevelId: attributesData?.jobLevel?.value || null,
          DesignationId: attributesData?.designation?.value || null,
          ClientFacingTitle: attributesData?.clientFacingTitle || null,
        };

        const negoRes = await APICall(updateNegoDetailsById, "POST", negoObj);
      }
    } catch (error) {
      console.error("Error in Saving Nego Data: ", error);
    }
  };

  const handleOfferLetter = async ({ requestParams }) => {
    try {
      if (requestParams.StageId == CANDIDATE_STAGES.OfferApproval) {
        let requestParams2 = {
          CandidateDetailId: requestParams.CandidateId,
          MrfID: requestParams.MRFId,
        };

        //Own API created same Project
        const resCandidateData = await APICall(
          GetCandidateDetails,
          "POST",
          requestParams2
        );
        if (resCandidateData?.status == 0 && resCandidateData?.data !== null) {
          const CandidateData = resCandidateData?.data;
          const candidateObject = {
            totalctc:
              attributesData?.offeredCTC !== undefined &&
              attributesData?.offeredCTC !== null
                ? String(attributesData.offeredCTC)
                : null,
            firstname:
              CandidateData?.agjafn !== undefined &&
              CandidateData?.agjafn !== null
                ? String(CandidateData.agjafn)
                : null,
            lastname:
              CandidateData?.agjaln !== undefined &&
              CandidateData?.agjaln !== null
                ? String(CandidateData.agjaln)
                : null,
            middlename:
              CandidateData?.agjamn !== undefined &&
              CandidateData?.agjamn !== null
                ? String(CandidateData.agjamn)
                : null,
            address1:
              CandidateData?.cafaddrL1 !== undefined &&
              CandidateData?.cafaddrL1 !== null
                ? String(CandidateData.cafaddrL1)
                : null,
            address2:
              CandidateData?.cafaddrL2 !== undefined &&
              CandidateData?.cafaddrL2 !== null
                ? String(CandidateData.cafaddrL2)
                : null,
            pincode:
              CandidateData?.cafposcod !== undefined &&
              CandidateData?.cafposcod !== null
                ? String(CandidateData.cafposcod)
                : null,
            letterdate: String(moment().format("DD MMM YYYY")),
            designation:
              attributesData?.designation.label !== undefined &&
              attributesData?.designation.label !== null
                ? String(attributesData.designation.label)
                : null,
            department:
              CandidateData?.department !== undefined &&
              CandidateData?.department !== null
                ? String(CandidateData.department)
                : null,
            // departmentcode: String(CandidateData.departmentcode),
            // officeaddress: String(CandidateData.officeaddress),
            officelocation:
              CandidateData?.officelocation !== undefined &&
              CandidateData?.officelocation !== null
                ? String(CandidateData.officelocation)
                : null,
            dateofjoining:
              attributesData.dateofJoining !== undefined &&
              attributesData.dateofJoining !== null
                ? String(
                    moment(attributesData.dateofJoining).format("DD MMM YYYY")
                  )
                : null,
            acceptancedate:
              attributesData.offerAcceptanceDueDate !== undefined &&
              attributesData.offerAcceptanceDueDate !== null
                ? String(
                    moment(attributesData.offerAcceptanceDueDate).format(
                      "DD MMM YYYY"
                    )
                  )
                : null,
            acceptancedays:
              attributesData.noticePeriodDays !== undefined &&
              attributesData.noticePeriodDays !== null
                ? String(attributesData.noticePeriodDays)
                : null,
            fixedpay:
              attributesData.fixedPay !== undefined &&
              attributesData.fixedPay !== null
                ? String(attributesData.fixedPay)
                : null,
            variablepay:
              attributesData.variablePay !== undefined &&
              attributesData.variablePay !== null
                ? String(attributesData.variablePay)
                : null,
          };

          // genrate document apicall
          const genrateDocumentParams = {
            documenttypecode: offerDocCodeType ? offerDocCodeType : "OFR_WORLD",
            attributes: [candidateObject],
          };
          const resGenrateDocument = await APICall(
            `${DMSURL}/GenerateDocument`,
            "POST",
            genrateDocumentParams
          );
          if (resGenrateDocument.status == "200") {
            //got request id
            const DocRequestid = resGenrateDocument?.data;
            setOfferDocReqId(DocRequestid);
            const updatedRequestParams2 = {
              ...requestParams2,
              CandidateRequestID: DocRequestid,
            };
            const resInsertRequestId = await APICall(
              insertRequestId,
              "POST",
              updatedRequestParams2
            );
          }
        }
      }
    } catch (error) {}
  };

  const handleOfferLetterReviewDoc = async (requestParams) => {
    try {
      if (requestParams.StageId == CANDIDATE_STAGES.OfferLetterReview) {
        if (
          selectedFilesForSet.find((item) => item.AC === "OFR") !== undefined
        ) {
          // document to pdf apicall
          let files = selectedFilesForSet.find(
            (item) => item.AC === "OFR"
          ).Data;
          const resGenrateDocument = await APICall(
            `${DMSURL}/GeneratePdf`,
            "POST",
            {
              requestid: offerDocReqId,
              file: `${files}`,
              docextension: "docx",
              isuploaded: "true",
            }
          );
          if (resGenrateDocument.status == "200") {
            //got request id
            const DocRequestid = resGenrateDocument?.data;

            let requestParams2 = {
              CandidateDetailId: requestParams.CandidateId,
              MrfID: requestParams.MRFId,
              CandidateRequestID: DocRequestid,
            };
            const resInsertRequestId = await APICall(
              insertRequestId,
              "POST",
              requestParams2
            );
            setOfferDocReqId(DocRequestid);
          }
        }
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const onSubminFunction = async () => {
    try {
      const isError = validateData();
      if (isError) return;

      setDisabledSubmitBtn(true);
      const filterData = dashboardGridData?.find((i) => i?.Id == currentGridId);

      const requestParams = getRequestParamsForWorkflow();

      showLoader();
      const [response, response2] = await Promise.all([
        APICall(SubmitForStagesAndResponsiblity, "POST", requestParams),
        handleNegoDataSave({ requestParams }),
      ]);

      if (response?.status == 0) {
        await Promise.all([
          insertDocuments({ filterData }),
          handleOfferLetter({ requestParams }),
          handleOfferLetterReviewDoc(requestParams),
        ]);

        notify(0, "Record saved successfully.");
        navigate("/home");
        resetFunction();
        setCurrentGridId(null);
        setShowAttrSet(false);
      } else if (response?.status == 5) {
        notify(1, response?.message);
      } else {
        notify(1, response?.message);
        throw new Error(response?.message);
      }
    } catch (error) {
      console.error("Submit Stages error := ", error);
      notify(1, "Something went wrong.");
    } finally {
      hideLoader();
      setDisabledSubmitBtn(false);
    }
  };

  const handleSendInviteValidation = () => {
    try {
      const errorObj = formErrors;
      let isError = false;
      if (
        !attributesData.responsiblePersons ||
        attributesData.responsiblePersons?.length == 0
      ) {
        errorObj["error_ResponsiblePersons"] = "Evaluator Required.";
        isError = true;
      } else {
        delete errorObj["error_ResponsiblePersons"];
      }

      //# CHECK IF REQUIRED FIELDS ARE EMPTY
      if (
        !attributesData.scheduledDateandTime ||
        attributesData?.scheduledDateandTime?.length == 0 ||
        !moment(attributesData?.scheduledDateandTime).isValid()
      ) {
        errorObj["error_ScheduleDateTime"] =
          "Scheduled start date and time required.";
        isError = true;
      } else {
        delete errorObj["error_ScheduleDateTime"];
      }

      if (
        !attributesData.scheduledEndDateandTime ||
        attributesData?.scheduledEndDateandTime?.length == 0 ||
        !moment(attributesData?.scheduledEndDateandTime).isValid()
      ) {
        errorObj["error_scheduledEndDateandTime"] =
          "Scheduled end date and time required.";
        isError = true;
      } else {
        delete errorObj["error_scheduledEndDateandTime"];
      }

      if (!attributesData?.calendarBlocking) {
        errorObj["error_calendarBlocking"] = "Calendar blocking required.";
        isError = true;
      } else {
        delete errorObj["error_calendarBlocking"];
      }

      if (
        !attributesData?.meetingSubject ||
        String(attributesData?.meetingSubject)?.length == 0
      ) {
        errorObj["error_meetingSubject"] = "Meeting subject required.";
        isError = true;
      } else {
        delete errorObj["error_meetingSubject"];
      }

      if (
        !attributesData?.meetingDescription ||
        String(removeHTMLTags(attributesData?.meetingDescription))?.length == 0
      ) {
        errorObj["error_meetingDescription"] = "Meeting description required.";
        isError = true;
      } else {
        delete errorObj["error_meetingDescription"];
      }

      if (
        moment(attributesData?.scheduledDateandTime).isValid() &&
        moment(attributesData?.scheduledEndDateandTime).isValid()
      ) {
        if (moment(attributesData.scheduledDateandTime).isBefore(new Date())) {
          errorObj["error_ScheduleDateTime"] = "Past time not allowed";
          isError = true;
        } else if (
          moment(attributesData.scheduledEndDateandTime).isBefore(new Date())
        ) {
          errorObj["error_scheduledEndDateandTime"] = "Past time not allowed";
          isError = true;
        } else if (
          !moment(attributesData?.scheduledDateandTime).isSame(
            moment(attributesData?.scheduledEndDateandTime),
            "day"
          )
        ) {
          errorObj["error_scheduledEndDateandTime"] =
            "Start datetime and end datetime should be same.";
          isError = true;
        } else {
          delete errorObj["error_scheduledEndDateandTime"];
          delete errorObj["error_ScheduleDateTime"];
        }
      }

      setFormErrors((prev) => {
        return { ...prev, errorObj };
      });
      return isError;
    } catch (error) {
      console.error("send invation validation error: ", error);
    }
  };

  async function handleSendInvite() {
    try {
      const filterData = dashboardGridData?.find((i) => i?.Id == currentGridId);
      const Stage = Number(filterData?.Stage) || 0;

      const IsInterviewStage = [
        CANDIDATE_STAGES.FirstInterview,
        CANDIDATE_STAGES.SecondInterview,
        CANDIDATE_STAGES.FinalInterview,
      ].includes(Number(Stage));
      const EvaluatorIds = attributesData?.responsiblePersons
        ?.map((i) => i?.value)
        ?.join(",");

      if (IsInterviewStage) {
        const isError = handleSendInviteValidation();

        if (isError) return;

        const object = {
          CandidateWorkflowTaskId: currentGridId,
          CandidateId: candidateId,
          MRFId: mrfId,
          ScheduledDate: attributesData?.scheduledDateandTime
            ? moment(attributesData.scheduledDateandTime).toISOString()
            : null,
          SchdeduleEndDateTime: attributesData?.scheduledEndDateandTime
            ? moment(attributesData.scheduledEndDateandTime).toISOString()
            : null,
          EvaluatorsId: EvaluatorIds,
          CalendarBlocking: attributesData?.calendarBlocking?.value || 0,
          Guests:
            attributesData?.guest?.map((user) => user?.value).join(",") || "",
          MeetingDescription: attributesData?.meetingDescription,
          MeetingSubject: attributesData?.meetingSubject,
        };

        showLoader();
        const response = await APICall(
          ScheduleMeetingCandidateAndEvaluaotrs,
          "POST",
          object
        );

        if (response?.status == 0) {
          if (attributesData?.meetingId) {
            notify(0, "Meeting rescheduled successfully.");
          } else {
            notify(0, "Meeting scheduled successfully.");
          }
        } else {
          notify(1, "Meeting scheduled unsuccessfully.");
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  const resetFunction = () => {
    setFormErrors({});

    setIsView(false);

    const attributesDataReset = {
      candidateId: "",
      candidateHistory: "",
      stage: "",
      typeofActivity: null,
      mode: null,
      responsiblePersons: null,
      scheduledDateandTime: null,
      conductedBy: null,
      decision: null,
      feedback: null,
      remarks: "",
      currency: "",
      currentCTC: 0,
      cTCExpectedByCandidate: 0,
      offeredCTC: 0,
      fixedPay: 0,
      variablePay: 0,
      noticePay: 0,
      relocationAllowance: 0,
      percentageHike: 0,
      noticePeriodDays: 0,
      dateofJoining: null,
      offerAcceptanceDueDate: null,
      previousConversation: "",
      joiningStatus: null,
    };

    setAttributesData(attributesDataReset);
  };

  function parseJSONWithErrorHandling(str) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return [];
    }
  }

  async function GetMRFApproversMappingByMRFId() {
    try {
      showLoader();
      const { status, message, data } = await APICall(
        GetMRFApproversMappingByMRFIdURL,
        "POST",
        {
          MRFId: mrfId,
          StageId: CANDIDATE_STAGES.RecommedationReview,
          CandidateId: candidateId,
        }
      );

      if (status == 0) {
        setHiringManagerDetails(data);
      } else {
        throw new Error(`Error while fetching MRF Appprovers:  ${message}`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  const fetchCandidateCandidateWorkflowInstanceByCandidateId = async () => {
    try {
      showLoader();

      let requestParams = {
        TId: mrfId,
        CandidateId: candidateId,
      };
      const response = await APICall(getStagesData, "POST", requestParams);

      if (response?.status == 0) {
        const responseData = response?.data;

        let DecisionOptions = [];
        let arr = responseData?.map((data) => {
          let ScheduledDate = moment(data?.scheduledDate).isValid()
            ? moment(data?.scheduledDate).format("DD-MM-YYYY HH:mm:ss")
            : null;

          return {
            Id: data?.id,
            Stage: data?.stage,
            ActivityName: data?.activityName,
            ModeName: data?.modeName,
            ScheduledByName: data?.scheduledByName,
            DecisionName: data?.decisionName,
            DecisionSystemName: data?.decisionSystemName,
            StaticStagesSystemName: data?.StaticStagesSystemName,
            ResponsiblePersonNames: data?.responsiblePersonName,
            MinimumEvaluators: data?.minEvaluators,
            DecisionOptions: data?.decisionOptions,
            FeedbackOptions: data?.feedbackOptions,
            OutComeOptions: data?.outComeOptions,
            CurrentStage: data?.currentStage,
            FeedBackUpload: data?.feedBackUpload || "",
            ResponsiblePersonIds: data?.responsiblePersonIds || "",
            ScheduledDate: data?.scheduledDate,
            Remarks: data?.remarks || "",
            EvaluatorsIds: data?.evaluatorsIds || "",
            CandidateName: data?.candidateName || "",
            Evaluators: data?.evaluators,
            PendingUserIdForCurrentStage:
              data?.pendingUserIdForCurrentStage || "",
            CurrencyId: data.currencyId,
            CurrentCTC: IsEmpty(data.currentCTC) ? 0 : Number(data.currentCTC),
            CTCExpectedbyCandidate: data.ctcExpectedbyCandidate,
            OfferedCTC: data.offeredCTC,
            FixedPay: data.fixedPay,
            VariablePay: data.variablePay || "0",
            NoticePay: data.noticePay || "0",
            RelocationAllowance: data.relocationAllowance || "0",
            PercentageHike: data.percentageHike,
            noticePeriodDays: data.noticePeriodDays,
            DateofJoining: data.dateofJoining,
            OfferAcceptanceDueDate: data.offerAcceptanceDueDate,
            EmployeeClassId: data.employeeClassId,
            JobBandId: data.jobBandId,
            JobLevelId: data.jobLevelId,
            DesignationId: data.designationId,
            ClientFacingTitle: data.clientFacingTitle,
            fileName: data?.fileName,
            fileURL: data?.fileURL,
            DecisionId: data?.decisionId || 0,
            ActivityStatusId: data?.activityStatusId || 0,
            ActivityStatusOptions: data?.activityStatusOptions || "",
            ActivityStatusName: data?.activityStatusName || "",
            ActivityStatusSystemName: data?.activityStatusSystemName || "",
            CandidateFeedBackOptions: data?.candidateFeedBackOptions || "",
            CandidateFeedbackId: data?.candidateFeedbackId || 0,
            RecruiterIds: data?.recruiterIds || "",
            ResponsiblePersonNameJSON: data?.responsiblePersonNameJSON,
            PendingUserIdForCurrentStageEvaluation:
              data?.pendingUserIdForCurrentStageEvaluation,
            MeetingSubject: data?.meetingSubject,
            CalendarBlocking: data?.calendarBlocking,
            MeetingDescription: data?.meetingDescription,
            ScheduledEndDateandTime: data?.scheduleEndDateTime,
            Guest: data?.guest,
            MeetingId: data?.meetingId,
          };
        });

        setGridData(arr);
        setDashboardGridData(arr);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const dashboardColumns = [
    {
      name: "Id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "StaticStagesSystemName",
      label: "StaticStagesSystemName",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "EvaluatorsIds",
      label: "EvaluatorsIds",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "RecruiterIds",
      label: "RecruiterIds",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "PendingUserIdForCurrentStageEvaluation",
      label: "PendingUserIdForCurrentStageEvaluation",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "DecisionOptions",
      label: "DecisionOptions",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "DecisionSystemName",
      label: "DecisionSystemName",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "ActivityStatusSystemName",
      label: "ActivityStatusSystemName",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "CurrentStage",
      label: "CurrentStage",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "Stage",
      label: "Stage",
      options: {
        filter: false,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta) => {
          if (value % 1 === 0) {
            value = parseInt(value);
          }
          return <>{value}</>;
        },
      },
    },
    {
      name: "ActivityName",
      label: "Stage name",
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: "ModeName",
      label: "Mode name",
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: "ResponsiblePersonNames",
      label: "Responsible Persons",
      options: {
        filter: false,
        sort: false,
        display: true,
        width: 100,
      },
    },
    {
      name: "ResponsiblePersonIds",
      label: "ResponsiblePersonIds",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "PendingUserIdForCurrentStage",
      label: "PendingUserIdForCurrentStage",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "ScheduledByName",
      label: "Scheduled by",
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: "ScheduledDate",
      label: "Scheduled Date Time",
      options: {
        filter: false,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta) => {
          let date = value ? moment(value).format("DD-MM-YYYY HH:mm:ss A") : "";
          return <>{date}</>;
        },
      },
    },
    {
      name: "MinimumEvaluators",
      label: "Min Evaluators",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "Feedback",
      label: "Feedback",
      options: {
        filter: false,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta) => {
          const Id = tableMeta.tableData[tableMeta.rowIndex].Id;
          const PendingUserIdForCurrentStageEvaluation =
            tableMeta.tableData[tableMeta.rowIndex]
              .PendingUserIdForCurrentStageEvaluation;
          const DecisionOptionsJSON =
            tableMeta.tableData[tableMeta.rowIndex].DecisionOptions;

          const PendingUserIdForCurrentStage =
            tableMeta.tableData[tableMeta.rowIndex]
              ?.PendingUserIdForCurrentStage;

          const StaticStagesSystemName =
            tableMeta.tableData[tableMeta.rowIndex].StaticStagesSystemName;

          let CurrentStage =
            tableMeta.tableData[tableMeta.rowIndex].CurrentStage;

          CurrentStage = Number.isNaN(CurrentStage) ? 0 : Number(CurrentStage);

          let Stage = tableMeta.tableData[tableMeta.rowIndex].Stage;

          Stage = Number.isNaN(Stage) ? 0 : Number(Stage);

          const DecisionSystemName =
            tableMeta.tableData[tableMeta.rowIndex].DecisionSystemName;

          const ResponsiblePersonIds = tableMeta.tableData[
            tableMeta.rowIndex
          ].ResponsiblePersonIds?.split(",")?.map((i) => {
            i = !Number.isNaN(i) ? Number(i) : 0;
            return i;
          });

          let Evaluators =
            tableMeta.tableData[tableMeta.rowIndex].EvaluatorsIds;

          Evaluators = Evaluators?.split(",") || [];

          const IsEvaluator = Evaluators?.some(
            (i) => i?.value == Number(userDetails?.Id)
          );

          let show = false;

          const IsInterviewStage = [
            CANDIDATE_STAGES.FirstInterview,
            CANDIDATE_STAGES.SecondInterview,
            CANDIDATE_STAGES.FinalInterview,
          ].includes(Number(Stage));

          if (
            ResponsiblePersonIds?.includes(Number(userDetails?.Id)) &&
            DecisionSystemName != "PendingSchedule" &&
            DecisionSystemName != "Rescheduled" &&
            DecisionSystemName != "Skipped" &&
            IsInterviewStage &&
            !IsEvaluator &&
            !isEvaluationTask &&
            Stage <= CurrentStage
          ) {
            show = true;
          }

          return (
            <>
              <div className="d-flex justify-content-center">
                {show && (
                  <span
                    onClick={() => {
                      setCwtId(Id);
                      setModel(true);
                      setIsOpenModalPopup(true);
                      setfeedBackUploadData(null);
                      setFeedbackModelDetails({
                        stage: null,
                        evaluators: [],
                      });
                      window.scrollTo({
                        top: 300,
                        behavior: "smooth",
                      });
                    }}
                    style={{ color: "skyblue", cursor: "pointer" }}
                  >
                    Feedback
                  </span>
                )}
              </div>
            </>
          );
        },
      },
    },
    {
      name: "ActivityStatusName",
      label: "Activity Status",
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: "DecisionName",
      label: "Outcome",
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: "fileURL",
      label: "fileUrl",
      options: {
        filter: false,
        sort: false,
        display: false,
        // setCellProps: () => ({
        //   style: { textAlign: "center" },
        // }),
        // setCellHeaderProps: () => ({
        //   style: { textAlign: "center" },
        // }),
      },
    },
    // {
    //   name: "fileName",
    //   label: "Documents",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     display: true,
    //     // setCellProps: () => ({
    //     //   style: { textAlign: "center" },
    //     // }),
    //     // setCellHeaderProps: () => ({
    //     //   style: { textAlign: "center" },
    //     // }),
    //     customBodyRender: (value, tableMeta) => {
    //       const link = tableMeta.tableData[tableMeta.rowIndex].fileURL;
    //       return (
    //         <>
    //           <div className="d-flex justify-content-center">
    //             <a className="mr-2" href={link} target="_blank">
    //               {value}
    //             </a>
    //           </div>
    //         </>
    //       );
    //     },
    //   },
    // },

    {
      name: "",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta) => {
          const Id = tableMeta.tableData[tableMeta.rowIndex].Id;
          let Evaluators =
            tableMeta.tableData[tableMeta.rowIndex].EvaluatorsIds;

          const isEvaluatorForThisStage = Evaluators?.split(",")?.some(
            (i) => i == userDetails?.Id
          );

          const PendingUserIdForCurrentStageEvaluation =
            tableMeta.tableData[tableMeta.rowIndex]
              ?.PendingUserIdForCurrentStageEvaluation;

          const PendingUserIdForCurrentStage =
            tableMeta.tableData[tableMeta.rowIndex]
              ?.PendingUserIdForCurrentStage;

          const StaticStagesSystemName =
            tableMeta.tableData[tableMeta.rowIndex].StaticStagesSystemName;

          let CurrentStage =
            tableMeta.tableData[tableMeta.rowIndex].CurrentStage;

          CurrentStage = Number.isNaN(CurrentStage) ? 0 : Number(CurrentStage);

          let Stage = tableMeta.tableData[tableMeta.rowIndex].Stage;

          Stage = Number.isNaN(Stage) ? 0 : Number(Stage);

          const DecisionSystemName =
            tableMeta.tableData[tableMeta.rowIndex].DecisionSystemName;

          const ActivitySystemName =
            tableMeta.tableData[tableMeta.rowIndex].ActivityStatusSystemName;

          const ResponsiblePersonIds = tableMeta.tableData[
            tableMeta.rowIndex
          ].ResponsiblePersonIds?.split(",")?.map((i) => {
            i = !Number.isNaN(i) ? Number(i) : 0;
            return i;
          });

          const RecruiterRoleId =
            roles?.find(
              (i) =>
                String(i?.name).trim().toLocaleLowerCase().trim() == "recruiter"
            )?.id || 0;

          const RecruiterIds =
            tableMeta.tableData[tableMeta.rowIndex].RecruiterIds;

          const checkIsRecruiterForThisMRF = RecruiterIds?.trim()
            .split(",")
            ?.some((recruiterUserId) => recruiterUserId == userDetails?.Id);

          const isCurrentUserTaskPendingForCurrentStage =
            PendingUserIdForCurrentStage?.split(",")?.some(
              (i) => i == userDetails?.Id
            );

          let IsEdit = false;

          const isEvaluationPendingForThisStage =
            PendingUserIdForCurrentStageEvaluation?.split(",").some(
              (i) => i == userDetails?.Id
            );

          const decisionLowerCase = String(DecisionSystemName)
            .toLocaleLowerCase()
            .trim();

          if (isEdit) {
            if (
              isEvaluationTask &&
              String(DecisionSystemName).toLocaleLowerCase().trim() ==
                "pendingschedule"
            ) {
              IsEdit = false;
            } else if (
              String(ActivitySystemName).toLocaleLowerCase().trim() ==
              "completed"
            ) {
              IsEdit = false;
            } else if (
              isEvaluatorForThisStage &&
              isEvaluationTask &&
              isEvaluationPendingForThisStage
            ) {
              IsEdit = true;
            } else if (CurrentStage == CANDIDATE_STAGES.Joining) {
              if (props.type != "edit") {
                IsEdit = false;
              } else {
                IsEdit = isCurrentUserTaskPendingForCurrentStage;
              }
            } else {
              if (isEvaluationTask) {
                IsEdit = false;
              } else {
                IsEdit = isCurrentUserTaskPendingForCurrentStage;
              }
            }
          }

          if (props?.isMRFAdmin) {
            return (
              <>
                <>
                  <div
                    className="d-flex justify-content-center"
                    style={{ cursor: "pointer" }}
                  >
                    <>
                      <Tooltip title={"view"}>
                        <a
                          style={{ cursor: "pointer" }}
                          className="mr-2"
                          onClick={(e) => {
                            e.preventDefault();
                            setCurrentGridId(Id);
                            setShowAttrSet(true);
                            setIsDisable(true);
                            setfeedBackUploadData(null);
                            setFeedbackModelDetails({
                              stage: null,
                              evaluators: [],
                            });
                            window.scrollTo({
                              top: 300,
                              behavior: "smooth",
                            });
                          }}
                        >
                          <i className={`fas fa-eye`}></i>
                        </a>
                      </Tooltip>
                    </>
                  </div>
                </>
              </>
            );
          }

          return (
            <>
              <div
                className="d-flex justify-content-center"
                style={{ cursor: "pointer" }}
              >
                {currentRoleId.code === "BUHR" &&
                CurrentStage == CANDIDATE_STAGES.InitiateOnboarding ? (
                  <>
                    {Stage === CurrentStage && (
                      <Tooltip title={IsEdit ? "edit" : "view"}>
                        <a
                          style={{ cursor: "pointer" }}
                          className="mr-2"
                          onClick={(e) => {
                            e.preventDefault();
                            setCurrentGridId(Id);
                            setShowAttrSet(true);
                            setIsDisable(false);
                            // getDocumentsDetails();

                            if (!IsEdit) {
                              setIsDisable(true);
                            } else {
                              setIsDisable(false);
                            }
                            setfeedBackUploadData(null);
                            setFeedbackModelDetails({
                              stage: null,
                              evaluators: [],
                            });
                            window.scrollTo({
                              top: 300,
                              behavior: "smooth",
                            });
                          }}
                        >
                          <i
                            className={`fas fa-${IsEdit ? "edit" : "eye"}`}
                          ></i>
                        </a>
                      </Tooltip>
                    )}
                  </>
                ) : (
                  <>
                    <Tooltip title={IsEdit ? "edit" : "view"}>
                      <a
                        style={{ cursor: "pointer" }}
                        className="mr-2"
                        onClick={(e) => {
                          e.preventDefault();
                          setCurrentGridId(Id);
                          setShowAttrSet(true);
                          setIsDisable(false);
                          // getDocumentsDetails();

                          if (!IsEdit) {
                            setIsDisable(true);
                          } else {
                            setIsDisable(false);
                          }
                          setfeedBackUploadData(null);
                          setFeedbackModelDetails({
                            stage: null,
                            evaluators: [],
                          });
                          window.scrollTo({
                            top: 300,
                            behavior: "smooth",
                          });
                        }}
                      >
                        <i className={`fas fa-${IsEdit ? "edit" : "eye"}`}></i>
                      </a>
                    </Tooltip>
                  </>
                )}
              </div>
            </>
          );
        },
      },
    },
  ];

  const downloadOfferLetterDoc = async (DocUrl) => {
    try {
      showLoader();
      let relativeurl = DocUrl.split("/sites")[1];
      const GeneratedDocument = await APICall(
        OfferLetterGeneratedDocDounload,
        "POST",
        {
          DocURL: `/sites${relativeurl}`,
        }
      );
      if (GeneratedDocument?.data) {
        let docname = DocUrl.split("/");
        let name = docname[docname.length - 1];
        downloadBase64File(GeneratedDocument?.data, name);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const fileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileValidation = (files, attribute) => {
    // const { FS, FX, FC } = JSON.parse(attribute.V);
    let [FS, FX, FC] = [
      5,
      ".docx,.doc,.pdf,.png,.jpg,.jpeg,.txt,.ppt,.pptx,.xlsx",
      5,
    ];

    if (attribute === "OFR") {
      [FS, FX, FC] = [5, ".docx", 1];
    }

    // Split the validExtensionsString into an array
    const validExtensions = FX.split(",");

    let isValid = [];

    // validate file count
    if (files.length > FC) {
      notify(1, `Only ${FC} files allowed!`);
      return false;
    }
    // check extension of each file
    files?.forEach((file) => {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();

      // Check if the file extension is allowed
      if (!validExtensions.includes("." + fileExtension)) {
        isValid.push(false);
      } else {
        isValid.push(true); //valid
      }
    });

    let returnValue = isValid.some((v) => v === false);

    if (returnValue) {
      notify(1, `Please upload file within this ${FX} extentsion`);
      return false;
    }

    files?.forEach((file) => {
      const fileSize = file.size;
      const maxFileSizeBytes = FS * 1024 * 1024;
      if (fileSize <= maxFileSizeBytes) {
        isValid.push(true); //valid
      } else {
        isValid.push(false);
      }
    });

    returnValue = isValid.some((v) => v === false);

    if (returnValue) {
      notify(1, `File size exceeds ${FS}MB. Please select a smaller file.`);
      return false;
    } else {
      return true;
    }
  };

  const handleFileChange = async (e, attribute) => {
    // const { FS, FX, FC } = JSON.parse(attribute.V);

    const [FS, FX, FC] = [
      "5",
      ".docx,.doc,.pdf,.png,.jpg,.jpeg,.txt,.ppt,.pptx,.xlsx",
      5,
    ];

    const files = Array.prototype.slice.call(e.target.files);

    const isValid = handleFileValidation(files, attribute);
    if (isValid) {
      if (files && files.length > 0) {
        const base64Array: { Name: string; Data: string }[] = [];
        for (let i = 0; i < files.length; i++) {
          const base64 = (await fileToBase64(files[i])) as string;
          const base64WithoutPrefix = base64.split(",")[1];
          base64Array.push({ Name: files[i].name, Data: base64WithoutPrefix });
        }

        //check if AC present and file count matched

        const fileCount = selectedFilesForSet.reduce((total, item) => {
          if (item.AC === attribute) {
            return total + 1;
          }
          return total;
        }, 0);

        let FCs = FC;

        if (attribute === "OFR") {
          FCs = 1;
        }

        if (fileCount == FCs) {
          notify(1, `Only ${FCs} files allowed!`);
        } else {
          // let type =
          //   JSON.parse(attribute.AD).CON === "National ID Information"
          //     ? attributeSetData["IDCATY"]?.label
          //     : JSON.parse(attribute.AD).TYP
          //     ? JSON.parse(attribute.AD).TYP
          //     : "";
          base64Array?.forEach((file) => {
            setSelectedFilesForSet((prevSelectedFiles) => [
              ...prevSelectedFiles,
              {
                AC: attribute,
                Name: file.Name,
                Data: file.Data,
                IsValidFile: false,
                DocId: 0,
                // Type: type,
                // Context: JSON.parse(attribute.AD).CON
                //   ? JSON.parse(attribute.AD).CON
                //   : "",
                // DCVALUE: file.Name,
                // DVTILL: "",
                // DSTATUS: "Verified",
                // DPSINCE: "",
                // DPWITH: "",
              },
            ]);
          });
        }
      }
    } else {
      e.target.value = null;
    }
  };

  const handleDownloadForSet = async (fileAttribute, fileIndex) => {
    showLoader();
    if (
      fileAttribute.DocId != undefined &&
      fileAttribute.DocId != null &&
      fileAttribute.DocId != 0
    ) {
      const doc = await APICall(downloadDocument, "POST", {
        TId: candidateId,
        AC: fileAttribute.AC,
        DocId: fileAttribute.DocId,
      });
      if (doc && doc.data !== null) {
        downloadBase64File(doc.data, fileAttribute.Name);
        hideLoader();
      } else {
        notify(1, "Something Went Wrong.");
        hideLoader();
      }
    } else {
      downloadBase64File(fileAttribute.Data, fileAttribute.Name);
      hideLoader();
    }
  };

  function downloadBase64File(base64String: string, fileName: string) {
    const mimeType = inferMimeType(base64String);
    const blob = base64toBlob(base64String, mimeType);

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  }

  function inferMimeType(base64String: string): string {
    const dataUrl = `data:application/octet-stream;base64,${base64String}`;
    const typeInfo = /^data:(.*?);/.exec(dataUrl);

    if (typeInfo && typeInfo[1]) {
      return typeInfo[1];
    }

    return "application/octet-stream";
  }

  function base64toBlob(base64String: string, mimeType: string): Blob {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], { type: mimeType });
  }

  const handleDeleteFileForSet = async (fileAttribute, fileIndex) => {
    if (
      fileAttribute.DocId != undefined &&
      fileAttribute.DocId != null &&
      fileAttribute.DocId != 0
    ) {
      showLoader();
      const doc = await APICall(deleteDocument, "POST", {
        TId: candidateId,
        AC: fileAttribute.AC,
        DocId: fileAttribute.DocId,
      });
      if (doc.message === "Success") {
        documentRef.current.value = null;
        let remainingDocs = selectedFilesForSet.filter(function (obj) {
          return obj.DocId !== fileAttribute.DocId;
        });
        setSelectedFilesForSet(remainingDocs);
        hideLoader();
      } else {
        notify(1, "File not Deleted, Something Went Wrong.");
        hideLoader();
      }
    } else {
      documentRef.current.value = null;
      setSelectedFilesForSet((prevSelectedFiles) => {
        return prevSelectedFiles.filter(
          (item, attIndex) => fileIndex !== attIndex
        );
      });
    }
  };

  const getDocumentsDetails = async (name = "") => {
    if (candidateId > 0) {
      await showLoader();
      const getDocument = await APICall(getDocuments, "POST", {
        TId: candidateId,
        AC: name,
      });

      if (getDocument && getDocument.data !== null) {
        setSelectedFilesForSet(getDocument.data.files);
      } else {
        setSelectedFilesForSet([]);
      }
      await hideLoader();
    }
  };
  const insertDocuments = async ({ filterData }) => {
    if (
      filterData.Stage == CANDIDATE_STAGES.BackgroundVerification ||
      filterData.Stage == CANDIDATE_STAGES.ReferenceCheck ||
      filterData.Stage == CANDIDATE_STAGES.PsychometricTest
    ) {
      showLoader();
      if (selectedFilesForSet.length > 0) {
        let newFiles = selectedFilesForSet.filter((sf) => {
          return sf.DocId === 0;
        });
        try {
          const response = await APICall(documentUpload, "POST", {
            ModuleName: "ManpowerRequisition",
            files: newFiles,
            SectionName: "Stages",
            TabName: "Candidates",
            TId: candidateId,
            ModuleId: 5,
            UserId: userDetails.Id,
          });

          if (response.data === null || response.data === undefined) {
            notify(1, "Failed to upload documents.");
          } else {
            setSelectedFilesForSet([
              ...selectedFilesForSet,
              response.data.files,
            ]);
            const negoObj = {
              Id: currentGridId,
              FileAC: response.data.files[0].AC,
              FileURL: response.data.files[0].Data,
              FileId: response.data.files[0].DocId,
              FileName: response.data.files[0].Name,
            };

            const negoRes = await APICall(
              updateFileDetailsById,
              "POST",
              negoObj
            );
          }
        } catch (error) {
          throw new Error("Error uploading documents: " + error.message);
        }
      }
    }

    await hideLoader();
  };

  async function handleFetchEvaluatorsForStage(stageId) {
    try {
      showLoader();

      const response = await APICall(GetEvaluatorsForStage, "POST", {
        MRFId: mrfId,
        StageId: stageId,
        candidateId,
      });

      if (response?.status == 0) {
        const data = response?.data;
        return data;
      } else {
        console.error(response?.message);
      }
      return [];
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  async function cancelMeetingAsync() {
    try {
      showLoader();
      const response = await APICall(CancelMeetingByMeetingId, "POST", {
        Id: currentGridId,
        ScheduledDateandTime: attributesData.scheduledDateandTime,
      });

      if (response?.status == 0) {
        setAttributesData((prev) => {
          return {
            ...prev,
            meetingId: "",
            meetingSubject: "",
            meetingDescription: "",
          };
        });
        notify(0, "Meeting cancelled successfully.");
      }
    } catch (error) {
      console.error("Cancel meeting error: ", error);
      notify(1, "Meeting cancelled unsuccessfully.");
    } finally {
      setCancelMeetingModal(false);
      hideLoader();
    }
  }

  function closeCancelMeetingModal() {
    setCancelMeetingModal(false);
  }

  return (
    <>
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-3 col-sm-3 col-xs-4"></div>
          <div className="col-lg-3 col-sm-3 col-xs-4"></div>
          <div className="col-lg-3 col-sm-3 col-xs-4"></div>
        </div>
      </div>
      <div className="col-lg-12">
        <EmptyAccordian header={"Stages"}>
          <div className="row">
            <React.Fragment key={1}>
              <div className="col-lg-12">
                <div className="row">
                  {ShowAttrSet && (
                    <>
                      {/* Candidate Id */}
                      <div className="col-lg-3 col-sm-3 col-xs-4">
                        <div className="mb-1">
                          <label className="col-form-label">
                            Candidate ID
                            {/* <sup>*</sup> */}
                          </label>
                          <InputForm
                            className="form-control"
                            placeholder={"Candidate ID"}
                            isDisabled={isStatic}
                            textArea={false}
                            value={state?.CandidateId}
                            onChange={(val: any) => {
                              let value = val.target.value;
                              onChange("candidateId", value);
                            }}
                          />
                          <p style={{ color: "red" }}>
                            {formErrors["candidateId"]}
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-3 col-sm-3 col-xs-4">
                        <div className="mb-1">
                          <label className="col-form-label">
                            Candidate Name
                          </label>
                          <InputForm
                            className="form-control"
                            placeholder={"Candidate name"}
                            isDisabled={isStatic}
                            textArea={false}
                            value={attributesData?.candidateName}
                            onChange={(val: any) => {
                              let value = val.target.value;
                              onChange("candidateName", value);
                            }}
                          />
                          <p style={{ color: "red" }}>
                            {formErrors["candidateName"]}
                          </p>
                        </div>
                      </div>

                      {/* Candidate History */}
                      <div className="col-lg-3 col-sm-3 col-xs-4">
                        <div className="mb-1">
                          <label className="col-form-label">
                            Candidate history,If any
                          </label>
                          <InputForm
                            className="form-control"
                            placeholder={"Candidate history"}
                            isDisabled={isStatic}
                            textArea={false}
                            value={attributesData.candidateHistory}
                            onChange={(val: any) => {
                              let value = val.target.value;
                              onChange("candidateHistory", value);
                            }}
                          />
                          <p style={{ color: "red" }}>
                            {formErrors["candidateHistory"]}
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-3 col-sm-3 col-xs-4">
                        <div className="mb-4">
                          <label className="col-form-label"></label>
                          <div>
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.preventDefault();
                                notify(1, "No History");
                              }}
                            >
                              view history
                            </span>
                          </div>
                          <p style={{ color: "red" }}>
                            {formErrors["viewHistory_isEmpty"]}
                          </p>
                        </div>
                      </div>

                      {sourceName?.toLocaleLowerCase().trim() === "ijp" && (
                        <>
                          <div className="col-lg-3 col-sm-3 col-xs-4">
                            <div className="mb-4">
                              <label className="col-form-label">
                                Tenure in Welspun
                              </label>
                              <div>
                                {moment(ijpData[0]?.groupJoiningDate)?.isValid()
                                  ? `${moment
                                      .duration(
                                        moment().diff(
                                          moment(ijpData[0]?.groupJoiningDate)
                                        )
                                      )
                                      .years()} years ${moment
                                      .duration(
                                        moment().diff(
                                          moment(ijpData[0]?.groupJoiningDate)
                                        )
                                      )
                                      .months()} months ${moment
                                      .duration(
                                        moment().diff(
                                          moment(ijpData[0]?.groupJoiningDate)
                                        )
                                      )
                                      .days()} days`
                                  : ""}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-3 col-xs-4">
                            <div className="mb-4">
                              <label className="col-form-label">
                                Last Two Appraisal Ratings
                              </label>
                              <div>
                                {ijpData.some(
                                  (s) => s.performanceYear !== null
                                ) && (
                                  <>
                                    <Table striped bordered>
                                      <thead>
                                        <tr>
                                          <th className="text-dark">
                                            Performance year
                                          </th>
                                          <th className="text-dark">
                                            Performance rating
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {ijpData.map((ijp) => {
                                          return (
                                            <tr>
                                              <td>{ijp.performanceYear}</td>
                                              <td>{ijp.performanceRating}</td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </Table>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="col-lg-3 col-sm-3 col-xs-4">
                        <div className="mb-1">
                          <label className="col-form-label">
                            Source
                            {/* <sup>*</sup> */}
                          </label>
                          <InputForm
                            className="form-control"
                            placeholder={"Source"}
                            isDisabled={isStatic}
                            textArea={false}
                            value={attributesData?.source}
                            onChange={(val: any) => {
                              let value = val.target.value;
                              onChange("source", value);
                            }}
                          />
                          <p style={{ color: "red" }}>{formErrors["source"]}</p>
                        </div>
                      </div>

                      <div className="col-lg-3 col-sm-3 col-xs-4">
                        <div className="mb-1">
                          <label className="col-form-label">
                            Stage
                            {/* <sup>*</sup> */}
                          </label>
                          <InputForm
                            className="form-control"
                            placeholder={"Stage"}
                            isDisabled={isStatic}
                            textArea={false}
                            value={attributesData.stage}
                            onChange={(val: any) => {
                              let value = val.target.value;
                              onChange("stage", value);
                            }}
                          />
                          {formErrors["stage"] && (
                            <p style={{ color: "red" }}>
                              {formErrors["stage"]}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-3 col-sm-3 col-xs-4">
                        <div className="mb-1">
                          <label className="col-form-label">
                            Type of Activity
                          </label>
                          <SelectForm
                            isClearable
                            isSearchable
                            options={typeofActivityOptions}
                            placeholder={"Type of Activity"}
                            isDisabled={isStatic}
                            value={attributesData.typeofActivity}
                            onChange={(val: any) => {
                              onChange("typeofActivity", val);
                            }}
                            isMulti={false}
                            noIndicator={false}
                            noSeparator={false}
                          />
                        </div>
                      </div>

                      <div className="col-lg-3 col-sm-3 col-xs-4">
                        <div className="dropdown mb-1">
                          <label className="col-form-label">
                            Responsible Persons
                          </label>
                          <sup>*</sup>
                          <SelectForm
                            isClearable
                            isSearchable
                            async
                            options={(searchString, cb) => {
                              handleInputChangeForApp(searchString, cb);
                            }}
                            placeholder={"Responsible Persons"}
                            isDisabled={isDisable || disableEvaluatorOptions}
                            value={attributesData.responsiblePersons}
                            onChange={(val: any) => {
                              onChange("responsiblePersons", val);
                            }}
                            isMulti={true}
                            noIndicator={false}
                            noSeparator={false}
                            pluralPlaceHolder={
                              showScheduleDateAndTime
                                ? "Interviewer"
                                : "Responsible Person"
                            }
                          />
                          <span style={{ color: "red" }}>
                            {formErrors?.error_ResponsiblePersons || ""}
                          </span>
                          <div className="dropdown-content">
                            {attributesData.responsiblePersons?.length > 1 && (
                              <>
                                {attributesData.responsiblePersons?.map((i) => (
                                  <p>{i?.label || ""}</p>
                                ))}
                              </>
                            )}
                          </div>
                        </div>
                      </div>

                      {hideHiringManager === false && (
                        <div className="col-lg-3 col-sm-3 col-xs-4">
                          <div className="dropdown mb-1">
                            <label className="col-form-label">
                              Hiring Manager
                            </label>
                            <SelectForm
                              isClearable
                              isSearchable
                              async
                              options={(searchString, cb) => {
                                handleInputChangeForApp(searchString, cb);
                              }}
                              placeholder={"Hiring Manager"}
                              isDisabled={isDisable || disableHiringManager}
                              value={hiringManagerDetails}
                              onChange={(val: any) => {
                                setHiringManagerDetails(val);
                              }}
                              isMulti={true}
                              noIndicator={false}
                              noSeparator={false}
                            />
                            <span style={{ color: "red" }}>
                              {formErrors?.hiringManager || ""}
                            </span>
                            <div className="dropdown-content">
                              {hiringManagerDetails.length > 1 && (
                                <>
                                  {hiringManagerDetails?.map((i) => (
                                    <p key={uuid()}>{i?.label || ""}</p>
                                  ))}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      )}

                      {showOfferDocument && (
                        <>
                          <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                            <div className="mb-1">
                              <label className="col-form-label">
                                Offer Letter Document <sup>*</sup>
                              </label>

                              <div className="box position-relative">
                                <input
                                  id={"OFR"}
                                  className="form-control inputfile inputfile-6 multiple-inputfile"
                                  data-multiple-caption="{count} files selected"
                                  multiple={false}
                                  type="file"
                                  ref={documentRef}
                                  onChange={(e) => {
                                    if (e !== null) {
                                      handleFileChange(e, "OFR");
                                      let obj = formErrors;
                                      delete obj["offerLetter"];
                                      setFormErrors(obj);
                                    }
                                  }}
                                  accept={".docx"}
                                  disabled={isDisable}
                                />
                                <label
                                  htmlFor={"OFR"}
                                  className="form-control"
                                  style={{ width: 0, border: "none" }}
                                >
                                  <strong
                                    style={{
                                      padding: "6px 16px",
                                      backgroundColor: "#3c5464",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <i
                                      className="fa fa-upload rotate90"
                                      aria-hidden="true"
                                    ></i>
                                    {"  "}
                                    Upload
                                  </strong>{" "}
                                </label>
                              </div>
                              <div className="file-added-list">
                                <ul className="list-unstyle">
                                  {selectedFilesForSet &&
                                    selectedFilesForSet.length > 0 &&
                                    selectedFilesForSet.map(
                                      (fileAttribute, attributeIndex) => (
                                        <>
                                          <li
                                            className="list mt-1"
                                            key={attributeIndex}
                                          >
                                            <div className="media">
                                              <div className="media-body text-truncate">
                                                <span className="view-more">
                                                  {fileAttribute.Name}
                                                </span>
                                              </div>

                                              {/* <div className="media-right ml-2">
                                            <i
                                              className="fa-solid fa-download"
                                              aria-hidden="true"
                                              onClick={() =>
                                                handleDownloadForSet(
                                                  fileAttribute,
                                                  attributeIndex
                                                )
                                              }
                                            ></i>
                                          </div> */}

                                              <div
                                                className="media-right ml-2"
                                                hidden={isDisable}
                                              >
                                                <i
                                                  className="fa fa-trash"
                                                  aria-hidden="true"
                                                  onClick={() =>
                                                    handleDeleteFileForSet(
                                                      fileAttribute,
                                                      attributeIndex
                                                    )
                                                  }
                                                ></i>
                                              </div>
                                            </div>
                                          </li>
                                        </>
                                      )
                                    )}
                                </ul>
                                <span
                                  style={{ cursor: "pointer", color: "blue" }}
                                  onClick={async () => {
                                    if (offerDocGeneratedLink !== null) {
                                      await downloadOfferLetterDoc(
                                        offerDocGeneratedLink
                                      );
                                    } else {
                                      notify(
                                        1,
                                        "Offer letter is not generated, Please wait for sometime."
                                      );
                                    }
                                  }}
                                >
                                  <span
                                    className="list mt-1"
                                    //key={}
                                  >
                                    <div className="media">
                                      <div className="media-body text-truncate">
                                        <span className="view-more">
                                          <i
                                            className="fa-solid fa-download"
                                            aria-hidden="true"
                                          ></i>
                                          Download Offer Letter
                                        </span>
                                      </div>
                                    </div>
                                  </span>
                                </span>
                              </div>
                              {formErrors["offerLetter"] && (
                                <p style={{ color: "red" }}>
                                  {formErrors["offerLetter"]}
                                </p>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                      {candidateOfferLetterDetails?.showDocument && (
                        <>
                          <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                            <div className="mb-1">
                              <label className="col-form-label">
                                Offer Letter Document <sup>*</sup>
                              </label>

                              <div className="box position-relative">
                                <input
                                  id={"OFR1"}
                                  className="form-control inputfile inputfile-6 multiple-inputfile"
                                  data-multiple-caption="{count} files selected"
                                  multiple={false}
                                  type="file"
                                  onChange={(e) => {}}
                                  accept={".docx"}
                                  disabled={true}
                                />
                                <label
                                  htmlFor={"OFR1"}
                                  className="form-control"
                                  style={{ width: 0, border: "none" }}
                                >
                                  <strong
                                    style={{
                                      padding: "6px 16px",
                                      backgroundColor: "#3c5464",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <i
                                      className="fa fa-upload rotate90"
                                      aria-hidden="true"
                                    ></i>
                                    Upload
                                  </strong>
                                </label>
                              </div>
                              <div className="file-added-list">
                                <ul className="list-unstyle">
                                  <li className="list mt-1">
                                    <div className="media">
                                      <div className="media-body text-truncate">
                                        <Tooltip
                                          title={
                                            candidateOfferLetterDetails?.fileName ||
                                            ""
                                          }
                                        >
                                          <span
                                            className="view-more"
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            onClick={
                                              downloadCandiateOfferLetter
                                            }
                                          >
                                            {
                                              candidateOfferLetterDetails?.fileName
                                            }
                                          </span>
                                        </Tooltip>
                                      </div>
                                      {candidateOfferLetterDetails?.fileName && (
                                        <>
                                          {candidateOfferLetterDetails?.isPdf && (
                                            <div className="media-right ml-2">
                                              <i
                                                className="fas fa-eye"
                                                aria-hidden="true"
                                                style={{
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  setOfferLetterModelOpen(true)
                                                }
                                              />
                                            </div>
                                          )}
                                          <div className="media-right ml-2">
                                            <i
                                              className="fa-solid fa-download"
                                              aria-hidden="true"
                                              style={{
                                                cursor: "pointer",
                                              }}
                                              onClick={
                                                downloadCandiateOfferLetter
                                              }
                                            />
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      <>
                        {showScheduleDateAndTime && (
                          <>
                            <div className="col-lg-3 col-sm-3 col-xs-4">
                              <div className="mb-1">
                                <label className="col-form-label">
                                  Scheduled Start Date and Time <sup>*</sup>
                                </label>
                                <DateTimeComponent
                                  value={attributesData.scheduledDateandTime}
                                  disabled={isDisable || isEvaluationTask}
                                  onChange={(val: any) => {
                                    onChange("scheduledDateandTime", val);
                                  }}
                                />
                                <span style={{ color: "red" }}>
                                  {formErrors["error_ScheduleDateTime"]}
                                </span>
                                {attributesData?.meetingId && !isDisable && (
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      color: "blue",
                                      display: "block",
                                      textDecoration: "underline",
                                    }}
                                    onClick={() => {
                                      setCancelMeetingModal(true);
                                    }}
                                  >
                                    Cancel meeting
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-3 col-sm-3 col-xs-4">
                              <div className="mb-1">
                                <label className="col-form-label">
                                  Scheduled End Date and Time <sup>*</sup>
                                </label>
                                <DateTimeComponent
                                  value={
                                    attributesData?.scheduledEndDateandTime
                                  }
                                  disabled={isDisable || isEvaluationTask}
                                  onChange={(val: any) => {
                                    onChange("scheduledEndDateandTime", val);
                                  }}
                                  placeholder={"Scheduled end date and time"}
                                />
                                <span style={{ color: "red" }}>
                                  {formErrors["error_scheduledEndDateandTime"]}
                                </span>
                              </div>
                            </div>
                            <div className="col-lg-3 col-sm-3 col-xs-4">
                              <div className="mb-1">
                                <label className="col-form-label">
                                  Calendar Blocking <sup>*</sup>
                                </label>
                                <SelectForm
                                  isClearable
                                  isSearchable
                                  options={CALENDAR_BLOCKING_OPTIONS}
                                  placeholder={"Calendar blocking"}
                                  isDisabled={isDisable || isEvaluationTask}
                                  value={attributesData?.calendarBlocking}
                                  onChange={(val: any) => {
                                    onChange("calendarBlocking", val);
                                  }}
                                  isMulti={false}
                                  noIndicator={false}
                                  noSeparator={false}
                                />
                                <span style={{ color: "red" }}>
                                  {formErrors["error_calendarBlocking"]}
                                </span>
                              </div>
                            </div>
                            <div className="col-lg-3 col-sm-3 col-xs-4">
                              <div className="mb-1">
                                <label className="col-form-label">Guest</label>
                                <SelectForm
                                  isClearable
                                  isSearchable
                                  async
                                  options={(searchString, cb) => {
                                    handleInputChangeForApp(
                                      searchString,
                                      cb,
                                      true
                                    );
                                  }}
                                  placeholder={"Guest"}
                                  isDisabled={isDisable || isEvaluationTask}
                                  value={attributesData?.guest}
                                  onChange={(val: any) => {
                                    onChange("guest", val);
                                  }}
                                  isMulti={true}
                                  noIndicator={false}
                                  noSeparator={false}
                                  pluralPlaceHolder={"Guest"}
                                />

                                <span style={{ color: "red" }}>
                                  {formErrors["error_Guest"]}
                                </span>
                                <div className="dropdown-content">
                                  {attributesData?.guest?.length > 1 && (
                                    <>
                                      {attributesData?.guest?.map((i) => (
                                        <p key={uuid()}>{i?.label || ""}</p>
                                      ))}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-sm-3 col-xs-4">
                              <div className="mb-1">
                                <label className="col-form-label">
                                  Meeting Subject <sup>*</sup>
                                </label>
                                <InputForm
                                  className="form-control"
                                  placeholder={"Meeting subject"}
                                  isDisabled={isDisable || isEvaluationTask}
                                  textArea={true}
                                  rows={4}
                                  value={attributesData.meetingSubject}
                                  onChange={(val: any) => {
                                    let value = val.target.value;
                                    onChange("meetingSubject", value);
                                  }}
                                />

                                <span style={{ color: "red" }}>
                                  {formErrors["error_meetingSubject"]}
                                </span>
                              </div>
                            </div>
                            <div className="col-lg-3 col-sm-3 col-xs-4">
                              <div className="mb-1">
                                <label className="col-form-label">
                                  Meeting Description
                                </label>
                                <sup>*</sup>
                                <RTE
                                  content={
                                    attributesData?.meetingDescription || ""
                                  }
                                  disabled={isDisable || isEvaluationTask}
                                  onblur={(val: any) => {
                                    let value = val || "";
                                    onChange("meetingDescription", value);
                                  }}
                                />
                                <span style={{ color: "red" }}>
                                  {formErrors["error_meetingDescription"]}
                                </span>
                              </div>
                            </div>
                          </>
                        )}

                        <div className="col-lg-3 col-sm-3 col-xs-4">
                          <div className="dropdown mb-1">
                            <label className="col-form-label">
                              {showScheduleDateAndTime
                                ? "Scheduled by"
                                : "Conducted by"}
                              {showScheduleDateAndTime && <sup>*</sup>}
                            </label>
                            <SelectForm
                              isClearable
                              isSearchable
                              options={conductedByOptions}
                              placeholder={"Conducted By"}
                              isDisabled={true}
                              value={conductedByOptions}
                              onChange={(val: any) => {
                                onChange("conductedBy", val);
                              }}
                              isMulti={true}
                              noIndicator={false}
                              noSeparator={false}
                            />
                          </div>
                          <div className="dropdown-content">
                            {attributesData.responsiblePersons?.length > 1 && (
                              <>
                                {attributesData.responsiblePersons?.map((i) => (
                                  <p key={i?.label}>{i?.label || ""}</p>
                                ))}
                              </>
                            )}
                          </div>
                        </div>

                        {(attributesData.typeofActivity?.label === "Joining" ||
                          attributesData.typeofActivity?.label ===
                            "Initiate Onboarding") && (
                          <div className="col-lg-3 col-sm-3 col-xs-4">
                            <div className="mb-1">
                              <label className="col-form-label">
                                Scheduled Date Of Joining<sup>*</sup>
                              </label>
                              <DateForm
                                isDisabled={true}
                                value={attributesData.dateofJoining}
                                onChange={(val: any) => {
                                  onChange(
                                    "dateofJoining",
                                    moment(val).format("DD-MMM-YYYY")
                                  );
                                }}
                              />
                              {formErrors["dateofJoining"] && (
                                <p style={{ color: "red" }}>
                                  {formErrors["dateofJoining"]}
                                </p>
                              )}
                            </div>
                          </div>
                        )}

                        {!hideDecisionOptions && (
                          <>
                            <div className="col-lg-3 col-sm-3 col-xs-4">
                              <div className="mb-1">
                                <label className="col-form-label">
                                  Activity Status
                                  <sup>*</sup>
                                </label>
                                <SelectForm
                                  isClearable
                                  isSearchable
                                  options={activityStatusOptions}
                                  placeholder={"Activity Status"}
                                  isDisabled={isDisable}
                                  value={attributesData.activityStatus}
                                  onChange={(val: any) => {
                                    onChange("activityStatus", val);
                                    let obj = formErrors;
                                    delete obj["decision"];
                                    delete obj["outcome"];
                                    setFormErrors(obj);
                                  }}
                                  isMulti={false}
                                  noIndicator={false}
                                  noSeparator={false}
                                />
                                {formErrors["decision"] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors["decision"]}
                                  </p>
                                )}
                              </div>
                            </div>

                            <div className="col-lg-3 col-sm-3 col-xs-4">
                              <div className="mb-1">
                                <label className="col-form-label">
                                  Outcome
                                  <sup>*</sup>
                                </label>
                                <SelectForm
                                  isClearable
                                  isSearchable
                                  options={decisionOptions}
                                  placeholder={"Outcome"}
                                  isDisabled={isDisable}
                                  value={attributesData.decision}
                                  onChange={(val: any) => {
                                    /*If remark non-mandatory Uncomment this*/
                                    // if (
                                    //   val.code.toLowerCase() === "sendback" ||
                                    //   val.code.toLowerCase() === "rejected"
                                    // ) {
                                    //   setIsRemarkMandatory(true);
                                    // } else {
                                    //   setIsRemarkMandatory(false);
                                    //   let obj = formErrors;
                                    //   delete obj["remarks"];
                                    //   setFormErrors(obj);
                                    // }

                                    onChange("decision", val);
                                    let obj = formErrors;
                                    delete obj["outcome"];
                                    setFormErrors(obj);
                                  }}
                                  isMulti={false}
                                  noIndicator={false}
                                  noSeparator={false}
                                />
                                {formErrors["outcome"] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors["outcome"]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        )}

                        {showFeedbackDropdown && (
                          <>
                            <div className="col-lg-3 col-sm-3 col-xs-4">
                              <div className="mb-1">
                                <label className="col-form-label">
                                  Candidate Feedback
                                  <sup>*</sup>
                                </label>
                                <SelectForm
                                  isClearable
                                  isSearchable
                                  options={feedbackDropdownOptions}
                                  placeholder={"Candidate Feedback"}
                                  isDisabled={isDisable}
                                  value={attributesData.candidateFeedback}
                                  onChange={(val: any) => {
                                    onChange("candidateFeedback", val);
                                  }}
                                  isMulti={false}
                                  noIndicator={false}
                                  noSeparator={false}
                                />
                                {formErrors["candidateFeedback"] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors["candidateFeedback"]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </>

                      {attributesData.typeofActivity?.label === "Joining" && (
                        <div className="col-lg-3 col-sm-3 col-xs-4">
                          <div className="mb-1">
                            <label className="col-form-label">
                              Date Of Joining
                            </label>
                            <DateForm
                              isDisabled={props?.isMRFAdmin || false}
                              value={attributesData.dateofJoining}
                              onChange={(val: any) => {
                                onChange(
                                  "dateofJoining",
                                  moment(val).format("DD-MMM-YYYY")
                                );
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {onNegotiationHideandShow && (
                        <>
                          <SectionSeperator />

                          <div className="col-lg-12">
                            <SalaryNegotiation
                              disabled={true}
                              MRFId={mrfId}
                              CandidateId={candidateId}
                              CandidateWorkflowTaskId={negotiationId}
                              hideAccordian={true}
                              setHasDocument={setSalaryDocumentData}
                              key={negotiationId}
                            />
                          </div>

                          <SectionSeperator />
                          <div className="col-lg-12">
                            <div className="row">
                              <div className="col-lg-3 col-sm-3 col-xs-4">
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    Currency
                                  </label>{" "}
                                  <sup>*</sup>
                                  <SelectForm
                                    isClearable
                                    isSearchable
                                    options={currencyOption}
                                    placeholder={"Currency"}
                                    isDisabled={isDisable || negoReadOnly}
                                    value={attributesData?.currency}
                                    onChange={(val: any) => {
                                      onChange("currency", val);
                                    }}
                                    isMulti={false}
                                    noIndicator={false}
                                    noSeparator={false}
                                  />
                                  {formErrors["currency"] && (
                                    <p style={{ color: "red" }}>
                                      {formErrors["currency"]}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-3 col-sm-3 col-xs-4">
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    Current CTC <sup>*</sup>
                                  </label>
                                  <InputForm
                                    className="form-control"
                                    placeholder={"Current CTC"}
                                    isDisabled={isDisable || negoReadOnly}
                                    textArea={false}
                                    value={attributesData.currentCTC}
                                    onChange={(val: any) => {
                                      let value = val.target.value;
                                      onChange("currentCTC", value);
                                    }}
                                    type={"number"}
                                  />
                                  {formErrors["currentCTC"] && (
                                    <p style={{ color: "red" }}>
                                      {formErrors["currentCTC"]}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-3 col-sm-3 col-xs-4">
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    CTC Expected by Candidate <sup>*</sup>
                                  </label>
                                  <InputForm
                                    className="form-control"
                                    placeholder={"CTC Expected by Candidate"}
                                    isDisabled={isDisable || negoReadOnly}
                                    textArea={false}
                                    value={
                                      attributesData.cTCExpectedByCandidate
                                    }
                                    onChange={(val: any) => {
                                      let value = val.target.value;
                                      onChange("cTCExpectedByCandidate", value);
                                    }}
                                    type={"number"}
                                  />
                                  {formErrors["cTCExpectedByCandidate"] && (
                                    <p style={{ color: "red" }}>
                                      {formErrors["cTCExpectedByCandidate"]}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-3 col-sm-3 col-xs-4">
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    Offered CTC <sup>*</sup>
                                  </label>
                                  <InputForm
                                    className="form-control"
                                    placeholder={"Offered CTC"}
                                    isDisabled={isDisable || negoReadOnly}
                                    textArea={false}
                                    value={attributesData.offeredCTC}
                                    onChange={(val: any) => {
                                      let value = val.target.value;
                                      onChange("offeredCTC", value);
                                    }}
                                    type={"number"}
                                  />
                                  {formErrors["offeredCTC"] && (
                                    <p style={{ color: "red" }}>
                                      {formErrors["offeredCTC"]}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-3 col-sm-3 col-xs-4">
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    Fixed Pay <sup>*</sup>
                                  </label>
                                  <InputForm
                                    className="form-control"
                                    placeholder={"Fixed Pay"}
                                    isDisabled={isDisable || negoReadOnly}
                                    textArea={false}
                                    value={attributesData.fixedPay}
                                    onChange={(val: any) => {
                                      let value = val.target.value;
                                      onChange("fixedPay", value);
                                    }}
                                    type={"number"}
                                  />
                                  {formErrors["fixedPay"] && (
                                    <p style={{ color: "red" }}>
                                      {formErrors["fixedPay"]}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-3 col-sm-3 col-xs-4">
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    Variable Pay <sup>*</sup>
                                  </label>
                                  <InputForm
                                    className="form-control"
                                    placeholder={"Variable Pay"}
                                    isDisabled={isDisable || negoReadOnly}
                                    textArea={false}
                                    value={attributesData.variablePay}
                                    onChange={(val: any) => {
                                      let value = val.target.value;
                                      onChange("variablePay", value);
                                    }}
                                    type={"number"}
                                  />
                                  {formErrors["variablePay"] && (
                                    <p style={{ color: "red" }}>
                                      {formErrors["variablePay"]}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-3 col-sm-3 col-xs-4">
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    Notice Pay <sup>*</sup>
                                  </label>
                                  <InputForm
                                    className="form-control"
                                    placeholder={"Notice Pay"}
                                    isDisabled={isDisable || negoReadOnly}
                                    textArea={false}
                                    value={attributesData.noticePay}
                                    onChange={(val: any) => {
                                      let value = val.target.value;
                                      onChange("noticePay", value);
                                    }}
                                    type={"number"}
                                  />
                                  {formErrors["noticePay"] && (
                                    <p style={{ color: "red" }}>
                                      {formErrors["noticePay"]}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-3 col-sm-3 col-xs-4">
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    Relocation Allowance <sup>*</sup>
                                  </label>
                                  <InputForm
                                    className="form-control"
                                    placeholder={"Relocation Allowance"}
                                    isDisabled={isDisable || negoReadOnly}
                                    textArea={false}
                                    value={attributesData.relocationAllowance}
                                    onChange={(val: any) => {
                                      let value = val.target.value;
                                      onChange("relocationAllowance", value);
                                    }}
                                    type={"number"}
                                  />
                                  {formErrors["relocationAllowance"] && (
                                    <p style={{ color: "red" }}>
                                      {formErrors["relocationAllowance"]}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-3 col-sm-3 col-xs-4">
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    Percentage Hike <sup>*</sup>
                                  </label>
                                  <InputForm
                                    className="form-control"
                                    placeholder={"Percentage Hike"}
                                    isDisabled={true}
                                    textArea={false}
                                    value={attributesData.percentageHike}
                                    onChange={(val: any) => {
                                      let value = val.target.value;
                                      onChange("percentageHike", value);
                                    }}
                                  />
                                  {formErrors["percentageHike"] && (
                                    <p style={{ color: "red" }}>
                                      {formErrors["percentageHike"]}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="col-lg-3 col-sm-3 col-xs-4">
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    Notice Period (Days) <sup>*</sup>
                                  </label>
                                  <InputForm
                                    className="form-control"
                                    placeholder={"Notice Period (Days)"}
                                    isDisabled={isDisable || negoReadOnly}
                                    textArea={false}
                                    value={attributesData.noticePeriodDays}
                                    onChange={(val: any) => {
                                      let value = val.target.value;
                                      onChange("noticePeriodDays", value);
                                    }}
                                    type={"number"}
                                  />
                                  {formErrors["noticePeriodDays"] && (
                                    <p style={{ color: "red" }}>
                                      {formErrors["noticePeriodDays"]}
                                    </p>
                                  )}
                                </div>
                              </div>

                              {
                                <div className="col-lg-3 col-sm-3 col-xs-4">
                                  <div className="mb-1">
                                    <label className="col-form-label">
                                      Date of Joining <sup>*</sup>
                                    </label>
                                    <DateForm
                                      isDisabled={isDisable || negoReadOnly}
                                      disablePast={true}
                                      value={attributesData.dateofJoining}
                                      onChange={(val: any) => {
                                        onChange(
                                          "dateofJoining",
                                          moment(val).format("DD-MMM-YYYY")
                                        );
                                      }}
                                    />
                                    {formErrors["dateofJoining"] && (
                                      <p style={{ color: "red" }}>
                                        {formErrors["dateofJoining"]}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              }

                              <div className="col-lg-3 col-sm-3 col-xs-4">
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    Offer Acceptance Due Date <sup>*</sup>
                                  </label>
                                  <DateForm
                                    isDisabled={isDisable || negoReadOnly}
                                    value={
                                      attributesData.offerAcceptanceDueDate
                                    }
                                    onChange={(val: any) => {
                                      onChange(
                                        "offerAcceptanceDueDate",
                                        moment(val).format("DD-MMM-YYYY")
                                      );
                                    }}
                                  />
                                  {formErrors["offerAcceptanceDueDate"] && (
                                    <p style={{ color: "red" }}>
                                      {formErrors["offerAcceptanceDueDate"]}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {onNegotiationHideandShow && (
                        <>
                          <div className="col-lg-3 col-sm-3 col-xs-4">
                            <div className="mb-1">
                              <label className="col-form-label">
                                Employee Class
                              </label>{" "}
                              {/* <sup>*</sup> */}
                              <SelectForm
                                isClearable
                                isSearchable
                                options={employeeClassOptions}
                                placeholder={"Employee Class"}
                                isDisabled={true}
                                // isDisabled={isDisable || negoReadOnly}
                                value={attributesData.employeeClass}
                                onChange={(val: any) => {
                                  onChange("employeeClass", val);
                                }}
                                isMulti={false}
                                noIndicator={false}
                                noSeparator={false}
                              />
                              {/* {formErrors["employeeClass"] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors["employeeClass"]}
                                  </p>
                                )} */}
                            </div>
                          </div>

                          <div className="col-lg-3 col-sm-3 col-xs-4">
                            <div className="mb-1">
                              <label className="col-form-label">Job Band</label>{" "}
                              {/* <sup>*</sup> */}
                              <SelectForm
                                isClearable
                                isSearchable
                                options={jobBandOptions}
                                placeholder={"Job Band"}
                                isDisabled={true}
                                //isDisabled={isDisable || negoReadOnly}
                                value={attributesData.jobBand}
                                onChange={(val: any) => {
                                  onChange("jobBand", val);
                                }}
                                isMulti={false}
                                noIndicator={false}
                                noSeparator={false}
                              />
                              {/* {formErrors["jobBand"] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors["jobBand"]}
                                  </p>
                                )} */}
                            </div>
                          </div>

                          <div className="col-lg-3 col-sm-3 col-xs-4">
                            <div className="mb-1">
                              <label className="col-form-label">
                                Job Level
                              </label>{" "}
                              <sup>*</sup>
                              <SelectForm
                                isClearable
                                isSearchable
                                options={jobLeveloptions}
                                placeholder={"Job Level"}
                                isDisabled={isDisable || negoReadOnly}
                                value={attributesData.jobLevel}
                                onChange={(val: any) => {
                                  onChange("jobLevel", val);
                                }}
                                isMulti={false}
                                noIndicator={false}
                                noSeparator={false}
                              />
                              {formErrors["jobLevel"] && (
                                <p style={{ color: "red" }}>
                                  {formErrors["jobLevel"]}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-3 col-sm-3 col-xs-4">
                            <div className="mb-1">
                              <label className="col-form-label">
                                Designation
                              </label>{" "}
                              <sup>*</sup>
                              <SelectForm
                                isClearable
                                isSearchable
                                options={designationOptions}
                                placeholder={"Designation"}
                                isDisabled={isDisable || negoReadOnly}
                                value={attributesData.designation}
                                onChange={(val: any) => {
                                  onChange("designation", val);
                                }}
                                isMulti={false}
                                noIndicator={false}
                                noSeparator={false}
                              />
                              {formErrors["designation"] && (
                                <p style={{ color: "red" }}>
                                  {formErrors["designation"]}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-3 col-sm-3 col-xs-4">
                            <div className="mb-1">
                              <label className="col-form-label">
                                Client Facing Title
                              </label>{" "}
                              <sup>*</sup>
                              <InputForm
                                className="form-control"
                                placeholder={"Client Facing Title"}
                                isDisabled={isDisable || negoReadOnly}
                                textArea={false}
                                value={attributesData.clientFacingTitle}
                                onChange={(val: any) => {
                                  let value = val.target.value;
                                  onChange("clientFacingTitle", value);
                                }}
                              />
                              {formErrors["clientFacingTitle"] && (
                                <p style={{ color: "red" }}>
                                  {formErrors["clientFacingTitle"]}
                                </p>
                              )}
                            </div>
                          </div>
                        </>
                      )}

                      {!hideDecisionOptions && (
                        <div className="col-lg-3 col-sm-3 col-xs-4">
                          <div className="mb-1">
                            <label className="col-form-label">Remarks</label>
                            {<sup>*</sup>}
                            <InputForm
                              className="form-control"
                              placeholder={"Remarks"}
                              isDisabled={isDisable}
                              textArea={true}
                              rows={4}
                              value={attributesData.remarks}
                              onChange={(val: any) => {
                                let value = val.target.value;
                                onChange("remarks", value);
                              }}
                            />
                            {formErrors["remarks"] && (
                              <p style={{ color: "red" }}>
                                {formErrors["remarks"]}
                              </p>
                            )}
                          </div>
                        </div>
                      )}

                      <NegotiationDiffernce
                        Stage={attributesData.stage}
                        negoDiffNote={negoDiffNote}
                      />

                      {showFeedbackUpload && (
                        <>
                          <div className="col-lg-3 col-sm-3 col-xs-4">
                            <div className="mb-4">
                              <label className="col-form-label"></label>

                              <div>
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    setShowInterviewQuestionsModal(true)
                                  }
                                >
                                  <strong style={{ color: "Blue" }}>
                                    Interview Question
                                  </strong>
                                </span>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {showFeedbackUpload && (
                        <div className="col-lg-3 col-sm-3 col-xs-4">
                          <div className="mb-4">
                            <label className="col-form-label"></label>
                            <div>
                              <span
                                style={{ cursor: "pointer" }}
                                hidden={!currentGridId}
                                onClick={() => {
                                  setCwtId(currentGridId);
                                  setIsOpenModalPopup(true);
                                }}
                              >
                                <strong style={{ color: "Blue" }}>
                                  Interview Evaluation
                                </strong>
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                      {showViewFeedback && (
                        <div className="col-lg-3 col-sm-3 col-xs-4">
                          <div className="mb-4">
                            <label className="col-form-label"></label>
                            <div>
                              <span
                                style={{ cursor: "pointer" }}
                                hidden={!currentGridId}
                                onClick={() => {
                                  setCwtId(currentGridId);
                                  setModel(true);
                                }}
                              >
                                <strong style={{ color: "Blue" }}>
                                  View Feedback
                                </strong>
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {/* DONT REMOVE THIS IMP */}
                  {(attributesData.typeofActivity?.label ===
                    "Background Verification" ||
                    attributesData.typeofActivity?.label ===
                      "Reference Check" ||
                    attributesData.typeofActivity?.label ===
                      "Psychometric Test") && (
                    <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                      <div className="mb-1">
                        <label className="col-form-label">Documents</label>

                        <div className="box position-relative">
                          <input
                            id={"DOC"}
                            className="form-control inputfile inputfile-6 multiple-inputfile"
                            data-multiple-caption="{count} files selected"
                            multiple={true}
                            type="file"
                            ref={documentRef}
                            onChange={(e) => {
                              handleFileChange(e, attributeName);
                            }}
                            accept={
                              ".docx,.doc,.pdf,.png,.jpg,.jpeg,.txt,.ppt,.pptx,.xlsx"
                            }
                            disabled={props?.isMRFAdmin || false}
                          />
                          <label
                            htmlFor={"DOC"}
                            className="form-control"
                            style={{ width: 0, border: "none" }}
                          >
                            <strong
                              style={{
                                padding: "6px 16px",
                                backgroundColor: "#3c5464",
                                borderRadius: "5px",
                              }}
                            >
                              <i
                                className="fa fa-upload rotate90"
                                aria-hidden="true"
                              ></i>
                              {"  "}
                              Upload
                            </strong>{" "}
                          </label>
                        </div>
                        <div className="file-added-list">
                          <ul className="list-unstyle">
                            {selectedFilesForSet &&
                              selectedFilesForSet.length > 0 &&
                              selectedFilesForSet.map(
                                (fileAttribute, attributeIndex) => (
                                  <>
                                    <li
                                      className="list mt-1"
                                      key={attributeIndex}
                                    >
                                      <div className="media">
                                        <div className="media-body text-truncate">
                                          <span className="view-more">
                                            {fileAttribute.Name}
                                          </span>
                                        </div>

                                        <div className="media-right ml-2">
                                          <i
                                            className="fa-solid fa-download"
                                            aria-hidden="true"
                                            onClick={() =>
                                              handleDownloadForSet(
                                                fileAttribute,
                                                attributeIndex
                                              )
                                            }
                                          ></i>
                                        </div>

                                        <div
                                          className="media-right ml-2"
                                          hidden={isDisable}
                                        >
                                          <i
                                            className="fa fa-trash"
                                            aria-hidden="true"
                                            onClick={() =>
                                              handleDeleteFileForSet(
                                                fileAttribute,
                                                attributeIndex
                                              )
                                            }
                                          ></i>
                                        </div>
                                      </div>
                                    </li>
                                  </>
                                )
                              )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="row">
                  {ShowAttrSet && (
                    <div className="col-lg-12 mt-2 mb-3 d-flex justify-content-end">
                      {showScheduleDateAndTime && !isEvaluationTask && (
                        <button
                          type="button"
                          className="btn btn-primary mr-2"
                          disabled={isDisable}
                          onClick={handleSendInvite}
                        >
                          {attributesData?.meetingId
                            ? "Reschedule Invite"
                            : "Send Invite"}
                        </button>
                      )}

                      {!isDisable && currentGridId != null && (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={(e) => {
                            if (
                              attributesData.stage ==
                              CANDIDATE_STAGES.Negotiation
                            ) {
                              const isError = validateData();
                              if (isError) return;
                              //show confirmation Modal

                              if (!haveSalaryDocument) {
                                setSalaryNotificationModal(true);
                                return;
                              }

                              setShowNegoConfirmationModal(true);
                            } else {
                              onSubminFunction();
                            }
                          }}
                          disabled={isEdit ? disabledSubmitBtn : true}
                        >
                          <i
                            className={
                              currentGridId === null
                                ? "fa fa-plus"
                                : "fas fa-edit"
                            }
                          ></i>
                          Update Record
                        </button>
                      )}
                    </div>
                  )}

                  <div className="col-lg-12 p-0 mt-2 mb-6 px-3">
                    {showScheduleDateAndTime && (
                      <>
                        <EmptyAccordian
                          header={"Interview Panel Available Timeslots"}
                        >
                          <ResponsiblePersonScheduleSlots
                            userName={attributesData.responsiblePersons}
                            startDateTime={
                              attributesData?.scheduledDateandTime || null
                            }
                            endDateTime={
                              attributesData?.scheduledEndDateandTime || null
                            }
                            setSlotsData={setTimeSlotData}
                          />
                        </EmptyAccordian>
                      </>
                    )}
                  </div>

                  <div className="col-lg-12 p-0 mt-2 mb-3">
                    <DynamicGrid
                      options={gridOptions}
                      data={gridData}
                      columns={dashboardColumns}
                    />
                  </div>
                </div>
              </div>
            </React.Fragment>
          </div>
        </EmptyAccordian>
      </div>

      {/**
       * Interview Question Model
       */}
      <Modal
        show={showInterviewQuestionsModal}
        onHide={() => setShowInterviewQuestionsModal(false)}
        backdrop="static"
        keyboard={false}
        size="xl"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">Interview Question</div>
        </div>
        <Modal.Body>
          <>
            <InterviewQuestion />
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowInterviewQuestionsModal(false);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      {/* Confirmation Message Modal */}
      <Modal
        show={showNegoConfirmationModal}
        onHide={() => setShowNegoConfirmationModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">Confirmation!</div>
        </div>
        <Modal.Body>
          <p>
            Hope you have rechecked all offer details. Once you update the
            record you won't be allowed to make any changes. Are you sure you
            want to submit the Offer details?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowNegoConfirmationModal(false);
              onSubminFunction();
            }}
          >
            Yes
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowNegoConfirmationModal(false);
            }}
          >
            No
          </button>
        </Modal.Footer>
      </Modal>

      {/*
        Feedback form Upload Model
      */}
      <>
        {showFeedbackFormModal && (
          <FeedbackForm
            feedbackData={feedBackUploadData}
            isView={isView}
            modal={showFeedbackFormModal}
            setModal={setShowFeedbackFormModal}
          />
        )}
      </>

      {/*
         Feedback View Model
      */}
      <>
        {model && cwtId > 0 && tempFeedbackModelDetails?.stage && (
          <FeedbackFormView
            candidateId={candidateId}
            candidateName={tempFeedbackModelDetails?.candidateName}
            stageId={tempFeedbackModelDetails?.stage}
            mrfId={mrfId}
            evaluators={tempFeedbackModelDetails?.evaluators}
            modal={model}
            setModal={setModel}
          />
        )}
      </>

      {/* <StagesDynamicSections
        isIJP={sourceName?.toLocaleLowerCase().trim() === "ijp"}
        employeeId={employeeId}
        isMRFAdmin={props?.isMRFAdmin || false}
      /> */}

      <div className="col-lg-12">
        <CandidateForm
          MRFId={mrfId}
          CandidateId={candidateId}
          isIJP={
            sourceName?.toLocaleLowerCase().trim() === "ijp" ||
            sourceName?.toLocaleLowerCase().trim() === "werise"
          }
          isDisableApplication={true}
          source={sourceName}
          isRecruiterEdit={isRecruiterForCurrentMRF}
        />
      </div>

      {/* Offer Letter View */}
      <Modal
        show={offerLetterModelOpen}
        onHide={() => setOfferLetterModelOpen(false)}
        backdrop="static"
        keyboard={false}
        size="xl"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">Candidate Offer Letter</div>
        </div>
        <Modal.Body>
          <div
            className=""
            style={{
              height: "60vh",
              overflowX: "hidden",
            }}
          >
            <PDFViewer pdfURL={candidateOfferLetterDetails?.base64 || ""} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{
              marginLeft: 5,
              color: "black !important",
              borderColor: "#ACACAC",
            }}
            onClick={() => {
              setOfferLetterModelOpen(false);
              downloadCandiateOfferLetter();
            }}
          >
            <i className="fa-solid fa-download mr-2"></i>
            Download
          </button>
          <button
            className="btn"
            style={{
              marginLeft: 5,
              color: "black !important",
              borderColor: "#acacac",
            }}
            onClick={() => {
              setOfferLetterModelOpen(false);
            }}
          >
            Ok
          </button>
        </Modal.Footer>
      </Modal>

      {/* Cancel Meeting Modal */}
      <CustomModal
        message={"Are you sure want to cancel meeting?"}
        modal={cancelMeetingModal}
        setModal={setCancelMeetingModal}
        yesCallback={cancelMeetingAsync}
        noCallback={closeCancelMeetingModal}
      />

      <ConfirmationModal
        message={
          "Candidate has not submitted documents yet, are you sure you want to proceed further?"
        }
        modal={salaryNotificationModal}
        setModal={setSalaryNotificationModal}
        hideConfirmMation={false}
        confirmCallback={() => {
          setSalaryNotificationModal(false);
          setShowNegoConfirmationModal(true);
        }}
        noCallback={() => {
          setSalaryNotificationModal(false);
        }}
      />
    </>
  );
};

export default Stages;

const PDFViewer = ({ pdfURL }) => {
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  return (
    <div>
      <>
        <Document
          className="img-fluid invoice-img"
          file={`data:application/pdf;base64,${pdfURL}`}
          onLoadSuccess={({ numPages }) => {
            setNumberOfPages(numPages);
          }}
        >
          <Page
            size="A4"
            pageNumber={pageNumber}
            renderMode="svg"
            scale={1.5}
          />
          {Array.from(new Array(numberOfPages), (el, index) => (
            <Page
              scale={1.5}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
            />
          ))}
        </Document>
      </>
    </div>
  );
};

const CustomModal = ({ message, modal, setModal, yesCallback, noCallback }) => {
  return (
    <>
      <Modal
        show={modal}
        onHide={() => setModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">Confirmation!</div>
        </div>
        <Modal.Body>
          <p>{message}</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={yesCallback}
          >
            Yes
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={noCallback}
          >
            No
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const ResponsiblePersonScheduleSlots = ({
  userName = [],
  startDateTime = null,
  endDateTime = null,
  setSlotsData,
}) => {
  const [gridData, setGridData] = useState([]);
  const { showLoader, hideLoader } = useContext(LoaderContext);

  useEffect(() => {
    const helper = async () => {
      await GetData();
    };

    if (userName && startDateTime && endDateTime) {
      helper();
    }
  }, [userName, startDateTime, endDateTime]);

  async function GetData() {
    try {
      const isTimeValid = validateScheduleDateTime(startDateTime, endDateTime);
      if (!isTimeValid) return;

      showLoader();
      const response = await APICall(CheckUserAvailablityForMeeting, "POST", {
        StartDateTime: moment(startDateTime).toISOString(),
        EndDateTime: moment(endDateTime).toISOString(),
        ResponsiblePersonUserId: userName
          ?.map((i) => i?.value || "")
          ?.join(","),
      });

      if (response?.status == 0) {
        setGridData(response?.data);
        setSlotsData(response?.data);
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  }

  const gridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    rowsPerPage: gridData.length,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "scroll",

    //standard | vertical | simple
    onChangeRowsPerPage: (num) => {},

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
      }
      if (sortDirection === "desc") {
      }
    },
    onChangePage: async (page) => {},
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const dashboardColumns = [
    {
      name: "userName",
      label: "Interview Panelist",
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: "availableTimeSlots",
      label: "Available Time Slots",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { minWidth: "300px", maxWidth: "300px", textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta) => {
          if (!value)
            return (
              <div className="d-flex justify-content-center">
                <span style={{ fontWeight: "bold" }}>NO SLOTS AVAILABLE</span>
              </div>
            );
          return <>{value}</>;
        },
      },
    },
  ];

  return (
    <>
      <DynamicGrid
        options={gridOptions}
        data={gridData}
        columns={dashboardColumns}
      />
    </>
  );
};

const NegotiationDiffernce = ({ Stage, negoDiffNote }) => {
  return (
    <>
      {(Stage == CANDIDATE_STAGES.Negotiation ||
        Stage == CANDIDATE_STAGES.OfferApproval) &&
        negoDiffNote.length > 0 && (
          <div className="mt-4 mb-4 col-lg-6 col-sm-6 col-xs-6">
            {negoDiffNote.map((note) => (
              <>
                {note?.empClass && note?.empClass?.newData && (
                  <p className="text-danger">{`Employee Class revised from ${
                    note?.empClass?.oldData?.label || "N/A"
                  } to ${note?.empClass?.newData?.label}`}</p>
                )}
                {note?.jobBand && note?.jobBand?.newData && (
                  <p className="text-danger">{`Job Band revised from ${
                    note?.jobBand?.oldData?.label || "N/A"
                  } to ${note?.jobBand?.newData?.label}`}</p>
                )}
                {note?.jobLevel && note?.jobLevel?.newData && (
                  <p className="text-danger">{`Job Level revised from ${
                    note?.jobLevel?.oldData?.label || "N/A"
                  } to ${note?.jobLevel?.newData?.label}`}</p>
                )}
                {note?.designation && note?.designation?.newData && (
                  <p className="text-danger">{`Designation revised from ${
                    note?.designation?.oldData?.label || "N/A"
                  } to ${note?.designation?.newData?.label}`}</p>
                )}
                {note?.clientFT && note?.clientFT?.newData && (
                  <p className="text-danger">{`Client-facing title revised from ${
                    note?.clientFT?.oldData || ""
                  } to ${note?.clientFT?.newData}`}</p>
                )}
              </>
            ))}
          </div>
        )}
    </>
  );
};

const SectionSeperator = () => {
  return (
    <>
      <div
        style={{
          color: "#cccccc",
          backgroundColor: "#cccccc",
          height: 1,
          marginTop: 10,
        }}
        className="col-lg-12 col-sm-12 col-xs-12"
      ></div>
    </>
  );
};

function IsEmpty(value, acceptZero = false) {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value === "object") {
    // Check for empty object
    if (Object.keys(value).length === 0) {
      return true;
    }
    // Check for empty array
    if (Array.isArray(value) && value.length === 0) {
      return true;
    }
  }

  if (typeof value === "string") {
    // Check for empty string after trimming
    if (value.trim() === "" || (value.trim() === "0" && !acceptZero)) {
      return true;
    }
  }

  if (typeof value === "number") {
    // Check for zero or NaN
    if ((value === 0 && !acceptZero) || Number.isNaN(value)) {
      return true;
    }
  }

  return false;
}
