import React, { useContext, useEffect, useState } from "react";
import { APICall } from "../../Helpers/APICalls";
import {
  insertWorkFlowRecord,
  getWorkFlowTypes,
  getWorkflowTypeReason,
  getModulesList,
  getRolesList,
  GetWorkflowDetailsById,
  insertWorkflowStepsDetails,
  getStepsForWorkFlowList,
  getDropdowns,
  insertWFEmailDetails,
  getWorkFlowEmailList,
  getEmailTempeleteList,
} from "../../Helpers/APIEndPoints/EndPoints";
import "./WorkFlowForm.css";
import ExcelJS from "exceljs";
import Modal from "react-bootstrap/Modal";
import SelectForm from "../../Components/SelectForm/SelectForm";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import notify from "../../Helpers/ToastNotification";
import { LoaderContext } from "../../Helpers/Context/Context";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";

import { ExpandMore } from "@mui/icons-material";
import { useLocation } from "react-router-dom";

import InputForm from "../../Components/InputForm/InputForm";

import { useNavigate } from "react-router-dom";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { Button } from "react-bootstrap";

const WorkFlowForm = () => {
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [panal1, setPanal1] = useState(true);
  const [workflowName, setWorkflowName] = useState("");
  const [systemName, setSystemName] = useState("");
  const [systemNameError, setSystemNameError] = useState("");
  const [workflowTypeIdDropdownOptions, setWorkflowTypeIdDropdownOptions] =
    useState([]);
  const [workflowTypeIdDropdownValue, setWorkflowTypeIdDropdownValue] =
    useState(null);
  const [workflowTypeIdErrorDropdown, setWorkflowTypeIdErrorDropdown] =
    useState("");

  const [
    workFlowTypeReasonDropdownOptions,
    setWorkFlowTypeReasonDropdownOptions,
  ] = useState([]);
  const [workFlowTypeReasonDropdownValue, setWorkFlowTypeReasonDropdownValue] =
    useState(null);
  const [workFlowTypeReasonErrorDropdown, setWorkFlowTypeReasonErrorDropdown] =
    useState("");

  const [moduleIdDropdownValue, setModuleIdDropdownValue] = useState(null);
  const [moduleIdDropdownOptions, setModuleIdDropdownOptions] = useState([]);
  const [moduleIdErrorDropdown, setModuleIdErrorDropdown] = useState(null);

  const [roleDropdownOptions, setRoleDropdownOptions] = useState([]);
  const [roleDropdownValue, setRoleDropdownValue] = useState(null);

  const [FYIDropdownOptions, setFYIDropdownOptions] = useState([]);
  const [FYIDropdownValue, setFYIDropdownValue] = useState(null);

  const [CountryDropdownOptions, setCountryDropdownOptions] = useState([]);
  const [CountryDropdownValue, setCountryDropdownValue] = useState(null);
  const [countryErrorDropdown, setCountryErrorDropdown] = useState(null);

  const [PayLocDropdownOptions, setPayLocDropdownOptions] = useState([]);
  const [PayLocDropdownValue, setPayLocDropdownValue] = useState(null);
  const [displayPayLoc, setDisplayPayLoc] = useState(false);

  const [isActive, setIsActive] = useState(true);
  const [isBudget, setIsBudget] = useState(false);
  const [displayBudget, setDisplayBudget] = useState(false);

  const [isDeputyManager, setIsAboveDeputyManager] = useState(false);
  const [displayDM, setDisplayDM] = useState(false);
  const [isAVP, setIsAVP] = useState(false);
  const [displayAvp, setDisplayAvp] = useState(false);
  const navigate = useNavigate();
  const { userDetails, currentRoleId }: any = useUserContext();
  const { state } = useLocation();
  const [UId, setUId] = useState(state !== null && state.UId ? state.UId : 0);
  const [MUId, setMUId] = useState(
    state !== null && state.MUId ? state.MUId : 0
  );
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [panal2, setPanal2] = useState(true);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [PageSize, setPageSize] = useState(10);
  const [sortDirection, setSortDirection] = useState("");
  const [sortColumn, setSortColumn] = useState("");

  const [roleIDDropdownOptions, setRoleIDDropdownOptions] = useState([]);
  const [roleIDDropdownValue, setRoleIDDropdownValue] = useState(null);
  const [roleIDErrorDropdown, setRoleIDErrorDropdown] = useState(null);

  const [stageName, setStageName] = useState("");
  const [sequenceNumber, setSequenceNumber] = useState<any>("");
  const [sequenceErrorDropdown, setSequenceErrorDropdown] = useState(null);
  const [roleType, setRoleType] = useState(null);
  const [roleTypeOptions, setRoleTypeOptions] = useState<
    { value: number; label: string }[]
  >([
    { value: 1, label: "Global" },
    { value: 2, label: "Entity" },
    { value: 3, label: "Function" },
  ]);
  const [isActive2, setIsActive2] = useState(true);
  const [IsAcknowledgedRequired, setAcknowledgeRequired] = useState(false);

  const [addDetailsDropdownValue, setAddDetailsDropdownValue] = useState([]);
  const [addDetailsDropdownOptions, setAddDetailsDropdownOptions] = useState([
    { value: 1, label: "Approved" },
    { value: 2, label: "Rejected" },
    { value: 3, label: "Sendback" },
  ]);
  const [addDetailsDropDownError, setAddDetailsDropDownError] = useState(null);
  const [displayAddDetails, setDisplayAddDetails] = useState(true);
  const [RId, setRId] = useState(0);

  // modal start
  const [isEdit, setIsEdit] = useState(false);
  const [EId, setEId] = useState(0);
  const [rowInx, setRowInx] = useState(0);
  const [showFormModal, setShowFormModal] = useState(false);

  const [rowData2, setRowData2] = useState([]);
  const [count2, setCount2] = useState(0);
  const [page2, setPage2] = useState(0);
  const [sortDirection2, setSortDirection2] = useState("");
  const [sortColumn2, setSortColumn2] = useState("");

  const [EmailTempDropdownOptions, setEmailTempDropdownOptions] = useState([]);
  const [emailTemplateError, setEmailTemplateError] = useState("");
  // const [EmailTempDropdownValue, setEmailTempDropdownValue] = useState(null);

  const [toRoleDropdownOptions, setToRoleDropdownOptions] = useState([]);
  const [toRolesError, setToRolesError] = useState("");
  // const [toRoleDropdownValue, setToRoleValue] = useState(null);

  const [ccRoleDropdownOptions, setCCRoleDropdownOptions] = useState([]);
  const [ccRolesError, setCcRolesError] = useState("");
  // const [ccRoleDropdownValue, setCCRoleValue] = useState(null);

  const [actionIdError, setActionIdError] = useState("");
  const [ActionDropdownOptions, setActionDropdownOptions] = useState([
    { value: 0, label: "Initiate" },
    { value: 1, label: "Approved" },
    { value: 2, label: "Rejected" },
    { value: 3, label: "Sendback" },
    { value: 4, label: "Finally Approved" },
  ]);
  // const [actionDropdownValue, setActionDropdownValue] = useState(null);

  const [newRecord, setNewRecord] = useState({
    rowIndex: 0,
    id: 0,
    workFlowStepsId: MUId[0],
    emailTempleteNames: null,
    emailTempleteId: null,
    toRoles: [],
    toRolesNames: [],
    ccRoles: [],
    ccRolesNames: [],
    actionId: null,
    isActive: isActive,
  });
  // modal end

  useEffect(() => {
    fetchEmailTmp();
    fetchWorkflowTypeIdData();
    fetchWorkFlowTypeReasonData();
    fetchModuleID();
    fetchRoleID();
    CountryOptionValue();
    PLocOptionValue();
  }, []);
  console.log("state id", state?.UId);

  useEffect(() => {
    getWorkFlowId();
  }, [
    workflowName,
    systemName,
    workflowTypeIdDropdownOptions,
    workFlowTypeReasonDropdownOptions,
    moduleIdDropdownOptions,
    roleDropdownOptions,
    FYIDropdownOptions,
    CountryDropdownOptions,
    PayLocDropdownOptions,
  ]);

  useEffect(() => {
    getGridStructureOne();
    setPanal2(true);
  }, []);

  // From main Dashboard Edit fetch data
  const getWorkFlowId = async () => {
    showLoader();
    if (
      state !== null &&
      state?.UId > 0 &&
      workflowName.length === 0 &&
      systemName.length === 0 &&
      workflowTypeIdDropdownOptions.length > 0 &&
      workFlowTypeReasonDropdownOptions.length > 0 &&
      moduleIdDropdownOptions.length > 0 &&
      roleDropdownOptions.length > 0 &&
      FYIDropdownOptions.length > 0 &&
      CountryDropdownOptions.length > 0 &&
      PayLocDropdownOptions.length > 0
    ) {
      try {
        const GetEditUserRoleResp = await APICall(
          GetWorkflowDetailsById,
          "POST",
          {
            Id: state?.UId,
          }
        );

        const WorkFlowData = GetEditUserRoleResp.data;

        // Set the values into the state
        setWorkflowName(WorkFlowData.name);
        setSystemName(WorkFlowData.systemName);
        setIsFormSubmitted(true);
        const workflowTypeId = WorkFlowData.workflowTypeId;
        const workflowTypeObj = workflowTypeIdDropdownOptions.find(
          (item) => item.value === workflowTypeId
        );
        setWorkflowTypeIdDropdownValue(workflowTypeObj);

        const workflowReason = WorkFlowData.workflowTypeReason;
        const workflowReasonObj = workFlowTypeReasonDropdownOptions.find(
          (item) => item.value === workflowReason
        );
        setWorkFlowTypeReasonDropdownValue(workflowReasonObj);

        const moduleOp = WorkFlowData.moduleId;
        const moduleOpObj = moduleIdDropdownOptions.find(
          (item) => item.value === moduleOp
        );
        setModuleIdDropdownValue(moduleOpObj);

        const initiatorRoleOp = WorkFlowData.initiatorRoleId;
        const initiatorRoleOpObj = roleDropdownOptions.find(
          (item) => item.value === initiatorRoleOp
        );
        setRoleDropdownValue(initiatorRoleOpObj);

        const fyiRoleOp = WorkFlowData.fyiRoleId;
        const fyiRoleOpObj = FYIDropdownOptions.find(
          (item) => item.value === fyiRoleOp
        );
        setFYIDropdownValue(fyiRoleOpObj);

        const CountryOp = WorkFlowData.countryId;
        const CountryOpObj = CountryDropdownOptions.find(
          (item) => item.value === CountryOp
        );
        setCountryDropdownValue(CountryOpObj);

        const payLocOp = WorkFlowData.payrollLocationId;
        const payLocOpObj = PayLocDropdownOptions.find(
          (item) => item.value === payLocOp
        );
        setPayLocDropdownValue(payLocOpObj);

        setIsActive(WorkFlowData.isActive || false);
        setIsAVP(WorkFlowData.isAboveAVP || false);
        setIsAboveDeputyManager(WorkFlowData.isAboveDeputyManager || false);
        setIsBudget(WorkFlowData.isBudget || false);
        hideLoader();
      } catch (error) {
        console.error("Error fetching user data:", error);
        hideLoader();
      }
    }
    hideLoader();
  };

  // get dropdown API WorkflowTypeId
  const fetchWorkflowTypeIdData = async () => {
    try {
      const response = await APICall(getWorkFlowTypes, "POST", {});
      setWorkflowTypeIdDropdownOptions(response.data);
      //console.log(response.data);
    } catch (error) {
      console.error("Error fetching Role data:", error);
    }
  };

  // get dropdown API WorkFlowTypeReason
  const fetchWorkFlowTypeReasonData = async () => {
    try {
      const response = await APICall(getWorkflowTypeReason, "POST", {});
      setWorkFlowTypeReasonDropdownOptions(response.data);
    } catch (error) {
      console.error("Error fetching Role data:", error);
    }
  };

  // get dropdown API ModuleID
  const fetchModuleID = async () => {
    try {
      const response = await APICall(getModulesList, "POST", {});
      setModuleIdDropdownOptions(response.data);
    } catch (error) {
      console.error("Error fetching Role data:", error);
    }
  };

  // get dropdown API Role & FYI
  const fetchRoleID = async () => {
    try {
      const response = await APICall(getRolesList, "POST", {});
      setRoleDropdownOptions(response.data);
      setFYIDropdownOptions(response.data);
      setRoleIDDropdownOptions(response.data);
      setToRoleDropdownOptions(response.data);
      setCCRoleDropdownOptions(response.data);
    } catch (error) {
      console.error("Error fetching Role data:", error);
    }
  };

  const handleInputChange = (event) => {
    setWorkflowName(event.target.value);
    setWorkFlowTypeReasonErrorDropdown("");
  };

  const handleSystemChange = (event) => {
    if (event.target.value.includes(" ")) {
      const updatedValue = event.target.value.replace(/\s/g, "");
      setSystemName(updatedValue);
    } else {
      setSystemName(event.target.value);
    }
    setSystemNameError("");
  };

  const handleworkflowTypeIdChange = async (selectedOption: any) => {
    showLoader();
    try {
      if (selectedOption && selectedOption.label === "Compensation") {
        setDisplayAvp(true);
        setDisplayDM(false);
      } else if (selectedOption.label === "Separation") {
        setDisplayDM(true);
        setDisplayAvp(false);
      } else {
        setDisplayDM(false);
        setDisplayAvp(false);
      }
      setWorkflowTypeIdDropdownValue(selectedOption);
    } catch (error) {
      console.error("Error handling interface change:", error);
    } finally {
      hideLoader();
    }
  };

  const handleworkFlowTypeReasonChange = async (selectedOption: any) => {
    showLoader();
    try {
      setWorkFlowTypeReasonDropdownValue(selectedOption);
    } catch (error) {
      console.error("Error handling interface change:", error);
    } finally {
      hideLoader();
    }
  };

  const handleModuleChange = async (selectedOption: any) => {
    showLoader();
    try {
      if (selectedOption && selectedOption.value === 2) {
        setDisplayBudget(true);
      } else {
        setDisplayBudget(false);
      }
      setModuleIdDropdownValue(selectedOption);
      setModuleIdErrorDropdown("");
    } catch (error) {
      console.error("Error handling interface change:", error);
    } finally {
      hideLoader();
    }
  };

  const handleRoleChange = async (selectedOption: any) => {
    showLoader();
    try {
      setRoleDropdownValue(selectedOption);
    } catch (error) {
      console.error("Error handling interface change:", error);
    } finally {
      hideLoader();
    }
  };

  const handleFYIChange = async (selectedOption: any) => {
    showLoader();
    try {
      setFYIDropdownValue(selectedOption);
    } catch (error) {
      console.error("Error handling interface change:", error);
    } finally {
      hideLoader();
    }
  };

  //Call api for all Dropdown
  const getDDData = async (AC, PID) => {
    const dropdowndataResponse = await APICall(getDropdowns, "POST", {
      AC,
      PID,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });
    const dropdownData = dropdowndataResponse.data;

    return dropdownData;
  };

  const PLocOptionValue = async () => {
    const OptionValue = await getDDData("PLOC", "");
    setPayLocDropdownOptions(OptionValue);
  };

  const CountryOptionValue = async () => {
    const OptionValue = await getDDData("COU", "");
    const filteredOptions = OptionValue.filter((option) =>
      ["India", "United Kingdom", "United States"].includes(option.label)
    );

    setCountryDropdownOptions(filteredOptions);
  };

  const handleOnChange = async (DDName, e) => {
    if (DDName === "COU") {
      let PID = "";
      if (e.value === 570) {
        setDisplayPayLoc(true);
      } else {
        setDisplayPayLoc(false);
      }
      setCountryDropdownValue(e);
      setCountryErrorDropdown("");
    }

    if (DDName === "PLOC") {
      let PID = "";
      setPayLocDropdownValue(e);
    }
  };

  const handleIsActiveChange = () => {
    setIsActive(!isActive);
  };

  const handleIsBudgetChange = () => {
    setIsBudget(!isBudget);
  };

  const handleIsAAVPChange = () => {
    setIsAVP(!isAVP);
  };

  const handleIsDMChange = () => {
    setIsAboveDeputyManager(!isDeputyManager);
  };

  // submit workflow
  const handleSubmit = async () => {
    if (
      workFlowTypeReasonDropdownValue &&
      systemName &&
      moduleIdDropdownValue &&
      CountryDropdownValue
    ) {
      const response = await APICall(insertWorkFlowRecord, "POST", {
        Name: workflowName,
        SystemName: systemName,
        IsActive: isActive,
        WorkFlowTypeId: workflowTypeIdDropdownValue?.value,
        WorkFlowTypeReason: workFlowTypeReasonDropdownValue?.value,
        ModuleId: moduleIdDropdownValue?.value,
        InitiatorRoleId: roleDropdownValue?.value,
        FYIRoleId: FYIDropdownValue?.value,
        IsBudget: isBudget,
        IsAboveAVP: isAVP,
        IsAboveDeputyManager: isDeputyManager,
        Id: state?.UId || 0,
        CreatedBy: userDetails.Id,
        CountryId: CountryDropdownValue?.value,
        PayrollLocationId: PayLocDropdownValue?.value,
      });
      // console.log("API response:", response);
      if (response.data == 1) {
        notify(0, `${response.message}.`);
        setIsFormSubmitted(true);
        setPanal1(true);
      } else {
        notify(1, `${response.message}`);
        setIsFormSubmitted(true);
        setPanal1(true);
      }
    } else {
      setIsFormSubmitted(false);
      setWorkFlowTypeReasonErrorDropdown("Please select Workflow Name.");
      setSystemNameError("Please Enter System Name.");
      setModuleIdErrorDropdown("Please select Module.");
      setCountryErrorDropdown("Please select Country.");
    }
  };

  const handleClear = () => {
    setPanal2(true);
    setRoleIDDropdownValue(null);
    setSequenceNumber("");
    setStageName("");
    setRoleType(null);
    setIsActive(false);
    setAcknowledgeRequired(false);
    setRId(0);
  };

  // workflow steps Stage
  const handleStageChange = (event) => {
    setStageName(event.target.value);
  };

  // workflow steps RoleID
  const handleRoleIDChange = async (selectedOption: any) => {
    showLoader();
    try {
      setRoleIDDropdownValue(selectedOption);
      setRoleIDErrorDropdown("");
    } catch (error) {
      console.error("Error handling interface change:", error);
    } finally {
      hideLoader();
    }
  };

  const getGridStructureOne = async () => {
    showLoader();
    try {
      const GetDashboardData = await APICall(getStepsForWorkFlowList, "POST", {
        WorkflowId: state?.UId,
      });

      if (GetDashboardData.data && GetDashboardData.data.length > 0) {
        setRowData(GetDashboardData.data);
        setCount(GetDashboardData.data[0].count);
        setPanal2(true);
      } else {
        setRowData([]);
      }
    } catch (error) {
      console.error("Error fetching grid data:", error);
      hideLoader();
    }
    setPanal2(true);
    hideLoader();
  };

  // WorkFlow Steps Submit
  const handleWFStepsSubmit = async () => {
    if (roleIDDropdownValue && sequenceNumber) {
      const additionalDetailsArray = addDetailsDropdownValue.map((option) => ({
        value: option.value,
        label: option.label,
      }));
      const response = await APICall(insertWorkflowStepsDetails, "POST", {
        SequenceNo: sequenceNumber,
        WorkFlowId: state?.UId,
        AdditionalDetails: JSON.stringify(additionalDetailsArray),
        StageName: stageName,
        RoleId: roleIDDropdownValue?.value,
        // RoleType: roleType?.value,
        IsAcknolwdgedRequired: IsAcknowledgedRequired,
        Name: workflowName,
        IsActive: isActive,
        Id: RId,
        CreatedBy: userDetails.Id,
      });
      if (response.data.isSaved == 1 && response.data.sequenceNoExists == 0) {
        notify(0, `${response.message}.`);
        setPanal2(true);
        getGridStructureOne();
        setIsFormSubmitted(true);
      } else if (
        response.data.isSaved == 0 &&
        response.data.sequenceNoExists == 1
      ) {
        notify(1, `${response.message}`);
        setPanal2(true);
        getGridStructureOne();
        setIsFormSubmitted(true);
      } else {
        notify(1, `${response.message}`);
        setPanal2(true);
        getGridStructureOne();
        setIsFormSubmitted(true);
      }
    } else {
      setIsFormSubmitted(false);
      setRoleIDErrorDropdown("Please select Role Id");
      setSequenceErrorDropdown("Please select Sequence Number");
    }
  };

  // workflow steps Sequence number
  const handleSequenceChange = (event) => {
    const inputText = event.target.value;
    if (inputText === "" || !isNaN(inputText)) {
      setSequenceNumber(inputText === "" ? "" : parseInt(inputText, 10));
      setSequenceErrorDropdown("");
      const isSequenceOne = inputText === "1" || parseInt(inputText, 10) === 1;
      setDisplayAddDetails(!isSequenceOne);
    } else {
      setSequenceErrorDropdown("Please enter a valid number");
    }
  };

  const handleRoleTypeChange = (selectedOption) => {
    setRoleType(selectedOption);
  };

  const handleAddDetailsChange = (selectedOption) => {
    setAddDetailsDropdownValue(selectedOption);
  };
  const handleAcknolwdgeChange = () => {
    setAcknowledgeRequired(!IsAcknowledgedRequired);
  };

  const edit = (value, tableMeta) => {
    setRoleIDErrorDropdown("");
    setModuleIdErrorDropdown("");
    const UId = tableMeta.rowData;
    // const tempRId = UId[0];
    const tempRId = Number(tableMeta.tableData[tableMeta.rowIndex].id);
    setRId(tempRId);

    // Sequence number
    // const sequenceNumValue = UId[4];
    const sequenceNumValue = tableMeta.tableData[tableMeta.rowIndex].sequenceNo;
    setSequenceNumber(sequenceNumValue);
    if (sequenceNumValue === 1) {
      setDisplayAddDetails(false);
    } else {
      setDisplayAddDetails(true);
    }

    // additional Detail
    // const addDetailValueString = UId[5];
    const addDetailValueString =
      tableMeta.tableData[tableMeta.rowIndex].additionalDetails;
    if (addDetailValueString !== null || addDetailValueString?.trim() !== "") {
      try {
        const addDetailValue = JSON.parse(addDetailValueString);
        if (Array.isArray(addDetailValue)) {
          const filterAddDetailValue = addDetailsDropdownOptions.filter(
            (item) =>
              addDetailValue.some((selected) => selected.value === item.value)
          );
          setAddDetailsDropdownValue(filterAddDetailValue);
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    } else {
      setAddDetailsDropdownValue([]);
    }

    // const stageNMValue = UId[7];
    // setStageName(stageNMValue);

    // const isActiveValue = UId[9];
    const isActiveValue = tableMeta.tableData[tableMeta.rowIndex].isActive;
    setIsActive(isActiveValue);

    // const isAcknolwdgedValue = UId[10];
    //Is
    const isAcknowledgedValue =
      tableMeta.tableData[tableMeta.rowIndex].isAcknolwdgedRequired;
    if (isAcknowledgedValue == undefined || isAcknowledgedValue == null) {
      setAcknowledgeRequired(false);
    } else {
      setAcknowledgeRequired(isAcknowledgedValue);
    }

    // const roleTypeValue = UId[6];
    const roleTypeValue = tableMeta.tableData[tableMeta.rowIndex].roleType;
    const filterRoleType = roleTypeOptions.find(
      (item) => item.value === roleTypeValue
    );
    setRoleType(filterRoleType);

    // const RoleID = UId[8];
    const RoleID = tableMeta.tableData[tableMeta.rowIndex].roleId;
    const filterRoleId = roleIDDropdownOptions.find(
      (item) => item.value === RoleID
    );
    setRoleIDDropdownValue(filterRoleId);
  };

  const columns = [
    {
      name: "id",
      label: "id",
      options: { display: false },
    }, //0
    {
      name: "workFlowId",
      label: "Work FlowId",
      options: { display: false },
    }, //1
    {
      name: "workFlowName",
      label: "Workflow Name",
      options: { display: true, sort: false },
    }, //2
    {
      name: "roleName",
      label: "Role",
      options: { display: true, sort: false },
    }, //3
    {
      name: "sequenceNo",
      label: "Sequence Number",
      options: { display: true, sort: false },
    }, //4
    {
      name: "additionalDetails",
      label: "Additional Details",
      options: { display: false, sort: false },
    }, //5
    {
      name: "roleType",
      label: "Role Type",
      options: { display: false, sort: false },
    }, //6
    // {
    //   name: "stageName",
    //   label: "Stage Name",
    //   options: { display: true, sort: false },
    // },
    {
      name: "roleTypeName",
      label: "Role TypeName",
      options: { display: false, sort: false },
    }, //7
    {
      name: "roleId",
      label: "Role Id",
      options: { display: false, sort: false },
    }, //8
    {
      name: "isActive",
      label: "Active Status",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          if (value) {
            return <i className="fas fa-check"></i>;
          } else {
            return <i className="fas fa-times"></i>;
          }
        },
      },
    }, //9
    {
      name: "isAcknolwdgedRequired",
      label: "Acknowledged Required",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          if (value) {
            return <i className="fas fa-check"></i>;
          } else {
            return <i className="fas fa-times"></i>;
          }
        },
      },
    }, //10
    {
      name: "action",
      label: "Action",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <div className="edit-form">
                <Tooltip title="Edit">
                  <i
                    style={{ marginRight: "0px", cursor: "pointer" }}
                    onClick={() => {
                      edit(value, tableMeta);
                    }}
                    className="fas fa-edit"
                  ></i>
                </Tooltip>
                <Tooltip title="Email Form">
                  <i
                    style={{ marginRight: "0px", cursor: "pointer" }}
                    onClick={() => {
                      getGridStructureTwo(value, tableMeta);
                    }}
                    className="fa-regular fa-clone"
                  ></i>
                </Tooltip>
              </div>
            </>
          );
        },
      },
    }, //11
  ];

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count2: count2,
    rowsPerPage2: PageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",
    onChangePage: async (page) => {
      setPage2(page + 1);
    },
    onColumnSortChange: async (sortColumn2, sortDirection2) => {
      setPage2(page2);
      if (sortDirection2 === "asc") {
        await setSortColumn2(sortColumn2);
        await setSortDirection2(sortDirection2);
      }
      if (sortDirection === "desc") {
        await setSortColumn2(sortColumn2);
        await setSortDirection2(sortDirection2);
      }
    },
  };
  // =============================================================================================================================
  // Modal start

  // fetch EmailTmp
  const fetchEmailTmp = async () => {
    try {
      const response = await APICall(getEmailTempeleteList, "POST", {});
      setEmailTempDropdownOptions(response.data);
    } catch (error) {
      console.error("Error fetching EmailTmp data:", error);
    }
  };

  // Modal Dashboard 2
  const getGridStructureTwo = async (value, tableMeta) => {
    showLoader();
    const UId = tableMeta.rowData;
    const tempRId = UId[0];
    setNewRecord((prevRecord) => ({
      ...prevRecord,
      workFlowStepsId: tempRId,
    }));
    try {
      const GetDashboardDataTwo = await APICall(getWorkFlowEmailList, "POST", {
        WorkFlowStepsId: tempRId,
      });

      if (GetDashboardDataTwo.data && GetDashboardDataTwo.data.length > 0) {
        setRowData2(GetDashboardDataTwo.data);

        //  const processedData = GetDashboardDataTwo.data.map((item) => ({
        //   ...item,
        //   actionLabel: mapActionIdToLabel(item.actionId),
        // }));

        setCount2(GetDashboardDataTwo.data[0].count);
      } else {
        setRowData2([]);
        setRowInx(0);
      }
    } catch (error) {
      console.error("Error fetching grid data:", error);
      hideLoader();
    }
    hideLoader();

    setShowFormModal(true);
  };

  // Edit the dashboard 2
  const edit2 = (value, tableMeta) => {
    const MUId = tableMeta.rowData;
    const tempEId = MUId[0];
    setEId(tempEId);

    //RowIndex
    const rowIndex = MUId[11];
    setRowInx(rowIndex);
    const workFlowId = MUId[1];

    //EmailTemplete Names
    const emailTempleteNames = MUId[3];
    if (emailTempleteNames.length > 0) {
      const filterEmailTempNames = EmailTempDropdownOptions.find(
        (item) => item.label === emailTempleteNames
      );
      setNewRecord((prev) => ({
        ...prev,
        emailTempleteNames: filterEmailTempNames,
      }));
    }

    // EmailTemplete Id
    const emailTempleteId = MUId[2];
    if (emailTempleteId.length > 0) {
      const filterEmailTempId = EmailTempDropdownOptions.find(
        (item) => item.value === emailTempleteId
      );
      setNewRecord((prev) => ({
        ...prev,
        emailTempleteId: filterEmailTempId,
      }));
    }

    // array = []
    //loop on Names
    ////extract filterToRoles
    ////append that filtered data in array
    //after loop end set this array in setNewRecord

    //To Role Names
    const toRolesNames = MUId[5];
    const toRolesArray = [];

    if (toRolesNames && typeof toRolesNames === "string") {
      const rolesArray = toRolesNames.split(",");

      rolesArray.forEach((roleName) => {
        const filterToRoles = toRoleDropdownOptions.find(
          (item) => item.label === roleName.trim()
        );
        if (filterToRoles) {
          toRolesArray.push(filterToRoles);
        }
      });
    }

    setNewRecord((prev) => ({
      ...prev,
      toRolesNames: toRolesArray,
    }));

    // CC Roles Names
    const ccRolesNames = MUId[7];
    const ccRolesNamesArray = [];

    if (ccRolesNames && typeof ccRolesNames === "string") {
      const rolesArray = ccRolesNames.split(",");

      rolesArray.forEach((roleName) => {
        const filterCcRole = ccRoleDropdownOptions.find(
          (item) => item.label === roleName.trim()
        );
        if (filterCcRole) {
          ccRolesNamesArray.push(filterCcRole);
        }
      });
    }

    setNewRecord((prev) => ({
      ...prev,
      ccRolesNames: ccRolesNamesArray,
    }));

    //Action
    const actionId = MUId[8];
    const filterAction = ActionDropdownOptions.find(
      (item) => item.value === actionId
    );
    setNewRecord((prev) => ({
      ...prev,
      actionId: [filterAction],
    }));

    //Active
    const isActiveValue = MUId[9];
    setNewRecord((prev) => ({
      ...prev,
      action: isActiveValue,
    }));
  };

  // Add record in grid dashboard 2
  const handleWFAddUpdate = (rowIndex1) => {
    let id = EId;
    let rowIndex = rowIndex1;
    let workFlowStepsId = newRecord.workFlowStepsId;

    let emailTempleteNames = newRecord.emailTempleteNames.label;
    let emailTempleteId = newRecord.emailTempleteNames.value;

    let toRoles = newRecord.toRolesNames
      .map((torole) => torole.value)
      .join(", ");
    let toRolesNames = newRecord.toRolesNames
      .map((torole) => torole.label)
      .join(", ");

    let ccRoles = newRecord.ccRolesNames
      .map((ccrole) => ccrole.value)
      .join(", ");
    let ccRolesNames = newRecord.ccRolesNames
      .map((ccrole) => ccrole.label)
      .join(", ");

    let actionId;
    if (Array.isArray(newRecord.actionId)) {
      actionId = newRecord.actionId[0].value;
    } else if (
      typeof newRecord.actionId === "object" &&
      newRecord.actionId !== null
    ) {
      actionId = newRecord.actionId.value;
    } else {
      actionId = newRecord.actionId;
    }

    let isActive = newRecord.isActive;

    const formattedRecord = {
      rowIndex: rowIndex,
      id: id,
      workFlowStepsId: workFlowStepsId,
      emailTempleteNames: emailTempleteNames,
      emailTempleteId: emailTempleteId,
      toRoles: toRoles,
      toRolesNames: toRolesNames,
      ccRoles: ccRoles,
      ccRolesNames: ccRolesNames,
      actionId: actionId,
      isActive: isActive,
    };

    // check and compare the index the index is from database
    let existingRowIndex = rowData2.findIndex(
      (item) => item.rowIndex === rowIndex
    );

    if (existingRowIndex !== -1) {
      existingRowIndex += 1;
    }

    if (existingRowIndex !== -1) {
      let tempRowData = [...rowData2];
      tempRowData[existingRowIndex - 1] = formattedRecord;

      setRowData2(tempRowData);
    } else {
      const newRowIndex = rowData2.length + 1;

      formattedRecord.rowIndex = newRowIndex;

      setRowData2((prevData) => [...prevData, formattedRecord]);
    }
    setNewRecord({
      rowIndex: 0,
      id: 0,
      workFlowStepsId: workFlowStepsId,
      emailTempleteNames: null,
      emailTempleteId: null,
      toRoles: [],
      toRolesNames: [],
      ccRoles: [],
      ccRolesNames: [],
      actionId: null,
      isActive: isActive,
    });
    setIsEdit(false);
    setRowInx(0);
    setEmailTemplateError("");
  };

  const handleCloseFormModal = () => {
    setShowFormModal(false);
  };

  const columns2 = [
    { name: "id", label: "id", options: { display: false } }, // 0
    {
      name: "workFlowStepsId", //1
      label: "workFlowSteps Id",
      options: { display: false },
    },
    {
      name: "emailTempleteId", //2
      label: "Email emailTempleteId",
      options: { display: false },
    },
    {
      name: "emailTempleteNames", //3
      label: "Email TempleteNames",
      options: { display: true, sort: false },
    },
    { name: "toRoles", label: "To Roles id", options: { display: false } }, //4
    {
      name: "toRolesNames", //5
      label: "To Roles Names",
      options: { display: true, sort: false },
    },
    { name: "ccRoles", label: "CC Role id", options: { display: false } }, //6
    {
      name: "ccRolesNames", //7
      label: "CC Role Name",
      options: { display: true, sort: false },
    },
    // { name: "actionId", label: "Action Id", options: { display: true } }, //8
    {
      name: "actionId",
      label: "Action",
      options: {
        display: true,
        customBodyRender: (value) => {
          const actionOption = ActionDropdownOptions.find(
            (option) => option.value === value
          );
          return actionOption ? actionOption.label : "";
        },
      },
    },
    {
      name: "isActive", //9
      label: "Active Status",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          if (value) {
            return <i className="fas fa-check"></i>;
          } else {
            return <i className="fas fa-times"></i>;
          }
        },
      },
    },
    {
      name: "action", //10
      label: "Action",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Tooltip title="Edit">
                <i
                  style={{ marginRight: "0px", cursor: "pointer" }}
                  onClick={() => {
                    edit2(value, tableMeta);
                    setIsEdit(true);

                    setEId(tableMeta.rowData[0]);
                  }}
                  className="fas fa-edit"
                ></i>
              </Tooltip>
            </>
          );
        },
      },
    },
    { name: "rowIndex", label: "roxInx", options: { display: false } }, //11
  ];

  const options2 = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count2: count2,
    rowsPerPage: count2,
    page2: page2,
    serverSide: true,
    onChangePage: async (page2) => {
      setPage2(page2);
    },
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",
    onColumnSortChange: async (sortColumn2, sortDirection2) => {
      setPage(0);
      if (sortDirection2 === "asc") {
        await setSortColumn2(sortColumn2);
        await setSortDirection2(sortDirection2);
      }
      if (sortDirection === "desc") {
        await setSortColumn2(sortColumn2);
        await setSortDirection2(sortDirection2);
      }
    },
  };

  // email on Change
  const handleEmailTemplateChange = (selectedOption) => {
    // setEmailTempDropdownValue(selectedOption);
    setNewRecord((prev) => ({
      ...prev,
      emailTempleteNames: selectedOption,
    }));
    setEmailTemplateError("");
  };

  // Action on change
  const handleActionChange = (selectedOption) => {
    setNewRecord((prev) => ({
      ...prev,
      actionId: selectedOption,
    }));
    setActionIdError("");
    // setAction(selectedOption.label);
  };

  // To role on change
  const handleToRoleChange = (selectedOption) => {
    setNewRecord((prev) => ({
      ...prev,
      toRolesNames: selectedOption,
    }));
    setToRolesError("");
  };

  // cc Role on change
  const handleCCRoleChange = (selectedOption) => {
    setNewRecord((prev) => ({
      ...prev,
      ccRolesNames: selectedOption,
    }));
    setCcRolesError("");
  };

  // Clear Email Configuration
  const handleWFClear = () => {
    const tempRId = MUId[0];
    setNewRecord({
      rowIndex: 0,
      id: 0,
      workFlowStepsId: tempRId,
      emailTempleteNames: null,
      emailTempleteId: null,
      toRoles: [],
      toRolesNames: [],
      ccRoles: [],
      ccRolesNames: [],
      actionId: null,
      isActive: isActive,
    });
    setIsEdit(false);
    setRowInx(0);
    setEmailTemplateError("");
    setActionIdError("");
    setToRolesError("");
    setCcRolesError("");
  };

  const handleIsActiveChange2 = () => {
    setIsActive2(!isActive2);
  };

  //submit all Email Config
  const handleWFEmailSubmit = async () => {
    let isValid = true;
    if (!newRecord.emailTempleteNames) {
      setEmailTemplateError("Please select Email");
      isValid = false;
    }
    if (!newRecord.actionId) {
      setActionIdError("Please select Action");
      isValid = false;
    }
    if (newRecord.toRolesNames.length === 0) {
      setCcRolesError("Please select CC Roles");
      isValid = false;
    }

    if (newRecord.toRolesNames.length === 0) {
      setToRolesError("Please select To Roles");
      isValid = false;
    }

    if (isValid) {
      try {
        const response = await APICall(insertWFEmailDetails, "POST", {
          finalData: rowData2,
          CreatedBy: userDetails.Id,
        });
        if (response.data.isSaved === 1) {
          notify(0, `${response.message}.`);
        } else {
          notify(1, `${response.message}.`);
        }
      } catch (error) {
        console.error("Unexpected error:", error);
      }
    }
  };

  return (
    <>
      <div className="container-fluid">
        <button
          onClick={() => {
            navigate("/WorkFlowDashboard ");
          }}
          className="btn btn-primary my-2"
        >
          <i className="fa fa-left-long mr-2"></i>
          Back
        </button>
        <Accordion
          className="mb-3"
          expanded={panal1}
          onChange={() => setPanal1((prev) => !prev)}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            style={{ background: "#3C5464" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}
          >
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                {" "}
                <Typography>Workflow</Typography>
              </div>
              <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12">
                <Tooltip title="Submit">
                  <button className="btn" onClick={handleSubmit}>
                    <i className="fa fa-save"></i>
                  </button>
                </Tooltip>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row">
              {/* Workflow Name */}
              <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown1">
                    Workflow Name<sup>*</sup>
                  </label>
                  <InputForm
                    value={workflowName}
                    placeholder="Enter Workflow Name"
                    isDisabled={false}
                    textArea={false}
                    onChange={handleInputChange}
                  />
                  {workFlowTypeReasonErrorDropdown && (
                    <p className="text-danger">
                      {workFlowTypeReasonErrorDropdown}
                    </p>
                  )}
                </div>
              </div>

              {/* system name */}
              <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown1">
                    System Name <sup>*</sup>
                  </label>
                  <InputForm
                    value={systemName}
                    placeholder="Enter System Name"
                    textArea={false}
                    onChange={handleSystemChange}
                    isDisabled={isFormSubmitted}
                  />
                  {systemNameError && (
                    <p className="text-danger">{systemNameError}</p>
                  )}
                </div>
              </div>

              {/* workflow TypeId */}
              <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown21">
                    Workflow Type
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                    id="dropdown21"
                    value={workflowTypeIdDropdownValue}
                    placeholder="Select an option"
                    options={workflowTypeIdDropdownOptions}
                    onChange={handleworkflowTypeIdChange}
                    isDisabled={isFormSubmitted}
                  />
                  {workflowTypeIdErrorDropdown && (
                    <p className="text-danger">{workflowTypeIdErrorDropdown}</p>
                  )}
                </div>
              </div>

              {/* WorkFlowTypeReason */}
              <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown21">
                    Workflow Type Reason
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                    id="dropdown21"
                    value={workFlowTypeReasonDropdownValue}
                    placeholder="Select an option"
                    options={workFlowTypeReasonDropdownOptions}
                    onChange={handleworkFlowTypeReasonChange}
                    isDisabled={isFormSubmitted}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              {/* Module ID */}
              <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown21">
                    Module<sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                    id="dropdown21"
                    value={moduleIdDropdownValue}
                    placeholder="Select an option"
                    options={moduleIdDropdownOptions}
                    onChange={handleModuleChange}
                    isDisabled={isFormSubmitted}
                  />
                  {moduleIdErrorDropdown && (
                    <p className="text-danger">{moduleIdErrorDropdown}</p>
                  )}
                </div>
              </div>

              {/* Role */}
              <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown21">
                    Role
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                    id="dropdown21"
                    value={roleDropdownValue}
                    placeholder="Select an option"
                    options={roleDropdownOptions}
                    onChange={handleRoleChange}
                    isDisabled={isFormSubmitted}
                  />
                </div>
              </div>

              {/* FYI */}
              <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown21">
                    FYI Role
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                    id="dropdown21"
                    value={FYIDropdownValue}
                    placeholder="Select an option"
                    options={FYIDropdownOptions}
                    onChange={handleFYIChange}
                  />
                </div>
              </div>

              {/* /Country */}
              <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown17">
                    Country<sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                    id="dropdown17"
                    value={CountryDropdownValue}
                    placeholder="Select an option"
                    options={CountryDropdownOptions}
                    onChange={(e) => {
                      handleOnChange("COU", e);
                    }}
                  />
                  {countryErrorDropdown && (
                    <p className="text-danger">{countryErrorDropdown}</p>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              {/* Payroll Location */}
              {displayPayLoc && (
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="col-form-label" htmlFor="dropdown16">
                      Payroll Location
                    </label>
                    <SelectForm
                      isClearable
                      isSearchable
                      isMulti={false}
                      noIndicator={false}
                      noSeparator={false}
                      id="dropdown16"
                      value={PayLocDropdownValue}
                      placeholder="Select an option"
                      options={PayLocDropdownOptions}
                      onChange={(e) => {
                        handleOnChange("PLOC", e);
                      }}
                    />
                  </div>
                </div>
              )}

              {/* is deputy manager */}
              {displayDM && (
                <div className="col-md-3 mt-2">
                  <div className="form-group d-flex flex-column-reverse justify-content-start align-items-start">
                    <label
                      className="col-form-label mr-2"
                      htmlFor="dropdown302"
                    >
                      Is Deputy Manager
                    </label>
                    <input
                      type="checkbox"
                      id="dropdown302"
                      checked={isDeputyManager}
                      onChange={handleIsDMChange}
                    />
                  </div>
                </div>
              )}

              {/* is above vp */}
              {displayAvp && (
                <div className="col-md-3 mt-2">
                  <div className="form-group d-flex flex-column-reverse justify-content-start align-items-start">
                    <label
                      className="col-form-label mr-2"
                      htmlFor="dropdown301"
                    >
                      Is Above VP
                    </label>
                    <input
                      type="checkbox"
                      id="dropdown301"
                      checked={isAVP}
                      onChange={handleIsAAVPChange}
                    />
                  </div>
                </div>
              )}
              {/* isActive */}
              <div className="col-md-3 mt-2">
                <div className="form-group d-flex flex-column-reverse justify-content-start align-items-start">
                  <label className="col-form-label mr-2" htmlFor="dropdown25">
                    Is Active
                  </label>
                  <input
                    type="checkbox"
                    id="dropdown25"
                    checked={isActive}
                    onChange={handleIsActiveChange}
                    disabled={state?.UId ? false : true}
                  />
                </div>
              </div>

              {/* isBudget */}
              {displayBudget && (
                <div className="col-md-3 mt-2">
                  <div className="form-group d-flex flex-column-reverse justify-content-start align-items-start">
                    <label className="col-form-label mr-2" htmlFor="dropdown26">
                      Is Budget
                    </label>
                    <input
                      type="checkbox"
                      id="dropdown25"
                      checked={isBudget}
                      onChange={handleIsBudgetChange}
                    />
                  </div>
                </div>
              )}
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion
          className="mb-3"
          expanded={panal2}
          onChange={() => setPanal2((prev) => !prev)}
        >
          <AccordionSummary
            aria-controls="panel2d-content"
            id="panel2d-header"
            style={{ background: "#3C5464" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}
          >
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <Typography>Workflow Steps</Typography>
              </div>
              <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12">
                <Tooltip title="Submit">
                  <button
                    className="btn"
                    onClick={() => {
                      handleWFStepsSubmit();
                    }}
                  >
                    <i className="fa fa-save"></i>
                  </button>
                </Tooltip>
                <Tooltip title="Clear">
                  <button
                    className="btn"
                    onClick={() => {
                      handleClear();
                    }}
                  >
                    <i className="fa-solid fa-arrow-rotate-left"></i>
                  </button>
                </Tooltip>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row">
              {/* RoleID is here */}
              <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown21">
                    Role<sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                    id="dropdown21"
                    value={roleIDDropdownValue}
                    placeholder="Select an option"
                    options={roleIDDropdownOptions}
                    onChange={handleRoleIDChange}
                  />
                  {roleIDErrorDropdown && (
                    <p className="text-danger">{roleIDErrorDropdown}</p>
                  )}
                </div>
              </div>

              {/* Sequence num is here */}
              <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown22">
                    Sequence Number<sup>*</sup>
                  </label>
                  <InputForm
                    value={sequenceNumber}
                    placeholder="Enter Sequence number"
                    textArea={false}
                    onChange={handleSequenceChange}
                    isDisabled={false}
                  />
                  {sequenceErrorDropdown && (
                    <p className="text-danger">{sequenceErrorDropdown}</p>
                  )}
                </div>
              </div>
              {/* Additional Details */}
              {displayAddDetails && (
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="col-form-label" htmlFor="dropdown24">
                      Additional Details
                    </label>
                    <SelectForm
                      isClearable
                      isSearchable
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={addDetailsDropdownValue}
                      placeholder="Select an option"
                      options={addDetailsDropdownOptions}
                      onChange={handleAddDetailsChange}
                    />
                  </div>
                </div>
              )}
              <>
                {/* stage name is here */}
                {/* <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown22">
                    Stage name
                  </label>
                  <InputForm
                    value={stageName}
                    placeholder="Enter Stage Name"
                    textArea={false}
                    onChange={handleStageChange}
                    isDisabled={false}
                  />
                </div>
              </div> */}

                {/* Role Type */}
                {/* <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown24">
                    Role Type
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                    value={roleType}
                    placeholder="Select Role Type"
                    options={roleTypeOptions}
                    onChange={handleRoleTypeChange}
                  />
                </div>
              </div> */}
              </>
            </div>

            <div className="row">
              {/* Is Active */}
              <div className="col-md-4">
                <div className="form-group d-flex flex-column-reverse justify-content-start align-items-start">
                  <label className="col-form-label mr-2" htmlFor="dropdown25">
                    Is Active
                  </label>
                  <input
                    type="checkbox"
                    id="dropdown25"
                    checked={isActive2}
                    onChange={handleIsActiveChange2}
                  />
                </div>
              </div>

              {/*  Is Acknowledged Required */}
              <div className="col-md-4">
                <div className="form-group d-flex flex-column-reverse justify-content-start align-items-start">
                  <label className="col-form-label mr-2" htmlFor="dropdown26">
                    Is Acknowledged Required
                  </label>
                  <input
                    type="checkbox"
                    id="dropdown25"
                    checked={IsAcknowledgedRequired}
                    onChange={handleAcknolwdgeChange}
                  />
                </div>
              </div>
            </div>

            <DynamicGrid data={rowData} columns={columns} options={options} />
          </AccordionDetails>
        </Accordion>

        <Modal
          show={showFormModal}
          className="modal-dialog"
          backdrop="static"
          keyboard={false}
        >
          <div
            className="bg-secondary text-white"
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <div className="col-lg-12">Email Workflow</div>
          </div>

          <Modal.Body>
            <div>
              {/* hidden row index */}
              <div style={{ display: "none" }}>
                <input type="text" value={rowInx} id="rowIndex" />
              </div>

              <div className="row">
                {/* Email Temp */}
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="col-form-label" htmlFor="dropdown24">
                      Email Template<sup>*</sup>
                    </label>
                    <SelectForm
                      isClearable
                      isSearchable
                      isMulti={false}
                      noIndicator={false}
                      noSeparator={false}
                      value={newRecord.emailTempleteNames}
                      placeholder="Select an option"
                      options={EmailTempDropdownOptions}
                      onChange={handleEmailTemplateChange}
                    />
                    <div className="text-danger">{emailTemplateError}</div>
                  </div>
                </div>

                {/* Action */}
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="col-form-label" htmlFor="dropdown24">
                      Action<sup>*</sup>
                    </label>
                    <SelectForm
                      isClearable
                      isSearchable
                      isMulti={false}
                      noIndicator={false}
                      noSeparator={false}
                      value={newRecord.actionId}
                      placeholder="Select an option"
                      options={ActionDropdownOptions}
                      onChange={handleActionChange}
                    />
                    <div className="text-danger">{actionIdError}</div>
                  </div>
                </div>

                {/* to Role */}
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="col-form-label" htmlFor="dropdown24">
                      ToRole<sup>*</sup>
                    </label>
                    <SelectForm
                      isClearable
                      isSearchable
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={newRecord.toRolesNames}
                      placeholder="Select an option"
                      options={toRoleDropdownOptions}
                      onChange={handleToRoleChange}
                    />
                    <div className="text-danger">{toRolesError}</div>
                  </div>
                </div>

                {/* CCRole */}
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="col-form-label" htmlFor="dropdown24">
                      CCRole
                    </label>
                    <SelectForm
                      isClearable
                      isSearchable
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={newRecord.ccRolesNames}
                      placeholder="Select an option"
                      options={ccRoleDropdownOptions}
                      onChange={handleCCRoleChange}
                    />
                    {/* <div className="text-danger">{ccRolesError}</div> */}
                  </div>
                </div>
              </div>

              <div className="row">
                {/* Is Active */}
                <div className="col-md-3">
                  <div className="form-group d-flex flex-column-reverse justify-content-start align-items-start">
                    <label className="col-form-label mr-2" htmlFor="dropdown25">
                      Is Active
                    </label>
                    <input
                      type="checkbox"
                      id="dropdown25"
                      checked={isActive}
                      onChange={handleIsActiveChange}
                      // disabled={true}
                    />
                  </div>
                </div>

                <div className="col-md-3"></div>
                <div className="col-md-3"></div>

                {/* Add / update button */}
                <div className="col-md-3 edit-form">
                  <Button
                    onClick={() => handleWFAddUpdate(rowInx ? rowInx : 0)}
                    disabled={
                      !newRecord.emailTempleteNames ||
                      !newRecord.actionId ||
                      newRecord.toRolesNames.length === 0 ||
                      newRecord.ccRolesNames.length === 0
                    }
                  >
                    {/* {EId ? "Edit" : "Add"} */}
                    {isEdit ? "Edit" : "Add"}
                  </Button>
                  <Button onClick={handleWFClear}>Clear</Button>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <DynamicGrid
                    data={rowData2}
                    columns={columns2}
                    options={options2}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleWFEmailSubmit}>Submit</Button>
            <Button onClick={handleCloseFormModal}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};
export default WorkFlowForm;
