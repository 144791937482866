import React, { useContext, useEffect, useState } from "react";
import { LoaderContext } from "../../Helpers/Context/Context";
import { useNavigate } from "react-router-dom";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import {
  Collapse,
  Tabs,
  ThemeProvider,
  Tooltip,
  createTheme,
} from "@mui/material";
import InputForm from "../../Components/InputForm/InputForm";
import SelectForm from "../../Components/SelectForm/SelectForm";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import notify from "../../Helpers/ToastNotification";
import {
  getLMSRequestDashboardDropDownList,
  getRequestDashboardData,
  getRequestsApprovalTrail,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import { APICall } from "../../Helpers/APICalls";
import { Modal, Table } from "react-bootstrap";
import moment from "moment";

const RequestDashboard = () => {
  const [open, setOpen] = useState(false);

  const { showLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  let { userDetails, currentRoleId }: any = useUserContext();

  const [activetabIndex, setActivetabIndex] = useState<number>(0);
  const [sectionValuesArray, setSectionValuesArray] = useState<any>([]);

  const [activetab, setActivetab] = useState<string>("My Request");
  const tabs = ["My Request", "My Action", "All Request"];

  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useState(0);
  const [start, setStart] = useState(0);
  const [sortDirection, setSortDirection] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [searchText, setSearchText] = useState("");
  const [rowData, setRowData] = useState([]);
  const [requestId, setRequestId] = useState([]);
  const [requestIdOptions, setRequestIdOptions] = useState([]);
  const [requestor, setRequestor] = useState([]);
  const [requestorOptions, setRequestorOptions] = useState([]);
  const [requestStatus, setRequestStatus] = useState([]);
  const [requestStatusOptions, setRequestStatusOptions] = useState([]);
  const [pendingWith, setpendingWith] = useState([]);
  const [pendingWithOptions, setPendingWithOptions] = useState([]);
  const [reSet, setReSet] = useState(false);

  const [requestHistoryModel, setRequestHistoryModel] = useState(false);

  const [approvalTrailData, setApprovalTrailData] = useState([]);

  useEffect(() => {
    showLoader();
    const responseData = async () => {
      const objRequestor = {
        mode: "CREATEDBY",
        userId: userDetails?.Id,
        roleId: currentRoleId?.value,
      };
      const res = await APICall(
        getLMSRequestDashboardDropDownList,
        "POST",
        objRequestor
      );
      if (res.data && res.data.length > 0) {
        setRequestorOptions(res.data);
      }

      const objRecord = {
        mode: "REQUESTCODE",
        userId: userDetails?.Id,
        roleId: currentRoleId?.value,
      };
      const res1 = await APICall(
        getLMSRequestDashboardDropDownList,
        "POST",
        objRecord
      );
      if (res1.data && res1.data.length > 0) {
        setRequestIdOptions(res1.data);
      }

      const objRecordStatus = {
        mode: "REQUESTSTATUS",
        userId: userDetails?.Id,
        roleId: currentRoleId?.value,
      };
      const res2 = await APICall(
        getLMSRequestDashboardDropDownList,
        "POST",
        objRecordStatus
      );
      if (res2.data && res2.data.length > 0) {
        setRequestStatusOptions(res2.data);
      }

      const objPendingWith = {
        mode: "PENDINGAT",
        userId: userDetails?.Id,
        roleId: currentRoleId?.value,
      };
      const res3 = await APICall(
        getLMSRequestDashboardDropDownList,
        "POST",
        objPendingWith
      );
      if (res3.data && res3.data.length > 0) {
        setPendingWithOptions(res3.data);
      }
    };

    responseData();
    hideLoader();
  }, []);

  useEffect(() => {
    getRequestDashboardDataAPICall();
  }, [
    start,
    pageSize,
    sortColumn,
    sortDirection,
    searchText,
    reSet,
    activetab,
  ]);

  const getApprovalTrail = async (requestId) => {
    showLoader();
    const res = await APICall(getRequestsApprovalTrail, "POST", {
      RequestId: requestId,
    });

    if (res.status === 0 && res.data) {
      setApprovalTrailData(res.data);
    }
    hideLoader();
  };

  const getRequestDashboardDataAPICall = async () => {
    await showLoader();

    let action = "";
    if (activetab == "My Action") {
      action = "MYACT";
    } else if (activetab == "My Request") {
      action = "MYREQ";
    } else {
      action = "ALL";
    }
    const requestIds = requestId?.map((s) => s.value).join(",");
    const GetDashboardData = await APICall(getRequestDashboardData, "POST", {
      Mode: action,
      PageNumber: start,
      PageSize: pageSize,
      SortColumn: sortColumn,
      SortOrder: sortDirection,
      SearchText: searchText,
      CreatedBytIds: requestor?.map((s) => s.value).join(","),
      RequestIds: requestId?.map((s) => s.value).join(","),
      RequestStatus: requestStatus?.map((s) => s.value).join(","),
      PendingAtIds: pendingWith?.map((s) => s.value).join(","),
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });
    if (
      GetDashboardData &&
      GetDashboardData.data !== null &&
      GetDashboardData.data.length > 0
    ) {
      setCount(GetDashboardData.data[0].count);

      setRowData(GetDashboardData.data);
    } else {
      setRowData([]);
      setCount(0);
    }
    await hideLoader();
  };

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: pageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      setStart(page * pageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const gridColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        display: false,
      },
    },
    {
      name: "requestCode",
      label: "Request Id",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "courseTitle",
      label: "Course Name",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "requestType",
      label: "Request Type",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "pendingAt",
      label: "Pending With",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "requestor",
      label: "Initiator",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "requestDate",
      label: "Submited Date",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "approvedOn",
      label: "Approved On",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta) => {
          let requestId = tableMeta.tableData[tableMeta.rowIndex].id;
          let courseScheduleId =
            tableMeta.tableData[tableMeta.rowIndex].courseScheduleId;
          let action = activetab == "My Action" ? "edit" : "view";
          let launchId = tableMeta.tableData[tableMeta.rowIndex].launchId;

          if (activetab == "My Action") {
            return (
              <div style={{ width: "100px" }}>
                <Tooltip title="edit">
                  <a
                    className="mr-2"
                    style={{ fontSize: "15px" }}
                    onClick={(e) => {
                      e.preventDefault();

                      navigate("/RegistrationRequest", {
                        state: {
                          requestId: requestId,
                          courseScheduleId: courseScheduleId,
                          launchId: launchId,
                          action: action,
                        },
                      });
                    }}>
                    <i className="fas fa-edit"></i>
                  </a>
                </Tooltip>

                <Tooltip title="History">
                  <a
                    className="mr-2"
                    style={{ fontSize: "15px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      getApprovalTrail(requestId);
                      setRequestHistoryModel(true);
                    }}>
                    <i className="fas fa-history"></i>
                  </a>
                </Tooltip>
              </div>
            );
          } else {
            return (
              <div style={{ width: "100px" }}>
                <Tooltip title="View">
                  <a
                    className="mr-2"
                    style={{ fontSize: "15px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/RegistrationRequest", {
                        state: {
                          requestId: requestId,
                          courseScheduleId: courseScheduleId,
                          launchId,
                          action: action,
                        },
                      });
                    }}>
                    <i className="fas fa-eye"></i>
                  </a>
                </Tooltip>
                <Tooltip title="History">
                  <a
                    className="mr-2"
                    style={{ fontSize: "15px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      getApprovalTrail(requestId);
                      setRequestHistoryModel(true);
                    }}>
                    <i className="fas fa-history"></i>
                  </a>
                </Tooltip>
              </div>
            );
          }
        },
      },
    },
  ];

  const onSubmitFilter = () => {
    if (
      requestId?.length === 0 &&
      requestStatus?.length === 0 &&
      pendingWith?.length === 0 &&
      requestor?.length === 0
    ) {
      notify(
        1,
        "Please select at least one filter option  to perform the search action."
      );
    } else {
      getRequestDashboardDataAPICall();
    }
  };

  const resetFilters = () => {
    setStart(0);
    setSearchText("");
    setRequestId([]);
    setRequestStatus([]);
    setpendingWith([]);
    setRequestor([]);

    setReSet(!reSet);
  };

  const defaultTheme = createTheme({});
  const { breakpoints } = defaultTheme;
  const tabsTheme = {
    ...defaultTheme,
    components: {
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: "0px !important",
            marginLeft: "-42px",
            [breakpoints.down("md")]: {
              minHeight: "0px !important",
              marginLeft: "0px",
            },
          },
        },
      },
    },
  };
  return (
    <>
      <div className="breadcrumb modified-bredcrumb">
        <span>
          <ul>
            <li>
              <a href="/home">Home</a>
            </li>
            <li>Request</li>
          </ul>
        </span>
      </div>
      <div className="container-fluid">
        <div className="d-flex align-items-center justify-content-between">
          {/* Tabs Are here */}
          <div className="col-lg-10 col-md-12 col-sm-12 ">
            <ThemeProvider theme={tabsTheme}>
              <Tabs
                value={activetabIndex}
                className="profile-tabs"
                onChange={() => {}}
                variant="scrollable"
                TabScrollButtonProps={{
                  style: {
                    color: "black",
                  },
                }}
                scrollButtons
                TabIndicatorProps={{
                  style: { display: "none" },
                }}
                allowScrollButtonsMobile
                aria-label="scrollable tabs">
                <div className="tabBtn mt-2">
                  <ul>
                    {tabs.map((tabName, index) => (
                      <li key={index}>
                        <button
                          onClick={() => {
                            setSectionValuesArray([]);
                            setStart(0); //imp
                            setActivetab(tabName);
                          }}
                          className={
                            tabName === activetab
                              ? "bttn active"
                              : "bttn border border-primary border-bottom-0"
                          }>
                          {tabName}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </Tabs>
            </ThemeProvider>
          </div>
        </div>

        <div className="grid-wrapper grid_mob">
          <div className="row mx-auto filtBox">
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="form-group filtInp">
                <InputForm
                  value={searchText}
                  placeholder={"Search text"}
                  isDisabled={false}
                  textArea={false}
                  onChange={(e) => {
                    //   setPage(0);
                    setSearchText(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-12 filtrt">
              <ul className=" filter-icon-wel">
                <li>
                  <a
                    href="javascript:void(0);"
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                    className="filter-btn">
                    <i className="fa fa-filter"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Advanced Filter */}
        <>
          <Collapse in={open}>
            <div id="example-collapse-text grid-wrapper">
              <div className="row mx-auto myInlineStyle">
                <>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">
                      Request ID
                    </label>
                    <SelectForm
                      isClearable
                      options={requestIdOptions}
                      placeholder={"Request ID"}
                      isDisabled={false}
                      onChange={(event) => {
                        setRequestId(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={requestId}
                    />
                  </div>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">
                      Request Status
                    </label>
                    <SelectForm
                      isClearable
                      options={requestStatusOptions}
                      placeholder={"Request Status"}
                      isDisabled={false}
                      onChange={(event) => {
                        setRequestStatus(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={requestStatus}
                    />
                  </div>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">
                      Pending With
                    </label>
                    <SelectForm
                      isClearable
                      options={pendingWithOptions}
                      placeholder={"Pending With"}
                      isDisabled={false}
                      onChange={(event) => {
                        setpendingWith(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={pendingWith}
                    />
                  </div>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">
                      Requestor
                    </label>
                    <SelectForm
                      isClearable
                      options={requestorOptions}
                      placeholder={"Requestor"}
                      isDisabled={false}
                      onChange={(event) => {
                        setRequestor(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={requestor}
                    />
                  </div>
                </>
                <div className="col-lg-12 d-flex mt-2 justify-content-end">
                  <button
                    onClick={() => resetFilters()}
                    className="btn btn-secondary mr-2">
                    <i className="fa fa-undo" aria-hidden="true"></i> Reset{" "}
                  </button>
                  <button
                    className="btn btn-filter-submit"
                    onClick={() => onSubmitFilter()}>
                    <i className="fa fa-search" aria-hidden="true"></i> Search{" "}
                  </button>
                </div>
              </div>
            </div>
          </Collapse>
        </>

        {/* Grid */}
        <div className="mb-3 pt-3">
          <DynamicGrid data={rowData} columns={gridColumns} options={options} />
        </div>
      </div>

      {/* Request History */}
      <Modal
        show={requestHistoryModel}
        onHide={() => setRequestHistoryModel(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">{"Approval Trail"}</div>
        </div>
        <Modal.Body>
          <>
            {approvalTrailData.length > 0 && (
              <>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th className="text-dark">Approval level</th>
                      <th className="text-dark">Approver</th>
                      <th className="text-dark">Decision</th>
                      <th className="text-dark">Rejection Reason</th>
                      <th className="text-dark">Decision On</th>
                      <th className="text-dark">Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {approvalTrailData.map((trail) => (
                      <tr>
                        <td>{trail && trail?.approvalLevel}</td>
                        <td>{trail && trail?.approver}</td>
                        <td>{trail && trail?.decision}</td>
                        <td>{trail && trail?.rejectionReason}</td>
                        <td>
                          {trail &&
                          moment(trail.decisionOn, "DD-MM-YYYY").isValid()
                            ? moment(
                                trail?.decisionOn,
                                "DD-MM-YYYY HH:mm"
                              ).format("DD-MMM-YYYY HH:mm")
                            : null}
                        </td>
                        <td>{trail && trail?.remarks}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )}
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setRequestHistoryModel(false);
            }}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RequestDashboard;
