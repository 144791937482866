const prod = {
  url: "",
};

const dev = {
  url: "",
};

export const { url } = process.env.NODE_ENV === "development" ? dev : prod;

export const GetDocumentCheckListByJobId = `${url}/api/RCMBDocumentsScreening/GetDocumentCheckListByJobId`;
export const GetDocumentTypeListByDocumentTypeIds = `${url}/api/RCMBDocumentsScreening/GetDocumentTypeListByDocumentTypeIds`;
export const SetDocumentURLDetails = `${url}/api/RCMBDocumentsScreening/SetDocumentURLDetails`;
export const GetPositionDropdownData = `${url}/api/RCMBDocumentsScreening/GetPositionDropdownData`;
export const GetOUBasedPositionDropdownData = `${url}/api/RCMBDocumentsScreening/GetOUBasedPositionDropdownData`;
export const GetPositionDetailsByPositionId = `${url}/api/RCMBDocumentsScreening/GetPositionDetailsByPositionId`;
export const InsertAssociatesOrganizationInformation = `${url}/api/RCMBDocumentsScreening/InsertAssociatesOrganizationInformation`;
export const GetAssociatesOrganizationInformation = `${url}/api/RCMBDocumentsScreening/GetAssociatesOrganizationInformation`;
export const UpdateCandidateCategoryId = `${url}/api/RCMBDocumentsScreening/UpdateCategoryId`;

export const GetGenderDetails = `${url}/api/RCMBSecurityScreening/GetGenderDetails`;
export const GetRelationDetailsdata = `${url}/api/RCMBSecurityScreening/GetRelationDetailsdata`;
export const InsertUpdateTokenIssuanceDetails = `${url}/api/RCMBSecurityScreening/InsertUpdateTokenIssuanceDetails`;
export const InsertUpdateCandidateWorkflowInstanceTasks = `${url}/api/RCMBAssociateDetails/InsertUpdateCandidateWorkflowInstanceTasks`;
export const GetStageNameById = `${url}/api/RCMBAssociateDetails/GetStageNameById`;
export const InsertUpdateAssociateDetails = `${url}/api/RCMBAssociateDetails/InsertUpdateAssociateDetails`;
export const GetGeneratedQRCode = `${url}/api/RCMBDocumentsScreening/GenerateQRCode`;
export const GetRehiredCandidateDetails = `${url}/api/RCMBAssociateDetails/GetRehiredCandidateDetails`;

export const GetDropdownData = `${url}/api/RCMBDocumentsScreening/RCBMGetDropdownData`;
export const GetAssociateDetailsById = `${url}/api/RCMBAssociateDetails/GetAssociateDetailsById`;
export const GetAssociateDetailsForTransactionScreenById = `${url}/api/RCMBAssociateDetails/GetAssociateDetailsForTransactionScreenById`;
export const RCBMGetDropdownData = `${url}/api/RCMBDocumentsScreening/RCBMGetDropdownData`;
export const GetAssociatesDocument = `${url}/api/RCMBDocumentsScreening/GetAssociatesDocument`;
export const GetAssociatesFingerprint = `${url}/api/RCMBDocumentsScreening/GetAssociatesFingerprint`;

//CLM
export const GetWernerSkillTestData = `${url}/api/RCMBDocumentsScreening/GetWernerSkillTestData`;
export const GetWernerSkillTestGradeChartData = `${url}/api/RCMBDocumentsScreening/GetWernerSkillTestGradeChartData`;
export const GetManageWernerSkillTest = `${url}/api/RCMBDocumentsScreening/GetManageWernerSkillTest`;
export const ManageWernerSkillTest = `${url}/api/RCMBDocumentsScreening/ManageWernerSkillTest`;
export const GetWernerSkillTestDropDown = `${url}/api/RCMBDocumentsScreening/GetWernerSkillTestDropDown`;
export const GetContractorDetailsDropDown = `${url}/api/CLMContractorDetails/GetContractorDetailsDropDown`;
export const GetManageContractorDetails = `${url}/api/CLMContractorDetails/GetManageContractorDetails`;
export const ManageContractorDetails = `${url}/api/CLMContractorDetails/ManageContractorDetails`;
export const ManageContractorUser = `${url}/api/CLMContractorDetails/ManageContractorUser`;
export const SendEmailNotification = `${url}/api/CLMContractorDetails/SendEmailNotification`;
export const GetManageContractorUser = `${url}/api/CLMContractorDetails/GetManageContractorUser`;
export const GetContractorUserDasboardDetails = `${url}/api/CLMContractorDetails/GetContractorUserDasboardDetails`;
export const ManageContractorComplianceDetails = `${url}/api/CLMContractorDetails/ManageContractorComplianceDetails`;
export const GetManageContractorComplianceDetails = `${url}/api/CLMContractorDetails/GetManageContractorComplianceDetails`;
export const DeleteManageContractorComplianceDetails = `${url}/api/CLMContractorDetails/DeleteManageContractorComplianceDetails`;
export const GetContractorComplianceDasboardDetails = `${url}/api/CLMContractorDetails/GetContractorComplianceDasboardDetails`;
export const GetContractorDetailsDasboardData = `${url}/api/CLMContractorDetails/GetContractorDetailsDasboardData`;
export const GetContractorComplianceDetailsDasboardData = `${url}/api/CLMContractorDetails/GetContractorComplianceDetailsDasboardData`;

export const ProcessAadharImage = `${url}/api/RCMBAssociateDetails/UploadAndProcessImages`;
export const GetAssociateUANDetails = `${url}/api/RCMBAssociateDetails/GetAssociateUANDetails`;
export const GetNotVerifiedAssociateUANDetails = `${url}/api/RCMBAssociateDetails/GetNotVerifiedAssociateUANDetails`;
export const UploadAssociateBiometricDetails = `${url}/api/RCMBDocumentsScreening/UploadAssociateBiometricDetails`;
export const DownloadCandidateUANExcelTemplate = `${url}/api/RCMBAssociateDetails/DownloadCandidateUANExcelTemplate`;
export const UploadCandidateUANExcel = `${url}/api/RCMBAssociateDetails/UploadCandidateUANExcel`;
export const DownloadUANExcelTemplate = `${url}/api/RCMBAssociateDetails/DownloadUANExcelTemplate`;
export const UploadSignatureAndSetDetails = `${url}/api/RCMBDocumentsScreening/UploadSignatureAndSetDetails`;

//        public async Task<JsonResponseModel> SetDocumentURLDetails(RCMBDocumentsScreeningEntity value)
export const GetSkillTestData = `${url}/api/RCMBSkillTest/GetSkillListByJobIdandAssociateId`;
export const GetSkillTestDataById = `${url}/api/RCMBSkillTest/GetSkillTestDataById`;
export const InsertUpdateAssociatesSkillTest = `${url}/api/RCMBSkillTest/InsertUpdateAssociatesSkillTest`;
export const UpdateStagesForAssociateWhenProceed = `${url}/api/RCMBAssociateDetails/UpdateStagesForAssociateWhenProceed`;
//api/RCMBAssociateDetails/GetAssociateDetailsById
export const ValidateBiometricVerification = `${url}/api/RCMBAssociateDetails/ValidateBiometricVerification`;
//        public async Task<JsonResponseModel> SetDocumentURLDetails(RCMBDocumentsScreeningEntity value)
export const GetAssociateStageStatus = `${url}/api/RCMBAssociateDetails/GetAssociateStageStatus`;
export const GetCandidateQueueData = `${url}/api/RCMBAssociateDetails/GetCandidateQueueData`;

export const GetCandidateDropdownData = `${url}/api/RCMBAssociateDetails/GetAssociateDropdownData`;
//change
export const GetAssociateDropdownData = `${url}/api/RCMBAssociateDetails/GetCandidateeDropdownData`;

export const GetAssociateIdAndEIDDropdownData = `${url}/api/RCMBAssociateDetails/GetAssociateIdAndEIDDropdownData`;

export const GetDepartmentDropdownData = `${url}/api/RCMBAssociateDetails/GetDepartmentDropdownData`;

export const DownloadAssociateStageStatus = `${url}/api/RCMBAssociateDetails/DownloadAssociateStageStatus`;
export const GetMaritalStatusOptions = `${url}/api/RCMBAssociateDetails/GetMaritalStatusOptions`;

export const MoveCandidateToEmployeee = `${url}/api/RCMBAssociateDetails/MoveCandidateToEmployeee`;
export const GetMedicalDashboard = `${url}/api/RCMBAssociateDetails/GetMedicalDashboard`;
export const GetAppointmentDashboard = `${url}/api/RCMBAssociateDetails/GetAppointmentDashboard`;

export const GetOverallProcessDashboard = `${url}/api/RCMBAssociateDetails/GetOverallProcessDashboard`;

///api/RCMBAssociateDetails/GetCandidateQueueData
///RCMBAssociateDetails/GetAssociateDropdownData

// export const GenerateBlueColarDocument ="https://localhost:7190/GenerateBlueColarDocument";
export const GenerateBlueColarDocument =
  "https://localhost:44363/GenerateBlueColarDocument";
// export const Statuswithfile = "https://localhost:7190/Statuswithfile";
export const Statuswithfile = "https://localhost:44363/Statuswithfile";
export const GetSectionByWorkArea = `${url}/api/RCMBAssociateDetails/GetSectionByWorkArea`;

//http://110.227.208.79:1010/api/WebCommunication/GetExternalToken

export const GetExternalToken =
  "http://110.227.208.79:1010/api/WebCommunication/GetExternalToken";
export const ValidateBiometric = `http://110.227.208.79:1010/api/WebCommunication/ExternalBiometricVerification/Validate`;
//export const GetExternalToken = `http://110.227.208.79:1010/api/WebCommunication/GetExternalToken`;
export const GetStandardMonthlyWage = `${url}/api/RCMBAssociateDetails/GetStandardMonthlyWage`;
export const GetMinimumWage = `${url}/api/RCMBAssociateDetails/GetMinimumMonthlyWage`;
export const GetStandardRehireWage = `${url}/api/RCMBAssociateDetails/GetRehireStandardWage`;

export const UpdateAssociateEventDetails = `${url}/api/RCMBAssociateDetails/UpdateAssociateEventDetails`;
export const GetEventOptions = `${url}/api/RCMBAssociateDetails/GetEventOptions`;
export const GetEventReasonOptions = `${url}/api/RCMBAssociateDetails/GetEventReasonOptions`;
export const GetAssociateCounts = `${url}/api/RCMBAssociateDetails/GetAssociateCounts`;
export const GetActiveAppraisalCycle = `${url}/api/RCMBAssociateDetails/GetActiveAppraisalCycle`;
export const GetSkillAmount = `${url}/api/RCMBAssociateDetails/GetSkillAmount`;

export const UpdateTATForTransitionStages = `${url}/api/RCMBAssociateDetails/UpdateTATForTransitionStages`;
export const GetTATReport = `${url}/api/RCMBAssociateDetails/GetTATReport`;
export const GetWorkflowStages = `${url}/api/RCMBAssociateDetails/GetWorkflowStages`;
export const GetAssociateTATReport = `${url}/api/RCMBAssociateDetails/GetAssociateTATReport`;

export const DownloadCandidateLogsheetExcel = `${url}/api/RCMBAssociateDetails/DownloadCandidateLogsheetExcel`;
export const GetAssociateDepartmentRecrutment = `${url}/api/RCMBAssociateDetails/GetAssociateDepartmentRecrutment`;
export const DownloadDepartmentExcel = `${url}api/RCMBAssociateDetails/DownloadDepartmentExcel`;
export const GetDailyRecruitment = `${url}/api/RCMBAssociateDetails/GetDailyRecruitment`;
export const DownloadDailyRecruitmentExcel = `${url}/api/RCMBAssociateDetails/DownloadDailyRecruitmentExcel`;
export const GetSixtydays = `${url}/api/RCMBAssociateDetails/GetSixtydays`;
export const GetRejectedCandidates = `${url}/api/RCMBAssociateReport/GetRejectedCandidates`;
export const DownloadRejectedCandidatesExcel = `${url}api/RCMBAssociateReport/DownloadRejectedCandidatesExcel`;
export const GetAadharValidation = `${url}/api/RCMBAssociateReport/GetAadhaarValidationReport`;
export const DownloadAadharValidationExcel = `${url}api/RCMBAssociateReport/DownloadAadhaarValidationReportExcel`;
export const GetAssociateDailyConsolidate = `${url}/api/RCMBAssociateReport/GetAssociateDailyConsolidate`;
export const DownloadAssociateDailyConsolidateExcel = `${url}api/RCMBAssociateReport/DownloadAssociateDailyConsolidateExcel`;
export const GetCategoryOptions = `${url}api/RCMBAssociateReport/GetCategoryOptions`;
export const GetQRValidDatetimeByToken1 = `${url}api/RCMBAssociateDetails/GetQRValidDatetimeByToken`;
export const CheckAadhaarVerified = `${url}api/RCMBAssociateDetails/CheckAadhaarVerified`;
export const UpdateAadhaarVerified = `${url}api/RCMBAssociateDetails/UpdateAadhaarVerified`;
export const GetBlueCollarBussinessDropdown = `${url}api/RCMBAssociateDetails/GetBlueCollarBussinessDropdown`;

export const GetWCLApprovalWorkflow = `${url}/api/RCMBWCL/GetApprovalWorkflow`;
export const initiateApprovalWorkflowEndPoint = `${url}/api/RCMBWCL/InitiateApprovalWorkflow`;
export const CreateSendBackRequest = `${url}/api/RCMBWCL/CreateSendBackRequest`;
export const CreateApproversTask = `${url}/api/RCMBWCL/CreateApproversTask`;
export const GetSendBackWorkflowDetails = `${url}/api/RCMBWCL/GetSendBackWorkflowDetails`;
export const GetHistoryDetailBasedOnId = `${url}/api/RCMBWCL/GetHistoryDetailBasedOnId`;
export const RejectApprovalRequest = `${url}/api/RCMBWCL/RejectApprovalRequest`;
export const CompleteApprovalRequest = `${url}/api/RCMBWCL/CompleteApprovalRequest`;
export const GetApproverForWCL = `${url}/api/RCMBWCL/GetApproverForWCL`;

export const isOrganisationInfo = `${url}api/RCMBAssociateDetails/IsOrganisation`;
export const UpdateRehireFlag = `${url}api/RCMBAssociateDetails/UpdateRehireFlag`;

export const GetUANReportData = `${url}/api/RCMBAssociateReport/GetUANReportData`;
export const DownloadAssociateUANReportDataExcel = `${url}api/RCMBAssociateReport/DownloadAssociateUANReportDataExcel`;
export const MedicalPreEmployment = `${url}/api/RCMBAssociateReport/MedicalPreEmploymentReport`;
export const DownloadMedicalPreEmploymentReportExcel = `${url}/api/RCMBAssociateReport/DownloadMedicalPreEmploymentReportExcel`;

//api/RCMBAssociateDetails/GetSixtydays

//api/RCMBAssociateDetails/DownloadDailyRecruitmentExcel
///api/RCMBAssociateDetails/GetDailyRecruitment

//api/RCMBAssociateDetails/GetAssociateTATReport

//api/RCMBAssociateDetails/GetAssociateDepartmentRecrutment

//api/RCMBAssociateDetails/DownloadDepartmentExcel
