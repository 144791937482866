import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoaderContext } from "../../Helpers/Context/Context";
import { getCandidateDashboardDataForApprove } from "../../Helpers/APIEndPoints/EndPoints";
import { APICall } from "../../Helpers/APICalls";
import { Tooltip } from "@mui/material";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import moment from "moment";
import InputForm from "../../Components/InputForm/InputForm";

const CandidateDashboard = ({
  setActiveTab = () => {},
  setTabs = () => {},
  hideAction = false,
  isMRFAdmin = false,
}: {
  setActiveTab?: any;
  setTabs?: any;
  hideAction?: boolean;
  isMRFAdmin?: boolean;
}) => {
  const { state } = useLocation();
  // Get the local timezone offset in minutes
  const localOffsetMinutes = moment().utcOffset();
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();

  const [mrfId, setMrfId] = useState(state && state?.TId > 0 ? state.TId : 0);

  const [gridData, setGridData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [start, setStart] = useState(0);
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("");
  const [searchText, setSearchText] = useState("");
  const [count, setCount] = useState(0);

  useEffect(() => {
    (async () => {
      await getDashboardData();
    })();
  }, [start, sortColumn, sortDirection, searchText]);

  const getDashboardData = async () => {
    showLoader();
    let requestParams = {
      PageNumber: start,
      PageSize: pageSize,
      SearchText: searchText,
      SortColumn: sortColumn,
      SortOrder: sortDirection,
      MrfId: mrfId,
    };

    const res = await APICall(
      getCandidateDashboardDataForApprove,
      "POST",
      requestParams
    );

    if (res?.data && res?.data?.length > 0) {
      res.data.forEach((response) => {
        response.appliedDate = response.appliedDate
          ? moment
              .utc(response.appliedDate)
              .utcOffset(localOffsetMinutes)
              .format("DD MMM YYYY HH:mm:ss")
          : "";
        response.schedule = response.schedule
          ? moment
              .utc(response.schedule)
              .utcOffset(localOffsetMinutes)
              .format("DD MMM YYYY HH:mm:ss")
          : "";
      });
      setGridData(res?.data);
      setCount(res?.data[0]?.count);
    } else {
      setGridData([]);
      setCount(0);
    }
    hideLoader();
  };

  const edit = (value, tableMeta) => {
    const CandidateId = tableMeta.rowData[0];
    const MrfId = tableMeta.rowData[1];
    if (isMRFAdmin) {
      navigate("/requistion", {
        state: {
          ...state,
          TId: MrfId,
          CandidateId,
          Tab: "NewTab",
          isRecruiterCandidate: true,
        },
      });
    } else {
      navigate("/recruiterCandidate", {
        state: {
          TId: MrfId,
          CandidateId,
          Tab: "NewTab",
          isRecruiterCandidate: true,
        },
      });
    }
  };

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: pageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",
    onChangePage: (page) => {
      setStart(page * pageSize);
    },
    onColumnSortChange: (sortColumn, sortDirection) => {
      setSortColumn(sortColumn);
      setSortDirection(sortDirection);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const gridColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        display: true,
      },
    },
    {
      name: "mrfId",
      label: "MRFId",
      options: {
        display: false,
      },
    },
    {
      name: "candidateName",
      label: "Candidate Name",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },
    {
      name: "source",
      label: "Source",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },
    {
      name: "appliedDate",
      label: "Applied Date",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },
    {
      name: "emailId",
      label: "Email Id",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },
    {
      name: "mobileNo",
      label: "Mobile No",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },
    {
      name: "currentStep",
      label: "Current Stage",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },
    {
      name: "nextStep",
      label: "Next Stage",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },
    {
      name: "schedule",
      label: "Schedule",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta) => {
          let id = tableMeta.tableData[tableMeta.rowIndex].Id;
          return (
            <>
              {isMRFAdmin ? (
                <>
                  <Tooltip title="view">
                    <a
                      onClick={(e) => {
                        e.preventDefault();

                        setTabs((prev) => {
                          return [
                            ...prev,
                            {
                              TDN: "Application",
                              TN: "NewTab",
                              TSN: 1,
                              createdBy: 0,
                              createdDateUtc: "0001-01-01T00:00:00",
                              id: 0,
                              isActive: true,
                              modifiedBy: null,
                              modifiedDateUtc: null,
                              proxyUserId: null,
                              tid: 0,
                            },
                          ];
                        });
                        setActiveTab("NewTab");
                        edit(value, tableMeta);
                        //   navigate("edit", { state: value });
                      }}
                    >
                      <i className="fas fa-eye"></i>
                    </a>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip title="edit">
                    <a
                      onClick={(e) => {
                        e.preventDefault();

                        setTabs((prev) => {
                          return [
                            ...prev,
                            {
                              TDN: "Application",
                              TN: "NewTab",
                              TSN: 1,
                              createdBy: 0,
                              createdDateUtc: "0001-01-01T00:00:00",
                              id: 0,
                              isActive: true,
                              modifiedBy: null,
                              modifiedDateUtc: null,
                              proxyUserId: null,
                              tid: 0,
                            },
                          ];
                        });
                        setActiveTab("NewTab");
                        edit(value, tableMeta);
                        //   navigate("edit", { state: value });
                      }}
                    >
                      <i className="fas fa-edit"></i>
                    </a>
                  </Tooltip>
                </>
              )}
            </>
          );
        },
      },
    },
  ];

  return (
    <div className="container-fluid">
      {/* <div className="d-flex align-items-center justify-content-end mb-3">
        <button
          onClick={() => {
            navigate("/agencycandidate", {
              state: { TId: mrfId, JobId: state?.JobId },
            });
          }}
          className="btn btn-primary mt-3"
        >
          <i className="fa fa-plus mr-2"></i>
          Add New
        </button>
      </div> */}

      <div className="grid-wrapper grid_mob">
        <div className="row mx-auto filtBox">
          <div className="col-lg-3  col-md-6 col-sm-12">
            <InputForm
              value={searchText}
              placeholder={"Search..."}
              isDisabled={false}
              textArea={false}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </div>
        </div>
      </div>

      <div className="mb-3 pt-3">
        <DynamicGrid data={gridData} columns={gridColumns} options={options} />
      </div>
    </div>
  );
};

export default CandidateDashboard;
