import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import InputForm from "../../Components/InputForm/InputForm";
import { Modal } from "react-bootstrap";
import StructureDetail from "./StructureDetail";
import {
  CommonDropdownData,
  GetStructureDetailsDataById,
} from "../../Helpers/APIEndPoints/EndPoints";
import { LoaderContext } from "../../Helpers/Context/Context";
import { APICall } from "../../Helpers/APICalls";
import SelectForm from "../../Components/SelectForm/SelectForm";
import notify from "../../Helpers/ToastNotification";

const JobDetail = ({ jobData, setJobData, job, jobIndex, isReadOnly }) => {
  const [accordionState, setAccordionState] = useState(true);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationModalReturnValue, setConfirmationModalReturnValue] =
    useState(false);

  const [showStructureModal, setShowStructureModal] = useState(false);
  const [showStructureModalReturnValue, setShowStructureModalReturnValue] =
    useState(false);
  const [selectedStructure, setSelectedStructure] = useState<any>({});
  const [structureOptions, setStructureOptions] = useState([]);

  const closeStructureModal = (confirmation) => {
    if (confirmation) {
      setShowStructureModalReturnValue(true);
    }
  };

  useEffect(() => {
    if (showStructureModalReturnValue) {
      if (
        Object.keys(selectedStructure).length > 0 &&
        selectedStructure.value === "custom"
      ) {
        let newArr = [...jobData];
        if (newArr[jobIndex].hasOwnProperty("structureDetails")) {
          newArr[jobIndex].structureDetails = [
            ...newArr[jobIndex].structureDetails,
            newStructure,
          ];
        } else {
          newArr[jobIndex].structureDetails = [newStructure];
        }
        setJobData(newArr);
        setShowStructureModal(false);
      } else if (
        Object.keys(selectedStructure).length > 0 &&
        selectedStructure.value !== "custom"
      ) {
        getStructureDetails();
      }
    }
    setShowStructureModalReturnValue(false);
  }, [showStructureModalReturnValue]);

  const closeConfirmationModal = (confirmation) => {
    if (confirmation) {
      setConfirmationModalReturnValue(confirmation);
    }
    setShowConfirmationModal(false);
  };

  const getStructureDropdown = async () => {
    const res = await APICall(CommonDropdownData, "POST", {
      dropdownName: "structure",
    });

    if (res.data !== null) {
      let data = [...res.data, { label: "custom", value: "custom" }];
      setStructureOptions(data);
    } else {
      setStructureOptions([]);
    }
  };

  useEffect(() => {
    if (showStructureModal) {
      getStructureDropdown();
    } else {
      setSelectedStructure({});
      setShowStructureModalReturnValue(false);
    }
  }, [showStructureModal]);

  useEffect(() => {
    if (confirmationModalReturnValue) {
      setJobData((prev) => prev.filter((job, jx) => jx !== jobIndex));
    }
    return () => {
      setShowConfirmationModal(false);
      setConfirmationModalReturnValue(false);
    };
  }, [confirmationModalReturnValue]);

  const newStructure = {
    strSeqNo: "",
    name: "",
  };

  const getStructureDetails = async () => {
    showLoader();
    const res = await APICall(GetStructureDetailsDataById, "POST", {
      StructureId: selectedStructure.value,
    });

    if (res.data !== null && res.data.length > 0) {
      let newArr = [...jobData];
      if (newArr[jobIndex].hasOwnProperty("structureDetails")) {
        newArr[jobIndex].structureDetails = [
          ...newArr[jobIndex].structureDetails,
          ...res.data,
        ];
      } else {
        newArr[jobIndex].structureDetails = res.data;
      }
      setJobData(newArr);
      setShowStructureModal(false);
    } else {
      notify(1, `No structure mapped for ${selectedStructure.label}`);
    }
    hideLoader();
  };

  return (
    <>
      <Accordion
        className="mb-2"
        expanded={accordionState}
        onChange={() => setAccordionState((prev) => !prev)}>
        <AccordionSummary
          className="text-white"
          style={{ background: "rgb(151 151 151)", height: "20px" }}
          expandIcon={<ExpandMore />}
          id={`job-${jobIndex}`}>
          <div className="d-flex align-items-center">
            <Typography>
              {job.jobNo && `${job.jobNo} `}
              <strong>Job Number</strong> {`${job.name}`}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className="page_heading">
          <div className="container-fluid">
            <div
              style={{ padding: 15 }}
              className="row align-items-center justify-content-between">
              <div className="row">
                <div className="col-lg-4 col-md-2 col-sx-2">
                  <div className="form-group">
                    <label className="col-form-label">Job Number</label>
                    <sup>*</sup>
                    <InputForm
                      className="form-control form-control-lg"
                      placeholder={""}
                      name="jobNo"
                      isDisabled={isReadOnly}
                      textArea={false}
                      value={job.jobNo}
                      onChange={(e) => {
                        let newArr = [...jobData];
                        newArr[jobIndex][e.target.name] = e.target.value;
                        setJobData(newArr);
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-2 col-sx-2">
                  <div className="form-group">
                    <label className="col-form-label">Job Name</label>
                    <sup>*</sup>
                    <InputForm
                      className="form-control form-control-lg"
                      placeholder={""}
                      name="name"
                      isDisabled={isReadOnly}
                      textArea={false}
                      value={job.name}
                      onChange={(e) => {
                        let newArr = [...jobData];
                        newArr[jobIndex][e.target.name] = e.target.value;
                        setJobData(newArr);
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-2 col-sx-2">
                  <div className="form-group">
                    <label className="col-form-label">Job Location</label>
                    {/* <sup>*</sup> */}
                    <InputForm
                      className="form-control form-control-lg"
                      placeholder={""}
                      name="location"
                      isDisabled={isReadOnly}
                      textArea={false}
                      value={job.location}
                      onChange={(e) => {
                        let newArr = [...jobData];
                        newArr[jobIndex][e.target.name] = e.target.value;
                        setJobData(newArr);
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-2 col-sx-2">
                  <div className="form-group">
                    <label className="col-form-label">Job Description</label>
                    <InputForm
                      className="form-control form-control-lg"
                      placeholder={""}
                      name="description"
                      isDisabled={isReadOnly}
                      textArea={true}
                      rows={3}
                      value={job.description}
                      onChange={(e) => {
                        let newArr = [...jobData];
                        newArr[jobIndex][e.target.name] = e.target.value;
                        setJobData(newArr);
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sx-2">
                  <div className="form-group">
                    <label htmlFor="isActive" className="col-form-label">
                      Is Active
                    </label>
                    <sup>*</sup>
                    <div className="custom-checkbox">
                      <input
                        disabled={isReadOnly}
                        id="isActive"
                        type="checkbox"
                        onChange={(e) => {
                          let newArr = [...jobData];
                          newArr[jobIndex][e.target.name] = e.target.checked;
                          setJobData(newArr);
                        }}
                        name="isActive"
                        checked={job.isActive}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {!isReadOnly && (
                <div>
                  <button
                    className="btn btn-danger"
                    style={{ marginLeft: 5 }}
                    onClick={(e) => setShowConfirmationModal(true)}>
                    <i className="fa fa-trash"></i> Delete
                  </button>
                  <button
                    className="btn btn-info"
                    style={{ marginLeft: 5 }}
                    onClick={(e) => {
                      setShowStructureModal(true);
                    }}>
                    <i className="fa fa-sitemap"></i> Add Structure
                  </button>
                </div>
              )}
            </div>
            <div>
              {job.hasOwnProperty("structureDetails") &&
                job.structureDetails.length > 0 &&
                job.structureDetails.map((structure, stIndex) => (
                  <React.Fragment key={stIndex}>
                    <StructureDetail
                      structure={structure}
                      jobData={jobData}
                      setJobData={setJobData}
                      jobIndex={jobIndex}
                      stIndex={stIndex}
                      isReadOnly={isReadOnly}
                    />
                  </React.Fragment>
                ))}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Modal
        show={showConfirmationModal}
        onHide={() => closeConfirmationModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">Delete Job Number</div>
        </div>
        <Modal.Body>
          <h5>Are you sure?</h5>
          <div>
            <strong>
              (Note: This will also delete related Structure, Activity and
              Parameter)
            </strong>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-success"
            style={{ marginLeft: 5 }}
            onClick={() => closeConfirmationModal(true)}>
            Yes
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => closeConfirmationModal(false)}>
            No
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showStructureModal}
        onHide={() => closeStructureModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">Select Structure</div>
        </div>
        <Modal.Body>
          <div className="col-lg-12">
            <div className="form-group">
              <label className="col-form-label">Structure</label>
              <sup>*</sup>
              <SelectForm
                value={selectedStructure}
                onChange={(event) => {
                  setSelectedStructure(event);
                }}
                placeholder={"Select"}
                options={structureOptions}
                isDisabled={false}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-success"
            style={{ marginLeft: 5 }}
            onClick={() => closeStructureModal(true)}>
            Ok
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => setShowStructureModal(false)}>
            <i className="fa fa-times" aria-hidden="true"></i> Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default JobDetail;
