import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoaderContext } from "../../Helpers/Context/Context";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import SelectForm from "../../Components/SelectForm/SelectForm";
import InputForm from "../../Components/InputForm/InputForm";
import { APICall } from "../../Helpers/APICalls";
import {
  GetCostAllotmentData,
  SaveCostAllotmentData,
  getCostAllotmentDropDownData,
  getCourseAssessmentDropDownList,
  getLMSDomainDropDownData,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import CourseGrid from "./CostAllotmentGridComponent/CourseGrid";
import notify from "../../Helpers/ToastNotification";
import CourseScheduleGrid from "./CostAllotmentGridComponent/CourseScheduleGrid";

interface IOptions {
  label?: string;
  value?: number | string;
  code?: string;
}

interface IFormData {
  domain?: IOptions[] | any;
  costAllotmentLevel?: IOptions | any;
  feedbackType?: IOptions | any;
  costCurrency?: IOptions | any;
  courseSchedule?: IOptions | any;
  costPerCourse?: Number | any;
}

const CostAllotment = ({ activeTab, setActiveTab }) => {
  const { state } = useLocation();

  const [isDisable, setIsDisable] = useState(
    state && state?.isDisable ? true : false
  );

  const { showLoader, hideLoader } = useContext(LoaderContext);

  const { userDetails, currentRoleId }: any = useUserContext();

  const navigate = useNavigate();

  const [formData, setFormData] = useState<IFormData>({});
  const [formErrors, setFormErrors] = useState<IFormData>({});
  const [formOptions, setFormOptions] = useState<IFormData>({});

  const [costAllotmentId, setCostAllotmentId] = useState(0);

  const [gridReset, setGridReset] = useState(false);

  const costAllotmentLevelObj = {
    Course: "COURSE",
    CourseSchedule: "CS",
  };

  useEffect(() => {
    const responseData = async () => {
      //COSTALLOTMENTLEVEL
      const res = await APICall(getCostAllotmentDropDownData, "POST", {
        Mode: "COSTALLOTMENTLEVEL",
        UserId: userDetails?.id,
        RoleId: currentRoleId?.value,
      });
      if (res.data && res.data.length > 0) {
        setFormOptions((prev) => {
          return {
            ...prev,
            costAllotmentLevel: res.data,
          };
        });
      }
      //COSTCURRENCY
      const res1 = await APICall(getCostAllotmentDropDownData, "POST", {
        Mode: "COSTCURRENCY",
        UserId: userDetails?.id,
        RoleId: currentRoleId?.value,
      });
      if (res1.data && res1.data.length > 0) {
        setFormOptions((prev) => {
          return {
            ...prev,
            costCurrency: res1.data,
          };
        });
      }

      //COURSE_SCHEDULE
      const res5 = await APICall(getCourseAssessmentDropDownList, "POST", {
        Mode: "COURSE_SCHEDULE",
        CourseId: state?.courseId,
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      });

      if (res5.data && res5.data.length > 0) {
        setFormOptions((prev) => {
          return {
            ...prev,
            courseSchedule: res5.data,
          };
        });
      }
    };

    showLoader();

    responseData();
    getLMSDomainDropDownDataApiCall();

    hideLoader();
  }, []);

  const getLMSDomainDropDownDataApiCall = async () => {
    const response = await APICall(getLMSDomainDropDownData, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      GetAll: state && state?.assessmentId > 0 ? true : false,
    });

    if (response?.status === 0 && response?.data.length > 0) {
      setFormOptions((prev) => {
        return {
          ...prev,
          domain: response?.data,
        };
      });
    }
  };

  const formOnChange = (e, type) => {
    switch (type) {
      case "domain":
        setFormData({
          ...formData,
          domain: e,
        });
        break;

      case "costAllotmentLevel":
        setFormData({
          ...formData,
          costAllotmentLevel: e,
        });

        if (e.code === costAllotmentLevelObj.Course) {
          GetCostAllotmentDataCourseApiCall(e.value);
        }

        break;

      case "costCurrency":
        setFormData({
          ...formData,
          costCurrency: e,
        });
        break;

      case "courseSchedule":
        setFormData({
          ...formData,
          courseSchedule: e,
        });

        if (
          formData?.costAllotmentLevel?.code ===
          costAllotmentLevelObj.CourseSchedule
        ) {
          GetCostAllotmentDataCSApiCall(
            formData?.costAllotmentLevel?.value,
            e?.value
          );

          setGridReset(!gridReset);
        }
        break;

      default:
        break;
    }
  };

  const validateForm = () => {
    let errorObj = {};
    let isError = false;

    setFormErrors({});

    if (
      formData?.domain?.length === 0 ||
      formData?.domain === null ||
      formData?.domain === undefined
    ) {
      errorObj = {
        ...errorObj,
        ["domain"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["domain"]: "",
      };
    }

    if (
      formData?.costAllotmentLevel === null ||
      formData?.costAllotmentLevel === undefined
    ) {
      errorObj = {
        ...errorObj,
        ["costAllotmentLevel"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["costAllotmentLevel"]: "",
      };
    }

    if (
      formData?.costCurrency === null ||
      formData?.costCurrency === undefined
    ) {
      errorObj = {
        ...errorObj,
        ["costCurrency"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["costCurrency"]: "",
      };
    }

    // if (
    //   formData?.feedbackDays !== null &&
    //   formData?.feedbackDays !== undefined
    // ) {
    //   if (!/^([0-9]+|0)$/.test(formData?.feedbackDays)) {
    //     errorObj = {
    //       ...errorObj,
    //       ["feedbackDays"]: "Only numbers are allowed",
    //     };
    //   } else {
    //     errorObj = {
    //       ...errorObj,
    //       ["feedbackDays"]: "",
    //     };
    //   }
    // }

    const isEmpty = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  const handleSubmitFunction = async () => {
    showLoader();

    if (validateForm()) {
      hideLoader();
      return;
    }

    if (state?.courseId > 0) {
      const parameters = {
        CourseId: state?.courseId,
        DomainIds: formData?.domain?.map((s) => s.value).join(","),
        CostAllotmentLevelId: formData?.costAllotmentLevel?.value,
        CurrencyId: formData?.costCurrency?.value,
        CourseScheduleId: formData?.courseSchedule?.value,
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      };

      const responseData = await APICall(
        SaveCostAllotmentData,
        "POST",
        parameters
      );

      if (responseData?.status === 0) {
        notify(responseData?.status, responseData?.message);
        setCostAllotmentId(responseData?.data?.id);
        // handleResetFunction();
        // setGridReset(!gridReset);
        setGridReset(!gridReset);
      } else if (responseData?.status === 1) {
        notify(responseData?.status, responseData?.message);
      }
    } else {
      notify(1, "Course ID not found.");
    }

    hideLoader();
  };

  const handleResetFunction = () => {
    setFormData((prev) => {
      return {
        ...prev,
        domain: null,
        costCurrency: null,
      };
    });

    setFormErrors({});
  };

  useEffect(() => {
    if (
      formData?.costAllotmentLevel?.code === costAllotmentLevelObj.Course &&
      formData?.costAllotmentLevel?.value > 0
    ) {
      GetCostAllotmentDataCourseApiCall(formData?.costAllotmentLevel?.value);
    } else if (
      formData?.costAllotmentLevel?.code ===
        costAllotmentLevelObj.CourseSchedule &&
      formData?.costAllotmentLevel?.value > 0 &&
      formData?.courseSchedule?.value > 0
    ) {
      GetCostAllotmentDataCSApiCall(
        formData?.costAllotmentLevel?.value,
        formData?.courseSchedule?.value
      );
    }
  }, [gridReset]);

  const GetCostAllotmentDataCourseApiCall = async (costAllotmentLevelId) => {
    showLoader();
    if (state?.courseId > 0 && costAllotmentLevelId > 0) {
      try {
        const responseData = await APICall(GetCostAllotmentData, "POST", {
          CourseId: state?.courseId,
          CostAllotmentLevelId: costAllotmentLevelId,
          UserId: userDetails?.Id,
          RoleId: currentRoleId?.value,
        });

        if (responseData?.status === 0 && responseData?.data !== null) {
          setCostAllotmentId(responseData?.data?.costAllotmentId);
          const costAllotmentLevelObj = formOptions?.costAllotmentLevel?.find(
            (s) =>
              s.value === parseInt(responseData?.data?.costAllotmentLevelId)
          );

          const costCurrencyObj = formOptions?.costCurrency?.find(
            (s) => s.value === parseInt(responseData?.data?.currencyId)
          );

          const domainIds = responseData?.data?.domainIds?.split(",") || null;

          const domainArray = [];

          if (domainIds && formOptions?.domain?.length > 0) {
            domainIds?.map((item) => {
              const domainData = formOptions?.domain?.find(
                (i) => i.value === parseInt(item)
              );
              domainArray.push(domainData);
            });
          }

          setFormData((env) => {
            return {
              ...env,
              domain: domainArray.length > 0 ? domainArray : null,
              costAllotmentLevel: costAllotmentLevelObj,
              costCurrency: costCurrencyObj,
              costPerCourse: responseData?.data?.totalCost,
            };
          });
        } else if (responseData?.status === 1) {
          notify(responseData?.status, responseData?.message);
        }
      } catch (error) {
        console.error(error);
      }
    }
    hideLoader();
  };

  const GetCostAllotmentDataCSApiCall = async (
    costAllotmentLevelId,
    courseScheduleId
  ) => {
    showLoader();
    if (
      state?.courseId > 0 &&
      costAllotmentLevelId > 0 &&
      courseScheduleId > 0
    ) {
      try {
        const responseData = await APICall(GetCostAllotmentData, "POST", {
          CourseId: state?.courseId,
          CostAllotmentLevelId: costAllotmentLevelId,
          CourseScheduleId: courseScheduleId,
          UserId: userDetails?.Id,
          RoleId: currentRoleId?.value,
        });

        if (responseData?.status === 0 && responseData?.data !== null) {
          setCostAllotmentId(responseData?.data?.costAllotmentId);
          const costAllotmentLevelObj = formOptions?.costAllotmentLevel?.find(
            (s) =>
              s.value === parseInt(responseData?.data?.costAllotmentLevelId)
          );

          const costCurrencyObj = formOptions?.costCurrency?.find(
            (s) => s.value === parseInt(responseData?.data?.currencyId)
          );

          const domainIds = responseData?.data?.domainIds?.split(",") || null;

          const domainArray = [];

          if (domainIds && formOptions?.domain?.length > 0) {
            domainIds?.map((item) => {
              const domainData = formOptions?.domain?.find(
                (i) => i.value === parseInt(item)
              );
              domainArray.push(domainData);
            });
          }

          setFormData((env) => {
            return {
              ...env,
              domain: domainArray.length > 0 ? domainArray : null,
              costAllotmentLevel: costAllotmentLevelObj,
              costCurrency: costCurrencyObj,
              costPerCourse: responseData?.data?.totalCost,
            };
          });
        } else if (responseData?.status === 1) {
          notify(responseData?.status, responseData?.message);
        }
      } catch (error) {
        console.error(error);
      }
    }
    hideLoader();
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className={"col-lg-3 col-sm-4 col-xs-6"}>
            <div className="dropdown mb-1">
              <label className="col-form-label">Domain</label> <sup>*</sup>
              <SelectForm
                isClearable
                isSearchable
                options={formOptions?.domain}
                placeholder={"Domain"}
                isDisabled={isDisable}
                value={formData?.domain}
                onChange={(e) => {
                  formOnChange(e, "domain");
                }}
                isMulti={true}
                noIndicator={false}
                noSeparator={false}
              />
              {formErrors["domain"] && (
                <p style={{ color: "red" }}>{formErrors["domain"]}</p>
              )}
            </div>
          </div>

          <div className={"col-lg-3 col-sm-4 col-xs-6"}>
            <div className="dropdown mb-1">
              <label className="col-form-label">Cost Allotment Level</label>{" "}
              <sup>*</sup>
              <SelectForm
                isClearable
                isSearchable
                options={formOptions?.costAllotmentLevel}
                placeholder={"Cost Allotment Level"}
                isDisabled={false}
                value={formData?.costAllotmentLevel}
                onChange={(e) => {
                  formOnChange(e, "costAllotmentLevel");
                }}
                isMulti={false}
                noIndicator={false}
                noSeparator={false}
              />
              {formErrors["costAllotmentLevel"] && (
                <p style={{ color: "red" }}>
                  {formErrors["costAllotmentLevel"]}
                </p>
              )}
            </div>
          </div>

          {formData?.costAllotmentLevel?.code ===
            costAllotmentLevelObj?.CourseSchedule && (
            <>
              <div className={"col-lg-3 col-sm-4 col-xs-6"}>
                <div className="dropdown mb-1">
                  <label className="col-form-label">Course Schedule</label>{" "}
                  <sup>*</sup>
                  <SelectForm
                    isClearable
                    isSearchable
                    options={formOptions?.courseSchedule}
                    placeholder={"Course Schedule"}
                    isDisabled={false}
                    value={formData?.courseSchedule}
                    onChange={(e) => {
                      formOnChange(e, "courseSchedule");
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  {formErrors["courseSchedule"] && (
                    <p style={{ color: "red" }}>
                      {formErrors["courseSchedule"]}
                    </p>
                  )}
                </div>
              </div>
            </>
          )}

          <div className={"col-lg-3 col-sm-4 col-xs-6"}>
            <div className="dropdown mb-1">
              <label className="col-form-label">Cost Currency</label>{" "}
              <sup>*</sup>
              <SelectForm
                isClearable
                isSearchable
                options={formOptions?.costCurrency}
                placeholder={"Cost Currency"}
                isDisabled={isDisable}
                value={formData?.costCurrency}
                onChange={(e) => {
                  formOnChange(e, "costCurrency");
                }}
                isMulti={false}
                noIndicator={false}
                noSeparator={false}
              />
              {formErrors["costCurrency"] && (
                <p style={{ color: "red" }}>{formErrors["costCurrency"]}</p>
              )}
            </div>
          </div>

          <div className={"col-lg-3 col-sm-4 col-xs-6"}>
            <div className="mb-1">
              <label className="col-form-label">
                Cost Per Course
                <sup>*</sup>
              </label>

              <InputForm
                className="form-control"
                placeholder={"Cost Per Course"}
                isDisabled={true}
                textArea={false}
                value={formData?.costPerCourse}
                onChange={(e) => {
                  formOnChange(e, "costPerCourse");
                }}
                maxLength={6}
                type="number"
              />
              {formErrors["costPerCourse"] && (
                <p style={{ color: "red" }}>{formErrors["costPerCourse"]}</p>
              )}
            </div>
          </div>
        </div>

        <br />

        {!isDisable && (
          <div className="SectionSubmit mb-4 clearfix">
            <button
              onClick={(e) => {
                e.preventDefault();
                handleSubmitFunction();
              }}
              disabled={isDisable}
              className="btn btn-filter-submit float-right ml-2">
              <i className="fa fa-save mr-2"></i>
              Save
            </button>

            <button
              onClick={() => {
                handleResetFunction();
              }}
              disabled={isDisable}
              className="btn btn-secondary float-right ml-2">
              <i className="fa-solid fa-refresh"></i> Reset
            </button>
          </div>
        )}

        {formData?.costAllotmentLevel?.code ===
          costAllotmentLevelObj.Course && (
          <CourseGrid
            costAllotmentId={costAllotmentId}
            gridReset={gridReset}
            setGridReset={setGridReset}
          />
        )}

        {formData?.costAllotmentLevel?.code ===
          costAllotmentLevelObj.CourseSchedule && (
          <CourseScheduleGrid
            costAllotmentId={costAllotmentId}
            gridReset={gridReset}
            setGridReset={setGridReset}
            courseScheduleId={formData?.courseSchedule?.value}
          />
        )}
      </div>
    </>
  );
};

export default CostAllotment;
