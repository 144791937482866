import React, { useState, useEffect, useContext } from "react";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import SelectForm from "../../Components/SelectForm/SelectForm";
import Collapse from "react-bootstrap/Collapse";
import { APICall } from "../../Helpers/APICalls";
import { 
  getDropdowns, 
  getOrganizationUnitGridData, 
  InsertUpdateOUMappings
} from "../../Helpers/APIEndPoints/EndPoints";
import InputForm from "../../Components/InputForm/InputForm";
import { Tooltip } from "@mui/material";
import { LoaderContext } from "../../Helpers/Context/Context";
import notify from "../../Helpers/ToastNotification";
import { useLocation } from "react-router-dom";
import ExcelJS from "exceljs";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import ViewHistoryOu from "./ViewHistoryOu";

const OrganizationUnitDashboard = () => {
  const { state } = useLocation();
  const [sortColumn, setDashboardSortColumn] = useState<any>("");
  const [sortOrder, setDashboardSortDirection] = useState("");
  const [open, setOpen] = useState(false);
  const [isExportToExcel, setExportToExcel] = useState(false);
  const [count, setCount] = useState(0);
  const [gridData, setGridData] = useState(null);
  const [start, setStart] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [searchText, setSearchText] = useState(""); 
  const [PageSize, setPageSize] = useState(10);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  const [ddValue, setDdValue] = useState({});
  const [reset, setReset] = useState(false);
  const [OUIsActive, setOUIsActive] = useState<any>([]);
  console.log(state);
  const [OUId, setSelectedOUId] = useState<any>(state?.ouType);
  let { userDetails, currentRoleId, interfacePermissionRecord }: any = useUserContext();
  const [page, setPage] = useState(0);
  const [showAddNew, setShowAddNew] = useState(false);

  const [idForHistory, setIdForHistory] = useState(0);
  const [codeForHistory, setCodeForHistory] = useState("");
  const [showHistoryModal, setShowHistoryModal] = useState(false);

  const mapVals = ["M1","M2","M4","M5","M6","M7","M8","M9","M11","M12","M13","M14","M15"];
  
  const isViewPermitted = interfacePermissionRecord.some(
    ipr => ipr.interfaceName === 'OrganizationUnit_Form' && ipr.name === 'View');
  
  const isEditPermitted = interfacePermissionRecord.some(
    ipr => ipr.interfaceName === 'OrganizationUnit_Form' && ipr.name === 'Edit');

  const isDeletePermitted = interfacePermissionRecord.some(
    ipr => ipr.interfaceName === 'OrganizationUnit_Form' && ipr.name === 'Delete');

    const isHistoryPermitted = interfacePermissionRecord.some(
      ipr => ipr.interfaceName === 'OrganizationUnit_Form' && ipr.name === 'History');
  
  const getDropdownsData = async (AC, PID = null, cb) => {
    await showLoader();
    const dropdownData = await APICall(getDropdowns, "POST", {
      AC,
      PID,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });
    if (
      dropdownData.data !== null &&
      dropdownData.data !== undefined &&
      dropdownData.data.length > 0 
    ) {
      setDdValue((prev) => ({ ...prev, [AC]: dropdownData.data }));
      
    } else {
      setDdValue((prev) => ({ ...prev, [AC]: [] }));
    }
    await hideLoader();
  };

  useEffect(() => {
    getInterfaceDetails();
    if(OUId && OUId.value && OUId?.value !== 'M1' && OUId?.value !== 'M2' && OUId?.value !== 'M4' && OUId?.value !== 'M5' &&
    OUId?.value !== 'M6' && OUId?.value !== 'M7' && OUId?.value !== 'M8' && OUId?.value !== 'M9' && 
    OUId?.value !== 'M11' && OUId?.value !== 'M12' && OUId?.value !== 'M13' && OUId?.value !== 'M14' && OUId?.value !== 'M15'){
      setShowAddNew(true);
    } else {
      setShowAddNew(false);
    }
  }, [OUId]);

  const getInterfaceDetails = async () => {
    showLoader();
    const gridDataResponse = await APICall(
      "/api/Interface/GetInterfaceDetailsForGrid",
      "POST",
      {
        MN: "OrganizationUnits",
        IN: "OrganizationUnit_Dashboard",
        TN: "OrganizationUnit_Dashboard",
      }
    );
    if (gridDataResponse?.data?.d?.fa !== null) {
      for await (const cFilter of gridDataResponse?.data?.d?.fa) {
        if (cFilter.filt === "multidropdown") {
          await getDropdownsData(cFilter.filAN, null, null);
        }
      }
    }
    if (gridDataResponse?.data?.d?.afa !== null) {
      for await (const cFilter of gridDataResponse?.data?.d?.afa) {
        if (cFilter.filt === "multidropdown") {
          await getDropdownsData(cFilter.filAN, null, null);
        }
      }
    }

    if(!isEditPermitted){
      setShowAddNew(false);
    }
    if (!isEditPermitted && !isDeletePermitted && !isViewPermitted && !isHistoryPermitted)
    {
      for(let i = 0; i < gridDataResponse.data.d.s.length ; i++){
        let actionColumnIndex = gridDataResponse.data.d.s[i].columnData?.findIndex(x=> x.name === 'action');
        gridDataResponse.data.d.s[i].columnData.splice(actionColumnIndex, 1);
      }
    }

    setGridData(gridDataResponse);
    await getGridStructureTwo();
    hideLoader();
  };

  useEffect(() => {
    if (gridData?.data?.d?.s[0]?.columnData.length > 0) {
      getGridStructureTwo();
    }
  }, [start, searchText, reset, sortOrder, sortColumn, isExportToExcel]);

  const getGridStructureTwo = async () => {
    showLoader();

    const GetDashboardData = await APICall(getOrganizationUnitGridData, "POST", {
      DashboardName: "OrganizationUnit",
      Offset: isExportToExcel ? 0 : start,
      PageSize: isExportToExcel ? 0 : PageSize,
      SearchText: isExportToExcel ? '' : searchText,
      SortColumn: isExportToExcel ? '' : sortColumn,
      SortOrder: isExportToExcel ? 'asc' : sortOrder,
      CommaSeperatedIsActiveIds: OUIsActive.map((s) => s.value === true ? 'Yes' : 'No').join(","),
      MasterId : OUId?.value?.toString(),
      MappingId : OUId?.value
    });

    if(isExportToExcel){
      const responseData = GetDashboardData.data;

      if (!responseData || responseData.length === 0) {
        notify(1, "There is No data.");
        setExportToExcel(false);
        hideLoader();
        return;
      }

      const convertCamelCaseToWords = (camelCaseString) => {
        return camelCaseString
        .replace(/([a-z])([A-Z])/g, '$1 $2').split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
      };
      let filteredHeaders = [];

      if(OUId?.value !== 'M1' && OUId?.value !== 'M2' && OUId?.value !== 'M3' && OUId?.value !== 'M4' && OUId?.value !== 'M5' &&
        OUId?.value !== 'M6' && OUId?.value !== 'M7' && OUId?.value !== 'M8' && OUId?.value !== 'M9' && OUId?.value !== 'M10' && 
        OUId?.value !== 'M11' && OUId?.value !== 'M12' && OUId?.value !== 'M13' && OUId?.value !== 'M14' && OUId?.value !== 'M15' && 
        OUId?.value !== 'M16' && OUId?.value !== 'M17' && OUId?.value !== 'M18' && OUId?.value !== 'M19' && OUId?.value !== 'M20' && 
        OUId?.value !== 'M21' && OUId?.value !== 'M22' && OUId?.value !== 'M23' && OUId?.value !== 'M24' && OUId?.value !== 'M25' && 
        OUId?.value !== 'M26' 
      ){
        filteredHeaders = ['Code', 'Name', 'IsActive'];
      } else if(OUId.value === 'M1'){
        filteredHeaders = ['BusinessCode', 'BusinessName', 'SBUCode', 'SBUName', 'IsActive'];
      } else if(OUId.value === 'M2'){
        filteredHeaders = ['SBUCode', 'SBUName', 'LegalEntityCode', 'LegalEntityName', 'IsActive'];
      } else if (OUId.value === 'M3'){
        filteredHeaders = ['BusinessCode', 'BusinessName', 'SBUCode', 'SBUName', 'LegalEntityCode', 'LegalEntityName', 'CustomBusinessCode', 'CustomBusinessName', 'IsActive'];
      } else if(OUId.value === 'M4'){
        filteredHeaders = ['LegalEntityCode', 'LegalEntityName', 'DivisionCode', 'DivisionName', 'IsActive'];
      } else if (OUId.value === 'M5'){
        filteredHeaders = [ 'DivisionCode', 'DivisionName', 'FunctionCode', 'FunctionName', 'IsActive'];
      } else if (OUId.value === 'M6'){
        filteredHeaders = ['FunctionCode', 'FunctionName', 'DepartmentCode', 'DepartmentName', 'IsActive'];
      } else if(OUId.value === 'M7'){
        filteredHeaders = ['DepartmentCode', 'DepartmentName', 'SectionCode', 'SectionName', 'IsActive'];
      } else if(OUId.value === 'M8'){
        filteredHeaders = [ 'SectionCode', 'SectionName', 'SubSectionCode', 'SubSectionName', 'IsActive'];
      } else if (OUId.value === 'M9'){
        filteredHeaders = ['SubSectionCode', 'SubSectionName', 'SubSubSectionCode', 'SubSubSectionName', 'IsActive'];
      } else if(OUId.value === 'M10'){
        filteredHeaders = ['LegalEntityCode', 'LegalEntityName', 'EmployeeClassCode', 'EmployeeClassName', 'JobBandCode', 'JobBandName', 'IsActive'];
      } else if(OUId.value === 'M11'){
        filteredHeaders = ['LegalEntityCode', 'LegalEntityName', 'EmployeeClassCode', 'EmployeeClassName', 'IsActive'];
      } else if(OUId.value === 'M12'){
        filteredHeaders = ['JobBandCode', 'JobBandName', 'JobLevelCode', 'JobLevelName', 'IsActive'];
      } else if(OUId.value === 'M13'){
        filteredHeaders = ['JobLevelCode', 'JobLevelName', 'DesignationCode', 'DesignationName', 'IsActive'];
      } else if(OUId.value === 'M14'){
        filteredHeaders = [ 'DepartmentCode', 'DepartmentName', 'CostCenterCode', 'CostCenterName', 'IsActive'];
      } else if(OUId.value === 'M15'){
        filteredHeaders = ['LegalEntityCode', 'LegalEntityName', 'PayrollLocationCode', 'PayrollLocationName', 'IsActive'];
      } else if(OUId.value === 'M16'){
        filteredHeaders = ['BusinessCode', 'BusinessName', 'SBUCode', 'SBUName', 'LegalEntityCode', 'LegalEntityName', 'CustomBusinessCode', 'CustomBusinessName', 'JobLevelCode', 'JobLevelName', 'NoticePeriodDays', 'NoticePeriodInMonths', 'IsActive'];
      } else if(OUId.value === 'M17'){
        filteredHeaders = ['LegalEntityCode', 'LegalEntityName', 'PayrollLocationCode', 'PayrollLocationName', 'TAN Number', 'IsActive'];
      } else if(OUId.value === 'M18'){
        filteredHeaders = [ 'SBUCode', 'SBUName', 'LegalEntityCode', 'LegalEntityName', 'CompanyCode', 'IsActive'];
      } else if(OUId.value === 'M19'){
        filteredHeaders = ['LegalEntityCode', 'LegalEntityName', 'CustomBusinessCode', 'CustomBusinessName', 'JobLevelCode', 'JobLevelName', 'StandardDesignationCode', 'StandardDesignationName', 'ProbationPeriodDays', 'IsActive'];
      } else if(OUId.value === 'M20'){
        filteredHeaders = ['LegalEntityCode', 'LegalEntityName', 'EmployeeClassCode', 'EmployeeClassName', 'JobLevelCode', 'JobLevelName', 'LOPRate', 'IsActive'];
      } else if(OUId.value === 'M21'){
        filteredHeaders = ['BusinessCode', 'BusinessName', 'SBUCode', 'SBUName', 'LegalEntityCode', 'LegalEntityName', 'PayrollLocationCode', 'PayrollLocationName', 'WorkAreaCode', 'WorkAreaName', 'IsActive'];
      } else if(OUId.value === 'M22'){
        filteredHeaders = ['WorkAreaCode', 'WorkAreaName', 'WorkAreaStageCode', 'WorkAreaStageName', 'IsActive'];
      } else if(OUId.value === 'M23'){
        filteredHeaders = ['DesignationCode', 'DesignationName', 'SkillCategoryCode', 'SkillCategoryName', 'IsActive'];
      } else if(OUId.value === 'M24'){
        filteredHeaders = ['BusinessCode', 'BusinessName', 'SBUCode', 'SBUName', 'LegalEntityCode', 'LegalEntityName', 'PayrollLocationCode', 'PayrollLocationName', 'DesignationCode', 'DesignationName', 'SkillCategoryCode', 'SkillCategoryName', 'ProficiencyCode', 'ProficiencyName', 'Wage', 'IsActive'];
      } else if(OUId.value === 'M25'){
        filteredHeaders = ['DesignationCode', 'DesignationName', 'SlaDays', 'IsActive'];
      } else if(OUId.value === 'M26'){
        filteredHeaders = ['BusinessCode', 'BusinessName', 'SBUCode', 'SBUName', 'LegalEntityCode', 'LegalEntityName', 'PayrollLocationCode', 'PayrollLocationName', 'EmployeeClassCode', 'EmployeeClassName', 'ApprovedHeadCount', 'PermissiblePercentage', 'RecruitableHeadCount', 'EndDate', 'IsActive'];
      }
      

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Master");

      const headerRow = worksheet.addRow(filteredHeaders.map(header => convertCamelCaseToWords(header)));
      headerRow.font = { bold: true };

      responseData.forEach((row) => {
        const dataRow = [];
        if(OUId?.value !== 'M1' && OUId?.value !== 'M2' && OUId?.value !== 'M3' && OUId?.value !== 'M4' && OUId?.value !== 'M5' &&
          OUId?.value !== 'M6' && OUId?.value !== 'M7' && OUId?.value !== 'M8' && OUId?.value !== 'M9' && OUId?.value !== 'M10' && 
          OUId?.value !== 'M11' && OUId?.value !== 'M12' && OUId?.value !== 'M13' && OUId?.value !== 'M14' && OUId?.value !== 'M15' &&
          OUId?.value !== 'M16' && OUId?.value !== 'M17' && OUId?.value !== 'M18' && OUId?.value !== 'M19' && OUId?.value !== 'M20' && 
          OUId?.value !== 'M21' && OUId?.value !== 'M22' && OUId?.value !== 'M23' && OUId?.value !== 'M24' && OUId?.value !== 'M25' && 
          OUId?.value !== 'M26' 
        ){
          dataRow.push(row['code']);    
          dataRow.push(row['name']);
          dataRow.push(row['isOUActive']);
          dataRow.push(row['isMappingActive']);
        } else {
          
          dataRow.push(row['oU1Code']);
          dataRow.push(row['oU1Name']);

          if (OUId?.value !== 'M25'){
            dataRow.push(row['oU2Code']);
            dataRow.push(row['oU2Name']);
          }

          if(OUId.value === 'M10'){
            dataRow.push(row['oU3Code']);
            dataRow.push(row['oU3Name']);
          } else if(OUId.value === 'M3'){
            dataRow.push(row['oU3Code']);
            dataRow.push(row['oU3Name']);
            dataRow.push(row['oU4Code']);
            dataRow.push(row['oU4Name']);
          } else if(OUId.value === 'M16'){
            dataRow.push(row['oU3Code']);
            dataRow.push(row['oU3Name']);
            dataRow.push(row['oU4Code']);
            dataRow.push(row['oU4Name']);
            dataRow.push(row['oU5Code']);
            dataRow.push(row['oU5Name']);
            dataRow.push(row['noticePeriodDays']);
            dataRow.push(row['noticePeriodInMonths']);
          } else if (OUId.value === 'M17'){
            dataRow.push(row['tanNumber']);
          } else if(OUId.value === 'M18'){
            dataRow.push(row['companyCode']);
          } else if (OUId.value === 'M19'){
            dataRow.push(row['oU3Code']);
            dataRow.push(row['oU3Name']);
            dataRow.push(row['oU4Code']);
            dataRow.push(row['oU4Name']);
            dataRow.push(row['probationPeriodDays']);
          } else if(OUId.value === 'M20'){
            dataRow.push(row['oU3Code']);
            dataRow.push(row['oU3Name']);
            dataRow.push(row['lopRate']);
          } else if(OUId.value === 'M21'){
            dataRow.push(row['oU3Code']);
            dataRow.push(row['oU3Name']);
            dataRow.push(row['oU4Code']);
            dataRow.push(row['oU4Name']);
            dataRow.push(row['oU5Code']);
            dataRow.push(row['oU5Name']);
          } else if(OUId.value === 'M24'){
            dataRow.push(row['oU3Code']);
            dataRow.push(row['oU3Name']);
            dataRow.push(row['oU4Code']);
            dataRow.push(row['oU4Name']);
            dataRow.push(row['oU5Code']);
            dataRow.push(row['oU5Name']);
            dataRow.push(row['oU6Code']);
            dataRow.push(row['oU6Name']);
            dataRow.push(row['oU7Code']);
            dataRow.push(row['oU7Name']);
            dataRow.push(row['wage']);
          } else if (OUId.value === 'M25'){
            dataRow.push(row['sla']);
          } else if (OUId.value === 'M26'){
            dataRow.push(row['oU3Code']);
            dataRow.push(row['oU3Name']);
            dataRow.push(row['oU4Code']);
            dataRow.push(row['oU4Name']);
            dataRow.push(row['oU5Code']);
            dataRow.push(row['oU5Name']);
            dataRow.push(row['approvedheadcount']);
            dataRow.push(row['permissiblepercentage']);
            dataRow.push(row['recruitableheadcount']);
            dataRow.push(row['enddate']);
          }
          dataRow.push(row['isMappingActive']);
        }
        worksheet.addRow(dataRow);
      });

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = `${OUId.label}.xlsx`;
        a.click();

        URL.revokeObjectURL(url);
        setExportToExcel(false);
      });
    } else {
      if (GetDashboardData?.data !== null && GetDashboardData?.data?.length > 0) {
        if(!OUId || OUId?.value === 0){
          setRowData([]);
          setCount(0);  
        } else{
          setCount(GetDashboardData.data[0].count);       
          setRowData(GetDashboardData.data);
        }
      } else {
        setRowData([]);
        setCount(0);
      }
    }
    hideLoader();
  };

  const edit = (value, tableMeta, url) => {
    const Id = tableMeta.rowData[0];
    const ouType = state?.ouType;
    navigate("/OrganizationUnit", { state: { Id ,  MasterId: OUId.value, MasterName:OUId.label, MasterCode: OUId.code, ouType} });
  };

  const view = (value, tableMeta, url) => {
    const Id = tableMeta.rowData[0];
    const ouType = state?.ouType;
    const isViewOu = true;
    navigate("/OrganizationUnit", { state: { Id ,  MasterId: OUId.value, MasterName:OUId.label, MasterCode: OUId.code, ouType, isViewOu} });
  };

  const history = (value, tableMeta, url) => {
    let Id = tableMeta.rowData[0];
    const ouType = OUId.code;
    if(mapVals.includes(ouType)) { 
      Id = tableMeta.rowData[tableMeta.columnIndex-1];
      setCodeForHistory(''); 
    } 
    else { setCodeForHistory(ouType); }
    setIdForHistory(Id);
    setShowHistoryModal(true);
  };

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    page: page,
    rowsPerPage: PageSize,
    serverSide: true,
    onChangePage: async (page) => {
      await setPage(page);
      setStart(page * PageSize);
    },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setDashboardSortColumn(sortColumn);
        await setDashboardSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setDashboardSortColumn(sortColumn);
        await setDashboardSortDirection(sortDirection);
      }
    },
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical"
  };

  const onEditActive = async (tableMeta) => {

    const updateMappingActive = await APICall(InsertUpdateOUMappings, "POST", {
      MappingValues: OUId?.value,
      Id: tableMeta.rowData[0],
      OUCode: tableMeta.rowData[1],
      OU1Code: tableMeta.rowData[3],
      IsActive: tableMeta.rowData[5] === 'Yes' ? 0 : 1
    });

    if(updateMappingActive.message == "Ok"){
      notify(0, 'Data Updated Successfully');
    } else{
      notify(1, "Some error occurred. Please try again");
    }

    getInterfaceDetails();
  }

  let columnsWithCustomRender = '';
  if(OUId?.value !== 'M1' && OUId?.value !== 'M2' && OUId?.value !== 'M3' && OUId?.value !== 'M4' && OUId?.value !== 'M5' &&
    OUId?.value !== 'M6' && OUId?.value !== 'M7' && OUId?.value !== 'M8' && OUId?.value !== 'M9' && OUId?.value !== 'M10' && 
    OUId?.value !== 'M11' && OUId?.value !== 'M12' && OUId?.value !== 'M13' && OUId?.value !== 'M14' && OUId?.value !== 'M15' && 
    OUId?.value !== 'M16' && OUId?.value !== 'M17' && OUId?.value !== 'M18' && OUId?.value !== 'M19' && OUId?.value !== 'M20' && 
    OUId?.value !== 'M21' && OUId?.value !== 'M22' && OUId?.value !== 'M23' && OUId?.value !== 'M24' && OUId?.value !== 'M25' && 
    OUId?.value !== 'M26' 
    ){
      columnsWithCustomRender = gridData?.data?.d?.s[0]?.columnData.map(
        (column) => {
          if (column.name === "action"
          ) {
            return {
              ...column,
              options: {
                ...column.options,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (
                    <>
                      <div className="centerStyles">
                        {isEditPermitted && (
                          <Tooltip title="Edit">
                          <i
                            style={{ marginRight: "7px", cursor: "pointer" }}
                            onClick={() => {
                              edit(value, tableMeta, column.url);
                            }}
                            className="fas fa-edit"></i>
                          </Tooltip>
                        )}
                        {isViewPermitted && (
                          <Tooltip title="View">
                          <i
                            style={{ marginRight: "7px", cursor: "pointer" }}
                            onClick={() => {
                              view(value, tableMeta, column.url);
                            }}
                            className="fas fa-eye"></i>
                          </Tooltip>
                        )}
                        {isHistoryPermitted && (
                          <Tooltip title="History">
                          <i
                            style={{ marginRight: "7px", cursor: "pointer" }}
                            onClick={() => {
                              history(value, tableMeta, column.url);
                            }}
                            className="fas fa-history"></i>
                          </Tooltip>
                        )}
                      </div>
                    </>
                  );
                },
              },
            };
          }
          return column;
        }
      );
  } else if(OUId?.value === 'M1' || OUId?.value === 'M2' || OUId?.value === 'M4' || OUId?.value === 'M5' || 
  OUId?.value === 'M6' || OUId?.value === 'M7' || OUId?.value === 'M8' || OUId?.value === 'M9' ||
  OUId?.value === 'M11' || OUId?.value === 'M12' || OUId?.value === 'M13' || OUId?.value === 'M14' || OUId?.value === 'M15' || 
  OUId?.value === 'M17' || OUId?.value === 'M18' || OUId?.value === 'M20' || OUId?.value === 'M22' || OUId?.value === 'M23'){
    if(gridData === null || gridData === undefined)
    {
      return;
    }
    let actionColumn = {
      "name": "action",
      "label": "Action",
      "options": {
        "filter": true,
        "sort": true,
        "display": true
      }
    }
    if(!gridData?.data?.d.s[1].columnData.some(x => x.name === 'action')){
      gridData?.data?.d.s[1].columnData.push(actionColumn);
    }
    if(OUId?.value === 'M2'){
      gridData.data.d.s[1].columnData[1].label = 'SBU Code';
      gridData.data.d.s[1].columnData[2].label = 'SBU Name';
      gridData.data.d.s[1].columnData[3].label = 'Legal Entity Code';
      gridData.data.d.s[1].columnData[4].label = 'Legal Entity Name';
    } else if (OUId?.value === 'M22'){
      gridData.data.d.s[1].columnData[1].label = 'Work Area Code';
      gridData.data.d.s[1].columnData[2].label = 'Work Area Name';
      gridData.data.d.s[1].columnData[3].label = 'Work Area Stage Code';
      gridData.data.d.s[1].columnData[4].label = 'Work Area Stage Name';
    } else if (OUId?.value === 'M23'){
      gridData.data.d.s[1].columnData[1].label = 'Designation Code';
      gridData.data.d.s[1].columnData[2].label = 'Designation Name';
      gridData.data.d.s[1].columnData[3].label = 'Skill Category Code';
      gridData.data.d.s[1].columnData[4].label = 'Skill Category Name';
    } else if(OUId.value === 'M4'){
      gridData.data.d.s[1].columnData[1].label = 'Legal Entity Code';
      gridData.data.d.s[1].columnData[2].label = 'Legal Entity Name';
      gridData.data.d.s[1].columnData[3].label = 'Division Code';
      gridData.data.d.s[1].columnData[4].label = 'Division Name';
    }  else if(OUId.value === 'M5'){
      gridData.data.d.s[1].columnData[1].label = 'Division Code';
      gridData.data.d.s[1].columnData[2].label = 'Division Name';
      gridData.data.d.s[1].columnData[3].label = 'Function Code';
      gridData.data.d.s[1].columnData[4].label = 'Function Name';
    }  else if(OUId.value === 'M6'){
      gridData.data.d.s[1].columnData[1].label = 'Function Code';
      gridData.data.d.s[1].columnData[2].label = 'Function Name';
      gridData.data.d.s[1].columnData[3].label = 'Department Code';
      gridData.data.d.s[1].columnData[4].label = 'Department Name';
    }  else if(OUId.value === 'M7'){
      gridData.data.d.s[1].columnData[1].label = 'Department Code';
      gridData.data.d.s[1].columnData[2].label = 'Department Name';
      gridData.data.d.s[1].columnData[3].label = 'Section Code';
      gridData.data.d.s[1].columnData[4].label = 'Section Name';
    }  else if(OUId.value === 'M8'){
      gridData.data.d.s[1].columnData[1].label = 'Section Code';
      gridData.data.d.s[1].columnData[2].label = 'Section Name';
      gridData.data.d.s[1].columnData[3].label = 'Sub-Section Code';
      gridData.data.d.s[1].columnData[4].label = 'Sub-Section Name';
    }  else if(OUId.value === 'M9'){
      gridData.data.d.s[1].columnData[1].label = 'Sub-Section Code';
      gridData.data.d.s[1].columnData[2].label = 'Sub-Section Name';
      gridData.data.d.s[1].columnData[3].label = 'Sub Sub-Section Code';
      gridData.data.d.s[1].columnData[4].label = 'Sub Sub-Section Name';
    }  else if(OUId.value === 'M11'){
      gridData.data.d.s[1].columnData[1].label = 'Legal Entity Code';
      gridData.data.d.s[1].columnData[2].label = 'Legal Entity Name';
      gridData.data.d.s[1].columnData[3].label = 'Employee Class Code';
      gridData.data.d.s[1].columnData[4].label = 'Employee Class Name';
    }  else if(OUId.value === 'M12'){
      gridData.data.d.s[1].columnData[1].label = 'Job Band Code';
      gridData.data.d.s[1].columnData[2].label = 'Job Band Name';
      gridData.data.d.s[1].columnData[3].label = 'Job Level Code';
      gridData.data.d.s[1].columnData[4].label = 'Job Level Name';
    } else if(OUId.value === 'M13'){
      gridData.data.d.s[1].columnData[1].label = 'Job Level Code';
      gridData.data.d.s[1].columnData[2].label = 'Job Level Name';
      gridData.data.d.s[1].columnData[3].label = 'Designation Code';
      gridData.data.d.s[1].columnData[4].label = 'Designation Name';
    } else if(OUId.value === 'M14'){
      gridData.data.d.s[1].columnData[1].label = 'Department Code';
      gridData.data.d.s[1].columnData[2].label = 'Department Name';
      gridData.data.d.s[1].columnData[3].label = 'Cost Center Code';
      gridData.data.d.s[1].columnData[4].label = 'Cost Center Name';
    } else if(OUId.value === 'M15'){
      gridData.data.d.s[1].columnData[1].label = 'Legal Entity Code';
      gridData.data.d.s[1].columnData[2].label = 'Legal Entity Name';
      gridData.data.d.s[1].columnData[3].label = 'Payroll Location Code';
      gridData.data.d.s[1].columnData[4].label = 'Payroll Location Name';
    } else if (OUId.value === 'M17' || OUId.value === 'M20'){
      gridData.data.d.s[1].columnData[1].label = 'Legal Entity Code';
      gridData.data.d.s[1].columnData[2].label = 'Legal Entity Name';
      if(OUId.value === 'M17'){
        gridData.data.d.s[1].columnData[3].label = 'Payroll Location Code';
        gridData.data.d.s[1].columnData[4].label = 'Payroll Location Name';
        gridData.data.d.s[1].columnData[5].label = 'TAN Number';
        gridData.data.d.s[1].columnData[5].name = 'tanNumber';
      } else if(OUId.value === 'M20'){
        gridData.data.d.s[1].columnData[3].label = 'Employee Class Code';
        gridData.data.d.s[1].columnData[4].label = 'Employee Class Name';
        gridData.data.d.s[1].columnData[5].name = 'oU3Code';
        gridData.data.d.s[1].columnData[5].label = 'Job Level Code';
        let jobLevelNameColumn = {
          "name": "oU3Name",
          "label": "Job Level Name",
          "options": {
            "filter": true,
            "sort": true,
            "display": true
          }
        }
        if(!gridData.data.d.s[1].columnData.some(x => x.name === 'oU3Name')){
          gridData.data.d.s[1].columnData.push(jobLevelNameColumn);
        }
        let isActiveColumn = {
          "name": "lopRate",
          "label": "LOP Rate",
          "options": {
            "filter": true,
            "sort": true,
            "display": true
          }
        }
        if(!gridData.data.d.s[1].columnData.some(x => x.name === 'lopRate')){
          gridData.data.d.s[1].columnData.push(isActiveColumn);
        }
      }
      
      let isActiveColumn = {
        "name": "isMappingActive",
        "label": "Is Active",
        "options": {
          "filter": true,
          "sort": true,
          "display": true
        }
      }
      if(!gridData.data.d.s[1].columnData.some(x => x.name === 'isMappingActive')){
        gridData.data.d.s[1].columnData.push(isActiveColumn);
      }
      let actionColumn = {
        "name": "action",
        "label": "Action",
        "options": {
          "filter": true,
          "sort": true,
          "display": true
        }
      }
      if(!gridData.data.d.s[1].columnData.some(x => x.name === 'action')){
        gridData.data.d.s[1].columnData.push(actionColumn);
      }
    } else if (OUId.value === 'M18'){
      gridData.data.d.s[1].columnData[1].label = 'SBU Code';
      gridData.data.d.s[1].columnData[2].label = 'SBU Name';
      gridData.data.d.s[1].columnData[3].label = 'Legal Entity Code';
      gridData.data.d.s[1].columnData[4].label = 'Legal Entity Name';
      gridData.data.d.s[1].columnData[5].label = 'Company Code';
      gridData.data.d.s[1].columnData[5].name = 'companyCode';
      let isActiveColumn = {
        "name": "isMappingActive",
        "label": "Is Active",
        "options": {
          "filter": true,
          "sort": true,
          "display": true
        }
      }
      if(!gridData.data.d.s[1].columnData.some(x => x.name === 'isMappingActive')){
        gridData.data.d.s[1].columnData.push(isActiveColumn);
      }
      let actionColumn = {
        "name": "action",
        "label": "Action",
        "options": {
          "filter": true,
          "sort": true,
          "display": true
        }
      }
      if(!gridData.data.d.s[1].columnData.some(x => x.name === 'action')){
        gridData.data.d.s[1].columnData.push(actionColumn);
      }
    }
    if(OUId.value === 'M17' || OUId.value === 'M18' || OUId.value === 'M20'){
      columnsWithCustomRender = gridData?.data?.d?.s[1]?.columnData.map(
        (column) => {
          if (column.name === "action"
            ) {
              return {
                ...column,
                options: {
                  ...column.options,
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      <>
                      <div className="centerStyles">
                        {isEditPermitted && (
                          <Tooltip title="Edit">
                          <i
                            style={{ marginRight: "7px", cursor: "pointer" }}
                            onClick={() => {
                              edit(value, tableMeta, column.url);
                            }}
                            className="fas fa-edit"></i>
                          </Tooltip>
                        )}
                        {isViewPermitted && (
                          <Tooltip title="View">
                          <i
                            style={{ marginRight: "7px", cursor: "pointer" }}
                            onClick={() => {
                              view(value, tableMeta, column.url);
                            }}
                            className="fas fa-eye"></i>
                          </Tooltip>
                        )}
                        {isHistoryPermitted && (
                          <Tooltip title="History">
                          <i
                            style={{ marginRight: "7px", cursor: "pointer" }}
                            onClick={() => {
                              history(value, tableMeta, column.url);
                            }}
                            className="fas fa-history"></i>
                          </Tooltip>
                        )}
                      </div>
                    </>
                    );
                  },
                },
              };
            }
          return column;
        }
      );
    } else{
      columnsWithCustomRender = gridData?.data?.d?.s[1]?.columnData.map(
        (column) => {
          if (column.name === "isMappingActive"
            ) {
              return {
                ...column,
                options: {
                  ...column.options,
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      <>
                        <div>
                          <input
                            disabled={true}
                            type="checkbox"
                            id="IsActive"
                            onChange={() => {
                              // onEditActive(tableMeta);
                            }}
                            checked={ 
                              (OUId.value === 'M17' || OUId.value === 'M18') 
                              ? (tableMeta.rowData[9] === 'Yes' ? true : false) 
                              : tableMeta.rowData[8] === 'Yes' ? true : false
                            }
                            className = "mr-2"
                          /> 
                          <label>
                            {
                              (OUId.value === 'M17' || OUId.value === 'M18') 
                              ? tableMeta.rowData[9] 
                              : tableMeta.rowData[8]
                            }
                          </label>
                        </div>
                      </>
                    );
                  },
                },
              };
            }
          if (column.name === "action"
            ) {
              return {
                ...column,
                options: {
                  ...column.options,
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      <>
                      <div className="centerStyles">
                        {isHistoryPermitted && (
                          <Tooltip title="History">
                          <i
                            style={{ marginRight: "7px", cursor: "pointer" }}
                            onClick={() => {
                              history(value, tableMeta, column.url);
                            }}
                            className="fas fa-history"></i>
                          </Tooltip>
                        )}
                      </div>
                    </>
                    );
                  },
                },
              };
            }
          return column;
        }
      );
    } 
  } else if(OUId?.value === 'M3' || OUId.value === 'M19' || OUId.value === 'M20'){
    if(gridData === null || gridData === undefined)
    {
      return;
    }  
    if(OUId.value === 'M19'){
      gridData.data.d.s[2].columnData[1].label = 'Legal Entity Code';
      gridData.data.d.s[2].columnData[2].label = 'Legal Entity Name';
      gridData.data.d.s[2].columnData[3].label = 'Custom Business Code';
      gridData.data.d.s[2].columnData[4].label = 'Custom Business Name';
      gridData.data.d.s[2].columnData[5].label = 'Job Level Code';
      gridData.data.d.s[2].columnData[6].label = 'Job Level Name';
      gridData.data.d.s[2].columnData[7].label = 'Standard Designation Code';
      gridData.data.d.s[2].columnData[8].label = 'Standard Designation Name';
      gridData.data.d.s[2].columnData[9].label = 'Probation Period Days';
      gridData.data.d.s[2].columnData[9].name = 'probationPeriodDays';
      let isActiveColumn = {
        "name": "isMappingActive",
        "label": "Is Active",
        "options": {
          "filter": true,
          "sort": true,
          "display": true
        }
      }
      if(!gridData.data.d.s[2].columnData.some(x => x.name === 'isMappingActive')){
        gridData.data.d.s[2].columnData.push(isActiveColumn);
      }
    } 
    let actionColumn = {
      "name": "action",
      "label": "Action",
      "options": {
        "filter": true,
        "sort": true,
        "display": true
      }
    }

    if(gridData && !gridData.data.d.s[2].columnData.some(x => x.name === 'action')){
      gridData.data.d.s[2].columnData.push(actionColumn);
    }
    columnsWithCustomRender = gridData?.data?.d?.s[2]?.columnData.map(
      (column) => {
        if (column.name === "action" 
          ) {
            return {
              ...column,
              options: {
                ...column.options,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (
                    <>
                      <div className="centerStyles">
                        {isEditPermitted && (
                          <Tooltip title="Edit">
                          <i
                            style={{ marginRight: "7px", cursor: "pointer" }}
                            onClick={() => {
                              edit(value, tableMeta, column.url);
                            }}
                            className="fas fa-edit"></i>
                          </Tooltip>
                        )}
                        {isViewPermitted && (
                          <Tooltip title="View">
                          <i
                            style={{ marginRight: "7px", cursor: "pointer" }}
                            onClick={() => {
                              view(value, tableMeta, column.url);
                            }}
                            className="fas fa-eye"></i>
                          </Tooltip>
                        )}
                        {isHistoryPermitted && (
                          <Tooltip title="History">
                          <i
                            style={{ marginRight: "7px", cursor: "pointer" }}
                            onClick={() => {
                              history(value, tableMeta, column.url);
                            }}
                            className="fas fa-history"></i>
                          </Tooltip>
                        )}
                      </div>
                    </>
                  );
                },
              },
            };
          }
        return column;
      }
    );
  } else if (OUId?.value === 'M10'){
    columnsWithCustomRender = gridData?.data?.d?.s[3]?.columnData.map(
      (column) => {
        if (column.name === "action"
          ) {
            return {
              ...column,
              options: {
                ...column.options,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (
                    <>
                      <div className="centerStyles">
                        {isEditPermitted && (
                          <Tooltip title="Edit">
                          <i
                            style={{ marginRight: "7px", cursor: "pointer" }}
                            onClick={() => {
                              edit(value, tableMeta, column.url);
                            }}
                            className="fas fa-edit"></i>
                          </Tooltip>
                        )}
                        {isViewPermitted && (
                          <Tooltip title="View">
                          <i
                            style={{ marginRight: "7px", cursor: "pointer" }}
                            onClick={() => {
                              view(value, tableMeta, column.url);
                            }}
                            className="fas fa-eye"></i>
                          </Tooltip>
                        )}
                        {isHistoryPermitted && (
                          <Tooltip title="History">
                          <i
                            style={{ marginRight: "7px", cursor: "pointer" }}
                            onClick={() => {
                              history(value, tableMeta, column.url);
                            }}
                            className="fas fa-history"></i>
                          </Tooltip>
                        )}
                      </div>
                    </>
                  );
                },
              },
            };
          }
        return column;
      }
    );
  } else if (OUId?.value === 'M16'){
    columnsWithCustomRender = gridData?.data?.d?.s[4]?.columnData.map(
      (column) => {
        if (column.name === "action"
          ) {
            return {
              ...column,
              options: {
                ...column.options,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (
                    <>
                      <div className="centerStyles">
                        {isEditPermitted && (
                          <Tooltip title="Edit">
                          <i
                            style={{ marginRight: "7px", cursor: "pointer" }}
                            onClick={() => {
                              edit(value, tableMeta, column.url);
                            }}
                            className="fas fa-edit"></i>
                          </Tooltip>
                        )}
                        {isViewPermitted && (
                          <Tooltip title="View">
                          <i
                            style={{ marginRight: "7px", cursor: "pointer" }}
                            onClick={() => {
                              view(value, tableMeta, column.url);
                            }}
                            className="fas fa-eye"></i>
                          </Tooltip>
                        )}
                        {isHistoryPermitted && (
                          <Tooltip title="History">
                          <i
                            style={{ marginRight: "7px", cursor: "pointer" }}
                            onClick={() => {
                              history(value, tableMeta, column.url);
                            }}
                            className="fas fa-history"></i>
                          </Tooltip>
                        )}
                      </div>
                    </>
                  );
                },
              },
            };
          }
        return column;
      }
    );
  } else if (OUId?.value === 'M21'){
    columnsWithCustomRender = gridData?.data?.d?.s[5]?.columnData.map(
      (column) => {
        if (column.name === "action"
          ) {
            return {
              ...column,
              options: {
                ...column.options,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (
                    <>
                      <div className="centerStyles">
                        {isEditPermitted && (
                          <Tooltip title="Edit">
                          <i
                            style={{ marginRight: "7px", cursor: "pointer" }}
                            onClick={() => {
                              edit(value, tableMeta, column.url);
                            }}
                            className="fas fa-edit"></i>
                          </Tooltip>
                        )}
                        {isViewPermitted && (
                          <Tooltip title="View">
                          <i
                            style={{ marginRight: "7px", cursor: "pointer" }}
                            onClick={() => {
                              view(value, tableMeta, column.url);
                            }}
                            className="fas fa-eye"></i>
                          </Tooltip>
                        )}
                        {isHistoryPermitted && (
                          <Tooltip title="History">
                          <i
                            style={{ marginRight: "7px", cursor: "pointer" }}
                            onClick={() => {
                              history(value, tableMeta, column.url);
                            }}
                            className="fas fa-history"></i>
                          </Tooltip>
                        )}
                      </div>
                    </>
                  );
                },
              },
            };
          }
        return column;
      }
    );
  } else if (OUId?.value === 'M24'){
    columnsWithCustomRender = gridData?.data?.d?.s[6]?.columnData.map(
      (column) => {
        if (column.name === "action"
          ) {
            return {
              ...column,
              options: {
                ...column.options,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (
                    <>
                      <div className="centerStyles">
                        {isEditPermitted && (
                          <Tooltip title="Edit">
                          <i
                            style={{ marginRight: "7px", cursor: "pointer" }}
                            onClick={() => {
                              edit(value, tableMeta, column.url);
                            }}
                            className="fas fa-edit"></i>
                          </Tooltip>
                        )}
                        {isViewPermitted && (
                          <Tooltip title="View">
                          <i
                            style={{ marginRight: "7px", cursor: "pointer" }}
                            onClick={() => {
                              view(value, tableMeta, column.url);
                            }}
                            className="fas fa-eye"></i>
                          </Tooltip>
                        )}
                        {isHistoryPermitted && (
                          <Tooltip title="History">
                          <i
                            style={{ marginRight: "7px", cursor: "pointer" }}
                            onClick={() => {
                              history(value, tableMeta, column.url);
                            }}
                            className="fas fa-history"></i>
                          </Tooltip>
                        )}
                      </div>
                    </>
                  );
                },
              },
            };
          }
        return column;
      }
    );
  } else if (OUId?.value === 'M25'){
    columnsWithCustomRender = gridData?.data?.d?.s[7]?.columnData.map(
      (column) => {
        if (column.name === "action"
          ) {
            return {
              ...column,
              options: {
                ...column.options,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (
                    <>
                      <div className="centerStyles">
                        {isEditPermitted && (
                          <Tooltip title="Edit">
                          <i
                            style={{ marginRight: "7px", cursor: "pointer" }}
                            onClick={() => {
                              edit(value, tableMeta, column.url);
                            }}
                            className="fas fa-edit"></i>
                          </Tooltip>
                        )}
                        {isViewPermitted && (
                          <Tooltip title="View">
                          <i
                            style={{ marginRight: "7px", cursor: "pointer" }}
                            onClick={() => {
                              view(value, tableMeta, column.url);
                            }}
                            className="fas fa-eye"></i>
                          </Tooltip>
                        )}
                        {isHistoryPermitted && (
                          <Tooltip title="History">
                          <i
                            style={{ marginRight: "7px", cursor: "pointer" }}
                            onClick={() => {
                              history(value, tableMeta, column.url);
                            }}
                            className="fas fa-history"></i>
                          </Tooltip>
                        )}
                      </div>
                    </>
                  );
                },
              },
            };
          }
        return column;
    });
  } else if (OUId?.value === 'M26'){
    columnsWithCustomRender = gridData?.data?.d?.s[8]?.columnData.map(
      (column) => {
        if (column.name === "action"
          ) {
            return {
              ...column,
              options: {
                ...column.options,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (
                    <>
                      <div className="centerStyles">
                        {isEditPermitted && (
                          <Tooltip title="Edit">
                          <i
                            style={{ marginRight: "7px", cursor: "pointer" }}
                            onClick={() => {
                              edit(value, tableMeta, column.url);
                            }}
                            className="fas fa-edit"></i>
                          </Tooltip>
                        )}
                        {isViewPermitted && (
                          <Tooltip title="View">
                          <i
                            style={{ marginRight: "7px", cursor: "pointer" }}
                            onClick={() => {
                              view(value, tableMeta, column.url);
                            }}
                            className="fas fa-eye"></i>
                          </Tooltip>
                        )}
                        {isHistoryPermitted && (
                          <Tooltip title="History">
                          <i
                            style={{ marginRight: "7px", cursor: "pointer" }}
                            onClick={() => {
                              history(value, tableMeta, column.url);
                            }}
                            className="fas fa-history"></i>
                          </Tooltip>
                        )}
                      </div>
                    </>
                  );
                },
              },
            };
          }
        return column;
    });
  }
  

  const onFilterChange = (event, type) => {
    if (type === "IsOUActive") {
        setOUIsActive(event);
      } else if(type === 'OUTypes'){
        setSelectedOUId(event);
        navigate(`/organizationUnitDashboard`, { state: { ouType: event } });
      }
      setPage(0);
  };

  const onSearchFilter = (event, type) => {
    if (type === "Search By Name / Code") {
      setSearchText(event.target.value);
    }
  };
  const onSubmitFilter = () => {
    if (!OUId) {
      notify(1, "Select organization Unit Type filter");
    } else {
      if (gridData?.data?.d?.s[0]?.columnData.length > 0) {
        getGridStructureTwo();
      }
    }
  };

  const onClickReset = () => {
    setSearchText("");
    setOUIsActive([]);
    setSelectedOUId([]);
    setStart(0);
    setReset(!reset);
    setDashboardSortColumn("");
    setDashboardSortDirection("desc");
  };

  const exportToExcel = async () => {
    if(!OUId || OUId?.value === 0){
      notify(1, 'Please select organization unit type');
    } else{
      setExportToExcel(true);
    }
  }
  const addNewOU = async () => {
    if(OUId && OUId.value){
      const ouType = state?.ouType;    
      navigate("/OrganizationUnit", { state: { Id: 0,  MasterId: OUId.value, MasterName:OUId.label, MasterCode: OUId.code, ouType} });
    } else{
      notify(1, 'Please select Organization Unit');
    }
  }

  return (
    <div className="container-fluid">
      <div className="d-flex align-items-center justify-content-end my-3">
        <button
          onClick={() => navigate("/home")}
          className="btn btn-primary btn-filter-submit ml-2 mr-2">
          <i className="fa-solid fa-arrow-left mr-1"></i> Back
        </button>
        <button
          className="btn btn-primary btn-filter-submit mr-2"
          onClick={()=>{
            navigate("/organizationUnitBulkUpload",{});
          }}>
            Bulk Upload 
        </button>
        {
          isEditPermitted && showAddNew && (
            <>
              <button
                disabled = {isEditPermitted ? false : true}
                onClick={addNewOU}
                className="btn btn-primary mr-2">
                <i className="fa fa-plus mr-2"></i>
                Add New
              </button>
            </>
          )
        }
        <button
          className="btn btn-primary"
          onClick={exportToExcel}>
          Export to excel
        </button>
      </div>

      <div className="grid-wrapper grid_mob">
        <div className="row mx-auto filtBox">
          {gridData &&
            gridData?.data?.d?.fa.map((cFilter, index) => (
              <React.Fragment key={index}>
                {cFilter.filt === "multidropdown" ? (
                  <>
                    <span
                      className="mr-2 text-white"
                      style={{ float: "left" }}>
                      <label className="col-form-label">
                        {cFilter.filn}
                      </label>
                    </span>
                    <div className="col-lg-3 col-md-6 col-sm-12 ">
                      <div className="form-group filtInp">
                        <SelectForm
                          isClearable
                          options={ddValue[cFilter.filAN]}
                          placeholder={cFilter.filn}
                          isDisabled={false}
                          onChange={(event) => {
                            onFilterChange(event, cFilter.filAN);
                            setStart(0);
                          }}
                          value={
                              cFilter.filAN === "OUTypes"
                              ? OUId
                              : null
                          }
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                        />
                      </div>
                    </div>
                  </>
                ) : cFilter.filt === "text" ? (
                  <div className="col-lg-3 col-md-6 col-sm-12 ">
                    <div className="form-group filtInp">
                      <InputForm
                        value={cFilter.filn === "Search By Name / Code" ? searchText : ""}
                        placeholder={cFilter.filn}
                        isDisabled={false}
                        textArea={false}
                        onChange={(e) => {
                          onSearchFilter(e, cFilter.filn);
                          setStart(0);
                        }}
                      />
                    </div>
                  </div>
                ) : null}
              </React.Fragment>
            ))}
          <div className="col-lg-1 col-md-1 col-sm-12 filtrt">
            <ul className=" filter-icon-wel">
              <li>
                <a
                  onClick={() => setOpen(!open)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                  className="filter-btn">
                  <i className="fa fa-filter"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="col-lg-12 col-md-10 col-sm-12 "></div>

      <>
        <Collapse in={open}>
          <div id="example-collapse-text grid-wrapper">
            <div className="row mx-auto myInlineStyle">
              {gridData &&
                gridData?.data?.d?.afa?.map((cFilter, index) => (
                  <>
                    {cFilter.filt === "multidropdown" ? (
                      <>
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                          <div className="form-group filtInp"> 
                              <label className="col-form-label text-white">
                                {cFilter.filn}
                              </label> 
                            <SelectForm
                              isClearable
                              options={ddValue[cFilter.filAN]}
                              placeholder={cFilter.filn}
                              isDisabled={false}
                              onChange={(event) => {
                                onFilterChange(event, cFilter.filAN);
                              }}
                              isMulti={true}
                              noIndicator={false}
                              noSeparator={false}
                              value={
                                cFilter.filAN === "IsOUActive"
                              ? OUIsActive : null
                              }
                            />
                          </div>
                        </div>
                      </>
                    ) : cFilter.filt === "text" ? (
                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                        <div className="form-group filtInp">
                          <InputForm
                            value={""}
                            placeholder={cFilter.filn}
                            isDisabled={false}
                            textArea={false}
                            onChange={(e) => {
                              onSearchFilter(e, cFilter.filn);
                            }}
                          />
                        </div>
                      </div>
                    ) : null}
                  </>
                ))}
              <div className="col-lg-12 d-flex mt-2 justify-content-end">
                <button
                  className="btn btn-secondary mr-2"
                  onClick={onClickReset}>
                  <i className="fa fa-undo" aria-hidden="true"></i> Reset
                </button>
                <button
                  className="btn btn-filter-submit"
                  onClick={onSubmitFilter}>
                  <i className="fa fa-check" aria-hidden="true"></i> Submit{" "}
                </button>
              </div>
            </div>
          </div>
        </Collapse>
      </>

      <div className="mb-3 pt-3">
        <DynamicGrid
          data={rowData}
          columns={columnsWithCustomRender}
          options={options}
        />
      </div>

      {/* Show Modal Pop-up For History */}
      {
        idForHistory != 0 &&
        showHistoryModal &&
        <Modal
          show={showHistoryModal}
          onHide={() => {
            setShowHistoryModal(false);
            setIdForHistory(0);
            setCodeForHistory("");
          }}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            History!
          </Modal.Header>
          <Modal.Body>
            <ViewHistoryOu OuId = {idForHistory} OuCode = {codeForHistory}/>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn customButtonCancel"
              style={{ marginLeft: 5 }}
              onClick={() => {
                setShowHistoryModal(false);
                setIdForHistory(0);
                setCodeForHistory("");
              }}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      }
    </div>
  );
};

export default OrganizationUnitDashboard;
