import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import './DocumentModal.css'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

const DocumentModal = ({
  documentUrl,
  onClose,
  title,
  onDownload,
  showDownloadOption,
}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <Modal
      show={true}
      id={"alpha"}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      size="xl"
      fullscreen={true}
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showDownloadOption && (
          <>
            <Document file={documentUrl} onLoadSuccess={onDocumentLoadSuccess}>
              {/* <Page pageNumber={pageNumber} />
            
            <p>
              Page {pageNumber} of {numPages}
            </p> */}
            {Array.from(new Array(numPages), (el, index) => (
            <Page
              scale={1.5}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
            />
            
            ))}
          </Document>
          </>
        )}
        {!showDownloadOption && (
          // <>
          //   <iframe
          //     title="Document Viewer"
          //     src={`data:application/pdf;base64,${documentUrl}`}
          //     width="100%"
          //     height="400rem"
          //   />
          // </>
          <>
          <Document file={`data:application/pdf;base64,${documentUrl}`} onLoadSuccess={onDocumentLoadSuccess}>
            {/* <Page pageNumber={pageNumber} />
          
          <p>
            Page {pageNumber} of {numPages}
          </p> */}
          {Array.from(new Array(numPages), (el, index) => (
          <Page
            scale={1.5}
            key={`page_${index + 1}`}
            pageNumber={index + 1}
          />
          
          ))}
        </Document>
        </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>Close</Button>
        <Button hidden={!showDownloadOption} onClick={onDownload}>
          Download
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DocumentModal;
