import React, { useContext, useEffect, useState, useRef } from "react";
// import { apiResp, cityData, stateData } from "./formData";
import ButtonForm from "../../Components/ButtonForm/ButtonForm";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tabs,
  Tooltip,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { ExpandMore } from "@mui/icons-material";
import SelectForm from "../../Components/SelectForm/SelectForm";
import InputForm from "../../Components/InputForm/InputForm";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import uuid from "react-uuid";
import DateForm from "../../Components/DateForm/DateForm";
import moment from "moment";
import "./Agency.css";
import profileImg from "../../Assets/Images/profile.jpeg";
import { APICall } from "../../Helpers/APICalls";
import { LoaderContext } from "../../Helpers/Context/Context";
import {
  getAttributeValueDetailsV2,
  getDropdowns,
  getSectionDetails,
  getSectionDetailsV2,
  getTabsData,
  getTabHeaderDetails,
  postAttributeValueDetailsV2,
  getDocuments,
  viewDocument,
  downloadDocument,
  deleteDocument,
  documentUpload,
} from "../../Helpers/APIEndPoints/EndPoints";
import notify from "../../Helpers/ToastNotification";
import { useLocation, useNavigate } from "react-router-dom";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { post } from "jquery";

const SectionComponent = ({
  TId,
  setTId,
  formData,
  setFormData,
  section: ogSection,
  sectionValuesArray,
  sectionIndex,
}) => {
  const [hasValue, setHasValue] = useState(
    sectionValuesArray.filter((es) => es.sectionSystemName == ogSection.SN) ||
      null
  );
  const [section, setSection] = useState(ogSection);
  const [accordion, setAccordion] = useState(sectionIndex === 0 ? true : false);
  const [formErrors, setFormErrors] = useState<any>({});
  const [optionsObj, setOptionsObj] = useState<any>({});
  const [gridData, setGridData] = useState([]);
  const [displayGrid, setDisplayGrid] = useState([]);
  const [gridColumns, setGridColumns] = useState([]);
  const [ogAttributeSet, setOgAttributeSet] = useState({});
  const [ogFilledAttributeSet, setOgFilledAttributeSet] = useState([]);
  const [currentGridId, setCurrentGridId] = useState(null);
  const [deleteGridId, setDeleteGridId] = useState(null);
  const [ShowAttrSet, setShowAttrSet] = useState(false);
  const [viewOnly, setViewOnly] = useState(false);

  const navigate = useNavigate();

  const { userDetails, currentRoleId, proxyUserDetails }: any =
    useUserContext();

  // value Object for section
  const [attributesData, setAttributesData] = useState<any>({});

  const [attributeSetData, setAttributeSetData] = useState<any>({});

  const [attributeSetCode, setAttributeSetCode] = useState(null);
  const [isOnce, setIsOnce] = useState(true);
  const [isValidateOnce, setIsValidateOnce] = useState(true);

  const [attributeValidation, setAttributeValidation] = useState<any>({});
  const [attributeSetValidation, setAttributeSetValidation] = useState<any>({});

  const [dataFilled, setDataFilled] = useState(false);

  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [sectionHasDocuments, setSectionHasDocuments] = useState(false);
  let FlatDocRef = useRef(null);
  let SetDocRef = useRef(null);
  const getDropdownsData = async (AC, PID) => {
    await showLoader();
    const dropdownData = await APICall(getDropdowns, "POST", {
      AC,
      PID,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    if (
      dropdownData.data !== null &&
      dropdownData.data !== undefined &&
      dropdownData.data.length > 0
    ) {
      await hideLoader();
      return dropdownData.data;
    } else {
      await hideLoader();
      return [];
    }
  };

  const [fileDemo, setFileDemo] = useState(null);
  const [selectedFilesForFlat, setSelectedFilesForFlat] = useState<
    {
      AC: string;
      Name: string;
      Data: string;
      IsValidFile: boolean;
      DocId: Number;
      Type: string;
      Context: string;
      DCVALUE: string;
      DVTILL: string;
      DSTATUS: string;
      DPSINCE: string;
      DPWITH: string;
    }[]
  >([]);

  const [selectedFilesForSet, setSelectedFilesForSet] = useState<
    {
      AC: string;
      Id: string | number;
      Name: string;
      Data: string;
      IsValidFile: boolean;
      DocId: Number;
      Type: string;
      Context: string;
      DCVALUE: string;
      DVTILL: string;
      DSTATUS: string;
      DPSINCE: string;
      DPWITH: string;
    }[]
  >([]);

  const fileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileValidation = (files, attribute) => {
    const { FS, FX, FC } = JSON.parse(attribute.V);
    // Split the validExtensionsString into an array
    const validExtensions = FX.split(",");

    let isValid = [];

    // validate file count
    if (files.length > FC) {
      notify(1, `Only ${FC} files allowed!`);
      return false;
    }
    // check extension of each file
    files.forEach((file) => {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();

      // Check if the file extension is allowed
      if (!validExtensions.includes("." + fileExtension)) {
        isValid.push(false);
      } else {
        isValid.push(true); //valid
      }
    });

    let returnValue = isValid.some((v) => v === false);

    if (returnValue) {
      notify(1, `Please upload file within this ${FX} extentsion`);
      return false;
    }

    files.forEach((file) => {
      const fileSize = file.size;
      const maxFileSizeBytes = FS * 1024 * 1024;
      if (fileSize <= maxFileSizeBytes) {
        isValid.push(true); //valid
      } else {
        isValid.push(false);
      }
    });

    returnValue = isValid.some((v) => v === false);

    if (returnValue) {
      notify(1, `File size exceeds ${FS}MB. Please select a smaller file.`);
      return false;
    } else {
      return true;
    }
  };

  const handleFileChangeForFlat = async (e, attribute) => {
    const { FS, FX, FC } = JSON.parse(attribute.V);

    const files = Array.prototype.slice.call(e.target.files);

    const isValid = handleFileValidation(files, attribute);
    if (isValid) {
      if (files && files.length > 0) {
        const base64Array: { Name: string; Data: string }[] = [];
        for (let i = 0; i < files.length; i++) {
          const base64 = (await fileToBase64(files[i])) as string;
          const base64WithoutPrefix = base64.split(",")[1];
          base64Array.push({ Name: files[i].name, Data: base64WithoutPrefix });
        }

        //check if AC present and file count matched

        const fileCount = selectedFilesForFlat.reduce((total, item) => {
          if (item.AC === attribute.AC) {
            return total + 1;
          }
          return total;
        }, 0);

        if (fileCount == FC) {
          notify(1, `Only ${FC} files allowed!`);
        } else {
          let type =
            JSON.parse(attribute.AD).CON === "National ID Information"
              ? attributeSetData["IDCATY"]?.label
              : JSON.parse(attribute.AD).TYP
              ? JSON.parse(attribute.AD).TYP
              : "";
          base64Array.forEach((file) => {
            setSelectedFilesForFlat((prevSelectedFiles) => [
              ...prevSelectedFiles,
              {
                AC: attribute.AC,
                Name: file.Name,
                Data: file.Data,
                IsValidFile: false,
                DocId: 0,
                Type: type,
                Context: JSON.parse(attribute.AD).CON
                  ? JSON.parse(attribute.AD).CON
                  : "",
                DCVALUE: file.Name,
                DVTILL: "",
                DSTATUS: "Verified",
                DPSINCE: "",
                DPWITH: "",
              },
            ]);
          });
        }
      }
    } else {
      e.target.value = null;
    }
  };

  const handleFileChangeForSet = async (e, subAttribute) => {
    const { FS, FX, FC } = JSON.parse(subAttribute.V);

    const files = Array.prototype.slice.call(e.target.files);

    const isValid = handleFileValidation(files, subAttribute);
    if (isValid) {
      if (files && files.length > 0) {
        const base64Array: { Name: string; Data: string }[] = [];
        for (let i = 0; i < files.length; i++) {
          const base64 = (await fileToBase64(files[i])) as string;
          const base64WithoutPrefix = base64.split(",")[1];
          base64Array.push({ Name: files[i].name, Data: base64WithoutPrefix });
        }

        //check if AC present and file count matched

        const fileCount = selectedFilesForSet.reduce((total, item) => {
          if (
            item.AC === subAttribute.AC &&
            ((currentGridId && currentGridId == item.Id) || item.Id == 0)
          ) {
            return total + 1;
          }
          return total;
        }, 0);

        if (fileCount == FC) {
          notify(1, `Only ${FC} files allowed!`);
        } else {
          let type =
            JSON.parse(subAttribute.AD).CON === "National ID Information"
              ? attributeSetData["IDCATY"]?.label
              : JSON.parse(subAttribute.AD).TYP
              ? JSON.parse(subAttribute.AD).TYP
              : "";
          base64Array.forEach((file) => {
            setSelectedFilesForSet((prevSelectedFiles) => [
              ...prevSelectedFiles,
              {
                AC: subAttribute.AC,
                Name: file.Name,
                Data: file.Data,
                IsValidFile: false,
                Id: currentGridId ?? 0,
                DocId: 0,
                Type: type,
                Context: JSON.parse(subAttribute.AD).CON
                  ? JSON.parse(subAttribute.AD).CON
                  : "",
                DCVALUE:
                  JSON.parse(subAttribute.AD).CON === "National ID Information"
                    ? attributeSetData["PERIDNM"]
                    : file.Name,
                DVTILL:
                  JSON.parse(subAttribute.AD).CON === "National ID Information"
                    ? attributeSetData["NVALTIL"]
                    : "",
                DSTATUS: "Verified",
                DPSINCE: "",
                DPWITH: "",
              },
            ]);
          });
        }
      }
    } else {
      e.target.value = null;
    }
  };

  const handleViewForFlat = async (fileAttribute, fileIndex) => {
    showLoader();
    if (
      fileAttribute.DocId != undefined &&
      fileAttribute.DocId != null &&
      fileAttribute.DocId != 0
    ) {
      const doc = await APICall(viewDocument, "POST", {
        TId,
        AC: fileAttribute.AC,
        DocId: fileAttribute.DocId,
      });

      if (doc && doc.data !== null) {
        //window.open(doc.data,'_blank');
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = doc.data;
        link.click();
      }
      hideLoader();
    } else {
      notify(1, "File Not Available");
    }
  };

  const handleDownloadFileFlat = async (fileAttribute, fileIndex) => {
    showLoader();
    if (
      fileAttribute.DocId != undefined &&
      fileAttribute.DocId != null &&
      fileAttribute.DocId != 0
    ) {
      const doc = await APICall(downloadDocument, "POST", {
        TId,
        AC: fileAttribute.AC,
        DocId: fileAttribute.DocId,
      });
      if (doc && doc.data !== null) {
        downloadBase64File(doc.data, fileAttribute.Name);
        hideLoader();
      } else {
        notify(1, "Something Went Wrong.");
        hideLoader();
      }
    } else {
      downloadBase64File(fileAttribute.Data, fileAttribute.Name);
      hideLoader();
    }
  };
  function downloadBase64File(base64String: string, fileName: string) {
    const mimeType = inferMimeType(base64String);
    const blob = base64toBlob(base64String, mimeType);

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  }
  function inferMimeType(base64String: string): string {
    const dataUrl = `data:application/octet-stream;base64,${base64String}`;
    const typeInfo = /^data:(.*?);/.exec(dataUrl);

    if (typeInfo && typeInfo[1]) {
      return typeInfo[1];
    }

    return "application/octet-stream";
  }
  function base64toBlob(base64String: string, mimeType: string): Blob {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], { type: mimeType });
  }
  const handleDeleteFileForFlat = async (fileAttribute, fileIndex) => {
    if (
      fileAttribute.DocId != undefined &&
      fileAttribute.DocId != null &&
      fileAttribute.DocId != 0
    ) {
      showLoader();
      const doc = await APICall(deleteDocument, "POST", {
        TId,
        AC: fileAttribute.AC,
        DocId: fileAttribute.DocId,
      });
      if (doc.message === "Success") {
        FlatDocRef.current.value = null;
        let remainingDocs = selectedFilesForFlat.filter(function (obj) {
          return obj.DocId !== fileAttribute.DocId;
        });
        setSelectedFilesForFlat(remainingDocs);
        hideLoader();
      } else {
        notify(1, "File not Deleted, Something Went Wrong.");
        hideLoader();
      }
    } else {
      FlatDocRef.current.value = null;
      setSelectedFilesForFlat((prevSelectedFiles) => {
        return prevSelectedFiles.filter(
          (item, attIndex) => fileIndex !== attIndex
        );
      });
    }
  };

  const handleDeleteFileForSet = async (fileAttribute, fileIndex) => {
    if (
      fileAttribute.DocId != undefined &&
      fileAttribute.DocId != null &&
      fileAttribute.DocId != 0
    ) {
      showLoader();
      const doc = await APICall(deleteDocument, "POST", {
        TId,
        AC: fileAttribute.AC,
        DocId: fileAttribute.DocId,
      });
      if (doc.message === "Success") {
        SetDocRef.current.value = null;
        let remainingDocs = selectedFilesForSet.filter(function (obj) {
          return obj.DocId !== fileAttribute.DocId;
        });
        setSelectedFilesForSet(remainingDocs);
        hideLoader();
      } else {
        notify(1, "File not Deleted, Something Went Wrong.");
        hideLoader();
      }
    } else {
      SetDocRef.current.value = null;
      setSelectedFilesForSet((prevSelectedFiles) => {
        return prevSelectedFiles.filter(
          (item, attIndex) => fileIndex !== attIndex
        );
      });
    }
  };
  const [newGridIdCreated, setNewGridIdCreated] = useState(null);

  useEffect(() => {
    if (selectedFilesForSet.length > 0) {
      setSelectedFilesForSet((prevSelectedFiles) => {
        // Create a copy of the previous state with the updated Id values
        const updatedSelectedFiles = prevSelectedFiles.map((item, index) => {
          if (item.Id === undefined || item.Id === 0) {
            // If Id is undefined or 0, assign a new value
            return { ...item, Id: newGridIdCreated };
          } else {
            return item; // Keep existing Id values
          }
        });

        return updatedSelectedFiles;
      });
    }
  }, [newGridIdCreated]);

  useEffect(() => {
    //add attribute in attributesData

    let attributes = {};
    let attributeSet = {};
    const gridColumnsArr = [];
    let dropdownOptionsObj = {};
    const sectionHasDocuments = [];

    section.Attribute?.forEach(async (eachAttribute) => {
      if (eachAttribute.AT === "Attribute") {
        //change value according to dt

        setAttributesData((prev) => ({
          ...prev,
          [eachAttribute.AC]:
            eachAttribute.DT === "DropdownSingle" || eachAttribute.DT === "Date"
              ? null
              : eachAttribute.DT === "Text" || eachAttribute.DT === "Number"
              ? ""
              : eachAttribute.DT === "Checkbox"
              ? false
              : "",
        }));

        attributes = {
          ...attributes,
          [eachAttribute.AC]:
            eachAttribute.DT === "DropdownSingle" || eachAttribute.DT === "Date"
              ? null
              : eachAttribute.DT === "Text" || eachAttribute.DT === "Number"
              ? ""
              : eachAttribute.DT === "Checkbox"
              ? false
              : "",
        };

        //for document
        if (eachAttribute.DT === "Document") {
          setSectionHasDocuments(true);
          sectionHasDocuments.push(eachAttribute.AC);
        }

        //attributeValidation
        setAttributeValidation((prev) => ({
          ...prev,
          [eachAttribute.AC]: JSON.parse(eachAttribute.V),
        }));

        setAttributeValidation((prev) => ({
          ...prev,
          [eachAttribute.AC]: {
            ...prev[eachAttribute.AC],
            DD: eachAttribute.DD ? JSON.parse(eachAttribute.DD) : null,
            isDisable: false,
          },
        }));

        if (eachAttribute.DT === "DropdownSingle") {
          // api call for each dropdown option

          // const options = await getDropdownsData(eachAttribute.AC, null);

          dropdownOptionsObj = {
            ...dropdownOptionsObj,
            [eachAttribute.AC]: eachAttribute.OPT,
          };

          setOptionsObj((prev) => ({
            ...prev,
            [eachAttribute.AC]: eachAttribute.OPT,
          }));
        }
      } else if (eachAttribute.AT === "AttributeSet") {
        setAttributeSetCode(eachAttribute.AC);

        // Id for grid
        gridColumnsArr.push({
          name: "Id",
          label: "Id",
          options: { display: false },
        });

        eachAttribute.Attribute.forEach(async (subAttribute) => {
          //for document
          if (subAttribute.DT === "Document") {
            setSectionHasDocuments(true);
            sectionHasDocuments.push(subAttribute.AC);
          }

          let display = JSON.parse(subAttribute.AD);
          display = display?.ISOG?.toLowerCase() === "true" ? true : false;

          //grid columns
          gridColumnsArr.push({
            name: subAttribute.AC,
            label: subAttribute.AN,
            options: { sort: false, display },
          });

          //attributeSetValidation
          setAttributeSetValidation((prev) => ({
            ...prev,
            [subAttribute.AC]: JSON.parse(subAttribute.V),
          }));

          //change value according to dt

          setAttributeSetData((prev) => ({
            ...prev,
            [subAttribute.AC]:
              subAttribute.DT === "DropdownSingle" || subAttribute.DT === "Date"
                ? null
                : subAttribute.DT === "Text" || subAttribute.DT === "Number"
                ? ""
                : subAttribute.DT === "Checkbox"
                ? false
                : "",
          }));

          attributeSet = {
            ...attributeSet,
            [subAttribute.AC]:
              subAttribute.DT === "DropdownSingle" || subAttribute.DT === "Date"
                ? null
                : subAttribute.DT === "Text" || subAttribute.DT === "Number"
                ? ""
                : subAttribute.DT === "Checkbox"
                ? false
                : "",
          };

          if (subAttribute.DT === "DropdownSingle") {
            // const options = await getDropdownsData(subAttribute.AC, null);

            dropdownOptionsObj = {
              ...dropdownOptionsObj,
              [subAttribute.AC]: subAttribute.OPT,
            };

            setOptionsObj((prev) => ({
              ...prev,
              [subAttribute.AC]: subAttribute.OPT,
            }));
          }
        });
      }
    });

    // setAttributesData((prev) => ({
    //   ...prev,
    //   ...attributes,
    // }));

    // setAttributeSetData((prev) => ({
    //   ...prev,
    //   ...attributeSet,
    // }));

    setOgAttributeSet(attributeSet);

    setGridColumns((prev) => [...prev, ...gridColumnsArr, action]);

    // setOptionsObj((prev) => ({ ...prev, ...dropdownOptionsObj }));

    const fillData = async () => {
      await showLoader();
      if (isOnce && TId !== 0) {
        //fetch attributes/set data

        //check if not first time then call api
        let attributesRes = null;

        if (hasValue.length > 0 && hasValue[0].attributes.length > 0) {
          attributesRes = hasValue[0].attributes;
          // handle data

          if (attributesRes !== null && attributesRes.length > 0) {
            let newAttributeData: any = { ...attributes };
            if (sectionHasDocuments.length > 0) {
              // getDocuments for Flat
              for (const AC of sectionHasDocuments) {
                if (newAttributeData.hasOwnProperty(AC)) {
                  await showLoader();
                  const getDocument = await APICall(getDocuments, "POST", {
                    TId,
                    AC: AC,
                  });

                  if (getDocument && getDocument.data !== null) {
                    setSelectedFilesForFlat(getDocument.data.files);
                  } else {
                    setSelectedFilesForFlat([]);
                  }
                  await hideLoader();
                }
              }
            }
            attributesRes.forEach(async (eachAttributeRes) => {
              if (eachAttributeRes.type === "Attribute") {
                //Attributes

                if (
                  Object.keys(attributes).length > 0 &&
                  eachAttributeRes.details === null
                ) {
                  let newAttributeData = { ...attributes };
                  let dropdownOptions = { ...dropdownOptionsObj };

                  Object.keys(newAttributeData).forEach(async (attribute) => {
                    if (
                      Object.keys(dropdownOptions).find(
                        (option) =>
                          option.toLowerCase() === attribute.toLowerCase()
                      )
                    ) {
                      const valueForDropdown = attributesRes.find(
                        (attri) => attri.key === attribute
                      ).value;

                      const options = dropdownOptions[attribute];

                      if (options !== undefined && options.length > 0) {
                        const option = options.find(
                          (attri) => attri.value == valueForDropdown
                        );

                        newAttributeData = {
                          ...newAttributeData,
                          [attribute]: option === undefined ? null : option,
                        };
                      }
                    } else {
                      let valueForField = attributesRes?.find(
                        (attri) => attri.key === attribute
                      )?.value;

                      //check type later when actual data

                      valueForField =
                        typeof valueForField === "string" &&
                        valueForField.toLowerCase() === "true"
                          ? true
                          : typeof valueForField === "string" &&
                            valueForField.toLowerCase() === "false"
                          ? false
                          : valueForField !== null
                          ? valueForField?.toString()
                          : valueForField;

                      // format date

                      const dateString = valueForField;
                      const allowedFormat = [
                        "DD-MM-YYYY hh.mm.ss A",
                        "DD/MM/YYYY hh.mm.ss A",
                      ];

                      const isValidDate = moment(
                        dateString,
                        allowedFormat,
                        true
                      ).isValid();
                      if (
                        isValidDate &&
                        dateString !== "01-01-0001 12.00.00 AM" &&
                        dateString !== "1/1/0001 12:00:00 AM"
                      ) {
                        const parsedDate = moment(
                          valueForField,
                          allowedFormat[0]
                        );
                        const formattedDate = parsedDate.format("DD-MMM-YYYY");
                        valueForField = formattedDate;
                      } else if (
                        dateString === "01-01-0001 12.00.00 AM" ||
                        dateString === "1/1/0001 12:00:00 AM"
                      ) {
                        valueForField = null;
                      }

                      newAttributeData = {
                        ...newAttributeData,
                        [attribute]: valueForField,
                      };
                    }
                  });

                  setAttributesData(newAttributeData);
                }
                // setIsOnce(false);
              } else if (eachAttributeRes.type === "AttributeSet") {
                //AttributesSet

                if (
                  Object.keys(attributeSet).length > 0 &&
                  eachAttributeRes.details !== null &&
                  eachAttributeRes.details.length > 0
                ) {
                  setAttributeSetCode(eachAttributeRes.key);

                  const details = eachAttributeRes.details;
                  if (sectionHasDocuments.length > 0) {
                    // getDocuments for Set
                    for (const AC of sectionHasDocuments) {
                      if (attributeSet.hasOwnProperty(AC)) {
                        await showLoader();
                        const getDocument = await APICall(
                          getDocuments,
                          "POST",
                          {
                            TId,
                            AC: AC,
                          }
                        );

                        if (getDocument && getDocument.data !== null) {
                          setSelectedFilesForSet(getDocument.data.files);
                        } else {
                          setSelectedFilesForSet([]);
                        }
                        await hideLoader();
                      }
                    }
                  }
                  if (details.length > 0) {
                    let newAttributeSetData = { ...attributeSet };
                    let newAttributeSetDataForGrid = { ...attributeSet };
                    let dropdownOptions = { ...dropdownOptionsObj };

                    const filledData = [];
                    const gridData = [];
                    details.forEach(async (detail) => {
                      detail.IsActive = true;
                      Object.entries(detail).forEach(async (attribute) => {
                        const [Code, Value]: any = attribute;

                        if (
                          Object.keys(dropdownOptions).find(
                            (option) =>
                              option.toLowerCase() === Code.toLowerCase()
                          )
                        ) {
                          const options = dropdownOptions[Code];
                          if (options !== undefined && options.length > 0) {
                            const option = options.find(
                              (attri) => attri.value == Value
                            );

                            newAttributeSetDataForGrid = {
                              ...newAttributeSetDataForGrid,
                              [Code]:
                                option === undefined ? null : option?.label,
                            };

                            newAttributeSetData = {
                              ...newAttributeSetData,
                              [Code]: option === undefined ? null : option,
                            };
                          }
                        } else {
                          //check type later when actual data

                          let valueForGrid =
                            typeof Value === "string" &&
                            Value.toLowerCase() === "true"
                              ? "true"
                              : typeof Value === "string" &&
                                Value.toLowerCase() === "false"
                              ? "false"
                              : Value !== null
                              ? Value.toString()
                              : Value;

                          // format date

                          const dateString = Value;
                          const allowedFormat = [
                            "DD-MM-YYYY hh.mm.ss A",
                            "DD/MM/YYYY hh.mm.ss A",
                          ];

                          const isValidDate = moment(
                            dateString,
                            allowedFormat,
                            true
                          ).isValid();
                          if (
                            isValidDate &&
                            dateString !== "01-01-0001 12.00.00 AM" &&
                            dateString !== "1/1/0001 12:00:00 AM"
                          ) {
                            const parsedDate = moment(Value, allowedFormat[0]);
                            const formattedDate =
                              parsedDate.format("DD-MMM-YYYY");
                            valueForGrid = formattedDate;
                          } else if (
                            dateString === "01-01-0001 12.00.00 AM" ||
                            dateString === "1/1/0001 12:00:00 AM"
                          ) {
                            valueForGrid = null;
                          }

                          let valueForField =
                            typeof Value === "string" &&
                            Value.toLowerCase() === "true"
                              ? true
                              : typeof Value === "string" &&
                                Value.toLowerCase() === "false"
                              ? false
                              : typeof Value === "boolean"
                              ? Value
                              : Value !== null
                              ? Value.toString()
                              : Value;

                          if (
                            isValidDate &&
                            dateString !== "01-01-0001 12.00.00 AM" &&
                            dateString !== "1/1/0001 12:00:00 AM"
                          ) {
                            const parsedDate = moment(Value, allowedFormat[0]);
                            const formattedDate =
                              parsedDate.format("DD-MMM-YYYY");
                            valueForField = formattedDate;
                          } else if (
                            dateString === "01-01-0001 12.00.00 AM" ||
                            dateString === "1/1/0001 12:00:00 AM"
                          ) {
                            valueForField = null;
                          }

                          newAttributeSetDataForGrid = {
                            ...newAttributeSetDataForGrid,
                            [Code]: valueForGrid,
                          };

                          newAttributeSetData = {
                            ...newAttributeSetData,
                            [Code]: valueForField,
                          };
                        }
                      });
                      gridData.push(newAttributeSetDataForGrid);
                      filledData.push(newAttributeSetData);
                    });

                    setDisplayGrid(gridData);
                    setGridData(gridData);
                    setOgFilledAttributeSet(filledData);
                  }
                  // setIsOnce(false);
                }
              }
            });
          }
        }
        setIsOnce(false);
      }
      await hideLoader();
    };

    const fillTimer = setTimeout(() => {
      clearTimeout(fillTimer);
      fillData();
    }, 2000);

    //save in state
    return () => {
      clearTimeout(fillTimer);
    };
  }, []);

  useEffect(() => {
    if (
      attributesData !== undefined &&
      Object.keys(attributesData).length > 0 &&
      attributeValidation !== undefined &&
      Object.keys(attributeValidation).length > 0
    ) {
      if (
        (isValidateOnce && isOnce === false && TId !== 0) ||
        (isValidateOnce && isOnce === true && TId === 0)
      ) {
        let attributeValues = { ...attributesData }; // for checking values
        let attributeValObj = { ...attributeValidation };
        Object.keys(attributeValObj).forEach(async (attribute) => {
          if (
            attributeValObj[attribute].DD !== null &&
            attributeValObj[attribute].DD.length > 0
          ) {
            let ad = attributeValObj[attribute].DD;

            ad.forEach(async (record) => {
              //   CCode: "POLENDT",
              // PValue: "false",
              // Condition: "equalTo",
              // Flag: "disable",
              // PDT: "checkbox",
              // CDT: "checkbox",
              let CCode = record.CCode;
              let PValue = record.PValue;
              let Condition = record.Condition;
              let Flag = record.Flag;
              let PDT = record.PDT;
              let CDT = record.CDT;

              if (
                attributeValues[attribute].toString().toLowerCase() ===
                  PValue.toLowerCase() ||
                attributeValues[attribute].toString().toLowerCase() === ""
              ) {
                if (Flag === "disable") {
                  attributeValObj[CCode].isDisable = true;
                  attributeValObj[CCode].ISM = "false";
                }
              } else if (
                attributeValues[attribute].toString().toLowerCase() !==
                PValue.toLowerCase()
              ) {
                if (Flag === "disable") {
                  attributeValObj[CCode].isDisable = false;
                  attributeValObj[CCode].ISM = "true";
                }
              }
            });
          }
        });
        setAttributeValidation((prev) => ({ ...prev, ...attributeValObj }));
        setIsValidateOnce(false);
      }
    }
  }, [attributesData, isOnce]);

  const action = {
    name: "Id",
    label: "Action",
    options: {
      filter: false,
      sort: false,
      display: true,
      setCellProps: () => ({
        style: { textAlign: "center" },
      }),
      setCellHeaderProps: () => ({
        style: { textAlign: "center" },
      }),
      customBodyRender: (value, tableMeta) => {
        let Id = tableMeta.tableData[tableMeta.rowIndex].Id;

        return (
          <div className="d-flex justify-content-center">
            <Tooltip title="edit">
              <a
                className="mr-2"
                onClick={(e) => {
                  e.preventDefault();
                  setFormErrors({});
                  setCurrentGridId(Id);
                  setViewOnly(false);
                  setShowAttrSet(true);
                }}
              >
                <i className="fas fa-edit"></i>
              </a>
            </Tooltip>
            <Tooltip title="delete">
              <a
                className="mr-2"
                onClick={(e) => {
                  e.preventDefault();
                  setFormErrors({});
                  setDeleteGridId(Id);
                  setViewOnly(true);
                }}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </a>
            </Tooltip>
          </div>
        );
      },
    },
  };

  useEffect(() => {
    if (currentGridId) {
      let attributeSetData = ogFilledAttributeSet.find(
        (record) => record.Id === currentGridId
      );

      setAttributeSetData(attributeSetData);
    }
  }, [currentGridId]);

  useEffect(() => {
    if (deleteGridId && currentGridId === null) {
      //to disable grid delete button when edit is active
      setGridData((prev) => {
        const newState = prev.map((record) => {
          if (record.Id === deleteGridId) {
            return {
              ...record,
              IsActive: false,
            };
          }
          return record;
        });
        return newState;
      });

      setOgFilledAttributeSet((prev) => {
        const newState = prev.map((record) => {
          if (record.Id === deleteGridId) {
            return {
              ...record,
              IsActive: false,
            };
          }
          return record;
        });
        return newState;
      });

      setDisplayGrid((prev) =>
        prev.filter((record) => record.Id !== deleteGridId)
      );
      //delete documents for that grid record
      let fileToDelete = selectedFilesForSet.filter((sf) => {
        return sf.Id === deleteGridId;
      });
      if (fileToDelete.length > 0) {
        handleDeleteFileForSet(fileToDelete[0], 0);
      }
    }
    setDeleteGridId(null);
  }, [deleteGridId]);

  const gridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    // count: gridData.length,
    rowsPerPage: gridData.length,
    // page: page,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",

    //standard | vertical | simple
    onChangeRowsPerPage: (num) => {
      //   setLimit(num);
      //   setNxtPgInfo("");
      //   setPrevPgInfo("");
      //   setIsPrevOrNext("");
    },
    // onSearchChange: (searchText) => {
    //   if (searchText !== null) {
    //     setSearchText(searchText);
    //   } else {
    //     setSearchText("");
    //   }
    // },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        // await setDashboardSortColumn(sortColumn);
        // await setDashboardSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        // await setDashboardSortColumn(sortColumn);
        // await setDashboardSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      // await setPage(page);
      // await setDashboardStart(page * dashboardPageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const dependentValidations = (targetValue, targetAttributeCode) => {
    let attributeValues = { ...attributesData }; // for checking values
    let attributeValObj = { ...attributeValidation };

    if (
      attributeValObj[targetAttributeCode].DD !== null &&
      attributeValObj[targetAttributeCode].DD.length > 0
    ) {
      let ad = attributeValObj[targetAttributeCode].DD;

      ad.forEach(async (record) => {
        let CCode = record.CCode;
        let PValue = record.PValue;
        let Condition = record.Condition;
        let Flag = record.Flag;
        let PDT = record.PDT;
        let CDT = record.CDT;

        if (targetValue.toString()?.toLowerCase() === PValue?.toLowerCase()) {
          //check condition
          if (Flag === "disable") {
            attributeValObj[CCode].isDisable = true;
            attributeValObj[CCode].ISM = "false";
          }
        } else {
          attributeValObj[CCode].isDisable = false;
          attributeValObj[CCode].ISM = "true";
        }

        //clear values based to CDT
        attributeValues[CCode] =
          CDT === "DropdownSingle" || CDT === "Date"
            ? null
            : CDT === "Text" || CDT === "Number"
            ? ""
            : CDT === "Checkbox"
            ? false
            : "";
      });

      attributeValues[targetAttributeCode] = targetValue; //imp
      setFormErrors({});
      setAttributeValidation((prev) => ({ ...prev, ...attributeValObj }));
      setAttributesData((prev) => ({
        ...prev,
        ...attributeValues,
      }));
    }
  };

  const validateData = (Attribute) => {
    let errorObj = {};
    let isError = false;
    Attribute.forEach(async (subAttribute) => {
      if (subAttribute.AT === "Attribute") {
        const validation = JSON.parse(subAttribute.V);

        if (validation?.ISM?.toLowerCase() === "true") {
          if (subAttribute.DT === "Text") {
            if (attributeSetData[subAttribute.AC] === "") {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else if (
              attributeSetData[subAttribute.AC]?.length > validation.MaxC
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: `Max ${validation.MaxC} characters allowed`,
              };
            } else if (subAttribute.AC === "CPEMID") {
              if (
                !/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/.test(
                  attributeSetData[subAttribute.AC]
                )
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: `${subAttribute.AN} is not valid`,
                };
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          } else if (subAttribute.DT === "DropdownSingle") {
            if (attributeSetData[subAttribute.AC] === null) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          } else if (subAttribute.DT === "Date") {
            if (attributeSetData[subAttribute.AC] === null) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else if (
              moment(
                validation.MaxV === "CurrentDate" &&
                  attributeSetData[subAttribute.AC]
              ).isAfter(new Date())
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Only till current date allowed",
              };
            } else if (
              moment(
                validation.MinV === "CurrentDate" &&
                  attributeSetData[subAttribute.AC]
              ).isBefore()
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Only after current date allowed",
              };
            } else if (
              validation.MinV !== undefined &&
              validation.MinV !== "CurrentDate" &&
              validation.MinV !== ""
            ) {
              let parentValue = attributeSetData[validation.MinV];
              if (
                parentValue !== null &&
                parentValue !== "" &&
                attributeSetData[subAttribute.AC] !== null &&
                attributeSetData[subAttribute.AC] !== "" &&
                moment(attributeSetData[subAttribute.AC]).isBefore(parentValue)
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Past date not allowed",
                };
              } else if (
                (parentValue === null || parentValue === "") &&
                attributeSetData[subAttribute.AC] !== null &&
                attributeSetData[subAttribute.AC] !== ""
              ) {
                errorObj = {
                  ...errorObj,
                  [validation.MinV]: "Required",
                };
              }
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          } else if (subAttribute.DT === "Number") {
            //change with regex
            if (attributeSetData[subAttribute.AC] === "") {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else if (
              // isNaN(
              //   attributeSetData[subAttribute.AC]
              // )
              !/^([0-9]+|0)$/.test(attributeSetData[subAttribute.AC])
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Only numbers are allowed",
              };
            } else if (
              Number(attributeSetData[subAttribute.AC]) > validation.MaxV
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: `Max ${validation.MaxV} allowed`,
              };
            } else if (
              Number(attributeSetData[subAttribute.AC]) < validation.MinV
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: `Min ${validation.MinV} required`,
              };
            } else if (
              !/^([6-9]\d{9})$/.test(attributeSetData[subAttribute.AC])
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: `${subAttribute.AN} is not valid`,
              };
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          }
        } else {
          // for num and date

          if (subAttribute.DT === "Number") {
            if (attributeSetData[subAttribute.AC] !== "") {
              //change with regex
              if (
                // isNaN(
                //   attributeSetData[
                //     subAttribute.AC
                //   ]
                // )
                !/^([0-9]+|0)$/.test(attributeSetData[subAttribute.AC])
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Only numbers are allowed",
                };
              } else if (
                Number(attributeSetData[subAttribute.AC]) > validation.MaxV
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: `Max ${validation.MaxV} allowed`,
                };
              } else if (
                Number(attributeSetData[subAttribute.AC]) < validation.MinV
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: `Min ${validation.MinV} required`,
                };
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }
            }
          } else if (subAttribute.DT === "Date") {
            if (attributeSetData[subAttribute.AC] !== null) {
              if (
                moment(
                  validation.MaxV === "CurrentDate" &&
                    attributeSetData[subAttribute.AC]
                ).isAfter(new Date())
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Only till current date allowed",
                };
              } else if (
                moment(
                  validation.MinV === "CurrentDate" &&
                    attributeSetData[subAttribute.AC]
                ).isBefore()
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Only after current date allowed",
                };
              } else if (
                validation.MinV !== undefined &&
                validation.MinV !== "CurrentDate" &&
                validation.MinV !== ""
              ) {
                let parentValue = attributeSetData[validation.MinV];
                if (
                  parentValue !== null &&
                  parentValue !== "" &&
                  attributeSetData[subAttribute.AC] !== null &&
                  attributeSetData[subAttribute.AC] !== "" &&
                  moment(attributeSetData[subAttribute.AC]).isBefore(
                    parentValue
                  )
                ) {
                  errorObj = {
                    ...errorObj,
                    [subAttribute.AC]: "Past date not allowed",
                  };
                } else if (
                  (parentValue === null || parentValue === "") &&
                  attributeSetData[subAttribute.AC] !== null &&
                  attributeSetData[subAttribute.AC] !== ""
                ) {
                  errorObj = {
                    ...errorObj,
                    [validation.MinV]: "Required",
                  };
                }
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }
            }
          }
        }
      }
    });

    const isEmpty = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  return (
    <div className="col-lg-12">
      {section?.Attribute?.length > 0 ? (
        <Accordion
          elevation={0}
          className="mb-3"
          expanded={accordion}
          onChange={() => setAccordion((prev) => !prev)}
        >
          <AccordionSummary
            id="profile-accordion"
            style={{ background: "#3C5464" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}
          >
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <p style={{ fontSize: "16px" }}>{section.SDN}</p>
              </div>
              <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12">
                <Tooltip title="clear">
                  <button
                    className="btn ml-lg-0 ml-md-0 ml-1"
                    onClick={(e) => {
                      e.stopPropagation();
                      //clear data

                      section.Attribute.forEach(async (eachAttribute) => {
                        if (eachAttribute.AT === "Attribute") {
                          //change value according to dt

                          setAttributesData((prev) => ({
                            ...prev,
                            [eachAttribute.AC]:
                              eachAttribute.DT === "DropdownSingle" ||
                              eachAttribute.DT === "Date"
                                ? null
                                : eachAttribute.DT === "Text" ||
                                  eachAttribute.DT === "Number"
                                ? ""
                                : eachAttribute.DT === "Checkbox"
                                ? false
                                : "",
                          }));
                        } else if (eachAttribute.AT === "AttributeSet") {
                          setAttributeSetData(ogAttributeSet);
                        }
                      });

                      //clear error
                      setFormErrors({});
                    }}
                  >
                    <i className="fas fa-times-circle"></i>
                  </button>
                </Tooltip>
                <Tooltip title="submit">
                  <button
                    className="btn"
                    disabled={currentGridId !== null ? true : false}
                    onClick={async (e) => {
                      e.stopPropagation();

                      if (TId === 0 && section.SN !== "AgencyDetails") {
                        notify(1, "Save Agency Details Data First");
                      } else {
                        let errorObj = {};
                        section.Attribute.map((eachAttribute) => {
                          if (eachAttribute.AT === "Attribute") {
                            const validation =
                              attributeValidation[eachAttribute.AC];

                            if (validation?.ISM?.toLowerCase() === "true") {
                              if (eachAttribute.DT === "Text") {
                                if (attributesData[eachAttribute.AC] === "") {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "Required",
                                  };
                                } else if (
                                  attributesData[eachAttribute.AC]?.length >
                                  validation.MaxC
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: `Max ${validation.MaxC} characters allowed`,
                                  };
                                } else {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "",
                                  };
                                }
                              } else if (
                                eachAttribute.DT === "DropdownSingle"
                              ) {
                                if (attributesData[eachAttribute.AC] === null) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "Required",
                                  };
                                } else {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "",
                                  };
                                }
                              } else if (eachAttribute.DT === "Date") {
                                if (attributesData[eachAttribute.AC] === null) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "Required",
                                  };
                                } else if (
                                  moment(
                                    validation.MaxV === "CurrentDate" &&
                                      attributesData[eachAttribute.AC]
                                  ).isAfter(new Date())
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]:
                                      "Only till current date allowed",
                                  };
                                } else if (
                                  moment(
                                    validation.MinV === "CurrentDate" &&
                                      attributesData[eachAttribute.AC]
                                  ).isBefore()
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]:
                                      "Only after current date allowed",
                                  };
                                } else if (
                                  validation.MinV !== undefined &&
                                  validation.MinV !== "CurrentDate" &&
                                  validation.MinV !== ""
                                ) {
                                  let parentValue =
                                    attributesData[validation.MinV];
                                  if (
                                    parentValue !== null &&
                                    parentValue !== "" &&
                                    attributesData[eachAttribute.AC] !== null &&
                                    attributesData[eachAttribute.AC] !== "" &&
                                    moment(
                                      attributesData[eachAttribute.AC]
                                    ).isBefore(parentValue)
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]:
                                        "Past date not allowed",
                                    };
                                  } else if (
                                    (parentValue === null ||
                                      parentValue === "") &&
                                    attributesData[eachAttribute.AC] !== null &&
                                    attributesData[eachAttribute.AC] !== ""
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [validation.MinV]: "Required",
                                    };
                                  }
                                } else {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "",
                                  };
                                }
                              } else if (eachAttribute.DT === "Number") {
                                //change with regex

                                if (attributesData[eachAttribute.AC] === "") {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "Required",
                                  };
                                } else if (
                                  // isNaN(attributesData[eachAttribute.AC])
                                  !/^([0-9]+|0)$/.test(
                                    attributesData[eachAttribute.AC]
                                  )
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]:
                                      "Only numbers are allowed",
                                  };
                                } else if (
                                  Number(attributesData[eachAttribute.AC]) >
                                  validation.MaxV
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: `Max ${validation.MaxV} allowed`,
                                  };
                                } else if (
                                  Number(attributesData[eachAttribute.AC]) <
                                  validation.MinV
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: `Min ${validation.MinV} required`,
                                  };
                                } else if (eachAttribute.AC === "AGCYCON") {
                                  if (
                                    !/^([1-9][0-9]{5})$/.test(
                                      attributesData[eachAttribute.AC]
                                    )
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]:
                                        "Postal code not started from 0",
                                    };
                                  } else {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "",
                                    };
                                  }
                                } else {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "",
                                  };
                                }
                              }
                            } else {
                              // for num and date

                              if (eachAttribute.DT === "Number") {
                                if (attributesData[eachAttribute.AC] !== "") {
                                  //change with regex
                                  if (
                                    // isNaN(attributesData[eachAttribute.AC])
                                    !/^([0-9]+|0)$/.test(
                                      attributesData[eachAttribute.AC]
                                    )
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]:
                                        "Only numbers are allowed",
                                    };
                                  } else if (
                                    Number(attributesData[eachAttribute.AC]) >
                                    validation.MaxV
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: `Max ${validation.MaxV} allowed`,
                                    };
                                  } else if (
                                    Number(attributesData[eachAttribute.AC]) <
                                    validation.MinV
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: `Min ${validation.MinV} required`,
                                    };
                                  } else {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "",
                                    };
                                  }
                                }
                              } else if (eachAttribute.DT === "Date") {
                                if (attributesData[eachAttribute.AC] !== null) {
                                  if (
                                    moment(
                                      validation.MaxV === "CurrentDate" &&
                                        attributesData[eachAttribute.AC]
                                    ).isAfter(new Date())
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]:
                                        "Only till current date allowed",
                                    };
                                  } else if (
                                    moment(
                                      validation.MinV === "CurrentDate" &&
                                        attributesData[eachAttribute.AC]
                                    ).isBefore()
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]:
                                        "Only after current date allowed",
                                    };
                                  } else if (
                                    validation.MinV !== undefined &&
                                    validation.MinV !== "CurrentDate" &&
                                    validation.MinV !== ""
                                  ) {
                                    let parentValue =
                                      attributesData[validation.MinV];
                                    if (
                                      parentValue !== null &&
                                      parentValue !== "" &&
                                      attributesData[eachAttribute.AC] !==
                                        null &&
                                      attributesData[eachAttribute.AC] !== "" &&
                                      moment(
                                        attributesData[eachAttribute.AC]
                                      ).isBefore(parentValue)
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]:
                                          "Past date not allowed",
                                      };
                                    } else if (
                                      (parentValue === null ||
                                        parentValue === "") &&
                                      attributesData[eachAttribute.AC] !==
                                        null &&
                                      attributesData[eachAttribute.AC] !== ""
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [validation.MinV]: "Required",
                                      };
                                    }
                                  } else {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "",
                                    };
                                  }
                                }
                              }
                            }
                          } else {
                            //submit attributeSet
                          }
                        });

                        if (
                          attributesData["AGCYCEF"] !== null &&
                          attributesData["AGCYCET"] !== null
                        ) {
                          if (
                            moment(attributesData["AGCYCEF"]).isAfter(
                              moment(attributesData["AGCYCET"])
                            )
                          ) {
                            errorObj = {
                              ...errorObj,
                              ["AGCYCEF"]:
                                "Contract Effective From should be less than Contract Effective Till",
                            };
                          }
                        }

                        const isEmpty = Object.values(errorObj).every(
                          (s) => s === ""
                        );

                        if (isEmpty && attributeSetCode === null) {
                          await showLoader();
                          // post data attribute
                          const postAttributes = [];

                          Object.entries(attributesData).forEach(
                            async (attributeData) => {
                              const [Code, Value]: any[] = attributeData;

                              let attribute = {
                                Type: "Attribute",
                                Code,
                                Value:
                                  typeof Value === "object"
                                    ? Value?.value.toString() || null
                                    : typeof Value === "string" ||
                                      typeof Value === "boolean"
                                    ? Value
                                    : null,
                              };
                              postAttributes.push(attribute);
                            }
                          );

                          let postObj = {
                            MN: "Agency",
                            IN: "Agency_Form",
                            TN: formData.tn,
                            SN: section.SN,
                            TId: TId || 0,
                            UserId: userDetails.Id,
                            UserEmailId: userDetails.EmailId,
                            UserName: userDetails.FirstName,
                            RoleId: currentRoleId?.value,
                            Attributes: postAttributes,
                          };

                          const postRes = await APICall(
                            postAttributeValueDetailsV2,
                            "POST",
                            postObj
                          );

                          if (
                            postRes.data !== null &&
                            section.SN === "AgencyDetails" &&
                            postRes.data.id !== undefined
                          ) {
                            setTId(postRes.data.id);
                            navigate("/agency", {
                              state: { TId: postRes.data.id },
                            });
                          }
                          if (
                            selectedFilesForFlat.length > 0 &&
                            sectionHasDocuments
                          ) {
                            let newFiles = selectedFilesForFlat.filter((sf) => {
                              return sf.DocId === 0;
                            });
                            try {
                              const response = await APICall(
                                documentUpload,
                                "POST",
                                {
                                  ModuleName: "Agency",
                                  files: newFiles,
                                  SectionName: "AgencyDetails",
                                  TabName: "Agency",
                                  TId: postRes.data?.id ? postRes.data.id : TId,
                                  ModuleId: 4,
                                  UserId: userDetails.UserId,
                                  ProxyUserId:
                                    proxyUserDetails === null
                                      ? null
                                      : proxyUserDetails?.value,
                                }
                              );

                              if (
                                response.data === null ||
                                response.data === undefined
                              ) {
                                notify(1, "Failed to upload documents.");
                              } else {
                                setSelectedFilesForFlat([
                                  ...selectedFilesForFlat,
                                  response.data.files,
                                ]);
                              }
                            } catch (error) {
                              throw new Error(
                                "Error uploading documents: " + error.message
                              );
                            }
                          }

                          await hideLoader();
                          notify(postRes.status, postRes.message);
                        } else if (isEmpty && attributeSetCode !== null) {
                          await showLoader();
                          // post data attributeSet

                          let details = [...ogFilledAttributeSet];

                          const finalDetails = [];
                          details.forEach(async (attributes) => {
                            let attributeObj = {};
                            Object.keys(attributes).forEach(
                              async (attribute) => {
                                attributeObj = {
                                  ...attributeObj,
                                  [attribute]:
                                    typeof attributes[attribute] === "object"
                                      ? attributes[
                                          attribute
                                        ]?.value.toString() || null
                                      : typeof attributes[attribute] ===
                                          "string" ||
                                        typeof attributes[attribute] ===
                                          "boolean"
                                      ? attributes[attribute]
                                      : null,
                                };

                                if (
                                  attribute === "Id" &&
                                  isNaN(Number(attributes[attribute]))
                                ) {
                                  attributeObj = {
                                    ...attributeObj,
                                    [attribute]: 0,
                                  };
                                } else if (
                                  attribute === "Id" &&
                                  !isNaN(Number(attributes[attribute]))
                                ) {
                                  attributeObj = {
                                    ...attributeObj,
                                    [attribute]: attributes[attribute],
                                  };
                                }
                              }
                            );
                            finalDetails.push(attributeObj);
                          });

                          let postObj = {
                            MN: "Agency",
                            IN: "Agency_Form",
                            TN: formData.tn,
                            SN: section.SN,
                            TId: TId || 0,
                            Attributes: [
                              {
                                Code: attributeSetCode,
                                Value: "",
                                Type: "AttributeSet",
                                Details: finalDetails,
                              },
                            ],
                          };

                          const postRes = await APICall(
                            postAttributeValueDetailsV2,
                            "POST",
                            postObj
                          );

                          if (
                            postRes?.status == 0 &&
                            postRes?.data?.length > 0
                          ) {
                            setOgFilledAttributeSet((prevOgFilled) => {
                              return prevOgFilled.map((og, rowIndex) => ({
                                ...og,
                                Id:
                                  postRes.data === null
                                    ? 0
                                    : postRes.data[rowIndex],
                              }));
                            });

                            let trackIds = [];

                            setGridData((prevGrid) => {
                              return prevGrid.map((og, rowIndex) => {
                                trackIds = [
                                  ...trackIds,
                                  {
                                    prevId: og.Id,
                                    newId:
                                      postRes.data === null
                                        ? 0
                                        : postRes.data[rowIndex],
                                  },
                                ];

                                return {
                                  ...og,
                                  Id:
                                    postRes.data === null
                                      ? 0
                                      : postRes.data[rowIndex],
                                };
                              });
                            });

                            setDisplayGrid((prevDisplay) => {
                              return prevDisplay.map((og, rowIndex) => {
                                const matchedPrevId = trackIds.find(
                                  (track) => track.prevId == og.Id
                                );
                                return {
                                  ...og,
                                  Id: matchedPrevId.newId,
                                };
                              });
                            });
                          }

                          if (
                            selectedFilesForSet.length > 0 &&
                            sectionHasDocuments
                          ) {
                            try {
                              if (postRes.data?.length > 0) {
                                selectedFilesForSet.forEach((f, index) => {
                                  f.Id = postRes.data[index];
                                });
                              }
                              let newFiles = selectedFilesForSet.filter(
                                (sf) => {
                                  return sf.DocId === 0;
                                }
                              );
                              const response = await APICall(
                                documentUpload,
                                "POST",
                                {
                                  ModuleName: "Agency",
                                  files: newFiles,
                                  SectionName: "AgencyDetails",
                                  TabName: "Agency",
                                  TId: postRes.data?.id ? postRes.data.id : TId,
                                  ModuleId: 4,
                                  UserId: userDetails.UserId,
                                  ProxyUserId:
                                    proxyUserDetails === null
                                      ? null
                                      : proxyUserDetails?.value,
                                }
                              );

                              if (
                                response.data === null ||
                                response.data === undefined
                              ) {
                                notify(1, "Failed to upload documents.");
                              } else {
                                let docFromApi = response.data.files;
                                setSelectedFilesForSet((prevFiles) => {
                                  return prevFiles.map((doc) => {
                                    docFromApi = docFromApi.filter(
                                      (file) => file.Id === doc.Id
                                    );
                                    if (doc.Id === docFromApi.Id) {
                                      return {
                                        ...doc,
                                        Data: docFromApi.Data,
                                        DocId: docFromApi.DocId,
                                      };
                                    } else {
                                      return doc;
                                    }
                                  });
                                });
                              }
                            } catch (error) {
                              throw new Error(
                                "Error uploading documents: " + error.message
                              );
                            }
                          }

                          await hideLoader();
                          notify(postRes.status, postRes.message);
                        }

                        setFormErrors((err) => ({
                          ...err,
                          ...errorObj,
                        }));
                      }
                    }}
                  >
                    <i className="fa fa-save"></i>
                  </button>
                </Tooltip>
              </div>
            </div>
          </AccordionSummary>

          <AccordionDetails className="page_heading">
            <div className="row">
              {section.Attribute.length > 0 &&
                section.Attribute.map((eachAttribute, index) => (
                  <React.Fragment key={`${index}-${eachAttribute.AC}`}>
                    {eachAttribute.AT === "Attribute" ? (
                      <>
                        {eachAttribute.DT === "DropdownSingle" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                )?.IFR?.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }
                            >
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM?.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <SelectForm
                                  isClearable
                                  isSearchable
                                  options={
                                    optionsObj[eachAttribute.AC]
                                      ? optionsObj[eachAttribute.AC]
                                      : []
                                  }
                                  placeholder={eachAttribute.AN}
                                  isDisabled={
                                    attributeValidation[eachAttribute.AC]
                                      ?.isDisable
                                  }
                                  value={
                                    attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : null
                                  }
                                  onChange={(event) => {
                                    setAttributesData((prev) => ({
                                      ...prev,
                                      [eachAttribute.AC]: event,
                                    }));

                                    if (
                                      event !== null &&
                                      eachAttribute.CC !== null &&
                                      eachAttribute.CC !== ""
                                    ) {
                                      const CC = eachAttribute.CC.split(",");

                                      CC.forEach(async (childDropdown) => {
                                        setAttributesData((prev) => ({
                                          ...prev,
                                          [childDropdown]: null,
                                        }));

                                        const options = await getDropdownsData(
                                          childDropdown,
                                          event.value
                                        );

                                        setOptionsObj((prev) => ({
                                          ...prev,
                                          [childDropdown]: options,
                                        }));
                                      });
                                    }

                                    dependentValidations(
                                      event,
                                      eachAttribute.AC
                                    );
                                  }}
                                  isMulti={false}
                                  noIndicator={false}
                                  noSeparator={false}
                                />
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "Text" ||
                          eachAttribute.DT === "Number" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                )?.IFR?.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }
                            >
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM?.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <InputForm
                                  className="form-control"
                                  placeholder={eachAttribute.AN}
                                  isDisabled={
                                    attributeValidation[eachAttribute.AC]
                                      ?.isDisable
                                  }
                                  textArea={false}
                                  value={
                                    attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : ""
                                  }
                                  onChange={(e) => {
                                    setAttributesData((prev) => ({
                                      ...prev,
                                      [eachAttribute.AC]: e.target.value,
                                    }));

                                    dependentValidations(
                                      e.target.value,
                                      eachAttribute.AC
                                    );
                                  }}
                                  maxLength="255"
                                />
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "Date" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                )?.IFR?.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }
                            >
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM?.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <DateForm
                                  isDisabled={
                                    attributeValidation[eachAttribute.AC]
                                      ?.isDisable
                                  }
                                  value={
                                    attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : null
                                  }
                                  onChange={(date) => {
                                    setAttributesData((prev) => ({
                                      ...prev,
                                      [eachAttribute.AC]:
                                        moment(date).format("DD-MMM-YYYY"),
                                    }));

                                    dependentValidations(
                                      moment(date).format("DD-MMM-YYYY"),
                                      eachAttribute.AC
                                    );
                                  }}
                                />
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "Checkbox" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                )?.IFR?.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }
                            >
                              <div className="mb-1">
                                <label
                                  htmlFor={eachAttribute.AC}
                                  className="col-form-label"
                                >
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM?.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <div>
                                  <input
                                    disabled={
                                      attributeValidation[eachAttribute.AC]
                                        ?.isDisable
                                    }
                                    type="checkbox"
                                    onChange={(e) => {
                                      setAttributesData((prev) => ({
                                        ...prev,
                                        [eachAttribute.AC]: e.target.checked,
                                      }));

                                      dependentValidations(
                                        e.target.checked,
                                        eachAttribute.AC
                                      );
                                    }}
                                    id={eachAttribute.AC}
                                    checked={
                                      attributesData[eachAttribute.AC]
                                        ? attributesData[eachAttribute.AC]
                                        : false
                                    }
                                  />
                                </div>
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "Textarea" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                )?.IFR?.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }
                            >
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM?.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <InputForm
                                  className="form-control"
                                  placeholder={eachAttribute.AN}
                                  isDisabled={
                                    attributeValidation[eachAttribute.AC]
                                      ?.isDisable
                                  }
                                  textArea={true}
                                  value={
                                    attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : ""
                                  }
                                  onChange={(e) => {
                                    setAttributesData((prev) => ({
                                      ...prev,
                                      [eachAttribute.AC]: e.target.value,
                                    }));

                                    dependentValidations(
                                      e.target.value,
                                      eachAttribute.AC
                                    );
                                  }}
                                  maxLength="255"
                                />
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "Document" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }
                            >
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <div className="box position-relative">
                                  <input
                                    id={`files-${sectionIndex}`}
                                    className="form-control inputfile inputfile-6 multiple-inputfile"
                                    data-multiple-caption="{count} files selected"
                                    multiple={
                                      JSON.parse(eachAttribute.V).FC > 1
                                        ? true
                                        : false
                                    }
                                    ref={FlatDocRef}
                                    accept={JSON.parse(eachAttribute.V).FX}
                                    type="file"
                                    onChange={(e) => {
                                      handleFileChangeForFlat(e, eachAttribute);
                                    }}
                                    value={""}
                                  />
                                  <label
                                    htmlFor={`files-${sectionIndex}`}
                                    className="form-control"
                                    style={{ width: 0, border: "none" }}
                                  >
                                    <strong
                                      style={{
                                        padding: "6px 16px",
                                        backgroundColor: "#3c5464",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      <i
                                        className="fa fa-upload rotate90"
                                        aria-hidden="true"
                                      ></i>
                                      {"  "}
                                      Upload
                                    </strong>{" "}
                                  </label>
                                </div>
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                                <div className="file-added-list">
                                  <ul className="list-unstyle">
                                    {selectedFilesForFlat &&
                                      selectedFilesForFlat.length > 0 &&
                                      selectedFilesForFlat.map(
                                        (fileAttribute, attributeIndex) => (
                                          <>
                                            {fileAttribute.AC ===
                                              eachAttribute.AC && (
                                              <li
                                                className="list mt-1"
                                                key={attributeIndex}
                                              >
                                                <div className="media">
                                                  <div className="media-body text-truncate">
                                                    <span className="view-more">
                                                      {fileAttribute.Name}
                                                    </span>
                                                  </div>
                                                  {/*Here code for view same div of trash should be used. ISDOCVIEW/ISDOCDOWN*/}
                                                  {/* {
                                                  // section?.ISDOCVIEW &&
                                                  fileAttribute.DocId !==
                                                    0 && (
                                                    <div className="media-right ml-2">
                                                      <i
                                                        className="fa fa-eye"
                                                        aria-hidden="true"
                                                        onClick={() =>
                                                          handleViewForFlat(
                                                            fileAttribute,
                                                            attributeIndex
                                                          )
                                                        }></i>
                                                    </div>
                                                  )
                                                } */}
                                                  {
                                                    //  section?.ISDOCDOWN &&
                                                    <div className="media-right ml-2">
                                                      <i
                                                        className="fa-solid fa-download"
                                                        aria-hidden="true"
                                                        onClick={() =>
                                                          handleDownloadFileFlat(
                                                            fileAttribute,
                                                            attributeIndex
                                                          )
                                                        }
                                                      ></i>
                                                    </div>
                                                  }
                                                  {section?.ISEDIT &&
                                                    fileAttribute.DocId !==
                                                      0 && (
                                                      <div className="media-right ml-2">
                                                        <i
                                                          className="fa fa-trash"
                                                          aria-hidden="true"
                                                          onClick={() =>
                                                            handleDeleteFileForFlat(
                                                              fileAttribute,
                                                              attributeIndex
                                                            )
                                                          }
                                                        ></i>
                                                      </div>
                                                    )}
                                                </div>
                                              </li>
                                            )}
                                          </>
                                        )
                                      )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : eachAttribute.AT === "AttributeSet" &&
                      eachAttribute.DT === "Custom" ? (
                      <div className="col-lg-12">
                        {section?.ISEDIT && (
                          <div className="col-lg-12 mt-2 mb-3 d-flex justify-content-end">
                            <Tooltip title="Add">
                              <button
                                id={"addRec"}
                                className="btn btn-primary"
                                onClick={() => {
                                  if (ShowAttrSet) {
                                    setAttributeSetData((prev) => ({
                                      ...prev,
                                      ...ogAttributeSet,
                                    }));
                                    setCurrentGridId(null);
                                    setViewOnly(false);
                                  }
                                  setShowAttrSet(!ShowAttrSet);
                                }}
                              >
                                <i className={"fa fa-plus"}></i> Add
                              </button>
                            </Tooltip>
                          </div>
                        )}
                        {ShowAttrSet && (
                          <div className="row">
                            {eachAttribute.Attribute.length > 0 &&
                              eachAttribute.Attribute.map(
                                (subAttribute, index) => (
                                  <React.Fragment
                                    key={`${index}-${subAttribute.AC}`}
                                  >
                                    {subAttribute.DT === "DropdownSingle" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            )?.IFR?.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {JSON.parse(
                                                subAttribute.V
                                              )?.ISM?.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <SelectForm
                                              isClearable
                                              isSearchable
                                              options={
                                                optionsObj[subAttribute.AC]
                                                  ? optionsObj[subAttribute.AC]
                                                  : []
                                              }
                                              placeholder={subAttribute.AN}
                                              isDisabled={
                                                !section.ISEDIT || viewOnly
                                              }
                                              value={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : null
                                              }
                                              onChange={(event) => {
                                                setAttributeSetData((prev) => ({
                                                  ...prev,
                                                  [subAttribute.AC]: event,
                                                }));

                                                if (
                                                  event !== null &&
                                                  subAttribute.CC !== null &&
                                                  subAttribute.CC !== ""
                                                ) {
                                                  const CC =
                                                    subAttribute.CC.split(",");

                                                  CC.forEach(
                                                    async (childDropdown) => {
                                                      setAttributeSetData(
                                                        (prev) => ({
                                                          ...prev,
                                                          [childDropdown]: null,
                                                        })
                                                      );

                                                      const options =
                                                        await getDropdownsData(
                                                          childDropdown,
                                                          event.value
                                                        );

                                                      setOptionsObj((prev) => ({
                                                        ...prev,
                                                        [childDropdown]:
                                                          options,
                                                      }));
                                                    }
                                                  );
                                                }
                                              }}
                                              isMulti={false}
                                              noIndicator={false}
                                              noSeparator={false}
                                            />
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT === "Text" ||
                                      subAttribute.DT === "Number" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            )?.IFR?.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {JSON.parse(
                                                subAttribute.V
                                              )?.ISM?.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <InputForm
                                              className="form-control"
                                              placeholder={subAttribute.AN}
                                              isDisabled={
                                                !section.ISEDIT || viewOnly
                                              }
                                              textArea={false}
                                              value={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : ""
                                              }
                                              onChange={(e) => {
                                                setAttributeSetData((prev) => ({
                                                  ...prev,
                                                  [subAttribute.AC]:
                                                    e.target.value,
                                                }));
                                              }}
                                              maxLength="255"
                                            />
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT === "Date" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            )?.IFR?.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {JSON.parse(
                                                subAttribute.V
                                              )?.ISM?.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <DateForm
                                              isDisabled={
                                                !section.ISEDIT || viewOnly
                                              }
                                              value={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : null
                                              }
                                              onChange={(date) => {
                                                setAttributeSetData((prev) => ({
                                                  ...prev,
                                                  [subAttribute.AC]:
                                                    moment(date).format(
                                                      "DD-MMM-YYYY"
                                                    ),
                                                }));
                                              }}
                                            />
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT === "Checkbox" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            )?.IFR?.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label
                                              htmlFor={subAttribute.AC}
                                              className="col-form-label"
                                            >
                                              {subAttribute.AN}
                                              {JSON.parse(
                                                subAttribute.V
                                              )?.ISM?.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <div>
                                              <input
                                                disabled={
                                                  !section.ISEDIT || viewOnly
                                                }
                                                type="checkbox"
                                                onChange={(e) => {
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      [subAttribute.AC]:
                                                        e.target.checked,
                                                    })
                                                  );
                                                }}
                                                id={subAttribute.AC}
                                                checked={
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ]
                                                    ? attributeSetData[
                                                        subAttribute.AC
                                                      ]
                                                    : false
                                                }
                                              />
                                            </div>
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </React.Fragment>
                                )
                              )}
                          </div>
                        )}

                        <div className="row">
                          {ShowAttrSet && (
                            <div className="col-lg-3 mt-2 mb-3">
                              <button
                                id={currentGridId === null ? "0" : "1"}
                                className="btn btn-primary"
                                onClick={(e) => {
                                  const targetId = e.currentTarget.id;

                                  if (targetId === "0") {
                                    //insert
                                    const uniqueId = uuid();

                                    //validation for empty data before adding in grid

                                    const isError = validateData(
                                      eachAttribute.Attribute
                                    );

                                    if (!isError) {
                                      //add record in grid

                                      setOgFilledAttributeSet((prev) => [
                                        ...prev,
                                        {
                                          ...attributeSetData,
                                          Id: uniqueId,
                                          IsActive: true,
                                        },
                                      ]);

                                      let gridData = {};

                                      eachAttribute.Attribute.forEach(
                                        async (subAttribute) => {
                                          if (
                                            typeof attributeSetData[
                                              subAttribute.AC
                                            ] === "object"
                                          ) {
                                            gridData = {
                                              ...gridData,
                                              [subAttribute.AC]:
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]?.label || null, //for date and dropdown
                                            };
                                          } else if (
                                            typeof attributeSetData[
                                              subAttribute.AC
                                            ] === "boolean"
                                          ) {
                                            gridData = {
                                              ...gridData,
                                              [subAttribute.AC]:
                                                attributeSetData[
                                                  subAttribute.AC
                                                ] === true
                                                  ? "true"
                                                  : "false",
                                            };
                                          } else {
                                            gridData = {
                                              ...gridData,
                                              [subAttribute.AC]:
                                                attributeSetData[
                                                  subAttribute.AC
                                                ],
                                            };
                                          }
                                        }
                                      );

                                      //reset
                                      setAttributeSetData((prev) => ({
                                        ...prev,
                                        ...ogAttributeSet,
                                      }));

                                      setFormErrors({});

                                      setGridData((prev) => [
                                        ...prev,
                                        {
                                          Id: uniqueId,
                                          ...gridData,
                                          IsActive: true,
                                        },
                                      ]);

                                      setDisplayGrid((prev) => [
                                        ...prev,
                                        {
                                          Id: uniqueId,
                                          ...gridData,
                                          IsActive: true,
                                        },
                                      ]);
                                    }
                                  } else {
                                    //update

                                    //validation for empty data before updating in grid

                                    const isError = validateData(
                                      eachAttribute.Attribute
                                    );

                                    if (!isError) {
                                      //update

                                      setOgFilledAttributeSet((prev) => {
                                        const newState = prev.map((record) => {
                                          if (record.Id === currentGridId) {
                                            return { ...attributeSetData };
                                          }
                                          return record;
                                        });
                                        return newState;
                                      });

                                      let gridData = {};

                                      eachAttribute.Attribute.forEach(
                                        async (subAttribute) => {
                                          if (
                                            typeof attributeSetData[
                                              subAttribute.AC
                                            ] === "object"
                                          ) {
                                            gridData = {
                                              ...gridData,
                                              [subAttribute.AC]:
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]?.label || null, //for date and dropdown
                                            };
                                          } else if (
                                            typeof attributeSetData[
                                              subAttribute.AC
                                            ] === "boolean"
                                          ) {
                                            gridData = {
                                              ...gridData,
                                              [subAttribute.AC]:
                                                attributeSetData[
                                                  subAttribute.AC
                                                ] === true
                                                  ? "true"
                                                  : "false",
                                            };
                                          } else {
                                            gridData = {
                                              ...gridData,
                                              [subAttribute.AC]:
                                                attributeSetData[
                                                  subAttribute.AC
                                                ],
                                            };
                                          }
                                        }
                                      );

                                      //reset
                                      setAttributeSetData((prev) => ({
                                        ...prev,
                                        ...ogAttributeSet,
                                      }));

                                      setFormErrors({});

                                      setGridData((prev) => {
                                        const newState = prev.map((record) => {
                                          if (record.Id === currentGridId) {
                                            return {
                                              Id: currentGridId,
                                              ...gridData,
                                            };
                                          }
                                          return record;
                                        });
                                        return newState;
                                      });

                                      setDisplayGrid((prev) => {
                                        const newState = prev.map((record) => {
                                          if (record.Id === currentGridId) {
                                            return {
                                              Id: currentGridId,
                                              ...gridData,
                                            };
                                          }
                                          return record;
                                        });
                                        return newState;
                                      });

                                      setCurrentGridId(null);
                                    }
                                  }
                                }}
                              >
                                <i
                                  className={
                                    currentGridId === null
                                      ? "fa fa-plus"
                                      : "fas fa-edit"
                                  }
                                ></i>{" "}
                                {currentGridId === null
                                  ? "Save"
                                  : "Update Record"}
                              </button>
                            </div>
                          )}

                          <div className="col-lg-12 p-0 mb-3">
                            <DynamicGrid
                              options={gridOptions}
                              data={displayGrid}
                              columns={gridColumns}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </React.Fragment>
                ))}
            </div>
          </AccordionDetails>
        </Accordion>
      ) : null}
    </div>
  );
};

const Agency = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<any>({});
  const [tabs, setTabs] = useState([]);
  const [activetab, setActivetab] = useState<string>(null);
  const [activetabIndex, setActivetabIndex] = useState<number>(0);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { state } = useLocation();
  const [TId, setTId] = useState((state !== null && state.TId) || 0);
  const [headerValues, setHeaderValues] = useState<any>(null);
  const [sectionValuesArray, setSectionValuesArray] = useState<any>([]);
  let { userDetails }: any = useUserContext();
  const { currentRoleId }: any = useUserContext();

  useEffect(() => {
    (async () => {
      showLoader();
      const tabs = await APICall(getTabsData, "POST", {
        MN: "Agency",
        IN: "Agency_Form",
      });

      if (tabs.data !== null && tabs.data.length > 0) {
        setTabs(tabs.data);
        setActivetab(tabs.data[0].TN);
      } else {
        console.log("No tabs found!", tabs);
        setTabs([]);
      }
      hideLoader();
    })();
  }, []);

  //tab api here
  useEffect(() => {
    //get section
    if (activetab !== null) {
      (async () => {
        showLoader();
        //Spacial header API for Agency
        const tabHeader = await APICall(getTabHeaderDetails, "POST", {
          MN: "Agency",
          IN: "",
          Id: TId,
        });
        if (tabHeader.data !== null) {
          setHeaderValues(tabHeader.data);
        } else {
          setHeaderValues(null);
        }
        const section = await APICall(getSectionDetailsV2, "POST", {
          MN: "Agency",
          IN: "Agency_Form",
          TN: activetab,
          UID: userDetails.Id,
          RoleId: currentRoleId?.value,
        });

        if (
          section.data !== null &&
          section.data.t !== undefined &&
          section.data.t.tn === activetab
        ) {
          setFormData(section.data.t);
        } else {
          setFormData({});
        }

        hideLoader();
      })();

      if (TId !== 0 && headerValues === null) {
        (async () => {
          let bodyObj = {
            mn: "Agency",
            in: "Agency_Form",
            tn: "Agency",
            SN: "AgencyDetails",
            TID: TId,
            ISH: "true",
          };

          const response = await APICall(
            getAttributeValueDetailsV2,
            "POST",
            bodyObj
          );

          if (response.data.sections && response.data.sections.length > 0) {
            setSectionValuesArray(response.data.sections);
          } else {
            setSectionValuesArray([]);
          }
        })();
      }
    }
  }, [activetab]);

  const defaultTheme = createTheme({});
  const { breakpoints } = defaultTheme;

  const tabsTheme = {
    ...defaultTheme,
    components: {
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: "0px !important",
            marginLeft: "-42px",
            [breakpoints.down("md")]: {
              minHeight: "0px !important",
              marginLeft: "0px",
            },
          },
        },
      },
    },
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row prof_bg mb-3 border-bottom">
          <div className="container-fluid">
            {headerValues && (
              <div className="row botpad">
                <div className="col-lg-2 col-md-2 col-sm-12">
                  <div className="prof_img">
                    {headerValues && <img src={profileImg} />}
                  </div>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-12 profData">
                  <div className="name">
                    <h4>{headerValues && `${headerValues?.agcyid} `}</h4>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12 profDetail">
                      <ul>
                        <li>
                          <a>
                            {headerValues && (
                              <>
                                {/* <i className="fa-regular fa-address-card"></i>{" "} */}
                                {headerValues?.agcynm}
                              </>
                            )}
                          </a>
                        </li>
                        <li>
                          <a>
                            {headerValues &&
                              headerValues?.serviceType !== null && (
                                <>
                                  <i className="fa-solid fa-user"></i>{" "}
                                  {headerValues?.serviceType}
                                </>
                              )}
                          </a>
                        </li>
                        {/*  <li>
                          <a>
                            {headerValues && headerValues?.LEN !== null && (
                              <>
                                <i className="fa-solid fa-briefcase"></i>{" "}
                                {headerValues?.LEN}
                              </>
                            )}
                          </a>
                        </li> 
                        {/* <li>
                          <a>
                            {headerValues && headerValues?.PHLOC !== null && (
                              <>
                                <i className="fa-solid fa-location-dot"></i>{" "}
                                {headerValues?.PHLOC}{" "}
                                {headerValues?.FUNC !== null &&
                                  `| ${headerValues?.FUNC}`}
                                {headerValues?.JT !== "" &&
                                  `| ${headerValues?.JT}`}
                              </>
                            )}
                          </a>
                        </li> */}
                      </ul>
                    </div>
                    {/* <div className="col-lg-4 col-md-4 col-sm-12 profDetail">
                      <ul>
                        <li>
                          <a>
                            {TId !== 0 && (
                              <>
                                <i className="fa-solid fa-phone"></i>
                                +91 9876543210
                              </>
                            )}
                          </a>
                        </li>
                        <li>
                          <a>
                            {headerValues && (
                              <>
                                <i className="fa-solid fa-envelope"></i>{" "}
                                {`${headerValues?.FN?.toLowerCase()}.${headerValues?.LN?.toLowerCase()}@welspun.com`}
                              </>
                            )}
                          </a>
                        </li>
                        <li>
                          <a>
                            {headerValues && headerValues?.MG !== "" && (
                              <>
                                <i className="fas fa-network-wired"></i>{" "}
                                {headerValues?.MG}
                              </>
                            )}
                          </a>
                        </li>
                        <li>
                          <a>
                            {headerValues && headerValues?.IsActive ? (
                              <>
                                <i className="fas fa-tag"></i> Active
                              </>
                            ) : (
                              headerValues &&
                              headerValues?.IsActive === false && (
                                <>
                                  <i className="fas fa-tag"></i> InActive
                                </>
                              )
                            )}
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-lg-2 col-md-12 col-sm-12 "></div>
              <div className="col-lg-10 col-md-12 col-sm-12 ">
                <ThemeProvider theme={tabsTheme}>
                  <Tabs
                    value={activetabIndex}
                    className="profile-tabs"
                    onChange={() => {}}
                    variant="scrollable"
                    TabScrollButtonProps={{
                      style: {
                        color: "white",
                      },
                    }}
                    scrollButtons
                    TabIndicatorProps={{
                      style: { display: "none" },
                    }}
                    allowScrollButtonsMobile
                    aria-label="scrollable tabs"
                  >
                    <div className="tabBtn">
                      <ul>
                        {tabs.length > 0 &&
                          tabs.map((eachTab, index) => (
                            <li key={`${index}-${eachTab.TN}`}>
                              <button
                                onClick={() => setActivetab(eachTab.TN)}
                                className={
                                  eachTab.TN === activetab
                                    ? "bttn active"
                                    : "bttn"
                                }
                              >
                                {eachTab.TDN}
                              </button>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </Tabs>
                </ThemeProvider>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
            <div className="text-right my-1">
              <button
                onClick={() => {
                  navigate(-1);
                }}
                disabled={false}
                className="btn btn-secondary float-right"
              >
                <i className="fa-solid fa-arrow-left"></i> Back
              </button>
            </div>
          </div>
        </div>

        {/* section row */}
        <div className="row accordion_grid">
          {formData.S !== undefined &&
            formData.S.length > 0 &&
            ((((state !== null && state.TId === 0) || state === null) &&
              sectionValuesArray.length === 0) ||
              (state !== null &&
                state.TId !== 0 &&
                sectionValuesArray.length > 0)) &&
            formData.S.map((eachSection, index) => (
              <React.Fragment key={`${index}-${eachSection.SN}`}>
                <SectionComponent
                  formData={formData}
                  setFormData={setFormData}
                  section={eachSection}
                  TId={TId}
                  setTId={setTId}
                  sectionValuesArray={sectionValuesArray}
                  sectionIndex={index}
                  // showLoader={showLoader}
                  // hideLoader={hideLoader}
                />
              </React.Fragment>
            ))}
        </div>
      </div>
    </>
  );
};

export default Agency;
