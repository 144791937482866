import React, { useState, useEffect, useContext } from "react";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { useNavigate } from "react-router-dom";
import "./WorkFlowDashboard.css";
import { APICall } from "../../Helpers/APICalls";
import {
  getWorkFlowDashboardDetails,
  getUserPermissionExcelData,
  getUserPermissionExcelDetailsForAllUsers,
  getWorkFlowExcelList,
  getWorkFlowTypes,
  getWorkflowTypeReason,
  getModulesList,
} from "../../Helpers/APIEndPoints/EndPoints";
import InputForm from "../../Components/InputForm/InputForm";
import { Collapse, Tooltip } from "@mui/material";
import { LoaderContext } from "../../Helpers/Context/Context";
import ExcelJS from "exceljs";
import SelectForm from "../../Components/SelectForm/SelectForm";

const WorkFlowDashboard = () => {
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [PageSize, setPageSize] = useState(10);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  const [panal1, setPanal1] = useState(true);
  const [sortDirection, setSortDirection] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [open, setOpen] = useState(false);

  const [moduleIdDropdownOptions, setModuleIdDropdownOptions] = useState([]);
  const [moduleIdDropdownValue, setModuleIdDropdownValue] = useState(null);

  const [
    workFlowTypeReasonDropdownOptions,
    setWorkFlowTypeReasonDropdownOptions,
  ] = useState([]);
  const [workFlowTypeReasonDropdownValue, setWorkFlowTypeReasonDropdownValue] =
    useState(null);

  const [workflowTypeIdDropdownOptions, setWorkflowTypeIdDropdownOptions] =
    useState([]);
  const [workflowTypeIdDropdownValue, setWorkflowTypeIdDropdownValue] =
    useState(null);

  useEffect(() => {
    showLoader();
    getGridStructureTwo({
      PageNumber: page,
      PageSize: PageSize,
      SearchText: searchText,
      SortColumn: sortColumn,
      SortOrder: sortDirection,
      FilterWorkflowTypeReason: "",
      FilterWorkflowType: "",
      FilterModuleId: "",
    });
    hideLoader();
  }, [page, searchText, sortDirection, sortColumn]);

  useEffect(() => {
    fetchWorkflowTypeIdData();
    fetchWorkFlowTypeReasonData();
    fetchModuleID();
  }, []);

  // Export Excel with search
  const toTitleCase = (str) => {
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  };

  const exportToExcelUserPermission = async () => {
    const response = await APICall(getUserPermissionExcelData, "POST", {
      SearchText: searchText,
    });

    const responseData = response.data;

    if (!responseData || responseData.length === 0) {
      console.error("No data to export.");
      return;
    }

    const columnsToInclude = [
      "userName",
      "roleName",
      "business",
      "sbu",
      "legalEntity",
      "division",
      "country",
      "physicalLocation",
      "customBusiness",
      "function",
      "department",
      "section",
      "employeeClass",
      "jobBand",
      "jobLabel",
      "standardDesignation",
      "payrollLocation",
      "isActive",
    ];

    const headers = Object.keys(responseData[0])?.filter((header) =>
      columnsToInclude.includes(header)
    );
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Report");
    const formattedHeaders = headers.map((header) =>
      toTitleCase(header.replace(/([a-z])([A-Z])/g, "$1 $2"))
    );

    worksheet.addRow(formattedHeaders);
    worksheet.getRow(1).font = { bold: true };
    responseData.font = { bold: true };
    responseData.forEach((row) => {
      const dataRow = headers.map((header) => {
        if (header === "isActive") {
          return row[header] ? "Yes" : "No";
        }
        return row[header] || "";
      });

      worksheet.addRow(dataRow);
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "Report User Permission.xlsx";
      a.click();

      URL.revokeObjectURL(url);
    });
  };

  const exportToExcel = async () => {
    const response = await APICall(getWorkFlowExcelList, "POST", {
      SearchText: searchText,
    });

    const responseData = response.data;

    if (!responseData || responseData.length === 0) {
      console.error("No data to export.");
      return;
    }

    const columnsToInclude = [
      "workFlowName",
      "workFlowTypeName",
      "workFlowTypeReasonName",
      "moduleName",
      "roleName",
      "payRoleLocationName",
      "countryName",
      "isActive",
    ];

    //Add Header
    const headers = Object.keys(responseData[0])?.filter((header) =>
      columnsToInclude.includes(header)
    );
    //Sheet Name
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Report");

    //Space in width
    const columnWidths = headers.map((header) => {
      const maxContentLength = Math.max(
        ...responseData.map((row) =>
          row[header] ? String(row[header]).length : 0
        )
      );
      return Math.min(50, Math.max(10, maxContentLength * 1.5));
    });

    // Set column widths
    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width;
    });

    //set Column header first letter capital
    const formattedHeaders = headers.map((header) =>
      toTitleCase(header.replace(/([a-z])([A-Z])/g, "$1 $2"))
    );

    // //change name isActive to Status
    // const indexOfIsActive = formattedHeaders.indexOf("Is Active");
    // if (indexOfIsActive !== -1) {
    //   formattedHeaders[indexOfIsActive] = "Status";
    // }

    worksheet.addRow(formattedHeaders);
    //Bold headers
    worksheet.getRow(1).font = { bold: true };
    responseData.font = { bold: true };

    responseData.forEach((row) => {
      const dataRow = headers.map((header) => {
        if (header === "isActive") {
          return row[header] ? "Yes" : "No";
        }
        return row[header] || "";
      });

      worksheet.addRow(dataRow);
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      //Excel file name
      a.download = "Report Workflow.xlsx";
      a.click();

      URL.revokeObjectURL(url);
    });
  };

  // Fetch Dashboard records
  const getGridStructureTwo = async ({
    PageNumber,
    PageSize,
    SearchText,
    SortColumn,
    SortOrder,
    FilterWorkflowTypeReason,
    FilterWorkflowType,
    FilterModuleId,
  }) => {
    showLoader();
    try {
      const GetDashboardData = await APICall(
        getWorkFlowDashboardDetails,
        "POST",
        {
          PageNumber,
          PageSize,
          SearchText,
          SortColumn,
          SortOrder,
          FilterWorkflowTypeReason,
          FilterWorkflowType,
          FilterModuleId,
        }
      );

      if (GetDashboardData.data && GetDashboardData.data.length > 0) {
        setRowData(GetDashboardData.data);
        setCount(GetDashboardData.data[0].count);
        hideLoader();
      } else {
        setRowData([]);
        hideLoader();
      }
    } catch (error) {
      console.error("Error fetching grid data:", error);
      hideLoader();
    }
    hideLoader();
  };

  const edit = (value, tableMeta) => {
    const UId = tableMeta.rowData[0];
    navigate("/WorkFlowForm", { state: { UId } });
  };

  const onSearchFilter = (event, type) => {
    setSearchText(event.target.value);
  };

  const columns = [
    {
      name: "id",
      label: "Id",
      options: { display: false },
    },
    {
      name: "workFlowName",
      label: "Name",
      options: { display: true, sort: true },
    },
    {
      name: "systemName",
      label: "System Name",
      options: { display: true, sort: true },
    },
    {
      name: "workFlowTypeName",
      label: "WorkFlow Type",
      options: { display: true, sort: true, filter: true },
    },
    {
      name: "workFlowTypeReasonName",
      label: "WorkFlow Type Reason",
      options: { display: true, sort: true, filter: true },
    },
    {
      name: "moduleName",
      label: "Module Name",
      options: { display: true, sort: true, filter: true },
    },
    {
      name: "roleName",
      label: "Initiator Role",
      options: { display: true, sort: true },
    },
    {
      name: "countryId ",
      label: "CountryId ",
      options: { display: false },
    },
    {
      name: "countryName ",
      label: "Country Name ",
      options: { display: false },
    },
    {
      name: "payrollLocationId  ",
      label: "PayrollLocationId  ",
      options: { display: false },
    },
    {
      name: "payRoleLocationName  ",
      label: "PayRoleLocation Name  ",
      options: { display: false },
    },
    {
      name: "isAboveDeputyManager  ",
      label: "IsAboveDeputyManager  ",
      options: { display: false },
    },
    {
      name: "isAboveAVP ",
      label: "IsAboveAVP ",
      options: { display: false },
    },
    {
      name: "isActive",
      label: "Active Status",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          if (value) {
            return <i className="fas fa-check"></i>;
          } else {
            return <i className="fas fa-times"></i>;
          }
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Tooltip title="Edit">
                <i
                  style={{ marginRight: "0px", cursor: "pointer" }}
                  onClick={() => {
                    edit(value, tableMeta);
                  }}
                  className="fas fa-edit"
                ></i>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: PageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",
    onChangePage: async (page) => {
      setPage(page + 1);
    },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      setPage(page);
      if (sortDirection === "asc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
    },
  };

  // Filters dropdown
  const handleModuleChange = async (selectedOption: any) => {
    showLoader();
    try {
      setModuleIdDropdownValue(selectedOption);
    } catch (error) {
      console.error("Error handling interface change:", error);
    } finally {
      hideLoader();
    }
  };

  const handleworkFlowTypeReasonChange = async (selectedOption: any) => {
    showLoader();
    try {
      setWorkFlowTypeReasonDropdownValue(selectedOption);
    } catch (error) {
      console.error("Error handling interface change:", error);
    } finally {
      hideLoader();
    }
  };

  const handleworkflowTypeIdChange = async (selectedOption: any) => {
    showLoader();
    try {
      setWorkflowTypeIdDropdownValue(selectedOption);
    } catch (error) {
      console.error("Error handling interface change:", error);
    } finally {
      hideLoader();
    }
  };

  // Fetch dropdown API WorkflowTypeId
  const fetchWorkflowTypeIdData = async () => {
    try {
      const response = await APICall(getWorkFlowTypes, "POST", {});
      setWorkflowTypeIdDropdownOptions(response.data);
      //console.log(response.data);
    } catch (error) {
      console.error("Error fetching Role data:", error);
    }
  };

  // Fetch dropdown API WorkFlowTypeReason
  const fetchWorkFlowTypeReasonData = async () => {
    try {
      const response = await APICall(getWorkflowTypeReason, "POST", {});
      setWorkFlowTypeReasonDropdownOptions(response.data);
    } catch (error) {
      console.error("Error fetching Role data:", error);
    }
  };

  // Fetch dropdown API ModuleID
  const fetchModuleID = async () => {
    try {
      const response = await APICall(getModulesList, "POST", {});
      setModuleIdDropdownOptions(response.data);
    } catch (error) {
      console.error("Error fetching Role data:", error);
    }
  };

  // Reset filters
  const resetFilters = () => {
    showLoader();
    getGridStructureTwo({
      PageNumber: page,
      PageSize: PageSize,
      SearchText: searchText,
      SortColumn: sortColumn,
      SortOrder: sortDirection,
      FilterWorkflowTypeReason: null,
      FilterWorkflowType: null,
      FilterModuleId: null,
    });
    setModuleIdDropdownValue(null);
    setWorkFlowTypeReasonDropdownValue(null);
    setWorkflowTypeIdDropdownValue(null);

    hideLoader();
  };

  // Submit the filters
  const onSubmitFilter = () => {
    showLoader();
    const FilterWorkflowTypeReason = workFlowTypeReasonDropdownValue
      ? workFlowTypeReasonDropdownValue.map((e) => e.value).join(", ")
      : "";

    const FilterWorkflowType = workflowTypeIdDropdownValue
      ? workflowTypeIdDropdownValue.map((e) => e.value).join(", ")
      : "";

    const FilterModuleId = moduleIdDropdownValue
      ? moduleIdDropdownValue.map((e) => e.value).join(", ")
      : "";

    getGridStructureTwo({
      PageNumber: page,
      PageSize: PageSize,
      SearchText: searchText,
      SortColumn: sortColumn,
      SortOrder: sortDirection,
      FilterWorkflowTypeReason,
      FilterWorkflowType,
      FilterModuleId,
    });
    hideLoader();
  };

  return (
    <>
      <div className="container-fluid">
        <>
          {/* <Accordion
          className="mb-3"
          expanded={panal1}
          onChange={() => setPanal1((prev) => !prev)}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            style={{ background: "#3C5464" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}
          >
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                {" "}
                <Typography>User And OU Mapping</Typography>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails> */}
        </>
        <div className="col-lg-12 col-md-10 col-sm-12 "></div>
        <div className="mb-3 pt-3">
          <div className="d-flex align-items-center justify-content-end align-items-center mb-3">
            <button
              onClick={() => {
                navigate("/WorkFlowForm", { state: { UId: 0 } });
              }}
              className="btn btn-primary mr-2"
            >
              <i className="fa fa-plus mr-2"></i>
              Add New
            </button>
            {/* <button
              type="button"
              onClick={exportToExcelUserPermission}
              className="btn btn-primary mr-2"
            >
              <i className="fa fa-download mr-2"></i>
              Export To Excel User Permission
            </button> */}
            {/* This is for all record */}
            <button
              type="button"
              onClick={exportToExcel}
              className="btn btn-primary mr-2"
            >
              <i className="fa fa-download mr-2"></i>
              Export To Excel{" "}
            </button>
          </div>
          <div className="grid-wrapper grid_mob">
            <div className="row mx-auto filtBox">
              <div className="col-lg-3 col-md-3 col-sm-12">
                <div className="form-group filtInp acc_btn">
                  <InputForm
                    value={searchText}
                    placeholder={"Search Workflow"}
                    isDisabled={false}
                    textArea={false}
                    onChange={onSearchFilter}
                  />
                </div>
              </div>
              <div className="col-lg-1 col-md-1 col-sm-12 filtrt">
                <ul className=" filter-icon-wel">
                  <li>
                    <a
                      href="javascript:void(0);"
                      onClick={() => setOpen(!open)}
                      aria-controls="example-collapse-text"
                      aria-expanded={open}
                      className="filter-btn"
                    >
                      <i className="fa fa-filter"></i>
                    </a>
                  </li>
                </ul>
              </div>
              {/* <Tooltip title="Export To Excel">
                      <button
                        type="button"
                        onClick={exportToExcel}
                        className="btn btn-primary mr-2"
                      >
                        <i className="fa fa-download mr-2"></i>
                      </button>
                    </Tooltip> */}
            </div>
          </div>

          <Collapse in={open}>
            <div id="example-collapse-text grid-wrapper">
              <div className="row mx-auto myInlineStyle">
                {/* Module Id */}
                <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                  <label className="col-form-label text-white">Module</label>
                  <SelectForm
                    isClearable
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                    placeholder={"Select an option"}
                    value={moduleIdDropdownValue}
                    options={moduleIdDropdownOptions}
                    onChange={handleModuleChange}
                    isDisabled={false}
                  />
                </div>

                {/*  Workflow Type */}
                <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                  <label className="col-form-label text-white">
                    Workflow Type
                  </label>
                  <SelectForm
                    isClearable
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                    options={workflowTypeIdDropdownOptions}
                    placeholder={"Select an option"}
                    value={workflowTypeIdDropdownValue}
                    onChange={handleworkflowTypeIdChange}
                    isDisabled={false}
                  />
                </div>

                {/* WorkflowType Reason */}
                <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                  <label className="col-form-label text-white">
                    WorkflowType Reason
                  </label>
                  <SelectForm
                    isClearable
                    options={workFlowTypeReasonDropdownOptions}
                    placeholder={"Select an option"}
                    isDisabled={false}
                    onChange={handleworkFlowTypeReasonChange}
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                    value={workFlowTypeReasonDropdownValue}
                  />
                </div>

                <div className="col-lg-12 d-flex mt-2 justify-content-end">
                  <button
                    onClick={resetFilters}
                    className="btn btn-secondary mr-2"
                  >
                    <i className="fa fa-undo" aria-hidden="true"></i> Reset{" "}
                  </button>
                  <button
                    className="btn btn-filter-submit"
                    onClick={onSubmitFilter}
                  >
                    <i className="fa fa-check" aria-hidden="true"></i> Submit{" "}
                  </button>
                </div>
              </div>
            </div>
          </Collapse>
          <div className="mb-3 pt-3">
            <DynamicGrid data={rowData} columns={columns} options={options} />
          </div>
        </div>
        {/* </AccordionDetails>
        </Accordion> */}
      </div>
    </>
  );
};

export default WorkFlowDashboard;
