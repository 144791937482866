import React from "react";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import "./LMSDynamicGrid.css";
declare module "@mui/material/styles" {
  interface Components {
    [key: string]: any;
  }
}

const LMSDynamicGrid = (props: any, ...rest: any) => {
  const muiCache = createCache({
    key: "mui",
    prepend: true,
  });

  const options = {
    pagination: false,
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: 0,
    rowsPerPage: 0,
    serverSide: false,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              border: "1px solid #dee2e6",
              outline: "none",
              boxShadow: "none",
              overflowX: "auto",
            },
          },
        },

        MUIDataTableToolbar: {
          styleOverrides: {
            root: {
              backgroundColor: "#87CEEB",
              color: "black",
            },
            icon: {
              color: "black",
              "&:hover": {
                color: "black",
              },
            },
            iconActive: {
              color: "black",
            },
          },
        },
        MUIDataTableSearch: {
          styleOverrides: {
            searchIcon: {
              color: "white",
            },
            searchText: {
              "& input": {
                color: "white",
              },
            },
            clearIcon: {
              color: "white",
            },
          },
        },
        MUIDataTableHeadCell: {
          styleOverrides: {
            root: {
              backgroundColor: "#87CEEB",
              color: "black",
              paddingTop: "4px",
              paddingBottom: "4px",
              fontFamily: "Segoe UI",
              fontSize: "12px",
            },
            sortActive: {
              color: "black",
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              fontFamily: "Segoe UI",
              fontSize: "12px",
              paddingTop: "8px",
              paddingBottom: "8px",
              border: "2px solid #000",
            },
          },
        },

        MuiTableBody: {
          styleOverrides: {
            root: {
              "&.emptyTitle": { fontFamily: "Segoe UI", fontSize: "12px" },
            },
          },
        },

        MUIDataTableSelectCell: {
          styleOverrides: {
            headerCell: {
              backgroundColor: "#3c3c3c",
              color: "white",
              fontFamily: "Segoe UI",
              fontSize: "12px",
            },
            sortActive: {
              color: "black",
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              fontFamily: "Segoe UI",
              fontSize: "12px",
            },
          },
        },
        MuiButtonBase: {
          styleOverrides: {
            root: {
              "& .MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon": {
                color: "black",
              },
            },
          },
        },
        MUIDataTableBodyRow: {
          styleOverrides: {
            root: {
              ":nth-of-type(0)": {
                borderBottom: props.showBorderLine ? "5px solid #dbd9d9" : "",
              },
              ":nth-of-type(1)": {
                borderBottom: props.showBorderLine ? "5px solid #dbd9d9" : "",
              },
              ":nth-of-type(2)": {
                borderBottom: props.showBorderLine ? "5px solid #dbd9d9" : "",
              },
              ":nth-of-type(12)": {
                borderBottom: props.showBorderLine ? "5px solid #dbd9d9" : "",
              },
              ":nth-of-type(24)": {
                borderBottom: props.showBorderLine ? "5px solid #dbd9d9" : "",
              },
            },
          },
        },
      },
    });

  return (
    <div className="px-3 course_inner">
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            className="text-align-center table_grid"
            title={props.title}
            data={props.data}
            columns={props.columns}
            options={options}
            components={props.components}
          />
        </ThemeProvider>
      </CacheProvider>
    </div>
  );
};

export default LMSDynamicGrid;
