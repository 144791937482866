import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import BaseModal from "../../Components/BaseModel/BaseModel";
import FormStructure from "../../Components/FromStructure/FormStructure";
import SelectForm from "../../Components/SelectForm/SelectForm";
import InputForm from "../../Components/InputForm/InputForm";
import "./GenericMasterDashboard.css";
import GenericMasterHistory from "./GenericMasterHistory";
import Collapse from "react-bootstrap/Collapse";
import ExcelJS from "exceljs";
import { APICall } from "../../Helpers/APICalls";
import {
  getGenericDashboard,
  getMasterTypeDD,
  getDropdowns,
  getSectionDetailsForPicklist,
} from "../../Helpers/APIEndPoints/EndPoints";
import { Tooltip } from "@mui/material";
import { LoaderContext } from "../../Helpers/Context/Context";
import { createFilter } from "react-select";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import notify from "../../Helpers/ToastNotification";
import { useLocation } from "react-router-dom";

const GenericMasterDashboard = (props) => {
  const { state } = useLocation();
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [gridData, setGridData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortDirection, setSortDirection] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [PageSize, setPageSize] = useState(10);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  let { userDetails, currentRoleId, sectionInterfacePermissionRecord }: any = useUserContext();
  const [masterType, setMasterType] = useState<any>(state?.masterType || []);
  const [statusValue, setStatusValue] = useState<any>([]);
  const [masterTypeOption, setMasterTypeOption] = useState([]);
  const [modifiedByValue, setModifiedByValue] = useState<any>(null);
  const [sectionOption, setSectionOption] = useState([]);
  const [sectionSelectedValues, setSectionSelectedValues] = useState<any>(null);
  const [isExportToExcel, setExportToExcel] = useState(false);
  const [resetReload, setResetReload] = useState(false);

  const [idForHistory, setIdForHistory] = useState(0);
  const [showHistoryModal, setShowHistoryModal] = useState(false);

  const statusDropdown = [
    { value: 0, label: 'Inactive'},
    { value: 1, label: 'Active'}
  ];

  useEffect(() => {
    getInterfaceDetails();
  }, []);
  //-------------------------------------------------

  const GetMasterDataDropdown = async () => {
    showLoader();
    try {
      const MTData = await APICall(getMasterTypeDD, "POST", {
        AC: "",
        PID: "",
      });

      if (MTData.data !== null && MTData.data.length > 0) {
        console.log("Master Type Data found!", MTData.data);
        let temp: any[] = [];
        MTData.data.map((d: any) => {
          let obj = {
            label: d.label,
            value: d.value,
          };
          temp.push(obj);
        });
        setMasterTypeOption(temp);
      } else {
        console.log("No Master Type data found!", MTData);
        //setTabs([]);
      }
    } catch (error) {
      console.error("", error);
    } finally {
      hideLoader();
    }
  };

  const GetSectionNames = async () => {
    showLoader();
    try {
      const SecData= await APICall(getSectionDetailsForPicklist, "POST", {
        AC: "",
        PID: "",
      });

      if (SecData.data !== null && SecData.data.length > 0) {
        setSectionOption(SecData.data);
      } else {
        console.log("No Master Type data found!", SecData);
      }
    } catch (error) {
      console.error("", error);
    } finally {
      hideLoader();
    }
  };
  
  const handleFetchUsers = async (event, callback, code, PID) => {
    try {
      if (event?.length > 3) {
        showLoader();
        const data = await getDropdownsData(code, PID, null, event, "");

        if (data && data?.length > 0) {
          callback(data);
        } else {
          callback([]);
        }
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  const getDropdownsData = async (
    AC,
    PID = null,
    cb,
    SearchText = "",
    FilterString = ""
  ) => {
    try {
      showLoader();

      const dropdownData = await APICall(getDropdowns, "POST", {
        AC,
        PID,
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
        SearchText,
        FilterString,
      });

      const data = dropdownData.data || [];

      return data;
    } catch (error) {
      console.error("Error in getDropdownsData:", error);
      return []; // Return an empty array in case of an error
    } finally {
      hideLoader();
    }
  };

  const getInterfaceDetails = async () => {
    showLoader();
    // ;
    const gridDataResponse = await APICall(
      "/api/Interface/GetInterfaceDetailsForGrid",
      "POST",
      {
        MN: "GenericMaster",
        IN: "GenericMaster_Dashboard",
        TN: "GenericMaster_Dashboard",
      }
    );

    if (gridDataResponse?.data?.d?.fa != null) {
      for await (const cFilter of gridDataResponse?.data?.d?.fa) {
        if (cFilter.filt === "multidropdown" && cFilter.filAN === "MasterType") {
          await GetMasterDataDropdown();
        }
      }
      for await (const cFilterAfa of gridDataResponse?.data?.d?.afa) {
        if (cFilterAfa.filt === "dropdown" && cFilterAfa.filAN === "sectionnames") {
          await GetSectionNames();
        } else if (cFilterAfa.filt === "searchabledropdown" && cFilterAfa.filAN === "modifiedby") {
        }
      }
    }
    setGridData(gridDataResponse);
    await getGridStructureTwo();
    hideLoader();
  };

  useEffect(() => {
    debugger;
    if (gridData?.data?.d?.s[0]?.columnData.length > 0) {
      getGridStructureTwo();
    }
  }, [
    page,
    searchText,
    sortDirection,
    sortColumn,
    masterType,
    statusValue,
    isExportToExcel,
    resetReload,
  ]);

  const getGridStructureTwo = async () => {
    debugger;
    showLoader();
    const GetDashboardData = await APICall(getGenericDashboard, "POST", {
      DashboardName: "GenericMaster",
      ///PageNumber: page + 1,
      PageNumber: isExportToExcel ? 0 : page + 1,
      PageSize: isExportToExcel ? 0 : PageSize,
      SortColumn: isExportToExcel ? "" : sortColumn,
      SortOrder: isExportToExcel ? "asc" : sortDirection,
      SearchText: isExportToExcel ? searchText : searchText,
      MasterTypeIds: masterType.map((s) => s.value).join(","),
      Status: statusValue.map((m)=> m.value).join(","),
      // SectionNames: sectionSelectedValues.map((sec)=> sec.label).join(","),
      SectionNames: sectionSelectedValues?.label || '',
      ModifiedByValues: modifiedByValue?.value || '',
      // ModifiedByValues: modifiedByValue.map((mbv)=> mbv.value).join(","),
    });

    if (isExportToExcel) {
      const responseData = GetDashboardData.data;

      if (!responseData || responseData.length === 0) {
        notify(1, "There is No data.");
        setExportToExcel(false);
        hideLoader();
        return;
      }

      const convertCamelCaseToWords = (camelCaseString) => {
        return camelCaseString
          .replace(/([a-z])([A-Z])/g, "$1 $2")
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      };
      let filteredHeaders = [];

      filteredHeaders = ["Master Type Code","Master Type", "Code", "Name", "Parent Picklist Code", "Parent Picklist Name", "Section", "Effective Date", "Last Modified By", "Status"];

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("GenericMaster");

      const headerRow = worksheet.addRow(
        filteredHeaders.map((header) => convertCamelCaseToWords(header))
      );
      headerRow.font = { bold: true };

      responseData.forEach((row) => {
        const dataRow = [];
        dataRow.push(row["masterTypeCode"]);
        dataRow.push(row["masterType"]);
        dataRow.push(row["code"]);
        dataRow.push(row["name"]);
        dataRow.push(row["parentCode"]);
        dataRow.push(row["parentName"]);
        dataRow.push(row["sectionname"]);
        dataRow.push(row["effectivedate"]);
        dataRow.push(row["lastmodifiedbyname"]);
        dataRow.push(row["isActive"] ? 'Active' : 'Inactive');
        worksheet.addRow(dataRow);
      });

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = `GenericMaster.xlsx`;
        a.click();

        URL.revokeObjectURL(url);
        setExportToExcel(false);
      });
    } else {
      if (GetDashboardData.data !== null && GetDashboardData.data.length > 0) {
        setCount(GetDashboardData.data[0].count);
        setRowData(GetDashboardData.data);
      } else {
        setRowData([]);
        setCount(0);
      }
    }

    hideLoader();
  };

  const edit = (value, tableMeta, url) => {
    debugger;
    const Id = tableMeta.rowData[0];
    navigate("/GenericMasterFormUpdate", {
      state: { Id },
    });
  };
  const view = (value, tableMeta, url) => {
    const Id = tableMeta.rowData[0];
    const isView = true;
    navigate("/GenericMasterFormUpdate", {
      state: { Id, isView },
    });
  };

  const historyFunction = (value, tableMeta, url) => {
    const Id = tableMeta.rowData[0];
    setIdForHistory(Id);
    setShowHistoryModal(true);
  };

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: PageSize,
    page: page,
    serverSide: true,
    onChangePage: async (page) => {
      setPage(page);
    },
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",
    onColumnSortChange: async (sortColumn, sortDirection) => {
      setPage(0);
      if (sortDirection === "asc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
    },
  };

  const columnsWithCustomRender = gridData?.data?.d?.s[0]?.columnData.map(
    (column) => {
      if (column.name === "isActive") {
        return {
          ...column,
          options: {
            ...column.options,
            customBodyRender: (value, tableMeta, updateValue) => {
              return <>{value ? "Active" : "Inactive"}</>;
            },
          },
        };
      }
      if (column.name === "action") {
        return {
          ...column,
          options: {
            ...column.options,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <>
                  <div className="centerStyles">
                    {
                      sectionInterfacePermissionRecord?.some(
                        (e) => e.sectionSystemName === "AddGenericMaster_Button" &&  (e.name === "View" || e.name === "Edit")
                      ) && (
                        <Tooltip title="Edit">
                          <i
                            style={{ marginRight: "0px", cursor: "pointer" }}
                            onClick={() => {
                              // navigate("/gridEdit")
                              edit(value, tableMeta, column.url);
                            }}
                            className="fas fa-edit"
                          ></i>
                        </Tooltip>
                      )
                    }
                    {
                      sectionInterfacePermissionRecord?.some(
                        (e) => e.sectionSystemName === "ViewForPicklist" &&  (e.name === "View" || e.name === "Edit")
                      ) && (
                        <Tooltip title="view">
                          <i 
                            style={{ marginRight: "0px", marginLeft: "5px",cursor: "pointer" }}
                            onClick={() => {
                              // navigate("/gridEdit")
                              view(value, tableMeta, column.url);
                            }}
                            className="fa-solid fa-eye"
                          ></i>
                        </Tooltip>
                      )
                    }
                    {
                      sectionInterfacePermissionRecord?.some(
                        (e) => e.sectionSystemName === "HistoryForPicklist" &&  (e.name === "View" || e.name === "Edit")
                      ) && (
                        <Tooltip title="History">
                          <i
                            style={{ marginRight: "0px", marginLeft: "5px",cursor: "pointer" }}
                            onClick={() => {
                              // navigate("/gridEdit")
                              historyFunction(value, tableMeta, column.url);
                            }}
                            className="fas fa-history"
                          ></i>
                        </Tooltip>
                      )
                    }
                  </div>
                </>
              );
            },
          },
        };
      }
      return column;
    }
  );

  const onFilterChange = (event, type) => {
    if (type === "sectionnames") {
      setSectionSelectedValues(event);
    } else if (type === "modifiedby"){
      setModifiedByValue(event);
    }
  };

  const resetFilters = () => {
    setSortColumn("");
    setSortDirection("");
    setSectionSelectedValues(null);
    setModifiedByValue(null);
    setResetReload(!resetReload);
  };

  const onSubmitFilter = () => {
    if ((sectionSelectedValues === null || sectionSelectedValues.length === 0) 
      && (modifiedByValue === null || modifiedByValue.length === 0)) {
      notify(1, "Select atleast one filter");
    } else {
      getGridStructureTwo();
    }
  };

  const onSearchFilter = (event, type) => {
    if (type === "Search") {
      setPage(0);
      setSearchText(event.target.value);
    } else if (type == "MasterType") {
      setPage(0);
      setMasterType(event);
      navigate('/GenericMaster', { state: { masterType: event } });
    } else {
      setPage(0);
      setStatusValue(event);
    }
  };

  const exportToExcel1 = async () => {
    setExportToExcel(true);
  };

  return (<>
    <div className="container-fluid"> 
      <div className="d-flex justify-content-between align-items-center my-3">
        <button
          onClick={() => navigate("/home")}
          className="btn btn-primary">
          <i className="fa-solid fa-arrow-left mr-1"></i> Back
        </button>
        <div className="d-flex align-items-center justify-content-end">
          {
            sectionInterfacePermissionRecord?.some(
              (e) => e.sectionSystemName === "Picklist_BulkUpload" &&  (e.name === "View" || e.name === "Edit")
            ) && (
              <button
                onClick={() => {
                  navigate("/PicklistBulkUpload", {});
                }}
                className="btn btn-primary mr-2"
              >
                <i className="fa fa-plus mr-2"></i>
                Bulk Upload
              </button>
            )
          }
          {
            sectionInterfacePermissionRecord?.some(
              (e) => e.sectionSystemName === "EditMasterType_Button" &&  (e.name === "View" || e.name === "Edit")
            ) && (
              <button
                onClick={() => {
                  navigate("/PicklistAndMasterMapForm", {});
                }}
                className="btn btn-primary mr-2"
              >
                <i className="fa fa-edit mr-2"></i>
                Edit Master Type
              </button>
            )
          }
          {
            sectionInterfacePermissionRecord?.some(
              (e) => e.sectionSystemName === "AddGenericMaster_Button" &&  (e.name === "View" || e.name === "Edit")
            ) && (
              <button
                onClick={() => {
                  debugger;
                  navigate("/GenericMasterForm", {
                    state: {
                      TId: 0,
                      COU: userDetails.COU,
                      showIsActiveColumn: userDetails.IsActive,
                    },
                  });
                }}
                className="btn btn-primary mr-2"
              >
                <i className="fa fa-plus mr-2"></i>
                Add Generic Master
              </button> 
            )
          }

          <button className="btn btn-primary" onClick={exportToExcel1}>
            Export to excel
          </button>
        </div>
      </div>
      <div className="grid-wrapper grid_mob">
        <div className="row mx-auto filtBox">
          {gridData &&
            gridData?.data?.d?.fa?.map((cFilter, index) => (
              <>
                {cFilter.filt === "multidropdown" ? (
                  <>
                    <div className="col-lg-3 col-md-6 col-sm-12 ">
                      <div className="form-group filtInp">
                        <span
                          className="mr-2 text-white"
                          style={{ float: "left" }}
                        >
                          <label className="col-form-label">
                            {cFilter.filn}
                          </label>
                        </span>
                        <SelectForm
                          isClearable
                          options={
                            cFilter.filAN == "MasterType" 
                              ? masterTypeOption
                              : statusDropdown
                          }
                          placeholder={cFilter.filn}
                          isDisabled={false}
                          onChange={(event) => {
                            //onFilterChange(event, cFilter.filAN);
                            onSearchFilter(event, cFilter.filAN);
                          }}
                          isMulti={true}
                          noIndicator={false}
                          noSeparator={false}
                          value={
                            cFilter.filAN == "MasterType" 
                              ? masterType
                              : statusValue
                          }
                        />
                      </div>
                    </div>
                  </>
                ) : cFilter.filt == "searchabledropdown" ? (
                  <>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="form-group filtInp">
                        <span
                          className="mr-2 text-white"
                          style={{ float: "left" }}>
                          <label className="col-form-label">
                            {cFilter.filn}
                          </label>
                        </span>
                        <SelectForm
                          isClearable
                          async
                          options={(searchString, cb) =>
                            handleFetchUsers(
                              searchString,
                              cb,
                              cFilter.filAN,
                              null
                            )
                          }
                          placeholder={cFilter.filn}
                          isDisabled={false}
                          onChange={(event) => {
                            onSearchFilter(event, cFilter.filAN);
                          }}
                          isMulti={
                            // activetab === "Manage Transactions" &&
                            // cFilter.filAN === "initiator"
                            //   ? true
                            //   : 
                            false
                          }
                          noIndicator={false}
                          noSeparator={false}
                          value={modifiedByValue}
                        />
                      </div>
                    </div>
                  </>
                ) : cFilter.filt === "text" ? (
                  <div className="col-lg-3 col-md-6 col-sm-12  ">
                    <div className="form-group filtInp">
                      <InputForm
                        value={cFilter.filn === "Search" ? searchText : ""}
                        placeholder={cFilter.filn}
                        isDisabled={false}
                        textArea={false}
                        onChange={(e) => {
                          onSearchFilter(e, cFilter.filn);
                        }}
                      />
                    </div>
                  </div>
                ) : null}
              </>
            ))
          }
          <div className="col-lg-1 col-md-1 col-sm-12 filtrt">
            <ul className=" filter-icon-wel">
              <li>
                <a
                  href="javascript:void(0);"
                  onClick={() => setOpen(!open)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                  className="filter-btn"
                >
                  <i className="fa fa-filter"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="col-lg-12 col-md-10 col-sm-12 "></div>

      <>
        <Collapse in={open}>
          <div id="example-collapse-text grid-wrapper">
            <div className="row mx-auto myInlineStyle">
              {gridData &&
                gridData?.data?.d?.afa.map((cFilter, index) => (
                  <>
                    {cFilter.filt === "dropdown" ? (
                      <>
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                          {/* <label className="col-form-label text-white">
                            {cFilter.filn}
                          </label> */}
                          <span
                            className="mr-2 text-white"
                            style={{ float: "left" }}>
                            <label className="col-form-label">
                              {cFilter.filn}
                            </label>
                          </span>
                          <SelectForm
                            isClearable
                            options={
                              cFilter.filAN == "sectionnames" 
                              ? sectionOption
                              : []
                            }
                            placeholder={cFilter.filn}
                            isDisabled={false}
                            onChange={(event) => {
                              onFilterChange(event, cFilter.filAN);
                            }}
                            isMulti={false}
                            noIndicator={false}
                            noSeparator={false}
                            value={
                              cFilter.filAN == "sectionnames" 
                              ? sectionSelectedValues
                              : []
                            }
                          />
                        </div>
                      </>
                    ) : cFilter.filt === "multidropdown" ? (
                      <>
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                          {/* <label className="col-form-label text-white">
                            {cFilter.filn}
                          </label> */}
                          <span
                            className="mr-2 text-white"
                            style={{ float: "left" }}>
                            <label className="col-form-label">
                              {cFilter.filn}
                            </label>
                          </span>
                          <SelectForm
                            isClearable
                            options={
                              cFilter.filAN == "sectionnames" 
                              ? sectionOption
                              : []
                            }
                            placeholder={cFilter.filn}
                            isDisabled={false}
                            onChange={(event) => {
                              onFilterChange(event, cFilter.filAN);
                            }}
                            isMulti={true}
                            noIndicator={false}
                            noSeparator={false}
                            value={
                              cFilter.filAN == "sectionnames" 
                              ? sectionSelectedValues
                              : []
                            }
                          />
                        </div>
                      </>
                    ) : cFilter.filt == "searchabledropdown" ? (
                      <>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <div className="form-group filtInp">
                            <span
                              className="mr-2 text-white"
                              style={{ float: "left" }}>
                              <label className="col-form-label">
                                {cFilter.filn}
                              </label>
                            </span>
                            <SelectForm
                              isClearable
                              async
                              options={(searchString, cb) =>
                                handleFetchUsers(
                                  searchString,
                                  cb,
                                  cFilter.filAN,
                                  null
                                )
                              }
                              placeholder={cFilter.filn}
                              isDisabled={false}
                              onChange={(event) => {
                                onFilterChange(event, cFilter.filAN);
                              }}
                              isMulti={
                                // activetab === "Manage Transactions" &&
                                // cFilter.filAN === "initiator"
                                //   ? true
                                //   : 
                                false
                              }
                              noIndicator={false}
                              noSeparator={false}
                              value={modifiedByValue}
                            />
                          </div>
                        </div>
                      </>
                    ) : cFilter.filt === "text" ? (
                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                        <div className="form-group filtInp">
                          <InputForm
                            value={cFilter.filn === "Search" ? searchText : ""}
                            placeholder={cFilter.filn}
                            isDisabled={false}
                            textArea={false}
                            onChange={(e) => {
                              onSearchFilter(e, cFilter.filn);
                            }}
                          />
                        </div>
                      </div>
                    ) : null}
                  </>
                ))}

              <div className="col-lg-12 d-flex mt-2 justify-content-end">
                <button
                  onClick={resetFilters}
                  className="btn btn-secondary mr-2"
                >
                  <i className="fa fa-undo" aria-hidden="true"></i> Reset{" "}
                </button>
                <button
                  className="btn btn-filter-submit"
                  onClick={onSubmitFilter}
                >
                  <i className="fa fa-check" aria-hidden="true"></i> Submit{" "}
                </button>
              </div>
            </div>
          </div>
        </Collapse>
      </>
      {}
      <div className="mb-3 pt-3">
        <DynamicGrid
          data={rowData}
          columns={columnsWithCustomRender}
          options={options}
        />
      </div>
    </div>

    {/* Show Modal Pop-up For History */}
    {
      idForHistory != 0 &&
      showHistoryModal &&
      <Modal
        show={showHistoryModal}
        onHide={() => {
          setShowHistoryModal(false);
          setIdForHistory(0);
        }}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          History!
        </Modal.Header>
        <Modal.Body>
          <GenericMasterHistory TId={idForHistory} />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn customButtonCancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowHistoryModal(false);
              setIdForHistory(0);
            }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    }
  </>);
};

export default GenericMasterDashboard;
