import React, { useState, useEffect, useContext } from "react";
import InputForm from "../InputForm/InputForm";
import { APICall } from "../../Helpers/APICalls";
import { GetRehiredCandidateDetails } from "../../Helpers/APIEndPoints/AssociateEndPoints";
import moment from "moment";

const RehireCandidateDetails = ({ eId, ...rest }) => {
  const [rehiredCandidateData, setRehiredCandidateData] = useState<any>({});

  useEffect(() => {
    if (eId) {
      const getRehiredEmployeeData = async () => {
        let getRehiredEmployeeDetails = await APICall(
          GetRehiredCandidateDetails,
          "POST",
          {
            PreviousEmployeeId: eId,
          }
        );

        if (
          getRehiredEmployeeDetails &&
          getRehiredEmployeeDetails.status === 0
        ) {
          if (getRehiredEmployeeDetails.data) {
            let lastWorkingDate = null;
            let groupJoiningDate = null;

            if (moment(getRehiredEmployeeDetails?.data?.lwd)?.isValid()) {
              lastWorkingDate = moment(
                getRehiredEmployeeDetails?.data?.lwd
              ).format("DD-MM-YYYY");
            }

            if (moment(getRehiredEmployeeDetails?.data?.gjd)?.isValid()) {
              groupJoiningDate = moment(
                getRehiredEmployeeDetails?.data?.gjd
              ).format("DD-MM-YYYY");
            }

            setRehiredCandidateData({
              business: getRehiredEmployeeDetails.data.business || "",
              sbu: getRehiredEmployeeDetails.data.sbu || "",
              legalEntity: getRehiredEmployeeDetails.data.legalEntity || "",
              employeeClass: getRehiredEmployeeDetails.data.employeeClass || "",
              payrollLocation:
                getRehiredEmployeeDetails.data.payrollLocation || "",
              designation: getRehiredEmployeeDetails.data.designation || "",
              gjd: groupJoiningDate,
              lwd: lastWorkingDate,
              ctc: getRehiredEmployeeDetails.data.ctc || "",
            });
          }
        }
      };

      getRehiredEmployeeData();
    }
  }, [eId]);

  return (
    <div>
      <div className="row">
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1">
            <label className="col-form-label">Business</label>
            <InputForm
              className="form-control"
              placeholder={"Business"}
              isDisabled={true}
              textArea={false}
              value={rehiredCandidateData.business}
              onChange={(val: any) => {}}
            />
          </div>
        </div>
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1">
            <label className="col-form-label">SBU</label>
            <InputForm
              className="form-control"
              placeholder={"SBU"}
              isDisabled={true}
              textArea={false}
              value={rehiredCandidateData.sbu}
              onChange={(val: any) => {}}
            />
          </div>
        </div>
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1">
            <label className="col-form-label">Legal Entity</label>
            <InputForm
              className="form-control"
              placeholder={"Legal Entity"}
              isDisabled={true}
              textArea={false}
              value={rehiredCandidateData.legalEntity}
              onChange={(val: any) => {}}
            />
          </div>
        </div>
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1">
            <label className="col-form-label">Payroll Location</label>
            <InputForm
              className="form-control"
              placeholder={"Payroll Location"}
              isDisabled={true}
              textArea={false}
              value={rehiredCandidateData.payrollLocation}
              onChange={(val: any) => {}}
            />
          </div>
        </div>
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1">
            <label className="col-form-label">Employee Class</label>
            <InputForm
              className="form-control"
              placeholder={"Employee Class"}
              isDisabled={true}
              textArea={false}
              value={rehiredCandidateData.employeeClass}
              onChange={(val: any) => {}}
            />
          </div>
        </div>
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1">
            <label className="col-form-label">Designation</label>
            <InputForm
              className="form-control"
              placeholder={"Designation"}
              isDisabled={true}
              textArea={false}
              value={rehiredCandidateData.designation}
              onChange={(val: any) => {}}
            />
          </div>
        </div>
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1">
            <label className="col-form-label">Group Joining Date</label>
            <InputForm
              className="form-control"
              placeholder={"Group Joining Date"}
              isDisabled={true}
              textArea={false}
              value={rehiredCandidateData.gjd}
              onChange={(val: any) => {}}
            />
          </div>
        </div>
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1">
            <label className="col-form-label">Last Working Date</label>
            <InputForm
              className="form-control"
              placeholder={"Last Working Date"}
              isDisabled={true}
              textArea={false}
              value={rehiredCandidateData.lwd}
              onChange={(val: any) => {}}
            />
          </div>
        </div>
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1">
            <label className="col-form-label">CTC</label>
            <InputForm
              className="form-control"
              placeholder={"CTC"}
              isDisabled={true}
              textArea={false}
              value={rehiredCandidateData.ctc}
              onChange={(val: any) => {}}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RehireCandidateDetails;
