import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import InputForm from "../../Components/InputForm/InputForm";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import SelectForm from "../../Components/SelectForm/SelectForm";
import { Tooltip } from "@mui/material";
import { LoaderContext } from "../../Helpers/Context/Context";
import { APICall } from "../../Helpers/APICalls";
import {
   getOUProgressionDashboard,
  // GetCriticalDataDashboardDropdowns,
} from "../../Helpers/APIEndPoints/EndPoints";
// import CriticalDataHistory from "./CriticalDataHistory";
import OrganizationUnitProgressionEdit from "./OrganizationUnitProgressionEdit";
import { useDebounce } from "../../utils/useDebounce";
import moment from "moment";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";


interface IFormData {
  OUType ?: string | any,
  OUCode ?: string | any,
  OUName ?: string | any,
  EffectiveDate ?: string |any,
  IsSync ?: string | any,
  ModifiedBy ?: string | any,
  ModifiedDate ?: string | any,
  Status ?: string | any,
  IsMultiplevelOu ? : string | any,
  MasterTypeId ?: string | any,
}

const OrganizationUnitProgressionDashboard = () => {
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [editid, seteditid] = useState(null);
  const [datahistory, setDataHistory] = useState("");
  const [ouProgressionForm, setOuProgressionForm] = useState("");
  const [ouformdata, setOuformdata] = useState<IFormData>({})
  const [ouProgressionGridData, setOuProgressionGridData] = useState({
    count: 0,
    start: 0,
    pageSize: 10,
    searchText: "",
    sortColumn: "",
    sortDirection: "",
    data: [],
  });
  const handleAction = (event, tableMeta) => {
    debugger
    if (event === "critical_edit" || event === "critical_view") {
      setOuProgressionForm(event);
    } else if (event === "critical_history") {
      setDataHistory(event);
    }
    seteditid(tableMeta.rowData[0]);
    setOuformdata((prev) => {
      console.log("hello",tableMeta.tableData[tableMeta.rowIndex][9]); 
      return {
        ...prev,
        Id : {label: tableMeta.tableData[tableMeta.rowIndex][0]},
        OUName:{label: tableMeta.rowData[1]},
        OUCode :{label: tableMeta.rowData[2]},
        OUType :{label: tableMeta.rowData[3]},
        EffectiveDate :{label: tableMeta.rowData[4]},
        IsSync :{label: tableMeta.rowData[5]},
        Status : {label: tableMeta.rowData[8], value:tableMeta.rowData[8]==="Active"?1:0},
        IsMultiplevelOu : {label:tableMeta.tableData[tableMeta.rowIndex][9]===true?true:false},
        MasterTypeId : {label:tableMeta.tableData[tableMeta.rowIndex][10]},
      };
    });
  };
  
  const ouDataGridOptions = {
    count: ouProgressionGridData.count,
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    rowsPerPage: ouProgressionGridData.pageSize,
    rowsPerPageOptions: [],
    page: ouProgressionGridData.start,
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setOuProgressionGridData((prev) => {
          return {
            ...prev,
            sortColumn: sortColumn,
            sortDirection: sortDirection,
          };
        });
      }
      if (sortDirection === "desc") {
        await setOuProgressionGridData((prev) => {
          return {
            ...prev,
            sortColumn: sortColumn,
            sortDirection: sortDirection,
          };
        });
      }
    },
    onChangePage: async (page) => {
      setOuProgressionGridData((prev) => {
        return { ...prev, start: page };
      });
    },
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",
    serverSide: true,
  };
  const ouProgressionDataGridColumns = [
    {
      name: "id",
      label: "Sr No.",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "ouType",
      label: "OU Type (Orgnization Unit Type)",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "oucode",
      label: "OU Code (Orgnization Unit Code)",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "name",
      label: "OU Name (Orgnization Unit Name)",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <div className="d-flex justify-content-start">
              <p style={{ textAlign: "center" }}>{value}</p>
            </div>
          );
        },
      },
    },
    {
      name: "effectiveDate",
      label: "Effective Date",
      options: {
        filter: true,
        sort: true,
        // customBodyRender:(value) =>{
        //   return (
        //     <div>
        //       <p>{moment(value).format('DD/MM/YYYY')}</p>
        //     </div>
        //   )
        // }
      },
    },
    {
      name: "isSync",
      label: "IsSync",
      options: {
        filter: true,
        sort: true,
        // customBodyRender: (value) => {
        //   return (
        //     <div className="d-flex justify-content-start">
        //       <p>{value === true ? "Synced":"Pending"}</p>
        //     </div>
        //   );
        // },
      },
    },
    {
      name: "modifiedByUser",
      label: "Modified By",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      // name: "createdDateUtc",
      name: "modifiedDateString",
      label: "Modified Date",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        // customBodyRender: (value, tableMeta, updateValue) => {
        //   return (
        //     <div className="d-flex justify-content-start">
        //       <p>{value === true ? "Active":"Inactive"}</p>
        //     </div>
        //   );
        // },
      },
    },
    {
      name: "isMultiLevelOu",
      label: "IsMultiLevelOu",
      options: {
        filter: true,
        sort: true,
        display : false,
         customBodyRender: (value) => {
           return (
             <div className="d-flex justify-content-start">
               <p>{value === true ? "True":"False"}</p>
             </div>
           );
         },
      },
    },
    {
      name: "masterTypeId",
      label: "MasterTypeId",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "Action",
      label: "Action",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="d-flex justify-content-start">
              {
                <>
                  <Tooltip title="Edit">
                    <i
                      style={{
                        marginRight: "10px",
                        cursor: "pointer",
                        fontSize: "medium",
                      }}
                      onClick={() => handleAction("critical_edit", tableMeta)}
                      className="fas fa-edit"
                    ></i>
                  </Tooltip>{" "}
                  {/* <Tooltip title="View">
                    <i
                      style={{
                        marginRight: "10px",
                        cursor: "pointer",
                        fontSize: "medium",
                      }}
                      onClick={() => handleAction("critical_view", tableMeta)}
                      className="fas fa-eye"
                    ></i>
                  </Tooltip>{" "} */}
                 
                </>
              }
            </div>
          );
        },
      },
    },
  ];
  const debouncedValue = useDebounce(ouProgressionGridData?.searchText);
  const getOuProgressionDashboardGridStructure = async (page) => {
    showLoader();
    try {
      const dashboardData = await APICall(getOUProgressionDashboard, "POST", {
        PageNumber: page ?? ouProgressionGridData.start,
        PageSize: ouProgressionGridData.pageSize,
        SortColumn: ouProgressionGridData.sortColumn || "",
        SortOrder: ouProgressionGridData.sortDirection || "",
        SearchText: ouProgressionGridData?.searchText || "",
      });
      const { data } = dashboardData || {};
      if ((data || []).length > 0) {
        setOuProgressionGridData((prev) => ({
          ...prev,
          count: data[0].count,
          data,
        }));
      } else {
        setOuProgressionGridData((prev) => ({
          ...prev,
          count: 0,
          data: [],
        }));
      }
    } catch (error) {
      console.error("Error in fetching OU Progression dashboard data", error);
    } finally {
      hideLoader();
    }
  };
  // const getSearchableDropdownAPI = async (type) => {
  //   showLoader();
  //   try {
  //     const dropdowndashboardData = await APICall(
  //       GetCriticalDataDashboardDropdowns,
  //       "POST",
  //       {
  //         Type: type,
  //       }
  //     );
  //     const { data } = dropdowndashboardData || {};
  //     if ((data || []).length > 0) {
  //       if (type === "Country") {
  //         setCriticalDashboardFilterDropdown((prev) => ({
  //           ...prev,
  //           countryoption: data,
  //         }));
  //       } else {
  //         setCriticalDashboardFilterDropdown((prev) => ({
  //           ...prev,
  //           sectionoption: data,
  //         }));
  //       }
  //     } else {
  //       return null;
  //     }
  //   } catch (error) {
  //     console.error("Error in fetching Dropdown for filters data", error);
  //   } finally {
  //     hideLoader();
  //   }
  // };
  // useEffect(() => {
  //   getSearchableDropdownAPI("Country");
  //   getSearchableDropdownAPI("Section");
  // }, []);

   useEffect(() => {

     getOuProgressionDashboardGridStructure(null);
   }, [
     ouProgressionGridData.start,
      ouProgressionGridData.sortColumn,
      ouProgressionGridData.sortDirection,
   ]);

  
   useEffect(() => {
     getOuProgressionDashboardGridStructure(0);
   }, [debouncedValue,ouProgressionForm]);
   
  return (
    <div>
      <div className="row mt-1 mx-3">
        <div className="col-lg-12 my-3">
          <div className="d-flex align-items-center justify-content-end">
            <button
              className="btn btn-primary mr-2"
              onClick={() => {
                if (ouProgressionForm) {
                    setOuProgressionForm("");
                } else {
                  navigate(-1);
                }
              }}
            >
              <i className="fa-solid fa-arrow-left mr-2"></i>
              Back
            </button>
          </div>
        </div>
      </div>
      <div
        className="customized-Grid-Wrapper grid-wrapper grid_mob"
        style={{ marginLeft: "15px", marginRight: "15px" }}
      >
        <div className="row mx-auto filtBox">
          {!ouProgressionForm && (
            <>
              <div className="col-lg-3 col-md-3 col-sm-12">
                <div className="form-group filtInp">
                  <InputForm
                    value={ouProgressionGridData?.searchText || ""}
                    placeholder={"Search"}
                    isDisabled={false}
                    textArea={false}
                    onChange={(e) =>
                      setOuProgressionGridData((prev) => ({
                        ...prev,
                        searchText: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="mt-2">
        {!ouProgressionForm && (
          <DynamicGrid
            options={ouDataGridOptions}
            data={ouProgressionGridData.data || []}
            columns={ouProgressionDataGridColumns}
          />
        )}
        {ouProgressionForm && (
           <OrganizationUnitProgressionEdit
           ouformdata={ouformdata}
           setOuformdata={setOuformdata}
             formtype={ouProgressionForm}
             setformtype={setOuProgressionForm}
             id={editid || 0}
           />
        )}
      </div>
    </div>
  );
};

export default OrganizationUnitProgressionDashboard;
