import React, { useState, useEffect } from "react";
import "../Home/Home.css";
import DateForm from "../../Components/DateForm/DateForm";
import "./ReportingDashboard.css";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import AccessDenied from "../AccessDenied/AccessDenied";
import { Link } from "react-router-dom";
import { APICall } from "../../Helpers/APICalls";
import { GetAssociateCounts } from "../../Helpers/APIEndPoints/AssociateEndPoints";
import SelectForm from "../../Components/SelectForm/SelectForm";
import WillLogo from "../../Assets/Images/WLL GPTW Logo1.png";
import Report from "../Report/Report";

const ReportingDashboard = () => {
  let { userDetails, interfacePermissionRecord, currentRoleId }: any =
    useUserContext();

  const [formData, setFormData] = useState<IFormData>({ dob: new Date() });
  const [candidateposition, setCandidatePosition] = useState<any>(null);
  const [locationOption, setlocationOption] = useState<any>([
    {
      value: "Anjar",
      label: "Anjar",
    },
  ]);

  const [location, setlocation] = useState<any>(null);

  interface IFormData {
    dob?: Date | string | boolean | any;
  }

  const today = new Date(); // Get today's date
  const yesterday = new Date(today); // Create a new date object with today's date
  let yest1 = yesterday.setDate(today.getDate() - 1);

  function onChange(event, code) {
    try {
      setFormData((prev) => {
        return { ...prev, [code]: event };
      });
    } catch (error) {
    } finally {
    }
  }

  useEffect(() => {
    if (formData?.dob) {
      getcandidateCount();
    }
  }, [formData]);

  useEffect(() => {
    setlocation(locationOption[0]);
  }, []);

  const getcandidateCount = async () => {
    const response = await APICall(GetAssociateCounts, "POST", {
      Date: formData?.dob,
    });
    if (response?.data !== null) {
      console.log(response, "Candidateresource");
      setCandidatePosition(response?.data);
    }
  };
  return (
    <>
      {/* <div className="form-main">
        <div className="page-title w-100">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-2 col-md-4 p-2">
                <SelectForm
                  value={location}
                  placeholder={"Select Location"}
                  options={locationOption}
                  onChange={""}
                  isDisabled={true}
                  noIndicator={false}
                  noSeparator={false}
                />
              </div>

              <div
                className="col-lg-8 col-md-4 p-2"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <h3 style={{ color: "white" }}>Welspun Living Limited</h3>
              </div>

              <div className="col-lg-2 col-md-4 p-2 text-right">
                <img src={WillLogo} style={{ width: "35px" }} />
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="form-main">
        <div className="">
          <div className="col-lg-12">
            <div className="row">
              <div
                className="col-lg-12 col-md-4 p-2 ml-2"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                {/* <h4>Security Screening</h4> */}
                <h4>Reports</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      {currentRoleId?.label === "Associate Recruitment" ? (
        <div className="container-fluid">
          <div className="row quick_link blueCollarLinks">
            {interfacePermissionRecord?.find(
              (e) => e.interfaceName === "Departmentwise"
            ) && (
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <Link
                  to="/Departmentwise"
                  style={{ pointerEvents: "auto" }}
                  // onClick={handleReportClick}
                >
                  <div
                    className="quicklinks_grid"
                    data-toggle="tooltip"
                    title=""
                  >
                    <div className="imgText">
                      <img
                        className="img-grid"
                        src={require("../../Assets/Images/book.png")}
                        alt="Candidate Status"
                      />
                      <p className="text-grid">Department Report</p>
                    </div>
                  </div>
                </Link>
              </div>
            )}

            {interfacePermissionRecord?.find(
              (e) => e.interfaceName === "TATLogsheet"
            ) && (
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <Link
                  to="/Logsheet"
                  style={{ pointerEvents: "auto" }}
                  // onClick={handleReportClick}
                >
                  <div
                    className="quicklinks_grid"
                    data-toggle="tooltip"
                    title=""
                  >
                    <div className="imgText">
                      <img
                        className="img-grid"
                        src={require("../../Assets/Images/book.png")}
                        alt="Candidate Status"
                      />
                      <p className="text-grid">TAT Status Report</p>
                    </div>
                  </div>
                </Link>
              </div>
            )}

            {interfacePermissionRecord?.find(
              (e) => e.interfaceName === "DailyRecruitment"
            ) && (
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <Link
                  to="/DailyRecruitment"
                  style={{ pointerEvents: "auto" }}
                  // onClick={handleReportClick}
                >
                  <div
                    className="quicklinks_grid"
                    data-toggle="tooltip"
                    title=""
                  >
                    <div className="imgText">
                      <img
                        className="img-grid"
                        src={require("../../Assets/Images/book.png")}
                        alt="Candidate Status"
                      />
                      <p className="text-grid">DailyRecruitment Report</p>
                    </div>
                  </div>
                </Link>
              </div>
            )}

            {interfacePermissionRecord?.find(
              (e) => e.interfaceName === "RejectedCandidate"
            ) && (
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <Link
                  to="/RejectedCandidate"
                  style={{ pointerEvents: "auto" }}
                  // onClick={handleReportClick}
                >
                  <div
                    className="quicklinks_grid"
                    data-toggle="tooltip"
                    title=""
                  >
                    <div className="imgText">
                      <img
                        className="img-grid"
                        src={require("../../Assets/Images/book.png")}
                        alt="Candidate Status"
                      />
                      <p className="text-grid">Rejected Candidate Report</p>
                    </div>
                  </div>
                </Link>
              </div>
            )}

            {interfacePermissionRecord?.find(
              (e) => e.interfaceName === "DailyConsolidate"
            ) && (
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <Link
                  to="/DailyConsolidate"
                  style={{ pointerEvents: "auto" }}
                  // onClick={handleReportClick}
                >
                  <div
                    className="quicklinks_grid"
                    data-toggle="tooltip"
                    title=""
                  >
                    <div className="imgText">
                      <img
                        className="img-grid"
                        src={require("../../Assets/Images/book.png")}
                        alt="Candidate Status"
                      />
                      <p className="text-grid">
                        Daily Consolidate Recruitment Report
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            )}
            {interfacePermissionRecord?.find(
              (e) => e.interfaceName === "AadharValidation"
            ) && (
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <Link
                  to="/AadharValidation"
                  style={{ pointerEvents: "auto" }}
                  // onClick={handleReportClick}
                >
                  <div
                    className="quicklinks_grid"
                    data-toggle="tooltip"
                    title=""
                  >
                    <div className="imgText">
                      <img
                        className="img-grid"
                        src={require("../../Assets/Images/book.png")}
                        alt="Candidate Status"
                      />
                      <p className="text-grid">Aadhaar Validation Report</p>
                    </div>
                  </div>
                </Link>
              </div>
            )}

            {interfacePermissionRecord?.find(
              (e) => e.interfaceName === "UANReport"
            ) && (
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12 mt-3">
                <Link
                  to="/UANReport"
                  style={{ pointerEvents: "auto" }}
                  // onClick={handleReportClick}
                >
                  <div
                    className="quicklinks_grid"
                    data-toggle="tooltip"
                    title=""
                  >
                    <div className="imgText">
                      <img
                        className="img-grid"
                        src={require("../../Assets/Images/book.png")}
                        alt="Candidate Status"
                      />
                      <p className="text-grid">UAN Report</p>
                    </div>
                  </div>
                </Link>
              </div>
            )}
            {interfacePermissionRecord?.find(
              (e) => e.interfaceName === "MedicalPreEmploymentReport"
            ) && (
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12 mt-3">
                <Link
                  to="/MedicalPreEmploymentReport"
                  style={{ pointerEvents: "auto" }}
                  // onClick={handleReportClick}
                >
                  <div
                    className="quicklinks_grid"
                    data-toggle="tooltip"
                    title=""
                  >
                    <div className="imgText">
                      <img
                        className="img-grid"
                        src={require("../../Assets/Images/book.png")}
                        alt="Candidate Status"
                      />
                      <p className="text-grid">Medical PreEmployment Report</p>
                    </div>
                  </div>
                </Link>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>
          <AccessDenied />
        </div>
      )}
    </>
  );
};

export default ReportingDashboard;
