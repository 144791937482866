import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import { useState } from "react";

export const CustomAccordian = ({
  header,
  disabled,
  submitCallback,
  clearFunc,
  defaultAccordianValue = false,
  children,
  hideBtn,
  hideAccordian = false,
}) => {
  const [accordion, setAccordian] = useState(defaultAccordianValue);
  return (
    <>
      {hideAccordian ? (
        <>{children}</>
      ) : (
        <>
          <Accordion
            elevation={0}
            className="mb-3"
            expanded={accordion}
            onChange={() => {
              setAccordian((prev) => !prev);
            }}
          >
            <AccordionSummary
              id="profile-accordion"
              style={{ background: "#3C5464" }}
              className="text-white acc_close"
              expandIcon={<ExpandMore />}
            >
              <div className="row d-flex align-items-center acc-heading">
                <div className="col-lg-10 col-md-10 col-sm-12">
                  <p style={{ fontSize: "16px" }}>{header}</p>
                </div>
                <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12">
                  {!hideBtn && (
                    <>
                      <Tooltip title="clear">
                        <button
                          disabled={disabled}
                          className="btn ml-lg-0 ml-md-0 ml-1"
                          onClick={clearFunc}
                        >
                          <i className="fas fa-times-circle"></i>
                        </button>
                      </Tooltip>
                      <Tooltip title="submit">
                        <button
                          className="btn"
                          disabled={disabled}
                          onClick={submitCallback}
                        >
                          <i className="fa fa-save"></i>
                        </button>
                      </Tooltip>
                    </>
                  )}
                </div>
              </div>
            </AccordionSummary>

            <AccordionDetails className="page_heading">
              {children}
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </>
  );
};
