import React, { useContext, useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import InputForm from "../../../Components/InputForm/InputForm";
import { APICall } from "../../../Helpers/APICalls";
import { LoaderContext } from "../../../Helpers/Context/Context";
import {
  GetNaukriDropdownOption,
  GetInfoLabelForAttributeOfNaukri,
  GetJobPostingDetailsByMRFIdAndType,
  InsertUpdateNaukriPostingDetails,
  GetSavedNaukriEducationDetails,
  GenericMasterDataByParentId,
  MRFDepublishNaukriJob,
  MRFRepublishNaukriJob,
} from "../../../Helpers/APIEndPoints/EndPoints";
import notify from "../../../Helpers/ToastNotification";
import useUserContext from "../../../Helpers/ZustandStore/UserContextStore";

import {
  INaukriDropdownOptions,
  INaukriInterface,
  Dropdown,
  NaurkiFieldsValidation,
  CustomApproverAccordian,
  isEmpty,
  ConfirmationModal,
} from "./ApproverHelper";
import DynamicGrid from "../../../Components/DynamicGrid/DynamicGrid";
import uuid from "react-uuid";
import RTE from "../../../Components/RTE/RTE";

const NaukriColumns = ({
  data,
  setData,
  disabled = false,
  formErrors,
  setFormErrors,
  MRFId,
  postingStatus = false,
  setRefreshKey,
  isDepublished = false,
}: {
  data?: INaukriInterface;
  setData?: any;
  disabled?: boolean;
  formErrors?: any;
  setFormErrors?: any;
  MRFId: number;
  postingStatus: boolean;
  setRefreshKey: any;
  isDepublished: boolean;
}) => {
  const { showLoader, hideLoader } = useContext(LoaderContext);
  let { userDetails, currentRoleId }: any = useUserContext();
  const onlyNumbericRegex = /^\d+$/;

  const [tooltipData, setToolTipData] = useState<INaukriInterface>({});
  const [triggerToJobPostingDetails, setTriggerToFetchJobPostingDetails] =
    useState(false);
  const [dropdownOptions, setDropdownOptions] =
    useState<INaukriDropdownOptions>({
      JobTypeOptions: [],
      IndustryOptions: [],
      FunctionalAreaOptions: [],
      ShowSalaryOptions: [],
      SalaryCurrenyOptions: [],
      DegreeOptions: [],
      QualificationOptions: [],
      SpecializationOptions: [],
      DiversityOptions: [],
      WorkmodeOptions: [],
      EmploymentTypeOptions: [],
      OrgNameOptions: [],
      WebsiteOptions: [],
      KeySkillsOptions: [],
      PhysicalLocationOptions: [],
    });
  const [educationData, setEducationData] = useState([]);
  const [tempEducationId, setTempEducationId] = useState(null);
  const [disableInsertBtn, setDisableInsertBtn] = useState(false);
  const [depublishModal, setDepublishModal] = useState(false);

  const gridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    rowsPerPage: 5,
    serverSide: false,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",
    //standard | vertical | simple
    onChangeRowsPerPage: (num) => {},
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
      }
      if (sortDirection === "desc") {
      }
    },
    onChangePage: async (page) => {},
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        GetInfoLabelForAttributeOfNaukriAsync(),
        GetDropdownOptionsAsync(),
      ]);
      setFormErrors({});
    };
    fetchData();
  }, []);

  //CALLED SEPRATE USEFFECT BECAUSE IT WAS NOT ABLE TO FIND
  // VALUES IN DROPDOWN OPTIONS AND STATE WAS NOT SET AT THAT TIME
  useEffect(() => {
    const helper = async () => {
      await Promise.all([
        GetJobPostingDetailsByMRFIdAndTypeAsync(),
        GetEducationSavedData(),
      ]);
    };
    helper();
  }, [triggerToJobPostingDetails]);

  useEffect(() => {
    if (!isEmpty(tempEducationId)) {
      //let obj = educationData?.find((item) => item.id == tempEducationId);
      // //GET OPTIONS
      // // FIND FROM OPTIONS
      // // SET OPTION
      // // SET DATA
      // const degree = dropdownOptions?.DegreeOptions?.find(
      //   (i) => i?.value == obj?.degreeValue
      // );
      // let qualification = dropdownOptions.QualificationOptions.find(
      //   (item) => item?.value == obj.qualificationValue
      // );
      // let specialization = dropdownOptions.SpecializationOptions.find(
      //   (item) => item?.value == obj.specializationValue
      // );
      // setData((prev) => {
      //   return {
      //     ...prev,
      //     Degree: degree,
      //     Qualification: qualification,
      //     Specialization: specialization,
      //   };
      // });
    }

    const helper = async () => {
      let obj = educationData?.find((item) => item.id == tempEducationId);

      const degree = dropdownOptions?.DegreeOptions?.find(
        (i) => i?.value == obj?.degreeValue
      );
      setData((prev) => {
        return {
          ...prev,
          Degree: degree,
        };
      });

      if (!isEmpty(obj?.qualificationValue, true)) {
        await handleBindCascadingDropdown(
          obj?.degreeValue,
          "Qualification",
          obj.qualificationValue,
          "QualificationOptions"
        );
      }

      if (
        !isEmpty(obj?.specializationValue) &&
        !isEmpty(obj.qualificationValue)
      ) {
        await handleBindCascadingDropdown(
          obj.qualificationValue,
          "Specialization",
          obj.specializationValue,
          "SpecializationOptions"
        );
      }
    };

    if (!isEmpty(tempEducationId)) {
      helper();
    }
  }, [tempEducationId]);

  async function handleBindCascadingDropdown(
    casacdingParentId = 0,
    casacdingChild = "",
    casacdingChildValue = null,
    casacdingChildDropdown = "",
    shouldSetValue = true
  ) {
    try {
      //GET OPTIONS
      showLoader();
      const options = await GetEducationCasacdingData(casacdingParentId);

      // FIND FROM OPTIONS
      const option =
        options?.find((i) => i?.value == casacdingChildValue) || [];

      if (shouldSetValue) {
        // SET DATA
        setData((prev) => {
          return { ...prev, [casacdingChild]: option };
        });

        // SET casacding dropdown options
        setDropdownOptions((prev) => {
          return { ...prev, [casacdingChildDropdown]: options };
        });
      }

      return option;
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  async function handleChange(event, code) {
    let arr = [];
    switch (code) {
      //Text
      case "JobTitle":
      case "NotifyEmail":

      case "Questions":
        setData((prev) => {
          return { ...prev, [code]: event?.target?.value };
        });
        break;

      case "JobDescription":
        setData((prev) => {
          return { ...prev, [code]: event };
        });
        break;

      //Number
      case "CompensationMin":
      case "CompensationMax":
      case "MinWorkExperience":
      case "MaxWorkExperience":
        const value = event?.target?.value;

        if (onlyNumbericRegex.test(value) || value === "") {
          setData((prev) => {
            return { ...prev, [code]: event?.target?.value };
          });
        }
        break;

      //Dropdown
      case "JobType":
      case "Industry":
      case "FunctionalArea":
      case "SalaryCurreny":
      case "ShowSalary":
      case "EducationQualifications":
      case "Diversity":
      case "Workmode":
      case "EmploymentType":
      case "OrgName":
      case "Website":
      case "KeySkills":
      case "PhysicalLocations":
      case "Specialization":
        setData((prev) => {
          return { ...prev, [code]: event };
        });
        break;

      case "Degree":
        if (!isEmpty(event?.value)) {
          const id = event?.value;
          const data = await GetEducationCasacdingData(id);
          arr = data || [];
        }
        setDropdownOptions((prev) => {
          return { ...prev, QualificationOptions: arr };
        });

        setData((prev) => {
          return { ...prev, [code]: event, Qualification: null };
        });
        break;

      case "Qualification":
        if (!isEmpty(event?.value)) {
          const id = event?.value;
          const data = await GetEducationCasacdingData(id);
          arr = data || [];
        }
        setDropdownOptions((prev) => {
          return { ...prev, SpecializationOptions: arr };
        });
        setData((prev) => {
          return { ...prev, [code]: event, Specialization: null };
        });
        break;

      default:
        break;
    }
  }

  async function GetDropdownOptionsAsync() {
    try {
      showLoader();
      const { status, message, data } = await APICall(
        GetNaukriDropdownOption,
        "POST",
        {
          MRFId: MRFId,
        }
      );

      if (status === 0) {
        setDropdownOptions((prev) => {
          return {
            IndustryOptions: data?.industryOptions || [],
            JobTypeOptions: data?.jobTypeOptions || [],
            FunctionalAreaOptions: data?.functionalAreaOptions || [],
            ShowSalaryOptions: data?.showSalaryOptions || [],
            SalaryCurrenyOptions: data?.salaryCurrenyOptions || [],
            DiversityOptions: data?.diversityOptions || [],
            WorkmodeOptions: data?.workmodeOptions || [],
            EmploymentTypeOptions: data?.employmentTypeOptions || [],
            OrgNameOptions: data?.orgNameOptions || [],
            WebsiteOptions: data?.websiteOptions || [],
            KeySkillsOptions: data?.keySkillsOptions || [],
            PhysicalLocationOptions: data?.physicalLocationOptions || [],
            DegreeOptions: data?.degreeOptions || [],
            QualificationOptions: data?.qualificationOptions || [],
            SpecializationOptions: data?.specializationOptions || [],
          };
        });

        if (data?.jobTypeOptions?.length > 0) {
          setData((prev) => {
            return { ...prev, JobType: [data?.jobTypeOptions[0]] };
          });
        }
        setTriggerToFetchJobPostingDetails((prev) => !prev);
      } else {
        console.error("server naukri dropdown fetching error: ", message);
      }
    } catch (error) {
      console.error("Naukri dropdown fetching error: ", error);
    } finally {
      hideLoader();
    }
  }

  async function GetInfoLabelForAttributeOfNaukriAsync() {
    try {
      showLoader();
      const { status, message, data } = await APICall(
        GetInfoLabelForAttributeOfNaukri,
        "POST",
        {}
      );

      if (status === 0 && data?.length > 0) {
        let obj = {};
        for (const item of data) {
          obj[item?.configKey] = item?.configValue;
        }
        setToolTipData(obj);
      } else {
        console.error("server naukri info fetching error: ", message);
      }
    } catch (error) {
      console.error("Naukri info label fetching error: ", error);
    } finally {
      hideLoader();
    }
  }

  async function GetJobPostingDetailsByMRFIdAndTypeAsync() {
    try {
      showLoader();
      const response = await APICall(
        GetJobPostingDetailsByMRFIdAndType,
        "POST",
        {
          MRFId: MRFId,
        }
      );

      if (response?.status === 0 && response?.data) {
        const responseData = response?.data;

        setData((prev) => {
          return {
            ...prev,
            JobTitle: responseData?.jobTitle,
            JobUrl: responseData?.jobUrl,
            JobDescription: responseData?.jobDescription,
            CompensationMax: responseData?.compensationMax,
            CompensationMin: responseData?.compensationMin,
            MinWorkExperience: responseData?.minWorkExperience,
            MaxWorkExperience: responseData?.maxWorkExperience,
            NotifyEmail: responseData?.notifyEmail,
            Industry: findSavedDataFromOptions(
              dropdownOptions?.IndustryOptions,
              responseData?.industry
            ),
            FunctionalArea: findSavedDataFromOptions(
              dropdownOptions?.FunctionalAreaOptions,
              responseData?.functionalArea
            ),
            ShowSalary: findSavedDataFromOptions(
              dropdownOptions?.ShowSalaryOptions,
              responseData?.showSalary
            ),
            SalaryCurreny: findSavedDataFromOptions(
              dropdownOptions?.SalaryCurrenyOptions,
              responseData?.salaryCurrency
            ),
            Degree: findSavedDataFromOptions(
              dropdownOptions?.DegreeOptions,
              responseData?.degree
            ),
            Qualification: findSavedDataFromOptions(
              dropdownOptions?.QualificationOptions,
              responseData?.qualification
            ),
            Specialization: findSavedDataFromOptions(
              dropdownOptions?.SpecializationOptions,
              responseData?.specialization
            ),
            Diversity: findSavedDataFromOptions(
              dropdownOptions?.DiversityOptions,
              responseData?.diversity
            ),
            Workmode: findSavedDataFromOptions(
              dropdownOptions?.WorkmodeOptions,
              responseData?.workmode
            ),
            EmploymentType: findSavedDataFromOptions(
              dropdownOptions?.EmploymentTypeOptions,
              responseData?.employmentType
            ),
            OrgName: findSavedDataFromOptions(
              dropdownOptions?.OrgNameOptions,
              responseData?.orgName
            ),
            Website: findSavedDataFromOptions(
              dropdownOptions?.WebsiteOptions,
              responseData?.website
            ),
            KeySkills: findSavedDataFromOptions(
              dropdownOptions?.KeySkillsOptions,
              responseData?.keySkills
            ),
            PhysicalLocations: findSavedDataFromOptions(
              dropdownOptions?.PhysicalLocationOptions,
              responseData?.physicalLocations
            ),
          };
        });
      } else {
        console.error(
          "server naukri saved info fetching error: ",
          response.message
        );
      }
    } catch (error) {
      console.error("Naukri info label fetching error: ", error);
    } finally {
      hideLoader();
    }
  }

  async function GetEducationSavedData() {
    try {
      showLoader();
      const response = await APICall(GetSavedNaukriEducationDetails, "POST", {
        MRFId: MRFId,
      });

      if (response?.status == 0) {
        const responseData = response?.data;

        let arr = [];
        for (const item of responseData) {
          const degree = findSavedDataFromOptions(
            dropdownOptions?.DegreeOptions,
            item?.degree
          );

          let qualification = null;
          let specialization = null;

          // const qualification = findSavedDataFromOptions(
          //   dropdownOptions?.QualificationOptions,
          //   item?.qualification
          // );

          // const specialization = findSavedDataFromOptions(
          //   dropdownOptions?.SpecializationOptions,
          //   item?.specialization
          // );
          if (!isEmpty(item?.degree, false)) {
            qualification = await handleBindCascadingDropdown(
              item?.degree,
              "Qualification",
              item?.qualification,
              "QualificationOptions",
              false
            );
          }

          if (!isEmpty(item?.specialization) && !isEmpty(item?.qualification)) {
            specialization = await handleBindCascadingDropdown(
              item?.qualification,
              "Specialization",
              item?.specialization,
              "SpecializationOptions",
              false
            );
          }

          if (!isEmpty(degree) && !isEmpty(qualification)) {
            let object = {
              id: uuid(),
              degreeLabel: degree[0]?.label || null,
              degree: degree[0] || {},
              degreeValue: degree[0]?.value ?? null,

              qualificationLabel: qualification?.label || null,
              specializationLabel: specialization?.label ?? null,
              qualificationValue: qualification?.value ?? null,

              specializationValue: specialization?.value || null,
              qualification: qualification || {},
              specialization: specialization || {} || null,
            };
            arr.push(object);
          }
        }
        setData((prev) => {
          return { ...prev, EducationData: arr };
        });
        setEducationData(arr);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  async function GetEducationCasacdingData(id = 0) {
    try {
      showLoader();
      const response = await APICall(GenericMasterDataByParentId, "POST", {
        Id: id,
      });

      if (response?.status == 0) {
        return response?.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  function findSavedDataFromOptions(options = [], dataToFind = {}) {
    try {
      const response =
        options.filter((option) =>
          String(dataToFind).split(",").includes(String(option?.value))
        ) || [];
      return response;
    } catch (error) {
      console.error("findSavedDataFromOptions error: ", error);
    }
  }

  async function publishNaukriJob() {
    try {
      const isFieldsAreValid = NaurkiFieldsValidation(data, setFormErrors);
      if (isFieldsAreValid) return;

      let education = educationData?.map((item) => {
        return {
          Degree: getValue(item?.degree, true),
          Qualification: item?.qualification,
          Specialization: item?.specialization,
        };
      });

      const requestParams = {
        JobTitle: data?.JobTitle,
        JobType: getValue(data?.JobType),
        JobDescription: data?.JobDescription,
        Industry: getValue(data?.Industry),
        FunctionalArea: getValue(data?.FunctionalArea),
        CompensationMax: data?.CompensationMax,
        CompensationMin: data?.CompensationMin,
        ShowSalary: getValue(data?.ShowSalary),
        NotifyEmail: data?.NotifyEmail,
        EmploymentType: getValue(data?.EmploymentType),
        OrgName: getValue(data?.OrgName),
        Website: getValue(data?.Website, true),
        KeySkills: getValue(data?.KeySkills),
        MinWorkExperience: data?.MinWorkExperience || 0,
        MaxWorkExperience: data?.MaxWorkExperience || 0,
        SalaryCurreny: getValue(data.SalaryCurreny),
        Diversity: getValue(data?.Diversity, true),
        Workmode: getValue(data?.Workmode),
        PhysicalLocations: getValue(data?.PhysicalLocations),
        SalaryCurrency: getValue(data.SalaryCurreny),
        MRFId,
        UserId: userDetails?.Id || 0,
        Education: education,
      };

      showLoader();
      const response = await APICall(
        InsertUpdateNaukriPostingDetails,
        "POST",
        requestParams
      );
      if (response?.status == 0) {
        notify(0, "Job posted successfully.");
        setRefreshKey((prev) => !prev);
      } else if (response?.status == 5) {
        if (response?.data) {
          setFormErrors(response?.data);
        }
        notify(1, response?.message);
      } else {
        throw new Error(response?.message);
      }
    } catch (error) {
      console.error(error);
      notify(1, "Job posted unsuccessfully.");
    } finally {
      hideLoader();
    }
  }

  async function republishJobAsync() {
    try {
      const isFieldsAreValid = NaurkiFieldsValidation(data, setFormErrors);
      if (isFieldsAreValid) return;

      let education = educationData?.map((item) => {
        return {
          Degree: getValue(item?.degree, true), //item?.degree,
          Qualification: item?.qualification,
          Specialization: item?.specialization,
        };
      });

      const requestParams = {
        JobTitle: data?.JobTitle,
        JobType: getValue(data?.JobType),
        JobDescription: data?.JobDescription,
        Industry: getValue(data?.Industry),
        FunctionalArea: getValue(data?.FunctionalArea),
        CompensationMax: data?.CompensationMax,
        CompensationMin: data?.CompensationMin,
        ShowSalary: getValue(data?.ShowSalary),
        NotifyEmail: data?.NotifyEmail,
        EmploymentType: getValue(data?.EmploymentType),
        OrgName: getValue(data?.OrgName),
        Website: getValue(data?.Website, true),
        KeySkills: getValue(data?.KeySkills),
        MinWorkExperience: data?.MinWorkExperience || 0,
        MaxWorkExperience: data?.MaxWorkExperience || 0,
        SalaryCurreny: getValue(data.SalaryCurreny),
        Diversity: getValue(data?.Diversity, true),
        Workmode: getValue(data?.Workmode),
        PhysicalLocations: getValue(data?.PhysicalLocations),
        SalaryCurrency: getValue(data.SalaryCurreny),
        MRFId,
        UserId: userDetails?.Id || 0,
        Education: education,
      };

      showLoader();
      const response = await APICall(
        MRFRepublishNaukriJob,
        "POST",
        requestParams
      );

      if (response?.status == 0) {
        notify(0, "Job republished on Naukri successfully.");
        setRefreshKey((prev) => !prev);
      } else if (response?.status == 5) {
        setFormErrors(response?.data);
        notify(1, "Fields validation error.");
      } else {
        throw new Error(response?.message);
      }
    } catch (error) {
      console.error(error);
      notify(1, "Job republished unsuccessfully.");
    } finally {
      hideLoader();
    }
  }

  async function depublishNaukriJob() {
    try {
      showLoader();
      const requestParams = {
        MRFId: MRFId,
      };
      const response = await APICall(
        MRFDepublishNaukriJob,
        "POST",
        requestParams
      );

      if (response?.status == 0) {
        notify(0, "Job Depublished successfully.");
        setRefreshKey((prev) => !prev);
      } else {
        notify(1, "Job Depublished unsuccessfully.");
        throw new Error(response?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  function getValue(param: any = {}, key = false) {
    let response = {};

    if (key) {
      //GET VALUE BASED ON CODE
      if (Array.isArray(param)) {
        const label = param.map((ele) => ele?.code).join(",");
        const value = param.map((ele) => ele?.value).join(",");
        response = { label, value };
        return response;
      } else {
        const label = param?.code || "";
        const value = param?.value || "";
        response = { label, value };
        return response;
      }
    } else {
      // GET VALUE BASED ON LABEL
      if (Array.isArray(param)) {
        const label = param.map((ele) => ele?.label).join(",");
        const value = param.map((ele) => ele?.value).join(",");
        response = { label, value };
        return response;
      } else {
        response = { ...param };
      }
    }

    return response;
  }

  function insertUpdateEducation() {
    try {
      setDisableInsertBtn(true);
      let formErr = {
        ...formErrors,
      };

      if (isEmpty(data.Degree)) {
        formErr["Degree"] = "Degree required.";
      } else {
        delete formErr?.Degree;
      }

      if (isEmpty(data.Qualification)) {
        formErr["Qualification"] = "Qualification required.";
      } else {
        delete formErr?.Qualification;
      }

      let check = false;
      if (
        educationData?.length > 0 &&
        !isEmpty(data.Degree) &&
        !isEmpty(data.Qualification)
      ) {
        const checkLineItem = educationData?.some(
          (i) =>
            i?.degreeValue == data.Degree?.value &&
            i?.qualificationValue == data.Qualification?.value &&
            i?.specializationValue == data?.Specialization?.value &&
            i?.id != tempEducationId
        );
        if (checkLineItem) {
          formErr["Degree"] = "Cannot enter duplicate values.";
          check = true;
        } else {
          delete formErr?.Degree;
        }
      }

      if (educationData?.length == 15) {
        notify(1, "Cannot add more 15 values.");
        return;
      }

      setFormErrors((prev) => {
        return { ...formErr };
      });

      if ((isEmpty(data.Degree) && isEmpty(data.Qualification)) || check)
        return;

      let id = tempEducationId ?? uuid();

      let object = {
        id: id,
        degreeLabel: data.Degree?.label,
        qualificationLabel: data.Qualification?.label,
        specializationLabel: data?.Specialization?.label,
        degreeValue: data.Degree?.value,
        qualificationValue: data.Qualification?.value,
        specializationValue: data.Specialization?.value,
        degree: data?.Degree,
        qualification: data?.Qualification,
        specialization: data?.Specialization,
      };
      if (isEmpty(tempEducationId)) {
        let arr = [...educationData, object];
        setData((prev) => {
          return { ...prev, EducationData: arr };
        });
        setEducationData(arr);
      } else {
        let arr = [];
        setEducationData((prev: any) => {
          arr = prev.map((prevItem) => {
            if (prevItem?.id == id) return object;
            return prevItem;
          });
          return arr;
        });

        setData((prev) => {
          return { ...prev, EducationData: arr };
        });
      }

      setData((prev) => {
        return {
          ...prev,
          Degree: null,
          Qualification: null,
          Specialization: null,
        };
      });

      setDropdownOptions((prev) => {
        return {
          ...prev,
          QualificationOptions: [],
          SpecializationOptions: [],
        };
      });
      setTempEducationId(null);
    } catch (error) {
      console.error(error);
    } finally {
      setDisableInsertBtn(false);
    }
  }

  function deleteEducation(id) {
    try {
      let arr = educationData?.filter((prev) => prev?.id != id);
      setEducationData(arr);
      setData((prev) => {
        return { ...prev, EducationData: arr };
      });
    } catch (error) {}
  }

  function viewEducation(id: any) {
    setTempEducationId(id);
  }

  const educationColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "degreeLabel",
      label: "Degree",
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: "qualificationLabel",
      label: "Qualification",
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: "specializationLabel",
      label: "Specialization",
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: "",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.tableData[tableMeta.rowIndex].id;

          return (
            <div>
              <Tooltip title={"edit"} className="mr-2">
                <span
                  onClick={() => {
                    viewEducation(id);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <i className={`fas fa-edit`} />
                </span>
              </Tooltip>

              <Tooltip title={"delete"} className="mr-2">
                <span
                  onClick={() => {
                    deleteEducation(id);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <i className={`fas fa-trash`} />
                </span>
              </Tooltip>
            </div>
          );
        },
      },
    },
  ];

  return (
    <>
      <div className="col-lg-12 mt-2">
        <h5 className="">Naukri Details</h5>
      </div>

      <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
        <div className="form-group">
          <label className="col-form-label">Job Title</label>
          <sup>*</sup>
          <Tooltip title={tooltipData?.JobTitle || ""} className="ml-1">
            <span>
              <i className="fas fa-info-circle" />
            </span>
          </Tooltip>
          <InputForm
            value={data.JobTitle}
            placeholder={"Job Title"}
            isDisabled={disabled}
            textArea={false}
            maxLength={70}
            onChange={(event) => handleChange(event, "JobTitle")}
          />
          <span style={{ color: "red" }}>{formErrors["JobTitle"]}</span>
        </div>
      </div>

      <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
        <Dropdown
          label={"Job Type"}
          isRequired={true}
          isTooltipRequired={true}
          tooltipMessage={tooltipData?.JobType || ""}
          options={dropdownOptions?.JobTypeOptions || []}
          placeholder="Select Job Type"
          isDisabled={true}
          value={data.JobType}
          onChange={(event) => {
            handleChange(event, "JobType");
          }}
          isMulti={false}
          errorMessage={formErrors["JobType"] || ""}
        />
      </div>

      <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
        <Dropdown
          label={"Industry"}
          isRequired={true}
          isTooltipRequired={true}
          tooltipMessage={tooltipData?.Industry || ""}
          options={dropdownOptions?.IndustryOptions || []}
          placeholder="Select Industry"
          isDisabled={disabled}
          value={data.Industry}
          onChange={(event) => {
            handleChange(event, "Industry");
          }}
          isMulti={false}
          errorMessage={formErrors["Industry"] || ""}
        />
      </div>

      <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
        <Dropdown
          label={"Functional Area"}
          value={data.FunctionalArea}
          isRequired={false}
          isTooltipRequired={true}
          tooltipMessage={tooltipData?.FunctionalArea || ""}
          options={dropdownOptions?.FunctionalAreaOptions || []}
          placeholder="Select Functional Area"
          isDisabled={disabled}
          onChange={(event) => {
            handleChange(event, "FunctionalArea");
          }}
          isMulti={true}
          errorMessage={formErrors["FunctionalArea"] || ""}
        />
      </div>

      <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
        <div className="form-group">
          <label className="col-form-label mr-1">Compensation Min</label>
          <sup>*</sup>
          <Tooltip title={tooltipData?.CompensationMin || ""}>
            <span>
              <i className="fas fa-info-circle" />
            </span>
          </Tooltip>
          <InputForm
            value={data.CompensationMin}
            placeholder={"Compensation Min"}
            isDisabled={disabled}
            textArea={false}
            maxLength={255}
            type={"number"}
            onChange={(event) => handleChange(event, "CompensationMin")}
          />
          <span style={{ color: "red" }}>{formErrors["CompensationMin"]}</span>
        </div>
      </div>

      <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
        <div className="form-group">
          <label className="col-form-label mr-1">Compensation Max</label>
          <sup>*</sup>
          <Tooltip title={tooltipData?.CompensationMax || ""} className="ml-1">
            <span>
              <i className="fas fa-info-circle" />
            </span>
          </Tooltip>
          <InputForm
            value={data.CompensationMax}
            placeholder={"Compensation Max"}
            isDisabled={disabled}
            textArea={false}
            maxLength={255}
            type={"number"}
            onChange={(event) => handleChange(event, "CompensationMax")}
          />
          <span style={{ color: "red" }}>{formErrors["CompensationMax"]}</span>
        </div>
      </div>

      <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
        <Dropdown
          label={"Show salary"}
          isRequired={true}
          isTooltipRequired={true}
          tooltipMessage={tooltipData?.ShowSalary || ""}
          options={dropdownOptions?.ShowSalaryOptions || []}
          placeholder="Select Show salary"
          isDisabled={disabled}
          value={data.ShowSalary}
          onChange={(event) => {
            handleChange(event, "ShowSalary");
          }}
          isMulti={false}
          errorMessage={formErrors["ShowSalary"] || ""}
        />
      </div>

      <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
        <div className="form-group">
          <label className="col-form-label mr-1">Notify Email</label>

          <Tooltip title={tooltipData?.NotifyEmail || ""} className="ml-1">
            <span>
              <i className="fas fa-info-circle" />
            </span>
          </Tooltip>
          <InputForm
            value={data.NotifyEmail}
            placeholder={"Notify Email"}
            isDisabled={disabled}
            textArea={false}
            maxLength={255}
            onChange={(event) => handleChange(event, "NotifyEmail")}
          />
          <span style={{ color: "red" }}>{formErrors["NotifyEmail"]}</span>
        </div>
      </div>

      <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
        <Dropdown
          label={"Salary Currency"}
          isRequired={true}
          isTooltipRequired={true}
          tooltipMessage={tooltipData?.SalaryCurreny || ""}
          options={dropdownOptions?.SalaryCurrenyOptions || []}
          placeholder="Select Salary Currency"
          isDisabled={disabled}
          value={data.SalaryCurreny}
          onChange={(event) => {
            handleChange(event, "SalaryCurreny");
          }}
          errorMessage={formErrors["SalaryCurreny"] || ""}
        />
      </div>

      <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
        <Dropdown
          label={"Diversity"}
          isRequired={false}
          isTooltipRequired={true}
          tooltipMessage={tooltipData?.Diversity || ""}
          options={dropdownOptions?.DiversityOptions || []}
          placeholder="Select Diversity"
          isDisabled={disabled}
          value={data.Diversity}
          onChange={(event) => {
            handleChange(event, "Diversity");
          }}
          isMulti={false}
          errorMessage={formErrors["Diversity"] || ""}
        />
      </div>

      <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
        <Dropdown
          label={"Workmode"}
          isRequired={true}
          isTooltipRequired={true}
          tooltipMessage={tooltipData?.Workmode || ""}
          options={dropdownOptions?.WorkmodeOptions || []}
          placeholder="Select Workmode"
          isDisabled={disabled}
          value={data.Workmode}
          onChange={(event) => {
            handleChange(event, "Workmode");
          }}
          isMulti={false}
          errorMessage={formErrors["Workmode"] || ""}
        />
      </div>

      <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
        <Dropdown
          label={"Employment Type"}
          isRequired={true}
          isTooltipRequired={true}
          tooltipMessage={tooltipData?.EmploymentType || ""}
          options={dropdownOptions?.EmploymentTypeOptions || []}
          placeholder="Select Employment Type"
          isDisabled={disabled}
          value={data.EmploymentType}
          onChange={(event) => {
            handleChange(event, "EmploymentType");
          }}
          isMulti={false}
          errorMessage={formErrors["EmploymentType"] || ""}
        />
      </div>

      <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
        <Dropdown
          label={"Org Name (Legal entity)"}
          isRequired={true}
          isTooltipRequired={true}
          tooltipMessage={tooltipData?.OrgName || ""}
          options={dropdownOptions?.OrgNameOptions || []}
          placeholder="Select Org Name (Legal entity)"
          isDisabled={disabled}
          value={data.OrgName}
          onChange={(event) => {
            handleChange(event, "OrgName");
          }}
          isMulti={false}
          errorMessage={formErrors["OrgName"] || ""}
        />
      </div>

      <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
        <Dropdown
          label={"Website"}
          isRequired={false}
          isTooltipRequired={true}
          tooltipMessage={tooltipData?.Website || ""}
          options={dropdownOptions?.WebsiteOptions || []}
          placeholder="Select Website"
          isDisabled={disabled}
          value={data.Website}
          onChange={(event) => {
            handleChange(event, "Website");
          }}
          isMulti={false}
          errorMessage={formErrors["Website"] || ""}
        />
      </div>

      <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
        <Dropdown
          label={"Key Skills"}
          isRequired={false}
          isTooltipRequired={true}
          tooltipMessage={tooltipData?.KeySkills || ""}
          options={dropdownOptions?.KeySkillsOptions || []}
          placeholder="Select Key Skills"
          isDisabled={disabled}
          value={data.KeySkills}
          onChange={(event) => {
            handleChange(event, "KeySkills");
          }}
          isMulti={true}
          errorMessage={formErrors["KeySkills"] || ""}
        />
      </div>

      <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
        <div className="form-group">
          <label className="col-form-label">Minimum Work Experience</label>
          <sup>*</sup>
          <Tooltip
            title={tooltipData?.MinWorkExperience || ""}
            className="ml-1"
          >
            <span>
              <i className="fas fa-info-circle" />
            </span>
          </Tooltip>

          <InputForm
            value={data.MinWorkExperience}
            placeholder={"Minimum work experience"}
            isDisabled={disabled}
            textArea={false}
            maxLength={500}
            onChange={(event) => handleChange(event, "MinWorkExperience")}
          />
          <span style={{ color: "red" }}>
            {formErrors["MinWorkExperience"]}
          </span>
        </div>
      </div>

      <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
        <div className="form-group">
          <label className="col-form-label">Maximum Work Experience</label>
          <sup>*</sup>
          <Tooltip
            title={tooltipData?.MaxWorkExperience || ""}
            className="ml-1"
          >
            <span>
              <i className="fas fa-info-circle" />
            </span>
          </Tooltip>
          <InputForm
            value={data.MaxWorkExperience}
            placeholder={"Maximum Work Experience"}
            isDisabled={disabled}
            textArea={false}
            maxLength={500}
            type={"number"}
            onChange={(event) => handleChange(event, "MaxWorkExperience")}
          />
          <span style={{ color: "red" }}>
            {formErrors["MaxWorkExperience"]}
          </span>
        </div>
      </div>

      <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
        <Dropdown
          label={"Physical Locations"}
          isRequired={true}
          isTooltipRequired={true}
          tooltipMessage={tooltipData?.PhysicalLocations || ""}
          options={dropdownOptions?.PhysicalLocationOptions || []}
          placeholder="Physical Locations"
          isDisabled={disabled}
          value={data.PhysicalLocations}
          onChange={(event) => {
            handleChange(event, "PhysicalLocations");
          }}
          isMulti={true}
          errorMessage={formErrors["PhysicalLocations"] || ""}
        />
      </div>

      <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
        {!isEmpty(data?.JobUrl) && (
          <>
            <div className="form-group">
              <label className="col-form-label mr-1">
                URL For Posted Job On Naukri
              </label>
              <Tooltip title={tooltipData?.JobUrl || ""} className="ml-1">
                <span>
                  <i className="fas fa-info-circle" />
                </span>
              </Tooltip>
              <div className="form-control">
                <a
                  href={data?.JobUrl}
                  target="_blank"
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    width: "194px",
                    display: "block",
                    overflow: "hidden",
                  }}
                >
                  {data?.JobUrl}
                </a>
              </div>
              <span style={{ color: "red" }}>{formErrors["JobUrl"]}</span>
            </div>
          </>
        )}
      </div>

      <div className="col-lg-12 col-md-12 col-sm-12 mobile-view top-m p-0">
        <div className="form-group col-lg-6">
          <label className="col-form-label">Job Description</label>
          <sup>*</sup>
          <Tooltip title={tooltipData?.JobDescription || ""} className="ml-1">
            <span>
              <i className="fas fa-info-circle" />
            </span>
          </Tooltip>

          {/*Due to faster re-render used this approach*/}
          {disabled ? (
            <RTE
              key={11}
              content={data.JobDescription}
              onblur={(event) => handleChange(event, "JobDescription")}
              disabled={true}
              placeholder={"Job description"}
            />
          ) : (
            <RTE
              key={22}
              content={data.JobDescription}
              onblur={(event) => handleChange(event, "JobDescription")}
              disabled={false}
              placeholder={"Job description"}
            />
          )}
          <span style={{ color: "red" }}>{formErrors["JobDescription"]}</span>
        </div>
      </div>
      <div className="col-lg-12 px-2">
        <div
          style={{
            color: "#cccccc",
            backgroundColor: "#cccccc",
            height: 1,
            marginTop: 10,
          }}
          className="col-lg-12 col-sm-12 col-xs-12 top-m"
        />
        <>
          <div className="col-lg-12 mt-2">
            <h6 className="mt-2">Education</h6>
          </div>
          <div className="col-lg-12 row">
            <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
              <Dropdown
                label={"Degree"}
                isRequired={true}
                isTooltipRequired={true}
                tooltipMessage={tooltipData?.Degree || ""}
                options={dropdownOptions?.DegreeOptions || []}
                placeholder="Select Degree"
                isDisabled={disabled}
                value={data.Degree}
                onChange={(event) => {
                  handleChange(event, "Degree");
                }}
                isMulti={false}
                errorMessage={formErrors["Degree"] || ""}
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
              <Dropdown
                label={"Qualification"}
                isRequired={true}
                isTooltipRequired={true}
                tooltipMessage={tooltipData?.Qualification || ""}
                options={dropdownOptions?.QualificationOptions || []}
                placeholder="Select Qualification"
                isDisabled={disabled}
                value={data.Qualification}
                onChange={(event) => {
                  handleChange(event, "Qualification");
                }}
                isMulti={false}
                errorMessage={formErrors["Qualification"] || ""}
              />
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
              <Dropdown
                label={"Specialization"}
                isRequired={false}
                isTooltipRequired={true}
                tooltipMessage={tooltipData?.Specialization || ""}
                options={dropdownOptions?.SpecializationOptions || []}
                placeholder="Select Specialization"
                isDisabled={disabled}
                value={data.Specialization}
                onChange={(event) => {
                  handleChange(event, "Specialization");
                }}
                isMulti={false}
                errorMessage={formErrors["Specialization"] || ""}
              />
            </div>
            <div className="col-lg-12 d-flex justify-content-end mt-2">
              <button
                className="btn btn-primary mr-2"
                onClick={insertUpdateEducation}
                disabled={disableInsertBtn || disabled}
              >
                {isEmpty(tempEducationId) ? (
                  <> Insert Record</>
                ) : (
                  <>Update Record</>
                )}
              </button>
              <button
                className="btn btn-primary mr-2"
                onClick={() => {
                  setTempEducationId(null);
                  setDropdownOptions((prev) => {
                    return {
                      ...prev,

                      QualificationOptions: [],
                      SpecializationOptions: [],
                    };
                  });
                  setData((prev) => {
                    return {
                      ...prev,
                      Degree: null,
                      Qualification: null,
                      Specialization: null,
                    };
                  });
                }}
                disabled={disabled}
              >
                Cancel
              </button>
            </div>
            <div className="col-lg-12 p-0 my-4">
              <DynamicGrid
                data={educationData}
                options={gridOptions}
                columns={educationColumns}
              />
            </div>
          </div>
        </>
      </div>
      <div className="col-lg-12 d-flex justify-content-end  p-0 my-4">
        {!postingStatus ? (
          <>
            <button
              type="button"
              className="btn btn-primary mr-2"
              onClick={publishNaukriJob}
              disabled={disabled}
            >
              Publish
            </button>
          </>
        ) : (
          <>
            <button
              type="button"
              className="btn btn-primary mr-2"
              onClick={republishJobAsync}
              disabled={disabled}
            >
              {!isDepublished ? "Republish" : "Update"}
            </button>
            <button
              type="button"
              className="btn btn-primary mr-2"
              disabled={disabled || !isDepublished}
              onClick={() => {
                setDepublishModal(true);
              }}
            >
              Depublish
            </button>
          </>
        )}
      </div>

      {/* Depublish Confirmation */}
      <ConfirmationModal
        modal={depublishModal}
        setModal={setDepublishModal}
        confirmCallback={async () => {
          setDepublishModal(false);
          depublishNaukriJob();
        }}
        noCallback={() => setDepublishModal(false)}
        message={"Are you sure want to depublish job on naukri?"}
        hideConfirmMation={false}
      />
    </>
  );
};

export default NaukriColumns;
