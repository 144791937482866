import React from "react";
import "./DateForm.css";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TextField from "@mui/material/TextField";

const DateForm = ({
  isDisabled,
  value,
  onChange,
  readonly = false,
  ...rest
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        disabled={isDisabled}
        value={value}
        disableMaskedInput
        className="w-100 bg-white date_icon"
        onChange={onChange}
        inputFormat="dd/MM/yyyy"
        InputProps={{
          readOnly: readonly,
          sx: {
            "&.Mui-disabled": {
              backgroundColor: "#e9ecef",
              borderColor: "#e9ecef",
              outline: "none",
              opacity: "1",
            },
          },
        }}
        renderInput={(params) => (
          <TextField
            size="small"
            name=""
            autoComplete="off"
            id="date-input"
            sx={{
              svg: {
                color: "#00ABE6",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  fontFamily: "Segoe UI",
                  boxShadow: "none",
                  outline: "none",
                },
                "&:hover fieldset": {
                  borderColor: "hsl(0, 0%, 70%)",
                  boxShadow: "none",
                  outline: "none",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "hsl(0, 0%, 70%)",
                  boxShadow: "none",
                  outline: "none",
                },
              },
            }}
            {...params}
            inputProps={{ ...params.inputProps, readOnly: readonly }}
          />
        )}
        {...rest}
      />
    </LocalizationProvider>
  );
};

export default DateForm;
