import { userData, updateReduxStore } from "./userData";
import { combineReducers } from "redux";
import invoiceConfig from "./invoiceConfig";
import entityConfig from "./entityConfig";
import userRole from "./userRole";

const rootReducer = combineReducers({
  userData,
  updateReduxStore,
});

export default rootReducer;
