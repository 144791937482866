import { Collapse } from "react-bootstrap";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import SelectForm from "../../Components/SelectForm/SelectForm";
import InputForm from "../../Components/InputForm/InputForm";
import React, { useContext, useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import { LoaderContext } from "../../Helpers/Context/Context";
import { APICall } from "../../Helpers/APICalls";
import { GetModuleDropdown, GetTaskChecklistDashboardData, getAllRoles, getDropdowns, getDropdownsData } from "../../Helpers/APIEndPoints/EndPoints";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { useNavigate } from "react-router-dom";
import { useDateValidation } from "@mui/x-date-pickers/internals/hooks/validation/useDateValidation";
import notify from "../../Helpers/ToastNotification";


const TaskChecklistDashboard = () => {

    const [open, setOpen] = useState(false);
    const [gridData, setGridData] = useState(null);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [start, setStart] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [rowData, setRowData] = useState([]);
    const [reload, setReload] = useState(false);
    const [PageSize, setPageSize] = useState(10);
    const [sortOrder, setDashboardSortDirection] = useState("");
    const [sortColumn, setDashboardSortColumn] = useState<any>("");
    const { userDetails, currentRoleId }: any = useUserContext();
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const navigate = useNavigate();
    const [ddValue, setDdValue] = useState({});
    const [moduleNameDropdownOptions, setModuleNameDropdownOptions] = useState([]);
    const [rolesDropdownOptions, setRolesDropdownOptions] = useState([]);
    const [taskGroupDropdownOptions, setTaskGroupDropdownOptions] = useState([]);
    const [filterValue, setFilterValue] = useState({});

    useEffect(() => {
        getInterfaceDetails();
      }, []);

      useEffect(() => {
        if (gridData?.data?.d?.s[0]?.columnData.length > 0) {
          (async () => await getGridStructureTwo())();
        }
      }, [start, sortOrder, sortColumn, reload, searchText]);

    const options = {
        showEmptyDataSourceMessage: true,
        selectableRows: "none",
        count: count,
        page: page,
        rowsPerPage: PageSize,
        serverSide: true,
        onChangePage: async (page) => {
          await setPage(page);
          setStart(page * PageSize);
        },
        onColumnSortChange: async (sortColumn, sortDirection) => {
          if (sortDirection === "asc") {
            await setDashboardSortColumn(sortColumn);
            await setDashboardSortDirection(sortDirection);
          }
          if (sortDirection === "desc") {
            await setDashboardSortColumn(sortColumn);
            await setDashboardSortDirection(sortDirection);
          }
        },
        rowsPerPageOptions: [],
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        search: false,
        responsive: "vertical"
      };

      const getDropdownsData = async (AC, PID = null) => {
        await showLoader();
        const dropdowndataResponse = await APICall(getDropdowns, "POST", {
          AC,
          PID,
          UserId: userDetails?.Id,
          RoleId: currentRoleId?.value,
        });
        await hideLoader();
        const dropdownData = dropdowndataResponse.data;
        return dropdownData;
      };

      const GetDropdownValuesForTaskGroup = async () => {
        let dropdownDataForCountry = await getDropdownsData("GenMasterData", 'TaskGroup');
        setTaskGroupDropdownOptions(dropdownDataForCountry);
      };

        //Api call for Module Name
  const fetchModuleNames = async () => {
    try {
      const response = await APICall(GetModuleDropdown, "POST", {

      });
      setModuleNameDropdownOptions(response.data);
    } catch (error) {
      console.error("Error fetching Module data:", error);
    }
  };

  //Api call for Roles
  const fetchRoleDetails = async () => {
    try {
      const response = await APICall(getAllRoles, "POST", {

      });
      setRolesDropdownOptions(response.data);
    } catch (error) {
      console.error("Error fetching Role data:", error);
    }
  };

      const getGridStructureTwo = async () => {
        showLoader();

        const GetDashboardData = await APICall(
          GetTaskChecklistDashboardData,
          "POST",
          {
            Offset: start,
            PageSize: PageSize,
            SearchText: searchText,
            SortColumn: sortColumn,
            SortOrder: sortOrder,
            CommaSeperatedModuleIds: filterValue && filterValue["module"]
            ? filterValue["module"]?.map((s) => s.value)?.join(",") : '',
            CommaSeperateResponsibleRoleIds: filterValue && filterValue["responsibleRole"]
            ? filterValue["responsibleRole"]?.map((s) => s.value)?.join(",")
            : '',
            CommaSeperatedTaskGroupIds: filterValue && filterValue["taskGroup"]
            ? filterValue["taskGroup"]?.map((s) => s.value)?.join(",")
            : ''
          }
        );

        if (GetDashboardData?.data !== null && GetDashboardData?.data?.length > 0) {
          setCount(GetDashboardData?.data[0]?.count);
          setRowData(GetDashboardData.data);
        } else {
          setRowData([]);
          setCount(0);
        }
        hideLoader();
      };

      const getInterfaceDetails = async () => {
        try {
          showLoader();
          const gridDataResponse = await APICall(
            "/api/Interface/GetInterfaceDetailsForGrid",
            "POST",
            {
              MN: "OnBoarding",
              IN: "OnBoarding_Dashboard",
              TN: "OnBoarding_Dashboard",
            }
          );

          if (gridDataResponse?.data?.d?.fa !== null) {
            for await (const cFilter of gridDataResponse?.data?.d?.fa) {
              if (cFilter.filt === "multidropdown") {
                await getDropdownsData(cFilter.filAN, null);
              }
            }
          }
          if (gridDataResponse?.data?.d?.afa !== null) {
            for await (const cFilter of gridDataResponse?.data?.d?.afa) {
              if (cFilter.filt === "multidropdown") {
                await getDropdownsData(cFilter.filAN, null);
              }
            }
          }

          setGridData(gridDataResponse);
          fetchModuleNames();
          fetchRoleDetails();
          GetDropdownValuesForTaskGroup();
          await getGridStructureTwo();
        } catch (error) {
          console.log(error);
        } finally {
          hideLoader();
        }
      };

      const edit = (value, tableMeta, url) => {
        const Id = tableMeta.rowData[0];
        navigate("/TaskChecklist", { state: { Id: Id } });
      };


  let columnsWithCustomRender = '';

  columnsWithCustomRender = gridData?.data?.d?.s[0]?.columnData.map(
    (column) => {
      if (column.name === "action"
      ) {
        return {
          ...column,
          options: {
            ...column.options,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <>
                      <div className="centerStyles">
                        <Tooltip title="Edit">
                        <i
                          style={{ marginRight: "0px", cursor: "pointer" }}
                          onClick={() => {
                            edit(value, tableMeta, column.url);
                          }}
                          className="fas fa-edit"></i>
                        </Tooltip>
                      </div>
                    </>
              );
            },
          },
        };
      }
      return column;
    }
  );

  const addNewOU = async () => {
      navigate("/TaskChecklist", { state: { Id: 0,  MasterId: 0, MasterName: 0 } });
  }

  const onSubmitFilter = () => {
    let isNull = Object.values(filterValue).every(
      (value) => value === null || (Array.isArray(value) && value.length === 0)
    );
    if (isNull) {
      notify(1, "Select atleast one filter");
    } else {
      getGridStructureTwo();
    }
  };

  const onClickReset = () => {
    setSearchText("");
    setStart(0);
    setDashboardSortColumn("");
    setFilterValue(null);
    setDashboardSortDirection("desc");
  };

    return(

    <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center my-3">
          <button
            onClick={() => navigate("/home")}
            className="btn btn-primary">
            <i className="fa-solid fa-arrow-left mr-1"></i> Back
          </button>
          <button
            disabled={false}
            onClick={addNewOU}
            className="btn btn-primary mr-2">
            <i className="fa fa-plus mr-2"></i>
            Add New
          </button>
        </div>

            <div className="grid-wrapper grid_mob">
        <div className="row mx-auto filtBox">
          {gridData &&
            gridData?.data?.d?.fa.map((cFilter, index) => (
              <React.Fragment key={index}>
                {cFilter.filt === "multidropdown" ? (
                  <>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="form-group filtInp">
                        <span
                          className="mr-2 text-white"
                          style={{ float: "left" }}
                        >
                          <label className="col-form-label">
                            {cFilter.filn}
                          </label>
                        </span>
                        <SelectForm
                          isClearable
                          options={cFilter.filAN === 'module' ?
                          moduleNameDropdownOptions :
                          cFilter.filAN === 'taskGroup' ?
                          taskGroupDropdownOptions :
                          []
                        }
                          placeholder={cFilter.filn}
                          isDisabled={false}
                          onChange={(event) => {
                            setFilterValue((prev) => {
                              return { ...prev, [cFilter.filAN]: event };
                            });
                            setStart(0);
                            setReload(!reload); //imp
                          }}
                          isMulti={true}
                          noIndicator={false}
                          noSeparator={false}
                          value={filterValue && filterValue[cFilter.filAN] ? filterValue[cFilter.filAN] : []}
                        />
                      </div>
                    </div>
                  </>
                ) : cFilter.filt == "searchabledropdown" ? (
                  <>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="form-group filtInp">
                        <span
                          className="mr-2 text-white"
                          style={{ float: "left" }}
                        >
                          <label className="col-form-label">
                            {cFilter.filn}
                          </label>
                        </span>
                        <SelectForm
                          isClearable
                          async
                        //   options={(searchString, cb) =>
                        //     handleFetchUsers(searchString, cb, cFilter.filAN)
                        //   }
                          options={[]}
                          placeholder={cFilter.filn}
                          isDisabled={false}
                          onChange={(event) => {
                            setFilterValue((prev) => {
                              return { ...prev, [cFilter.filAN]: event };
                            });

                            setStart(0);
                            setReload(!reload);
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={filterValue && filterValue[cFilter.filAN] ? filterValue[cFilter.filAN] : []}
                        />
                      </div>
                    </div>
                  </>
                ) : cFilter.filt === "text" ? (
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="form-group filtInp">
                      <InputForm
                        value={searchText}
                        placeholder={cFilter.filn}
                        isDisabled={false}
                        textArea={false}
                        onChange={(event) => {
                          setStart(0);
                          if (event) {
                            setSearchText(event.target.value);
                          }
                        }}
                      />
                    </div>
                  </div>
                ) : null}
              </React.Fragment>
            ))}
          <div className="col-lg-1 col-md-1 col-sm-12 filtrt">
            <ul className=" filter-icon-wel">
              <li>
                <a
                  href="javascript:void(0);"
                  onClick={() => setOpen(!open)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                  className="filter-btn"
                >
                  <i className="fa fa-filter"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="col-lg-12 col-md-10 col-sm-12 "></div>

      <>
        <Collapse in={open}>
          <div id="example-collapse-text grid-wrapper">
            <div className="row mx-auto myInlineStyle">
              {gridData &&
                gridData?.data?.d?.afa?.map((cFilter, index) => (
                  <>
                    {cFilter.filt === "multidropdown" ? (
                      <>
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                          <div className="form-group filtInp">
                              <label className="col-form-label text-white">
                                {cFilter.filn}
                              </label>
                            <SelectForm
                              isClearable
                              options={cFilter.filAN === 'responsibleRole' ?
                              rolesDropdownOptions : ddValue[cFilter.filAN]}
                              placeholder={cFilter.filn}
                              isDisabled={false}
                              onChange={(event) => {
                                setFilterValue((prev) => {
                                  return { ...prev, [cFilter.filAN]: event };
                                });
    
                                setStart(0);
                                setReload(!reload);
                              }}
                              isMulti={true}
                              noIndicator={false}
                              noSeparator={false}
                              value={filterValue && filterValue[cFilter.filAN] ? filterValue[cFilter.filAN] : []}
                            />
                          </div>
                        </div>
                      </>
                    ) : cFilter.filt === "text" ? (
                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                        <div className="form-group filtInp">
                          <InputForm
                            value={searchText}
                            placeholder={cFilter.filn}
                            isDisabled={false}
                            textArea={false}
                            onChange={(event) => {
                                setStart(0);
                                if (event) {
                                  setSearchText(event.target.value);
                                }
                              }}
                          />
                        </div>
                      </div>
                    ) : null}
                  </>
                ))}
              <div className="col-lg-12 d-flex mt-2 justify-content-end">
                <button
                  className="btn btn-secondary mr-2"
                  onClick={onClickReset}
                  >
                  <i className="fa fa-undo" aria-hidden="true"></i> Reset
                </button>
                <button
                  className="btn btn-filter-submit"
                  onClick={onSubmitFilter}
                  >
                  <i className="fa fa-check" aria-hidden="true"></i> Submit{" "}
                </button>
              </div>
            </div>
          </div>
        </Collapse>
      </>

      <div className="mb-3 pt-3">
        <DynamicGrid
          data={rowData}
          columns={columnsWithCustomRender}
          options={options}
        />
      </div>
        </div>
    );
};

export default TaskChecklistDashboard;

