import React, { useContext,useState, useEffect } from "react";
import "../Home/Home.css";
import DateForm from "../../Components/DateForm/DateForm";
import "./BlueCollarHomepage.css";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import AccessDenied from "../AccessDenied/AccessDenied";
import { Link } from "react-router-dom";
import { APICall } from "../../Helpers/APICalls";
import { GetAssociateCounts, GetDropdownData,GetBlueCollarBussinessDropdown } from "../../Helpers/APIEndPoints/AssociateEndPoints";
import SelectForm from "../../Components/SelectForm/SelectForm";
import WillLogo from "../../Assets/Images/WLL GPTW Logo1.png";
import Report from "../Report/Report";
import moment from "moment";
import { getDropdowns } from "../../Helpers/APIEndPoints/EndPoints";
import { LoaderContext } from "../../Helpers/Context/Context";
import { isEmpty } from "../../utils/isEmpty";

const BlueCollarHomePage = () => {
  let { userDetails, interfacePermissionRecord, currentRoleId }: any =
    useUserContext();

  const [formData, setFormData] = useState<IFormData>({ dob: new Date() });
  const [formData1, setFormData1] = useState<IFormData>({ dob: new Date() });
  const [options, setOptions] = useState<IFormDataCascade>({});
  const [formDataCascade, setFormDataCascade] = useState<IFormDataCascade>({});
  const [candidateposition, setCandidatePosition] = useState<any>(null);
  const [userLegalEntities, setUserLegalEntities] = useState<any>({});
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [locationOption, setlocationOption] = useState<any>([
    {
      value: 5614,
      label: "Anjar",
    },
  ]);
  
  const [location, setlocation] = useState<any>(null);
  const [legalEntityValue, setLegalEntityValue] = useState<any>(null);
  const [legalEntityLabelValue, setLegalEntityLabelValue] = useState<any>('');
  const [showWelspunLogo, setShowWelspunLogo] = useState<any>(false);

  interface IFormData {
    dob?: Date | string | boolean | any;
  }
  interface IOptions {
    label?: string;
    value?: number | string;
  }

  interface IFormDataCascade {
    business?: IOptions[] | string | any;
    sbu?: IOptions[] | string | any;
    legalEntity?: IOptions[] | string | any;
    location?: IOptions[] | string | any;
  }
  const today = new Date(); // Get today's date
  const yesterday = new Date(today); // Create a new date object with today's date
  let yest1 = yesterday.setDate(today.getDate() - 1);

  function onChange(event, code) {
    try {
      setFormData((prev) => {
        return { ...prev, [code]: event };
      });
    } catch (error) {
    } finally {
    }
  }

  const getDDData = async (AC, PID) => {
    const dropdowndataResponse = await APICall(getDropdowns, "POST", {
      AC,
      PID,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    const dropdownData = dropdowndataResponse.data;

    return dropdownData;
  };
  const formOnChange = async (event, DDName) => {
    showLoader();

    if (DDName === "business") {
      setShowWelspunLogo(false);
      setLegalEntityLabelValue('');
      setFormDataCascade((env) => {
        return {
          ...env,
          business: event,
          sbu: null,
          legalEntity: null,
          location: null,
        };
      });

      if (!isEmpty(event?.value)) {
        let sbuData = await getDDData("SBU", event?.value);
        setOptions((env) => {
          return {
            ...env,
            sbu: sbuData,
          };
        });
      }
    }

    if (DDName === "sbu") {
      setShowWelspunLogo(false);
      setLegalEntityLabelValue('');
      setFormDataCascade((env) => {
        return {
          ...env,
          sbu: event,
          legalEntity: null,
          location: null,
        };
      });

      if (!isEmpty(event?.value)) {
        let LENData = await getDDData("LEN", event?.value);
        // let userLenCodes = ['13000005', '13000011'];
        // let CustomizedLegalEntity = LENData.filter(item=>{
        //   if(userLenCodes.includes(item?.code)){
        //     return item
        //   }
        // })
        setOptions((env) => {
          return {
            ...env,
            legalEntity: LENData,
          };
        });
      }
    }

    if (DDName === "legalEntity") {
      if (!isEmpty(event?.value)) {
        let PLOCData = await getDDData("PLOC", event?.value);
        setOptions((env) => {
          return {
            ...env,
            location: PLOCData,
          };
        });
      }
      if(event?.code === '13000011'){
        setShowWelspunLogo(true);
      }
      else{
        setShowWelspunLogo(false);
      }
      let label = event?.label?.split('-');
      setLegalEntityLabelValue(label ? label[1] : '');
      setFormDataCascade((env) => {
        return {
          ...env,
          legalEntity: event,
          location:null,
        };
      });
    }
    if (DDName === "payrollLocation") {
      setFormDataCascade((env) => {
        return {
          ...env,
          location:event,
        };
      });
    }
    hideLoader();
  };
  const getAllDropdownData = async (userRoleMappingData) => {
    if (userRoleMappingData) {
      let businessOptions = await getDDData("BISP", "");
      if (userRoleMappingData?.BIS.length > 0) {
        businessOptions = businessOptions?.filter((option) => {
          let foundOption = userRoleMappingData?.BIS?.find(
            (env) => env === option?.value
          );
          return foundOption !== undefined; 
         })
      }

     

      

      setOptions((env) => {
        return {
          ...env,
          business: businessOptions,
        };
      });
    }
    else{
      let businessOptions = await getDDData("BISP", "");
      setOptions((env) => {
        return {
          ...env,
          business: businessOptions,
        };
      });
    }
  };

  function onChange1(event, code) {
    try {
      setFormData1((prev) => {
        return { ...prev, [code]: event };
      });
    } catch (error) {
    } finally {
    }
  }

  // useEffect(() => {
  //   if (formData?.dob) {
  //     getcandidateCount();
  //   }
  // }, [formData]);


  useEffect(() => {
    setlocation(locationOption[0]);
    (async () => {
      const userRoleMappingData = await getUserRoleMappingDataApiCall();

      await getAllDropdownData(userRoleMappingData);
    })();
  }, []);


  const getUserRoleMappingDataApiCall = async () => {
    
    const response = await APICall(GetBlueCollarBussinessDropdown, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    

    if (response?.status === 0) {
      let domainOUDetails = response?.data?.ouDetails;
      if (domainOUDetails !== null) {
        return JSON.parse(domainOUDetails);
      } else {
        return null;
      }
    }
  };



  
  const getcandidateCount = async () => {
    const response = await APICall(GetAssociateCounts, "POST", {
      Date: formData?.dob,
    });
    if (response?.data !== null) {
      console.log(response, "Candidateresource");
      setCandidatePosition(response?.data);
    }
  };
  return (
    <>
      <div className="form-main">
        <div className="page-title w-100">
          <div className="col-lg-12">
            <div className="row">
            {legalEntityLabelValue && (
            <div
                 className={`col-md-4 p-2 ${showWelspunLogo ? 'col-lg-11' : 'col-lg-12'}`}
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <h3 style={{ color: "white" }}>{legalEntityLabelValue}</h3>
              </div>)}

                {showWelspunLogo && (
                  <div className="col-lg-1 col-md-4 p-2 text-right">
                  <img src={WillLogo} style={{ width: "35px" }} />
                </div>
                )}
              </div>
            <div className="row">
            <div className="col-lg-3 col-md-4 p-2">
            <div className="mb-1">
            <label className="col-form-label text-white">
              Business
            </label>
                <SelectForm
                isClearable
                isSearchable
                  value={formDataCascade?.business}
                  placeholder={"Select Business"}
                  options={options?.business}
                  isOptionDisabled={(option) => option.isDisable}
                  onChange={async (e) => {
                    formOnChange(e, "business");
                  }}
                  isDisabled={false}
                  noIndicator={false}
                  noSeparator={false}
                />
                </div>
              </div>
              <div className="col-lg-3 col-md-4 p-2">
              <div className="mb-1">
            <label className="col-form-label text-white">
              SBU
            </label>

            <SelectForm
            isClearable
                isSearchable
                value={formDataCascade?.sbu}
                placeholder={"Select SBU"}
                options={options?.sbu}
                isOptionDisabled={(option) => option.isDisable}
                onChange={async (e) => {
                  formOnChange(e, "sbu");
                }}
                isDisabled={false}
                noIndicator={false}
                noSeparator={false}
              />
              </div>
            </div>
            <div className="col-lg-3 col-md-4 p-2">
            <div className="mb-1">
            <label className="col-form-label text-white">
              Legal Entity
            </label>
                <SelectForm
                isClearable
                isSearchable
                  value={formDataCascade?.legalEntity}
                  placeholder={"Select Legal Entity"}
                  options={options?.legalEntity}
                  onChange={async (e) => {
                    formOnChange(e, "legalEntity");
                  }}
                  isDisabled={false}
                  noIndicator={false}
                  noSeparator={false}
                />
                </div>
              </div>
              <div className="col-lg-3 col-md-4 p-2">
                {/* <h4>Security Screening</h4> */}
                {/* <h4>Candidate Authentication</h4> */}
                <div className="mb-1">
            <label className="col-form-label text-white">
            Location
            </label>
                <SelectForm
                isClearable
                isSearchable
                  value={formDataCascade?.location}
                  placeholder={"Select Location"}
                  options={options?.location}
                  onChange={async (e) => {
                    formOnChange(e, "payrollLocation");
                  }}
                  isDisabled={false}
                  noIndicator={false}
                  noSeparator={false}
                />
              </div>
             </div>
              
              
            </div>
          </div>
        </div>
      </div>

      <div className="form-main">
        <div className="">
          <div className="col-lg-12">
            <div className="row">
              <div
                className="col-lg-12 col-md-4 p-2 ml-2"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                {/* <h4>Security Screening</h4> */}
                <h4>Blue Collar - Recruitment Stage</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      {currentRoleId?.label === "Associate Recruitment" ? (
        <div className="container-fluid">
          <div className="row quick_link blueCollarLinks">
            {interfacePermissionRecord?.find(
              (e) => e.interfaceName === "Document_Screen"
            ) && (
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <Link
                  to="/CandidateAuthentication"
                  style={{
                    pointerEvents: "auto",
                  }}
                >
                  <div
                    className="quicklinks_grid"
                    data-toggle="tooltip"
                    title=""
                  >
                    <div className="imgText">
                      <img
                        className="img-grid"
                        src={require("../../Assets/Images/authentication.png")}
                        alt="Candidate Authentication"
                      />
                      <p className="text-grid">Candidate Authentication</p>
                    </div>
                  </div>
                </Link>
              </div>
            )}

            {interfacePermissionRecord?.find(
              (e) => e.interfaceName === "Werner_Test"
            ) && (
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <Link
                  to="/WernerSkillTest"
                  style={{
                    pointerEvents: "auto",
                  }}
                >
                  <div
                    className="quicklinks_grid"
                    data-toggle="tooltip"
                    title=""
                  >
                    <div className="imgText">
                      <img
                        className="img-grid"
                        src={require("../../Assets/Images/Candidate_Status.png")}
                        alt="Candidate Status"
                      />
                      <p className="text-grid">Werner Test</p>
                    </div>
                  </div>
                </Link>
              </div>
            )}

            {interfacePermissionRecord?.find(
              (e) => e.interfaceName === "Medical_Screen"
            ) && (
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <Link
                  to="/MedicalScreen"
                  style={{
                    pointerEvents: "auto",
                  }}
                >
                  <div
                    className="quicklinks_grid"
                    data-toggle="tooltip"
                    title=""
                  >
                    <div className="imgText">
                      <img
                        className="img-grid"
                        src={require("../../Assets/Images/medical-test.png")}
                        alt="Medical Test"
                      />
                      <p className="text-grid">Medical Test</p>
                    </div>
                  </div>
                </Link>
              </div>
            )}

            {interfacePermissionRecord?.find(
              (e) => e.interfaceName === "MainGateEntry"
            ) && (
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <Link
                  to="/MainGateEntry"
                  style={{
                    pointerEvents: "auto",
                  }}
                >
                  <div
                    className="quicklinks_grid"
                    data-toggle="tooltip"
                    title=""
                  >
                    <div className="imgText">
                      <img
                        className="img-grid"
                        src={require("../../Assets/Images/Main_Gate_Entry.png")}
                        alt="Main Gate Entry"
                      />
                      <p className="text-grid">Main Gate Entry</p>
                    </div>
                  </div>
                </Link>
              </div>
            )}
            {interfacePermissionRecord?.find(
              (e) => e.interfaceName === "PlantGateEntry"
            ) && (
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <Link
                  to="/PlantGateEntry"
                  style={{
                    pointerEvents: "auto",
                  }}
                >
                  <div
                    className="quicklinks_grid"
                    data-toggle="tooltip"
                    title=""
                  >
                    <div className="imgText">
                      <img
                        className="img-grid"
                        src={require("../../Assets/Images/security-gate.png")}
                        alt="Plant Gate Entry"
                      />
                      <p className="text-grid">Plant Gate Entry</p>
                    </div>
                  </div>
                </Link>
              </div>
            )}

            {interfacePermissionRecord?.find(
              (e) => e.interfaceName === "Handover"
            ) && (
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <Link
                  to="/Handover"
                  style={{
                    pointerEvents: "auto",
                  }}
                >
                  <div
                    className="quicklinks_grid"
                    data-toggle="tooltip"
                    title=""
                  >
                    <div className="imgText">
                      <img
                        className="img-grid"
                        src={require("../../Assets/Images/Instructor_Handover.png")}
                        alt="Instructor Handover"
                      />
                      <p className="text-grid">Instructor Handover</p>
                    </div>
                  </div>
                </Link>
              </div>
            )}

            {interfacePermissionRecord?.find(
              (e) => e.interfaceName === "Skill_Test"
            ) && (
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <Link
                  to="/SkillTest"
                  style={{
                    pointerEvents: "auto",
                  }}
                >
                  <div
                    className="quicklinks_grid"
                    data-toggle="tooltip"
                    title=""
                  >
                    <div className="imgText">
                      <img
                        className="img-grid"
                        src={require("../../Assets/Images/Skill_Test.png")}
                        alt="Skill Test"
                      />
                      <p className="text-grid">Skill Test</p>
                    </div>
                  </div>
                </Link>
              </div>
            )}

            {interfacePermissionRecord?.find(
              (e) => e.interfaceName === "UAN"
            ) && (
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <Link
                  to="/CanteenDesk"
                  style={{
                    pointerEvents: "auto",
                  }}
                >
                  <div
                    className="quicklinks_grid"
                    data-toggle="tooltip"
                    title=""
                  >
                    <div className="imgText">
                      <img
                        className="img-grid"
                        src={require("../../Assets/Images/Canteen_Desk.png")}
                        alt="Canteen Desk"
                      />
                      <p className="text-grid">Canteen Desk</p>
                    </div>
                  </div>
                </Link>
              </div>
            )}

            {interfacePermissionRecord?.find(
              (e) => e.interfaceName === "HandoverBackToInst"
            ) && (
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <Link
                  to="/HandoverToInstructorBack"
                  style={{
                    pointerEvents: "auto",
                  }}
                >
                  <div
                    className="quicklinks_grid"
                    data-toggle="tooltip"
                    title=""
                  >
                    <div className="imgText">
                      <img
                        className="img-grid"
                        src={require("../../Assets/Images/Instructor_Handover_Return.png")}
                        alt="Instructor Handover Return"
                      />
                      <p className="text-grid">Instructor Handover Return</p>
                    </div>
                  </div>
                </Link>
              </div>
            )}

            {interfacePermissionRecord?.find(
              (e) => e.interfaceName === "PlantGateExit"
            ) && (
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <Link
                  to="/PlantGateExit"
                  style={{
                    pointerEvents: "auto",
                  }}
                >
                  <a target="_blank" rel="noreferrer">
                    <div
                      className="quicklinks_grid"
                      data-toggle="tooltip"
                      title=""
                    >
                      <div className="imgText">
                        <img
                          className="img-grid"
                          src={require("../../Assets/Images/Plant_Gate_Exit.png")}
                          alt="Plant Gate Exit"
                        />
                        <p className="text-grid">Plant Gate Exit</p>
                      </div>
                    </div>
                  </a>
                </Link>
              </div>
            )}

            {interfacePermissionRecord?.find(
              (e) => e.interfaceName === "MainGateExit"
            ) && (
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <Link
                  to="/MainGateExit"
                  style={{
                    pointerEvents: "auto",
                  }}
                >
                  <div
                    className="quicklinks_grid"
                    data-toggle="tooltip"
                    title=""
                  >
                    <div className="imgText">
                      <img
                        className="img-grid"
                        src={require("../../Assets/Images/Main_Gate_Exit.png")}
                        alt="Main Gate Exit"
                      />
                      <p className="text-grid">Main Gate Exit</p>
                    </div>
                  </div>
                </Link>
              </div>
            )}

            {interfacePermissionRecord?.find(
              (e) => e.interfaceName === "UAN"
            ) && (
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <Link
                  to="/UAN"
                  style={{
                    pointerEvents: "auto",
                  }}
                >
                  <div
                    className="quicklinks_grid"
                    data-toggle="tooltip"
                    title=""
                  >
                    <div className="imgText">
                      <img
                        className="img-grid"
                        src={require("../../Assets/Images/UAN_Verification.png")}
                        alt="UAN Verification"
                      />
                      <p className="text-grid">UAN Verification</p>
                    </div>
                  </div>
                </Link>
              </div>
            )}

            {interfacePermissionRecord?.find(
              (e) => e.interfaceName === "Appointment"
            ) && (
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <Link
                  to="/AppointmentScreen"
                  style={{
                    pointerEvents: "auto",
                  }}
                >
                  <div
                    className="quicklinks_grid"
                    data-toggle="tooltip"
                    title=""
                  >
                    <div className="imgText">
                      <img
                        className="img-grid"
                        src={require("../../Assets/Images/Appointment.png")}
                        alt="Appointment"
                      />
                      <p className="text-grid">Appointment</p>
                    </div>
                  </div>
                </Link>
              </div>
            )}

            {interfacePermissionRecord?.find(
              (e) => e.interfaceName === "CandidateQueue"
            ) && (
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <Link
                  to="/CandidateQueue"
                  style={{
                    pointerEvents: "auto",
                  }}
                >
                  <div
                    className="quicklinks_grid"
                    data-toggle="tooltip"
                    title=""
                  >
                    <div className="imgText">
                      <img
                        className="img-grid"
                        src={require("../../Assets/Images/Candidate_Queue.png")}
                        alt="Candidate Queue"
                      />
                      <p className="text-grid">Candidate Queue</p>
                    </div>
                  </div>
                </Link>
              </div>
            )}

            {interfacePermissionRecord?.find(
              (e) => e.interfaceName === "CandidateStatus"
            ) && (
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <Link
                  to="/CandidateStatus"
                  style={{
                    pointerEvents: "auto",
                  }}
                >
                  <div
                    className="quicklinks_grid"
                    data-toggle="tooltip"
                    title=""
                  >
                    <div className="imgText">
                      <img
                        className="img-grid"
                        src={require("../../Assets/Images/Candidate_Status.png")}
                        alt="Candidate Status"
                      />
                      <p className="text-grid">Candidate Status</p>
                    </div>
                  </div>
                </Link>
              </div>
            )}

            {interfacePermissionRecord?.find(
              (e) => e.interfaceName === "HR_Analytics"
            ) && (
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <Link
                  to="https://ems-urlprotect.trendmicro.com/wis/clicktime/v1/query?url=https%3a%2f%2fapp.powerbi.com%2fgroups%2fdb653d6a-6e01-4d3b-ac0a-8d6335207beb%2freports%2f0354f3e3-f259-4499-8cd2-c1ddc67e8241%2fReportSection8b6830f3e1104d96e369%3fexperience%3dpower-bi%26clientSideAuth%3d0&umid=61e271a4-6b14-4f8f-97d6-281b86577b26&auth=6649641abc5b8719c4a47fb718ab516a4f767c1e-ebbdcd74da345a3eae0e0be59fbbfb273f7bf3f9"
                  style={{
                    pointerEvents: "auto",
                  }}
                  target="_blank"
                >
                  <div
                    className="quicklinks_grid"
                    data-toggle="tooltip"
                    title=""
                  >
                    <div className="imgText">
                      <img
                        className="img-grid"
                        src={require("../../Assets/Images/PowerBi.png")}
                        alt="HR-Analytics"
                      />
                      <p className="text-grid">Dashboards</p>
                    </div>
                  </div>
                </Link>
              </div>
            )}

            {interfacePermissionRecord?.find(
              (e) =>
                e.interfaceName === "Departmentwise" ||
                e.interfaceName === "TATLogsheet" ||
                e.interfaceName === "DailyRecruitment"
            ) && (
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <Link
                  to="/ReportingDashboard"
                  style={{ pointerEvents: "auto" }}
                  //  onClick={handleReportClick}
                >
                  <div
                    className="quicklinks_grid"
                    data-toggle="tooltip"
                    title=""
                  >
                    <div className="imgText">
                      <img
                        className="img-grid"
                        src={require("../../Assets/Images/book.png")}
                        alt="Candidate Status"
                      />
                      <p className="text-grid">Report</p>
                    </div>
                  </div>
                </Link>
              </div>
            )}

            {interfacePermissionRecord?.find(
              (e) => e.interfaceName === "OverallProcess"
            ) && (
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <Link
                  to="/OverallProcessDashboard"
                  style={{
                    pointerEvents: "auto",
                  }}
                >
                  <div
                    className="quicklinks_grid"
                    data-toggle="tooltip"
                    title=""
                  >
                    <div className="imgText">
                      <img
                        className="img-grid"
                        src={require("../../Assets/Images/book.png")}
                        alt="Candidate Status"
                      />
                      <p className="text-grid">OverAll Process Dashboard</p>
                    </div>
                  </div>
                </Link>
              </div>
            )}

            {interfacePermissionRecord?.find(
              (e) => e.interfaceName === "MedicalDashboard"
            ) && (
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <Link
                  to="/MedicalDashboard"
                  style={{
                    pointerEvents: "auto",
                  }}
                >
                  <div
                    className="quicklinks_grid"
                    data-toggle="tooltip"
                    title=""
                  >
                    <div className="imgText">
                      <img
                        className="img-grid"
                        src={require("../../Assets/Images/book.png")}
                        alt="Candidate Status"
                      />
                      <p className="text-grid">Medical Dashboard</p>
                    </div>
                  </div>
                </Link>
              </div>
            )}

            {interfacePermissionRecord?.find(
              (e) => e.interfaceName === "AppointmentDashboard"
            ) && (
              <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <Link
                  to="/AppointmentDashboard"
                  style={{
                    pointerEvents: "auto",
                  }}
                >
                  <div
                    className="quicklinks_grid"
                    data-toggle="tooltip"
                    title=""
                  >
                    <div className="imgText">
                      <img
                        className="img-grid"
                        src={require("../../Assets/Images/book.png")}
                        alt="Candidate Status"
                      />
                      <p className="text-grid">Appointment Dashboard</p>
                    </div>
                  </div>
                </Link>
              </div>
            )}
          </div>
          {/* <div className="row grid-wrapper d-flex align-items-center my-3 mx-0">
            <div className="col-lg-9 col-md-9 col-sm-12 acc-heading text-white ">
              <p style={{ fontSize: "16px;", fontWeight: "600" }}>
                Vacant Position Status
              </p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 cal_box">
              <DateForm
                isDisabled={false}
                value={formData?.dob || null}
                onChange={(val: any) => {
                  onChange(val, "dob");
                }}
                maxDate={new Date()}
                minDate={yest1}
                style={{ borderRadius: "5px" }}
              />
            </div>
          </div>
          {/* <div className="row d-flex justify-content-space-between vacant mb-4">
            <div className="col">
              <div className="vacant_counts">
                <h3>{candidateposition?.openPosition} </h3>
                <h4>Open Position</h4>
              </div>
            </div>
            <div className="col ">
              <div className="vacant_counts">
                <h3>{candidateposition?.totalWalkIn} </h3>
                <h4>Walking Candidate</h4>
              </div>
            </div>
            <div className="col">
              <div className="vacant_counts">
                <h3>{candidateposition?.movedToEp}</h3>
                <h4>Convert to EP</h4>
              </div>
            </div>
            <div className="col">
              <div className="vacant_counts">
                <h3>{candidateposition?.closedPosition} </h3>
                <h4>Closed Position</h4>
              </div>
            </div>
            <div className="col">
              <div className="vacant_counts">
                <h3>{candidateposition?.remainingPosition}</h3>
                <h4>Remaining Position</h4>
              </div>
            </div>
          </div> */}

          <div className="row grid-wrapper d-flex align-items-center my-3 mx-0">
            <div className="col-lg-9 col-md-9 col-sm-12 acc-heading text-white ">
              <p style={{ fontSize: "16px;", fontWeight: "600" }}>TAT Grid</p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 cal_box">
              <DateForm
                isDisabled={false}
                value={formData1?.dob || null}
                onChange={(val: any) => {
                  onChange1(moment(val).format("MM-DD-YYYY"), "dob");
                }}
                style={{ borderRadius: "5px" }}
              />
            </div>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12">
            {formData1.dob && <Report dob={formData1.dob} 
            business={!isEmpty(formDataCascade?.business?.value) ? formDataCascade?.business?.value : 0}
            sbu={!isEmpty(formDataCascade?.sbu?.value) ? formDataCascade?.sbu?.value : 0}
            legalEntity={!isEmpty(formDataCascade?.legalEntity?.value) ? formDataCascade?.legalEntity?.value : 0}
            location={!isEmpty(formDataCascade?.location?.value) ? formDataCascade?.location?.value : 0} />}
          </div>
        </div>
      ) : (
        <div>
          <AccessDenied />
        </div>
      )}
    </>
  );
};

export default BlueCollarHomePage;
