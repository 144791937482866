import React, {
  useState,
  useEffect,
  useRef,
  createRef,
  useContext,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import asideIcon from "./right_panel.svg";
import Logo from "../../Assets/Images/afcons_logo.png";
import WelspunLogo from "../../Assets/Images/final_logo@2x.png";
import WelspunSolgan from "../../Assets/Images/WCL_slogan1.png";
import "./index.css";
import {
  getEntityFilter,
  getPermissionRecordRoleMappingByRoleId,
  getProxyUserDropdownData,
  getRoleDetailsByUserId,
  getRolesDetails,
  getUserRoleMappingDetails,
  logoutUserById,
  updateUserMasterByUserIdandRoleId,
  AddLoginLogoutHistory,
  UpdateLogoutHistoryForProxy,
  getSectionPermissionRecordRoleMappingByRoleId,
} from "../../Helpers/APIEndPoints/EndPoints";
import { clearUserData, setUserRole, updateRole } from "../../Redux/Actions";
import { Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { LoaderContext, getContext } from "../../Helpers/Context/Context";
import SelectForm from "../../Components/SelectForm/SelectForm";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import async from "react-select/dist/declarations/src/async/index";
import { APICall } from "../../Helpers/APICalls";

const Header = (props) => {
  const [search, setSearch] = useState<any>();
  const searchfunction = (value) => {
    setSearch(value);
  };
  const navigate = useNavigate();
  const location = useLocation();

  const { showLoader, hideLoader } = useContext(LoaderContext);

  // const userDetails = getContext();
  let {
    userDetails,
    addUserDetails,
    deleteUserDetails,
    updateCurrentRoleId,
    currentRoleId,
    updateInterfacePermissionRecord,
    interfacePermissionRecord,
    updateSectionInterfacePermissionRecord,
    sectionInterfacePermissionRecord,
    proxyUserDetails,
    updateProxyUserDetails,
    proxyUserRoleDetails,
    updateProxyUserRoleDetails,
    proxyUserRoleOptions,
    updateProxyUserRoleOptions,
  }: any = useUserContext();
  // const { userData } = useSelector((state): any => state);
  const [roleValue, setRoleValue] = useState<any>();
  const [rolesArray, setRolesArray] = useState([]);

  //For Proxy User

  const [isProxyUserRole, setIsProxyUserRole] = useState(false);

  const [showProxyModel, setShowProxyModel] = useState(false);
  // for getting main user Id while activating proxy user for adding record of proxy login
  const [coreUserId, setCoreUserId] = useState(false);

  const dispatch = useDispatch();

  /*
    IF THER ARE MULTIPLE ROLES THEN REMOVE CANDIDATE ROLE.
  */
  useEffect(() => {
    let arr = userDetails?.UserRoles?.map((ele) => ({
      label: ele.Role,
      value: ele.RoleId,
      isProxyUserRole: ele.IsProxyUserRole,
      code: ele?.Code,
      oudetails: ele?.OuDetails,
    }));

    if (arr?.length == 1 && arr[0]?.code == "Candidate") {
      setRolesArray(arr);
    } else {
      arr = arr.filter((role) => role?.code != "Candidate");
      setRolesArray(arr);
    }

    setCoreUserId(userDetails?.Id);
  }, []);

  useEffect(() => {
    (async () => {
      await getRoleDetailsByUserIdAPICall();
    })();
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     await getRoleDetailsByUserIdAPICall();
  //   })();
  // }, []);

  // useEffect(() => {
  //   ;
  //   if (rolesArray && rolesArray.length > 0) {
  //     const roles = rolesArray;

  //     console.log("userDetails 1 ", userDetails);

  //     const role = roles.filter((r) => r.value === userDetails.loggedInAs);

  //     setRoleValue(role);
  //   }
  // }, [rolesArray]);

  const Logout = async () => {
    //api call to logout user from backend
    // (async () => {
    //   const apiRes = await APICall(logoutUserById, "POST", {
    //     LoginId: userDetails.LoginId,
    //   });
    // })();

    // setTimeout(async () => {
    const response = await APICall(updateUserMasterByUserIdandRoleId, "POST", {
      UserId: userDetails?.Id,
      RoleId: null,
      isLogout: true,
    });
    deleteUserDetails();
    dispatch(clearUserData({}));
    navigate("/", { replace: true });
    // }, 3000);
  };

  // console.log(userDetails);
  const popover = (
    <Popover>
      {/* <Popover.Header>{userData.name}</Popover.Header> */}
      <Popover.Body>
        <a
          className="ft-16"
          style={{
            textDecoration: "none",
          }}
          onClick={() => {
            // deleteCookie("ApplicationToken");
            // return true;
            stopProxyUserOnLogout();
            Logout();
          }}
          // rel="noopener noreferrer"
          // href={"http://localhost:3000/login"}
        >
          Logout
        </a>
      </Popover.Body>
    </Popover>
  );

  // console.log("currentRoleId 1", currentRoleId);

  // console.log("interfacePermissionRecord", interfacePermissionRecord);

  // console.log("proxyUserDetails", proxyUserDetails);

  // console.log("proxyUserRoleDetails", proxyUserRoleDetails);

  // console.log("Roles Array", rolesArray);

  //User Role Change
  const onRoleChange = async (role) => {
    showLoader();

    setRoleValue(role);

    updateCurrentRoleId(role);

    setIsProxyUserRole(role.isProxyUserRole);

    if (!role.isProxyUserRole) {
      updateProxyUserDetails(null);

      updateProxyUserRoleDetails(null);
    }

    const response = await APICall(updateUserMasterByUserIdandRoleId, "POST", {
      UserId: userDetails?.Id,
      RoleId: role.value,
      isLogout: false,
    });

    if (response.status == 0) {
      // window.location.reload();
      window.location.href = "/home";
    }

    hideLoader();

    // navigate("/home");
  };

  //On Change For Proxy
  const onChangeForProxy = async (Event, fieldName) => {
    showLoader();
    if (fieldName === "proxyUser") {
      updateProxyUserDetails(Event);

      updateProxyUserRoleDetails(null);

      await getProxyRoleDropdownDetails(Event?.value);

      userDetails.Id = Event?.value;

      addUserDetails(userDetails);
    }

    if (fieldName === "proxyRole") {
      updateProxyUserRoleDetails(Event);

      updateCurrentRoleId(Event);
      navigate("/home");
    }
    hideLoader();
  };

  //Get Proxy Role DropDown
  /*
    Remove Candidate Role if there are multiple role.
  */
  const getProxyRoleDropdownDetails = async (userId) => {
    showLoader();
    debugger;
    const response = await APICall(getUserRoleMappingDetails, "POST", {
      UserId: userId,
    });

    if (response.data && response.data.length > 0) {
      let options = await response.data;

      options = options?.map((ele) => ({
        label: ele.role,
        value: ele.roleId,
        code: ele.code,
        oudetails: ele?.ouDetails,
      }));

      if (options?.length === 1 && options[0]?.code === "Candidate") {
        updateProxyUserRoleOptions(options);
        updateProxyUserRoleDetails(options[0]);
        updateCurrentRoleId(options[0]);
      } else {
        options = options?.filter((role) => role?.code !== "Candidate");

        updateProxyUserRoleOptions(options);
        updateProxyUserRoleDetails(options[0]);
        updateCurrentRoleId(options[0]);
      }

      // if (options.length > 0) {
      //   // updateProxyUserRoleOptions(options);
      //   // updateProxyUserRoleDetails(options[0]);
      //   // updateCurrentRoleId(options[0]);
      // }
    } else {
      updateProxyUserRoleOptions([]);
    }
    hideLoader();
  };

  //Get Proxy User DropDown
  const getProxyUserDropdownDetails = async (e, callback) => {
    if (e.length >= 3) {
      let requestParams = {
        searchText: e,
      };
      const response = await APICall(
        getProxyUserDropdownData,
        "POST",
        requestParams
      );

      if (response.data && response.data.length > 0) {
        let options = await response.data;

        options = await options?.map((ele) => ({
          label: ele.label,
          value: ele.value,
          FirstName: ele.firstName,
          LastName: ele.lastName,
          EmailId: ele.emailId,
          EmployeeCode: ele.employeeCode,
          EmployeeId: ele.employeeId,
          COU: ele.cou,
          BId: ele.bId,
        }));

        await callback(options);
      }
    }
  };

  //Get currentlyLoginRoleId
  const getRoleDetailsByUserIdAPICall = async () => {
    showLoader();

    let roleObj;

    let isProxy = false;

    //getUserAndInterfacePermissionRecord

    let userId;
    let roleId;

    if (proxyUserDetails === null && proxyUserRoleDetails === null) {
      userId = userDetails?.Id;

      const response = await APICall(getRoleDetailsByUserId, "POST", {
        Id: userId,
      });

      if (response?.data?.currentlyLoginRoleId > 0) {
        roleObj = userDetails?.UserRoles?.find(
          (ele) => ele.RoleId === response.data.currentlyLoginRoleId
        );

        roleObj = {
          label: roleObj?.Role,
          value: roleObj?.RoleId,
          code: roleObj?.Code,
          oudetails: roleObj?.OuDetails,
        };

        isProxy = response?.data?.isProxyUserRole;
      } else {
        let isEmployeeRole = userDetails?.UserRoles?.find(
          (ele) => ele.Role === "Employee"
        );
        if (isEmployeeRole) {
          roleObj = {
            label: isEmployeeRole?.Role,
            value: isEmployeeRole?.RoleId,
            code: isEmployeeRole?.Code,
            oudetails: isEmployeeRole?.OuDetails,
          };
        } else {
          roleObj = {
            label: userDetails?.UserRoles[0]?.Role,
            value: userDetails?.UserRoles[0]?.RoleId,
            code: userDetails?.UserRoles[0]?.Code,
            oudetails: userDetails?.UserRoles[0]?.OuDetails,
          };
        }

        isProxy = userDetails?.UserRoles[0]?.RoleId.IsProxyUserRole;
      }

      setIsProxyUserRole(isProxy);

      if (!isProxy) {
        updateProxyUserDetails(null);

        updateProxyUserRoleDetails(null);
      } else {
        updateProxyUserDetails(proxyUserDetails);
      }
      roleId = roleObj?.value;
      setRoleValue(roleObj);
      updateCurrentRoleId(roleObj);

      await getInterfacePermissionRecord(userId, roleId);
      await getInterfaceSectionsPermissionRecord(userId, roleId);
    } else {
      userId = userDetails?.Id;
      roleId = proxyUserRoleDetails?.value;

      updateCurrentRoleId(proxyUserRoleDetails);

      updateProxyUserRoleDetails(proxyUserRoleDetails);
      // await getUserAndInterfacePermissionRecordAPICall(userId, roleId);
      // await getProxyRoleDropdownDetails(userId);
      await getInterfacePermissionRecord(userId, roleId);
      await getInterfaceSectionsPermissionRecord(userId, roleId);
    }

    // console.log("proxyUserDetails 290", proxyUserDetails);

    // console.log("proxyUserRoleDetails 292", proxyUserRoleDetails);

    // navigate("/home");

    hideLoader();
  };

  //Get Interface Permission record
  const getInterfacePermissionRecord = async (userId, roleId) => {
    const response = await APICall(
      getPermissionRecordRoleMappingByRoleId,
      "POST",
      {
        UserId: userId,
        RoleId: roleId,
      }
    );

    if (response?.data && response?.data.length > 0) {
      updateInterfacePermissionRecord(response?.data);
    } else {
      updateInterfacePermissionRecord(null);
    }
  };

  //Get Interface Sections all Permission record
  const getInterfaceSectionsPermissionRecord = async (userId, roleId) => {
    const response = await APICall(
      getSectionPermissionRecordRoleMappingByRoleId,
      "POST",
      {
        UserIdToGetSectionPermission: userId,
        RoleIdToGetSectionPermission: roleId,
      }
    );

    if (response?.data && response?.data.length > 0) {
      updateSectionInterfacePermissionRecord(response?.data);
    } else {
      updateSectionInterfacePermissionRecord(null);
    }
  };

  const stopProxyUserFunction = async () => {
    showLoader();

    updateProxyUserDetails(null);

    updateProxyUserRoleDetails(null);

    setShowProxyModel(false);

    userDetails.Id = userDetails.UserId;

    addUserDetails(userDetails);

    const res = await APICall(UpdateLogoutHistoryForProxy, "POST", {
      UserId: userDetails.UserId,
    });

    // window.location.reload();
    window.location.href = "/home";

    hideLoader();
  };

  const stopProxyUserOnLogout = () => {
    showLoader();
    updateProxyUserDetails(null);
    updateProxyUserRoleDetails(null);
    setShowProxyModel(false);
    userDetails.Id = userDetails.UserId;
    addUserDetails(userDetails);
    hideLoader();
  };

  const onClickOkFunction = async () => {
    // window.location.reload();

    // adding proxy login record
    const res = await APICall(AddLoginLogoutHistory, "POST", {
      ProxyUserId: userDetails?.Id,
      SessionId: "",
      UserId: coreUserId,
    });
    window.location.href = "/home";
  };

  // const [roleId, setRoleId] = useState(() => {
  //   if (userData?.isVendor && props.userRoles.length === 0) {
  //     return 0;
  //   } else return props.userRoles[0].id;
  // });

  // const getRoles = async (Id) => {
  //   // call roles api
  //   const res = await fetch(getRolesDetails, {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({ Id }),
  //   });
  //   const rolesData = await res.json();
  //   console.log(rolesData);

  //   if (rolesData.status === 0 && rolesData.data.length > 0) {
  //     setUserRoles(rolesData.data);
  //     getEntityFilterArr(rolesData.data[0].id, userData.id);
  //   } else {
  //     console.log("roles error", rolesData);
  //   }
  // };

  // const getEntityFilterArr = async (roleId, userId) => {
  //   const res = await fetch(getEntityFilter, {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({ roleId, userId }),
  //   });
  //   const entityData = await res.json();
  //   console.log(entityData);

  //   if (entityData.status === 0 && entityData.data.length > 0) {
  //   } else {
  //     console.log("entity error", entityData);
  //   }
  // };

  // useEffect(() => {
  //   getRoles(userData.id);
  // }, []);

  return (
    // <>
    //   <header id="header">
    //     <div className="headerbar">
    //       <div className="headerbar-left left-logo">
    //         <ul className="header-nav header-nav-options">
    //           <li
    //             className="sandwich-menu"
    //             style={{ marginTop: "-10px" }}
    //             onClick={() => props.menuOpenFunc()}
    //           >
    //             <a className="btn btn-icon-toggle btn-default menubar-toggle">
    //               <i className="fa fa-bars"></i>{" "}
    //             </a>{" "}
    //           </li>
    //           <li className="header-nav-brand">
    //             <div className="brand-holder">
    //               {/* {props.defaultModulePath && (
    //                 <Link to={props.defaultModulePath.link}>
    //                   <img
    //                     src={props.logoId.current}
    //                     alt="Logo"
    //                     className="logo"
    //                   />
    //                 </Link>
    //               )} */}
    //               <a onClick={(e) => e.preventDefault()} href="/">
    //                 {" "}
    //                 <img src={Logo} alt="" className="logo" />
    //               </a>
    //               {/* <a href="/" className="page-header ml-2">
    //                 {" "}
    //                 Competitor Announcements
    //               </a> */}
    //             </div>
    //           </li>
    //         </ul>
    //       </div>
    //       <div className="headerbar-right">
    //         <ul className="header-nav header-nav-options header-nav-profile">
    //           {/* {props.headerData.showSearch && (
    //             <li
    //               className=""
    //               style={{ maxWidth: "20rem", marginTop: "-5px" }}>
    //               <SASelect
    //                 isSearchable
    //                 async
    //                 maxWidth="40"
    //                 isMulti
    //                 value={search}
    //                 onChange={searchfunction}
    //                 placeholder="What are you looking for? 🔍"
    //                 options={[]}
    //                 noOptionsMessage={(e) => (e.inputValue ? "" : null)}
    //                 noIndicator
    //               />

    //             </li>
    //           )} */}
    //           {/* <!-- <li className="p-2">
    //                     <div className="search-header">
    //                         <input className="form-control searchbox-header" placeholder="What are you looking for ?">
    //                         <i className="fa fa-search" aria-hidden="true"></i>
    //                     </div>
    //                 </li> --> */}
    //           {/* {!props.headerData.showRoles &&
    //             !userData?.isVendor &&
    //             props.userRoles.length > 0 && (
    //               <li className="Role_select">
    //                 <div className=" dropdown">
    //                   {props.userRoles.length > 0 && (
    //                     <select
    //                       onChange={(e) => {
    //                         props.setSelectedRoleId(e.target.value);

    //                         setRoleId(e.target.value);

    //                         dispatch(setUserRole(Number(e.target.value)));
    //                       }}
    //                       defaultValue={roleId}>
    //                       {props.userRoles.length > 0 &&
    //                         props.userRoles.map((role, index) => (
    //                           <option key={index} value={role.id}>
    //                             {role.roleName}
    //                           </option>
    //                         ))}
    //                     </select>
    //                   )}
    //                 </div>
    //               </li>
    //             )} */}

    //           {/* {props.headerData.showNotification && (
    //             <li className="notification dropdown">
    //               <div className="dropdown-toggle" data-toggle="dropdown">
    //                 <a href="javascript:void(0);">
    //                   <i className="fa fa-bell" aria-hidden="true"></i>
    //                   <span className="notify">3</span>
    //                 </a>
    //               </div>
    //               <ul className="dropdown-menu p-1">
    //                 <div className="notification-panel card">
    //                   <div className="card-body">
    //                     <p className="notification_title">Notifications</p>
    //                     <div className="card-noti">
    //                       Expense report for trip{" "}
    //                       <a href="javascript:void(0);">PU9M</a>
    //                       mumbai-Zurich-Mumbai is due.
    //                     </div>
    //                     <div className="card-noti">
    //                       Bhavesh Panchal (HOD) has raised query re trip{" "}
    //                       <a href="javascript:void(0);">JB31</a>
    //                       Mumbai-Hyderabad-Mumbai
    //                     </div>
    //                   </div>
    //                 </div>
    //               </ul>
    //             </li>
    //           )} */}
    //           <li>
    //             <div className="user-profile dropdown">
    //               <div className="dropdown-toggle" data-toggle="dropdown">
    //                 <a>
    //                   <span
    //                     style={{ color: "white" }}
    //                     className="role-icon far fa-user-circle"
    //                     data-toggle="tooltip"
    //                     title="Profile"
    //                   ></span>
    //                   <span className="welcome desktop"></span>
    //                 </a>
    //               </div>
    //               <ul className="dropdown-menu p-1">
    //                 {/* <li className="welcome mobile">
    //                   <a href="!#">
    //                     <span className="">UserName</span>
    //                   </a>
    //                 </li> */}
    //                 <li className="dropdown-header">
    //                   {userData && userData.userName}
    //                   <ul style={{ marginLeft: "-20px" }}>
    //                     <li className="dropdown-item">
    //                       {/* {props.headerData.profileMenu.map((ele, index) => (
    //                         <a
    //                           key={index}
    //                           style={{
    //                             fontSize: "0.9rem",
    //                             textDecoration: "none",
    //                           }}
    //                           onClick={() => {
    //                             dispatch({ type: "clearUserData" });
    //                             return true;
    //                           }}
    //                           rel="noopener noreferrer"
    //                           href={
    //                             ele.link ||
    //                             "https://prosares-sureally1.azurewebsites.net/" ||
    //                             "https://localhost:5001"
    //                           }>
    //                           {ele.label}
    //                         </a>
    //                       ))} */}
    //                     </li>
    //                     {/* <li className="dropdown-item">Delegate</li>
    //                     <li className="dropdown-item">Settings</li> */}
    //                   </ul>
    //                 </li>
    //                 {/* <div className="dropdown-divider"></div>
    //                 <li className="dropdown-header">
    //                   Login As
    //                   <ul className="ml-1">
    //                     <li className="dropdown-item">Sandeep Yalgi</li>
    //                   </ul>
    //                 </li> */}
    //               </ul>
    //             </div>
    //           </li>
    //           <li>
    //             <OverlayTrigger
    //               trigger="click"
    //               placement="bottom"
    //               overlay={popover}
    //               rootClose
    //             >
    //               <div
    //                 className="user-profile dropdown"
    //                 style={{ cursor: "pointer" }}
    //               >
    //                 <div data-toggle="dropdown">
    //                   <a
    //                     className="dropdown-toggle"
    //                     role="button"
    //                     id="dropdownMenuLink"
    //                     data-toggle="dropdown"
    //                     aria-haspopup="true"
    //                     aria-expanded="false"
    //                   >
    //                     <i className="fa fa-user"></i>{" "}
    //                     <span className="desktop">{userData.name}</span>
    //                   </a>
    //                 </div>
    //               </div>
    //             </OverlayTrigger>
    //           </li>
    //           {location.pathname === "/invoicing/upload" && (
    //             <li className="asidebar-icon d-sm-block d-md-none">
    //               <div className="respo-right-content">
    //                 <p
    //                   style={{ cursor: "pointer" }}
    //                   onClick={() => props.setAsidebar(!props.asidebar)}
    //                 >
    //                   <img src={asideIcon} className="asidebar-icon" />
    //                 </p>
    //               </div>
    //             </li>
    //           )}
    //         </ul>
    //       </div>
    //     </div>
    //   </header>
    // </>
    <>
      <header id="header">
        <div className="headerbar">
          <div
            className="headerbar-left left-logo"
            // style={{ marginRight: "30px" }}
          >
            <ul className="header-nav header-nav-options">
              {/* <li className="sandwich-menu">
                <a
                  className="btn btn-icon-toggle btn-default menubar-toggle"
                  data-toggle="menubar"
                  onClick={(e) => {
                    e.preventDefault();
                    props.menuOpenFunc();
                  }}>
                  <i className="fa fa-bars"></i>{" "}
                </a>
              </li> */}
              <li
                className="pl-2 btn btn-icon-toggle btn-default menubar-toggle"
                data-toggle="menubar"
                onClick={(e) => {
                  e.preventDefault();
                  props.menuOpenFunc();
                }}
              >
                <i className="fa fa-bars"></i>{" "}
              </li>
              <li className="header-nav-brand">
                <div className="brand-holder">
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/home");
                    }}
                  >
                    <img
                      style={{
                        width: "120px",
                        margin: "0 13px 0 0",
                        padding: "5px 0px",
                      }}
                      src={WelspunLogo}
                      alt=""
                      className="app_logo"
                    />
                  </a>

                  {/* <a
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/");
                    }}>
                    <img
                      style={{
                        width: "35px",
                        margin: "0px 0px",
                        padding: "5px 0px",
                      }}
                      src={WelspunSolgan}
                      alt=""
                      className="app_logo"
                    />
                  </a> */}
                </div>
              </li>
            </ul>
          </div>
          <div className="float-right no-padding nav-right">
            <ul className="right-head float-right mx-4">
              {isProxyUserRole &&
                proxyUserDetails === null &&
                proxyUserRoleDetails === null && (
                  <li className="ProxyDetails" style={{ color: "#0000" }}>
                    <a
                      style={{ color: "white" }}
                      onClick={(e) => {
                        setShowProxyModel(true);
                      }}
                    >
                      Activate Proxy User
                    </a>
                  </li>
                )}

              {proxyUserDetails !== null && proxyUserRoleDetails !== null && (
                <li style={{ color: "#0000" }}>
                  <a
                    style={{ color: "white" }}
                    onClick={(e) => {
                      setShowProxyModel(true);
                      stopProxyUserFunction();
                    }}
                  >
                    Stop Proxy User
                  </a>
                </li>
              )}

              {proxyUserDetails === null &&
                proxyUserRoleDetails === null &&
                rolesArray?.length > 0 && (
                  <li style={{ width: "150px" }} className="Role_select">
                    <div className="dropdown">
                      <SelectForm
                        options={rolesArray}
                        placeholder="Select"
                        isDisabled={false}
                        value={roleValue}
                        onChange={(event) => {
                          onRoleChange(event);
                        }}
                        isMulti={false}
                        noIndicator={false}
                        noSeparator={false}
                      />
                    </div>
                  </li>
                )}

              {proxyUserDetails !== null &&
                proxyUserRoleDetails !== null &&
                proxyUserRoleOptions?.length > 0 && (
                  <li style={{ width: "150px" }} className="Role_select">
                    <div className="dropdown">
                      <SelectForm
                        options={proxyUserRoleOptions}
                        placeholder="Select Role"
                        isDisabled={false}
                        value={proxyUserRoleDetails}
                        onChange={(event) => {
                          onChangeForProxy(event, "proxyRole");
                          // window.location.reload();
                          window.location.href = "/home";
                        }}
                        isMulti={false}
                        noIndicator={false}
                        noSeparator={false}
                      />
                    </div>
                  </li>
                )}

              {proxyUserDetails === null && proxyUserRoleDetails === null && (
                <li>
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    overlay={popover}
                    rootClose
                  >
                    <div
                      className="user-profile dropdown"
                      style={{ cursor: "pointer" }}
                    >
                      <div data-toggle="dropdown">
                        <a
                          className="dropdown-toggle text-white"
                          role="button"
                          id="dropdownMenuLink"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="fa fa-user"></i>

                          <span className="desktop">{`${userDetails.FirstName} ${userDetails.LastName}`}</span>
                        </a>
                      </div>
                    </div>
                  </OverlayTrigger>
                </li>
              )}

              {proxyUserDetails !== null && proxyUserRoleDetails !== null && (
                <li>
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    overlay={popover}
                    rootClose
                  >
                    <div
                      className="user-profile dropdown"
                      style={{ cursor: "pointer" }}
                    >
                      <div data-toggle="dropdown">
                        <a
                          className="dropdown-toggle text-white"
                          role="button"
                          id="dropdownMenuLink"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="fa fa-user"></i>

                          <span className="desktop">{`${proxyUserDetails.FirstName} ${proxyUserDetails.LastName}`}</span>
                        </a>
                      </div>
                    </div>
                  </OverlayTrigger>
                </li>
              )}
            </ul>
          </div>
        </div>
      </header>
      <>
        <Modal
          show={showProxyModel}
          onHide={() => setShowProxyModel(false)}
          backdrop="static"
          keyboard={false}
          size="lg"
          centered
        >
          <div
            className="bg-secondary text-white"
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <div className="col-lg-12">Proxy User</div>
          </div>
          <Modal.Body>
            <>
              <div className="row acc-heading">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <SelectForm
                    isClearable
                    isSearchable
                    async
                    options={(e, callback) =>
                      getProxyUserDropdownDetails(e, callback)
                    }
                    placeholder="Search User"
                    isDisabled={false}
                    value={proxyUserDetails}
                    onChange={(event) => {
                      onChangeForProxy(event, "proxyUser");
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <SelectForm
                    options={proxyUserRoleOptions}
                    placeholder="Select Role"
                    isDisabled={false}
                    value={proxyUserRoleDetails}
                    onChange={(event) => {
                      onChangeForProxy(event, "proxyRole");
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
              </div>
            </>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary"
              style={{ marginLeft: 5 }}
              onClick={() => {
                onClickOkFunction();
              }}
            >
              OK
            </button>
            <button
              className="btn btn-cancel"
              style={{ marginLeft: 5 }}
              onClick={() => {
                stopProxyUserFunction();
              }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </>
    </>
  );
};

export default Header;
