import React, { useContext, useEffect, useState } from "react";
import DynamicGrid from "../../../Components/DynamicGrid/DynamicGrid";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { APICall, APICallWithSignal, Encrypt } from "../../../Helpers/APICalls";
import {
  GetCourseById,
  GetCourseScheduleByCourseIdDashboard,
  GetCommonDropdownForScheduling,
  DeleteCourseScheduleById,
} from "../../../Helpers/APIEndPoints/LMS_EndPoints";
import moment from "moment";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  setRef,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InputForm from "../../../Components/InputForm/InputForm";
import notify from "../../../Helpers/ToastNotification";
import { LoaderContext } from "../../../Helpers/Context/Context";
import useUserContext from "../../../Helpers/ZustandStore/UserContextStore";
import SelectForm from "../../../Components/SelectForm/SelectForm";
import { Modal } from "react-bootstrap";

interface IVenueDetails {
  id?: number;
  venue_name?: string;
  start_date?: string | Date | null;
  end_date?: string | Date | null;
  trainer?: string;
  training_centre?: string;
}

const ScheduleCourseAndSession = ({ activeTab, setActiveTab }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isDisable, setIsDisable] = useState(
    state && state?.isDisable ? true : false
  );

  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { userDetails }: any = useUserContext();

  const [courseId, setCourseId] = useState<number>(0);
  const [gridData, setGridData] = useState<IVenueDetails[]>([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("");
  const [searchText, setSearchText] = useState("");
  const [scheduleRequired, setScheduleRequired] = useState(false);
  const [encryptedCourseId, setEncryptedCourseId] = useState("");
  const [count, setCount] = useState(0);
  const [tempId, setTempId] = useState(0);
  const [remarks, setRemarks] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [courseValue, setCourseValue] = useState(null);
  const [courseOptions, setCourseOptions] = useState([]);
  const [refreshDD, setRefreshDD] = useState(false);

  useEffect(() => {
    const helper = async () => {
      setCourseId(state?.courseId);
      const encryptedId = await Encrypt(String(state?.courseId) || "");
      setEncryptedCourseId(encryptedId);
    };

    helper();
  }, [state?.courseId]);

  useEffect(() => {
    if (courseId > 0) {
      const helper = async () => {
        const encryptedId = await Encrypt(String(courseId) || "");
        setEncryptedCourseId(encryptedId);
      };

      helper();
    }
  }, [refreshDD]);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchData = async () => {
      if (courseId > 0) {
        await handleFetchCourseScheduleByCourseId(signal);
      } else {
        setCount(0);
        setGridData([]);
      }
    };

    fetchData();

    return () => {
      // Cleanup: Abort the POST request if the component is unmounted
      abortController.abort();
    };
  }, [
    courseId,
    pageSize,
    pageNumber,
    searchText,
    sortColumn,
    sortDirection,
    refresh,
  ]);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    async function helperFunction() {
      await handleFetchCoursesById(signal);
    }

    helperFunction();

    return () => {
      // Cleanup: Abort the POST request if the component is unmounted
      abortController.abort();
    };
  }, [courseId, refreshDD]);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    async function helperFunction() {
      await fetchDropdown(signal);
    }

    helperFunction();

    return () => {
      // Cleanup: Abort the POST request if the component is unmounted
      abortController.abort();
    };
  }, []);

  const gridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: pageSize,
    page: pageNumber,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",

    //standard | vertical | simple
    onChangeRowsPerPage: (num) => {},

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        setSortDirection(sortDirection);
        setSortColumn(sortColumn);
      }
    },
    onChangePage: async (page) => {
      setPageNumber(page);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const gridColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "venue_name",
      label: "Training Centre",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "course_scheduleName",
      label: "Schedule Name",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "start_date",
      label: "Start Date",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "end_date",
      label: "End Date",
      options: {
        filter: false,
        sort: true,
        display: true,
        customBodyRender: (value, tableMeta) => {
          const endDate = moment(
            tableMeta.tableData[tableMeta.rowIndex].end_date
          ).year();

          return <div>{endDate === 9999 ? <p>N/A</p> : <p>{value}</p>}</div>;
        },
      },
    },
    {
      name: "launch",
      label: "Launch",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "trainer",
      label: "Trainer",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "active",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.tableData[tableMeta.rowIndex].id;
          const isLaunch = tableMeta.tableData[tableMeta.rowIndex].isLaunch;
          const active = tableMeta.tableData[tableMeta.rowIndex].active;
          const encryptedCourseScheduleId = Encrypt(String(id));
          const isCourseScheduleSave = Encrypt(String(true));
          const endDate = moment(
            tableMeta.tableData[tableMeta.rowIndex].end_date
          ).year();

          const canDuplicate =
            tableMeta.tableData[tableMeta.rowIndex].canDuplicate;

          return (
            <div style={{ width: "100px" }}>
              {isLaunch ? (
                <>
                  <div className="">
                    <Tooltip title={"View"}>
                      <Link
                        style={{ marginRight: "10px", cursor: "pointer" }}
                        to={`/addschedule?courseId=${encryptedCourseId}&courseScheduleId=${encryptedCourseScheduleId}&isCourseScheduleSave=${isCourseScheduleSave}&isDisable=${isDisable}`}
                        target="_blank"
                      >
                        <i className={`fas fa-eye`}></i>
                      </Link>
                    </Tooltip>

                    {canDuplicate && (
                      <Tooltip title={"Duplicate this schedule"}>
                        <Link
                          style={{ marginRight: "10px", cursor: "pointer" }}
                          to={`/addschedule?courseId=${encryptedCourseId}&courseScheduleId=${encryptedCourseScheduleId}&isCourseScheduleSave=${isCourseScheduleSave}&isDisable=${isDisable}&canDuplicate=${canDuplicate}`}
                          target="_blank"
                        >
                          <i className="fa fa-clone" aria-hidden="true"></i>
                        </Link>
                      </Tooltip>
                    )}
                  </div>
                </>
              ) : (
                <>
                  {!isDisable ? (
                    <div className="">
                      {endDate !== 9999 && (
                        <Tooltip title={"Edit"}>
                          <Link
                            style={{ marginRight: "10px", cursor: "pointer" }}
                            to={`/addschedule?courseId=${encryptedCourseId}&courseScheduleId=${encryptedCourseScheduleId}&isCourseScheduleSave=${isCourseScheduleSave}`}
                            target="_blank"
                          >
                            <i className={`fas fa-edit`}></i>
                          </Link>
                        </Tooltip>
                      )}

                      <Tooltip title={"Delete"}>
                        <i
                          style={{
                            marginRight: "0px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleModalOpen(id);
                          }}
                          className={`fas fa-trash`}
                        ></i>
                      </Tooltip>
                    </div>
                  ) : (
                    <div className="">
                      <Tooltip title={"View"}>
                        <Link
                          style={{ marginRight: "10px", cursor: "pointer" }}
                          to={`/addschedule?courseId=${encryptedCourseId}&courseScheduleId=${encryptedCourseScheduleId}&isCourseScheduleSave=${isCourseScheduleSave}&isDisable=${isDisable}`}
                          target="_blank"
                        >
                          <i className={`fas fa-eye`}></i>
                        </Link>
                      </Tooltip>
                    </div>
                  )}
                </>
              )}
            </div>
          );
        },
      },
    },
  ];

  async function handleFetchCoursesById(signal) {
    try {
      showLoader();
      const response = await APICallWithSignal(
        GetCourseById,
        "POST",
        {
          Id: courseId,
        },
        true,
        null,
        signal
      );

      if (response?.status == 0) {
        const responseData = response?.data;
        setScheduleRequired(responseData?.scheduleRequired);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  async function handleFetchCourseScheduleByCourseId(signal) {
    try {
      showLoader();
      const response = await APICallWithSignal(
        GetCourseScheduleByCourseIdDashboard,
        "POST",
        {
          courseId,
          PageNumber: pageNumber,
          SortColumn: sortColumn,
          SortOrder: sortDirection,
          SearchText: searchText,
          PageSize: pageSize,
          LaunchId: state?.launchHubId,
        },
        true,
        null,
        signal
      );

      if (response?.status == 0 && response?.data?.length > 0) {
        const responseData = response?.data;

        let arr = responseData?.map((res) => {
          return {
            id: res?.courserScheduleId,
            venue_name: res?.trainingCentreName,
            course_scheduleName: res?.courseScheduleName,
            start_date: res?.courseStartDate,
            // moment(res?.startDate, "DD-MM-YYYY HH:mm:ss").isValid()
            //   ? moment(res?.startDate, "DD-MM-YYYY HH:mm:ss").format(
            //       "DD-MMM-YYYY"
            //     )
            //   : ""
            end_date: res?.courseEndDate,
            // moment(res?.endDate, "DD-MM-YYYY HH:mm:ss").isValid()
            //   ? moment(res?.endDate, "DD-MM-YYYY HH:mm:ss").format(
            //       "DD-MMM-YYYY"
            //     )
            //   : ""
            trainer: res?.instructorsName,
            launch: res?.launch,
            active: res?.isActive,
            count: res?.count,
            isLaunch: res?.isLaunch,
            canDuplicate: res?.canDuplicate,
          };
        });

        let tempCount = arr[0]?.count;
        setCount(tempCount);
        setGridData(arr);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  async function handleModalOpen(id) {
    setTempId(id);
    setRemarks("");
    setOpenModal(true);
  }

  async function handleModalClose() {
    setTempId(0);
    setRemarks("");
    setOpenModal(false);
  }

  async function handleCourseScheduleDelete() {
    try {
      if (
        remarks == null ||
        remarks == undefined ||
        String(remarks).trim().length == 0
      ) {
        notify(1, "Remarks Required.");
        return;
      }

      showLoader();
      setBtnDisable(true);
      const response = await APICall(DeleteCourseScheduleById, "POST", {
        courseScheduleId: tempId,
        UserId: userDetails?.Id,
        Remarks: remarks,
      });

      if (response?.status == 0) {
        notify(0, "Record Deleted Successfully.");
        handleModalClose();
        setRefresh((prev) => !prev);
      } else {
        notify(1, "Something went wrong.");
        console.error(response?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
      setBtnDisable(false);
    }
  }

  async function fetchDropdown(signal) {
    try {
      showLoader();
      const response = await APICallWithSignal(
        GetCommonDropdownForScheduling,
        "POST",
        {
          Code: "course",
        },
        true,
        null,
        signal
      );

      if (response?.status === 0) {
        setCourseOptions(response?.data);
        return response?.data;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  }

  return (
    <>
      <div className="container-fluid">
        {state?.isMenu && (
          <>
            <div className="breadcrumb modified-bredcrumb">
              <span>
                <ul>
                  <li>
                    <a href="/home">Home</a>
                  </li>
                  <li>Schedule</li>
                </ul>
              </span>
            </div>
            <div className="row"></div>
          </>
        )}

        {!isDisable && (
          <div className="d-flex align-items-center justify-content-end mb-3">
            {scheduleRequired && (
              <div>
                <button
                  className="btn btn-primary mt-4 mr-2"
                  style={{ marginLeft: 5 }}
                  onClick={() => {
                    setRefresh((prev) => !prev);
                  }}
                >
                  <i className="fa-solid fa-refresh"></i> Refresh
                </button>
                <Link
                  className="btn btn-primary mt-4"
                  to={`/addschedule?courseId=${encryptedCourseId}&courseScheduleId=${Encrypt(
                    "0"
                  )}&isCourseScheduleSave=${Encrypt(false)}`}
                  target="_blank"
                >
                  <i className="fa fa-plus mr-2"></i>
                  Create Schedule
                </Link>
              </div>
            )}
          </div>
        )}

        {state?.isMenu && (
          <div className="grid-wrapper grid_mob">
            <div
              className="row mx-auto"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="form-group filtInp">
                  <span className="mr-4 text-white" style={{ float: "left" }}>
                    <label>Course</label>
                  </span>
                  <SelectForm
                    value={courseValue}
                    placeholder={"Select Course"}
                    options={courseOptions}
                    onChange={(event) => {
                      setCourseValue(event);
                      if (event) {
                        setCourseId(event?.value);
                        setRefreshDD((prev) => !prev);
                        setSortColumn("");
                        setSortDirection("");
                        setPageNumber(0);
                      } else {
                        setRefreshDD((prev) => !prev);
                        setCourseId(0);
                      }
                    }}
                    isDisabled={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div>
          {gridData.length === 0 && scheduleRequired && courseId != 0 && (
            <>
              <div className="">
                <h5>No Schedule</h5>
              </div>
            </>
          )}
        </div>
        <div>
          {!scheduleRequired && courseId != 0 && courseId && (
            <>
              <div className="">
                <h5>Schedule is not required for this course</h5>
              </div>
            </>
          )}
        </div>

        {scheduleRequired && gridData?.length > 0 && (
          <div className="mt-4">
            <DynamicGrid
              options={gridOptions}
              data={gridData}
              columns={gridColumns}
            />
          </div>
        )}
      </div>

      <Modal
        show={openModal}
        onHide={() => handleModalClose()}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">Do you want to cancel this schedule?</div>
        </div>
        <Modal.Body>
          <>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <label>
                  Remarks <span style={{ color: "red" }}>*</span>
                </label>
                <InputForm
                  value={remarks}
                  placeholder={"Remarks"}
                  isDisabled={false}
                  textArea={true}
                  onChange={(e) => {
                    const value = e?.target?.value;
                    setRemarks(value);
                  }}
                  maxLength={500}
                />
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleCourseScheduleDelete}
            className="btn btn-primary mr-2"
            disabled={btnDisable}
          >
            Yes
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={(e) => {
              e.preventDefault();
              handleModalClose();
            }}
          >
            No
          </button>
        </Modal.Footer>
      </Modal>

      {/* Employee Grid DataTable  */}
      {/* <React.Fragment>
        <Dialog
          onClose={handleModalClose}
          aria-labelledby="customized-dialog-title"
          open={openModal}
          maxWidth={"lg"}
          fullWidth={true}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Participants List
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <label>
                  Remarks <span style={{ color: "red" }}>*</span>
                </label>
                <InputForm
                  value={remarks}
                  placeholder={"Remarks"}
                  isDisabled={false}
                  textArea={true}
                  onChange={(e) => {
                    const value = e?.target?.value;
                    setRemarks(value);
                  }}
                  maxLength={500}
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <button
              onClick={handleCourseScheduleDelete}
              className="btn btn-primary mr-2"
              disabled={btnDisable}
            >
              Save
            </button>
            <button onClick={handleModalClose} className="btn btn-primary mr-2">
              Cancel
            </button>
          </DialogActions>
        </Dialog>
      </React.Fragment> */}
    </>
  );
};

export default ScheduleCourseAndSession;
