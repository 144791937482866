import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { LoaderContext } from "../../Helpers/Context/Context";
import {
  GetCompanyCode,
  GetNationalIDDuplicateValidation,
  downloadSectionDocForProgression,
  downloadSupportingDocForProgression,
  getDropdowns,
  getWorkflowData,
} from "../../Helpers/APIEndPoints/EndPoints";
import { APICall } from "../../Helpers/APICalls";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  getEssMssEditSections,
  getDdForProgression,
  getSearchDdForProgression,
  getOptForApproval,
  getAttrForProgression,
  postDataForProgression,
  getSectionDetailsV2,
  getGlobalValues,
  viewDocument,
  downloadDocument,
  deleteDocument,
  getDocuments,
  getSearchableDropdowns,
  getParenPBasedonIncm,
  postAttributeValueDetailsV2,
  getAttributeValueDetailsV2,
  documentUpload,
  getAccountValidation,
} from "../../Helpers/APIEndPoints/EndPoints";
import { NationalIdCardType } from "../../Components/FromStructure/NationalIdCardTypeRegex";
import notify from "../../Helpers/ToastNotification";
import moment from "moment";
import { ExpandMore, Satellite, Try, TryRounded } from "@mui/icons-material";
import { Modal } from "react-bootstrap";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import uuid from "react-uuid";
import SelectForm from "../../Components/SelectForm/SelectForm";
import InputForm from "../../Components/InputForm/InputForm";
import DateForm from "../../Components/DateForm/DateForm";
import { useLocation } from "react-router-dom";
import useDataChangeStore from "../../Helpers/ZustandStore/DataChangeSectionStore";
import useDocumentStore from "../../Helpers/ZustandStore/DocumentForProgressionStore";

const EmpProgSectionComponent = ({
  TId,
  setTId,
  formData,
  setFormData,
  section: ogSection,
  sectionIndex,
  sectionValuesArray,
  SetSectionValuesArray,
  Globalv,
  COU,
  submitFunc,
  setGetData,
  validationTrigger = false,
  IsSendBack = false,
  payrolldd = null,
  countrydd = null,
  effectiveDate = null,
}) => {
  const [hasValue, setHasValue] = useState(
    sectionValuesArray.filter((es) => es.sectionSystemName == ogSection?.SN) ||
      null
  );
  const { documents, insertDocument, updateDocument }: any = useDocumentStore();
  const [sectionId, setSectionId] = useState(0);
  const [globalEC, setGlobalEC] = useState(Globalv);
  const [section, setSection] = useState(ogSection);
  const [accordion, setAccordion] = useState(sectionIndex === 0 ? true : false);
  const [formErrors, setFormErrors] = useState<any>({});
  const [optionsObj, setOptionsObj] = useState<any>({});
  const [gridData, setGridData] = useState([]);
  const [displayGrid, setDisplayGrid] = useState([]);
  const [gridColumns, setGridColumns] = useState([]);
  const [ogAttributeSet, setOgAttributeSet] = useState({});
  const [ogFilledAttributeSet, setOgFilledAttributeSet] = useState([]);
  const [currentGridId, setCurrentGridId] = useState(null);
  const [deleteGridId, setDeleteGridId] = useState(null);

  const [wholeaAttributeSetData, setWholseAttributeSetData] = useState([]);

  // value Object for section
  const [attributesData, setAttributesData] = useState<any>({});

  const [attributeSetData, setAttributeSetData] = useState<any>({});
  
  const [attributeSetCode, setAttributeSetCode] = useState(null);
  const [isOnce, setIsOnce] = useState(true);
  const [isValidateOnce, setIsValidateOnce] = useState(true);

  const [attributeValidation, setAttributeValidation] = useState<any>({});
  const [attributeSetValidation, setAttributeSetValidation] = useState<any>({});

  const [dataFilled, setDataFilled] = useState(false);

  const [sectionHasDocuments, setSectionHasDocuments] = useState(false);

  const { showLoader, hideLoader } = useContext(LoaderContext);

  const { userDetails, currentRoleId }: any = useUserContext();

  const [PageSize, setPageSize] = useState(3);
  const [page, setPage] = useState(0);

  const [fillDDValuesForGrid, setFillDDValuesForGrid] = useState([]);
  const [dAutomapAttribute, setdAutomapAttribute] = useState([]);
  const [modalHeader, setModalHeader] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  let validAccount = useRef(false);
  const [confirmationPromise, setConfirmationPromise] = useState(null);
  const [ShowAttrSet, setShowAttrSet] = useState(false);
  const [multiDropdown, setMultiDropdown] = useState(true);
  const {
    sectionsData,
    sendBackJson,
    updateSectionData,
    setSectionDataAndJsonData,
  }: any = useDataChangeStore();

  const [effectiveDateCode, setEffectiveDateCode] = useState<any>(null);

  const [sectionsForProgEss, setSectionsForProgEss] = useState<any>([
    // "Basic", "NationalIDInformation", "BankInformation", "AddressInformation", "Email Information",
    // "ContactInformation", "Emergency Information", "FormalEducation", "PreviousEmployment",
    // "FamilyMemberDetails", "NomineeDetails", "HealthInformation", "FamilyinWelspun",
    // "AdditionalSkills", "GeaographicMobility", "LanguageSkills",
    // , "PhysicallyChallanged", "AssetManagement", "WorkPermitinfo",
    // , "CertificateLicenses",
    // , "NextofKin",
    // , "PositionInformation", "JobOrganisationInformation", "JobRelationships", "EmploymentDetails"
    // , "TerminationsAbsconding", "Compensation", "OneTimePaymentDeductions", "Deputation"
    // , "DisciplinaryActions", "NatureofEmployment", "Absconding", "OnBoardingCheckList"
    // , "EmployeeLogin"
  ]);

  // const [showAttr, setshowAttr] = useState(section?.ISEDIT ? false : true);
  const [showAttr, setshowAttr] = useState(
    currentRoleId?.value == 7
      ? //&& sectionsForProgEss.includes(section?.SN)
        false
      : true
  );

  const getDropdownsData = async (AC, PID) => {
    await showLoader();
    const dropdownData = await APICall(getDropdowns, "POST", {
      AC,
      PID,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      RoleTypeId: 3,
    });

    if (
      dropdownData.data !== null &&
      dropdownData.data !== undefined &&
      dropdownData.data.length > 0
    ) {
      await hideLoader();
      return dropdownData.data;
    } else {
      await hideLoader();
      return [];
    }
  };

  const [addressType, setAddressType] = useState<any>({});

  const [selectedFilesForFlat, setSelectedFilesForFlat] = useState<
    {
      AC: string;
      Name: string;
      Data: string;
      IsValidFile: boolean;
      DocId: Number;
      Type: string;
      Context: string;
      DCVALUE: string;
      DVTILL: string;
      DSTATUS: string;
      DPSINCE: string;
      DPWITH: string;
    }[]
  >([]);

  const [selectedFilesForSet, setSelectedFilesForSet] = useState<
    {
      AC: string;
      Id: string | number;
      Name: string;
      Data: string;
      IsValidFile: boolean;
      DocId: Number;
      Type: string;
      Context: string;
      DCVALUE: string;
      DVTILL: string;
      DSTATUS: string;
      DPSINCE: string;
      DPWITH: string;
    }[]
  >([]);

  const [newGridIdCreated, setNewGridIdCreated] = useState(null);
  const [disableSaveGrid, setDisableSaveGrid] = useState(false);

  const fileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileValidationProg = (files, attribute) => {
    const { FS, FX, FC } = JSON.parse(attribute.V);

    // Split the validExtensionsString into an array
    const validExtensions = FX.split(",");

    let isValid = [];

    // validate file count
    if (files.length > FC) {
      notify(1, `Only ${FC} files allowed!`);
      return false;
    }
    // check extension of each file
    files.forEach((file) => {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop()?.toLowerCase();

      // Check if the file extension is allowed
      if (!validExtensions.includes("." + fileExtension)) {
        isValid.push(false);
      } else {
        isValid.push(true); //valid
      }
    });

    let returnValue = isValid.some((v) => v === false);

    if (returnValue) {
      notify(1, `Please upload file within this ${FX} extentsion`);
      return false;
    }

    files.forEach((file) => {
      const fileSize = file.size;
      const maxFileSizeBytes = FS * 1024 * 1024;
      if (fileSize <= maxFileSizeBytes) {
        isValid.push(true); //valid
      } else {
        isValid.push(false);
      }
    });

    returnValue = isValid.some((v) => v === false);

    if (returnValue) {
      notify(1, `File size exceeds ${FS}MB. Please select a smaller file.`);
      return false;
    } else {
      return true;
    }
  };

  const handleFileChangeForFlatProg = async (e, attribute) => {
    const { FS, FX, FC } = JSON.parse(attribute.V);

    const files = Array.prototype.slice.call(e.target.files);

    const isValid = handleFileValidationProg(files, attribute);
    if (isValid) {
      if (files && files.length > 0) {
        const base64Array: { Name: string; Data: string }[] = [];
        for (let i = 0; i < files.length; i++) {
          const base64 = (await fileToBase64(files[i])) as string;
          const base64WithoutPrefix = base64.split(",")[1];
          base64Array.push({ Name: files[i].name, Data: base64WithoutPrefix });
        }

        //check if AC present and file count matched

        const fileCount = selectedFilesForFlat.reduce((total, item) => {
          if (item.AC === attribute.AC) {
            return total + 1;
          }
          return total;
        }, 0);

        let prevDocs = documents; //global doc state
        const docCount = prevDocs.reduce((total, item) => {
          if (item.AC === attribute.AC && item.IsActive) {
            return total + 1;
          }
          return total;
        }, 0);

        if (docCount == FC) {
          notify(1, `Only ${FC} files allowed!`);
        } else {
          let type =
            JSON.parse(attribute.AD).CON === "National ID Information"
              ? attributeSetData["IDCATY"]?.label
              : JSON.parse(attribute.AD).TYP
              ? JSON.parse(attribute.AD).TYP
              : "";
          base64Array.forEach((file) => {
            setSelectedFilesForFlat((prevSelectedFiles) => [
              ...prevSelectedFiles,
              {
                AC: attribute.AC,
                Name: file.Name,
                Data: file.Data,
                IsValidFile: false,
                DocId: 0,
                Type: type,
                Context: JSON.parse(attribute.AD).CON
                  ? JSON.parse(attribute.AD).CON
                  : "",
                DCVALUE: file.Name,
                DVTILL: "",
                DSTATUS: "Verified",
                DPSINCE: "",
                DPWITH: "",
              },
            ]);

            //add new Doc in global State
            prevDocs = [
              ...prevDocs,
              {
                AC: attribute.AC,
                Name: file.Name,
                Data: file.Data,
                IsValidFile: false,
                DocId: 0,
                Type: type,
                Context: JSON.parse(attribute.AD).CON
                  ? JSON.parse(attribute.AD).CON
                  : "",
                DCVALUE: file.Name,
                DVTILL: "",
                DSTATUS: "Verified",
                DPSINCE: "",
                DPWITH: "",
                IsNew: true,
                IsActive: true,
                MN: "EmployeeCentral",
                TN: "Personal",
                SN: section.SN,
              },
            ];

            updateDocument(prevDocs);
          });
        }
      }
    } else {
      e.target.value = null;
    }
  };

  const handleFileChangeForSetProg = async (e, subAttribute) => {
    const { FS, FX, FC } = JSON.parse(subAttribute.V);

    const files = Array.prototype.slice.call(e.target.files);

    const isValid = handleFileValidationProg(files, subAttribute);
    if (isValid) {
      if (files && files.length > 0) {
        const base64Array: { Name: string; Data: string }[] = [];
        for (let i = 0; i < files.length; i++) {
          const base64 = (await fileToBase64(files[i])) as string;
          const base64WithoutPrefix = base64.split(",")[1];
          base64Array.push({ Name: files[i].name, Data: base64WithoutPrefix });
        }

        //check if AC present and file count matched
        const fileCount = selectedFilesForSet.reduce((total, item) => {
          if (
            item.AC === subAttribute.AC &&
            ((currentGridId && currentGridId == item.Id) || item.Id == 0)
          ) {
            return total + 1;
          }
          return total;
        }, 0);

        let prevDocs = documents; //global doc state
        const docCount = prevDocs.reduce((total, item) => {
          if (
            item.AC === subAttribute.AC &&
            item.IsActive &&
            ((currentGridId && currentGridId == item.Id) || item.Id == 0)
          ) {
            return total + 1;
          }
          return total;
        }, 0);

        if (docCount == FC) {
          notify(1, `Only ${FC} files allowed!`);
        } else {
          let type =
            JSON.parse(subAttribute.AD).CON === "National ID Information"
              ? attributeSetData["IDCATY"]?.label
              : JSON.parse(subAttribute.AD).TYP
              ? JSON.parse(subAttribute.AD).TYP
              : "";
          base64Array.forEach((file) => {
            setSelectedFilesForSet((prevSelectedFiles) => [
              ...prevSelectedFiles,
              {
                AC: subAttribute.AC,
                Name: file.Name,
                Data: file.Data,
                IsValidFile: false,
                Id: currentGridId ?? 0,
                DocId: 0,
                Type: type,
                Context: JSON.parse(subAttribute.AD).CON
                  ? JSON.parse(subAttribute.AD).CON
                  : "",
                DCVALUE:
                  JSON.parse(subAttribute.AD).CON === "National ID Information"
                    ? attributeSetData["PERIDNM"]
                    : file.Name,
                DVTILL:
                  JSON.parse(subAttribute.AD).CON === "National ID Information"
                    ? attributeSetData["NVALTIL"]
                    : "",
                DSTATUS: "Verified",
                DPSINCE: "",
                DPWITH: "",
              },
            ]);

            //add new Doc in global State
            prevDocs = [
              ...prevDocs,
              {
                AC: subAttribute.AC,
                Name: file.Name,
                Data: file.Data,
                IsValidFile: false,
                Id: currentGridId ?? 0,
                DocId: 0,
                Type: type,
                Context: JSON.parse(subAttribute.AD).CON
                  ? JSON.parse(subAttribute.AD).CON
                  : "",
                DCVALUE:
                  JSON.parse(subAttribute.AD).CON === "National ID Information"
                    ? attributeSetData["PERIDNM"]
                    : file.Name,
                DVTILL:
                  JSON.parse(subAttribute.AD).CON === "National ID Information"
                    ? attributeSetData["NVALTIL"]
                    : "",
                DSTATUS: "Verified",
                DPSINCE: "",
                DPWITH: "",
                IsNew: true,
                IsActive: true,
                MN: "EmployeeCentral",
                TN: "Personal",
                SN: section.SN,
              },
            ];

            updateDocument(prevDocs);
          });
        }
      }
    } else {
      e.target.value = null;
    }
  };

  const handleViewForFlat = async (fileAttribute, fileIndex) => {
    showLoader();
    if (
      fileAttribute.DocId != undefined &&
      fileAttribute.DocId != null &&
      fileAttribute.DocId != 0
    ) {
      const doc = await APICall(viewDocument, "POST", {
        TId,
        AC: fileAttribute.AC,
        DocId: fileAttribute.DocId,
      });

      if (doc && doc.data !== null) {
        //window.open(doc.data,'_blank');
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = doc.data;
        link.click();
      }
      hideLoader();
    } else {
      notify(1, "File Not Available");
    }
  };

  const handleDownloadFileFlat = async (fileAttribute, fileIndex) => {
    debugger;
    showLoader();
    if (fileAttribute.IsValidFile) {
      const doc = await APICall(downloadSectionDocForProgression, "POST", {
        Data: fileAttribute.Data,
      });
      if (doc && doc.data !== null) {
        downloadBase64File(doc.data, fileAttribute.Name);
        hideLoader();
      } else {
        notify(1, "Something Went Wrong.");
        hideLoader();
      }
    } else {
      downloadBase64File(fileAttribute.Data, fileAttribute.Name);
      hideLoader();
    }
  };

  function downloadBase64File(base64String: string, fileName: string) {
    const mimeType = inferMimeType(base64String);
    const blob = base64toBlob(base64String, mimeType);

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  }

  function inferMimeType(base64String: string): string {
    const dataUrl = `data:application/octet-stream;base64,${base64String}`;
    const typeInfo = /^data:(.*?);/.exec(dataUrl);

    if (typeInfo && typeInfo[1]) {
      return typeInfo[1];
    }

    return "application/octet-stream";
  }

  function base64toBlob(base64String: string, mimeType: string): Blob {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], { type: mimeType });
  }

  const handleDeleteFileForFlat = async (fileAttribute, fileIndex) => {
    if (
      fileAttribute.DocId != undefined &&
      fileAttribute.DocId != null &&
      fileAttribute.DocId != 0
    ) {
      showLoader();
      // const doc = await APICall(deleteDocument, "POST", {
      //   TId,
      //   AC: fileAttribute.AC,
      //   DocId: fileAttribute.DocId,
      // });
      // if (doc.message === "Success") {
      if (true) {
        let updatedDocs = documents.map((doc) => {
          if (doc.DocId === fileAttribute.DocId) {
            return { ...doc, IsActive: false };
          } else {
            return doc;
          }
        });

        updateDocument(updatedDocs);

        let remainingDocs = selectedFilesForFlat.filter(function (obj) {
          return obj.DocId !== fileAttribute.DocId;
        });
        setSelectedFilesForFlat(remainingDocs);
        hideLoader();
      } else {
      }
    } else {
      let updatedDocs = documents.map((doc, attIndex) => {
        if (fileIndex === attIndex) {
          return { ...doc, IsActive: false };
        } else {
          return doc;
        }
      });

      updateDocument(updatedDocs);

      setSelectedFilesForFlat((prevSelectedFiles) => {
        return prevSelectedFiles.filter(
          (item, attIndex) => fileIndex !== attIndex
        );
      });
    }
  };

  // useEffect(() => {
  //   console.log(documents);
  // }, [documents]);

  const handleDeleteFileForSet = async (fileAttribute, fileIndex) => {
    if (
      fileAttribute.DocId != undefined &&
      fileAttribute.DocId != null &&
      fileAttribute.DocId != 0
    ) {
      showLoader();
      // const doc = await APICall(deleteDocument, "POST", {
      //   TId,
      //   AC: fileAttribute.AC,
      //   DocId: fileAttribute.DocId,
      // });
      // if (doc.message === "Success") {
      if (true) {
        let updatedDocs = documents.map((doc) => {
          if (doc.DocId === fileAttribute.DocId) {
            return { ...doc, IsActive: false };
          } else {
            return doc;
          }
        });

        updateDocument(updatedDocs);

        let remainingDocs = selectedFilesForSet.filter(function (obj) {
          return obj.DocId !== fileAttribute.DocId;
        });
        setSelectedFilesForSet(remainingDocs);
        hideLoader();
      } else {
      }
    } else {
      let updatedDocs = documents.map((doc, attIndex) => {
        if (fileIndex === attIndex) {
          return { ...doc, IsActive: false };
        } else {
          return doc;
        }
      });

      updateDocument(updatedDocs);

      setSelectedFilesForSet((prevSelectedFiles) => {
        return prevSelectedFiles.filter(
          (item, attIndex) => fileIndex !== attIndex
        );
      });
    }
  };

  useEffect(() => {
    if (selectedFilesForSet.length > 0) {
      setSelectedFilesForSet((prevSelectedFiles) => {
        // Create a copy of the previous state with the updated Id values
        const updatedSelectedFiles = prevSelectedFiles.map((item, index) => {
          if (item.Id === undefined || item.Id === 0) {
            // If Id is undefined or 0, assign a new value
            return { ...item, Id: newGridIdCreated };
          } else {
            return item; // Keep existing Id values
          }
        });

        return updatedSelectedFiles;
      });

      //udpate Id of global doc
      let prevDocs = documents;

      prevDocs = prevDocs.map((item, index) => {
        if (item.Id === 0 && item.IsNew === true) {
          // If Id is undefined or 0, assign a new value
          return { ...item, Id: newGridIdCreated };
        } else {
          return item; // Keep existing Id values
        }
      });

      updateDocument(prevDocs);
    }
  }, [newGridIdCreated]);

  useEffect(() => {
    let attributes = {};
    let attributeSet = {};
    const gridColumnsArr = [];
    let dropdownOptionsObj = {};
    const sectionHasDocuments = [];
    section?.Attribute?.forEach(async (eachAttribute) => {
      if (eachAttribute.AT === "Attribute") {
        const defaultAttributeValue =
          eachAttribute.DT === "DropdownSingle" ||
          eachAttribute.DT === "Date" ||
          eachAttribute.DT === "SearchableDropdownSingle" ||
          eachAttribute.DT === "Document" ||
          eachAttribute.DT === "DropdownMultiple"
            ? null
            : eachAttribute.DT === "Text" || eachAttribute.DT === "Number"
            ? ""
            : eachAttribute.DT === "Checkbox"
            ? false
            : "";

        setAttributesData((prev) => ({
          ...prev,
          [eachAttribute.AC]: defaultAttributeValue,
        }));

        attributes = {
          ...attributes,
          [eachAttribute.AC]: defaultAttributeValue,
        };

        if (eachAttribute.DT === "Document") {
          setSectionHasDocuments(true);
          sectionHasDocuments.push(eachAttribute.AC);
        }

        setAttributeValidation((prev) => ({
          ...prev,
          [eachAttribute.AC]: JSON.parse(eachAttribute.V),
        }));

        setAttributeValidation((prev) => ({
          ...prev,
          [eachAttribute.AC]: {
            ...prev[eachAttribute.AC],
            DD: eachAttribute.DD !== null ? JSON.parse(eachAttribute.DD) : null,
            isDisable: false,
          },
        }));

        if (
          eachAttribute.DT === "DropdownSingle" ||
          eachAttribute.DT === "DropdownMultiple"
        ) {
          dropdownOptionsObj = {
            ...dropdownOptionsObj,
            [eachAttribute.AC]: eachAttribute.OPT,
          };

          setOptionsObj((prev) => ({
            ...prev,
            [eachAttribute.AC]: eachAttribute.OPT,
          }));

          if (JSON.parse(eachAttribute.AD).DF !== undefined) {
            const filteredOPT = eachAttribute.OPT?.filter(
              (o) => o.label?.toLowerCase() === JSON.parse(eachAttribute.AD).DF
            );
            if (filteredOPT.length > 0) {
              setAttributesData((prev) => ({
                ...prev,
                [eachAttribute.AC]: filteredOPT[0],
              }));
              attributeSet = {
                ...attributeSet,
                [eachAttribute.AC]: filteredOPT[0],
              };
            }
          }
        } else if (eachAttribute.DT === "SearchableDropdownSingle") {
          dropdownOptionsObj = {
            ...dropdownOptionsObj,
            [eachAttribute.AC]: "SearchableDropdownSingle",
          };

          setOptionsObj((prev) => ({
            ...prev,
            [eachAttribute.AC]: "SearchableDropdownSingle",
          }));
        }
      } else if (eachAttribute.AT === "AttributeSet") {
        setAttributeSetCode(eachAttribute.AC);

        gridColumnsArr.push({
          name: "Id",
          label: "Id",
          options: { display: false },
        });

        // MEthod 1: Hardcode condiion for Each Section:
        // if (section.SN === 'BankInformation'){
        //   setEffectiveDateCode('EFFCTVDT');
        // }

        // MEthod 2: Find string 'Effective Date' as label from Attributes array:
        const effectDateObj = eachAttribute.Attribute.find(
          (item) => item.AN === "Effective Date"
        );
        if (effectDateObj !== null && effectDateObj !== undefined) {
          setEffectiveDateCode(effectDateObj?.AC);
        }

        eachAttribute.Attribute.forEach(async (subAttribute) => {
          if (subAttribute.DT === "Document") {
            setSectionHasDocuments(true);
            sectionHasDocuments.push(subAttribute.AC);
          }

          const display =
            JSON.parse(subAttribute.AD)?.ISOG?.toLowerCase() === "true"
              ? true
              : false;

          gridColumnsArr.push({
            name: subAttribute.AC,
            label: subAttribute.AN,
            options: { sort: false, display },
          });

          setAttributeSetValidation((prev) => ({
            ...prev,
            [subAttribute.AC]: JSON.parse(
              subAttribute.AC === "ATTP" &&
                ((globalEC[0] != undefined &&
                  globalEC[0].ecLabel === "Staff") ||
                  (globalEC[0] != undefined &&
                    globalEC[0].ecLabel === "Retainer"))
                ? '{"ISM": "true","FS": "5","FX": ".docx,.doc,.pdf,.png,.jpg,.jpeg,.xlsx,.txt,.ppt,.pptx","FC": "1"}'
                : subAttribute.AC === "BNKATTCH" &&
                  ((globalEC[0] != undefined &&
                    globalEC[0].ecLabel === "Staff") ||
                    (globalEC[0] != undefined &&
                      globalEC[0].ecLabel === "Retainer"))
                ? '{"ISM": "true","FS": "5","FX": ".docx,.doc,.pdf,.png,.jpg,.jpeg,.xlsx,.txt,.ppt,.pptx","FC": "1"}'
                : subAttribute.V
            ),
          }));

          setAttributeSetValidation((prev) => ({
            ...prev,
            [subAttribute.AC]: {
              ...prev[subAttribute.AC],
              DD: subAttribute.DD !== null ? JSON.parse(subAttribute.DD) : null,
              // isDisable: false,
              isDisable: subAttribute.AC === "OTHLEN" ? true : false,
            },
          }));

          const defaultAttributeValue =
            subAttribute.DT === "DropdownSingle" ||
            subAttribute.DT === "Date" ||
            subAttribute.DT === "SearchableDropdownSingle" ||
            subAttribute.DT === "Document" ||
            subAttribute.DT === "DropdownMultiple"
              ? null
              : subAttribute.DT === "Text" || subAttribute.DT === "Number"
              ? ""
              : subAttribute.DT === "Checkbox"
              ? false
              : "";

          setAttributeSetData((prev) => ({
            ...prev,
            [subAttribute.AC]: defaultAttributeValue,
          }));

          attributeSet = {
            ...attributeSet,
            [subAttribute.AC]: defaultAttributeValue,
          };

          if (
            subAttribute.DT === "DropdownSingle" ||
            subAttribute.DT === "DropdownMultiple"
          ) {
            dropdownOptionsObj = {
              ...dropdownOptionsObj,
              [subAttribute.AC]: subAttribute.OPT,
            };

            if (
              subAttribute.AC === "STSE" &&
              section.SN === "FamilyMemberDetails"
            ) {
              if (subAttribute.OPT?.length > 0) {
                setAttributeSetData((prev) => ({
                  ...prev,
                  [subAttribute.AC]: subAttribute.OPT?.filter(
                    (x) => x.label.toLowerCase() === "living"
                  )[0],
                }));
                attributeSet = {
                  ...attributeSet,
                  [subAttribute.AC]: subAttribute.OPT?.filter(
                    (x) => x.label.toLowerCase() === "living"
                  )[0],
                };
              }
            }

            if (
              subAttribute.AC === "PVSEMP" &&
              section.SN === "PreviousEmployment"
            ) {
              if (subAttribute.OPT?.length > 0) {
                setAttributeSetData((prev) => ({
                  ...prev,
                  [subAttribute.AC]: subAttribute.OPT?.filter(
                    (x) => x.label.toLowerCase() === "living"
                  )[0],
                }));
                attributeSet = {
                  ...attributeSet,
                  [subAttribute.AC]: subAttribute.OPT?.filter(
                    (x) => x.label.toLowerCase() === "living"
                  )[0],
                };
              }
            }

            setOptionsObj((prev) => ({
              ...prev,
              [subAttribute.AC]: subAttribute.OPT,
            }));

            if (JSON.parse(subAttribute.AD).DF !== undefined) {
              const filteredOPT = subAttribute.OPT?.filter(
                (o) => o.label?.toLowerCase() === JSON.parse(subAttribute.AD).DF
              );
              if (filteredOPT.length > 0) {
                setAttributeSetData((prev) => ({
                  ...prev,
                  [subAttribute.AC]: filteredOPT[0],
                }));
                attributeSet = {
                  ...attributeSet,
                  [subAttribute.AC]: filteredOPT[0],
                };
              }
            }
          } else if (subAttribute.DT === "SearchableDropdownSingle") {
            dropdownOptionsObj = {
              ...dropdownOptionsObj,
              [subAttribute.AC]: "SearchableDropdownSingle",
            };

            setOptionsObj((prev) => ({
              ...prev,
              [subAttribute.AC]: "SearchableDropdownSingle",
            }));
          }
        });
      }
    });

    setOgAttributeSet(attributeSet);
    setGridColumns((prev) => [...prev, ...gridColumnsArr, action]);
    const fillData = async () => {
      showLoader();

      if (isOnce && TId !== 0) {
        let attributesRes = hasValue[0]?.attributes || [];
        let flatArr = {};
        let mainArr = {};
        let setArr = {};
        let filledData = [];

        let mainJsonArr = [];

        let gridData = [];
        const sectionId = section?.SId;

        // Handle flat attributes

        if (IsSendBack) {
          let attirbutes = section?.Attribute || [];
          let flatAttributeObj: any = {};

          const filterSectionData = sendBackJson[section?.SId];

          for (const attribute of attirbutes) {
            if (sectionHasDocuments.length > 0) {
              // getDocuments for Flat
              for (const AC of sectionHasDocuments) {
                if (attribute.AC === AC) {
                  await showLoader();
                  const getDocument = await APICall(getDocuments, "POST", {
                    TId,
                    AC: AC,
                  });

                  if (getDocument && getDocument.data !== null) {
                    setSelectedFilesForFlat(getDocument.data.files);
                    //add doc in global state
                    if (getDocument.data.files.length > 0) {
                      let documents = getDocument.data.files;
                      documents = documents.map((doc) => ({
                        ...doc,
                        IsNew: false,
                        IsActive: true,
                        MN: "EmployeeCentral",
                        TN: "Personal",
                        SN: section.SN,
                        IsValidFile: true,
                      }));
                      insertDocument(documents);
                    }
                  } else {
                    setSelectedFilesForFlat([]);
                  }
                  await hideLoader();
                }
              }
            }

            const attributeCode = attribute["AC"];
            if (attribute["AT"] == "Attribute") {
              if (attribute["DT"]) {
                switch (attribute["DT"]) {
                  case "DropdownSingle":
                  case "DropdownMultiple":
                    let options = dropdownOptionsObj[attributeCode] || [];
                    let filteredOption = options?.find(
                      (option) =>
                        option.value == filterSectionData[attributeCode]?.value
                    );
                    flatAttributeObj[attributeCode] = filteredOption || "";
                    break;
                  case "Text":
                  case "Number":
                    flatAttributeObj[attributeCode] =
                      filterSectionData[attributeCode] || "";
                    break;
                  case "SearchableDropdownSingle":
                    const DDResponse = await getSearchableDropdownAPI(
                      "",
                      attributeCode,
                      null,
                      filterSectionData[attributeCode]?.value || 0,
                      "attribute",
                      0
                    );
                    flatAttributeObj[attributeCode] = DDResponse
                      ? { label: DDResponse?.label, value: DDResponse?.value }
                      : null;
                    break;
                  case "Date":
                    if (
                      filterSectionData[attributeCode] &&
                      moment(filterSectionData[attributeCode]).isValid()
                    ) {
                      flatAttributeObj[attributeCode] = moment(
                        filterSectionData[attributeCode]
                      ).format("DD-MMM-YYYY");
                    } else {
                      flatAttributeObj[attributeCode] = null;
                    }
                    break;
                  default:
                    break;
                }
              }
            } else if (attribute["AT"] == "AttributeSet") {
              if (sectionHasDocuments.length > 0) {
                // getDocuments for Set
                for (const AC of sectionHasDocuments) {
                  if (attributeSet.hasOwnProperty(AC)) {
                    await showLoader();
                    const getDocument = await APICall(getDocuments, "POST", {
                      TId,
                      AC: AC,
                    });

                    if (getDocument && getDocument.data !== null) {
                      setSelectedFilesForSet(getDocument.data.files);
                      //add doc in global state
                      if (getDocument.data.files.length > 0) {
                        let documents = getDocument.data.files;
                        documents = documents.map((doc) => ({
                          ...doc,
                          IsNew: false,
                          IsActive: true,
                          MN: "EmployeeCentral",
                          TN: "Personal",
                          SN: section.SN,
                          IsValidFile: true,
                        }));
                        insertDocument(documents);
                      }
                    } else {
                      setSelectedFilesForSet([]);
                    }
                    await hideLoader();
                  }
                }
              }

              const subAttribute = attribute["Attribute"];

              if (Array.isArray(subAttribute)) {
                const attributesResDetails =
                  filterSectionData[attributeCode] || [];

                for (const detail of attributesResDetails) {
                  let showGridObj = {};
                  let valueGridObj = {};
                  let mainObj = {};
                  for (const key in detail) {
                    if (key == "Id") {
                      showGridObj[key] = detail[key];
                      valueGridObj[key] = detail[key];
                      mainObj[key] = detail[key];
                    } else {
                      // find the type

                      const subAttributeDetails = subAttribute?.find(
                        (i) => i?.AC == key
                      );

                      let value = detail[key];
                      if (subAttributeDetails) {
                        switch (subAttributeDetails["DT"]) {
                          case "DropdownSingle":
                          case "DropdownMultiple":
                            let options = subAttributeDetails["OPT"] || [];

                            let filteredOption = options?.find(
                              (option) => option.value == value?.value
                            );

                            showGridObj[key] = filteredOption?.label || "";
                            valueGridObj[key] = filteredOption || null;
                            mainObj[key] = filteredOption;
                            break;

                          case "Text":
                          case "Number":
                            showGridObj[key] = value;
                            valueGridObj[key] = value;
                            mainObj[key] = value;
                            break;
                          case "SearchableDropdownSingle":
                            const searchAblefilteredOption =
                              await getSearchableDropdownAPI(
                                "",
                                key,
                                null,
                                value?.value,
                                "attribute",
                                0
                              );
                            showGridObj[key] = searchAblefilteredOption?.label;
                            valueGridObj[key] = searchAblefilteredOption;
                            mainObj[key] = searchAblefilteredOption;
                            break;
                          case "Date":
                            if (value && moment(value).isValid()) {
                              showGridObj[key] =
                                moment(value).format("DD-MMM-YYYY");
                              valueGridObj[key] =
                                moment(value).format("DD-MMM-YYYY");
                              mainObj[key] =
                                moment(value).format("DD-MMM-YYYY");
                            } else {
                              showGridObj[key] = "";
                              valueGridObj[key] = null;
                              mainObj[key] = null;
                            }
                            break;
                          default:
                            if (key == "IsActive") {
                              showGridObj[key] = value;
                              valueGridObj[key] = value;
                              mainObj[key] = value;
                            } else {
                              showGridObj[key] = value || "";
                              valueGridObj[key] = value || "";
                              mainObj[key] = value || "";
                            }

                            break;
                        }
                      } else {
                        // showGridObj[key] = value || "";
                        // valueGridObj[key] = value || null;
                        // mainObj[key] = value || null;
                        if (key == "IsActive") {
                          showGridObj[key] = value;
                          valueGridObj[key] = value;
                          mainObj[key] = value;
                        } else {
                          showGridObj[key] = value || "";
                          valueGridObj[key] = value || "";
                          mainObj[key] = value || "";
                        }
                      }
                    }
                  }
                  // showGridObj["IsActive"] = true;
                  // valueGridObj["IsActive"] = true;
                  // mainObj["IsActive"] = true;

                  gridData.push(showGridObj);
                  filledData.push(valueGridObj);
                  mainJsonArr.push(mainObj);
                }
              }
              setArr = { [attributeCode]: mainJsonArr };

              gridData = gridData.filter((i) => i?.IsActive == true);
              //filledData = filledData.filter((i) => i?.IsActive == true);

              setDisplayGrid(gridData);
              setGridData(gridData);
              setOgFilledAttributeSet(filledData);
              setWholseAttributeSetData(mainJsonArr);
            }
          }

          //hard code to fill display name on load
          if (section.SN === "Basic") {
            let name =
              flatAttributeObj.MN !== null &&
              flatAttributeObj.MN !== undefined &&
              flatAttributeObj.MN !== ""
                ? `${flatAttributeObj.FN} ${flatAttributeObj.MN} ${flatAttributeObj.LN}`.trim()
                : `${flatAttributeObj.FN} ${flatAttributeObj.LN}`.trim();
            flatAttributeObj = {
              ...flatAttributeObj,
              ["DN"]: `${name}`.trim(),
            };

            if (
              flatAttributeObj.DOB != null ||
              flatAttributeObj.DOB != undefined ||
              flatAttributeObj.DOB != ""
            ) {
              const ageInYears = moment().diff(
                moment(flatAttributeObj.DOB).format(),
                "years"
              );

              flatAttributeObj = {
                ...flatAttributeObj,
                ["AGE"]: ageInYears,
              };
            }
          }

          flatArr = flatAttributeObj;
          setAttributesData(flatAttributeObj);
          mainArr = { [sectionId]: { ...setArr, ...flatArr } };
          setSectionDataAndJsonData(mainArr);

          // setDisplayGrid(gridData);
          // setGridData(gridData);
          // setOgFilledAttributeSet(filledData);
          // setAttributesData(flatAttributeObj);
        } else {
          if (attributesRes.length > 0) {
            let attirbutes = section?.Attribute || [];
            let flatAttributeObj: any = {};

            for (const attribute of attirbutes) {
              if (sectionHasDocuments.length > 0) {
                // getDocuments for Flat
                for (const AC of sectionHasDocuments) {
                  if (attribute.AC === AC) {
                    await showLoader();
                    const getDocument = await APICall(getDocuments, "POST", {
                      TId,
                      AC: AC,
                    });

                    if (getDocument && getDocument.data !== null) {
                      setSelectedFilesForFlat(getDocument.data.files);
                      //add doc in global state
                      if (getDocument.data.files.length > 0) {
                        let documents = getDocument.data.files;
                        documents = documents.map((doc) => ({
                          ...doc,
                          IsNew: false,
                          IsActive: true,
                          MN: "EmployeeCentral",
                          TN: "Personal",
                          SN: section.SN,
                          IsValidFile: true,
                        }));
                        insertDocument(documents);
                      }
                    } else {
                      setSelectedFilesForFlat([]);
                    }
                    await hideLoader();
                  }
                }
              }

              const attributeCode = attribute["AC"];
              if (attribute["AT"] == "Attribute") {
                let filterAttributeRes = attributesRes?.find(
                  (i) => i["key"] == attributeCode
                );

                if (attribute["DT"]) {
                  if (attributeCode == "IN") {
                  }
                  switch (attribute["DT"]) {
                    case "DropdownSingle":
                    case "DropdownMultiple":
                      let options = dropdownOptionsObj[attributeCode] || [];
                      let filteredOption = options?.find(
                        (option) => option.value == filterAttributeRes?.value
                      );
                      flatAttributeObj[attributeCode] = filteredOption || "";
                      break;
                    case "Text":
                    case "Number":
                      flatAttributeObj[attributeCode] =
                        filterAttributeRes?.value || "";
                      break;
                    case "SearchableDropdownSingle":
                      const DDResponse = await getSearchableDropdownAPI(
                        "",
                        attributeCode,
                        null,
                        filterAttributeRes?.value || 0,
                        "attribute",
                        0
                      );

                      if (DDResponse?.label && DDResponse?.value) {
                        flatAttributeObj[attributeCode] = DDResponse
                          ? {
                              label: DDResponse?.label,
                              value: DDResponse?.value,
                            }
                          : null;
                      } else {
                        flatAttributeObj[attributeCode] = null;
                      }
                      break;
                    case "Date":
                      if (
                        filterAttributeRes?.value &&
                        moment(filterAttributeRes?.value).isValid()
                      ) {
                        flatAttributeObj[attributeCode] = moment(
                          filterAttributeRes?.value
                        ).format("DD-MMM-YYYY");
                      } else {
                        flatAttributeObj[attributeCode] = null;
                      }
                      break;
                    default:
                      if (attributeCode == "IsActive") {
                        flatAttributeObj[attributeCode] =
                          filterAttributeRes?.value;
                      } else {
                        flatAttributeObj[attributeCode] =
                          filterAttributeRes?.value;
                      }
                      break;
                  }
                }
              } else if (attribute["AT"] == "AttributeSet") {
                if (sectionHasDocuments.length > 0) {
                  // getDocuments for Set
                  for (const AC of sectionHasDocuments) {
                    if (attributeSet.hasOwnProperty(AC)) {
                      await showLoader();
                      const getDocument = await APICall(getDocuments, "POST", {
                        TId,
                        AC: AC,
                      });

                      if (getDocument && getDocument.data !== null) {
                        setSelectedFilesForSet(getDocument.data.files);
                        //add doc in global state
                        if (getDocument.data.files.length > 0) {
                          let documents = getDocument.data.files;
                          documents = documents.map((doc) => ({
                            ...doc,
                            IsNew: false,
                            IsActive: true,
                            MN: "EmployeeCentral",
                            TN: "Personal",
                            SN: section.SN,
                            IsValidFile: true,
                          }));
                          insertDocument(documents);
                        }
                      } else {
                        setSelectedFilesForSet([]);
                      }
                      await hideLoader();
                    }
                  }
                }

                const subAttribute = attribute["Attribute"];
                const details = attirbutes["details"];

                if (Array.isArray(subAttribute)) {
                  const filterAttributeRes = attributesRes?.find(
                    (i) => i?.key == attributeCode
                  );
                  const attributesResDetails =
                    filterAttributeRes?.details || [];

                  for (const detail of attributesResDetails) {
                    let showGridObj = {};
                    let valueGridObj = {};
                    let mainObj = {};
                    for (const key in detail) {
                      if (key == "Id") {
                        showGridObj[key] = detail[key];
                        valueGridObj[key] = detail[key];
                        mainObj[key] = detail[key];
                      } else {
                        // find the type

                        const subAttributeDetails = subAttribute?.find(
                          (i) => i?.AC == key
                        );

                        let value = detail[key];
                        if (subAttributeDetails) {
                          switch (subAttributeDetails["DT"]) {
                            case "DropdownSingle":
                            case "DropdownMultiple":
                              let options = subAttributeDetails["OPT"] || [];

                              let filteredOption = options?.find(
                                (option) => option.value == value
                              );

                              showGridObj[key] = filteredOption?.label || "";
                              valueGridObj[key] = filteredOption || null;
                              mainObj[key] = filteredOption;
                              break;

                            case "Text":
                            case "Number":
                              showGridObj[key] = value;
                              valueGridObj[key] = value;
                              mainObj[key] = value;
                              break;
                            case "SearchableDropdownSingle":
                              const searchAblefilteredOption =
                                await getSearchableDropdownAPI(
                                  "",
                                  key,
                                  null,
                                  value,
                                  "attribute",
                                  0
                                );
                              showGridObj[key] =
                                searchAblefilteredOption?.label;
                              valueGridObj[key] = searchAblefilteredOption;
                              mainObj[key] = searchAblefilteredOption;
                              break;
                            case "Date":
                              if (value && moment(value).isValid()) {
                                showGridObj[key] =
                                  moment(value).format("DD-MMM-YYYY");
                                valueGridObj[key] =
                                  moment(value).format("DD-MMM-YYYY");
                                mainObj[key] =
                                  moment(value).format("DD-MMM-YYYY");
                              } else {
                                showGridObj[key] = "";
                                valueGridObj[key] = null;
                                mainObj[key] = null;
                              }
                              break;
                            default:
                              if (key == "IsActive") {
                                showGridObj[key] = value;
                                valueGridObj[key] = value;
                                mainObj[key] = value;
                              } else {
                                showGridObj[key] = value || "";
                                valueGridObj[key] = value || "";
                                mainObj[key] = value || "";
                              }
                              break;
                          }
                        } else {
                          if (key == "IsActive") {
                            showGridObj[key] = value;
                            valueGridObj[key] = value;
                            mainObj[key] = value;
                          } else {
                            showGridObj[key] = value || "";
                            valueGridObj[key] = value || "";
                            mainObj[key] = value || "";
                          }
                        }
                      }
                    }
                    showGridObj["IsActive"] = true;
                    valueGridObj["IsActive"] = true;
                    mainObj["IsActive"] = true;
                    gridData.push(showGridObj);
                    filledData.push(valueGridObj);
                    mainJsonArr.push(mainObj);
                  }
                }
                setArr = { [attributeCode]: mainJsonArr };
                setDisplayGrid(gridData);
                setGridData(gridData);
                setOgFilledAttributeSet(filledData);
                setWholseAttributeSetData(mainJsonArr);
              }
            }

            //hard code to fill display name on load
            if (section.SN === "Basic") {
              let name =
                flatAttributeObj.MN !== null &&
                flatAttributeObj.MN !== undefined &&
                flatAttributeObj.MN !== ""
                  ? `${flatAttributeObj.FN} ${flatAttributeObj.MN} ${flatAttributeObj.LN}`.trim()
                  : `${flatAttributeObj.FN} ${flatAttributeObj.LN}`.trim();
              flatAttributeObj = {
                ...flatAttributeObj,
                ["DN"]: `${name}`.trim(),
              };

              if (
                flatAttributeObj.DOB != null ||
                flatAttributeObj.DOB != undefined ||
                flatAttributeObj.DOB != ""
              ) {
                const ageInYears = moment().diff(
                  moment(flatAttributeObj.DOB).format(),
                  "years"
                );

                flatAttributeObj = {
                  ...flatAttributeObj,
                  ["AGE"]: ageInYears,
                };
              }
            }

            flatArr = flatAttributeObj;
            setAttributesData(flatAttributeObj);
            mainArr = { [sectionId]: { ...setArr, ...flatArr } };
            setSectionDataAndJsonData(mainArr);
          } else if (attributesRes.length === 0){
            let attirbutes = section?.Attribute || [];
            let flatAttributeObj: any = {};

            const filterSectionData = sendBackJson[section?.SId];

            for (const attribute of attirbutes) {
              const attributeCode = attribute["AC"];
              if (attribute["AT"] == "Attribute") {
                if (attribute["DT"]) {
                  switch (attribute["DT"]) {
                    case "DropdownSingle":
                    case "DropdownMultiple":
                      flatAttributeObj[attributeCode] = "";
                      break;
                    case "Text":
                    case "Number":
                      flatAttributeObj[attributeCode] = "";
                      break;
                    case "SearchableDropdownSingle":
                      flatAttributeObj[attributeCode] = null;
                      break;
                    case "Date":
                      flatAttributeObj[attributeCode] = null;
                      break;
                    default:
                      break;
                  }
                }
              }
            }

            flatArr = flatAttributeObj;
            mainArr = { [sectionId]: { ...setArr, ...flatArr } };
            setSectionDataAndJsonData(mainArr);

            mainArr = { [sectionId]: { ...setArr, ...flatArr } };
          }
        }

        setIsOnce(false);
      }

      hideLoader();
    };

    const fillTimer = setTimeout(() => {
      clearTimeout(fillTimer);
      fillData();
    }, 2000);

    //save in state
    return () => {
      clearTimeout(fillTimer);
    };
  }, []);

  function formatValue(value, inputFormat, outputFormat) {
    const dateString = value;
    const allowedFormats = [
      inputFormat,
      "01-01-0001 12.00.00 AM",
      "1/1/0001 12:00:00 AM",
      "01-Jan-0001",
      "01-Jan-1900",
    ];

    for (const format of allowedFormats) {
      const isValid = moment(dateString, format, true).isValid();
      if (isValid) {
        if (
          dateString === "01-01-0001 12.00.00 AM" ||
          dateString === "1/1/0001 12:00:00 AM" ||
          dateString === "01-Jan-0001" ||
          dateString === "01-Jan-1900"
        ) {
          return null;
        }

        return moment(dateString, inputFormat).format(outputFormat);
      }
    }

    return value;
  }

  useEffect(() => {
    // ;
    if (
      attributesData !== undefined &&
      Object.keys(attributesData).length > 0 &&
      attributeValidation !== undefined &&
      Object.keys(attributeValidation).length > 0
    ) {
      if (
        (isValidateOnce && isOnce === false && TId !== 0) ||
        (isValidateOnce && isOnce === true && TId === 0)
      ) {
        let attributeValues = { ...attributesData }; // for checking values
        let attributeValObj = { ...attributeValidation };
        Object.keys(attributeValObj).forEach(async (attribute) => {
          if (
            attributeValObj[attribute].DD !== null &&
            attributeValObj[attribute].DD.length > 0
          ) {
            let ad = attributeValObj[attribute].DD;

            ad.forEach(async (record) => {
              let CCode = record.CCode;
              let PValue = record.PValue;
              let Condition = record.Condition;
              let Flag = record.Flag;
              let PDT = record.PDT;
              let CDT = record.CDT;

              if (PDT?.toLowerCase() === "checkbox") {
                if (
                  attributeValues[attribute].toString()?.toLowerCase() ===
                    PValue?.toLowerCase() ||
                  attributeValues[attribute].toString()?.toLowerCase() === ""
                ) {
                  if (Flag === "disable") {
                    attributeValObj[CCode].isDisable = true;
                    attributeValObj[CCode].ISM = "false";
                  }
                } else if (
                  attributeValues[attribute].toString()?.toLowerCase() !==
                  PValue?.toLowerCase()
                ) {
                  if (Flag === "disable") {
                    attributeValObj[CCode].isDisable = false;
                    attributeValObj[CCode].ISM = "true";
                  }
                }
              } else if (PDT === "DropdownSingle") {
                if (attribute === "MARITLST") {
                  if (attributeValues[attribute]?.label === "Married") {
                    attributeValObj["ProofBD"].ISM = "false";
                  }
                }
                if (
                  attributeValues[attribute] !== null &&
                  attributeValues[attribute]?.label
                    ?.toString()
                    ?.toLowerCase() === PValue?.toLowerCase()
                ) {
                  //check condition
                  if (Flag === "disable") {
                    attributeValObj[CCode].isDisable = true;
                    attributeValObj[CCode].ISM = "false";
                  }
                } else {
                  if (attributeValObj[CCode] !== undefined) {
                    attributeValObj[CCode].isDisable = false;
                    attributeValObj[CCode].ISM =
                      attributeValues[attribute]?.label === "Married"
                        ? "true"
                        : CCode === "MARITLSTS"
                        ? "false"
                        : attributeValues[attribute]?.label === "Worker"
                        ? "true"
                        : CCode === "SC"
                        ? "false"
                        : "true";
                  }
                }
              } else if (PDT === "Document") {
              }
            });
          }
          // else {
          //   if (attribute === "Gen") {
          //     if (attributeValues[attribute]?.label === "Female") {
          //       attributeValObj["MAN"].isDisable = false;
          //       attributeValObj["MAN"].ISM = "true";
          //     }
          //   }
          // }
        });
        setAttributeValidation((prev) => ({ ...prev, ...attributeValObj }));
        setIsValidateOnce(false);
      }
    }
  }, [attributesData, isOnce]);

  const action = {
    name: "Id",
    label: "Action",
    options: {
      filter: false,
      sort: false,
      display: true,
      setCellProps: () => ({
        style: { textAlign: "center" },
      }),
      setCellHeaderProps: () => ({
        style: { textAlign: "center" },
      }),
      customBodyRender: (value, tableMeta) => {
        let Id = tableMeta.tableData[tableMeta.rowIndex].Id;

        return (
          <div className="d-flex justify-content-center">
            {currentRoleId?.value == 7 && (
              //sectionsForProgEss.includes(section?.SN) &&
              <Tooltip title="edit">
                <a
                  className="mr-2"
                  onClick={(e) => {
                    e.preventDefault();
                    setFormErrors({});
                    setMultiDropdown(false);
                    setShowAttrSet(true);
                    setCurrentGridId(Id);
                  }}
                >
                  <i className="fas fa-edit"></i>
                </a>
              </Tooltip>
            )}
            {section?.ISDEL && (
              <Tooltip title="delete">
                <a
                  className="mr-2"
                  onClick={(e) => {
                    e.preventDefault();
                    setFormErrors({});
                    //handleDeleteGrid(Id);
                    setDeleteGridId(Id);
                  }}
                >
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </a>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  };

  useEffect(() => {
    if (currentGridId) {
      let attributeSetData = wholeaAttributeSetData?.find(
        (record) => record.Id === currentGridId
      );
      if (attributeSetData.PA != undefined && attributeSetData.PA != null) {
        if (
          attributeSetData.PA.label?.toLowerCase() === "emergency" ||
          attributeSetData.PA.label?.toLowerCase() === "permanent"
        ) {
          setOptionsObj((prev) => ({
            ...prev,
            ["ADRTYP"]: [],
          }));

          let attributeSetValObj = {
            ...attributeSetValidation,
          };
          attributeSetValObj.ADRTYP.ISM = "false";
          if (attributeSetData.PA.label.toLowerCase() === "permanent") {
            attributeSetValObj.ADRTYP.isDisable = "true";
          }
          setAttributeSetValidation((prev) => ({
            ...prev,
            ...attributeSetValObj,
          }));
        }
      }

      if (
        attributeSetData.IDCATY != undefined &&
        attributeSetData.IDCATY != null
      ) {
        let attributeSetValObj = {
          ...attributeSetValidation,
        };
        if (
          attributeSetData.IDCATY.label.toLowerCase() === "aadhar card" ||
          attributeSetData.IDCATY.label.toLowerCase() === "pan card"
        ) {
          if (
            attributeSetData.IDCATY.label.toLowerCase() === "aadhar card" ||
            attributeSetData.IDCATY.label.toLowerCase() === "pan card"
          ) {
            attributeSetValObj.NTNLATTCH.ISM = "true";
          } else {
            attributeSetValObj.NTNLATTCH.ISM = "false";
          }
        } else {
          attributeSetValObj.NTNLATTCH.ISM = "false";
        }
      }

      if (
        attributeSetData.PVSEMP != undefined &&
        attributeSetData.PVSEMP != null
      ) {
        dependentValidations(attributeSetData.PVSEMP, "PVSEMP", "Attributeset");
      }
      setAttributeSetData(attributeSetData);
    }
  }, [currentGridId]);

  useEffect(() => {
    if (deleteGridId && currentGridId === null) {
      //to disable grid delete button when edit is active
      setGridData((prev) => {
        return prev
          .map((record) => {
            if (record.Id === deleteGridId) {
              // Check if Id is a string/uuid, remove the entry from state
              if (typeof record.Id === "string" && isNaN(record.Id)) {
                return null; // Return null to filter this entry out
              }
              // Update the value for numeric Id
              return { ...record, IsActive: false };
            } else {
              return record;
            }
          })
          .filter((record) => record !== null); // Filter out entries with null (for uuid Ids)
      });

      // ogFilledAttributeSet
      //   ?.map((record) => {
      //     if (record.Id === deleteGridId) {
      //       if (
      //         record.PCE?.label === "Fixed Pay" ||
      //         record.PCE?.label === "Variable Pay"
      //       ) {
      //         setAttributesData((prev) => ({
      //           ...prev,
      //           ASA: attributesData["ASA"]
      //             ? (
      //                 parseInt(attributesData["ASA"]) - parseInt(record.AMT)
      //               ).toString()
      //             : "0",
      //         }));
      //       } else if (record.PCE?.label === "Stipend") {
      //         setAttributesData((prev) => ({
      //           ...prev,
      //           ASA: "0",
      //         }));
      //       }
      //       if (typeof record.Id === "string" && isNaN(record.Id)) {
      //         return null; // Return null to filter this entry out
      //       }
      //       return {
      //         ...record,
      //         IsActive: false,
      //       };
      //     }
      //     return record;
      //   })
      //   .filter((record) => record !== null);

      setOgFilledAttributeSet((prev) => {
        return prev
          .map((record) => {
            if (record.Id === deleteGridId) {
              if (
                record.PCE?.label === "Fixed Pay" ||
                record.PCE?.label === "Variable Pay"
              ) {
                setAttributesData((prev) => ({
                  ...prev,
                  ASA: attributesData["ASA"]
                    ? (
                        parseInt(attributesData["ASA"]) - parseInt(record.AMT)
                      ).toString()
                    : "0",
                }));
              } else if (record.PCE?.label === "Stipend") {
                setAttributesData((prev) => ({
                  ...prev,
                  ASA: "0",
                }));
              }

              if (typeof record.Id === "string" && isNaN(record.Id)) {
                return null; // Return null to filter this entry out
              }
              return {
                ...record,
                IsActive: false,
              };
            } else {
              return record;
            }
          })
          .filter((record) => record !== null);
      });

      const tempOgFilledAttributeSet = wholeaAttributeSetData
        .map((record) => {
          if (record.Id === deleteGridId) {
            if (
              record.PCE?.label === "Fixed Pay" ||
              record.PCE?.label === "Variable Pay"
            ) {
              setAttributesData((prev) => ({
                ...prev,
                ASA: attributesData["ASA"]
                  ? (
                      parseInt(attributesData["ASA"]) - parseInt(record.AMT)
                    ).toString()
                  : "0",
              }));
            } else if (record.PCE?.label === "Stipend") {
              setAttributesData((prev) => ({
                ...prev,
                ASA: "0",
              }));
            }
            if (typeof record.Id === "string" && isNaN(record.Id)) {
              return null; // Return null to filter this entry out
            }
            return {
              ...record,
              IsActive: false,
            };
          }
          return record;
        })
        .filter((record) => record !== null);

      setWholseAttributeSetData(tempOgFilledAttributeSet);

      handleChangeForSectionsData(
        tempOgFilledAttributeSet,
        attributeSetCode,
        true
      );

      setDisplayGrid((prev) =>
        prev.filter((record) => record.Id !== deleteGridId)
      );

      //delete documents for that grid record
      let fileToDelete = selectedFilesForSet.filter((sf) => {
        return sf.Id === deleteGridId;
      });
      if (fileToDelete.length > 0) {
        handleDeleteFileForSet(fileToDelete[0], 0);
      }
    }
    setDeleteGridId(null);
  }, [deleteGridId]);

  const gridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    //count: displayGrid.length,
    rowsPerPage: PageSize,
    page: page,
    serverSide: false,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical", //standard | vertical | simple
    onChangeRowsPerPage: (num) => {},

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
      }
      if (sortDirection === "desc") {
      }
    },

    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const handleDeleteGrid = async (Id) => {
    try {
      setGridData((prev) => {
        const newState = prev.map((record) => {
          if (record.Id === Id) {
            return {
              ...record,
              IsActive: false,
            };
          }
          return record;
        });
        return newState;
      });

      ogFilledAttributeSet?.map((record) => {
        if (record.Id === Id) {
          if (
            record.PCE?.label === "Fixed Pay" ||
            record.PCE?.label === "Variable Pay"
          ) {
            setAttributesData((prev) => ({
              ...prev,
              ASA: attributesData["ASA"]
                ? (
                    parseInt(attributesData["ASA"]) - parseInt(record.AMT)
                  ).toString()
                : "0",
            }));
          } else if (record.PCE?.label === "Stipend") {
            setAttributesData((prev) => ({
              ...prev,
              ASA: "0",
            }));
          }
          return {
            ...record,
            IsActive: false,
          };
        }
        return record;
      });

      const tempOgFilledAttributeSet = wholeaAttributeSetData.map((record) => {
        if (record.Id === Id) {
          if (
            record.PCE?.label === "Fixed Pay" ||
            record.PCE?.label === "Variable Pay"
          ) {
            setAttributesData((prev) => ({
              ...prev,
              ASA: attributesData["ASA"]
                ? (
                    parseInt(attributesData["ASA"]) - parseInt(record.AMT)
                  ).toString()
                : "0",
            }));
          } else if (record.PCE?.label === "Stipend") {
            setAttributesData((prev) => ({
              ...prev,
              ASA: "0",
            }));
          }
          return {
            ...record,
            IsActive: false,
          };
        }
        return record;
      });

      setWholseAttributeSetData(tempOgFilledAttributeSet);

      handleChangeForSectionsData(
        tempOgFilledAttributeSet,
        attributeSetCode,
        true
      );

      setOgFilledAttributeSet((prev) => {
        const newState = prev.map((record) => {
          if (record.Id === Id) {
            if (
              record.PCE?.label === "Fixed Pay" ||
              record.PCE?.label === "Variable Pay"
            ) {
              setAttributesData((prev) => ({
                ...prev,
                ASA: attributesData["ASA"]
                  ? (
                      parseInt(attributesData["ASA"]) - parseInt(record.AMT)
                    ).toString()
                  : "0",
              }));
            } else if (record.PCE?.label === "Stipend") {
              setAttributesData((prev) => ({
                ...prev,
                ASA: "0",
              }));
            }
            return {
              ...record,
              IsActive: false,
            };
          }
          return record;
        });
        return newState;
      });

      setDisplayGrid((prev) => prev.filter((record) => record.Id !== Id));

      //delete documents for that grid record
      let fileToDelete = selectedFilesForSet.filter((sf) => {
        return sf.Id === Id;
      });
      if (fileToDelete.length > 0) {
        handleDeleteFileForSet(fileToDelete[0], 0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const dependentValidations = (
    targetValue,
    targetAttributeCode,
    attributeType
  ) => {
    //  ;
    if (attributeType === "Attribute") {
      let attributeValues = { ...attributesData }; // for checking values
      let attributeValObj = { ...attributeValidation };

      if (
        attributeValObj[targetAttributeCode].DD !== null &&
        attributeValObj[targetAttributeCode].DD.length > 0
      ) {
        let ad = attributeValObj[targetAttributeCode].DD;

        ad.forEach(async (record) => {
          let CCode = record.CCode;
          let PValue = record.PValue;
          let Condition = record.Condition;
          let Flag = record.Flag;
          let PDT = record.PDT;
          let CDT = record.CDT;

          if (PDT === "DropdownSingle") {
            if (
              targetValue &&
              targetValue.label.toString()?.toLowerCase() ===
                PValue?.toLowerCase()
            ) {
              //check condition
              if (Flag === "disable") {
                attributeValObj[CCode].isDisable = true;
                attributeValObj[CCode].ISM = "false";
              }
            } else {
              attributeValObj[CCode].isDisable = false;
              attributeValObj[CCode].ISM =
                targetValue?.label === "Married"
                  ? "true"
                  : CCode === "MARITLSTS"
                  ? "false"
                  : targetValue?.label === "Worker"
                  ? "true"
                  : CCode === "SC"
                  ? "false"
                  : CCode === "VACXDT"
                  ? "false"
                  : CCode === "VACXDT2"
                  ? "false"
                  : CCode === "VACXDT3"
                  ? "false"
                  : "true";
            }
          } else if (PDT?.toLowerCase() === "checkbox") {
            if (
              targetValue.toString()?.toLowerCase() === PValue?.toLowerCase()
            ) {
              //check condition
              if (Flag === "disable") {
                attributeValObj[CCode].isDisable = true;
                attributeValObj[CCode].ISM = "false";
              }
            } else {
              attributeValObj[CCode].isDisable = false;
              attributeValObj[CCode].ISM = "true";
            }
          }

          //clear values based to CDT
          attributeValues[CCode] =
            CDT === "DropdownSingle" ||
            CDT === "Date" ||
            CDT === "SearchableDropdownSingle" ||
            CDT === "DropdownMultiple"
              ? null
              : CDT === "Text" || CDT === "Number"
              ? ""
              : CDT === "Checkbox"
              ? false
              : "";
        });

        attributeValues[targetAttributeCode] = targetValue; //imp
        setFormErrors({});
        setAttributeValidation((prev) => ({ ...prev, ...attributeValObj }));
        setAttributesData((prev) => ({
          ...prev,
          ...attributeValues,
        }));
      }
    } else {
      let attributeValues = { ...attributeSetData }; // for checking values
      let attributeValObj = { ...attributeSetValidation };

      if (
        attributeValObj[targetAttributeCode].DD !== null &&
        attributeValObj[targetAttributeCode].DD.length > 0
      ) {
        let ad = attributeValObj[targetAttributeCode].DD;

        ad.forEach(async (record) => {
          let CCode = record.CCode;
          let PValue = record.PValue;
          let Condition = record.Condition;
          let Flag = record.Flag;
          let PDT = record.PDT;
          let CDT = record.CDT;

          if (PDT === "DropdownSingle") {
            if (
              targetValue &&
              targetValue.label.toString()?.toLowerCase() ===
                PValue?.toLowerCase()
            ) {
              //check condition
              if (Flag === "disable") {
                attributeValObj[CCode].isDisable = true;
                attributeValObj[CCode].ISM = "false";
              } else if (Flag === "disablefalsemandatorytrue"){
                attributeValObj[CCode].isDisable = false;
                attributeValObj[CCode].ISM = "true";
              }
            } else if(CCode == "OTHLEN"){
              if(attributeValObj[CCode]!=undefined){
                attributeValObj[CCode].isDisable = true;
                attributeValObj[CCode].ISM = "false";
              }
            } else {
              attributeValObj[CCode].isDisable = false;
              attributeValObj[CCode].ISM =
                targetValue?.label === "Married"
                  ? "true"
                  : CCode === "MARITLSTS"
                  ? "false"
                  : targetValue?.label === "Worker"
                  ? "true"
                  : CCode === "SC"
                  ? "false"
                  : CCode === "VACXDT"
                  ? "false"
                  : CCode === "VACXDT2"
                  ? "false"
                  : CCode === "VACXDT3"
                  ? "false"
                  : "true";
            }
          } else if (PDT?.toLowerCase() === "checkbox") {
            if (
              targetValue.toString()?.toLowerCase() === PValue?.toLowerCase()
            ) {
              //check condition
              if (Flag === "disable") {
                attributeValObj[CCode].isDisable = true;
                attributeValObj[CCode].ISM = "false";
              }
            } else {
              attributeValObj[CCode].isDisable = false;
              attributeValObj[CCode].ISM = "true";
            }
          }

          //clear values based to CDT
          attributeValues[CCode] =
            CDT === "DropdownSingle" ||
            CDT === "Date" ||
            CDT === "SearchableDropdownSingle" ||
            CDT === "DropdownMultiple"
              ? null
              : CDT === "Text" || CDT === "Number"
              ? ""
              : CDT === "Checkbox"
              ? false
              : "";
        });

        attributeValues[targetAttributeCode] = targetValue; //imp
        setFormErrors({});
        setAttributeSetValidation((prev) => ({ ...prev, ...attributeValObj }));
        setAttributeSetData((prev) => ({
          ...prev,
          ...attributeValues,
        }));
      }
    }
  };
  const isValidEmail = (email, emailPattern) => {
    const regex = new RegExp(emailPattern);
    return regex.test(email);
  };

  const validateData = async (Attribute) => {
    return new Promise((resolve) => {
      let errorObj = {};
      let isError = false;
      Attribute.forEach(async (subAttribute) => {
        if (subAttribute.AT === "Attribute") {
          // const validation = JSON.parse(subAttribute.V);
          const validation = attributeSetValidation[subAttribute.AC];

          if (validation.ISM?.toLowerCase() === "true") {
            if (subAttribute.DT === "Text") {
              if (attributeSetData[subAttribute.AC]?.trim() === "") {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Required",
                };
              } else if (
                attributeSetData[subAttribute.AC]?.length > validation.MaxC
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: `Max ${validation.MaxC} characters allowed`,
                };
              } else if (validation.PTN != undefined) {
                if (
                  isValidEmail(
                    attributeSetData[subAttribute.AC],
                    validation.PTN
                  ) != true
                ) {
                  errorObj = {
                    ...errorObj,
                    [subAttribute.AC]: `Data is not in correct format.`,
                  };
                }
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }

              //Validate business email welspun.com

              if (
                subAttribute.AC === "EA" &&
                attributeSetData[subAttribute.AC] !== ""
              ) {
                if (
                  attributeSetData["ETP"] !== null &&
                  attributeSetData["ETP"].label?.toLowerCase() === "business"
                ) {
                  if (
                    !/^[a-z0-9](\.?[a-z0-9._]){4,}@welspun\.com$/.test(
                      attributeSetData["EA"]?.toLowerCase()
                    ) &&
                    !/^[a-z0-9](\.?[a-z0-9._]){4,}@welspunusa\.com$/.test(
                      attributeSetData["EA"].toLowerCase()
                    ) &&
                    !/^[a-z0-9](\.?[a-z0-9._]){4,}@christy\.co\.in$/.test(
                      attributeSetData["EA"].toLowerCase()
                    ) &&
                    !/^[a-z0-9](\.?[a-z0-9._]){4,}@welspun\.co\.uk$/.test(
                      attributeSetData["EA"].toLowerCase()
                    ) &&
                    !/^[a-z0-9](\.?[a-z0-9._]){4,}@baplrototech\.com$/.test(
                      attributeSetData["EA"].toLowerCase()
                    )
                  ) {
                    errorObj = {
                      ...errorObj,
                      [subAttribute.AC]: `Invalid Email`,
                    };
                  }
                } else if (
                  attributeSetData["ETP"] !== null &&
                  attributeSetData["ETP"].label?.toLowerCase() === "personal"
                ) {
                  if (
                    !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
                      attributeSetData["EA"]?.toLowerCase()
                    )
                  ) {
                    errorObj = {
                      ...errorObj,
                      [subAttribute.AC]: `Invalid Email`,
                    };
                  } else if (
                    /^[a-z0-9](\.?[a-z0-9._]){4,}@welspun\.com$/.test(
                      attributeSetData["EA"].toLowerCase()
                    )
                  ) {
                    errorObj = {
                      ...errorObj,
                      [subAttribute.AC]: `Invalid domain @welspun.com for personal email.`,
                    };
                  } else if (
                    /^[a-z0-9](\.?[a-z0-9._]){4,}@welspunusa\.com$/.test(
                      attributeSetData["EA"].toLowerCase()
                    )
                  ) {
                    errorObj = {
                      ...errorObj,
                      [subAttribute.AC]: `Invalid domain @welspunusa.com for personal email.`,
                    };
                  } else if (
                    /^[a-z0-9](\.?[a-z0-9._]){4,}@christy\.co\.in$/.test(
                      attributeSetData["EA"].toLowerCase()
                    )
                  ) {
                    errorObj = {
                      ...errorObj,
                      [subAttribute.AC]: `Invalid domain @christy.co.in for personal email.`,
                    };
                  } else if (
                    /^[a-z0-9](\.?[a-z0-9._]){4,}@welspun\.co\.uk$/.test(
                      attributeSetData["EA"].toLowerCase()
                    )
                  ) {
                    errorObj = {
                      ...errorObj,
                      [subAttribute.AC]: `Invalid domain @welspun.co.uk for personal email.`,
                    };
                  } else if (
                    /^[a-z0-9](\.?[a-z0-9._]){4,}@baplrototech\.com$/.test(
                      attributeSetData["EA"].toLowerCase()
                    )
                  ) {
                    errorObj = {
                      ...errorObj,
                      [subAttribute.AC]: `Invalid domain @baplrototech.com for personal email.`,
                    };
                  }
                }
              }

              //hard code to validate personalId based on selected IdCardType

              if (
                subAttribute.AC === "PERIDNM" &&
                attributeSetData[subAttribute.AC] !== ""
              ) {
                if (attributeSetData["IDCATY"] !== null) {
                  switch (attributeSetData["IDCATY"].label?.toLowerCase()) {
                    case "aadhar card":
                      if (
                        !NationalIdCardType.AADHAR.test(
                          attributeSetData[subAttribute.AC]
                        )
                      ) {
                        errorObj = {
                          ...errorObj,
                          [subAttribute.AC]: "Invalid Aadhar",
                        };
                      } else {
                        errorObj = {
                          ...errorObj,
                          [subAttribute.AC]: "",
                        };
                      }

                      break;
                    case "pan card":
                      if (
                        !NationalIdCardType.PAN.test(
                          attributeSetData[subAttribute.AC]
                        )
                      ) {
                        errorObj = {
                          ...errorObj,
                          [subAttribute.AC]: "Invalid PAN",
                        };
                      } else {
                        errorObj = {
                          ...errorObj,
                          [subAttribute.AC]: "",
                        };
                      }

                      break;
                    case "driving license":
                      if (
                        !NationalIdCardType.DRIVINGLICENCE.test(
                          attributeSetData[subAttribute.AC]
                        )
                      ) {
                        errorObj = {
                          ...errorObj,
                          [subAttribute.AC]: "Invalid Driving license",
                        };
                      } else {
                        errorObj = {
                          ...errorObj,
                          [subAttribute.AC]: "",
                        };
                      }

                      break;
                    case "voter id":
                      if (
                        !NationalIdCardType.VOTERID.test(
                          attributeSetData[subAttribute.AC]
                        )
                      ) {
                        errorObj = {
                          ...errorObj,
                          [subAttribute.AC]: "Invalid voter Id",
                        };
                      } else {
                        errorObj = {
                          ...errorObj,
                          [subAttribute.AC]: "",
                        };
                      }
                      break;
                    case "esic number":
                      if (
                        !NationalIdCardType.ESIC.test(
                          attributeSetData[subAttribute.AC]
                        )
                      ) {
                        errorObj = {
                          ...errorObj,
                          [subAttribute.AC]: "Invalid ESIC Number",
                        };
                      } else {
                        errorObj = {
                          ...errorObj,
                          [subAttribute.AC]: "",
                        };
                      }
                      break;
                    case "passport":
                      if (
                        !NationalIdCardType.PASSPORT.test(
                          attributeSetData[subAttribute.AC]
                        )
                      ) {
                        errorObj = {
                          ...errorObj,
                          [subAttribute.AC]: "Invalid Passport",
                        };
                      } else {
                        errorObj = {
                          ...errorObj,
                          [subAttribute.AC]: "",
                        };
                      }
                      break;
                    case "pran":
                      if (
                        !NationalIdCardType.PRAN.test(
                          attributeSetData[subAttribute.AC]
                        )
                      ) {
                        errorObj = {
                          ...errorObj,
                          [subAttribute.AC]: "Invalid PRAN",
                        };
                      } else {
                        errorObj = {
                          ...errorObj,
                          [subAttribute.AC]: "",
                        };
                      }
                      break;
                    case "provident fund":
                      if (
                        !NationalIdCardType.PF.test(
                          attributeSetData[subAttribute.AC]
                        )
                      ) {
                        errorObj = {
                          ...errorObj,
                          [subAttribute.AC]: "Invalid Provident fund",
                        };
                      } else {
                        errorObj = {
                          ...errorObj,
                          [subAttribute.AC]: "",
                        };
                      }
                      break;
                    case "uan":
                      if (
                        !NationalIdCardType.UAN.test(
                          attributeSetData[subAttribute.AC]
                        )
                      ) {
                        errorObj = {
                          ...errorObj,
                          [subAttribute.AC]: "Invalid UAN",
                        };
                      } else {
                        errorObj = {
                          ...errorObj,
                          [subAttribute.AC]: "",
                        };
                      }
                      break;
                    case "national insurance number":
                      if (
                        !NationalIdCardType.NATIONALINSURANCENUMBER.test(
                          attributeSetData[subAttribute.AC]
                        )
                      ) {
                        errorObj = {
                          ...errorObj,
                          [subAttribute.AC]:
                            "Invalid National Insurance Number",
                        };
                      } else {
                        errorObj = {
                          ...errorObj,
                          [subAttribute.AC]: "",
                        };
                      }

                      break;
                    case "national insurance number":
                      if (
                        !NationalIdCardType.NATIONALINSURANCENUMBER.test(
                          attributeSetData[subAttribute.AC]
                        )
                      ) {
                        errorObj = {
                          ...errorObj,
                          [subAttribute.AC]:
                            "Invalid National Insurance Number",
                        };
                      } else {
                        errorObj = {
                          ...errorObj,
                          [subAttribute.AC]: "",
                        };
                      }

                      break;
                    default:
                      break;
                  }
                }
              }
            } else if (
              subAttribute.DT === "DropdownSingle" ||
              subAttribute.DT === "SearchableDropdownSingle" ||
              subAttribute.DT === "DropdownMultiple"
            ) {
              if (attributeSetData[subAttribute.AC] === null) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Required",
                };
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }
            } else if (subAttribute.DT === "Date") {
              if (attributeSetData[subAttribute.AC] === null) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Required",
                };
              } else if (subAttribute.AC === "DOBE") {
                const ageYears = moment().diff(
                  moment(attributeSetData["DOBE"]),
                  "years"
                );
                switch (attributeSetData["RLP"].label?.toLowerCase()) {
                  case "father":
                  case "mother":
                    if (ageYears < 35) {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]:
                          "Father/Mother's Age Should be Minimum 35 Years.",
                      };
                    } else {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "",
                      };
                    }

                    break;
                  case "spouse":
                    if (ageYears < 18) {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]:
                          "Spouse Age Should be Minimum 18 Years.",
                      };
                    } else {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "",
                      };
                    }

                    break;
                  default:
                    break;
                }
              } else if (
                moment(
                  validation.MaxV === "CurrentDate" &&
                    attributeSetData[subAttribute.AC]
                ).isAfter(new Date())
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Only till current date allowed",
                };
              } else if (
                validation.MaxV === "CurrentMonth" &&
                attributeSetData[subAttribute.AC] != ""
              ) {
                let validatingValues = validateCurrentMonth(
                  attributeSetData[subAttribute.AC]
                );
                if (
                  validatingValues.monthsDiff != 0 ||
                  validatingValues.yearsDiff != 0
                ) {
                  errorObj = {
                    ...errorObj,
                    [subAttribute.AC]: "Only date from current month allowed",
                  };
                }
              } else if (
                moment(
                  validation.MinV === "CurrentDate" &&
                    attributeSetData[subAttribute.AC]
                ).isBefore()
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Only after current date allowed",
                };
              } else if (
                validation.MinV !== undefined &&
                validation.MinV !== "CurrentDate" &&
                validation.MinV !== ""
              ) {
                let parentValue = attributeSetData[validation.MinV];
                if (
                  parentValue !== null &&
                  parentValue !== "" &&
                  attributeSetData[subAttribute.AC] !== null &&
                  attributeSetData[subAttribute.AC] !== "" &&
                  moment(attributeSetData[subAttribute.AC]).isBefore(
                    parentValue
                  )
                ) {
                  errorObj = {
                    ...errorObj,
                    [subAttribute.AC]: "Past date not allowed",
                  };
                } else if (
                  (parentValue === null || parentValue === "") &&
                  attributeSetData[subAttribute.AC] !== null &&
                  attributeSetData[subAttribute.AC] !== ""
                ) {
                  errorObj = {
                    ...errorObj,
                    [validation.MinV]: "Required",
                    [subAttribute.AC]: "",
                  };
                } else {
                  errorObj = {
                    ...errorObj,
                    [subAttribute.AC]: "",
                  };
                }
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }
            } else if (subAttribute.DT === "Number") {
              //change with regex
              if (attributeSetData[subAttribute.AC].trim() === "") {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Required",
                };
              } else if (
                // isNaN(
                //   attributeSetData[subAttribute.AC]
                // )
                !/^([0-9]+|0)$/.test(attributeSetData[subAttribute.AC])
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Only numbers are allowed",
                };
              } else if (subAttribute.AC === "PN") {
                switch (attributeSetData["PTP"]?.label?.toLowerCase()) {
                  case "office extension":
                    if (
                      !/^[0-9]{10}$/.test(attributeSetData[subAttribute.AC])
                    ) {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "Only numbers are allowed",
                      };
                    }
                    break;
                  case "mobile":
                    if (
                      !/^[0-9]{10}$/.test(attributeSetData[subAttribute.AC])
                    ) {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]:
                          "Please enter a 10-digit numeric mobile number.",
                      };
                    }
                    break;
                  case "office telephone":
                    if (!/^[0-9]{6,8}$/.test(attributeSetData[subAttribute.AC])) {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]:
                          "Please enter a 6 to 8 digits landline number.",
                      };
                    }
                    break;
                }
              } else if (
                Number(attributeSetData[subAttribute.AC]) > validation.MaxV
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: `Max ${validation.MaxV} allowed`,
                };
              } else if (
                Number(attributeSetData[subAttribute.AC]) < validation.MinV
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: `Min ${validation.MinV} required`,
                };
              }
              //If Numeric values has pattern
              else if (validation.PTN != undefined) {
                if (
                  isValidEmail(
                    attributeSetData[subAttribute.AC],
                    validation.PTN
                  ) != true
                ) {
                  errorObj = {
                    ...errorObj,
                    [subAttribute.AC]: `Invalid Data`,
                  };
                }
              } else if (subAttribute.AC === "PN") {
                switch (attributeSetData["PTP"].label?.toLowerCase()) {
                  case "office extension":
                    if (
                      attributeSetData["PN"].length > 6 ||
                      attributeSetData["PN"].length < 4
                    ) {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "Ext no. should be 4-6 Digits",
                      };
                    } else {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "",
                      };
                    }

                    break;
                  case "office telephone":
                    if (attributeSetData["PN"].length > 7) {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: " Max 7 Digit is Accepted.",
                      };
                    } else {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "",
                      };
                    }

                    break;
                  default:
                    break;
                }
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }
            } else if (subAttribute.DT === "Document") {
              const fileCount = selectedFilesForSet.reduce((total, item) => {
                if (
                  item.AC === subAttribute.AC &&
                  ((currentGridId && currentGridId === item.Id) ||
                    item.Id === 0 ||
                    item.Id !== 0)
                ) {
                  return total + 1;
                }
                return total;
              }, 0);

              if (selectedFilesForSet.length === 0 || fileCount === 0) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Required",
                };
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }
            }
          } else {
            // for num and date
            console.log(subAttribute);
            if (subAttribute.DT === "Number") {
              if (
                attributeSetData[subAttribute.AC] !== "" &&
                attributeSetData[subAttribute.AC] !== null &&
                attributeSetData[subAttribute.AC] !== undefined
              ) {
                //change with regex
                if (!/^([0-9]+|0)$/.test(attributeSetData[subAttribute.AC])) {
                  errorObj = {
                    ...errorObj,
                    [subAttribute.AC]: "Only numbers are allowed",
                  };
                } else if (
                  Number(attributeSetData[subAttribute.AC]) > validation.MaxV
                ) {
                  errorObj = {
                    ...errorObj,
                    [subAttribute.AC]: `Max ${validation.MaxV} allowed`,
                  };
                } else if (
                  Number(attributeSetData[subAttribute.AC]) < validation.MinV
                ) {
                  errorObj = {
                    ...errorObj,
                    [subAttribute.AC]: `Min ${validation.MinV} required`,
                  };
                } else if (subAttribute.AC === "AC") {
                  if (!/^\d{0,3}$/.test(attributeSetData[subAttribute.AC])) {
                    errorObj = {
                      ...errorObj,
                      [subAttribute.AC]: `Invalid Number, Maximum 3 Digits are Accepted.`,
                    };
                  }
                } else {
                  errorObj = {
                    ...errorObj,
                    [subAttribute.AC]: "",
                  };
                }
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }
            } else if (subAttribute.DT === "Date") {
              if (
                attributeSetData[subAttribute.AC] !== null &&
                attributeSetData[subAttribute.AC] !== undefined
              ) {
                if (
                  moment(
                    validation.MaxV === "CurrentDate" &&
                      attributeSetData[subAttribute.AC]
                  ).isAfter(new Date())
                ) {
                  errorObj = {
                    ...errorObj,
                    [subAttribute.AC]: "Only till current date allowed",
                  };
                } else if (
                  moment(
                    validation.MinV === "CurrentDate" &&
                      attributeSetData[subAttribute.AC]
                  ).isBefore()
                ) {
                  errorObj = {
                    ...errorObj,
                    [subAttribute.AC]: "Only after current date allowed",
                  };
                } else if (
                  validation.MinV !== undefined &&
                  validation.MinV !== "CurrentDate" &&
                  validation.MinV !== ""
                ) {
                  let parentValue = attributeSetData[validation.MinV];
                  if (
                    parentValue !== null &&
                    parentValue !== "" &&
                    attributeSetData[subAttribute.AC] !== null &&
                    attributeSetData[subAttribute.AC] !== "" &&
                    moment(attributeSetData[subAttribute.AC]).isBefore(
                      parentValue
                    )
                  ) {
                    errorObj = {
                      ...errorObj,
                      [subAttribute.AC]: "Past date not allowed",
                    };
                  } else if (
                    (parentValue === null || parentValue === "") &&
                    attributeSetData[subAttribute.AC] !== null &&
                    attributeSetData[subAttribute.AC] !== ""
                  ) {
                    errorObj = {
                      ...errorObj,
                      [validation.MinV]: "Required",
                      [subAttribute.AC]: "",
                    };
                  } else {
                    errorObj = {
                      ...errorObj,
                      [subAttribute.AC]: "",
                    };
                  }
                } else {
                  errorObj = {
                    ...errorObj,
                    [subAttribute.AC]: "",
                  };
                }
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }
            }
          }
        }
      });

      const isEmpty = Object.values(errorObj).every((s) => s === "");

      if (Object.keys(errorObj).length > 0 && !isEmpty) {
        isError = true;
        notify(1, `${section.SDN} - Please check validation errors!`);
        setFormErrors((err) => ({
          ...err,
          ...errorObj,
        }));
      }

      resolve(isError);
    });
  };

  const getSearchableDropdownAPI = async (
    searchString,
    AC,
    cb,
    valueForDropdown,
    type,
    gridId
  ) => {
    if (
      searchString === "" &&
      cb === null &&
      valueForDropdown !== "" &&
      AC !== ""
    ) {
      const response = await APICall(getSearchableDropdowns, "POST", {
        searchString,
        AC,
        valueForDropdown,
      });

      if (response.data !== null && response.data.length > 0) {
        if (response.data.length === 1) {
          if (gridId !== 0) {
            let ddObj = {
              gridId,
              AC,
              DDResponse: response.data[0],
            };
            setFillDDValuesForGrid((prev) => [...prev, ddObj]);
          } else {
            return response.data[0];
            //fillSearchableDDValuesOnEdit(response.data[0], AC);
          }
        }
      }
    } else if (searchString.length > 1) {
      const response = await APICall(getSearchableDropdowns, "POST", {
        searchString,
        AC,
      });
      if (response.data !== null && response.data.length > 0) {
        cb(response.data);
        setOptionsObj((prev) => ({ ...prev, [AC]: response.data }));
      } else {
        cb([]);
        setOptionsObj((prev) => ({ ...prev, [AC]: [] }));
      }
    }
  };

  const validateNationalID = async () => {
    return new Promise(async (resolve, reject) => {
      const response = await APICall(GetNationalIDDuplicateValidation, "POST", {
        PERIDNM: attributeSetData["PERIDNM"],
        EID: TId,
      });
      if (
        response.data !== null &&
        response.data != undefined &&
        response.data.length > 0
      ) {
        let CheckIfEmpInActive = response.data.filter((res) => {
          if (res.empActive === false && res.EID != TId) {
            return res;
          }
        });
        if (
          CheckIfEmpInActive &&
          CheckIfEmpInActive.length === response.data.length
        ) {
          if (CheckIfEmpInActive[0].employeeCode === null) {
            notify(
              1,
              `The Same Account Number already exist for Draft-Inactive Employee ${CheckIfEmpInActive[0].fn}. 
            Please Purge the Employee before continuing.`
            );
            validAccount.current = false;
            resolve(true);
          } else {
            //when employee is inactive
            setModalHeader(
              "The Same Account Number already exist for Inactive Employee ID ( " +
                (CheckIfEmpInActive[0].employeeCode === null
                  ? ""
                  : `${CheckIfEmpInActive[0].employeeCode}`) +
                "-" +
                CheckIfEmpInActive[0].fn +
                " ). Do you want to Continue?"
            );
            setShowConfirmationModal(true);
            setConfirmationPromise({ resolve, reject });
          }
        } else {
          // when employee is active
          //check if employee is active but record is inactive
          let checkRecordInActive = response.data.filter((res) => {
            if (res.isActive === false && res.EID != TId) {
              return res;
            }
          });
          if (
            checkRecordInActive &&
            checkRecordInActive.length === response.data.length
          ) {
            //Employee Is active but record is not in use
            validAccount.current = true;
            resolve(true);
          } else {
            if (response.data[0].employeeCode === null) {
              notify(
                1,
                `The Same Account Number already exist for Draft-Active Employee ${response.data[0].fn}. 
              Please Purge or Approve the Employee before continuing.`
              );
              validAccount.current = false;
              resolve(true);
            } else {
              //Employee Is active and  record is in use
              notify(
                1,
                "The Same Account Number already exist with Active" +
                  " Employee ID ( " +
                  (response.data[0].employeeCode === null
                    ? ""
                    : `${response.data[0].employeeCode}`) +
                  "-" +
                  response.data[0].fn +
                  " )."
              );
              validAccount.current = false;
              resolve(true);
            }
          }
        }
      } else {
        // New value is added
        validAccount.current = true;
        resolve(true);
      }
    });
  };

  const handleSaveGrid = async (e, eachAttribute) => {
    showLoader();
    const targetId = e.currentTarget.id;
    const uniqueId = uuid();
    const isError = await validateData(eachAttribute.Attribute);

    if (isError) {
      // setCurrentGridId(null);
      hideLoader();
      setDisableSaveGrid(false);
      return;
    }

    try {
      showLoader();

      setDisableSaveGrid(true);

      if (targetId === "0" && !isError) {
        if (section.SN === "BankInformation") {
          await validateBankAccAdhar();
        }
        if (section.SN === "NationalIDInformation") {
          await validateNationalID();
        }

        if (
          !isError &&
          validAccount.current === true &&
          section.SN === "NationalIDInformation"
        ) {
          //Check Same Type eg. 2 entries of PAN Card
          let duplicateTDType = [];
          duplicateTDType = ogFilledAttributeSet.filter((og) => {
            return (
              og.IDCATY?.label === attributeSetData["IDCATY"].label &&
              og.IsActive === true &&
              og.Id != currentGridId
            );
          });
          if (duplicateTDType.length > 0) {
            notify(
              1,
              `${attributeSetData["IDCATY"].label} ID card Type Already Exist, Can't Insert Another.`
            );
            return;
          }
        }

        if (section.SN === "Email Information") {
          const filteredAttributeSetEmailEdit =
            attributeSetData["ETP"].label === "Business"
              ? ogFilledAttributeSet.filter((og) => {
                  return (
                    og["ETP"]?.label === attributeSetData["ETP"]?.label &&
                    og.IsActive === true
                  );
                })
              : ogFilledAttributeSet.filter((og) => {
                  return (
                    og["ETP"]?.label === attributeSetData["ETP"]?.label &&
                    og["EA"] === attributeSetData["EA"] &&
                    og.IsActive === true
                  );
                });

          if (filteredAttributeSetEmailEdit.length > 0) {
            notify(
              1,
              `${attributeSetData["ETP"]?.label} ${section.SN} Type Already Exist, Can't Insert Another.`
            );
            return;
          }
        }

        if (section.SN === "AddressInformation") {
          const filteredAttributeSetAddressEdit = ogFilledAttributeSet.filter(
            (og) => {
              return (
                og["PA"]?.label === attributeSetData["PA"]?.label &&
                og.IsActive === true
              );
            }
          );

          if (filteredAttributeSetAddressEdit.length > 0) {
            notify(
              1,
              `${attributeSetData["PA"]?.label} ${section.SN} Type Already Exist, Can't Insert Another.`
            );
            return;
          }
        }

        // console.log(section);
        /*Hardcode to if Eligible for stipend then not to able to add FP or VP*/
        if (!isError && section.SN === "Compensation") {
          let SelectedPayComponent = [];
          ogFilledAttributeSet.map((og) => {
            if (og.IsActive === true) {
              SelectedPayComponent.push(og.PCE?.label);
            }
          });
          if (SelectedPayComponent.length > 0) {
            if (SelectedPayComponent.includes("Stipend")) {
              if (attributeSetData["PCE"].label != "Stipend") {
                notify(
                  1,
                  "You are already have entry for stipend so can not have fixed pay or variable pay as pay component."
                );
                return;
              }
            } else {
              if (attributeSetData["PCE"].label === "Stipend") {
                notify(
                  1,
                  "You are already have entry for fixed Pay or variable pay so can not have stipend as pay component."
                );
                return;
              }
            }
            if (
              SelectedPayComponent.length === 0 &&
              ogFilledAttributeSet.length > 0
            ) {
              notify(
                1,
                "You are already have entry for fixed Pay or variable pay so can not have stipend as pay component."
              );
              return;
            }
          }
          ogFilledAttributeSet.map((og) => {
            if (og.IsActive === true) {
              SelectedPayComponent.push(og.PCE?.label);
            }
          });
          if (SelectedPayComponent.length > 0) {
            if (SelectedPayComponent.includes("Stipend")) {
              if (attributeSetData["PCE"].label != "Stipend") {
                notify(
                  1,
                  "You are already have entry for stipend so can not have fixed pay or variable pay as pay component."
                );
                return;
              }
            } else {
              if (attributeSetData["PCE"].label === "Stipend") {
                notify(
                  1,
                  "You are already have entry for fixed Pay or variable pay so can not have stipend as pay component."
                );
                return;
              }
            }
            if (
              SelectedPayComponent.length === 0 &&
              ogFilledAttributeSet.length > 0
            ) {
              notify(
                1,
                "You are already have entry for fixed Pay or variable pay so can not have stipend as pay component."
              );
              return;
            }
          }
        }
        /*Hardcode to if Eligible for stipend then not to able to add FP or VP*/

        /*Harcode For Contact Information check From Here*/
        if (!isError && section.SN === "ContactInformation") {
          let filteredContacts = ogFilledAttributeSet.filter((og) => {
            return (
              og.IP2?.code === attributeSetData.IP2.code &&
              og.IP2?.code === "CODEISPMRY2" &&
              og.IsActive === true
            );
          });
          if (filteredContacts.length > 0) {
            notify(1, "Primary Contact Already Exist, Can't Insert Another.");
            return;
          }
        }
        /*Harcode For Contact Information check Till Here*/

        /*Harcode For Work Association check From Here*/
        if (!isError && section.SN === "JobRelationships"){
          let filteredWorkAssociation =
            ogFilledAttributeSet.filter(
              (og) => {
                return (
                  og.RELSHTYP?.code === attributeSetData.RELSHTYP.code &&
                  og.IsActive === true
                );
              }
            );
          if (filteredWorkAssociation.length > 0) {
            notify(1, `${attributeSetData["RELSHTYP"].label} record already exists in the system.`);
            return;
          }
        }
        /*Harcode For Work Association check Till Here*/

        // Nominee Details 
        if(( section.SN === "NomineeDetails" || section.SN === "NomineeDetails_NewHire") 
          && typeof attributeSetData["TYOFNOM"] ==="object" 
          && Array.isArray(
          attributeSetData["TYOFNOM"]) === true){
          if (
            section.SN === "NomineeDetails" || section.SN === "NomineeDetails_NewHire"
          ) {
            if(!isError){
              for (
                let i = 0;
                i <
                attributeSetData["TYOFNOM"].length; //NOMSHAR
                i++
              ){
                // Other Saving  Validations Here 
                
                let filteredTyp =
                  ogFilledAttributeSet.filter(
                    (og) => {
                      return (
                        og.TYOFNOM?.label === attributeSetData["TYOFNOM"][i]?.label 
                        && attributeSetData["TYOFNOM"][i]?.label == "Full & Final" &&
                        og.IsActive === true
                        //&& og.Id != currentGridId
                      );
                    }
                  );
                if (
                  filteredTyp.length > 0
                ) {
                  notify(
                    1, "Nominee Type Full & Final already exists, Can't Insert Another."
                  );
                  return;
                }
              }
              let finalSetNomm = [];
              let nomArr = [...wholeaAttributeSetData];

              attributeSetData[`TYOFNOM`].map((value, indx)=>{
                const uniqueIdNom = uuid();
                //validation for empty data before adding in grid
                let gridData = {};
                let ogf = {};
                eachAttribute.Attribute.forEach(async (subAttribute) => {
                  if (
                    typeof attributeSetData[subAttribute.AC] === "object" &&
                    Array.isArray(attributeSetData[subAttribute.AC]) === true
                  ) {
                    gridData = {
                      ...gridData,
                      [subAttribute.AC]: attributeSetData[subAttribute.AC][indx]
                        .label
                        ? attributeSetData[subAttribute.AC][indx].label
                        : null, //for MultiSelect Dropdownn
                    };
                    ogf = {
                      ...ogf,
                      [subAttribute.AC]:
                        attributeSetData[subAttribute.AC][indx],
                    };
                    
                    if (
                      value.label !=
                      "Full & Final"
                    ) {
                      gridData = {
                        ...gridData,
                        ["NOM_Witness1Name"]: "",
                        ["NOM_Witness1Address"]: "",
                        ["NOM_Witness2Name"]: "",
                        ["NOM_Witness2Address"]: "", //for date and dropdown
                      };
                      ogf = {
                        ...ogf,
                        ["NOM_Witness1Name"]: "",
                        ["NOM_Witness1Address"]: "",
                        ["NOM_Witness2Name"]: "",
                        ["NOM_Witness2Address"]: "",
                      };
                    }
                  } else if (
                    typeof attributeSetData[subAttribute.AC] === "object"
                  ) {
                    gridData = {
                      ...gridData,
                      [subAttribute.AC]:
                        attributeSetData[subAttribute.AC]?.label || null, //for date and dropdown
                    };
                    ogf = {
                      ...ogf,
                      [subAttribute.AC]:
                        attributeSetData[subAttribute.AC],
                    };
                    if (
                      value.label !=
                      "Full & Final"
                    ) {
                      gridData = {
                        ...gridData,
                        ["NOM_Witness1Name"]: "",
                        ["NOM_Witness1Address"]: "",
                        ["NOM_Witness2Name"]: "",
                        ["NOM_Witness2Address"]: "", //for date and dropdown
                      };
                      ogf = {
                        ...ogf,
                        ["NOM_Witness1Name"]: "",
                        ["NOM_Witness1Address"]: "",
                        ["NOM_Witness2Name"]: "",
                        ["NOM_Witness2Address"]: "",
                      };
                    }
                    
                  } else if (
                    typeof attributeSetData[subAttribute.AC] === "boolean"
                  ) {
                    gridData = {
                      ...gridData,
                      [subAttribute.AC]:
                        attributeSetData[subAttribute.AC] === true
                          ? "true"
                          : "false",
                    };
                    if (
                      value.label !=
                      "Full & Final"
                    ) {
                      gridData = {
                        ...gridData,
                        ["NOM_Witness1Name"]: "",
                        ["NOM_Witness1Address"]: "",
                        ["NOM_Witness2Name"]: "",
                        ["NOM_Witness2Address"]: "", //for date and dropdown
                      };
                      ogf = {
                        ...ogf,
                        ["NOM_Witness1Name"]: "",
                        ["NOM_Witness1Address"]: "",
                        ["NOM_Witness2Name"]: "",
                        ["NOM_Witness2Address"]: "",
                      };
                    }
                    
                  } else {
                    gridData = {
                      ...gridData,
                      [subAttribute.AC]:
                        attributeSetData[subAttribute.AC],
                    };
                    ogf = {
                      ...ogf,
                      [subAttribute.AC]:
                        attributeSetData[subAttribute.AC],
                    };
                    if (
                      value.label !=
                      "Full & Final"
                    ) {
                      gridData = {
                        ...gridData,
                        ["NOM_Witness1Name"]: "",
                        ["NOM_Witness1Address"]: "",
                        ["NOM_Witness2Name"]: "",
                        ["NOM_Witness2Address"]: "", //for date and dropdown
                      };
                      ogf = {
                        ...ogf,
                        ["NOM_Witness1Name"]: "",
                        ["NOM_Witness1Address"]: "",
                        ["NOM_Witness2Name"]: "",
                        ["NOM_Witness2Address"]: "",
                      };
                    }
                  }
                });

                nomArr = [
                  ...nomArr,
                  {
                    ...ogf,
                    Id: uniqueIdNom,
                    IsActive: true,
                    EID: TId,
                  },
                ];
                setOgFilledAttributeSet(
                  (prev) => [
                    ...prev,
                    {
                      ...ogf,
                      Id: uniqueIdNom,
                      IsActive: true,
                    },
                  ]
                );
                setGridData((prev) => [
                  ...prev,
                  getGridDataForAddress(uniqueIdNom, eachAttribute, indx),
                ]);
                setDisplayGrid((prev) => [
                  ...prev,
                  getGridDataForAddress(uniqueIdNom, eachAttribute, indx),
                ]);
                resetFormAndErrors();
                
                if(selectedFilesForSet.length > 0 ){
                  let setfiles = [];
                  let SelectFileswithoutId = selectedFilesForSet.filter((ite) => (ite.Id == 0 || ite.Id == "" ) ) ;
                  setfiles = JSON.parse(JSON.stringify(SelectFileswithoutId));
                  
                  setfiles.forEach(obj => {
                    obj.Id = uniqueIdNom;
                    obj.DCVALUE = value?.label
                  });
                  
                  setfiles.map(pbj=>{
                    finalSetNomm.push(pbj);
                  }) ;
                }
              });

              let remaining = selectedFilesForSet.filter((ite) => (ite.Id != 0 && ite.Id != "" ) ) ;
              setSelectedFilesForSet([...remaining ,...finalSetNomm]);
              updateDocument([...remaining ,...finalSetNomm]);

              setWholseAttributeSetData(nomArr);
              handleChangeForSectionsData(nomArr, eachAttribute.AC, true);
            }
          }
        }
        //For AddressINformartion Hardcode----------------
        else if (section.SN === "AddressInformation") {
          if (
            typeof attributeSetData["PA"] === "object" &&
            Array.isArray(attributeSetData["PA"]) === true
          ) {
            let key = "PA";
            for (let i = 0; i < attributeSetData["PA"].length; i++) {
              //
              let attributed = attributeSetData["PA"][i];
              let filteredEmail = ogFilledAttributeSet.filter((og) => {
                return (
                  og.PA?.label === attributed.label && og.IsActive === true
                );
              });
              //if (!isError) {

              //           }
              //);
              if (filteredEmail.length > 0) {
                notify(
                  1,
                  attributed.label +
                    " Address Type Already Exist, Can't Insert Another."
                );
                return;
              }
            }
            let finalSetAdddr = [];
            let arr = [...wholeaAttributeSetData];
            attributeSetData[`${key}`].map((value, indx) => {
              const uniqueIdAdr = uuid();
              if (!isError) {
                //add record in grid
                // if()

                let gridData = {};
                let ogf = {};
                eachAttribute.Attribute.forEach(async (subAttribute) => {
                  if (
                    typeof attributeSetData[subAttribute.AC] === "object" &&
                    Array.isArray(attributeSetData[subAttribute.AC]) === true
                  ) {
                    gridData = {
                      ...gridData,
                      [subAttribute.AC]: attributeSetData[subAttribute.AC][indx]
                        .label
                        ? attributeSetData[subAttribute.AC][indx].label
                        : null, //for MultiSelect Dropdownn
                    };
                    ogf = {
                      ...ogf,
                      [subAttribute.AC]:
                        attributeSetData[subAttribute.AC][indx],
                    };
                    if (
                      attributeSetData[subAttribute.AC][indx].label ===
                      "Permanent"
                    ) {
                      gridData = {
                        ...gridData,
                        ["ADRTYP"]: null, //for date and dropdown
                      };
                      ogf = {
                        ...ogf,
                        ["ADRTYP"]: null,
                        ["LSD"]: null,
                        ["LED"]: null,
                      };
                    }
                  } else if (
                    typeof attributeSetData[subAttribute.AC] === "object"
                  ) {
                    gridData = {
                      ...gridData,
                      [subAttribute.AC]:
                        attributeSetData[subAttribute.AC]?.label || null, //for date and dropdown
                    };
                    ogf = {
                      ...ogf,
                      [subAttribute.AC]: attributeSetData[subAttribute.AC],
                    };
                    if (value.label === "Permanent") {
                      gridData = {
                        ...gridData,
                        ["ADRTYP"]: null, //for date and dropdown
                      };
                      ogf = {
                        ...ogf,
                        ["ADRTYP"]: null,
                        ["LSD"]: null,
                        ["LED"]: null,
                      };
                    }
                  } else if (
                    typeof attributeSetData[subAttribute.AC] === "boolean"
                  ) {
                    gridData = {
                      ...gridData,
                      [subAttribute.AC]:
                        attributeSetData[subAttribute.AC] === true
                          ? "true"
                          : "false",
                    };
                    ogf = {
                      ...ogf,
                      ["ADRTYP"]: null,
                      ["LSD"]: null,
                      ["LED"]: null,
                    };
                  } else {
                    gridData = {
                      ...gridData,
                      [subAttribute.AC]: attributeSetData[subAttribute.AC],
                    };
                    ogf = {
                      ...ogf,
                      [subAttribute.AC]: attributeSetData[subAttribute.AC],
                    };
                    if (value.label === "Permanent") {
                      gridData = {
                        ...gridData,
                        ["ADRTYP"]: null, //for date and dropdown
                      };
                      ogf = {
                        ...ogf,
                        ["ADRTYP"]: null,
                        ["LSD"]: null,
                        ["LED"]: null,
                      };
                    }
                  }
                });

                arr = [
                  ...arr,
                  {
                    ...ogf,
                    Id: uniqueIdAdr,
                    IsActive: true,
                    EID: TId,
                  },
                ];

                setOgFilledAttributeSet((prev) => [
                  ...prev,
                  {
                    ...ogf,
                    Id: uniqueIdAdr,
                    IsActive: true,
                  },
                ]);

                setGridData((prev) => [
                  ...prev,
                  getGridDataForAddress(uniqueIdAdr, eachAttribute, indx),
                ]);
                setDisplayGrid((prev) => [
                  ...prev,
                  getGridDataForAddress(uniqueIdAdr, eachAttribute, indx),
                ]);
                resetFormAndErrors();
                // setNewGridIdCreated(uniqueIdAdr);

                if(selectedFilesForSet.length > 0 ){
                  let setfiles = [];
                  let SelectFileswithoutId = selectedFilesForSet.filter((ite) => (ite.Id == 0 || ite.Id == "" ) ) ;
                  setfiles = JSON.parse(JSON.stringify(SelectFileswithoutId));
                  
                  setfiles.forEach(obj => {
                    obj.Id = uniqueIdAdr;
                    obj.DCVALUE = value?.label
                  });
                  
                  setfiles.map(pbj=>{
                    finalSetAdddr.push(pbj);
                  }) ;
                }
              }
            });

            let remaining = selectedFilesForSet.filter((ite) => (ite.Id != 0 && ite.Id != "" ) ) ;
            setSelectedFilesForSet([...remaining ,...finalSetAdddr]);
            updateDocument([...remaining ,...finalSetAdddr]);

            setWholseAttributeSetData(arr);
            handleChangeForSectionsData(arr, eachAttribute.AC, true);
          }
        }
        //-------------------------------------------------
        else if (
          validAccount.current === false &&
          (section.SN === "NationalIDInformation" ||
            section.SN === "BankInformation")
        ) {
        } else {
          let arr = [
            ...wholeaAttributeSetData,
            {
              ...attributeSetData,
              Id: uniqueId,
              IsActive: true,
              EID: TId,
            },
          ];
          setWholseAttributeSetData(arr);
          handleChangeForSectionsData(arr, eachAttribute.AC, true);

          setOgFilledAttributeSet((prev) => [
            ...prev,
            {
              ...attributeSetData,
              Id: uniqueId,
              IsActive: true,
            },
          ]);

          setGridData((prev) => [
            ...prev,
            getGridData(uniqueId, eachAttribute),
          ]);
          setDisplayGrid((prev) => [
            ...prev,
            getGridData(uniqueId, eachAttribute),
          ]);
          resetFormAndErrors();
          setNewGridIdCreated(uniqueId);
        }
      } else {
        if (targetId !== "0") {
          // Update existing record
          if (section.SN === "BankInformation") {
            if (!isError) {
              await validateBankAccAdhar();
            }
          }
          if (
            validAccount.current === true &&
            section.SN === "BankInformation" &&
            !isError
          ) {
            let arr = wholeaAttributeSetData.map((record) => {
              if (record.Id === currentGridId) {
                return {
                  ...attributeSetData,
                };
              }
              return record;
            });

            handleChangeForSectionsData(arr, eachAttribute.AC, true);

            setOgFilledAttributeSet((prev) => {
              const newState = prev.map((record) => {
                if (record.Id === currentGridId) {
                  return {
                    ...attributeSetData,
                  };
                }
                return record;
              });
              return newState;
            });

            setGridDataAndUpdate(currentGridId, eachAttribute);
            resetFormAndErrors();
            setCurrentGridId(null);
          } else if (section.SN !== "BankInformation") {
            // Update existing record
            if (section.SN === "Email Information") {
              const filteredAttributeSetEmailUpd =
                attributeSetData["ETP"].label === "Business"
                  ? ogFilledAttributeSet.filter((og) => {
                      return (
                        og["ETP"]?.label === attributeSetData["ETP"]?.label &&
                        og.IsActive === true &&
                        og.Id != attributeSetData.Id
                      );
                    })
                  : ogFilledAttributeSet.filter((og) => {
                      return (
                        og["ETP"]?.label === attributeSetData["ETP"]?.label &&
                        og["EA"] === attributeSetData["EA"] &&
                        og.IsActive === true &&
                        og.Id != attributeSetData.Id
                      );
                    });

              if (filteredAttributeSetEmailUpd.length > 0) {
                notify(
                  1,
                  `${attributeSetData["ETP"]?.label} ${section.SN} Type Already Exist, Can't Insert Another.`
                );
                return;
              }
            }

            /*Harcode For Contact Information check From Here*/
            if (!isError && section.SN === "ContactInformation") {
              let filteredContacts = ogFilledAttributeSet.filter((og) => {
                return (
                  og.IP2?.code === attributeSetData.IP2.code &&
                  og.IP2?.code === "CODEISPMRY2" &&
                  og.IsActive === true &&
                  og.Id != attributeSetData.Id
                );
              });
              if (filteredContacts.length > 0) {
                notify(
                  1,
                  "Primary Contact Already Exist, Can't Insert Another."
                );
                return;
              }
            }
            /*Harcode For Contact Information check Till Here*/

            /*Harcode For Work Association check From Here*/
            if (!isError && section.SN === "JobRelationships"){
              let filteredWorkAssociation =
                ogFilledAttributeSet.filter(
                  (og) => {
                    return (
                      og.RELSHTYP?.code === attributeSetData.RELSHTYP.code &&
                      og.IsActive === true
                      && og.Id != attributeSetData.Id
                    );
                  }
                );
              if (filteredWorkAssociation.length > 0) {
                notify(1, `${attributeSetData["RELSHTYP"].label} record already exists in the system.`);
                return;
              }
            }
            /*Harcode For Work Association check Till Here*/

            if (section.SN === "AddressInformation") {
              const filteredAttributeSetAddressUpd =
                ogFilledAttributeSet.filter((og) => {
                  return (
                    og["PA"]?.label === attributeSetData["PA"]?.label &&
                    og.IsActive === true
                  );
                });

              if (filteredAttributeSetAddressUpd.length > 0) {
                notify(
                  1,
                  `${attributeSetData["PA"]?.label} ${section.SN} Type Already Exist, Can't Insert Another.`
                );
                return;
              }
            }

            if (section.SN === "NationalIDInformation") {
              await validateNationalID();
            }

            if (
              !isError &&
              validAccount.current === true &&
              section.SN === "NationalIDInformation"
            ) {
              //Check Same Type eg. 2 entries of PAN Card
              let duplicateTDType = [];
              duplicateTDType = ogFilledAttributeSet.filter((og) => {
                return (
                  og.IDCATY?.label === attributeSetData["IDCATY"].label &&
                  og.IsActive === true &&
                  og.Id != currentGridId
                );
              });
              if (duplicateTDType.length > 0) {
                notify(
                  1,
                  `${attributeSetData["IDCATY"].label} ID card Type Already Exist, Can't Insert Another.`
                );
                return;
              }
            }

            let arr = wholeaAttributeSetData.map((record) => {
              if (record.Id === currentGridId) {
                return {
                  ...attributeSetData,
                  EID: TId,
                };
              }
              return record;
            });

            setWholseAttributeSetData((prev) => {
              const newState = prev.map((record) => {
                if (record.Id === currentGridId) {
                  return {
                    ...attributeSetData,
                  };
                }
                return record;
              });
              return newState;
            });

            handleChangeForSectionsData(arr, eachAttribute.AC, true);

            setOgFilledAttributeSet((prev) => {
              const newState = prev.map((record) => {
                if (record.Id === currentGridId) {
                  return {
                    ...attributeSetData,
                  };
                }
                return record;
              });
              return newState;
            });
            setGridDataAndUpdate(currentGridId, eachAttribute);
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      if (!isError) {
        resetFormAndErrors();
      }
      setCurrentGridId(null);
      hideLoader();
      setDisableSaveGrid(false);
    }
  };

  useEffect(() => {
    //  ;
    if (
      fillDDValuesForGrid.length > 0 &&
      fillDDValuesForGrid.length === gridData.length
    ) {
      fillDDValuesForGrid.forEach((ddValues) => {
        setGridData((current) =>
          current.map((obj) => {
            if (obj.Id == ddValues.gridId) {
              return { ...obj, [ddValues.AC]: ddValues.DDResponse.label };
            }

            return obj;
          })
        );

        setDisplayGrid((current) =>
          current.map((obj) => {
            if (obj.Id == ddValues.gridId) {
              return { ...obj, [ddValues.AC]: ddValues.DDResponse.label };
            }

            return obj;
          })
        );

        setOgFilledAttributeSet((current) =>
          current.map((obj) => {
            if (obj.Id == ddValues.gridId) {
              return {
                ...obj,
                [ddValues.AC]: {
                  label: ddValues.DDResponse.label,
                  value: ddValues.DDResponse.value,
                },
              };
            }

            return obj;
          })
        );
      });
      setFillDDValuesForGrid([]);
    }
    if (gridData.length > 0) {
      let isbank = false;
      let amount = 0;
      gridData.map((gd) => {
        if (gd.hasOwnProperty("PCE") && gd.IsActive != false) {
          isbank = true;
          if (gd.PCE === "Fixed Pay" || gd.PCE === "Variable Pay") {
            if (gd.AMT) {
              amount += parseInt(gd.AMT);
            }
          } else if (gd.PCE === "Stipend") {
            amount = gd.AMT;
          }
        } else {
          isbank = false;
        }
        if (gd.hasOwnProperty("PVSEMP") && gd.IsActive != false) {
          let filOpt = optionsObj["PVSEMP"]?.filter((op) => {
            return op.label === gd.PVSEMP;
          });
          setOgAttributeSet((prev) => ({
            ...prev,
            ["PVSEMP"]: filOpt[0],
          }));
          setAttributeSetData((prev) => ({
            ...prev,
            PVSEMP: filOpt[0],
          }));
        }
      });
      if (isbank) {
        setAttributesData((prev) => ({
          ...prev,
          ASA: amount.toString(),
        }));
      }
    }
  }, [fillDDValuesForGrid, gridData]);

  //Searchable Dropdown for Attributes
  const searchableDDValues = async (event, AC) => {
    let DD = event.DD;
    if (DD.length > 0) {
      let newAttributeData = { ...attributesData };
      let dropdownOptions = { ...optionsObj };

      for (const attribute of DD) {
        const Code = attribute.Key;

        if (
          attribute.DT === "DropdownSingle" ||
          attribute.DT === "DropdownMultiple"
        ) {
          const options = dropdownOptions[Code];

          if (options !== undefined && options.length > 0) {
            const option = options.find(
              (attri) => attri.value == attribute.Value
            );

            newAttributeData = {
              ...newAttributeData,
              [Code]: option === undefined ? null : option,
            };
          }
        } else if (attribute.DT === "SearchableDropdownSingle") {
          const DDResponse = await getSearchableDropdownAPI(
            "",
            Code,
            null,
            attribute.Value,
            "attribute",
            0
          );

          newAttributeData = {
            ...newAttributeData,
            [Code]:
              DDResponse === undefined
                ? null
                : {
                    label: DDResponse.label,
                    value: DDResponse.value,
                  },
          };
        } else {
          newAttributeData = {
            ...newAttributeData,
            [Code]: attribute.Value,
          };
        }
      }
      newAttributeData[AC] = event; //imp

      setAttributesData(newAttributeData);
    }
  };

  const validateCurrentMonth = (EnteredDate) => {
    let monthsDiff;
    let yearsDiff;

    const endDate = moment();
    const startDate = moment(EnteredDate);

    monthsDiff = endDate.diff(startDate, "months");

    yearsDiff = endDate.diff(startDate, "years");

    return { monthsDiff, yearsDiff };
  };

  /*get company code from legal entity */

  const getCompanyCode = async (value) => {
    //let code;
    if (value != undefined && value != null) {
      let response = await APICall(GetCompanyCode, "POST", {
        SBU: attributesData["SBU"].value,
        LEN: value.value,
      });
      if (response.data !== null && response.data != undefined) {
        //code = value.code;
        await setAttributesData((prev) => ({
          ...prev,
          LEN: value,
          ["CCD"]: response.data.ccd,
        }));
        //return null;
      }
      // else {
      //   return null;
      // }
    }
  };

  const GetNPEndDate = (NPSD, NPIM) => {
    if (NPSD != undefined && NPSD != null) {
      if (NPIM != undefined && NPIM != null) {
        const startDateNP = moment(NPSD);
        let NPDays = Number(NPIM) * 30.44;
        let EndDateNP = startDateNP.add(NPDays, "d");
        return EndDateNP;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const GetShortDays = (NPEDE, LWD) => {
    if (
      LWD != undefined &&
      LWD != null &&
      NPEDE != undefined &&
      NPEDE != null
    ) {
      let daysDiff;
      if (moment(LWD) < moment(NPEDE)) {
        daysDiff = moment(NPEDE).diff(moment(LWD), "days");
      } else {
        daysDiff = 0;
      }
      return daysDiff;
    } else {
      return "";
    }
  };

  const dateyearmonthdiff = (date) => {
    if (date != undefined && date != null) {
      const endDate = moment();
      const startDate = moment(date);
      const yearsDiff = endDate.diff(startDate, "years");
      startDate.add(yearsDiff, "years"); // Adjust the start date by the years difference
      const monthsDiff = endDate.diff(startDate, "months");
      startDate.add(monthsDiff, "months"); // Adjust the start date by the months difference
      const daysDiff = endDate.diff(startDate, "days");
      return `${yearsDiff} years, ${monthsDiff} months, ${daysDiff} days`;
    } else {
      return "";
    }
  };
  //Searchable Dropdown for Attributes Set
  const searchableDDValuesSet = (event, AC) => {
    let DD = event.DD;
    if (DD.length > 0) {
      let newAttributeSetData = { ...attributeSetData };
      let dropdownOptions = { ...optionsObj };

      DD.forEach((attribute) => {
        const Code = attribute.Key;

        // check DT

        if (attribute.DT === "DropdownSingle") {
          const options = dropdownOptions[Code];

          if (options !== undefined && options.length > 0) {
            const option = options?.find(
              (attri) => attri.value == attribute.Value
            );

            newAttributeSetData = {
              ...newAttributeSetData,
              [Code]: option === undefined ? null : option,
            };
          }
        } else {
          //check all DT
          newAttributeSetData = {
            ...newAttributeSetData,
            [Code]: attribute.Value,
          };
        }
      });
      newAttributeSetData[AC] = event; //imp

      setAttributeSetData(newAttributeSetData);
    }
  };

  const fillSearchableDDValuesOnEdit = (DDResponse, AC) => {
    setAttributesData((prev) => ({
      ...prev,
      [AC]: { label: DDResponse.label, value: DDResponse.value },
    }));
  };

  useEffect(() => {
    // ;
    if (Object.keys(addressType).length === 2) {
      if (addressType.address === "present" && addressType.type === "lease") {
        let attributeSetValObj = { ...attributeSetValidation };

        attributeSetValObj.LSD.ISM = "true";
        attributeSetValObj.LED.ISM = "true";

        setAttributeSetValidation((prev) => ({
          ...prev,
          ...attributeSetValObj,
        }));
      } else {
        let attributeSetValObj = { ...attributeSetValidation };

        attributeSetValObj.LSD.ISM = "false";
        attributeSetValObj.LED.ISM = "false";

        setAttributeSetValidation((prev) => ({
          ...prev,
          ...attributeSetValObj,
        }));
      }
    }
  }, [addressType]);

  const validateBankAccAdhar = async () => {
    return new Promise(async (resolve, reject) => {
      const response = await APICall(getAccountValidation, "POST", {
        BNKACCNM: attributeSetData["BNKACCNM"],
      });

      if (
        response.data !== null &&
        response.data != undefined &&
        response.data.length > 0
      ) {
        let filterAccount = response.data.filter((res) => {
          if (
            res.empActive === false &&
            res.peridnm === (globalEC[0] != undefined && globalEC[0].aadhar)
          ) {
            return res;
          }
        });
        if (filterAccount && filterAccount.length > 0) {
          if (filterAccount[0].employeeCode === null) {
            notify(
              1,
              `The Same Account Number already exist for Draft-Inactive Employee ${filterAccount[0].fn}. 
            Please Purge the Employee before continuing.`
            );
            validAccount.current = false;
            resolve(true);
          } else {
            setModalHeader(
              "The Same Account Number is already existing for Inactive Employee ID ( " +
                filterAccount[0].employeeCode +
                "-" +
                filterAccount[0].fn +
                " ). Do you want to Continue?"
            );
            setShowConfirmationModal(true);
            setConfirmationPromise({ resolve, reject });
          }
        } else {
          let filternumber = response.data.filter((res) => {
            if (
              res.bnkaccnm === attributeSetData["BNKACCNM"] &&
              res.eid != TId
            ) {
              return res;
            }
          });
          if (filternumber && filternumber.length > 0) {
            if (filternumber.data[0].employeeCode === null) {
              notify(
                1,
                `The Same Account Number already exist for Draft-Active Employee ${response.data[0].fn}. 
              Please Purge or Approve the Employee before continuing.`
              );
              validAccount.current = false;
              resolve(true);
            } else {
              notify(
                1,
                "The Same Account Number already exist with" +
                  (filternumber[0].employeeStatus === "Active"
                    ? " Active "
                    : " Inactive ") +
                  " Employee ID ( " +
                  filternumber[0].employeeCode +
                  "-" +
                  filternumber[0].fn +
                  " )."
              );
              validAccount.current = false;
              resolve(true);
            }
          } else {
            validAccount.current = true;
            resolve(true);
          }
        }
      } else {
        validAccount.current = true;
        resolve(true);
      }
    });
  };

  const closeConfirmationModal = async (confirmation) => {
    if (confirmationPromise) {
      if (confirmation) {
        validAccount.current = true;
      } else {
        validAccount.current = false;
      }

      setShowConfirmationModal(false);
    }
  };

  const getPPonIncm = async (AC, GenId) => {
    const response = await APICall(getParenPBasedonIncm, "POST", {
      AC,
      GenId,
    });
    if (response.data !== null) {
      return response.data;
    }
  };

  useEffect(() => {
    if (confirmationPromise) {
      if (validAccount.current) {
        confirmationPromise.resolve(true);
      } else {
        confirmationPromise.reject(false); // Reject the promise
      }
    }
    setConfirmationPromise(null);
  }, [validAccount.current]);

  const getDocumentForAC = async (AC) => {};

  const sectionSaveFunction = async () => {
    if (TId === 0 && section.SN !== "Basic") {
      notify(1, "Save Basic Data First");
    } else {
      let errorObj = {};
      section?.Attribute?.map((eachAttribute) => {
        if (eachAttribute.AT === "Attribute") {
          const validation = attributeValidation[eachAttribute.AC];

          if (
            validation.ISM?.toLowerCase() === "true" &&
            eachAttribute.AC != "FTEA"
          ) {
            if (eachAttribute.DT === "Text") {
              if (attributesData[eachAttribute.AC].trim() === "") {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: "Required",
                };
              } else if (validation.PTN != undefined) {
                if (
                  isValidEmail(
                    attributesData[eachAttribute.AC],
                    validation.PTN
                  ) != true
                )
                  errorObj = {
                    ...errorObj,
                    [eachAttribute.AC]: `Data is not in correct format.`,
                  };
              } else if (
                attributesData[eachAttribute.AC]?.length > validation.MaxC
              ) {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: `Max ${validation.MaxC} characters allowed`,
                };
              } else {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: "",
                };
              }
            } else if (
              eachAttribute.DT === "DropdownSingle" ||
              eachAttribute.DT === "DropdownMultiple" ||
              eachAttribute.DT === "SearchableDropdownSingle"
            ) {
              if (attributesData[eachAttribute.AC] === null) {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: "Required",
                };
              } else {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: "",
                };
              }
            } else if (eachAttribute.DT === "Date") {
              let FutureMonth = moment().add(1, "M");
              if (attributesData[eachAttribute.AC] === null) {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: "Required",
                };
              } else if (
                validation.MaxV === "CurrentDate" &&
                moment(attributesData[eachAttribute.AC]).isAfter(moment())
              ) {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: "Only till current date allowed",
                };
              } else if (
                validation.MaxV === "CurrentDateNextMonth" &&
                moment(attributesData[eachAttribute.AC]).isAfter(FutureMonth)
              ) {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]:
                    "Only till current date of next month allowed",
                };
              } else if (
                moment(
                  validation.MinV === "CurrentDate" &&
                    attributesData[eachAttribute.AC]
                ).isBefore()
              ) {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: "Only after current date allowed",
                };
              } else if (
                validation.MinV !== undefined &&
                validation.MinV !== "CurrentDate" &&
                validation.MinV !== ""
              ) {
                let parentValue = attributesData[validation.MinV];
                if (
                  parentValue !== null &&
                  parentValue !== "" &&
                  attributesData[eachAttribute.AC] !== null &&
                  attributesData[eachAttribute.AC] !== "" &&
                  moment(attributesData[eachAttribute.AC]).isBefore(parentValue)
                ) {
                  errorObj = {
                    ...errorObj,
                    [eachAttribute.AC]: "Past date not allowed",
                  };
                } else if (
                  parentValue !== null &&
                  parentValue !== "" &&
                  attributesData[eachAttribute.AC] !== null &&
                  attributesData[eachAttribute.AC] !== ""
                ) {
                  if (validation.MinV === "DOB") {
                    const diffInYear = moment(
                      attributesData[eachAttribute.AC]
                    ).diff(moment(parentValue), "years");
                    if (diffInYear < 16) {
                      errorObj = {
                        ...errorObj,
                        [eachAttribute.AC]: "Past date not allowed",
                      };
                    }
                  }
                } else if (
                  (parentValue === null || parentValue === "") &&
                  attributesData[eachAttribute.AC] !== null &&
                  attributesData[eachAttribute.AC] !== ""
                ) {
                  errorObj = {
                    ...errorObj,
                    [validation.MinV]: "Required",
                    [eachAttribute.AC]: "",
                  };
                } else {
                  errorObj = {
                    ...errorObj,
                    [eachAttribute.AC]: "",
                  };
                }
              } else if (
                validation.MaxV !== undefined &&
                validation.MaxV !== "CurrentDate" &&
                validation.MaxV !== "CurrentDateNextMonth" &&
                validation.MaxV !== ""
              ) {
                let parentValue = attributesData[validation.MinV];
                if (
                  parentValue !== null &&
                  parentValue !== "" &&
                  attributesData[eachAttribute.AC] !== null &&
                  attributesData[eachAttribute.AC] !== "" &&
                  moment(attributesData[eachAttribute.AC]).isAfter(parentValue)
                ) {
                  errorObj = {
                    ...errorObj,
                    [eachAttribute.AC]: "Future Date not Allowed.",
                  };
                } else if (
                  (parentValue === null || parentValue === "") &&
                  attributesData[eachAttribute.AC] !== null &&
                  attributesData[eachAttribute.AC] !== ""
                ) {
                  errorObj = {
                    ...errorObj,
                    [validation.MinV]: "Required",
                    [eachAttribute.AC]: "",
                  };
                } else {
                  errorObj = {
                    ...errorObj,
                    [eachAttribute.AC]: "",
                  };
                }
              } else {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: "",
                };
              }
            } else if (
              eachAttribute.DT === "Number" &&
              eachAttribute.AC != "FTEA"
            ) {
              //change with regex

              // if (
              //   attributesData[eachAttribute.AC].trim() === ""
              // ) {
              //   errorObj = {
              //     ...errorObj,
              //     [eachAttribute.AC]: "Required",
              //   };
              // } else
              if (
                // isNaN(attributesData[eachAttribute.AC])
                !/^([0-9]+|0)$/.test(attributesData[eachAttribute.AC])
              ) {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: "Only numbers are allowed",
                };
              } else if (
                Number(attributesData[eachAttribute.AC]) >
                Number(validation.MaxV)
              ) {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: `Max ${validation.MaxV} allowed`,
                };
              } else if (
                Number(attributesData[eachAttribute.AC]) <
                Number(validation.MinV)
              ) {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: `Min ${validation.MinV} required`,
                };
              } else {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: "",
                };
              }
            } else if (eachAttribute.DT === "Document") {
              const fileCount = selectedFilesForFlat.reduce((total, item) => {
                if (item.AC === eachAttribute.AC) {
                  return total + 1;
                }
                return total;
              }, 0);

              if (selectedFilesForFlat.length === 0 || fileCount === 0) {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: "Required",
                };
              } else {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: "",
                };
              }
            }
          } else {
            // for num and date
            if (eachAttribute.DT === "Text") {
              if (validation.PTN != undefined) {
                if (
                  isValidEmail(
                    attributesData[eachAttribute.AC],
                    validation.PTN
                  ) != true
                ) {
                  errorObj = {
                    ...errorObj,
                    [eachAttribute.AC]: `Data is not in correct format.`,
                  };
                } else {
                  errorObj = {
                    ...errorObj,
                    [eachAttribute.AC]: ``,
                  };
                }
              }
            }
            if (eachAttribute.DT === "Number" && eachAttribute.AC != "FTEA") {
              if (attributesData[eachAttribute.AC] !== "") {
                //change with regex
                if (
                  // isNaN(attributesData[eachAttribute.AC])
                  !/^([0-9]+|0)$/.test(attributesData[eachAttribute.AC])
                ) {
                  errorObj = {
                    ...errorObj,
                    [eachAttribute.AC]: "Only numbers are allowed",
                  };
                } else if (
                  Number(attributesData[eachAttribute.AC]) > validation.MaxV
                ) {
                  errorObj = {
                    ...errorObj,
                    [eachAttribute.AC]: `Max ${validation.MaxV} allowed`,
                  };
                } else if (
                  Number(attributesData[eachAttribute.AC]) < validation.MinV
                ) {
                  errorObj = {
                    ...errorObj,
                    [eachAttribute.AC]: `Min ${validation.MinV} required`,
                  };
                } else {
                  errorObj = {
                    ...errorObj,
                    [eachAttribute.AC]: "",
                  };
                }
              } else {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: "",
                };
              }
            } else if (eachAttribute.DT === "Date") {
              if (attributesData[eachAttribute.AC] !== null) {
                if (
                  moment(
                    validation.MaxV === "CurrentDate" &&
                      attributesData[eachAttribute.AC]
                  ).isAfter(new Date())
                ) {
                  errorObj = {
                    ...errorObj,
                    [eachAttribute.AC]: "Only till current date allowed",
                  };
                } else if (
                  moment(
                    validation.MinV === "CurrentDate" &&
                      attributesData[eachAttribute.AC]
                  ).isBefore()
                ) {
                  errorObj = {
                    ...errorObj,
                    [eachAttribute.AC]: "Only after current date allowed",
                  };
                } else if (
                  validation.MinV !== undefined &&
                  validation.MinV !== "CurrentDate" &&
                  validation.MinV !== ""
                ) {
                  let parentValue = attributesData[validation.MinV];
                  if (
                    parentValue !== null &&
                    parentValue !== "" &&
                    attributesData[eachAttribute.AC] !== null &&
                    attributesData[eachAttribute.AC] !== "" &&
                    moment(attributesData[eachAttribute.AC]).isBefore(
                      parentValue
                    )
                  ) {
                    errorObj = {
                      ...errorObj,
                      [eachAttribute.AC]: "Past date not allowed",
                    };
                  } else if (
                    (parentValue === null || parentValue === "") &&
                    attributesData[eachAttribute.AC] !== null &&
                    attributesData[eachAttribute.AC] !== ""
                  ) {
                    errorObj = {
                      ...errorObj,
                      [validation.MinV]: "Required",
                      [eachAttribute.AC]: "",
                    };
                  } else {
                    errorObj = {
                      ...errorObj,
                      [eachAttribute.AC]: "",
                    };
                  }
                } else {
                  errorObj = {
                    ...errorObj,
                    [eachAttribute.AC]: "",
                  };
                }
              } else {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: "",
                };
              }
            }
          }
        } else {
          //submit attributeSet
        }
      });

      const isEmpty = Object.values(errorObj).every((s) => s === "");

      if (!isEmpty) {
        setFormErrors((err) => ({
          ...err,
          ...errorObj,
        }));

        //clear setGetData
        setGetData([]);
      } else {
        setFormErrors({});
      }

      if (isEmpty && attributeSetCode === null) {
        await showLoader();
        // post data attribute
        const postAttributes = [];

        Object.entries(attributesData).forEach(async (attributeData) => {
          const [Code, Value]: any[] = attributeData;

          if (Code && Value) {
            let attribute = {
              Type: "Attribute",
              Code,
              Value:
                typeof Value === "object" && Value !== null && "value" in Value
                  ? Value.value.toString()
                  : typeof Value === "string" || typeof Value === "boolean"
                  ? Value
                  : null,
            };
            postAttributes.push(attribute);
          }
        });

        let postObj = {
          MN: "EmployeeCentral",
          IN: "EC_Form",
          TN: "Personal",
          SN: section.SN,
          TId: TId || 0,
          UserId: userDetails.Id,
          UserEmailId: userDetails.EmailId,
          UserName: userDetails.FirstName,
          RoleId: userDetails?.UserRoles[0]?.RoleId,
          Attributes: postAttributes,
          COU: COU,
        };

        setGetData((prev) => {
          let prevSectionIndex = prev?.findIndex(
            (s) => s.section === section.SN
          );

          if (prevSectionIndex !== -1) {
            // If an item with the same name exists, update it
            const updatedItems = [...prev];
            updatedItems[prevSectionIndex] = {
              section: section.SN,
              data: postObj,
              sectionId: section.SId,
            }; // Update the existing item
            return updatedItems;
          } else {
            // If no item with the same name, add the new item
            return [
              ...prev,
              {
                section: section.SN,
                data: postObj,
                sectionId: section.SId,
              },
            ];
          }
        });

        await hideLoader();
        setShowAttrSet(false);
        if (section.SN === "Basic") {
          let filteredObj = postAttributes.filter((pa) => {
            if (pa.Code === "MARITLST") {
              return pa;
            }
          });
          if (filteredObj[0].label === "Married") {
            notify(
              2,
              "Please fill out spouse details in Family Member Details Section."
            );
          }
        }
        if (section.SN === "JobOrganisationInformation") {
          notify(2, "Please mantain HR manager in Job relationship portlet.");
        }
      } else if (!isEmpty && attributeSetCode === null) {
        //notify on flat attribute
        notify(1, `${section.SDN} - Please check validation errors!`);
      } else if (
        isEmpty &&
        attributeSetCode !== null &&
        Object.keys(attributesData).length === 0
      ) {
        await showLoader();

        //Contact information Validation for atleast 1 primary key
        if (section.SN === "ContactInformation") {
          if (ogFilledAttributeSet.length > 0) {
            let filteredContacts = ogFilledAttributeSet.filter((og) => {
              return og.IP2?.code === "CODEISPMRY2" && og.IsActive === true;
            });
            if (filteredContacts.length == 0) {
              notify(1, "Please select atleast 1 primary contact.");
              await hideLoader();
              return;
            }
          } else {
            notify(1, "Please select atleast 1 primary contact.");
            await hideLoader();
            return;
          }
        }
        // post data attributeSet
        let details = [...ogFilledAttributeSet];

        const finalDetails = [];
        details.forEach(async (attributes) => {
          let attributeObj = {};
          Object.keys(attributes).forEach(async (attribute) => {
            attributeObj = {
              ...attributeObj,
              [attribute]:
                typeof attributes[attribute] === "object"
                  ? attributes[attribute]?.value.toString() || null
                  : typeof attributes[attribute] === "string" ||
                    typeof attributes[attribute] === "boolean" ||
                    typeof attributes[attribute] === "number"
                  ? attributes[attribute]
                  : null,
            };

            if (attribute === "Id" && isNaN(Number(attributes[attribute]))) {
              attributeObj = {
                ...attributeObj,
                [attribute]: 0,
              };
            } else if (
              attribute === "Id" &&
              !isNaN(Number(attributes[attribute]))
            ) {
              attributeObj = {
                ...attributeObj,
                [attribute]: attributes[attribute],
              };
            }
          });
          finalDetails.push(attributeObj);
        });

        let postObj = {
          MN: "EmployeeCentral",
          IN: "EC_Form",
          TN: "Personal",
          SN: section.SN,
          COU: COU,
          TId: TId || 0,
          UserId: userDetails.Id,
          UserEmailId: userDetails.EmailId,
          UserName: userDetails.FirstName,
          RoleId: userDetails?.UserRoles[0]?.RoleId,
          Attributes: [
            {
              Code: attributeSetCode,
              Value: "",
              Type: "AttributeSet",
              Details: finalDetails,
            },
          ],
        };

        setGetData((prev) => {
          let prevSectionIndex = prev?.findIndex(
            (s) => s.section === section.SN
          );

          if (prevSectionIndex !== -1) {
            // If an item with the same name exists, update it
            const updatedItems = [...prev];
            updatedItems[prevSectionIndex] = {
              section: section.SN,
              data: postObj,
              sectionId: section.SId,
            }; // Update the existing item
            return updatedItems;
          } else {
            // If no item with the same name, add the new item
            return [
              ...prev,
              {
                section: section.SN,
                data: postObj,
                sectionId: section.SId,
              },
            ];
          }
        });

        await hideLoader();
      } else if (
        isEmpty &&
        attributeSetCode !== null &&
        Object.keys(attributesData).length > 0
      ) {
        await showLoader();

        const postAttributes = [];
        const finalPostAttr = [];
        Object.entries(attributesData).forEach(async (attributeData) => {
          const [Code, Value]: any[] = attributeData;

          let attribute = {
            Type: "Attribute",
            Code,
            Value:
              typeof Value === "object"
                ? Value?.value.toString() || null
                : typeof Value === "string" || typeof Value === "boolean"
                ? Value
                : null,
            Details: null,
          };
          postAttributes.push(attribute);
        });
        finalPostAttr.push(postAttributes);
        let details = [...ogFilledAttributeSet];

        console.log("OG Details::::", details);

        const finalDetails = [];
        details.forEach(async (attributes) => {
          debugger;
          let attributeObj = {};
          Object.keys(attributes).forEach(async (attribute) => {
            debugger;
            attributeObj = {
              ...attributeObj,
              [attribute]:
                typeof attributes[attribute] === "object"
                  ? attributes[attribute]?.value.toString() || null
                  : typeof attributes[attribute] === "string" ||
                    typeof attributes[attribute] === "boolean"
                  ? attributes[attribute]
                  : null,
            };

            if (attribute === "Id" && isNaN(Number(attributes[attribute]))) {
              attributeObj = {
                ...attributeObj,
                [attribute]: 0,
              };
            }
          });
          finalDetails.push(attributeObj);
        });

        console.log("Final Set::::", finalDetails);

        postAttributes.push({
          Code: attributeSetCode,
          Value: "",
          Type: "AttributeSet",
          Details: finalDetails,
        });
        let postObjflat = {
          MN: "EmployeeCentral",
          IN: "EC_Form",
          TN: "Personal",
          SN: section.SN,
          TId: TId || 0,
          COU: COU,
          UserId: userDetails.Id,
          UserEmailId: userDetails.EmailId,
          UserName: userDetails.FirstName,
          RoleId: userDetails?.UserRoles[0]?.RoleId,
          Attributes: postAttributes,
        };

        // const postResflat = await APICall(
        //   postAttributeValueDetailsV2,
        //   "POST",
        //   postObjflat
        // );

        // notify(postResflat.status, postResflat.message);

        setGetData((prev) => {
          let prevSectionIndex = prev?.findIndex(
            (s) => s.section === section.SN
          );

          if (prevSectionIndex !== -1) {
            // If an item with the same name exists, update it
            const updatedItems = [...prev];
            updatedItems[prevSectionIndex] = {
              section: section.SN,
              data: postObjflat,
              sectionId: section.SId,
            }; // Update the existing item
            return updatedItems;
          } else {
            // If no item with the same name, add the new item
            return [
              ...prev,
              {
                section: section.SN,
                data: postObjflat,
                sectionId: section.SId,
              },
            ];
          }
        });

        setShowAttrSet(false);
        await hideLoader();
      }
    }
  };

  useEffect(() => {
    if (submitFunc) {
      // call accordion save function
      sectionSaveFunction();
    }
  }, [submitFunc]);

  // handle Single Dropdonw

  const handleSingleDropdownChange = (event, eachAttribute) => {
    setAttributesData((prev) => ({
      ...prev,
      [eachAttribute?.AC]: event,
    }));

    if (eachAttribute?.AC === "LEN" && event) {
      getCompanyCode(event);
    }

    if (eachAttribute?.CC !== null && eachAttribute?.CC !== "") {
      const CC = eachAttribute.CC.split(",");

      CC.forEach(async (childDropdown) => {
        // hard code to get CUSBIS dropdown data
        let PId = "";
        if (
          (eachAttribute?.AC === "BIS" ||
            eachAttribute?.AC === "SBU" ||
            eachAttribute?.AC === "LEN") &&
          childDropdown === "CUSBIS"
        ) {
          let BIS =
            eachAttribute?.AC === "BIS"
              ? event
                ? event.value.toString()
                : null
              : attributesData["BIS"] !== null
              ? attributesData["BIS"].value.toString()
              : null;
          let SBU =
            eachAttribute.AC === "SBU"
              ? event
                ? event.value.toString()
                : null
              : attributesData["SBU"] !== null
              ? attributesData["SBU"].value.toString()
              : null;
          let LEN =
            eachAttribute?.AC === "LEN"
              ? event
                ? event.value.toString()
                : null
              : attributesData["LEN"] !== null
              ? attributesData["LEN"].value.toString()
              : null;
          if (BIS && SBU && LEN) {
            PId = `${BIS},${SBU},${LEN}`;
          }
        }

        if (
          (eachAttribute?.AC === "EC" || eachAttribute?.AC === "LEN") &&
          childDropdown === "JB"
        ) {
          let LEN =
            eachAttribute?.AC === "LEN"
              ? event
                ? event.value.toString()
                : null
              : attributesData["LEN"] !== null
              ? attributesData["LEN"].value.toString()
              : null;
          let BIS =
            eachAttribute?.AC === "EC"
              ? event
                ? event.value.toString()
                : null
              : attributesData["EC"] !== null
              ? attributesData["EC"].value.toString()
              : null;

          if (BIS && LEN) {
            PId = `${LEN},${BIS}`;
          }
        }
        setAttributesData((prev) => ({
          ...prev,
          [childDropdown]: null,
        }));

        const options = await getDropdownsData(
          childDropdown,
          (event && childDropdown === "CUSBIS") ||
            (event && childDropdown === "JB")
            ? PId
            : event
            ? event.value
            : ""
        );

        setOptionsObj((prev) => ({
          ...prev,
          [childDropdown]: options,
        }));
      });
    }

    if (eachAttribute.AC === "Gen") {
      let attributeValObj = {
        ...attributeValidation,
      };
      if (event != null && event.label === "Female" && attributesData.MARITLST.label === "Married") {
        attributeValObj.MAN.ISM = "true";
      } else {
        attributeValObj.MAN.ISM = "false";
      }
      setAttributeSetValidation((prev) => ({
        ...prev,
        ...attributeValObj,
      }));
    }

    if (eachAttribute.AC === "MARITLST") {
      let attributeValObj = {
        ...attributeValidation,
      };
      if (event != null && event.label === "Married" && attributesData.Gen.label == "Female") {
        attributeValObj.ProofBD.ISM = "true";
        attributeValObj.MAN.ISM = "true";
      } else if (event != null && event.label === "Married" && attributesData.Gen.label == "Male") {
        attributeValObj.ProofBD.ISM = "true";
        attributeValObj.MAN.ISM = "false";
      } else {
        attributeValObj.ProofBD.ISM = "false";
        attributeValObj.MAN.ISM = "false";
      }
      setAttributeSetValidation((prev) => ({
        ...prev,
        ...attributeValObj,
      }));

      handleChangeForSectionsData(null, "MARITLSTS", false);
    }
    dependentValidations(event, eachAttribute.AC, "Attribute");
  };

  const handleChangeForSectionsData = (value, code, isSet = false) => {
    let obj = IsSendBack ? { ...sendBackJson } : { ...sectionsData };
    if (isSet) {
      obj[section?.SId][code] = value;
    } else {
      obj[section?.SId][code] = value;
    }
    updateSectionData(obj);
  };

  function getGridData(uniqueId, eachAttribute) {
    const gridData = {};
    eachAttribute.Attribute.forEach((subAttribute) => {
      if (typeof attributeSetData[subAttribute.AC] === "object") {
        gridData[subAttribute.AC] =
          attributeSetData[subAttribute.AC]?.label || null;
      } else if (typeof attributeSetData[subAttribute.AC] === "boolean") {
      } else {
        gridData[subAttribute.AC] = attributeSetData[subAttribute.AC];
      }
    });
    return { Id: uniqueId, ...gridData, IsActive: true };
  }

  function getGridDataForAddress(uniqueId, eachAttribute, index) {
    const gridData = {};
    eachAttribute.Attribute.forEach((subAttribute) => {
      if (
        typeof attributeSetData[subAttribute.AC] === "object" &&
        Array.isArray(attributeSetData[subAttribute.AC]) === true
      ) {
        gridData[subAttribute.AC] =
          attributeSetData[subAttribute.AC][index]?.label || null;
      } else if (typeof attributeSetData[subAttribute.AC] === "object") {
        gridData[subAttribute.AC] =
          attributeSetData[subAttribute.AC]?.label || null;
      } else if (typeof attributeSetData[subAttribute.AC] === "boolean") {
      } else {
        gridData[subAttribute.AC] = attributeSetData[subAttribute.AC];
      }
    });
    return { Id: uniqueId, ...gridData, IsActive: true };
  }

  function setGridDataAndUpdate(id, eachAttribute) {
    const gridData = getGridData(id, eachAttribute);
    setGridData((prev) => {
      const newState = prev.map((record) => {
        if (record.Id === id) {
          return { Id: id, ...gridData };
        }
        return record;
      });
      return newState;
    });
    setDisplayGrid((prev) => {
      const newState = prev.map((record) => {
        if (record.Id === id) {
          return { Id: id, ...gridData };
        }
        return record;
      });
      return newState;
    });
  }

  function resetFormAndErrors() {
    setAttributeSetData((prev) => ({ ...prev, ...ogAttributeSet }));
    setFormErrors({});
  }
  // console.log("Show Attr:::", showAttr);

  return (
    <>
      {section?.Attribute?.length > 0 ? (
        <Accordion
          elevation={0}
          className="mb-3"
          expanded={accordion}
          onChange={() => setAccordion((prev) => !prev)}
        >
          <AccordionSummary
            id="profile-accordion"
            style={{ background: "#3C5464" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}
          >
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <p style={{ fontSize: "16px" }}>{section.SDN}</p>
              </div>
              <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
            </div>
          </AccordionSummary>

          <AccordionDetails className="">
            <div className="row">
              {section?.Attribute?.length > 0 &&
                section?.Attribute?.map((eachAttribute, index) => (
                  <React.Fragment key={`${index}-${eachAttribute.AC}`}>
                    {eachAttribute.AT === "Attribute" ? (
                      <>
                        {eachAttribute.DT === "DropdownSingle" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR?.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }
                              hidden={
                                payrolldd != null &&
                                payrolldd.code == "OHDC" &&
                                eachAttribute.AC == "JT_LR"
                                  ? true
                                  : false
                              }
                            >
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM?.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <SelectForm
                                  isClearable
                                  isSearchable
                                  options={
                                    optionsObj[eachAttribute.AC]
                                      ? optionsObj[eachAttribute.AC]
                                      : []
                                  }
                                  placeholder={eachAttribute.AN}
                                  isDisabled={
                                    // attributeValidation[eachAttribute.AC]
                                    //   ?.isDisable ||
                                    countrydd?.code == "CODECNTRY1" &&
                                    (eachAttribute.AC === "RELI" ||
                                      eachAttribute.AC === "NATVLANG" ||
                                      eachAttribute.AC === "MARITLST" ||
                                      eachAttribute.AC === "DOMICL" ||
                                      eachAttribute.AC === "TYOFEXMN" ||
                                      eachAttribute.AC === "HELRESLT" ||
                                      eachAttribute.AC === "BGWRHF" ||
                                      eachAttribute.AC === "COVNVACC" ||
                                      eachAttribute.AC === "COVNVACC2" ||
                                      eachAttribute.AC === "COVNVACC3")
                                      ? // || (countrydd?.code == "CODECNTRY7" &&
                                        //   (eachAttribute.AC === "DV" ||
                                        //     eachAttribute.AC === "DPT" ||
                                        //     eachAttribute.AC === "CC"))
                                        false
                                      : true
                                  }
                                  value={
                                    attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : null
                                  }
                                  onChange={(event) => {
                                    handleSingleDropdownChange(
                                      event,
                                      eachAttribute
                                    );
                                    handleChangeForSectionsData(
                                      event,
                                      eachAttribute.AC
                                    );
                                  }}
                                  isMulti={false}
                                  noIndicator={false}
                                  noSeparator={false}
                                />
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "DropdownMultiple" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }
                              hidden={
                                payrolldd != null &&
                                payrolldd.code == "OHDC" &&
                                eachAttribute.AC == "JT_LR"
                                  ? true
                                  : false
                              }
                            >
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {JSON.parse(
                                    eachAttribute.V
                                  ).ISM.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <SelectForm
                                  isClearable
                                  isSearchable
                                  options={
                                    optionsObj[eachAttribute.AC]
                                      ? optionsObj[eachAttribute.AC]
                                      : []
                                  }
                                  isMulti={multiDropdown}
                                  placeholder={eachAttribute.AN}
                                  isDisabled={
                                    attributeValidation[eachAttribute.AC]
                                      ?.isDisable || showAttr
                                  }
                                  value={
                                    attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : null
                                  }
                                  onChange={(event) => {
                                    handleSingleDropdownChange(
                                      event,
                                      eachAttribute
                                    );
                                    handleChangeForSectionsData(
                                      event,
                                      eachAttribute.AC
                                    );
                                  }}
                                  noIndicator={false}
                                  noSeparator={false}
                                />
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "Text" ||
                          eachAttribute.DT === "Number" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR?.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }
                            >
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM?.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <InputForm
                                  className="form-control"
                                  placeholder={eachAttribute.AN}
                                  isDisabled={
                                    eachAttribute.AC === "POB" ||
                                    eachAttribute.AC === "HELHIGHT" ||
                                    eachAttribute.AC === "HELWEIGHT" ||
                                    eachAttribute.AC === "FATHRNM"
                                      ? false
                                      : eachAttribute.AC === "MAN"
                                      ? attributesData["Gen"]?.label ===
                                          "Female" &&
                                        attributesData["MARITLST"]?.label ===
                                          "Married"
                                        ? false
                                        : true
                                      : true
                                  }
                                  textArea={false}
                                  value={
                                    eachAttribute.AC === "TIPOS"
                                      ? dateyearmonthdiff(
                                          attributesData["POSED"]
                                        )
                                      : eachAttribute.AC === "NPD" &&
                                        (attributesData["NPD"] === null ||
                                          attributesData["NPD"] === undefined)
                                      ? "0"
                                      : attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : ""
                                  }
                                  onChange={(e) => {
                                    setAttributesData((prev) => ({
                                      ...prev,
                                      [eachAttribute.AC]: e.target.value,
                                    }));

                                    dependentValidations(
                                      e.target.value,
                                      eachAttribute.AC,
                                      "Attribute"
                                    );

                                    handleChangeForSectionsData(
                                      e.target.value || null,
                                      eachAttribute.AC
                                    );

                                    //hard code to fill display name
                                    if (section.SN === "Basic") {
                                      setAttributesData((prev) => ({
                                        ...prev,
                                        ["DN"]:
                                          prev.MN !== null &&
                                          prev.MN !== undefined &&
                                          prev.MN !== ""
                                            ? `${prev.FN.trim()} ${prev.MN.trim()} ${prev.LN.trim()}`.trim()
                                            : `${prev.FN.trim()} ${prev.LN.trim()}`.trim(),
                                      }));
                                    }
                                  }}
                                  maxLength="255"
                                />
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "Date" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR?.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }
                            >
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM?.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <DateForm
                                  isDisabled={
                                    eachAttribute.AC === "MARITLSTS" ||
                                    eachAttribute.AC === "LASTDTEXMN" ||
                                    eachAttribute.AC === "DTOFEXMN" ||
                                    eachAttribute.AC === "VACXDT" ||
                                    eachAttribute.AC === "VACXDT2" ||
                                    eachAttribute.AC === "VACXDT3"
                                      ? false
                                      : true
                                  }
                                  value={
                                    attributesData[eachAttribute.AC] === "NPEDE"
                                      ? GetNPEndDate(
                                          attributesData["NPSD"],
                                          attributesData["NPIM"]
                                        )
                                      : attributesData[eachAttribute.AC] ===
                                        "NPSDE"
                                      ? GetShortDays(
                                          attributesData["NPEDE"],
                                          attributesData["LWD"]
                                        )
                                      : attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : null
                                  }
                                  onChange={(date) => {
                                    setAttributesData((prev) => ({
                                      ...prev,
                                      [eachAttribute.AC]:
                                        date != null
                                          ? moment(date).format("DD-MMM-YYYY")
                                          : null,
                                    }));

                                    dependentValidations(
                                      moment(date).format("DD-MMM-YYYY"),
                                      eachAttribute.AC,
                                      "Attribute"
                                    );

                                    if (moment(date).isValid()) {
                                      date = moment(date).format("DD-MMM-YYYY");
                                    } else {
                                      date = null;
                                    }

                                    handleChangeForSectionsData(
                                      date,
                                      eachAttribute.AC
                                    );

                                    //hard code to calculate age on DOB
                                    if (eachAttribute.AC === "DOB" && date) {
                                      const ageInYears = moment().diff(
                                        date,
                                        "years"
                                      );

                                      setAttributesData((prev) => ({
                                        ...prev,
                                        AGE: ageInYears.toString(),
                                      }));

                                      handleChangeForSectionsData(
                                        ageInYears.toString() || null,
                                        "AGE"
                                      );
                                    }
                                  }}
                                />
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "Checkbox" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR?.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }
                            >
                              <div className="mb-1">
                                <label
                                  htmlFor={eachAttribute.AC}
                                  className="col-form-label"
                                >
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM?.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <div>
                                  <input
                                    disabled={
                                      attributeValidation[eachAttribute.AC]
                                        ?.isDisable || showAttr
                                    }
                                    type="checkbox"
                                    onChange={(e) => {
                                      setAttributesData((prev) => ({
                                        ...prev,
                                        [eachAttribute.AC]: e.target.checked,
                                      }));

                                      dependentValidations(
                                        e.target.checked,
                                        eachAttribute.AC,
                                        "Attribute"
                                      );

                                      handleChangeForSectionsData(
                                        e.target.checked || false,
                                        eachAttribute.AC
                                      );
                                    }}
                                    id={eachAttribute.AC}
                                    checked={
                                      attributesData[eachAttribute.AC]
                                        ? attributesData[eachAttribute.AC]
                                        : false
                                    }
                                  />
                                </div>
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "Textarea" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR?.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }
                            >
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM?.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <InputForm
                                  className="form-control"
                                  placeholder={eachAttribute.AN}
                                  isDisabled={true}
                                  textArea={true}
                                  value={
                                    attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : ""
                                  }
                                  onChange={(e) => {
                                    setAttributesData((prev) => ({
                                      ...prev,
                                      [eachAttribute.AC]: e.target.value,
                                    }));

                                    dependentValidations(
                                      e.target.value,
                                      eachAttribute.AC,
                                      "Attribute"
                                    );

                                    handleChangeForSectionsData(
                                      e.target.value || false,
                                      eachAttribute.AC
                                    );
                                  }}
                                  maxLength="255"
                                />
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "Document" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR?.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }
                            >
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM?.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <div className="box position-relative">
                                  <input
                                    id={`files-${sectionIndex}`}
                                    className="form-control inputfile inputfile-6 multiple-inputfile"
                                    data-multiple-caption="{count} files selected"
                                    multiple={
                                      JSON.parse(eachAttribute.V).FC > 1
                                        ? true
                                        : false
                                    }
                                    disabled={
                                      eachAttribute.AC === "ProofBD" ||
                                      eachAttribute.AC === "HELATTCH"
                                        ? false
                                        : true
                                    }
                                    accept={JSON.parse(eachAttribute.V).FX}
                                    type="file"
                                    onChange={(e) => {
                                      // console.log('Here Flat.');
                                      handleFileChangeForFlatProg(e, eachAttribute);
                                    }}
                                    value={""}
                                  />
                                  <label
                                    htmlFor={`files-${sectionIndex}`}
                                    className="form-control"
                                    style={{ width: 0, border: "none" }}
                                  >
                                    <strong
                                      style={{
                                        padding: "6px 16px",
                                        backgroundColor: "#3c5464",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      <i
                                        className="fa fa-upload rotate90"
                                        aria-hidden="true"
                                      ></i>
                                      {"  "}
                                      Upload
                                    </strong>{" "}
                                  </label>
                                </div>
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                                <div className="file-added-list">
                                  <ul className="list-unstyle">
                                    {documents &&
                                      documents.length > 0 &&
                                      documents.map(
                                        (fileAttribute, attributeIndex) => (
                                          <React.Fragment key={attributeIndex}>
                                            {fileAttribute.AC ===
                                              eachAttribute.AC &&
                                              fileAttribute.IsActive && (
                                                <li
                                                  className="list mt-1"
                                                  key={attributeIndex}
                                                >
                                                  <div className="media">
                                                    <div className="media-body text-truncate">
                                                      <span className="view-more">
                                                        {fileAttribute.Name}
                                                      </span>
                                                    </div>
                                                    {/*Here code for view same div of trash should be used. ISDOCVIEW/ISDOCDOWN*/}
                                                    {/* {
                                                      // section?.ISDOCVIEW &&
                                                      fileAttribute.DocId !==
                                                        0 && (
                                                        <div className="media-right ml-2">
                                                          <i
                                                            className="fa fa-eye"
                                                            aria-hidden="true"
                                                            onClick={() =>
                                                              handleViewForFlat(
                                                                fileAttribute,
                                                                attributeIndex
                                                              )
                                                            }></i>
                                                        </div>
                                                      )
                                                    } */}

                                                    {
                                                      //  section?.ISDOCDOWN &&
                                                      <div className="media-right ml-2">
                                                        <i
                                                          className="fa-solid fa-download"
                                                          aria-hidden="true"
                                                          onClick={() =>
                                                            handleDownloadFileFlat(
                                                              fileAttribute,
                                                              attributeIndex
                                                            )
                                                          }
                                                        ></i>
                                                      </div>
                                                    }

                                                    {/* {(section?.ISEDIT 
                                                    //|| fileAttribute.DocId !== 0
                                                    ) && ( */}
                                                      <div className="media-right ml-2">
                                                        <i
                                                          className="fa fa-trash"
                                                          aria-hidden="true"
                                                          onClick={() =>
                                                            handleDeleteFileForFlat(
                                                              fileAttribute,
                                                              attributeIndex
                                                            )
                                                          }
                                                        ></i>
                                                      </div>
                                                    {/* )} */}
                                                  </div>
                                                </li>
                                              )}
                                          </React.Fragment>
                                        )
                                      )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "SearchableDropdownSingle" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                )?.IFR?.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }
                            >
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM?.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <SelectForm
                                  isClearable
                                  isSearchable
                                  async
                                  options={(searchString, cb) =>
                                    getSearchableDropdownAPI(
                                      searchString,
                                      eachAttribute.AC,
                                      cb,
                                      "",
                                      "attribute",
                                      0
                                    )
                                  }
                                  placeholder={eachAttribute.AN}
                                  isDisabled={
                                    //eachAttribute.AC === "IOPPOS" ? false : true
                                    true
                                  }
                                  value={
                                    attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : null
                                  }
                                  onChange={async (event) => {
                                    if (
                                      eachAttribute.AC === "MG" &&
                                      event.value === TId
                                    ) {
                                      notify(
                                        1,
                                        "Cannot select yourself as your Manager."
                                      );
                                      return;
                                    }

                                    setAttributesData((prev) => ({
                                      ...prev,
                                      [eachAttribute.AC]: event,
                                    }));

                                    handleChangeForSectionsData(
                                      event || null,
                                      eachAttribute.AC
                                    );

                                    if (
                                      eachAttribute.CC !== null &&
                                      eachAttribute.CC !== ""
                                    ) {
                                      const CC = eachAttribute.CC.split(",");

                                      CC.forEach(async (childDropdown) => {
                                        setAttributesData((prev) => ({
                                          ...prev,
                                          [childDropdown]: null,
                                        }));

                                        const options = await getDropdownsData(
                                          childDropdown,
                                          event ? event.value : ""
                                        );

                                        setOptionsObj((prev) => ({
                                          ...prev,
                                          [childDropdown]: options,
                                        }));
                                      });
                                    }

                                    dependentValidations(
                                      event,
                                      eachAttribute.AC,
                                      "Attribute"
                                    );

                                    //searchable DD
                                    if (event) {
                                      searchableDDValues(
                                        event,
                                        eachAttribute.AC
                                      );
                                    }

                                    if (
                                      eachAttribute.AC === "POS" &&
                                      event !== null
                                    ) {
                                      const ddEvent = await getPPonIncm(
                                        eachAttribute.AC,
                                        event.value
                                      );
                                      if (ddEvent !== undefined) {
                                        if (
                                          ddEvent.value !== null &&
                                          ddEvent.label !== null
                                        ) {
                                          const ac = "IOPPOS";
                                          const temp = {
                                            value: ddEvent.value,
                                            label: ddEvent.label,
                                          };
                                          setAttributesData((prev) => ({
                                            ...prev,
                                            POSED:
                                              moment().format("DD-MMM-YYYY"),
                                            TIPOS: "0 years, 0 months, 0 days",
                                            [ac]: temp,
                                          }));
                                        }
                                      } else {
                                        setAttributesData((prev) => ({
                                          ...prev,
                                          POSED: moment().format("DD-MMM-YYYY"),
                                          TIPOS: "0 years, 0 months, 0 days",
                                        }));
                                      }
                                    }
                                    if (
                                      eachAttribute.AC === "POS" &&
                                      event === null
                                    ) {
                                      const ac = "IOPPOS";
                                      setAttributesData((prev) => ({
                                        ...prev,
                                        [ac]: event,
                                      }));
                                    }
                                  }}
                                  isMulti={false}
                                  noIndicator={false}
                                  noSeparator={false}
                                />
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : eachAttribute.AT === "AttributeSet" &&
                      eachAttribute.DT === "Custom" ? (
                      <div className="col-lg-12">
                        {ShowAttrSet && (
                          <div className="row">
                            {eachAttribute.Attribute.length > 0 &&
                              eachAttribute.Attribute.map(
                                (subAttribute, index) => (
                                  <React.Fragment
                                    key={`${index}-${subAttribute.AC}`}
                                  >
                                    {subAttribute.DT === "DropdownSingle" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR?.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {attributeSetValidation[
                                                subAttribute.AC
                                              ]?.ISM?.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <SelectForm
                                              isClearable
                                              isSearchable
                                              options={
                                                optionsObj[subAttribute.AC]
                                                  ? optionsObj[subAttribute.AC]
                                                  : []
                                              }
                                              {...(subAttribute.AC ===
                                                "STSE" && {
                                                filterOption: (option) =>
                                                  currentGridId === null &&
                                                  subAttribute.AC === "STSE"
                                                    ? option.label !== "Demise"
                                                    : option,
                                              })}
                                              placeholder={subAttribute.AN}
                                              isDisabled={
                                                subAttribute?.AC === "ETP" &&
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]?.code === "Business" &&
                                                currentGridId !== null
                                                  ? true
                                                  : showAttr ||
                                                    (subAttribute.AC ===
                                                      "STATDESE" &&
                                                      (currentGridId === "0" ||
                                                        currentGridId ===
                                                          null)) ||
                                                    attributeSetValidation[
                                                      subAttribute.AC
                                                    ]?.isDisable
                                              }
                                              value={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : null
                                              }
                                              onChange={(event) => {
                                                ///Hardcoding for Compensation
                                                let flag = false;
                                                if (subAttribute.AC === "PCE") {
                                                  gridData.map((item) => {
                                                    if (
                                                      event.label ===
                                                      item["PCE"]
                                                    ) {
                                                      flag = true;
                                                    }
                                                  });
                                                }
                                                if (flag) {
                                                  notify(
                                                    1,
                                                    "You can not have Entries for Same Pay Component Type."
                                                  );
                                                  return;
                                                }
                                                //-------------------------------

                                                //OLD
                                                // setAttributeSetData((prev) => ({
                                                //   ...prev,
                                                //   [subAttribute.AC]: event,
                                                // }));
                                                //---

                                                //NEW
                                                if (
                                                  effectiveDateCode !== null &&
                                                  effectiveDateCode !==
                                                    undefined
                                                ) {
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      [subAttribute.AC]: event,
                                                      [effectiveDateCode]:
                                                        effectiveDate,
                                                    })
                                                  );
                                                } else {
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      [subAttribute.AC]: event,
                                                    })
                                                  );
                                                }
                                                //---

                                                if (subAttribute.AC === "ACT") {
                                                  let attributeSetValObj = {
                                                    ...attributeSetValidation,
                                                  };
                                                  if (
                                                    event != null &&
                                                    event.label ===
                                                      "Car/Vehicle"
                                                  ) {
                                                    attributeSetValObj.ASMODLNUM.ISM =
                                                      "true";
                                                  } else {
                                                    attributeSetValObj.ASMODLNUM.ISM =
                                                      "false";
                                                  }
                                                  setAttributeSetValidation(
                                                    (prev) => ({
                                                      ...prev,
                                                      ...attributeSetValObj,
                                                    })
                                                  );
                                                }
                                                if (
                                                  eachAttribute.AC === "PTP"
                                                ) {
                                                  let attributeValObj = {
                                                    ...attributeValidation,
                                                  };
                                                  if (
                                                    event != null &&
                                                    event.label ===
                                                      "Office Telephone"
                                                  ) {
                                                    attributeValObj.AC.ISM =
                                                      "true";
                                                  } else {
                                                    attributeValObj.AC.ISM =
                                                      "false";
                                                  }
                                                  setAttributeSetValidation(
                                                    (prev) => ({
                                                      ...prev,
                                                      ...attributeValObj,
                                                    })
                                                  );
                                                }
                                                if (
                                                  subAttribute.CC !== null &&
                                                  subAttribute.CC !== ""
                                                ) {
                                                  const CC =
                                                    subAttribute.CC.split(",");

                                                  CC.forEach(
                                                    async (childDropdown) => {
                                                      setAttributeSetData(
                                                        (prev) => ({
                                                          ...prev,
                                                          [childDropdown]: null,
                                                        })
                                                      );

                                                      const options =
                                                        await getDropdownsData(
                                                          childDropdown,
                                                          event
                                                            ? event.value
                                                            : ""
                                                        );

                                                      setOptionsObj((prev) => ({
                                                        ...prev,
                                                        [childDropdown]:
                                                          options,
                                                      }));
                                                    }
                                                  );
                                                }

                                                //hard code to enable valild till on change
                                                //maybe add this in DD later for attributeSet
                                                if (
                                                  event &&
                                                  (event.label?.toLowerCase() ===
                                                    "driving license" ||
                                                    event.label?.toLowerCase() ===
                                                      "passport")
                                                ) {
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      NVALTIL: null,
                                                    })
                                                  );
                                                }

                                                //hard code for address information
                                                if (
                                                  event &&
                                                  (subAttribute.AC === "PA" ||
                                                    subAttribute.AC ===
                                                      "ADRTYP")
                                                ) {
                                                  if (
                                                    subAttribute.AC === "PA"
                                                  ) {
                                                    setAddressType((prev) => ({
                                                      ...prev,
                                                      address:
                                                        event.label?.toLowerCase(),
                                                    }));
                                                  } else {
                                                    setAddressType((prev) => ({
                                                      ...prev,
                                                      type: event.label?.toLowerCase(),
                                                    }));
                                                  }
                                                }

                                                if (subAttribute.AC === "PA") {
                                                  if (
                                                    event != null &&
                                                    event != undefined
                                                  ) {
                                                    if (
                                                      event.label?.toLowerCase() ===
                                                        "emergency" ||
                                                      event.label?.toLowerCase() ===
                                                        "permanent"
                                                    ) {
                                                      setOptionsObj((prev) => ({
                                                        ...prev,
                                                        ["ADRTYP"]: [],
                                                      }));

                                                      let attributeSetValObj = {
                                                        ...attributeSetValidation,
                                                      };
                                                      attributeSetValObj.ADRTYP.ISM =
                                                        "false";
                                                      setAttributeSetValidation(
                                                        (prev) => ({
                                                          ...prev,
                                                          ...attributeSetValObj,
                                                        })
                                                      );
                                                      setAttributeSetData(
                                                        (prev) => ({
                                                          ...prev,
                                                          ["ADRTYP"]: null,
                                                        })
                                                      );
                                                    } else {
                                                      const adrTOPT =
                                                        eachAttribute.Attribute?.find(
                                                          (item) =>
                                                            item.AC === "ADRTYP"
                                                        );
                                                      if (adrTOPT) {
                                                        setOptionsObj(
                                                          (prev) => ({
                                                            ...prev,
                                                            ["ADRTYP"]:
                                                              adrTOPT.OPT,
                                                          })
                                                        );
                                                      }
                                                      let attributeSetValObj = {
                                                        ...attributeSetValidation,
                                                      };
                                                      attributeSetValObj.ADRTYP.ISM =
                                                        "true";
                                                      setAttributeSetValidation(
                                                        (prev) => ({
                                                          ...prev,
                                                          ...attributeSetValObj,
                                                        })
                                                      );
                                                    }
                                                  } else {
                                                    const adrTOPT =
                                                      eachAttribute.Attribute?.find(
                                                        (item) =>
                                                          item.AC === "ADRTYP"
                                                      );
                                                    if (adrTOPT) {
                                                      setOptionsObj((prev) => ({
                                                        ...prev,
                                                        ["ADRTYP"]: adrTOPT.OPT,
                                                      }));
                                                    }
                                                    setAttributeSetData(
                                                      (prev) => ({
                                                        ...prev,
                                                        ["ADRTYP"]: null,
                                                      })
                                                    );
                                                  }
                                                }
                                                dependentValidations(
                                                  event,
                                                  subAttribute.AC,
                                                  "Attributeset"
                                                );
                                              }}
                                              isMulti={false}
                                              noIndicator={false}
                                              noSeparator={false}
                                            />
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT ===
                                      "DropdownMultiple" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {attributeSetValidation[
                                                subAttribute.AC
                                              ]?.ISM?.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <SelectForm
                                              isClearable
                                              isSearchable
                                              options={
                                                optionsObj[subAttribute.AC]
                                                  ? optionsObj[subAttribute.AC]
                                                  : []
                                              }
                                              isMulti={multiDropdown}
                                              placeholder={subAttribute.AN}
                                              isDisabled={
                                                showAttr ||
                                                (subAttribute.AC ===
                                                  "STATDESE" &&
                                                  (currentGridId === "0" ||
                                                    currentGridId === null)) ||
                                                attributeSetValidation[
                                                  subAttribute.AC
                                                ]?.isDisable
                                              }
                                              value={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : null
                                              }
                                              onChange={(event) => {
                                                //OLD
                                                // setAttributeSetData((prev) => ({
                                                //   ...prev,
                                                //   [subAttribute.AC]: event,
                                                // }));
                                                //---

                                                //NEW
                                                if (
                                                  effectiveDateCode !== null &&
                                                  effectiveDateCode !==
                                                    undefined
                                                ) {
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      [subAttribute.AC]: event,
                                                      [effectiveDateCode]:
                                                        effectiveDate,
                                                    })
                                                  );
                                                } else {
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      [subAttribute.AC]: event,
                                                    })
                                                  );
                                                }
                                                //---

                                                if (
                                                  event !== null &&
                                                  subAttribute.CC !== null &&
                                                  subAttribute.CC !== ""
                                                ) {
                                                  const CC =
                                                    subAttribute.CC.split(",");

                                                  CC.forEach(
                                                    async (childDropdown) => {
                                                      setAttributeSetData(
                                                        (prev) => ({
                                                          ...prev,
                                                          [childDropdown]: null,
                                                        })
                                                      );

                                                      const options =
                                                        await getDropdownsData(
                                                          childDropdown,
                                                          event.value
                                                        );

                                                      setOptionsObj((prev) => ({
                                                        ...prev,
                                                        [childDropdown]:
                                                          options,
                                                      }));
                                                    }
                                                  );
                                                }
                                              }}
                                              noIndicator={false}
                                              noSeparator={false}
                                            />
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT === "Text" ||
                                      subAttribute.DT === "Number" ? (
                                      <>
                                      {
                                        attributeSetValidation[subAttribute.AC
                                          ]?.IsHiddenForEP 
                                        && attributeSetValidation[subAttribute.AC
                                          ]?.IsHiddenForEP.toLowerCase() === "true" 
                                        ? <></> 
                                        : 
                                        <div
                                          hidden={
                                            subAttribute.AC === "AC2"
                                              ? true
                                              : false
                                          }
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR?.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {attributeSetValidation[
                                                subAttribute.AC
                                              ]?.ISM?.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <InputForm
                                              className="form-control"
                                              placeholder={subAttribute.AN}
                                              isDisabled={
                                                subAttribute?.AC === "EA" &&
                                                attributeSetData["ETP"]
                                                  ?.code === "Business" &&
                                                currentGridId !== null
                                                  ? true
                                                  : showAttr ||
                                                    // subAttribute.AC === "RELNNM" ||
                                                    subAttribute.AC ===
                                                      "DURGCRC" ||
                                                    subAttribute.AC ===
                                                      "WLSPEXP" ||
                                                    subAttribute.AC ===
                                                      "PLEN" ||
                                                    subAttribute.AC ===
                                                      "RELNCOMP" 
                                                  ? true
                                                  : subAttribute.AC === "AC"
                                                  ? attributeSetData["PTP"]
                                                      ?.label ===
                                                    "Office Telephone"
                                                    ? false
                                                    : true
                                                  : attributeSetValidation[
                                                      subAttribute.AC
                                                    ]?.isDisable
                                              }
                                              textArea={false}
                                              value={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : ""
                                              }
                                              onChange={(e) => {
                                                //OLD
                                                // setAttributeSetData((prev) => ({
                                                //   ...prev,
                                                //   [subAttribute.AC]:
                                                //     subAttribute.AC === "EA"
                                                //       ? e.target.value?.toLowerCase()
                                                //       : e.target.value,
                                                // }));
                                                //---

                                                //NEW
                                                if (
                                                  effectiveDateCode !== null &&
                                                  effectiveDateCode !==
                                                    undefined
                                                ) {
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      [subAttribute.AC]:
                                                        subAttribute.AC === "EA"
                                                          ? e.target.value?.toLowerCase()
                                                          : e.target.value,
                                                      [effectiveDateCode]:
                                                        effectiveDate,
                                                    })
                                                  );
                                                } else {
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      [subAttribute.AC]:
                                                        subAttribute.AC === "EA"
                                                          ? e.target.value?.toLowerCase()
                                                          : e.target.value,
                                                    })
                                                  );
                                                }
                                                //---
                                              }}
                                              maxLength="255"
                                            />
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>}
                                      </>
                                    ) : subAttribute.DT === "MonthYear" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {attributeSetValidation[
                                                subAttribute.AC
                                              ]?.ISM.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <DateForm
                                              isDisabled={
                                                showAttr ||
                                                attributeSetValidation[
                                                  subAttribute.AC
                                                ]?.isDisable
                                              }
                                              views={["month", "year"]}
                                              format="MMM/yyyy"
                                              value={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : null
                                              }
                                              onChange={(date) => {
                                                //OLD
                                                // setAttributeSetData((prev) => ({
                                                //   ...prev,
                                                //   [subAttribute.AC]:
                                                //     date != null
                                                //       ? moment(date).format(
                                                //           "DD-MMM-YYYY"
                                                //         )
                                                //       : null,
                                                // }));
                                                //---

                                                //NEW
                                                if (
                                                  effectiveDateCode !== null &&
                                                  effectiveDateCode !==
                                                    undefined
                                                ) {
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      [subAttribute.AC]:
                                                        date != null
                                                          ? moment(date).format(
                                                              "DD-MMM-YYYY"
                                                            )
                                                          : null,
                                                      [effectiveDateCode]:
                                                        effectiveDate,
                                                    })
                                                  );
                                                } else {
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      [subAttribute.AC]:
                                                        date != null
                                                          ? moment(date).format(
                                                              "DD-MMM-YYYY"
                                                            )
                                                          : null,
                                                    })
                                                  );
                                                }
                                                //---

                                                //hard code to calculate duration in formal education
                                                if (
                                                  date &&
                                                  (subAttribute.AC ===
                                                    "FRMDTFE" ||
                                                    subAttribute.AC ===
                                                      "ENDDTED")
                                                ) {
                                                  const endDate = moment(
                                                    subAttribute.AC ===
                                                      "ENDDTED"
                                                      ? date
                                                      : attributeSetData[
                                                          "ENDDTED"
                                                        ] !== null
                                                      ? attributeSetData[
                                                          "ENDDTED"
                                                        ]
                                                      : date
                                                  );

                                                  const fromDate = moment(
                                                    subAttribute.AC ===
                                                      "FRMDTFE"
                                                      ? date
                                                      : attributeSetData[
                                                          "FRMDTFE"
                                                        ] !== null
                                                      ? attributeSetData[
                                                          "FRMDTFE"
                                                        ]
                                                      : date
                                                  );

                                                  const duration =
                                                    moment.duration(
                                                      endDate.diff(fromDate)
                                                    );

                                                  const durationInYears =
                                                    duration.years();

                                                  const durationInMonths =
                                                    duration.months();

                                                  const durationInDays =
                                                    duration.days();

                                                  if (
                                                    isNaN(durationInYears) ||
                                                    durationInYears < 0 ||
                                                    isNaN(durationInMonths) ||
                                                    durationInMonths < 0 ||
                                                    isNaN(durationInDays) ||
                                                    durationInDays < 0
                                                  ) {
                                                    setAttributeSetData(
                                                      (prev) => ({
                                                        ...prev,
                                                        ["DURGCRC"]: "",
                                                      })
                                                    );
                                                  } else {
                                                    setAttributeSetData(
                                                      (prev) => ({
                                                        ...prev,
                                                        ["DURGCRC"]: `${durationInYears} years ${durationInMonths} months ${durationInDays} days`,
                                                      })
                                                    );
                                                  }
                                                }
                                              }}
                                            />
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT === "Date" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR?.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {/* {JSON.parse(
                                                subAttribute.V
                                              ).ISM?.toLowerCase() === "true" && (
                                                <sup>*</sup>
                                              )} */}
                                              {attributeSetValidation[
                                                subAttribute.AC
                                              ]?.ISM?.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <DateForm
                                              isDisabled={
                                                subAttribute.AC ===
                                                  effectiveDateCode ||
                                                showAttr ||
                                                subAttribute.AC === "NVALTIL"
                                                  ? attributeSetData?.IDCATY !==
                                                      null &&
                                                    (attributeSetData?.IDCATY?.label?.toLowerCase() ===
                                                      "driving license" ||
                                                      attributeSetData?.IDCATY?.label?.toLowerCase() ===
                                                        "passport")
                                                    ? false
                                                    : true
                                                  : subAttribute.AC === "LSD" ||
                                                    subAttribute.AC === "LED"
                                                  ? attributeSetData?.ADRTYP !=
                                                      null &&
                                                    attributeSetData?.ADRTYP?.label?.toLowerCase() ===
                                                      "lease"
                                                    ? false
                                                    : true
                                                  : attributeSetValidation[
                                                      subAttribute.AC
                                                    ]?.isDisable
                                              }
                                              value={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : null
                                              }
                                              onChange={(date) => {
                                                //OLD
                                                // setAttributeSetData((prev) => ({
                                                //   ...prev,
                                                //   [subAttribute.AC]:
                                                //     date != null
                                                //       ? moment(date).format(
                                                //           "DD-MMM-YYYY"
                                                //         )
                                                //       : null,
                                                // }));
                                                //---

                                                //NEW
                                                if (
                                                  effectiveDateCode !== null &&
                                                  effectiveDateCode !==
                                                    undefined
                                                ) {
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      [subAttribute.AC]:
                                                        date != null
                                                          ? moment(date).format(
                                                              "DD-MMM-YYYY"
                                                            )
                                                          : null,
                                                      [effectiveDateCode]:
                                                        effectiveDate,
                                                    })
                                                  );
                                                } else {
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      [subAttribute.AC]:
                                                        date != null
                                                          ? moment(date).format(
                                                              "DD-MMM-YYYY"
                                                            )
                                                          : null,
                                                    })
                                                  );
                                                }
                                                //---

                                                //hard code to calculate duration in Additional Skills
                                                if (
                                                  date &&
                                                  (subAttribute.AC === "STDT" ||
                                                    subAttribute.AC === "ENDDT")
                                                ) {
                                                  const endDate = moment(
                                                    subAttribute.AC === "ENDDT"
                                                      ? date
                                                      : attributeSetData[
                                                          "ENDDT"
                                                        ] !== null
                                                      ? attributeSetData[
                                                          "ENDDT"
                                                        ]
                                                      : date
                                                  );

                                                  const fromDate = moment(
                                                    subAttribute.AC === "STDT"
                                                      ? date
                                                      : attributeSetData[
                                                          "STDT"
                                                        ] !== null
                                                      ? attributeSetData["STDT"]
                                                      : date
                                                  );

                                                  const duration =
                                                    moment.duration(
                                                      endDate.diff(fromDate)
                                                    );

                                                  const durationInYears =
                                                    duration.years();

                                                  const durationInMonths =
                                                    duration.months();

                                                  const durationInDays =
                                                    duration.days();

                                                  if (
                                                    isNaN(durationInYears) ||
                                                    durationInYears < 0 ||
                                                    isNaN(durationInMonths) ||
                                                    durationInMonths < 0 ||
                                                    isNaN(durationInDays) ||
                                                    durationInDays < 0
                                                  ) {
                                                    setAttributeSetData(
                                                      (prev) => ({
                                                        ...prev,
                                                        ["WLSPEXP"]: "",
                                                      })
                                                    );
                                                  } else {
                                                    setAttributeSetData(
                                                      (prev) => ({
                                                        ...prev,
                                                        ["WLSPEXP"]: `${durationInYears} years ${durationInMonths} months ${durationInDays} days`,
                                                      })
                                                    );
                                                  }
                                                }

                                                /*Hardcode to set learning duration */
                                                if (
                                                  date &&
                                                  (subAttribute.AC === "DTEC" ||
                                                    subAttribute.AC === "DTES")
                                                ) {
                                                  const endDate = moment(
                                                    subAttribute.AC === "DTEC"
                                                      ? date
                                                      : attributeSetData[
                                                          "DTEC"
                                                        ] !== null
                                                      ? attributeSetData["DTEC"]
                                                      : date
                                                  );

                                                  const fromDate = moment(
                                                    subAttribute.AC === "DTES"
                                                      ? date
                                                      : attributeSetData[
                                                          "DTES"
                                                        ] !== null
                                                      ? attributeSetData["DTES"]
                                                      : date
                                                  );

                                                  const duration =
                                                    moment.duration(
                                                      endDate.diff(fromDate)
                                                    );

                                                  const durationInYears =
                                                    duration.years();

                                                  const durationInMonths =
                                                    duration.months();

                                                  const durationInDays =
                                                    duration.days();

                                                  if (
                                                    isNaN(durationInYears) ||
                                                    durationInYears < 0 ||
                                                    isNaN(durationInMonths) ||
                                                    durationInMonths < 0 ||
                                                    isNaN(durationInDays) ||
                                                    durationInDays < 0
                                                  ) {
                                                    setAttributeSetData(
                                                      (prev) => ({
                                                        ...prev,
                                                        ["PLEN"]: "",
                                                      })
                                                    );
                                                  } else {
                                                    setAttributeSetData(
                                                      (prev) => ({
                                                        ...prev,
                                                        ["PLEN"]: `${durationInYears} years ${durationInMonths} months ${durationInDays} days`,
                                                      })
                                                    );
                                                  }
                                                }
                                                /*Hardcode to set learning duration */
                                              }}
                                            />
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT === "Checkbox" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR?.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label
                                              htmlFor={subAttribute.AC}
                                              className="col-form-label"
                                            >
                                              {subAttribute.AN}
                                              {attributeSetValidation[
                                                subAttribute.AC
                                              ]?.ISM?.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <div>
                                              <input
                                                disabled={
                                                  showAttr ||
                                                  attributeSetValidation[
                                                    subAttribute.AC
                                                  ]?.isDisable
                                                }
                                                type="checkbox"
                                                onChange={(e) => {
                                                  //OLD
                                                  // setAttributeSetData(
                                                  //   (prev) => ({
                                                  //     ...prev,
                                                  //     [subAttribute.AC]:
                                                  //       e.target.checked,
                                                  //   })
                                                  // );
                                                  //---

                                                  //NEW
                                                  if (
                                                    effectiveDateCode !==
                                                      null &&
                                                    effectiveDateCode !==
                                                      undefined
                                                  ) {
                                                    setAttributeSetData(
                                                      (prev) => ({
                                                        ...prev,
                                                        [subAttribute.AC]:
                                                          e.target.checked,
                                                        [effectiveDateCode]:
                                                          effectiveDate,
                                                      })
                                                    );
                                                  } else {
                                                    setAttributeSetData(
                                                      (prev) => ({
                                                        ...prev,
                                                        [subAttribute.AC]:
                                                          e.target.checked,
                                                      })
                                                    );
                                                  }
                                                  //---
                                                }}
                                                id={subAttribute.AC}
                                                checked={
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ]
                                                    ? attributeSetData[
                                                        subAttribute.AC
                                                      ]
                                                    : false
                                                }
                                              />
                                            </div>
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT === "Document" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR?.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {attributeSetValidation[
                                                subAttribute.AC
                                              ]?.ISM?.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>
                                            <div className="box position-relative">
                                              <input
                                                id={`files-${sectionIndex}`}
                                                disabled={
                                                  showAttr ||
                                                  attributeSetValidation[
                                                    subAttribute.AC
                                                  ]?.isDisable
                                                }
                                                className="form-control inputfile inputfile-6 multiple-inputfile"
                                                data-multiple-caption="{count} files selected"
                                                multiple={
                                                  JSON.parse(subAttribute.V)
                                                    .FC > 1
                                                    ? true
                                                    : false
                                                }
                                                accept={
                                                  JSON.parse(subAttribute.V).FX
                                                }
                                                type="file"
                                                onChange={(e) => {
                                                  // console.log('Here Set.');
                                                  handleFileChangeForSetProg(
                                                    e,
                                                    subAttribute
                                                  );
                                                }}
                                                value={""}
                                              />
                                              <label
                                                htmlFor={`files-${sectionIndex}`}
                                                className="form-control"
                                                style={{
                                                  width: 0,
                                                  border: "none",
                                                }}
                                              >
                                                <strong
                                                  style={{
                                                    padding: "6px 16px",
                                                    backgroundColor: "#3c5464",
                                                    borderRadius: "5px",
                                                  }}
                                                >
                                                  <i
                                                    className="fa fa-upload rotate90"
                                                    aria-hidden="true"
                                                  ></i>
                                                  {"  "}
                                                  Upload
                                                </strong>{" "}
                                              </label>
                                            </div>
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                            <div className="file-added-list">
                                              <ul className="list-unstyle">
                                                {documents &&
                                                  documents.length > 0 &&
                                                  documents.map(
                                                    (
                                                      fileAttribute,
                                                      attributeIndex
                                                    ) => (
                                                      <React.Fragment
                                                        key={attributeIndex}
                                                      >
                                                        {fileAttribute.AC ===
                                                          subAttribute.AC &&
                                                          fileAttribute.IsActive &&
                                                          (fileAttribute.Id ===
                                                            0 ||
                                                            fileAttribute.Id ==
                                                              currentGridId) && (
                                                            <li
                                                              className="list mt-1"
                                                              key={
                                                                attributeIndex
                                                              }
                                                            >
                                                              <div className="media">
                                                                <div className="media-body text-truncate">
                                                                  <span className="view-more">
                                                                    {
                                                                      fileAttribute.Name
                                                                    }
                                                                  </span>
                                                                </div>
                                                                {/*Here code for view same div of trash should be used. ISDOCVIEW/ISDOCDOWN*/}
                                                                {/* {
                                                                    // section?.ISDOCVIEW &&
                                                                    fileAttribute.DocId !==
                                                                      0 && (
                                                                      <div className="media-right ml-2">
                                                                        <i
                                                                          className="fa fa-eye"
                                                                          aria-hidden="true"
                                                                          onClick={() =>
                                                                            handleViewForFlat(
                                                                              fileAttribute,
                                                                              attributeIndex
                                                                            )
                                                                          }></i>
                                                                      </div>
                                                                    )
                                                                  } */}

                                                                {
                                                                  // section?.ISDOCDOWN &&
                                                                  <div className="media-right ml-2">
                                                                    <i
                                                                      className="fa-solid fa-download"
                                                                      aria-hidden="true"
                                                                      onClick={() =>
                                                                        handleDownloadFileFlat(
                                                                          fileAttribute,
                                                                          attributeIndex
                                                                        )
                                                                      }
                                                                    ></i>
                                                                  </div>
                                                                }
                                                                {/* {(section?.ISEDIT 
                                                                //||fileAttribute.DocId !==0
                                                                ) && ( */}
                                                                  <div className="media-right ml-2">
                                                                    <i
                                                                      className="fa fa-trash"
                                                                      aria-hidden="true"
                                                                      onClick={() =>
                                                                        handleDeleteFileForSet(
                                                                          fileAttribute,
                                                                          attributeIndex
                                                                        )
                                                                      }
                                                                    ></i>
                                                                  </div>
                                                                {/* )} */}
                                                              </div>
                                                            </li>
                                                          )}
                                                      </React.Fragment>
                                                    )
                                                  )}
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT ===
                                      "SearchableDropdownSingle" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR?.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {attributeSetValidation[
                                                subAttribute.AC
                                              ]?.ISM?.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <SelectForm
                                              isClearable
                                              isSearchable
                                              async
                                              options={(searchString, cb) =>
                                                getSearchableDropdownAPI(
                                                  searchString,
                                                  subAttribute.AC,
                                                  cb,
                                                  "",
                                                  "attributeSet",
                                                  0
                                                )
                                              }
                                              placeholder={subAttribute.AN}
                                              isDisabled={
                                                subAttribute.AC === "RELNEMPNO"
                                                  ? false
                                                  : !section.ISEDIT ||
                                                    attributeSetValidation[
                                                      eachAttribute.AC
                                                    ]?.isDisable
                                              }
                                              value={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : null
                                              }
                                              onChange={(event) => {
                                                //OLD
                                                // setAttributeSetData((prev) => ({
                                                //   ...prev,
                                                //   [subAttribute.AC]: event,
                                                // }));
                                                //---

                                                //NEW
                                                if (
                                                  effectiveDateCode !== null &&
                                                  effectiveDateCode !==
                                                    undefined
                                                ) {
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      [subAttribute.AC]: event,
                                                      [effectiveDateCode]:
                                                        effectiveDate,
                                                    })
                                                  );
                                                } else {
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      [subAttribute.AC]: event,
                                                    })
                                                  );
                                                }
                                                //---

                                                if (
                                                  subAttribute.CC !== null &&
                                                  subAttribute.CC !== ""
                                                ) {
                                                  const CC =
                                                    subAttribute.CC.split(",");

                                                  CC.forEach(
                                                    async (childDropdown) => {
                                                      setAttributeSetData(
                                                        (prev) => ({
                                                          ...prev,
                                                          [childDropdown]: null,
                                                        })
                                                      );

                                                      const options =
                                                        await getDropdownsData(
                                                          childDropdown,
                                                          event
                                                            ? event.value
                                                            : ""
                                                        );

                                                      setOptionsObj((prev) => ({
                                                        ...prev,
                                                        [childDropdown]:
                                                          options,
                                                      }));
                                                    }
                                                  );
                                                }

                                                // dependentValidations(
                                                //   event,
                                                //   subAttribute.AC
                                                // );

                                                //searchable DD
                                                if (event) {
                                                  searchableDDValuesSet(
                                                    event,
                                                    subAttribute.AC
                                                  );
                                                }
                                              }}
                                              isMulti={false}
                                              noIndicator={false}
                                              noSeparator={false}
                                            />
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </React.Fragment>
                                )
                              )}
                          </div>
                        )}

                        <div className="row">
                          {ShowAttrSet && (
                            <div className="col-lg-12 mt-2 mb-3">
                              {
                                //section?.ISEDIT &&
                                <button
                                  id={currentGridId === null ? "0" : "1"}
                                  className="btn btn-primary"
                                  disabled={disableSaveGrid}
                                  onClick={async (e) => {
                                    await handleSaveGrid(e, eachAttribute);
                                  }}
                                >
                                  {currentGridId === null
                                    ? "Save"
                                    : "Update Record"}
                                </button>
                              }
                            </div>
                          )}
                          {
                            //section?.ISEDIT &&
                            <div className="col-lg-12 mt-2 mb-3 d-flex justify-content-end">
                              <button
                                id={"addRec"}
                                className="btn btn-primary"
                                onClick={() => {
                                  if (ShowAttrSet) {
                                    setAttributeSetData((prev) => ({
                                      ...prev,
                                      ...ogAttributeSet,
                                    }));
                                    setCurrentGridId(null);
                                  }
                                  setShowAttrSet(!ShowAttrSet);
                                }}
                              >
                                <i className={"fa fa-plus"}></i> Add
                              </button>
                            </div>
                          }
                          <div className="col-lg-12 p-0 mb-3">
                            <DynamicGrid
                              options={gridOptions}
                              data={displayGrid}
                              columns={gridColumns}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </React.Fragment>
                ))}
            </div>
          </AccordionDetails>
        </Accordion>
      ) : null}
      <Modal
        show={showConfirmationModal}
        onHide={() => closeConfirmationModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">Confirm!</div>
        </div>
        <Modal.Body>
          <h5>{modalHeader}</h5>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-success"
            style={{ marginLeft: 5 }}
            onClick={() => closeConfirmationModal(true)}
          >
            Yes
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => closeConfirmationModal(false)}
          >
            No
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EmpProgSectionComponent;
