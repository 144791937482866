import React, { useContext, useEffect, useState } from "react";
import { APICall } from "../../Helpers/APICalls";
import SelectForm from "../../Components/SelectForm/SelectForm";
import notify from "../../Helpers/ToastNotification";
import { LoaderContext } from "../../Helpers/Context/Context";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { ExpandMore } from "@mui/icons-material";
import ExcelJS from "exceljs";
import {
  getReportNames,
  getReportExcelData,
  getDropdowns,
} from "../../Helpers/APIEndPoints/EndPoints";
import DateForm from "../../Components/DateForm/DateForm";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import moment from "moment";
import fileDownload from "js-file-download";
import { base64ToArrayBuffer } from "../../Helpers/Common";
import {
  getLMSDomainDropDownData,
  getLMSReport,
  getReportDropdown,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import { Dropdown } from "react-bootstrap";

const LMSReport = () => {
  useEffect(() => {
    // (async () => {
    //   const response = await APICall(getLMSReport, "POST", {
    //     Name: "Journey",
    //     UserId: userDetails?.Id,
    //     RoleId: currentRoleId?.value,
    //     IsDynamic: true,
    //     ExcelFileName: "PRO",
    //     FileType: "EXCEL",
    //   });
    //   if (response.status === 0 && response.data !== null) {
    //     const base64 = response?.data.base64String;
    //     const fileName = `${response?.data.name}.xlsx`;
    //     // // Decode base64 data
    //     // const csvData = atob(base64);
    //     // // Create blob for CSV data
    //     // const blob = new Blob([csvData], { type: "text/csv" });
    //     // // Create file from blob
    //     // const newFile = new File([blob], fileName, {
    //     //   type: "text/csv",
    //     //   lastModified: new Date().getTime(),
    //     // });
    //     // // Trigger file download
    //     // fileDownload(newFile, fileName);
    //     let arrayBuffer = await base64ToArrayBuffer(base64);
    //     var byteArray = new Uint8Array(arrayBuffer);
    //     let blob = new Blob([byteArray], {
    //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //     });
    //     const newFile = new File([blob], "", {
    //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //       lastModified: new Date().getTime(),
    //     });
    //     fileDownload(newFile, fileName);
    //     notify(0, "Report Downloaded Successfully.");
    //   } else {
    //     console.log("no report data", response);
    //     notify(1, "No Data Found!");
    //   }
    // })();
  }, []);

  const navigate = useNavigate();
  const [reportNameDropdownOptions, setreportNameDropdownOptions] = useState(
    []
  );
  const [reportNameDropdownValue, setReportNameDropdownValue] = useState("");
  const [reportNameErrorDropdown, setReportNameErrorDropdown] = useState("");
  const [ResetReportName, setResetReportName] = useState(false);

  const [SBUDropdownOptions, setSBUDropdownOptions] = useState([]);
  const [sbuVal, setSbuVal] = useState<any>([]);
  const [ResetSBU, setResetSBU] = useState(false);

  const [SBUOptions, setSBUOptions] = useState([]);
  const [LENOptions, setLENOptions] = useState([]);
  const [CUSBISOptions, setCUSBISOptions] = useState([]);

  const [businessDropdownOptions, setBusinessNameDropdownOptions] = useState(
    []
  );
  const [bussiness, setBussiness] = useState<any>([]);
  const [ResetBusiness, setResetBusiness] = useState(false);

  const [legalEntityDropdownOptions, setLegalEntityDropdownOptions] = useState(
    []
  );
  const [legalEntity, setLegalEntity] = useState<any>([]);
  const [ResetLegalEntity, setResetLegalEntity] = useState(false);

  const [customBusinessDropdownOptions, setCustomBusinessDropdownOptions] =
    useState([]);
  const [cubis, setCubis] = useState<any>([]);
  const [ResetCustomBusiness, setResetCustomBusiness] = useState(false);

  const [physicalLocationDropdownOptions, setPhysicalLocationDropdownOptions] =
    useState([]);
  const [phyLoc, setPhyLoc] = useState("");
  const [ResetPL, setResetPL] = useState(false);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { userDetails, currentRoleId }: any = useUserContext();

  const [domainOptions, setDomainOptions] = useState([]);

  const [selectedDomains, setSelectedDomains] = useState([]);

  const [selectedReport, setSelectedReport] = useState(null);

  //Call Bisness and PL Dropdown
  useEffect(() => {
    GetDropdownValuesForBusiness();
    GetDropdownValuesForPhysicalLocation();
    fetchReportData();
    getLMSDomainDropDownDataApiCall();
  }, []);

  const getLMSDomainDropDownDataApiCall = async () => {
    const response = await APICall(getLMSDomainDropDownData, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      GetAll: false,
    });

    if (response?.status === 0 && response?.data.length > 0) {
      setDomainOptions(response?.data);
    }
  };

  //Api call for Repost Name
  const fetchReportData = async () => {
    try {
      const response = await APICall(getReportDropdown, "POST", {
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      });
      setreportNameDropdownOptions(response.data);
    } catch (error) {
      console.error("Error fetching Module data:", error);
    }
  };

  //Call api for all Dropdown
  const getDDData = async (AC, PID) => {
    const dropdowndataResponse = await APICall(getDropdowns, "POST", {
      AC,
      PID,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });
    const dropdownData = dropdowndataResponse.data;
    return dropdownData;
  };

  //Call api Covert Response in excel and pass parameter
  const exportToExcel = async (FileType) => {
    if (!selectedReport) {
      setReportNameErrorDropdown("Required!");
      return null;
    }
    const response = await APICall(getLMSReport, "POST", {
      Name: selectedReport.code,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      ExcelFileName: selectedReport.code,
      DomainIds:
        selectedDomains.length > 0
          ? selectedDomains.map((d) => d.value).join(",")
          : null,
      FileType,
      FromDate:
        fromDate !== null ? moment(fromDate).format("YYYY-MM-DD") : null,
      ToDate: toDate !== null ? moment(toDate).format("YYYY-MM-DD") : null,
    });

    if (response.status === 0 && response.data !== null) {
      const base64 = response?.data.base64String;
      const fileName = `${response?.data.name}.${
        FileType === "CSV" ? "csv" : "xlsx"
      }`;

      if (FileType === "CSV") {
        const csvData = atob(base64);
        const blob = new Blob([csvData], { type: "text/csv" });
        const newFile = new File([blob], fileName, {
          type: "text/csv",
          lastModified: new Date().getTime(),
        });
        fileDownload(newFile, fileName);
      } else {
        let arrayBuffer = await base64ToArrayBuffer(base64);
        var byteArray = new Uint8Array(arrayBuffer);

        let blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const newFile = new File([blob], "", {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          lastModified: new Date().getTime(),
        });
        fileDownload(newFile, fileName);
      }
      notify(0, "Report Downloaded Successfully.");
    } else {
      console.log("no report data", response);
      notify(1, "No Data Found!");
    }

    return null;

    setReportNameErrorDropdown("");

    let isValid = true;

    if (!reportNameDropdownValue) {
      setReportNameErrorDropdown("Please select Report Name.");
      isValid = false;
      return;
    }

    try {
      showLoader();
      const response = await APICall(getReportExcelData, "POST", {
        CUSBISIds: cubis.map((s) => s.value).join(","),
        LENIds: legalEntity.map((s) => s.value).join(","),
        PHLOCIds: phyLoc,
        ReportName: reportNameDropdownValue,
        SBUIds: sbuVal.map((s) => s.value).join(","),
        businessIds: bussiness.map((s) => s.value).join(","),
        startDate: fromDate,
        endDate: toDate,
        RoleId: currentRoleId?.value,
        UserId: userDetails?.Id,
      });

      const responseData = response.data;

      if (response?.status == 5) {
        notify(1, response?.message);
        return;
      }

      if (reportNameDropdownValue == "MRF" && response?.status == 0) {
        const base64 = response?.data;
        const fileName = `RCM_Report.xlsx`;

        let arrayBuffer = await base64ToArrayBuffer(base64);
        var byteArray = new Uint8Array(arrayBuffer);

        let blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const newFile = new File([blob], "", {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          lastModified: new Date().getTime(),
        });
        fileDownload(newFile, fileName);
        notify(0, "Report Downloaded Successfully.");
        return;
      }

      if (!responseData || responseData.length === 0) {
        notify(1, "There is No data.");
        hideLoader();
        return;
      }
      const convertCamelCaseToWords = (camelCaseString) => {
        return camelCaseString
          .replace(/([a-z])([A-Z])/g, "$1 $2")
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      };

      let filteredHeaders = [];

      let commonNationalIdInfo = [
        "EmployeeId",
        "FirstName",
        "LastName",
        "Business",
        "SBU",
        "LegalEntity",
        "Function",
        "Department",
        "Division",
        "PayrollLocation",
        "PhysicalLocation",
        "EmployeeClass",
        "JobBand",
        "JobLevel",
        "Designation",
        "BDOJ",
        "GDOJ",
        "AadharCard",
        "PanCard",
        "PassportNumber",
        "UANNumber",
        "PFNo",
        "ESICNumber",
        "PRANNumber",
        "VoterID",
        "DrivingLicense",
        "EmployeeStatus",
      ];

      let commonCompansationHeaders = [
        "EmployeeId",
        "FirstName",
        "LastName",
        "Business",
        "SBU",
        "LegalEntity",
        "Function",
        "Department",
        "Division",
        "PayrollLocation",
        "PhysicalLocation",
        "EmployeeClass",
        "JobBand",
        "JobLevel",
        "Designation",
        "BDOJ",
        "GDOJ",
        "FinalFixedPay",
        "variablepay",
        "Stipend",
        "TotalCTC",
        "EmployeeStatus",
      ];

      let commonEmployeeReportHeaders = [
        "EmployeeId",
        "UserName",
        "LegacyId",
        "FirstName",
        "MiddleName",
        "LastName",
        "MaritalStatus",
        "MaritalStatusSince",
        "Gender",
        "DateOfBirth",
        "PositionTitle",
        "Nationality",
        "BloodGroup",
        "Business",
        "BusinessEmailInformationEmailAddress",
        "Domicile",
        "LegalEntity",
        "SBU",
        "Department",
        "Division",
        "Function",
        "Section",
        "SubSection",
        "JobBand",
        "JobLevel",
        "LOPRate",
        "EmployeeClass",
        "CostCenter",
        "Designation",
        "ClientFacingTitle",
        "PayrollLocation",
        "PhysicalLocation",
        "CustomBusiness",
        "NativePreferredLanguage",
        "EmploymentDetailsBusinessJoiningDate",
        "EmploymentDetailsGroupJoiningDate",
        "PastExperienceInMonths",
        "HRManagerJobRelationshipsUserId",
        "HRManagerJobRelationshipsName",
        "ManagerUserSysID",
        "CompensationManagerJobRelationshipsName",
        "CompensationManagerJobRelationshipsUserId",
        "JobClassification",
        "Position",
        "MatrixManagerJobRelationshipsName",
        "MatrixManagerJobRelationshipsUserId",
        "SkillCategory",
        "MobilePhoneInformationPhoneNumber",
      ];

      if (reportNameDropdownValue === "AllEmployeeData") {
        commonEmployeeReportHeaders.push(
          "EmploymentDetailsDateOfResignation",
          "EmploymentDetailsLastWorkingDay",
          "EmployeeStatus"
        );
        filteredHeaders = commonEmployeeReportHeaders;
      } else if (reportNameDropdownValue === "ActiveEmployeeData") {
        commonEmployeeReportHeaders.push("NoticePeriodDays");
        filteredHeaders = commonEmployeeReportHeaders;
      } else if (reportNameDropdownValue === "Addressinformation") {
        filteredHeaders = [
          "EmployeeId",
          "FirstName",
          "LastName",
          "Business",
          "SBU",
          "LegalEntity",
          "Function",
          "Department",
          "Division",
          "PayrollLocation",
          "PhysicalLocation",
          "EmployeeClass",
          "JobBand",
          "JobLevel",
          "Designation",
          "BDOJ",
          "GDOJ",
          "AddressType",
          "HouseNo",
          "AddressLine",
          "City",
          "District",
          "Country",
          "State",
          "PIN",
          "Type",
          "LeaseStartDate",
          "LeaseEndDate",
          "EmployeeStatus",
        ];
      } else if (reportNameDropdownValue === "BankInformation") {
        filteredHeaders = [
          "EmployeeId",
          "UserName",
          "FirstName",
          "LastName",
          "Business",
          "SBU",
          "LegalEntity",
          "Function",
          "Department",
          "Division",
          "PayrollLocation",
          "PhysicalLocation",
          "EmployeeClass",
          "JobBand",
          "JobLevel",
          "Designation",
          "BDOJ",
          "GDOJ",
          "PayType",
          "PaymentMethod",
          "BankCountryRegion",
          "BankName",
          "IFSCSwiftcode",
          "BankAccountNumber",
          "Currency",
          "EmployeeStatus",
        ];
      } else if (reportNameDropdownValue === "BankHistoryInformation") {
        filteredHeaders = [
          "EmployeeId",
          "UserName",
          "FirstName",
          "LastName",
          "Business",
          "SBU",
          "LegalEntity",
          "Function",
          "Department",
          "Division",
          "PayrollLocation",
          "PhysicalLocation",
          "EmployeeClass",
          "JobBand",
          "JobLevel",
          "Designation",
          "BDOJ",
          "GDOJ",
          "PayType",
          "PaymentMethod",
          "BankCountryRegion",
          "BankName",
          "IFSCSwiftcode",
          "BankAccountNumber",
          "Currency",
          "EmployeeStatus",
          "ChangedBy",
          "EffectiveDate",
          "LastModifiedDate",
          "LastModifiedTime",
          // "ApprovedBy",
          // "ApprovedOn",
        ];
      } else if (reportNameDropdownValue === "NationalIdInformation") {
        filteredHeaders = commonNationalIdInfo;
      } else if (reportNameDropdownValue === "NationalIdHistoryInformation") {
        commonNationalIdInfo.push(
          "CreatedByEmpIdName",
          "EffectiveDate",
          "LastModifiedDate",
          "LastModifiedTime"
        );
        filteredHeaders = commonNationalIdInfo;
      } else if (reportNameDropdownValue == "CompansationHistory") {
        commonCompansationHeaders.push(
          "CreatedByEmpIdName",
          "EffectiveDate",
          "LastModifiedDate",
          "LastModifiedTime"
        );
        filteredHeaders = commonCompansationHeaders;
      } else if (reportNameDropdownValue === "PreviousEmployment") {
        filteredHeaders = [
          "EmployeeId",
          "FirstName",
          "LastName",
          "Business",
          "SBU",
          "LegalEntity",
          "Function",
          "Department",
          "Division",
          "PayrollLocation",
          "PhysicalLocation",
          "EmployeeClass",
          "JobBand",
          "JobLevel",
          "Designation",
          "BDOJ",
          "GDOJ",
          "PreviousEmployment",
          "IndustryType",
          "CompanyName",
          "StartDate",
          "EndDate",
          "EmployeeDesignation",
          "EmployerAddress",
          "Country",
          "PreviousEmployerFunction",
          "AnnualCTC",
          "EmployeeStatus",
        ];
      } else if (reportNameDropdownValue === "FormalEducation") {
        filteredHeaders = [
          "EmployeeId",
          "FirstName",
          "LastName",
          "Business",
          "SBU",
          "LegalEntity",
          "Function",
          "Department",
          "Division",
          "PayrollLocation",
          "PhysicalLocation",
          "EmployeeClass",
          "JobBand",
          "JobLevel",
          "Designation",
          "BDOJ",
          "GDOJ",
          "Qualification",
          "Degree",
          "FromDate",
          "EndDate",
          "SpecializationMajor",
          "SpecializationMinor",
          "EducationEshtablishmentType",
          "InstituteCollege",
          "DurationOfCourse",
          "GradeScore",
          "HighestEducation",
          "EducationMode",
          "EmployeeStatus",
        ];
      } else if (reportNameDropdownValue === "Compensation") {
        filteredHeaders = commonCompansationHeaders;
      } else if (reportNameDropdownValue === "Dependant") {
        filteredHeaders = [
          "PersonId",
          "Relationship",
          "FirstName",
          "MiddleName",
          "LastName",
          "DateOfBirth",
          "Gender",
          "DependentEmpPersonalInfoTLastModifiedDate",
          "Business",
          "SBU",
          "LegalEntity",
          "Division",
          "Function",
          "Department",
          "PhysicalLocation",
          "PayrollLocation",
          "EmployeeClass",
          "JobBand",
          "JobLevel",
          "Designation",
          "EmployeeStatus",
        ];
      } else if (
        reportNameDropdownValue === "ResignationPendingApprovedAuditTrailReport"
      ) {
        filteredHeaders = [
          "EmployeeStatus",
          "EmployeeId",
          "DisplayName",
          "LegacyId",
          "FirstName",
          "MiddleName",
          "LastName",
          "MaritalStatus",
          "MaritalStatusSince",
          "Gender",
          "DateOfBirth",
          "Nationality",
          "Business",
          "BusinessEmailInformationEmailAddress",
          "Domicile",
          "SBU",
          "LegalEntity",
          "CustomBusiness",
          "Function",
          "Department",
          "Division",
          "Section",
          "SubSection",
          "SubSubSection",
          "JobBand",
          "JobLevel",
          "EmployeeClass",
          "CostCenter",
          "Designation",
          "PayrollLocation",
          "PhysicalLocation",
          "EmploymentDetailsBusinessJoiningDate",
          "EmploymentDetailsGroupJoiningDate",
          "EventName",
          "EventReason",
          "WorkflowStepsNumber",
          "CurrentWorkflowStepNo",
          "CreatedByUser",
          "CreatedDate",
          "Status",
          "TransactionId",
          "InitiatorRole",
          "EmployeeDOR",
          "EmployeeLWD",
          "SeperationType",
          "SeperationReason",
          "NoticePeriodStartDate",
          "NoticePeriodEndDate",
          "NoticePeriodInMonths",
          "NoticePeriodInShortDays",
          "WaiveOffApproval",
          "WaiveOffApprovalReason",
          "okToRehire",
          "CurrentPendingWith",
          "FirstLevelApprover",
          "FirstApprovedOnDateAndTime",
          "SecondLevelApprover",
          "SecondApprovedOnDateAndTime",
          "FinalLevelApprover",
          "FinalApprovedOnDateAndTime",
          "TotalDaysFromInitiationDateToFinalApprovalDate",
        ];
      } else if (reportNameDropdownValue === "EmployeePerformanceRating") {
        filteredHeaders = [
          "EmployeeStatus",
          "EmployeeId",
          "UserName",
          "LegacyId",
          "FirstName",
          "MiddleName",
          "LastName",
          "MaritalStatus",
          "MarriedSince",
          "Gender",
          "DOB",
          "Nationality",
          "Domicile",
          "BusinessEmail",
          "PastExperience",
          "Business",
          "SBU",
          "LegalEntity",
          "CustomBusiness",
          "Function",
          "Department",
          "Division",
          "Section",
          "SubSection",
          "SubSubSection",
          "CostCenter",
          "PayrollLocation",
          "PhysicalLocation",
          "EmployeeClass",
          "JobBand",
          "JobLevel",
          "Designation",
          "SkillCategory",
          "BDOJ",
          "GDOJ",
          "FromDate",
          "EndDate",
          "PerformanceYear",
          "PerformanceRating",
          "PotentialRating",
        ];
      } else if (reportNameDropdownValue === "GTLIReport") {
        filteredHeaders = [
          "EmployeeStatus",
          "EmployeeId",
          "UserName",
          "LegacyId",
          "FirstName",
          "MiddleName",
          "LastName",
          "MaritalStatus",
          "MarriedSince",
          "Gender",
          "DOB",
          "Nationality",
          "Domicile",
          "BusinessEmail",
          "Business",
          "SBU",
          "LegalEntity",
          "CustomBusiness",
          "Function",
          "Department",
          "Division",
          "Section",
          "SubSection",
          "SubSubSection",
          "CostCenter",
          "PayrollLocation",
          "PhysicalLocation",
          "EmployeeClass",
          "JobBand",
          "JobLevel",
          "Designation",
          "BDOJ",
          "GDOJ",
          "SchemeApplicability",
          "PolicyStartDate",
          "PolicyEndDate",
          "CoverageAmount",
          "CoverageStartDate",
          "CoverageEndDate",
        ];
      } else if (reportNameDropdownValue === "LoginReport") {
        filteredHeaders = [
          "SessionId",
          "UserName",
          "FirstName",
          "MiddleName",
          "LastName",
          "Business",
          "BusinessEmailInformation",
          "LegalEntity",
          "SBU",
          "Department",
          "Division",
          "Function",
          "Section",
          "SubSection",
          "JobBand",
          "JobLevel",
          "EmployeeClass",
          "CostCenter",
          "Designation",
          "ClientFacingTitle",
          "PayrollLocation",
          "PhysicalLocation",
          "CustomBusiness",
          "BusinessJoiningDate",
          "GroupJoiningDate",
          "Position",
          "MobilePhoneInformation",
          "LoginRequired",
          "LoginType",
          "ModeOfCommunication",
          "CommunicationEmail",
          "CommunicationPhone",
          "IPAddressOfLogin",
          "LoginDate",
          "LoginTime",
          "HoldPeriod",
          "ProxyLogin",
          "IPAddressOfProxyLogin",
          "ProxyEmployeeId",
        ];
      }

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Report");

      const headerRow = worksheet.addRow(
        filteredHeaders.map((header) => convertCamelCaseToWords(header))
      );
      headerRow.font = { bold: true };

      responseData.forEach((row) => {
        const dataRow = filteredHeaders.map(
          (header) => row[header.toLowerCase()] || ""
        );
        worksheet.addRow(dataRow);
      });

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);

        const reportName = reportNameDropdownOptions.find(
          (x) => x.value === reportNameDropdownValue
        )?.label;
        const a = document.createElement("a");
        a.href = url;
        a.download = `${reportName}.xlsx`;
        a.click();

        URL.revokeObjectURL(url);
        hideLoader();
      });
    } catch (error) {
      console.error("Error exporting to Excel:", error);
      hideLoader();
    } finally {
      hideLoader();
    }
  };

  // business dropdown
  const GetDropdownValuesForBusiness = async () => {
    const AC = "BIS";
    const PID = null;
    let dropdownDataForBis = await getDDData("BIS", PID);
    setBusinessNameDropdownOptions(dropdownDataForBis);
    if (dropdownDataForBis) {
      const commaSeparatedBISIds = dropdownDataForBis
        .map((e) => e.value)
        .join(",");
      if (commaSeparatedBISIds) {
        let sbuData = await getDDData("SBU", commaSeparatedBISIds);
        setSBUOptions(sbuData);
        const commaSeparatedSBUIds = sbuData.map((e) => e.value).join(",");
        if (commaSeparatedSBUIds) {
          let lEData = await getDDData("LEN", commaSeparatedSBUIds);
          setLENOptions(lEData);
          const commaSeparatedLENIds = lEData.map((e) => e.value).join(",");
          let PId = `${commaSeparatedBISIds}-${commaSeparatedSBUIds}-${commaSeparatedLENIds}`;
          if (PId) {
            const cubisData = await getDDData("CUBISDash", PID);
            setCUSBISOptions(cubisData);
          }
        }
      }
    }
  };

  //Physical Location Dropdown
  const GetDropdownValuesForPhysicalLocation = async () => {
    const AC = "PHLOC";
    const PID = null;
    let dropdownDataForPL = await getDDData("PHLOC", PID);
    setPhysicalLocationDropdownOptions(dropdownDataForPL);
  };

  //Fetch values in cascading dropdown
  const handleOnChange = async (DDName, event) => {
    if (DDName === "BIS") {
      let PID = "";
      setBussiness(event);
      setResetBusiness(false);
      const commaSeparatedBISIds =
        event && event.length > 0 && event.map((e) => e.value).join(",");
      if (commaSeparatedBISIds) {
        let sbuData = await getDDData("SBU", commaSeparatedBISIds);
        setSBUDropdownOptions(sbuData);
      }
    }

    if (DDName === "SBU") {
      let PID = "";
      setSbuVal(event);
      setResetSBU(false);
      const commaSeparatedSBUIds =
        event && event.length > 0 && event.map((e) => e.value).join(",");
      if (commaSeparatedSBUIds) {
        let lEData = await getDDData("LEN", commaSeparatedSBUIds);
        setLegalEntityDropdownOptions(lEData);
      }
    }

    if (DDName === "LEN") {
      let PID = "";
      setLegalEntity(event);
      setResetLegalEntity(false);
      let BIS = bussiness.map((e) => e.value).join(",");
      let SBU = sbuVal.map((e) => e.value).join(",");
      let LEN = event.map((e) => e.value).join(",");

      let PId = `${BIS}-${SBU}-${LEN}`;
      if (PId) {
        const cubisData = await getDDData("CUBISDash", PID);
        setCustomBusinessDropdownOptions(cubisData);
      }
    }

    if (DDName === "CUBIS") {
      let PID = "";
      setCubis(event);
    }

    if (DDName === "PHLOC") {
      let PID = "";
      const commaSeparatedPLIds =
        event && event.length > 0 && event.map((e) => e.value).join(",");
      if (commaSeparatedPLIds) {
        setPhyLoc(commaSeparatedPLIds);
        setResetPL(false);
      }
    }
  };

  //Chnage and set From Date
  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  //Chnage and set To Date
  const handleToDateChange = (date) => {
    setToDate(date);
  };

  //To change get Repost Name
  const handleReportChange = (selectedOption: any) => {
    showLoader();
    setReportNameDropdownValue(selectedOption ? selectedOption.value : "");
    hideLoader();
  };

  //Form reset
  const resetForm = () => {
    setSelectedReport(null);
    setSelectedDomains([]);
    setBussiness([]);
    setPhyLoc("");
    setSbuVal([]);
    setCubis([]);
    setToDate(null);
    setFromDate(null);
    setReportNameErrorDropdown("");
    setResetSBU(true);
    setResetBusiness(true);
    setResetReportName(true);
    setResetLegalEntity(true);
    setResetCustomBusiness(true);
    setResetPL(true);
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <>
      <div className="breadcrumb modified-bredcrumb">
        <span>
          <ul>
            <li>
              <a href="/home">Home</a>
            </li>
            <li>LMS Reports</li>
          </ul>
        </span>
      </div>

      <div className="container-fluid">
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            style={{ background: "#3C5464" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}
          >
            <Typography>Reports</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row">
              {/* Report Name */}
              <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown1">
                    Report<sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    value={selectedReport}
                    placeholder="Select an option"
                    options={reportNameDropdownOptions}
                    isOptionDisabled={(option) =>
                      !option?.isView || !option?.isEdit
                    }
                    onChange={(selectedOption) => {
                      setSelectedReport(selectedOption);
                    }}
                    isMulti={false}
                  />
                  {reportNameErrorDropdown && (
                    <p className="text-danger">{reportNameErrorDropdown}</p>
                  )}
                </div>
              </div>

              <div className={"dropdown col-lg-3 col-sm-3 col-xs-3"}>
                <label className="col-form-label">Domains</label>
                <SelectForm
                  isClearable
                  options={domainOptions}
                  placeholder={"Domains"}
                  isDisabled={false}
                  value={selectedDomains}
                  onChange={(e) => {
                    setSelectedDomains(e);
                  }}
                  isMulti={true}
                  noIndicator={false}
                  noSeparator={false}
                />
              </div>
              {/* 
              <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown2">
                    Business
                  </label>
                  <SelectForm
                    isClearable
                    id="dropdown2"
                    value={ResetBusiness ? null : bussiness}
                    placeholder="Select an option"
                    options={businessDropdownOptions}
                    onChange={(e) => {
                      setResetBusiness(false); // Reset the flag
                      handleOnChange("BIS", e);
                    }}
                    isMulti={true}
                  />
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown3">
                    SBU
                  </label>
                  <SelectForm
                    isClearable
                    id="dropdown3"
                    value={ResetSBU ? null : sbuVal}
                    placeholder="Select an option"
                    options={SBUDropdownOptions}
                    onChange={(e) => {
                      handleOnChange("SBU", e);
                    }}
                    isMulti={true}
                  />
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown4">
                    Legal Entity
                  </label>
                  <SelectForm
                    isClearable
                    id="dropdown4"
                    value={ResetLegalEntity ? null : legalEntity}
                    placeholder="Select an option"
                    options={legalEntityDropdownOptions}
                    onChange={(e) => {
                      setResetLegalEntity(false); // Reset the flag
                      handleOnChange("LEN", e);
                    }}
                    isMulti={true}
                  />
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown5">
                    Custom Business
                  </label>
                  <SelectForm
                    isClearable
                    id="dropdown5"
                    value={ResetCustomBusiness ? null : cubis}
                    placeholder="Select an option"
                    options={customBusinessDropdownOptions}
                    onChange={(e) => {
                      setResetCustomBusiness(false); // Reset the flag
                      handleOnChange("CUBIS", e);
                    }}
                    isMulti={true}
                  />
                </div>
              </div>

              <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown6">
                    Physical Location
                  </label>
                  <SelectForm
                    isClearable
                    id="dropdown6"
                    value={
                      ResetPL
                        ? null
                        : physicalLocationDropdownOptions.find((option) =>
                            option.value === phyLoc ? option : null
                          )
                    }
                    placeholder="Select an option"
                    options={physicalLocationDropdownOptions}
                    onChange={(e) => {
                      setResetPL(false);
                      handleOnChange("PHLOC", e);
                    }}
                    isMulti={true}
                  />
                </div>
              </div> */}

              {/* From Date */}
              <div className="col-md-3">
                <label className="col-form-label">From Date</label>
                <DateForm
                  isDisabled={false}
                  value={fromDate}
                  onChange={handleFromDateChange}
                />
              </div>

              {/* To Date */}
              <div className="col-md-3">
                <label className="col-form-label">To Date</label>
                <DateForm
                  isDisabled={false}
                  value={toDate}
                  onChange={handleToDateChange}
                />
              </div>
            </div>

            {/* Buttons */}
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-3"></div>
              <div className="col-md-3"></div>

              {/* Reset & Submit */}
              <div className="col-md-3">
                {/* <Dropdown className="d-inline">
                  <Dropdown.Toggle
                    className="btn btn-primary mr-2"
                    variant="success"
                    id="export"
                    style={{ color: "white" }}>
                    <i className="fa fa-download mr-2"></i>
                    Export
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => exportToExcel("EXCEL")}>
                      Excel
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => exportToExcel("CSV")}>
                      CSV
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}

                <button
                  type="button"
                  onClick={() => exportToExcel("EXCEL")}
                  className="btn btn-primary mr-2"
                >
                  {" "}
                  <i className="fa fa-download mr-2"></i>
                  Export to Excel
                </button>

                <button
                  type="button"
                  onClick={resetForm}
                  className="btn btn-cancel"
                >
                  {" "}
                  <i className="fa fa-undo mr-2" aria-hidden="true"></i>
                  Reset
                </button>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};

export default LMSReport;
