import React, { useEffect, useState } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import "./PdfViewer.css";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

const PdfViewer = ({
  contentUrl,
  setTrackPdfData = null,
  currentPageNumber = null,
  handletractPdfStatusFunction = null,
}) => {
  // for pdf
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);

    if (numPages === 1) {
      handletractPdfStatusFunction(numPages);
    }
    if (
      currentPageNumber === null ||
      currentPageNumber === "" ||
      currentPageNumber === undefined ||
      currentPageNumber === 0
    ) {
      setPageNumber(1);
    } else {
      setPageNumber(currentPageNumber);
    }
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  useEffect(() => {
    if (setTrackPdfData) {
      setTrackPdfData({ pageNumber, numPages });
    }
  }, [pageNumber, numPages]);

  return (
    <>
      <nav className="my-2">
        <button
          className="btn"
          onClick={previousPage}
          disabled={pageNumber <= 1}
        >
          <i className="fa fa-chevron-left" aria-hidden="true"></i>
        </button>
        <span>
          Page {pageNumber} of {numPages}
        </span>
        <button
          className="btn"
          onClick={nextPage}
          disabled={pageNumber >= numPages}
        >
          <i className="fa fa-chevron-right" aria-hidden="true"></i>
        </button>
      </nav>

      <div style={{ width: "100%", height: "100%" }}>
        <Document
          file={`data:application/pdf;base64,${contentUrl}`}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document>
      </div>

      <nav className="my-2">
        <button
          className="btn"
          onClick={previousPage}
          disabled={pageNumber <= 1}
        >
          <i className="fa fa-chevron-left" aria-hidden="true"></i>
        </button>
        <span>
          Page {pageNumber} of {numPages}
        </span>
        <button
          className="btn"
          onClick={nextPage}
          disabled={pageNumber >= numPages}
        >
          <i className="fa fa-chevron-right" aria-hidden="true"></i>
        </button>
      </nav>
    </>
  );
};

export default PdfViewer;
