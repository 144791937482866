import React, { useRef, useState, useEffect, useContext, CSSProperties, useCallback } from 'react';
import Tree from 'react-d3-tree';
import './style.css';
import Button from 'react-bootstrap/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Form from 'react-bootstrap/Form';
import { APICall } from '../../Helpers/APICalls';
import { getEmployeeWithManager, getEmployeeChildrens, getEmployeeGridData, getEmployeeStructureDetails } from '../../Helpers/APIEndPoints/EndPoints';
import useUserContext from '../../Helpers/ZustandStore/UserContextStore';
import { isEmpty } from '../../utils/isEmpty';
import Base64Img from './Base64Img';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import ReactDOM from 'react-dom';
import { OverlayTrigger, Popover as RPopover } from "react-bootstrap";
import html2canvas from 'html2canvas';
import pptxgen from 'pptxgenjs';
import moment from 'moment';
import { LoaderContext } from "../../Helpers/Context/Context";
import notify from "../../Helpers/ToastNotification";
import SelectForm from "../../Components/SelectForm/SelectForm";
import { useNavigate } from "react-router-dom";
import Loader from '../../Helpers/Loader/Loader';
import PptFirstSlide from '../../Assets/Images/pptx1.png'
import PpptSecondSlide from '../../Assets/Images/pptx2.png'
 import FinalBlueLogo from  "../../Assets/Images/welspunlogo.png";
import { useDebounceFunc } from "../../utils/useDebounce";
// import { PDFDownloadLink, PDFViewer, Document, Page, Text } from '@react-pdf/renderer';
// import { renderToString } from 'react-dom/server';


// Position ID:​
// Designation
// Occupied By
// Effective Date :
// Status :
// Is budgeted ?
// Criticality
// FTE
// To be Hired
// Vacancy status
// Is KMP
// Is critical role
// Business​
// SBU​
// Legal Entity​
// Custom Business
// Division​
// Function​
// Department​
// Section
// Sub section
// Sub Sub section
// Payroll Location
// Cost Center
// Employee Class​
// Job Band​
// Job level​
// Job Code
// Job Title
// Client Facing Title
// Parent Position (Higher Level Position )
// Incumbent of parent Position

const popoverData = [
    {
        key: 'positionId',
        label: 'Position ID'
    },
    {
        key: 'designation',
        label: 'Designation'
    },
    {
        key: 'occupiedBy',
        label: 'Occupied By'
    },
    {
        key: 'mrfid',
        label: 'Mrf ID'
    },
    {
        key: 'effectiveDate',
        label: 'Effective Date'
    },
    {
        key: 'status',
        label: 'Status'
    },
    {
        key: 'isBudgeted',
        label: 'Is budgeted?'
    },
    {
        key: 'criticality',
        label: 'Criticality'
    },
    {
        key: 'fte',
        label: 'FTE'
    },
    {
        key: 'toBeHired',
        label: 'To be Hired'
    },
    {
        key: 'vacancyStatus',
        label: 'Vacancy status'
    },
    {
        key: 'isKMP',
        label: 'Is KMP'
    },
    {
        key: 'isCriticalRole',
        label: 'Is critical role'
    },
    {
        key: 'business',
        label: 'Business'
    },
    {
        key: 'sbu',
        label: 'SBU'
    },
    {
        key: 'legalentity',
        label: 'Legal Entity'
    },
    {
        key: 'customBusiness',
        label: 'Custom Business'
    },
    {
        key: 'division',
        label: 'Division'
    },
    {
        key: 'function',
        label: 'Function'
    },
    {
        key: 'department',
        label: 'Department'
    },
    {
        key: 'section',
        label: 'Section'
    },
    {
        key: 'subSection',
        label: 'Sub section'
    },
    {
        key: 'subSubSection',
        label: 'Sub Sub section'
    },
    {
        key: 'payrollLocation',
        label: 'Payroll Location'
    },
    {
        key: 'costCenter',
        label: 'Cost Center'
    },
    {
        key: 'employeeclass',
        label: 'Employee Class'
    },
    {
        key: 'jobBand',
        label: 'Job Band'
    },
    {
        key: 'jobLevel',
        label: 'Job level'
    },
    {
        key: 'jobCode',
        label: 'Job Code'
    },
    {
        key: 'jobTitle',
        label: 'Job Title'
    },
    {
        key: 'clientFacingTitle',
        label: 'Client Facing Title'
    },
    {
        key: 'parentPosition',
        label: 'Parent Position (Higher Level Position)'
    },
    {
        key: 'incumbentOfParentPosition',
        label: 'Incumbent of parent Position'
    }
];


let orgChart = {
    name: 'CEO',
    children: [
        {
            name: 'Manager',
            attributes: {
                department: 'Production',
            },
            children: [
                {
                    name: 'Foreman',
                    attributes: {
                        department: 'Fabrication',
                    },
                    size: { x: 500, y: 500 },
                    children: [
                        {
                            name: 'Worker',
                        },
                    ],
                },
                {
                    name: 'Foreman',
                    attributes: {
                        department: 'Assembly',
                    },
                    children: [
                        {
                            name: 'Worker',
                        },
                    ],
                },
            ],
        },
    ],
};


const ReportingStructure = () => {
    const shouldRecenterTreeRef = useRef(true);
    const [treeTranslate, setTreeTranslate] = useState({ x: 0, y: 0 });
    const treeContainerRef = useRef(null);
    const navigate = useNavigate();

    const { userDetails, currentRoleId,interfacePermissionRecord, proxyUserDetails }: any = useUserContext();
    const { EmployeeId } = userDetails || {};
    const { EmployeeId: proxyEmployeeId } = proxyUserDetails || {};

    const [requestType, setRequestType] = useState('employee');
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = React.useState('female');
    const [searchStr, setSearchStr] = useState('');
    const [orgData, setOrgData] = useState<any>();
    const [nodeData, setNodeData] = useState({});
    const [treeConfigs, setTreeConfigs] = useState({
        nodeSize: { x: 400, y: 200 },
        foreignObjectProps: { width: 400, height: 400, x: -200, y: -40 },
        separation: { nonSiblings: 4, siblings: 3 }
    });

    // const [nodeSize, setnodeSize] = useState({ x: 400, y: 200 });
    const [foreignObjectProps, setForeignObjectProps] = useState({ width: 400, height: 400, x: -200, y: -40 });
    // const [separation, setSeparation] = useState({ 'nonSiblings': 4, 'siblings': 3 })

    const [anchorEl, setAnchorEl] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [historyData, setHistoryData] = useState([]);
    const [employeeSearch, setEmployeeSearch] = useState(null);
    const [printableNameLogo,setPrintableNameLogo] = useState(false);
    const handleClick = (event, nodeDataItem) => {
        event.stopPropagation();
        const { attributes } = nodeDataItem || {};
        setAnchorEl(event.currentTarget);
        setNodeData(attributes);
    };

    const printStylesOrg = `
    @media print {
   html, body * {
                visibility: hidden !important;
                -webkit-print-color-adjust: exact;
                max-height: 90vh  !important;
            }
                .position-print {
    display: none;
  }
  .main-content {
    min-height: 100% !important;
  }
  #printableContent, #printableContent *{
    visibility: visible !important;
    max-height: 90vh  !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important; 
  }
}
`;

    const handleClose = () => {
        setAnchorEl(null);
        setNodeData({});
    };
    const open = Boolean(anchorEl);
    const itemId = open ? 'simple-popover' : undefined;

    const [zoom, setZoom] = useState(1);
    const { showLoader, hideLoader } = useContext(LoaderContext);

    const handleChange = async (event) => {
        setOrgData(null);
        setEmployeeSearch(null);
        showLoader();
        try {
            const { value } = event.target || {};
            setRequestType(value);
            if (value === 'position') {
                // setnodeSize((prev) => ({ ...prev, x : 450 }));
                setForeignObjectProps((prev) => ({ ...prev, x: -230, width: 450 }));
                // setSeparation((prev) => ({ ...prev, siblings : 4 }));

                await setTreeConfigs(prevState => ({
                    ...prevState,
                    nodeSize: { ...prevState.nodeSize, x: 450,y:200 },
                    foreignObjectProps: { ...prevState.foreignObjectProps, x: -230, width: 450 },
                    separation: { ...prevState.separation, siblings: 4 }
                }));

            } else {
                setForeignObjectProps((prev) => ({ ...prev, x: -200, width: 400 }));
                await setTreeConfigs(prevState => ({
                    ...prevState,
                    nodeSize: { ...prevState.nodeSize, x: 400,y:200 },
                    foreignObjectProps: { ...prevState.foreignObjectProps, x: -200 },
                    separation: { ...prevState.separation, siblings: 3 }
                }));
            }
        }
        catch (error) {
            console.error("Error in Toggle from emp to pos", error)
        }
        finally {
            hideLoader();
        }

    };
    const captureDivAsImage = async () => {
        showLoader();
        let positionPrintDivs;
        let currentEmployeeDivs;
        const white_color: CSSProperties = {
            border: '1px solid #2c2b7c',
            background: '#e9e9f7',
            color: 'white',
        };
        const white_color_after: CSSProperties = {
            border: '0px',
            background: '#2c2b7c',
            color: 'white',
        };
        try {
            const divElement = document.getElementById('printableContent');
            positionPrintDivs = divElement.querySelectorAll('.position-print');
            currentEmployeeDivs = divElement.querySelectorAll('.current_employee');

            positionPrintDivs.forEach(div => {
                (div as HTMLElement).style.display = 'none';
            });
            currentEmployeeDivs.forEach(div => {
                Object.assign((div as HTMLElement).style, white_color);
            });
            const canvas = await html2canvas(divElement, {
                backgroundColor: null,
                scale: 10,
            });
            const imageData = canvas.toDataURL('image/png');
            const pptx = new pptxgen();
            pptx.addSlide().addImage({
                path: PptFirstSlide,
                x: 0, y: 0, w: 10, h: 5.5
            });
            pptx.addSlide().addImage({
                data: imageData,
                x: 0, y: 0, w: 10, h: 5.5
            });
            pptx.addSlide().addImage({
                path: PpptSecondSlide,
                x: 0, y: 0, w: 10, h: 5.5
            })
            await pptx.writeFile({ fileName: `WelPro_OrgStructure_${moment().format("DD-MM-YYYY")}.pptx` });
            notify(0, "File Downloaded Successfully");
        }
        catch (error) {
            console.error("Error in Downloading PPT:", error);
            // return null;
        } finally {
            positionPrintDivs.forEach(div => {
                (div as HTMLElement).style.display = 'block';
            });
            currentEmployeeDivs.forEach(div => {
                Object.assign((div as HTMLElement).style, white_color_after);
            });
            hideLoader();
        }
    };
    function printDiv(event) {
        event.preventDefault();
        const printableContent = document.getElementById('printableContent').innerHTML;
        const divElement = document.getElementById('printableContent');
        let positionPrintDivsPdf = divElement.querySelectorAll('.position-print');
        const tempContainer = document.createElement('div');
        tempContainer.innerHTML = printableContent;
        document.body.appendChild(tempContainer);
        document.title=`WelPro_OrgStructure_${moment().format("DD-MM-YYYY")}`;
        positionPrintDivsPdf.forEach(div => {
            (div as HTMLElement).style.display = 'none';
        });
        window.print();
        positionPrintDivsPdf.forEach(div => {
            (div as HTMLElement).style.display = 'block';
        });
        tempContainer.remove();
        document.title='WelPro';
    };
    // const addChildByEid = (data, eid, newData) => {
    //     if (data.attributes && data.attributes.eid === eid) {
    //         if (!data.children) {
    //             data.children = [];
    //         }
    //         data.children.push(...newData.map(item => ({
    //             "name": item.name,
    //             "attributes": {
    //                 "department": item.department,
    //                 "designation": item.designation,
    //                 "eid": item.employeeCode
    //             }
    //         })));
    //         return true;
    //     } else if (data.children) {
    //         for (const child of data.children) {
    //             if (addChildByEid(child, eid, newData)) {
    //                 return true;
    //             }
    //         }
    //     }
    //     return false;
    // };


    const handleCloseModal = () => {
        setOpenModal(false);
        setHistoryData([]);
    };

    const addChildByEid = (eid, newData) => {
        if (!isEmpty(String(eid))) {
            const findAndAddChild = (node) => {
                if (node.attributes && String(node.attributes.eid) === String(eid)) {
                    if (!node.children) {
                        node.children = [];
                    }
                    const formattedChildren = newData.map(item => ({
                        name: item.name || 'w',
                        attributes: {
                            ...item,
                            department: item.department || '',
                            designation: item.designation || '',
                            eid: item.id || '',
                            isIndirectReportee: item?.isIndirectReportee || '',
                            positionId: item?.positionId || '',
                            occupiedBy: item?.occupiedBy || '',
                            isCollapsed: true
                        }
                    }));
                    node.children = newData.length === 0 ? [] : formattedChildren;
                    node.attributes.isCollapsed = newData.length > 0 ? false : true;
                    return true;
                }
                if (node.children) {
                    for (const child of node.children) {
                        if (findAndAddChild(child)) {
                            return true;
                        }
                    }
                }
                return false;
            };

            const dataCopy = JSON.parse(JSON.stringify(orgData));
            findAndAddChild(dataCopy);
            return dataCopy;
        }
    };


    const getUserIWithManager = async (eid?: string | number,pid?: string | number, isManagerData ?: Boolean) => {
        setIsLoading(true);
        try {
            const isPid = eid ? undefined : pid;
            const EID = (requestType === 'position' && !isEmpty(employeeSearch) ? 0 : employeeSearch?.value || proxyEmployeeId || EmployeeId);
            const PID = requestType === 'position' && !isEmpty(employeeSearch) ? employeeSearch?.value : undefined;
            const res = await APICall(getEmployeeWithManager, "POST", {
                EID : isManagerData ? eid || undefined : EID,
                PID : isManagerData ? isPid || undefined : PID,
                RequestOption: requestType
            });

            const { data: empData, status } = res || {};


            if (status === 0 && empData) {
                const { name, department, designation, manager, id, positionId, occupiedBy, ...rest } = empData || {};
                const { name: mName, department: mDepartment, designation: mDesignation, id: mId, positionId: mPositionId, occupiedBy: moccupiedBy, ...mRest } = manager || {};

                let orgChartData;
                
                if (manager) {
                    orgChartData = {
                        name: mName || 'w',
                        attributes: {
                            ...mRest,
                            department: mDepartment || '',
                            designation: mDesignation || '',
                            eid: mId || '',
                            mainNode: true,
                            positionId: mPositionId || '',
                            occupiedBy: moccupiedBy || '',
                        },
                        children: [
                            {
                                name: name || 'w',
                                attributes: {
                                    ...rest,
                                    department: department || '',
                                    designation: designation || '',
                                    eid: id || '',
                                    positionId: positionId || '',
                                    occupiedBy: occupiedBy || '',
                                    isCollapsed: true
                                },
                            }
                        ]
                    }
                } else {
                    orgChartData = {
                        name: name || 'w',
                        attributes: {
                            ...rest,
                            department: department || '',
                            designation: designation || '',
                            eid: id,
                            positionId: positionId || '',
                            occupiedBy: occupiedBy || '',
                            isCollapsed: true
                        },
                    }
                }

                setOrgData(orgChartData);
            }else{
                setOrgData(null);
            }
        }
        catch (error) {
            console.error("Getting error in manager data", error);
            //return null;
        }
        finally {
            setIsLoading(false);
        }
    };


    const getEmployeeChildrensData = async (id,pid,toggleNode) => {
        showLoader();
        try {
            const res = await APICall(getEmployeeChildrens, "POST", {
                // EID: 27531 || id,
                EID:requestType === 'employee'? id:undefined,
                PID:requestType === 'position'? pid:undefined,
                RequestOption: requestType
            });

            const { data, status } = res || {};

            if (status === 0 && (data || [])?.length > 0) {
                const newChildData = await addChildByEid(id, data);
                setOrgData(newChildData);
                toggleNode();
            }
        } catch (error) {
            console.error('error', error);
            //return null;
        }
        finally {
            hideLoader();
        }
    };

    const getEmployeeHistoryGrid = async (event, posId) => {
        event.stopPropagation();
        const res = await APICall(getEmployeeGridData, "POST", {
            PID: posId,
            RequestOption: "position"
        });

        const { data, status } = res || {};

        if (status === 0 && data) {
            setHistoryData(data);
            setOpenModal(true);
            // const newChildData = await addChildByEid(id, data);

            // console.log('childData', orgData, data, newChildData, toggleNode);

            // setOrgData(newChildData);
            // toggleNode();
        }
    }

    const handleToggleData = (nodeDatum, toggleNode) => {
        const isCollapsed = nodeDatum?.attributes?.isCollapsed;

        const { eid, pid } = nodeDatum?.attributes;
         //console.log("nodedtm",nodeDatum?.attributes)
        if (isCollapsed) {
            getEmployeeChildrensData(eid,pid, () => { });
        } else {
            const tempData = addChildByEid(nodeDatum?.attributes?.eid, []);
            setOrgData(tempData);
            // toggleNode();
        }
    }

    const handleMrfRedirection = (posId, pId, mrfId) => {
        let stateData;

        if (mrfId) {
            stateData = { TId: mrfId };
        } else {
            stateData = {
                posId: {
                    label: posId,
                    value: pId
                }
            }
        }
        navigate("/mrf", { state: stateData });

        // {label: '60026882', value: 46483}
        // {TId: 39}
    }

    useEffect(() => {
        if (treeContainerRef.current && shouldRecenterTreeRef.current) {
            shouldRecenterTreeRef.current = false;
            const dimensions = treeContainerRef.current.getBoundingClientRect();
            setTreeTranslate({
                x: dimensions.width / 2,
                y: dimensions.height / 6,
            });
        }
    }, [orgData]);

    const loadOptionsDebounced = useCallback(
        useDebounceFunc(async(e: string, callback: (options: any) => void) => {
            if (e?.length >= 2) {
                let requestParams = {
                    InitiatorId: userDetails?.Id,
                    InitiatorRoleId:currentRoleId?.value,
                    RequestOption: requestType,
                    searchString: e,
                };
                const response = await APICall(
                    getEmployeeStructureDetails,
                    "POST",
                    requestParams
                );
    
                if (response.data && response.data?.length > 0) {
                    let options = await response.data;
    
                    options = await options?.map((ele) => ({
                        label: ele.label,
                        value: ele.id,
                    }));
    
                    await callback(options);
                }
                else if(response.data && response.data?.length <= 0 ){
                    await callback(null);

                }
            }
        }, 500),
        [requestType]
    );

    const getEmployeeDetails = async (e, callback) => {
        if (e.length >= 2) {
            let requestParams = {
                InitiatorId: userDetails?.Id,
                InitiatorRoleId:currentRoleId?.value,
                RequestOption: requestType,
                searchString: e,
            };
            const response = await APICall(
                getEmployeeStructureDetails,
                "POST",
                requestParams
            );

            if (response.data && response.data.length > 0) {
                let options = await response.data;

                options = await options?.map((ele) => ({
                    label: ele.label,
                    value: ele.id,
                }));

                await callback(options);
            }
        }
    };

    const handleChangeEmployee = (event) => {
        setEmployeeSearch(event);
    }

    const handleExport = (event) => {
        const elements = document.querySelectorAll('.rd3t-leaf-node');
      
        let minX = Infinity;
        let maxX = -Infinity;
        elements.forEach(element => {
            const transformAttr = element.getAttribute('transform');
            const match = transformAttr.match(/translate\(([-\d.]+),\s*([-\d.]+)\)/);
            if (match) {
                const translateX = parseFloat(match[1]);
                minX = Math.min(minX, translateX);
                maxX = Math.max(maxX, translateX);
            }
        });
        const xDifference = maxX - minX;
        let zoomValue = 1;
        if (elements.length === 0) {
            zoomValue = 1;
        }
        else if (xDifference <= 1000) {
            zoomValue = 0.7;
        }
        else if (xDifference <= 4000) {
            zoomValue = 0.3;
        }
        else {
            zoomValue = 0.1;
        }
        setZoom(zoomValue);
        setPrintableNameLogo((prev) => !prev);
            setTimeout(
                ()=>{
                    if (event) {
                        printDiv(event);
                    }
                    else {
                        captureDivAsImage();
                    }
                    setZoom(1);
                    setPrintableNameLogo((prev) => !prev);
                }
                ,100)
          
    };

    //console.log('orgData', orgData);


    // useEffect(() => {
    //     const recenterTree = async () => {
    //         if (treeContainerRef.current && shouldRecenterTreeRef.current) {
    //             shouldRecenterTreeRef.current = false;
    //             showLoader(); // Show loader before performing operations
    //             try {
    //                 await new Promise(resolve => setTimeout(resolve, 0)); // Simulating asynchronous operation with 0ms delay
    //                 const dimensions = treeContainerRef.current.getBoundingClientRect();

    //                 setTreeTranslate({
    //                     x: dimensions.width / 2,
    //                     y: dimensions.height / 6,
    //                 });
    //             } catch (error) {
    //                 console.error("Error in recenterTree:", error);
    //             } finally {
    //                 hideLoader(); // Hide loader after operations complete
    //             }
    //         }
    //     };

    //     recenterTree();
    // }, [orgData]);

    useEffect(() => {
        getUserIWithManager();
    }, [requestType, employeeSearch]);
    useEffect(() => {
        const scrollToTop = () => {
            window.scrollTo(0, 0);
        };
        const timeoutId = setTimeout(scrollToTop, 100);
        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    const renderForeignObjectNode = ({
        nodeDatum,
        toggleNode,
        foreignObjectProps
    }) => {
        const { toBeHired, mrfid, vacancyStatus, mainNode, isIndirectReportee, eid, designation, department, positionId, occupiedBy, pid,lwd ,childrenCount} = nodeDatum?.attributes || {};
        const isBuHR = currentRoleId?.code === 'BUHR';
        const isMrfExist = mrfid || (toBeHired === 'Yes' && vacancyStatus === 'Vacant');
        return (
            <g>
                {/* `foreignObject` requires width & height to be explicitly set. */}
                <foreignObject {...foreignObjectProps}>
                    <div className={`org-strcuture-card flex-wrap col-md-12 ${isIndirectReportee && 'indirect_employee'} ${(proxyEmployeeId || EmployeeId) === eid && 'current_employee'}`}
                        onClick={mainNode ? () => {} : () => handleToggleData(nodeDatum, toggleNode)}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            //border: '1px solid #191919',
                            color: ((proxyEmployeeId || EmployeeId) === eid) ? '#FFFFFF' : '#000000',
                            backgroundColor: ((proxyEmployeeId || EmployeeId) === eid) ? '#2c2b7c' : (isIndirectReportee ? '#FFF67E' : '#fff'),
                            //backgroundColor: '#fff',
                            borderRadius: '8px',
                            padding: '4px 8px',
                            boxShadow: '0px 11px 6px -6px rgba(0,0,0,0.77)',
                        }}>
                        <div className='row w-100 m-0'>
                            <div className={`${requestType==="employee" && (mainNode||childrenCount)?"col-md-9": requestType==="position"?"col-md-9":"col-md-12"} pl-0`} style={{ paddingRight: '10px' }}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                    <Base64Img id={eid} />
                                    {requestType === 'employee' ? (<div className='org-employee-data'>
                                        <div>{nodeDatum.name}</div>
                                        <div>Designation : {designation}</div>
                                        <div>Department : {department}</div>
                                        {lwd
                                            && (
                                                (currentRoleId.code !== "Employee") || 
                                                (currentRoleId.code === "Employee" && eid === (proxyEmployeeId || EmployeeId))
                                            )
                                            &&(<div>Last Working Date : {lwd}</div>)}  
                                    </div>) : (
                                        <div className='org-employee-data'>
                                            <div>Position ID : {positionId}</div>
                                            <div>Designation : {designation}</div>
                                            <div>Occupied By : {occupiedBy}</div>
                                            {mrfid &&(<div>Mrf ID : {mrfid}</div>)}                                            
                                        </div>
                                    )}
                                </div>
                            </div>
                            {requestType === 'employee' && (
                                <div className='col-md-3 px-1 position-print'>
                                    <div className='d-flex flex-column justify-content-between position-emp-data h-100'>
                                   
                                        
                                             <div className='d-flex align-item-center justify-content-end'>
                                             {mainNode &&(
                                            <i className="fa-solid fa-angles-up mt-1"onClick={() => getUserIWithManager(eid,pid,true)} style={{ fontSize: 14 }}></i>
                                             )}</div>
                                        
                                        
                                          
                                         
                                        <div className='d-flex align-item-center justify-content-end'>
                                         {!mainNode &&  childrenCount > 0 && (
                                        <i className="fa-solid fa-angles-down mt-1" onClick={() => handleToggleData(nodeDatum, toggleNode)} style={{ fontSize: 14 }}></i> 
                                           )}  </div>
                                        
                                         
                                        

                                        
                                    </div>
                                </div>
                            )}
                            {requestType === 'position' && (
                                <div className='col-md-3 px-1 position-print'>
                                    <div className='d-flex flex-column justify-content-between position-emp-data h-100'>
                                        <div className='d-flex align-item-center justify-content-end'>
                                        {mainNode &&(
                                            
                                            <i className="fa-solid fa-angles-up mt-1"onClick={() => getUserIWithManager(eid,pid,true)} style={{ fontSize: 14 }}></i>
                                        
                                        )}
                                        {isBuHR && isMrfExist && (
                                            <div onClick={() => handleMrfRedirection(positionId, pid, mrfid)} className='ml-2 text-danger font-weight-bold text-nowrap'>
                                                Open Requisition
                                            </div>
                                        )}
                                        </div>
                                        <div className='d-flex justify-content-end'>
                                            <i className="fa fa-eye mt-1" onClick={(e) => getEmployeeHistoryGrid(e, pid)} style={{ fontSize: 12 }}></i>
                                        </div>
                                        <div className='d-flex justify-content-end'>
                                        {!mainNode &&  childrenCount > 0 && (
                                        <i className="fa-solid fa-angles-down mt-1" onClick={() => handleToggleData(nodeDatum, toggleNode)} style={{ fontSize: 14 }}></i> 
                                           )}
                                            <Button className={`py-0 px-0 ml-1 ${(proxyEmployeeId || EmployeeId) === eid && 'text-white'}`} style={{ fontSize: 12 }} aria-describedby={itemId} variant="contained" onClick={(e) => handleClick(e, nodeDatum)}>
                                                Read More
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </foreignObject>
            </g>
        )
    }

    // const nodeSize = { x: 450, y: 200 };
    // const foreignObjectProps = { width: nodeSize.x, height: nodeSize.y, x: -200, y: -20 };

    //const nodeSize = { x: 400, y: 200 };
    //const foreignObjectProps = { width: nodeSize.x, height: nodeSize.y, x: -200, y: -20 };
    const popover = (
        <RPopover className="custom-popover">
            <RPopover.Body>
                <ul>
                    <li>
                        <div style={{ display: "inline-block" }}>
                            <a
                                style={{
                                    textDecoration: "none",
                                }}
                                onClick={(event) => handleExport(event)}
                            >
                                Export to PDF
                            </a>
                        </div>
                    </li>
                    <li>
                        <div style={{ display: "inline-block" }}>
                            <a
                                style={{
                                    textDecoration: "none",
                                }}

                                onClick={() => handleExport(null)}
                            >
                                Export to PPT
                            </a>
                        </div>
                    </li>
                </ul>
            </RPopover.Body>
        </RPopover>);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className='px-4 py-2'>
            {/* <div className='row mb-2 mt-5'> */}
            <div className='row mb-2'>
                <div className='col-md-6'>
                    <div className='d-flex flex-wrap align-items-center'>
                        <Button variant="primary" size="sm" className='mr-4' onClick={() => { navigate("/home") }}><i className="fa-solid fa-arrow-left mr-2" /> Back</Button>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={requestType}
                            onChange={handleChange}
                        >
                            <FormControlLabel className='mb-0' value="employee" control={<Radio size='small' />} label="Employee" />
                            {interfacePermissionRecord?.some(
                            (e) => e.interfaceName === "Organization_Position" && (e.name === "View" || e.name === "Edit")
                            ) &&
                            <FormControlLabel className='mb-0' value="position" control={<Radio size='small' />} label="Position" />
                             } 
                             </RadioGroup>
                    </div>
                </div>
                <div className='col-md-6 d-flex align-items-center'>
                    <div className='d-flex flex-wrap align-items-center justify-content-end w-100'>
                        {
                        (currentRoleId.code !== "Employee") &&
                        <div className='search_employee'>
                            <SelectForm
                                isClearable
                                isSearchable
                                async
                                options={loadOptionsDebounced}
                                placeholder="Search User"
                                isDisabled={false}
                                value={employeeSearch}
                                onChange={(event) => {
                                    handleChangeEmployee(event);
                                }}
                                isMulti={false}
                                noIndicator={false}
                                noSeparator={false}
                            />
                        </div>
                        }

                        <OverlayTrigger
                            trigger="click"
                            placement="bottom"
                            overlay={popover}
                            rootClose
                        >
                            <div className='org-data-print ml-2 d-flex justify-content-center align-items-center'>
                                <i className="fa-solid fa-print text-white" />
                            </div>
                        </OverlayTrigger>
                    </div>
                </div>
            </div>
            <div id="printableContent">
                {!isEmpty(orgData) && (
                    <div className=''>
                        {/* {printableNameLogo && ( */}
                        <div className={`${printableNameLogo ? 'd-flex' : 'd-none'} align-items-center justify-content-sm-between mx-3 my-3`}>
                    <h2 className='my-2' style={{color:'#00008B',textDecoration: 'underline', textUnderlineOffset: '8px'}}>{`${requestType.toUpperCase()} STRUCTURE`}</h2>
                      <img
                      loading='eager'
                      style={{ width: '100px'}}
                      src={FinalBlueLogo}
                      alt={"FinalBlueLogo"}
                    /> 
                    </div>
                    {/* )} */}
                    <div ref={treeContainerRef} className='row mx-0' id="treeWrapper" style={{ width: '100%', height: '600px' }}>
                        <Tree data={orgData} translate={treeTranslate} pathFunc='step'
                            rootNodeClassName="node__root"
                            branchNodeClassName="node__branch"
                            leafNodeClassName="node__leaf"
                            orientation='vertical'
                            renderCustomNodeElement={(rd3tProps) =>
                                renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
                            }
                            // initialDepth={initialDepth}
                            separation={treeConfigs.separation}
                            scaleExtent={{ max: 10, min: 0.1 }}
                            zoom={zoom}
        
                        />
                    </div>
                    </div>
                )}
                {open &&
                    (
                        <Popover
                            id={itemId}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            onClose={handleClose}
                        >
                            <div>
                                {popoverData.map((val) => (
                                    <Typography className='py-1 px-2' style={{ fontSize: 12 }}>{val.label} : {nodeData[val.key] || '-'}</Typography>
                                ))}
                            </div>
                        </Popover>
                    )}
            </div>
            {openModal && <Modal
                show={openModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className='d-flex justify-content-end mb-2' style={{ cursor: 'pointer' }}>
                        <i className="fa fa-close" style={{ fontSize: 20 }} onClick={handleCloseModal}></i>
                    </div>
                    <Table className='history-grid_rs' striped bordered hover>
                        <thead>
                            <tr>
                                <th>Employee Name</th>
                                <th>From Date</th>
                                <th>End Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {historyData.map((val) => (
                                <tr>
                                    <td>{val.employeeName || '-'}</td>
                                    <td>{val?.fromDate ? moment(val.fromDate, "DD-MM-YYYY HH:mm:ss").format("DD-MMM-YYYY") : '-'}</td>
                                    <td>{val?.toDate ? moment(val.toDate, "DD-MM-YYYY HH:mm:ss").format("DD-MMM-YYYY") : '-'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>}
            <style>{printStylesOrg}</style>
        </div>
    )
}

export default ReportingStructure;