import React, { useContext, useRef, useState, useEffect } from "react";
import QR from "../QRComponent/QR";
import InputForm from "../../Components/InputForm/InputForm";
import AccordianCustom1 from "../DocumentScreen/AccordianCustom1";
import SelectForm from "../../Components/SelectForm/SelectForm";
import defaultProfileImgPic from "../../Assets/Images/adhars.jpg";
import { Modal } from "react-bootstrap";
import "./skilltest.css";
import { useNavigate } from "react-router-dom";
import {
  GetPositionDropdownData,
  InsertUpdateAssociateDetails,
  GetDropdownData,
  InsertUpdateTokenIssuanceDetails,
  GetPositionDetailsByPositionId,
  RCBMGetDropdownData,
  GetSkillTestData,
  GetAssociateDetailsById,
  GetSkillTestDataById,
  InsertUpdateAssociatesSkillTest,
  GetAssociatesDocument,
  GetAssociateDropdownData,
  GetWorkflowStages,
  InsertUpdateCandidateWorkflowInstanceTasks,
  GetStageNameById,
  GetStandardMonthlyWage,
  GetSkillAmount,
  GetActiveAppraisalCycle,
  GetStandardRehireWage,
  GetMinimumWage,
  isOrganisationInfo,
} from "../../Helpers/APIEndPoints/AssociateEndPoints";
import defaultProfileImgPic1 from "../../Assets/Images/default+_pic.jpg";
import { APICall } from "../../Helpers/APICalls";
import { Tooltip } from "@mui/material";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import notify from "../../Helpers/ToastNotification";
import { LoaderContext } from "../../Helpers/Context/Context";
import { ExitStatus } from "typescript";
import WernerSkillTest from "./WernerSkillTest";
import OrganizationInformation from "../../Components/OrganizationInformation/OrganizationInformation";
import RehireCandidateDetails from "../../Components/RehireCandidateDetails/RehireCandidateDetails";
import SecurityAccordian from "../SecurityScreenings/SecurityAccordian";
import async from "react-select/dist/declarations/src/async/index";
import moment from "moment";
import { GetConfigMasterData } from "../../Helpers/APIEndPoints/EndPoints";

interface CandidateDetails {
  name?: string;
  mobileNumber?: string;
  category?: string | number | null;
  skillCategory?: string | number | null;
  workArea?: string | number | null;
}

const SkillTest = () => {
  const [startScan, setStartScan] = useState(false);
  const [scanData, setScanData] = useState(null);
  const [isaccordiandisable, setIsAccordiandisable] = useState<any>(true);
  const [formData, setFormData] = useState<IFormData>({});
  const [tokenData, setTokenData] = useState<ITokenData>({});
  const [positionText, setPositionText] = useState<any>({ name: "", id: 0 });
  const [business, setBusiness] = useState<any>({ name: "", id: 0 });
  const [sbu, setSBU] = useState<any>({ name: "", id: 0 });
  const [division, setDivision] = useState<any>({ name: "", id: 0 });
  const [subdivision, setsubDivision] = useState<any>({ name: "", id: 0 });
  const [Function, setFunction] = useState<any>(null);
  const [Department, setDepartment] = useState<any>({ name: "", id: 0 });
  const [Section, setSection] = useState<any>({ name: "", id: 0 });
  const [subSection, setSubSection] = useState<any>({ name: "", id: 0 });
  const [empClass, setEmpClass] = useState<any>({ name: "", id: 0 });
  const [JobLevel, setJobLevel] = useState<any>({ name: "", id: 0 });
  const [jobBand, setJobBand] = useState<any>({ name: "", id: 0 });
  const [designation, setDesignation] = useState<any>({ name: "", id: 0 });
  const [jobCode, setJobCode] = useState<any>({ name: "", id: 0 });
  const [skillCategory, setSkillCategory] = useState<any>({ name: "", id: 0 });
  const [reportingManager, setReportingManager] = useState<any>({
    name: "",
    id: 0,
  });
  const [dashboard, setdashboard] = useState([]);
  const [dashboardPageSize, setDashboardPageSize] = useState(10);
  const [dashboardStart, setDashboardStart] = useState(0);
  const [dashboardSortColumn, setDashboardSortColumn] = useState<any>("");
  const [dashboardSortDirection, setDashboardSortDirection] = useState("");
  const [dashboardCount, setDashboardCount] = useState(0);
  const [isTrainee, setIsTrainee] = React.useState(0);
  const [isActive, setisActive] = useState<any>(true);
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  const [DecisionOption, setDecisionOption] = useState([
    // { label: "Draft", value: 1 },
    // { label: "Issue Token", value: 2 },
    { label: "Rejected", value: 3 },
    { label: "Shortlisted", value: 4 },
  ]);
  const [decision, setDecision] = useState<any>(null);
  const [RejectionReasonOption, setRejectionReasonOption] = useState<any>([]);
  const [Rejection, setRejection] = useState<any>(null);
  const [fielddisable, setfielddisable] = useState<any>(true);
  const [Remark, setRemark] = useState<any>(null);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [tokenId, settokenId] = useState<any>(null);
  const [CategoryOption, setCategoryOption] = useState([
    { label: "On roll Experience", value: 1 },
    { label: "On roll Fresher", value: 2 },
    { label: "On roll Rehire", value: 3 },
    { label: "Contract Worker", value: 4 },
    { label: "Scheme Trainee", value: 5 },
    { label: "Scheme Apprentice", value: 6 },
    { label: "Experience Apprentice", value: 7 },
  ]);
  const [Category, setCategory] = useState<any>(null);
  const [skillCategoryOptions, setSkillCategoryOption] = useState<any>([]);
  const [skillCategorys, setSkillCategorys] = useState(null);
  const [workAreaOption, setWorkAreaOption] = useState<any>([]);
  const [workArea, setWorkArea] = useState<any>(null);
  const [skillCategoryName, setskillCategoryName] = useState<any>(null);
  const [isRefresh, setIsRefresh] = useState<any>(false);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [skill, setSkill] = useState<any>(null);
  const [target, setTarget] = useState<any>(null);
  const [actualScore, setActualScore] = useState<any>(null);
  const [percentage, setPercentage] = useState<any>(null);
  const [proficiency, setProficiency] = useState<any>(null);
  const [RatingOption, setRatingOption] = useState([
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
  ]);
  const [Rating, setRating] = useState<any>(null);
  const [StatusOption, setStatusOption] = useState([
    { label: "Passed", value: 1 },
    { label: "Failed", value: 2 },
  ]);
  const [Status, setStatus] = useState<any>(null);
  const [rowId, setRowId] = useState<any>(null);
  const [jobId, setJobId] = useState<any>(null);
  const [avgPercentage, setAvgPercentage] = useState<any>(null);
  const [avgProficiency, setAvgProficiency] = useState<any>(null);
  const [avgRating, setAvgRating] = useState<any>(null);
  const [avgStatus, setAvgStatus] = useState<any>(null);
  const [candidateTypeDisable, setCandidateTypeDisable] = useState<any>(true);
  const [IsProcessing, setIsProcessing] = useState<any>(null);
  const [currentStageName, setCurrentStageName] = useState(null);
  const [showcurrentstage, setShowCurrentStage] = useState<any>(false);
  const [isdecisiondisable, setIsDecisionDisable] = useState<any>(false);
  const [candidateimage, setCandidateimage] = useState(null);
  const [disableDecision, setdisableDecision] = useState(true);
  const [payrollLocation, setPayRolllocation] = useState<string>("");
  const [modalForGunScanner, setModalForGunScanner] = useState<boolean>(false);
  const [barcode, setBarcode] = useState<string>("");
  const [disableSkills, setdisableSkills] = useState<boolean>(true);
  const [editRowId, setEditRowId] = useState(null);
  const [candidateDetails, setCandidateDetails] = useState<CandidateDetails>(
    {}
  );
  const [LegalEntity, setLegalEntity] = useState<any>({
    name: "",
    id: 0,
    code: "",
  });
  const [QRCode, setQRCode] = useState<any>(null);
  const [currentId, setcurrentId] = useState<any>(null);
  const [disabledSkilltestDashboard, setdisabledSkilltestDashboard] =
    useState<any>(null);
  const [showModal1, setShowModal1] = useState(false);
  const navigate = useNavigate();
  const [positionbutton, setpositionbutton] = useState(false);
  const [currentStageId, setCurrentStageId] = useState(null);
  const [StandardWage, setStandardWage] = useState<any>(null);
  const [OfferedMonthlyWage, setOfferedMonthlyWage] = useState<any>(null);
  const [OfferedAnnualWage, setOfferedAnnualWage] = useState<any>(null);
  const [hike, setHike] = useState<any>(null);
  const [incrementAmount, setIncrementAmount] = useState<any>(0);
  const [showWageSection, setShowWageSection] = useState(true);
  const [showRehireDetailsSection, setShowRehireDetailsSection] =
    useState<any>(false);
  const [previousEmployeeId, setPreviousEmployeeId] = useState<any>(null);
  const [IsOrganisationunitpopup, setisOrganisationunitpopup] =
    useState<any>(false);
  const [showWCLSection, setShowWCLSection] = useState(false);
  const [WCLDetails, setWCLDetails] = useState<WCLDetails>({});
  const [WCLOfferedMonthlyWage, setWCLOfferedMonthlyWage] = useState<any>(null);
  const [WCLOfferedAnnualWage, setWCLOfferedAnnualWage] = useState<any>(null);
  const [decisionValue, setDecisionValue] = useState<any>(null);

  type FormErrors = {
    Rejection?: string;
    Decision?: string;
  };
  interface IFormData {
    position_Id?: IOptions | boolean | any;
    categoryId?: any;
  }
  interface ITokenData {
    candidate_Id?: IOptions | boolean | any;
  }

  interface IOptions {
    label?: string;
    value?: number | string;
    code?: string;
  }

  interface WCLDetails {
    min?: number | any;
    med?: number | any;
    max?: number | any;
  }

  useEffect(() => {
    (async () => {
      await GetRejectionReason();
    })();
  }, []);

  useEffect(() => {
    if (decision?.value == "3") {
      setfielddisable(false);
    } else {
      setfielddisable(true);
    }
  }, [decision]);

  useEffect(() => {
    const helperFunction = async () => {
      if (formData?.position_Id && formData?.position_Id !== null) {
        await getPositionbyId();
        setpositionbutton(true);
      } else {
        setIsAccordiandisable(true);
      }
    };
    helperFunction();
  }, [formData]);

  useEffect(() => {
    if (designation.id != 0) {
      SkillCategoryFun(designation.id);
    }
  }, [designation]);

  useEffect(() => {
    const helper = async () => {
      if (scanData?.QRCodeExpiry) {
        let isAfterTargetDate = isTimeAfterTargetDate(scanData?.QRCodeExpiry);

        if (isAfterTargetDate) {
          setShowModal1(true);
        } else {
          setShowModal1(false);
          let isOrganisationInf = await isOrganisationunit(
            scanData.CandidateId
          );
          // if (!isOrganisationInf) {
          //   setisOrganisationunitpopup(true);
          // } else {
          //   setisOrganisationunitpopup(false);

          await LoadUserData(scanData.CandidateId);
          setStartScan(false);
          //}
        }

        // await getSkillTestDetails(skillCategory?.id ,scanData.CandidateId);
      }
    };
    helper();
  }, [scanData, isRefresh]);

  function isTimeAfterTargetDate(targetDate) {
    // Convert the given date string to a JavaScript Date object
    const targetDateTime = new Date(targetDate);

    // Get the current date and time
    const currentDate = new Date();

    // Check if the current date and time are after the target date and time
    return currentDate > targetDateTime;
  }

  const isOrganisationunit = async (AssociateId: any) => {
    try {
      const { data } = await APICall(isOrganisationInfo, "POST", {
        TokenId: AssociateId,
        UserId: userDetails.Id,
      });

      if (data !== null) {
        console.log(data, "Organisationunitdata");
        return data.isExist;
      }
    } catch (error) {
      // Handle any errors that occur during the API call
      console.error("Error during API call:", error);
      return false; // or another appropriate default value
    }
  };

  useEffect(() => {
    if (target != 0) {
      //To set Percentage
      //let res=((target-actualScore)/target)*100
      let profRes = (actualScore / target) * 100;
      if (profRes >= 0) {
        setPercentage(profRes.toFixed(2));
      } else {
        setPercentage("");
      }

      //To set Proficiency, Rating & status
      //let profRes=(actualScore/target)*100

      if (!actualScore) {
        setProficiency("");
        setRating("");
        setStatus("");
      } else if (profRes >= 90) {
        setProficiency("Master");
        setRating(4);
        setStatus("Pass");
      } else if (profRes >= 75 && profRes < 90) {
        setProficiency("Expert");
        setRating(3);
        setStatus("Pass");
      } else if (profRes >= 60 && profRes < 75) {
        setProficiency("Beginner");
        setRating(2);
        setStatus("Failed");
      } else if (profRes >= 0 && profRes < 59) {
        setProficiency("Learner");
        setRating(1);
        setStatus("Failed");
      } else {
        setProficiency("");
        setRating("");
        setStatus("");
      }
    }
  }, [actualScore]);

  useEffect(() => {
    if (isTrainee === 1) {
      setDecision(DecisionOption[1]);
    } else {
      setDecision(DecisionOption[0]);
    }
  }, [isTrainee]);

  useEffect(() => {
    if (avgPercentage) {
      if (currentId === 4) {
        setdisableDecision(false);
      } else {
        setdisableDecision(true);
      }

      // } else {
      //   setdisableDecision(true);
    }
  }, [avgPercentage]);

  useEffect(() => {
    const handleKeyDown = async (evt: any) => {
      try {
        if (evt.code === "Enter") {
          if (barcode) await handleBarcode(barcode);
          return;
        }
        if (evt.key !== "Shift") setBarcode((prev) => prev + evt.key);
      } catch (error) {
        hideLoader();
      }
    };
    if (modalForGunScanner) {
      showLoader();
      document.addEventListener("keydown", handleKeyDown);
      hideLoader();
    }
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [barcode, modalForGunScanner]);

  async function handleBarcode(scannedBarcode: any) {
    const object = JSON.parse(scannedBarcode);
    //console.log(object);
    setScanData(object || null);
    setBarcode("");
    setModalForGunScanner(false);
    await handleScanData(scannedBarcode);
    hideLoader();
  }
  function onTokenChange(event, code) {
    try {
      candidateProfile(event.id);
      setTokenData((prev) => {
        return { ...prev, [code]: event };
      });
    } catch (error) {
    } finally {
    }
  }

  const handleScanData = (data) => {
    debugger;
    //console.log(data);
    setScanData(JSON.parse(data));
    const response = JSON.parse(data);
    candidateProfile(response?.CandidateId);
  };
  const candidateProfile = async (id: any) => {
    const response = await APICall(GetAssociatesDocument, "POST", {
      AssociatesDetailsId: id,
      DocumentTypeId: 8,
    });

    if (response.data !== null) {
      //console.log(response.data, "console");
      setCandidateimage(response?.data?.documentDataObject);
    }
  };
  const getLabelForStage = (stageID) => {
    switch (stageID) {
      case 1:
        return "Candidate Authentication";
      case 2:
        return "Document Screen";
      case 3:
        return "Medical  Screen";
      case 4:
        return "Skill Test  Screen";
      case 5:
        return "UAN  Screen";
      case 6:
        return "Appointment  Screen";
      // Add more cases for additional stages
      default:
        return "EP";
    }
  };
  const handleCandidateTypeChange = (type) => {
    setIsTrainee(type);
  };
  async function fetchDropdown(searchText = "", code = "", value = null) {
    try {
      const response = await APICall(
        GetPositionDropdownData,
        "POST",
        {
          SearchText: searchText,
        },
        true
      );

      if (response?.status === 0) {
        if (response?.data) {
          return response?.data;
        } else {
          return [];
        }
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }
  async function fetchCandidateDropdown(
    searchText = "",
    code = "",
    value = null
  ) {
    try {
      const response = await APICall(
        GetAssociateDropdownData,
        "POST",
        {
          SearchText: searchText,
          userId: userDetails.Id,
        },
        true
      );

      if (response?.status === 0) {
        if (response?.data) {
          return response?.data;
        } else {
          return [];
        }
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }
  async function handleSearchForAsyncDropdown(event, code, callback) {
    try {
      if (event?.length >= 3) {
        const data = await fetchDropdown(event, code);
        callback(data);
      } else {
        callback([]);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function handleSearchForCandidateIdDropdown(event, code, callback) {
    try {
      if (event?.length >= 1) {
        const data = await fetchCandidateDropdown(event, code);
        callback(data);
      } else {
        callback([]);
      }
    } catch (error) {
      console.log(error);
    }
  }
  function onChange(event, code) {
    try {
      setFormData((prev) => {
        return { ...prev, [code]: event };
      });
    } catch (error) {
    } finally {
    }
  }
  const GetRejectionReason = async () => {
    const { data } = await APICall(GetDropdownData, "POST", {
      dropdownName: "Document",
    });

    if (data !== null && data.length > 0) {
      let reason = data.map((element) => ({
        value: element.value,
        label: element.label,
      }));
      setRejectionReasonOption(reason);
    }
  };

  const submitCandidateType = async () => {
    let requestParams = {
      Id: tokenId || 0,
      Section: "Category",
      IsTrainee: isTrainee,
      IsActive: isActive,
      CreatedBy: userDetails?.Id,
    };
    const response = await APICall(
      InsertUpdateAssociateDetails,
      "POST",
      requestParams
    );

    if (response.status === 0) {
      notify(
        0,
        `The data for Candidate ID ${tokenId} has been successfully submitted.`
      );
    } else {
      notify(1, "Data not inserted");
    }
  };

  const submitDecision = async () => {
    let errors: FormErrors = {};
    let isValid = true;
    if (decision.value == "3") {
      // if (Rejection == null || Rejection?.value === "") {
      //   errors.Rejection = "Reason of regection is required";
      //   isValid = false;
      // }
    }

    if (!decision || !decision.value) {
      errors.Decision = "Decision is required";
      isValid = false;
    }

    if (isValid) {
      let stageId = null;

      let getWorkFlowStages = await APICall(GetWorkflowStages, "POST", {
        employeeClass: empClass.code,
        CategoryId: formData?.categoryId,
      });

      if (getWorkFlowStages && getWorkFlowStages.status === 0) {
        if (getWorkFlowStages.data && getWorkFlowStages.data.length > 0) {
          let stages = getWorkFlowStages.data;
          let currentStage = stages.find((x) => x.id === currentStageId);
          if (currentStage) {
            let nextStageId = stages.find(
              (x) => x.sequence > currentStage.sequence
            );
            if (nextStageId) {
              stageId = nextStageId.id;
            }
          }
        }
      }

      let requestParams = {
        AssociateId: tokenId || 0,
        StageId: decision.value == "3" ? currentStageId : stageId,
        WorkflowStageId: 6,
        Decision: decision?.value,
        RejectionReason: null, //Rejection?.value,
        Remarks: Remark,
        IsActive: isActive,
        CreatedBy: userDetails?.Id,
        IsProcessing: IsProcessing || 0,
      };
      const response = await APICall(
        InsertUpdateTokenIssuanceDetails,
        "POST",
        requestParams
      );

      if (response.status === 0) {
        if (decision?.value === 4) {
          let currentStageId = 0;
          let stages = getWorkFlowStages.data;
          let currentStage = stages.find((x) => x.id === stageId);
          if (currentStage) {
            currentStageId = currentStage.id;
            let nextStageId = stages.find(
              (x) => x.sequence > currentStage.sequence
            );
            if (nextStageId) {
              stageId = nextStageId.id;
            }
          }

          const insertUpdateCandidateWorkflowInstanceTasks = await APICall(
            InsertUpdateCandidateWorkflowInstanceTasks,
            "POST",
            {
              Id: scanData?.CandidateId || tokenId,
              CurrentStagesId: currentStageId,
              NextStageId: stageId,
              Decision: decision?.value,
              CategoryId: formData?.categoryId,
              CreatedBy: userDetails?.Id || proxyUserDetails?.value,
            }
          );
        }

        notify(
          0,
          `The data for Candidate ID ${tokenId} has been successfully submitted. You can proceed for next candidate`
        );
        window.location.reload();
      } else {
        notify(1, "Data not inserted");
      }
    } else {
      setFormErrors(errors);
    }
  };

  const handleModalClose1 = () => {
    // Close the modal when needed
    setShowModal1(false);
  };
  const getPositionbyId = async () => {
    const response = await APICall(GetPositionDetailsByPositionId, "POST", {
      PositionId: formData?.position_Id.value,
    });
    if (response?.status === 0) {
      //console.log(response?.data, "Sanket");
      setIsAccordiandisable(false);
      setPositionText({
        name: response?.data?.positionText,
        id: response?.data?.positionId,
      });

      setBusiness({
        name: response?.data?.business,
        id: response?.data?.businessId,
      });

      setSBU({
        name: response?.data?.sbu,
        id: response?.data?.sbuId,
      });

      setDivision({
        name: response?.data?.division,
        id: response?.data?.divisionId,
      });

      setsubDivision({
        name: response?.data?.subdivision,
        id: response?.data?.subdivisionId,
      });

      setFunction({
        name: response?.data?.function,
        id: response?.data?.functionId,
      });

      setDepartment({
        name: response?.data?.department,
        id: response?.data?.departmentId,
      });

      setSection({
        name: response?.data?.sectionName,
        id: response?.data?.sectionId,
      });

      setSubSection({
        name: response?.data?.subSection,
        id: response?.data?.subSectionId,
      });

      setEmpClass({
        name: response?.data?.employeeClass,
        id: response?.data?.employeeClassId,
        code: response?.data?.employeeClassCode,
      });

      setJobLevel({
        name: response?.data?.jobLevel,
        id: response?.data?.jobLevelId,
      });

      setJobBand({
        name: response?.data?.jobBand,
        id: response?.data?.jobBandId,
      });

      setDesignation({
        name: response?.data?.designation,
        id: response?.data?.designationId,
      });

      setJobCode({
        name: response?.data?.jobCode,
        id: response?.data?.jobCodeId,
      });

      setSkillCategory({
        name: response?.data?.jobTitles,
        id: response?.data?.jobTitleId,
      });

      setReportingManager({
        name: response?.data?.reportingManager,
        id: response?.data?.reportingManagerId,
      });

      setLegalEntity({
        name: response?.data?.legalEntity,
        id: response?.data?.legalEntityId,
        code: response?.data?.legalEntityCode,
      });

      if (response?.data?.employeeClassCode === "CLM") {
        setShowWageSection(false);
      }

      if (
        response?.data?.employeeClassCode === "SchemeTrainee" ||
        response?.data?.employeeClassCode === "SchemeApprentice"
      ) {
        setShowWageSection(true);
      }
      if (response?.data?.employeeClassCode === "ExperienceApprentice") {
        setShowWageSection(true);
      }

      WorkAreafun(response?.data?.positionId);
      setPayRolllocation(response?.data?.payrollLocation || "");
    }
  };

  const WorkAreafun = async (id: any) => {
    debugger;
    const response = await APICall(RCBMGetDropdownData, "POST", {
      dropdownName: "PositionWorkArea",
      PositionId: id,
    });
    // dropdownName : 'PositionWorkArea'
    //PositionId: positionId
    if (response?.data != null) {
      //console.log(response?.data, "wrk");
      if (Array.isArray(response?.data)) {
        // Assuming response.data is an array
        const mappedOptions = response.data.map((item) => ({
          label: item.workAreaName, // Adjust property names based on your actual data structure
          value: item.workAreaId, // Adjust property names based on your actual data structure
        }));
        setWorkArea(response?.data[0]?.workAreaName);

        setWorkAreaOption(mappedOptions);
        //setWorkArea(workArea[0]);

        // setSkillCategoryOption(mappedOptions[0]);  // Assuming you want the first element
      } else {
        console.error("response.data is not an array");
      }
    }
  };
  const SkillCategoryFun = async (id: any) => {
    // showLoader();
    debugger;
    const response = await APICall(RCBMGetDropdownData, "POST", {
      dropdownName: "SkillCategory",
      Id: id,
    });

    //console.log(response?.data, "Skill");
    if (Array.isArray(response?.data)) {
      // Assuming response.data is an array
      const mappedOptions = response.data.map((item) => ({
        label: item.skillCategory, // Adjust property names based on your actual data structure
        value: item.skillCategoryId, // Adjust property names based on your actual data structure
      }));

      // You might want to set the state with the entire array or just a specific element based on your use case
      // If you want to set the state with the entire array
      setSkillCategoryOption(mappedOptions);
      //setSkillCategory(skillCategory[0]);
      // Or if you want to set the state with a specific element
      // setSkillCategoryOption(mappedOptions[0]);  // Assuming you want the first element
    } else {
      // Handle the case where response.data is not an array
      console.error("response.data is not an array");
      // You may want to set a default value or handle it appropriately
    }
    // hideLoader();
  };
  const getSkillTestDetails = async (jobId: any, candidateId: any) => {
    try {
      //showLoader();
      const response = await APICall(GetSkillTestData, "POST", {
        JobId: jobId,
        AssociateId: candidateId,
      });

      showLoader();
      if (response?.status === 0 && response?.data.length > 0) {
        //console.log(response?.data, "Associate");
        setdashboard(response?.data);
        if (response?.data[0].actualScore != null) {
          getWageFinaliation(candidateId);
        }
        setDashboardCount(response?.data[0].count);
        calculateOverallData(response?.data[0].avgPercentage);
      } else {
        setdashboard([]);
        setDashboardCount(0);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const getSkillTestDataById = async (skillId: any, skillTestId: any) => {
    try {
      //showLoader();
      setSkill("");
      setTarget("");
      setActualScore("");
      setPercentage("");
      setProficiency("");
      setRating("");
      setStatus("");
      const response = await APICall(GetSkillTestDataById, "POST", {
        SkillId: skillId,
        SkillTestId: skillTestId,
        JobId: jobId,
      });

      showLoader();
      if (response?.data != null) {
        setSkill(response?.data.skillName);
        setTarget(response?.data.targetScore);
        setActualScore(response?.data.actualScore);
        setPercentage(response?.data.percentage);
        setProficiency(response?.data.proficiency);
        setRating(response?.data.rating);
        setStatus(response?.data.status);
      } else {
        setSkill("");
        setTarget("");
        setActualScore("");
        setPercentage("");
        setProficiency("");
        setRating("");
        setStatus("");
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const LoadUserData = async (id: any) => {
    settokenId(id);
    showLoader();
    const response = await APICall(GetAssociateDetailsById, "POST", {
      Id: id,
      WorkflowStageId: 6,
      CreatedBy: userDetails?.Id,
    });
    console.log(response, "user");
    if (response.data != null) {
      const { data } = response;
      debugger;
      if (data) {
        setCandidateDetails((prev) => ({
          ...prev,
          category: data?.category || "",
          skillCategory: data?.skillCategory || "",
          workArea: data?.workAreaName || "",
          mobileNumber: data?.mobileNumber || "",
          name: data?.name || "",
        }));
      }
      if (response.data.positionId !== null) {
        const position_Ids = await fetchDropdown(
          response?.data?.positionId,
          "co_ordinator"
        );

        const position = position_Ids[0];

        setFormData((prev) => {
          return {
            ...prev,
            position_Id: position,
            categoryId: response?.data?.categoryId,
            currentStageId: response?.data?.currentStagesId,
          };
        });
        setskillCategoryName(response?.data?.skillCategory);
        QRCodeExist(id);
        WorkAreafun(response?.data?.positionId);
        setJobId(response?.data?.jobId);
        getSkillTestDetails(response?.data?.jobId, id);
        // getWageFinaliation();
        setIsDecisionDisable(true);
      }
      setCurrentStageId(response?.data?.currentStagesId);
      if (response.data.isProcessing === true) {
        setIsProcessing(0);
      }
      setDecisionValue(response?.data?.decision);
      setcurrentId(response?.data?.currentStagesId);
      if (response?.data?.currentStagesId !== 4) {
        setdisableDecision(true);
        setCandidateTypeDisable(true);
        setdisabledSkilltestDashboard(true);
      } else {
        setdisabledSkilltestDashboard(false);
      }

      const filteredOptions = CategoryOption.filter(
        (option) => option.value === response?.data?.categoryId
      );
      // setCategory(filteredOptions[0]);
      // setCategory(filteredOptions[0]);
      setCategory(response?.data?.category);
      setShowRehireDetailsSection(response?.data?.isRehire);
      setPreviousEmployeeId(response?.data?.previousEmployeeId);
    }
    hideLoader();
  };

  useEffect(() => {
    const fetchStages = async () => {
      // if (currentStageId != 4) {
      //   let getWorkFlowStages = await APICall(GetStageNameById, "POST", {
      //     CurrentStagesId: currentStageId,
      //   });

      //   let currentStageName = "";
      //   if (getWorkFlowStages && getWorkFlowStages.status === 0) {
      //     if (getWorkFlowStages.data) {
      //       currentStageName = getWorkFlowStages.data.candidateCurrentstage;
      //       setCurrentStageName(currentStageName);
      //       setShowCurrentStage(true);
      //     } else {
      //       setShowCurrentStage(false);
      //     }
      //   }
      // } else {
      //   setShowCurrentStage(false);
      // }
      if (scanData && currentId != 4) {
        console.log(currentId, "current Test");
        let getWorkFlowStages = await APICall(GetStageNameById, "POST", {
          CurrentStagesId: currentId,
        });

        let currentStageName = "";
        if (getWorkFlowStages && getWorkFlowStages.status === 0) {
          if (getWorkFlowStages.data) {
            currentStageName = getWorkFlowStages.data.candidateCurrentstage;
            setCurrentStageName(currentStageName);
            setShowCurrentStage(true);
          }
        }
      } else if (scanData && currentId == 4 && currentId != null) {
        console.log(currentId, "orgcurrentstage");
        let isOrganisationInf = await isOrganisationunit(scanData.CandidateId);
        console.log(isOrganisationInf, "isExist");
        if (!isOrganisationInf) {
          console.log(isOrganisationInf, "isExist1");
          setisOrganisationunitpopup(true);
          setdisabledSkilltestDashboard(true);
          setdisableDecision(true);
        } else {
          setisOrganisationunitpopup(false);
          setdisabledSkilltestDashboard(false);
          setdisableDecision(false);
          // if (currentId != 4) {
          //   let getWorkFlowStages = await APICall(GetStageNameById, "POST", {
          //     CurrentStagesId: currentId,
          //   });

          //   let currentStageName = "";
          //   if (getWorkFlowStages && getWorkFlowStages.status === 0) {
          //     if (getWorkFlowStages.data) {
          //       currentStageName = getWorkFlowStages.data.candidateCurrentstage;
          //       setCurrentStageName(currentStageName);
          //       setShowCurrentStage(true);
          //     }
          //   }
          // }
        }
      }
    };

    fetchStages();
  }, [currentStageId, scanData?.CandidateId, tokenData?.candidate_Id]);

  useEffect(() => {
    let CTC = OfferedMonthlyWage * 12;
    setOfferedAnnualWage(CTC);

    let hike = (OfferedMonthlyWage / StandardWage - 1) * 100;

    if (
      hike !== null &&
      hike !== undefined &&
      StandardWage !== null &&
      StandardWage !== undefined
    ) {
      setHike(hike.toFixed(2));
    } else {
      setHike(0);
    }
  }, [OfferedMonthlyWage]);

  const getWageFinaliation = async (candidateId: any) => {
    const response = await APICall(GetAssociateDetailsById, "POST", {
      Id: scanData?.CandidateId || candidateId,
      WorkflowStageId: 12,
      CreatedBy: userDetails?.Id,
    });

    let candidateDetails = null;
    if (response) {
      //await LoadUserData(response);
      candidateDetails = response.data;
    }

    if (candidateDetails) {
      // 1: If candidate is getting rehired, wage calculation logic is different
      if (candidateDetails.isRehire) {
        let resignDate = moment(candidateDetails.resignDate);

        const currentDate = moment();
        const modifiedDate = currentDate.subtract(365, "days");

        //1.1: candidate has resigned before 365 days.
        if (modifiedDate > resignDate) {
          await getStandardWage(candidateId);
        } else {
          // 1.2: candidate has resigned in last 365 days.
          await getRehireStandardWage(
            resignDate,
            candidateDetails?.skillCategory,
            candidateId
          );
        }
      } else {
        //2: Candidate is New to system
        await getStandardWage(candidateId);
      }
    }
  };

  useEffect(() => {
    if (LegalEntity) {
      // console.log("scanData?.CandidateId: " + scanData?.CandidateId);
      // console.log("tokenData?.candidate_Id: " + tokenData?.candidate_Id);
      if (scanData?.CandidateId || tokenId) {
        getStandardWage(scanData?.CandidateId || tokenId);
      }
    }
  }, [LegalEntity]);

  const getConfigData = async (masterKey) => {
    const response = await APICall(GetConfigMasterData, "POST", {});
    let masterData = [];
    if (response?.status === 0) {
      masterData = response.data;
      let value = masterData?.find(x => x.key === masterKey)?.value;
      return value;
    } else {
      return null;
    }
  };

  const getStandardWage = async (candidateId: any) => {
    const { data } = await APICall(GetStandardMonthlyWage, "POST", {
      Id: scanData?.CandidateId || candidateId,
      //Id: 209,
    });

    let WCLEntitiesResponse = await getConfigData("RCM_WCL_LENs");
    let WCLEntities = [];
    if(WCLEntitiesResponse != null){
      WCLEntities = WCLEntitiesResponse.split(',');
    }

    // console.log("Inside Standard Wage");
    // console.log('LegalEntity?.code: ' + LegalEntity?.code);
    if (WCLEntities.some((x) => x.toString() === LegalEntity?.code)) {
      // console.log("Inside WCL");
      setShowWCLSection(true);
      if (
        data &&
        data.minimunAmount &&
        data.medianAmount &&
        data.maximumAmount
      ) {
        // console.log("WCL DATA: " + data.minimunAmount);
        setWCLDetails({
          min: data.minimunAmount,
          med: data.medianAmount,
          max: data.maximumAmount,
        });
      } else {
        setWCLDetails({
          min: 0,
          med: 0,
          max: 0,
        });
      }
    } else {
      // console.log("outside WCL");
      if (data != null) {
        setStandardWage((prev) => data.standardWage);
        setOfferedMonthlyWage((prev) => data.standardWage);
      } else {
        const response = await APICall(GetMinimumWage, "POST", {
          Id: scanData?.CandidateId || candidateId,
          //Id: 209,
        });

        if (response !== null) {
          // setStandardWage(data.standardWage);
          setStandardWage((prev) => response?.data?.standardWage);
          setOfferedMonthlyWage((prev) => response?.data?.standardWage);
        }
      }
    }
  };

  const getRehireStandardWage = async (
    resignDate,
    skillCategory,
    candidateId
  ) => {
    const { data } = await APICall(GetStandardRehireWage, "POST", {
      Id: scanData?.CandidateId || candidateId,
    });

    if (data !== null && data.standardWage !== 0) {
      // Get active appraisal cycle
      const appraisalCycleData = await APICall(
        GetActiveAppraisalCycle,
        "POST",
        {}
      );

      if (appraisalCycleData.status === 0) {
        if (appraisalCycleData.data.length > 0) {
          let appraisalCycle = appraisalCycleData.data[0];
          let fromDate = appraisalCycle.fromDate;
          let toDate = appraisalCycle.toDate;

          let skillAmount = 0;

          //1: Resign Date is between current active cycle.
          if (moment(fromDate) < resignDate && resignDate < moment(toDate)) {
            setStandardWage(Math.round(data.standardWage / 12));
            setOfferedMonthlyWage(Math.round(data.standardWage / 12));
          } else {
            //2: Resign Date is not between current active cycle. (Candidate has joined again but the appraisal cycle is passed.)
            // extra amount based on the skill category of candidate will be added.
            const skillAmountData = await APICall(GetSkillAmount, "POST", {
              skillCategory: skillCategory,
            });

            if (
              skillAmountData.status === 0 &&
              skillAmountData.data.length > 0
            ) {
              const currentDate = moment();
              let skill = skillAmountData.data.find(
                (x) => moment(x.effectiveDate) < currentDate
              );

              if (skill) {
                skillAmount = skill.wage;
                setIncrementAmount(skillAmount);
              }
            }

            setStandardWage(Math.round(data.standardWage / 12) + skillAmount);
            setOfferedMonthlyWage(
              Math.round(data.standardWage / 12) + skillAmount
            );
          }
        }
      }
    } else {
      const response = await APICall(GetMinimumWage, "POST", {
        Id: scanData?.CandidateId || candidateId,
        //Id: 209,
      });

      if (response !== null) {
        // setStandardWage(data.standardWage);
        setStandardWage((prev) => response.data?.standardWage);
        setOfferedMonthlyWage((prev) => response.data?.standardWage);
      }
    }
  };

  const QRCodeExist = async (id: any) => {
    const response = await APICall(GetAssociatesDocument, "POST", {
      AssociatesDetailsId: id,
      DocumentTypeId: 7,
    });
    //console.log(response?.data);
    if (response.data !== null) {
      setQRCode(response.data?.documentDataObject);
    }
  };

  const gridColumns = [
    {
      name: "id",
      label: "Sr.no",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "skillName",
      label: "Skill",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "targetScore",
      label: "Target Score",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "actualScore",
      label: "Actual Score",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "percentage",
      label: "Percentage",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "proficiency",
      label: "Proficieny",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "rating",
      label: "Rating",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    // {
    //   name: "",
    //   label: "Action",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     display: true,
    //     setCellProps: () => ({
    //       style: { textAlign: "center" },
    //     }),
    //     setCellHeaderProps: () => ({
    //       style: { textAlign: "center" },
    //     }),
    //     customBodyRender: (value, tableMeta) => {
    //       let skillId = tableMeta.tableData[tableMeta.rowIndex].skillId;
    //       let skillTestId = tableMeta.tableData[tableMeta.rowIndex].skillTestId;
    //       let Id = tableMeta.tableData[tableMeta.rowIndex].id;

    //       // let documenTypeids =
    //       //   tableMeta.tableData[tableMeta.rowIndex].documentTypeIds;
    //       // let requirement = tableMeta.tableData[tableMeta.rowIndex].requirement;

    //       return (
    //         <div className="d-flex justify-content-center">
    //           <Tooltip title="Edit">
    //             <a
    //               className="mr-2"
    //               style={{ fontSize: "15px" }}
    //               onClick={(e) => {
    //                 e.preventDefault();
    //                 // GetDocumentTypeList();
    //                 setRowId(Id);
    //                 getSkillTestDataById(skillId, skillTestId);
    //               }}
    //             >
    //               <i className="fas fa-edit"></i>
    //             </a>
    //           </Tooltip>
    //         </div>
    //       );
    //     },
    //   },
    // },
  ];

  // const dashboardOptions = {
  //   showEmptyDataSourceMessage: true,
  //   selectableRows: "none",
  //   count: dashboardCount,
  //   rowsPerPage: dashboardPageSize,
  //   serverSide: true,
  //   rowsPerPageOptions: [],
  //   download: false,
  //   print: false,
  //   viewColumns: false,
  //   filter: false,
  //   search: false,
  //   responsive: "standard",

  //   onChangeRowsPerPage: (num) => {
  //     //   setLimit(num);
  //     //   setNxtPgInfo("");
  //     //   setPrevPgInfo("");
  //     //   setIsPrevOrNext("");
  //   },
  //   onSearchChange: (searchText) => {
  //     if (searchText !== null) {
  //       //setDashboardSearchText(searchText);
  //     } else {
  //       //setDashboardSearchText("");
  //     }
  //   },
  //   onColumnSortChange: async (sortColumn, sortDirection) => {
  //     if (sortDirection === "asc") {
  //       await setDashboardSortColumn(sortColumn);
  //       await setDashboardSortDirection(sortDirection);
  //     }
  //     if (sortDirection === "desc") {
  //       await setDashboardSortColumn(sortColumn);
  //       await setDashboardSortDirection(sortDirection);
  //     }
  //   },
  //   onChangePage: async (page) => {
  //     setDashboardStart(page * dashboardPageSize);
  //   },
  //   textLabels: {
  //     body: {
  //       noMatch: "No data found",
  //     },
  //   },
  // };

  const dashboardOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: dashboardCount,
    rowsPerPage: dashboardPageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "scroll",

    onChangeRowsPerPage: (num) => {
      //   setLimit(num);
      //   setNxtPgInfo("");
      //   setPrevPgInfo("");
      //   setIsPrevOrNext("");
    },
    onSearchChange: (searchText) => {
      if (searchText !== null) {
        //setDashboardSearchText(searchText);
      } else {
        //setDashboardSearchText("");
      }
    },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setDashboardSortColumn(sortColumn);
        await setDashboardSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setDashboardSortColumn(sortColumn);
        await setDashboardSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      setDashboardStart(page * dashboardPageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const submitSkillTest = async () => {
    showLoader();
    let testArray = dashboard.map((item) => ({
      AssociateId: tokenId,
      id: item?.id,
      skillId: item?.skillId,
      skillTestId: item?.skillTestId,
      skillName: item?.skillName,
      targetScore: item?.targetScore,
      actualScore: rowId == item.id ? actualScore : item?.actualScore, // Change this line to assign a new value to ActualScore
      percentage: rowId == item.id ? percentage : item?.percentage,
      proficiency: rowId == item.id ? proficiency : item?.proficiency,
      rating: rowId == item.id ? Rating : item?.rating,
      status: rowId == item.id ? Status : item?.status,
    }));
    setdashboard(testArray);
    onResetData();
    notify(0, "Skill test data  saved Successfully");
    hideLoader();
  };

  const submitSkillTestSection = async (event) => {
    event?.stopPropagation();
    let errors: FormErrors = {};
    let isValid = true;
    let actualCount = dashboard.filter(
      (x) => x.actualScore == "" || x.actualScore == null
    ).length;
    if (actualCount > 0) {
      notify(1, "Please fill all skill's data.");
      isValid = false;
    }

    if (isValid) {
      const response = await APICall(
        InsertUpdateAssociatesSkillTest,
        "POST",
        dashboard
      );

      if (response.status === 0) {
        notify(
          0,
          `The data for Candidate ID ${tokenId} has been successfully submitted.`
        );
        getSkillTestDetails(jobId, tokenId);
      } else {
        notify(1, "Data not inserted");
      }
    }
  };
  const onResetData = () => {
    // showLoader();
    setSkill("");
    setTarget("");
    setActualScore("");
    setPercentage("");
    setProficiency("");
    setRating("");
    setStatus("");
    //hideLoader();
  };
  const calculateOverallData = (avgPer: any) => {
    // showLoader();
    // let data=dashboard
    // if(data!=null){
    //   let avgPer=data[0].avgPercentage;
    setAvgPercentage(avgPer);

    if (avgPer >= 90) {
      setAvgProficiency("Master");
      setAvgRating(4);
      setAvgStatus("Pass");
      setCandidateTypeDisable(true);
      // setDecision(1);
      setDecision(DecisionOption[1]);
    } else if (avgPer >= 75 && avgPer < 90) {
      setAvgProficiency("Expert");
      setAvgRating(3);
      setAvgStatus("Pass");
      setCandidateTypeDisable(true);
      // setDecision(1);
      setDecision(DecisionOption[1]);
    } else if (avgPer >= 60 && avgPer < 75) {
      setAvgProficiency("Beginner");
      setAvgRating(2);
      // setAvgStatus("Failed");
      // setDecision(DecisionOption[0]);
      // setCandidateTypeDisable(false);
      setAvgStatus("Pass");
      setDecision(DecisionOption[1]);
      setCandidateTypeDisable(true);
    } else if (avgPer >= 0 && avgPer < 60) {
      setAvgProficiency("Learner");
      setAvgRating(1);
      setAvgStatus("Failed");
      setCandidateTypeDisable(false);
      setDecision(DecisionOption[0]);
    } else {
      setAvgProficiency("");
      setAvgRating("");
      setAvgStatus("");
    }
    // }
    // else{
    //   setAvgPercentage("");
    //   setAvgProficiency("");
    //   setAvgRating("");
    //   setAvgStatus("");
    // }

    // if((avgRating==1 ||avgRating==2)&&avgStatus=="Failed"){
    //   setCandidateTypeDisable(false)
    // }
    // else {
    //   setCandidateTypeDisable(true)
    //   setDecision(1);
    // }
    //hideLoader();
  };

  const handleActualScoreUpdate = (
    itemId: any,
    targetScore: any,
    newActualScore: any
  ) => {
    if (targetScore != 0) {
      //To set Percentage
      //let res=((target-actualScore)/target)*100
      let profRes = (newActualScore / targetScore) * 100;
      let proficiency = "";
      let rating = 0;
      let status = "";
      if (profRes >= 90) {
        proficiency = "Master";
        rating = 4;
        status = "Pass";
      } else if (profRes >= 75 && profRes < 90) {
        proficiency = "Expert";
        rating = 3;
        status = "Pass";
      } else if (profRes >= 60 && profRes < 75) {
        proficiency = "Beginner";
        rating = 2;
        status = "Failed";
      } else if (profRes >= 0 && profRes < 59) {
        proficiency = "Learner";
        rating = 1;
        status = "Failed";
      } else {
        proficiency = "";
        rating = 0;
        status = "";
      }

      const updatedDashboard = dashboard.map((item) => {
        // If the item id matches, update its actual score
        if (item.id === itemId) {
          return {
            ...item,
            actualScore: newActualScore,
            percentage: profRes.toFixed(2),
            proficiency: proficiency,
            rating: rating,
            status: status,
            AssociateId: tokenId,
            IsActive: 1,
            CreatedBy: userDetails?.Id,
          };
        }
        // Otherwise, return the original item unchanged
        return item;
      });

      // Update the dashboard state with the modified array
      setdashboard(updatedDashboard);
    }
  };
  const handleEditClick = (itemId) => {
    setEditRowId(itemId);
  };
  return (
    <>
      <div className="form-main">
        <div className="page-title w-100">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <h4>
                  {" "}
                  <i
                    className="fa-solid fa-home pr-1"
                    style={{
                      color: "white",
                      cursor: "pointer",
                      fontSize: "13px",
                      verticalAlign: "middle",
                    }}
                    onClick={() => {
                      navigate("/Bluecollar");
                    }}
                  ></i>
                  Skill Test
                </h4>
              </div>
              <div className="offset-lg-6 "></div>
              <div
                className="col-lg-2 d-flex align-items-center justify-content-end"
                style={{ width: "200px" }}
              >
                <SelectForm
                  isClearable={true}
                  async
                  options={(searchString, bc) => {
                    handleSearchForCandidateIdDropdown(
                      searchString,
                      "candidateId",
                      bc
                    );
                  }}
                  placeholder={"Select candidate Id"}
                  onChange={(val: any) => {
                    if (val !== null) {
                      // Check if the selected value is not null
                      onTokenChange(val, "candidate_Id");
                      LoadUserData(val.id);
                    } else {
                      setTokenData((prev) => {
                        return { ...prev, ["candidate_Id"]: null };
                      });
                    }
                  }}
                  isMulti={false}
                  noIndicator={false}
                  noSeparator={false}
                  value={tokenData?.candidate_Id}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}

      <div className="container-fluid mt-2" id="blue-collar-pages">
        <div className="row">
          <div className="col-md-4 d-flex justify-content-center align-items-start">
            <div className="p-3 text-center">
              {startScan ? (
                <QR startScanOps={startScan} onScan={handleScanData} />
              ) : (
                <img
                  src={QRCode || defaultProfileImgPic}
                  className="card-img-top"
                  alt="Thumbnail Image"
                />
              )}

              <div className="SectionSubmit clearfix mt-2">
                {/* <button
                  onClick={() => {
                    //onResetData();
                  }}
                  disabled={false}
                  className="btn btn-secondary float-right ml-2"
                >
                  <i className="fa-solid fa-refresh"></i> Reset
                </button> */}
                <button
                  onClick={() => {
                    setModalForGunScanner(true);
                  }}
                  disabled={false}
                  className="btn btn-primary mr-2"
                >
                  {<i className="fa-solid fa-qrcode mr-2"></i>}
                  QR-Scan gun
                </button>
                <button
                  onClick={() => {
                    setStartScan(!startScan);
                  }}
                  disabled={false}
                  className="btn btn-primary ml-2 "
                >
                  <i className="fas fa-camera mr-2"></i> QR-Camera
                </button>
                <div className="col-lg-10 col-sm-3 col-xs-4"></div>
              </div>
            </div>
          </div>

          <div className="col-md-3 d-flex justify-content-center align-items-start">
            <div className="p-3 text-center">
              <img
                src={candidateimage ? candidateimage : defaultProfileImgPic1}
                className="card-img-top"
                alt="Thumbnail Image"
              />
            </div>
          </div>
          <div className="col-md-5">
            <div className="row">
              <div className="col-md-6 mb-1">
                <label className="col-form-label">Token Id</label>
                <sup></sup>
                <InputForm
                  className="form-control"
                  placeholder="Token Id"
                  isDisabled={true}
                  onChange={""}
                  textArea={false}
                  value={tokenId || ""}
                  maxLength="255"
                />
              </div>
              <div className="col-md-6">
                <label className="col-form-label">Name</label>
                <sup></sup>
                <InputForm
                  className="form-control"
                  placeholder="Name"
                  isDisabled={true}
                  onChange={""}
                  textArea={false}
                  value={candidateDetails?.name || ""}
                  //  value={"Mallesh Fakkirappa Dollin"}
                  maxLength="255"
                />
              </div>

              <div className="col-md-6">
                <label className="col-form-label">Mobile Number</label>
                <sup></sup>
                <InputForm
                  className="form-control"
                  placeholder="Mobile Number"
                  isDisabled={true}
                  onChange={""}
                  textArea={false}
                  value={candidateDetails?.mobileNumber || ""}
                  //  value={"Mallesh Fakkirappa Dollin"}
                  maxLength="255"
                />
              </div>

              <div className="col-md-6">
                <label className="col-form-label">
                  Category <span style={{ color: "red" }}>*</span>
                </label>
                <sup></sup>
                {/* <SelectForm
                  value={Category}
                  placeholder={"Select Category"}
                  options={CategoryOption}
                  onChange={(event) => {
                    setCategory(event);
                    // onChange(event, "journey_by_courseid");
                  }}
                  isDisabled={false}
                  noIndicator={false}
                  noSeparator={false}
                /> */}
                <InputForm
                  className="form-control"
                  placeholder="Category"
                  isDisabled={true}
                  onChange={""}
                  textArea={false}
                  value={Category}
                  //  value={"Mallesh Fakkirappa Dollin"}
                  maxLength="255"
                />
              </div>

              {/* <div className="col-md-6">
                <label className="col-form-label">Skill Category</label>
                <sup></sup>
                <InputForm
                  className="form-control"
                  placeholder="Skill Category"
                  isDisabled={true}
                  onChange={""}
                  textArea={false}
                  value={scanData?.CandidateName || ""}
                  //  value={"Mallesh Fakkirappa Dollin"}
                  maxLength="255"
                />
              </div> */}

              {/* <div className="col-md-6">
                <label className="col-form-label">Work Area</label>
                <sup></sup>
                <InputForm
                  className="form-control"
                  placeholder="Work Area"
                  isDisabled={true}
                  onChange={""}
                  textArea={false}
                  value={scanData?.CandidateName || ""}
                  //  value={"Mallesh Fakkirappa Dollin"}
                  maxLength="255"
                />
              </div> */}
            </div>
          </div>
        </div>

        {showRehireDetailsSection && (
          <>
            <div>
              <AccordianCustom1
                header={"Rehire Candidate Details"}
                submitFunc={async (event) => {
                  event?.stopPropagation();
                }}
                btnDisable={true}
              >
                <RehireCandidateDetails
                  eId={previousEmployeeId}
                ></RehireCandidateDetails>
              </AccordianCustom1>
            </div>
          </>
        )}

        <div className="col-lg-12 mt-2">
          <AccordianCustom1
            header={"Organization Information"}
            submitFunc={() => {}}
            //clearFunc={""}
            btnDisable={true}
          >
            <OrganizationInformation
              positionScanned={positionbutton}
              formData={formData}
              enableOUSection={false}
              onSubmitData={false}
              associateId={0}
              submitData={false}
              onGetPositionData={false}
            ></OrganizationInformation>

            {/* <div className="row"> */}
            {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Position ID <span style={{ color: "red" }}>*</span>
                  </label>
                  <SelectForm
                    isClearable
                    async
                    options={(searchString, cb) => {
                      handleSearchForAsyncDropdown(
                        searchString,
                        "position_Id",
                        cb
                      );
                    }}
                    placeholder={"Select Position Id"}
                    onChange={(val: any) => {
                      onChange(val, "position_Id");
                    }}
                    isMulti={false}
                    isDisabled={true}
                    noIndicator={false}
                    noSeparator={false}
                    value={formData?.position_Id}
                  />
                </div>
              </div> */}

            {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Position Text <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Position Text"}
                    isDisabled={true}
                    textArea={false}
                    value={positionText?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div> */}
            {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Business <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Business"}
                    isDisabled={true}
                    textArea={false}
                    value={business?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div> */}

            {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    SBU <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"SBU"}
                    isDisabled={true}
                    textArea={false}
                    value={sbu?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div> */}
            {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Legal Entity <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Legal entity"}
                    isDisabled={true}
                    textArea={false}
                    value={LegalEntity?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div> */}
            {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Division <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Division"}
                    isDisabled={true}
                    textArea={false}
                    value={division?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div> */}
            {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Sub-Division <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Sub-Division"}
                    isDisabled={true}
                    textArea={false}
                    value={subdivision?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div> */}

            {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Function <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Function"}
                    isDisabled={true}
                    textArea={false}
                    value={Function?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div> */}

            {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Department <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Department"}
                    isDisabled={true}
                    textArea={false}
                    value={Department?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div> */}

            {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Section <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Section"}
                    isDisabled={true}
                    textArea={false}
                    value={Section?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div> */}

            {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Sub Section <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={" Sub Section"}
                    isDisabled={true}
                    textArea={false}
                    value={subSection?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div> */}

            {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Emp class <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Emp Class"}
                    isDisabled={true}
                    textArea={false}
                    value={empClass?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div> */}

            {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Job level <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Job Level"}
                    isDisabled={true}
                    textArea={false}
                    value={JobLevel?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div> */}

            {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Job Band <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Job Band"}
                    isDisabled={true}
                    textArea={false}
                    value={jobBand?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div> */}

            {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Designation <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Designation"}
                    isDisabled={true}
                    textArea={false}
                    value={designation?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div> */}

            {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Job Code <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Job Code"}
                    isDisabled={true}
                    textArea={false}
                    value={jobCode?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div> */}

            {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Job Title <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Job Title"}
                    isDisabled={true}
                    textArea={false}
                    value={skillCategory?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div> */}

            {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Reporting Manager <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Reporting Manager"}
                    isDisabled={true}
                    textArea={false}
                    value={reportingManager?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div> */}

            {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Skill Category <span style={{ color: "red" }}></span>
                  </label>
                  {/* <SelectForm
                    value={skillCategorys}
                    placeholder={"Select Category"}
                    options={skillCategoryOptions}
                    onChange={(event) => {
                      setSkillCategorys(event);
                      // onChange(event, "journey_by_courseid");
                    }}
                    isDisabled={false}
                    noIndicator={false}
                    noSeparator={false}
                  /> */}
            {/*<InputForm
                    className="form-control"
                    placeholder={"SkillCategory"}
                    isDisabled={true}
                    textArea={false}
                    value={skillCategoryName}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div> */}

            {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Work Area <span style={{ color: "red" }}></span>
                  </label> */}
            {/* <SelectForm
                    value={workArea}
                    placeholder={"Select Category"}
                    options={workAreaOption}
                    onChange={(event) => {
                      setWorkArea(event);
                      // onChange(event, "journey_by_courseid");
                    }}
                    isDisabled={false}
                    noIndicator={false}
                    noSeparator={false}
                  /> */}
            {/* <InputForm
                    className="form-control"
                    placeholder={"Work Area"}
                    isDisabled={true}
                    textArea={false}
                    // value={workArea?.name}
                    value={workArea}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div> */}
            {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Payroll Location <span style={{ color: "red" }}></span>
                  </label>

                  <InputForm
                    className="form-control"
                    placeholder={"Payroll Location"}
                    isDisabled={true}
                    textArea={false}
                    // value={workArea?.name}
                    value={payrollLocation}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div> */}
            {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Leagal Entity <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Leagal entity"}
                    isDisabled={true}
                    textArea={false}
                    value={LegalEntity?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div> */}
            {/* </div> */}
          </AccordianCustom1>

          <AccordianCustom1
            header={"Skill Test"}
            submitFunc={submitSkillTestSection}
            //clearFunc={""}
            //btnDisable={false}
            // btnDisable={dashboard.length <= 0}
            btnDisable={disabledSkilltestDashboard}
          >
            <div className="row">
              {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Skill <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Skill"}
                    isDisabled={true}
                    textArea={false}
                    value={skill}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Target <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Target"}
                    isDisabled={true}
                    textArea={false}
                    value={target}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div>

              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Actual Score <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Actual Score"}
                    isDisabled={false}
                    textArea={false}
                    value={actualScore}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      setActualScore(value);
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Percentage <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Percentage"}
                    isDisabled={true}
                    textArea={false}
                    value={percentage}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Proficieny <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Proficieny"}
                    isDisabled={true}
                    textArea={false}
                    value={proficiency}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div> */}

              {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Rating <span style={{ color: "red" }}></span>
                  </label>                  
                  <InputForm
                    className="form-control"
                    placeholder={"Rating"}
                    isDisabled={true}
                    textArea={false}
                    value={Rating}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div> */}

              {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Status <span style={{ color: "red" }}></span>
                  </label>                 
                  <InputForm
                    className="form-control"
                    placeholder={"Status"}
                    isDisabled={true}
                    textArea={false}
                    value={Status}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div> */}
            </div>
            {/* <div className="SectionSubmit mb-4 clearfix">
              <button
                onClick={() => {
                  submitSkillTest();
                }}
                disabled={false}
                className="btn btn-filter-submit float-right ml-2 mt-5"
              >
                <i className="fa fa-save"></i> Save               
              </button>

              <button
                onClick={() => {
                  onResetData();
                }}
                disabled={false}
                className="btn btn-secondary float-right ml-2 mt-5"
              >
                <i className="fa-solid fa-refresh"></i> Reset
              </button>
            </div> */}
            <div className="mb-3 pt-3">
              {/* <DynamicGrid
                data={dashboard}
                columns={gridColumns}
                options={dashboardOptions}               
              /> */}
              <table className="text-align-center table_grid skill-tbl">
                <thead>
                  <tr>
                    <th>Sr.no</th>
                    <th>Skill</th>
                    <th>Target Score</th>
                    <th>Actual Score</th>
                    <th>Percentage</th>
                    <th>Proficiency</th>
                    <th>Rating</th>
                    {/* <th>Status</th> */}
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {dashboard.map((item) => (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.skillName}</td>
                      <td>{item.targetScore}</td>
                      <td>
                        <input
                          type="number"
                          //disabled={(editRowId !== item.id && item.skillTestId!=null) || item.skillTestId!=null}
                          //disabled={editRowId !== item.id}
                          value={item.actualScore}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            // Assuming you have a function to handle actual score update
                            handleActualScoreUpdate(
                              item.id,
                              item.targetScore,
                              newValue
                            );
                          }}
                        />
                      </td>
                      <td>{item.percentage}</td>
                      <td>{item.proficiency}</td>
                      <td>{item.rating}</td>
                      {/* <td>{item.status}</td> */}
                      {/* <td>
                        {" "}
                        <div className="d-flex justify-content-center">
                          <Tooltip title="Edit">
                            <a
                              className="mr-2"
                              style={{ fontSize: "15px" }}
                              onClick={(e) => {
                                e.preventDefault();
                                //setdisableSkills(false);
                                handleEditClick(item.id);
                                // GetDocumentTypeList();
                                //setRowId(Id);
                                //getSkillTestDataById(skillId, skillTestId);
                              }}
                            >
                              <i className="fas fa-edit"></i>
                            </a>
                          </Tooltip>
                        </div>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </AccordianCustom1>

          {showWageSection && (
            <>
              <AccordianCustom1
                header={"Wage Finalisation"}
                submitFunc={() => {}}
                //clearFunc={""}
                btnDisable={true}
                hideBtn={true} // Adding a prop to hide the button
              >
                {showWCLSection && decision?.value == "4" && (
                  <>
                    <div className="row">
                      <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                        <label className="col-form-label">
                          Min Annual Wage
                        </label>

                        <InputForm
                          className="form-control"
                          placeholder="Min Annual Wage"
                          isDisabled={true}
                          onChange={(e) => {
                            //setRemark(e.target.value);\
                            setStandardWage(e.target.value);
                          }}
                          textArea={false}
                          value={WCLDetails?.min}
                          maxLength="255"
                        />
                      </div>
                      <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                        <label className="col-form-label">
                          Median Annual Wage
                        </label>

                        <InputForm
                          className="form-control"
                          placeholder="Median Annual Wage"
                          isDisabled={true}
                          onChange={(e) => {
                            //setRemark(e.target.value);\
                            setStandardWage(e.target.value);
                          }}
                          textArea={false}
                          value={WCLDetails?.med}
                          maxLength="255"
                        />
                      </div>
                      <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                        <label className="col-form-label">
                          Max Annual Wage
                        </label>

                        <InputForm
                          className="form-control"
                          placeholder="Max Annual Wage"
                          isDisabled={true}
                          onChange={(e) => {
                            //setRemark(e.target.value);\
                            setStandardWage(e.target.value);
                          }}
                          textArea={false}
                          value={WCLDetails?.max}
                          maxLength="255"
                        />
                      </div>

                      <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                        <label className="col-form-label">
                          Offered Annual Wage
                        </label>

                        <InputForm
                          className="form-control"
                          placeholder="Offered Annual Wage"
                          isDisabled={true}
                          onChange={(e) => {
                            setWCLOfferedAnnualWage(e.target.value);
                          }}
                          textArea={false}
                          value={WCLOfferedAnnualWage}
                          maxLength="255"
                        />
                      </div>
                      <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                        <label className="col-form-label">Monthly Wage</label>

                        <InputForm
                          className="form-control"
                          placeholder="Monthly Wage"
                          isDisabled={true}
                          onChange={(e) => {
                            //setRemark(e.target.value);\
                          }}
                          textArea={false}
                          value={WCLOfferedMonthlyWage}
                          maxLength="255"
                        />
                      </div>
                    </div>
                    {/* {isSendBackRequestFlow && (
                        <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                        <label className="col-form-label">
                        Remarks
                        </label>

                        <InputForm
                          className="form-control"
                          placeholder="Remarks"
                          isDisabled={true}
                          onChange={(e) => {
                            //setRemark(e.target.value);\
                          }}
                          textArea={false}
                          value={sendBackRemark}
                          maxLength="255"
                        />
                      </div>
                      )} */}
                  </>
                )}

                {!showWCLSection && decision?.value == "4" && (
                  <>
                    <div className="row">
                      <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                        <label className="col-form-label">
                          Standard Gross Wage per month (Rs)
                        </label>

                        <InputForm
                          className="form-control"
                          placeholder="Standard Gross Wage"
                          isDisabled={true}
                          onChange={(e) => {
                            //setRemark(e.target.value);\
                            setStandardWage(e.target.value);
                          }}
                          textArea={false}
                          value={StandardWage}
                          maxLength="255"
                        />
                      </div>

                      <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                        <label className="col-form-label">
                          Offered Gross Wage per month (Rs.)
                        </label>

                        <InputForm
                          className="form-control"
                          placeholder="Enter Offered Gross Wage"
                          isDisabled={true}
                          onChange={(e) => {
                            setOfferedMonthlyWage(e.target.value);
                          }}
                          textArea={false}
                          value={OfferedMonthlyWage}
                          maxLength="255"
                        />
                      </div>

                      <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                        <label className="col-form-label">
                          Offered Gross wage - Annual (Rs)
                        </label>

                        <InputForm
                          className="form-control"
                          placeholder="Offered Annual wage"
                          isDisabled={true}
                          onChange={(e) => {
                            //setRemark(e.target.value);
                          }}
                          textArea={false}
                          value={OfferedAnnualWage}
                          maxLength="255"
                        />
                      </div>

                      <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                        <label className="col-form-label">
                          Percentage Difference (+/-){" "}
                        </label>

                        <InputForm
                          className="form-control"
                          placeholder="Hike"
                          isDisabled={true}
                          onChange={(e) => {
                            //setRemark(e.target.value);
                          }}
                          textArea={false}
                          value={hike}
                          maxLength="255"
                        />
                      </div>

                      <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                        <label className="col-form-label">
                          Increment Amount{" "}
                        </label>

                        <InputForm
                          className="form-control"
                          placeholder="Increment Amount"
                          isDisabled={true}
                          onChange={(e) => {}}
                          textArea={false}
                          value={incrementAmount}
                          maxLength="255"
                        />
                      </div>
                    </div>
                  </>
                )}
              </AccordianCustom1>
            </>
          )}

          <AccordianCustom1
            header={"Candidate Type"}
            submitFunc={submitCandidateType}
            //clearFunc={""}
            btnDisable={candidateTypeDisable}
          >
            <div className="row">
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Overall Average Percentage{" "}
                    <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Percentage"}
                    isDisabled={true}
                    textArea={false}
                    value={avgPercentage}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Overall Proficiency <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Proficiency"}
                    isDisabled={true}
                    textArea={false}
                    value={avgProficiency}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div>

              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Overall Rating <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Rating"}
                    isDisabled={true}
                    textArea={false}
                    value={avgRating}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div>

              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Overall Status <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Status"}
                    isDisabled={true}
                    textArea={false}
                    value={avgStatus}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div>
              {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Consider as Trainee? <span style={{ color: "red" }}></span>
                  </label>
                  <div className="row">
                    <div>
                      <input
                        className="ml-2 mr-1"
                        disabled={candidateTypeDisable}
                        id={`descision_1`}
                        type="radio"
                        name="descision"
                        onChange={() => handleCandidateTypeChange(1)}
                        checked={isTrainee === 1}
                      />
                      <label htmlFor={`descision_1`}>Yes</label>
                    </div>
                    <div>
                      <input
                        className="ml-2 mr-1"
                        disabled={candidateTypeDisable}
                        id={`descision_2`}
                        type="radio"
                        name="descision"
                        onChange={() => handleCandidateTypeChange(0)}
                        checked={isTrainee === 0}
                      />
                      <label htmlFor={`descision_2`}>No</label>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </AccordianCustom1>

          <AccordianCustom1
            header={"Decision"}
            submitFunc={() => {}}
            //clearFunc={""}
            btnDisable={disableDecision ? true : false}
            hideBtn={true}
          >
            <div className="row">
              <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                <label className="col-form-label">
                  Decision <span style={{ color: "red" }}>*</span>
                </label>
                <sup></sup>
                <SelectForm
                  isClearable
                  options={DecisionOption}
                  placeholder={"Select Decision"}
                  isDisabled={isdecisiondisable}
                  onChange={(e) => {
                    setDecision(e);
                  }}
                  // onChange={(val: any) => {
                  //   onChange(val, "decision");
                  // }}
                  isMulti={false}
                  noIndicator={false}
                  noSeparator={false}
                  value={decision}
                />
                {formErrors.Decision && (
                  <p style={{ color: "red" }}>{formErrors.Decision}</p>
                )}
              </div>

              {/* <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                <label className="col-form-label">Reason of Rejection</label>
                <sup></sup>
                <SelectForm
                  isClearable
                  options={RejectionReasonOption}
                  placeholder={"Select Reason of Rejection"}
                  isDisabled={fielddisable}
                  onChange={(e) => {
                     setRejection(e);
                  }}
                  isMulti={false}
                  noIndicator={false}
                  noSeparator={false}
                  value={Rejection}
                />
                 {formErrors.Rejection && (
                  <p style={{ color: "red" }}>{formErrors.Rejection}</p>
                )}
              </div> */}

              <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                <label className="col-form-label">Remark</label>

                <InputForm
                  className="form-control"
                  placeholder="Enter Remark"
                  isDisabled={false}
                  onChange={(e) => {
                    setRemark(e.target.value);
                  }}
                  textArea={true}
                  value={Remark}
                  maxLength="255"
                />
              </div>
            </div>
            <div className="SectionSubmit clearfix">
              <button
                onClick={submitDecision}
                disabled={disableDecision ? true : false}
                className="btn btn-filter-submit float-right mt-3"
              >
                <i className="fa-solid fa-check mr-2"></i> Proceed
              </button>
            </div>
          </AccordianCustom1>
          <Modal
            show={showcurrentstage}
            onHide={() => {
              setShowCurrentStage(false);
            }}
            backdrop="static"
            keyboard={false}
            size="sm"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Current Stage</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4>{`You currently belong to ${currentStageName}`}</h4>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>

          <Modal
            show={modalForGunScanner}
            onHide={() => {
              setModalForGunScanner(false);
            }}
            backdrop="static"
            keyboard={false}
            size="sm"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Scan data</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4>{`Scan Data using scanner gun.`}</h4>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>

          <Modal
            show={showModal1}
            onHide={handleModalClose1}
            backdrop="static"
            keyboard={false}
            size="sm"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Invalid QR</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4>
                The Scan QR entry is more than 48 hours old and cannot be
                accepted. New candidate QR will need to be generated
              </h4>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>

          <Modal
            show={IsOrganisationunitpopup}
            onHide={() => {
              setisOrganisationunitpopup(false);
            }}
            backdrop="static"
            keyboard={false}
            size="sm"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Organisation Unit</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h4>{`Candidate does not belong to Organisation Unit.`}</h4>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default SkillTest;
