import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoaderContext } from "../../Helpers/Context/Context";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { APICall } from "../../Helpers/APICalls";
import {
  deactivateFeedbackDetailById,
  getAssessmentDashboardDropdownList,
  getFeedbackDasboardDetails,
  getFeedbackDashboardDropdownList,
  getFeedbackDropdownList,
  getLMSDomainDropDownData,
  getUserAndRoleWiseAccess,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import notify from "../../Helpers/ToastNotification";
import { Collapse, Tooltip } from "@mui/material";
import { Modal } from "react-bootstrap";
import InputForm from "../../Components/InputForm/InputForm";
import SelectForm from "../../Components/SelectForm/SelectForm";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";

const FeedbackDashboard = () => {
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [PageSize, setPageSize] = useState(10);
  const { state } = useLocation();
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  let { userDetails, currentRoleId }: any = useUserContext();
  const [sortDirection, setSortDirection] = useState("");
  const [sortColumn, setSortColumn] = useState("");

  const [reSet, setreSet] = useState(false);

  const [feedbackId, setFeedbackId] = useState([]);
  const [feedbackIdoptions, setFeedbackIdoptions] = useState([]);

  const [feedbackForm, setFeedbackForm] = useState([]);
  const [feedbackFormOptions, setFeedbackFormOptions] = useState([]);

  const [createdBy, setCreatedBy] = useState([]);
  const [createdByOptions, setCreatedByOptions] = useState([]);

  const [domain, setDomain] = useState([]);
  const [domainOptions, setDomainOptions] = useState([]);

  const [publishStatus, setPublishStatus] = useState(null);
  const [publishStatusOptions, setPublishStatusOptions] = useState([]);

  const [status, setStatus] = useState(null);
  const [statusOptions, setStatusOptions] = useState([]);

  const [remarks, setRemarks] = useState("");

  const [showModal, setShowModal] = useState(false);

  const [modalTitle, setModalTitle] = useState("");

  const [isActive, setIsActive] = useState(false);
  const [id, setId] = useState(null);

  interface userAndRoleWiseAccess {
    isView?: boolean;
    isEdit?: boolean;
    isDelete?: boolean;
  }

  const [userAndRoleWiseData, setUserAndRoleWiseData] =
    useState<userAndRoleWiseAccess>({});

  const getUserAndRoleWiseAccessApiCall = async () => {
    const response = await APICall(getUserAndRoleWiseAccess, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      InterfaceName: "Feedback_Dashboard",
    });

    if (response?.status === 0) {
      setUserAndRoleWiseData(response?.data);
    } else {
      notify(response?.status, response?.message);
    }
  };

  useEffect(() => {
    const responseData = async () => {
      const objContentId = {
        mode: "FEEDBACKCODE",
        userId: userDetails?.Id,
        roleId: currentRoleId?.value,
      };
      const res = await APICall(
        getFeedbackDashboardDropdownList,
        "POST",
        objContentId
      );
      if (res.data && res.data.length > 0) {
        setFeedbackIdoptions(res.data);
      }

      const objContentTitle = {
        mode: "FEEDBACKNAME",
        userId: userDetails?.Id,
        roleId: currentRoleId?.value,
      };
      const res1 = await APICall(
        getFeedbackDashboardDropdownList,
        "POST",
        objContentTitle
      );
      if (res1.data && res1.data.length > 0) {
        setFeedbackFormOptions(res1.data);
      }

      const objCreatedBy = {
        mode: "CREATEDBY",
        userId: userDetails?.Id,
        roleId: currentRoleId?.value,
      };
      const res2 = await APICall(
        getFeedbackDashboardDropdownList,
        "POST",
        objCreatedBy
      );
      if (res2.data && res2.data.length > 0) {
        setCreatedByOptions(res2.data);
      }

      const objPublish = {
        mode: "PUBLISH",
        userId: userDetails?.Id,
        roleId: currentRoleId?.value,
      };
      const res3 = await APICall(
        getFeedbackDashboardDropdownList,
        "POST",
        objPublish
      );
      if (res3.data && res3.data.length > 0) {
        setPublishStatusOptions(res3.data);
      }

      const objStatus = {
        mode: "ACTIVE",
        userId: userDetails?.Id,
        roleId: currentRoleId?.value,
      };
      const res4 = await APICall(
        getFeedbackDashboardDropdownList,
        "POST",
        objStatus
      );
      if (res4.data && res4.data.length > 0) {
        setStatusOptions(res4.data);
      }
    };

    showLoader();

    responseData();
    getLMSDomainDropDownDataApiCall();
    getUserAndRoleWiseAccessApiCall();

    hideLoader();
  }, []);

  const getLMSDomainDropDownDataApiCall = async () => {
    const response = await APICall(getLMSDomainDropDownData, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      GetAll: true,
    });

    if (response?.status === 0 && response?.data.length > 0) {
      setDomainOptions(response?.data);
    }
  };

  useEffect(() => {
    getAssessmentDasboardDetailsAPICall();
  }, [page, PageSize, sortColumn, sortDirection, searchText, reSet]);

  const getAssessmentDasboardDetailsAPICall = async () => {
    showLoader();

    try {
      const GetDashboardData = await APICall(
        getFeedbackDasboardDetails,
        "POST",
        {
          PageNumber: page,
          PageSize: PageSize,
          SortColumn: sortColumn,
          SortOrder: sortDirection,
          SearchText: searchText,
          FeedbackCodeIds: feedbackId?.map((s) => s.value).join(","),
          FeedbackNameIds: feedbackForm?.map((s) => s.value).join(","),
          CreatedByIds: createdBy?.map((s) => s.value).join(","),
          IsPublished: publishStatus?.value,
          IsStatus: status?.value,
          DomainIds: domain?.map((s) => s.value).join(","),
          UserId: userDetails?.Id,
          RoleId: currentRoleId?.value,
        }
      );
      if (
        GetDashboardData &&
        GetDashboardData.data !== null &&
        GetDashboardData.data.length > 0
      ) {
        setRowData(GetDashboardData.data);
        setCount(GetDashboardData.data[0].count);
      } else if (GetDashboardData?.status === 1) {
        notify(1, GetDashboardData?.message);
        setRowData([]);
        setCount(0);
      } else {
        setRowData([]);
        setCount(0);
      }
    } catch (error) {
      console.error(error);
    }

    hideLoader();
  };

  const activateandDeactivateFunction = async (id: any, isActive: any) => {
    if (remarks === "" || remarks === null) {
      notify(1, "Please enter remarks");
    } else {
      showLoader();
      const responseData = await APICall(deactivateFeedbackDetailById, "POST", {
        Id: id,
        IsActive: isActive,
        Remarks: remarks,
        CreatedBy: userDetails?.Id,
      });

      setShowModal(false);
      setRemarks("");
      if (responseData?.status === 0) {
        if (!isActive) {
          notify(0, "Feedback has been successfully Activated");
        } else {
          notify(0, "Feedback has been successfully Deactivated");
        }
        setreSet(!reSet);
        hideLoader();
      } else {
        notify(1, "Something went wrong");
      }
      hideLoader();
    }
  };

  const showActivateandDeactivateModal = async (id: any, isActive: any) => {
    setShowModal(true);
    setId(id);
    setIsActive(isActive);
    if (isActive) {
      setModalTitle("Do you want to Inactive the Feedback?");
    } else {
      setModalTitle("Do you want to active the Feedback?");
    }
  };

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: PageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      setPage(page * PageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };
  const gridColumns = [
    {
      name: "Id",
      label: "Id",
      options: {
        display: false,
      },
    },

    {
      name: "feedbackCode",
      label: "Feedback ID",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "feedbackName",
      label: "Feedback Form",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "sequenceType",
      label: "Sequence Type",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "publishStatus",
      label: "Publish Status",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta) => {
          let id = tableMeta.tableData[tableMeta.rowIndex].id;
          let isActive = tableMeta.tableData[tableMeta.rowIndex].isActive;

          let color = isActive ? "green" : "red";
          let activate = isActive ? "Active" : "Inactive";

          return (
            <div style={{ width: "100px" }}>
              {userAndRoleWiseData?.isEdit && (
                <>
                  <Tooltip title="Edit">
                    <a
                      className="mr-2"
                      style={{ fontSize: "15px" }}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/feedbackEdit", {
                          state: { feedbackId: value, isDisable: false },
                        });
                      }}
                    >
                      <i className="fas fa-edit"></i>
                    </a>
                  </Tooltip>
                  <Tooltip title={activate}>
                    <a
                      className="mr-2"
                      style={{ fontSize: "15px" }}
                      onClick={() => {
                        showActivateandDeactivateModal(id, isActive);
                      }}
                    >
                      <i
                        className="fa-solid fa-circle"
                        style={{ color: color }}
                      ></i>
                    </a>
                  </Tooltip>
                </>
              )}

              {!userAndRoleWiseData?.isEdit && userAndRoleWiseData?.isView && (
                <Tooltip title="View">
                  <a
                    className="mr-2"
                    style={{ fontSize: "15px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/feedbackEdit", {
                        state: { feedbackId: value, isDisable: true },
                      });
                    }}
                  >
                    <i className="fas fa-eye"></i>
                  </a>
                </Tooltip>
              )}
            </div>
          );
        },
      },
    },
  ];

  const onSubmitFilter = () => {
    if (
      feedbackId?.length === 0 &&
      feedbackForm?.length === 0 &&
      createdBy?.length === 0 &&
      domain?.length === 0 &&
      status === null &&
      publishStatus === null
    ) {
      notify(
        1,
        "Please select at least one filter option from the grid view to perform the search action."
      );
    } else {
      setPage(0);
      setreSet(!reSet);
      // getAssessmentDasboardDetailsAPICall();
    }
  };

  const resetFilters = () => {
    setPage(0);
    setSearchText("");
    setFeedbackId([]);
    setFeedbackForm([]);
    setCreatedBy([]);
    setDomain([]);
    setStatus(null);
    setPublishStatus(null);

    setreSet(!reSet);
  };
  return (
    <>
      <div className="breadcrumb modified-bredcrumb">
        <span>
          <ul>
            <li>
              <a href="/home">Home</a>
            </li>
            <li>Feedback Dashboard</li>
          </ul>
        </span>
        {userAndRoleWiseData?.isEdit && (
          <button
            onClick={() => {
              navigate("/feedbackEdit", { state: { feedbackId: 0 } });
            }}
            className="btn btn-primary mt-3"
          >
            <i className="fa fa-plus mr-2"></i>
            Create Feedback
          </button>
        )}
      </div>
      <div className="container-fluid">
        <div className="grid-wrapper grid_mob">
          <div className="row mx-auto filtBox">
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="form-group filtInp">
                <InputForm
                  value={searchText}
                  placeholder={"Search Keywords"}
                  isDisabled={false}
                  textArea={false}
                  onChange={(e) => {
                    setPage(0);
                    setSearchText(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-12 filtrt">
              <ul className=" filter-icon-wel">
                <li>
                  <a
                    href="javascript:void(0);"
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                    className="filter-btn"
                  >
                    <i className="fa fa-filter"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <>
          <Collapse in={open}>
            <div id="example-collapse-text grid-wrapper">
              <div className="row mx-auto myInlineStyle">
                <>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">
                      Feedback ID
                    </label>
                    <SelectForm
                      isClearable
                      options={feedbackIdoptions}
                      placeholder={"Feedback ID"}
                      isDisabled={false}
                      onChange={(event) => {
                        setFeedbackId(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={feedbackId}
                    />
                  </div>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">
                      Feedback Form
                    </label>
                    <SelectForm
                      isClearable
                      options={feedbackFormOptions}
                      placeholder={"Feedback Form"}
                      isDisabled={false}
                      onChange={(event) => {
                        setFeedbackForm(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={feedbackForm}
                    />
                  </div>

                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">
                      Created By
                    </label>
                    <SelectForm
                      isClearable
                      options={createdByOptions}
                      placeholder={"Created By"}
                      isDisabled={false}
                      onChange={(event) => {
                        setCreatedBy(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={createdBy}
                    />
                  </div>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">
                      Publish Status
                    </label>
                    <SelectForm
                      isClearable
                      options={publishStatusOptions}
                      placeholder={" Publish Status"}
                      isDisabled={false}
                      onChange={(event) => {
                        setPublishStatus(event);
                      }}
                      isMulti={false}
                      noIndicator={false}
                      noSeparator={false}
                      value={publishStatus}
                    />
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">Status</label>
                    <SelectForm
                      isClearable
                      options={statusOptions}
                      placeholder={"Status"}
                      isDisabled={false}
                      onChange={(event) => {
                        setStatus(event);
                      }}
                      isMulti={false}
                      noIndicator={false}
                      noSeparator={false}
                      value={status}
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">Domains</label>
                    <SelectForm
                      isClearable
                      options={domainOptions}
                      placeholder={"Domains"}
                      isDisabled={false}
                      onChange={(event) => {
                        setDomain(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={domain}
                    />
                  </div>
                </>
                <div className="col-lg-12 d-flex mt-2 justify-content-end">
                  <button
                    onClick={() => resetFilters()}
                    className="btn btn-secondary mr-2"
                  >
                    <i className="fa fa-undo" aria-hidden="true"></i> Reset{" "}
                  </button>
                  <button
                    className="btn btn-filter-submit"
                    onClick={() => onSubmitFilter()}
                  >
                    <i className="fa fa-search" aria-hidden="true"></i> Search{" "}
                  </button>
                </div>
              </div>
            </div>
          </Collapse>
        </>

        <div className="mb-3 pt-3">
          <DynamicGrid data={rowData} columns={gridColumns} options={options} />
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">{`${modalTitle}`}</div>
        </div>
        <Modal.Body>
          <>
            <div className="row">
              <div className="col-md-8">
                <label className="col-form-label">Remarks</label>
                <sup>*</sup>
                <InputForm
                  value={remarks}
                  placeholder={"Remarks"}
                  isDisabled={false}
                  textArea={true}
                  onChange={(e) => {
                    setRemarks(e.target.value);
                  }}
                />
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => activateandDeactivateFunction(id, isActive)}
          >
            Submit
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowModal(false);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FeedbackDashboard;
