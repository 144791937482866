import React, { useState, useEffect, useContext } from "react";
import { Tabs, Tooltip } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import InputForm from "../../Components/InputForm/InputForm";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import DateForm from "../../Components/DateForm/DateForm";
import {
  getSearchableDropdowns,
  insertUpdatePermissions,
  delegationRequests,
  delegationSearchableDropdown,
} from "../../Helpers/APIEndPoints/EndPoints";
import moment from "moment";
import SelectForm from "../../Components/SelectForm/SelectForm";
import { APICall } from "../../Helpers/APICalls";
import { LoaderContext } from "../../Helpers/Context/Context";
import notify from "../../Helpers/ToastNotification";
import "./DelegationProcess.css";

export const defaultTheme = createTheme({});
const { breakpoints } = defaultTheme;

const tabsTheme = {
  ...defaultTheme,
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: "0px !important",
          marginLeft: "-42px",
          [breakpoints.down("md")]: {
            minHeight: "0px !important",
            marginLeft: "0px",
          },
        },
      },
    },
  },
};

const isEmpty = (value) => {
  if (
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  ) {
    return true;
  }

  if (value instanceof Date && isNaN((value as Date).getTime())) {
    return true;
  }

  return false;
};

const DelegationProcess = () => {
  const [activeTab, setActiveTab] = useState("my_request");
  const [statusGridData, setStatusGridData] = useState({
    count: 0,
    start: 0,
    pageSize: 10,
    searchText: "",
    sortColumn: "",
    sortDirection: "",
    data: [],
  });
  const [attributesData, setAttributesData] = useState<any>({});
  const [formErrors, setFormErrors] = useState<any>({});
  const [createNew, setCreateNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [viewMode, setViewMode] = useState(false);

  const { showLoader, hideLoader } = useContext(LoaderContext);

  const { userDetails, currentRoleId }: any = useUserContext();

  const statusGridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: statusGridData.count,
    rowsPerPage: statusGridData.pageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",

    //standard | vertical | simple
    onChangeRowsPerPage: (num) => {},
    onSearchChange: (searchText) => {
      if (searchText !== null) {
        setStatusGridData((prev) => {
          return { ...prev, searchText: searchText };
        });
      } else {
        setStatusGridData((prev) => {
          return { ...prev, searchText: "" };
        });
      }
    },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setStatusGridData((prev) => {
          return {
            ...prev,
            sortColumn: sortColumn,
            sortDirection: sortDirection,
          };
        });
      }
      if (sortDirection === "desc") {
        await setStatusGridData((prev) => {
          return {
            ...prev,
            sortColumn: sortColumn,
            sortDirection: sortDirection,
          };
        });
      }
    },
    onChangePage: async (page) => {
      setStatusGridData((prev) => {
        return { ...prev, start: page };
      });
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const handleCreateNew = () => {
    setAttributesData((prev) => ({
      ...prev,
      current_username:
        currentRoleId?.code === 'SystemAdmin' || currentRoleId?.code === 'HRHead'
          ? ""
          : {
              label: `${EmployeeCode} - ${FirstName} ${LastName}`,
              value: EmployeeId,
            },
    }));
    setCreateNew(true);
  };

  const handleEdit = (tableMetadata, viewData) => {
    const { rowData } = tableMetadata || {};
    const currentUser = { label: rowData[2], value: rowData[3] };
    const delegateUser = { label: rowData[4], value: rowData[5] };
    const fromDate = rowData[6];
    const toDate = rowData[7];

    setAttributesData({
      current_username: currentUser,
      delegate_username: delegateUser,
      from_date: fromDate,
      to_date: toDate,
      id: rowData[1],
    });

    setCreateNew(true);
    setIsEdit(viewData ? false : true);
    setViewMode(viewData);
  };

  const statusGridColumns = [
    {
      name: "rowNum",
      label: "Sr No.",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "user",
      label: "Current UserName",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "userId",
      label: "Current UserName",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "delegate",
      label: "Delegate UserName",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "delegateId",
      label: "Delegate UserName",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "fromdate",
      label: "From date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return value ? moment(value).format("DD MMM YYYY") : "-";
        },
      },
    },
    {
      name: "todate",
      label: "To Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return value ? moment(value).format("DD MMM YYYY") : "-";
        },
      },
    },
    {
      name: "initiatedBy",
      label: "Request initiated by",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Action",
      label: "Action",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="centerStyles">
              {tableMeta?.rowData?.[9] === "Completed" ? (
                <Tooltip title="View">
                  <i
                    style={{ marginRight: "0px", cursor: "pointer" }}
                    onClick={() => handleEdit(tableMeta, true)}
                    className="fa-solid fa-eye"
                  ></i>
                </Tooltip>
              ) : (
                <Tooltip title="Edit">
                  <i
                    style={{ marginRight: "0px", cursor: "pointer" }}
                    onClick={() => handleEdit(tableMeta, false)}
                    className="fas fa-edit"
                  ></i>
                </Tooltip>
              )}
            </div>
          );
        },
      },
    },
  ];

  const getGridStructure = async (page) => {
    showLoader();

    try {
      const dashboardData = await APICall(delegationRequests, "POST", {
        PageNumber: page || statusGridData.start,
        PageSize: statusGridData.pageSize,
        SearchText: statusGridData?.searchText || "",
        SortColumn: statusGridData.sortColumn,
        SortOrder: statusGridData.sortDirection,
        CreatedBy: Id,
        CreatedByRoleId: currentRoleId?.value,
      });

      const { data } = dashboardData || {};

      if ((data || []).length > 0) {
        setStatusGridData((prev) => ({
          ...prev,
          count: data[0].count,
          data,
        }));
      } else {
        setStatusGridData((prev) => ({
          ...prev,
          count: 0,
          data: [],
        }));
      }

      hideLoader();
    } catch (error) {
      hideLoader();
    }
  };

  useEffect(() => {
    getGridStructure(0);
  }, [statusGridData.searchText]);

  useEffect(() => {
    getGridStructure(null);
  }, [statusGridData.start,statusGridData.sortColumn, statusGridData.sortDirection]);

  const handleValidation = () => {
    let errorCount = 0;
    if (isEmpty(attributesData["current_username"])) {
      setFormErrors((prev) => ({
        ...prev,
        current_username: "Current Username is required",
      }));
      errorCount += 1;
    }
    if (isEmpty(attributesData["delegate_username"])) {
      setFormErrors((prev) => ({
        ...prev,
        delegate_username: "Delegate Username is required",
      }));
      errorCount += 1;
    }
    if (isEmpty(attributesData["from_date"])) {
      setFormErrors((prev) => ({
        ...prev,
        from_date: "From Date is required",
      }));
      errorCount += 1;
    }
    if (isEmpty(attributesData["to_date"])) {
      setFormErrors((prev) => ({ ...prev, to_date: "To Date is required" }));
      errorCount += 1;
    }

    if(!isEmpty(attributesData['from_date']) && moment(attributesData["from_date"]).isBefore(moment().format('YYYY-MM-DD'))){
      setFormErrors((prev) => ({
        ...prev,
        from_date: "From date cannot be less than current date",
      }));
      errorCount += 1;
    }

    if (
      !isEmpty(attributesData["to_date"]) &&
      moment(attributesData["to_date"]).isBefore(attributesData["from_date"])
    ) {
      setFormErrors((prev) => ({
        ...prev,
        to_date: "To date should not be less than from date",
      }));
      errorCount += 1;
    }

    return errorCount > 0 ? true : false;
  };

  const { EmployeeCode, EmployeeId, FirstName, LastName, Id } = userDetails || {};

  const handleSubmit = async (IsClosed) => {
    showLoader();
    setFormErrors({});
    const isErrors = await handleValidation();
    if (isErrors) {
      hideLoader();
      return;
    }

    const { current_username, delegate_username, from_date, to_date, id } =
      attributesData || {};

    const delegationRes = await APICall(insertUpdatePermissions, "POST", {
      id: id || undefined,
      UserId: current_username.value,
      DelegateId: delegate_username.value,
      Fromdate: moment(from_date).format("YYYY-MM-DD"),
      Todate: moment(to_date).format("YYYY-MM-DD"),
      CreatedBy: Id,
      IsClosed: IsClosed || undefined,
    });

    hideLoader();

    if (delegationRes?.status === 1) {
      notify(1, delegationRes?.message || "Something went wrong");
    } else {
      setAttributesData({});
      setCreateNew(false);
      setIsEdit(false);
      setViewMode(false);
      getGridStructure(0);
      notify(0, `Form ${isEdit ? "Updated" : "Submitted"}  Successfully`);
    }
  };

  const handleCancel = () => {
    setCreateNew(false);
    setAttributesData({});
    setFormErrors({});
    setIsEdit(false);
    setViewMode(false);
  };

  const getSearchableDropdownAPI = async (
    searchString,
    AC,
    cb,
    valueForDropdown,
    type,
    gridId
  ) => {
    const payload = {
      InitiatorId: Id,
      InitiatorRoleId: currentRoleId?.value,
    };
    if (
      searchString === "" &&
      cb === null &&
      valueForDropdown !== "" &&
      AC !== ""
    ) {
      const response = await APICall(delegationSearchableDropdown, "POST", {
        searchString,
        AC,
        valueForDropdown,
        ...payload,
      });

      if (response.data !== null && response.data.length > 0) {
        if (response.data.length === 1) {
          return response.data[0];
        }
      }
    } else if (searchString.length > 1) {
      const response = await APICall(delegationSearchableDropdown, "POST", {
        searchString,
        AC,
        ...payload,
      });
      if (response.data !== null && response.data.length > 0) {
        cb(response.data);
      } else {
        cb([]);
      }
    }
  };

  return (
    <div className="dp_container mt-3">
      
      <div className="row px-3">
      {
        createNew &&
        <div className="col-8" style={{ marginTop:"25px" }}>
          <ThemeProvider theme={tabsTheme}>
            <Tabs
              value={activeTab}
              className="profile-tabs"
              onChange={() => {}}
              variant="scrollable"
              TabScrollButtonProps={{
                style: {
                  color: "white",
                },
              }}
              scrollButtons
              TabIndicatorProps={{
                style: { display: "none"},
              }}
              allowScrollButtonsMobile
              aria-label="scrollable tabs"
            >
              <div className="tabBtn">
                <ul>
                  <li>
                    <button
                      onClick={() => setActiveTab("my_request")}
                      className={
                        "my_request" === activeTab ? "bttn active" : "bttn"
                      }
                    >
                      My Request
                    </button>
                  </li>
                </ul>
              </div>
            </Tabs>
          </ThemeProvider>
        </div>
      }
      {
        !createNew &&
        <div className="col 12" style={{padding:"5px"}}>
          <div className="d-flex justify-content-md-end justify-content-sm-start mt-xs-3">
            <button className="btn btn-primary float-right ml-2 mt-3 mr-2"
              // "btn btn-warning" 
              onClick={handleCreateNew}>
              <i className="fa fa-plus mr-2"></i>
              New
            </button>
          </div>
        </div>
      }
      </div>
      <div className="grid-wrapper grid_mob" style={{marginBottom:"5px"}}>
        <div className="row mx-auto filtBox">
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div className="form-group filtInp">
            {
              !createNew ?
              <InputForm
                value={statusGridData?.searchText || ""}
                placeholder="Search"
                isDisabled={false}
                textArea={false}
                onChange={(e) =>
                  setStatusGridData((prev) => ({
                    ...prev,
                    searchText: e.target.value,
                  }))
                }
              />
              : <div style={{padding:"15px"}}>
              </div>
            }
            </div>
          </div>
        </div>
      </div>
      {!createNew ? (
        <DynamicGrid
          options={statusGridOptions}
          data={statusGridData.data || []}
          columns={statusGridColumns}
        />
      ) : (
        <>
          <div className="row px-3 my-3">
            <div className="col-lg-12 col-sm-12 col-xs-4">
              <div className="mb-3 row">
                <div className="col-md-2">
                  <label className="col-form-label">
                    Current Username
                    <sup>*</sup>
                  </label>
                </div>
                <div className="col-md-4">
                  <SelectForm
                    isClearable
                    async
                    options={(searchString, cb) =>
                      getSearchableDropdownAPI(
                        searchString,
                        "IOPPOS",
                        cb,
                        "",
                        "attributeSet",
                        0
                      )
                    }
                    isDisabled={viewMode || isEdit || currentRoleId.value !== 3}
                    placeholder="Current Username"
                    onChange={(event) => {
                      setAttributesData((prev) => {
                        return { ...prev, ["current_username"]: event };
                      });
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                    value={attributesData["current_username"] || ""}
                  />
                  {formErrors["current_username"] && (
                    <p style={{ color: "red" }}>
                      {formErrors["current_username"]}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-sm-12 col-xs-4">
              <div className="mb-3 row">
                <div className="col-md-2">
                  <label className="col-form-label">
                    Delegate Username
                    <sup>*</sup>
                  </label>
                </div>
                <div className="col-md-4">
                  <SelectForm
                    isClearable
                    async
                    options={(searchString, cb) =>
                      getSearchableDropdownAPI(
                        searchString,
                        "IOPPOS",
                        cb,
                        "",
                        "attributeSet",
                        0
                      )
                    }
                    placeholder="Delegate Username"
                    isDisabled={viewMode}
                    onChange={(event) => {
                      setAttributesData((prev) => {
                        return { ...prev, ["delegate_username"]: event };
                      });
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                    value={attributesData["delegate_username"] || ""}
                  />
                  {formErrors["delegate_username"] && (
                    <p style={{ color: "red" }}>
                      {formErrors["delegate_username"]}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-sm-12 col-xs-4">
              <div className="mb-3 row">
                <div className="col-md-2">
                  <label className="col-form-label">
                    From Date
                    <sup>*</sup>
                  </label>
                </div>
                <div className="col-md-4">
                  <DateForm
                    isDisabled={viewMode}
                    value={
                      attributesData["from_date"]
                        ? attributesData["from_date"]
                        : null
                    }
                    onChange={(date) => {
                      setAttributesData((prev) => ({
                        ...prev,
                        ["from_date"]: moment(date).format("DD-MMM-YYYY"),
                      }));
                    }}
                  />
                  {formErrors["from_date"] && (
                    <p style={{ color: "red" }}>{formErrors["from_date"]}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-sm-12 col-xs-4">
              <div className="mb-3 row">
                <div className="col-md-2">
                  <label className="col-form-label">
                    To Date
                    <sup>*</sup>
                  </label>
                </div>
                <div className="col-md-4">
                  <DateForm
                    isDisabled={viewMode}
                    value={attributesData["to_date"] || null}
                    onChange={(date) => {
                      setAttributesData((prev) => ({
                        ...prev,
                        ["to_date"]: moment(date).format("DD-MMM-YYYY"),
                      }));
                    }}
                  />
                  {formErrors["to_date"] && (
                    <p style={{ color: "red" }}>{formErrors["to_date"]}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 offset-1">
              <div className="d-flex flex-wrap">
                <button
                  type="button"
                  disabled={viewMode}
                  className="btn btn-filter-submit"
                  onClick={() => handleSubmit(false)}
                >
                  Save
                </button>
                <button
                  type="button"
                  disabled={viewMode || !attributesData.id}
                  className="btn buttonRed ml-2"
                  onClick={() => handleSubmit(true)}
                >
                  Close the delegation
                </button>{" "}
                <button
                  type="button"
                  className="btn btn-secondary ml-2"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DelegationProcess;
