import React, { useContext, useEffect, useState } from "react";
import { LoaderContext } from "../../Helpers/Context/Context";
import { useNavigate } from "react-router-dom";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import {
  Collapse,
  Tabs,
  ThemeProvider,
  Tooltip,
  createTheme,
} from "@mui/material";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import InputForm from "../../Components/InputForm/InputForm";
import { APICall } from "../../Helpers/APICalls";
import {
  getUserFeedbackDashboardData,
  getUserFeedbackDashboardFilter,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import SelectForm from "../../Components/SelectForm/SelectForm";
import notify from "../../Helpers/ToastNotification";

const UserFeedbackDashboard = () => {
  const [open, setOpen] = useState(false);

  const { showLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  let { userDetails, currentRoleId }: any = useUserContext();

  const [activetabIndex, setActivetabIndex] = useState<number>(0);
  const [sectionValuesArray, setSectionValuesArray] = useState<any>([]);

  const [activetab, setActivetab] = useState<string>("Pending Feedback");
  const tabs = ["Pending Feedback", "Completed Feedback"];

  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useState(0);
  const [start, setStart] = useState(0);
  const [sortDirection, setSortDirection] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [searchText, setSearchText] = useState("");
  const [rowData, setRowData] = useState([]);
  const [reSet, setReSet] = useState(false);

  const [feedbackForm, setFeedbackForm] = useState([]);
  const [feedbackFormOptions, setFeedbackFormOptions] = useState([]);

  const [journeyName, setJourneyName] = useState([]);
  const [journeyNameOptions, setJourneyNameOptions] = useState([]);

  const [CourseName, setCourseName] = useState([]);
  const [CourseNameOptions, setCourseNameOptions] = useState([]);

  useEffect(() => {
    const responseData = async () => {
      const objfeedbackName = {
        mode: "FEEDBACKNAME",
        FeedbackStatus: activetab === "Pending Feedback" ? 0 : 1,
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      };
      const res = await APICall(
        getUserFeedbackDashboardFilter,
        "POST",
        objfeedbackName
      );
      if (res.data && res.data.length > 0) {
        setFeedbackFormOptions(res.data);
      }

      const objCourse = {
        mode: "COURSE",
        FeedbackStatus: activetab === "Pending Feedback" ? 0 : 1,
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      };
      const res2 = await APICall(
        getUserFeedbackDashboardFilter,
        "POST",
        objCourse
      );
      if (res2.data && res2.data.length > 0) {
        setCourseNameOptions(res2.data);
      }

      const objJourney = {
        mode: "PROGRAM",
        FeedbackStatus: activetab === "Pending Feedback" ? 0 : 1,
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      };
      const res4 = await APICall(
        getUserFeedbackDashboardFilter,
        "POST",
        objJourney
      );
      if (res4.data && res4.data.length > 0) {
        setJourneyNameOptions(res4.data);
      }
    };

    showLoader();

    responseData();
    getUserFeedbackDashboardDataAPI();

    hideLoader();
  }, [activetab, reSet, start]);

  const getUserFeedbackDashboardDataAPI = async () => {
    showLoader();
    const res = await APICall(getUserFeedbackDashboardData, "POST", {
      PageNumber: start,
      PageSize: pageSize,
      SearchText: searchText,
      UserId: userDetails.Id,
      FeedbackStatus: activetab === "Pending Feedback" ? 0 : 1,
      FeedbackNameIds: feedbackForm?.map((s) => s.value).join(","),
      JourneyIds: journeyName?.map((s) => s.value).join(","),
      CourseIds: CourseName?.map((s) => s.value).join(","),
      SortColumn: sortColumn,
      SortOrder: sortDirection,
    });
    if (res.status === 0 && res.data !== null && res.data.length > 0) {
      setRowData(res.data);
      setCount(res.data[0].totalCount);
    } else {
      setRowData([]);
      setCount(0);
    }
    hideLoader();
  };

  const gridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: pageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      setStart(page * pageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const gridColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        display: false,
      },
    },

    {
      name: "course",
      label: "course Name",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "journey",
      label: "Journey Name",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },

    {
      name: "feedbackName",
      label: "Feedback Name",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "feedbackType",
      label: "Feedback Type",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },

    {
      name: "startTime",
      label: "Assigned On",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "endTime",
      label: "Completed On",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        display: activetab === "Pending Feedback" ? false : true,
      },
    },

    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta) => {
          let feedbackId = tableMeta.tableData[tableMeta.rowIndex].feedbackId;
          let courseScheduleId =
            tableMeta.tableData[tableMeta.rowIndex].courseScheduleId;
          return (
            <div style={{ width: "100px" }}>
              {activetab === "Pending Feedback" ? (
                <Tooltip title="Give feedback">
                  <a
                    className="mr-2"
                    style={{ fontSize: "15px" }}
                    onClick={(e) => {
                      e.preventDefault();

                      navigate("/feedbackPreview", {
                        state: {
                          feedbackId: feedbackId,
                          preview: false,
                          pending: false,
                          userFeedbackId: value,
                          courseScheduleId: courseScheduleId,
                        },
                      });
                    }}>
                    <i className="fas fa-eye"></i>
                  </a>
                </Tooltip>
              ) : (
                <Tooltip title="View">
                  <a
                    className="mr-2"
                    style={{ fontSize: "15px" }}
                    onClick={(e) => {
                      e.preventDefault();

                      navigate("/feedbackPreview", {
                        state: {
                          feedbackId: feedbackId,
                          preview: false,
                          pending: true,
                          userFeedbackId: value,
                          courseScheduleId: courseScheduleId,
                        },
                      });
                    }}>
                    <i className="fas fa-eye"></i>
                  </a>
                </Tooltip>
              )}
            </div>
          );
        },
      },
    },
  ];

  const defaultTheme = createTheme({});
  const { breakpoints } = defaultTheme;
  const tabsTheme = {
    ...defaultTheme,
    components: {
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: "0px !important",
            marginLeft: "-42px",
            [breakpoints.down("md")]: {
              minHeight: "0px !important",
              marginLeft: "0px",
            },
          },
        },
      },
    },
  };

  const onSubmitFilter = () => {
    if (
      feedbackForm?.length === 0 &&
      CourseName?.length === 0 &&
      journeyName?.length === 0
    ) {
      notify(
        1,
        "Please select at least one filter option from the grid view to perform the search action."
      );
    } else {
      setCount(0);
      setReSet(!reSet);
    }
  };

  const resetFilters = () => {
    setCount(0);
    setSearchText("");
    setFeedbackForm([]);
    setJourneyName([]);
    setCourseName([]);

    setReSet(!reSet);
  };
  return (
    <>
      <div className="breadcrumb modified-bredcrumb">
        <span>
          <ul>
            <li>
              <a href="/LMSHome">Home</a>
            </li>
            <li>User Feedback</li>
          </ul>
        </span>
      </div>
      <div className="container-fluid">
        <div className="d-flex align-items-center justify-content-between">
          {/* Tabs Are here */}
          <div className="col-lg-10 col-md-12 col-sm-12 ">
            <ThemeProvider theme={tabsTheme}>
              <Tabs
                value={activetabIndex}
                className="profile-tabs"
                onChange={() => {}}
                variant="scrollable"
                TabScrollButtonProps={{
                  style: {
                    color: "black",
                  },
                }}
                scrollButtons
                TabIndicatorProps={{
                  style: { display: "none" },
                }}
                allowScrollButtonsMobile
                aria-label="scrollable tabs">
                <div className="tabBtn mt-2">
                  <ul>
                    {tabs.map((tabName, index) => (
                      <li key={index}>
                        <button
                          onClick={() => {
                            setSectionValuesArray([]);
                            setStart(0); //imp
                            setActivetab(tabName);
                          }}
                          className={
                            tabName === activetab
                              ? "bttn active"
                              : "bttn border border-primary border-bottom-0"
                          }>
                          {tabName}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </Tabs>
            </ThemeProvider>
          </div>
        </div>

        <div className="grid-wrapper grid_mob">
          <div className="row mx-auto filtBox">
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="form-group filtInp">
                <InputForm
                  value={searchText}
                  placeholder={"Search Keywords"}
                  isDisabled={false}
                  textArea={false}
                  onChange={(e) => {
                    setCount(0);
                    setSearchText(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-12 filtrt">
              <ul className=" filter-icon-wel">
                <li>
                  <a
                    href="javascript:void(0);"
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                    className="filter-btn">
                    <i className="fa fa-filter"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <>
          <Collapse in={open}>
            <div id="example-collapse-text grid-wrapper">
              <div className="row mx-auto myInlineStyle">
                <>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">
                      Feedback Form
                    </label>
                    <SelectForm
                      isClearable
                      options={feedbackFormOptions}
                      placeholder={"Feedback Form"}
                      isDisabled={false}
                      onChange={(event) => {
                        setFeedbackForm(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={feedbackForm}
                    />
                  </div>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">
                      Journey Name
                    </label>
                    <SelectForm
                      isClearable
                      options={journeyNameOptions}
                      placeholder={"Journey Name"}
                      isDisabled={false}
                      onChange={(event) => {
                        setJourneyName(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={journeyName}
                    />
                  </div>

                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">
                      Course Name
                    </label>
                    <SelectForm
                      isClearable
                      options={CourseNameOptions}
                      placeholder={"Course Name"}
                      isDisabled={false}
                      onChange={(event) => {
                        setCourseName(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={CourseName}
                    />
                  </div>
                </>
                <div className="col-lg-12 d-flex mt-2 justify-content-end">
                  <button
                    onClick={() => resetFilters()}
                    className="btn btn-secondary mr-2">
                    <i className="fa fa-undo" aria-hidden="true"></i> Reset{" "}
                  </button>
                  <button
                    className="btn btn-filter-submit"
                    onClick={() => onSubmitFilter()}>
                    <i className="fa fa-search" aria-hidden="true"></i> Search{" "}
                  </button>
                </div>
              </div>
            </div>
          </Collapse>
        </>

        {/* Grid */}
        <div className="mb-3 pt-3">
          <DynamicGrid
            data={rowData}
            columns={gridColumns}
            options={gridOptions}
          />
        </div>
      </div>
    </>
  );
};

export default UserFeedbackDashboard;
