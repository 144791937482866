import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { LoaderContext } from "../../Helpers/Context/Context";
import { APICall } from "../../Helpers/APICalls";
import {
  GetContractorDetailsDropDown,
  GetContractorUserDasboardDetails,
  GetManageContractorUser,
  ManageContractorUser,
  SendEmailNotification,
} from "../../Helpers/APIEndPoints/AssociateEndPoints";
import AccordianCustom1 from "../DocumentScreen/AccordianCustom1";
import SelectForm from "../../Components/SelectForm/SelectForm";
import InputForm from "../../Components/InputForm/InputForm";
import { isEmpty } from "../../utils/isEmpty";
import { NationalIdCardType } from "../../Components/FromStructure/NationalIdCardTypeRegex";
import { Tooltip } from "@mui/material";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { join } from "path";
import notify from "../../Helpers/ToastNotification";
import { Phone } from "@mui/icons-material";
import { useDebounce } from "../../utils/useDebounce";

interface IOptions {
  value?: number | string;
  label?: string;
  code?: string;
}

interface IFormData {
  contractorName?: IOptions | string | any;
  firstName?: string | Number | any;
  lastName?: Number | string | any;
  emailId?: string | Number | any;
  mobileNumber?: string | Number | any;
  status?: IOptions | Number | any;
}

const ContractorUser = () => {
  const navigate = useNavigate();
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  const { showLoader, hideLoader } = useContext(LoaderContext);

  const [formErrors, setFormErrors] = useState<IFormData>({});

  const [formOptions, setFormOptions] = useState<IFormData>({});

  const [formData, setFormData] = useState<IFormData>({});

  const [id, setId] = useState(0);

  const [count, setCount] = useState(0);
  const [gridData, setGridData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sortDirection, setSortDirection] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [searchText, setSearchText] = useState("");

  const [reSet, setreSet] = useState(false);
  const debouncedValue = useDebounce(searchText);
  useEffect(() => {
    const responseData = async () => {
      const parameters = {
        Mode: "CONTRACTORNAME",
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      };
      const res = await APICall(
        GetContractorDetailsDropDown,
        "POST",
        parameters
      );

      if (res?.status === 0 && res?.data?.length > 0) {
        setFormOptions((env) => {
          return {
            ...env,
            contractorName: res?.data,
          };
        });
      }

      parameters.Mode = "STATUS";

      const res1 = await APICall(
        GetContractorDetailsDropDown,
        "POST",
        parameters
      );

      if (res1?.status === 0 && res1?.data?.length > 0) {
        setFormOptions((env) => {
          return {
            ...env,
            status: res1?.data,
          };
        });
      }
    };

    showLoader();

    responseData();

    hideLoader();
  }, []);

  const gridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: pageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      setPageNumber(page * pageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };
  const gridColumns = [
    {
      name: "Id",
      label: "Id",
      options: {
        display: false,
      },
    },

    {
      name: "userName",
      label: "User Name",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    // {
    //   name: "contractorName",
    //   label: "Contractor Name",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     sortDescFirst: true,
    //   },
    // },
    {
      name: "emailId",
      label: "Email Id",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "lastUpdatedDate",
      label: "Last Updated Date",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "lastUpdatedBy",
      label: "Last Updated By",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta) => {
          let id = tableMeta.tableData[tableMeta.rowIndex].id;
          let emailId = tableMeta.tableData[tableMeta.rowIndex].emailId;

          return (
            <div style={{ width: "100px" }}>
              <>
                <Tooltip title="Edit">
                  <a
                    className="mr-2"
                    style={{ fontSize: "15px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      GetManageContractorUserApiCall(id);
                    }}
                  >
                    <i className="fas fa-edit"></i>
                  </a>
                </Tooltip>

                <Tooltip title="Lock">
                  <a
                    className="mr-2"
                    style={{ fontSize: "15px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      sendEmailNotificationApiCall(emailId);
                    }}
                  >
                    <i className="fas fa-lock"></i>
                  </a>
                </Tooltip>
              </>
            </div>
          );
        },
      },
    },
  ];

  useEffect(() => {
    getContractorUserDasboardDetailsAPICall();
  }, [pageNumber, pageSize, sortColumn, sortDirection, debouncedValue, reSet]);

  const getContractorUserDasboardDetailsAPICall = async () => {
    try {
      showLoader();

      const requestParams = {
        PageNumber: pageNumber,
        PageSize: pageSize,
        SortColumn: sortColumn,
        SortOrder: sortDirection,
        SearchText: searchText,
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      };

      const responseData = await APICall(
        GetContractorUserDasboardDetails,
        "POST",
        requestParams
      );

      if (
        responseData &&
        responseData.data !== null &&
        responseData.data.length > 0
      ) {
        setGridData(responseData.data);
        setCount(responseData.data[0].totalCount);
      } else {
        setGridData([]);
        setCount(0);
      }
    } catch (error) {
      console.error("GetContractorUserDasboardDetails", error);
    } finally {
      hideLoader();
    }
  };

  const formOnChange = (e, type) => {
    switch (type) {
      case "contractorName":
        setFormData({
          ...formData,
          contractorName: e,
        });
        break;

      case "firstName":
        setFormData({
          ...formData,
          firstName: e.target.value,
        });
        break;

      case "lastName":
        setFormData({
          ...formData,
          lastName: e.target.value,
        });
        break;

      case "emailId":
        setFormData({
          ...formData,
          emailId: e.target.value,
        });
        break;

      case "mobileNumber":
        setFormData({
          ...formData,
          mobileNumber: e.target.value,
        });
        break;

      case "status":
        setFormData({
          ...formData,
          status: e,
        });
        break;

      default:
        break;
    }
  };

  const validateContractorUsersSection = () => {
    let errorObj = {};
    let isError = false;

    setFormErrors({});

    if (isEmpty(formData?.contractorName)) {
      errorObj = {
        ...errorObj,
        ["contractorName"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["contractorName"]: "",
      };
    }

    if (isEmpty(formData?.firstName)) {
      errorObj = {
        ...errorObj,
        ["firstName"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["firstName"]: "",
      };
    }

    if (isEmpty(formData?.lastName)) {
      errorObj = {
        ...errorObj,
        ["lastName"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["lastName"]: "",
      };
    }

    if (isEmpty(formData?.mobileNumber)) {
      errorObj = {
        ...errorObj,
        ["mobileNumber"]: "Required",
      };
    } else if (!NationalIdCardType.MOBILENO.test(formData?.mobileNumber)) {
      errorObj = {
        ...errorObj,
        ["mobileNumber"]: "Mobile Number is invalid",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["mobileNumber"]: "",
      };
    }

    if (isEmpty(formData?.emailId)) {
      errorObj = {
        ...errorObj,
        ["emailId"]: "Required",
      };
    } else if (!NationalIdCardType.EMAILID.test(formData?.emailId)) {
      errorObj = {
        ...errorObj,
        ["emailId"]: "Email Id is invalid",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["emailId"]: "",
      };
    }

    if (isEmpty(formData?.status)) {
      errorObj = {
        ...errorObj,
        ["status"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["status"]: "",
      };
    }

    const isEmptyObj = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmptyObj) {
      isError = true;
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  const handleContractorUsersSection = async () => {
    try {
      showLoader();
      if (validateContractorUsersSection()) {
        hideLoader();
        return;
      }

      const requestParams = {
        Id: id,
        ContractorIds: formData?.contractorName
          ?.map((val) => val.value)
          .join(","),
        FirstName: formData?.firstName,
        LastName: formData?.lastName,
        EmailId: formData?.emailId,
        MobileNumber: formData?.mobileNumber,
        IsActive: formData?.status?.value,
        CreatedBy: userDetails?.Id,
      };

      const response = await APICall(
        ManageContractorUser,
        "POST",
        requestParams
      );

      if (response?.status === 0) {
        notify(response?.status, response?.message);
        setId(0);
        handleResetSection();
        setreSet(!reSet);
      } else if (response?.status === 1) {
        notify(response?.status, response?.message);
      }
    } catch (error) {
      console.error("ManageContractorUser", error);
    } finally {
      hideLoader();
    }
  };

  const sendEmailNotificationApiCall = async (emailId) => {
    try {
      showLoader();

      const response = await APICall(SendEmailNotification, "POST", {
        EmailId: emailId,
      });

      if (response?.status === 0) {
        notify(response?.status, response?.message);
      } else if (response?.status === 1) {
        notify(response?.status, response?.message);
      }
    } catch (error) {
      console.error("SendEmailNotification", error);
    } finally {
      hideLoader();
    }
  };
  const GetManageContractorUserApiCall = async (id) => {
    try {
      showLoader();

      setId(id);

      const response = await APICall(GetManageContractorUser, "POST", {
        Id: id,
      });

      if (response?.status === 0) {
        const contractorUserdata = response?.data;

        const contractorIds = response?.data?.contractorIds;

        const contractorArray = [];
        if (contractorIds !== null) {
          for (const userId of contractorIds?.split(",")) {
            const contractorObj = formOptions?.contractorName.find(
              (option) => option.value == userId
            );
            contractorArray.push(contractorObj);
          }
        }

        const statusObj = contractorUserdata?.isActive
          ? formOptions?.status.find((val) => val?.value === 1)
          : formOptions?.status.find((val) => val?.value === 0);

        setFormData((env) => {
          return {
            contractorName: contractorArray,
            firstName: contractorUserdata?.firstName,
            lastName: contractorUserdata?.lastName,
            emailId: contractorUserdata?.emailId,
            mobileNumber: contractorUserdata?.mobileNumber,
            status: statusObj,
          };
        });
      } else if (response?.status === 1) {
        notify(response?.status, response?.message);
      }
    } catch (error) {
      console.error("GetManageContractorUser", error);
    } finally {
      hideLoader();
    }
  };

  const handleResetSection = () => {
    setFormErrors({});

    setId(0);

    setFormData({
      contractorName: null,
      firstName: "",
      lastName: "",
      emailId: "",
      mobileNumber: "",
      status: null,
    });
  };
  return (
    <>
      {/* <div className="form-main">
        <div className="page-title w-100">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <h4>
                  {" "}
                  <i
                    className="fa-solid fa-home pr-1"
                    style={{
                      color: "white",
                      cursor: "pointer",
                      fontSize: "13px",
                      verticalAlign: "middle",
                    }}
                    onClick={() => {
                      navigate("/Bluecollar");
                    }}
                  ></i>
                  Contractor Users
                </h4>
              </div>
              <div className="offset-lg-6 "></div>
            </div>
          </div>
        </div>
      </div> */}
      {/* </div> */}

      <div className="breadcrumb modified-bredcrumb">
        <h4>Contractor User</h4>
        <div className="SectionSubmit mb-2 clearfix">
          <button
            onClick={() => {
              navigate("/Home");
            }}
            disabled={false}
            className="btn btn-secondary float-right ml-2 mt-2"
          >
            <i className="fa-solid fa-arrow-left"></i> Back
          </button>
        </div>
      </div>

      <div className="container-fluid mt-2" id="blue-collar-pages">
        <div className="col-lg-12 mt-2">
          <AccordianCustom1
            header={"Contractor Users"}
            submitFunc={""}
            btnDisable={false}
            hideBtn={true}
          >
            <div className="row">
              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">Contractor Name</label>
                  <sup>*</sup>

                  <SelectForm
                    isClearable
                    isSearchable
                    options={formOptions?.contractorName}
                    placeholder={"Contractor Name"}
                    isDisabled={false}
                    value={formData?.contractorName}
                    onChange={async (e) => {
                      formOnChange(e, "contractorName");
                    }}
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  {formErrors["contractorName"] && (
                    <p style={{ color: "red" }}>
                      {formErrors["contractorName"]}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    First Name
                    <sup>*</sup>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"First Name"}
                    isDisabled={false}
                    textArea={false}
                    value={formData?.firstName}
                    onChange={(e: any) => {
                      formOnChange(e, "firstName");
                    }}
                  />
                  {formErrors["firstName"] && (
                    <p style={{ color: "red" }}>{formErrors["firstName"]}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Last Name
                    <sup>*</sup>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Last Name"}
                    isDisabled={false}
                    textArea={false}
                    value={formData?.lastName}
                    onChange={(e: any) => {
                      formOnChange(e, "lastName");
                    }}
                  />
                  {formErrors["lastName"] && (
                    <p style={{ color: "red" }}>{formErrors["lastName"]}</p>
                  )}
                </div>
              </div>

              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Email Id
                    <sup>*</sup>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Email Id"}
                    isDisabled={false}
                    textArea={false}
                    value={formData?.emailId}
                    onChange={(e: any) => {
                      formOnChange(e, "emailId");
                    }}
                  />
                  {formErrors["emailId"] && (
                    <p style={{ color: "red" }}>{formErrors["emailId"]}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Mobile Number
                    <sup>*</sup>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Mobile Number"}
                    isDisabled={false}
                    textArea={false}
                    value={formData?.mobileNumber}
                    onChange={(e: any) => {
                      formOnChange(e, "mobileNumber");
                    }}
                  />
                  {formErrors["mobileNumber"] && (
                    <p style={{ color: "red" }}>{formErrors["mobileNumber"]}</p>
                  )}
                </div>
              </div>

              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">Status</label>
                  <sup>*</sup>
                  <SelectForm
                    isClearable
                    isSearchable
                    options={formOptions?.status}
                    placeholder={"Status"}
                    isDisabled={false}
                    value={formData?.status}
                    onChange={async (e) => {
                      formOnChange(e, "status");
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  {formErrors["status"] && (
                    <p style={{ color: "red" }}>{formErrors["status"]}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="SectionSubmit mb-2 clearfix">
              <button
                onClick={() => {
                  handleContractorUsersSection();
                }}
                disabled={false}
                className="btn btn-filter-submit float-right ml-2 mt-4"
              >
                <i className="fa fa-save"></i> Save
              </button>

              <button
                onClick={() => {
                  handleResetSection();
                }}
                disabled={false}
                className="btn btn-secondary float-right ml-2 mt-4"
              >
                <i className="fa-solid fa-refresh"></i> Reset
              </button>
            </div>
          </AccordianCustom1>
        </div>

        <div className="grid-wrapper grid_mob">
          <div className="row mx-auto filtBox">
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="form-group filtInp">
                <InputForm
                  value={searchText}
                  placeholder={"Search Keywords"}
                  isDisabled={false}
                  textArea={false}
                  onChange={(e) => {
                    setPageNumber(0);
                    setSearchText(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-3 pt-3">
          <DynamicGrid
            data={gridData}
            columns={gridColumns}
            options={gridOptions}
          />
        </div>
      </div>
    </>
  );
};

export default ContractorUser;
