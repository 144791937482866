import "./OrganizationChart.css";
import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { OrganizationChart } from "primereact/organizationchart";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { Carousel } from "primereact/carousel";
import { Tooltip } from "primereact/tooltip";
import defaultProfileImgPic from "../../Assets/Images/user.jpg";
import { APICall } from "../../Helpers/APICalls";
import async from "react-select/dist/declarations/src/async/index";
import {
    getUserHirerarchEmployee,
    getUserHirerarchManagerEmployee,
    getUserHierarchyEmployeeChildrens,
    getUserEmployeeSearchableData,
    getProfilePicImg,
} from "../../Helpers/APIEndPoints/EndPoints";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { LoaderContext } from "../../Helpers/Context/Context";
import moment from "moment";

export default function TemplateDemo() {
    const navigate = useNavigate();
    const [employee, setEmployee] = useState([]);
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const [managerEmployee, setmanagerEmployee] = useState([]);
    let { userDetails, proxyUserDetails, currentRoleId }: any = useUserContext();
    const [data1, SetData1] = useState(
        // 13315
        proxyUserDetails != null
            ? proxyUserDetails?.EmployeeId
            : userDetails?.EmployeeId
    );
    const [searchValue, setSearchValue] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [clickedData, setClickedData] = useState({});
    const [profileImage, setProfileImage] = useState(null);
    const [profileManager, setProfileManager] = useState(null);
    const [profileEmployee, setProfileEmployee] = useState(null);
    const [profileChild, setProfileChild] = useState(null);

    //   useEffect(() => {
    //     showLoader();
    //     getUserHirerarchManagerEmployeeData();
    //     // SearchFunction("");
    //     hideLoader();
    //   }, [data1]);
    useEffect(() => {
        const fetchData = async () => {
            showLoader();
            await getUserHirerarchManagerEmployeeData();
            hideLoader();
        };

        fetchData();
    }, [data1]);

    // useEffect(() => {
    //     if (searchValue.length >= 0) {
    //         SearchFunction(searchValue);
    //     } else {
    //         setSearchResults([]);
    //     }
    // }, [searchValue]);
    //useEffect(() => {
    //    (async () => {
    //        const getPhoto = await APICall(getProfilePicImg, "POST", {
    //            TId: userDetails.EmployeeId,
    //            AC: "ProfilePic",
    //        });

    //        if (getPhoto && getPhoto.data !== null) {
    //            setProfileImage(`data:image;base64,${getPhoto.data}`);
    //        }
    //    })();
    //}, []);
    const SearchFunction = async (searchTerm) => {
        try {
            await showLoader();
            const actionEmployeeSearchableData = await APICall(
                getUserEmployeeSearchableData,
                "POST",
                {
                    searchtext: searchTerm,
                }
            );

            if (Array.isArray(actionEmployeeSearchableData)) {
                setSearchResults(actionEmployeeSearchableData);
            } else if (
                actionEmployeeSearchableData &&
                typeof actionEmployeeSearchableData === "object"
            ) {
                const searchData = actionEmployeeSearchableData.data;
                if (Array.isArray(searchData)) {
                    setSearchResults(searchData);
                } else {
                    console.error("Invalid response format: Expected an array.");
                }
            } else {
                console.error("Invalid response format: Expected an array or object.");
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            await hideLoader();
        }
    };

    const handleChange = (e) => {
        const searchTerm = e.target.value;
        setSearchValue(searchTerm);
        if (searchTerm.length >= 0) {
            SearchFunction(searchTerm);
        } else {
            setSearchResults([]);
        }
    };
    const handleClickSetToEmployee = (event, internalValue) => {
        event.preventDefault();
        SetData1(internalValue);
    };
    const handleSearchSetData = (event, searchdata) => {
        event.preventDefault();
        SetData1(searchdata);
        setSearchValue("");
    };

    const handleClickSetToEmployeeProfilePage = (value) => {
        let TId = value;
        let WorkflowTaskId = 0;
        let NewHireStatus = "Approved";
        /*let CT = "CODECNTRY1";*/

        if(proxyUserDetails){
            if (proxyUserDetails.EmployeeId === value){
                navigate("/profile", {
                    state: { TId, WorkflowTaskId, NewHireStatus /* CT*/ },
                });
            }
        } else {
            if(value === userDetails.EmployeeId){
                navigate("/profile", {
                    state: { TId, WorkflowTaskId, NewHireStatus /* CT*/ },
                });
            } 
        }
    };

    const getUserHirerarchManagerEmployeeData = async () => {
        try {
            // debugger
            showLoader();
            const actionLinksResponseManager = await APICall(
                getUserHirerarchManagerEmployee,
                "POST",
                {
                    // Id: userDetails?.EmployeeId
                    Id: data1,
                }
            );
            if (
                actionLinksResponseManager.data &&
                actionLinksResponseManager.data.length > 0
            ) {
                const actionLinksResponseEmployee = await APICall(
                    getUserHirerarchEmployee,
                    "POST",
                    {
                        Id: data1,
                    }
                );
                if (
                    actionLinksResponseEmployee.data &&
                    actionLinksResponseManager.data.length > 0
                ) {
                    const actionLinksResponseChildren = await APICall(
                        getUserHierarchyEmployeeChildrens,
                        "POST",
                        {
                            Id: data1,
                        }
                    );

                    const managerdata = actionLinksResponseManager.data[0]
                        ? actionLinksResponseManager.data[0]
                        : null;
                    const formattedmanagerdataDate = moment(managerdata?.lwd).isValid()
                        ? moment(managerdata.lwd).format("YYYY-MM-DD")
                        : null;
                    const employeedata = actionLinksResponseEmployee.data[0];
                    const formattedemployeedataDate =
                        employeedata && employeedata?.lwd
                            ? moment(employeedata.lwd).format("YYYY-MM-DD")
                            : null;
                    const firstChildIndex = 0;
                    const childrendata1 = actionLinksResponseChildren.data[0];
                    const childrendata =
                        actionLinksResponseChildren.data[firstChildIndex];
                    const formattedChildfirstDate =
                        childrendata && childrendata?.lwd
                            ? moment(childrendata.lwd).format("YYYY-MM-DD")
                            : null;

                    let updatednewchild = [];
                    if (!actionLinksResponseChildren.data.length) {
                        setChildData(null);
                        updatednewchild = [];
                    } else if (actionLinksResponseChildren.data.length == 1) {
                        const remainingChildrendata = actionLinksResponseChildren.data;

                        const transformedData = remainingChildrendata.map((item) => {
                            const formattedDate = item.lwd
                                ? moment(item.lwd).format("YYYY-MM-DD")
                                : null;
                            return {
                                label: item ? item.label : null,
                                empCode: item ? item.employeeCode : null,
                                empDesignation: item ? item.designation : null,
                                empStatus: item ? item.employeeStatus : null,
                                empFunction: item ? item.functionName : null,
                                empDepartment: item ? item.department : null,
                                empLocation: item ? item.physicalLocation : null,
                                empClass: item ? item.employeeClass : null,
                                empJobBand: item ? item.jobBand : null,
                                value: item ? item.value : null,
                                lwd: formattedDate,
                                imageurl: null,
                            };
                        });

                        // let arr = transformedData?.map((item) => {
                        //   const data = getChildImage(item?.value);

                        //   item.imageurl = data;

                        //   return item;
                        // });

                        // await Promise.all([arr]);
                        // updatednewchild = arr;
                        // setChildData(arr);
                        setChildData(transformedData);
                    } else if (
                        actionLinksResponseChildren.data.length >
                        firstChildIndex + 1
                    ) {
                        const remainingChildrendata =
                            actionLinksResponseChildren.data.slice(firstChildIndex + 1);
                        const transformedData = remainingChildrendata.map((item) => {
                            const formattedDate = item.lwd
                                ? moment(item.lwd).format("YYYY-MM-DD")
                                : null;
                            return {
                                label: item ? item.label : null,
                                empCode: item ? item.employeeCode : null,
                                empDesignation: item ? item.designation : null,
                                empStatus: item ? item.employeeStatus : null,
                                empFunction: item ? item.functionName : null,
                                empDepartment: item ? item.department : null,
                                empLocation: item ? item.physicalLocation : null,
                                empClass: item ? item.employeeClass : null,
                                empJobBand: item ? item.jobBand : null,
                                lwd: formattedDate,
                                value: item ? item.value : null,
                                imageurl: null,
                            };
                        });
                        const updatedfirstIndexchild = {
                            label: childrendata ? childrendata.label : null,
                            empCode: childrendata ? childrendata.employeeCode : null,
                            empDesignation: childrendata ? childrendata.designation : null,
                            empStatus: childrendata ? childrendata.employeeStatus : null,
                            empFunction: childrendata ? childrendata.functionName : null,
                            empDepartment: childrendata ? childrendata.department : null,
                            empLocation: childrendata ? childrendata.physicalLocation : null,
                            empClass: childrendata ? childrendata.employeeClass : null,
                            empJobBand: childrendata ? childrendata.jobBand : null,
                            lwd: formattedChildfirstDate,
                            value: childrendata ? childrendata.value : null,
                            imageurl: null,
                        };

                        let updatedChildData = [...transformedData];

                        // if (Array.isArray(childrendata) && childrendata.length > 0) {
                        //     updatedChildData = [...childrendata, ...transformedData];
                        // } else {
                        //     updatedChildData = transformedData;
                        // }
                        updatedChildData.push(updatedfirstIndexchild);
                        // console.log(updatedChildData);
                        // let arr1 = updatedChildData?.map(async (item) => {
                        //   const data = await getChildImage(item?.value);

                        //   item.imageurl = data;

                        //   return item;
                        // });
                        // showLoader();
                        // await Promise.all([arr1]);
                        // let arr1 = [];
                        // showLoader();
                        // for await (const item of updatedChildData) {
                        //   const data = await getChildImage(item?.value);
                        //   item.imageurl = data;
                        //   arr1.push(item);
                        // }
                        // hideLoader();
                        // updatednewchild = arr1;
                        setChildData(updatedChildData);
                        //loop
                    }

                    const upperHierarchy = [
                        {
                            label: managerdata.managerName,
                            empCode: managerdata.employeeCode,
                            empDesignation: managerdata.designation,
                            empStatus: managerdata.employeeStatus,
                            empFunction: managerdata.functionName,
                            empDepartment: managerdata.department,
                            empLocation: managerdata.physicalLocation,
                            empClass: managerdata.employeeClass,
                            empJobBand: managerdata.jobBand,
                            value: managerdata ? managerdata.value : null,
                            lwd: formattedmanagerdataDate,
                            imageurl: null,
                            lastChild: false,
                            expanded: true,
                            children: [
                                {
                                    label: employeedata.employeeName,
                                    empCode: employeedata.employeeCode,
                                    empDesignation: employeedata.designation,
                                    empStatus: employeedata.employeeStatus,
                                    empFunction: employeedata.functionName,
                                    empDepartment: employeedata.department,
                                    empLocation: employeedata.physicalLocation,
                                    empClass: employeedata.employeeClass,
                                    empJobBand: employeedata.jobBand,
                                    value: employeedata ? employeedata.value : null,
                                    lwd: formattedemployeedataDate,
                                    imageurl: null,
                                    lastChild: false,
                                    expanded: true,
                                    activeClass: "active",
                                    children: [
                                        {
                                            label: childrendata ? childrendata.label : null,
                                            empCode: childrendata ? childrendata.employeeCode : null,
                                            empDesignation: childrendata
                                                ? childrendata.designation
                                                : null,
                                            empStatus: childrendata
                                                ? childrendata.employeeStatus
                                                : null,
                                            empFunction: childrendata
                                                ? childrendata.functionName
                                                : null,
                                            empDepartment: childrendata
                                                ? childrendata.department
                                                : null,
                                            empLocation: childrendata
                                                ? childrendata.physicalLocation
                                                : null,
                                            empClass: childrendata
                                                ? childrendata.employeeClass
                                                : null,
                                            empJobBand: childrendata ? childrendata.jobBand : null,
                                            value: childrendata ? childrendata.value : null,
                                            lwd: childrendata ? childrendata.lwd : null,
                                            imageurl: null,
                                            lastChild: true,
                                            expanded: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ];
                    let managerValue = upperHierarchy[0]?.value || 0;
                    let employeeValue = upperHierarchy[0].children[0].value;
                    //loop
                    //upperHierarchy[0].children[0].children[0].value

                    const managerGetProfile = await getManagerPhotoAsync(managerValue);
                    const employeeGetProfile = await getEmployeeImage(employeeValue);
                    const updatedUpperHierarchy = [
                        {
                            label: managerdata.managerName,
                            empCode: managerdata.employeeCode,
                            empDesignation: managerdata.designation,
                            empStatus: managerdata.employeeStatus,
                            empFunction: managerdata.functionName,
                            empDepartment: managerdata.department,
                            empLocation: managerdata.physicalLocation,
                            empClass: managerdata.employeeClass,
                            empJobBand: managerdata.jobBand,
                            value: managerdata ? managerdata.value : null,
                            lwd: formattedmanagerdataDate,
                            imageurl: managerGetProfile,
                            lastChild: false,
                            expanded: true,
                            children: [
                                {
                                    label: employeedata.employeeName,
                                    empCode: employeedata.employeeCode,
                                    empDesignation: employeedata.designation,
                                    empStatus: employeedata.employeeStatus,
                                    empFunction: employeedata.functionName,
                                    empDepartment: employeedata.department,
                                    empLocation: employeedata.physicalLocation,
                                    empClass: employeedata.employeeClass,
                                    empJobBand: employeedata.jobBand,
                                    value: employeedata ? employeedata.value : null,
                                    lwd: formattedemployeedataDate,
                                    imageurl: employeeGetProfile,
                                    lastChild: false,
                                    expanded: true,
                                    activeClass: "active",
                                    children: [
                                        {
                                            label: childrendata ? childrendata.label : null,
                                            empCode: childrendata ? childrendata.employeeCode : null,
                                            empDesignation: childrendata
                                                ? childrendata.designation
                                                : null,
                                            empStatus: childrendata
                                                ? childrendata.employeeStatus
                                                : null,
                                            empFunction: childrendata
                                                ? childrendata.functionName
                                                : null,
                                            empDepartment: childrendata
                                                ? childrendata.department
                                                : null,
                                            empLocation: childrendata
                                                ? childrendata.physicalLocation
                                                : null,
                                            empClass: childrendata
                                                ? childrendata.employeeClass
                                                : null,
                                            empJobBand: childrendata ? childrendata.jobBand : null,
                                            value: childrendata ? childrendata.value : null,
                                            lwd: childrendata ? childrendata.lwd : null,
                                            imageurl: null,
                                            lastChild: true,
                                            expanded: true,
                                        },
                                    ],
                                },
                            ],
                        },
                    ];
                    setData(updatedUpperHierarchy);

                    if (managerdata.value == null || managerdata.value == undefined) {
                        const employeehierarchy = [
                            {
                                label: employeedata.employeeName,
                                empCode: employeedata.employeeCode,
                                empDesignation: employeedata.designation,
                                empStatus: employeedata.employeeStatus,
                                empFunction: employeedata.functionName,
                                empDepartment: employeedata.department,
                                empLocation: employeedata.physicalLocation,
                                empClass: employeedata.employeeClass,
                                empJobBand: employeedata.jobBand,
                                value: employeedata ? employeedata.value : null,
                                lwd: formattedemployeedataDate,
                                imageurl: employeeGetProfile,
                                lastChild: false,
                                expanded: true,
                                activeClass: "active",
                                children: [
                                    {
                                        label: childrendata ? childrendata.label : null,
                                        empCode: childrendata ? childrendata.employeeCode : null,
                                        empDesignation: childrendata
                                            ? childrendata.designation
                                            : null,
                                        empStatus: childrendata
                                            ? childrendata.employeeStatus
                                            : null,
                                        empFunction: childrendata
                                            ? childrendata.functionName
                                            : null,
                                        empDepartment: childrendata
                                            ? childrendata.department
                                            : null,
                                        empLocation: childrendata
                                            ? childrendata.physicalLocation
                                            : null,
                                        empClass: childrendata ? childrendata.employeeClass : null,
                                        empJobBand: childrendata ? childrendata.jobBand : null,
                                        value: childrendata ? childrendata.value : null,
                                        lwd: childrendata ? childrendata.lwd : null,
                                        imageurl: null,
                                        lastChild: true,
                                        expanded: true,
                                    },
                                ],
                            },
                        ];
                        setData(employeehierarchy);
                    }
                    if (childrendata?.value == null) {
                        const managerhierarchy = [
                            {
                                label: managerdata.managerName,
                                empCode: managerdata.employeeCode,
                                empDesignation: managerdata.designation,
                                empStatus: managerdata.employeeStatus,
                                empFunction: managerdata.functionName,
                                empDepartment: managerdata.department,
                                empLocation: managerdata.physicalLocation,
                                empClass: managerdata.employeeClass,
                                empJobBand: managerdata.jobBand,
                                value: managerdata ? managerdata.value : null,
                                lwd: formattedmanagerdataDate,
                                imageurl: managerGetProfile,
                                lastChild: false,
                                expanded: true,
                                children: [
                                    {
                                        label: employeedata.employeeName,
                                        empCode: employeedata.employeeCode,
                                        empDesignation: employeedata.designation,
                                        empStatus: employeedata.employeeStatus,
                                        empFunction: employeedata.functionName,
                                        empDepartment: employeedata.department,
                                        empLocation: employeedata.physicalLocation,
                                        empClass: employeedata.employeeClass,
                                        empJobBand: employeedata.jobBand,
                                        value: employeedata ? employeedata.value : null,
                                        lwd: formattedemployeedataDate,
                                        imageurl: employeeGetProfile,
                                        lastChild: false,
                                        expanded: true,
                                        activeClass: "active",
                                    },
                                ],
                            },
                        ];
                        setData(managerhierarchy);
                    }
                    // console.log(managerGetProfile, employeeGetProfile);
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            hideLoader();
        }
    };
    // console.log(profileManager, profileEmployee);
    const getManagerPhotoAsync = async (managerValue = 0) => {
        try {
            showLoader();
            const getPhoto = await APICall(getProfilePicImg, "POST", {
                TId: managerValue,
                AC: "ProfilePic",
            });
            // console.log(getPhoto);
            if (getPhoto && getPhoto.data !== null) {
                setProfileManager(`data:image;base64,${getPhoto.data}`);
                return `data:image;base64,${getPhoto.data}`;
            }
        } catch (error) {
            console.error(error);
        } finally {
            hideLoader();
        }
    };

    const getEmployeeImage = async (employeeValue = 0) => {
        try {
            showLoader();
            const getPhoto = await APICall(getProfilePicImg, "POST", {
                TId: employeeValue,
                AC: "ProfilePic",
            });
            // console.log(getPhoto);
            if (getPhoto && getPhoto.data !== null) {
                setProfileEmployee(`data:image;base64,${getPhoto.data}`);
                return `data:image;base64,${getPhoto.data}`;
            } else {
                setProfileEmployee(null);
            }
        } catch (error) {
            console.error(error);
        } finally {
            hideLoader();
        }
    };
    const getChildImage = async (Value = 0) => {
        try {
            showLoader();
            const getPhoto = await APICall(getProfilePicImg, "POST", {
                TId: Value,
                AC: "ProfilePic",
            });
            // console.log(getPhoto);
            if (getPhoto && getPhoto.data !== null) {
                return `data:image;base64,${getPhoto.data}`;
            }
        } catch (error) {
            console.error(error);
        } finally {
            hideLoader();
        }
    };
    // const getuserposition = async()=> {
    // //    // debugger
    // //     showLoader();
    // //     const actionLinksResponsePositionManager = await APICall(getUserHirerarchManagerPosition, "POST", {
    // //         Id: 36
    // //     });
    // //     //setmanagerEmployee(actionLinksResponse.data);

    // //     if (actionLinksResponsePositionManager.data && actionLinksResponsePositionManager.data.length > 0) {
    // //         const actionLinksResponsePositionEmployee = await APICall(getUserHirerarchEmployeePosition, "POST", {
    // //             PositionId: 5
    // //         });
    // //        // debugger
    // //         const managerposition = actionLinksResponsePositionManager?.data[0];
    // //         if(actionLinksResponsePositionEmployee.data && actionLinksResponsePositionEmployee.data.length > 0)
    // //         {

    // //             const employeeposition = actionLinksResponsePositionEmployee?.data[0];
    // //         const upperPositionHierarchy = [{
    // //             label: managerposition.employeeName,
    // //             empCode: managerposition.employeeCode,
    // //             empDesignation: managerposition.designation,
    // //             empStatus: managerposition.employeeStatus,
    // //             empFunction: managerposition.functionName,
    // //             empDepartment: managerposition.department,
    // //             empLocation: managerposition.physicalLocation,
    // //             empClass: managerposition.employeeClass,
    // //             empJobBand: managerposition.jobBand,
    // //             lastChild: false,
    // //             expanded: true,
    // //             children: [
    // //                 {
    // //                     label: employeeposition.employeeName,
    // //                     empCode: employeeposition.employeeCode,
    // //                     empDesignation: employeeposition.designation,
    // //                     empStatus: employeeposition.employeeStatus,
    // //                     empFunction: employeeposition.functionName,
    // //                     empDepartment: employeeposition.department,
    // //                     empLocation: employeeposition.physicalLocation,
    // //                     empClass: employeeposition.employeeClass,
    // //                     empJobBand: employeeposition.jobBand,
    // //                     lastChild: false,
    // //                     expanded: true,
    // //                     activeClass: 'active',
    // //                     children: [
    // //                         {
    // //                             label: 'Bhanu Singh',
    // //                             empCode: '40/25800',
    // //                             empDesignation: 'Managing Director',
    // //                             empStatus: 'Status Comes Here',
    // //                             empFunction: 'Function Comes Here',
    // //                             empDepartment: 'Department Comes Here',
    // //                             empLocation: 'Physical Location Comes Here',
    // //                             empClass: 'Employee Class Comes Here',
    // //                             empJobBand: 'Job Band Comes Here',
    // //                             expanded: true,
    // //                             lastChild: true
    // //                         }
    // //                     ]
    // //                 }
    // //             ]

    // //         }]
    // //         setData(upperPositionHierarchy);

    // //         }
    // //         else{
    // //             setData(null);
    // //         }
    // //         await hideLoader();
    // //     }

    // //     hideLoader();

    // }
    const [data, setData] = useState(null);
    console.log(data);
    // const [data,setData] = useState([
    //     {
    //        label: 'Rajesh Mandawewala',
    //        empCode: '37/30001',
    //        empDesignation: 'Managing Director',
    //        empStatus: 'Status Comes Here',
    //        empFunction: 'Function Comes Here',
    //        empDepartment: 'Department Comes Here',
    //        empLocation: 'Physical Location Comes Here',
    //        empClass: 'Employee Class Comes Here',
    //        empJobBand: 'Job Band Comes Here',
    //        lastChild: false,
    //        expanded: true,
    //        children: [
    //            {
    //                label: 'Ganesh Iyer',
    //                empCode: '40/25800',
    //                empDesignation: 'Managing Director',
    //                empStatus: 'Status Comes Here',
    //                empFunction: 'Function Comes Here',
    //                empDepartment: 'Department Comes Here',
    //                empLocation: 'Physical Location Comes Here',
    //                empClass: 'Employee Class Comes Here',
    //                empJobBand: 'Job Band Comes Here',
    //                lastChild: false,
    //                expanded: true,
    //                activeClass: 'active',
    //                children: [
    //                    {
    //                        label: 'Bhanu Singh',
    //                        empCode: '40/25800',
    //                        empDesignation: 'Managing Director',
    //                        empStatus: 'Status Comes Here',
    //                        empFunction: 'Function Comes Here',
    //                        empDepartment: 'Department Comes Here',
    //                        empLocation: 'Physical Location Comes Here',
    //                        empClass: 'Employee Class Comes Here',
    //                        empJobBand: 'Job Band Comes Here',
    //                        expanded: true,
    //                        lastChild: true
    //                    }
    //                ]
    //            }
    //        ]
    //     }

    // ]);

    const [childData, setChildData] = useState(
        null
        // {
        //     label: 'Bhanu Singh',
        //     empCode: '37/30001',
        //     empDesignation: 'Managing Director',
        //     empStatus: 'Status Comes Here',
        //     empFunction: 'Function Comes Here',
        //     empDepartment: 'Department Comes Here',
        //     empLocation: 'Physical Location Comes Here',
        //     empClass: 'Employee Class Comes Here',
        //     empJobBand: 'Job Band Comes Here',
        // },
        // {
        //     label: 'Suchet Singh',
        //     empCode: '40/25800',
        //     empDesignation: 'Managing Director',
        //     empStatus: 'Status Comes Here',
        //     empFunction: 'Function Comes Here',
        //     empDepartment: 'Department Comes Here',
        //     empLocation: 'Physical Location Comes Here',
        //     empClass: 'Employee Class Comes Here',
        //     empJobBand: 'Job Band Comes Here',
        // },
        // {
        //     label: 'Bhanu Singh',
        //     empCode: '37/30001',
        //     empDesignation: 'Managing Director',
        //     empSt atus: 'Status Comes Here',
        //     empFunction: 'Function Comes Here',
        //     empDepartment: 'Department Comes Here',
        //     empLocation: 'Physical Location Comes Here',
        //     empClass: 'Employee Class Comes Here',
        //     empJobBand: 'Job Band Comes Here',
        // },
        // {
        //     label: 'Suchet Singh',
        //     empCode: '40/25800',
        //     empDesignation: 'Managing Director',
        //     empStatus: 'Status Comes Here',
        //     empFunction: 'Function Comes Here',
        //     empDepartment: 'Department Comes Here',
        //     empLocation: 'Physical Location Comes Here',
        //     empClass: 'Employee Class Comes Here',
        //     empJobBand: 'Job Band Comes Here',
        // },
        // {
        //     label: 'Bhanu Singh',
        //     empCode: '37/30001',
        //     empDesignation: 'Managing Director',
        //     empStatus: 'Status Comes Here',
        //     empFunction: 'Function Comes Here',
        //     empDepartment: 'Department Comes Here',
        //     empLocation: 'Physical Location Comes Here',
        //     empClass: 'Employee Class Comes Here',
        //     empJobBand: 'Job Band Comes Here',
        // },
        // {
        //     label: 'Suchet Singh',
        //     empCode: '40/25800',
        //     empDesignation: 'Managing Director',
        //     empStatus: 'Status Comes Here',
        //     empFunction: 'Function Comes Here',
        //     empDepartment: 'Department Comes Here',
        //     empLocation: 'Physical Location Comes Here',
        //     empClass: 'Employee Class Comes Here',
        //     empJobBand: 'Job Band Comes Here',
        // },
    );

    const responsiveOptions = [
        {
            breakpoint: "1199px",
            numVisible: 3,
            numScroll: 1,
        },
        {
            breakpoint: "1024px",
            numVisible: 2,
            numScroll: 1,
        },
        {
            breakpoint: "991px",
            numVisible: 1,
            numScroll: 1,
        },
        {
            breakpoint: "767px",
            numVisible: 1,
            numScroll: 1,
        },
    ];

    const childTemplate = (nodeChild) => {
        const internalValue = nodeChild.value;
        return (
            <>
                {nodeChild.value && (
                    <div className="box_grid modified-box_grid">
                        <div className="media">
                            <div className="media-left">
                                <img
                                    alt={nodeChild.label}
                                    src={
                                        nodeChild.imageurl != null
                                            ? nodeChild.imageurl
                                            : defaultProfileImgPic
                                    }
                                    className="org_img"
                                    onClick={() =>
                                        handleClickSetToEmployeeProfilePage(nodeChild.value)
                                    }
                                />
                                <a
                                    href="#"
                                    className="view_user"
                                    data-pr-tooltip="View Reportees"
                                    data-pr-position="top"
                                    onClick={(event) =>
                                        handleClickSetToEmployee(event, nodeChild.value)
                                    }
                                >
                                    {" "}
                                    <i className="fa fa-users" aria-hidden="true"></i>{" "}
                                </a>
                            </div>
                            <div className="media-body">
                                <h4>{nodeChild.label}</h4>
                                <ul>
                                    <li>
                                        Employee Code : <span>{nodeChild.empCode}</span>
                                    </li>
                                    <li>
                                        Designation : <span>{nodeChild.empDesignation}</span>
                                    </li>
                                    <li>
                                        Employee Status : <span>{nodeChild.empStatus}</span>
                                    </li>
                                    <li>
                                        Function : <span>{nodeChild.empFunction}</span>
                                    </li>
                                    <li>
                                        Department : <span>{nodeChild.empDepartment}</span>
                                    </li>
                                    <li>
                                        Physical Location : <span>{nodeChild.empLocation}</span>
                                    </li>
                                    <li>
                                        Employee Class : <span>{nodeChild.empClass}</span>
                                    </li>
                                    <li>
                                        Job Band : <span>{nodeChild.empJobBand}</span>
                                    </li>
                                    {nodeChild.lwd !== null && nodeChild.lwd !== undefined 
                                    && (
                                        (currentRoleId.code !== "Employee") ||
                                        (currentRoleId.code === "Employee" && nodeChild.value === (proxyUserDetails?.EmployeeId || userDetails?.EmployeeId))
                                    )
                                    && (
                                        <li className="danger-Text-lwd">
                                            Last Working Day : <span>{nodeChild.lwd}</span>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    };

    const parentTemplate = (node) => {
        let internalValue1 = node.value;
        if (node.lastChild === true) {
            if (childData && childData.length === 1) {
                return (
                    <div className="child_grid">
                        {childData && (
                            <Carousel
                                value={childData}
                                numScroll={1}
                                numVisible={3}
                                responsiveOptions={responsiveOptions}
                                itemTemplate={childTemplate}
                            />
                        )}
                    </div>
                );
            } else if (childData && childData.length > 1) {
                return (
                    <div className="child_grid">
                        {childData && (
                            <Carousel
                                key={childData}
                                value={childData}
                                numScroll={1}
                                numVisible={3}
                                responsiveOptions={responsiveOptions}
                                itemTemplate={childTemplate}
                            />
                        )}
                    </div>
                ); // Your existing logic for rendering multiple child items
            }
        } else {
            return (
                <>
                    {node.value && (
                        <div
                            className={`flex flex-column align-items-center box_grid modified-box_grid parentNode ${node.activeClass ? node.activeClass : ""
                                }`}
                        >
                            <div className="media">
                                <div className="media-left">
                                    {/*{node.value && <h1>hello</h1> }*/}
                                    <img
                                        alt={node.label}
                                        src={
                                            node.imageurl != null
                                                ? node.imageurl
                                                : defaultProfileImgPic
                                        }
                                        className="org_img"
                                        onClick={() =>
                                            handleClickSetToEmployeeProfilePage(node.value)
                                        }
                                    />
                                    {!node.activeClass && (
                                        <a
                                            href="#"
                                            className="view_user"
                                            data-pr-tooltip="View Reportees"
                                            data-pr-position="top"
                                            onClick={(event) =>
                                                handleClickSetToEmployee(event, node.value)
                                            }
                                        >
                                            {" "}
                                            <i className="fa fa-users" aria-hidden="true"></i>{" "}
                                        </a>
                                    )}
                                </div>
                                <div className="media-body">
                                    <h4>{node.label}</h4>
                                    <ul>
                                        <li>
                                            Employee Code : <span>{node.empCode}</span>
                                        </li>
                                        <li>
                                            Designation : <span>{node.empDesignation}</span>
                                        </li>
                                        <li>
                                            Employee Status : <span>{node.empStatus}</span>
                                        </li>
                                        <li>
                                            Function : <span>{node.empFunction}</span>
                                        </li>
                                        <li>
                                            Department : <span>{node.empDepartment}</span>
                                        </li>
                                        <li>
                                            Physical Location : <span>{node.empLocation}</span>
                                        </li>
                                        <li>
                                            Employee Class : <span>{node.empClass}</span>
                                        </li>
                                        <li>
                                            Job Band : <span>{node.empJobBand}</span>
                                        </li>
                                        {node.lwd !== null && node.lwd !== undefined 
                                        && (
                                            (currentRoleId.code !== "Employee") ||
                                            (currentRoleId.code === "Employee" && node.value === (proxyUserDetails?.EmployeeId || userDetails?.EmployeeId))
                                        ) 
                                        && (
                                            <li className="danger-Text-lwd">
                                                Last Working Day : <span>{node.lwd}</span>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            );
        }
    };

    // const onChangeValue = (e,eventChange) => {
    //     showLoader()
    //    // debugger
    //     if(eventChange === "Employee")
    //     {

    //         setEventNane(eventChange);

    //     }
    //     else{

    //         setEventNane(eventChange);
    //     }
    //     setOrg(e.target.value);
    //     hideLoader();
    // }

    return (
        <>
            <div className="breadcrumb modified-bredcrumb">
                <span>
                    <ul>
                        <li>
                            <a href="/home">Home</a>
                        </li>
                        {/* <li>
            <a href="/employee">Employee</a>
          </li> */}
                        <li>Profile</li>
                    </ul>
                </span>
                <button
                    onClick={() => {
                        navigate(-1);
                    }}
                    disabled={false}
                    className="btn btn-secondary float-right"
                >
                    <i className="fa-solid fa-arrow-left"></i> Back
                </button>
            </div>
            <div className="d-flex justify-content-start gap-3 radio_search modified-radio_search">
                {/* <div className="d-flex align-items-center">
                    <RadioButton inputId="org1" name="org" value='Employee' onChange={ (e) => onChangeValue(e,"Employee")} checked={org === 'Employee'} />
                    <label htmlFor="org1" className="ml-2 mb-0">Employee</label>
                </div> */}
                {/* <div className="d-flex align-items-center">
                    <RadioButton inputId="org2" name="org" value='Position' onChange={(e) => onChangeValue(e,"Position")} checked={org === 'Position'} />
                    <label htmlFor="org2" className="ml-2 mb-0">Position</label>
                </div> */}

                <div>
                    {/*<span className="p-input-icon-right">*/}
                    {/*  <i className="fa fa-search" />*/}
                    {/*  <InputText*/}
                    {/*    value={searchValue}*/}
                    {/*    onChange={handleChange}*/}
                    {/*    placeholder="Search Organization Chart"*/}
                    {/*  />*/}
                    {/*  {searchValue && (*/}
                    {/*    <div className="Search_Result_div py-2">*/}
                    {/*      {searchResults.map((result) => (*/}
                    {/*        <div className="Search_Result_p">*/}
                    {/*          <p*/}
                    {/*            onClick={(event) => handleSearchSetData(event, result.id)}*/}
                    {/*          >*/}
                    {/*            {result.employeeName}*/}
                    {/*          </p>*/}
                    {/*        </div>*/}
                    {/*      ))}*/}
                    {/*      {searchResults.length < 1 && (*/}
                    {/*        <div className="Search_Result_p">*/}
                    {/*          <p>{"No result found"}</p>*/}
                    {/*        </div>*/}
                    {/*      )}*/}
                    {/*    </div>*/}
                    {/*  )}*/}
                    {/*</span>*/}
                </div>
            </div>
            <div className="org_card modified-org_card">
                {data && (
                    <>
                        <OrganizationChart value={data} nodeTemplate={parentTemplate} />
                        <Tooltip target=".view_user" />
                    </>
                )}
            </div>

            {/* <div className="p-2">
                {employee.map((itemEmp) => (<>
                    <div className="box_grid">
                        <div className="media">
                            <div className="media-left">
                                <img src={defaultProfileImgPic} className="org_img" />
                                <a href="/" className="view_user" data-pr-tooltip="View User" data-pr-position="top"> <i className="fa fa-users" aria-hidden="true"></i> </a>
                            </div>
                            <div className="media-body">
                                <h4>{itemEmp.employeeName}</h4>
                                <ul>
                                    <li>Employee Code <span>{itemEmp.employeeCode}</span></li>
                                    <li>Designation <span>{itemEmp.designation}</span></li>
                                    <li>Employee Status <span>{itemEmp.employeeStatus}</span></li>
                                    <li>Function <span>{itemEmp.functionName}</span></li>
                                    <li>Department <span>{itemEmp.department}</span></li>
                                    <li>Physical Location <span>{itemEmp.physicalLocation}</span></li>
                                    <li>Employee Class <span>{itemEmp.employeeClass}</span></li>
                                    <li>Job Band <span>{itemEmp.jobBand}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </>
                ))}
            </div> */}

            {/* <div className="p-2">
                {managerEmployee.map((itemMang) => (<>
                    <div className="box_grid">
                        <div className="media">
                            <div className="media-left">
                                <img src={defaultProfileImgPic} className="org_img" />
                                <a href="/" className="view_user" data-pr-tooltip="View User" data-pr-position="top"> <i className="fa fa-users" aria-hidden="true"></i> </a>
                            </div>
                            <div className="media-body">
                                <h4>{itemMang.employeeName}</h4>
                                <ul>
                                    <li>Employee Code <span>{itemMang.employeeCode}</span></li>
                                    <li>Designation <span>{itemMang.designation}</span></li>
                                    <li>Employee Status <span>{itemMang.employeeStatus}</span></li>
                                    <li>Function <span>{itemMang.functionName}</span></li>
                                    <li>Department <span>{itemMang.department}</span></li>
                                    <li>Physical Location <span>{itemMang.physicalLocation}</span></li>
                                    <li>Employee Class <span>{itemMang.employeeClass}</span></li>
                                    <li>Job Band <span>{itemMang.jobBand}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </>
                ))}
            </div> */}
        </>
    );
}
