import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import InputForm from "../../Components/InputForm/InputForm";
import ButtonForm from "../../Components/ButtonForm/ButtonForm";
import { APICall } from "../../Helpers/APICalls";
import { Modal, OverlayTrigger, Popover } from "react-bootstrap";
import ExcelJS from "exceljs";
import {
  getOnboardingCheckListDetails,
  createTaskChecklistWorkFlow,
  getEmployeedetailsBasedOnCandidateId,
  getDocuments,
  documentUpload,
  getWorkFlowIdBasedOnTaskIdandUserId,
  getProfilePicForOnboarding,
  getOnBoardingPermission,
} from "../../Helpers/APIEndPoints/EndPoints";
import { Tabs, ThemeProvider, Tooltip } from "@mui/material";
import moment from "moment";
import { LoaderContext } from "../../Helpers/Context/Context";
import notify from "../../Helpers/ToastNotification";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import "./OnBoardingCheckList.css";
import { tabsTheme } from "../MRF/MRFHelper";
import defaultProfileImgPic from "../../Assets/Images/profile.png";
import { Email } from "@mui/icons-material";

const OnBoardingCheckList = () => {
  let navigate = useNavigate();
  const { state } = useLocation();
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [searchText, setSearchText] = useState("");
  const [sortDirection, setSortDirection] = useState("");
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(100000);
  const [start, setStart] = useState(0);
  const [sortColumn, setSortColumn] = useState("");
  const [isSelectRow, setSelectedRows] = useState([]);
  const [AllStatus, setAllStatus] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const [onBoardingData, setOnBoardingData] = useState(" 123456, 03 Jun 2023");
  const [name, setName] = useState("Firstname, Lastname");
  const [employeeClass, setEmployeeClass] = useState("Employee Class");
  const [designation, setDesignation] = useState("Designation");
  const [canHeader, setCanHeader] = useState("CandidteId");
  const [email, setEmail] = useState("Email Id");
  const [phone, setPhone] = useState("Mobile Number");
  const [jobTitle, setJobTitle] = useState("Job Title");
  const [legalEntity, setLegalEntity] = useState("Legal Entity");
  const [location, setLocation] = useState("LocationMU | FunctionBU");
  const [dateOfJoining, setDateOfJoining] = useState("10 july 2023");
  const [status, setStatus] = useState("In Progress");
  const [showModel, setShowModel] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);
  const [dashboardStatusData, setDashboarStatusdData] = useState([]);
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  const TabsData = [
    { Id: "Status", TN: "Status" },
    { Id: "CheckList", TN: "CheckList" },
  ];
  const [activetab, setActivetab] = useState<string>("Status");
  const [activetabId, setActivetabId] = useState<number>(1);
  const [profileImg, setProfileImg] = useState(null);
  const [IsEdit, setIsEdit] = useState(false);
  const [selectedFilesForFlat, setSelectedFilesForFlat] = useState<
    {
      AC: string;
      Name: string;
      Data: string;
      IsValidFile: boolean;
      DocId: Number;
      Type: string;
      Context: string;
      DCVALUE: string;
      DVTILL: string;
      DSTATUS: string;
      DPSINCE: string;
      DPWITH: string;
    }[]
  >([]);

  useEffect(() => {
    if (state !== null && state && state > 0) {
      getDashboardData();
      getPermission();
    }
  }, []);

  useEffect(() => {
    if (canHeader != "" && canHeader != "CandidteId") {
      (async () => {
        await showLoader();
        const getPhoto = await APICall(getProfilePicForOnboarding, "POST", {
          TId: canHeader,
          AC: "ProfilePic",
        });

        if (getPhoto && getPhoto.data !== null) {
          setProfileImg(`data:image;base64,${getPhoto.data}`);
        }
        await hideLoader();
      })();
    }
  }, [canHeader]);

  const getDashboardData = async () => {
    showLoader();
    let obj = {
      Id: state,
    };

    const resp = await APICall(getOnboardingCheckListDetails, "POST", obj);

    if (resp?.data?.length > 0) {
      const alreadySelectedData = resp?.data[0]
        .filter((dd: any) => dd.isMandatory || dd.isChecked || dd.isTaskCreated)
        .map((dd: any) => dd.id || dd.Id);
      setSelectedRows(alreadySelectedData);
      let checklistData = resp?.data[0];
      let StatusDasboardData = resp?.data[1];
      let dashboard = resp?.data[0].sort(
        (a: any, b: any) => b.isMandatory - a.isMandatory
      );

      dashboard.map((res) => {
        res.responsiblePerson = "";
        if (res.userData != null && res.userData?.length >= 1) {
          for (let index = 0; index < res.userData?.length; index++) {
            const val = res.userData[index];
            res.responsiblePerson += val.firstName + " " + val.lastName + ", ";
            res.role = val.userRole + ", ";
          }
          res.role = res.role.slice(0, -2);
          res.responsiblePerson = res.responsiblePerson.slice(0, -2);
        }
        return res;
      });
      let stat = [];
      StatusDasboardData.map((item) => {
        if (
          item.taskGroup === "Data Review" ||
          item.taskGroup === "Data Collection" ||
          item.taskGroup === "Document Collection"
        ) {
          let CStat = item.status.split(",");
          let stt =
            CStat.find((i) => i == "Completed") != undefined
              ? CStat.find((i) => i == "Completed")
              : CStat[0];
          stat.push(stt);
          setAllStatus(stat);
        }
      });

      for(let i=0; i < StatusDasboardData.length; i++){
        if(StatusDasboardData[i].taskGroup === "Pre-Joining"){
          let CStat = StatusDasboardData[i].status.split(",");
          if(CStat.some((x) => x === "Completed")){
            StatusDasboardData[i].status = "Completed";
          }
        }
      }
      
      console.log(stat);
      setDashboardData(dashboard);
      setDashboarStatusdData(StatusDasboardData);
      console.log("StatusDasboardData::::", StatusDasboardData);
      let onBoardingNo =
        resp?.data[0][0]?.legalEntity[0].onBoardingNo != null ||
        resp?.data[0][0]?.legalEntity[0].onBoardingNo != ""
          ? resp?.data[0][0]?.legalEntity[0].onBoardingNo
          : "";
      let dn =
        resp?.data[0][0]?.legalEntity[0].dn != null ||
        resp?.data[0][0]?.legalEntity[0].dn != ""
          ? resp?.data[0][0]?.legalEntity[0].dn
          : "";

      let jobTitle =
        resp?.data[0][0]?.legalEntity[0].jobTitle != null ||
        resp?.data[0][0]?.legalEntity[0].jobTitle != ""
          ? resp?.data[0][0]?.legalEntity[0].jobTitle
          : "";
      let legalEntity =
        resp?.data[0][0]?.legalEntity[0].legalEntity != null ||
        resp?.data[0][0]?.legalEntity[0].legalEntity != ""
          ? resp?.data[0][0]?.legalEntity[0].legalEntity
          : "";
      let status =
        resp?.data[0][0]?.legalEntity[0].status != null ||
        resp?.data[0][0]?.legalEntity[0].status != ""
          ? resp?.data[0][0]?.legalEntity[0].status
          : "";
      let location =
        resp?.data[0][0]?.legalEntity[0].location != null ||
        resp?.data[0][0]?.legalEntity[0].location != ""
          ? resp?.data[0][0]?.legalEntity[0].location
          : "";
      let dateOfJoining =
        resp?.data[0][0]?.legalEntity[0]?.dateOfJoining !== null
          ? moment(resp?.data[0][0]?.legalEntity[0]?.dateOfJoining).format(
              "DD MMM YYYY"
            )
          : "";

      let EmployeeClass =
        resp?.data[0][0]?.legalEntity[0].dn != null ||
        resp?.data[0][0]?.legalEntity[0].dn != ""
          ? resp?.data[0][0]?.legalEntity[0].employeeClass
          : "";

      let Designation =
        resp?.data[0][0]?.legalEntity[0].dn != null ||
        resp?.data[0][0]?.legalEntity[0].dn != ""
          ? resp?.data[0][0]?.legalEntity[0].designation
          : "";
      let EmailId =
        resp?.data[0][0]?.legalEntity[0].dn != null ||
        resp?.data[0][0]?.legalEntity[0].dn != ""
          ? resp?.data[0][0]?.legalEntity[0].email
          : "";
      let PhoneNum =
        resp?.data[0][0]?.legalEntity[0].dn != null ||
        resp?.data[0][0]?.legalEntity[0].dn != ""
          ? resp?.data[0][0]?.legalEntity[0].phone
          : "";
      let CanHeaderId =
        resp?.data[0][0]?.legalEntity[0].candidateId != null ||
        resp?.data[0][0]?.legalEntity[0].candidateId != ""
          ? resp?.data[0][0]?.legalEntity[0].candidateId
          : "";

      setEmployeeClass(EmployeeClass);
      setDesignation(Designation);
      setEmail(EmailId);
      setPhone(PhoneNum);

      setJobTitle(jobTitle);
      setLegalEntity(legalEntity);
      setOnBoardingData(onBoardingNo);
      setName(dn);
      setStatus(status);
      setLocation(location);
      setDateOfJoining(dateOfJoining);
      setCanHeader(CanHeaderId);
      await hideLoader();
    } else {
      setDashboardData([]);
      hideLoader();
    }
  };

  const getPermission = async () => {
    showLoader();
    let obj = {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    };

    const Perm = await APICall(getOnBoardingPermission, "POST", obj);
    if (Perm != null && Perm.data !== null && Perm.data != undefined) {
      if (Perm.data.length > 0) {
        Perm.data.forEach((pr) => {
          if (pr.name === "Edit") {
            setIsEdit(true);
          }
        });
      }
    }
  };

  const resetFunction = () => {
    setIsAllSelected(false);
    setSelectedRows([]);
    getDashboardData();
  };

  const onClickFunction = async (action: any) => {
    showLoader();
    if (action === "Submit") {
      let inserParam = dashboardData.map((res) => {
        let newRes = {
          id: res.id,
          roleId: res.roleId,
          roleTypeId: res.roleTypeId,
          workFlowId: res.workFlowId,
          workFlowTaskId: res.workFlowTaskId,
          workFlowInstanceId: res.workFlowInstanceId,
          trackClosure: res.trackClosure,
          requestId: res.requestId,
          isTaskCreated: res.isTaskCreated,
          isChecked: res.isChecked,
          pmbusn: res.pmbusn,
          pmsbu: res.pmsbu,
          pmlegen: res.pmlegen,
          pmlocn: res.pmlocn,
          emailTemplateId: res.emailTemplateId,
          CandidateId: res?.userData?.length > 0 ? res.userData[0].userId : 0,
          CID: res.cid,
          CreatedBy: userDetails?.Id,
        };

        if (isSelectRow.length >= 1) {
          for (let jindex = 0; jindex < isSelectRow.length; jindex++) {
            if (res.id == isSelectRow[jindex]) {
              newRes.isChecked = true;
            }
          }
          return newRes;
        }
      });
      const resp = await APICall(
        createTaskChecklistWorkFlow,
        "POST",
        inserParam
      );

      if (resp?.status == 0) {
        notify(0, "Data Saved Successfully.");
        //   //Copying Documents after Creation of Employee
        //   const getDocument = await APICall(getDocuments, "POST", {
        //     TId:dashboardData[0].cid,
        //     AC: "AGJARES",
        //   });

        //   if (getDocument && getDocument.data !== null) {
        //     setSelectedFilesForFlat(getDocument.data.files);
        //   if (
        //     getDocument.data.files.length > 0
        //   ) {
        //     let newFiles = selectedFilesForFlat.filter((sf) => {
        //       return sf.DocId === 0;
        //     });
        //     let temp = [];
        //     newFiles.map((item) => {
        //       let obj = item;
        //       obj = { ...item, AC: "EMPRES" };
        //       temp.push(obj);
        //     });

        //     newFiles = temp;
        //     try {
        //       const response = await APICall(
        //         documentUpload,
        //         "POST",
        //         {
        //           ModuleName: "EmployeeCentral",
        //           files: newFiles,
        //           SectionName: "Basic",
        //           TabName: "Personal",
        //           TId: resp.Data.EId,
        //           ModuleId: 1,
        //           UserId: proxyUserDetails?.EmployeeId
        //             ? proxyUserDetails?.EmployeeId
        //             : userDetails.UserId,
        //           ProxyUserId:
        //             proxyUserDetails === null
        //               ? null
        //               : proxyUserDetails?.value,
        //         }
        //       );

        //       if (
        //         response.data === null ||
        //         response.data === undefined
        //       ) {
        //         notify(1, "Failed to upload documents.");
        //       }
        //     } catch (error) {
        //       throw new Error(
        //         "Error uploading documents: " + error.message
        //       );
        //     }
        //   }
        // }
        //Copying Documents after Creation of Employee
        navigate(-1); // add here URL
      } else {
        notify(1, "Something went wrong.");
      }
    } else if (action === "Reset") {
      resetFunction();
    } else if (action === "Cancel") {
      navigate(-1);
    } else if (action === "Reject") {
      setShowModel(false);
    }
    hideLoader();
  };

  const redirectToCandidateDocUpload = async (
    CandidateUserId,
    TaskId,
    UserId,
    workFlowInstanceID,
    Role,
    TaskGroup,
    IsTaskCompleted
  ) => {
    const resp = await APICall(getEmployeedetailsBasedOnCandidateId, "POST", {
      CandidateId: CandidateUserId,
    });
    const response = await APICall(
      getWorkFlowIdBasedOnTaskIdandUserId,
      "POST",
      {
        WorkFlowInstanceId: workFlowInstanceID,
        TaskID: TaskId,
        UserId: UserId,
        ResponsibleRole: Role,
      }
    );
    const Id = response?.data ? response?.data?.workFlowTaskId : 0;
    const TId = resp.data.tId;
    if (TaskGroup === "Document Collection") {
      navigate("/CandidateOnboardingApprovalDashboard", {
        state: { Id, EID: TId, IsTaskCompleted: IsTaskCompleted, IsEdit },
      });
    } else if (TaskGroup === "Pre-Joining") {
      navigate("/OnBoardingCheckList/ApprovalDashboard", {
        state: {
          Id,
          EID: TId,
          IsTaskCompleted: IsTaskCompleted,
          Role: Role,
          IsEdit,
        },
      });
    }
  };

  const redirectToCandidateReview = async (
    CandidateUserId,
    TaskId,
    UserId,
    workFlowInstanceID,
    Role,
    TaskGroup,
    IsTaskCompleted
  ) => {
    const resp = await APICall(getEmployeedetailsBasedOnCandidateId, "POST", {
      CandidateId: CandidateUserId,
    });

    const TId = resp.data.tId;
    const NewHireStatus = resp.data.newHireStatus;
    const CT = resp.data.ct;

    const response = await APICall(
      getWorkFlowIdBasedOnTaskIdandUserId,
      "POST",
      {
        WorkFlowInstanceId: workFlowInstanceID,
        TaskID: TaskId,
        UserId: UserId,
        ResponsibleRole: Role,
      }
    );

    const WorkFlowTaskId = response?.data ? response?.data?.workFlowTaskId : 0;
    const StatusOutcome = response?.data ? response?.data?.outcome : "";

    navigate("/CandidateFormReview", {
      state: {
        TId,
        NewHireStatus,
        CT,
        CandidateUserId,
        WorkFlowTaskId,
        StatusOutcome,
        IsEdit,
      },
    });
  };

  const redirectToCandidateForm = async (CandidateUserId) => {
    const resp = await APICall(getEmployeedetailsBasedOnCandidateId, "POST", {
      CandidateId: CandidateUserId,
    });

    const DecisionSystemName = "Completed";
    const TId = resp.data.tId;
    const NewHireStatus = resp.data.newHireStatus;
    const CT = resp.data.ct;
    const CandidateWorkflowTaskId = 0;
    const isEdit = false;

    navigate("/candidateform", {
      state: {
        TId,
        CandidateWorkflowTaskId,
        NewHireStatus,
        CT,
        isEdit,
        DecisionSystemName,
        designation,
        legalEntity,
        location,
        jobTitle,
      },
    });
  };

  const Options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: pageSize,
    serverSide: true,
    rowsPerPageOptions: [] as any[],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    onChangeRowsPerPage: () => {
      // num
      //   setLimit(num);
      //   setNxtPgInfo("");
      //   setPrevPgInfo("");
      //   setIsPrevOrNext("");
    },
    onSearchChange: (searchText) => {
      if (searchText !== null) {
        setSearchText(searchText);
      } else {
        setSearchText("");
      }
      setStart(0);
    },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
      setStart(0);
    },
    onChangePage: async (page) => {
      setStart(page * pageSize);
    },
  };

  const StatusGridColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        display: false,
      },
    },
    {
      name: "userIds",
      label: "UserIds",
      options: {
        display: false,
      },
    },
    {
      name: "candidateUserId",
      label: "candidateUserId",
      options: {
        display: false,
      },
    },
    {
      name: "workFlowInstanceID",
      label: "WorkFlowInstanceID",
      options: {
        display: false,
      },
    },
    {
      name: "cid",
      label: "cid",
      options: {
        display: false,
      },
    },
    {
      name: "userIds",
      label: "cid",
      options: {
        display: false,
      },
    },
    {
      name: "id",
      label: "taskID",
      options: {
        display: false,
      },
    },
    {
      name: "responsibleRoleSystemName",
      label: "taskID",
      options: {
        display: false,
      },
    },
    {
      name: "taskGroup",
      label: "Task Group",
      options: {
        display: true,
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
      },
    },
    {
      name: "task",
      label: "Task",
      options: {
        display: true,
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
      },
    },
    {
      name: "responsibleRole",
      label: "Responsible Role",
      options: {
        display: true,
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
      },
    },
    {
      name: "responsible",
      label: "Responsible Person",
      options: {
        display: true,
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
      },
    },
    {
      name: "createdDateUtc",
      label: "Initiated Date",
      options: {
        display: true,
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        customBodyRender: (value: any, tableMeta: any) => {
          return <>{moment.utc(value).local().format("DD MMM YYYY hh:mm A")}</>;
        },
      },
    },
    {
      name: "dueDate",
      label: "Due Date",
      options: {
        display: true,
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              {value != null
                ? moment(value, "DD-MM-YYYY HH:mm:ss")?.isValid()
                  ? moment(value, "DD-MM-YYYY HH:mm:ss").format("DD MMM YYYY")
                  : ""
                : ""}
            </>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        display: true,
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        customBodyRender: (value: any, tableMeta: any) => {
          const Role = tableMeta.tableData[tableMeta.rowIndex].responsibleRoleSystemName;
          const UserId =
            Role === "ONBBUHR"
              ? tableMeta.tableData[tableMeta.rowIndex].userIds
                  .split(",")
                  .find((x) => x === userDetails.Id.toString())
              : tableMeta.tableData[tableMeta.rowIndex].userIds;
          
          const StatIndex = tableMeta.tableData[tableMeta.rowIndex].userIds
            .split(",")
            .indexOf(UserId);
          const Status =
            tableMeta.tableData[tableMeta.rowIndex].status?.split(",")[
              StatIndex
            ];
          let val =(value!=null && value!=undefined)?value.split(",") : '' ;
          let Valu= val!=''?val.filter((x)=>x =="Completed"): [];
          let FinalStat = Valu.length > 0 ? Valu.toString(): Status != undefined ? Status : val!=''?val[0].toString() :''  ; 
          return <>{FinalStat}</>;
        },
      },
    },
    {
      name: "",
      label: "Action",
      options: {
        display: true,
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        customBodyRender: (value: any, tableMeta: any) => {
          const Role = tableMeta.tableData[tableMeta.rowIndex].responsibleRoleSystemName;
          const UserId =
            Role === "ONBBUHR"
              ? tableMeta.tableData[tableMeta.rowIndex].userIds
                  .split(",")
                  .find((x) => x === userDetails.Id.toString())
              : tableMeta.tableData[tableMeta.rowIndex].userIds;
          const TaskGroup = tableMeta.tableData[tableMeta.rowIndex].taskGroup;
          const CandidateUserId =
            tableMeta.tableData[tableMeta.rowIndex].candidateUserId;
          const TaskId = tableMeta.tableData[tableMeta.rowIndex].taskID;
          const workFlowInstanceID =
            tableMeta.tableData[tableMeta.rowIndex].workFlowInstanceId;
          const MRFId = tableMeta.tableData[tableMeta.rowIndex].mrfId;
          const CandidateId = tableMeta.tableData[tableMeta.rowIndex].cid;
          const StatIndex = tableMeta.tableData[tableMeta.rowIndex].userIds
            .split(",")
            .indexOf(UserId);
          const AStatus =
            tableMeta.tableData[tableMeta.rowIndex].status?.split(",")[
              StatIndex
            ];
          const taskStatus = tableMeta.tableData[tableMeta.rowIndex].status;

          return TaskGroup === "Joining Status" ? (
            AllStatus.length != 0 &&
            AllStatus.length ===
              AllStatus.filter((status) => status === "Completed").length ? (
              <>
                <Tooltip title="Edit">
                  <i
                    style={{ marginRight: "0px", cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      const state = {
                        type: "edit",
                        url: "/mrf",
                        id: TaskId,
                        TId: MRFId,
                        sendback: 0,
                        Tab: "ManpowerCandidates",
                        CandidateId: CandidateId,
                      };
                      navigate("/mrf", { state });
                    }}
                    className="fas fa-edit"
                  ></i>
                </Tooltip>
                <Tooltip title={ taskStatus === "Joined" ? "Completed" : "To be completed"}>
                  <i
                    style={{
                      fontSize: "small",
                      marginRight: "0px",
                      cursor: "pointer",
                      color: taskStatus === "Joined" ? "green" : "red",
                    }}
                    className="fas fa-circle ml-2"
                  ></i>
                </Tooltip>
              </>
            ) : (
              <>
                <Tooltip title="View">
                  <i
                    style={{ marginRight: "0px", cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();

                      const state = {
                        type: "view",
                        url: "/mrf",
                        id: TaskId,
                        TId: MRFId,
                        sendback: 0,
                        Tab: "ManpowerCandidates",
                        CandidateId: CandidateId,
                      };
                      navigate("/mrf", { state });
                    }}
                    className="fas fa-eye"
                  ></i>
                </Tooltip>
                <Tooltip title={ taskStatus === "Joined" ? "Completed" : "To be completed"}>
                  <i
                    style={{
                      fontSize: "small",
                      marginRight: "0px",
                      cursor: "pointer",
                      color: taskStatus === "Joined" ? "green" : "red",
                    }}
                    className="fas fa-circle ml-2"
                  ></i>
                </Tooltip>
              </>
            )
          ) : UserId !== undefined &&
            Role === "ONBBUHR" &&
            (AStatus !=undefined ? AStatus != "Completed" : taskStatus != "Completed")&&
            IsEdit === true ? (
            <>
              <Tooltip title="Edit">
                <i
                  style={{ marginRight: "0px", cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (TaskGroup === "Data Review") {
                      redirectToCandidateReview(
                        CandidateUserId,
                        Number(TaskId),
                        Number(UserId),
                        Number(workFlowInstanceID),
                        Role,
                        TaskGroup,
                        false
                      );
                    } else if (TaskGroup === "Pre-Joining") {
                      redirectToCandidateDocUpload(
                        CandidateUserId,
                        Number(TaskId),
                        Number(UserId),
                        Number(workFlowInstanceID),
                        Role,
                        TaskGroup,
                        false
                      );
                    } else {
                      const state = {
                        type: "edit",
                        url: "/mrf",
                        id: TaskId,
                        TId: MRFId,
                        sendback: 0,
                        Tab: "ManpowerCandidates",
                        CandidateId: CandidateId,
                      };
                      // navigate("/mrf", { state });
                    }
                  }}
                  className="fas fa-edit"
                ></i>
              </Tooltip>
              <Tooltip title={TaskGroup === "Pre-Joining" ? (taskStatus === "Completed" ? "Completed" : "To be completed") : (AStatus === "Completed" ? "Completed" : "To be completed")}>
                <i
                  style={{
                    fontSize: "small",
                    marginRight: "0px",
                    cursor: "pointer",
                    color: TaskGroup === "Pre-Joining" ? (taskStatus === "Completed" ? "green" : "red") : (AStatus === "Completed" ? "green" : "red"),
                  }}
                  className="fas fa-circle ml-2"
                ></i>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip title="View">
                <i
                  style={{ marginRight: "0px", cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (TaskGroup === "Data Collection") {
                      redirectToCandidateForm(CandidateUserId);
                    } else if (TaskGroup === "Document Collection") {
                      redirectToCandidateDocUpload(
                        CandidateUserId,
                        Number(TaskId),
                        Number(UserId),
                        Number(workFlowInstanceID),
                        Role,
                        TaskGroup,
                        true
                      );
                    } else if (TaskGroup === "Pre-Joining") {
                      redirectToCandidateDocUpload(
                        CandidateUserId,
                        Number(TaskId),
                        Number(UserId.split(',')[0]),
                        Number(workFlowInstanceID),
                        Role,
                        TaskGroup,
                        true
                      );
                    } else if (TaskGroup === "Data Review") {
                      redirectToCandidateReview(
                        CandidateUserId,
                        Number(TaskId),
                        Number(UserId),
                        Number(workFlowInstanceID),
                        Role,
                        TaskGroup,
                        false
                      );
                    } else {
                      const state = {
                        type: "view",
                        url: "/mrf",
                        id: TaskId,
                        TId: MRFId,
                        sendback: 0,
                        Tab: "ManpowerCandidates",
                        CandidateId: CandidateId,
                      };
                      navigate("/mrf", { state });
                    }
                  }}
                  className="fas fa-eye"
                ></i>
              </Tooltip>
              <Tooltip title={TaskGroup === "Pre-Joining" ? (taskStatus === "Completed" ? "Completed" : "To be completed") : (AStatus === "Completed" ? "Completed" : "To be completed")}>
                <i
                  style={{
                    fontSize: "small",
                    marginRight: "0px",
                    cursor: "pointer",
                    color: TaskGroup === "Pre-Joining" ? (taskStatus === "Completed" ? "green" : "red") : (AStatus === "Completed" ? "green" : "red"),
                  }}
                  className="fas fa-circle ml-2"
                ></i>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  const GridColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        display: false,
      },
    },
    {
      name: "isChecked",
      label: "isChecked",
      options: {
        display: false,
      },
    },
    {
      name: "isTaskCreated",
      label: "isTaskCreated",
      options: {
        display: false,
      },
    },
    {
      name: "isMandatory",
      label: "Select",
      options: {
        display: true,
        filter: false,
        sort: false,
        sortDescFirst: false,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
        customHeadRender: (columnMeta) => {
          return (
            <th
              key={columnMeta.name}
              className="center-header" // Add a custom CSS class
            >
              <>
                <input
                  type="checkbox"
                  onChange={() => {
                    setIsAllSelected(!isAllSelected);
                    if (!isAllSelected) {
                      setSelectedRows(
                        dashboardData.map((dd: any) => dd.Id || dd.id)
                      );
                    } else {
                      setSelectedRows(
                        dashboardData
                          .filter((dd: any) => dd.isMandatory || dd.isChecked) //|| dd.isTaskCreated
                          .map((dd: any) => dd.Id || dd.id)
                      );
                    }
                  }}
                  name={`isSelect`}
                  checked={isAllSelected}
                  disabled={dashboardData.length <= 1}
                />
                {/* <label className="col-form-label">{columnMeta.label}</label> */}
              </>
            </th>
          );
        },
        customBodyRender: (value: any, tableMeta: any) => {
          const id = tableMeta.rowData[0];
          const isChecked = tableMeta.rowData[1];
          const isTaskCreated = tableMeta.rowData[2];
          const isSelected = isSelectRow.includes(id);
          const toggleSelect = () => {
            if (isSelected) {
              // unselect header
              setIsAllSelected(false);
              // If the row is selected, remove it from the isSelectRow array
              setSelectedRows(isSelectRow.filter((rowId) => rowId !== id));
            } else {
              // select header
              setIsAllSelected(isSelectRow.length + 1 == dashboardData.length);
              // If the row is not selected, add it to the isSelectRow array
              setSelectedRows([...isSelectRow, id]);
            }
          };

          return (
            <input
              type="checkbox"
              onChange={toggleSelect}
              name={`isSelect_${id}`}
              checked={isSelected || value || isChecked || isTaskCreated} // Check if either isSelected is true or isMandatory is true
              disabled={value || isChecked} // Disable the checkbox if value is 1
            />
          );
        },
      },
    },
    {
      name: "taskGroup",
      label: "Task Group",
      options: {
        display: true,
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
      },
    },
    {
      name: "task",
      label: "Task",
      options: {
        display: true,
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
      },
    },
    {
      name: "responsibleRole",
      label: "Responsible Role",
      options: {
        display: true,
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
      },
    },
    {
      name: "responsiblePerson",
      label: "Responsible Person",
      options: {
        display: true,
        filter: false,
        sort: false,
        customHeadRender: (columnMeta) => {
          return (
            <th
              key={columnMeta.name}
              className="left-header" // Add a custom CSS class
            >
              <>
                <label className="col-form-label">{columnMeta.label}</label>
              </>
            </th>
          );
        },
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
      },
    },
  ];

  return (
    <>
      <div className="main-section">
        <div
          className="row prof_bg mb-3 border-bottom"
          style={{ backgroundColor: "#00abe6" }}
        >
          <div className="container-fluid">
            <div className="row botpad">
              <div className="col-lg-2 col-md-2 col-sm-12">
                <div className="prof_img">
                  {/* {headerValues && <img src={profileImg} />} */}
                  <img
                    src={profileImg != null ? profileImg : defaultProfileImgPic}
                    alt="Profile"
                  />
                </div>
              </div>

              <div className="col-lg-10 col-md-10 col-sm-12 profData">
                <div className="name">
                  <h4>{onBoardingData}</h4>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12 profDetail">
                    <ul>
                      <li>
                        <span className="header">{`Name : ` + name}</span>
                      </li>
                      <li>
                        <span className="header">
                          <>{`Job Title : ` + jobTitle}</>
                        </span>
                      </li>
                      <li>
                        <span className="header">
                          <>{`Legal Entity : ` + legalEntity}</>
                        </span>
                      </li>
                      <li>
                        <span className="header">
                          <>{`Designation : ` + designation}</>
                        </span>
                      </li>
                      <li>
                        <span className="header">
                          <>{`Employee Class : ` + employeeClass}</>
                        </span>
                      </li>
                      <li>
                        <span className="header">
                          <>{`Location : ` + location}</>
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12 profDetail">
                    <ul>
                      <li>
                        <span className="header">
                          <>{`Email : ` + email}</>
                        </span>
                      </li>
                      <li>
                        <span className="header">
                          <>{`CandidateId : ` + canHeader}</>
                        </span>
                      </li>
                      <li>
                        <span className="header">
                          <>{`Mobile Number : ` + phone}</>
                        </span>
                      </li>
                      <li>
                        <span className="header">
                          <>{`Expected Date of Joining : ` + dateOfJoining}</>
                        </span>
                      </li>
                      <li>
                        <span className="header">{`Status : ` + status}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-sm-4 col-md-4">
            <table>
              <tr>
                <td>Onboarding ONB # : </td>
                <td>{onBoardingData}</td>
              </tr>
              <tr>
                <td>Name : </td>
                <td>{name}</td>
              </tr>
              <tr>
                <td>Job Title : </td>
                <td>{jobTitle}</td>
              </tr>
              <tr>
                <td>Legal Entity : </td>
                <td>{legalEntity}</td>
              </tr>
              <tr>
                <td>Designation : </td>
                <td>{designation}</td>
              </tr>
              <tr>
                <td>Employee Class : </td>
                <td>{employeeClass}</td>
              </tr>
              <tr>
                <td>Location : </td>
                <td>{location}</td>
              </tr>
            </table>
          </div> */}
          {/* <div className="col-sm-4 col-md-4"></div>
          <div className="col-sm-4 col-md-4">
            <table>
              <tr>
                <td>Email : </td>
                <td>{email}</td>
              </tr>
              <tr>
                <td>CandidateId : </td>
                <td>{canHeader}</td>
              </tr>
              <tr>
                <td>Mobile Number : </td>
                <td>{phone}</td>
              </tr>
              <tr>
                <td>Expected Date of Joining : </td>
                <td>{dateOfJoining}</td>
              </tr>
              <tr>
                <td>Status : </td>
                <td>{status}</td>
              </tr>
            </table>
          </div> */}
        </div>
        <div className="row">
          <div className="col-lg-2 col-md-12 col-sm-12 "></div>
          <div className="col-lg-10 col-md-12 col-sm-12 ">
            <ThemeProvider theme={tabsTheme}>
              <Tabs
                value={activetabId}
                className="profile-tabs"
                onChange={() => {}}
                variant="scrollable"
                TabScrollButtonProps={{
                  style: {
                    color: "white",
                  },
                }}
                scrollButtons
                TabIndicatorProps={{
                  style: { display: "none" },
                }}
                allowScrollButtonsMobile
                aria-label="scrollable tabs"
              >
                <div className="tabBtn">
                  <ul>
                    {TabsData.length > 0 &&
                      TabsData.map((eachTab, index) =>
                        eachTab.TN != "" ? (
                          <li key={`${index}-${eachTab.TN}`}>
                            <button
                              onClick={() => setActivetab(eachTab.TN)}
                              className={
                                eachTab.TN === activetab
                                  ? "bttn active"
                                  : "bttn"
                              }
                            >
                              {eachTab.TN}
                            </button>
                          </li>
                        ) : (
                          <li key={`${index}-${eachTab.TN}`}>
                            <button
                              onClick={() => setActivetab(eachTab.TN)}
                              hidden={false}
                              className={
                                eachTab.TN === activetab
                                  ? "bttn active"
                                  : "bttn"
                              }
                            >
                              {eachTab.TN}
                            </button>
                          </li>
                        )
                      )}
                  </ul>
                </div>
              </Tabs>
            </ThemeProvider>
          </div>
        </div>
      </div>

      {activetab === "CheckList" ? (
        <div className="container-fluid">
          <div className="grid-wrapper-onboard mt-3">
            <p className="ChecklistTitle">Onboarding Checklist</p>
          </div>
          <div className="mb-3 pt-3">
            <DynamicGrid
              showBorderLine={true}
              data={dashboardData}
              columns={GridColumns}
              options={Options}
            />
          </div>
          <div
            className="d-flex justify-content-end mt-3"
            style={{ marginRight: "2rem" }}
          >
            <div>
              <button
                className="btn btn-secondary mr-2"
                onClick={() => onClickFunction("Cancel")}
              >
                <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
              </button>
              {dashboardData.length >= 1 && (
                <>
                  <button
                    className="btn btn-secondary mr-2"
                    onClick={() => onClickFunction("Reset")}
                  >
                    <i className="fa fa-undo" aria-hidden="true"></i> Reset
                  </button>
                  <button
                    style={{ marginLeft: 5 }}
                    className="btn btn-filter-submit"
                    onClick={() => setShowModel(true)}
                  >
                    <i className="fa fa-check" aria-hidden="true"></i> Submit{" "}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid">
          <div className="grid-wrapper-onboard mt-3">
            <p className="ChecklistTitle">Onboarding Status</p>
          </div>
          <div className="mb-3 pt-3">
            <DynamicGrid
              showBorderLine={true}
              data={dashboardStatusData}
              columns={StatusGridColumns}
              options={Options}
            />
          </div>
          <div
            className="d-flex justify-content-end mt-3"
            style={{ marginRight: "2rem" }}
          >
            <div>
              <button
                className="btn btn-secondary mr-2"
                onClick={() => onClickFunction("Cancel")}
              >
                <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
              </button>
            </div>
          </div>
        </div>
      )}
      <>
        <Modal
          show={showModel}
          onHide={() => setShowModel(false)}
          backdrop="static"
          keyboard={false}
          size="lg"
          centered
        >
          <Modal.Body>
            <>
              <div>
                <p className="ProceedOnBoard">
                  The selected onboarding actions will be initiated and assigned
                  to respective action owners. <br></br>Click Proceed to
                  confirm.
                </p>
              </div>
            </>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary"
              style={{ marginLeft: 5 }}
              onClick={() => {
                onClickFunction("Submit");
              }}
            >
              Proceed
            </button>
            <button
              className="btn btn-cancel"
              style={{ marginLeft: 5 }}
              onClick={() => {
                onClickFunction("Reject");
              }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </>
    </>
  );
};

export default OnBoardingCheckList;
