import React, { useContext, useRef, useState, useEffect } from "react";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import notify from "../../Helpers/ToastNotification";
import { LoaderContext } from "../../Helpers/Context/Context";
import { APICall } from "../../Helpers/APICalls";
import { useNavigate } from "react-router-dom";

import { GetAppointmentDashboard } from "../../Helpers/APIEndPoints/AssociateEndPoints";
import SelectForm from "../../Components/SelectForm/SelectForm";
import moment from "moment";

const MedicalDashboard = () => {
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [dashboard, setdashboard] = useState([]);
  const [dashboardCount, setDashboardCount] = useState(0);
  const [refresh, setRefresh] = useState<any>(false);
  const [dashboardStart, setDashboardStart] = useState(0);
  const [dashboardSortColumn, setDashboardSortColumn] = useState<any>("");
  const [dashboardSortDirection, setDashboardSortDirection] = useState("");
  const [dashboardPageSize, setDashboardPageSize] = useState(10);

  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      await getCandidateStatus(true);
    };
    fetchData();

    const interval = setInterval(() => {
      setDashboardStart((prevStart) => {
        const nextStart = prevStart + dashboardPageSize;
        console.log("nextStart1:" + nextStart);
        if (nextStart >= dashboardCount) {
          setCurrentPage(0); // Reset the page to the first one
          return 0; // Reset to the first page when reaching the end
        }
        setCurrentPage((prevPage) => prevPage + 1); // Update the current page state
        return nextStart;
      });
    }, 30000); // 30 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [
    refresh,
    dashboardStart,
    dashboardSortColumn,
    dashboardSortDirection,
    dashboardPageSize,
    dashboardCount,
  ]);
  const getCandidateStatus = async (validateflag: any) => {
    try {
      showLoader();

      const response = await APICall(GetAppointmentDashboard, "POST", {
        PageNumber: dashboardStart,
        PageSize: dashboardPageSize,
        SortColumn: dashboardSortColumn,
        SortOrder: dashboardSortColumn,
        IsExcel: false,
      });

      if (response?.status === 0 && response?.data.length > 0) {
        console.log(response?.data, "Associate");
        setdashboard(response?.data);
        setDashboardCount(response?.data[0].count);
      } else {
        setdashboard([]);
        setDashboardCount(0);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  const gridColumns = [
    {
      name: "sequence",
      label: "Sl.No",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "candidateToken",
      label: "Token Id",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "counter",
      label: "Counter",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "isProcessing",
      label: "Status",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        customBodyRender: (value) => {
          const text = value === false ? "In Queue" : "In Processing";
          const color = value === false ? "red" : "green";
          return <span style={{ color }}>{text}</span>;
        },
      },
    },
  ];
  const dashboardOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: dashboardCount,
    rowsPerPage: dashboardPageSize,
    page: currentPage,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    onChangeRowsPerPage: (num) => {
      //   setLimit(num);
      //   setNxtPgInfo("");
      //   setPrevPgInfo("");
      //   setIsPrevOrNext("");
    },
    onSearchChange: (searchText) => {
      if (searchText !== null) {
        //  setDashboardSearchText(searchText);
      } else {
        //       setDashboardSearchText("");
      }
    },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setDashboardSortColumn(sortColumn);
        await setDashboardSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setDashboardSortColumn(sortColumn);
        await setDashboardSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      setCurrentPage(page);
      const newStart = page * dashboardPageSize;
      console.log("nextStart2:" + newStart);
      setDashboardStart(newStart);
      await getCandidateStatus(true); // Fetch data for the new page
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  return (
    <>
      <div className="form-main">
        <div className="page-title w-100">
          <div className="col-lg-8">
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <div className="row">
                  <i
                    className="fa-solid fa-home mt-3 mr-3 ml-3"
                    style={{ color: "white", cursor: "pointer" }}
                    onClick={() => {
                      navigate("/Bluecollar");
                    }}
                  ></i>
                  <h4>Appointment Dashboard</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-2" id="blue-collar-pages">
        <div className="col-lg-12 col-md-12 col-sm-12" id="dashboard-page">
          <DynamicGrid
            data={dashboard}
            columns={gridColumns}
            options={dashboardOptions}
          />
        </div>
      </div>
    </>
  );
};

export default MedicalDashboard;
