import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import InputForm from "../../Components/InputForm/InputForm";
import { useLocation, useNavigate } from "react-router-dom";
import { LoaderContext, getContext } from "../../Helpers/Context/Context";
import {
  getGenericDropdownsData,
  getParameterDetailsById,
  insertUpdateParametertDetails,
} from "../../Helpers/APIEndPoints/EndPoints";
import { APICall } from "../../Helpers/APICalls";
import SelectForm from "../../Components/SelectForm/SelectForm";
import notify from "../../Helpers/ToastNotification";
import AccessDenied from "../AccessDenied/AccessDenied";

const ParameterEdit = (props) => {
  const { state } = useLocation();
  let navigate = useNavigate();
  const context = getContext();
  const [formErrors, setFormErrors] = useState({});
  const { showLoader, hideLoader } = useContext(LoaderContext);

  const [parameterName, setParameterName] = useState("");
  const [dataTypeOptions, setDataTypeOptions] = useState([]);
  const [dataType, setDataType] = useState<any>([]);
  const [unitOfMeasures, setUnitOfMeasures] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [isUOMApplicable, setIsUOMApplicable] = useState(false);
  const [isDissabled, setIsDissabled] = useState(true);

  useEffect(() => {
    (async () => {
      showLoader();
      await GetDataTypeOptions();
      if (state !== null && state.id && state.id > 0) {
        await getParameterDetails();
      }
      hideLoader();
    })();
  }, []);

  const GetDataTypeOptions = async () => {
    const response = await APICall(getGenericDropdownsData, "POST", {
      MasterType: 3,
    });
    if (response.data !== null && response.data.length > 0) {
      let datatype = response.data.map((element) => ({
        value: element.name,
        label: element.name,
      }));
      setDataTypeOptions(datatype);
    }
  };

  const getParameterDetails = async () => {
    const { data } = await APICall(getParameterDetailsById, "POST", {
      Id: state.id,
    });
    if (data !== null) {
      setParameterName(data.name);
      setDataType({ value: data.dataType, label: data.dataType });
      setUnitOfMeasures(data.unitOfMeasures);
      setIsUOMApplicable(data.isUOMApplicable);
      setIsActive(data.isActive);
    }
  };

  const handleTextEvent = (event, name) => {
    if (name === "parameterName") {
      setParameterName(event.target.value);
    }
    if (name === "unitOfMeasures") {
      setUnitOfMeasures(event.target.value);
    }
  };

  const handleCheckBox = (name) => {
    if (name === "isActive") {
      setIsActive((current) => !current);
    }
    if (name === "isUOMApplicable") {
      setIsUOMApplicable((current) => !current);
    }
  };

  const selectOnChange = (event, name) => {
    if (name === "dataType") {
      setDataType(event);
      if (event.value === "Numeric" || event.value === "Currency") {
        setIsUOMApplicable(false);
      } else {
        setUnitOfMeasures("");
        setIsUOMApplicable(true);
      }
      setFormErrors((prev) => {
        return {
          ...prev,
          ["DataType_isEmpty"]: undefined,
        };
      });
    }
  };

  const onClickFunction = async (event, name) => {
    if (name === "Reset") {
      setParameterName("");
      setDataType("");
      setUnitOfMeasures("");
      setIsUOMApplicable(false);
    }
    if (name === "Cancel") {
      if (
        (state !== null && state.hasOwnProperty("structureId")) ||
        state.hasOwnProperty("projectId")
      ) {
        props.setParameterReturnValue(true);
      } else {
        navigate("/ParameterDashboard");
      }
    }
    if (name === "Submit") {
      let error = Validation();

      if (!error) {
        await InsertUpdateParameterDetails();
      }
    }
  };

  const Validation = () => {
    let isError = false;
    setFormErrors({});
    if (parameterName.trim().length == 0) {
      setFormErrors((prev) => {
        return {
          ...prev,
          ["Parameter_isEmpty"]: "Parameter name required",
        };
      });
      isError = true;
    }
    if (dataType.length == 0) {
      setFormErrors((prev) => {
        return {
          ...prev,
          ["DataType_isEmpty"]: "DataType required",
        };
      });
      isError = true;
    }
    if (
      (unitOfMeasures === null || unitOfMeasures.trim() === "") &&
      isUOMApplicable === false
    ) {
      setFormErrors((prev) => {
        return {
          ...prev,
          ["UnitOfMeasures_isEmpty"]: "Unit Of Measures required",
        };
      });
      isError = true;
    } else if (
      !/^[^,]+(?:,[^,]+)*$/.test(unitOfMeasures.trim()) &&
      isUOMApplicable === false
    ) {
      setFormErrors((prev) => {
        return {
          ...prev,
          ["UnitOfMeasures_isEmpty"]: "Enter next unit of measure",
        };
      });
      isError = true;
    }
    return isError;
  };
  const InsertUpdateParameterDetails = async () => {
    let requestParams = {
      Id: state === null ? 0 : state.id,
      UserId: context.userId,
      Name: parameterName,
      DataType: dataType.value,
      UnitOfMeasures: unitOfMeasures === "" ? null : unitOfMeasures,
      IsUOMApplicable: isUOMApplicable,
      IsActive: isActive,
    };
    const data = await APICall(
      insertUpdateParametertDetails,
      "POST",
      requestParams
    );

    if (data.data !== null) {
      if (state !== null && state.id > 0) {
        notify(0, "Data Updated Successfully.");
        navigate("/ParameterDashboard");
      } else if (state === null || state.id == 0) {
        notify(0, "Data Saved Successfully.");
        navigate("/ParameterDashboard");
      } else if (
        state.hasOwnProperty("structureId") ||
        state.hasOwnProperty("projectId")
      ) {
        notify(0, "Data Saved Successfully.");
        props.setParameterReturnValue(true);
      }
    } else {
      data.message === "mapped"
        ? setFormErrors({ ["Parameter_isEmpty"]: "Parameter already exists" })
        : notify(1, "Something went wrong, Try again later.");
    }
  };

  useEffect(() => {
    setFormErrors((prev) => {
      return {
        ...prev,
        ["UnitOfMeasures_isEmpty"]: undefined,
      };
    });
    if (dataType.value === "Numeric" || dataType.value === "Currency") {
      setIsDissabled(false);
      setIsUOMApplicable(false);
    } else {
      setIsDissabled(true);
      setIsUOMApplicable(true);
    }

    if (
      (dataType.value === "Numeric" || dataType.value === "Currency") &&
      isUOMApplicable === true
    ) {
      setUnitOfMeasures("");
      setIsUOMApplicable(true);
    }
  }, [dataType.value, isUOMApplicable]);

  return (
    <>
      {context.loggedInAs === 1 ? (
        <>
          <div className="form-main px-3">
            <div className="page-title w-100">
              <div className="col-lg-12 p-0">
                <div className="row">
                  <div className="col-lg-4 col-md-4">
                    <h4>Parameter and Unit of Measures(UOM)</h4>
                  </div>
                  <div className="col-lg-4 col-md-2"></div>
                  <div className="col-lg-4 col-md-6">
                    <div
                      className="float-right col-sm-offset-2"
                      style={{
                        marginBottom: 10,
                      }}>
                      <button
                        className="btn  btn-success"
                        style={{ marginLeft: 5 }}
                        onClick={(e) => onClickFunction(e, "Submit")}>
                        <i className="fa fa-save"></i> Submit
                      </button>

                      <button
                        className="btn btn-cancel"
                        style={{ marginLeft: 5 }}
                        onClick={(e) => onClickFunction(e, "Cancel")}>
                        <i className="fa fa-times" aria-hidden="true"></i>{" "}
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12 col-md-12 px-3 py-2 page_heading">
            <div className="row align-items-center">
              <div className="col-lg-4 col-sm-3 col-xs-4 ">
                <div className="form-group">
                  <label className="col-form-label">Parameter </label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control form-control-lg input_form_height"
                    placeholder={"Parameter Name"}
                    isDisabled={false}
                    textArea={false}
                    value={parameterName}
                    onChange={(e) => {
                      handleTextEvent(e, "parameterName");
                    }}
                    maxLength="255"
                  />
                  <p style={{ color: "red" }}>
                    {formErrors["Parameter_isEmpty"]}
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-sm-3 col-xs-4 ">
                <div className="form-group">
                  <label className="col-form-label">Data Type</label>
                  <sup>*</sup>
                  <SelectForm
                    options={dataTypeOptions}
                    placeholder="Select Data Type"
                    isDisabled={false}
                    value={dataType}
                    onChange={(event) => {
                      selectOnChange(event, "dataType");
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  <p style={{ color: "red" }}>
                    {formErrors["DataType_isEmpty"]}
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-sm-3 col-xs-4 ">
                <div className="form-group">
                  <label className="col-form-label">
                    Unit Of Measures (you may enter multiple values seperated by
                    comma){" "}
                  </label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control form-control-lg input_form_height "
                    placeholder={"Unit Of Measures"}
                    //isDisabled={dataType.value ==="Numeric" ? false : dataType.value ==="Currency" ? false :true }
                    //isDisabled={dataType.value!=="Numeric"||dataType.value!=="Currency"}
                    isDisabled={isUOMApplicable}
                    textArea={false}
                    value={unitOfMeasures}
                    onChange={(e) => handleTextEvent(e, "unitOfMeasures")}
                  />
                  <p style={{ color: "red" }}>
                    {formErrors["UnitOfMeasures_isEmpty"]}
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-sm-3 col-xs-4 ">
                <div className="form-group">
                  <label htmlFor="isNotUOM" className="col-form-label">
                    Unit of Measure not Applicable
                  </label>
                  <sup>*</sup>
                  <div className="custom-checkbox">
                    <input
                      disabled={isDissabled}
                      type="checkbox"
                      onChange={() => handleCheckBox("isUOMApplicable")}
                      name="isNotUOM"
                      id="isNotUOM"
                      checked={isUOMApplicable}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-sm-3 col-xs-4 ">
                <div className="form-group">
                  <label htmlFor="isActive" className="col-form-label">
                    Active Status
                  </label>
                  <sup>*</sup>
                  <div className="custom-checkbox">
                    <input
                      disabled={
                        state === null
                          ? true
                          : state.id == 0
                          ? true
                          : state.hasOwnProperty("structureId")
                          ? true
                          : state.hasOwnProperty("projectId")
                          ? true
                          : false
                      }
                      //disabled={false}
                      type="checkbox"
                      onChange={() => handleCheckBox("isActive")}
                      name="isActive"
                      id="isActive"
                      checked={isActive}
                    />
                  </div>
                </div>
              </div>

              {/* <div className="col-lg-4 col-md-4">
            <div className="float-right">
              <button
                className="btn  btn-success"
                style={{ marginLeft: 5 }}
                onClick={(e) => onClickFunction(e, "Submit")}>
                <i className="fa fa-save"></i> Submit
              </button>
              <button
                className="btn btn-info"
                style={{ marginLeft: 5 }}
                onClick={(e) => onClickFunction(e, "Reset")}
              >
                Reset
              </button>
              <button
                className="btn btn-cancel"
                style={{ marginLeft: 5 }}
                onClick={(e) => onClickFunction(e, "Cancel")}>
                Cancel
              </button>
            </div>
          </div> */}
            </div>
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};

export default ParameterEdit;
