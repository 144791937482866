import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import InputForm from "../../Components/InputForm/InputForm";
import defaultProfileImgPic4 from "../../Assets/Images/default+_pic.jpg";
import { APICall } from "../../Helpers/APICalls";

import { GetAssociateDetailsForTransactionScreenById, GetAssociatesDocument } from "../../Helpers/APIEndPoints/AssociateEndPoints";

// Define Loader component
const Loader = () => {
  return <div>Loading...</div>;
};

const UserDetails = ({ id,stageId,alreadyScanned}) => {
  const [modalForUserDetails, setModalForUserDetails] = useState(true);
  const [scanData, setScanData] = useState(null);
  const [candidateimage, setCandidateimage] = useState(null);
  const [modalTitle, setmodalTitle] = useState(null);
  const [loading, setLoading] = useState(false); // Add loading state
  const [showValidation, setshowValidation] = useState(false);



  const handleScanData = async (id,stageId,alreadyScanned) => {
    try {
      getLabelForTitle(stageId);
      // Make API call with the provided ID
      const otherData = await APICall(GetAssociateDetailsForTransactionScreenById, "POST", {
        Id: id,
        WorkflowStageId: stageId
      });

      // if (otherData.data.isProcessing === true) {
      //   // Handle isProcessing true case
      // }
   
      const { status, data, message } = otherData;
      if(alreadyScanned  == true){
        setmodalTitle('Already Scanned');
        setshowValidation(true);
      }

      setScanData(data);
      console.log("FZ1", data);
      candidateProfile(id);
      if (status === 0) {
        setModalForUserDetails(true);
        console.error("SUCCESS");
      } else {
        console.error(message); // Output the actual message, not the string "message"
      }
    } catch (error) {
      console.error(error);
    } finally {
     
     
    }
  };

  const candidateProfile = async (id) => {
    setLoading(true); // Set loading state to true
    try {
      const response = await APICall(GetAssociatesDocument, "POST", {
        AssociatesDetailsId: id,
        DocumentTypeId: 8,
      });

      if (response.data !== null) {
        console.log(response.data, "console");
        setCandidateimage(response?.data?.documentDataObject);
      }
    } catch (error) {
      console.error("Error fetching candidate profile:", error);
    } finally {
      setLoading(false); // Set loading state to false after API call completes
    }
  };
  const getLabelForTitle = (stageID) => {
      if(stageID == 3 || stageID == 4 || stageID == 5 || stageID == 7){
        setmodalTitle('IN Time Entered Successfully');
      }
      else if (stageID == 8){
        setmodalTitle('Handover Done Successfully');
      }
      else if (stageID == 9 || stageID == 10){
        setmodalTitle('OUT Time Entered Successfully');
      }
      else{
        setmodalTitle('Time Entered Successfully')};
   
  }

  // Call handleScanData when component mounts
  useEffect(() => {
    handleScanData(id,stageId,alreadyScanned);
  }, [id,stageId,alreadyScanned]);

  return (
    <>
      <Modal
        show={modalForUserDetails}
        onHide={() => {
          setModalForUserDetails(false);
          window.location.reload();
        }}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
        style={{ padding: 0, margin: 0 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
        
          {modalTitle}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center align-items-center">
            <div className="col-md-4 d-flex justify-content-center align-items-center">
              {loading ? ( // Show loader when loading is true
                <Loader />
              ) : (
                // Show either the fetched image or the default image
                <img
                  src={candidateimage ? candidateimage : defaultProfileImgPic4}
                  className="card-img-top"
                  alt="Thumbnail Image"
                />
              )}
            </div>
            <div className="col-md-12 text-center">
              <p className="font-weight-bold">
                <br />
                {`Token ID - ${scanData?.id || ""}`} <br />
                {scanData?.name || ""} <br />
                {`Category - ${
                  scanData?.categoryId === 1
                    ? "Experience"
                    : scanData?.categoryId === 2
                    ? "Fresher"
                    : scanData?.categoryId === 3
                    ? "Rehire"
                    : ""
                }`}{" "}
                <br />
                {`Entity- ${scanData?.legalEntity || ""}`} <br />
                {`SBU- ${scanData?.sbu || ""}`} <br />
                {`QR Date - ${scanData?.qrDatetime || ""}`} <br />
              </p>
          
               {showValidation ? ( 
              <p className="font-weight-bold text-danger">This QR code is already scanned {scanData?.stageInTime}</p>
              ) : (
          
                <p></p>
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default UserDetails;
