import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import "./style.css";
import Button from "react-bootstrap/Button";
import { APICall } from "../../../Helpers/APICalls";
import { getConsentTemplates, updateUserMasterByUserIdandRoleId, updateTemplateData, getEmployeeConesetDocument } from "../../../Helpers/APIEndPoints/EndPoints";
import useUserContext from "../../../Helpers/ZustandStore/UserContextStore";
import { clearUserData } from "../../../Redux/Actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CountdownTimer from "./CountdownTimer";
import { LoaderContext } from "../../../Helpers/Context/Context";
import notify from "../../../Helpers/ToastNotification";
import { isEmpty } from "../../../utils/isEmpty";
import moment from "moment";
import Loader from "../../../Helpers/Loader/Loader";

const acceptDeclineOptions = ['0,1', '1,0'];

const Base64PdfViewer = ({ base64String }) => {
    const embeddedPdf = `data:application/pdf;base64,${base64String}`;

    return (
        <div style={{ width: "100%" }}>
            <iframe
                title='PDF Viewer'
                src={`${embeddedPdf}#toolbar=0&navpanes=0`}
                width='100%'
                height='400px'
            ></iframe>
        </div>
    );
};

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

// const Base64PdfViewer = ({ base64String }) => {
//   const [pdfData, setPdfData] = useState(null);
//   const [numPages, setNumPages] = useState(null);

//   useEffect(() => {
//     // Convert base64 string to Uint8Array
//     const bytes = atob(base64String);
//     const pdf = new Uint8Array(bytes.length);
//     for (let i = 0; i < bytes.length; i++) {
//       pdf[i] = bytes.charCodeAt(i);
//     }
//     setPdfData(pdf);
//   }, [base64String]);

//   useEffect(() => {
//     if (pdfData) {
//       // Fetch number of pages in the PDF
//       pdfjs.getDocument({ data: pdfData }).promise.then(pdfInfo => {
//         setNumPages(pdfInfo.numPages);
//       });
//     }
//   }, [pdfData]);

//   return (
//     <div style={{ width: '100%' }}>
//       {numPages && (
//         <Document file={{ data: pdfData }} style={{ width: '100%' }}>
//           {Array.from(new Array(numPages), (el, index) => (
//             <Page key={`page_${index + 1}`} height={500} pageNumber={index + 1} width={window.innerWidth} />
//           ))}
//         </Document>
//       )}
//     </div>
//   );
// };

interface Props {
    // Define your props interface with the optional prop
    shouldShowConsentForm?: any;
}

const ConsentModal: React.FC<Props> = ({ shouldShowConsentForm }) => {
    const [openModal, setOpenModal] = useState(false);
    const [templateData, setTemplateData] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [pdfUrl, setPdfUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { showLoader, hideLoader } = useContext(LoaderContext);

    let { userDetails, deleteUserDetails }: any = useUserContext();

    const templateItem = templateData?.[currentIndex] || {};

    const logout = async () => {
        const response = await APICall(updateUserMasterByUserIdandRoleId, "POST", {
            UserId: userDetails?.Id,
            RoleId: null,
            isLogout: true
        });
        deleteUserDetails();
        dispatch(clearUserData({}));
        navigate("/", { replace: true });
    }

    const updateTemplateActions = async (action) => {
        const { id, filename, templateName, employeeConsentTeamplateId } = templateItem || {};
        showLoader();
        try {
            const res = await APICall(updateTemplateData, 'POST', {
                Eid: userDetails?.EmployeeId,
                TemplateUrl: pdfUrl,
                ActionTaken: action,
                Id: id,
                Userid: userDetails?.UserId,
                Filename: filename,
                TemplateName : templateName,
                EmployeeConsentTeamplateId : employeeConsentTeamplateId
            });

            if (res?.status === 0) {
                return true;
            }

            notify(1, "Something went wrong");
            return false;
        } catch (error) {
            notify(1, "Something went wrong");
            console.log('err', error);
        } finally {
            hideLoader();
        }
    };

    const getTemplatePdf = async (num, data?: any) => {
        const templateItemData = await (data || templateData)?.[num] || {};
        const { templateUrl, firstName, dispalyname, employeeConsentTeamplateId, templateName } = templateItemData || {};
        setPdfUrl('');
        setIsLoading(true);
        try {
            const response = await APICall(getEmployeeConesetDocument, "POST", {
                Eid: userDetails?.EmployeeId,
                templateUrl,
                Dispalyname: dispalyname,
                FirstName: firstName,
                TemplateName : templateName,
                Userid : String(userDetails?.UserId || ''),
                // added the 24 date format.Raj Potdar, Date : 29-02-2024
                SignDate: moment().format('DD-MM-YYYY HH:mm:ss')
            });

            const { data, status } = response || {};

            if (status === 0) {
                setPdfUrl(data);
                setOpenModal(true);
                setIsLoading(false);
                return true;
            } else {
                notify(1, 'Something went wrong');
                setIsLoading(false);
                return false;
            }
        } catch (error) {
            notify(1, 'Something went wrong');
            console.log('err', error);
            setIsLoading(false);
            return false;
        } 
    };

    const handleDecline = async () => {
        const resStatus = await updateTemplateActions('0');

        if (resStatus) {
            if (templateItem?.isConfirmationMandatory === 0) {
                setOpenModal(false);

                if (currentIndex !== templateData.length - 1) {
                    const nextIndex = currentIndex + 1;
                    getTemplatePdf(nextIndex);
                    setCurrentIndex(nextIndex);
                    // setOpenModal(true);
                }
            } else {
                logout();
            }
        }
    };

    const handleAccept = async () => {
        const resStatus = await updateTemplateActions('1');

        if (resStatus) {
            setOpenModal(false);

            if (currentIndex !== templateData.length - 1) {
                const nextIndex = currentIndex + 1;
                setCurrentIndex(nextIndex);
                getTemplatePdf(nextIndex);
            }
        }
    };

    const getConsentTemplatesData = async () => {
        showLoader();
        try {
            const response = await APICall(getConsentTemplates, "POST", {
                Userid: String(userDetails?.UserId || '')
            });

            const { data, status } = response || {};

            if (status === 0) {
                const firstFalseIndex = (data || []).findIndex(item => !item.showform);

                const currentFalseIndex = firstFalseIndex === -1 ? null : firstFalseIndex;

                setCurrentIndex(currentFalseIndex);
                setTemplateData(data);
                if (currentFalseIndex !== null) {
                    getTemplatePdf(currentFalseIndex, data);
                }
            }
            hideLoader();
        } catch (error) {
            hideLoader();
            console.log('err', error);
        } 
    }

    useEffect(() => {
        if (shouldShowConsentForm) getConsentTemplatesData();
    }, [shouldShowConsentForm]);

    const onTimerEnd = () => {
        logout();
    }

    if(isLoading) {
        return <Loader/>;
    }

    return (
        <>
            {openModal && currentIndex !== null && <Modal
                show={openModal}
                size='sm'
                aria-labelledby='contained-modal-title-vcenter'
                backdrop='static'
                centered
            >
                <Modal.Body>
                    {!isEmpty(pdfUrl) && (<>
                        <div className="d-flex justify-content-between mb-2">
                            <h6 className="mb-0 text-capitalize">{templateItem?.templateName || ''}</h6>
                            <CountdownTimer onTimerEnd={onTimerEnd} key={templateItem?.id} />
                        </div>
                        <Base64PdfViewer base64String={pdfUrl} />
                        <div className='d-flex justify-content-center mt-3'>
                            <div className='d-flex align-items-center'>
                                {acceptDeclineOptions.includes(templateItem?.decision) && (
                                    <>
                                        <Button
                                            variant='success'
                                            className='mr-3 consent-success-btn'
                                            onClick={handleAccept}
                                        >
                                            <i className="fa-solid fa-check mr-1" /> Accept
                                        </Button>
                                        <Button
                                            variant='danger'
                                            className='consent-decline-btn'
                                            onClick={handleDecline}
                                        >
                                            <i className="fa fa-times mr-1" /> Decline
                                        </Button>{" "}
                                    </>
                                )}
                                {templateItem?.decision === "0" && (
                                    <Button
                                        variant='danger'
                                        className='consent-decline-btn'
                                        onClick={handleDecline}
                                    >
                                        <i className="fa fa-times mr-1" /> Decline
                                    </Button>
                                )}
                                {templateItem?.decision === "1" && (
                                    <Button
                                        variant='success'
                                        className='mr-3 consent-success-btn'
                                        onClick={handleAccept}
                                    >
                                        <i className="fa-solid fa-check mr-1" /> Accept
                                    </Button>
                                )}
                            </div>
                        </div>
                    </>)}
                </Modal.Body>
            </Modal>}
        </>
    );
};

export default ConsentModal;
