import React, { useEffect, useRef, useState, useContext } from "react";
import InputForm from "../../../Components/InputForm/InputForm";
import { CustomAccordian } from "./CustomAccordtion";
import {
  base64ToArrayBuffer,
  getBase64FromFilePromise,
} from "../../../Helpers/Common";
import notify from "../../../Helpers/ToastNotification";
import fileDownload from "js-file-download";
import { APICall } from "../../../Helpers/APICalls";
import {
  IntitiateCandidateWorkflow,
  ConvertDocToPdf,
  GetCandidateDetailsByMRFIDAndCandidateId,
  DeleteAttachment,
  SaveCandidateWhenRecruiterEdit,
  getInternalJobPostingDataById,
} from "../../../Helpers/APIEndPoints/EndPoints";
import { LoaderContext } from "../../../Helpers/Context/Context";
import { useLocation, useNavigate } from "react-router-dom";
import useUserContext from "../../../Helpers/ZustandStore/UserContextStore";
import { Modal } from "react-bootstrap";
import { RCMPdfViewer } from "../../../Components/PDFViewerRCM/PDFViewerRCM";
import { Tooltip } from "@mui/material";

interface IFormDetails {
  firstName?: string;
  lastName?: string;
  emailId?: string;
  mobileNumber?: string;
  resume?: IDocument;
  profilePic?: IDocument | string;
}

interface IDocument {
  AC?: string;
  Name?: string;
  Data?: string;
  IsValidFile?: boolean;
  DocId?: Number;
  Id?: number;
}

const ResumeSection = ({
  CandidateId,
  MRFId,
  isDisableApplication = false,
  isDeclartionFormSubmited,
  setDeclartionFormSubmited,
  source,
  declartionFormData,
  isRecruiterEdit = false,
  isIJP,
  setHideShowAddCandidateTabFunc = null,
  setActiveTabFunc = null,
}) => {
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { state } = useLocation();
  const { userDetails, currentRoleId }: any = useUserContext();

  const [formDetails, setFormData] = useState<IFormDetails>({});
  const [disableForm, setDisableFormApplication] = useState(false);
  const [formErrors, setFormErrors] = useState<any>({});
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);
  const [reload, setReload] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const helper = async () => {
      if (!isEmpty(userDetails?.Id) && isIJP) {
        await GetCandidateIJPDetails();
      } else {
        await fetchCandidateDetailsById();
      }
    };

    helper();
    return () => {};
  }, [reload]);

  async function fetchCandidateDetailsById() {
    try {
      debugger;
      showLoader();
      const { data, status, message } = await APICall(
        GetCandidateDetailsByMRFIDAndCandidateId,
        "POST",
        {
          CandidateId: CandidateId,
          MRFId: MRFId,
        }
      );

      if (status == 0) {
        const obj: IFormDetails = {
          firstName: data?.firstName,
          lastName: data?.lastName,
          emailId: data?.emailId,
          mobileNumber: data?.mobileNumber,
        };

        if (!isEmpty(data?.resume) && !isEmpty(data?.resume?.data)) {
          const resume: IDocument = {
            Name: data?.resume?.name,
            AC: data?.resume?.ac,
            Data: data?.resume?.data,
            IsValidFile: true,
            DocId: data?.resume?.docId,
            Id: data?.resume?.id,
          };

          obj["resume"] = resume;
        }
        if (!isEmpty(data?.profilePic) && !isEmpty(data?.profilePic?.data)) {
          const profilePic: IDocument = {
            Name: data?.profilePic?.name,
            AC: data?.profilePic?.ac,
            Data: data?.profilePic?.data,
            IsValidFile: true,
            DocId: data?.profilePic?.docId,
            Id: data?.profilePic?.id,
          };

          obj["profilePic"] = profilePic;
        }

        setFormData(obj);
      } else {
        setFormData({});
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  async function GetCandidateIJPDetails() {
    try {
      showLoader();
      const { status, data, message } = await APICall(
        getInternalJobPostingDataById,
        "POST",
        {
          Id: userDetails?.Id,
        }
      );

      if (status == 0) {
        const attributeDetails = {
          firstName: data?.AGJAFN,
          lastName: data?.AGJALN,
          emailId: data?.AGJAEMLID,
          mobileNumber: data?.AGJAMOBNM,
        };

        setFormData((prev) => {
          return { ...prev, ...attributeDetails };
        });
      } else {
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  function handleChange(event: any, key: string) {
    switch (key) {
      case "mobileNumber":
        const value = event.target.value;
        if (value?.trim()?.length > 10) {
          return;
        }
        setFormData((prev) => {
          return {
            ...prev,
            [key]: value,
          };
        });
        break;

      default:
        setFormData((prev) => {
          return {
            ...prev,
            [key]: event.target.value,
          };
        });
        break;
    }

    try {
    } catch (error) {}
  }

  function formValidation() {
    try {
      let errorObj = {};
      if (isEmpty(formDetails.firstName)) {
        errorObj["firstName"] = "First Name is required";
      }

      if (isEmpty(formDetails.lastName)) {
        errorObj["lastName"] = "Last Name is required";
      }

      if (isEmpty(formDetails.emailId)) {
        errorObj["emailId"] = "Email ID is required";
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formDetails.emailId)) {
        errorObj["emailId"] = "Invalid email address";
      }

      if (isEmpty(formDetails.mobileNumber)) {
        errorObj["mobileNumber"] = "Mobile number is required";
      } else if (formDetails.mobileNumber?.trim()?.length !== 10) {
        errorObj["mobileNumber"] = "Invalid Mobile Number";
      }

      if (isEmpty(formDetails.resume)) {
        errorObj["resume"] = "Resume is required";
      }

      setFormErrors(errorObj);
      return Object.keys(errorObj).length === 0;
    } catch (error) {
      console.error(error);
    }
  }

  async function handleSubmitApplication(event) {
    try {
      event.preventDefault();
      event.stopPropagation();

      if (disabledSubmitBtn) return;
      debugger;

      const validation = formValidation();
      if (!validation) {
        notify(1, "Form Validation Failed.");
        return;
      }

      if (isEmpty(declartionFormData) && !isIJP) {
        notify(1, "Please submit declartion form first.");
        return;
      }

      setDisabledSubmitBtn(true);
      showLoader();

      const requestParams = {
        MRFId: MRFId,
        CandidateId: CandidateId ?? 0,
        FirstName: formDetails?.firstName,
        LastName: formDetails?.lastName,
        EmailId: formDetails?.emailId,
        MobileNumber: formDetails?.mobileNumber,
        Resume: formDetails?.resume,
        ProfilePic: formDetails?.profilePic,
        DeclartionForm: isIJP ? [] : declartionFormData,
        Source: source,
        CreatedBy: userDetails?.Id,
      };

      const response = await APICall(
        IntitiateCandidateWorkflow,
        "POST",
        requestParams
      );

      if (response?.status == 0) {
        if (setHideShowAddCandidateTabFunc != null) {
          setHideShowAddCandidateTabFunc(false);
          setActiveTabFunc("ManpowerCandidates");
        } else {
          if (String(source) == "Website") {
            navigate("/careers");
          } else if (String(source) == "IJP") {
            navigate("/careers", {
              state: { IJP: true },
            });
          } else if (String(source) == "Agency") {
            setActiveTabFunc("AgencyCandidates");
          } else {
            navigate("/home");
          }
        }
        notify(0, "Candidate added successfully.");
      } else if (response?.status == 5) {
        notify(1, response?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDisabledSubmitBtn(false);
      hideLoader();
    }
  }

  async function clearApplication(event) {
    event.preventDefault();
    event.stopPropagation();

    if (isDisableApplication) return;

    setFormData({});
  }

  async function handleFileChange(
    event: any,
    key: string,
    AC: string,
    AN = "",
    extensions = []
  ) {
    const fileSizeLimitMB = 5;
    const file = event?.target?.files[0] ?? null;

    if (!file) {
      event.target.value = null;
      return;
    }

    if (!isEmpty(formDetails[key])) {
      notify(1, `Please delete the previous ${AN}`);
      event.target.value = null;
      return;
    }

    const fileSizeBytes = fileSizeLimitMB * 1024 * 1024;
    if (file.size > fileSizeBytes) {
      notify(1, `File size cannot be greater than ${fileSizeLimitMB}MB.`);
      event.target.value = null;
      return;
    }

    const fileName = file?.name;
    const fileExtension = fileName.split(".").pop().toLowerCase();

    if (!extensions.includes(fileExtension)) {
      notify(
        1,
        "Please upload the file with the following extensions: " +
          extensions.join(", ")
      );
      event.target.value = null;
      return;
    }

    let base64: any = await getBase64FromFilePromise(file);
    base64 = String(base64).split("base64,")[1];

    const obj: IDocument = {
      AC: AC,
      Name: fileName,
      Data: base64,
      IsValidFile: true,
      DocId: 0,
    };

    setFormData((prev) => ({ ...prev, [key]: obj }));
    event.target.value = null;
  }

  async function handleFileDelete(key) {
    try {
      const file: IDocument = formDetails[key];

      if (!file.Id) {
        setFormData((prev) => {
          return { ...prev, [key]: null };
        });
        notify(0, "File deleted successfully.");
        return;
      }
      showLoader();
      const { status, message } = await APICall(DeleteAttachment, "POST", {
        Id: file.Id,
      });

      if (status == 0) {
        setFormData((prev) => {
          return { ...prev, [key]: null };
        });
        notify(0, "File deleted successfully.");
      } else {
        console.error("Error in deleting file", message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  }

  const isFileSizeGreaterThan = (file, sizeLimitMB) => {
    const sizeLimitBytes = sizeLimitMB * 1024 * 1024; // Convert MB to bytes
    return file.size > sizeLimitBytes;
  };

  async function handleRecruiterSubmit(event) {
    try {
      event.preventDefault();
      event.stopPropagation();

      if (isEmpty(formDetails.resume)) {
        notify(1, "Please provide a Resume.");
        return;
      }

      if (formDetails?.resume.Id > 0) {
        notify(1, "Cannot update the same resume.");
        return;
      }

      showLoader();
      const response = await APICall(SaveCandidateWhenRecruiterEdit, "POST", {
        MRFId: MRFId,
        CandidateId: CandidateId,
        Resume: formDetails?.resume,
      });
      if (response?.status == 0) {
        notify(0, "Resume was successfully Updated.");
        setReload((prev) => !prev);
      } else {
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  }

  return (
    <>
      <CustomAccordian
        header={"Resume"}
        disabled={
          isRecruiterEdit
            ? false
            : disabledSubmitBtn ||
              disableForm ||
              !isDeclartionFormSubmited ||
              isDisableApplication
        }
        submitCallback={
          isRecruiterEdit ? handleRecruiterSubmit : handleSubmitApplication
        }
        clearFunc={clearApplication}
        hideBtn={false}
        defaultAccordianValue={true}
      >
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
            <div className="form-group">
              <label className="col-form-label">First Name</label>
              <sup>*</sup>

              <InputForm
                value={formDetails.firstName}
                placeholder={"First Name"}
                isDisabled={
                  disableForm ||
                  !isDeclartionFormSubmited ||
                  isDisableApplication ||
                  isIJP
                }
                textArea={false}
                maxLength={255}
                onChange={(event) => handleChange(event, "firstName")}
              />
              <span style={{ color: "red" }}>{formErrors.firstName}</span>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
            <div className="form-group">
              <label className="col-form-label">Last Name</label>
              <sup>*</sup>

              <InputForm
                value={formDetails.lastName}
                placeholder={"Last Name"}
                isDisabled={
                  disableForm ||
                  !isDeclartionFormSubmited ||
                  isDisableApplication ||
                  isIJP
                }
                textArea={false}
                maxLength={255}
                onChange={(event) => handleChange(event, "lastName")}
              />
              <span style={{ color: "red" }}>{formErrors.lastName}</span>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
            <div className="form-group">
              <label className="col-form-label">Email ID</label>
              <sup>*</sup>

              <InputForm
                value={formDetails.emailId}
                placeholder={"Email Id"}
                isDisabled={
                  disableForm ||
                  !isDeclartionFormSubmited ||
                  isDisableApplication ||
                  isIJP
                }
                textArea={false}
                maxLength={255}
                onChange={(event) => handleChange(event, "emailId")}
              />
              <span style={{ color: "red" }}>{formErrors.emailId}</span>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
            <div className="form-group">
              <label className="col-form-label">Mobile Number</label>
              <sup>*</sup>

              <InputForm
                value={formDetails.mobileNumber}
                placeholder={"Mobile Number"}
                isDisabled={
                  disableForm ||
                  !isDeclartionFormSubmited ||
                  isDisableApplication ||
                  isIJP
                }
                textArea={false}
                maxLength={15}
                type={"number"}
                onChange={(event) => handleChange(event, "mobileNumber")}
              />
              <span style={{ color: "red" }}>{formErrors.mobileNumber}</span>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
            <div className="form-group">
              <label className="col-form-label">Resume/CV</label>
              <sup>*</sup>
              <FileComponent
                onChange={async (event) => {
                  await handleFileChange(event, "resume", "AGJARES", "Resume", [
                    "pdf",
                    "docx",
                    "doc",
                  ]);
                }}
                id={"resume"}
                file={formDetails.resume}
                handleFileDelete={() => {
                  handleFileDelete("resume");
                }}
                showView={true}
                disabled={
                  isRecruiterEdit
                    ? false
                    : disableForm ||
                      !isDeclartionFormSubmited ||
                      isDisableApplication
                }
                accept={".docx,.doc,.pdf"}
              />
              <span style={{ color: "red" }}>{formErrors.resume}</span>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
            <div className="form-group">
              <label className="col-form-label">Profile Picture</label>
              <FileComponent
                onChange={(event) => {
                  handleFileChange(
                    event,
                    "profilePic",
                    "CandidateProfilePic",
                    "Profile Picture",
                    ["jpg", "jpeg", "png"]
                  );
                }}
                id={"profilePic"}
                file={formDetails.profilePic}
                handleFileDelete={() => {
                  handleFileDelete("profilePic");
                }}
                accept={".jpg,.png,.jpeg"}
                disabled={
                  disableForm ||
                  !isDeclartionFormSubmited ||
                  isDisableApplication
                }
              />
              <span style={{ color: "red" }}>{formErrors.profilePic}</span>
            </div>
          </div>
        </div>
      </CustomAccordian>
    </>
  );
};

export default ResumeSection;

const FileComponent = ({
  onChange,
  file,
  handleFileDelete,
  disabled = false,
  id,
  accept = "",
  showView = false,
}) => {
  const fileRef = useRef();
  const [viewResume, setViewResume] = useState(false);
  const [base64ForDocument, setBase64ForDocument] = useState(null);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [showPdfView, setShowPdfView] = useState(false);

  useEffect(() => {
    const fileExtension = file?.Name.split(".").pop().toLowerCase();
    if (fileExtension == "pdf") {
      setShowPdfView(true);
    } else {
      setShowPdfView(false);
    }
  }, [file]);

  async function downloadFile() {
    try {
      const doc: IDocument = file;

      const fileName = doc?.Name || "";
      const base64 = doc?.Data;

      let arrayBuffer = await base64ToArrayBuffer(base64);
      var byteArray = new Uint8Array(arrayBuffer);

      let blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const newFile = new File([blob], "", {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        lastModified: new Date().getTime(),
      });

      fileDownload(newFile, fileName);
    } catch (error) {
      console.error(error);
    }
  }

  async function viewDocument() {
    try {
      const doc: IDocument = file;
      const fileExtension = doc.Name.split(".").pop().toLowerCase();

      if (fileExtension == "pdf") {
        setBase64ForDocument(doc.Data);
        setViewResume(true);
        return;
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  return (
    <>
      <div className="box position-relative">
        <input
          id={id}
          className="form-control inputfile inputfile-6 multiple-inputfile"
          data-multiple-caption="{count} files selected"
          multiple={false}
          type="file"
          ref={fileRef}
          onChange={onChange}
          accept={accept}
          disabled={disabled}
        />
        <label
          htmlFor={id}
          className="form-control"
          style={{ width: 0, border: "none" }}
        >
          <strong
            style={{
              padding: "6px 16px",
              backgroundColor: "#3c5464",
              borderRadius: "5px",
            }}
          >
            <i className="fa fa-upload rotate90" aria-hidden="true"></i>
            Upload
          </strong>
        </label>
      </div>
      <div className="file-added-list">
        <ul className="list-unstyle">
          {file && (
            <>
              <li className="list mt-1" key={id}>
                <div className="media">
                  <Tooltip title={file?.Name ?? ""}>
                    <div className="media-body text-truncate">
                      <span className="view-more">{file?.Name}</span>
                    </div>
                  </Tooltip>

                  <div className="media-right ml-2">
                    <i
                      className="fa-solid fa-download"
                      aria-hidden="true"
                      onClick={downloadFile}
                    ></i>
                  </div>

                  {showView && showPdfView && (
                    <div className="media-right ml-2">
                      <i
                        className="fa-solid fa-eye"
                        aria-hidden="true"
                        onClick={viewDocument}
                      ></i>
                    </div>
                  )}

                  {!disabled && (
                    <div className="media-right ml-2">
                      <i
                        className="fa fa-trash"
                        aria-hidden="true"
                        onClick={handleFileDelete}
                      ></i>
                    </div>
                  )}
                </div>
              </li>
            </>
          )}
        </ul>
      </div>

      <DocumentViewer
        modal={viewResume}
        setModal={setViewResume}
        header={"Resume"}
        base64={base64ForDocument}
        handleOkClick={() => {
          setViewResume(false);
          setBase64ForDocument(null);
        }}
      />
    </>
  );
};

export function isEmpty(value, acceptZero = false) {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value === "object") {
    // Check for empty object
    if (Object.keys(value).length === 0) {
      return true;
    }
    // Check for empty array
    if (Array.isArray(value) && value.length === 0) {
      return true;
    }
  }

  if (typeof value === "string") {
    // Check for empty string after trimming
    if (value.trim() === "" || (value.trim() === "0" && !acceptZero)) {
      return true;
    }
  }

  if (typeof value === "number") {
    // Check for zero or NaN
    if ((value === 0 && !acceptZero) || Number.isNaN(value)) {
      return true;
    }
  }

  return false;
}

const DocumentViewer = ({
  modal,
  setModal,
  header,
  base64 = "",
  handleOkClick,
}) => {
  return (
    <>
      <Modal
        show={modal}
        onHide={() => setModal(false)}
        backdrop="static"
        keyboard={false}
        size="xl"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">{header}</div>
        </div>
        <Modal.Body>
          <div
            className=""
            style={{
              height: "60vh",
              overflowX: "hidden",
            }}
          >
            <RCMPdfViewer pdfURL={base64 || ""} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn"
            style={{
              marginLeft: 5,
              color: "black !important",
              borderColor: "#acacac",
            }}
            onClick={() => {
              setModal(false);
            }}
          >
            Ok
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
