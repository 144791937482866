import React, { useState, useEffect } from "react";
import "./StructureType.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import InputForm from "../../Components/InputForm/InputForm";
import ActivityDetail from "./ActivityDetail";
import { Modal } from "react-bootstrap";

const StructureDetail = ({
  structureData,
  setStructureData,
  structure,
  stIndex,
}) => {
  const newActivity = {
    acSeqNo: "",
    name: "",
  };
  const [accordionState, setAccordionState] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationModalReturnValue, setConfirmationModalReturnValue] =
    useState(false);

  const closeConfirmationModal = (confirmation) => {
    if (confirmation) {
      setConfirmationModalReturnValue(confirmation);
    }
    setShowConfirmationModal(false);
  };

  useEffect(() => {
    if (confirmationModalReturnValue) {
      setStructureData((prev) =>
        prev.filter((structure, ix) => ix !== stIndex)
      );
    }
    return () => {
      setShowConfirmationModal(false);
      setConfirmationModalReturnValue(false);
    };
  }, [confirmationModalReturnValue]);

  return (
    <>
      <Accordion
        className="mb-2"
        expanded={accordionState}
        onChange={() => setAccordionState((prev) => !prev)}>
        <AccordionSummary
          className="text-white"
          style={{ background: "rgb(151 151 151)", height: "20px" }}
          expandIcon={<ExpandMore />}
          id={`structure-${stIndex}`}>
          <div className="d-flex align-items-center">
            <Typography>
              {structure.strSeqNo && `${structure.strSeqNo} `}
              <strong>Structure</strong> {`${structure.name}`}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails className="page_heading">
          <div className="container-fluid">
            <div
              style={{ padding: 15 }}
              className="row align-items-center justify-content-between">
              <div className="row">
                <div className="col-lg-4 col-md-2 col-sx-2">
                  <div className="form-group">
                    <label className="col-form-label">Sequence</label>
                    <sup>*</sup>
                    <InputForm
                      className="form-control form-control-lg"
                      placeholder={""}
                      name="strSeqNo"
                      isDisabled={false}
                      textArea={false}
                      value={structure.strSeqNo}
                      onChange={(e) => {
                        let newArr = [...structureData];
                        newArr[stIndex][e.target.name] = e.target.value;
                        setStructureData(newArr);
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-2 col-sx-2">
                  <div className="form-group">
                    <label className="col-form-label">Name</label>
                    <sup>*</sup>
                    <InputForm
                      className="form-control form-control-lg"
                      placeholder={""}
                      name="name"
                      isDisabled={false}
                      textArea={false}
                      value={structure.name}
                      onChange={(e) => {
                        let newArr = [...structureData];
                        newArr[stIndex][e.target.name] = e.target.value;
                        setStructureData(newArr);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div>
                <button
                  className="btn btn-danger"
                  style={{ marginLeft: 5 }}
                  onClick={(e) => setShowConfirmationModal(true)}>
                  <i className="fa fa-trash"></i> Delete
                </button>
                <button
                  className="btn btn-info"
                  style={{ marginLeft: 5 }}
                  onClick={(e) => {
                    let newArr = [...structureData];

                    if (newArr[stIndex].hasOwnProperty("activityDetails")) {
                      newArr[stIndex].activityDetails = [
                        ...newArr[stIndex].activityDetails,
                        newActivity,
                      ];
                    } else {
                      newArr[stIndex].activityDetails = [newActivity];
                    }

                    setStructureData(newArr);
                  }}>
                  <i className="fa fa-sitemap"></i> Add Activity
                </button>
              </div>
            </div>
            <div>
              {structure.hasOwnProperty("activityDetails") &&
                structure.activityDetails.length > 0 &&
                structure.activityDetails.map((activity, acIndex) => (
                  <React.Fragment key={acIndex}>
                    <ActivityDetail
                      activity={activity}
                      structureData={structureData}
                      setStructureData={setStructureData}
                      stIndex={stIndex}
                      acIndex={acIndex}
                    />
                  </React.Fragment>
                ))}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Modal
        show={showConfirmationModal}
        onHide={() => closeConfirmationModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">Delete Structure</div>
        </div>
        <Modal.Body>
          <h5>Are you sure?</h5>
          <div>
            <strong>
              (Note: This will also delete related Activity and Parameter)
            </strong>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-success"
            style={{ marginLeft: 5 }}
            onClick={() => closeConfirmationModal(true)}>
            Yes
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => closeConfirmationModal(false)}>
            No
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StructureDetail;
