import React, { useContext, useEffect, useState } from "react";
import AccordianCustom1 from "../DocumentScreen/AccordianCustom1";
import { useLocation, useNavigate } from "react-router-dom";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { LoaderContext } from "../../Helpers/Context/Context";
import InputForm from "../../Components/InputForm/InputForm";
import SelectForm from "../../Components/SelectForm/SelectForm";
import DateForm from "../../Components/DateForm/DateForm";
import { APICall } from "../../Helpers/APICalls";
import {
  GetContractorDetailsDropDown,
  GetManageContractorDetails,
  ManageContractorDetails,
} from "../../Helpers/APIEndPoints/AssociateEndPoints";
import notify from "../../Helpers/ToastNotification";
import { isEmpty } from "../../utils/isEmpty";
import { NationalIdCardType } from "../../Components/FromStructure/NationalIdCardTypeRegex";
import moment from "moment";
import { getDropdowns } from "../../Helpers/APIEndPoints/EndPoints";

interface IOptions {
  value?: number | string;
  label?: string;
  code?: string;
}

interface IFormData {
  sapVendorCode?: string | Number | any;
  contractorRegistrationNumber?: string | Number | any;
  vendorCompanyName?: Number | string | any;
  business?: IOptions | Number | any;
  sbu?: IOptions | Number | any;
  legalEntity?: IOptions | Number | any;
  payrollLocation?: IOptions | Number | any;
  vendorTypeId?: IOptions | Number | any;
  vendorCategoryId?: IOptions | Number | any;
  ownerName?: Number | string | any;
  contactNumber?: Number | string | any;
  emailId?: string | Number | any;
  registrationCategoryId?: IOptions | Number | any;
  vendorCompanyTypeId?: IOptions | Number | any;
  stateId?: IOptions | Number | any;
  pinCode?: Number | string | any;
  address?: Number | string | any;
  panNumber?: Number | string | any;
  staxNumber?: Number | string | any;
  cstNumber?: Number | string | any;
  gstNumber?: Number | string | any;
  tanNumber?: Number | string | any;
  effectiveDate?: Number | string | any;
  natureOrWork?: Number | string | any;
  division?: IOptions | Number | string | any;
  operation?: IOptions | Number | string | any;
}

const ContractorCreation = () => {
  const { state } = useLocation();

  const navigate = useNavigate();
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  const { showLoader, hideLoader } = useContext(LoaderContext);

  const [formErrors, setFormErrors] = useState<IFormData>({});

  const [formOptions, setFormOptions] = useState<IFormData>({});

  const [formData, setFormData] = useState<IFormData>({});

  const [id, setId] = useState((state && state?.id) || 0);

  useEffect(() => {
    const responseData = async () => {
      const parameters = {
        Mode: "PAYROLLLOCATION",
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      };
      // const res = await APICall(
      //   GetContractorDetailsDropDown,
      //   "POST",
      //   parameters
      // );

      // if (res?.status === 0 && res?.data?.length > 0) {
      //   setFormOptions((env) => {
      //     return {
      //       ...env,
      //       payrollLocation: res?.data,
      //     };
      //   });
      // }

      const res1 = await getDDData("BISP", "");

      if (res1?.length > 0) {
        setFormOptions((env) => {
          return {
            ...env,
            business: res1,
          };
        });
      }

      parameters.Mode = "STATE";

      const res2 = await APICall(
        GetContractorDetailsDropDown,
        "POST",
        parameters
      );

      if (res2?.status === 0 && res2?.data?.length > 0) {
        setFormOptions((env) => {
          return {
            ...env,
            stateId: res2?.data,
          };
        });
      }

      parameters.Mode = "VendorType";

      const res3 = await APICall(
        GetContractorDetailsDropDown,
        "POST",
        parameters
      );

      if (res3?.status === 0 && res3?.data?.length > 0) {
        setFormOptions((env) => {
          return {
            ...env,
            vendorTypeId: res3?.data,
          };
        });
      }

      parameters.Mode = "VendorCategory";

      const res4 = await APICall(
        GetContractorDetailsDropDown,
        "POST",
        parameters
      );

      if (res4?.status === 0 && res4?.data?.length > 0) {
        setFormOptions((env) => {
          return {
            ...env,
            vendorCategoryId: res4?.data,
          };
        });
      }

      parameters.Mode = "RegistrationCategory";

      const res5 = await APICall(
        GetContractorDetailsDropDown,
        "POST",
        parameters
      );

      if (res5?.status === 0 && res5?.data?.length > 0) {
        setFormOptions((env) => {
          return {
            ...env,
            registrationCategoryId: res5?.data,
          };
        });
      }

      parameters.Mode = "VendorCompanyType";

      const res6 = await APICall(
        GetContractorDetailsDropDown,
        "POST",
        parameters
      );

      if (res6?.status === 0 && res6?.data?.length > 0) {
        setFormOptions((env) => {
          return {
            ...env,
            vendorCompanyTypeId: res6?.data,
          };
        });
      }
    };

    showLoader();

    responseData();

    hideLoader();
  }, []);

  const getDDData = async (AC, PID) => {
    const dropdowndataResponse = await APICall(getDropdowns, "POST", {
      AC,
      PID,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });
    const dropdownData = dropdowndataResponse.data;

    return dropdownData;
  };

  const formOnChange = async (e, type) => {
    switch (type) {
      case "sapVendorCode":
        setFormData({
          ...formData,
          sapVendorCode: e.target.value,
        });
        break;
      case "contractorRegistrationNumber":
        setFormData({
          ...formData,
          contractorRegistrationNumber: e.target.value,
        });
        break;

      case "vendorCompanyName":
        setFormData({
          ...formData,
          vendorCompanyName: e.target.value,
        });
        break;

      case "business":
        setFormData({
          ...formData,
          business: e,
          sbu: null,
          legalEntity: null,
          payrollLocation: null,
        });

        // const commaSeparatedBISIds =
        //   e && e?.length > 0 && e?.map((eve) => eve.value).join(",");
        // if (commaSeparatedBISIds) {

        if (isEmpty(e)) {
          setFormOptions((env) => {
            return {
              ...env,
              sbu: [],
              legalEntity: [],
              payrollLocation: [],
            };
          });
        } else {
          let sbuData = await getDDData("SBU", e?.value);

          if (sbuData?.length > 0) {
            setFormOptions((env) => {
              return {
                ...env,
                sbu: sbuData,
                legalEntity: [],
                payrollLocation: [],
              };
            });
          } else {
            setFormOptions((env) => {
              return {
                ...env,
                sbu: [],
                legalEntity: [],
                payrollLocation: [],
              };
            });
          }
        }

        break;

      case "sbu":
        setFormData({
          ...formData,
          sbu: e,
          legalEntity: null,
          payrollLocation: null,
        });

        // const commaSeparatedSBUIds =
        //   e && e?.length > 0 && e?.map((eve) => eve.value).join(",");
        // if (commaSeparatedSBUIds) {

        if (isEmpty(e)) {
          setFormOptions((env) => {
            return {
              ...env,
              legalEntity: [],
              payrollLocation: [],
            };
          });
        } else {
          let LENData = await getDDData("LEN", e?.value);

          if (LENData?.length > 0) {
            setFormOptions((env) => {
              return {
                ...env,
                legalEntity: LENData,
                payrollLocation: [],
              };
            });
          } else {
            setFormOptions((env) => {
              return {
                ...env,
                legalEntity: [],
                payrollLocation: [],
              };
            });
          }
        }
        break;

      case "legalEntity":
        setFormData({
          ...formData,
          legalEntity: e,
          payrollLocation: null,
        });

        if (isEmpty(e)) {
          setFormOptions((env) => {
            return {
              ...env,
              payrollLocation: [],
            };
          });
        } else {
          let PayrollLocationData = await getDDData("PLOC", e?.value);

          if (PayrollLocationData?.length > 0) {
            setFormOptions((env) => {
              return {
                ...env,
                payrollLocation: PayrollLocationData,
              };
            });
          } else {
            setFormOptions((env) => {
              return {
                ...env,
                payrollLocation: PayrollLocationData,
              };
            });
          }
        }
        break;

      case "payrollLocation":
        setFormData({
          ...formData,
          payrollLocation: e,
        });
        break;

      case "vendorTypeId":
        setFormData({
          ...formData,
          vendorTypeId: e,
        });
        break;

      case "vendorCategoryId":
        setFormData({
          ...formData,
          vendorCategoryId: e,
        });
        break;

      case "ownerName":
        setFormData({
          ...formData,
          ownerName: e.target.value,
        });
        break;

      case "contactNumber":
        setFormData({
          ...formData,
          contactNumber: e.target.value,
        });
        break;

      case "emailId":
        setFormData({
          ...formData,
          emailId: e.target.value,
        });
        break;

      case "registrationCategoryId":
        setFormData({
          ...formData,
          registrationCategoryId: e,
        });
        break;

      case "vendorCompanyTypeId":
        setFormData({
          ...formData,
          vendorCompanyTypeId: e,
        });
        break;

      case "stateId":
        setFormData({
          ...formData,
          stateId: e,
        });
        break;

      case "pinCode":
        setFormData({
          ...formData,
          pinCode: e.target.value,
        });
        break;

      case "address":
        setFormData({
          ...formData,
          address: e.target.value,
        });
        break;

      case "panNumber":
        setFormData({
          ...formData,
          panNumber: e.target.value,
        });
        break;

      case "staxNumber":
        setFormData({
          ...formData,
          staxNumber: e.target.value,
        });
        break;

      case "cstNumber":
        setFormData({
          ...formData,
          cstNumber: e.target.value,
        });
        break;

      case "gstNumber":
        setFormData({
          ...formData,
          gstNumber: e.target.value,
        });
        break;

      case "tanNumber":
        setFormData({
          ...formData,
          tanNumber: e.target.value,
        });
        break;

      case "effectiveDate":
        setFormData({
          ...formData,
          effectiveDate: e,
        });
        break;

      case "natureOrWork":
        setFormData({
          ...formData,
          natureOrWork: e.target.value,
        });
        break;

      default:
        break;
    }
  };

  const validateContractorDetails = () => {
    let errorObj = {};
    let isError = false;

    setFormErrors({});

    if (isEmpty(formData?.vendorCompanyName)) {
      errorObj = {
        ...errorObj,
        ["vendorCompanyName"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["vendorCompanyName"]: "",
      };
    }

    if (isEmpty(formData?.business)) {
      errorObj = {
        ...errorObj,
        ["business"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["business"]: "",
      };
    }

    if (isEmpty(formData?.sbu)) {
      errorObj = {
        ...errorObj,
        ["sbu"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["sbu"]: "",
      };
    }

    if (isEmpty(formData?.legalEntity)) {
      errorObj = {
        ...errorObj,
        ["legalEntity"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["legalEntity"]: "",
      };
    }

    if (isEmpty(formData?.payrollLocation)) {
      errorObj = {
        ...errorObj,
        ["payrollLocation"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["payrollLocation"]: "",
      };
    }

    if (isEmpty(formData?.vendorTypeId)) {
      errorObj = {
        ...errorObj,
        ["vendorTypeId"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["vendorTypeId"]: "",
      };
    }

    if (isEmpty(formData?.vendorCategoryId)) {
      errorObj = {
        ...errorObj,
        ["vendorCategoryId"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["vendorCategoryId"]: "",
      };
    }

    if (isEmpty(formData?.ownerName)) {
      errorObj = {
        ...errorObj,
        ["ownerName"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["ownerName"]: "",
      };
    }

    if (isEmpty(formData?.contactNumber)) {
      errorObj = {
        ...errorObj,
        ["contactNumber"]: "Required",
      };
    } else if (!NationalIdCardType.MOBILENO.test(formData?.contactNumber)) {
      errorObj = {
        ...errorObj,
        ["contactNumber"]: "Contact Number is invalid",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["contactNumber"]: "",
      };
    }

    if (isEmpty(formData?.emailId)) {
      errorObj = {
        ...errorObj,
        ["emailId"]: "Required",
      };
    } else if (!NationalIdCardType.EMAILID.test(formData?.emailId)) {
      errorObj = {
        ...errorObj,
        ["emailId"]: "Email Id is invalid",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["emailId"]: "",
      };
    }

    if (isEmpty(formData?.registrationCategoryId)) {
      errorObj = {
        ...errorObj,
        ["registrationCategoryId"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["registrationCategoryId"]: "",
      };
    }

    if (isEmpty(formData?.address)) {
      errorObj = {
        ...errorObj,
        ["address"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["address"]: "",
      };
    }

    if (isEmpty(formData?.stateId)) {
      errorObj = {
        ...errorObj,
        ["stateId"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["stateId"]: "",
      };
    }

    if (isEmpty(formData?.pinCode)) {
      errorObj = {
        ...errorObj,
        ["pinCode"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["pinCode"]: "",
      };
    }

    if (isEmpty(formData?.vendorCompanyTypeId)) {
      errorObj = {
        ...errorObj,
        ["vendorCompanyTypeId"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["vendorCompanyTypeId"]: "",
      };
    }

    if (isEmpty(formData?.panNumber)) {
      errorObj = {
        ...errorObj,
        ["panNumber"]: "Required",
      };
    } else if (!NationalIdCardType.PAN.test(formData?.panNumber)) {
      errorObj = {
        ...errorObj,
        ["panNumber"]: "PAN Number is invalid",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["panNumber"]: "",
      };
    }

    if (!isEmpty(formData?.staxNumber)) {
      if (!NationalIdCardType.STaxNumber.test(formData?.staxNumber)) {
        errorObj = {
          ...errorObj,
          ["staxNumber"]: "STax Number is invalid",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["staxNumber"]: "",
        };
      }
    }

    if (!isEmpty(formData?.cstNumber)) {
      if (!NationalIdCardType.CSTNumber.test(formData?.cstNumber)) {
        errorObj = {
          ...errorObj,
          ["cstNumber"]: "CST Number is invalid",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["cstNumber"]: "",
        };
      }
    }

    if (!isEmpty(formData?.tanNumber)) {
      if (!NationalIdCardType.TANNumber.test(formData?.tanNumber)) {
        errorObj = {
          ...errorObj,
          ["tanNumber"]: "TAN Number is invalid",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["tanNumber"]: "",
        };
      }
    }

    if (isEmpty(formData?.gstNumber)) {
      errorObj = {
        ...errorObj,
        ["gstNumber"]: "Required",
      };
    } else if (!NationalIdCardType.GSTNumber.test(formData?.gstNumber)) {
      errorObj = {
        ...errorObj,
        ["gstNumber"]: "GST Number is invalid",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["gstNumber"]: "",
      };
    }

    if (
      formData?.effectiveDate === null ||
      formData?.effectiveDate === undefined
    ) {
      errorObj = {
        ...errorObj,
        ["effectiveDate"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["effectiveDate"]: "",
      };
    }

    if (isEmpty(formData?.natureOrWork)) {
      errorObj = {
        ...errorObj,
        ["natureOrWork"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["natureOrWork"]: "",
      };
    }
    const isEmptyObj = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmptyObj) {
      isError = true;
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  const handleManageContractorDetailsApiCall = async () => {
    try {
      showLoader();

      if (validateContractorDetails()) {
        hideLoader();
        return;
      }

      const parameters = {
        Mode: "CONTRACTORREGISTRATION",
        Id: id,
        SAPVendorCode: formData?.sapVendorCode,
        VendorCompanyName: formData?.vendorCompanyName,
        Business: formData?.business?.value,
        SBU: formData?.sbu?.value,
        LegalEntity: formData?.legalEntity?.value,
        PayrollLocation: formData?.payrollLocation?.value,
        VendorTypeId: formData?.vendorTypeId?.value,
        VendorCategoryId: formData?.vendorCategoryId?.value,
        OwnerName: formData?.ownerName,
        ContactNumber: formData?.contactNumber,
        EmailId: formData?.emailId,
        RegistrationCategoryId: formData?.registrationCategoryId?.value,
        VendorCompanyTypeId: formData?.vendorCompanyTypeId?.value,
        StateId: formData?.stateId?.value,
        PinCode: formData?.pinCode,
        Address: formData?.address,
        PANNumber: formData?.panNumber,
        STaxNumber: formData?.staxNumber,
        CSTNumber: formData?.cstNumber,
        GSTNumber: formData?.gstNumber,
        TANNumber: formData?.tanNumber,
        EffectiveDate: moment(formData?.effectiveDate).format("MM-DD-YYYY"),
        NatureOrWork: formData?.natureOrWork,
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      };
      const response = await APICall(
        ManageContractorDetails,
        "POST",
        parameters
      );

      if (response?.status === 0) {
        notify(response?.status, response?.message);
        navigate("/ContractorDashboard");
      } else if (response?.status === 1) {
        notify(response?.status, response?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (
      id > 0 &&
      formOptions?.business?.length > 0 &&
      formOptions?.stateId?.length > 0 &&
      formOptions?.vendorTypeId?.length > 0 &&
      formOptions?.vendorCategoryId?.length > 0 &&
      formOptions?.registrationCategoryId?.length > 0 &&
      formOptions?.vendorCompanyTypeId?.length > 0
    ) {
      GetManageContractorDetailsApiCall(id);
    }
  }, [
    formOptions?.business,
    formOptions?.stateId,
    formOptions?.vendorTypeId,
    formOptions?.vendorCategoryId,
    formOptions?.registrationCategoryId,
    formOptions?.vendorCompanyTypeId,
  ]);

  const GetManageContractorDetailsApiCall = async (id) => {
    try {
      showLoader();
      if (id > 0) {
        const response = await APICall(GetManageContractorDetails, "POST", {
          Id: id,
        });

        if (response?.status === 0) {
          setId(response?.data?.id);

          const contractorDetails = response?.data;

          const businessObj = formOptions?.business?.find(
            (val) => val?.value === Number(contractorDetails?.business)
          );

          const sbuData = await getDDData("SBU", contractorDetails?.business);

          const LegalEntityData = await getDDData(
            "LEN",
            contractorDetails?.sbu
          );

          const PayrollLocationData = await getDDData(
            "PLOC",
            contractorDetails?.legalEntity
          );

          const sbuObj = sbuData?.find(
            (val) => val?.value === Number(contractorDetails?.sbu)
          );

          const LegalEntityDataObj = LegalEntityData?.find(
            (val) => val?.value === Number(contractorDetails?.legalEntity)
          );

          const payrollLocationObj = PayrollLocationData?.find(
            (val) => val?.value === Number(contractorDetails?.payrollLocation)
          );

          const stateObj = formOptions?.stateId?.find(
            (val) => val?.value === contractorDetails?.stateId
          );

          const vendorTypeObj = formOptions?.vendorTypeId?.find(
            (val) => val?.value === contractorDetails?.vendorTypeId
          );

          const vendorCategoryObj = formOptions?.vendorCategoryId?.find(
            (val) => val?.value === contractorDetails?.vendorCategoryId
          );

          const registrationCategoryObj =
            formOptions?.registrationCategoryId?.find(
              (val) => val?.value === contractorDetails?.registrationCategoryId
            );

          const vendorCompanyTypeObj = formOptions?.vendorCompanyTypeId?.find(
            (val) => val?.value === contractorDetails?.vendorCompanyTypeId
          );

          setFormOptions((env) => {
            return {
              ...env,
              sbu: sbuData,
              legalEntity: LegalEntityData,
              payrollLocation: PayrollLocationData,
            };
          });

          setFormData((env) => {
            return {
              ...env,
              sapVendorCode: contractorDetails?.sapVendorCode,
              contractorRegistrationNumber:
                contractorDetails?.contractorRegistrationNumber,
              vendorCompanyName: contractorDetails?.vendorCompanyName,
              business: businessObj,
              sbu: sbuObj,
              legalEntity: LegalEntityDataObj,
              payrollLocation: payrollLocationObj,
              vendorTypeId: vendorTypeObj,
              vendorCategoryId: vendorCategoryObj,
              ownerName: contractorDetails?.ownerName,
              contactNumber: contractorDetails?.contactNumber,
              emailId: contractorDetails?.emailId,
              registrationCategoryId: registrationCategoryObj,
              vendorCompanyTypeId: vendorCompanyTypeObj,
              stateId: stateObj,
              pinCode: contractorDetails?.pinCode,
              address: contractorDetails?.address,
              panNumber: contractorDetails?.panNumber,
              staxNumber: contractorDetails?.sTaxNumber,
              cstNumber: contractorDetails?.cstNumber,
              gstNumber: contractorDetails?.gstNumber,
              tanNumber: contractorDetails?.tanNumber,
              effectiveDate: contractorDetails?.effectiveDate,
              natureOrWork: contractorDetails?.natureOrWork,
            };
          });
        } else if (response?.status === 1) {
          notify(response?.status, response?.message);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const handleResetSection = () => {
    setFormErrors({});
    setFormData((env) => {
      return {
        sapVendorCode: "",
        contractorRegistrationNumber: "",
        vendorCompanyName: "",
        legalEntityId: null,
        locationId: null,
        vendorTypeId: null,
        vendorCategoryId: null,
        ownerName: "",
        contactNumber: "",
        emailId: "",
        registrationCategoryId: null,
        vendorCompanyTypeId: null,
        stateId: null,
        pinCode: "",
        address: "",
        panNumber: "",
        staxNumber: "",
        cstNumber: "",
        gstNumber: "",
        tanNumber: "",
        effectiveDate: null,
        natureOrWork: "",
      };
    });
  };
  return (
    <>
      {/* <div className="form-main">
        <div className="page-title w-100">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <h4>
                  {" "}
                  <i
                    className="fa-solid fa-home pr-1"
                    style={{
                      color: "white",
                      cursor: "pointer",
                      fontSize: "13px",
                      verticalAlign: "middle",
                    }}
                    onClick={() => {
                      navigate("/Bluecollar");
                    }}
                  ></i>
                  Contractor Creation
                </h4>
              </div>
              <div className="offset-lg-6 "></div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="breadcrumb modified-bredcrumb">
        <h4>Contractor Creation</h4>
        <button
          onClick={() => {
            navigate("/ContractorDashboard");
          }}
          disabled={false}
          className="btn btn-secondary float-right"
        >
          <i className="fa-solid fa-arrow-left"></i> Back
        </button>
      </div>
      <div className="container-fluid mt-2" id="blue-collar-pages">
        <div className="col-lg-12 mt-2">
          <AccordianCustom1
            header={"Contract Registration"}
            submitFunc={""}
            btnDisable={false}
            hideBtn={true}
          >
            <div className="row">
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">SAP Vendor Code</label>
                  <InputForm
                    className="form-control"
                    placeholder={"SAP Vendor Code"}
                    isDisabled={false}
                    textArea={false}
                    value={formData?.sapVendorCode}
                    onChange={(e: any) => {
                      formOnChange(e, "sapVendorCode");
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Contractor Registration Number
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Contractor Registration Number"}
                    isDisabled={true}
                    textArea={false}
                    value={formData?.contractorRegistrationNumber}
                    onChange={(e: any) => {
                      formOnChange(e, "contractorRegistrationNumber");
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">Vendor Company Name</label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control"
                    placeholder={"Vendor Company Name"}
                    isDisabled={false}
                    textArea={false}
                    value={formData?.vendorCompanyName}
                    onChange={(e: any) => {
                      formOnChange(e, "vendorCompanyName");
                    }}
                  />
                  {formErrors["vendorCompanyName"] && (
                    <p style={{ color: "red" }}>
                      {formErrors["vendorCompanyName"]}
                    </p>
                  )}
                </div>
              </div>

              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">Business</label>
                  <sup>*</sup>

                  <SelectForm
                    isClearable
                    isSearchable
                    options={formOptions?.business}
                    placeholder={"Business"}
                    isDisabled={false}
                    value={formData?.business}
                    onChange={async (e) => {
                      formOnChange(e, "business");
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  {formErrors["business"] && (
                    <p style={{ color: "red" }}>{formErrors["business"]}</p>
                  )}
                </div>
              </div>

              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">SBU</label>
                  <sup>*</sup>

                  <SelectForm
                    isClearable
                    isSearchable
                    options={formOptions?.sbu}
                    placeholder={"SBU"}
                    isDisabled={false}
                    value={formData?.sbu}
                    onChange={async (e) => {
                      formOnChange(e, "sbu");
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  {formErrors["sbu"] && (
                    <p style={{ color: "red" }}>{formErrors["sbu"]}</p>
                  )}
                </div>
              </div>

              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">Legal Entity</label>
                  <sup>*</sup>

                  <SelectForm
                    isClearable
                    isSearchable
                    options={formOptions?.legalEntity}
                    placeholder={"Legal Entity"}
                    isDisabled={false}
                    value={formData?.legalEntity}
                    onChange={async (e) => {
                      formOnChange(e, "legalEntity");
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  {formErrors["legalEntity"] && (
                    <p style={{ color: "red" }}>{formErrors["legalEntity"]}</p>
                  )}
                </div>
              </div>
              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">Payroll Location</label>
                  <sup>*</sup>

                  <SelectForm
                    isClearable
                    isSearchable
                    options={formOptions?.payrollLocation}
                    placeholder={"Payroll Location"}
                    isDisabled={false}
                    value={formData?.payrollLocation}
                    onChange={async (e) => {
                      formOnChange(e, "payrollLocation");
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  {formErrors["payrollLocation"] && (
                    <p style={{ color: "red" }}>
                      {formErrors["payrollLocation"]}
                    </p>
                  )}
                </div>
              </div>
              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">Vendor Type</label>
                  <sup>*</sup>

                  <SelectForm
                    isClearable
                    isSearchable
                    options={formOptions?.vendorTypeId}
                    placeholder={"Vendor Type"}
                    isDisabled={false}
                    value={formData?.vendorTypeId}
                    onChange={async (e) => {
                      formOnChange(e, "vendorTypeId");
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  {formErrors["vendorTypeId"] && (
                    <p style={{ color: "red" }}>{formErrors["vendorTypeId"]}</p>
                  )}
                </div>
              </div>

              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">Vendor Category</label>
                  <sup>*</sup>

                  <SelectForm
                    isClearable
                    isSearchable
                    options={formOptions?.vendorCategoryId}
                    placeholder={"Vendor Category"}
                    isDisabled={false}
                    value={formData?.vendorCategoryId}
                    onChange={async (e) => {
                      formOnChange(e, "vendorCategoryId");
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  {formErrors["vendorCategoryId"] && (
                    <p style={{ color: "red" }}>
                      {formErrors["vendorCategoryId"]}
                    </p>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="SectionSubmit mb-2 clearfix">
              <button
                onClick={() => {
                  //   submitSkillTest();
                }}
                disabled={false}
                className="btn btn-filter-submit float-right ml-2 mt-4"
              >
                <i className="fa fa-save"></i> Submit
              </button>

              <button
                onClick={() => {
                  //   onResetData();
                }}
                disabled={false}
                className="btn btn-secondary float-right ml-2 mt-4"
              >
                <i className="fa-solid fa-refresh"></i> Reset
              </button>
            </div> */}
          </AccordianCustom1>

          <AccordianCustom1
            header={"Owner Details"}
            submitFunc={""}
            btnDisable={false}
            hideBtn={true}
          >
            <div className="row">
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">Owner Name</label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control"
                    placeholder={"Owner Name"}
                    isDisabled={false}
                    textArea={false}
                    value={formData?.ownerName}
                    onChange={(e: any) => {
                      formOnChange(e, "ownerName");
                    }}
                  />
                  {formErrors["ownerName"] && (
                    <p style={{ color: "red" }}>{formErrors["ownerName"]}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">Contact Number</label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control"
                    placeholder={"Contact Number"}
                    isDisabled={false}
                    textArea={false}
                    value={formData?.contactNumber}
                    onChange={(e: any) => {
                      formOnChange(e, "contactNumber");
                    }}
                  />
                  {formErrors["contactNumber"] && (
                    <p style={{ color: "red" }}>
                      {formErrors["contactNumber"]}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">Email Id</label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control"
                    placeholder={"Email Id"}
                    isDisabled={false}
                    textArea={false}
                    value={formData?.emailId}
                    onChange={(e: any) => {
                      formOnChange(e, "emailId");
                    }}
                  />
                  {formErrors["emailId"] && (
                    <p style={{ color: "red" }}>{formErrors["emailId"]}</p>
                  )}
                </div>
              </div>

              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Registration Category
                  </label>
                  <sup>*</sup>

                  <SelectForm
                    isClearable
                    isSearchable
                    options={formOptions?.registrationCategoryId}
                    placeholder={"Registration Category"}
                    isDisabled={false}
                    value={formData?.registrationCategoryId}
                    onChange={async (e) => {
                      formOnChange(e, "registrationCategoryId");
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  {formErrors["registrationCategoryId"] && (
                    <p style={{ color: "red" }}>
                      {formErrors["registrationCategoryId"]}
                    </p>
                  )}
                </div>
              </div>
              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">Vendor Company Type</label>
                  <sup>*</sup>

                  <SelectForm
                    isClearable
                    isSearchable
                    options={formOptions?.vendorCompanyTypeId}
                    placeholder={"Vendor Company Type"}
                    isDisabled={false}
                    value={formData?.vendorCompanyTypeId}
                    onChange={async (e) => {
                      formOnChange(e, "vendorCompanyTypeId");
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  {formErrors["vendorCompanyTypeId"] && (
                    <p style={{ color: "red" }}>
                      {formErrors["vendorCompanyTypeId"]}
                    </p>
                  )}
                </div>
              </div>
              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">State</label>
                  <sup>*</sup>

                  <SelectForm
                    isClearable
                    isSearchable
                    options={formOptions?.stateId}
                    placeholder={"State"}
                    isDisabled={false}
                    value={formData?.stateId}
                    onChange={async (e) => {
                      formOnChange(e, "stateId");
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  {formErrors["stateId"] && (
                    <p style={{ color: "red" }}>{formErrors["stateId"]}</p>
                  )}
                </div>
              </div>

              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">Pin Code</label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control"
                    placeholder={"Pin Code"}
                    isDisabled={false}
                    textArea={false}
                    value={formData?.pinCode}
                    onChange={(e: any) => {
                      formOnChange(e, "pinCode");
                    }}
                  />
                  {formErrors["pinCode"] && (
                    <p style={{ color: "red" }}>{formErrors["pinCode"]}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">Address</label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control"
                    placeholder={"Address"}
                    isDisabled={false}
                    textArea={true}
                    value={formData?.address}
                    onChange={(e: any) => {
                      formOnChange(e, "address");
                    }}
                  />
                  {formErrors["address"] && (
                    <p style={{ color: "red" }}>{formErrors["address"]}</p>
                  )}
                </div>
              </div>
            </div>
          </AccordianCustom1>

          <AccordianCustom1
            header={"Registration Details"}
            submitFunc={""}
            btnDisable={false}
            hideBtn={true}
          >
            <div className="row">
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">PAN No.</label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control"
                    placeholder={"PAN No."}
                    isDisabled={false}
                    textArea={false}
                    value={formData?.panNumber}
                    onChange={(e: any) => {
                      formOnChange(e, "panNumber");
                    }}
                  />
                  {formErrors["panNumber"] && (
                    <p style={{ color: "red" }}>{formErrors["panNumber"]}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">STax Number</label>
                  <InputForm
                    className="form-control"
                    placeholder={"STax Number"}
                    isDisabled={false}
                    textArea={false}
                    value={formData?.staxNumber}
                    onChange={(e: any) => {
                      formOnChange(e, "staxNumber");
                    }}
                  />
                  {formErrors["staxNumber"] && (
                    <p style={{ color: "red" }}>{formErrors["staxNumber"]}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">CST Number</label>
                  <InputForm
                    className="form-control"
                    placeholder={"CST Number"}
                    isDisabled={false}
                    textArea={false}
                    value={formData?.cstNumber}
                    onChange={(e: any) => {
                      formOnChange(e, "cstNumber");
                    }}
                  />
                  {formErrors["cstNumber"] && (
                    <p style={{ color: "red" }}>{formErrors["cstNumber"]}</p>
                  )}
                </div>
              </div>

              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">GST Number</label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control"
                    placeholder={"GST Number"}
                    isDisabled={false}
                    textArea={false}
                    value={formData?.gstNumber}
                    onChange={(e: any) => {
                      formOnChange(e, "gstNumber");
                    }}
                  />
                  {formErrors["gstNumber"] && (
                    <p style={{ color: "red" }}>{formErrors["gstNumber"]}</p>
                  )}
                </div>
              </div>

              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">TAN Number</label>
                  <InputForm
                    className="form-control"
                    placeholder={"TAN Number"}
                    isDisabled={false}
                    textArea={false}
                    value={formData?.tanNumber}
                    onChange={(e: any) => {
                      formOnChange(e, "tanNumber");
                    }}
                  />
                  {formErrors["tanNumber"] && (
                    <p style={{ color: "red" }}>{formErrors["tanNumber"]}</p>
                  )}
                </div>
              </div>

              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">Effective Date</label>
                  <sup>*</sup>
                  <DateForm
                    placeholder={"Effective Date"}
                    isDisabled={false}
                    value={formData?.effectiveDate || null}
                    onChange={(e: any) => {
                      formOnChange(e, "effectiveDate");
                    }}
                  />
                  {formErrors["effectiveDate"] && (
                    <p style={{ color: "red" }}>
                      {formErrors["effectiveDate"]}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">Nature Or Work</label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control"
                    placeholder={"Nature Or Work"}
                    isDisabled={false}
                    textArea={false}
                    value={formData?.natureOrWork}
                    onChange={(e: any) => {
                      formOnChange(e, "natureOrWork");
                    }}
                  />
                  {formErrors["natureOrWork"] && (
                    <p style={{ color: "red" }}>{formErrors["natureOrWork"]}</p>
                  )}
                </div>
              </div>
            </div>
          </AccordianCustom1>

          <div className="SectionSubmit mb-2 clearfix">
            <button
              onClick={() => {
                handleManageContractorDetailsApiCall();
              }}
              disabled={false}
              className="btn btn-filter-submit float-right ml-2 mt-4"
            >
              <i className="fa fa-save"></i> Save
            </button>

            <button
              onClick={() => {
                handleResetSection();
              }}
              disabled={false}
              className="btn btn-secondary float-right ml-2 mt-4"
            >
              <i className="fa-solid fa-refresh"></i> Reset
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContractorCreation;
