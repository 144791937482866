import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import InputForm from "../../Components/InputForm/InputForm";
import SelectForm from "../../Components/SelectForm/SelectForm";
import { LoaderContext } from "../../Helpers/Context/Context";
import { useLocation, useNavigate } from "react-router-dom";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { APICall } from "../../Helpers/APICalls";
import {
  getCourseAssessmentDropDownList,
  getCourseFeedbackDropDownList,
  manageCourseNomination,
  saveCourseAssessment,
  saveCourseFeedback,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import notify from "../../Helpers/ToastNotification";

interface IOptions {
  label?: string;
  value?: number | string;
  code?: string;
}

interface IFormData {
  assessmentNameId?: IOptions | string | any;
  assessmentMandatory?: IOptions | string | any;
  assessmentCourse?: IOptions | string | any;
  assessmentLevel?: IOptions | string | any;
  assessmentType?: IOptions | string | any;
  assessmentDays?: Number | any;
  feedbackNameId?: IOptions | string | any;
  feedbackMandatory?: IOptions | string | any;
  feedbacktCourse?: IOptions | string | any;
  feedbacktLevel?: IOptions | string | any;
  feedbackDays?: Number | any;
}
const AssessmantAndFeedback = ({
  showAssessmentandFeedbackModal,
  setShowAssessmentandFeedbackModal,
  newLaunchHubId,
  isCourseMandatory,
}) => {
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isDisable, setIsDisable] = useState(
    state && state?.isDisable ? true : false
  );
  let { userDetails, currentRoleId }: any = useUserContext();
  const [formData, setFormData] = useState<IFormData>({});
  const [formErrors, setFormErrors] = useState<IFormData>({});
  const [formOptions, setFormOptions] = useState<IFormData>({});

  const [launchType, setLaunchType] = useState(true);
  const [modelTitle, setModelTitle] = useState("Launch Assessment");

  const [selectedCourseSchedule, setSelectedCourseSchedule] = useState(null);
  const [CourseScheduleOptions, setCourseScheduleOptions] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [courseOptions, setCourseOptions] = useState([]);

  const getCourseOptions = async () => {
    const res = await APICall(manageCourseNomination, "POST", {
      Mode: "Course",
      LaunchId: newLaunchHubId,
    });

    if (res.status === 0 && res.data !== null) {
      setCourseOptions(res.data);
    } else {
      setCourseOptions([]);
    }
  };

  const getCourseScheduleOptions = async () => {
    const res = await APICall(manageCourseNomination, "POST", {
      Mode: "CourseSchedule",
      CourseId: selectedCourse.value,
      LaunchId: newLaunchHubId,
    });

    if (res.status === 0 && res.data !== null) {
      setCourseScheduleOptions(res.data);
    } else {
      setCourseScheduleOptions([]);
    }
  };

  useEffect(() => {
    const responseData = async () => {
      // if (isCourseMandatory) {
      //   const res6 = await APICall(getCourseAssessmentDropDownList, "POST", {
      //     Mode: "COURSE",
      //     CourseId: newLaunchHubId,
      //     UserId: userDetails?.Id,
      //     RoleId: currentRoleId?.value,
      //   });
      //   if (res6.data && res6.data.length > 0) {
      //     setFormOptions((prev) => {
      //       return {
      //         ...prev,
      //         assessmentCourse: res6.data,
      //         feedbacktCourse: res6.data,
      //       };
      //     });
      //   }
      // }

      getCourseOptions();

      handleResetFunction();
    };

    showLoader();
    if (showAssessmentandFeedbackModal) {
      responseData();
    }

    hideLoader();
  }, [showAssessmentandFeedbackModal]);

  useEffect(() => {
    if (selectedCourse) {
      getCourseScheduleOptions();
    }
    setCourseScheduleOptions([]);
    setSelectedCourseSchedule(null);
  }, [selectedCourse]);

  useEffect(() => {
    const responseData = async () => {
      //ASSESSMENT

      const dropdownObj = {
        mode: "ASSESSMENT",
        userId: userDetails?.Id,
        roleId: currentRoleId?.value,
      };
      const res = await APICall(
        getCourseAssessmentDropDownList,
        "POST",
        dropdownObj
      );

      if (res.data && res.data.length > 0) {
        setFormOptions((prev) => {
          return {
            ...prev,
            assessmentNameId: res.data,
          };
        });
      }

      //ASSESSMENT_LEVEL

      dropdownObj.mode = "ASSESSMENT_LEVEL";
      const res1 = await APICall(
        getCourseAssessmentDropDownList,
        "POST",
        dropdownObj
      );

      if (res1.data && res1.data.length > 0) {
        setFormOptions((prev) => {
          return {
            ...prev,
            assessmentLevel: res1.data,
          };
        });
      }

      //ASSESSMENT_TYPE

      dropdownObj.mode = "ASSESSMENT_TYPE";
      const res2 = await APICall(
        getCourseAssessmentDropDownList,
        "POST",
        dropdownObj
      );

      if (res2.data && res2.data.length > 0) {
        setFormOptions((prev) => {
          return {
            ...prev,
            assessmentType: res2.data,
          };
        });
      }

      //FEEDBACK_LEVEL

      dropdownObj.mode = "FEEDBACK_LEVEL";
      const res3 = await APICall(
        getCourseFeedbackDropDownList,
        "POST",
        dropdownObj
      );

      if (res3.data && res3.data.length > 0) {
        setFormOptions((prev) => {
          return {
            ...prev,
            feedbacktLevel: res3.data,
          };
        });
      }

      const mandatoryObj = [
        { value: 1, label: "Yes" },
        { value: 0, label: "No" },
      ];

      setFormOptions((prev) => {
        return {
          ...prev,
          assessmentMandatory: mandatoryObj,
        };
      });

      setFormOptions((prev) => {
        return {
          ...prev,
          feedbackMandatory: mandatoryObj,
        };
      });

      //FEEDBACK
      const res4 = await APICall(getCourseFeedbackDropDownList, "POST", {
        Mode: "FEEDBACK",
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      });

      if (res4.data && res4.data.length > 0) {
        setFormOptions((prev) => {
          return {
            ...prev,
            feedbackNameId: res4.data,
          };
        });
      }
    };

    showLoader();

    responseData();

    hideLoader();
  }, []);

  const formOnChange = (e, type) => {
    switch (type) {
      case "assessmentNameId":
        setFormData({
          ...formData,
          assessmentNameId: e,
        });
        break;
      case "assessmentMandatory":
        setFormData({
          ...formData,
          assessmentMandatory: e,
        });
        break;

      case "assessmentCourse":
        setFormData({
          ...formData,
          assessmentCourse: e,
        });
        break;

      case "assessmentDays":
        setFormData({
          ...formData,
          assessmentDays: e.target.value,
        });
        break;

      case "feedbackNameId":
        setFormData({
          ...formData,
          feedbackNameId: e,
        });
        break;

      case "feedbacktCourse":
        setFormData({
          ...formData,
          feedbacktCourse: e,
        });
        break;

      case "feedbackMandatory":
        setFormData({
          ...formData,
          feedbackMandatory: e,
        });
        break;

      case "feedbackDays":
        setFormData({
          ...formData,
          feedbackDays: e.target.value,
        });
        break;

      case "course":
        setSelectedCourse(e);
        break;

      case "courseSchedule":
        setSelectedCourseSchedule(e);
        break;

      default:
        break;
    }
  };

  const validateForm = () => {
    let errorObj = {};
    let isError = false;
    setFormErrors({});

    if (launchType) {
      if (
        formData?.assessmentNameId?.length === 0 ||
        formData?.assessmentNameId === null ||
        formData?.assessmentNameId === undefined
      ) {
        errorObj = {
          ...errorObj,
          ["assessmentNameId"]: "Required",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["assessmentNameId"]: "",
        };
      }

      // if (isCourseMandatory) {
      //   if (
      //     formData?.assessmentCourse === null ||
      //     formData?.assessmentCourse === undefined
      //   ) {
      //     errorObj = {
      //       ...errorObj,
      //       ["assessmentCourse"]: "Required",
      //     };
      //   } else {
      //     errorObj = {
      //       ...errorObj,
      //       ["assessmentCourse"]: "",
      //     };
      //   }
      // }

      if (
        formData?.assessmentMandatory === null ||
        formData?.assessmentMandatory === undefined
      ) {
        errorObj = {
          ...errorObj,
          ["assessmentMandatory"]: "Required",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["assessmentMandatory"]: "",
        };
      }

      if (
        formData?.assessmentDays !== "" &&
        formData?.assessmentDays !== null &&
        formData?.assessmentDays !== undefined
      ) {
        if (!/^([0-9]+|0)$/.test(formData?.assessmentDays)) {
          errorObj = {
            ...errorObj,
            ["assessmentDays"]: "Only numbers are allowed",
          };
        } else {
          errorObj = {
            ...errorObj,
            ["assessmentDays"]: "",
          };
        }
      }
    } else {
      if (
        formData?.feedbackNameId === null ||
        formData?.feedbackNameId === undefined
      ) {
        errorObj = {
          ...errorObj,
          ["feedbackNameId"]: "Required",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["feedbackNameId"]: "",
        };
      }

      if (
        formData?.feedbackMandatory === null ||
        formData?.feedbackMandatory === undefined
      ) {
        errorObj = {
          ...errorObj,
          ["feedbackMandatory"]: "Required",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["feedbackMandatory"]: "",
        };
      }

      // if (isCourseMandatory) {
      //   if (
      //     formData?.feedbacktCourse === null ||
      //     formData?.feedbacktCourse === undefined
      //   ) {
      //     errorObj = {
      //       ...errorObj,
      //       ["feedbacktCourse"]: "Required",
      //     };
      //   } else {
      //     errorObj = {
      //       ...errorObj,
      //       ["feedbacktCourse"]: "",
      //     };
      //   }
      // }

      if (
        formData?.feedbackDays !== "" &&
        formData?.feedbackDays !== null &&
        formData?.feedbackDays !== undefined
      ) {
        if (!/^([0-9]+|0)$/.test(formData?.feedbackDays)) {
          errorObj = {
            ...errorObj,
            ["feedbackDays"]: "Only numbers are allowed",
          };
        } else {
          errorObj = {
            ...errorObj,
            ["feedbackDays"]: "",
          };
        }
      }
    }

    if (!selectedCourse) {
      errorObj = {
        ...errorObj,
        ["course"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["course"]: "",
      };
    }
    if (!selectedCourseSchedule) {
      errorObj = {
        ...errorObj,
        ["courseSchedule"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["courseSchedule"]: "",
      };
    }

    const isEmpty = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  const formOnChangeForLaunch = (type) => {
    handleResetFunction();
    if (type === "assessment") {
      setLaunchType(true);
      setModelTitle("Launch Assessment");
    } else if (type === "feedback") {
      setLaunchType(false);
      setModelTitle("Launch Feedback");
    }
  };

  const handleAssessmentSubmitFunction = async () => {
    showLoader();

    if (validateForm()) {
      hideLoader();
      return;
    }

    const assessmentLevelObj = formOptions?.assessmentLevel?.find(
      (val) => val?.code === "CS"
    );

    const assessmentTypeObj = formOptions?.assessmentType?.find(
      (val) => val?.code === "POST"
    );

    const parameters = {
      Mode: "SAVE",
      CourseId: selectedCourse.value || 0,
      AssessmentIds: formData?.assessmentNameId?.map((s) => s.value).join(","),
      AssessmentLevelId: assessmentLevelObj?.value,
      AssessmentTypeId: assessmentTypeObj?.value,
      IsMandatory: formData?.assessmentMandatory?.value,
      AssessmentDays: formData?.assessmentDays || 0,
      ValueId: selectedCourseSchedule.value || 0,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      LaunchId: newLaunchHubId,
    };

    const responseData = await APICall(
      saveCourseAssessment,
      "POST",
      parameters
    );

    if (responseData?.status === 0) {
      notify(responseData?.status, responseData?.message);
      setShowAssessmentandFeedbackModal(false);
    } else if (responseData?.status === 1) {
      notify(responseData?.status, responseData?.message);
    }

    hideLoader();
  };

  const handleResetFunction = () => {
    setFormData((env) => {
      return {
        ...env,
        assessmentNameId: null,
        assessmentMandatory: null,
        assessmentDays: "",
        assessmentCourse: null,
        feedbackNameId: null,
        feedbackDays: "",
        feedbackMandatory: null,
        feedbacktCourse: null,
      };
    });

    setSelectedCourse(null);
    setSelectedCourseSchedule(null);

    setFormErrors({});
  };

  const handleFeedbackSubmitFunction = async () => {
    showLoader();

    if (validateForm()) {
      hideLoader();
      return;
    }

    const feedbackLeveObj = formOptions?.feedbacktLevel?.find(
      (val) => val?.code === "CS"
    );

    const parameters = {
      Mode: "SAVE",
      CourseId: selectedCourse.value || 0,
      FeedbackIds: formData?.feedbackNameId?.map((s) => s.value).join(","),
      FeedbackLevelId: feedbackLeveObj?.value,
      IsMandatory: formData?.feedbackMandatory?.value,
      FeedbackDays: formData?.feedbackDays || 0,
      ValueId: selectedCourseSchedule.value || 0,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      LaunchId: newLaunchHubId,
    };

    const responseData = await APICall(saveCourseFeedback, "POST", parameters);

    if (responseData?.status === 0) {
      notify(responseData?.status, responseData?.message);
      setShowAssessmentandFeedbackModal(false);
    } else if (responseData?.status === 1) {
      notify(responseData?.status, responseData?.message);
    }

    hideLoader();
  };

  return (
    <>
      {" "}
      {/* Assessment & Feedback */}
      <Modal
        show={showAssessmentandFeedbackModal}
        onHide={() => setShowAssessmentandFeedbackModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">{`${modelTitle}`}</div>
        </div>
        <Modal.Body>
          <>
            <div className="row">
              <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                <div className="mb-1">
                  <div>
                    <input
                      className="ml-2 mr-1"
                      disabled={false}
                      id={`assessment`}
                      type="radio"
                      name="assessment"
                      onChange={(e) => {
                        // handleResetFunction();
                        formOnChangeForLaunch("assessment");
                      }}
                      checked={launchType === true}
                    />
                    <label htmlFor={`assessment`}>Assessment</label>
                    <input
                      className="ml-2 mr-1"
                      disabled={false}
                      id={`feedback`}
                      name="feedback"
                      type="radio"
                      onChange={(e) => {
                        formOnChangeForLaunch("feedback");
                      }}
                      checked={launchType === false}
                    />
                    <label htmlFor={`feedback`}>Feedback</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              {launchType ? (
                <>
                  <div className={"col-lg-6 col-sm-4 col-xs-4"}>
                    <div className="dropdown mb-1">
                      <label className="col-form-label">Assessment Name</label>{" "}
                      <sup>*</sup>
                      <SelectForm
                        isClearable
                        isSearchable
                        options={formOptions?.assessmentNameId}
                        placeholder={"Assessment Name"}
                        isDisabled={isDisable}
                        value={formData?.assessmentNameId}
                        onChange={(e) => {
                          formOnChange(e, "assessmentNameId");
                        }}
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                      />
                      {formErrors["assessmentNameId"] && (
                        <p style={{ color: "red" }}>
                          {formErrors["assessmentNameId"]}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className={"col-lg-6 col-sm-4 col-xs-4"}>
                    <div className="dropdown mb-1">
                      <label className="col-form-label">Mandatory</label>{" "}
                      <sup>*</sup>
                      <SelectForm
                        isClearable
                        isSearchable
                        options={formOptions?.assessmentMandatory}
                        placeholder={"Mandatory"}
                        isDisabled={isDisable}
                        value={formData?.assessmentMandatory}
                        onChange={(e) => {
                          formOnChange(e, "assessmentMandatory");
                        }}
                        isMulti={false}
                        noIndicator={false}
                        noSeparator={false}
                      />
                      {formErrors["assessmentMandatory"] && (
                        <p style={{ color: "red" }}>
                          {formErrors["assessmentMandatory"]}
                        </p>
                      )}
                    </div>
                  </div>

                  {/* {isCourseMandatory && (
                    <div className={"col-lg-6 col-sm-4 col-xs-4"}>
                      <div className="dropdown mb-1">
                        <label className="col-form-label">
                          Course Schedule
                        </label>{" "}
                        <sup>*</sup>
                        <SelectForm
                          isClearable
                          isSearchable
                          options={formOptions?.assessmentCourse}
                          placeholder={"Course Schedule"}
                          isDisabled={isDisable}
                          value={formData?.assessmentCourse}
                          onChange={(e) => {
                            formOnChange(e, "assessmentCourse");
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                        />
                        {formErrors["assessmentCourse"] && (
                          <p style={{ color: "red" }}>
                            {formErrors["assessmentCourse"]}
                          </p>
                        )}
                      </div>
                    </div>
                  )} */}

                  <div className={"col-lg-6 col-sm-4 col-xs-4"}>
                    <div className="form-group filtInp">
                      <label className="col-form-label ">Course</label>{" "}
                      <sup>*</sup>
                      <SelectForm
                        isClearable
                        options={courseOptions}
                        placeholder={"Select Course"}
                        isDisabled={false}
                        onChange={(event) => {
                          formOnChange(event, "course");
                        }}
                        isMulti={false}
                        noIndicator={false}
                        noSeparator={false}
                        value={selectedCourse}
                      />
                      {formErrors["course"] && (
                        <p style={{ color: "red" }}>{formErrors["course"]}</p>
                      )}
                    </div>
                  </div>

                  <div className={"col-lg-6 col-sm-4 col-xs-4"}>
                    <div className="form-group filtInp">
                      <label className="col-form-label ">Course Schedule</label>{" "}
                      <sup>*</sup>
                      <SelectForm
                        isClearable
                        options={CourseScheduleOptions}
                        placeholder={"Select Course Schedule "}
                        isDisabled={false}
                        onChange={(event) => {
                          formOnChange(event, "courseSchedule");
                        }}
                        isMulti={false}
                        noIndicator={false}
                        noSeparator={false}
                        value={selectedCourseSchedule}
                      />
                      {formErrors["courseSchedule"] && (
                        <p style={{ color: "red" }}>
                          {formErrors["courseSchedule"]}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className={"col-lg-6 col-sm-4 col-xs-4"}>
                    <div className="mb-1">
                      <label className="col-form-label">Assessment Days</label>

                      <InputForm
                        className="form-control"
                        placeholder={"Assessment Days"}
                        isDisabled={isDisable}
                        textArea={false}
                        value={formData?.assessmentDays}
                        onChange={(e) => {
                          formOnChange(e, "assessmentDays");
                        }}
                        maxLength="250"
                      />
                      {formErrors["assessmentDays"] && (
                        <p style={{ color: "red" }}>
                          {formErrors["assessmentDays"]}
                        </p>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className={"col-lg-6 col-sm-4 col-xs-4"}>
                    <div className="dropdown mb-1">
                      <label className="col-form-label">Feedback Name</label>{" "}
                      <sup>*</sup>
                      <SelectForm
                        isClearable
                        isSearchable
                        options={formOptions?.feedbackNameId}
                        placeholder={"Feedback Name"}
                        isDisabled={isDisable}
                        value={formData?.feedbackNameId}
                        onChange={(e) => {
                          formOnChange(e, "feedbackNameId");
                        }}
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                      />
                      {formErrors["feedbackNameId"] && (
                        <p style={{ color: "red" }}>
                          {formErrors["feedbackNameId"]}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className={"col-lg-6 col-sm-4 col-xs-4"}>
                    <div className="dropdown mb-1">
                      <label className="col-form-label">Mandatory</label>{" "}
                      <sup>*</sup>
                      <SelectForm
                        isClearable
                        isSearchable
                        options={formOptions?.feedbackMandatory}
                        placeholder={"Mandatory"}
                        isDisabled={isDisable}
                        value={formData?.feedbackMandatory}
                        onChange={(e) => {
                          formOnChange(e, "feedbackMandatory");
                        }}
                        isMulti={false}
                        noIndicator={false}
                        noSeparator={false}
                      />
                      {formErrors["feedbackMandatory"] && (
                        <p style={{ color: "red" }}>
                          {formErrors["feedbackMandatory"]}
                        </p>
                      )}
                    </div>
                  </div>

                  {/* {isCourseMandatory && (
                    <div className={"col-lg-6 col-sm-4 col-xs-4"}>
                      <div className="dropdown mb-1">
                        <label className="col-form-label">
                          Course Schedule
                        </label>{" "}
                        <sup>*</sup>
                        <SelectForm
                          isClearable
                          isSearchable
                          options={formOptions?.feedbacktCourse}
                          placeholder={"Course Schedule"}
                          isDisabled={isDisable}
                          value={formData?.feedbacktCourse}
                          onChange={(e) => {
                            formOnChange(e, "feedbacktCourse");
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                        />
                        {formErrors["feedbacktCourse"] && (
                          <p style={{ color: "red" }}>
                            {formErrors["feedbacktCourse"]}
                          </p>
                        )}
                      </div>
                    </div>
                  )} */}

                  <div className={"col-lg-6 col-sm-4 col-xs-4"}>
                    <div className="form-group filtInp">
                      <label className="col-form-label ">Course</label>{" "}
                      <sup>*</sup>
                      <SelectForm
                        isClearable
                        options={courseOptions}
                        placeholder={"Select Course"}
                        isDisabled={false}
                        onChange={(event) => {
                          formOnChange(event, "course");
                        }}
                        isMulti={false}
                        noIndicator={false}
                        noSeparator={false}
                        value={selectedCourse}
                      />
                      {formErrors["course"] && (
                        <p style={{ color: "red" }}>{formErrors["course"]}</p>
                      )}
                    </div>
                  </div>

                  <div className={"col-lg-6 col-sm-4 col-xs-4"}>
                    <div className="form-group filtInp">
                      <label className="col-form-label ">Course Schedule</label>{" "}
                      <sup>*</sup>
                      <SelectForm
                        isClearable
                        options={CourseScheduleOptions}
                        placeholder={"Select Course Schedule "}
                        isDisabled={false}
                        onChange={(event) => {
                          formOnChange(event, "courseSchedule");
                        }}
                        isMulti={false}
                        noIndicator={false}
                        noSeparator={false}
                        value={selectedCourseSchedule}
                      />
                      {formErrors["courseSchedule"] && (
                        <p style={{ color: "red" }}>
                          {formErrors["courseSchedule"]}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className={"col-lg-6 col-sm-4 col-xs-4"}>
                    <div className="mb-1">
                      <label className="col-form-label">Feedback Days</label>

                      <InputForm
                        className="form-control"
                        placeholder={"Feedback Days"}
                        isDisabled={isDisable}
                        textArea={false}
                        value={formData?.feedbackDays}
                        onChange={(e) => {
                          formOnChange(e, "feedbackDays");
                        }}
                        maxLength="250"
                      />
                      {formErrors["feedbackDays"] && (
                        <p style={{ color: "red" }}>
                          {formErrors["feedbackDays"]}
                        </p>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>

            <br />
            {launchType ? (
              <>
                <div className="SectionSubmit mb-4 clearfix">
                  {!isDisable && (
                    <>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          handleAssessmentSubmitFunction();
                        }}
                        disabled={false}
                        className="btn btn-filter-submit float-right ml-2">
                        <i className="fa-solid fa-check"></i> Save
                      </button>

                      <button
                        onClick={() => {
                          handleResetFunction();
                        }}
                        disabled={false}
                        className="btn btn-secondary float-right ml-2">
                        <i className="fa-solid fa-refresh"></i> Reset
                      </button>
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="SectionSubmit mb-4 clearfix">
                  {!isDisable && (
                    <>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          handleFeedbackSubmitFunction();
                        }}
                        disabled={false}
                        className="btn btn-filter-submit float-right ml-2">
                        <i className="fa-solid fa-check"></i> Save
                      </button>

                      <button
                        onClick={() => {
                          handleResetFunction();
                        }}
                        disabled={false}
                        className="btn btn-secondary float-right ml-2">
                        <i className="fa-solid fa-refresh"></i> Reset
                      </button>
                    </>
                  )}
                </div>
              </>
            )}
          </>
        </Modal.Body>
        <Modal.Footer>
          {/* <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            // onClick={() => activateandDeactivateFunction(Id, IsActive)}
          >
            Submit
          </button> */}
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowAssessmentandFeedbackModal(false);
            }}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AssessmantAndFeedback;
