import React, { useEffect, useState } from "react";
import "../../Pages/Progression/ChangeLog.css";
import { getAuditLogsV2ForEC } from "../../Helpers/APIEndPoints/EndPoints";
import { APICall } from "../../Helpers/APICalls";
import moment from "moment";
import { Table } from "react-bootstrap";
function PreviousConversation({ PrevConv }) {
    const [prevConversationArray, setPrevConvsationArray]= useState<any>([]);

    const parseJSON = (jsonString) => {
        try {
          return JSON.parse(jsonString);
        } catch (error) {
          return null;
        }
      };

    useEffect(() => { 
      if(PrevConv!=null && PrevConv!= undefined){
        let remarksArray = parseJSON(PrevConv);
        if (remarksArray) {
          remarksArray.map((item) => {
            item.ModifiedDateUtc = item.ModifiedDateUtc
              ? moment.utc(item.ModifiedDateUtc).local().format("DD-MMM-YYYY hh:mm A")
              : "";
            item.UserName = `${item.EmployeeCode} - ${item.UserName}` || "";
          });
          setPrevConvsationArray(remarksArray);
        }
      }
    },[PrevConv]);

  return (
    <div>
        <Table striped bordered hover>
          {prevConversationArray?.map((eachApp, index) => (
            <>
              {index == 0 && (
                <thead>
                  <tr>
                    <th className="text-dark col-lg-2 col-md-2 col-sm-2 mobile-view top-m">Date-Time</th>
                    <th className="text-dark col-lg-2 col-md-2 col-sm-2 mobile-view top-m">User Name</th>
                    <th className="text-dark col-lg-2 col-md-2 col-sm-2 mobile-view top-m">Role</th>
                    <th className="text-dark col-lg-6 col-md-6 col-sm-6 mobile-view top-m">Remarks</th>
                  </tr>
                </thead>
              )}
              <tbody>
                <tr>
                  <td>{eachApp?.ModifiedDateUtc}</td>
                  <td>{eachApp?.UserName}</td>
                  <td>{eachApp?.RoleName}</td>
                  <td>{eachApp?.Remarks}</td>
                </tr>
              </tbody>
            </>
          ))}
        </Table>
    </div>
  );
}

export default PreviousConversation;
