import React, { useContext, useEffect, useState, createContext } from "react";
import { Avatar, Breadcrumbs, Tabs, Tooltip } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DoneIcon from "@mui/icons-material/Done";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import { APICall } from "../../Helpers/APICalls";
import { LoaderContext } from "../../Helpers/Context/Context";
import {
  getAttributeValueDetailsV2,
  getSectionDetailsV2,
  getTabsData,
  getTabHeaderDetails,
  getPositionDetailsForMRF,
  getCurrentWFStage,
  GetRolesURL,
  GetPositionVacantStatusByMRFId,
  UpdateMRFStatus,
  CheckStatusWhenMRFTryToReopen,
} from "../../Helpers/APIEndPoints/EndPoints";
import { useLocation, useNavigate } from "react-router-dom";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { tabsTheme } from "../MRF/MRFHelper";
import SectionComponent from "../MRF/Components/MRFSectionComponent";
import RCSectionComponent from "./RCSectionComponent";
import CandidateDashboard from "./CandidateDashboard";
import InternalJobPostion from "../AnonymousCandidate/InternalJobPostion";
import AddCandidate from "./AddCandidate";
import ScreeningQuestion from "./Component/ScreeningQuestion";
import Stages from "../MRF/Components/Stages";
import SelectForm from "../../Components/SelectForm/SelectForm";
import notify from "../../Helpers/ToastNotification";
import { isNull } from "util";
import CandidateForm from "../AnonymousCandidate/Components/CandidateForm";

const RecruiterCandidate = () => {
  const { state } = useLocation();
  const [formData, setFormData] = useState<any>({});
  const [tabs, setTabs] = useState([]);
  const [activetab, setActivetab] = useState<string>(
    state !== null && state.Tab && null
  );
  const [activetabIndex, setActivetabIndex] = useState<number>(0);
  const { showLoader, hideLoader } = useContext(LoaderContext);

  const [TId, setTId] = useState((state !== null && state.TId) || 0);
  const [COU, setCOU] = useState((state !== null && state.COU) || "");
  const [headerValues, setHeaderValues] = useState<any>(null);
  const [sectionValuesArray, setSectionValuesArray] = useState<any>([]);
  let { userDetails, currentRoleId }: any = useUserContext();

  const navigate = useNavigate();

  const [accordion, setAccordion] = useState(true);
  const [statusGridData, setStatusGridData] = useState({
    count: 0,
    start: 0,
    pageSize: 10,
    searchText: "",
    sortColumn: "",
    sortDirection: "",
    data: [],
  });
  const [attributeDataForPos, setAttributeDataForPos] = useState<any>({});
  const [hideSectionAndAttribute, setHideSectionAndAttribute] = useState(true);
  const [positionIdDetails, setPositionIdDetails] = useState(null);

  const [positionCountryIdDetails, setPositionCountryDetails] = useState(null);
  const [disableAttribute, setDisableAttribute] = useState(false);
  const [sendback, setSendback] = useState(false);
  const [jobId, setJobId] = useState(0);

  const [showStagesAndResponsbility, setShowStagesAndResponsbility] =
    useState(false);

  const [hideShowAddCandidateTab, setHideShowAddCandidateTab] = useState(false);
  const [showMRFStatus, setShowMRFStatus] = useState(false);
  const [mrfStatuDisable, setMRFStatusDisable] = useState(false);
  const [mrfStatusValue, setMRFStatusValue] = useState(null);
  const [mrfStatusOptions, setMRFStatusOptions] = useState([]);
  const [reset, setReset] = useState(false);
  const [roles, setRoles] = useState([]);

  enum RolesSystemName {
    SystemAdmin = "systemadmin",
    Recruiter = "recruiter",
    TALead = "talead",
  }

  useEffect(() => {
    const helperFunc = async () => {
      if (TId > 0) {
        const showStatus = await getRolesAndSetStatus();
        const isVacant = await handleGetPositionisVacantOrNot();
        setMRFStatusDisable(!isVacant);
        setShowMRFStatus(showStatus === true);
        const { options, value } = getOptionsBasedOnCurrentStatus(
          headerValues?.mrfStatus
        );
        setMRFStatusValue(value);
        setMRFStatusOptions(options);
      }
    };

    helperFunc();
  }, [TId, headerValues?.mrfStatus]);

  const getRolesAndSetStatus = async () => {
    try {
      showLoader();
      let arr = [];
      const response = await APICall(GetRolesURL, "POST", {});

      if (response?.status == 0 && response?.data?.length > 0) {
        const data = response?.data;

        const showStatusArr = data?.filter((role) => {
          return (
            String(role?.name).trim().toLocaleLowerCase() ===
              RolesSystemName.SystemAdmin ||
            String(role?.name).trim().toLocaleLowerCase() ===
              RolesSystemName.Recruiter ||
            String(role?.name).trim().toLocaleLowerCase() ===
              RolesSystemName.TALead
          );
        });

        const showStatus = showStatusArr?.some(
          (role) => role?.id == currentRoleId?.value
        );

        setRoles(data);
        return showStatus;
      } else {
        setRoles([]);
        return false;
      }

      return arr;
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const handleGetPositionisVacantOrNot = async () => {
    let isVacant = false;
    try {
      showLoader();
      const { status, data, message } = await APICall(
        GetPositionVacantStatusByMRFId,
        "POST",
        {
          Id: TId,
        }
      );

      if (status == 0) {
        isVacant = data?.code === "CODEVACNSTS1";
      } else {
        console.error(message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
    return isVacant;
  };

  const getOptionsBasedOnCurrentStatus = (headerValue = "") => {
    try {
      headerValue = String(headerValue).toLocaleLowerCase().trim();

      let options = [
        { label: "Hold", value: "Hold", disabled: false },
        { label: "Closed", value: "Closed", disabled: false },
        { label: "Re-open", value: "Re-open", disabled: true },
        { label: "Open", value: "Open", disabled: true },
        {
          label: "Pending Approval",
          value: "Pending Approval",
          disabled: true,
        },
        { label: "Draft", value: "Draft", disabled: true },
      ];

      let value = null;

      switch (headerValue) {
        case "open":
          options = options.map((option) => {
            if (option.value === "Hold" || option.value === "Closed") {
              option["disabled"] = false;
            } else {
              option["disabled"] = true;
            }
            return option;
          });

          value = options.find((option) => option.value === "Open");
          break;

        case "closed":
        case "terminated":
          options = options.map((option) => {
            if (
              option.value === "Re-open" ||
              option.value === "Hold" ||
              option.value === "Closed"
            ) {
              option["disabled"] = false;
            } else {
              option["disabled"] = true;
            }
            return option;
          });

          value = options.find((option) => option.value === "Closed");
          break;

        case "pending approval":
          options = options.map((option) => {
            if (
              option.value === "Closed" ||
              option.value === "Hold" ||
              option.value === "Pending Approval"
            ) {
              option["disabled"] = false;
            } else {
              option["disabled"] = true;
            }
            return option;
          });

          value = options.find((option) => option.value === "Pending Approval");
          break;

        case "hold":
          options = options.map((option) => {
            if (
              option.value === "Closed" ||
              option.value === "Hold" ||
              option.value === "Re-open"
            ) {
              option["disabled"] = false;
            } else {
              option["disabled"] = true;
            }
            return option;
          });

          value = options.find((option) => option.value === "Hold");
          break;

        case "draft":
          options = options.map((option) => {
            if (
              option.value === "Closed" ||
              option.value === "Hold" ||
              option.value === "Draft"
            ) {
              option["disabled"] = false;
            } else {
              option["disabled"] = true;
            }
            return option;
          });

          value = options.find((option) => option.value === "Draft");
          break;

        default:
          break;
      }

      return { options, value };
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (state?.type == "edit" && state?.sendback == true) {
      setSendback(true);
      setDisableAttribute(true);
    } else {
      setSendback(false);
    }
    return () => {};
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (TId !== 0) {
        setHideSectionAndAttribute(false);
      } else {
        setHideSectionAndAttribute(true);
      }

      await GetTabDataAsync();
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (activetab !== null) {
      const fetchData = async () => {
        if (TId !== 0 && headerValues === null) {
          setSectionValuesArray([]);
          setFormData({});
          await GetTabHeaderDetailsAsync();
        }

        await GetAttributeValueDetailsAsync();
        await GetSectionAsync();
      };

      fetchData();
    }
  }, [activetab]);

  const [currentWFStage, setCurrentWFStage] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      if (TId !== 0) {
        await GetTabHeaderDetailsAsync();
      }

      const currentWFStage = await APICall(getCurrentWFStage, "POST", {
        MRFId: TId,
      });

      if (currentWFStage.data !== null) {
        // mrfId is actualt current stage returned from sp
        setCurrentWFStage(currentWFStage?.data?.MRFId || null);
      }
    };
    fetchData();
  }, [TId, reset]);

  useEffect(() => {
    const fetchData = async () => {
      if (
        positionIdDetails &&
        Object.keys(positionIdDetails).length > 0 &&
        positionCountryIdDetails &&
        Object.keys(positionCountryIdDetails).length > 0
      ) {
        if (activetab != null && activetab == "Requisition") {
          await GetSectionAsync();

          if (TId != 0) {
            await GetAttributeValueDetailsAsync();
          }

          if (positionIdDetails && positionIdDetails?.value) {
            await getPositionDetails();
          }
        }
        setHideSectionAndAttribute(false);
      } else {
        setHideSectionAndAttribute(true);
      }
    };

    fetchData();
  }, [positionIdDetails, positionCountryIdDetails]);

  const statusGridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: statusGridData.count,
    rowsPerPage: statusGridData.pageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    //standard | vertical | simple
    onChangeRowsPerPage: (num) => {},
    onSearchChange: (searchText) => {
      if (searchText !== null) {
        setStatusGridData((prev) => {
          return { ...prev, searchText: searchText };
        });
      } else {
        setStatusGridData((prev) => {
          return { ...prev, searchText: "" };
        });
      }
    },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setStatusGridData((prev) => {
          return {
            ...prev,
            sortColumn: sortColumn,
            sortDirection: sortDirection,
          };
        });
      }
      if (sortDirection === "desc") {
        await setStatusGridData((prev) => {
          return {
            ...prev,
            sortColumn: sortColumn,
            sortDirection: sortDirection,
          };
        });
      }
    },
    onChangePage: async (page) => {
      setStatusGridData((prev) => {
        return { ...prev, start: page * prev.pageSize };
      });
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const GetTabDataAsync = async () => {
    try {
      showLoader();
      const tabs = await APICall(getTabsData, "POST", {
        MN: "ManpowerRequisition",
        IN: "ManpowerRequisition_Form",
      });

      if (tabs.data !== null && tabs.data.length > 0) {
        // console.log(state?.Tab);
        // setActivetab(state?.Tab || tabs.data[0].TN);
        // setTabs(tabs.data);

        // if (currentRoleId?.value != 9 && state?.Tab != "ManpowerCandidates") {
        //   let tempTabs = tabs?.data?.filter(
        //     (i) => i?.TN != "ManpowerCandidates"
        //   );
        //   setTabs(tempTabs);
        // } else {
        //   setTabs(tabs.data);
        // }

        tabs.data = tabs.data?.filter(
          (item) =>
            item.TN === "Requisition" ||
            item.TN === "JobDescription" ||
            item.TN === "ManpowerCandidates"
        );
        setTabs(tabs.data);
        setActivetab(tabs.data[0].TN);
      } else {
        setTabs([]);
      }
    } catch (error) {
      console.error("Get Tab Data Error: ", error);
    } finally {
      hideLoader();
    }
  };

  const GetTabHeaderDetailsAsync = async () => {
    try {
      showLoader();
      const tabHeader = await APICall(getTabHeaderDetails, "POST", {
        MN: "ManpowerRequisition",
        IN: "",
        Id: TId,
      });
      setHeaderValues(tabHeader.data || null);
    } catch (error) {
      console.error("get headers details: ", error);
    } finally {
      hideLoader();
    }
  };

  const GetSectionAsync = async () => {
    try {
      showLoader();
      const section = await APICall(getSectionDetailsV2, "POST", {
        MN: "ManpowerRequisition",
        IN: "ManpowerRequisition_Form",
        TN: activetab,
        UID: userDetails.Id,
        TID: null,
        RoleId: currentRoleId?.value,
        IsMRF: true,
      });

      if (
        section.data !== null &&
        section.data.t !== undefined &&
        section.data.t.tn === activetab
      ) {
        setFormData(section.data.t);
      } else {
        setFormData({});
      }
    } catch (error) {
      console.error("get sections details: ", error);
    } finally {
      hideLoader();
    }
  };

  const GetAttributeValueDetailsAsync = async () => {
    try {
      let bodyObj = {
        mn: "ManpowerRequisition",
        in: "ManpowerRequisition_Form",
        tn: activetab,
        SN: "RequisitionDetails",
        TID: TId,
        ISH: "true",
        COU: COU,
      };

      showLoader();
      const response = await APICall(
        getAttributeValueDetailsV2,
        "POST",
        bodyObj
      );

      if (
        response.data &&
        response.data.sections &&
        response.data.sections.length > 0
      ) {
        setSectionValuesArray(response.data.sections);
      } else {
        setSectionValuesArray([]);
      }
    } catch (error) {
      console.error("Attribute value details async: ", error);
    } finally {
      hideLoader();
    }
  };

  const getPositionDetails = async () => {
    try {
      if (positionIdDetails?.value) {
        let bodyObj = {
          val: TId,
        };
        showLoader();
        const response = await APICall(
          getPositionDetailsForMRF,
          "POST",
          bodyObj
        );
        if (response?.status == 0 && response?.data?.length > 0) {
          let responseData = response?.data;
          let JobId = responseData?.find((k) => k.key == "MRJOBID")?.value || 0;
          JobId = Number(JobId);
          setJobId(JobId);

          setAttributeDataForPos(response.data);
        }
      }
    } catch (error) {
      console.error("get postion data: ", error);
    } finally {
      hideLoader();
    }
  };

  const mrfStatus = [
    <div className="mx-2 my-3 d-flex flex-column">
      <p>Requisition</p>
      <p className="mx-auto">
        <Avatar sx={{ bgcolor: "white", width: 24, height: 24 }}>
          {currentWFStage === 1 ? (
            <DoneIcon fontSize="small" color="success" />
          ) : (
            <DoneIcon fontSize="small" color="success" />
          )}
        </Avatar>
      </p>
    </div>,
    <div className="mx-2 my-3 d-flex flex-column">
      <p>Approval</p>
      <p className="mx-auto">
        <Avatar sx={{ bgcolor: "white", width: 24, height: 24 }}>
          {currentWFStage > 1 ? (
            <DoneIcon fontSize="small" color="success" />
          ) : (
            <HourglassBottomIcon fontSize="small" color="warning" />
          )}
        </Avatar>
      </p>
    </div>,
    <div className="mx-2 my-3 d-flex flex-column">
      <p>Posting</p>
      <p className="mx-auto">
        <Avatar
          sx={{
            bgcolor: `${
              currentWFStage === 3 || currentWFStage > 3 ? "white" : "secondary"
            }`,
            width: 24,
            height: 24,
          }}
        >
          {currentWFStage === 3 ? (
            <HourglassBottomIcon fontSize="small" color="warning" />
          ) : currentWFStage > 3 ? (
            <DoneIcon fontSize="small" color="success" />
          ) : (
            " "
          )}
        </Avatar>
      </p>
    </div>,
    <div className="mx-2 my-3 d-flex flex-column">
      <p>Sourcing</p>
      <p className="mx-auto">
        <Avatar
          sx={{
            bgcolor: `${
              currentWFStage === 4 || currentWFStage > 4 ? "white" : "secondary"
            }`,
            width: 24,
            height: 24,
          }}
        >
          {currentWFStage === 4 ? (
            <HourglassBottomIcon fontSize="small" color="warning" />
          ) : currentWFStage > 4 ? (
            <DoneIcon fontSize="small" color="success" />
          ) : (
            " "
          )}
        </Avatar>
      </p>
    </div>,
    <div className="mx-2 my-3 d-flex flex-column">
      <p>Evaluation</p>
      <p className="mx-auto">
        <Avatar
          sx={{
            bgcolor: `${
              currentWFStage === 5 || currentWFStage > 5 ? "white" : "secondary"
            }`,
            width: 24,
            height: 24,
          }}
        >
          {currentWFStage === 5 ? (
            <HourglassBottomIcon fontSize="small" color="warning" />
          ) : currentWFStage > 5 ? (
            <DoneIcon fontSize="small" color="success" />
          ) : (
            " "
          )}
        </Avatar>
      </p>
    </div>,
    <div className="mx-2 my-3 d-flex flex-column">
      <p>Offer</p>
      <p className="mx-auto">
        <Avatar
          sx={{
            bgcolor: `${
              currentWFStage === 6 || currentWFStage > 6 ? "white" : "secondary"
            }`,
            width: 24,
            height: 24,
          }}
        >
          {currentWFStage === 6 ? (
            <HourglassBottomIcon fontSize="small" color="warning" />
          ) : currentWFStage > 6 ? (
            <DoneIcon fontSize="small" color="success" />
          ) : (
            " "
          )}
        </Avatar>
      </p>
    </div>,
    <div className="mx-2 my-3 d-flex flex-column">
      <p>Joining</p>
      <p className="mx-auto">
        <Avatar
          sx={{
            bgcolor: `${
              currentWFStage === 7 || currentWFStage > 7 ? "white" : "secondary"
            }`,
            width: 24,
            height: 24,
          }}
        >
          {currentWFStage === 7 ? (
            <HourglassBottomIcon fontSize="small" color="warning" />
          ) : currentWFStage > 7 ? (
            <DoneIcon fontSize="small" color="success" />
          ) : (
            " "
          )}
        </Avatar>
      </p>
    </div>,
  ];

  const addCandidateFunction = () => {
    setHideShowAddCandidateTab(true);
    setActivetab("AddCandidate");
  };

  function handleChangeForMRFStatus(e) {
    try {
      setMRFStatusValue(e);
    } catch (error) {}
  }

  async function handleSubmitForMRFStatus(event) {
    try {
      if (mrfStatusValue?.value == "Re-open") {
        showLoader();
        const response = await APICall(CheckStatusWhenMRFTryToReopen, "POST", {
          Id: TId,
        });

        if (response?.status == 0) {
          if (response?.data == true) {
            notify(
              1,
              "You cannot re-open this MRF, another MRF is already in progress or in hold  for this position."
            );
            hideLoader();
            return;
          }
        }
      }

      showLoader();
      setMRFStatusDisable(true);
      const response = await APICall(UpdateMRFStatus, "POST", {
        MRFId: TId,
        Status: mrfStatusValue?.value,
      });

      if (response?.status == 0) {
        notify(0, "Status updated sucessfully.");
        setReset((prev) => !prev);
      } else {
        notify(1, "Something went wrong.");
        console.error(response?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
      setMRFStatusDisable(false);
    }
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row prof_bg mb-3 border-bottom">
          <div className="container-fluid">
            {headerValues && (
              <div className="row my-2">
                <div className="col-lg-3">
                  <h4>Manpower Requisition</h4>
                  <p>
                    {headerValues && `MRF #: ${headerValues?.mrF_NO}`}
                    {headerValues && headerValues?.mrfCreatedDate !== null && (
                      <Tooltip title="MRF Creation Date">
                        <span>, {headerValues?.mrfCreatedDate}</span>
                      </Tooltip>
                    )}
                  </p>
                  <p>
                    <Tooltip title="Job Title">
                      <span>
                        {headerValues && headerValues?.jobtitle !== null && (
                          <>{headerValues?.jobtitle}</>
                        )}
                      </span>
                    </Tooltip>
                  </p>
                  <p>
                    <Tooltip title="Legal Entity">
                      <span>
                        {headerValues && headerValues?.legalEntity !== null && (
                          <>{headerValues?.legalEntity}</>
                        )}
                      </span>
                    </Tooltip>
                  </p>

                  <p>
                    <Tooltip title="Designation">
                      <span>
                        {headerValues && headerValues?.designation !== null && (
                          <>{headerValues?.designation}</>
                        )}
                      </span>
                    </Tooltip>
                  </p>

                  <p>
                    <Tooltip title="Department">
                      <span>
                        {headerValues && headerValues?.department !== null && (
                          <>{headerValues?.department}</>
                        )}
                      </span>
                    </Tooltip>
                  </p>

                  <p>
                    <Tooltip title="Physical Location">
                      <span>
                        {headerValues &&
                          headerValues?.locationName !== null && (
                            <>{headerValues?.locationName}</>
                          )}
                      </span>
                    </Tooltip>{" "}
                    |{" "}
                    <Tooltip title="Function">
                      <span>
                        {headerValues &&
                          headerValues?.functionName !== null && (
                            <>{headerValues?.functionName}</>
                          )}
                      </span>
                    </Tooltip>
                  </p>
                  <p>
                    <Tooltip title="MRF Status">
                      <span>
                        {headerValues && headerValues?.mrfStatus !== null && (
                          <> {headerValues?.mrfStatus}</>
                        )}
                      </span>
                    </Tooltip>
                    <Tooltip title="TAT Time Lapse">
                      <span>
                        {headerValues &&
                          headerValues?.tat_time !== null &&
                          headerValues?.tat_time !== 0 && (
                            <>
                              {headerValues?.mrfStatus && " | "}
                              {headerValues?.tat_time}{" "}
                              {headerValues?.tat_time > 1 ? "days" : "day"}
                            </>
                          )}
                      </span>
                    </Tooltip>
                  </p>
                </div>
                <div className="col-lg-9">
                  <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                  >
                    {mrfStatus}
                  </Breadcrumbs>
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-lg-2 col-md-12 col-sm-12 "></div>
              <div className="col-lg-10 col-md-12 col-sm-12 ">
                <ThemeProvider theme={tabsTheme}>
                  <Tabs
                    value={activetabIndex}
                    className="profile-tabs"
                    onChange={() => {}}
                    variant="scrollable"
                    TabScrollButtonProps={{
                      style: {
                        color: "white",
                      },
                    }}
                    scrollButtons
                    TabIndicatorProps={{
                      style: { display: "none" },
                    }}
                    allowScrollButtonsMobile
                    aria-label="scrollable tabs"
                  >
                    <div className="tabBtn">
                      <ul>
                        {tabs.length > 0 &&
                          tabs.map((eachTab, index) =>
                            eachTab.TN != "" ? (
                              <li key={`${index}-${eachTab.TN}`}>
                                <button
                                  onClick={() => {
                                    if (
                                      eachTab.TN === "Requisition" ||
                                      eachTab.TN === "JobDescription" ||
                                      eachTab.TN === "ManpowerCandidates"
                                    ) {
                                      let tempTabs = tabs?.filter(
                                        (tab) =>
                                          tab.TN != "NewTab" &&
                                          tab.TN != "AddCandidate"
                                      );
                                      setTabs(tempTabs);

                                      if (hideShowAddCandidateTab) {
                                        setHideShowAddCandidateTab(false);
                                      }
                                    }
                                    setActivetab(eachTab.TN);
                                  }}
                                  className={
                                    eachTab.TN === activetab
                                      ? "bttn active"
                                      : "bttn"
                                  }
                                >
                                  {eachTab.TDN}
                                </button>
                              </li>
                            ) : (
                              <li key={`${index}-${eachTab.TN}`}>
                                <button
                                  onClick={() => setActivetab(eachTab.TN)}
                                  hidden={false}
                                  className={
                                    eachTab.TN === activetab
                                      ? "bttn active"
                                      : "bttn"
                                  }
                                >
                                  {eachTab.TDN}
                                </button>
                              </li>
                            )
                          )}
                        {hideShowAddCandidateTab && (
                          <li key={`${5}-${"AddCandidate"}`}>
                            <button
                              onClick={() => setActivetab("AddCandidate")}
                              hidden={false}
                              className={
                                "AddCandidate" === activetab
                                  ? "bttn active"
                                  : "bttn"
                              }
                            >
                              {"Application"}
                            </button>
                          </li>
                        )}
                      </ul>
                    </div>
                  </Tabs>
                </ThemeProvider>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
            <div className="text-right my-1">
              <button
                onClick={() => {
                  navigate(-1);
                }}
                disabled={false}
                className="btn btn-secondary float-right"
              >
                <i className="fa-solid fa-arrow-left"></i> Back
              </button>
            </div>
          </div>
        </div> */}

        <div className="row SectionSubmit mb-4 clearfix">
          <div className="col-lg-6 d-flex">
            {showMRFStatus && activetab === "Requisition" && (
              <>
                <span>MRF Status</span>
                <div className="col-lg-4">
                  <SelectForm
                    value={mrfStatusValue}
                    placeholder={"MRF Status"}
                    options={mrfStatusOptions}
                    onChange={handleChangeForMRFStatus}
                    isDisabled={mrfStatuDisable}
                    isOptionDisabled={(option) => option.disabled}
                  />
                </div>
                <div className="">
                  <button
                    disabled={mrfStatuDisable}
                    className="btn btn-primary"
                    onClick={handleSubmitForMRFStatus}
                  >
                    Save status
                  </button>
                </div>
              </>
            )}
          </div>

          <div className="col-lg-6">
            {!hideShowAddCandidateTab && activetab === "ManpowerCandidates" && (
              <>
                <button
                  onClick={() => addCandidateFunction()}
                  disabled={
                    String(mrfStatusValue?.value).toLocaleLowerCase().trim() ===
                    "open"
                      ? false
                      : true
                  }
                  className="btn btn-filter-submit float-right ml-2"
                >
                  <i className="fa-solid fa-plus"></i> Candidate
                </button>
              </>
            )}
            <button
              onClick={() => {
                if (tabs.length === 3 && activetab !== "AddCandidate") {
                  navigate("/recruiterDashboard");
                  return null;
                }

                if (state?.isRecruiterCandidate) {
                  let tempTabs = tabs?.filter((tab) => tab.TN != "NewTab");
                  if (
                    activetab === "ManpowerCandidates" ||
                    activetab === "JobDescription" ||
                    activetab === "Requisition"
                  ) {
                    navigate(-1);
                  } else {
                    setTabs(tempTabs);
                    setActivetab("ManpowerCandidates");
                  }
                  if (hideShowAddCandidateTab) {
                    setHideShowAddCandidateTab(false);
                  }
                } else if (
                  (state?.isRecruiterCandidate ||
                    state?.isRecruiterCandidate === undefined) &&
                  activetab === "AddCandidate"
                ) {
                  setActivetab("ManpowerCandidates");
                  if (hideShowAddCandidateTab) {
                    setHideShowAddCandidateTab(false);
                  }
                } else {
                  navigate(-1);
                }
              }}
              disabled={false}
              className="btn btn-secondary float-right"
            >
              <i className="fa-solid fa-arrow-left"></i> Back
            </button>
          </div>
        </div>

        {/* section row */}
        <div className="row accordion_grid">
          <>
            {activetab === "AddCandidate" ? (
              // <AddCandidate
              //   setActiveTabFunc={setActivetab}
              //   setHideShowAddCandidateTabFunc={setHideShowAddCandidateTab}
              // />
              <div className="col-lg-12">
                <CandidateForm
                  isIJP={false}
                  isDisableApplication={false}
                  source={"Recruiter"}
                  MRFId={TId}
                  CandidateId={0}
                  setActiveTabFunc={setActivetab}
                  setHideShowAddCandidateTabFunc={setHideShowAddCandidateTab}
                />
              </div>
            ) : (
              <>
                {" "}
                {formData.S !== undefined &&
                  formData?.S?.length > 0 &&
                  formData.S.map((eachSection, index) => {
                    const lastIndex = formData?.S.length - 1;

                    return (
                      <React.Fragment key={`${index}-${eachSection.SN}`}>
                        {eachSection?.SN?.trim().toLocaleLowerCase() ==
                        "screeningquestion" ? (
                          <>
                            <ScreeningQuestion
                              TId={TId}
                              setTId={setTId}
                              formData={formData}
                              setFormData={setFormData}
                              section={eachSection}
                              sectionValuesArray={sectionValuesArray}
                              hideSectionAndAttribute={hideSectionAndAttribute}
                              disableAttribute={disableAttribute}
                            />
                          </>
                        ) : (
                          <>
                            <RCSectionComponent
                              formData={formData}
                              section={eachSection}
                              TId={TId}
                              setTId={setTId}
                              sectionValuesArray={sectionValuesArray}
                              attrDataForPosition={attributeDataForPos}
                              activetab={activetab}
                              index={index}
                              lastIndex={lastIndex}
                              COU={COU}
                              hideSectionAndAttribute={hideSectionAndAttribute}
                              setHideSectionAndAttribute={
                                setHideSectionAndAttribute
                              }
                              positionIdDetails={positionIdDetails}
                              setPositionIdDetails={setPositionIdDetails}
                              positionCountryIdDetails={
                                positionCountryIdDetails
                              }
                              setPositionCountryDetails={
                                setPositionCountryDetails
                              }
                              disableAttribute={disableAttribute}
                              setDisableAttribute={setDisableAttribute}
                            />
                          </>
                        )}
                      </React.Fragment>
                    );
                  })}
              </>
            )}
          </>
        </div>
      </div>
      <>
        {activetab === "ManpowerCandidates" && (
          <CandidateDashboard setTabs={setTabs} setActiveTab={setActivetab} />
        )}
      </>

      <>{activetab === "NewTab" && <Stages />}</>
    </>
  );
};

export default RecruiterCandidate;
