import React, { useContext, useEffect, useState } from "react";
import SelectForm from "../../Components/SelectForm/SelectForm";
import InputForm from "../../Components/InputForm/InputForm";
import { APICall } from "../../Helpers/APICalls";
import {
  GetApprovalLavelDroupDownList,
  GetCommonDropdownForScheduling,
  GetProgramDroupDownList,
  getCourseData,
  getLMSDomainDropDownData,
  saveCourseData,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import RTE from "../../Components/RTE/RTE";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import notify from "../../Helpers/ToastNotification";
import { useLocation, useNavigate } from "react-router-dom";
import { LoaderContext } from "../../Helpers/Context/Context";
import { Modal } from "react-bootstrap";
import DateTimeForm from "../../Components/DateTimeForm/DateTimeForm";

const CourseGeneral = ({ activeTab, setActiveTab }) => {
  const [generalDataObj, setGeneralDataObj] = useState<any>({
    courseCode: "",
    courseTitle: "",
    learningObjective: "",
    journey: null,
    minParticipants: "",
    maxParticipants: "",
    requestBefore: "",
    optoutBefore: "",
    requestApprovalRequired: null,
    optOutApprovalRequired: null,
    optoutAllowed: false,
    requestRequired: true,
    registrationByManager: false,
    scheduleRequired: true,
    mandatoryCourse: false,
    domain: null,
  });

  const { state } = useLocation();

  const [isDisable, setIsDisable] = useState(
    state && state?.isDisable ? true : false
  );

  const { showLoader, hideLoader } = useContext(LoaderContext);

  const { userDetails, currentRoleId, proxyUserDetails }: any =
    useUserContext();

  const navigate = useNavigate();

  const [formErrors, setFormErrors] = useState<any>({});
  const [journeyOptions, setJourneyOptions] = useState([]);
  const [approvalRequiredOptions, setApprovalRequiredOptions] = useState([]);
  const [reset, setReset] = useState(false);
  const objOpprovallavel = {
    NotRequired: 1,
  };

  const [domainOptions, setDomainOptions] = useState([]);

  const [notification, setNotification] = useState(false);
  const [toEmail, setToEmail] = useState("");
  const [effectiveDate, setEffectiveDate] = useState(new Date());
  const [remarks, setRemarks] = useState("");
  const [ccuser, setCCuser] = useState<any>(null);

  useEffect(() => {
    (async () => {
      getProgramDroupDownListApiCall();
    })();

    (async () => {
      const res = await APICall(GetApprovalLavelDroupDownList, "POST", {});
      if (res.data && res.data.length > 0) {
        setApprovalRequiredOptions(res.data);
      }
    })();

    (async () => {
      await getLMSDomainDropDownDataApiCall();
    })();
  }, []);

  const getProgramDroupDownListApiCall = async () => {
    const res = await APICall(GetProgramDroupDownList, "POST", {});
    if (res.data && res.data.length > 0) {
      setJourneyOptions(res.data);
    }
  };

  useEffect(() => {
    if (approvalRequiredOptions.length > 0) {
      const requestApprovalRequired = approvalRequiredOptions.find(
        (e) => e.value === objOpprovallavel.NotRequired
      );

      setGeneralDataObj({
        ...generalDataObj,
        requestApprovalRequired: requestApprovalRequired,
        optOutApprovalRequired: requestApprovalRequired,
      });
    }
  }, [approvalRequiredOptions]);

  const getLMSDomainDropDownDataApiCall = async () => {
    const response = await APICall(getLMSDomainDropDownData, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      GetAll: state && state?.courseId > 0 ? true : false,
    });

    if (response?.status === 0 && response?.data.length > 0) {
      setDomainOptions(response?.data);
    }
  };

  const formOnChange = (e, type) => {
    const requestApprovalRequired = approvalRequiredOptions.find(
      (e) => e.value === objOpprovallavel.NotRequired
    );
    switch (type) {
      case "courseTitle":
        setGeneralDataObj({
          ...generalDataObj,
          courseTitle: e.target.value,
        });
        break;

      case "learningObjective":
        setGeneralDataObj({
          ...generalDataObj,
          learningObjective: e,
        });
        break;

      case "journey":
        setGeneralDataObj({
          ...generalDataObj,
          journey: e,
        });
        break;

      case "minParticipants":
        if (/^[1-9][0-9]{0,5}$/.test(e.target.value) || e.target.value === "") {
          setGeneralDataObj({
            ...generalDataObj,
            minParticipants: e.target.value,
          });
        }

        break;

      case "maxParticipants":
        if (/^[1-9][0-9]{0,5}$/.test(e.target.value) || e.target.value === "") {
          setGeneralDataObj({
            ...generalDataObj,
            maxParticipants: e.target.value,
          });
        }

        break;

      case "requestBefore":
        setGeneralDataObj({
          ...generalDataObj,
          requestBefore: e.target.value,
        });
        break;

      case "optoutBefore":
        setGeneralDataObj({
          ...generalDataObj,
          optoutBefore: e.target.value,
        });
        break;

      case "requestApprovalRequired":
        setGeneralDataObj({
          ...generalDataObj,
          requestApprovalRequired: e,
        });
        break;

      case "optOutApprovalRequired":
        setGeneralDataObj({
          ...generalDataObj,
          optOutApprovalRequired: e,
        });
        break;

      case "optoutAllowed":
        setGeneralDataObj({
          ...generalDataObj,
          optoutAllowed: e.target.id === "optoutAllowed_1" ? true : false,
          optOutApprovalRequired:
            e.target.id === "optoutAllowed_1" ? "" : requestApprovalRequired,
          optoutBefore: "",
        });

        break;

      case "requestRequired":
        setGeneralDataObj({
          ...generalDataObj,
          requestRequired: e.target.id === "requestRequired_1" ? true : false,
          requestApprovalRequired:
            e.target.id === "requestRequired_1" ? "" : requestApprovalRequired,
          requestBefore: "",
          mandatoryCourse: e.target.id === "requestRequired_2" ? true : false,
        });

        break;

      case "registrationByManager":
        setGeneralDataObj({
          ...generalDataObj,
          registrationByManager:
            e.target.id === "registrationByManager_1" ? true : false,
        });
        break;

      case "scheduleRequired":
        setGeneralDataObj({
          ...generalDataObj,
          scheduleRequired: e.target.id === "scheduleRequired_1" ? true : false,
        });
        break;

      case "mandatoryCourse":
        setGeneralDataObj({
          ...generalDataObj,
          mandatoryCourse: e.target.id === "mandatoryCourse_1" ? true : false,
          requestRequired: e.target.id === "mandatoryCourse_1" ? false : true,
          requestApprovalRequired:
            e.target.id === "mandatoryCourse_1" ? requestApprovalRequired : "",
          registrationByManager: "mandatoryCourse_1" ? false : true,
        });
        break;

      case "domain":
        setGeneralDataObj({
          ...generalDataObj,
          domain: e,
        });
        break;

      default:
        break;
    }
  };

  const validateForm = () => {
    let errorObj = {};
    let isError = false;

    if (generalDataObj.courseTitle === "") {
      errorObj = {
        ...errorObj,
        ["courseTitle"]: "Required",
      };
    } else if (generalDataObj.courseTitle.trim().length < 5) {
      errorObj = {
        ...errorObj,
        ["courseTitle"]: "Minimum 5 characters required",
      };
    } else if (generalDataObj.courseTitle.trim().length > 50) {
      errorObj = {
        ...errorObj,
        ["courseTitle"]: "Maximum 50 characters allowed",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["courseTitle"]: "",
      };
    }

    if (generalDataObj.learningObjective === "") {
      errorObj = {
        ...errorObj,
        ["learningObjective"]: "Required",
      };
    } else if (generalDataObj.learningObjective.trim().length > 3000) {
      errorObj = {
        ...errorObj,
        ["learningObjective"]: "Maximum 3000 characters allowed",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["learningObjective"]: "",
      };
    }

    if (generalDataObj.journey === null) {
      errorObj = {
        ...errorObj,
        ["journey"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["journey"]: "",
      };
    }

    if (generalDataObj.domain === null || generalDataObj.domain.length === 0) {
      errorObj = {
        ...errorObj,
        ["domain"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["domain"]: "",
      };
    }

    if (generalDataObj.minParticipants === "") {
      errorObj = {
        ...errorObj,
        ["minParticipants"]: "Required",
      };
    } else if (generalDataObj.minParticipants.length > 6) {
      errorObj = {
        ...errorObj,
        ["minParticipants"]: "Paricipants limit reached",
      };
    } else if (!/^([0-9]+|0)$/.test(generalDataObj.minParticipants)) {
      errorObj = {
        ...errorObj,
        ["minParticipants"]: "Only numbers are allowed",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["minParticipants"]: "",
      };
    }

    if (generalDataObj.maxParticipants === "") {
      errorObj = {
        ...errorObj,
        ["maxParticipants"]: "Required",
      };
    } else if (Number(generalDataObj.maxParticipants) === 0) {
      errorObj = {
        ...errorObj,
        ["maxParticipants"]: "Required",
      };
    } else if (generalDataObj.maxParticipants.length > 6) {
      errorObj = {
        ...errorObj,
        ["maxParticipants"]: "Paricipants limit reached",
      };
    } else if (!/^([0-9]+|0)$/.test(generalDataObj.maxParticipants)) {
      errorObj = {
        ...errorObj,
        ["maxParticipants"]: "Only numbers are allowed",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["maxParticipants"]: "",
      };
    }

    if (
      generalDataObj.minParticipants !== "" &&
      generalDataObj.maxParticipants !== ""
    ) {
      if (
        Number(generalDataObj.minParticipants) &&
        Number(generalDataObj.maxParticipants)
      ) {
        if (
          parseInt(generalDataObj.minParticipants) >
          parseInt(generalDataObj.maxParticipants)
        ) {
          errorObj = {
            ...errorObj,
            ["minParticipants"]:
              "Max Participant should be greater than Min Participant",
          };
        } else {
          errorObj = {
            ...errorObj,
            ["minParticipants"]: "",
          };
        }
      }
    }

    if (generalDataObj?.optoutAllowed) {
      if (generalDataObj.optoutBefore === "") {
        errorObj = {
          ...errorObj,
          ["optoutBefore"]: "Required",
        };
      } else if (!/^([0-9]+|0)$/.test(generalDataObj.optoutBefore)) {
        errorObj = {
          ...errorObj,
          ["optoutBefore"]: "Only numbers are allowed",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["optoutBefore"]: "",
        };
      }

      if (
        generalDataObj.optOutApprovalRequired === "" ||
        generalDataObj.optOutApprovalRequired === null
      ) {
        errorObj = {
          ...errorObj,
          ["optOutApprovalRequired"]: "Required",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["optOutApprovalRequired"]: "",
        };
      }
    } else {
      errorObj = {
        ...errorObj,
        ["optoutBefore"]: "",
      };

      if (generalDataObj.optOutApprovalRequired === "") {
        errorObj = {
          ...errorObj,
          ["optOutApprovalRequired"]: "Required",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["optOutApprovalRequired"]: "",
        };
      }
    }

    if (generalDataObj?.requestRequired) {
      if (generalDataObj.requestBefore === "") {
        errorObj = {
          ...errorObj,
          ["requestBefore"]: "Required",
        };
      } else if (!/^([0-9]+|0)$/.test(generalDataObj.requestBefore)) {
        errorObj = {
          ...errorObj,
          ["requestBefore"]: "Only numbers are allowed",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["requestBefore"]: "",
        };
      }

      if (
        generalDataObj.requestApprovalRequired === "" ||
        generalDataObj.requestApprovalRequired === null
      ) {
        errorObj = {
          ...errorObj,
          ["requestApprovalRequired"]: "Required",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["requestApprovalRequired"]: "",
        };
      }
    } else {
      errorObj = {
        ...errorObj,
        ["requestBefore"]: "",
      };

      if (generalDataObj.requestApprovalRequired === null) {
        errorObj = {
          ...errorObj,
          ["requestApprovalRequired"]: "Required",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["requestApprovalRequired"]: "",
        };
      }
    }

    const isEmpty = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  useEffect(() => {
    if (state && state?.courseId > 0) {
      getLMCourceMasterDataByIdApiCall();
    }
  }, [journeyOptions, domainOptions, approvalRequiredOptions, reset]);

  const getLMCourceMasterDataByIdApiCall = async () => {
    showLoader();
    resetFunction();

    const responseData = await APICall(getCourseData, "POST", {
      Id: state?.courseId,
      LaunchId: state?.launchHubId,
    });

    if (responseData?.status === 0) {
      setToEmail(responseData?.data?.toUser);

      const requestApprovalRequired = approvalRequiredOptions?.find(
        (s) => s.value === parseInt(responseData?.data?.requestApprovalRequired)
      );

      const optoutApprovalRequired = approvalRequiredOptions?.find(
        (s) => s.value === parseInt(responseData?.data?.optoutApprovalRequired)
      );

      const journyIds = responseData?.data?.programIds?.split(",") || null;

      const journyArray = [];

      if (journyIds && journeyOptions?.length > 0) {
        journyIds?.map((item) => {
          const journyData = journeyOptions.find(
            (i) => i.value === parseInt(item)
          );
          journyArray.push(journyData);
        });
      }

      const domainIds = responseData?.data?.domainIds?.split(",") || null;

      const domainArray = [];

      if (domainIds && domainOptions?.length > 0) {
        domainIds?.map((item) => {
          const domainData = domainOptions.find(
            (i) => i.value === parseInt(item)
          );
          domainArray.push(domainData);
        });
      }

      const LMSCourceMasterData = {
        courseCode: responseData?.data?.courseCode,
        courseTitle: responseData?.data?.courseTitle,
        learningObjective: responseData?.data?.learningObjective,
        journey: journyArray.length > 0 ? journyArray : null,
        minParticipants: responseData?.data?.minimumParticipants,
        maxParticipants: responseData?.data?.maximumParticipants,
        requestBefore: responseData?.data?.requestBeforeDays,
        optoutBefore: responseData?.data?.optoutBeforeDays,
        requestApprovalRequired: requestApprovalRequired,
        optOutApprovalRequired: optoutApprovalRequired,
        optoutAllowed: responseData?.data?.optoutAllowed,
        requestRequired: responseData?.data?.requestRequired,
        registrationByManager: responseData?.data?.registerationByManager,
        scheduleRequired: responseData?.data?.scheduleRequired,
        mandatoryCourse: responseData?.data?.mandetoryCourse,
        domain: domainArray.length > 0 ? domainArray : null,
      };

      setGeneralDataObj(LMSCourceMasterData);
    } else {
    }

    hideLoader();
  };

  async function fetchDropdown(searchText = "", code = "", value = null) {
    try {
      const response = await APICall(
        GetCommonDropdownForScheduling,
        "POST",
        {
          SearchText: searchText,
          DropdownValues: value,
          Code: code,
          UserId: userDetails?.Id,
          RoleId: currentRoleId?.value,
        },
        true
      );

      if (response?.status === 0) {
        if (response?.data) {
          return response?.data;
        } else {
          return [];
        }
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  async function handleSearchForAsyncDropdown(event, code, callback) {
    try {
      if (event?.length >= 3) {
        const data = await fetchDropdown(event, code);
        callback(data);
      } else {
        callback([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const requestParamsFun = () => {
    const requestParams = {
      Id:
        state === null ||
        state?.courseId === null ||
        state?.courseId === undefined
          ? 0
          : state?.courseId,
      courseCode: generalDataObj?.courseCode,
      courseTitle: generalDataObj?.courseTitle,
      learningObjective: generalDataObj?.learningObjective,
      programIds: generalDataObj?.journey
        ? generalDataObj?.journey?.map((j) => j.value).join(",")
        : null,
      minimumParticipants: generalDataObj?.minParticipants,
      maximumParticipants: generalDataObj?.maxParticipants,
      requestBeforeDays: generalDataObj?.requestBefore,
      optoutBeforeDays: generalDataObj?.optoutBefore,
      requestApprovalRequired: generalDataObj?.requestApprovalRequired?.value,
      optOutApprovalRequired: generalDataObj?.optOutApprovalRequired?.value,
      optoutAllowed: generalDataObj?.optoutAllowed,
      requestRequired: generalDataObj?.requestRequired,
      registerationByManager: generalDataObj?.registrationByManager,
      launched: 0,
      mandetoryCourse: generalDataObj?.mandatoryCourse,
      isActive: true,
      createdBy: userDetails?.Id,
      ScheduleRequired: generalDataObj?.scheduleRequired,
      domainIds: generalDataObj?.domain
        ? generalDataObj?.domain?.map((j) => j.value).join(",")
        : null,
      ToUser: toEmail || null,
      CCUserIds: ccuser?.map((event) => event.value).join(",") || null,
      EffectiveDate: effectiveDate || null,
      Remarks: remarks || null,
    };

    return requestParams;
  };

  const handleSubmitButton = async () => {
    showLoader();

    if (validateForm()) {
      hideLoader();
      return;
    }

    const requestParams = requestParamsFun();

    if (requestParams?.Id === 0) {
      insertUpdateData();
    } else {
      setNotification(true);
    }

    hideLoader();
  };

  const InsertUpdateDataOnNotification = async () => {
    if (validateForm()) {
      hideLoader();
      setNotification(false);
      return; // Stop the submission if there are validation errors
    }

    insertUpdateData();
  };

  const insertUpdateData = async () => {
    showLoader();

    const requestParams = requestParamsFun();

    const response = await APICall(saveCourseData, "POST", requestParams);

    if (response?.status === 0) {
      const newcourseId = response?.data?.id;
      notify(response?.status, response?.message);
      navigate("/Courses", { state: { courseId: newcourseId } });
      setActiveTab("Session");
      setReset(!reset);
    } else if (response?.status === 1) {
      notify(response?.status, response?.message);
    }

    hideLoader();
  };

  const resetFunction = () => {
    setFormErrors({});

    const requestApprovalRequired = approvalRequiredOptions.find(
      (e) => e.value === objOpprovallavel.NotRequired
    );

    const objManageCource = {
      courseCode: "",
      courseTitle: "",
      learningObjective: "",
      journey: null,
      minParticipants: "",
      maxParticipants: "",
      requestBefore: "",
      optoutBefore: "",
      requestApprovalRequired: requestApprovalRequired,
      optOutApprovalRequired: requestApprovalRequired,
      optoutAllowed: false,
      requestRequired: true,
      registrationByManager: false,
      scheduleRequired: false,
      mandatoryCourse: false,
    };

    setGeneralDataObj(objManageCource);
  };
  console.log(generalDataObj);
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className={"col-lg-6 col-sm-6 col-xs-6"}>
            <div className="row">
              <div className={"col-lg-6 col-sm-6 col-xs-6"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Course ID
                    <sup>*</sup>
                  </label>

                  <InputForm
                    className="form-control"
                    placeholder={"Course ID"}
                    isDisabled={true}
                    textArea={false}
                    value={generalDataObj.courseCode}
                    onChange={(e) => {}}
                    maxLength="255"
                  />
                  {formErrors["course_Id"] && (
                    <p style={{ color: "red" }}>{formErrors["course_Id"]}</p>
                  )}
                </div>
              </div>
              <div className={"col-lg-6 col-sm-6 col-xs-6"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Course Title
                    <sup>*</sup>
                  </label>

                  <InputForm
                    className="form-control"
                    placeholder={"Course Title"}
                    isDisabled={isDisable}
                    textArea={false}
                    value={generalDataObj.courseTitle}
                    onChange={(e) => {
                      formOnChange(e, "courseTitle");
                    }}
                    maxLength="255"
                  />
                  {formErrors["courseTitle"] && (
                    <p style={{ color: "red" }}>{formErrors["courseTitle"]}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className={"col-lg-6 col-sm-6 col-xs-6"}>
                <div className="row">
                  <div className={"col-lg-10 col-sm-10 col-xs-10"}>
                    <div className="dropdown mb-1">
                      <label className="col-form-label">Journey</label>{" "}
                      <sup>*</sup>
                      <SelectForm
                        isClearable
                        isSearchable
                        options={journeyOptions}
                        placeholder={"Journey"}
                        isDisabled={isDisable}
                        value={generalDataObj.journey}
                        onChange={async (e) => {
                          formOnChange(e, "journey");
                        }}
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                      />
                      {formErrors["journey"] && (
                        <p style={{ color: "red" }}>{formErrors["journey"]}</p>
                      )}
                    </div>
                  </div>
                  <div className={"col-lg-2 col-sm-2 col-xs-2"}>
                    {/* <div className="mb-1"> */}
                    <label className="col-form-label"></label>{" "}
                    <div className="mt-3">
                      <button
                        className="btn btn-primary"
                        // style={{ marginLeft: 5 }}
                        onClick={() => {
                          getProgramDroupDownListApiCall();
                        }}
                      >
                        <i className="fa-solid fa-refresh"></i>
                      </button>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
              <div className={"col-lg-6 col-sm-6 col-xs-6"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Minimum Participants
                    <sup>*</sup>
                  </label>

                  <InputForm
                    className="form-control"
                    placeholder={"Minimum Participants"}
                    isDisabled={isDisable}
                    textArea={false}
                    value={generalDataObj.minParticipants}
                    onChange={(e) => {
                      formOnChange(e, "minParticipants");
                    }}
                    maxLength={6}
                    // type="number"
                  />
                  {formErrors["minParticipants"] && (
                    <p style={{ color: "red" }}>
                      {formErrors["minParticipants"]}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className={"col-lg-6 col-sm-6 col-xs-6"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Maximum Participants
                    <sup>*</sup>
                  </label>

                  <InputForm
                    className="form-control"
                    placeholder={"Maximum Participants"}
                    isDisabled={isDisable}
                    textArea={false}
                    value={generalDataObj.maxParticipants}
                    // onKeyPress={(event) => {
                    //   if (!/^[1-9]\d{0,5}$/.test(event.key)) {
                    //     event.preventDefault();
                    //   }
                    // }}
                    onChange={(e) => {
                      formOnChange(e, "maxParticipants");
                    }}
                    maxLength={6}
                    // type="number"
                  />
                  {formErrors["maxParticipants"] && (
                    <p style={{ color: "red" }}>
                      {formErrors["maxParticipants"]}
                    </p>
                  )}
                </div>
              </div>
              <div className={"col-lg-6 col-sm-6 col-xs-6"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Mandatory Course
                    <sup>*</sup>
                  </label>

                  <div>
                    <input
                      className="ml-2 mr-1"
                      disabled={isDisable}
                      id={`mandatoryCourse_1`}
                      type="radio"
                      name="mandatoryCourse"
                      onChange={(e) => {
                        formOnChange(e, "mandatoryCourse");
                      }}
                      checked={generalDataObj?.mandatoryCourse === true}
                    />
                    <label htmlFor={`mandatoryCourse_1`}>Yes</label>
                    <input
                      className="ml-2 mr-1"
                      disabled={isDisable}
                      id={`mandatoryCourse_2`}
                      name="mandatoryCourse"
                      type="radio"
                      onChange={(e) => {
                        formOnChange(e, "mandatoryCourse");
                      }}
                      checked={generalDataObj?.mandatoryCourse === false}
                    />
                    <label htmlFor={`mandatoryCourse_2`}>No</label>
                  </div>
                  {formErrors["mandatoryCourse"] && (
                    <p style={{ color: "red" }}>
                      {formErrors["mandatoryCourse"]}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className={"col-lg-6 col-sm-6 col-xs-6"}>
            <div className={"col-lg-12 col-sm-12 col-xs-12"}>
              <div className="mb-1">
                <label className="col-form-label">
                  Course Objective
                  <sup>*</sup>
                </label>
                <RTE
                  content={generalDataObj.learningObjective}
                  disabled={isDisable}
                  onblur={(e) => {
                    formOnChange(e, "learningObjective");
                  }}
                />

                {formErrors["learningObjective"] && (
                  <p style={{ color: "red" }}>
                    {formErrors["learningObjective"]}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className={"col-lg-3 col-sm-3 col-xs-4"}>
            <div className="mb-1">
              <label className="col-form-label">
                Employee Request Required
                <sup>*</sup>
              </label>

              <div>
                <input
                  className="ml-2 mr-1"
                  // disabled={generalDataObj?.mandatoryCourse || isDisable}
                  disabled={false || isDisable}
                  id={`requestRequired_1`}
                  type="radio"
                  name="requestRequired"
                  onChange={(e) => {
                    formOnChange(e, "requestRequired");
                  }}
                  checked={generalDataObj?.requestRequired === true}
                />
                <label htmlFor={`requestRequired_1`}>Yes</label>
                <input
                  className="ml-2 mr-1"
                  // disabled={generalDataObj?.mandatoryCourse || isDisable}
                  disabled={false || isDisable}
                  id={`requestRequired_2`}
                  name="requestRequired"
                  type="radio"
                  onChange={(e) => {
                    formOnChange(e, "requestRequired");
                  }}
                  checked={generalDataObj?.requestRequired === false}
                />
                <label htmlFor={`requestRequired_2`}>No</label>
              </div>
              {formErrors["requestRequired"] && (
                <p style={{ color: "red" }}>{formErrors["requestRequired"]}</p>
              )}
            </div>
          </div>
          <div className={"col-lg-3 col-sm-3 col-xs-4"}>
            <div className="mb-1">
              <label className="col-form-label">
                Request before (in days)
                <sup>*</sup>
              </label>

              <InputForm
                className="form-control"
                placeholder={"Request before (in days)"}
                isDisabled={
                  isDisable
                    ? true
                    : generalDataObj.requestRequired
                    ? false
                    : true
                }
                textArea={false}
                value={generalDataObj.requestBefore}
                onChange={(e) => {
                  formOnChange(e, "requestBefore");
                }}
                maxLength="255"
                type="number"
              />
              {formErrors["requestBefore"] && (
                <p style={{ color: "red" }}>{formErrors["requestBefore"]}</p>
              )}
            </div>
          </div>
          <div className={"col-lg-3 col-sm-3 col-xs-4"}>
            <div className="mb-1">
              <label className="col-form-label">
                Request approval Required
                <sup>*</sup>
              </label>

              <SelectForm
                isClearable
                isSearchable
                options={approvalRequiredOptions}
                placeholder={"Request approval Required"}
                isDisabled={
                  isDisable
                    ? true
                    : generalDataObj.requestRequired
                    ? false
                    : true
                }
                value={generalDataObj.requestApprovalRequired}
                onChange={async (e) => {
                  formOnChange(e, "requestApprovalRequired");
                }}
                isMulti={false}
                noIndicator={false}
                noSeparator={false}
              />
              {formErrors["requestApprovalRequired"] && (
                <p style={{ color: "red" }}>
                  {formErrors["requestApprovalRequired"]}
                </p>
              )}
            </div>
          </div>

          <div className={"col-lg-3 col-sm-3 col-xs-4"}>
            <div className="mb-1">
              <label className="col-form-label">
                Registration by Manager
                <sup>*</sup>
              </label>

              <div>
                <input
                  className="ml-2 mr-1"
                  disabled={generalDataObj?.mandatoryCourse || isDisable}
                  id={`registrationByManager_1`}
                  type="radio"
                  name="registrationByManager"
                  onChange={(e) => {
                    formOnChange(e, "registrationByManager");
                  }}
                  checked={generalDataObj?.registrationByManager === true}
                />
                <label htmlFor={`registrationByManager_1`}>Yes</label>
                <input
                  className="ml-2 mr-1"
                  disabled={generalDataObj?.mandatoryCourse || isDisable}
                  id={`registrationByManager_2`}
                  name="registrationByManager"
                  type="radio"
                  onChange={(e) => {
                    formOnChange(e, "registrationByManager");
                  }}
                  checked={generalDataObj?.registrationByManager === false}
                />
                <label htmlFor={`registrationByManager_2`}>No</label>
              </div>
              {formErrors["registrationByManager"] && (
                <p style={{ color: "red" }}>
                  {formErrors["registrationByManager"]}
                </p>
              )}
            </div>
          </div>
          <div className={"col-lg-3 col-sm-3 col-xs-4"}>
            <div className="mb-1">
              <label className="col-form-label">
                Employee Opt-out Allowed
                <sup>*</sup>
              </label>

              <div>
                <input
                  className="ml-2 mr-1"
                  disabled={isDisable}
                  id={`optoutAllowed_1`}
                  type="radio"
                  name="optoutAllowed"
                  onChange={(e) => {
                    formOnChange(e, "optoutAllowed");
                  }}
                  checked={generalDataObj?.optoutAllowed === true}
                />
                <label htmlFor={`optoutAllowed_1`}>Yes</label>
                <input
                  className="ml-2 mr-1"
                  disabled={isDisable}
                  id={`optoutAllowed_2`}
                  name="optoutAllowed"
                  type="radio"
                  onChange={(e) => {
                    formOnChange(e, "optoutAllowed");
                  }}
                  checked={generalDataObj?.optoutAllowed === false}
                />
                <label htmlFor={`optoutAllowed_2`}>No</label>
              </div>
              {formErrors["optoutAllowed"] && (
                <p style={{ color: "red" }}>{formErrors["optoutAllowed"]}</p>
              )}
            </div>
          </div>

          <div className={"col-lg-3 col-sm-3 col-xs-4"}>
            <div className="mb-1">
              <label className="col-form-label">
                Opt-out before (in days)
                <sup>*</sup>
              </label>

              <InputForm
                className="form-control"
                placeholder={"Opt-out before (in days)"}
                isDisabled={
                  isDisable ? true : generalDataObj.optoutAllowed ? false : true
                }
                textArea={false}
                value={generalDataObj.optoutBefore}
                onChange={(e) => {
                  formOnChange(e, "optoutBefore");
                }}
                maxLength="255"
                type="number"
              />
              {formErrors["optoutBefore"] && (
                <p style={{ color: "red" }}>{formErrors["optoutBefore"]}</p>
              )}
            </div>
          </div>

          <div className={"col-lg-3 col-sm-3 col-xs-4"}>
            <div className="mb-1">
              <label className="col-form-label">
                Opt-out Approval Required
                <sup>*</sup>
              </label>

              <SelectForm
                isClearable
                isSearchable
                options={approvalRequiredOptions}
                placeholder={"Opt-out Approval Required"}
                isDisabled={
                  isDisable ? true : generalDataObj.optoutAllowed ? false : true
                }
                value={generalDataObj.optOutApprovalRequired}
                onChange={async (e) => {
                  formOnChange(e, "optOutApprovalRequired");
                }}
                isMulti={false}
                noIndicator={false}
                noSeparator={false}
              />
              {formErrors["optOutApprovalRequired"] && (
                <p style={{ color: "red" }}>
                  {formErrors["optOutApprovalRequired"]}
                </p>
              )}
            </div>
          </div>

          <div className={"col-lg-3 col-sm-3 col-xs-4"}>
            <div className="mb-1">
              <label className="col-form-label">
                Schedule Required
                <sup>*</sup>
              </label>

              <div>
                <input
                  className="ml-2 mr-1"
                  disabled={isDisable}
                  id={`scheduleRequired_1`}
                  type="radio"
                  name="scheduleRequired"
                  onChange={(e) => {
                    formOnChange(e, "scheduleRequired");
                  }}
                  checked={generalDataObj?.scheduleRequired === true}
                />
                <label htmlFor={`scheduleRequired_1`}>Yes</label>
                <input
                  className="ml-2 mr-1"
                  disabled={isDisable}
                  id={`scheduleRequired_2`}
                  name="scheduleRequired"
                  type="radio"
                  onChange={(e) => {
                    formOnChange(e, "scheduleRequired");
                  }}
                  checked={generalDataObj?.scheduleRequired === false}
                />
                <label htmlFor={`scheduleRequired_2`}>No</label>
              </div>
              {formErrors["scheduleRequired"] && (
                <p style={{ color: "red" }}>{formErrors["scheduleRequired"]}</p>
              )}
            </div>
          </div>

          <div className={"col-lg-3 col-sm-3 col-xs-4"}>
            <div className="row">
              <div className={"dropdown col-lg-10 col-sm-10 col-xs-10"}>
                <label className="col-form-label">Domains</label> <sup>*</sup>
                <SelectForm
                  isClearable
                  options={domainOptions}
                  isOptionDisabled={(option) => !option?.isEnabled}
                  placeholder={"Domains"}
                  isDisabled={isDisable}
                  value={generalDataObj.domain}
                  onChange={(e) => {
                    formOnChange(e, "domain");
                  }}
                  isMulti={true}
                  noIndicator={false}
                  noSeparator={false}
                />
                {formErrors["domain"] && (
                  <p style={{ color: "red" }}>{formErrors["domain"]}</p>
                )}
              </div>
              <div className={"col-lg-2 col-sm-2 col-xs-2"}>
                {/* <div className="mb-1"> */}
                <label className="col-form-label"></label>{" "}
                <div className="mt-3">
                  <button
                    className="btn btn-primary"
                    // style={{ marginLeft: 5 }}
                    onClick={() => {
                      getLMSDomainDropDownDataApiCall();
                    }}
                  >
                    <i className="fa-solid fa-refresh"></i>
                  </button>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
        <br />
        {!isDisable && (
          <div className="SectionSubmit mb-4 clearfix">
            <button
              onClick={() => {
                resetFunction();
              }}
              disabled={false}
              className="btn btn-secondary float-right ml-2"
            >
              <i className="fa-solid fa-refresh"></i> Reset
            </button>

            <button
              onClick={() => {
                handleSubmitButton();
              }}
              disabled={false}
              className="btn btn-filter-submit float-right ml-2"
            >
              <i className="fa-solid fa-check"></i> Save
            </button>
          </div>
        )}
        {/* <div className="SectionSubmit mb-4 clearfix">
        <button
          onClick={() => {
            resetFunction();
          }}
          disabled={false}
          className="btn btn-filter-submit float-right ml-2"
        >
          <i className="fa-solid fa-refresh"></i> Reset
        </button>
        <button
          onClick={() => {
            navigate(-1);
          }}
          disabled={false}
          className="btn btn-filter-submit float-right ml-2">
          <i className="fa-solid fa-arrow-left"></i> Cancel
        </button>

        <button
          onClick={() => {
            handleSubmitButton();
          }}
          disabled={false}
          className="btn btn-filter-submit float-right ml-2">
          <i className="fa-solid fa-check"></i> Save
        </button>
      </div> */}
      </div>
      {/* Notification */}

      <Modal
        show={notification}
        onHide={() => setNotification(false)}
        backdrop="static"
        keyboard={false}
        size="xl"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">
            Notification and mail will be sent to the following users
          </div>
        </div>
        <Modal.Body>
          <>
            <div className="row mx-auto ">
              <div className="col-lg-6 col-sm-6 col-xs-6">
                <div className="mb-1">
                  <label className="col-form-label">
                    To <sup>*</sup>
                  </label>
                  <InputForm
                    value={toEmail}
                    placeholder={"Enter To Email"}
                    isDisabled={true}
                    textArea={false}
                    onChange={(e) => {
                      setToEmail(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="col-lg-6 col-sm-6 col-xs-6">
                <div className="dropdown mb-1">
                  <label className="col-form-label">CC</label>
                  <SelectForm
                    isClearable
                    async
                    placeholder={"Search CC User"}
                    isDisabled={false}
                    options={(searchString, cb) => {
                      handleSearchForAsyncDropdown(
                        searchString,
                        "co_ordinator",
                        cb
                      );
                    }}
                    onChange={(val: any) => {
                      setCCuser(val);
                    }}
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                    value={ccuser}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 col-xs-6">
                <div className="mb-1">
                  <label className="col-form-label">
                    Effective Date <sup>*</sup>
                  </label>
                  <DateTimeForm
                    value={effectiveDate}
                    placeholder={"Effective Date"}
                    onChange={(event) => {
                      setEffectiveDate(event);
                    }}
                    isDisabled={true}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 col-xs-6">
                <div className="mb-1">
                  <label className="col-form-label">Remarks</label>
                  <InputForm
                    value={remarks}
                    placeholder={"Enter the remarks"}
                    isDisabled={false}
                    textArea={true}
                    onChange={(e) => {
                      setRemarks(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => {
              InsertUpdateDataOnNotification();
            }}
          >
            Execute
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setNotification(false);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CourseGeneral;
