import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoaderContext } from "../../../Helpers/Context/Context";
import useUserContext from "../../../Helpers/ZustandStore/UserContextStore";
import DynamicGrid from "../../../Components/DynamicGrid/DynamicGrid";
import { Modal } from "react-bootstrap";
import { Tooltip } from "@mui/material";
import notify from "../../../Helpers/ToastNotification";
import { APICall } from "../../../Helpers/APICalls";
import {
  changeSequenceCourseFeedback,
  delinkCourseFeedback,
  getCourseFeedbackDashboardList,
} from "../../../Helpers/APIEndPoints/LMS_EndPoints";

const CourseGrid = ({ gridReset, setGridReset }) => {
  const { state } = useLocation();

  const [isDisable, setIsDisable] = useState(
    state && state?.isDisable ? true : false
  );

  const { showLoader, hideLoader } = useContext(LoaderContext);

  const { userDetails, currentRoleId, proxyUserDetails }: any =
    useUserContext();

  const navigate = useNavigate();
  const [gridData, setGridData] = useState([]);
  const [count, setCount] = useState(0);

  const [feedbackId, setFeedbackId] = useState(0);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    getCourseFeedbackDashboardListApiCall();
  }, [gridReset]);

  const getCourseFeedbackDashboardListApiCall = async () => {
    showLoader();
    const parameters = {
      Mode: "COURSE_LIST",
      CourseId: state?.courseId,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      LaunchId: state?.launchHubId,
    };

    const responseData = await APICall(
      getCourseFeedbackDashboardList,
      "POST",
      parameters
    );

    if (responseData?.status === 0) {
      setGridData(responseData?.data);
      setCount(responseData?.data?.length);
    } else if (responseData?.status === 1) {
      notify(responseData?.status, responseData?.message);
    }

    hideLoader();
  };

  const chengeSequenceCourseAssessmentApiCall = async (
    feedbackId,
    direction
  ) => {
    showLoader();
    const parameters = {
      Mode: "COURSE_CHANGE",
      CourseId: state?.courseId,
      FeedbackId: feedbackId,
      Direction: direction,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    };
    const responseData = await APICall(
      changeSequenceCourseFeedback,
      "POST",
      parameters
    );

    if (responseData?.status === 0) {
      setGridReset(!gridReset);
      notify(responseData?.status, responseData?.message);
    } else if (responseData?.status === 1) {
      notify(responseData?.status, responseData?.message);
    }
    hideLoader();
  };

  const delinkCourseAssessmentApiCall = async () => {
    showLoader();
    const parameters = {
      Mode: "COURSE_DELINK",
      CourseId: state?.courseId,
      FeedbackId: feedbackId,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    };
    const responseData = await APICall(
      delinkCourseFeedback,
      "POST",
      parameters
    );

    if (responseData?.status === 0) {
      setGridReset(!gridReset);
      setShowModal(false);
      notify(responseData?.status, responseData?.message);
    } else if (responseData?.status === 1) {
      notify(responseData?.status, responseData?.message);
    }
    hideLoader();
  };

  const gridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: gridData.length,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        // await setSortColumn(sortColumn);
        // await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        // await setSortColumn(sortColumn);
        // await setSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      //   setPage(page * PageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };
  const gridColumns = [
    {
      name: "Id",
      label: "Id",
      options: {
        display: false,
      },
    },

    {
      name: "feedback",
      label: "Feedback",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "mandatory",
      label: "Mandatory",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },
    {
      name: "feedbackDays",
      label: "Feedback Days",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },

    {
      name: "sequence",
      label: "Sequence",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "Id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta) => {
          let Id = tableMeta.tableData[tableMeta.rowIndex].id;

          const { rowIndex, tableData } = tableMeta;
          const sequenceNo = tableData[rowIndex].sequence;
          const totalItems = tableData.length;
          const isFirst = rowIndex === 0; // First item has rowIndex 0
          const isLast = rowIndex === totalItems - 1; // Last item has rowIndex totalItems - 1

          const upArrow = isFirst ? (
            <Tooltip title="">
              <button className="btn mr-1" disabled={true}></button>
            </Tooltip>
          ) : (
            <Tooltip title="Move up">
              <button
                className="btn"
                disabled={false}
                onClick={(e) => {
                  e.preventDefault();
                  chengeSequenceCourseAssessmentApiCall(Id, -1);
                }}>
                <i className="fa fa-caret-up"></i>
              </button>
            </Tooltip>
          );

          const downArrow = isLast ? (
            <Tooltip title="">
              <button className="btn mr-1" disabled={true}></button>
            </Tooltip>
          ) : (
            <Tooltip title="Move down">
              <button
                className="btn"
                disabled={false}
                onClick={(e) => {
                  e.preventDefault();
                  chengeSequenceCourseAssessmentApiCall(Id, 1);
                }}>
                <i className="fa fa-caret-down"></i>
              </button>
            </Tooltip>
          );

          return (
            <div className="">
              {!isDisable && (
                <>
                  <div className="">
                    {upArrow}
                    {downArrow}

                    <Tooltip title="Delink">
                      <a
                        className=" ml-2 mr-2 mt-1"
                        style={{ fontSize: "15px" }}
                        onClick={(e) => {
                          handleDelinkFunction(Id);
                        }}>
                        <i className="fa fa-link"></i>
                      </a>
                    </Tooltip>
                  </div>
                </>
              )}
            </div>
          );
        },
      },
    },
  ];

  const handleDelinkFunction = async (id: any) => {
    setShowModal(true);
    setFeedbackId(id);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="mx-auto d-flex justify-content-between align-items-center grid-wrapper grid_mob">
          <p style={{ fontSize: "16px" }} className="text-white">
            Course
          </p>
        </div>
        <div className="mb-3 pt-3">
          <DynamicGrid
            data={gridData}
            columns={gridColumns}
            options={gridOptions}
          />
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">Course Feedback Delink</div>
        </div>
        <Modal.Body>
          <>
            <div>
              <b>{`Do you want to Delink the Course Feedback?`}</b>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => {
              delinkCourseAssessmentApiCall();
            }}>
            Submit
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={(e) => {
              e.preventDefault();
              setShowModal(false);
            }}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CourseGrid;
