import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoaderContext } from "../../Helpers/Context/Context";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import SelectForm from "../../Components/SelectForm/SelectForm";
import {
  getCourseData,
  getCourseFeedbackDropDownList,
  saveCourseFeedback,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import { APICall } from "../../Helpers/APICalls";
import notify from "../../Helpers/ToastNotification";
import CourseGrid from "./FeedbackGridComponent/CourseGrid";
import CourseScheduleGrid from "./FeedbackGridComponent/CourseScheduleGrid";
import InputForm from "../../Components/InputForm/InputForm";

interface IOptions {
  label?: string;
  value?: number | string;
  code?: string;
}

interface IFormData {
  feedbackForm?: IOptions[] | any;
  feedbackLevel?: IOptions | any;
  feedbackType?: IOptions | any;
  courseSchedule?: IOptions | any;
  mandatory?: IOptions | any;
  feedbackDays?: Number | any;
}

const CourseFeedback = ({ activeTab, setActiveTab }) => {
  const { state } = useLocation();

  const [isDisable, setIsDisable] = useState(
    state && state?.isDisable ? true : false
  );

  const { showLoader, hideLoader } = useContext(LoaderContext);

  const { userDetails, currentRoleId }: any = useUserContext();

  const navigate = useNavigate();

  const [formData, setFormData] = useState<IFormData>({});
  const [formErrors, setFormErrors] = useState<IFormData>({});
  const [formOptions, setFormOptions] = useState<IFormData>({});

  const feedbackLevelObj = {
    Course: "COURSE",
    Session: "SESSION",
    CourseSchedule: "CS",
    SessionSchedule: "SS",
  };

  const [gridReset, setGridReset] = useState(false);

  const [isScheduleRequired, setIsScheduleRequired] = useState(false);

  useEffect(() => {
    const responseData = async () => {
      //FEEDBACK_LEVEL
      const res = await APICall(getCourseFeedbackDropDownList, "POST", {
        Mode: "FEEDBACK_LEVEL",
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      });

      debugger;

      if (res.data && res.data.length > 0) {
        setFormOptions((prev) => {
          return {
            ...prev,
            feedbackLevel: res.data,
          };
        });
      }

      //FEEDBACK
      const res3 = await APICall(getCourseFeedbackDropDownList, "POST", {
        Mode: "FEEDBACK",
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      });

      if (res3.data && res3.data.length > 0) {
        setFormOptions((prev) => {
          return {
            ...prev,
            feedbackForm: res3.data,
          };
        });
      }

      //COURSE_SCHEDULE
      const res5 = await APICall(getCourseFeedbackDropDownList, "POST", {
        Mode: "COURSE_SCHEDULE",
        CourseId: state?.courseId,
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      });

      if (res5.data && res5.data.length > 0) {
        setFormOptions((prev) => {
          return {
            ...prev,
            courseSchedule: res5.data,
          };
        });
      }

      const mandatoryObj = [
        { value: 1, label: "Yes" },
        { value: 0, label: "No" },
      ];

      setFormOptions((prev) => {
        return {
          ...prev,
          mandatory: mandatoryObj,
        };
      });

      const responseData = await APICall(getCourseData, "POST", {
        Id: state?.courseId,
        LaunchId: state?.launchHubId,
      });

      if (responseData?.status === 0) {
        setIsScheduleRequired(responseData?.data?.scheduleRequired);
      }
    };

    showLoader();

    responseData();

    hideLoader();
  }, [gridReset]);

  const formOnChange = (e, type) => {
    switch (type) {
      case "feedbackLevel":
        setFormData({
          ...formData,
          feedbackLevel: e,
        });
        break;

      case "feedbackForm":
        setFormData({
          ...formData,
          feedbackForm: e,
        });
        break;

      case "courseSchedule":
        setFormData({
          ...formData,
          courseSchedule: e,
        });
        break;

      case "mandatory":
        setFormData({
          ...formData,
          mandatory: e,
        });
        break;

      case "feedbackDays":
        setFormData({
          ...formData,
          feedbackDays: e.target.value,
        });
        break;

      default:
        break;
    }
  };

  const validateForm = () => {
    let errorObj = {};
    let isError = false;

    setFormErrors({});

    if (
      formData?.feedbackLevel === null ||
      formData?.feedbackLevel === undefined
    ) {
      errorObj = {
        ...errorObj,
        ["feedbackLevel"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["feedbackLevel"]: "",
      };
    }

    if (
      formData?.feedbackForm?.length === 0 ||
      formData?.feedbackForm === null ||
      formData?.feedbackForm === undefined
    ) {
      errorObj = {
        ...errorObj,
        ["feedbackForm"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["feedbackForm"]: "",
      };
    }

    if (formData?.mandatory === null || formData?.mandatory === undefined) {
      errorObj = {
        ...errorObj,
        ["mandatory"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["mandatory"]: "",
      };
    }

    if (
      formData?.feedbackDays !== "" &&
      formData?.feedbackDays !== null &&
      formData?.feedbackDays !== undefined
    ) {
      if (!/^([0-9]+|0)$/.test(formData?.feedbackDays)) {
        errorObj = {
          ...errorObj,
          ["feedbackDays"]: "Only numbers are allowed",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["feedbackDays"]: "",
        };
      }
    }

    if (formData?.feedbackLevel?.code === feedbackLevelObj?.CourseSchedule) {
      if (
        formData?.courseSchedule === null ||
        formData?.courseSchedule === undefined
      ) {
        errorObj = {
          ...errorObj,
          ["courseSchedule"]: "Required",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["courseSchedule"]: "",
        };
      }
    }

    const isEmpty = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  const handleSubmitFunction = async () => {
    showLoader();

    if (validateForm()) {
      hideLoader();
      return;
    }

    debugger;

    let valueId = 0;

    if (formData?.feedbackLevel?.code === feedbackLevelObj.Course) {
      valueId = state?.courseId;
    } else if (
      formData?.feedbackLevel?.code === feedbackLevelObj.CourseSchedule
    ) {
      valueId = formData?.courseSchedule?.value;
    }

    const parameters = {
      Mode: "SAVE",
      CourseId: state?.courseId,
      FeedbackIds: formData?.feedbackForm?.map((s) => s.value).join(","),
      FeedbackLevelId: formData?.feedbackLevel?.value,
      IsMandatory: formData?.mandatory?.value,
      FeedbackDays: formData?.feedbackDays || 0,
      ValueId: valueId,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    };

    const responseData = await APICall(saveCourseFeedback, "POST", parameters);

    debugger;

    if (responseData?.status === 0) {
      notify(responseData?.status, responseData?.message);
      handleResetFunction();
      setGridReset(!gridReset);
    } else if (responseData?.status === 1) {
      notify(responseData?.status, responseData?.message);
    }

    hideLoader();
  };

  const handleResetFunction = () => {
    setFormData((prev) => {
      return {
        ...prev,
        feedbackLevel: null,
        feedbackForm: null,
        courseSchedule: null,
        mandatory: null,
        feedbackDays: null,
      };
    });

    setFormErrors({});
  };
  return (
    <>
      <div className="container-fluid">
        {!isDisable && (
          <>
            <div className="row">
              <div className={"col-lg-3 col-sm-4 col-xs-6"}>
                <div className="dropdown mb-1">
                  <label className="col-form-label">Feedback Level</label>{" "}
                  <sup>*</sup>
                  <SelectForm
                    isClearable
                    isSearchable
                    options={formOptions?.feedbackLevel}
                    placeholder={"Feedback Level"}
                    isDisabled={isDisable}
                    value={formData?.feedbackLevel}
                    onChange={(e) => {
                      formOnChange(e, "feedbackLevel");
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  {formErrors["feedbackLevel"] && (
                    <p style={{ color: "red" }}>
                      {formErrors["feedbackLevel"]}
                    </p>
                  )}
                </div>
              </div>

              {formData?.feedbackLevel?.code ===
                feedbackLevelObj?.CourseSchedule && (
                <>
                  <div className={"col-lg-3 col-sm-4 col-xs-6"}>
                    <div className="dropdown mb-1">
                      <label className="col-form-label">Course Schedule</label>{" "}
                      <sup>*</sup>
                      <SelectForm
                        isClearable
                        isSearchable
                        options={formOptions?.courseSchedule}
                        placeholder={"Course Schedule"}
                        isDisabled={isDisable}
                        value={formData?.courseSchedule}
                        onChange={(e) => {
                          formOnChange(e, "courseSchedule");
                        }}
                        isMulti={false}
                        noIndicator={false}
                        noSeparator={false}
                      />
                      {formErrors["courseSchedule"] && (
                        <p style={{ color: "red" }}>
                          {formErrors["courseSchedule"]}
                        </p>
                      )}
                    </div>
                  </div>
                </>
              )}

              <div className={"col-lg-3 col-sm-4 col-xs-6"}>
                <div className="dropdown mb-1">
                  <label className="col-form-label">Feedback Form</label>{" "}
                  <sup>*</sup>
                  <SelectForm
                    isClearable
                    isSearchable
                    options={formOptions?.feedbackForm}
                    placeholder={"Feedback Form"}
                    isDisabled={isDisable}
                    value={formData?.feedbackForm}
                    onChange={(e) => {
                      formOnChange(e, "feedbackForm");
                    }}
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  {formErrors["feedbackForm"] && (
                    <p style={{ color: "red" }}>{formErrors["feedbackForm"]}</p>
                  )}
                </div>
              </div>

              <div className={"col-lg-3 col-sm-4 col-xs-6"}>
                <div className="dropdown mb-1">
                  <label className="col-form-label">Mandatory</label>{" "}
                  <sup>*</sup>
                  <SelectForm
                    isClearable
                    isSearchable
                    options={formOptions?.mandatory}
                    placeholder={"Mandatory"}
                    isDisabled={isDisable}
                    value={formData?.mandatory}
                    onChange={(e) => {
                      formOnChange(e, "mandatory");
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  {formErrors["mandatory"] && (
                    <p style={{ color: "red" }}>{formErrors["mandatory"]}</p>
                  )}
                </div>
              </div>

              {isScheduleRequired && (
                <div className={"col-lg-3 col-sm-4 col-xs-4"}>
                  <div className="mb-1">
                    <label className="col-form-label">Feedback Days</label>

                    <InputForm
                      className="form-control"
                      placeholder={"Feedback Days"}
                      isDisabled={isDisable}
                      textArea={false}
                      value={formData?.feedbackDays}
                      onChange={(e) => {
                        formOnChange(e, "feedbackDays");
                      }}
                      maxLength="255"
                    />
                    {formErrors["feedbackDays"] && (
                      <p style={{ color: "red" }}>
                        {formErrors["feedbackDays"]}
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>

            <br />
            <div className="SectionSubmit mb-4 clearfix">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmitFunction();
                }}
                disabled={isDisable}
                className="btn btn-filter-submit float-right ml-2">
                <i className="fa fa-plus mr-2"></i>
                Add Feedback
              </button>

              <button
                onClick={() => {
                  handleResetFunction();
                }}
                disabled={isDisable}
                className="btn btn-secondary float-right ml-2">
                <i className="fa-solid fa-refresh"></i> Reset
              </button>
            </div>
          </>
        )}

        <div>
          <CourseGrid gridReset={gridReset} setGridReset={setGridReset} />
        </div>
        {/* <div>
          <SessionGrid gridReset={gridReset} setGridReset={setGridReset} />
        </div> */}
        <br />
        <div>
          <CourseScheduleGrid
            gridReset={gridReset}
            setGridReset={setGridReset}
          />
        </div>
        {/* <div>
          <SessionScheduleGrid
            gridReset={gridReset}
            setGridReset={setGridReset}
          />
        </div> */}
      </div>
    </>
  );
};

export default CourseFeedback;
