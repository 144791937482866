import { APICall } from "../../Helpers/APICalls";
import { getUserDetails } from "../../Helpers/APIEndPoints/EndPoints";

const getParameter = (parameterName) => {
  let urlParameter = new URLSearchParams(window.location.search);
  return urlParameter.get(parameterName);
};

export const getUserData = () => {
  const id = getParameter("id");
  //  const id = 8;
  return async (dispatch) => {
    function getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }

    let token = getCookie("token");

    if (id !== null) {
      const response = await APICall(getUserDetails, "Post", {
        id,
      });

      dispatch({
        type: "getUserData",
        payload: response.data,
      });
    }
  };
};

export const setEntityConfig = (payload) => {
  return {
    type: "setEntityConfig",
    payload,
  };
};

export const setUserRole = (payload) => {
  return {
    type: "setUserRole",
    payload,
  };
};

export const setUserData = (payload) => {
  return {
    type: "setUserData",
    payload,
  };
};

export const clearUserData = (payload) => {
  return {
    type: "clearUserData",
    payload,
  };
};

export const updateRole = (payLoads: any) => {
  return {
    type: "UPDATE_ROLE",
    payLoad: payLoads,
  };
};
