import "./MainLogin.css";
import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { APICall } from "../../Helpers/APICalls";
import blueLogo from "../../Assets/Images/final_logo_blue.png";
import {
  loginUrl,
  logoutUserById,
  OTPUrl,
  GetRolesURL,
} from "../../Helpers/APIEndPoints/EndPoints";
import welproLogo from "../../Assets/Images/welspun-word-white.png";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import {
  decryptUsingAES256,
  encryptUsingAES256,
} from "../../Helpers/Cryptography/CryptoJs";
import { LoaderContext } from "../../Helpers/Context/Context";

const MainLogin = () => {
  const { addUserDetails, updateCurrentRoleId }: any = useUserContext();
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const currentStateType = {
    normal: "normal",
    newUser: "newUser",
    forgetPassLogin: "forgetPassLogin",
    forgetPassChange: "forgetPassChange",
    OTP: "OTP",
  };
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentState, setCurrentState] = useState(currentStateType.normal);
  const [OTP, setOTP] = useState("");
  const [formErrors, setFormErrors] = useState<string>("");
  const [forgetPass, setForgetPass] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showForgotConfirmPassword, setShowForgotConfirmPassword] =
    useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    (async () => {
      await GetRolesAsync();
    })();
  }, []);

  const getLoginDetails = async () => {
    await showLoader();
    let reqObj = {
      LoginId: username,
      Password: password,
    };
    reqObj = encryptUsingAES256(reqObj); //ecrypt data

    const GetLoginData = await APICall(loginUrl, "POST", {
      Data: reqObj,
    });

    if (
      GetLoginData.data !== null &&
      GetLoginData.data.valid === false &&
      GetLoginData.data.data === "NewUser"
    ) {
      setCurrentState(currentStateType.newUser);
      setPassword("");
      setFormErrors("");
    } else if (
      GetLoginData.data !== null &&
      GetLoginData.data.valid &&
      GetLoginData.data.data === "OTP"
    ) {
      setCurrentState(currentStateType.OTP);
      setFormErrors(GetLoginData.message);
    }
    // else if (GetLoginData.message === "Concurrent") {
    //   if (
    //     window.confirm(
    //       "Unauthorized, Concurrent login not allowed. Please click Ok to logout from all devices and login again!"
    //     )
    //   ) {
    //     (async () => {
    //       const apiRes = await APICall(logoutUserById, "POST", {
    //         LoginId: username,
    //       });
    //     })();
    //   }
    // }
    else {
      setFormErrors(GetLoginData.message);
    }
    await hideLoader();
  };

  const handleNormalLogin = async () => {
    if (!username.trim() || !password.trim()) {
      setFormErrors("Please enter required data");
    }
    // else if (password.length < 8) {
    //   setFormErrors("Password should be at least 8 characters long!");
    // }
    else {
      getLoginDetails();
    }
  };

  const handleForgetPassLogin = async () => {
    if (!username.trim()) {
      setFormErrors("Please enter required data");
    } else {
      postForgetLogin();
    }
  };

  const handleForgetPassChange = async () => {
    await showLoader();
    if (!confirmPassword.trim() || !newPassword.trim()) {
      setFormErrors("Please enter required data");
    } else if (newPassword.length < 8) {
      setFormErrors("Password should be at least 8 characters long!");
    } else if (newPassword === confirmPassword) {
      let reqObj = {
        LoginId: username,
        NewPassword: newPassword,
      };

      reqObj = encryptUsingAES256(reqObj);

      const GetLoginData = await APICall(loginUrl, "POST", {
        Data: reqObj,
      });

      if (
        GetLoginData.data !== null &&
        GetLoginData.data.valid === false &&
        GetLoginData.data.data === "ForgotPasswordChanged"
      ) {
        setPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setOTP("");
        setFormErrors(GetLoginData.message);
        setCurrentState(currentStateType.normal);
      }
    } else {
      setFormErrors("Password does not match");
    }
    await hideLoader();
  };

  const postForgetLogin = async () => {
    await showLoader();
    let reqObj = {
      LoginId: username,
    };

    reqObj = encryptUsingAES256(reqObj);

    const GetLoginData = await APICall(loginUrl, "POST", {
      Data: reqObj,
    });

    if (
      GetLoginData.data !== null &&
      GetLoginData.data.valid === false &&
      GetLoginData.data.data === "OTPForgotPass"
    ) {
      setForgetPass(true); //imp
      setCurrentState(currentStateType.OTP);
      setOTP("");
      setNewPassword("");
      setConfirmPassword("");
      setFormErrors("");
    } else {
      setFormErrors(GetLoginData.message);
    }
    await hideLoader();
  };

  const handleNewUserLogin = async () => {
    await showLoader();
    if (!password.trim() || !confirmPassword.trim() || !newPassword.trim()) {
      setFormErrors("Please enter requird data");
    } else if (newPassword.length < 8 || confirmPassword.length < 8) {
      setFormErrors("Password should be at least 8 characters long!");
    } else if (newPassword === confirmPassword) {
      let reqObj = {
        LoginId: username,
        Password: password,
        NewPassword: newPassword,
      };

      reqObj = encryptUsingAES256(reqObj);

      const GetLoginData = await APICall(loginUrl, "POST", {
        Data: reqObj,
      });

      if (
        GetLoginData.data !== null &&
        GetLoginData.data.valid === false &&
        GetLoginData.data.data === "FirstLoginPassChanged"
      ) {
        setPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setFormErrors(GetLoginData.message);
        setCurrentState(currentStateType.normal);
      }
    } else {
      setFormErrors("Password does not match");
    }
    await hideLoader();
  };

  //Need to call OTP API

  const getOTPDetails = async () => {
    await showLoader();
    let reqObj = {
      LoginId: username,
      OTP: OTP,
      ...(forgetPass && { IsForgotPassword: true }),
    };

    reqObj = encryptUsingAES256(reqObj);

    const GetOTPData = await APICall(OTPUrl, "POST", {
      Data: reqObj,
    });

    if (GetOTPData.data !== null && GetOTPData.data?.valid !== false) {
      const userData = decryptUsingAES256(GetOTPData.data); //decrypt data

      const Roles = await GetRolesAsync();

      const Agency = Roles.find(
        (i) => i?.name?.toLocaleLowerCase()?.trim() == "agency"
      );

      const Candidate = Roles.find(
        (i) => i?.name?.toLocaleLowerCase()?.trim() == "candidate"
      );

      // const IsCandidate = userData?.UserRoles?.some(
      //   (i) => i?.RoleId == Candidate?.id
      // );

      const IsCandidate =
        userData?.UserRoles?.length == 1 &&
        userData?.UserRoles[0]?.RoleId == Candidate?.id;

      const IsAgency = userData?.UserRoles?.some(
        (i) => i?.RoleId == Agency?.id
      );

      const Employee = Roles.find(
        (i) => i?.name?.toLocaleLowerCase()?.trim() == "employee"
      );

      const IsEmployee = userData?.UserRoles?.some(
        (i) => i?.RoleId == Employee?.id
      );

      userData["IsEmployee"] = IsEmployee;
      userData["IsAgency"] = IsAgency;
      userData["Roles"] = Roles;
      // userData["IsCandidate"] = IsCandidate;

      await addUserDetails(userData);
      await updateCurrentRoleId();
      if (GetOTPData?.status == 4) {
        navigate("/profile", {
          state: { TId: userData?.EmployeeId ? userData?.EmployeeId : 0 },
        });
      } else if (userData?.UserRoles.length == 1 && IsEmployee) {
        navigate("/profile", {
          state: {
            TId: userData?.EmployeeId ? userData?.EmployeeId : 0,
            NewHireStatus: "Approved",
          },
        });
      } else {
        navigate("/home");
      }
    } else if (
      GetOTPData.data !== null &&
      GetOTPData.data.valid === false &&
      GetOTPData.data.data === "OTPMatchedForForget"
    ) {
      setCurrentState(currentStateType.forgetPassChange);
      setForgetPass(false); //imp
      setPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setFormErrors("");
    } else {
      setFormErrors(GetOTPData.message);
    }
    await hideLoader();
  };

  const handleOTP = async () => {
    if (!OTP.trim()) {
      setFormErrors("Please enter OTP");
    } else {
      getOTPDetails();
    }
  };

  const togglePassword = (type) => {
    if (type === "Regular") {
      showPassword === false ? setShowPassword(true) : setShowPassword(false);
    }
    if (type === "Forgot") {
      showForgotPassword === false
        ? setShowForgotPassword(true)
        : setShowForgotPassword(false);
    }
    if (type === "ForgotConfirm") {
      showForgotConfirmPassword === false
        ? setShowForgotConfirmPassword(true)
        : setShowForgotConfirmPassword(false);
    }
    if (type === "Otp") {
      showOTP === false ? setShowOTP(true) : setShowOTP(false);
    }
  };

  const GetRolesAsync = async () => {
    try {
      showLoader();
      let arr = [];
      const response = await APICall(GetRolesURL, "POST", {});

      if (response?.status == 0 && response?.data?.length > 0) {
        const data = response?.data;
        setRoles(data);
        arr = data;
      } else {
        setRoles([]);
      }

      return arr;
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <div className="login_left_bg">
      <div className="login_wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 p-0">
              <div className="">
                <div className="text-center w-100">
                  <img className="logoimage" src={welproLogo} alt="" />
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="left-section left-side-div">
                    <div className="left-text mb-4">
                      WE ARE <b>RISING</b> TO THE POWER OF <b>OUR POTENTIAL</b>
                    </div>
                    <div className="left-text">
                      WE ARE <b>RISING</b> TO THE POWER OF <b>WELSPUN</b>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="main">
                    <div className="login-form clearfix">
                      <div className="col-lg-12">
                        <div className="right_bg_inner">
                          <div className="text-center">
                            <img
                              className="main-logo"
                              src={blueLogo}
                              alt="Welpro_Logo"
                            />
                            <h5 className="header-h5">
                              {" "}
                              Welcome to WelPro (HRMS)
                            </h5>
                          </div>

                          <div className="tab-pane container">
                            <div className="form_grid mx-3 mt-4">
                              {(currentState === currentStateType.normal ||
                                currentState ===
                                  currentStateType.forgetPassLogin) && (
                                <div className="form-group">
                                  <div className="input_grid">
                                    <input
                                      type="text"
                                      value={username}
                                      onChange={(e) =>
                                        setUsername(e.target.value)
                                      }
                                      placeholder="Enter User ID"
                                      className="form-control"
                                    />
                                    <i className="fa fa-user"></i>
                                  </div>
                                </div>
                              )}

                              {(currentState === currentStateType.normal ||
                                currentState === currentStateType.newUser) && (
                                <div className="form-group">
                                  <div className="input_grid">
                                    <input
                                      type={showPassword ? "text" : "password"}
                                      value={password}
                                      onChange={(e) =>
                                        setPassword(e.target.value)
                                      }
                                      placeholder={
                                        currentState === currentStateType.normal
                                          ? "Password"
                                          : currentState ===
                                            currentStateType.newUser
                                          ? "Current Password"
                                          : ""
                                      }
                                      className="form-control"
                                    />
                                    <i
                                      className="fas fa-eye"
                                      onClick={() => togglePassword("Regular")}
                                    ></i>
                                  </div>
                                </div>
                              )}

                              {(currentState === currentStateType.newUser ||
                                currentState ===
                                  currentStateType.forgetPassChange) && (
                                <>
                                  <div className="form-group">
                                    <div className="input_grid">
                                      <input
                                        //type="password"
                                        type={
                                          showForgotPassword
                                            ? "text"
                                            : "password"
                                        }
                                        value={newPassword}
                                        onChange={(e) =>
                                          setNewPassword(e.target.value)
                                        }
                                        placeholder="Enter New Password"
                                        className="form-control"
                                      />
                                      {/* <i className="fa fa-lock"></i> */}
                                      <i
                                        className="fas fa-eye"
                                        onClick={() => togglePassword("Forgot")}
                                      ></i>
                                    </div>
                                  </div>

                                  <div className="form-group">
                                    <div className="input_grid">
                                      <input
                                        //type="password"
                                        type={
                                          showForgotConfirmPassword
                                            ? "text"
                                            : "password"
                                        }
                                        value={confirmPassword}
                                        onChange={(e) =>
                                          setConfirmPassword(e.target.value)
                                        }
                                        placeholder="Confirm Password"
                                        className="form-control"
                                      />
                                      {/* <i className="fa fa-lock"></i> */}
                                      <i
                                        className="fas fa-eye"
                                        onClick={() =>
                                          togglePassword("ForgotConfirm")
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                </>
                              )}

                              {(currentState === currentStateType.normal ||
                                currentState ===
                                  currentStateType.forgetPassLogin) && (
                                <div className="forgot_pass text-right">
                                  <a
                                    onClick={() => {
                                      if (
                                        currentState === currentStateType.normal
                                      ) {
                                        setUsername("");
                                        setPassword("");
                                        setNewPassword("");
                                        setConfirmPassword("");
                                        setFormErrors("");

                                        setCurrentState(
                                          currentStateType.forgetPassLogin
                                        );
                                      } else {
                                        setCurrentState(
                                          currentStateType.normal
                                        );
                                      }
                                    }}
                                  >
                                    {currentState === currentStateType.normal
                                      ? "Forgot Password?"
                                      : "Back to Login"}
                                  </a>
                                </div>
                              )}

                              {currentState === currentStateType.OTP ? (
                                <div className="form-group">
                                  <div className="input_grid">
                                    <input
                                      //  const [showOTP, setShowOTP] = useState(false);
                                      //type="password"
                                      type={showOTP ? "text" : "password"}
                                      value={OTP}
                                      onChange={(e) => setOTP(e.target.value)}
                                      placeholder="OTP"
                                      className="form-control"
                                    />
                                    {/* <i className="fa fa-lock"></i> */}
                                    <i
                                      className="fas fa-eye"
                                      onClick={() => togglePassword("Otp")}
                                    ></i>
                                  </div>
                                </div>
                              ) : null}

                              <div className="signUp text-center">
                                <a
                                  onClick={() => {
                                    switch (currentState) {
                                      case currentStateType.normal:
                                        handleNormalLogin();
                                        break;
                                      case currentStateType.newUser:
                                        handleNewUserLogin();
                                        break;
                                      case currentStateType.OTP:
                                        handleOTP();
                                        break;
                                      case currentStateType.forgetPassLogin:
                                        handleForgetPassLogin();
                                        break;

                                      case currentStateType.forgetPassChange:
                                        handleForgetPassChange();
                                        break;

                                      default:
                                        break;
                                    }
                                  }}
                                >
                                  Submit
                                </a>
                              </div>
                              {formErrors && (
                                <div className="text-center mb-2 error-message">
                                  {formErrors}
                                </div>
                              )}

                              <div className="sso text-right">
                                <a href="https://adfs.welspun.com/adfs/ls/IdpInitiatedSignon.aspx">
                                  Click Here for SSO Login
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLogin;
