import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoaderContext } from "../../Helpers/Context/Context";
import { APICall } from "../../Helpers/APICalls";
import { getDropdowns } from "../../Helpers/APIEndPoints/EndPoints";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import InputForm from "../../Components/InputForm/InputForm";
import { Collapse, Tooltip } from "@mui/material";
import SelectForm from "../../Components/SelectForm/SelectForm";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { GetEmployeeListDasboardList } from "../../Helpers/APIEndPoints/LMS_EndPoints";
import notify from "../../Helpers/ToastNotification";
import { useDebounce } from "../../utils/useDebounce";
interface IOptions {
  label?: string;
  value?: number | string;
}

interface IFormData {
  business?: IOptions[] | string | any;
  sbu?: IOptions[] | string | any;
  legalEntity?: IOptions[] | string | any;
  customBusiness?: IOptions[] | string | any;
  physicalLocation?: IOptions[] | string | any;
}

const EmployeeList = () => {
  const navigate = useNavigate();

  const [options, setOptions] = useState<IFormData>({});

  const [formData, setFormData] = useState<IFormData>({});
  const { showLoader, hideLoader } = useContext(LoaderContext);
  let { userDetails, currentRoleId }: any = useUserContext();
  const [dashboardStart, setDashboardStart] = useState(0);
  const [searchText, setSearchText] = useState<any>("");
  const [page, setPage] = useState<any>(0);
  const [open, setOpen] = useState<any>(false);
  const [dashboardData, setDashboardData] = useState<any>([]);
  const [dashboardPageSize, setDashboardPageSize] = useState(10);
  //const [dashboardStart, setDashboardStart] = useState(0);
  const [dashboardSortColumn, setDashboardSortColumn] = useState<any>("");
  const [dashboardSortDirection, setDashboardSortDirection] = useState("");
  const [dashboardCount, setDashboardCount] = useState(0);
  const [statusOptions, setStatusOptions] = useState([
    { value: 1, label: "Active" },
    { value: 0, label: "Inactive" },
  ]);
  const [status, setStatus] = useState(null);
  const [filter, setFilter] = useState(false);

  const debouncedValue = useDebounce(searchText);

  const [isReset, setIsReset] = useState(false);

  useEffect(() => {
    getAllDropdownData();
    setStatus(statusOptions[0]);
  }, []);

  //Call api for all Dropdown
  const getDDData = async (AC, PID) => {
    const dropdowndataResponse = await APICall(getDropdowns, "POST", {
      AC,
      PID,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });
    const dropdownData = dropdowndataResponse.data;

    return dropdownData;
  };

  const getAllDropdownData = async () => {
    showLoader();
    const businessOptions = await getDDData("BISP", "");

    const sbuOptions = await getDDData("SBUP", "");

    const legalEntityOptions = await getDDData("LENP", "");

    const physicalLocationOptions = await getDDData("PHLOC", "");

    setOptions((env) => {
      return {
        ...env,
        business: businessOptions,
        sbu: sbuOptions,
        legalEntity: legalEntityOptions,
        physicalLocation: physicalLocationOptions,
      };
    });

    hideLoader();
  };

  const formOnChange = async (event, DDName) => {
    debugger;
    showLoader();
    if (DDName === "business") {
      setFormData((env) => {
        return {
          ...env,
          business: event,
          sbu: null,
        };
      });

      const commaSeparatedBISIds =
        event && event.length > 0 && event?.map((e) => e.value).join(",");
      if (commaSeparatedBISIds) {
        let sbuData = await getDDData("SBU", commaSeparatedBISIds);
        setOptions((env) => {
          return {
            ...env,
            sbu: sbuData,
          };
        });
      }
    }

    if (DDName === "sbu") {
      setFormData((env) => {
        return {
          ...env,
          sbu: event,
          legalEntity: null,
        };
      });

      const commaSeparatedSBUIds =
        event && event.length > 0 && event?.map((e) => e.value).join(",");
      if (commaSeparatedSBUIds) {
        let LENData = await getDDData("LEN", commaSeparatedSBUIds);
        setOptions((env) => {
          return {
            ...env,
            legalEntity: LENData,
          };
        });
      }
    }

    if (DDName === "legalEntity") {
      setFormData((env) => {
        return {
          ...env,
          legalEntity: event,
          division: null,
          employeeClass: null,
        };
      });
      debugger;

      let BIS = formData?.business?.map((e) => e.value).join(",");
      let SBU = formData?.sbu?.map((e) => e.value).join(",");
      let LEN = event?.map((e) => e.value).join(",");

      let PId = `${BIS}-${SBU}-${LEN}`;
      if (PId) {
        const customBusinessData = await getDDData("CUSBIS", PId);
        const commaSeparatedLegalEntityIds =
          event && event.length > 0 && event?.map((e) => e.value).join(",");

        //let ECData = await getDDData("EC", commaSeparatedLegalEntityIds);
        let PayLocData;
        if (DDName === "legalEntity" && commaSeparatedLegalEntityIds) {
          PayLocData = await getDDData("PLOC", commaSeparatedLegalEntityIds);
        }

        setOptions((env) => {
          return {
            ...env,
            customBusiness: customBusinessData,
            //division: DVData,
            //employeeClass: ECData,
            payrollLocation: PayLocData !== undefined ? PayLocData : [],
          };
        });
      }
    }

    if (DDName === "customBusiness") {
      setFormData((env) => {
        return {
          ...env,
          customBusiness: event,
        };
      });
    }

    if (DDName === "physicalLocation") {
      setFormData((env) => {
        return {
          ...env,
          physicalLocation: event,
        };
      });
    }

    hideLoader();
  };

  const onSubmitFilter = () => {
    console.log(formData?.business);
    if (
      formData?.business === undefined &&
      formData?.sbu === undefined &&
      formData?.legalEntity === undefined &&
      formData?.customBusiness === undefined &&
      formData?.physicalLocation === undefined
    ) {
      notify(
        1,
        "Please select at least one filter option  to perform the search action."
      );
    } else {
      setIsReset(!isReset);
    }
  };

  const onResetData = () => {};

  const onClickReset = async () => {
    setFormData((env) => {
      return {
        ...env,
        business: null,
        sbu: null,
        legalEntity: null,
        customBusiness: null,
        physicalLocation: null,
      };
    });
    setSearchText("");
    setStatus(null);

    setDashboardCount(0);

    setIsReset(!isReset);
  };

  useEffect(() => {
    showLoader();
    LoadDashboard();
    hideLoader();
  }, [
    debouncedValue,
    dashboardStart,
    dashboardSortColumn,
    dashboardSortDirection,
    status,
    isReset,
  ]);

  const dashboardOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: dashboardCount,
    Page: page,
    rowsPerPage: dashboardPageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    //standard | vertical | simple
    onChangeRowsPerPage: (num) => {
      //   setLimit(num);
      //   setNxtPgInfo("");
      //   setPrevPgInfo("");
      //   setIsPrevOrNext("");
    },
    onSearchChange: (searchText) => {
      if (searchText !== null) {
        //setDashboardSearchText(searchText);
      } else {
        //setDashboardSearchText("");
      }
    },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setDashboardSortColumn(sortColumn);
        await setDashboardSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setDashboardSortColumn(sortColumn);
        await setDashboardSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      setPage(page);
      setDashboardStart(page * dashboardPageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const LoadDashboard = async () => {
    debugger;

    // await setDashboardData([]);
    // await setDashboardCount(0);

    try {
      showLoader();
      let requestParams = {
        SearchText: searchText,
        Business: formData?.business?.map((e) => e.value).join(","),
        SBU: formData?.sbu?.map((e) => e.value).join(","),
        LegalEntity: formData?.legalEntity?.map((e) => e.value).join(","),
        PhysicalLocation: formData?.physicalLocation
          ?.map((e) => e.value)
          .join(","),
        CustomBusiness: formData?.customBusiness?.map((e) => e.value).join(","),
        SortOrder: dashboardSortDirection,
        SortColumn: dashboardSortColumn,
        PageSize: dashboardPageSize,
        Page: dashboardStart,
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
        EmployeeListIsActive: status?.value,
      };
      debugger;
      //const { data } = await APICall(ProgramDashboardList, "POST", requestParams);
      const { data } = await APICall(
        GetEmployeeListDasboardList,
        "POST",
        requestParams
      );
      debugger;
      if (data !== null && data.length > 0) {
        setDashboardData(data);
        setDashboardCount(data[0].count);
        // hideLoader();
      } else {
        setDashboardData([]);
        //await setPage(0)
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const gridColumns = [
    {
      name: "employeeCode",
      label: "Employee Id",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "displayName",
      label: "Display Name",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "employeeEmailId",
      label: "Business Email Id",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "business",
      label: "Business",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "sbu",
      label: "SBU",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "legalEntity",
      label: "Legal Entity",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "payrollLocation",
      label: "Payroll location",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "physicalLocation",
      label: "Physical Location",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "",
      label: "Action",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta) => {
          let TId = tableMeta.tableData[tableMeta.rowIndex].id;
          let isActive = tableMeta.tableData[tableMeta.rowIndex].isActive;

          console.log(TId, "TID");

          return (
            <div style={{ width: "100px" }}>
              <Tooltip title="View">
                <a
                  className="mr-2"
                  style={{ fontSize: "15px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/profile", {
                      state: { TId, isLMS: true, NewHireStatus: "Approved" },
                    });
                  }}
                >
                  <i className="fas fa-eye"></i>
                </a>
              </Tooltip>
            </div>
          );
        },
      },
    },
  ];

  return (
    <>
      <div className="breadcrumb modified-bredcrumb">
        <span>
          <ul>
            <li>
              <a href="/home">Home</a>
            </li>
            <li>
              {" "}
              <a href="/Employee">Employee List</a>
            </li>
            <li></li>
          </ul>
        </span>
      </div>
      <div className="container-fluid">
        <div className="grid-wrapper grid_mob">
          <div className="row mx-auto filtBox">
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="form-group filtInp">
                <InputForm
                  value={searchText}
                  placeholder={"Search Keyword"}
                  isDisabled={false}
                  textArea={false}
                  onChange={(e) => {
                    //setPage(0);
                    setDashboardStart(0);
                    setPage(0);
                    setSearchText(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="form-group filtInp">
                <SelectForm
                  isClearable
                  options={statusOptions}
                  placeholder={"Select Status"}
                  isDisabled={false}
                  onChange={(event) => {
                    setDashboardStart(0);
                    setPage(0);
                    setStatus(event);
                  }}
                  isMulti={false}
                  noIndicator={false}
                  noSeparator={false}
                  value={status}
                />
              </div>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-12 filtrt">
              <ul className=" filter-icon-wel">
                <li>
                  <a
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                    className="filter-btn"
                  >
                    <i className="fa fa-filter"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <>
          <Collapse in={open}>
            <div id="example-collapse-text grid-wrapper">
              <div className="row mx-auto myInlineStyle">
                <>
                  <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <div className="form-group filtInp">
                      <label className="col-form-label text-white">
                        Business
                      </label>
                      <SelectForm
                        isClearable
                        options={options.business}
                        placeholder={"Select Business"}
                        isDisabled={false}
                        onChange={async (e) => {
                          formOnChange(e, "business");
                        }}
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                        value={formData?.business}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <div className="form-group filtInp">
                      <label className="col-form-label text-white">SBU</label>
                      <SelectForm
                        isClearable
                        options={options.sbu}
                        placeholder={"Select SBU"}
                        isDisabled={false}
                        onChange={async (e) => {
                          formOnChange(e, "sbu");
                        }}
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                        value={formData?.sbu}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <div className="form-group filtInp">
                      <label className="col-form-label text-white">
                        Legal Entity
                      </label>
                      <SelectForm
                        isClearable
                        options={options.legalEntity}
                        placeholder={"Select Legal Entity"}
                        isDisabled={false}
                        onChange={async (e) => {
                          formOnChange(e, "legalEntity");
                        }}
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                        value={formData?.legalEntity}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <div className="form-group filtInp">
                      <label className="col-form-label text-white">
                        Custom Business
                      </label>
                      <SelectForm
                        isClearable
                        options={options.customBusiness}
                        placeholder={"Select Custom Business"}
                        isDisabled={false}
                        onChange={async (e) => {
                          formOnChange(e, "customBusiness");
                        }}
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                        value={formData?.customBusiness}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <div className="form-group filtInp">
                      <label className="col-form-label text-white">
                        Physical Location
                      </label>
                      <SelectForm
                        isClearable
                        options={options.physicalLocation}
                        placeholder={"Select Physical Location"}
                        isDisabled={false}
                        onChange={async (e) => {
                          formOnChange(e, "physicalLocation");
                        }}
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                        value={formData?.physicalLocation}
                      />
                    </div>
                  </div>
                </>
                <div className="col-lg-12 d-flex mt-2 justify-content-end">
                  <button
                    className="btn btn-secondary mr-2"
                    onClick={onClickReset}
                  >
                    <i className="fa fa-undo" aria-hidden="true"></i> Reset
                  </button>
                  <button
                    className="btn btn-filter-submit"
                    onClick={() => {
                      setDashboardStart(0);
                      setPage(0);
                      onSubmitFilter();
                    }}
                  >
                    <i className="fa fa-search" aria-hidden="true"></i> Search{" "}
                  </button>
                </div>
              </div>
            </div>
          </Collapse>
        </>

        <div className="mb-3 pt-3">
          <DynamicGrid
            data={dashboardData}
            columns={gridColumns}
            options={dashboardOptions}
          />
        </div>
      </div>
    </>
  );
};

export default EmployeeList;
