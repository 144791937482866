import React, { useContext, useEffect, useState } from "react";
import { LoaderContext } from "../../Helpers/Context/Context";
import { useNavigate } from "react-router-dom";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { Collapse, Modal } from "react-bootstrap";
import notify from "../../Helpers/ToastNotification";
import InputForm from "../../Components/InputForm/InputForm";
import SelectForm from "../../Components/SelectForm/SelectForm";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import {
  domainDasboardActions,
  getDomainDasboardDetails,
  getDomainDasboardFilterDetails,
  getLMSDomainDropDownData,
  getUserAndRoleWiseAccess,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import { APICall } from "../../Helpers/APICalls";
import { Tooltip } from "@mui/material";

const DomainDashboard = () => {
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  let { userDetails, currentRoleId }: any = useUserContext();
  const [sortDirection, setSortDirection] = useState("");
  const [sortColumn, setSortColumn] = useState("");

  const [reSet, setreSet] = useState(false);

  const [domain, setDomain] = useState([]);
  const [domainOptions, setDomainOptions] = useState([]);

  const [createdBy, setCreatedBy] = useState([]);
  const [createdByOptions, setCreatedByOptions] = useState([]);

  const [statusOptions, setStatusOptions] = useState([
    { value: 1, label: "Active" },
    { value: 0, label: "Inactive" },
  ]);

  const [status, setStatus] = useState(null);

  const [remarks, setRemarks] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [id, setId] = useState(null);
  const [IsActive, setIsActive] = useState(false);

  interface userAndRoleWiseAccess {
    isView?: boolean;
    isEdit?: boolean;
    isDelete?: boolean;
  }

  const [userAndRoleWiseData, setUserAndRoleWiseData] =
    useState<userAndRoleWiseAccess>({});

  const getUserAndRoleWiseAccessApiCall = async () => {
    const response = await APICall(getUserAndRoleWiseAccess, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      InterfaceName: "Domain_Dashboard",
    });

    if (response?.status === 0) {
      setUserAndRoleWiseData(response?.data);
    } else {
      notify(response?.status, response?.message);
    }
  };

  useEffect(() => {
    const responseData = async () => {
      // const objDomain = {
      //   mode: "DOMAINNAME",
      //   userId: userDetails?.Id,
      //   roleId: currentRoleId?.value,
      // };
      // const res = await APICall(
      //   getDomainDasboardFilterDetails,
      //   "POST",
      //   objDomain
      // );
      // if (res.data && res.data.length > 0) {
      //   setDomainOptions(res.data);
      // }

      const response = await APICall(getLMSDomainDropDownData, "POST", {
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
        GetAll: true,
      });

      if (response?.status === 0 && response?.data.length > 0) {
        setDomainOptions(response?.data);
      }

      const objCreatedBy = {
        mode: "CREATEDBY",
        userId: userDetails?.Id,
        roleId: currentRoleId?.value,
      };
      const res2 = await APICall(
        getDomainDasboardFilterDetails,
        "POST",
        objCreatedBy
      );
      if (res2.data && res2.data.length > 0) {
        setCreatedByOptions(res2.data);
      }
    };
    responseData();
    getUserAndRoleWiseAccessApiCall();
  }, []);

  useEffect(() => {
    getDomainDasboardDetailsAPICall();
  }, [page, pageSize, sortColumn, sortDirection, searchText, reSet]);

  const getDomainDasboardDetailsAPICall = async () => {
    showLoader();
    const GetDashboardData = await APICall(getDomainDasboardDetails, "POST", {
      Page: page,
      PageSize: pageSize,
      SortColumn: sortColumn,
      SortOrder: sortDirection,
      SearchText: searchText,
      DomainIds: domain?.map((s) => s.value).join(","),
      DomainCreatedBy: createdBy?.map((s) => s.value).join(","),
      DomainIsActive: status?.value,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });
    if (
      GetDashboardData &&
      GetDashboardData.data !== null &&
      GetDashboardData.data.length > 0
    ) {
      setCount(GetDashboardData.data[0].count);

      setRowData(GetDashboardData.data);
    } else {
      setRowData([]);
      setCount(0);
    }
    hideLoader();
  };

  const activateandDeactivateFunction = async (id: any, isActive: any) => {
    if (remarks === "" || remarks === null) {
      notify(1, "Please enter remarks");
    } else {
      showLoader();
      const { data } = await APICall(domainDasboardActions, "POST", {
        Id: id,
        IsActive: isActive,
        Remarks: remarks,
        CreatedBy: userDetails?.Id,
      });
      setShowModal(false);
      setRemarks("");
      if (data != null) {
        if (!isActive) {
          notify(0, "Domain has been successfully Activated");
        } else {
          notify(0, "Domain has been successfully Deactivated");
        }
        setreSet(!reSet);
        hideLoader();
      } else {
        notify(1, "Something went wrong");
      }
      hideLoader();
    }
  };

  const showActivateandDeactivateModal = async (id: any, isActive: any) => {
    setShowModal(true);
    setId(id);
    if (isActive) {
      setModalTitle("Do you want to Inactive the Session?");
    } else {
      setModalTitle("Do you want to active the Session?");
    }
    setIsActive(isActive);
  };

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: pageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      setPage(page * pageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const gridColumns = [
    {
      name: "Id",
      label: "Id",
      options: {
        display: false,
      },
    },
    {
      name: "domainCode",
      label: "Domain ID",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "domainName",
      label: "Domain Name",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "createdByName",
      label: "Created By",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "createdOnDate",
      label: "Created On",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta) => {
          let id = tableMeta.tableData[tableMeta.rowIndex].id;
          let isActive = tableMeta.tableData[tableMeta.rowIndex].isActive;
          let isPublic = tableMeta.tableData[tableMeta.rowIndex].isPublic;
          let color = isActive ? "green" : "red";
          let activate = isActive ? "Active" : "Inactive";

          // if (!isPublic) {

          // }
          return (
            <div style={{ width: "100px" }}>
              {userAndRoleWiseData?.isEdit && (
                <>
                  <Tooltip title="Edit">
                    <a
                      className="mr-2"
                      style={{ fontSize: "15px" }}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/domain", {
                          state: { domainId: value, isDisable: false },
                        });
                      }}>
                      <i className="fas fa-edit"></i>
                    </a>
                  </Tooltip>
                  <Tooltip title={activate}>
                    <a
                      className="mr-2"
                      style={{ fontSize: "15px" }}
                      onClick={() => {
                        showActivateandDeactivateModal(id, isActive);
                      }}>
                      <i
                        className="fa-solid fa-circle"
                        style={{ color: color }}></i>
                    </a>
                  </Tooltip>
                </>
              )}
              {!userAndRoleWiseData?.isEdit && userAndRoleWiseData?.isView && (
                <Tooltip title="View">
                  <a
                    className="mr-2"
                    style={{ fontSize: "15px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/domain", {
                        state: { domainId: value, isDisable: true },
                      });
                    }}>
                    <i className="fas fa-eye"></i>
                  </a>
                </Tooltip>
              )}
            </div>
          );
        },
      },
    },
  ];

  const onSubmitFilter = () => {
    if (domain?.length === 0 && createdBy?.length === 0 && status === null) {
      notify(1, "Select atleast one filter");
    } else {
      setPage(0);
      setreSet(!reSet);
    }
  };

  const resetFilters = () => {
    setSearchText("");
    setPage(0);
    setDomain([]);
    setCreatedBy([]);
    setStatus(null);

    setreSet(!reSet);
  };
  return (
    <>
      <div className="breadcrumb modified-bredcrumb">
        <span>
          <ul>
            <li>
              <a href="/home">Home</a>
            </li>

            <li>Domain</li>
          </ul>
        </span>
        {userAndRoleWiseData?.isEdit && (
          <button
            onClick={() => {
              navigate("/domain", { state: { domainId: 0 } });
            }}
            className="btn btn-primary mt-3">
            <i className="fa fa-plus mr-2"></i>
            Create Domain
          </button>
        )}
      </div>
      <div className="container-fluid">
        <div className="grid-wrapper grid_mob">
          <div className="row mx-auto filtBox">
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="form-group filtInp">
                <InputForm
                  value={searchText}
                  placeholder={"Search Keywords"}
                  isDisabled={false}
                  textArea={false}
                  onChange={(e) => {
                    setPage(0);
                    setSearchText(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-12 filtrt">
              <ul className=" filter-icon-wel">
                <li>
                  <a
                    href="javascript:void(0);"
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                    className="filter-btn">
                    <i className="fa fa-filter"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <>
          <Collapse in={open}>
            <div id="example-collapse-text grid-wrapper">
              <div className="row mx-auto myInlineStyle">
                <>
                  <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">Domain</label>
                    <SelectForm
                      isClearable
                      options={domainOptions}
                      isOptionDisabled={(option) => !option?.isEnabled}
                      placeholder={"Domain"}
                      isDisabled={false}
                      onChange={(event) => {
                        setDomain(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={domain}
                    />
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">
                      Created By
                    </label>
                    <SelectForm
                      isClearable
                      options={createdByOptions}
                      placeholder={"Created By"}
                      isDisabled={false}
                      onChange={(event) => {
                        setCreatedBy(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={createdBy}
                    />
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">Status</label>
                    <SelectForm
                      isClearable
                      options={statusOptions}
                      placeholder={"Status"}
                      isDisabled={false}
                      onChange={(event) => {
                        setStatus(event);
                      }}
                      isMulti={false}
                      noIndicator={false}
                      noSeparator={false}
                      value={status}
                    />
                  </div>
                </>
                <div className="col-lg-12 d-flex mt-2 justify-content-end">
                  <button
                    onClick={() => resetFilters()}
                    className="btn btn-secondary mr-2">
                    <i className="fa fa-undo" aria-hidden="true"></i> Reset{" "}
                  </button>
                  <button
                    className="btn btn-filter-submit"
                    onClick={() => onSubmitFilter()}>
                    <i className="fa fa-search" aria-hidden="true"></i> Search{" "}
                  </button>
                </div>
              </div>
            </div>
          </Collapse>
        </>
        {}
        <div className="mb-3 pt-3">
          <DynamicGrid data={rowData} columns={gridColumns} options={options} />
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">{`${modalTitle}`}</div>
        </div>
        <Modal.Body>
          <>
            <div className="row">
              <div className="col-md-8">
                <label className="col-form-label">Remarks</label>
                <sup>*</sup>
                <InputForm
                  value={remarks}
                  placeholder={"Remarks"}
                  isDisabled={false}
                  textArea={true}
                  onChange={(e) => {
                    setRemarks(e.target.value);
                  }}
                />
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => activateandDeactivateFunction(id, IsActive)}>
            Submit
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowModal(false);
            }}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DomainDashboard;
