// @ts-nocheck
import "./style1.css";
import { useEffect, useState } from "react";
import QrReader from "react-qr-reader";

function QR(props: any) {
  const [selected, setSelected] = useState("environment");
  const [startScan, setStartScan] = useState(props.startScanOps);
  const [loadingScan, setLoadingScan] = useState(false);
  const [data, setData] = useState("");

  const handleScan = async (scanData) => {
    setLoadingScan(true);

    if (scanData && scanData !== "") {
      await props.onScan(scanData);
      await setData(scanData);
      await setStartScan(false);
      await setLoadingScan(false);
    }
  };

  useEffect(() => {
    setStartScan(props.startScanOps);
  }, [props.startScanOps]);
  const handleError = (err) => {
    console.error(err);
  };
  return (
    <div className="">
      {/* <button
        onClick={() => {
          setStartScan(!startScan);
        }}>
        {startScan ? "Stop Scan" : "Start Scan"}
      </button> */}
      {startScan && (
        <>
          {/* <select onChange={(e) => setSelected(e.target.value)}>
            <option value={"environment"}>Back Camera</option>
            <option value={"user"}>Front Camera</option>
          </select> */}
          <QrReader
            facingMode={selected}
            delay={1000}
            onError={handleError}
            onScan={handleScan}
            // chooseDeviceId={()=>selected}
            style={{ width: "300px" }}
          />
        </>
      )}
      {loadingScan && <p>Please wait...</p>}
      {/* {data !== "" && <p>{data}</p>} */}
    </div>
  );
}

export default QR;
