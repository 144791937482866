import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import { useState } from "react";
import { Modal, OffcanvasBody, Table } from "react-bootstrap";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  TextField,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import SelectForm from "../../../Components/SelectForm/SelectForm";

//# INTERFACES ---------------------------------------

export interface IMRFApproverMappingData {
  Accordian?: boolean;
  Acknowledgement?: boolean;
  ApproverId?: number;
  ApproverName?: string;
  Date?: string;
  Decision?: any;
  DecisionId?: number;
  Id?: number;
  InitiatorId?: number;
  InitiatorName?: string;
  IsDisabled?: boolean;
  LastSequence?: number;
  MRFApproverDetailsId: number;
  PreviousRemarks?: string;
  Recruiter?: any;
  RecruiterId?: any[];
  RecruiterName?: string;
  Remarks?: string;
  RequestId?: number;
  RoleId?: number;
  RoleName?: string;
  SequenceNo?: number;
  WorkflowId?: number;
  WorkflowTaskId?: number;
  WorkflowTaskStatus?: number | null;
  IsSendback?: boolean;
  IntiatorId?: number;
}

export interface IJobPosting {
  RecruiterId?: number;
  RecruiterName?: string;
  PostingId?: string;
  PostingData?: any;
  PostingFromDate?: string;
  PostingTillDate?: string;
  RecruiterAgencyData?: any[] | null;
  IsDisabled?: boolean;
  RequestId?: number;
  RecruiterSelectedOption?: [];
  LegalEntity: any[] | null;
  //JobLevel: any[] | null;
  PayrollLocation: any[] | null;
  JobBoard: any[];
  LinkedInCommonHashtag?: any[];
  RecruiterHashTag?: string;
}

export interface INaukriInterface {
  JobTitle?: string;
  JobType?: any;
  JobDescription?: string;
  Industry?: any;
  FunctionalArea?: any;
  CompensationMax?: number | string;
  CompensationMin?: number | string;
  ShowSalary?: any;
  NotifyEmail?: any;
  EmploymentType?: any;
  OrgName?: any;
  Website?: any;
  KeySkills?: any;
  MinWorkExperience?: any;
  MaxWorkExperience?: any;
  Locations?: any;
  Questions?: string;
  SalaryCurreny?: any;
  Diversity?: string | any;
  Workmode?: string | any;
  PhysicalLocations?: string | any;
  Degree?: string | any;
  Qualification?: string | any;
  Specialization?: string | any;
  JobUrl?: string | null;
  EducationData?: any[];
}

export interface INaukriDropdownOptions {
  JobTypeOptions?: any[];
  IndustryOptions?: any[];
  FunctionalAreaOptions?: any[];
  ShowSalaryOptions?: any[];
  SalaryCurrenyOptions?: any[];
  DegreeOptions?: any[];
  QualificationOptions?: any[];
  SpecializationOptions?: any[];
  DiversityOptions?: any[];
  WorkmodeOptions?: any[];
  EmploymentTypeOptions?: any[];
  OrgNameOptions?: any[];
  WebsiteOptions?: any[];
  KeySkillsOptions?: any[];
  PhysicalLocationOptions?: any[];
}

export interface INaukriEducationDetails {
  Id?: number;
  Qualification?: string;
}

// ---------------------------------------------------

//# ENUMS -------------------------------------------------------
export enum MRFStatus {
  Open = "open",
  PendingApproval = "pending approval",
}

export const DECISION = [
  { value: 1, label: "Approved" },
  { value: 2, label: "Rejected" },
  { value: 3, label: "Sendback" },
];

export enum WorkflowTaskStatus {
  Pending = 1,
  Completed = 2,
}

export enum DecisionEnum {
  Approved = 1,
  Rejected = 2,
  Sendback = 3,
  Initiated = 4,
  ReInitiated = 5,
}

export enum PostingType {
  WeRise = "CODEPOSOP1",
  CorporateWebsite = "CODEPOSOP2",
  JobBoard = "CODEPOSOP3",
  Agency = "CODEPOSOP4",
  Confidential = "CODEPOSOP5",
}

export enum JobBoardPosting {
  Naukri = "Naukri",
  LinkedIn = "linkedin",
}

export enum JobPostingType {
  Naukri = 1,
}

// --------------------------------------------------------------------------------

const onlyNumbericRegex = /^\d*\.?\d{0,2}$/;
//# COMMON FUNCTIONS
export function isEmpty(value, acceptZero = false) {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value === "object") {
    // Check for empty object
    if (Object.keys(value).length === 0) {
      return true;
    }
    // Check for empty array
    if (Array.isArray(value) && value.length === 0) {
      return true;
    }
  }

  if (typeof value === "string") {
    // Check for empty string after trimming
    if (value.trim() === "" || (value.trim() === "0" && !acceptZero)) {
      return true;
    }
  }

  if (typeof value === "number") {
    // Check for zero or NaN
    if ((value === 0 && !acceptZero) || Number.isNaN(value)) {
      return true;
    }
  }

  return false;
}

export function isInvalidDate(date) {
  if (date === null || date === undefined || date === "") {
    return true;
  }
  const momentDate = moment(date).format("DD-MM-YYYY");
  return !moment(momentDate, "DD-MM-YYYY").isValid();
}

export function isStartDateGreaterThanEndDate(startDate, endDate) {
  let fromDate = moment(startDate).format("DD-MM-YYYY");
  let tillDate = moment(endDate).format("DD-MM-YYYY");

  // let fromDate = moment(jobPostingData.PostingFromDate);
  // let tillDate = moment(jobPostingData.PostingTillDate);
  // if (!fromDate.isSameOrBefore(tillDate)) {
  //   ErrorObj["error_JobPostingTillDate"] =
  //     "Posting Till Date cannot be less than Posting From Date";
  // } else {
  //   delete ErrorObj["error_JobPostingTillDate"];
  // }
  const bool = moment(fromDate, "DD-MM-YYYY").isSameOrBefore(
    moment(tillDate, "DD-MM-YYYY")
  );
  return !bool;
}

// ------------------------------------------------------------------------------------

//# Common Componenets --------------------------------------------------

export const ApproverDateForm = ({
  value,
  disabled,
  onChange,
  ...othersProps
}) => {
  const DateFormStyle = {
    sx: {
      "&.Mui-disabled": {
        backgroundColor: "#e9ecef",
        borderColor: "#e9ecef",
        outline: "none",
        opacity: "1",
      },
    },
  };

  const DateFormTextFieldStyle = {
    svg: {
      color: "#00ABE6",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        fontFamily: "Segoe UI",
        boxShadow: "none",
        outline: "none",
      },
      "&:hover fieldset": {
        borderColor: "hsl(0, 0%, 70%)",
        boxShadow: "none",
        outline: "none",
      },
      "&.Mui-focused fieldset": {
        borderColor: "hsl(0, 0%, 70%)",
        boxShadow: "none",
        outline: "none",
      },
    },
  };
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          value={value}
          disabled={disabled}
          className="w-100 bg-white date_icon"
          InputProps={DateFormStyle}
          onChange={onChange}
          inputFormat="dd-MMM-yyyy"
          renderInput={(params) => (
            <TextField
              size="small"
              name=""
              autoComplete="off"
              id="date-input"
              sx={DateFormTextFieldStyle}
              {...params}
            />
          )}
          {...othersProps}
        />
      </LocalizationProvider>
    </>
  );
};

export const ConfirmationModal = ({
  modal,
  setModal,
  message,
  confirmCallback,
  noCallback,
  hideConfirmMation = false,
}) => {
  return (
    <>
      <Modal
        show={modal}
        onHide={() => setModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">Confirm!</div>
        </div>
        <Modal.Body>
          <h5>{message}</h5>
        </Modal.Body>
        <Modal.Footer>
          {hideConfirmMation ? (
            <>
              <button
                className="btn"
                style={{
                  marginLeft: 5,
                  color: "black !important",
                  borderColor: "#acacac",
                }}
                onClick={confirmCallback}
              >
                Ok
              </button>
            </>
          ) : (
            <>
              <button
                className="btn"
                style={{
                  marginLeft: 5,
                  color: "black !important",
                  borderColor: "#acacac",
                }}
                onClick={confirmCallback}
              >
                Yes
              </button>
              <button
                className="btn btn-cancel"
                style={{
                  marginLeft: 5,
                  color: "black !important",
                  borderColor: "#ACACAC",
                }}
                onClick={noCallback}
              >
                No
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const CustomApproverAccordian = ({
  header,
  disabled,
  submitCallback,
  clearFunc,
  defaultAccordianValue = false,
  children,
  hideBtn = false,
}) => {
  const [accordion, setAccordian] = useState(defaultAccordianValue);
  return (
    <>
      <>
        <Accordion
          elevation={0}
          className="mb-3"
          expanded={accordion}
          onChange={() => {
            setAccordian((prev) => !prev);
          }}
        >
          <AccordionSummary
            id="profile-accordion"
            style={{ background: "#3C5464" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}
          >
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <p style={{ fontSize: "16px" }}>{header}</p>
              </div>
              <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12">
                {!hideBtn && (
                  <>
                    <Tooltip title="clear">
                      <button
                        disabled={disabled}
                        className="btn ml-lg-0 ml-md-0 ml-1"
                        onClick={clearFunc}
                      >
                        <i className="fas fa-times-circle"></i>
                      </button>
                    </Tooltip>
                    <Tooltip title="submit">
                      <button
                        className="btn"
                        disabled={disabled}
                        onClick={submitCallback}
                      >
                        <i className="fa fa-save"></i>
                      </button>
                    </Tooltip>
                  </>
                )}
              </div>
            </div>
          </AccordionSummary>

          <AccordionDetails className="page_heading">
            {children}
          </AccordionDetails>
        </Accordion>
      </>
    </>
  );
};

export const Dropdown = ({
  label,
  tooltipMessage = "",
  options,
  placeholder,
  isDisabled,
  onChange,
  value,
  errorMessage,
  isMulti = false,
  isRequired = false,
  isTooltipRequired = false,
}) => {
  return (
    <>
      <div className="dropdown mb-1">
        <label className="col-form-label">{label}</label>
        {isRequired && (
          <>
            <sup>*</sup>
          </>
        )}
        {isTooltipRequired && (
          <Tooltip title={tooltipMessage || ""} className="ml-1">
            <span>
              <i className="fas fa-info-circle" />
            </span>
          </Tooltip>
        )}
        <SelectForm
          isClearable
          isSearchable
          options={options}
          placeholder={placeholder}
          isDisabled={isDisabled}
          value={value}
          onChange={onChange}
          isMulti={isMulti}
          noIndicator={false}
          noSeparator={false}
        />
        <span style={{ color: "red" }}>{errorMessage || ""}</span>

        {isMulti && value?.length > 1 && (
          <div className="dropdown-content">
            {value?.map((i, j) => (
              <p key={j}>{i?.label || ""}</p>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

// ----------------------------------
const NAUKRI_REQUIRED_FIELDS = [
  { field: "JobTitle", message: "JobTitle required." },
  { field: "Industry", message: "Industry required." },
  { field: "CompensationMax", message: "Compensation max required." },
  { field: "CompensationMin", message: "Compensation min required." },
  { field: "ShowSalary", message: "ShowSalary required." },
  { field: "EmploymentType", message: "Employment Type required." },
  { field: "OrgName", message: "OrgName required." },
  {
    field: "MinWorkExperience",
    message: "Min Work Experience required.",
  },
  {
    field: "MaxWorkExperience",
    message: "Max Work Experience required.",
  },
  { field: "PhysicalLocations", message: "Physical Locations required." },
  { field: "JobDescription", message: "Job Description required." },
  { field: "Workmode", message: "Workmode required." },
  { field: "SalaryCurreny", message: "Salary Currency required." },
];

export function NaurkiFieldsValidation(data, setFormErrors) {
  try {
    const errorObj = {};

    //# Checking Mandatory Fields
    NAUKRI_REQUIRED_FIELDS.forEach(({ field, message }) => {
      if (field) {
      } else {
      }
      if (isEmpty(data[field], field == "MinWorkExperience")) {
        errorObj[field] = message;
      }
    });

    if (isEmpty(data.EducationData)) {
      errorObj["Degree"] = "Education Required.";
    }

    //# Other validations
    if (
      !isEmpty(Number(data.CompensationMax)) &&
      !isEmpty(Number(data.CompensationMin)) &&
      Number(data.CompensationMin) > Number(data.CompensationMax)
    ) {
      errorObj["CompensationMin"] =
        "Compenstation Min cannot be greater than Compenstation Max.";
    }

    if (
      !isEmpty(Number(data.MinWorkExperience)) &&
      !isEmpty(Number(data.MaxWorkExperience)) &&
      Number(data.MinWorkExperience) > Number(data.MaxWorkExperience)
    ) {
      errorObj["MinWorkExperience"] =
        "Minimum work experience cannot be greater than Maximum work experience.";
    }

    const domain = "welspun.com";
    const emailRegex = new RegExp(
      `^[\\w-]+(\\.[\\w-]+)*@${domain.replace(/\./g, "\\.")}$`,
      "i"
    );

    if (!isEmpty(data?.NotifyEmail) && !emailRegex.test(data?.NotifyEmail)) {
      errorObj["NotifyEmail"] = "Invalid Email Id";
    }

    setFormErrors((error) => {
      return { ...errorObj };
    });

    const isError = Object.keys(errorObj).length !== 0;
    if (isError) {
      return true;
    }

    //# Naukri Api Validations

    if (!isEmpty(data?.JobTitle) && String(data?.JobTitle).length > 70) {
      errorObj["JobTitle"] = "Job Title cannot greater than 70 characters";
    }

    if (
      !isEmpty(data?.JobDescription) &&
      String(data?.JobDescription).length > 30000
    ) {
      errorObj["JobDescription"] =
        "Job Description cannot greater than 30000 characters";
    }

    if (!isEmpty(data?.Diversity) && data?.Diversity.length > 2) {
      errorObj["Diversity"] = "Select maximum 2 diversity allowed";
    }

    if (
      !isEmpty(data.OrgName) &&
      !isEmpty(data?.OrgName?.label) &&
      String(data?.OrgName?.label).length > 100
    ) {
      errorObj["OrgName"] = "Select Org Name within 100 characthers.";
    }

    if (
      !isEmpty(data.Website) &&
      !isEmpty(data?.Website?.label) &&
      String(data?.Website?.label).length > 75
    ) {
      errorObj["Website"] = "Select Website within 75 characthers.";
    }

    if (!isEmpty(data?.KeySkills)) {
      const skills = data?.KeySkills?.map((i) => i?.label || "")?.join(",");
      if (String(skills).length > 250) {
        errorObj["KeySkills"] =
          "Select Keys skills which total of skills characther length is within 250 characthers.";
      }
    }

    if (
      !isEmpty(data?.PhysicalLocations) &&
      data?.PhysicalLocations?.length > 3
    ) {
      errorObj["PhysicalLocations"] = "Max 3 Locations are allowed";
    }

    if (
      !isEmpty(data?.MaxWorkExperience) &&
      Number(data?.MaxWorkExperience) > 30
    ) {
      errorObj["MaxWorkExperience"] = "Max 30 is allowed.";
    }

    if (
      !isEmpty(data?.MinWorkExperience) &&
      Number(data?.MinWorkExperience) > 30
    ) {
      errorObj["MinWorkExperience"] = "Max 30 is allowed.";
    }

    if (
      !isEmpty(Number(data.CompensationMin)) &&
      !isEmpty(data?.SalaryCurreny)
    ) {
      if (
        data?.SalaryCurreny?.label?.toLocaleLowerCase() == "inr" &&
        Number(data.CompensationMin) < 50000
      ) {
        errorObj["CompensationMin"] =
          "Compenstation Min must be greater than or equal to 50,000 rupees.";
      } else if (
        data?.SalaryCurreny?.label?.toLocaleLowerCase() == "usd" &&
        Number(data.CompensationMin) < 5000
      ) {
        errorObj["CompensationMin"] =
          "Compenstation Min must be greater than or equal to 5,000 dollars.";
      }
    }

    setFormErrors((error) => {
      return { ...errorObj };
    });
    return !(Object.keys(errorObj).length === 0);
  } catch (error) {
    throw new Error(error);
  }
}
