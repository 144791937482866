import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoaderContext } from "../../../Helpers/Context/Context";
import useUserContext from "../../../Helpers/ZustandStore/UserContextStore";
import { Tooltip } from "@mui/material";
import DynamicGrid from "../../../Components/DynamicGrid/DynamicGrid";
import SelectForm from "../../../Components/SelectForm/SelectForm";
import InputForm from "../../../Components/InputForm/InputForm";
import { APICall } from "../../../Helpers/APICalls";
import {
  DeleteCostExpenseData,
  GetCostExpenseDashboardData,
  SaveCostExpenseData,
  getCostAllotmentDropDownData,
} from "../../../Helpers/APIEndPoints/LMS_EndPoints";
import notify from "../../../Helpers/ToastNotification";
import { Modal } from "react-bootstrap";

interface IOptions {
  label?: string;
  value?: number | string;
  code?: string;
}

interface IFormData {
  expenses?: IOptions | any;
  cost?: Number | any;
}

const CourseGrid = ({ costAllotmentId, gridReset, setGridReset }) => {
  const { state } = useLocation();

  const [isDisable, setIsDisable] = useState(
    state && state?.isDisable ? true : false
  );

  const { showLoader, hideLoader } = useContext(LoaderContext);

  const { userDetails, currentRoleId, proxyUserDetails }: any =
    useUserContext();

  const navigate = useNavigate();
  const [gridData, setGridData] = useState([]);
  const [count, setCount] = useState(0);

  const [formData, setFormData] = useState<IFormData>({});
  const [formErrors, setFormErrors] = useState<IFormData>({});
  const [formOptions, setFormOptions] = useState<IFormData>({});

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [costExpenseId, setCostExpenseId] = useState(0);

  useEffect(() => {
    const responseData = async () => {
      //EXPENSES
      const res = await APICall(getCostAllotmentDropDownData, "POST", {
        Mode: "EXPENSES",
        UserId: userDetails?.id,
        RoleId: currentRoleId?.value,
      });
      if (res.data && res.data.length > 0) {
        setFormOptions((prev) => {
          return {
            ...prev,
            expenses: res.data,
          };
        });
      }
    };

    showLoader();

    responseData();

    hideLoader();
  }, []);

  useEffect(() => {
    GetCostExpenseDashboardDataApiCall();
  }, [gridReset]);

  const GetCostExpenseDashboardDataApiCall = async () => {
    showLoader();
    const parameters = {
      Mode: "COURSECOSTEXPENSE",
      CourseId: state?.courseId,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    };

    const responseData = await APICall(
      GetCostExpenseDashboardData,
      "POST",
      parameters
    );

    if (responseData?.status === 0) {
      setGridData(responseData?.data);
      setCount(responseData?.data?.length);
    } else if (responseData?.status === 1) {
      notify(responseData?.status, responseData?.message);
    }

    hideLoader();
  };

  const formOnChange = (e, type) => {
    switch (type) {
      case "expenses":
        setFormData({
          ...formData,
          expenses: e,
        });
        break;

      case "cost":
        setFormData({
          ...formData,
          cost: e.target.value,
        });
        break;

      default:
        break;
    }
  };

  const validateForm = () => {
    let errorObj = {};
    let isError = false;

    setFormErrors({});

    if (formData?.expenses === null || formData?.expenses === undefined) {
      errorObj = {
        ...errorObj,
        ["expenses"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["expenses"]: "",
      };
    }

    if (
      formData?.cost === null ||
      formData?.cost === undefined ||
      formData?.cost === ""
    ) {
      errorObj = {
        ...errorObj,
        ["cost"]: "Required",
      };
    } else if (!/^([0-9]+|0)$/.test(formData?.cost)) {
      errorObj = {
        ...errorObj,
        ["cost"]: "Only numbers are allowed",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["cost"]: "",
      };
    }

    const isEmpty = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  const handleSubmitFunction = async () => {
    showLoader();

    if (validateForm()) {
      hideLoader();
      return;
    }

    if (costAllotmentId > 0) {
      const parameters = {
        CourseId: state?.courseId,
        CostAllotmentId: costAllotmentId,
        ExpenseId: formData?.expenses?.value,
        ExpenseCost: formData?.cost,
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      };

      const responseData = await APICall(
        SaveCostExpenseData,
        "POST",
        parameters
      );

      if (responseData?.status === 0) {
        notify(responseData?.status, responseData?.message);
        setGridReset(!gridReset);

        setFormData((env) => {
          return {
            ...env,
            expenses: null,
            cost: "",
          };
        });
      } else if (responseData?.status === 1) {
        notify(responseData?.status, responseData?.message);
      }
    } else {
      notify(1, "Please save data for cost allotment level");
    }

    hideLoader();
  };

  const DeleteCostExpenseDataApiCall = async (costExpenseId) => {
    showLoader();

    const parameters = {
      CourseId: state?.courseId,
      CostExpenseId: costExpenseId,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    };

    const responseData = await APICall(
      DeleteCostExpenseData,
      "POST",
      parameters
    );

    if (responseData?.status === 0) {
      notify(responseData?.status, responseData?.message);
      setGridReset(!gridReset);
      setShowDeleteModal(false);
    } else if (responseData?.status === 1) {
      notify(responseData?.status, responseData?.message);
    }

    hideLoader();
  };

  const gridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: count,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        // await setSortColumn(sortColumn);
        // await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        // await setSortColumn(sortColumn);
        // await setSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      //   setPage(page * PageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };
  const gridColumns = [
    {
      name: "Id",
      label: "Id",
      options: {
        display: false,
      },
    },

    {
      name: "expenseName",
      label: "Expenses",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "expenseCost",
      label: "Cost",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "Id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta) => {
          let costExpenseId =
            tableMeta.tableData[tableMeta.rowIndex].costExpenseId;

          return (
            <div className="d-flex justify-content-center">
              {!isDisable && (
                <>
                  <Tooltip title="delete">
                    <a
                      className="mr-2"
                      onClick={(e) => {
                        e.preventDefault();
                        handleDeleteCostExpenseModel(costExpenseId);
                      }}
                    >
                      <i className="fa fa-trash" aria-hidden="true"></i>
                    </a>
                  </Tooltip>
                </>
              )}
            </div>
          );
        },
      },
    },
  ];

  const handleDeleteCostExpenseModel = (costExpenseId) => {
    setCostExpenseId(costExpenseId);
    setShowDeleteModal(true);
  };

  return (
    <>
      <div className="container-fluid">
        <h6 className="">Cost</h6>
        {!isDisable && (
          <>
            <div className="row">
              <div className={"col-lg-3 col-sm-4 col-xs-6"}>
                <div className="dropdown mb-1">
                  <label className="col-form-label">Expenses</label>{" "}
                  <sup>*</sup>
                  <SelectForm
                    isClearable
                    isSearchable
                    options={formOptions?.expenses}
                    placeholder={"Expenses"}
                    isDisabled={isDisable}
                    value={formData?.expenses}
                    onChange={(e) => {
                      formOnChange(e, "expenses");
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  {formErrors["expenses"] && (
                    <p style={{ color: "red" }}>{formErrors["expenses"]}</p>
                  )}
                </div>
              </div>

              <div className={"col-lg-3 col-sm-4 col-xs-6"}>
                <div className="mb-1">
                  <label className="col-form-label"></label> <sup></sup>
                  <InputForm
                    className="form-control mt-3"
                    placeholder={""}
                    isDisabled={isDisable}
                    textArea={false}
                    value={formData?.cost}
                    onChange={(e) => {
                      formOnChange(e, "cost");
                    }}
                    maxLength={6}
                    type="number"
                  />
                  {formErrors["cost"] && (
                    <p style={{ color: "red" }}>{formErrors["cost"]}</p>
                  )}
                </div>
              </div>

              <div className={"col-lg-3 col-sm-4 col-xs-6"}>
                <div className="mb-1">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmitFunction();
                    }}
                    disabled={isDisable}
                    className="btn btn-filter-submit"
                    style={{ marginTop: "33px" }}
                  >
                    <i className="fa fa-plus mr-2"></i>
                    Add
                  </button>
                </div>
              </div>
            </div>
          </>
        )}

        <br />
        <DynamicGrid
          data={gridData}
          columns={gridColumns}
          options={gridOptions}
        />
        <br />
      </div>

      {/* Delete Cost Expenses Model */}
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">{"Confirmation"}</div>
        </div>
        <Modal.Body>
          <>
            <div className="col-lg-12">
              {"Are you sure you want to delete this record?"}
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => {
              DeleteCostExpenseDataApiCall(costExpenseId);
            }}
          >
            Yes
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowDeleteModal(false);
            }}
          >
            No
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CourseGrid;
