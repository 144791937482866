import { useState, useEffect, useContext, useRef } from "react";
import { Tabs, TextField, Tooltip } from "@mui/material";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { ThemeProvider } from "@mui/material/styles";
import { tabsTheme } from "../MRF/MRFHelper";

import uuid from "react-uuid";
import React from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import "./UAN.css";
import notify from "../../Helpers/ToastNotification";
import { APICall, APICallWithSignal } from "../../Helpers/APICalls";
import SelectForm from "../../Components/SelectForm/SelectForm";
import {
  GetAssociateUANDetails,
  DownloadCandidateUANExcelTemplate,
  UploadCandidateUANExcel,
  UpdateStagesForAssociateWhenProceed,
  DownloadUANExcelTemplate,
  GetNotVerifiedAssociateUANDetails,
  GetAssociateDropdownData,
} from "../../Helpers/APIEndPoints/AssociateEndPoints";
import { LoaderContext } from "../../Helpers/Context/Context";
import moment from "moment";
import {
  base64ToArrayBuffer,
  getBase64FromFilePromise,
} from "../../Helpers/Common";
import fileDownload from "js-file-download";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { all } from "axios";
import { useNavigate } from "react-router-dom";
import UserDetails from "../MainGateEntry/UserDetailsEntry";

/**
 *  Date Component Styles
 */
const DateFormStyle = {
  sx: {
    "&.Mui-disabled": {
      backgroundColor: "#e9ecef",
      borderColor: "#e9ecef",
      outline: "none",
      opacity: "1",
    },
  },
};

const DateFormTextFieldStyle = {
  svg: {
    color: "#00ABE6",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      fontFamily: "Segoe UI",
      boxShadow: "none",
      outline: "none",
    },
    "&:hover fieldset": {
      borderColor: "hsl(0, 0%, 70%)",
      boxShadow: "none",
      outline: "none",
    },
    "&.Mui-focused fieldset": {
      borderColor: "hsl(0, 0%, 70%)",
      boxShadow: "none",
      outline: "none",
    },
  },
};

/**
 * Date Componet:
 *
 */
const DateComponent = ({ date, handleDateChange, code }) => {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          value={date}
          disabled={false}
          className="w-100 bg-white date_icon"
          onChange={(e) => handleDateChange(e, code)}
          inputFormat="dd-MMM-yyyy"
          InputProps={DateFormStyle}
          renderInput={(params) => (
            <TextField
              size="small"
              name=""
              autoComplete="off"
              id="date-input"
              sx={DateFormTextFieldStyle}
              {...params}
            />
          )}
        />
      </LocalizationProvider>
    </>
  );
};

/**
 *
 * Interfaces
 */
interface IDashboardfilter {
  fromDate?: string | Date | null;
  endDate?: string | Date | null;
}

interface IGenerateExcelData {
  id?: number;
  candidateId?: number;
  candidateName?: string;
  candidateDesignation?: string;
  candidateAdhar?: string;
  candidateMobileNumber?: string;
  candidateUANStatus?: string;
}

const UAN = () => {
  const [activeTab, setActivetab] = useState(null);
  const navigate = useNavigate();
  const tabs = [
    { name: "Pending UAN", code: "GenerateExcel" },
    { name: "Verify UAN Number", code: "ImportExcel" },
    { name: "Not Verified Candidates", code: "NotVerified" },
  ];

  useEffect(() => {
    setActivetab(tabs[0].code);
  }, []);

  return (
    <>
      <div className="form-main">
        <div className="page-title w-100">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <div className="row">
                  <i
                    className="fa-solid fa-home mt-3 mr-3 ml-3"
                    style={{ color: "white", cursor: "pointer" }}
                    onClick={() => {
                      navigate("/Bluecollar");
                    }}
                  ></i>
                  <h4>UAN Verification</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* TABS */}
      <div className="form-main">
        <div className="mt-3">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 ">
                <ThemeProvider theme={tabsTheme}>
                  <Tabs
                    value={0}
                    className="profile-tabs"
                    onChange={() => {}}
                    variant="scrollable"
                    TabScrollButtonProps={{
                      style: {
                        color: "white",
                      },
                    }}
                    scrollButtons
                    TabIndicatorProps={{
                      style: { display: "none" },
                    }}
                    allowScrollButtonsMobile
                    aria-label="scrollable tabs"
                  >
                    <div className="tabBtn">
                      <ul>
                        {tabs.map((eachTab) => {
                          return (
                            <React.Fragment key={uuid()}>
                              <li>
                                <button
                                  onClick={() => setActivetab(eachTab.code)}
                                  className={
                                    eachTab.code === activeTab
                                      ? "bttn active"
                                      : "bttn"
                                  }
                                >
                                  {eachTab.name}
                                </button>
                              </li>
                            </React.Fragment>
                          );
                        })}
                      </ul>
                    </div>
                  </Tabs>
                </ThemeProvider>
              </div>
            </div>
            <div>
              <hr style={{ margin: "0" }} />
            </div>
          </div>
        </div>
      </div>

      <>
        <div className="form-main">
          <div className="mt-3">
            {activeTab === "GenerateExcel" ? (
              <GenerateExcelTab />
            ) : activeTab === "ImportExcel" ? (
              <ImportExcelTab />
            ) : (
              <NotVerifiedTab />
            )}
          </div>
        </div>
      </>
    </>
  );
};

export default UAN;

const GenerateExcelTab = ({}) => {
  const [filter, setFilter] = useState<IDashboardfilter>({});
  const [page, setPage] = useState<number>(0);
  const [dataCount, setDataCount] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sortDirection, setSortDirection] = useState<string | null>("");
  const [sortColumn, setSortColumn] = useState<string | null>("");
  const [dashboardData, setDashboardData] = useState<IGenerateExcelData[]>([]);
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  let { userDetails, interfacePermissionRecord, currentRoleId }: any =
    useUserContext();

  const { showLoader, hideLoader } = useContext(LoaderContext);

  useEffect(() => {
    setFilter({});
    setIsFilterApplied(false);
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const helper = async () => {
      await handleFetchCandidateDashboardData({ signal });
    };

    helper();

    return () => {
      // Cleanup: Abort the POST request if the component is unmounted
      abortController.abort();
    };
  }, [page, sortColumn, sortDirection, isFilterApplied]);

  function handleFilterDataChange(event, code) {
    try {
      setFilter((prev) => ({ ...prev, [code]: event }));
    } catch (error) {
      console.error(error);
    } finally {
    }
  }

  async function handleFetchCandidateDashboardData({ signal }) {
    try {
      const params = {
        PageNumber: page,
        PageSize: pageSize,
        SortColumn: sortColumn,
        SortOrder: sortDirection,
        UserId: userDetails?.Id,
      };

      showLoader();
      const response = await APICallWithSignal(
        GetAssociateUANDetails,
        "POST",
        params,
        true,
        null,
        signal
      );

      if (response?.status == 0 && response?.data?.length > 0) {
        const responseData = response?.data?.map((i) => ({
          candidateId: i?.candidateId || 0,
          candidateName: i?.candidateName || "",
          candidateAdhar: i?.candidateAdhar || "",
          candidateDesignation: i?.candidateDesignation || "",
          candidateMobileNumber: i?.candidateMobileNumber || "",
          count: i?.count || 0,
        }));

        setDashboardData(responseData);
        setDataCount(responseData[0]?.count);
      } else {
        setDashboardData([]);
        setDataCount(0);
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  }

  const gridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    rowsPerPage: 10,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",
    count: dataCount,
    //standard | vertical | simple
    onChangeRowsPerPage: (num) => {},

    onColumnSortChange: (sortColumn, sortDirection) => {
      setSortColumn(sortColumn);
      setSortDirection(sortDirection);
    },
    onChangePage: (page) => {
      debugger;
      setPage(page * pageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const generateExcelDashboardColumns = [
    {
      name: "id",
      label: "id",
      options: {
        display: false,
      },
    },
    {
      name: "candidateId",
      label: "Candidate Id",
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      name: "candidateName",
      label: "Candidate name",
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      name: "candidateDesignation",
      label: "Candidate Designation",
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      name: "candidateAdhar",
      label: "Candidate Aadhaar",
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      name: "candidateMobileNumber",
      label: "Candidate Mobile Number",
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
  ];

  function handleSearchBtn() {
    setIsFilterApplied(true);
  }

  function handleClearnBtn() {
    setIsFilterApplied(false);
    setFilter({});
  }

  async function handleExportToExcel() {
    try {
      showLoader();
      const response = await APICall(
        DownloadCandidateUANExcelTemplate,
        "POST",
        {}
      );

      if (response?.status == 0) {
        const { base64 } = response?.data;
        const fileName = `UAN_${moment().format("DD-MM-YYYY HH:mm:ss")}.xlsx`;

        let arrayBuffer = await base64ToArrayBuffer(base64);
        var byteArray = new Uint8Array(arrayBuffer);

        let blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const newFile = new File([blob], "", {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          lastModified: new Date().getTime(),
        });

        fileDownload(newFile, fileName);
        notify(0, "File Downloaded Successfully.");
      } else if (response?.status == 5) {
        notify(1, response?.message);
      } else {
        console.error("Export to excel ", response?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  return (
    <>
      {/* FILTER  */}
      <div className="form-main ">
        <div className="">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
                <div className="form-group">
                  <label className="col-form-label">From date</label>
                  <DateComponent
                    date={filter.fromDate || null}
                    handleDateChange={handleFilterDataChange}
                    code={"fromDate"}
                  />
                </div>
                <span style={{ color: "red" }}>{""}</span>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
                <div className="form-group">
                  <label className="col-form-label">End date</label>
                  <DateComponent
                    date={filter.endDate || null}
                    handleDateChange={handleFilterDataChange}
                    code={"endDate"}
                  />
                </div>
                <span style={{ color: "red" }}>{""}</span>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mobile-view">
                <label className="col-form-label">&nbsp;</label>
                <div className="form-group">
                  <button
                    className="btn btn-filter-submit mr-2"
                    type={"button"}
                    onClick={handleSearchBtn}
                  >
                    <i className="fa-solid fa-magnifying-glass mr-2"></i> Search
                  </button>
                  <button
                    className="btn btn-secondary mr-2"
                    type={"button"}
                    onClick={handleClearnBtn}
                  >
                    <i className="fa-solid fa-circle-xmark mr-2"></i> Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* DATA TABLE  */}
      <div className="col-lg-12 d-flex justify-content-end mb-2">
        <button
          className="btn btn-primary"
          type={"button"}
          onClick={handleExportToExcel}
        >
          <i className="fa fa-file-alt mr-2"></i> Export to Excel
        </button>
      </div>
      <div className="col-lg-12 dashboard_removing_padding">
        <DynamicGrid
          data={dashboardData}
          columns={generateExcelDashboardColumns}
          options={gridOptions}
        />
      </div>
    </>
  );
};

const ImportExcelTab = ({}) => {
  const fileRef = useRef(null);
  const [file, setFile] = useState(null);
  const [formError, setFormError] = useState({});
  const [checkedCandidateId, setCheckedCandidateId] = useState([]);
  const [verifiedAssociatesData, setVerifiedAssociatesDashboardData] =
    useState<any>([]);
  const [notVerifiedAssociatesData, setNotVerifiedAssociatesDashboardData] =
    useState<any>([]);

  const [verifiedCandidateIds, setVerifiedCandidateIds] = useState([]);

  const [
    checkedNonVerifiedCandidateCheckboxValue,
    setCheckedNonVerifiedCandidateCheckboxValue,
  ] = useState({});

  const [
    allNotVerfiedCandidateCheckboxValueWithTrue,
    setAllNotVerfiedCandidateCheckboxValueWithTrue,
  ] = useState({});

  const [isAllCandidateCheckboxChecked, setIsAllCandidateCheckboxChecked] =
    useState(false);

  let { userDetails }: any = useUserContext();
  const { showLoader, hideLoader } = useContext(LoaderContext);

  useEffect(() => {
    setFile(null);
    setNotVerifiedAssociatesDashboardData([]);
    setVerifiedAssociatesDashboardData([]);
    setCheckedNonVerifiedCandidateCheckboxValue({});
    setAllNotVerfiedCandidateCheckboxValueWithTrue({});
  }, []);

  function handleFileChange(event) {
    try {
      const files = event.target.files[0] || null;
      if (!files) {
        setFile(null);
        notify(1, "Please select a file.");
        return;
      }

      // isAllCandidateCheckboxChecked if the selected file is an Excel file
      if (!files.name.endsWith(".xls") && !files.name.endsWith(".xlsx")) {
        setFile(null);
        notify(1, "Please upload an Excel file.");
        return;
      }
      setFile(files);
    } catch (error) {
      console.error(error);
    }
  }

  async function UploadUANExcel() {
    try {
      if (!file) {
        setFile(null);
        notify(1, "Please select a file.");
        return;
      }

      let base64 = await getBase64FromFilePromise(file);
      base64 = String(base64).split("base64,")[1];

      showLoader();
      const response = await APICall(UploadCandidateUANExcel, "POST", {
        base64,
        UserId: userDetails?.Id || 0,
      });

      if (response?.status == 0) {
        const {
          verifiedAssociateList,
          notVerifiedAssociateList,
          isDataUploaded,
        } = response?.data;

        if (isDataUploaded) {
          const tempVerifiedList = [];
          const tempVerifiedCandidateIds = [];
          for (const item of verifiedAssociateList) {
            let obj = {
              id: item?.id,
              candidateId: Number(item?.candidateId) || 0,
              candidateName: item?.candidateName,
              candidateDesignation: item?.candidateDesignation,
              candidateAdhar: item?.candidateAdhar,
              candidateMobileNumber: item?.candidateMobileNumber,
              candidateUANStatus: item?.candidateUANStatus,
            };
            tempVerifiedList.push(obj);
            tempVerifiedCandidateIds.push(Number(item?.candidateId) || 0);
          }
          setVerifiedAssociatesDashboardData(tempVerifiedList);
          setVerifiedCandidateIds(tempVerifiedCandidateIds);

          const allNotVerfiedCandidateCheckboxObject = {};
          const tempCheckedCandidateId = {};
          const tempNotVerifiedList = [];

          for (const item of notVerifiedAssociateList) {
            allNotVerfiedCandidateCheckboxObject[Number(item?.candidateId)] =
              true;
            tempCheckedCandidateId[Number(item?.candidateId)] = false;
            tempNotVerifiedList.push({
              id: item?.id,
              candidateId: Number(item?.candidateId) || 0,
              candidateName: item?.candidateName,
              candidateDesignation: item?.candidateDesignation,
              candidateAdhar: item?.candidateAdhar,
              candidateMobileNumber: item?.candidateMobileNumber,
              candidateUANStatus: item?.candidateUANStatus,
            });
          }

          setAllNotVerfiedCandidateCheckboxValueWithTrue(
            allNotVerfiedCandidateCheckboxObject
          );
          setCheckedNonVerifiedCandidateCheckboxValue(tempCheckedCandidateId);
          setNotVerifiedAssociatesDashboardData(tempNotVerifiedList);
          setFile(null);
          notify(0, `Data Uploaded Successfully.`);
        } else {
          notify(1, "Data Uploaded UnSucessfully.");
        }
      } else if (response?.status == 5) {
        notify(1, response?.message);
      } else {
        notify(1, "Something went wrong.");
        console.error("Upload Excel: ", response.message);
      }
    } catch (error) {
      console.error("Upload Excel: ", error);
    } finally {
      hideLoader();
    }
  }

  const verifiedAssociateListDashboardOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    rowsPerPage: 10,
    serverSide: false,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    //standard | vertical | simple
    onChangeRowsPerPage: (num) => {},

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
      }
      if (sortDirection === "desc") {
      }
    },
    onChangePage: async (page) => {},
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const notVerifiedAssociateListDashboardOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    rowsPerPage: 10,
    serverSide: false,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",
    //standard | vertical | simple
    onChangeRowsPerPage: (num) => {},

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
      }
      if (sortDirection === "desc") {
      }
    },
    onChangePage: async (page) => {},
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const verifiedAssociateDashboardColumns = [
    {
      name: "candidateUANStatus",
      label: "Candidate UAN Status",
      options: {
        filter: true,
        display: true,
        sort: false,
      },
    },
    {
      name: "candidateId",
      label: "Candidate Id",
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      name: "candidateName",
      label: "Candidate name",
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      name: "candidateDesignation",
      label: "Candidate Designation",
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      name: "candidateAdhar",
      label: "Candidate Aadhaar",
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      name: "candidateMobileNumber",
      label: "Candidate Mobile Number",
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
  ];

  const notVerifiedAssociateDashboardColumns = [
    {
      name: "candidateUANStatus",
      label: "Candidate UAN Status",
      options: {
        filter: true,
        display: true,
        sort: false,
      },
    },
    {
      name: "candidateId",
      label: "Candidate Id",
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      name: "candidateName",
      label: "Candidate name",
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      name: "candidateDesignation",
      label: "Candidate Designation",
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      name: "candidateAdhar",
      label: "Candidate Aadhaar",
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      name: "candidateMobileNumber",
      label: "Candidate Mobile Number",
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    // {
    //   name: "candidateId",
    //   label: (
    //     <>
    //       <div className="d-flex">
    //         <span>Action</span>
    //         <>
    //           <input
    //             type="checkbox"
    //             className="ml-1"
    //             checked={isAllCandidateCheckboxChecked}
    //             onChange={(e) => {
    //               if (e.target.checked) {
    //                 setIsAllCandidateCheckboxChecked(true);
    //                 setCheckedNonVerifiedCandidateCheckboxValue(
    //                   allNotVerfiedCandidateCheckboxValueWithTrue
    //                 );
    //               } else {
    //                 setIsAllCandidateCheckboxChecked(false);
    //                 setCheckedNonVerifiedCandidateCheckboxValue({});
    //               }
    //             }}
    //           />
    //         </>
    //       </div>
    //     </>
    //   ),
    //   options: {
    //     filter: true,
    //     display: true,
    //     sort: false,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return (
    //         <>
    //           <input
    //             type="checkbox"
    //             checked={checkedNonVerifiedCandidateCheckboxValue[value]}
    //             onChange={(e) => {
    //               setCheckedNonVerifiedCandidateCheckboxValue((prev) => ({
    //                 ...prev,
    //                 [value]: e.target.checked,
    //               }));
    //             }}
    //           />
    //         </>
    //       );
    //     },
    //   },
    // },
  ];
  async function handleDownloadTemplete() {
    try {
      showLoader();
      const response = await APICall(DownloadUANExcelTemplate, "POST", {});

      if (response?.status == 0) {
        const { base64 } = response?.data;
        const fileName = `UA - 10.xlsx`;

        let arrayBuffer = await base64ToArrayBuffer(base64);
        var byteArray = new Uint8Array(arrayBuffer);

        let blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const newFile = new File([blob], "", {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          lastModified: new Date().getTime(),
        });

        fileDownload(newFile, fileName);
        notify(0, "File Downloaded Successfully.");
      } else if (response?.status == 5) {
        notify(1, response?.message);
      } else {
        console.error("handleDownloadTemplete", response?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  async function handleProceed(event) {
    event.preventDefault();
    event.stopPropagation();

    let notVerifiedIdsSelectIdList = [];

    if (Object.keys(checkedNonVerifiedCandidateCheckboxValue)?.length > 0) {
      for (const item of Object.keys(
        checkedNonVerifiedCandidateCheckboxValue
      )) {
        if (checkedNonVerifiedCandidateCheckboxValue[item] === false) {
          notVerifiedIdsSelectIdList.push(Number(item));
        }
      }
    }

    if (
      (!notVerifiedIdsSelectIdList || notVerifiedIdsSelectIdList.length == 0) &&
      (!verifiedCandidateIds || verifiedCandidateIds.length === 0)
    ) {
      notify(1, "No Data to proceed!");
      return;
    }

    try {
      showLoader();
      const response = await APICall(
        UpdateStagesForAssociateWhenProceed,
        "POST",
        {
          VerfiedCandidateIdList: verifiedCandidateIds,
          NotVerfiedCandidateIdList: notVerifiedIdsSelectIdList,
        }
      );

      if (response?.status == 0) {
        setVerifiedCandidateIds([]);
        setVerifiedAssociatesDashboardData([]);

        setNotVerifiedAssociatesDashboardData((prev) => {
          const arrReturn = prev?.filter(
            (i) =>
              checkedNonVerifiedCandidateCheckboxValue[i?.candidateId] === false
          );
          return arrReturn;
        });

        setAllNotVerfiedCandidateCheckboxValueWithTrue((prevObj1) => {
          const newObj1 = { ...prevObj1 };
          for (const key in checkedNonVerifiedCandidateCheckboxValue) {
            if (checkedNonVerifiedCandidateCheckboxValue[key] === true) {
              delete newObj1[key];
            }
          }
          return newObj1;
        });

        let filteredObj = Object.fromEntries(
          Object.entries(checkedNonVerifiedCandidateCheckboxValue).filter(
            ([key, value]) => value === false
          )
        );

        setCheckedNonVerifiedCandidateCheckboxValue(filteredObj);

        notify(0, "Data Updated Successfully");
      } else {
        notify(1, "Something went wrong.");
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  return (
    <>
      <div className="form-main mt-2">
        <div className="">
          <div className="row mx-4">
            <div className="col-lg-2 col-xs">
              <div>
                <label className="col-form-label"></label>
                <>
                  <div className="box position-relative">
                    <input
                      id="file"
                      className="form-control inputfile inputfile-6 multiple-inputfile"
                      data-multiple-caption="{count} files selected"
                      multiple={false}
                      type="file"
                      onChange={handleFileChange}
                      accept={".xlsx,.xls"}
                      ref={fileRef}
                      value={""}
                      disabled={false}
                    />
                    <label
                      htmlFor={`file`}
                      className="form-control"
                      style={{ width: 0, border: "none" }}
                    >
                      <strong
                        style={{
                          padding: "6px 16px",
                          backgroundColor: "#3c5464",
                          borderRadius: "5px",
                        }}
                      >
                        <i
                          className="fa fa-upload rotate90 mr-2"
                          aria-hidden="true"
                        ></i>
                        Upload
                      </strong>
                    </label>
                  </div>
                </>

                <div className="file-added-list">
                  <ul className="list-unstyle">
                    <li className="list mt-1">
                      <div className="media">
                        <div className="media-body text-truncate">
                          <Tooltip title={file?.name}>
                            <span className="view-more">{file?.name}</span>
                          </Tooltip>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <span style={{ color: "red" }}>{formError["file"]}</span>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="d-flex">
                <div style={{ marginTop: "19px" }}>
                  <button
                    className="btn btn-primary"
                    type={"button"}
                    onClick={UploadUANExcel}
                  >
                    Upload UAN Excel
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="d-flex justify-content-end align-items-center">
                <div style={{ marginTop: "19px" }}>
                  <button
                    className="btn btn-primary"
                    type={"button"}
                    onClick={handleDownloadTemplete}
                  >
                    Download Templete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-2 mb-2">
        <h6 className="col-form-label px-3">Verified Candidates</h6>
        <DynamicGrid
          options={verifiedAssociateListDashboardOptions}
          data={verifiedAssociatesData}
          columns={verifiedAssociateDashboardColumns}
        />
      </div>
      <div className="col-lg-12 mt-2 mb-2">
        <h6 className="col-form-label px-3">Not Verified Candidates</h6>
        <DynamicGrid
          options={notVerifiedAssociateListDashboardOptions}
          data={notVerifiedAssociatesData}
          columns={notVerifiedAssociateDashboardColumns}
        />
        <div className="col-lg-12 d-flex justify-content-end mt-2">
          <button
            className="btn btn-primary "
            type={"button"}
            onClick={handleProceed}
          >
            Proceed
          </button>
        </div>
      </div>
      <></>
    </>
  );
};

const NotVerifiedTab = ({}) => {
  const [notVerifiedAssociatesData, setNotVerifiedAssociatesDashboardData] =
    useState<any>([]);

  const [candidateToken, setCandidateToken] = useState<any>(null);

  const [verifiedCandidateIds, setVerifiedCandidateIds] = useState([]);

  const [
    checkedNonVerifiedCandidateCheckboxValue,
    setCheckedNonVerifiedCandidateCheckboxValue,
  ] = useState({});

  const [
    allNotVerfiedCandidateCheckboxValueWithTrue,
    setAllNotVerfiedCandidateCheckboxValueWithTrue,
  ] = useState({});

  const [isAllCandidateCheckboxChecked, setIsAllCandidateCheckboxChecked] =
    useState(false);

  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [page, setPage] = useState<number>(0);
  const [dataCount, setDataCount] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sortDirection, setSortDirection] = useState<string | null>("");
  const [sortColumn, setSortColumn] = useState<string | null>("");
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  let { userDetails }: any = useUserContext();
  // useEffect(() => {

  //   handleFetchCandidateDashboardData();
  // });

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const helper = async () => {
      await handleFetchCandidateDashboardData({ signal });
    };

    helper();

    return () => {
      // Cleanup: Abort the POST request if the component is unmounted
      abortController.abort();
    };
  }, [page, sortColumn, sortDirection, isFilterApplied]);
  async function handleSearchForCandidateIdDropdown(event, code, callback) {
    try {
      if (event?.length >= 1) {
        const data = await fetchCandidateDropdown(event, code);
        callback(data);
      } else {
        callback([]);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function fetchCandidateDropdown(
    searchText = "",
    code = "",
    value = null
  ) {
    try {
      const response = await APICall(
        GetAssociateDropdownData,
        "POST",
        {
          SearchText: searchText,
          userId: userDetails.Id,
          CurrentStagesId: 5,
        },
        true
      );

      if (response?.status === 0) {
        if (response?.data) {
          return response?.data;
        } else {
          return [];
        }
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  async function handleFetchCandidateDashboardData({ signal }) {
    try {
      const params = {
        PageNumber: page,
        PageSize: pageSize,
        SortColumn: sortColumn,
        SortOrder: sortDirection,
        CandidateToken: formData?.id?.id,
        UserId: userDetails?.Id,
      };

      showLoader();
      // const response = await APICall
      const response = await APICall(
        GetNotVerifiedAssociateUANDetails,
        "POST",
        params,
        true,
        null
        // signal
      );

      if (response?.status == 0 && response?.data?.length > 0) {
        const responseData = response?.data?.map((i) => ({
          candidateId: i?.candidateId || 0,
          candidateName: i?.candidateName || "",
          candidateAdhar: i?.candidateAdhar || "",
          candidateDesignation: i?.candidateDesignation || "",
          candidateMobileNumber: i?.candidateMobileNumber || "",
          additionalRemarks: i?.additionalRemarks || "",
          count: i?.count || 0,
        }));

        setNotVerifiedAssociatesDashboardData(responseData);
        const allNotVerfiedCandidateCheckboxObject = {};
        for (const item of responseData) {
          allNotVerfiedCandidateCheckboxObject[Number(item?.candidateId)] =
            true;
        }
        setAllNotVerfiedCandidateCheckboxValueWithTrue(
          allNotVerfiedCandidateCheckboxObject
        );
        setDataCount(responseData[0]?.count);
      } else {
        setNotVerifiedAssociatesDashboardData([]);
        // setDataCount(0);
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
      setIsFilterApplied(false);
    }
  }

  const gridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    rowsPerPage: 10,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",
    count: dataCount,
    //standard | vertical | simple
    onChangeRowsPerPage: (num) => {},

    onColumnSortChange: (sortColumn, sortDirection) => {
      setSortColumn(sortColumn);
      setSortDirection(sortDirection);
    },
    onChangePage: (page) => {
      debugger;
      setPage(page * pageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const notVerifiedAssociateDashboardColumns = [
    {
      name: "candidateId",
      label: "Candidate Id",
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      name: "candidateName",
      label: "Candidate name",
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      name: "candidateDesignation",
      label: "Candidate Designation",
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      name: "candidateAdhar",
      label: "Candidate Aadhaar",
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      name: "candidateMobileNumber",
      label: "Candidate Mobile Number",
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      name: "additionalRemarks",
      label: "Remark",
      options: {
        filter: true,
        display: true,
        sort: true,
      },
    },
    {
      name: "candidateId",
      label: (
        <>
          <div className="d-flex">
            <span>Action</span>
            <>
              <input
                type="checkbox"
                className="ml-1"
                checked={isAllCandidateCheckboxChecked}
                onChange={(e) => {
                  if (e.target.checked) {
                    setIsAllCandidateCheckboxChecked(true);
                    setCheckedNonVerifiedCandidateCheckboxValue(
                      allNotVerfiedCandidateCheckboxValueWithTrue
                    );
                  } else {
                    setIsAllCandidateCheckboxChecked(false);
                    setCheckedNonVerifiedCandidateCheckboxValue({});
                  }
                }}
              />
            </>
          </div>
        </>
      ),
      options: {
        filter: true,
        display: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <input
                type="checkbox"
                checked={checkedNonVerifiedCandidateCheckboxValue[value]}
                onChange={(e) => {
                  setCheckedNonVerifiedCandidateCheckboxValue((prev) => ({
                    ...prev,
                    [value]: e.target.checked,
                  }));
                }}
              />
            </>
          );
        },
      },
    },
  ];

  async function handleProceed(event) {
    event.preventDefault();
    event.stopPropagation();

    let notVerifiedIdsSelectIdList = [];

    if (Object.keys(checkedNonVerifiedCandidateCheckboxValue)?.length > 0) {
      for (const item of Object.keys(
        checkedNonVerifiedCandidateCheckboxValue
      )) {
        if (checkedNonVerifiedCandidateCheckboxValue[item] === true) {
          notVerifiedIdsSelectIdList.push(Number(item));
        }
      }
    }

    if (
      (!notVerifiedIdsSelectIdList || notVerifiedIdsSelectIdList.length == 0) &&
      (!verifiedCandidateIds || verifiedCandidateIds.length === 0)
    ) {
      notify(1, "No Data to proceed!");
      return;
    }

    try {
      showLoader();
      const response = await APICall(
        UpdateStagesForAssociateWhenProceed,
        "POST",
        {
          VerfiedCandidateIdList: notVerifiedIdsSelectIdList,
        }
      );

      if (response?.status == 0) {
        setVerifiedCandidateIds([]);
        // setVerifiedAssociatesDashboardData([]);

        setNotVerifiedAssociatesDashboardData((prev) => {
          const arrReturn = prev?.filter(
            (i) =>
              checkedNonVerifiedCandidateCheckboxValue[i?.candidateId] === false
          );
          return arrReturn;
        });

        setAllNotVerfiedCandidateCheckboxValueWithTrue((prevObj1) => {
          const newObj1 = { ...prevObj1 };
          for (const key in checkedNonVerifiedCandidateCheckboxValue) {
            if (checkedNonVerifiedCandidateCheckboxValue[key] === true) {
              delete newObj1[key];
            }
          }
          return newObj1;
        });

        let filteredObj = Object.fromEntries(
          Object.entries(checkedNonVerifiedCandidateCheckboxValue).filter(
            ([key, value]) => value === false
          )
        );

        setCheckedNonVerifiedCandidateCheckboxValue(filteredObj);

        notify(0, "Data Updated Successfully");
        setIsFilterApplied(true);
      } else {
        notify(1, "Something went wrong.");
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }
  function onChange(event, code) {
    try {
      setFormData((prev) => {
        return { ...prev, [code]: event };
      });
    } catch (error) {
    } finally {
    }
  }
  const [formData, setFormData] = useState<IFormData>({});
  interface IFormData {
    id?: IOptions | boolean | any;
  }
  interface IOptions {
    label?: string;
    value?: number | string;
    code?: string;
  }

  return (
    <>
      <div className="col-lg-12 mt-2 mb-2">
        <div className="col-lg-2 col-sm-3 col-xs-4">
          <div className="d-flex align-items-center mb-1">
            <div className="mr-2 flex-grow-1">
              {" "}
              {/* Added flex-grow-1 class for the label and select to take remaining space */}
              <label className="col-form-label">
                Candidate Id <span style={{ color: "red" }}></span>
              </label>
              <SelectForm
                isClearable
                async
                options={(searchString, cb) => {
                  handleSearchForCandidateIdDropdown(
                    searchString,
                    "candidateId",
                    cb
                  );
                }}
                placeholder={"Select candidate Id"}
                onChange={(val: any) => {
                  onChange(val, "id");
                  // setCandidateToken(val.id);
                  setIsFilterApplied(true);
                }}
                isMulti={false}
                noIndicator={false}
                noSeparator={false}
                value={formData?.id}
              />
            </div>
            {/* <button
      className="btn btn-filter-submit"
      type={"button"}
      //onClick={}
    >
      <i className="fa-solid fa-magnifying-glass mr-2"></i> Search
    </button> */}
          </div>
        </div>

        <DynamicGrid
          options={gridOptions}
          data={notVerifiedAssociatesData}
          columns={notVerifiedAssociateDashboardColumns}
        />
        <div className="col-lg-12 d-flex justify-content-end mt-2">
          <button
            className="btn btn-primary "
            type={"button"}
            onClick={handleProceed}
          >
            Proceed
          </button>
        </div>
      </div>
      <></>
    </>
  );
};
