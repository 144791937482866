import React, { useState } from "react";
import AccessDenied from "../../Pages/AccessDenied/AccessDenied";

const ProtectedNew = ({ permittedPages, pageContext, children }) => {
  var results = false;
  let permissionName = "";
  if (pageContext) {
    let interfaceContext = pageContext?.filter(
      (e) => e.interfaceName  === permittedPages
    );
    const values = ["Edit", "View"];
   let interfacePermissionContext = interfaceContext?.map((permission) => permission.name);

    const isBoth = values.every((permission) => {
      return interfacePermissionContext?.includes(permission);
    });

    if (isBoth) {
      results = true;
      permissionName = "Edit";
    } else {
      const values = ["Edit"];
      const isOnlyEdit = values.every((permission) => {
        return interfaceContext.includes(permission);
      });
      if (isOnlyEdit) {
        results = true;
        permissionName = "Edit";
      } else {
        const values = ["View"];
        const isOnlyView = values.every((permission) => {
          return interfaceContext.includes(permission);
        });
        if (isOnlyView) {
          results = true;
          permissionName = "View";
        }
      }
    }

    // interfaceContext.map((permission) => {
    //   if (permission.Name === "View") {
    //     results = true;
    //     permissionName = "View";
    //   }
    //   if (permission.Name === "Edit") {
    //     results = true;
    //     permissionName = "Edit";
    //   }
    // });
  }

  if (!results) {
    return <AccessDenied />;
  }
  // Conditionally render children (GridStructure) based on permission
  if (permissionName !== "") {
    // Pass permissionName as a prop to all children components
    const childrenWithProps = React.Children.map(children, (child) => {
      return React.cloneElement(child, { permissionName });
    });

    return childrenWithProps;
  } else {
    return <AccessDenied />;
  }
};
export default ProtectedNew;
