import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Rating,
  Tabs,
  Tooltip,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import SelectForm from "../../Components/SelectForm/SelectForm";
// import InputForm from "../../Components/InputForm/InputForm";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Pages/Catalogue/Catalogue.css";
import Slider from "react-slick";
import { useLocation, useNavigate } from "react-router-dom";
import { APICall } from "../../Helpers/APICalls";
import CalendarSvg from "../../Assets/Images/Iconakar-calendar.svg";
import VideoSvg from "../../Assets/Images/Iconakar-video.svg";
import ReviewsOutlinedIcon from "@mui/icons-material/ReviewsOutlined";
import {
  CheckIfManagerById,
  GetCatalogueEmployeeDropdown,
  GetMyJourneyCatalogueData,
  GetThumbnailDetails,
  GetUpcomingCatalogueData,
  ManageWishListDetails,
  getJourneyProgressCatalogue,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import moment from "moment";
import { LoaderContext } from "../../Helpers/Context/Context";
import ImageColumnComponent from "../../Components/LMS/ImageColumnComponent/ImageColumnComponent";
import notify from "../../Helpers/ToastNotification";
import uuid from "react-uuid";
import Carousel from "nuka-carousel";
import DateForm from "../../Components/DateForm/DateForm";
import { ProgressBar } from "react-bootstrap";
import { green } from "@mui/material/colors";
import InputForm from "../../Components/InputForm/InputForm";
import { useDebounce } from "../../utils/useDebounce";

const AccordianCustom = ({ children, header }) => {
  const [accordion, setAccordion] = useState(true);
  return (
    <Accordion
      elevation={0}
      className="mb-3"
      expanded={accordion}
      onChange={() => setAccordion((prev) => !prev)}>
      <AccordionSummary
        id="profile-accordion"
        style={{ background: "#2C2B7C" }}
        className="text-white acc_close dark_bg"
        expandIcon={<ExpandMore />}>
        <div className="row d-flex align-items-center acc-heading">
          {header}
        </div>
      </AccordionSummary>

      <AccordionDetails className="">{children}</AccordionDetails>
    </Accordion>
  );
};

const UpComingCarousel = (props) => {
  const {
    upComingDataChange,
    managerId,
    selectedRadio,
    selectedUserId,
    selectedDate,
    searchText: search,
  } = props;
  const { showLoader, hideLoader } = useContext(LoaderContext);
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  const [upComingData, setUpComingData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [totalRecords, setTotalRecords] = useState(0);
  const navigate = useNavigate();
  const searchText = useDebounce(search);

  const getUpComingData = async (userId) => {
    showLoader();

    const res = await APICall(GetUpcomingCatalogueData, "POST", {
      UserId: userId,
      RoleId: currentRoleId.value,
      Offset: currentPage,
      PageSize: itemsPerPage,
      IsManager: selectedRadio === "team" && managerId > 0 ? true : false,
      SelectedDate: selectedDate
        ? moment(selectedDate).format("DD-MMM-YYYY")
        : null,
      IsAdmin:
        currentRoleId?.code == "LMSAdmin" ||
        currentRoleId.label.toLowerCase() === "lm admin" ||
        currentRoleId.label.toLowerCase() === "lm super admin" ||
        currentRoleId?.code == "LMSSuperAdmin" ||
        currentRoleId?.code == "SystemAdmin" ||
        currentRoleId.label.toLowerCase() === "system admin"
          ? true
          : false,
      searchText,
    });
    if (res.status === 0 && res.data.length > 0) {
      const upComingData = res.data;
      setUpComingData(upComingData);
      setTotalRecords(upComingData[0].totalCount);
    } else {
      setUpComingData([]);
      setTotalRecords(0);
    }
    hideLoader();
  };

  const handleWishListFormChange = async (
    programId,
    courseScheduleId,
    isProgramLaunch,
    newValue
  ) => {
    const parameters = {
      ProgramId: isProgramLaunch ? programId : 0,
      CourseScheduleId: isProgramLaunch ? 0 : courseScheduleId,
      IsWishlist: newValue === 1 ? true : false,
      UserId: upComingDataChange,
    };

    showLoader();
    const res = await APICall(ManageWishListDetails, "POST", parameters);

    if (res.status === 0) {
      notify(res.status, "Course has been added to the wishlist successfully.");
      getUpComingData(upComingDataChange);
    } else if (res.status === 1) {
      notify(res.status, res.message);
    }
    hideLoader();
  };

  useEffect(() => {
    if (upComingDataChange) {
      getUpComingData(upComingDataChange);
    }
  }, [upComingDataChange, currentPage, selectedDate, searchText]);

  const handleNext = () => {
    // if (currentPage < Math.ceil(totalRecords / itemsPerPage) - 1) {
    setCurrentPage(currentPage + 1);
    // }
  };

  const handlePrev = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      {upComingData.length > 0 ? (
        <Carousel
          animation="fade"
          slidesToShow={itemsPerPage}
          slidesToScroll={1}
          wrapAround={false}
          disableEdgeSwiping
          renderBottomCenterControls={null}
          afterSlide={(newIndex) => setCurrentPage(newIndex)}
          renderCenterLeftControls={({ previousSlide }) => (
            <>
              {totalRecords > itemsPerPage && currentPage !== 0 ? (
                <button
                  className="btn btn-outline-dark"
                  onClick={handlePrev}
                  disabled={currentPage === 0}>
                  <i
                    className="fa fa-chevron-circle-left"
                    aria-hidden="true"></i>
                </button>
              ) : null}
            </>
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <>
              {totalRecords > itemsPerPage &&
              currentPage + itemsPerPage !== totalRecords ? (
                <button
                  className="btn btn-outline-dark"
                  onClick={handleNext}
                  disabled={
                    // currentPage === Math.ceil(totalRecords / itemsPerPage) - 1
                    currentPage + itemsPerPage === totalRecords ? true : false
                  }>
                  <i
                    className="fa fa-chevron-circle-right"
                    aria-hidden="true"></i>
                </button>
              ) : null}
            </>
          )}>
          {upComingData &&
            upComingData.length > 0 &&
            upComingData.map((data) => (
              <div key={data} className="SlideCont">
                <div className="slide_bg">
                  <div className="vidImg">
                    <ImageColumnComponent
                      link={data.thumbnailUrl}
                      width="auto"
                    />
                    {data.isLaunched && (
                      <Rating
                        name="size-large"
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "25px",
                        }}
                        value={data?.isWishlist ? 1 : 0}
                        onChange={(event, newValue) => {
                          const programId = data.programId;
                          const courseScheduleId = data.id;
                          const isProgramLaunch = data.isProgramLaunch;

                          handleWishListFormChange(
                            programId,
                            courseScheduleId,
                            isProgramLaunch,
                            newValue
                          );
                        }}
                        max={1}
                        disabled={
                          currentRoleId?.code == "LMSAdmin" ||
                          currentRoleId.label.toLowerCase() === "lm admin" ||
                          currentRoleId.label.toLowerCase() ===
                            "lm super admin" ||
                          currentRoleId?.code == "LMSSuperAdmin" ||
                          currentRoleId?.code == "SystemAdmin" ||
                          currentRoleId.label.toLowerCase() === "system admin"
                            ? true
                            : managerId > 0 && selectedRadio === "team"
                            ? true
                            : false
                        }
                        size="large"
                      />
                    )}
                  </div>
                  <div className="cont catBtn">
                    <div className="Cname">
                      <img className="name_icon" src={VideoSvg}></img>
                      <h4>{data.title}</h4>
                    </div>
                    <div className="Cdate">
                      <img className="name_icon" src={CalendarSvg}></img>
                      <span className="date">
                        {data.startDateString} {data.startTime}
                      </span>
                    </div>
                    <div
                      className={
                        data.isLaunched
                          ? "date_btn"
                          : "date_btn opt_view icn_all"
                      }>
                      {data.isLaunched ? (
                        <>
                          {data?.isHold ? (
                            <button
                              className="btn btn-filter-submit hold"
                              onClick={(e) => {
                                if (data.isProgramLaunch) {
                                  // redirect to program registration but disable
                                  navigate("/JourneyRegistrationRequest", {
                                    state: {
                                      programId: data.programId,
                                      launchId: data.launchId,
                                      action: "view",
                                      isManager:
                                        selectedRadio === "team" &&
                                        managerId > 0
                                          ? true
                                          : false,
                                      userId:
                                        selectedRadio === "team" &&
                                        managerId > 0
                                          ? selectedUserId.value
                                          : userDetails.Id,
                                    },
                                  });
                                } else if (!data.isProgramLaunch) {
                                  // redirect to course registration but disable
                                  navigate("/RegistrationRequest", {
                                    state: {
                                      courseScheduleId: data.id,
                                      launchId: data.launchId,
                                      action: "view",
                                      requestId: 0,
                                      type: "IN",
                                      isManager:
                                        selectedRadio === "team" &&
                                        managerId > 0
                                          ? true
                                          : false,
                                      userId:
                                        selectedRadio === "team" &&
                                        managerId > 0
                                          ? selectedUserId.value
                                          : userDetails.Id,
                                    },
                                  });
                                }
                              }}>
                              <i className="fas fa-exclamation-triangle"></i> On
                              Hold
                            </button>
                          ) : (
                            <>
                              {data.canRegister ? (
                                <span>
                                  <Tooltip
                                    title={
                                      (currentRoleId.code === "LMSAdmin" ||
                                        currentRoleId.code ===
                                          "LMSSuperAdmin" ||
                                        currentRoleId.label.toLowerCase() ===
                                          "lm admin" ||
                                        currentRoleId.label.toLowerCase() ===
                                          "lm super admin" ||
                                        currentRoleId?.code == "SystemAdmin" ||
                                        currentRoleId.label.toLowerCase() ===
                                          "system admin") &&
                                      selectedUserId &&
                                      selectedUserId.value
                                        ? "Action cannot be performed"
                                        : null
                                    }>
                                    <button
                                      disabled={
                                        (currentRoleId.code === "LMSAdmin" ||
                                          currentRoleId.code ===
                                            "LMSSuperAdmin" ||
                                          currentRoleId.label.toLowerCase() ===
                                            "lm admin" ||
                                          currentRoleId.label.toLowerCase() ===
                                            "lm super admin" ||
                                          currentRoleId?.code ==
                                            "SystemAdmin" ||
                                          currentRoleId.label.toLowerCase() ===
                                            "system admin") &&
                                        selectedUserId &&
                                        selectedUserId.value
                                          ? true
                                          : false
                                      }
                                      onClick={() => {
                                        if (
                                          data.enableRegister &&
                                          data.isProgramLaunch
                                        ) {
                                          navigate(
                                            "/JourneyRegistrationRequest",
                                            {
                                              state: {
                                                programId: data.programId,
                                                launchId: data.launchId,
                                                isManager:
                                                  selectedRadio === "team" &&
                                                  managerId > 0
                                                    ? true
                                                    : false,
                                                userId:
                                                  selectedRadio === "team" &&
                                                  managerId > 0
                                                    ? selectedUserId.value
                                                    : userDetails.Id,
                                              },
                                            }
                                          );

                                          return null;
                                        }

                                        if (
                                          data.enableRegister &&
                                          !data.isProgramLaunch
                                        ) {
                                          navigate("/RegistrationRequest", {
                                            state: {
                                              courseScheduleId: data.id,
                                              launchId: data.launchId,
                                              action: "edit",
                                              requestId: 0,
                                              type: "IN",
                                              isManager:
                                                selectedRadio === "team" &&
                                                managerId > 0
                                                  ? true
                                                  : false,
                                              userId:
                                                selectedRadio === "team" &&
                                                managerId > 0
                                                  ? selectedUserId.value
                                                  : userDetails.Id,
                                            },
                                          });
                                        } else {
                                          if (
                                            !data.enableRegister &&
                                            data.isProgramLaunch
                                          ) {
                                            // redirect to program registration but disable
                                            navigate(
                                              "/JourneyRegistrationRequest",
                                              {
                                                state: {
                                                  programId: data.programId,
                                                  launchId: data.launchId,
                                                  action: "view",
                                                  isManager:
                                                    selectedRadio === "team" &&
                                                    managerId > 0
                                                      ? true
                                                      : false,
                                                  userId:
                                                    selectedRadio === "team" &&
                                                    managerId > 0
                                                      ? selectedUserId.value
                                                      : userDetails.Id,
                                                },
                                              }
                                            );
                                          }

                                          if (
                                            !data.enableRegister &&
                                            !data.isProgramLaunch
                                          ) {
                                            // redirect to course registration but disable
                                            navigate("/RegistrationRequest", {
                                              state: {
                                                courseScheduleId: data.id,
                                                launchId: data.launchId,
                                                action: "view",
                                                requestId: 0,
                                                type: "IN",
                                                isManager:
                                                  selectedRadio === "team" &&
                                                  managerId > 0
                                                    ? true
                                                    : false,
                                                userId:
                                                  selectedRadio === "team" &&
                                                  managerId > 0
                                                    ? selectedUserId.value
                                                    : userDetails.Id,
                                              },
                                            });
                                          }
                                        }
                                      }}
                                      className="btn btn-filter-submit regs">
                                      {selectedRadio === "team" &&
                                        managerId > 0 &&
                                        selectedUserId &&
                                        selectedUserId.value && (
                                          <i
                                            className="fa-regular fa-check-circle"
                                            aria-hidden="true"></i>
                                        )}

                                      {!data.enableRegister
                                        ? "View"
                                        : selectedRadio === "team" &&
                                          managerId > 0 &&
                                          selectedUserId &&
                                          selectedUserId.value
                                        ? "Assign"
                                        : "Register"}
                                    </button>
                                  </Tooltip>
                                </span>
                              ) : (
                                <button
                                  className="btn btn-filter-submit inprogress"
                                  onClick={(e) => {
                                    e.preventDefault();

                                    navigate("/RegistrationRequest", {
                                      state: {
                                        requestId: data?.requestId,
                                        courseScheduleId:
                                          data?.courseScheduleId,
                                        launchId: data?.launchId,
                                        action: "view",
                                      },
                                    });
                                  }}>
                                  <i className="fas fa-share"></i> In Progress
                                </button>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <span>
                            <Tooltip title={"View"}>
                              <button
                                style={null}
                                onClick={() =>
                                  navigate("/ContentViewer", {
                                    state: {
                                      courseScheduleId: data?.courseScheduleId,
                                      launchId: data.launchId,
                                    },
                                  })
                                }
                                className="btn btn-filter-submit">
                                <ReviewsOutlinedIcon className="icn_all_mat" />
                                View
                              </button>
                            </Tooltip>
                          </span>
                          <span>Not Launched</span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </Carousel>
      ) : (
        <div className="noCourse">
          <img className="name_icon" src={VideoSvg}></img>
          <h5>No course available</h5>
        </div>
      )}
    </div>
  );
};

const MyJourneyCarousel = (props) => {
  const {
    myJourneyDataChange,
    managerId,
    selectedRadio,
    selectedUserId,
    selectedDate,
    searchText: search,
  } = props;
  const { showLoader, hideLoader } = useContext(LoaderContext);
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  const [myJourneyData, setMyJourneyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [totalRecords, setTotalRecords] = useState(0);
  const navigate = useNavigate();
  const searchText = useDebounce(search);

  const getMyJourneyData = async (userId) => {
    showLoader();
    const res = await APICall(GetMyJourneyCatalogueData, "POST", {
      UserId: userId,
      Offset: currentPage,
      PageSize: itemsPerPage,
      SelectedDate: selectedDate
        ? moment(selectedDate).format("DD-MMM-YYYY")
        : null,
      searchText,
    });
    if (res.status === 0 && res.data.length > 0) {
      const myJourneyData = res.data;
      setMyJourneyData(myJourneyData);
      setTotalRecords(myJourneyData[0].totalCount);
    } else {
      setMyJourneyData([]);
      setTotalRecords(0);
    }
    hideLoader();
  };

  useEffect(() => {
    if (myJourneyDataChange) {
      getMyJourneyData(myJourneyDataChange);
    }
  }, [myJourneyDataChange, currentPage, selectedDate, searchText]);

  const handleNext = () => {
    // if (currentPage < Math.ceil(totalRecords / itemsPerPage) - 1) {
    setCurrentPage(currentPage + 1);
    // }
  };

  const handlePrev = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      {myJourneyData.length > 0 ? (
        <Carousel
          key={currentPage}
          animation="fade"
          slidesToShow={itemsPerPage}
          slidesToScroll={1}
          wrapAround={false}
          disableEdgeSwiping
          renderBottomCenterControls={null}
          afterSlide={(newIndex) => setCurrentPage(newIndex)}
          renderCenterLeftControls={({ previousSlide }) => (
            <>
              {totalRecords > itemsPerPage && currentPage !== 0 ? (
                <button
                  className="btn btn-outline-dark"
                  onClick={handlePrev}
                  disabled={currentPage === 0}>
                  <i
                    className="fa fa-chevron-circle-left"
                    aria-hidden="true"></i>
                </button>
              ) : null}
            </>
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <>
              {totalRecords > itemsPerPage &&
              currentPage + itemsPerPage !== totalRecords ? (
                <button
                  className="btn btn-outline-dark"
                  onClick={handleNext}
                  disabled={
                    // currentPage === Math.ceil(totalRecords / itemsPerPage) - 1
                    currentPage + itemsPerPage === totalRecords ? true : false
                  }>
                  <i
                    className="fa fa-chevron-circle-right"
                    aria-hidden="true"></i>
                </button>
              ) : null}
            </>
          )}>
          {myJourneyData &&
            myJourneyData.length > 0 &&
            myJourneyData.map((data) => (
              <div key={data} className="SlideCont">
                <div className="slide_bg">
                  <div className="vidImg">
                    <ImageColumnComponent
                      link={data.thumbnailUrl}
                      width="auto"
                    />
                  </div>
                  <div className="cont catBtn">
                    <div className="Cname">
                      <img className="name_icon" src={VideoSvg}></img>
                      {/* <h4>{data.title}</h4> */}
                      <Tooltip title={`${data.title}`}>
                        <h4>{data.title}</h4>
                      </Tooltip>
                    </div>
                    <div className="Cdate">
                      <img className="name_icon" src={CalendarSvg}></img>
                      <span className="date">
                        {data.startDateString} {data.startTime}
                      </span>
                    </div>
                    <div
                      className={
                        data.isHold ? "date_btn" : "date_btn opt_view icn_all"
                      }>
                      {data?.isHold ? (
                        <button
                          className="btn btn-filter-submit hold"
                          onClick={(e) => {
                            //  content view but on hold
                            navigate("/ContentViewer", {
                              state: {
                                courseScheduleId: data.id,
                                launchId: data.launchId,
                                isHold: data.isHold,
                              },
                            });
                          }}>
                          <i className="fas fa-exclamation-triangle"></i> On
                          Hold
                        </button>
                      ) : (
                        <>
                          {data.canView && (
                            <>
                              <span>
                                <Tooltip
                                  title={
                                    (currentRoleId.code === "LMSAdmin" ||
                                      currentRoleId.code === "LMSSuperAdmin" ||
                                      currentRoleId.label.toLowerCase() ===
                                        "lm admin" ||
                                      currentRoleId.label.toLowerCase() ===
                                        "lm super admin" ||
                                      currentRoleId?.code == "SystemAdmin" ||
                                      currentRoleId.label.toLowerCase() ===
                                        "system admin" ||
                                      managerId > 0) &&
                                    selectedUserId &&
                                    selectedUserId.value
                                      ? "Action cannot be performed"
                                      : null
                                  }>
                                  <button
                                    disabled={
                                      (currentRoleId.code === "LMSAdmin" ||
                                        currentRoleId.code ===
                                          "LMSSuperAdmin" ||
                                        currentRoleId.label.toLowerCase() ===
                                          "lm admin" ||
                                        currentRoleId.label.toLowerCase() ===
                                          "lm super admin" ||
                                        currentRoleId?.code == "SystemAdmin" ||
                                        currentRoleId.label.toLowerCase() ===
                                          "system admin" ||
                                        (managerId && managerId > 0)) &&
                                      selectedUserId &&
                                      selectedUserId.value
                                        ? true
                                        : false || data.enableRegister
                                    }
                                    onClick={() =>
                                      navigate("/ContentViewer", {
                                        state: {
                                          courseScheduleId: data.id,
                                          launchId: data.launchId,
                                        },
                                      })
                                    }
                                    className="btn btn-filter-submit">
                                    <ReviewsOutlinedIcon className="icn_all_mat" />
                                    View
                                  </button>
                                </Tooltip>
                              </span>
                              <span>{data.progressText}</span>
                            </>
                          )}
                          {data.canOpt && (
                            <span>
                              <Tooltip
                                title={
                                  (currentRoleId.code === "LMSAdmin" ||
                                    currentRoleId.code === "LMSSuperAdmin" ||
                                    currentRoleId.label.toLowerCase() ===
                                      "lm admin" ||
                                    currentRoleId.label.toLowerCase() ===
                                      "lm super admin" ||
                                    currentRoleId?.code == "SystemAdmin" ||
                                    currentRoleId.label.toLowerCase() ===
                                      "system admin" ||
                                    managerId > 0) &&
                                  selectedUserId &&
                                  selectedUserId.value
                                    ? "Action cannot be performed"
                                    : null
                                }>
                                <button
                                  disabled={
                                    (currentRoleId.code === "LMSAdmin" ||
                                      currentRoleId.code === "LMSSuperAdmin" ||
                                      currentRoleId.label.toLowerCase() ===
                                        "lm admin" ||
                                      currentRoleId.label.toLowerCase() ===
                                        "lm super admin" ||
                                      currentRoleId?.code == "SystemAdmin" ||
                                      currentRoleId.label.toLowerCase() ===
                                        "system admin" ||
                                      managerId > 0) &&
                                    selectedUserId &&
                                    selectedUserId.value
                                      ? true
                                      : false
                                  }
                                  onClick={() =>
                                    navigate("/RegistrationRequest", {
                                      state: {
                                        courseScheduleId: data.id,
                                        launchId: data.launchId,
                                        action: "edit",
                                        requestId: 0,
                                        type: "OUT",
                                      },
                                    })
                                  }
                                  className="btn btn-filter-submit">
                                  <i className="fa-solid fa-arrow-right-from-bracket"></i>
                                  Opt-out
                                </button>
                              </Tooltip>
                            </span>
                          )}
                        </>
                      )}
                    </div>
                    {/* <div className="Cname">
                      <div className="name_icon">
                        <i className="fa-solid fa-signal"></i>
                      </div>
                      <div className="courstatus">
                        <span className="date">{"Course Status"}</span>{" "}
                        <span className="selfTeam">{`${data?.progressPercentage}%`}</span>
                      </div>
                    </div> */}
                    <div className="progBar">
                      <ProgressBar
                        now={data?.progressPercentage}
                        variant={
                          data?.progressPercentage <= 30
                            ? "danger"
                            : data?.progressPercentage > 30 &&
                              data?.progressPercentage <= 90
                            ? "warning"
                            : data?.progressPercentage > 90
                            ? "success"
                            : ""
                          // "green" === "green" ? `success` : "yellow" === "yellow" ? "warning" : "red" === "red" ? "danger" : ""
                        }
                        // label={`${data?.progressPercentage}%`}
                        // className={`mt-1`}
                      />
                      <span>{`${data?.progressPercentage}%`}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </Carousel>
      ) : (
        <div className="noCourse">
          <img className="name_icon" src={VideoSvg}></img>
          <h5>No course available</h5>
        </div>
      )}
    </div>
  );
};

const MyJourneyProgressCarousel = (props) => {
  const {
    myJourneyDataChange,
    managerId,
    selectedRadio,
    selectedUserId,
    selectedDate,
    searchText: search,
  } = props;
  const { showLoader, hideLoader } = useContext(LoaderContext);
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  const [myJourneyProgressData, setMyJourneyProgressData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [totalRecords, setTotalRecords] = useState(0);
  const navigate = useNavigate();
  const searchText = useDebounce(search);

  const getMyJourneyData = async (userId) => {
    showLoader();
    const res = await APICall(getJourneyProgressCatalogue, "POST", {
      UserId: userId,
      Offset: currentPage,
      PageSize: itemsPerPage,
      SelectedDate: selectedDate
        ? moment(selectedDate).format("DD-MMM-YYYY")
        : null,
      searchText,
    });
    if (res.status === 0 && res.data.length > 0) {
      const myJourneyData = res.data;
      setMyJourneyProgressData(myJourneyData);
      setTotalRecords(myJourneyData[0].totalCount);
    } else {
      setMyJourneyProgressData([]);
      setTotalRecords(0);
    }
    hideLoader();
  };

  useEffect(() => {
    if (myJourneyDataChange) {
      getMyJourneyData(myJourneyDataChange);
    }
  }, [myJourneyDataChange, currentPage, selectedDate, searchText]);

  const handleNext = () => {
    // if (currentPage < Math.ceil(totalRecords / itemsPerPage) - 1) {
    setCurrentPage(currentPage + 1);
    // }
  };

  const handlePrev = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      {myJourneyProgressData.length > 0 ? (
        <Carousel
          key={currentPage}
          animation="fade"
          slidesToShow={itemsPerPage}
          slidesToScroll={1}
          wrapAround={false}
          disableEdgeSwiping
          renderBottomCenterControls={null}
          afterSlide={(newIndex) => setCurrentPage(newIndex)}
          renderCenterLeftControls={({ previousSlide }) => (
            <>
              {totalRecords > itemsPerPage && currentPage !== 0 ? (
                <button
                  className="btn btn-outline-dark"
                  onClick={handlePrev}
                  disabled={currentPage === 0}>
                  <i
                    className="fa fa-chevron-circle-left"
                    aria-hidden="true"></i>
                </button>
              ) : null}
            </>
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <>
              {totalRecords > itemsPerPage &&
              currentPage + itemsPerPage !== totalRecords ? (
                <button
                  className="btn btn-outline-dark"
                  onClick={handleNext}
                  disabled={
                    // currentPage === Math.ceil(totalRecords / itemsPerPage) - 1
                    currentPage + itemsPerPage === totalRecords ? true : false
                  }>
                  <i
                    className="fa fa-chevron-circle-right"
                    aria-hidden="true"></i>
                </button>
              ) : null}
            </>
          )}>
          {myJourneyProgressData &&
            myJourneyProgressData.length > 0 &&
            myJourneyProgressData.map((data) => (
              <div key={data} className="SlideCont">
                <div className="slide_bg">
                  <div className="vidImg">
                    <ImageColumnComponent
                      link={data.thumbnailUrl}
                      width="auto"
                    />
                  </div>
                  <div className="cont catBtn">
                    <div className="Cname">
                      <img className="name_icon" src={VideoSvg}></img>
                      <Tooltip title={`${data.title}`}>
                        <h4>{data.title}</h4>
                      </Tooltip>
                    </div>
                    <div className="Cdate">
                      <img className="name_icon" src={CalendarSvg}></img>
                      <span className="date">
                        {data.startDateString} {data.startTime}
                      </span>
                    </div>
                    <div
                      className={
                        data.isHold ? "date_btn" : "date_btn opt_view icn_all"
                      }>
                      {data?.isHold ? (
                        <button
                          className="btn btn-filter-submit hold"
                          onClick={(e) => {
                            //  content view but on hold
                            debugger;
                            navigate("/journeyProgressViewer", {
                              state: {
                                programId: data.programId,
                                launchId: data.launchId,
                                action: "view",
                                isManager:
                                  selectedRadio === "team" && managerId > 0
                                    ? true
                                    : false,
                                userId:
                                  selectedRadio === "team" && managerId > 0
                                    ? selectedUserId.value
                                    : userDetails.Id,
                              },
                            });
                          }}>
                          <i className="fas fa-exclamation-triangle"></i> On
                          Hold
                        </button>
                      ) : (
                        <>
                          <span>
                            <Tooltip title={"View"}>
                              <button
                                disabled={false}
                                onClick={() => {
                                  debugger;
                                  navigate("/journeyProgressViewer", {
                                    state: {
                                      programId: data.programId,
                                      launchId: data.launchId,
                                      action: "view",
                                      isManager:
                                        selectedRadio === "team" &&
                                        managerId > 0
                                          ? true
                                          : false,
                                      userId:
                                        selectedRadio === "team" &&
                                        managerId > 0
                                          ? selectedUserId.value
                                          : userDetails.Id,
                                    },
                                  });
                                }}
                                className="btn btn-filter-submit">
                                <ReviewsOutlinedIcon className="icn_all_mat" />
                                View
                              </button>
                            </Tooltip>
                          </span>
                        </>
                      )}
                    </div>
                    {/* <div className="Cname">
                      <img className="name_icon" src={VideoSvg}></img>
                      <span className="date">{"Journey Status"}</span>{" "}
                      <span className="selfTeam">{`${data?.progressPercentage}%`}</span>
                    </div> */}
                    <div className="Cname">
                      <div className="name_icon">
                        <i className="fa-solid fa-signal"></i>
                      </div>
                      <div className="courstatus">
                        <span className="date">{"Journey Status"}</span>{" "}
                        <span className="selfTeam">{`${data?.progressPercentage}%`}</span>
                      </div>
                    </div>
                    <div>
                      <ProgressBar
                        now={data?.progressPercentage}
                        variant={
                          data?.progressPercentage <= 30
                            ? "danger"
                            : data?.progressPercentage > 30 &&
                              data?.progressPercentage <= 90
                            ? "warning"
                            : data?.progressPercentage > 90
                            ? "success"
                            : ""
                        }
                        label={`${data?.progressPercentage}%`}
                        className={`mt-1`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </Carousel>
      ) : (
        <div className="noCourse">
          <img className="name_icon" src={VideoSvg}></img>
          <h5>No course available</h5>
        </div>
      )}
    </div>
  );
};

const Catalogue = () => {
  let { userDetails, currentRoleId }: any = useUserContext();

  const navigate = useNavigate();
  const { state } = useLocation();

  const [selectedDate, setSelectedDate] = useState(
    state !== null && state.selectedDate !== undefined
      ? state.selectedDate
      : null
  );

  const [isAdmin, setIsAdmin] = useState(false);
  const [managerId, setManagerId] = useState(0);

  const [searchText, setSearchText] = useState(null);

  useEffect(() => {
    if (
      currentRoleId?.code == "LMSAdmin" ||
      currentRoleId.label.toLowerCase() === "lm admin" ||
      currentRoleId.label.toLowerCase() === "lm super admin" ||
      currentRoleId?.code == "LMSSuperAdmin" ||
      currentRoleId?.code == "SystemAdmin" ||
      currentRoleId.label.toLowerCase() === "system admin"
    ) {
      setIsAdmin(true);
      setSelectedRadio("team");
    } else {
      setIsAdmin(false);
      checkIfManager(userDetails.Id);
    }
  }, []);

  const checkIfManager = async (UserId) => {
    const res = await APICall(CheckIfManagerById, "POST", {
      UserId,
    });
    if (res.status === 0 && res.data) {
      setManagerId(userDetails.Id);
    } else {
      setManagerId(0);
    }
  };

  const [selectedUserId, setSelectedUserId] = useState(null);

  const getSearchableDropdownAPI = async (searchString, cb) => {
    if (searchString.length > 1) {
      const response = await APICall(GetCatalogueEmployeeDropdown, "POST", {
        searchString,
        UserId: managerId,
      });
      if (response.data !== null && response.data.length > 0) {
        cb(response.data);
      } else {
        cb([]);
      }
    }
  };

  const [upComingDataChange, setUpComingDataChange] = useState(userDetails.Id);
  const [myJourneyDataChange, setMyJourneyDataChange] = useState(
    userDetails.Id
  );

  useEffect(() => {
    if (selectedUserId && selectedUserId.value) {
      setUpComingDataChange(selectedUserId.value);
      setMyJourneyDataChange(selectedUserId.value);
    } else {
      setUpComingDataChange(userDetails.Id);
      setMyJourneyDataChange(userDetails.Id);
    }
  }, [selectedUserId]);

  const [selectedRadio, setSelectedRadio] = useState("self");

  useEffect(() => {
    if (selectedRadio === "self") {
      setUpComingDataChange(userDetails.Id);
      setMyJourneyDataChange(userDetails.Id);
      setSelectedUserId(null);
    }
  }, [selectedRadio]);

  const [upcomingAcc, setUpcomingAcc] = useState(true);
  const [myJourneyAcc, setMyJourneyAcc] = useState(true);
  const [myJourneyProgress, setMyJourneyProgress] = useState(true);

  return (
    <>
      <div className="banner">
        <img
          src={require("../../Assets/Images/learning_banner.jpg")}
          alt="banner"
        />
      </div>

      <div className="grid-wrapper grid_mob mt-3">
        <div className="row mx-auto filtBox ">
          {/* <div className="float-left col-lg-3 col-md-3 col-sm-12">
            <button
              onClick={() => {
                navigate("/LMSHome");
              }}
              disabled={false}
              className="btn btn-secondary float-right">
              <i className="fa-solid fa-arrow-left"></i> Back
            </button>
          </div> */}

          <div className="col-lg-3 col-md-3 col-sm-12">
            <InputForm
              value={searchText}
              placeholder={"Search"}
              isDisabled={false}
              textArea={false}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </div>
          {state !== null && state.selectedDate !== null && (
            <div className="col-lg-3 col-md-3 col-sm-12">
              <span
                onClick={() => {
                  let currentDate = new Date(selectedDate);
                  currentDate.setDate(currentDate.getDate() - 1);
                  setSelectedDate(currentDate);
                }}
                className="mr-2 btn text-white">
                <i
                  className="fa fa-arrow-circle-left mt-3"
                  aria-hidden="true"></i>
              </span>
              <div className="d-inline-block">
                <DateForm
                  placeholder={"Date"}
                  isDisabled={false}
                  value={selectedDate}
                  onChange={(val: any) => {
                    setSelectedDate(val);
                  }}
                />
              </div>

              <span
                onClick={() => {
                  let currentDate = new Date(selectedDate);
                  currentDate.setDate(currentDate.getDate() + 1);
                  setSelectedDate(currentDate);
                }}
                className="ml-2 btn text-white">
                <i
                  className="fa fa-arrow-circle-right mt-3"
                  aria-hidden="true"></i>
              </span>
            </div>
          )}

          {state === null && (
            <>
              {managerId > 0 && (
                <div className="col-lg-3 col-md-3 col-sm-12 rad_wid">
                  <span className="selfTeam">
                    <input
                      type="radio"
                      id="self"
                      name="fav_language"
                      value="self"
                      onChange={(e) => setSelectedRadio("self")}
                      checked={selectedRadio === "self"}
                    />
                    <label htmlFor="self">Self</label>
                    <input
                      type="radio"
                      id="team"
                      name="fav_language"
                      value="team"
                      onChange={(e) => setSelectedRadio("team")}
                      checked={selectedRadio === "team"}
                    />
                    <label htmlFor="team">Team</label>
                  </span>
                </div>
              )}

              {(isAdmin || managerId !== 0) && (
                <div className="col-lg-4 col-md-5 col-sm-12 team_wid">
                  <div className="form-group filtInp">
                    <span className="mr-2 text-white" style={{ float: "left" }}>
                      <label className="col-form-label">Search Employees</label>
                    </span>
                    <SelectForm
                      isClearable
                      isSearchable
                      async
                      options={(searchString, cb) =>
                        getSearchableDropdownAPI(searchString, cb)
                      }
                      placeholder={""}
                      isDisabled={selectedRadio === "self" ? true : false}
                      value={selectedUserId}
                      onChange={async (event) => {
                        setSelectedUserId(event);
                      }}
                      isMulti={false}
                      noIndicator={false}
                      noSeparator={false}
                    />
                  </div>
                </div>
              )}
              <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12 filtrt fil_wid">
                {managerId > 0 && (
                  <button
                    onClick={() => navigate("/RequestDashboard")}
                    className="btn btn-filter-submit ml-4">
                    <i className="fa-solid fa-check"></i> My Approvals
                  </button>
                )}
                {isAdmin && (
                  <button
                    className="btn btn-filter-submit ml-4 "
                    onClick={() => {
                      navigate("/RequestDashboard");
                    }}>
                    <i className="fa-solid fa-check"></i> All Requests
                  </button>
                )}
                {!isAdmin && managerId === 0 && (
                  <button
                    className="btn btn-filter-submit ml-4 pull-right"
                    onClick={() => {
                      navigate("/RequestDashboard");
                    }}>
                    <i className="fa-solid fa-check"></i> My Requests
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <div className="col-lg-12 col-md-10 col-sm-12 "></div>
      <div className="col-lg-12 mt-3 upCourses">
        <Accordion
          elevation={0}
          className="mb-3"
          expanded={upcomingAcc}
          onChange={() => setUpcomingAcc((prev) => !prev)}>
          <AccordionSummary
            id="profile-accordion"
            style={{ background: "#2C2B7C" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}>
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <p style={{ fontSize: "16px" }}>
                  {selectedDate ? "Courses" : "Upcoming Courses"}
                </p>
              </div>
              <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
            </div>
          </AccordionSummary>

          <AccordionDetails className="page_heading">
            <UpComingCarousel
              upComingDataChange={upComingDataChange}
              managerId={managerId}
              selectedRadio={selectedRadio}
              selectedUserId={selectedUserId}
              selectedDate={selectedDate}
              searchText={searchText}
            />
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="col-lg-12 mt-3 myJourny">
        <Accordion
          elevation={0}
          className="mb-3"
          expanded={myJourneyAcc}
          onChange={() => setMyJourneyAcc((prev) => !prev)}>
          <AccordionSummary
            id="profile-accordion"
            style={{ background: "#2C2B7C" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}>
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <p style={{ fontSize: "16px" }}>My Courses</p>
              </div>
              <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
            </div>
          </AccordionSummary>

          <AccordionDetails className="page_heading">
            <MyJourneyCarousel
              myJourneyDataChange={myJourneyDataChange}
              managerId={managerId}
              selectedRadio={selectedRadio}
              selectedUserId={selectedUserId}
              selectedDate={selectedDate}
              searchText={searchText}
            />
          </AccordionDetails>
        </Accordion>
      </div>

      <div className="col-lg-12 mt-3 myJourny">
        <Accordion
          elevation={0}
          className="mb-3"
          expanded={myJourneyProgress}
          onChange={() => setMyJourneyProgress((prev) => !prev)}>
          <AccordionSummary
            id="profile-accordion"
            style={{ background: "#2C2B7C" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}>
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <p style={{ fontSize: "16px" }}>My Journey Progress</p>
              </div>
              <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
            </div>
          </AccordionSummary>

          <AccordionDetails className="page_heading">
            <MyJourneyProgressCarousel
              myJourneyDataChange={myJourneyDataChange}
              managerId={managerId}
              selectedRadio={selectedRadio}
              selectedUserId={selectedUserId}
              selectedDate={selectedDate}
              searchText={searchText}
            />
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};

export default Catalogue;
