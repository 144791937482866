import React, { useContext, useEffect, useState } from "react";
import SelectForm from "../../Components/SelectForm/SelectForm";
import InputForm from "../../Components/InputForm/InputForm";
import { APICall } from "../../Helpers/APICalls";
import { LoaderContext } from "../../Helpers/Context/Context";
import notify from "../../Helpers/ToastNotification";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  InsertUpdateCandidateOperationMapping,
  GetCandidateOperationMappingDashboardData,
  getDropdowns,
  GetOperationBasedOnDivisionMappingData,
} from "../../Helpers/APIEndPoints/EndPoints";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { Collapse, Tooltip } from "@mui/material";
import { event } from "jquery";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";

const MappingConfig = () => {
  const [pinBoard, setPinBoard] = useState<number | string>("");
  const [formBoard, setFormBoard] = useState<number | string>("");
  const [Perception, setPerception] = useState<number | string>("");
  const [TotalPoints, setTotalPoints] = useState<number | string>("");
  const [formErrors, setFormErrors] = useState<any>({});
  const [MDDropdownOptions, setMDDropdownOptions] = useState([]);
  const [MDDropdownValue, setMDDropdownValue] = useState(null);
  const [MDErrorDropdown, setMDErrorDropdown] = useState("");

  const [MOprDropdownOptions, setMOprDropdownOptions] = useState([]);
  const [MOprDropdownValue, setMOprDropdownValue] = useState(null);
  const [MOprErrorDropdown, setMOprErrorDropdown] = useState("");
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const Navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [PageSize, setPageSize] = useState(10);
  const [sortDirection, setSortDirection] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [searchText, setSearchText] = useState("");
  const [start, setStart] = useState(0);
  let { userDetails, currentRoleId }: any = useUserContext();
  const [selectedId, setSelectedId] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);

  const [isActive, setIsActive] = useState(true);

  const columns = [
    //0
    {
      name: "id",
      label: "Id",
      options: { display: false },
    },
    //1
    {
      name: "pinBoardPoint",
      label: "PinBoard Points",
      options: { display: true, sort: true, filter: false },
    },
    //2
    {
      name: "formBoardPoint",
      label: "Form Board Point",
      options: { display: true, sort: true, filter: false },
    },
    //3
    {
      name: "perceptionPoint",
      label: "Perception Point",
      options: { display: true, sort: true, filter: false },
    },
    //4
    {
      name: "totalPoint",
      label: "TotalPoints",
      options: { display: true, sort: true, filter: false },
    },
    //5
    {
      name: "mappedDivisionName",
      label: "Mapped Division",
      options: { display: true, sort: true, filter: false },
    },
    //6
    {
      name: "mappedOperationName",
      label: "Mapped Operation",
      options: { display: true, sort: true, filter: false },
    },
    //7
    {
      name: "mappedDivisionOU",
      label: "Mapped Operation",
      options: { display: false, sort: false, filter: false },
    },
    //8
    {
      name: "mappedOperationOU",
      label: "mappedOperationOU",
      options: { display: false, sort: false, filter: false },
    },
    //9
    {
      name: "isActive",
      label: "Status",
      options: {
        display: true,
        sort: true,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return value ? "Active" : "InActive";
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          let isActive = tableMeta.tableData[tableMeta.rowIndex].isActive;

          return (
            <>
              <Tooltip title="Edit">
                <i
                  style={{ marginRight: "0px", cursor: "pointer" }}
                  onClick={() => {
                    handleEdit(tableMeta.rowData, isActive);
                  }}
                  className="fas fa-edit"
                ></i>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: PageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",
    onChangePage: async (page) => {
      setPage(page);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      setPage(page);
      if (sortDirection === "asc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
    },
  };

  const CheckValidation = () => {
    let objError: any = {};
    let error = false;
    setFormErrors({});

    if (Perception === "" || Perception === undefined || Perception === null) {
      error = true;
      objError["Perception_isEmpty"] = "Perception is required";
    } else if (
      parseInt(Perception as string) < 1 ||
      parseInt(Perception as string) > 100
    ) {
      error = true;
      objError["Perception_Points_OutOfRange"] =
        "Perception points must be between 1 to 100";
    }
    if (pinBoard === "" || pinBoard === undefined || pinBoard === null) {
      error = true;
      objError["Pinboard_Points_isEmpty"] = "Pinboard points is required";
    } else if (
      parseInt(pinBoard as string) < 1 ||
      parseInt(pinBoard as string) > 100
    ) {
      error = true;
      objError["Pinboard_Points_OutOfRange"] =
        "Pinboard points must be between 1 to 100";
    }
    if (formBoard === "" || formBoard === undefined || formBoard === null) {
      error = true;
      objError["FormBoard_points_isEmpty"] = "Formboard points is required";
    } else if (
      parseInt(formBoard as string) < 1 ||
      parseInt(formBoard as string) > 100
    ) {
      error = true;
      objError["FormBoard_points_OutOfRange"] =
        "Formboard points must be between 1 to 100";
    }
    if (
      TotalPoints === "" ||
      TotalPoints === undefined ||
      TotalPoints === null
    ) {
      error = true;
      objError["TotalPoints_isEmpty"] = "TotalPoints is required";
    }

    if (!MDDropdownValue) {
      setMDErrorDropdown("Please select Mapped Division.");
      error = true;
    }
    if (!MOprDropdownValue) {
      setMOprErrorDropdown("Please select Mapped Operation");
      error = true;
    }

    // if (
    //   MOprErrorDropdown === "" ||
    //   MOprErrorDropdown === undefined ||
    //   MOprErrorDropdown === null
    // ) {
    //   error = true;
    //   objError["MOprErrorDropdown_isEmpty"] = "Please select Mapped Operation";
    //   // setMOprErrorDropdown("Please select Mapped Operation");
    // }

    // if (
    //   MDErrorDropdown === "" ||
    //   MDErrorDropdown === undefined ||
    //   MDErrorDropdown === null
    // ) {
    //   error = true;
    //   objError["MDErrorDropdown_isEmpty"] = "Please select Mapped Division";
    //   // setMDErrorDropdown("Please select Mapped Division");
    // }
    setFormErrors(objError);
    return error;
  };

  const calculateTotalPoints = (newFormBoard, newPinBoard, newPerception) => {
    const total =
      (newFormBoard ? newFormBoard : 0) +
      (newPinBoard ? newPinBoard : 0) +
      (newPerception ? newPerception : 0);
    setTotalPoints(total);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      TotalPoints_isEmpty: "",
    }));
  };

  const handleOnChangeForm = (event) => {
    const value = event.target.value;
    if (value === "") {
      setFormBoard("");
      calculateTotalPoints(0, pinBoard, Perception);
    } else if (/^\d*$/.test(value)) {
      const newValue = parseInt(value, 10);
      setFormBoard(newValue);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        FormBoard_points_isEmpty: "",
        FormBoard_points_OutOfRange: "",
      }));
      calculateTotalPoints(newValue, pinBoard, Perception);
    }
  };

  const handleOnChangePin = (event) => {
    const value = event.target.value;
    if (value === "") {
      setPinBoard("");
      calculateTotalPoints(formBoard, 0, Perception);
    } else if (/^\d*$/.test(value)) {
      const newValue = parseInt(value, 10);
      setPinBoard(newValue);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        Pinboard_Points_isEmpty: "",
        Pinboard_Points_OutOfRange: "",
      }));
      calculateTotalPoints(formBoard, newValue, Perception);
    }
  };

  const handleOnChangePerception = (event) => {
    const value = event.target.value;
    if (value === "") {
      setPerception("");
      calculateTotalPoints(formBoard, pinBoard, 0);
    } else if (/^\d*$/.test(value)) {
      const newValue = parseInt(value, 10);
      setPerception(newValue);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        Perception_isEmpty: "",
      }));
      calculateTotalPoints(formBoard, pinBoard, newValue);
    }
  };

  const getOperationBasedOnDivisionMappingDataApiCall = async (divisionId) => {
    showLoader();
    try {
      const GetOperationData = await APICall(
        GetOperationBasedOnDivisionMappingData,
        "POST",
        {
          DivisionId: divisionId,
        }
      );

      if (GetOperationData.status === 0) {
        if (GetOperationData?.data.length > 0) {
          setMOprDropdownOptions(GetOperationData?.data);
          return GetOperationData?.data;
        } else {
          setMOprDropdownOptions([]);
          return [];
        }
      } else if (GetOperationData.status === 1) {
        notify(GetOperationData.status, GetOperationData.message);

        return [];
      }
    } catch (error) {
      console.error("Error on GetOperationBasedOnDivisionMappingData :", error);
    } finally {
      hideLoader();
    }
  };

  const getGridStructure = async () => {
    showLoader();
    try {
      const GetDashboardData = await APICall(
        GetCandidateOperationMappingDashboardData,
        "POST",
        {
          PageNumber: page,
          PageSize: PageSize,
          SortColumn: sortColumn,
          SortOrder: sortDirection,
          SearchText: searchText,
        }
      );

      if (GetDashboardData.data && GetDashboardData.data.length > 0) {
        setRowData(GetDashboardData.data);
        setCount(GetDashboardData.totalCount);
        hideLoader();
      } else {
        setRowData([]);
        setCount(0);
        hideLoader();
      }
    } catch (error) {
      console.error("Error fetching grid data:", error);
      hideLoader();
    }
    hideLoader();
  };

  const InsertUpdateActivityDetails = async () => {
    showLoader();
    let IsInValid = await CheckValidation();

    let requestParams = {
      Id: Number(selectedId),
      PinBoardPoint: pinBoard,
      FormBoardPoint: formBoard,
      PerceptionPoint: Perception,
      TotalPoint: TotalPoints,
      MappedDivisionOU: MDDropdownValue,
      MappedOperationOU: MOprDropdownValue?.value,
      ModifiedBy: userDetails.Id,
      IsActive: isActive,
    };

    if (IsInValid == false) {
      const GetDashboardData = await APICall(
        InsertUpdateCandidateOperationMapping,
        "POST",
        requestParams
      );

      if (GetDashboardData.status === 0) {
        notify(0, "Data is updated.");
        setSelectedId("");
        setPinBoard("");
        setFormBoard("");
        setPerception("");
        setTotalPoints(0);
        setIsActive(true);
        setIsEditMode(false);
        setMDDropdownValue(null || "");
        setMOprDropdownValue(null);
        setMOprDropdownOptions([]);
        getGridStructure();
        hideLoader();
      } else {
        notify(1, "Data is not updated.");
        getGridStructure();
        hideLoader();
      }
    }
    hideLoader();
  };

  const handleMDChange = (selectedOption: any) => {
    showLoader();
    setMDDropdownValue(selectedOption ? selectedOption.value : null);
    // if (selectedOption) {
    //   setFormErrors((prevErrors) => ({
    //     ...prevErrors,
    //     MDErrorDropdown_isEmpty: "",
    //   }));
    // }

    setMOprDropdownValue(null);

    if (selectedOption?.value > 0) {
      getOperationBasedOnDivisionMappingDataApiCall(selectedOption?.value);
    } else {
      setMOprDropdownOptions([]);
    }

    setMDErrorDropdown("");
    hideLoader();
  };

  const handleMOprChange = (selectedOption: any) => {
    showLoader();
    setMOprDropdownValue(selectedOption);
    // if (selectedOption) {
    //   setFormErrors((prevErrors) => ({
    //     ...prevErrors,
    //     MOprErrorDropdown_isEmpty: "",
    //   }));
    // }
    setMOprErrorDropdown("");
    hideLoader();
  };

  const fetchMDData = async () => {
    try {
      const responseMod = await APICall(getDropdowns, "POST", {
        AC: "OUMappingValues",
        PID: "Division",
      });

      if (responseMod.data && responseMod.data.length > 0) {
        const MappedDivisionArr = responseMod.data.map((item) => ({
          label: item.label,
          value: item.value,
          id: item.Id,
        }));
        // const firstItem = responseMod.Data;
        setMDDropdownOptions(MappedDivisionArr);
        setMDDropdownValue(MappedDivisionArr.value);
      } else {
        setMDDropdownOptions([]);
        setMDDropdownValue(null);
      }

      //console.log(responseMod.data);
    } catch (error) {
      console.error("Error fetching Module data:", error);
    }
  };

  const fetchMOData = async () => {
    try {
      const responseMod = await APICall(getDropdowns, "POST", {
        AC: "OUMappingValues",
        PID: "Operations",
      });

      if (responseMod.data && responseMod.data.length > 0) {
        const MappedOprationArr = responseMod.data.map((item) => ({
          label: item.label,
          value: item.value,
          id: item.Id,
        }));
        setMOprDropdownOptions(MappedOprationArr);
        setMOprDropdownValue(MappedOprationArr.value);
      } else {
        setMOprDropdownOptions([]);
        setMOprDropdownValue(null);
      }

      //console.log(responseMod.data);
    } catch (error) {
      console.error("Error fetching Module data:", error);
    }
  };

  useEffect(() => {
    fetchMDData();
    // fetchMOData();
  }, []);

  useEffect(() => {
    showLoader();
    getGridStructure();
    hideLoader();
  }, [page, PageSize, start, sortDirection, sortColumn]);

  const handleEdit = async (rowData, isActive) => {
    setSelectedId(rowData[0]);
    setPinBoard(rowData[1]);
    setFormBoard(rowData[2]);
    setPerception(rowData[3]);
    setTotalPoints(rowData[4]);
    setMDDropdownValue(rowData[7]);
    // setMOprDropdownValue(rowData[8]);
    const divisionId = rowData[7];

    if (divisionId > 0) {
      const operationOptionsData =
        await getOperationBasedOnDivisionMappingDataApiCall(divisionId);

      const optionsData = operationOptionsData?.find(
        (env) => env?.value === rowData[8]
      );

      setMOprDropdownValue(optionsData);
    }

    setIsEditMode(true);
    setIsActive(isActive);
    setMDErrorDropdown("");
    setMOprErrorDropdown("");
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      FormBoard_points_isEmpty: "",
      Pinboard_Pointsmpty: "",
      Perception_isEmpty: "",
      TotalPoints_isEmpty: "",
      Pinboard_MinGreaterThanMax: "",
      Pinboard_PointsOfRange: "",
      FormBoard_points_OutOfRange: "",
      Pinboard_Points_OutOfRange: "",
      // MDErrorDropdown_isEmpty: "",
      // MOprErrorDropdown_isEmpty: "",
    }));
  };

  const handleReset = () => {
    console.log("MOprDropdownValue", MOprDropdownValue);
    setSelectedId("");
    setPinBoard("");
    setFormBoard("");
    setPerception("");
    setTotalPoints(0);
    setIsEditMode(false);
    setIsActive(true);
    getGridStructure();
    setMDErrorDropdown("");
    setMOprErrorDropdown("");
    setMDDropdownValue(0);
    setMOprDropdownValue(0);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      FormBoard_points_isEmpty: "",
      Pinboard_Points_isEmpty: "",
      Perception_isEmpty: "",
      TotalPoints_isEmpty: "",
      Pinboard_PointsOfRange: "",
      FormBoard_points_OutOfRange: "",
      Pinboard_Points_OutOfRange: "",
      // MDErrorDropdown_isEmpty: "",
      // MOprErrorDropdown_isEmpty: "",
    }));
    console.log("MOprDropdownValue", MOprDropdownValue);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-3 col-sm-3 col-xs-4">
              <div className="mb-1">
                <label className="col-form-label">
                  Pinboard points<sup>*</sup>
                </label>
                <InputForm
                  isDisabled={false}
                  textArea={false}
                  value={pinBoard}
                  placeholder={"Range 1 - 100"}
                  onChange={handleOnChangePin}
                />
                <p style={{ color: "red" }}>
                  {formErrors["Pinboard_Points_isEmpty"]}
                  {formErrors.Pinboard_Points_OutOfRange && (
                    <div className="error-message">
                      {formErrors.Pinboard_Points_OutOfRange}
                    </div>
                  )}
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-sm-3 col-xs-4">
              <div className="mb-1">
                <label className="col-form-label">
                  Formboard points<sup>*</sup>
                </label>
                <InputForm
                  value={formBoard}
                  isDisabled={false}
                  placeholder={"Range 1 - 100"}
                  onChange={handleOnChangeForm}
                  textArea={false}
                />
                <p style={{ color: "red" }}>
                  {formErrors["FormBoard_points_isEmpty"]}
                  {formErrors.FormBoard_points_OutOfRange && (
                    <div className="error-message">
                      {formErrors.FormBoard_points_OutOfRange}
                    </div>
                  )}
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-sm-3 col-xs-4">
              <div className="mb-1">
                <label className="col-form-label">
                  Perception points<sup>*</sup>
                </label>
                <InputForm
                  value={Perception}
                  placeholder={"Range 1-100"}
                  isDisabled={false}
                  onChange={handleOnChangePerception}
                  textArea={false}
                />
                <p style={{ color: "red" }}>
                  {formErrors["Perception_isEmpty"]}
                  {formErrors.Perception_points_OutOfRange && (
                    <div className="error-message">
                      {formErrors.Perception_points_OutOfRange}
                    </div>
                  )}
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-sm-3 col-xs-4">
              <div className="mb-1">
                <label className="col-form-label">
                  TotalPoints<sup>*</sup>
                </label>
                <InputForm
                  value={TotalPoints}
                  placeholder={"TotalPoints"}
                  isDisabled={true}
                  onChange={() => {}}
                  textArea={false}
                />
                <p style={{ color: "red" }}>
                  {formErrors["TotalPoints_isEmpty"]}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-3">
          <div className="form-group mt-3">
            <label className="col-form-label" htmlFor="dropdown3">
              Mapped Division<sup>*</sup>
            </label>
            <SelectForm
              isClearable
              isSearchable
              isMulti={false}
              noIndicator={false}
              noSeparator={false}
              id="dropdown1"
              value={
                MDDropdownOptions
                  ? MDDropdownOptions.find(
                      (option) => option.value === MDDropdownValue
                    ) || null
                  : null
              }
              placeholder="Select an option"
              options={MDDropdownOptions}
              onChange={handleMDChange}
            />
            <p style={{ color: "red" }}>
              {/* {formErrors["MDErrorDropdown_isEmpty"]} */}
              {MDErrorDropdown}
            </p>
          </div>
        </div>

        <div className="col-md-3">
          <div className="form-group mt-3">
            <label className="col-form-label" htmlFor="dropdown3">
              Mapped Operation<sup>*</sup>
            </label>
            <SelectForm
              isClearable
              isSearchable
              isMulti={false}
              noIndicator={false}
              noSeparator={false}
              id="dropdown2"
              value={MOprDropdownValue}
              placeholder="Select an option"
              options={MOprDropdownOptions}
              onChange={handleMOprChange}
            />
            <p style={{ color: "red" }}>
              {/* {formErrors["MOprErrorDropdown_isEmpty"]} */}
              {MOprErrorDropdown}
            </p>
          </div>
        </div>

        <div className="col-lg-3 col-sm-3 col-xs-4 mt-3">
          <div className="mb-1">
            <label className="col-form-label">
              Status<sup>*</sup>
            </label>
            <div>
              <input
                type="checkbox"
                className="form-check-input"
                checked={isActive}
                style={{ marginLeft: "0px" }}
                onChange={(e) => setIsActive(e.target.checked)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 col-sm-12 col-xs-12 mt-3">
          <div style={{ textAlign: "end" }}>
            <button
              onClick={handleReset}
              disabled={false}
              className="btn btn-secondary float-right ml-2"
            >
              <i className="fa fa-undo"></i> Reset
            </button>

            <Button
              className="btn btn-filter-submit float-right btnBlue"
              onClick={InsertUpdateActivityDetails}
            >
              <i className="fa-solid fa-check"></i>{" "}
              {isEditMode ? "Update" : "Submit"}
            </Button>
          </div>
        </div>
      </div>

      <div className="row">
        <div className=" col-lg-12 mb-3 pt-3">
          <DynamicGrid data={rowData} columns={columns} options={options} />
        </div>
      </div>
    </div>
  );
};

export default MappingConfig;
