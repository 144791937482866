import React, { useContext, useEffect, useRef, useState } from "react";
import { LoaderContext } from "../../Helpers/Context/Context";
import { useLocation, useNavigate } from "react-router-dom";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { APICall } from "../../Helpers/APICalls";
import {
  SetThumbnailDetails,
  getCertificateData,
  getLMSDomainDropDownData,
  getUserCertificateFile,
  saveCertificateData,
  validateCertificatePlaceholders,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import notify from "../../Helpers/ToastNotification";
import InputForm from "../../Components/InputForm/InputForm";
import SelectForm from "../../Components/SelectForm/SelectForm";
import { base64ToArrayBuffer } from "../../Helpers/Common";
import fileDownload from "js-file-download";
import { Tooltip } from "@mui/material";

interface IOptions {
  label?: string;
  value?: number | string;
  code?: string;
}

interface IFormData {
  certificateId?: string | any;
  certificateName?: string | any;
  domain?: IOptions[] | string | any;
}

const CertificateEdit = () => {
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isDisable, setIsDisable] = useState(
    state && state?.isDisable ? true : false
  );
  let { userDetails, currentRoleId }: any = useUserContext();
  const [formData, setFormData] = useState<IFormData>({});
  const [formErrors, setFormErrors] = useState<IFormData>({});
  const [formOptions, setFormOptions] = useState<IFormData>({});
  const [showModal, setShowModal] = useState(false);

  const [file, setFile] = useState(null);
  const fileRef = useRef(null);
  useEffect(() => {
    showLoader();

    getLMSDomainDropDownDataApiCall();

    hideLoader();
  }, []);

  const getLMSDomainDropDownDataApiCall = async () => {
    const response = await APICall(getLMSDomainDropDownData, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      GetAll: state && state?.certificateId > 0 ? true : false,
    });

    if (response?.status === 0 && response?.data.length > 0) {
      setFormOptions((prev) => {
        return {
          ...prev,
          domain: response?.data,
        };
      });
    }
  };

  const formOnChange = (e, type) => {
    switch (type) {
      case "certificateName":
        setFormData({
          ...formData,
          certificateName: e.target.value,
        });
        break;

      case "domain":
        setFormData({
          ...formData,
          domain: e,
        });
        break;

      case "file":
        const files = e.target.files[0] || null;
        if (!files) {
          setFile(null);
          notify(1, "Please select a file.");
          fileRef.current.value = "";
          return;
        }

        handleFileChange(e);
        break;

      default:
        break;
    }
  };

  const fileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    let fileBase64: { Name: string; Data: string } = null;
    const base64 = (await fileToBase64(file)) as string;
    const base64WithoutPrefix = base64.split(",")[1];
    fileBase64 = { Name: file.name, Data: base64WithoutPrefix };

    //".docx,.doc,.pdf,.png,.jpg,.jpeg,.txt,.ppt,.pptx,.xlsx"
    let validExtensions = ["docx"];

    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();
      const fileSize = file.size;
      const maxFileSizeBytes = 5 * 1024 * 1024;
      // Check if the file extension is allowed
      if (
        validExtensions.includes(fileExtension) &&
        fileSize <= maxFileSizeBytes
      ) {
        // file to upload
        setFile(fileBase64);
      } else {
        fileRef.current.value = "";
        e.target.value = "";
        notify(1, "Only file with .docx allowed and size under 5MB");
        setFile(null);
      }
    }
  };

  const handleFileUpload = async (id: any) => {
    if (file && file.Data !== null) {
      showLoader();
      let reqObj = {
        file: {
          Name: file.Name,
          Data: file.Data,
        },
        PId: id,
        SectionName: "Certificate",
        UserId: userDetails?.UserId,
        CreatedBy: userDetails?.UserId,
      };

      try {
        const response = await APICall(SetThumbnailDetails, "POST", reqObj);
        if (response && response.data) {
          hideLoader();
          return true;
        } else {
          console.error("Certificate upload failed");
          notify(1, "Certificate upload failed.");
          hideLoader();
          return false;
        }
      } catch (error) {
        console.error("Error uploading certificate:", error);
        hideLoader();
        return false;
      }
    } else {
      // no file change
      return true;
    }
  };

  const validateForm = () => {
    let errorObj = {};
    let isError = false;
    setFormErrors({});

    if (
      formData?.certificateName === "" ||
      formData?.certificateName === null ||
      formData?.certificateName === undefined
    ) {
      errorObj = {
        ...errorObj,
        ["certificateName"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["certificateName"]: "",
      };
    }

    if (
      formData?.domain?.length === 0 ||
      formData?.domain === null ||
      formData?.domain === undefined
    ) {
      errorObj = {
        ...errorObj,
        ["domain"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["domain"]: "",
      };
    }

    if (file === null || file === undefined) {
      errorObj = {
        ...errorObj,
        ["file"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["file"]: "",
      };
    }

    const isEmpty = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  const requestParamsFun = () => {
    // let base64 = await getBase64FromFilePromise(file);
    // base64 = String(base64).split("base64,")[1];

    const requestParams = {
      Id:
        state === null ||
        state?.certificateId === null ||
        state?.certificateId === undefined
          ? 0
          : state?.certificateId,
      CertificateName: formData?.certificateName,
      DomainIds: formData?.domain
        ? formData?.domain?.map((j) => j.value).join(",")
        : null,
      FileBase64: file,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    };

    return requestParams;
  };

  const validateFile = async () => {
    if (file && file.Data !== null) {
      showLoader();
      const res = await APICall(validateCertificatePlaceholders, "POST", {
        Id:
          state === null ||
          state?.certificateId === null ||
          state?.certificateId === undefined
            ? 0
            : state?.certificateId,
        FileBase64: file,
      });

      if (res.status === 0 && res.data) {
      } else if (res.status === 0 && !res.data) {
        notify(1, "Invalid Placeholder found");
      } else {
        console.log("file validation error", res);
      }
      hideLoader();
      return res.data;
    } else {
      // no file change
      return true;
    }
  };

  const handleSubmitFunction = async () => {
    showLoader();

    if (validateForm()) {
      hideLoader();
      return;
    }

    const fileIsValid = await validateFile();

    if (fileIsValid) {
      await insertUpdateData();
    }

    hideLoader();
  };

  const insertUpdateData = async () => {
    showLoader();

    const requestParams = requestParamsFun();

    const response = await APICall(saveCertificateData, "POST", requestParams);

    if (response?.status === 0) {
      const isFileUploaded = await handleFileUpload(response.data.id);
      if (isFileUploaded) {
        notify(response?.status, response?.message);
        navigate("/certificateDashboard");
      }
    } else if (response?.status === 1) {
      notify(response?.status, response?.message);
    }

    hideLoader();
  };

  useEffect(() => {
    if (state?.certificateId > 0 && formOptions?.domain?.length > 0) {
      getCertificateDataApiCall();
    }
  }, [formOptions?.domain]);

  const getCertificateDataApiCall = async () => {
    showLoader();
    if (state?.certificateId > 0) {
      try {
        const responseData = await APICall(getCertificateData, "POST", {
          Id: state?.certificateId,
        });
        if (responseData?.status === 0) {
          const domainIds = responseData?.data?.domainIds?.split(",") || null;
          const domainArray = [];
          if (domainIds && formOptions?.domain?.length > 0) {
            domainIds?.map((item) => {
              const domainData = formOptions?.domain?.find(
                (i) => i.value === parseInt(item)
              );
              domainArray.push(domainData);
            });
          }
          setFormData((env) => {
            return {
              ...env,
              certificateId: responseData?.data?.certificateCode,
              certificateName: responseData?.data?.certificateName,

              domain: domainArray.length > 0 ? domainArray : null,
            };
          });

          const fileName = responseData?.data?.certificateUrl.split("/").pop();

          setFile(
            responseData?.data?.certificateUrl
              ? { Name: fileName, Data: null }
              : null
          );
        } else if (responseData?.status === 1) {
          notify(responseData?.status, responseData?.message);
        }
      } catch (error) {
        console.error(error);
      }
    }
    hideLoader();
  };

  const resetFunction = () => {
    setFormData((env) => {
      return {
        ...env,
        assessmentName: "",
        domain: null,
      };
    });
  };

  const viewCertificate = async () => {
    showLoader();
    const response = await APICall(getUserCertificateFile, "POST", {
      UserId: userDetails.Id,
      ThumbnailUrl: file.Name,
    });

    if (response.status === 0 && response.data !== null) {
      const base64 = response?.data;
      const fileName = "Certificate_template.docx";
      let arrayBuffer = await base64ToArrayBuffer(base64);
      var byteArray = new Uint8Array(arrayBuffer);

      let blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });

      const blobToUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobToUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Revoke the object URL after download
      URL.revokeObjectURL(blobToUrl);
    } else {
      notify(1, "Something went wrong try again later");
    }
    hideLoader();
  };

  return (
    <>
      <div className="breadcrumb modified-bredcrumb">
        <span>
          <ul>
            <li>
              <a href="/home">Home</a>
            </li>
            <li>
              {" "}
              <a href="/certificateDashboard">Certificate</a>
            </li>
            <li>Manage Certificate</li>
          </ul>
        </span>
        <button
          onClick={() => {
            navigate("/certificateDashboard");
          }}
          disabled={false}
          className="btn btn-secondary float-right">
          <i className="fa-solid fa-arrow-left"></i> Back
        </button>
      </div>

      <div className="container-fluid">
        <div className="row">
          {/* <div className="col-md-12">
            <div className="row"> */}
          <div className={"col-lg-2 col-sm-4 col-xs-4"}>
            <div className="mb-1">
              <label className="col-form-label">
                Certificate ID
                <sup>*</sup>
              </label>

              <InputForm
                className="form-control"
                placeholder={"Certificate ID"}
                isDisabled={true}
                textArea={false}
                value={formData.certificateId}
                onChange={(e) => {}}
                maxLength="255"
              />
              {formErrors["certificateId"] && (
                <p style={{ color: "red" }}>{formErrors["certificateId"]}</p>
              )}
            </div>
          </div>
          <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="mb-1">
              <label className="col-form-label">
                Certificate Name
                <sup>*</sup>
              </label>

              <InputForm
                className="form-control"
                placeholder={"Certificate Name"}
                isDisabled={isDisable}
                textArea={false}
                value={formData?.certificateName}
                onChange={(e) => {
                  formOnChange(e, "certificateName");
                }}
                maxLength="255"
              />
              {formErrors["certificateName"] && (
                <p style={{ color: "red" }}>{formErrors["certificateName"]}</p>
              )}
            </div>
          </div>

          <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="dropdown mb-1">
              <label className="col-form-label">Domains</label> <sup>*</sup>
              <SelectForm
                isClearable
                isSearchable
                options={formOptions?.domain}
                isOptionDisabled={(option) => !option?.isEnabled}
                placeholder={"Domains"}
                isDisabled={isDisable}
                value={formData?.domain}
                onChange={(e) => {
                  formOnChange(e, "domain");
                }}
                isMulti={true}
                noIndicator={false}
                noSeparator={false}
              />
              {formErrors["domain"] && (
                <p style={{ color: "red" }}>{formErrors["domain"]}</p>
              )}
            </div>
          </div>

          <div className="col-lg-4 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Certificate File
                <sup>*</sup>
              </label>
              <div className="box position-relative">
                <input
                  id="file"
                  className="form-control inputfile inputfile-6 multiple-inputfile"
                  data-multiple-caption="{count} files selected"
                  multiple={false}
                  type="file"
                  onChange={(e) => {
                    formOnChange(e, "file");
                  }}
                  accept={".docx"}
                  ref={fileRef}
                  value={""}
                  disabled={isDisable}
                />
                <label
                  htmlFor={`file`}
                  className="form-control"
                  style={{ width: 0, border: "none" }}>
                  <strong
                    style={{
                      padding: "6px 16px",
                      backgroundColor: "#3c5464",
                      borderRadius: "5px",
                    }}>
                    <i className="fa fa-upload rotate90" aria-hidden="true"></i>{" "}
                    Upload
                  </strong>
                </label>
              </div>
              <div className="file-added-list">
                <ul className="list-unstyle">
                  <li className="list mt-1">
                    <div className="media">
                      <div className="media-body text-truncate">
                        <span className="view-more">{file?.Name}</span>{" "}
                        {file && file.Data === null && (
                          <Tooltip title="View">
                            <i
                              style={{ cursor: "pointer" }}
                              className="fas fa-eye ml-1"
                              aria-hidden="true"
                              onClick={(e) => {
                                viewCertificate();
                              }}></i>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              {formErrors["file"] && (
                <p style={{ color: "red" }}>{formErrors["file"]}</p>
              )}
            </div>
          </div>
        </div>

        <br />
        {!isDisable && (
          <div className="SectionSubmit mb-4 clearfix">
            <button
              onClick={() => {
                resetFunction();
              }}
              disabled={false}
              className="btn btn-secondary float-right ml-2">
              <i className="fa-solid fa-refresh"></i> Reset
            </button>

            <button
              onClick={() => {
                handleSubmitFunction();
              }}
              disabled={false}
              className="btn btn-filter-submit float-right ml-2">
              <i className="fa-solid fa-check"></i> Save
            </button>
          </div>
        )}

        {/* {fileUrl && (
          <div className="row">
            <iframe
              src={fileUrl}
              title="Document Viewer"
              width="100%"
              height="500px"
              style={{ border: "none" }}
            />
          </div>
        )} */}
      </div>
    </>
  );
};

export default CertificateEdit;
