import React, { useState, useEffect, useContext } from "react";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { useNavigate } from "react-router-dom";
import "./WorkFlowDashboard.css";
import { APICall } from "../../Helpers/APICalls";
import {
  getEmailTEmpleteDashboardDetails,
  getEmailTEmpleteExcelDetails,
} from "../../Helpers/APIEndPoints/EndPoints";
import InputForm from "../../Components/InputForm/InputForm";
import { Tooltip } from "@mui/material";
import { LoaderContext } from "../../Helpers/Context/Context";
import ExcelJS from "exceljs";

const EmailTemplateDashboard = () => {
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [PageSize, setPageSize] = useState(10);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  const [panal1, setPanal1] = useState(true);
  const [sortDirection, setSortDirection] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    showLoader();
    getGridStructureTwo();
    hideLoader();
  }, [page, searchText, sortDirection, sortColumn]);

  // Export Excel with search
  const toTitleCase = (str) => {
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  };

  // Export to excel
  const exportToExcel = async () => {
    debugger;
    const response = await APICall(getEmailTEmpleteExcelDetails, "POST", {
      SearchText: searchText,
    });

    const responseData = response.data;

    if (!responseData || responseData.length === 0) {
      console.error("No data to export.");
      return;
    }

    const columnsToInclude = [
      "name",
      "systemName",
      "subject",
      // "emailBody",
      "plainEmailBody",
      "isActive",
    ];

    const headers = Object.keys(responseData[0])?.filter((header) =>
      columnsToInclude.includes(header)
    );

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Report");

    // Space in width
    const columnWidths = headers.map((header) => {
      const maxContentLength = Math.max(
        ...responseData.map((row) =>
          row[header] ? String(row[header]).length : 0
        )
      );
      return Math.min(200, Math.max(10, maxContentLength * 1.5));
    });

    // Set column widths
    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width;
    });

    const formattedHeaders = headers.map((header) =>
      toTitleCase(header.replace(/([a-z])([A-Z])/g, "$1 $2"))
    );

    // Add the formatted "emailBody" to headers
    const emailBodyIndex = headers.indexOf("plainEmailBody");
    if (emailBodyIndex !== -1) {
      formattedHeaders[emailBodyIndex] = "Plain Email Body";
    }

    worksheet.addRow(formattedHeaders);
    worksheet.getRow(1).font = { bold: true };
    responseData.font = { bold: true };
    responseData.forEach((row) => {
      const dataRow = headers.map((header) => {
        if (header === "isActive") {
          return row[header] ? "Yes" : "No";
        }
        if (header === "plainEmailBody") {
          // Insert a new line after periods (.) and commas (,)
          const plainEmailBodyWithLineBreaks = row[header].replace(
            /[\.,]/g,
            (match) => {
              return match === "." || match === "," ? match + "\n" : match;
            }
          );

          return plainEmailBodyWithLineBreaks;
        }
        return row[header] || "";
      });

      worksheet.addRow(dataRow);
      worksheet.getRow(worksheet.rowCount).height = 75;
    });
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "Email Template Report.xlsx";
      a.click();

      URL.revokeObjectURL(url);
    });
  };

  // Fetch Dashboard records
  const getGridStructureTwo = async () => {
    showLoader();
    try {
      const GetDashboardData = await APICall(
        getEmailTEmpleteDashboardDetails,
        "POST",
        {
          PageNumber: page,
          PageSize: PageSize,
          SearchText: searchText,
          SortColumn: sortColumn,
          SortOrder: sortDirection,
        }
      );

      if (GetDashboardData.data && GetDashboardData.data.length > 0) {
        setRowData(GetDashboardData.data);
        setCount(GetDashboardData.data[0].count);
        hideLoader();
      } else {
        setRowData([]);
        hideLoader();
      }
    } catch (error) {
      console.error("Error fetching grid data:", error);
      hideLoader();
    }
    hideLoader();
  };

  const edit = (value, tableMeta) => {
    const UId = tableMeta.rowData[0];
    navigate("/emailTemplateForm", { state: { UId } });
  };

  // Search
  const onSearchFilter = (event, type) => {
    setSearchText(event.target.value);
  };

  const columns = [
    {
      name: "id",
      label: "Id",
      options: { display: false },
    },
    {
      name: "name",
      label: "Name",
      options: { display: true, sort: true },
    },
    {
      name: "systemName",
      label: "System Name",
      options: { display: true, sort: true },
    },
    {
      name: "subject",
      label: "Subject",
      options: { display: true, sort: true, filter: true },
    },
    {
      name: "isActive",
      label: "Active Status",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          if (value) {
            return <i className="fas fa-check"></i>;
          } else {
            return <i className="fas fa-times"></i>;
          }
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Tooltip title="Edit">
                <i
                  style={{ marginRight: "0px", cursor: "pointer" }}
                  onClick={() => {
                    edit(value, tableMeta);
                  }}
                  className="fas fa-edit"
                ></i>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: PageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",
    onChangePage: async (page) => {
      setPage(page + 1);
    },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      setPage(page);
      if (sortDirection === "asc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
    },
  };

  return (
    <>
      <div className="container-fluid">
        <div className="col-lg-12 col-md-10 col-sm-12 "></div>
        <div className="mb-3 pt-3">
          <div className="d-flex align-items-center justify-content-end align-items-center mb-3">
            <button
              onClick={() => {
                navigate("/emailTemplateForm", { state: { UId: 0 } });
              }}
              className="btn btn-primary mr-2"
            >
              <i className="fa fa-plus mr-2"></i>
              Add New
            </button>
            <button
              type="button"
              onClick={exportToExcel}
              className="btn btn-primary mr-2"
            >
              <i className="fa fa-download mr-2"></i>
              Export To Excel
            </button>
          </div>
          <div className="grid-wrapper grid_mob">
            <div className="row mx-auto filtBox">
              <div className="col-lg-3 col-md-3 col-sm-12">
                <div className="form-group filtInp acc_btn">
                  <InputForm
                    value={searchText}
                    placeholder={"Search Name"}
                    isDisabled={false}
                    textArea={false}
                    onChange={onSearchFilter}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Dashboard */}
          <div className="mb-3 pt-3">
            <DynamicGrid data={rowData} columns={columns} options={options} />
          </div>
        </div>
      </div>
    </>
  );
};
export default EmailTemplateDashboard;
