import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  TextField,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

const AccordianCustom1 = ({
  header,
  submitFunc,
  btnDisable = false,
  children,
  hideBtn = false,
}) => {
  const [accordion, setAccordion] = useState<boolean>(true);

  return (
    <Accordion
      elevation={0}
      className="mb-3"
      expanded={accordion}
      onChange={() => {
        setAccordion((prev) => !prev);
      }}
    >
      <AccordionSummary
        id="profile-accordion"
        style={{ background: "#3C5464" }}
        className="text-white acc_close"
        expandIcon={<ExpandMore />}
      >
        <div className="row d-flex align-items-center acc-heading">
          <div className="col-lg-10 col-md-10 col-sm-12">
            <p style={{ fontSize: "16px" }}>{header}</p>
          </div>
          {!hideBtn && (
            <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12">
              {/* <Tooltip title="Clear">
                <button
                  disabled={btnDisable ? true : false}
                  className="btn ml-lg-0 ml-md-0 ml-1"
                  onClick={clearFunc}>
                  <i className="fas fa-times-circle"></i>
                </button>
              </Tooltip> */}
              <Tooltip title="Submit">
                <button
                  className="btn"
                  disabled={btnDisable}
                  onClick={submitFunc}
                >
                  <i className="fa fa-save"></i>
                </button>
              </Tooltip>
            </div>
          )}
        </div>
      </AccordionSummary>

      <AccordionDetails className="page_heading">{children}</AccordionDetails>
    </Accordion>
  );
};

export default AccordianCustom1;
