import React, { useState, useEffect, useContext, useRef } from "react";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { useNavigate } from "react-router-dom";
import SelectForm from "../../Components/SelectForm/SelectForm";
import Collapse from "react-bootstrap/Collapse";
import { APICall, APICallWithSignal } from "../../Helpers/APICalls";
import {
  ProgramNameData,
  ProgramCreatedByData,
  ProgramDashboardList,
  DeactivateProgramDetailById,
  HoldProgramDetails,
  ProgramDashboardListwithThumbnail,
  GetProgramCodeData,
  CheckRoleWiseModuleAccess,
  UploadEmployeeToCourseParticipation,
  DownloadExcelJourneyTemplate,
  UploadJourneyTemplate,
  getUserAndRoleWiseAccess,
  getLMSDomainDropDownData,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import InputForm from "../../Components/InputForm/InputForm";
import { Tooltip } from "@mui/material";
import { LoaderContext } from "../../Helpers/Context/Context";
import notify from "../../Helpers/ToastNotification";
import { useLocation } from "react-router-dom";
import ExcelJS from "exceljs";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import defaultProfileImgPic from "../../Assets/Images/thumbnail.jpg";
import { Modal, ModalTitle, Table } from "react-bootstrap";
import LazyLoad from "react-lazyload";
import { Spinner } from "react-bootstrap";
import ImageColumnComponent from "../../Components/LMS/ImageColumnComponent/ImageColumnComponent";
import * as xlsx from "xlsx";
import moment from "moment";
import {
  base64ToArrayBuffer,
  getBase64FromFilePromise,
} from "../../Helpers/Common";
import fileDownload from "js-file-download";

const ProgramDashboard = () => {
  const [open, setOpen] = useState(false);
  const [isExportToExcel, setExportToExcel] = useState(false);
  const [count, setCount] = useState(0);
  const [gridData, setGridData] = useState(null);
  const [start, setStart] = useState(0);
  const [rowData, setRowData] = useState([]);

  const [PageSize, setPageSize] = useState(50);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  const [ddValue, setDdValue] = useState({});
  const [reset, setReset] = useState(false);
  const [OUIsActive, setOUIsActive] = useState<any>([]);
  const [OUId, setSelectedOUId] = useState<any>();
  let { userDetails, currentRoleId }: any = useUserContext();

  const [showAddNew, setShowAddNew] = useState(false);
  const [ProgramIDOption, setProgramIDOption] = useState<any>([]);
  const [ProgramIDVal, setProgramIDVal] = useState<any>([]);

  const [ProgramNameOption, setProgramNameOption] = useState<any>([]);
  const [ProgramNameVal, setProgramNameVal] = useState<any>([]);

  const [ProgramCreatedOption, setProgramCreatedOption] = useState<any>([]);
  const [ProgramCreatedVal, setProgramCreatedVal] = useState<any>([]);

  const [dashboardData, setDashboardData] = useState([]);
  const [dashboardPageSize, setDashboardPageSize] = useState(7);
  const [dashboardStart, setDashboardStart] = useState(0);
  const [dashboardSortColumn, setDashboardSortColumn] = useState<any>("");
  const [dashboardSortDirection, setDashboardSortDirection] = useState("");
  const [dashboardCount, setDashboardCount] = useState(0);
  const [searchText, setSearchText] = useState<any>("");
  const [page, setPage] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [Id, setId] = useState<any>("");
  const [IsActive, setIsActive] = useState<any>(false);
  const [modalTitle, setModalTitle] = useState<any>("");
  const [hodeModal, sethodeModal] = useState(false);
  const [ishold, setIshold] = useState(false);
  const [profileImg, setProfileImg] = useState(null);
  const [filter, setFilter] = useState(false);
  const [statusOptions, setStatusOptions] = useState([
    { value: 1, label: "Active" },
    { value: 0, label: "Inactive" },
  ]);

  const [domain, setDomain] = useState(null);
  const [domainOptions, setDomainOptions] = useState([]);

  const [roleWiseModuleAccess, setRoleWiseModuleAccess] = useState({
    listAccess: false,
    createAccess: false,
    editAccess: false,
    activeAccess: false,
    deleteAccess: false,
    holdAccess: false,
    LaunchAccess: false,
  });

  const [remarks, setRemarks] = useState("");

  const [sessionRemarks, setsessionRemarks] = useState("");

  const [status, setStatus] = useState(null);

  const isViewPermitted = userDetails.InterfacePermissionRecord.some(
    (ipr) =>
      ipr.InterfaceName === "OrganizationUnit_Form" && ipr.Name === "View"
  );

  let isEditPermitted = userDetails.InterfacePermissionRecord.some(
    (ipr) =>
      ipr.InterfaceName === "OrganizationUnit_Form" && ipr.Name === "Edit"
  );

  const isDeletePermitted = userDetails.InterfacePermissionRecord.some(
    (ipr) =>
      ipr.InterfaceName === "OrganizationUnit_Form" && ipr.Name === "Delete"
  );

  useEffect(() => {
    (async () => {
      await getUserAndRoleWiseAccessApiCall();
      await GetProgramID();
      await GetProgramName();
      await GetProgramCreated();
      await CheckRoleWiseModuleAccessApiCall();
      await getLMSDomainDropDownDataApiCall();
    })();
  }, []);

  interface userAndRoleWiseAccess {
    isView?: boolean;
    isEdit?: boolean;
    isDelete?: boolean;
  }

  const [userAndRoleWiseData, setUserAndRoleWiseData] =
    useState<userAndRoleWiseAccess>({});

  const getUserAndRoleWiseAccessApiCall = async () => {
    const response = await APICall(getUserAndRoleWiseAccess, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      InterfaceName: "Program_Dashboard",
    });

    if (response?.status === 0) {
      setUserAndRoleWiseData(response?.data);
    } else {
      notify(response?.status, response?.message);
    }
  };

  const getLMSDomainDropDownDataApiCall = async () => {
    const response = await APICall(getLMSDomainDropDownData, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      GetAll: true,
    });

    if (response?.status === 0 && response?.data.length > 0) {
      setDomainOptions(response?.data);
    }
  };
  const GetProgramID = async () => {
    let UserId;

    const { data } = await APICall(GetProgramCodeData, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    if (data !== null && data.length > 0) {
      let role = data.map((element) => ({
        value: element.value,
        label: element.label,
      }));
      setProgramIDOption(role);
    }
  };

  const GetProgramName = async () => {
    const { data } = await APICall(ProgramNameData, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    if (data !== null && data.length > 0) {
      let ProgramName = data.map((element) => ({
        value: element.value,
        label: element.label,
      }));
      setProgramNameOption(ProgramName);
    }
  };

  const DeleteuserbyId = async (id: any, isActive: any) => {
    if (remarks === "" || remarks === null) {
      notify(1, "Please enter remarks");
    } else {
      showLoader();
      const { data } = await APICall(DeactivateProgramDetailById, "POST", {
        Id: id,
        IsActive: isActive,
        Remarks: remarks,
        CreatedBy: userDetails?.Id,
      });

      setShowModal(false);
      setRemarks("");
      if (data != null) {
        if (!isActive) {
          notify(0, "Journey has been successfully Activated");
        } else {
          notify(0, "Journey has been successfully InActivated");
        }
        await LoadDashboard();
        hideLoader();
      } else {
        notify(1, "Something went wrong");
      }
    }
  };

  const DeleteUserIdModal = async (id: any, isActive: any) => {
    setShowModal(true);
    setId(id);
    if (isActive) {
      setModalTitle("Do you want to Inactive the Journey?");
    } else {
      setModalTitle("Do you want to active the Journey?");
    }
    setIsActive(isActive);
  };

  const holduserbyIdmodal = async (id: any, hold: any) => {
    sethodeModal(true);
    if (!hold) {
      setModalTitle("Do you want to release the Journey?");
    } else {
      setModalTitle("Do you want to hold the Journey?");
    }
    setId(id);
    setIshold(hold);
  };

  const holduserbyId = async (id: any, hold: any) => {
    showLoader();
    if (remarks === "" || remarks === null) {
      notify(1, "Please enter remarks");
    } else {
      const { data } = await APICall(HoldProgramDetails, "POST", {
        Id: id,
        Hold: hold,
        Remarks: remarks,
        CreatedBy: userDetails?.Id,
      });
      sethodeModal(false);

      setRemarks("");

      if (data != null) {
        if (!hold) {
          notify(0, "Journey released Successfully");
        } else {
          notify(0, "Journey hold Successfully");
        }
        await LoadDashboard();
        hideLoader();
      } else {
        notify(1, "Something Went Wrong");
      }
    }

    hideLoader();
  };

  const LoadDashboard = async () => {
    showLoader();
    await setDashboardData([]);
    await setDashboardCount(0);

    let requestParams = {
      PageSize: dashboardPageSize,
      Page: dashboardStart,
      SortOrder: dashboardSortDirection,
      SortColumn: dashboardSortColumn,
      SearchText: searchText,
      CreatedByName: ProgramCreated.length > 0 ? ProgramCreated.toString() : "",
      ProgramCode: ProgramIDVals.length > 0 ? ProgramIDVals.toString() : "",
      ProgramTitle:
        ProgramNameVals.length > 0 ? ProgramNameVals.toString() : "",
      JourneyIsActive: status?.value,
      DomainIds: domain?.map((s) => s.value).join(","),
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    };
    //const { data } = await APICall(ProgramDashboardList, "POST", requestParams);
    const { data } = await APICall(ProgramDashboardList, "POST", requestParams);
    if (data !== null && data.length > 0) {
      await setDashboardData(data);
      setDashboardCount(data[0].count);
      hideLoader();
    } else {
      await setDashboardData([]);
      setDashboardCount(0);
    }
    hideLoader();
  };

  const GetProgramCreated = async () => {
    const { data } = await APICall(ProgramCreatedByData, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    if (data !== null && data.length > 0) {
      let ProgramCreated = data.map((element) => ({
        value: element.value,
        label: element.label,
      }));
      setProgramCreatedOption(ProgramCreated);
    }
  };

  const CheckRoleWiseModuleAccessApiCall = async () => {
    showLoader();
    const response = await APICall(CheckRoleWiseModuleAccess, "POST", {
      RoleId: currentRoleId?.value,
    });

    if (response?.status === 0) {
      setRoleWiseModuleAccess(response?.data);
    } else {
      notify(response?.status, response?.message);
    }
    hideLoader();
  };

  const gridColumns = [
    {
      name: "thumbnailUrl",
      label: "Thumbnail",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: false,
        customBodyRender: (value, tableMeta) => {
          let Thumbnail =
            tableMeta.tableData[tableMeta.rowIndex].thumbnailDataObject;
          //setProfileImg(`data:image;base64,${Thumbnail}`);
          return <ImageColumnComponent link={value} />;
        },
      },
    },
    {
      name: "programCode",
      label: "Journey ID",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "programTitle",
      label: "Journey Title",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    // {
    //   name: "description",
    //   label: "Description",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     sortDescFirst: true,
    //     customBodyRender: (value, tableMeta) => {
    //       return (
    //         <span
    //           dangerouslySetInnerHTML={{
    //             __html: value,
    //           }}
    //         ></span>
    //       );
    //     },
    //   },
    // },

    {
      name: "categoryName",
      label: "Journey Category",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "createdByName",
      label: "Created by",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "launchStatus",
      label: "Launch",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "domainName",
      label: "Domains",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "courseCount",
      label: "Course",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        customBodyRender: (value, tableMeta) => {
          debugger;
          const courseCount =
            tableMeta.tableData[tableMeta.rowIndex].courceCount;
          const id = tableMeta.tableData[tableMeta.rowIndex].id;
          const launched = tableMeta.tableData[tableMeta.rowIndex].launched;
          let courseText =
            courseCount === 0
              ? "No Course"
              : `${courseCount} Course${courseCount > 1 ? "s" : ""}`;

          if (courseCount === 0) {
            return (
              <Tooltip title="Add Course">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/journeyCourse", {
                      state: { id, launched },
                    });
                  }}
                  style={{ textDecoration: "none", color: "blue" }}
                >
                  {courseText}
                </a>
              </Tooltip>
            );
          } else {
            return (
              <Tooltip title="Edit or Add more courses">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/journeyCourse", {
                      state: { id, launched },
                    });
                  }}
                  style={{ textDecoration: "none", color: "blue" }}
                >
                  {courseText}
                </a>
              </Tooltip>
            );
          }
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta) => {
          let id = tableMeta.tableData[tableMeta.rowIndex].id;
          let isActive = tableMeta.tableData[tableMeta.rowIndex].isActive;
          let hold = tableMeta.tableData[tableMeta.rowIndex].hold;
          let color = isActive ? "green" : "red";
          let activate = isActive ? "Active" : "Inactive";
          let hold1 = hold ? "fa fa-play" : "fa fa-pause";
          let color1 = hold ? "green" : "red";
          let status = hold ? "Release" : "Hold";
          let launched = tableMeta.tableData[tableMeta.rowIndex].launched;
          const courseCount =
            tableMeta.tableData[tableMeta.rowIndex].courceCount;
          return (
            <div style={{ width: "100px" }}>
              {
                // (roleWiseModuleAccess?.editAccess ||
                userAndRoleWiseData?.isEdit && (
                  <Tooltip title="Edit">
                    <a
                      className="mr-2"
                      style={{ fontSize: "15px" }}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/journey", {
                          state: { id, isDisable: false },
                        });
                      }}
                    >
                      <i className="fas fa-edit"></i>
                    </a>
                  </Tooltip>
                )
              }

              {
                // (!roleWiseModuleAccess?.editAccess ||
                !userAndRoleWiseData?.isEdit && userAndRoleWiseData?.isView && (
                  <Tooltip title="View">
                    <a
                      className="mr-2"
                      style={{ fontSize: "15px" }}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/journey", {
                          state: { id, isDisable: true },
                        });
                      }}
                    >
                      <i className="fas fa-eye"></i>
                    </a>
                  </Tooltip>
                )
              }

              {
                // (roleWiseModuleAccess?.activeAccess ||
                userAndRoleWiseData?.isEdit &&
                  (courseCount > 0 ? (
                    <Tooltip
                      title={"Delink all courses to deactivate this journy"}
                    >
                      <a
                        className="mr-2"
                        style={{
                          fontSize: "15px", //, pointerEvents: "none"
                        }}
                        // onClick={() => {
                        //   //DeleteuserbyId(id ,isActive)
                        //   DeleteUserIdModal(id, isActive);
                        // }}
                      >
                        <i
                          className="fa-solid fa-circle"
                          style={{ color: color }}
                        ></i>
                      </a>
                    </Tooltip>
                  ) : (
                    <Tooltip title={activate}>
                      <a
                        aria-disabled
                        className="mr-2"
                        style={{
                          fontSize: "15px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          DeleteUserIdModal(id, isActive);
                        }}
                      >
                        <i
                          className="fa-solid fa-circle"
                          style={{ color: color }}
                        ></i>
                      </a>
                    </Tooltip>
                  ))
              }

              {/* {launched &&
                // (roleWiseModuleAccess?.holdAccess ||
                userAndRoleWiseData?.isEdit && (
                  <Tooltip title={status}>
                    <a
                      className="mr-2"
                      style={{ fontSize: "15px" }}
                      onClick={() => {
                        holduserbyIdmodal(id, !hold);
                      }}
                    >
                      <i className={hold1} style={{ color: color1 }}></i>
                    </a>
                  </Tooltip>
                )} */}
            </div>
          );
        },
      },
    },
  ];

  useEffect(() => {
    LoadDashboard();
  }, [
    searchText,
    dashboardStart,
    dashboardSortColumn,
    dashboardSortDirection,
    filter,
    reset,
  ]);

  const MapArr = (arr) => {
    let s = [];
    if (arr[0] === null || arr.length < 0) {
      return "";
    }
    arr.map((i) => s.push(i.value));
    return s;
  };

  let ProgramIDVals = MapArr(ProgramIDVal);

  let ProgramNameVals = MapArr(ProgramNameVal);

  let ProgramCreated = MapArr(ProgramCreatedVal);

  const dashboardOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    page: page,
    count: dashboardCount,
    rowsPerPage: dashboardPageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    //standard | vertical | simple
    onChangeRowsPerPage: (num) => {
      //   setLimit(num);
      //   setNxtPgInfo("");
      //   setPrevPgInfo("");
      //   setIsPrevOrNext("");
    },
    onSearchChange: (searchText) => {
      if (searchText !== null) {
        //setDashboardSearchText(searchText);
      } else {
        //setDashboardSearchText("");
      }
    },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setDashboardSortColumn(sortColumn);
        await setDashboardSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setDashboardSortColumn(sortColumn);
        await setDashboardSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      setPage(page);
      setDashboardStart(page * dashboardPageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const onSubmitFilter = async () => {
    if (
      ProgramIDVal.length === 0 &&
      ProgramNameVal.length === 0 &&
      ProgramCreatedVal.length === 0 &&
      status === null &&
      domain === null
    ) {
      notify(
        1,
        "Please select at least one filter option  to perform the search action."
      );
    } else {
      setFilter((prev) => !prev);
    }
  };

  const onClickReset = () => {
    setProgramCreatedVal([]);
    setProgramIDVal([]);
    setProgramNameVal([]);
    setDomain([]);
    LoadDashboard();
    setStatus(null);

    setReset(!reset);
    // setPage(0);
  };

  const exportToExcel = async () => {
    if (!OUId || OUId?.value === 0) {
      notify(1, "Please select organization unit type");
    } else {
      setExportToExcel(true);
    }
  };
  const addNewOU = async () => {
    navigate("/journey");
  };

  // bulk upload
  const [bulkUploadModal, setBulkUploadModal] = useState(false);

  const [file, setFile] = useState(null);
  const fileRef = useRef(null);
  const [isValidated, setIsValidated] = useState(false);
  const [showFileError, setShowFileError] = useState(false);
  const [errorData, setErrorData] = useState([]);

  function handleOnChange(event, code) {
    switch (code) {
      case "file":
        const files = event.target.files[0] || null;
        if (!files) {
          fileRef.current.value = null;
          setErrorData([]);
          setFile(null);
          notify(1, "Please select a file.");
          setIsValidated(false);
          return;
        }

        // Check if the selected file is an Excel file
        if (!files.name.endsWith(".xls") && !files.name.endsWith(".xlsx")) {
          fileRef.current.value = null;
          setErrorData([]);
          setFile(null);
          notify(1, "Please upload an Excel file.");
          setIsValidated(false);
          return;
        }
        setFile(files);
        setIsValidated(false);
        setErrorData([]);
        break;
      default:
        break;
    }
  }

  async function handleSave(validate) {
    try {
      showLoader();

      if (file) {
        let base64 = await getBase64FromFilePromise(file);
        base64 = String(base64).split("base64,")[1];

        const response = await APICall(UploadJourneyTemplate, "POST", {
          CreatedBy: userDetails?.Id,
          ValidationOnly: validate,
          ExcelFileBase64: base64,
        });

        if (response?.status == 0) {
          notify(
            0,
            validate ? "Validated Successfully" : "Uploaded Successfully"
          );
          setIsValidated(true);
          if (!validate) {
            setFile(null);
            fileRef.current.value = null;
            setErrorData([]);
            setBulkUploadModal(false);
          }
        } else if (response?.status == 5) {
          const { data } = response;

          if (data) {
            const fileName = "InvalidJourneyTempate_.xlsx";
            const base64 = data;

            let arrayBuffer = await base64ToArrayBuffer(base64);
            var byteArray = new Uint8Array(arrayBuffer);

            let blob = new Blob([byteArray], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            const newFile = new File([blob], "", {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              lastModified: new Date().getTime(),
            });

            fileDownload(newFile, fileName);

            const excelErrorMessage = JSON.parse(response?.message);

            const errorMessage = () => {
              if (
                Array.isArray(excelErrorMessage) &&
                excelErrorMessage.length > 0
              ) {
                setErrorData(excelErrorMessage);
              } else {
                return excelErrorMessage;
              }
            };

            notify(1, errorMessage());

            setFile(null);

            fileRef.current.value = null;
          } else {
            setFile(null);
            notify(1, response?.message);
            fileRef.current.value = null;
            setErrorData([]);
          }
          setIsValidated(false);
        } else {
          notify(1, "Something went wrong.");
          fileRef.current.value = null;
          setErrorData([]);
          setFile(null);
          setIsValidated(false);
        }
      } else {
        notify(1, "Please upload an Excel file.");
        setIsValidated(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  const DownloadExcelTemplateApiCall = async () => {
    try {
      showLoader();
      const response = await APICall(DownloadExcelJourneyTemplate, "POST", {});

      if (response?.status == 0) {
        const { excelFileBase64 } = response?.data;

        if (excelFileBase64) {
          const fileName = `JourneyTempate_${moment().format(
            "DD-MM-YYYY HH:mm:ss"
          )}.xlsx`;
          const base64 = excelFileBase64;

          let arrayBuffer = await base64ToArrayBuffer(base64);
          var byteArray = new Uint8Array(arrayBuffer);

          let blob = new Blob([byteArray], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          const newFile = new File([blob], "", {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            lastModified: new Date().getTime(),
          });

          fileDownload(newFile, fileName);
        }
      } else {
        notify(1, "Something went wrong, Please Try again Later.");
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const errorDataColumns = [
    {
      name: "Row",
      label: "Row",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: false,
      },
    },
    {
      name: "Col",
      label: "Column",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: false,
      },
    },
    {
      name: "ErrorMessage",
      label: "Error Message",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: false,
      },
    },
  ];

  const errorDataOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    rowsPerPage: 5,
    serverSide: false,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    //standard | vertical | simple
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  return (
    <>
      <div className="breadcrumb modified-bredcrumb">
        <span>
          <ul>
            <li>
              <a href="/home">Home</a>
            </li>
            <li>Journey</li>
          </ul>
        </span>
        {
          // roleWiseModuleAccess?.createAccess ||
          userAndRoleWiseData?.isEdit && (
            <div>
              <button
                disabled={false}
                onClick={addNewOU}
                className="btn btn-primary mt-3 mr-2"
              >
                <i className="fa fa-plus mr-2"></i>
                Create Journey
              </button>
              <button
                disabled={false}
                onClick={() => setBulkUploadModal(true)}
                className="btn btn-primary mt-3"
              >
                Bulk Upload
              </button>
            </div>
          )
        }
      </div>
      <div className="container-fluid">
        <div className="grid-wrapper grid_mob">
          <div className="row mx-auto filtBox">
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="form-group filtInp">
                <InputForm
                  value={searchText}
                  placeholder={"Search Keywords"}
                  isDisabled={false}
                  textArea={false}
                  onChange={(e) => {
                    setDashboardStart(0);
                    setPage(0);
                    setSearchText(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-12 filtrt">
              <ul className=" filter-icon-wel">
                <li>
                  <a
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                    className="filter-btn"
                  >
                    <i className="fa fa-filter"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <>
          <Collapse in={open}>
            <div id="example-collapse-text grid-wrapper">
              <div className="row mx-auto myInlineStyle">
                <>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <div className="form-group filtInp">
                      <label className="col-form-label text-white">
                        Journey ID
                      </label>
                      <SelectForm
                        isClearable
                        options={ProgramIDOption}
                        placeholder={"Select Journey ID"}
                        isDisabled={false}
                        onChange={(event) => {
                          setProgramIDVal(event);
                        }}
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                        value={ProgramIDVal}
                      />
                    </div>
                  </div>

                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <div className="form-group filtInp">
                      <label className="col-form-label text-white">
                        Journey Title
                      </label>
                      <SelectForm
                        isClearable
                        options={ProgramNameOption}
                        placeholder={"Select Journey Title"}
                        isDisabled={false}
                        onChange={(event) => {
                          setProgramNameVal(event);
                        }}
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                        value={ProgramNameVal}
                      />
                    </div>
                  </div>

                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <div className="form-group filtInp">
                      <label className="col-form-label text-white">
                        Created by
                      </label>
                      <SelectForm
                        isClearable
                        options={ProgramCreatedOption}
                        placeholder={"Select Created By "}
                        isDisabled={false}
                        onChange={(event) => {
                          setProgramCreatedVal(event);
                        }}
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                        value={ProgramCreatedVal}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <div className="form-group filtInp">
                      <label className="col-form-label text-white">
                        Status
                      </label>
                      <SelectForm
                        isClearable
                        options={statusOptions}
                        placeholder={"Select Status"}
                        isDisabled={false}
                        onChange={(event) => {
                          setStatus(event);
                        }}
                        isMulti={false}
                        noIndicator={false}
                        noSeparator={false}
                        value={status}
                      />
                    </div>
                  </div>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">Domains</label>
                    <SelectForm
                      isClearable
                      options={domainOptions}
                      placeholder={"Select Domains"}
                      isDisabled={false}
                      onChange={(event) => {
                        setDomain(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={domain}
                    />
                  </div>
                  {/* <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <div className="form-group filtInp">
                      <label className="col-form-label text-white">
                        Hold & Release
                      </label>
                      <SelectForm
                        isClearable
                        options={holdAndReleaseOptions}
                        placeholder={"Hold & Release"}
                        isDisabled={false}
                        onChange={(event) => {
                          setholdAndRelease(event);
                        }}
                        isMulti={false}
                        noIndicator={false}
                        noSeparator={false}
                        value={holdAndRelease}
                      />
                    </div>
                  </div> */}
                </>

                <div className="col-lg-12 d-flex mt-2 justify-content-end">
                  <button
                    className="btn btn-secondary mr-2"
                    onClick={() => onClickReset()}
                  >
                    <i className="fa fa-undo" aria-hidden="true"></i> Reset
                  </button>
                  <button
                    className="btn btn-filter-submit"
                    onClick={() => {
                      setDashboardStart(0);
                      setPage(0);
                      onSubmitFilter();
                    }}
                  >
                    <i className="fa fa-search" aria-hidden="true"></i> Search{" "}
                  </button>
                </div>
              </div>
            </div>
          </Collapse>
        </>

        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          backdrop="static"
          keyboard={false}
          size="sm"
          centered
        >
          <div
            className="bg-secondary text-white"
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <div className="col-lg-12">{`${modalTitle}`}</div>
          </div>
          <Modal.Body>
            <>
              <div className="row">
                {/* <div className="col-md-12">
                  <b>{`${modalTitle}`}</b>
                </div> */}
                <div className="col-md-8">
                  <label className="col-form-label">Remarks</label>
                  <sup>*</sup>
                  <InputForm
                    value={remarks}
                    placeholder={"Remarks"}
                    isDisabled={false}
                    textArea={true}
                    onChange={(e) => {
                      setRemarks(e.target.value);
                    }}
                  />
                </div>
              </div>
            </>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary"
              style={{ marginLeft: 5 }}
              onClick={() => DeleteuserbyId(Id, IsActive)}
            >
              Submit
            </button>
            <button
              className="btn btn-cancel"
              style={{ marginLeft: 5 }}
              onClick={() => {
                setShowModal(false);
              }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={hodeModal}
          onHide={() => sethodeModal(false)}
          backdrop="static"
          keyboard={false}
          size="sm"
          centered
        >
          <div
            className="bg-secondary text-white"
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <div className="col-lg-12">{`${modalTitle}`}</div>
          </div>
          <Modal.Body>
            <>
              <div className="row">
                <div className="col-md-8">
                  <label className="col-form-label">Remarks</label>
                  <sup>*</sup>
                  <InputForm
                    value={remarks}
                    placeholder={"Remarks"}
                    isDisabled={false}
                    textArea={true}
                    onChange={(e) => {
                      setRemarks(e.target.value);
                    }}
                  />
                </div>
              </div>
            </>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary"
              style={{ marginLeft: 5 }}
              onClick={() => holduserbyId(Id, ishold)}
            >
              Submit
            </button>
            <button
              className="btn btn-cancel"
              style={{ marginLeft: 5 }}
              onClick={() => {
                sethodeModal(false);
              }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={bulkUploadModal}
          onHide={() => setBulkUploadModal(false)}
          backdrop="static"
          keyboard={false}
          size="sm"
          centered
        >
          <div
            className="bg-secondary text-white"
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <div className="col-lg-12">Bulk Upload</div>
          </div>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-4 col-sm-4 col-xs-6">
                <button
                  className="btn btn-primary mt-2"
                  disabled={false}
                  onClick={() => DownloadExcelTemplateApiCall()}
                >
                  Download Journey Template
                </button>
              </div>

              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mt-2">
                  <div className="box position-relative">
                    <input
                      id="file"
                      className="form-control inputfile inputfile-6 multiple-inputfile"
                      data-multiple-caption="{count} files selected"
                      multiple={false}
                      type="file"
                      onChange={(e) => {
                        handleOnChange(e, "file");
                      }}
                      accept={".xlsx,.xls"}
                      ref={fileRef}
                      value={""}
                    />
                    <label
                      htmlFor={`file`}
                      className="form-control"
                      style={{ width: 0, border: "none" }}
                    >
                      <strong
                        style={{
                          padding: "6px 16px",
                          backgroundColor: "#3c5464",
                          borderRadius: "5px",
                        }}
                      >
                        <i
                          className="fa fa-upload rotate90"
                          aria-hidden="true"
                        ></i>{" "}
                        Upload
                      </strong>
                    </label>
                  </div>

                  <div className="file-added-list">
                    <ul className="list-unstyle">
                      <li className="list mt-1">
                        <div className="media">
                          <div className="media-body text-truncate">
                            <span className="view-more">{file?.name}</span>
                          </div>
                          {file !== null && (
                            <p>
                              <a
                                onClick={() => {
                                  setFile(null);
                                  setIsValidated(false);
                                  fileRef.current.value = null;
                                  setErrorData([]);
                                }}
                              >
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </p>
                          )}
                        </div>
                      </li>
                    </ul>
                  </div>
                  {showFileError && (
                    <span style={{ color: "red" }}>Required</span>
                  )}
                </div>
              </div>
            </div>

            {errorData.length > 0 && (
              <div className="row my-2">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                  <DynamicGrid
                    data={errorData}
                    columns={errorDataColumns}
                    options={errorDataOptions}
                  />
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary"
              style={{ marginLeft: 5 }}
              onClick={() => {
                handleSave(true);
              }}
            >
              Validate
            </button>
            <button
              disabled={!isValidated}
              className="btn btn-primary"
              style={{ marginLeft: 5 }}
              onClick={() => {
                handleSave(false);
              }}
            >
              Submit
            </button>
            <button
              className="btn btn-cancel"
              style={{ marginLeft: 5 }}
              onClick={() => {
                setFile(null);
                setIsValidated(false);
                fileRef.current.value = null;
                setErrorData([]);
                setBulkUploadModal(false);
              }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        <div className="mb-3 pt-3">
          <DynamicGrid
            data={dashboardData}
            columns={gridColumns}
            options={dashboardOptions}
          />
        </div>
      </div>
    </>
  );
};

export default ProgramDashboard;
