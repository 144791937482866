const prod = {
  url: "",
};

const dev = {
  url: "",
};

export const { url } = process.env.NODE_ENV === "development" ? dev : prod;

/////LMS

export const GetProgramCategoryData = `${url}/api/LMSProgram/GetProgramCategoryData`;

export const InsertUpdateProgramData = `${url}/api/LMSProgram/InsertUpdateProgramData`;

export const GetProgramCodeData = `${url}/api/LMSProgram/GetProgramCodeData`;

export const ProgramNameData = `${url}/api/LMSProgram/ProgramNameData`;

export const ProgramCreatedByData = `${url}/api/LMSProgram/ProgramCreatedByData`;

export const CheckRoleWiseModuleAccess = `${url}/api/LMSProgram/CheckRoleWiseModuleAccess`;

export const ProgramDashboardList = `${url}api/LMSProgram/ProgramDashboardList`;

export const GetProgramDetails = `${url}api/LMSProgram/GetProgramDetails`;

export const DeactivateProgramDetailById = `${url}api/LMSProgram/DeactivateProgramDetailById`;

export const HoldProgramDetails = `${url}api/LMSProgram/HoldProgramDetails`;
export const SetThumbnailProgramDetails = `${url}api/LMSProgram/SetThumbnailProgramDetails`;

export const DeleteThumbnailProgramDetails = `${url}api/LMSProgram/DeleteThumbnailProgramDetails`;

export const ProgramDashboardListwithThumbnail = `${url}api/LMSProgram/ProgramDashboardListwithThumbnail`;

export const GetProgramCourseDataList = `${url}api/LMSProgram/GetProgramCourseDataList`;
export const getLaunchHistoryDashboardDataForProgram = `${url}api/LMSProgram/GetLaunchHistoryDashboardDataForProgram`;
export const GetLaunchHistoryDashboardDataForCourse = `${url}api/LMSProgram/GetLaunchHistoryDashboardDataForCourse`;

export const GetProgramCodeNameData = `${url}api/LMSProgram/GetProgramCodeNameData`;

export const GetCoursesCodeNameData = `${url}api/LMSProgram/GetCoursesCodeNameData`;

export const ChangeProgramCourseData = `${url}api/LMSProgram/ChangeProgramCourseData`;

export const RemoveProgramCourseData = `${url}api/LMSProgram/RemoveProgramCourseData`;

export const GetProgramDroupDownList = `${url}api/LMSCourse/GetProgramDroupDownList`;
export const deleteCourseScheduleParticipantsById = `${url}api/LMSCourse/DeleteCourseScheduleParticipantsById`;
export const AddProgramCourseData = `${url}api/LMSProgram/AddProgramCourseData`;

export const GetApprovalLavelDroupDownList = `${url}api/LMSCourse/GetApprovalLavelDroupDownList`;
export const GetCertificateDropDown = `${url}api/LMSCourse/GetCertificateDropDown`;
export const GetSignatureDropDown = `${url}api/LMSCourse/GetSignatureDropDown`;

//Course Assessmant
export const getCourseAssessmentDropDownList = `${url}api/LMSCourse/GetCourseAssessmentDropDownList`;
export const getCourseAssessmentDashboardList = `${url}api/LMSCourse/GetCourseAssessmentDashboardList`;
export const saveCourseAssessment = `${url}api/LMSCourse/SaveCourseAssessment`;
export const changeSequenceCourseAssessment = `${url}api/LMSCourse/ChangeSequenceCourseAssessment`;
export const delinkCourseAssessment = `${url}api/LMSCourse/DelinkCourseAssessment`;

//Course Feedback
export const getCourseFeedbackDropDownList = `${url}api/LMSCourse/GetCourseFeedbackDropDownList`;
export const getCourseFeedbackDashboardList = `${url}api/LMSCourse/GetCourseFeedbackDashboardList`;
export const changeSequenceCourseFeedback = `${url}api/LMSCourse/ChangeSequenceCourseFeedback`;
export const delinkCourseFeedback = `${url}api/LMSCourse/DelinkCourseFeedback`;
export const saveCourseFeedback = `${url}api/LMSCourse/SaveCourseFeedback`;

//Course Cost Allotment
export const getCostAllotmentDropDownData = `${url}api/LMSCostAllotment/GetCostAllotmentDropDownData`;
export const SaveCostAllotmentData = `${url}api/LMSCostAllotment/SaveCostAllotmentData`;
export const GetCostAllotmentData = `${url}api/LMSCostAllotment/GetCostAllotmentData`;
export const SaveCostExpenseData = `${url}api/LMSCostAllotment/SaveCostExpenseData`;
export const DeleteCostExpenseData = `${url}api/LMSCostAllotment/DeleteCostExpenseData`;
export const GetCostExpenseDashboardData = `${url}api/LMSCostAllotment/GetCostExpenseDashboardData`;

export const GetCourseScheduleByCourseId =
  url + "/api/LMSCourse/GetCourseScheduleByCourseId";

export const GetCourseScheduleByCourseIdDashboard =
  url + "/api/LMSCourse/GetCourseScheduleByCourseIdDashboard";

export const GetCourseById = url + "/api/LMSCourse/GetCourseById";

export const GetCommonDropdownForScheduling =
  url + "/api/LMSCourse/GetCommonDropdownForScheduling";

export const validateSessionSchedule =
  url + "/api/LMSCourse/ValidateSessionSchedule";

export const InsertUpdateCourseSchedule =
  url + "/api/LMSCourse/InsertUpdateCourseSchedule";

export const GetScheduleDetailsByCourseIdAndScheduleId =
  url + "/api/LMSCourse/GetScheduleDetailsByCourseIdAndScheduleId";

export const CourseScheduleById = url + "/api/LMSCourse/CourseScheduleById";
export const InsertUpdateSessionSchedule =
  url + "/api/LMSCourse/InsertUpdateSessionSchedule";

export const GetSessionDropdown = `${url}api/LMSSession/GetSessionDropdown`;
export const getSessionDropdownByUserId = `${url}api/LMSSession/GetSessionDropdownByUserId`;

export const ManageCourseSessions = `${url}api/LMSSession/ManageCourseSessions`;

export const InsertUpdateCourseSession = `${url}api/LMSSession/InsertUpdateCourseSession`;

export const GetSessionContent = `${url}api/LMSSession/GetSessionContent`;
export const LaunchProgramData = `${url}api/LMSProgram/LaunchProgramData`;

export const GetCourseScheduleDataList = `${url}api/LMSParticipationCourse/GetCourseScheduleDataList`;
export const GetCourseScheduleAttendance = `${url}api/LMSParticipationCourse/GetCourseScheduleAttendance`; //added by mahesh

//export const GetCourseScheduleParticipantsList = `${url}api/LMSParticipationCourse/GetCourseScheduleParticipantsList`;
// export const GetCourseScheduleParticipantsList = `${url}api/LMSParticipationCourse/GetCourseScheduleParticipantsList`;

export const GetCourseScheduleParticipantsListExport = `${url}api/LMSParticipationCourse/GetCourseScheduleParticipantsListExport`;
export const UpdateParticipantsStatusList = `${url}api/LMSParticipationCourse/UpdateParticipantsStatusList`;
export const GetCourseScheduleParticipantsList = `${url}api/LMSParticipationCourse/GetCourseScheduleParticipantsList`;

//GetCourseScheduleParticipantsList

export const saveCourseData = `${url}api/LMSCourse/SaveCourseData`;

export const getCourseData = `${url}api/LMSCourse/GetCourseData`;
export const contentCompletionStatus = `${url}api/LMSCourse/ContentCompletionStatus`;
export const getContentCompletionStatus = `${url}api/LMSCourse/GetContentCompletionStatus`;
export const getCourseDetailsForRequest = `${url}api/LMSCourse/GetCourseDetailsForRequest`;
export const getCourseScheduleDropDownForLaunch = `${url}api/LMSCourse/GetCourseScheduleDropDownForLaunch`;

export const sessionDropdownData = `${url}api/LMSCourse/GetSessionDasboardFilterData`;

export const getCourseDashboardFilterDropDownList = `${url}api/LMSCourse/GetCourseDashboardFilterDropDownList`;

export const getCourseDasboardDetails = `${url}api/LMSCourse/GetCourseDasboardDetails`;
export const deactivateCourseDetailById = `${url}api/LMSCourse/DeactivateCourseDetailById`;
export const holdCourseDetails = `${url}api/LMSCourse/HoldCourseDetails`;

export const getSessionDasboardDetails = `${url}api/LMSCourse/GetSessionDasboardDetails`;

export const insertUpdateLMSSessionMaster = `${url}api/LMSCourse/InsertUpdateLMSSessionMaster`;

export const getLMSSessionMasterDataById = `${url}api/LMSCourse/GetLMSSessionMasterDataById`;

export const GetUpcomingCatalogueData = `${url}api/LMSCatalogue/GetUpcomingCatalogueData`;
export const GetMyCourseWishlist = `${url}api/LMSCatalogue/GetMyCourseWishlist`;
export const ManageWishListDetails = `${url}api/LMSCatalogue/ManageWishListDetails`;

export const GetMyJourneyCatalogueData = `${url}api/LMSCatalogue/GetMyJourneyCatalogueData`;
export const getJourneyProgressCatalogue = `${url}api/LMSCatalogue/GetJourneyProgressCatalogue`;

export const CheckIfManagerById = `${url}api/LMSCatalogue/CheckIfManagerById`;

export const GetCatalogueEmployeeDropdown = `${url}api/LMSCatalogue/GetCatalogueEmployeeDropdown`;

export const UploadExcelForCourseScheduleParticipantsStatus = `${url}api/LMSParticipationCourse/UploadExcelForCourseScheduleParticipantsStatus`;
export const DownloadExcelTemplateParticipationCourse = `${url}api/LMSParticipationCourse/DownloadExcelTemplateParticipationCourse`;
export const UploadCourseScheduleParticipants = `${url}api/LMSParticipationCourse/UploadCourseScheduleParticipants`;
export const getDropdownDataForParticipants = `${url}api/LMSParticipationCourse/GetDropdownDataForParticipants`;
export const getParticipantsAttendance = `${url}api/LMSParticipationCourse/GetParticipantsAttendance`;
export const getSessionScheduleAttendance = `${url}api/LMSParticipationCourse/GetSessionScheduleAttendance`;
export const saveSessionScheduleAttendance = `${url}api/LMSParticipationCourse/SaveSessionScheduleAttendance`;
export const saveBulkSessionScheduleAttendance = `${url}api/LMSParticipationCourse/SaveBulkSessionScheduleAttendance`;
export const getParticipantsAttendancePrint = `${url}api/LMSParticipationCourse/GetParticipantsAttendancePrint`;

export const getLMSRequestDashboardDropDownList = `${url}api/LMSRequestDashboard/GetLMSRequestDashboardDropDownList`;

export const getRequestDashboardData = `${url}api/LMSRequestDashboard/GetRequestDashboardData`;

export const createRegistrationRequest = `${url}api/LMSRequestDashboard/CreateRegistrationRequest`;

export const getCourseScheduleDetails = `${url}api/LMSRequestDashboard/GetCourseScheduleDetails`;

export const GetProgramScheduleDetails = `${url}api/LMSRequestDashboard/GetProgramScheduleDetails`;
export const getProgramProgress = `${url}api/LMSRequestDashboard/GetProgramProgress`;

export const getRejectionReasonList = `${url}api/LMSRequestDashboard/GetRejectionReasonList`;

export const requestsApproval = `${url}api/LMSRequestDashboard/RequestsApproval`;

export const getRequestsApprovalTrail = `${url}api/LMSRequestDashboard/GetRequestsApprovalTrail`;

export const getRequestHeaderData = `${url}api/LMSRequestDashboard/GetRequestHeaderData`;

export const SetThumbnailDetails = `${url}api/LMSProgram/SetThumbnailDetails`;
export const GetThumbnailDetails = `${url}api/LMSProgram/GetThumbnailDetails`;
export const getLaunchValidationForProgram = `${url}api/LMSProgram/GetLaunchValidationForProgram`;
export const GetLaunchValidationForCourse = `${url}api/LMSProgram/GetLaunchValidationForCourse`;

export const getContentDasboardFilterData = `${url}api/LMSContent/GetContentDasboardFilterData`;

export const setContentUrlDetails = `${url}api/LMSContent/SetContentUrlDetails`;
export const LMSContentUploadFile = `${url}api/LMSContent/LMSContentUploadFile`;
export const LMSContentUploadMP4File = `${url}api/LMSContent/LMSContentUploadMP4File`;

export const getContentUrlDetails = `${url}api/LMSContent/GetContentUrlDetails`;
export const uploadVimeoMP4File = `${url}api/LMSContent/UploadVimeoMP4File`;
export const checkMp4VideoStatus = `${url}api/LMSContent/CheckMp4VideoStatus`;
export const GetConfigDataCallForScromProject = `${url}api/LMSContent/GetConfigDataCallForScromProject`;
export const LMSUpdateScromData = `${url}api/LMSContent/LMSUpdateScromData`;

export const contentDashboardList = `${url}api/LMSContent/ContentDashboardList`;
export const deactivateContentDetailById = `${url}api/LMSContent/DeactivateContentDetailById`;
export const LMSManageContentMaster = `${url}api/LMSContent/LMSManageContentMaster`;
export const GetTargetLearnerInfo = `${url}api/LMSCourse/GetTargetLearnerInfo`;
export const getLMSManageContentMaster = `${url}api/LMSContent/GetLMSManageContentMaster`;
export const GetCourseScheduleParticpantDashboard = `${url}api/LMSCourse/GetCourseScheduleParticpantDashboard`;
export const InsertIntoLMSParticipating = `${url}api/LMSCourse/InsertIntoLMSParticipating`;
export const LaunchCourse = `${url}api/LMSCourse/LaunchCourse`;
export const GetThumbnailFromSharepointUsingPath = `${url}api/LMSProgram/GetThumbnailFromSharepointUsingPath`;

export const GetUpCommingAndOnGoingScheduleByCourseId = `${url}api/LMSCourse/GetUpCommingAndOnGoingScheduleByCourseId`;
export const deactivateSessionDetailById = `${url}api/LMSSession/DeactivateSessionDetailById`;
export const DeleteCourseScheduleById = `${url}api/LMSCourse/DeleteCourseScheduleById`;

export const UploadEmployeeToCourseParticipation =
  url + "/api/LMSCourse/UploadEmployeeToCourseParticipation";

export const GetTargetLearnerGroupDropdown =
  url + "/api/LMSTargetLearnerGroup/GetTargetLearnerGroupDropdownData";

export const getTargetLearnerParameters =
  url + "/api/LMSTargetLearnerGroup/GetTargetLearnerParameters";

export const ExportToExcelParticipationListForTargetLearner = `${url}/api/LMSCourse/ExportToExcelParticipationListForTargetLearner`;

export const DownloadExcelTemplate = `${url}/api/LMSCourse/DownloadExcelTemplate`;

export const GetValueForDropdown =
  url + "/api/LMSTargetLearnerGroup/GetValueForDropdown";

export const InsertTargetLearnerGroupName =
  url + "/api/LMSTargetLearnerGroup/InsertTargetLearnerGroupName";

export const InsertTargetLearnerConditions =
  url + "/api/LMSTargetLearnerGroup/InsertTargetLearnerConditions";

export const getTargetLearnerConditionsDataByTargetGroupId =
  url +
  "/api/LMSTargetLearnerGroup/GetTargetLearnerConditionsDataByTargetGroupId";

export const deleteTargetLearnerConditions =
  url + "/api/LMSTargetLearnerGroup/DeleteTargetLearnerConditions";

export const UploadEmployeeToTargetLearnerEmployeeList =
  url + "/api/LMSTargetLearnerGroup/UploadEmployeeToTargetLearnerEmployeeList";
export const GenerateTargetLearnerByID =
  url + "/api/LMSTargetLearnerGroup/GenerateTargetLearnerByID";
// export const GetTargetLearnerEmployeeListById = url + "/api/LMSTargetLearnerGroup/GetTargetLearnerEmployeeListById";
// export const DeleteEmployeeToTargetLearnerEmployeeList = url + "/api/LMSTargetLearnerGroup/DeleteEmployeeToTargetLearnerEmployeeList";
// export const GetTargetLearnerEmployeeListByIdExportToExcel = url + "/api/LMSTargetLearnerGroup/GetTargetLearnerEmployeeListByIdExportToExcel";
export const GetTrainerDasboardFilter =
  url + "/api/LMSTrainer/GetTrainerDasboardFilter";
export const TrainerDashboardList =
  url + "/api/LMSTrainer/TrainerDashboardList";
export const TrainerDasboardActionsById =
  url + "/api/LMSTrainer/TrainerDasboardActionsById";
export const DeleteTrainerById = url + "/api/LMSTrainer/DeleteTrainerById";
export const GetTargetLearnerEmployeeListById =
  url + "/api/LMSTargetLearnerGroup/GetTargetLearnerEmployeeListById";
export const DeleteEmployeeToTargetLearnerEmployeeList =
  url + "/api/LMSTargetLearnerGroup/DeleteEmployeeToTargetLearnerEmployeeList";
export const GetTargetLearnerEmployeeListByIdExportToExcel =
  url +
  "/api/LMSTargetLearnerGroup/GetTargetLearnerEmployeeListByIdExportToExcel";

export const GetLegalEntityPhysicalLocationbyEmployeeId =
  url + "/api/LMSTrainer/GetLegalEntityPhysicalLocationbyEmployeeId";

export const InsertUpdateTrainerData =
  url + "/api/LMSTrainer/InsertUpdateTrainerData";

export const GetTrainerDetails = url + "api/LMSTrainer/GetTrainerDetails";

export const DeleteThumbnailUrl = url + "api/LMSTrainer/DeleteThumbnailUrl";

export const GetTrainingCentersDasboardFilter =
  url + "api/LMSTrainingCenters/GetTrainingCentersDasboardFilter";

export const TrainingCentersDashboardList =
  url + "api/LMSTrainingCenters/TrainingCentersDashboardList";

export const TrainingCentersDasboardActions =
  url + "api/LMSTrainingCenters/TrainingCentersDasboardActionsById";

export const GetDroupdownList = url + "api/LMSTrainingCenters/GetDroupdownList";

export const InsertUpdateTrainingCentersData =
  url + "api/LMSTrainingCenters/InsertUpdateTrainingCentersData ";

export const DeleteThumbnailUrls =
  url + "api/LMSTrainingCenters/DeleteThumbnailUrl";
export const GetTrainingCentersDetails =
  url + "api/LMSTrainingCenters/GetTrainingCentersDetails";

export const GetEmployeeListDasboardList =
  url + "api/LMSEmployeeList/GetEmployeeListDasboardList";

// phase 2

export const DownloadExcelJourneyTemplate = `${url}api/LMSProgram/DownloadExcelJourneyTemplate`;
export const UploadJourneyTemplate = `${url}api/LMSProgram/UploadJourneyTemplate`;
export const DownloadExcelCourseTemplate = `${url}/api/LMSCourse/DownloadExcelCourseTemplate`;
export const UploadCourseTemplate = `${url}/api/LMSCourse/UploadCourseTemplate`;
export const GetCalendarData = `${url}/api/LMSHome/GetCalendarData`;
export const duplicateCourseSchedule = `${url}/api/LMSCourse/DuplicateCourseSchedule`;
export const manageSessionSchedules = `${url}api/LMSCourse/ManageSessionSchedules`;
export const deleteAllCourseScheduleParticipants = `${url}api/LMSCourse/DeleteAllCourseScheduleParticipants`;
export const meetingActionApi = `${url}api/LMSCourse/MeetingAction`;

// phase 2
//Domain

export const InsertUpdateLMSDomain = `${url}api/LMSDomain/InsertUpdateLMSDomain`;
export const getDomainDasboardDetails = `${url}api/LMSDomain/GetDomainDasboardDetails`;
export const getDomainDasboardFilterDetails = `${url}api/LMSDomain/GetDomainDasboardFilterDetails`;
export const domainDasboardActions = `${url}api/LMSDomain/DomainDasboardActions`;
export const getLMSDomainData = `${url}api/LMSDomain/GetLMSDomainData`;
export const getLMSDomainDropDownData = `${url}api/LMSDomain/GetLMSDomainDropDownData`;
export const getUserAndRoleWiseAccess = `${url}api/LMSDomain/GetUserAndRoleWiseAccess`;
export const getUserRoleMappingData = `${url}api/LMSDomain/GetUserRoleMappingData`;
export const getEmployeeDropDownByIsDomain = `${url}api/LMSDomain/GetEmployeeDropDownByIsDomain`;

export const getHistoricDataDasboardDetails = `${url}api/LMSHome/GetHistoricDataDasboardDetails`;
export const getHistoricDasboardFilterDropDown = `${url}api/LMSHome/GetHistoricDasboardFilterDropDown`;

export const getLaunchHubDashboardData = `${url}api/LMSLaunchHub/GetLaunchHubDashboardData`;
export const updateLaunchHubActionById = `${url}api/LMSLaunchHub/UpdateLaunchHubActionById`;
export const getLaunchHubFilterOptions = `${url}api/LMSLaunchHub/GetLaunchHubFilterOptions`;
export const ReminderMailDorpdown = `${url}api/LMSLaunchHub/ReminderMailDorpdown`;
export const GetEmailTemplatesData = `${url}api/LMSLaunchHub/GetEmailTemplatesData`;
export const ManageReminderMail = `${url}api/LMSLaunchHub/ManageReminderMail`;
export const getLMSReport = `${url}api/LMSReport/GetReport`;
export const getReportDropdown = `${url}api/LMSReport/GetReportDropdown`;

//Assessment
export const getAssessmentDashboardDropdownList = `${url}api/LMSAssessment/GetAssessmentDashboardDropdownList`;
export const getAssessmentDasboardDetails = `${url}api/LMSAssessment/GetAssessmentDasboardDetails`;
export const getAssessmentDropdownList = `${url}api/LMSAssessment/GetAssessmentDropdownList`;
export const saveManageAssessments = `${url}api/LMSAssessment/SaveManageAssessments`;
export const getManageAssessments = `${url}api/LMSAssessment/GetManageAssessments`;
export const getManageAssessmentQuestionMasterList = `${url}api/LMSAssessment/GetManageAssessmentQuestionMasterList`;
export const saveManageAssessmentQuestionMaster = `${url}api/LMSAssessment/SaveManageAssessmentQuestionMaster`;
export const saveManageAssessmentExistingQuestion = `${url}api/LMSAssessment/SaveManageAssessmentExistingQuestion`;
export const chengeSequenceManageAssessmentQuestion = `${url}api/LMSAssessment/ChengeSequenceManageAssessmentQuestion`;
export const getAssessmentDataById = `${url}api/LMSAssessment/GetAssessmentDataById`;
export const getManageAssessmentQuestionMaster = `${url}api/LMSAssessment/GetManageAssessmentQuestionMaster`;
export const delinkManageAssessmentQuestionMaster = `${url}api/LMSAssessment/DelinkManageAssessmentQuestionMaster`;
export const deactivateAssessmentDetailById = `${url}api/LMSAssessment/DeactivateAssessmentDetailById`;
export const getUserAssessmentDashboardFilter = `${url}api/LMSAssessment/GetUserAssessmentDashboardFilter`;
export const getUserAssessments = `${url}api/LMSAssessment/GetUserAssessments`;
export const manageUserAssessment = `${url}api/LMSAssessment/ManageUserAssessment`;
export const getAssessmentHeader = `${url}api/LMSAssessment/GetAssessmentHeader`;
export const getAssessmentStartMessage = `${url}api/LMSAssessment/GetAssessmentStartMessage`;
export const isAssessmentPending = `${url}api/LMSAssessment/IsAssessmentPending`;

//Feedback
export const getFeedbackDashboardDropdownList = `${url}api/LMSFeedback/GetFeedbackDashboardDropdownList`;
export const getFeedbackDasboardDetails = `${url}api/LMSFeedback/GetFeedbackDasboardDetails`;
export const getFeedbackDropdownList = `${url}api/LMSFeedback/GetFeedbackDropdownList`;
export const saveManageFeedbacks = `${url}api/LMSFeedback/SaveManageFeedbacks`;
export const getManageFeedbacks = `${url}api/LMSFeedback/GetManageFeedbacks`;
export const saveManageFeedbackQuestionMaster = `${url}api/LMSFeedback/SaveManageFeedbackQuestionMaster`;
export const getManageFeedbackQuestionMasterList = `${url}api/LMSFeedback/GetManageFeedbackQuestionMasterList`;
export const delinkManageFeedbackQuestionMaster = `${url}api/LMSFeedback/DelinkManageFeedbackQuestionMaster`;
export const changeSequenceManageFeedbackQuestionMaster = `${url}api/LMSFeedback/ChangeSequenceManageFeedbackQuestionMaster`;
export const getManageFeedbackQuestionMaster = `${url}api/LMSFeedback/GetManageFeedbackQuestionMaster`;
export const saveManageFeedbackExistingQuestionMaster = `${url}api/LMSFeedback/SaveManageFeedbackExistingQuestionMaster`;
export const deactivateFeedbackDetailById = `${url}api/LMSFeedback/DeactivateFeedbackDetailById`;
export const getFeedbackPreviewDataById = `${url}api/LMSFeedback/GetFeedbackPreviewDataById`;
export const getUserFeedbackDashboardData = `${url}api/LMSFeedback/GetUserFeedbackDashboardData`;
export const getUserFeedbackDashboardFilter = `${url}api/LMSFeedback/GetUserFeedbackDashboardFilter`;
export const saveUserFeedback = `${url}api/LMSFeedback/SaveUserFeedback`;

//Certificate
export const getCertificateDasboardFilter = `${url}api/LMSCertificate/GetCertificateDasboardFilter`;
export const getCertificateDasboardList = `${url}api/LMSCertificate/GetCertificateDasboardList`;
export const saveCertificateData = `${url}api/LMSCertificate/SaveCertificateData`;
export const getCertificateData = `${url}api/LMSCertificate/GetCertificateData`;
export const validateCertificatePlaceholders = `${url}api/LMSCertificate/ValidateCertificatePlaceholders`;
export const generateUserCertificate = `${url}api/LMSCertificate/GenerateUserCertificate`;
export const getUserCertificateDashboardData = `${url}api/LMSCertificate/GetUserCertificateDashboardData`;
export const getUserCertificateFile = `${url}api/LMSCertificate/GetUserCertificate`;

//Signature
export const getSignatureDasboardDetails = `${url}api/LMSCertificate/GetSignatureDasboardDetails`;
export const getSignatureData = `${url}api/LMSCertificate/GetSignatureData`;
export const saveSignatureData = `${url}api/LMSCertificate/SaveSignatureData`;
export const deleteSignatureData = `${url}api/LMSCertificate/DeleteSignatureData`;

// CourseNomination
export const manageCourseNomination = `${url}api/LMSCourseNomination/ManageCourseNomination`;
export const GetNomineeEmployeeDropdown = `${url}api/LMSCourseNomination/GetNomineeEmployeeDropdown`;

//SetThumbnailProgramDetails
//SetThumbnailProgramDetails
//api/LMSProgram/ProgramDashboardList
//DeleteThumbnailProgramDetails
//GetProgramCourseDataList
