import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import BaseModal from "../../Components/BaseModel/BaseModel";
import FormStructure from "../../Components/FromStructure/FormStructure";
import SelectForm from "../../Components/SelectForm/SelectForm";
import InputForm from "../../Components/InputForm/InputForm";
import "./PositionDashboard.css";
import Collapse from "react-bootstrap/Collapse";
import { APICall } from "../../Helpers/APICalls";
import ExcelJS from "exceljs";
import {
  getPositionDashboardData,
  GetPositionActionHistory,
  getDropdowns,
  CommonDropdownData,
  GetPositionExcelData,
  getSearchableDropdowns
} from "../../Helpers/APIEndPoints/EndPoints";
import { Tooltip } from "@mui/material";
import { LoaderContext } from "../../Helpers/Context/Context";
import notify from "../../Helpers/ToastNotification";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { useDebounce } from "../../utils/useDebounce";

const PositionDashboard = (props) => {
  const [sectionData, setSectionData] = useState({});
  // const [tabs, setTabs] = useState([]);
  // const [activetab, setActivetab] = useState<string>(null);
  const [filteredData, setFilteredData] = useState([]);
  const [open, setOpen] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [count, setCount] = useState(0);
  const [histCount, setHistCount] = useState(0);
  const [gridData, setGridData] = useState(null);
  const [page, setPage] = useState(0);
  const [Histpage, setHistPage] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortDirection, setSortDirection] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [PageSize, setPageSize] = useState(10);
  const [UpdatedPageSize, setUpdatedPageSize] = useState(6);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  let CNTRY = [
    { value: "India", code: "CODECNTRY1", label: "India" },
    { value: "United States", code: "CODECNTRY7", label: "United States" },
    { value: "United Kingdom", code: "CODECNTRY51", label: "United Kingdom" },
  ];
  //-----------------added on 11-08-2023-------------------------------
  const [business, setBusiness] = useState<any>([]);
  const [sbu, setSbu] = useState<any>([]);
  const [legalEntity, setLegalEntity] = useState<any>([]);
  const [PyLocation, setPyLocation] = useState<any>([]);
  const [status, setStatus] = useState<any>([]);
  const [commonDdValue, setCommonDdValue] = useState<any>({});
  const [ddValue, setDdValue] = useState({});
  const [isBudgeted, setIsBudgeted] = useState(null);
  const [positionStatus, setPositionStatus] = useState(null);
  const [jobCode, setJobCode] = useState(null);

  const debouncedValue = useDebounce(searchText);

  const getDropdownsData = async (AC, PID = null, cb) => {
    await showLoader();
    const dropdownData = await APICall(getDropdowns, "POST", {
      AC,
      PID,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });
    if (
      dropdownData.data !== null &&
      dropdownData.data !== undefined &&
      dropdownData.data.length > 0
    ) {
      setDdValue((prev) => ({ ...prev, [AC]: dropdownData.data }));
    } else {
      setDdValue((prev) => ({ ...prev, [AC]: [] }));
    }
    //setCommonDdValue((prev)=>({...prev, [AC]:[]}));
    await hideLoader();
  };
  //--------------------------------------------------------------------

  useEffect(() => {
    getInterfaceDetails();
  }, []);

  const getInterfaceDetails = async () => {
    showLoader();
    // ;
    const gridDataResponse = await APICall(
      "/api/Interface/GetInterfaceDetailsForGrid",
      "POST",
      {
        MN: "PositionManagement",
        IN: "PositionManagement_Dashboard",
        TN: "PositionManagement_Dashboard",
      }
    );
    if (gridDataResponse?.data?.d?.fa !== null) {
      for await (const cFilter of gridDataResponse?.data?.d?.fa) {
        if (cFilter.filt === "multidropdown" || cFilter.filt === 'dropdown' ) {
          await getDropdownsData(cFilter.filAN, null, null);
        }
      }
    }
    if (gridDataResponse?.data?.d?.afa !== null) {
      for await (const cFilter of gridDataResponse?.data?.d?.afa) {
        if (cFilter.filt === "multidropdown" || cFilter.filt === 'dropdown') {
          await getDropdownsData(cFilter.filAN, null, null);
        }
      }
    }
    setGridData(gridDataResponse);
    await getGridStructureTwo();
    hideLoader();
  };

  const onSubmitFilter = () => {
    if (
      business.length === 0 &&
      legalEntity.length === 0 &&
      sbu.length === 0 &&
      PyLocation.length === 0 && (isBudgeted || '').length === 0 && (positionStatus || '').length === 0 && (jobCode || '').length === 0
      //&& status.length === 0
    ) {
      notify(1, "Select atleast one filter");
    } else {
      setPage(0);
      if (gridData?.data?.d?.s[0]?.columnData.length > 0) {
        getGridStructureTwo();
      }
    }
  };

  const resetFilters = () => {
    setSearchText("");
    setBusiness([]);
    setSbu([]);
    setLegalEntity([]);
    setPyLocation([]);
    setStatus([]);
    setJobCode(null);
    setPositionStatus('');
    setIsBudgeted('')
  };
  const getGridStructureTwo = async () => {
    showLoader();
    //;
    const GetDashboardData = await APICall(getPositionDashboardData, "POST", {
      DashboardName: "PositionManagement",
      PageNumber: page + 1,
      PageSize: PageSize,
      SortColumn: sortColumn,
      SortOrder: sortDirection,
      SearchText: searchText,
      STATSP: status.map((s) => s.label).join(","),
      PMBUSN: business.map((s) => s.label).join(","),
      PMSBU: sbu.map((s) => s.label).join(","),
      PMLEGEN: legalEntity.map((s) => s.label).join(","),
      PMLOCN: PyLocation.map((s) => s.label).join(","),
      ISBDGT : String(isBudgeted?.value || ''),
      VCANSTAT : String(positionStatus?.value || ''),
      PMJOCD : String(jobCode?.value || ''),
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });
    if (GetDashboardData.data !== null && GetDashboardData.data.length > 0) {
      setCount(GetDashboardData.data[0].count);
      // ;
      setRowData(GetDashboardData.data);
    } else {
      setRowData([]);
      setCount(0);
    }
    hideLoader();
  };
  useEffect(() => {
    if (gridData?.data?.d?.s[0]?.columnData.length > 0) {
      getGridStructureTwo();
    }
  }, [page, debouncedValue, sortDirection, sortColumn, status]);
  // useEffect(() => {
  //   (async () => {
  //     const tabs = await APICall("/api/Tabs/getTabs", "POST", {
  //       MN: "EmployeeCentral",
  //       IN: "Form",
  //     });

  //     if (tabs.data !== null && tabs.data.length > 0) {
  //       setTabs(tabs.data);
  //       setActivetab(tabs.data[0].TN);
  //     } else {
  //       setTabs([]);
  //     }
  //   })();
  // }, []);

  const view = (value, tableMeta) => {
    const url = value;
    // window.location.href = url;
    window.open(url);
  };

  const edit = (value, tableMeta, url) => {
    const TId = tableMeta.rowData[0];
    const PosSts = tableMeta.rowData[3];
    // navigate(url[1].editUrl);
    navigate("/positionManagement", { state: { 
      TId, 
      COU: proxyUserDetails ? proxyUserDetails.COU : userDetails.COU,
      IsDraft: PosSts == 'Draft' ? true : false, 
    } });
  };

  const copy = (value, tableMeta) => {
    alert("copy");
  };

  const modal = (value, tableMeta) => {
    alert("modal");
  };

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: PageSize,
    page: page,
    serverSide: true,
    onChangePage: async (page) => {
      setPage(page);
    },
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",
    onColumnSortChange: async (sortColumn, sortDirection) => {
      setPage(0);
      if (sortDirection === "asc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
    },
  };
  const HistOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: histCount,
    rowsPerPage: UpdatedPageSize,
    page: page,
    serverSide: true,
    onChangePage: async (page) => {
      setPage(page);
    },
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",
    onColumnSortChange: async (sortColumn, sortDirection) => {
      setPage(0);
      if (sortDirection === "asc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
    },
  };
  //option which is from backend if needed we use it
  // const getOption = (attribute) => {
  //   const faData = data?.fa;
  //   if (faData && attribute.filn === "city") {
  //     return cityData.map((city) => ({ value: city.label, label: city.label }));
  //   } else {
  //     return [];
  //   }
  // };

  const columnsWithCustomRender = gridData?.data?.d?.s[0]?.columnData.map(
    (column,index) => {
      if (column.name === "action") {
        return {
          ...column,
          options: {
            ...column.options,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <>
                  <div className="centerStyles">
                    {/* <i
                    style={{ marginRight: "15px" }}
                    onClick={() => view(value, tableMeta)}
                    className="fas fa-eye"
                  ></i>
                  <i
                    style={{ marginRight: "15px" }}
                    onClick={() => copy(value, tableMeta)}
                    className="fas fa-copy"
                  ></i> */}
                    <Tooltip title="Edit">
                      <i
                        style={{ marginRight: "10px", cursor: "pointer" }}
                        onClick={() => {
                          // navigate("/gridEdit")
                          edit(value, tableMeta, column.url);
                        }}
                        className="fas fa-edit"
                      ></i>
                    </Tooltip>
                    <Tooltip title="History">
                                <i
                                  style={{
                                    marginRight: "0px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setShowHistoryModal(true);
                                    getHistoryData(tableMeta );
                                  }}
                                  className="fas fa-history"
                                ></i>
                              </Tooltip>
                    {/* <BaseModal
                    buttonText={<i className="fa-solid fa-paper-plane"></i>}
                    content={<FormStructure />}
                  /> */}
                  </div>
                </>
              );
            },
          },
        };
      }
      return column;
    }
  );

  const onFilterChange = (event, type) => {
    if (type === "PMBUSN") {
      setBusiness(event);
      const commaSeparatedBISIds =
        event && event.length > 0 && event.map((e) => e.value).join(",");

      //cascading
      if (commaSeparatedBISIds) {
        getDropdownsData("PMSBU", commaSeparatedBISIds, null);
      }
      //reset cascading
      setSbu([]);
      setLegalEntity([]);
    }
    if (type === "PMSBU") {
      setSbu(event);
      const commaSeparatedSBUIds =
        event && event.length > 0 && event.map((e) => e.value).join(",");
      if (commaSeparatedSBUIds) {
        getDropdownsData("PMLEGEN", commaSeparatedSBUIds, null);
      }
      //reset cascading
      setLegalEntity([]);
    }
    if (type === "PMLEGEN") {
      setLegalEntity(event);
    }
    if (type === "STATSP") {
      setPage(0);
      setStatus(event);
    }
    if (type === "PMLOCN") {
      setPyLocation(event);
    }
    if(type === 'ISBDGT') {
      setIsBudgeted(event);
    }
    if(type === 'VCANSTAT') {
      setPositionStatus(event);
    }
  };
  const onSearchFilter = (event, type) => {
    if (type === "SearchPositionDashboard") {
      setPage(0);
      setSearchText(event.target.value);
    }
  };

  const exportToExcel = async () => {
    getExcelData();
  };

  const getExcelData = async () => {
    showLoader();

    const GetDashboardData = await APICall(GetPositionExcelData, "POST", {
      //DashboardName: "PositionManagement",
      PageNumber: 0,
      PageSize: 0,
      SortColumn: "",
      SortOrder: "",
      SearchText: "",
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    const responseData = GetDashboardData.data;

    if (!responseData || responseData.length === 0) {
      notify(1, "There is No data.");
      hideLoader();
      return;
    }

    const convertCamelCaseToWords = (camelCaseString) => {
      return camelCaseString
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    };

    const excludeHeaders = [
      "id",
      "isActive",
      "createdDateUtc",
      "createdBy",
      "modifiedDateUtc",
      "modifiedBy",
    ];
    let filteredHeaders = Object.keys(responseData[0]).filter(
      (header) => !excludeHeaders.includes(header)
    );
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Master");

    const headerRow = worksheet.addRow(
      filteredHeaders.map((header) => convertCamelCaseToWords(header))
    );
    headerRow.font = { bold: true };

    responseData.forEach((row) => {
      const dataRow = filteredHeaders.map((header) => row[header] || "");
      worksheet.addRow(dataRow);
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = `Position Management.xlsx`;
      a.click();

      URL.revokeObjectURL(url);
    });
    hideLoader();
  };

  const getHistoryData = async(tableMeta )=>{
    showLoader();
    const  PosId = tableMeta.rowData[0];
    const  RequestNo = tableMeta.rowData[8];
    
    // if(RequestNo !==null && RequestNo !==undefined){
      const GetHistoryData = await APICall(GetPositionActionHistory, "POST", {
          PageNumber: Histpage ,
          PageSize: UpdatedPageSize,
          SortColumn: sortColumn,
          SortOrder: sortDirection,
          PositionId: PosId,
          Request: RequestNo,
        }
      );
      if(GetHistoryData?.data?.length > 0){
        setHistoryData(GetHistoryData?.data) ;
        setHistCount(GetHistoryData?.data?.length);
      }
      else{
        setHistoryData([]) ;
        setHistCount(0);
      }
  // }
    hideLoader();
  }

  const HistColumns = [
    { name: "id", label: "id", options: { display: false } }, // 0
    {
      name: "modifiedDate", //1
      label: "Modified Date",
      options: { display: true ,
        customBodyRender: (value: any, tableMeta: any) => {
          return(
            value !== null ?moment(value).format("DD-MM-YYYY") :""
          )
        }
      },
    },
    {
      name: "modifiedTime", //2
      label: "Modified Time",
      options: { display: true },
    },
    {
      name: "event", //3
      label: "Event",
      options: { display: true, sort: false },
    },
    { name: "eventReason", label: "Event Reason", options: { display: true } }, //4
    {
      name: "sectionName", //5
      label: "Sections",
      options: { display: true, sort: false },
    },
    { name: "status", label: "Status", options: { display: true } }, //6
    {
      name: "userName", //7
      label: "Modified By",
      options: { display: true, sort: false },
    },
    {
      name: "effectiveDate",
      label: "Effective Date",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        // setCellProps: () => ({ align: "center" }),
        // setCellHeaderProps: () => ({ style: { textAlign: "center" } }),
        customBodyRender: (value: any, tableMeta: any) => {
          return(
            value !== null ?moment(value).format("DD-MM-YYYY hh:mm:ss A") :""
          )
        }
      },
    },
    {
      name: "remarks", //8
      label: "Remarks",
      options: { display: true, sort: false },
    },
    // {
    //   name: "oldApprover",
    //   label: "Old Approver",
    //   options: { display: true },
    // }, //8
    // {
    //   name: "newApprover", //9
    //   label: "New Approver",
    //   options: { display: true, sort: false },
    // },

    { name: "rowIndex", label: "roxInx", options: { display: false } }, //10
  ];

  const getSearchableDropdownAPI = async (
    searchString,
    AC,
    cb,
    valueForDropdown,
    type,
    gridId
  ) => {
    if (
      searchString === "" &&
      cb === null &&
      valueForDropdown !== "" &&
      AC !== ""
    ) {
      const response = await APICall(getSearchableDropdowns, "POST", {
        searchString,
        AC,
        valueForDropdown,
      });

      if (response.data !== null && response.data.length > 0) {
        if (response.data.length === 1) {
          if (gridId !== 0) {
            let ddObj = {
              gridId,
              AC,
              DDResponse: response.data[0],
            };
            // setFillDDValuesForGrid((prev) => [...prev, ddObj]);
          } else {
            return response.data[0];
            //fillSearchableDDValuesOnEdit(response.data[0], AC);
          }
        }
      }
    } else if (searchString.length > 1) {
      const response = await APICall(getSearchableDropdowns, "POST", {
        searchString,
        AC,
      });
      if (response.data !== null && response.data.length > 0) {
        cb(response.data);
      } else {
        cb([]);
      }
    }
  };

  return (
    <>
    <div className="container-fluid">
      <div className="d-flex align-items-center justify-content-end mb-3">
        {props.permissionName === "Edit" && (
          <button
            onClick={() => {
              navigate("/positionManagement", {
                state: { 
                  TId: 0, 
                  COU: proxyUserDetails ? proxyUserDetails.COU : userDetails.COU 
                },
              });
            }}
            className="btn btn-primary mt-3 mr-2"
          >
            <i className="fa fa-plus mr-2"></i>
            Create Position
          </button>
        )}
        <button className="btn btn-primary mt-3" onClick={exportToExcel}>
          Export to excel
        </button>
      </div>
      <div className="grid-wrapper grid_mob">
        <div className="row mx-auto filtBox">
          {gridData &&
            gridData?.data?.d?.fa?.map((cFilter, index) => (
              <>
                {cFilter.filt === "multidropdown" ? (
                  <>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="form-group filtInp">
                        <span
                          className="mr-2 text-white"
                          style={{ float: "left" }}
                        >
                          <label className="col-form-label">
                            {cFilter.filn}
                          </label>
                        </span>
                        <SelectForm
                          isClearable
                          options={ddValue[cFilter.filAN]}
                          placeholder={cFilter.filn}
                          isDisabled={false}
                          onChange={(event) => {
                            onFilterChange(event, cFilter.filAN);
                            //onFilterChange(event, cFilter.filn);
                          }}
                          isMulti={true}
                          noIndicator={false}
                          noSeparator={false}
                          value={
                            cFilter.filAN === "STATSP"
                              ? status
                              : cFilter.filAN === "PMBUSN"
                              ? business
                              : cFilter.filAN === "PMSBU"
                              ? sbu
                              : cFilter.filAN === "PMLEGEN"
                              ? legalEntity
                              : cFilter.filAN === "PMLOCN"
                              ? PyLocation
                              : null
                          }
                        />
                      </div>
                    </div>
                  </>
                ) : cFilter.filt === "text" ? (
                  <div className="col-lg-3 col-md-6 col-sm-12 ">
                    <div className="form-group filtInp">
                      <InputForm
                        value={
                          cFilter.filAN === "SearchPositionDashboard" ? searchText : ""
                        }
                        placeholder={cFilter.filn}
                        isDisabled={false}
                        textArea={false}
                        onChange={(e) => {
                          onSearchFilter(e, cFilter.filAN);
                        }}
                      />
                    </div>
                  </div>
                ) : null}
              </>
            ))}
          <div className="col-lg-1 col-md-1 col-sm-12 filtrt">
            <ul className=" filter-icon-wel">
              <li>
                <a
                  href="javascript:void(0);"
                  onClick={() => setOpen(!open)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                  className="filter-btn"
                >
                  <i className="fa fa-filter"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-10 col-sm-12"></div>
      <>
        <Collapse in={open}>
          <div id="example-collapse-text grid-wrapper">
            <div className="row mx-auto myInlineStyle">
              {gridData &&
                gridData?.data?.d?.afa?.map(
                  (
                    cFilter,
                    index //fa->qf  // same in af in collapse
                  ) => (
                    <>
                      {cFilter.filt === "multidropdown" ? (
                        <>
                          <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                            <div className="form-group filtInp">
                              <label className="col-form-label text-white ">
                                {cFilter.filn}
                              </label>
                              <SelectForm
                                isClearable
                                options={ddValue[cFilter.filAN]}
                                placeholder={cFilter.filn}
                                isDisabled={false}
                                onChange={(event) => {
                                  onFilterChange(event, cFilter.filAN);
                                  //onFilterChange(event, cFilter.filn);
                                }}
                                isMulti={true}
                                noIndicator={false}
                                noSeparator={false}
                                value={
                                  cFilter.filAN === "STATSP"
                                    ? status
                                    : cFilter.filAN === "PMBUSN"
                                    ? business
                                    : cFilter.filAN === "PMSBU"
                                    ? sbu
                                    : cFilter.filAN === "PMLEGEN"
                                    ? legalEntity
                                    : cFilter.filAN === "PMLOCN"
                                    ? PyLocation
                                    : null
                                }
                              />
                            </div>
                          </div>
                        </>
                      ) : cFilter.filt === "text" ? (
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                          <div className="form-group filtInp">
                            <InputForm
                              value={
                                cFilter.filn === "Search Job Title"
                                  ? searchText
                                  : ""
                              }
                              placeholder={cFilter.filn}
                              isDisabled={false}
                              textArea={false}
                              onChange={(e) => {
                                onSearchFilter(e, cFilter.filn);
                              }}
                            />
                          </div>
                        </div>
                      ) : cFilter.filt === "dropdown" ? (
                        <>
                          <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                            <label className="col-form-label text-white">
                              {cFilter.filn}
                            </label>
                            <SelectForm
                              isClearable
                              options={ddValue[cFilter.filAN]}
                              placeholder={cFilter.filn}
                              isDisabled={false}
                              onChange={(event) => {
                                onFilterChange(event, cFilter.filAN);
                              }}
                              isMulti={false}
                              noIndicator={false}
                              noSeparator={false}
                              value={cFilter.filAN === "ISBDGT" ? isBudgeted : positionStatus}
                            />
                          </div>
                        </>
                      ) : cFilter.filt == "searchabledropdown" ? (
                        <>
                          <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                            <div className="form-group filtInp">
                              <label className="col-form-label text-white">
                                {cFilter.filn}
                              </label>
                              <SelectForm
                                isClearable 
                                async
                                placeholder={cFilter.filn}
                                isDisabled={false}
                                options={(searchString, cb) =>
                                  getSearchableDropdownAPI(
                                    searchString,
                                    cFilter.filAN,
                                    cb,
                                    "",
                                    "attribute",
                                    0
                                  )
                                }
                                onChange={(event) => {
                                  setJobCode(event);
                                }}
                                value={jobCode}
                                noIndicator={false}
                                noSeparator={false}
                              />
                            </div>
                          </div>
                        </>
                      ) : null}
                    </>
                  )
                )}
              <div className="col-lg-12 d-flex mt-2 justify-content-end">
                <button
                  onClick={resetFilters}
                  className="btn btn-secondary mr-2"
                >
                  <i className="fa fa-undo" aria-hidden="true"></i> Reset{" "}
                </button>
                <button
                  className="btn btn-filter-submit"
                  onClick={onSubmitFilter}
                >
                  <i className="fa fa-check" aria-hidden="true"></i> Search{" "}
                </button>
              </div>
            </div>
          </div>
        </Collapse>
      </>
      {}
      <div className="mb-3 pt-3">
        <DynamicGrid
          data={rowData}
          columns={columnsWithCustomRender}
          options={options}
        />
      </div>
    </div>
    <Modal
    className="customized-history-modal-modified"
                              show={showHistoryModal}
                              onHide={() => setShowHistoryModal(false)}
                              //size="lg"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              backdrop="static"
                              keyboard={false}
                            >
                              <Modal.Body>
                                <div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <DynamicGrid
                                        data={historyData}
                                        columns={HistColumns}
                                        options={HistOptions}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  className="btn customButtonCancel"
                                  style={{ marginLeft: 5 }}
                                  onClick={() => {
                                    setShowHistoryModal(false);
                                  }}
                                >
                                  Close
                                </button>
                              </Modal.Footer>
                            </Modal>
    </>
  );
};

export default PositionDashboard;
