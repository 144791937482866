import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tabs, Tooltip } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "./Courses.css";
import { LoaderContext } from "../../Helpers/Context/Context";
import { useLocation } from "react-router-dom";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import CourseSession from "./CourseSession";
import CourseHeader from "./CourseHeader";
import ScheduleCourseAndSession from "./ScheduleCourseAndSession/ScheduleCourseAndSession";
import Participant from "../Participant/Participant";
import Participation from "./Particiption/Participation";
import CourseGeneral from "./CourseGeneral";
import CourseAssessment from "./CourseAssessment";
import CourseFeedback from "./CourseFeedback";
import CostAllotment from "./CostAllotment";

const Courses = () => {
  const { state } = useLocation();
  const [tabs, setTabs] = useState([
    { name: "General", label: "General" },
    { name: "Session", label: "Session" },
    { name: "Schedule", label: "Schedule" },
    { name: "Participants", label: "Participants" },
    { name: "Assessment", label: "Assessment" },
    { name: "Feedback", label: "Feedback" },
    { name: "CostAllotment", label: "Cost Allotment" },
  ]);
  const [activetab, setActivetab] = useState<string>(state?.tab || "General");
  const [activetabIndex, setActivetabIndex] = useState<number>(0);
  const { showLoader, hideLoader } = useContext(LoaderContext);

  const [headerValues, setHeaderValues] = useState<any>(null);
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  const navigate = useNavigate();

  const getCurrentTab = (activetab) => {
    switch (activetab) {
      case "General":
        return (
          <CourseGeneral activeTab={activetab} setActiveTab={setActivetab} />
        );

      case "Session":
        return (
          <CourseSession activeTab={activetab} setActiveTab={setActivetab} />
        );

      case "Schedule":
        return (
          <ScheduleCourseAndSession
            activeTab={activetab}
            setActiveTab={setActivetab}
          />
        );

      case "Participants":
        return (
          <Participation activeTab={activetab} setActiveTab={setActivetab} />
        );

      case "Assessment":
        return (
          <CourseAssessment activeTab={activetab} setActiveTab={setActivetab} />
        );

      case "Feedback":
        return (
          <CourseFeedback activeTab={activetab} setActiveTab={setActivetab} />
        );

      case "CostAllotment":
        return (
          <CostAllotment activeTab={activetab} setActiveTab={setActivetab} />
        );

      default:
        return null;
    }
  };

  const defaultTheme = createTheme({});
  const { breakpoints } = defaultTheme;

  const tabsTheme = {
    ...defaultTheme,
    components: {
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: "0px !important",
            marginLeft: "-42px",
            [breakpoints.down("md")]: {
              minHeight: "0px !important",
              marginLeft: "0px",
            },
          },
        },
      },
    },
  };

  useEffect(() => {
    if (state === null) {
      navigate("/CoursesDashboard", { replace: true });
    }
  }, [state]);

  return (
    <>
      <div className="container-fluid">
        <div
          className="row prof_bg mb-3 border-bottom"
          style={{
            backgroundColor: state && state.courseId > 0 ? "#00abe6" : "white",
          }}>
          <div className="container-fluid">
            {state && state?.courseId > 0 && <CourseHeader />}
            <div className="row">
              {state && state?.courseId > 0 && (
                <div className="col-lg-2 col-md-12 col-sm-12 "></div>
              )}

              <div
                className={
                  headerValues
                    ? "col-lg-10 col-md-12 col-sm-12 "
                    : "col-lg-12 col-md-12 col-sm-12"
                }>
                <ThemeProvider theme={tabsTheme}>
                  <Tabs
                    value={activetabIndex}
                    className="profile-tabs"
                    onChange={() => {}}
                    variant="scrollable"
                    TabScrollButtonProps={{
                      style: {
                        color: "black",
                      },
                    }}
                    scrollButtons
                    TabIndicatorProps={{
                      style: { display: "none" },
                    }}
                    allowScrollButtonsMobile
                    aria-label="scrollable tabs">
                    <div className="tabBtn">
                      <ul>
                        {tabs.length > 0 &&
                          tabs.map((eachTab, index) => (
                            <li key={`${index}-${eachTab.name}`}>
                              <button
                                disabled={
                                  state && state?.courseId > 0 ? false : true
                                }
                                onClick={() => {
                                  setActivetab(eachTab.name);
                                }}
                                className={
                                  eachTab.name === activetab
                                    ? "bttn active"
                                    : "bttn border border-primary border-bottom-0"
                                }>
                                {eachTab.label}
                              </button>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </Tabs>
                </ThemeProvider>
              </div>
            </div>
          </div>
        </div>

        <div className="breadcrumb modified-bredcrumb">
          <span>
            <ul>
              <li>
                <a href="/home">Home</a>
              </li>
              <li>
                {" "}
                <a href="/CoursesDashboard">Course</a>
              </li>
              <li>Manage Course</li>
            </ul>
          </span>
          <button
            onClick={() => {
              if (state && state?.from !== undefined) {
                navigate(state?.from);
              } else {
                navigate("/CoursesDashboard");
              }
            }}
            disabled={false}
            className="btn btn-secondary float-right">
            <i className="fa-solid fa-arrow-left"></i> Back
          </button>
        </div>

        {/* <div className="SectionSubmit mb-4 clearfix">
          <button
            onClick={() => {
              navigate(-1);
            }}
            disabled={false}
            className="btn btn-secondary float-right">
            <i className="fa-solid fa-arrow-left"></i> Back
          </button>
        </div> */}

        {/* sections row */}

        <div className="row accordion_grid">{getCurrentTab(activetab)}</div>
      </div>
    </>
  );
};

export default Courses;
