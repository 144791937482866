import { create } from "zustand";

const useMRFStore = create((set) => ({
  posDetails: null,
  orgDetails: null,
  jobDetails: null,
  addPosDetails: (data) => set({ posDetails: data }),
  addOrgDetails: (data) => set({ orgDetails: data }),
  addJobDetails: (data) => set({ jobDetails: data }),
  setSectionHide: 0,
  updateSectionHide: (count) =>
    set((state) => ({ setSectionHide: state.setSectionHide + count })),
  defPosId: null,
  updateDefPosId: (data) => set({ defPosId: data }),
  posChange: 0,
  updatePosChange: (count) =>
    set((state) => ({ posChange: state.posChange + count })),
}));

export default useMRFStore;
