import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import SelectForm from "../../../Components/SelectForm/SelectForm";
import InputForm from "../../../Components/InputForm/InputForm";
import DynamicGrid from "../../../Components/DynamicGrid/DynamicGrid";
import uuid from "react-uuid";
import DateForm from "../../../Components/DateForm/DateForm";
import moment from "moment";
import { APICall } from "../../../Helpers/APICalls";
import { LoaderContext } from "../../../Helpers/Context/Context";
import {
  getDropdowns,
  GetEmployeeBasedOnIdorCode,
  postAttributeValueDetailsV2,
  getSearchableDropdowns,
  getSearchableDdMrf,
  getMRFApproverByMRFId,
  CheckIfMRFIsAlreadyInProgressForPosition,
  GetPositionDetailsByEmployeeId,
  CheckMandatoryFieldsAreFilledInPosition,
  downloadDocument,
  deleteDocument,
  documentUpload,
  getDocuments,
  GetConfigValueByKey,
} from "../../../Helpers/APIEndPoints/EndPoints";
import notify from "../../../Helpers/ToastNotification";
import useUserContext from "../../../Helpers/ZustandStore/UserContextStore";
import { ExpandMore } from "@mui/icons-material";
import WorkflowSection from "./WorkflowSectionComponent";
import { MRFContext } from "../MRF";
import { useNavigate, useLocation } from "react-router-dom";
import { isEmpty } from "../../../utils/isEmpty";
import set from "date-fns/fp/set/index";
import { Modal } from "react-bootstrap";
import { RCMPdfViewer } from "../../../Components/PDFViewerRCM/PDFViewerRCM";

const SectionComponent = ({
  TId,
  setTId,
  formData,
  section: ogSection,
  sectionValuesArray,
  attrDataForPosition,
  activetab,
  index,
  lastIndex,
  COU,
  hideSectionAndAttribute,
  setHideSectionAndAttribute,
  positionIdDetails,
  setPositionIdDetails,
  positionCountryIdDetails,
  setPositionCountryDetails,
  disableAttribute,
  setDisableAttribute,
  setAttributeDataForPos,
  setJobId,
  isScreeningSubmitted,
  IsMRFAdmin = false,
}) => {
  const { state } = useLocation();
  const [hasValue, setHasValue] = useState(
    sectionValuesArray.filter((es) => es.sectionSystemName == ogSection.SN) ||
      null
  );

  const [section, setSection] = useState(ogSection);
  const [accordion, setAccordion] = useState(true);
  const [formErrors, setFormErrors] = useState<any>({});
  const [optionsObj, setOptionsObj] = useState<any>({});
  const [gridData, setGridData] = useState([]);
  const [displayGrid, setDisplayGrid] = useState([]);
  const [gridColumns, setGridColumns] = useState([]);
  const [ogAttributeSet, setOgAttributeSet] = useState({});
  const [ogFilledAttributeSet, setOgFilledAttributeSet] = useState([]);
  const [currentGridId, setCurrentGridId] = useState(null);
  const [deleteGridId, setDeleteGridId] = useState(null);
  const [attributesData, setAttributesData] = useState<any>({});
  const [attributeSetData, setAttributeSetData] = useState<any>({});
  const [attributeSetCode, setAttributeSetCode] = useState(null);
  const [isOnce, setIsOnce] = useState(true);
  const [attributeValidation, setAttributeValidation] = useState<any>({});
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [workflowAccordian, setWorkflowAccordian] = useState(true);
  const [btnDisable, setBtnDisable] = useState(false);
  const [mRREFWHDisable, setMRREFWHDisable] = useState(true);
  const [isDraft, setIsDraft] = useState(false);
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  const navigate = useNavigate();
  const [isPositionSetOnce, setIsPositionSetOnce] = useState(false);
  const { sendback, jobId } = useContext(MRFContext);
  const [jobRedirectionUrl, setJobRedirectionUrl] = useState(null);
  let FlatDocRef = useRef(null);
  const [fileViewModalPopup, setFileViewModalPopup] = useState(false);
  const [fileHeader, setFileHeader] = useState("");
  const [docViewBase64, setDocViewBase64] = useState(null);
  const [selectedFilesForFlat, setSelectedFilesForFlat] = useState<
    {
      AC: string;
      Name: string;
      Data: string;
      IsValidFile: boolean;
      DocId: Number;
      Type: string;
      Context: string;
      DCVALUE: string;
      DVTILL: string;
      DSTATUS: string;
      DPSINCE: string;
      DPWITH: string;
    }[]
  >([]);

  const [sectionHasDocuments, setSectionHasDocuments] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      showLoader();
      await handleFetchMRFApproverByMRFId();
      hideLoader();
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (String(activetab).toLocaleLowerCase() != "manpowerprocess") {
        showLoader();
        await handleSetAttributeData();
      }
    };

    fetchData().finally(() => {
      hideLoader();
    });
  }, []);

  useEffect(() => {
    if (attrDataForPosition?.length > 0) {
      (async () => {
        await handleBindDataForPosition();
      })();
    }
  }, [attrDataForPosition]);

  useEffect(() => {
    if (
      section.SN == "RequisitionDetails" ||
      section?.SN == "RequisitionDetails_MRF_Admin"
    ) {
      if (
        attributesData["MRPOSID"] &&
        attributesData["MRPOSCON"] &&
        Object.keys(attributesData["MRPOSID"])?.length > 0 &&
        Object.keys(attributesData["MRPOSCON"])?.length > 0
      ) {
        setPositionIdDetails(attributesData["MRPOSID"]);
        setPositionCountryDetails(attributesData["MRPOSCON"]);
        setIsOnce(true);
      }
    }
  }, [attributesData["MRPOSID"], attributesData["MRPOSCON"]]);

  useEffect(() => {}, [sendback, disableAttribute]);

  useEffect(() => {
    const helper = async () => {
      await GetJobRedirectionUrl();
    };
    helper();
  }, []);

  async function handleFileView({ header = "", fileAttribute = null }) {
    try {
      showLoader();
      let base64 = "";
      if (
        fileAttribute.DocId != undefined &&
        fileAttribute.DocId != null &&
        fileAttribute.DocId != 0
      ) {
        const doc = await APICall(downloadDocument, "POST", {
          TId,
          AC: fileAttribute.AC,
          DocId: fileAttribute.DocId,
        });
        if (doc && doc?.data !== null) {
          base64 = doc?.data;
        }
      } else {
        base64 = fileAttribute?.Data;
      }

      setFileHeader(header);
      setDocViewBase64(base64);
      setFileViewModalPopup(true);
    } catch (error) {
    } finally {
      hideLoader();
    }
  }

  async function handleCloseDocView() {
    try {
      setFileHeader("");
      setDocViewBase64(null);
      setFileViewModalPopup(false);
    } catch (error) {}
  }

  async function GetJobRedirectionUrl() {
    try {
      const { status, data, message } = await APICall(
        GetConfigValueByKey,
        "POST",
        {
          Key: "JDUrlRedirection",
        }
      );

      if (status == 0 && !isEmpty(data)) {
        const MRF = 300100 + Number(TId);
        let MRF_NO = `MRF${MRF}`;
        setJobRedirectionUrl(`${data}/?mrfid=${MRF_NO}`);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const getDropdownsData = async (AC, PID) => {
    await showLoader();

    const dropdownData = await APICall(getDropdowns, "POST", {
      AC,
      PID,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      IsMRF: true,
    });

    if (
      dropdownData.data !== null &&
      dropdownData.data !== undefined &&
      dropdownData.data.length > 0
    ) {
      await await hideLoader();
      return dropdownData.data;
    } else {
      await await hideLoader();
      return [];
    }
  };

  //Searchable Dropdown for Attributes
  const searchableDDValues = (event, AC) => {
    let DD = event.DD;
    if (DD?.length > 0) {
      let newAttributeData = { ...attributesData };
      let dropdownOptions = { ...optionsObj };

      DD.forEach((attribute) => {
        const Code = attribute.Key;

        // check DT

        if (attribute.DT === "DropdownSingle") {
          const options = dropdownOptions[Code];

          if (options !== undefined && options.length > 0) {
            const option = options?.find(
              (attri) => attri.value == attribute.Value
            );

            newAttributeData = {
              ...newAttributeData,
              [Code]: option === undefined ? null : option,
            };
          }
        } else {
          //check all DT
          newAttributeData = {
            ...newAttributeData,
            [Code]: attribute.Value,
          };
        }
      });
      newAttributeData[AC] = event; //imp

      setAttributesData(newAttributeData);
    }
  };

  //Searchable Dropdown for Attributes Set
  const searchableDDValuesSet = (event, AC) => {
    let DD = event.DD;
    if (DD.length > 0) {
      let newAttributeSetData = { ...attributeSetData };
      let dropdownOptions = { ...optionsObj };

      DD.forEach((attribute) => {
        const Code = attribute.Key;

        // check DT

        if (attribute.DT === "DropdownSingle") {
          const options = dropdownOptions[Code];

          if (options !== undefined && options.length > 0) {
            const option = options?.find(
              (attri) => attri.value == attribute.Value
            );

            newAttributeSetData = {
              ...newAttributeSetData,
              [Code]: option === undefined ? null : option,
            };
          }
        } else {
          //check all DT
          newAttributeSetData = {
            ...newAttributeSetData,
            [Code]: attribute.Value,
          };
        }
      });
      newAttributeSetData[AC] = event; //imp

      setAttributeSetData(newAttributeSetData);
    }
  };

  const fillSearchableDDValuesOnEdit = (DDResponse, AC) => {
    setAttributesData((prev) => ({
      ...prev,
      [AC]: { label: DDResponse.label, value: DDResponse.value },
    }));
  };

  const getSearchableDdForMrf = async (
    searchString,
    AC,
    cb,
    valueForDropdown,
    type,
    gridId
  ) => {
    if (
      searchString === "" &&
      cb === null &&
      valueForDropdown !== "" &&
      AC !== ""
    ) {
      const response = await APICall(getSearchableDdMrf, "POST", {
        searchString,
        AC,
        valueForDropdown,
        IsMRF: true,
        UserId: userDetails?.Id,
      });
      if (response.data !== null && response.data.length > 0) {
        if (response.data.length === 1) {
          if (gridId !== 0) {
            let ddObj = {
              gridId,
              AC,
              DDResponse: response.data[0],
            };
          } else {
            fillSearchableDDValuesOnEdit(response.data[0], AC);
          }
        }
      }
    } else if (searchString.length > 1) {
      const response = await APICall(getSearchableDdMrf, "POST", {
        searchString,
        AC,
        valueForDropdown,
        IsMRF: true,
        UserId: userDetails?.Id,
      });
      if (response.data !== null && response.data.length > 0) {
        cb(response.data);
        setOptionsObj((prev) => ({ ...prev, [AC]: response.data }));
      } else {
        cb([]);
        setOptionsObj((prev) => ({ ...prev, [AC]: [] }));
      }
    }
  };

  const getSearchableDropdownAPI = async (
    searchString,
    AC,
    cb,
    valueForDropdown,
    type,
    gridId
  ) => {
    if (
      searchString === "" &&
      cb === null &&
      valueForDropdown !== "" &&
      AC !== ""
    ) {
      const response = await APICall(getSearchableDropdowns, "POST", {
        searchString,
        AC,
        valueForDropdown,
        IsMRF: true,
      });
      if (response?.data !== null && response?.data?.length > 0) {
        if (response?.data?.length === 1) {
          if (gridId !== 0) {
            let ddObj = {
              gridId,
              AC,
              DDResponse: response.data[0],
            };
          } else {
            fillSearchableDDValuesOnEdit(response.data[0], AC);
          }
        }
      }
    } else if (searchString.length > 1) {
      const response = await APICall(getSearchableDropdowns, "POST", {
        searchString,
        AC,
        IsMRF: true,
      });
      if (response.data !== null && response.data.length > 0) {
        cb(response.data);
        setOptionsObj((prev) => ({ ...prev, [AC]: response.data }));
      } else {
        cb([]);
        setOptionsObj((prev) => ({ ...prev, [AC]: [] }));
      }
    }
  };

  const getParentPositionBasedOnEmployeeIdAsync = async (Id = 0) => {
    try {
      showLoader();
      const response = await APICall(GetPositionDetailsByEmployeeId, "POST", {
        Id,
      });

      if (response?.status == 0) {
        let responseData = response?.data;

        const POSPOSID =
          responseData?.find((k) => k.key == "POSPOSID")?.value || 0;
        const POSITID =
          responseData?.find((k) => k.key == "POSITID")?.value || "";

        if (POSITID && POSITID) {
          setAttributesData((prev) => {
            return {
              ...prev,
              ["MRPOSID"]: { value: POSPOSID, label: POSITID },
            };
          });
        } else {
          setAttributesData((prev) => {
            return { ...prev, ["MRPOSID"]: null };
          });
        }
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  const gridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    // count: gridData.length,
    rowsPerPage: gridData.length,
    // page: page,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",

    //standard | vertical | simple
    onChangeRowsPerPage: (num) => {
      //   setLimit(num);
      //   setNxtPgInfo("");
      //   setPrevPgInfo("");
      //   setIsPrevOrNext("");
    },
    // onSearchChange: (searchText) => {
    //   if (searchText !== null) {
    //     setSearchText(searchText);
    //   } else {
    //     setSearchText("");
    //   }
    // },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        // await setDashboardSortColumn(sortColumn);
        // await setDashboardSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        // await setDashboardSortColumn(sortColumn);
        // await setDashboardSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      // await setPage(page);
      // await setDashboardStart(page * dashboardPageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const action = {
    name: "Id",
    label: "Action",
    options: {
      filter: false,
      sort: false,
      display: true,
      setCellProps: () => ({
        style: { textAlign: "center" },
      }),
      setCellHeaderProps: () => ({
        style: { textAlign: "center" },
      }),
      customBodyRender: (value, tableMeta) => {
        let Id = tableMeta.tableData[tableMeta.rowIndex].Id;

        return (
          <div className="d-flex justify-content-center">
            <Tooltip title="edit">
              <a
                className="mr-2"
                onClick={(e) => {
                  e.preventDefault();
                  setFormErrors({});
                  setCurrentGridId(Id);
                }}
              >
                <i className="fas fa-edit"></i>
              </a>
            </Tooltip>
            <Tooltip title="delete">
              <a
                className="mr-2"
                onClick={(e) => {
                  e.preventDefault();
                  setFormErrors({});
                  setDeleteGridId(Id);
                }}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </a>
            </Tooltip>
          </div>
        );
      },
    },
  };

  const dependentValidations = (targetValue, targetAttributeCode) => {
    let attributeValues = { ...attributesData }; // for checking values
    let attributeValObj = { ...attributeValidation };

    if (
      attributeValObj[targetAttributeCode].DD !== null &&
      attributeValObj[targetAttributeCode].DD.length > 0
    ) {
      let ad = attributeValObj[targetAttributeCode].DD;

      ad.forEach(async (record) => {
        let CCode = record.CCode;
        let PValue = record.PValue;
        let Condition = record.Condition;
        let Flag = record.Flag;
        let PDT = record.PDT;
        let CDT = record.CDT;

        if (targetValue.toString()?.toLowerCase() === PValue?.toLowerCase()) {
          //check condition
          if (Flag === "disable") {
            attributeValObj[CCode].isDisable = true;
            attributeValObj[CCode].ISM = "false";
          }
        } else {
          attributeValObj[CCode].isDisable = false;
          attributeValObj[CCode].ISM = "true";
        }

        //clear values based to CDT
        attributeValues[CCode] =
          CDT === "DropdownSingle" ||
          CDT === "Date" ||
          CDT === "SearchableDropdownSingle"
            ? null
            : CDT === "Text" || CDT === "Number"
            ? ""
            : CDT === "Checkbox"
            ? false
            : "";
      });

      attributeValues[targetAttributeCode] = targetValue; //imp
      setFormErrors({});
      setAttributeValidation((prev) => ({ ...prev, ...attributeValObj }));
      setAttributesData((prev) => ({
        ...prev,
        ...attributeValues,
      }));
    }
  };

  const validateData = (Attribute) => {
    let errorObj = {};
    let isError = false;
    Attribute.forEach(async (subAttribute) => {
      if (subAttribute.AT === "Attribute") {
        const validation = JSON.parse(subAttribute.V);

        if (validation?.ISM?.toLowerCase() === "true") {
          if (subAttribute.DT === "Text") {
            if (attributeSetData[subAttribute.AC] === "") {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else if (
              attributeSetData[subAttribute.AC]?.length > validation.MaxC
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: `Max ${validation.MaxC} characters allowed`,
              };
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          } else if (
            subAttribute.DT === "DropdownSingle" ||
            subAttribute.DT === "SearchableDropdownSingle"
          ) {
            if (attributeSetData[subAttribute.AC] === null) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          } else if (subAttribute.DT === "Date") {
            if (attributeSetData[subAttribute.AC] === null) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else if (
              moment(
                validation.MaxV === "CurrentDate" &&
                  attributeSetData[subAttribute.AC]
              ).isAfter(new Date())
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Only till current date allowed",
              };
            } else if (
              moment(
                validation.MinV === "CurrentDate" &&
                  attributeSetData[subAttribute.AC]
              ).isBefore()
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Only after current date allowed",
              };
            } else if (
              validation.MinV !== undefined &&
              validation.MinV !== "CurrentDate" &&
              validation.MinV !== ""
            ) {
              let parentValue = attributeSetData[validation.MinV];
              if (
                parentValue !== null &&
                parentValue !== "" &&
                attributeSetData[subAttribute.AC] !== null &&
                attributeSetData[subAttribute.AC] !== "" &&
                moment(attributeSetData[subAttribute.AC]).isBefore(parentValue)
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Past date not allowed",
                };
              } else if (
                (parentValue === null || parentValue === "") &&
                attributeSetData[subAttribute.AC] !== null &&
                attributeSetData[subAttribute.AC] !== ""
              ) {
                errorObj = {
                  ...errorObj,
                  [validation.MinV]: "Required",
                };
              }
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          } else if (subAttribute.DT === "Number") {
            //change with regex
            if (attributeSetData[subAttribute.AC] === "") {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else if (
              !/^([0-9]+|0)$/.test(attributeSetData[subAttribute.AC])
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Only numbers are allowed",
              };
            } else if (
              Number(attributeSetData[subAttribute.AC]) > validation.MaxV
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: `Max ${validation.MaxV} allowed`,
              };
            } else if (
              Number(attributeSetData[subAttribute.AC]) < validation.MinV
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: `Min ${validation.MinV} required`,
              };
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          }
        } else {
          if (subAttribute.DT === "Number") {
            if (attributeSetData[subAttribute.AC] !== "") {
              if (!/^([0-9]+|0)$/.test(attributeSetData[subAttribute.AC])) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Only numbers are allowed",
                };
              } else if (
                Number(attributeSetData[subAttribute.AC]) > validation.MaxV
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: `Max ${validation.MaxV} allowed`,
                };
              } else if (
                Number(attributeSetData[subAttribute.AC]) < validation.MinV
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: `Min ${validation.MinV} required`,
                };
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }
            }
          } else if (subAttribute.DT === "Date") {
            if (attributeSetData[subAttribute.AC] !== null) {
              if (
                moment(
                  validation.MaxV === "CurrentDate" &&
                    attributeSetData[subAttribute.AC]
                ).isAfter(new Date())
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Only till current date allowed",
                };
              } else if (
                moment(
                  validation.MinV === "CurrentDate" &&
                    attributeSetData[subAttribute.AC]
                ).isBefore()
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Only after current date allowed",
                };
              } else if (
                validation.MinV !== undefined &&
                validation.MinV !== "CurrentDate" &&
                validation.MinV !== ""
              ) {
                let parentValue = attributeSetData[validation.MinV];
                if (
                  parentValue !== null &&
                  parentValue !== "" &&
                  attributeSetData[subAttribute.AC] !== null &&
                  attributeSetData[subAttribute.AC] !== "" &&
                  moment(attributeSetData[subAttribute.AC]).isBefore(
                    parentValue
                  )
                ) {
                  errorObj = {
                    ...errorObj,
                    [subAttribute.AC]: "Past date not allowed",
                  };
                } else if (
                  (parentValue === null || parentValue === "") &&
                  attributeSetData[subAttribute.AC] !== null &&
                  attributeSetData[subAttribute.AC] !== ""
                ) {
                  errorObj = {
                    ...errorObj,
                    [validation.MinV]: "Required",
                  };
                }
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }
            }
          }
        }
      }
    });

    const isEmpty = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  const handleModalSubmit = async (event) => {
    event.preventDefault();

    let errorObj = {};
    section.Attribute.map((eachAttribute) => {
      if (eachAttribute.AT === "Attribute") {
        const validation = attributeValidation[eachAttribute.AC];

        if (validation?.ISM?.toLowerCase() === "true") {
          if (eachAttribute.DT === "Text") {
            if (attributesData[eachAttribute.AC] === "") {
              errorObj = {
                ...errorObj,
                [eachAttribute.AC]: "Required",
              };
            } else if (
              attributesData[eachAttribute.AC] == null ||
              attributesData[eachAttribute.AC] == undefined ||
              String(attributesData[eachAttribute.AC]).trim().length == 0
            ) {
              errorObj = {
                ...errorObj,
                [eachAttribute.AC]: "Required",
              };
            } else if (
              attributesData[eachAttribute.AC]?.length > validation.MaxC &&
              !["MRMINSRNG", "MRMESARNG", "MRMXSARNG"].includes(
                eachAttribute.AC
              )
            ) {
              errorObj = {
                ...errorObj,
                [eachAttribute.AC]: `Max ${validation.MaxC} characters allowed`,
              };
            } else if (
              ["MRMINSRNG", "MRMESARNG", "MRMXSARNG"].includes(eachAttribute.AC)
            ) {
              const OnlyNumberRegex = /^[1-9]\d*$/;

              if (!OnlyNumberRegex.test(attributesData[eachAttribute.AC])) {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: "Only numeric values are allowed.",
                };
              } else {
                const AC = eachAttribute?.AC;
                const ACValue = Number(attributesData[AC]);
                const AN = eachAttribute?.AN || "";

                if (AC == "MRMINSRNG") {
                  const MRMESARNG = Number(attributesData["MRMESARNG"]);

                  const AN_MRMESARNG = section?.Attribute?.find(
                    (attribute) => attribute?.AC == "MRMESARNG"
                  );

                  if (!Number.isNaN(MRMESARNG)) {
                    if (ACValue > MRMESARNG) {
                      errorObj = {
                        ...errorObj,
                        [eachAttribute.AC]: `${AN} cannot be greather than ${
                          AN_MRMESARNG?.AN || ""
                        }`,
                      };
                    } else {
                      errorObj = {
                        ...errorObj,
                        [eachAttribute.AC]: "",
                      };
                    }
                  } else {
                    errorObj = {
                      ...errorObj,
                      [eachAttribute.AC]: "",
                    };
                  }
                } else if (AC == "MRMESARNG") {
                  const MRMXSARNG = Number(attributesData["MRMXSARNG"]);

                  const OtherCode = section?.Attribute?.find(
                    (attribute) => attribute?.AC == "MRMXSARNG"
                  );

                  if (!Number.isNaN(MRMXSARNG)) {
                    if (ACValue > MRMXSARNG) {
                      errorObj = {
                        ...errorObj,
                        [eachAttribute.AC]: `${AN} cannot be greather than ${
                          OtherCode?.AN || ""
                        }`,
                      };
                    } else {
                      errorObj = {
                        ...errorObj,
                        [eachAttribute.AC]: "",
                      };
                    }
                  } else {
                    errorObj = {
                      ...errorObj,
                      [eachAttribute.AC]: "",
                    };
                  }
                }
              }
            } else {
              errorObj = {
                ...errorObj,
                [eachAttribute.AC]: "",
              };
            }
          } else if (
            eachAttribute.DT === "DropdownSingle" ||
            eachAttribute.DT === "SearchableDropdownSingle"
          ) {
            if (
              attributesData[eachAttribute.AC] === null ||
              typeof attributesData[eachAttribute.AC] === "undefined" ||
              (typeof attributesData[eachAttribute.AC] === "object" &&
                Object.keys(attributesData[eachAttribute.AC]).length === 0)
            ) {
              errorObj = {
                ...errorObj,
                [eachAttribute.AC]: "Required",
              };
            } else {
              errorObj = {
                ...errorObj,
                [eachAttribute.AC]: "",
              };
            }
          } else if (eachAttribute.DT === "Date") {
            if (
              attributesData[eachAttribute.AC] === null ||
              attributesData[eachAttribute.AC] == undefined
            ) {
              errorObj = {
                ...errorObj,
                [eachAttribute.AC]: "Required",
              };
            } else if (!moment(attributesData[eachAttribute.AC]).isValid()) {
              errorObj = {
                ...errorObj,
                [eachAttribute.AC]: "Invalid Date",
              };
            } else if (
              moment(
                validation.MaxV === "CurrentDate" &&
                  attributesData[eachAttribute.AC]
              ).isAfter(new Date())
            ) {
              errorObj = {
                ...errorObj,
                [eachAttribute.AC]: "Only till current date allowed",
              };
            } else if (
              moment(
                validation.MinV === "CurrentDate" &&
                  attributesData[eachAttribute.AC]
              ).isBefore()
            ) {
              errorObj = {
                ...errorObj,
                [eachAttribute.AC]: "Only after current date allowed",
              };
            } else if (
              validation.MinV !== undefined &&
              validation.MinV !== "CurrentDate" &&
              validation.MinV !== ""
            ) {
              let parentValue = attributesData[validation.MinV];
              if (
                parentValue !== null &&
                parentValue !== "" &&
                attributesData[eachAttribute.AC] !== null &&
                attributesData[eachAttribute.AC] !== "" &&
                moment(attributesData[eachAttribute.AC]).isBefore(parentValue)
              ) {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: "Past date not allowed",
                };
              } else if (
                (parentValue === null || parentValue === "") &&
                attributesData[eachAttribute.AC] !== null &&
                attributesData[eachAttribute.AC] !== ""
              ) {
                errorObj = {
                  ...errorObj,
                  [validation.MinV]: "Required",
                };
              }
            } else {
              errorObj = {
                ...errorObj,
                [eachAttribute.AC]: "",
              };
            }
          } else if (eachAttribute.DT === "Number") {
            //change with regex

            if (attributesData[eachAttribute.AC] === "") {
              errorObj = {
                ...errorObj,
                [eachAttribute.AC]: "Required",
              };
            } else if (
              // isNaN(attributesData[eachAttribute.AC])
              !/^([0-9]+|0)$/.test(attributesData[eachAttribute.AC])
            ) {
              errorObj = {
                ...errorObj,
                [eachAttribute.AC]: "Only numbers are allowed",
              };
            } else if (
              Number(attributesData[eachAttribute.AC]) > validation.MaxV
            ) {
              errorObj = {
                ...errorObj,
                [eachAttribute.AC]: `Max ${validation.MaxV} allowed`,
              };
            } else if (
              Number(attributesData[eachAttribute.AC]) < validation.MinV
            ) {
              errorObj = {
                ...errorObj,
                [eachAttribute.AC]: `Min ${validation.MinV} required`,
              };
            } else {
              errorObj = {
                ...errorObj,
                [eachAttribute.AC]: "",
              };
            }
          }
        } else {
          // for num and date

          if (eachAttribute.DT === "Number") {
            if (attributesData[eachAttribute.AC] !== "") {
              //change with regex
              if (
                // isNaN(attributesData[eachAttribute.AC])
                !/^([0-9]+|0)$/.test(attributesData[eachAttribute.AC])
              ) {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: "Only numbers are allowed",
                };
              } else if (
                Number(attributesData[eachAttribute.AC]) > validation.MaxV
              ) {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: `Max ${validation.MaxV} allowed`,
                };
              } else if (
                Number(attributesData[eachAttribute.AC]) < validation.MinV
              ) {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: `Min ${validation.MinV} required`,
                };
              } else {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: "",
                };
              }
            }
          } else if (eachAttribute.DT === "Date") {
            if (attributesData[eachAttribute.AC] !== null) {
              if (
                moment(
                  validation.MaxV === "CurrentDate" &&
                    attributesData[eachAttribute.AC]
                ).isAfter(new Date())
              ) {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: "Only till current date allowed",
                };
              } else if (
                moment(
                  validation.MinV === "CurrentDate" &&
                    attributesData[eachAttribute.AC]
                ).isBefore()
              ) {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: "Only after current date allowed",
                };
              } else if (
                validation.MinV !== undefined &&
                validation.MinV !== "CurrentDate" &&
                validation.MinV !== ""
              ) {
                let parentValue = attributesData[validation.MinV];
                if (
                  parentValue !== null &&
                  parentValue !== "" &&
                  attributesData[eachAttribute.AC] !== null &&
                  attributesData[eachAttribute.AC] !== "" &&
                  moment(attributesData[eachAttribute.AC]).isBefore(parentValue)
                ) {
                  errorObj = {
                    ...errorObj,
                    [eachAttribute.AC]: "Past date not allowed",
                  };
                } else if (
                  (parentValue === null || parentValue === "") &&
                  attributesData[eachAttribute.AC] !== null &&
                  attributesData[eachAttribute.AC] !== ""
                ) {
                  errorObj = {
                    ...errorObj,
                    [validation.MinV]: "Required",
                  };
                }
              } else {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: "",
                };
              }
            }
          }
        }
      } else {
        //submit attributeSet
      }
    });

    const isEmpty = Object.values(errorObj).every((s) => s === "");

    if (isEmpty && attributeSetCode === null) {
      // post data attribute
      const postAttributes = [];

      Object.entries(attributesData).forEach((attributeData) => {
        const [Code, Value]: any[] = attributeData;

        let attribute = {
          Type: "Attribute",
          Code,
          Value:
            typeof Value === "object"
              ? Value?.value?.toString() || null
              : typeof Value === "string" || typeof Value === "boolean"
              ? Value
              : null,
        };
        postAttributes.push(attribute);
      });

      /* DONOT REMOVE THIS CODE */
      if (
        (String(section?.SN).toLocaleLowerCase().trim() ==
          "requisitiondetails" ||
          String(section?.SN).toLocaleLowerCase().trim() ==
            "RequisitionDetails_MRF_Admin") &&
        !TId
      ) {
        const postiD =
          postAttributes?.find((i) => i?.Code == "MRPOSID")?.Value || 0;

        const response = await APICall(
          CheckIfMRFIsAlreadyInProgressForPosition,
          "POST",
          {
            Id: postiD,
          }
        );

        if (response?.status == 0) {
          if (response?.data == true) {
            notify(1, "MRF already exists for the position.");
            return;
          }
        } else {
          notify(1, "Something went wrong.");
          console.error(response?.message || "");
          return;
        }

        const positionResponse = await APICall(
          CheckMandatoryFieldsAreFilledInPosition,
          "POST",
          {
            Id: postiD,
          }
        );

        if (positionResponse?.status == 0) {
          if (positionResponse?.data?.isError) {
            notify(1, positionResponse?.data?.message);
            return;
          }
        } else {
          console.error(positionResponse?.message);
          return;
        }
      }

      let postObj = {
        MN: "ManpowerRequisition",
        IN: "ManpowerRequisition_Form",
        TN: formData.tn,
        SN: section.SN,
        TId: TId || 0,
        Attributes: postAttributes,
        UserId: userDetails.Id,
        UserEmailId: userDetails.EmailId,
        UserName: userDetails.FirstName,
        RoleId: currentRoleId?.value,
        COU: COU,
      };
      showLoader();
      setBtnDisable(true);
      setFormErrors((err) => ({}));
      const postRes = await APICall(
        postAttributeValueDetailsV2,
        "POST",
        postObj
      );
      if (
        postRes.data !== null &&
        (section.SN === "RequisitionDetails" ||
          section?.SN == "RequisitionDetails_MRF_Admin") &&
        postRes.data.id !== undefined
      ) {
        setTId(postRes.data.id);
        navigate("/mrf", {
          state: { TId: postRes.data.id },
        });

        if (selectedFilesForFlat.length > 0) {
          let newFiles = selectedFilesForFlat.filter((sf) => {
            return sf.DocId === 0;
          });
          try {
            const response = await APICall(documentUpload, "POST", {
              ModuleName: "MRF",
              files: newFiles,
              SectionName: section.SN,
              TabName: "Requisition",
              TId: postRes.data?.id || 0,
              ModuleId: 5,
              UserId: userDetails?.Id,
            });

            if (response.data === null || response.data === undefined) {
              notify(1, "Failed to upload documents.");
            } else {
              setSelectedFilesForFlat([
                ...selectedFilesForFlat,
                response.data.files,
              ]);
            }
          } catch (error) {
            throw new Error("Error uploading documents: " + error.message);
          }
        }
      }
      setBtnDisable(false);
      hideLoader();
      notify(postRes.status, postRes.message);
    } else if (isEmpty && attributeSetCode !== null) {
      await showLoader();

      let details = [...ogFilledAttributeSet];

      const finalDetails = [];
      details.forEach(async (attributes) => {
        let attributeObj = {};
        Object.keys(attributes).forEach(async (attribute) => {
          attributeObj = {
            ...attributeObj,
            [attribute]:
              typeof attributes[attribute] === "object"
                ? attributes[attribute]?.value.toString() || null
                : typeof attributes[attribute] === "string" ||
                  typeof attributes[attribute] === "boolean"
                ? attributes[attribute]
                : null,
          };

          if (attribute === "Id" && isNaN(Number(attributes[attribute]))) {
            attributeObj = {
              ...attributeObj,
              [attribute]: 0,
            };
          }
        });
        finalDetails.push(attributeObj);
      });

      let postObj = {
        MN: "ManpowerRequisition",
        IN: "ManpowerRequisition_Form",
        TN: formData.tn,
        SN: section.SN,
        TId: TId || 0,
        Attributes: [
          {
            Code: attributeSetCode,
            Value: "",
            Type: "AttributeSet",
            Details: finalDetails,
          },
        ],
        COU: COU,
      };

      const postRes = await APICall(
        postAttributeValueDetailsV2,
        "POST",
        postObj
      );

      debugger;

      hideLoader();
      notify(postRes.status, postRes.message);

      if (selectedFilesForFlat.length > 0) {
        let newFiles = selectedFilesForFlat.filter((sf) => {
          return sf.DocId === 0;
        });
        try {
          const response = await APICall(documentUpload, "POST", {
            ModuleName: "MRF",
            files: newFiles,
            SectionName: section.SN,
            TabName: "Requisition",
            TId: postRes.data?.id || 0,
            ModuleId: 5,
            UserId: userDetails?.Id,
          });

          if (response.data === null || response.data === undefined) {
            notify(1, "Failed to upload documents.");
          } else {
            setSelectedFilesForFlat([
              ...selectedFilesForFlat,
              response.data.files,
            ]);
          }
        } catch (error) {
          throw new Error("Error uploading documents: " + error.message);
        }
      }
    }
    setFormErrors((err) => ({
      ...err,
      ...errorObj,
    }));
  };

  const handleFetchMRFApproverByMRFId = async () => {
    try {
      const response = await APICall(getMRFApproverByMRFId, "POST", {
        MRFId: TId,
      });

      if (response?.status == 0 && response?.data?.length > 0) {
        let isDraft = response?.data?.some((item) => item.isDraft == true);

        setDisableAttribute(!isDraft);
        setIsDraft(isDraft);
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const handleChangeEventForDropdown = async (event, eachAttribute) => {
    try {
      setAttributesData((prev) => ({
        ...prev,
        [eachAttribute.AC]: event,
      }));

      if (eachAttribute?.AC == "MREVRE") {
        setMRREFWHDisable(event?.code == "REP");
      }

      if (
        event !== null &&
        eachAttribute.CC !== null &&
        eachAttribute.CC !== ""
      ) {
        const CC = eachAttribute.CC.split(",");

        CC.forEach(async (childDropdown) => {
          setAttributesData((prev) => ({
            ...prev,
            [childDropdown]: null,
          }));

          const options = await getDropdownsData(childDropdown, event.value);

          setOptionsObj((prev) => ({
            ...prev,
            [childDropdown]: options,
          }));
        });

        // if (eachAttribute?.AC == "MRREFWH" && event?.value) {
        //   await getParentPositionBasedOnEmployeeIdAsync(event?.value);
        // }
      }

      dependentValidations(event, eachAttribute.AC);
      const AC = eachAttribute?.AC;

      if (AC == "MRPOSID" || AC == "MRPOSCON") {
        if (AC == "MRPOSID") {
          setPositionIdDetails(event);
        } else {
          setPositionCountryDetails(event);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeEventForSearchableDropdown = async (
    event,
    eachAttribute
  ) => {
    try {
      setAttributesData((prev) => ({
        ...prev,
        [eachAttribute.AC]: event,
      }));

      if (
        event !== null &&
        eachAttribute.CC !== null &&
        eachAttribute.CC !== ""
      ) {
        const CC = eachAttribute.CC.split(",");

        CC.forEach(async (childDropdown) => {
          setAttributesData((prev) => ({
            ...prev,
            [childDropdown]: null,
          }));

          const options = await getDropdownsData(childDropdown, event.value);

          setOptionsObj((prev) => ({
            ...prev,
            [childDropdown]: options,
          }));
        });
      }

      dependentValidations(event, eachAttribute.AC);

      //searchable DD
      if (event) {
        searchableDDValues(event, eachAttribute.AC);
      }

      const AC = eachAttribute?.AC;

      if (AC == "MRPOSID" || AC == "MRPOSCON") {
        if (AC == "MRPOSID") {
          setPositionIdDetails(event);
        } else {
          positionCountryIdDetails(event);
        }
      }

      // if (eachAttribute?.AC == "MRREFWH" && event?.value) {
      //   await getParentPositionBasedOnEmployeeIdAsync(event?.value);
      // }
    } catch (error) {
      console.error(error);
    }
  };

  //UTILS
  const getSearchableDropdownAPIWithoutCallback = async (
    searchString,
    AC,
    valueForDropdown,
    type,
    gridId
  ) => {
    if (searchString === "" && valueForDropdown !== "" && AC !== "") {
      if (AC == "MRREFWH") {
        const DDResponse = await GetEmployeeBasedOnId(valueForDropdown || 0);
        return DDResponse || {};
      }

      const response = await APICall(getSearchableDropdowns, "POST", {
        searchString,
        AC,
        valueForDropdown,
        IsMRF: true,
      });
      if (response?.data !== null && response?.data?.length > 0) {
        if (response?.data?.length === 1) {
          if (gridId !== 0) {
            let ddObj = {
              gridId,
              AC,
              DDResponse: response.data[0],
            };
          } else {
            fillSearchableDDValuesOnEdit(response.data[0], AC);
          }
        }
      }
    } else if (searchString.length > 1) {
      const response = await APICall(getSearchableDropdowns, "POST", {
        searchString,
        AC,
        IsMRF: true,
      });
      if (response.data !== null && response.data.length > 0) {
        if (Array.isArray(response.data)) {
          return response.data[0];
        } else {
          return response.data;
        }
      } else {
        return null;
      }
    }
  };

  const GetEmployeeBasedOnId = async (value) => {
    try {
      showLoader();
      const response = await APICall(GetEmployeeBasedOnIdorCode, "POST", {
        valueForDropdown: value,
        searchString: "",
      });

      if (response?.status != 0) return {};
      return response?.data;
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };

  const GetEmployeeBasedOnCode = async (event, cb) => {
    try {
      showLoader();
      if (event?.length < 3) {
        cb([]);
        return;
      }
      const response = await APICall(GetEmployeeBasedOnIdorCode, "POST", {
        valueForDropdown: "",
        searchString: event,
      });

      if (response?.status != 0) return {};
      cb(response?.data);
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };

  const resetValueByDataType = (dataType) => {
    switch (dataType) {
      case "DropdownSingle":
      case "Date":
      case "SearchableDropdownSingle":
        return null;
      case "Text":
      case "Number":
        return "";
      case "Checkbox":
        return false;
      default:
        return "";
    }
  };

  const getStoredValueFromSectionValueArray = async (attribute) => {
    try {
      const attributeValues = hasValue[0].attributes;
      const attributeCode = attribute?.AC;
      const attributeValue = attributeValues?.find(
        (atr) => atr?.key === attributeCode
      )?.value;

      switch (attribute.DT) {
        case "DropdownSingle":
          const options = attribute?.OPT || [];
          const filteredOption = options?.find(
            (option) => option?.value == attributeValue
          );
          return filteredOption;
        case "Date":
          let formattedDate = moment(attributeValue).isValid()
            ? moment(attributeValue).format("DD-MMM-YYYY")
            : null;
          return formattedDate;
        case "SearchableDropdownSingle":
          const DDResponse = await getSearchableDropdownAPIWithoutCallback(
            "",
            attributeCode,
            attributeValue || 0,
            "attribute",
            0
          );
          return DDResponse || {};
        case "Text":
        case "Number":
        case "Checkbox":
          return attributeValue;
        default:
          return attributeValue;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getStoredValueFromPosition = async (attribute) => {
    try {
      const attributeValues = attrDataForPosition;
      const attributeCode = attribute?.AC;
      const attributeValue = attributeValues?.find(
        (atr) => atr?.key === attributeCode
      )?.value;

      switch (attribute.DT) {
        case "DropdownSingle":
          const options = attribute?.OPT || [];
          const filteredOption = options?.find(
            (option) => option?.value == attributeValue
          );
          return filteredOption;
        case "Date":
          let formattedDate = moment(attributeValue).isValid()
            ? moment(attributeValue).format("DD-MMM-YYYY")
            : null;
          return formattedDate;
        case "SearchableDropdownSingle":
          const DDResponse = await getSearchableDropdownAPIWithoutCallback(
            "",
            attributeCode,
            attributeValue || 0,
            "attribute",
            0
          );
          return DDResponse || {};
        case "Text":
        case "Number":
        case "Checkbox":
          return attributeValue;
        default:
          return attributeValue;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSetAttributeData = async () => {
    try {
      let attributes = {};
      let dropdownOptionsObj = {};
      const validationObj = {};

      let attributeSet = {};
      const gridColumnsArr = [];
      let subAttributeSetValidation = {};
      const sectionHasDocuments = [];
      if (section?.Attribute?.length > 0) {
        showLoader();
        await Promise.all(
          section?.Attribute?.map(async (eachAttribute) => {
            if (eachAttribute.AT === "Attribute") {
              const defaultValue = resetValueByDataType(eachAttribute.DT);
              const AC = eachAttribute?.AC;
              const DT = eachAttribute?.DT;

              attributes[AC] = defaultValue;
              validationObj[AC] = JSON.parse(eachAttribute.V);
              validationObj[AC] = {
                ...validationObj[AC],
                DD:
                  eachAttribute.DD !== null
                    ? JSON.parse(eachAttribute.DD)
                    : null,
                isDisable:
                  section?.ISEDIT == false
                    ? true
                    : disableAttribute == true
                    ? true
                    : false,
              };

              if (DT === "DropdownSingle") {
                dropdownOptionsObj[AC] = eachAttribute.OPT;

                if (AC == "MRFEVENT" && isEmpty(TId)) {
                  if (!isEmpty(dropdownOptionsObj[AC])) {
                    const dd = dropdownOptionsObj[AC][0];
                    attributes[AC] = dd;
                    handleChangeEventForDropdown(dd, eachAttribute);
                  }
                }
              } else if (DT === "SearchableDropdownSingle") {
                dropdownOptionsObj[AC] = "SearchableDropdownSingle";
              }

              //for document
              if (eachAttribute.DT === "Document") {
                setSectionHasDocuments(true);
                sectionHasDocuments.push(eachAttribute.AC);
              }
            } else if (eachAttribute.AT === "AttributeSet") {
              const AC = eachAttribute.AC;
              setAttributeSetCode(AC);

              gridColumnsArr.push({
                name: "Id",
                label: "Id",
                options: { display: false },
              });

              eachAttribute.Attribute.map((subAttribute) => {
                let display = JSON.parse(subAttribute.AD);
                display =
                  display?.ISOG?.toLowerCase() === "true" ? true : false;

                const SubAC = subAttribute.AC;
                const SubAN = subAttribute.AN;
                const SubDT = subAttribute.DT;

                gridColumnsArr.push({
                  name: SubAC,
                  label: SubAN,
                  options: { sort: false, display },
                });

                subAttributeSetValidation = {
                  ...subAttributeSetValidation,
                  [SubAC]: JSON.parse(subAttribute.V),
                };

                const defaultSubAttributeValue = resetValueByDataType(
                  subAttribute.DT
                );

                attributeSet = {
                  ...attributeSet,
                  [SubAC]: defaultSubAttributeValue,
                };

                if (SubDT === "DropdownSingle") {
                  dropdownOptionsObj[SubAC] = eachAttribute.OPT;
                } else if (SubDT === "SearchableDropdownSingle") {
                  dropdownOptionsObj[SubAC] = "SearchableDropdownSingle";
                }
              });
            }
          })
        );

        if (
          (isOnce &&
            TId > 0 &&
            hasValue.length > 0 &&
            hasValue[0].attributes.length > 0) ||
          (section.SN == "MRFJobDescription" &&
            isOnce &&
            hasValue.length > 0 &&
            hasValue[0].attributes.length > 0)
        ) {
          (async () => {
            showLoader();

            await Promise.all(
              section.Attribute.map((eachAttribute) => {
                return new Promise(async (resolve) => {
                  const defaultValue = resetValueByDataType(eachAttribute.DT);
                  const AC = eachAttribute?.AC;

                  if (
                    section.SN == "RequisitionDetails" ||
                    section?.SN == "RequisitionDetails_MRF_Admin" ||
                    section.SN == "MRFJobDescription"
                  ) {
                    const actualValue =
                      await getStoredValueFromSectionValueArray(eachAttribute);

                    let finalValue = actualValue ? actualValue : defaultValue;
                    attributes[AC] = finalValue;

                    resolve(actualValue);
                  } else {
                    resolve(defaultValue);
                  }
                });
              })
            );

            let attributesRes = hasValue[0].attributes;

            let newAttributeData: any = { ...attributes };
            if (sectionHasDocuments.length > 0) {
              // getDocuments for Flat
              for (const AC of sectionHasDocuments) {
                if (newAttributeData.hasOwnProperty(AC)) {
                  await showLoader();
                  const getDocument = await APICall(getDocuments, "POST", {
                    TId: TId,
                    AC: AC,
                  });

                  if (getDocument && getDocument.data !== null) {
                    setSelectedFilesForFlat(getDocument.data.files);
                  } else {
                    setSelectedFilesForFlat([]);
                  }
                  await hideLoader();
                }
              }
            }
            setIsOnce(false);
          })();
        }
      }

      if (!attributes["MRPOSID"]) {
        attributes = {
          ...attributes,
          ["MRPOSID"]: state?.posId,
        };
      }

      setAttributesData(attributes);
      setAttributeValidation(validationObj);

      setOgAttributeSet(attributeSet);
      setGridColumns((prev) => [...prev, ...gridColumnsArr, action]);
      setAttributeSetData(attributeSet);

      setOptionsObj(dropdownOptionsObj);
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const handleBindDataForPosition = async () => {
    try {
      if (IsMRFAdmin) {
        let attributes = attributesData;
        // if (IsMRFAdmin) {
        //   attributes = JSON.parse(JSON.stringify(attributesData)) || {};
        // }
        showLoader();
        if (section?.Attribute?.length > 0) {
          await Promise.all(
            section.Attribute.map((eachAttribute) => {
              return new Promise(async (resolve) => {
                const AC = eachAttribute?.AC;
                if (
                  section.SN != "RequisitionDetails" ||
                  section?.SN == "RequisitionDetails_MRF_Admin" ||
                  AC == "MRREFWH" ||
                  AC == "MRMINSRNG" ||
                  AC == "MRMESARNG" ||
                  AC == "MRMXSARNG"
                ) {
                  if (
                    (AC == "MRMINSRNG" ||
                      AC == "MRMESARNG" ||
                      AC == "MRMXSARNG") &&
                    disableAttribute
                  ) {
                    resolve("");
                  } else if (
                    (AC == "MRMINSRNG" ||
                      AC == "MRMESARNG" ||
                      AC == "MRMXSARNG") &&
                    (isDraft || sendback) &&
                    isPositionSetOnce == false
                  ) {
                    setIsPositionSetOnce(true);
                    resolve("");
                  } else {
                    const actualValue = await getStoredValueFromPosition(
                      eachAttribute
                    );

                    if (!isEmpty(actualValue) || actualValue == 0) {
                      attributes[AC] = actualValue;
                    }

                    resolve(actualValue);
                  }
                } else {
                  resolve("");
                }
              });
            })
          );
        }
        setAttributesData((prev) => {
          return { ...prev, attributes };
        });
      } else {
        let attributes = attributesData;
        showLoader();
        if (section?.Attribute?.length > 0) {
          await Promise.all(
            section.Attribute.map((eachAttribute) => {
              return new Promise(async (resolve) => {
                const AC = eachAttribute?.AC;
                if (
                  section.SN != "RequisitionDetails" ||
                  section?.SN == "RequisitionDetails_MRF_Admin" ||
                  AC == "MRREFWH" ||
                  AC == "MRMINSRNG" ||
                  AC == "MRMESARNG" ||
                  AC == "MRMXSARNG"
                ) {
                  if (
                    (AC == "MRMINSRNG" ||
                      AC == "MRMESARNG" ||
                      AC == "MRMXSARNG") &&
                    disableAttribute
                  ) {
                    resolve("");
                  } else if (
                    (AC == "MRMINSRNG" ||
                      AC == "MRMESARNG" ||
                      AC == "MRMXSARNG") &&
                    (isDraft || sendback) &&
                    isPositionSetOnce == false
                  ) {
                    setIsPositionSetOnce(true);
                    resolve("");
                  } else {
                    const actualValue = await getStoredValueFromPosition(
                      eachAttribute
                    );

                    if (actualValue || actualValue == 0) {
                      attributes[AC] = actualValue;
                    }

                    resolve(actualValue);
                  }
                } else {
                  resolve("");
                }
              });
            })
          );
        }
        setAttributesData((prev) => {
          return { ...prev, attributes };
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const handleFileValidation = (files, attribute) => {
    const { FS, FX, FC } = JSON.parse(attribute.V);
    // Split the validExtensionsString into an array
    const validExtensions = FX.split(",");

    let isValid = [];

    // validate file count
    if (files.length > FC) {
      notify(1, `Only ${FC} files allowed!`);
      return false;
    }
    // check extension of each file
    files.forEach((file) => {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();

      // Check if the file extension is allowed
      if (!validExtensions.includes("." + fileExtension)) {
        isValid.push(false);
      } else {
        isValid.push(true); //valid
      }
    });

    let returnValue = isValid.some((v) => v === false);

    if (returnValue) {
      notify(1, `Please upload file within this ${FX} extentsion`);
      return false;
    }

    files.forEach((file) => {
      const fileSize = file.size;
      const maxFileSizeBytes = FS * 1024 * 1024;
      if (fileSize <= maxFileSizeBytes) {
        isValid.push(true); //valid
      } else {
        isValid.push(false);
      }
    });

    returnValue = isValid.some((v) => v === false);

    if (returnValue) {
      notify(1, `File size exceeds ${FS}MB. Please select a smaller file.`);
      return false;
    } else {
      return true;
    }
  };

  const handleFileChangeForFlat = async (e, attribute) => {
    debugger;
    const { FS, FX, FC } = JSON.parse(attribute.V);

    const files = Array.prototype.slice.call(e.target.files);

    const isValid = handleFileValidation(files, attribute);
    if (isValid) {
      if (files && files.length > 0) {
        const base64Array: { Name: string; Data: string }[] = [];
        for (let i = 0; i < files.length; i++) {
          const base64 = (await fileToBase64(files[i])) as string;
          const base64WithoutPrefix = base64.split(",")[1];
          base64Array.push({ Name: files[i].name, Data: base64WithoutPrefix });
        }

        //check if AC present and file count matched

        const fileCount = selectedFilesForFlat.reduce((total, item) => {
          if (item.AC === attribute.AC) {
            return total + 1;
          }
          return total;
        }, 0);

        if (fileCount == FC) {
          notify(1, `Only ${FC} files allowed!`);
        } else {
          // let type =
          //   JSON.parse(attribute.AD).CON === "National ID Information"
          //     ? attributeSetData["IDCATY"]?.label
          //     : JSON.parse(attribute.AD).TYP
          //     ? JSON.parse(attribute.AD).TYP
          //     : "";
          base64Array.forEach((file) => {
            setSelectedFilesForFlat((prevSelectedFiles) => [
              ...prevSelectedFiles,
              {
                AC: attribute.AC, //==="CONFDOC"?attributesData["DOCTYPE"]?.code :attribute.AC,
                Name: file.Name,
                Data: file.Data,
                IsValidFile: false,
                DocId: 0,
                Type:
                  attribute.AC === "CONFDOC"
                    ? attributesData["DOCTYPE"]?.label
                    : JSON.parse(attribute.AD).TYP
                    ? JSON.parse(attribute.AD).TYP
                    : "",
                Context:
                  attribute.AC === "CONFDOC"
                    ? attributesData["CNTXT"]
                    : JSON.parse(attribute.AD).CON
                    ? JSON.parse(attribute.AD).CON
                    : "",
                DCVALUE: "",
                DVTILL: "",
                DSTATUS: "Verified",
                DPSINCE: "",
                DPWITH: "",
              },
            ]);
          });
        }
      }
    } else {
      e.target.value = null;
    }
  };

  const handleDownloadFileFlat = async (fileAttribute, fileIndex) => {
    showLoader();
    if (
      fileAttribute.DocId != undefined &&
      fileAttribute.DocId != null &&
      fileAttribute.DocId != 0
    ) {
      const doc = await APICall(downloadDocument, "POST", {
        TId,
        AC: fileAttribute.AC,
        DocId: fileAttribute.DocId,
      });
      if (doc && doc?.data !== null) {
        downloadBase64File(doc.data, fileAttribute.Name);
        hideLoader();
        return doc?.data;
      } else {
        notify(1, "Something Went Wrong.");
        hideLoader();
      }
    } else {
      downloadBase64File(fileAttribute.Data, fileAttribute.Name);
      hideLoader();
      return fileAttribute?.Data;
    }
  };

  const handleDeleteFileForFlat = async (fileAttribute, fileIndex) => {
    if (
      fileAttribute.DocId != undefined &&
      fileAttribute.DocId != null &&
      fileAttribute.DocId != 0
    ) {
      showLoader();
      const doc = await APICall(deleteDocument, "POST", {
        TId,
        AC: fileAttribute.AC,
        DocId: fileAttribute.DocId,
        UserId: proxyUserDetails?.value
          ? proxyUserDetails?.value
          : userDetails.UserId,
      });
      if (doc.message === "Success") {
        FlatDocRef.current.value = null;
        let remainingDocs = selectedFilesForFlat.filter(function (obj) {
          return obj.DocId !== fileAttribute.DocId;
        });
        setSelectedFilesForFlat(remainingDocs);
        hideLoader();
      } else {
        notify(1, "File not Deleted, Something Went Wrong.");
        hideLoader();
      }
    } else {
      FlatDocRef.current.value = null;
      setSelectedFilesForFlat((prevSelectedFiles) => {
        return prevSelectedFiles.filter(
          (item, attIndex) => fileIndex !== attIndex
        );
      });
    }
  };

  function downloadBase64File(base64String: string, fileName: string) {
    const mimeType = inferMimeType(base64String);
    const blob = base64toBlob(base64String, mimeType);

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  }

  function inferMimeType(base64String: string): string {
    const dataUrl = `data:application/octet-stream;base64,${base64String}`;
    const typeInfo = /^data:(.*?);/.exec(dataUrl);

    if (typeInfo && typeInfo[1]) {
      return typeInfo[1];
    }

    return "application/octet-stream";
  }
  function base64toBlob(base64String: string, mimeType: string): Blob {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], { type: mimeType });
  }

  const fileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <div className="col-lg-12">
      <div>
        {section?.Attribute?.length > 0 ? (
          <Accordion
            hidden={
              section.SN === "RequisitionDetails" ||
              section?.SN == "RequisitionDetails_MRF_Admin"
                ? false
                : hideSectionAndAttribute == false
                ? false
                : true
            }
            elevation={0}
            className="mb-3"
            expanded={accordion}
            onChange={() => setAccordion((prev) => !prev)}
          >
            <AccordionSummary
              id="profile-accordion"
              style={{ background: "#3C5464" }}
              className="text-white acc_close"
              expandIcon={<ExpandMore />}
            >
              <div className="row d-flex align-items-center acc-heading">
                <div className="col-lg-10 col-md-10 col-sm-12">
                  <p style={{ fontSize: "16px" }}>{section.SDN}</p>
                </div>
                <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12">
                  <Tooltip title="clear">
                    <button
                      hidden={
                        section.SN === "ManpowerRequisitionPosition" ||
                        section.SN === "ManpowerRequisitionPosition_MRF_Admin"
                      }
                      className="btn ml-lg-0 ml-md-0 ml-1"
                      disabled={
                        sendback == true
                          ? false
                          : disableAttribute == true
                          ? true
                          : false
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        //clear data
                        section.Attribute.forEach(async (eachAttribute) => {
                          if (eachAttribute.AT === "Attribute") {
                            //change value according to dt

                            setAttributesData((prev) => ({
                              ...prev,
                              [eachAttribute.AC]:
                                eachAttribute.DT === "DropdownSingle" ||
                                eachAttribute.DT === "Date"
                                  ? null
                                  : eachAttribute.DT === "Text" ||
                                    eachAttribute.DT === "Number"
                                  ? ""
                                  : eachAttribute.DT === "Checkbox"
                                  ? false
                                  : "",
                            }));
                          } else if (eachAttribute.AT === "AttributeSet") {
                            setAttributeSetData(ogAttributeSet);
                          }
                        });

                        //clear error
                        setFormErrors({});
                      }}
                    >
                      <i className="fas fa-times-circle"></i>
                    </button>
                  </Tooltip>
                  <Tooltip title="submit">
                    <button
                      className="btn"
                      hidden={
                        section.SN === "ManpowerRequisitionPosition" ||
                        section.SN === "ManpowerRequisitionPosition_MRF_Admin"
                      }
                      disabled={
                        sendback
                          ? false
                          : disableAttribute == true
                          ? true
                          : btnDisable == true
                          ? true
                          : currentGridId !== null
                          ? true
                          : false
                      }
                      onClick={async (e) => {
                        e.stopPropagation();

                        if (TId === 0 && section.SN !== "RequisitionDetails") {
                          notify(1, "Save Requisition Details Data First");
                        } else if (section.SN === "RequisitionDetails") {
                          handleModalSubmit(e);
                        } else {
                          handleModalSubmit(e);
                        }
                      }}
                    >
                      <i className="fa fa-save"></i>
                    </button>
                  </Tooltip>
                </div>
              </div>
            </AccordionSummary>

            <AccordionDetails className="page_heading">
              <div className="row">
                {section.Attribute &&
                  section.Attribute.length > 0 &&
                  section.Attribute.map((eachAttribute, index) => {
                    let disable = false;

                    // if (
                    //   // eachAttribute.AC === "MRREFWH" ||
                    //   eachAttribute.AC === "MRMINSRNG" ||
                    //   eachAttribute.AC === "MRMESARNG" ||
                    //   eachAttribute.AC === "MRMXSARNG"
                    // ) {
                    //   disable = true;
                    // } else
                    if (sendback) {
                      if (
                        section?.SN === "RequisitionDetails" ||
                        section?.SN == "RequisitionDetails_MRF_Admin"
                      ) {
                        if (
                          eachAttribute.AC === "MRPOSID" ||
                          eachAttribute.AC === "MRPOSCON"
                        ) {
                          disable = true;
                        } else {
                          disable = false;
                        }
                      } else {
                        if (
                          disableAttribute ||
                          attributeValidation[eachAttribute.AC]?.isDisable
                        ) {
                          disable = true;
                        } else {
                          disable = false;
                        }
                      }
                    } else {
                      if (
                        disableAttribute ||
                        attributeValidation[eachAttribute.AC]?.isDisable
                      ) {
                        disable = true;
                      } else {
                        disable = false;
                      }
                    }

                    return (
                      <React.Fragment key={`${index}-${eachAttribute.AC}`}>
                        {eachAttribute.AT === "Attribute" ? (
                          <>
                            {eachAttribute.DT === "DropdownSingle" ? (
                              <>
                                <div
                                  hidden={
                                    eachAttribute.AC === "MRPOSID" ||
                                    eachAttribute.AC === "MRPOSCON"
                                      ? false
                                      : hideSectionAndAttribute == false
                                      ? false
                                      : true
                                  }
                                  className={
                                    JSON.parse(
                                      eachAttribute.AD
                                    )?.IFR?.toLowerCase() === "true"
                                      ? "col-lg-12 col-sm-12 col-xs-12 "
                                      : "col-lg-3 col-sm-3 col-xs-4"
                                  }
                                >
                                  <div className="mb-1">
                                    <label className="col-form-label">
                                      {eachAttribute.AN}
                                      {attributeValidation[
                                        eachAttribute.AC
                                      ]?.ISM?.toLowerCase() === "true" && (
                                        <sup>*</sup>
                                      )}
                                    </label>

                                    {section.SN === "MRFJobDescription" && (
                                      <>
                                        {(eachAttribute.AC === "JBLEGEN" ||
                                          eachAttribute.AC === "JBDIVSN") && (
                                          <>
                                            <SelectForm
                                              isClearable
                                              isSearchable
                                              options={
                                                optionsObj[eachAttribute.AC]
                                                  ? optionsObj[eachAttribute.AC]
                                                  : []
                                              }
                                              placeholder={eachAttribute.AN}
                                              isDisabled={disable}
                                              value={
                                                attributesData[eachAttribute.AC]
                                                  ? attributesData[
                                                      eachAttribute.AC
                                                    ]
                                                  : ""
                                              }
                                              onChange={(event) => {
                                                handleChangeEventForDropdown(
                                                  event,
                                                  eachAttribute
                                                );
                                              }}
                                              isMulti={false}
                                              noIndicator={false}
                                              noSeparator={false}
                                            />
                                            {formErrors[eachAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[eachAttribute.AC]}
                                              </p>
                                            )}
                                          </>
                                        )}
                                        {eachAttribute.AC !== "JBLEGEN" &&
                                          eachAttribute.AC !== "JBDIVSN" && (
                                            <>
                                              <p>
                                                {attributesData[
                                                  eachAttribute.AC
                                                ]
                                                  ? attributesData[
                                                      eachAttribute.AC
                                                    ].label
                                                  : ""}
                                              </p>
                                            </>
                                          )}
                                      </>
                                    )}

                                    {section.SN !== "MRFJobDescription" && (
                                      <>
                                        <SelectForm
                                          isClearable
                                          isSearchable
                                          options={
                                            eachAttribute?.AC === "MRFEVENT"
                                              ? optionsObj[
                                                  eachAttribute?.AC
                                                ]?.filter(
                                                  (e) => e.label !== "Re Hire"
                                                )
                                              : optionsObj[eachAttribute.AC]
                                              ? optionsObj[eachAttribute.AC]
                                              : []
                                          }
                                          placeholder={eachAttribute.AN}
                                          isDisabled={disable}
                                          value={
                                            attributesData[eachAttribute.AC]
                                              ? attributesData[eachAttribute.AC]
                                              : ""
                                          }
                                          onChange={(event) => {
                                            handleChangeEventForDropdown(
                                              event,
                                              eachAttribute
                                            );
                                          }}
                                          isMulti={false}
                                          noIndicator={false}
                                          noSeparator={false}
                                        />
                                        {formErrors[eachAttribute.AC] && (
                                          <p style={{ color: "red" }}>
                                            {formErrors[eachAttribute.AC]}
                                          </p>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : eachAttribute.DT === "RichText" ? (
                              <>
                                <div
                                  className={
                                    JSON.parse(
                                      eachAttribute.AD
                                    ).IFR.toLowerCase() === "true"
                                      ? "col-lg-12 col-sm-12 col-xs-12 "
                                      : "col-lg-3 col-sm-3 col-xs-4"
                                  }
                                >
                                  <div className="mb-1">
                                    <label className="col-form-label">
                                      {eachAttribute.AN}
                                      {attributeValidation[
                                        eachAttribute.AC
                                      ]?.ISM.toLowerCase() === "true" && (
                                        <sup>*</sup>
                                      )}
                                    </label>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          attributesData[eachAttribute.AC],
                                      }}
                                    />
                                  </div>
                                </div>
                              </>
                            ) : eachAttribute.DT === "Text" ||
                              eachAttribute.DT === "Number" ? (
                              <>
                                <div
                                  hidden={
                                    eachAttribute.AC === "MRPOSID" ||
                                    eachAttribute.AC === "MRPOSCON"
                                      ? false
                                      : hideSectionAndAttribute == false
                                      ? false
                                      : true
                                  }
                                  className={
                                    JSON.parse(
                                      eachAttribute.AD
                                    )?.IFR?.toLowerCase() === "true"
                                      ? "col-lg-12 col-sm-12 col-xs-12 "
                                      : "col-lg-3 col-sm-3 col-xs-4"
                                  }
                                >
                                  <div className="mb-1">
                                    <label className="col-form-label">
                                      {eachAttribute.AN}
                                      {attributeValidation[
                                        eachAttribute.AC
                                      ]?.ISM?.toLowerCase() === "true" && (
                                        <sup>*</sup>
                                      )}
                                    </label>
                                    {section.SN === "MRFJobDescription" && (
                                      <>
                                        <p>
                                          {attributesData[eachAttribute.AC]
                                            ? attributesData[eachAttribute.AC]
                                            : ""}
                                        </p>
                                      </>
                                    )}
                                    {section.SN !== "MRFJobDescription" && (
                                      <>
                                        <InputForm
                                          className="form-control"
                                          placeholder={eachAttribute.AN}
                                          isDisabled={disable}
                                          textArea={false}
                                          value={
                                            attributesData[eachAttribute.AC]
                                              ? attributesData[eachAttribute.AC]
                                              : ""
                                          }
                                          onChange={(e) => {
                                            setAttributesData((prev) => ({
                                              ...prev,
                                              [eachAttribute.AC]:
                                                e.target.value,
                                            }));

                                            dependentValidations(
                                              e.target.value,
                                              eachAttribute.AC
                                            );
                                          }}
                                          maxLength="255"
                                        />
                                        {formErrors[eachAttribute.AC] && (
                                          <p style={{ color: "red" }}>
                                            {formErrors[eachAttribute.AC]}
                                          </p>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : eachAttribute.DT === "Date" ? (
                              <>
                                <div
                                  hidden={
                                    eachAttribute.AC === "MRPOSID" ||
                                    eachAttribute.AC === "MRPOSCON"
                                      ? false
                                      : hideSectionAndAttribute == false
                                      ? false
                                      : true
                                  }
                                  className={
                                    JSON.parse(
                                      eachAttribute.AD
                                    )?.IFR?.toLowerCase() === "true"
                                      ? "col-lg-12 col-sm-12 col-xs-12 "
                                      : "col-lg-3 col-sm-3 col-xs-4"
                                  }
                                >
                                  <div className="mb-1">
                                    <label className="col-form-label">
                                      {eachAttribute.AN}
                                      {attributeValidation[
                                        eachAttribute.AC
                                      ]?.ISM?.toLowerCase() === "true" && (
                                        <sup>*</sup>
                                      )}
                                    </label>

                                    <DateForm
                                      isDisabled={disable}
                                      value={
                                        attributesData[eachAttribute.AC]
                                          ? attributesData[eachAttribute.AC]
                                          : null
                                      }
                                      onChange={(date) => {
                                        setAttributesData((prev) => ({
                                          ...prev,
                                          [eachAttribute.AC]:
                                            moment(date).format("DD-MMM-YYYY"),
                                        }));

                                        dependentValidations(
                                          moment(date).format("DD-MMM-YYYY"),
                                          eachAttribute.AC
                                        );
                                      }}
                                    />
                                    {formErrors[eachAttribute.AC] && (
                                      <p style={{ color: "red" }}>
                                        {formErrors[eachAttribute.AC]}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : eachAttribute.DT === "Checkbox" ? (
                              <>
                                <div
                                  hidden={
                                    eachAttribute.AC === "MRPOSID" ||
                                    eachAttribute.AC === "MRPOSCON"
                                      ? false
                                      : hideSectionAndAttribute == false
                                      ? false
                                      : true
                                  }
                                  className={
                                    JSON.parse(
                                      eachAttribute.AD
                                    )?.IFR?.toLowerCase() === "true"
                                      ? "col-lg-12 col-sm-12 col-xs-12 "
                                      : "col-lg-3 col-sm-3 col-xs-4"
                                  }
                                >
                                  <div className="mb-1">
                                    <label
                                      htmlFor={eachAttribute.AC}
                                      className="col-form-label"
                                    >
                                      {eachAttribute.AN}
                                      {attributeValidation[
                                        eachAttribute.AC
                                      ]?.ISM?.toLowerCase() === "true" && (
                                        <sup>*</sup>
                                      )}
                                    </label>

                                    <div>
                                      <input
                                        disabled={disable}
                                        type="checkbox"
                                        onChange={(e) => {
                                          setAttributesData((prev) => ({
                                            ...prev,
                                            [eachAttribute.AC]:
                                              e.target.checked,
                                          }));

                                          dependentValidations(
                                            e.target.checked,
                                            eachAttribute.AC
                                          );
                                        }}
                                        id={eachAttribute.AC}
                                        checked={
                                          attributesData[eachAttribute.AC]
                                            ? attributesData[eachAttribute.AC]
                                            : false
                                        }
                                      />
                                    </div>
                                    {formErrors[eachAttribute.AC] && (
                                      <p style={{ color: "red" }}>
                                        {formErrors[eachAttribute.AC]}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : eachAttribute.DT === "Textarea" ? (
                              <>
                                {eachAttribute.AC === "MRJSTFHRNG" ? (
                                  <>
                                    <div
                                      hidden={
                                        eachAttribute.AC === "MRPOSID" ||
                                        eachAttribute.AC === "MRPOSCON"
                                          ? false
                                          : hideSectionAndAttribute == false
                                          ? false
                                          : true
                                      }
                                      className={
                                        JSON.parse(
                                          eachAttribute.AD
                                        )?.IFR?.toLowerCase() === "true"
                                          ? "col-lg-12 col-sm-12 col-xs-12 "
                                          : "col-lg-6 col-sm-6 col-xs-6"
                                      }
                                    >
                                      <div className="mb-1">
                                        <label className="col-form-label">
                                          {eachAttribute.AN}
                                          {attributeValidation[
                                            eachAttribute.AC
                                          ]?.ISM?.toLowerCase() === "true" && (
                                            <sup>*</sup>
                                          )}
                                        </label>

                                        <InputForm
                                          className="form-control"
                                          placeholder={eachAttribute.AN}
                                          isDisabled={disable}
                                          textArea={true}
                                          value={
                                            attributesData[eachAttribute.AC]
                                              ? attributesData[eachAttribute.AC]
                                              : ""
                                          }
                                          rows={6}
                                          onChange={(e) => {
                                            setAttributesData((prev) => ({
                                              ...prev,
                                              [eachAttribute.AC]:
                                                e.target.value,
                                            }));

                                            dependentValidations(
                                              e.target.value,
                                              eachAttribute.AC
                                            );
                                          }}
                                          maxLength="500"
                                        />
                                        {formErrors[eachAttribute.AC] && (
                                          <p style={{ color: "red" }}>
                                            {formErrors[eachAttribute.AC]}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {" "}
                                    <div
                                      hidden={
                                        eachAttribute.AC === "MRPOSID" ||
                                        eachAttribute.AC === "MRPOSCON"
                                          ? false
                                          : hideSectionAndAttribute == false
                                          ? false
                                          : true
                                      }
                                      className={
                                        JSON.parse(
                                          eachAttribute.AD
                                        )?.IFR?.toLowerCase() === "true"
                                          ? "col-lg-12 col-sm-12 col-xs-12 "
                                          : "col-lg-3 col-sm-3 col-xs-4"
                                      }
                                    >
                                      <div className="mb-1">
                                        <label className="col-form-label">
                                          {eachAttribute.AN}
                                          {attributeValidation[
                                            eachAttribute.AC
                                          ]?.ISM?.toLowerCase() === "true" && (
                                            <sup>*</sup>
                                          )}
                                        </label>

                                        <InputForm
                                          className="form-control"
                                          placeholder={eachAttribute.AN}
                                          isDisabled={disable}
                                          textArea={true}
                                          value={
                                            attributesData[eachAttribute.AC]
                                              ? attributesData[eachAttribute.AC]
                                              : ""
                                          }
                                          onChange={(e) => {
                                            setAttributesData((prev) => ({
                                              ...prev,
                                              [eachAttribute.AC]:
                                                e.target.value,
                                            }));

                                            dependentValidations(
                                              e.target.value,
                                              eachAttribute.AC
                                            );
                                          }}
                                          maxLength="255"
                                        />
                                        {formErrors[eachAttribute.AC] && (
                                          <p style={{ color: "red" }}>
                                            {formErrors[eachAttribute.AC]}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            ) : eachAttribute.DT === "Document" ? (
                              <>
                                <div
                                  className={
                                    JSON.parse(
                                      eachAttribute.AD
                                    ).IFR.toLowerCase() === "true"
                                      ? "col-lg-12 col-sm-12 col-xs-12 "
                                      : "col-lg-3 col-sm-3 col-xs-4"
                                  }
                                  hidden={
                                    eachAttribute.AC === "MRPOSID" ||
                                    eachAttribute.AC === "MRPOSCON"
                                      ? false
                                      : hideSectionAndAttribute == false
                                      ? false
                                      : true
                                  }
                                >
                                  <div className="mb-1">
                                    <label className="col-form-label">
                                      {eachAttribute.AN}
                                      {attributeValidation[
                                        eachAttribute.AC
                                      ]?.ISM.toLowerCase() === "true" && (
                                        <sup>*</sup>
                                      )}
                                    </label>

                                    <div className="box position-relative">
                                      <input
                                        id={`files-${index}`}
                                        className="form-control inputfile inputfile-6 multiple-inputfile"
                                        data-multiple-caption="{count} files selected"
                                        multiple={
                                          JSON.parse(eachAttribute.V).FC > 1
                                            ? true
                                            : false
                                        }
                                        ref={FlatDocRef}
                                        accept={JSON.parse(eachAttribute.V).FX}
                                        type="file"
                                        onChange={(e) => {
                                          handleFileChangeForFlat(
                                            e,
                                            eachAttribute
                                          );
                                        }}
                                        disabled={disable}
                                        value={""}
                                      />
                                      <label
                                        htmlFor={`files-${index}`}
                                        className="form-control"
                                        style={{ width: 0, border: "none" }}
                                      >
                                        <strong
                                          style={{
                                            padding: "6px 16px",
                                            backgroundColor: "#3c5464",
                                            borderRadius: "5px",
                                          }}
                                        >
                                          <i
                                            className="fa fa-upload rotate90"
                                            aria-hidden="true"
                                          ></i>
                                          {"  "}
                                          Upload
                                        </strong>{" "}
                                      </label>
                                    </div>
                                    {formErrors[eachAttribute.AC] && (
                                      <p style={{ color: "red" }}>
                                        {formErrors[eachAttribute.AC]}
                                      </p>
                                    )}
                                    <div className="file-added-list">
                                      <ul className="list-unstyle">
                                        {selectedFilesForFlat &&
                                          selectedFilesForFlat.length > 0 &&
                                          selectedFilesForFlat.map(
                                            (fileAttribute, attributeIndex) => {
                                              const fileExtension =
                                                fileAttribute?.Name.split(".")
                                                  .pop()
                                                  .toLowerCase() ?? "";
                                              return (
                                                <React.Fragment key={uuid()}>
                                                  {fileAttribute.AC ===
                                                    eachAttribute.AC && (
                                                    <li
                                                      className="list mt-1"
                                                      key={attributeIndex}
                                                    >
                                                      <div className="media">
                                                        <div className="media-body text-truncate">
                                                          <span className="view-more">
                                                            {fileAttribute.Name}
                                                          </span>
                                                        </div>
                                                        {/*Here code for view same div of trash should be used. ISDOCVIEW/ISDOCDOWN*/}
                                                        {/* {
                                                        // section?.ISDOCVIEW &&
                                                        fileAttribute.DocId !==
                                                          0 && (
                                                          <div className="media-right ml-2">
                                                            <i
                                                              className="fa fa-eye"
                                                              aria-hidden="true"
                                                              onClick={() =>
                                                                handleViewForFlat(
                                                                  fileAttribute,
                                                                  attributeIndex
                                                                )
                                                              }></i>
                                                          </div>
                                                        )
                                                      } */}
                                                        {
                                                          //  section?.ISDOCDOWN &&

                                                          <>
                                                            {fileExtension ==
                                                              "pdf" && (
                                                              <div className="media-right ml-2">
                                                                <i
                                                                  className="fa fa-eye"
                                                                  aria-hidden="true"
                                                                  onClick={() =>
                                                                    handleFileView(
                                                                      {
                                                                        header:
                                                                          eachAttribute.AN,
                                                                        fileAttribute:
                                                                          fileAttribute,
                                                                      }
                                                                    )
                                                                  }
                                                                ></i>
                                                              </div>
                                                            )}
                                                            <div className="media-right ml-2">
                                                              <i
                                                                className="fa-solid fa-download"
                                                                aria-hidden="true"
                                                                onClick={() =>
                                                                  handleDownloadFileFlat(
                                                                    fileAttribute,
                                                                    attributeIndex
                                                                  )
                                                                }
                                                              ></i>
                                                            </div>
                                                          </>
                                                        }
                                                        {section?.ISEDIT &&
                                                          disable == false && (
                                                            <div className="media-right ml-2">
                                                              <i
                                                                className="fa fa-trash"
                                                                aria-hidden="true"
                                                                onClick={() =>
                                                                  handleDeleteFileForFlat(
                                                                    fileAttribute,
                                                                    attributeIndex
                                                                  )
                                                                }
                                                              ></i>
                                                            </div>
                                                          )}
                                                      </div>
                                                    </li>
                                                  )}
                                                </React.Fragment>
                                              );
                                            }
                                          )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : eachAttribute.DT ===
                              "SearchableDropdownSingle" ? (
                              <>
                                {section.SN === "RequisitionDetails" ||
                                section?.SN ==
                                  "RequisitionDetails_MRF_Admin" ? (
                                  <>
                                    {((eachAttribute.AC !== "MRREFWH" &&
                                      attributesData["MREVRE"] !== undefined &&
                                      attributesData["MREVRE"]?.code !==
                                        "REP") ||
                                      (attributesData["MREVRE"] !== undefined &&
                                        attributesData["MREVRE"]?.code ===
                                          "REP")) && (
                                      <div
                                        hidden={
                                          eachAttribute.AC === "MRPOSID" ||
                                          eachAttribute.AC === "MRPOSCON"
                                            ? false
                                            : hideSectionAndAttribute == false
                                            ? false
                                            : true
                                        }
                                        className={
                                          JSON.parse(
                                            eachAttribute.AD
                                          )?.IFR?.toLowerCase() === "true"
                                            ? "col-lg-12 col-sm-12 col-xs-12 "
                                            : "col-lg-3 col-sm-3 col-xs-4"
                                        }
                                      >
                                        <div className="mb-1">
                                          <label className="col-form-label">
                                            {eachAttribute.AN}
                                            {attributeValidation[
                                              eachAttribute.AC
                                            ]?.ISM?.toLowerCase() ===
                                              "true" && <sup>*</sup>}
                                          </label>

                                          <SelectForm
                                            isClearable
                                            isSearchable
                                            async
                                            options={
                                              section.SDN ===
                                                "Requisition Details" &&
                                              eachAttribute.AC === "MRPOSID"
                                                ? (searchString, cb) =>
                                                    getSearchableDdForMrf(
                                                      searchString,
                                                      eachAttribute.AC,
                                                      cb,
                                                      "",
                                                      "attribute",
                                                      0
                                                    )
                                                : eachAttribute?.AC == "MRREFWH"
                                                ? (searchString, cb) =>
                                                    GetEmployeeBasedOnCode(
                                                      searchString,
                                                      cb
                                                    )
                                                : (searchString, cb) =>
                                                    getSearchableDropdownAPI(
                                                      searchString,
                                                      eachAttribute.AC,
                                                      cb,
                                                      "",
                                                      "attribute",
                                                      0
                                                    )
                                            }
                                            placeholder={eachAttribute.AN}
                                            isDisabled={
                                              eachAttribute.AC === "MRPOSID" &&
                                              hideSectionAndAttribute === false
                                                ? true
                                                : eachAttribute.AC ==
                                                    "MRREFWH" &&
                                                  attributesData["MREVRE"]
                                                    ?.code != "REP"
                                                ? true
                                                : disable
                                            }
                                            value={
                                              attributesData[eachAttribute.AC]
                                                ? attributesData[
                                                    eachAttribute.AC
                                                  ]
                                                : null
                                            }
                                            onChange={(event) => {
                                              handleChangeEventForSearchableDropdown(
                                                event,
                                                eachAttribute
                                              );
                                            }}
                                            isMulti={false}
                                            noIndicator={false}
                                            noSeparator={false}
                                          />
                                          {formErrors[eachAttribute.AC] && (
                                            <p style={{ color: "red" }}>
                                              {formErrors[eachAttribute.AC]}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <div
                                      hidden={
                                        eachAttribute.AC === "MRPOSID" ||
                                        eachAttribute.AC === "MRPOSCON"
                                          ? false
                                          : hideSectionAndAttribute == false
                                          ? false
                                          : true
                                      }
                                      className={
                                        JSON.parse(
                                          eachAttribute.AD
                                        )?.IFR?.toLowerCase() === "true"
                                          ? "col-lg-12 col-sm-12 col-xs-12 "
                                          : "col-lg-3 col-sm-3 col-xs-4"
                                      }
                                    >
                                      <div className="mb-1">
                                        <label className="col-form-label">
                                          {eachAttribute.AN}
                                          {attributeValidation[
                                            eachAttribute.AC
                                          ]?.ISM?.toLowerCase() === "true" && (
                                            <sup>*</sup>
                                          )}
                                        </label>

                                        <SelectForm
                                          isClearable
                                          isSearchable
                                          async
                                          options={
                                            section.SDN ===
                                              "Requisition Details" &&
                                            eachAttribute.AC === "MRPOSID"
                                              ? (searchString, cb) =>
                                                  getSearchableDdForMrf(
                                                    searchString,
                                                    eachAttribute.AC,
                                                    cb,
                                                    "",
                                                    "attribute",
                                                    0
                                                  )
                                              : eachAttribute?.AC == "MRREFWH"
                                              ? (searchString, cb) =>
                                                  GetEmployeeBasedOnCode(
                                                    searchString,
                                                    cb
                                                  )
                                              : (searchString, cb) =>
                                                  getSearchableDropdownAPI(
                                                    searchString,
                                                    eachAttribute.AC,
                                                    cb,
                                                    "",
                                                    "attribute",
                                                    0
                                                  )
                                          }
                                          placeholder={eachAttribute.AN}
                                          isDisabled={
                                            eachAttribute.AC === "MRPOSID" &&
                                            hideSectionAndAttribute === false
                                              ? true
                                              : eachAttribute.AC == "MRREFWH" &&
                                                attributesData["MREVRE"]
                                                  ?.code != "REP"
                                              ? true
                                              : disable
                                          }
                                          value={
                                            attributesData[eachAttribute.AC]
                                              ? attributesData[eachAttribute.AC]
                                              : null
                                          }
                                          onChange={(event) => {
                                            handleChangeEventForSearchableDropdown(
                                              event,
                                              eachAttribute
                                            );
                                          }}
                                          isMulti={false}
                                          noIndicator={false}
                                          noSeparator={false}
                                        />
                                        {formErrors[eachAttribute.AC] && (
                                          <p style={{ color: "red" }}>
                                            {formErrors[eachAttribute.AC]}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </>
                                )}

                                {/* hide replacement for whom */}
                              </>
                            ) : (
                              <></>
                            )}

                            {Number(TId) > 0 &&
                              section?.SN == "RequisitionDetails" &&
                              index == section?.Attribute?.length - 1 && (
                                <>
                                  <div
                                    hidden={
                                      eachAttribute.AC === "MRPOSID" ||
                                      eachAttribute.AC === "MRPOSCON"
                                        ? false
                                        : hideSectionAndAttribute == false
                                        ? false
                                        : true
                                    }
                                    className={
                                      JSON.parse(
                                        eachAttribute.AD
                                      )?.IFR?.toLowerCase() === "true"
                                        ? "col-lg-12 col-sm-12 col-xs-12 "
                                        : "col-lg-3 col-sm-3 col-xs-4"
                                    }
                                  >
                                    <div className="mb-1">
                                      <label className="col-form-label">
                                        &nbsp;
                                      </label>

                                      <div className="">
                                        <button
                                          className="btn btn-primary"
                                          onClick={() => {
                                            if (!isEmpty(jobRedirectionUrl)) {
                                              window.open(jobRedirectionUrl);
                                            }
                                          }}
                                        >
                                          WeHire Bot
                                        </button>
                                      </div>

                                      {formErrors[eachAttribute.AC] && (
                                        <p style={{ color: "red" }}></p>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                          </>
                        ) : eachAttribute.AT === "AttributeSet" &&
                          eachAttribute.DT === "Custom" ? (
                          <div className="col-lg-12">
                            <div className="row">
                              {eachAttribute.Attribute.length > 0 &&
                                eachAttribute.Attribute.map(
                                  (subAttribute, index) => (
                                    <React.Fragment
                                      key={`${index}-${subAttribute.AC}`}
                                    >
                                      {subAttribute.DT === "DropdownSingle" ? (
                                        <>
                                          <div
                                            className={
                                              JSON.parse(
                                                subAttribute.AD
                                              )?.IFR?.toLowerCase() === "true"
                                                ? "col-lg-12 col-sm-12 col-xs-12 "
                                                : "col-lg-3 col-sm-3 col-xs-4"
                                            }
                                          >
                                            <div className="mb-1">
                                              <label className="col-form-label">
                                                {subAttribute.AN}
                                                {JSON.parse(
                                                  subAttribute.V
                                                )?.ISM?.toLowerCase() ===
                                                  "true" && <sup>*</sup>}
                                              </label>

                                              <SelectForm
                                                isClearable
                                                isSearchable
                                                options={
                                                  optionsObj[subAttribute.AC]
                                                    ? optionsObj[
                                                        subAttribute.AC
                                                      ]
                                                    : []
                                                }
                                                placeholder={subAttribute.AN}
                                                isDisabled={disable}
                                                value={
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ]
                                                    ? attributeSetData[
                                                        subAttribute.AC
                                                      ]
                                                    : null
                                                }
                                                onChange={(event) => {
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      [subAttribute.AC]: event,
                                                    })
                                                  );

                                                  if (
                                                    event !== null &&
                                                    subAttribute.CC !== null &&
                                                    subAttribute.CC !== ""
                                                  ) {
                                                    const CC =
                                                      subAttribute.CC.split(
                                                        ","
                                                      );

                                                    CC.forEach(
                                                      async (childDropdown) => {
                                                        setAttributeSetData(
                                                          (prev) => ({
                                                            ...prev,
                                                            [childDropdown]:
                                                              null,
                                                          })
                                                        );

                                                        const options =
                                                          await getDropdownsData(
                                                            childDropdown,
                                                            event.value
                                                          );

                                                        setOptionsObj(
                                                          (prev) => ({
                                                            ...prev,
                                                            [childDropdown]:
                                                              options,
                                                          })
                                                        );
                                                      }
                                                    );
                                                  }
                                                }}
                                                isMulti={false}
                                                noIndicator={false}
                                                noSeparator={false}
                                              />
                                              {formErrors[subAttribute.AC] && (
                                                <p style={{ color: "red" }}>
                                                  {formErrors[subAttribute.AC]}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ) : subAttribute.DT === "Text" ||
                                        subAttribute.DT === "Number" ? (
                                        <>
                                          <div
                                            className={
                                              JSON.parse(
                                                subAttribute.AD
                                              )?.IFR?.toLowerCase() === "true"
                                                ? "col-lg-12 col-sm-12 col-xs-12 "
                                                : "col-lg-3 col-sm-3 col-xs-4"
                                            }
                                          >
                                            <div className="mb-1">
                                              <label className="col-form-label">
                                                {subAttribute.AN}
                                                {JSON.parse(
                                                  subAttribute.V
                                                )?.ISM?.toLowerCase() ===
                                                  "true" && <sup>*</sup>}
                                              </label>

                                              <InputForm
                                                className="form-control"
                                                placeholder={subAttribute.AN}
                                                isDisabled={disable}
                                                textArea={false}
                                                value={
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ]
                                                    ? attributeSetData[
                                                        subAttribute.AC
                                                      ]
                                                    : ""
                                                }
                                                onChange={(e) => {
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      [subAttribute.AC]:
                                                        e.target.value,
                                                    })
                                                  );
                                                }}
                                                maxLength="255"
                                              />
                                              {formErrors[subAttribute.AC] && (
                                                <p style={{ color: "red" }}>
                                                  {formErrors[subAttribute.AC]}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ) : subAttribute.DT === "Date" ? (
                                        <>
                                          <div
                                            className={
                                              JSON.parse(
                                                subAttribute.AD
                                              )?.IFR?.toLowerCase() === "true"
                                                ? "col-lg-12 col-sm-12 col-xs-12 "
                                                : "col-lg-3 col-sm-3 col-xs-4"
                                            }
                                          >
                                            <div className="mb-1">
                                              <label className="col-form-label">
                                                {subAttribute.AN}
                                                {JSON.parse(
                                                  subAttribute.V
                                                )?.ISM?.toLowerCase() ===
                                                  "true" && <sup>*</sup>}
                                              </label>

                                              <DateForm
                                                isDisabled={disable}
                                                value={
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ]
                                                    ? attributeSetData[
                                                        subAttribute.AC
                                                      ]
                                                    : null
                                                }
                                                onChange={(date) => {
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      [subAttribute.AC]:
                                                        moment(date).format(
                                                          "DD-MMM-YYYY"
                                                        ),
                                                    })
                                                  );
                                                }}
                                              />
                                              {formErrors[subAttribute.AC] && (
                                                <p style={{ color: "red" }}>
                                                  {formErrors[subAttribute.AC]}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ) : subAttribute.DT === "Checkbox" ? (
                                        <>
                                          <div
                                            className={
                                              JSON.parse(
                                                subAttribute.AD
                                              )?.IFR?.toLowerCase() === "true"
                                                ? "col-lg-12 col-sm-12 col-xs-12 "
                                                : "col-lg-3 col-sm-3 col-xs-4"
                                            }
                                          >
                                            <div className="mb-1">
                                              <label
                                                htmlFor={subAttribute.AC}
                                                className="col-form-label"
                                              >
                                                {subAttribute.AN}
                                                {JSON.parse(
                                                  subAttribute.V
                                                )?.ISM?.toLowerCase() ===
                                                  "true" && <sup>*</sup>}
                                              </label>

                                              <div>
                                                <input
                                                  disabled={disable}
                                                  type="checkbox"
                                                  onChange={(e) => {
                                                    setAttributeSetData(
                                                      (prev) => ({
                                                        ...prev,
                                                        [subAttribute.AC]:
                                                          e.target.checked,
                                                      })
                                                    );
                                                  }}
                                                  id={subAttribute.AC}
                                                  checked={
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                      ? attributeSetData[
                                                          subAttribute.AC
                                                        ]
                                                      : false
                                                  }
                                                />
                                              </div>
                                              {formErrors[subAttribute.AC] && (
                                                <p style={{ color: "red" }}>
                                                  {formErrors[subAttribute.AC]}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ) : subAttribute.DT ===
                                        "SearchableDropdownSingle" ? (
                                        <>
                                          <div
                                            className={
                                              JSON.parse(
                                                subAttribute.AD
                                              ).IFR.toLowerCase() === "true"
                                                ? "col-lg-12 col-sm-12 col-xs-12 "
                                                : "col-lg-3 col-sm-3 col-xs-4"
                                            }
                                          >
                                            <div className="mb-1">
                                              <label className="col-form-label">
                                                {subAttribute.AN}
                                                {JSON.parse(
                                                  subAttribute.V
                                                ).ISM.toLowerCase() ===
                                                  "true" && <sup>*</sup>}
                                              </label>

                                              <SelectForm
                                                isClearable
                                                isSearchable
                                                async
                                                options={(searchString, cb) =>
                                                  getSearchableDropdownAPI(
                                                    searchString,
                                                    subAttribute.AC,
                                                    cb,
                                                    "",
                                                    "attributeSet",
                                                    0
                                                  )
                                                }
                                                placeholder={subAttribute.AN}
                                                isDisabled={disable}
                                                value={
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ]
                                                    ? attributeSetData[
                                                        subAttribute.AC
                                                      ]
                                                    : null
                                                }
                                                onChange={(event) => {
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      [subAttribute.AC]: event,
                                                    })
                                                  );

                                                  if (
                                                    event !== null &&
                                                    subAttribute.CC !== null &&
                                                    subAttribute.CC !== ""
                                                  ) {
                                                    const CC =
                                                      subAttribute.CC.split(
                                                        ","
                                                      );

                                                    CC.forEach(
                                                      async (childDropdown) => {
                                                        setAttributeSetData(
                                                          (prev) => ({
                                                            ...prev,
                                                            [childDropdown]:
                                                              null,
                                                          })
                                                        );

                                                        const options =
                                                          await getDropdownsData(
                                                            childDropdown,
                                                            event.value
                                                          );

                                                        setOptionsObj(
                                                          (prev) => ({
                                                            ...prev,
                                                            [childDropdown]:
                                                              options,
                                                          })
                                                        );
                                                      }
                                                    );
                                                  }

                                                  if (event) {
                                                    searchableDDValuesSet(
                                                      event,
                                                      subAttribute.AC
                                                    );
                                                  }
                                                }}
                                                isMulti={false}
                                                noIndicator={false}
                                                noSeparator={false}
                                              />
                                              {formErrors[subAttribute.AC] && (
                                                <p style={{ color: "red" }}>
                                                  {formErrors[subAttribute.AC]}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </React.Fragment>
                                  )
                                )}
                            </div>

                            <div className="row">
                              <div className="col-lg-3 mt-2 mb-3">
                                <button
                                  id={currentGridId === null ? "0" : "1"}
                                  className="btn btn-primary"
                                  onClick={async (e) => {
                                    const targetId = e.currentTarget.id;

                                    if (targetId === "0") {
                                      const uniqueId = uuid();

                                      const isError = await validateData(
                                        eachAttribute.Attribute
                                      );

                                      if (!isError) {
                                        setOgFilledAttributeSet((prev) => [
                                          ...prev,
                                          {
                                            ...attributeSetData,
                                            Id: uniqueId,
                                          },
                                        ]);

                                        let gridData = {};

                                        eachAttribute.Attribute.forEach(
                                          async (subAttribute) => {
                                            if (
                                              typeof attributeSetData[
                                                subAttribute.AC
                                              ] === "object"
                                            ) {
                                              gridData = {
                                                ...gridData,
                                                [subAttribute.AC]:
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ]?.label || null, //for date and dropdown
                                              };
                                            } else if (
                                              typeof attributeSetData[
                                                subAttribute.AC
                                              ] === "boolean"
                                            ) {
                                              gridData = {
                                                ...gridData,
                                                [subAttribute.AC]:
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ] === true
                                                    ? "true"
                                                    : "false",
                                              };
                                            } else {
                                              gridData = {
                                                ...gridData,
                                                [subAttribute.AC]:
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ],
                                              };
                                            }
                                          }
                                        );

                                        //reset
                                        setAttributeSetData((prev) => ({
                                          ...prev,
                                          ...ogAttributeSet,
                                        }));

                                        setFormErrors({});

                                        setGridData((prev) => [
                                          ...prev,
                                          {
                                            Id: uniqueId,
                                            ...gridData,
                                            IsActive: true,
                                          },
                                        ]);

                                        setDisplayGrid((prev) => [
                                          ...prev,
                                          {
                                            Id: uniqueId,
                                            ...gridData,
                                            IsActive: true,
                                          },
                                        ]);
                                      }
                                    } else {
                                      //update

                                      //validation for empty data before updating in grid

                                      const isError = await validateData(
                                        eachAttribute.Attribute
                                      );

                                      if (!isError) {
                                        //update

                                        setOgFilledAttributeSet((prev) => {
                                          const newState = prev.map(
                                            (record) => {
                                              if (record.Id === currentGridId) {
                                                return { ...attributeSetData };
                                              }
                                              return record;
                                            }
                                          );
                                          return newState;
                                        });

                                        let gridData = {};

                                        eachAttribute.Attribute.forEach(
                                          async (subAttribute) => {
                                            if (
                                              typeof attributeSetData[
                                                subAttribute.AC
                                              ] === "object"
                                            ) {
                                              gridData = {
                                                ...gridData,
                                                [subAttribute.AC]:
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ]?.label || null, //for date and dropdown
                                              };
                                            } else if (
                                              typeof attributeSetData[
                                                subAttribute.AC
                                              ] === "boolean"
                                            ) {
                                              gridData = {
                                                ...gridData,
                                                [subAttribute.AC]:
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ] === true
                                                    ? "true"
                                                    : "false",
                                              };
                                            } else {
                                              gridData = {
                                                ...gridData,
                                                [subAttribute.AC]:
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ],
                                              };
                                            }
                                          }
                                        );

                                        //reset
                                        setAttributeSetData((prev) => ({
                                          ...prev,
                                          ...ogAttributeSet,
                                        }));

                                        setFormErrors({});

                                        setGridData((prev) => {
                                          const newState = prev.map(
                                            (record) => {
                                              if (record.Id === currentGridId) {
                                                return {
                                                  Id: currentGridId,
                                                  ...gridData,
                                                };
                                              }
                                              return record;
                                            }
                                          );
                                          return newState;
                                        });

                                        setDisplayGrid((prev) => {
                                          const newState = prev.map(
                                            (record) => {
                                              if (record.Id === currentGridId) {
                                                return {
                                                  Id: currentGridId,
                                                  ...gridData,
                                                };
                                              }
                                              return record;
                                            }
                                          );
                                          return newState;
                                        });

                                        setCurrentGridId(null);
                                      }
                                    }
                                  }}
                                >
                                  <i
                                    className={
                                      currentGridId === null
                                        ? "fa fa-plus"
                                        : "fas fa-edit"
                                    }
                                  ></i>{" "}
                                  {currentGridId === null
                                    ? "Save"
                                    : "Update Record"}
                                </button>
                              </div>

                              <div className="col-lg-12 p-0 mb-3">
                                <DynamicGrid
                                  options={gridOptions}
                                  data={displayGrid}
                                  columns={gridColumns}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </React.Fragment>
                    );
                  })}
              </div>
            </AccordionDetails>
          </Accordion>
        ) : null}
      </div>
      <React.Fragment>
        {index == lastIndex &&
          (String(activetab).toLowerCase().trim() == "requisition" ||
            String(activetab).toLowerCase().trim() ==
              "requisition_mrf_admin") &&
          !hideSectionAndAttribute && (
            <>
              <WorkflowSection
                TId={TId}
                accordion={workflowAccordian}
                setAccordion={setWorkflowAccordian}
                positionIdDetails={positionIdDetails}
                hideSectionAndAttribute={hideSectionAndAttribute}
                disableAttribute={disableAttribute}
                setDisableAttribute={setDisableAttribute}
                isScreeningSubmitted={isScreeningSubmitted}
              />
            </>
          )}
        <DocumentViewer
          modal={fileViewModalPopup}
          setModal={setFileViewModalPopup}
          header={fileHeader}
          handleClose={handleCloseDocView}
          base64={docViewBase64}
        />
      </React.Fragment>
    </div>
  );
};
export default SectionComponent;

const DocumentViewer = ({
  modal,
  setModal,
  header,
  base64 = "",
  handleClose,
}) => {
  return (
    <>
      <Modal
        show={modal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">{header}</div>
        </div>
        <Modal.Body>
          <div
            className=""
            style={{
              height: "60vh",
              overflowX: "hidden",
            }}
          >
            <RCMPdfViewer pdfURL={base64 || ""} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn"
            style={{
              marginLeft: 5,
              color: "black !important",
              borderColor: "#acacac",
            }}
            onClick={handleClose}
          >
            Ok
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
