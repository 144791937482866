import React, { useContext, useEffect, useState } from "react";
import { LoaderContext } from "../../Helpers/Context/Context";
import { useLocation, useNavigate } from "react-router-dom";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import {
  Collapse,
  Tabs,
  ThemeProvider,
  Tooltip,
  createTheme,
} from "@mui/material";
import InputForm from "../../Components/InputForm/InputForm";
import SelectForm from "../../Components/SelectForm/SelectForm";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import notify from "../../Helpers/ToastNotification";
import {
  getAssessmentStartMessage,
  getUserAssessmentDashboardFilter,
  getUserAssessments,
  manageUserAssessment,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import { APICall } from "../../Helpers/APICalls";
import { Button, Modal } from "react-bootstrap";

const UserAssessmentsDashboard = () => {
  const [open, setOpen] = useState(false);

  const { showLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  let { userDetails, currentRoleId }: any = useUserContext();
  const { state } = useLocation();
  const [activetabIndex, setActivetabIndex] = useState<number>(0);

  const [activetab, setActivetab] = useState<string>("Pending Assessment");
  const tabs = ["Pending Assessment", "Completed Assessment"];

  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [start, setStart] = useState(0);
  const [sortDirection, setSortDirection] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [searchText, setSearchText] = useState("");
  const [rowData, setRowData] = useState([]);
  const [reSet, setReSet] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [assessmentId, setAssessmentId] = useState(null);
  const [userAssessmentId, setUserAssessmentId] = useState(null);
  const [assessmentMessageData, setAssessmentMessageData] = useState(null);
  const [reAttempt, setReAttempt] = useState(false);

  const [assessmentDropdownList, setAssessmentDropdownList] = useState([]);

  const [selectedAssessmentIds, setSelectedAssessmentIds] = useState([]);

  const [courseDropdownList, setCourseDropdownList] = useState([]);

  const [selectedCourseIds, setSelectedCourseIds] = useState([]);

  const [programDropdownList, setProgramDropdownList] = useState([]);

  const [selectedProgramIds, setSelectedProgramIds] = useState([]);

  const [passingStatusDropdownList, setPassingStatusDropdownList] = useState([
    { value: 1, label: "Pass" },
    { value: 0, label: "Fail" },
  ]);

  const [selectedPassingStatus, setSelectedPassingStatus] = useState(null);

  const [userAssessmentIds, setUserAssessmentIds] = useState([]);
  const [userAttempts, setUserAttempts] = useState([]);
  const [viewModal, setViewModal] = useState(false);

  useEffect(() => {
    if (!viewModal) {
      setUserAssessmentIds([]);
      setUserAttempts([]);
    }
  }, [viewModal]);

  const clearFilters = () => {
    setSelectedAssessmentIds([]);
    setSelectedCourseIds([]);
    setSelectedProgramIds([]);
    setSelectedPassingStatus(null);
  };

  const clearModalSelection = () => {
    setMessageModal(false);
    setAssessmentId(null);
    setUserAssessmentId(null);
    setAssessmentMessageData(null);
    setReAttempt(false);
    setUserAssessmentIds([]);
    setUserAttempts([]);
  };

  useEffect(() => {
    if (state && state.from !== null && state.from !== undefined)
      setActivetab(state.from);
  }, [state]);

  useEffect(() => {
    clearFilters();
    clearModalSelection();
    setReSet(!reSet);
  }, [open, activetab]);

  useEffect(() => {
    const getDropdownData = async (mode) => {
      showLoader();
      const res = await APICall(getUserAssessmentDashboardFilter, "POST", {
        Mode: mode,
        UserId: userDetails.Id,
        GetStatus: activetab === "Pending Assessment" ? 0 : 1,
      });

      if (res.status === 0 && res.data !== null) {
        switch (mode) {
          case "ASSESSMENTNAME":
            setAssessmentDropdownList(res.data);
            break;
          case "COURSE":
            setCourseDropdownList(res.data);
            break;

          case "PROGRAM":
            setProgramDropdownList(res.data);
            break;
        }
      }
      hideLoader();
    };

    getDropdownData("ASSESSMENTNAME");
    getDropdownData("COURSE");
    getDropdownData("PROGRAM");
  }, [activetab]);

  useEffect(() => {
    if (!messageModal) {
      setAssessmentId(null);
      setUserAssessmentId(null);
      setAssessmentMessageData(null);
      setReAttempt(false);
    }
  }, [messageModal]);

  const comma = (data) => {
    return data.length > 0 ? data.map((s) => s.value).join(",") : null;
  };

  const getUserAssessmentDashboardData = async () => {
    showLoader();
    const res = await APICall(getUserAssessments, "POST", {
      PageNumber: start,
      PageSize: pageSize,
      SortColumn: sortColumn,
      SortOrder: sortDirection,
      CourseIds: comma(selectedCourseIds),
      JourneyIds: comma(selectedProgramIds),
      AssessmentIds: comma(selectedAssessmentIds),
      SearchText: searchText,
      PassingStatus: selectedPassingStatus ? selectedPassingStatus.value : null,
      GetStatus: activetab === "Pending Assessment" ? 0 : 1,
      UserId: userDetails.Id,
      RoleId: currentRoleId.value,
    });

    if (res.status === 0 && res.data !== null && res.data.length > 0) {
      setRowData(res.data);
      setCount(res.data[0].totalCount);
    } else {
      setCount(0);
      setRowData([]);
    }
    hideLoader();
  };

  useEffect(() => {
    getUserAssessmentDashboardData();
  }, [reSet, searchText, start, sortColumn, sortDirection]);

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    page: page,
    rowsPerPage: pageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      setPage(page);
      setStart(page * pageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const getAssessmentMessage = async (assessmentId, courseScheduleId) => {
    showLoader();
    const res = await APICall(getAssessmentStartMessage, "POST", {
      AssessmentId: assessmentId,
      UserId: userDetails.Id,
      CourseScheduleId: courseScheduleId,
    });

    if (res.status === 0 && res.data !== null) {
      setAssessmentMessageData(res.data);
    } else {
      console.log("startMessage error", res);
      setMessageModal(false);
      setAssessmentId(null);
      setUserAssessmentId(null);
    }
    hideLoader();
  };

  const gridColumnsPending = [
    {
      name: "rowNum",
      label: "Id",
      options: {
        display: false,
      },
    },
    {
      name: "course",
      label: "course Name",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "journey",
      label: "Journey Name",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "assessmentName",
      label: "Assessment Name",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "assessmentType",
      label: "Assessment Type",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },

    {
      name: "passingScore",
      label: "Passing Marks",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },
    {
      name: "totalMarks",
      label: "Total Marks",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },
    {
      name: "assignedOn",
      label: "Assigned On",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },

    {
      name: "rowNum",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta) => {
          let assessmentId =
            tableMeta.tableData[tableMeta.rowIndex].assessmentId;
          let userAssessmentId =
            tableMeta.tableData[tableMeta.rowIndex].userAssessmentId.split(",");

          let courseScheduleId =
            tableMeta.tableData[tableMeta.rowIndex].courseScheduleId;

          return (
            <div style={{ width: "100px" }}>
              <Tooltip title="Take assessment">
                <a
                  className="mr-2"
                  style={{ fontSize: "15px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    setMessageModal(true);
                    setAssessmentId(assessmentId);
                    setUserAssessmentId(userAssessmentId[0]);
                    getAssessmentMessage(assessmentId, courseScheduleId);
                  }}>
                  <i className="fas fa-eye"></i>
                </a>
              </Tooltip>
            </div>
          );
        },
      },
    },
  ];

  const gridColumnsCompleted = [
    {
      name: "rowNum",
      label: "Id",
      options: {
        display: false,
      },
    },
    {
      name: "course",
      label: "course Name",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "journey",
      label: "Journey Name",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "assessmentName",
      label: "Assessment Name",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "assessmentType",
      label: "Assessment Type",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },

    {
      name: "passingScore",
      label: "Passing Marks",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },
    {
      name: "noOfAttempts",
      label: "Attempts",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },

    {
      name: "score",
      label: "Score",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },

    {
      name: "percentage",
      label: "Percentage",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },

    {
      name: "totalMarks",
      label: "Total Marks",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },

    {
      name: "passingStatus",
      label: "Status",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },

    {
      name: "assignedOn",
      label: "Assigned On",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },

    {
      name: "rowNum",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta) => {
          let assessmentId =
            tableMeta.tableData[tableMeta.rowIndex].assessmentId;

          let userAssessmentId =
            tableMeta.tableData[tableMeta.rowIndex].userAssessmentId.split(",");

          let userAttempts =
            tableMeta.tableData[tableMeta.rowIndex].userAttempts.split(",");

          let canAttempt = tableMeta.tableData[tableMeta.rowIndex].canAttempt;

          let courseScheduleId =
            tableMeta.tableData[tableMeta.rowIndex].courseScheduleId;

          return (
            <div style={{ width: "100px" }}>
              <Tooltip title="View">
                <a
                  className="mr-2"
                  style={{ fontSize: "15px" }}
                  onClick={(e) => {
                    e.preventDefault();

                    debugger;
                    if (userAssessmentId.length === 1) {
                      navigate("/assessment", {
                        state: {
                          assessmentId: assessmentId,
                          userAssessmentId: userAssessmentId[0],
                          preview: true,
                          isUser: true,
                          from: activetab,
                        },
                      });
                    } else {
                      setAssessmentId(assessmentId);
                      setUserAssessmentIds(userAssessmentId);
                      setUserAttempts(userAttempts);
                      setViewModal(true);
                    }
                  }}>
                  <i className="fas fa-eye"></i>
                </a>
              </Tooltip>

              {canAttempt && (
                <Tooltip title="Re-Attempt">
                  <a
                    className="mr-2"
                    style={{ fontSize: "15px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setMessageModal(true);
                      setAssessmentId(assessmentId);
                      setUserAssessmentId(userAssessmentId[0]);
                      getAssessmentMessage(assessmentId, courseScheduleId);
                      setReAttempt(true);
                    }}>
                    <i className="fa-solid fa-book-open-reader"></i>
                  </a>
                </Tooltip>
              )}
            </div>
          );
        },
      },
    },
  ];

  const defaultTheme = createTheme({});
  const { breakpoints } = defaultTheme;
  const tabsTheme = {
    ...defaultTheme,
    components: {
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: "0px !important",
            marginLeft: "-42px",
            [breakpoints.down("md")]: {
              minHeight: "0px !important",
              marginLeft: "0px",
            },
          },
        },
      },
    },
  };
  return (
    <>
      <div className="breadcrumb modified-bredcrumb">
        <span>
          <ul>
            <li>
              <a href="/LMSHome">Home</a>
            </li>
            <li>User Assessments</li>
          </ul>
        </span>
      </div>
      <div className="container-fluid">
        <div className="d-flex align-items-center justify-content-between">
          {/* Tabs Are here */}
          <div className="col-lg-10 col-md-12 col-sm-12 ">
            <ThemeProvider theme={tabsTheme}>
              <Tabs
                value={activetabIndex}
                className="profile-tabs"
                onChange={() => {}}
                variant="scrollable"
                TabScrollButtonProps={{
                  style: {
                    color: "black",
                  },
                }}
                scrollButtons
                TabIndicatorProps={{
                  style: { display: "none" },
                }}
                allowScrollButtonsMobile
                aria-label="scrollable tabs">
                <div className="tabBtn mt-2">
                  <ul>
                    {tabs.map((tabName, index) => (
                      <li key={index}>
                        <button
                          onClick={() => {
                            setStart(0); //imp
                            setPage(0); //imp
                            setActivetab(tabName);
                          }}
                          className={
                            tabName === activetab
                              ? "bttn active"
                              : "bttn border border-primary border-bottom-0"
                          }>
                          {tabName}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </Tabs>
            </ThemeProvider>
          </div>
        </div>

        <div className="grid-wrapper grid_mob">
          <div className="row mx-auto filtBox">
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="form-group filtInp">
                <InputForm
                  value={searchText}
                  placeholder={"Search text"}
                  isDisabled={false}
                  textArea={false}
                  onChange={(e) => {
                    setPage(0);
                    setStart(0);
                    setSearchText(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-12 filtrt">
              <ul className=" filter-icon-wel">
                <li>
                  <a
                    href={null}
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                    className="filter-btn">
                    <i className="fa fa-filter"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Advanced Filter */}
        <>
          <Collapse in={open}>
            <div id="example-collapse-text grid-wrapper">
              <div className="row mx-auto myInlineStyle">
                <>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">
                      Assessment Name
                    </label>
                    <SelectForm
                      isClearable
                      options={assessmentDropdownList}
                      placeholder={"Assessment Name"}
                      isDisabled={false}
                      onChange={(event) => {
                        setSelectedAssessmentIds(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={selectedAssessmentIds}
                    />
                  </div>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">Journey</label>
                    <SelectForm
                      isClearable
                      options={programDropdownList}
                      placeholder={"Journey"}
                      isDisabled={false}
                      onChange={(event) => {
                        setSelectedProgramIds(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={selectedProgramIds}
                    />
                  </div>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">Course</label>
                    <SelectForm
                      isClearable
                      options={courseDropdownList}
                      placeholder={"Course"}
                      isDisabled={false}
                      onChange={(event) => {
                        setSelectedCourseIds(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={selectedCourseIds}
                    />
                  </div>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">
                      Passing Status
                    </label>
                    <SelectForm
                      isClearable
                      options={passingStatusDropdownList}
                      placeholder={"Passing Status"}
                      isDisabled={false}
                      onChange={(event) => {
                        setSelectedPassingStatus(event);
                      }}
                      isMulti={false}
                      noIndicator={false}
                      noSeparator={false}
                      value={selectedPassingStatus}
                    />
                  </div>
                </>
                <div className="col-lg-12 d-flex mt-2 justify-content-end">
                  <button
                    onClick={() => clearFilters()}
                    className="btn btn-secondary mr-2">
                    <i className="fa fa-undo" aria-hidden="true"></i> Reset{" "}
                  </button>
                  <button
                    className="btn btn-filter-submit"
                    onClick={() => {
                      clearModalSelection();
                      getUserAssessmentDashboardData();
                    }}>
                    <i className="fa fa-search" aria-hidden="true"></i> Search{" "}
                  </button>
                </div>
              </div>
            </div>
          </Collapse>
        </>

        {/* Grid */}
        <div className="mb-3 pt-3">
          <DynamicGrid
            data={rowData}
            columns={
              activetab === "Pending Assessment"
                ? gridColumnsPending
                : gridColumnsCompleted
            }
            options={options}
          />
        </div>
      </div>

      <Modal
        show={messageModal}
        onHide={() => setMessageModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">Assessment Details</div>
        </div>
        <Modal.Body>
          <div className="container-fluid">
            {assessmentMessageData && (
              <>
                {assessmentMessageData.map((message) => (
                  <p
                    className="border bg-light p-2 m-2 d-flex align-items-center"
                    key={message.startMessage}>
                    {message.startMessage}
                  </p>
                ))}
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={async () => {
              if (reAttempt) {
                showLoader();
                const res = await APICall(manageUserAssessment, "POST", {
                  Mode: "RE-ASSIGN",
                  AssessmentId: assessmentId,
                  UserAssessmentId: userAssessmentId,
                  UserId: userDetails?.Id,
                  RoleId: currentRoleId?.value,
                });

                if (
                  res.status === 0 &&
                  res.data !== null &&
                  res.data.userAssessmentId > 0 &&
                  res.data.isError === false
                ) {
                  hideLoader();
                  navigate("/assessment", {
                    state: {
                      assessmentId: assessmentId,
                      userAssessmentId: res.data.userAssessmentId,
                      preview: false,
                      isUser: true,
                      from: activetab,
                    },
                  });
                } else {
                  hideLoader();
                  console.log("err", res);
                  notify(1, res.data.errorMessage);
                }
              } else {
                navigate("/assessment", {
                  state: {
                    assessmentId: assessmentId,
                    userAssessmentId: userAssessmentId,
                    preview: false,
                    isUser: true,
                    from: activetab,
                  },
                });
              }
            }}>
            Start
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setMessageModal(false);
              setAssessmentId(null);
              setUserAssessmentId(null);
            }}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={viewModal}
        onHide={() => setViewModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">Assessment Details</div>
        </div>
        <Modal.Body>
          <div className="container-fluid">
            {userAssessmentIds.length > 0 && (
              <>
                {userAssessmentIds.map((userAssessmentId, index) => (
                  <p className="border bg-light p-2 m-2 d-flex align-items-center">
                    <span>{index + 1}. </span>
                    <Button
                      variant="Link"
                      key={index}
                      onClick={() => {
                        navigate("/assessment", {
                          state: {
                            assessmentId: assessmentId,
                            userAssessmentId: userAssessmentId,
                            preview: true,
                            isUser: true,
                            from: activetab,
                          },
                        });
                      }}>
                      {userAttempts[index]}
                    </Button>
                  </p>
                ))}
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setViewModal(false);
            }}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserAssessmentsDashboard;
