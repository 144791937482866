import React, { useContext, useEffect, useState } from "react";
import InputForm from "../../Components/InputForm/InputForm";
import SelectForm from "../../Components/SelectForm/SelectForm";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { useLocation, useNavigate } from "react-router-dom";
import { APICall } from "../../Helpers/APICalls";
import {
  DeleteThumbnailProgramDetails,
  GetCommonDropdownForScheduling,
  GetThumbnailDetails,
  SetThumbnailDetails,
  getLMSDomainDropDownData,
  getLMSSessionMasterDataById,
  insertUpdateLMSSessionMaster,
  sessionDropdownData,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import notify from "../../Helpers/ToastNotification";
import { LoaderContext } from "../../Helpers/Context/Context";
import { Modal } from "react-bootstrap";
import defaultProfileImgPic from "../../Assets/Images/thumbnail.jpg";
import RTE from "../../Components/RTE/RTE";
import DateTimeForm from "../../Components/DateTimeForm/DateTimeForm";

const SessionEdit = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isDisable, setIsDisable] = useState(
    state && state?.isDisable ? true : false
  );

  let { userDetails, currentRoleId }: any = useUserContext();
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [attributeDataObj, setAttributeDataObj] = useState<any>({
    sessionId: "",
    sessionTitle: "",
    sessionObjective: "",
    sessionType: null,
    content: null,
    durationInHour: "",
    durationInMinutes: "",
    domain: null,
    attendanceType: null,
  });
  const [formData, setFormData] = useState<IFormData>({});
  const [formErrors, setFormErrors] = useState<any>({});
  const [sessionTypeOptions, setSessionTypeOptions] = useState([]);
  const [contentOptions, setContentOptions] = useState([]);
  const [reset, setReset] = useState(false);

  const [showProfileModal, setShowProfileModal] = useState(false);
  const [profileImg, setProfileImg] = useState(null);
  const [profileImgToUpload, setProfileImgToUpload] = useState<any>(null);
  const [isDurationMandatory, setIsDurationMandatory] = useState(true);
  const [domainOption, setDomainOption] = useState<any>([]);
  const [domain, setDomain] = useState(null);
  const [notification, setNotification] = useState(false);
  const [toEmail, setToEmail] = useState("");
  const [effectiveDate, setEffectiveDate] = useState(new Date());
  const [remarks, setRemarks] = useState("");
  const [ccuser, setCCuser] = useState<any>(null);
  const [isContentMandatory, setIsContentMandatory] = useState(true);

  const [attendanceTypeOption, setAttendanceTypeOption] = useState([]);

  const attendanceTypeData = [
    { value: "AUTO", label: "Automatic" },
    { value: "OPTIONAL", label: "Optional" },
  ];
  interface IFormData {
    co_ordinator?: IOptions | boolean | any;
  }
  interface IOptions {
    label?: string;
    value?: number | string;
    code?: string;
  }

  useEffect(() => {
    const helper = async () => {
      if (!state?.SessionId) {
        const urlSearchString = window.location.search;
        const params = new URLSearchParams(urlSearchString);

        const encryptedSessionId = params
          .get("sessionId")
          ?.replaceAll(" ", "+");

        navigate("/SessionEdit", {
          state: { SessionId: encryptedSessionId },
        });
      }
    };
    helper();
  }, []);

  useEffect(() => {
    const responseData = async () => {
      const objCreateSessionType = {
        mode: "CreateSessionType",
        userId: userDetails?.Id,
        roleId: currentRoleId?.value,
      };
      const res = await APICall(
        sessionDropdownData,
        "POST",
        objCreateSessionType
      );
      if (res.data && res.data.length > 0) {
        setSessionTypeOptions(res.data);
      }

      contentDropdownApiCall();
    };

    responseData();

    getLMSDomainDropDownDataApiCall();
  }, []);

  const contentDropdownApiCall = async () => {
    const objCreateSessionContent = {
      mode: "CreateSessionContent",
      userId: userDetails?.Id,
      roleId: currentRoleId?.value,
    };
    const res1 = await APICall(
      sessionDropdownData,
      "POST",
      objCreateSessionContent
    );
    if (res1.data && res1.data.length > 0) {
      setContentOptions(res1.data);
    }
  };

  const deleteProfileImage = async (id: any) => {
    if (profileImg != null) {
      showLoader();
      const doc = await APICall(DeleteThumbnailProgramDetails, "POST", {
        id,
        CreatedBy: userDetails?.Id,
      });
      if (doc.message === "Ok") {
        setProfileImg(null);
        setShowProfileModal(false);
        setProfileImgToUpload(null);
        hideLoader();
      } else {
        notify(1, "File not Deleted, Something Went Wrong.");
        hideLoader();
      }
    }
  };

  const fileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleImageSelect = async (e) => {
    const file = e.target.files[0];
    let fileBase64: { Name: string; Data: string } = null;
    const base64 = (await fileToBase64(file)) as string;
    const base64WithoutPrefix = base64.split(",")[1];
    fileBase64 = { Name: file.name, Data: base64WithoutPrefix };

    const validExtensions = ["png", "jpg", "jpeg"];
    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();
      const fileSize = file.size;
      const maxFileSizeBytes = 5 * 1024 * 1024;

      // Check if the file extension is allowed
      if (
        validExtensions.includes(fileExtension) &&
        fileSize <= maxFileSizeBytes
      ) {
        // file to upload
        setProfileImgToUpload(fileBase64);
        setProfileImg(`data:image;base64,${fileBase64.Data}`);
      } else {
        notify(1, "Only file with png,jpg,jpeg allowed and size under 5MB");
        e.target.value = "";
        setProfileImgToUpload(null);
      }
    }
  };

  const handleImageUpload = async (id: any) => {
    if (profileImgToUpload) {
      showLoader();
      let reqObj = {
        file: {
          // AC: "ProfilePic",
          Name: profileImgToUpload.Name,
          Data: profileImgToUpload.Data,
        },
        PId: id,
        SectionName: "Session",
        UserId: userDetails?.UserId,
        ///ModuleName: "EmployeeCentral",
        //TabName: "Personal",
        //ModuleId: 1,
        // ProxyUserId: proxyUserDetails === null ? null : proxyUserDetails?.value,
        // Id: id,
      };

      try {
        const response = await APICall(SetThumbnailDetails, "POST", reqObj);
        if (response && response.data) {
          setProfileImg(`data:image;base64,${response.data.base64}`);
          setShowProfileModal(false);
          hideLoader();
        } else {
          console.error("Image upload failed");
          notify(1, "Image upload failed.");
          hideLoader();
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        hideLoader();
      }
    }
    // else {
    //   notify(1, "Please Select Image to Upload.");
    // }
  };

  const getLMSDomainDropDownDataApiCall = async () => {
    const response = await APICall(getLMSDomainDropDownData, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      GetAll: state && state?.SessionId > 0 ? true : false,
    });

    if (response?.status === 0 && response?.data.length > 0) {
      setDomainOption(response?.data);
    }
  };

  const formOnChange = (e, type) => {
    switch (type) {
      case "sessionTitle":
        setAttributeDataObj({
          ...attributeDataObj,
          sessionTitle: e.target.value,
        });
        break;

      case "sessionObjective":
        setAttributeDataObj({
          ...attributeDataObj,
          sessionObjective: e,
        });
        break;

      case "sessionType":
        if (
          e &&
          (e.code === "JOB" ||
            e.code === "ALP" ||
            e.code === "OTHERS" ||
            e.code === "ELEARNING")
        ) {
          setIsDurationMandatory(false);
        } else {
          setIsDurationMandatory(true);
        }

        if (e && e.code === "ELEARNING") {
          setAttendanceTypeOption(attendanceTypeData);
        } else {
          const attendanceArray = attendanceTypeData?.filter(
            (env) => env?.value === "OPTIONAL"
          );
          setAttendanceTypeOption(attendanceArray);
        }

        if (
          e &&
          (e.code === "CLASS" ||
            e.code === "JOB" ||
            e.code === "OTHERS" ||
            e.code === "WEBINAR")
        ) {
          setIsContentMandatory(false);
        } else {
          setIsContentMandatory(true);
        }

        setAttributeDataObj({
          ...attributeDataObj,
          sessionType: e,
          attendanceType: null,
        });

        break;

      case "content":
        setAttributeDataObj({
          ...attributeDataObj,
          content: e,
        });
        break;

      case "domain":
        setAttributeDataObj({
          ...attributeDataObj,
          domain: e,
        });
        break;

      case "attendanceType":
        setAttributeDataObj({
          ...attributeDataObj,
          attendanceType: e,
        });
        break;

      case "durationInHour":
        setAttributeDataObj({
          ...attributeDataObj,
          durationInHour: e.target.value,
        });
        break;
      case "durationInMinutes":
        setAttributeDataObj({
          ...attributeDataObj,
          durationInMinutes: e.target.value,
        });
        break;

      default:
        break;
    }
  };

  const validateForm = () => {
    let errorObj = {};
    let isError = false;

    if (attributeDataObj.sessionTitle === "") {
      errorObj = {
        ...errorObj,
        ["sessionTitle"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["sessionTitle"]: "",
      };
    }

    if (attributeDataObj.sessionObjective === "") {
      errorObj = {
        ...errorObj,
        ["sessionObjective"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["sessionObjective"]: "",
      };
    }

    if (attributeDataObj.sessionType === null) {
      errorObj = {
        ...errorObj,
        ["sessionType"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,

        ["sessionType"]: "",
      };
    }

    if (isContentMandatory) {
      if (attributeDataObj.content === null) {
        errorObj = {
          ...errorObj,
          ["content"]: "Required",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["content"]: "",
        };
      }
    } else {
      errorObj = {
        ...errorObj,
        ["content"]: "",
      };
    }

    if (attributeDataObj.domain === null) {
      errorObj = {
        ...errorObj,
        ["domain"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["domain"]: "",
      };
    }

    if (attributeDataObj.attendanceType === null) {
      errorObj = {
        ...errorObj,
        ["attendanceType"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["attendanceType"]: "",
      };
    }

    if (isDurationMandatory) {
      if (attributeDataObj.durationInHour === "") {
        errorObj = {
          ...errorObj,
          ["durationInHour"]: "Required",
        };
      } else if (!/^([0-9]+|0)$/.test(attributeDataObj.durationInHour)) {
        errorObj = {
          ...errorObj,
          ["durationInHour"]: "Only numbers are allowed",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["durationInHour"]: "",
        };
      }
    } else {
      if (
        attributeDataObj.durationInHour !== "" &&
        attributeDataObj.durationInHour !== null
      ) {
        if (
          attributeDataObj.durationInHour !== "" &&
          !/^([0-9]+|0)$/.test(attributeDataObj.durationInHour)
        ) {
          errorObj = {
            ...errorObj,
            ["durationInHour"]: "Only numbers are allowed",
          };
        } else {
          errorObj = {
            ...errorObj,
            ["durationInHour"]: "",
          };
        }
      } else {
        errorObj = {
          ...errorObj,
          ["durationInHour"]: "",
        };
      }
    }

    if (isDurationMandatory) {
      if (
        attributeDataObj.durationInMinutes === "" &&
        attributeDataObj.durationInMinutes === null
      ) {
        errorObj = {
          ...errorObj,
          ["durationInMinutes"]: "Required",
        };
      } else if (!/^([0-9]+|0)$/.test(attributeDataObj.durationInMinutes)) {
        errorObj = {
          ...errorObj,
          ["durationInMinutes"]: "Only numbers are allowed",
        };
      } else if (parseInt(attributeDataObj.durationInMinutes) > 59) {
        errorObj = {
          ...errorObj,
          ["durationInMinutes"]: "Please enter in minutes",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["durationInMinutes"]: "",
        };
      }
    } else {
      if (attributeDataObj.durationInMinutes === "") {
        if (
          attributeDataObj.durationInMinutes !== "" &&
          !/^([0-9]+|0)$/.test(attributeDataObj.durationInMinutes)
        ) {
          errorObj = {
            ...errorObj,
            ["durationInMinutes"]: "Only numbers are allowed",
          };
        } else if (
          attributeDataObj.durationInMinutes !== "" &&
          parseInt(attributeDataObj.durationInMinutes) > 59
        ) {
          errorObj = {
            ...errorObj,
            ["durationInMinutes"]: "Please enter in minutes",
          };
        } else {
          errorObj = {
            ...errorObj,
            ["durationInMinutes"]: "",
          };
        }
      } else {
        errorObj = {
          ...errorObj,
          ["durationInMinutes"]: "",
        };
      }
    }

    const isEmpty = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    debugger;

    return isError;
  };

  useEffect(() => {
    if (
      state &&
      state?.SessionId > 0 &&
      sessionTypeOptions?.length > 0 &&
      contentOptions?.length > 0
    ) {
      getLMSSessionMasterDataByIdApiCall();
    }
  }, [sessionTypeOptions, contentOptions, reset]);

  const getLMSSessionMasterDataByIdApiCall = async () => {
    showLoader();

    const responseData = await APICall(getLMSSessionMasterDataById, "POST", {
      Id: state?.SessionId,
    });

    if (responseData?.status === 0) {
      const thumbnailUrl = responseData?.data?.thumbnailUrl;

      if (thumbnailUrl) {
        const getThumbnailData = await APICall(GetThumbnailDetails, "POST", {
          ThumbnailUrl: thumbnailUrl,
        });

        if (getThumbnailData && getThumbnailData.data !== null) {
          setProfileImg(
            `data:image;base64,${getThumbnailData?.data?.thumbnailDataObject}`
          );
        }
      }

      setToEmail(responseData?.data?.toUser);

      const sessionType = sessionTypeOptions?.find(
        (s) => s.value === responseData?.data?.sessionTypeId
      );

      if (sessionType && sessionType.code === "ELEARNING") {
        setAttendanceTypeOption(attendanceTypeData);
      } else {
        const attendanceArray = attendanceTypeData?.filter(
          (env) => env?.value === "OPTIONAL"
        );
        setAttendanceTypeOption(attendanceArray);
      }

      const attendanceType = attendanceTypeData?.find(
        (s) => s.value === responseData?.data?.attendanceType
      );

      //Content
      const contentIds =
        responseData?.data?.contentIds !== ""
          ? responseData?.data?.contentIds?.split(",")
          : null;

      const contentArray = [];

      if (contentIds) {
        contentIds?.map((item) => {
          const contantData = contentOptions.find(
            (i) => i.value === parseInt(item)
          );
          contentArray.push(contantData);
        });
      }

      //Domain
      const domainIds = responseData?.data?.domainIds?.split(",");
      const domainArray = [];

      domainIds?.map((item) => {
        const domainData = domainOption.find((i) => i.value === parseInt(item));
        domainArray.push(domainData);
      });

      if (
        sessionType &&
        (sessionType.code === "JOB" ||
          sessionType.code === "ALP" ||
          sessionType.code === "OTHERS" ||
          sessionType.code === "ELEARNING")
      ) {
        setIsDurationMandatory(false);
      } else {
        setIsDurationMandatory(true);
      }

      if (
        sessionType &&
        (sessionType.code === "CLASS" ||
          sessionType.code === "JOB" ||
          sessionType.code === "OTHERS" ||
          sessionType.code === "WEBINAR")
      ) {
        setIsContentMandatory(false);
      } else {
        setIsContentMandatory(true);
      }

      const LMSSessionMasterData = {
        sessionId: responseData?.data?.sessionCode,
        sessionTitle: responseData?.data?.sessionTitle,
        sessionObjective: responseData?.data?.sessionObjective,
        sessionType: sessionType,
        content: contentArray.length > 0 ? contentArray : null,
        durationInHour: responseData?.data?.totalDurationHours,
        durationInMinutes: responseData?.data?.totalDurationMinutes,
        domain: domainArray,
        attendanceType: attendanceType,
      };

      setAttributeDataObj(LMSSessionMasterData);
      hideLoader();
    } else {
    }
  };

  async function fetchDropdown(searchText = "", code = "", value = null) {
    try {
      const response = await APICall(
        GetCommonDropdownForScheduling,
        "POST",
        {
          SearchText: searchText,
          DropdownValues: value,
          Code: code,
          UserId: userDetails?.Id,
          RoleId: currentRoleId?.value,
        },
        true
      );

      if (response?.status === 0) {
        if (response?.data) {
          return response?.data;
        } else {
          return [];
        }
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  async function handleSearchForAsyncDropdown(event, code, callback) {
    try {
      if (event?.length >= 3) {
        const data = await fetchDropdown(event, code);
        callback(data);
      } else {
        callback([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const requestParamsFun = () => {
    const requestParams = {
      Id:
        state === null ||
        state?.SessionId === null ||
        state?.SessionId === undefined
          ? 0
          : state?.SessionId,
      SessionTitle: attributeDataObj?.sessionTitle,
      SessionObjective: attributeDataObj?.sessionObjective,
      SessionType: attributeDataObj?.sessionType?.value,
      ContentIds: attributeDataObj?.content?.map((j) => j.value).join(","),
      TotalDurationHours:
        attributeDataObj?.durationInHour !== ""
          ? attributeDataObj?.durationInHour
          : null,
      TotalDurationMinutes:
        attributeDataObj?.durationInMinutes !== ""
          ? attributeDataObj?.durationInMinutes
          : null,
      isActive: true,
      createdBy: userDetails?.Id,
      DomainIds: attributeDataObj?.domain?.map((e) => e.value).join(","),
      ToUser: toEmail || null,
      CCUserIds: ccuser?.map((event) => event.value).join(",") || null,
      EffectiveDate: effectiveDate || null,
      Remarks: remarks || null,
      attendanceType: attributeDataObj?.attendanceType?.value,
    };

    return requestParams;
  };

  const handleSubmitButton = async () => {
    showLoader();

    if (validateForm()) {
      hideLoader();
      return;
    }

    const requestParams = requestParamsFun();

    if (requestParams?.Id === 0) {
      insertUpdateData();
    } else {
      setNotification(true);
    }

    hideLoader();
  };

  const InsertUpdateDataOnNotification = async () => {
    if (validateForm()) {
      setNotification(false);
      return; // Stop the submission if there are validation errors
    }

    insertUpdateData();
  };

  const insertUpdateData = async () => {
    showLoader();
    const requestParams = requestParamsFun();
    const response = await APICall(
      insertUpdateLMSSessionMaster,
      "POST",
      requestParams
    );
    if (response?.status === 0) {
      const newSessionId = response?.data?.id;

      if (newSessionId != 0 && profileImg != null) {
        await handleImageUpload(newSessionId);
      }

      navigate("/Session");
      notify(response?.status, response?.message);

      setReset(!reset);
    } else {
      notify(response?.status, response?.message);
    }

    hideLoader();
  };

  const resetFunction = () => {
    setFormErrors({});

    const objManageSession = {
      sessionId: "",
      sessionTitle: "",
      sessionObjective: "",
      sessionType: null,
      content: null,
      duration: "",
      durationInHour: "",
      durationInMinutes: "",
      domain: null,
    };

    setProfileImg(null);

    setAttributeDataObj(objManageSession);
  };
  return (
    <>
      <div className="breadcrumb modified-bredcrumb">
        <span>
          <ul>
            <li>
              <a href="/home">Home</a>
            </li>
            <li>
              {" "}
              <a href="/Session">Session</a>
            </li>
            <li>Manage Session</li>
          </ul>
        </span>
        <button
          onClick={() => {
            navigate("/Session");
          }}
          disabled={false}
          className="btn btn-secondary float-right">
          <i className="fa-solid fa-arrow-left"></i> Back
        </button>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 d-flex justify-content-center align-items-start">
            <div className="card Prof_img">
              <img
                src={profileImg != null ? profileImg : defaultProfileImgPic}
                className="card-img-top "
                alt="Thumbnail Image"
              />
              {!isDisable && (
                <a
                  className="edit-profile"
                  onClick={() => setShowProfileModal(true)}>
                  <i className="fas fa-edit"></i>
                </a>
              )}
            </div>
          </div>

          <div className="col-md-9">
            <div className="row">
              <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Session ID
                    <sup>*</sup>
                  </label>

                  <InputForm
                    className="form-control"
                    placeholder={"Session ID"}
                    isDisabled={true}
                    textArea={false}
                    value={attributeDataObj.sessionId}
                    onChange={(e) => {}}
                    maxLength="255"
                  />
                  {formErrors["sessionId"] && (
                    <p style={{ color: "red" }}>{formErrors["sessionId"]}</p>
                  )}
                </div>
              </div>
              <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Session Title
                    <sup>*</sup>
                  </label>

                  <InputForm
                    className="form-control"
                    placeholder={"Session Title"}
                    isDisabled={isDisable}
                    textArea={false}
                    value={attributeDataObj.sessionTitle}
                    onChange={(e) => {
                      formOnChange(e, "sessionTitle");
                    }}
                    maxLength="255"
                  />
                  {formErrors["sessionTitle"] && (
                    <p style={{ color: "red" }}>{formErrors["sessionTitle"]}</p>
                  )}
                </div>
              </div>

              <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Session Type
                    <sup>*</sup>
                  </label>

                  <SelectForm
                    isClearable
                    isSearchable
                    options={sessionTypeOptions}
                    placeholder={"Session Type"}
                    isDisabled={isDisable}
                    value={attributeDataObj.sessionType}
                    onChange={async (e) => {
                      formOnChange(e, "sessionType");
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  {formErrors["sessionType"] && (
                    <p style={{ color: "red" }}>{formErrors["sessionType"]}</p>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Duration
                    {isDurationMandatory && <sup>*</sup>}
                  </label>

                  <div className="row">
                    <div className={"col-lg-3 col-sm-4 col-xs-4"}>
                      <InputForm
                        className="form-control form-inline"
                        placeholder={"hh"}
                        isDisabled={isDisable}
                        textArea={false}
                        value={attributeDataObj.durationInHour}
                        maxLength={2}
                        onChange={(e) => {
                          formOnChange(e, "durationInHour");
                        }}
                      />
                      {formErrors["durationInHour"] && (
                        <p style={{ color: "red" }}>
                          {formErrors["durationInHour"]}
                        </p>
                      )}
                    </div>
                    <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                      {" : "}
                    </span>
                    <div className={"col-lg-3 col-sm-4 col-xs-4"}>
                      <InputForm
                        className="form-control"
                        placeholder={"mm"}
                        isDisabled={isDisable}
                        textArea={false}
                        maxLength={2}
                        value={attributeDataObj.durationInMinutes}
                        onChange={(e) => {
                          formOnChange(e, "durationInMinutes");
                        }}
                      />
                      {formErrors["durationInMinutes"] && (
                        <p style={{ color: "red" }}>
                          {formErrors["durationInMinutes"]}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                <div className="dropdown mb-1">
                  <label className="col-form-label">
                    Content
                    {isContentMandatory && <sup>*</sup>}
                  </label>

                  <SelectForm
                    isClearable
                    isSearchable
                    options={contentOptions}
                    placeholder={"Content"}
                    isDisabled={isDisable}
                    value={attributeDataObj.content}
                    onChange={async (e) => {
                      formOnChange(e, "content");
                    }}
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  {formErrors["content"] && (
                    <p style={{ color: "red" }}>{formErrors["content"]}</p>
                  )}
                </div>
              </div>
              {!isDisable && (
                <div className="col-lg-4 col-sm-4 col-xs-4">
                  <div className="mt-2"></div>
                  <button
                    onClick={() => {
                      // resetFunction();

                      window.open("/Contentedit", "_blank");
                    }}
                    disabled={false}
                    className="btn btn-primary mt-4">
                    <i className="fa fa-plus mr-2"></i>
                    Create Content
                  </button>

                  <button
                    onClick={() => {
                      // resetFunction();

                      //window.open("/Contentedit", "_blank");
                      // window.location.reload();
                      contentDropdownApiCall();
                    }}
                    disabled={false}
                    className="btn btn-primary ml-2 mt-4">
                    <i className="fa-solid fa-refresh"></i> Refresh
                  </button>
                </div>
              )}
            </div>

            <div className="row">
              <div className={"col-lg-8 col-sm-8 col-xs-8"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Session Objective
                    <sup>*</sup>
                  </label>

                  <RTE
                    content={attributeDataObj.sessionObjective}
                    disabled={isDisable}
                    onblur={(e) => {
                      formOnChange(e, "sessionObjective");
                    }}
                  />

                  {formErrors["sessionObjective"] && (
                    <p style={{ color: "red" }}>
                      {formErrors["sessionObjective"]}
                    </p>
                  )}
                </div>
              </div>

              <div className="col-md-4">
                <div className="row">
                  <div className="dropdown col-md-12">
                    <label className="col-form-label">Domains</label>
                    <sup>*</sup>
                    <SelectForm
                      isClearable
                      options={domainOption}
                      isOptionDisabled={(option) => !option?.isEnabled}
                      placeholder={"Domains"}
                      isDisabled={isDisable}
                      onChange={(e) => {
                        formOnChange(e, "domain");
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={attributeDataObj.domain}
                    />
                    {formErrors["domain"] && (
                      <p style={{ color: "red" }}>{formErrors["domain"]}</p>
                    )}
                  </div>
                  <div className="col-md-12">
                    <label className="col-form-label">Attendance Type</label>
                    <sup>*</sup>
                    <SelectForm
                      isClearable
                      options={attendanceTypeOption}
                      placeholder={"Attendance Type"}
                      isDisabled={isDisable}
                      onChange={(e) => {
                        formOnChange(e, "attendanceType");
                      }}
                      isMulti={false}
                      noIndicator={false}
                      noSeparator={false}
                      value={attributeDataObj.attendanceType}
                    />
                    {formErrors["attendanceType"] && (
                      <p style={{ color: "red" }}>
                        {formErrors["attendanceType"]}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {!isDisable && (
              <div className="mt-2 SectionSubmit mb-4 clearfix">
                <button
                  onClick={() => {
                    resetFunction();
                  }}
                  disabled={false}
                  className="btn btn-secondary float-right ml-2">
                  <i className="fa-solid fa-refresh"></i> Reset
                </button>

                <button
                  onClick={() => {
                    handleSubmitButton();
                  }}
                  disabled={false}
                  className="btn btn-filter-submit float-right ml-2">
                  <i className="fa-solid fa-check"></i> Save
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={showProfileModal}
        onHide={() => setShowProfileModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">Upload Profile Picture!</div>
        </div>
        <Modal.Body>
          <>
            <input
              type="file"
              multiple={false}
              accept={".png,.jpg,.jpeg"}
              onChange={handleImageSelect}
            />
            <div>
              <b>Acceptable image formats:</b> .png, .jpg and .jpeg{" "}
            </div>
            <div>
              {" "}
              <b>Maximum File Size:</b> 5MB{" "}
            </div>
            <div>
              <b>Resolution:</b> 3245 X 3245{" "}
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          {profileImg != null && (
            <>
              {state != null && state.contentId != 0 ? (
                // Add any additional JSX you want to render when state is not null and state.id is not 0
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: 5 }}
                  onClick={() => {
                    deleteProfileImage(state.id);
                  }}>
                  Remove
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: 5 }}
                  onClick={() => {
                    setShowProfileModal(false);
                    setProfileImg(null);
                    setProfileImgToUpload(null);
                  }}>
                  Remove
                </button>
              )}
            </>
          )}

          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => setShowProfileModal(false)}>
            Upload
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowProfileModal(false);
              setProfileImgToUpload(null);
            }}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      {/* Notification */}

      <Modal
        show={notification}
        onHide={() => setNotification(false)}
        backdrop="static"
        keyboard={false}
        size="xl"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">
            Notification and mail will be sent to the following users
          </div>
        </div>
        <Modal.Body>
          <>
            <div className="row mx-auto ">
              <div className="col-lg-6 col-sm-6 col-xs-6">
                <div className="mb-1">
                  <label className="col-form-label">
                    To <sup>*</sup>
                  </label>
                  <InputForm
                    value={toEmail}
                    placeholder={"Enter To Email"}
                    isDisabled={true}
                    textArea={false}
                    onChange={(e) => {
                      setToEmail(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="col-lg-6 col-sm-6 col-xs-6">
                <div className="dropdown mb-1">
                  <label className="col-form-label">CC</label>
                  <SelectForm
                    isClearable
                    async
                    placeholder={"Search CC User"}
                    isDisabled={false}
                    options={(searchString, cb) => {
                      handleSearchForAsyncDropdown(
                        searchString,
                        "co_ordinator",
                        cb
                      );
                    }}
                    onChange={(val: any) => {
                      setCCuser(val);
                    }}
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                    value={ccuser}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 col-xs-6">
                <div className="mb-1">
                  <label className="col-form-label">
                    Effective Date <sup>*</sup>
                  </label>
                  <DateTimeForm
                    value={effectiveDate}
                    placeholder={"Effective Date"}
                    onChange={(event) => {
                      setEffectiveDate(event);
                    }}
                    isDisabled={true}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 col-xs-6">
                <div className="mb-1">
                  <label className="col-form-label">Remarks</label>
                  <InputForm
                    value={remarks}
                    placeholder={"Enter the remarks"}
                    isDisabled={false}
                    textArea={true}
                    onChange={(e) => {
                      setRemarks(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => {
              InsertUpdateDataOnNotification();
            }}>
            Execute
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setNotification(false);
            }}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SessionEdit;
