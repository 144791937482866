import React, { useState } from "react";

function BiometricsReact() {
  const [deviceInfo, setDeviceInfo] = useState<any>({});
  const [deviceConnection, setDeviceConnection] = useState<any>("1");

  const [slap, setSlap] = useState<any>(1);
  const [timeout, setTimeout] = useState<any>(50);
  const [tempImage, setTempImage] = useState<any>("");

  const [biometricsData, setBiometricsData] = useState<any>([]);
  const [fingerPosition, setFingerPosition] = useState<any>({
    LEFT_LITTLE: false,
    LEFT_RING: false,
    LEFT_MIDDLE: false,
    LEFT_INDEX: false,

    RIGHT_INDEX: false,
    RIGHT_MIDDLE: false,
    RIGHT_RING: false,
    RIGHT_LITTLE: false,

    LEFT_THUMB: false,
    RIGHT_THUMB: false,
  });

  var uri = "https://localhost:8032/morfinenroll/"; //Secure

  const getDeviceInfo = async () => {
    let deviceInfo = await PostMorFinEnrollClient("info", "", 0);
    await setDeviceInfo(deviceInfo.data.DeviceInfo);
  };

  const checkDevice = async () => {
    let deviceInfo = await PostMorFinEnrollClient("checkdevice", "", 0);
    await setDeviceConnection(deviceInfo.data.ErrorCode);
  };

  const GetImage = async (imgformat, compressionRatio) => {
    let biometricsArray = [];
    await setBiometricsData([]);
    var MorFinEnrollRequest = {
      ImgFormat: imgformat, //Jpeg
      CompressionRatio: compressionRatio,
    };
    debugger;
    var jsondata = JSON.stringify(MorFinEnrollRequest);
    //return PostMorFinEnrollClient("getimage", jsondata, 1);

    let fingerInfo = await PostMorFinEnrollClient("getimage", jsondata, 1);
    console.log("base64encoded", fingerInfo);
    if (fingerInfo.data != null && fingerInfo.data.ErrorCode == "0") {
      if (fingerInfo.data.Fingers.FingerCount > 0) {
        var Isfinger1Assign = false;
        var Isfinger2Assign = false;
        var Isfinger3Assign = false;
        var Isfinger4Assign = false;

        for (var i = 1; i <= fingerInfo.data.Fingers.FingerCount; i++) {
          if (slap == 0) {
            if (Isfinger1Assign == false && !fingerPosition.LEFT_LITTLE) {
              let fingerObj: any = {
                fpPosition: 2,
                fPHand: slap,
                fpImageData: fingerInfo.data.Fingers.fingers[i],
                quality: "",
              };
              await setTempImage(fingerInfo.data.Fingers.fingers[i]);
              biometricsArray.push(fingerObj);
              await setBiometricsData([...biometricsData, fingerObj]);
              Isfinger1Assign = true;
            } else if (Isfinger2Assign == false && !fingerPosition.LEFT_RING) {
              let fingerObj: any = {
                fpPosition: 3,
                fPHand: slap,
                fpImageData: fingerInfo.data.Fingers.fingers[i],
                quality: "",
              };
              biometricsArray.push(fingerObj);
              await setBiometricsData([...biometricsData, fingerObj]);
              Isfinger1Assign = true;
              Isfinger2Assign = true;
            } else if (
              Isfinger3Assign == false &&
              !fingerPosition.LEFT_MIDDLE
            ) {
              let fingerObj: any = {
                fpPosition: 4,
                fPHand: slap,
                fpImageData: fingerInfo.data.Fingers.fingers[i],
                quality: "",
              };
              biometricsArray.push(fingerObj);
              await setBiometricsData([...biometricsData, fingerObj]);
              Isfinger1Assign = true;
              Isfinger2Assign = true;
              Isfinger3Assign = true;
            } else if (Isfinger4Assign == false && !fingerPosition.LEFT_INDEX) {
              let fingerObj: any = {
                fpPosition: 5,
                fPHand: slap,
                fpImageData: fingerInfo.data.Fingers.fingers[i],
                quality: "",
              };
              biometricsArray.push(fingerObj);
              await setBiometricsData([...biometricsData, fingerObj]);

              Isfinger1Assign = true;
              Isfinger2Assign = true;
              Isfinger3Assign = true;
              Isfinger4Assign = true;
            }
          } else if (slap == 1) {
            if (Isfinger1Assign == false && !fingerPosition.RIGHT_INDEX) {
              let fingerObj: any = {
                fpPosition: 7,
                fPHand: slap,
                fpImageData: fingerInfo.data.Fingers.fingers[i],
                quality: "",
              };
              await setTempImage(fingerInfo.data.Fingers.fingers[i]);
              biometricsArray.push(fingerObj);
              await setBiometricsData([...biometricsData, fingerObj]);
              Isfinger1Assign = true;
            } else if (
              Isfinger2Assign == false &&
              !fingerPosition.RIGHT_MIDDLE
            ) {
              let fingerObj: any = {
                fpPosition: 8,
                fPHand: slap,
                fpImageData: fingerInfo.data.Fingers.fingers[i],
                quality: "",
              };
              biometricsArray.push(fingerObj);
              await setBiometricsData([...biometricsData, fingerObj]);
              Isfinger1Assign = true;
              Isfinger2Assign = true;
            } else if (Isfinger3Assign == false && !fingerPosition.RIGHT_RING) {
              let fingerObj: any = {
                fpPosition: 9,
                fPHand: slap,
                fpImageData: fingerInfo.data.Fingers.fingers[i],
                quality: "",
              };
              biometricsArray.push(fingerObj);
              await setBiometricsData([...biometricsData, fingerObj]);
              Isfinger1Assign = true;
              Isfinger2Assign = true;
              Isfinger3Assign = true;
            } else if (
              Isfinger4Assign == false &&
              !fingerPosition.RIGHT_LITTLE
            ) {
              let fingerObj: any = {
                fpPosition: 8,
                fPHand: slap,
                fpImageData: fingerInfo.data.Fingers.fingers[i],
                quality: "",
              };
              biometricsArray.push(fingerObj);
              await setBiometricsData([...biometricsData, fingerObj]);
              Isfinger1Assign = true;
              Isfinger2Assign = true;
              Isfinger3Assign = true;
              Isfinger4Assign = true;
            }
          } else if (slap == 2) {
            if (Isfinger1Assign == false && !fingerPosition.LEFT_THUMB) {
              let fingerObj: any = {
                fpPosition: 1,
                fPHand: slap,
                fpImageData: fingerInfo.data.Fingers.fingers[i],
                quality: "",
              };
              biometricsArray.push(fingerObj);
              await setBiometricsData([...biometricsData, fingerObj]);
              Isfinger1Assign = true;
              Isfinger2Assign = true;
              Isfinger3Assign = true;
              Isfinger4Assign = true;

              Isfinger1Assign = true;
            } else if (
              Isfinger2Assign == false &&
              !fingerPosition.RIGHT_THUMB
            ) {
              let fingerObj: any = {
                fpPosition: 6,
                fPHand: slap,
                fpImageData: fingerInfo.data.Fingers.fingers[i],
                quality: "",
              };
              biometricsArray.push(fingerObj);
              await setBiometricsData([...biometricsData, fingerObj]);
              Isfinger1Assign = true;
              Isfinger2Assign = true;
              Isfinger3Assign = true;
              Isfinger4Assign = true;
            }
          }
        }
      }
      await setBiometricsData(biometricsArray);
      console.log(biometricsArray);
    }
  };

  const CaptureFinger = async () => {
    let biometricsArray = [];
    await setBiometricsData([]);
    var MorFinEnrollRequest = {
      TimeOut: timeout,
      Slap: slap, // Hand
      FingerPosition: fingerPosition,
    };
    var jsondata = JSON.stringify(MorFinEnrollRequest);
    let fingerInfo = await PostMorFinEnrollClient("capture", jsondata, 1);
  };

  async function PostMorFinEnrollClient(method, jsonData, isBodyAvailable) {
    var res;

    var httpStatus = false;
    var requestOptions: any = {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: isBodyAvailable ? jsonData : undefined,
      mode: "cors",
      cache: "no-cache",
      redirect: "follow",
      referrerPolicy: "no-referrer",
    };

    try {
      const response = await fetch(uri + method, requestOptions);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      httpStatus = true;
      const data = await response.json();
      console.log("method->", method);
      console.log(data);
      res = { httpStatus: httpStatus, data: data };
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      res = { httpStatus: httpStatus, err: error };
    }

    return res;
  }

  return (
    <div>
      <button
        style={{
          marginTop: "100px",
          backgroundColor: deviceConnection === "0" ? "green" : "red",
        }}
        onClick={checkDevice}
      >
        Check device
      </button>

      <button style={{ marginTop: "100px" }} onClick={getDeviceInfo}>
        Get Device info
      </button>
      <div>
        <strong>Firmware:</strong> {deviceInfo.Firmware}
        <strong> Height:</strong> {deviceInfo.Height}
        <strong> Make: </strong> {deviceInfo.Make}
        <strong> Model: </strong> {deviceInfo.Model}
        <strong> SerialNo:</strong> {deviceInfo.SerialNo}
        <strong> Width: </strong> {deviceInfo.Width}
      </div>
      <br />
      <button style={{ marginTop: "100px" }} onClick={CaptureFinger}>
        Start capture
      </button>

      <br />
      <button style={{ marginTop: "100px" }} onClick={() => GetImage("0", 10)}>
        Get Image
      </button>
      <br />

      {biometricsData ? (
        <>
          <img src={`data:image/png;base64,${tempImage}`} />
        </>
      ) : null}
    </div>
  );
}

export default BiometricsReact;
