import React, { useContext, useEffect, useState } from "react";
import { LoaderContext } from "../../../Helpers/Context/Context";
import {
  deleteManpowerRequisitionScreeningQuestion,
  getDropdowns,
  getMRScreeningQuestion,
  postAttributeValueDetailsV2,
  postMRScreeningQuestion,
} from "../../../Helpers/APIEndPoints/EndPoints";
import { APICall } from "../../../Helpers/APICalls";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import notify from "../../../Helpers/ToastNotification";
import moment from "moment";
import { ExpandMore } from "@mui/icons-material";
import DynamicGrid from "../../../Components/DynamicGrid/DynamicGrid";
import uuid from "react-uuid";
import SelectForm from "../../../Components/SelectForm/SelectForm";
import InputForm from "../../../Components/InputForm/InputForm";
import DateForm from "../../../Components/DateForm/DateForm";
import { useLocation } from "react-router-dom";
import { MRFContext } from "../MRF";
import async from "react-select/dist/declarations/src/async/index";

const ScreeningQuestion = ({
  TId,
  setTId,
  formData,
  setFormData,
  section: ogSection,
  sectionValuesArray,
  hideSectionAndAttribute,
  disableAttribute,
  setIsScreeningSubmitted,
}) => {
  const [hasValue, setHasValue] = useState(
    sectionValuesArray.filter((es) => es.sectionSystemName == ogSection.SN) ||
      null
  );
  const { state } = useLocation();
  const [section, setSection] = useState(ogSection);
  const [accordion, setAccordion] = useState(true);
  const [formErrors, setFormErrors] = useState<any>({});
  const [optionsObj, setOptionsObj] = useState<any>({});
  const [gridData, setGridData] = useState([]);
  const [displayGrid, setDisplayGrid] = useState([]);
  const [gridColumns, setGridColumns] = useState([]);
  const [ogAttributeSet, setOgAttributeSet] = useState({});
  const [ogFilledAttributeSet, setOgFilledAttributeSet] = useState([]);
  const [currentGridId, setCurrentGridId] = useState(null);
  const [deleteGridId, setDeleteGridId] = useState(null);

  const [ShowAttrSet, setShowAttrSet] = useState(false);
  const [viewOnly, setViewOnly] = useState(false);

  // value Object for section
  const [attributesData, setAttributesData] = useState<any>({});
  const [attributeSetData, setAttributeSetData] = useState<any>({});
  const [attributeSetCode, setAttributeSetCode] = useState(null);
  const [isOnce, setIsOnce] = useState(true);
  const [isValidateOnce, setIsValidateOnce] = useState(true);
  const [attributeValidation, setAttributeValidation] = useState<any>({});
  const [attributeSetValidation, setAttributeSetValidation] = useState<any>({});
  const [dataFilled, setDataFilled] = useState(false);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { sendback, setSendback } = useContext(MRFContext);
  const [options, setOptions] = useState({});
  const [tempAttributes, setTempAttribute] = useState({});

  const getDropdownsData = async (AC, PID) => {
    await showLoader();
    const dropdownData = await APICall(getDropdowns, "POST", {
      AC,
      PID,
    });

    if (
      dropdownData.data !== null &&
      dropdownData.data !== undefined &&
      dropdownData.data.length > 0
    ) {
      await hideLoader();
      return dropdownData.data;
    } else {
      await hideLoader();
      return [];
    }
  };

  const answerSetObj = {
    Id: 0,
    SQANS: "",
    SQCODANS: false,
    SQADDTXTB: false,
  };

  const [answerSet, setAnswerSet] = useState([answerSetObj]);

  const resetAnswerSet = async () => {
    setAnswerSet([answerSetObj]);
  };

  useEffect(() => {
    let attributeSet = {};
    const gridColumnsArr = [];
    let dropdownOptionsObj = {};
    section?.Attribute?.forEach(async (eachAttribute) => {
      if (eachAttribute.AT === "AttributeSet") {
        setAttributeSetCode(eachAttribute.AC);

        // Id for grid
        gridColumnsArr.push({
          name: "Id",
          label: "Id",
          options: { display: false },
        });

        eachAttribute.Attribute.forEach(async (subAttribute) => {
          if (
            subAttribute.AC === "SQQUESEQ" ||
            subAttribute.AC === "SQQUES" ||
            subAttribute.AC === "SQTYOVAL" ||
            subAttribute.AC === "SQTXTLBL" ||
            subAttribute.AC === "MQAAJ"
            // subAttribute.AC === "SQCT"
          ) {
            gridColumnsArr.push({
              name: subAttribute.AC,
              label: subAttribute.AN,
              options: {
                sort: false,
                display:
                  subAttribute.AC === "SQTXTLBL" ||
                  // subAttribute.AC === "SQCT" ||
                  subAttribute.AC === "MQAAJ"
                    ? false
                    : true,
              },
            });

            setAttributeSetValidation((prev) => ({
              ...prev,
              [subAttribute.AC]: JSON.parse(subAttribute.V),
            }));

            //change value according to dt

            setAttributeSetData((prev) => ({
              ...prev,
              [subAttribute.AC]:
                subAttribute.DT === "DropdownSingle" ||
                subAttribute.DT === "Date"
                  ? null
                  : subAttribute.DT === "Text" || subAttribute.DT === "Number"
                  ? ""
                  : subAttribute.DT === "Checkbox" ||
                    subAttribute.DT === "Radio"
                  ? false
                  : "",
            }));

            attributeSet = {
              ...attributeSet,
              answerSet: [],
              [subAttribute.AC]:
                subAttribute.DT === "DropdownSingle" ||
                subAttribute.DT === "Date"
                  ? null
                  : subAttribute.DT === "Text" || subAttribute.DT === "Number"
                  ? ""
                  : subAttribute.DT === "Checkbox"
                  ? false
                  : "",
            };

            if (subAttribute.DT === "DropdownSingle") {
              dropdownOptionsObj = {
                ...dropdownOptionsObj,
                [subAttribute.AC]: subAttribute.OPT,
              };

              setOptionsObj((prev) => ({
                ...prev,
                [subAttribute.AC]: subAttribute.OPT,
              }));
            }
          }
        });
      }
    });
    setOgAttributeSet(attributeSet);
    setGridColumns((prev) => [...prev, ...gridColumnsArr, action]);

    const GetScreeningData = async () => {
      showLoader();

      const response = await APICall(getMRScreeningQuestion, "POST", {
        TId: TId ? TId : 0,
      });

      if (response.data && response.data.length > 0) {
        const details = response.data;
        if (details.length > 0) {
          let newAttributeSetData = { ...attributeSet };
          let newAttributeSetDataForGrid = { ...attributeSet };
          let dropdownOptions = { ...dropdownOptionsObj };

          const filledData = [];
          const gridData = [];

          details.forEach(async (attribute) => {
            newAttributeSetData = {
              ...newAttributeSetData,
              Id: attribute.Id,
              IsActive: attribute.IsActive,
              SQQUES: attribute.SQQUES,
              SQQUESEQ: attribute.SQQUESEQ,
              SQTXTLBL: attribute.SQTXTLBL,
              SQTYOVAL: attribute.SQTYOVAL,
              answerSet: attribute.answerSet,
              MQAAJ: attribute.MQAAJ,
              SQCT: attribute.SQCT,
            };

            newAttributeSetDataForGrid = {
              ...newAttributeSetDataForGrid,
              Id: attribute.Id,
              IsActive: attribute.IsActive,
              SQQUES: attribute.SQQUES,
              SQQUESEQ: attribute.SQQUESEQ,
              SQTXTLBL: attribute.SQTXTLBL,
              SQTYOVAL: attribute.SQTYOVAL.label,
              answerSet: attribute.answerSet,
              MQAAJ: attribute.MQAAJ,
              SQCT: attribute.SQCT?.label,
            };

            gridData.push(newAttributeSetDataForGrid);
            filledData.push(newAttributeSetData);
          });

          setDisplayGrid(gridData);
          setGridData(gridData);
          setOgFilledAttributeSet(filledData);
          setOptions(dropdownOptions);
        }
      }

      hideLoader();
    };

    //if (state !== null && state.TId !== 0) {
    GetScreeningData();
    // }
  }, []);

  const [showTextLabel, setShowTextLabel] = useState(false);

  const GetScreeningDataV2 = async () => {
    showLoader();

    const response = await APICall(getMRScreeningQuestion, "POST", {
      TId: TId ? TId : 0,
    });

    if (response.data && response.data.length > 0) {
      const details = response.data;
      if (details.length > 0) {
        setIsScreeningSubmitted(true);

        let newAttributeSetData = {};
        let newAttributeSetDataForGrid = {};

        const filledData = [];
        const gridData = [];

        details.forEach(async (attribute) => {
          newAttributeSetData = {
            ...newAttributeSetData,
            Id: attribute.Id,
            IsActive: attribute.IsActive,
            SQQUES: attribute.SQQUES,
            SQQUESEQ: attribute.SQQUESEQ,
            SQTXTLBL: attribute.SQTXTLBL,
            SQTYOVAL: attribute.SQTYOVAL,
            answerSet: attribute.answerSet,
            MQAAJ: attribute.MQAAJ,
            SQCT: attribute.SQCT,
          };

          newAttributeSetDataForGrid = {
            ...newAttributeSetDataForGrid,
            Id: attribute.Id,
            IsActive: attribute.IsActive,
            SQQUES: attribute.SQQUES,
            SQQUESEQ: attribute.SQQUESEQ,
            SQTXTLBL: attribute.SQTXTLBL,
            SQTYOVAL: attribute.SQTYOVAL.label,
            answerSet: attribute.answerSet,
            MQAAJ: attribute.MQAAJ,
            SQCT: attribute.SQCT?.label,
          };

          gridData.push(newAttributeSetDataForGrid);
          filledData.push(newAttributeSetData);
        });

        setDisplayGrid(gridData);
        setGridData(gridData);
        setOgFilledAttributeSet(filledData);
      } else {
        setIsScreeningSubmitted(false);
      }
    }

    hideLoader();
  };

  useEffect(() => {
    setShowTextLabel(answerSet.some((element) => element.SQADDTXTB === true));
  }, [answerSet]);

  useEffect(() => {
    if (showTextLabel) {
      setAttributeSetData((prev) => ({
        ...prev,
        SQTXTLBL: prev.SQTXTLBL !== "" ? prev.SQTXTLBL : "",
      }));
    } else {
      setAttributeSetData((prev) => ({ ...prev, SQTXTLBL: "" }));
    }
  }, [showTextLabel]);

  const action = {
    name: "Id",
    label: "Action",
    options: {
      filter: false,
      sort: false,
      display: true,
      setCellProps: () => ({
        style: { textAlign: "center" },
      }),
      setCellHeaderProps: () => ({
        style: { textAlign: "center" },
      }),
      customBodyRender: (value, tableMeta) => {
        let Id = tableMeta.tableData[tableMeta.rowIndex].Id;
        let disable = false;
        if (sendback) {
          disable = true;
        } else {
          if (disableAttribute) {
            disable = true;
          } else {
            disable = false;
          }
        }

        return (
          <div className="d-flex justify-content-center">
            <Tooltip title="edit">
              <a
                className="mr-2"
                onClick={(e) => {
                  e.preventDefault();
                  setFormErrors({});
                  setCurrentGridId(Id);
                  setViewOnly(false);
                  setShowAttrSet(true);
                }}>
                <i className="fas fa-edit"></i>
              </a>
            </Tooltip>
            <Tooltip title="delete">
              <a
                className="mr-2"
                hidden={disable}
                onClick={(e) => {
                  e.preventDefault();
                  setFormErrors({});
                  setDeleteGridId(Id);
                }}>
                <i className="fa fa-trash" aria-hidden="true"></i>
              </a>
            </Tooltip>
          </div>
        );
      },
    },
  };

  useEffect(() => {
    if (currentGridId) {
      let attributeSetData = ogFilledAttributeSet.find(
        (record) => record.Id === currentGridId
      );

      setAnswerSet(attributeSetData.answerSet);

      setAttributeSetData(attributeSetData);
    }
  }, [currentGridId]);

  useEffect(() => {
    if (deleteGridId && currentGridId === null) {
      setGridData((prev) => {
        const newState = prev.map((record) => {
          if (record.Id === deleteGridId) {
            return {
              ...record,
              IsActive: false,
            };
          }
          return record;
        });
        return newState;
      });

      setOgFilledAttributeSet((prev) => {
        const newState = prev.map((record) => {
          if (record.Id === deleteGridId) {
            return {
              ...record,
              IsActive: false,
            };
          }
          return record;
        });
        return newState;
      });

      setDisplayGrid((prev) =>
        prev
          .filter((record) => record.Id !== deleteGridId)
          .map((record, index) => ({ ...record, SQQUESEQ: index + 1 }))
      );

      const isMandatory = displayGrid?.some((item) => item.MQAAJ === true);

      if (deleteGridId > 0 && isMandatory) {
        //Delete from table

        (async () => await deteteScreeningQuestionData(deleteGridId))();
      }
    }
    setDeleteGridId(null);
  }, [deleteGridId]);

  useEffect(() => {
    // keep grid and ogData seqNo in sync
    if (ogFilledAttributeSet.length !== displayGrid.length) {
      setOgFilledAttributeSet((prev) => {
        const newState = prev.map((record) => {
          const existingData = displayGrid.find((r) => r.Id == record.Id);
          if (existingData !== undefined) {
            return {
              ...record,
              SQQUESEQ: existingData.SQQUESEQ,
            };
          }
          return record;
        });
        return newState;
      });

      setGridData((prev) => {
        const newState = prev.map((record) => {
          const existingData = displayGrid.find((r) => r.Id == record.Id);
          if (existingData !== undefined) {
            return {
              ...record,
              SQQUESEQ: existingData.SQQUESEQ,
            };
          }
          return record;
        });
        return newState;
      });
    }
  }, [displayGrid.length, ogFilledAttributeSet.length]);

  const deteteScreeningQuestionData = async (id) => {
    const response = await APICall(
      deleteManpowerRequisitionScreeningQuestion,
      "POST",
      {
        TId: id,
      }
    );

    if (response?.status) {
    }
  };

  const gridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    // count: gridData.length,
    rowsPerPage: gridData.length,
    // page: page,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",

    //standard | vertical | simple
    onChangeRowsPerPage: (num) => {},

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
      }
      if (sortDirection === "desc") {
      }
    },
    onChangePage: async (page) => {},
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const validateData = async (Attribute) => {
    return new Promise((resolve) => {
      let errorObj = {};
      let isError = false;
      Attribute.forEach(async (subAttribute) => {
        if (subAttribute.AT === "Attribute") {
          const validation = JSON.parse(subAttribute.V);

          // if (subAttribute.AC === "SQQUESEQ") {
          //   if (attributeSetData[subAttribute.AC] === "") {
          //     errorObj = {
          //       ...errorObj,
          //       [subAttribute.AC]: "Required",
          //     };
          //   } else if (
          //     !/^([0-9]+|0)$/.test(attributeSetData[subAttribute.AC])
          //   ) {
          //     errorObj = {
          //       ...errorObj,
          //       [subAttribute.AC]: "Only numbers are allowed",
          //     };
          //   } else {
          //     errorObj = {
          //       ...errorObj,
          //       [subAttribute.AC]: "",
          //     };
          //   }
          // } else
          if (subAttribute.AC === "SQQUES") {
            if (attributeSetData[subAttribute.AC] === "") {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          } else if (subAttribute.AC === "SQTYOVAL") {
            if (attributeSetData[subAttribute.AC] === null) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          } else if (subAttribute.AC === "SQTXTLBL") {
            if (showTextLabel) {
              if (attributeSetData[subAttribute.AC] === "") {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Required",
                };
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }
            }
          }
          // else if (subAttribute.AC === "SQCT") {
          //   //Added By Santosh
          //   if (attributeSetData[subAttribute.AC] === null) {
          //     errorObj = {
          //       ...errorObj,
          //       [subAttribute.AC]: "Required",
          //     };
          //   } else {
          //     errorObj = {
          //       ...errorObj,
          //       [subAttribute.AC]: "",
          //     };
          //   }
          // }
        }
      });

      const isEmpty = Object.values(errorObj).every((s) => s === "");

      if (Object.keys(errorObj).length > 0 && !isEmpty) {
        isError = true;
        setFormErrors((err) => ({
          ...err,
          ...errorObj,
        }));
      }

      resolve(isError);
    });
  };

  const handleSaveForGrid = async (e) => {
    e.stopPropagation();

    if (TId === 0 && section.SN !== "RequisitionDetails") {
      notify(1, "Save Requisition Details Data First");
    } else {
      let errorObj = {};
      section?.Attribute?.map((eachAttribute) => {
        if (eachAttribute.AT === "Attribute") {
          const validation = attributeValidation[eachAttribute.AC];

          if (validation?.ISM?.toLowerCase() === "true") {
            if (eachAttribute.DT === "Text") {
              if (attributesData[eachAttribute.AC] === "") {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: "Required",
                };
              } else if (
                attributesData[eachAttribute.AC]?.length > validation.MaxC
              ) {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: `Max ${validation.MaxC} characters allowed`,
                };
              } else {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: "",
                };
              }
            } else if (eachAttribute.DT === "DropdownSingle") {
              if (attributesData[eachAttribute.AC] === null) {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: "Required",
                };
              } else {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: "",
                };
              }
            } else if (eachAttribute.DT === "Date") {
              if (attributesData[eachAttribute.AC] === null) {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: "Required",
                };
              } else if (
                moment(
                  validation.MaxV === "CurrentDate" &&
                    attributesData[eachAttribute.AC]
                ).isAfter(new Date())
              ) {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: "Only till current date allowed",
                };
              } else if (
                moment(
                  validation.MinV === "CurrentDate" &&
                    attributesData[eachAttribute.AC]
                ).isBefore()
              ) {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: "Only after current date allowed",
                };
              } else if (
                validation.MinV !== undefined &&
                validation.MinV !== "CurrentDate" &&
                validation.MinV !== ""
              ) {
                let parentValue = attributesData[validation.MinV];
                if (
                  parentValue !== null &&
                  parentValue !== "" &&
                  attributesData[eachAttribute.AC] !== null &&
                  attributesData[eachAttribute.AC] !== "" &&
                  moment(attributesData[eachAttribute.AC]).isBefore(parentValue)
                ) {
                  errorObj = {
                    ...errorObj,
                    [eachAttribute.AC]: "Past date not allowed",
                  };
                } else if (
                  (parentValue === null || parentValue === "") &&
                  attributesData[eachAttribute.AC] !== null &&
                  attributesData[eachAttribute.AC] !== ""
                ) {
                  errorObj = {
                    ...errorObj,
                    [validation.MinV]: "Required",
                  };
                }
              } else {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: "",
                };
              }
            } else if (eachAttribute.DT === "Number") {
              //change with regex

              if (attributesData[eachAttribute.AC] === "") {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: "Required",
                };
              } else if (
                // isNaN(attributesData[eachAttribute.AC])
                !/^([0-9]+|0)$/.test(attributesData[eachAttribute.AC])
              ) {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: "Only numbers are allowed",
                };
              } else if (
                Number(attributesData[eachAttribute.AC]) > validation.MaxV
              ) {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: `Max ${validation.MaxV} allowed`,
                };
              } else if (
                Number(attributesData[eachAttribute.AC]) < validation.MinV
              ) {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: `Min ${validation.MinV} required`,
                };
              } else {
                errorObj = {
                  ...errorObj,
                  [eachAttribute.AC]: "",
                };
              }
            }
          } else {
            // for num and date

            if (eachAttribute.DT === "Number") {
              if (attributesData[eachAttribute.AC] !== "") {
                //change with regex
                if (
                  // isNaN(attributesData[eachAttribute.AC])
                  !/^([0-9]+|0)$/.test(attributesData[eachAttribute.AC])
                ) {
                  errorObj = {
                    ...errorObj,
                    [eachAttribute.AC]: "Only numbers are allowed",
                  };
                } else if (
                  Number(attributesData[eachAttribute.AC]) > validation.MaxV
                ) {
                  errorObj = {
                    ...errorObj,
                    [eachAttribute.AC]: `Max ${validation.MaxV} allowed`,
                  };
                } else if (
                  Number(attributesData[eachAttribute.AC]) < validation.MinV
                ) {
                  errorObj = {
                    ...errorObj,
                    [eachAttribute.AC]: `Min ${validation.MinV} required`,
                  };
                } else {
                  errorObj = {
                    ...errorObj,
                    [eachAttribute.AC]: "",
                  };
                }
              }
            } else if (eachAttribute.DT === "Date") {
              if (attributesData[eachAttribute.AC] !== null) {
                if (
                  moment(
                    validation.MaxV === "CurrentDate" &&
                      attributesData[eachAttribute.AC]
                  ).isAfter(new Date())
                ) {
                  errorObj = {
                    ...errorObj,
                    [eachAttribute.AC]: "Only till current date allowed",
                  };
                } else if (
                  moment(
                    validation.MinV === "CurrentDate" &&
                      attributesData[eachAttribute.AC]
                  ).isBefore()
                ) {
                  errorObj = {
                    ...errorObj,
                    [eachAttribute.AC]: "Only after current date allowed",
                  };
                } else if (
                  validation.MinV !== undefined &&
                  validation.MinV !== "CurrentDate" &&
                  validation.MinV !== ""
                ) {
                  let parentValue = attributesData[validation.MinV];
                  if (
                    parentValue !== null &&
                    parentValue !== "" &&
                    attributesData[eachAttribute.AC] !== null &&
                    attributesData[eachAttribute.AC] !== "" &&
                    moment(attributesData[eachAttribute.AC]).isBefore(
                      parentValue
                    )
                  ) {
                    errorObj = {
                      ...errorObj,
                      [eachAttribute.AC]: "Past date not allowed",
                    };
                  } else if (
                    (parentValue === null || parentValue === "") &&
                    attributesData[eachAttribute.AC] !== null &&
                    attributesData[eachAttribute.AC] !== ""
                  ) {
                    errorObj = {
                      ...errorObj,
                      [validation.MinV]: "Required",
                    };
                  }
                } else {
                  errorObj = {
                    ...errorObj,
                    [eachAttribute.AC]: "",
                  };
                }
              }
            }
          }
        } else {
          //submit attributeSet
        }
      });

      const isEmpty = Object.values(errorObj).every((s) => s === "");

      if (isEmpty && attributeSetCode === null) {
        await showLoader();
        // post data attribute
        const postAttributes = [];

        Object.entries(attributesData).forEach(async (attributeData) => {
          const [Code, Value]: any[] = attributeData;

          let attribute = {
            Type: "Attribute",
            Code,
            Value:
              typeof Value === "object"
                ? Value?.value.toString() || null
                : typeof Value === "string" || typeof Value === "boolean"
                ? Value
                : null,
          };
          postAttributes.push(attribute);
        });

        let postObj = {
          MN: "ManpowerRequisition",
          IN: "ManpowerRequisition_Form",
          TN: formData.tn,
          SN: section.SN,
          TId: TId || 0,
          Attributes: postAttributes,
        };

        const postRes = await APICall(
          postAttributeValueDetailsV2,
          "POST",
          postObj
        );

        if (
          postRes.data !== null &&
          section.SN === "RequisitionDetails" &&
          postRes.data.id !== undefined
        ) {
          setTId(postRes.data.id);
        }

        await hideLoader();
        notify(postRes.status, postRes.message);
      } else if (isEmpty && attributeSetCode !== null) {
        await showLoader();
        // post data attributeSet

        let details = [...ogFilledAttributeSet];

        let finalDetails = [];
        details.forEach(async (attributes) => {
          let attributeObj = {};
          Object.keys(attributes).forEach(async (attribute) => {
            attributeObj = {
              ...attributeObj,
              [attribute]: attributes[attribute],
            };

            if (attribute === "Id" && isNaN(Number(attributes[attribute]))) {
              attributeObj = {
                ...attributeObj,
                [attribute]: 0,
              };
            }
          });
          finalDetails.push(attributeObj);
        });

        // to remove new entry but deleted
        finalDetails = finalDetails.filter(
          (record) => (record.Id === 0 && record.IsActive) || record.Id > 0
        );

        let postObj = {
          MN: "ManpowerRequisition",
          IN: "ManpowerRequisition_Form",
          TN: formData.tn,
          SN: section.SN,
          TId: TId || 0,
          details: finalDetails,
        };

        const postRes = await APICall(postMRScreeningQuestion, "POST", postObj);
        if (postRes?.status == 0) {
          await GetScreeningDataV2();
        }
        await hideLoader();
        notify(postRes.status, postRes.message);
      }

      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }
  };

  const handleAddOrUpdateRecord = async (e, eachAttribute) => {
    const targetId = e.currentTarget.id;

    if (targetId === "0") {
      //insert
      const uniqueId = uuid();

      //validation for empty data before adding in grid
      const isError = await validateData(eachAttribute.Attribute);

      if (isError) return;

      // let isSequenceExist = false;

      // if (attributeSetData?.SQQUESEQ !== null) {
      //   isSequenceExist = displayGrid.some(
      //     (seqence) => seqence?.SQQUESEQ === attributeSetData?.SQQUESEQ
      //   );

      //   if (isSequenceExist) {
      //     notify(1, "Sequence number already exist.");
      //     return;
      //   }
      // }

      let checkAnswerIsEmpty = answerSet.some((answer) => {
        const sqans = answer?.SQANS;
        return sqans === null || sqans === undefined || sqans?.trim() === "";
      });
      if (checkAnswerIsEmpty) {
        notify(1, "Answer Cannot be empty.");
        return;
      }

      if (attributeSetData?.SQTYOVAL?.code === "ExceptionRestricted") {
        let isDesiredAnswerSelected = false;
        isDesiredAnswerSelected = answerSet.some(
          (answer) => !!answer?.SQCODANS
        );

        if (!isDesiredAnswerSelected) {
          notify(1, "Please select atleast one desired answer.");
          return;
        }
      }

      setOgFilledAttributeSet((prev) => [
        ...prev,
        {
          ...attributeSetData,
          Id: uniqueId,
          IsActive: true,
          answerSet,
        },
      ]);

      let gridData = {};

      eachAttribute.Attribute.forEach(async (subAttribute) => {
        if (typeof attributeSetData[subAttribute.AC] === "object") {
          gridData = {
            ...gridData,
            [subAttribute.AC]: attributeSetData[subAttribute.AC]?.label || null, //for date and dropdown
          };
        } else if (typeof attributeSetData[subAttribute.AC] === "boolean") {
          gridData = {
            ...gridData,
            [subAttribute.AC]:
              attributeSetData[subAttribute.AC] === true ? "true" : "false",
          };
        } else {
          gridData = {
            ...gridData,
            [subAttribute.AC]: attributeSetData[subAttribute.AC],
          };
        }
      });

      //reset
      setAttributeSetData((prev) => ({
        ...prev,
        ...ogAttributeSet,
      }));

      setFormErrors({});

      setGridData((prev) => [
        ...prev,
        {
          Id: uniqueId,
          ...gridData,
          IsActive: true,
          answerSet,
        },
      ]);

      let tempDisplayGrid = [
        ...displayGrid,
        {
          Id: uniqueId,
          ...gridData,
          IsActive: true,
          answerSet,
        },
      ];

      tempDisplayGrid = tempDisplayGrid?.sort(
        (a, b) => a?.SQQUESEQ - b.SQQUESEQ
      );

      setDisplayGrid(tempDisplayGrid);

      setAnswerSet([answerSetObj]);
    } else {
      const isError = await validateData(eachAttribute.Attribute);

      if (!isError) {
        let checkAnswerIsEmpty = answerSet.some((answer) => {
          const sqans = answer?.SQANS;
          return sqans === null || sqans === undefined || sqans?.trim() === "";
        });
        if (checkAnswerIsEmpty) {
          notify(1, "Answer Cannot be empty.");
          return;
        }

        if (attributeSetData?.SQTYOVAL?.code === "ExceptionRestricted") {
          let isDesiredAnswerSelected = false;
          isDesiredAnswerSelected = answerSet.some(
            (answer) => !!answer?.SQCODANS
          );

          if (!isDesiredAnswerSelected) {
            notify(1, "Please select atleast one desired answer.");
            return;
          }
        }

        setOgFilledAttributeSet((prev) => {
          const newState = prev.map((record) => {
            if (record.Id === currentGridId) {
              return {
                ...attributeSetData,
                answerSet,
              };
            }
            return record;
          });
          return newState;
        });

        let gridData = {};

        eachAttribute.Attribute.forEach(async (subAttribute) => {
          if (typeof attributeSetData[subAttribute.AC] === "object") {
            gridData = {
              ...gridData,
              [subAttribute.AC]:
                attributeSetData[subAttribute.AC]?.label || null, //for date and dropdown
            };
          } else if (typeof attributeSetData[subAttribute.AC] === "boolean") {
            gridData = {
              ...gridData,
              [subAttribute.AC]:
                attributeSetData[subAttribute.AC] === true ? "true" : "false",
            };
          } else {
            gridData = {
              ...gridData,
              [subAttribute.AC]: attributeSetData[subAttribute.AC],
            };
          }
        });

        //reset
        setAttributeSetData((prev) => ({
          ...prev,
          ...ogAttributeSet,
        }));

        setFormErrors({});

        setGridData((prev) => {
          const newState = prev.map((record) => {
            if (record.Id === currentGridId) {
              return {
                Id: currentGridId,
                ...gridData,
                answerSet,
              };
            }
            return record;
          });
          return newState;
        });

        let tempDisplayGrid = [
          ...displayGrid,
          {
            ...gridData,
            IsActive: true,
            answerSet,
          },
        ];

        tempDisplayGrid = tempDisplayGrid?.sort(
          (a, b) => a?.SQQUESEQ - b.SQQUESEQ
        );

        setDisplayGrid((prev) => {
          const newState = prev.map((record) => {
            if (record.Id === currentGridId) {
              return {
                Id: currentGridId,
                ...gridData,
                answerSet,
              };
            }
            return record;
          });
          return newState;
        });

        setAnswerSet([answerSetObj]);

        setCurrentGridId(null);
      }
    }
  };

  return (
    <div className="col-lg-12">
      <Accordion
        hidden={hideSectionAndAttribute}
        elevation={0}
        className="mb-3"
        expanded={accordion}
        onChange={() => setAccordion((prev) => !prev)}>
        <AccordionSummary
          id="profile-accordion"
          style={{ background: "#3C5464" }}
          className="text-white acc_close"
          expandIcon={<ExpandMore />}>
          <div className="row d-flex align-items-center acc-heading">
            <div className="col-lg-10 col-md-10 col-sm-12">
              <p style={{ fontSize: "16px" }}>{section.SDN}</p>
            </div>
            <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12">
              <Tooltip title="clear">
                <button
                  className="btn ml-lg-0 ml-md-0 ml-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    //clear data

                    section?.Attribute?.forEach(async (eachAttribute) => {
                      if (eachAttribute.AT === "Attribute") {
                        //change value according to dt

                        setAttributesData((prev) => ({
                          ...prev,
                          [eachAttribute.AC]:
                            eachAttribute.DT === "DropdownSingle" ||
                            eachAttribute.DT === "Date"
                              ? null
                              : eachAttribute.DT === "Text" ||
                                eachAttribute.DT === "Number"
                              ? ""
                              : eachAttribute.DT === "Checkbox"
                              ? false
                              : "",
                        }));
                      } else if (eachAttribute.AT === "AttributeSet") {
                        setAttributeSetData(ogAttributeSet);
                        setAnswerSet([answerSetObj]);
                      }
                    });

                    //clear error
                    setFormErrors({});
                  }}>
                  <i className="fas fa-times-circle"></i>
                </button>
              </Tooltip>
              <Tooltip title="submit">
                <button
                  className="btn"
                  disabled={
                    sendback
                      ? false
                      : disableAttribute == true
                      ? true
                      : currentGridId !== null
                      ? true
                      : false
                  }
                  onClick={handleSaveForGrid}>
                  <i className="fa fa-save"></i>
                </button>
              </Tooltip>
            </div>
          </div>
        </AccordionSummary>

        <AccordionDetails className="page_heading">
          <div className="row">
            {section?.Attribute?.length > 0 &&
              answerSet.length > 0 &&
              section?.Attribute?.map((eachAttribute, index) => {
                return (
                  <React.Fragment key={`${index}-${eachAttribute.AC}`}>
                    {eachAttribute.AT === "AttributeSet" &&
                    eachAttribute.DT === "Custom" ? (
                      <div className="col-lg-12">
                        {section?.ISEDIT && (
                          <div className="col-lg-12 mt-2 mb-3 d-flex justify-content-end">
                            <Tooltip title="Add">
                              <button
                                id={"addRec"}
                                className="btn btn-primary"
                                onClick={() => {
                                  if (ShowAttrSet) {
                                    setAttributeSetData((prev) => ({
                                      ...prev,
                                      ...ogAttributeSet,
                                    }));
                                    setCurrentGridId(null);
                                    setViewOnly(false);
                                  }
                                  setShowAttrSet(!ShowAttrSet);
                                }}>
                                <i className={"fa fa-plus"}></i> Add
                              </button>
                            </Tooltip>
                          </div>
                        )}
                        {ShowAttrSet && (
                          <div className="row">
                            {eachAttribute.Attribute.length > 0 &&
                              eachAttribute.Attribute.map(
                                (subAttribute, index) => {
                                  return (
                                    <React.Fragment
                                      key={`${index}-${subAttribute.AC}`}>
                                      {(subAttribute.AC === "SQQUES" ||
                                        subAttribute.AC === "SQTYOVAL") && (
                                        // subAttribute.AC === "SQCT"
                                        <>
                                          {subAttribute.DT ===
                                          "DropdownSingle" ? (
                                            <>
                                              <div
                                                className={
                                                  JSON.parse(
                                                    subAttribute.AD
                                                  )?.IFR?.toLowerCase() ===
                                                  "true"
                                                    ? "col-lg-12 col-sm-12 col-xs-12 "
                                                    : "col-lg-3 col-sm-3 col-xs-4"
                                                }>
                                                <div className="mb-1">
                                                  <label className="col-form-label">
                                                    {subAttribute.AN}
                                                    {JSON.parse(
                                                      subAttribute.V
                                                    )?.ISM?.toLowerCase() ===
                                                      "true" && <sup>*</sup>}
                                                  </label>

                                                  <SelectForm
                                                    isClearable
                                                    isSearchable
                                                    options={
                                                      optionsObj[
                                                        subAttribute.AC
                                                      ]
                                                        ? optionsObj[
                                                            subAttribute.AC
                                                          ]
                                                        : []
                                                    }
                                                    placeholder={
                                                      subAttribute.AN
                                                    }
                                                    isDisabled={
                                                      sendback
                                                        ? false
                                                        : disableAttribute ==
                                                          true
                                                        ? true
                                                        : false ||
                                                          !section.ISEDIT ||
                                                          viewOnly
                                                    }
                                                    value={
                                                      attributeSetData[
                                                        subAttribute.AC
                                                      ]
                                                        ? attributeSetData[
                                                            subAttribute.AC
                                                          ]
                                                        : null
                                                    }
                                                    onChange={(event) => {
                                                      setAttributeSetData(
                                                        (prev) => ({
                                                          ...prev,
                                                          [subAttribute.AC]:
                                                            event,
                                                        })
                                                      );

                                                      if (
                                                        event !== null &&
                                                        subAttribute.CC !==
                                                          null &&
                                                        subAttribute.CC !== ""
                                                      ) {
                                                        const CC =
                                                          subAttribute.CC.split(
                                                            ","
                                                          );

                                                        CC.forEach(
                                                          async (
                                                            childDropdown
                                                          ) => {
                                                            setAttributeSetData(
                                                              (prev) => ({
                                                                ...prev,
                                                                [childDropdown]:
                                                                  null,
                                                              })
                                                            );

                                                            const options =
                                                              await getDropdownsData(
                                                                childDropdown,
                                                                event.value
                                                              );

                                                            setOptionsObj(
                                                              (prev) => ({
                                                                ...prev,
                                                                [childDropdown]:
                                                                  options,
                                                              })
                                                            );
                                                          }
                                                        );
                                                      }
                                                    }}
                                                    isMulti={false}
                                                    noIndicator={false}
                                                    noSeparator={false}
                                                  />
                                                  {formErrors[
                                                    subAttribute.AC
                                                  ] && (
                                                    <p style={{ color: "red" }}>
                                                      {
                                                        formErrors[
                                                          subAttribute.AC
                                                        ]
                                                      }
                                                    </p>
                                                  )}
                                                </div>
                                              </div>
                                            </>
                                          ) : subAttribute.DT === "Text" ||
                                            subAttribute.DT === "Number" ? (
                                            <>
                                              <div
                                                className={
                                                  JSON.parse(
                                                    subAttribute.AD
                                                  )?.IFR?.toLowerCase() ===
                                                  "true"
                                                    ? "col-lg-12 col-sm-12 col-xs-12 "
                                                    : subAttribute.AC ===
                                                      "SQQUES"
                                                    ? "col-lg-6 col-sm-3 col-xs-4"
                                                    : "col-lg-3 col-sm-3 col-xs-4"
                                                }>
                                                <div className="mb-1">
                                                  <label className="col-form-label">
                                                    {subAttribute.AN}
                                                    {JSON.parse(
                                                      subAttribute.V
                                                    )?.ISM?.toLowerCase() ===
                                                      "true" && <sup>*</sup>}
                                                  </label>

                                                  <InputForm
                                                    className="form-control"
                                                    placeholder={
                                                      subAttribute.AN
                                                    }
                                                    isDisabled={
                                                      sendback
                                                        ? false
                                                        : disableAttribute ==
                                                          true
                                                        ? true
                                                        : false ||
                                                          !section.ISEDIT ||
                                                          viewOnly
                                                    }
                                                    textArea={false}
                                                    value={
                                                      attributeSetData[
                                                        subAttribute.AC
                                                      ]
                                                        ? attributeSetData[
                                                            subAttribute.AC
                                                          ]
                                                        : ""
                                                    }
                                                    onChange={(e) => {
                                                      setAttributeSetData(
                                                        (prev) => ({
                                                          ...prev,
                                                          SQQUESEQ:
                                                            displayGrid.length +
                                                            1,
                                                          [subAttribute.AC]:
                                                            e.target.value,
                                                        })
                                                      );
                                                    }}
                                                    maxLength="255"
                                                  />
                                                  {formErrors[
                                                    subAttribute.AC
                                                  ] && (
                                                    <p style={{ color: "red" }}>
                                                      {
                                                        formErrors[
                                                          subAttribute.AC
                                                        ]
                                                      }
                                                    </p>
                                                  )}
                                                </div>
                                              </div>
                                            </>
                                          ) : subAttribute.DT === "Date" ? (
                                            <>
                                              <div
                                                className={
                                                  JSON.parse(
                                                    subAttribute.AD
                                                  )?.IFR?.toLowerCase() ===
                                                  "true"
                                                    ? "col-lg-12 col-sm-12 col-xs-12 "
                                                    : "col-lg-3 col-sm-3 col-xs-4"
                                                }>
                                                <div className="mb-1">
                                                  <label className="col-form-label">
                                                    {subAttribute.AN}
                                                    {JSON.parse(
                                                      subAttribute.V
                                                    )?.ISM?.toLowerCase() ===
                                                      "true" && <sup>*</sup>}
                                                  </label>

                                                  <DateForm
                                                    isDisabled={
                                                      sendback
                                                        ? false
                                                        : disableAttribute ==
                                                          true
                                                        ? true
                                                        : false ||
                                                          !section.ISEDIT ||
                                                          viewOnly
                                                    }
                                                    value={
                                                      attributeSetData[
                                                        subAttribute.AC
                                                      ]
                                                        ? attributeSetData[
                                                            subAttribute.AC
                                                          ]
                                                        : null
                                                    }
                                                    onChange={(date) => {
                                                      setAttributeSetData(
                                                        (prev) => ({
                                                          ...prev,
                                                          [subAttribute.AC]:
                                                            moment(date).format(
                                                              "DD-MMM-YYYY"
                                                            ),
                                                        })
                                                      );
                                                    }}
                                                  />
                                                  {formErrors[
                                                    subAttribute.AC
                                                  ] && (
                                                    <p style={{ color: "red" }}>
                                                      {
                                                        formErrors[
                                                          subAttribute.AC
                                                        ]
                                                      }
                                                    </p>
                                                  )}
                                                </div>
                                              </div>
                                            </>
                                          ) : subAttribute.DT === "Checkbox" ? (
                                            <>
                                              <div
                                                className={
                                                  JSON.parse(
                                                    subAttribute.AD
                                                  )?.IFR?.toLowerCase() ===
                                                  "true"
                                                    ? "col-lg-12 col-sm-12 col-xs-12 "
                                                    : "col-lg-3 col-sm-3 col-xs-4"
                                                }>
                                                <div className="mb-1">
                                                  <label
                                                    htmlFor={subAttribute.AC}
                                                    className="col-form-label">
                                                    {subAttribute.AN}
                                                    {JSON.parse(
                                                      subAttribute.V
                                                    )?.ISM?.toLowerCase() ===
                                                      "true" && <sup>*</sup>}
                                                  </label>

                                                  <div>
                                                    <input
                                                      disabled={
                                                        sendback
                                                          ? false
                                                          : disableAttribute ==
                                                            true
                                                          ? true
                                                          : false ||
                                                            !section.ISEDIT ||
                                                            viewOnly
                                                      }
                                                      type="checkbox"
                                                      onChange={(e) => {
                                                        setAttributeSetData(
                                                          (prev) => ({
                                                            ...prev,
                                                            [subAttribute.AC]:
                                                              e.target.checked,
                                                          })
                                                        );
                                                      }}
                                                      id={subAttribute.AC}
                                                      checked={
                                                        attributeSetData[
                                                          subAttribute.AC
                                                        ]
                                                          ? attributeSetData[
                                                              subAttribute.AC
                                                            ]
                                                          : false
                                                      }
                                                    />
                                                  </div>
                                                  {formErrors[
                                                    subAttribute.AC
                                                  ] && (
                                                    <p style={{ color: "red" }}>
                                                      {
                                                        formErrors[
                                                          subAttribute.AC
                                                        ]
                                                      }
                                                    </p>
                                                  )}
                                                </div>
                                              </div>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      )}
                                    </React.Fragment>
                                  );
                                }
                              )}
                          </div>
                        )}

                        {ShowAttrSet && (
                          <div className="row">
                            {answerSet.length > 0 &&
                              answerSet.map((subAttribute, index) => (
                                <React.Fragment key={`${index}}`}>
                                  <div className={"col-lg-5 col-sm-6 col-xs-4"}>
                                    <div className="mb-1">
                                      <label className="col-form-label">
                                        Answer <sup>*</sup>
                                      </label>
                                      <InputForm
                                        className="form-control"
                                        placeholder="Answer"
                                        isDisabled={
                                          sendback
                                            ? false
                                            : disableAttribute == true
                                            ? true
                                            : false ||
                                              !section.ISEDIT ||
                                              viewOnly
                                        }
                                        textArea={false}
                                        value={subAttribute.SQANS}
                                        onChange={(e) => {
                                          setAnswerSet((prev) =>
                                            prev.map((ele, zindex) =>
                                              zindex === index
                                                ? {
                                                    ...ele,
                                                    SQANS: e.target.value,
                                                  }
                                                : ele
                                            )
                                          );
                                        }}
                                        maxLength="255"
                                      />
                                      {formErrors[`${index}-SQANS`] && (
                                        <p style={{ color: "red" }}>
                                          {formErrors[`${index}-SQANS`]}
                                        </p>
                                      )}
                                    </div>
                                  </div>

                                  <div className={"col-lg-2 col-sm-2 col-xs-4"}>
                                    <div className="mb-1">
                                      <label
                                        htmlFor={`${index}-SQCODANS`}
                                        className="col-form-label">
                                        Correct desired answer
                                        <sup>*</sup>
                                      </label>

                                      <div>
                                        <input
                                          disabled={
                                            sendback
                                              ? false
                                              : disableAttribute == true
                                              ? true
                                              : false ||
                                                !section.ISEDIT ||
                                                viewOnly
                                          }
                                          id={`${index}-SQCODANS`}
                                          type="radio"
                                          onChange={(e) => {
                                            setAnswerSet((prev) =>
                                              prev.map((ele, zindex) =>
                                                zindex === index
                                                  ? {
                                                      ...ele,
                                                      SQCODANS:
                                                        e.target.checked,
                                                    }
                                                  : {
                                                      ...ele,
                                                      SQCODANS:
                                                        !e.target.checked,
                                                    }
                                              )
                                            );
                                          }}
                                          checked={subAttribute.SQCODANS}
                                        />
                                      </div>
                                      {/* {formErrors[subAttribute.radio] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.radio]}
                                              </p>
                                            )} */}
                                    </div>
                                  </div>

                                  <div className={"col-lg-2 col-sm-2 col-xs-4"}>
                                    <div className="mb-1">
                                      <label
                                        htmlFor={`${index}-SQADDTXTB`}
                                        className="col-form-label">
                                        Additional textbox
                                        <sup>*</sup>
                                      </label>

                                      <div>
                                        <input
                                          id={`${index}-SQADDTXTB`}
                                          disabled={
                                            sendback
                                              ? false
                                              : disableAttribute == true
                                              ? true
                                              : false ||
                                                !section.ISEDIT ||
                                                viewOnly
                                          }
                                          type="checkbox"
                                          onChange={(e) => {
                                            setAnswerSet((prev) =>
                                              prev.map((ele, zindex) =>
                                                zindex === index
                                                  ? {
                                                      ...ele,
                                                      SQADDTXTB:
                                                        e.target.checked,
                                                    }
                                                  : ele
                                              )
                                            );
                                          }}
                                          checked={subAttribute.SQADDTXTB}
                                        />
                                      </div>
                                      {/* {formErrors[subAttribute.checkbox] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.checkbox]}
                                              </p> */}
                                    </div>
                                  </div>

                                  {/* {index > 0 && ( */}
                                  <div className={"col-lg-2 col-sm-2 col-xs-4"}>
                                    <div className="mb-1">
                                      <label
                                        htmlFor={`${index}-SQADDTXTB`}
                                        className="col-form-label"></label>

                                      <div>
                                        <span
                                          className="col-lg-2"
                                          onClick={() => {
                                            if (answerSet.length === 1) {
                                              notify(
                                                1,
                                                "Atleast one answer is required!"
                                              );
                                              return null;
                                            } else {
                                              const newArray = answerSet.filter(
                                                (_, i) => i !== index
                                              );
                                              setAnswerSet(newArray);
                                            }
                                          }}>
                                          <i
                                            className="fa fa-trash"
                                            aria-hidden="true"></i>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  {/* )} */}
                                </React.Fragment>
                              ))}
                          </div>
                        )}

                        {ShowAttrSet && (
                          <div className="row">
                            <div className="col-lg-3 mt-2">
                              <button
                                id={currentGridId === null ? "0" : "1"}
                                className="btn btn-primary"
                                disabled={
                                  sendback
                                    ? false
                                    : disableAttribute == true
                                    ? true
                                    : false || !section.ISEDIT || viewOnly
                                }
                                onClick={(e) =>
                                  setAnswerSet((prev) => [
                                    ...prev,
                                    answerSetObj,
                                  ])
                                }>
                                <i className={"fa fa-plus"}></i> Add Answer
                              </button>
                              {/* <button
                              className="btn btn-primary"
                              onClick={resetAnswerSet}>
                              Reset
                            </button> */}
                            </div>
                          </div>
                        )}

                        {showTextLabel && (
                          <div className="row">
                            {eachAttribute.Attribute.length > 0 &&
                              eachAttribute.Attribute.map(
                                (subAttribute, index) => (
                                  <React.Fragment
                                    key={`${index}-${subAttribute.AC}`}>
                                    {subAttribute.AC === "SQTXTLBL" && (
                                      <>
                                        {subAttribute.DT === "Text" ||
                                        subAttribute.DT === "Number" ? (
                                          <>
                                            <div
                                              className={
                                                JSON.parse(
                                                  subAttribute.AD
                                                )?.IFR?.toLowerCase() === "true"
                                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                                  : "col-lg-6 col-sm-6 col-xs-4"
                                              }>
                                              <div className="mb-1">
                                                <label className="col-form-label">
                                                  {subAttribute.AN}
                                                  {JSON.parse(
                                                    subAttribute.V
                                                  )?.ISM?.toLowerCase() ===
                                                    "true" && <sup>*</sup>}
                                                </label>

                                                <InputForm
                                                  className="form-control"
                                                  placeholder={subAttribute.AN}
                                                  isDisabled={
                                                    sendback
                                                      ? false
                                                      : disableAttribute == true
                                                      ? true
                                                      : false ||
                                                        !section.ISEDIT ||
                                                        viewOnly
                                                  }
                                                  textArea={false}
                                                  value={
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                      ? attributeSetData[
                                                          subAttribute.AC
                                                        ]
                                                      : ""
                                                  }
                                                  onChange={(e) => {
                                                    setAttributeSetData(
                                                      (prev) => ({
                                                        ...prev,
                                                        [subAttribute.AC]:
                                                          e.target.value,
                                                      })
                                                    );
                                                  }}
                                                  maxLength="255"
                                                />
                                                {formErrors[
                                                  subAttribute.AC
                                                ] && (
                                                  <p style={{ color: "red" }}>
                                                    {
                                                      formErrors[
                                                        subAttribute.AC
                                                      ]
                                                    }
                                                  </p>
                                                )}
                                              </div>
                                            </div>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    )}
                                  </React.Fragment>
                                )
                              )}
                          </div>
                        )}

                        <div className="row">
                          {ShowAttrSet && (
                            <div className="col-lg-3 mt-2 mb-3">
                              <button
                                id={currentGridId === null ? "0" : "1"}
                                className="btn btn-primary"
                                disabled={
                                  sendback
                                    ? false
                                    : disableAttribute == true
                                    ? true
                                    : false || !section.ISEDIT || viewOnly
                                }
                                onClick={async (e) => {
                                  await handleAddOrUpdateRecord(
                                    e,
                                    eachAttribute
                                  );
                                }}>
                                <i
                                  className={
                                    currentGridId === null
                                      ? "fa fa-plus"
                                      : "fas fa-edit"
                                  }></i>{" "}
                                {currentGridId === null
                                  ? "Save"
                                  : "Update Record"}
                              </button>
                            </div>
                          )}

                          {ShowAttrSet &&
                            eachAttribute.Attribute.length > 0 &&
                            eachAttribute.Attribute.map(
                              (subAttribute, index) => (
                                <React.Fragment
                                  key={`${index}-${subAttribute.AC}`}>
                                  {subAttribute.DT === "Checkbox" &&
                                    subAttribute.AC === "MQAAJ" && (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            )?.IFR?.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }>
                                          <div className="mb-1">
                                            <label
                                              htmlFor={subAttribute.AC}
                                              className="col-form-label">
                                              {subAttribute.AN}
                                              {JSON.parse(
                                                subAttribute.V
                                              )?.ISM?.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <div>
                                              <input
                                                disabled={
                                                  sendback
                                                    ? false
                                                    : disableAttribute == true
                                                    ? true
                                                    : false ||
                                                      !section.ISEDIT ||
                                                      viewOnly
                                                }
                                                type="checkbox"
                                                onChange={(e) => {
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      [subAttribute.AC]:
                                                        e.target.checked,
                                                    })
                                                  );
                                                }}
                                                id={subAttribute.AC}
                                                checked={
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ]
                                                    ? attributeSetData[
                                                        subAttribute.AC
                                                      ]
                                                    : false
                                                }
                                              />
                                            </div>
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    )}
                                </React.Fragment>
                              )
                            )}

                          <div className="col-lg-12 p-0 mb-3">
                            <DynamicGrid
                              options={gridOptions}
                              data={displayGrid}
                              columns={gridColumns}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </React.Fragment>
                );
              })}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ScreeningQuestion;
