import React, { useContext, useEffect, useState } from "react";
import { APICall } from "../../Helpers/APICalls";
import {
  insetEmailTemplate,
  getEmailTempleteDetailsById,
} from "../../Helpers/APIEndPoints/EndPoints";
import "./EmailTemplate.css";
import SelectForm from "../../Components/SelectForm/SelectForm";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import notify from "../../Helpers/ToastNotification";
import { LoaderContext } from "../../Helpers/Context/Context";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import InputForm from "../../Components/InputForm/InputForm";
import { useNavigate } from "react-router-dom";
import RTE from "../../Components/RTE/RTE";

const EmailTemplate = () => {
  const [panal1, setPanal1] = useState(true);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { userDetails, currentRoleId }: any = useUserContext();
  const { state } = useLocation();
  const [UId, setUId] = useState(state !== null && state.UId ? state.UId : 0);
  const navigate = useNavigate();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [emailNameValue, setEmailNameValue] = useState("");
  const [emailNameError, setEmailNameError] = useState("");

  const [systemName, setSystemName] = useState("");
  const [systemNameError, setSystemNameError] = useState("");

  const [subjectValue, setSubjectValue] = useState("");
  const [subjectValueError, setSubjectValueError] = useState("");

  const [rteContent, setRteContent] = useState("");
  const [emailBodyError, setEmailBodyError] = useState("");
  const [isSystem, setIsSystem] = useState(true);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    getEmailTempId();
  }, [emailNameValue, systemName]);

  const getEmailTempId = async () => {
    showLoader();
    if (
      state !== null &&
      state?.UId > 0 &&
      emailNameValue.length === 0 &&
      systemName.length === 0
    ) {
      try {
        const GetEmailTempResp = await APICall(
          getEmailTempleteDetailsById,
          "POST",
          {
            Id: state?.UId,
          }
        );
        const EmailTempData = GetEmailTempResp.data;
        setEmailNameValue(EmailTempData.name);
        setSystemName(EmailTempData.systemName);
        setSubjectValue(EmailTempData.subject);
        setIsSystem(EmailTempData.isSystem || false);
        setIsActive(EmailTempData.isActive || false);
        setRteContent(EmailTempData.emailBody);
        setIsFormSubmitted(true);
        hideLoader();
      } catch (error) {
        console.error("Error fetching user data:", error);
        hideLoader();
      }
    }
    hideLoader();
  };

  //EmailTemplate Name
  const handleemailNameChange = (event) => {
    setEmailNameValue(event.target.value);
    setEmailNameError("");
  };

  //System name
  const handleSystemChange = (event) => {
    if (event.target.value.includes(" ")) {
      const updatedValue = event.target.value.replace(/\s/g, "");
      setSystemName(updatedValue);
    } else {
      setSystemName(event.target.value);
    }
    setSystemNameError("");
  };

  //Subject
  const handleSubjectChange = (event) => {
    setSubjectValue(event.target.value);
    setSubjectValueError("");
  };

  //Email Body
  const handleRTEBlur = (content) => {
    setRteContent(content);
    setEmailBodyError("");
  };

  //Is System
  const handleIsSystemChange = () => {
    setIsSystem(!isSystem);
  };

  //Is Active
  const handleIsActiveChange = () => {
    setIsActive(!isActive);
  };

  //Clear form
  const handleClear = () => {
    setEmailNameError("");
    setSystemNameError("");
    setSubjectValueError("");
    setEmailBodyError("");
  };

  //Submit form
  const handleSubmit = async () => {
    debugger;
    let isValid = true;
    if (!emailNameValue) {
      setEmailNameError("Please Enter Name.");
      isValid = false;
    }
    if (!systemName) {
      setSystemNameError("Please Enter System Name.");
      isValid = false;
    }
    if (!subjectValue) {
      setSubjectValueError("Please Enter Subject");
      isValid = false;
    }
    if (!rteContent) {
      setEmailBodyError("Please Enter Body");
    }
    if (isValid) {
      showLoader();
      const response = await APICall(insetEmailTemplate, "POST", {
        Id: state?.UId || 0,
        Name: emailNameValue,
        SystemName: systemName,
        Subject: subjectValue,
        EmailBody: rteContent,
        IsActive: isActive,
        IsSystem: isSystem,
        CreatedBy: userDetails.Id,
      });

      if (response.data.isSaved == 1) {
        notify(0, `${response.message}.`);
        //   setIsFormSubmitted(true);
        setPanal1(true);
        navigate("/emailTemplateDashboard");
      } else {
        notify(1, `${response.message}`);
        //   setIsFormSubmitted(true);
        setPanal1(true);
      }
      hideLoader();
    }
  };

  return (
    <>
      <div className="container-fluid">
        <button
          onClick={() => {
            navigate("/emailTemplateDashboard ");
          }}
          className="btn btn-primary my-2"
        >
          <i className="fa fa-left-long mr-2"></i>
          Back
        </button>
        <Accordion
          className="mb-3"
          expanded={panal1}
          onChange={() => setPanal1((prev) => !prev)}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            style={{ background: "#3C5464" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}
          >
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <Typography>Email Template</Typography>
              </div>
              <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12">
                <Tooltip title="Submit">
                  <button
                    className="btn"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    <i className="fa fa-save"></i>
                  </button>
                </Tooltip>
                <Tooltip title="Clear">
                  <button
                    className="btn"
                    onClick={() => {
                      handleClear();
                    }}
                  >
                    <i className="fa-solid fa-arrow-rotate-left"></i>
                  </button>
                </Tooltip>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row">
              {/*Name */}
              <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown1">
                    Email Template <sup>*</sup>
                  </label>
                  <InputForm
                    id="dropdown1"
                    textArea={false}
                    value={emailNameValue}
                    placeholder="Enter Your Email Template"
                    onChange={handleemailNameChange}
                    isDisabled={false}
                    // isDisabled={isFormSubmitted}
                  />
                  {emailNameError && (
                    <p className="text-danger">{emailNameError}</p>
                  )}
                </div>
              </div>

              {/* system name */}
              <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown2">
                    System Name <sup>*</sup>
                  </label>
                  <InputForm
                    value={systemName}
                    placeholder="Enter Your Syatem Name"
                    textArea={false}
                    onChange={handleSystemChange}
                    isDisabled={isFormSubmitted}
                  />
                  {systemNameError && (
                    <p className="text-danger">{systemNameError}</p>
                  )}
                </div>
              </div>

              {/* Subject */}
              <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown3">
                    Subject <sup>*</sup>
                  </label>
                  <InputForm
                    id="dropdown3"
                    textArea={false}
                    value={subjectValue}
                    placeholder="Enter Your subject"
                    onChange={handleSubjectChange}
                    isDisabled={false}
                    // isDisabled={isFormSubmitted}
                  />
                  {subjectValueError && (
                    <p className="text-danger">{subjectValueError}</p>
                  )}
                </div>
              </div>

              {/* Is Active */}
              <div className="col-md-3 p-0 d-flex justify-content-around align-items-center acc_btn">
                <div className="form-group d-flex flex-column-reverse justify-content-start align-items-start">
                  <label className="col-form-label mr-2" htmlFor="dropdown4">
                    Is Active
                  </label>
                  <input
                    type="checkbox"
                    id="dropdown4"
                    checked={isActive}
                    onChange={handleIsActiveChange}
                    disabled={state?.UId ? false : true}
                  />
                </div>
                <div className="form-group d-flex flex-column-reverse justify-content-start align-items-start">
                  <label className="col-form-label mr-2" htmlFor="dropdown5">
                    Is System
                  </label>
                  <input
                    type="checkbox"
                    id="dropdown5"
                    checked={isSystem}
                    onChange={handleIsSystemChange}
                    // disabled={true}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              {/* Email Body */}
              <div className="col-md-12">
                <div className="form-group">
                  <label className="col-form-label">
                    Email Body <sup>*</sup>
                  </label>
                  <RTE
                    content={rteContent}
                    onblur={handleRTEBlur}
                    placeholder="Start typing..."
                    disabled={false}
                  />
                  {emailBodyError && (
                    <p className="text-danger">{emailBodyError}</p>
                  )}
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};
export default EmailTemplate;
