import { create } from "zustand";

const useDataChangeStore = create((set) => ({
  sectionsData: {},
  tempOldSectionData: {},
  oldJsonSectionData: {},

  sendBackJson: {},

  setSectionsData: (data, IsSendBack = false) => {
    if (IsSendBack) {
      set((state) => ({
        sendBackJson: { ...state.sendBackJson, ...data },
      }));
    } else {
      set((state) => ({
        sectionsData: { ...state.sectionsData, ...data },
      }));
    }
  },

  emptySectionData: (IsSendBack = false) => {
    if (IsSendBack) {
      set({ sendBackJson: {} });
    } else {
      set({ sectionsData: {} });
    }
  },

  updateSectionData: (data, IsSendBack = false) => {
    if (IsSendBack) {
      set({ sendBackJson: data });
    } else {
      set({ sectionsData: data });
    }
  },

  // old Json
  updateOldJson: (data) =>
    set((state) => ({
      tempOldSectionData: { ...state.tempOldSectionData, ...data },
    })),
  setOldJson: (data) => set({ oldJsonSectionData: data }),

  emptySectionAndJsonData: (IsSendBack = false) => {
    if (IsSendBack) {
      set({ oldJsonSectionData: {} });
    } else {
      set({ oldJsonSectionData: [], sectionsData: {} });
    }
  },

  setSectionDataAndJsonData: (data, IsSendBack = false) => {
    if (IsSendBack) {
      set((state) => ({
        sendBackJson: { ...state.sendBackJson, ...data },
      }));
    } else {
      set((state) => ({
        sectionsData: { ...state.sectionsData, ...data },
        tempOldSectionData: { ...state.sectionsData, ...data },
      }));
    }
  },
}));

export default useDataChangeStore;
