import React, { useContext, useEffect, useState } from "react";
import { LoaderContext } from "../../../Helpers/Context/Context";
import { APICall } from "../../../Helpers/APICalls";
import {
  getApproverDetailsForProccessByMRFId,
  getDropdowns,
  getSearchableDropdowns,
  getStaticStagesandResponsibilityData,
  getTAInputData,
  insertIntoStagesandResponsibility,
} from "../../../Helpers/APIEndPoints/EndPoints";
import moment from "moment";
import DynamicGrid from "../../../Components/DynamicGrid/DynamicGrid";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import { ExpandMore, Feedback } from "@mui/icons-material";
import notify from "../../../Helpers/ToastNotification";
import SelectForm from "../../../Components/SelectForm/SelectForm";
import InputForm from "../../../Components/InputForm/InputForm";
import DateForm from "../../../Components/DateForm/DateForm";
import uuid from "react-uuid";
import { useLocation } from "react-router-dom";
import useUserContext from "../../../Helpers/ZustandStore/UserContextStore";
import async from "react-select/dist/declarations/src/async/index";
import { type } from "os";

const StagesAndResponsibility = ({ TId, IsMRFOpen }) => {
  interface IStagesData {
    Id?: number;
    StageName?: string;
    Mode?: any;
    ResponsiblePersonNames?: string;
    ResponsiblePersons?: any;
    MinimumEvaluators?: number;
    ScheduledByName?: string;
    ScheduledById?: string;
    Feedback?: string;
    StaticSystemName?: string;
    Stage?: any;
    StaticStagesSystemName?: string;
    OriginalResponsiblePersonName?: string;
    ModeName?: string;
    ResponsiblePersonUsers?: string;
  }

  interface IOption {
    label?: string;
    value?: number;
  }

  let { userDetails, currentRoleId }: any = useUserContext();

  const { state } = useLocation();

  const [accordion, setAccordion] = useState(true);
  const [optionsObj, setOptionsObj] = useState<any>({});
  const [gridData, setGridData] = useState([]);

  const [ogFilledAttributeSet, setOgFilledAttributeSet] = useState([]);
  const [currentGridId, setCurrentGridId] = useState(0);
  const [deleteGridId, setDeleteGridId] = useState(null);

  // value Object for section
  const [attributesData, setAttributesData] = useState<any>({});
  const [attributeSetData, setAttributeSetData] = useState<any>({});
  const [attributeSetCode, setAttributeSetCode] = useState(null);
  const [isOnce, setIsOnce] = useState(true);
  const [isValidateOnce, setIsValidateOnce] = useState(true);
  const [attributeValidation, setAttributeValidation] = useState<any>({});
  const [attributeSetValidation, setAttributeSetValidation] = useState<any>({});
  const [dataFilled, setDataFilled] = useState(false);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [fillDDValuesForGrid, setFillDDValuesForGrid] = useState([]);

  const [ShowAttrSet, setShowAttrSet] = useState(false);
  const [viewOnly, setViewOnly] = useState(false);

  const [formValues, setFormValues] = useState<IStagesData>({});
  const [recruiter, setRecruiter] = useState<IOption[]>([]);
  const [stagesData, setStagesData] = useState<IStagesData[]>([]);
  const [disableForm, setDisableForm] = useState(false);
  const [isDataStoredAlready, setIsDataStoredAlready] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await fetchRecruiterData();
      await fetchStaticStagesAndResponsibilityAsync();
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (currentGridId && currentGridId != 0) {
      let filterStaged = stagesData?.find((i) => i?.Id == currentGridId);

      setFormValues((prev) => {
        return {
          ...prev,
          Id: currentGridId,
          Stage: {
            value: 0,
            label: filterStaged?.StageName,
          },
          ResponsiblePersons: filterStaged?.ResponsiblePersons,
          MinimumEvaluators: filterStaged?.MinimumEvaluators,
          Feedback: filterStaged?.Feedback,
        };
      });
    }
  }, [currentGridId]);

  const parseJSON = (jsonString) => {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      return [];
    }
  };

  const fetchStaticStagesAndResponsibilityAsync = async () => {
    try {
      showLoader();

      const response = await APICall(
        getStaticStagesandResponsibilityData,
        "POST",
        {
          MRFId: TId ? TId : 0,
        }
      );

      if (response?.status == 0 && response?.data?.length > 0) {
        const responseData = response?.data || [];

        let firstIndexData = responseData[0];

        if (firstIndexData && firstIndexData?.isDataStored) {
          setDisableForm(!IsMRFOpen);
          setIsDataStoredAlready(true);
        }

        let arr = responseData.map((data) => {
          return {
            Id:
              firstIndexData && firstIndexData?.isDataStored
                ? data?.id
                : data?.staticStagesandResponsibilityId,
            StaticStagesSystemName: data?.StaticStagesSystemName,
            Stage: data?.stage,
            StageName: data?.activityName,
            ModeName: data?.modeName,
            MinimumEvaluators: data?.minEvaluators,
            ScheduledByName: data?.scheduledByName,
            Feedback: data?.feedBackUpload,
            OriginalResponsiblePersonName: data?.responsiblePersonName,
            ResponsiblePersonNames: data?.responsiblePersonName,
            ResponsiblePersons: parseJSON(data?.responsiblePersonUsers),
            ResponsiblePersonUsers: data?.responsiblePersonUsers,
          };
        });

        setStagesData(arr);
        setGridData(arr);
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  const gridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    // count: gridData.length,
    rowsPerPage: gridData.length,
    // page: page,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",

    //standard | vertical | simple
    onChangeRowsPerPage: (num) => {
      //   setLimit(num);
      //   setNxtPgInfo("");
      //   setPrevPgInfo("");
      //   setIsPrevOrNext("");
    },
    // onSearchChange: (searchText) => {
    //   if (searchText !== null) {
    //     setSearchText(searchText);
    //   } else {
    //     setSearchText("");
    //   }
    // },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        // await setDashboardSortColumn(sortColumn);
        // await setDashboardSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        // await setDashboardSortColumn(sortColumn);
        // await setDashboardSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      // await setPage(page);
      // await setDashboardStart(page * dashboardPageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const ShowEditOnStage = [
    "FirstInterview",
    "SecondInterview",
    "FinalInterview",
  ];

  const stagesGridColumn = [
    {
      name: "Id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "StaticStagesSystemName",
      label: "StaticStagesSystemName",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "Stage",
      label: "Stage",
      options: {
        filter: false,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta) => {
          if (value % 1 === 0) {
            value = parseInt(value);
          }
          return <>{value}</>;
        },
      },
    },
    {
      name: "StageName",
      label: "Stage name",
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: "ModeName",
      label: "Mode name",
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: "ResponsiblePersonNames",
      label: "Responsible Persons",
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: "ScheduledByName",
      label: "Conducted by",
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    // {
    //   name: "MinimumEvaluators",
    //   label: "Min Evaluators",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     display: true,
    //   },
    // },
    {
      name: "Feedback",
      label: "Feedback",
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },

    {
      name: "",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta) => {
          let Id = tableMeta.tableData[tableMeta.rowIndex].Id;
          let staticStage =
            tableMeta.tableData[tableMeta.rowIndex].StaticStagesSystemName;

          const IsEdit = ShowEditOnStage?.includes(staticStage);

          if (IsEdit) {
            return (
              <>
                <div className="d-flex justify-content-center">
                  <Tooltip title="edit">
                    <a
                      className="mr-2"
                      onClick={(e) => {
                        e.preventDefault();
                        setCurrentGridId(Id);
                        setShowAttrSet(true);
                      }}
                    >
                      <i
                        className={`fas fa-${disableForm ? "eye" : "edit"}`}
                      ></i>
                    </a>
                  </Tooltip>
                </div>
              </>
            );
          } else {
            return <></>;
          }
        },
      },
    },
  ];

  const fetchRecruiterData = async () => {
    try {
      showLoader();

      const response = await APICall(
        getApproverDetailsForProccessByMRFId,
        "POST",
        {
          MRFId: TId,
        }
      );

      if (response?.status === 0 && response?.data?.length > 0) {
        const responseData = response?.data[0];

        if (responseData?.RecruiterSelectedOption?.length > 0) {
          const recruiterOption = JSON.parse(
            responseData?.RecruiterSelectedOption
          );

          setRecruiter(recruiterOption);
        }
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  const handleUpdateRecord = () => {
    try {
      if (stagesData?.length == 0 || currentGridId == 0) return;

      if (
        formValues?.ResponsiblePersons?.length == 0 ||
        formValues?.ResponsiblePersons == null ||
        formValues?.ResponsiblePersons == undefined
      ) {
        notify(1, " Interview Panel cannot be empty.");
        return;
      } else if (
        formValues?.Feedback == null ||
        formValues?.Feedback == undefined ||
        (typeof formValues.Feedback == "string" &&
          formValues.Feedback.length == 0)
      ) {
        notify(1, "Please select Feedback.");
        return;
      }

      const uniqueData = {};

      const filteredResponsisblerPerson = [
        ...recruiter,
        ...formValues?.ResponsiblePersons,
      ].filter((item) => {
        if (!uniqueData[item.value]) {
          uniqueData[item.value] = true;
          return true;
        }
        return false;
      });

      let ResponsiblePersonName = filteredResponsisblerPerson
        ?.map((i) => i?.label)
        .join(" , ");

      let arr = stagesData?.map((data) => {
        if (data?.Id == currentGridId) {
          data.Feedback = formValues?.Feedback;
          data.MinimumEvaluators = formValues?.MinimumEvaluators;
          data.ResponsiblePersons = formValues?.ResponsiblePersons;
          data.ResponsiblePersonNames = ResponsiblePersonName;
          data.ScheduledByName = recruiter?.map((i) => i?.label).join(" ,");
        }

        return data;
      });

      setStagesData(arr);
      handleCancelGrid();
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const handleInputChangeForApp = async (e, cb) => {
    try {
      if (e?.length > 3) {
        let filterString = "";
        if (formValues && formValues?.ResponsiblePersons?.length > 0) {
          let selectedUsersId = [...formValues.ResponsiblePersons, ...recruiter]
            .filter((i) => i.value)
            .map((i) => i.value);

          filterString = selectedUsersId.join(",");
        }
        showLoader();
        const response = await APICall(getTAInputData, "POST", {
          textInput: e,
          FilterString: filterString,
          IsAdhocApprover: true,
        });

        if (response?.data?.length > 0) {
          cb(response?.data);
        } else {
          throw new Error("API request failed.");
        }
      }
    } catch (error) {
      console.error("Error fetching API data:", error);
    } finally {
      await hideLoader();
    }
  };

  const handleCancelGrid = () => {
    try {
      setFormValues((prev) => {
        return {
          ...prev,
          Id: 0,
          Stage: null,
          ResponsiblePersons: [],
          MinimumEvaluators: 0,
          Feedback: null,
        };
      });
      setCurrentGridId(0);
      setShowAttrSet(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.stopPropagation();
      showLoader();

      if (stagesData?.length == 0) return;

      let isError = false;
      let message = "";

      let filterStages = stagesData.filter((stage) =>
        ShowEditOnStage.includes(stage?.StaticStagesSystemName)
      );

      for await (const stage of filterStages) {
        // if (
        //   stage?.MinimumEvaluators == 0 ||
        //   stage?.MinimumEvaluators == null ||
        //   stage?.MinimumEvaluators == undefined
        // ) {
        //   notify(1, "Minimum Evaluators Required.");
        //   isError = true;
        //   break;
        // } else
        if (
          stage?.ResponsiblePersons?.length == 0 ||
          stage?.ResponsiblePersons == null ||
          stage?.ResponsiblePersons == undefined
        ) {
          notify(1, "Interview Panel cannot be empty.");
          isError = true;
          break;
        } else if (
          stage?.Feedback == null ||
          stage?.Feedback == undefined ||
          (typeof stage.Feedback == "string" && stage.Feedback.length == 0)
        ) {
          notify(1, "Please select Feedback.");
          isError = true;
          break;
        }
        // else if (
        //   stage?.ResponsiblePersons?.length < stage?.MinimumEvaluators
        // ) {
        //   notify(
        //     1,
        //     "Minimum Evaluators cannot be greather than selected responsible person."
        //   );
        //   isError = true;
        //   break;
        // }
      }

      if (!isError) {
        // let arr = stagesData?.map((stage) => {
        //   if (ShowEditOnStage.includes(stage?.StaticStagesSystemName)) {
        //     let responsiblePerson = [...stage.ResponsiblePersons];
        //     stage.ResponsiblePersons = responsiblePerson;
        //   }

        //   return stage;
        // });

        let dataObj = stagesData?.map((i) => {
          return {
            MRFId: TId,
            TId: TId,
            UserId: userDetails.Id,
            Feedback: i.Feedback,
            MinimumEvaluators: i?.MinimumEvaluators,
            OriginalResponsiblePersonName: i.OriginalResponsiblePersonName,
            ResponsiblePersons:
              i.ResponsiblePersons?.map((i) =>
                !Number.isNaN(i?.value) ? Number(i?.value) : 0
              ) || [],
            Stage: !Number.isNaN(i.Stage) ? Number(i.Stage) : 0,
            ModeName: i?.ModeName,
            Id: i.Id,
            ResponsiblePersonNames: i?.ResponsiblePersonNames,
            Recruiters: recruiter,
          };
        });

        const response = await APICall(
          insertIntoStagesandResponsibility,
          "POST",
          { StagesData: dataObj, TId: TId, UserId: userDetails?.Id || 0 }
        );

        if (response?.status == 0) {
          await fetchStaticStagesAndResponsibilityAsync();
          notify(0, "Record Saved Successfully.");
        } else {
          notify(1, "Something went wrong.");
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <div className="col-lg-12">
      <div>
        <Accordion
          elevation={0}
          className="mb-3"
          expanded={accordion}
          onChange={() => setAccordion((prev) => !prev)}
        >
          <AccordionSummary
            id="profile-accordion"
            style={{ background: "#3C5464" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}
          >
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <p style={{ fontSize: "16px" }}>
                  Activities and Responsibilties
                </p>
              </div>
              <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12">
                <Tooltip title="clear">
                  <button
                    disabled={disableForm ? disableForm : false}
                    className="btn ml-lg-0 ml-md-0 ml-1"
                    onClick={handleCancelGrid}
                  >
                    <i className="fas fa-times-circle"></i>
                  </button>
                </Tooltip>
                <Tooltip title="submit">
                  <button
                    className="btn"
                    disabled={
                      disableForm
                        ? disableForm
                        : currentGridId != 0
                        ? true
                        : false
                    }
                    onClick={handleSubmit}
                  >
                    <i className="fa fa-save"></i>
                  </button>
                </Tooltip>
              </div>
            </div>
          </AccordionSummary>

          <AccordionDetails className="page_heading">
            <div className="row">
              {ShowAttrSet && (
                <React.Fragment>
                  <div className="col-lg-3 col-sm-3 col-xs-4">
                    <div className="mb-1">
                      <label className="col-form-label">Stages</label>
                      <InputForm
                        className="form-control"
                        placeholder={"Stages"}
                        isDisabled={true}
                        textArea={false}
                        value={formValues?.Id}
                        onChange={(e) => {}}
                        maxLength="255"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-3 col-xs-4">
                    <div className="mb-1">
                      <label className="col-form-label">Activity</label>
                      <SelectForm
                        isClearable
                        isSearchable
                        options={[]}
                        placeholder={"Activity"}
                        isDisabled={true}
                        value={formValues.Stage}
                        onChange={(event) => {}}
                        isMulti={false}
                        noIndicator={false}
                        noSeparator={false}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-3 col-xs-4">
                    <div className="mb-1">
                      <label className="col-form-label">Interview Panel</label>
                      <SelectForm
                        isClearable
                        isSearchable
                        async
                        options={(searchString, cb) => {
                          handleInputChangeForApp(searchString, cb);
                        }}
                        placeholder={"Interview Panel"}
                        isDisabled={
                          disableForm ? disableForm : currentGridId == 0
                        }
                        value={formValues.ResponsiblePersons}
                        onChange={(event) => {
                          setFormValues((prev) => {
                            return { ...prev, ResponsiblePersons: event };
                          });
                        }}
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                      />
                    </div>
                  </div>
                  {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                    <div className="mb-1">
                      <label className="col-form-label">
                        Min Resp Persons per session
                      </label>
                      <InputForm
                        value={formValues?.MinimumEvaluators}
                        placeholder={"Min Resp Persons per session"}
                        isDisabled={
                          disableForm ? disableForm : currentGridId == 0
                        }
                        textArea={false}
                        onChange={(event) => {
                          let value = event?.target?.value;

                          setFormValues((prev) => {
                            return {
                              ...prev,
                              MinimumEvaluators: value,
                            };
                          });
                        }}
                        type="number"
                      />
                    </div>
                  </div> */}

                  <div className="col-lg-3 col-sm-3 col-xs-4">
                    <div className="mb-1">
                      <label className="col-form-label">Conducted by</label>
                      <SelectForm
                        isClearable
                        isSearchable
                        options={[{ ...recruiter }]}
                        placeholder={"Conducted by"}
                        isDisabled={true}
                        value={recruiter}
                        onChange={(event) => {}}
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-3 col-xs-4">
                    <div className="mb-1">
                      <label className="col-form-label">
                        Feedback <span style={{ color: "red" }}>*</span>
                      </label>

                      <div>
                        <input
                          id="mandatory"
                          disabled={
                            disableForm ? disableForm : currentGridId == 0
                          }
                          type="radio"
                          value={"Mandatory"}
                          className="mr-1"
                          onChange={(e: any) => {
                            setFormValues((prev) => {
                              return { ...prev, Feedback: e?.target?.value };
                            });
                          }}
                          checked={formValues.Feedback == "Mandatory"}
                          name="feedbackUpload"
                        />

                        <label htmlFor="mandatory">Mandatory</label>

                        <input
                          id="feedbackUpload"
                          disabled={
                            disableForm ? disableForm : currentGridId == 0
                          }
                          type="radio"
                          className="ml-2 mr-1"
                          value={"Optional"}
                          onChange={(e: any) => {
                            setFormValues((prev) => {
                              return { ...prev, Feedback: e?.target?.value };
                            });
                          }}
                          checked={formValues.Feedback == "Optional"}
                          name="feedbackUpload"
                        />

                        <label htmlFor="feedbackUpload">Optional</label>

                        <input
                          id="na"
                          disabled={
                            disableForm ? disableForm : currentGridId == 0
                          }
                          className="ml-2 mr-1"
                          type="radio"
                          value={"NA"}
                          onChange={(e: any) => {
                            setFormValues((prev) => {
                              return { ...prev, Feedback: e?.target?.value };
                            });
                          }}
                          checked={formValues.Feedback == "NA"}
                          name="feedbackUpload"
                        />

                        <label htmlFor="na">NA</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="col-lg-3 pl-0 mt-3 mb-3">
                      <button
                        type={"button"}
                        disabled={disableForm}
                        className="btn btn-primary"
                        onClick={handleUpdateRecord}
                      >
                        Update Record
                      </button>

                      <button
                        type={"button"}
                        disabled={disableForm}
                        className="btn btn-primary ml-2"
                        onClick={handleCancelGrid}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </React.Fragment>
              )}

              <div className="col-lg-12 mt-2 mb-3 d-flex justify-content-end">
                {!disableForm && (
                  <Tooltip title="Add">
                    <button
                      id={"addRec"}
                      className="btn btn-primary"
                      onClick={() => {
                        if (ShowAttrSet) {
                          setCurrentGridId(0);
                        }
                        setShowAttrSet(!ShowAttrSet);
                      }}
                    >
                      <i className={"fa fa-plus"}></i> Add
                    </button>
                  </Tooltip>
                )}
              </div>
              <div className="col-lg-12 grid">
                <DynamicGrid
                  options={gridOptions}
                  data={gridData}
                  columns={stagesGridColumn}
                />
              </div>
            </div>
            <div className="mx-3"></div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default StagesAndResponsibility;
