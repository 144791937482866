import React, { useContext, useEffect, useState } from "react";
import { LoaderContext } from "../../Helpers/Context/Context";
import { useLocation, useNavigate } from "react-router-dom";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import InputForm from "../../Components/InputForm/InputForm";
import SelectForm from "../../Components/SelectForm/SelectForm";
import { Modal } from "react-bootstrap";
import Questions from "./Questions";
import { APICall } from "../../Helpers/APICalls";
import {
  getAssessmentDropdownList,
  getLMSDomainDropDownData,
  getManageAssessments,
  saveManageAssessments,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import notify from "../../Helpers/ToastNotification";
import AssessmentGrid from "./AssessmentGrid";

interface IOptions {
  label?: string;
  value?: number | string;
  code?: string;
}

interface IFormData {
  assessmentId?: string | any;
  assessmentName?: string | any;
  description?: string | any;
  domain?: IOptions[] | string | any;
  sequenceType?: IOptions | any;
  passingScore?: Number | any;
  passingScoreType?: IOptions | any;
  noofAttempts?: number | any;
  revealAnswer?: IOptions | any;
  questionFlowDirection?: IOptions | any;
  assessmentTime?: number | any;
}

const AssessmentEdit = () => {
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isDisable, setIsDisable] = useState(
    state && state?.isDisable ? true : false
  );
  let { userDetails, currentRoleId }: any = useUserContext();
  const [formData, setFormData] = useState<IFormData>({});
  const [formErrors, setFormErrors] = useState<IFormData>({});
  const [formOptions, setFormOptions] = useState<IFormData>({});
  const [showModal, setShowModal] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [questionId, setQuestionId] = useState(0);
  const [questionType, setQuestionType] = useState(false);
  const [assessmentGridCount, setAssessmentGridCount] = useState(0);

  useEffect(() => {
    const responseData = async () => {
      //ASSESSMENT_TYPE
      const res = await APICall(getAssessmentDropdownList, "POST", {
        Mode: "ASSESSMENT_TYPE",
      });
      if (res.data && res.data.length > 0) {
        setFormOptions((prev) => {
          return {
            ...prev,
            assessmentType: res.data,
          };
        });
      }

      //SEQUENCE_TYPE
      const res1 = await APICall(getAssessmentDropdownList, "POST", {
        Mode: "SEQUENCE_TYPE",
      });
      if (res1.data && res1.data.length > 0) {
        setFormOptions((prev) => {
          return {
            ...prev,
            sequenceType: res1.data,
          };
        });
      }

      //PASSING_TYPE
      const res2 = await APICall(getAssessmentDropdownList, "POST", {
        Mode: "PASSING_TYPE",
      });
      if (res2.data && res2.data.length > 0) {
        setFormOptions((prev) => {
          return {
            ...prev,
            passingScoreType: res2.data,
          };
        });
      }

      //REVEAL_ANSWER_LEVEL
      const res3 = await APICall(getAssessmentDropdownList, "POST", {
        Mode: "REVEAL_ANSWER_LEVEL",
      });
      if (res3.data && res3.data.length > 0) {
        setFormOptions((prev) => {
          return {
            ...prev,
            revealAnswer: res3.data,
          };
        });
      }

      //ASMTDIRECTION
      const res4 = await APICall(getAssessmentDropdownList, "POST", {
        Mode: "ASMTDIRECTION",
      });
      if (res4.data && res4.data.length > 0) {
        setFormOptions((prev) => {
          return {
            ...prev,
            questionFlowDirection: res4.data,
          };
        });
      }
    };

    showLoader();

    responseData();
    getLMSDomainDropDownDataApiCall();

    hideLoader();
  }, []);

  const getLMSDomainDropDownDataApiCall = async () => {
    const response = await APICall(getLMSDomainDropDownData, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      GetAll: state && state?.assessmentId > 0 ? true : false,
    });

    if (response?.status === 0 && response?.data.length > 0) {
      setFormOptions((prev) => {
        return {
          ...prev,
          domain: response?.data,
        };
      });
    }
  };

  const formOnChange = (e, type) => {
    switch (type) {
      case "assessmentName":
        setFormData({
          ...formData,
          assessmentName: e.target.value,
        });
        break;

      case "description":
        setFormData({
          ...formData,
          description: e.target.value,
        });
        break;

      case "domain":
        setFormData({
          ...formData,
          domain: e,
        });
        break;

      case "sequenceType":
        setFormData({
          ...formData,
          sequenceType: e,
        });
        break;

      case "passingScore":
        setFormData({
          ...formData,
          passingScore: e.target.value,
        });
        break;

      case "passingScoreType":
        setFormData({
          ...formData,
          passingScoreType: e,
        });
        break;

      case "noofAttempts":
        setFormData({
          ...formData,
          noofAttempts: e.target.value,
        });
        break;

      case "revealAnswer":
        setFormData({
          ...formData,
          revealAnswer: e,
        });
        break;

      case "questionFlowDirection":
        setFormData({
          ...formData,
          questionFlowDirection: e,
        });
        break;

      case "assessmentTime":
        setFormData({
          ...formData,
          assessmentTime: e.target.value,
        });
        break;

      default:
        break;
    }
  };

  const validateForm = () => {
    let errorObj = {};
    let isError = false;
    setFormErrors({});

    if (
      formData?.assessmentName === "" ||
      formData?.assessmentName === null ||
      formData?.assessmentName === undefined
    ) {
      errorObj = {
        ...errorObj,
        ["assessmentName"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["assessmentName"]: "",
      };
    }

    // if (
    //   formData?.description === "" ||
    //   formData?.description === null ||
    //   formData?.description === undefined
    // ) {
    //   errorObj = {
    //     ...errorObj,
    //     ["description"]: "Required",
    //   };
    // } else {
    //   errorObj = {
    //     ...errorObj,
    //     ["description"]: "",
    //   };
    // }

    if (
      formData?.domain?.length === 0 ||
      formData?.domain === null ||
      formData?.domain === undefined
    ) {
      errorObj = {
        ...errorObj,
        ["domain"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["domain"]: "",
      };
    }

    // if (
    //   formData?.assessmentType === null ||
    //   formData?.assessmentType === undefined
    // ) {
    //   errorObj = {
    //     ...errorObj,
    //     ["assessmentType"]: "Required",
    //   };
    // } else {
    //   errorObj = {
    //     ...errorObj,
    //     ["assessmentType"]: "",
    //   };
    // }

    if (
      formData?.sequenceType === null ||
      formData?.sequenceType === undefined
    ) {
      errorObj = {
        ...errorObj,
        ["sequenceType"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["sequenceType"]: "",
      };
    }

    if (
      formData?.passingScore === "" ||
      formData?.passingScore === null ||
      formData?.passingScore === undefined
    ) {
      errorObj = {
        ...errorObj,
        ["passingScore"]: "Required",
      };
    } else if (!/^([0-9]+|0)$/.test(formData?.passingScore)) {
      errorObj = {
        ...errorObj,
        ["passingScore"]: "Only numbers are allowed",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["passingScore"]: "",
      };
    }

    if (
      formData?.passingScoreType === null ||
      formData?.passingScoreType === undefined
    ) {
      errorObj = {
        ...errorObj,
        ["passingScoreType"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["passingScoreType"]: "",
      };
    }

    if (
      formData?.noofAttempts !== "" &&
      formData?.noofAttempts !== null &&
      formData?.noofAttempts !== undefined
    ) {
      if (!/^([0-9]+|0)$/.test(formData?.noofAttempts)) {
        errorObj = {
          ...errorObj,
          ["noofAttempts"]: "Only numbers are allowed",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["noofAttempts"]: "",
        };
      }
    }

    if (
      formData?.revealAnswer === null ||
      formData?.revealAnswer === undefined
    ) {
      errorObj = {
        ...errorObj,
        ["revealAnswer"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["revealAnswer"]: "",
      };
    }

    if (
      formData?.questionFlowDirection === null ||
      formData?.questionFlowDirection === undefined
    ) {
      errorObj = {
        ...errorObj,
        ["questionFlowDirection"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["questionFlowDirection"]: "",
      };
    }

    if (
      formData?.assessmentTime !== "" &&
      formData?.assessmentTime !== null &&
      formData?.assessmentTime !== undefined
    ) {
      if (!/^([0-9]+|0)$/.test(formData?.assessmentTime)) {
        errorObj = {
          ...errorObj,
          ["assessmentTime"]: "Only numbers are allowed",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["assessmentTime"]: "",
        };
      }
    }

    const isEmpty = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  const requestParamsFun = (publishStatus) => {
    const requestParams = {
      Id:
        state === null ||
        state?.assessmentId === null ||
        state?.assessmentId === undefined
          ? 0
          : state?.assessmentId,
      AssessmentName: formData?.assessmentName,
      AssessmentDesc: formData?.description,
      // AssessmentTypeId: formData?.assessmentType?.value,
      SequenceTypeId: formData?.sequenceType?.value,
      PassingScore: formData?.passingScore,
      PassingScoreTypeId: formData?.passingScoreType?.value,
      NoOfAttempts: formData?.noofAttempts,
      RevealAnswerAfterId: formData?.revealAnswer?.value,
      IsPublished: publishStatus,
      QuestionFlowDirectionId: formData?.questionFlowDirection?.value,
      AssessmentTime: formData?.assessmentTime,
      DomainIds: formData?.domain
        ? formData?.domain?.map((j) => j.value).join(",")
        : null,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    };

    return requestParams;
  };

  const handleSubmitFunction = async (publishStatus) => {
    showLoader();

    if (validateForm()) {
      hideLoader();
      return;
    }

    // const requestParams = requestParamsFun();

    // if (requestParams?.Id === 0) {
    //  await insertUpdateData();
    // } else {
    //   await insertUpdateData();
    // }

    await insertUpdateData(publishStatus);

    hideLoader();
  };

  const handlePublishedFunction = async (publishStatus) => {
    showLoader();

    if (validateForm()) {
      hideLoader();
      return;
    }

    await insertUpdateData(publishStatus);

    hideLoader();
  };

  const insertUpdateData = async (publishStatus) => {
    showLoader();

    const requestParams = requestParamsFun(publishStatus);

    const response = await APICall(
      saveManageAssessments,
      "POST",
      requestParams
    );

    if (response?.status === 0) {
      const newAssessmentId = response?.data?.id;
      notify(response?.status, response?.message);

      navigate("/assessmentEdit", {
        state: { assessmentId: newAssessmentId, isDisable: false },
      });
    } else if (response?.status === 1) {
      notify(response?.status, response?.message);
    }

    hideLoader();
  };

  useEffect(() => {
    if (
      state?.assessmentId > 0 &&
      formOptions?.sequenceType?.length > 0 &&
      formOptions?.passingScoreType?.length > 0 &&
      formOptions?.revealAnswer?.length > 0 &&
      formOptions?.questionFlowDirection?.length > 0 &&
      formOptions?.domain?.length > 0
    ) {
      getManageAssessmentsApiCall();
    }
  }, [
    formOptions?.sequenceType,
    formOptions?.passingScoreType,
    formOptions?.revealAnswer,
    formOptions?.questionFlowDirection,
    formOptions?.domain,
  ]);

  const getManageAssessmentsApiCall = async () => {
    showLoader();
    if (state?.assessmentId > 0) {
      try {
        const responseData = await APICall(getManageAssessments, "POST", {
          Id: state?.assessmentId,
        });

        if (responseData?.status === 0) {
          setIsPublished(responseData?.data?.isPublished);

          const sequenceTypeObj = formOptions?.sequenceType?.find(
            (s) => s.value === parseInt(responseData?.data?.sequenceTypeId)
          );

          const passingScoreTypeObj = formOptions?.passingScoreType?.find(
            (s) => s.value === parseInt(responseData?.data?.passingScoreTypeId)
          );

          const revealAnswerObj = formOptions?.revealAnswer?.find(
            (s) => s.value === parseInt(responseData?.data?.revealAnswerAfterId)
          );

          const revealQuestionFlowDirectionObj =
            formOptions?.questionFlowDirection?.find(
              (s) =>
                s.value ===
                parseInt(responseData?.data?.questionFlowDirectionId)
            );

          const domainIds = responseData?.data?.domainIds?.split(",") || null;

          const domainArray = [];

          if (domainIds && formOptions?.domain?.length > 0) {
            domainIds?.map((item) => {
              const domainData = formOptions?.domain?.find(
                (i) => i.value === parseInt(item)
              );
              domainArray.push(domainData);
            });
          }

          setFormData((env) => {
            return {
              ...env,
              assessmentId: responseData?.data?.assessmentCode,
              assessmentName: responseData?.data?.assessmentName,
              description: responseData?.data?.assessmentDesc,
              questionFlowDirection: revealQuestionFlowDirectionObj,
              assessmentTime: responseData?.data?.assessmentTime,
              sequenceType: sequenceTypeObj,
              passingScore: responseData?.data?.passingScore,
              passingScoreType: passingScoreTypeObj,
              noofAttempts: responseData?.data?.noOfAttempts,
              revealAnswer: revealAnswerObj,
              domain: domainArray.length > 0 ? domainArray : null,
            };
          });
        } else if (responseData?.status === 1) {
          notify(responseData?.status, responseData?.message);
        }
      } catch (error) {
        console.error(error);
      }
    }
    hideLoader();
  };

  const resetFunction = () => {
    setFormData((env) => {
      return {
        ...env,
        assessmentName: "",
        description: "",
        assessmentType: null,
        sequenceType: null,
        passingScore: "",
        passingScoreType: null,
        noofAttempts: "",
        revealAnswer: null,
        domain: null,
      };
    });
  };

  const formOnChangeForQuestion = (type) => {
    if (type === "newQuestion") {
      setQuestionType(true);
    } else if (type === "existingQuestion") {
      setQuestionType(false);
    }
  };

  const questionTypechange = (questionType, questionId) => {
    if (questionType) {
      setShowModal(true);
      formOnChangeForQuestion("newQuestion");
      setQuestionId(questionId);
    } else {
      formOnChangeForQuestion("existingQuestion");
    }
  };

  return (
    <>
      <div className="breadcrumb modified-bredcrumb">
        <span>
          <ul>
            <li>
              <a href="/home">Home</a>
            </li>
            <li>
              {" "}
              <a href="/assessmentDashboard">Assessment</a>
            </li>
            <li>Manage Assessment</li>
          </ul>
        </span>
        <button
          onClick={() => {
            navigate("/assessmentDashboard");
          }}
          disabled={false}
          className="btn btn-secondary float-right">
          <i className="fa-solid fa-arrow-left"></i> Back
        </button>
      </div>

      <div className="container-fluid">
        <div className="row">
          {/* <div className="col-md-12">
            <div className="row"> */}
          <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="mb-1">
              <label className="col-form-label">
                Assessment ID
                <sup>*</sup>
              </label>

              <InputForm
                className="form-control"
                placeholder={"Assessment ID"}
                isDisabled={true}
                textArea={false}
                value={formData.assessmentId}
                onChange={(e) => {}}
                maxLength="255"
              />
              {formErrors["assessmentId"] && (
                <p style={{ color: "red" }}>{formErrors["assessmentId"]}</p>
              )}
            </div>
          </div>
          <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="mb-1">
              <label className="col-form-label">
                Assessment Title
                <sup>*</sup>
              </label>

              <InputForm
                className="form-control"
                placeholder={"Assessment Title"}
                isDisabled={isDisable}
                textArea={false}
                value={formData?.assessmentName}
                onChange={(e) => {
                  formOnChange(e, "assessmentName");
                }}
                maxLength="255"
              />
              {formErrors["assessmentName"] && (
                <p style={{ color: "red" }}>{formErrors["assessmentName"]}</p>
              )}
            </div>
          </div>

          <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="mb-1">
              <label className="col-form-label">Description</label>

              <InputForm
                className="form-control"
                placeholder={"Description"}
                isDisabled={isDisable}
                textArea={true}
                value={formData?.description}
                onChange={(e) => {
                  formOnChange(e, "description");
                }}
                maxLength="500"
              />
              {formErrors["description"] && (
                <p style={{ color: "red" }}>{formErrors["description"]}</p>
              )}
            </div>
          </div>

          <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="dropdown mb-1">
              <label className="col-form-label">Domains</label> <sup>*</sup>
              <SelectForm
                isClearable
                isSearchable
                options={formOptions?.domain}
                isOptionDisabled={(option) => !option?.isEnabled}
                placeholder={"Domains"}
                isDisabled={isDisable}
                value={formData?.domain}
                onChange={(e) => {
                  formOnChange(e, "domain");
                }}
                isMulti={true}
                noIndicator={false}
                noSeparator={false}
              />
              {formErrors["domain"] && (
                <p style={{ color: "red" }}>{formErrors["domain"]}</p>
              )}
            </div>
          </div>

          <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="mb-1">
              <label className="col-form-label">
                Question Sequence Type
                <sup>*</sup>
              </label>

              <SelectForm
                isClearable
                isSearchable
                options={formOptions?.sequenceType}
                placeholder={"Question Sequence Type"}
                isDisabled={isDisable}
                value={formData?.sequenceType}
                onChange={async (e) => {
                  formOnChange(e, "sequenceType");
                }}
                isMulti={false}
                noIndicator={false}
                noSeparator={false}
              />
              {formErrors["sequenceType"] && (
                <p style={{ color: "red" }}>{formErrors["sequenceType"]}</p>
              )}
            </div>
          </div>

          <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="mb-1">
              <label className="col-form-label">
                Scoring Type
                <sup>*</sup>
              </label>

              <SelectForm
                isClearable
                isSearchable
                options={formOptions?.passingScoreType}
                placeholder={"Scoring Type"}
                isDisabled={isDisable}
                value={formData?.passingScoreType}
                onChange={async (e) => {
                  formOnChange(e, "passingScoreType");
                }}
                isMulti={false}
                noIndicator={false}
                noSeparator={false}
              />
              {formErrors["passingScoreType"] && (
                <p style={{ color: "red" }}>{formErrors["passingScoreType"]}</p>
              )}
            </div>
          </div>

          <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="mb-1">
              <label className="col-form-label">
                Passing Score
                <sup>*</sup>
              </label>

              <InputForm
                className="form-control"
                placeholder={"Passing Score"}
                isDisabled={isDisable}
                textArea={false}
                value={formData?.passingScore}
                onChange={(e) => {
                  formOnChange(e, "passingScore");
                }}
                maxLength="255"
              />
              {formErrors["passingScore"] && (
                <p style={{ color: "red" }}>{formErrors["passingScore"]}</p>
              )}
            </div>
          </div>

          <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="mb-1">
              <label className="col-form-label">No. of Attempts</label>

              <InputForm
                className="form-control"
                placeholder={"No. of Attempts"}
                isDisabled={isDisable}
                textArea={false}
                value={formData?.noofAttempts}
                onChange={(e) => {
                  formOnChange(e, "noofAttempts");
                }}
                maxLength="255"
              />
              {formErrors["noofAttempts"] && (
                <p style={{ color: "red" }}>{formErrors["noofAttempts"]}</p>
              )}
            </div>
          </div>

          <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="mb-1">
              <label className="col-form-label">
                Reveal Answer Level
                <sup>*</sup>
              </label>

              <SelectForm
                isClearable
                isSearchable
                options={formOptions?.revealAnswer}
                placeholder={"Reveal Answer Level"}
                isDisabled={isDisable}
                value={formData?.revealAnswer}
                onChange={async (e) => {
                  formOnChange(e, "revealAnswer");
                }}
                isMulti={false}
                noIndicator={false}
                noSeparator={false}
              />
              {formErrors["revealAnswer"] && (
                <p style={{ color: "red" }}>{formErrors["revealAnswer"]}</p>
              )}
            </div>
          </div>

          <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="mb-1">
              <label className="col-form-label">
                Question Flow Direction
                <sup>*</sup>
              </label>

              <SelectForm
                isClearable
                isSearchable
                options={formOptions?.questionFlowDirection}
                placeholder={"Reveal Answer Level"}
                isDisabled={isDisable}
                value={formData?.questionFlowDirection}
                onChange={async (e) => {
                  formOnChange(e, "questionFlowDirection");
                }}
                isMulti={false}
                noIndicator={false}
                noSeparator={false}
              />
              {formErrors["questionFlowDirection"] && (
                <p style={{ color: "red" }}>
                  {formErrors["questionFlowDirection"]}
                </p>
              )}
            </div>
          </div>

          <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="mb-1">
              <label className="col-form-label">
                Assessment Time (In Minutes)
              </label>

              <InputForm
                className="form-control"
                placeholder={"Assessment Time"}
                isDisabled={isDisable}
                textArea={false}
                value={formData?.assessmentTime}
                onChange={(e) => {
                  formOnChange(e, "assessmentTime");
                }}
                maxLength="255"
              />
              {formErrors["assessmentTime"] && (
                <p style={{ color: "red" }}>{formErrors["assessmentTime"]}</p>
              )}
            </div>
          </div>

          {state?.assessmentId > 0 && (
            <>
              <div className="col-lg-2 col-md-2 col-sm-12 mb-3">
                <label className="col-form-label">&nbsp;</label>
                {!isDisable && (
                  <>
                    <div>
                      <button
                        className="btn btn-filter-submit"
                        onClick={() => {
                          setQuestionId(0);
                          setShowModal(true);
                          setQuestionType(false);
                        }}>
                        <i className="fa fa-plus mr-2"></i>
                        Add Questions
                      </button>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>

        {state?.assessmentId > 0 && (
          <>
            <div>
              <AssessmentGrid
                showModal={showModal}
                setShowModal={setShowModal}
                questionTypechange={questionTypechange}
                setAssessmentGridCount={setAssessmentGridCount}
                assessmentGridCount={assessmentGridCount}
              />
            </div>
          </>
        )}

        <br />
        <div className="SectionSubmit mb-4 clearfix">
          {!isDisable && (
            <>
              {state?.assessmentId > 0 &&
                assessmentGridCount > 0 &&
                !isPublished && (
                  <>
                    {" "}
                    <button
                      onClick={() => {
                        handlePublishedFunction(true);
                      }}
                      disabled={false}
                      className="btn btn-filter-submit float-right ml-2">
                      <i className="fa-solid fa-check"></i> Publish
                    </button>
                  </>
                )}
            </>
          )}

          {state?.assessmentId > 0 && assessmentGridCount > 0 && (
            <>
              <button
                onClick={() => {
                  navigate("/assessment", {
                    state: {
                      assessmentId: state?.assessmentId,
                      preview: true,
                    },
                  });
                }}
                disabled={false}
                className="btn btn-filter-submit float-right ml-2">
                <i className="fas fa-eye"></i> Preview
              </button>
            </>
          )}

          {!isDisable && (
            <>
              <button
                onClick={() => {
                  resetFunction();
                }}
                disabled={false}
                className="btn btn-secondary float-right ml-2">
                <i className="fa-solid fa-refresh"></i> Reset
              </button>
              <button
                onClick={() => {
                  handleSubmitFunction(false);
                }}
                disabled={false}
                className="btn btn-filter-submit float-right ml-2">
                <i className="fa-solid fa-check"></i> Save
              </button>
            </>
          )}
        </div>
      </div>
      {/* </div>
      </div> */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">{`Manage Questions`}</div>
        </div>
        <Modal.Body>
          <>
            <div className="row">
              <Questions
                showModal={showModal}
                setShowModal={setShowModal}
                formOnChangeForQuestion={formOnChangeForQuestion}
                questionType={questionType}
                setQuestionType={setQuestionType}
                questionId={questionId}
                setQuestionId={setQuestionId}
                questionTypechange={questionTypechange}
              />
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          {/* <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => activateandDeactivateFunction(id, isActive)}
          >
            Submit
          </button> */}
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowModal(false);
            }}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AssessmentEdit;
