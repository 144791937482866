import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./GenericMaster.css";
import InputForm from "../../Components/InputForm/InputForm";
import SelectForm from "../../Components/SelectForm/SelectForm";
import { APICall } from "../../Helpers/APICalls";
import moment from "moment";
import {
  getMasterTypeDD,
  getGenericParentDDd,
  SaveGenericMasterData,
  checkGenericMasterExists,
  insertGenericMasterProgData
} from "../../Helpers/APIEndPoints/EndPoints";
import { Tooltip } from "@mui/material";
import InputRadioCheck from "../../Components/CheckBox/InputRadioCheck";
import ButtonForm from "../../Components/ButtonForm/ButtonForm";
import { getDate } from "date-fns";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { LoaderContext } from "../../Helpers/Context/Context";
import notify from "../../Helpers/ToastNotification";
import { Button } from "react-bootstrap";
import DateForm from "../../Components/DateForm/DateForm";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { color } from "html2canvas/dist/types/css/types/color";

const GenericMasterForm = ({}) => {
  const [description, setDescription] = useState<any>('');
  const [name, setname] = useState("");
  const [Code, setCode] = useState("");
  const [masterType, setMasterType] = useState<any>("");
  const [masterTypeOption, setMasterTypeOption] = useState([]);
  const [Parent, setParent] = useState<any>("");
  const [ParentName, setParentName] = useState<any>("");
  const [ParentOption, setParentOption] = useState([]);
  const [effectiveDate, setEffectiveDate] = useState(null);
  const [stateId, setstateId] = useState(0);
  const { userDetails, currentRoleId }: any = useUserContext();
  const Navigate = useNavigate();
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [formErrors, setFormErrors] = useState<any>({});
  let formErrorObj: any = {};
  const [IsParentDDShowNReq, setParentDDReq] = useState(false);
  const [IsActive, setIsActive] = useState(true);
  const [rowData, setRowData] = useState([]);   //TO Show on Grid
  const [ogData, setOgData] = useState([]);     //TO submit
  const [mtDisable, setMtDisable] = useState(false);
  const [rowsAdded, setRowsAdded] = useState(0);
  const [submitDisable, setSubmitDisable] = useState(true);
  // useEffect(() => {
  //   (async () => {
  //     //showLoader();
  //     const MTData = await APICall(getMasterTypeDD, "POST", {
  //       AC: "",
  //       PID: "",
  //     });

  //     if (MTData.data !== null && MTData.data.length > 0) {
  //       console.log("Data found!", MTData.data);
  //       let temp: any[] = [];
  //       MTData.data.map((d: any) => {
  //         let obj = {
  //           label: d.label,
  //           value: d.value,
  //         };
  //         temp.push(obj);
  //       });
  //       setMasterTypeOption(temp);
  //     } else {
  //       console.log("No tabs found!", MTData);
  //       //setTabs([]);
  //     }

  //     const ParentData = await APICall(getGenericParentDDd, "POST", {
  //       AC: "",
  //       PID: "",
  //     });

  //     if (ParentData.data !== null && ParentData.data.length > 0) {
  //       console.log("Data found!", ParentData.data);
  //       let temp: any[] = [];
  //       ParentData.data.map((d: any) => {
  //         let obj = {
  //           label: d.label,
  //           value: d.value,
  //         };
  //         temp.push(obj);
  //       });
  //       setParentOption(temp);
  //     } else {
  //       console.log("No tabs found!", ParentData);
  //       //setTabs([]);
  //     }
  //     //hideLoader();
  //   })();
  // }, []);

  useEffect(() => {
    console.log(stateId, "ddd");
    const fetchData = async () => {
      showLoader();

      await GetMasterDataDropdown();
      ////await GetParentData();

      hideLoader();
    };
    fetchData();
  }, []);

  const GetMasterDataDropdown = async () => {
    try {
      const MTData = await APICall(getMasterTypeDD, "POST", {
        AC: "",
        PID: "",
      });

      if (MTData.data !== null && MTData.data.length > 0) {
        console.log("Master Type Data found!", MTData.data);
        let temp: any[] = [];
        MTData.data.map((d: any) => {
          let obj = {
            label: d.label,
            value: d.value,
          };
          temp.push(obj);
        });
        setMasterTypeOption(temp);
      } else {
        console.log("No Master Type data found!", MTData);
        //setTabs([]);
      }
    } catch (error) {
      console.error("", error);
    } finally {
    }
  };

  const GetParentData = async (MTID) => {
    try {
      const ParentData = await APICall(getGenericParentDDd, "POST", {
        MasterTypeId: MTID == "" ? 0 : Number(MTID),
      });

      if (ParentData.data !== null && ParentData.data.length > 0) {
        console.log("Parent Data found!", ParentData.data);
        setParentName(ParentData.data[0].additionalDetails);
        let temp: any[] = [];
        ParentData.data.map((d: any) => {
          let obj = {
            label: d.label,
            value: d.value,
          };
          temp.push(obj);
        });
        setParentOption(temp);
        setParentDDReq(true);
      } else {
        console.log("No Parent Data found!", ParentData);

        let temp: any[] = [];
        setParentOption(temp);

        if (ParentData.data == true) {
          setParentDDReq(true);
        } else {
          setParentDDReq(false);
        }
        //setTabs([]);
      }
    } catch (error) {
    } finally {
    }
  };

  const handleOnChangeMT = (event) => {
    console.log(event,'EVENT::::')
    setMasterType(event);
    if(event){
      setMtDisable(true);
    }

    const fetchData = async () => {
      showLoader();
      ////await GetParentData();
      await GetParentData(event.value);
      hideLoader();
    };
    fetchData();
  };

  const ParenthandleOnChange = (event) => {
    debugger;
    console.log(event);
    setParent(event);
  };

  const handleOnChangeName = (event) => {
    setname(event.target.value);
  };

  const handleOnChangeCode = (event) => {
    setCode(event.target.value);
  };

  const CheckValidation = async () => {
    let objError: any = {};
    let error = false;
    await setFormErrors({});

    if (name.trim() === "" || name == undefined || name == null) {
      error = true;
      objError["name_isEmpty"] = "Name required";
    }
    if (Code === "" || Code == undefined || Code == null) {
      error = true;
      objError["Code_isEmpty"] = "Code is required";
    }
    if (effectiveDate === "" || effectiveDate == undefined || effectiveDate == null) {
      error = true;
      objError["error_EffectiveDate"] = "Effective Date is required";
    }
    if (
      masterType.value === "" ||
      masterType.value == undefined ||
      masterType.value == null
    ) {
      error = true;
      objError["masterType_isEmpty"] = "Please select Master Type";
    }

    if (
      IsParentDDShowNReq == true &&
      (Parent.value === "" || Parent.value == undefined || Parent.value == null)
    ) {
      error = true;
      objError["parent_isEmpty"] = "Please select Parent";
    }

    formErrorObj = objError;
    await setFormErrors(objError);
    return error;
  };
  const handleOnChangeIsActive = (event) => {
    console.log(event);
    setIsActive(event.target.checked);
  };

  const InsertUpdateActivityDetailsOld = async () => {
    let IsInValid = await CheckValidation();

    let requestParams = {
      Id: stateId,
      Name: name,
      Code: Code,
      MasterTypeId: masterType.value == "" ? 0 : Number(masterType.value),
      ParentId: Parent.value == "" ? 0 : Number(Parent.value),
      IsActive: true,
      CreatedBy: userDetails?.Id,
    };

    if (IsInValid == false) {
      const response = await APICall(
        SaveGenericMasterData, ///IT has functionality of checking and uploading data
        "POST",
        requestParams
      );

      if (response.data !== null) {
        if (response.data == 0 && response.message == "Ok") {
          if (stateId === 0) {
            notify(0, "Inserted successfully!");
            Navigate(-1);
          } else {
            notify(0, "Updated successfully!");
            Navigate(-1);
          }
        } else if (response.data == false) {
          notify(1, response.message);
        }
      } else {
        notify(1, response.message);
      }
    }
  };

  const InsertUpdateActivityDetails = async () => {
    //A function to Submit Everything
    showLoader();
    setSubmitDisable(true);

    if(ogData.length === 0)
    {
      notify(1, 'Please add some data to Grid to Submit.');
      return;
    }

    let requestbody = 
    {
      CreatedBy: userDetails?.Id,
      picklistDataList : ogData
    }
    
    const response = await APICall(
      insertGenericMasterProgData, 
      "POST", 
      requestbody
    );

    if (response.status===0) {
      notify(0, `${response.data} ${response.data == 1 ? 'record' : 'records'} submitted successfully!`); //Data submitted Successfully! 
      Navigate(-1);
    } else {
      notify(1, response.message)
    }

    setSubmitDisable(false);
    hideLoader();
  };

  useEffect(()=>{
    if(ogData.length>0){
      setSubmitDisable(false);
    } else {
      setSubmitDisable(true);
    }
  }, [ogData]);
  

  const AddDataToGrid = async () => {
    //Check Validation-----------------------------------
    let IsInValid = await CheckValidation();
    if(IsInValid)
    {
      notify(1, "Invalid Data, please check.");
      return null;
    }
    //---------------------------------------------------

    //Validation backend---------------------------------------------------
    const response = await APICall(
      checkGenericMasterExists, ///Check if mapping exists
      "POST",
      {
        Id: stateId,
        Name: name,
        Code: Code,
        MasterTypeId: masterType.value == "" ? 0 : Number(masterType.value),
        ParentId: Parent?.value == "" ? 0 : Number(Parent.value),
        IsActive: true,
        CreatedBy: userDetails?.Id,
      }
    );
    if(response.status == 1){
      notify(1, response.message);
      return;
    }
    //---------------------------------------------------------------------

    //Validation Front Grid------------------------------------------------
    let IsInValidFront = false; 
    if (ogData.length > 0)
    {
      for(const item of ogData)
      {
        if (item?.picklistCode === Code)
        {
          IsInValidFront = true
        }
      }
    }
    if(IsInValidFront === true)
    {
      notify(1, "Record of same Code already exist in Grid.");
      return;
    }
    //---------------------------------------------------------------------

    //A function To add data to grid
    let tempRowData = [...rowData];
    let tempOgData = [...ogData];
    let tempId = rowsAdded + 1;
    // if(IsParentDDShowNReq)
    // {
      tempRowData.push(
        {
          'Id' : 0,
          'delid' : tempId,
          'effectiveDate': effectiveDate,
          'MasterTypeId': masterType?.label,
          'Code': Code,
          'Name': name,
          'ParentId': Parent?.label || "",
          'description': description,
          'IsActive': 1,
        }
      );
      tempOgData.push(
        {
          'Id' : 0,
          'delid' : tempId,
          'effectiveDate': effectiveDate,
          'MasterTypeId': masterType?.value,
          'Code': Code,
          'Name': name,
          'ParentId': Parent?.value || null,
          'description': description,
          'IsActive': 1,
        }
      );
    // }
    // else
    // {
    //   tempRowData.push(
    //     {
    //       'id' : 0,
    //       'delid' : tempId,
    //       'effectiveDate': effectiveDate,
    //       'masterTypeId': masterType?.label,
    //       'Code': Code,
    //       'Name': name,
    //       'description': description,
    //       'IsActive': 1,
    //     }
    //   );
    //   tempOgData.push(
    //     {
    //       'id' : 0,
    //       'delid' : tempId,
    //       'effectiveDate': effectiveDate,
    //       'masterTypeId': masterType?.value,
    //       'Code': Code,
    //       'Name': name,
    //       'description': description,
    //       'IsActive': 1,
    //     }
    //   );
    // }
    setRowData(tempRowData);
    setOgData(tempOgData);
    setRowsAdded(rowsAdded + 1);
    // resetData(); //TO RESETT ALL FIELDS EXCEPT MASTER-TYPE         [OLD]
    afterAddingResetData(); //TO RESETT ALL FIELDS EXCEPT MASTER-TYPE [NEW]
  }

  const resetData = async()=> {
    setCode("");
    setname("");
    setEffectiveDate(null);
    setParent("");
    setDescription('');
    setMtDisable(false);
    setMasterType("");
    setParentDDReq(false);
  }

  const afterAddingResetData = async()=> {
    setCode("");
    setname("");
    setParent("");
  }

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    rowsPerPage: 10000,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical"
  };

  return (
    <>
      <div className="col-lg-12">
        <div className="row"
          style={{
            backgroundColor:"#dce6ff",
            margin: 1,
            marginTop: 15,
            borderRadius: 4,
            boxShadow: "0 3px 5px #ccc",
          }}
        >
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Effective Date<sup>*</sup>
              </label>
              <DateForm
                isDisabled={false}
                value={effectiveDate}
                onChange={(date) => {
                  if (date != null) {
                    setEffectiveDate(moment(date).format("DD-MMM-YYYY"));
                  }
                }}
              />
              <span style={{ color: "red" }}>
                {formErrors["error_EffectiveDate"]}
              </span>
            </div>
          </div>

          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Master Type<sup>*</sup>
              </label>
              <SelectForm
                isDisabled={mtDisable}
                options={masterTypeOption}
                value={masterType}
                placeholder={"Select Master Type"}
                onChange={handleOnChangeMT}
                noIndicator={false}
                noSeparator={false}
              />
              <p style={{ color: "red" }}>{formErrors["masterType_isEmpty"]}</p>
            </div>
          </div>
          
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Code<sup>*</sup>
              </label>
              <InputForm
                value={Code}
                placeholder={" Code"}
                isDisabled={false}
                onChange={handleOnChangeCode}
                textArea={false}
              />
              <p style={{ color: "red" }}>{formErrors["Code_isEmpty"]}</p>
            </div>
          </div>

          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Name<sup>*</sup>
              </label>
              <InputForm
                value={name}
                placeholder={" Name"}
                isDisabled={false}
                onChange={handleOnChangeName}
                textArea={false}
              />
              <p style={{ color: "red" }}>{formErrors["name_isEmpty"]}</p>
            </div>
          </div>

          {/* col-xs-4 */}
          <div className="col-lg-6 col-sm-12 mobile-view top-m"> 
            <div className="mb-1">
              <label className="col-form-label" id="lblParent">
                Description
              </label>
              <InputForm
                value={description ? description : ''}
                placeholder={"Type Description"}
                onChange={(e)=>{
                  setDescription(e.target.value)
                }}
                isDisabled={false}
                textArea={true}
              />
              {/* <p style={{ color: "red" }}>{formErrors["parent_isEmpty"]}</p> */}
            </div>
          </div>

          {IsParentDDShowNReq && (
            <div className="col-lg-3 col-sm-3 col-xs-4">
              <div className="mb-1">
                <label className="col-form-label" id="lblParent">
                  {ParentName || 'Parent'}<sup>*</sup>
                </label>
                <SelectForm
                  options={ParentOption}
                  value={Parent}
                  placeholder={`Select ${ParentName || 'Parent'}`}
                  onChange={ParenthandleOnChange}
                  noIndicator={false}
                  noSeparator={false}
                />
                <p style={{ color: "red" }}>{formErrors["parent_isEmpty"]}</p>
              </div>
            </div>
          )}
          
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                IsActive<sup>*</sup>
              </label>
              <div>
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={IsActive}
                  style={{ marginLeft: "0px" }}
                  onChange={handleOnChangeIsActive}
                  disabled
                />
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className="container-fluid">
        <div className="mb-3 pt-3">
          <DynamicGrid
            data={rowData}
            columns={
              IsParentDDShowNReq? [ 
                {name: 'delid', label: 'Del Id', options: {display: false}}, 
                {name: 'effectiveDate', label: 'Effective Date'}, 
                {name: 'MasterTypeId', label: 'Master Name'}, 
                {name: 'Code', label: 'Code'}, 
                {name: 'Name', label: 'Name'}, 
                {name: 'ParentId', label: 'Parent Name'},
                {name: 'action', label: 'Action', 
                  options: {
                    filter: false,
                    sort: false,
                    display: true,
                    customBodyRender: (value, tableMeta) => {
                      console.log(tableMeta);
                      let Id = tableMeta.tableData[tableMeta.rowIndex].id;
                      return (
                        <div className="d-flex justify-content-center">
                          <Tooltip title="delete">
                            <a
                              className="mr-2"
                              onClick={async(e) =>{
                                const tempRowData = [...rowData];
                                const tempOgData = [...ogData];

                                let newTempRowData = tempRowData.filter((item)=> item.id !== Id);
                                let newTempOgData = tempOgData.filter((item)=> item.id !== Id);

                                setRowData(newTempRowData);
                                setOgData(newTempOgData);
                              }}
                            >
                              <i className="fa fa-trash" aria-hidden="true"></i>
                            </a>
                          </Tooltip>
                        </div>
                      );
                    },
                  },
                },
                {name: '', label: ''},
              ] : [
                {name: 'delid', label: 'Del Id', options: {display: false}},
                {name: 'effectiveDate', label: 'Effective Date'}, 
                {name: 'MasterTypeId', label: 'Master Name'}, 
                {name: 'Code', label: 'Code'}, 
                {name: 'Name', label: 'Name'}, 
                {name: 'action', label: 'Action', 
                  options: {
                    filter: false,
                    sort: false,
                    display: true,
                    customBodyRender: (value, tableMeta) => {
                      console.log(tableMeta);
                      let DelId = tableMeta.tableData[tableMeta.rowIndex].delid;
                      return (
                        <div className="d-flex justify-content-center">
                          <Tooltip title="delete">
                            <a
                              className="mr-2"
                              onClick={async(e) =>{
                                const tempRowData = [...rowData];
                                const tempOgData = [...ogData];

                                let newTempRowData = tempRowData.filter((item)=> item.delid !== DelId);
                                let newTempOgData = tempOgData.filter((item)=> item.delid !== DelId);

                                setRowData(newTempRowData);
                                setOgData(newTempOgData);
                              }}
                            >
                              <i className="fa fa-trash" aria-hidden="true"></i>
                            </a>
                          </Tooltip>
                        </div>
                      );
                    },
                  },
                }, 
                {name: '', label: ''}, 
              ]
            }
            options={options}
          />
        </div>
      </div>

      <div className="col-lg-12 col-sm-12 col-xs-12">
        <div className="mb-1">
          <div style={{ textAlign: "end" }}>
            <Button
              className="btn btn-filter-submit float-right ml-2 btnBlue"
              onClick={InsertUpdateActivityDetails}
              disabled={submitDisable}
            >
              <i className="fa-solid fa-check"></i> Submit
            </Button>

            <button
              onClick={AddDataToGrid}
              disabled={false}
              className="btn float-right ml-2"
              style={{backgroundColor:"green", color:"white"}}
            >
              <i className="fa-solid fa-plus mr-2"></i> Add
            </button>

            <button
              onClick={resetData}
              disabled={false}
              className="btn float-right ml-2"
              style={{backgroundColor:"red", color:"white"}}
            >
              <i className="fa-solid fa-times mr-2"></i> Clear
            </button>

            <button
              onClick={() => {
                Navigate(-1);
              }}
              disabled={false}
              className="btn btn-secondary float-right"
            >
              <i className="fa-solid fa-arrow-left"></i> Back
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default GenericMasterForm;
