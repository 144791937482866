import React, { useContext, useState, useEffect } from "react";
import SelectForm from "../../Components/SelectForm/SelectForm";
import { APICall, APICallWithSignal } from "../../Helpers/APICalls";
import {
  DownloadExcelTemplate,
  GetTargetLearnerGroupDropdown,
  GetValueForDropdown,
  InsertTargetLearnerConditions,
  InsertTargetLearnerGroupName,
  deleteTargetLearnerConditions,
  getTargetLearnerConditionsDataByTargetGroupId,
  UploadEmployeeToTargetLearnerEmployeeList,
  GetTargetLearnerEmployeeListById,
  DeleteEmployeeToTargetLearnerEmployeeList,
  GenerateTargetLearnerByID,
  GetTargetLearnerEmployeeListByIdExportToExcel,
  getTargetLearnerParameters,
  getUserAndRoleWiseAccess,
  getLMSDomainDropDownData,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import { LoaderContext } from "../../Helpers/Context/Context";
import DateForm from "../../Components/DateForm/DateForm";
import { fi } from "date-fns/locale";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { Tooltip } from "@mui/material";
import uuid from "react-uuid";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { Modal } from "react-bootstrap";
import InputForm from "../../Components/InputForm/InputForm";
import notify from "../../Helpers/ToastNotification";
import moment from "moment";
import fileDownload from "js-file-download";
import {
  base64ToArrayBuffer,
  getBase64FromFilePromise,
} from "../../Helpers/Common";
import ExcelJS from "exceljs";

const conditionOptions = [
  { label: "And", value: "And" },
  { label: "Or", value: "Or" },
  { label: "Greater", value: "Greater" },
  { label: "Smaller", value: "Smaller" },
  { label: "Equal", value: "Equal" },
];

// const ParameterOptions = [
//   { label: "Business", value: "BIS", MT: "Business" },
//   { label: "Department", value: "DPT", MT: "Department" },
//   { label: "Designation", value: "DESIG" },
//   { label: "Gender", value: "GEN", MT: "Gender" },
//   { label: "SBU", value: "SBU", MT: "SBU" },
//   { label: "Job Level", value: "JL", MT: "JobLevel" },
//   { label: "Function", value: "FUNC", MT: "Function" },
//   { label: "Employee Class", value: "EC", MT: "EmployeeClass" },
//   { label: "Divsion", value: "DV", MT: "Division" },
//   { label: "User ID", value: "EmployeeCode", MT: "EmployeeCode" },
//   { label: "Group Joining Date", value: "GJD", MT: "GJD" },
//   { label: "Job Band", value: "JB", MT: "JobBand" },
//   { label: "Business Joining Date", value: "BJD", MT: "BJD" },
//   { label: "Custom Business", value: "CUS", MT: "CustomBusiness" },
// ];

interface IOptions {
  value: string | number | null;
  label: string | null;
}

interface IFormData {
  target_learner_group?: string | number | null | any;
  condition?: string | null | any;
  parameter?: string | null | any;
  values?: string | number | any;
}

const TargetLearnerGroup = () => {
  const { showLoader, hideLoader } = useContext(LoaderContext);
  let { userDetails, currentRoleId, proxyUserRoleDetails }: any =
    useUserContext();

  const [options, setOptions] = useState<any>({});
  const [formData, setFormData] = useState<IFormData>({});
  const [gridData, setGridData] = useState<any>([]);
  const [tempGridId, setTempGridId] = useState<any>(0);

  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [deleteId, setDeleteId] = useState(0);

  const [reserLearnerGroup, setReserLearnerGroup] = useState(false);

  const [targetLearnerGroupName, setTargetLearnerGroupName] = useState("");

  const [formErrors, setFormErrors] = useState<any>({});

  const [targetGroup, setTargetGroup] = useState("condition");

  const [selectedFile, setSelectedFile] = useState(null);

  const [employeeListModel, setEmployeeListModel] = useState(false);

  const [employeeListData, setEmployeeListData] = useState([]);

  const [employeeListPage, setEmployeeListPage] = useState(0);

  const [employeeListPageSize, setEmployeeListPageSize] = useState(10);

  const [employeeListSearchText, setEmployeeListSearchText] = useState("");

  const [employeeListSortColumn, setEmployeeListSortColumn] = useState("");

  const [employeeListSortDirection, setEmployeeListSortDirection] =
    useState("");

  const [employeeListCount, setEmployeeListCount] = useState(0);
  const [TargetLearnerId, setTargetLearnerId] = useState<any>("");

  const [showErrorModal, setShowErrorModal] = useState(false);

  const [domainOption, setDomainOption] = useState<any>([]);
  const [domain, setDomain] = useState(null);

  const [searchText, setSearchText] = useState("");

  const [errorData, setErrorData] = useState([]);
  interface userAndRoleWiseAccess {
    isView?: boolean;
    isEdit?: boolean;
    isDelete?: boolean;
  }

  const [userAndRoleWiseData, setUserAndRoleWiseData] =
    useState<userAndRoleWiseAccess>({});

  const getUserAndRoleWiseAccessApiCall = async () => {
    const response = await APICall(getUserAndRoleWiseAccess, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      InterfaceName: "TargetLearnerGroup_Dashboard",
    });

    if (response?.status === 0) {
      setUserAndRoleWiseData(response?.data);
    } else {
      notify(response?.status, response?.message);
    }
  };

  useEffect(() => {
    getUserAndRoleWiseAccessApiCall();
    getLMSDomainDropDownDataApiCall();
  }, []);

  useEffect(() => {
    getEmployeeListDashboardData();
  }, [
    employeeListModel,
    employeeListPage,
    employeeListPageSize,
    employeeListSearchText,
    employeeListSortColumn,
    employeeListSortDirection,
  ]);

  useEffect(() => {
    if (TargetLearnerId != "") {
      getTargetLearnerEmployeeDataByTargetGroupIdAplCall(TargetLearnerId);
    }
  }, [
    employeeListSearchText,
    employeeListSortColumn,
    employeeListSortDirection,
    employeeListPage,
    searchText,
  ]);

  const getLMSDomainDropDownDataApiCall = async () => {
    const response = await APICall(getLMSDomainDropDownData, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      GetAll: true,
    });

    if (response?.status === 0 && response?.data.length > 0) {
      setDomainOption(response?.data);
    }
  };

  const getEmployeeListDashboardData = async () => {
    showLoader();
    // if (courseId != null && courseId != 0) {
    let requestParams = {
      PageSize: employeeListPageSize,
      Page: employeeListPage,
      SortOrder: employeeListSortDirection,
      SortColumn: employeeListSortColumn,
      SearchText: employeeListSearchText,
      ProgramId: 0,
      // CourseId: courseId,
    };

    // const response = await APICall(
    //   GetEmployeeListDashboardDataForCourse,
    //   "POST",
    //   requestParams
    // );

    // if (response?.status === 0 && response?.data.length > 0) {
    //   setEmployeeListData(response?.data);
    //   setEmployeeListCount(response?.data[0]?.totalCount);
    //   hideLoader();
    // } else {
    //   setEmployeeListData([]);
    // }
    // }
    hideLoader();
  };

  const employeeListOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: employeeListCount,
    rowsPerPage: employeeListPageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setEmployeeListSortColumn(sortColumn);
        await setEmployeeListSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setEmployeeListSortColumn(sortColumn);
        await setEmployeeListSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      setEmployeeListPage(page * employeeListPageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const employeeListGridColumns = [
    {
      name: "employeeCode",
      label: "Employee Code",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "employeeFullName",
      label: "Full Name",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "sbu",
      label: "SBU",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "legalEntity",
      label: "Legal Entity",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "department",
      label: "Department",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "groupDateOfJoining",
      label: "Group Date Of Joining",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "jobBand",
      label: "Job Band",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "jobLevel",
      label: "JobLevel",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "employeeClass",
      label: "Employee Class",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "payrollLocation",
      label: "Payroll Location",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    // {
    //   name: "designation",
    //   label: "Designation",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     sortDescFirst: true,
    //   },
    // },
    // {
    //   name: "employeeDateOfJoining",
    //   label: "Date of joining",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     sortDescFirst: true,
    //   },
    // },
    // {
    //   name: "gender",
    //   label: "Gender",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     sortDescFirst: true,
    //   },
    // },
    // {
    //   name: "legalEntity",
    //   label: "Legal Entity",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     sortDescFirst: true,
    //   },
    // },
    // {
    //   name: "payrollLocation",
    //   label: "Payroll Location",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     sortDescFirst: true,
    //   },
    // },
    // {
    //   name: "employeeClass",
    //   label: "Employee Class",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     sortDescFirst: true,
    //   },
    // },
    // {
    //   name: "jobBand",
    //   label: "Job Class",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     sortDescFirst: true,
    //   },
    // },
    // {
    //   name: "jobLevel",
    //   label: "Job Level",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     sortDescFirst: true,
    //   },
    // },
    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.tableData[tableMeta.rowIndex].id;
          const target_leaner_group_id =
            tableMeta.tableData[tableMeta.rowIndex].target_leaner_group_id;

          return (
            <div style={{ width: "100px" }}>
              {userAndRoleWiseData?.isDelete && (
                <Tooltip title="delete">
                  <a
                    className="mr-2"
                    style={{ fontSize: "15px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowDeleteModal(true);
                      setDeleteId(id);
                    }}>
                    <i className="fas fa-trash"></i>
                  </a>
                </Tooltip>
              )}
            </div>
          );
        },
      },
    },
  ];

  // useEffect(() => {
  //   if (tempGridId != 0) {
  //     const filterData = gridData?.find((i) => i?.id == tempGridId);
  //   }
  // }, [tempGridId]);

  useEffect(() => {
    // const abortController = new AbortController();
    // const signal = abortController.signal;

    const fetchData = async () => {
      await fetchTargetLearnGroup();
      // await getTargetLearnerParametersApiCall();
    };

    fetchData();

    // return () => {
    //   abortController.abort();
    // };
  }, [reserLearnerGroup]);

  async function fetchTargetLearnGroup() {
    try {
      showLoader();

      const response = await APICall(GetTargetLearnerGroupDropdown, "POST", {
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      });

      if (response.status == 0 && response?.data) {
        setOptions((prev) => {
          return { ...prev, target_learner_group: response?.data };
        });
      } else {
        setOptions((prev) => {
          return {
            ...prev,
            target_learner_group: [],
          };
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  const getTargetLearnerParametersApiCall = async (value) => {
    try {
      showLoader();

      debugger;

      const response = await APICall(getTargetLearnerParameters, "POST", {});

      if (response.status == 0 && response?.data?.length > 0) {
        if (value == "Greater" || value == "Smaller" || value == "Equal") {
          const arr = response?.data?.filter((i) => i?.isDatePara === true);

          setOptions((prev) => {
            return { ...prev, ["parameter"]: arr };
          });
        } else {
          const arr = response?.data?.filter((i) => i?.isDatePara === false);

          setOptions((prev) => {
            return { ...prev, ["parameter"]: arr };
          });
        }
        // setOptions((prev) => {
        //   return { ...prev, parameter: response?.data };
        // });
      } else {
        setOptions((prev) => {
          return {
            ...prev,
            parameter: [],
          };
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  async function handleFetchValuesBasedOnParameter(code, value) {
    try {
      showLoader();

      debugger;

      const response = await APICall(GetValueForDropdown, "POST", {
        value: value,
        code: code,
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      });

      let options = [];
      if (response?.status == 0) {
        setOptions((prev) => {
          return { ...prev, values: response?.data };
        });
        options = response?.data;
      } else {
        setOptions((prev) => {
          return { ...prev, values: [] };
        });
        options = [];
      }

      return options;
    } catch (error) {
      console.error(error);
      return [];
    } finally {
      hideLoader();
    }
  }

  async function handleFetchEmployeeCode(value, cb) {
    try {
      showLoader();

      const response = await APICall(GetValueForDropdown, "POST", {
        value: value,
        code: "ECode",
      });

      let options = [];
      if (response?.status == 0) {
        options = response?.data || [];
        cb(options);
      } else {
        cb(options);
      }
    } catch (error) {
      console.error(error);
      return [];
    } finally {
      hideLoader();
    }
  }

  async function handleOnChange(event, code) {
    debugger;
    try {
      setFormData((prev) => {
        return { ...prev, [code]: event };
      });

      switch (code) {
        case "parameter":
          setFormData((prev) => {
            return { ...prev, values: null };
          });
          if (
            event?.value &&
            (event?.parameter?.value !== "GJD" ||
              event?.parameter?.value !== "BJD")
          ) {
            const response = await handleFetchValuesBasedOnParameter(
              event?.value,
              event?.masterTypeName
            );
          } else {
            setOptions((prev) => {
              return { ...prev, values: [] };
            });
          }
          break;

        case "condition":
          setFormData((prev) => {
            return { ...prev, parameter: null, values: null };
          });

          await getTargetLearnerParametersApiCall(event?.value);

          break;
        case "target_learner_group":
          targetGroup === "employeelist"
            ? await getTargetLearnerEmployeeDataByTargetGroupIdAplCall(
                event?.value
              )
            : await getTargetLearnerConditionsDataByTargetGroupIdAplCall(
                event?.value
              );

          break;

        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleOnChangeForEmployeeList = (event, code) => {
    switch (code) {
      case "file":
        const files = event.target.files[0] || null;
        if (!files) {
          setSelectedFile(null);
          notify(1, "Please select a file.");
          return;
        }

        // Check if the selected file is an Excel file
        if (!files.name.endsWith(".xls") && !files.name.endsWith(".xlsx")) {
          setSelectedFile(null);
          notify(1, "Please upload an Excel file.");
          return;
        }
        setSelectedFile(files);

        break;
      default:
        break;
    }
  };

  const gridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    // count: gridData.length,
    rowsPerPage: gridData.length,
    // page: page,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",

    //standard | vertical | simple
    onChangeRowsPerPage: (num) => {
      //   setLimit(num);
      //   setNxtPgInfo("");
      //   setPrevPgInfo("");
      //   setIsPrevOrNext("");
    },
    // onSearchChange: (searchText) => {
    //   if (searchText !== null) {
    //     setSearchText(searchText);
    //   } else {
    //     setSearchText("");
    //   }
    // },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        // await setDashboardSortColumn(sortColumn);
        // await setDashboardSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        // await setDashboardSortColumn(sortColumn);
        // await setDashboardSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      // await setPage(page);
      // await setDashboardStart(page * dashboardPageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  // function handleSave() {
  //   try {
  //     if (tempGridId == 0) {
  //       let id = uuid();
  //       const condition = formData?.condition?.value;
  //       const parameter = formData.parameter?.value;
  //       const values = formData?.values?.join(",");
  //       const createdBy = `${userDetails?.FirstName} ${userDetails?.LastName}`;
  //       const actualParamter = formData?.parameter;
  //       const actualValues = formData?.values;
  //       const actualCondition = formData?.condition;

  //       setGridData((prev) => {
  //         return [
  //           ...prev,
  //           {
  //             id,
  //             condition,
  //             parameter,
  //             createdBy,
  //             values,
  //             modifiedBy: "",
  //             actualParamter,
  //             actualValues,
  //             actualCondition,
  //           },
  //         ];
  //       });
  //     } else {
  //       const condition = formData?.condition?.value;
  //       const parameter = formData.parameter?.value;
  //       const values = formData?.values?.join(",");

  //       const filterData = gridData?.find((i) => i?.id == tempGridId);
  //       filterData["condition"] = condition;
  //       filterData["paramter"] = parameter;
  //       filterData["values"] = values;
  //       filterData["actualParamter"] = formData?.parameter;
  //       filterData["actualValues"] = formData?.values;
  //       filterData["actualCondition"] = formData?.condition;

  //       setGridData((prev) => {
  //         return prev?.map((i) => {
  //           return i?.id == tempGridId ? filterData : prev;
  //         });
  //       });
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  const gridColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "condition",
      label: "Condition",
      options: {
        filter: false,
        sort: false,
        width: 200,
      },
    },
    {
      name: "paraName",
      label: "Parameter",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "conditionValues",
      label: "ConditionValues",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "createdByName",
      label: "Created By",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.tableData[tableMeta.rowIndex].id;
          const target_leaner_group_id =
            tableMeta.tableData[tableMeta.rowIndex].target_leaner_group_id;

          return (
            <>
              {userAndRoleWiseData?.isDelete && (
                <Tooltip title="delete">
                  <a
                    className="mr-2"
                    style={{ fontSize: "15px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setDeleteId(id);
                      setShowDeleteModal(true);
                    }}>
                    <i className="fas fa-trash"></i>
                  </a>
                </Tooltip>
              )}
            </>
          );
        },
      },
    },
  ];

  const validateForm = () => {
    let errorObj = {};
    let isError = false;

    if (
      formData?.target_learner_group === null ||
      formData?.target_learner_group === undefined
      // TargetLearnerId == "" ||
      // TargetLearnerId == null ||
      // TargetLearnerId == undefined
    ) {
      errorObj = {
        ...errorObj,
        ["target_learner_group"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["target_learner_group"]: "",
      };
    }

    if (formData?.condition === null || formData?.condition === undefined) {
      errorObj = {
        ...errorObj,
        ["condition"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["condition"]: "",
      };
    }

    if (formData?.parameter === null || formData?.parameter === undefined) {
      errorObj = {
        ...errorObj,
        ["parameter"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["parameter"]: "",
      };
    }

    if (formData?.values === null || formData?.values === undefined) {
      errorObj = {
        ...errorObj,
        ["values"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["values"]: "",
      };
    }

    const isEmpty = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  const domainValidateForm = () => {
    let errorObj = {};
    let isError = false;

    if (targetLearnerGroupName === null || targetLearnerGroupName === "") {
      errorObj = {
        ...errorObj,
        ["targetLearnerGroupName"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["targetLearnerGroupName"]: "",
      };
    }

    if (domain === null || domain === "") {
      errorObj = {
        ...errorObj,
        ["domain"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["domain"]: "",
      };
    }

    const isEmpty = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  const insertTargetLearnerConditionsApiCall = async () => {
    showLoader();

    const isValid = validateForm();

    if (!isValid) {
      let conditionValues = "";

      if (
        formData?.parameter?.value == "GJD" ||
        formData?.parameter?.value == "BJD"
      ) {
        conditionValues = moment(formData?.values).format("DD-MMM-YYYY");
      } else {
        conditionValues = formData?.values?.map((s) => s.value).join(",");
      }
      const objParameter = {
        TargetGroupId: formData?.target_learner_group?.value,
        Condition: formData?.condition?.value,
        Parameter: formData?.parameter?.value,
        ConditionValues: conditionValues,
        CreatedBy: userDetails?.Id,
      };
      const response = await APICall(
        InsertTargetLearnerConditions,
        "POST",
        objParameter
      );

      if (response?.status === 0) {
        notify(response?.status, response?.message);

        resetFunction();
        const targetGroupId = formData?.target_learner_group?.value;

        if (Number(targetGroupId) > 0) {
          await getTargetLearnerConditionsDataByTargetGroupIdAplCall(
            targetGroupId
          );
        }
      } else {
        notify(response?.status, response?.message);
      }
    }

    hideLoader();
  };

  const resetFunction = () => {
    setFormErrors({});

    setFormData((prev) => {
      return { ...prev, parameter: null, values: null, condition: null };
    });
  };

  const addTargetLearnerGroupNameFunction = async () => {
    // if (targetLearnerGroupName === "") {
    //   notify(1, "Target Learner Group Name is required");
    // } else

    const isDomainValidate = domainValidateForm();
    if (!isDomainValidate) {
      showLoader();

      const response = await APICall(InsertTargetLearnerGroupName, "POST", {
        TargetGroupName: targetLearnerGroupName,
        DomainIds: domain?.map((e) => e.value).join(","),
        CreatedBy: userDetails?.Id,
        RoleId: currentRoleId.value,
      });

      if (response?.status === 0) {
        notify(response?.status, response?.message);
        setShowModal(false);
        setTargetLearnerGroupName("");
        setDomain(null);
        setFormErrors({});
        setReserLearnerGroup(!reserLearnerGroup);
      } else if (response?.status === 1) {
        notify(response?.status, response?.message);
      }

      hideLoader();
    }
  };

  const getTargetLearnerConditionsDataByTargetGroupIdAplCall = async (
    targetGroupId: any
  ) => {
    debugger;
    showLoader();

    if (Number(targetGroupId) > 0) {
      const response = await APICall(
        getTargetLearnerConditionsDataByTargetGroupId,
        "POST",
        {
          TargetGroupId: targetGroupId,
        }
      );

      if (response?.status === 0 && response?.data?.length > 0) {
        setGridData(response?.data);
      } else if (response?.data?.length === 0) {
        setGridData([]);
      } else if (response?.status === 1) {
        notify(response?.status, response?.message);
      }
    }

    hideLoader();
  };

  const getTargetLearnerEmployeeDataByTargetGroupIdAplCall = async (
    targetGroupId: any
  ) => {
    debugger;
    showLoader();
    setTargetLearnerId(targetGroupId);

    if (Number(targetGroupId) > 0) {
      const response = await APICall(GetTargetLearnerEmployeeListById, "POST", {
        TargetGroupId: targetGroupId,
        PageSize: employeeListPageSize,
        PageNumber: employeeListPage,
        SortOrder: employeeListSortDirection,
        SortColumn: employeeListSortColumn,
        SearchText: searchText,
      });

      debugger;

      if (response?.status === 0 && response?.data?.length > 0) {
        console.log(response?.data, "response");
        setEmployeeListData(response?.data);
        setEmployeeListCount(response?.data[0].count);
      } else if (response?.data?.length === 0) {
        setEmployeeListData([]);
      } else if (response?.status === 1) {
        notify(response?.status, response?.message);
      }
    }

    hideLoader();
  };

  const deleteTargetLearnerConditionsApiCall = async (id: any) => {
    const response = await APICall(deleteTargetLearnerConditions, "POST", {
      Id: id,
    });
    if (response?.status === 0) {
      notify(response?.status, response?.message);

      const targetGroupId = formData?.target_learner_group?.value;

      setShowDeleteModal(false);

      if (Number(targetGroupId) > 0) {
        await getTargetLearnerConditionsDataByTargetGroupIdAplCall(
          targetGroupId
        );
      }
    } else if (response?.status === 1) {
      notify(response?.status, response?.message);
    }
  };

  const deleteTargetEmployeeApiCall = async (id: any) => {
    debugger;
    const response = await APICall(
      DeleteEmployeeToTargetLearnerEmployeeList,
      "POST",
      {
        Id: id,
        Mode: "DELETE",
      }
    );

    if (response?.status === 0) {
      notify(response?.status, response?.data?.errorMessage);

      const targetGroupId = TargetLearnerId;

      setShowDeleteModal(false);

      if (Number(targetGroupId) > 0) {
        await getTargetLearnerEmployeeDataByTargetGroupIdAplCall(targetGroupId);
      }
    } else if (response?.status === 1) {
      notify(response?.status, response?.message);
    }
  };

  async function handleSubmit() {
    debugger;
    try {
      if (gridData.length > 0) {
      } else {
        notify(1, "Please add data in grid");
      }
      showLoader();
    } catch (error) {
    } finally {
      hideLoader();
    }
  }

  const DownloadExcelTemplateApiCall = async () => {
    try {
      showLoader();
      const response = await APICall(DownloadExcelTemplate, "POST", {});

      if (response?.status == 0) {
        const { excelFileBase64 } = response?.data;

        if (excelFileBase64) {
          const fileName = `UploadTempate_${moment().format(
            "DD-MM-YYYY HH:mm:ss"
          )}.xlsx`;
          const base64 = excelFileBase64;

          let arrayBuffer = await base64ToArrayBuffer(base64);
          var byteArray = new Uint8Array(arrayBuffer);

          let blob = new Blob([byteArray], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          const newFile = new File([blob], "", {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            lastModified: new Date().getTime(),
          });

          fileDownload(newFile, fileName);
        }
      } else {
        notify(1, "Something went wrong, Please Try again Later.");
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const employeeListUploadApiCall = async () => {
    debugger;
    try {
      setFormErrors({});

      const errorObj = {};

      if (
        targetGroup === "employeelist" &&
        (selectedFile == null || selectedFile == undefined)
      ) {
        errorObj["fileName"] = "File Required.";
      }

      if (
        TargetLearnerId == "" ||
        TargetLearnerId == null ||
        TargetLearnerId == undefined
      ) {
        errorObj["target_learner_group"] = "Required.";
      }

      setFormErrors(errorObj);

      if (Object.keys(errorObj).length !== 0) {
        return;
      }
      showLoader();

      if (targetGroup === "employeelist") {
        debugger;
        let base64 = await getBase64FromFilePromise(selectedFile);
        base64 = String(base64).split("base64,")[1];

        const response = await APICall(
          UploadEmployeeToTargetLearnerEmployeeList,
          "POST",
          {
            Id: 0,
            TargetGroupId: formData?.target_learner_group?.value,
            CreatedBy: userDetails.Id,
            Mode: "SAVE",
            ExcelFileBase64: base64,
            UserId: userDetails?.Id,
            RoleId:
              proxyUserRoleDetails === null
                ? currentRoleId?.value
                : proxyUserRoleDetails?.value,
          }
        );
        hideLoader();
        if (response?.status == 0) {
          notify(0, "Upload Successfully");
          // setPage(0);

          const targetGroupId = formData?.target_learner_group?.value;

          await getTargetLearnerEmployeeDataByTargetGroupIdAplCall(
            targetGroupId
          );

          setTargetGroup("employeelist");

          setSelectedFile(null);
        } else if (response?.status == 5) {
          debugger;
          const { data } = response;

          if (data) {
            // notify(1, "Error download in excel sheet,Please check it.");

            const fileName = "InvalidEmployeeCode.xlsx";
            const base64 = data;

            let arrayBuffer = await base64ToArrayBuffer(base64);
            var byteArray = new Uint8Array(arrayBuffer);

            let blob = new Blob([byteArray], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            const newFile = new File([blob], "", {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              lastModified: new Date().getTime(),
            });

            fileDownload(newFile, fileName);

            const excelErrorMessage = JSON.parse(response?.message);

            const errorMessage = () => {
              if (
                Array.isArray(excelErrorMessage) &&
                excelErrorMessage.length > 0
              ) {
                setErrorData(excelErrorMessage);
              } else {
                notify(1, excelErrorMessage);
              }
            };
            errorMessage();

            setShowErrorModal(true);

            // notify(1, response?.message);

            setTargetGroup("employeelist");

            setSelectedFile(null);
          } else {
            notify(1, response?.message);
          }
        }
      }
    } catch (error) {}
  };

  const GenerateTargetLearnerByIDApiCall = async () => {
    if (
      formData?.target_learner_group === null ||
      formData?.target_learner_group === undefined
    ) {
      notify(1, "Please select target learner group");
    } else {
      const TargetGroupId = formData?.target_learner_group?.value;

      if (TargetGroupId > 0) {
        const response = await APICall(GenerateTargetLearnerByID, "POST", {
          TargetGroupId: TargetGroupId,
          UserId: userDetails.Id,
          RoleId: currentRoleId.value,
        });

        if (response?.status === 0) {
          notify(response?.status, "Employee List genetated sucessfully");
          setTargetGroup("employeelist");
          await getTargetLearnerEmployeeDataByTargetGroupIdAplCall(
            TargetGroupId
          );
        } else if (response?.status === 1) {
          notify(response?.status, response?.message);
        }
      } else {
        notify(1, "Something went wrong");
      }
    }
  };

  const toTitleCase = (str) => {
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  };

  function formatDate(dateString) {
    const date = new Date(dateString);

    if (!isNaN(date.getTime())) {
      return `${date.getDate().toString().padStart(2, "0")}/${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}/${date.getFullYear()}`;
    } else {
      return dateString; // Return the original value if date conversion fails
    }
  }

  const errorDataColumns = [
    {
      name: "Row",
      label: "Row",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: false,
      },
    },
    {
      name: "Col",
      label: "Column",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: false,
      },
    },
    {
      name: "ColName",
      label: "Column Name",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: false,
      },
    },
    {
      name: "ErrorMessage",
      label: "Error Message",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: false,
      },
    },
  ];

  const errorDataOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    rowsPerPage: 5,
    serverSide: false,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    //standard | vertical | simple
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const exporttoexcel = async () => {
    showLoader();
    const TargetGroupId = TargetLearnerId;
    const response = await APICall(
      GetTargetLearnerEmployeeListByIdExportToExcel,
      "POST",
      { TargetGroupId: TargetGroupId }
    );

    if (response?.status == 0) {
      const { excelFileBase64 } = response?.data;

      if (excelFileBase64) {
        const fileName = `TargetLearnerList_${moment().format(
          "DD-MM-YYYY HH:mm:ss"
        )}.xlsx`;
        const base64 = excelFileBase64;

        let arrayBuffer = await base64ToArrayBuffer(base64);
        var byteArray = new Uint8Array(arrayBuffer);

        let blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const newFile = new File([blob], "", {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          lastModified: new Date().getTime(),
        });

        fileDownload(newFile, fileName);
      }
    } else {
      notify(1, "Something went wrong, Please Try again Later.");
    }
    // console.log(response.data, "export to excel");

    // const responseData = response.data;

    // if (!responseData || responseData.length === 0) {
    //   console.error("No data to export.");
    //   notify(1, "No data to export.");
    //   hideLoader();
    //   return;
    // }

    // const columnsToInclude = [
    //   "employeeCode",
    //   "firstName",
    //   "middleName",
    //   "lastName",
    //   "gender",
    //   "business",
    //   "sbu",
    //   "legalEntity",
    //   "payrollLocation",
    //   "physicalLocation",
    //   "empFunction",
    //   "division",
    //   "department",
    //   "customBusiness",
    //   "section",
    //   "subSection",
    //   "subSubSection",
    //   "jobBand",
    //   "jobLevel",
    //   "employeeClass",
    //   "designation",
    //   "groupDateOfJoining",
    //   "employeeDateOfJoining",
    //   "jobCode",
    //   "jobTitle",
    //   "keyTalent",
    //   "clientFacingTitle",
    //   "criticalPosition",
    //   "hrManager",
    // ];

    // const headers = Object.keys(responseData[0])?.filter((header) =>
    //   columnsToInclude.includes(header)
    // );
    // const workbook = new ExcelJS.Workbook();
    // const worksheet = workbook.addWorksheet("TargetLearner");

    // const columnWidths = headers.map((header) => {
    //   const maxContentLength = Math.max(
    //     ...responseData.map((row) =>
    //       row[header] ? String(row[header]).length : 0
    //     )
    //   );
    //   return Math.min(50, Math.max(10, maxContentLength * 1.5));
    // });

    // columnWidths.forEach((width, index) => {
    //   worksheet.getColumn(index + 1).width = width;
    // });

    // const formattedHeaders = headers.map((header) =>
    //   toTitleCase(header.replace(/([a-z])([A-Z])/g, "$1 $2"))
    // );

    // worksheet.addRow(formattedHeaders);
    // worksheet.getRow(1).font = { bold: true };
    // responseData.font = { bold: true };
    // responseData.forEach((row) => {
    //   const dataRow = headers.map((header) => {
    //     if (header === "employeeDateOfJoining") {
    //       const dateOdJoining = row[header];
    //       const formattedDate = formatDate(dateOdJoining);
    //       return formattedDate;
    //     }
    //     return row[header] || "";
    //   });

    //   worksheet.addRow(dataRow);
    // });

    // workbook.xlsx.writeBuffer().then((buffer) => {
    //   const blob = new Blob([buffer], {
    //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //   });
    //   const url = URL.createObjectURL(blob);

    //   const a = document.createElement("a");
    //   a.href = url;
    //   a.download = "Participant.xlsx";
    //   a.click();

    //   URL.revokeObjectURL(url);
    // });
    hideLoader();
  };

  const onConditionandEmployeeList = async (event) => {
    const TargetGroupId = formData?.target_learner_group?.value;

    if (event === "condition") {
      setTargetGroup(event);
      if (TargetGroupId > 0) {
        await getTargetLearnerConditionsDataByTargetGroupIdAplCall(
          TargetGroupId
        );
      }
    } else if (event === "employeelist") {
      setTargetGroup(event);
      if (TargetGroupId > 0) {
        await getTargetLearnerEmployeeDataByTargetGroupIdAplCall(TargetGroupId);
      }
    }
  };

  return (
    <>
      <div className="breadcrumb modified-bredcrumb">
        <span>
          <ul>
            <li>
              <a href="/home">Home</a>
            </li>
            <li>Target Learner Group</li>
          </ul>
        </span>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4 col-sm-12 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Target Learner Group Name <sup>*</sup>{" "}
              </label>
              <SelectForm
                value={formData?.target_learner_group}
                placeholder={"Target Learner Group Name"}
                options={options?.target_learner_group || []}
                onChange={(event) => {
                  // if (targetGroup === "condition") {
                  //   handleOnChange(event, "target_learner_group");
                  // } else {
                  //   handleOnChange(event, "target_learner_group1");
                  // }
                  handleOnChange(event, "target_learner_group");
                }}
                noIndicator={false}
                noSeparator={false}
              />
              {formErrors["target_learner_group"] && (
                <p style={{ color: "red" }}>
                  {formErrors["target_learner_group"]}
                </p>
              )}
            </div>
          </div>
          {userAndRoleWiseData?.isEdit && (
            <div className="col-lg-2 col-md-2 col-sm-12 mb-3">
              <label className="col-form-label">&nbsp;</label>
              <div>
                <button
                  className="btn btn-primary"
                  onClick={() => setShowModal(true)}>
                  <i className="fa fa-plus mr-2"></i>
                  Create Target Group
                </button>
              </div>
              <span className="" style={{ color: "red" }}></span>
            </div>
          )}
          <div className="col-lg-6 col-sm-8 col-xs-8">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12 mb-3">
                <br />
                <br />
                <div>
                  <input
                    className="ml-2 mr-1"
                    disabled={false}
                    id={`targetGroup_1`}
                    type="radio"
                    name="targetGroup"
                    onChange={() => onConditionandEmployeeList("condition")}
                    checked={targetGroup === "condition"}
                  />
                  <label htmlFor={`targetGroup_1`}>Condition</label>
                </div>
              </div>

              <div className="col-lg-4 col-md-3 col-sm-12 mb-3">
                <br />
                <br />
                <div>
                  <input
                    className="ml-2 mr-1"
                    disabled={false}
                    id={`targetGroup_2`}
                    type="radio"
                    name="targetGroup"
                    onChange={() => onConditionandEmployeeList("employeelist")}
                    checked={targetGroup === "employeelist"}
                  />
                  <label htmlFor={`targetGroup_2`}>Employee List</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        {targetGroup === "condition" && (
          <>
            {userAndRoleWiseData?.isEdit && (
              <div className="row">
                <div className="col-lg-4 col-sm-6 col-xs-2">
                  <div className="mb-1">
                    <label className="col-form-label">Condition</label>{" "}
                    <sup>*</sup>
                    <SelectForm
                      value={formData?.condition}
                      placeholder={"Condition"}
                      options={conditionOptions}
                      onChange={(event) => {
                        handleOnChange(event, "condition");
                      }}
                      noIndicator={false}
                      noSeparator={false}
                    />
                    {formErrors["condition"] && (
                      <p style={{ color: "red" }}>{formErrors["condition"]}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-xs-2">
                  <div className="mb-1">
                    <label className="col-form-label">Parameter</label>{" "}
                    <sup>*</sup>{" "}
                    <SelectForm
                      value={formData?.parameter}
                      placeholder={"Parameter"}
                      options={options?.parameter}
                      onChange={(event) => {
                        handleOnChange(event, "parameter");
                      }}
                      noIndicator={false}
                      noSeparator={false}
                    />
                    {formErrors["parameter"] && (
                      <p style={{ color: "red" }}>{formErrors["parameter"]}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-xs-2">
                  <div className="dropdown mb-1">
                    <label className="col-form-label">Values</label>{" "}
                    <sup>*</sup>{" "}
                    {formData?.parameter?.value == "EmployeeCode" ? (
                      <>
                        <SelectForm
                          value={formData?.values}
                          placeholder={"Type of search"}
                          options={(searchString, cb) => {
                            if (searchString?.length >= 3) {
                              handleFetchEmployeeCode(searchString, cb);
                            } else {
                              cb([]);
                            }
                          }}
                          onChange={(event) => {
                            handleOnChange(event, "values");
                          }}
                          noIndicator={false}
                          noSeparator={false}
                          async={true}
                          isMulti={true}
                        />
                      </>
                    ) : formData?.parameter?.value == "GJD" ||
                      formData?.parameter?.value == "BJD" ? (
                      <>
                        <DateForm
                          placeholder={"Start Date"}
                          isDisabled={false}
                          value={formData?.values || null}
                          onChange={(val: any) => {
                            handleOnChange(val, "values");
                          }}
                        />
                      </>
                    ) : (
                      <SelectForm
                        value={formData?.values}
                        placeholder={"Select Values"}
                        options={options?.values}
                        onChange={(event) => {
                          handleOnChange(event, "values");
                        }}
                        noIndicator={false}
                        noSeparator={false}
                        isMulti={true}
                      />
                    )}
                    {formErrors["values"] && (
                      <p style={{ color: "red" }}>{formErrors["values"]}</p>
                    )}
                  </div>
                </div>
              </div>
            )}

            {userAndRoleWiseData?.isEdit && (
              <div className="row">
                <div className="col-lg-12 mt-2 mb-3 text-right">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      insertTargetLearnerConditionsApiCall();
                    }}>
                    <i className={"fa fa-plus"}></i> Add
                  </button>
                  {/* <button className="btn btn-primary ml-2">
              <i className={"fa fa-plus"}></i> Reset
            </button> */}
                </div>
              </div>
            )}

            <div className="mb-3 pt-3">
              <DynamicGrid
                options={gridOptions}
                data={gridData}
                columns={gridColumns}
              />
            </div>

            {gridData?.length > 0 && userAndRoleWiseData?.isEdit && (
              <>
                <div className="mt-2 SectionSubmit mb-4 clearfix">
                  <button
                    onClick={() => {
                      GenerateTargetLearnerByIDApiCall();
                    }}
                    disabled={false}
                    className="btn btn-filter-submit float-right ml-2">
                    <i className="fa-solid fa-check"></i> Generate Employee List
                  </button>
                </div>
              </>
            )}
          </>
        )}

        {/* Employee List */}

        {targetGroup === "employeelist" && (
          <>
            {userAndRoleWiseData?.isEdit && (
              <div className="row">
                <div className={"col-lg-12 col-sm-12 col-xs-12"}>
                  <div className="row">
                    <div className={"col-lg-4 col-md-6 col-sm-4 col-xs-4"}>
                      <div className="mb-1">
                        <label className="col-form-label">
                          {" "}
                          Documents <sup>*</sup>
                        </label>
                      </div>
                      <InputForm
                        className="form-control"
                        placeholder="upload a file"
                        isDisabled={false}
                        onChange={(e) => {
                          // setfileName(e.target.value);
                        }}
                        textArea={false}
                        value={selectedFile?.name}
                        maxLength="255"
                      />
                      {formErrors["fileName"] && (
                        <p style={{ color: "red" }}>{formErrors["fileName"]}</p>
                      )}
                    </div>
                    <div className={"col-lg-1 col-md-2 col-sm-1 col-xs-1"}>
                      <div className="mb-1">
                        <label className="col-form-label mt-3"> </label>
                      </div>
                      <div className="box position-relative">
                        <input
                          id={"REQSUPDOC"}
                          className="form-control inputfile inputfile-6 multiple-inputfile"
                          multiple={false}
                          type="file"
                          onChange={(e) => {
                            ///handleSupportingDoc(e, "REQSUPDOC");
                            handleOnChangeForEmployeeList(e, "file");
                          }}
                          accept={".xlsx,.xls"}
                          disabled={false}
                        />
                        <label
                          htmlFor={"REQSUPDOC"}
                          className="form-control"
                          style={{ width: 0, border: "none" }}>
                          <strong
                            style={{
                              padding: "6px 10px",
                              backgroundColor: "#3c5464",
                              borderRadius: "5px",
                            }}>
                            <i
                              className="fa fa-upload rotate90"
                              aria-hidden="true"></i>
                            {"  "}
                            Upload
                          </strong>{" "}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-8 col-sm-5 col-xs-4">
                      <div className="mt-3">
                        <label className="col-form-label"></label>
                        <div className="d-flex" style={{ marginTop: "2px" }}>
                          <button
                            className="btn btn-primary mr-2"
                            disabled={false}
                            onClick={() => DownloadExcelTemplateApiCall()}>
                            Download Template
                          </button>

                          <button
                            className="btn btn-primary mr-2"
                            disabled={false}
                            onClick={() => employeeListUploadApiCall()}>
                            Save
                          </button>
                          <button className="btn btn-primary">Reset</button>
                          {employeeListData?.length > 0 && (
                            <button
                              className="btn btn-primary  ml-2 mr-2"
                              disabled={false}
                              onClick={() => exporttoexcel()}>
                              Export to Excel
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <br />
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 ">
                <div className="form-group filtInp float-right mr-3">
                  <InputForm
                    value={searchText}
                    placeholder={"Search Keyword"}
                    isDisabled={false}
                    textArea={false}
                    onChange={(e) => {
                      //setPage(0);
                      // setDashboardStart(0);
                      // setPage(0);
                      setSearchText(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="mb-3 pt-3">
              <DynamicGrid
                options={employeeListOptions}
                data={employeeListData}
                columns={employeeListGridColumns}
              />
            </div>
          </>
        )}
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">{"Target Learner Group Name"}</div>
        </div>
        <Modal.Body>
          <>
            <div className="row">
              <div className="col-md-6">
                <label className="col-form-label">
                  Target Learner Group Name
                </label>
                <sup>*</sup>
                <InputForm
                  value={targetLearnerGroupName}
                  placeholder={"Target Learner Group Name"}
                  isDisabled={false}
                  textArea={false}
                  onChange={(e) => {
                    setTargetLearnerGroupName(e.target.value);
                  }}
                />
                {formErrors["targetLearnerGroupName"] && (
                  <p style={{ color: "red" }}>
                    {formErrors["targetLearnerGroupName"]}
                  </p>
                )}
              </div>
              <div className="dropdown col-md-6">
                <label className="col-form-label">Domains</label>
                <sup>*</sup>
                <SelectForm
                  isClearable
                  options={domainOption}
                  isOptionDisabled={(option) => !option?.isEnabled}
                  placeholder={"Domains"}
                  isDisabled={false}
                  onChange={(e) => setDomain(e)}
                  isMulti={true}
                  noIndicator={false}
                  noSeparator={false}
                  value={domain}
                />
                {formErrors["domain"] && (
                  <p style={{ color: "red" }}>{formErrors["domain"]}</p>
                )}
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => addTargetLearnerGroupNameFunction()}>
            Submit
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowModal(false);
            }}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      {/* Delete Employee List Model */}
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">{"Confirmation"}</div>
        </div>
        <Modal.Body>
          <>
            <div className="col-lg-12">
              {"Are you sure you want to delete this record?"}
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() =>
              targetGroup === "condition"
                ? deleteTargetLearnerConditionsApiCall(deleteId)
                : deleteTargetEmployeeApiCall(deleteId)
            }>
            Yes
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowDeleteModal(false);
            }}>
            No
          </button>
        </Modal.Footer>
      </Modal>

      {/* Validation Error */}
      <Modal
        show={showErrorModal}
        onHide={() => setShowErrorModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">Validation Error</div>
        </div>
        <Modal.Body>
          <>
            {errorData.length > 0 && (
              <div className="row my-2">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                  <DynamicGrid
                    data={errorData}
                    columns={errorDataColumns}
                    options={errorDataOptions}
                  />
                </div>
              </div>
            )}
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={(e) => {
              e.preventDefault();
              setShowErrorModal(false);
            }}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TargetLearnerGroup;
