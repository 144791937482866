import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Tooltip,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import SelectForm from "../../Components/SelectForm/SelectForm";
import InputForm from "../../Components/InputForm/InputForm";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import uuid from "react-uuid";
import DateForm from "../../Components/DateForm/DateForm";
import moment from "moment";
import { APICall } from "../../Helpers/APICalls";
import { LoaderContext } from "../../Helpers/Context/Context";
import {
  getDropdowns,
  postAttributeValueDetailsV2,
  viewDocument,
  downloadDocument,
  deleteDocument,
  documentUpload,
  getDocuments,
  insertIntoCandidateDeclartion,
  CheckAdharCardAndEmailIdAlreadyExistsForMRF,
  getRecruiterRemarkById,
  CheckJobPostingOnLinkdin,
} from "../../Helpers/APIEndPoints/EndPoints";
import notify from "../../Helpers/ToastNotification";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { DeclartionFormSection } from "./DeclartionFormSection";
import { useLocation, useNavigate } from "react-router-dom";
import { access } from "fs";
import useMrfAnonymousCandidateDeclarationStore from "../../Helpers/ZustandStore/MrfAnonymousCandidateDeclarationStore";
import { NationalIdCardType } from "../../Components/FromStructure/NationalIdCardTypeRegex";
import { isIP } from "net";
import { cloneDeep } from "lodash";
import { Modal } from "react-bootstrap";
import { RCMPdfViewer } from "../../Components/PDFViewerRCM/PDFViewerRCM";

const sectionEmployeeCandidateMapping = {
  CAFEDUATTAH: "EDUATT", //Education
  CAATTP: "ATTP", //
  CAFEMPHISATTAH: "",
};

export const SectionComponent = ({
  // JobId,
  CId,
  setCid,
  TId,
  setTId,
  formData,
  setFormData,
  section: ogSection,
  sectionValuesArray,
  activetab,
  index,
  lastIndex,
  showAllSection,
  isDisableApplication,
  workflowTaskId,
  setWorkflowTaskId,
  setGetData,
  sectionIndex,
  IsAnonymousCandidate,
  disableBeforeDeclaration,
  setDisableBeforeDeclaration,
  rejectCandidate,
  setRejectCandidate,
  isIJP,
  employeeId,
  isJobPostingDoneOnLinkdin,
}) => {
  const [hasValue, setHasValue] = useState(
    sectionValuesArray.filter((es) => es.sectionSystemName == ogSection.SN) ||
      null
  );
  const [section, setSection] = useState(ogSection);
  //const [accordion, setAccordion] = useState(true);
  const [accordion, setAccordion] = useState(sectionIndex === 0 ? true : false);

  const [formErrors, setFormErrors] = useState<any>({});
  const [optionsObj, setOptionsObj] = useState<any>({});
  const [gridData, setGridData] = useState([]);
  const [displayGrid, setDisplayGrid] = useState([]);
  const [gridColumns, setGridColumns] = useState([]);
  const [ogAttributeSet, setOgAttributeSet] = useState({});
  const [ogFilledAttributeSet, setOgFilledAttributeSet] = useState([]);
  const [currentGridId, setCurrentGridId] = useState(null);
  const [deleteGridId, setDeleteGridId] = useState(null);
  const [showSection, setShowSection] = useState(showAllSection);
  // value Object for section
  const [attributesData, setAttributesData] = useState<any>({});

  const [attributeSetData, setAttributeSetData] = useState<any>({});

  const [attributeSetCode, setAttributeSetCode] = useState(null);
  const [isOnce, setIsOnce] = useState(true);
  const [isValidateOnce, setIsValidateOnce] = useState(true);
  const [attributeValidation, setAttributeValidation] = useState<any>({});
  const [attributeSetValidation, setAttributeSetValidation] = useState<any>({});
  const [declarationQuestions, setDeclartionQuestions] = useState<any>([]);
  const [declarationQuestionsAnswers, setDeclartionQuestionsAnswers] =
    useState<any>({});
  const [declarationQuestionError, setDeclartionQuestionError] = useState<any>(
    {}
  );
  const [confirmCheckboxValue, setConfirmCheckboxValue] = useState(false);
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);
  const [disableDeclartionForm, setDisableDeclartionForm] = useState(true);
  const [sectionHasDocuments, setSectionHasDocuments] = useState(false);
  const [newGridIdCreated, setNewGridIdCreated] = useState(null);
  let FlatDocRef = useRef(null);
  let SetDocRef = useRef(null);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  let { userDetails, currentRoleId }: any = useUserContext();
  const { declaration, addDeclaration, question }: any =
    useMrfAnonymousCandidateDeclarationStore();
  const [multiDropdown, setMultiDropdown] = useState(true);
  const [recuiterRemark, setRecuiterRemark] = useState("");
  const [addressType, setAddressType] = useState<any>({});
  const [addressTypeUniqueIds, setAddressTypeUniqueIds] = useState([]);
  const [orginalAttributeValidationSet, setOrignalAttributeValiationSet] =
    useState({});
  const [sorginalAttributeValidationSet, setsOrignalAttributeValiationSet] =
    useState({});
  const [reset, setReset] = useState(false);
  const [isOnce1, setIsOnce1] = useState(false);
  const [fileViewModalPopup, setFileViewModalPopup] = useState(false);
  const [fileHeader, setFileHeader] = useState("");
  const [docViewBase64, setDocViewBase64] = useState(null);

  async function handleFileView({ header = "", fileAttribute = null }) {
    try {
      showLoader();
      let base64 = "";
      if (
        fileAttribute.DocId != undefined &&
        fileAttribute.DocId != null &&
        fileAttribute.DocId != 0
      ) {
        const doc = await APICall(downloadDocument, "POST", {
          TId,
          AC: fileAttribute.AC,
          DocId: fileAttribute.DocId,
        });
        if (doc && doc?.data !== null) {
          base64 = doc?.data;
        }
      } else {
        base64 = fileAttribute?.Data;
      }

      setFileHeader(header);
      setDocViewBase64(base64);
      setFileViewModalPopup(true);
    } catch (error) {
    } finally {
      hideLoader();
    }
  }

  async function handleCloseDocView() {
    try {
      setFileHeader("");
      setDocViewBase64(null);
      setFileViewModalPopup(false);
    } catch (error) {}
  }

  useEffect(() => {
    if (currentGridId) {
      let attributeSetData = ogFilledAttributeSet.find(
        (record) => record.Id === currentGridId
      );

      if (section?.SN == "EmployementHistory") {
        if (attributeSetData["CAFISPREMP"]?.code === "CODEPREVEMP1") {
          dependentValidations(
            attributeSetData["CAFISPREMP"],
            "CAFISPREMP",
            "AttributeSet",
            null,
            true
          );
        } else {
          dependentValidations(
            attributeSetData["CACURRWOKK"],
            "CACURRWOKK",
            "AttributeSet",
            null,
            true
          );
        }
      } else if (section?.SN == "Relationship") {
        dependentValidations(
          attributeSetData["CAFARWG"],
          "CAFARWG",
          "AttributeSet",
          null,
          true
        );
      }
      setAttributeSetData(attributeSetData);
    } else {
      if (Object.keys(attributeSetValidation).length > 0) {
        if (section?.SN == "EmployementHistory") {
          dependentValidations(
            attributeSetData["CAFISPREMP"],
            "CAFISPREMP",
            "AttributeSet",
            true,
            true
          );
        } else if (section?.SN == "Relationship") {
          dependentValidations(
            attributeSetData["CAFARWG"],
            "CAFARWG",
            "AttributeSet",
            true,
            true
          );
        }
      }
    }
  }, [currentGridId, reset]);

  // useEffect(() => {
  //   setsOrignalAttributeValiationSet(orginalAttributeValidationSet);
  // }, [setOrignalAttributeValiationSet]);

  useEffect(() => {
    // ;
    if (Object.keys(addressType).length === 2) {
      if (addressType.address === "present" && addressType.type === "lease") {
        let attributeSetValObj = { ...attributeSetValidation };

        attributeSetValObj.CALSD.ISM = "true";
        attributeSetValObj.CALED.ISM = "true";

        setAttributeSetValidation((prev) => ({
          ...prev,
          ...attributeSetValObj,
        }));
      } else {
        let attributeSetValObj = { ...attributeSetValidation };

        attributeSetValObj.CALSD.ISM = "false";
        attributeSetValObj.CALED.ISM = "false";

        setAttributeSetValidation((prev) => ({
          ...prev,
          ...attributeSetValObj,
        }));
      }
    }
  }, [addressType]);

  const [selectedFilesForFlat, setSelectedFilesForFlat] = useState<
    {
      AC: string;
      Name: string;
      Data: string;
      IsValidFile: boolean;
      DocId: Number;
      Type: string;
      Context: string;
      DCVALUE: string;
      DVTILL: string;
      DSTATUS: string;
      DPSINCE: string;
      DPWITH: string;
    }[]
  >([]);

  const [selectedFilesForSet, setSelectedFilesForSet] = useState<
    {
      AC: string;
      Id: string | number;
      Name: string;
      Data: string;
      IsValidFile: boolean;
      DocId: Number;
      Type: string;
      Context: string;
      DCVALUE: string;
      DVTILL: string;
      DSTATUS: string;
      DPSINCE: string;
      DPWITH: string;
    }[]
  >([]);

  const { state } = useLocation();

  const navigate = useNavigate();

  const fileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileValidation = (files, attribute) => {
    const { FS, FX, FC } = JSON.parse(attribute.V);
    // Split the validExtensionsString into an array
    const validExtensions = FX.split(",");

    let isValid = [];

    // validate file count
    if (files.length > FC) {
      if (FC == 1) {
        notify(1, `Only one file is allowed`);
      } else {
        notify(1, `Only ${FC} files allowed!`);
      }
      return false;
    }
    // check extension of each file
    files.forEach((file) => {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();

      // Check if the file extension is allowed
      if (!validExtensions.includes("." + fileExtension)) {
        isValid.push(false);
      } else {
        isValid.push(true); //valid
      }
    });

    let returnValue = isValid.some((v) => v === false);

    if (returnValue) {
      notify(1, `Please upload file within this ${FX} extentsion`);
      return false;
    }

    files.forEach((file) => {
      const fileSize = file.size;
      const maxFileSizeBytes = FS * 1024 * 1024;
      if (fileSize <= maxFileSizeBytes) {
        isValid.push(true); //valid
      } else {
        isValid.push(false);
      }
    });

    returnValue = isValid.some((v) => v === false);

    if (returnValue) {
      notify(1, `File size exceeds ${FS}MB. Please select a smaller file.`);
      return false;
    } else {
      return true;
    }
  };

  const handleFileChangeForFlat = async (e, attribute) => {
    const { FS, FX, FC } = JSON.parse(attribute.V);

    const files = Array.prototype.slice.call(e.target.files);

    const isValid = handleFileValidation(files, attribute);
    if (isValid) {
      if (files && files.length > 0) {
        const base64Array: { Name: string; Data: string }[] = [];
        for (let i = 0; i < files.length; i++) {
          const base64 = (await fileToBase64(files[i])) as string;
          const base64WithoutPrefix = base64.split(",")[1];
          base64Array.push({ Name: files[i].name, Data: base64WithoutPrefix });
        }

        //check if AC present and file count matched

        const fileCount = selectedFilesForFlat.reduce((total, item) => {
          if (item.AC === attribute.AC) {
            return total + 1;
          }
          return total;
        }, 0);

        if (fileCount == FC) {
          if (FC == 1) {
            notify(1, `Only one file is allowed`);
            return;
          } else {
            notify(1, `Only ${FC} files allowed!`);
            return;
          }
        } else {
          let type =
            JSON.parse(attribute.AD).CON === "National ID Information"
              ? attributeSetData["IDCATY"]?.label
              : JSON.parse(attribute.AD).TYP
              ? JSON.parse(attribute.AD).TYP
              : "";
          base64Array.forEach((file) => {
            setSelectedFilesForFlat((prevSelectedFiles) => [
              ...prevSelectedFiles,
              {
                ...(attribute.AC === "AGJARES" && { Id: TId }), //Id is mrfId when uploading resume
                AC: attribute.AC,
                Name: file.Name,
                Data: file.Data,
                IsValidFile: false,
                DocId: 0,
                Type: type,
                Context: JSON.parse(attribute.AD).CON
                  ? JSON.parse(attribute.AD).CON
                  : "",
                DCVALUE: file.Name,
                DVTILL: "",
                DSTATUS: "Verified",
                DPSINCE: "",
                DPWITH: "",
              },
            ]);
          });
        }
      }
    } else {
      e.target.value = null;
    }
  };

  const handleFileChangeForSet = async (e, subAttribute) => {
    const { FS, FX, FC } = JSON.parse(subAttribute.V);

    const files = Array.prototype.slice.call(e.target.files);

    const isValid = handleFileValidation(files, subAttribute);
    if (isValid) {
      if (files && files.length > 0) {
        const base64Array: { Name: string; Data: string }[] = [];
        for (let i = 0; i < files.length; i++) {
          const base64 = (await fileToBase64(files[i])) as string;
          const base64WithoutPrefix = base64.split(",")[1];
          base64Array.push({ Name: files[i].name, Data: base64WithoutPrefix });
        }

        //check if AC present and file count matched

        const fileCount = selectedFilesForSet.reduce((total, item) => {
          if (
            item.AC === subAttribute.AC &&
            ((currentGridId && currentGridId == item.Id) || item.Id == 0)
          ) {
            return total + 1;
          }
          return total;
        }, 0);

        if (fileCount == FC) {
          if (FC == 1) {
            notify(1, `Only one file is allowed`);
          } else {
            notify(1, `Only ${FC} files allowed!`);
          }
        } else {
          let type =
            JSON.parse(subAttribute.AD).CON === "National ID Information"
              ? attributeSetData["IDCATY"]?.label
              : JSON.parse(subAttribute.AD).TYP
              ? JSON.parse(subAttribute.AD).TYP
              : "";
          base64Array.forEach((file) => {
            setSelectedFilesForSet((prevSelectedFiles) => [
              ...prevSelectedFiles,
              {
                AC: subAttribute.AC,
                Name: file.Name,
                Data: file.Data,
                IsValidFile: false,
                Id: currentGridId ?? 0,
                DocId: 0,
                Type: type,
                Context: JSON.parse(subAttribute.AD).CON
                  ? JSON.parse(subAttribute.AD).CON
                  : "",
                DCVALUE:
                  JSON.parse(subAttribute.AD).CON === "National ID Information"
                    ? attributeSetData["PERIDNM"]
                    : file.Name,
                DVTILL:
                  JSON.parse(subAttribute.AD).CON === "National ID Information"
                    ? attributeSetData["NVALTIL"]
                    : "",
                DSTATUS: "Verified",
                DPSINCE: "",
                DPWITH: "",
              },
            ]);
          });
        }
      }
    } else {
      e.target.value = null;
    }
  };

  const handleViewForFlat = async (fileAttribute, fileIndex) => {
    showLoader();
    if (
      fileAttribute.DocId != undefined &&
      fileAttribute.DocId != null &&
      fileAttribute.DocId != 0
    ) {
      const doc = await APICall(viewDocument, "POST", {
        TId: CId,
        AC: fileAttribute.AC,
        DocId: fileAttribute.DocId,
      });

      if (doc && doc.data !== null) {
        //window.open(doc.data,'_blank');
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = doc.data;
        link.click();
      }
      hideLoader();
    } else {
      notify(1, "File Not Available");
    }
  };

  const handleDownloadFileFlat = async (fileAttribute, fileIndex) => {
    showLoader();
    if (
      fileAttribute.DocId != undefined &&
      fileAttribute.DocId != null &&
      fileAttribute.DocId != 0
    ) {
      const doc = await APICall(downloadDocument, "POST", {
        TId: section?.SN == "AgencyJobDescription" ? TId : CId,
        AC: fileAttribute.AC,
        DocId: fileAttribute.DocId,
      });
      if (doc && doc.data !== null) {
        downloadBase64File(doc.data, fileAttribute.Name);
        hideLoader();
      } else {
        notify(1, "Something Went Wrong.");
        hideLoader();
      }
    } else {
      downloadBase64File(fileAttribute.Data, fileAttribute.Name);
      hideLoader();
    }
  };

  function downloadBase64File(base64String: string, fileName: string) {
    const mimeType = inferMimeType(base64String);
    const blob = base64toBlob(base64String, mimeType);

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  }

  function inferMimeType(base64String: string): string {
    const dataUrl = `data:application/octet-stream;base64,${base64String}`;
    const typeInfo = /^data:(.*?);/.exec(dataUrl);

    if (typeInfo && typeInfo[1]) {
      return typeInfo[1];
    }

    return "application/octet-stream";
  }

  function base64toBlob(base64String: string, mimeType: string): Blob {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], { type: mimeType });
  }

  const handleDeleteFileForFlat = async (fileAttribute, fileIndex) => {
    if (
      fileAttribute.DocId != undefined &&
      fileAttribute.DocId != null &&
      fileAttribute.DocId != 0
    ) {
      showLoader();
      const doc = await APICall(deleteDocument, "POST", {
        TId: section?.SN == "AgencyJobDescription" ? TId : CId,
        AC: fileAttribute.AC,
        DocId: fileAttribute.DocId,
      });
      if (doc.message === "Success") {
        FlatDocRef.current.value = null;
        let remainingDocs = selectedFilesForFlat.filter(function (obj) {
          return obj.DocId !== fileAttribute.DocId;
        });
        setSelectedFilesForFlat(remainingDocs);
        hideLoader();
      } else {
        notify(1, "File not Deleted, Something Went Wrong.");
        hideLoader();
      }
    } else {
      FlatDocRef.current.value = null;
      setSelectedFilesForFlat((prevSelectedFiles) => {
        return prevSelectedFiles.filter(
          (item, attIndex) => fileIndex !== attIndex
        );
      });
    }
  };

  const handleDeleteFileForSet = async (fileAttribute, fileIndex) => {
    if (
      fileAttribute.DocId != undefined &&
      fileAttribute.DocId != null &&
      fileAttribute.DocId != 0
    ) {
      showLoader();
      const doc = await APICall(deleteDocument, "POST", {
        TId: CId,
        AC: fileAttribute.AC,
        DocId: fileAttribute.DocId,
      });
      if (doc.message === "Success") {
        SetDocRef.current.value = null;
        let remainingDocs = selectedFilesForSet.filter(function (obj) {
          return obj.DocId !== fileAttribute.DocId;
        });
        setSelectedFilesForSet(remainingDocs);
        hideLoader();
      } else {
        notify(1, "File not Deleted, Something Went Wrong.");
        hideLoader();
      }
    } else {
      SetDocRef.current.value = null;
      setSelectedFilesForSet((prevSelectedFiles) => {
        return prevSelectedFiles.filter((item, attIndex) =>
          deleteGridId !== null
            ? item.Id !== deleteGridId
            : fileIndex !== attIndex
        );
      });
    }
  };

  const getDropdownsData = async (AC, PID) => {
    await showLoader();
    const dropdownData = await APICall(getDropdowns, "POST", {
      AC,
      PID,
    });

    if (
      dropdownData.data !== null &&
      dropdownData.data !== undefined &&
      dropdownData.data.length > 0
    ) {
      await hideLoader();
      return dropdownData.data;
    } else {
      await hideLoader();
      return [];
    }
  };

  useEffect(() => {
    //add attribute in attributesData
    let attributes = {};
    let attributeSet = {};
    let attributeSetValidationObj = {};
    let emp = {};
    const gridColumnsArr = [];
    let dropdownOptionsObj = {};
    const sectionHasDocuments = [];
    section?.Attribute?.forEach(async (eachAttribute) => {
      if (eachAttribute.AT === "Attribute") {
        //change value according to dt
        setAttributesData((prev) => ({
          ...prev,
          [eachAttribute.AC]:
            eachAttribute.DT === "DropdownSingle" || eachAttribute.DT === "Date"
              ? null
              : eachAttribute.DT === "DropdownMultiple"
              ? null
              : eachAttribute.DT === "Text" ||
                eachAttribute.DT === "Number" ||
                eachAttribute.DT === "RichText"
              ? ""
              : eachAttribute.DT === "Checkbox"
              ? false
              : "",
        }));
        //RichText add
        attributes = {
          ...attributes,
          [eachAttribute.AC]:
            eachAttribute.DT === "DropdownSingle" || eachAttribute.DT === "Date"
              ? null
              : eachAttribute.DT === "DropdownMultiple"
              ? null
              : eachAttribute.DT === "Text" ||
                eachAttribute.DT === "Number" ||
                eachAttribute.DT === "RichText"
              ? ""
              : eachAttribute.DT === "Checkbox"
              ? false
              : "",
        };

        //for document
        if (eachAttribute.DT === "Document") {
          setSectionHasDocuments(true);
          sectionHasDocuments.push(eachAttribute.AC);
        }

        //attributeValidation
        setAttributeValidation((prev) => ({
          ...prev,
          [eachAttribute.AC]: JSON.parse(eachAttribute.V),
        }));

        setAttributeValidation((prev) => ({
          ...prev,
          [eachAttribute.AC]: {
            ...prev[eachAttribute.AC],
            DD: eachAttribute.DD !== null ? JSON.parse(eachAttribute.DD) : null,
            isDisable: false,
          },
        }));

        if (
          eachAttribute.DT === "DropdownSingle" ||
          eachAttribute.DT === "DropdownMultiple"
        ) {
          // api call for each dropdown option
          // const options = await getDropdownsData(eachAttribute.AC, null);
          dropdownOptionsObj = {
            ...dropdownOptionsObj,
            [eachAttribute.AC]: eachAttribute.OPT,
          };

          setOptionsObj((prev) => ({
            ...prev,
            [eachAttribute.AC]: eachAttribute.OPT,
          }));

          /*Hardcode to set Default Value.*/
          if (JSON.parse(eachAttribute.AD).DF !== undefined) {
            let filtredOPT = eachAttribute.OPT?.filter((o) => {
              if (o.label.toLowerCase() === JSON.parse(eachAttribute.AD).DF) {
                return o;
              }
            });
            if (filtredOPT.length > 0) {
              setAttributesData((prev) => ({
                ...prev,
                [eachAttribute.AC]: filtredOPT[0],
              }));
            }
          }
          /*Hardcode to set Default Value.*/
        }
      } else if (eachAttribute.AT === "AttributeSet") {
        setAttributeSetCode(eachAttribute.AC);

        // Id for grid
        gridColumnsArr.push({
          name: "Id",
          label: "Id",
          options: { display: false },
        });

        eachAttribute.Attribute.forEach(async (subAttribute) => {
          let display = JSON.parse(subAttribute.AD);
          display = display.ISOG?.toLowerCase() === "true" ? true : false;
          //for document
          if (subAttribute.DT === "Document") {
            setSectionHasDocuments(true);
            sectionHasDocuments.push(subAttribute.AC);
          }
          //grid columns
          gridColumnsArr.push({
            name: subAttribute.AC,
            label: subAttribute.AN,
            options: { sort: false, display },
          });

          //attributeSetValidation
          // setAttributeSetValidation((prev) => ({
          //   ...prev,
          //   [subAttribute.AC]: JSON.parse(subAttribute.V),
          //   DD: subAttribute.DD !== null ? JSON.parse(subAttribute.DD) : null,
          //   isDisable: false,
          // }));
          // if (subAttribute.AC == "CAFINDUSTYP") {
          //   ;
          // }

          if (section.SN == "EmployementHistory") {
            // ;
            emp = {
              ...emp,
              [subAttribute.AC]: JSON.parse(subAttribute.V),
            };

            emp = {
              ...emp,
              [subAttribute.AC]: {
                ...emp[subAttribute.AC],
                DD:
                  subAttribute.DD !== null ? JSON.parse(subAttribute.DD) : null,
                isDisable: false,
              },
            };
          }

          attributeSetValidationObj = {
            ...attributeSetValidationObj,
            [subAttribute.AC]: JSON.parse(subAttribute.V),
          };

          attributeSetValidationObj = {
            ...attributeSetValidationObj,
            [subAttribute.AC]: {
              ...attributeSetValidationObj[subAttribute.AC],
              DD: subAttribute.DD !== null ? JSON.parse(subAttribute.DD) : null,
              isDisable: false,
            },
          };

          setAttributeSetValidation((prev) => ({
            ...prev,
            [subAttribute.AC]: JSON.parse(subAttribute.V),
          }));

          setAttributeSetValidation((prev) => ({
            ...prev,
            [subAttribute.AC]: {
              ...prev[subAttribute.AC],
              DD: subAttribute.DD !== null ? JSON.parse(subAttribute.DD) : null,
              isDisable: false,
            },
          }));

          //change value according to dt
          setAttributeSetData((prev) => ({
            ...prev,
            [subAttribute.AC]:
              subAttribute.DT === "DropdownSingle" ||
              subAttribute.DT === "Date" ||
              subAttribute.DT === "MonthYear" ||
              subAttribute.DT === "DropdownMultiple"
                ? null
                : subAttribute.DT === "Text" ||
                  subAttribute.DT === "Number" ||
                  eachAttribute.DT === "RichText"
                ? ""
                : subAttribute.DT === "Checkbox"
                ? false
                : "",
          }));

          attributeSet = {
            ...attributeSet,
            [subAttribute.AC]:
              subAttribute.DT === "DropdownSingle" ||
              subAttribute.DT === "Date" ||
              subAttribute.DT === "MonthYear" ||
              subAttribute.DT === "DropdownMultiple"
                ? null
                : subAttribute.DT === "Text" ||
                  subAttribute.DT === "Number" ||
                  eachAttribute.DT === "RichText"
                ? ""
                : subAttribute.DT === "Checkbox"
                ? false
                : "",
          };

          if (
            subAttribute.DT === "DropdownSingle" ||
            subAttribute.DT === "DropdownMultiple"
          ) {
            // const options = await getDropdownsData(subAttribute.AC, null);
            dropdownOptionsObj = {
              ...dropdownOptionsObj,
              [subAttribute.AC]: subAttribute.OPT,
            };

            setOptionsObj((prev) => ({
              ...prev,
              [subAttribute.AC]: subAttribute.OPT,
            }));

            /*Hardcode to set Default Value.*/
            if (JSON.parse(subAttribute.AD).DF !== undefined) {
              let filtredOPT = subAttribute.OPT?.filter((o) => {
                if (o.label.toLowerCase() === JSON.parse(subAttribute.AD).DF) {
                  return o;
                }
              });
              if (filtredOPT.length > 0) {
                setAttributeSetData((prev) => ({
                  ...prev,
                  [subAttribute.AC]: filtredOPT[0],
                }));

                attributeSet = {
                  ...attributeSet,
                  [subAttribute.AC]: filtredOPT[0],
                };
              }
            }
            /*Hardcode to set Default Value.*/
          }
        });
      }
    });

    setOgAttributeSet(attributeSet);
    setGridColumns((prev) => [...prev, ...gridColumnsArr, action]);

    // if (section?.SN == "EmployementHistory") {
    //   if (!isOnce1) {
    //     ;
    //     setIsOnce1(true);
    //     //const i = { ...attributeSetValidationObj };
    //     // const i = JSON.parse(JSON.stringify(attributeSetValidationObj));
    //     const i = cloneDeep(emp);
    //     //Object.assign({}, attributeSetValidationObj);
    //     setOrignalAttributeValiationSet((prev) => ({
    //       ...i,
    //     }));
    //     //orginalAttributeValidationSet.current = i;
    //   }
    // }

    const fillData = async () => {
      await showLoader();
      if (isOnce && TId !== 0) {
        //fetch attributes/set data
        //check if not first time then call api
        let attributesRes = null;

        if (hasValue.length > 0 && hasValue[0].attributes.length > 0) {
          attributesRes = hasValue[0].attributes;
          // handle data
          if (attributesRes !== null && attributesRes.length > 0) {
            let newAttributeData: any = { ...attributes };
            if (sectionHasDocuments.length > 0) {
              let sectFlatFiles = [];
              // getDocuments for Flat
              for (const AC of sectionHasDocuments) {
                if (newAttributeData.hasOwnProperty(AC)) {
                  await showLoader();
                  const getDocument = await APICall(getDocuments, "POST", {
                    TId: section?.SN == "AgencyJobDescription" ? TId : CId,
                    AC: AC,
                  });

                  if (
                    getDocument &&
                    getDocument?.data !== null &&
                    getDocument?.data?.files?.length > 0
                  ) {
                    if (AC === "AGJARES") {
                      let resumeDoc = await getDocument.data.files;
                      resumeDoc = resumeDoc.filter(
                        (doc) => doc.Id === state?.TId
                      ); //resume of that mrfId
                      sectFlatFiles.push(resumeDoc);
                      //await setSelectedFilesForFlat((prevSelectedFiles) => [...prevSelectedFiles,resumeDoc]);
                    } else {
                      if (getDocument?.data?.files?.length > 0) {
                        //await setSelectedFilesForFlat((prevSelectedFiles) => [...prevSelectedFiles,getDocument.data.files]);
                        sectFlatFiles.push(getDocument.data.files);
                      }
                    }
                    setSelectedFilesForFlat(sectFlatFiles.flat());
                  } else {
                    setSelectedFilesForFlat([]);
                  }
                  await hideLoader();
                }
              }
            }
            attributesRes.forEach(async (eachAttributeRes) => {
              if (eachAttributeRes.type === "Attribute") {
                //Attributes
                if (
                  Object.keys(attributes).length > 0 &&
                  eachAttributeRes.details === null
                ) {
                  let newAttributeData = { ...attributes };
                  let dropdownOptions = { ...dropdownOptionsObj };

                  Object.keys(newAttributeData).forEach(async (attribute) => {
                    if (
                      Object.keys(dropdownOptions).find(
                        (option) =>
                          option.toLowerCase() === attribute.toLowerCase()
                      ) &&
                      dropdownOptions[attribute] !== "SearchableDropdownSingle"
                    ) {
                      const valueForDropdown = attributesRes?.find(
                        (attri) => attri.key === attribute
                      )?.value;

                      const options = dropdownOptions[attribute];

                      if (options !== undefined && options.length > 0) {
                        const option = options.find(
                          (attri) => attri?.value == valueForDropdown
                        );

                        newAttributeData = {
                          ...newAttributeData,
                          [attribute]: option === undefined ? null : option,
                        };
                      }
                    } else {
                      let valueForField = attributesRes?.find(
                        (attri) => attri.key === attribute
                      )?.value;

                      //check type later when actual data
                      valueForField =
                        valueForField === undefined
                          ? ""
                          : typeof valueForField === "string" &&
                            valueForField.toLowerCase() === "true"
                          ? true
                          : typeof valueForField === "string" &&
                            valueForField.toLowerCase() === "false"
                          ? false
                          : valueForField !== null &&
                            valueForField !== undefined
                          ? valueForField?.toString()
                          : valueForField;

                      // format date
                      const dateString = valueForField;
                      const allowedFormat = [
                        "DD-MM-YYYY hh.mm.ss A",
                        "DD/MM/YYYY hh.mm.ss A",
                      ];

                      const isValidDate = moment(
                        dateString,
                        allowedFormat,
                        true
                      ).isValid();
                      if (
                        isValidDate &&
                        dateString !== "01-01-0001 12.00.00 AM" &&
                        dateString !== "1/1/0001 12:00:00 AM"
                      ) {
                        const parsedDate = moment(
                          valueForField,
                          allowedFormat[0]
                        );
                        const formattedDate = parsedDate.format("DD-MMM-YYYY");
                        valueForField = formattedDate;
                      } else if (
                        dateString === "01-01-0001 12.00.00 AM" ||
                        dateString === "1/1/0001 12:00:00 AM"
                      ) {
                        valueForField = null;
                      }

                      newAttributeData = {
                        ...newAttributeData,
                        [attribute]: valueForField,
                      };
                    }
                  });

                  setAttributesData(newAttributeData);
                  let postObj = {
                    MN: "AgencyCandidate",
                    IN: "AgencyCandidate_Form",
                    TN: formData.tn,
                    SN: section.SN,
                    TId: TId || 0,
                    CId: CId || userDetails?.Id,
                    Attributes: newAttributeData,
                    UserId: userDetails?.Id,
                    UserEmailId: userDetails?.EmailId,
                    UserName: userDetails?.FirstName,
                    RoleId: currentRoleId?.value,
                  };
                  setGetData((prev) => {
                    let prevSectionIndex = prev?.findIndex(
                      (s) => s.section === section.SN
                    );

                    if (prevSectionIndex !== -1) {
                      // If an item with the same name exists, update it
                      const updatedItems = [...prev];
                      updatedItems[prevSectionIndex] = {
                        section: section.SN,
                        data: postObj,
                        sectionId: section.SId,
                      }; // Update the existing item
                      return updatedItems;
                    } else {
                      // If no item with the same name, add the new item
                      return [
                        ...prev,
                        {
                          section: section.SN,
                          data: postObj,
                          sectionId: section.SId,
                        },
                      ];
                    }
                  });
                }
                // setIsOnce(false);
              } else if (eachAttributeRes.type === "AttributeSet") {
                //AttributesSet
                if (
                  Object.keys(attributeSet).length > 0 &&
                  eachAttributeRes.details !== null &&
                  eachAttributeRes.details.length > 0
                ) {
                  setAttributeSetCode(eachAttributeRes.key);
                  if (sectionHasDocuments.length > 0) {
                    // getDocuments for Set
                    for (const AC of sectionHasDocuments) {
                      if (attributeSet.hasOwnProperty(AC)) {
                        await showLoader();
                        const getDocument = await APICall(
                          getDocuments,
                          "POST",
                          {
                            TId: isIJP ? employeeId : CId,
                            AC: isIJP
                              ? sectionEmployeeCandidateMapping[AC]
                              : AC,
                          }
                        );

                        if (getDocument && getDocument.data !== null) {
                          if (isIJP && getDocument?.data?.files?.length > 0) {
                            let arr = getDocument?.data?.files?.map((i) => {
                              i.AC = AC;
                              return i;
                            });
                            setSelectedFilesForSet(arr);
                          } else {
                            setSelectedFilesForSet(getDocument.data.files);
                          }
                        } else {
                          setSelectedFilesForSet([]);
                        }
                        await hideLoader();
                      }
                    }
                  }
                  const details = eachAttributeRes.details;

                  if (details.length > 0) {
                    let newAttributeSetData = { ...attributeSet };
                    let newAttributeSetDataForGrid = { ...attributeSet };
                    let dropdownOptions = { ...dropdownOptionsObj };

                    const filledData = [];
                    const gridData = [];
                    details.forEach(async (detail) => {
                      detail.IsActive = true;
                      Object.entries(detail).forEach(async (attribute) => {
                        const [Code, Value]: any = attribute;

                        if (
                          Object.keys(dropdownOptions).find(
                            (option) =>
                              option.toLowerCase() === Code.toLowerCase()
                          ) &&
                          dropdownOptions[Code] !== "SearchableDropdownSingle"
                        ) {
                          const options = dropdownOptions[Code];
                          if (options !== undefined && options.length > 0) {
                            const option = options.find(
                              (attri) => attri?.value == Value
                            );

                            newAttributeSetDataForGrid = {
                              ...newAttributeSetDataForGrid,
                              [Code]:
                                option === undefined ? null : option?.label,
                            };

                            newAttributeSetData = {
                              ...newAttributeSetData,
                              [Code]: option === undefined ? null : option,
                            };
                          }
                        } else {
                          //check type later when actual data
                          let valueForGrid =
                            typeof Value === "string" &&
                            Value.toLowerCase() === "true"
                              ? true
                              : typeof Value === "string" &&
                                Value.toLowerCase() === "false"
                              ? false
                              : Value !== null
                              ? Value?.toString()
                              : Value;

                          // format date
                          const dateString = Value;
                          const allowedFormat = [
                            "DD-MM-YYYY hh.mm.ss A",
                            "DD/MM/YYYY hh.mm.ss A",
                          ];

                          const isValidDate = moment(
                            dateString,
                            allowedFormat,
                            true
                          ).isValid();
                          if (
                            isValidDate &&
                            dateString !== "01-01-0001 12.00.00 AM" &&
                            dateString !== "1/1/0001 12:00:00 AM"
                          ) {
                            const parsedDate = moment(Value, allowedFormat[0]);
                            const formattedDate =
                              parsedDate.format("DD-MMM-YYYY");
                            valueForGrid = formattedDate;
                          } else if (
                            dateString === "01-01-0001 12.00.00 AM" ||
                            dateString === "1/1/0001 12:00:00 AM"
                          ) {
                            valueForGrid = null;
                          }

                          let valueForField =
                            typeof Value === "string" &&
                            Value.toLowerCase() === "true"
                              ? true
                              : typeof Value === "string" &&
                                Value.toLowerCase() === "false"
                              ? false
                              : typeof Value === "boolean"
                              ? Value
                              : Value !== null
                              ? Value?.toString()
                              : Value;

                          if (
                            isValidDate &&
                            dateString !== "01-01-0001 12.00.00 AM" &&
                            dateString !== "1/1/0001 12:00:00 AM"
                          ) {
                            const parsedDate = moment(Value, allowedFormat[0]);
                            const formattedDate =
                              parsedDate.format("DD-MMM-YYYY");
                            valueForField = formattedDate;
                          } else if (
                            dateString === "01-01-0001 12.00.00 AM" ||
                            dateString === "1/1/0001 12:00:00 AM"
                          ) {
                            valueForField = null;
                          }

                          newAttributeSetDataForGrid = {
                            ...newAttributeSetDataForGrid,
                            [Code]: valueForGrid,
                          };

                          newAttributeSetData = {
                            ...newAttributeSetData,
                            [Code]: valueForField,
                          };
                        }
                      });
                      gridData.push(newAttributeSetDataForGrid);
                      filledData.push(newAttributeSetData);
                    });
                    let postObj = {
                      MN: "AgencyCandidate",
                      IN: "AgencyCandidate_Form",
                      TN: formData.tn,
                      SN: section.SN,
                      TId: TId || 0,
                      CId: CId || userDetails?.Id,
                      Attributes: filledData,
                      UserId: userDetails?.Id,
                      UserEmailId: userDetails?.EmailId,
                      UserName: userDetails?.FirstName,
                      RoleId: currentRoleId?.value,
                    };
                    setGetData((prev) => {
                      let prevSectionIndex = prev?.findIndex(
                        (s) => s.section === section.SN
                      );

                      if (prevSectionIndex !== -1) {
                        // If an item with the same name exists, update it
                        const updatedItems = [...prev];
                        updatedItems[prevSectionIndex] = {
                          section: section.SN,
                          data: postObj,
                          sectionId: section.SId,
                        }; // Update the existing item
                        return updatedItems;
                      } else {
                        // If no item with the same name, add the new item
                        return [
                          ...prev,
                          {
                            section: section.SN,
                            data: postObj,
                            sectionId: section.SId,
                          },
                        ];
                      }
                    });

                    setDisplayGrid(gridData);
                    setGridData(gridData);
                    setOgFilledAttributeSet(filledData);
                  }
                }
              }
            });
          }
        }
        setIsOnce(false);
      }

      await hideLoader();
    };

    const fillTimer = setTimeout(() => {
      clearTimeout(fillTimer);
      fillData();
    }, 2000);

    return () => {
      clearTimeout(fillTimer);
    };
  }, []);

  useEffect(() => {
    if (
      attributesData !== undefined &&
      Object.keys(attributesData).length > 0 &&
      attributeValidation !== undefined &&
      Object.keys(attributeValidation).length > 0
    ) {
      if (
        (isValidateOnce && isOnce === false && TId !== 0) ||
        (isValidateOnce && isOnce === true && TId === 0)
      ) {
        let attributeValues = { ...attributesData }; // for checking values
        let attributeValObj = { ...attributeValidation };
        Object.keys(attributeValObj).forEach(async (attribute) => {
          if (
            attributeValObj[attribute].DD !== null &&
            attributeValObj[attribute].DD.length > 0
          ) {
            let ad = attributeValObj[attribute].DD;

            ad.forEach(async (record) => {
              //   CCode: "POLENDT",
              // PValue: "false",
              // Condition: "equalTo",
              // Flag: "disable",
              // PDT: "checkbox",
              // CDT: "checkbox",
              let CCode = record.CCode;
              let PValue = record.PValue;
              let Condition = record.Condition;
              let Flag = record.Flag;
              let PDT = record.PDT;
              let CDT = record.CDT;

              if (PDT === "DropdownSingle") {
                if (
                  attributeValues[attribute] &&
                  attributeValues[attribute]?.label
                    ?.toString()
                    .toLowerCase() === PValue.toLowerCase()
                ) {
                  //check condition
                  if (Flag === "disable") {
                    attributeValObj[CCode].isDisable = true;
                    attributeValObj[CCode].ISM = "false";
                  } else if (Flag === "non_mandatory") {
                    attributeValObj[CCode].ISM = "false";
                  }
                } else {
                  attributeValObj[CCode].isDisable = false;
                  attributeValObj[CCode].ISM =
                    attributeValues[attribute]?.label === "Married"
                      ? "true"
                      : CCode === "MARITLSTS"
                      ? "false"
                      : attributeValues[attribute]?.label === "Worker"
                      ? "true"
                      : CCode === "SC"
                      ? "false"
                      : CCode === "VACXDT"
                      ? "false"
                      : CCode === "VACXDT2"
                      ? "false"
                      : CCode === "VACXDT3"
                      ? "false"
                      : "true";
                }
              } else if (PDT.toLowerCase() === "checkbox") {
                if (
                  attributeValues[attribute]?.toString().toLowerCase() ===
                  PValue.toLowerCase()
                ) {
                  //check condition
                  if (Flag === "disable") {
                    attributeValObj[CCode].isDisable = true;
                    attributeValObj[CCode].ISM = "false";
                  } else if (Flag === "non_mandatory") {
                    attributeValObj[CCode].ISM = "false";
                  }
                } else {
                  attributeValObj[CCode].isDisable = false;
                  attributeValObj[CCode].ISM = "true";
                }
              }
            });
          }
        });
        setAttributeValidation((prev) => ({ ...prev, ...attributeValObj }));
        setIsValidateOnce(false);
      }
    }
  }, [attributesData, isOnce]);

  // useEffect(() => {
  //   if (
  //     attributesData !== undefined &&
  //     Object.keys(attributesData).length > 0 &&
  //     attributeValidation !== undefined &&
  //     Object.keys(attributeValidation).length > 0 &&
  //     currentGridId != null
  //   ) {
  //     if (
  //       (isValidateOnce && isOnce === false && TId !== 0) ||
  //       (isValidateOnce && isOnce === true && TId === 0)
  //     ) {
  //       let attributeValues = { ...attributesData }; // for checking values
  //       let attributeValObj = { ...attributeValidation };
  //       Object.keys(attributeValObj).forEach(async (attribute) => {
  //         if (
  //           attributeValObj[attribute].DD !== null &&
  //           attributeValObj[attribute].DD.length > 0
  //         ) {
  //           let ad = attributeValObj[attribute].DD;

  //           ad.forEach(async (record) => {
  //             //   CCode: "POLENDT",
  //             // PValue: "false",
  //             // Condition: "equalTo",
  //             // Flag: "disable",
  //             // PDT: "checkbox",
  //             // CDT: "checkbox",
  //             let CCode = record.CCode;
  //             let PValue = record.PValue;
  //             let Condition = record.Condition;
  //             let Flag = record.Flag;
  //             let PDT = record.PDT;
  //             let CDT = record.CDT;

  //             if (PDT === "DropdownSingle") {
  //               if (
  //                 attributeValues[attribute] &&
  //                 attributeValues[attribute]?.label
  //                   ?.toString()
  //                   .toLowerCase() === PValue.toLowerCase()
  //               ) {
  //                 //check condition
  //                 if (Flag === "disable") {
  //                   attributeValObj[CCode].isDisable = true;
  //                   attributeValObj[CCode].ISM = "false";
  //                 } else if (Flag === "non_mandatory") {
  //                   attributeValObj[CCode].ISM = "false";
  //                 }
  //               } else {
  //                 attributeValObj[CCode].isDisable = false;
  //                 attributeValObj[CCode].ISM =
  //                   attributeValues[attribute]?.label === "Married"
  //                     ? "true"
  //                     : CCode === "MARITLSTS"
  //                     ? "false"
  //                     : attributeValues[attribute]?.label === "Worker"
  //                     ? "true"
  //                     : CCode === "SC"
  //                     ? "false"
  //                     : CCode === "VACXDT"
  //                     ? "false"
  //                     : CCode === "VACXDT2"
  //                     ? "false"
  //                     : CCode === "VACXDT3"
  //                     ? "false"
  //                     : "true";
  //               }
  //             } else if (PDT.toLowerCase() === "checkbox") {
  //               if (
  //                 attributeValues[attribute]?.toString().toLowerCase() ===
  //                 PValue.toLowerCase()
  //               ) {
  //                 //check condition
  //                 if (Flag === "disable") {
  //                   attributeValObj[CCode].isDisable = true;
  //                   attributeValObj[CCode].ISM = "false";
  //                 } else if (Flag === "non_mandatory") {
  //                   attributeValObj[CCode].ISM = "false";
  //                 }
  //               } else {
  //                 attributeValObj[CCode].isDisable = false;
  //                 attributeValObj[CCode].ISM = "true";
  //               }
  //             }
  //           });
  //         }
  //       });
  //       setAttributeValidation((prev) => ({ ...prev, ...attributeValObj }));
  //       setIsValidateOnce(false);
  //     }
  //   }
  // }, [currentGridId]);

  function parseCustomDateFormat(dateString) {
    const parsedDate = moment(dateString, "DD-MMM-YYYY", true); // 'true' for strict parsing

    if (parsedDate.isValid()) {
      return parsedDate.toDate();
    } else {
      console.error("Invalid date format");
      return null;
    }
  }

  function doDatesOverlap(newFromDate, newToDate, existingRanges) {
    newFromDate = parseCustomDateFormat(newFromDate);
    newToDate = parseCustomDateFormat(newToDate);

    for (const range of existingRanges) {
      const existingFromDate = parseCustomDateFormat(range.fromDate);
      const existingToDate = parseCustomDateFormat(range.toDate);

      if (
        moment(newFromDate).isBetween(
          existingFromDate,
          existingToDate,
          null,
          "[]"
        ) ||
        moment(newToDate).isBetween(
          existingFromDate,
          existingToDate,
          null,
          "[]"
        ) ||
        moment(existingFromDate).isBetween(
          newFromDate,
          newToDate,
          null,
          "[]"
        ) ||
        moment(existingToDate).isBetween(newFromDate, newToDate, null, "[]")
      ) {
        return true; // Overlapping ranges found
      }
    }

    return false; // No overlapping ranges found
  }

  const action = {
    name: "Id",
    label: "Action",
    options: {
      filter: false,
      sort: false,
      display: true,
      setCellProps: () => ({
        style: { textAlign: "center" },
      }),
      setCellHeaderProps: () => ({
        style: { textAlign: "center" },
      }),
      customBodyRender: (value, tableMeta) => {
        let Id = tableMeta.tableData[tableMeta.rowIndex].Id;

        return (
          <div className="d-flex justify-content-center">
            {section?.ISEDIT && (
              <Tooltip title="edit">
                <span
                  style={{ cursor: "pointer" }}
                  className="mr-2"
                  onClick={(e) => {
                    e.preventDefault();
                    setFormErrors({});
                    setCurrentGridId(Id);
                    setMultiDropdown(false);
                  }}
                >
                  <i className="fas fa-edit"></i>
                </span>
              </Tooltip>
            )}
            {(!isDisableApplication || !isIJP) && (
              <>
                {section?.ISEDIT && (
                  <Tooltip title="delete">
                    <a
                      className="mr-2"
                      onClick={(e) => {
                        e.preventDefault();
                        setFormErrors({});
                        setDeleteGridId(Id);
                      }}
                    >
                      <i className="fa fa-trash" aria-hidden="true"></i>
                    </a>
                  </Tooltip>
                )}
              </>
            )}
          </div>
        );
      },
    },
  };

  useEffect(() => {
    if (deleteGridId && currentGridId === null) {
      //to disable grid delete button when edit is active
      setGridData((prev) => {
        const newState = prev
          .map((record) => {
            if (record.Id === deleteGridId) {
              // Check if Id is a string/uuid, remove the entry from state
              if (typeof record.Id === "string" && isNaN(record.Id)) {
                return null; // Return null to filter this entry out
              }
              // Update the value for numeric Id
              return { ...record, IsActive: false };
            } else {
              return record;
            }
          })
          .filter((record) => record !== null);
        return newState;
      });

      setOgFilledAttributeSet((prev) => {
        const newState = prev
          .map((record) => {
            if (record.Id === deleteGridId) {
              if (typeof record.Id === "string" && isNaN(record.Id)) {
                return null; // Return null to filter this entry out
              }
              return {
                ...record,
                IsActive: false,
              };
            } else {
              return record;
            }
          })
          .filter((record) => record !== null);
        return newState;
      });

      setDisplayGrid((prev) =>
        prev.filter((record) => record.Id !== deleteGridId)
      );

      //delete documents for that grid record
      let fileToDelete = selectedFilesForSet.filter((sf) => {
        return sf.Id == deleteGridId;
      });
      if (fileToDelete.length > 0) {
        fileToDelete.forEach((file) => {
          handleDeleteFileForSet(file, 0);
        });
      }
    }
    setDeleteGridId(null);
  }, [deleteGridId]);

  const gridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    // count: gridData.length,
    rowsPerPage: gridData.length,
    // page: page,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",
    onChangeRowsPerPage: (num) => {
      //   setLimit(num);
      //   setNxtPgInfo("");
      //   setPrevPgInfo("");
      //   setIsPrevOrNext("");
    },
    // onSearchChange: (searchText) => {
    //   if (searchText !== null) {
    //     setSearchText(searchText);
    //   } else {
    //     setSearchText("");
    //   }
    // },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        // await setDashboardSortColumn(sortColumn);
        // await setDashboardSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        // await setDashboardSortColumn(sortColumn);
        // await setDashboardSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      // await setPage(page);
      // await setDashboardStart(page * dashboardPageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const dependentValidations = (
    targetValue,
    targetAttributeCode,
    attributeType = "Attribute",
    elsepart = null,
    isEffect = false
  ) => {
    //  ;
    if (attributeType === "Attribute") {
      let attributeValues = { ...attributesData }; // for checking values
      let attributeValObj = { ...attributeValidation };

      if (
        attributeValObj[targetAttributeCode].DD !== null &&
        attributeValObj[targetAttributeCode].DD.length > 0
      ) {
        let ad = attributeValObj[targetAttributeCode].DD;

        ad.forEach(async (record) => {
          let CCode = record.CCode;
          let PValue = record.PValue;
          let Condition = record.Condition;
          let Flag = record.Flag;
          let PDT = record.PDT;
          let CDT = record.CDT;

          if (PDT === "DropdownSingle") {
            if (
              targetValue &&
              targetValue?.label.toString().toLowerCase() ===
                PValue?.toLowerCase()
            ) {
              //check condition
              if (Flag === "disable") {
                attributeValObj[CCode].isDisable = true;
                attributeValObj[CCode].ISM = "false";
              } else if (Flag === "non_mandatory") {
                attributeValObj[CCode].ISM = "false";
              }
            } else {
              attributeValObj[CCode].isDisable = false;
              attributeValObj[CCode].ISM =
                targetValue?.label === "Married"
                  ? "true"
                  : CCode === "MARITLSTS"
                  ? "false"
                  : targetValue?.label === "Worker"
                  ? "true"
                  : CCode === "SC"
                  ? "false"
                  : CCode === "VACXDT"
                  ? "false"
                  : CCode === "VACXDT2"
                  ? "false"
                  : CCode === "VACXDT3"
                  ? "false"
                  : "true";
            }
          } else if (PDT?.toLowerCase() === "checkbox") {
            if (
              targetValue?.toString()?.toLowerCase() === PValue.toLowerCase()
            ) {
              //check condition
              if (Flag === "disable") {
                attributeValObj[CCode].isDisable = true;
                attributeValObj[CCode].ISM = "false";
              } else if (Flag === "non_mandatory") {
                attributeValObj[CCode].ISM = "false";
              }
            } else {
              attributeValObj[CCode].isDisable = false;
              attributeValObj[CCode].ISM = "true";
            }
          }

          //clear values based to CDT
          attributeValues[CCode] =
            CDT === "DropdownSingle" ||
            CDT === "DropdownMultiple" ||
            CDT === "Date" ||
            CDT === "SearchableDropdownSingle"
              ? null
              : CDT === "Text" || CDT === "Number"
              ? ""
              : CDT === "Checkbox"
              ? false
              : "";
        });

        attributeValues[targetAttributeCode] = targetValue; //imp
        setFormErrors({});
        setAttributeValidation((prev) => ({ ...prev, ...attributeValObj }));
        setAttributesData((prev) => ({
          ...prev,
          ...attributeValues,
        }));
      }
    } else {
      let attributeValues = { ...attributeSetData }; // for checking values
      let attributeValObj = { ...attributeSetValidation };

      if (
        attributeValObj[targetAttributeCode].DD !== null &&
        attributeValObj[targetAttributeCode].DD.length > 0
      ) {
        let ad = attributeValObj[targetAttributeCode].DD;

        ad.forEach(async (record) => {
          let CCode = record.CCode;
          let PValue = record.PValue;
          let Condition = record.Condition;
          let Flag = record.Flag;
          let PDT = record.PDT;
          let CDT = record.CDT;

          if (PDT === "DropdownSingle") {
            if (
              targetValue &&
              targetValue.label.toString().toLowerCase() ===
                PValue.toLowerCase()
            ) {
              //check condition

              if (Flag === "disable") {
                attributeValObj[CCode].isDisable = elsepart ? false : true;
                attributeValObj[CCode].ISM = elsepart ? "true" : "false";
              } else if (Flag === "non_mandatory") {
                attributeValObj[CCode].ISM = elsepart ? "true" : "false";
              }
            } else {
              attributeValObj[CCode].isDisable = false;
              attributeValObj[CCode].ISM =
                targetValue?.label === "Married"
                  ? "true"
                  : CCode === "MARITLSTS"
                  ? "false"
                  : targetValue?.label === "Worker"
                  ? "true"
                  : CCode === "SC"
                  ? "false"
                  : CCode === "VACXDT"
                  ? "false"
                  : CCode === "VACXDT2"
                  ? "false"
                  : CCode === "VACXDT3"
                  ? "false"
                  : "true";
            }
          } else if (PDT.toLowerCase() === "checkbox") {
            if (targetValue.toString().toLowerCase() === PValue.toLowerCase()) {
              //check condition
              if (Flag === "disable") {
                attributeValObj[CCode].isDisable = true;
                attributeValObj[CCode].ISM = "false";
              } else if (Flag === "non_mandatory") {
                attributeValObj[CCode].ISM = "false";
              }
            } else {
              attributeValObj[CCode].isDisable = false;
              attributeValObj[CCode].ISM = "true";
            }
          }

          //clear values based to CDT
          attributeValues[CCode] =
            CDT === "DropdownSingle" ||
            CDT === "DropdownMultiple" ||
            CDT === "Date" ||
            CDT === "SearchableDropdownSingle"
              ? null
              : CDT === "Text" || CDT === "Number"
              ? ""
              : CDT === "Checkbox"
              ? false
              : "";

          if (!isEffect) {
            let tempSetSelectedFiles =
              selectedFilesForSet?.filter(
                (i) =>
                  i.AC != CCode &&
                  // i.DocId != 0 &&
                  i?.Id != 0 &&
                  i?.Id != null &&
                  i?.Id != undefined
              ) || [];
            setSelectedFilesForSet((prev) => [...tempSetSelectedFiles]);
          }
        });

        attributeValues[targetAttributeCode] = targetValue; //imp
        setFormErrors({});
        setAttributeSetValidation((prev) => ({ ...prev, ...attributeValObj }));
        setAttributeSetData((prev) => ({
          ...prev,
          ...attributeValues,
        }));
      }
    }
  };

  const isValidEmail = (email, emailPattern) => {
    const regex = new RegExp(emailPattern);
    return regex.test(email);
  };

  const validateData = (Attribute) => {
    let errorObj = {};
    let isError = false;
    Attribute.forEach(async (subAttribute) => {
      if (subAttribute.AT === "Attribute") {
        const validation = attributeSetValidation[subAttribute.AC];

        if (validation.ISM.toLowerCase() === "true") {
          if (subAttribute.DT === "Text") {
            if (attributeSetData[subAttribute.AC] === "") {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else if (
              attributeSetData[subAttribute.AC]?.length > validation.MaxC
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: `Max ${validation.MaxC} characters allowed`,
              };
            } else if (validation.PTN != undefined) {
              if (
                isValidEmail(
                  attributeSetData[subAttribute.AC],
                  validation.PTN
                ) != true
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: `Incorrect format.`,
                };
              }
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          } else if (
            subAttribute.DT === "DropdownSingle" ||
            subAttribute.DT === "DropdownMultiple"
          ) {
            if (attributeSetData[subAttribute.AC] === null) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          } else if (subAttribute.DT === "MonthYear") {
            if (attributeSetData[subAttribute.AC] === null) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else if (!moment(attributeSetData[subAttribute.AC]).isValid()) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Please enter a valid date",
              };
            } else if (
              moment(
                validation.MaxV === "CurrentDate" &&
                  attributeSetData[subAttribute.AC]
              ).isAfter(new Date())
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Only till current date allowed",
              };
            } else if (
              moment(
                validation.MinV === "CurrentDate" &&
                  attributeSetData[subAttribute.AC]
              ).isBefore()
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Only after current date allowed",
              };
            } else if (
              validation.MinV !== undefined &&
              validation.MinV !== "CurrentDate" &&
              validation.MinV !== ""
            ) {
              let parentValue = attributeSetData[validation.MinV];
              if (
                parentValue !== null &&
                parentValue !== "" &&
                attributeSetData[subAttribute.AC] !== null &&
                attributeSetData[subAttribute.AC] !== "" &&
                moment(attributeSetData[subAttribute.AC]).isBefore(parentValue)
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Past date not allowed",
                };
              } else if (
                (parentValue === null || parentValue === "") &&
                attributeSetData[subAttribute.AC] !== null &&
                attributeSetData[subAttribute.AC] !== ""
              ) {
                errorObj = {
                  ...errorObj,
                  [validation.MinV]: "Required",
                };
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          } else if (subAttribute.DT === "Date") {
            if (attributeSetData[subAttribute.AC] === null) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else if (!moment(attributeSetData[subAttribute.AC]).isValid()) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Please enter a valid date",
              };
            } else if (!moment(attributesData[subAttribute.AC]).isValid()) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Please enter valid date",
              };
            } else if (
              moment(
                validation.MaxV === "CurrentDate" &&
                  attributeSetData[subAttribute.AC]
              ).isAfter(new Date())
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Only till current date allowed",
              };
            } else if (
              moment(
                validation.MinV === "CurrentDate" &&
                  attributeSetData[subAttribute.AC]
              ).isBefore()
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Only after current date allowed",
              };
            } else if (
              validation.MinV !== undefined &&
              validation.MinV !== "CurrentDate" &&
              validation.MinV !== ""
            ) {
              let parentValue = attributeSetData[validation.MinV];
              if (
                parentValue !== null &&
                parentValue !== "" &&
                attributeSetData[subAttribute.AC] !== null &&
                attributeSetData[subAttribute.AC] !== "" &&
                moment(attributeSetData[subAttribute.AC]).isBefore(parentValue)
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Past date not allowed",
                };
              } else if (
                (parentValue === null || parentValue === "") &&
                attributeSetData[subAttribute.AC] !== null &&
                attributeSetData[subAttribute.AC] !== ""
              ) {
                errorObj = {
                  ...errorObj,
                  [validation.MinV]: "Required",
                };
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          } else if (subAttribute.DT === "Number") {
            //change with regex
            if (attributeSetData[subAttribute.AC] === "") {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else if (
              // isNaN(
              //   attributeSetData[subAttribute.AC]
              // )
              !/^([0-9]+|0)$/.test(attributeSetData[subAttribute.AC])
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Only numbers are allowed",
              };
            } else if (
              Number(attributeSetData[subAttribute.AC]) > validation.MaxV
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: `Max ${validation.MaxV} allowed`,
              };
            } else if (
              Number(attributeSetData[subAttribute.AC]) < validation.MinV
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: `Min ${validation.MinV} required`,
              };
            } else if (validation.PTN != undefined) {
              if (
                isValidEmail(
                  attributeSetData[subAttribute.AC],
                  validation.PTN
                ) != true
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: `Invalid Data`,
                };
              }
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          } else if (subAttribute.DT === "Document") {
            const fileCount = selectedFilesForSet.reduce((total, item) => {
              if (
                item.AC === subAttribute.AC &&
                ((currentGridId && currentGridId == item.Id) || item.Id == 0)
              ) {
                return total + 1;
              } else if (
                item.AC === subAttribute.AC &&
                currentGridId === null &&
                item.Id != 0
              ) {
                return total;
              } else if (
                item.AC === subAttribute.AC &&
                currentGridId !== null &&
                item.Id != 0
              ) {
                return total;
              }
              return total;
            }, 0);

            if (selectedFilesForSet.length === 0 || fileCount === 0) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          }
        } else {
          // for num and date
          if (subAttribute.DT === "Number") {
            if (attributeSetData[subAttribute.AC] !== "") {
              //change with regex
              if (
                // isNaN(
                //   attributeSetData[
                //     subAttribute.AC
                //   ]
                // )
                !/^([0-9]+|0)$/.test(attributeSetData[subAttribute.AC])
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Only numbers are allowed",
                };
              } else if (
                Number(attributeSetData[subAttribute.AC]) > validation.MaxV
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: `Max ${validation.MaxV} allowed`,
                };
              } else if (
                Number(attributeSetData[subAttribute.AC]) < validation.MinV
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: `Min ${validation.MinV} required`,
                };
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }
            }
          } else if (subAttribute.DT === "Date") {
            if (attributeSetData[subAttribute.AC] !== null) {
              if (!moment(attributesData[subAttribute.AC]).isValid()) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Please enter valid Date",
                };
              } else if (
                moment(
                  validation.MaxV === "CurrentDate" &&
                    attributeSetData[subAttribute.AC]
                ).isAfter(new Date())
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Only till current date allowed",
                };
              } else if (
                moment(
                  validation.MinV === "CurrentDate" &&
                    attributeSetData[subAttribute.AC]
                ).isBefore()
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Only after current date allowed",
                };
              } else if (
                validation.MinV !== undefined &&
                validation.MinV !== "CurrentDate" &&
                validation.MinV !== ""
              ) {
                let parentValue = attributeSetData[validation.MinV];
                if (
                  parentValue !== null &&
                  parentValue !== "" &&
                  attributeSetData[subAttribute.AC] !== null &&
                  attributeSetData[subAttribute.AC] !== "" &&
                  moment(attributeSetData[subAttribute.AC]).isBefore(
                    parentValue
                  )
                ) {
                  errorObj = {
                    ...errorObj,
                    [subAttribute.AC]: "Past date not allowed",
                  };
                } else if (
                  (parentValue === null || parentValue === "") &&
                  attributeSetData[subAttribute.AC] !== null &&
                  attributeSetData[subAttribute.AC] !== ""
                ) {
                  errorObj = {
                    ...errorObj,
                    [validation.MinV]: "Required",
                  };
                }
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }
            }
          }
        }
      }
    });

    const isEmpty = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  useEffect(() => {
    if (selectedFilesForSet.length > 0) {
      setSelectedFilesForSet((prevSelectedFiles) => {
        // Create a copy of the previous state with the updated Id values
        const updatedSelectedFiles = prevSelectedFiles.map((item, index) => {
          if (item.Id === undefined || item.Id === 0) {
            // If Id is undefined or 0, assign a new value
            return { ...item, Id: newGridIdCreated };
          } else {
            return item; // Keep existing Id values
          }
        });

        return updatedSelectedFiles;
      });
    }
  }, [newGridIdCreated]);

  useEffect(() => {
    if (addressTypeUniqueIds?.length > 0 && selectedFilesForSet.length > 0) {
      const prevfilesState = selectedFilesForSet;

      const fileWithIdZero = selectedFilesForSet?.find(
        (file) => file?.Id === undefined || file?.Id === 0
      );

      const filteredFilesWithoutZeroId = selectedFilesForSet?.filter(
        (file) => file?.Id !== undefined && file?.Id !== 0
      );

      for (const id of addressTypeUniqueIds) {
        let file = { ...fileWithIdZero };
        file["Id"] = id;
        filteredFilesWithoutZeroId?.push(file);
      }

      setSelectedFilesForSet((prev) => {
        return filteredFilesWithoutZeroId;
      });
    }
  }, [addressTypeUniqueIds]);

  const checkAdharAndEmailValidationAsync = async (EmailId, Aadhar) => {
    let isExists = false;
    try {
      const response = await APICall(
        CheckAdharCardAndEmailIdAlreadyExistsForMRF,
        "POST",
        {
          EmailId,
          Aadhar,
          MRFId: TId,
        }
      );

      if (response?.status == 0) {
        const responseData = response?.data;
        isExists = responseData?.isExists == true;
        if (isExists) {
          notify(1, responseData?.message);
        }
      }
    } catch (error) {
      console.error(error);
    }
    return isExists;
  };

  useEffect(() => {
    //api call to get recruitor remark if any

    if (section.SN === "Resume") {
      (async () => {
        const res = await APICall(getRecruiterRemarkById, "POST", {
          CandidateId: userDetails ? userDetails.Id : 0,
          MRFId: TId || 0,
        });

        if (res.status === 0 && res.data !== null) {
          setRecuiterRemark(res.data.feedback);
        }
      })();
    }
  }, []);

  return (
    <div className="col-lg-12">
      {index == 0 &&
        String(activetab).toLowerCase().trim() ==
          "agencycandidateapplicationform" &&
        showSection === true && (
          <>
            <RecruiterRemarks recuiterRemark={recuiterRemark} />
          </>
        )}
      {index == lastIndex &&
        String(activetab).toLowerCase().trim() ==
          "agencycandidateapplicationform" &&
        showSection === true && (
          <>
            <DeclartionFormSection
              accordion={accordion}
              setAccordion={setAccordion}
              reload={false}
              setReload={() => {}}
              isDisableApplication={isDisableApplication}
              TId={TId}
              WorkflowTaskId={workflowTaskId}
              setWorkflowTaskId={setWorkflowTaskId}
              setShowSection={setShowSection}
              isAnonymousCandidate={IsAnonymousCandidate}
              setDisableBeforeDeclaration={setDisableBeforeDeclaration}
              setRejectCandidate={setRejectCandidate}
              isIJP={isIJP}
              isSection={true}
            />
          </>
        )}
      {showSection === false &&
      String(activetab).toLowerCase().trim() ===
        "agencycandidateapplicationform" ? (
        section.SN === "Resume" && section?.Attribute?.length > 0 ? (
          <Accordion
            elevation={0}
            className="mb-3"
            expanded={accordion}
            onChange={() => setAccordion((prev) => !prev)}
          >
            <AccordionSummary
              id="profile-accordion"
              style={{ background: "#3C5464" }}
              className="text-white acc_close"
              expandIcon={<ExpandMore />}
            >
              <div className="row d-flex align-items-center acc-heading">
                <div className="col-lg-10 col-md-10 col-sm-12">
                  <p style={{ fontSize: "16px" }}>{section.SDN}</p>
                </div>
                <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12">
                  <Tooltip title="clear">
                    <button
                      className="btn ml-lg-0 ml-md-0 ml-1"
                      disabled={!isDisableApplication || !isIJP}
                      onClick={(e) => {
                        e.stopPropagation();
                        //clear data
                        section?.Attribute?.forEach(async (eachAttribute) => {
                          if (eachAttribute.AT === "Attribute") {
                            //change value according to dt
                            setAttributesData((prev) => ({
                              ...prev,
                              [eachAttribute.AC]:
                                eachAttribute.DT === "DropdownSingle" ||
                                eachAttribute.DT === "Date"
                                  ? null
                                  : eachAttribute.DT === "Text" ||
                                    eachAttribute.DT === "Number"
                                  ? ""
                                  : eachAttribute.DT === "Checkbox"
                                  ? false
                                  : "",
                            }));
                          } else if (eachAttribute.AT === "AttributeSet") {
                            setAttributeSetData(ogAttributeSet);
                          }
                        });

                        //clear error
                        setFormErrors({});
                      }}
                    >
                      <i className="fas fa-times-circle"></i>
                    </button>
                  </Tooltip>
                  <Tooltip title="submit">
                    <button
                      className="btn"
                      disabled={
                        disableBeforeDeclaration ||
                        isDisableApplication ||
                        isIJP ||
                        disabledSubmitBtn
                      }
                      onClick={async (e) => {
                        e.stopPropagation();
                        try {
                          if (
                            TId === 0 &&
                            section.SN !== "AgencyJobDescription"
                          ) {
                            notify(1, "Save Basic Candidate Data First");
                          } else {
                            let errorObj = {};
                            section?.Attribute?.map((eachAttribute) => {
                              if (eachAttribute.AT === "Attribute") {
                                const validation =
                                  attributeValidation[eachAttribute.AC];

                                if (validation.ISM.toLowerCase() === "true") {
                                  if (eachAttribute.DT === "Text") {
                                    if (
                                      attributesData[eachAttribute.AC] === ""
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: "Required",
                                      };
                                    } else if (
                                      attributesData[eachAttribute.AC]?.length >
                                      validation.MaxC
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: `Max ${validation.MaxC} characters allowed`,
                                      };
                                    } else if (
                                      eachAttribute.AC === "AGJAEMLID"
                                    ) {
                                      if (
                                        !NationalIdCardType.EMAILID.test(
                                          attributesData[eachAttribute.AC]
                                        )
                                      ) {
                                        errorObj = {
                                          ...errorObj,
                                          [eachAttribute.AC]:
                                            "Invalid Email ID",
                                        };
                                      } else if (!state?.IsIJP) {
                                        const emailRegex =
                                          /^(?!.*@welspun\.com).*$/;

                                        if (
                                          !emailRegex.test(
                                            attributesData[eachAttribute.AC]
                                          )
                                        ) {
                                          errorObj = {
                                            ...errorObj,
                                            [eachAttribute.AC]:
                                              "Invalid Email ID",
                                          };
                                        }
                                      } else {
                                        errorObj = {
                                          ...errorObj,
                                          [eachAttribute.AC]: "",
                                        };
                                      }
                                    } else if (eachAttribute.AC === "AGJAPN") {
                                      if (
                                        !NationalIdCardType.PAN.test(
                                          attributesData[eachAttribute.AC]
                                        )
                                      ) {
                                        errorObj = {
                                          ...errorObj,
                                          [eachAttribute.AC]:
                                            "Invalid Pan Number",
                                        };
                                      } else {
                                        errorObj = {
                                          ...errorObj,
                                          [eachAttribute.AC]: "",
                                        };
                                      }
                                    } else {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: "",
                                      };
                                    }
                                  } else if (
                                    eachAttribute.DT === "DropdownSingle"
                                  ) {
                                    if (
                                      attributesData[eachAttribute.AC] === null
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: "Required",
                                      };
                                    } else {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: "",
                                      };
                                    }
                                  }
                                  if (eachAttribute.DT === "RichText") {
                                    if (
                                      attributesData[eachAttribute.AC] === ""
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: "Required",
                                      };
                                    } else if (
                                      attributesData[eachAttribute.AC]?.length >
                                      validation.MaxC
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: `Max ${validation.MaxC} characters allowed`,
                                      };
                                    } else {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: "",
                                      };
                                    }
                                  } else if (eachAttribute.DT === "Date") {
                                    if (
                                      attributesData[eachAttribute.AC] === null
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: "Required",
                                      };
                                    } else if (
                                      moment(
                                        validation.MaxV === "CurrentDate" &&
                                          attributesData[eachAttribute.AC]
                                      ).isAfter(new Date())
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]:
                                          "Only till current date allowed",
                                      };
                                    } else if (
                                      moment(
                                        validation.MinV === "CurrentDate" &&
                                          attributesData[eachAttribute.AC]
                                      ).isBefore()
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]:
                                          "Only after current date allowed",
                                      };
                                    } else if (
                                      validation.MinV !== undefined &&
                                      validation.MinV !== "CurrentDate" &&
                                      validation.MinV !== ""
                                    ) {
                                      let parentValue =
                                        attributesData[validation.MinV];
                                      if (
                                        parentValue !== null &&
                                        parentValue !== "" &&
                                        attributesData[eachAttribute.AC] !==
                                          null &&
                                        attributesData[eachAttribute.AC] !==
                                          "" &&
                                        moment(
                                          attributesData[eachAttribute.AC]
                                        ).isBefore(parentValue)
                                      ) {
                                        errorObj = {
                                          ...errorObj,
                                          [eachAttribute.AC]:
                                            "Past date not allowed",
                                        };
                                      } else if (
                                        (parentValue === null ||
                                          parentValue === "") &&
                                        attributesData[eachAttribute.AC] !==
                                          null &&
                                        attributesData[eachAttribute.AC] !== ""
                                      ) {
                                        errorObj = {
                                          ...errorObj,
                                          [validation.MinV]: "Required",
                                        };
                                      }
                                    } else {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: "",
                                      };
                                    }
                                  } else if (eachAttribute.DT === "Number") {
                                    //change with regex

                                    if (
                                      attributesData[eachAttribute.AC] === ""
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: "Required",
                                      };
                                    } else if (
                                      // isNaN(attributesData[eachAttribute.AC])
                                      !/^([0-9]+|0)$/.test(
                                        attributesData[eachAttribute.AC]
                                      )
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]:
                                          "Only numbers are allowed",
                                      };
                                    } else if (
                                      Number(attributesData[eachAttribute.AC]) >
                                      validation.MaxV
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: `Max ${validation.MaxV} allowed`,
                                      };
                                    } else if (
                                      Number(attributesData[eachAttribute.AC]) <
                                      validation.MinV
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: `Min ${validation.MinV} required`,
                                      };
                                    } else if (
                                      eachAttribute.AC === "AGJAMOBNM"
                                    ) {
                                      if (
                                        !NationalIdCardType.MOBILENO.test(
                                          attributesData[eachAttribute.AC]
                                        )
                                      ) {
                                        errorObj = {
                                          ...errorObj,
                                          [eachAttribute.AC]:
                                            "Invalid Mobile Number",
                                        };
                                      } else {
                                        errorObj = {
                                          ...errorObj,
                                          [eachAttribute.AC]: "",
                                        };
                                      }
                                    } else if (eachAttribute.AC === "AGJAACN") {
                                      if (
                                        !NationalIdCardType.MOBILENO.test(
                                          attributesData[eachAttribute.AC]
                                        )
                                      ) {
                                        errorObj = {
                                          ...errorObj,
                                          [eachAttribute.AC]:
                                            "Invalid Alternate Contact Number",
                                        };
                                      } else {
                                        errorObj = {
                                          ...errorObj,
                                          [eachAttribute.AC]: "",
                                        };
                                      }
                                    } else if (eachAttribute.AC === "AGJAAN") {
                                      if (
                                        !NationalIdCardType.AADHAR.test(
                                          attributesData[eachAttribute.AC]
                                        )
                                      ) {
                                        errorObj = {
                                          ...errorObj,
                                          [eachAttribute.AC]:
                                            "Invalid Aadhar Number",
                                        };
                                      } else {
                                        errorObj = {
                                          ...errorObj,
                                          [eachAttribute.AC]: "",
                                        };
                                      }
                                    } else {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: "",
                                      };
                                    }
                                  } else if (eachAttribute.DT === "Document") {
                                    const fileCount =
                                      selectedFilesForFlat.reduce(
                                        (total, item) => {
                                          if (item.AC === eachAttribute.AC) {
                                            return total + 1;
                                          }
                                          return total;
                                        },
                                        0
                                      );

                                    if (
                                      selectedFilesForFlat.length === 0 ||
                                      fileCount === 0
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: "Required",
                                      };
                                    } else {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: "",
                                      };
                                    }
                                  }
                                } else {
                                  // for num and date

                                  if (eachAttribute.DT === "Number") {
                                    if (
                                      attributesData[eachAttribute.AC] !== ""
                                    ) {
                                      //change with regex
                                      if (
                                        // isNaN(attributesData[eachAttribute.AC])
                                        !/^([0-9]+|0)$/.test(
                                          attributesData[eachAttribute.AC]
                                        )
                                      ) {
                                        errorObj = {
                                          ...errorObj,
                                          [eachAttribute.AC]:
                                            "Only numbers are allowed",
                                        };
                                      } else if (
                                        Number(
                                          attributesData[eachAttribute.AC]
                                        ) > validation.MaxV
                                      ) {
                                        errorObj = {
                                          ...errorObj,
                                          [eachAttribute.AC]: `Max ${validation.MaxV} allowed`,
                                        };
                                      } else if (
                                        Number(
                                          attributesData[eachAttribute.AC]
                                        ) < validation.MinV
                                      ) {
                                        errorObj = {
                                          ...errorObj,
                                          [eachAttribute.AC]: `Min ${validation.MinV} required`,
                                        };
                                      } else {
                                        errorObj = {
                                          ...errorObj,
                                          [eachAttribute.AC]: "",
                                        };
                                      }
                                    }
                                  } else if (eachAttribute.DT === "Date") {
                                    if (
                                      attributesData[eachAttribute.AC] !== null
                                    ) {
                                      if (
                                        moment(
                                          validation.MaxV === "CurrentDate" &&
                                            attributesData[eachAttribute.AC]
                                        ).isAfter(new Date())
                                      ) {
                                        errorObj = {
                                          ...errorObj,
                                          [eachAttribute.AC]:
                                            "Only till current date allowed",
                                        };
                                      } else if (
                                        moment(
                                          validation.MinV === "CurrentDate" &&
                                            attributesData[eachAttribute.AC]
                                        ).isBefore()
                                      ) {
                                        errorObj = {
                                          ...errorObj,
                                          [eachAttribute.AC]:
                                            "Only after current date allowed",
                                        };
                                      } else if (
                                        validation.MinV !== undefined &&
                                        validation.MinV !== "CurrentDate" &&
                                        validation.MinV !== ""
                                      ) {
                                        let parentValue =
                                          attributesData[validation.MinV];
                                        if (
                                          parentValue !== null &&
                                          parentValue !== "" &&
                                          attributesData[eachAttribute.AC] !==
                                            null &&
                                          attributesData[eachAttribute.AC] !==
                                            "" &&
                                          moment(
                                            attributesData[eachAttribute.AC]
                                          ).isBefore(parentValue)
                                        ) {
                                          errorObj = {
                                            ...errorObj,
                                            [eachAttribute.AC]:
                                              "Past date not allowed",
                                          };
                                        } else if (
                                          (parentValue === null ||
                                            parentValue === "") &&
                                          attributesData[eachAttribute.AC] !==
                                            null &&
                                          attributesData[eachAttribute.AC] !==
                                            ""
                                        ) {
                                          errorObj = {
                                            ...errorObj,
                                            [validation.MinV]: "Required",
                                          };
                                        }
                                      } else {
                                        errorObj = {
                                          ...errorObj,
                                          [eachAttribute.AC]: "",
                                        };
                                      }
                                    }
                                  } else if (eachAttribute.DT === "Text") {
                                    if (eachAttribute.AC === "AGJAPN") {
                                      if (
                                        attributesData[eachAttribute.AC]
                                          ?.length > 0 &&
                                        !NationalIdCardType.PAN.test(
                                          attributesData[eachAttribute.AC]
                                        )
                                      ) {
                                        errorObj = {
                                          ...errorObj,
                                          [eachAttribute.AC]:
                                            "Invalid Pan Number",
                                        };
                                      } else {
                                        errorObj = {
                                          ...errorObj,
                                          [eachAttribute.AC]: "",
                                        };
                                      }
                                    }
                                  }
                                }
                              } else {
                                //submit attributeSet
                              }
                            });

                            const isEmpty = Object.values(errorObj).every(
                              (s) => s === ""
                            );

                            if (isEmpty && attributeSetCode === null) {
                              await showLoader();
                              // post data attribute
                              const postAttributes = [];

                              Object.entries(attributesData).forEach(
                                async (attributeData) => {
                                  const [Code, Value]: any[] = attributeData;

                                  let attribute = {
                                    Type: "Attribute",
                                    Code,
                                    Value:
                                      typeof Value === "object"
                                        ? Value?.value?.toString() || null
                                        : typeof Value === "string" ||
                                          typeof Value === "boolean"
                                        ? Value
                                        : null,
                                  };
                                  postAttributes.push(attribute);
                                }
                              );

                              let postObj = {
                                MN: "AgencyCandidate",
                                IN: "AgencyCandidate_Form",
                                TN: formData.tn,
                                SN: section.SN,
                                TId: TId || 0,
                                CId: CId || userDetails?.Id,
                                Attributes: postAttributes,
                                UserId: userDetails?.Id,
                                UserEmailId: userDetails?.EmailId,
                                UserName: userDetails?.FirstName,
                                RoleId: currentRoleId?.value,
                                IsAnonymousCandidate: IsAnonymousCandidate,
                              };

                              if (
                                state?.IsAnonymousCandidate &&
                                question?.length > 0 &&
                                !declaration
                              ) {
                                await hideLoader();
                                notify(1, "Submit declaration first!");
                                return null;
                              }
                              setDisabledSubmitBtn(true);

                              if (state?.IsAnonymousCandidate) {
                                const isExists =
                                  await checkAdharAndEmailValidationAsync(
                                    attributesData["AGJAEMLID"],
                                    attributesData["AGJAAN"]
                                  );

                                if (isExists) {
                                  setFormErrors((err) => ({
                                    ...err,
                                    ...errorObj,
                                  }));
                                  return;
                                }
                              }

                              const postRes = await APICall(
                                postAttributeValueDetailsV2,
                                "POST",
                                postObj
                              );

                              if (
                                postRes.data !== null &&
                                section.SN === "Resume" &&
                                postRes.data.id !== undefined
                              ) {
                                setCid(postRes.data.id);
                              }

                              setGetData((prev) => {
                                let prevSectionIndex = prev?.findIndex(
                                  (s) => s.section === section.SN
                                );

                                if (prevSectionIndex !== -1) {
                                  // If an item with the same name exists, update it
                                  const updatedItems = [...prev];
                                  updatedItems[prevSectionIndex] = {
                                    section: section.SN,
                                    data: postObj,
                                    sectionId: section.SId,
                                  }; // Update the existing item
                                  return updatedItems;
                                } else {
                                  // If no item with the same name, add the new item
                                  return [
                                    ...prev,
                                    {
                                      section: section.SN,
                                      data: postObj,
                                      sectionId: section.SId,
                                    },
                                  ];
                                }
                              });
                              if (
                                selectedFilesForFlat.length > 0 &&
                                sectionHasDocuments
                              ) {
                                let newFiles = selectedFilesForFlat.filter(
                                  (sf) => {
                                    return sf.DocId === 0;
                                  }
                                );
                                try {
                                  const response = await APICall(
                                    documentUpload,
                                    "POST",
                                    {
                                      ModuleName: "AnonymousCandidate",
                                      files: newFiles,
                                      SectionName: "Resume",
                                      TabName: "AgencyJobApplication",
                                      TId: postRes.data?.id
                                        ? postRes.data.id
                                        : CId || TId,
                                      ModuleId: 10006,
                                      UserId: userDetails?.Id,
                                    }
                                  );

                                  if (
                                    response.data === null ||
                                    response.data === undefined
                                  ) {
                                    notify(1, "Failed to upload documents.");
                                  } else {
                                    setSelectedFilesForFlat([
                                      ...selectedFilesForFlat,
                                      response.data.files,
                                    ]);
                                  }
                                } catch (error) {
                                  throw new Error(
                                    "Error uploading documents: " +
                                      error.message
                                  );
                                }
                              }
                              if (state?.IsAnonymousCandidate) {
                                //submit declaration
                                const response = await APICall(
                                  insertIntoCandidateDeclartion,
                                  "POST",
                                  {
                                    CandidateDeclartionAnswerMappingList:
                                      declaration,
                                    CreatedBy: userDetails ? userDetails.Id : 0,
                                    MRFId: TId || 0,
                                    WorkflowTaskId: workflowTaskId || 0,
                                    CandidateId: postRes.data.id,
                                  }
                                );
                              }

                              if (rejectCandidate) {
                                // call api to reject candidate
                              }

                              await hideLoader();
                              navigate("/careers");
                              notify(postRes.status, postRes.message);
                            } else if (isEmpty && attributeSetCode !== null) {
                              await showLoader();
                              setDisabledSubmitBtn(true);
                              // post data attributeSet
                              let details = [...ogFilledAttributeSet];

                              const finalDetails = [];
                              details.forEach(async (attributes) => {
                                let attributeObj = {};
                                Object.keys(attributes).forEach(
                                  async (attribute) => {
                                    attributeObj = {
                                      ...attributeObj,
                                      [attribute]:
                                        typeof attributes[attribute] ===
                                        "object"
                                          ? attributes[
                                              attribute
                                            ]?.value?.toString() || null
                                          : typeof attributes[attribute] ===
                                              "string" ||
                                            typeof attributes[attribute] ===
                                              "boolean"
                                          ? attributes[attribute]
                                          : null,
                                    };

                                    if (
                                      attribute === "Id" &&
                                      isNaN(Number(attributes[attribute]))
                                    ) {
                                      attributeObj = {
                                        ...attributeObj,
                                        [attribute]: 0,
                                      };
                                    }
                                  }
                                );
                                finalDetails.push(attributeObj);
                              });

                              let postObj = {
                                MN: "AgencyCandidate",
                                IN: "AgencyCandidate_Form",
                                TN: formData.tn,
                                SN: section.SN,
                                TId: TId || 0,
                                CId: CId || userDetails?.Id,
                                Attributes: [
                                  {
                                    Code: attributeSetCode,
                                    Value: "",
                                    Type: "AttributeSet",
                                    Details: finalDetails,
                                  },
                                ],
                              };

                              if (section.SN == "") {
                                const response = await APICall("", "POST", {});
                              }

                              const postRes = await APICall(
                                postAttributeValueDetailsV2,
                                "POST",
                                postObj
                              );

                              if (postRes?.status == 0) {
                                let trackIds = [];

                                setGridData((prevGrid) => {
                                  return prevGrid.map((og, rowIndex) => {
                                    trackIds = [
                                      ...trackIds,
                                      {
                                        prevId: og.Id,
                                        newId:
                                          postRes.data === null
                                            ? 0
                                            : postRes.data[rowIndex],
                                      },
                                    ];

                                    return {
                                      ...og,
                                      Id:
                                        postRes.data === null
                                          ? 0
                                          : postRes.data[rowIndex],
                                    };
                                  });
                                });
                                setDisplayGrid((prevDisplay) => {
                                  return prevDisplay.map((og, rowIndex) => {
                                    const matchedPrevId = trackIds.find(
                                      (track) => track.prevId == og.Id
                                    );
                                    return {
                                      ...og,
                                      Id: matchedPrevId.newId,
                                    };
                                  });
                                });
                              }

                              setGetData((prev) => {
                                let prevSectionIndex = prev?.findIndex(
                                  (s) => s.section === section.SN
                                );

                                if (prevSectionIndex !== -1) {
                                  // If an item with the same name exists, update it
                                  const updatedItems = [...prev];
                                  updatedItems[prevSectionIndex] = {
                                    section: section.SN,
                                    data: postObj,
                                    sectionId: section.SId,
                                  }; // Update the existing item
                                  return updatedItems;
                                } else {
                                  // If no item with the same name, add the new item
                                  return [
                                    ...prev,
                                    {
                                      section: section.SN,
                                      data: postObj,
                                      sectionId: section.SId,
                                    },
                                  ];
                                }
                              });
                              await hideLoader();
                              if (state?.IsAnonymousCandidate) {
                                navigate("/careers");
                              }
                              notify(postRes.status, postRes.message);
                              setDisabledSubmitBtn(false);
                            }

                            setFormErrors((err) => ({
                              ...err,
                              ...errorObj,
                            }));
                          }
                        } catch (error) {
                          console.error(error);
                        } finally {
                          setDisabledSubmitBtn(false);
                          hideLoader();
                        }
                      }}
                    >
                      <i className="fa fa-save"></i>
                    </button>
                  </Tooltip>
                </div>
              </div>
            </AccordionSummary>

            <AccordionDetails className="">
              <div className="row">
                {section?.Attribute?.length > 0 &&
                  section?.Attribute?.map((eachAttribute, index) => {
                    const hideLinkinFields = true;

                    if (
                      eachAttribute.AC == "LinkedInCommonHashTag" ||
                      eachAttribute.AC == "RecruiterHashTag"
                    ) {
                      debugger;
                    }
                    if (
                      (eachAttribute.AC == "LinkedInCommonHashTag" ||
                        eachAttribute.AC == "RecruiterHashTag") &&
                      !isJobPostingDoneOnLinkdin
                    ) {
                      return (
                        <React.Fragment
                          key={`${index}-${eachAttribute.AC}`}
                        ></React.Fragment>
                      );
                    }

                    return (
                      <React.Fragment key={`${index}-${eachAttribute.AC}`}>
                        {eachAttribute.AT === "Attribute" ? (
                          <>
                            {eachAttribute.DT === "DropdownSingle" ? (
                              <>
                                <div
                                  className={
                                    JSON.parse(
                                      eachAttribute.AD
                                    ).IFR.toLowerCase() === "true"
                                      ? "col-lg-12 col-sm-12 col-xs-12 "
                                      : "col-lg-3 col-sm-3 col-xs-4"
                                  }
                                >
                                  <div className="mb-1">
                                    <label className="col-form-label">
                                      {eachAttribute.AN}
                                      {attributeValidation[
                                        eachAttribute.AC
                                      ]?.ISM.toLowerCase() === "true" && (
                                        <sup>*</sup>
                                      )}
                                    </label>

                                    <SelectForm
                                      isClearable
                                      isSearchable
                                      options={
                                        optionsObj[eachAttribute.AC]
                                          ? optionsObj[eachAttribute.AC]
                                          : []
                                      }
                                      placeholder={eachAttribute.AN}
                                      // isDisabled={
                                      //   attributeValidation[eachAttribute.AC]
                                      //     ?.isDisable
                                      // }
                                      isDisabled={
                                        isDisableApplication
                                          ? true
                                          : isIJP
                                          ? true
                                          : attributeValidation[
                                              eachAttribute.AC
                                            ]?.isDisable
                                          ? true
                                          : false
                                      }
                                      value={
                                        attributesData[eachAttribute.AC]
                                          ? attributesData[eachAttribute.AC]
                                          : null
                                      }
                                      onChange={(event) => {
                                        setAttributesData((prev) => ({
                                          ...prev,
                                          [eachAttribute.AC]: event,
                                        }));

                                        if (
                                          event !== null &&
                                          eachAttribute.CC !== null &&
                                          eachAttribute.CC !== ""
                                        ) {
                                          const CC =
                                            eachAttribute.CC.split(",");

                                          CC.forEach(async (childDropdown) => {
                                            setAttributesData((prev) => ({
                                              ...prev,
                                              [childDropdown]: null,
                                            }));

                                            const options =
                                              await getDropdownsData(
                                                childDropdown,
                                                event?.value
                                              );

                                            setOptionsObj((prev) => ({
                                              ...prev,
                                              [childDropdown]: options,
                                            }));
                                          });
                                        }

                                        dependentValidations(
                                          event,
                                          eachAttribute.AC
                                        );
                                      }}
                                      isMulti={false}
                                      noIndicator={false}
                                      noSeparator={false}
                                    />
                                    {formErrors[eachAttribute.AC] && (
                                      <p style={{ color: "red" }}>
                                        {formErrors[eachAttribute.AC]}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : eachAttribute.DT === "Text" ||
                              eachAttribute.DT === "Number" ? (
                              <>
                                <div
                                  className={
                                    JSON.parse(
                                      eachAttribute.AD
                                    ).IFR.toLowerCase() === "true"
                                      ? "col-lg-12 col-sm-12 col-xs-12 "
                                      : "col-lg-3 col-sm-3 col-xs-4"
                                  }
                                >
                                  <div className="mb-1">
                                    <label className="col-form-label">
                                      {eachAttribute.AN}
                                      {attributeValidation[
                                        eachAttribute.AC
                                      ]?.ISM.toLowerCase() === "true" && (
                                        <sup>*</sup>
                                      )}
                                    </label>

                                    <InputForm
                                      className="form-control"
                                      placeholder={
                                        eachAttribute.AC === "AGJAAN"
                                          ? "NNNNNNNNNNNN"
                                          : eachAttribute.AC === "AGJAPN"
                                          ? "AAAAANNNNA"
                                          : eachAttribute.AN
                                      }
                                      // isDisabled={
                                      //   attributeValidation[eachAttribute.AC]
                                      //     ?.isDisable
                                      // }
                                      isDisabled={
                                        isDisableApplication
                                          ? true
                                          : isIJP
                                          ? true
                                          : attributeValidation[
                                              eachAttribute.AC
                                            ]?.isDisable
                                          ? true
                                          : false
                                      }
                                      textArea={false}
                                      value={
                                        attributesData[eachAttribute.AC]
                                          ? attributesData[eachAttribute.AC]
                                          : ""
                                      }
                                      onChange={(e) => {
                                        setAttributesData((prev) => ({
                                          ...prev,
                                          [eachAttribute.AC]: e.target?.value,
                                        }));

                                        dependentValidations(
                                          e.target?.value,
                                          eachAttribute.AC
                                        );
                                      }}
                                      maxLength="255"
                                    />
                                    {formErrors[eachAttribute.AC] && (
                                      <p style={{ color: "red" }}>
                                        {formErrors[eachAttribute.AC]}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : eachAttribute.DT === "Date" ? (
                              <>
                                <div
                                  className={
                                    JSON.parse(
                                      eachAttribute.AD
                                    ).IFR.toLowerCase() === "true"
                                      ? "col-lg-12 col-sm-12 col-xs-12 "
                                      : "col-lg-3 col-sm-3 col-xs-4"
                                  }
                                >
                                  <div className="mb-1">
                                    <label className="col-form-label">
                                      {eachAttribute.AN}
                                      {attributeValidation[
                                        eachAttribute.AC
                                      ]?.ISM.toLowerCase() === "true" && (
                                        <sup>*</sup>
                                      )}
                                    </label>

                                    <DateForm
                                      // isDisabled={
                                      //   attributeValidation[eachAttribute.AC]
                                      //     ?.isDisable
                                      // }
                                      isDisabled={
                                        isDisableApplication
                                          ? true
                                          : isIJP
                                          ? true
                                          : attributeValidation[
                                              eachAttribute.AC
                                            ]?.isDisable
                                          ? true
                                          : false
                                      }
                                      value={
                                        attributesData[eachAttribute.AC]
                                          ? attributesData[eachAttribute.AC]
                                          : null
                                      }
                                      onChange={(date) => {
                                        setAttributesData((prev) => ({
                                          ...prev,
                                          [eachAttribute.AC]:
                                            date != null
                                              ? moment(date).format(
                                                  "DD-MMM-YYYY"
                                                )
                                              : null,
                                        }));

                                        dependentValidations(
                                          moment(date).format("DD-MMM-YYYY"),
                                          eachAttribute.AC
                                        );
                                      }}
                                    />
                                    {formErrors[eachAttribute.AC] && (
                                      <p style={{ color: "red" }}>
                                        {formErrors[eachAttribute.AC]}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : eachAttribute.DT === "Checkbox" ? (
                              <>
                                <div
                                  className={
                                    JSON.parse(
                                      eachAttribute.AD
                                    ).IFR.toLowerCase() === "true"
                                      ? "col-lg-12 col-sm-12 col-xs-12 "
                                      : "col-lg-3 col-sm-3 col-xs-4"
                                  }
                                >
                                  <div className="mb-1">
                                    <label
                                      htmlFor={eachAttribute.AC}
                                      className="col-form-label"
                                    >
                                      {eachAttribute.AN}
                                      {attributeValidation[
                                        eachAttribute.AC
                                      ]?.ISM.toLowerCase() === "true" && (
                                        <sup>*</sup>
                                      )}
                                    </label>

                                    <div>
                                      <input
                                        disabled={
                                          isDisableApplication
                                            ? true
                                            : isIJP
                                            ? true
                                            : attributeValidation[
                                                eachAttribute.AC
                                              ]?.isDisable
                                            ? true
                                            : false
                                        }
                                        type="checkbox"
                                        onChange={(e) => {
                                          setAttributesData((prev) => ({
                                            ...prev,
                                            [eachAttribute.AC]:
                                              e.target.checked,
                                          }));

                                          dependentValidations(
                                            e.target.checked,
                                            eachAttribute.AC
                                          );
                                        }}
                                        id={eachAttribute.AC}
                                        checked={
                                          attributesData[eachAttribute.AC]
                                            ? attributesData[eachAttribute.AC]
                                            : false
                                        }
                                      />
                                    </div>
                                    {formErrors[eachAttribute.AC] && (
                                      <p style={{ color: "red" }}>
                                        {formErrors[eachAttribute.AC]}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : eachAttribute.DT === "Textarea" ? (
                              <>
                                <div
                                  className={
                                    JSON.parse(
                                      eachAttribute.AD
                                    ).IFR.toLowerCase() === "true"
                                      ? "col-lg-12 col-sm-12 col-xs-12 "
                                      : "col-lg-3 col-sm-3 col-xs-4"
                                  }
                                >
                                  <div className="mb-1">
                                    <label className="col-form-label">
                                      {eachAttribute.AN}
                                      {attributeValidation[
                                        eachAttribute.AC
                                      ]?.ISM.toLowerCase() === "true" && (
                                        <sup>*</sup>
                                      )}
                                    </label>

                                    <InputForm
                                      className="form-control"
                                      placeholder={eachAttribute.AN}
                                      // isDisabled={
                                      //   attributeValidation[eachAttribute.AC]
                                      //     ?.isDisable
                                      // }
                                      isDisabled={
                                        isDisableApplication
                                          ? true
                                          : isIJP
                                          ? true
                                          : attributeValidation[
                                              eachAttribute.AC
                                            ]?.isDisable
                                          ? true
                                          : false
                                      }
                                      textArea={true}
                                      value={
                                        attributesData[eachAttribute.AC]
                                          ? attributesData[eachAttribute.AC]
                                          : ""
                                      }
                                      onChange={(e) => {
                                        setAttributesData((prev) => ({
                                          ...prev,
                                          [eachAttribute.AC]: e.target?.value,
                                        }));

                                        dependentValidations(
                                          e.target?.value,
                                          eachAttribute.AC
                                        );
                                      }}
                                      maxLength="255"
                                    />
                                    {formErrors[eachAttribute.AC] && (
                                      <p style={{ color: "red" }}>
                                        {formErrors[eachAttribute.AC]}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : eachAttribute.DT === "Document" ? (
                              <>
                                <div
                                  className={
                                    JSON.parse(
                                      eachAttribute.AD
                                    ).IFR.toLowerCase() === "true"
                                      ? "col-lg-12 col-sm-12 col-xs-12 "
                                      : "col-lg-3 col-sm-3 col-xs-4"
                                  }
                                >
                                  <div className="mb-1">
                                    <label className="col-form-label">
                                      {eachAttribute.AN}
                                      {attributeValidation[
                                        eachAttribute.AC
                                      ]?.ISM.toLowerCase() === "true" && (
                                        <sup>*</sup>
                                      )}
                                    </label>

                                    <div className="box position-relative">
                                      <input
                                        id={`files-${sectionIndex}`}
                                        className="form-control inputfile inputfile-6 multiple-inputfile"
                                        data-multiple-caption="{count} files selected"
                                        multiple={
                                          JSON.parse(eachAttribute.V).FC > 1
                                            ? true
                                            : false
                                        }
                                        ref={FlatDocRef}
                                        accept={JSON.parse(eachAttribute.V).FX}
                                        type="file"
                                        onChange={(e) => {
                                          handleFileChangeForFlat(
                                            e,
                                            eachAttribute
                                          );
                                        }}
                                        value={""}
                                      />
                                      <label
                                        htmlFor={`files-${sectionIndex}`}
                                        className="form-control"
                                        style={{ width: 0, border: "none" }}
                                      >
                                        <strong
                                          style={{
                                            padding: "6px 16px",
                                            backgroundColor: "#3c5464",
                                            borderRadius: "5px",
                                          }}
                                        >
                                          <i
                                            className="fa fa-upload rotate90"
                                            aria-hidden="true"
                                          ></i>
                                          {"  "}
                                          Upload
                                        </strong>{" "}
                                      </label>
                                    </div>
                                    {formErrors[eachAttribute.AC] && (
                                      <p style={{ color: "red" }}>
                                        {formErrors[eachAttribute.AC]}
                                      </p>
                                    )}
                                    <div className="file-added-list">
                                      <ul className="list-unstyle">
                                        {selectedFilesForFlat &&
                                          selectedFilesForFlat.length > 0 &&
                                          selectedFilesForFlat.map(
                                            (fileAttribute, attributeIndex) => (
                                              <>
                                                {fileAttribute.AC ===
                                                  eachAttribute.AC && (
                                                  <li
                                                    className="list mt-1"
                                                    key={attributeIndex}
                                                  >
                                                    <div className="media">
                                                      <div className="media-body text-truncate">
                                                        <span className="view-more">
                                                          {fileAttribute.Name}
                                                        </span>
                                                      </div>
                                                      {/*Here code for view same div of trash should be used. ISDOCVIEW/ISDOCDOWN*/}
                                                      {/* {
                                                  // section?.ISDOCVIEW &&
                                                  fileAttribute.DocId !==
                                                    0 && (
                                                    <div className="media-right ml-2">
                                                      <i
                                                        className="fa fa-eye"
                                                        aria-hidden="true"
                                                        onClick={() =>
                                                          handleViewForFlat(
                                                            fileAttribute,
                                                            attributeIndex
                                                          )
                                                        }></i>
                                                    </div>
                                                  )
                                                } */}
                                                      {
                                                        <>
                                                          {fileAttribute?.Name.split(
                                                            "."
                                                          )
                                                            .pop()
                                                            .toLowerCase() ==
                                                            "pdf" && (
                                                            <div className="media-right ml-2">
                                                              <i
                                                                className="fa fa-eye"
                                                                aria-hidden="true"
                                                                onClick={() =>
                                                                  handleFileView(
                                                                    {
                                                                      header:
                                                                        eachAttribute.AN,
                                                                      fileAttribute:
                                                                        fileAttribute,
                                                                    }
                                                                  )
                                                                }
                                                              ></i>
                                                            </div>
                                                          )}
                                                          <div className="media-right ml-2">
                                                            <i
                                                              className="fa-solid fa-download"
                                                              aria-hidden="true"
                                                              onClick={() =>
                                                                handleDownloadFileFlat(
                                                                  fileAttribute,
                                                                  attributeIndex
                                                                )
                                                              }
                                                            ></i>
                                                          </div>
                                                        </>
                                                      }
                                                      {(isDisableApplication ||
                                                        isIJP) === false &&
                                                        section?.SN !=
                                                          "AgencyJobDescription" &&
                                                        section?.ISEDIT && (
                                                          <div className="media-right ml-2">
                                                            <i
                                                              className="fa fa-trash"
                                                              aria-hidden="true"
                                                              onClick={() =>
                                                                handleDeleteFileForFlat(
                                                                  fileAttribute,
                                                                  attributeIndex
                                                                )
                                                              }
                                                            ></i>
                                                          </div>
                                                        )}
                                                    </div>
                                                  </li>
                                                )}
                                              </>
                                            )
                                          )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : eachAttribute.DT === "RichText" ? (
                              <div
                                className={
                                  JSON.parse(
                                    eachAttribute.AD
                                  ).IFR.toLowerCase() === "true"
                                    ? "col-lg-12 col-sm-12 col-xs-12 "
                                    : "col-lg-3 col-sm-3 col-xs-4"
                                }
                              >
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    {eachAttribute.AN}
                                    {attributeValidation[
                                      eachAttribute.AC
                                    ]?.ISM.toLowerCase() === "true" && (
                                      <sup>*</sup>
                                    )}
                                  </label>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: attributesData[eachAttribute.AC],
                                    }}
                                  />
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : eachAttribute.AT === "AttributeSet" &&
                          eachAttribute.DT === "Custom" ? (
                          <div className="col-lg-12">
                            <div className="row">
                              {eachAttribute.Attribute.length > 0 &&
                                eachAttribute.Attribute.map(
                                  (subAttribute, index) => (
                                    <React.Fragment
                                      key={`${index}-${subAttribute.AC}`}
                                    >
                                      {subAttribute.DT === "DropdownSingle" ? (
                                        <>
                                          <div
                                            className={
                                              JSON.parse(
                                                subAttribute.AD
                                              ).IFR.toLowerCase() === "true"
                                                ? "col-lg-12 col-sm-12 col-xs-12 "
                                                : "col-lg-3 col-sm-3 col-xs-4"
                                            }
                                          >
                                            <div className="mb-1">
                                              <label className="col-form-label">
                                                {subAttribute.AN}
                                                {/* {JSON.parse(
                                              subAttribute.V
                                            ).ISM.toLowerCase() ===
                                              "true" && <sup>*</sup>} */}
                                                {attributeSetValidation[
                                                  subAttribute.AC
                                                ]?.ISM.toLowerCase() ===
                                                  "true" && <sup>*</sup>}
                                              </label>

                                              <SelectForm
                                                isClearable
                                                isSearchable
                                                options={
                                                  optionsObj[subAttribute.AC]
                                                    ? optionsObj[
                                                        subAttribute.AC
                                                      ]
                                                    : []
                                                }
                                                placeholder={subAttribute.AN}
                                                isDisabled={
                                                  isDisableApplication || isIJP
                                                }
                                                value={
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ]
                                                    ? attributeSetData[
                                                        subAttribute.AC
                                                      ]
                                                    : null
                                                }
                                                onChange={(event) => {
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      [subAttribute.AC]: event,
                                                    })
                                                  );

                                                  if (
                                                    event !== null &&
                                                    subAttribute.CC !== null &&
                                                    subAttribute.CC !== ""
                                                  ) {
                                                    const CC =
                                                      subAttribute.CC.split(
                                                        ","
                                                      );

                                                    CC.forEach(
                                                      async (childDropdown) => {
                                                        setAttributeSetData(
                                                          (prev) => ({
                                                            ...prev,
                                                            [childDropdown]:
                                                              null,
                                                          })
                                                        );

                                                        const options =
                                                          await getDropdownsData(
                                                            childDropdown,
                                                            event?.value
                                                          );

                                                        setOptionsObj(
                                                          (prev) => ({
                                                            ...prev,
                                                            [childDropdown]:
                                                              options,
                                                          })
                                                        );
                                                      }
                                                    );
                                                  }

                                                  dependentValidations(
                                                    event,
                                                    subAttribute.AC,
                                                    "AttributeSet"
                                                  );
                                                }}
                                                isMulti={false}
                                                noIndicator={false}
                                                noSeparator={false}
                                              />
                                              {formErrors[subAttribute.AC] && (
                                                <p style={{ color: "red" }}>
                                                  {formErrors[subAttribute.AC]}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ) : subAttribute.DT === "Text" ||
                                        subAttribute.DT === "Number" ? (
                                        <>
                                          <div
                                            className={
                                              JSON.parse(
                                                subAttribute.AD
                                              ).IFR.toLowerCase() === "true"
                                                ? "col-lg-12 col-sm-12 col-xs-12 "
                                                : "col-lg-3 col-sm-3 col-xs-4"
                                            }
                                          >
                                            <div className="mb-1">
                                              <label className="col-form-label">
                                                {subAttribute.AN}
                                                {attributeSetValidation[
                                                  subAttribute.AC
                                                ]?.ISM.toLowerCase() ===
                                                  "true" && <sup>*</sup>}
                                              </label>

                                              <InputForm
                                                className="form-control"
                                                placeholder={subAttribute.AN}
                                                isDisabled={
                                                  isDisableApplication ||
                                                  isIJP ||
                                                  attributeSetValidation[
                                                    subAttribute.AC
                                                  ]?.isDisable ||
                                                  activetab ===
                                                    "AgencyJobDescription"
                                                    ? true
                                                    : false
                                                }
                                                textArea={false}
                                                value={
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ]
                                                    ? attributeSetData[
                                                        subAttribute.AC
                                                      ]
                                                    : ""
                                                }
                                                onChange={(e) => {
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      [subAttribute.AC]:
                                                        e.target?.value,
                                                    })
                                                  );
                                                }}
                                                maxLength="255"
                                              />
                                              {formErrors[subAttribute.AC] && (
                                                <p style={{ color: "red" }}>
                                                  {formErrors[subAttribute.AC]}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ) : subAttribute.DT === "Date" ? (
                                        <>
                                          <div
                                            className={
                                              JSON.parse(
                                                subAttribute.AD
                                              ).IFR.toLowerCase() === "true"
                                                ? "col-lg-12 col-sm-12 col-xs-12 "
                                                : "col-lg-3 col-sm-3 col-xs-4"
                                            }
                                          >
                                            <div className="mb-1">
                                              <label className="col-form-label">
                                                {subAttribute.AN}
                                                {attributeSetValidation[
                                                  subAttribute.AC
                                                ]?.ISM.toLowerCase() ===
                                                  "true" && <sup>*</sup>}
                                              </label>

                                              <DateForm
                                                isDisabled={
                                                  isDisableApplication ||
                                                  isIJP ||
                                                  attributeSetValidation[
                                                    subAttribute.AC
                                                  ]?.isDisable ||
                                                  activetab ===
                                                    "AgencyJobDescription"
                                                    ? true
                                                    : false
                                                }
                                                value={
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ]
                                                    ? attributeSetData[
                                                        subAttribute.AC
                                                      ]
                                                    : null
                                                }
                                                onChange={(date) => {
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      [subAttribute.AC]:
                                                        date != null
                                                          ? moment(date).format(
                                                              "DD-MMM-YYYY"
                                                            )
                                                          : null,
                                                    })
                                                  );
                                                }}
                                              />
                                              {formErrors[subAttribute.AC] && (
                                                <p style={{ color: "red" }}>
                                                  {formErrors[subAttribute.AC]}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ) : subAttribute.DT === "Checkbox" ? (
                                        <>
                                          <div
                                            className={
                                              JSON.parse(
                                                subAttribute.AD
                                              ).IFR.toLowerCase() === "true"
                                                ? "col-lg-12 col-sm-12 col-xs-12 "
                                                : "col-lg-3 col-sm-3 col-xs-4"
                                            }
                                          >
                                            <div className="mb-1">
                                              <label
                                                htmlFor={subAttribute.AC}
                                                className="col-form-label"
                                              >
                                                {subAttribute.AN}
                                                {attributeSetValidation[
                                                  subAttribute.AC
                                                ]?.ISM.toLowerCase() ===
                                                  "true" && <sup>*</sup>}
                                              </label>

                                              <div>
                                                <input
                                                  disabled={
                                                    isDisableApplication ||
                                                    isIJP
                                                  }
                                                  type="checkbox"
                                                  onChange={(e) => {
                                                    setAttributeSetData(
                                                      (prev) => ({
                                                        ...prev,
                                                        [subAttribute.AC]:
                                                          e.target.checked,
                                                      })
                                                    );
                                                  }}
                                                  id={subAttribute.AC}
                                                  checked={
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                      ? attributeSetData[
                                                          subAttribute.AC
                                                        ]
                                                      : false
                                                  }
                                                />
                                              </div>
                                              {formErrors[subAttribute.AC] && (
                                                <p style={{ color: "red" }}>
                                                  {formErrors[subAttribute.AC]}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </React.Fragment>
                                  )
                                )}
                            </div>

                            <div className="row">
                              <div className="col-lg-3 mt-2 mb-3">
                                {section?.ISEDIT && (
                                  <button
                                    disabled={isDisableApplication || isIJP}
                                    id={currentGridId === null ? "0" : "1"}
                                    className="btn btn-primary"
                                    onClick={(e) => {
                                      const targetId = e.currentTarget.id;
                                      if (targetId === "0") {
                                        //insert
                                        if (
                                          typeof attributeSetData[
                                            "CAFISPERADD"
                                          ] === "object" &&
                                          Array.isArray(
                                            attributeSetData["CAFISPERADD"]
                                          ) === true
                                        ) {
                                          if (
                                            section.SN === "AddressInformation"
                                          ) {
                                            const uniqueIds = [];
                                            let key = "CAFISPERADD";
                                            const isError = validateData(
                                              eachAttribute.Attribute
                                            );
                                            if (!isError) {
                                              if (key !== "") {
                                                for (
                                                  let i = 0;
                                                  i <
                                                  attributeSetData[
                                                    "CAFISPERADD"
                                                  ].length;
                                                  i++
                                                ) {
                                                  //
                                                  let attributed =
                                                    attributeSetData[
                                                      "CAFISPERADD"
                                                    ][i];
                                                  let filteredEmail =
                                                    ogFilledAttributeSet.filter(
                                                      (og) => {
                                                        return (
                                                          og.PA?.label ===
                                                            attributed.label &&
                                                          og.IsActive === true
                                                        );
                                                      }
                                                    );
                                                  if (
                                                    filteredEmail.length > 0
                                                  ) {
                                                    notify(
                                                      1,
                                                      attributed.label +
                                                        " Address Type Already Exist, Can't Insert Another."
                                                    );
                                                    return;
                                                  }
                                                }
                                                attributeSetData[`${key}`].map(
                                                  (value, indx) => {
                                                    const uniqueId = uuid();
                                                    uniqueIds.push(uniqueId);
                                                    let isError = false;
                                                    if (!isError) {
                                                      let gridData = {};
                                                      let ogf = {};
                                                      eachAttribute.Attribute.forEach(
                                                        async (
                                                          subAttribute
                                                        ) => {
                                                          if (
                                                            typeof attributeSetData[
                                                              subAttribute.AC
                                                            ] === "object" &&
                                                            Array.isArray(
                                                              attributeSetData[
                                                                subAttribute.AC
                                                              ]
                                                            ) === true
                                                          ) {
                                                            gridData = {
                                                              ...gridData,
                                                              [subAttribute.AC]:
                                                                value.label
                                                                  ? value.label
                                                                  : null, //for MultiSelect Dropdownn
                                                            };
                                                            ogf = {
                                                              ...ogf,
                                                              [subAttribute.AC]:
                                                                value,
                                                            };
                                                            if (
                                                              value.label ===
                                                              "Permanent"
                                                            ) {
                                                              gridData = {
                                                                ...gridData,
                                                                ["CATYPE"]:
                                                                  null, //for date and dropdown
                                                              };
                                                              ogf = {
                                                                ...ogf,
                                                                ["CATYPE"]:
                                                                  null,
                                                                ["CALSD"]: null,
                                                                ["CALED"]: null,
                                                              };
                                                            }
                                                          } else if (
                                                            typeof attributeSetData[
                                                              subAttribute.AC
                                                            ] === "object"
                                                          ) {
                                                            gridData = {
                                                              ...gridData,
                                                              [subAttribute.AC]:
                                                                attributeSetData[
                                                                  subAttribute
                                                                    .AC
                                                                ]?.label ||
                                                                null, //for date and dropdown
                                                            };
                                                            ogf = {
                                                              ...ogf,
                                                              [subAttribute.AC]:
                                                                attributeSetData[
                                                                  subAttribute
                                                                    .AC
                                                                ],
                                                            };
                                                            if (
                                                              value.label ===
                                                              "Permanent"
                                                            ) {
                                                              gridData = {
                                                                ...gridData,
                                                                ["CATYPE"]:
                                                                  null, //for date and dropdown
                                                              };
                                                              ogf = {
                                                                ...ogf,
                                                                ["CATYPE"]:
                                                                  null,
                                                                ["CALSD"]: null,
                                                                ["CALED"]: null,
                                                              };
                                                            }
                                                          } else if (
                                                            typeof attributeSetData[
                                                              subAttribute.AC
                                                            ] === "boolean"
                                                          ) {
                                                            gridData = {
                                                              ...gridData,
                                                              [subAttribute.AC]:
                                                                attributeSetData[
                                                                  subAttribute
                                                                    .AC
                                                                ] === true
                                                                  ? "true"
                                                                  : "false",
                                                            };
                                                            ogf = {
                                                              ...ogf,
                                                              ["CATYPE"]: null,
                                                              ["CALSD"]: null,
                                                              ["CALED"]: null,
                                                            };
                                                          } else {
                                                            gridData = {
                                                              ...gridData,
                                                              [subAttribute.AC]:
                                                                attributeSetData[
                                                                  subAttribute
                                                                    .AC
                                                                ],
                                                            };
                                                            ogf = {
                                                              ...ogf,
                                                              [subAttribute.AC]:
                                                                attributeSetData[
                                                                  subAttribute
                                                                    .AC
                                                                ],
                                                            };
                                                            if (
                                                              value.label ===
                                                              "Permanent"
                                                            ) {
                                                              gridData = {
                                                                ...gridData,
                                                                ["CATYPE"]:
                                                                  null, //for date and dropdown
                                                              };
                                                              ogf = {
                                                                ...ogf,
                                                                ["CATYPE"]:
                                                                  null,
                                                                ["CALSD"]: null,
                                                                ["CALED"]: null,
                                                              };
                                                            }
                                                          }
                                                        }
                                                      );
                                                      setOgFilledAttributeSet(
                                                        (prev) => [
                                                          ...prev,
                                                          {
                                                            ...ogf,
                                                            Id: uniqueId,
                                                            IsActive: true,
                                                          },
                                                        ]
                                                      );
                                                      //reset
                                                      setAttributeSetData(
                                                        (prev) => ({
                                                          ...prev,
                                                          ...ogAttributeSet,
                                                        })
                                                      );

                                                      setFormErrors({});

                                                      setNewGridIdCreated(
                                                        uniqueId
                                                      ); //send uniqueId for document

                                                      setGridData((prev) => [
                                                        ...prev,
                                                        {
                                                          Id: uniqueId,
                                                          ...gridData,
                                                          IsActive: true,
                                                        },
                                                      ]);

                                                      setDisplayGrid((prev) => [
                                                        ...prev,
                                                        {
                                                          Id: uniqueId,
                                                          ...gridData,
                                                          IsActive: true,
                                                        },
                                                      ]);
                                                    }
                                                  }
                                                );
                                                setAddressTypeUniqueIds(
                                                  uniqueIds
                                                );
                                              }
                                            }
                                          }
                                        } else {
                                          const uniqueId = uuid();

                                          //validation for empty data before adding in grid
                                          //
                                          const isError = validateData(
                                            eachAttribute.Attribute
                                          );

                                          if (
                                            !isError &&
                                            section.SN != "BankInformation" &&
                                            section.SN !=
                                              "NationalIDInformation"
                                          ) {
                                            //add record in grid
                                            setOgFilledAttributeSet((prev) => [
                                              ...prev,
                                              {
                                                ...attributeSetData,
                                                Id: uniqueId,
                                                IsActive: true,
                                              },
                                            ]);

                                            let gridData = {};

                                            eachAttribute.Attribute.forEach(
                                              async (subAttribute) => {
                                                if (
                                                  typeof attributeSetData[
                                                    subAttribute.AC
                                                  ] === "object"
                                                ) {
                                                  gridData = {
                                                    ...gridData,
                                                    [subAttribute.AC]:
                                                      attributeSetData[
                                                        subAttribute.AC
                                                      ]?.label || null, //for date and dropdown
                                                  };
                                                } else if (
                                                  typeof attributeSetData[
                                                    subAttribute.AC
                                                  ] === "boolean"
                                                ) {
                                                  gridData = {
                                                    ...gridData,
                                                    [subAttribute.AC]:
                                                      attributeSetData[
                                                        subAttribute.AC
                                                      ] === true
                                                        ? "true"
                                                        : "false",
                                                  };
                                                } else {
                                                  gridData = {
                                                    ...gridData,
                                                    [subAttribute.AC]:
                                                      attributeSetData[
                                                        subAttribute.AC
                                                      ],
                                                  };
                                                }
                                              }
                                            );

                                            //reset
                                            setAttributeSetData((prev) => ({
                                              ...prev,
                                              ...ogAttributeSet,
                                            }));

                                            setFormErrors({});

                                            setNewGridIdCreated(uniqueId); //send uniqueId for document

                                            setGridData((prev) => [
                                              ...prev,
                                              {
                                                Id: uniqueId,
                                                ...gridData,
                                                IsActive: true,
                                              },
                                            ]);

                                            setDisplayGrid((prev) => [
                                              ...prev,
                                              {
                                                Id: uniqueId,
                                                ...gridData,
                                                IsActive: true,
                                              },
                                            ]);

                                            setReset((prev) => !prev);
                                          }
                                          //}
                                        }
                                      } else {
                                        //update
                                        //validation for empty data before updating in grid
                                        const isError = validateData(
                                          eachAttribute.Attribute
                                        );

                                        if (!isError) {
                                          //update // Correct
                                          //Duplicate entries in Education
                                          if (section.SN === "Education") {
                                            let Duplicate = [];
                                            Duplicate =
                                              ogFilledAttributeSet.filter(
                                                (og) => {
                                                  return (
                                                    og.CAFQUALF?.label ===
                                                      attributeSetData[
                                                        "CAFQUALF"
                                                      ].label &&
                                                    og.IsActive === true &&
                                                    og.Id != currentGridId
                                                  );
                                                }
                                              );
                                            if (Duplicate.length > 0) {
                                              notify(
                                                1,
                                                `Qualification ${attributeSetData["CAFQUALF"].label} Already Exist, Can't Insert Another.`
                                              );
                                              return;
                                            }
                                          }

                                          setOgFilledAttributeSet((prev) => {
                                            const newState = prev.map(
                                              (record) => {
                                                if (
                                                  record.Id === currentGridId
                                                ) {
                                                  return {
                                                    ...attributeSetData,
                                                  };
                                                }
                                                return record;
                                              }
                                            );
                                            return newState;
                                          });

                                          let gridData = {};

                                          eachAttribute.Attribute.forEach(
                                            async (subAttribute) => {
                                              if (
                                                typeof attributeSetData[
                                                  subAttribute.AC
                                                ] === "object"
                                              ) {
                                                gridData = {
                                                  ...gridData,
                                                  [subAttribute.AC]:
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ]?.label || null, //for date and dropdown
                                                };
                                              } else if (
                                                typeof attributeSetData[
                                                  subAttribute.AC
                                                ] === "boolean"
                                              ) {
                                                gridData = {
                                                  ...gridData,
                                                  [subAttribute.AC]:
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ] === true
                                                      ? "true"
                                                      : "false",
                                                };
                                              } else {
                                                gridData = {
                                                  ...gridData,
                                                  [subAttribute.AC]:
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ],
                                                };
                                              }
                                            }
                                          );

                                          //reset
                                          setAttributeSetData((prev) => ({
                                            ...prev,
                                            ...ogAttributeSet,
                                          }));

                                          setFormErrors({});

                                          setGridData((prev) => {
                                            const newState = prev.map(
                                              (record) => {
                                                if (
                                                  record.Id === currentGridId
                                                ) {
                                                  return {
                                                    Id: currentGridId,
                                                    ...gridData,
                                                  };
                                                }
                                                return record;
                                              }
                                            );
                                            return newState;
                                          });

                                          setDisplayGrid((prev) => {
                                            const newState = prev.map(
                                              (record) => {
                                                if (
                                                  record.Id === currentGridId
                                                ) {
                                                  return {
                                                    Id: currentGridId,
                                                    ...gridData,
                                                  };
                                                }
                                                return record;
                                              }
                                            );
                                            return newState;
                                          });

                                          setCurrentGridId(null);
                                        }
                                      }
                                    }}
                                  >
                                    <i
                                      className={
                                        currentGridId === null
                                          ? "fa fa-plus"
                                          : "fas fa-edit"
                                      }
                                    ></i>{" "}
                                    {currentGridId === null
                                      ? "Save"
                                      : "Update Record"}
                                  </button>
                                )}
                              </div>

                              <div className="col-lg-12 p-0 mb-3">
                                <DynamicGrid
                                  options={gridOptions}
                                  data={displayGrid}
                                  columns={gridColumns}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </React.Fragment>
                    );
                  })}
              </div>
            </AccordionDetails>
          </Accordion>
        ) : null
      ) : section?.Attribute?.length > 0 ? (
        <Accordion
          elevation={0}
          className="mb-3"
          expanded={accordion}
          onChange={() => setAccordion((prev) => !prev)}
        >
          <AccordionSummary
            id="profile-accordion"
            style={{ background: "#3C5464" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}
          >
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <p style={{ fontSize: "16px" }}>{section.SDN}</p>
              </div>
              <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12">
                <Tooltip title="clear">
                  <button
                    className="btn ml-lg-0 ml-md-0 ml-1"
                    disabled={
                      isDisableApplication
                        ? true
                        : isIJP
                        ? true
                        : activetab === "AgencyJobDescription"
                        ? true
                        : false
                      // (activetab = hasValue?.length == 0 ? false : true)
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      //clear data
                      section?.Attribute?.forEach(async (eachAttribute) => {
                        if (eachAttribute.AT === "Attribute") {
                          //change value according to dt
                          setAttributesData((prev) => ({
                            ...prev,
                            [eachAttribute.AC]:
                              eachAttribute.DT === "DropdownSingle" ||
                              eachAttribute.DT === "Date"
                                ? null
                                : eachAttribute.DT === "Text" ||
                                  eachAttribute.DT === "Number"
                                ? ""
                                : eachAttribute.DT === "Checkbox"
                                ? false
                                : "",
                          }));
                        } else if (eachAttribute.AT === "AttributeSet") {
                          setAttributeSetData(ogAttributeSet);
                        }
                      });

                      //clear error
                      setFormErrors({});
                    }}
                  >
                    <i className="fas fa-times-circle"></i>
                  </button>
                </Tooltip>
                <Tooltip title="submit">
                  <button
                    className="btn"
                    disabled={
                      isDisableApplication
                        ? true
                        : isIJP
                        ? true
                        : activetab === "AgencyJobDescription"
                        ? true
                        : disableBeforeDeclaration
                        ? true
                        : false
                    }
                    onClick={async (e) => {
                      e.stopPropagation();
                      try {
                        setDisabledSubmitBtn(true);

                        if (
                          TId === 0 &&
                          section.SN !== "AgencyJobDescription"
                        ) {
                          notify(1, "Save Basic Candidate Data First");
                        } else {
                          let errorObj = {};
                          section?.Attribute?.map((eachAttribute) => {
                            if (eachAttribute.AT === "Attribute") {
                              const validation =
                                attributeValidation[eachAttribute.AC];

                              if (validation.ISM.toLowerCase() === "true") {
                                if (eachAttribute.DT === "Text") {
                                  if (attributesData[eachAttribute.AC] === "") {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "Required",
                                    };
                                  } else if (
                                    attributesData[eachAttribute.AC]?.length >
                                    validation.MaxC
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: `Max ${validation.MaxC} characters allowed`,
                                    };
                                  } else if (validation.PTN != undefined) {
                                    if (
                                      isValidEmail(
                                        attributesData[eachAttribute.AC],
                                        validation.PTN
                                      ) != true
                                    )
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: `Incorrect format.`,
                                      };
                                  } else {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "",
                                    };
                                  }
                                } else if (
                                  eachAttribute.DT === "DropdownSingle" ||
                                  eachAttribute.DT === "DropdownMultiple"
                                ) {
                                  if (
                                    attributesData[eachAttribute.AC] === null
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "Required",
                                    };
                                  } else {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "",
                                    };
                                  }
                                }
                                if (eachAttribute.DT === "RichText") {
                                  if (attributesData[eachAttribute.AC] === "") {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "Required",
                                    };
                                  } else if (
                                    attributesData[eachAttribute.AC]?.length >
                                    validation.MaxC
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: `Max ${validation.MaxC} characters allowed`,
                                    };
                                  } else {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "",
                                    };
                                  }
                                } else if (eachAttribute.DT === "Date") {
                                  if (
                                    attributesData[eachAttribute.AC] === null
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "Required",
                                    };
                                  } else if (
                                    moment(
                                      validation.MaxV === "CurrentDate" &&
                                        attributesData[eachAttribute.AC]
                                    ).isAfter(new Date())
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]:
                                        "Only till current date allowed",
                                    };
                                  } else if (validation?.MinV == "18") {
                                    const age = moment().diff(
                                      attributesData[eachAttribute.AC],
                                      "years"
                                    );
                                    if (age < 18) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]:
                                          "Age must be 18 or above.",
                                      };
                                    } else {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: "",
                                      };
                                    }
                                  } else if (
                                    moment(
                                      validation.MinV === "CurrentDate" &&
                                        attributesData[eachAttribute.AC]
                                    ).isBefore()
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]:
                                        "Only after current date allowed",
                                    };
                                  } else if (
                                    validation.MinV !== undefined &&
                                    validation.MinV !== "CurrentDate" &&
                                    validation.MinV !== ""
                                  ) {
                                    let parentValue =
                                      attributesData[validation.MinV];
                                    if (
                                      parentValue !== null &&
                                      parentValue !== "" &&
                                      attributesData[eachAttribute.AC] !==
                                        null &&
                                      attributesData[eachAttribute.AC] !== "" &&
                                      moment(
                                        attributesData[eachAttribute.AC]
                                      ).isBefore(parentValue)
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]:
                                          "Past date not allowed",
                                      };
                                    } else if (
                                      (parentValue === null ||
                                        parentValue === "") &&
                                      attributesData[eachAttribute.AC] !==
                                        null &&
                                      attributesData[eachAttribute.AC] !== ""
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [validation.MinV]: "Required",
                                      };
                                    }
                                  } else {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "",
                                    };
                                  }
                                } else if (eachAttribute.DT === "Number") {
                                  //change with regex
                                  if (attributesData[eachAttribute.AC] === "") {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "Required",
                                    };
                                  } else if (
                                    // isNaN(attributesData[eachAttribute.AC])
                                    !/^([0-9]+|0)$/.test(
                                      attributesData[eachAttribute.AC]
                                    )
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]:
                                        "Only numbers are allowed",
                                    };
                                  } else if (
                                    Number(attributesData[eachAttribute.AC]) >
                                    validation.MaxV
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: `Max ${validation.MaxV} allowed`,
                                    };
                                  } else if (
                                    Number(attributesData[eachAttribute.AC]) <
                                    validation.MinV
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: `Min ${validation.MinV} required`,
                                    };
                                  } else if (eachAttribute.AC === "AGJAMOBNM") {
                                    if (
                                      !NationalIdCardType.MOBILENO.test(
                                        attributesData[eachAttribute.AC]
                                      )
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]:
                                          "Invalid Mobile Number",
                                      };
                                    } else {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: "",
                                      };
                                    }
                                  } else if (eachAttribute.AC === "AGJAACN") {
                                    if (
                                      !NationalIdCardType.MOBILENO.test(
                                        attributesData[eachAttribute.AC]
                                      )
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]:
                                          "Invalid Alternate Contact Number",
                                      };
                                    } else {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: "",
                                      };
                                    }
                                  } else if (eachAttribute.AC === "AGJAAN") {
                                    if (
                                      !NationalIdCardType.AADHAR.test(
                                        attributesData[eachAttribute.AC]
                                      )
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]:
                                          "Invalid Aadhar Number",
                                      };
                                    } else {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: "",
                                      };
                                    }
                                  } else {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "",
                                    };
                                  }
                                } else if (eachAttribute.DT === "Document") {
                                  const fileCount = selectedFilesForFlat.reduce(
                                    (total, item) => {
                                      if (item.AC === eachAttribute.AC) {
                                        return total + 1;
                                      }
                                      return total;
                                    },
                                    0
                                  );

                                  if (
                                    selectedFilesForFlat.length === 0 ||
                                    fileCount === 0
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "Required",
                                    };
                                  } else {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "",
                                    };
                                  }
                                }
                              } else {
                                // for num and date
                                if (eachAttribute.DT === "Number") {
                                  if (attributesData[eachAttribute.AC] !== "") {
                                    //change with regex
                                    if (
                                      // isNaN(attributesData[eachAttribute.AC])
                                      !/^([0-9]+|0)$/.test(
                                        attributesData[eachAttribute.AC]
                                      )
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]:
                                          "Only numbers are allowed",
                                      };
                                    } else if (eachAttribute.AC === "AGJAACN") {
                                      if (
                                        !NationalIdCardType.MOBILENO.test(
                                          attributesData[eachAttribute.AC]
                                        )
                                      ) {
                                        errorObj = {
                                          ...errorObj,
                                          [eachAttribute.AC]:
                                            "Invalid Alternate Contact Number",
                                        };
                                      } else {
                                        errorObj = {
                                          ...errorObj,
                                          [eachAttribute.AC]: "",
                                        };
                                      }
                                    } else if (
                                      Number(attributesData[eachAttribute.AC]) >
                                      validation.MaxV
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: `Max ${validation.MaxV} allowed`,
                                      };
                                    } else if (
                                      Number(attributesData[eachAttribute.AC]) <
                                      validation.MinV
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: `Min ${validation.MinV} required`,
                                      };
                                    } else {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: "",
                                      };
                                    }
                                  } else {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "",
                                    };
                                  }
                                } else if (eachAttribute.DT === "Date") {
                                  if (
                                    attributesData[eachAttribute.AC] !== null
                                  ) {
                                    if (
                                      moment(
                                        validation.MaxV === "CurrentDate" &&
                                          attributesData[eachAttribute.AC]
                                      ).isAfter(new Date())
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]:
                                          "Only till current date allowed",
                                      };
                                    } else if (
                                      moment(
                                        validation.MinV === "CurrentDate" &&
                                          attributesData[eachAttribute.AC]
                                      ).isBefore()
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]:
                                          "Only after current date allowed",
                                      };
                                    } else if (
                                      validation.MinV !== undefined &&
                                      validation.MinV !== "CurrentDate" &&
                                      validation.MinV !== ""
                                    ) {
                                      let parentValue =
                                        attributesData[validation.MinV];
                                      if (
                                        parentValue !== null &&
                                        parentValue !== "" &&
                                        attributesData[eachAttribute.AC] !==
                                          null &&
                                        attributesData[eachAttribute.AC] !==
                                          "" &&
                                        moment(
                                          attributesData[eachAttribute.AC]
                                        ).isBefore(parentValue)
                                      ) {
                                        errorObj = {
                                          ...errorObj,
                                          [eachAttribute.AC]:
                                            "Past date not allowed",
                                        };
                                      } else if (
                                        (parentValue === null ||
                                          parentValue === "") &&
                                        attributesData[eachAttribute.AC] !==
                                          null &&
                                        attributesData[eachAttribute.AC] !== ""
                                      ) {
                                        errorObj = {
                                          ...errorObj,
                                          [validation.MinV]: "Required",
                                        };
                                      }
                                    } else {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: "",
                                      };
                                    }
                                  }
                                } else if (eachAttribute.DT === "Text") {
                                  if (eachAttribute.AC === "AGJAPN") {
                                    if (
                                      attributesData[eachAttribute.AC]?.length >
                                        0 &&
                                      !NationalIdCardType.PAN.test(
                                        attributesData[eachAttribute.AC]
                                      )
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]:
                                          "Invalid Pan Number",
                                      };
                                    } else {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: "",
                                      };
                                    }
                                  }
                                }
                              }
                            } else {
                              //submit attributeSet
                            }
                          });

                          const isEmpty = Object.values(errorObj).every(
                            (s) => s === ""
                          );

                          if (isEmpty && attributeSetCode === null) {
                            await showLoader();
                            // post data attribute
                            const postAttributes = [];

                            Object.entries(attributesData).forEach(
                              async (attributeData) => {
                                const [Code, Value]: any[] = attributeData;

                                let attribute = {
                                  Type: "Attribute",
                                  Code,
                                  Value:
                                    typeof Value === "object"
                                      ? Value?.value?.toString() || null
                                      : typeof Value === "string" ||
                                        typeof Value === "boolean"
                                      ? Value
                                      : null,
                                };
                                postAttributes.push(attribute);
                              }
                            );

                            let postObj = {
                              MN: "AgencyCandidate",
                              IN: "AgencyCandidate_Form",
                              TN: formData.tn,
                              SN: section.SN,
                              TId: TId || 0,
                              CId: CId || userDetails?.Id,
                              Attributes: postAttributes,
                              UserId: userDetails?.Id,
                              UserEmailId: userDetails?.EmailId,
                              UserName: userDetails?.FirstName,
                              RoleId: currentRoleId?.value,
                            };

                            if (state?.IsAnonymousCandidate) {
                              const isExists =
                                await checkAdharAndEmailValidationAsync(
                                  attributesData["AGJAEMLID"],
                                  attributesData["AGJAAN"]
                                );

                              if (isExists) {
                                setFormErrors((err) => ({
                                  ...err,
                                  ...errorObj,
                                }));
                                return;
                              }
                            }

                            const postRes = await APICall(
                              postAttributeValueDetailsV2,
                              "POST",
                              postObj
                            );
                            // if (
                            //   postRes.data !== null &&
                            //   section.SN === "AgencyJobDescription" &&
                            //   postRes.data.id !== undefined
                            // ) {
                            //   setTId(postRes.data.id);
                            // }
                            if (
                              postRes.data !== null &&
                              section.SN === "Resume" &&
                              postRes.data.id !== undefined
                            ) {
                              setCid(postRes.data.id);
                            }
                            setGetData((prev) => {
                              let prevSectionIndex = prev?.findIndex(
                                (s) => s.section === section.SN
                              );

                              if (prevSectionIndex !== -1) {
                                // If an item with the same name exists, update it
                                const updatedItems = [...prev];
                                updatedItems[prevSectionIndex] = {
                                  section: section.SN,
                                  data: postObj,
                                  sectionId: section.SId,
                                }; // Update the existing item
                                return updatedItems;
                              } else {
                                // If no item with the same name, add the new item
                                return [
                                  ...prev,
                                  {
                                    section: section.SN,
                                    data: postObj,
                                    sectionId: section.SId,
                                  },
                                ];
                              }
                            });

                            if (
                              selectedFilesForFlat.length > 0 &&
                              sectionHasDocuments
                            ) {
                              let newFiles = selectedFilesForFlat.filter(
                                (sf) => {
                                  return sf.DocId === 0;
                                }
                              );
                              try {
                                const response = await APICall(
                                  documentUpload,
                                  "POST",
                                  {
                                    ModuleName: "AgencyCandidate",
                                    files: newFiles,
                                    SectionName: section.SN,
                                    TabName: "AgencyCandidateApplicationForm",
                                    TId: postRes.data?.id
                                      ? postRes.data.id
                                      : CId || userDetails?.Id,
                                    ModuleId: 10005,
                                    UserId: userDetails?.Id,
                                  }
                                );

                                if (
                                  response.data === null ||
                                  response.data === undefined
                                ) {
                                  notify(1, "Failed to upload documents.");
                                } else {
                                  setSelectedFilesForFlat([
                                    ...selectedFilesForFlat,
                                    response.data.files,
                                  ]);
                                }
                              } catch (error) {
                                throw new Error(
                                  "Error uploading documents: " + error.message
                                );
                              }
                            }

                            await hideLoader();
                            if (state?.IsAnonymousCandidate) {
                              navigate("/careers");
                            }
                            notify(postRes.status, postRes.message);
                          } else if (isEmpty && attributeSetCode !== null) {
                            await showLoader();
                            // post data attributeSet
                            let details = [...ogFilledAttributeSet];

                            //Formal Education to have 2 entries
                            // if (section.SN === "Education") {
                            //   let ActiveData = ogFilledAttributeSet.filter(
                            //     (og) => {
                            //       return og.IsActive === true;
                            //     }
                            //   );
                            //   if (ActiveData.length > 0) {
                            //     let filtereddata = ogFilledAttributeSet.filter(
                            //       (og) => {
                            //         return (
                            //           (og.CAFQUALF?.label === "10th" ||
                            //             og.CAFQUALF?.label === "SSC") &&
                            //           og.IsActive === true
                            //         );
                            //       }
                            //     );

                            //     if (filtereddata.length == 0) {
                            //       await hideLoader();
                            //       notify(1, "Please Enter your 10th details.");
                            //       return;
                            //     }

                            //     if (
                            //       ogFilledAttributeSet.length <= 1 &&
                            //       filtereddata.length != 0
                            //     ) {
                            //       await hideLoader();
                            //       notify(
                            //         1,
                            //         "Please Enter your Graduation Or Diploma details."
                            //       );
                            //       return;
                            //     }
                            //   }
                            // }

                            const finalDetails = [];
                            details.forEach(async (attributes) => {
                              let attributeObj = {};
                              Object.keys(attributes).forEach(
                                async (attribute) => {
                                  attributeObj = {
                                    ...attributeObj,
                                    [attribute]:
                                      typeof attributes[attribute] === "object"
                                        ? attributes[
                                            attribute
                                          ]?.value?.toString() || null
                                        : typeof attributes[attribute] ===
                                            "string" ||
                                          typeof attributes[attribute] ===
                                            "boolean"
                                        ? attributes[attribute]
                                        : null,
                                  };

                                  if (
                                    attribute === "Id" &&
                                    isNaN(Number(attributes[attribute]))
                                  ) {
                                    attributeObj = {
                                      ...attributeObj,
                                      [attribute]: 0,
                                    };
                                  } else if (
                                    attribute === "Id" &&
                                    !isNaN(Number(attributes[attribute]))
                                  ) {
                                    attributeObj = {
                                      ...attributeObj,
                                      [attribute]: attributes[attribute],
                                    };
                                  }
                                }
                              );
                              finalDetails.push(attributeObj);
                            });

                            let postObj = {
                              MN: "AgencyCandidate",
                              IN: "AgencyCandidate_Form",
                              TN: formData.tn,
                              SN: section.SN,
                              TId: TId || 0,
                              CId: CId || userDetails?.Id,
                              Attributes: [
                                {
                                  Code: attributeSetCode,
                                  Value: "",
                                  Type: "AttributeSet",
                                  Details: finalDetails,
                                },
                              ],
                            };

                            const postRes = await APICall(
                              postAttributeValueDetailsV2,
                              "POST",
                              postObj
                            );

                            if (postRes?.status == 0) {
                              let trackIds = [];
                              setOgFilledAttributeSet((prevOgFilled) => {
                                return prevOgFilled.map((og, rowIndex) => ({
                                  ...og,
                                  Id:
                                    postRes.data === null
                                      ? 0
                                      : postRes.data[rowIndex],
                                }));
                              });

                              setGridData((prevGrid) => {
                                return prevGrid.map((og, rowIndex) => {
                                  trackIds = [
                                    ...trackIds,
                                    {
                                      prevId: og.Id,
                                      newId:
                                        postRes.data === null
                                          ? 0
                                          : postRes.data[rowIndex],
                                    },
                                  ];

                                  return {
                                    ...og,
                                    Id:
                                      postRes.data === null
                                        ? 0
                                        : postRes.data[rowIndex],
                                  };
                                });
                              });

                              setDisplayGrid((prevDisplay) => {
                                return prevDisplay.map((og, rowIndex) => {
                                  const matchedPrevId = trackIds.find(
                                    (track) => track.prevId == og.Id
                                  );
                                  return {
                                    ...og,
                                    Id: matchedPrevId.newId,
                                  };
                                });
                              });
                            }

                            if (
                              selectedFilesForSet.length > 0 &&
                              sectionHasDocuments
                            ) {
                              try {
                                let trackIds = [];
                                const prevGridData: any = [...gridData];
                                for (const [
                                  rowIndex,
                                  og,
                                ] of prevGridData.entries()) {
                                  trackIds = [
                                    ...trackIds,
                                    {
                                      prevId: og.Id,
                                      newId:
                                        postRes.data === null
                                          ? 0
                                          : postRes.data[rowIndex],
                                    },
                                  ];
                                }

                                const setFiles = [...selectedFilesForSet];
                                if (postRes.data?.length > 0) {
                                  setFiles.forEach((file, fileIndex) => {
                                    const matchedPrevId = trackIds.find(
                                      (track) => track.prevId == file.Id
                                    );
                                    file.Id = matchedPrevId
                                      ? matchedPrevId.newId
                                      : file.Id;
                                  });
                                }
                                let newFiles = setFiles.filter((sf) => {
                                  return sf.DocId === 0;
                                });

                                if (newFiles.length > 0) {
                                  const response = await APICall(
                                    documentUpload,
                                    "POST",
                                    {
                                      ModuleName: "AgencyCandidate",
                                      files: newFiles,
                                      SectionName: section.SN,
                                      TabName: "AgencyCandidateApplicationForm",
                                      TId: CId || userDetails?.Id,
                                      ModuleId: 10005,
                                      UserId: userDetails?.Id,
                                    }
                                  );

                                  if (
                                    response.data === null ||
                                    response.data === undefined
                                  ) {
                                    notify(1, "Failed to upload documents.");
                                  } else {
                                    let docFromApi = response.data.files;
                                    setSelectedFilesForSet((prevFiles) => {
                                      return prevFiles.map((doc) => {
                                        docFromApi = docFromApi.filter(
                                          (file) => file.Id === doc.Id
                                        );
                                        if (doc.Id === docFromApi.Id) {
                                          return {
                                            ...doc,
                                            Data: docFromApi.Data,
                                            DocId: docFromApi.DocId,
                                          };
                                        } else {
                                          return doc;
                                        }
                                      });
                                    });
                                  }
                                }
                              } catch (error) {
                                throw new Error(
                                  "Error uploading documents: " + error.message
                                );
                              }
                            }

                            await hideLoader();
                            setGetData((prev) => {
                              let prevSectionIndex = prev?.findIndex(
                                (s) => s.section === section.SN
                              );

                              if (prevSectionIndex !== -1) {
                                // If an item with the same name exists, update it
                                const updatedItems = [...prev];
                                updatedItems[prevSectionIndex] = {
                                  section: section.SN,
                                  data: postObj,
                                  sectionId: section.SId,
                                }; // Update the existing item
                                return updatedItems;
                              } else {
                                // If no item with the same name, add the new item
                                return [
                                  ...prev,
                                  {
                                    section: section.SN,
                                    data: postObj,
                                    sectionId: section.SId,
                                  },
                                ];
                              }
                            });
                            if (state?.IsAnonymousCandidate) {
                              navigate("/careers");
                            }
                            notify(postRes.status, postRes.message);
                          }

                          setFormErrors((err) => ({
                            ...err,
                            ...errorObj,
                          }));
                        }
                      } catch (error) {
                      } finally {
                        setDisabledSubmitBtn(false);
                      }
                    }}
                  >
                    <i className="fa fa-save"></i>
                  </button>
                </Tooltip>
              </div>
            </div>
          </AccordionSummary>

          <AccordionDetails className="">
            <div className="row">
              {section?.Attribute?.length > 0 &&
                section?.Attribute?.map((eachAttribute, index) => {
                  if (
                    (eachAttribute.AC == "LinkedInCommonHashTag" ||
                      eachAttribute.AC == "RecruiterHashTag") &&
                    !isJobPostingDoneOnLinkdin
                  ) {
                    return (
                      <React.Fragment
                        key={`${index}-${eachAttribute.AC}`}
                      ></React.Fragment>
                    );
                  }

                  return (
                    <React.Fragment key={`${index}-${eachAttribute.AC}`}>
                      {eachAttribute.AT === "Attribute" ? (
                        <>
                          {eachAttribute.AC == "LinkedInCommonHashTag" && (
                            <>
                              <div
                                style={{
                                  color: "#cccccc",
                                  backgroundColor: "#cccccc",
                                  height: 1,
                                  marginTop: 10,
                                }}
                                className="col-lg-12 col-sm-12 col-xs-12"
                              ></div>
                            </>
                          )}
                          {eachAttribute.DT === "DropdownSingle" ? (
                            <>
                              <div
                                className={
                                  JSON.parse(
                                    eachAttribute.AD
                                  ).IFR.toLowerCase() === "true"
                                    ? "col-lg-12 col-sm-12 col-xs-12 "
                                    : "col-lg-3 col-sm-3 col-xs-4"
                                }
                              >
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    {eachAttribute.AN}
                                    {attributeValidation[
                                      eachAttribute.AC
                                    ]?.ISM.toLowerCase() === "true" && (
                                      <sup>*</sup>
                                    )}
                                  </label>

                                  {activetab === "AgencyJobDescription" && (
                                    <>
                                      {(eachAttribute.AC === "JBLEGEN" ||
                                        eachAttribute.AC === "JBDIVSN") && (
                                        <>
                                          <SelectForm
                                            isClearable
                                            isSearchable
                                            options={
                                              optionsObj[eachAttribute.AC]
                                                ? optionsObj[eachAttribute.AC]
                                                : []
                                            }
                                            placeholder={eachAttribute.AN}
                                            isDisabled={
                                              isDisableApplication ||
                                              isIJP ||
                                              attributeValidation[
                                                eachAttribute.AC
                                              ]?.isDisable ||
                                              activetab ===
                                                "AgencyJobDescription"
                                                ? true
                                                : false
                                              //   ||
                                              // (activetab =
                                              //   hasValue?.length == 0 ? false : true)
                                            }
                                            value={
                                              attributesData[eachAttribute.AC]
                                                ? attributesData[
                                                    eachAttribute.AC
                                                  ]
                                                : null
                                            }
                                            onChange={(event) => {
                                              setAttributesData((prev) => ({
                                                ...prev,
                                                [eachAttribute.AC]: event,
                                              }));

                                              if (
                                                event !== null &&
                                                eachAttribute.CC !== null &&
                                                eachAttribute.CC !== ""
                                              ) {
                                                const CC =
                                                  eachAttribute.CC.split(",");

                                                CC.forEach(
                                                  async (childDropdown) => {
                                                    setAttributesData(
                                                      (prev) => ({
                                                        ...prev,
                                                        [childDropdown]: null,
                                                      })
                                                    );

                                                    const options =
                                                      await getDropdownsData(
                                                        childDropdown,
                                                        event?.value
                                                      );

                                                    setOptionsObj((prev) => ({
                                                      ...prev,
                                                      [childDropdown]: options,
                                                    }));
                                                  }
                                                );
                                              }

                                              dependentValidations(
                                                event,
                                                eachAttribute.AC,
                                                "Attribute"
                                              );
                                            }}
                                            isMulti={false}
                                            noIndicator={false}
                                            noSeparator={false}
                                          />
                                          {formErrors[eachAttribute.AC] && (
                                            <p style={{ color: "red" }}>
                                              {formErrors[eachAttribute.AC]}
                                            </p>
                                          )}
                                        </>
                                      )}
                                      {eachAttribute.AC !== "JBLEGEN" &&
                                        eachAttribute.AC !== "JBDIVSN" && (
                                          <>
                                            <p>
                                              {attributesData[eachAttribute.AC]
                                                ? attributesData[
                                                    eachAttribute.AC
                                                  ].label
                                                : ""}
                                            </p>
                                          </>
                                        )}
                                    </>
                                  )}
                                  {activetab !== "AgencyJobDescription" && (
                                    <>
                                      <SelectForm
                                        isClearable
                                        isSearchable
                                        options={
                                          optionsObj[eachAttribute.AC]
                                            ? optionsObj[eachAttribute.AC]
                                            : []
                                        }
                                        placeholder={eachAttribute.AN}
                                        isDisabled={
                                          isDisableApplication ||
                                          isIJP ||
                                          attributeValidation[eachAttribute.AC]
                                            ?.isDisable ||
                                          activetab === "AgencyJobDescription"
                                            ? true
                                            : false
                                          //   ||
                                          // (activetab =
                                          //   hasValue?.length == 0 ? false : true)
                                        }
                                        value={
                                          attributesData[eachAttribute.AC]
                                            ? attributesData[eachAttribute.AC]
                                            : null
                                        }
                                        onChange={(event) => {
                                          setAttributesData((prev) => ({
                                            ...prev,
                                            [eachAttribute.AC]: event,
                                          }));

                                          if (
                                            event !== null &&
                                            eachAttribute.CC !== null &&
                                            eachAttribute.CC !== ""
                                          ) {
                                            const CC =
                                              eachAttribute.CC.split(",");

                                            CC.forEach(
                                              async (childDropdown) => {
                                                setAttributesData((prev) => ({
                                                  ...prev,
                                                  [childDropdown]: null,
                                                }));

                                                const options =
                                                  await getDropdownsData(
                                                    childDropdown,
                                                    event?.value
                                                  );

                                                setOptionsObj((prev) => ({
                                                  ...prev,
                                                  [childDropdown]: options,
                                                }));
                                              }
                                            );
                                          }

                                          dependentValidations(
                                            event,
                                            eachAttribute.AC,
                                            "Attribute"
                                          );
                                        }}
                                        isMulti={false}
                                        noIndicator={false}
                                        noSeparator={false}
                                      />
                                      {formErrors[eachAttribute.AC] && (
                                        <p style={{ color: "red" }}>
                                          {formErrors[eachAttribute.AC]}
                                        </p>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : eachAttribute.DT === "Text" ||
                            eachAttribute.DT === "Number" ? (
                            <>
                              <div
                                className={
                                  JSON.parse(
                                    eachAttribute.AD
                                  ).IFR.toLowerCase() === "true"
                                    ? "col-lg-12 col-sm-12 col-xs-12 "
                                    : "col-lg-3 col-sm-3 col-xs-4"
                                }
                              >
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    {eachAttribute.AN}
                                    {attributeValidation[
                                      eachAttribute.AC
                                    ]?.ISM.toLowerCase() === "true" && (
                                      <sup>*</sup>
                                    )}
                                  </label>

                                  {activetab === "AgencyJobDescription" && (
                                    <>
                                      <p>
                                        {attributesData[eachAttribute.AC]
                                          ? attributesData[eachAttribute.AC]
                                          : ""}
                                      </p>
                                    </>
                                  )}

                                  {activetab !== "AgencyJobDescription" && (
                                    <>
                                      <InputForm
                                        className="form-control"
                                        placeholder={
                                          eachAttribute.AC === "AGJAAN"
                                            ? "NNNNNNNNNNNN"
                                            : eachAttribute.AC === "AGJAPN"
                                            ? "AAAAANNNNA"
                                            : eachAttribute.AN
                                        }
                                        // isDisabled={
                                        //   attributeValidation[eachAttribute.AC]
                                        //     ?.isDisable
                                        // }
                                        isDisabled={
                                          isDisableApplication
                                            ? true
                                            : isIJP
                                            ? true
                                            : attributeValidation[
                                                eachAttribute.AC
                                              ]?.isDisable ||
                                              activetab ===
                                                "AgencyJobDescription"
                                            ? true
                                            : false
                                          //   ||
                                          // (activetab =
                                          //   hasValue?.length == 0 ? false : true)
                                        }
                                        textArea={false}
                                        value={
                                          attributesData[eachAttribute.AC]
                                            ? attributesData[eachAttribute.AC]
                                            : ""
                                        }
                                        onChange={(e) => {
                                          setAttributesData((prev) => ({
                                            ...prev,
                                            [eachAttribute.AC]: e.target?.value,
                                          }));

                                          dependentValidations(
                                            e.target?.value,
                                            eachAttribute.AC,
                                            "Attribute"
                                          );
                                        }}
                                        maxLength="255"
                                      />
                                      {formErrors[eachAttribute.AC] && (
                                        <p style={{ color: "red" }}>
                                          {formErrors[eachAttribute.AC]}
                                        </p>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : eachAttribute.DT === "Date" ? (
                            <>
                              <div
                                className={
                                  JSON.parse(
                                    eachAttribute.AD
                                  ).IFR.toLowerCase() === "true"
                                    ? "col-lg-12 col-sm-12 col-xs-12 "
                                    : "col-lg-3 col-sm-3 col-xs-4"
                                }
                              >
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    {eachAttribute.AN}
                                    {attributeValidation[
                                      eachAttribute.AC
                                    ]?.ISM.toLowerCase() === "true" && (
                                      <sup>*</sup>
                                    )}
                                  </label>

                                  <DateForm
                                    // isDisabled={
                                    //   attributeValidation[eachAttribute.AC]
                                    //     ?.isDisable
                                    // }
                                    isDisabled={
                                      isDisableApplication ||
                                      isIJP ||
                                      attributeValidation[eachAttribute.AC]
                                        ?.isDisable ||
                                      activetab === "AgencyJobDescription"
                                        ? true
                                        : false
                                      //   ||
                                      // (activetab =
                                      //   hasValue?.length == 0 ? false : true)
                                    }
                                    value={
                                      attributesData[eachAttribute.AC]
                                        ? attributesData[eachAttribute.AC]
                                        : null
                                    }
                                    onChange={(date) => {
                                      setAttributesData((prev) => ({
                                        ...prev,
                                        [eachAttribute.AC]:
                                          date != null
                                            ? moment(date).format("DD-MMM-YYYY")
                                            : null,
                                      }));

                                      dependentValidations(
                                        moment(date).format("DD-MMM-YYYY"),
                                        eachAttribute.AC,
                                        "Attribute"
                                      );
                                    }}
                                  />
                                  {formErrors[eachAttribute.AC] && (
                                    <p style={{ color: "red" }}>
                                      {formErrors[eachAttribute.AC]}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : eachAttribute.DT === "Checkbox" ? (
                            <>
                              <div
                                className={
                                  JSON.parse(
                                    eachAttribute.AD
                                  ).IFR.toLowerCase() === "true"
                                    ? "col-lg-12 col-sm-12 col-xs-12 "
                                    : "col-lg-3 col-sm-3 col-xs-4"
                                }
                              >
                                <div className="mb-1">
                                  <label
                                    htmlFor={eachAttribute.AC}
                                    className="col-form-label"
                                  >
                                    {eachAttribute.AN}
                                    {attributeValidation[
                                      eachAttribute.AC
                                    ]?.ISM.toLowerCase() === "true" && (
                                      <sup>*</sup>
                                    )}
                                  </label>

                                  <div>
                                    <input
                                      disabled={
                                        isDisableApplication ||
                                        isIJP ||
                                        attributeValidation[eachAttribute.AC]
                                          ?.isDisable ||
                                        activetab === "AgencyJobDescription"
                                          ? true
                                          : false
                                      }
                                      type="checkbox"
                                      onChange={(e) => {
                                        setAttributesData((prev) => ({
                                          ...prev,
                                          [eachAttribute.AC]: e.target.checked,
                                        }));

                                        dependentValidations(
                                          e.target.checked,
                                          eachAttribute.AC,
                                          "Attribute"
                                        );
                                      }}
                                      id={eachAttribute.AC}
                                      checked={
                                        attributesData[eachAttribute.AC]
                                          ? attributesData[eachAttribute.AC]
                                          : false
                                      }
                                    />
                                  </div>
                                  {formErrors[eachAttribute.AC] && (
                                    <p style={{ color: "red" }}>
                                      {formErrors[eachAttribute.AC]}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : eachAttribute.DT === "Textarea" ? (
                            <>
                              <div
                                className={
                                  JSON.parse(
                                    eachAttribute.AD
                                  ).IFR.toLowerCase() === "true"
                                    ? "col-lg-12 col-sm-12 col-xs-12 "
                                    : "col-lg-3 col-sm-3 col-xs-4"
                                }
                              >
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    {eachAttribute.AN}
                                    {attributeValidation[
                                      eachAttribute.AC
                                    ]?.ISM.toLowerCase() === "true" && (
                                      <sup>*</sup>
                                    )}
                                  </label>

                                  <InputForm
                                    className="form-control"
                                    placeholder={eachAttribute.AN}
                                    isDisabled={
                                      isDisableApplication ||
                                      isIJP ||
                                      attributeValidation[eachAttribute.AC]
                                        ?.isDisable ||
                                      activetab === "AgencyJobDescription"
                                        ? true
                                        : false
                                    }
                                    textArea={true}
                                    value={
                                      attributesData[eachAttribute.AC]
                                        ? attributesData[eachAttribute.AC]
                                        : ""
                                    }
                                    onChange={(e) => {
                                      setAttributesData((prev) => ({
                                        ...prev,
                                        [eachAttribute.AC]: e.target?.value,
                                      }));

                                      dependentValidations(
                                        e.target?.value,
                                        eachAttribute.AC,
                                        "Attribute"
                                      );
                                    }}
                                    maxLength="255"
                                  />
                                  {formErrors[eachAttribute.AC] && (
                                    <p style={{ color: "red" }}>
                                      {formErrors[eachAttribute.AC]}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : eachAttribute.DT === "Document" ? (
                            <>
                              <div
                                className={
                                  JSON.parse(
                                    eachAttribute.AD
                                  ).IFR.toLowerCase() === "true"
                                    ? "col-lg-12 col-sm-12 col-xs-12 "
                                    : "col-lg-3 col-sm-3 col-xs-4"
                                }
                              >
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    {eachAttribute.AN}
                                    {attributeValidation[
                                      eachAttribute.AC
                                    ]?.ISM.toLowerCase() === "true" && (
                                      <sup>*</sup>
                                    )}
                                  </label>

                                  <div className="box position-relative">
                                    <input
                                      id={`files-${sectionIndex}`}
                                      className="form-control inputfile inputfile-6 multiple-inputfile"
                                      data-multiple-caption="{count} files selected"
                                      multiple={
                                        JSON.parse(eachAttribute.V).FC > 1
                                          ? true
                                          : false
                                      }
                                      ref={FlatDocRef}
                                      accept={JSON.parse(eachAttribute.V).FX}
                                      type="file"
                                      onChange={(e) => {
                                        handleFileChangeForFlat(
                                          e,
                                          eachAttribute
                                        );
                                      }}
                                      value={""}
                                    />
                                    <label
                                      htmlFor={`files-${sectionIndex}`}
                                      className="form-control"
                                      style={{ width: 0, border: "none" }}
                                    >
                                      <strong
                                        style={{
                                          padding: "6px 16px",
                                          backgroundColor: "#3c5464",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <i
                                          className="fa fa-upload rotate90"
                                          aria-hidden="true"
                                        ></i>
                                        {"  "}
                                        Upload
                                      </strong>{" "}
                                    </label>
                                  </div>
                                  {formErrors[eachAttribute.AC] && (
                                    <p style={{ color: "red" }}>
                                      {formErrors[eachAttribute.AC]}
                                    </p>
                                  )}
                                  <div className="file-added-list">
                                    <ul className="list-unstyle">
                                      {selectedFilesForFlat &&
                                        selectedFilesForFlat.length > 0 &&
                                        selectedFilesForFlat.map(
                                          (fileAttribute, attributeIndex) => (
                                            <React.Fragment key={uuid()}>
                                              {fileAttribute.AC ===
                                                eachAttribute.AC && (
                                                <li
                                                  className="list mt-1"
                                                  key={attributeIndex}
                                                >
                                                  <div className="media">
                                                    <div className="media-body text-truncate">
                                                      <span className="view-more">
                                                        {fileAttribute.Name}
                                                      </span>
                                                    </div>
                                                    {/*Here code for view same div of trash should be used. ISDOCVIEW/ISDOCDOWN*/}
                                                    {/* {
                                                    // section?.ISDOCVIEW &&
                                                    fileAttribute.DocId !==
                                                      0 && (
                                                      <div className="media-right ml-2">
                                                        <i
                                                          className="fa fa-eye"
                                                          aria-hidden="true"
                                                          onClick={() =>
                                                            handleViewForFlat(
                                                              fileAttribute,
                                                              attributeIndex
                                                            )
                                                          }></i>
                                                      </div>
                                                    )
                                                  } */}
                                                    {
                                                      //  section?.ISDOCDOWN &&
                                                      <>
                                                        {fileAttribute?.Name.split(
                                                          "."
                                                        )
                                                          .pop()
                                                          .toLowerCase() ==
                                                          "pdf" && (
                                                          <div className="media-right ml-2">
                                                            <i
                                                              className="fa fa-eye"
                                                              aria-hidden="true"
                                                              onClick={() =>
                                                                handleFileView({
                                                                  header:
                                                                    eachAttribute.AN,
                                                                  fileAttribute:
                                                                    fileAttribute,
                                                                })
                                                              }
                                                            ></i>
                                                          </div>
                                                        )}
                                                        <div className="media-right ml-2">
                                                          <i
                                                            className="fa-solid fa-download"
                                                            aria-hidden="true"
                                                            onClick={() =>
                                                              handleDownloadFileFlat(
                                                                fileAttribute,
                                                                attributeIndex
                                                              )
                                                            }
                                                          ></i>
                                                        </div>
                                                      </>
                                                    }
                                                    {(isDisableApplication ||
                                                      isIJP) === false &&
                                                      section?.SN !=
                                                        "AgencyJobDescription" &&
                                                      section?.ISEDIT && (
                                                        <div className="media-right ml-2">
                                                          <i
                                                            className="fa fa-trash"
                                                            aria-hidden="true"
                                                            onClick={() =>
                                                              handleDeleteFileForFlat(
                                                                fileAttribute,
                                                                attributeIndex
                                                              )
                                                            }
                                                          ></i>
                                                        </div>
                                                      )}
                                                  </div>
                                                </li>
                                              )}
                                            </React.Fragment>
                                          )
                                        )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : eachAttribute.DT === "RichText" ? (
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }
                            >
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: attributesData[eachAttribute.AC],
                                  }}
                                />
                              </div>
                            </div>
                          ) : eachAttribute.DT === "DropdownMultiple" ? (
                            <>
                              <div
                                className={
                                  JSON.parse(
                                    eachAttribute.AD
                                  ).IFR.toLowerCase() === "true"
                                    ? "col-lg-12 col-sm-12 col-xs-12 "
                                    : "col-lg-3 col-sm-3 col-xs-4"
                                }
                              >
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    {eachAttribute.AN}
                                    {JSON.parse(
                                      eachAttribute.V
                                    ).ISM.toLowerCase() === "true" && (
                                      <sup>*</sup>
                                    )}
                                  </label>

                                  <SelectForm
                                    isClearable
                                    isSearchable
                                    options={
                                      optionsObj[eachAttribute.AC]
                                        ? optionsObj[eachAttribute.AC]
                                        : []
                                    }
                                    isMulti={multiDropdown}
                                    placeholder={eachAttribute.AN}
                                    isDisabled={
                                      isDisableApplication ||
                                      isIJP ||
                                      attributeValidation[eachAttribute.AC]
                                        ?.isDisable ||
                                      activetab === "AgencyJobDescription"
                                        ? true
                                        : false
                                    }
                                    value={
                                      attributesData[eachAttribute.AC]
                                        ? attributesData[eachAttribute.AC]
                                        : null
                                    }
                                    onChange={(event) => {
                                      let attributeValObj = {
                                        ...attributeValidation,
                                      };
                                      setAttributesData((prev) => ({
                                        ...prev,
                                        [eachAttribute.AC]: event,
                                      }));
                                      if (eachAttribute.AC === "MODOFCOMM") {
                                        if (
                                          event !== null &&
                                          event.find(
                                            (item) => item.code === "Phone"
                                          ) !== undefined &&
                                          event.find(
                                            (item) => item.code === "Email"
                                          ) !== undefined
                                        ) {
                                          attributeValObj.COMMPHN.ISM = "true";
                                          attributeValObj.COMMPHN.isDisable =
                                            false;
                                          attributeValObj.COMMEMAIL.isDisable =
                                            false;
                                          attributeValObj.COMMEMAIL.ISM =
                                            "true";
                                        } else if (
                                          event !== null &&
                                          event.find(
                                            (item) => item.code === "Phone"
                                          ) !== undefined
                                        ) {
                                          attributeValObj.COMMPHN.ISM = "true";
                                          attributeValObj.COMMPHN.isDisable =
                                            false;
                                          attributeValObj.COMMEMAIL.isDisable =
                                            true;
                                          attributeValObj.COMMEMAIL.ISM =
                                            "false";

                                          setAttributesData((prev) => ({
                                            ...prev,
                                            ["COMMEMAIL"]: null,
                                          }));
                                        } else if (
                                          event !== null &&
                                          event.find(
                                            (item) => item.code === "Email"
                                          ) !== undefined
                                        ) {
                                          attributeValObj.COMMPHN.ISM = "false";
                                          attributeValObj.COMMPHN.isDisable =
                                            true;
                                          attributeValObj.COMMEMAIL.isDisable =
                                            false;
                                          attributeValObj.COMMEMAIL.ISM =
                                            "true";
                                          setAttributesData((prev) => ({
                                            ...prev,
                                            ["COMMPHN"]: null,
                                          }));
                                        } else {
                                          attributeValObj.COMMPHN.ISM = "true";
                                          attributeValObj.COMMPHN.isDisable =
                                            false;
                                          attributeValObj.COMMEMAIL.isDisable =
                                            false;
                                          attributeValObj.COMMEMAIL.ISM =
                                            "true";
                                          setAttributesData((prev) => ({
                                            ...prev,
                                            ["COMMPHN"]: null,
                                            ["COMMEMAIL"]: null,
                                          }));
                                        }
                                        setAttributeValidation((prev) => ({
                                          ...prev,
                                          ...attributeValObj,
                                        }));
                                      }
                                      if (
                                        event !== null &&
                                        eachAttribute.CC !== null &&
                                        eachAttribute.CC !== ""
                                      ) {
                                        const CC = eachAttribute.CC.split(",");

                                        CC.forEach(async (childDropdown) => {
                                          setAttributesData((prev) => ({
                                            ...prev,
                                            [childDropdown]: null,
                                          }));

                                          const options =
                                            await getDropdownsData(
                                              childDropdown,
                                              event.value
                                            );

                                          setOptionsObj((prev) => ({
                                            ...prev,
                                            [childDropdown]: options,
                                          }));
                                        });
                                      }
                                    }}
                                    noIndicator={false}
                                    noSeparator={false}
                                  />
                                  {formErrors[eachAttribute.AC] && (
                                    <p style={{ color: "red" }}>
                                      {formErrors[eachAttribute.AC]}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : eachAttribute.AT === "AttributeSet" &&
                        eachAttribute.DT === "Custom" ? (
                        <div className="col-lg-12">
                          <div className="row">
                            {eachAttribute.Attribute.length > 0 &&
                              eachAttribute.Attribute.map(
                                (subAttribute, index) => (
                                  <React.Fragment
                                    key={`${index}-${subAttribute.AC}`}
                                  >
                                    {subAttribute.DT === "DropdownSingle" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {attributeSetValidation[
                                                subAttribute?.AC
                                              ]?.ISM?.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <SelectForm
                                              isClearable
                                              isSearchable
                                              options={
                                                optionsObj[subAttribute.AC]
                                                  ? optionsObj[subAttribute.AC]
                                                  : []
                                              }
                                              placeholder={subAttribute.AN}
                                              isDisabled={
                                                attributeSetValidation[
                                                  subAttribute.AC
                                                ]?.isDisable ||
                                                isDisableApplication ||
                                                isIJP ||
                                                activetab ===
                                                  "AgencyJobDescription"
                                                  ? true
                                                  : false
                                              }
                                              value={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : null
                                              }
                                              onChange={(event) => {
                                                setAttributeSetData((prev) => ({
                                                  ...prev,
                                                  [subAttribute.AC]: event,
                                                }));

                                                if (
                                                  event !== null &&
                                                  subAttribute.CC !== null &&
                                                  subAttribute.CC !== ""
                                                ) {
                                                  const CC =
                                                    subAttribute.CC.split(",");

                                                  CC.forEach(
                                                    async (childDropdown) => {
                                                      setAttributeSetData(
                                                        (prev) => ({
                                                          ...prev,
                                                          [childDropdown]: null,
                                                        })
                                                      );

                                                      const options =
                                                        await getDropdownsData(
                                                          childDropdown,
                                                          event?.value
                                                        );

                                                      setOptionsObj((prev) => ({
                                                        ...prev,
                                                        [childDropdown]:
                                                          options,
                                                      }));
                                                    }
                                                  );
                                                }
                                                //hard code for address information
                                                if (
                                                  event &&
                                                  (subAttribute.AC ===
                                                    "CAFISPERADD" ||
                                                    subAttribute.AC ===
                                                      "CATYPE")
                                                ) {
                                                  if (
                                                    subAttribute.AC ===
                                                    "CAFISPERADD"
                                                  ) {
                                                    setAddressType((prev) => ({
                                                      ...prev,
                                                      address:
                                                        event.label.toLowerCase(),
                                                    }));
                                                  } else {
                                                    setAddressType((prev) => ({
                                                      ...prev,
                                                      type: event.label.toLowerCase(),
                                                    }));
                                                  }
                                                }

                                                if (
                                                  subAttribute.AC ===
                                                  "CAFISPERADD"
                                                ) {
                                                  if (
                                                    event != null &&
                                                    event != undefined
                                                  ) {
                                                    if (
                                                      event.label.toLowerCase() ===
                                                        "emergency" ||
                                                      event.label.toLowerCase() ===
                                                        "permanent"
                                                    ) {
                                                      setOptionsObj((prev) => ({
                                                        ...prev,
                                                        ["CATYPE"]: [],
                                                      }));

                                                      let attributeSetValObj = {
                                                        ...attributeSetValidation,
                                                      };
                                                      attributeSetValObj.CATYPE.ISM =
                                                        "false";
                                                      setAttributeSetValidation(
                                                        (prev) => ({
                                                          ...prev,
                                                          ...attributeSetValObj,
                                                        })
                                                      );
                                                      setAttributeSetData(
                                                        (prev) => ({
                                                          ...prev,
                                                          ["CATYPE"]: null,
                                                        })
                                                      );
                                                    } else {
                                                      const adrTOPT =
                                                        eachAttribute.Attribute.find(
                                                          (item) =>
                                                            item.AC === "CATYPE"
                                                        );
                                                      if (adrTOPT) {
                                                        setOptionsObj(
                                                          (prev) => ({
                                                            ...prev,
                                                            ["CATYPE"]:
                                                              adrTOPT.OPT,
                                                          })
                                                        );
                                                      }
                                                      let attributeSetValObj = {
                                                        ...attributeSetValidation,
                                                      };
                                                      attributeSetValObj.CATYPE.ISM =
                                                        "true";
                                                      setAttributeSetValidation(
                                                        (prev) => ({
                                                          ...prev,
                                                          ...attributeSetValObj,
                                                        })
                                                      );
                                                    }
                                                  } else {
                                                    const adrTOPT =
                                                      eachAttribute.Attribute.find(
                                                        (item) =>
                                                          item.AC === "CATYPE"
                                                      );
                                                    if (adrTOPT) {
                                                      setOptionsObj((prev) => ({
                                                        ...prev,
                                                        ["CATYPE"]: adrTOPT.OPT,
                                                      }));
                                                    }
                                                    setAttributeSetData(
                                                      (prev) => ({
                                                        ...prev,
                                                        ["CATYPE"]: null,
                                                      })
                                                    );
                                                  }
                                                }
                                                dependentValidations(
                                                  event,
                                                  subAttribute.AC,
                                                  "AttributeSet"
                                                );
                                              }}
                                              isMulti={false}
                                              noIndicator={false}
                                              noSeparator={false}
                                            />
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT === "Text" ||
                                      subAttribute.DT === "Number" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {attributeSetValidation[
                                                subAttribute.AC
                                              ]?.ISM.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <InputForm
                                              className="form-control"
                                              placeholder={subAttribute.AN}
                                              isDisabled={
                                                attributeSetValidation[
                                                  subAttribute.AC
                                                ]?.isDisable ||
                                                isDisableApplication ||
                                                isIJP ||
                                                subAttribute.AC ===
                                                  "CAFDURATN" ||
                                                activetab ===
                                                  "AgencyJobDescription"
                                                  ? true
                                                  : false
                                              }
                                              textArea={false}
                                              value={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : ""
                                              }
                                              onChange={(e) => {
                                                setAttributeSetData((prev) => ({
                                                  ...prev,
                                                  [subAttribute.AC]:
                                                    e.target?.value,
                                                }));
                                              }}
                                              maxLength="255"
                                            />
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT === "MonthYear" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {attributeSetValidation[
                                                subAttribute.AC
                                              ]?.ISM.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <DateForm
                                              isDisabled={
                                                isDisableApplication ||
                                                isIJP ||
                                                attributeSetValidation[
                                                  subAttribute.AC
                                                ]?.isDisable ||
                                                activetab ===
                                                  "AgencyJobDescription"
                                                  ? true
                                                  : false
                                              }
                                              views={["month", "year"]}
                                              format="MMM/yyyy"
                                              value={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : null
                                              }
                                              onChange={(date) => {
                                                setAttributeSetData((prev) => ({
                                                  ...prev,
                                                  [subAttribute.AC]:
                                                    date != null
                                                      ? moment(date).format(
                                                          "DD-MMM-YYYY"
                                                        )
                                                      : null,
                                                }));

                                                //hard code to calculate duration in formal education
                                                if (
                                                  date &&
                                                  (subAttribute.AC ===
                                                    "CAFFRMDTFE" ||
                                                    subAttribute.AC ===
                                                      "CAFENDDTED")
                                                ) {
                                                  const endDate = moment(
                                                    subAttribute.AC ===
                                                      "CAFENDDTED"
                                                      ? date
                                                      : attributeSetData[
                                                          "CAFENDDTED"
                                                        ] !== null
                                                      ? attributeSetData[
                                                          "CAFENDDTED"
                                                        ]
                                                      : date
                                                  );

                                                  const fromDate = moment(
                                                    subAttribute.AC ===
                                                      "CAFFRMDTFE"
                                                      ? date
                                                      : attributeSetData[
                                                          "CAFFRMDTFE"
                                                        ] !== null
                                                      ? attributeSetData[
                                                          "CAFFRMDTFE"
                                                        ]
                                                      : date
                                                  );

                                                  const duration =
                                                    moment.duration(
                                                      endDate.diff(fromDate)
                                                    );

                                                  const durationInYears =
                                                    duration.years();

                                                  const durationInMonths =
                                                    duration.months();

                                                  const durationInDays =
                                                    duration.days();

                                                  if (
                                                    isNaN(durationInYears) ||
                                                    durationInYears < 0 ||
                                                    isNaN(durationInMonths) ||
                                                    durationInMonths < 0 ||
                                                    isNaN(durationInDays) ||
                                                    durationInDays < 0
                                                  ) {
                                                    setAttributeSetData(
                                                      (prev) => ({
                                                        ...prev,
                                                        ["CAFDURATN"]: "",
                                                      })
                                                    );
                                                  } else {
                                                    setAttributeSetData(
                                                      (prev) => ({
                                                        ...prev,
                                                        ["CAFDURATN"]: `${durationInYears} years ${durationInMonths} months ${durationInDays} days`,
                                                      })
                                                    );
                                                  }
                                                }
                                              }}
                                            />
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT === "Date" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {attributeSetValidation[
                                                subAttribute.AC
                                              ]?.ISM.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <DateForm
                                              isDisabled={
                                                isDisableApplication ||
                                                isIJP ||
                                                activetab ===
                                                  "AgencyJobDescription"
                                                  ? true
                                                  : attributeSetValidation[
                                                      subAttribute.AC
                                                    ]?.isDisable
                                                  ? true
                                                  : subAttribute.AC ===
                                                      "CALSD" ||
                                                    subAttribute.AC === "CALED"
                                                  ? attributeSetData?.CATYPE !=
                                                      null &&
                                                    attributeSetData?.CATYPE?.label.toLowerCase() ===
                                                      "lease"
                                                    ? false
                                                    : true
                                                  : false
                                              }
                                              value={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : null
                                              }
                                              onChange={(date) => {
                                                setAttributeSetData((prev) => ({
                                                  ...prev,
                                                  [subAttribute.AC]:
                                                    date != null
                                                      ? moment(date).format(
                                                          "DD-MMM-YYYY"
                                                        )
                                                      : null,
                                                }));
                                              }}
                                            />
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT === "Checkbox" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label
                                              htmlFor={subAttribute.AC}
                                              className="col-form-label"
                                            >
                                              {subAttribute.AN}
                                              {attributeSetValidation[
                                                subAttribute.AC
                                              ]?.ISM.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <div>
                                              <input
                                                disabled={
                                                  isDisableApplication ||
                                                  isIJP ||
                                                  activetab ===
                                                    "AgencyJobDescription"
                                                    ? true
                                                    : false
                                                }
                                                type="checkbox"
                                                onChange={(e) => {
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      [subAttribute.AC]:
                                                        e.target.checked,
                                                    })
                                                  );
                                                }}
                                                id={subAttribute.AC}
                                                checked={
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ]
                                                    ? attributeSetData[
                                                        subAttribute.AC
                                                      ]
                                                    : false
                                                }
                                              />
                                            </div>
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT === "Document" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {attributeSetValidation[
                                                subAttribute.AC
                                              ]?.ISM.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>
                                            <div className="box position-relative">
                                              <input
                                                id={`files-${sectionIndex}-${subAttribute.AC}`}
                                                disabled={
                                                  attributeSetValidation[
                                                    subAttribute.AC
                                                  ]?.isDisable ||
                                                  isDisableApplication ||
                                                  isIJP ||
                                                  activetab ===
                                                    "AgencyJobDescription"
                                                    ? true
                                                    : false
                                                }
                                                ref={SetDocRef}
                                                className="form-control inputfile inputfile-6 multiple-inputfile"
                                                data-multiple-caption="{count} files selected"
                                                multiple={
                                                  JSON.parse(subAttribute.V)
                                                    .FC > 1
                                                    ? true
                                                    : false
                                                }
                                                accept={
                                                  JSON.parse(subAttribute.V).FX
                                                }
                                                type="file"
                                                onChange={(e) => {
                                                  handleFileChangeForSet(
                                                    e,
                                                    subAttribute
                                                  );
                                                }}
                                                value={""}
                                              />
                                              <label
                                                htmlFor={`files-${sectionIndex}-${subAttribute.AC}`}
                                                className="form-control"
                                                style={{
                                                  width: 0,
                                                  border: "none",
                                                }}
                                              >
                                                <strong
                                                  style={{
                                                    padding: "6px 16px",
                                                    backgroundColor: "#3c5464",
                                                    borderRadius: "5px",
                                                  }}
                                                >
                                                  <i
                                                    className="fa fa-upload rotate90"
                                                    aria-hidden="true"
                                                  ></i>
                                                  {"  "}
                                                  Upload
                                                </strong>{" "}
                                              </label>
                                            </div>
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                            <div className="file-added-list">
                                              <ul className="list-unstyle">
                                                {selectedFilesForSet &&
                                                  selectedFilesForSet.length >
                                                    0 &&
                                                  selectedFilesForSet.map(
                                                    (
                                                      fileAttribute,
                                                      attributeIndex
                                                    ) => (
                                                      <React.Fragment
                                                        key={uuid()}
                                                      >
                                                        {fileAttribute.AC ===
                                                          subAttribute.AC &&
                                                          (fileAttribute.Id ===
                                                            0 ||
                                                            fileAttribute.Id ==
                                                              currentGridId) && (
                                                            <li
                                                              className="list mt-1"
                                                              key={
                                                                attributeIndex
                                                              }
                                                            >
                                                              <div className="media">
                                                                <div className="media-body text-truncate">
                                                                  <span className="view-more">
                                                                    {
                                                                      fileAttribute.Name
                                                                    }
                                                                  </span>
                                                                </div>

                                                                {
                                                                  <>
                                                                    {fileAttribute?.Name.split(
                                                                      "."
                                                                    )
                                                                      .pop()
                                                                      .toLowerCase() ==
                                                                      "pdf" && (
                                                                      <div className="media-right ml-2">
                                                                        <i
                                                                          className="fa fa-eye"
                                                                          aria-hidden="true"
                                                                          onClick={() =>
                                                                            handleFileView(
                                                                              {
                                                                                header:
                                                                                  eachAttribute.AN,
                                                                                fileAttribute:
                                                                                  fileAttribute,
                                                                              }
                                                                            )
                                                                          }
                                                                        ></i>
                                                                      </div>
                                                                    )}
                                                                    <div className="media-right ml-2">
                                                                      <i
                                                                        className="fa-solid fa-download"
                                                                        aria-hidden="true"
                                                                        onClick={() =>
                                                                          handleDownloadFileFlat(
                                                                            fileAttribute,
                                                                            attributeIndex
                                                                          )
                                                                        }
                                                                      ></i>
                                                                    </div>
                                                                  </>
                                                                }
                                                                {(isDisableApplication ===
                                                                  false ||
                                                                  isIJP ==
                                                                    false) &&
                                                                  section?.ISEDIT && (
                                                                    <div className="media-right ml-2">
                                                                      <i
                                                                        className="fa fa-trash"
                                                                        aria-hidden="true"
                                                                        onClick={() =>
                                                                          handleDeleteFileForSet(
                                                                            fileAttribute,
                                                                            attributeIndex
                                                                          )
                                                                        }
                                                                      ></i>
                                                                    </div>
                                                                  )}
                                                              </div>
                                                            </li>
                                                          )}
                                                      </React.Fragment>
                                                    )
                                                  )}
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT ===
                                      "DropdownMultiple" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {attributeSetValidation[
                                                subAttribute.AC
                                              ]?.ISM.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <SelectForm
                                              isClearable
                                              isSearchable
                                              options={
                                                optionsObj[subAttribute.AC]
                                                  ? optionsObj[subAttribute.AC]
                                                  : []
                                              }
                                              isMulti={multiDropdown}
                                              placeholder={subAttribute.AN}
                                              isDisabled={!section.ISEDIT}
                                              value={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : null
                                              }
                                              onChange={(event) => {
                                                setAttributeSetData((prev) => ({
                                                  ...prev,
                                                  [subAttribute.AC]: event,
                                                }));

                                                if (
                                                  event !== null &&
                                                  subAttribute.CC !== null &&
                                                  subAttribute.CC !== ""
                                                ) {
                                                  const CC =
                                                    subAttribute.CC.split(",");

                                                  CC.forEach(
                                                    async (childDropdown) => {
                                                      setAttributeSetData(
                                                        (prev) => ({
                                                          ...prev,
                                                          [childDropdown]: null,
                                                        })
                                                      );

                                                      const options =
                                                        await getDropdownsData(
                                                          childDropdown,
                                                          event.value
                                                        );

                                                      setOptionsObj((prev) => ({
                                                        ...prev,
                                                        [childDropdown]:
                                                          options,
                                                      }));
                                                    }
                                                  );
                                                }
                                              }}
                                              noIndicator={false}
                                              noSeparator={false}
                                            />
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </React.Fragment>
                                )
                              )}
                          </div>

                          <div className="row">
                            <div className="col-lg-3 mt-2 mb-3">
                              {section?.ISEDIT && (
                                <button
                                  disabled={
                                    isDisableApplication ||
                                    isIJP ||
                                    activetab === "AgencyJobDescription"
                                      ? true
                                      : false
                                  }
                                  id={currentGridId === null ? "0" : "1"}
                                  className="btn btn-primary"
                                  onClick={(e) => {
                                    const targetId = e.currentTarget.id;

                                    //Education Validation
                                    if (
                                      String(section?.SN).toLowerCase() ==
                                        "education" &&
                                      gridData?.length > 0
                                    ) {
                                      const fromDate =
                                        attributeSetData["CAFFRMDTFE"];
                                      const endDate =
                                        attributeSetData["CAFENDDTED"];

                                      let datesArr = gridData
                                        ?.filter(
                                          (i) =>
                                            i?.Id != currentGridId &&
                                            (i?.IsActive != "false" ||
                                              i?.IsActive != false)
                                        )
                                        ?.map((i) => {
                                          return {
                                            fromDate: i?.CAFFRMDTFE,
                                            toDate: i?.CAFENDDTED,
                                          };
                                        });

                                      const isOverlapped = doDatesOverlap(
                                        fromDate,
                                        endDate,
                                        datesArr
                                      );

                                      if (isOverlapped) {
                                        notify(
                                          1,
                                          "From date and End date is overlapping between other dates."
                                        );
                                        return;
                                      }
                                    }

                                    /* Relationship validation */
                                    if (section?.SN === "Relationship") {
                                      let arr = gridData?.filter(
                                        (i) =>
                                          i?.IsActive != "false" ||
                                          i?.IsActive != false
                                      );
                                      if (
                                        arr?.length > 0 &&
                                        arr?.some(
                                          (i) =>
                                            String(i?.CAFARWG)
                                              .toLowerCase()
                                              .trim() === "no" &&
                                            i.Id != currentGridId
                                        )
                                      ) {
                                        notify(
                                          1,
                                          "You have already provided with answer that you don't have any relatives in this company, you cannot add more."
                                        );
                                        return;
                                      } else if (
                                        arr?.length > 0 &&
                                        String(
                                          attributeSetData["CAFARWG"]?.code
                                        ) == "no" &&
                                        arr?.some(
                                          (i) =>
                                            String(i?.CAFARWG)
                                              .toLowerCase()
                                              .trim() === "yes" &&
                                            i.Id != currentGridId
                                        )
                                      ) {
                                        notify(
                                          1,
                                          "You have already provided with answer that you have relatives in this company, so cannot add no relatives."
                                        );
                                        return;
                                      }
                                    }
                                    /**/

                                    if (targetId === "0") {
                                      //insert
                                      if (
                                        typeof attributeSetData[
                                          "CAFISPERADD"
                                        ] === "object" &&
                                        Array.isArray(
                                          attributeSetData["CAFISPERADD"]
                                        ) === true
                                      ) {
                                        if (
                                          section.SN === "CorrespondenceAddress"
                                        ) {
                                          const uniqueIds = [];

                                          let key = "CAFISPERADD";
                                          const isError = validateData(
                                            eachAttribute.Attribute
                                          );
                                          if (!isError) {
                                            if (key !== "") {
                                              for (
                                                let i = 0;
                                                i <
                                                attributeSetData["CAFISPERADD"]
                                                  .length;
                                                i++
                                              ) {
                                                //
                                                let attributed =
                                                  attributeSetData[
                                                    "CAFISPERADD"
                                                  ][i];
                                                let filteredEmail =
                                                  ogFilledAttributeSet.filter(
                                                    (og) => {
                                                      return (
                                                        og.PA?.label ===
                                                          attributed.label &&
                                                        og.IsActive === true
                                                      );
                                                    }
                                                  );
                                                if (filteredEmail.length > 0) {
                                                  notify(
                                                    1,
                                                    attributed.label +
                                                      " Address Type Already Exist, Can't Insert Another."
                                                  );
                                                  return;
                                                }
                                              }

                                              attributeSetData[`${key}`].map(
                                                (value, indx) => {
                                                  const uniqueId = uuid();
                                                  uniqueIds.push(uniqueId);
                                                  let isError = false;
                                                  if (!isError) {
                                                    let gridData = {};
                                                    let ogf = {};
                                                    eachAttribute.Attribute.forEach(
                                                      async (subAttribute) => {
                                                        if (
                                                          typeof attributeSetData[
                                                            subAttribute.AC
                                                          ] === "object" &&
                                                          Array.isArray(
                                                            attributeSetData[
                                                              subAttribute.AC
                                                            ]
                                                          ) === true
                                                        ) {
                                                          gridData = {
                                                            ...gridData,
                                                            [subAttribute.AC]:
                                                              value.label
                                                                ? value.label
                                                                : null, //for MultiSelect Dropdownn
                                                          };
                                                          ogf = {
                                                            ...ogf,
                                                            [subAttribute.AC]:
                                                              value,
                                                          };
                                                          if (
                                                            value.label ===
                                                            "Permanent"
                                                          ) {
                                                            gridData = {
                                                              ...gridData,
                                                              ["CATYPE"]: null, //for date and dropdown
                                                            };
                                                            ogf = {
                                                              ...ogf,
                                                              ["CATYPE"]: null,
                                                              ["CALSD"]: null,
                                                              ["CALED"]: null,
                                                            };
                                                          }
                                                        } else if (
                                                          typeof attributeSetData[
                                                            subAttribute.AC
                                                          ] === "object"
                                                        ) {
                                                          gridData = {
                                                            ...gridData,
                                                            [subAttribute.AC]:
                                                              attributeSetData[
                                                                subAttribute.AC
                                                              ]?.label || null, //for date and dropdown
                                                          };
                                                          ogf = {
                                                            ...ogf,
                                                            [subAttribute.AC]:
                                                              attributeSetData[
                                                                subAttribute.AC
                                                              ],
                                                          };
                                                          if (
                                                            value.label ===
                                                            "Permanent"
                                                          ) {
                                                            gridData = {
                                                              ...gridData,
                                                              ["CATYPE"]: null, //for date and dropdown
                                                            };
                                                            ogf = {
                                                              ...ogf,
                                                              ["CATYPE"]: null,
                                                              ["CALSD"]: null,
                                                              ["CALED"]: null,
                                                            };
                                                          }
                                                        } else if (
                                                          typeof attributeSetData[
                                                            subAttribute.AC
                                                          ] === "boolean"
                                                        ) {
                                                          gridData = {
                                                            ...gridData,
                                                            [subAttribute.AC]:
                                                              attributeSetData[
                                                                subAttribute.AC
                                                              ] === true
                                                                ? "true"
                                                                : "false",
                                                          };
                                                          ogf = {
                                                            ...ogf,
                                                            ["CATYPE"]: null,
                                                            ["CALSD"]: null,
                                                            ["CALED"]: null,
                                                          };
                                                        } else {
                                                          gridData = {
                                                            ...gridData,
                                                            [subAttribute.AC]:
                                                              attributeSetData[
                                                                subAttribute.AC
                                                              ],
                                                          };
                                                          ogf = {
                                                            ...ogf,
                                                            [subAttribute.AC]:
                                                              attributeSetData[
                                                                subAttribute.AC
                                                              ],
                                                          };
                                                          if (
                                                            value.label ===
                                                            "Permanent"
                                                          ) {
                                                            gridData = {
                                                              ...gridData,
                                                              ["CATYPE"]: null, //for date and dropdown
                                                            };
                                                            ogf = {
                                                              ...ogf,
                                                              ["CATYPE"]: null,
                                                              ["CALSD"]: null,
                                                              ["CALED"]: null,
                                                            };
                                                          }
                                                        }
                                                      }
                                                    );
                                                    setOgFilledAttributeSet(
                                                      (prev) => [
                                                        ...prev,
                                                        {
                                                          ...ogf,
                                                          Id: uniqueId,
                                                          IsActive: true,
                                                        },
                                                      ]
                                                    );
                                                    //reset
                                                    setAttributeSetData(
                                                      (prev) => ({
                                                        ...prev,
                                                        ...ogAttributeSet,
                                                      })
                                                    );

                                                    setFormErrors({});

                                                    //setNewGridIdCreated(uniqueId); //send uniqueId for document

                                                    setGridData((prev) => [
                                                      ...prev,
                                                      {
                                                        Id: uniqueId,
                                                        ...gridData,
                                                        IsActive: true,
                                                      },
                                                    ]);

                                                    setDisplayGrid((prev) => [
                                                      ...prev,
                                                      {
                                                        Id: uniqueId,
                                                        ...gridData,
                                                        IsActive: true,
                                                      },
                                                    ]);
                                                  }
                                                }
                                              );

                                              setAddressTypeUniqueIds(
                                                uniqueIds
                                              );
                                            }
                                          }
                                        }
                                      } else {
                                        const uniqueId = uuid();

                                        //validation for empty data before adding in grid
                                        //
                                        const isError = validateData(
                                          eachAttribute.Attribute
                                        );

                                        if (
                                          !isError &&
                                          section.SN != "BankInformation" &&
                                          section.SN != "NationalIDInformation"
                                        ) {
                                          //add record in grid
                                          // Duplicate in Education
                                          if (section.SN === "Education") {
                                            let Duplicate = [];
                                            Duplicate =
                                              ogFilledAttributeSet.filter(
                                                (og) => {
                                                  return (
                                                    og.CAFQUALF?.label ===
                                                      attributeSetData[
                                                        "CAFQUALF"
                                                      ].label &&
                                                    og.IsActive === true &&
                                                    og.Id !== currentGridId
                                                  );
                                                }
                                              );
                                            if (Duplicate.length > 0) {
                                              notify(
                                                1,
                                                `Qualification ${attributeSetData["CAFQUALF"].label} Already Exist, Can't Insert Another.`
                                              );
                                              return;
                                            }
                                          }

                                          if (
                                            attributeSetData["CAFARWG"]
                                              ?.label === "No"
                                          ) {
                                            if (
                                              ogFilledAttributeSet.length > 0
                                            ) {
                                              let filteredCAFARWG =
                                                ogFilledAttributeSet.filter(
                                                  (og) => {
                                                    if (
                                                      og["CAFARWG"]?.label ===
                                                        "No" &&
                                                      og.IsActive === true
                                                    ) {
                                                      return og;
                                                    }
                                                  }
                                                );
                                              if (filteredCAFARWG.length > 0) {
                                                notify(
                                                  1,
                                                  "Same Entries Not Allowed."
                                                );
                                                return;
                                              }
                                            }
                                          }
                                          setOgFilledAttributeSet((prev) => [
                                            ...prev,
                                            {
                                              ...attributeSetData,
                                              Id: uniqueId,
                                              IsActive: true,
                                            },
                                          ]);

                                          let gridData = {};

                                          eachAttribute.Attribute.forEach(
                                            async (subAttribute) => {
                                              if (
                                                typeof attributeSetData[
                                                  subAttribute.AC
                                                ] === "object"
                                              ) {
                                                gridData = {
                                                  ...gridData,
                                                  [subAttribute.AC]:
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ]?.label || null, //for date and dropdown
                                                };
                                              } else if (
                                                typeof attributeSetData[
                                                  subAttribute.AC
                                                ] === "boolean"
                                              ) {
                                                gridData = {
                                                  ...gridData,
                                                  [subAttribute.AC]:
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ] === true
                                                      ? "true"
                                                      : "false",
                                                };
                                              } else {
                                                gridData = {
                                                  ...gridData,
                                                  [subAttribute.AC]:
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ],
                                                };
                                              }
                                            }
                                          );

                                          //reset
                                          setAttributeSetData((prev) => ({
                                            ...prev,
                                            ...ogAttributeSet,
                                          }));

                                          setFormErrors({});

                                          setNewGridIdCreated(uniqueId); //send uniqueId for document

                                          setGridData((prev) => [
                                            ...prev,
                                            {
                                              Id: uniqueId,
                                              ...gridData,
                                              IsActive: true,
                                            },
                                          ]);

                                          setDisplayGrid((prev) => [
                                            ...prev,
                                            {
                                              Id: uniqueId,
                                              ...gridData,
                                              IsActive: true,
                                            },
                                          ]);

                                          setReset((prev) => !prev);
                                        }
                                        //}
                                      }
                                    } else {
                                      //update
                                      //validation for empty data before updating in grid
                                      const isError = validateData(
                                        eachAttribute.Attribute
                                      );

                                      if (!isError) {
                                        //update
                                        //Duplicate entries in Education
                                        if (section.SN === "Education") {
                                          let Duplicate = [];
                                          Duplicate =
                                            ogFilledAttributeSet.filter(
                                              (og) => {
                                                return (
                                                  og.CAFQUALF?.label ===
                                                    attributeSetData["CAFQUALF"]
                                                      .label &&
                                                  og.IsActive === true &&
                                                  og.Id != currentGridId
                                                );
                                              }
                                            );
                                          if (Duplicate.length > 0) {
                                            notify(
                                              1,
                                              `Qualification ${attributeSetData["CAFQUALF"].label} Already Exist, Can't Insert Another.`
                                            );
                                            return;
                                          }
                                        }

                                        if (
                                          attributeSetData["CAFARWG"]?.label ===
                                          "No"
                                        ) {
                                          if (ogFilledAttributeSet.length > 0) {
                                            let filteredCAFARWG =
                                              ogFilledAttributeSet.filter(
                                                (og) => {
                                                  if (
                                                    og["CAFARWG"]?.label ===
                                                      "No" &&
                                                    og.IsActive === true
                                                  ) {
                                                    return og;
                                                  }
                                                }
                                              );
                                            if (filteredCAFARWG.length > 0) {
                                              notify(
                                                1,
                                                "Same Entries Not Allowed."
                                              );
                                              return;
                                            }
                                          }
                                        }
                                        setOgFilledAttributeSet((prev) => {
                                          const newState = prev.map(
                                            (record) => {
                                              if (record.Id === currentGridId) {
                                                return { ...attributeSetData };
                                              }
                                              return record;
                                            }
                                          );
                                          return newState;
                                        });

                                        let gridData = {};

                                        eachAttribute.Attribute.forEach(
                                          async (subAttribute) => {
                                            if (
                                              typeof attributeSetData[
                                                subAttribute.AC
                                              ] === "object"
                                            ) {
                                              gridData = {
                                                ...gridData,
                                                [subAttribute.AC]:
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ]?.label || null, //for date and dropdown
                                              };
                                            } else if (
                                              typeof attributeSetData[
                                                subAttribute.AC
                                              ] === "boolean"
                                            ) {
                                              gridData = {
                                                ...gridData,
                                                [subAttribute.AC]:
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ] === true
                                                    ? "true"
                                                    : "false",
                                              };
                                            } else {
                                              gridData = {
                                                ...gridData,
                                                [subAttribute.AC]:
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ],
                                              };
                                            }
                                          }
                                        );

                                        //reset
                                        setAttributeSetData((prev) => ({
                                          ...prev,
                                          ...ogAttributeSet,
                                        }));

                                        setFormErrors({});

                                        setGridData((prev) => {
                                          const newState = prev.map(
                                            (record) => {
                                              if (record.Id === currentGridId) {
                                                return {
                                                  Id: currentGridId,
                                                  ...gridData,
                                                };
                                              }
                                              return record;
                                            }
                                          );
                                          return newState;
                                        });

                                        setDisplayGrid((prev) => {
                                          const newState = prev.map(
                                            (record) => {
                                              if (record.Id === currentGridId) {
                                                return {
                                                  Id: currentGridId,
                                                  ...gridData,
                                                };
                                              }
                                              return record;
                                            }
                                          );
                                          return newState;
                                        });

                                        setCurrentGridId(null);
                                        setReset((prev) => !prev);
                                      }
                                    }
                                  }}
                                >
                                  <i
                                    className={
                                      currentGridId === null
                                        ? "fa fa-plus"
                                        : "fas fa-edit"
                                    }
                                  ></i>{" "}
                                  {currentGridId === null
                                    ? "Save"
                                    : "Update Record"}
                                </button>
                              )}
                              {/* {section?.ISEDIT && (
                              <button
                                className="btn btn-secondary ml-3"
                                onClick={() => {
                                  setAttributeSetData((prev) => ({
                                    ...prev,
                                    ...ogAttributeSet,
                                  }));
                                  setFormErrors({});
                                  setCurrentGridId(null);
                                }}
                              >
                                Cancel
                              </button>
                            )} */}
                            </div>

                            <div className="col-lg-12 p-0 mb-3">
                              <DynamicGrid
                                options={gridOptions}
                                data={displayGrid}
                                columns={gridColumns}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </React.Fragment>
                  );
                })}
            </div>
          </AccordionDetails>
        </Accordion>
      ) : null}

      <DocumentViewer
        modal={fileViewModalPopup}
        setModal={setFileViewModalPopup}
        header={fileHeader}
        handleClose={handleCloseDocView}
        base64={docViewBase64}
      />
    </div>
  );
};

const RecruiterRemarks = ({ recuiterRemark = "" }) => {
  const [accordion, setAccordion] = useState(true);

  return (
    <>
      <Accordion
        elevation={0}
        className="mb-3"
        expanded={accordion}
        onChange={() => {
          setAccordion((prev) => !prev);
        }}
      >
        <AccordionSummary
          id="profile-accordion"
          style={{ background: "#3C5464" }}
          className="text-white acc_close"
          expandIcon={<ExpandMore />}
        >
          <div className="row d-flex align-items-center acc-heading">
            <div className="col-lg-10 col-md-10 col-sm-12">
              <p style={{ fontSize: "16px" }}>Recruiter Remarks</p>
            </div>
          </div>
        </AccordionSummary>

        <AccordionDetails className="page_heading">
          <div className="col-lg- col-sm-6 col-xs-6">
            <div className="mb-1">
              <label className="col-form-label">Recruiter Remarks</label>
              <InputForm
                className="form-control"
                placeholder={"Remarks"}
                isDisabled={true}
                textArea={true}
                rows={4}
                value={recuiterRemark}
                onChange={(val: any) => {}}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const DocumentViewer = ({
  modal,
  setModal,
  header,
  base64 = "",
  handleClose,
}) => {
  return (
    <>
      <Modal
        show={modal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">{header}</div>
        </div>
        <Modal.Body>
          <div
            className=""
            style={{
              height: "60vh",
              overflowX: "hidden",
            }}
          >
            <RCMPdfViewer pdfURL={base64 || ""} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn"
            style={{
              marginLeft: 5,
              color: "black !important",
              borderColor: "#acacac",
            }}
            onClick={handleClose}
          >
            Ok
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
