import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  GetSessionContent,
  GetThumbnailDetails,
  InsertUpdateCourseSession,
  ManageCourseSessions,
  createRegistrationRequest,
  getCourseData,
  getCourseDetailsForRequest,
  getCourseScheduleDetails,
  getRejectionReasonList,
  getRequestHeaderData,
  getRequestsApprovalTrail,
  requestsApproval,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import { APICall } from "../../Helpers/APICalls";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { LoaderContext } from "../../Helpers/Context/Context";
import defaultProfileImgPic from "../../Assets/Images/thumbnail.jpg";
import { Modal, Table } from "react-bootstrap";
import InputForm from "../../Components/InputForm/InputForm";
import SelectForm from "../../Components/SelectForm/SelectForm";
import ImageColumnComponent from "../../Components/LMS/ImageColumnComponent/ImageColumnComponent";
import notify from "../../Helpers/ToastNotification";
import "./journRegs.css";
import LMSDynamicGrid from "../../Components/LMSDynamicGrid/LMSDynamicGrid";

const SessionAccordion = (props) => {
  const [accordion, setAccordion] = useState(false);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { state } = useLocation();

  const [profileImg, setProfileImg] = useState(null);

  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();

  const [contents, setContents] = useState([]);

  const getContent = async (sessionId) => {
    showLoader();
    const res = await APICall(GetSessionContent, "POST", {
      SessionId: sessionId,
      Mode: "LISTCONTENTADDED",
      CreatedBy: userDetails.Id,
      LaunchId: state.launchId,
    });

    if (res.status === 0 && res.data.length > 0) {
      const contentData = res.data;

      for (let index = 0; index < contentData.length; index++) {
        const content = contentData[index];

        content.thumbnailUrl =
          content.thumbnailUrl !== null
            ? await getThumbnail(content.thumbnailUrl)
            : null;
      }

      setContents(contentData);
    }

    hideLoader();
  };

  const getThumbnail = async (thumbnailUrl) => {
    const res = await APICall(GetThumbnailDetails, "POST", {
      ThumbnailUrl: thumbnailUrl,
    });

    if (res.status === 0) {
      return `data:image;base64,${res.data.thumbnailDataObject}`;
    }
  };

  useEffect(() => {
    if (accordion) {
      getContent(props.currentSession.sessionId);

      if (props.currentSession.thumbnailUrl) {
        (async () => {
          const res = await getThumbnail(props.currentSession.thumbnailUrl);

          setProfileImg(res);
        })();
      }
    }
  }, [accordion]);

  const [currentSessionGridData, setCurrentSessionGridData] = useState([]);

  const sessionColumns = [
    {
      name: "sessionId",
      label: "Session ID",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "sessionTitle",
      label: "Session Title",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "sessionType",
      label: "Session Type",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "duration",
      label: "Duration",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "sessionObjective",
      label: "Session Objective",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <span
              dangerouslySetInnerHTML={{
                __html: value,
              }}></span>
          );
        },
      },
    },
  ];

  useEffect(() => {
    if (props.currentSession) {
      const gridData = [
        {
          sessionId: props.currentSession.sessionCode,
          sessionTitle: props.currentSession.sessionTitle,
          sessionType: props.currentSession.sessionType,
          duration: `${props.currentSession.totalDurationHours ?? "00"} hrs : ${
            props.currentSession.totalDurationMinutes ?? "00"
          } min`,
          sessionObjective: props.currentSession.sessionObjective,
        },
      ];

      setCurrentSessionGridData(gridData);
    }
  }, [props.currentSession]);

  return (
    <Accordion
      elevation={0}
      className="mb-3"
      expanded={accordion}
      onChange={() => setAccordion((prev) => !prev)}>
      <AccordionSummary
        id="profile-accordion"
        style={{ background: "#3C5464" }}
        className="text-white acc_close"
        expandIcon={<ExpandMore />}>
        <div className="row d-flex align-items-center acc-heading">
          <div className="col-lg-10 col-md-10 col-sm-12">
            <p style={{ fontSize: "16px" }}>
              {props.currentSession.sessionTitle} -{" "}
              {/* {props.currentSession.sessionCode} -{" "} */}
              {
                // moment(props.currentSession.sessionStartDate).format(
                //   "DD-MMM-YYYY hh:mm"
                // )
                props.currentSession.newSessionStartDate
              }{" "}
              -{" "}
              {moment(props.currentSession.sessionEndDate).year() === 9999
                ? "N/A"
                : // moment(props.currentSession.sessionEndDate).format(
                  //     "DD-MMM-YYYY hh:mm"
                  //   )
                  props.currentSession.newSessionEndDate}
            </p>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails className="">
        <div className="row">
          <div className="col-lg-2">
            <div className="card">
              <img
                src={profileImg != null ? profileImg : defaultProfileImgPic}
                className="card-img-top"
                alt="Thumbnail Image"
              />
            </div>
          </div>
          <div className="col-lg-10 fnt_we">
            <div className="row">
              {/* <div className="col-lg-4">
                <p>
                  <b>Session ID :</b> {props.currentSession.sessionCode}
                </p>
              </div>
              <div className="col-lg-4">
                <p>
                  <b>Session Title :</b> {props.currentSession.sessionTitle}
                </p>
              </div>
              <div className="col-lg-4">
                <p>
                  <b>Session Type :</b> {props.currentSession.sessionType}
                </p>
              </div>
              <div className="col-lg-4">
                <p>
                  <b>Duration :</b> {props.currentSession.totalDurationHours}{" "}
                  hrs : {props.currentSession.totalDurationMinutes ?? "00"} min
                </p>
              </div>
              <div className="col-lg-8 col-md-6 col-sm-4">
                <p>
                  <b className="leftalign">Session Objective :</b>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: props.currentSession.sessionObjective,
                    }}></span>
                </p>
              </div> */}
              <LMSDynamicGrid
                data={currentSessionGridData}
                columns={sessionColumns}
              />
            </div>
          </div>
          <div className="col-lg-12">
            {contents.length > 0 ? (
              <>
                <p className="mt-3 mb-3" style={{ color: "#2C2B7C" }}>
                  <b
                    className="rounded px-3 py-2"
                    style={{ backgroundColor: "#e7e7e7" }}>
                    Content
                  </b>
                </p>
                {/* <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th className="text-dark">Thumbnail</th>
                      <th className="text-dark">Title</th>
                      <th className="text-dark">Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contents.map((content) => (
                      <tr>
                        <td>
                          <div style={{ maxWidth: "100px" }} className="card">
                            <img
                              src={
                                content?.thumbnailUrl != null
                                  ? content?.thumbnailUrl
                                  : defaultProfileImgPic
                              }
                              className="card-img-top"
                              alt="Thumbnail Image"
                            />
                          </div>
                        </td>
                        <td>{content?.contentTitle}</td>
                        <td>{content?.contentType}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table> */}
                <div className="row">
                  <div className="col-lg-12 p-0">
                    <ContentGrid contents={contents} />
                  </div>
                </div>
              </>
            ) : (
              <h4>No content available</h4>
            )}
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const ContentGrid = ({ contents }) => {
  const { state } = useLocation();

  const [contentGridData, setContentGridData] = useState<any>(contents);

  const contentColumns = [
    {
      name: "thumbnailUrl",
      label: "Thumbnail",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={{ maxWidth: "100px" }} className="card">
              <img
                src={value != null ? value : defaultProfileImgPic}
                className="card-img-top"
                alt="Thumbnail Image"
              />
            </div>
          );
        },
      },
    },
    {
      name: "contentTitle",
      label: "Title",
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: "contentType",
      label: "Type",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  return <LMSDynamicGrid data={contentGridData} columns={contentColumns} />;
};

const Registration = (props) => {
  const navigate = useNavigate();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [accordion, setAccordion] = useState(true);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { state } = useLocation();

  const [profileImg, setProfileImg] = useState(null);

  const [requestId, setRequestId] = useState(0);

  const [courseId, setCourseId] = useState(0);

  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();

  const getThumbnail = async (thumbnailUrl) => {
    const res = await APICall(GetThumbnailDetails, "POST", {
      ThumbnailUrl: thumbnailUrl,
    });

    if (res.status === 0) {
      return `data:image;base64,${res.data.thumbnailDataObject}`;
    }
  };

  const [courseData, setCourseData] = useState<any>(null);

  const [rejectionOptions, setRejectionOptions] = useState([]);

  const [decision, setDecision] = useState(true);

  const [selectRejectionReason, setSelectRejectionReason] = useState(null);

  const [remarks, setRemarks] = useState("");

  const [courseScheduleData, setCourseScheduleData] = useState([]);

  useEffect(() => {
    if (decision) {
      setSelectRejectionReason(null);
      setFormErrors({});
    }
  }, [decision]);

  const getCourseScheduleData = async () => {
    const res = await APICall(getCourseScheduleDetails, "POST", {
      CourseScheduleId: state.courseScheduleId,
      LaunchId: state.launchId,
    });

    if (res.status === 0 && res.data !== null && res.data.length > 0) {
      setCourseId(res.data[0].courseId);
      setCourseScheduleData(res.data);
      setSessionsArr(res.data);
    }
  };

  const getRejectionDropdown = async () => {
    const res = await APICall(getRejectionReasonList, "POST", {});

    if (res.status === 0 && res.data !== null && res.data.length > 0) {
      setRejectionOptions(res.data);
    }
  };

  useEffect(() => {
    getCourseScheduleData();
    getRejectionDropdown();
  }, []);

  const getLMCourceMasterDataByIdApiCall = async () => {
    showLoader();
    // const responseData = await APICall(getCourseData, "POST", {
    //   Id: courseId,
    //   LaunchId: state.launchId,
    // });

    //getCourseDetailsForRequest

    const responseData = await APICall(getCourseDetailsForRequest, "POST", {
      CourseId: courseId,
      LaunchId: state.launchId,
    });

    if (responseData?.status === 0) {
      // const journyIds = responseData?.data?.programIds?.split(",") || null;

      // const journyArray = [];

      // const LMSCourceMasterData = {
      //   courseId: responseData?.data?.courseCode,
      //   courseTitle: responseData?.data?.courseTitle,
      //   learningObjective: responseData?.data?.learningObjective,
      //   thumbnailUrl: responseData?.data?.thumbnailUrl,
      //   journey: journyArray.length > 0 ? journyArray : null,
      //   minParticipants: responseData?.data?.minimumParticipants,
      //   maxParticipants: responseData?.data?.maximumParticipants,
      //   requestBefore: responseData?.data?.requestBeforeDays,
      //   optoutBefore: responseData?.data?.optoutBeforeDays,
      //   requestApprovalRequired: null,
      //   optOutApprovalRequired: null,
      //   optoutAllowed: !responseData?.data?.requestRequired,
      //   requestRequired: !responseData?.data?.optoutAllowed,
      //   registrationByManager: responseData?.data?.registerationByManager,
      //   scheduleRequired: responseData?.data?.launched,
      //   mandatoryCourse: responseData?.data?.mandetoryCourse,
      // };

      debugger;

      const coursedetails = {
        courseId: responseData?.data?.courseCode,
        courseTitle: responseData?.data?.courseTitle,
        journeyTitle: responseData?.data?.programTitle,
        startDate: responseData?.data?.startDate,
        endDate: responseData?.data?.endDate,
        trainingCenter: responseData?.data?.trainingCentre,
        trainer: responseData?.data?.trainer,
        coOrdinator: responseData?.data?.coordinator,
        learningObjective: responseData?.data?.learningObjective,
      };

      if (responseData?.data?.thumbnailUrl) {
        setProfileImg(await getThumbnail(responseData?.data?.thumbnailUrl));
      }

      setCourseData(coursedetails);
    }

    hideLoader();
  };

  const [requestHeaderData, setRequestHeaderData] = useState<any>(null);

  const getRequestHeaderDetails = async () => {
    const res = await APICall(getRequestHeaderData, "POST", {
      RequestId: state.requestId,
      UserId: state.isManager ? state.userId : userDetails.Id,
      CourseId: courseId,
      LaunchId: state.launchId,
      RequestType: state?.type,
    });

    if (res.status === 0 && res.data) {
      setRequestHeaderData(res.data);
    }
  };

  useEffect(() => {
    if (courseId > 0) {
      getLMCourceMasterDataByIdApiCall();
      getRequestHeaderDetails();
    }
  }, [courseId]);

  const [approvalTrailData, setApprovalTrailData] = useState([]);

  const getApprovalTrail = async () => {
    const res = await APICall(getRequestsApprovalTrail, "POST", {
      RequestId: state.requestId,
      UserId: state.isManager ? state.userId : userDetails.Id,
      RequestType: state.type,
      CourseScheduleId: state.courseScheduleId,
      IsManager: state.isManager,
      CreatedBy: userDetails.Id,
    });

    if (res.status === 0 && res.data) {
      setApprovalTrailData(res.data);
    }
  };

  useEffect(() => {
    debugger;
    // if (state && state.requestId > 0) {
    getApprovalTrail();
    // }
  }, [state]);

  const [refresh, setRefresh] = useState(false);
  const [sessionsArr, setSessionsArr] = useState([]);

  const createRequest = async () => {
    const res = await APICall(createRegistrationRequest, "POST", {
      UserId: state.isManager ? state.userId : userDetails.Id,
      RequestType: state.type,
      CourseScheduleId: state.courseScheduleId,
      IsManager: state.isManager,
      CreatedBy: userDetails.Id,
    });

    if (res.status === 0 && res.data.isError === false) {
      notify(0, res.data.errorMessage);
      navigate("/LMSHome");
    } else {
      notify(1, res.data.errorMessage);
    }
  };

  const [formErrors, setFormErrors] = useState<any>({});

  const validateForm = () => {
    let errorObj = {};
    let isError = false;

    // if (remarks === "") {
    //   errorObj = {
    //     ...errorObj,
    //     ["remarks"]: "Required",
    //   };
    // } else {
    //   errorObj = {
    //     ...errorObj,
    //     ["remarks"]: "",
    //   };
    // }

    if (!selectRejectionReason) {
      errorObj = {
        ...errorObj,
        ["selectedReason"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["selectedReason"]: "",
      };
    }

    const isEmpty = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  const submitRequest = async () => {
    if (decision === false) {
      const isError = validateForm();

      if (isError) {
        return null;
      }
    }

    const res = await APICall(requestsApproval, "POST", {
      RequestId: state.requestId,
      UserId: userDetails.Id,
      RoleId:
        currentRoleId.code === "LMSAdmin" ||
        currentRoleId.label.toLowerCase() === "lm admin"
          ? currentRoleId.value
          : 0,
      Decision: decision === true ? "APPROVE" : "REJECT",
      RejectionReasonId: selectRejectionReason
        ? selectRejectionReason.value
        : null,
      Remarks: remarks,
      CreatedBy: userDetails.Id,
    });

    if (res.status === 0) {
      notify(
        0,
        decision
          ? "Request approved successfully"
          : "Request rejected successfully"
      );
      navigate("/RequestDashboard");
    } else {
      notify(1, "Something went wrong");
    }
  };

  const [courseScheduleGridData, setCourseScheduleGridData] = useState([]);

  useEffect(() => {
    if (courseData && courseScheduleData.length > 0) {
      let gridData = [
        {
          courseId: courseData.courseId,
          courseTitle: courseData.courseTitle,
          journeyTitle:
            requestHeaderData &&
            requestHeaderData.programTitle !== "" &&
            requestHeaderData.programTitle !== null
              ? requestHeaderData.programTitle
              : courseData?.journeyTitle,
          startDate: moment(courseScheduleData[0].courseStartDate).format(
            "DD-MMM-YYYY"
          ),
          endDate:
            moment(courseScheduleData[0].courseEndDate).year() === 9999
              ? "N/A"
              : courseData && moment(courseData?.endDate).format("DD-MMM-YYYY"),

          trainingCenter: courseScheduleData[0].trainingCenter,
          trainerName: courseScheduleData[0].trainerName,
          coordinatorName: courseScheduleData[0].coordinatorName,
          learningObjective: courseData.learningObjective,
        },
      ];

      setCourseScheduleGridData(gridData);
    }
  }, [courseData, courseScheduleData]);

  const courseScheduleColumns = [
    {
      name: "courseId",
      label: "Course ID",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "courseTitle",
      label: "Course Title",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "journeyTitle",
      label: "Journey Title",
      options: {
        filter: false,
        sort: false,
        width: 200,
      },
    },
    {
      name: "startDate",
      label: "Start Date",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "endDate",
      label: "End Date",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "trainingCenter",
      label: "Training Center",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "trainerName",
      label: "Trainer",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "coordinatorName",
      label: "Co-ordinator",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "learningObjective",
      label: "Learning Objective",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <span
              dangerouslySetInnerHTML={{
                __html: value,
              }}></span>
          );
        },
      },
    },
  ];

  const [requestHeaderGridData, setRequestHeaderGridData] = useState([]);

  const requestHeaderColumns = [
    {
      name: "requestCode",
      label: "Request No",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "requestDate",
      label: "Request Date",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "requestor",
      label: "Requestor",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "department",
      label: "Department",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "designation",
      label: "Designation",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "doj",
      label: "Date of joining",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  useEffect(() => {
    if (requestHeaderData) {
      const gridData = [
        {
          requestCode: requestHeaderData.requestCode,
          requestDate: moment(
            requestHeaderData.requestDate,
            "DD-MM-YYYY"
          ).isValid()
            ? moment(requestHeaderData.requestDate, "DD-MM-YYYY").format(
                "DD-MMM-YYYY"
              )
            : moment(new Date()).format("DD-MMM-YYYY"),
          requestor: requestHeaderData.requestor,
          department: requestHeaderData.department,
          designation: requestHeaderData.designation,
          doj: requestHeaderData.doj
            ? moment(requestHeaderData.doj).format("DD-MMM-YYYY")
            : null,
        },
      ];
      setRequestHeaderGridData(gridData);
    }
  }, [requestHeaderData]);

  return (
    <div className="container-fluid mt-3 mb-5">
      <div className="row">
        <div className="col-lg-8 breadcrumb modified-bredcrumb">
          <span>
            <ul>
              <li>
                <a href="/Catalogue">Catalogue</a>
              </li>
              <li>Registration</li>
            </ul>
          </span>
        </div>

        <div className="col-lg-4">
          <button
            onClick={() => {
              navigate(-1);
            }}
            disabled={false}
            className="btn btn-secondary float-right">
            <i className="fa-solid fa-arrow-left"></i> Back
          </button>
        </div>

        {/* <div className="col-lg-12">
          <div className="row bg_gry"> */}
        <div
          style={{ color: "#2C2B7C" }}
          className="col-lg-12 d-flex justify-content-start mb-4">
          <h2
            className="rounded px-3 py-2"
            style={{ backgroundColor: "#e7e7e7" }}>
            {requestHeaderData && requestHeaderData.requestTitle}
          </h2>
        </div>
        {/* </div>
        </div> */}
        <br />
        <hr />

        <div className="col-lg-12">
          {/* <div className="col-lg-3">
              <b>
                <div className="lable_width">Request No</div> :
              </b>{" "}
              {requestHeaderData && requestHeaderData.requestCode}
            </div>
            <div className="col-lg-3">
              <b>
                <div className="lable_width">Request Date</div> :
              </b>{" "}
              {requestHeaderData &&
              requestHeaderData.requestDate &&
              moment(requestHeaderData.requestDate, "DD-MM-YYYY").isValid()
                ? moment(requestHeaderData.requestDate, "DD-MM-YYYY").format(
                    "DD-MMM-YYYY"
                  )
                : moment(new Date()).format("DD-MMM-YYYY")}
            </div>
            <div className="col-lg-3">
              <b>
                <div className="lable_width">Requestor</div> :
              </b>{" "}
              {requestHeaderData && requestHeaderData.requestor}
            </div>
            <div className="col-lg-3">
              <b>
                <div className="lable_width">Department</div> :
              </b>{" "}
              {requestHeaderData && requestHeaderData.department}
            </div>
            <div className="col-lg-3">
              <b>
                <div className="lable_width">Designation</div> :
              </b>{" "}
              {requestHeaderData && requestHeaderData.designation}
            </div>
            <div className="col-lg-3">
              <b>
                <div className="lable_width">Date of joining</div> :
              </b>{" "}
              {requestHeaderData && requestHeaderData.doj
                ? moment(requestHeaderData.doj).format("DD-MMM-YYYY")
                : null}
            </div> */}
          <LMSDynamicGrid
            data={requestHeaderGridData}
            columns={requestHeaderColumns}
          />
        </div>
      </div>
      <hr />

      <div className="row mt-2">
        <div className="col-lg-12">
          <div className="row fnt_we">
            <div className="col-lg-2">
              <div className="card">
                <img
                  src={profileImg != null ? profileImg : defaultProfileImgPic}
                  className="card-img-top"
                  alt="Thumbnail Image"
                />
              </div>
              {/* <ImageColumnComponent
                link={courseData && courseData.thumbnailUrl}
              /> */}
            </div>
            <div className="col-lg-10">
              <div className="row">
                {/* <div className="col-lg-4">
                  <b className="wid_obj">
                    <div className="lable_width">Course ID</div> :
                  </b>{" "}
                  {courseData && courseData.courseId}
                </div>
                <div className="col-lg-4">
                  <b className="wid_obj">
                    <div className="lable_width">Course Title</div> :
                  </b>{" "}
                  {courseData && courseData.courseTitle}
                </div>
                <div className="col-lg-4">
                  <b className="wid_obj">
                    <div className="lable_width">Journey Title</div> :
                  </b>{" "}
                  {requestHeaderData &&
                  requestHeaderData.programTitle !== "" &&
                  requestHeaderData.programTitle !== null
                    ? requestHeaderData.programTitle
                    : courseData?.journeyTitle}
                </div>

                <div className="col-lg-4">
                  <b className="wid_obj">
                    <div className="lable_width">Start Date</div> :
                  </b>{" "}
                  {courseScheduleData.length > 0 &&
                    moment(courseScheduleData[0].courseStartDate).format(
                      "DD-MMM-YYYY"
                    )}
                </div>

                <div className="col-lg-4">
                  <b className="wid_obj">
                    <div className="lable_width">End Date</div> :
                  </b>{" "}
                  {courseScheduleData.length > 0 &&
                  moment(courseScheduleData[0].courseEndDate).year() === 9999
                    ? "N/A"
                    : courseData &&
                      moment(courseData?.endDate).format("DD-MMM-YYYY")}
                </div>

                <div className="col-lg-4">
                  <b className="wid_obj">
                    <div className="lable_width">Training Center</div> :
                  </b>{" "}
                  {courseScheduleData.length > 0 &&
                    courseScheduleData[0].trainingCenter}
                </div>

                <div className="col-lg-4">
                  <b className="wid_obj">
                    <div className="lable_width">Trainer</div> :
                  </b>{" "}
                  {courseScheduleData.length > 0 &&
                    courseScheduleData[0].trainerName}
                </div>

                <div className="col-lg-4">
                  <b className="wid_obj">
                    <div className="lable_width">Co-ordinator</div> :
                  </b>{" "}
                  {courseScheduleData.length > 0 &&
                    courseScheduleData[0].coordinatorName}
                </div>

                <div className="col-lg-12 ">
                  <b className="wid_obj">
                    <div className="lable_width">Learning Objective</div> :
                  </b>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: courseData && courseData.learningObjective,
                    }}></span>
                </div> */}
                <LMSDynamicGrid
                  data={courseScheduleGridData}
                  columns={courseScheduleColumns}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {state && state.action === "edit" ? (
        <>
          <hr />

          <div className="row mb-4">
            {state && state.requestId > 0 && state.action === "edit" ? (
              <>
                <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                  <div className="mb-1">
                    <label className="col-form-label">
                      Decision
                      <sup>*</sup>
                    </label>

                    <div>
                      <input
                        className="ml-2 mr-1"
                        disabled={false}
                        id={`approve`}
                        type="radio"
                        name="decision"
                        onChange={(e) => setDecision(true)}
                        checked={decision === true}
                      />
                      <label htmlFor={`approve`}>Approve</label>
                      <input
                        className="ml-2 mr-1"
                        disabled={false}
                        id={`reject`}
                        name="decision"
                        type="radio"
                        onChange={(e) => setDecision(false)}
                        checked={decision === false}
                      />
                      <label htmlFor={`reject`}>Reject</label>
                    </div>
                  </div>
                </div>

                {decision === false && (
                  <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                    <div className="mb-1">
                      <label className="col-form-label">
                        Rejection Reason
                        <sup>*</sup>
                      </label>
                      <SelectForm
                        isClearable
                        isSearchable
                        options={rejectionOptions}
                        placeholder={""}
                        isDisabled={false}
                        value={selectRejectionReason}
                        onChange={(event) => {
                          setSelectRejectionReason(event);
                        }}
                        isMulti={false}
                        noIndicator={false}
                        noSeparator={false}
                      />
                      {formErrors["selectedReason"] && (
                        <p style={{ color: "red" }}>
                          {formErrors["selectedReason"]}
                        </p>
                      )}
                    </div>
                  </div>
                )}

                <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                  <div className="mb-1">
                    <label className="col-form-label">
                      Remarks
                      {/* <sup>*</sup> */}
                    </label>

                    <InputForm
                      className="form-control"
                      placeholder={""}
                      isDisabled={false}
                      textArea={true}
                      value={remarks}
                      onChange={(e) => {
                        setRemarks(e.target.value);
                      }}
                      maxLength="255"
                    />
                    {formErrors["remarks"] && (
                      <p style={{ color: "red" }}>{formErrors["remarks"]}</p>
                    )}
                  </div>
                </div>
                <div
                  className={
                    decision
                      ? "mt-4 col-lg-9 col-sm-9 col-xs-9"
                      : "mt-4 col-lg-3 col-sm-3 col-xs-4"
                  }>
                  <button
                    onClick={() => {
                      submitRequest();
                    }}
                    disabled={false}
                    className="btn btn-primary float-right">
                    Submit
                  </button>
                </div>
              </>
            ) : (
              state &&
              state.requestId === 0 &&
              state.action === "edit" && (
                <div className={"col-lg-12 col-sm-12 col-xs-12"}>
                  <button
                    onClick={() => {
                      setShowConfirmationModal(true);
                    }}
                    disabled={false}
                    className="btn btn-primary float-right">
                    {state.isManager
                      ? "Assign"
                      : state.type === "IN" &&
                        courseScheduleData.length > 0 &&
                        courseScheduleData[0]?.requestApprovalRequired === 1
                      ? "Register"
                      : state.type === "OUT" &&
                        courseScheduleData.length > 0 &&
                        courseScheduleData[0]?.optoutApprovalRequired === 1
                      ? "Opt-out"
                      : "Send for approval"}
                  </button>
                </div>
              )
            )}
          </div>
        </>
      ) : (
        state &&
        state.requestId === 0 &&
        state.action === "view" && (
          <>
            <hr />
            <h5 className="text-right text-danger">
              {state.launchId !== 0
                ? "Sorry registration has closed"
                : "Not Launched"}
            </h5>
          </>
        )
      )}

      {approvalTrailData.length > 0 && (
        <>
          <hr />
          <p className="mt-3 mb-2 hed_bl">Approval Trail</p>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th className="text-dark">Approval level</th>
                <th className="text-dark">Approver</th>
                <th className="text-dark">Decision</th>
                <th className="text-dark">Rejection Reason</th>
                <th className="text-dark">Decision On</th>
                <th className="text-dark">Remarks</th>
              </tr>
            </thead>
            <tbody>
              {approvalTrailData.map((trail) => (
                <tr>
                  <td>{trail && trail?.approvalLevel}</td>
                  <td>{trail && trail?.approver}</td>
                  <td>{trail && trail?.decision}</td>
                  <td>{trail && trail?.rejectionReason}</td>
                  <td>
                    {trail && moment(trail.decisionOn, "DD-MM-YYYY").isValid()
                      ? moment(trail?.decisionOn, "DD-MM-YYYY HH:mm").format(
                          "DD-MMM-YYYY HH:mm"
                        )
                      : null}
                  </td>
                  <td>{trail && trail?.remarks}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
      <div className="row mt-3">
        <div className="col-lg-12">
          {sessionsArr.length > 0 &&
            sessionsArr.map((currentSession, sessionIndex) => (
              <SessionAccordion
                currentSession={currentSession}
                sessionIndex={sessionIndex}
                sessionsArr={sessionsArr}
                setSessionsArr={setSessionsArr}
                setRefresh={setRefresh}
                courseId={courseId}
              />
            ))}
        </div>
      </div>
      <Modal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">Confirmation!</div>
        </div>
        <Modal.Body>
          {state.isManager && state.type === "IN" ? (
            <p>Are you sure you want to assign?</p>
          ) : state.type === "IN" &&
            courseScheduleData.length > 0 &&
            courseScheduleData[0]?.requestApprovalRequired === 1 ? (
            <p>Are you sure you want to register?</p>
          ) : state.type === "OUT" &&
            courseScheduleData.length > 0 &&
            courseScheduleData[0]?.optoutApprovalRequired === 1 ? (
            <p>Are you sure you want to opt-out?</p>
          ) : (
            <p>Are you sure you want to send request for approval?</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowConfirmationModal(false);
              createRequest();
            }}>
            Yes
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowConfirmationModal(false);
            }}>
            No
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Registration;
