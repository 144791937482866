import React, { useContext, useEffect, useState } from "react";
import { LoaderContext } from "../../Helpers/Context/Context";
import { useLocation, useNavigate } from "react-router-dom";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { Modal } from "react-bootstrap";
import SelectForm from "../../Components/SelectForm/SelectForm";
import InputForm from "../../Components/InputForm/InputForm";
import { APICall } from "../../Helpers/APICalls";
import {
  GetCommonDropdownForScheduling,
  GetEmailTemplatesData,
  ManageReminderMail,
  ReminderMailDorpdown,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import RTE from "../../Components/RTE/RTE";
import notify from "../../Helpers/ToastNotification";
import is from "date-fns/locale/is";
import DateForm from "../../Components/DateForm/DateForm";
import moment from "moment";
import { Tooltip } from "@mui/material";

interface IOptions {
  label?: string;
  value?: number | string;
  code?: string;
}

interface IFormData {
  courseScheduleId?: IOptions | string | any;
  emailTemplatesId?: IOptions | Number | any;
  participantStatusId?: IOptions | Number | any;
  subject?: string | any;
  body?: string | any;
  templateName?: string | any;
  cc?: IOptions | any;
  bcc?: IOptions | any;
  effectiveDate?: Date | String | any;
  frequency?: string | any;
  repeatCount?: string | any;
}
const ReminderMail = ({
  showReminderMailModel,
  setShowReminderMailModel,
  newLaunchHubId,
}) => {
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isDisable, setIsDisable] = useState(
    state && state?.isDisable ? true : false
  );
  let { userDetails, currentRoleId }: any = useUserContext();
  const [formData, setFormData] = useState<IFormData>({});
  const [formErrors, setFormErrors] = useState<IFormData>({});
  const [formOptions, setFormOptions] = useState<IFormData>({});

  const [isSavetemplate, setIsSavetemplate] = useState(false);

  const [isImmediate, setIsImmediate] = useState(true);

  const listofVariables = [
    "[[First Name]]",
    "[[Last Name]]",
    "[[Course Name]]",
    "[[Journey Name]]",
    "[[Start Date]]",
    "[[End Date]]",
    "[[Location]]",
    "[[Trainer Name]]",
  ];

  const tooltip = () => {
    return (
      <ul>
        <li style={{ color: "#007bff" }}>List of Variables</li>
        {listofVariables && listofVariables?.map((val) => <li>{val}</li>)}
      </ul>
    );
  };

  const interval = () => {
    return (
      <span>
        Interval of number of days after which the reminders should be sent
      </span>
    );
  };

  const totalReminder = () => {
    return <span>Total number of reminders to be sent</span>;
  };

  useEffect(() => {
    const responseData = async () => {
      //COURSESCHEDULE
      const dropdownObj = {
        mode: "COURSESCHEDULE",
        userId: userDetails?.Id,
        roleId: currentRoleId?.value,
        launchHubId: newLaunchHubId,
      };
      const res = await APICall(ReminderMailDorpdown, "POST", dropdownObj);

      if (res.data && res.data.length > 0) {
        setFormOptions((prev) => {
          return {
            ...prev,
            courseScheduleId: res.data,
          };
        });
      }

      //EMAILTEMPLATE

      dropdownObj.mode = "EMAILTEMPLATE";
      const res1 = await APICall(ReminderMailDorpdown, "POST", dropdownObj);

      if (res1.data && res1.data.length > 0) {
        setFormOptions((prev) => {
          return {
            ...prev,
            emailTemplatesId: res1.data,
          };
        });
      }

      //PARTICIPANTSTATUS

      dropdownObj.mode = "PARTICIPANTSTATUS";
      const res2 = await APICall(ReminderMailDorpdown, "POST", dropdownObj);

      if (res2.data && res2.data.length > 0) {
        setFormOptions((prev) => {
          return {
            ...prev,
            participantStatusId: res2.data,
          };
        });
      }
    };

    showLoader();

    responseData();

    hideLoader();
  }, [showReminderMailModel]);

  const getEmailTemplatesDataApiCall = async (e) => {
    try {
      if (e?.value > 0) {
        const paramater = {
          userId: userDetails?.Id,
          roleId: currentRoleId?.value,
          Id: e?.value,
        };

        showLoader();
        const response = await APICall(
          GetEmailTemplatesData,
          "POST",
          paramater
        );

        if (response?.status === 0) {
          setFormData({
            ...formData,
            subject: response?.data?.subject,
            body: response?.data?.emailBody,
            emailTemplatesId: e,
          });
        } else {
          setFormData({
            ...formData,
            subject: "",
            body: "",
            emailTemplatesId: e,
          });
        }
      } else {
        setFormData({
          ...formData,
          subject: "",
          body: "",
          emailTemplatesId: e,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const formOnChange = (e, type) => {
    switch (type) {
      case "courseScheduleId":
        setFormData({
          ...formData,
          courseScheduleId: e,
        });
        break;
      case "emailTemplatesId":
        getEmailTemplatesDataApiCall(e);

        break;

      case "participantStatusId":
        setFormData({
          ...formData,
          participantStatusId: e,
        });
        break;

      case "subject":
        setFormData({
          ...formData,
          subject: e.target.value,
        });
        break;

      case "body":
        setFormData({
          ...formData,
          body: e,
        });
        break;

      case "templateName":
        setFormData({
          ...formData,
          templateName: e.target.value,
        });
        break;

      case "cc":
        setFormData({
          ...formData,
          cc: e,
        });
        break;

      case "bcc":
        setFormData({
          ...formData,
          bcc: e,
        });
        break;

      case "effectiveDate":
        setFormData({
          ...formData,
          effectiveDate: e,
        });
        break;

      case "frequency":
        setFormData({
          ...formData,
          frequency: e.target.value,
        });
        break;

      case "repeatCount":
        setFormData({
          ...formData,
          repeatCount: e.target.value,
        });
        break;

      default:
        break;
    }
  };

  const validateForm = () => {
    let errorObj = {};
    let isError = false;
    setFormErrors({});

    if (
      formData?.courseScheduleId === null ||
      formData?.courseScheduleId === undefined ||
      formData?.courseScheduleId?.length === 0
    ) {
      errorObj = {
        ...errorObj,
        ["courseScheduleId"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["courseScheduleId"]: "",
      };
    }

    if (
      formData?.participantStatusId === null ||
      formData?.participantStatusId === undefined
    ) {
      errorObj = {
        ...errorObj,
        ["participantStatusId"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["participantStatusId"]: "",
      };
    }

    if (
      formData?.subject === null ||
      formData?.subject === undefined ||
      formData?.subject === ""
    ) {
      errorObj = {
        ...errorObj,
        ["subject"]: "Required",
      };
    } else if (
      formData?.subject !== null ||
      formData?.subject !== undefined ||
      formData?.subject !== ""
    ) {
      const inputString = formData?.subject;

      const regex = /\[\[(.*?)\]\]/g;
      const matches = inputString.matchAll(regex);
      const resultArray = [];

      for (const match of matches) {
        resultArray.push(match[0]);
      }

      if (resultArray.length > 0) {
        const wordSet = new Set(listofVariables);

        const allWordsMatch = resultArray.every((word) => wordSet.has(word));

        if (allWordsMatch) {
          errorObj = {
            ...errorObj,
            ["subject"]: "",
          };
        } else {
          errorObj = {
            ...errorObj,
            ["subject"]: "Please add valid keywords",
          };
        }
      }
    } else {
      errorObj = {
        ...errorObj,
        ["subject"]: "",
      };
    }

    if (
      formData?.body === null ||
      formData?.body === undefined ||
      formData?.body === ""
    ) {
      errorObj = {
        ...errorObj,
        ["body"]: "Required",
      };
    } else if (
      formData?.body !== null ||
      formData?.body !== undefined ||
      formData?.body !== ""
    ) {
      const inputString = formData?.body;

      const regex = /\[\[(.*?)\]\]/g;
      const matches = inputString.matchAll(regex);
      const resultArray = [];

      for (const match of matches) {
        resultArray.push(match[0]);
      }

      if (resultArray.length > 0) {
        const wordSet = new Set(listofVariables);

        const allWordsMatch = resultArray.every((word) => wordSet.has(word));

        if (allWordsMatch) {
          errorObj = {
            ...errorObj,
            ["body"]: "",
          };
        } else {
          errorObj = {
            ...errorObj,
            ["body"]: "Please add valid keywords",
          };
        }
      }
    } else {
      errorObj = {
        ...errorObj,
        ["body"]: "",
      };
    }

    if (isSavetemplate) {
      if (
        formData?.templateName === null ||
        formData?.templateName === undefined ||
        formData?.templateName === ""
      ) {
        errorObj = {
          ...errorObj,
          ["templateName"]: "Required",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["templateName"]: "",
        };
      }
    }

    if (!isImmediate) {
      if (
        formData?.effectiveDate === null ||
        formData?.effectiveDate === undefined ||
        !moment(formData.effectiveDate).isValid()
      ) {
        errorObj = {
          ...errorObj,
          ["effectiveDate"]: "Required",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["effectiveDate"]: "",
        };
      }

      if (
        formData?.frequency === null ||
        formData?.frequency === undefined ||
        formData?.frequency === ""
      ) {
        errorObj = {
          ...errorObj,
          ["frequency"]: "Required",
        };
      } else if (!/^([0-9]+|0)$/.test(formData?.frequency)) {
        errorObj = {
          ...errorObj,
          ["frequency"]: "Only numbers are allowed",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["frequency"]: "",
        };
      }

      if (
        formData?.repeatCount === null ||
        formData?.repeatCount === undefined ||
        formData?.repeatCount === ""
      ) {
        errorObj = {
          ...errorObj,
          ["repeatCount"]: "Required",
        };
      } else if (!/^([0-9]+|0)$/.test(formData?.repeatCount)) {
        errorObj = {
          ...errorObj,
          ["repeatCount"]: "Only numbers are allowed",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["repeatCount"]: "",
        };
      }
    }

    const isEmpty = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  const handleReminderMailFunction = async () => {
    showLoader();

    if (validateForm()) {
      hideLoader();
      return;
    }

    const parameters = {
      CourseScheduleIds: formData?.courseScheduleId
        ?.map((s) => s.value)
        .join(","),
      EmailTemplatesId: formData?.emailTemplatesId?.value,
      ParticipantStatusId: formData?.participantStatusId?.value,
      Subject: formData?.subject,
      EmailBody: formData?.body,
      IsSaveTemplate: isSavetemplate,
      TemplateName: formData?.templateName,
      CCIds: formData?.cc?.map((s) => s.value).join(","),
      BCCIs: formData?.bcc?.map((s) => s.value).join(","),
      IsSchedule: !isImmediate,
      EffectiveDate: isImmediate
        ? moment().format("MM-DD-YYYY")
        : moment(formData?.effectiveDate).format("MM-DD-YYYY"),
      Frequency: formData?.frequency,
      RepeatCount: formData?.repeatCount,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    };

    try {
      showLoader();
      const responseData = await APICall(
        ManageReminderMail,
        "POST",
        parameters
      );

      if (responseData?.status === 0) {
        notify(responseData?.status, responseData?.message);
        setShowReminderMailModel(false);
        handleResetFunction();
      } else if (responseData?.status === 1) {
        notify(responseData?.status, responseData?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const handleResetFunction = () => {
    setFormData({
      ...formData,
      courseScheduleId: null,
      emailTemplatesId: null,
      participantStatusId: null,
      subject: null,
      body: null,
      templateName: null,
      cc: null,
      bcc: null,
      effectiveDate: null,
      frequency: null,
      repeatCount: null,
    });

    setIsSavetemplate(false);

    setIsImmediate(true);

    setFormErrors({});
  };

  async function fetchDropdown(searchText = "", code = "", value = null) {
    try {
      const response = await APICall(
        GetCommonDropdownForScheduling,
        "POST",
        {
          SearchText: searchText,
          DropdownValues: value,
          Code: code,
          UserId: userDetails?.Id,
          RoleId: currentRoleId?.value,
        },
        true
      );

      if (response?.status === 0) {
        return response?.data;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  async function handleSearchForAsyncDropdown(event, code, callback) {
    try {
      if (event?.length >= 3) {
        const data = await fetchDropdown(event, code);
        callback(data);
      } else {
        callback([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      {" "}
      {/* Reminder Mail */}
      <Modal
        show={showReminderMailModel}
        onHide={() => setShowReminderMailModel(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">{`Reminder Mail`}</div>
        </div>
        <Modal.Body>
          <>
            <div className="row">
              <>
                <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                  <div className="dropdown mb-1">
                    <label className="col-form-label">Course Schedule</label>{" "}
                    <sup>*</sup>
                    <SelectForm
                      isClearable
                      isSearchable
                      options={formOptions?.courseScheduleId}
                      placeholder={"Course Schedule"}
                      isDisabled={isDisable}
                      value={formData?.courseScheduleId}
                      onChange={(e) => {
                        formOnChange(e, "courseScheduleId");
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                    />
                    {formErrors["courseScheduleId"] && (
                      <p style={{ color: "red" }}>
                        {formErrors["courseScheduleId"]}
                      </p>
                    )}
                  </div>
                </div>

                <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                  <div className="dropdown mb-1">
                    <label className="col-form-label">Email Templates</label>{" "}
                    <SelectForm
                      isClearable
                      isSearchable
                      options={formOptions?.emailTemplatesId}
                      placeholder={"Email Templates"}
                      isDisabled={isDisable}
                      value={formData?.emailTemplatesId}
                      onChange={(e) => {
                        formOnChange(e, "emailTemplatesId");
                      }}
                      isMulti={false}
                      noIndicator={false}
                      noSeparator={false}
                    />
                    {formErrors["emailTemplatesId"] && (
                      <p style={{ color: "red" }}>
                        {formErrors["emailTemplatesId"]}
                      </p>
                    )}
                  </div>
                </div>

                <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                  <div className="dropdown mb-1">
                    <label className="col-form-label">Participant Status</label>{" "}
                    <sup>*</sup>
                    <SelectForm
                      isClearable
                      isSearchable
                      options={formOptions?.participantStatusId}
                      placeholder={"Participant Status"}
                      isDisabled={isDisable}
                      value={formData?.participantStatusId}
                      onChange={(e) => {
                        formOnChange(e, "participantStatusId");
                      }}
                      isMulti={false}
                      noIndicator={false}
                      noSeparator={false}
                    />
                    {formErrors["participantStatusId"] && (
                      <p style={{ color: "red" }}>
                        {formErrors["participantStatusId"]}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 col-sm-4 col-xs-4">
                  <div className="dropdown mb-1">
                    <label className="col-form-label">CC</label>
                    <SelectForm
                      isClearable
                      isSearchable
                      async
                      options={(searchString, cb) => {
                        handleSearchForAsyncDropdown(
                          searchString,
                          "co_ordinator",
                          cb
                        );
                      }}
                      placeholder={"Type to search"}
                      isDisabled={isDisable}
                      value={formData?.cc}
                      onChange={(e: any) => {
                        formOnChange(e, "cc");
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                    />
                    {formErrors["cc"] && (
                      <p style={{ color: "red" }}>{formErrors["cc"]}</p>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 col-sm-4 col-xs-4">
                  <div className="dropdown mb-1">
                    <label className="col-form-label">BCC</label>
                    <SelectForm
                      isClearable
                      isSearchable
                      async
                      options={(searchString, cb) => {
                        handleSearchForAsyncDropdown(
                          searchString,
                          "co_ordinator",
                          cb
                        );
                      }}
                      placeholder={"Type to search"}
                      isDisabled={isDisable}
                      value={formData?.bcc}
                      onChange={(e: any) => {
                        formOnChange(e, "bcc");
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                    />
                    {formErrors["bcc"] && (
                      <p style={{ color: "red" }}>{formErrors["bcc"]}</p>
                    )}
                  </div>
                </div>

                <div className="col-lg-4 col-sm-4 col-xs-4">
                  <div className="row">
                    <div className="col-lg-6 col-sm-6 col-xs-6">
                      <div className="mb-1">
                        <label className="col-form-label"></label>
                        <div>
                          <input
                            className="ml-2 mr-1"
                            id={`Immediate`}
                            type="radio"
                            name="Immediate"
                            onChange={(e) => {
                              setIsImmediate(true);
                              setFormData({
                                ...formData,
                                effectiveDate: null,
                                frequency: "",
                                repeatCount: "",
                              });
                            }}
                            checked={isImmediate === true}
                          />
                          <label htmlFor={`Immediate`}>Immediate</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-xs-6">
                      <div className="mb-1">
                        <label className="col-form-label"></label>
                        <div>
                          <input
                            className="ml-2 mr-1"
                            id={`Schedule`}
                            type="radio"
                            name="Schedule"
                            onChange={(e) => {
                              setIsImmediate(false);
                            }}
                            checked={isImmediate === false}
                          />
                          <label htmlFor={`Schedule`}>Schedule</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {!isImmediate && (
                  <>
                    <div className="col-lg-4 col-sm-4 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Effective Date <span style={{ color: "red" }}>*</span>
                        </label>
                        <DateForm
                          placeholder={"Start Date"}
                          isDisabled={isDisable}
                          value={formData?.effectiveDate || null}
                          onChange={(e: any) => {
                            formOnChange(e, "effectiveDate");
                          }}
                        />
                        {formErrors["effectiveDate"] && (
                          <p style={{ color: "red" }}>
                            {formErrors["effectiveDate"]}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                      <div className="mb-1">
                        <label className="col-form-label">Frequency</label>
                        <sup>*</sup>{" "}
                        <Tooltip title={interval()}>
                          <i className="fa fa-info-circle"></i>
                        </Tooltip>
                        <InputForm
                          className="form-control"
                          placeholder={"Frequency"}
                          isDisabled={isDisable}
                          textArea={false}
                          value={formData?.frequency}
                          onChange={(e) => {
                            formOnChange(e, "frequency");
                          }}
                          maxLength="250"
                        />
                        {formErrors["frequency"] && (
                          <p style={{ color: "red" }}>
                            {formErrors["frequency"]}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                      <div className="mb-1">
                        <label className="col-form-label">Repeat Count</label>
                        <sup>*</sup>{" "}
                        <Tooltip title={totalReminder()}>
                          <i className="fa fa-info-circle"></i>
                        </Tooltip>
                        <InputForm
                          className="form-control"
                          placeholder={"Repeat Count"}
                          isDisabled={isDisable}
                          textArea={false}
                          value={formData?.repeatCount}
                          onChange={(e) => {
                            formOnChange(e, "repeatCount");
                          }}
                          maxLength="250"
                        />
                        {formErrors["repeatCount"] && (
                          <p style={{ color: "red" }}>
                            {formErrors["repeatCount"]}
                          </p>
                        )}
                      </div>
                    </div>
                  </>
                )}

                <div className={"col-lg-12 col-sm-12 col-xs-12"}>
                  <div className="mb-1">
                    <label className="col-form-label">Subject</label>
                    <sup>*</sup>
                    <InputForm
                      className="form-control"
                      placeholder={"Subject"}
                      isDisabled={isDisable}
                      textArea={false}
                      value={formData?.subject}
                      onChange={(e) => {
                        formOnChange(e, "subject");
                      }}
                      maxLength="250"
                    />
                    {formErrors["subject"] && (
                      <p style={{ color: "red" }}>{formErrors["subject"]}</p>
                    )}
                  </div>
                </div>

                <div className={"col-lg-12 col-sm-12 col-xs-12"}>
                  <div className="mb-1">
                    <label className="col-form-label">
                      Body
                      <sup>*</sup>{" "}
                      <Tooltip title={tooltip()}>
                        <i className="fa fa-info-circle"></i>
                      </Tooltip>
                    </label>

                    <RTE
                      placeholder={""}
                      content={formData?.body}
                      disabled={isDisable}
                      onblur={(e) => {
                        formOnChange(e, "body");
                      }}
                    />

                    {formErrors["body"] && (
                      <p style={{ color: "red" }}>{formErrors["body"]}</p>
                    )}
                  </div>
                </div>

                <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                  <div className="mb-1">
                    <label className="col-form-label">Save Template</label>
                    <br></br>

                    <input
                      className="form-contro ml-2 mr-1"
                      disabled={false}
                      type="checkbox"
                      onChange={(e) => {
                        setIsSavetemplate(e.target.checked);
                      }}
                      checked={isSavetemplate}
                    />
                    {formErrors["saveTemplate"] && (
                      <p style={{ color: "red" }}>
                        {formErrors["saveTemplate"]}
                      </p>
                    )}
                  </div>
                </div>

                {isSavetemplate && (
                  <>
                    <div className={"col-lg-8 col-sm-8 col-xs-8"}>
                      <div className="mb-1">
                        <label className="col-form-label">Template Name</label>
                        <sup>*</sup>
                        <InputForm
                          className="form-control"
                          placeholder={"Template Name"}
                          isDisabled={isDisable}
                          textArea={false}
                          value={formData?.templateName}
                          onChange={(e) => {
                            formOnChange(e, "templateName");
                          }}
                          maxLength="250"
                        />
                        {formErrors["templateName"] && (
                          <p style={{ color: "red" }}>
                            {formErrors["templateName"]}
                          </p>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => handleReminderMailFunction()}>
            Send
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowReminderMailModel(false);
              handleResetFunction();
            }}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReminderMail;
