import React, { useState, useEffect, useContext } from "react";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { useLocation, useNavigate } from "react-router-dom";
import BaseModal from "../../Components/BaseModel/BaseModel";
import SelectForm from "../../Components/SelectForm/SelectForm";
import "./AnonymousCandidateDashboard.css";
import Collapse from "react-bootstrap/Collapse";
import { APICall } from "../../Helpers/APICalls";
import {
  getAnonymousCandidateDashboardData,
  getDropdowns,
} from "../../Helpers/APIEndPoints/EndPoints";
import InputForm from "../../Components/InputForm/InputForm";
import { Tooltip } from "@mui/material";
import { LoaderContext } from "../../Helpers/Context/Context";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import moment from "moment";
import { Document, Page, Outline } from "react-pdf/dist/esm/entry.webpack";
import { Modal } from "react-bootstrap";

const AnonymousCandidateDashboard = ({ setShowHeader }) => {
  const { state } = useLocation();

  const [sectionData, setSectionData] = useState({});
  // const [tabs, setTabs] = useState([]);
  // const [activetab, setActivetab] = useState<string>(null);
  const [filteredData, setFilteredData] = useState([]);
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [gridData, setGridData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [PageSize, setPageSize] = useState(10);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();

  const [legalEntity, setLegalEntity] = useState<any>([]);
  const [mrfLocation, setMrfLocation] = useState<any>([]);
  const [Bussiness, setBussiness] = useState<any>([]);

  const [commonDdValue, setCommonDdValue] = useState<any>({});
  const [ddValue, setDdValue] = useState({});
  const [IsIJP, setIsIJP] = useState(state?.IJP || false);
  const [sortDirection, setSortDirection] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [reset, setReset] = useState(false);
  const [model, setModelOpen] = useState(false);
  const pdfURL = require("./IJP Guidelines.pdf");
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  let { userDetails, currentRoleId }: any = useUserContext();

  const getDropdownsData = async (AC, cb) => {
    await showLoader();
    const dropdownData = await APICall(getDropdowns, "POST", {
      AC,
      PID: null,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });
    if (
      dropdownData.data !== null &&
      dropdownData.data !== undefined &&
      dropdownData.data.length > 0
    ) {
      setDdValue((prev) => ({ ...prev, [AC]: dropdownData.data }));
    } else {
      setDdValue((prev) => ({ ...prev, [AC]: [] }));
    }
    //setCommonDdValue((prev)=>({...prev, [AC]:[]}));
    await hideLoader();
  };

  useEffect(() => {
    if (!state?.IJP) {
      setShowHeader(false);
    }
  }, []);

  useEffect(() => {
    getInterfaceDetails();
  }, []);

  const getInterfaceDetails = async () => {
    showLoader();
    // ;
    const gridDataResponse = await APICall(
      "/api/Interface/GetInterfaceDetailsForGrid",
      "POST",
      {
        MN: "AnonymousCandidate",
        IN: "AnonymousCandidate_Dashboard",
        TN: "AnonymousCandidate_Dashboard",
      }
    );
    if (gridDataResponse?.data?.d?.fa !== null) {
      for await (const cFilter of gridDataResponse?.data?.d?.fa) {
        if (cFilter.filt === "multidropdown") {
          await getDropdownsData(cFilter.filAN, null);
        }
      }
    }
    if (gridDataResponse?.data?.d?.afa !== null) {
      for await (const cFilter of gridDataResponse?.data?.d?.afa) {
        if (cFilter.filt === "multidropdown") {
          await getDropdownsData(cFilter.filAN, null);
        }
      }
    }
    setGridData(gridDataResponse);
    await getGridStructureTwo();
    hideLoader();
  };

  useEffect(() => {
    if (gridData?.data?.d?.s[0]?.columnData.length > 0) {
      getGridStructureTwo();
    }
  }, [page, searchText, reset, sortDirection, sortColumn, state]);

  const getGridStructureTwo = async () => {
    showLoader();

    const GetDashboardData = await APICall(
      getAnonymousCandidateDashboardData,
      "POST",
      {
        DashboardName: "AnonymousCandidateDashboard",
        PageNumber: page,
        PageSize: PageSize,
        SearchText: searchText,
        SortColumn: sortColumn,
        SortOrder: sortDirection,
        MRORLEGENTY: legalEntity.map((s) => s.value).join(","),
        MRORLOC: mrfLocation.map((s) => s.value).join(","),
        BIS: Bussiness.map((s) => s.value).join(","),
        IsIJP: IsIJP,
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      }
    );
    //;
    if (GetDashboardData.data !== null && GetDashboardData.data.length > 0) {
      const formattedData = GetDashboardData.data.map((item) => {
        const createdDateUtc = item.mrtbfbd;
        const dateParts = new Date(createdDateUtc)
          .toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
          })
          .split("/");
        // const formattedDate = `${dateParts[0]} ${dateParts[1]} ${dateParts[2]}`;
        const formatedDate = moment(item.mrtbfbd).format("DD-MMM-YYYY");
        return {
          ...item,
          // mrtbfbd: formatedDate,
          mrtbfbd: dateParts[0] !== "Invalid Date" ? dateParts[0] : "",
        };
      });

      setCount(formattedData[0].count);
      setRowData(formattedData);

      // setCount(GetDashboardData.data[0].count);
      // setRowData(GetDashboardData.data);
    } else {
      setRowData([]);
      setCount(0);
    }
    hideLoader();
  };

  const onSubmitFilter = () => {
    if (gridData?.data?.d?.s[0]?.columnData.length > 0) {
      getGridStructureTwo();
    }
  };

  const onClickReset = () => {
    setLegalEntity([]);

    setMrfLocation([]);
    setBussiness([]);

    setSearchText("");

    setReset(!reset);
  };

  // useEffect(() => {
  //   (async () => {
  //     const tabs = await APICall("/api/Tabs/getTabs", "POST", {
  //       MN: "EmployeeCentral",
  //       IN: "Form",
  //     });

  //     if (tabs.data !== null && tabs.data.length > 0) {
  //       setTabs(tabs.data);
  //       setActivetab(tabs.data[0].TN);
  //     } else {
  //       setTabs([]);
  //     }
  //   })();
  // }, []);

  const view = (value, tableMeta) => {
    const TId = tableMeta.rowData[0];
    const JobId = tableMeta.rowData[3];

    navigate("/anonymouscanidate", {
      state: { TId, MRFId: TId, JobId, IsIJP, IsAnonymousCandidate: true },
    });
  };

  const edit = (value, tableMeta, url) => {
    const TId = tableMeta.rowData[0];
    //navigate("/agencycandidate", { state: { TId } }); //-----------------------------------------------------------CHANGE
    alert("Edit For: " + TId);
  };

  const copy = (value, tableMeta) => {
    alert("copy");
  };

  const modal = (value, tableMeta) => {
    alert("modal");
  };

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: PageSize,
    page: page,
    serverSide: true,
    onChangePage: async (page) => {
      setPage(page);
    },
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",
    onColumnSortChange: async (sortColumn, sortDirection) => {
      setPage(0);
      if (sortDirection === "asc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
    },
  };
  //option which is from backend if needed we use it
  // const getOption = (attribute) => {
  //   const faData = data?.fa;
  //   if (faData && attribute.filn === "city") {
  //     return cityData.map((city) => ({ value: city.label, label: city.label }));
  //   } else {
  //     return [];
  //   }
  // };

  const columnsWithCustomRender = gridData?.data?.d?.s[0]?.columnData.map(
    (column) => {
      if (column.name === "action") {
        return {
          ...column,
          options: {
            ...column.options,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <>
                  <div className="centerStyles">
                    <i
                      style={{ marginRight: "0px" }}
                      onClick={() => view(value, tableMeta)}
                      className="fas fa-eye"
                    ></i>
                  </div>
                </>
              );
            },
          },
        };
      }
      return column;
    }
  );

  const onFilterChange = (event, type) => {
    //;
    if (type === "MRORLEGENTY") {
      setLegalEntity(event);
    }

    if (type === "MRORLOC") {
      setMrfLocation(event);
    }
    if (type === "BIS") {
      setBussiness(event);
    }
  };

  const onSearchFilter = (event, type) => {
    if (type === "Search Job") {
      setSearchText(event.target.value);
    }
  };

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-center mb-3 mt-3">
        {/* ---------------------commented as not required in Anonymous Candidate dashboard page--------------------- */}
        {/* <button
          onClick={() => {
            navigate("/agencycandidate", { state: { TId: 0 } }); 
          }}
          className="btn btn-primary mt-3">
          <i className="fa fa-plus mr-2"></i>
          Add Agency Candidate Details
        </button> */}

        {/* <div className="text-right my-1">
          {state?.IJP == true && (
            <button
              onClick={() => {
                let url = require("./IJP Guidelines.pdf");
                const a = document.createElement("a");
                a.href = url;
                a.download = "WeRise Guidelines.pdf";

                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

                URL.revokeObjectURL(url);
              }}
              disabled={false}
              className="btn btn-filter-submit float-right ml-2"
            >
              <strong>WeRise Guideline and FAQ</strong>
            </button>
          )}
        </div> */}
        {/* ----------------------------------------------------------------------------------------------------- */}

        <div className="d-flex justify-content-center" style={{ width: '90%' }}>
          {state?.IJP == true && (
            <>
              <div className="" style={{ display: "flex" }}>
                <div className="">
                  <h5 style={{ paddingRight: "5px", marginTop: "6px" }}>
                    Please click here to read
                  </h5>
                </div>
                <div className="">
                  <div>
                    <i
                      className="fa-solid fa-right-long"
                      style={{ fontSize: "40px", color: "#00ABE6" }}
                    />
                  </div>
                </div>
                <div className="">
                  <button
                    className="btn btn-filter-submit float-right ml-2"
                    style={{
                      background: "#3C5464",
                      border: "#3C5464",
                      padding: "10px",
                    }}
                    onClick={() => {
                      // let url = require("./IJP Guidelines.pdf");
                      // const a = document.createElement("a");
                      // a.href = url;
                      // a.download = "WeRise Guidelines.pdf";

                      // document.body.appendChild(a);
                      // a.click();
                      // document.body.removeChild(a);

                      // URL.revokeObjectURL(url);
                      setModelOpen(true);
                    }}
                    disabled={false}
                  >
                    <h6 style={{ marginBottom: "2px" }}>
                      WeRise Guidelines and FAQ
                    </h6>
                  </button>
                </div>
              </div>

              {/* <div
              className="text-right m-1 mt-3"
              style={{
                display: "flex",
                // justifyContent: "space-between",
                // width: "100vw",
                alignContent: "center",
              }}
            >
              <div className="d-flex align-items-center"></div>
              <div className="d-flex align-items-center"></div>
            </div> */}
            </>
          )}
        </div>
        <div className="d-flex justify-content-end" style={{ width: '10%' }}>
          <button
            style={{
              background: "#3C5464",
              border: "#3C5464",
              padding: "10px",
            }}
            className="btn btn-filter-submit ml-2"
            onClick={() => navigate('/home')}
          >
            <i className="fa-solid fa-arrow-left mr-1"></i> Back
          </button>
        </div>
      </div>
      <div className="grid-wrapper grid_mob">
        <div className="row mx-auto filtBox">
          {gridData &&
            gridData?.data?.d?.fa.map((cFilter, index) => (
              <React.Fragment key={index}>
                {cFilter.filt === "multidropdown" ? (
                  <>
                    <div className="col-lg-3 col-md-4 col-sm-12">
                      <div className="form-group filtInp">
                        <span
                          className="mr-2 text-white"
                          style={{ float: "left" }}
                        >
                          <label className="col-form-label">
                            {cFilter.filn}
                          </label>
                        </span>
                        <SelectForm
                          isClearable
                          options={ddValue[cFilter.filAN]}
                          placeholder={cFilter.filn}
                          isDisabled={false}
                          onChange={(event) => {
                            onFilterChange(event, cFilter.filAN);
                          }}
                          value={
                            cFilter.filAN === "MRORLEGENTY"
                              ? legalEntity
                              : cFilter.filAN === "MRORLOC"
                              ? mrfLocation
                              : cFilter.filAN === "BIS"
                              ? Bussiness
                              : null
                          }
                          isMulti={true}
                          noIndicator={false}
                          noSeparator={false}
                        />
                      </div>
                    </div>
                  </>
                ) : cFilter.filt === "text" ? (
                  <div className="col-lg-3 col-md-3 col-sm-12">
                    <div className="form-group filtInp">
                      <InputForm
                        value={cFilter.filn === "Search Job" ? searchText : ""}
                        placeholder={cFilter.filn}
                        isDisabled={false}
                        textArea={false}
                        onChange={(e) => {
                          onSearchFilter(e, cFilter.filn);
                        }}
                      />
                    </div>
                  </div>
                ) : null}
              </React.Fragment>
            ))}
          <div className="col-lg-1 col-md-1 col-sm-12 filtrt">
            <ul className=" filter-icon-wel">
              <li>
                <span
                  style={{ cursor: "pointer" }}
                  // href=""
                  onClick={() => setOpen(!open)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                  className="filter-btn"
                >
                  <i className="fa fa-filter"></i>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="col-lg-12 col-md-10 col-sm-12 "></div>

      <>
        <Collapse in={open}>
          <div id="example-collapse-text grid-wrapper">
            <div className="row mx-auto myInlineStyle">
              {gridData &&
                gridData?.data?.d?.afa?.map((cFilter, index) => (
                  <>
                    {cFilter.filt === "multidropdown" ? (
                      <>
                        <div className="col-lg-3 col-md-4 col-sm-12">
                          <div className="form-group filtInp">
                            <span
                              className="mr-2 text-white"
                              style={{ float: "left" }}
                            >
                              <label className="col-form-label">
                                {cFilter.filn}
                              </label>
                            </span>
                            <SelectForm
                              isClearable
                              options={ddValue[cFilter.filAN]}
                              placeholder={cFilter.filn}
                              isDisabled={false}
                              onChange={(event) => {
                                onFilterChange(event, cFilter.filAN);
                              }}
                              isMulti={true}
                              noIndicator={false}
                              noSeparator={false}
                              value={
                                cFilter.filAN === "MRORLEGENTY"
                                  ? legalEntity
                                  : cFilter.filAN === "MRORLOC"
                                  ? mrfLocation
                                  : cFilter.filAN === "BIS"
                                  ? Bussiness
                                  : null
                              }
                            />
                          </div>
                        </div>
                      </>
                    ) : cFilter.filt === "text" ? (
                      <div className="col-lg-3 col-md-3 col-sm-12">
                        <div className="form-group filtInp">
                          <InputForm
                            value={
                              cFilter.filn === "Search Job" ? searchText : ""
                            }
                            placeholder={cFilter.filn}
                            isDisabled={false}
                            textArea={false}
                            onChange={(e) => {
                              onSearchFilter(e, cFilter.filn);
                            }}
                          />
                        </div>
                      </div>
                    ) : null}
                  </>
                ))}
              <div className="col-lg-12 d-flex mt-2 justify-content-end">
                <button
                  className="btn btn-secondary mr-2"
                  onClick={onClickReset}
                >
                  <i className="fa fa-undo" aria-hidden="true"></i> Reset
                </button>
                <button
                  className="btn btn-filter-submit"
                  onClick={onSubmitFilter}
                >
                  <i className="fa fa-check" aria-hidden="true"></i> Submit{" "}
                </button>
              </div>
            </div>
          </div>
        </Collapse>
      </>

      <div className="mb-3 pt-3">
        <DynamicGrid
          data={rowData}
          columns={columnsWithCustomRender}
          options={options}
        />
      </div>

      <Modal
        show={model}
        onHide={() => setModelOpen(false)}
        backdrop="static"
        keyboard={false}
        size="xl"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">WeRise Guidelines and FAQ</div>
        </div>
        <Modal.Body>
          <div
            className=""
            style={{
              height: "60vh",
              overflowX: "hidden",
            }}
          >
            <Document
              className="img-fluid invoice-img"
              file={pdfURL}
              onLoadSuccess={({ numPages }) => {
                setNumberOfPages(numPages);
              }}
            >
              <Page
                size="A4"
                pageNumber={pageNumber}
                renderMode="svg"
                scale={1.5}
              />
              {Array.from(new Array(numberOfPages), (el, index) => (
                <Page
                  scale={1.5}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                />
              ))}
            </Document>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{
              marginLeft: 5,
              color: "black !important",
              borderColor: "#ACACAC",
            }}
            onClick={() => {
              setModelOpen(false);
              let url = require("./IJP Guidelines.pdf");
              const a = document.createElement("a");
              a.href = url;
              a.download = "WeRise Guidelines.pdf";
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              URL.revokeObjectURL(url);
            }}
          >
            <i className="fa-solid fa-download mr-2"></i>
            Download
          </button>
          <button
            className="btn"
            style={{
              marginLeft: 5,
              color: "black !important",
              borderColor: "#acacac",
            }}
            onClick={() => {
              setModelOpen(false);
            }}
          >
            Ok
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const PDFViewer = () => {
  const pdfURL = require("./IJP Guidelines.pdf");

  const [numberOfPages, setNumberOfPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  return (
    <div>
      <>
        <Document
          className="img-fluid invoice-img"
          file={pdfURL}
          onLoadSuccess={({ numPages }) => {
            setNumberOfPages(numPages);
          }}
        >
          <Page
            size="A4"
            pageNumber={pageNumber}
            renderMode="svg"
            scale={1.5}
          />
          {Array.from(new Array(numberOfPages), (el, index) => (
            <Page
              scale={1.5}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
            />
          ))}
        </Document>
      </>
    </div>
  );
};

export default AnonymousCandidateDashboard;
