import React, { useContext, useEffect, useState } from "react";
import { LoaderContext } from "../../Helpers/Context/Context";
import {
  mediBuddyApiCallAfterRevoke,
  updateRelationAndManagerData,
  approverChangeDataForSeparation,
  InsertAuditLog,
  getUsermasterIdBasedOnEid,
  entryUpdateInActionHistory,
  updatePosIncmMapOnRevoke,
  updateEventAndReasonInWorkline,
  changeIsRevokedStatus,
  updateSyncStatusOfRevoke,
  revokeProgressionAfterApproved,
  submitProgressionRevoke,
  getProgressionDataBasedOnId,
  getReporteesForEmployee,
  getRelationsForEmployee,
  getEmpRlBasedOnId,
  getEmpRlMapForEmployee,
  getRepMgMapForEmployee,
  getAllRoles,
  getDropdowns,
  getProgressionById,
  getApproverAndProgressionDetailsByWorkflowTaskId,
  handleApprovalForProgressionModel,
  getWorkflowData,
  getAttrForProgression,
  getDdForProgression,
  getSearchDdForProgression,
  getSectionDetailsV2,
  getAttributeValueDetailsV2,
  getGlobalValues,
  getApproverAndProgressionDetailsByWorkflowTaskIdV2,
  downloadSupportingDocForProgression,
  getDocuments,
} from "../../Helpers/APIEndPoints/EndPoints";
import { APICall } from "../../Helpers/APICalls";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import notify from "../../Helpers/ToastNotification";
import moment from "moment";
import { ExpandMore } from "@mui/icons-material";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import uuid from "react-uuid";
import SelectForm from "../../Components/SelectForm/SelectForm";
import InputForm from "../../Components/InputForm/InputForm";
import DateForm from "../../Components/DateForm/DateForm";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { resolveNs } from "dns";
import async from "react-select/dist/declarations/src/async/index";
import { createAwait } from "typescript";
import SectionCompontentProgressionView from "../Progression/SectionCompontentProgressionView";
import ChangeLog from "../Progression/ChangeLog";
import PreviousConversation from "../Progression/PreviousConversation";
import "../Progression/Request.css";
import useDocumentStore from "../../Helpers/ZustandStore/DocumentForProgressionStore";

const ReviewerSectionClm = () =>
  //{ TId, setTId,}
  {
    const { state } = useLocation();
    const [accordion, setAccordion] = useState(true);
    const [formErrors, setFormErrors] = useState<any>({});
    const [optionsObj, setOptionsObj] = useState<any>({});

    // value Object for section
    const [attributesData, setAttributesData] = useState<any>({});
    const [progAttributesData, setProgAttributesData] = useState<any>({});
    const [accordionReqDet, setAccordionReqDet] = useState(false);
    const [accordionWrkfl, setAccordionWrkfl] = useState(false);
    let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
    const [approvers, setApprovers] = useState<any>([]);
    const [progId, setProgId] = useState(0); //Current Progression Id
    const [isOnce, setIsOnce] = useState(true);
    const [isValidateOnce, setIsValidateOnce] = useState(true);
    const [attributeValidation, setAttributeValidation] = useState<any>({});
    const [dataFilled, setDataFilled] = useState(false);
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const [decisionOptionsArr, setDecisionOptionsArr] = useState([]);
    const [workflowTaskId, setWorkflowTaskId] = useState(state ? state : 0);
    const [dynamicAccordian, setDynamicAccordian] = useState(null);
    const [accordianEvent, setAccordianEvent] = useState(false);
    const [accordianChangeLog, setAccordianChangeLog] = useState(false);
    const [sectionTabHide, setSectionTabHide] = useState(true);
    const [disableSubmitBtn, setDisabledSubmitBtn] = useState(false);
    const [initId, setInitId] = useState(0);

    const [finalformData, setFinalFormData] = useState([]);
    const [TId, setTId] = useState(0);
    const [sectionValuesArray, setSectionValuesArray] = useState([]);
    const [allSectionsData, setAllSectionsData] = useState({});
    const [formData, setFormData] = useState<any>({});
    const [globalValues, setGlobalValues] = useState([]);
    const [initiatorUserId, setInitiatorUserId] = useState(0);
    const [initiatorRoleId, setInitiatorRoleId] = useState(0);

    const [approverSectionsData, setApproverSectionsData] = useState<any>([]);

    const [optForRoles, setOptForRoles] = useState<any>([]);
    const [waveOffReasonDisable, setWaveOffReasonDisable] = useState(false);
    
    const [skillcategoryHide, setSkillCategoryHide] = useState(true);

    const { documents, insertDocument, updateDocument }: any =
      useDocumentStore();

    const navigate = useNavigate();

    const [preConvJson, SetPreconvjson] = useState<any>(null);
    const [selectedFilesForFlatProg, setSelectedFilesForFlatProg] = useState([]);

    const getProgDocuments = async () => {
      const progDocuments = await APICall(getDocuments, "POST", {
        isProgressionFlat : true,
        ProgressionId : progId
      });
      if(progDocuments.status === 0){
        console.log('setSelectedFilesForFlat', progDocuments);
        setSelectedFilesForFlatProg(progDocuments?.data?.files);
      }
    }
  
    useEffect(() => {
      getProgDocuments();
    }, [progId])

    //CALL A USE-EFFECT TO GET DATA FROM DATABASE
    useEffect(() => {
      setAttributesData((prev) => ({
        ["PROGRVWR"]: "",
        ["PROGROLE"]: "",
        ["PROGEFFDT"]: null,
        ["PROGDEC"]: null,
        ["PROGRMKS"]: "",
        ["PROGACK"]: false,
        ["PROGPRCONVO"]: "",
      }));
      setProgAttributesData((prev) => ({
        ["REQCNTRY"]: null,
        ["REQTYPOUPD"]: null,
        ["REQRSNOUP"]: null,
        ["REQEMPLID"]: null,
        ["REQEMPLNM"]: "",
        ["REQEFCTVDT"]: null,
        ["REQSUPDOC"]: false,
        ["REQDREMK"]: "",
        ["WRKFLNM"]: "",
      }));
    }, []);

    useEffect(() => {
      (async () => {
        const forRoles = await APICall(getAllRoles, "POST", {});
        setOptForRoles(forRoles.data);
        await Promise.all([
          handleFetchProgressionData(),
          //handleFetchProgressionDataV2(),
        ]);
      })();
    }, []);

    //section api here
    useEffect(() => {
      // Extract attributes for better readability
      const { REQTABS } = attributesData;

      // Check if both attributes are available
      if (TId && REQTABS != null) {
        // Initialize values array
        setSectionValuesArray([]);

        // Fetch section data
        (async () => {
          await showLoader();
          try {
            const section = await APICall(getSectionDetailsV2, "POST", {
              MN: "EmployeeCentral",
              IN: "EC_Form",
              TN: REQTABS.code,
              UID: initiatorUserId,
              TID: TId,
              RoleId: initiatorRoleId,
              COU: userDetails.COU,
              IsNewHire: true,
            });

            if (
              section.data !== null &&
              section.data.t !== undefined &&
              section.data.t.tn === REQTABS.code
            ) {
              setFormData(section.data.t);
            } else {
              setFormData({});
            }
          } finally {
            hideLoader();
          }
        })();

        // Fetch section values array
        (async () => {
          await showLoader();
          try {
            const bodyObj = {
              mn: "EmployeeCentral",
              in: "EC_Form",
              tn: REQTABS.code,
              SN: "Basic",
              TID: TId,
              ISH: "true",
              UserId: initiatorUserId,
              RoleId: initiatorRoleId,
              COU: userDetails.COU,
            };

            const response = await APICall(
              getAttributeValueDetailsV2,
              "POST",
              bodyObj
            );

            if (response.data.sections && response.data.sections.length > 0) {
              setSectionValuesArray(response.data.sections);
            } else {
              setSectionValuesArray([]);
            }
          } finally {
            hideLoader();
          }
        })();
      }

      // Fetch global values if REQEMPLID is available
      if (TId != null) {
        (async () => {
          await showLoader();
          try {
            const globalValues = await APICall(getGlobalValues, "POST", {
              TID: TId,
            });

            if (globalValues.data !== null && globalValues.data.length > 0) {
              setGlobalValues(globalValues.data);
            }
          } finally {
            hideLoader();
          }
        })();
      }
    }, [TId, attributesData["REQTABS"]]);

    useEffect(() => {
      if (
        formData &&
        allSectionsData &&
        progAttributesData?.["REQTYPOUPD"]?.code == "DataChange"
      ) {
        (async () => {
          await showLoader();

          let setFinalSections = [];
          let sectArr = [];
          attributesData["REQSECT"]?.map((eachSect) => {
            sectArr.push(eachSect?.value);
          });

          sectArr.map((eachId) => {
            const settingVal = formData?.S?.find((item) => item?.SId == eachId);
            setFinalSections.push(settingVal);
          });

          if (finalformData != null || finalformData != undefined) {
            setFinalFormData(setFinalSections);
          }
          await hideLoader();
        })();
      }
    }, [formData, allSectionsData]);

    useEffect(() => {}, [finalformData]);

    const getDdData = async (AC, PID, EmployeeId = 0, CountryId = 564) => {
      try {
        showLoader();
        const GenId = currentRoleId?.value; //Role Id of the User
        const dropdownData = await APICall(getDdForProgression, "POST", {
          AC,
          PID,
          UserId: proxyUserDetails?.value
        ? proxyUserDetails?.value
        : userDetails?.Id,
          GenId: EmployeeId != 0 ? EmployeeId : GenId,
          CountryId,
        });

        if (
          dropdownData.data !== null &&
          dropdownData.data !== undefined &&
          dropdownData.data.length > 0
        ) {
          await hideLoader();
          return dropdownData.data;
        } else {
          return [];
        }
      } catch (error) {
      } finally {
        hideLoader();
      }
    };

    const [mapTypeVal, setMapTypeVal] = useState(0);
    const [repMgMap, setRepMgMap] = useState<any>([]);
    const [reportees, setReportees] = useState<any>([]);
    const [directReportee, setDirectReportee] = useState<any>(null);
    const [managerForReportee, setManagerForReportee] = useState<any>(null);
    const [reporteeManagerMap, setReporteeManagerMap] = useState<any>([]);
    const [managerDisable, setManagerDisable] = useState(false);

    const [rlMapTypeVal, setRlMapTypeVal] = useState(0);
    const [relReportees, setRelReportees] = useState<any>([]);
    const [empRlMap, setEmpRlMap] = useState<any>([]);
    const [directReporteeRel, setDirectReporteeRel] = useState<any>(null);
    const [relationForReportee, setRelationForReportee] = useState<any>(null);
    const [relationDisable, setRelationDisable] = useState(false);
    const [userRelationMap, setUserRelationMap] = useState<any>([]);
    const [progIdForRevoke, setProgIdForRevoke] = useState(0); //Progression Id for Revoke
    const [progressionDetails, setProgressionDetails] = useState<any>(null);
    const [newJsonForProgression, setNewJsonForProgression] = useState<any>(null);
    const [approverRoleCode, setApproverRoleCode] = useState(null);

    const typeOptions = [
      { value: 1, label: "Transfer all to upper position.", code: "TATUP" },
      { value: 2, label: "Transfer all to other manager.", code: "TATOM" },
      {
        value: 3,
        label: "Individual transfer to other manager.",
        code: "ITTOM",
      },
    ];
    const typeOptionsRel = [
      { value: 1, label: "Transfer all to upper position.", code: "RLTATUP" },
      { value: 2, label: "Transfer all to other manager.", code: "RLTATOM" },
      {
        value: 3,
        label: "Individual transfer to other manager.",
        code: "RLITTOM",
      },
      { value: 4, label: "Terminate all Relations.", code: "RLTAR" },
    ];

    const parseJSON = (jsonString) => {
      try {
        return JSON.parse(jsonString);
      } catch (error) {
        return null;
      }
    };

    //Ye wala item niche table mei dalna hai
    const parsePreviousConversation = (previousConversation) => {
      let conversation = "";
      const remarksArray = parseJSON(previousConversation);

      if (remarksArray) {
        remarksArray.map((item) => {
          const Date = item.ModifiedDateUtc
            ? moment.utc(item.ModifiedDateUtc).local().format("DD-MMM-YYYY")
            : "";
          const ApproverName = item.UserName || "";
          const RoleName = item.RoleName || "";
          const Remarks = item.Remarks || "";

          if (Remarks?.trim().length > 0) {
            conversation += `${Date} ${ApproverName} ${RoleName}\n`;
            conversation += `\t\t${Remarks}\n\n`;
          }
        });
      }
      return conversation;
    };

    const handleFetchProgressionData = async () => {
      try {
        showLoader();

        const [response] = await Promise.all([
          // getDdData("REQTYPOUPD", ""),
          // getDdData("REQTABS", ""),
          APICall(getApproverAndProgressionDetailsByWorkflowTaskId, "POST", {
            Id: workflowTaskId,
          }),
        ]);

        const responseData = response?.data;
        if (response?.status === 0 && responseData) {
          console.log("Approver And Progression Data", responseData);

          //add supporting doc
          if (
            responseData.supportingDoc !== "" &&
            responseData.supportingDoc !== null
          ) {
            let supportingDoc = JSON.parse(responseData.supportingDoc);

            supportingDoc = supportingDoc.map((doc) => ({
              ...doc,
              IsValidFile: true,
            }));

            setSelectedFilesForFlat(supportingDoc);
          }

          //add section doc for data change
          if (
            responseData.sectionDoc !== "" &&
            responseData.sectionDoc !== null
          ) {
            let sectionDoc = JSON.parse(responseData.sectionDoc);
            sectionDoc = sectionDoc.map((doc) =>
              doc.IsActive ? { ...doc, IsValidFile: true } : doc
            );
            updateDocument(sectionDoc);
          } else {
            updateDocument([]);
          }

          const NewJson = parseJSON(responseData?.RequestNewJson) || [];
          setNewJsonForProgression(NewJson);

          const additionalDetails =
            parseJSON(responseData.AdditionalDetails) || [];

          setDecisionOptionsArr(additionalDetails);

          const isStatus2 = responseData.WorkflowTaskStatusId === 2;

          const decision =
            (isStatus2 &&
              additionalDetails.find(
                (i) =>
                  String(i?.label).trim().toLowerCase() ===
                  String(responseData.Outcome).toLowerCase().trim()
              )) ||
            null;

          if (responseData?.ProgressionId > 0) {
            setProgId(responseData?.ProgressionId || 0);
          }
          if (responseData?.ProgIdForRevoke!=null && responseData?.ProgIdForRevoke>0) {
            setProgIdForRevoke(responseData?.ProgIdForRevoke || 0);
          }

          const previousConversation = parsePreviousConversation(
            responseData.PreviousConversation
          );

          SetPreconvjson(responseData.PreviousConversation);

          setInitiatorUserId(responseData.InitiatorId);
          setInitiatorRoleId(responseData.InitiatorRoleId);

          const approverObject = {
            ApproverName: responseData.ApproverName || "",
            RoleName: responseData.ApproverRoleName || "",
            RoleCode: responseData.ApproverRoleCode || "",
            Date:
              responseData.modifiedDateUtc || moment().format("DD-MMM-YYYY"),
            EmployeeId: responseData.EmployeeId,
            IsAcknowledged: responseData.IsAcknowledged,
            IsAcknolwdgedRequired: responseData.IsAcknolwdgedRequired || false,
            Outcome: responseData.Outcome || "",
            PreviousConversation: previousConversation,
            Decision: decision,
            Remarks: responseData.WorkflowTasksRemarks || "",
            WorkflowTaskStatusId: responseData.WorkflowTaskStatusId || 0,
          };

          const isDisabled = approverObject.WorkflowTaskStatusId === 2;

          const [
            ProgressionCountryOptions,
            ProgressionPayrollOptions,
            WorkflowTypeOptions,
            WorkflowSubTypeOptions,
          ] = await Promise.all([
            getDdData(
              "REQCNTRY",
              "",
              responseData.InitiatorRoleId,
              responseData.ProgressionCountryId
            ),
            getDdData(
              "REQPLOCN",
              "",
              responseData.InitiatorRoleId,
              responseData.ProgressionCountryId
            ),
            getDdData(
              "REQTYPOUPD",
              "",
              responseData.InitiatorRoleId,
              responseData.ProgressionCountryId
            ),
            getDdData(
              "REQRSNOUP",
              "",
              responseData.InitiatorRoleId,
              responseData.ProgressionCountryId
            ),
          ]);

          let filterWorkflowTypeOption = WorkflowTypeOptions?.find(
            (option) => option?.value == responseData.WorkflowTypeId
          );

          filterWorkflowTypeOption = filterWorkflowTypeOption
            ? filterWorkflowTypeOption
            : {
                value: responseData.WorkflowTypeId,
                label: responseData.WorkflowTypeName,
                code: responseData.WorkflowTypeCode,
              };

          let filterWorkflowSubTypeOption = WorkflowSubTypeOptions?.find(
            (option) => option?.value == responseData.WorkflowSubTypeId
          );

          filterWorkflowSubTypeOption = filterWorkflowSubTypeOption
            ? filterWorkflowSubTypeOption
            : {
                value: responseData.WorkflowSubTypeId,
                label: responseData.WorkflowSubTypeName,
                code: responseData.WorkflowSubTypeCode,
              };

          let filterCountryOption = ProgressionCountryOptions?.find(
            (option) => option?.value == responseData.ProgressionCountryId
          );

          filterCountryOption = filterCountryOption
            ? filterCountryOption
            : {
                value: responseData.ProgressionCountryId,
                label: responseData.ProgressionCountryName,
                code: responseData.ProgressionCountryCode,
              };

          let filterPayrollOption = ProgressionPayrollOptions?.find(
            (option) =>
              option?.value == responseData.ProgressionPayrollLocationId
          );

          filterPayrollOption = filterPayrollOption
            ? filterPayrollOption
            : {
                value: responseData.ProgressionPayrollLocationId,
                label: responseData.ProgressionPayrollLocationName,
                code: responseData.ProgressionPayrollLocationCode,
              };

          const setAttributes = {
            PROGRVWR: approverObject.ApproverName,
            PROGROLE: approverObject.RoleName,
            PROGEFFDT: approverObject.Date,
            PROGDEC: approverObject.Decision,
            PROGRMKS: isDisabled ? approverObject.Remarks : '',
            PROGACK: approverObject.IsAcknowledged,
            PROGPRCONVO: approverObject.PreviousConversation,
            EmployeeId: approverObject.EmployeeId,
            IsAcknolwdgedRequired: approverObject.IsAcknolwdgedRequired,
            IsDisabled: isDisabled,
          };
          setApproverRoleCode(approverObject.RoleCode);
          
          //for Transfer Event
          const newLabel = responseData.reasonNewLabel;
          if (filterWorkflowSubTypeOption?.code == "Transfer" && newLabel){
            filterWorkflowSubTypeOption.label = newLabel;
          }
          //------------------

          setDisabledSubmitBtn(isDisabled);
          const progressionId = responseData.ProgressionId;
          setProgId(progressionId);

          const setProgAttributes = {
            REQCNTRY:
              responseData.ProgressionCountryId != null &&
              responseData.ProgressionCountryName != null
                ? filterCountryOption
                : null,

            REQPLOCN:
              responseData.ProgressionPayrollLocationId != null &&
              responseData.ProgressionPayrollLocationName != null
                ? filterPayrollOption
                : null,

            REQTYPOUPD:
              responseData.WorkflowTypeId != null &&
              responseData.WorkflowTypeName != null
                ? filterWorkflowTypeOption
                : null,
            REQRSNOUP:
              responseData.WorkflowSubTypeId != null &&
              responseData.WorkflowSubTypeName != null
                ? filterWorkflowSubTypeOption
                : null,
            REQEMPLID:
              responseData.EmployeeId != null &&
              responseData.EmployeeName != null
                ? {
                    value: responseData.EmployeeId,
                    label: responseData.EmployeeName,
                  }
                : null,
            REQEMPLNM: responseData.EmployeeName,
            REQEFCTVDT: responseData.EffectiveDate
              ? moment(responseData.EffectiveDate).format("DD-MMM-YYYY")
              : null,
            REQSUPDOC: false,
            REQDREMK: responseData.ProgressionRemarks || "",
            WRKFLNM: responseData.InitiatorName || "",
          };
          setTId(responseData.EmployeeId || 0);
          setAttributesData(setAttributes);
          setProgAttributesData(setProgAttributes);

          const WorkflowSectionId = responseData.WorkflowSectionId;
          if (
            responseData.WorkflowTypeId &&
            responseData.EmployeeId &&
            responseData.WorkflowSubTypeId
          ) {
            if (
              filterWorkflowTypeOption &&
              filterWorkflowSubTypeOption &&
              filterWorkflowTypeOption?.code == "DataChange" &&
              filterWorkflowSubTypeOption?.code == "DTACHG"
            ) {
              setSectionTabHide(false);

              let result = {};
              NewJson.forEach((item) => {
                const sectionId = item.sectionId;
                const code = item.Code;
                const attribute = item.Main[0];

                if (!result[sectionId]) {
                  result[sectionId] = {};
                }

                if (item.IsAttributeSet) {
                  if (!result[sectionId][code]) {
                    result[sectionId][code] = [];
                  }
                  result[sectionId][code].push(attribute);
                } else {
                  result[sectionId][code] = attribute[code];
                }
              });
              setAllSectionsData(result);

              let tabSectionJson = JSON.parse(
                responseData?.TabSectionMappingJson
              );

              setAttributesData((prev) => {
                return {
                  ...prev,
                  ["REQTABS"]: tabSectionJson.tab || null,
                  ["REQSECT"]: tabSectionJson.section || null,
                };
              });

              const workflowResponse = await APICall(getWorkflowData, "POST", {
                WorkflowTypeId: responseData.WorkflowTypeId,
                WorkflowSubTypeId: responseData.WorkflowSubTypeId,
                EmployeeId: responseData.EmployeeId,
                InitiatorId: responseData.InitiatorId,
                InitiatorRoleId: responseData.InitiatorRoleId,
                CountryId: responseData.CountryId ? responseData.CountryId : null,
                PayrollLocationId: responseData.PayrollLocationId ? responseData.PayrollLocationId : null,
                SectionId: responseData.WorkflowSectionId ? responseData.WorkflowSectionId : null
              });

              if (workflowResponse.status == 0 && workflowResponse.data) {
                setApprovers(workflowResponse.data);
              }
            } else {
              await handleFetchDynamic({
                WorkflowTypeId: responseData.WorkflowTypeId,
                EmployeeId: responseData.EmployeeId,
                WorkflowSubTypeId: responseData.WorkflowSubTypeId,
                COU: responseData?.EmployeeDetails?.cou || 0,
                WorkflowTypeName: responseData.WorkflowTypeName,
                NewJson,
                InitId: responseData.InitiatorId,
                RolId: responseData.InitiatorRoleId,
                WorkflowTypeCode: responseData.WorkflowTypeCode,
                CountryId: responseData.CountryId,
                PayrollLocationId: responseData.PayrollLocationId,
                CountryCode: responseData.ProgressionCountryCode,
                WorkflowSectionId: responseData.WorkflowSectionId,
              });

              // const progressionId = responseData.ProgressionId;
              // if (progressionId > 0) {
              //   setProgId(progressionId || 0);
              // }

              if (
                responseData.WorkflowTypeCode == "Separation" ||
                responseData.WorkflowTypeCode == "Termination" ||
                responseData.WorkflowTypeCode == "ResignationWithdraw"
              ) {
                const RepMgData = await APICall(
                  getRepMgMapForEmployee,
                  "POST",
                  {
                    progressionId,
                  }
                );

                setMapTypeVal(RepMgData.data.id);
                setRepMgMap(parseJSON(RepMgData.data.value));

                const EmpRlData = await APICall(
                  getEmpRlMapForEmployee,
                  "POST",
                  {
                    progressionId,
                  }
                );

                setRlMapTypeVal(EmpRlData.data.id);
                setEmpRlMap(parseJSON(EmpRlData.data.value));
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        hideLoader();
      }
    };

    useEffect(() => {
      if (mapTypeVal != null && repMgMap != null && repMgMap.length > 0) {
        (async () => {
          setDirectReportee(
            typeOptions.find((item) => item.value == mapTypeVal)
          );
          if (mapTypeVal == 1 || mapTypeVal == 2) {
            const mgId = await getSearchableDropdownAPI(
              "",
              "MG",
              null,
              repMgMap[0].ManagerId,
              "attribute",
              0
            );
            setManagerForReportee(mgId);

            let repMg = [];
            repMgMap.map(async (eachRep) => {
              let Reportee = await getSearchableDropdownAPI(
                "",
                "MG",
                null,
                eachRep.EmpId,
                "attribute",
                0
              );
              Reportee.mg = mgId;
              repMg.push(Reportee);
            });
            setReporteeManagerMap(repMg);
          }
          if (mapTypeVal == 3) {
            let repMg = [];
            repMgMap.map(async (eachRep) => {
              let Reportee = await getSearchableDropdownAPI(
                "",
                "MG",
                null,
                eachRep.EmpId,
                "attribute",
                0
              );
              const Manager = await getSearchableDropdownAPI(
                "",
                "MG",
                null,
                eachRep.ManagerId,
                "attribute",
                0
              );
              Reportee.mg = Manager;
              repMg.push(Reportee);
            });
            setReporteeManagerMap(repMg);
          }
          if(mapTypeVal == 1 || mapTypeVal == 3) {setManagerDisable(true);}
          else {setManagerDisable(false);}
        })();
      }
    }, [mapTypeVal, repMgMap]);

    useEffect(() => {
      if (rlMapTypeVal != null && empRlMap != null && empRlMap.length > 0) {
        (async () => {
          setDirectReporteeRel(
            typeOptionsRel.find((item) => item.value == rlMapTypeVal)
          );
          if (rlMapTypeVal == 1 || rlMapTypeVal == 2) {
            const relId = await getSearchableDropdownAPI(
              "",
              "RELNME", //Change this with "RELNME" once kiran sir's update is done
              null,
              empRlMap[0].RelationId,
              "attribute",
              0
            );
            setRelationForReportee(relId);

            let empRl = [];
            empRlMap.map(async (eachRep) => {
              // Send Id and Get Details
              const RelData = await APICall(getEmpRlBasedOnId, "POST", {
                Id: eachRep.Id,
              });

              const userid = await getSearchableDropdownAPI(
                "",
                "MG",
                null,
                RelData.data.userid,
                "attribute",
                0
              );
              const relationtype = optForRoles.find(
                (item) => item.value == RelData.data.relationtype
              );

              const RelDataNew = {
                id: eachRep.Id,
                userid: userid,
                relationtype: relationtype,
                relationid: relId,
              };
              empRl.push(RelDataNew);
            });
            setUserRelationMap(empRl);
          }
          if (rlMapTypeVal == 4) {
            setRelationForReportee(null);

            let empRl = [];
            empRlMap.map(async (eachRep) => {
              // Send Id and Get Details
              const RelData = await APICall(getEmpRlBasedOnId, "POST", {
                Id: eachRep.Id,
              });

              const userid = await getSearchableDropdownAPI(
                "",
                "MG",
                null,
                RelData.data.userid,
                "attribute",
                0
              );
              const relationtype = optForRoles.find(
                (item) => item.value == RelData.data.relationtype
              );

              const RelDataNew = {
                id: eachRep.Id,
                userid: userid,
                relationtype: relationtype,
                relationid: null,
              };
              empRl.push(RelDataNew);
            });
            setUserRelationMap(empRl);
          }
          if (rlMapTypeVal == 3) {
            let empRl = [];
            empRlMap.map(async (eachRep) => {
              // Send Id and Get Details
              const RelData = await APICall(getEmpRlBasedOnId, "POST", {
                Id: eachRep.Id,
              });

              const userid = await getSearchableDropdownAPI(
                "",
                "MG",
                null,
                RelData.data.userid,
                "attribute",
                0
              );
              const relationtype = optForRoles.find(
                (item) => item.value == RelData.data.relationtype
              );
              const relationid = await getSearchableDropdownAPI(
                "",
                "RELNME", //Change this with "RELNME" once kiran sir's update is done
                null,
                eachRep.RelationId,
                "attribute",
                0
              );

              const RelDataNew = {
                id: eachRep.Id,
                userid: userid,
                relationtype: relationtype,
                relationid: relationid,
              };
              empRl.push(RelDataNew);
            });
            setUserRelationMap(empRl);
          }
          if(rlMapTypeVal == 1 || rlMapTypeVal == 4 || rlMapTypeVal == 3) {setRelationDisable(true);}
          else {setRelationDisable(false);}
        })();
      }
    }, [rlMapTypeVal, empRlMap]);

    const getSearchableDropdownAPI = async (
      searchString,
      AC,
      cb,
      valueForDropdown,
      type,
      gridId
    ) => {
      if (
        searchString === "" &&
        cb === null &&
        valueForDropdown !== "" &&
        AC !== ""
      ) {
        const response = await APICall(getSearchDdForProgression, "POST", {
          searchString,
          AC,
          valueForDropdown,
        });

        if (response.data !== null && response.data.length > 0) {
          if (response.data.length === 1) {
            return response.data[0];
          }
        }
      } else if (searchString.length > 1) {
        const response = await APICall(getSearchDdForProgression, "POST", {
          searchString,
          AC,
        });
        if (response.data !== null && response.data.length > 0) {
          cb(response.data);
          setOptionsObj((prev) => ({ ...prev, ["REQEMPLID"]: response.data }));
        } else {
          cb([]);
          setOptionsObj((prev) => ({ ...prev, ["REQEMPLID"]: [] }));
        }
      }
    };

    const handleFetchDynamic = async ({
      WorkflowTypeId,
      WorkflowSubTypeId,
      EmployeeId,
      COU,
      WorkflowTypeName,
      NewJson,
      InitId,
      RolId,
      WorkflowTypeCode,
      CountryId,
      PayrollLocationId,
      CountryCode,
      WorkflowSectionId,
    }) => {
      try {
        showLoader();
        const workflowResponse = await APICall(getWorkflowData, "POST", {
          WorkflowTypeId,
          WorkflowSubTypeId,
          EmployeeId,
          InitiatorId: InitId,
          InitiatorRoleId: RolId,
          CountryId,
          PayrollLocationId,
          SectionId: WorkflowSectionId ? WorkflowSectionId : null
        });

        if (workflowResponse.status == 0 && workflowResponse.data) {
          setApprovers(workflowResponse.data);

          const response = await APICall(getAttrForProgression, "POST", {
            WorkFlowId: workflowResponse.data[0].workFlowId,
            COU,
            EmployeeId,
            userid: userDetails.Id,
            roleid: currentRoleId?.value,
            IsNewHire: true,
            IsProg: true,
            IsViewMode: true,
          });

          if (response?.status === 0 && response?.data) {
            let responseData = response?.data;

            if (responseData && responseData?.length > 0) {
              responseData.map(async (eachAttr) => {
                if (eachAttr.attributeType == "Attribute") {
                  if (eachAttr.attributeDatatype === "DropdownSingle") {
                    const resultObj = NewJson.find((obj) =>
                      obj.hasOwnProperty(eachAttr.attributeCode)
                    );

                    if (resultObj) {
                      const valForDD = eachAttr.options.find(
                        (item) =>
                          item.value === resultObj[eachAttr.attributeCode]
                      );
                      eachAttr.value = valForDD?.value || null;
                      eachAttr.valueOnDd = valForDD || null;

                      //Customization for Promotion (India)
                      if (
                        CountryCode == "CODECNTRY1" &&
                        WorkflowTypeCode == "Promotion" &&
                        eachAttr.attributeCode == "EC" &&
                        valForDD &&
                        valForDD?.code == "Worker"
                      ) {
                        setSkillCategoryHide(false);
                      } else if (
                        CountryCode == "CODECNTRY1" &&
                        WorkflowTypeCode == "Promotion" &&
                        eachAttr.attributeCode == "EC" &&
                        valForDD &&
                        valForDD?.code != "Worker"
                      )  {
                        setSkillCategoryHide(true);
                      }
                      //----------------------------------------------------------
                    } else {
                      const valForDD = eachAttr.options.find(
                        (item) => item.value === eachAttr.value
                      );
                      eachAttr.value = valForDD?.value || null;
                      eachAttr.valueOnDd = valForDD || null;

                      //Customization for Promotion (India)
                      if (
                        CountryCode == "CODECNTRY1" &&
                        WorkflowTypeCode == "Promotion" &&
                        eachAttr.attributeCode == "EC" &&
                        valForDD &&
                        valForDD?.code == "Worker"
                      ) {
                        setSkillCategoryHide(false);
                      } else if (
                        CountryCode == "CODECNTRY1" &&
                        WorkflowTypeCode == "Promotion" &&
                        eachAttr.attributeCode == "EC" &&
                        valForDD &&
                        valForDD?.code != "Worker"
                      )  {
                        setSkillCategoryHide(true);
                      }
                      //----------------------------------------------------------
                    }

                    // set extra attributes when demotion
                    if (WorkflowTypeCode == "Demotion") {
                      for (const attribute of NewJson) {
                        if (eachAttr.attributeCode === attribute.Code) {
                          eachAttr.valueOnDd =
                            attribute.Main[0][attribute.Code];
                        }
                      }
                    }
                  } else if (
                    ["Text", "Number"].includes(eachAttr.attributeDatatype)
                  ) {
                    const resultObj = NewJson.find((obj) =>
                      obj.hasOwnProperty(eachAttr.attributeCode)
                    );
                    eachAttr.value =
                      resultObj && 
                      resultObj[eachAttr.attributeCode] != null &&
                      resultObj[eachAttr.attributeCode] != undefined
                        ? resultObj[eachAttr.attributeCode]
                        : "";
                    eachAttr.valueOnDd =
                      eachAttr.value != null && eachAttr.value != undefined
                        ? eachAttr.value
                        : "";
                  } else if (eachAttr.attributeDatatype === "Date") {
                    const resultObj = NewJson.find((obj) =>
                      obj.hasOwnProperty(eachAttr.attributeCode)
                    );

                    const parsedDateNew = 
                      resultObj
                        ? moment(
                          resultObj[eachAttr.attributeCode],
                          "YYYY-MM-DD"
                        ) 
                        : null;

                    const formattedDateNew =
                      parsedDateNew 
                        ? parsedDateNew.format("DD-MMM-YYYY")
                        : null;
                    eachAttr.value = formattedDateNew || null;
                    eachAttr.valueOnDd = formattedDateNew || null;
                  } else if (
                    eachAttr.attributeDatatype === "SearchableDropdownSingle"
                  ) {
                    const resultObj = NewJson.find((obj) =>
                      obj.hasOwnProperty(eachAttr.attributeCode)
                    );

                    const obj = await getSearchableDropdownAPI(
                      "",
                      eachAttr.attributeCode,
                      null,
                      resultObj[eachAttr.attributeCode],
                      "attribute",
                      0
                    );

                    eachAttr.valueOnDd = obj;
                  }
                  eachAttr.AccordianName = WorkflowTypeName;
                } else if (eachAttr.attributeType == "AttributeSet") {
                  let tabcode = "";
                  if (eachAttr.attributeCode == "SA_CompensationSet") {
                    tabcode = "Employment";
                  } else if (
                    eachAttr.attributeCode == "SA_DisciplinaryActions"
                  ) {
                    tabcode = "Employment";
                  } else if (
                    eachAttr.attributeCode == "SA_OneTimePaymentDeductions"
                  ) {
                    tabcode = "Employment";
                  }
                  setSectionValuesArray([]); //imp

                  (async () => {
                    await showLoader();
                    const section = await APICall(getSectionDetailsV2, "POST", {
                      MN: "EmployeeCentral",
                      IN: "EC_Form",
                      TN: tabcode,
                      UID: InitId,
                      TID: parseInt(EmployeeId),
                      RoleId: RolId,
                      //COU:TId==0?COU:userDetails.COU,
                      COU: userDetails.COU,
                      IsNewHire: true,
                    });

                    if (
                      section.data !== null &&
                      section.data.t !== undefined &&
                      section.data.t.tn === tabcode
                    ) {
                      let setFinalSections = [];
                      if (eachAttr.attributeCode == "SA_CompensationSet") {
                        const sect = section.data.t.S?.find(
                          (item) => item.SN == "Compensation"
                        );
                        setFinalSections.push(sect);

                        setFinalFormData(setFinalSections);
                      } else if (
                        eachAttr.attributeCode == "SA_DisciplinaryActions"
                      ) {
                        const sect = section.data.t.S?.find(
                          (item) => item.SN == "DisciplinaryActions"
                        );
                        
                        setFinalSections.push(sect);
                        setFinalFormData(setFinalSections);
                      } else if (
                        eachAttr.attributeCode == "SA_OneTimePaymentDeductions"
                      ) {
                        const sect = section.data.t.S?.find(
                          (item) => item.SN == "OneTimePaymentDeductions"
                        );
                        
                        setFinalSections.push(sect);
                        setFinalFormData(setFinalSections);
                      }
                      //
                      let result = {};
                      NewJson.forEach((item) => {
                        const sectionId = item.sectionId;
                        const code = item.Code;
                        const attribute = item.Main[0];

                        if (!result[sectionId]) {
                          result[sectionId] = {};
                        }

                        if (item.IsAttributeSet) {
                          if (!result[sectionId][code]) {
                            result[sectionId][code] = [];
                          }
                          result[sectionId][code].push(attribute);
                        } else {
                          result[sectionId][code] = attribute[code];
                        }
                      });
                      setAllSectionsData(result);
                      //
                    } else {
                      setFinalFormData([]);
                    }
                    await hideLoader();
                  })();
                  (async () => {
                    showLoader();
                    let bodyObj = {
                      mn: "EmployeeCentral",
                      in: "EC_Form",
                      tn: tabcode,
                      SN: "Basic",
                      TID: parseInt(EmployeeId),
                      ISH: "true",
                      UserId: InitId,
                      RoleId: RolId,
                      COU: userDetails.COU,
                    };

                    const response = await APICall(
                      getAttributeValueDetailsV2,
                      "POST",
                      bodyObj
                    );
                    if (
                      response.data.sections &&
                      response.data.sections.length > 0
                    ) {
                      setSectionValuesArray(response.data.sections);
                    } else {
                      setSectionValuesArray([]);
                    }
                    hideLoader();
                  })();
                  if (EmployeeId != null) {
                    (async () => {
                      await showLoader();
                      const globalValues = await APICall(
                        getGlobalValues,
                        "POST",
                        {
                          TID: EmployeeId,
                        }
                      );

                      if (
                        globalValues.data !== null &&
                        globalValues.data.length > 0
                      ) {
                        setGlobalValues(globalValues.data);
                      }
                      await hideLoader();
                    })();
                  }
                }
              });

              //sort
              responseData.sort(
                (a, b) => a?.attributeSequenceNo - b?.attributeSequenceNo
              );
              if (responseData?.length > 1) {
                setDynamicAccordian(responseData);
              }
            }
          }
        }
      } catch (error) {
      } finally {
        hideLoader();
      }
    };
    const updateProgressionDetails = async() => {
      showLoader();
      try {

        let oldJsonObj = [];
        let newJsonObj = [];
        let oldForAuditLog = [];
        let newForAuditLog = [];
        let oldForFinalAuditLog = []; //For Final Approver 

        Object.keys(dynamicAccordian).length > 0 &&
          dynamicAccordian.map(async (eachAttr) => {
              if (eachAttr.attributeDatatype == "DropdownSingle") {
                const tempOldObj = {
                  [eachAttr.attributeCode]:
                    eachAttr.value == null || eachAttr.value == undefined
                      ? ""
                      : parseInt(eachAttr.value),
                };
                oldJsonObj.push(tempOldObj);
                const opObj = eachAttr.options?.find(
                  (item) => item.value == eachAttr.value
                );
                const tempOldAuditObj = {
                  sectionId: eachAttr.attributeSectionId,
                  IsAttributeSet: false,
                  Code: eachAttr.attributeCode,
                  Attribute: [
                    {
                      [eachAttr.attributeCode]:
                        opObj == null || opObj == undefined ? "" : opObj.label,
                    },
                  ],
                };
                oldForAuditLog.push(tempOldAuditObj);
                //For Final Approver Starts
                const oldForFinalAuditObj = {
                  sectionId: eachAttr.attributeSectionId,
                  IsAttributeSet: false,
                  Code: eachAttr.attributeCode,
                  Attribute: [
                    {
                      [eachAttr.attributeCode]: "",
                    },
                  ],
                };
                oldForFinalAuditLog.push(oldForFinalAuditObj);
                //For Final Approver Ends
                const tempNewObj = {
                  [eachAttr.attributeCode]:
                    eachAttr.valueOnDd == undefined || eachAttr.valueOnDd == null
                      ? ""
                      : parseInt(eachAttr.valueOnDd.value),
                };
                newJsonObj.push(tempNewObj);
                const tempNewAuditObj = {
                  sectionId: eachAttr.attributeSectionId,
                  IsAttributeSet: false,
                  Code: eachAttr.attributeCode,
                  Attribute: [
                    {
                      [eachAttr.attributeCode]:
                        eachAttr.valueOnDd == undefined ||
                        eachAttr.valueOnDd == null
                          ? ""
                          : eachAttr.valueOnDd.label,
                    },
                  ],
                };
                newForAuditLog.push(tempNewAuditObj);
              }
              if (eachAttr.attributeDatatype == "Text") {
                const tempOldObj = {
                  [eachAttr.attributeCode]:
                    eachAttr.value == null || eachAttr.value == undefined
                      ? ""
                      : eachAttr.value,
                };
                oldJsonObj.push(tempOldObj);
                const tempOldAuditObj = {
                  sectionId: eachAttr.attributeSectionId,
                  IsAttributeSet: false,
                  Code: eachAttr.attributeCode,
                  Attribute: [
                    {
                      [eachAttr.attributeCode]:
                        eachAttr.value == null || eachAttr.value == undefined
                          ? ""
                          : eachAttr.value,
                    },
                  ],
                };
                oldForAuditLog.push(tempOldAuditObj);
                //For Final Approver Starts
                const oldForFinalAuditObj = {
                  sectionId: eachAttr.attributeSectionId,
                  IsAttributeSet: false,
                  Code: eachAttr.attributeCode,
                  Attribute: [
                    {
                      [eachAttr.attributeCode]: "",
                    },
                  ],
                };
                oldForFinalAuditLog.push(oldForFinalAuditObj);
                //For Final Approver Ends
                const tempNewObj = {
                  [eachAttr.attributeCode]:
                    eachAttr.valueOnDd == undefined || eachAttr.valueOnDd == null
                      ? ""
                      : eachAttr.valueOnDd,
                };
                newJsonObj.push(tempNewObj);
                const tempNewAuditObj = {
                  sectionId: eachAttr.attributeSectionId,
                  IsAttributeSet: false,
                  Code: eachAttr.attributeCode,
                  Attribute: [
                    {
                      [eachAttr.attributeCode]:
                        eachAttr.valueOnDd == undefined ||
                        eachAttr.valueOnDd == null
                          ? ""
                          : eachAttr.valueOnDd,
                    },
                  ],
                };
                newForAuditLog.push(tempNewAuditObj);
              }
              if (eachAttr.attributeDatatype == "Number") {
                const tempOldObj = {
                  [eachAttr.attributeCode]:
                    eachAttr.value == null || eachAttr.value == undefined
                      ? ""
                      : parseInt(eachAttr.value),
                };
                oldJsonObj.push(tempOldObj);
                const tempOldAuditObj = {
                  sectionId: eachAttr.attributeSectionId,
                  IsAttributeSet: false,
                  Code: eachAttr.attributeCode,
                  Attribute: [
                    {
                      [eachAttr.attributeCode]:
                        eachAttr.value == null || eachAttr.value == undefined
                          ? ""
                          : parseInt(eachAttr.value),
                    },
                  ],
                };
                oldForAuditLog.push(tempOldAuditObj);
                //For Final Approver Starts
                const oldForFinalAuditObj = {
                  sectionId: eachAttr.attributeSectionId,
                  IsAttributeSet: false,
                  Code: eachAttr.attributeCode,
                  Attribute: [
                    {
                      [eachAttr.attributeCode]: 
                        eachAttr.attributeCode === 'NPIM'
                          ? parseInt(eachAttr.value) 
                          : "",
                    },
                  ],
                };
                oldForFinalAuditLog.push(oldForFinalAuditObj);
                //For Final Approver Ends
                const tempNewObj = {
                  [eachAttr.attributeCode]:
                    eachAttr.valueOnDd == undefined || eachAttr.valueOnDd == null
                      ? ""
                      : parseInt(eachAttr.valueOnDd),
                };
                newJsonObj.push(tempNewObj);
                const tempNewAuditObj = {
                  sectionId: eachAttr.attributeSectionId,
                  IsAttributeSet: false,
                  Code: eachAttr.attributeCode,
                  Attribute: [
                    {
                      [eachAttr.attributeCode]:
                        eachAttr.valueOnDd == undefined ||
                        eachAttr.valueOnDd == null
                          ? ""
                          : parseInt(eachAttr.valueOnDd),
                    },
                  ],
                };
                newForAuditLog.push(tempNewAuditObj);
              }
              if (eachAttr.attributeDatatype == "Date") {
                const parsedDateOld = moment(eachAttr.value, "DD-MMM-YYYY");
                const formattedDateOld = parsedDateOld.format("YYYY-MM-DD");
                const tempOldObj = {
                  [eachAttr.attributeCode]:
                    eachAttr.value == null ||
                    eachAttr.value == undefined ||
                    formattedDateOld == "Invalid date"
                      ? ""
                      : formattedDateOld,
                };
                oldJsonObj.push(tempOldObj);
                const tempOldAuditObj = {
                  sectionId: eachAttr.attributeSectionId,
                  IsAttributeSet: false,
                  Code: eachAttr.attributeCode,
                  Attribute: [
                    {
                      [eachAttr.attributeCode]:
                        eachAttr.value == null ||
                        eachAttr.value == undefined ||
                        formattedDateOld == "Invalid date"
                          ? ""
                          : formattedDateOld,
                    },
                  ],
                };
                oldForAuditLog.push(tempOldAuditObj);

                //For Final Approver Starts
                const oldForFinalAuditObj = {
                  sectionId: eachAttr.attributeSectionId,
                  IsAttributeSet: false,
                  Code: eachAttr.attributeCode,
                  Attribute: [
                    {
                      [eachAttr.attributeCode]: "",
                    },
                  ],
                };
                oldForFinalAuditLog.push(oldForFinalAuditObj);
                //For Final Approver Ends

                const parsedDateNew = moment(eachAttr.valueOnDd, "DD-MMM-YYYY");
                const formattedDateNew = parsedDateNew.format("YYYY-MM-DD");
                const tempNewObj = {
                  [eachAttr.attributeCode]:
                    eachAttr.valueOnDd == undefined ||
                    eachAttr.valueOnDd == null ||
                    formattedDateNew == "Invalid date"
                      ? ""
                      : formattedDateNew,
                };
                newJsonObj.push(tempNewObj);
                const tempNewAuditObj = {
                  sectionId: eachAttr.attributeSectionId,
                  IsAttributeSet: false,
                  Code: eachAttr.attributeCode,
                  Attribute: [
                    {
                      [eachAttr.attributeCode]:
                        eachAttr.valueOnDd == undefined ||
                        eachAttr.valueOnDd == null ||
                        formattedDateNew == "Invalid date"
                          ? ""
                          : formattedDateNew,
                    },
                  ],
                };
                newForAuditLog.push(tempNewAuditObj);
              }
              if (eachAttr.attributeDatatype == "SearchableDropdownSingle") {
                const tempOldObj = {
                  [eachAttr.attributeCode]:
                    eachAttr.value == null || eachAttr.value == undefined
                      ? ""
                      : parseInt(eachAttr.value),
                };
                oldJsonObj.push(tempOldObj);
                const tempNewObj = {
                  [eachAttr.attributeCode]:
                    eachAttr.valueOnDd == undefined || eachAttr.valueOnDd == null
                      ? ""
                      : parseInt(eachAttr.valueOnDd.value),
                };
                newJsonObj.push(tempNewObj);

                if (
                  eachAttr.value != null &&
                  eachAttr.value != undefined &&
                  eachAttr.value != ""
                ) {
                  const obj = await getSearchableDropdownAPI(
                    "",
                    eachAttr.attributeCode,
                    null,
                    eachAttr.value,
                    "attribute",
                    0
                  );
                  const tempOldAuditObj = {
                    sectionId: eachAttr.attributeSectionId,
                    IsAttributeSet: false,
                    Code: eachAttr.attributeCode,
                    Attribute: [
                      {
                        [eachAttr.attributeCode]:
                          obj.data[0] == null || obj.data[0] == undefined
                            ? ""
                            : obj.data[0].label,
                      },
                    ],
                  };
                  oldForAuditLog.push(tempOldAuditObj);
                }

                const tempNewAuditObj = {
                  sectionId: eachAttr.attributeSectionId,
                  IsAttributeSet: false,
                  Code: eachAttr.attributeCode,
                  Attribute: [
                    {
                      [eachAttr.attributeCode]:
                        eachAttr.valueOnDd == undefined ||
                        eachAttr.valueOnDd == null
                          ? ""
                          : parseInt(eachAttr.valueOnDd.label),
                    },
                  ],
                };
                newForAuditLog.push(tempNewAuditObj);
              }
          });

        const oldJson = JSON.stringify(oldJsonObj);
        const newJson = JSON.stringify(newJsonObj);
        const oldAuditJson = JSON.stringify(oldForAuditLog);
        const newAuditJson = JSON.stringify(newForAuditLog);
        const oldForFinalAuditJson = JSON.stringify(oldForFinalAuditLog);

        const SaveData = await APICall(approverChangeDataForSeparation, "POST", {
          Id: progId,
          RequestNewJson: newJson,
        });

        const auditRes = await APICall(InsertAuditLog, "POST", {
          UserId: userDetails.Id,
          UserEmail: userDetails.EmailId,
          UserName: `${userDetails.FirstName} ${userDetails.LastName}`,
          OldValue: approverRoleCode !== 'HRManager' ? oldAuditJson : oldForFinalAuditJson, 
          NewValue: newAuditJson,
          TransactionId: progId, //Request Details Id 
          EId: attributesData?.EmployeeId,
        });
      } catch (error) {
        console.log(error);
      } finally {
        hideLoader();
      }
    }
    const updateReporteeDetails = async() => {
      showLoader();
      try {
        // manager map
        let repMgMap = [];
        // relationship map
        let userRlMap = [];

        if (
          directReportee != null &&
          (directReportee.code == "TATUP" || directReportee.code == "TATOM")
        ) {
            if (reporteeManagerMap != null && reporteeManagerMap.length > 0) {
              reporteeManagerMap.map((eachRep) => {
                const tempObj = {
                  EmpId: eachRep.value,
                  ManagerId: managerForReportee.value,
                };
                repMgMap.push(tempObj);
              });
            }
        } else if (
          directReportee != null &&
          directReportee.code == "ITTOM"
        ) {
            if (reporteeManagerMap != null && reporteeManagerMap.length > 0) {
              reporteeManagerMap.map((eachRep) => {
                const tempObj = {
                  EmpId: eachRep.value,
                  ManagerId: eachRep.mg.value,
                };
                repMgMap.push(tempObj);
              });
            }
        }
        if (
          directReporteeRel != null &&
          (directReporteeRel.code == "RLTATUP" ||
            directReporteeRel.code == "RLTATOM")
        ) {
            if (userRelationMap != null && userRelationMap.length > 0) {
            userRelationMap.map((eachRep) => {
              const tempObj = {
                Id: eachRep.id,
                RelationId: relationForReportee.value,
              };
              userRlMap.push(tempObj);
            });
            }
        } else if (
          directReporteeRel != null &&
          directReporteeRel.code == "RLITTOM"
        ) {
            if (userRelationMap != null && userRelationMap.length > 0) {
            userRelationMap.map((eachRep) => {
              const tempObj = {
                Id: eachRep.id,
                RelationId: eachRep.relationid.value,
              };
              userRlMap.push(tempObj);
            });
            }
        } else if (
          directReporteeRel != null &&
          directReporteeRel.code == "RLTAR"
        ) {
            if (userRelationMap != null && userRelationMap.length > 0) {
              userRelationMap.map((eachRep) => {
                const tempObj = {
                  Id: eachRep.id,
                  RelationId: 0,
                };
                userRlMap.push(tempObj);
              });
            }
        }

        const repMgMapJson = JSON.stringify(repMgMap);
        const userRlMapJson = JSON.stringify(userRlMap);

        const UpdateRelationAndManagerData = await APICall(updateRelationAndManagerData, "POST", {
          Id: progId,
          RpMgMapType: directReportee ? directReportee.value : null,
          RpMgMap: repMgMapJson,
          JobRlMapType: directReporteeRel ? directReporteeRel.value : null,
          JobRlMap: userRlMapJson,
          UserId: userDetails.Id,
        });
      } catch (error) {
        console.log(error);
      } finally {
        hideLoader();
      }
    }

    const handleSubmitForApprover = async () => {
      try {
        let isError = {};

        showLoader();
        if (
          attributesData["PROGRMKS"] == null ||
          attributesData["PROGRMKS"] == "" ||
          !attributesData["PROGRMKS"]
        ) {
          isError["PROGRMKS"] = "Remarks are Mandatory.";
        }

        if (!attributesData["PROGDEC"]) {
          isError["PROGDEC"] = "Decision Required.";
        }

        if (
          attributesData["IsAcknolwdgedRequired"] != attributesData["PROGACK"]
        ) {
          isError["PROGACK"] = "Acknowledgement Required.";
        }

        if (
          attributesData["IsAcknolwdgedRequired"] &&
          !attributesData["PROGACK"]
        ) {
          isError["PROGACK"] = "Acknowledgement Required.";
        }

        setFormErrors(isError);
        if (Object.keys(isError).length > 0) return;

        let obj = {
          CreatedBy: userDetails?.Id,
          CreatedByRoleId: currentRoleId?.value,
          WorkflowTaskId: workflowTaskId,
          DecisionId: attributesData["PROGDEC"]?.value || 0,
          IsAcknowledged: attributesData["PROGACK"] || false,
          EmployeeId: attributesData?.EmployeeId,
          WorkflowTasksRemarks: attributesData["PROGRMKS"],
          ProgressionId: progId,
          InitiatorId: initId,
          ProxyCreatedBy: proxyUserDetails==null ? null : userDetails.UserId,
        };
        console.log('Final Object::::',obj);

        const response = await APICall(
          handleApprovalForProgressionModel,
          "POST",
          obj
        );

        if (response?.status == 0) {
          //Entry in Action History
          const actHistObj = await APICall(entryUpdateInActionHistory, "POST", {
            Status: attributesData["PROGDEC"]?.label,
            Remarks: attributesData["PROGRMKS"],
            ProgId: progId,
            CreatedBy: userDetails.Id,
          });
          //--
          if (progAttributesData["REQTYPOUPD"].code == "Separation") {
            await updateProgressionDetails();
            await updateReporteeDetails();
          }
          if (progAttributesData["REQTYPOUPD"].code == "Termination") {
            await updateReporteeDetails();
          }
        }
        
        if(response?.status == 0 && progAttributesData["REQTYPOUPD"].code == 'ResignationWithdraw' && progId != 0 ){
          const CurrentProgDetails = await APICall(
            getProgressionDataBasedOnId,
            "POST",
            { Id: progId }
          );
          const currentProgDet = CurrentProgDetails.data;
          if((currentProgDet.approvedStatus!=null && currentProgDet.approvedStatus!=false) 
            && (currentProgDet.workflowInstanceStatus == 'Completed')){
            const SyncDetails = await APICall(
              updateSyncStatusOfRevoke,
              "POST",
              { Id: progId }
            );

            const RevokeProgDetails = await APICall(
              getProgressionDataBasedOnId,
              "POST",
              { Id: progIdForRevoke }
            );
            const revokeProgDet = RevokeProgDetails.data;
            if((revokeProgDet.approvedStatus==null || revokeProgDet.approvedStatus==false)
              && (revokeProgDet.workflowInstanceStatus == 'InProgress' || revokeProgDet.workflowInstanceStatus == 'Inprogress')){
              //progression id pe sab isactive 0
              const revokeObj = {
                'Id' : revokeProgDet.empId,
                'ProgressionId' : progIdForRevoke,
              }
              await APICall(submitProgressionRevoke, "POST", revokeObj);
            } else if ((revokeProgDet.approvedStatus==true) 
            && (revokeProgDet.workflowInstanceStatus == 'Completed')){
              //repMgMap, empRlMap, newJsonForProgression
              let postObj = {};
              postObj = {
                ...postObj,
                'Id' : revokeProgDet.empId,
                'ProgressionId' : progIdForRevoke,
              }
              newJsonForProgression.map((upobj)=>{
                const item = Object.keys(upobj)[0];
                if(item == 'NPIM'){
                  postObj = {
                    ...postObj,
                    [item] : upobj[item]
                  }
                } else {
                  postObj = {
                    ...postObj,
                    [item] : null
                  }
                }
              })

              let RpMgArray = [];
              if(repMgMap.length>0){
                repMgMap.map((obj)=>{
                  const item = Object.keys(obj);
                  const addtojson = {
                    [item[0]] : obj[item[0]],
                    [item[1]] : revokeProgDet.empId,
                  }
                  RpMgArray.push(addtojson);
                });
              }

              let EmpRlArray = [];
              if(empRlMap.length>0){
                empRlMap.map((obj)=>{
                  const item = Object.keys(obj);
                  const addtojson = {
                    [item[0]] : obj[item[0]],
                    [item[1]] : revokeProgDet.progCreatedById,
                  }
                  EmpRlArray.push(addtojson);
                });
              }

              postObj = {
                ...postObj,
                'RpMgMap' : RpMgArray,
                'EmpRlMap' : EmpRlArray,
              }

              console.log('Post Object::::', postObj);

              //API Call to submit data
              await APICall(revokeProgressionAfterApproved, "POST", postObj);
            }

            const revokedetails = await APICall(changeIsRevokedStatus, "POST", { Id : progIdForRevoke });
            const worklineResp = await APICall(updateEventAndReasonInWorkline, "POST", {
              Eid: parseInt(attributesData?.EmployeeId),
              WorkFlowTypeId: attributesData["REQTYPOUPD"]?.value,
              WorkFlowSubTypeId: attributesData["REQRSNOUP"]?.value,
              CreatedBy: initiatorUserId,
              WorkFlowEffectiveDate: moment(attributesData["REQEFCTVDT"]).format('YYYY-MM-DD'),
            });
            const mediBuddyApiCall = await APICall(mediBuddyApiCallAfterRevoke,"POST",{
              EmployeeId: parseInt(attributesData["REQEMPLID"].value),
            });
            if(mediBuddyApiCall != null && mediBuddyApiCall != undefined)
            {
              notify(mediBuddyApiCall?.status, mediBuddyApiCall?.message);
            } 
            else 
            {
              notify(1, "No data received from Medibuddy Integration API.");
            }
            const updateEmpIncmOnRevoke = await APICall(updatePosIncmMapOnRevoke, "POST",{
              EmployeeId: parseInt(attributesData?.EmployeeId),
              userid: initiatorUserId,
            });
            notify(0, "Resignation Revoked!");
            navigate("/home");
          }
          notify(0, "Record Saved Successfully.");
          navigate("/home");
        } else if (response?.status == 0) {
          notify(0, "Record Saved Successfully.");
          navigate("/home");
        } else {
          notify(1, "Something went wrong.");
        }
      } catch (error) {
        console.log(error);
      } finally {
        hideLoader();
      }
    };
    const GetNPEndDate = (date) => {
      if (dynamicAccordian.length > 0) {
        // let NPSD = dynamicAccordians.filter((dc)=>{
        //   return dc.attributeCode === "NPSD"
        // })[0].valueOnDd
        let NPSD = date;
        let NPIM = dynamicAccordian.filter((dc) => {
          return dc.attributeCode === "NPIM";
        })[0].valueOnDd;
  
        if (NPSD != undefined && NPSD != null && NPSD != "") {
          if (NPIM != undefined && NPIM != null && NPIM != "") {
            const startDateNP = moment(NPSD);
            let NPDays = Number(NPIM) * 30.44;
            let EndDateNP = startDateNP.add(NPDays, "d");
            GetShortDays("NPEDE", EndDateNP);
            return EndDateNP;
          } else {
            return "";
          }
        } else {
          return "";
        }
      }
    };
    const GetShortDays = (attribute, date) => {
      if (dynamicAccordian.length > 0) {
        let NPEDE = "";
        let LWD = "";
        // if(attribute === "NPEDE"){
        //    NPEDE = date
        // }
        // else{
        NPEDE = dynamicAccordian.filter((dc) => {
          return dc.attributeCode === "NPEDE";
        })[0].valueOnDd;
        //}
        //   if(attribute === "LWD"){
        //     LWD = date
        //  }
        //  else{
        LWD = dynamicAccordian.filter((dc) => {
          return dc.attributeCode === "LWD";
        })[0].valueOnDd;
        //}
        if (
          LWD != undefined &&
          LWD != null &&
          NPEDE != undefined &&
          NPEDE != null
        ) {
          let daysDiff;
          if (moment(LWD) <= moment(NPEDE)) {
            daysDiff = moment(NPEDE).diff(moment(LWD), "days");
          } else {
            daysDiff = 0;
          }
          // let tempAcc = [...dynamicAccordians];
          //  let tempAtt = tempAcc.find(
          //                         (item) =>
          //                           item.attributeCode ==
          //                           "NPSDE"
          //                       );
          //                       tempAtt.valueOnDd = daysDiff;
          //                       setDynamicAccordian(tempAcc);
          return daysDiff > 0 ? daysDiff - 1 : daysDiff;
        } else {
          return "";
        }
      }
    };
console.log('dynamicAccordian::::', dynamicAccordian);
    const handleDownloadFileFlat = async (fileAttribute, fileIndex) => {
      showLoader();
      if (
        fileAttribute.DocId != undefined &&
        fileAttribute.DocId != null &&
        fileAttribute.DocId != 0
      ) {
        const doc = await APICall(downloadSupportingDocForProgression, "POST", {
          AC: fileAttribute.Data,
        });
        if (doc && doc.data !== null) {
          downloadBase64File(doc.data, fileAttribute.Name);
          hideLoader();
        } else {
          notify(1, "Something Went Wrong.");
          hideLoader();
        }
      } else {
        downloadBase64File(fileAttribute.Data, fileAttribute.Name);
        hideLoader();
      }
    };
    function downloadBase64File(base64String: string, fileName: string) {
      const mimeType = inferMimeType(base64String);
      const blob = base64toBlob(base64String, mimeType);

      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      URL.revokeObjectURL(url);
    }
    function inferMimeType(base64String: string): string {
      const dataUrl = `data:application/octet-stream;base64,${base64String}`;
      const typeInfo = /^data:(.*?);/.exec(dataUrl);

      if (typeInfo && typeInfo[1]) {
        return typeInfo[1];
      }

      return "application/octet-stream";
    }
    function base64toBlob(base64String: string, mimeType: string): Blob {
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      return new Blob([byteArray], { type: mimeType });
    }

    const [selectedFilesForFlat, setSelectedFilesForFlat] = useState<
      {
        AC: string;
        Name: string;
        Data: string;
        IsValidFile: boolean;
        DocId: Number;
      }[]
    >([]);

    return (
      <div className="col-lg-12">
        <div className="my-3 clearfix d-flex justify-content-end mr-3">
          <button
            onClick={() => navigate("/home")}
            className="btn btn-filter-submit ml-2">
            <i className="fa-solid fa-arrow-left mr-1"></i> Back
          </button>
          <button
            onClick={async () => {
              setAccordion((prev) => !prev);
              await handleSubmitForApprover();
            }}
            disabled={attributesData["IsDisabled"]}
            className="btn btn-filter-submit float-right ml-2">
            <i className="fa-solid fa-check"></i> Submit
          </button>
          {/* <button
            onClick={async () => {
              await updateProgressionDetails();
              await updateReporteeDetails();
            }}
            disabled={attributesData["IsDisabled"]}
            className="btn btn-red-danger float-right ml-2">
            <i className="fa-solid fa-check"></i> Test Button
          </button> */}
        </div>

        {/* approver accordian */}
        <Accordion
          elevation={0}
          className="mb-3"
          expanded={accordion}
          onChange={() => setAccordion((prev) => !prev)}>
          <AccordionSummary
            id="profile-accordion"
            style={{ background: "#3C5464" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}>
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <p style={{ fontSize: "16px" }}>{`${"Approver"}`}</p>
              </div>
              <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
            </div>
          </AccordionSummary>

          <AccordionDetails className="page_heading">
            <div className="row">
              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Approver
                    <sup>*</sup>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Approver"}
                    isDisabled={true}
                    textArea={false}
                    value={
                      attributesData["PROGRVWR"]
                        ? attributesData["PROGRVWR"]
                        : ""
                    }
                    onChange={(e) => {
                      setAttributesData((prev) => ({
                        ...prev,
                        ["PROGRVWR"]: e.target.value,
                      }));
                    }}
                  />
                </div>
                {formErrors["PROGRVWR"] && (
                  <p style={{ color: "red" }}>{formErrors["PROGRVWR"]}</p>
                )}
              </div>
              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Role
                    <sup>*</sup>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Role"}
                    isDisabled={true}
                    textArea={false}
                    value={
                      attributesData["PROGROLE"]
                        ? attributesData["PROGROLE"]
                        : ""
                    }
                    onChange={(e) => {
                      setAttributesData((prev) => ({
                        ...prev,
                        ["PROGROLE"]: e.target.value,
                      }));
                    }}
                  />
                </div>
                {formErrors["PROGROLE"] && (
                  <p style={{ color: "red" }}>{formErrors["PROGROLE"]}</p>
                )}
              </div>
              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Date
                    <sup>*</sup>
                  </label>
                  <DateForm
                    isDisabled={true}
                    value={
                      attributesData["PROGEFFDT"]
                        ? attributesData["PROGEFFDT"]
                        : null
                    }
                    onChange={(date) => {
                      setAttributesData((prev) => ({
                        ...prev,
                        ["PROGEFFDT"]: moment(date).format("DD-MMM-YYYY"),
                      }));
                    }}
                  />
                </div>
                {formErrors["PROGEFFDT"] && (
                  <p style={{ color: "red" }}>{formErrors["PROGEFFDT"]}</p>
                )}
              </div>
              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Decision
                    <sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={attributesData["IsDisabled"]}
                    value={
                      attributesData["PROGDEC"]
                        ? attributesData["PROGDEC"]
                        : null
                    }
                    placeholder={"Decision"}
                    options={decisionOptionsArr}
                    onChange={(e) => {
                      setAttributesData((prev) => ({
                        ...prev,
                        ["PROGDEC"]: e,
                      }));

                      let obj = formErrors;
                      delete obj["PROGDEC"];
                      setFormErrors(obj);
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
                {formErrors["PROGDEC"] && (
                  <p style={{ color: "red" }}>{formErrors["PROGDEC"]}</p>
                )}
              </div>
            </div>
            <div className="row">
              <div className={"col-lg-6 col-sm-12 mobile-view top-m"}>
                <div className="mb-1">
                  <label className="col-form-label">Remarks<sup>*</sup></label>
                  <InputForm
                    className="form-control"
                    placeholder={"Remarks"}
                    isDisabled={attributesData["IsDisabled"]}
                    textArea={true}
                    value={
                      attributesData["PROGRMKS"]
                        ? attributesData["PROGRMKS"]
                        : ""
                    }
                    onChange={(e) => {
                      setAttributesData((prev) => ({
                        ...prev,
                        ["PROGRMKS"]: e.target.value,
                      }));
                    }}
                  />
                </div>
                {formErrors["PROGRMKS"] && (
                  <p style={{ color: "red" }}>{formErrors["PROGRMKS"]}</p>
                )}
              </div>
              <div className={"col-lg-6 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    I acknowledge that this request has been reviewed by me.
                    {attributesData["IsAcknolwdgedRequired"] == true && (
                      <sup>*</sup>
                    )}
                  </label>
                  <div>
                    <input
                      disabled={attributesData["IsDisabled"]}
                      type="checkbox"
                      onChange={(e) => {
                        setAttributesData((prev) => ({
                          ...prev,
                          ["PROGACK"]: e.target.checked,
                        }));

                        let obj = formErrors;
                        delete obj["PROGACK"];
                        setFormErrors(obj);
                      }}
                      id={"PROGACK"}
                      checked={attributesData["PROGACK"]}
                    />
                  </div>
                </div>
                {formErrors["PROGACK"] && (
                  <p style={{ color: "red" }}>{formErrors["PROGACK"]}</p>
                )}
              </div>
            </div>
            <div className="row">
              <div className={"col-lg-12 col-md-12 col-sm-12 mobile-view top-m"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Previous Conversation
                  </label>
                  {/* <InputForm
                    className="form-control"
                    placeholder={"Previous Conversation"}
                    isDisabled={true}
                    textArea={true}
                    value={
                      attributesData["PROGPRCONVO"]
                        ? attributesData["PROGPRCONVO"]
                        : ""
                    }
                    onChange={(e) => {
                      setAttributesData((prev) => ({
                        ...prev,
                        ["PROGPRCONVO"]: e.target.value,
                      }));
                    }}
                    rows={7}
                  /> */}
                  <PreviousConversation PrevConv={preConvJson}/>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        {/* progression attributes accordian */}
        <Accordion
          elevation={0}
          className="mb-3"
          expanded={accordionReqDet}
          onChange={() => setAccordionReqDet((prev) => !prev)}>
          <AccordionSummary
            id="profile-accordion"
            style={{ background: "#3C5464" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}>
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <p style={{ fontSize: "16px" }}>Request Details</p>
              </div>
              <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
            </div>
          </AccordionSummary>

          <AccordionDetails className="page_heading">
            <div className="row" aria-disabled={true}>
              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Effective From Date
                    <sup>*</sup>
                  </label>
                  <DateForm
                    isDisabled={true}
                    value={
                      progAttributesData["REQEFCTVDT"]
                        ? progAttributesData["REQEFCTVDT"]
                        : null
                    }
                    onChange={(date) => {}}
                  />
                </div>
                {formErrors["REQEFCTVDT"] && (
                  <p style={{ color: "red" }}>{formErrors["REQEFCTVDT"]}</p>
                )}
              </div>

              <div className={"col-lg-3 col-sm-3 col-xs-4"}
              hidden={progAttributesData["REQTYPOUPD"]?.code == 'Separation' 
              || progAttributesData["REQTYPOUPD"]?.code == 'ResignationWithdraw' ? true : false}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Country
                    <sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={true}
                    value={
                      progAttributesData["REQCNTRY"]
                        ? progAttributesData["REQCNTRY"]
                        : null
                    }
                    placeholder={"Country"}
                    options={
                      optionsObj["REQCNTRY"] ? optionsObj["REQCNTRY"] : []
                    }
                    onChange={async (e) => {}}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
                {formErrors["REQCNTRY"] && (
                  <p style={{ color: "red" }}>{formErrors["REQCNTRY"]}</p>
                )}
              </div>

              <div
                className={"col-lg-3 col-sm-3 col-xs-4"}
                hidden={
                  progAttributesData["REQCNTRY"] &&
                  progAttributesData["REQCNTRY"].code == "CODECNTRY7"
                    ? false
                    : true
                }>
                <div className="mb-1">
                  <label className="col-form-label">
                    Payroll Location
                    {progAttributesData["REQCNTRY"] &&
                      progAttributesData["REQCNTRY"].code == "CODECNTRY7" && (
                        <sup>*</sup>
                      )}
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={true}
                    value={
                      progAttributesData["REQPLOCN"]
                        ? progAttributesData["REQPLOCN"]
                        : null
                    }
                    placeholder={"Payroll Location"}
                    options={[]}
                    onChange={async (e) => {}}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
                {formErrors["REQPLOCN"] && (
                  <p style={{ color: "red" }}>{formErrors["REQPLOCN"]}</p>
                )}
              </div>

              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Type of Update
                    <sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={true}
                    value={
                      progAttributesData["REQTYPOUPD"]
                        ? progAttributesData["REQTYPOUPD"]
                        : null
                    }
                    placeholder={"Type Of Update"}
                    options={[]}
                    onChange={async (e) => {}}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
                {formErrors["REQTYPOUPD"] && (
                  <p style={{ color: "red" }}>{formErrors["REQTYPOUPD"]}</p>
                )}
              </div>
              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Reason for Update
                    <sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={true}
                    value={
                      progAttributesData["REQRSNOUP"]
                        ? progAttributesData["REQRSNOUP"]
                        : null
                    }
                    placeholder={"Reason Of Update"}
                    options={[]}
                    onChange={(e) => {}}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
                {formErrors["REQRSNOUP"] && (
                  <p style={{ color: "red" }}>{formErrors["REQRSNOUP"]}</p>
                )}
              </div>

              <div
                className={"col-lg-3 col-sm-3 col-xs-4"}
                hidden={sectionTabHide}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Tab
                    <sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={true}
                    value={
                      attributesData["REQTABS"]
                        ? attributesData["REQTABS"]
                        : null
                    }
                    placeholder={"Tab"}
                    options={optionsObj["REQTABS"] ? optionsObj["REQTABS"] : []}
                    onChange={() => {}}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
              </div>
              <div
                className={"col-lg-3 col-sm-3 col-xs-4"}
                hidden={sectionTabHide}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Section
                    <sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={true}
                    value={
                      attributesData["REQSECT"]
                        ? attributesData["REQSECT"]
                        : null
                    }
                    placeholder={"Section"}
                    options={optionsObj["REQSECT"] ? optionsObj["REQSECT"] : []}
                    onChange={(e) => {}}
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
              </div>

              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Employee ID
                    <sup>*</sup>
                  </label>

                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={true}
                    value={
                      progAttributesData["REQEMPLID"]
                        ? progAttributesData["REQEMPLID"]
                        : null
                    }
                    placeholder={"Employee ID"}
                    async
                    options={[]}
                    onChange={(e) => {}}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
                {formErrors["REQEMPLID"] && (
                  <p style={{ color: "red" }}>{formErrors["REQEMPLID"]}</p>
                )}
              </div>
              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Employee Name
                    <sup>*</sup>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Employee Name"}
                    isDisabled={true}
                    textArea={false}
                    value={
                      progAttributesData["REQEMPLNM"]
                        ? progAttributesData["REQEMPLNM"]
                        : ""
                    }
                    onChange={(e) => {}}
                  />
                </div>
                {formErrors["REQEMPLNM"] && (
                  <p style={{ color: "red" }}>{formErrors["REQEMPLNM"]}</p>
                )}
              </div>

              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">Supporting Documents</label>

                  <div className="box position-relative">
                    <input
                      id={`files`}
                      disabled
                      className="form-control inputfile inputfile-6 multiple-inputfile"
                      data-multiple-caption="{count} files selected"
                      type="file"
                      onChange={(e) => {}}
                      value={""}
                    />
                    <label
                      htmlFor={`files`}
                      className="form-control"
                      style={{ width: 0, border: "none" }}>
                      <strong
                        style={{
                          padding: "6px 16px",
                          backgroundColor: "#3c5464",
                          borderRadius: "5px",
                        }}>
                        <i
                          className="fa fa-upload rotate90"
                          aria-hidden="true"></i>
                        {"  "}
                        Upload
                      </strong>{" "}
                    </label>
                  </div>

                  <div className="file-added-list">
                    <ul className="list-unstyle">
                      {selectedFilesForFlat &&
                        selectedFilesForFlat.length > 0 &&
                        selectedFilesForFlat.map(
                          (fileAttribute, attributeIndex) => (
                            <>
                              <li className="list mt-1" key={attributeIndex}>
                                <div className="media">
                                  <div className="media-body text-truncate">
                                    <span className="view-more">
                                      {fileAttribute.Name}
                                    </span>
                                  </div>

                                  <div className="media-right ml-2">
                                    <i
                                      className="fa-solid fa-download"
                                      aria-hidden="true"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleDownloadFileFlat(
                                          fileAttribute,
                                          attributeIndex
                                        )
                                      }></i>
                                  </div>
                                </div>
                              </li>
                            </>
                          )
                        )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className={"col-lg-6 col-sm-12 mobile-view top-m"}>
                <div className="mb-1">
                  <label className="col-form-label">Remarks</label>
                  <InputForm
                    className="form-control"
                    placeholder={"Remarks, if any."}
                    isDisabled={true}
                    textArea={true}
                    value={
                      progAttributesData["REQDREMK"]
                        ? progAttributesData["REQDREMK"]
                        : ""
                    }
                    onChange={(e) => {}}
                  />
                </div>
              </div>
            </div>
            {/* <div className="row" aria-disabled={true}>
              
              
            </div> */}
          </AccordionDetails>
        </Accordion>

        {progId > 0 && (
          <Accordion
            elevation={0}
            className="mb-3"
            expanded={accordianChangeLog}
            onChange={() => setAccordianChangeLog((prev) => !prev)}>
            <AccordionSummary
              id="profile-accordion"
              style={{ background: "#3C5464" }}
              className="text-white acc_close"
              expandIcon={<ExpandMore />}>
              <div className="row d-flex align-items-center acc-heading">
                <div className="col-lg-10 col-md-10 col-sm-12">
                  <p style={{ fontSize: "16px" }}>Change Log</p>
                </div>
                <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
              </div>
            </AccordionSummary>

            <AccordionDetails className="page_heading">
              <div className="">
                <ChangeLog TId={progId} />
              </div>
            </AccordionDetails>
          </Accordion>
        )}

        {/* dynamic attributes accordian  */}
        {dynamicAccordian !== null && dynamicAccordian?.length > 0 && (
          <>
            <Accordion
              elevation={0}
              className="mb-3"
              expanded={accordianEvent}
              onChange={() => setAccordianEvent((prev) => !prev)}>
              <AccordionSummary
                id="profile-accordion"
                style={{ background: "#3C5464" }}
                className="text-white acc_close"
                expandIcon={<ExpandMore />}>
                <div className="row d-flex align-items-center acc-heading">
                  <div className="col-lg-10 col-md-10 col-sm-12">
                    <p style={{ fontSize: "16px" }}>
                      {progAttributesData["REQTYPOUPD"]
                        ? progAttributesData["REQTYPOUPD"].label
                        : "Event"}
                    </p>
                  </div>
                  <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
                </div>
              </AccordionSummary>

              <AccordionDetails className="page_heading">
                <div className="row">
                  {dynamicAccordian !== null &&
                    dynamicAccordian.length > 0 &&
                    dynamicAccordian.map((attribute, key) => (
                      <React.Fragment key={key}>
                        {attribute.attributeType == "Attribute" ? (
                          attribute.attributeDatatype === "DropdownSingle" ? (
                            <div className="col-lg-3 col-sm-3 col-xs-4"
                              hidden={
                                progAttributesData["REQCNTRY"]?.code == "CODECNTRY1" &&
                                  progAttributesData["REQTYPOUPD"]?.code == "Promotion" &&
                                  attribute.attributeCode == "SC"
                                ? skillcategoryHide
                                : (attribute.attributeCode === "OTR"
                                    || attribute.attributeCode == "WOA"
                                    || attribute.attributeCode == "WOAR"
                                    || attribute.attributeCode == "ETD") 
                                  && (approverRoleCode !== 'HRManager')
                                  && (progAttributesData["REQTYPOUPD"]?.code == 'Separation' )
                                ? true : false
                              }
                            >
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {attribute.attributeName}
                                </label>
                                {attribute.attributeCode != "OTR" &&
                                  attribute.attributeCode != "WOA" &&
                                  attribute.attributeCode != "WOAR" && 
                                  attribute.attributeCode != 'SCN' &&
                                  attribute.attributeCode != 'SSCN' &&
                                  attribute.attributeCode != 'SSSCN' && (
                                    <sup>*</sup>
                                  )}
                                <SelectForm
                                  isSearchable
                                  isDisabled={
                                    progAttributesData["REQTYPOUPD"]?.code == 'Separation' 
                                      && approverRoleCode === 'HRManager' 
                                    ? false 
                                    : true
                                  }
                                  value={attribute.valueOnDd}
                                  options={attribute.options}
                                  placeholder={attribute.attributeName}
                                  isMulti={false}
                                  noIndicator={false}
                                  noSeparator={false}
                                  onChange={(e) => {
                                    if(progAttributesData["REQTYPOUPD"]?.code == 'Separation' 
                                      && approverRoleCode === 'HRManager' ){
                                      if (e) {
                                        let tempAcc = [...dynamicAccordian];
      
                                        let tempAtt = tempAcc.find(
                                          (item) =>
                                            item.attributeCode ==
                                            attribute.attributeCode
                                        );
      
                                        tempAtt.valueOnDd = e;
                                        setDynamicAccordian(tempAcc);

                                        //-----------Set Wave Off Reason----------------------//
                                        if (attribute.attributeCode == "WOA") {
                                          if (e.code == "CODEWOAPPR2") {
                                            setWaveOffReasonDisable(true);
                                          } else {
                                            setWaveOffReasonDisable(false);
                                          }
                                        }
                                        ////////////////////////////////////////////////////////
                                      } else {
                                        notify(1, "Cannot Clear the Field");
                                      }
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          ) : attribute.attributeDatatype === "Text" ||
                            attribute.attributeDatatype === "Number" ? (
                            <div className="col-lg-3 col-sm-3 col-xs-4"
                              hidden={
                                (attribute.attributeCode === "OTR"
                                  || attribute.attributeCode == "WOA"
                                  || attribute.attributeCode == "WOAR"
                                  || attribute.attributeCode == "ETD") 
                                && (approverRoleCode !== 'HRManager')
                                && (progAttributesData["REQTYPOUPD"]?.code == 'Separation' )
                                ? true : false
                              }
                            >
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {attribute.attributeName}
                                </label>
                                {attribute.attributeCode != "OTR" &&
                                  attribute.attributeCode != "WOA" &&
                                  attribute.attributeCode != "WOAR" && (
                                    <sup>*</sup>
                                  )}
                                <InputForm
                                  className="form-control"
                                  isDisabled={
                                    // progAttributesData["REQTYPOUPD"]?.code == 'Separation' 
                                    //   && approverRoleCode === 'HRManager'
                                    //   && attribute.attributeCode === "WOAR"
                                    // ? waveOffReasonDisable
                                    // : 
                                    progAttributesData["REQTYPOUPD"]?.code == 'Separation' 
                                      && approverRoleCode === 'HRManager'
                                      && attribute.attributeCode !== "NPIM" 
                                    ? false 
                                    : true
                                  }
                                  value={attribute.valueOnDd}
                                  placeholder={attribute.attributeName}
                                  textArea={false}
                                  onChange={(e) => {
                                    if(progAttributesData["REQTYPOUPD"]?.code == 'Separation' 
                                      && approverRoleCode === 'HRManager' ){
                                      let tempAcc = [...dynamicAccordian];
                                      let tempAtt = tempAcc.find(
                                        (item) =>
                                          item.attributeCode ==
                                          attribute.attributeCode
                                      );
                                      tempAtt.valueOnDd = e.target.value;
                                      setDynamicAccordian(tempAcc);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          ) : attribute.attributeDatatype === "Date" ? (
                            <div className="col-lg-3 col-sm-3 col-xs-4"
                              hidden={
                                (attribute.attributeCode === "OTR"
                                  || attribute.attributeCode == "WOA"
                                  || attribute.attributeCode == "WOAR"
                                  || attribute.attributeCode == "ETD") 
                                && (approverRoleCode !== 'HRManager')
                                && (progAttributesData["REQTYPOUPD"]?.code == 'Separation' )
                                ? true : false
                              }
                            >
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {attribute.attributeName}
                                </label>
                                <sup>*</sup>
                                <DateForm
                                  isDisabled={
                                    (progAttributesData["REQTYPOUPD"]?.code == 'Separation' 
                                      && approverRoleCode === 'HRManager'
                                      && attribute.attributeCode !== "NPSD"
                                      && attribute.attributeCode !== "NPEDE") 
                                    || (progAttributesData["REQTYPOUPD"]?.code == 'Separation' 
                                      && approverRoleCode !== 'HRManager' 
                                      && attribute.attributeCode === 'LWD')
                                    ? false 
                                    : true
                                  }
                                  value={attribute.valueOnDd}
                                  onChange={(date) => {
                                    if(progAttributesData["REQTYPOUPD"]?.code == 'Separation'){
                                      let tempAcc = [...dynamicAccordian];
                                      let tempAtt = tempAcc?.find(
                                        (item) => item.attributeCode == attribute.attributeCode
                                      );
                                      tempAtt.valueOnDd =
                                        date != null
                                          ? moment(date).format("DD-MMM-YYYY")
                                          : null;
                                      if (
                                        attribute.attributeCode === "DOR" &&
                                        date != null
                                      ) {
                                        let tempAtt1 = tempAcc.find(
                                          (item) => item.attributeCode == "NPSD"
                                        );
                                        tempAtt1.valueOnDd =
                                          date != null
                                            ? moment(date).format("DD-MMM-YYYY")
                                            : null;
                                        let tempAtt2 = tempAcc.find(
                                          (item) => item.attributeCode == "NPEDE"
                                        );
                                        tempAtt2.valueOnDd =
                                          date != null
                                            ? moment(GetNPEndDate(date)).format(
                                                "DD-MMM-YYYY"
                                              )
                                            : null;
                                        let tempAtt3 = tempAcc.find(
                                          (item) => item.attributeCode == "NPSDE"
                                        );
                                        tempAtt3.valueOnDd = GetShortDays(
                                          attribute.attributeCode,
                                          date
                                        );
                                      }
                                      if (
                                        attribute.attributeCode === "LWD" &&
                                        date != null
                                      ) {
                                        let tempAttNPSDE = tempAcc.find(
                                          (item) => item.attributeCode == "NPSDE"
                                        );
                                        tempAttNPSDE.valueOnDd = GetShortDays(
                                          attribute.attributeCode,
                                          date
                                        );
                                        
                                        const tempDate = moment(date);
                                        const newDate = tempDate.add(1, "days");
                                        let tempAtt5 = tempAcc.find(
                                          (item) => item.attributeCode == "ETD"
                                        );
                                        if (approverRoleCode !== 'HRManager' ){
                                          tempAtt5.value =
                                            moment(newDate).format("DD-MMM-YYYY");
                                        }
                                        tempAtt5.valueOnDd =
                                          moment(newDate).format("DD-MMM-YYYY");
                                      }
                                      setDynamicAccordian(tempAcc);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          ) : attribute.attributeDatatype ===
                            "SearchableDropdownSingle" ? (
                            <div className="col-lg-3 col-sm-3 col-xs-4">
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {attribute.attributeName}
                                </label>
                                <sup>*</sup>
                                <SelectForm
                                  isClearable
                                  isSearchable
                                  isDisabled={true}
                                  options={[]}
                                  placeholder={attribute.attributeName}
                                  value={
                                    attribute.valueOnDd
                                      ? attribute.valueOnDd
                                      : null
                                  }
                                  onChange={(e) => {}}
                                  isMulti={false}
                                  noIndicator={false}
                                  noSeparator={false}
                                />
                              </div>
                            </div>
                          ) : attribute.attributeDatatype === "Document" ? (
                            <>
                              <div
                                className={"col-lg-3 col-sm-3 col-xs-4"}
                              >
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    {attribute.attributeName}
                                      <sup>*</sup>
                                  </label>
    
                                  <div className="box position-relative">
                                    <input
                                      id={`files-${key}`}
                                      className="form-control inputfile inputfile-6 multiple-inputfile"
                                      data-multiple-caption="{count} files selected"
                                      multiple={true}
                                      // ref={FlatDocRef}
                                      disabled={true}
                                      accept={JSON.parse(attribute.attributeValidations).FX}
                                      type="file"
                                      // onChange={(e) => {
                                      //   handleFileChangeForFlat(e, attribute);
                                      // }}
                                      value={""}
                                    />
                                    <label
                                      htmlFor={`files-${key}`}
                                      className="form-control"
                                      style={{ width: 0, border: "none" }}
                                    >
                                      <strong
                                        style={{
                                          padding: "6px 16px",
                                          backgroundColor: "#3c5464",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <i
                                          className="fa fa-upload rotate90"
                                          aria-hidden="true"
                                        ></i>
                                        {"  "}
                                        Upload
                                      </strong>{" "}
                                    </label>
                                  </div>
                                  {formErrors[attribute.attributeCode] && (
                                    <p style={{ color: "red" }}>
                                      {formErrors[attribute.attributeCode]}
                                    </p>
                                  )}
                                  <div className="file-added-list">
                                    <ul className="list-unstyle">
                                      {selectedFilesForFlatProg &&
                                        selectedFilesForFlatProg.length > 0 &&
                                        selectedFilesForFlatProg.map(
                                          (fileAttribute, attributeIndex) => (
                                            <>
                                              {fileAttribute?.AC ===
                                                attribute.attributeCode && (
                                                <li
                                                  className="list mt-1"
                                                  key={attributeIndex}
                                                >
                                                  <div className="media">
                                                    <div className="media-body text-truncate">
                                                      <span className="view-more">
                                                        {fileAttribute.Name}
                                                      </span>
                                                    </div>
                                                    {/*Here code for view same div of trash should be used. ISDOCVIEW/ISDOCDOWN*/}
                                                    {/* {
                                                      // section?.ISDOCVIEW &&
                                                      fileAttribute.DocId !==
                                                        0 && (
                                                        <div className="media-right ml-2">
                                                          <i
                                                            className="fa fa-eye"
                                                            aria-hidden="true"
                                                            onClick={() =>
                                                              handleViewForFlat(
                                                                fileAttribute,
                                                                attributeIndex
                                                              )
                                                            }></i>
                                                        </div>
                                                      )
                                                    } */}
                                                    {
                                                      //  section?.ISDOCDOWN &&
                                                      <div className="media-right ml-2">
                                                        <i
                                                          className="fa-solid fa-download"
                                                          aria-hidden="true"
                                                          onClick={() =>
                                                            handleDownloadFileFlat(
                                                              fileAttribute,
                                                              attributeIndex
                                                            )
                                                          }
                                                        ></i>
                                                      </div>
                                                    }
                                                    {/* <div className="media-right ml-2">
                                                          <i
                                                            className="fa fa-trash"
                                                            aria-hidden="true"
                                                            onClick={() =>
                                                              handleDeleteFileForFlat(
                                                                fileAttribute,
                                                                attributeIndex
                                                              )
                                                            }
                                                          ></i>
                                                      </div> */}
                                                  </div>
                                                </li>
                                              )}
                                            </>
                                          )
                                        )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}
                      </React.Fragment>
                    ))}
                </div>

                {progAttributesData["REQTYPOUPD"] != null &&
                  progAttributesData["REQRSNOUP"] != null &&
                  (
                    ["Separation","Termination","ResignationWithdraw"].includes(progAttributesData["REQTYPOUPD"].code)
                  ) && (
                    <>
                      {/* Reportee Manager */}
                      <div
                        className="row"
                        hidden={repMgMap.length > 0 ? false : true}>
                        <div className="col-lg-3 col-sm-3 col-xs-4">
                          <div className="mb-1">
                            <label className="col-form-label">
                              {"TRANSFER DIRECT REPORTEE"}
                            </label>
                            <sup>*</sup>
                            <SelectForm
                              isSearchable
                              isClearable
                              isDisabled={
                                ["Separation","Termination"].includes(progAttributesData["REQTYPOUPD"].code)
                                ? false 
                                : true
                              }
                              value={directReportee ? directReportee : null}
                              options={typeOptions}
                              placeholder={"Select a Value"}
                              onChange={async (e) => {
                                setDirectReportee(e);
                                if (e) {
                                  if (e.code == "TATUP") {
                                    if (
                                      attributesData["REQEMPLID"].mg != null
                                    ) {
                                      const mgObj =
                                        await getSearchableDropdownAPI(
                                          "",
                                          "MG",
                                          null,
                                          attributesData["REQEMPLID"].mg,
                                          "attribute",
                                          0
                                        );
                                      setManagerForReportee(mgObj);
                                    }
                                    setManagerDisable(true); //Set Upper position manager
                                  } else if (e.code == "ITTOM") {
                                    //managerForReportee
                                    setManagerForReportee(null);
                                    setManagerDisable(true);
                                  } else {
                                    setManagerForReportee(null);
                                    setManagerDisable(false);
                                  }
                                } else {
                                  setManagerForReportee(null);
                                  setManagerDisable(false);
                                }
                              }}
                              isMulti={false}
                              noIndicator={false}
                              noSeparator={false}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-xs-4">
                          <div className="mb-1">
                            <label className="col-form-label">
                              {"Manager Name"}
                            </label>
                            <sup>*</sup>
                            <SelectForm
                              isClearable
                              isSearchable
                              async
                              isDisabled={
                                managerDisable ? managerDisable :
                                ["Separation","Termination"].includes(progAttributesData["REQTYPOUPD"].code)
                                ? false 
                                : true
                              }
                              options={(searchString, cb) =>
                                getSearchableDropdownAPI(
                                  searchString,
                                  "MG",
                                  cb,
                                  "",
                                  "attribute",
                                  0
                                )
                              }
                              placeholder={"Select a Manager"}
                              value={managerForReportee}
                              onChange={(e) => {
                                setManagerForReportee(e);
                              }}
                              isMulti={false}
                              noIndicator={false}
                              noSeparator={false}
                            />
                          </div>
                        </div>
                      </div>
                      {reporteeManagerMap != null &&
                        reporteeManagerMap.length > 0 && (
                          <div className="row"
                          hidden={directReportee.code == "ITTOM" ? false : true}>
                            {reporteeManagerMap.map((eachRep) => (
                              <>
                                <div className="col-lg-3 col-sm-3 col-xs-4">
                                  <div className="mb-1">
                                    <label className="col-form-label">
                                      {"Reportee Name"}
                                    </label>
                                    <sup>*</sup>
                                    <SelectForm
                                      isClearable
                                      isSearchable
                                      isDisabled={
                                        ["Separation","Termination"].includes(progAttributesData["REQTYPOUPD"].code)
                                        ? false 
                                        : true
                                      }
                                      options={[]}
                                      placeholder={"Select a Manager"}
                                      value={eachRep}
                                      onChange={(e) => {}}
                                      isMulti={false}
                                      noIndicator={false}
                                      noSeparator={false}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-3 col-sm-3 col-xs-4">
                                  <div className="mb-1">
                                    <label className="col-form-label">
                                      {"Manager Name"}
                                    </label>
                                    <sup>*</sup>
                                    <SelectForm
                                      isClearable
                                      isSearchable
                                      isDisabled={
                                        ["Separation","Termination"].includes(progAttributesData["REQTYPOUPD"].code) 
                                        ? false 
                                        : true
                                      }
                                      async
                                      options={(searchString, cb) =>
                                        getSearchableDropdownAPI(
                                          searchString,
                                          "MG",
                                          cb,
                                          "",
                                          "attribute",
                                          0
                                        )
                                      }
                                      placeholder={"Select a Manager"}
                                      value={eachRep.mg ? eachRep.mg : null}
                                      onChange={(e) => {
                                        let tempAcc = [...reporteeManagerMap];
                                        let tempAtt = tempAcc.find(
                                          (item) => item.value == eachRep.value
                                        );
                                        tempAtt.mg = e;
                                        setReporteeManagerMap(tempAcc);
                                      }}
                                      isMulti={false}
                                      noIndicator={false}
                                      noSeparator={false}
                                    />
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                        )}

                      {/* Job Relation */}
                      <div
                        className="row"
                        hidden={empRlMap.length > 0 ? false : true}>
                        <div className="col-lg-3 col-sm-3 col-xs-4">
                          <div className="mb-1">
                            <label className="col-form-label">
                              {"Transfer Job Relationships"}
                            </label>
                            <sup>*</sup>
                            <SelectForm
                              isSearchable
                              isClearable
                              isDisabled={
                                ["Separation","Termination"].includes(progAttributesData["REQTYPOUPD"].code)
                                ? false 
                                : true
                              }
                              value={
                                directReporteeRel ? directReporteeRel : null
                              }
                              options={typeOptionsRel}
                              placeholder={"Select a Value"}
                              onChange={async (e) => {
                                setDirectReporteeRel(e);
                                if (e) {
                                  if (e.code == "RLTATUP") {
                                    if (
                                      attributesData["REQEMPLID"].mg != null
                                    ) {
                                      // code for get usermaster id using employeeid
                                      const umid = await APICall(
                                        getUsermasterIdBasedOnEid,
                                        "POST",
                                        {
                                          userid:
                                            attributesData["REQEMPLID"].mg,
                                        }
                                      );
                                      const mgObj =
                                        await getSearchableDropdownAPI(
                                          "",
                                          "RELNME",
                                          null,
                                          umid.data.relationId,
                                          "attribute",
                                          0
                                        );
                                      setRelationForReportee(mgObj);
                                    }
                                    setRelationDisable(true); //Set Upper position manager
                                  } else if (e.code == "RLITTOM") {
                                    //relationForReportee
                                    setRelationForReportee(null);
                                    setRelationDisable(true);
                                  } else if (e.code == "RLTAR") {
                                    //noRelationForReportee
                                    setRelationForReportee(null);
                                    setRelationDisable(true);
                                  } else {
                                    setRelationForReportee(null);
                                    setRelationDisable(false);
                                  }
                                } else {
                                  setRelationForReportee(null);
                                  setRelationDisable(false);
                                }
                              }}
                              isMulti={false}
                              noIndicator={false}
                              noSeparator={false}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-sm-3 col-xs-4">
                          <div className="mb-1">
                            <label className="col-form-label">
                              {"Search Users"}
                            </label>
                            <sup>*</sup>
                            <SelectForm
                              isClearable
                              isSearchable
                              isDisabled={
                                relationDisable 
                                ? relationDisable 
                                : ["Separation","Termination"].includes(progAttributesData["REQTYPOUPD"].code)
                                  ? false 
                                  : true
                              }
                              async
                              options={(searchString, cb) =>
                                getSearchableDropdownAPI(
                                  searchString,
                                  "RELNME", //Change this with "RELNME" once kiran sir's update is done
                                  cb,
                                  "",
                                  "attribute",
                                  0
                                )
                              }
                              placeholder={"Select a User"}
                              value={relationForReportee}
                              onChange={(e) => {
                                setRelationForReportee(e);
                              }}
                              isMulti={false}
                              noIndicator={false}
                              noSeparator={false}
                            />
                          </div>
                        </div>
                      </div>

                      {userRelationMap != null &&
                        userRelationMap.length > 0 && (
                          <div className="row"
                          hidden={directReporteeRel.code == "RLITTOM" ? false : true}>
                            {userRelationMap.map((eachRep) => (
                              <>
                                <div className="col-lg-3 col-sm-3 col-xs-4">
                                  <div className="mb-1">
                                    <label className="col-form-label">
                                      {"Reportee Name"}
                                    </label>
                                    <sup>*</sup>
                                    <SelectForm
                                      isClearable
                                      isSearchable
                                      isDisabled={true}
                                      options={[]}
                                      placeholder={"Select a Reportee"}
                                      value={eachRep.userid}
                                      onChange={(e) => {}}
                                      isMulti={false}
                                      noIndicator={false}
                                      noSeparator={false}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-3 col-sm-3 col-xs-4">
                                  <div className="mb-1">
                                    <label className="col-form-label">
                                      {"Realtion Type"}
                                    </label>
                                    <sup>*</sup>
                                    <SelectForm
                                      isClearable
                                      isSearchable
                                      isDisabled={true}
                                      async
                                      options={optForRoles}
                                      placeholder={"Select a Type"}
                                      value={
                                        eachRep.relationtype
                                          ? eachRep.relationtype
                                          : null
                                      }
                                      onChange={() => {}}
                                      isMulti={false}
                                      noIndicator={false}
                                      noSeparator={false}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-3 col-sm-3 col-xs-4">
                                  <div className="mb-1">
                                    <label className="col-form-label">
                                      {"Manager Name"}
                                    </label>
                                    <sup>*</sup>
                                    <SelectForm
                                      isClearable
                                      isSearchable
                                      isDisabled={
                                        ["Separation","Termination"].includes(progAttributesData["REQTYPOUPD"].code)
                                        ? false 
                                        : true
                                      }
                                      async
                                      options={(searchString, cb) =>
                                        getSearchableDropdownAPI(
                                          searchString,
                                          "RELNME", //Change this with "RELNME" once kiran sir's update is done
                                          cb,
                                          "",
                                          "attribute",
                                          0
                                        )
                                      }
                                      placeholder={"Select a Manager"}
                                      value={
                                        eachRep.relationid
                                          ? eachRep.relationid
                                          : null
                                      }
                                      onChange={(e) => {
                                        let tempAcc = [...userRelationMap];
                                        let tempAtt = tempAcc.find(
                                          (item) => item.id == eachRep.id
                                        );
                                        tempAtt.relationid = e;
                                        setUserRelationMap(tempAcc);
                                      }}
                                      isMulti={false}
                                      noIndicator={false}
                                      noSeparator={false}
                                    />
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                        )}
                    </>
                  )}
              </AccordionDetails>
            </Accordion>
          </>
        )}

        {
          //For Section and tab render
          ((finalformData != null &&
            finalformData.length > 0 &&
            sectionValuesArray.length > 0) ||
            (finalformData != null &&
              finalformData.length > 0 &&
              sectionValuesArray.length > 0)) &&
            finalformData.map((eachSection, index) => (
              <React.Fragment key={`${index}-${eachSection?.SN}`}>
                <SectionCompontentProgressionView
                  formData={finalformData}
                  setFormData={setFinalFormData}
                  section={eachSection}
                  sectionIndex={index}
                  TId={TId}
                  setTId={setTId}
                  sectionValuesArray={sectionValuesArray}
                  SetSectionValuesArray={setSectionValuesArray}
                  Globalv={globalValues}
                  COU={userDetails.COU}
                  submitFunc={false}
                  setGetData={[]}
                  allSectionData={allSectionsData}
                  payrolldd={progAttributesData["REQPLOCN"]}
                />
              </React.Fragment>
            ))
        }

        <Accordion
          elevation={0}
          className="mb-3"
          expanded={accordionWrkfl}
          onChange={() => setAccordionWrkfl((prev) => !prev)}>
          <AccordionSummary
            id="profile-accordion"
            style={{ background: "#3C5464" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}>
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <p style={{ fontSize: "16px" }}>Workflow</p>
              </div>
            </div>
          </AccordionSummary>

          <AccordionDetails className="page_heading">
            <div className="row">
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">Initiator</label>
                  <sup>*</sup>
                  <InputForm
                    value={
                      progAttributesData["WRKFLNM"]
                        ? progAttributesData["WRKFLNM"]
                        : ""
                    }
                    placeholder={"Initiator"}
                    isDisabled={true}
                    textArea={false}
                    label={"Initiator"}
                    onChange={""}
                  />
                </div>
              </div>

              {approvers &&
                approvers.length > 0 &&
                approvers.map(
                  (eachApprover, index) =>
                    index > 0 && (
                      <>
                        <div className="col-lg-3 col-sm-3 col-xs-4">
                          <div className="dropdown mb-1">
                            <label className="col-form-label">
                            {eachApprover?.roleName 
                              ? eachApprover?.roleName 
                              : `${"Approver"} ${index}`}
                            </label>
                            <sup>*</sup>
                            <SelectForm
                              isClearable
                              isSearchable
                              isDisabled={true}
                              value={
                                eachApprover?.approvers?.length > 0
                                  ? eachApprover?.approvers[0]
                                  : null
                              }
                              options={[]}
                              placeholder={"No Employee Found"}
                              onChange={(e) => {}}
                              isMulti={true}
                              noIndicator={false}
                              noSeparator={false}
                            />
                          </div>
                        </div>
                      </>
                    )
                )}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  };

export default ReviewerSectionClm;
