import {
  addContextToLocalStorage,
  deleteLocalStorage,
  getContext,
} from "../../Helpers/Context/Context";

const initialState: any = {};
const userData = async (state = initialState, action) => {
  switch (action.type) {
    case "getUserData":
      return action.payload;
    case "clearUserData":
      deleteLocalStorage();
      return {};
    case "setUserData":
      let newState = state;
      newState.userData = action.payload;
      addContextToLocalStorage(action.payload);
      return newState;
    default:
      return state;
  }
};

const updateReduxStore = (state: any, action: any) => {
  const context = getContext();
  state = context;
  switch (action.type) {
    case "UPDATE_ROLE":
      state.loggedInAs = action.payLoad;
      addContextToLocalStorage(state);
      return state;

    default:
      if (context != undefined) {
        return state;
      }
      return {};
  }
};

export { userData, updateReduxStore };
