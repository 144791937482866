import React, { useContext, useEffect, useState } from "react";
import AccordianCustom1 from "../DocumentScreen/AccordianCustom1";
import InputForm from "../../Components/InputForm/InputForm";
import SelectForm from "../../Components/SelectForm/SelectForm";
import { isEmpty } from "../../utils/isEmpty";
import { useNavigate } from "react-router-dom";
import { APICall } from "../../Helpers/APICalls";
import {
  GetAssociateDetailsById,
  GetAssociateDropdownData,
  GetAssociatesDocument,
  GetManageWernerSkillTest,
  GetWernerSkillTestData,
  GetWernerSkillTestDropDown,
  GetWernerSkillTestGradeChartData,
  GetWorkflowStages,
  InsertUpdateTokenIssuanceDetails,
  InsertUpdateCandidateWorkflowInstanceTasks,
  GetPositionDropdownData,
  GetPositionDetailsByPositionId,
  ManageWernerSkillTest,
  GetStageNameById,
  isOrganisationInfo,
} from "../../Helpers/APIEndPoints/AssociateEndPoints";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { LoaderContext } from "../../Helpers/Context/Context";
import QR from "../QRComponent/QR";
import defaultProfileImgPic from "../../Assets/Images/adhars.jpg";
import defaultProfileImgPic1 from "../../Assets/Images/default+_pic.jpg";
import { Modal } from "react-bootstrap";
import notify from "../../Helpers/ToastNotification";
import OrganizationInformation from "../../Components/OrganizationInformation/OrganizationInformation";
import SecurityAccordian from "../SecurityScreenings/SecurityAccordian";

interface IOptions {
  value?: number | string;
  label?: string;
  score?: string;
  grade?: string;
}

type FormErrors = {
  Rejection?: string;
  Decision?: string;
};

interface IFormData {
  pinBoardRH?: IOptions | Number | any;
  pinBoardLH?: IOptions | Number | any;
  pinBoardTotal?: Number | string | any;
  evaluationScorePinBoard?: Number | string | any;
  formBoardA?: IOptions | Number | any;
  formBoardB?: IOptions | Number | any;
  formBoardTotal?: Number | string | any;
  evaluationScoreFormBoard?: Number | string | any;
  perceptionTest?: IOptions | Number | any;
  evaluationScorePerception?: Number | string | any;
  division?: IOptions | Number | string | any;
  operation?: IOptions | Number | string | any;
  position_Id?: IOptions | boolean | any;
  categoryId?: any;
  currentStageId?: any;
  skillSubmitted?: boolean;
}

interface ITokenData {
  candidate_Id?: IOptions | boolean | any;
}

interface CandidateDetails {
  name?: string;
  mobileNumber?: string;
  category?: string | number | null;
  skillCategory?: string | number | null;
  workArea?: string | number | null;
}

const WernerSkillTest = () => {
  const navigate = useNavigate();
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  const { showLoader, hideLoader } = useContext(LoaderContext);

  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const [formOptions, setFormOptions] = useState<IFormData>({});

  const [formData, setFormData] = useState<IFormData>({});

  const [scanData, setScanData] = useState(null);

  const [candidateimage, setCandidateimage] = useState(null);

  const [tokenData, setTokenData] = useState<ITokenData>({});

  const [isRefresh, setIsRefresh] = useState<any>(false);

  const [startScan, setStartScan] = useState(false);

  const [tokenId, settokenId] = useState<any>(null);

  const [modalForGunScanner, setModalForGunScanner] = useState<boolean>(false);

  const handleWernerSkillTestSection = () => {};

  const [candidateDetails, setCandidateDetails] = useState<CandidateDetails>(
    {}
  );

  const [id, setId] = useState(0);

  const [gradeChartData, setGradeChartData] = useState([]);

  const [ShowGradeChartModel, setShowGradeChartModel] = useState(false);

  const [isReasonDisable, setIsReasonDisable] = useState(false);

  const [reasonOptions, setReasonOptions] = useState([
    { value: "No Combination found", label: "No Combination found" },
  ]);

  const [reason, setReason] = useState(null);

  const [showScoreOptions, setShowScoreOptions] = useState([
    { value: 1, label: "Pinboard", code: "PINBOARD" },
    { value: 2, label: "Formboard", code: "FORMBOARD" },
    { value: 3, label: "Perception", code: "PERCEPTION" },
  ]);

  const [showScore, setShowScore] = useState(null);

  const [Category, setCategory] = useState<any>(null);

  const [QRCode, setQRCode] = useState<any>(null);
  const [decision, setDecision] = useState<any>(null);
  const [Remark, setRemark] = useState<any>(null);
  const [positionText, setPositionText] = useState<any>({ name: "", id: 0 });
  const [empClass, setEmpClass] = useState<any>({ name: "", id: 0 });
  const [currentStageId, setCurrentStageId] = useState(null);
  const [IsProcessing, setIsProcessing] = useState<any>(null);
  const [disableDecision, setdisableDecision] = useState(true);
  const [isdecisiondisable, setIsDecisionDisable] = useState<any>(false);
  const [positionbutton, setpositionbutton] = useState(false);
  const [organizationDataSubmit, setOrganizationDataSubmit] = useState(false);
  const [enableOUSectionFields, setEnableOUSectionFields] = useState(false);
  const [skillsSubmitted, setIsSkillSubmitted] = useState(false);
  const [ouSubmitted, setOUSubmitted] = useState(false);

  const [DecisionOption, setDecisionOption] = useState([
    // { label: "Draft", value: 1 },
    // { label: "Issue Token", value: 2 },
    { label: "Rejected", value: 3 },
    { label: "Shortlisted", value: 4 },
  ]);

  const showScoreObj = {
    Pinboard: "PINBOARD",
    Formboard: "FORMBOARD",
    Perception: "PERCEPTION",
  };

  const categoryObj = {
    Experience: "On roll Experience",
    Fresher: "On roll Fresher",
    Rehire: "On roll Rehire",
    CLM: "Contract Worker",
    SchemeTrainee: "Scheme Trainee",
    SchemeApprentice: "Scheme Apprentice",
    ExperienceApprentice: "Experience Apprentice",
  };

  const [isSchemeTrainee, setIsSchemeTrainee] = useState(false);
  const [ispositionsubmited, setIspositionsubmited] = useState(false);
  const [isaccordiandisable, setIsAccordiandisable] = useState<any>(true);
  const [showcurrentstage, setShowCurrentStage] = useState<any>(false);
  const [currentStageName, setCurrentStageName] = useState(null);
  const [CategoryOption, setCategoryOption] = useState([
    { label: "Scheme Trainee", value: 5 },
    { label: "Scheme Apprentice", value: 6 },
  ]);
  const [categoryId, setCategoryId] = useState(null);
  const [positionId, setPositionId] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [IsOrganisationunitpopup, setisOrganisationunitpopup] =
    useState<any>(false);

  // useEffect(() => {
  //   const responseData = async () => {
  //     const parameters = {
  //       Mode: "DIVISION",
  //       UserId: userDetails?.Id,
  //       RoleId: currentRoleId?.value,
  //     };
  //     const res = await APICall(GetWernerSkillTestDropDown, "POST", parameters);

  //     if (res?.status === 0 && res?.data?.length > 0) {
  //       setFormOptions((env) => {
  //         return {
  //           ...env,
  //           division: res?.data,
  //         };
  //       });
  //     }

  //     parameters.Mode = "OPERATION";

  //     const res1 = await APICall(
  //       GetWernerSkillTestDropDown,
  //       "POST",
  //       parameters
  //     );

  //     if (res1?.status === 0 && res1?.data?.length > 0) {
  //       setFormOptions((env) => {
  //         return {
  //           ...env,
  //           operation: res1?.data,
  //         };
  //       });
  //     }
  //   };

  //   showLoader();

  //   responseData();

  //   hideLoader();
  // }, []);

  const WernerSkillTestForOperationDropDownApiCall = async (
    pinBoardPoint,
    formBoardPoint,
    perceptionPoint,
    divisionId = null
  ) => {
    const parameters = {
      Mode: "OPERATION",
      PinBoardPoint: pinBoardPoint,
      FormBoardPoint: formBoardPoint,
      PerceptionPoint: perceptionPoint,
      DivisionId: divisionId,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    };
    const res = await APICall(GetWernerSkillTestDropDown, "POST", parameters);

    if (res?.status === 0 && res?.data?.length > 0) {
      setFormOptions((env) => {
        return {
          ...env,
          operation: res?.data,
        };
      });

      const decisionObj = DecisionOption?.find((val) => val?.value === 4);

      setDecision(decisionObj);
      setIsDecisionDisable(true);

      setIsReasonDisable(false);

      return res?.data;
    } else if (res?.status === 1) {
      notify(res?.status, res?.message);
    } else {
      notify(1, "No Combination found");

      setIsReasonDisable(true);

      setFormOptions((env) => {
        return {
          ...env,
          operation: [],
        };
      });

      setReason(reasonOptions[0]);

      const decisionObj = DecisionOption?.find((val) => val?.value === 3);

      setDecision(decisionObj);
    }
  };

  const WernerSkillTestDropDownApiCall = async (
    mode,
    pinBoardPoint,
    formBoardPoint,
    perceptionPoint
  ) => {
    if (mode === "DIVISION") {
      const parameters = {
        Mode: "DIVISION",
        PinBoardPoint: pinBoardPoint,
        FormBoardPoint: formBoardPoint,
        PerceptionPoint: perceptionPoint,
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      };
      const res = await APICall(GetWernerSkillTestDropDown, "POST", parameters);

      if (res?.status === 0 && res?.data?.length > 0) {
        setFormOptions((env) => {
          return {
            ...env,
            division: res?.data,
          };
        });

        const decisionObj = DecisionOption?.find((val) => val?.value === 4);

        setDecision(decisionObj);
        setIsDecisionDisable(true);

        setIsReasonDisable(false);

        return res?.data;
      } else if (res?.status === 1) {
        notify(res?.status, res?.message);
      } else {
        notify(1, "No Combination found");

        setIsReasonDisable(true);

        setFormOptions((env) => {
          return {
            ...env,
            division: [],
            operation: [],
          };
        });

        setReason(reasonOptions[0]);

        const decisionObj = DecisionOption?.find((val) => val?.value === 3);

        setDecision(decisionObj);
      }
    }
  };

  async function handleSearchForCandidateIdDropdown(event, code, callback) {
    try {
      if (event?.length >= 1) {
        const data = await fetchCandidateDropdown(event, code);
        callback(data);
      } else {
        callback([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchCandidateDropdown(
    searchText = "",
    code = "",
    value = null
  ) {
    try {
      const response = await APICall(
        GetAssociateDropdownData,
        "POST",
        {
          SearchText: searchText,
        },
        true
      );

      if (response?.status === 0) {
        if (response?.data) {
          return response?.data;
        } else {
          return [];
        }
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  const handleScanData = (data) => {
    setScanData(JSON.parse(data));
    const response = JSON.parse(data);
    candidateProfile(response?.CandidateId);
  };
  const candidateProfile = async (id: any) => {
    const response = await APICall(GetAssociatesDocument, "POST", {
      AssociatesDetailsId: id,
      DocumentTypeId: 8,
    });

    if (response.data !== null) {
      console.log(response.data, "console");
      setCandidateimage(response?.data?.documentDataObject);
    }
  };

  function onTokenChange(event, code) {
    try {
      candidateProfile(event.id);
      setTokenData((prev) => {
        return { ...prev, [code]: event };
      });
    } catch (error) {
    } finally {
    }
  }

  useEffect(() => {
    const helper = async () => {
      if (scanData) {
        // let isOrganisationInf = await isOrganisationunit(scanData.CandidateId);
        // if (!isOrganisationInf) {
        //   setisOrganisationunitpopup(true);
        // } else {
        //   setisOrganisationunitpopup(false);
        await LoadUserData(scanData.CandidateId);
        setStartScan(false);
        // }
        // await getSkillTestDetails(skillCategory?.id ,scanData.CandidateId);
      }
    };
    helper();
  }, [scanData, isRefresh]);

  const isOrganisationunit = async (AssociateId: any) => {
    try {
      const { data } = await APICall(isOrganisationInfo, "POST", {
        TokenId: AssociateId,
        UserId: userDetails.Id,
      });

      if (data !== null) {
        console.log(data, "Organisationunitdata");
        return data.isExist;
      }
    } catch (error) {
      // Handle any errors that occur during the API call
      console.error("Error during API call:", error);
      return false; // or another appropriate default value
    }
  };

  const LoadUserData = async (id: any) => {
    settokenId(id);
    showLoader();

    const response = await APICall(GetAssociateDetailsById, "POST", {
      Id: id,
      WorkflowStageId: 6,
      CreatedBy: userDetails?.Id,
    });
    if (response.data != null) {
      const { data } = response;
      if (data) {
        setCandidateDetails((prev) => ({
          ...prev,
          category: data?.category || "",
          skillCategory: data?.skillCategory || "",
          workArea: data?.workAreaName || "",
          mobileNumber: data?.mobileNumber || "",
          name: data?.name || "",
        }));
      }

      QRCodeExist(id);
      setCategoryId(response?.data?.categoryId);
      setPositionId(response.data.positionId);

      setCategory(response?.data?.category);

      if (response?.data?.category === categoryObj.SchemeTrainee) {
        setIsSchemeTrainee(true);
      }

      setCurrentStageId(response?.data?.currentStagesId);
      if (response?.data?.currentStagesId !== 13) {
        setdisableDecision(true);
        setIsDecisionDisable(true);
        setIsAccordiandisable(true);
        setDisableSubmit(true);
      } else {
        setIsDecisionDisable(false);
        setdisableDecision(false);
      }

      await getManageWernerSkillTestApiCall(
        id,
        response?.data?.currentStagesId,
        response?.data?.categoryId,
        response.data.positionId
      );
      if (response.data.isProcessing === true) {
        setIsProcessing(0);
      }
    }
    hideLoader();
  };

  useEffect(() => {
    const fetchStages = async () => {
      if (scanData && currentStageId != 13) {
        let getWorkFlowStages = await APICall(GetStageNameById, "POST", {
          CurrentStagesId: currentStageId,
        });

        let currentStageName = "";
        if (getWorkFlowStages && getWorkFlowStages.status === 0) {
          if (getWorkFlowStages.data) {
            currentStageName = getWorkFlowStages.data.candidateCurrentstage;
            setCurrentStageName(currentStageName);
            setShowCurrentStage(true);
          }
        }
      }
    };

    fetchStages();
  }, [currentStageId, scanData?.CandidateId]);

  const getPositionbyId = async () => {
    const response = await APICall(GetPositionDetailsByPositionId, "POST", {
      PositionId: formData?.position_Id.value,
    });
    if (response?.status === 0) {
      setPositionText({
        name: response?.data?.positionText,
        id: response?.data?.positionId,
      });

      setEmpClass({
        name: response?.data?.employeeClass,
        id: response?.data?.employeeClassId,
        code: response?.data?.employeeClassCode,
      });
    }
  };

  useEffect(() => {
    const helperFunction = async () => {
      if (formData?.position_Id && formData?.position_Id !== null) {
        setpositionbutton(true);
        setEnableOUSectionFields(false);
        await getPositionbyId();
      }
    };
    helperFunction();
  }, [formData]);

  async function fetchDropdown(searchText = "", code = "", value = null) {
    try {
      const response = await APICall(
        GetPositionDropdownData,
        "POST",
        {
          SearchText: searchText,
        },
        true
      );

      if (response?.status === 0) {
        if (response?.data) {
          return response?.data;
        } else {
          return [];
        }
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  const QRCodeExist = async (id: any) => {
    const response = await APICall(GetAssociatesDocument, "POST", {
      AssociatesDetailsId: id,
      DocumentTypeId: 7,
    });
    console.log(response?.data);
    if (response.data !== null) {
      setQRCode(response.data?.documentDataObject);
    }
  };

  const getWernerSkillTestDataApiCall = async (mode, minValue, maxValue) => {
    try {
      showLoader();
      const response = await APICall(GetWernerSkillTestData, "POST", {
        Mode: mode,
        MinValue: minValue,
        MaxValue: maxValue,
      });
      if (response?.status === 0 && response?.data !== null) {
        return response?.data?.score;
      } else if (response?.status === 1) {
        notify(response?.status, response?.Message);
      } else {
        return 0;
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const GetWernerSkillTestGradeChartDataApiCall = async (mode) => {
    try {
      showLoader();
      const response = await APICall(GetWernerSkillTestGradeChartData, "POST", {
        Mode: mode,
      });
      if (response?.status === 0) {
        setGradeChartData(response?.data);
      } else if (response?.status === 1) {
        notify(response?.status, response?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const formOnChange = async (e, type) => {
    let pinBoardTotal = null;
    let formBoardTotal = null;
    let evaluationScorePinboard = null;
    let evaluationScoreFormboard = null;
    let evaluationScorePerception = null;
    switch (type) {
      case "pinBoardRH":
        if (/^[1-9][0-9]{0,5}$/.test(e.target.value) || e.target.value === "") {
          let pinBoardRHData = e.target.value;
          if (!isEmpty(e.target.value) && !isEmpty(formData?.pinBoardLH)) {
            pinBoardTotal =
              Number(e.target.value) + Number(formData?.pinBoardLH);
            evaluationScorePinboard = await getWernerSkillTestDataApiCall(
              "PINBOARD",
              Number(e.target.value),
              Number(formData?.pinBoardLH)
            );
          } else {
            pinBoardTotal = "";
            evaluationScorePinboard = "";
          }

          if (
            !isEmpty(evaluationScorePinboard) &&
            !isEmpty(formData.evaluationScoreFormBoard) &&
            !isEmpty(formData.evaluationScorePerception)
          ) {
            let totalPoint =
              evaluationScorePinboard +
              formData.evaluationScoreFormBoard +
              formData.evaluationScorePerception;

            await WernerSkillTestDropDownApiCall(
              "DIVISION",
              evaluationScorePinboard,
              formData.evaluationScoreFormBoard,
              formData.evaluationScorePerception
            );
          }
          setFormData({
            ...formData,
            pinBoardRH: pinBoardRHData,
            pinBoardTotal: pinBoardTotal,
            evaluationScorePinBoard: evaluationScorePinboard,
          });
        }
        break;
      case "pinBoardLH":
        if (/^[1-9][0-9]{0,5}$/.test(e.target.value) || e.target.value === "") {
          let pinBoardLHData = e.target.value;
          if (!isEmpty(formData?.pinBoardRH) && !isEmpty(e.target.value)) {
            pinBoardTotal =
              Number(formData?.pinBoardRH) + Number(e.target.value);
            evaluationScorePinboard = await getWernerSkillTestDataApiCall(
              "PINBOARD",
              Number(formData?.pinBoardRH),
              Number(e.target.value)
            );
          } else {
            pinBoardTotal = "";
            evaluationScorePinboard = "";
          }

          if (
            !isEmpty(evaluationScorePinboard) &&
            !isEmpty(formData.evaluationScoreFormBoard) &&
            !isEmpty(formData.evaluationScorePerception)
          ) {
            let totalPoint =
              evaluationScorePinboard +
              formData.evaluationScoreFormBoard +
              formData.evaluationScorePerception;

            await WernerSkillTestDropDownApiCall(
              "DIVISION",
              evaluationScorePinboard,
              formData.evaluationScoreFormBoard,
              formData.evaluationScorePerception
            );
          }
          setFormData({
            ...formData,
            pinBoardLH: pinBoardLHData,
            pinBoardTotal: pinBoardTotal,
            evaluationScorePinBoard: evaluationScorePinboard,
          });
        }
        break;

      case "formBoardA":
        if (/^[1-9][0-9]{0,5}$/.test(e.target.value) || e.target.value === "") {
          let formBoardAData = e.target.value;
          if (!isEmpty(e.target.value) && !isEmpty(formData?.formBoardB)) {
            formBoardTotal =
              Number(e.target.value) + Number(formData?.formBoardB);
            evaluationScoreFormboard = await getWernerSkillTestDataApiCall(
              "FORMBOARD",
              Number(e.target.value),
              Number(formData?.formBoardB)
            );
          } else {
            formBoardTotal = "";
            evaluationScoreFormboard = "";
          }

          if (
            !isEmpty(formData.evaluationScorePinBoard) &&
            !isEmpty(evaluationScoreFormboard) &&
            !isEmpty(formData.evaluationScorePerception)
          ) {
            let totalPoint =
              formData.evaluationScorePinBoard +
              evaluationScoreFormboard +
              formData.evaluationScorePerception;

            await WernerSkillTestDropDownApiCall(
              "DIVISION",
              formData.evaluationScorePinBoard,
              evaluationScoreFormboard,
              formData.evaluationScorePerception
            );
          }
          setFormData({
            ...formData,
            formBoardA: formBoardAData,
            formBoardTotal: formBoardTotal,
            evaluationScoreFormBoard: evaluationScoreFormboard,
          });
        }
        break;

      case "formBoardB":
        if (/^[1-9][0-9]{0,5}$/.test(e.target.value) || e.target.value === "") {
          let formBoardBData = e.target.value;
          if (!isEmpty(formData?.formBoardA) && !isEmpty(e.target.value)) {
            formBoardTotal =
              Number(formData?.formBoardA) + Number(e.target.value);
            evaluationScoreFormboard = await getWernerSkillTestDataApiCall(
              "FORMBOARD",
              Number(formData?.formBoardA),
              Number(e.target.value)
            );
          } else {
            formBoardTotal = "";
            evaluationScoreFormboard = "";
          }

          if (
            !isEmpty(formData.evaluationScorePinBoard) &&
            !isEmpty(evaluationScoreFormboard) &&
            !isEmpty(formData.evaluationScorePerception)
          ) {
            let totalPoint =
              formData.evaluationScorePinBoard +
              evaluationScoreFormboard +
              formData.evaluationScorePerception;

            await WernerSkillTestDropDownApiCall(
              "DIVISION",
              formData.evaluationScorePinBoard,
              evaluationScoreFormboard,
              formData.evaluationScorePerception
            );
          }
          setFormData({
            ...formData,
            formBoardB: formBoardBData,
            formBoardTotal: formBoardTotal,
            evaluationScoreFormBoard: evaluationScoreFormboard,
          });
        }
        break;
      case "perceptionTest":
        if (/^[1-9][0-9]{0,5}$/.test(e.target.value) || e.target.value === "") {
          let perceptionTestData = e.target.value;

          if (!isEmpty(e.target.value)) {
            evaluationScorePerception = await getWernerSkillTestDataApiCall(
              "PERCEPTION",
              Number(e.target.value),
              Number(e.target.value)
            );
          } else {
            evaluationScorePerception = "";
          }
          if (
            !isEmpty(formData.evaluationScorePinBoard) &&
            !isEmpty(formData.evaluationScoreFormBoard) &&
            !isEmpty(evaluationScorePerception)
          ) {
            let totalPoint =
              formData.evaluationScorePinBoard +
              formData.evaluationScoreFormBoard +
              evaluationScorePerception;

            await WernerSkillTestDropDownApiCall(
              "DIVISION",
              formData.evaluationScorePinBoard,
              formData.evaluationScoreFormBoard,
              evaluationScorePerception
            );
          }
          setFormData({
            ...formData,
            perceptionTest: perceptionTestData,
            evaluationScorePerception: evaluationScorePerception,
          });
        }
        break;
      case "division":
        if (
          !isEmpty(formData?.evaluationScorePinBoard) &&
          !isEmpty(formData?.evaluationScoreFormBoard) &&
          !isEmpty(formData?.evaluationScorePerception)
        ) {
          if (e?.value > 0) {
            await WernerSkillTestForOperationDropDownApiCall(
              formData.evaluationScorePinBoard,
              formData.evaluationScoreFormBoard,
              formData?.evaluationScorePerception,
              e?.value
            );
          } else {
            setFormOptions((env) => {
              return {
                ...env,
                operation: [],
              };
            });
          }
        }
        setFormData({
          ...formData,
          division: e,
          operation: null,
        });
        break;
      case "operation":
        setFormData({
          ...formData,
          operation: e,
        });
        break;

      default:
        break;
    }
  };

  const handleShowScoreOnChange = async (event) => {
    setGradeChartData([]);
    setShowScore(event);

    await GetWernerSkillTestGradeChartDataApiCall(event?.code);
  };

  const handleGradeChartModel = async () => {
    setShowScore(showScoreOptions[0]);
    await GetWernerSkillTestGradeChartDataApiCall(showScoreOptions[0]?.code);
    setShowGradeChartModel(true);
  };

  const validateWernerSkillTest = () => {
    let errorObj = {};
    let isError = false;

    setFormErrors({});

    if (isEmpty(formData?.pinBoardRH)) {
      errorObj = {
        ...errorObj,
        ["pinBoardRH"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["pinBoardRH"]: "",
      };
    }

    if (isEmpty(formData?.pinBoardLH)) {
      errorObj = {
        ...errorObj,
        ["pinBoardLH"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["pinBoardLH"]: "",
      };
    }

    if (isEmpty(formData?.formBoardA)) {
      errorObj = {
        ...errorObj,
        ["formBoardA"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["formBoardA"]: "",
      };
    }

    if (isEmpty(formData?.formBoardB)) {
      errorObj = {
        ...errorObj,
        ["formBoardB"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["formBoardB"]: "",
      };
    }

    if (isEmpty(formData?.perceptionTest)) {
      errorObj = {
        ...errorObj,
        ["perceptionTest"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["perceptionTest"]: "",
      };
    }

    if (isEmpty(formData?.division)) {
      errorObj = {
        ...errorObj,
        ["division"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["division"]: "",
      };
    }

    if (isEmpty(formData?.operation)) {
      errorObj = {
        ...errorObj,
        ["operation"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["operation"]: "",
      };
    }

    const isEmptyObj = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmptyObj) {
      isError = true;
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  const handleSubmitWernerSkillTest = async () => {
    try {
      showLoader();

      if (tokenId !== null || tokenId > 0) {
        if (validateWernerSkillTest()) {
          hideLoader();
          return;
        }

        const parameters = {
          Id: id,
          TokenId: tokenId,
          PinBoardRH: formData?.pinBoardRH,
          PinBoardLH: formData?.pinBoardLH,
          PinBoardTotal: formData?.pinBoardTotal,
          EvaluationScorePinBoard: formData?.evaluationScorePinBoard,
          FormBoardA: formData?.formBoardA,
          FormBoardB: formData?.formBoardB,
          FormBoardTotal: formData?.formBoardTotal,
          EvaluationScoreFormBoard: formData?.evaluationScoreFormBoard,
          PerceptionTest: formData?.perceptionTest,
          EvaluationScorePerception: formData?.evaluationScorePerception,
          DivisionId: formData?.division?.value,
          OperationId: formData?.operation?.value,
          UserId: userDetails?.Id,
        };

        const response = await APICall(
          ManageWernerSkillTest,
          "POST",
          parameters
        );

        if (response?.status === 0) {
          notify(response?.status, response?.message);
          setId(response?.data?.id);
          setIsSkillSubmitted(true);
          await getManageWernerSkillTestApiCall(
            tokenId,
            currentStageId,
            categoryId,
            positionId
          );
        } else if (response?.status === 1) {
          setEnableOUSectionFields(false);
          notify(response?.status, response?.message);
        }
      } else {
        notify(1, "Token Id is null");
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const submitDecision = async () => {
    let errors: FormErrors = {};
    let isValid = true;
    if (decision.value == "3") {
      //if (Rejection == null || Rejection?.value === "") {
      //  errors.Rejection = "Reason of regection is required";
      //  isValid = false;
      //}
    }

    if (!decision || !decision.value) {
      errors.Decision = "Decision is required";
      isValid = false;
    }

    if (!skillsSubmitted || !ouSubmitted) {
      isValid = false;
      notify(1, "Please save all sections!");
    }

    if (isValid) {
      let stageId = null;

      let getWorkFlowStages = await APICall(GetWorkflowStages, "POST", {
        employeeClass: empClass.code,
        CategoryId: categoryId,
      });

      if (getWorkFlowStages && getWorkFlowStages.status === 0) {
        if (getWorkFlowStages.data && getWorkFlowStages.data.length > 0) {
          let stages = getWorkFlowStages.data;
          let currentStage = stages.find((x) => x.id === currentStageId);
          if (currentStage) {
            let nextStageId = stages.find(
              (x) => x.sequence > currentStage.sequence
            );
            if (nextStageId) {
              stageId = nextStageId.id;
            }
          }
        }
      }

      let requestParams = {
        AssociateId: tokenId || 0,
        StageId: decision.value == "3" ? currentStageId : stageId,
        WorkflowStageId: 6,
        Decision: decision?.value,
        RejectionReason: null, //Rejection?.value,
        Remarks: Remark,
        IsActive: 1,
        CreatedBy: userDetails?.Id,
        IsProcessing: IsProcessing || 0,
      };
      const response = await APICall(
        InsertUpdateTokenIssuanceDetails,
        "POST",
        requestParams
      );

      if (response.status === 0) {
        if (decision?.value === 4) {
          let currentStageId = 0;
          let stages = getWorkFlowStages.data;
          let currentStage = stages.find((x) => x.id === stageId);
          if (currentStage) {
            currentStageId = currentStage.id;
            let nextStageId = stages.find(
              (x) => x.sequence > currentStage.sequence
            );
            if (nextStageId) {
              stageId = nextStageId.id;
            }
          }

          const insertUpdateCandidateWorkflowInstanceTasks = await APICall(
            InsertUpdateCandidateWorkflowInstanceTasks,
            "POST",
            {
              Id: scanData?.CandidateId || tokenId,
              CurrentStagesId: currentStageId,
              NextStageId: stageId,
              Decision: decision?.value,
              CategoryId: formData?.categoryId,
              CreatedBy: userDetails?.Id || proxyUserDetails?.value,
            }
          );
        }

        notify(
          0,
          `The data for Candidate ID ${tokenId} has been successfully submitted. You can proceed for next candidate`
        );
        window.location.reload();
      } else {
        notify(1, "Data not inserted");
      }
    } else {
      setFormErrors(errors);
    }
  };

  const getManageWernerSkillTestApiCall = async (
    tokenId,
    currentStageId,
    categoryId,
    resPositionId
  ) => {
    try {
      showLoader();

      if (tokenId > 0) {
        const response = await APICall(GetManageWernerSkillTest, "POST", {
          TokenId: tokenId,
        });

        let position = null;
        if (resPositionId !== null) {
          const position_Ids = await fetchDropdown(
            resPositionId,
            "co_ordinator"
          );

          position = position_Ids[0];
          setIsAccordiandisable(true);
          setIsDecisionDisable(true);
          setOUSubmitted(true);
          if (currentStageId === 13) {
            setdisableDecision(false);
          } else {
            setdisableDecision(true);
          }
        }

        if (response?.status === 0 && response?.data !== null) {
          const WernerSkillTesData = response?.data;

          const totalPoint =
            WernerSkillTesData?.evaluationScorePinBoard +
            WernerSkillTesData?.evaluationScoreFormBoard +
            WernerSkillTesData?.evaluationScorePerception;

          const divisionArray = await WernerSkillTestDropDownApiCall(
            "DIVISION",
            WernerSkillTesData?.evaluationScorePinBoard,
            WernerSkillTesData?.evaluationScoreFormBoard,
            WernerSkillTesData?.evaluationScorePerception
          );

          const operationArray =
            await WernerSkillTestForOperationDropDownApiCall(
              WernerSkillTesData?.evaluationScorePinBoard,
              WernerSkillTesData?.evaluationScoreFormBoard,
              WernerSkillTesData?.evaluationScorePerception,
              WernerSkillTesData?.divisionId
            );

          const divisionObj = divisionArray?.find(
            (val) => val?.value === WernerSkillTesData?.divisionId
          );
          const operationObj = operationArray?.find(
            (val) => val?.value === WernerSkillTesData?.operationId
          );

          setFormData((env) => {
            return {
              ...formData,
              pinBoardRH: WernerSkillTesData?.pinBoardRH,
              pinBoardLH: WernerSkillTesData?.pinBoardLH,
              pinBoardTotal: WernerSkillTesData?.pinBoardTotal,
              evaluationScorePinBoard:
                WernerSkillTesData?.evaluationScorePinBoard,
              formBoardA: WernerSkillTesData?.formBoardA,
              formBoardB: WernerSkillTesData?.formBoardB,
              formBoardTotal: WernerSkillTesData?.formBoardTotal,
              evaluationScoreFormBoard:
                WernerSkillTesData?.evaluationScoreFormBoard,
              perceptionTest: WernerSkillTesData?.perceptionTest,
              evaluationScorePerception:
                WernerSkillTesData?.evaluationScorePerception,
              division: divisionObj,
              operation: operationObj,
              currentStageId: currentStageId,
              categoryId: categoryId,
              skillSubmitted: WernerSkillTesData ? true : false,
              position_Id: position,
            };
          });
          setEnableOUSectionFields(true);
          //setDisableSubmit(true);
          setIsSkillSubmitted(true);
        } else if (response?.status === 1) {
          setFormData((env) => {
            return {
              ...formData,
              currentStageId: currentStageId,
              categoryId: categoryId,
              skillSubmitted: false,
              position_Id: position || "",
            };
          });
          notify(response?.status, response?.Message);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const handleResetData = () => {
    setFormErrors({});
    setIsReasonDisable(false);
    setFormOptions((env) => {
      return {
        ...env,
        division: [],
        operation: [],
      };
    });
    setFormData((env) => {
      return {
        ...formData,
        pinBoardRH: "",
        pinBoardLH: "",
        pinBoardTotal: "",
        evaluationScorePinBoard: "",
        formBoardA: "",
        formBoardB: "",
        formBoardTotal: "",
        evaluationScoreFormBoard: "",
        perceptionTest: "",
        evaluationScorePerception: "",
        division: null,
        operation: null,
      };
    });
  };

  const handleSubmitOrganizationDataCompletion = (positionInfo) => {
    if (positionInfo?.status === "success") {
      setEmpClass({ code: positionInfo?.employeeClassCode });
      //setLegalEntity({name: positionInfo?.legalEntityName});
      setCategory(
        CategoryOption.find((x) => x.value === positionInfo?.categoryId)?.label
      );

      const updateWorkflowTaskInstance = async () => {
        let currentId = 1;
        let StageId = 1;

        let getWorkFlowStages = await APICall(GetWorkflowStages, "POST", {
          employeeClass: positionInfo?.employeeClassCode,
          CategoryId: positionInfo?.categoryId,
        });

        let stages = getWorkFlowStages.data;
        let currentStage = stages.find((x) => x.id === currentStageId);
        if (currentStage) {
          currentId = currentStage.id;
          let nextStageId = stages.find(
            (x) => x.sequence > currentStage.sequence
          );
          if (nextStageId) {
            StageId = nextStageId.id;
          }
        }

        const insertUpdateCandidateWorkflowInstanceTasks = await APICall(
          InsertUpdateCandidateWorkflowInstanceTasks,
          "POST",
          {
            Id: tokenId,
            CurrentStagesId: currentId,
            NextStageId: StageId,
            Decision: 1,
            CategoryId: positionInfo?.categoryId,
          }
        );
      };

      setOUSubmitted(true);

      setIspositionsubmited(true);
      setIsAccordiandisable(true);
    } else if (positionInfo?.status === "error") {
      setIspositionsubmited(false);
      setIsAccordiandisable(false);
    } else if (positionInfo?.status === "skip") {
      setCategory(
        CategoryOption.find((x) => x.value === positionInfo?.categoryId)
      );
      setIspositionsubmited(true);
      setIsAccordiandisable(true);
    }
  };

  const handleGetPositionDataCompletion = () => {
    let positiontype = true;
    if (currentStageId === 13) {
      if (positionId) {
        setIsAccordiandisable(true);
      } else {
        setIsAccordiandisable(false);
      }
    } else {
      if (positiontype) {
        if (currentStageId != 13 && currentStageId != null) {
          setIsAccordiandisable(true);
        } else {
          if (positionId) {
            setIsAccordiandisable(true);
          } else {
            setIsAccordiandisable(false);
          }
        }
      } else {
        setIsAccordiandisable(true);
      }
    }
  };

  return (
    <>
      <div className="form-main">
        <div className="page-title w-100">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <h4>
                  {" "}
                  <i
                    className="fa-solid fa-home pr-1"
                    style={{
                      color: "white",
                      cursor: "pointer",
                      fontSize: "13px",
                      verticalAlign: "middle",
                    }}
                    onClick={() => {
                      navigate("/Bluecollar");
                    }}
                  ></i>
                  Werner Skill Test
                </h4>
              </div>
              <div className="offset-lg-6 "></div>
              {/* <div
                className="col-lg-2 d-flex align-items-center justify-content-end"
                style={{ width: "200px" }}
              >
                <SelectForm
                  isClearable
                  async
                  options={(searchString, cb) => {
                    handleSearchForCandidateIdDropdown(
                      searchString,
                      "candidateId",
                      cb
                    );
                  }}
                  placeholder={"Select candidate Id"}
                  onChange={(val: any) => {
                    if (val !== null) {
                      // Check if the selected value is not null
                      onTokenChange(val, "candidate_Id");
                      LoadUserData(val.id);
                    }
                  }}
                  isMulti={false}
                  noIndicator={false}
                  noSeparator={false}
                  value={tokenData?.candidate_Id}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-2" id="blue-collar-pages">
        <div className="row">
          <div className="col-md-4 d-flex justify-content-center align-items-start">
            <div className="p-3 text-center">
              {startScan ? (
                <QR startScanOps={startScan} onScan={handleScanData} />
              ) : (
                <img
                  src={QRCode || defaultProfileImgPic}
                  className="card-img-top"
                  alt="Thumbnail Image"
                />
              )}

              <div className="SectionSubmit clearfix mt-2">
                {/* <button
                  onClick={() => {
                    //onResetData();
                  }}
                  disabled={false}
                  className="btn btn-secondary float-right ml-2"
                >
                  <i className="fa-solid fa-refresh"></i> Reset
                </button> */}
                <button
                  onClick={() => {
                    setModalForGunScanner(true);
                  }}
                  disabled={false}
                  className="btn btn-primary mr-2"
                >
                  {<i className="fa-solid fa-qrcode mr-2"></i>}
                  QR-Scan gun
                </button>
                <button
                  onClick={() => {
                    setStartScan(!startScan);
                  }}
                  disabled={false}
                  className="btn btn-primary ml-2 "
                >
                  <i className="fas fa-camera mr-2"></i> QR-Camera
                </button>
                <div className="col-lg-10 col-sm-3 col-xs-4"></div>
              </div>
            </div>
          </div>

          <div className="col-md-3 d-flex justify-content-center align-items-start">
            <div className="p-3 text-center">
              <img
                src={candidateimage ? candidateimage : defaultProfileImgPic1}
                className="card-img-top"
                alt="Thumbnail Image"
              />
            </div>
          </div>
          <div className="col-md-5">
            <div className="row">
              <div className="col-md-6 mb-1">
                <label className="col-form-label">Token Id</label>
                <sup></sup>
                <InputForm
                  className="form-control"
                  placeholder="Token Id"
                  isDisabled={true}
                  onChange={""}
                  textArea={false}
                  value={tokenId || ""}
                  maxLength="255"
                />
              </div>
              <div className="col-md-6">
                <label className="col-form-label">Name</label>
                <sup></sup>
                <InputForm
                  className="form-control"
                  placeholder="Name"
                  isDisabled={true}
                  onChange={""}
                  textArea={false}
                  value={candidateDetails?.name || ""}
                  //  value={"Mallesh Fakkirappa Dollin"}
                  maxLength="255"
                />
              </div>

              <div className="col-md-6">
                <label className="col-form-label">Mobile Number</label>
                <sup></sup>
                <InputForm
                  className="form-control"
                  placeholder="Mobile Number"
                  isDisabled={true}
                  onChange={""}
                  textArea={false}
                  value={candidateDetails?.mobileNumber || ""}
                  //  value={"Mallesh Fakkirappa Dollin"}
                  maxLength="255"
                />
              </div>

              <div className="col-md-6">
                <label className="col-form-label">
                  Category <span style={{ color: "red" }}>*</span>
                </label>
                <sup></sup>
                {/* <SelectForm
                  value={Category}
                  placeholder={"Select Category"}
                  options={CategoryOption}
                  onChange={(event) => {
                    setCategory(event);
                    // onChange(event, "journey_by_courseid");
                  }}
                  isDisabled={false}
                  noIndicator={false}
                  noSeparator={false}
                /> */}
                <InputForm
                  className="form-control"
                  placeholder="Category"
                  isDisabled={true}
                  onChange={""}
                  textArea={false}
                  value={Category}
                  //  value={"Mallesh Fakkirappa Dollin"}
                  maxLength="255"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12 mt-2">
          <AccordianCustom1
            header={"Skill Test"}
            submitFunc={handleWernerSkillTestSection}
            btnDisable={false}
            hideBtn={true}
          >
            <div className="SectionSubmit mb-2 clearfix">
              <button
                onClick={() => {
                  handleGradeChartModel();
                }}
                disabled={false}
                className="btn btn-primary float-right ml-2 mt-2"
              >
                {/* <i className="fa fa-plus mr-2"></i> */}
                Grade Chart
              </button>
            </div>
            <div className="row">
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Pin Board (RH)
                    <sup>*</sup>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Pin Board (RH)"}
                    isDisabled={false}
                    textArea={false}
                    value={formData?.pinBoardRH}
                    onChange={(e: any) => {
                      formOnChange(e, "pinBoardRH");
                    }}
                  />
                  {formErrors["pinBoardRH"] && (
                    <p style={{ color: "red" }}>{formErrors["pinBoardRH"]}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">Pin Board (LH)</label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control"
                    placeholder={"Pin Board (LH)"}
                    isDisabled={false}
                    textArea={false}
                    value={formData?.pinBoardLH}
                    onChange={(e: any) => {
                      formOnChange(e, "pinBoardLH");
                    }}
                  />
                  {formErrors["pinBoardLH"] && (
                    <p style={{ color: "red" }}>{formErrors["pinBoardLH"]}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">Pin Board Total</label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control"
                    placeholder={"Pin Board Total"}
                    isDisabled={true}
                    textArea={false}
                    value={formData?.pinBoardTotal}
                    onChange={(e: any) => {
                      formOnChange(e, "pinBoardTotal");
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Evaluation Score - PinBoard
                  </label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control"
                    placeholder={"Evaluation Score - PinBoard"}
                    isDisabled={true}
                    textArea={false}
                    value={formData?.evaluationScorePinBoard}
                    onChange={(e: any) => {
                      formOnChange(e, "evaluationScorePinBoard");
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    FormBoard (A) 100ᵗʰ/min
                    <sup>*</sup>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={`FormBoard (B) 100ᵗʰ/min`}
                    isDisabled={false}
                    textArea={false}
                    value={formData?.formBoardA}
                    onChange={(e: any) => {
                      formOnChange(e, "formBoardA");
                    }}
                  />
                  {formErrors["formBoardA"] && (
                    <p style={{ color: "red" }}>{formErrors["formBoardA"]}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    FormBoard (B) 100ᵗʰ/min
                    <sup>*</sup>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={`FormBoard (B) 100ᵗʰ/min`}
                    isDisabled={false}
                    textArea={false}
                    value={formData?.formBoardB}
                    onChange={(e: any) => {
                      formOnChange(e, "formBoardB");
                    }}
                  />
                  {formErrors["formBoardB"] && (
                    <p style={{ color: "red" }}>{formErrors["formBoardB"]}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">FormBoard Total(A+B)</label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control"
                    placeholder={`FormBoard Total(A+B)`}
                    isDisabled={true}
                    textArea={false}
                    value={formData?.formBoardTotal}
                    onChange={(e: any) => {
                      formOnChange(e, "formBoardTotal");
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Evaluation Score - Form Board
                  </label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control"
                    placeholder={`Evaluation Score - Form Board`}
                    isDisabled={true}
                    textArea={false}
                    value={formData?.evaluationScoreFormBoard}
                    onChange={(e: any) => {
                      formOnChange(e, "evaluationScoreFormBoard");
                    }}
                  />
                </div>
              </div>
              {/* <div className="col-lg-6 col-sm-6 col-xs-6"></div> */}
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Perception Test(Total Corrent)
                    <sup>*</sup>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={`Perception Test(Total Corrent)`}
                    isDisabled={false}
                    textArea={false}
                    value={formData?.perceptionTest}
                    onChange={(e: any) => {
                      formOnChange(e, "perceptionTest");
                    }}
                  />
                  {formErrors["perceptionTest"] && (
                    <p style={{ color: "red" }}>
                      {formErrors["perceptionTest"]}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Evaluation Score - Perception
                  </label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control"
                    placeholder={`Perception Test(Total Corrent)`}
                    isDisabled={true}
                    textArea={false}
                    value={formData?.evaluationScorePerception}
                    onChange={(e: any) => {
                      formOnChange(e, "evaluationScorePerception");
                    }}
                  />
                </div>
              </div>

              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Selected for Division
                  </label>
                  <sup>*</sup>

                  <SelectForm
                    isClearable
                    isSearchable
                    options={formOptions?.division}
                    placeholder={"Selected for Division"}
                    isDisabled={false}
                    value={formData?.division}
                    onChange={async (e) => {
                      formOnChange(e, "division");
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  {formErrors["division"] && (
                    <p style={{ color: "red" }}>{formErrors["division"]}</p>
                  )}
                </div>
              </div>
              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Selected for Operation
                  </label>
                  <sup>*</sup>

                  <SelectForm
                    isClearable
                    isSearchable
                    options={formOptions?.operation}
                    placeholder={"Selected for Operation"}
                    isDisabled={false}
                    value={formData?.operation}
                    onChange={async (e) => {
                      formOnChange(e, "operation");
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  {formErrors["operation"] && (
                    <p style={{ color: "red" }}>{formErrors["operation"]}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="SectionSubmit mb-2 clearfix">
              <button
                onClick={() => {
                  handleSubmitWernerSkillTest();
                }}
                disabled={disableSubmit}
                className="btn btn-filter-submit float-right ml-2 mt-4"
              >
                <i className="fa fa-save"></i> Submit
              </button>

              <button
                onClick={() => {
                  handleResetData();
                }}
                disabled={false}
                className="btn btn-secondary float-right ml-2 mt-4"
              >
                <i className="fa-solid fa-refresh"></i> Reset
              </button>
            </div>
          </AccordianCustom1>

          <SecurityAccordian
            header={"Organization Information"}
            submitFunc={async (event) => {
              event?.stopPropagation();
              //              submitOrganisationData();
              setOrganizationDataSubmit(true);
            }}
            btnDisable={isaccordiandisable ? true : false}
          >
            <OrganizationInformation
              positionScanned={positionbutton}
              formData={formData}
              enableOUSection={enableOUSectionFields}
              onSubmitData={handleSubmitOrganizationDataCompletion}
              associateId={tokenId || 0}
              submitData={organizationDataSubmit}
              onGetPositionData={handleGetPositionDataCompletion}
            ></OrganizationInformation>
          </SecurityAccordian>

          <AccordianCustom1
            header={"Decision"}
            submitFunc={() => {}}
            //clearFunc={""}
            btnDisable={false}
            hideBtn={true}
          >
            <div className="row">
              <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                <label className="col-form-label">
                  Decision <span style={{ color: "red" }}>*</span>
                </label>
                <sup></sup>
                <SelectForm
                  isClearable
                  options={DecisionOption}
                  placeholder={"Select Decision"}
                  isDisabled={isdecisiondisable || isReasonDisable}
                  onChange={(e) => {
                    setDecision(e);
                  }}
                  isMulti={false}
                  noIndicator={false}
                  noSeparator={false}
                  value={decision}
                />
                {formErrors.Decision && (
                  <p style={{ color: "red" }}>{formErrors.Decision}</p>
                )}
              </div>

              {isReasonDisable && (
                <>
                  <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                    <label className="col-form-label">
                      Reason <span style={{ color: "red" }}>*</span>
                    </label>
                    <sup></sup>
                    <SelectForm
                      isClearable
                      options={reasonOptions}
                      placeholder={"Select Reason"}
                      isDisabled={isReasonDisable}
                      onChange={(e) => {
                        setReason(e);
                      }}
                      isMulti={false}
                      noIndicator={false}
                      noSeparator={false}
                      value={reason}
                    />
                  </div>
                </>
              )}

              <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                <label className="col-form-label">Remark</label>

                <InputForm
                  className="form-control"
                  placeholder="Enter Remark"
                  isDisabled={false}
                  onChange={(e) => {
                    setRemark(e.target.value);
                  }}
                  textArea={true}
                  value={Remark}
                  maxLength="255"
                />
              </div>
            </div>
            <div className="SectionSubmit clearfix">
              <button
                onClick={submitDecision}
                disabled={disableDecision ? true : false}
                className="btn btn-filter-submit float-right mt-3"
              >
                <i className="fa-solid fa-check mr-2"></i> Proceed
              </button>
            </div>
          </AccordianCustom1>
        </div>
      </div>

      {/* Gun Scanner Model */}

      <Modal
        show={modalForGunScanner}
        onHide={() => {
          setModalForGunScanner(false);
        }}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Scan data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>{`Scan Data using scanner gun.`}</h4>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      {/* Grade Chart Model */}

      <Modal
        show={ShowGradeChartModel}
        onHide={() => setShowGradeChartModel(false)}
        backdrop="static"
        keyboard={false}
        size="xl"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">Grade Chart</div>
        </div>
        <Modal.Body>
          <>
            <div className="row mx-auto ">
              <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">Show Score</label>
                  <SelectForm
                    isClearable
                    isSearchable
                    options={showScoreOptions}
                    placeholder={"Show Score"}
                    isDisabled={false}
                    value={showScore}
                    onChange={(e) => {
                      handleShowScoreOnChange(e);
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
              </div>
            </div>
            <div className="row mx-auto ">
              <div className="col-lg-10 col-sm-10 col-xs-10 mb-3 pt-3">
                <table className="text-align-center table_grid skill-tbl">
                  <thead>
                    {showScore?.code === showScoreObj?.Pinboard && (
                      <>
                        {" "}
                        <tr>
                          <th>Pinboard Min</th>
                          <th>Pinboard Max</th>
                          <th>Score</th>
                          <th>Grade</th>
                        </tr>
                      </>
                    )}
                    {showScore?.code === showScoreObj?.Formboard && (
                      <>
                        {" "}
                        <tr>
                          <th>Form board Min</th>
                          <th>Form board Max</th>
                          <th>Score</th>
                          <th>Grade</th>
                        </tr>
                      </>
                    )}
                    {showScore?.code === showScoreObj?.Perception && (
                      <>
                        {" "}
                        <tr>
                          <th>Perception Min</th>
                          <th>Perception Max</th>
                          <th>Score</th>
                          <th>Grade</th>
                        </tr>
                      </>
                    )}
                  </thead>
                  <tbody>
                    {gradeChartData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.minValue}</td>
                        <td>{item.maxValue}</td>
                        <td>{item.score}</td>
                        <td>{item.grade}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowGradeChartModel(false);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showcurrentstage}
        onHide={() => {
          setShowCurrentStage(false);
        }}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Current Stage</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>{`You currently belong to ${currentStageName}`}</h4>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        show={IsOrganisationunitpopup}
        onHide={() => {
          setisOrganisationunitpopup(false);
        }}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Organisation Unit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>{`Candidate does not belong to Organisation Unit.`}</h4>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default WernerSkillTest;
