import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PdfViewer from "../../Components/PdfViewer/PdfViewer";

const contentObj = {
  Scrome: "SCROME",
  Audio: "AUDIO",
  Pdf: "PDF",
  Link: "LINK",
  VideoLink: "VIDEOLINK",
};

const ContentViewerPage = () => {
  const navigate = useNavigate();

  useEffect(() => {}, []);

  const [contentName, setContentName] = useState("defaultImage");

  const [contentUrl, setContentUrl] = useState("");

  const [profileImg, setProfileImg] = useState("");
  return (
    <>
      <div className="Container-fluid">
        <div className="col-lg-12 text-right mt-2">
          <button
            onClick={() => {
              navigate("/Catalogue");
            }}
            disabled={false}
            className="btn btn-secondary ml-2 float-right">
            <i className="fa-solid fa-arrow-left"></i> Back
          </button>
        </div>

        <div className="contView row">
          <div className={"col-lg-12 text-center"}>
            {contentName === contentObj?.Scrome && (
              <div className="cont_img">
                <iframe
                  src={process.env.PUBLIC_URL + contentUrl}
                  width="100%"
                  // height={resize ? "120vh" : "auto"}
                  style={{ height: "100vh" }}></iframe>
              </div>
            )}

            {/* <div className="vid">
              <video src={``} controls></video>
            </div> */}

            {contentName === "defaultImage" && (
              <div className="cont_img">
                <img src={require("../../Assets/Images/thumbnail.jpg")} />
              </div>
            )}
            {contentName === contentObj?.Pdf && (
              <div className="pdf">
                <PdfViewer contentUrl={contentUrl} />
              </div>
            )}

            {contentName === contentObj?.Audio && (
              // <>
              //   <div className="audio_img">
              //     <audio controls>
              //       <source src={`data:audio/wav;base64,${contentUrl}`} />
              //     </audio>
              //   </div>
              // </>
              <>
                {profileImg ? (
                  <div className="audio_img">
                    <img src={profileImg} />
                  </div>
                ) : (
                  <div className="audio_img">
                    <img src={require("./assets/audi_def.jpg")} />
                  </div>
                )}

                <audio
                  // controls="controls"
                  // autobuffer="autobuffer"
                  // autoplay="autoplay"

                  controls>
                  <source src={`data:audio/wav;base64,${contentUrl}`} />
                </audio>
              </>
            )}

            {/* {contentName === contentObj?.Link && ( */}
            {/* <div>
              <a
                href="https://fontawesome.com/v4/icon/eye"
                // target="_blank"
                rel="noopener noreferrer"
              ></a>
            </div> */}
            {/* )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentViewerPage;
