import { ThemeProvider, createTheme } from "@mui/material/styles";

export const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  width: "75%",
  height: "42%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#00ABE6",
  boxShadow: 24,
  p: 4,
};

export const defaultTheme = createTheme({});
const { breakpoints } = defaultTheme;

export const tabsTheme = {
  ...defaultTheme,
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: "0px !important",
          marginLeft: "-42px",
          [breakpoints.down("md")]: {
            minHeight: "0px !important",
            marginLeft: "0px",
          },
        },
      },
    },
  },
};

export const statusGridColumns = [
  {
    name: "Id",
    label: "Id",
    options: {
      filter: true,
      sort: true,
      display: false,
    },
  },
  {
    name: "Date",
    label: "Date",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "Activity",
    label: "Activity",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "Outcome",
    label: "Outcome",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "Shedule/DueDate",
    label: "Shedule/DueDate",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "Action",
    label: "Action",
    options: {
      filter: true,
      sort: true,
    },
  },
];
