import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { LoaderContext } from "../../Helpers/Context/Context";
import { APICall } from "../../Helpers/APICalls";
import {
  GetContractorComplianceDetailsDasboardData,
  GetContractorDetailsDropDown,
} from "../../Helpers/APIEndPoints/AssociateEndPoints";
import { Collapse, Tooltip } from "@mui/material";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import InputForm from "../../Components/InputForm/InputForm";
import SelectForm from "../../Components/SelectForm/SelectForm";

interface IOptions {
  value?: number | string;
  label?: string;
  code?: string;
}

interface IFormData {
  contractorCategory?: IOptions | string | any;
}

const ContractorComplianceDashboard = () => {
  const { state } = useLocation();

  const navigate = useNavigate();
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  const { showLoader, hideLoader } = useContext(LoaderContext);

  const [formOptions, setFormOptions] = useState<IFormData>({});

  const [formData, setFormData] = useState<IFormData>({});

  const [count, setCount] = useState(0);
  const [gridData, setGridData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sortDirection, setSortDirection] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [searchText, setSearchText] = useState("");

  const [reSet, setreSet] = useState(false);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const responseData = async () => {
      const parameters = {
        Mode: "VendorCategory",
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      };

      const res = await APICall(
        GetContractorDetailsDropDown,
        "POST",
        parameters
      );

      if (res?.status === 0 && res?.data?.length > 0) {
        setFormOptions((env) => {
          return {
            ...env,
            contractorCategory: res?.data,
          };
        });
      }
    };

    showLoader();

    responseData();

    hideLoader();
  }, []);

  const gridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: pageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      setPageNumber(page * pageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };
  const gridColumns = [
    {
      name: "Id",
      label: "Id",
      options: {
        display: false,
      },
    },
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "contractorName",
      label: "Contractor Name",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,

        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
      },
    },
    {
      name: "address",
      label: "Address",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,

        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
      },
    },
    {
      name: "contractorType",
      label: "Type",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,

        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
      },
    },
    {
      name: "clNumber",
      label: "CL Number",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
      },
    },
    {
      name: "clDate",
      label: "CL No. Reg Date",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
      },
    },
    {
      name: "contractorCategory",
      label: "Contractor Category",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
      },
    },
    {
      name: "newEffectiveDate",
      label: "Effective Date",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
      },
    },
    {
      name: "maxLicenseStrength",
      label: "Max License Strength",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
      },
    },
    {
      name: "contactNumber",
      label: "Contact",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
      },
    },
    {
      name: "newExpiryDate",
      label: "Expiry Date",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "vendorStatus",
      label: "Vendor Status",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta) => {
          let id = tableMeta.tableData[tableMeta.rowIndex].id;
          let contractorName =
            tableMeta.tableData[tableMeta.rowIndex].contractorName;

          return (
            <div style={{ width: "100px" }}>
              <>
                <Tooltip title="Update Compliance">
                  <a
                    className="mr-2"
                    style={{ fontSize: "15px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/ContractorCompliance", {
                        state: { contractorId: id, contractorName },
                      });
                    }}
                  >
                    <i className="fas fa-edit"></i>
                  </a>
                </Tooltip>
              </>
            </div>
          );
        },
      },
    },
  ];

  useEffect(() => {
    getContractorComplianceDetailsDasboardDataAPICall();
  }, [
    pageNumber,
    pageSize,
    sortColumn,
    sortDirection,
    searchText,
    reSet,
    formData?.contractorCategory,
  ]);

  const getContractorComplianceDetailsDasboardDataAPICall = async () => {
    try {
      showLoader();

      const requestParams = {
        PageNumber: pageNumber,
        PageSize: pageSize,
        SortColumn: sortColumn,
        SortOrder: sortDirection,
        SearchText: searchText,
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
        ContractorCategoryIds: formData?.contractorCategory
          ?.map((val) => val?.value)
          .join(","),
      };

      const responseData = await APICall(
        GetContractorComplianceDetailsDasboardData,
        "POST",
        requestParams
      );

      if (
        responseData &&
        responseData.data !== null &&
        responseData.data.length > 0
      ) {
        setGridData(responseData.data);
        setCount(responseData.data[0].totalCount);
      } else {
        setGridData([]);
        setCount(0);
      }
    } catch (error) {
      console.error("GetContractorUserDasboardDetails", error);
    } finally {
      hideLoader();
    }
  };

  function downloadBase64File(base64String: string, fileName: string) {
    const mimeType = inferMimeType(base64String);
    const blob = base64toBlob(base64String, mimeType);

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  }
  function inferMimeType(base64String: string): string {
    const dataUrl = `data:application/octet-stream;base64,${base64String}`;
    const typeInfo = /^data:(.*?);/.exec(dataUrl);

    if (typeInfo && typeInfo[1]) {
      return typeInfo[1];
    }

    return "application/octet-stream";
  }
  function base64toBlob(base64String: string, mimeType: string): Blob {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], { type: mimeType });
  }
  return (
    <>
      <div className="breadcrumb modified-bredcrumb">
        <h4>Contractor Compliance Dashboard</h4>
        <div className="SectionSubmit mb-2 clearfix">
          <button
            onClick={() => {
              navigate("/Home");
            }}
            disabled={false}
            className="btn btn-secondary float-right ml-2 mt-2"
          >
            <i className="fa-solid fa-arrow-left"></i> Back
          </button>
        </div>
      </div>

      <div className="container-fluid mt-2" id="blue-collar-pages">
        <div className="grid-wrapper grid_mob">
          <div className="row mx-auto filtBox">
            <div className="col-lg-3 col-md-4 col-sm-12">
              <div className="form-group filtInp">
                <span className="mr-2 text-white" style={{ float: "left" }}>
                  <label className="col-form-label">{`Contractor Category`}</label>
                </span>
                <SelectForm
                  isClearable
                  options={formOptions?.contractorCategory}
                  placeholder={"Select"}
                  isDisabled={false}
                  onChange={(event) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        contractorCategory: event,
                      };
                    });
                  }}
                  isMulti={true}
                  noIndicator={false}
                  noSeparator={false}
                  value={formData?.contractorCategory}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="form-group filtInp">
                <InputForm
                  value={searchText}
                  placeholder={"Search Keywords"}
                  isDisabled={false}
                  textArea={false}
                  onChange={(e) => {
                    setPageNumber(0);
                    setSearchText(e.target.value);
                  }}
                />
              </div>
            </div>
            {/* <div className="col-lg-1 col-md-1 col-sm-12 filtrt">
              <ul className=" filter-icon-wel">
                <li>
                  <a
                    href="javascript:void(0);"
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                    className="filter-btn"
                  >
                    <i className="fa fa-filter"></i>
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>

        <>
          <Collapse in={open}>
            <div id="example-collapse-text grid-wrapper">
              <div className="row mx-auto myInlineStyle">
                <>
                  {/* <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">
                      Content ID
                    </label>
                    <SelectForm
                      isClearable
                      options={contentIdOptions}
                      placeholder={"Content ID"}
                      isDisabled={false}
                      onChange={(event) => {
                        setContentId(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={contentId}
                    />
                  </div> */}
                </>
                <div className="col-lg-12 d-flex mt-2 justify-content-end">
                  <button
                    onClick={() => {
                      //   resetFilters();
                    }}
                    className="btn btn-secondary mr-2"
                  >
                    <i className="fa fa-undo" aria-hidden="true"></i> Reset{" "}
                  </button>
                  <button
                    className="btn btn-filter-submit"
                    onClick={() => {
                      //   onSubmitFilter();
                    }}
                  >
                    <i className="fa fa-search" aria-hidden="true"></i> Search{" "}
                  </button>
                </div>
              </div>
            </div>
          </Collapse>
        </>
        <div className="mb-3 pt-3">
          <DynamicGrid
            data={gridData}
            columns={gridColumns}
            options={gridOptions}
          />
        </div>
      </div>
    </>
  );
};

export default ContractorComplianceDashboard;
