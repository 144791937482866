import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoaderContext, getContext } from "../../Helpers/Context/Context";
import { APICall } from "../../Helpers/APICalls";
import {
  InsertupdateUserData,
  getADUsers,
  getRoleDropdowndata,
  getUserDetailsById,
} from "../../Helpers/APIEndPoints/EndPoints";
import SelectForm from "../../Components/SelectForm/SelectForm";
import InputForm from "../../Components/InputForm/InputForm";
import notify from "../../Helpers/ToastNotification";
import AccessDenied from "../AccessDenied/AccessDenied";

const UserEdit = () => {
  const { state } = useLocation();
  let navigate = useNavigate();
  const context = getContext();
  const [formErrors, setFormErrors] = useState({});
  const { showLoader, hideLoader } = useContext(LoaderContext);

  const [roleOptions, setRoleOptions] = useState([]);
  const [roleVal, setRoleVal] = useState<any>([]);
  const [selectedUser, setSelectedUser] = useState<any>([]);
  const [isActive, setIsActive] = useState(true);
  const [userEmail, setUserEmail] = useState<any>("");

  const getUsers = async (event, cb) => {
    if (event.length > 3) {
      const data = await APICall(getADUsers, "POST", { searchTerm: event });

      if (data?.length > 0) {
        let users = data.map((element) => ({
          value: element.emailId,
          label: element.displayName,
          LoginId: element.loginId,
        }));

        cb(users);
      } else {
        cb([]);
      }
    }
  };

  useEffect(() => {
    (async () => {
      showLoader();
      await GetRoleOptions();
      if (state !== null && state.id && state.id > 0) {
        await getUserDetails();
      }
      hideLoader();
    })();
  }, []);

  const selectOnChange = (event, name) => {
    if (name === "role") {
      setRoleVal(event);
    }
    if (name === "user") {
      setSelectedUser(event);

      setUserEmail(event.value);
    }
  };

  const GetRoleOptions = async () => {
    const { data } = await APICall(getRoleDropdowndata, "POST", {});

    if (data !== null && data.length > 0) {
      let role = data.map((element) => ({
        value: element.id,
        label: element.name,
      }));
      setRoleOptions(role);
    }
  };

  const getUserDetails = async () => {
    const { data } = await APICall(getUserDetailsById, "POST", {
      Id: state.id,
    });
    if (data !== null) {
      setSelectedUser({
        value: data.emailId,
        label: data.name,
        LoginId: data.loginId,
      });

      setRoleVal({
        value: data.roleId,
        label: data.roleName,
      });
      setUserEmail(data.emailId);
      setIsActive(data.isActive);
    }
  };

  const onClickFunction = async (event, name) => {
    if (name === "Cancel") {
      navigate("/UserDashboard");
    }
    if (name === "Submit") {
      let error = Validation();

      if (!error) {
        await InsertUpdateUsers();
      }
    }
  };

  const Validation = () => {
    let isError = false;
    setFormErrors({});

    if (selectedUser.length == 0) {
      setFormErrors((prev) => {
        return {
          ...prev,
          ["user_isEmpty"]: "Select User",
        };
      });
      isError = true;
    }
    if (roleVal.length == 0) {
      setFormErrors((prev) => {
        return {
          ...prev,
          ["role_isEmpty"]: "Select Role",
        };
      });
      isError = true;
    }

    return isError;
  };

  const InsertUpdateUsers = async () => {
    let requestParams = {
      Id: state === null ? 0 : state.id,
      RoleId: roleVal.value,
      Name: selectedUser.label,
      EmailId: userEmail,
      LoginId: selectedUser.LoginId,
      AuthenticationType: 1,
      CreatedBy: context.userId,
      IsAccountLocked: 0,
      IsActive: isActive,
    };

    const data = await APICall(InsertupdateUserData, "POST", requestParams);

    if (data.data !== null) {
      if (state.id === 0 || state.id === null) {
        notify(0, "Data Saved Successfully.");
      } else {
        notify(0, "Data Updated Successfully.");
      }
      navigate(-1);
    } else {
      data.message === "mapped"
        ? setFormErrors({ ["role_isEmpty"]: "Role is mapped" })
        : notify(1, "Something went wrong, Try again later.");
    }
  };

  return (
    <>
      {context.loggedInAs === 1 ? (
        <>
          <div className="form-main px-3">
            <div className="page-title w-100">
              <div className="col-lg-12 p-0">
                <div className="row">
                  <div className="col-lg-4 col-md-4">
                    <h4>User Role</h4>
                  </div>
                  <div className="col-lg-4 col-md-2"></div>
                  <div className="col-lg-4 col-md-6">
                    <div
                      className="float-right col-sm-offset-2"
                      style={{
                        marginBottom: 10,
                      }}>
                      <button
                        className="btn  btn-success"
                        style={{ marginLeft: 5 }}
                        onClick={(e) => onClickFunction(e, "Submit")}>
                        <i className="fa fa-save"></i> Submit
                      </button>

                      <button
                        className="btn btn-cancel"
                        style={{ marginLeft: 5 }}
                        onClick={(e) => onClickFunction(e, "Cancel")}>
                        <i className="fa fa-times" aria-hidden="true"></i>{" "}
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12 col-md-12 px-3 py-2 page_heading">
            <div className="row align-items-center">
              <div className="col-lg-4 col-sm-3 col-xs-4 ">
                <div className="form-group">
                  <label className="col-form-label">User </label>
                  <sup>*</sup>
                  <SelectForm
                    async
                    isClearable
                    isSearchable
                    options={(event, cb) => getUsers(event, cb)}
                    placeholder="Select user"
                    isDisabled={false}
                    value={selectedUser}
                    onChange={(event) => {
                      selectOnChange(event, "user");
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  <p style={{ color: "red" }}>{formErrors["user_isEmpty"]}</p>
                </div>
              </div>

              <div className="col-lg-4 col-sm-3 col-xs-4 ">
                <div className="form-group">
                  <label className="col-form-label">Email</label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control form-control-lg input_form_height "
                    placeholder={"Email"}
                    isDisabled={true}
                    textArea={false}
                    value={userEmail}
                    onChange={() => {}}
                    //maxLength="255"
                  />
                </div>
              </div>

              <div className="col-lg-4 col-sm-3 col-xs-4 ">
                <div className="form-group">
                  <label className="col-form-label">Role</label>
                  <sup>*</sup>
                  <SelectForm
                    options={roleOptions}
                    placeholder="Select role"
                    isDisabled={false}
                    value={roleVal}
                    onChange={(event) => {
                      selectOnChange(event, "role");
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  <p style={{ color: "red" }}>{formErrors["role_isEmpty"]}</p>
                </div>
              </div>

              <div className="col-lg-4 col-sm-3 col-xs-4 ">
                <div className="form-group">
                  <label htmlFor="isActive" className="col-form-label">
                    Active Status
                  </label>
                  <sup>*</sup>
                  <div className="custom-checkbox">
                    <input
                      disabled={
                        state === null ? true : state.id == 0 ? true : false
                      }
                      //disabled={false}
                      type="checkbox"
                      onChange={() => setIsActive((current) => !current)}
                      id="isActive"
                      name="isActive"
                      checked={isActive}
                    />
                  </div>
                </div>
              </div>

              {/* <div className="col-lg-8 col-md-4">
            <div className="float-right">
              <button
                className="btn  btn-success"
                style={{ marginLeft: 5 }}
                onClick={(e) => onClickFunction(e, "Submit")}>
                <i className="fa fa-save"></i> Submit
              </button>
              <button
                className="btn btn-info"
                style={{ marginLeft: 5 }}
                onClick={(e) => onClickFunction(e, "Reset")}
              >
                Reset
              </button>
              <button
                className="btn btn-cancel"
                style={{ marginLeft: 5 }}
                onClick={(e) => onClickFunction(e, "Cancel")}>
                Cancel
              </button>
            </div>
          </div> */}
            </div>
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};

export default UserEdit;
