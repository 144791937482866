import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoaderContext } from "../../Helpers/Context/Context";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import SelectForm from "../../Components/SelectForm/SelectForm";
import {
  getCourseAssessmentDropDownList,
  getCourseData,
  saveCourseAssessment,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import { APICall } from "../../Helpers/APICalls";
import CourseGrid from "./GridComponent/CourseGrid";
import notify from "../../Helpers/ToastNotification";
import CourseScheduleGrid from "./GridComponent/CourseScheduleGrid";
import InputForm from "../../Components/InputForm/InputForm";

interface IOptions {
  label?: string;
  value?: number | string;
  code?: string;
}

interface IFormData {
  assessment?: IOptions[] | any;
  assessmentLevel?: IOptions | any;
  assessmentType?: IOptions | any;
  courseSchedule?: IOptions | any;
  mandatory?: IOptions | any;
  assessmentDays?: Number | any;
}

const CourseAssessment = ({ activeTab, setActiveTab }) => {
  const { state } = useLocation();

  const [isDisable, setIsDisable] = useState(
    state && state?.isDisable ? true : false
  );

  const { showLoader, hideLoader } = useContext(LoaderContext);

  const { userDetails, currentRoleId }: any = useUserContext();

  const navigate = useNavigate();

  const [formData, setFormData] = useState<IFormData>({});
  const [formErrors, setFormErrors] = useState<IFormData>({});
  const [formOptions, setFormOptions] = useState<IFormData>({});

  const [isScheduleRequired, setIsScheduleRequired] = useState(false);

  const assessmentLevelObj = {
    Course: "COURSE",
    Session: "SESSION",
    CourseSchedule: "CS",
    SessionSchedule: "SS",
  };

  const [gridReset, setGridReset] = useState(false);

  useEffect(() => {
    const responseData = async () => {
      //ASSESSMENT_LEVEL
      const res = await APICall(getCourseAssessmentDropDownList, "POST", {
        Mode: "ASSESSMENT_LEVEL",
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      });

      debugger;

      if (res.data && res.data.length > 0) {
        setFormOptions((prev) => {
          return {
            ...prev,
            assessmentLevel: res.data,
          };
        });
      }

      //ASSESSMENT_TYPE
      const res2 = await APICall(getCourseAssessmentDropDownList, "POST", {
        Mode: "ASSESSMENT_TYPE",
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      });

      if (res2.data && res2.data.length > 0) {
        setFormOptions((prev) => {
          return {
            ...prev,
            assessmentType: res2.data,
          };
        });
      }

      //ASSESSMENT
      const res3 = await APICall(getCourseAssessmentDropDownList, "POST", {
        Mode: "ASSESSMENT",
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      });

      if (res3.data && res3.data.length > 0) {
        setFormOptions((prev) => {
          return {
            ...prev,
            assessment: res3.data,
          };
        });
      }

      //SESSION
      const res4 = await APICall(getCourseAssessmentDropDownList, "POST", {
        Mode: "SESSION",
        CourseId: state?.courseId,
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      });

      if (res4.data && res4.data.length > 0) {
        setFormOptions((prev) => {
          return {
            ...prev,
            session: res4.data,
          };
        });
      }

      //COURSE_SCHEDULE
      const res5 = await APICall(getCourseAssessmentDropDownList, "POST", {
        Mode: "COURSE_SCHEDULE",
        CourseId: state?.courseId,
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      });

      if (res5.data && res5.data.length > 0) {
        setFormOptions((prev) => {
          return {
            ...prev,
            courseSchedule: res5.data,
          };
        });
      }

      //SESSION_SCHEDULE
      const res6 = await APICall(getCourseAssessmentDropDownList, "POST", {
        Mode: "SESSION_SCHEDULE",
        CourseId: state?.courseId,
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      });

      if (res6.data && res6.data.length > 0) {
        setFormOptions((prev) => {
          return {
            ...prev,
            sessionSchedule: res6.data,
          };
        });
      }

      const mandatoryObj = [
        { value: 1, label: "Yes" },
        { value: 0, label: "No" },
      ];

      setFormOptions((prev) => {
        return {
          ...prev,
          mandatory: mandatoryObj,
        };
      });

      const responseData = await APICall(getCourseData, "POST", {
        Id: state?.courseId,
        LaunchId: state?.launchHubId,
      });

      if (responseData?.status === 0) {
        setIsScheduleRequired(responseData?.data?.scheduleRequired);
      }
    };

    showLoader();

    responseData();

    hideLoader();
  }, [gridReset]);

  const formOnChange = (e, type) => {
    switch (type) {
      case "assessmentLevel":
        setFormData({
          ...formData,
          assessmentLevel: e,
        });
        break;

      case "assessment":
        setFormData({
          ...formData,
          assessment: e,
        });
        break;

      case "assessmentType":
        setFormData({
          ...formData,
          assessmentType: e,
        });
        break;

      case "courseSchedule":
        setFormData({
          ...formData,
          courseSchedule: e,
        });
        break;

      case "mandatory":
        setFormData({
          ...formData,
          mandatory: e,
        });
        break;

      case "assessmentDays":
        setFormData({
          ...formData,
          assessmentDays: e.target.value,
        });
        break;

      default:
        break;
    }
  };

  const validateForm = () => {
    let errorObj = {};
    let isError = false;

    setFormErrors({});

    if (
      formData?.assessmentLevel === null ||
      formData?.assessmentLevel === undefined
    ) {
      errorObj = {
        ...errorObj,
        ["assessmentLevel"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["assessmentLevel"]: "",
      };
    }

    if (
      formData?.assessment?.length === 0 ||
      formData?.assessment === null ||
      formData?.assessment === undefined
    ) {
      errorObj = {
        ...errorObj,
        ["assessment"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["assessment"]: "",
      };
    }

    if (
      formData?.assessmentType === null ||
      formData?.assessmentType === undefined
    ) {
      errorObj = {
        ...errorObj,
        ["assessmentType"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["assessmentType"]: "",
      };
    }

    if (formData?.mandatory === null || formData?.mandatory === undefined) {
      errorObj = {
        ...errorObj,
        ["mandatory"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["mandatory"]: "",
      };
    }

    debugger;

    if (
      formData?.assessmentDays !== "" &&
      formData?.assessmentDays !== null &&
      formData?.assessmentDays !== undefined
    ) {
      if (!/^([0-9]+|0)$/.test(formData?.assessmentDays)) {
        errorObj = {
          ...errorObj,
          ["assessmentDays"]: "Only numbers are allowed",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["assessmentDays"]: "",
        };
      }
    }

    if (
      formData?.assessmentLevel?.code === assessmentLevelObj?.CourseSchedule
    ) {
      if (
        formData?.courseSchedule === null ||
        formData?.courseSchedule === undefined
      ) {
        errorObj = {
          ...errorObj,
          ["courseSchedule"]: "Required",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["courseSchedule"]: "",
        };
      }
    }

    const isEmpty = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  const handleSubmitFunction = async () => {
    showLoader();

    if (validateForm()) {
      hideLoader();
      return;
    }

    let valueId = 0;

    if (formData?.assessmentLevel?.code === assessmentLevelObj.Course) {
      valueId = state?.courseId;
    } else if (
      formData?.assessmentLevel?.code === assessmentLevelObj.CourseSchedule
    ) {
      valueId = formData?.courseSchedule?.value;
    }

    const parameters = {
      Mode: "SAVE",
      CourseId: state?.courseId,
      AssessmentIds: formData?.assessment?.map((s) => s.value).join(","),
      AssessmentLevelId: formData?.assessmentLevel?.value,
      AssessmentTypeId: formData?.assessmentType?.value,
      IsMandatory: formData?.mandatory?.value,
      AssessmentDays: formData?.assessmentDays,
      ValueId: valueId,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    };

    const responseData = await APICall(
      saveCourseAssessment,
      "POST",
      parameters
    );

    if (responseData?.status === 0) {
      notify(responseData?.status, responseData?.message);
      handleResetFunction();
      setGridReset(!gridReset);
    } else if (responseData?.status === 1) {
      notify(responseData?.status, responseData?.message);
    }

    hideLoader();
  };

  const handleResetFunction = () => {
    setFormData((prev) => {
      return {
        ...prev,
        assessmentLevel: null,
        assessment: null,
        assessmentType: null,
        session: null,
        courseSchedule: null,
        sessionSchedule: null,
        mandatory: null,
        assessmentDays: null,
      };
    });

    setFormErrors({});
  };

  return (
    <>
      <div className="container-fluid">
        {!isDisable && (
          <>
            {" "}
            <div className="row">
              <div className={"col-lg-3 col-sm-4 col-xs-6"}>
                <div className="dropdown mb-1">
                  <label className="col-form-label">Assessment Level</label>{" "}
                  <sup>*</sup>
                  <SelectForm
                    isClearable
                    isSearchable
                    options={formOptions?.assessmentLevel}
                    placeholder={"Assessment Level"}
                    isDisabled={isDisable}
                    value={formData?.assessmentLevel}
                    onChange={(e) => {
                      formOnChange(e, "assessmentLevel");
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  {formErrors["assessmentLevel"] && (
                    <p style={{ color: "red" }}>
                      {formErrors["assessmentLevel"]}
                    </p>
                  )}
                </div>
              </div>

              {formData?.assessmentLevel?.code ===
                assessmentLevelObj?.CourseSchedule && (
                <>
                  <div className={"col-lg-3 col-sm-4 col-xs-6"}>
                    <div className="dropdown mb-1">
                      <label className="col-form-label">Course Schedule</label>{" "}
                      <sup>*</sup>
                      <SelectForm
                        isClearable
                        isSearchable
                        options={formOptions?.courseSchedule}
                        placeholder={"Course Schedule"}
                        isDisabled={isDisable}
                        value={formData?.courseSchedule}
                        onChange={(e) => {
                          formOnChange(e, "courseSchedule");
                        }}
                        isMulti={false}
                        noIndicator={false}
                        noSeparator={false}
                      />
                      {formErrors["courseSchedule"] && (
                        <p style={{ color: "red" }}>
                          {formErrors["courseSchedule"]}
                        </p>
                      )}
                    </div>
                  </div>
                </>
              )}

              <div className={"col-lg-3 col-sm-4 col-xs-6"}>
                <div className="dropdown mb-1">
                  <label className="col-form-label">Assessments</label>{" "}
                  <sup>*</sup>
                  <SelectForm
                    isClearable
                    isSearchable
                    options={formOptions?.assessment}
                    placeholder={"Assessments"}
                    isDisabled={isDisable}
                    value={formData?.assessment}
                    onChange={(e) => {
                      formOnChange(e, "assessment");
                    }}
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  {formErrors["assessment"] && (
                    <p style={{ color: "red" }}>{formErrors["assessment"]}</p>
                  )}
                </div>
              </div>

              <div className={"col-lg-3 col-sm-4 col-xs-6"}>
                <div className="dropdown mb-1">
                  <label className="col-form-label">Mandatory</label>{" "}
                  <sup>*</sup>
                  <SelectForm
                    isClearable
                    isSearchable
                    options={formOptions?.mandatory}
                    placeholder={"Mandatory"}
                    isDisabled={isDisable}
                    value={formData?.mandatory}
                    onChange={(e) => {
                      formOnChange(e, "mandatory");
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  {formErrors["mandatory"] && (
                    <p style={{ color: "red" }}>{formErrors["mandatory"]}</p>
                  )}
                </div>
              </div>

              <div className={"col-lg-3 col-sm-4 col-xs-6"}>
                <div className="dropdown mb-1">
                  <label className="col-form-label">Assessment Type</label>{" "}
                  <sup>*</sup>
                  <SelectForm
                    isClearable
                    isSearchable
                    options={formOptions?.assessmentType}
                    placeholder={"Assessment Type"}
                    isDisabled={isDisable}
                    value={formData?.assessmentType}
                    onChange={(e) => {
                      formOnChange(e, "assessmentType");
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  {formErrors["assessmentType"] && (
                    <p style={{ color: "red" }}>
                      {formErrors["assessmentType"]}
                    </p>
                  )}
                </div>
              </div>

              {isScheduleRequired && (
                <div className={"col-lg-3 col-sm-4 col-xs-4"}>
                  <div className="mb-1">
                    <label className="col-form-label">Assessment Days</label>

                    <InputForm
                      className="form-control"
                      placeholder={"Assessment Days"}
                      isDisabled={isDisable}
                      textArea={false}
                      value={formData?.assessmentDays}
                      onChange={(e) => {
                        formOnChange(e, "assessmentDays");
                      }}
                      maxLength="255"
                    />
                    {formErrors["assessmentDays"] && (
                      <p style={{ color: "red" }}>
                        {formErrors["assessmentDays"]}
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
            <br />
            <div className="SectionSubmit mb-4 clearfix">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmitFunction();
                }}
                disabled={isDisable}
                className="btn btn-filter-submit float-right ml-2">
                <i className="fa fa-plus mr-2"></i>
                Add Assessment
              </button>

              <button
                onClick={() => {
                  handleResetFunction();
                }}
                disabled={isDisable}
                className="btn btn-secondary float-right ml-2">
                <i className="fa-solid fa-refresh"></i> Reset
              </button>
            </div>
          </>
        )}

        <div>
          <CourseGrid gridReset={gridReset} setGridReset={setGridReset} />
        </div>
        {/* <div>
          <SessionGrid gridReset={gridReset} setGridReset={setGridReset} />
        </div> */}
        <br />
        <div>
          <CourseScheduleGrid
            gridReset={gridReset}
            setGridReset={setGridReset}
          />
        </div>
        {/* <div>
          <SessionScheduleGrid
            gridReset={gridReset}
            setGridReset={setGridReset}
          />
        </div> */}
      </div>
    </>
  );
};

export default CourseAssessment;
