import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  GetCatalogueEmployeeDropdown,
  GetCommonDropdownForScheduling,
  GetNomineeEmployeeDropdown,
  GetSessionContent,
  GetThumbnailDetails,
  InsertUpdateCourseSession,
  ManageCourseSessions,
  createRegistrationRequest,
  getCourseData,
  getCourseDetailsForRequest,
  getCourseScheduleDetails,
  getRejectionReasonList,
  getRequestHeaderData,
  getRequestsApprovalTrail,
  manageCourseNomination,
  requestsApproval,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import { APICall } from "../../Helpers/APICalls";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { LoaderContext } from "../../Helpers/Context/Context";
import defaultProfileImgPic from "../../Assets/Images/thumbnail.jpg";
import { Modal, Table } from "react-bootstrap";
import InputForm from "../../Components/InputForm/InputForm";
import SelectForm from "../../Components/SelectForm/SelectForm";
import ImageColumnComponent from "../../Components/LMS/ImageColumnComponent/ImageColumnComponent";
import notify from "../../Helpers/ToastNotification";
import "./journRegs.css";
import LMSDynamicGrid from "../../Components/LMSDynamicGrid/LMSDynamicGrid";

const SessionAccordion = (props) => {
  const [accordion, setAccordion] = useState(false);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { state } = useLocation();

  const [profileImg, setProfileImg] = useState(null);

  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();

  const [contents, setContents] = useState([]);

  const getContent = async (sessionId) => {
    showLoader();
    const res = await APICall(GetSessionContent, "POST", {
      SessionId: sessionId,
      Mode: "LISTCONTENTADDED",
      CreatedBy: userDetails.Id,
      LaunchId: props.launchId,
    });

    if (res.status === 0 && res.data.length > 0) {
      const contentData = res.data;

      for (let index = 0; index < contentData.length; index++) {
        const content = contentData[index];

        content.thumbnailUrl =
          content.thumbnailUrl !== null
            ? await getThumbnail(content.thumbnailUrl)
            : null;
      }

      setContents(contentData);
    }

    hideLoader();
  };

  const getThumbnail = async (thumbnailUrl) => {
    const res = await APICall(GetThumbnailDetails, "POST", {
      ThumbnailUrl: thumbnailUrl,
    });

    if (res.status === 0) {
      return `data:image;base64,${res.data.thumbnailDataObject}`;
    }
  };

  useEffect(() => {
    if (accordion) {
      getContent(props.currentSession.sessionId);

      if (props.currentSession.thumbnailUrl) {
        (async () => {
          const res = await getThumbnail(props.currentSession.thumbnailUrl);

          setProfileImg(res);
        })();
      }
    }
  }, [accordion]);

  const [currentSessionData, setCurrentSessionData] = useState([]);

  const sessionColumns = [
    {
      name: "sessionId",
      label: "Session ID",
      options: {
        filter: false,
        sort: false,
        width: 200,
      },
    },
    {
      name: "sessionTitle",
      label: "Session Title",
      options: {
        filter: false,
        sort: false,
        width: 200,
      },
    },
    {
      name: "sessionType",
      label: "Session Type",
      options: {
        filter: false,
        sort: false,
        width: 200,
      },
    },
    {
      name: "duration",
      label: "Duration",
      options: {
        filter: false,
        sort: false,
        width: 200,
      },
    },
    {
      name: "sessionObjective",
      label: "Session Objective",
      options: {
        filter: false,
        sort: false,
        width: 200,
        customBodyRender: (value, tableMeta) => {
          return (
            <span
              dangerouslySetInnerHTML={{
                __html: value,
              }}
            ></span>
          );
        },
      },
    },
  ];

  useEffect(() => {
    if (props.currentSession) {
      const gridData = [
        {
          sessionId: props.currentSession.sessionCode,
          sessionTitle: props.currentSession.sessionTitle,
          sessionType: props.currentSession.sessionType,
          duration: `${props.currentSession.totalDurationHours ?? "00"} hrs : ${
            props.currentSession.totalDurationMinutes ?? "00"
          } min`,
          sessionObjective: props.currentSession.sessionObjective,
        },
      ];

      setCurrentSessionData(gridData);
    }
  }, [props.currentSession]);

  return (
    <Accordion
      elevation={0}
      className="mb-3"
      expanded={accordion}
      onChange={() => setAccordion((prev) => !prev)}
    >
      <AccordionSummary
        id="profile-accordion"
        style={{ background: "#3C5464" }}
        className="text-white acc_close"
        expandIcon={<ExpandMore />}
      >
        <div className="row d-flex align-items-center acc-heading">
          <div className="col-lg-10 col-md-10 col-sm-12">
            <p style={{ fontSize: "16px" }}>
              {props.currentSession.sessionTitle} -{" "}
              {/* {props.currentSession.sessionCode} -{" "} */}
              {
                // moment(props.currentSession.sessionStartDate).format(
                //   "DD-MMM-YYYY hh:mm"
                // )
                props.currentSession.newSessionStartDate
              }{" "}
              -{" "}
              {moment(props.currentSession.sessionEndDate).year() === 9999
                ? "N/A"
                : // moment(props.currentSession.sessionEndDate).format(
                  //     "DD-MMM-YYYY hh:mm"
                  //   )
                  props.currentSession.newSessionEndDate}
            </p>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails className="">
        <div className="row">
          <div className="col-lg-2">
            <div className="card">
              <img
                src={profileImg != null ? profileImg : defaultProfileImgPic}
                className="card-img-top"
                alt="Thumbnail Image"
              />
            </div>
          </div>
          <div className="col-lg-10 fnt_we">
            <div className="row">
              <LMSDynamicGrid
                data={currentSessionData}
                columns={sessionColumns}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            {contents.length > 0 ? (
              <>
                <p className="mt-3 mb-3" style={{ color: "#2C2B7C" }}>
                  <b
                    className="rounded px-3 py-2"
                    style={{ backgroundColor: "#e7e7e7" }}
                  >
                    Content
                  </b>
                </p>
                {/* <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th className="text-dark">Thumbnail</th>
                      <th className="text-dark">Title</th>
                      <th className="text-dark">Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contents.map((content) => (
                      <tr>
                        <td>
                          <div style={{ maxWidth: "100px" }} className="card">
                            <img
                              src={
                                content?.thumbnailUrl != null
                                  ? content?.thumbnailUrl
                                  : defaultProfileImgPic
                              }
                              className="card-img-top"
                              alt="Thumbnail Image"
                            />
                          </div>
                        </td>
                        <td>{content?.contentTitle}</td>
                        <td>{content?.contentType}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table> */}
                <div className="row">
                  <div className="col-lg-12 p-0">
                    <ContentGrid contents={contents} />
                  </div>
                </div>
              </>
            ) : (
              <h4>No content available</h4>
            )}
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const ContentGrid = ({ contents }) => {
  const { state } = useLocation();

  const [contentGridData, setContentGridData] = useState<any>(contents);

  const contentColumns = [
    {
      name: "thumbnailUrl",
      label: "Thumbnail",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={{ maxWidth: "100px" }} className="card">
              <img
                src={value != null ? value : defaultProfileImgPic}
                className="card-img-top"
                alt="Thumbnail Image"
              />
            </div>
          );
        },
      },
    },
    {
      name: "contentTitle",
      label: "Title",
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: "contentType",
      label: "Type",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  return <LMSDynamicGrid data={contentGridData} columns={contentColumns} />;
};

const CourseNomination = (props) => {
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useContext(LoaderContext);

  const [profileImg, setProfileImg] = useState(null);

  const [courseId, setCourseId] = useState(0);

  const [launchId, setLaunchId] = useState(0);

  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();

  const getThumbnail = async (thumbnailUrl) => {
    const res = await APICall(GetThumbnailDetails, "POST", {
      ThumbnailUrl: thumbnailUrl,
    });

    if (res.status === 0) {
      return `data:image;base64,${res.data.thumbnailDataObject}`;
    }
  };

  const [courseData, setCourseData] = useState<any>(null);

  const [courseScheduleData, setCourseScheduleData] = useState([]);

  const getCourseScheduleData = async (courseScheduleId, launchId) => {
    const res = await APICall(getCourseScheduleDetails, "POST", {
      CourseScheduleId: courseScheduleId,
      LaunchId: launchId,
    });

    if (res.status === 0 && res.data !== null && res.data.length > 0) {
      setLaunchId(launchId);
      setCourseId(res.data[0].courseId);
      setCourseScheduleData(res.data);
      setSessionsArr(res.data);
    }
  };

  const getLMCourceMasterDataByIdApiCall = async (courseId, launchId) => {
    showLoader();
    // const responseData = await APICall(getCourseData, "POST", {
    //   Id: courseId,
    //   LaunchId: state.launchId,
    // });

    //getCourseDetailsForRequest

    const responseData = await APICall(getCourseDetailsForRequest, "POST", {
      CourseId: courseId,
      LaunchId: launchId,
    });

    if (responseData?.status === 0) {
      const coursedetails = {
        courseId: responseData?.data?.courseCode,
        courseTitle: responseData?.data?.courseTitle,
        journeyTitle: responseData?.data?.programTitle,
        startDate: responseData?.data?.startDate,
        endDate: responseData?.data?.endDate,
        trainingCenter: responseData?.data?.trainingCentre,
        trainer: responseData?.data?.trainer,
        coOrdinator: responseData?.data?.coordinator,
        learningObjective: responseData?.data?.learningObjective,
      };

      if (responseData?.data?.thumbnailUrl) {
        setProfileImg(await getThumbnail(responseData?.data?.thumbnailUrl));
      }

      setCourseData(coursedetails);
    }

    hideLoader();
  };

  const [refresh, setRefresh] = useState(false);
  const [sessionsArr, setSessionsArr] = useState([]);

  const [formErrors, setFormErrors] = useState<any>({});

  const validateForm = () => {
    let errorObj = {};
    let isError = false;

    if (!selectedCourse) {
      errorObj = {
        ...errorObj,
        ["course"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["course"]: "",
      };
    }

    if (!selectedCourseSchedule) {
      errorObj = {
        ...errorObj,
        ["courseSchedule"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["courseSchedule"]: "",
      };
    }

    if (selectedEmployees.length === 0) {
      errorObj = {
        ...errorObj,
        ["employee"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["employee"]: "",
      };
    }

    const isEmpty = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  const [selectedCourseSchedule, setSelectedCourseSchedule] = useState(null);
  const [CourseScheduleOptions, setCourseScheduleOptions] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [courseOptions, setCourseOptions] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const getCourseOptions = async () => {
    const res = await APICall(manageCourseNomination, "POST", {
      Mode: "Course",
    });

    if (res.status === 0 && res.data !== null) {
      setCourseOptions(res.data);
    } else {
      setCourseOptions([]);
    }
  };

  const getCourseScheduleOptions = async (courseId) => {
    const res = await APICall(manageCourseNomination, "POST", {
      Mode: "CourseSchedule",
      CourseId: courseId,
    });

    if (res.status === 0 && res.data !== null) {
      setCourseScheduleOptions(res.data);
    } else {
      setCourseScheduleOptions([]);
    }
  };

  useEffect(() => {
    getCourseOptions();
  }, []);

  useEffect(() => {
    if (selectedCourse) {
      getCourseScheduleOptions(selectedCourse.value);
      // getRequestHeaderDetails();
    }
  }, [selectedCourse]);

  useEffect(() => {
    if (selectedCourse && selectedCourseSchedule) {
      getLMCourceMasterDataByIdApiCall(
        selectedCourse.value,
        selectedCourseSchedule.launchId
      );
      getCourseScheduleData(
        selectedCourseSchedule.value,
        selectedCourseSchedule.launchId
      );
    }
  }, [selectedCourse, selectedCourseSchedule]);

  const formOnChange = (e, type) => {
    switch (type) {
      case "course":
        setSelectedCourse(e);
        setCourseScheduleOptions([]);
        setSelectedCourseSchedule(null);

        break;

      case "courseSchedule":
        setSelectedCourseSchedule(e);
        break;

      case "employee":
        setSelectedEmployees(e);
        break;

      default:
        break;
    }
  };

  const getSearchableDropdownAPI = async (searchString, cb) => {
    if (searchString.length >= 2) {
      //GetNomineeEmployeeDropdown
      //GetCommonDropdownForScheduling
      const response = await APICall(GetNomineeEmployeeDropdown, "POST", {
        searchString,
        // Code: "co_ordinator",
        UserId: userDetails.Id,
        RoleId: currentRoleId.value,
      });
      if (response.data !== null && response.data.length > 0) {
        cb(response.data);
      } else {
        cb([]);
      }
    }
  };

  const courseNominationSubmit = async () => {
    const isError = validateForm();

    if (isError) {
      return null;
    }

    const res = await APICall(manageCourseNomination, "POST", {
      Mode: "Submit",
      CourseScheduleId: selectedCourseSchedule?.value || 0,
      UserIds: selectedEmployees.map((s) => s.value).join(","),
      NominatedByUserId: userDetails.Id,
      NominatedByRoleId: currentRoleId.value,
    });

    if (res.status === 0) {
      setSelectedCourse(null);
      setSelectedCourseSchedule(null);
      setSelectedEmployees([]);
      setFormErrors({});
      notify(0, "Employees added to course successfully");
    } else {
      console.log("error", res);
    }
  };

  const [courseScheduleGridData, setCourseScheduleGridData] = useState([]);

  useEffect(() => {
    if (courseData && courseScheduleData.length > 0) {
      let gridData = [
        {
          courseId: courseData.courseId,
          courseTitle: courseData.courseTitle,
          journeyTitle: courseData?.journeyTitle,
          startDate: moment(courseScheduleData[0].courseStartDate).format(
            "DD-MMM-YYYY"
          ),
          endDate:
            moment(courseScheduleData[0].courseEndDate).year() === 9999
              ? "N/A"
              : courseData && moment(courseData?.endDate).format("DD-MMM-YYYY"),

          trainingCenter: courseScheduleData[0].trainingCenter,
          trainerName: courseScheduleData[0].trainerName,
          coordinatorName: courseScheduleData[0].coordinatorName,
          learningObjective: courseData.learningObjective,
        },
      ];

      setCourseScheduleGridData(gridData);
    }
  }, [courseData, courseScheduleData]);

  const courseScheduleColumns = [
    {
      name: "courseId",
      label: "Course ID",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "courseTitle",
      label: "Course Title",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "journeyTitle",
      label: "Journey Title",
      options: {
        filter: false,
        sort: false,
        width: 200,
      },
    },
    {
      name: "startDate",
      label: "Start Date",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "endDate",
      label: "End Date",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "trainingCenter",
      label: "Training Center",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "trainerName",
      label: "Trainer",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "coordinatorName",
      label: "Co-ordinator",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "learningObjective",
      label: "Learning Objective",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <span
              dangerouslySetInnerHTML={{
                __html: value,
              }}
            ></span>
          );
        },
      },
    },
  ];

  return (
    <div className="container-fluid mt-3 mb-5">
      <div className="row">
        <div className="col-lg-8 breadcrumb modified-bredcrumb">
          <span>
            <ul>
              <li>
                <a href="/home">Home</a>
              </li>
              <li>Course Nomination</li>
            </ul>
          </span>
        </div>

        <div className="col-lg-4">
          <button
            onClick={() => {
              navigate(-1);
            }}
            disabled={false}
            className="btn btn-secondary float-right"
          >
            <i className="fa-solid fa-arrow-left"></i> Back
          </button>
        </div>

        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-12 mb-3">
              <div className="form-group filtInp">
                <label className="col-form-label ">Course</label> <sup>*</sup>
                <SelectForm
                  isClearable
                  options={courseOptions}
                  placeholder={"Select Course"}
                  isDisabled={false}
                  onChange={(event) => {
                    formOnChange(event, "course");
                  }}
                  isMulti={false}
                  noIndicator={false}
                  noSeparator={false}
                  value={selectedCourse}
                />
                {formErrors["course"] && (
                  <p style={{ color: "red" }}>{formErrors["course"]}</p>
                )}
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-sm-12 mb-3">
              <div className="form-group filtInp">
                <label className="col-form-label ">Course Schedule</label>{" "}
                <sup>*</sup>
                <SelectForm
                  isClearable
                  options={CourseScheduleOptions}
                  placeholder={"Select Course Schedule "}
                  isDisabled={false}
                  onChange={(event) => {
                    formOnChange(event, "courseSchedule");
                  }}
                  isMulti={false}
                  noIndicator={false}
                  noSeparator={false}
                  value={selectedCourseSchedule}
                />
                {formErrors["courseSchedule"] && (
                  <p style={{ color: "red" }}>{formErrors["courseSchedule"]}</p>
                )}
              </div>
            </div>

            <div className="col-lg-3 col-md-4 col-sm-12 mb-3">
              <div className="dropdown form-group filtInp">
                <label className="col-form-label">Search Employees</label>{" "}
                <sup>*</sup>
                <SelectForm
                  isClearable
                  isSearchable
                  async
                  options={(searchString, cb) =>
                    getSearchableDropdownAPI(searchString, cb)
                  }
                  placeholder={"Search Employees"}
                  isDisabled={false}
                  value={selectedEmployees}
                  onChange={async (event) => {
                    formOnChange(event, "employee");
                  }}
                  isMulti={true}
                  noIndicator={false}
                  noSeparator={false}
                />
                {formErrors["employee"] && (
                  <p style={{ color: "red" }}>{formErrors["employee"]}</p>
                )}
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12 mb-3">
              <button
                onClick={() => {
                  courseNominationSubmit();
                }}
                disabled={false}
                style={{ marginTop: "32px" }}
                className="btn btn-filter-submit"
              >
                <i className="fa-solid fa-check"></i> Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      {selectedCourse && selectedCourseSchedule && (
        <>
          <hr />

          <div className="row mt-2">
            <div className="col-lg-12">
              <div className="row fnt_we">
                <div className="col-lg-2">
                  <div className="card">
                    <img
                      src={
                        profileImg != null ? profileImg : defaultProfileImgPic
                      }
                      className="card-img-top"
                      alt="Thumbnail Image"
                    />
                  </div>
                  {/* <ImageColumnComponent
          link={courseData && courseData.thumbnailUrl}
        /> */}
                </div>
                <div className="col-lg-10">
                  <div className="row">
                    <LMSDynamicGrid
                      data={courseScheduleGridData}
                      columns={courseScheduleColumns}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-lg-12">
              {sessionsArr.length > 0 &&
                sessionsArr.map((currentSession, sessionIndex) => (
                  <SessionAccordion
                    currentSession={currentSession}
                    sessionIndex={sessionIndex}
                    sessionsArr={sessionsArr}
                    setSessionsArr={setSessionsArr}
                    setRefresh={setRefresh}
                    courseId={courseId}
                    launchId={launchId}
                  />
                ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CourseNomination;
