import React, { useState, useContext, useEffect } from "react";
import SelectForm from "../../Components/SelectForm/SelectForm";
import { LoaderContext } from "../../Helpers/Context/Context";
import notify from "../../Helpers/ToastNotification";
import { APICall } from "../../Helpers/APICalls";
import {
  GetDocumentTypeListByDocumentTypeIds,
  SetDocumentURLDetails,
} from "../../Helpers/APIEndPoints/AssociateEndPoints";

type DocumentRequiredProps = {
  ispositionsubmited: boolean;
  AssociateId: any;
  scanData: any;
  setIsRefresh: any;
  getBase64FromFilePromise: any;
  isDisabled1: any;
  docId: any;
  documenTypeids: any;
  requirement: any;
  index: any;
};

const DocumentRequired: React.FC<DocumentRequiredProps> = ({
  ispositionsubmited,
  AssociateId,
  scanData,
  setIsRefresh,
  getBase64FromFilePromise,
  docId: ids,
  documenTypeids: id,
  requirement: requirment,
  index,
  isDisabled1,
}) => {
  const [documentTypeOption, setDocumentTypeOption] = useState([]);
  const [documentType, setdocumentType] = useState(null);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [ActionDocument, setActionDocument] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setfileName] = useState("");
  const [ID, setID] = useState<any>(null);
  const [MandatoryDocument, setMandatoryDocument] = useState(null);
  const [isDisabled, setisDisabled] = useState<any>(true);

  const { showLoader, hideLoader } = useContext(LoaderContext);

  const handleDocumentTypeChange = (event: any) => {
    setdocumentType(event);
    console.log(event, "event");
    setSelectedFile(event?.documentName || null);
    console.log(event, "event");
    let obj = {
      name: event?.documentName,
      Data: event?.documentDataObject,
    };

    if (event?.documentName != null) {
      setFile(obj || null);
      setActionDocument(true);
    }
  };

  const handleUpload = async () => {
    showLoader();

    if (!ispositionsubmited) {
      notify(1, "Please submit organization section first");
      hideLoader();
      return;
    }

    if (file !== null) {
      // Check file size

      const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to megabytes
      if (fileSizeInMB > 5) {
        // Show notification for exceeding 10MB
        notify(1, "File size exceeds 5MB. Please choose a smaller file.");
        hideLoader();
        return;
      }

      debugger;
      if (ActionDocument) {
        const response = await APICall(SetDocumentURLDetails, "POST", {
          SectionName: documentType?.label,
          AssociatesDetailsId: AssociateId || scanData?.CandidateId,

          DocumentTypeId: documentType?.value,
          DocumentUrl: "",
          StagesId: 2,
          DocumentsChecklistId: ID,
          file: {
            name: file?.name,
            Data: file?.Data,
          },
          IsActive: 1,
          CreatedBy: 1,
        });

        if (response?.status == 0) {
          // notify(0, "Upload Successfully");
          notify(
            0,
            `The data for Candidate ID ${
              AssociateId || scanData?.CandidateId
            } has been successfully submitted.`
          );
          setIsRefresh((prev) => !prev);
          //   handleDashboardData(CourseSchedule?.value);
          setFile(null);
          setfileName("");
          setdocumentType(null);
          setSelectedFile(null);
        }
        hideLoader();
      } else {
        let base64 = await getBase64FromFilePromise(file);
        base64 = String(base64).split("base64,")[1];

        console.log(base64, "pdf");

        const response = await APICall(SetDocumentURLDetails, "POST", {
          SectionName: documentType.label,
          AssociatesDetailsId: AssociateId || scanData?.CandidateId,

          DocumentTypeId: documentType?.value,
          DocumentUrl: "",
          StagesId: 3,
          DocumentsChecklistId: ID,
          file: {
            name: file?.name,
            Data: base64,
          },
          IsActive: 1,
          CreatedBy: 1,
        });

        if (response?.status == 0) {
          // notify(0, "Upload Successfully");
          notify(
            0,
            `The data for Candidate ID ${
              AssociateId || scanData?.CandidateId
            } has been successfully submitted.`
          );
          setIsRefresh((prev) => !prev);
          //   handleDashboardData(CourseSchedule?.value);
          setFile(null);
          setfileName("");
          setdocumentType(null);
          setSelectedFile(null);
        }
        hideLoader();
      }

      hideLoader();
    } else {
      if (!documentType) {
        notify(1, "Please select the document Type");
        hideLoader();
        return;
      }
      if (!file && MandatoryDocument == "Identity Proof") {
        notify(1, "Document Manadatory  for Identity Proof");
        hideLoader();
        return;
      }
      if (!file && MandatoryDocument == "Age Proof") {
        notify(1, "Document Manadatory for Age Proof");
        hideLoader();
        return;
      }
      hideLoader();
    }
  };

  const handleOnChange = (event) => {
    // debugger;
    const files = event.target.files[0] || null;

    if (!files) {
      setFile(null);
      notify(1, "Please select a file.");
      return;
    } else {
      // Check if the selected file is an Excel file
      // Check if the selected file is an Excel file
      const allowedExtensions = ["pdf", "jpeg", "png", "jpg"];
      const fileNameArray = files.name.split(".");
      const fileExtension =
        fileNameArray[fileNameArray.length - 1].toLowerCase();

      if (!documentType) {
        notify(1, "Please select a document type first.");
        return;
      }

      if (!allowedExtensions.includes(fileExtension)) {
        setFile(null);
        notify(1, "File should be a PDF, JPEG, JPG, PNG .");
        return;
      }

      setfileName(files.name);

      setSelectedFile(files.name);

      console.log(files, "files");

      setFile(files);
      setActionDocument(false);
    }
  };

  // GetDocumentTypeList(documenTypeids, id, requirement);
  const GetDocumentTypeList = async () => {
    showLoader();
    setdocumentType(null);
    setID(ids);
    setMandatoryDocument(requirment);
    if (id != null) {
      const response = await APICall(
        GetDocumentTypeListByDocumentTypeIds,
        "POST",
        {
          DocumentTypeIds: id,
          CheckListId: ids,
          AssociatesDetailsId: AssociateId || scanData.CandidateId,
        }
      );
      if (response?.status === 0 && response?.data.length > 0) {
        console.log(response?.data, "DocumentTypeList");
        setDocumentTypeOption(response?.data);
        setisDisabled(false);
        setSelectedFile(null);
        hideLoader();
      }
    } else {
      hideLoader();
      setisDisabled(false);
    }
  };

  useEffect(() => {
    GetDocumentTypeList();
  }, []);

  return (
    <>
      <div className="d-flex">
        <div style={{ width: "200px" }}>
          <SelectForm
            isClearable
            options={documentTypeOption}
            placeholder={"Select Document Type"}
            isDisabled={false}
            onChange={(e) => {
              //setdocumentType(e);
              handleDocumentTypeChange(e);
            }}
            isMulti={false}
            noIndicator={false}
            noSeparator={false}
            value={documentType}
          />
        </div>

        <div className="box ml-2 mr-5">
          <input
            id={`REQSUPDOC_${index}`}
            className="form-control inputfile inputfile-6 multiple-inputfile"
            multiple={false}
            type="file"
            onChange={(e) => {
              // handleSupportingDoc(e, "REQSUPDOC");
              // handleFileChange(e);
              handleOnChange(e);
              // e.target.value = null;
            }}
            // ref={supportingDocRef}
            // onChange={handleFileChange}
            accept={" .pdf, .jpeg, .png, .jpg"} // Add the additional file types here
            disabled={false}
          />
          <label
            htmlFor={`REQSUPDOC_${index}`}
            className="form-control"
            style={{ width: 0, border: "none" }}
          >
            <strong
              style={{
                padding: "6px 16px",
                backgroundColor: "#3c5464",
                borderRadius: "5px",
              }}
            >
              <i className="fa-solid fa-file" aria-hidden="true"></i>
            </strong>{" "}
          </label>
        </div>
        <div className="SectionSubmit clearfix">
          {/* <button
          onClick={() => {
            onResetData();
          }}
          disabled={false}
          className="btn btn-secondary float-right ml-2"
        >
          <i className="fa-solid fa-refresh mr-2"></i>
        </button> */}

          <button
            onClick={() => {
              handleUpload();
            }}
            disabled={isDisabled1 ? true : false}
            className="btn btn-filter-submit float-right ml-2"
          >
            <i className="fa fa-upload rotate90"></i>
          </button>
        </div>
      </div>
      {selectedFile && (
        <div className="file-added-list mt-2">
          <ul className="list-unstyle">
            <li className="list">
              <div className="media">
                <div className="text-truncate">
                  <span className="view-more mr-2">{selectedFile}</span>
                </div>
                <a
                  onClick={() => {
                    //fileRef.current.value = null;
                    setFile(null);
                    setSelectedFile(null);
                  }}
                >
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </a>
              </div>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default DocumentRequired;
