import React, { useContext } from "react";
import TextField from "@mui/material/TextField";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import InputForm from "../../Components/InputForm/InputForm";
import SelectForm from "../../Components/SelectForm/SelectForm";
import { APICall } from "../../Helpers/APICalls";
import {
  getGenericDropdownsData,
  getProjectDetailsById,
  getProjectUserMapedById,
  getADUsers,
  InsertUpdateProjectData,
  GetProjectJobDetailsDataById,
} from "../../Helpers/APIEndPoints/EndPoints";
import { getContext, LoaderContext } from "../../Helpers/Context/Context";
import notify from "../../Helpers/ToastNotification";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import JobDetail from "./JobDetail";
import AccessDenied from "../AccessDenied/AccessDenied";
import { components } from "react-select";

const ProjectEdit = () => {
  const { state } = useLocation();
  let navigate = useNavigate();
  const context = getContext();

  const newJob = {
    jobNo: "",
    name: "",
    location: "",
    description: "",
    isActive: true,
  };
  const [accordionState, setAccordionState] = useState(true);
  const [jobData, setJobData] = useState([]);
  const [isReadOnly, setIsReadOnly] = useState(
    (context.loggedInAs !== 1 && context.loggedInAs !== 2) ||
      state.hasOwnProperty("readOnly")
  );

  // useEffect(() => {
  //   console.log(jobData);
  // }, [jobData]);

  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [projectName, setProjectName] = useState<any>("");
  //const [textMember, setTextMember] = useState<any>("");
  const [projectLocation, setProjectLocation] = useState<any>("");
  const [contractValue, setContractValue] = useState<any | null>(0);
  const [jvPartner, setJVPartner] = useState<any>("");
  const [clientName, setClientName] = useState<any>("");
  const [workScope, setWorkScope] = useState<any>("");
  const [detailedEnggCons, setDetailedEnggCons] = useState<any>("");
  const [proofCons, setProofCons] = useState<any>("");
  const [safetyCons, setSafetyCons] = useState<any>("");
  const [authorityEngg, setAuthorityEngg] = useState<any>("");
  const [generalCons, setGeneralCons] = useState<any>("");
  const [indepProofChecker, setIndepProofChecker] = useState<any>("");
  const [statusOptions, setStatusOptions] = useState([]);
  const [statusVal, setStatusVal] = useState<any>([]);
  const [modeofContractOptions, setModeofContractOptions] = useState([]);
  const [modeofContractVal, setModeofContractVal] = useState<any>([]);
  const [adminsOptions, setAdminOptions] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState([]);
  const [memberOptions, setMemberOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [isLocked, setIsLocked] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [contractualStartDate, setContractualStartDate] = useState<Date | null>(
    null
  );
  const [actualStartDate, setActualStartDate] = useState<Date | null>(null);
  const [contractualEndDate, setContractualEndDate] = useState<Date | null>(
    null
  );
  const [actualEndDate, setActualEndDate] = useState<Date | null>(null);

  const GetProjectStatus = async () => {
    const response = await APICall(getGenericDropdownsData, "POST", {
      MasterType: 1,
    });
    if (response.data !== null && response.data.length > 0) {
      let status = response.data.map((element) => ({
        value: element.id,
        label: element.name,
      }));
      setStatusOptions(status);
    } else {
      setStatusOptions([]);
    }
  };

  const GetContractModeOptions = async () => {
    const response = await APICall(getGenericDropdownsData, "POST", {
      MasterType: 2,
    });
    if (response.data !== null && response.data.length > 0) {
      let mode = response.data.map((element) => ({
        value: element.id,
        label: element.name,
      }));
      setModeofContractOptions(mode);
    } else {
      setModeofContractOptions([]);
    }
  };

  useEffect(() => {
    (async () => {
      showLoader();
      await GetProjectStatus();
      await GetContractModeOptions();

      if (state.projectId && state.projectId > 0) {
        await GetProjectDetailsById();
        await GetProjectUserMapping();
      }
      hideLoader();
    })();
  }, []);

  const GetProjectDetailsById = async () => {
    const { data } = await APICall(getProjectDetailsById, "POST", {
      Id: state.projectId,
    });

    if (data !== null) {
      setProjectName(data.name);
      setStatusVal({ value: data.projectStatusId, label: data.status });
      setProjectLocation(data.projectLocation);
      setContractValue(data.contractValue);
      setModeofContractVal({
        value: data.contractModeId,
        label: data.contractMode,
      });
      setIsLocked(data.isProjectLocked);
      setJVPartner(data.jvPartner);
      setClientName(data.clientName);
      setWorkScope(data.scopeOfWork);
      setContractualStartDate(data.startDateContractural);
      setActualStartDate(data.stateDateActual);
      setContractualEndDate(data.endDateContractural);
      setActualEndDate(data.endDateActual);
      setDetailedEnggCons(data.detailEngineeringConsultant);
      setProofCons(data.proofConsultant);
      setSafetyCons(data.saftyConsultant);
      setAuthorityEngg(data.authorityEngineer);
      setIndepProofChecker(data.independentProofChecker);
      setGeneralCons(data.genralConsultant);
      setIsActive(data.isActive);
    }
  };

  const GetProjectUserMapping = async () => {
    const { data } = await APICall(getProjectUserMapedById, "POST", {
      ProjectId: state.projectId,
    });

    let a = [];
    let m = [];

    if (data !== null) {
      let adminSelectedValue = data.map((item) => {
        if (item.roleId == 2) {
          a.push({
            value: item.value,
            label: item.label,
            LoginId: item.loginId,
          });
        }
        if (item.roleId == 3) {
          m.push({
            value: item.value,
            label: item.label,
            LoginId: item.loginId,
          });
        }
      });
      setSelectedAdmin(a);
      setSelectedMembers(m);
    }
  };

  const handleTextEvent = (e, name) => {
    if (name === "projectName") {
      setProjectName(e.target.value);
    }
    if (name === "projectLocation") {
      setProjectLocation(e.target.value);
    }
    if (name === "contractValue") {
      const numRegex = /^\d+(?:\.\d+)*$/;
      if (!numRegex.test(e.target.value)) {
        setFormErrors((prev) => {
          return {
            ...prev,
            ["contractvalue_isEmpty"]: "Must be numeric",
          };
        });
      } else {
        setFormErrors((prev) => {
          return {
            ...prev,
            ["contractvalue_isEmpty"]: "",
          };
        });
      }
      setContractValue(e.target.value);
    }
    if (name === "clientName") {
      setClientName(e.target.value);
    }
    if (name === "scopeOfWork") {
      setWorkScope(e.target.value);
    }
    if (name === "jvPartner") {
      setJVPartner(e.target.value);
    }
    if (name === "contractualStartDate") {
      setContractualStartDate(e);
    }
    if (name === "actualStartDate") {
      setActualStartDate(e);
    }
    if (name === "contractualEndDate") {
      setContractualEndDate(e);
    }
    if (name === "actualEndDate") {
      setActualEndDate(e);
    }
    if (name === "DDC") {
      setDetailedEnggCons(e.target.value);
    }
    if (name === "PC") {
      setProofCons(e.target.value);
    }
    if (name === "SC") {
      setSafetyCons(e.target.value);
    }
    if (name === "AE") {
      setAuthorityEngg(e.target.value);
    }
    if (name === "GC") {
      setGeneralCons(e.target.value);
    }
    if (name === "IPC") {
      setIndepProofChecker(e.target.value);
    }
  };

  const getUsers = async (event, cb) => {
    if (event.length > 3) {
      const data = await APICall(getADUsers, "POST", { searchTerm: event });
      if (data?.length > 0) {
        let users = data.map((element) => ({
          value: element.emailId,
          label: element.displayName,
          LoginId: element.loginId,
        }));

        cb(users);
      } else {
        cb([]);
      }
    }
  };

  const selectOnChange = (event, name) => {
    if (name === "status") {
      setStatusVal(event);
      setFormErrors((prev) => {
        return {
          ...prev,
          ["status_isEmpty"]: undefined,
        };
      });
    }
    if (name === "contractMode") {
      setModeofContractVal(event);
      setFormErrors((prev) => {
        return {
          ...prev,
          ["contractmode_isEmpty"]: undefined,
        };
      });
    }
    if (name === "admin") {
      setSelectedAdmin(event);
    }
    if (name === "member") {
      setSelectedMembers(event);
    }
  };

  const handleCheckBox = (name) => {
    if (name === "isActive") {
      setIsActive((current) => !current);
    }
    if (name === "isLocked") {
      setIsLocked((current) => !current);
    }
  };

  const sortData = async (jobData) => {
    let data = jobData;

    data.forEach((job) => {
      if (
        job.hasOwnProperty("structureDetails") &&
        job.structureDetails.length > 0
      ) {
        job.structureDetails.forEach((structure) => {
          if (
            structure.hasOwnProperty("activityDetails") &&
            structure.activityDetails.length > 0
          ) {
            structure.activityDetails.forEach((activity) => {
              if (
                activity.hasOwnProperty("parameterDetails") &&
                activity.parameterDetails.length > 0
              ) {
                activity.parameterDetails.sort((a, b) => a.paSeqNo - b.paSeqNo);
              }
            });
            structure.activityDetails.sort((a, b) =>
              a.acSeqNo > b.acSeqNo ? 1 : -1
            );
          }
        });
        job.structureDetails.sort((a, b) => (a.strSeqNo > b.strSeqNo ? 1 : -1));
      }
    });

    data.sort((a, b) => (a.jobNo > b.jobNo ? 1 : -1));
  };

  const checkValidation = async (jobData) => {
    const data = [...jobData];
    const regex = /^[1-9]\d*(?:\.[1-9]\d*)?(?:\.[1-9]\d*)?$/;

    let isValid = true;

    for (let a = 0; a < data.length; a++) {
      const job = data[a];

      if (job.name === "" || !regex.test(job.jobNo)) {
        isValid = false;
        break;
      }

      if (
        job.hasOwnProperty("structureDetails") &&
        job.structureDetails.length > 0
      ) {
        for (let i = 0; i < job.structureDetails.length; i++) {
          const structure = job.structureDetails[i];

          if (structure.name === "" || !regex.test(structure.strSeqNo)) {
            isValid = false;
            break;
          }
          if (
            structure.hasOwnProperty("activityDetails") &&
            structure.activityDetails.length > 0
          ) {
            for (let j = 0; j < structure.activityDetails.length; j++) {
              const activity = structure.activityDetails[j];

              if (activity.name === "" || !regex.test(activity.acSeqNo)) {
                isValid = false;
                break;
              }
              if (
                activity.hasOwnProperty("parameterDetails") &&
                activity.parameterDetails.length > 0
              ) {
                for (let k = 0; k < activity.parameterDetails.length; k++) {
                  const parameter = activity.parameterDetails[k];

                  if (!regex.test(parameter.paSeqNo)) {
                    isValid = false;
                    break;
                  }

                  if (
                    parameter.name === null ||
                    !parameter.hasOwnProperty("paValue") ||
                    parameter.paValue === null ||
                    parameter.paValue === "" ||
                    (parameter.name.isUOMApplicable === false &&
                      parameter.uom === null) // This is isUOMNotApplicable
                  ) {
                    isValid = false;
                    break;
                  }

                  if (
                    parameter.name?.dataType === "Numeric" ||
                    parameter.name?.dataType === "Currency"
                  ) {
                    const numbericRegex = /^\d+(?:\.\d+)*$/;
                    if (!numbericRegex.test(parameter.paValue)) {
                      isValid = false;
                      break;
                    }
                  }
                  if (
                    parameter.name?.dataType === "Date" &&
                    parameter.paValue === "Invalid date"
                  ) {
                    isValid = false;
                    break;
                  }
                }
              }
            }
          }
        }
      }
    }

    return isValid;
  };

  const checkSequence = (value) => {
    let data = value;

    // first number check
    if (Number(data[0]) !== 1) {
      return false;
    }

    const removeCommonPrefix = (a, b) => {
      const minLen = Math.min(a.length, b.length);

      let i = 0;
      while (i < minLen && a[i] === b[i]) ++i;

      return [a.substr(0, i), a.substr(i), b.substr(i)];
    };

    const FindError = (data) => {
      for (let i = 1; i < data.length; ++i) {
        const [prevValue, value] = [data[i - 1], data[i]];

        if (value === prevValue) return `Duplicate ${value}`;

        const [prefix, prevSuffix, suffix] = removeCommonPrefix(
          prevValue,
          value
        );

        if (prevSuffix === "") {
          if (suffix !== ".1") return `Expected ${prevValue}.1, got ${value}`;
        } else if (suffix !== String(parseInt(prevSuffix) + 1)) {
          return `Expected ${prefix}${parseInt(prevSuffix) + 1}, got ${value}`;
        }
      }
      return null; // no error
    };

    const error = FindError(data);
    console.log(error || "No error");

    return error === null; // Does the sequence pass the check without any errors?
  };

  const onClickFunction = async (e, name) => {
    if (name === "Cancel") {
      navigate("/ProjectDashboard");
    }
    if (name === "Reset") {
      if (state.projectId === 0) {
        setProjectName("");
        setStatusVal([]);
        setProjectLocation("");
        setContractValue(0);
        setClientName("");
        setModeofContractVal([]);
        setIsLocked(false);
        setIsActive(true);
      }
      setWorkScope("");
      setJVPartner("");
      setContractualStartDate(null);
      setContractualEndDate(null);
      setActualStartDate(null);
      setActualEndDate(null);
      setDetailedEnggCons("");
      setProofCons("");
      setSafetyCons("");
      setAuthorityEngg("");
      setGeneralCons("");
      setIndepProofChecker("");
      setFormErrors({});
    }
    if (name === "Submit") {
      let error = Validation();

      if (!error) {
        if (jobData.length > 0) {
          const isDataValid = await checkValidation(jobData);

          if (isDataValid) {
            sortData(jobData);

            let isSequenceValid = false;

            let data = jobData.map((ele) => ele.jobNo);

            isSequenceValid = checkSequence(data);

            if (isSequenceValid) {
              for (let a = 0; a < jobData.length; a++) {
                const job = jobData[a];

                if (
                  job.hasOwnProperty("structureDetails") &&
                  job.structureDetails.length > 0
                ) {
                  let data = job.structureDetails.map((ele) => ele.strSeqNo);
                  isSequenceValid = checkSequence(data);

                  if (isSequenceValid === false) {
                    break;
                  }

                  for (let i = 0; i < job.structureDetails.length; i++) {
                    const structure = job.structureDetails[i];

                    if (
                      structure.hasOwnProperty("activityDetails") &&
                      structure.activityDetails.length > 0
                    ) {
                      let data = structure.activityDetails.map(
                        (ele) => ele.acSeqNo
                      );
                      isSequenceValid = checkSequence(data);

                      if (isSequenceValid === false) {
                        break;
                      }

                      for (
                        let j = 0;
                        j < structure.activityDetails.length;
                        j++
                      ) {
                        const activity = structure.activityDetails[j];

                        if (
                          structure.activityDetails[j].hasOwnProperty(
                            "parameterDetails"
                          ) &&
                          activity.parameterDetails.length > 0
                        ) {
                          let data = activity.parameterDetails.map(
                            (ele) => ele.paSeqNo
                          );
                          isSequenceValid = checkSequence(data);
                          if (isSequenceValid === false) {
                            break;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            if (isSequenceValid) {
              await InsertUpdateProjectDetails();
            } else {
              notify(1, "Invalid sequence!");
            }
          } else {
            notify(1, "Invalid input!");
          }
        } else {
          await InsertUpdateProjectDetails();
        }
      }
    }
  };

  const Validation = () => {
    let isError = false;
    setFormErrors({});
    if (projectName.trim().length == 0) {
      setFormErrors((prev) => {
        return {
          ...prev,
          ["projectname_isEmpty"]: "Project name required",
        };
      });
      isError = true;
    }
    if (statusVal.length == 0) {
      setFormErrors((prev) => {
        return {
          ...prev,
          ["status_isEmpty"]: "Status required",
        };
      });
      isError = true;
    }
    if (projectLocation.trim().length == 0) {
      setFormErrors((prev) => {
        return {
          ...prev,
          ["location_isEmpty"]: "Project location required",
        };
      });
      isError = true;
    }
    if (
      contractValue === 0 ||
      contractValue === undefined ||
      contractValue === null
    ) {
      setFormErrors((prev) => {
        return {
          ...prev,
          ["contractvalue_isEmpty"]: "Contract value required",
        };
      });
      isError = true;
    } else if (isNaN(parseFloat(contractValue)) === true) {
      setFormErrors((prev) => {
        return {
          ...prev,
          ["contractvalue_isEmpty"]: "Enter numeric values ",
        };
      });
      isError = true;
    }
    if (clientName.trim().length == 0) {
      setFormErrors((prev) => {
        return {
          ...prev,
          ["clientname_isEmpty"]: "Client name required",
        };
      });
      isError = true;
    }
    if (modeofContractVal.length === 0) {
      setFormErrors((prev) => {
        return {
          ...prev,
          ["contractmode_isEmpty"]: "Contract mode required",
        };
      });
      isError = true;
    }
    if (context.loggedInAs === 1 && selectedAdmin.length === 0) {
      setFormErrors((prev) => {
        return {
          ...prev,
          ["projectadmin_isEmpty"]: "Project admin required",
        };
      });
      isError = true;
    }

    return isError;
  };

  const InsertUpdateProjectDetails = async () => {
    let requestParams = {
      Id: state === null ? 0 : state.projectId,
      UserId: context.userId,
      Name: projectName,
      ProjectStatusId: statusVal.value,
      ProjectLocation: projectLocation,
      ContractValue: parseFloat(contractValue),
      ContractModeId: modeofContractVal.value,
      IsProjectLocked: isLocked,
      JVPartner: jvPartner,
      ClientName: clientName,
      ScopeOfWork: workScope,
      StartDateContractural: contractualStartDate,
      StateDateActual: actualStartDate,
      EndDateContractural: contractualEndDate,
      EndDateActual: actualEndDate,
      DetailEngineeringConsultant:
        detailedEnggCons !== "" ? detailedEnggCons : null,
      ProofConsultant: proofCons !== "" ? proofCons : null,
      SaftyConsultant: safetyCons !== "" ? safetyCons : null,
      AuthorityEngineer: authorityEngg !== "" ? authorityEngg : null,
      GenralConsultant: generalCons !== "" ? generalCons : null,
      IndependentProofChecker:
        indepProofChecker !== "" ? indepProofChecker : null,
      IsActive: isActive,
      AdminUsers: selectedAdmin,
      MemberUsers: selectedMembers,
      AuthenticationType: 1,
      IsAccounttLocked: false,
      projectJobDetails: jobData,
    };

    const data = await APICall(InsertUpdateProjectData, "POST", requestParams);

    if (data.data !== null) {
      if (state.projectId > 0) {
        notify(0, "Data Updated Successfully.");
      } else {
        notify(0, "Data Saved Successfully.");
      }
      navigate("/ProjectDashboard");
    } else {
      data.message === "mapped"
        ? setFormErrors({
            ["projectname_isEmpty"]: "Project is already mapped",
          })
        : notify(1, "Something went wrong, Try again later.");
    }
  };

  const GetProjectJobDetails = async () => {
    showLoader();
    const res = await APICall(GetProjectJobDetailsDataById, "POST", {
      ProjectId: state.projectId,
    });

    if (res.data !== null && res.data.length > 0) {
      setJobData(res.data);
      sortData(res.data);
    } else {
      setJobData([]);
    }
    hideLoader();
  };

  useEffect(() => {
    GetProjectJobDetails();
  }, []);

  // const NoOptionsMessage = (props) => {
  //   return (
  //     <components.NoOptionsMessage {...props}>
  //       <span>Search</span>
  //     </components.NoOptionsMessage>
  //   );
  // };

  return (
    <>
      <div className="form-main px-3">
        <div className="page-title w-100">
          <div className="col-lg-12 p-0">
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <h4>Project</h4>
              </div>
              {!isReadOnly && (
                <div className="col-lg-8 col-md-8">
                  <div className="float-right">
                    {(context.loggedInAs === 1 || context.loggedInAs === 2) && (
                      <>
                        <button
                          className="btn  btn-success"
                          style={{ marginLeft: 5 }}
                          onClick={(e) => onClickFunction(e, "Submit")}>
                          <i className="fa fa-save"></i> Submit
                        </button>
                        {/* <button
                                className="btn btn-info"
                                style={{ marginLeft: 5 }}
                                onClick={(e) => onClickFunction(e, "Reset")}
                              >
                                Reset
                              </button> */}
                        <button
                          className="btn btn-cancel"
                          style={{ marginLeft: 5 }}
                          onClick={(e) => onClickFunction(e, "Cancel")}>
                          <i className="fa fa-times" aria-hidden="true"></i>{" "}
                          Cancel
                        </button>
                        <button
                          className="btn btn-info"
                          style={{ marginLeft: 5 }}
                          disabled={false}
                          onClick={(e) => {
                            setJobData((prev) => [...prev, newJob]);
                          }}>
                          <i className="fa fa-sitemap"></i> Add Job Number
                        </button>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <React.Fragment>
        <Accordion
          className="mb-2"
          expanded={accordionState}
          onChange={() => setAccordionState((prev) => !prev)}>
          <AccordionSummary
            className="text-white"
            style={{ background: "rgb(151 151 151)", height: "20px" }}
            expandIcon={<ExpandMore />}
            id={state.projectId}>
            <div className="d-flex align-items-center">
              {projectName !== null && projectName}
            </div>
          </AccordionSummary>

          <AccordionDetails className="page_heading">
            <div className="container-fluid">
              <div
                style={{ padding: 15 }}
                className="row align-items-center justify-content-between">
                <div className="row">
                  <div className="col-lg-3 col-sm-3 col-xs-4 ">
                    <div className="form-group">
                      <label className="col-form-label">Project Name</label>
                      <sup>*</sup>
                      <InputForm
                        className="form-control form-control-lg input_form_height "
                        placeholder={"Project Name"}
                        isDisabled={context.loggedInAs !== 1 || isReadOnly}
                        textArea={false}
                        value={projectName}
                        onChange={(e) => {
                          handleTextEvent(e, "projectName");
                        }}
                        maxLength="255"
                      />
                      <p style={{ color: "red" }}>
                        {formErrors["projectname_isEmpty"]}
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-3 col-xs-4 ">
                    <div className="form-group">
                      <label className="col-form-label">Status</label>
                      <sup>*</sup>
                      <SelectForm
                        options={statusOptions}
                        placeholder="Select status"
                        isDisabled={
                          isReadOnly
                            ? true
                            : context.loggedInAs === 1
                            ? false
                            : context.loggedInAs === 2
                            ? false
                            : true
                        }
                        value={statusVal}
                        onChange={(event) => {
                          selectOnChange(event, "status");
                        }}
                        isMulti={false}
                        noIndicator={false}
                        noSeparator={false}
                      />
                      <p style={{ color: "red" }}>
                        {formErrors["status_isEmpty"]}
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-3 col-xs-4 ">
                    <div className="form-group">
                      <label className="col-form-label">Project Location</label>
                      <sup>*</sup>
                      <InputForm
                        className="form-control form-control-lg input_form_height "
                        placeholder={"Location"}
                        isDisabled={context.loggedInAs !== 1 || isReadOnly}
                        textArea={false}
                        value={projectLocation}
                        onChange={(e) => handleTextEvent(e, "projectLocation")}
                        maxLength="255"
                      />
                      <p style={{ color: "red" }}>
                        {formErrors["location_isEmpty"]}
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-3 col-xs-4 ">
                    <div className="form-group">
                      <label className="col-form-label">
                        Contract Value (₹ Crore)
                      </label>
                      <sup>*</sup>
                      <InputForm
                        className="form-control form-control-lg input_form_height "
                        placeholder={"Contract Value"}
                        isDisabled={
                          isReadOnly
                            ? true
                            : context.loggedInAs === 1
                            ? false
                            : context.loggedInAs === 2
                            ? false
                            : true
                        }
                        textArea={false}
                        value={contractValue}
                        onChange={(e) => handleTextEvent(e, "contractValue")}
                        maxLength="15"
                        // onKeyPress={(event) => {
                        //   if (!/^\d+(?:\.\d+)*$/.test(event.key)) {
                        //     event.preventDefault();
                        //   }
                        // }}
                      />
                      <p style={{ color: "red" }}>
                        {formErrors["contractvalue_isEmpty"]}
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-3 col-xs-4 ">
                    <div className="form-group">
                      <label className="col-form-label">Mode of Contract</label>
                      <sup>*</sup>
                      <SelectForm
                        options={modeofContractOptions}
                        placeholder="Select contract mode"
                        isDisabled={context.loggedInAs !== 1 || isReadOnly}
                        value={modeofContractVal}
                        onChange={(event) => {
                          selectOnChange(event, "contractMode");
                        }}
                        isMulti={false}
                        noIndicator={false}
                        noSeparator={false}
                      />
                      <p style={{ color: "red" }}>
                        {formErrors["contractmode_isEmpty"]}
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-3 col-xs-4 ">
                    <div className="form-group">
                      <label className="col-form-label">Client Name</label>
                      <sup>*</sup>
                      <InputForm
                        className="form-control form-control-lg input_form_height"
                        placeholder={"Client name"}
                        isDisabled={context.loggedInAs !== 1 || isReadOnly}
                        textArea={false}
                        value={clientName}
                        onChange={(e) => handleTextEvent(e, "clientName")}
                        maxLength="255"
                      />
                      <p style={{ color: "red" }}>
                        {formErrors["clientname_isEmpty"]}
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-3 col-xs-4 ">
                    <div className="form-group">
                      <label className="col-form-label">Project Admin</label>
                      {context.loggedInAs === 1 && <sup>*</sup>}
                      <SelectForm
                        async
                        isClearable
                        isSearchable
                        options={(event, cb) => getUsers(event, cb)}
                        placeholder="Search admin"
                        isDisabled={context.loggedInAs !== 1 || isReadOnly}
                        value={selectedAdmin}
                        onChange={(event) => {
                          selectOnChange(event, "admin");
                        }}
                        isMulti={true}
                        noIndicator={true}
                        noSeparator={false}
                      />
                      <p style={{ color: "red" }}>
                        {formErrors["projectadmin_isEmpty"]}
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-3 col-xs-4 ">
                    <div className="form-group">
                      <label className="col-form-label">Project Member</label>
                      <SelectForm
                        async
                        isClearable
                        isSearchable
                        options={(event, cb) => getUsers(event, cb)}
                        placeholder="Search member"
                        isDisabled={context.loggedInAs !== 1 || isReadOnly}
                        value={selectedMembers}
                        onChange={(event) => {
                          selectOnChange(event, "member");
                        }}
                        isMulti={true}
                        noIndicator={true}
                        noSeparator={false}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-3 col-xs-4 ">
                    <div className="form-group">
                      <label htmlFor="isLocked" className="col-form-label">
                        Lock Project ?
                      </label>
                      <sup>*</sup>
                      <div className="custom-checkbox">
                        <input
                          disabled={context.loggedInAs !== 1 || isReadOnly}
                          type="checkbox"
                          onChange={() => handleCheckBox("isLocked")}
                          name="isLocked"
                          id="isLocked"
                          checked={isLocked}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-3 col-xs-4 ">
                    <div className="form-group">
                      <label htmlFor="isActive" className="col-form-label">
                        Active Status
                      </label>
                      <sup>*</sup>
                      <div className="custom-checkbox">
                        <input
                          disabled={
                            isReadOnly
                              ? true
                              : state.projectId == 0
                              ? true
                              : context.loggedInAs === 1
                              ? false
                              : true
                          }
                          //disabled={false}
                          type="checkbox"
                          onChange={() => handleCheckBox("isActive")}
                          id="isActive"
                          name="isActive"
                          checked={isActive}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-8 col-sm-3 col-xs-4 ">
                    <div className="form-group">
                      <label className="col-form-label">Scope of Work</label>
                      <InputForm
                        className="form-control form-control-lg input_form_height "
                        placeholder={"Scope of Work"}
                        isDisabled={
                          isReadOnly
                            ? true
                            : context.loggedInAs === 1
                            ? false
                            : context.loggedInAs === 2
                            ? false
                            : true
                        }
                        textArea={true}
                        rows={3}
                        value={workScope}
                        onChange={(e) => {
                          handleTextEvent(e, "scopeOfWork");
                        }}
                        maxLength="500"
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 col-sm-3 col-xs-4 ">
                    <div className="form-group">
                      <label className="col-form-label">JV Partner</label>
                      <InputForm
                        className="form-control form-control-lg input_form_height "
                        placeholder={"JV Partner"}
                        isDisabled={
                          isReadOnly
                            ? true
                            : context.loggedInAs === 1
                            ? false
                            : context.loggedInAs === 2
                            ? false
                            : true
                        }
                        textArea={false}
                        value={jvPartner}
                        onChange={(e) => {
                          handleTextEvent(e, "jvPartner");
                        }}
                        maxLength="255"
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        Start Date - Contractual
                      </label>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          disabled={
                            isReadOnly
                              ? true
                              : context.loggedInAs === 1
                              ? false
                              : context.loggedInAs === 2
                              ? false
                              : true
                          }
                          value={contractualStartDate}
                          className="w-100 bg-white"
                          onChange={(e) =>
                            handleTextEvent(e, "contractualStartDate")
                          }
                          inputFormat="dd-MMM-yyyy"
                          //minDate={}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        Start Date - Actual
                      </label>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          disabled={
                            isReadOnly
                              ? true
                              : context.loggedInAs === 1
                              ? false
                              : context.loggedInAs === 2
                              ? false
                              : true
                          }
                          value={actualStartDate}
                          className="w-100 bg-white"
                          onChange={(e) =>
                            handleTextEvent(e, "actualStartDate")
                          }
                          inputFormat="dd-MMM-yyyy"
                          //minDate={}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </LocalizationProvider>
                      {/* <p style={{ color: "red" }}>
                                {formErrors["toDate_isEmpty"]}
                              </p> */}
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        End Date - Contractual
                      </label>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          disabled={
                            isReadOnly
                              ? true
                              : context.loggedInAs === 1
                              ? false
                              : context.loggedInAs === 2
                              ? false
                              : true
                          }
                          value={contractualEndDate}
                          className="w-100 bg-white"
                          onChange={(e) =>
                            handleTextEvent(e, "contractualEndDate")
                          }
                          inputFormat="dd-MMM-yyyy"
                          //minDate={}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </LocalizationProvider>
                      {/* <p style={{ color: "red" }}>
                                {formErrors["toDate_isEmpty"]}
                              </p> */}
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="form-group">
                      <label className="col-form-label">
                        End Date - Actual
                      </label>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          disabled={
                            isReadOnly
                              ? true
                              : context.loggedInAs === 1
                              ? false
                              : context.loggedInAs === 2
                              ? false
                              : true
                          }
                          value={actualEndDate}
                          className="w-100 bg-white"
                          onChange={(e) => handleTextEvent(e, "actualEndDate")}
                          inputFormat="dd-MMM-yyyy"
                          //minDate={}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </LocalizationProvider>
                      {/* <p style={{ color: "red" }}>
                                {formErrors["toDate_isEmpty"]}
                              </p> */}
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-3 col-xs-4 ">
                    <div className="form-group">
                      <label className="col-form-label">
                        Detailed Engineering Consultant (DDC)
                      </label>
                      <InputForm
                        className="form-control form-control-lg input_form_height "
                        placeholder={"Detailed Engineering Consultant"}
                        isDisabled={
                          isReadOnly
                            ? true
                            : context.loggedInAs === 1
                            ? false
                            : context.loggedInAs === 2
                            ? false
                            : true
                        }
                        textArea={false}
                        value={detailedEnggCons}
                        onChange={(e) => handleTextEvent(e, "DDC")}
                        maxLength="255"
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-3 col-xs-4 ">
                    <div className="form-group">
                      <label className="col-form-label">
                        Proof Consultant (PC)
                      </label>
                      <InputForm
                        className="form-control form-control-lg input_form_height "
                        placeholder={"Proof Consultant"}
                        isDisabled={
                          isReadOnly
                            ? true
                            : context.loggedInAs === 1
                            ? false
                            : context.loggedInAs === 2
                            ? false
                            : true
                        }
                        textArea={false}
                        value={proofCons}
                        onChange={(e) => handleTextEvent(e, "PC")}
                        maxLength="255"
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-3 col-xs-4 ">
                    <div className="form-group">
                      <label className="col-form-label">
                        Safety Consultant (SC)
                      </label>
                      <InputForm
                        className="form-control form-control-lg input_form_height "
                        placeholder={"Safety Consultant"}
                        isDisabled={
                          isReadOnly
                            ? true
                            : context.loggedInAs === 1
                            ? false
                            : context.loggedInAs === 2
                            ? false
                            : true
                        }
                        textArea={false}
                        value={safetyCons}
                        onChange={(e) => handleTextEvent(e, "SC")}
                        maxLength="255"
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-3 col-xs-4 ">
                    <div className="form-group">
                      <label className="col-form-label">
                        Authority Engineer (AE)
                      </label>
                      <InputForm
                        className="form-control form-control-lg input_form_height "
                        placeholder={"Authority Engineer"}
                        isDisabled={
                          isReadOnly
                            ? true
                            : context.loggedInAs === 1
                            ? false
                            : context.loggedInAs === 2
                            ? false
                            : true
                        }
                        textArea={false}
                        value={authorityEngg}
                        onChange={(e) => handleTextEvent(e, "AE")}
                        maxLength="255"
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-3 col-xs-4 ">
                    <div className="form-group">
                      <label className="col-form-label">
                        General Consultant (GC)
                      </label>
                      <InputForm
                        className="form-control form-control-lg input_form_height "
                        placeholder={"General Consultant"}
                        isDisabled={
                          isReadOnly
                            ? true
                            : context.loggedInAs === 1
                            ? false
                            : context.loggedInAs === 2
                            ? false
                            : true
                        }
                        textArea={false}
                        value={generalCons}
                        onChange={(e) => handleTextEvent(e, "GC")}
                        maxLength="255"
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-3 col-xs-4 ">
                    <div className="form-group">
                      <label className="col-form-label">
                        Independent Proof Checker (IPC)
                      </label>
                      <InputForm
                        className="form-control form-control-lg input_form_height "
                        placeholder={"Independent Proof Checker"}
                        isDisabled={
                          isReadOnly
                            ? true
                            : context.loggedInAs === 1
                            ? false
                            : context.loggedInAs === 2
                            ? false
                            : true
                        }
                        textArea={false}
                        value={indepProofChecker}
                        onChange={(e) => handleTextEvent(e, "IPC")}
                        maxLength="255"
                      />
                    </div>
                  </div>
                </div>
                {/* 
                <div className="col-lg-12 p-0">
                  <div className="row">
                    <div className="col-lg-4 col-md-4"></div>
                    <div className="col-lg-4 col-md-2"></div>
                    <div className="col-lg-4 col-md-6">
                      <div
                        className="float-right col-sm-offset-2"
                        style={{
                          marginBottom: 10,
                        }}></div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </React.Fragment>

      <React.Fragment>
        <div className="mx-1 px-1 mt-2">
          {jobData.length > 0 &&
            jobData.map((job, jobIndex) => (
              <React.Fragment key={jobIndex}>
                <JobDetail
                  jobData={jobData}
                  setJobData={setJobData}
                  job={job}
                  jobIndex={jobIndex}
                  isReadOnly={isReadOnly}
                />
              </React.Fragment>
            ))}
        </div>
      </React.Fragment>
    </>
  );
};

export default ProjectEdit;
