import React, { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { getContext } from "../Helpers/Context/Context";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import Sidebar from "../Layout/Sidebar";
import AccessDenied from "../Pages/AccessDenied/AccessDenied";
import Dashboard from "../Pages/Dashboard/Dashboard";
import Request from "../Pages/Dashboard/Request/Request";
import InvoiceType from "../Pages/InvoiceType/InvoiceType";
import InvoiceTypeEdit from "../Pages/InvoiceType/InvoiceTypeEdit";
import Login from "../Pages/Login/Login";
import ParameterDashboard from "../Pages/Parameter/ParameterDashboard";
import ParameterEdit from "../Pages/Parameter/ParameterEdit";
import ProjectDashboard from "../Pages/Project/ProjectDashboard";
import ProjectEdit from "../Pages/Project/ProjectEdit";
import StructureType from "../Pages/StructureType/StructureType";
import StructureTypeEdit from "../Pages/StructureType/StructureTypeEdit";
// added by Yawar
import PositionDashboard from "../Pages/PositionManagement/PositionDashboard";
import JobProfileDashboard from "../Pages/JobProfile/JobProfileDashboard";
import AgencyDashboard from "../Pages/Agency/AgencyDashboard";
import AgencyCandidateDashboard from "../Pages/AgencyCandidate/AgencyCandidateDashboard";
import AnonymousCandidateDashboard from "../Pages/AnonymousCandidate/AnonymousCandidateDashboard";
import CandidateForm from "../Components/FromStructure/CandidateForm";
import CandidateFormReview from "../Components/FromStructure/CandidateReview";
//--------------------------
import UserEdit from "../Pages/User/UserEdit";
import UserDashboard from "../Pages/User/UserDashboard";
import LOP from "../Pages/LOP/LOP";
import FormStructure from "../Components/FromStructure/FormStructure";
import CLMFormstructure from "../Components/CLMFormstructure/CLMFormstructure";
import "./router.css";
import GridStructure from "../Components/GridStructure/GridStructure";
import ContractLabour from "../Components/ContractLabour/ContractLabour";
import useUserContext from "../Helpers/ZustandStore/UserContextStore";
import MainLogin from "../Pages/MainLogin/MainLogin";
import MRF from "../Pages/MRF/MRF";
import Agency from "../Pages/Agency/Agency";

import Catalogue from "../Pages/Catalogue/Catalogue";

import JobProfileForm from "../Components/JobProfile/JobProfileForm";
import PositionManageForm from "../Components/PositionForm/PositionForm";
import MrfDashboard from "../Pages/MRF/MrfDashboard";

import Protected from "../Helpers/PageAccess/Protected";
import ProtectedNew from "../Helpers/PageAccess/ProtectedNew";
import MRF_Protected from "../Helpers/PageAccess/MRF_Protected";
import AgencyCandidate from "../Pages/AgencyCandidate/AgencyCandidate";
import SSO from "../Pages/SSO/SSO";
import AnonymousCandidate from "../Pages/AnonymousCandidate/AnonymousCandidate";
import Home from "../Pages/Home/Home";
import CommonDashboard from "../Pages/CommonDashboard/CommonDashboard";
import BulkUpload from "../Pages/BulkUpload/BulkUpload";
import ReviewerSection from "../Pages/Progression/ReviewerSection";
import ReviewerSectionClm from "../Pages/ProgressionClm/ReviewerSectionClm";
import ProgRequisitionDetatils from "../Pages/Progression/Requisition";
import CopyOfSSo from "../Pages/Progression/CopyOfSSo";
import ProgRequisitionDetatilsCLM from "../Pages/ProgressionClm/RequisitionClm";
import ProgRevoke from "../Pages/Progression/Revoke";
import SendbackRequisitionDetatils from "../Pages/Progression/SendbackReq";
import OrganizationUnitForm from "../Pages/OrganizationUnit/OrganizationUnitForm";
import OrgUnitBulkUpload from "../Pages/OrganizationUnit/OrgUnitBulkUpload";
import SendbackRequisitionDetatilsClm from "../Pages/ProgressionClm/SendbackReqClm";
import OrganizationUnit from "../Pages/OrganizationUnit/OrganizationUnit";
import OrganizationUnitProgressionDashboard from "../Pages/OrganizationUnit/OrganizationUnitProgressionDashboard";
import OrganizationUnitDashboard from "../Pages/OrganizationUnit/OrganizationUnitDashboard";
import ViewRequisitionDetatils from "../Pages/Progression/RequisitionView";
import ViewRequisitionDetatilsClm from "../Pages/ProgressionClm/RequisitionViewClm";
import ProgResignation from "../Pages/Progression/Resignation";
import NewHire from "../Pages/NewHire/NewHire";
import NewHireApproval from "../Pages/NewHire/NewHireApproval";
import NewHireApprovalClm from "../Pages/NewHireClm/NewHireApprovalClm";
import Report from "../Pages/ReportMaster/Report";
import UserRoleMaster from "../Pages/UserRoleMaster/UserRoleMaster";
import OrganizationChart from "../Pages/OrganizationChart/OrganizationChart";
import UserRoleDashboard from "../Pages/UserRoleMaster/UserRoleDashboard";

import ProgramDashboard from "../Pages/Program/ProgramDashboard";
import Program from "../Pages/Program/Program";
import Programcourse from "../Pages/Program/Programcourse";
import Participant from "../Pages/Participant/Participant";
import Content from "../Pages/Content/Content";
import Trainer from "../Pages/Trainer/Trainer";
import TrainerMaster from "../Pages/TrainerMaster/TrainerMaster";
import Trainers from "../Pages/TrainerMaster/Trainers";
import TrainingCenterDashboard from "../Pages/TrainingCenter/TrainingCenterDashboard";
import TrainingCentreMaster from "../Pages/TrainingCenter/TrainingCentreMaster";
import EmployeeList from "../Pages/EmployeeList/EmployeeList";
import EmployeeListProfileDashboard from "../Pages/EmployeeList/EmployeeListProfileDashboard";

import RecruiterDashboard from "../Pages/Recruiter/RecruiterDashboard";
import CandidateDashboard from "../Pages/Recruiter/CandidateDashboard";

import OnBoardingCheckList from "../Pages/OnBoarding/OnBoardingCheckList";
import OnBoardingApprovalDashboard from "../Pages/OnBoarding/OnBoardingApprovalDashboard";
import CandidateOnBoardingApprovalDashboard from "../Pages/OnBoarding/CandidateOnboardingApprovalDashboard";

import GenericMasterDashboard from "../Pages/GenericMaster/GenericMasterDashboard";
import GenericMasterForm from "../Pages/GenericMaster/GenericMasterForm";
import PicklistAndMasterMapForm from "../Pages/GenericMaster/PicklistAndMasterMapForm";
import PicklistBulkUpload from "../Pages/GenericMaster/PicklistBulkUpload";
import GenericMasterFormUpdate from "../Pages/GenericMaster/GenericMasterFormUpdate";
import TaskChecklist from "../Pages/TaskChecklist/TaskChecklist";
import TaskChecklistDashboard from "../Pages/TaskChecklist/TaskChecklistDashboard";
import { APICall } from "../Helpers/APICalls";
import { CheckSessionTimer } from "../Helpers/APIEndPoints/EndPoints";
import WorkFlowDashboard from "../Pages/WorkFlowMaster/WorkFlowDashboard";
import WorkFlowForm from "../Pages/WorkFlowMaster/WorkFlowForm";
import RestrictedPageForCandidateOrAgency from "../Helpers/PageAccess/HomePageAcces";
import RecruiterCandidate from "../Pages/Recruiter/RecruiterCandidate";
import EmailTemplate from "../Pages/EmailTemplate/EmailTemplate";
import EmailTemplateDashboard from "../Pages/EmailTemplate/EmailTemplateDashboard";
import DelegationProcess from "../Pages/DelegationProcess";
import CoursesDashboard from "../Pages/Courses/CoursesDashboard";
import Courses from "../Pages/Courses/Courses";
import Session from "../Pages/Session/Session";
import SessionEdit from "../Pages/Session/SessionEdit";
import Registration from "../Pages/RegistrationRequest/Registration";
import RequestDashboard from "../Pages/RequestDashboard/RequestDashboard";
import AddScheduleCourse from "../Pages/Courses/ScheduleCourseAndSession/AddScheduleCourse";
import ScheduleCourseAndSession from "../Pages/Courses/ScheduleCourseAndSession/ScheduleCourseAndSession";
import Contentedit from "../Pages/Content/ContentEdit";
import Participation from "../Pages/Courses/Particiption/Participation";
import TargetLearnerGroup from "../Pages/TargetLearnerGroup/TargetLearnerGroup";
import ContentViewer from "../Pages/ContentViewer/ContentViewer";
import OnBoardingDashboard from "../Pages/OnBoarding/OnBoardingDashboard";
import JourneyRegistration from "../Pages/RegistrationRequest/JourneyRegistration";
import ReportingStructure from "../Pages/ReportingStructure";

import ManageSequence from "../Pages/ManageSequence/ManageSequence";
import EmployeeConsent from "../Pages/EmployeeConsent/EmployeeConsent";
import Domain from "../Pages/Domain/Domain";
import DomainDashboard from "../Pages/Domain/DomainDashboard";
import DocumentScreen from "../Pages/DocumentScreen/DocumentScreen";
import SecurityScreenings from "../Pages/SecurityScreenings/SecurityScreenings";
import MedicalDashboard from "../Pages/MedicalScreen/MedicalDashboard";
import OverallProcessDashboard from "../Pages/Appointment/OverallProcess";

import SecurityScreening from "../Pages/SecurityScreening/SecurityScreening";
import AppointmentDashboard from "../Pages/Appointment/AppointmentDashboard";
import MedicalScreen from "../Pages/MedicalScreen/MedicalScreen";
import SkillTest from "../Pages/SkillTest/SkillTest";
import UAN from "../Pages/UAN/UAN";
import Appointment from "../Pages/Appointment/AppointmentScreen";
import CandidateQueue from "../Pages/CandidateQueue/CandidateQueue";
import CandidateStatus from "../Pages/CandidateStatus/CandidateStatus";
import Biometrics from "../Pages/MantraBiomecrics/Biometrics";
import BiometricsReact from "../Pages/MantraBiomecrics/BiometricsReact";
import BlueCollarHomePage from "../Pages/BlueCollarHomepage/BlueCollarHomePage";
import MainGateEntry from "../Pages/MainGateEntry/MainGateEntry";
import PlantGateEntry from "../Pages/PlantGateEntry/PlantGateEntry";
import Handover from "../Pages/Handover/Handover";
import MainGateExit from "../Pages/MainGateExit/MainGateExit";
import PlantGateExit from "../Pages/PlantGateExit/PlantGateExit";
import { Handoverback } from "../Pages/HandoverBack/Handoverback";
import PreBookingRecruitment from "../Pages/PreBookingRecruitment/PreBookingRecruitment";
import { CanteenDesk } from "../Pages/CanteenDesk/CanteenDesk";

import AppointmentApproval from "../Pages/AppointmentApproval/AppointmentApproval";
import LMSHome from "../Pages/LMSHome/LMSHome";
import HistoricData from "../Pages/HistoricData/HistoricData";
import LaunchHub from "../Pages/LaunchHub/LaunchHub";
import ContentViewerPage from "../Pages/ContentViewer/ContentViewerPage";
// import WithConsentForm from "../Components/WithConsentModal";
import ConsentModal from "../Components/WithConsentModal/ConsentModal";
import CriticalDataDashBoard from "../Pages/CriticalDataDashBoard/CriticalDataDashBoard";
import JourneyProgressViewer from "../Pages/ContentViewer/JourneyProgressViewer";
import LMSReport from "../Pages/LMSReport/LMSReport";
import MRFAdmin from "../Pages/MRFAdmin/MRFAdmin";
import MRFAdmin_MRF from "../Pages/MRFAdmin/MRFAdmin_MRF";

import Signature from "../Pages/Signature/Signature";
import UserCertificateDashboard from "../Pages/Certificate/UserCertificateDashboard";
import WernerSkillTest from "../Pages/SkillTest/WernerSkillTest";
import ContractorCreation from "../Pages/Contractor/ContractorCreation";
import ContractorUser from "../Pages/Contractor/ContractorUser";
import ContractorCompliance from "../Pages/Contractor/ContractorCompliance";
import ContractorDashboard from "../Pages/Contractor/ContractorDashboard";
import ContractorComplianceDashboard from "../Pages/Contractor/ContractorComplianceDashboard";
import Departmentcandidate from "../Pages/Departmentcandidate/Departmentcandidate";
import AssociateTATReport from "../Pages/AssociateTATReport/AssociateTATReport";
import Reportss from "../Pages/Reportss/Reportss";
import DailyRecruitment from "../Pages/DailyRecruitment/DailyRecruitment";
import RejectedCandidate from "../Pages/RejectedCandidate/RejectedCandidate";
import AadharValidation from "../Pages/AadhaarValidationReport/AadharValidation";
import MedicalPreEmploymentReport from "../Pages/MedicalPreEmploymentReport/MedicalPreEmploymentReport";
import DailyConsolidate from "../Pages/DailyConsolidateReport/DailyConsolidate";
import UANReport from "../Pages/UANReport/UANReport";
import FeedbackDashboard from "../Pages/Feedback/FeedbackDashboard";
import MyWishlist from "../Pages/LMSHome/MyWishlist";
import AssessmentEdit from "../Pages/Assessment/AssessmentEdit";
import CertificateEdit from "../Pages/Certificate/CertificateEdit";
import CertificateDashboard from "../Pages/Certificate/CertificateDashboard";
import UserFeedbackDashboard from "../Pages/Feedback/UserFeedbackDashboard";
import FeedbackPreview from "../Pages/Feedback/FeedbackPreview";
import FeedbackEdit from "../Pages/Feedback/FeedbackEdit";
import AssessmentDashboard from "../Pages/Assessment/AssessmentDashboard";
import UserAssessmentsDashboard from "../Pages/Assessment/UserAssessmentsDashboard";
import ReportingDashboard from "../Pages/ReportingDashboard/ReportingDashboard";
// import CourseNomination from "../Pages/Catalogue/CourseNomination";
import ProgressionGenericMater from "../Pages/ProgressionGenericMaster/ProgressionGenericMater";
import ProgressionGenericMasterUpdate from "../Pages/ProgressionGenericMaster/ProgressionGenericMasterUpdate";
import PinBoard from "../Pages/MastersForConfig/PinBoard";
import PerceptionConfig from "../Pages/MastersForConfig/PerceptionConfig";
import FormBoard from "../Pages/MastersForConfig/FormBoard";
import MappingConfig from "../Pages/MastersForConfig/MappingConfig";
import CourseNomination from "../Pages/CourseNomination/CourseNomination";
import JobPosting from "../Pages/Job Posting/JobPosting";
import SchemeCreationMaster from "../Pages/MastersForConfig/SchemeCreationMaster";
import Assessment from "../Pages/Assessment/Assessment";
const Router = () => {
  const location = useLocation();

  let { userDetails, interfacePermissionRecord, currentRoleId }: any =
    useUserContext();

  const [asidebar, setAsidebar] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [shouldShowConsentForm, setShouldShowConsentForm] = useState(false);
  const [showHeader, setShowHeader] = useState(true);

  const secret = "secret";
  let data = "Xxxx";

  const navigate = useNavigate();

  const mobileResMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const Roles = userDetails?.Roles || [];

  const Candidate = Roles.find(
    (i) => i?.name?.toLocaleLowerCase()?.trim() == "candidate"
  );

  const IsCandidate = currentRoleId?.value == Candidate?.id;

  useEffect(() => {
    if (window.location.pathname.includes("/whoami")) {
      navigate("/sso", { state: { data: window.location.href } });
    }
  }, []);

  // useEffect(() => {
  //   let sessionCheck;
  //   if (userDetails) {
  //     sessionCheck = setInterval(() => {
  //       console.log("yo");

  //       (async () => {
  //         const timeRes = await APICall(CheckSessionTimer, "POST", {
  //           LoginId: userDetails.LoginId,
  //         });
  //         if (timeRes.data) {
  //           if (
  //             window.confirm(
  //               "Your session is about to expire, do you want to continue?"
  //             )
  //           ) {
  //             // const extendSession = await APICall(
  //             //   CheckSessionTimer,
  //             //   "POST",
  //             //   {}
  //             // );
  //           } else {
  //             //do nothing
  //           }
  //         }
  //       })();
  //     }, 120000);
  //   }

  //   //120000 - 5 minutes

  //   return () => {
  //     clearInterval(sessionCheck);
  //   };
  // }, []);

  // {!userDetails ? (
  //   <Route path="/" element={<Navigate to="/" replace />} />
  // ) : userDetails?.UserRoles.length == 1 &&
  //   userDetails?.UserRoles[0].RoleId == 5 ? (
  //   <Route
  //     path="/"
  //     element={<Navigate to="/anonymouscanidate" replace />}
  //   />
  // ) : (
  //   <Route path="/" element={<Navigate to="/home" replace />} />
  // )}

  useEffect(() => {
    setShouldShowConsentForm(false);
    const timeout = setTimeout(() => {
      setShouldShowConsentForm(
        !["/", "/sso", "/whoami"].includes(location.pathname)
      );
    }, 100);
    return () => clearTimeout(timeout);
  }, [location.pathname]);

  console.log("setShouldShowConsentForm", shouldShowConsentForm);

  return (
    <div className="menubar-hoverable header-fixed">
      {window.location.pathname !== "/" && userDetails && showHeader && (
        <Header
          menuOpenFunc={mobileResMenu}
          setAsidebar={setAsidebar}
          aisebar={asidebar}
        />
      )}

      {shouldShowConsentForm && (
        <ConsentModal shouldShowConsentForm={shouldShowConsentForm} />
      )}

      {window.location.pathname === "/" && !userDetails ? (
        <Routes>
          <Route path="/" element={<MainLogin />} />
        </Routes>
      ) : window.location.pathname.includes("/whoami") ? (
        <Routes>
          <Route path="/sso" element={<SSO />} />
        </Routes>
      ) : (
        <>
          <div id="base">
            <div
              id="content"
              style={{ paddingTop: showHeader ? "48px" : "0px" }}>
              <div className="main-content">
                <Routes>
                  {!userDetails ? (
                    <Route path="/" element={<Navigate to="/" replace />} />
                  ) : IsCandidate ? (
                    <Route
                      path="/"
                      element={<Navigate to="/anonymouscanidate" replace />}
                    />
                  ) : userDetails?.IsAgency ? (
                    <Route
                      path="/"
                      element={
                        <Navigate to="/AgencyCandidateDashboard" replace />
                      }
                    />
                  ) : (
                    <Route path="/" element={<Navigate to="/home" replace />} />
                  )}
                  {/* <Route path="/Dashboard" element={<Dashboard />} /> */}
                  <Route path="/sso" element={<SSO />} />
                  <Route path="/home" element={<Home />} />
                  <Route path="/copyofsso" element={<CopyOfSSo />} />
                  <Route path="/JobPosting" element={<JobPosting />} />
                  <Route
                    path="/OrganizationChart"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <OrganizationChart />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/organizationUnit"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}
                      >
                        {/* <OrganizationUnit /> */}
                        <OrganizationUnitForm />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/organizationUnitBulkUpload"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}
                      >
                        {/* <OrganizationUnit /> */}
                        <OrgUnitBulkUpload />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />                  

                  <Route
                    path="/OrganizationUnitProgressionDashboard"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}
                      >
                        {/* <OrganizationUnit /> */}
                        <OrganizationUnitProgressionDashboard />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />       
                  <Route
                    path="/organizationUnitDashboard"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <OrganizationUnitDashboard />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/journeyDashboard"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <ProgramDashboard />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/EmployeeList"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <EmployeeList />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/EmployeeProfileDashboard"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <EmployeeListProfileDashboard />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/journey"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <Program />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/journeyCourse"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <Programcourse />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/TrainerMaster"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <TrainerMaster />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/TrainingCentreDashboard"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <TrainingCenterDashboard />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/LMContent"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <Content />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/Participant"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <Participant />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/Trainer"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <Trainer />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/Trainers"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <Trainers />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/CoursesDashboard"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <CoursesDashboard />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/TrainingCentre"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <TrainingCentreMaster />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/Courses"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <Courses />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/Reportss"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <Reportss />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/Departmentwise"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <Departmentcandidate />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/DailyRecruitment"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <DailyRecruitment />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/RejectedCandidate"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <RejectedCandidate />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/AadharValidation"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <AadharValidation />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                   <Route
                    path="/MedicalPreEmploymentReport"
                    element={
                      <ProtectedNew
                      permittedPages={"MedicalPreEmploymentReport"}
                      pageContext={interfacePermissionRecord}>
                        <MedicalPreEmploymentReport />
                      </ProtectedNew>
                    }
                  />
                  <Route
                    path="/DailyConsolidate"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <DailyConsolidate />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/UANReport"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <UANReport />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/Catalogue"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <Catalogue />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/ContentViewer"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <ContentViewer />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/JourneyRegistrationRequest"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <JourneyRegistration />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/RegistrationRequest"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <Registration />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/LMSHome"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <LMSHome />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/LaunchHub"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <LaunchHub />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route path="/taskChecklist" element={<TaskChecklist />} />
                  <Route
                    path="/taskChecklistDashboard"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <TaskChecklistDashboard />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/ecdashboard"
                    element={
                      <Protected
                        permittedPages={"EC_Dashboard"}
                        pageContext={userDetails}>
                        <RestrictedPageForCandidateOrAgency
                          permittedPages={"EC_Dashboard"}
                          pageContext={userDetails}>
                          <GridStructure />
                        </RestrictedPageForCandidateOrAgency>
                      </Protected>
                    }
                  />
                  <Route
                    path="/ContractLabourdashboard"
                    element={
                      <ProtectedNew
                        permittedPages={"Contract_Labour"}
                        pageContext={interfacePermissionRecord}>
                        <ContractLabour />
                      </ProtectedNew>
                    }
                  />
                  <Route
                    path="/profile"
                    element={
                      <Protected
                        permittedPages={"EC_Form"}
                        pageContext={userDetails}>
                        <RestrictedPageForCandidateOrAgency
                          permittedPages={"EC_Form"}
                          pageContext={userDetails}>
                          <FormStructure />
                        </RestrictedPageForCandidateOrAgency>
                      </Protected>
                    }
                  />
                  <Route
                    path="/clmprofile"
                    element={
                      <ProtectedNew
                        permittedPages={"EC_Form"}
                        pageContext={interfacePermissionRecord}>
                        <CLMFormstructure />
                      </ProtectedNew>
                    }
                  />
                  <Route
                    path="/job"
                    element={
                      <Protected
                        permittedPages={"JobProfile_Form"}
                        pageContext={userDetails}>
                        <RestrictedPageForCandidateOrAgency
                          permittedPages={"JobProfile_Form"}
                          pageContext={userDetails}>
                          <JobProfileForm />
                        </RestrictedPageForCandidateOrAgency>
                      </Protected>
                    }
                  />
                  <Route
                    path="/positionManagement"
                    element={
                      <Protected
                        permittedPages={"PositionManagement_Form"}
                        pageContext={userDetails}>
                        <RestrictedPageForCandidateOrAgency
                          permittedPages={"PositionManagement_Form"}
                          pageContext={userDetails}>
                          <PositionManageForm />
                        </RestrictedPageForCandidateOrAgency>
                      </Protected>
                    }
                  />
                  <Route
                    path="/PositionManagementDashboard"
                    element={
                      <Protected
                        permittedPages={"PositionManagement_Dashboard"}
                        pageContext={userDetails}>
                        <RestrictedPageForCandidateOrAgency
                          permittedPages={"PositionManagement_Dashboard"}
                          pageContext={userDetails}>
                          <PositionDashboard />
                        </RestrictedPageForCandidateOrAgency>
                      </Protected>
                    }
                  />
                  <Route
                    path="/JobProfileDashboard"
                    element={
                      <Protected
                        permittedPages={"JobProfile_Dashboard"}
                        pageContext={userDetails}>
                        <RestrictedPageForCandidateOrAgency
                          permittedPages={"JobProfile_Dashboard"}
                          pageContext={userDetails}>
                          <JobProfileDashboard />
                        </RestrictedPageForCandidateOrAgency>
                      </Protected>
                    }
                  />
                  <Route
                    path="/PreBookingRecruitment"
                    element={
                      <PreBookingRecruitment setShowHeader={setShowHeader} />
                    }
                  />
                  <Route
                    path="/GenericMaster"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <GenericMasterDashboard />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/GenericMasterForm"
                    element={
                      <Protected
                        permittedPages={"GenericMaster_Form"}
                        pageContext={userDetails}>
                        <RestrictedPageForCandidateOrAgency
                          permittedPages={"GenericMaster_Form"}
                          pageContext={userDetails}>
                          <GenericMasterForm />
                        </RestrictedPageForCandidateOrAgency>
                      </Protected>
                    }
                  />
                  <Route
                    path="/PicklistAndMasterMapForm"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <PicklistAndMasterMapForm />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/PicklistBulkUpload"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <PicklistBulkUpload />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/GenericMasterFormUpdate"
                    element={
                      <Protected
                        permittedPages={"GenericMaster_Form"}
                        pageContext={userDetails}>
                        <RestrictedPageForCandidateOrAgency
                          permittedPages={"GenericMaster_Form"}
                          pageContext={userDetails}>
                          <GenericMasterFormUpdate />
                        </RestrictedPageForCandidateOrAgency>
                      </Protected>
                    }
                  />
                  <Route
                    path="/mrf"
                    element={
                      <MRF_Protected
                        permittedPages={"ManpowerRequisition_Form"}
                        pageContext={userDetails}
                        InterfacePermissionRecord={interfacePermissionRecord}>
                        <RestrictedPageForCandidateOrAgency
                          permittedPages={"ManpowerRequisition_Form"}
                          pageContext={userDetails}>
                          <MRF />
                        </RestrictedPageForCandidateOrAgency>
                      </MRF_Protected>
                    }
                  />
                  <Route
                    path="/mrfdashboard"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <MrfDashboard />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/AgencyDashboard"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <AgencyDashboard />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/AgencyCandidateDashboard"
                    element={<AgencyCandidateDashboard />}
                  />
                  <Route
                    path="/careers"
                    element={
                      <AnonymousCandidateDashboard
                        setShowHeader={setShowHeader}
                      />
                    }
                  />
                  <Route
                    path="/mrfadmin"
                    element={
                      <MRF_Protected
                        permittedPages={"MRF_Admin"}
                        pageContext={userDetails}
                        InterfacePermissionRecord={interfacePermissionRecord}>
                        <RestrictedPageForCandidateOrAgency
                          permittedPages={"MRF_Admin"}
                          pageContext={userDetails}>
                          <MRFAdmin />
                        </RestrictedPageForCandidateOrAgency>
                      </MRF_Protected>
                    }
                  />
                  <Route
                    path="/requistion"
                    element={
                      <MRF_Protected
                        permittedPages={"MRF_Admin"}
                        pageContext={userDetails}
                        InterfacePermissionRecord={interfacePermissionRecord}>
                        <RestrictedPageForCandidateOrAgency
                          permittedPages={"MRF_Admin"}
                          pageContext={userDetails}>
                          <MRFAdmin_MRF />
                        </RestrictedPageForCandidateOrAgency>
                      </MRF_Protected>
                    }
                  />
                  <Route
                    path="/commonDashboard"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <CommonDashboard />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/agency"
                    element={
                      <MRF_Protected
                        permittedPages={"Agency_Form"}
                        pageContext={userDetails}
                        InterfacePermissionRecord={interfacePermissionRecord}>
                        <RestrictedPageForCandidateOrAgency
                          permittedPages={"Agency_Form"}
                          pageContext={userDetails}>
                          <Agency />
                        </RestrictedPageForCandidateOrAgency>
                      </MRF_Protected>
                    }
                  />
                  <Route
                    path="/agencycandidate"
                    element={<AgencyCandidate />}
                  />
                  <Route
                    path="/anonymouscanidate"
                    element={
                      <AnonymousCandidate setShowHeader={setShowHeader} />
                    }
                  />
                  <Route path="/candidateform" element={<CandidateForm />} />
                  <Route
                    path="/CandidateFormReview"
                    element={<CandidateFormReview />}
                  />
                  <Route
                    path="/recruiterCandidate"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={"ManpowerRequisition_Form"}
                        pageContext={userDetails}>
                        <RecruiterCandidate />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  {/* <Route
                    path="/mrf"
                    element={
                      <Protected
                        permittedPages={"ManpowerRequisition_Form"}
                        pageContext={userDetails}
                      >
                        <RestrictedPageForCandidateOrAgency
                          permittedPages={"ManpowerRequisition_Form"}
                          pageContext={userDetails}
                        >
                          <MRF />
                        </RestrictedPageForCandidateOrAgency>
                      </Protected>
                    }
                  /> */}
                  {/* User Role  Permission*/}
                  <Route
                    path="/UserRole"
                    element={
                      <Protected
                        permittedPages={"UserAccess_Dashboard"}
                        pageContext={userDetails}>
                        <RestrictedPageForCandidateOrAgency
                          permittedPages={"UserAccess_Dashboard"}
                          pageContext={userDetails}>
                          <UserRoleMaster />
                        </RestrictedPageForCandidateOrAgency>
                      </Protected>
                    }
                  />
                  <Route
                    path="/UserRoleDashboard"
                    element={
                      <Protected
                        permittedPages={"UserAccess_Dashboard"}
                        pageContext={userDetails}>
                        <RestrictedPageForCandidateOrAgency
                          permittedPages={"UserAccess_Dashboard"}
                          pageContext={userDetails}>
                          <UserRoleDashboard />
                        </RestrictedPageForCandidateOrAgency>
                      </Protected>
                    }
                  />
                  <Route
                    path="/WorkFlowDashboard"
                    element={
                      <Protected
                        permittedPages={"WorkflowConfiguration_Dashboard"}
                        pageContext={userDetails}>
                        <RestrictedPageForCandidateOrAgency
                          permittedPages={"WorkflowConfiguration_Dashboard"}
                          pageContext={userDetails}>
                          <WorkFlowDashboard />
                        </RestrictedPageForCandidateOrAgency>
                      </Protected>
                    }
                  />
                  <Route
                    path="/ManageSequence"
                    element={
                      <ManageSequence />
                      // <Protected
                      //   permittedPages={"WorkflowConfiguration_Dashboard"}
                      //   pageContext={userDetails}
                      // >
                      //   <RestrictedPageForCandidateOrAgency
                      //     permittedPages={"WorkflowConfiguration_Dashboard"}
                      //     pageContext={userDetails}
                      //   >
                      //     <WorkFlowDashboard />
                      //   </RestrictedPageForCandidateOrAgency>
                      // </Protected>
                    }
                  />
                  <Route
                    path="/EmployeeConsent"
                    element={<EmployeeConsent />}
                  />
                  <Route
                    path="/CriticalDataDashBoard"
                    element={
                      <ProtectedNew
                        permittedPages={"Critical_Data_Dashboard"}
                        pageContext={interfacePermissionRecord}>
                        <CriticalDataDashBoard />
                      </ProtectedNew>
                    }
                  />
                  <Route
                    path="/WorkFlowForm"
                    element={
                      <Protected
                        permittedPages={"WorkflowConfiguration_Form"}
                        pageContext={userDetails}>
                        <RestrictedPageForCandidateOrAgency
                          permittedPages={"WorkflowConfiguration_Form"}
                          pageContext={userDetails}>
                          <WorkFlowForm />
                        </RestrictedPageForCandidateOrAgency>
                      </Protected>
                    }
                  />
                  <Route
                    path="/emailTemplateForm"
                    element={
                      <Protected
                        permittedPages={"EmailTemplate_Form"}
                        pageContext={userDetails}>
                        <RestrictedPageForCandidateOrAgency
                          permittedPages={"EmailTemplate_Form"}
                          pageContext={userDetails}>
                          <EmailTemplate />
                        </RestrictedPageForCandidateOrAgency>
                      </Protected>
                    }
                  />
                  <Route
                    path="/emailTemplateDashboard"
                    element={
                      <Protected
                        permittedPages={"EmailTemplate_Dashboard"}
                        pageContext={userDetails}>
                        <RestrictedPageForCandidateOrAgency
                          permittedPages={"EmailTemplate_Dashboard"}
                          pageContext={userDetails}>
                          <EmailTemplateDashboard />
                        </RestrictedPageForCandidateOrAgency>
                      </Protected>
                    }
                  />
                  {/* <Route path="/WorkFlowForm" element={<WorkFlowForm />} /> */}
                  {/* <Route path="/UserRole" element={<UserRoleMaster />} />
                  <Route
                    path="/UserRoleDashboard"
                    element={<UserRoleDashboard />}
                  /> */}
                  <Route
                    path="/reviewerSection"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <ReviewerSection />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/reviewerSectionclm"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <ReviewerSectionClm />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/progression"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <ProgRequisitionDetatils />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/progressionclm"
                    element={
                      <ProtectedNew
                        permittedPages={"ProgressionCLM"}
                        pageContext={interfacePermissionRecord}>
                        <ProgRequisitionDetatilsCLM />
                      </ProtectedNew>
                    }
                  />
                  <Route
                    path="/sendback"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <SendbackRequisitionDetatils />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/sendbackclm"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <SendbackRequisitionDetatilsClm />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route path="/revoke" element={<ProgRevoke />} />
                  <Route
                    path="/requisitionview"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <ViewRequisitionDetatils />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/requisitionviewclm"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <ViewRequisitionDetatilsClm />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/resignation"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <ProgResignation />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route path="/newHire" element={<NewHire />} />
                  <Route
                    path="/newHireApproval"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <NewHireApproval />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/newHireApprovalclm"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <NewHireApprovalClm />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/EmployeeImport"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <BulkUpload />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/Report"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <Report />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  {/* // user permission mapping */}
                  {/* <Route path="/dashboard" element={<GridStructure />} /> 

                  <Route path="/profile" element={<FormStructure />} />
                  
                  <Route path="/agency" element={<Agency />} />
                  <Route
                    path="/ProjectDashboard"
                    element={<ProjectDashboard />}
                  />
                  {/* <Route path="/Dashboard" element={<h1>Dashboard</h1>} /> */}
                  {/* <Route path="/Request" element={<Request />} /> */}
                  <Route
                    path="/StructureType"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <StructureType />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  {/* <Route
                    path="/ParameterDashboard"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <ParameterDashboard />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />

                  <Route
                    path="/DocumentScreen"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={"Document_Screen"}
                        pageContext={userDetails}
                      >
                        <DocumentScreen />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  /> */}
                  <Route
                    path="/CandidateAuthentication"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <SecurityScreenings />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/MedicalDashboard"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <MedicalDashboard />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/OverallProcessDashboard"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <OverallProcessDashboard />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/AppointmentDashboard"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <AppointmentDashboard />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/Logsheet"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <AssociateTATReport />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/ReportingDashboard"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <ReportingDashboard />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/CandidateQueue"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <CandidateQueue />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/UserDashboard"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <UserDashboard />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/StructureTypeEdit"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <StructureTypeEdit />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  {/* <Route path="/InvoiceType" element={<InvoiceType />} /> */}
                  {/* <Route path="/InvoiceTypeEdit"element={<InvoiceTypeEdit />}/> */}
                  <Route
                    path="/ProjectEdit"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <ProjectEdit />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/ParameterEdit"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <ParameterEdit />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/UserEdit"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <UserEdit />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route path="/lop" element={<LOP />} />
                  <Route path="/AccessDenied" element={<AccessDenied />} />
                  <Route
                    path="/recruiterDashboard"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <RecruiterDashboard />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/candidateDashboard"
                    element={<CandidateDashboard />}
                  />
                  {/* <Route path="/testGridStructure" element={<GridStructure />} />  */}
                  <Route
                    path="/OnBoardingCheckList"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <OnBoardingCheckList />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/MedicalScreen"
                    element={
                      // <Protected
                      //   permittedPages={"Medical_Screen"}
                      //   pageContext={userDetails}
                      // >
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={"Medical_Screen"}
                        pageContext={userDetails}>
                        <MedicalScreen />
                      </RestrictedPageForCandidateOrAgency>
                      // </Protected>
                    }
                  />
                  <Route
                    path="/SkillTest"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <SkillTest />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  ///blue
                  <Route
                    path="/Bluecollar"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <BlueCollarHomePage />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/MainGateEntry"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <MainGateEntry />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/PlantGateEntry"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <PlantGateEntry />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/CanteenDesk"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <CanteenDesk />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/Handover"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <Handover />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/MainGateExit"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <MainGateExit />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/PlantGateExit"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <PlantGateExit />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/HandoverToInstructorBack"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <Handoverback />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/AppointmentScreen"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <Appointment />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/AppointmentApprovalScreen"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <AppointmentApproval />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/CandidateStatus"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <CandidateStatus />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/OnBoardingCheckList/ApprovalDashboard"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <OnBoardingApprovalDashboard />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/CandidateOnboardingApprovalDashboard"
                    element={<CandidateOnBoardingApprovalDashboard />}
                  />
                  <Route path="/Session" element={<Session />} />
                  <Route path="/SessionEdit" element={<SessionEdit />} />
                  <Route path="/Contentedit" element={<Contentedit />} />
                  <Route
                    path="/SecurityScreening"
                    element={<SecurityScreening />}
                  />
                  <Route
                    path="/RequestDashboard"
                    element={<RequestDashboard />}
                  />
                  {/* <Route
                    path="/CandidateOnboardingApprovalDashboard"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}
                      >
                        <CandidateOnBoardingApprovalDashboard />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  /> */}
                  <Route
                    path="/OnBoardingCheckList"
                    element={
                      <Protected
                        permittedPages={"OnBoarding_CheckList"}
                        pageContext={userDetails}>
                        <RestrictedPageForCandidateOrAgency
                          permittedPages={"OnBoarding_CheckList"}
                          pageContext={userDetails}>
                          <OnBoardingCheckList />
                        </RestrictedPageForCandidateOrAgency>
                      </Protected>
                    }
                  />
                  <Route
                    path="/OnBoardingCheckList/ApprovalDashboard"
                    element={
                      <Protected
                        permittedPages={"OnBoarding_Approval_Dashboard"}
                        pageContext={userDetails}>
                        <RestrictedPageForCandidateOrAgency
                          permittedPages={"OnBoarding_Approval_Dashboard"}
                          pageContext={userDetails}>
                          <OnBoardingApprovalDashboard />
                        </RestrictedPageForCandidateOrAgency>
                      </Protected>
                    }
                  />
                  <Route
                    path="/delegation-process"
                    element={
                      <Protected
                        permittedPages={"Delegation_Dashboard"}
                        pageContext={userDetails}>
                        <RestrictedPageForCandidateOrAgency
                          permittedPages={"Delegation_Dashboard"}
                          pageContext={userDetails}>
                          <DelegationProcess />
                        </RestrictedPageForCandidateOrAgency>
                      </Protected>
                    }
                  />
                  {/* LMS  */}
                  <Route
                    path="/schedulecoursesession"
                    element={
                      <ScheduleCourseAndSession
                        activeTab={undefined}
                        setActiveTab={undefined}
                      />
                    }
                  />
                  <Route path="/uan" element={<UAN />} />
                  <Route path="/addschedule" element={<AddScheduleCourse />} />
                  <Route
                    path="/org-structure"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <ReportingStructure />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route
                    path="/targetlearnergroup"
                    element={<TargetLearnerGroup />}
                  />
                  <Route
                    path="/participation"
                    element={
                      <Participation
                        activeTab={undefined}
                        setActiveTab={undefined}
                      />
                    }
                  />
                  <Route
                    path="/OnBoardingDashboard"
                    element={
                      <RestrictedPageForCandidateOrAgency
                        permittedPages={""}
                        pageContext={userDetails}>
                        <OnBoardingDashboard />
                      </RestrictedPageForCandidateOrAgency>
                    }
                  />
                  <Route path="/domain" element={<Domain />} />
                  <Route
                    path="/domainDashboard"
                    element={<DomainDashboard />}
                  />
                  <Route path="/Biometrics" element={<Biometrics />} />
                  <Route
                    path="/BiometricsReact"
                    element={<BiometricsReact />}
                  />
                  <Route path="/historicData" element={<HistoricData />} />
                  <Route
                    path="/contentViewerPage"
                    element={<ContentViewerPage />}
                  />
                  <Route
                    path="/journeyProgressViewer"
                    element={<JourneyProgressViewer />}
                  />
                  <Route
                    path="/assessmentDashboard"
                    element={<AssessmentDashboard />}
                  />
                  <Route path="/assessmentEdit" element={<AssessmentEdit />} />
                  <Route
                    path="/UserAssessments"
                    element={<UserAssessmentsDashboard />}
                  />
                  <Route
                    path="/feedbackDashboard"
                    element={<FeedbackDashboard />}
                  />
                  <Route path="/feedbackEdit" element={<FeedbackEdit />} />
                  <Route
                    path="/feedbackPreview"
                    element={<FeedbackPreview />}
                  />
                  <Route path="/assessment" element={<Assessment />} />
                  <Route path="/MyWishlist" element={<MyWishlist />} />
                  <Route path="/Signature" element={<Signature />} />
                  <Route
                    path="/UserFeedback"
                    element={<UserFeedbackDashboard />}
                  />
                  <Route
                    path="/certificateDashboard"
                    element={<CertificateDashboard />}
                  />
                  <Route
                    path="/certificateEdit"
                    element={<CertificateEdit />}
                  />
                  <Route
                    path="/UserCertificate"
                    element={<UserCertificateDashboard />}
                  />
                  <Route path="/LMSReport" element={<LMSReport />} />
                  <Route
                    path="/CourseNomination"
                    element={<CourseNomination />}
                  />
                  {/* Added new route for the progression user dashboard. Raj Potdar, Date : 19-06-2024 */}
                  <Route
                    path="/ProgressionGenericMater"
                    element={<ProgressionGenericMater />}
                  />
                  <Route
                    path="/ProgressionGenericMasterUpdate"
                    element={<ProgressionGenericMasterUpdate />}
                  />
                  <Route path="/PinBoardDashboard" element={<PinBoard />} />
                  <Route path="/FormBoardDashboard" element={<FormBoard />} />
                  <Route
                    path="/PerceptionConfigDashboard"
                    element={<PerceptionConfig />}
                  />
                  <Route
                    path="/WernerSkillTest"
                    element={<WernerSkillTest />}
                  />
                  <Route
                    path="/ContractorCreation"
                    element={<ContractorCreation />}
                  />
                  <Route
                    path="/ContractorRequestDashboard"
                    element={<CommonDashboard />}
                  />
                  <Route path="/ContractorUser" element={<ContractorUser />} />
                  <Route
                    path="/ContractorDashboard"
                    element={<ContractorDashboard />}
                  />
                  <Route
                    path="/ContractorComplianceDashboard"
                    element={<ContractorComplianceDashboard />}
                  />
                  <Route
                    path="/ContractorCompliance"
                    element={<ContractorCompliance />}
                  />
                  <Route
                    path="/MappingConfigDashboard"
                    element={<MappingConfig />}
                  />
                  <Route
                    path="/SchemeCreationDashboard"
                    element={<SchemeCreationMaster />}
                  />
                  <Route path="/PinBoardDashboard" element={<PinBoard />} />
                  <Route path="/FormBoardDashboard" element={<FormBoard />} />
                  <Route
                    path="/PerceptionConfigDashboard"
                    element={<PerceptionConfig />}
                  />
                  <Route
                    path="/MappingConfigDashboard"
                    element={<MappingConfig />}
                  />
                  <Route
                    path="/SchemeCreationDashboard"
                    element={<SchemeCreationMaster />}
                  />
                </Routes>
              </div>
            </div>
            {window.location.pathname !== "/" && userDetails && (
              <Sidebar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
            )}
          </div>
        </>
      )}

      {window.location.pathname !== "/" && userDetails && <Footer />}
    </div>
  );
};

export default Router;
