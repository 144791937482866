import { Document, Page, Outline } from "react-pdf/dist/esm/entry.webpack";
import { useState } from "react";

export const RCMPdfViewer = ({ pdfURL }) => {
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  return (
    <div>
      <>
        <Document
          className="img-fluid invoice-img"
          file={`data:application/pdf;base64,${pdfURL}`}
          onLoadSuccess={({ numPages }) => {
            setNumberOfPages(numPages);
          }}
        >
          <Page
            size="A4"
            pageNumber={pageNumber}
            renderMode="svg"
            scale={1.5}
          />
          {Array.from(new Array(numberOfPages), (el, index) => (
            <Page
              scale={1.5}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
            />
          ))}
        </Document>
      </>
    </div>
  );
};
