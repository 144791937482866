import React, { useState, useEffect } from 'react';
import './style.css';

interface Props {
    onTimerEnd?: any;
}

const CountdownTimer : React.FC<Props> = ({ onTimerEnd }) => {
  const [time, setTime] = useState(15 * 60);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime === 0) {
          clearInterval(timer);
          if (onTimerEnd) {
            onTimerEnd();
          }
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [onTimerEnd]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    const minutesStr = String(minutes).padStart(2, '0');
    const secondsStr = String(seconds).padStart(2, '0');
    return `${minutesStr}:${secondsStr}`;
  };

  const timerClassName = time <= 5 * 60 ? 'countdown-timer red' : 'countdown-timer green';

  return <div className={`${timerClassName}`}><i className="fa-solid fa-clock mr-2"/>{formatTime(time)}</div>;
};

export default CountdownTimer;
