import React, { useState, useEffect, useContext } from "react";
import SelectForm from "../../Components/SelectForm/SelectForm";
import "./CriticalDataDashBoard.css";
import { APICall } from "../../Helpers/APICalls";
import { LoaderContext } from "../../Helpers/Context/Context";
import {
  GetCriticalDataFormDropdowns,
  GetCriticalDataAttributes,
  GetCriticalDataAttributesPrimaryValues,
  GetCriticalDataById,
  InsertUpdateCriticalData,
} from "../../Helpers/APIEndPoints/EndPoints";
import { isEmpty } from "../../utils/isEmpty";
import notify from "../../Helpers/ToastNotification";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
function CriticalDataForm({ formtype, setformtype, id }) {
  const { showLoader, hideLoader } = useContext(LoaderContext);
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  let employeeId = proxyUserDetails ? proxyUserDetails.value : userDetails?.Id;
  const [criticalFormFilterDropdown, setCriticalFormFilterDropdown] = useState({
    country: null,
    countryoption: null,
    tab: null,
    taboption: null,
    section: null,
    sectionoption: null,
    fieldname: null,
    fieldnameoption: null,
    fieldvalue: null,
    fieldvalueoption: null,
    criticality: null,
    criticalityoption: [
      { label: "High", value: 0 },
      { label: "Medium", value: 1 },
      { label: "Low", value: 2 },
    ],
    status: null,
    statusoption: [
      { label: "Active", value: 1 },
      { label: "In Active", value: 0 },
    ],
  });
  const [formErrors, setFormErrors] = useState<any>({});
  const handlereset = () => {
    setCriticalFormFilterDropdown((prev) => ({
      ...prev,
      country: null,
      tab: null,
      section: null,
      fieldname: null,
      fieldvalue: null,
      criticality: null,
      status: null,
    }));
  };
  const handleValidation = () => {
    let errorCount = 0;
    if (isEmpty(criticalFormFilterDropdown["country"])) {
      setFormErrors((prev) => ({
        ...prev,
        country: "Required",
      }));
      errorCount += 1;
    }
    if (isEmpty(criticalFormFilterDropdown["tab"])) {
      setFormErrors((prev) => ({
        ...prev,
        tab: "Required",
      }));
      errorCount += 1;
    }
    if (isEmpty(criticalFormFilterDropdown["section"])) {
      setFormErrors((prev) => ({
        ...prev,
        section: "Required",
      }));
      errorCount += 1;
    }
    if (isEmpty(criticalFormFilterDropdown["fieldname"])) {
      setFormErrors((prev) => ({
        ...prev,
        fieldname: "Required",
      }));
      errorCount += 1;
    }
    if (
      isEmpty(criticalFormFilterDropdown["fieldvalue"]) &&
      !isEmpty(criticalFormFilterDropdown["fieldvalueoption"]) &&
        !isEmpty(criticalFormFilterDropdown["section"]?.isGrid)
    ) {
      setFormErrors((prev) => ({
        ...prev,
        fieldvalue: "Required",
      }));
      errorCount += 1;
    }
    if (isEmpty(criticalFormFilterDropdown["criticality"])) {
      setFormErrors((prev) => ({
        ...prev,
        criticality: "Required",
      }));
      errorCount += 1;
    }
    if (isEmpty(criticalFormFilterDropdown["status"])) {
      setFormErrors((prev) => ({
        ...prev,
        status: "Required",
      }));
      errorCount += 1;
    }
    return errorCount > 0 ? true : false;
  };
  const handleSubmit = async () => {
    showLoader();
    try {
      setFormErrors({});
      const isErrors = handleValidation();
      if (isErrors) {
        hideLoader();
        return;
      }

      const {
        country,
        tab,
        section,
        fieldname,
        fieldvalue,
        criticality,
        status,
      } = criticalFormFilterDropdown || {};

      const criticaldataform = await APICall(InsertUpdateCriticalData, "POST", {
        Id: id,
        CountrIds:
          formtype === "critical_edit"
            ? country?.value
            : country?.map((item) => item.value).join(",") || null,
        TabId: tab?.value || "",
        SectionId: section?.value || "",
        AttributeId: fieldname?.value || "",
        PrimaryValue: fieldvalue?.value || "",
        Criticality: criticality?.label || "",
        IsActive: status && status.value !== undefined ? status.value : "",
        CreatedBy: employeeId,
      });
      const { data, status: statuscritical, message } = criticaldataform || {};
      if (statuscritical === 1) {
        notify(1, message || "Something went wrong");
      } else {
        handlereset();
        setformtype("");
        notify(0, `Form Submitted  Successfully`);
      }
    } catch (error) {
      console.error("Error in insert update critical form data", error);
    } finally {
      hideLoader();
    }
  };
  const getFormDropdownAPI = async (type,sectionid=null) => {
    showLoader();
    try {
      const dropdowndashboardData = await APICall(
        GetCriticalDataFormDropdowns,
        "POST",
        {
          Type: type,
          TabId:`${type==="Section"?sectionid:0}`
        }
      );
      const { data } = dropdowndashboardData || {};
      if ((data || []).length > 0) {
        if (type === "Country") {
          setCriticalFormFilterDropdown((prev) => ({
            ...prev,
            countryoption: data,
          }));
        } else if (type === "Section") {
          setCriticalFormFilterDropdown((prev) => ({
            ...prev,
            sectionoption: data,
          }));
        } else if (type === "Tab") {
          setCriticalFormFilterDropdown((prev) => ({
            ...prev,
            taboption: data,
          }));
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error in fetching Dropdown data", error);
    } finally {
      hideLoader();
    }
  };
  const getFieldnameDropdownAPI = async (id) => {
    try {
      const dropdowndashboardData = await APICall(
        GetCriticalDataAttributes,
        "POST",
        {
          Type: id,
        }
      );
      const { data } = dropdowndashboardData || {};
      if ((data || []).length > 0) {
        setCriticalFormFilterDropdown((prev) => ({
          ...prev,
          fieldnameoption: data,
        }));
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error in fetching Dropdown data", error);
    } finally {
      hideLoader();
    }
  };
  const getFieldtypeDropdownAPI = async (type) => {
    try {
      const dropdowndashboardData = await APICall(
        GetCriticalDataAttributesPrimaryValues,
        "POST",
        {
          Type: type,
        }
      );
      const { data } = dropdowndashboardData || {};
      if ((data || []).length > 0) {
        setCriticalFormFilterDropdown((prev) => ({
          ...prev,
          fieldvalueoption: data,
        }));
      } else {
        setCriticalFormFilterDropdown((prev) => ({
          ...prev,
          fieldvalueoption: null,
        }));
      }
    } catch (error) {
      console.error("Error in fetching Dropdown data", error);
    } finally {
      hideLoader();
    }
  };
  const geteditvalueDropdownAPI = async (type) => {
    try {
      const dropdowneditData = await APICall(GetCriticalDataById, "POST", {
        Id: type,
      });
      const { data } = dropdowneditData || {};
      if (!isEmpty(data)) {
        const {
          fieldName,
          fieldType,
          country,
          criticality,
          tab,
          section,
          tabId,
          sectionId,
          attributeId,
          primaryValue,
          countryId,
          isActive,
            type,
          isGrid,
        } = data || {};
        const { criticalityoption, statusoption } =
          criticalFormFilterDropdown || {};
        const customisedcritical = criticalityoption.find(
          (elem) => elem.label === criticality
        );
        const customisedstatusoption = statusoption.find(
          (elem) => elem.value === (isActive ? 1 : 0)
        );
        setCriticalFormFilterDropdown((prev) => ({
          ...prev,
          country: { label: country, value: String(countryId) },
          tab: { label: tab, value: String(tabId) },
            section: { label: section, value: String(sectionId), isGrid: isGrid },
          fieldname: { label: fieldName, value:String(attributeId) },
          criticality:customisedcritical,
          status: customisedstatusoption,
        }));
        if (!isEmpty(fieldType) && !isEmpty(primaryValue)) {
          setCriticalFormFilterDropdown((prev) => ({
            ...prev,
            fieldvalue: { label: fieldType, value: String(primaryValue) },
          }));
        }
        if (!isEmpty(type)) {
          getFieldtypeDropdownAPI(type);
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error in fetching Dropdown data", error);
    } finally {
      hideLoader();
    }
  };
  useEffect(() => {
    getFormDropdownAPI("Country");
    getFormDropdownAPI("Tab");
    if (formtype === "critical_edit" || formtype === "critical_view") {
      geteditvalueDropdownAPI(id);
      getFieldnameDropdownAPI(criticalFormFilterDropdown?.section?.value);
    }
  }, []);
  useEffect(() => {
    if (formtype === "critical_edit" || formtype === "critical_view") {
      getFieldnameDropdownAPI(criticalFormFilterDropdown?.section?.value);
    }
  }, [criticalFormFilterDropdown.section]);
  useEffect(() => {
    if (criticalFormFilterDropdown?.tab?.value) {
      getFormDropdownAPI("Section",criticalFormFilterDropdown?.tab?.value);
    }
  }, [criticalFormFilterDropdown.tab]);
  return (
    <div className="px-3">
      <div className="row px-3 my-3">
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1">
            <label className="col-form-label">
              Country<span className="modified-asterick">*</span>
            </label>
            <div className="">
              <SelectForm
                isClearable
                options={criticalFormFilterDropdown["countryoption"] || []}
                placeholder={"Select Country"}
                isDisabled={formtype === "critical_view" ? true : false}
                onChange={(event) => {
                  setCriticalFormFilterDropdown((prev) => {
                    return { ...prev, ["country"]: event };
                  });
                }}
                isMulti={formtype === "critical_edit" ? false : true}
                noIndicator={false}
                noSeparator={false}
                value={criticalFormFilterDropdown["country"] || ""}
              />
              {formErrors["country"] && (
                <p style={{ color: "red" }}>{formErrors["country"]}</p>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1">
            <label className="col-form-label">
              Tab Name<span className="modified-asterick">*</span>
            </label>
            <div className="">
              <SelectForm
                isClearable
                options={criticalFormFilterDropdown["taboption"] || []}
                placeholder={"Tab Name"}
                isDisabled={formtype === "critical_view" ? true : false}
                onChange={(event) => {
                  setCriticalFormFilterDropdown((prev) => {
                    return { ...prev, ["tab"]: event };
                  });
                  if (!isEmpty(criticalFormFilterDropdown.tab)) {
                    setCriticalFormFilterDropdown((prev) => {
                      return { ...prev, 
                      ["sectionoption"]: null,
                      ["section"]: null ,
                      ["fieldnameoption"]: null,
                      ["fieldname"]: null,
                      ["fieldvalue"]:null,
                      ["fieldvalueoption"]:null,
                    
                    };
                    });
                  }
                }}
                isMulti={false}
                noIndicator={false}
                noSeparator={false}
                value={criticalFormFilterDropdown["tab"] || ""}
              />
              {formErrors["tab"] && (
                <p style={{ color: "red" }}>{formErrors["tab"]}</p>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1">
            <label className="col-form-label">
              Section Name<span className="modified-asterick">*</span>
            </label>
            <div className="">
              <SelectForm
                isClearable
                options={criticalFormFilterDropdown["sectionoption"] || []}
                placeholder={"Section Name"}
                isDisabled={formtype === "critical_view" ? true : false}
                onChange={(event) => {
                  setCriticalFormFilterDropdown((prev) => {
                    return { ...prev, ["section"]: event };
                  });
                  getFieldnameDropdownAPI(event?.value);
                  if (!isEmpty(criticalFormFilterDropdown.section)) {
                    setCriticalFormFilterDropdown((prev) => {
                      return {
                        ...prev,
                        ["fieldname"]: null,
                        ["fieldvalue"]: null,
                        ["fieldvalueoption"]:null,
                      };
                    });
                  }
                }}
                isMulti={false}
                noIndicator={false}
                noSeparator={false}
                value={criticalFormFilterDropdown["section"] || ""}
              />
              {formErrors["section"] && (
                <p style={{ color: "red" }}>{formErrors["section"]}</p>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1">
            <label className="col-form-label">
              Field Name<span className="modified-asterick">*</span>
            </label>
            <div className="">
              <SelectForm
                isClearable
                options={criticalFormFilterDropdown["fieldnameoption"] || []}
                placeholder={"Field Name"}
                isDisabled={
                  isEmpty(criticalFormFilterDropdown.section) ||
                  formtype === "critical_view"
                    ? true
                    : false
                }
                onChange={(event) => {
                  setCriticalFormFilterDropdown((prev) => {
                    return { ...prev, ["fieldname"]: event };
                  });
                  getFieldtypeDropdownAPI(event?.type);
                  if (!isEmpty(criticalFormFilterDropdown.section)) {
                    setCriticalFormFilterDropdown((prev) => {
                      return { ...prev, ["fieldvalue"]: null };
                    });
                  }
                }}
                isMulti={false}
                noIndicator={false}
                noSeparator={false}
                value={criticalFormFilterDropdown["fieldname"] || ""}
              />
              {formErrors["fieldname"] && (
                <p style={{ color: "red" }}>{formErrors["fieldname"]}</p>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1">
            <label className="col-form-label">
              Field Value
              <span className="modified-asterick">{`${
              !isEmpty(criticalFormFilterDropdown.fieldvalueoption) && !isEmpty(criticalFormFilterDropdown["section"]?.isGrid) ? "*" : ""
              }`}</span>
            </label>
            <div className="">
              <SelectForm
                isClearable
                options={criticalFormFilterDropdown["fieldvalueoption"] || []}
                placeholder={"Field Value"}
                isDisabled={
                  isEmpty(criticalFormFilterDropdown.fieldvalueoption) ||
                  formtype === "critical_view"
                    ? true
                    : false
                }
                onChange={(event) => {
                  setCriticalFormFilterDropdown((prev) => {
                    return { ...prev, ["fieldvalue"]: event };
                  });
                }}
                isMulti={false}
                noIndicator={false}
                noSeparator={false}
                value={criticalFormFilterDropdown["fieldvalue"] || ""}
              />
              {formErrors["fieldvalue"] && (
                <p style={{ color: "red" }}>{formErrors["fieldvalue"]}</p>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1">
            <label className="col-form-label">
              Criticality<span className="modified-asterick">*</span>
            </label>
            <div className="">
              <SelectForm
                isClearable
                options={criticalFormFilterDropdown["criticalityoption"] || []}
                placeholder={"Criticality"}
                isDisabled={formtype === "critical_view" ? true : false}
                onChange={(event) => {
                  setCriticalFormFilterDropdown((prev) => {
                    return { ...prev, ["criticality"]: event };
                  });
                }}
                isMulti={false}
                noIndicator={false}
                noSeparator={false}
                value={criticalFormFilterDropdown["criticality"] || ""}
              />
              {formErrors["criticality"] && (
                <p style={{ color: "red" }}>{formErrors["criticality"]}</p>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1">
            <label className="col-form-label">
              Status<span className="modified-asterick">*</span>
            </label>
            <div className="">
              <SelectForm
                isClearable
                options={criticalFormFilterDropdown["statusoption"] || []}
                placeholder={"Status"}
                isDisabled={formtype === "critical_view" ? true : false}
                onChange={(event) => {
                  setCriticalFormFilterDropdown((prev) => {
                    return { ...prev, ["status"]: event };
                  });
                }}
                isMulti={false}
                noIndicator={false}
                noSeparator={false}
                value={criticalFormFilterDropdown["status"] || ""}
              />
              {formErrors["status"] && (
                <p style={{ color: "red" }}>{formErrors["status"]}</p>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1 ">
            <label className="col-form-label"> </label>
            <div className="">
              <button
                type="button"
                disabled={formtype === "critical_view" ? true : false}
                className="btn btn-secondary mt-3 ml-3"
                onClick={handlereset}
              >
                <i className="fa fa-undo" aria-hidden="true"></i> Reset{" "}
              </button>{" "}
              <button
                type="button"
                disabled={formtype === "critical_view" ? true : false}
                className="btn btn-primary mt-3 ml-2"
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CriticalDataForm;
