import React, { useState, useRef, useMemo } from "react";
import JoditEditor from "jodit-react";

const RTE = ({
  content,
  placeholder = content === "" || content === null
    ? "Start typing..."
    : content,
  onblur,
  disabled,
}) => {
  const editor = useRef(null);

  const defaultConfig: any = useMemo(
    () => ({
      buttons: [
        "bold",
        "italic",
        "underline",
        // "|",
        // "ul",
        // "ol",
        "|",
        "fontsize",
        "paragraph",
        "|",
        "left",
        "center",
        "right",
        "justify",
        "|",
        "undo",
        "redo",
        // "|",
        // "link",
      ],
      //spellcheck: true,
      componentStatus: null,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      defaultActionOnPaste: "insert_clear_html",
      placeholder,
      disabled: disabled,
      toolbarAdaptive: false,
      //showWordsCounter:false,
    }),
    [placeholder]
  );

  return (
    <>
      <JoditEditor
        ref={editor}
        value={content}
        config={defaultConfig}
        onBlur={onblur}
        onChange={(newContent) => {}}
        // preferred to use only this option to update the content for performance reasons
      />
    </>
  );
};

export default RTE;
