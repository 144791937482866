import React, { useState, useEffect,CSSProperties,useContext } from 'react';
import { getProfilePicture } from '../../Helpers/APIEndPoints/EndPoints';
import { APICall } from '../../Helpers/APICalls';
import './style.css';
import defaultProfileImgPic from "../../Assets/Images/profile.png";
const Base64Img = ({ id }) => {
    
  const [imageSource, setImageSource] = useState('');
  const orgEmployeeImg:CSSProperties={
    borderRadius: '50%',
    height: '40px',
    width: '40px',
    minWidth: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    objectFit: 'cover',
    border: '1px solid #6e6d6d',
    marginRight: '10px',
}; 
  const getEmpProfileImg = async (id) => {
    try {
        const res = await APICall(getProfilePicture, "POST", {
            TId : id,
            AC : "ProfilePic"
        });

        const { status, data } = res || {};

        console.log('resemp', res)

        if(status === 0){
            setImageSource(data);
        }
        return null;
    } catch (error) {
        return null;
    }
}

  useEffect(() => {
    getEmpProfileImg(id);
  }, [id]);
  return (
     <img
    className='org-employee-img'
    style={orgEmployeeImg}
    src={imageSource ? `data:image/jpeg;base64,${imageSource}` : defaultProfileImgPic}
/>
  );
};

export default Base64Img;
