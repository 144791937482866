import React, { useEffect, useState, useContext } from 'react'
import DateForm from '../../Components/DateForm/DateForm';
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from 'react-router-dom';
import { APICall } from '../../Helpers/APICalls';
import { LoaderContext } from "../../Helpers/Context/Context";
import {
  GetProgressionGenericMasterData,
  UpdateProgressionGenericMasterData
} from "../../Helpers/APIEndPoints/EndPoints";
import notify from "../../Helpers/ToastNotification";


const ProgressionGenericMasterUpdate = () => {
  const Navigate = useNavigate();
  //check if syncstatus = 1 : disableSTate=true
  // else disableSTate = false
  //const[isactive, setisactive]
  //const[oldisactive, setoldisactive]
  const [effectiveDate, seteffectiveDate] = useState("");
  const [remarks, setremarks] = useState<any>("");
  const [masterTypeName, setMasterTypeName] = useState<any>("");
  const [genericmastercode, setgenericmastercode] = useState("");
  const [genericmastername, setgenericmastername] = useState("");
  const [parentid, setparentid] = useState<any>("");
  const [parentname, setparentname] = useState<any>("");
  const [isactive, setisactive] = useState(false);
  const [oldisactive, setoldisactive] = useState(false);
  const [issync, setisync] = useState("");
  const [syncstatus, setsyncstatus] = useState("");
  const { state } = useLocation();
  const [stateId, setstateId] = useState(state !== null ? state.Id : 0);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [showConfirmationModalForInactive, setShowConfirmationModalForInactive] = useState(false);
  const [disableSTate, setdisableSTate] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      console.log("stateId", stateId);

      showLoader();

      // await GetMasterDataDropdown();
      ////await GetParentData();
      await getprogressiongenericmasterdata();

      hideLoader();
    };
    fetchData();
  }, []);

  //update button click at that time i will check isactive != oldisactive then only i'll hit the update api otherwise notify "No changes were masde"

  const getprogressiongenericmasterdata = async () => {
    try {
      const { data } = await APICall(GetProgressionGenericMasterData, "POST", {
        Id: state.Id,
        //Id: 13682,
      });

      if (data !== null) {
        console.log("Generic Master data found");
        console.log(data, "data");
        let MTobj = {
          label: data.masterTypeName,
          value: data.masterTypeId,
        };
        let Parentobj = {
          label: data.parentName,
          value: data.parentId,
        };
        debugger;
        seteffectiveDate(data.effectiveDate);
        setremarks(data.remarks);
        setMasterTypeName(data.masterTypeName);
        setgenericmastercode(data.genericMasterCode);
        setgenericmastername(data.genericMasterName);
        setparentname(data.parentName);
        setisactive(data.isActive);
        setsyncstatus(data.syncStatus);
        setisync(data.isSync);
        setparentid(data.parentId);
        setoldisactive(data.isActive);
        setdisableSTate(data.isSync == 1 ? true : false);
      }

    } catch (error) {

    }
  }

  // update api.
  const updateprogressiongenricmaster = async () => {
    const respone = await APICall(UpdateProgressionGenericMasterData, "POST", {
      Id: state.Id,
      IsActive: isactive
    });
  }

  const handleOnChangeIsActive = (event) => {
    console.log(event);
    if (event.target.checked) {
      setisactive(event.target.checked);
    } else {
      // setShowConfirmationModalForInactive(true);
      setisactive(event.target.checked);
    }
  };
  const handleOnClickUpdate = (event) => {
    console.log(event);
    if (isactive === oldisactive) {
      notify(1, "No changes were made.");
      return;
    } else {
      notify(0, "Data Submitted.");
      updateprogressiongenricmaster();
      Navigate(-1);
    }
  };
  return (
    <div>
      {/* For showing the data.  */}
      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Effective Date<sup>*</sup>
              </label>
              <DateForm
                isDisabled={true}
                value={effectiveDate}
                onChange={(date) => {

                }}
              />
            </div>
          </div>
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Remarks
              </label>
              <input
                type="text"
                className="form-control"
                value={remarks}
                // placeholder="Select Master Type"
                disabled={true}
              />
            </div>
          </div>
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Master Type<sup>*</sup>
              </label>
              <input
                type="text"
                className="form-control"
                value={masterTypeName}
                // placeholder="Select Master Type"
                disabled={true}
              />
            </div>
          </div>
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Generic Master Code<sup>*</sup>
              </label>
              <input
                type="text"
                className="form-control"
                value={genericmastercode}
                // placeholder="Select Master Type"
                disabled={true}
              />
            </div>
          </div>
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Generic Master Name<sup>*</sup>
              </label>
              <input
                type="text"
                className="form-control"
                value={genericmastername}
                // placeholder="Select Master Type"
                disabled={true}
              />
            </div>
          </div>
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Synced Status<sup>*</sup>
              </label>
              <input
                type="text"
                className="form-control"
                value={syncstatus}
                // placeholder="Select Master Type"
                disabled={true}
              />
            </div>
          </div>
          {parentid !== 0 && (
            <div className="col-lg-3 col-sm-3 col-xs-4">
              <div className="mb-1">
                <label className="col-form-label">
                  Parent
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={parentname}
                  // placeholder="Select Master Type"
                  disabled={true}
                />
              </div>
            </div>
          )}
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                IsActive<sup>*</sup>
              </label>
              <div>
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={isactive}
                  style={{ marginLeft: "0px" }}
                  disabled={disableSTate}
                  // value={isactive}
                  onChange={handleOnChangeIsActive}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* For showing the data. END */}
      {/* For button for update and back */}
      <div className="col-lg-12 col-sm-12 col-xs-12">
        <div className="mb-1">
          <div style={{ textAlign: "end" }}>
            <Button
              value={"Update"}
              onClick={handleOnClickUpdate}
              className="btn btn-filter-submit float-right ml-2 btnBlue"
              disabled={disableSTate}
            >
              <i className="fa-solid fa-check"></i> Update
            </Button>

            <button
              onClick={() => {
                Navigate(-1);
              }}
              disabled={false}
              className="btn btn-secondary float-right"
            >
              <i className="fa-solid fa-arrow-left"></i> Back
            </button>
          </div>
        </div>
      </div>
      {/* For button for update and back end */}
    </div>
  )
}

export default ProgressionGenericMasterUpdate
