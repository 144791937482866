import React, { useState, useEffect, useContext } from "react";
import { ThemeProvider } from "react-bootstrap";
import { APICall, APICallWithSignal } from "../../Helpers/APICalls";
import { LoaderContext } from "../../Helpers/Context/Context";
import {
  GetTabsForMRFAdminInterfaceLink,
  getAnonymousCandidateDashboardData,
  getDropdowns,
  getMrfDashboardData,
  getRecruiterDashboardData,
} from "../../Helpers/APIEndPoints/EndPoints";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import moment from "moment";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import SelectForm from "../../Components/SelectForm/SelectForm";
import InputForm from "../../Components/InputForm/InputForm";
import Collapse from "react-bootstrap/Collapse";
import { Tooltip } from "@mui/material";
import { act } from "react-dom/test-utils";
import { useNavigate } from "react-router-dom";
import { base64ToArrayBuffer } from "../../Helpers/Common";
import fileDownload from "js-file-download";
import notify from "../../Helpers/ToastNotification";

const MRFAdmin = () => {
  const [activeTab, setActiveTab] = useState(null);
  const [activetabIndex, setActivetabIndex] = useState<number>(0);
  const [ddValue, setDdValue] = useState({});
  const [gridData, setGridData] = useState(null);
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [PageSize, setPageSize] = useState(10);
  const [legalEntity, setLegalEntity] = useState<any>([]);
  const [mrfLocation, setMrfLocation] = useState<any>([]);
  const [Bussiness, setBussiness] = useState<any>([]);
  const [tabs, setTabs] = useState([]);
  const [actualPage, setActualPage] = useState(0);

  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { userDetails, currentRoleId }: any = useUserContext();

  const recruiterInterface = {
    MN: "Recruiter",
    IN: "Recruiter_Dashboard",
    TN: "Recruiter_Dashboard",
  };

  const weRiseInteface = {
    MN: "AnonymousCandidate",
    IN: "AnonymousCandidate_Dashboard",
    TN: "AnonymousCandidate_Dashboard",
  };

  const mrfInterface = {
    MN: "ManpowerRequisition",
    IN: "ManpowerRequisition_Dashboard",
    TN: "ManpowerRequisition_Dashboard",
  };

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchData = async () => {
      await getTabsForMRFAdminInterface({ signal });
    };

    fetchData();

    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const fetchData = async () => {
      await getInterfaceDetails({ signal });
      await getGridStructureTwo();
    };

    fetchData();

    return () => {
      abortController.abort();
    };
  }, [activeTab, page, searchText]);

  async function getTabsForMRFAdminInterface({ signal }) {
    try {
      showLoader();
      const response = await APICallWithSignal(
        GetTabsForMRFAdminInterfaceLink,
        "POST",
        {
          UserId: userDetails?.Id,
          RoleId: currentRoleId?.value,
        },
        true,
        null,
        signal
      );

      if (response?.status == 0) {
        const data = response?.data;
        if (data?.length > 0) {
          setActiveTab(data[0]);
          setTabs(data);
        }
      } else {
        setTabs([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  async function getInterfaceDetails({ signal }) {
    try {
      const bodyObject =
        activeTab?.value == "Manpower_Requisition_Dashboard"
          ? mrfInterface
          : activeTab?.value == "Recruiter_Dashboard_MRF_Admin"
          ? recruiterInterface
          : activeTab?.value == "WeRise_Dashboard"
          ? weRiseInteface
          : {};

      const gridDataResponse = await APICallWithSignal(
        "/api/Interface/GetInterfaceDetailsForGrid",
        "POST",
        bodyObject,
        true,
        null,
        signal
      );
      if (
        gridDataResponse?.data?.d?.fa !== null &&
        gridDataResponse?.data?.d?.fa
      ) {
        for await (const cFilter of gridDataResponse?.data?.d?.fa) {
          if (cFilter.filt === "multidropdown") {
            await getDropdownsData(cFilter.filAN, null, signal);
          }
        }
      }
      if (gridDataResponse?.data?.d?.afa !== null) {
        for await (const cFilter of gridDataResponse?.data?.d?.afa) {
          if (cFilter.filt === "multidropdown") {
            await getDropdownsData(cFilter.filAN, null, signal);
          }
        }
      }
      setGridData(gridDataResponse);
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  async function getDropdownsData(AC, cb, signal) {
    try {
      showLoader();
      const dropdownData = await APICallWithSignal(
        getDropdowns,
        "POST",
        {
          AC,
          PID: null,
          UserId: userDetails?.Id,
          RoleId: currentRoleId?.value,
        },
        true,
        null,
        signal
      );
      if (
        dropdownData.data !== null &&
        dropdownData.data !== undefined &&
        dropdownData.data.length > 0
      ) {
        setDdValue((prev) => ({ ...prev, [AC]: dropdownData.data }));
      } else {
        setDdValue((prev) => ({ ...prev, [AC]: [] }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  const getGridStructureTwo = async () => {
    showLoader();
    const dashboardName =
      activeTab?.value == "Manpower_Requisition_Dashboard"
        ? "ManpowerRequisition"
        : activeTab?.value == "Recruiter_Dashboard_MRF_Admin"
        ? "RecruiterDashboard"
        : activeTab?.value == "WeRise_Dashboard"
        ? "AnonymousCandidateDashboard"
        : "";

    const dashboardLink =
      activeTab?.value == "Manpower_Requisition_Dashboard"
        ? getMrfDashboardData
        : activeTab?.value == "Recruiter_Dashboard_MRF_Admin"
        ? getRecruiterDashboardData
        : activeTab?.value == "WeRise_Dashboard"
        ? getAnonymousCandidateDashboardData
        : getMrfDashboardData;

    const GetDashboardData = await APICall(dashboardLink, "POST", {
      DashboardName: dashboardName,
      PageNumber: page,
      PageSize: PageSize,
      SearchText: searchText,
      SortColumn: "",
      SortOrder: "",
      UserID: userDetails.Id,
      RoleId: currentRoleId?.value,
      MRORLEGENTY: legalEntity.map((s) => s.value).join(","),
      MRORLOC: mrfLocation.map((s) => s.value).join(","),
      UserId: userDetails?.Id,
      isMRFAdmin: true,
    });
    //;
    if (GetDashboardData?.data !== null && GetDashboardData?.data?.length > 0) {
      const localOffsetMinutes = moment().utcOffset();
      setCount(GetDashboardData.data[0].count);

      if (dashboardName == "ManpowerRequisition") {
        const mrfData = GetDashboardData.data;
        mrfData.forEach((mrf) => {
          mrf.openTime = mrf.openTime
            ? mrf.openTime < 2
              ? `${mrf.openTime} day`
              : `${mrf.openTime} days`
            : `0 day`;
        });
        setRowData(mrfData);
      } else if (dashboardName == "RecruiterDashboard") {
        const formattedData = GetDashboardData.data;
        setRowData(formattedData);
      } else if (dashboardName == "AnonymousCandidateDashboard") {
        const formattedData = GetDashboardData.data.map((item) => {
          const createdDateUtc = item.mrtbfbd;
          const dateParts = new Date(createdDateUtc)
            .toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            })
            .split("/");
          // const formattedDate = `${dateParts[0]} ${dateParts[1]} ${dateParts[2]}`;
          const formatedDate = moment(item.mrtbfbd).format("DD-MMM-YYYY");
          return {
            ...item,
            // mrtbfbd: formatedDate,
            mrtbfbd: dateParts[0] !== "Invalid Date" ? dateParts[0] : "",
          };
        });
        setRowData(formattedData);
      }
    } else {
      setRowData([]);
      setCount(0);
    }
    hideLoader();
  };

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: PageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",
    onChangePage: async (page) => {
      setActualPage(page);
      setPage(page * PageSize);
    },
    page: actualPage,
  };

  const navigate = useNavigate();

  const columnsWithCustomRender = gridData?.data?.d?.s[0]?.columnData.map(
    (column) => {
      if (column.name === "action") {
        return {
          ...column,
          options: {
            ...column.options,
            customBodyRender: (value, tableMeta, updateValue) => {
              const TId = tableMeta.rowData[0];
              return (
                <>
                  <div className="centerStyles">
                    <Tooltip title="view">
                      <i
                        style={{ marginRight: "0px", cursor: "pointer" }}
                        onClick={() => {
                          const dashboardName =
                            activeTab?.value == "Manpower_Requisition_Dashboard"
                              ? "ManpowerRequisition"
                              : activeTab?.value ==
                                "Recruiter_Dashboard_MRF_Admin"
                              ? "RecruiterDashboard"
                              : activeTab?.value == "WeRise_Dashboard"
                              ? "AnonymousCandidateDashboard"
                              : "";

                          if (
                            activeTab?.value == "Manpower_Requisition_Dashboard"
                          ) {
                            navigate("/requistion", {
                              state: { TId, IsMRFAdmin: true },
                            });
                          } else if (
                            activeTab?.value == "Recruiter_Dashboard_MRF_Admin"
                          ) {
                            navigate("/requistion", {
                              state: {
                                TId,
                                IsMRFAdmin: true,
                                isRecruiterDashboard: true,
                              },
                            });
                          } else {
                            navigate("/requistion", {
                              state: {
                                TId,
                                IsMRFAdmin: true,
                                showOnlyJD: true,
                              },
                            });
                          }
                        }}
                        className="fas fa-eye"
                      ></i>
                    </Tooltip>
                  </div>
                </>
              );
            },
          },
        };
      }
      let options = { ...column };
      options.options = {
        ...column.options,
        setCellProps: () => ({
          style: { minWidth: "150px", maxWidth: "300px" },
        }),
      };
      return options;
    }
  );

  const onFilterChange = (event, type) => {
    //;
    if (type === "MRORLEGENTY") {
      setLegalEntity(event);
    }

    if (type === "MRORLOC") {
      setMrfLocation(event);
    }
  };

  const onSearchFilter = (event, type) => {
    if (type === "Search MRF") {
      setSearchText(event.target.value);
    }
  };
  const onSubmitFilter = () => {
    if (gridData?.data?.d?.s[0]?.columnData.length > 0) {
      getGridStructureTwo();
    }
  };

  const onClickReset = () => {
    setLegalEntity([]);
    setMrfLocation([]);
    setSearchText("");
    setPage(0);
    setActualPage(0);
  };

  async function ExportToExcel() {
    try {
      showLoader();
      const response = await APICall(
        "/api/Dashboard/ExportToExcelMRFDashboard",
        "POST",
        {
          DashboardName: "",
          PageNumber: page,
          PageSize: PageSize,
          SearchText: searchText,
          SortColumn: "",
          SortOrder: "",
          UserID: userDetails.Id,
          RoleId: currentRoleId?.value,
          MRORLEGENTY: legalEntity.map((s) => s.value).join(","),
          MRORLOC: mrfLocation.map((s) => s.value).join(","),
          UserId: userDetails?.Id,
          isMRFAdmin: true,
          DashboardType: activeTab?.value,
          IsExcel: true,
        }
      );

      if (response?.status == 0) {
        const { base64 } = response?.data;

        if (base64) {
          const name =
            activeTab?.value == "Manpower_Requisition_Dashboard"
              ? "MRF_Dashboard"
              : activeTab?.value == "Recruiter_Dashboard_MRF_Admin"
              ? "Recruiter_Dashboard"
              : activeTab?.value == "WeRise_Dashboard"
              ? "WeRise_Dashboard"
              : "MRF_Dashboard";

          const fileName = `${name}_${moment().format(
            "DD-MM-YYYY HH:mm:ss"
          )}.xlsx`;

          let arrayBuffer = await base64ToArrayBuffer(base64);
          var byteArray = new Uint8Array(arrayBuffer);

          let blob = new Blob([byteArray], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          const newFile = new File([blob], "", {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            lastModified: new Date().getTime(),
          });

          fileDownload(newFile, fileName);
          notify(0, "File downloaded successfully.");
        }
      } else if (response?.status == 5) {
        notify(1, response?.message);
      } else {
        console.error(response?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  return (
    <>
      <div className="row mt-2 mb-2 justify-content-end mr-2">
        {tabs.map((tab, index) => (
          <React.Fragment key={index}>
            <button
              // className="btn  btn-primary mr-2"
              className={`btn  btn-${
                activeTab?.value == tab?.value ? "primary" : "secondary"
              } mr-2`}
              // className={`btn ${
              //   tab?.value == activeTab?.value ? "btn-secondary" : "btn-primary"
              // } mr-2`}
              onClick={() => {
                onClickReset();
                setActiveTab(tab);
              }}
            >
              {tab?.label || ""}
            </button>
          </React.Fragment>
        ))}
      </div>

      <div className="container-fluid">
        <div className="grid-wrapper grid_mob">
          <div className="row mx-auto filtBox">
            {gridData &&
              gridData?.data?.d?.fa.map((cFilter, index) => (
                <React.Fragment key={index}>
                  {cFilter.filt === "multidropdown" ? (
                    <>
                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                        <div className="form-group filtInp">
                          <span
                            className="mr-2 text-white"
                            style={{ float: "left" }}
                          >
                            <label className="col-form-label">
                              {cFilter.filn}
                            </label>
                          </span>
                          <SelectForm
                            isClearable
                            options={ddValue[cFilter.filAN]}
                            placeholder={cFilter.filn}
                            isDisabled={false}
                            onChange={(event) => {
                              setPage(0);
                              onFilterChange(event, cFilter.filAN);
                            }}
                            value={
                              cFilter.filAN === "MRORLEGENTY"
                                ? legalEntity
                                : cFilter.filAN === "MRORLOC"
                                ? mrfLocation
                                : null
                            }
                            isMulti={true}
                            noIndicator={false}
                            noSeparator={false}
                          />
                        </div>
                      </div>
                    </>
                  ) : cFilter.filt === "text" ? (
                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                      <div className="form-group filtInp">
                        <InputForm
                          value={
                            cFilter.filn === "Search MRF" ? searchText : ""
                          }
                          placeholder={cFilter.filn}
                          isDisabled={false}
                          textArea={false}
                          onChange={(e) => {
                            setPage(0);
                            onSearchFilter(e, cFilter.filn);
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                </React.Fragment>
              ))}

            <div className="col-lg-1 col-md-1 col-sm-12 filtrt">
              <ul className=" filter-icon-wel">
                <li>
                  <a
                    // href=""
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                    className="filter-btn"
                  >
                    <i className="fa fa-filter"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-lg-12 col-md-10 col-sm-12 "></div>

        <>
          <Collapse in={open}>
            <div id="example-collapse-text grid-wrapper">
              <div className="row mx-auto myInlineStyle">
                {gridData &&
                  gridData?.data?.d?.afa?.map((cFilter, index) => (
                    <>
                      {cFilter.filt === "multidropdown" ? (
                        <>
                          <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                            <div className="form-group filtInp">
                              <span
                                className="mr-2 text-white"
                                style={{ float: "left" }}
                              >
                                <label className="col-form-label">
                                  {cFilter.filn}
                                </label>
                              </span>
                              <SelectForm
                                isClearable
                                options={ddValue[cFilter.filAN]}
                                placeholder={cFilter.filn}
                                isDisabled={false}
                                onChange={(event) => {
                                  onFilterChange(event, cFilter.filAN);
                                }}
                                isMulti={true}
                                noIndicator={false}
                                noSeparator={false}
                                value={
                                  cFilter.filAN === "MRORLEGENTY"
                                    ? legalEntity
                                    : cFilter.filAN === "MRORLOC"
                                    ? mrfLocation
                                    : null
                                }
                              />
                            </div>
                          </div>
                        </>
                      ) : cFilter.filt === "text" ? (
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                          <div className="form-group filtInp">
                            <InputForm
                              value={
                                cFilter.filn === "Search MRF" ? searchText : ""
                              }
                              placeholder={cFilter.filn}
                              isDisabled={false}
                              textArea={false}
                              onChange={(e) => {
                                onSearchFilter(e, cFilter.filn);
                              }}
                            />
                          </div>
                        </div>
                      ) : null}
                    </>
                  ))}
                <div className="col-lg-12 d-flex mt-2 justify-content-end">
                  <button
                    className="btn btn-secondary mr-2"
                    onClick={onClickReset}
                  >
                    <i className="fa fa-undo" aria-hidden="true"></i> Reset
                  </button>
                  <button
                    className="btn btn-filter-submit"
                    onClick={onSubmitFilter}
                  >
                    <i className="fa fa-check" aria-hidden="true"></i> Submit{" "}
                  </button>
                </div>
              </div>
            </div>
          </Collapse>
        </>
        <div className="col-lg-12 d-flex justify-content-end mt-2">
          <button className="btn btn-primary" onClick={ExportToExcel}>
            {" "}
            Export To Excel
          </button>
        </div>
        <div className="mb-3 pt-3">
          <DynamicGrid
            data={rowData}
            columns={columnsWithCustomRender}
            options={options}
          />
        </div>
      </div>
    </>
  );
};

export default MRFAdmin;
