import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { LoaderContext } from "../../Helpers/Context/Context";
import {
  DeleteThumbnailProgramDetails,
  GetCommonDropdownForScheduling,
  GetConfigDataCallForScromProject,
  GetThumbnailDetails,
  LMSContentUploadFile,
  LMSManageContentMaster,
  LMSUpdateScromData,
  SetThumbnailDetails,
  checkMp4VideoStatus,
  getContentDasboardFilterData,
  getContentUrlDetails,
  getLMSDomainDropDownData,
  getLMSManageContentMaster,
  setContentUrlDetails,
  uploadVimeoMP4File,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import { APICall } from "../../Helpers/APICalls";
import InputForm from "../../Components/InputForm/InputForm";
import SelectForm from "../../Components/SelectForm/SelectForm";
import notify from "../../Helpers/ToastNotification";
import { Modal, ProgressBar } from "react-bootstrap";
import defaultProfileImgPic from "../../Assets/Images/thumbnail.jpg";
import axios from "axios";
import { Tooltip } from "@mui/material";
import "./content.css";
import DateTimeForm from "../../Components/DateTimeForm/DateTimeForm";
import PdfViewer from "../../Components/PdfViewer/PdfViewer";
import ReactPlayer from "react-player";
const Contentedit = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [attributeDataObj, setAttributeDataObj] = useState<any>({
    contentId: "",
    contentTitle: "",
    durationInHours: "",
    durationInMinutes: "",
    contentType: null,
    content: null,
    link: null,
    domain: null,
  });

  const [isDisable, setIsDisable] = useState(
    state && state?.isDisable ? true : false
  );

  const chunkSize = 1048576 * 10; //its 3MB, increase the number measure in mb

  const [formErrors, setFormErrors] = useState<any>({});
  const [contentTypeOptions, setContentTypeOptions] = useState([]);
  const [reset, setReset] = useState(false);

  const [showProfileModal, setShowProfileModal] = useState(false);
  const [profileImg, setProfileImg] = useState(null);
  const [profileImgToUpload, setProfileImgToUpload] = useState<any>(null);

  const [isUploadFile, setIsUploadFile] = useState(false);
  const [fileName, setfileName] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);

  const [progress, setProgress] = useState(0);

  const [showDocumentModal, setShowDocumentModal] = useState(false);

  const [contentName, setContentName] = useState("");

  const [contentUrl, setContentUrl] = useState("");

  const [notification, setNotification] = useState(false);
  const [toEmail, setToEmail] = useState("");
  const [effectiveDate, setEffectiveDate] = useState(new Date());
  const [remarks, setRemarks] = useState("");
  const [ccuser, setCCuser] = useState<any>(null);

  const fileRef = useRef(null);

  const [isContentShow, setisContentShow] = useState(
    state?.contentId > 0 ? true : false
  );

  const [isFileUploaded, setisFileUploaded] = useState(false);

  const [domainOption, setDomainOption] = useState([]);

  const [isDurationMandatory, setIsDurationMandatory] = useState(true);

  const [SCROMUserId, setSCROMUserId] = useState(null);
  const [SCROMPassword, setSCROMPassword] = useState(null);
  const [SCROMAPIUrl, setSCROMAPIUrl] = useState(null);

  const contentTypeObj = {
    Scrome: "SCROME",
    Audio: "AUDIO",
    Pdf: "PDF",
    Link: "LINK",
    VideoLink: "VIDEOLINK",
    MP4: "MP4",
  };

  const contentObj = {
    Scrome: "SCROME",
    Audio: "AUDIO",
    Pdf: "PDF",
    Link: "LINK",
    VideoLink: "VIDEOLINK",
    MP4: "MP4",
  };

  useEffect(() => {
    (async () => {
      await GetConfigDataCallForScromProjectApiCall();
    })();
  }, []);

  const GetConfigDataCallForScromProjectApiCall = async () => {
    try {
      showLoader();
      const response = await APICall(GetConfigDataCallForScromProject, "POST", {
        UserId: userDetails?.Id,
        RoleID: currentRoleId?.value,
      });

      if (response.status === 0) {
        setSCROMUserId(response?.data?.scromUserId);
        setSCROMPassword(response?.data?.scromPassword);
        setSCROMAPIUrl(response?.data?.scromapiUrl);
      } else {
        hideLoader();
      }
    } catch (error) {
      console.error("Error :", error);
    } finally {
      hideLoader();
    }
  };

  const handleFileChangeScromeAndMp4 = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    setisFileUploaded(true);
  };

  const getJWTokenApiCall = async () => {
    try {
      let headersList = {
        Accept: "*/*",
        // "User-Agent": "Thunder Client (https://www.thunderclient.com)",
        "Content-Type": "application/json",
      };

      let bodyContent = JSON.stringify({
        Name: SCROMUserId,
        password: SCROMPassword,
      });

      let reqOptions = {
        url: `${SCROMAPIUrl}/APILogin/GenerateJWToken`,
        method: "POST",
        headers: headersList,
        data: bodyContent,
      };

      let response = await axios.request(reqOptions);

      let { Data, Status, Message } = response.data;
      if (Status === 0 && (Data?.Token !== null || Data?.Token !== "")) {
        return Data?.Token;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error : ", error);
      return null;
    }
  };

  const handleUploadScrome = async (id: any) => {
    if (selectedFile) {
      try {
        showLoader();

        const userAuthToken = await getJWTokenApiCall();

        if (userAuthToken === null) {
          notify(1, "AuthToken is null");
          return false;
        } else {
          let formData = new FormData();
          formData.append("File", selectedFile);
          formData.append("FileName", selectedFile.name);
          formData.append("Id", id);

          formData.append(
            "EmailId",
            proxyUserDetails === null
              ? userDetails?.EmailId
              : proxyUserDetails?.EmailId
          );
          formData.append("UserId", userDetails?.Id);
          formData.append(
            "EmployeeCode",
            proxyUserDetails === null
              ? userDetails?.EmployeeCode
              : proxyUserDetails?.EmployeeId
          );

          try {
            let headersList = {
              Accept: "*/*",
              // "User-Agent": "Thunder Client (https://www.thunderclient.com)",
              Authorization: "Bearer " + userAuthToken,
            };

            let bodyContent = formData;

            let reqOptions = {
              url: `${SCROMAPIUrl}/UserMaster/UploadFile`,
              method: "POST",
              headers: headersList,
              data: bodyContent,
              onUploadProgress: (event) => {
                debugger;
                const percentCompleted = Math.round(
                  (event.loaded * 100) / event.total
                );
                setProgress(percentCompleted);
              },
            };

            // let response = await axios.request(reqOptions);

            const response = await axios.post(
              `${SCROMAPIUrl}/UserMaster/UploadFile`,
              bodyContent,
              {
                headers: {
                  Accept: "*/*",
                  Authorization: "Bearer " + userAuthToken,
                },
                onUploadProgress: (event) => {
                  const percentCompleted = Math.round(
                    (event.loaded * 100) / event.total
                  );
                  setProgress(percentCompleted);
                },
              }
            );

            let { Data, Status, Message } = response.data;
            if (Status === 0) {
              console.log(Data);

              const response = await APICall(LMSUpdateScromData, "POST", {
                Id: id,
                ScromCourseId: Data?.ScromCourseId,
                ContentUrl: selectedFile.name,
                CreatedBy: userDetails?.Id,
              });

              if (response.status === 0) {
                return true;
              } else {
                return false;
              }
            } else {
              console.error("Error message : ", Message);
              notify(1, Message);
              return false;
            }
          } catch (error) {
            if (error.response) {
              console.error("Error response:", error.response);
              console.error("Error data:", error.response.data);
            } else if (error.request) {
              console.error("Error request:", error.request);
            } else {
              console.error("Error:", error.message);
            }
            return false;
          }
        }
      } catch (error) {
        console.error("Error:", error);
        console.error("Error message:", error.message);
        return false;
      }
    } else {
      notify(1, "Please upload decument");
    }
  };

  // const handleUploadScrome = async (id: any) => {
  //   if (selectedFile) {
  //     const chunkSize = 1024 * 1024; // 1MB chunks (adjust as needed)
  //     const totalChunks = Math.ceil(selectedFile.size / chunkSize);

  //     const chunkRange = Array.from(
  //       { length: totalChunks },
  //       (_, currentChunk) => currentChunk
  //     );

  //     for await (let currentChunk of chunkRange) {
  //       console.log("inside", currentChunk);

  //       const start = currentChunk * chunkSize;
  //       const end = Math.min((currentChunk + 1) * chunkSize, selectedFile.size);
  //       const chunk = selectedFile.slice(start, end);

  //       const formData = new FormData();
  //       formData.append("file", chunk);
  //       formData.append("fileName", selectedFile.name);
  //       formData.append("totalChunks", String(totalChunks));
  //       formData.append("currentChunk", String(currentChunk));
  //       formData.append("Id", id);
  //       formData.append("CreatedBy", userDetails?.Id);

  //       try {
  //         const response = await axios.post(LMSContentUploadFile, formData, {
  //           headers: {
  //             "Content-Type": "multipart/form-data",
  //           },
  //           onUploadProgress: (progressEvent) => {
  //             const loadedBytes =
  //               currentChunk * chunkSize + progressEvent.loaded;
  //             const totalBytes = totalChunks * chunkSize;
  //             const percentCompleted = Math.round(
  //               (loadedBytes * 100) / totalBytes
  //             );

  //             setProgress(percentCompleted);

  //             // console.log("percentCompleted", percentCompleted);

  //             // console.log(Upload Progress: ${percentCompleted}%);
  //           },
  //         });

  //         if (currentChunk === totalChunks - 1) {
  //           if (response?.data?.data?.isFileUploaded === true) {
  //             return true;
  //           } else if (response?.data?.status === 1) {
  //             // notify(1, "Invalid scrome file.");

  //             setProgress(0);

  //             notify(response?.data?.status, response?.data?.message);

  //             return false;
  //           }
  //         }

  //         console.log("Chunk uploaded successfully:", response.data);
  //       } catch (error) {
  //         console.error("Error uploading chunk:", error);
  //         notify(1, `Gettion Error uploading chunk ${error}`);
  //         // Handle error, e.g., show an error message to the user
  //         return "Error uploading chunk.";
  //       }
  //     }
  //     console.log("outside");

  //     // return true;
  //   } else {
  //     notify(1, "Please upload decument");
  //   }
  //   // return true;
  // };

  const handleMp4Upload = async (id) => {
    if (selectedFile) {
      showLoader();
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append(
        "fileName",
        `${attributeDataObj.contentTitle}-${selectedFile.name}`
      );
      formData.append("Id", id);
      formData.append("CreatedBy", userDetails?.Id);

      try {
        const response = await axios.post(uploadVimeoMP4File, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (event) => {
            const percentCompleted = Math.round(
              (event.loaded * 100) / event.total
            );
            setProgress(percentCompleted);
          },
        });

        const videoUri = response.data.data.contentUrl;
        // console.log("Video URI:", videoUri);
        hideLoader();
        return true;
        // You can add further processing or redirect here
      } catch (error) {
        console.error("Upload error:", error);
        hideLoader();
        setProgress(0);
        return false;
      }
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    let fileBase64: { Name: string; Data: string } = null;
    const base64 = (await fileToBase64(file)) as string;
    const base64WithoutPrefix = base64.split(",")[1];
    fileBase64 = { Name: file.name, Data: base64WithoutPrefix };

    //".docx,.doc,.pdf,.png,.jpg,.jpeg,.txt,.ppt,.pptx,.xlsx"
    let validExtensions = [];

    if (
      attributeDataObj?.contentType?.contentTypeCode === contentTypeObj?.Pdf
    ) {
      validExtensions = ["pdf"];
    } else if (
      attributeDataObj?.contentType?.contentTypeCode === contentTypeObj?.Audio
    ) {
      validExtensions = ["mp3"];
    }
    //added by mahesh
    else if (
      attributeDataObj?.contentType?.contentTypeCode === contentTypeObj?.MP4
    ) {
      validExtensions = ["mp4"];
    }
    // end
    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();
      const fileSize = file.size;
      let maxFileSizeBytes;
      if (
        attributeDataObj?.contentType?.contentTypeCode === contentTypeObj?.MP4
      ) {
        maxFileSizeBytes = 15 * 1024 * 1024;
      } else {
        maxFileSizeBytes = 5 * 1024 * 1024;
      }

      // Check if the file extension is allowed
      if (
        validExtensions.includes(fileExtension) &&
        fileSize <= maxFileSizeBytes
      ) {
        // file to upload
        setSelectedFile(fileBase64);
        // setProfileImg(`data:image;base64,${fileBase64.Data}`);
      } else {
        if (
          attributeDataObj?.contentType?.contentTypeCode === contentTypeObj?.Pdf
        ) {
          notify(1, "Only file with pdf allowed and size under 5MB");
        } else if (
          attributeDataObj?.contentType?.contentTypeCode ===
          contentTypeObj?.Audio
        ) {
          notify(1, "Only file with mp3 allowed and size under 5MB");
        } else if (
          attributeDataObj?.contentType?.contentTypeCode === contentTypeObj?.MP4
        ) {
          notify(1, "Only file with mp4 allowed and size under 15MB");
        }

        e.target.value = "";
        setSelectedFile(null);
      }
    }
  };

  const handleFileUpload = async (id: any) => {
    if (selectedFile) {
      showLoader();
      let reqObj = {
        file: {
          Name: selectedFile.Name,
          Data: selectedFile.Data,
        },
        Id: id,
        SectionName: "Content",
        UserId: userDetails?.UserId,
      };

      try {
        const response = await APICall(setContentUrlDetails, "POST", reqObj);
        if (response && response.data) {
          // setProfileImg(`data:image;base64,${response.data.base64}`);
          hideLoader();
        } else {
          console.error("Image upload failed");
          notify(1, "File upload failed.");
          hideLoader();
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        hideLoader();
      }
    }
  };

  useEffect(() => {
    const responseData = async () => {
      const objContentType = {
        mode: "CONTENTTYPEEDIT",
        userId: userDetails?.Id,
        roleId: currentRoleId?.value,
      };
      const res3 = await APICall(
        getContentDasboardFilterData,
        "POST",
        objContentType
      );
      if (res3.data && res3.data.length > 0) {
        setContentTypeOptions(res3.data);
      }
    };

    responseData();
    getLMSDomainDropDownDataApiCall();
  }, []);

  const deleteProfileImage = async (id: any) => {
    if (profileImg != null) {
      showLoader();
      const doc = await APICall(DeleteThumbnailProgramDetails, "POST", {
        id,
        CreatedBy: userDetails?.Id,
      });
      if (doc.message === "Ok") {
        setProfileImg(null);
        setShowProfileModal(false);
        setProfileImgToUpload(null);
        hideLoader();
      } else {
        notify(1, "File not Deleted, Something Went Wrong.");
        hideLoader();
      }
    }
  };

  const fileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleImageSelect = async (e) => {
    const file = e.target.files[0];
    let fileBase64: { Name: string; Data: string } = null;
    const base64 = (await fileToBase64(file)) as string;
    const base64WithoutPrefix = base64.split(",")[1];
    fileBase64 = { Name: file.name, Data: base64WithoutPrefix };

    const validExtensions = ["png", "jpg", "jpeg"];
    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();
      const fileSize = file.size;
      const maxFileSizeBytes = 5 * 1024 * 1024;

      // Check if the file extension is allowed
      if (
        validExtensions.includes(fileExtension) &&
        fileSize <= maxFileSizeBytes
      ) {
        // file to upload
        setProfileImgToUpload(fileBase64);
        setProfileImg(`data:image;base64,${fileBase64.Data}`);
      } else {
        notify(1, "Only file with png,jpg,jpeg allowed and size under 5MB");
        e.target.value = "";
        setProfileImgToUpload(null);
      }
    }
  };

  const handleImageUpload = async (id: any) => {
    if (profileImgToUpload) {
      showLoader();
      let reqObj = {
        file: {
          // AC: "ProfilePic",
          Name: profileImgToUpload.Name,
          Data: profileImgToUpload.Data,
        },
        PId: id,
        SectionName: "Content",
        UserId: userDetails?.UserId,
        ///ModuleName: "EmployeeCentral",
        //TabName: "Personal",
        //ModuleId: 1,
        // ProxyUserId: proxyUserDetails === null ? null : proxyUserDetails?.value,
        // Id: id,
      };

      try {
        const response = await APICall(SetThumbnailDetails, "POST", reqObj);
        if (response && response.data) {
          setProfileImg(`data:image;base64,${response.data.base64}`);
          setShowProfileModal(false);
          hideLoader();
        } else {
          console.error("Image upload failed");
          notify(1, "Image upload failed.");
          hideLoader();
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        hideLoader();
      }
    }
    // else {
    //   notify(1, "Please Select Image to Upload.");
    // }
  };

  const formOnChange = (e, type) => {
    switch (type) {
      case "contentTitle":
        setAttributeDataObj({
          ...attributeDataObj,
          contentTitle: e.target.value,
        });
        break;

      case "contentType":
        setAttributeDataObj({
          ...attributeDataObj,
          contentType: e,
        });

        if (e?.contentTypeCode === "PDF" || e?.contentTypeCode === "SCROME") {
          setIsDurationMandatory(false);
        } else {
          setIsDurationMandatory(true);
        }
        setSelectedFile(null);
        setIsUploadFile(e?.isFileUpload);
        break;

      case "durationInHours":
        setAttributeDataObj({
          ...attributeDataObj,
          durationInHours: e.target.value,
        });
        break;
      case "durationInMinutes":
        setAttributeDataObj({
          ...attributeDataObj,
          durationInMinutes: e.target.value,
        });
        break;

      case "link":
        setAttributeDataObj({
          ...attributeDataObj,
          link: e.target.value,
        });
        break;

      case "domain":
        setAttributeDataObj({
          ...attributeDataObj,
          domain: e,
        });
        break;

      default:
        break;
    }
  };

  const validateForm = () => {
    let errorObj = {};
    let isError = false;

    if (attributeDataObj.contentTitle === "") {
      errorObj = {
        ...errorObj,
        ["contentTitle"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["contentTitle"]: "",
      };
    }

    if (attributeDataObj.contentType === null) {
      errorObj = {
        ...errorObj,
        ["contentType"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["contentType"]: "",
      };
    }

    // if (
    //   attributeDataObj.durationInHours !== "" &&
    //   attributeDataObj.durationInHours !== null
    // ) {
    //   if (!/^([0-9]+|0)$/.test(attributeDataObj.durationInHours)) {
    //     errorObj = {
    //       ...errorObj,
    //       ["durationInHours"]: "Only numbers are allowed",
    //     };
    //   } else {
    //     errorObj = {
    //       ...errorObj,
    //       ["durationInHours"]: "",
    //     };
    //   }
    // }

    // if (
    //   attributeDataObj.durationInMinutes !== "" &&
    //   attributeDataObj.durationInMinutes !== null
    // ) {
    //   if (!/^([0-9]+|0)$/.test(attributeDataObj.durationInMinutes)) {
    //     errorObj = {
    //       ...errorObj,
    //       ["durationInMinutes"]: "Only numbers are allowed",
    //     };
    //   } else if (parseInt(attributeDataObj.durationInMinutes) > 59) {
    //     errorObj = {
    //       ...errorObj,
    //       ["durationInMinutes"]: "Please enter in minutes",
    //     };
    //   } else {
    //     errorObj = {
    //       ...errorObj,
    //       ["durationInMinutes"]: "",
    //     };
    //   }
    // }

    if (isDurationMandatory) {
      if (attributeDataObj.durationInHours === "") {
        errorObj = {
          ...errorObj,
          ["durationInHours"]: "Required",
        };
      } else if (!/^([0-9]+|0)$/.test(attributeDataObj.durationInHours)) {
        errorObj = {
          ...errorObj,
          ["durationInHours"]: "Only numbers are allowed",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["durationInHours"]: "",
        };
      }
    } else {
      if (
        attributeDataObj.durationInHours !== "" &&
        attributeDataObj.durationInHours !== null
      ) {
        if (
          attributeDataObj.durationInHours !== "" &&
          !/^([0-9]+|0)$/.test(attributeDataObj.durationInHours)
        ) {
          errorObj = {
            ...errorObj,
            ["durationInHours"]: "Only numbers are allowed",
          };
        } else {
          errorObj = {
            ...errorObj,
            ["durationInHours"]: "",
          };
        }
      } else {
        errorObj = {
          ...errorObj,
          ["durationInHours"]: "",
        };
      }
    }

    if (isDurationMandatory) {
      if (attributeDataObj.durationInMinutes === "") {
        errorObj = {
          ...errorObj,
          ["durationInMinutes"]: "Required",
        };
      } else if (!/^([0-9]+|0)$/.test(attributeDataObj.durationInMinutes)) {
        errorObj = {
          ...errorObj,
          ["durationInMinutes"]: "Only numbers are allowed",
        };
      } else if (parseInt(attributeDataObj.durationInMinutes) > 59) {
        errorObj = {
          ...errorObj,
          ["durationInMinutes"]: "Please enter in minutes",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["durationInMinutes"]: "",
        };
      }
    } else {
      if (
        attributeDataObj.durationInMinutes === "" &&
        attributeDataObj.durationInMinutes === null
      ) {
        if (
          attributeDataObj.durationInMinutes !== "" &&
          !/^([0-9]+|0)$/.test(attributeDataObj.durationInMinutes)
        ) {
          errorObj = {
            ...errorObj,
            ["durationInMinutes"]: "Only numbers are allowed",
          };
        } else if (
          attributeDataObj.durationInMinutes !== "" &&
          parseInt(attributeDataObj.durationInMinutes) > 59
        ) {
          errorObj = {
            ...errorObj,
            ["durationInMinutes"]: "Please enter in minutes",
          };
        } else {
          errorObj = {
            ...errorObj,
            ["durationInMinutes"]: "",
          };
        }
      } else {
        errorObj = {
          ...errorObj,
          ["durationInMinutes"]: "",
        };
      }
    }

    if (attributeDataObj?.contentType?.isFileUpload) {
      if (selectedFile === null || selectedFile === "") {
        errorObj = {
          ...errorObj,
          ["fileName"]: "Please upload content",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["fileName"]: "",
        };
      }
    } else {
      if (attributeDataObj?.link === "" || attributeDataObj?.link === null) {
        errorObj = {
          ...errorObj,
          ["link"]: "Please enter link",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["link"]: "",
        };
      }
    }

    const isEmpty = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  useEffect(() => {
    if (state && state?.contentId > 0 && contentTypeOptions?.length > 0) {
      getLMSContentMasterDataByIdApiCall();
    }
  }, [contentTypeOptions, reset]);

  const getLMSDomainDropDownDataApiCall = async () => {
    const response = await APICall(getLMSDomainDropDownData, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      GetAll: state && state?.contentId > 0 ? true : false,
    });

    if (response?.status === 0 && response?.data.length > 0) {
      setDomainOption(response?.data);
    }
  };
  const getLMSContentMasterDataByIdApiCall = async () => {
    showLoader();
    const responseData = await APICall(getLMSManageContentMaster, "POST", {
      Id: state?.contentId,
    });

    if (responseData?.status === 0) {
      showLoader();
      const thumbnailUrl = responseData?.data?.thumbnailUrl;

      setToEmail(responseData?.data?.toUser);

      if (
        responseData?.data?.contentType === "PDF" ||
        responseData?.data?.contentType === "SCROM"
      ) {
        setIsDurationMandatory(false);
      } else {
        setIsDurationMandatory(true);
      }

      if (thumbnailUrl) {
        const getThumbnailData = await APICall(GetThumbnailDetails, "POST", {
          ThumbnailUrl: thumbnailUrl,
        });

        if (getThumbnailData && getThumbnailData.data !== null) {
          setProfileImg(
            `data:image;base64,${getThumbnailData?.data?.thumbnailDataObject}`
          );
        } else {
          setSelectedFile(null);
        }
      }

      //Domain
      const domainIds = responseData?.data?.domainIds?.split(",") || null;
      const domainArray = [];

      domainIds?.map((item) => {
        const domainData = domainOption.find((i) => i.value === parseInt(item));
        if (domainData !== undefined) {
          domainArray.push(domainData);
        }
      });

      const contentType = contentTypeOptions?.find(
        (s) => parseInt(s.value) === responseData?.data?.contentTypeId
      );

      setIsUploadFile(contentType?.isFileUpload);

      let link = "";

      if (contentType?.isFileUpload) {
        const contentUrl = responseData?.data?.contentUrl;

        if (contentType?.contentTypeCode === contentTypeObj?.Scrome) {
          const data = "";
          const name = contentUrl;

          debugger;

          if (name) {
            let fileBase64: { name: string; data: string } = null;
            // fileBase64 = { name: responseData?.data?.displayUrl, data: data };
            fileBase64 = { name: name, data: data };

            setSelectedFile(fileBase64);

            // setProgress(100);

            setContentUrl(contentUrl);

            setContentName(contentObj?.Scrome);
          }
        } else if (
          contentType?.contentTypeCode === contentTypeObj?.Audio ||
          contentType?.contentTypeCode === contentTypeObj?.Pdf
          // || contentType?.contentTypeCode === contentTypeObj?.MP4
        ) {
          showLoader();
          if (contentUrl) {
            const getContentUrl = await APICall(getContentUrlDetails, "POST", {
              ContentUrl: contentUrl,
            });

            const data = getContentUrl?.data?.contentUrlDataObject;
            const name = responseData?.data?.displayUrl; //getContentUrl?.data?.fileName;

            setContentUrl(data);

            responseData?.data?.contentType === contentTypeObj?.Audio
              ? setContentName(contentObj?.Audio)
              : setContentName(contentObj?.Pdf);

            if (data && name) {
              let fileBase64: { Name: string; Data: string } = null;
              fileBase64 = { Name: name, Data: data };

              setSelectedFile(fileBase64);
            }
          }
        } else if (contentType?.contentTypeCode === contentTypeObj?.MP4) {
          setContentName(contentType?.contentTypeCode);
          setContentUrl(responseData?.data?.contentUrl);
        }
      } else if (
        contentType?.contentTypeCode === contentTypeObj?.Link ||
        contentType?.contentTypeCode === contentTypeObj?.VideoLink
      ) {
        link = responseData?.data?.contentUrl;

        setContentName(contentType?.contentTypeCode);

        setContentUrl(responseData?.data?.contentUrl);
      }

      const LMSContentMasterData = {
        contentId: responseData?.data?.contentCode,
        contentTitle: responseData?.data?.contentTitle,
        durationInHours: responseData?.data?.totalDurationHours,
        durationInMinutes: responseData?.data?.totalDurationMinutes,
        contentType: contentType,
        link: link,
        domain: domainArray.length > 0 ? domainArray : null,
      };

      setAttributeDataObj(LMSContentMasterData);
      hideLoader();
    } else {
    }
  };

  async function fetchDropdown(searchText = "", code = "", value = null) {
    try {
      const response = await APICall(
        GetCommonDropdownForScheduling,
        "POST",
        {
          SearchText: searchText,
          DropdownValues: value,
          Code: code,
          UserId: userDetails?.Id,
          RoleId: currentRoleId?.value,
        },
        true
      );

      if (response?.status === 0) {
        if (response?.data) {
          return response?.data;
        } else {
          return [];
        }
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  async function handleSearchForAsyncDropdown(event, code, callback) {
    try {
      if (event?.length >= 3) {
        const data = await fetchDropdown(event, code);
        callback(data);
      } else {
        callback([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const requestParamsFun = () => {
    const requestParams = {
      Id:
        state === null ||
        state?.contentId === null ||
        state?.contentId === undefined
          ? 0
          : state?.contentId,
      ContentTitle: attributeDataObj?.contentTitle,
      DurationInHours:
        attributeDataObj?.durationInHours !== ""
          ? attributeDataObj?.durationInHours
          : null,
      DurationInMinutes:
        attributeDataObj?.durationInMinutes !== ""
          ? attributeDataObj?.durationInMinutes
          : null,
      ContentTypeId: attributeDataObj?.contentType.value,
      ContentUrl:
        attributeDataObj?.link === "" ? contentUrl : attributeDataObj?.link,
      isActive: true,
      createdBy: userDetails?.Id,
      DomainIds:
        attributeDataObj?.domain !== null
          ? attributeDataObj?.domain?.map((event) => event.value).join(",")
          : null,
      ToUser: toEmail || null,
      CCUserIds: ccuser?.map((event) => event.value).join(",") || null,
      EffectiveDate: effectiveDate || null,
      Remarks: remarks || null,
    };

    return requestParams;
  };

  const handleSubmitButton = async () => {
    showLoader();

    if (validateForm()) {
      hideLoader();
      return;
    }

    const requestParams = requestParamsFun();

    if (requestParams?.Id === 0) {
      insertUpdateData();
    } else {
      setNotification(true);
    }

    hideLoader();
  };

  const InsertUpdateDataOnNotification = async () => {
    if (validateForm()) {
      hideLoader();
      setNotification(false);
      return; // Stop the submission if there are validation errors
    }
    setNotification(false);

    insertUpdateData();
  };

  const insertUpdateData = async () => {
    showLoader();
    setProgress(0);

    const requestParams = requestParamsFun();

    const response = await APICall(
      LMSManageContentMaster,
      "POST",
      requestParams
    );

    if (response?.status === 0) {
      const newContentId = response?.data?.id;

      await handleImageUpload(newContentId);

      if (
        isUploadFile &&
        selectedFile &&
        attributeDataObj?.contentType?.contentTypeCode ===
          contentTypeObj?.Scrome
      ) {
        if (isFileUploaded) {
          const IsFileUploadCompleted = await handleUploadScrome(newContentId);

          if (IsFileUploadCompleted) {
            setReset(!reset);
            notify(response?.status, response?.message);
            navigate("/LMContent");
          } else {
            setReset(!reset);
            notify(1, "Getting error while uploading SCROM");
            navigate("/LMContent");
          }
        } else {
          setReset(!reset);
          notify(response?.status, response?.message);
          navigate("/LMContent");
        }
      } else if (
        isUploadFile &&
        selectedFile &&
        attributeDataObj?.contentType?.contentTypeCode === contentTypeObj?.MP4
      ) {
        if (isFileUploaded) {
          const IsFileUploadCompleted = await handleMp4Upload(newContentId);

          if (IsFileUploadCompleted) {
            setReset(!reset);
            notify(response?.status, response?.message);
            navigate("/LMContent");
          }
        } else {
          setReset(!reset);
          notify(response?.status, response?.message);
          navigate("/LMContent");
        }
      } else if (
        isUploadFile &&
        selectedFile &&
        (attributeDataObj?.contentType?.contentTypeCode ===
          contentTypeObj?.Audio ||
          attributeDataObj?.contentType?.contentTypeCode ===
            contentTypeObj?.Pdf)
      ) {
        await handleFileUpload(newContentId);
        setReset(!reset);
        notify(response?.status, response?.message);
        navigate("/LMContent");
      } else {
        setReset(!reset);
        notify(response?.status, response?.message);
        navigate("/LMContent");
      }
    } else {
      notify(response?.status, response?.message);
    }

    hideLoader();
  };

  const resetFunction = () => {
    setFormErrors({});

    const objManageSession = {
      contentId: "",
      contentTitle: "",
      durationInHours: "",
      durationInMinutes: "",
      contentType: null,
      content: null,
      link: "",
    };

    setSelectedFile(null);

    setAttributeDataObj(objManageSession);
  };

  return (
    <>
      <div className="breadcrumb modified-bredcrumb">
        <span>
          <ul>
            <li>
              <a href="/home">Home</a>
            </li>
            <li>
              {" "}
              <a href="/Content">Content</a>
            </li>
            <li>Manage Content</li>
          </ul>
        </span>
        <button
          onClick={() => {
            navigate("/LMContent");
          }}
          disabled={false}
          className="btn btn-secondary float-right"
        >
          <i className="fa-solid fa-arrow-left"></i> Back
        </button>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 d-flex justify-content-center align-items-start">
            <div className="card Prof_img">
              <img
                src={profileImg != null ? profileImg : defaultProfileImgPic}
                className="card-img-top"
                alt="Thumbnail Image"
              />
              {!isDisable && (
                <a
                  className="edit-profile"
                  onClick={() => setShowProfileModal(true)}
                >
                  <i className="fas fa-edit"></i>
                </a>
              )}
            </div>
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Content ID
                    <sup>*</sup>
                  </label>

                  <InputForm
                    className="form-control"
                    placeholder={"Content ID"}
                    isDisabled={true}
                    textArea={false}
                    value={attributeDataObj.contentId}
                    onChange={(e) => {}}
                    maxLength="255"
                  />
                  {formErrors["contentId"] && (
                    <p style={{ color: "red" }}>{formErrors["contentId"]}</p>
                  )}
                </div>
              </div>
              <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Content Title
                    <sup>*</sup>
                  </label>

                  <InputForm
                    className="form-control"
                    placeholder={"Content Title"}
                    isDisabled={isDisable}
                    textArea={false}
                    value={attributeDataObj.contentTitle}
                    onChange={(e) => {
                      formOnChange(e, "contentTitle");
                    }}
                    maxLength="255"
                  />
                  {formErrors["contentTitle"] && (
                    <p style={{ color: "red" }}>{formErrors["contentTitle"]}</p>
                  )}
                </div>
              </div>

              <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                <div className="dropdown mb-1">
                  <label className="col-form-label">Domains</label>

                  <SelectForm
                    isClearable
                    isSearchable
                    options={domainOption}
                    isOptionDisabled={(option) => !option?.isEnabled}
                    placeholder={"Domains"}
                    isDisabled={false}
                    value={attributeDataObj.domain}
                    onChange={(e) => {
                      formOnChange(e, "domain");
                    }}
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  {formErrors["domain"] && (
                    <p style={{ color: "red" }}>{formErrors["domain"]}</p>
                  )}
                </div>
              </div>

              <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Content Type
                    <sup>*</sup>
                  </label>

                  <SelectForm
                    isClearable
                    isSearchable
                    options={contentTypeOptions}
                    placeholder={"Content Type"}
                    isDisabled={isContentShow}
                    value={attributeDataObj.contentType}
                    onChange={async (e) => {
                      formOnChange(e, "contentType");
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  {formErrors["contentType"] && (
                    <p style={{ color: "red" }}>{formErrors["contentType"]}</p>
                  )}
                </div>
              </div>

              {isUploadFile &&
                attributeDataObj?.contentType?.contentTypeCode ===
                  contentTypeObj?.Scrome && (
                  <>
                    <div className="col-lg-4 col-sm-4 col-xs-4">
                      <div className="mt-2">
                        <label className="col-form-label"></label>
                        <div className="box position-relative">
                          <input
                            id="file"
                            className="form-control inputfile inputfile-6 multiple-inputfile"
                            data-multiple-caption="{count} files selected"
                            multiple={false}
                            type="file"
                            onChange={(e) => {
                              handleFileChangeScromeAndMp4(e);
                            }}
                            accept={".zip"}
                            ref={fileRef}
                            value={""}
                          />

                          <label
                            htmlFor={`file`}
                            className="form-control"
                            style={{ width: 0, border: "none" }}
                          >
                            <strong
                              style={{
                                padding: "6px 16px",
                                backgroundColor: "#3c5464",
                                borderRadius: "5px",
                              }}
                            >
                              <i
                                className="fa fa-upload rotate90"
                                aria-hidden="true"
                              ></i>{" "}
                              Upload
                            </strong>
                          </label>
                        </div>

                        <div className="file-added-list upl_range">
                          <ul className="list-unstyle">
                            <li className="list mt-1">
                              <div className="media">
                                <div className="media-body text-truncate">
                                  <Tooltip title={selectedFile?.name}>
                                    <span className="view-more">
                                      {selectedFile?.name}
                                    </span>
                                  </Tooltip>
                                </div>
                                {selectedFile !== null && (
                                  <p>
                                    <a
                                      onClick={() => {
                                        setSelectedFile(null);
                                        fileRef.current.value = null;
                                      }}
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  </p>
                                )}
                              </div>
                            </li>
                          </ul>
                        </div>

                        {isFileUploaded && (
                          <ProgressBar
                            animated
                            now={progress}
                            label={`${progress}%`}
                            className="mt-1"
                          />
                        )}

                        {formErrors["fileName"] && (
                          <p style={{ color: "red" }}>
                            {formErrors["fileName"]}
                          </p>
                        )}
                      </div>
                    </div>
                    {/* <div className={"col-lg-8 col-sm-8 col-xs-8"}>
                      <div className="row">
                        <div className={"col-lg-6 col-sm-6 col-xs-6"}>
                          <div className="mb-1">
                            <label className="col-form-label">
                              {" "}
                              Documents <sup>*</sup>
                            </label>
                          </div>
                          <InputForm
                            className="form-control"
                            placeholder="upload a file"
                            isDisabled={isDisable}
                            onChange={(e) => {
                              // setfileName(e.target.value);
                            }}
                            textArea={false}
                            value={selectedFile?.name}
                            maxLength="255"
                          />
                          {isFileUploaded && (
                            <ProgressBar
                              animated
                              now={progress}
                              label={`${progress}%`}
                              className="mt-1"
                            />
                          )}

                          {formErrors["fileName"] && (
                            <p style={{ color: "red" }}>
                              {formErrors["fileName"]}
                            </p>
                          )}
                        </div>
                        <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                          <div className="mb-1">
                            <label className="col-form-label mt-3"> </label>
                          </div>
                          <div
                            className="box position-relative"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <>
                              <input
                                id={"REQSUPDOC"}
                                className="form-control inputfile inputfile-6 multiple-inputfile"
                                multiple={false}
                                type="file"
                                onChange={(e) => {
                                  handleFileChangeScrome(e);
                                }}
                                accept={".zip"}
                                disabled={isDisable}
                              />
                              <label
                                htmlFor={"REQSUPDOC"}
                                className="form-control"
                                style={{ width: 0, border: "none" }}
                              >
                                <strong
                                  style={{
                                    padding: "6px 16px",
                                    backgroundColor: "#3c5464",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <i
                                    className="fa fa-upload rotate90"
                                    aria-hidden="true"
                                  ></i>
                                  {"  "}
                                  Upload
                                </strong>{" "}
                              </label>
                            </>

                            {contentUrl && (
                              <Tooltip title="view">
                                <a
                                  className="mr-2 mt-1"
                                  style={{ fontSize: "15px" }}
                                  onClick={(e) => {
                                    e.preventDefault();

                                    setShowDocumentModal(true);
                                  }}
                                >
                                  <i className="fas fa-eye"></i>
                                </a>
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </>
                )}

              {isUploadFile &&
                attributeDataObj?.contentType?.contentTypeCode ===
                  contentTypeObj?.Pdf && (
                  <>
                    <div className="col-lg-4 col-sm-4 col-xs-4">
                      <div className="" style={{ marginTop: "13px" }}>
                        <label className="col-form-label"></label>
                        <div className="box position-relative one_line">
                          <input
                            id="file"
                            className="form-control inputfile inputfile-6 multiple-inputfile"
                            data-multiple-caption="{count} files selected"
                            multiple={false}
                            type="file"
                            onChange={(e) => {
                              handleFileChange(e);
                            }}
                            accept={".pdf"}
                            ref={fileRef}
                            value={""}
                          />

                          <label
                            htmlFor={`file`}
                            className="form-control"
                            style={{ width: 0, border: "none" }}
                          >
                            <strong
                              style={{
                                padding: "6px 16px",
                                backgroundColor: "#3c5464",
                                borderRadius: "5px",
                              }}
                            >
                              <i
                                className="fa fa-upload rotate90"
                                aria-hidden="true"
                              ></i>{" "}
                              Upload
                            </strong>
                          </label>
                          <div className="file-added-list upl_range text_limit">
                            <ul className="list-unstyle">
                              <li className="list mt-1">
                                <div className="media">
                                  <div className="media-body text-truncate">
                                    <span className="view-more">
                                      {selectedFile?.Name}
                                    </span>
                                  </div>
                                  {selectedFile !== null && (
                                    <p>
                                      <a
                                        onClick={() => {
                                          setSelectedFile(null);
                                          fileRef.current.value = null;
                                        }}
                                      >
                                        <i
                                          className="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                    </p>
                                  )}
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div>
                            {contentUrl && (
                              <Tooltip title="view">
                                <a
                                  className=""
                                  style={{ fontSize: "15px" }}
                                  onClick={(e) => {
                                    e.preventDefault();

                                    setShowDocumentModal(true);
                                  }}
                                >
                                  <i className="fas fa-eye "></i>
                                </a>
                              </Tooltip>
                            )}
                          </div>
                        </div>

                        {/* {contentUrl && (
                          <Tooltip title="view">
                            <a
                              className="mr-2 mt-1"
                              style={{ fontSize: "15px" }}
                              onClick={(e) => {
                                e.preventDefault();

                                setShowDocumentModal(true);
                              }}>
                              <i className="fas fa-eye"></i>
                            </a>
                          </Tooltip>
                        )} */}

                        {formErrors["fileName"] && (
                          <p style={{ color: "red" }}>
                            {formErrors["fileName"]}
                          </p>
                        )}
                      </div>
                    </div>
                    {/* <div className={"col-lg-8 col-sm-8 col-xs-8"}>
                      <div className="row">
                        <div className={"col-lg-6 col-sm-6 col-xs-6"}>
                          <div className="mb-1">
                            <label className="col-form-label">
                              {" "}
                              Documents <sup>*</sup>
                            </label>
                          </div>
                          <InputForm
                            className="form-control"
                            placeholder="upload a file"
                            isDisabled={isDisable}
                            onChange={(e) => {
                              // setfileName(e.target.value);
                            }}
                            textArea={false}
                            value={selectedFile?.Name}
                            maxLength="255"
                          />
                          {formErrors["fileName"] && (
                            <p style={{ color: "red" }}>
                              {formErrors["fileName"]}
                            </p>
                          )}
                        </div>
                        <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                          <div className="mb-1">
                            <label className="col-form-label mt-3"> </label>
                          </div>
                          <div className="box position-relative">
                            <input
                              id={"REQSUPDOC"}
                              className="form-control inputfile inputfile-6 multiple-inputfile"
                              multiple={false}
                              type="file"
                              onChange={(e) => {
                                ///handleSupportingDoc(e, "REQSUPDOC");
                                handleFileChange(e);
                              }}
                              accept={".pdf"}
                              disabled={isDisable}
                            />
                            <label
                              htmlFor={"REQSUPDOC"}
                              className="form-control"
                              style={{ width: 0, border: "none" }}
                            >
                              <strong
                                style={{
                                  padding: "6px 16px",
                                  backgroundColor: "#3c5464",
                                  borderRadius: "5px",
                                }}
                              >
                                <i
                                  className="fa fa-upload rotate90"
                                  aria-hidden="true"
                                ></i>
                                {"  "}
                                Upload
                              </strong>{" "}
                            </label>
                          </div>
                        </div>
                        <div className={"col-lg-2 col-sm-2 col-xs-2"}>
                          <div className="mb-1">
                            <label className="col-form-label mt-3"> </label>
                          </div>
                          {contentUrl && (
                            <Tooltip title="view">
                              <a
                                className="mr-2"
                                style={{ fontSize: "15px" }}
                                onClick={(e) => {
                                  e.preventDefault();

                                  setShowDocumentModal(true);
                                }}
                              >
                                <i className="fas fa-eye"></i>
                              </a>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div> */}
                  </>
                )}

              {isUploadFile &&
                attributeDataObj?.contentType?.contentTypeCode ===
                  contentTypeObj?.Audio && (
                  <>
                    <div className="col-lg-4 col-sm-4 col-xs-4">
                      <div className="mt-2">
                        <label className="col-form-label"></label>
                        <div className="box position-relative">
                          <input
                            id="file"
                            className="form-control inputfile inputfile-6 multiple-inputfile"
                            data-multiple-caption="{count} files selected"
                            multiple={false}
                            type="file"
                            onChange={(e) => {
                              handleFileChange(e);
                            }}
                            accept={".mp3"}
                            ref={fileRef}
                            value={""}
                          />

                          <label
                            htmlFor={`file`}
                            className="form-control"
                            style={{ width: 0, border: "none" }}
                          >
                            <strong
                              style={{
                                padding: "6px 16px",
                                backgroundColor: "#3c5464",
                                borderRadius: "5px",
                              }}
                            >
                              <i
                                className="fa fa-upload rotate90"
                                aria-hidden="true"
                              ></i>{" "}
                              Upload
                            </strong>
                          </label>
                        </div>

                        <div className="file-added-list upl_range ">
                          <ul className="list-unstyle">
                            <li className="list mt-1">
                              <div className="media">
                                <div className="media-body text-truncate">
                                  <span className="view-more">
                                    {selectedFile?.Name}
                                  </span>
                                </div>
                                {selectedFile !== null && (
                                  <p>
                                    <a
                                      onClick={() => {
                                        setSelectedFile(null);
                                        fileRef.current.value = null;
                                      }}
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  </p>
                                )}
                              </div>
                            </li>
                          </ul>
                        </div>

                        {/* {contentUrl && (
                          <Tooltip title="view">
                            <a
                              className="mr-2 mt-1"
                              style={{ fontSize: "15px" }}
                              onClick={(e) => {
                                e.preventDefault();

                                setShowDocumentModal(true);
                              }}>
                              <i className="fas fa-eye"></i>
                            </a>
                          </Tooltip>
                        )} */}

                        {formErrors["fileName"] && (
                          <p style={{ color: "red" }}>
                            {formErrors["fileName"]}
                          </p>
                        )}
                      </div>
                    </div>
                    {/* <div className={"col-lg-8 col-sm-8 col-xs-8"}>
                      <div className="row">
                        <div className={"col-lg-6 col-sm-6 col-xs-6"}>
                          <div className="mb-1">
                            <label className="col-form-label">
                              {" "}
                              Documents <sup>*</sup>
                            </label>
                          </div>
                          <InputForm
                            className="form-control"
                            placeholder="upload a file"
                            isDisabled={isDisable}
                            onChange={(e) => {
                              // setfileName(e.target.value);
                            }}
                            textArea={false}
                            value={selectedFile?.Name}
                            maxLength="255"
                          />
                          {formErrors["fileName"] && (
                            <p style={{ color: "red" }}>
                              {formErrors["fileName"]}
                            </p>
                          )}
                        </div>
                        <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                          <div className="mb-1">
                            <label className="col-form-label mt-3"> </label>
                          </div>
                          <div className="box position-relative">
                            <input
                              id={"REQSUPDOC"}
                              className="form-control inputfile inputfile-6 multiple-inputfile"
                              multiple={false}
                              type="file"
                              onChange={(e) => {
                                ///handleSupportingDoc(e, "REQSUPDOC");
                                handleFileChange(e);
                              }}
                              accept={".mp3"}
                              disabled={isDisable}
                            />
                            <label
                              htmlFor={"REQSUPDOC"}
                              className="form-control"
                              style={{ width: 0, border: "none" }}
                            >
                              <strong
                                style={{
                                  padding: "6px 16px",
                                  backgroundColor: "#3c5464",
                                  borderRadius: "5px",
                                }}
                              >
                                <i
                                  className="fa fa-upload rotate90"
                                  aria-hidden="true"
                                ></i>
                                {"  "}
                                Upload
                              </strong>{" "}
                            </label>
                          </div>
                        </div>
                        <div className={"col-lg-2 col-sm-2 col-xs-2"}>
                          <div className="mb-1">
                            <label className="col-form-label mt-3"> </label>
                          </div>
                          {contentUrl && (
                            <Tooltip title="view">
                              <a
                                className="mr-2"
                                style={{ fontSize: "15px" }}
                                onClick={(e) => {
                                  e.preventDefault();

                                  setShowDocumentModal(true);
                                }}
                              >
                                <i className="fas fa-eye"></i>
                              </a>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div> */}
                  </>
                )}

              {!isUploadFile && attributeDataObj.contentType && (
                <>
                  <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                    <div className="mb-1">
                      <label className="col-form-label">
                        URL
                        <sup>*</sup>
                      </label>

                      <InputForm
                        className="form-control"
                        placeholder={"URL"}
                        isDisabled={isDisable}
                        textArea={false}
                        value={attributeDataObj.link}
                        onChange={(e) => {
                          formOnChange(e, "link");
                        }}
                        maxLength="255"
                      />
                      {formErrors["link"] && (
                        <p style={{ color: "red" }}>{formErrors["link"]}</p>
                      )}
                    </div>
                    <div>
                      {contentUrl && (
                        <Tooltip title="view">
                          <a
                            className=""
                            style={{ fontSize: "15px" }}
                            onClick={(e) => {
                              e.preventDefault();

                              setShowDocumentModal(true);
                            }}
                          >
                            <i className="fas fa-eye "></i>
                          </a>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </>
              )}

              {isUploadFile &&
                attributeDataObj?.contentType?.contentTypeCode ===
                  contentTypeObj?.MP4 && (
                  <>
                    <div className="col-lg-4 col-sm-4 col-xs-4">
                      <div className="mt-2">
                        <label className="col-form-label"></label>
                        <div className="box position-relative">
                          <input
                            id="file"
                            className="form-control inputfile inputfile-6 multiple-inputfile"
                            data-multiple-caption="{count} files selected"
                            multiple={false}
                            type="file"
                            onChange={(e) => {
                              handleFileChangeScromeAndMp4(e);
                            }}
                            accept={".mp4"}
                            ref={fileRef}
                            value={""}
                          />

                          <label
                            htmlFor={`file`}
                            className="form-control"
                            style={{ width: 0, border: "none" }}
                          >
                            <strong
                              style={{
                                padding: "6px 16px",
                                backgroundColor: "#3c5464",
                                borderRadius: "5px",
                              }}
                            >
                              <i
                                className="fa fa-upload rotate90"
                                aria-hidden="true"
                              ></i>{" "}
                              Upload
                            </strong>
                          </label>
                        </div>

                        <div className="file-added-list upl_range">
                          <ul className="list-unstyle">
                            <li className="list mt-1">
                              <div className="media">
                                <div className="media-body text-truncate">
                                  <span className="view-more">
                                    {selectedFile?.Name}
                                  </span>
                                </div>
                                {selectedFile !== null && (
                                  <p>
                                    <a
                                      onClick={() => {
                                        setSelectedFile(null);
                                        fileRef.current.value = null;
                                      }}
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  </p>
                                )}
                                {contentUrl && (
                                  <Tooltip title="view">
                                    <a
                                      className=""
                                      style={{ fontSize: "15px" }}
                                      onClick={(e) => {
                                        e.preventDefault();

                                        setShowDocumentModal(true);
                                      }}
                                    >
                                      <i className="fas fa-eye "></i>
                                    </a>
                                  </Tooltip>
                                )}
                              </div>
                            </li>
                          </ul>
                        </div>

                        {isFileUploaded && (
                          <ProgressBar
                            animated
                            now={progress}
                            label={`${progress}%`}
                            className="mt-1"
                          />
                        )}

                        {formErrors["fileName"] && (
                          <p style={{ color: "red" }}>
                            {formErrors["fileName"]}
                          </p>
                        )}
                      </div>
                    </div>
                  </>
                )}

              <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Duration {isDurationMandatory && <sup>*</sup>}
                  </label>
                  <div className="row">
                    <div className={"col-lg-3 col-sm-4 col-xs-4"}>
                      <InputForm
                        className="form-control form-inline"
                        placeholder={"hh"}
                        isDisabled={isDisable}
                        textArea={false}
                        value={attributeDataObj.durationInHours}
                        maxLength={3}
                        onChange={(e) => {
                          formOnChange(e, "durationInHours");
                        }}
                      />
                      {formErrors["durationInHours"] && (
                        <p style={{ color: "red" }}>
                          {formErrors["durationInHours"]}
                        </p>
                      )}
                    </div>
                    <span style={{ fontSize: "20px", fontWeight: "bold" }}>
                      {" : "}
                    </span>

                    <div className={"col-lg-3 col-sm-4 col-xs-4"}>
                      <InputForm
                        className="form-control"
                        placeholder={"mm"}
                        isDisabled={isDisable}
                        textArea={false}
                        maxLength={2}
                        value={attributeDataObj.durationInMinutes}
                        onChange={(e) => {
                          formOnChange(e, "durationInMinutes");
                        }}
                      />
                      {formErrors["durationInMinutes"] && (
                        <p style={{ color: "red" }}>
                          {formErrors["durationInMinutes"]}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-lg-4 col-sm-4 col-xs-4 mar_tp">
                
              </div> */}
            </div>
            <br />
            {!isDisable && (
              <div className="SectionSubmit mb-4 clearfix">
                <button
                  onClick={() => {
                    resetFunction();
                  }}
                  disabled={false}
                  className="btn btn-secondary float-right ml-2"
                >
                  <i className="fa-solid fa-refresh"></i> Reset
                </button>

                <button
                  onClick={() => {
                    handleSubmitButton();
                  }}
                  disabled={false}
                  className="btn btn-filter-submit float-right ml-2"
                >
                  <i className="fa-solid fa-check"></i> Save
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        show={showProfileModal}
        onHide={() => setShowProfileModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">Upload Profile Picture!</div>
        </div>
        <Modal.Body>
          <>
            <input
              type="file"
              multiple={false}
              accept={".png,.jpg,.jpeg"}
              onChange={handleImageSelect}
            />
            <div>
              <b>Acceptable image formats:</b> .png, .jpg and .jpeg{" "}
            </div>
            <div>
              {" "}
              <b>Maximum File Size:</b> 5MB{" "}
            </div>
            <div>
              <b>Resolution:</b> 3245 X 3245{" "}
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          {profileImg != null && (
            <>
              {state != null && state.contentId != 0 ? (
                // Add any additional JSX you want to render when state is not null and state.id is not 0
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: 5 }}
                  onClick={() => {
                    deleteProfileImage(state.id);
                  }}
                >
                  Remove
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: 5 }}
                  onClick={() => {
                    setShowProfileModal(false);
                    setProfileImg(null);
                    setProfileImgToUpload(null);
                  }}
                >
                  Remove
                </button>
              )}
            </>
          )}

          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => setShowProfileModal(false)}
          >
            Upload
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowProfileModal(false);
              setProfileImgToUpload(null);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      {/* Document Model*/}
      <Modal
        show={showDocumentModal}
        onHide={() => setShowDocumentModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          {/* <div className="col-lg-12">{`${modalTitle}`}</div> */}
        </div>
        <Modal.Body>
          <>
            <div className="row">
              <div className={"col-lg-12 text-center"}>
                {contentName === contentObj?.Scrome && (
                  <div className="cont_img">
                    <iframe
                      src={process.env.PUBLIC_URL + contentUrl}
                      width="100%"
                      // height={resize ? "120vh" : "auto"}
                      style={{ height: "100vh" }}
                    ></iframe>
                  </div>
                )}

                {contentName === contentObj?.Pdf && (
                  <div className="pdf">
                    <PdfViewer contentUrl={contentUrl} />
                  </div>
                )}

                {contentName === contentObj?.Audio && (
                  <>
                    {profileImg ? (
                      <div className="audio_img">
                        <img src={profileImg} />
                      </div>
                    ) : (
                      <div className="audio_img">
                        <img src={require("./audi_def.jpg")} />
                      </div>
                    )}

                    <audio
                      // controls="controls"
                      // autobuffer="autobuffer"
                      // autoplay="autoplay"

                      controls
                    >
                      <source src={`data:audio/wav;base64,${contentUrl}`} />
                    </audio>
                  </>
                )}

                {contentName === contentObj?.Link && (
                  <div>
                    <iframe
                      src={`${contentUrl}`}
                      width="100%"
                      height="auto"
                      style={{ height: "100vh" }}
                    ></iframe>
                  </div>
                )}

                {contentName === contentObj?.VideoLink && (
                  <div className="player-wrapper">
                    <ReactPlayer
                      className="react-player"
                      url={contentUrl}
                      controls
                      // playbackRate={2}
                      config={{
                        youtube: {
                          playerVars: { showinfo: 1 },
                        },
                      }}
                      width="866px"
                      height="504px"
                      onDuration={(e) => console.log("Duration", e)}
                      onProgress={(e) => console.log("Progress", e)}
                    />
                  </div>
                )}

                {contentName === contentObj?.MP4 && (
                  <div className="player-wrapper">
                    <ReactPlayer
                      className="react-player"
                      url={contentUrl}
                      controls
                      config={{
                        vimeo: {
                          playerOptions: {
                            vimeo_logo: false,
                            dnt: true,
                            title: false,
                            byline: false,
                            portrait: false,
                            autoplay: false,
                            badge: false,
                            share: false,
                            like: false,
                            watchlater: false,
                          },
                        },
                      }}
                      width="866px"
                      height="504px"
                      onDuration={(e) => console.log("Duration", e)}
                      onProgress={(e) => console.log("Progress", e)}
                    />
                  </div>
                )}

                {/* {contentName === contentObj?.Link && ( */}
                {/* <div>
              <a
                href="https://fontawesome.com/v4/icon/eye"
                // target="_blank"
                rel="noopener noreferrer"
              ></a>
            </div> */}
                {/* )} */}
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          {/* <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => activateandDeactivateFunction(id, IsActive)}
          >
            Submit
          </button> */}
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowDocumentModal(false);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      {/* Notification */}

      <Modal
        show={notification}
        onHide={() => setNotification(false)}
        backdrop="static"
        keyboard={false}
        size="xl"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">
            Notification and mail will be sent to the following users
          </div>
        </div>
        <Modal.Body>
          <>
            <div className="row mx-auto ">
              <div className="col-lg-6 col-sm-6 col-xs-6">
                <div className="mb-1">
                  <label className="col-form-label">
                    To <sup>*</sup>
                  </label>
                  <InputForm
                    value={toEmail}
                    placeholder={"Enter To Email"}
                    isDisabled={true}
                    textArea={false}
                    onChange={(e) => {
                      setToEmail(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="col-lg-6 col-sm-6 col-xs-6">
                <div className="dropdown mb-1">
                  <label className="col-form-label">CC</label>
                  <SelectForm
                    isClearable
                    async
                    placeholder={"Search CC User"}
                    isDisabled={false}
                    options={(searchString, cb) => {
                      handleSearchForAsyncDropdown(
                        searchString,
                        "co_ordinator",
                        cb
                      );
                    }}
                    onChange={(val: any) => {
                      setCCuser(val);
                    }}
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                    value={ccuser}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 col-xs-6">
                <div className="mb-1">
                  <label className="col-form-label">
                    Effective Date <sup>*</sup>
                  </label>
                  <DateTimeForm
                    value={effectiveDate}
                    placeholder={"Effective Date"}
                    onChange={(event) => {
                      setEffectiveDate(event);
                    }}
                    isDisabled={true}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 col-xs-6">
                <div className="mb-1">
                  <label className="col-form-label">Remarks</label>
                  <InputForm
                    value={remarks}
                    placeholder={"Enter the remarks"}
                    isDisabled={false}
                    textArea={true}
                    onChange={(e) => {
                      setRemarks(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => {
              InsertUpdateDataOnNotification();
            }}
          >
            Execute
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setNotification(false);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Contentedit;
