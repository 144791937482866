import { Table } from "react-bootstrap";

const PreviousConversionTable = ({ data }) => {
  return (
    <>
      <h5>Previous Conversation</h5>
      <div className="mb-1 ">
        <Table striped bordered hover table-fixed>
          <thead>
            <tr>
              <th className="text-dark col-lg-2 col-md-2 col-sm-2 mobile-view top-m">
                Date
              </th>
              <th className="text-dark col-lg-2 col-md-2 col-sm-2 mobile-view top-m">
                Approver
              </th>
              <th className="text-dark col-lg-2 col-md-2 col-sm-2 mobile-view top-m">
                Role
              </th>
              <th className="text-dark col-lg-2 col-md-4 col-sm-6 mobile-view top-m">
                Decision
              </th>
              <th className="text-dark col-lg-6 col-md-6 col-sm-6 mobile-view top-m">
                Remarks
              </th>
            </tr>
          </thead>

          <>
            <tbody>
              {data?.map((item, index) => (
                <tr>
                  <td>{item?.Date || ""}</td>
                  <td>{item?.ApproverName || ""}</td>
                  <td>{item?.RoleName || ""}</td>
                  <td>{item?.Decision || ""}</td>
                  <td>{item?.Remarks || ""}</td>
                </tr>
              ))}
            </tbody>
          </>
        </Table>
      </div>
    </>
  );
};

export default PreviousConversionTable;
