import React, { useContext, useEffect, useState } from "react";
import { LoaderContext } from "../../Helpers/Context/Context";
import { useLocation, useNavigate } from "react-router-dom";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import SelectForm from "../../Components/SelectForm/SelectForm";
import InputForm from "../../Components/InputForm/InputForm";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { APICall } from "../../Helpers/APICalls";
import {
  getAssessmentDashboardDropdownList,
  getAssessmentDropdownList,
  getLMSDomainDropDownData,
  getManageAssessmentQuestionMaster,
  saveManageAssessmentExistingQuestion,
  saveManageAssessmentQuestionMaster,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import uuid from "react-uuid";
import { Tooltip } from "@mui/material";
import notify from "../../Helpers/ToastNotification";

interface IOptions {
  label?: string;
  value?: number | string;
  code?: string;
}

interface IFormData {
  question?: string | any;
  questionType?: IOptions | any;
  domain?: IOptions[] | string | any;
  marks?: Number | any;
}

const Questions = ({
  showModal,
  setShowModal,
  formOnChangeForQuestion,
  questionType,
  setQuestionType,
  questionId,
  setQuestionId,
  questionTypechange,
}) => {
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isDisable, setIsDisable] = useState(
    state && state?.isDisable ? true : false
  );
  let { userDetails, currentRoleId }: any = useUserContext();
  const [formData, setFormData] = useState<IFormData>({});
  const [formErrors, setFormErrors] = useState<IFormData>({});
  const [formOptions, setFormOptions] = useState<IFormData>({});
  const [matchValuesFormErrors, setMatchValuesFormErrors] = useState({});
  const [matchValuesGridData, setMatchValuesGridData] = useState([]);

  const [answerErrors, setAnswerErrors] = useState<any>({});

  const [questions, setQuestions] = useState(null);
  const [questionsOptions, setQuestionsOptions] = useState([]);

  const [answer, setAnswer] = useState("");
  const [correctAnswer, setCorrectAnswer] = useState(false);

  const [gridData, setGridData] = useState<any>([]);

  const [count, setCount] = useState(0);
  const [matchvaluesCount, setMatchvaluesCount] = useState(0);

  const [currentGridId, setCurrentGridId] = useState(null);
  const [deleteGridId, setDeleteGridId] = useState(null);

  const [isSingleSelection, setIsSingleSelection] = useState(false);
  const [isCorrectAnswer, setIsCorrectAnswer] = useState(false);
  const [sortAnswer, setSortAnswer] = useState("");
  const [isSkippable, setIsSkippable] = useState(false);

  const [leftSideValues, setLeftSideValues] = useState("");
  const [rightSideValues, setRightSideValues] = useState("");

  const questionTypeObj = {
    MultipleSelection: "MULTI",
    SingleSelection: "SINGLE",
    TrueandFalse: "BOOL",
    MatchValues: "MATCH",
    ShortAnswer: "SHORT",
  };

  useEffect(() => {
    const responseData = async () => {
      //QUESTION_TYPE
      const res = await APICall(getAssessmentDropdownList, "POST", {
        Mode: "QUESTION_TYPE",
      });

      if (res.data && res.data.length > 0) {
        setFormOptions((prev) => {
          return {
            ...prev,
            questionType: res.data,
          };
        });
      }

      const objQuestions = {
        mode: "QUESTIONS",
        assessmentId: state?.assessmentId,
        userId: userDetails?.Id,
        roleId: currentRoleId?.value,
      };
      const res4 = await APICall(
        getAssessmentDashboardDropdownList,
        "POST",
        objQuestions
      );
      if (res4.data && res4.data.length > 0) {
        setQuestionsOptions(res4.data);
      }
    };

    showLoader();

    responseData();
    getLMSDomainDropDownDataApiCall();

    hideLoader();
  }, []);

  const getLMSDomainDropDownDataApiCall = async () => {
    const response = await APICall(getLMSDomainDropDownData, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      GetAll: state && state?.assessmentId > 0 ? true : false,
    });

    if (response?.status === 0 && response?.data.length > 0) {
      setFormOptions((prev) => {
        return {
          ...prev,
          domain: response?.data,
        };
      });
    }
  };

  const formOnChange = (e, type) => {
    switch (type) {
      case "question":
        setFormData({
          ...formData,
          question: e.target.value,
        });
        break;

      case "questionType":
        setFormData({
          ...formData,
          questionType: e,
        });

        if (e.code === questionTypeObj.MultipleSelection) {
          setIsSingleSelection(false);
        }
        break;

      case "domain":
        setFormData({
          ...formData,
          domain: e,
        });
        break;

      case "marks":
        setFormData({
          ...formData,
          marks: e.target.value,
        });
        break;

      default:
        break;
    }
  };

  const gridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: gridData.length,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        // await setSortColumn(sortColumn);
        // await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        // await setSortColumn(sortColumn);
        // await setSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      //   setPage(page * PageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };
  const gridColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        display: false,
      },
    },

    {
      name: "answer",
      label: "Answer",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "correctAnswer",
      label: "Correct Answer",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "sequence",
      label: "Sequence",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta) => {
          let id = tableMeta.tableData[tableMeta.rowIndex].id;
          let isActive = tableMeta.tableData[tableMeta.rowIndex].IsActive;
          let sequence = tableMeta.tableData[tableMeta.rowIndex].sequence;

          const { rowIndex, tableData } = tableMeta;
          const totalItems = tableData.length;
          const isFirst = rowIndex === 0; // First item has rowIndex 0
          const isLast = rowIndex === totalItems - 1; // Last item has rowIndex totalItems - 1

          const upArrow = isFirst ? (
            <Tooltip title="">
              <button className="btn mr-1" disabled={true}></button>
            </Tooltip>
          ) : (
            <Tooltip title="Move up">
              <button
                className="btn"
                disabled={false}
                onClick={(e) => {
                  e.preventDefault();
                  moveUp(sequence);
                }}>
                <i className="fa fa-caret-up"></i>
              </button>
            </Tooltip>
          );

          const downArrow = isLast ? (
            <Tooltip title="">
              <button className="btn mr-1" disabled={true}></button>
            </Tooltip>
          ) : (
            <Tooltip title="Move down">
              <button
                className="btn"
                disabled={false}
                onClick={(e) => {
                  e.preventDefault();
                  moveDown(sequence);
                }}>
                <i className="fa fa-caret-down"></i>
              </button>
            </Tooltip>
          );

          return (
            <div className="">
              {!isDisable && (
                <>
                  {" "}
                  <>
                    <div className="">
                      {upArrow}
                      {downArrow}
                      <Tooltip title="delete">
                        <a
                          className="ml-2 mr-2 mt-1"
                          onClick={(e) => {
                            e.preventDefault();
                            setDeleteGridId(id);
                          }}>
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </a>
                      </Tooltip>
                    </div>
                  </>
                </>
              )}
            </div>
          );
        },
      },
    },
  ];

  const moveUp = (sequence) => {
    let sequenceNo = 0;

    const answerGridArray = gridData?.map((prev) => {
      if (prev.sequence === sequence) {
        sequenceNo = prev.sequence - 1;
      } else if (prev.sequence === sequence - 1) {
        sequenceNo = prev.sequence + 1;
      } else {
        sequenceNo = prev.sequence;
      }
      return {
        ...prev,
        id: prev.id,
        answer: prev.answer,
        correctAnswer: prev.correctAnswer,
        sequence: sequenceNo,
      };
    });

    const sortAnswerGridArray = answerGridArray.sort(
      (a, b) => a.sequence - b.sequence
    );

    setGridData(sortAnswerGridArray);
  };

  const moveDown = (sequence) => {
    let sequenceNo = 0;

    const answerGridArray = gridData?.map((prev) => {
      if (prev.sequence === sequence) {
        sequenceNo = prev.sequence + 1;
      } else if (prev.sequence === sequence + 1) {
        sequenceNo = prev.sequence - 1;
      } else {
        sequenceNo = prev.sequence;
      }
      return {
        ...prev,
        id: prev.id,
        answer: prev.answer,
        correctAnswer: prev.correctAnswer,
        sequence: sequenceNo,
      };
    });

    const sortAnswerGridArray = answerGridArray.sort(
      (a, b) => a.sequence - b.sequence
    );

    setGridData(sortAnswerGridArray);
  };

  const matchValuesMoveUp = (sequence) => {
    let sequenceNo = 0;

    const answerGridArray = matchValuesGridData?.map((prev) => {
      if (prev.sequence === sequence) {
        sequenceNo = prev.sequence - 1;
      } else if (prev.sequence === sequence - 1) {
        sequenceNo = prev.sequence + 1;
      } else {
        sequenceNo = prev.sequence;
      }
      return {
        ...prev,
        id: prev.id,
        leftSide: prev.leftSide,
        rightSide: prev.rightSide,
        sequence: sequenceNo,
      };
    });

    const sortAnswerGridArray = answerGridArray.sort(
      (a, b) => a.sequence - b.sequence
    );

    setMatchValuesGridData(sortAnswerGridArray);
  };

  const matchValuesMoveDown = (sequence) => {
    let sequenceNo = 0;

    const answerGridArray = matchValuesGridData?.map((prev) => {
      if (prev.sequence === sequence) {
        sequenceNo = prev.sequence + 1;
      } else if (prev.sequence === sequence + 1) {
        sequenceNo = prev.sequence - 1;
      } else {
        sequenceNo = prev.sequence;
      }
      return {
        ...prev,
        id: prev.id,
        leftSide: prev.leftSide,
        rightSide: prev.rightSide,
        sequence: sequenceNo,
      };
    });

    const sortAnswerGridArray = answerGridArray.sort(
      (a, b) => a.sequence - b.sequence
    );

    setMatchValuesGridData(sortAnswerGridArray);
  };

  const matchValuesGridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: matchvaluesCount,
    rowsPerPage: matchvaluesCount,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        // await setSortColumn(sortColumn);
        // await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        // await setSortColumn(sortColumn);
        // await setSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      //   setPage(page * PageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };
  const matchValuesGridColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        display: false,
      },
    },

    {
      name: "leftSide",
      label: "Left Side",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "rightSide",
      label: "Right Side",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "sequence",
      label: "Sequence",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta) => {
          let id = tableMeta.tableData[tableMeta.rowIndex].id;
          let isActive = tableMeta.tableData[tableMeta.rowIndex].IsActive;
          let sequence = tableMeta.tableData[tableMeta.rowIndex].sequence;

          const { rowIndex, tableData } = tableMeta;
          const totalItems = tableData.length;
          const isFirst = rowIndex === 0; // First item has rowIndex 0
          const isLast = rowIndex === totalItems - 1; // Last item has rowIndex totalItems - 1

          const upArrow = isFirst ? (
            <Tooltip title="">
              <button className="btn mr-1" disabled={true}></button>
            </Tooltip>
          ) : (
            <Tooltip title="Move up">
              <button
                className="btn"
                disabled={false}
                onClick={(e) => {
                  e.preventDefault();
                  matchValuesMoveUp(sequence);
                }}>
                <i className="fa fa-caret-up"></i>
              </button>
            </Tooltip>
          );

          const downArrow = isLast ? (
            <Tooltip title="">
              <button className="btn mr-1" disabled={true}></button>
            </Tooltip>
          ) : (
            <Tooltip title="Move down">
              <button
                className="btn"
                disabled={false}
                onClick={(e) => {
                  e.preventDefault();
                  matchValuesMoveDown(sequence);
                }}>
                <i className="fa fa-caret-down"></i>
              </button>
            </Tooltip>
          );

          return (
            <div className="">
              {!isDisable && (
                <>
                  <div className="">
                    {upArrow}
                    {downArrow}
                    <Tooltip title="delete">
                      <a
                        className="ml-2 mr-2 mt-1"
                        onClick={(e) => {
                          e.preventDefault();
                          setDeleteGridId(id);
                        }}>
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </a>
                    </Tooltip>
                  </div>
                </>
              )}
            </div>
          );
        },
      },
    },
  ];

  useEffect(() => {
    if (deleteGridId && currentGridId === null) {
      //to  delete data from grid

      let sequenceNo = 0;

      if (
        formData?.questionType?.code === questionTypeObj.MultipleSelection ||
        formData?.questionType?.code === questionTypeObj.SingleSelection
      ) {
        // setGridData((prev) =>
        //   prev.filter((record) => record.Id !== deleteGridId)
        // );

        const gridDataObj = gridData.find(
          (record) => record.id !== deleteGridId
        );

        const gridDataArray = gridData.filter(
          (record) => record.id !== deleteGridId
        );

        const gridNewDataArray = gridDataArray.map((prev) => {
          if (prev.sequence > gridDataObj.sequence) {
            sequenceNo = prev.sequence - 1;
          } else {
            sequenceNo = prev.sequence;
          }

          return {
            ...prev,
            id: prev.id,
            answer: prev.answer,
            correctAnswer: prev.correctAnswer,
            sequence: sequenceNo,
          };
        });

        setGridData(gridNewDataArray);
      } else if (formData?.questionType?.code === questionTypeObj.MatchValues) {
        // setMatchValuesGridData((prev) =>
        //   prev.filter((record) => record.Id !== deleteGridId)
        // );

        const gridDataObj = matchValuesGridData.find(
          (record) => record.id !== deleteGridId
        );

        const gridDataArray = matchValuesGridData.filter(
          (record) => record.id !== deleteGridId
        );

        const gridNewDataArray = gridDataArray.map((prev) => {
          if (prev.sequence > gridDataObj.sequence) {
            sequenceNo = prev.sequence - 1;
          } else {
            sequenceNo = prev.sequence;
          }

          return {
            ...prev,
            id: prev.id,
            leftSide: prev.leftSide,
            rightSide: prev.rightSide,
            sequence: sequenceNo,
          };
        });

        setMatchValuesGridData(gridNewDataArray);
      }
    }
    setDeleteGridId(null);
  }, [deleteGridId]);

  const matchValuesValidate = () => {
    setMatchValuesFormErrors({});
    let errorObj = {};
    let isError = false;

    if (leftSideValues === null || leftSideValues === "") {
      errorObj = {
        ...errorObj,
        ["leftSideValues"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["leftSideValues"]: "",
      };
    }

    if (rightSideValues === null || rightSideValues === "") {
      errorObj = {
        ...errorObj,
        ["rightSideValues"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["rightSideValues"]: "",
      };
    }

    const isEmpty = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      setMatchValuesFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  const addMatchValuesFunction = () => {
    showLoader();

    if (matchValuesValidate()) {
      hideLoader();
      return;
    }

    if (leftSideValues === rightSideValues) {
      hideLoader();
      notify(1, "Left Side Values and Right Side Values should not be same!");
      return null;
    }

    if (matchValuesGridData.length > 0) {
      const leftMatchFound = matchValuesGridData?.some(
        (val) =>
          val?.leftSide === leftSideValues || val?.leftSide === rightSideValues
      );

      const rightMatchFound = matchValuesGridData?.some(
        (val) =>
          val?.rightSide === leftSideValues ||
          val?.rightSide === rightSideValues
      );
      if (leftMatchFound || rightMatchFound) {
        hideLoader();
        notify(1, "Two values cannot be same");
        return null;
      }
    }

    const uniqueId = uuid();
    let sequenceData = 0;

    if (matchValuesGridData?.length > 0) {
      let maxValue = Math.max.apply(
        null,
        matchValuesGridData.map(function (o) {
          return o.sequence;
        })
      );

      sequenceData = maxValue + 1;
    } else {
      sequenceData = 1;
    }

    let gridFormData = {
      leftSide: leftSideValues,
      rightSide: rightSideValues,
      sequence: sequenceData,
    };

    setMatchValuesGridData((prev) => [
      ...prev,
      {
        id: uniqueId,
        ...gridFormData,
        IsActive: true,
      },
    ]);

    setLeftSideValues("");
    setRightSideValues("");

    hideLoader();
  };

  const answerValidate = () => {
    let errorObj = {};
    let isError = false;

    if (answer === null || answer === "") {
      errorObj = {
        ...errorObj,
        ["answer"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["answer"]: "",
      };
    }

    const isEmpty = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      setAnswerErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  const addAnswerFunction = () => {
    if (
      gridData.length > 0 &&
      formData?.questionType?.code === questionTypeObj?.SingleSelection
    ) {
      const singleAnserData = gridData?.some(
        (val) => val?.correctAnswer === "Yes"
      );
      if (singleAnserData && correctAnswer) {
        notify(1, "Only one answer should be correct!");
        return null;
      }
    }

    showLoader();

    if (answerValidate()) {
      hideLoader();
      return;
    }
    const uniqueId = uuid();
    let correctAnswerData = "";
    let sequenceData = 0;

    if (gridData?.length > 0) {
      let maxValue = Math.max.apply(
        null,
        gridData.map(function (o) {
          return o.sequence;
        })
      );

      sequenceData = maxValue + 1;
    } else {
      sequenceData = 1;
    }
    if (correctAnswer) {
      correctAnswerData = "Yes";
    } else {
      correctAnswerData = "No";
    }
    let gridFormData = {
      answer: answer,
      correctAnswer: correctAnswerData,
      sequence: sequenceData,
    };

    setGridData((prev) => [
      ...prev,
      {
        id: uniqueId,
        ...gridFormData,
        IsActive: true,
      },
    ]);

    if (formData?.questionType?.code === questionTypeObj?.SingleSelection) {
      const SingleSelectionData = gridData?.some(
        (val) => val?.correctAnswer === "Yes"
      );
      if (SingleSelectionData) {
        setIsSingleSelection(true);
      } else if (correctAnswer) {
        setIsSingleSelection(true);
      }
    } else if (
      formData?.questionType?.code === questionTypeObj?.MultipleSelection
    ) {
      setIsSingleSelection(false);
    }
    setCorrectAnswer(false);
    setAnswer("");

    hideLoader();
  };

  const requestParamsFun = () => {
    let answerData = "";

    if (
      formData?.questionType?.code === questionTypeObj.MultipleSelection ||
      formData?.questionType?.code === questionTypeObj.SingleSelection
    ) {
      answerData = JSON.stringify(gridData);
    } else if (formData?.questionType?.code === questionTypeObj.MatchValues) {
      // answerData = JSON.stringify(matchValuesGridData);
      // answer: prev.answer,
      // correctAnswer: prev.correctAnswer,
      // sequence: sequenceNo,

      const newAnswerDataArray = [];

      matchValuesGridData?.map((prev) => {
        const newAnswerObj = {
          answer: `${prev.leftSide}::${prev.rightSide}`,
          correctAnswer: 0,
          sequence: prev.sequence,
        };
        newAnswerDataArray.push(newAnswerObj);
      });

      answerData = JSON.stringify(newAnswerDataArray);
    } else if (formData?.questionType?.code === questionTypeObj.TrueandFalse) {
      const newAnswerDataArray = [
        {
          answer: `True`,
          correctAnswer: isCorrectAnswer ? "Yes" : "No",
          sequence: 1,
        },
        {
          answer: `False`,
          correctAnswer: !isCorrectAnswer ? "Yes" : "No",
          sequence: 2,
        },
      ];

      answerData = JSON.stringify(newAnswerDataArray);
    } else if (formData?.questionType?.code === questionTypeObj.ShortAnswer) {
      answerData = "";
    }

    const requestParams = {
      Id: questionId,
      AssessmentId:
        state === null ||
        state?.assessmentId === null ||
        state?.assessmentId === undefined
          ? 0
          : state?.assessmentId,
      Question: formData?.question,
      QuestionTypeId: formData?.questionType?.value,
      DomainIds: formData?.domain
        ? formData?.domain?.map((j) => j.value).join(",")
        : null,
      Marks: formData?.marks,
      IsSkippable: isSkippable,
      Answers: answerData,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    };

    return requestParams;
  };

  const validateForm = () => {
    let errorObj = {};
    let isError = false;
    setFormErrors({});

    if (
      formData?.question === "" ||
      formData?.question === null ||
      formData?.question === undefined
    ) {
      errorObj = {
        ...errorObj,
        ["question"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["question"]: "",
      };
    }

    if (
      formData?.questionType === null ||
      formData?.questionType === undefined
    ) {
      errorObj = {
        ...errorObj,
        ["questionType"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["questionType"]: "",
      };
    }

    // if (
    //   formData?.domain?.length === 0 ||
    //   formData?.domain === null ||
    //   formData?.domain === undefined
    // ) {
    //   errorObj = {
    //     ...errorObj,
    //     ["domain"]: "Required",
    //   };
    // } else {
    //   errorObj = {
    //     ...errorObj,
    //     ["domain"]: "",
    //   };
    // }

    if (
      formData?.questionType?.code === questionTypeObj.MultipleSelection ||
      formData?.questionType?.code === questionTypeObj.SingleSelection
    ) {
      if (gridData?.length === 0) {
        errorObj = {
          ...errorObj,
          ["questionGrid"]: "Required",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["questionGrid"]: "",
        };
      }
    }

    if (formData?.questionType?.code === questionTypeObj.MatchValues) {
      if (matchValuesGridData?.length === 0) {
        errorObj = {
          ...errorObj,
          ["matchValuesAnswerGrid"]: "Required",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["matchValuesAnswerGrid"]: "",
        };
      }
    }

    const isEmpty = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  const handleSubmitFunction = async () => {
    showLoader();

    if (validateForm()) {
      hideLoader();
      return;
    }

    switch (formData?.questionType?.code) {
      case questionTypeObj?.SingleSelection:
        // check if any existing answer is marked as correct

        const singleAnswerData = gridData?.some(
          (val) => val?.correctAnswer === "Yes"
        );
        if (!singleAnswerData) {
          hideLoader();
          notify(1, "Atleast one answer should be correct!");
          return null;
        }

        break;
      case questionTypeObj?.MultipleSelection:
        // check if any existing answer is not marked as correct

        const multiAnswerData = gridData?.some(
          (val) => val?.correctAnswer === "Yes"
        );
        if (!multiAnswerData) {
          hideLoader();
          notify(1, "Atleast one answer should be correct!");
          return null;
        }

        break;
      default:
        break;
    }

    // const requestParams = requestParamsFun();

    // if (requestParams?.Id === 0) {
    //  await insertUpdateData();
    // } else {
    //   await insertUpdateData();
    // }

    const requestParams = requestParamsFun();

    const response = await APICall(
      saveManageAssessmentQuestionMaster,
      "POST",
      requestParams
    );

    if (response?.status === 0) {
      setShowModal(false);
      notify(response?.status, "Question save sucessfully.");
    } else if (response?.status === 1) {
      notify(response?.status, response?.message);
    }

    hideLoader();
  };

  const saveManageAssessmentExistingQuestionApiCall = async () => {
    showLoader();

    if (questions?.length > 0) {
      const requestParams = {
        AssessmentId: state?.assessmentId,
        QuestionIds: questions?.map((s) => s.value).join(",") || null,
      };

      const response = await APICall(
        saveManageAssessmentExistingQuestion,
        "POST",
        requestParams
      );

      if (response?.status === 0) {
        setShowModal(false);
        notify(response?.status, "Question save sucessfully.");
      } else if (response?.status === 1) {
        notify(response?.status, response?.message);
      }
    } else {
      notify(1, "Please select questions");
    }

    hideLoader();
  };

  useEffect(() => {
    if (
      questionId > 0 &&
      formOptions?.questionType?.length > 0 &&
      formOptions?.domain?.length > 0
    ) {
      getAssessmentQuestionsDataById(questionId);
    }
  }, [questionId, formOptions?.questionType, formOptions?.domain]);

  const getAssessmentQuestionsDataById = async (questionId) => {
    showLoader();
    const requestParams = {
      AssessmentId: state?.assessmentId,
      Id: questionId,
    };

    const responseData = await APICall(
      getManageAssessmentQuestionMaster,
      "POST",
      requestParams
    );

    if (responseData?.status === 0) {
      const questionTypeDataObj = formOptions?.questionType?.find(
        (s) => s.value === parseInt(responseData?.data?.questionTypeId)
      );

      setIsSkippable(responseData?.data?.isSkippable);

      const domainIds = responseData?.data?.domainIds?.split(",") || null;

      const domainArray = [];

      if (domainIds && formOptions?.domain?.length > 0) {
        domainIds?.map((item) => {
          const domainData = formOptions?.domain?.find(
            (i) => i.value === parseInt(item)
          );
          domainArray.push(domainData);
        });
      }

      if (
        responseData?.data?.answers !== null &&
        responseData?.data?.answers !== undefined
      ) {
        if (
          questionTypeDataObj?.code === questionTypeObj?.MultipleSelection ||
          questionTypeDataObj?.code === questionTypeObj.SingleSelection
        ) {
          const answerGridData = JSON.parse(responseData?.data?.answers);

          const answerGridArray = answerGridData?.map((prev) => {
            let correctAnswerValue = "";
            if (prev.correctAnswer === 1) {
              correctAnswerValue = "Yes";
            } else if (prev.correctAnswer === 0) {
              correctAnswerValue = "No";
            }
            return {
              ...prev,
              id: prev.id,
              answer: prev.answer,
              correctAnswer: correctAnswerValue,
              sequence: prev.sequence,
            };
          });

          setGridData(answerGridArray);
        } else if (questionTypeDataObj?.code === questionTypeObj?.MatchValues) {
          const answerGridData = JSON.parse(responseData?.data?.answers);

          const answerGridArray = answerGridData?.map((prev) => {
            let correctAnswerValue = prev.answer.split("::");
            const leftSideData = correctAnswerValue[0];
            const rightSideData = correctAnswerValue[1];

            return {
              ...prev,
              id: prev.id,
              leftSide: leftSideData,
              rightSide: rightSideData,
              sequence: prev.sequence,
            };
          });

          setMatchValuesGridData(answerGridArray);
        } else if (
          questionTypeDataObj?.code === questionTypeObj?.TrueandFalse
        ) {
          const answerGridData = JSON.parse(responseData?.data?.answers);

          if (answerGridData[0]?.correctAnswer === 1) {
            setIsCorrectAnswer(true);
          } else {
            setIsCorrectAnswer(false);
          }
        } else if (questionTypeDataObj?.code === questionTypeObj?.ShortAnswer) {
        }
      }

      setFormData((env) => {
        return {
          ...env,
          question: responseData?.data?.question,
          questionType: questionTypeDataObj,
          domain: domainArray.length > 0 ? domainArray : null,
          marks: responseData?.data?.marks,
        };
      });
    } else if (responseData?.status === 1) {
      notify(responseData?.status, responseData?.message);
    }

    hideLoader();
  };

  const handleResetFunction = () => {
    showLoader();
    setFormData((env) => {
      return {
        ...env,
        question: "",
        questionType: null,
        domain: null,
        marks: "",
      };
    });

    setGridData([]);
    setMatchValuesGridData([]);
    hideLoader();
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className={"col-lg-4 col-sm-4 col-xs-4"}>
            <div className="mb-1">
              <div>
                <input
                  className="ml-2 mr-1"
                  disabled={false || isDisable}
                  id={`newQuestion`}
                  type="radio"
                  name="newQuestion"
                  onChange={(e) => {
                    handleResetFunction();
                    formOnChangeForQuestion("newQuestion");
                  }}
                  checked={questionType === true}
                />
                <label htmlFor={`newQuestion`}>New</label>
                <input
                  className="ml-2 mr-1"
                  disabled={false || isDisable}
                  id={`existingQuestion`}
                  name="existingQuestion"
                  type="radio"
                  onChange={(e) => {
                    formOnChangeForQuestion("existingQuestion");
                  }}
                  checked={questionType === false}
                />
                <label htmlFor={`existingQuestion`}>Existing</label>
              </div>
            </div>
          </div>
        </div>
        {!questionType && (
          <>
            <div className="row">
              <div className={"col-lg-6 col-sm-6 col-xs-6"}>
                <div className="dropdown mb-1">
                  <label className="col-form-label">Questions</label>{" "}
                  <sup>*</sup>
                  <SelectForm
                    isClearable
                    isSearchable
                    options={questionsOptions}
                    placeholder={"Questions"}
                    isDisabled={isDisable}
                    value={questions}
                    onChange={(e) => {
                      setQuestions(e);
                    }}
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
              </div>
            </div>
            <div className="SectionSubmit mb-4 clearfix">
              {!isDisable && (
                <>
                  <button
                    onClick={() => {
                      saveManageAssessmentExistingQuestionApiCall();
                    }}
                    disabled={false}
                    className="btn btn-filter-submit float-right ml-2">
                    <i className="fa-solid fa-check"></i> Save
                  </button>

                  <button
                    onClick={() => {
                      setQuestions(null);
                    }}
                    disabled={false}
                    className="btn btn-secondary float-right ml-2">
                    <i className="fa-solid fa-refresh"></i> Reset
                  </button>
                </>
              )}
            </div>
          </>
        )}

        {questionType && (
          <>
            <div className="row">
              <div className={"col-lg-6 col-sm-4 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">Question</label>{" "}
                  <sup>*</sup>
                  <InputForm
                    className="form-control"
                    placeholder={"Question"}
                    isDisabled={isDisable}
                    textArea={false}
                    value={formData?.question}
                    onChange={(e) => {
                      formOnChange(e, "question");
                    }}
                    maxLength="250"
                  />
                  {formErrors["question"] && (
                    <p style={{ color: "red" }}>{formErrors["question"]}</p>
                  )}
                </div>
              </div>
              <div className={"col-lg-6 col-sm-4 col-xs-4"}>
                <div className="dropdown mb-1">
                  <label className="col-form-label">Questions Type</label>{" "}
                  <sup>*</sup>
                  <SelectForm
                    isClearable
                    isSearchable
                    options={formOptions?.questionType}
                    placeholder={"Questions Type"}
                    isDisabled={isDisable}
                    value={formData?.questionType}
                    onChange={(e) => {
                      formOnChange(e, "questionType");
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  {formErrors["questionType"] && (
                    <p style={{ color: "red" }}>{formErrors["questionType"]}</p>
                  )}
                </div>
              </div>
              {/* <div className={"col-lg-6 col-sm-4 col-xs-4"}>
                <div className="dropdown mb-1">
                  <label className="col-form-label">Domains</label>
                  <SelectForm
                    isClearable
                    isSearchable
                    options={formOptions?.domain}
                    placeholder={"Domains"}
                    isDisabled={isDisable}
                    value={formData?.domain}
                    onChange={(e) => {
                      formOnChange(e, "domain");
                    }}
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  {formErrors["domain"] && (
                    <p style={{ color: "red" }}>{formErrors["domain"]}</p>
                  )}
                </div>
              </div> */}
              <div className={"col-lg-6 col-sm-4 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">Marks</label>

                  <InputForm
                    className="form-control"
                    placeholder={"Marks"}
                    isDisabled={isDisable}
                    textArea={false}
                    value={formData?.marks}
                    onChange={(e) => {
                      formOnChange(e, "marks");
                    }}
                    maxLength="250"
                  />
                  {formErrors["marks"] && (
                    <p style={{ color: "red" }}>{formErrors["marks"]}</p>
                  )}
                </div>
              </div>
              <div className={"col-lg-6 col-sm-6 col-xs-6"}>
                <div className="mb-1">
                  <label className="col-form-label">Skippable</label>

                  <div>
                    <input
                      className="ml-2 mr-1"
                      disabled={isDisable}
                      type="radio"
                      onChange={(e) => {
                        setIsSkippable(true);
                      }}
                      checked={isSkippable === true}
                    />
                    <label htmlFor={`True`}>Yes</label>
                    <input
                      className="ml-2 mr-1"
                      disabled={isDisable}
                      type="radio"
                      onChange={(e) => {
                        setIsSkippable(false);
                      }}
                      checked={isSkippable === false}
                    />
                    <label htmlFor={`False`}>No</label>
                  </div>
                </div>
              </div>
            </div>
            {(formData?.questionType?.code ===
              questionTypeObj.MultipleSelection ||
              formData?.questionType?.code ===
                questionTypeObj.SingleSelection) && (
              <>
                {" "}
                <div className="row">
                  <div className={"col-lg-6 col-sm-4 col-xs-4"}>
                    <div className="mb-1">
                      <label className="col-form-label">Answer</label>{" "}
                      <sup>*</sup>
                      <InputForm
                        className="form-control"
                        placeholder={"Answer"}
                        isDisabled={isDisable}
                        textArea={true}
                        value={answer}
                        onChange={(e) => {
                          setAnswer(e.target.value);
                        }}
                        maxLength="250"
                      />
                      {answerErrors["answer"] && (
                        <p style={{ color: "red" }}>{answerErrors["answer"]}</p>
                      )}
                    </div>
                  </div>
                  <div className={"col-lg-6 col-sm-4 col-xs-4"}>
                    <div className="row">
                      <div className={"col-lg-6 col-sm-4 col-xs-4"}>
                        <div className="mb-1">
                          <label className="col-form-label">
                            Correct Answer
                          </label>{" "}
                          <sup>*</sup>
                          <input
                            className="d-flex"
                            disabled={isSingleSelection || isDisable}
                            type="checkbox"
                            onChange={(e) => {
                              e.stopPropagation();
                              setCorrectAnswer(e.target.checked);
                            }}
                            checked={correctAnswer}
                          />
                          {answerErrors["correctAnswer"] && (
                            <p style={{ color: "red" }}>
                              {answerErrors["correctAnswer"]}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                        <label className="col-form-label">&nbsp;</label>
                        {!isDisable && (
                          <>
                            <div>
                              <button
                                className="btn btn-filter-submit float-right ml-2"
                                onClick={() => {
                                  addAnswerFunction();
                                }}>
                                <i className="fa fa-plus mr-2"></i>
                                Add Answer
                              </button>
                            </div>
                          </>
                        )}

                        <span className="" style={{ color: "red" }}></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-3 pt-3">
                  <DynamicGrid
                    data={gridData}
                    columns={gridColumns}
                    options={gridOptions}
                  />
                  {formErrors["questionGrid"] && (
                    <p className="mb-3 pt-3" style={{ color: "red" }}>
                      {formErrors["questionGrid"]}
                    </p>
                  )}
                </div>
              </>
            )}

            {formData?.questionType?.code === questionTypeObj.TrueandFalse && (
              <>
                <div className="row">
                  <div className={"col-lg-6 col-sm-6 col-xs-6"}>
                    <div className="mb-1">
                      <label className="col-form-label">Correct Answer</label>

                      <div>
                        <input
                          className="ml-2 mr-1"
                          disabled={isDisable}
                          type="radio"
                          onChange={(e) => {
                            setIsCorrectAnswer(true);
                          }}
                          checked={isCorrectAnswer === true}
                        />
                        <label htmlFor={`True`}>True</label>
                        <input
                          className="ml-2 mr-1"
                          disabled={isDisable}
                          type="radio"
                          onChange={(e) => {
                            setIsCorrectAnswer(false);
                          }}
                          checked={isCorrectAnswer === false}
                        />
                        <label htmlFor={`False`}>False</label>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* {formData?.questionType?.code === questionTypeObj.ShortAnswer && (
              <>
                <div className="row">
                  <div className={"col-lg-6 col-sm-6 col-xs-6"}>
                    <div className="mb-1">
                      <label className="col-form-label">Sort Answer</label>

                      <InputForm
                        className="form-control"
                        placeholder={"Sort Answer"}
                        isDisabled={isDisable}
                        textArea={false}
                        value={sortAnswer}
                        onChange={(e) => {
                          setSortAnswer(e.target.value);
                        }}
                        maxLength="255"
                      />
                    </div>
                  </div>
                </div>
              </>
            )} */}

            {formData?.questionType?.code === questionTypeObj.MatchValues && (
              <>
                <div className="row">
                  <div className={"col-lg-6 col-sm-6 col-xs-6"}>
                    <div className="mb-1">
                      <label className="col-form-label">Left Side Values</label>{" "}
                      <sup>*</sup>
                      <InputForm
                        className="form-control"
                        placeholder={"Left Side Values"}
                        isDisabled={isDisable}
                        textArea={false}
                        value={leftSideValues}
                        onChange={(e) => {
                          setLeftSideValues(e.target.value);
                        }}
                        maxLength="255"
                      />
                      {matchValuesFormErrors["leftSideValues"] && (
                        <p style={{ color: "red" }}>
                          {matchValuesFormErrors["leftSideValues"]}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className={"col-lg-6 col-sm-6 col-xs-6"}>
                    <div className="mb-1">
                      <label className="col-form-label">
                        Right Side Values
                      </label>{" "}
                      <sup>*</sup>
                      <InputForm
                        className="form-control"
                        placeholder={"Right Side Values"}
                        isDisabled={isDisable}
                        textArea={false}
                        value={rightSideValues}
                        onChange={(e) => {
                          setRightSideValues(e.target.value);
                        }}
                        maxLength="255"
                      />
                      {matchValuesFormErrors["rightSideValues"] && (
                        <p style={{ color: "red" }}>
                          {matchValuesFormErrors["rightSideValues"]}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
                    <label className="col-form-label">&nbsp;</label>
                    {!isDisable && (
                      <>
                        <div>
                          <button
                            className="btn btn-filter-submit float-right ml-2"
                            onClick={() => {
                              addMatchValuesFunction();
                            }}>
                            <i className="fa fa-plus mr-2"></i>
                            Add Answer
                          </button>
                        </div>
                      </>
                    )}

                    <span className="" style={{ color: "red" }}></span>
                  </div>
                </div>
                <div className="mb-3 pt-3">
                  <DynamicGrid
                    data={matchValuesGridData}
                    columns={matchValuesGridColumns}
                    options={matchValuesGridOptions}
                  />
                  {formErrors["matchValuesAnswerGrid"] && (
                    <p className="mb-3 pt-3" style={{ color: "red" }}>
                      {formErrors["matchValuesAnswerGrid"]}
                    </p>
                  )}
                </div>
              </>
            )}

            <br />
            <div className="SectionSubmit mb-4 clearfix">
              {!isDisable && (
                <>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmitFunction();
                    }}
                    disabled={false}
                    className="btn btn-filter-submit float-right ml-2">
                    <i className="fa-solid fa-check"></i> Save
                  </button>

                  <button
                    onClick={() => {
                      handleResetFunction();
                    }}
                    disabled={false}
                    className="btn btn-secondary float-right ml-2">
                    <i className="fa-solid fa-refresh"></i> Reset
                  </button>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Questions;
