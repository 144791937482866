import React, { useState, useEffect, useContext, useRef } from "react";
import InputForm from "../../../Components/InputForm/InputForm";
import SelectForm from "../../../Components/SelectForm/SelectForm";
import { useLocation } from "react-router-dom";
import useUserContext from "../../../Helpers/ZustandStore/UserContextStore";
import { LoaderContext } from "../../../Helpers/Context/Context";
import {
  getDropdowns,
  GetActivityOptions,
  getCompetencyCategoryData,
  GetEvaluatorsFeedbackForRecruiter,
} from "../../../Helpers/APIEndPoints/EndPoints";
import { APICall } from "../../../Helpers/APICalls";
import { Modal, Table } from "react-bootstrap";
import uuid from "react-uuid";
import DynamicGrid from "../../../Components/DynamicGrid/DynamicGrid";
import { useReactToPrint } from "react-to-print";

const FeedbackFormView = ({
  candidateId,
  candidateName,
  stageId,
  mrfId,
  evaluators,
  modal,
  setModal,
}) => {
  let { userDetails, currentRoleId }: any = useUserContext();
  const { state } = useLocation();
  const { showLoader, hideLoader } = useContext(LoaderContext);

  const [mainArr, setMainArr] = useState([]);
  const [activityOptions, setActivityOptions] = useState([]);
  const [activity, setActivity] = useState<any>(null);
  const [evaluatorOptions, setEvaluatorsOptions] = useState([]);
  const [evaluatorsValue, setEvaluatorsValue] = useState([]);
  const [evaluatorRemarks, setEvaluatorRemarks] = useState([]);
  const [competencyCategoryData, setCompetencyCategoryData] = useState([]);

  const [isDisable, setIsDisable] = useState<boolean>(true);
  const [formErrors, setFormErrors] = useState({});
  const [candidateDetails, setCandidateDetails] = useState<any>({});
  const [isFeedbackAvailable, setIsFeedbackAvailable] = useState(false);

  useEffect(() => {
    async function helperFunc() {
      await Promise.all([
        handleGetActivityOptionsAsync(),
        handleGetEvaluatorsFeedbackForRecruiterAsync(),
      ]);

      setCandidateDetails((prev) => {
        return {
          candidateId: candidateId,
          candidateName: candidateName,
        };
      });
    }
    helperFunc();
    return () => {
      handleResetComponent();
    };
  }, []);

  function onChange(code: string, value: any) {
    switch (code) {
      case "activity":
        setActivity(activity);
        break;

      default:
        break;
    }
  }

  function handleResetComponent() {
    setMainArr([]);
    setCandidateDetails({});
    setActivityOptions([]);
    setEvaluatorsOptions([]);
    setEvaluatorsValue([]);
    setFormErrors({});
    setIsFeedbackAvailable(false);
    setEvaluatorRemarks([]);
  }

  /* API CALLS */
  async function handleGetActivityOptionsAsync() {
    try {
      showLoader();
      const response = await APICall(GetActivityOptions, "POST", {
        Id: stageId,
      });
      if (response?.status == 0) {
        setActivityOptions(response?.data);
      } else {
        console.error(response?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  const getCompetencyCategoryDataApiCall = async () => {
    const response = await APICall(getCompetencyCategoryData, "POST", {
      TId: mrfId,
    });

    if (response?.data && response?.data?.length > 0) {
      setCompetencyCategoryData(response?.data);

      //1. set data for main arr
      const compentencyArr = response?.data;
      let temp = [];
      for (const i of compentencyArr) {
        let object = {};

        Object.keys(evaluatorOptions).map((key, index) => {
          let value = evaluatorOptions[key]?.label || "";
          object[`responsiblePerson${index + 1}`] = value;
          return null;
        });

        object["categoryId"] = i?.competencyCategoryId || 0;
        object["categoryName"] = i?.competencyCategoryName || "";
        object["competencyId"] = i?.competencyId || 0;
        object["competencyName"] = i?.competencyName || "";
        object["rating"] = 0;
        object["id"] =
          i?.id != null && i?.id != undefined && i?.id != 0 ? i?.id : uuid();

        temp.push(object);
      }
      if (temp?.length > 0) setCompetencyCategoryData(temp);
    } else {
      setCompetencyCategoryData([]);
    }
  };

  async function handleGetEvaluatorsFeedbackForRecruiterAsync() {
    try {
      showLoader();
      const response = await APICall(
        GetEvaluatorsFeedbackForRecruiter,
        "POST",
        {
          CandidateId: candidateId,
          MRFId: mrfId,
          Stage: stageId,
        }
      );

      if (response?.status == 0 && response?.data?.length > 0) {
        const responseData = response?.data || [];

        let mainData = [];

        // Separate data based on stages
        const stages: any = new Set(responseData.map((item) => item.stage));
        const stageData = {};

        for (const stage of stages) {
          let name = "";

          switch (stage) {
            case 5:
              name = "First Interview";
              break;

            case 6:
              name = "Second Interview";
              break;
            case 7:
              name = "Final Interview";
              break;
            default:
              break;
          }

          const evaluatorStageWise = evaluators?.filter(
            (i) => i?.code == stage
          );

          mainData.push({
            stage,
            title: name,
            data: responseData.filter((item) => item.stage === stage),
            evaluators: evaluatorStageWise,
          });
        }

        const filterStages = [5, 6, 7]
          .filter((i) => i <= stageId)
          .forEach((i) => {
            if (!stageData[i]) {
              let name = "";
              switch (i) {
                case 5:
                  name = "First Interview";
                  break;

                case 6:
                  name = "Second Interview";
                  break;
                case 7:
                  name = "Final Interview";
                  break;
                default:
                  break;
              }

              const evaluatorStageWise = evaluators?.filter(
                (i) => i?.code == i
              );

              if (!mainData?.some((ele) => ele?.stage == i)) {
                mainData.push({
                  stage: i,
                  title: name,
                  data: [],
                  evaluators: evaluatorStageWise,
                });
              }
            }
          });

        mainData = mainData.sort((a: any, b: any) => b?.stage - a?.stage);

        setMainArr(mainData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  const modalContentRef = useRef<any>();

  const handlePrint = useReactToPrint({
    content: () => modalContentRef.current,
    documentTitle: `${candidateName}_feedback`,
    onAfterPrint: () => console.log("Printed PDF successfully!"),
  });

  return (
    <>
      <Modal
        show={modal}
        onHide={() => setModal(false)}
        dialogClassName="modal-80w"
        backdrop="static"
        keyboard={false}
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12 d-flex justify-content-between align-items-center">
            <span>Feedback</span>
            <button className="btn btn-secondary" onClick={handlePrint}>
              <i className="fa fa-print" aria-hidden="true" />
            </button>
          </div>
        </div>
        <Modal.Body
          ref={modalContentRef}
          style={{ maxHeight: "calc(100vh - 150px)", overflowY: "auto" }}
        >
          <div className="SectionSubmit mb-4 clearfix"></div>
          <div className="row">
            <div className="col-lg-3 col-sm-3 col-xs-4">
              <div className="mb-1">
                <label className="col-form-label">Candidate ID</label>
                <InputForm
                  className="form-control"
                  placeholder={"Candidate ID"}
                  isDisabled={isDisable}
                  textArea={false}
                  value={candidateDetails?.candidateId || 0}
                  onChange={(val: any) => {}}
                />
              </div>
            </div>
            <div className="col-lg-3 col-sm-3 col-xs-4">
              <div className="mb-1">
                <label className="col-form-label">Candidate Name</label>
                <InputForm
                  className="form-control"
                  placeholder={"Candidate Name"}
                  isDisabled={isDisable}
                  textArea={false}
                  value={candidateDetails?.candidateName || ""}
                  onChange={(val: any) => {}}
                />
              </div>
            </div>
            {/* <div className="col-lg-3 col-sm-3 col-xs-4">
              <div className="mb-1">
                <label className="col-form-label">Type of Activity</label>
                <SelectForm
                  isClearable
                  isSearchable
                  options={activityOptions}
                  placeholder={"Type of Activity"}
                  isDisabled={isDisable}
                  value={activity}
                  onChange={(val: any) => {
                    onChange("activity", val);
                  }}
                  isMulti={false}
                  noIndicator={false}
                  noSeparator={false}
                />
                <p style={{ color: "red" }}>{formErrors["typeofActivity"]}</p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-3 col-xs-4">
              <div className="dropdown mb-1">
                <label className="col-form-label">Evaluator(s)</label>
                <SelectForm
                  isClearable
                  isSearchable
                  options={evaluatorOptions}
                  placeholder={"Evaluators"}
                  isDisabled={isDisable}
                  value={evaluatorsValue}
                  onChange={(val: any) => {
                    onChange("evaluators", val);
                  }}
                  isMulti={true}
                  noIndicator={false}
                  noSeparator={false}
                />
                <p style={{ color: "red" }}>{formErrors["evaluators"]}</p>
                <div className="dropdown-content">
                  {evaluatorsValue?.length > 1 && (
                    <>
                      {evaluatorsValue?.map((i) => (
                        <p>{i?.label || ""}</p>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div> */}
          </div>

          <div className="mt-2">
            {mainArr.map((value, index) => {
              const data = value["data"];
              const title = value["title"];
              const uniqueKey = uuid();
              return (
                <React.Fragment key={uniqueKey}>
                  <div className="my-2">
                    <InterviewRatingsTable
                      data={data}
                      title={title}
                      evaluators={value["evaluators"]}
                    />
                  </div>
                </React.Fragment>
              );
            })}
          </div>
          <div className="mb-3 pt-3"></div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setModal(false);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FeedbackFormView;

const EvaluatorRemarks = ({ data }) => {
  return (
    <>
      <Table striped bordered size="sm">
        <thead>
          <tr>
            <th className="text-dark">Evaluator</th>
            <th className="text-dark">Remarks</th>
          </tr>
        </thead>
        <tbody>
          {data.map((evaluator) => (
            <tr>
              <td>{evaluator.eName}</td>
              <td>{evaluator.remarks ? evaluator.remarks : "NA"}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

const InterviewRatingsTable = ({ data, evaluators, title }) => {
  const [tableData, setTableData] = useState([]);
  const [dashboardGridColumns, setDashboardGridColumns] = useState([]);
  const [evaluatorRemarks, setEvaluatorRemarks] = useState([]);
  const [isFeedbackAvailable, setIsFeedbackAvailable] = useState(false);
  const [page, setPage] = useState(0);

  useEffect(() => {
    processData();

    return () => {
      setDashboardGridColumns([]);
      setTableData([]);
    };
  }, []);

  // useEffect(() => {
  //
  //   if (tableData.length > 0) {
  //     setIsFeedbackAvailable(
  //       tableData.every(
  //         (data) => data.hasOwnProperty("average") && !isNaN(data?.average)
  //       )
  //     );
  //   }
  // }, [tableData]);

  function processData() {
    const groupkeyObject = {};
    const evaluatorsRating = {};
    const evaluatorMessage = {};

    addResponsiblePersonColumns(evaluators);
    /**
     * GROUPING DATA ACCORDING TO COMPENTENCY AND CATEGORY.
     */
    for (const item of data) {
      const key = `${item.competencyId}_${item.competencyCategoryId}`;

      groupkeyObject[key] = groupkeyObject[key] || [];
      evaluatorsRating[item.evaluatorId] =
        evaluatorsRating[item.evaluatorId] || [];

      evaluatorsRating[item.evaluatorId].push(item.rating);
      groupkeyObject[key].push(item);
    }

    const tempArr = [];
    const avgObj = {
      competencyCategoryName: "Average",
      competencyName: "",
    };

    // Calculate average ratings for each evaluator
    let avgSum: any = 0;
    let avgTotal = 0;
    for (const key in evaluatorsRating) {
      const value = evaluatorsRating[key];
      const avg =
        value.length > 0
          ? value.reduce((acc, currentValue) => acc + currentValue, 0) /
            value.length
          : " - ";
      avgTotal++;
      avgObj[`responsiblePerson_${key}`] = avg ? Number(avg).toFixed(2) : avg;
      avgSum += avg;
    }
    let avgAverage = avgSum / avgTotal;
    avgObj["average"] = avgAverage ? Number(avgAverage).toFixed(2) : avgAverage;

    // Process groupkeyObject to create mainArr
    for (const key of Object.keys(groupkeyObject)) {
      const value = groupkeyObject[key];
      let sum = 0;
      let total = 0;
      const object = {};

      evaluators.forEach((evaluator, index) => {
        const responisblePerson = `responsiblePerson_${evaluator?.value}`;
        const findObject = value.find(
          (i) => i?.evaluatorId == evaluator?.value
        );
        if (findObject?.competencyName) {
          object["competencyName"] = findObject?.competencyName;
        }
        if (findObject?.competencyCategoryName) {
          object["competencyCategoryName"] = findObject?.competencyCategoryName;
        }

        let rating: any = " - ";

        if (findObject?.rating) {
          sum += Number(findObject?.rating);
          rating = Number(findObject?.rating);
          total++;
        }

        object[responisblePerson] = rating;

        // add remarks
        if (findObject?.remarks) {
          let prevEval = [...evaluatorRemarks];

          prevEval.push({
            eName: evaluator?.label,
            remarks: findObject.remarks || "",
          });
          evaluatorMessage[evaluator.value] = {
            eName: evaluator.label,
            remarks: findObject.remarks || "",
          };
          prevEval = prevEval.filter(
            (item, index) => prevEval.indexOf(item) === index
          );
        }
      });

      const avg: any = total > 0 ? sum / total : " - ";
      object["average"] = !isNaN(avg) ? Number(avg).toFixed(2) : avg;

      tempArr.push(object);

      let arr = Object.keys(evaluatorMessage).map((key) => {
        return evaluatorMessage[key];
      });
      setEvaluatorRemarks(arr);
    }
    tempArr.push(avgObj);

    setTableData(tempArr);

    setIsFeedbackAvailable(
      tempArr.every(
        (data) => data.hasOwnProperty("average") && !isNaN(data?.average)
      )
    );
  }

  const addResponsiblePersonColumns = (evaluators = []) => {
    const newColumns =
      evaluators?.flatMap((responsiblePersonObj) => {
        const label = responsiblePersonObj.label || "";

        const ratingName = `responsiblePerson_${responsiblePersonObj.value}`;

        const nameColumn = {
          name: ratingName,
          label,
          options: {
            filter: false,
            sort: false,
            sortDescFirst: true,
            display: true,
            setCellProps: () => ({
              style: { textAlign: "center" },
            }),
            setCellHeaderProps: () => ({
              style: { textAlign: "center" },
            }),
          },
        };

        return [nameColumn];
      }) || [];

    const gridColumns = [
      {
        name: "Id",
        label: "Id",
        options: {
          display: false,
        },
      },

      {
        name: "competencyCategoryName",
        label: "Category",
        options: {
          filter: false,
          sort: false,
          sortDescFirst: true,
        },
      },
      {
        name: "competencyName",
        label: "Competency",
        options: {
          filter: false,
          sort: false,
          sortDescFirst: true,
        },
      },
    ];

    const action = {
      name: "average",
      label: "Average Rating",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "center" },
        }),
      },
    };
    const updatedColumns = [...gridColumns, ...newColumns, action];
    setDashboardGridColumns(updatedColumns);
  };

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: tableData?.length,
    rowsPerPage: tableData?.length,
    page: page,
    serverSide: false,
    onChangePage: async (page) => {
      setPage(page);
    },
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "scroll",
    onSearchChange: (searchText) => {},
    onColumnSortChange: async (sortColumn, sortDirection) => {},
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  return (
    <>
      {isFeedbackAvailable ? (
        <>
          <div className="row">
            <div className="col-lg-12 p-0 mb-3">
              <div className="d-flex justify-content-center my-1">
                <h6>{title}</h6>
              </div>
              <DynamicGrid
                data={tableData}
                columns={dashboardGridColumns}
                options={options}
              />
            </div>
          </div>
          {evaluatorRemarks.length > 0 && (
            <div className="col-lg-6 p-0">
              <EvaluatorRemarks data={evaluatorRemarks} />
            </div>
          )}
        </>
      ) : (
        <div className="d-flex justify-content-center">
          <h6>No feedback available for {title}, check later</h6>
        </div>
      )}
    </>
  );
};
