import React, { useContext, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  TextField,
} from "@mui/material";
import { ContentPasteOffSharp, ExpandMore } from "@mui/icons-material";
import SelectForm from "../../../Components/SelectForm/SelectForm";
import InputForm from "../../../Components/InputForm/InputForm";
import moment from "moment";
import { APICall } from "../../../Helpers/APICalls";
import { LoaderContext } from "../../../Helpers/Context/Context";
import {
  insertIntoMRFApprovalDetails,
  getApproverDetailsForProccessByMRFId,
  getRecruiterDropdown,
  getRecruiterAgencyData,
  getPostingOptions,
  insertIntoMRFAgencyMapping,
  getMRFAgencyMappingByMRFId,
  getOrganizationUnitsDataByType,
  IntiateApprovalWorkflow,
  GetRolesURL,
  getMRFApproverByMRFId,
  getTabHeaderDetails,
  GetJobBoardOptions,
  GetMRFNaukriPostedStatus,
  GetLinkedinOptions,
} from "../../../Helpers/APIEndPoints/EndPoints";
import notify from "../../../Helpers/ToastNotification";
import { useLocation, useNavigate } from "react-router-dom";
import useUserContext from "../../../Helpers/ZustandStore/UserContextStore";
import { MRFContext } from "../MRF";
import {
  DecisionEnum,
  IJobPosting,
  IMRFApproverMappingData,
  INaukriInterface,
  JobBoardPosting,
  MRFStatus,
  PostingType,
  WorkflowTaskStatus,
  isEmpty,
  ApproverDateForm,
  ConfirmationModal,
  CustomApproverAccordian,
  JobPostingType,
  isStartDateGreaterThanEndDate,
  isInvalidDate,
  NaurkiFieldsValidation,
} from "./ApproverHelper";
import NaukriColumns from "./NaukriColumns";
import PreviousConversionTable from "./PreviousConversionTable";

const DECISION = [
  { value: 1, label: "Approved" },
  { value: 2, label: "Rejected" },
  { value: 3, label: "Sendback" },
];

const ApproverSection = ({}) => {
  const navigate = useNavigate();
  let { userDetails, currentRoleId }: any = useUserContext();

  const [mrfApproverMappingData, setMrfApproverMappingData] = useState<
    IMRFApproverMappingData[]
  >([]);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { state } = useLocation();
  const [TId, setTId] = useState((state !== null && state.TId) || 0);
  const [jobPostingData, setJobPostingData] = useState<IJobPosting>({
    RecruiterId: null,
    RecruiterName: "",
    PostingId: "",
    PostingData: null,
    PostingFromDate: null,
    PostingTillDate: null,
    RecruiterAgencyData: null,
    IsDisabled: false,
    RequestId: null,
    LegalEntity: null,
   // JobLevel: null,
    PayrollLocation: null,
    JobBoard: null,
    LinkedInCommonHashtag: null,
    RecruiterHashTag: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [postingOptions, setPostingOptions] = useState([]);
  const [recruiterAgencyDisable, setRecruiterAgencyDisable] = useState(false);
  // const [showHideDivForIJP, setShowHideDivForIJP] = useState(false);
  const [legalEntityOptions, setLegalEntityOptions] = useState([]);
  //const [JoblevelOptions, setJoblevelOptions] = useState([]);
  const [PayrollLocationOptions, setPayrollLocationOptions] = useState([]);
  const [initiatorRemarks, setInitiatorRemarks] = useState("");
  const [buHR, setBUHR] = useState(0);
  const [recruiterDisabled, setRecruiterDisabled] = useState(false);
  const [recruiterRoleId, setRecruiterRoleId] = useState(0);
  const [disableApproverBtn, setDisableApproverBtn] = useState(false);
  const [prevConvArr, setPrevConv] = useState([]);
  const [isMRFOpen, setIsMRFOpen] = useState(false);
  const [roles, setRoles] = useState([]);
  const [jobBoardOptions, setJobBoardOptions] = useState([]);
  const [hideJobBoardOption, setHideJobBoardOption] = useState(true);
  const [jobPostingModal, setJobPostingModal] = useState(false);
  const [isNaukriSelected, setIsNaukriSelect] = useState<boolean>(false);
  const [naukriFormDetails, setNaukriFormDetails] = useState<INaukriInterface>(
    {}
  );
  const [jobPostedOnNaurki, setJobPostedOnNaukri] = useState(false);
  const [showPublishBtn, setShowPublishBtn] = useState(true);
  const [naukriNotSelectedModal, setNaukriNotSelectedModal] = useState(false);
  const {
    sendback,
    setShowStagesAndResponsbility,
    workflowApprovers,
    setWorkflowApprovers,
  } = useContext(MRFContext);
  const [naukriColumnsError, setNaukriColumnError] = useState({});
  const [refreshKey, setRefreshKey] = useState(false);
  const [linkedinOptions, setLinkedinOptions] = useState([]);

  const [showLinkdinField, setShowLinkdinField] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        getOrganizationUnitsDataForLegalEntityAPICall(),
        //getOrganizationUnitsDataForJobLevelAPICall(),
        getOrganizationUnitsDataForPayrollLocationAPICall(),
        GetRolesAsync(),
        GetTabHeaderDetailsAsync(),
        handleFetchPostingOptions(),
        handleFetchMRFApproverByMRFId(),
        handleFetchJobBoardOptions(),
        handleFetchLinkedDinOptions(),
      ]);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await handleFetchMRFApproverMappingData();
    };
    fetchData();
  }, [ roles, isMRFOpen]);

  useEffect(() => {
    const fetchData = async () => {
      await handleFetchNaukriStatus();
    };
    fetchData();
  }, [refreshKey]);
  /**
   * Get Headers Value
   */
  const GetTabHeaderDetailsAsync = async () => {
    try {
      showLoader();
      const response = await APICall(getTabHeaderDetails, "POST", {
        MN: "ManpowerRequisition",
        IN: "",
        Id: TId,
      });

      if (response?.status == 0) {
        const status =
          String(response?.data?.mrfStatus).toLocaleLowerCase().trim() ==
            MRFStatus.Open ||
          String(response?.data?.mrfStatus).toLocaleLowerCase().trim() ==
            MRFStatus.PendingApproval;

        setIsMRFOpen(status);
      }
    } catch (error) {
      console.error("get headers details error:  ", error);
    } finally {
      hideLoader();
    }
  };

  /**
   * Get Roles Value
   */
  const GetRolesAsync = async () => {
    try {
      showLoader();
      let arr = [];
      const response = await APICall(GetRolesURL, "POST", {});

      if (response?.status == 0 && response?.data?.length > 0) {
        const data = response?.data;
        const role =
          data.find((i) => i?.name?.toLocaleLowerCase()?.trim() == "buhr")
            ?.id || 0;
        setRoles(data);
        setBUHR(role);

        const recruiterRole =
          data?.find((i) => i?.name?.toLocaleLowerCase()?.trim() == "recruiter")
            ?.id || 0;

        setRecruiterRoleId(recruiterRole);

        arr = data;
      } else {
        setRoles([]);
      }

      return arr;
    } catch (error) {
      console.error("Get Roles error: ", error);
    } finally {
      hideLoader();
    }
  };

  /**
   *  When Some of the approvers sends back the request,
   * BU HR can re-initiate the workflow.
   */
  const ReInitiateWorkflow = async (id) => {
    try {
      if (!initiatorRemarks || String(initiatorRemarks)?.trim().length == 0) {
        notify(1, "Initiator Remarks Required.");
        return;
      }
      showLoader();
      const response = await APICall(IntiateApprovalWorkflow, "POST", {
        MRFId: TId,
        InitiatorId: userDetails?.Id,
        InitiatorRole: currentRoleId?.value,
        Remarks: initiatorRemarks,
        IsReInitiateWorkflow: true,
        Approvers: workflowApprovers,
        Decision: DecisionEnum.ReInitiated,
      });

      if (response?.status == 0) {
        notify(0, "Record Saved Successfully.");
        navigate("/home");
      } else {
        notify(1, "Something went wrong.");
      }
    } catch (error) {
      console.error("re initiateworkflow error message: ", error);
    } finally {
      hideLoader();
    }
  };

  /**
   * Getting All the Approvers and thier decions, remarks.
   * Showing task as per approvers and recruiters.
   */
  const handleFetchMRFApproverMappingData = async () => {
    try {
      showLoader();

      const response = await APICall(
        getApproverDetailsForProccessByMRFId,
        "POST",
        {
          MRFId: TId,
        }
      );

      if (response?.status === 0 && response?.data?.length > 0) {
        const mappedData = response.data.map((item) =>
          mapDataItem({
            item,
            userDetails,
            currentRoleId,
            buHR,
            isMRFOpen,
            state,
            sendback,
          })
        );
        const ogMappedData = mappedData;

        /*
          IF RECRUITER OR ONE OF THE APPROVER IN MRF REFER: MRFApproverMapping Table
        */
        const IsApprover = mappedData.some(
          (k) =>
            k.ApproverId == userDetails.Id ||
            k.RecruiterSelectedOption?.some((i) => i?.value == userDetails.Id)
        );

        if (mappedData.length > 0) {
          if (IsApprover) {
            const filterData = mappedData?.find(
              (k) =>
                k.ApproverId == userDetails.Id ||
                k.RecruiterSelectedOption?.some(
                  (i) => i?.value == userDetails.Id
                )
            );

            if (filterData?.prevConv && filterData?.prevConv?.length > 0) {
              const conv = JSON.parse(filterData?.prevConv);
              if (conv && conv?.length > 0) {
                const arr = GetPreviousConversation(conv);
                setPrevConv(arr);
              }
            }

            if (IsApprover && currentRoleId?.value == buHR) {
              if (
                filterData?.PreviousConversationForInitiator &&
                filterData?.PreviousConversationForInitiator?.length > 0
              ) {
                const conv = JSON.parse(
                  filterData?.PreviousConversationForInitiator
                );
                if (conv && conv?.length > 0) {
                  const arr = GetPreviousConversation(conv);
                  setPrevConv(arr);
                }
              }
            }
          } else {
            const filterData = mappedData[0];
            if (
              filterData?.PreviousConversationForInitiator &&
              filterData?.PreviousConversationForInitiator?.length > 0
            ) {
              const conv = JSON.parse(
                filterData?.PreviousConversationForInitiator
              );
              if (conv && conv?.length > 0) {
                const arr = GetPreviousConversation(conv);
                setPrevConv(arr);
              }
            }
          }
        }

        setMrfApproverMappingData(mappedData);

        if (IsApprover && currentRoleId?.value != buHR) {
          const filterObject = mappedData.find(
            (item) =>
              item.ApproverId === userDetails.Id ||
              item.RecruiterSelectedOption?.some(
                (i) => i?.value == userDetails.Id
              )
          );

          if (!filterObject.WorkflowTaskId) {
            setMrfApproverMappingData([]);
            return;
          }

          if (filterObject?.SequenceNo == filterObject?.LastSequence) {
            await handleBindJobPostingData({ filterObject });
          }

          const filteredData = mappedData.filter(
            (item) =>
              item.SequenceNo <= filterObject.SequenceNo &&
              item.WorkflowTaskId != 0 &&
              item.WorkflowTaskId != null
          );

          setMrfApproverMappingData(filteredData);
        } else {
          if (state?.IsMRFAdmin) {
            setMrfApproverMappingData(ogMappedData);
          } else {
            if (sendback) {
              const filterObject = mappedData.find(
                (i) => i?.DecisionId == DecisionEnum.Sendback
              );

              const filteredData = mappedData
                .filter((item) => item.SequenceNo <= filterObject?.SequenceNo)
                ?.map((i) => {
                  i["IsSendback"] = i.DecisionId == DecisionEnum.Sendback;
                  return i;
                });

              setMrfApproverMappingData(filteredData);
            } else {
              setMrfApproverMappingData([]);
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  /**
   *
   */
  async function handleBindJobPostingData({ filterObject }) {
    try {
      const res = await APICall(getMRFAgencyMappingByMRFId, "POST", {
        MRFId: TId,
      });

      if (res?.status == 0 && res?.data?.length > 0) {
        const postingOptionsArr = await handleFetchPostingOptions();

        const postingOptionSaved =
          res?.data[0].PostingOptionsId?.split(",").map(Number) || [];

        const PostingOptionsData = postingOptionsArr?.filter((item) =>
          postingOptionSaved?.includes(item?.value)
        );

        const isAgencyDisable = PostingOptionsData?.some(
          (eve) => eve.code === String(PostingType.Agency)
        );

        if (
          PostingOptionsData?.some(
            (eve) => eve.code === String(PostingType.JobBoard)
          )
        ) {
          setHideJobBoardOption((prev) => false);
          const savedJobBoardOptions =
            res?.data[0]?.jobBoardPosting?.split(",");

          const options = await handleFetchJobBoardOptions();

          const jobOption = options?.filter((item) =>
            savedJobBoardOptions?.includes(String(item?.value))
          );

          const isnaukri = jobOption?.some(
            (eve) => eve.code === String(JobBoardPosting.Naukri)
          );

          const isLinkdin = jobOption?.some(
            (eve) =>
              String(eve.code).toLowerCase() ===
              String(JobBoardPosting.LinkedIn).toLowerCase()
          );
          setIsNaukriSelect(isnaukri);
          setShowLinkdinField(isLinkdin);

          if (isLinkdin) {
            const linkdinOptionss = await handleFetchLinkedDinOptions();

            const savedLinkedinOptions =
              res?.data[0]?.linkedInCommonHashTag?.split(",");

            const linkedinData = linkdinOptionss?.filter((item) =>
              savedLinkedinOptions?.includes(item?.code)
            );
            setJobPostingData((prev) => {
              return {
                ...prev,
                LinkedInCommonHashtag: linkedinData,
                RecruiterHashTag: res?.data[0]?.recruiterHashTag,
              };
            });
          }
          setJobPostingData((prev) => {
            return { ...prev, JobBoard: jobOption };
          });
        } else {
          setHideJobBoardOption((prev) => true);
          setIsNaukriSelect(false);
          setShowLinkdinField(false);
          setJobPostingData((prev) => {
            return {
              ...prev,
              JobBoard: [],
              JobBoardPosting: [],
            };
          });
        }

        isAgencyDisable
          ? setRecruiterAgencyDisable(true)
          : setRecruiterAgencyDisable(false);

        // const isShowHideIJPDiv = PostingOptionsData?.some(
        //   (eve) => eve.code === String(PostingType.WeRise)
        // );

        // isShowHideIJPDiv
        //   ? setShowHideDivForIJP(true)
        //   : setShowHideDivForIJP(false);

        const legalEntityOptionSaved =
          res?.data[0].LegalEntityIds?.split(",").map(Number) || [];

        const legalEntityOptionsData = legalEntityOptions?.filter((item) =>
          legalEntityOptionSaved?.includes(item?.value)
        );

        // const joblevelOptionSaved =
        //   res?.data[0].JobLevelIds?.split(",").map(Number) || [];

        // let arr = JoblevelOptions;
        // if (isEmpty(JoblevelOptions)) {
        //   arr = await getOrganizationUnitsDataForJobLevelAPICallV2();
        // }

        // const joblevelOptionsData = arr?.filter((item) =>
        //   joblevelOptionSaved?.includes(item?.value)
        // );

        const payrollLocationOptionSaved =
          res?.data[0].PayrollLocationIds?.split(",").map(Number) || [];

        const payrollLocationOptionsData = PayrollLocationOptions?.filter(
          (item) => payrollLocationOptionSaved?.includes(item?.value)
        );

        let RecruiterAgencyData = null;

        if (res?.data?.length > 0) {
          RecruiterAgencyData = res?.data.map((item) => {
            return {
              value: item?.RecruiterAgencyId
                ? parseInt(item.RecruiterAgencyId)
                : null,
              label: item?.RecruiterAgencyName,
            };
          });

          if (
            RecruiterAgencyData?.length == 1 &&
            !RecruiterAgencyData[0]?.value &&
            !RecruiterAgencyData[0]?.label
          ) {
            RecruiterAgencyData = null;
          }
        }

        let RecruiterObject = filterObject.RecruiterSelectedOption?.find(
          (i) => i?.value == userDetails.Id
        );

        if (res?.data[0].PostingTillDate && res?.data[0].PostingFromDate) {
          setShowStagesAndResponsbility(true);
        }

        let tempIds = PostingOptionsData?.map(
          (selectedOption) => selectedOption?.value || ""
        );
        tempIds = tempIds?.join(",") || "";

        setJobPostingData((prev) => {
          return {
            ...prev,
            RecruiterName: RecruiterObject?.label,
            RecruiterId: RecruiterObject?.value,
            PostingFromDate: res?.data[0].PostingFromDate,
            PostingTillDate: res?.data[0].PostingTillDate,
            PostingData: PostingOptionsData,
            RecruiterAgencyData: RecruiterAgencyData || null,
            PostingId: tempIds,
            RequestId: filterObject.RequestId,
            IsDisabled: !isMRFOpen,
            //: legalEntityOptionsData,
            //JobLevel: joblevelOptionsData,
            //PayrollLocation: payrollLocationOptionsData,
          };
        });
      } else {
        let RecruiterObject = filterObject.RecruiterSelectedOption?.find(
          (i) => i?.value == userDetails.Id
        );

        setJobPostingData((prev) => {
          return {
            ...prev,
            RecruiterName: RecruiterObject?.label,
            RecruiterId: RecruiterObject?.value,
            RequestId: filterObject.RequestId,
            IsDisabled: !isMRFOpen || state?.type == "view",
          };
        });
      }
    } catch (error) {}
  }

  /**
   * Get All Workflow Approvers for based on MRF,
   * for global object.
   */
  const handleFetchMRFApproverByMRFId = async (flag = false) => {
    try {
      showLoader();
      const response = await APICall(getMRFApproverByMRFId, "POST", {
        MRFId: TId,
      });

      if (response?.status == 0 && response?.data?.length > 0) {
        let arr = response?.data?.map((item, index) => {
          return {
            ...item,
            label: item.ApproverName,
            value: item.ApproverId,
            roleId: item.ApproverRoleId,
            Id: item.Id,
            disabled: item?.isDraft ? false : sendback ? false : true,
            sequenceNo: item.SequenceNo,
          };
        });

        if (workflowApprovers?.length == 0 || !workflowApprovers) {
          setWorkflowApprovers(arr);
        } else {
          setWorkflowApprovers(workflowApprovers);
        }
      }
    } catch (error) {
      console.info(error);
    } finally {
      hideLoader();
    }
  };

  /**
   * Fetch Recruiters, when search...
   */
  const handleFetchRecruiterOptions = async (event, cb) => {
    try {
      if (event.length > 3) {
        showLoader();
        const response = await APICall(getRecruiterDropdown, "POST", {
          Id: userDetails.Id,
          SearchText: event,
        });

        if (response && response?.status == 0) {
          if (response?.data?.length > 0) {
            cb(response?.data);
          }
        }
        hideLoader();
      }
    } catch (error) {
      console.error(error);
      hideLoader();
    }
  };

  /**
   *  Get Legal Entity
   */
  const getOrganizationUnitsDataForLegalEntityAPICall = async () => {
    try {
      showLoader();
      const response = await APICall(getOrganizationUnitsDataByType, "POST", {
        SystemName: "LegalEntity",
      });

      if (response && response?.status == 0) {
        if (response?.data?.length > 0) {
          setLegalEntityOptions(response?.data);
        } else {
          setLegalEntityOptions([]);
        }
      }
      hideLoader();
    } catch (error) {
      console.error(error);
      hideLoader();
    }
  };

  /**
   * Get Job Level
   */
  // const getOrganizationUnitsDataForJobLevelAPICall = async () => {
  //   try {
  //     showLoader();
  //     const response = await APICall(getOrganizationUnitsDataByType, "POST", {
  //       SystemName: "JobLevel",
  //     });

  //     if (response && response?.status == 0) {
  //       if (response?.data?.length > 0) {
  //         setJoblevelOptions(response?.data);
  //         return response?.data;
  //       } else {
  //         setJoblevelOptions([]);
  //       }
  //     }

  //     return [];
  //   } catch (error) {
  //     console.error(error);
  //     hideLoader();
  //   }
  // };
  /**
   * Get Job Level
   */
  // const getOrganizationUnitsDataForJobLevelAPICallV2 = async () => {
  //   try {
  //     showLoader();
  //     const response = await APICall(getOrganizationUnitsDataByType, "POST", {
  //       SystemName: "JobLevel",
  //     });

  //     if (response && response?.status == 0) {
  //       if (response?.data?.length > 0) {
  //         return response?.data;
  //       } else {
  //       }
  //     }

  //     return [];
  //   } catch (error) {
  //     console.error(error);
  //     hideLoader();
  //   }
  // };

  /**
   * Get Payroll location
   */
  const getOrganizationUnitsDataForPayrollLocationAPICall = async () => {
    try {
      showLoader();
      const response = await APICall(getOrganizationUnitsDataByType, "POST", {
        SystemName: "PayrollLocation",
      });

      if (response && response?.status == 0) {
        if (response?.data?.length > 0) {
          setPayrollLocationOptions(response?.data);
        } else {
          setPayrollLocationOptions([]);
        }
      }
      hideLoader();
    } catch (error) {
      console.error(error);
      hideLoader();
    }
  };

  /**
   * handle Decision change when approver select the decisoin
   */
  const handleDecisionEventChange = (event, Id) => {
    setRecruiterDisabled(event?.value == 3);

    setMrfApproverMappingData((prevData) =>
      prevData.map((item) =>
        item.Id === Id
          ? {
              ...item,
              Decision: event,
              DecisionId: event?.value ? event?.value : 0,
            }
          : item
      )
    );
  };

  /**
   * handle Submit For Approver Section
   */
  const handleSubmitForApproverDetailsById = async (id) => {
    try {
      const filterData = mrfApproverMappingData.find((k) => k.Id == id);

      if (filterData == null) return;

      let ErrorObj = {};

      if (!filterData.Acknowledgement) {
        ErrorObj[`${filterData.Id}_Acknowledgement`] =
          "Acknowledgement Required.";
        setFormErrors(ErrorObj);
        return;
      } else {
        delete ErrorObj[`${filterData.Id}_Acknowledgement`];
      }

      if (!filterData.Date) {
        ErrorObj[`${filterData.Id}_Date`] = "Date Required.";
      } else if (!moment(filterData.Date).isValid()) {
        ErrorObj[`${filterData.Id}_Date`] = "Provide valid Date.";
      } else {
        delete ErrorObj[`${filterData.Id}_Date`];
      }

      if (!filterData.DecisionId) {
        ErrorObj[`${filterData.Id}_Decision`] = "Decision Required.";
      } else {
        delete ErrorObj[`${filterData.Id}_Decision`];
      }

      if (
        filterData.SequenceNo == filterData.LastSequence &&
        !filterData.RecruiterId &&
        filterData?.DecisionId != 3
      ) {
        ErrorObj[`${filterData.Id}_Recruiter`] = "Recruiter Required.";
      } else {
        delete ErrorObj[`${filterData.Id}_Recruiter`];
      }

      if (
        // filterData?.DecisionId == 3 &&
        filterData.Remarks?.trim()?.length == 0
      ) {
        ErrorObj[`${filterData.Id}_Remarks`] = "Remarks Required.";
      } else {
        delete ErrorObj[`${filterData.Id}_Remarks`];
      }

      setFormErrors(ErrorObj);

      if (Object.keys(ErrorObj).length > 0) {
        return;
      }

      showLoader();
      let Date = moment.utc(filterData.Date).format("YYYY-MMM-DD");
      const response = await APICall(insertIntoMRFApprovalDetails, "POST", {
        Id: filterData.Id,
        Date: Date,
        Decision: filterData.DecisionId,
        RecruiterId: filterData.RecruiterId ? filterData.RecruiterId : "",
        Remarks: filterData.Remarks,
        RoleId: currentRoleId?.value,
        CreatedBy: userDetails.Id,
        SequenceNo: filterData.SequenceNo,
        WorkflowId: filterData.WorkflowId,
        WorkflowTaskId: filterData.WorkflowTaskId,
        RequestId: filterData.RequestId,
        MRFId: TId,
        LastSequence: filterData.LastSequence,
        MRFApproverDetailsId: filterData.MRFApproverDetailsId,
      });

      if (response?.status == 0) {
        await handleFetchMRFApproverMappingData();
        notify(0, "Record Saved Successfully.");
        navigate("/home");
      } else {
        notify(1, response.message ? response.message : "");
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  /**
   * Clear Approver Section Details: Decision, Recruiter, remarks.
   */
  const handleClearForm = (event, id) => {
    try {
      event.stopPropagation();
      setMrfApproverMappingData((prevData) =>
        prevData.map((prev) =>
          id === prev.Id
            ? {
                ...prev,
                DecisionId: null,
                Decision: null,
                Recruiter: null,
                RecruiterId: null,
                Remarks: "",
                Acknowledgement: false,
                RecruiterName: null,
                Accordian: !prev.Accordian,
              }
            : prev
        )
      );
    } catch (error) {}
  };

  /**
   * Fetch Agency Options
   */
  const handleFetchRecruiterAgencyOptions = async (event, cb) => {
    try {
      if (event.length > 3) {
        showLoader();
        const response = await APICall(getRecruiterAgencyData, "POST", {
          Id: userDetails.Id,
          SearchText: event,
        });

        if (response && response?.status == 0) {
          if (response?.data?.length > 0) {
            cb(response?.data);
          }
        }
        hideLoader();
      }
    } catch (error) {
      console.error(error);
      hideLoader();
    }
  };

  /**
   * Fetch Posting Options
   */
  const handleFetchPostingOptions = async () => {
    try {
      showLoader();
      const response = await APICall(getPostingOptions, "POST", {});

      if (response && response?.status == 0) {
        if (response?.data?.length > 0) {
          setPostingOptions(response?.data);
          return response?.data;
        }
      }

      return [];
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  /**
   * Fetch Job Boarding Options
   */
  const handleFetchJobBoardOptions = async () => {
    try {
      showLoader();
      const response = await APICall(GetJobBoardOptions, "POST", {
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      });

      if (response && response?.status == 0) {
        if (response?.data?.length > 0) {
          setJobBoardOptions(response?.data);
          return response?.data;
        }
      }

      return [];
    } catch (error) {
    } finally {
      hideLoader();
    }
  };
  /**
   * Fetch Linkedin Options
   */
  const handleFetchLinkedDinOptions = async () => {
    try {
      showLoader();
      const response = await APICall(GetLinkedinOptions, "POST", {});

      if (response && response?.status == 0) {
        if (response?.data?.length > 0) {
          setLinkedinOptions(response?.data);
          return response?.data;
        }
      }

      return [];
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  /**
   * Job Posting Validation
   */
  const validationForJobPosting = () => {
    try {
      let ErrorObj = {};

      //# Mandatory Fields are empty? ----------------------------------
      if (isEmpty(jobPostingData?.PostingId)) {
        ErrorObj["error_JobPostingOptions"] = "Posting Option Required";
      } else {
        delete ErrorObj["error_JobPostingOptions"];
      }

      if (isInvalidDate(jobPostingData.PostingFromDate)) {
        ErrorObj["error_JobPostingFromDate"] = "Please provide a valid date.";
      } else {
        delete ErrorObj["error_JobPostingFromDate"];
      }

      if (isInvalidDate(jobPostingData.PostingTillDate)) {
        ErrorObj["error_JobPostingTillDate"] = "Please  provide a valid date.";
      } else {
        delete ErrorObj["error_JobPostingTillDate"];
      }

      //# Check posting start date is greather posting end date
      if (
        !isInvalidDate(jobPostingData.PostingFromDate) &&
        !isInvalidDate(jobPostingData.PostingTillDate) &&
        isStartDateGreaterThanEndDate(
          jobPostingData.PostingFromDate,
          jobPostingData.PostingTillDate
        )
      ) {
        ErrorObj["error_JobPostingTillDate"] =
          "Posting Till Date cannot be less than Posting From Date";
      } else {
        delete ErrorObj["error_JobPostingTillDate"];
      }

      //# Job Board option is select, but job board is empty.
      if (!isEmpty(jobPostingData?.PostingId)) {
        const isJobBoardSelected = jobPostingData.PostingData?.some(
          (i) => i?.code == PostingType.JobBoard
        );

        if (isJobBoardSelected && isEmpty(jobPostingData.JobBoard)) {
          ErrorObj["error_JobboardOption"] = "Job Board Type required.";
        } else delete ErrorObj["error_JobboardOption"];
      }

      //# If agency option is selected in job board but agency is empty.
      if (
        recruiterAgencyDisable &&
        isEmpty(jobPostingData.RecruiterAgencyData)
      ) {
        ErrorObj["error_JobPostingRecruiterAgency"] =
          "Recruiter Agency Required";
      } else {
        delete ErrorObj["error_JobPostingRecruiterAgency"];
      }

      //# in werise option is selected, job level is empty.
      // if (showHideDivForIJP && isEmpty(jobPostingData.JobLevel)) {
      //   ErrorObj["error_JobLevel"] = "Job Level Required";
      // } else {
      //   delete ErrorObj["error_JobLevel"];
      // }
      debugger;
      if (showLinkdinField) {
        if (isEmpty(jobPostingData.LinkedInCommonHashtag)) {
          ErrorObj["error_LinkedInCommonHashtag"] =
            "LinkedIn Common HashTag Required";
        } else {
          delete ErrorObj["error_LinkedInCommonHashtag"];
        }

        if (isEmpty(jobPostingData.RecruiterHashTag)) {
          ErrorObj["error_RecruiterHashTag"] = "Recruiter HashTag Required";
        } else {
          delete ErrorObj["error_RecruiterHashTag"];
        }
      } else {
        delete ErrorObj["error_RecruiterHashTag"];
      }

      /*----------------------------------------------------------------*/
      setFormErrors(ErrorObj);
      return Object.keys(ErrorObj).length > 0;
    } catch (error) {
      console.error("job post submit validation error: ", error);
      return true;
    }
  };

  /**
   * Submit For Job Posting
   */
  const handleSubmitForJobPosting = async () => {
    try {
      const isError = validationForJobPosting();
      if (isError) return;

      let PostingFromDate = moment(jobPostingData.PostingFromDate).format(
        "YYYY-MMM-DD"
      );

      let PostingTillDate = moment(jobPostingData.PostingTillDate).format(
        "YYYY-MMM-DD"
      );

      showLoader();
      const response = await APICall(insertIntoMRFAgencyMapping, "POST", {
        RecruiterId: jobPostingData.RecruiterId,
        PostingOptionsId: jobPostingData.PostingId,
        PostingFromDate: PostingFromDate,
        PostingTillDate: PostingTillDate,
        CreatedBy: userDetails.Id,
        RecruiterAgencyIds:
          jobPostingData.RecruiterAgencyData?.length > 0
            ? jobPostingData.RecruiterAgencyData.map((i) => i.value).join(",")
            : "",
        MRFId: TId,
        RequestId: jobPostingData.RequestId,
        // LegalEntityIds:
        //   jobPostingData.LegalEntity?.length > 0
        //     ? jobPostingData.LegalEntity.map((i) => i.value).join(",")
        //     : "",
        // JobLevelIds:
        //   jobPostingData.JobLevel?.length > 0
        //     ? jobPostingData.JobLevel.map((i) => i.value).join(",")
        //     : "",
        // PayrollLocationIds:
        //   jobPostingData.PayrollLocation?.length > 0
        //     ? jobPostingData.PayrollLocation.map((i) => i.value).join(",")
        //     : "",
        JobBoardPosting: jobPostingData?.JobBoard?.map((i) => i?.value)?.join(
          ","
        ),
        LinkedInCommonHashTag: jobPostingData.LinkedInCommonHashtag?.map(
          (i) => i?.code
        )?.join(","),
        RecruiterHashTag: jobPostingData.RecruiterHashTag,
      });

      if (response?.status == 0) {
        await handleFetchMRFApproverMappingData();
        notify(0, "Record Saved Successfully.");
      } else {
        notify(1, response.message ? response.message : "");
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  /**
   *
   */
  async function handleOnChangeForPostingOptions(event) {
    try {
      let tempIds = event?.map((selectedOption) => selectedOption?.value || "");

      tempIds = tempIds?.join(",") || "";

      setJobPostingData((prev) => {
        return {
          ...prev,
          PostingId: tempIds,
          PostingData: event,
        };
      });

      const isAgencyDisable = event?.some(
        (eve) => eve.code === String(PostingType.Agency)
      );

      setRecruiterAgencyDisable(isAgencyDisable == true);

      const isShowHideIJPDiv = event?.some(
        (eve) => eve.code === String(PostingType.WeRise)
      );

      const isJobBoardSelected = event?.some(
        (eve) => eve.code === String(PostingType.JobBoard)
      );

      setHideJobBoardOption(!isJobBoardSelected);

      //setShowHideDivForIJP(isShowHideIJPDiv == true);

      if (!isAgencyDisable) {
        setJobPostingData((prev) => {
          return {
            ...prev,
            RecruiterAgencyData: [],
          };
        });
      }

      if (!isJobBoardSelected) {
        setIsNaukriSelect(false);
        setShowLinkdinField(false);
        setJobPostingData((prev) => {
          return {
            ...prev,
            JobBoard: [],
            JobBoardPosting: [],
          };
        });
      }

      let obj = formErrors;
      delete obj["error_JobPostingOptions"];
      setFormErrors(obj);
    } catch (error) {}
  }

  async function handleSubmitForAccordian(event, item) {
    try {
      setDisableApproverBtn(true);
      event.stopPropagation();
      if (item?.IsSendback) {
        await ReInitiateWorkflow(item.Id);
      } else {
        await handleSubmitForApproverDetailsById(item.Id);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDisableApproverBtn(false);
    }
  }

  function handleChangeRecruiterForMainAccordain(event, item) {
    try {
      let RecruitersId = event
        ?.map((i) => (i?.value ? String(i?.value) : ""))
        ?.join(",");

      setMrfApproverMappingData((prevData) =>
        prevData.map((prev) =>
          item.Id === prev.Id
            ? {
                ...prev,
                Recruiter: event,
                RecruiterId: RecruitersId,
              }
            : prev
        )
      );

      let obj = formErrors;
      delete obj[`${item.Id}_Recruiter`];
      setFormErrors(obj);
    } catch (error) {
      console.error("on change recruiter error: ", error);
    }
  }

  function handleOnChangeForJobPosting(event, key, errorKey) {
    try {
      setJobPostingData((prev) => {
        return {
          ...prev,
          [key]: event,
        };
      });
      let obj = formErrors;
      delete obj[errorKey];
      setFormErrors(obj);

      switch (key) {
        case "JobBoard":
          const isnaukri = event?.some(
            (eve) => eve.code === String(JobBoardPosting.Naukri)
          );
          debugger;
          const isLinkdin = event?.some(
            (eve) =>
              String(eve.code).toLowerCase() ===
              String(JobBoardPosting.LinkedIn).toLowerCase()
          );
          setShowLinkdinField(isLinkdin);
          setIsNaukriSelect(isnaukri);
          break;

        default:
          break;
      }
    } catch (error) {
      console.error("on change job board error: ", error);
    }
  }

  async function handleFetchNaukriStatus() {
    try {
      showLoader();
      const response = await APICall(GetMRFNaukriPostedStatus, "POST", {
        MRFId: TId,
      });
      if (response?.status == 0) {
        setJobPostedOnNaukri(response?.data?.orginalStatus || false);
        setShowPublishBtn(response?.data?.isNaukriJobPosted || false);
      } else {
        setJobPostedOnNaukri(false);
      }
    } catch (error) {
      console.error("error while fetching naukri job posting status: ", error);
    } finally {
      hideLoader();
    }
  }

  function checkIfJobPostingAlreadyHappenAndJobBoardOptionIsSelectOrNot() {
    let check = false;
    try {
      if (jobPostedOnNaurki) {
        const isJobBoardSelected = jobPostingData?.PostingData?.some(
          (i) => i?.code == PostingType.JobBoard
        );
        const isNaurkriOptionSelected =
          isEmpty(jobPostingData?.JobBoard) ||
          jobPostingData?.JobBoard.some(
            (i) => i?.code == JobBoardPosting.Naukri
          );

        if (!isJobBoardSelected || !isNaurkriOptionSelected) {
          setNaukriNotSelectedModal(true);
          check = true;
          return check;
        }
      }
    } catch (error) {}
    return check;
  }

  return (
    <div className="col-lg-12">
      {mrfApproverMappingData?.length > 0 ? (
        <>
          {mrfApproverMappingData.map((item, index) => {
            return (
              <div className="mb-1" key={index}>
                {/*Job Posting*/}
                {item.SequenceNo == item.LastSequence &&
                  item.WorkflowTaskStatus == WorkflowTaskStatus.Completed &&
                  item.DecisionId == DecisionEnum.Approved &&
                  currentRoleId?.value == recruiterRoleId &&
                  item.Recruiter?.some(
                    (recruiter) => recruiter?.value == userDetails?.Id
                  ) && (
                    <>
                      <CustomApproverAccordian
                        header={"Job Posting"}
                        disabled={
                          !isMRFOpen ||
                          !(
                            item.DecisionId == DecisionEnum.Approved &&
                            currentRoleId?.value == recruiterRoleId &&
                            item.Recruiter?.some(
                              (recruiter) => recruiter?.value == userDetails?.Id
                            )
                          )
                        }
                        submitCallback={(e) => {
                          e.stopPropagation();
                          const isError = validationForJobPosting();
                          if (isError) return;

                          const checkJobPosting =
                            checkIfJobPostingAlreadyHappenAndJobBoardOptionIsSelectOrNot();
                          if (checkJobPosting) {
                            setNaukriNotSelectedModal(true);
                            return;
                          }

                          setJobPostingModal(true);
                        }}
                        clearFunc={() => {}}
                      >
                        <div className="row">
                          <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
                            <div className="form-group">
                              <label className="col-form-label">
                                Recruiter
                              </label>
                              <sup>*</sup>
                              <InputForm
                                value={jobPostingData.RecruiterName}
                                placeholder={"Recruiter"}
                                isDisabled={true}
                                textArea={false}
                                onChange={() => {}}
                              />
                            </div>
                          </div>

                          <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
                            <div className="dropdown mb-1">
                              <div className="form-group">
                                <label className="col-form-label">
                                  Posting options
                                </label>
                                <sup>*</sup>
                                <SelectForm
                                  isClearable
                                  isSearchable
                                  options={postingOptions}
                                  placeholder="Select posting options"
                                  isDisabled={jobPostingData.IsDisabled}
                                  value={jobPostingData.PostingData}
                                  onChange={handleOnChangeForPostingOptions}
                                  isMulti={true}
                                  noIndicator={false}
                                  noSeparator={false}
                                />
                                <span style={{ color: "red" }}>
                                  {formErrors["error_JobPostingOptions"]}
                                </span>

                                <div className="dropdown-content">
                                  {jobPostingData?.PostingData?.length > 1 && (
                                    <>
                                      {jobPostingData?.PostingData?.map((i) => (
                                        <p>{i?.label || ""}</p>
                                      ))}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
                            <div className="form-group">
                              <label className="col-form-label">
                                Posting from date
                              </label>
                              <sup>*</sup>
                              <ApproverDateForm
                                value={jobPostingData.PostingFromDate}
                                disabled={jobPostingData.IsDisabled}
                                onChange={(event) => {
                                  handleOnChangeForJobPosting(
                                    event,
                                    "PostingFromDate",
                                    "error_JobPostingFromDate"
                                  );
                                }}
                              />
                              <span style={{ color: "red" }}>
                                {formErrors["error_JobPostingFromDate"]}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
                            <div className="form-group">
                              <label className="col-form-label">
                                Posting till date
                              </label>
                              <sup>*</sup>
                              <ApproverDateForm
                                value={jobPostingData.PostingTillDate}
                                disabled={!isMRFOpen}
                                onChange={(event) => {
                                  handleOnChangeForJobPosting(
                                    event,
                                    "PostingTillDate",
                                    "error_JobPostingTillDate"
                                  );
                                }}
                              />
                              <span style={{ color: "red" }}>
                                {formErrors["error_JobPostingTillDate"]}
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
                            <div className="dropdown mb-1">
                              <label className="col-form-label">
                                Recruiter Agency
                              </label>
                              {recruiterAgencyDisable && <sup>*</sup>}
                              <SelectForm
                                async
                                isClearable
                                isSearchable
                                options={async (event, cb) => {
                                  await handleFetchRecruiterAgencyOptions(
                                    event,
                                    cb
                                  );
                                }}
                                placeholder="Select recruiter agency"
                                isDisabled={
                                  jobPostingData.IsDisabled ||
                                  !recruiterAgencyDisable
                                }
                                value={jobPostingData.RecruiterAgencyData}
                                onChange={(event) => {
                                  handleOnChangeForJobPosting(
                                    event,
                                    "RecruiterAgencyData",
                                    "error_JobPostingRecruiterAgency"
                                  );
                                }}
                                isMulti={true}
                                noIndicator={false}
                                noSeparator={false}
                              />
                              <span style={{ color: "red" }}>
                                {formErrors["error_JobPostingRecruiterAgency"]}
                              </span>
                              <div className="dropdown-content">
                                {jobPostingData?.RecruiterAgencyData?.length >
                                  1 && (
                                  <>
                                    {jobPostingData?.RecruiterAgencyData?.map(
                                      (i) => (
                                        <p>{i?.label || ""}</p>
                                      )
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>

                          {/* {showHideDivForIJP && (
                            <>
                              <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
                                <div className="dropdown mb-1">
                                  <div className="form-group">
                                    <label className="col-form-label">
                                      Target Job Level
                                    </label>
                                    <sup>*</sup>
                                    <SelectForm
                                      isClearable
                                      isSearchable
                                      options={JoblevelOptions}
                                      placeholder="Select Job Level"
                                      isDisabled={jobPostingData.IsDisabled}
                                      value={jobPostingData.JobLevel}
                                      onChange={(event) => {
                                        handleOnChangeForJobPosting(
                                          event,
                                          "JobLevel",
                                          "error_JobLevel"
                                        );
                                      }}
                                      isMulti={true}
                                      noIndicator={false}
                                      noSeparator={false}
                                    />
                                    <span style={{ color: "red" }}>
                                      {formErrors["error_JobLevel"]}
                                    </span>
                                    <div className="dropdown-content">
                                      {jobPostingData?.JobLevel?.length > 1 && (
                                        <>
                                          {jobPostingData?.JobLevel?.map(
                                            (i) => (
                                              <p>{i?.label || ""}</p>
                                            )
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )} */}

                          {!hideJobBoardOption && (
                            <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
                              <div className="form-group">
                                <label className="col-form-label">
                                  Job Board Type
                                </label>
                                <sup>*</sup>
                                <SelectForm
                                  isClearable
                                  isSearchable
                                  options={jobBoardOptions}
                                  placeholder="Select Job Board Type"
                                  isDisabled={jobPostingData.IsDisabled}
                                  value={jobPostingData.JobBoard}
                                  onChange={(event) => {
                                    handleOnChangeForJobPosting(
                                      event,
                                      "JobBoard",
                                      "error_JobboardOption"
                                    );
                                  }}
                                  isMulti={true}
                                  noIndicator={false}
                                  noSeparator={false}
                                />
                                <span style={{ color: "red" }}>
                                  {formErrors["error_JobboardOption"]}
                                </span>
                              </div>
                            </div>
                          )}

                          {showLinkdinField && (
                            <>
                              <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
                                <div className="form-group">
                                  <label className="col-form-label">
                                    LinkedIn Common HashTag
                                  </label>
                                  <sup>*</sup>
                                  <SelectForm
                                    isClearable
                                    isSearchable
                                    options={linkedinOptions}
                                    placeholder="Select LinkedIn Common Hashtag"
                                    isDisabled={jobPostingData.IsDisabled}
                                    value={jobPostingData.LinkedInCommonHashtag}
                                    onChange={(event) => {
                                      handleOnChangeForJobPosting(
                                        event,
                                        "LinkedInCommonHashtag",
                                        "error_LinkedInCommonHashtag"
                                      );
                                    }}
                                    isMulti={true}
                                    noIndicator={false}
                                    noSeparator={false}
                                  />
                                  <span style={{ color: "red" }}>
                                    {formErrors["error_LinkedInCommonHashtag"]}
                                  </span>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
                                <div className="form-group">
                                  <label className="col-form-label">
                                    Recruiter HashTag <sup>*</sup>
                                  </label>
                                  <InputForm
                                    value={jobPostingData.RecruiterHashTag}
                                    placeholder={"Recruiter HashTag"}
                                    isDisabled={jobPostingData.IsDisabled}
                                    textArea={false}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      setJobPostingData((prev) => {
                                        return {
                                          ...prev,
                                          RecruiterHashTag: value,
                                        };
                                      });
                                    }}
                                  />
                                  <span style={{ color: "red" }}>
                                    {formErrors["error_RecruiterHashTag"]}
                                  </span>
                                </div>
                              </div>
                            </>
                          )}

                          {isNaukriSelected && (
                            <>
                              <>
                                <div
                                  style={{
                                    color: "#cccccc",
                                    backgroundColor: "#cccccc",
                                    height: 1,
                                    marginTop: 10,
                                  }}
                                  className="col-lg-12 col-sm-12 col-xs-12 top-m"
                                />

                                <NaukriColumns
                                  data={naukriFormDetails}
                                  setData={setNaukriFormDetails}
                                  disabled={jobPostingData.IsDisabled || false}
                                  formErrors={naukriColumnsError}
                                  setFormErrors={setNaukriColumnError}
                                  MRFId={TId}
                                  postingStatus={showPublishBtn}
                                  key={`naukri_${refreshKey}`}
                                  setRefreshKey={setRefreshKey}
                                  isDepublished={jobPostedOnNaurki}
                                />
                              </>
                            </>
                          )}
                        </div>
                      </CustomApproverAccordian>
                    </>
                  )}

                {/* Approver Main */}
                <CustomApproverAccordian
                  header={
                    item.SequenceNo == 1
                      ? `Hiring Manager`
                      : item.SequenceNo == item.LastSequence
                      ? `TA Head`
                      : `Ad Hoc Approver`
                  }
                  disabled={
                    disableApproverBtn
                      ? disableApproverBtn
                      : item?.IsSendback &&
                        item?.IntiatorId == userDetails?.Id &&
                        currentRoleId?.value == buHR
                      ? false
                      : item.IsDisabled
                  }
                  submitCallback={async (event) =>
                    await handleSubmitForAccordian(event, item)
                  }
                  clearFunc={(e) => handleClearForm(e, item.Id)}
                >
                  <>
                    <div className="row">
                      <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
                        <div className="form-group">
                          <label className="col-form-label">Approver</label>
                          <InputForm
                            value={item.ApproverName}
                            placeholder={"Approver"}
                            isDisabled={true}
                            textArea={false}
                            onChange={() => {}}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
                        <div className="form-group">
                          <label className="col-form-label">Role</label>
                          <sup>*</sup>
                          <InputForm
                            value={item.RoleName}
                            placeholder={"Role"}
                            isDisabled={true}
                            textArea={false}
                            onChange={() => {}}
                          />
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
                        <div className="form-group">
                          <label className="col-form-label">Date</label>
                          <sup>*</sup>
                          <ApproverDateForm
                            value={item.Date}
                            onChange={(event) => {
                              setMrfApproverMappingData((prevData) =>
                                prevData.map((prev) =>
                                  item.Id === prev.Id
                                    ? { ...prev, Date: event }
                                    : prev
                                )
                              );
                            }}
                            disabled={true}
                          />
                        </div>
                        <span style={{ color: "red" }}>
                          {formErrors[`${item.Id}_Date`]}
                        </span>
                      </div>

                      <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
                        <div className="form-group">
                          <label className="col-form-label">Decision</label>
                          <sup>*</sup>
                          <SelectForm
                            options={DECISION}
                            value={item.Decision}
                            isDisabled={item.IsDisabled}
                            isClearable={true}
                            onChange={(event) => {
                              handleDecisionEventChange(event, item.Id);
                              let obj = formErrors;
                              delete obj[`${item.Id}_Decision`];
                              setFormErrors(obj);
                            }}
                            placeholder={"Select Decision"}
                          />
                        </div>
                        <span style={{ color: "red" }}>
                          {formErrors[`${item.Id}_Decision`]}
                        </span>
                      </div>

                      {item.SequenceNo === item.LastSequence && (
                        <>
                          <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
                            <div className="dropdown mb-1">
                              <label className="col-form-label">
                                Recruiter
                              </label>
                              {!recruiterDisabled && <sup>*</sup>}
                              <SelectForm
                                async
                                isClearable
                                isSearchable
                                options={(event, cb) =>
                                  handleFetchRecruiterOptions(event, cb)
                                }
                                placeholder="Select Recruiter"
                                isDisabled={
                                  recruiterDisabled || item.IsDisabled
                                }
                                value={item.Recruiter}
                                onChange={(event) => {
                                  handleChangeRecruiterForMainAccordain(
                                    event,
                                    item
                                  );
                                }}
                                isMulti={true}
                                noIndicator={false}
                                noSeparator={false}
                              />
                              {item?.Recruiter?.length > 1 && (
                                <>
                                  <div className="dropdown-content">
                                    {item?.Recruiter?.map((i) => (
                                      <p>{i?.label}</p>
                                    ))}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      )}

                      <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
                        <div className="form-group">
                          <label className="col-form-label">
                            Remarks
                            <span style={{ color: "red" }}>*</span>
                          </label>

                          <InputForm
                            value={item.Remarks}
                            placeholder={"Remarks"}
                            isDisabled={item.IsDisabled}
                            textArea={true}
                            onChange={(event) => {
                              setMrfApproverMappingData((prevData) =>
                                prevData.map((prev) =>
                                  item.Id === prev.Id
                                    ? { ...prev, Remarks: event.target.value }
                                    : prev
                                )
                              );
                            }}
                          />

                          <span style={{ color: "red" }}>
                            {formErrors[`${item.Id}_Remarks`]}
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={item.IsDisabled || item.Acknowledgement}
                            onChange={(event) => {
                              setMrfApproverMappingData((prevData) =>
                                prevData.map((prev) =>
                                  item.Id === prev.Id
                                    ? {
                                        ...prev,
                                        Acknowledgement: event.target.checked,
                                      }
                                    : prev
                                )
                              );
                              let obj = formErrors;
                              delete obj[`checkbox_${item.Id}`];
                              setFormErrors(obj);
                            }}
                            disabled={item.IsDisabled}
                            id={`checkbox_${item.Id}`}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`checkbox_${item.Id}`}
                          >
                            {item?.SequenceNo == 1 ? (
                              <>
                                I Acknowledge that this MRF has been reviewed by
                                me and it is as per our requirements.
                              </>
                            ) : item?.SequenceNo == item?.LastSequence ? (
                              <>
                                {mrfApproverMappingData?.length > 2 ? (
                                  <>
                                    I Acknowledge that this MRF has been
                                    reviewed by me and it is agreed by Hiring
                                    Manager and Ad HOC approver to hire.
                                  </>
                                ) : (
                                  <>
                                    I Acknowledge that this MRF has been
                                    reviewed by me and it is agreed by Hiring
                                    Manager to hire.
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                I Acknowledge that this MRF has been reviewed by
                                me and it is agreed by Hiring Manager to hire.
                              </>
                            )}

                            <span style={{ color: "red" }}>*</span>
                          </label>
                        </div>
                        <span style={{ color: "red" }}>
                          {formErrors[`${item.Id}_Acknowledgement`]}
                        </span>
                      </div>

                      {item?.IsSendback && currentRoleId?.value === buHR && (
                        <>
                          <div className="col-lg-6 col-md-6 col-sm-12 mobile-view top-m mt-2">
                            <div className="form-group">
                              Initiator Remarks
                              <span style={{ color: "red" }}>*</span>
                            </div>
                            <InputForm
                              value={initiatorRemarks}
                              placeholder={"Initiator Remarks"}
                              isDisabled={false}
                              textArea={true}
                              rows={7}
                              onChange={(e) => {
                                setInitiatorRemarks(e?.target?.value);
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </>
                </CustomApproverAccordian>
              </div>
            );
          })}
        </>
      ) : (
        <>
          <h2>
            {state?.IsMRFAdmin
              ? "No data found"
              : "Currently there are no task for you !"}
          </h2>
        </>
      )}

      {/* Previous Conversation  */}
      <div className="mt-2">
        <PreviousConversionTable data={prevConvArr} />
      </div>

      {/* Job Posting Confirmation Modal  */}
      <ConfirmationModal
        modal={jobPostingModal}
        setModal={setJobPostingModal}
        confirmCallback={async () => {
          setJobPostingModal(false);
          await handleSubmitForJobPosting();
        }}
        noCallback={() => setJobPostingModal(false)}
        message={"Are you sure you want to submit?"}
      />

      {/* naukri Not Selected Modal when job posting is already done.  */}
      <ConfirmationModal
        modal={naukriNotSelectedModal}
        setModal={setNaukriNotSelectedModal}
        confirmCallback={async () => {
          setNaukriNotSelectedModal(false);
          setJobPostingModal(true);
        }}
        noCallback={() => setNaukriNotSelectedModal(false)}
        message={
          "Naukri Job is already posted, and naukri options is not selected in job posting options, Job in naukri will be depublised?"
        }
        hideConfirmMation={true}
      />
    </div>
  );
};
export default ApproverSection;

const mapDataItem = ({
  item,
  userDetails,
  currentRoleId,
  buHR,
  isMRFOpen,
  state,
  sendback,
}) => {
  let IsDisabled = false;

  if (
    item.ApproverId != userDetails.Id ||
    currentRoleId?.value == buHR ||
    sendback
  ) {
    IsDisabled = true;
  } else if (item.WorkflowTaskStatus == WorkflowTaskStatus.Completed) {
    IsDisabled = true;
  } else {
    IsDisabled = false;
  }

  if (state?.IsMRFAdmin) {
    IsDisabled = true;
  }

  if (!isMRFOpen) {
    IsDisabled = true;
  }

  const DecisionObj = item.Decision
    ? DECISION.find((dec) => dec.value === item.Decision)
    : null;

  let str = "";
  if (item.PreviousConversionJson && item.PreviousConversionJson?.length > 0) {
    const previousConversionArr = JSON.parse(item.PreviousConversionJson);
    if (previousConversionArr && previousConversionArr?.length > 0) {
      previousConversionArr.map((i) => {
        const Date = i.CreatedDateUtc
          ? moment.utc(i.CreatedDateUtc).local().format("DD-MMM-YYYY hh:mm A")
          : "";
        const ApproverName = i.UserName || "";
        const RoleName = i.RoleName || "";
        const Remarks = i.Remarks || "";
        const Decision = i.Decision?.length > 0 ? `(${i.Decision})` : "";

        if (Remarks?.trim()?.length > 0) {
          str += `${Date} ${ApproverName} ${RoleName} ${Decision} \n`;
          str += `\t\t${Remarks}\n\n`;
        }
      });
    }
  }

  let RecruiterSelectedOption = [];
  if (
    item.RecruiterSelectedOption &&
    item.RecruiterSelectedOption?.length > 0
  ) {
    const recruiterSelectedOption = JSON.parse(item.RecruiterSelectedOption);
    RecruiterSelectedOption = recruiterSelectedOption;
  }

  return {
    Id: item.Id,
    IntiatorId: item.IntiatorId,
    IntiatorName: item.IntiatorName,
    ApproverId: item.ApproverId,
    ApproverName: item.ApproverName,
    RoleId: item.RoleId,
    RoleName: item.RoleName,
    SequenceNo: item.SequenceNo,
    DecisionId: item.Decision,
    RecruiterId: item.RecruiterId || null,
    RecruiterName: item.RecruiterName || "",
    Remarks: item.Remarks || "",
    Accordian: false,
    Decision: DecisionObj,
    Recruiter: RecruiterSelectedOption,
    Date: item.Date
      ? moment
          .utc(item.Date, "YYYY-MM-DD HH:mm:ss.SSS")
          .local()
          .format("DD-MMM-YYYY")
      : moment.utc().local().format("DD-MMM-YYYY"),
    IsDisabled: IsDisabled,
    WorkflowId: item.WorkflowId,
    WorkflowTaskId: item.WorkflowTaskId,
    RequestId: item.RequestId,
    LastSequence: item.LastSequence,
    MRFApproverDetailsId: item.MRFApproverDetailsId,
    WorkflowTaskStatus: item.WorkflowTaskStatus,
    PreviousRemarks: str,
    PostingFromDate: item.PostingFromDate,
    PostingTillDate: item.PostingTillDate,
    RecruiterSelectedOption,
    prevConv: item.PreviousConversionJson,
    PreviousConversationForInitiator: item?.PreviousConversationForInitiator,
    Acknowledgement: false,
  };
};

const parseJSON = (jsonString) => {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    return null;
  }
};

function GetPreviousConversation(conv) {
  try {
    let arr = conv.map((i) => {
      const Date = i.CreatedDateUtc
        ? moment.utc(i.CreatedDateUtc).local().format("DD-MMM-YYYY hh:mm A")
        : "";
      const ApproverName = i.UserName || "";
      const RoleName = i.RoleName || "";
      const Remarks = i.Remarks || "";
      const Decision = i.Decision?.length > 0 ? i.Decision : "";

      return {
        Date,
        ApproverName,
        RoleName,
        Remarks,
        Decision,
      };
    });
    arr = arr?.filter((i) => i?.Remarks?.trim()?.length > 0);
    return arr;
  } catch (error) {
    console.error(error);
    return [];
  }
}
