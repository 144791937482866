import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import InputForm from "../../Components/InputForm/InputForm";
import SelectForm from "../../Components/SelectForm/SelectForm";
import { APICall } from "../../Helpers/APICalls";
import { getDropdowns } from "../../Helpers/APIEndPoints/EndPoints";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { LoaderContext } from "../../Helpers/Context/Context";
import notify from "../../Helpers/ToastNotification";
import {
  GetCommonDropdownForScheduling,
  InsertUpdateLMSDomain,
  getEmployeeDropDownByIsDomain,
  getLMSDomainData,
  getUserRoleMappingData,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import { useLocation, useNavigate } from "react-router-dom";
import { fromData } from "../../Components/FromStructure/formData";
import { env } from "process";

interface IOptions {
  label?: string;
  value?: number | string;
}

interface IFormData {
  employeeDetail?: IOptions[] | string | any;
  business?: IOptions[] | string | any;
  sbu?: IOptions[] | string | any;
  legalEntity?: IOptions[] | string | any;
  division?: IOptions[] | string | any;
  function?: IOptions[] | string | any;
  department?: IOptions[] | string | any;
  section?: IOptions[] | string | any;
  employeeClass?: IOptions[] | string | any;
  jobBand?: IOptions[] | string | any;
  joblevel?: IOptions[] | string | any;
  standardDesignation?: IOptions[] | string | any;
  customBusiness?: IOptions[] | string | any;
  physicalLocation?: IOptions[] | string | any;
  payrollLocation?: IOptions[] | string | any;
  country?: IOptions[] | string | any;
}

const Domain = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isDisable, setIsDisable] = useState(
    state && state?.isDisable ? true : false
  );
  const { userDetails, currentRoleId }: any = useUserContext();

  const { showLoader, hideLoader } = useContext(LoaderContext);

  const [domainName, setDomainName] = useState("");
  const [domainId, setDomainId] = useState("");

  const [isDomain, setIsDomain] = useState(true);

  const [formErrors, setFormErrors] = useState<any>({});

  const [options, setOptions] = useState<IFormData>({});

  const [formData, setFormData] = useState<IFormData>({});

  const [userRoleMappingData, setUserRoleMappingData] = useState(null);

  const [adminOptions, setAdminOptions] = useState([]);

  useEffect(() => {
    (async () => {
      const userRoleMappingData = await getUserRoleMappingDataApiCall();

      await getEmployeeDropDownByIsDomainApiCall();

      await getAllDropdownData(userRoleMappingData);
    })();
  }, []);

  // useEffect(() => {
  //   if (options?.business?.length > 0) {
  //     debugger;
  //     if (state && state?.domainId > 0) {
  //       GetLMSDomainDataApiCall();
  //     }
  //   }
  // }, [options]);

  //Call api for all Dropdown
  const getDDData = async (AC, PID) => {
    const dropdowndataResponse = await APICall(getDropdowns, "POST", {
      AC,
      PID,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });
    const dropdownData = dropdowndataResponse.data;

    return dropdownData;
  };

  const getUserRoleMappingDataApiCall = async () => {
    debugger;
    const response = await APICall(getUserRoleMappingData, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    debugger;

    if (response?.status === 0) {
      let domainOUDetails = response?.data?.ouDetails;
      if (domainOUDetails !== null) {
        setUserRoleMappingData(JSON.parse(domainOUDetails));
        return JSON.parse(domainOUDetails);
      } else {
        return null;
      }
    }
  };

  // const handleSearchForAsyncDropdown = async (event, callback) => {
  //   try {
  //     if (event?.length >= 3) {
  //       const data = await fetchDropdown(event);
  //       callback(data);
  //     } else {
  //       callback([]);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getEmployeeDropDownByIsDomainApiCall = async () => {
    try {
      const response = await APICall(getEmployeeDropDownByIsDomain, "POST", {
        // SearchText: searchText,
        // DropdownValues: value,
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      });

      if (response?.status === 0 && response?.data?.length > 0) {
        setAdminOptions(response?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const getAllDropdownData = async (userRoleMappingData) => {
    if (userRoleMappingData) {
      let businessOptions = await getDDData("BISP", "");
      if (userRoleMappingData?.BIS.length > 0) {
        businessOptions = businessOptions?.map((option) => {
          let foundOption = userRoleMappingData?.BIS?.find(
            (env) => env === option?.value
          );
          if (foundOption !== undefined) {
            return { ...option, isDisable: false };
          } else {
            return { ...option, isDisable: true };
          }
        });
      }

      let sbuOptions = await getDDData("SBUP", "");

      if (userRoleMappingData?.SBU.length > 0) {
        sbuOptions = sbuOptions?.map((option) => {
          let foundOption = userRoleMappingData?.SBU?.find(
            (env) => env === option?.value
          );
          if (foundOption !== undefined) {
            return { ...option, isDisable: false };
          } else {
            return { ...option, isDisable: true };
          }
        });
      }

      let legalEntityOptions = await getDDData("LENP", "");

      if (userRoleMappingData?.LEN.length > 0) {
        legalEntityOptions = legalEntityOptions?.map((option) => {
          let foundOption = userRoleMappingData?.LEN?.find(
            (env) => env === option?.value
          );
          if (foundOption !== undefined) {
            return { ...option, isDisable: false };
          } else {
            return { ...option, isDisable: true };
          }
        });
      }

      let divisionOptions = await getDDData("DV", "");

      if (userRoleMappingData?.DV.length > 0) {
        divisionOptions = divisionOptions?.map((option) => {
          let foundOption = userRoleMappingData?.DV?.find(
            (env) => env === option?.value
          );
          if (foundOption !== undefined) {
            return { ...option, isDisable: false };
          } else {
            return { ...option, isDisable: true };
          }
        });
      }

      let functionOptions = await getDDData("FUNC", "");

      if (userRoleMappingData?.FUNC.length > 0) {
        functionOptions = functionOptions?.map((option) => {
          let foundOption = userRoleMappingData?.FUNC?.find(
            (env) => env === option?.value
          );
          if (foundOption !== undefined) {
            return { ...option, isDisable: false };
          } else {
            return { ...option, isDisable: true };
          }
        });
      }

      let departmentOptions = await getDDData("DPT", "");

      if (userRoleMappingData?.DPT.length > 0) {
        departmentOptions = departmentOptions?.map((option) => {
          let foundOption = userRoleMappingData?.DPT?.find(
            (env) => env === option?.value
          );
          if (foundOption !== undefined) {
            return { ...option, isDisable: false };
          } else {
            return { ...option, isDisable: true };
          }
        });
      }

      let sectionOptions = await getDDData("SCN", "");

      if (userRoleMappingData?.SCN.length > 0) {
        sectionOptions = sectionOptions?.map((option) => {
          let foundOption = userRoleMappingData?.SCN?.find(
            (env) => env === option?.value
          );
          if (foundOption !== undefined) {
            return { ...option, isDisable: false };
          } else {
            return { ...option, isDisable: true };
          }
        });
      }

      let employeeClassOptions = await getDDData("EC", "");

      if (userRoleMappingData?.EC.length > 0) {
        employeeClassOptions = employeeClassOptions?.map((option) => {
          let foundOption = userRoleMappingData?.EC?.find(
            (env) => env === option?.value
          );
          if (foundOption !== undefined) {
            return { ...option, isDisable: false };
          } else {
            return { ...option, isDisable: true };
          }
        });
      }

      let jobBandOptions = await getDDData("JB", "");

      if (userRoleMappingData?.JB.length > 0) {
        jobBandOptions = jobBandOptions?.map((option) => {
          let foundOption = userRoleMappingData?.JB?.find(
            (env) => env === option?.value
          );
          if (foundOption !== undefined) {
            return { ...option, isDisable: false };
          } else {
            return { ...option, isDisable: true };
          }
        });
      }

      let joblevelOptions = await getDDData("JL", "");

      if (userRoleMappingData?.JL.length > 0) {
        joblevelOptions = joblevelOptions?.map((option) => {
          let foundOption = userRoleMappingData?.JL?.find(
            (env) => env === option?.value
          );
          if (foundOption !== undefined) {
            return { ...option, isDisable: false };
          } else {
            return { ...option, isDisable: true };
          }
        });
      }

      let standardDesignationOptions = await getDDData("SD", "");

      if (userRoleMappingData?.SD.length > 0) {
        standardDesignationOptions = standardDesignationOptions?.map(
          (option) => {
            let foundOption = userRoleMappingData?.SD?.find(
              (env) => env === option?.value
            );
            if (foundOption !== undefined) {
              return { ...option, isDisable: false };
            } else {
              return { ...option, isDisable: true };
            }
          }
        );
      }

      let physicalLocationOptions = await getDDData("PHLOC", "");

      if (userRoleMappingData?.PHLOC.length > 0) {
        physicalLocationOptions = physicalLocationOptions?.map((option) => {
          let foundOption = userRoleMappingData?.PHLOC?.find(
            (env) => env === option?.value
          );
          if (foundOption !== undefined) {
            return { ...option, isDisable: false };
          } else {
            return { ...option, isDisable: true };
          }
        });
      }

      let payrollLocationOptions = await getDDData("PLOC", "");

      if (userRoleMappingData?.PLOC.length > 0) {
        payrollLocationOptions = payrollLocationOptions?.map((option) => {
          let foundOption = userRoleMappingData?.PLOC?.find(
            (env) => env === option?.value
          );
          if (foundOption !== undefined) {
            return { ...option, isDisable: false };
          } else {
            return { ...option, isDisable: true };
          }
        });
      }

      const OptionValue = await getDDData("COU", "");
      let countryOptions = OptionValue.filter((option) =>
        ["India", "United Kingdom", "United States"].includes(option.label)
      );

      if (userRoleMappingData?.COU.length > 0) {
        countryOptions = countryOptions?.map((option) => {
          let foundOption = userRoleMappingData?.COU?.find(
            (env) => env === option?.value
          );
          if (foundOption !== undefined) {
            return { ...option, isDisable: false };
          } else {
            return { ...option, isDisable: true };
          }
        });
      }

      setOptions((env) => {
        return {
          ...env,
          business: businessOptions,
          sbu: sbuOptions,
          legalEntity: legalEntityOptions,
          division: divisionOptions,
          function: functionOptions,
          department: departmentOptions,
          section: sectionOptions,
          employeeClass: employeeClassOptions,
          jobBand: jobBandOptions,
          joblevel: joblevelOptions,
          standardDesignation: standardDesignationOptions,
          physicalLocation: physicalLocationOptions,
          payrollLocation: payrollLocationOptions,
          country: countryOptions,
        };
      });
    }
  };

  const formOnChange = async (event, DDName) => {
    showLoader();

    if (DDName === "employeeDetail") {
      setFormData((env) => {
        return {
          ...env,
          employeeDetail: event,
        };
      });
    }
    if (DDName === "business") {
      setFormData((env) => {
        return {
          ...env,
          business: event,
          sbu: null,
        };
      });

      const commaSeparatedBISIds =
        event && event.length > 0 && event?.map((e) => e.value).join(",");
      if (commaSeparatedBISIds) {
        let sbuData = await getDDData("SBU", commaSeparatedBISIds);
        setOptions((env) => {
          return {
            ...env,
            sbu: sbuData,
          };
        });
      }
    }

    if (DDName === "sbu") {
      setFormData((env) => {
        return {
          ...env,
          sbu: event,
          legalEntity: null,
        };
      });

      const commaSeparatedSBUIds =
        event && event.length > 0 && event?.map((e) => e.value).join(",");
      if (commaSeparatedSBUIds) {
        let LENData = await getDDData("LEN", commaSeparatedSBUIds);
        setOptions((env) => {
          return {
            ...env,
            legalEntity: LENData,
          };
        });
      }
    }

    if (DDName === "legalEntity") {
      setFormData((env) => {
        return {
          ...env,
          legalEntity: event,
          division: null,
          employeeClass: null,
        };
      });

      let BIS = formData?.business?.map((e) => e.value).join(",");
      let SBU = formData?.sbu?.map((e) => e.value).join(",");
      let LEN = event?.map((e) => e.value).join(",");

      let PId = `${BIS}-${SBU}-${LEN}`;
      debugger;
      if (PId) {
        const customBusinessData = await getDDData("CUSBIS", PId);
        const commaSeparatedLegalEntityIds =
          event && event.length > 0 && event?.map((e) => e.value).join(",");

        let DVData = await getDDData("DV", commaSeparatedLegalEntityIds);

        let ECData = await getDDData("EC", commaSeparatedLegalEntityIds);

        let PayLocData = await getDDData("PLOC", commaSeparatedLegalEntityIds);

        setOptions((env) => {
          return {
            ...env,
            customBusiness: customBusinessData,
            division: DVData,
            employeeClass: ECData,
            payrollLocation: PayLocData,
          };
        });
      }
    }

    if (DDName === "division") {
      setFormData((env) => {
        return {
          ...env,
          division: event,
          function: null,
        };
      });

      const commaSeparatedDVIds =
        event && event.length > 0 && event?.map((e) => e.value).join(",");
      if (commaSeparatedDVIds) {
        let FUNCData = await getDDData("FUNC", commaSeparatedDVIds);
        setOptions((env) => {
          return {
            ...env,
            function: FUNCData,
          };
        });
      }
    }

    if (DDName === "function") {
      setFormData((env) => {
        return {
          ...env,
          function: event,
          department: null,
        };
      });

      const commaSeparatedFUNCIds =
        event && event.length > 0 && event?.map((e) => e.value).join(",");
      if (commaSeparatedFUNCIds) {
        let DeptData = await getDDData("DPT", commaSeparatedFUNCIds);
        setOptions((env) => {
          return {
            ...env,
            department: DeptData,
          };
        });
      }
    }

    if (DDName === "employeeClass") {
      setFormData((env) => {
        return {
          ...env,
          employeeClass: event,
          jobBand: null,
        };
      });

      let LEN = formData?.legalEntity?.map((e) => e.value).join(",");
      let EC =
        event && event.length > 0 && event?.map((e) => e.value).join(",");

      let PID = `${LEN},${EC}`;

      if (PID) {
        const JBData = await getDDData("JB", PID);
        setOptions((env) => {
          return {
            ...env,
            jobBand: JBData,
          };
        });
      }
    }

    if (DDName === "department") {
      setFormData((env) => {
        return {
          ...env,
          department: event,
          section: null,
        };
      });

      const commaSeparatedDPTIds =
        event && event.length > 0 && event?.map((e) => e.value).join(",");
      if (commaSeparatedDPTIds) {
        let SecData = await getDDData("SEC", commaSeparatedDPTIds);
        setOptions((env) => {
          return {
            ...env,
            section: SecData,
          };
        });
      }
    }

    if (DDName === "section") {
      setFormData((env) => {
        return {
          ...env,
          section: event,
        };
      });

      // const commaSeparatedDPTIds =
      //   event && event.length > 0 && event?.map((e) => e.value).join(",");
      // if (commaSeparatedDPTIds) {
      //   let SecData = await getDDData("SEC", commaSeparatedDPTIds);
      //   setOptions((env) => {
      //     return {
      //       ...env,
      //       section: SecData,
      //     };
      //   });
      // }
    }

    if (DDName === "jobBand") {
      setFormData((env) => {
        return {
          ...env,
          jobBand: event,
          joblevel: null,
        };
      });

      const commaSeparatedJBIds =
        event && event.length > 0 && event?.map((e) => e.value).join(",");
      if (commaSeparatedJBIds) {
        let JLData = await getDDData("JL", commaSeparatedJBIds);
        setOptions((env) => {
          return {
            ...env,
            joblevel: JLData,
          };
        });
      }
    }

    if (DDName === "joblevel") {
      setFormData((env) => {
        return {
          ...env,
          joblevel: event,
          standardDesignation: null,
        };
      });

      const commaSeparatedJLIds =
        event && event.length > 0 && event?.map((e) => e.value).join(",");
      if (commaSeparatedJLIds) {
        let SDData = await getDDData("SD", commaSeparatedJLIds);
        setOptions((env) => {
          return {
            ...env,
            standardDesignation: SDData,
          };
        });
      }
    }

    if (DDName === "standardDesignation") {
      setFormData((env) => {
        return {
          ...env,
          standardDesignation: event,
        };
      });
    }

    if (DDName === "customBusiness") {
      setFormData((env) => {
        return {
          ...env,
          customBusiness: event,
        };
      });
    }

    if (DDName === "payrollLocation") {
      setFormData((env) => {
        return {
          ...env,
          payrollLocation: event,
        };
      });
    }

    if (DDName === "physicalLocation") {
      setFormData((env) => {
        return {
          ...env,
          physicalLocation: event,
        };
      });
    }

    if (DDName === "country") {
      setFormData((env) => {
        return {
          ...env,
          country: event,
        };
      });
    }

    hideLoader();
  };

  const validateForm = () => {
    debugger;
    let errorObj = {};
    let isError = false;

    if (domainName === "" || domainName === null) {
      errorObj = {
        ...errorObj,
        ["domainName"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["domainName"]: "",
      };
    }

    if (isDomain) {
      if (
        formData?.employeeDetail === "" ||
        formData?.employeeDetail === null ||
        formData?.employeeDetail === undefined ||
        formData?.employeeDetail?.length === 0
      ) {
        errorObj = {
          ...errorObj,
          ["employeeDetail"]: "Required",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["employeeDetail"]: "",
        };
      }
    }

    const isEmpty = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  const handleSubmitButton = async () => {
    const isValid = validateForm();

    if (!isValid) {
      const responseParam = {
        Id: state?.domainId,
        DomainName: domainName,
        IsPublic: !isDomain,
        AdminIds: isDomain
          ? formData?.employeeDetail?.map((s) => s.value).join(",")
          : null,
        DomainDetails: JSON.stringify({
          BIS: formData?.business
            ? [...formData?.business?.map((e) => e.value)]
            : [],
          SBU: formData?.sbu ? [...formData?.sbu?.map((e) => e.value)] : [],
          LEN: formData?.legalEntity
            ? [...formData?.legalEntity?.map((e) => e.value)]
            : [],
          DV: formData?.division
            ? [...formData?.division?.map((e) => e.value)]
            : [],
          COU: formData?.country
            ? [...formData?.country?.map((e) => e.value)]
            : [],
          PHLOC: formData?.physicalLocation
            ? [...formData?.physicalLocation?.map((e) => e.value)]
            : [],
          CUSBIS: formData?.customBusiness
            ? [...formData?.customBusiness?.map((e) => e.value)]
            : [],
          DPT: formData?.department
            ? [...formData?.department?.map((e) => e.value)]
            : [],
          SCN: formData?.section
            ? [...formData?.section?.map((e) => e.value)]
            : [],
          EC: formData?.employeeClass
            ? [...formData?.employeeClass?.map((e) => e.value)]
            : [],
          JB: formData?.jobBand
            ? [...formData?.jobBand?.map((e) => e.value)]
            : [],
          JL: formData?.joblevel
            ? [...formData?.joblevel?.map((e) => e.value)]
            : [],
          SD: formData?.standardDesignation
            ? [...formData?.standardDesignation?.map((e) => e.value)]
            : [],
          PLOC: formData?.payrollLocation
            ? [...formData?.payrollLocation?.map((e) => e.value)]
            : [],
          FUNC: formData?.function
            ? [...formData?.function?.map((e) => e.value)]
            : [],
        }),

        CreatedBy: userDetails?.Id,
      };
      const response = await APICall(
        InsertUpdateLMSDomain,
        "POST",
        responseParam
      );
      if (response?.status === 0) {
        notify(response?.status, response?.message);
        resetFunction();
        navigate("/domainDashboard");
      } else if (response?.status === 1) {
        notify(response?.status, response?.message);
      }
    }
  };

  const resetFunction = () => {
    setFormErrors({});

    setFormData((env) => {
      return {
        business: null,
        sbu: null,
        legalEntity: null,
        division: null,
        function: null,
        department: null,
        section: null,
        employeeClass: null,
        jobBand: null,
        joblevel: null,
        standardDesignation: null,
        physicalLocation: null,
        payrollLocation: null,
        country: null,
        customBusiness: null,
      };
    });
  };

  const [isOnce, setIsOnce] = useState(true);

  // useEffect(() => {
  //   debugger;
  //   if (
  //     isOnce &&
  //     options?.business?.length > 0 &&
  //     options?.sbu?.length > 0 &&
  //     options?.legalEntity?.length > 0 &&
  //     options?.division?.length > 0 &&
  //     options?.function?.length > 0 &&
  //     options?.department?.length > 0 &&
  //     options?.section?.length > 0 &&
  //     options?.employeeClass?.length > 0 &&
  //     options?.jobBand?.length > 0 &&
  //     options?.joblevel?.length > 0 &&
  //     options?.physicalLocation?.length > 0 &&
  //     options?.payrollLocation?.length > 0 &&
  //     options?.standardDesignation?.length > 0 &&
  //     options?.country?.length > 0 &&
  //     domainDetails
  //   ) {
  //     const domainArray = [
  //       "BIS",
  //       "COU",
  //       "CUSBIS",
  //       "DPT",
  //       "DV",
  //       "EC",
  //       "FUNC",
  //       "JB",
  //       "JL",
  //       "LEN",
  //       "PHLOC",
  //       "PLOC",
  //       "SBU",
  //       "SCN",
  //       "SD",
  //     ];

  //     const domainDetail = {
  //       BIS: "business",
  //       SBU: "sbu",
  //       LEN: "legalEntity",
  //       DV: "division",
  //       FUNC: "function",
  //       DPT: "department",
  //       SCN: "section",
  //       EC: "employeeClass",
  //       JB: "jobBand",
  //       JL: "joblevel",
  //       SD: "standardDesignation",
  //       PHLOC: "physicalLocation",
  //       PLOC: "payrollLocation",
  //       COU: "country",
  //       CUSBIS: "customBusiness",
  //     };

  //     domainArray?.map((item) => {
  //       const selectedData = [];

  //       const optionName = domainDetail[item];
  //       if (domainDetails[item].length > 0) {
  //         const selectedArray = domainDetails[item];

  //         selectedArray.map((value) => {
  //           const data = options?.[optionName]?.find(
  //             (option) => option.value == value
  //           );
  //           selectedData.push(data);
  //         });

  //         setFormData((env) => {
  //           return {
  //             ...env,
  //             [optionName]: selectedData,
  //           };
  //         });
  //       } else {
  //         setFormData((env) => {
  //           return {
  //             ...env,
  //             [optionName]: null,
  //           };
  //         });
  //       }
  //     });
  //     setIsOnce(false);
  //   }
  // }, [options, domainDetails]);

  useEffect(() => {
    if (
      adminOptions?.length > 0 &&
      options?.business?.length > 0 &&
      options?.sbu?.length > 0 &&
      options?.legalEntity?.length > 0 &&
      options?.division?.length > 0 &&
      options?.function?.length > 0 &&
      options?.department?.length > 0 &&
      options?.section?.length > 0 &&
      options?.employeeClass?.length > 0 &&
      options?.jobBand?.length > 0 &&
      options?.joblevel?.length > 0 &&
      options?.physicalLocation?.length > 0 &&
      options?.payrollLocation?.length > 0 &&
      options?.standardDesignation?.length > 0 &&
      options?.country?.length > 0 &&
      isOnce
    ) {
      if (state && state?.domainId > 0) {
        GetLMSDomainDataApiCall();
      }
      setIsOnce(false);
    }
  }, [options]);

  // useEffect(() => {
  //   const responseData = async () => {
  //     if (
  //       formData?.business?.length > 0 &&
  //       formData?.sbu?.length > 0 &&
  //       formData?.legalEntity?.length > 0
  //     ) {
  //       let BIS = formData?.business?.map((e) => e.value).join(",");
  //       let SBU = formData?.sbu?.map((e) => e.value).join(",");
  //       let LEN = formData?.legalEntity?.map((e) => e.value).join(",");

  //       let PId = `${BIS}-${SBU}-${LEN}`;
  //       debugger;
  //       if (PId) {
  //         const customBusinessData = await getDDData("CUSBIS", PId);

  //         setOptions((env) => {
  //           return {
  //             ...env,
  //             customBusiness: customBusinessData,
  //           };
  //         });
  //       }
  //     }
  //   };

  //   responseData();
  // }, [formData?.business, formData?.sbu, formData?.legalEntity]);

  //getUserRoleMappingData

  const GetLMSDomainDataApiCall = async () => {
    showLoader();
    if (state && state?.domainId > 0) {
      const response = await APICall(getLMSDomainData, "POST", {
        Id: state?.domainId,
      });

      if (response?.status === 0) {
        setDomainId(response?.data?.domainCode);
        setDomainName(response?.data?.domainName);
        setIsDomain(!response?.data?.isPublic);

        debugger;

        const adminIds = response?.data?.adminIds;

        const adminArray = [];
        if (adminIds !== null) {
          for (const userId of adminIds?.split(",")) {
            // const trainer = await fetchDropdown("", userId);
            const trainer = adminOptions.find(
              (option) => option.value == userId
            );
            adminArray.push(trainer);
          }
        }

        let domainData = JSON.parse(response?.data?.domainDetails);

        // setDomainDetails(domainData);

        // const domainDetail = {
        //   BIS: "business",
        //   SBU: "sbu",
        //   LEN: "legalEntity",
        //   DV: "division",
        //   FUNC: "function",
        //   DPT: "department",
        //   SCN: "section",
        //   EC: "employeeClass",
        //   JB: "jobBand",
        //   JL: "joblevel",
        //   SD: "standardDesignation",
        //   PHLOC: "physicalLocation",
        //   PLOC: "payrollLocation",
        //   COU: "country",
        //   CUSBIS: "customBusiness",
        // };

        //Business
        const businessArray = domainData.BIS;
        const selectedBusiness = [];
        businessArray?.map((value) => {
          const business = options?.business.find(
            (option) => option.value == value
          );
          if (business !== undefined) {
            selectedBusiness.push(business);
          }
        });

        //sbu
        const sbuArray = domainData.SBU;
        const selectedsbu = [];
        sbuArray?.map((value) => {
          const sbu = options?.sbu.find((option) => option.value == value);
          if (sbu !== undefined) {
            selectedsbu.push(sbu);
          }
        });

        //legalEntity
        const legalEntityArray = domainData.LEN;
        const selectedlegalEntity = [];
        legalEntityArray?.map((value) => {
          const legalEntity = options?.legalEntity.find(
            (option) => option.value == value
          );
          if (legalEntity !== undefined) {
            selectedlegalEntity.push(legalEntity);
          }
        });

        debugger;
        //CustomBusiness
        let BIS = businessArray?.map((e) => e.value).join(",");
        let SBU = sbuArray?.map((e) => e.value).join(",");
        let LEN = legalEntityArray?.map((e) => e.value).join(",");

        let PId = `${BIS}-${SBU}-${LEN}`;
        const selectecustomBusiness = [];
        if (PId) {
          const customBusinessData = await getDDData("CUSBIS", PId);

          setOptions((env) => {
            return {
              ...env,
              customBusiness: customBusinessData,
            };
          });

          const customBusinessArray = domainData.CUSBIS;
          customBusinessArray?.map((value) => {
            const customBusiness = customBusinessData?.find(
              (option) => option.value == value
            );
            if (customBusiness !== undefined) {
              selectecustomBusiness.push(customBusiness);
            }
          });
        }

        //division
        const divisionArray = domainData.DV;
        const selecteddivision = [];
        divisionArray?.map((value) => {
          const division = options?.division.find(
            (option) => option.value == value
          );
          if (division !== undefined) {
            selecteddivision.push(division);
          }
        });

        //function
        const functionArray = domainData.FUNC;
        const selectedfunction = [];
        functionArray?.map((value) => {
          const functions = options?.function.find(
            (option) => option.value == value
          );
          if (functions !== undefined) {
            selectedfunction.push(functions);
          }
        });

        //department
        const departmentArray = domainData.DPT;
        const selecteddepartment = [];
        departmentArray?.map((value) => {
          const department = options?.department.find(
            (option) => option.value == value
          );
          if (department !== undefined) {
            selecteddepartment.push(department);
          }
        });

        //section
        const sectionArray = domainData.SCN;
        const selectedsection = [];
        sectionArray?.map((value) => {
          const section = options?.section.find(
            (option) => option.value == value
          );
          if (section !== undefined) {
            selectedsection.push(section);
          }
        });

        //employeeClass
        const employeeClassArray = domainData.EC;
        const selectedemployeeClass = [];
        employeeClassArray?.map((value) => {
          const employeeClass = options?.employeeClass.find(
            (option) => option.value == value
          );
          if (employeeClass !== undefined) {
            selectedemployeeClass.push(employeeClass);
          }
        });

        //jobBand
        const jobBandArray = domainData.JB;
        const selectedjobBand = [];
        jobBandArray?.map((value) => {
          const jobBand = options?.jobBand.find(
            (option) => option.value == value
          );
          if (jobBand !== undefined) {
            selectedjobBand.push(jobBand);
          }
        });

        //joblevel
        const joblevelArray = domainData.JL;
        const selectedjoblevel = [];
        joblevelArray?.map((value) => {
          const joblevel = options?.joblevel.find(
            (option) => option.value == value
          );
          if (joblevel !== undefined) {
            selectedjoblevel.push(joblevel);
          }
        });

        //standardDesignation
        const standardDesignationArray = domainData.SD;
        const selectedstandardDesignation = [];
        standardDesignationArray?.map((value) => {
          const standardDesignation = options?.standardDesignation.find(
            (option) => option.value == value
          );
          if (standardDesignation !== undefined) {
            selectedstandardDesignation.push(standardDesignation);
          }
        });

        //physicalLocation
        const physicalLocationArray = domainData.PHLOC;
        const selectedphysicalLocation = [];
        physicalLocationArray?.map((value) => {
          const physicalLocation = options?.physicalLocation.find(
            (option) => option.value == value
          );
          if (physicalLocation !== undefined) {
            selectedphysicalLocation.push(physicalLocation);
          }
        });

        //payrollLocation
        const payrollLocationArray = domainData.PLOC;
        const selectedpayrollLocation = [];
        payrollLocationArray?.map((value) => {
          const payrollLocation = options?.payrollLocation.find(
            (option) => option.value == value
          );
          if (payrollLocation !== undefined) {
            selectedpayrollLocation.push(payrollLocation);
          }
        });

        //country
        const countryArray = domainData.COU;
        const selectecountry = [];
        countryArray?.map((value) => {
          const country = options?.country.find(
            (option) => option.value == value
          );
          if (country !== undefined) {
            selectecountry.push(country);
          }
        });

        setFormData((env) => {
          return {
            ...env,
            employeeDetail: adminArray,
            business: selectedBusiness,
            sbu: selectedsbu,
            legalEntity: selectedlegalEntity,
            division: selecteddivision,
            function: selectedfunction,
            department: selecteddepartment,
            section: selectedsection,
            employeeClass: selectedemployeeClass,
            jobBand: selectedjobBand,
            joblevel: selectedjoblevel,
            standardDesignation: selectedstandardDesignation,
            physicalLocation: selectedphysicalLocation,
            payrollLocation: selectedpayrollLocation,
            country: selectecountry,
            customBusiness: selectecustomBusiness,
          };
        });
      } else if (response?.status === 1) {
        notify(response?.status, response?.message);
      }
    }
    hideLoader();
  };

  return (
    <>
      <div className="breadcrumb modified-bredcrumb">
        <span>
          <ul>
            <li>
              <a href="/home">Home</a>
            </li>
            <li>
              {" "}
              <a href="/domainDashboard">Domain</a>
            </li>
            <li>Manage Domain</li>
          </ul>
        </span>
        <button
          onClick={() => {
            navigate("/domainDashboard");
          }}
          disabled={false}
          className="btn btn-secondary float-right"
        >
          <i className="fa-solid fa-arrow-left"></i> Back
        </button>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3 col-sm-12 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Domain ID <sup>*</sup>{" "}
              </label>
              <InputForm
                value={domainId}
                placeholder={"Domain ID"}
                isDisabled={true}
                textArea={false}
                onChange={(e) => {
                  setDomainId(e.target.value);
                }}
              />
              {formErrors["domainId"] && (
                <p style={{ color: "red" }}>{formErrors["domainId"]}</p>
              )}
            </div>
          </div>
          <div className="col-lg-3 col-sm-12 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Domain Name <sup>*</sup>{" "}
              </label>
              <InputForm
                value={domainName}
                placeholder={"Domain Name"}
                isDisabled={isDisable}
                textArea={false}
                onChange={(e) => {
                  setDomainName(e.target.value);
                }}
              />
              {formErrors["domainName"] && (
                <p style={{ color: "red" }}>{formErrors["domainName"]}</p>
              )}
            </div>
          </div>
          {/* <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Access Restricted<sup>*</sup>
              </label>
              <div>
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={isDomain}
                  style={{ marginLeft: "0px" }}
                  onChange={(e) => setIsDomain(e.target.checked)}
                  disabled
                />
              </div>
            </div>
          </div> */}
          {isDomain && (
            <>
              <div className={"col-lg-3 col-sm-4 col-xs-4"}>
                <div className="mb-1 dropdown">
                  <label className="col-form-label">Admins</label>
                  <sup>*</sup>
                  <SelectForm
                    isClearable
                    // async
                    options={
                      adminOptions
                      //   (searchString, cb) => {
                      //   handleSearchForAsyncDropdown(searchString, cb);
                      // }
                    }
                    placeholder={"Admins"}
                    onChange={(e: any) => {
                      formOnChange(e, "employeeDetail");
                    }}
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                    value={formData?.employeeDetail}
                  />

                  {formErrors["employeeDetail"] && (
                    <p style={{ color: "red" }}>
                      {formErrors["employeeDetail"]}
                    </p>
                  )}
                </div>
              </div>
            </>
          )}

          <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="mb-1 dropdown">
              <label className="col-form-label">Business</label>
              <SelectForm
                isClearable
                isSearchable
                options={options?.business}
                placeholder={"Business"}
                isDisabled={isDisable}
                value={formData?.business}
                isOptionDisabled={(option) => option.isDisable}
                onChange={async (e) => {
                  formOnChange(e, "business");
                }}
                isMulti={true}
                noIndicator={false}
                noSeparator={false}
              />
            </div>
          </div>
          <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="mb-1 dropdown">
              <label className="col-form-label">SBU</label>
              <SelectForm
                isClearable
                isSearchable
                options={options?.sbu}
                placeholder={"SBU"}
                isDisabled={isDisable}
                value={formData?.sbu}
                isOptionDisabled={(option) => option.isDisable}
                onChange={async (e) => {
                  formOnChange(e, "sbu");
                }}
                isMulti={true}
                noIndicator={false}
                noSeparator={false}
              />
            </div>
          </div>
          <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="mb-1 dropdown">
              <label className="col-form-label">Legal Entity</label>
              <SelectForm
                isClearable
                isSearchable
                options={options?.legalEntity}
                placeholder={"SBU"}
                isDisabled={isDisable}
                value={formData?.legalEntity}
                isOptionDisabled={(option) => option.isDisable}
                onChange={async (e) => {
                  formOnChange(e, "legalEntity");
                }}
                isMulti={true}
                noIndicator={false}
                noSeparator={false}
              />
            </div>
          </div>
          <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="mb-1 dropdown">
              <label className="col-form-label">Division</label>
              <SelectForm
                isClearable
                isSearchable
                options={options?.division}
                placeholder={"Division"}
                isDisabled={isDisable}
                value={formData?.division}
                isOptionDisabled={(option) => option.isDisable}
                onChange={async (e) => {
                  formOnChange(e, "division");
                }}
                isMulti={true}
                noIndicator={false}
                noSeparator={false}
              />
            </div>
          </div>
          <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="mb-1 dropdown">
              <label className="col-form-label">Function</label>
              <SelectForm
                isClearable
                isSearchable
                options={options?.function}
                placeholder={"Function"}
                isDisabled={isDisable}
                value={formData?.function}
                isOptionDisabled={(option) => option.isDisable}
                onChange={async (e) => {
                  formOnChange(e, "function");
                }}
                isMulti={true}
                noIndicator={false}
                noSeparator={false}
              />
            </div>
          </div>
          <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="mb-1 dropdown">
              <label className="col-form-label">Department</label>
              <SelectForm
                isClearable
                isSearchable
                options={options?.department}
                placeholder={"Department"}
                isDisabled={isDisable}
                value={formData?.department}
                isOptionDisabled={(option) => option.isDisable}
                onChange={async (e) => {
                  formOnChange(e, "department");
                }}
                isMulti={true}
                noIndicator={false}
                noSeparator={false}
              />
            </div>
          </div>

          <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="mb-1 dropdown">
              <label className="col-form-label">Section</label>
              <SelectForm
                isClearable
                isSearchable
                options={options?.section}
                placeholder={"Section"}
                isDisabled={isDisable}
                value={formData?.section}
                isOptionDisabled={(option) => option.isDisable}
                onChange={async (e) => {
                  formOnChange(e, "section");
                }}
                isMulti={true}
                noIndicator={false}
                noSeparator={false}
              />
            </div>
          </div>
          <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="mb-1 dropdown">
              <label className="col-form-label">Employee Class</label>
              <SelectForm
                isClearable
                isSearchable
                options={options?.employeeClass}
                placeholder={"Employee Class"}
                isDisabled={isDisable}
                value={formData?.employeeClass}
                isOptionDisabled={(option) => option.isDisable}
                onChange={async (e) => {
                  formOnChange(e, "employeeClass");
                }}
                isMulti={true}
                noIndicator={false}
                noSeparator={false}
              />
            </div>
          </div>
          <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="mb-1 dropdown">
              <label className="col-form-label">Job Band</label>
              <SelectForm
                isClearable
                isSearchable
                options={options?.jobBand}
                placeholder={"job Band"}
                isDisabled={isDisable}
                value={formData?.jobBand}
                isOptionDisabled={(option) => option.isDisable}
                onChange={async (e) => {
                  formOnChange(e, "jobBand");
                }}
                isMulti={true}
                noIndicator={false}
                noSeparator={false}
              />
            </div>
          </div>
          <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="mb-1 dropdown">
              <label className="col-form-label">Job Level</label>
              <SelectForm
                isClearable
                isSearchable
                options={options?.joblevel}
                placeholder={"Job Level"}
                isDisabled={isDisable}
                value={formData?.joblevel}
                isOptionDisabled={(option) => option.isDisable}
                onChange={async (e) => {
                  formOnChange(e, "joblevel");
                }}
                isMulti={true}
                noIndicator={false}
                noSeparator={false}
              />
            </div>
          </div>

          <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="mb-1 dropdown">
              <label className="col-form-label">Standard Designation</label>
              <SelectForm
                isClearable
                isSearchable
                options={options?.standardDesignation}
                placeholder={"Standard Designation"}
                isDisabled={isDisable}
                value={formData?.standardDesignation}
                isOptionDisabled={(option) => option.isDisable}
                onChange={async (e) => {
                  formOnChange(e, "standardDesignation");
                }}
                isMulti={true}
                noIndicator={false}
                noSeparator={false}
              />
            </div>
          </div>
          <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="mb-1 dropdown">
              <label className="col-form-label">Custom Business</label>
              <SelectForm
                isClearable
                isSearchable
                options={options?.customBusiness}
                placeholder={"Custom Business"}
                isDisabled={isDisable}
                value={formData?.customBusiness}
                isOptionDisabled={(option) => option.isDisable}
                onChange={async (e) => {
                  formOnChange(e, "customBusiness");
                }}
                isMulti={true}
                noIndicator={false}
                noSeparator={false}
              />
            </div>
          </div>
          <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="mb-1 dropdown">
              <label className="col-form-label">Physical Location</label>
              <SelectForm
                isClearable
                isSearchable
                options={options?.physicalLocation}
                placeholder={"Physical Location"}
                isDisabled={isDisable}
                value={formData?.physicalLocation}
                isOptionDisabled={(option) => option.isDisable}
                onChange={async (e) => {
                  formOnChange(e, "physicalLocation");
                }}
                isMulti={true}
                noIndicator={false}
                noSeparator={false}
              />
            </div>
          </div>
          <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="mb-1 dropdown">
              <label className="col-form-label">Payroll Location</label>
              <SelectForm
                isClearable
                isSearchable
                options={options?.payrollLocation}
                placeholder={"Physical Location"}
                isDisabled={isDisable}
                value={formData?.payrollLocation}
                isOptionDisabled={(option) => option.isDisable}
                onChange={async (e) => {
                  formOnChange(e, "payrollLocation");
                }}
                isMulti={true}
                noIndicator={false}
                noSeparator={false}
              />
            </div>
          </div>
          <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="mb-1 dropdown">
              <label className="col-form-label">Country</label>
              <SelectForm
                isClearable
                isSearchable
                options={options?.country}
                placeholder={"Country"}
                isDisabled={isDisable}
                value={formData?.country}
                isOptionDisabled={(option) => option.isDisable}
                onChange={async (e) => {
                  formOnChange(e, "country");
                }}
                isMulti={true}
                noIndicator={false}
                noSeparator={false}
              />
            </div>
          </div>
        </div>
        <br />
        {!isDisable && (
          <div className="mt-2 SectionSubmit mb-4 clearfix">
            <button
              onClick={() => {
                resetFunction();
              }}
              disabled={false}
              className="btn btn-secondary float-right ml-2"
            >
              <i className="fa-solid fa-refresh"></i> Reset
            </button>

            <button
              onClick={() => {
                handleSubmitButton();
              }}
              disabled={false}
              className="btn btn-filter-submit float-right ml-2"
            >
              <i className="fa-solid fa-check"></i> Save
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Domain;
