import { useState, useEffect, useRef } from 'react';

export const useDebounce = (value, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const timerRef = useRef(null);

  useEffect(() => {
    // Clear the previous timer on value change
    clearTimeout(timerRef.current);

    // Set a new timer to update the debounced value after the specified delay
    timerRef.current = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Cleanup the timer on component unmount
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [value, delay]);

  return debouncedValue;
};


export function useDebounceFunc(fn, delay = 250) {
  let timeout;

  return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
          fn(...args);
      }, delay);
  };
}