import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import InputForm from "../../Components/InputForm/InputForm";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import SelectForm from "../../Components/SelectForm/SelectForm";
import { Tooltip } from "@mui/material";
import { LoaderContext } from "../../Helpers/Context/Context";
import { APICall } from "../../Helpers/APICalls";
import {
  GetCriticalDataDashboard,
  GetCriticalDataDashboardDropdowns,
} from "../../Helpers/APIEndPoints/EndPoints";
import CriticalDataHistory from "./CriticalDataHistory";
import CriticalDataForm from "./CriticalDataForm";
import { useDebounce } from "../../utils/useDebounce";
const CriticalDataDashBoard = () => {
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [editid, seteditid] = useState(null);
  const [datahistory, setDataHistory] = useState("");
  const [criticalForm, setCriticalForm] = useState("");
  const [criticalDashboardFilterDropdown, setCriticalDashboardFilterDropdown] =
    useState({
      country: null,
      countryoption: null,
      section: null,
      sectionoption: null,
    });
  const [criticalDashboardGridData, setCriticalDashboardGridData] = useState({
    count: 0,
    start: 0,
    pageSize: 10,
    searchText: "",
    sortColumn: "",
    sortDirection: "",
    data: [],
  });
  const handleAction = (event, tablemeta) => {
    if (event === "critical_edit" || event === "critical_view") {
      setCriticalForm(event);
    } else if (event === "critical_history") {
      setDataHistory(event);
    }
    seteditid(tablemeta.rowData[0]);
  };
  const criticalDataGridOptions = {
    count: criticalDashboardGridData.count,
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    rowsPerPage: criticalDashboardGridData.pageSize,
    rowsPerPageOptions: [],
    page: criticalDashboardGridData.start,
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setCriticalDashboardGridData((prev) => {
          return {
            ...prev,
            sortColumn: sortColumn,
            sortDirection: sortDirection,
          };
        });
      }
      if (sortDirection === "desc") {
        await setCriticalDashboardGridData((prev) => {
          return {
            ...prev,
            sortColumn: sortColumn,
            sortDirection: sortDirection,
          };
        });
      }
    },
    onChangePage: async (page) => {
      setCriticalDashboardGridData((prev) => {
        return { ...prev, start: page };
      });
    },
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",
    serverSide: true,
  };
  const criticalDataGridColumns = [
    {
      name: "id",
      label: "Sr No.",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "fieldName",
      label: "Field Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "fieldType",
      label: "Field Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "country",
      label: "Country",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "criticality",
      label: "Criticality",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "section",
      label: "Section Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "tab",
      label: "Tab Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "lastModifiedBy",
      label: "Modified By",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "lastModifiedDate",
      label: "Modified Date",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Action",
      label: "Action",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="d-flex justify-content-start">
              {
                <>
                  <Tooltip title="Edit">
                    <i
                      style={{
                        marginRight: "10px",
                        cursor: "pointer",
                        fontSize: "medium",
                      }}
                      onClick={() => handleAction("critical_edit", tableMeta)}
                      className="fas fa-edit"
                    ></i>
                  </Tooltip>{" "}
                  <Tooltip title="View">
                    <i
                      style={{
                        marginRight: "10px",
                        cursor: "pointer",
                        fontSize: "medium",
                      }}
                      onClick={() => handleAction("critical_view", tableMeta)}
                      className="fas fa-eye"
                    ></i>
                  </Tooltip>{" "}
                  <Tooltip title="History">
                    <i
                      style={{
                        marginRight: "0px",
                        cursor: "pointer",
                        fontSize: "medium",
                      }}
                      onClick={() =>
                        handleAction("critical_history", tableMeta)
                      }
                      className="fas fa-history"
                    ></i>
                  </Tooltip>
                </>
              }
            </div>
          );
        },
      },
    },
  ];
  const debouncedValue = useDebounce(criticalDashboardGridData?.searchText);
  const getcriticalDashboardGridStructure = async (page) => {
    showLoader();
    try {
      const dashboardData = await APICall(GetCriticalDataDashboard, "POST", {
        PageNumber: page ?? criticalDashboardGridData.start,
        PageSize: criticalDashboardGridData.pageSize,
        SortColumn: criticalDashboardGridData.sortColumn || "",
        SortOrder: criticalDashboardGridData.sortDirection || "",
        SearchText: criticalDashboardGridData?.searchText || "",
        CountrIds:
          criticalDashboardFilterDropdown.country
            ?.map((item) => item.value)
            .join(",") || null,
        SectionIds:
          criticalDashboardFilterDropdown.section
            ?.map((item) => item.value)
            .join(",") || null,
      });
      const { data } = dashboardData || {};
      if ((data || []).length > 0) {
        setCriticalDashboardGridData((prev) => ({
          ...prev,
          count: data[0].count,
          data,
        }));
      } else {
        setCriticalDashboardGridData((prev) => ({
          ...prev,
          count: 0,
          data: [],
        }));
      }
    } catch (error) {
      console.error("Error in fetching critical dashboard data", error);
    } finally {
      hideLoader();
    }
  };
  const getSearchableDropdownAPI = async (type) => {
    showLoader();
    try {
      const dropdowndashboardData = await APICall(
        GetCriticalDataDashboardDropdowns,
        "POST",
        {
          Type: type,
        }
      );
      const { data } = dropdowndashboardData || {};
      if ((data || []).length > 0) {
        if (type === "Country") {
          setCriticalDashboardFilterDropdown((prev) => ({
            ...prev,
            countryoption: data,
          }));
        } else {
          setCriticalDashboardFilterDropdown((prev) => ({
            ...prev,
            sectionoption: data,
          }));
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error in fetching Dropdown for filters data", error);
    } finally {
      hideLoader();
    }
  };
  useEffect(() => {
    getSearchableDropdownAPI("Country");
    getSearchableDropdownAPI("Section");
  }, []);

  useEffect(() => {

    getcriticalDashboardGridStructure(null);
  }, [
    criticalDashboardGridData.start,
    criticalDashboardGridData.sortColumn,
    criticalDashboardGridData.sortDirection,
  ]);

  useEffect(() => {
    getcriticalDashboardGridStructure(0);
  }, [
    debouncedValue,
    criticalDashboardFilterDropdown.section,
    criticalDashboardFilterDropdown.country,
    criticalForm,
  ]);
  return (
    <div>
      <div className="row mt-1 mx-3">
        <div className="col-lg-12 my-3">
          <div className="d-flex align-items-center justify-content-end">
            <button
              className="btn btn-primary mr-2"
              onClick={() => {
                if (criticalForm) {
                    setCriticalForm("");
                } else {
                  navigate(-1);
                }
              }}
            >
              <i className="fa-solid fa-arrow-left mr-2"></i>
              Back
            </button>
            {!criticalForm && (
              <button
                className="btn btn-primary mr-2"
                onClick={() => {setCriticalForm("critical_add_new");seteditid(null)}}
              >
                <i className="fa fa-plus mr-2"></i>
                Add New
              </button>
            )}
          </div>
        </div>
      </div>
      <div
        className="customized-Grid-Wrapper grid-wrapper grid_mob"
        style={{ marginLeft: "15px", marginRight: "15px" }}
      >
        <div className="row mx-auto filtBox">
          {!criticalForm && (
            <>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="form-group filtInp">
                  <span className="mr-2 text-white" style={{ float: "left" }}>
                    <label className="col-form-label">Country</label>
                  </span>
                  <SelectForm
                    isClearable
                    options={
                      criticalDashboardFilterDropdown["countryoption"] || []
                    }
                    placeholder={"Select Country"}
                    isDisabled={false}
                    onChange={(event) => {
                      setCriticalDashboardGridData((prev) => {
                        return { ...prev, start: 0 };
                      });
                      setCriticalDashboardFilterDropdown((prev) => {
                        return { ...prev, ["country"]: event };
                      });
                    }}
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                    value={criticalDashboardFilterDropdown["country"] || ""}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="form-group filtInp">
                  <span className="mr-2 text-white" style={{ float: "left" }}>
                    <label className="col-form-label">Sections</label>
                  </span>
                  <SelectForm
                    isClearable
                    options={
                      criticalDashboardFilterDropdown["sectionoption"] || []
                    }
                    placeholder={"Select Sections"}
                    isDisabled={false}
                    onChange={(event) => {
                      setCriticalDashboardGridData((prev) => {
                        return { ...prev, start: 0 };
                      });
                      setCriticalDashboardFilterDropdown((prev) => {
                        return { ...prev, ["section"]: event };
                      });
                    }}
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                    value={criticalDashboardFilterDropdown["section"] || ""}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-12">
                <div className="form-group filtInp">
                  <InputForm
                    value={criticalDashboardGridData?.searchText || ""}
                    placeholder={"Search"}
                    isDisabled={false}
                    textArea={false}
                    onChange={(e) =>
                      setCriticalDashboardGridData((prev) => ({
                        ...prev,
                        searchText: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="mt-2">
        {!criticalForm && (
          <DynamicGrid
            options={criticalDataGridOptions}
            data={criticalDashboardGridData.data || []}
            columns={criticalDataGridColumns}
          />
        )}
        {criticalForm && (
          <CriticalDataForm
            formtype={criticalForm}
            setformtype={setCriticalForm}
            id={editid || 0}
          />
        )}
      </div>
      <div className="mt-2">
        {datahistory === "critical_history" && (
          <CriticalDataHistory
            key={datahistory}
            tabsactive={datahistory}
            editid={editid}
            setDataHistory={setDataHistory}
          />
        )}
      </div>
    </div>
  );
};

export default CriticalDataDashBoard;
