import React, { useState, useEffect, useContext, useRef } from "react";
import SelectForm from "../../Components/SelectForm/SelectForm";
import { Tooltip } from "@mui/material";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { APICall } from "../../Helpers/APICalls";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import "./program.css";
import {
  GetProgramCourseDataList,
  GetProgramCodeNameData,
  ChangeProgramCourseData,
  RemoveProgramCourseData,
  GetCoursesCodeNameData,
  AddProgramCourseData,
  LaunchProgramData,
  getLaunchHistoryDashboardDataForProgram,
  getLaunchValidationForProgram,
  CheckRoleWiseModuleAccess,
  getLMSDomainDropDownData,
  getCourseScheduleDropDownForLaunch,
  getUserAndRoleWiseAccess,
  GetCommonDropdownForScheduling,
  GetCertificateDropDown,
  GetSignatureDropDown,
  generateUserCertificate,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import { LoaderContext } from "../../Helpers/Context/Context";
import notify from "../../Helpers/ToastNotification";
import defaultProfileImgPic from "../../Assets/Images/thumbnail.jpg";
import { Modal, ModalTitle } from "react-bootstrap";
import DateForm from "../../Components/DateForm/DateForm";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import TextField from "@mui/material/TextField";
import ImageColumnComponent from "../../Components/LMS/ImageColumnComponent/ImageColumnComponent";
import { base64ToArrayBuffer } from "../../Helpers/Common";
import fileDownload from "js-file-download";
import { WidthFull } from "@mui/icons-material";

const Programcourse = () => {
  const [Journey, setJourney] = useState([]);
  const [JourneyOption, setJourneyOption] = useState([]);
  // const [formErrors, setFormErrors] = useState<any>({});
  const [CourseVal, setCourseVal] = useState([]);
  const [CourseOption, setCourseOption] = useState([]);
  const [dashboard, setdashboard] = useState([]);
  const [dashboardPageSize, setDashboardPageSize] = useState(10);
  const [dashboardStart, setDashboardStart] = useState(0);
  const [dashboardSortColumn, setDashboardSortColumn] = useState<any>("");
  const [dashboardSortDirection, setDashboardSortDirection] = useState("");
  const [dashboardCount, setDashboardCount] = useState(0);
  const [searchText, setSearchText] = useState<any>("");
  const [page, setPage] = useState(0);
  const { state } = useLocation();

  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  const [LaunchModal, setLaunchModal] = useState<any>(false);
  const [isChecked, setisChecked] = useState<any>(false);
  const [isScheduledChecked, setisScheduledChecked] = useState<any>(false);
  const [scheduledDate, setscheduledDate] = useState<any>("");
  const [invalidTime, setInvalidTime] = useState(false);
  const [selectedDateandTime, setselectedDateandTime] = React.useState(null);
  const [customTime, setCustomTime] = React.useState("");
  const [formattedDate, setFormattedDate] = useState("");
  const [formattedTime, setFormattedTime] = useState("");
  const [launchType, setLaunchType] = useState("immediate");
  const [formErrors, setFormErrors] = useState({
    launchType: "",
    scheduledDateTime: "",
  });
  const [showModal, setShowModal] = useState<any>(false);
  const [modalTitle, setmodalTitle] = useState<any>("");
  const [Id, setId] = useState<any>("");
  const [minDate, setMinDate] = useState(new Date());

  const [isDisable, setIsDisable] = useState(state?.isDisable);

  const [launchCourseOptions, setLaunchCourseOptions] = useState([]);

  const [launchCourseScheduleOptions, setLaunchCourseScheduleOptions] =
    useState([]);

  const [launchCourseSchedule, setLaunchCourseSchedule] = useState(null);

  const [launchCourse, setLaunchCourse] = useState(null);

  const [launchHistoryModel, setLaunchHistoryModel] = useState(false);

  const [launchHistoryData, setLaunchHistoryData] = useState([]);

  const [launchHistoryPage, setLaunchHistoryPage] = useState(0);

  const [launchHistoryPageSize, setLaunchHistoryPageSize] = useState(10);

  const [launchHistorySearchText, setLaunchHistorySearchText] = useState("");

  const [launchHistorySortColumn, setLaunchHistorySortColumn] = useState("");

  const [launchHistorySortDirection, setLaunchHistorySortDirection] =
    useState("");

  const [launchHistoryCount, setLaunchHistoryCount] = useState(0);

  const [journeyLaunchErrors, setJourneyLaunchErrors] = useState({});

  // const [roleWiseModuleAccess, setRoleWiseModuleAccess] = useState({
  //   listAccess: false,
  //   createAccess: false,
  //   editAccess: false,
  //   activeAccess: false,
  //   deleteAccess: false,
  //   holdAccess: false,
  //   launchAccess: false,
  // });

  const [date, setDate] = useState("");
  const [selectedvalue, setSelectedvalue] = useState(null);
  const [domainOption, setDomainOption] = useState([]);
  const [domain, setDomain] = useState(null);

  const [isIssueCertificate, setIsIssueCertificate] = useState(false);

  const [certificateTemplateOptions, setCertificateTemplateOptions] = useState(
    []
  );

  const [certificateTemplate, setCertificateTemplate] = useState(null);

  const [signatureOptions, setSignatureOptions] = useState([]);

  const [signature1, setSignature1] = useState(null);

  const [signature2, setSignature2] = useState(null);

  interface userAndRoleWiseAccess {
    isView?: boolean;
    isEdit?: boolean;
    isDelete?: boolean;
  }

  const [userAndRoleWiseData, setUserAndRoleWiseData] =
    useState<userAndRoleWiseAccess>({});

  const getUserAndRoleWiseAccessApiCall = async () => {
    const response = await APICall(getUserAndRoleWiseAccess, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      InterfaceName: "Program_Form",
    });

    if (response?.status === 0) {
      setUserAndRoleWiseData(response?.data);
    } else {
      notify(response?.status, response?.message);
    }
  };

  useEffect(() => {
    getLaunchHistoryDashboardData();
  }, [
    launchHistoryModel,
    launchHistoryPage,
    launchHistoryPageSize,
    launchHistorySearchText,
    launchHistorySortColumn,
    launchHistorySortDirection,
  ]);

  useEffect(() => {
    (async () => {
      await getUserAndRoleWiseAccessApiCall();
      await getLMSDomainDropDownDataApiCall();
    })();
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     await getCourseScheduleDropDownForLaunchApiCall();
  //   })();
  // }, [launchCourse]);

  const getCourseScheduleDropDownForLaunchApiCall = async (courseIds) => {
    // if (launchCourse?.length > 0) {

    showLoader();

    const response = await APICall(getCourseScheduleDropDownForLaunch, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      CourseIds: courseIds,
    });

    if (response?.status === 0 && response?.data.length > 0) {
      setLaunchCourseScheduleOptions(response?.data);
    }

    hideLoader();
    // }
  };

  // const CheckRoleWiseModuleAccessApiCall = async () => {
  //   showLoader();
  //   const response = await APICall(CheckRoleWiseModuleAccess, "POST", {
  //     RoleId: currentRoleId?.value,
  //   });

  //   if (response?.status === 0 && response?.data !== null) {
  //     setRoleWiseModuleAccess(response?.data);
  //   } else if (response?.status === 1) {
  //     notify(response?.status, response?.message);
  //   }
  //   // else {
  //   //   notify(1, "RoleWiseModuleAccess data is null");
  //   // }
  //   hideLoader();
  // };

  useEffect(() => {
    getCertificateDropDownApiCall();
    getSignatureDropDownApiCall();
  }, [isIssueCertificate]);

  const getCertificateDropDownApiCall = async () => {
    showLoader();
    const response = await APICall(GetCertificateDropDown, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    if (response?.status === 0 && response?.data.length > 0) {
      setCertificateTemplateOptions(response?.data);
    }
    hideLoader();
  };

  const getSignatureDropDownApiCall = async () => {
    showLoader();
    const response = await APICall(GetSignatureDropDown, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    if (response?.status === 0 && response?.data.length > 0) {
      setSignatureOptions(response?.data);
    }
    hideLoader();
  };

  const getLMSDomainDropDownDataApiCall = async () => {
    const response = await APICall(getLMSDomainDropDownData, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      GetAll: state && state?.id > 0 ? true : false,
    });

    if (response?.status === 0 && response?.data.length > 0) {
      setDomainOption(response?.data);
    } else {
      notify(response?.status, response?.message);
    }
  };
  const getLaunchHistoryDashboardData = async () => {
    showLoader();
    if (state != null && state.id != 0) {
      let requestParams = {
        PageSize: launchHistoryPageSize,
        Page: launchHistoryPage,
        SortOrder: launchHistorySortDirection,
        SortColumn: launchHistorySortColumn,
        SearchText: launchHistorySearchText,
        ProgramId: state.id,
        CourseId: 0,
      };

      const response = await APICall(
        getLaunchHistoryDashboardDataForProgram,
        "POST",
        requestParams
      );

      if (response?.status === 0 && response?.data.length > 0) {
        setLaunchHistoryData(response?.data);
        setLaunchHistoryCount(response?.data[0]?.totalCount);
        hideLoader();
      } else {
        setLaunchHistoryData([]);
      }
    }
    hideLoader();
  };

  const onLaunchSetCourse = () => {
    debugger;

    if (launchCourseOptions.length > 0) {
      const objLaunchcourse = {
        value: launchCourseOptions[0].value,
        label: launchCourseOptions[0].label,
      };
      setLaunchCourse(objLaunchcourse);
    }
  };
  const launchHistoryOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: launchHistoryCount,
    rowsPerPage: launchHistoryPageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setLaunchHistorySortColumn(sortColumn);
        await setLaunchHistorySortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setLaunchHistorySortColumn(sortColumn);
        await setLaunchHistorySortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      setLaunchHistoryPage(page * launchHistoryPageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const launchHistoryGridColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        display: false,
      },
    },

    {
      name: "programTitle",
      label: "Journey Title",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "courseTitle",
      label: "Course Title",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "launchFor",
      label: "Launch For",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "launchType",
      label: "Launch Type",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "launchDate",
      label: "Launch Date",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "isRelaunched",
      label: "Is Relaunched",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "createdByName",
      label: "Created By Name",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "createdOnDate",
      label: "Created On Date",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
  ];

  const handleOpenForLaunchModel = async () => {
    try {
      showLoader();
      if (state.id != null && state.id != 0) {
        let requestParams = {
          CourseId: 0,
          ProgramId: state.id,
        };

        const response = await APICall(
          getLaunchValidationForProgram,
          "POST",
          requestParams
        );

        if (response?.status === 1) {
          notify(response?.status, response?.message);
          hideLoader();
        } else {
          setLaunchModal(true);
        }
      } else {
        notify(1, "Program id is zero");
      }

      hideLoader();
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const GetcourseDashboard = async () => {
    showLoader();
    if (state != null && state.id != 0) {
      let requestParams = {
        PageSize: dashboardPageSize,
        Page: dashboardStart,
        SortOrder: dashboardSortDirection,
        SortColumn: dashboardSortColumn,
        ProgramId: state.id,
        CreatedBy: userDetails?.Id,
        LaunchId: 0,
      };

      const { data } = await APICall(
        GetProgramCourseDataList,
        "POST",
        requestParams
      );
      if (data != null) {
        const courselaunchArray = data?.map((item) => ({
          value: item.id,
          //label: item.courseCode +" - " +item.courseTitle
          label: item.courseTitle + " - " + item.courseCode,
        }));

        setLaunchCourseOptions(courselaunchArray);

        console.log(data, "data");
        setdashboard(data);
        setDashboardCount(data[0]?.count);
        setDashboardPageSize(data[0]?.count);
        hideLoader();
      } else {
        setdashboard([]);
      }
    }
    hideLoader();
  };

  useEffect(() => {
    (async () => {
      await GetProgramID();
      await GetCourseData();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await GetcourseDashboard();
    })();
  }, [
    dashboardPageSize,
    dashboardStart,
    dashboardSortDirection,
    dashboardSortColumn,
  ]);

  const GetProgramID = async () => {
    const { data } = await APICall(GetProgramCodeNameData, "POST", {
      ProgramId: state?.id,
    });

    if (data !== null && data.length > 0) {
      let role = data.map((element) => ({
        value: element.value,
        label: element.label,
      }));
      setJourney(role);
    }
  };

  const previewCertificate = async () => {
    if (
      !launchCourseSchedule ||
      launchCourseSchedule.length === 0 ||
      !certificateTemplate?.value
    ) {
      notify(1, "Certificate Template and Course Schedule Required!");
      return null;
    }

    showLoader();
    const response = await APICall(generateUserCertificate, "POST", {
      UserId: userDetails.Id,
      CourseScheduleId: launchCourseSchedule[0]?.value || null,
      Signature1Id: signature1?.value || null,
      Signature2Id: signature2?.value || null,
      CertificateId: certificateTemplate?.value || null,
      IsPreview: true,
      ProgramId: Journey[0]?.value || null,
    });

    if (response.status === 0 && response.data !== null) {
      const base64 = response?.data;
      const fileName = `Certificate.pdf`;

      let arrayBuffer = await base64ToArrayBuffer(base64);
      var byteArray = new Uint8Array(arrayBuffer);

      let blob = new Blob([byteArray], {
        type: "application/pdf",
      });
      const newFile = new File([blob], "", {
        type: "application/pdf",
        lastModified: new Date().getTime(),
      });
      fileDownload(newFile, fileName);
    } else {
      notify(1, "Something went wrong try again later");
    }
    hideLoader();
  };

  const GetCourseData = async () => {
    showLoader();
    const { data } = await APICall(GetCoursesCodeNameData, "POST", {
      ProgramId: state?.id,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    if (data !== null && data.length > 0) {
      let CourseName = data.map((element) => ({
        value: element.value,
        label: element.label,
      }));
      setCourseOption(CourseName);
      hideLoader();
    }
    hideLoader();
  };

  const handlechangeupdown = async (e, id: any, sequencealign: any) => {
    showLoader();
    e.preventDefault();
    const { data } = await APICall(ChangeProgramCourseData, "POST", {
      CourseId: id,
      ProgramId: state?.id,
      Direction: sequencealign,
      CourseIds: "",
      CreatedBy: userDetails?.Id,
    });
    await GetcourseDashboard();
    hideLoader();
  };

  const delink = async (e, id: any) => {
    showLoader();
    const { data } = await APICall(RemoveProgramCourseData, "POST", {
      CourseId: id,
      ProgramId: state?.id,
      Direction: 0,
      CourseIds: "",
      CreatedBy: userDetails?.Id,
    });
    if (data != null) {
      notify(0, data[0].errorMessage);
      await GetcourseDashboard();
      setShowModal(false);
      await GetCourseData();
    }
  };

  const delinkuserId = async (id: any) => {
    setShowModal(true);
    setId(id);
    setmodalTitle(
      `Do you want to Delink the course assigned by you on ${date}`
    );
  };

  const MapArr = (arr) => {
    let s = [];
    if (arr[0] === null || arr.length < 0) {
      return "";
    }
    arr.map((i) => s.push(i.value));
    return s;
  };

  let CourseValues = MapArr(CourseVal);

  const AddCourse = async () => {
    if (CourseVal.length > 0) {
      let requestParams = {
        ProgramId: state?.id,
        CourseId: 0,
        CourseIds: CourseValues.toString(),
        Direction: 0,
        CreatedBy: userDetails?.Id,
      };

      const { data } = await APICall(
        AddProgramCourseData,
        "POST",
        requestParams
      );
      if (data != null) {
        if (
          data[0].errorMessage === "Course(s) linked to a program successfully"
        ) {
          notify(0, "Course(s) linked to a program successfully");
          GetcourseDashboard();
          setCourseVal([]);
          await GetCourseData();
        } else {
          notify(1, "Course(s)  is not linked to a program");
          setCourseVal([]);
        }
      }
    } else {
      notify(1, "Please select course");
    }
  };

  const handleLaunchTypeChange = (type) => {
    setLaunchType(type);
  };

  // const handleDateTimeChange = (val) => {
  //   // for past time not allowed
  //   if (moment(val).isBefore(new Date())) {
  //     setInvalidTime(true);
  //   } else {
  //     setInvalidTime(false);
  //   }

  //   // Format date and time
  //   setFormattedDate(moment(val).format("DD-MMM-yyyy")); // Change the format as needed

  //   console.log(moment(val).format("DD-MMM-yyyy"), "dddddddddd");
  //   console.log(val.toLocaleTimeString("en-US", { hour12: false }), "ssssss");
  //   setFormattedTime(val.toLocaleTimeString("en-US", { hour12: false })); // 24-hour format

  //   setselectedTime(val);
  // };

  const resetLaunchJourney = () => {
    // setLaunchCourse(null);
    setselectedDateandTime(null);
    setLaunchType("immediate");
    setJourneyLaunchErrors({});

    if (launchCourseOptions.length === 1) {
      const objLaunchcourse = {
        value: launchCourseOptions[0].value,
        label: launchCourseOptions[0].label,
      };

      getCourseScheduleDropDownForLaunchApiCall(objLaunchcourse?.value);
      setLaunchCourse(objLaunchcourse);
    }
  };

  const SubmitLanguage = async (launchname: any) => {
    const ErrorObj = {};

    debugger;
    const currentTime = new Date();
    if (
      launchCourse == null ||
      launchCourse == undefined ||
      launchCourse.length === 0
    ) {
      ErrorObj["launchJourney_isEmpty"] = "Course required.";
    }

    if (
      launchCourseSchedule == null ||
      launchCourseSchedule == undefined ||
      launchCourseSchedule.length === 0
    ) {
      ErrorObj["courseSchedule"] = "Course Schedule required.";
    }

    if (
      launchType === "scheduled" &&
      (selectedDateandTime == null ||
        selectedDateandTime == undefined ||
        !moment(selectedDateandTime, "DD-MM-YYYY HH:mm:ss").isValid())
    ) {
      ErrorObj["schedule_datetime"] = "Schedule Date and Time required.";
    }

    if (
      moment(selectedDateandTime, "DD-MM-YYYY HH:mm:ss").isBefore(currentTime)
    ) {
      ErrorObj["schedule_datetime"] = "Kindly select a time in the future.";
    }

    if (isIssueCertificate) {
      if (certificateTemplate == null || certificateTemplate == undefined) {
        ErrorObj["certificateTemplate"] = "Certificate Template Required.";
      }
    }

    debugger;

    setJourneyLaunchErrors(ErrorObj);

    if (Object.keys(ErrorObj).length != 0) {
      return;
    }
    showLoader();

    if (launchCourse.length > 0) {
      const courseIds = launchCourse?.map((item) => item.value).join(",");
      if (state.id != null && state.id != 0) {
        let requestParams = {
          CourseIds: courseIds,
          ProgramId: state.id,
        };

        const response = await APICall(
          getLaunchValidationForProgram,
          "POST",
          requestParams
        );

        if (response?.status === 1) {
          notify(response?.status, response?.message);
          hideLoader();
        } else {
          const courseScheduleIds = launchCourseSchedule
            ?.map((item) => item.value)
            .join(",");
          if (launchname == "immediate") {
            let requesparams = {
              ProgramId: state?.id,
              courseIds: courseIds,
              LaunchFor: "PROGRAM",
              LaunchType: "IMMEDIATE",
              SelectedDateandTime: null,
              // LaunchTime: "",
              IsActive: true,
              CreatedBy: userDetails?.Id,
              DomainIds: domain?.map((event) => event.value).join(","),
              CourseScheduleIds: courseScheduleIds,
              IsIssueCertificate: isIssueCertificate,
              CertificateTemplateId: certificateTemplate?.value,
              Signature1UserId: signature1?.value || null,
              Signature2UserId: signature2?.value || null,
            };
            const response = await APICall(
              LaunchProgramData,
              "POST",
              requesparams
            );
            hideLoader();
            if (response?.status === 0) {
              notify(0, "Journey launched successfully");
            } else {
              notify(response?.status, response?.message);
            }
            setLaunchModal(false);
          } else {
            const scheduleDateTime = moment(
              selectedDateandTime,
              "DD-MMM-yyyy HH:mm:ss"
            ).format("DD-MMM-yyyy HH:mm:ss");
            if (selectedDateandTime) {
              let requesparams = {
                ProgramId: state?.id,
                courseIds: courseIds,
                LaunchFor: "PROGRAM",
                LaunchType: "SCHEDULED",
                ScheduleDateTime: scheduleDateTime,
                // LaunchDate: formattedDate,
                // LaunchTime: formattedTime,
                IsActive: true,
                CreatedBy: userDetails?.Id,
                DomainIds: domain?.map((event) => event.value).join(","),
                CourseScheduleIds: courseScheduleIds,
                IsIssueCertificate: isIssueCertificate,
                CertificateTemplateId: certificateTemplate?.value,
                Signature1UserId: signature1?.value || null,
                Signature2UserId: signature2?.value || null,
              };

              const response = await APICall(
                LaunchProgramData,
                "POST",
                requesparams
              );
              hideLoader();

              if (response?.status === 0) {
                notify(0, "Journey launch scheduled successfully");
              } else {
                notify(response?.status, response?.message);
              }
              setLaunchModal(false);
            } else {
              notify(1, "Please Select the Scheduled time and date");
            }
          }
        }
      } else {
        notify(1, "Program id is zero");
      }
    } else {
      hideLoader();
      notify(1, "Please select course");
    }
    hideLoader();
  };

  const dashboardOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: dashboardCount,
    rowsPerPage: dashboardPageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    //standard | vertical | simple
    onChangeRowsPerPage: (num) => {
      //   setLimit(num);
      //   setNxtPgInfo("");
      //   setPrevPgInfo("");
      //   setIsPrevOrNext("");
    },
    onSearchChange: (searchText) => {
      if (searchText !== null) {
        //setDashboardSearchText(searchText);
      } else {
        //setDashboardSearchText("");
      }
    },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setDashboardSortColumn(sortColumn);
        await setDashboardSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setDashboardSortColumn(sortColumn);
        await setDashboardSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      setDashboardStart(page * dashboardPageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const gridColumns = [
    {
      name: "thumbnailUrl",
      label: "Thumbnail",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        customBodyRender: (value, tableMeta) => {
          return <ImageColumnComponent link={value} />;
        },
      },
    },

    {
      name: "courseCode",
      label: "Course ID",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "courseTitle",
      label: "Course Title",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "learningObjective",
      label: "Course Objective",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <span
                dangerouslySetInnerHTML={{
                  __html: value,
                }}
              ></span>
            </>
          );
        },
      },
    },
    {
      name: "launchStatus",
      label: "Launch",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "sequence",
      label: "Sequence",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "left", width: "140px" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left", width: "140px" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          const { rowIndex, tableData } = tableMeta;
          const sequenceNo = tableData[rowIndex].sequence;
          const totalItems = tableData.length;
          const isFirst = rowIndex === 0; // First item has rowIndex 0
          const isLast = rowIndex === totalItems - 1; // Last item has rowIndex totalItems - 1
          const id = tableData[rowIndex].id;
          const canDelink = tableData[rowIndex].canDelink;
          const EmpIdName = tableData[rowIndex].createdByName;
          const EmpAssignedOn = tableData[rowIndex].createdOnDate;
          setDate(EmpAssignedOn);

          const upArrow = isFirst ? (
            <Tooltip title="">
              <button className="btn mr-1" disabled={true}></button>
            </Tooltip>
          ) : (
            <Tooltip title="Move up">
              <button
                className="btn"
                disabled={false}
                onClick={(e) => handlechangeupdown(e, id, -1)}
              >
                <i className="fa fa-caret-up"></i>
              </button>
            </Tooltip>
          );

          const downArrow = isLast ? (
            <Tooltip title="">
              <button className="btn mr-1" disabled={true}></button>
            </Tooltip>
          ) : (
            <Tooltip title="Move down">
              <button
                className="btn"
                disabled={false}
                onClick={(e) => {
                  handlechangeupdown(e, id, 1);
                }}
              >
                <i className="fa fa-caret-down"></i>
              </button>
            </Tooltip>
          );
          if (state?.launched) {
            return (
              <div className="">
                {/* <div className="updw_arr"> */}
                {upArrow}
                {downArrow}
                {/* </div> */}

                <Tooltip
                  title={`You can not delink the course linked by ${EmpIdName}`}
                >
                  <a
                    className=" ml-2 mr-2 mt-1"
                    style={{ fontSize: "15px" }}
                    onClick={(e) => {
                      // delinkuserId(id);
                    }}
                  >
                    <i className="fa fa-link"></i>
                  </a>
                </Tooltip>
              </div>
            );
          } else {
            if (canDelink) {
              return (
                // <div className="d-flex justify-content-center">
                <div className="">
                  {/* <div className="updw_arr"> */}
                  {upArrow}
                  {downArrow}
                  {/* </div> */}
                  <Tooltip title="Delink">
                    <a
                      className=" ml-2 mr-2 mt-1"
                      style={{ fontSize: "15px" }}
                      onClick={(e) => {
                        delinkuserId(id);
                      }}
                    >
                      <i className="fa fa-link"></i>
                    </a>
                  </Tooltip>
                </div>
              );
            } else {
              return (
                <div className="">
                  {/* <div className="updw_arr"> */}
                  {upArrow}
                  {downArrow}
                  {/* </div> */}
                  <Tooltip title="You can't delink this course">
                    <a
                      className=" ml-2 mr-2 mt-1"
                      style={{ fontSize: "15px" }}
                      onClick={(e) => {
                        // delinkuserId(id);
                      }}
                    >
                      <i className="fa fa-link"></i>
                    </a>
                  </Tooltip>
                </div>
              );
            }
          }
        },
      },
    },
  ];

  return (
    <>
      <div className="breadcrumb modified-bredcrumb">
        <span>
          <ul>
            <li>
              <a href="/home">Home</a>
            </li>
            <li>
              <a href="/journeyDashboard">Journey</a>
            </li>
            <li>Map Course</li>
          </ul>
        </span>
        <div className="col-lg-12 d-flex mt-2 justify-content-end">
          {userAndRoleWiseData?.isEdit && (
            <button
              disabled={dashboard.length > 0 ? false : true}
              className="btn btn-filter-submit mr-2 float-right"
              onClick={() => {
                setLaunchModal(true);
                resetLaunchJourney();
              }}
            >
              <i className="fa-solid fa-rocket"></i>{" "}
              {/* {state?.launched ? "Re Launch" : "Launch"} */}
              Launch
            </button>
          )}

          {state?.launched && (
            <button
              disabled={dashboard.length > 0 ? false : true}
              className="btn btn-filter-submit mr-2 float-right"
              onClick={() => {
                setLaunchHistoryModel(true);
              }}
            >
              <i className="fa-solid fa-history "></i> Launch History
            </button>
          )}
          <button
            onClick={() => {
              navigate("/journeyDashboard");
            }}
            disabled={false}
            className="btn btn-secondary float-right"
          >
            <i className="fa-solid fa-arrow-left"></i> Back
          </button>
        </div>
      </div>

      {/* <div className="col-lg-12 d-flex mt-2 justify-content-end">
        <button
          disabled={dashboard.length > 0 ? false : true}
          className="btn btn-filter-submit mr-2"
          onClick={() => {
            setLaunchModal(true);
          }}
        >
          <i aria-hidden="true"></i> Launch{" "}
        </button>
      </div> */}

      <div className="col-lg-12">
        <div className="row">
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="dropdown mb-1">
              <label className="col-form-label">
                Journey<sup>*</sup>
              </label>
              <Tooltip title={Journey[0]?.label || "Journey"}>
                <span>
                  <SelectForm
                    isClearable
                    options={JourneyOption}
                    placeholder={"Select Journey Type"}
                    isDisabled={true}
                    onChange={(event) => {
                      setJourney(event);
                    }}
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                    value={Journey}
                  />
                </span>
              </Tooltip>

              <p style={{ color: "red" }}>{formErrors["Journey_isEmpty"]}</p>
            </div>
          </div>

          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="dropdown mb-1">
              <label className="col-form-label">
                Courses<sup>*</sup>
              </label>
              <SelectForm
                isClearable
                options={CourseOption}
                placeholder={"Select Course"}
                isDisabled={false}
                onChange={(event) => {
                  setCourseVal(event);
                }}
                isMulti={true}
                noIndicator={false}
                noSeparator={false}
                value={CourseVal}
              />
              <p style={{ color: "red" }}>{formErrors["Course_isEmpty"]}</p>
            </div>
          </div>

          <div
            className={"col-lg-3 col-sm-3 col-xs-3"}
            style={{ margin: "10px" }}
          >
            <div className="mb-1">
              <div className="mt-4">
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: 5 }}
                  onClick={() => {
                    GetCourseData();
                  }}
                >
                  <i className="fa-solid fa-refresh"></i> Refresh
                </button>
                <Link
                  className="btn btn-primary"
                  style={{ marginLeft: 5 }}
                  to={"/CoursesDashboard"}
                  target="_blank"
                >
                  <i className="fa-solid fa-plus"></i> Create Course
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-12 d-flex mt-2 justify-content-end">
            <button className="btn btn-filter-submit mr-2" onClick={AddCourse}>
              <i className="fa fa-check" aria-hidden="true"></i> Add{" "}
            </button>

            <button className="btn btn-secondary" onClick={() => {}}>
              <i className="fa fa-undo" aria-hidden="true"></i> Reset
            </button>
          </div>
        </div>

        {/* <div className="col-lg-12 d-flex mt-2 justify-content-end">
          <button className="btn btn-filter-submit mr-2" onClick={AddCourse}>
            <i className="fa fa-check" aria-hidden="true"></i> Add{" "}
          </button>
          
          <button className="btn btn-secondary" onClick={() => {}}>
            <i className="fa fa-undo" aria-hidden="true"></i> Reset
          </button>
        </div> */}
      </div>

      <div className="mb-3 pt-3">
        <DynamicGrid
          data={dashboard}
          columns={gridColumns}
          options={dashboardOptions}
        />
      </div>

      <Modal
        show={LaunchModal}
        onHide={() => setLaunchModal(false)}
        backdrop="static"
        keyboard={false}
        size="xl"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">Launch Journey</div>
        </div>
        <Modal.Body>
          <>
            <div className="continer-fluid">
              <div className="row">
                <div className="col-lg-6 col-sm-6 col-xs-6">
                  <div className="dropdown mb-1">
                    <label className="col-form-label">
                      Select Courses <sup>*</sup>
                    </label>
                    <SelectForm
                      isClearable
                      options={launchCourseOptions}
                      placeholder={"Select Courses"}
                      isDisabled={false}
                      onChange={(event) => {
                        setLaunchCourse(event);
                        // getCourseScheduleDropDownForLaunchApiCall();

                        const courseIds = event
                          ?.map((item) => item.value)
                          .join(",");

                        getCourseScheduleDropDownForLaunchApiCall(courseIds);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={launchCourse}
                    />

                    <span className="" style={{ color: "red" }}>
                      {journeyLaunchErrors["launchJourney_isEmpty"]}
                    </span>
                  </div>
                </div>

                <div className="col-lg-6 col-sm-6 col-xs-6">
                  <div className="mb-1">
                    <label className="col-form-label">Course Schedule</label>{" "}
                    <sup>*</sup>
                    <SelectForm
                      placeholder={"Course Schedule"}
                      isDisabled={false}
                      value={launchCourseSchedule}
                      onChange={(event) => {
                        setLaunchCourseSchedule(event);
                      }}
                      options={launchCourseScheduleOptions}
                      noIndicator={false}
                      noSeparator={false}
                      isMulti={true}
                    />
                    <span className="" style={{ color: "red" }}>
                      {journeyLaunchErrors["courseSchedule"]}
                    </span>
                  </div>
                </div>

                <div className="col-lg-6 col-sm-6 col-xs-6">
                  {" "}
                  <div className="row">
                    <div className="col-lg-6 col-sm-6 col-xs-6 mb-3">
                      <br />
                      <div>
                        <input
                          className="ml-2 mr-1"
                          disabled={false}
                          id={`registrationByManager_1`}
                          type="radio"
                          name="registrationByManager"
                          onChange={() => handleLaunchTypeChange("immediate")}
                          checked={launchType === "immediate"}
                        />
                        <label htmlFor={`registrationByManager_1`}>
                          Immediate Launch
                        </label>
                      </div>
                    </div>

                    <div className="col-lg-6 col-sm-6 col-xs-6 mb-3">
                      <br />
                      <div>
                        <input
                          className="ml-2 mr-1"
                          disabled={false}
                          id={`registrationByManager_2`}
                          type="radio"
                          name="registrationByManager"
                          onChange={() => handleLaunchTypeChange("scheduled")}
                          checked={launchType === "scheduled"}
                        />
                        <label htmlFor={`registrationByManager_2`}>
                          Scheduled Launch
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">Select Domain</label>
                  <SelectForm
                    isClearable
                    options={domainOption}
                    placeholder={"Select Domain"}
                    isDisabled={false}
                    onChange={(event) => {
                      setDomain(event);
                    }}
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                    value={domain}
                  />
                </div>
              </div> */}

                {launchType === "scheduled" && (
                  // <div className="row mx-auto">
                  <div className="col-lg-6 col-sm-6 col-xs-6">
                    <label className="col-form-label">
                      Scheduled Date and Time
                    </label>{" "}
                    <sup>*</sup>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        value={selectedDateandTime}
                        disabled={false}
                        onChange={(e) => setselectedDateandTime(e)}
                        disablePast={true}
                        inputFormat="dd/MM/yyyy HH:mm:ss"
                        disableMaskedInput
                        PopperProps={{ placement: "right" }}
                        className="w-100 bg-white date_icon"
                        InputProps={{
                          sx: {
                            "&.Mui-disabled": {
                              backgroundColor: "#e9ecef",
                              borderColor: "#e9ecef",
                              outline: "none",
                              opacity: "1",
                            },
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            name=""
                            autoComplete="off"
                            id="date-input"
                            sx={{
                              svg: {
                                color: "#00ABE6",
                              },
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  fontFamily: "Segoe UI",
                                  boxShadow: "none",
                                  outline: "none",
                                },
                                "&:hover fieldset": {
                                  borderColor: "hsl(0, 0%, 70%)",
                                  boxShadow: "none",
                                  outline: "none",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "hsl(0, 0%, 70%)",
                                  boxShadow: "none",
                                  outline: "none",
                                },
                              },
                            }}
                            {...params}
                          />
                        )}
                      />
                      <span className="" style={{ color: "red" }}>
                        {journeyLaunchErrors["schedule_datetime"]}
                      </span>
                    </LocalizationProvider>
                  </div>
                  // </div>
                )}

                <div className={"col-lg-6 col-sm-6 col-xs-6"}>
                  <div className="mb-1">
                    <label className="col-form-label">
                      Issue Certificate
                      <sup>*</sup>
                    </label>

                    <div>
                      <input
                        className="ml-2 mr-1"
                        disabled={isDisable}
                        type="radio"
                        onChange={(e) => {
                          setIsIssueCertificate(true);
                        }}
                        checked={isIssueCertificate === true}
                      />
                      <label>Yes</label>
                      <input
                        className="ml-2 mr-1"
                        disabled={isDisable}
                        type="radio"
                        onChange={(e) => {
                          setIsIssueCertificate(false);
                        }}
                        checked={isIssueCertificate === false}
                      />
                      <label>No</label>
                    </div>
                  </div>
                </div>

                {isIssueCertificate && (
                  <>
                    <div className="col-lg-6 col-sm-6 col-xs-6">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Certificate Template
                        </label>{" "}
                        <sup>*</sup>
                        <SelectForm
                          value={certificateTemplate}
                          placeholder={"Certificate Template"}
                          options={certificateTemplateOptions}
                          onChange={(event) => {
                            setCertificateTemplate(event);
                          }}
                          noIndicator={false}
                          noSeparator={false}
                        />
                        <span className="" style={{ color: "red" }}>
                          {journeyLaunchErrors["certificateTemplate"]}
                        </span>
                      </div>
                    </div>

                    {/* <div className="col-lg-6 col-sm-6 col-xs-6">
                      <div className="mb-1">
                        <label className="col-form-label">Signature 1</label>{" "}
                        <SelectForm
                          value={signature1}
                          placeholder={"Signature 1"}
                          options={signatureOptions}
                          onChange={(event) => {
                            setSignature1(event);
                          }}
                          noIndicator={false}
                          noSeparator={false}
                        />
                        <span className="" style={{ color: "red" }}>
                          {journeyLaunchErrors["signature1"]}
                        </span>
                      </div>
                    </div>

                    <div className="col-lg-6 col-sm-6 col-xs-6">
                      <div className="mb-1">
                        <label className="col-form-label">Signature 2</label>{" "}
                        <SelectForm
                          value={signature2}
                          placeholder={"Signature 2"}
                          options={signatureOptions}
                          onChange={(event) => {
                            setSignature2(event);
                          }}
                          noIndicator={false}
                          noSeparator={false}
                        />
                        <span className="" style={{ color: "red" }}>
                          {journeyLaunchErrors["signature2"]}
                        </span>
                      </div>
                    </div>

                    <div
                      className={"col-lg-6 col-sm-6 col-xs-6 mt-4"}
                      // style={{ margin: "5px" }}
                    >
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          getSignatureDropDownApiCall();
                        }}>
                        <i className="fa-solid fa-refresh"></i> Refresh
                      </button>

                      <Link
                        className="btn btn-primary"
                        style={{ marginLeft: 5 }}
                        to={"/Signature"}
                        target="_blank">
                        <i className="fa-solid fa-plus"></i> Create Signature
                      </Link>
                    </div> */}
                    <div className="col-lg-6 col-sm-6 col-xs-6">
                      <div className="mb-1">
                        <label className="col-form-label"></label>{" "}
                        <button
                          style={{ marginTop: "32px" }}
                          className="btn btn-primary"
                          onClick={() => {
                            previewCertificate();
                          }}
                        >
                          <i className="fa fa-file-pdf-o"></i> Preview
                          Certificate
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => {
              SubmitLanguage(launchType);
            }}
          >
            Submit
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setLaunchModal(false);
              setIsIssueCertificate(false);
              resetLaunchJourney();
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">Course Delink</div>
        </div>
        <Modal.Body>
          <>
            <div>
              <b>{`${modalTitle}`}</b>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={(e) => delink(e, Id)}
          >
            Submit
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={(e) => {
              e.preventDefault();
              setShowModal(false);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      {/* Launch History */}

      <Modal
        show={launchHistoryModel}
        onHide={() => setLaunchHistoryModel(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">Launch History</div>
        </div>
        <Modal.Body>
          <>
            <div className="mb-3 pt-3">
              <DynamicGrid
                data={launchHistoryData}
                columns={launchHistoryGridColumns}
                options={launchHistoryOptions}
              />
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={(e) => {
              e.preventDefault();
              setLaunchHistoryModel(false);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Programcourse;
