import React, { useState, useEffect, useContext } from "react";
import InputForm from "../../Components/InputForm/InputForm";
import SelectForm from "../../Components/SelectForm/SelectForm";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import notify from "../../Helpers/ToastNotification";
import { APICall } from "../../Helpers/APICalls";
import set from "date-fns/set/index.js";
import { FORMERR } from "dns";
import { LoaderContext } from "../../Helpers/Context/Context";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import {
  insertSequenceNumberDetails,
  insertUpdateSequenceConfigDetails,
  getLastSeqNoBySeqNo,
  getManagedSequencedDetailsById,
  getPositionSequencedDetailsById,
  insertUpdatePositionSequenceConfigDetails,
  getPosLastSeqNoBySeqNo,
} from "../../Helpers/APIEndPoints/EndPoints";
function MangeForm({
  selectCountry,
  selectLegalEntity,
  handleOnChange,
  selectedValue,
  selectEmployeeClass,
  selectSequenceNumber,
  data,
  dropdown,
  editid,
  setSelectedValue,
  activeTab,
  setActiveTab,
  setactiveType,
}) {
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  let employeeId = proxyUserDetails ? proxyUserDetails.value : userDetails?.Id;
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [sequenceNumbers, setSequenceNumbers] = useState({
    updatedSequenceNumber: null,
    // sequenceNumber:{label:null},
    lastSequenceNumber: null,
    nextSequenceNumber: null,
  });
  const [formErrors, setFormErrors] = useState({
    sequencenumber: false,
    lastsequencenumber: false,
    country: false,
    legal: false,
    // employeeclass:false,
    //lastsequence:false,
    newsequence: false,
    sequence: false,
  });
  const getManagedSequencedDetailsByIdfunc = async () => {
    showLoader();
    try {
      let apiFunct;
      if (activeTab === "position_code") {
        apiFunct = getPositionSequencedDetailsById;
      } else {
        apiFunct = getManagedSequencedDetailsById;
      }
      const response = await APICall(apiFunct, "POST", {
        ID: editid,
      });
      const { data, status } = response || {};
      setSelectedValue(
        status === 0 && data
          ? {
              ...selectedValue,
              country: {
                ...(selectedValue?.country || {}),
                value: parseInt(data.country, 10),
              },
              legal: {
                ...selectedValue.legal,
                value: parseInt(data.legalEntity, 10),
              },
              employeeclass: {
                ...selectedValue.employeeclass,
                value: parseInt(data.employeeClass, 10),
              },
              sequence: {
                ...selectedValue.sequence,
                value: data.sequenceNumberId,
              },
            }
          : null
      );
    } catch (error) {
      console.error(
        "Error in Manage Sequence Details By Id Function data",
        error
      );
    } finally {
      hideLoader();
    }
  };
  const getinsertUpdateSequenceConfigDetails = async () => {
    showLoader();
    const getCommaSeparatedValues = (data) => {
      if (!data) return "";
      return  data.map((item) => item.value).join(",");
    };
    try {
      let apiFunc;
      let payload;
      if (activeTab === "position_code") {
        apiFunc = insertUpdatePositionSequenceConfigDetails;
        payload = {
          Id: data === "position_edit" ? editid : 0,
          EmployeeClassId:  data === "position_edit" ? selectedValue?.employeeclass?.value : selectedValue?.employeeclass ? getCommaSeparatedValues(selectedValue.employeeclass):"",
          CountryId:   data === "position_edit" ? selectedValue?.country?.value : selectedValue?.country ? getCommaSeparatedValues(selectedValue.country) : "",
          SequenceNumber: selectedValue?.sequence?.value,
          LastSequenceNumber:
            sequenceNumbers.nextSequenceNumber !== null
              ? sequenceNumbers.nextSequenceNumber
              : selectedValue.newsequence,
          CreatedBy: employeeId,

        };
      } else {
        apiFunc = insertUpdateSequenceConfigDetails;
        payload = {
          Id: data === "employee_edit" ? editid : 0,
          LegalEntityId: data === "employee_edit" ? selectedValue?.legal?.value : selectedValue?.legal ? getCommaSeparatedValues(selectedValue.legal):"",
          EmployeeClassId:  data === "employee_edit" ? selectedValue?.employeeclass?.value : selectedValue?.employeeclass ? getCommaSeparatedValues(selectedValue.employeeclass):"",
          CountryId: data === "employee_edit" ?  selectedValue?.country?.value : selectedValue?.country ? getCommaSeparatedValues(selectedValue.country):"",
          SequenceNumber: selectedValue?.sequence?.value,
          LastSequenceNumber:
            sequenceNumbers.nextSequenceNumber !== null
              ? sequenceNumbers.nextSequenceNumber
              : selectedValue.newsequence,
          CreatedBy: employeeId,
        };
      }
      const response = await APICall(apiFunc, "POST", payload);
      return response;
    } catch (error) {
      console.error("Error in Insert Update Sequence Config data", error);
    } finally {
      hideLoader();
    }
  };
  const getinsertSequenceNumberDetails = async () => {
    showLoader();
    try {
      const response = await APICall(insertSequenceNumberDetails, "POST", {
        ActiveTab: activeTab,
        SequenceNumber: sequenceNumbers.updatedSequenceNumber,
        LastSequenceNumber: sequenceNumbers.lastSequenceNumber,
        CreatedBy: employeeId,
      });
      //let {data}= response || {};
      return response;
    } catch (error) {
      console.error("Error in Insert Sequence Number Details By Id", error);
    } finally {
      hideLoader();
    }
  };
  const getLastSeqNoBySeqNofunc = async () => {
    showLoader();
    try {
      let apifun;
      if (activeTab === "position_code") {
        apifun = getPosLastSeqNoBySeqNo;
      } else {
        apifun = getLastSeqNoBySeqNo;
      }
      const response = await APICall(apifun, "POST", {
        SequenceNumber: selectedValue.sequence.label,
      });
      const { data } = response || {};
      const formattedData = data ? data[0] : null;
      if (formattedData) {
        const lastSeqNum = formattedData.lastSequenceNumber;
        const nextSeqNum =
          (lastSeqNum.startsWith("0") ? "0" : "") + (Number(lastSeqNum) + 1);
        setSequenceNumbers((prevState) => ({
          ...prevState,
          lastSequenceNumber: lastSeqNum,
          nextSequenceNumber: nextSeqNum,
        }));
      }
    } catch (error) {
      console.error(
        "Error in Last SequenceNo By Sequence No function data",
        error
      );
    } finally {
      hideLoader();
    }
  };
  const handleOpenModal = () => {
    setShowModal(true);
    setSequenceNumbers((prevSequenceNumbers) => ({
      ...prevSequenceNumbers,
      updatedSequenceNumber: "",
      lastSequenceNumber: "",
    }));
  };
  const handleModalReset = () =>{
    setSequenceNumbers((prevSequenceNumbers) => ({
      ...prevSequenceNumbers,
      updatedSequenceNumber: "",
      lastSequenceNumber: "",
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      lastsequencenumber: false,
      sequencenumber: false,
    }));
  };
  const handleModalClose = () => {
    setShowModal(false);
    setSequenceNumbers((prevSequenceNumbers) => ({
      ...prevSequenceNumbers,
      updatedSequenceNumber: "",
      lastSequenceNumber: "",
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      lastsequencenumber: false,
      sequencenumber: false,
    }));
  };
  const validateField = (value, fieldName) => {
    if (fieldName == "newsequence") {
      let isEmpty = isNaN(value) ||
       value === null || value === "" ||(typeof value === 'string' && value.trim() === '');
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: isEmpty,
      }));
      return isEmpty;
    }
    if (fieldName == "sequencenumber") {
      let isEmpty =
        value === null || value === "" || !/^[a-zA-Z\d]{1,8}$/.test(value);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: isEmpty,
      }));
      return isEmpty;
    }
    if (fieldName == "lastsequencenumber") {
      let isEmpty =
        isNaN(value) ||
        value === null ||
        value === "" ||
        !/^\d{1,12}$/.test(value);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: isEmpty,
      }));
      return isEmpty;
    } else {
      const isEmpty = value === null || value === "" || (typeof value === 'string' && value.trim() === '') || ( value && value.length <= 0);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: isEmpty,
      }));
      return isEmpty;
    }
  };

  const handleModalSave = async () => {
    const isSequencenumberEmpty = validateField(
      sequenceNumbers.updatedSequenceNumber,
      "sequencenumber"
    );
    const isLastSequencenumberEmpty = validateField(
      sequenceNumbers.lastSequenceNumber,
      "lastsequencenumber"
    );
    if (isSequencenumberEmpty || isLastSequencenumberEmpty) {
      let errorMessage = "";
      if (isSequencenumberEmpty) {
        errorMessage += "sequenceNumber ";
      }
      if (isLastSequencenumberEmpty) {
        errorMessage += "lastsequenceNumber ";
      }
      errorMessage = errorMessage.trim();
      notify(1, `Please fill ${errorMessage} required field in correct format`);
      setFormErrors((previous) => ({
        ...previous,
        sequencenumber: isSequencenumberEmpty,
        lastsequencenumber: isLastSequencenumberEmpty,
      }));
    } else {
      const updatedLastSequenceNumber = sequenceNumbers.lastSequenceNumber;
      const updatedNextSequenceNumber =
        (updatedLastSequenceNumber.startsWith("0") ? "0" : "") +
        (Number(updatedLastSequenceNumber) + 1);
      setSequenceNumbers((prevState) => ({
        ...prevState,
        updatedSequenceNumber: sequenceNumbers.updatedSequenceNumber,
        // lastSequenceNumber:updatedLastSequenceNumber,
        nextSequenceNumber: updatedNextSequenceNumber,
      }));

      const responseData = await getinsertSequenceNumberDetails();
      if (responseData.status === 1) {
        notify(1, responseData.message);
      } else {
        notify(0, "Your data has been saved");
        const resp = await dropdown();
        const respLabel = resp;

        const matchFound = respLabel.find(
          (respLabel) =>
            respLabel.label === sequenceNumbers.updatedSequenceNumber
        );

        if (matchFound) {
          setSelectedValue({
            ...selectedValue,
            sequence: { label: matchFound?.label, value: matchFound?.value },
          });
        }
        setShowModal(false);
      }
    }
  };
  const handleMainReset = () => {
    setSelectedValue({
      ...selectedValue,
      country: null,
      legal: null,
      employeeclass: null,
      lastsequence: null,
      newsequence: null,
      sequencenumber: null,
      lastsequencenumber: null,
      sequence: null,
    });
  };
  const handleMainSave = async () => {
    const issequenceEmpty = validateField(
      sequenceNumbers.updatedSequenceNumber !== null
        ? sequenceNumbers.updatedSequenceNumber
        : selectedValue.sequence,
      "updatedSequenceNumber"
    );
    const isnewsequenceEmpty = validateField(
      sequenceNumbers.nextSequenceNumber !== null
        ? sequenceNumbers.nextSequenceNumber
        : selectedValue.newsequence,
      "newsequence"
    );
    const iscountryEmpty = validateField(selectedValue.country, "country");
    let islegalEmpty = false;
    //const islegalEmpty = validateField(selectedValue.legal, 'legal');
    // const isemployeeclassEmpty = validateField(selectedValue.employeeclass, 'employeeclass');
    //const islastsequenceEmpty = validateField(sequenceNumbers.lastSequenceNumber!==null?sequenceNumbers.lastSequenceNumber:selectedValue.lastsequence, 'lastsequence');
    if (activeTab !== "position_code") {
      islegalEmpty = validateField(selectedValue.legal, "legal");
    }
    if (
      isnewsequenceEmpty ||
      iscountryEmpty ||
      islegalEmpty ||
      issequenceEmpty
    ) {
      let errorMessage = "";
      if (issequenceEmpty) {
        errorMessage += "Sequence Number";
      }
      if (isnewsequenceEmpty) {
        errorMessage += "New Sequence Number ";
      }
      if (iscountryEmpty) {
        errorMessage += "Country ";
      }
      if (islegalEmpty && activeTab !== "position_code") {
        errorMessage += "LegalEntity ";
      }
      // if(isemployeeclassEmpty){
      //   errorMessage+="Employee Class ";
      // }
      //if(islastsequenceEmpty){
      //   errorMessage+="Last Sequence";
      // }
      errorMessage = errorMessage.trim();
      notify(1, `Please fill ${errorMessage} required field in correct form`);
      setFormErrors((previous) => ({
        ...previous,
        country: iscountryEmpty,
        legal: islegalEmpty,
        // employeeclass:isemployeeclassEmpty,
        //lastsequence:islastsequenceEmpty,
        newsequence: isnewsequenceEmpty,
        sequence: issequenceEmpty,
      }));
    } else {
      const responseData = await getinsertUpdateSequenceConfigDetails();
      if (responseData.status === 1) {
        notify(1, responseData.message);
      } else {
        notify(0, "Your data has been saved");
      }
      setSelectedValue({
        ...selectedValue,
        country: null,
        legal: null,
        employeeclass: null,
        lastsequence: null,
        newsequence: null,
        sequencenumber: null,
        lastsequencenumber: null,
        sequence: null,
      });
      if (data === "employee_addNew" || data === "employee_edit") {
        setActiveTab("employee_code");
        setactiveType("");
      } else if (data === "position_addNew" || data === "position_edit") {
        setActiveTab("position_code");
        setactiveType("");
      }
    }
  };
  const hadleModalBoxChanges = (value, field) => {
    // Update the state based on the field
    setSequenceNumbers((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  useEffect(() => {
    // Check if showModal is true, then set lastSequenceNumber to null
    if (showModal) {
      // selectedValue.las
      setSequenceNumbers((prevSequenceNumbers) => ({
        ...prevSequenceNumbers,
        updatedSequenceNumber: null,
        lastSequenceNumber: "",
      }));
    }
  }, [showModal]);
  useEffect(() => {
    //getinsertUpdateSequenceConfigDetails();
    if (data !== "employee_addNew" && data !== "position_addNew") {
      getManagedSequencedDetailsByIdfunc();
    }
  }, [data]);
  useEffect(() => {
    getLastSeqNoBySeqNofunc();
    if (selectedValue.sequence === null) {
      setSequenceNumbers((prevState) => ({
        ...prevState,
        lastSequenceNumber: "",
        nextSequenceNumber: "",
      }));
    }
  }, [selectedValue?.sequence]);
  useEffect(() => {
    handleMainReset();
  }, [activeTab]);
  return (
    <div className="px-3">
      <div className="row px-3 my-3">
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1">
            <label className="col-form-label">
              Country<span className="modified-asterick">*</span>
            </label>
            <div className="">
              <SelectForm
                isClearable
                options={selectCountry}
                placeholder={"Select Country"}
                isDisabled={false}
                onChange={(selectedOption) =>
                  handleOnChange(selectedOption, "country")
                }
                isMulti={(data === "employee_addNew" ||data === "position_addNew")?true:false}
                noIndicator={false}
                noSeparator={false}
                value={selectedValue?.country}
              />
              {formErrors.country && (
                <span className="modified-asterick">Required</span>
              )}
            </div>
          </div>
        </div>
        {activeTab !== "position_code" && (
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1 ">
              <label className="col-form-label">
                Legal Entity<span className="modified-asterick">*</span>
              </label>
              <div className="">
                <SelectForm
                  isClearable
                  options={selectLegalEntity}
                  placeholder={"Select Legal Entity"}
                  isDisabled={false}
                  onChange={(selectedOption) =>
                    handleOnChange(selectedOption, "legal")
                  }
                  isMulti={(data === "employee_addNew")?true:false}
                  noIndicator={false}
                  noSeparator={false}
                  value={selectedValue.legal}
                />
                {formErrors.legal && (
                  <span className="modified-asterick">Required</span>
                )}
              </div>
            </div>
          </div>
        )}
        {/* {activeTab !== "position_code" && ( */}
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1 ">
              <label className="col-form-label">
                Employee Class
                {/* <span className="modified-asterick">*</span> */}
              </label>
              <SelectForm
                isClearable
                options={selectEmployeeClass}
                placeholder={"Select Employee Class"}
                isDisabled={false}
                onChange={(selectedOption) =>
                  handleOnChange(selectedOption, "employeeclass")
                }
                isMulti={(data === "employee_addNew" || data ==="position_addNew")?true:false}
                noIndicator={false}
                noSeparator={false}
                value={selectedValue.employeeclass}
              />
              {/* {
                      formErrors.employeeclass &&(<span className="modified-asterick">Required</span>)
                    } */}
            </div>
          </div>
        {/* )} */}
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1 ">
            <label className="col-form-label">
              Sequence Number<span className="modified-asterick">*</span>
            </label>
            <div className="row">
              <SelectForm
                isClearable
                options={selectSequenceNumber}
                placeholder={"Select Sequence Number"}
                isDisabled={false}
                onChange={(selectedOption) =>
                  handleOnChange(selectedOption, "sequence")
                }
                isMulti={false}
                noIndicator={false}
                noSeparator={false}
                value={selectedValue?.sequence}
                className="col-lg-10"
              />
              <button
                className="btn btn-primary col-lg-2"
                onClick={handleOpenModal}
              >
                <i className="fa fa-plus"></i>
              </button>
              {formErrors.sequence && (
                <span className="modified-asterick">Required</span>
              )}
            </div>
          </div>
        </div>
        {
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Last Sequence Number<span className="modified-asterick">*</span>
              </label>
              <div className="">
                <InputForm
                  className="form-control form-control"
                  value={
                    sequenceNumbers.lastSequenceNumber !== null
                      ? sequenceNumbers.lastSequenceNumber
                      : selectedValue.lastsequence
                  }
                  placeholder="Last Sequence Number"
                  isDisabled={true}
                  textArea={false}
                  onChange={(e) =>
                    handleOnChange(e.target.value, "lastsequence")
                  }
                />
                {/* {
                      formErrors.lastsequence &&(<span className="modified-asterick">Required</span>)
                    } */}
              </div>
            </div>
          </div>
        }
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1 ">
            <label className="col-form-label">
              Next Sequence Number<span className="modified-asterick">*</span>
            </label>
            <div className="">
              <InputForm
                value={
                  sequenceNumbers.nextSequenceNumber !== null
                    ? sequenceNumbers.nextSequenceNumber
                    : selectedValue.newsequence
                }
                placeholder="New Sequence Number"
                isDisabled={false}
                textArea={false}
                onChange={(e) =>
                  hadleModalBoxChanges(e.target.value, "nextSequenceNumber")
                }
              />
              {formErrors.newsequence && (
                <span className="modified-asterick">Required</span>
              )}
            </div>
          </div>
        </div>
        <Modal
          show={showModal}
          onHide={handleModalClose}
          backdrop="static"
          keyboard={false}
          size="sm"
          centered
        >
          <div
            className="bg-secondary text-white"
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <div className="col-lg-12">Confirm!</div>
          </div>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6">
                <div className="mb-1 ">
                  <label className="col-form-label">
                    Sequence Number<span className="modified-asterick">*</span>
                  </label>
                  <div className="">
                    <InputForm
                      value={sequenceNumbers.updatedSequenceNumber}
                      placeholder="Enter Sequence Number"
                      isDisabled={false}
                      textArea={false}
                      onChange={(e) =>
                        hadleModalBoxChanges(
                          e.target.value,
                          "updatedSequenceNumber"
                        )
                      }
                    />
                    {formErrors.sequencenumber && (
                      <span className="modified-asterick">Required</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-1 ">
                  <label className="col-form-label">
                    Last Sequence Number
                    <span className="modified-asterick">*</span>
                  </label>
                  <div className="">
                    <InputForm
                      value={sequenceNumbers.lastSequenceNumber}
                      placeholder="Enter Last Sequence Number"
                      isDisabled={false}
                      textArea={false}
                      onChange={(e) =>
                        hadleModalBoxChanges(
                          e.target.value,
                          "lastSequenceNumber"
                        )
                      }
                    />
                    {formErrors.lastsequencenumber && (
                      <span className="modified-asterick">Required</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
          <button
              type="button"
              className="btn btn-primary ml-2"
              onClick={handleModalSave}
            >
              Save
            </button>
            <button
              type="button"
              disabled={false}
              className="btn btn-primary ml-3"
              onClick={handleModalClose}
            >
              <i className="fa fa-x" aria-hidden="true"></i> Close{" "}
            </button>
            <button
              type="button"
              disabled={false}
              className="btn btn-secondary ml-3"
              onClick={handleModalReset}
            >
                <i className="fa fa-undo" aria-hidden="true"></i> Reset{" "}
            </button>
          </Modal.Footer>
        </Modal>
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1 ">
            <label className="col-form-label"> </label>
            <div className="">
              <button
                type="button"
                disabled={false}
                className="btn btn-secondary mt-3 ml-3"
                onClick={handleMainReset}
              >
                <i className="fa fa-undo" aria-hidden="true"></i> Reset{" "}
              </button>{" "}
              <button
                type="button"
                className="btn btn-primary mt-3 ml-2"
                onClick={handleMainSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MangeForm;
