import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
  } from "react";
  import { LoaderContext } from "../../Helpers/Context/Context";
  import { APICall } from "../../Helpers/APICalls";
  import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Tooltip,
  } from "@mui/material";
  import {
    getRoleDetailsProgression,
    viewDocument,
    getDocuments,
    getSearchableDropdowns,
    getParenPBasedonIncm,
    getAccountValidation,
  } from "../../Helpers/APIEndPoints/EndPoints";
  import { NationalIdCardType } from "../../Components/FromStructure/NationalIdCardTypeRegex";
  import notify from "../../Helpers/ToastNotification";
  import moment from "moment";
  import { ExpandMore, Satellite, Try, TryRounded } from "@mui/icons-material";
  import { Modal } from "react-bootstrap";
  import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
  import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
  import uuid from "react-uuid";
  import SelectForm from "../../Components/SelectForm/SelectForm";
  import InputForm from "../../Components/InputForm/InputForm";
  import DateForm from "../../Components/DateForm/DateForm";
  import { useLocation } from "react-router-dom";
  import useDataChangeStore from "../../Helpers/ZustandStore/DataChangeSectionStore";
  import useDocumentStore from "../../Helpers/ZustandStore/DocumentForProgressionStore";
  import Loader from "../../Helpers/Loader/Loader";

  const SectionComponentPrintAndPreview = ({
    TId,
    setTId,
    formData,
    setFormData,
    section: ogSection,
    sectionIndex,
    sectionValuesArray,
    SetSectionValuesArray,
    Globalv,
    COU,
    submitFunc,
    setGetData,
    validationTrigger = false,
    IsSendBack = false,
    payrolldd = null,
    countrydd = null,
    enableForTextileWorkerWfl = false,
    effectiveDate = null,
    printChangesAccordion,
  }) => {
    const [hasValue, setHasValue] = useState(
      sectionValuesArray.filter((es) => es.sectionSystemName == ogSection?.SN) ||
        null
    );
    const { documents, insertDocument, updateDocument }: any = useDocumentStore();
    const [sectionId, setSectionId] = useState(0);
    const [globalEC, setGlobalEC] = useState(Globalv);
    const [section, setSection] = useState(ogSection);
    const [accordion, setAccordion] = useState( true);
    //const [accordion, setAccordion] = useState(sectionIndex === 0 ? true : false);
    const [optionsObj, setOptionsObj] = useState<any>({});
    const [gridData, setGridData] = useState([]);
    const [displayGrid, setDisplayGrid] = useState([]);
    const [gridColumns, setGridColumns] = useState([]);
    const [ogAttributeSet, setOgAttributeSet] = useState({});
    const [ogFilledAttributeSet, setOgFilledAttributeSet] = useState([]);
    const [currentGridId, setCurrentGridId] = useState(null);
    const [deleteGridId, setDeleteGridId] = useState(null);
    const [showAttr, setshowAttr] = useState(
      //  section?.ISEDIT ?
      false
      //  : true
    );

    const [wholeaAttributeSetData, setWholseAttributeSetData] = useState([]);

    // value Object for section
    const [attributesData, setAttributesData] = useState<any>({});

    const [attributeSetData, setAttributeSetData] = useState<any>({});

    const [attributeSetCode, setAttributeSetCode] = useState(null);
    const [isOnce, setIsOnce] = useState(true);
    const [isValidateOnce, setIsValidateOnce] = useState(true);

    const [attributeValidation, setAttributeValidation] = useState<any>({});
    const [attributeSetValidation, setAttributeSetValidation] = useState<any>({});

    const [dataFilled, setDataFilled] = useState(false);

    const [sectionHasDocuments, setSectionHasDocuments] = useState(false);

    const { showLoader, hideLoader } = useContext(LoaderContext);
    // const [isLoading, setIsLoading] = useState(false);
    const { userDetails, currentRoleId }: any = useUserContext();

    const [PageSize, setPageSize] = useState(20);
    const [page, setPage] = useState(0);

    const [fillDDValuesForGrid, setFillDDValuesForGrid] = useState([]);
    const [dAutomapAttribute, setdAutomapAttribute] = useState([]);
    const [modalHeader, setModalHeader] = useState("");
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    let validAccount = useRef(false);
    const [confirmationPromise, setConfirmationPromise] = useState(null);
    const [ShowAttrSet, setShowAttrSet] = useState(false);
    const [multiDropdown, setMultiDropdown] = useState(true);
    const {
      sectionsData,
      sendBackJson,
      updateSectionData,
      setSectionDataAndJsonData,
    }: any = useDataChangeStore();

    const [addressType, setAddressType] = useState<any>({});

    const [selectedFilesForFlat, setSelectedFilesForFlat] = useState<
      {
        AC: string;
        Name: string;
        Data: string;
        IsValidFile: boolean;
        DocId: Number;
        Type: string;
        Context: string;
        DCVALUE: string;
        DVTILL: string;
        DSTATUS: string;
        DPSINCE: string;
        DPWITH: string;
      }[]
    >([]);

    const [selectedFilesForSet, setSelectedFilesForSet] = useState<
      {
        AC: string;
        Id: string | number;
        Name: string;
        Data: string;
        IsValidFile: boolean;
        DocId: Number;
        Type: string;
        Context: string;
        DCVALUE: string;
        DVTILL: string;
        DSTATUS: string;
        DPSINCE: string;
        DPWITH: string;
      }[]
    >([]);

    const [newGridIdCreated, setNewGridIdCreated] = useState(null);
    const [disableSaveGrid, setDisableSaveGrid] = useState(false);
    const [effectiveDateCode, setEffectiveDateCode] = useState<any>(null);
//Del
//Del
//Del
    useEffect(() => {
      let attributes = {};
      let attributeSet = {};
      const gridColumnsArr = [];
      let dropdownOptionsObj = {};
      const sectionHasDocuments = [];
      section?.Attribute?.forEach(async (eachAttribute) => {
        if (eachAttribute.AT === "Attribute") {
          const defaultAttributeValue =
            eachAttribute.DT === "DropdownSingle" ||
            eachAttribute.DT === "Date" ||
            eachAttribute.DT === "SearchableDropdownSingle" ||
            eachAttribute.DT === "Document" ||
            eachAttribute.DT === "DropdownMultiple"
              ? null
              : eachAttribute.DT === "Text" || eachAttribute.DT === "Number"
              ? ""
              : eachAttribute.DT === "Checkbox"
              ? false
              : "";

          setAttributesData((prev) => ({
            ...prev,
            [eachAttribute.AC]: defaultAttributeValue,
          }));

          attributes = {
            ...attributes,
            [eachAttribute.AC]: defaultAttributeValue,
          };

          if (eachAttribute.DT === "Document") {
            setSectionHasDocuments(true);
            sectionHasDocuments.push(eachAttribute.AC);
          }

          setAttributeValidation((prev) => ({
            ...prev,
            [eachAttribute.AC]: JSON.parse(eachAttribute.V),
          }));

          setAttributeValidation((prev) => ({
            ...prev,
            [eachAttribute.AC]: {
              ...prev[eachAttribute.AC],
              DD: eachAttribute.DD !== null ? JSON.parse(eachAttribute.DD) : null,
              isDisable: false,
            },
          }));

          if (
            eachAttribute.DT === "DropdownSingle" ||
            eachAttribute.DT === "DropdownMultiple"
          ) {
            dropdownOptionsObj = {
              ...dropdownOptionsObj,
              [eachAttribute.AC]: eachAttribute.OPT,
            };

            setOptionsObj((prev) => ({
              ...prev,
              [eachAttribute.AC]: eachAttribute.OPT,
            }));

            if (JSON.parse(eachAttribute.AD).DF !== undefined) {
              const filteredOPT = eachAttribute.OPT?.filter(
                (o) => o.label?.toLowerCase() === JSON.parse(eachAttribute.AD).DF
              );
              if (filteredOPT.length > 0) {
                setAttributesData((prev) => ({
                  ...prev,
                  [eachAttribute.AC]: filteredOPT[0],
                }));
                attributeSet = {
                  ...attributeSet,
                  [eachAttribute.AC]: filteredOPT[0],
                };
              }
            }
          } else if (eachAttribute.DT === "SearchableDropdownSingle") {
            dropdownOptionsObj = {
              ...dropdownOptionsObj,
              [eachAttribute.AC]: "SearchableDropdownSingle",
            };

            setOptionsObj((prev) => ({
              ...prev,
              [eachAttribute.AC]: "SearchableDropdownSingle",
            }));
          }
        } else if (eachAttribute.AT === "AttributeSet") {
          setAttributeSetCode(eachAttribute.AC);

          gridColumnsArr.push({
            name: "Id",
            label: "Id",
            options: { display: false },
          });

          // MEthod 1: Hardcode condiion for Each Section:
          // if (section.SN === 'BankInformation'){
          //   setEffectiveDateCode('EFFCTVDT');
          // }

          // MEthod 2: Find string 'Effective Date' as label from Attributes array:
          const effectDateObj = eachAttribute.Attribute.find(
            (item) => item.AN === "Effective Date"
          );
          if (effectDateObj !== null && effectDateObj !== undefined) {
            setEffectiveDateCode(effectDateObj?.AC);
          }

          eachAttribute.Attribute.forEach(async (subAttribute) => {
            if (subAttribute.DT === "Document") {
              setSectionHasDocuments(true);
              sectionHasDocuments.push(subAttribute.AC);
            }

            const display =
              JSON.parse(subAttribute.AD)?.ISOG?.toLowerCase() === "true"
                ? true
                : true;

            gridColumnsArr.push({
              name: subAttribute.AC,
              label: subAttribute.AN,
              options: { sort: false, display },
            });

            setAttributeSetValidation((prev) => ({
              ...prev,
              [subAttribute.AC]: JSON.parse(
                subAttribute.AC === "ATTP" &&
                  ((globalEC[0] != undefined &&
                    globalEC[0].ecLabel === "Staff") ||
                    (globalEC[0] != undefined &&
                      globalEC[0].ecLabel === "Retainer"))
                  ? '{"ISM": "true","FS": "5","FX": ".docx,.doc,.pdf,.png,.jpg,.jpeg,.xlsx,.txt,.ppt,.pptx","FC": "1"}'
                  : subAttribute.AC === "BNKATTCH" &&
                    ((globalEC[0] != undefined &&
                      globalEC[0].ecLabel === "Staff") ||
                      (globalEC[0] != undefined &&
                        globalEC[0].ecLabel === "Retainer"))
                  ? '{"ISM": "true","FS": "5","FX": ".docx,.doc,.pdf,.png,.jpg,.jpeg,.xlsx,.txt,.ppt,.pptx","FC": "1"}'
                  : subAttribute.V
              ),
            }));

            setAttributeSetValidation((prev) => ({
              ...prev,
              [subAttribute.AC]: {
                ...prev[subAttribute.AC],
                DD: subAttribute.DD !== null ? JSON.parse(subAttribute.DD) : null,
                // isDisable: false,
                isDisable: subAttribute.AC === "OTHLEN" ? true : false,
              },
            }));

            const defaultAttributeValue =
              subAttribute.DT === "DropdownSingle" ||
              subAttribute.DT === "Date" ||
              subAttribute.DT === "SearchableDropdownSingle" ||
              subAttribute.DT === "Document" ||
              subAttribute.DT === "DropdownMultiple"
                ? null
                : subAttribute.DT === "Text" || subAttribute.DT === "Number"
                ? ""
                : subAttribute.DT === "Checkbox"
                ? false
                : "";

            setAttributeSetData((prev) => ({
              ...prev,
              [subAttribute.AC]: defaultAttributeValue,
            }));

            attributeSet = {
              ...attributeSet,
              [subAttribute.AC]: defaultAttributeValue,
            };

            if (
              subAttribute.DT === "DropdownSingle" ||
              subAttribute.DT === "DropdownMultiple"
            ) {
              dropdownOptionsObj = {
                ...dropdownOptionsObj,
                [subAttribute.AC]: subAttribute.OPT,
              };

              if (
                subAttribute.AC === "STSE" &&
                section.SN === "FamilyMemberDetails"
              ) {
                if (subAttribute.OPT?.length > 0) {
                  setAttributeSetData((prev) => ({
                    ...prev,
                    [subAttribute.AC]: subAttribute.OPT?.filter(
                      (x) => x.label.toLowerCase() === "living"
                    )[0],
                  }));
                  attributeSet = {
                    ...attributeSet,
                    [subAttribute.AC]: subAttribute.OPT?.filter(
                      (x) => x.label.toLowerCase() === "living"
                    )[0],
                  };
                }
              }

              if (
                subAttribute.AC === "PVSEMP" &&
                section.SN === "PreviousEmployment"
              ) {
                if (subAttribute.OPT?.length > 0) {
                  setAttributeSetData((prev) => ({
                    ...prev,
                    [subAttribute.AC]: subAttribute.OPT?.filter(
                      (x) => x.label.toLowerCase() === "living"
                    )[0],
                  }));
                  attributeSet = {
                    ...attributeSet,
                    [subAttribute.AC]: subAttribute.OPT?.filter(
                      (x) => x.label.toLowerCase() === "living"
                    )[0],
                  };
                }
              }

              setOptionsObj((prev) => ({
                ...prev,
                [subAttribute.AC]: subAttribute.OPT,
              }));

              if (JSON.parse(subAttribute.AD).DF !== undefined) {
                const filteredOPT = subAttribute.OPT?.filter(
                  (o) => o.label?.toLowerCase() === JSON.parse(subAttribute.AD).DF
                );
                if (filteredOPT.length > 0) {
                  setAttributeSetData((prev) => ({
                    ...prev,
                    [subAttribute.AC]: filteredOPT[0],
                  }));
                  attributeSet = {
                    ...attributeSet,
                    [subAttribute.AC]: filteredOPT[0],
                  };
                }
              }
            } else if (subAttribute.DT === "SearchableDropdownSingle") {
              dropdownOptionsObj = {
                ...dropdownOptionsObj,
                [subAttribute.AC]: "SearchableDropdownSingle",
              };

              setOptionsObj((prev) => ({
                ...prev,
                [subAttribute.AC]: "SearchableDropdownSingle",
              }));
            }
          });
        }
      });

      setOgAttributeSet(attributeSet);
      setGridColumns((prev) => [...prev, ...gridColumnsArr]);
    //   setGridColumns((prev) => [...prev, ...gridColumnsArr, action]);
      const fillData = async () => {
        showLoader();
        if (isOnce && TId !== 0) {
          let attributesRes = hasValue[0]?.attributes || [];
          let flatArr = {};
          let mainArr = {};
          let setArr = {};
          let filledData = [];
          let mainJsonArr = [];
          let gridData = [];
          const sectionId = section?.SId;

          // Handle flat attributes

            if (attributesRes.length > 0) {
              let attirbutes = section?.Attribute || [];
              let flatAttributeObj: any = {};

              for (const attribute of attirbutes) {
                if (sectionHasDocuments.length > 0) {
                  // getDocuments for Flat
                  for (const AC of sectionHasDocuments) {
                    if (attribute.AC === AC) {
                      await showLoader();
                      const getDocument = await APICall(getDocuments, "POST", {
                        TId,
                        AC: AC,
                      });

                      if (getDocument && getDocument.data !== null) {
                        setSelectedFilesForFlat(getDocument.data.files);
                        //add doc in global state
                        if (getDocument.data.files.length > 0) {
                          let documents = getDocument.data.files;
                          documents = documents.map((doc) => ({
                            ...doc,
                            IsNew: false,
                            IsActive: true,
                            MN: "EmployeeCentral",
                            TN: "Personal",
                            SN: section.SN,
                            IsValidFile: true,
                          }));
                          insertDocument(documents);
                        }
                      } else {
                        setSelectedFilesForFlat([]);
                      }
                      await hideLoader();
                    }
                  }
                }

                const attributeCode = attribute["AC"];
                if (attribute["AT"] == "Attribute") {
                  let filterAttributeRes = attributesRes?.find(
                    (i) => i["key"] == attributeCode
                  );

                  if (attribute["DT"]) {
                    if (attributeCode == "IN") {
                    }
                    switch (attribute["DT"]) {
                      case "DropdownSingle":
                      case "DropdownMultiple":
                        let options = dropdownOptionsObj[attributeCode] || [];
                        let filteredOption = options?.find(
                          (option) => option.value == filterAttributeRes?.value
                        );
                        flatAttributeObj[attributeCode] = filteredOption || "";
                        break;
                      case "Text":
                      case "Number":
                        flatAttributeObj[attributeCode] =
                          filterAttributeRes?.value || "";
                        break;
                      case "SearchableDropdownSingle":
                        const DDResponse = await getSearchableDropdownAPI(
                          "",
                          attributeCode,
                          null,
                          filterAttributeRes?.value || 0,
                          "attribute",
                          0
                        );

                        if (DDResponse?.label && DDResponse?.value) {
                          flatAttributeObj[attributeCode] = DDResponse
                            ? {
                                label: DDResponse?.label,
                                value: DDResponse?.value,
                              }
                            : null;
                        } else {
                          flatAttributeObj[attributeCode] = null;
                        }
                        break;
                      case "Date":
                        if (
                          filterAttributeRes?.value &&
                          moment(filterAttributeRes?.value).isValid()
                        ) {
                          flatAttributeObj[attributeCode] = moment(
                            filterAttributeRes?.value
                          ).format("DD-MMM-YYYY");
                        } else {
                          flatAttributeObj[attributeCode] = null;
                        }
                        break;
                      default:
                        if (attributeCode == "IsActive") {
                          flatAttributeObj[attributeCode] =
                            filterAttributeRes?.value;
                        } else {
                          flatAttributeObj[attributeCode] =
                            filterAttributeRes?.value;
                        }
                        break;
                    }
                  }
                } else if (attribute["AT"] == "AttributeSet") {
                  if (sectionHasDocuments.length > 0) {
                    // getDocuments for Set
                    for (const AC of sectionHasDocuments) {
                      if (attributeSet.hasOwnProperty(AC)) {
                        await showLoader();
                        const getDocument = await APICall(getDocuments, "POST", {
                          TId,
                          AC: AC,
                        });

                        if (getDocument && getDocument.data !== null) {
                          setSelectedFilesForSet(getDocument.data.files);
                          //add doc in global state
                          if (getDocument.data.files.length > 0) {
                            let documents = getDocument.data.files;
                            documents = documents.map((doc) => ({
                              ...doc,
                              IsNew: false,
                              IsActive: true,
                              MN: "EmployeeCentral",
                              TN: "Personal",
                              SN: section.SN,
                              IsValidFile: true,
                            }));
                            insertDocument(documents);
                          }
                        } else {
                          setSelectedFilesForSet([]);
                        }
                        await hideLoader();
                      }
                    }
                  }

                  const subAttribute = attribute["Attribute"];
                  const details = attirbutes["details"];

                  if (Array.isArray(subAttribute)) {
                    const filterAttributeRes = attributesRes?.find(
                      (i) => i?.key == attributeCode
                    );
                    const attributesResDetails =
                      filterAttributeRes?.details || [];

                    for (const detail of attributesResDetails) {
                      let showGridObj = {};
                      let valueGridObj = {};
                      let mainObj = {};
                      for (const key in detail) {
                        if (key == "Id") {
                          showGridObj[key] = detail[key];
                          valueGridObj[key] = detail[key];
                          mainObj[key] = detail[key];
                        } else {
                          // find the type

                          const subAttributeDetails = subAttribute?.find(
                            (i) => i?.AC == key
                          );

                          let value = detail[key];
                          if (subAttributeDetails) {
                            switch (subAttributeDetails["DT"]) {
                              case "DropdownSingle":
                              case "DropdownMultiple":
                                let options = subAttributeDetails["OPT"] || [];

                                let filteredOption = options?.find(
                                  (option) => option.value == value
                                );

                                showGridObj[key] = filteredOption?.label || "";
                                valueGridObj[key] = filteredOption || null;
                                mainObj[key] = filteredOption;
                                break;

                              case "Text":
                              case "Number":
                                showGridObj[key] = value;
                                valueGridObj[key] = value;
                                mainObj[key] = value;
                                break;
                              case "SearchableDropdownSingle":
                                const searchAblefilteredOption =
                                  await getSearchableDropdownAPI(
                                    "",
                                    key,
                                    null,
                                    value,
                                    "attribute",
                                    0
                                  );
                                showGridObj[key] =
                                  searchAblefilteredOption?.label;
                                valueGridObj[key] = searchAblefilteredOption;
                                mainObj[key] = searchAblefilteredOption;
                                break;
                              case "Date":
                                if (value && moment(value).isValid()) {
                                  showGridObj[key] =
                                    moment(value).format("DD-MMM-YYYY");
                                  valueGridObj[key] =
                                    moment(value).format("DD-MMM-YYYY");
                                  mainObj[key] =
                                    moment(value).format("DD-MMM-YYYY");
                                } else {
                                  showGridObj[key] = "";
                                  valueGridObj[key] = null;
                                  mainObj[key] = null;
                                }
                                break;
                              default:
                                if (key == "IsActive") {
                                  showGridObj[key] = value;
                                  valueGridObj[key] = value;
                                  mainObj[key] = value;
                                } else {
                                  showGridObj[key] = value || "";
                                  valueGridObj[key] = value || "";
                                  mainObj[key] = value || "";
                                }
                                break;
                            }
                          } else {
                            if (key == "IsActive") {
                              showGridObj[key] = value;
                              valueGridObj[key] = value;
                              mainObj[key] = value;
                            } else {
                              showGridObj[key] = value || "";
                              valueGridObj[key] = value || "";
                              mainObj[key] = value || "";
                            }
                          }
                        }
                      }
                      showGridObj["IsActive"] = true;
                      valueGridObj["IsActive"] = true;
                      mainObj["IsActive"] = true;
                      gridData.push(showGridObj);
                      filledData.push(valueGridObj);
                      mainJsonArr.push(mainObj);
                    }
                  }
                  setArr = { [attributeCode]: mainJsonArr };
                  setDisplayGrid(gridData);
                  setGridData(gridData);
                  setOgFilledAttributeSet(filledData);
                  setWholseAttributeSetData(mainJsonArr);
                }
              }

              //hard code to fill display name on load
              if (section.SN === "Basic") {
                let name =
                  flatAttributeObj.MN !== null &&
                  flatAttributeObj.MN !== undefined &&
                  flatAttributeObj.MN !== ""
                    ? `${flatAttributeObj.FN} ${flatAttributeObj.MN} ${flatAttributeObj.LN}`.trim()
                    : `${flatAttributeObj.FN} ${flatAttributeObj.LN}`.trim();
                flatAttributeObj = {
                  ...flatAttributeObj,
                  ["DN"]: `${name}`.trim(),
                };

                if (
                  flatAttributeObj.DOB != null ||
                  flatAttributeObj.DOB != undefined ||
                  flatAttributeObj.DOB != ""
                ) {
                  const ageInYears = moment().diff(
                    moment(flatAttributeObj.DOB).format(),
                    "years"
                  );

                  flatAttributeObj = {
                    ...flatAttributeObj,
                    ["AGE"]: ageInYears,
                  };
                }
              }

              flatArr = flatAttributeObj;
              setAttributesData(flatAttributeObj);
              mainArr = { [sectionId]: { ...setArr, ...flatArr } };
              setSectionDataAndJsonData(mainArr);
            } else if (attributesRes.length === 0){
              let attirbutes = section?.Attribute || [];
              let flatAttributeObj: any = {};

              const filterSectionData = sendBackJson[section?.SId];

              for (const attribute of attirbutes) {
                const attributeCode = attribute["AC"];
                if (attribute["AT"] == "Attribute") {
                  if (attribute["DT"]) {
                    switch (attribute["DT"]) {
                      case "DropdownSingle":
                      case "DropdownMultiple":
                        flatAttributeObj[attributeCode] = "";
                        break;
                      case "Text":
                      case "Number":
                        flatAttributeObj[attributeCode] = "";
                        break;
                      case "SearchableDropdownSingle":
                        flatAttributeObj[attributeCode] = null;
                        break;
                      case "Date":
                        flatAttributeObj[attributeCode] = null;
                        break;
                      default:
                        break;
                    }
                  }
                }
              }

              flatArr = flatAttributeObj;
              mainArr = { [sectionId]: { ...setArr, ...flatArr } };
              setSectionDataAndJsonData(mainArr);

              mainArr = { [sectionId]: { ...setArr, ...flatArr } };
            }

          setIsOnce(false);
        }

        hideLoader();
      };

      const fillTimer = setTimeout(() => {
        clearTimeout(fillTimer);
        fillData();
      }, 2000);

      //save in state
      return () => {
        clearTimeout(fillTimer);
      };
    }, []);

    const getSearchableDropdownAPI = async (
      searchString,
      AC,
      cb,
      valueForDropdown,
      type,
      gridId
    ) => {
      if (
        searchString === "" &&
        cb === null &&
        valueForDropdown !== "" &&
        AC !== ""
      ) {
        const response = await APICall(getSearchableDropdowns, "POST", {
          searchString,
          AC,
          valueForDropdown,
        });
  
        if (response.data !== null && response.data.length > 0) {
          if (response.data.length === 1) {
            if (gridId !== 0) {
              let ddObj = {
                gridId,
                AC,
                DDResponse: response.data[0],
              };
              setFillDDValuesForGrid((prev) => [...prev, ddObj]);
            } else {
              return response.data[0];
              //fillSearchableDDValuesOnEdit(response.data[0], AC);
            }
          }
        }
      } else if (searchString.length > 1) {
        const response = await APICall(getSearchableDropdowns, "POST", {
          searchString,
          AC,
        });
        if (response.data !== null && response.data.length > 0) {
          cb(response.data);
          setOptionsObj((prev) => ({ ...prev, [AC]: response.data }));
        } else {
          cb([]);
          setOptionsObj((prev) => ({ ...prev, [AC]: [] }));
        }
      }
    };
//Del
//rehne de
    useEffect(() => {
      // ;
      if (
        attributesData !== undefined &&
        Object.keys(attributesData).length > 0 &&
        attributeValidation !== undefined &&
        Object.keys(attributeValidation).length > 0
      ) {
        if (
          (isValidateOnce && isOnce === false && TId !== 0) ||
          (isValidateOnce && isOnce === true && TId === 0)
        ) {
          let attributeValues = { ...attributesData }; // for checking values
          let attributeValObj = { ...attributeValidation };
          Object.keys(attributeValObj).forEach(async (attribute) => {
            if (
              attributeValObj[attribute].DD !== null &&
              attributeValObj[attribute].DD.length > 0
            ) {
              let ad = attributeValObj[attribute].DD;

              ad.forEach(async (record) => {
                let CCode = record.CCode;
                let PValue = record.PValue;
                let Condition = record.Condition;
                let Flag = record.Flag;
                let PDT = record.PDT;
                let CDT = record.CDT;

                if (PDT?.toLowerCase() === "checkbox") {
                  if (
                    attributeValues[attribute].toString()?.toLowerCase() ===
                      PValue?.toLowerCase() ||
                    attributeValues[attribute].toString()?.toLowerCase() === ""
                  ) {
                    if (Flag === "disable") {
                      attributeValObj[CCode].isDisable = true;
                      attributeValObj[CCode].ISM = "false";
                    }
                  } else if (
                    attributeValues[attribute].toString()?.toLowerCase() !==
                    PValue?.toLowerCase()
                  ) {
                    if (Flag === "disable") {
                      attributeValObj[CCode].isDisable = false;
                      attributeValObj[CCode].ISM = "true";
                    }
                  }
                } else if (PDT === "DropdownSingle") {
                  if (attribute === "MARITLST") {
                    if (attributeValues[attribute]?.label === "Married") {
                      attributeValObj["ProofBD"].ISM = "false";
                    }
                  }
                  if (
                    attributeValues[attribute] !== null &&
                    attributeValues[attribute]?.label
                      ?.toString()
                      ?.toLowerCase() === PValue?.toLowerCase()
                  ) {
                    //check condition
                    if (Flag === "disable") {
                      attributeValObj[CCode].isDisable = true;
                      attributeValObj[CCode].ISM = "false";
                    }
                  } else {
                    if (attributeValObj[CCode] !== undefined) {
                      attributeValObj[CCode].isDisable = false;
                      attributeValObj[CCode].ISM =
                        attributeValues[attribute]?.label === "Married"
                          ? "true"
                          : CCode === "MARITLSTS"
                          ? "false"
                          : attributeValues[attribute]?.label === "Worker"
                          ? "true"
                          : CCode === "SC"
                          ? "false"
                          : "true";
                    }
                  }
                } else if (PDT === "Document") {
                }
              });
            }
            // else {
            //   if (attribute === "Gen") {
            //     if (attributeValues[attribute]?.label === "Female") {
            //       attributeValObj["MAN"].isDisable = false;
            //       attributeValObj["MAN"].ISM = "true";
            //     }
            //   }
            // }
          });
          setAttributeValidation((prev) => ({ ...prev, ...attributeValObj }));
          setIsValidateOnce(false);
        }
      }
    }, [attributesData, isOnce]);
//rehne de
    useEffect(() => {
      if (currentGridId) {
        let attributeSetData = wholeaAttributeSetData?.find(
          (record) => record.Id === currentGridId
        );
        if (attributeSetData.PA != undefined && attributeSetData.PA != null) {
          if (
            attributeSetData.PA.label?.toLowerCase() === "emergency" ||
            attributeSetData.PA.label?.toLowerCase() === "permanent"
          ) {
            setOptionsObj((prev) => ({
              ...prev,
              ["ADRTYP"]: [],
            }));

            let attributeSetValObj = {
              ...attributeSetValidation,
            };
            attributeSetValObj.ADRTYP.ISM = "false";
            if (attributeSetData.PA.label.toLowerCase() === "permanent") {
              attributeSetValObj.ADRTYP.isDisable = "true";
            }
            setAttributeSetValidation((prev) => ({
              ...prev,
              ...attributeSetValObj,
            }));
          }
        }

        if (
          attributeSetData.IDCATY != undefined &&
          attributeSetData.IDCATY != null
        ) {
          let attributeSetValObj = {
            ...attributeSetValidation,
          };
          if (
            attributeSetData.IDCATY.label.toLowerCase() === "aadhar card" ||
            attributeSetData.IDCATY.label.toLowerCase() === "pan card"
          ) {
            if (
              attributeSetData.IDCATY.label.toLowerCase() === "aadhar card" ||
              attributeSetData.IDCATY.label.toLowerCase() === "pan card"
            ) {
              attributeSetValObj.NTNLATTCH.ISM = "true";
            } else {
              attributeSetValObj.NTNLATTCH.ISM = "false";
            }
          } else {
            attributeSetValObj.NTNLATTCH.ISM = "false";
          }
        }

        if (
          attributeSetData.PVSEMP != undefined &&
          attributeSetData.PVSEMP != null
        ) {
          dependentValidations(attributeSetData.PVSEMP, "PVSEMP", "Attributeset");
        }
        setAttributeSetData(attributeSetData);
      }
    }, [currentGridId]);

    const gridOptions = {
      showEmptyDataSourceMessage: true,
      selectableRows: "none",
      //count: displayGrid.length,
      rowsPerPage: PageSize,
      page: page,
      serverSide: false,
      rowsPerPageOptions: [],
      download: false,
      print: false,
      viewColumns: false,
      filter: false,
      search: false,
      responsive: "vertical", //standard | vertical | simple
      onChangeRowsPerPage: (num) => {},

      onColumnSortChange: async (sortColumn, sortDirection) => {
        if (sortDirection === "asc") {
        }
        if (sortDirection === "desc") {
        }
      },

      textLabels: {
        body: {
          noMatch: "No data found",
        },
      },
    };

    const dependentValidations = (
      targetValue,
      targetAttributeCode,
      attributeType
    ) => {
      //  ;
      if (attributeType === "Attribute") {
        let attributeValues = { ...attributesData }; // for checking values
        let attributeValObj = { ...attributeValidation };

        if (
          attributeValObj[targetAttributeCode].DD !== null &&
          attributeValObj[targetAttributeCode].DD.length > 0
        ) {
          let ad = attributeValObj[targetAttributeCode].DD;

          ad.forEach(async (record) => {
            let CCode = record.CCode;
            let PValue = record.PValue;
            let Condition = record.Condition;
            let Flag = record.Flag;
            let PDT = record.PDT;
            let CDT = record.CDT;

            if (PDT === "DropdownSingle") {
              if (
                targetValue &&
                targetValue.label.toString()?.toLowerCase() ===
                  PValue?.toLowerCase()
              ) {
                //check condition
                if (Flag === "disable") {
                  attributeValObj[CCode].isDisable = true;
                  attributeValObj[CCode].ISM = "false";
                }
              } else {
                attributeValObj[CCode].isDisable = false;
                attributeValObj[CCode].ISM =
                  targetValue?.label === "Married"
                    ? "true"
                    : CCode === "MARITLSTS"
                    ? "false"
                    : targetValue?.label === "Worker"
                    ? "true"
                    : CCode === "SC"
                    ? "false"
                    : CCode === "VACXDT"
                    ? "false"
                    : CCode === "VACXDT2"
                    ? "false"
                    : CCode === "VACXDT3"
                    ? "false"
                    : "true";
              }
            } else if (PDT?.toLowerCase() === "checkbox") {
              if (
                targetValue.toString()?.toLowerCase() === PValue?.toLowerCase()
              ) {
                //check condition
                if (Flag === "disable") {
                  attributeValObj[CCode].isDisable = true;
                  attributeValObj[CCode].ISM = "false";
                }
              } else {
                attributeValObj[CCode].isDisable = false;
                attributeValObj[CCode].ISM = "true";
              }
            }

            //clear values based to CDT
            attributeValues[CCode] =
              CDT === "DropdownSingle" ||
              CDT === "DropdownMultiple" ||
              CDT === "Date" ||
              CDT === "SearchableDropdownSingle"
                ? null
                : CDT === "Text" || CDT === "Number"
                ? ""
                : CDT === "Checkbox"
                ? false
                : "";
          });

          attributeValues[targetAttributeCode] = targetValue; //imp
          
          setAttributeValidation((prev) => ({ ...prev, ...attributeValObj }));
          setAttributesData((prev) => ({
            ...prev,
            ...attributeValues,
          }));
        }
      } else {
        let attributeValues = { ...attributeSetData }; // for checking values
        let attributeValObj = { ...attributeSetValidation };

        if (
          attributeValObj[targetAttributeCode].DD !== null &&
          attributeValObj[targetAttributeCode].DD.length > 0
        ) {
          let ad = attributeValObj[targetAttributeCode].DD;

          ad.forEach(async (record) => {
            let CCode = record.CCode;
            let PValue = record.PValue;
            let Condition = record.Condition;
            let Flag = record.Flag;
            let PDT = record.PDT;
            let CDT = record.CDT;

            if (PDT === "DropdownSingle") {
              if (
                targetValue &&
                targetValue.label.toString()?.toLowerCase() ===
                  PValue?.toLowerCase()
              ) {
                //check condition
                if (Flag === "disable") {
                  attributeValObj[CCode].isDisable = true;
                  attributeValObj[CCode].ISM = "false";
                } else if (Flag === "disablefalsemandatorytrue"){
                  attributeValObj[CCode].isDisable = false;
                  attributeValObj[CCode].ISM = "true";
                }
              } else if(CCode == "OTHLEN"){
                if(attributeValObj[CCode]!=undefined){
                  attributeValObj[CCode].isDisable = true;
                  attributeValObj[CCode].ISM = "false";
                }
              } else {
                attributeValObj[CCode].isDisable = false;
                attributeValObj[CCode].ISM =
                  targetValue?.label === "Married"
                    ? "true"
                    : CCode === "MARITLSTS"
                    ? "false"
                    : targetValue?.label === "Worker"
                    ? "true"
                    : CCode === "SC"
                    ? "false"
                    : CCode === "VACXDT"
                    ? "false"
                    : CCode === "VACXDT2"
                    ? "false"
                    : CCode === "VACXDT3"
                    ? "false"
                    : "true";
              }
            } else if (PDT?.toLowerCase() === "checkbox") {
              if (
                targetValue.toString()?.toLowerCase() === PValue?.toLowerCase()
              ) {
                //check condition
                if (Flag === "disable") {
                  attributeValObj[CCode].isDisable = true;
                  attributeValObj[CCode].ISM = "false";
                }
              } else {
                attributeValObj[CCode].isDisable = false;
                attributeValObj[CCode].ISM = "true";
              }
            }

            //clear values based to CDT
            attributeValues[CCode] =
              CDT === "DropdownSingle" ||
              CDT === "DropdownMultiple" ||
              CDT === "Date" ||
              CDT === "SearchableDropdownSingle"
                ? null
                : CDT === "Text" || CDT === "Number"
                ? ""
                : CDT === "Checkbox"
                ? false
                : "";
          });

          attributeValues[targetAttributeCode] = targetValue; //imp
          
          setAttributeSetValidation((prev) => ({ ...prev, ...attributeValObj }));
          setAttributeSetData((prev) => ({
            ...prev,
            ...attributeValues,
          }));
        }
      }
    };

    useEffect(() => {
      //  ;
      if (
        fillDDValuesForGrid.length > 0 &&
        fillDDValuesForGrid.length === gridData.length
      ) {
        fillDDValuesForGrid.forEach((ddValues) => {
          setGridData((current) =>
            current.map((obj) => {
              if (obj.Id == ddValues.gridId) {
                return { ...obj, [ddValues.AC]: ddValues.DDResponse.label };
              }

              return obj;
            })
          );

          setDisplayGrid((current) =>
            current.map((obj) => {
              if (obj.Id == ddValues.gridId) {
                return { ...obj, [ddValues.AC]: ddValues.DDResponse.label };
              }

              return obj;
            })
          );

          setOgFilledAttributeSet((current) =>
            current.map((obj) => {
              if (obj.Id == ddValues.gridId) {
                return {
                  ...obj,
                  [ddValues.AC]: {
                    label: ddValues.DDResponse.label,
                    value: ddValues.DDResponse.value,
                  },
                };
              }

              return obj;
            })
          );
        });
        setFillDDValuesForGrid([]);
      }
      if (gridData.length > 0) {
        let isbank = false;
        let amount = 0;
        gridData.map((gd) => {
          if (gd.hasOwnProperty("PCE") && gd.IsActive != false) {
            isbank = true;
            if (gd.PCE === "Fixed Pay" || gd.PCE === "Variable Pay") {
              if (gd.AMT) {
                amount += parseInt(gd.AMT);
              }
            } else if (gd.PCE === "Stipend") {
              amount = gd.AMT;
            }
          } else {
            isbank = false;
          }
          if (gd.hasOwnProperty("PVSEMP") && gd.IsActive != false) {
            let filOpt = optionsObj["PVSEMP"]?.filter((op) => {
              return op.label === gd.PVSEMP;
            });
            setOgAttributeSet((prev) => ({
              ...prev,
              ["PVSEMP"]: filOpt[0],
            }));
            setAttributeSetData((prev) => ({
              ...prev,
              PVSEMP: filOpt[0],
            }));
          }
        });
        if (isbank) {
          setAttributesData((prev) => ({
            ...prev,
            ASA: amount.toString(),
          }));
        }
      }
    }, [fillDDValuesForGrid, gridData]);
//Del
//Del

    const GetNPEndDate = (NPSD, NPIM) => {
      if (NPSD != undefined && NPSD != null) {
        if (NPIM != undefined && NPIM != null) {
          const startDateNP = moment(NPSD);
          let NPDays = Number(NPIM) * 30.44;
          let EndDateNP = startDateNP.add(NPDays, "d");
          return EndDateNP;
        } else {
          return "";
        }
      } else {
        return "";
      }
    };

    const GetShortDays = (NPEDE, LWD) => {
      if (
        LWD != undefined &&
        LWD != null &&
        NPEDE != undefined &&
        NPEDE != null
      ) {
        let daysDiff;
        if (moment(LWD) < moment(NPEDE)) {
          daysDiff = moment(NPEDE).diff(moment(LWD), "days");
        } else {
          daysDiff = 0;
        }
        return daysDiff;
      } else {
        return "";
      }
    };

    const dateyearmonthdiff = (date) => {
      if (date != undefined && date != null) {
        const endDate = moment();
        const startDate = moment(date);
        const yearsDiff = endDate.diff(startDate, "years");
        startDate.add(yearsDiff, "years"); // Adjust the start date by the years difference
        const monthsDiff = endDate.diff(startDate, "months");
        startDate.add(monthsDiff, "months"); // Adjust the start date by the months difference
        const daysDiff = endDate.diff(startDate, "days");
        return `${yearsDiff} years, ${monthsDiff} months, ${daysDiff} days`;
      } else {
        return "";
      }
    };
    return (
      <>
        {section?.Attribute?.length > 0 ? (
          <Accordion
            elevation={0}
            className="mb-3"
            expanded={accordion}
            onChange={() => setAccordion(()=>true)}
          >
            <AccordionSummary
              id="profile-accordion"
              style={{ background: "#3C5464" }}
              className="text-white acc_close"
              expandIcon={<ExpandMore />}
            >
              <div className="row d-flex align-items-center acc-heading">
                <div className="col-lg-10 col-md-10 col-sm-12">
                  <p style={{ fontSize: "16px" }}>{section.SDN}</p>
                </div>
                <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
              </div>
            </AccordionSummary>

            <AccordionDetails className="">
              <div className="row">
                {section?.Attribute?.length > 0 &&
                  section?.Attribute?.map((eachAttribute, index) => (
                    <React.Fragment key={`${index}-${eachAttribute.AC}`}>
                      {eachAttribute.AT === "Attribute" ? (
                        <>
                          {eachAttribute.DT === "DropdownSingle" ? (
                            <>
                              <div
                                className={
                                  JSON.parse(
                                    eachAttribute.AD
                                  ).IFR?.toLowerCase() === "true"
                                    ? "col-lg-12 col-sm-12 col-xs-12 "
                                    : "col-lg-3 col-sm-3 col-xs-4"
                                }
                                hidden={
                                  payrolldd != null &&
                                  payrolldd.code == "OHDC" &&
                                  eachAttribute.AC == "JT_LR"
                                    ? true
                                    : false
                                }
                              >
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    {eachAttribute.AN}
                                    {attributeValidation[
                                      eachAttribute.AC
                                    ]?.ISM?.toLowerCase() === "true" && (
                                      <sup>*</sup>
                                    )}
                                  </label>

                                  <SelectForm
                                    isClearable
                                    isSearchable
                                    options={
                                      optionsObj[eachAttribute.AC]
                                        ? optionsObj[eachAttribute.AC]
                                        : []
                                    }
                                    placeholder={eachAttribute.AN}
                                    isDisabled={ true}
                                    value={
                                      attributesData[eachAttribute.AC]
                                        ? attributesData[eachAttribute.AC]
                                        : null
                                    }
                                    onChange={() => {
                                    }}
                                    isMulti={false}
                                    noIndicator={false}
                                    noSeparator={false}
                                  />
                                 
                                </div>
                              </div>
                            </>
                          ) : eachAttribute.DT === "DropdownMultiple" ? (
                            <>
                              <div
                                className={
                                  JSON.parse(
                                    eachAttribute.AD
                                  ).IFR.toLowerCase() === "true"
                                    ? "col-lg-12 col-sm-12 col-xs-12 "
                                    : "col-lg-3 col-sm-3 col-xs-4"
                                }
                                hidden={
                                  payrolldd != null &&
                                  payrolldd.code == "OHDC" &&
                                  eachAttribute.AC == "JT_LR"
                                    ? true
                                    : false
                                }
                              >
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    {eachAttribute.AN}
                                    {JSON.parse(
                                      eachAttribute.V
                                    ).ISM.toLowerCase() === "true" && (
                                      <sup>*</sup>
                                    )}
                                  </label>

                                  <SelectForm
                                    isClearable
                                    isSearchable
                                    options={
                                      optionsObj[eachAttribute.AC]
                                        ? optionsObj[eachAttribute.AC]
                                        : []
                                    }
                                    isMulti={multiDropdown}
                                    placeholder={eachAttribute.AN}
                                    isDisabled={
                                      true
                                    }
                                    value={
                                      attributesData[eachAttribute.AC]
                                        ? attributesData[eachAttribute.AC]
                                        : null
                                    }
                                    onChange={() => {

                                    }}
                                    noIndicator={false}
                                    noSeparator={false}
                                  />
                                  
                                </div>
                              </div>
                            </>
                          ) : eachAttribute.DT === "Text" ||
                            eachAttribute.DT === "Number" ? (
                            <>
                              <div
                                className={
                                  JSON.parse(
                                    eachAttribute.AD
                                  ).IFR?.toLowerCase() === "true"
                                    ? "col-lg-12 col-sm-12 col-xs-12 "
                                    : "col-lg-3 col-sm-3 col-xs-4"
                                }
                              >
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    {eachAttribute.AN}
                                    {attributeValidation[
                                      eachAttribute.AC
                                    ]?.ISM?.toLowerCase() === "true" && (
                                      <sup>*</sup>
                                    )}
                                  </label>

                                  <InputForm
                                    className="form-control"
                                    placeholder={eachAttribute.AN}
                                    isDisabled={
                                      true
                                    }
                                    textArea={false}
                                    value={
                                      eachAttribute.AC === "TIPOS"
                                        ? dateyearmonthdiff(
                                            attributesData["POSED"]
                                          )
                                        : eachAttribute.AC === "NPD" &&
                                          (attributesData["NPD"] === null ||
                                            attributesData["NPD"] === undefined)
                                        ? "0"
                                        : attributesData[eachAttribute.AC]
                                        ? attributesData[eachAttribute.AC]
                                        : ""
                                    }
                                    onChange={(e) => {}}
                                    maxLength="255"
                                  />
                                  
                                </div>
                              </div>
                            </>
                          ) : eachAttribute.DT === "Date" ? (
                            <>
                              <div
                                className={
                                  JSON.parse(
                                    eachAttribute.AD
                                  ).IFR?.toLowerCase() === "true"
                                    ? "col-lg-12 col-sm-12 col-xs-12 "
                                    : "col-lg-3 col-sm-3 col-xs-4"
                                }
                              >
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    {eachAttribute.AN}
                                    {attributeValidation[
                                      eachAttribute.AC
                                    ]?.ISM?.toLowerCase() === "true" && (
                                      <sup>*</sup>
                                    )}
                                  </label>

                                  <DateForm
                                    isDisabled={
                                      true
                                    }
                                    value={
                                      attributesData[eachAttribute.AC] === "NPEDE"
                                        ? GetNPEndDate(
                                            attributesData["NPSD"],
                                            attributesData["NPIM"]
                                          )
                                        : attributesData[eachAttribute.AC] ===
                                          "NPSDE"
                                        ? GetShortDays(
                                            attributesData["NPEDE"],
                                            attributesData["LWD"]
                                          )
                                        : attributesData[eachAttribute.AC]
                                        ? attributesData[eachAttribute.AC]
                                        : null
                                    }
                                    onChange={(date) => {}}
                                  />
                                 
                                </div>
                              </div>
                            </>
                          ) : eachAttribute.DT === "Checkbox" ? (
                            <>
                              <div
                                className={
                                  JSON.parse(
                                    eachAttribute.AD
                                  ).IFR?.toLowerCase() === "true"
                                    ? "col-lg-12 col-sm-12 col-xs-12 "
                                    : "col-lg-3 col-sm-3 col-xs-4"
                                }
                              >
                                <div className="mb-1">
                                  <label
                                    htmlFor={eachAttribute.AC}
                                    className="col-form-label"
                                  >
                                    {eachAttribute.AN}
                                    {attributeValidation[
                                      eachAttribute.AC
                                    ]?.ISM?.toLowerCase() === "true" && (
                                      <sup>*</sup>
                                    )}
                                  </label>

                                  <div>
                                    <input
                                      disabled={
                                      true
                                      }
                                      type="checkbox"
                                      onChange={(e) => {}}
                                      id={eachAttribute.AC}
                                      checked={
                                        attributesData[eachAttribute.AC]
                                          ? attributesData[eachAttribute.AC]
                                          : false
                                      }
                                    />
                                  </div>
                                 
                                </div>
                              </div>
                            </>
                          ) : eachAttribute.DT === "Textarea" ? (
                            <>
                              <div
                                className={
                                  JSON.parse(
                                    eachAttribute.AD
                                  ).IFR?.toLowerCase() === "true"
                                    ? "col-lg-12 col-sm-12 col-xs-12 "
                                    : "col-lg-3 col-sm-3 col-xs-4"
                                }
                              >
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    {eachAttribute.AN}
                                    {attributeValidation[
                                      eachAttribute.AC
                                    ]?.ISM?.toLowerCase() === "true" && (
                                      <sup>*</sup>
                                    )}
                                  </label>

                                  <InputForm
                                    className="form-control"
                                    placeholder={eachAttribute.AN}
                                    isDisabled={
                                      true
                                    }
                                    textArea={true}
                                    value={
                                      attributesData[eachAttribute.AC]
                                        ? attributesData[eachAttribute.AC]
                                        : ""
                                    }
                                    onChange={(e) => {}}
                                    maxLength="255"
                                  />
                                
                                </div>
                              </div>
                            </>
                          ) : eachAttribute.DT === "Document" ? (
                            <>
                              <div
                                className={
                                  JSON.parse(
                                    eachAttribute.AD
                                  ).IFR?.toLowerCase() === "true"
                                    ? "col-lg-12 col-sm-12 col-xs-12 "
                                    : "col-lg-3 col-sm-3 col-xs-4"
                                }
                              >
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    {eachAttribute.AN}
                                    {attributeValidation[
                                      eachAttribute.AC
                                    ]?.ISM?.toLowerCase() === "true" && (
                                      <sup>*</sup>
                                    )}
                                  </label>
                                 
                                  <div className="file-added-list">
                                    <ul className="list-unstyle">
                                      {documents &&
                                        documents.length > 0 &&
                                        documents.map(
                                          (fileAttribute, attributeIndex) => (
                                            <React.Fragment key={attributeIndex}>
                                              {fileAttribute.AC ===
                                                eachAttribute.AC &&
                                                fileAttribute.IsActive && (
                                                  <li
                                                    className="list mt-1"
                                                    key={attributeIndex}
                                                  >
                                                    <div className="media">
                                                      <div className="media-body text-truncate">
                                                        <span className="view-more">
                                                          {fileAttribute.Name}
                                                        </span>
                                                      </div>
                                                      {/*Here code for view same div of trash should be used. ISDOCVIEW/ISDOCDOWN*/}
                                                      {/* {
                                                        // section?.ISDOCVIEW &&
                                                        fileAttribute.DocId !==
                                                          0 && (
                                                          <div className="media-right ml-2">
                                                            <i
                                                              className="fa fa-eye"
                                                              aria-hidden="true"
                                                              onClick={() =>
                                                                handleViewForFlat(
                                                                  fileAttribute,
                                                                  attributeIndex
                                                                )
                                                              }></i>
                                                          </div>
                                                        )
                                                      } */}

                                                      {/* {
                                                        //  section?.ISDOCDOWN &&
                                                        <div className="media-right ml-2">
                                                          <i
                                                            className="fa-solid fa-download"
                                                            aria-hidden="true"
                                                            onClick={() =>
                                                              handleDownloadFileFlat(
                                                                fileAttribute,
                                                                attributeIndex
                                                              )
                                                            }
                                                          ></i>
                                                        </div>
                                                      } */}

                                                       {/* {
                                                        //section?.ISEDIT ||
                                                        fileAttribute.DocId !==
                                                          0 && ( */}
                                                          {/* <div className="media-right ml-2">
                                                            <i
                                                              className="fa fa-trash"
                                                              aria-hidden="true"
                                                              onClick={() =>
                                                                handleDeleteFileForFlat(
                                                                  fileAttribute,
                                                                  attributeIndex
                                                                )
                                                              }
                                                            ></i>
                                                          </div> */}
                                                        {/* )
                                                      } */}
                                                    </div>
                                                  </li>
                                                )}
                                            </React.Fragment>
                                          )
                                        )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : eachAttribute.DT === "SearchableDropdownSingle" ? (
                            <>
                              <div
                                className={
                                  JSON.parse(
                                    eachAttribute.AD
                                  )?.IFR?.toLowerCase() === "true"
                                    ? "col-lg-12 col-sm-12 col-xs-12 "
                                    : "col-lg-3 col-sm-3 col-xs-4"
                                }
                              >
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    {eachAttribute.AN}
                                    {attributeValidation[
                                      eachAttribute.AC
                                    ]?.ISM?.toLowerCase() === "true" && (
                                      <sup>*</sup>
                                    )}
                                  </label>

                                  <SelectForm
                                    isClearable
                                    isSearchable
                                    async
                                    options={() =>{}}
                                    placeholder={eachAttribute.AN}
                                    isDisabled={
                                    true
                                    }
                                    value={
                                      attributesData[eachAttribute.AC]
                                        ? attributesData[eachAttribute.AC]
                                        : null
                                    }
                                    onChange={async (event) =>{}}
                                    isMulti={false}
                                    noIndicator={false}
                                    noSeparator={false}
                                  />
                                  
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : eachAttribute.AT === "AttributeSet" &&
                        eachAttribute.DT === "Custom" ? (
                        <div className="col-lg-12">
                          {ShowAttrSet && (
                            <div className="row">
                              {eachAttribute.Attribute.length > 0 &&
                                eachAttribute.Attribute.map(
                                  (subAttribute, index) => (
                                    <React.Fragment
                                      key={`${index}-${subAttribute.AC}`}
                                    >
                                      {subAttribute.DT === "DropdownSingle" ? (
                                        <>
                                          <div
                                            className={
                                              JSON.parse(
                                                subAttribute.AD
                                              ).IFR?.toLowerCase() === "true"
                                                ? "col-lg-12 col-sm-12 col-xs-12 "
                                                : "col-lg-3 col-sm-3 col-xs-4"
                                            }
                                          >
                                            <div className="mb-1">
                                              <label className="col-form-label">
                                                {subAttribute.AN}
                                                {attributeSetValidation[
                                                  subAttribute.AC
                                                ]?.ISM?.toLowerCase() ===
                                                  "true" && <sup>*</sup>}
                                              </label>

                                              <SelectForm
                                                isClearable
                                                isSearchable
                                                options={
                                                  optionsObj[subAttribute.AC]
                                                    ? optionsObj[subAttribute.AC]
                                                    : []
                                                }
                                                {...(subAttribute.AC ===
                                                  "STSE" && {
                                                  filterOption: (option) =>
                                                    currentGridId === null &&
                                                    subAttribute.AC === "STSE"
                                                      ? option.label !== "Demise"
                                                      : option,
                                                })}
                                                placeholder={subAttribute.AN}
                                                isDisabled={
                                                  true
                                                }
                                                value={
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ]
                                                    ? attributeSetData[
                                                        subAttribute.AC
                                                      ]
                                                    : null
                                                }
                                                onChange={(event) => {}}
                                                isMulti={false}
                                                noIndicator={false}
                                                noSeparator={false}
                                              />
                                             
                                            </div>
                                          </div>
                                        </>
                                      ) : subAttribute.DT ===
                                        "DropdownMultiple" ? (
                                        <>
                                          <div
                                            className={
                                              JSON.parse(
                                                subAttribute.AD
                                              ).IFR.toLowerCase() === "true"
                                                ? "col-lg-12 col-sm-12 col-xs-12 "
                                                : "col-lg-3 col-sm-3 col-xs-4"
                                            }
                                          >
                                            <div className="mb-1">
                                              <label className="col-form-label">
                                                {subAttribute.AN}
                                                {attributeSetValidation[
                                                  subAttribute.AC
                                                ]?.ISM?.toLowerCase() ===
                                                  "true" && <sup>*</sup>}
                                              </label>

                                              <SelectForm
                                                isClearable
                                                isSearchable
                                                options={
                                                  optionsObj[subAttribute.AC]
                                                    ? optionsObj[subAttribute.AC]
                                                    : []
                                                }
                                                isMulti={multiDropdown}
                                                placeholder={subAttribute.AN}
                                                isDisabled={
                                                 true
                                                }
                                                value={
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ]
                                                    ? attributeSetData[
                                                        subAttribute.AC
                                                      ]
                                                    : null
                                                }
                                                onChange={(event) => {}}
                                                noIndicator={false}
                                                noSeparator={false}
                                              />
                                             
                                            </div>
                                          </div>
                                        </>
                                      ) : subAttribute.DT === "Text" ||
                                        subAttribute.DT === "Number" ? (
                                        <>
                                        {
                                          attributeSetValidation[subAttribute.AC
                                            ]?.IsHiddenForEP
                                          && attributeSetValidation[subAttribute.AC
                                            ]?.IsHiddenForEP.toLowerCase() === "true"
                                          ? <></>
                                          :
                                          <div
                                            className={
                                              JSON.parse(
                                                subAttribute.AD
                                              ).IFR?.toLowerCase() === "true"
                                                ? "col-lg-12 col-sm-12 col-xs-12 "
                                                : "col-lg-3 col-sm-3 col-xs-4"
                                            }
                                          >
                                            <div className="mb-1">
                                              <label className="col-form-label">
                                                {subAttribute.AN}
                                                {attributeSetValidation[
                                                  subAttribute.AC
                                                ]?.ISM?.toLowerCase() ===
                                                  "true" && <sup>*</sup>}
                                              </label>

                                              <InputForm
                                                className="form-control"
                                                placeholder={subAttribute.AN}
                                                isDisabled={
                                                 true
                                                }
                                                textArea={false}
                                                value={
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ]
                                                    ? attributeSetData[
                                                        subAttribute.AC
                                                      ]
                                                    : ""
                                                }
                                                onChange={(e) => {}}
                                                maxLength="255"
                                              />
                                              
                                            </div>
                                          </div>}
                                        </>
                                      ) : subAttribute.DT === "MonthYear" ? (
                                        <>
                                          <div
                                            className={
                                              JSON.parse(
                                                subAttribute.AD
                                              ).IFR.toLowerCase() === "true"
                                                ? "col-lg-12 col-sm-12 col-xs-12 "
                                                : "col-lg-3 col-sm-3 col-xs-4"
                                            }
                                          >
                                            <div className="mb-1">
                                              <label className="col-form-label">
                                                {subAttribute.AN}
                                                {attributeSetValidation[
                                                  subAttribute.AC
                                                ]?.ISM.toLowerCase() ===
                                                  "true" && <sup>*</sup>}
                                              </label>

                                              <DateForm
                                                isDisabled={
                                                  true
                                                }
                                                views={["month", "year"]}
                                                format="MMM/yyyy"
                                                value={
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ]
                                                    ? attributeSetData[
                                                        subAttribute.AC
                                                      ]
                                                    : null
                                                }
                                                onChange={(date) => {}}
                                              />
                                              
                                            </div>
                                          </div>
                                        </>
                                      ) : subAttribute.DT === "Date" ? (
                                        <>
                                          <div
                                            className={
                                              JSON.parse(
                                                subAttribute.AD
                                              ).IFR?.toLowerCase() === "true"
                                                ? "col-lg-12 col-sm-12 col-xs-12 "
                                                : "col-lg-3 col-sm-3 col-xs-4"
                                            }
                                          >
                                            <div className="mb-1">
                                              <label className="col-form-label">
                                                {subAttribute.AN}
                                                {/* {JSON.parse(
                                                subAttribute.V
                                              ).ISM?.toLowerCase() === "true" && (
                                                <sup>*</sup>
                                              )} */}
                                                {attributeSetValidation[
                                                  subAttribute.AC
                                                ]?.ISM?.toLowerCase() ===
                                                  "true" && <sup>*</sup>}
                                              </label>

                                              <DateForm
                                                isDisabled={
                                                  true
                                                }
                                                value={
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ]
                                                    ? attributeSetData[
                                                        subAttribute.AC
                                                      ]
                                                    : null
                                                }
                                                onChange={(date) => {}}
                                              />
                                             
                                            </div>
                                          </div>
                                        </>
                                      ) : subAttribute.DT === "Checkbox" ? (
                                        <>
                                        </>
                                      ) : subAttribute.DT ===
                                        "SearchableDropdownSingle" ? (
                                        <>
                                          <div
                                            className={
                                              JSON.parse(
                                                subAttribute.AD
                                              ).IFR?.toLowerCase() === "true"
                                                ? "col-lg-12 col-sm-12 col-xs-12 "
                                                : "col-lg-3 col-sm-3 col-xs-4"
                                            }
                                          >
                                            <div className="mb-1">
                                              <label className="col-form-label">
                                                {subAttribute.AN}
                                                {attributeSetValidation[
                                                  subAttribute.AC
                                                ]?.ISM?.toLowerCase() ===
                                                  "true" && <sup>*</sup>}
                                              </label>

                                              <SelectForm
                                                isClearable
                                                isSearchable
                                                async
                                                options={() => {}  }
                                                placeholder={subAttribute.AN}
                                                isDisabled={
                                                  //!section.ISEDIT ||
                                                 true
                                                }
                                                value={
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ]
                                                    ? attributeSetData[
                                                        subAttribute.AC
                                                      ]
                                                    : null
                                                }
                                                onChange={(event) => {}}
                                                isMulti={false}
                                                noIndicator={false}
                                                noSeparator={false}
                                              />
                                             
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </React.Fragment>
                                  )
                                )}
                            </div>
                          )}

                          <div className="row">
                            {ShowAttrSet && (
                              <div className="col-lg-12 mt-2 mb-3">
                                {
                                }
                              </div>
                            )}
                            {
                              <div className="col-lg-12 mt-2 mb-3 d-flex justify-content-end">
                              </div>
                            }
                            <div className="col-lg-12 p-0 mb-3 customized-dynamic-grid">
                              <DynamicGrid
                                options={gridOptions}
                                data={displayGrid}
                                columns={gridColumns}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </React.Fragment>
                  ))}
              </div>
            </AccordionDetails>
          </Accordion>
        ) : null}
      </>
    );
  };
  export default SectionComponentPrintAndPreview;
