import React, { useState, useEffect, useContext } from "react";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { useNavigate } from "react-router-dom";
import BaseModal from "../../Components/BaseModel/BaseModel";
// import FormStructure from "../FromStructure/FormStructure";
import SelectForm from "../../Components/SelectForm/SelectForm";
import "./MrfDashboard.css";
import Collapse from "react-bootstrap/Collapse";
import { APICall } from "../../Helpers/APICalls";
import {
  getMrfDashboardData,
  getDropdowns,
  GetPerimissionRecordForMRFDashboard,
  DeleteMRFById,
  GetRolesURL,
} from "../../Helpers/APIEndPoints/EndPoints";
import InputForm from "../../Components/InputForm/InputForm";
import { Tooltip } from "@mui/material";
import { LoaderContext } from "../../Helpers/Context/Context";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import moment from "moment";
import { Modal } from "react-bootstrap";
import notify from "../../Helpers/ToastNotification";

const MrfDashboard = () => {
  const [sectionData, setSectionData] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [gridData, setGridData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [PageSize, setPageSize] = useState(10);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();

  let { userDetails, currentRoleId }: any = useUserContext();

  const [legalEntity, setLegalEntity] = useState<any>([]);
  const [mrfLocation, setMrfLocation] = useState<any>([]);
  const [commonDdValue, setCommonDdValue] = useState<any>({});
  const [ddValue, setDdValue] = useState({});
  const [reset, setReset] = useState(false);
  const [roles, setRoles] = useState([]);
  const [buhrRoleId, setBUHRRoleId] = useState();
  const [tempDeleteMRFId, setTempDeleteMRFId] = useState<number>(0);
  const [modalOpen, setModelOpen] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [hasDeletePermission, setHasDeletePermission] = useState(false);
  const [tempId, setTempId] = useState<number>(0);
  const [remarks, setRemarks] = useState<string>("");
  const [modalErrors, setModalErrors] = useState<any>({});
  const [reload, setReload] = useState(false);
  const [showRemarks, setShowRemarks] = useState(false);

  const [deleteColumnsModal, setDeleteColumnsModal] = useState(false);
  const [deleteModalCount, setDeleteModalCount] = useState(0);
  const [deletePage, setDeletePage] = useState(0);
  const [deleteData, setDeleteData] = useState([]);

  // useEffect(() => {
  //   const Roles = userDetails?.Roles || [];
  //   const buhrId =
  //     Roles.find((i) => i?.name?.toLocaleLowerCase()?.trim() == "buhr")?.id ||
  //     0;
  //   setBUHRRoleId(buhrId);
  //   setRoles(Roles);
  // }, []);

  useEffect(() => {
    const helperFunction = async () => {
      await GetRolesAsync();
    };
    helperFunction();
    return () => {};
  }, []);

  useEffect(() => {
    const helper = async () => {
      await GetMRFDeletePermission();
    };

    helper();

    return () => {};
  }, []);

  const getDropdownsData = async (AC, cb) => {
    showLoader();

    const dropdownData = await APICall(getDropdowns, "POST", {
      AC,
      PID: null,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });
    if (
      dropdownData.data !== null &&
      dropdownData.data !== undefined &&
      dropdownData.data.length > 0
    ) {
      setDdValue((prev) => ({ ...prev, [AC]: dropdownData.data }));
    } else {
      setDdValue((prev) => ({ ...prev, [AC]: [] }));
    }
    //setCommonDdValue((prev)=>({...prev, [AC]:[]}));
    await hideLoader();
  };

  useEffect(() => {
    const helper = async () => {
      await getInterfaceDetails();
    };
    helper();
  }, [showRemarks]);

  const getInterfaceDetails = async () => {
    showLoader();
    // ;
    const gridDataResponse = await APICall(
      "/api/Interface/GetInterfaceDetailsForGrid",
      "POST",
      {
        MN: "ManpowerRequisition",
        IN: "ManpowerRequisition_Dashboard",
        TN: "ManpowerRequisition_Dashboard",
      }
    );
    if (gridDataResponse?.data?.d?.fa !== null) {
      for await (const cFilter of gridDataResponse?.data?.d?.fa) {
        if (cFilter.filt === "multidropdown") {
          await getDropdownsData(cFilter.filAN, null);
        }
      }
    }
    if (gridDataResponse?.data?.d?.afa !== null) {
      for await (const cFilter of gridDataResponse?.data?.d?.afa) {
        if (cFilter.filt === "multidropdown") {
          await getDropdownsData(cFilter.filAN, null);
        }
      }
    }
    setGridData(gridDataResponse);
    await getGridStructureTwo();
    hideLoader();
  };

  useEffect(() => {
    if (gridData?.data?.d?.s[0]?.columnData.length > 0) {
      const helper = async () => {
        await getGridStructureTwo();
      };
      helper();
    }
  }, [page, searchText, reset, legalEntity, mrfLocation, reload]);

  const getGridStructureTwo = async () => {
    showLoader();

    const GetDashboardData = await APICall(getMrfDashboardData, "POST", {
      DashboardName: "ManpowerRequisition",
      PageNumber: page,
      PageSize: PageSize,
      SearchText: searchText,
      SortColumn: "",
      SortOrder: "",
      UserID: userDetails.Id,
      RoleId: currentRoleId?.value,
      MRORLEGENTY: legalEntity.map((s) => s.value).join(","),
      MRORLOC: mrfLocation.map((s) => s.value).join(","),
      UserId: userDetails?.Id,
    });
    //;
    if (GetDashboardData.data !== null && GetDashboardData.data.length > 0) {
      // Get the local timezone offset in minutes
      const localOffsetMinutes = moment().utcOffset();

      setCount(GetDashboardData.data[0].count);

      const mrfData = GetDashboardData.data;

      // mrfData.forEach((mrf) => {
      //   mrf.openTime = mrf.openTime
      //     ? moment
      //         .utc(mrf.openTime)
      //         .utcOffset(localOffsetMinutes)
      //         .format("DD-MMM-YYYY HH:mm:ss")
      //     : "";
      // });

      mrfData.forEach((mrf) => {
        mrf.openTime = mrf.openTime
          ? mrf.openTime < 2
            ? `${mrf.openTime} day`
            : `${mrf.openTime} days`
          : `0 day`;
      });
      setRowData(mrfData);
    } else {
      setRowData([]);
      setCount(0);
    }
    hideLoader();
  };

  const GetRolesAsync = async () => {
    try {
      showLoader();
      let arr = [];
      const response = await APICall(GetRolesURL, "POST", {});

      if (response?.status == 0 && response?.data?.length > 0) {
        const data = response?.data;
        const role =
          data.find((i) => i?.name?.toLocaleLowerCase()?.trim() == "buhr")
            ?.id || 0;
        setBUHRRoleId(role);

        const systemAdmin =
          data?.find(
            (i) => i?.name?.toLocaleLowerCase()?.trim() == "systemadmin"
          )?.id || 0;

        setShowRemarks(currentRoleId?.value == systemAdmin);
      } else {
        setRoles([]);
      }

      return arr;
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  // useEffect(() => {
  //   (async () => {
  //     const tabs = await APICall("/api/Tabs/getTabs", "POST", {
  //       MN: "EmployeeCentral",
  //       IN: "Form",
  //     });

  //     if (tabs.data !== null && tabs.data.length > 0) {
  //       setTabs(tabs.data);
  //       setActivetab(tabs.data[0].TN);
  //     } else {
  //       setTabs([]);
  //     }
  //   })();
  // }, []);

  const view = (value, tableMeta) => {
    const url = value;
    // window.location.href = url;
    window.open(url);
  };

  const edit = (value, tableMeta, url) => {
    const TId = tableMeta.rowData[0];
    const status = tableMeta.rowData[2];

    const isdeleted = String(status).toLocaleLowerCase().trim() == "deleted";

    navigate("/mrf", { state: { TId, isdeleted: isdeleted } });
  };

  const copy = (value, tableMeta) => {
    alert("copy");
  };

  const modal = (value, tableMeta) => {
    alert("modal");
  };

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: PageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",
    onChangePage: async (page) => {
      setPage(page * PageSize);
    },
  };

  const deleteOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: deleteModalCount,
    rowsPerPage: PageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",
    onChangePage: async (page) => {
      setDeletePage(page * PageSize);
    },
  };

  //option which is from backend if needed we use it
  // const getOption = (attribute) => {
  //   const faData = data?.fa;
  //   if (faData && attribute.filn === "city") {
  //     return cityData.map((city) => ({ value: city.label, label: city.label }));
  //   } else {
  //     return [];
  //   }
  // };

  const columnsWithCustomRender = gridData?.data?.d?.s[0]?.columnData.map(
    (column) => {
      if (column.name === "action") {
        return {
          ...column,
          options: {
            ...column.options,
            customBodyRender: (value, tableMeta, updateValue) => {
              const TId = tableMeta.rowData[0];
              const status = tableMeta.rowData[2];
              const remarks = tableMeta.rowData[17];
              const deletedByUserdId = tableMeta.rowData[18];
              let deletedDateUTC = tableMeta.rowData[19];
             

              if (deletedDateUTC && moment.utc(deletedDateUTC).isValid()) {
                const localTime = moment.utc(deletedDateUTC).local();

                deletedDateUTC = localTime.format("DD-MM-YYYY hh:mm:ss A");
              }

              //

              return (
                <>
                  <div className="centerStyles">
                    <div className="mr-2">
                      <Tooltip title="Edit">
                        <i
                          style={{ marginRight: "0px", cursor: "pointer" }}
                          onClick={() => {
                            // navigate("/gridEdit")
                            edit(value, tableMeta, column.url);
                          }}
                          className="fas fa-edit"
                        ></i>
                      </Tooltip>
                    </div>
                    {hasDeletePermission &&
                      String(status).toLocaleLowerCase().trim() !=
                        "deleted" && (
                        <Tooltip title="Delete">
                          <i
                            style={{ marginRight: "0px", cursor: "pointer" }}
                            onClick={() => {
                              setTempId(TId);
                              setDeleteModal(true);
                            }}
                            className="fas fa-trash"
                          ></i>
                        </Tooltip>
                      )}

                    {hasDeletePermission &&
                      String(status).toLocaleLowerCase().trim() ==
                        "deleted" && (
                        <Tooltip title="Remarks">
                          <i
                            style={{ marginRight: "0px", cursor: "pointer" }}
                            onClick={() => {
                              setTempId(TId);
                              setDeleteColumnsModal(true);
                              setDeleteData([
                                { remarks, deletedByUserdId, deletedDateUTC },
                              ]);
                            }}
                            className="fas fa-regular fa-message"
                          ></i>
                        </Tooltip>
                      )}
                  </div>
                </>
              );
            },
          },
        };
      }
      // if (column?.name == "remarks") {
      //   return {
      //     ...column,
      //     options: {
      //       ...column.options,
      //       display: showRemarks,
      //       setCellProps: () => ({
      //         style: { minWidth: "250px", maxWidth: "300px" },
      //       }),
      //     },
      //   };
      // }
      let options = { ...column };
      options.options = {
        ...column.options,
        setCellProps: () => ({
          style: { minWidth: "150px", maxWidth: "300px" },
        }),
      };
      return options;
    }
  );

  const onFilterChange = (event, type) => {
    //;
    if (type === "MRORLEGENTY") {
      setLegalEntity(event);
    }

    if (type === "MRORLOC") {
      setMrfLocation(event);
    }
  };

  const onSearchFilter = (event, type) => {
    if (type === "Search MRF") {
      setSearchText(event.target.value);
    }
  };
  const onSubmitFilter = () => {
    if (gridData?.data?.d?.s[0]?.columnData.length > 0) {
      getGridStructureTwo();
    }
  };

  const onClickReset = () => {
    setLegalEntity([]);
    setMrfLocation([]);
    setSearchText("");
    setReset(!reset);
    setModelOpen(false);
  };

  async function GetMRFDeletePermission() {
    try {
      showLoader();
      const response = await APICall(
        GetPerimissionRecordForMRFDashboard,
        "POST",
        {
          UserId: userDetails?.Id,
          RoleId: currentRoleId?.value,
        }
      );

      if (response?.status == 0) {
        const responseData = response?.data;

        if (response?.data?.length > 0) {
          const permission = responseData?.map((i) =>
            String(i?.name).toLowerCase().trim()
          );
          const isDeletePermission = permission?.some((i) => i == "delete");
          setHasDeletePermission(isDeletePermission == true);
        }
      } else {
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  async function DeleteMRF() {
    try {
      if (tempId === 0) return;

      if (String(remarks).trim().length == 0) {
        setModalErrors({ remarks: "Remarks Required." });
        return;
      }
      setModalErrors({});
      showLoader();
      const response = await APICall(DeleteMRFById, "POST", {
        Id: tempId,
        Remarks: remarks,
        UserId: userDetails?.Id || 0,
      });

      if (response?.status == 0) {
        setReload((prev) => !prev);
        closeModel();
        notify(0, "Record deleted successfully.");
      } else {
        notify(1, "Something went wrong.");
      }
    } catch (error) {
      console.error(error);
      notify(1, "Something went wrong.");
    } finally {
      hideLoader();
    }
  }

  function closeModel() {
    setTempId(0);
    setDeleteModal(false);
    setModalErrors({});
    setRemarks("");
  }

  function handleCloseDeleteColumnsModal() {
    setDeleteColumnsModal(false);
    setDeleteData([]);
    setRemarks("");
  }

  const historyColumns = [
    {
      name: "deletedByUserdId",
      label: "Deleted by",
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: "deletedDateUTC",
      label: "Deleted At",
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: "remarks",
      label: "Remarks",
      options: {
        filter: false,
        sort: false,
        display: showRemarks,
        setCellProps: () => ({
          style: { minWidth: "250px", maxWidth: "300px" },
        }),
      },
    },
  ];

  return (
    <div className="container-fluid">
      <div className="d-flex align-items-center justify-content-end mb-3">
        {currentRoleId?.value == buhrRoleId ? (
          <button
            onClick={() => {
              navigate("/mrf", { state: { TId: 0 } });
            }}
            className="btn btn-primary mt-3"
          >
            <i className="fa fa-plus mr-2"></i>
            Add New
          </button>
        ) : null}
      </div>

      <div className="grid-wrapper grid_mob">
        <div className="row mx-auto filtBox">
          {gridData &&
            gridData?.data?.d?.fa.map((cFilter, index) => (
              <React.Fragment key={index}>
                {cFilter.filt === "multidropdown" ? (
                  <>
                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                      <div className="form-group filtInp">
                        <span
                          className="mr-2 text-white"
                          style={{ float: "left" }}
                        >
                          <label className="col-form-label">
                            {cFilter.filn}
                          </label>
                        </span>
                        <SelectForm
                          isClearable
                          options={ddValue[cFilter.filAN]}
                          placeholder={cFilter.filn}
                          isDisabled={false}
                          onChange={(event) => {
                            setPage(0);
                            onFilterChange(event, cFilter.filAN);
                          }}
                          value={
                            cFilter.filAN === "MRORLEGENTY"
                              ? legalEntity
                              : cFilter.filAN === "MRORLOC"
                              ? mrfLocation
                              : null
                          }
                          isMulti={true}
                          noIndicator={false}
                          noSeparator={false}
                        />
                      </div>
                    </div>
                  </>
                ) : cFilter.filt === "text" ? (
                  <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <div className="form-group filtInp">
                      <InputForm
                        value={cFilter.filn === "Search MRF" ? searchText : ""}
                        placeholder={cFilter.filn}
                        isDisabled={false}
                        textArea={false}
                        onChange={(e) => {
                          setPage(0);
                          onSearchFilter(e, cFilter.filn);
                        }}
                      />
                    </div>
                  </div>
                ) : null}
              </React.Fragment>
            ))}
          <div className="col-lg-1 col-md-1 col-sm-12 filtrt">
            <ul className=" filter-icon-wel">
              <li>
                <a
                  // href=""
                  onClick={() => setOpen(!open)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                  className="filter-btn"
                >
                  <i className="fa fa-filter"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="col-lg-12 col-md-10 col-sm-12 "></div>

      <>
        <Collapse in={open}>
          <div id="example-collapse-text grid-wrapper">
            <div className="row mx-auto myInlineStyle">
              {gridData &&
                gridData?.data?.d?.afa?.map((cFilter, index) => (
                  <>
                    {cFilter.filt === "multidropdown" ? (
                      <>
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                          <div className="form-group filtInp">
                            <span
                              className="mr-2 text-white"
                              style={{ float: "left" }}
                            >
                              <label className="col-form-label">
                                {cFilter.filn}
                              </label>
                            </span>
                            <SelectForm
                              isClearable
                              options={ddValue[cFilter.filAN]}
                              placeholder={cFilter.filn}
                              isDisabled={false}
                              onChange={(event) => {
                                onFilterChange(event, cFilter.filAN);
                              }}
                              isMulti={true}
                              noIndicator={false}
                              noSeparator={false}
                              value={
                                cFilter.filAN === "MRORLEGENTY"
                                  ? legalEntity
                                  : cFilter.filAN === "MRORLOC"
                                  ? mrfLocation
                                  : null
                              }
                            />
                          </div>
                        </div>
                      </>
                    ) : cFilter.filt === "text" ? (
                      <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                        <div className="form-group filtInp">
                          <InputForm
                            value={
                              cFilter.filn === "Search MRF" ? searchText : ""
                            }
                            placeholder={cFilter.filn}
                            isDisabled={false}
                            textArea={false}
                            onChange={(e) => {
                              onSearchFilter(e, cFilter.filn);
                            }}
                          />
                        </div>
                      </div>
                    ) : null}
                  </>
                ))}
              <div className="col-lg-12 d-flex mt-2 justify-content-end">
                <button
                  className="btn btn-secondary mr-2"
                  onClick={onClickReset}
                >
                  <i className="fa fa-undo" aria-hidden="true"></i> Reset
                </button>
                <button
                  className="btn btn-filter-submit"
                  onClick={onSubmitFilter}
                >
                  <i className="fa fa-check" aria-hidden="true"></i> Submit{" "}
                </button>
              </div>
            </div>
          </div>
        </Collapse>
      </>

      <div className="mb-3 pt-3">
        <DynamicGrid
          data={rowData}
          columns={columnsWithCustomRender}
          options={options}
        />
      </div>

      <Modal
        show={deleteModal}
        onHide={closeModel}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">Confirmation!</div>
        </div>
        <Modal.Body>
          <div>
            <div className="row">
              <div className="col-lg-6">
                <h6>Are you sure you want to delete this MRF?</h6> <br />
                <label>
                  Remarks <sup>*</sup>
                </label>
                <InputForm
                  value={remarks}
                  placeholder={"Remarks"}
                  isDisabled={false}
                  textArea={true}
                  onChange={(e) => {
                    setRemarks(e?.target?.value);
                  }}
                  max={500}
                />
                <p style={{ color: "red" }}>{modalErrors["remarks"]}</p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={DeleteMRF}
          >
            Yes
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={closeModel}
          >
            No
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={deleteColumnsModal}
        onHide={handleCloseDeleteColumnsModal}
        backdrop="static"
        keyboard={false}
        size="xl"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">Deleted Information</div>
        </div>
        <Modal.Body>
          <div className="col-lg-">
            <DynamicGrid
              options={deleteOptions}
              data={deleteData}
              columns={historyColumns}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={handleCloseDeleteColumnsModal}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MrfDashboard;
