import React, { useContext, useEffect, useState } from "react";
import { APICall } from "../../Helpers/APICalls";
import {
  getAllUserMaster,
  getEditUserRoleId,
  getDropdowns,
  InsertUpdateSchemeCreationMapping,
  GetSchemeCreationDashboardData,
} from "../../Helpers/APIEndPoints/EndPoints";
import SelectForm from "../../Components/SelectForm/SelectForm";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import notify from "../../Helpers/ToastNotification";
import { LoaderContext } from "../../Helpers/Context/Context";
import { useLocation, useNavigate } from "react-router-dom";
import InputForm from "../../Components/InputForm/InputForm";
import { Collapse, Tooltip } from "@mui/material";
import { Button } from "react-bootstrap";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";

const SchemeCreationMaster = () => {
  const [SchemeName, setSchemeName] = useState("");

  const [BusinessDropdownOptions, setBusinessDropdownOptions] = useState([]);
  const [BusinessDropdownValue, setBusinessDropdownValue] = useState([]);
  const [BISError, setBISError] = useState("");
  const [LegalEntityDropdownOptions, setLegalEntityDropdownOptions] = useState(
    []
  );
  const [LegalEntityDropdownValue, setLegalEntityDropdownValue] =
    useState(null);
  const [LegalEntityError, setLegalEntityError] = useState("");

  const [SBUDropdownOptions, setSBUDropdownOptions] = useState([]);
  const [SBUDropdownValue, setSBUDropdownValue] = useState(null);
  const [SBUError, setSBUError] = useState("");

  const [PayLocDropdownOptions, setPayLocDropdownOptions] = useState([]);
  const [PayLocDropdownValue, setPayLocDropdownValue] = useState(null);
  const [PayLocError, setPayLocError] = useState("");

  const [isEditMode, setIsEditMode] = useState(false);

  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [rowData, setRowData] = useState([]);
  const { userDetails, currentRoleId }: any = useUserContext();
  const [dropDown1, setDropDown1] = useState(true);
  const { state } = useLocation();
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [start, setStart] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [PageSize, setPageSize] = useState(10);
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("");
  const [formErrors, setFormErrors] = useState<any>({});
  const [selectedId, setSelectedId] = useState("");
  useEffect(() => {
    fetchData("");
  }, []);

  const fetchData = async (SearchQuery: string) => {
    await fetchUserData(SearchQuery);
    BusinessOptionValue();
    SBUOptionValue();
    LEOptionValue();
    PLocOptionValue();
  };

  useEffect(() => {
    getGridStructureTwo();
  }, [page, PageSize, start, sortDirection, sortColumn]);

  //Call api for all Dropdown
  const getDDData = async (AC, PID) => {
    const dropdowndataResponse = await APICall(getDropdowns, "POST", {
      AC,
      PID,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });
    const dropdownData = dropdowndataResponse.data;

    return dropdownData;
  };

  const getGridStructureTwo = async () => {
    showLoader();
    try {
      const GetDashboardData = await APICall(
        GetSchemeCreationDashboardData,
        "POST",
        {
          PageNumber: page,
          PageSize: PageSize,
          SortColumn: sortColumn,
          SortOrder: sortDirection,
          SearchText: searchText,
        }
      );

      if (
        (GetDashboardData.data !== null && GetDashboardData.data.length > 0) ||
        GetDashboardData.data.length == 0
      ) {
        setRowData(GetDashboardData.data);
        setCount(GetDashboardData.totalCount);
      } else {
        setRowData([]);
      }
    } catch (error) {
      console.error("Error fetching grid data:", error);
      hideLoader();
    }
    hideLoader();
  };

  const columns = [
    //0
    {
      name: "id",
      label: "SR. No",
      options: { display: true, sort: true },
    },
    //1
    {
      name: "schemeName",
      label: "Scheme",
      options: { display: true, sort: true, filter: false },
    },
    //2
    {
      name: "businessIds",
      label: "Max Points",
      options: { display: false, sort: false, filter: false },
    },
    //3
    {
      name: "businessName",
      label: "Business",
      options: { display: true, sort: false, filter: false },
    },
    //4
    {
      name: "sbuIds",
      label: "Score",
      options: { display: false, sort: false, filter: false },
    },
    //5
    {
      name: "sbuName",
      label: "SBU",
      options: { display: true, sort: false, filter: false },
    },
    //6
    {
      name: "legalEntityNameIds",
      label: "Grade",
      options: { display: false, sort: false, filter: false },
    },
    //7
    {
      name: "legalEntityName",
      label: "Legal Entity",
      options: { display: true, sort: false, filter: false },
    },
    //8
    {
      name: "payrollLocationIds",
      label: "Grade",
      options: { display: false, sort: false, filter: false },
    },
    //9
    {
      name: "payrollLocationName",
      label: "Payroll Location",
      options: { display: true, sort: false, filter: false },
    },

    // 10
    {
      name: "action",
      label: "Action",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Tooltip title="Edit">
                <i
                  style={{ marginRight: "0px", cursor: "pointer" }}
                  onClick={() => {
                    handleEdit(tableMeta.rowData);
                  }}
                  className="fas fa-edit"
                ></i>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: PageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",
    onChangePage: async (page) => {
      setPage(page);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      setPage(page);
      if (sortDirection === "asc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
    },
  };

  const fetchUserData = async (SearchQuery: string) => {
    showLoader();
    try {
      const response = await APICall(getAllUserMaster, "POST", {
        SearchQuery: SearchQuery || "",
      });

      //console.log(response.data);
    } catch (error) {
      console.error("Error fetching Role data:", error);
    } finally {
      hideLoader();
    }
  };

  const handleOnChangeSchName = (event) => {
    const value = event.target.value;
    setSchemeName(value);
    setFormErrors((prevErrors) => ({ ...prevErrors, SchemeName_isEmpty: "" }));
  };

  //Handle On change start
  const handleOnChange = async (DDName, e) => {
    if (DDName === "BIS") {
      setBusinessDropdownValue(e);
      setBISError("");
      setSBUDropdownValue(null);
      // setSubSectionDropdownOptions([]);
      const commaSeparatedBISIds =
        e && e.length > 0 && e?.map((e) => e.value).join(",");
      if (commaSeparatedBISIds) {
        let sbuData = await getDDData("SBU", commaSeparatedBISIds);
        setSBUDropdownOptions(sbuData);
      }
    }

    if (DDName === "SBU") {
      setSBUDropdownValue(e);
      setSBUError("");
      setLegalEntityDropdownValue(null);
      const commaSeparatedSBUIds =
        e && e.length > 0 && e?.map((e) => e.value).join(",");
      if (commaSeparatedSBUIds) {
        let LENData = await getDDData("LEN", commaSeparatedSBUIds);
        setLegalEntityDropdownOptions(LENData);
      }
    }

    if (DDName === "LEN") {
      let PID = "";
      setLegalEntityDropdownValue(e);
      setLegalEntityError("");
    }

    if (DDName === "PLOC") {
      let PID = "";
      setPayLocDropdownValue(e);
      setPayLocError("");
    }
  };
  //Handle On change end

  const BusinessOptionValue = async () => {
    const OptionValue = await getDDData("BISP", "");
    setBusinessDropdownOptions(OptionValue);
  };

  const SBUOptionValue = async () => {
    const OptionValue = await getDDData("SBUP", "");
    setSBUDropdownOptions(OptionValue);
  };

  const LEOptionValue = async () => {
    const OptionValue = await getDDData("LENP", "");
    setLegalEntityDropdownOptions(OptionValue);
  };

  const PLocOptionValue = async () => {
    const OptionValue = await getDDData("PLOC", "");
    setPayLocDropdownOptions(OptionValue);
  };

  const CheckValidation = () => {
    debugger;
    let objError: any = {};
    let error = false;
    setFormErrors({});

    if (SchemeName === "" || SchemeName === undefined || SchemeName === null) {
      error = true;
      objError["SchemeName_isEmpty"] = "Scheme Name is required";
    }
    if (!BusinessDropdownValue || BusinessDropdownValue.length == 0) {
      error = true;
      setBISError("Select Business");
    }

    if (!SBUDropdownValue) {
      error = true;
      setSBUError("Select SBU");
    }

    if (!LegalEntityDropdownValue) {
      error = true;
      setLegalEntityError("Select Legal Entity");
    }

    if (!PayLocDropdownValue) {
      error = true;
      setPayLocError("Select Pay Loc");
    }
    // if (BusinessDropdownValue === undefined || BusinessDropdownValue === null) {
    //   error = true;
    //   objError["BusinessDropdownValue_isEmpty"] = "Please select Business";
    //   // setBusinessDropdownValue("Please select Mapped Division");
    // }

    // if (SBUDropdownValue === undefined || SBUDropdownValue === null) {
    //   error = true;
    //   objError["SBUDropdownValue_isEmpty"] = "Please select SBU";
    //   // setSBUDropdownValue("Please select Mapped Division");
    // }

    // if (
    //   LegalEntityDropdownValue === undefined ||
    //   LegalEntityDropdownValue === null
    // ) {
    //   error = true;
    //   objError["LegalEntityDropdownValue_isEmpty"] =
    //     "Please select Legal Entity";
    //   // setLegalEntityDropdownValue("Please select Mapped Division");
    // }
    // if (PayLocDropdownValue === undefined || PayLocDropdownValue === null) {
    //   error = true;
    //   objError["PayLocDropdownValue_isEmpty"] =
    //     "Please select Payroll Location";
    //   // setPayLocDropdownValue("Please select Mapped Division");
    // }
    setFormErrors(objError);
    return error;
  };

  const handleSubmit = async () => {
    showLoader();
    let IsInValid = await CheckValidation();
    if (IsInValid == false) {
      const response = await APICall(
        InsertUpdateSchemeCreationMapping,
        "POST",
        {
          Id: Number(selectedId),
          SchemeName: SchemeName,
          BusinessIds: BusinessDropdownValue
            ? BusinessDropdownValue.map((e) => e.value).join(",")
            : "",
          SBUIds: SBUDropdownValue
            ? SBUDropdownValue.map((e) => e.value).join(",")
            : "",
          LegalEntityNameIds: LegalEntityDropdownValue
            ? LegalEntityDropdownValue.map((e) => e.value).join(",")
            : "",
          PayrollLocationIds: PayLocDropdownValue
            ? PayLocDropdownValue.map((e) => e.value).join(",")
            : "",
          ModifiedBy: userDetails.Id,
          IsActive: true,
        }
      );
      if (response.message === "Ok") {
        notify(0, "Data updated");
        setSchemeName("");
        setBusinessDropdownValue(null);
        setSBUDropdownValue(null);
        setLegalEntityDropdownValue(null);
        setPayLocDropdownValue(null);
        setBISError("");
        setSBUError("");
        setLegalEntityError("");
        setPayLocError("");
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          SchemeName_isEmpty: "",
          // BusinessDropdownValue_isEmpty: "",
          // SBUDropdownValue_isEmpty: "",
          // LegalEntityDropdownValue_isEmpty: "",
          // PayLocDropdownValue_isEmpty: "",
        }));
        getGridStructureTwo();
      }
    }
    hideLoader();
  };

  const handleEdit = (rowData) => {
    showLoader();
    // Assuming rowData contains all necessary data in the correct order
    const [
      id,
      schemeName,
      businessIds,
      businessName,
      sbuIds,
      sbuName,
      legalEntityIds,
      legalEntityName,
      payrollLocationIds,
      payrollLocationName,
    ] = rowData;

    setSelectedId(id);
    setSchemeName(schemeName);
    const businessIdsArray = businessIds ? businessIds.split(",") : [];
    const selectedBisLabels = [];
    businessIdsArray?.map((value) => {
      const Bis = BusinessDropdownOptions.find(
        (option) => option.value == value
      );
      if (Bis !== undefined) {
        selectedBisLabels.push(Bis);
      }
    });
    setBusinessDropdownValue(selectedBisLabels);

    // Split and map sbuIds to set dropdown value
    const sbuIdsArray = sbuIds ? sbuIds.split(",") : [];
    const selectedSBULabels = [];
    sbuIdsArray?.map((value) => {
      const SBU = SBUDropdownOptions.find((options) => options.value == value);
      if (SBU !== undefined) {
        selectedSBULabels.push(SBU);
      }
    });
    setSBUDropdownValue(selectedSBULabels);

    // Split and map legalEntityIds to set dropdown value
    const legalEntityIdsArray = legalEntityIds ? legalEntityIds.split(",") : [];
    const selectedlenLabels = [];
    legalEntityIdsArray?.map((value) => {
      const Len = LegalEntityDropdownOptions.find(
        (options) => options.value == value
      );
      if (Len !== undefined) {
        selectedlenLabels.push(Len);
      }
    });
    setLegalEntityDropdownValue(selectedlenLabels);

    // Split and map payrollLocationIds to set dropdown value
    const payrollLocationIdsArray = payrollLocationIds
      ? payrollLocationIds.split(",")
      : [];
    const foundPloc = [];
    payrollLocationIdsArray?.map((value) => {
      const Ploc = PayLocDropdownOptions.find(
        (option) => option.value == value
      );
      if (Ploc !== undefined) {
        foundPloc.push(Ploc);
      }
    });
    setPayLocDropdownValue(foundPloc);
    setIsEditMode(true);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      SchemeName_isEmpty: "",
      BusinessDropdownValue_isEmpty: "",
      SBUDropdownValue_isEmpty: "",
      LegalEntityDropdownValue_isEmpty: "",
      PayLocDropdownValue_isEmpty: "",
    }));
    hideLoader();
  };

  const handleReset = () => {
    showLoader();
    setSchemeName("");
    setBusinessDropdownValue(null);
    setSBUDropdownValue(null);
    setLegalEntityDropdownValue(null);
    setPayLocDropdownValue(null);
    setIsEditMode(false);
    setSelectedId("");
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      SchemeName_isEmpty: "",
      BusinessDropdownValue_isEmpty: "",
      SBUDropdownValue_isEmpty: "",
      LegalEntityDropdownValue_isEmpty: "",
      PayLocDropdownValue_isEmpty: "",
    }));
    hideLoader();
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              {/* Scheme name */}
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Scheme Name<sup>*</sup>
                  </label>
                  <InputForm
                    isDisabled={false}
                    textArea={false}
                    value={SchemeName}
                    placeholder={"Scheme Name"}
                    onChange={handleOnChangeSchName}
                  />
                  <p style={{ color: "red" }}>
                    {formErrors["SchemeName_isEmpty"]}
                  </p>
                </div>
              </div>
              {/* Business */}
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown3">
                    Business<sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                    id="dropdown3"
                    value={BusinessDropdownValue}
                    placeholder="Select an option"
                    options={BusinessDropdownOptions}
                    onChange={(e) => {
                      handleOnChange("BIS", e);
                    }}
                  />
                  <p style={{ color: "red" }}>
                    {/* {formErrors["BusinessDropdownValue_isEmpty"]} */}
                    {BISError}
                  </p>
                </div>
              </div>

              {/* SBU */}
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown4">
                    SBU<sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                    id="dropdown4"
                    value={SBUDropdownValue}
                    placeholder="Select an option"
                    options={SBUDropdownOptions}
                    onChange={(e) => {
                      handleOnChange("SBU", e);
                    }}
                  />
                  <p style={{ color: "red" }}>
                    {/* {formErrors["SBUDropdownValue_isEmpty"]} */}
                    {SBUError}
                  </p>
                </div>
              </div>

              {/* Legal Entity */}
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown5">
                    Legal Entity<sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                    id="dropdown5"
                    value={LegalEntityDropdownValue}
                    placeholder="Select an option"
                    options={LegalEntityDropdownOptions}
                    onChange={(e) => {
                      handleOnChange("LEN", e);
                    }}
                  />
                  <p style={{ color: "red" }}>
                    {/* {formErrors["LegalEntityDropdownValue_isEmpty"]} */}
                    {LegalEntityError}
                  </p>
                </div>
              </div>

              {/* Payroll Location */}
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown16">
                    Payroll Location<sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                    id="dropdown16"
                    value={PayLocDropdownValue}
                    placeholder="Select an option"
                    options={PayLocDropdownOptions}
                    onChange={(e) => {
                      handleOnChange("PLOC", e);
                    }}
                  />
                  <p style={{ color: "red" }}>
                    {/* {formErrors["PayLocDropdownValue_isEmpty"]} */}
                    {PayLocError}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-sm-12 col-xs-12 mt-3">
            <div style={{ textAlign: "end" }}>
              <button
                onClick={handleReset}
                disabled={false}
                className="btn btn-secondary float-right ml-2"
              >
                <i className="fa fa-undo"></i> Reset
              </button>

              <Button
                className="btn btn-filter-submit float-right btnBlue"
                onClick={handleSubmit}
              >
                <i className="fa-solid fa-check"></i>{" "}
                {isEditMode ? "Update" : "Submit"}
              </Button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className=" col-lg-12 mb-3 pt-3">
            <DynamicGrid data={rowData} columns={columns} options={options} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SchemeCreationMaster;
