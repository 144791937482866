import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import { LoaderContext } from "../../Helpers/Context/Context";
import "./FormStructure.css"; 
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import notify from "../../Helpers/ToastNotification";
import { APICall } from "../../Helpers/APICalls";
import { GetConfigValueByKey, getReplaceBonafiedData, insertIntoAttachmentDetails } from "../../Helpers/APIEndPoints/EndPoints";
function PrintConfirmation({
  printConfirmation,
  setPrintConfirmation,
  setPrintpreviewModal,
  setPrintConfirmationTab,
  printConfirmationTab,
  setBonafidemodal,
  printPreviewValueForApiCall,
}) {
    let { userDetails, proxyUserDetails, currentRoleId }: any = useUserContext();
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const [showConfigurationPrintModal, setShowConfigurationModal] = useState(false); 
    const [isDisabledConfiguration,setIsDisabledConfiguration] = useState(false);
    const handleDownloadFileFlat = async (fileAttribute, fileIndex) => {
      showLoader();
      downloadBase64File(fileAttribute[0].Data, fileAttribute[0].Name);
      hideLoader();
      };
      function downloadBase64File(base64String: string, fileName: string) {
      const mimeType = inferMimeType(base64String);
      const blob = base64toBlob(base64String, mimeType);
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      }
      function inferMimeType(base64String: string): string {
      const dataUrl = `data:application/octet-stream;base64,${base64String}`;
      const typeInfo = /^data:(.*?);/.exec(dataUrl);
      if (typeInfo && typeInfo[1]) {
      return typeInfo[1];
      }
      return "application/octet-stream";
      }
      function base64toBlob(base64String: string, mimeType: string): Blob {
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: mimeType });
      }
    const getApiFunCallForDMSbonafide = async (data) => {
      showLoader();
      try{
        const responseURL = await APICall(
          GetConfigValueByKey,
           "POST", {
          Key: "WelspunDMSAPILink",
        });
         if(responseURL?.status === 0){
          if(responseURL?.data){
             const urlDocGenerated = await APICall(
               `${responseURL.data}/GenerateBonafiedDocument`,
               "POST",
               {
                 documentNames:"BONAFIED_EMPLOYMENT_PROOF_LETTER,BONAFIED_SALARY_CERTIFICATE_FOR_HOME_LOAN,BONAFIED_ADDRESS_PROOF_LETTER",
                 attributes:data,
               },
             );
             if (urlDocGenerated?.status === 200) {
              const dataFile = [
                {
                AC: "BON",
                Name: urlDocGenerated.data.filename,
                Data: urlDocGenerated.data.base64String,
                IsValidFile: false,
                DocId: urlDocGenerated.data.documentId,
                },
                ]
               if (urlDocGenerated?.data) {
                // setDocTempfile(dataFile);
                await handleDownloadFileFlat(dataFile, 0)
                    const responseUpload = await APICall(
                        insertIntoAttachmentDetails,
                      "POST",
                      {
                          Eid: urlDocGenerated.data.employeeid,
                          fileName: urlDocGenerated.data.filename,
                          documentID: urlDocGenerated.data.documentId,
                          fileUrl: urlDocGenerated.data.documentUrl,
                          UserId: proxyUserDetails?.value ? proxyUserDetails.value : userDetails?.UserId,
                      },
                    );
                    if(responseUpload?.status === 0){
                      notify(0,"File Downloaded Sucessfully")
                    }
                                    
               } else {
                 return null;
               }
             } 
           
          }
         }
          else {
            return null;
          }
      }
      catch (error) {
        console.error("Error in Dms link for Bonafide and its functionality:", error);
        return null;
      } finally {
        hideLoader();
      }
    }
    const getApiFuncCallForDocumentDownload = async()=> {
      showLoader();
      try {
        const response = await APICall(
          getReplaceBonafiedData,
          "POST",
          {
            EID : printPreviewValueForApiCall.TId,
          },
        );
        if (response?.status === 0) {
          if (response?.data) {
           getApiFunCallForDMSbonafide(response?.data);
          } else {
            return null;
          }
        } else {
          return null;
        }
      } catch (error) {
        console.error("Error in Fetching Legal Entity and Values for Bonafide:", error);
        return null;
      } finally {
        hideLoader();
      }
    }
    const handleModalConfigurationClose = () => {
      function customizedAsync(){
        setIsDisabledConfiguration(true);
        setPrintConfirmation(false);
        setShowConfigurationModal(false);
        setPrintConfirmationTab("");
        if(printConfirmationTab==="bonafide") setBonafidemodal(false);
        if(printConfirmationTab==="sectionPrint") setPrintpreviewModal(false);
      }
      showLoader();
      const timer = setTimeout(() => {
        hideLoader();
        customizedAsync();
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
      };
    const handleModalConfigurationSubmit = () =>{
      function customizedAsync(){
        setIsDisabledConfiguration(true);
        setPrintConfirmation(false);
        setShowConfigurationModal(false);
        setPrintConfirmationTab("");
        if(printConfirmationTab==="bonafide"){
          getApiFuncCallForDocumentDownload();
          setBonafidemodal(false)};
        if(printConfirmationTab==="sectionPrint") setPrintpreviewModal(true);
      }
      showLoader();
      const timer = setTimeout(() => {
        hideLoader();
        customizedAsync();
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    };
    
      useEffect(() => {
        function customizedAsync(){
        if (printConfirmation) {
          setIsDisabledConfiguration(false);
          setShowConfigurationModal(true);
        }
        else{
          setShowConfigurationModal(false);
        }
      }
      showLoader();
      const timer = setTimeout(() => {
        hideLoader();
        customizedAsync();
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
      }, [printConfirmation]);
      return (
        <Modal
          show={showConfigurationPrintModal}
          onHide={handleModalConfigurationClose}
          backdrop="static"
          keyboard={false}
          size="lg"
          centered
        >
          <div
            className="bg-secondary text-white"
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <div className="col-lg-12">{printConfirmationTab==="sectionPrint" ? "Print Preview Profile":"Bonafide Letter" }</div>
          </div>
          <Modal.Body>
               <p className="col-form-label">
               {printConfirmationTab==="sectionPrint" ? "Do you want to Preview Employee profile ?":" Do you want to Download Bonafide Letter ?" }
                
               </p>
          </Modal.Body>
          <Modal.Footer>
          <button
              type="button"
              disabled={isDisabledConfiguration}
              className="btn btn-primary ml-3"
              onClick={handleModalConfigurationSubmit}
            >
              Yes    
            </button>
            <button
              type="button"
              disabled={isDisabledConfiguration}
              className="btn btn-secondary ml-3"
              onClick={handleModalConfigurationClose}
            >
              No
            </button>
          </Modal.Footer>
        </Modal>
      );
}
export default PrintConfirmation;
