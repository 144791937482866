export const NationalIdCardType = Object.freeze({
  AADHAR: /^\d{12}$/,
  UAN: /^\d{12}$/,
  PRAN: /^\d{12}$/,
  PAN: /^[A-Z]{5}\d{4}[A-Z]$/,
  DRIVINGLICENCE: /^[A-Z]{2}\d{13}$/,
  VOTERID: /^[A-Z]{3}\d{7}$/,
  ESIC: /^\d{10}$/,
  PASSPORT: /^[A-Z]{1}\d{7}$/,
  RATIONCARD: /^\d{15}$/,
  PF: /^[A-Z]{5}\d{17}$/,
  APPRENTICEREGISTRATIONNUMBER: /^\d{12}$/,
  EMAILID: /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
  MOBILENO: /^(\d{10})$/,
  NATIONALINSURANCENUMBER: /^[A-Z]{2}\d{6}[A-Z]$/,
  GSTNumber: /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d{1}Z\d{1}$/,
  STaxNumber: /^[A-Z]{5}\d{4}[A-Z]{1}ST\d{3}$/,
  CSTNumber: /^[A-Z0-9]{11}$/,
  TANNumber: /^[A-Z]{4}\d{5}[A-Z]$/,
});
