import React, { useEffect, useState } from "react";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { APICall } from "../../Helpers/APICalls";
import { GetTATReport } from "../../Helpers/APIEndPoints/AssociateEndPoints";

function Reportss() {
  const [dashboard, setdashboard] = useState([]);
  const [dashboardPageSize, setDashboardPageSize] = useState(10);
  const [dashboardStart, setDashboardStart] = useState(0);
  const [dashboardSortColumn, setDashboardSortColumn] = useState<any>("");
  const [dashboardSortDirection, setDashboardSortDirection] = useState("");
  const [dashboardCount, setDashboardCount] = useState(0);

  useEffect(() => {
    GetTATReports();
  }, [dashboardPageSize]);

  const GetTATReports = async () => {
    // showLoader();
    const response = await APICall(GetTATReport, "POST", {});

    console.log(response?.data, "GetTATReport");

    if (response?.data != null) {
      setdashboard(response?.data);
    }
    //hideLoader();
  };

  const data = [
    // {
    //   Stage: "Candidate Authentication",
    //   StartTime: "Aadhaar Validation",
    //   EndTime: "QR code Generation",
    //   Candidate: 35,
    //   AverageTAT: "",
    //   BaseLineTAT: 6,
    //   BenchmarkTAT: 5,
    //   Deviation: "",
    // },
    // {
    //   Stage: "Candidate Authentication",
    //   StartTime: "Aadhaar Validation",
    //   EndTime: "QR code Generation",
    //   Candidate: 35,
    //   AverageTAT: "",
    //   BaseLineTAT: 6,
    //   BenchmarkTAT: 5,
    //   Deviation: "",
    // },
    // {
    //   Stage: "Candidate Authentication",
    //   StartTime: "Aadhaar Validation",
    //   EndTime: "QR code Generation",
    //   Candidate: 35,
    //   AverageTAT: "",
    //   BaseLineTAT: 6,
    //   BenchmarkTAT: 5,
    //   Deviation: "",
    // },
    // {
    //   Stage: "Candidate Authentication",
    //   StartTime: "Aadhaar Validation",
    //   EndTime: "QR code Generation",
    //   Candidate: 35,
    //   AverageTAT: "",
    //   BaseLineTAT: 6,
    //   BenchmarkTAT: 5,
    //   Deviation: "",
    // },
    // {
    //   Stage: "Candidate Authentication",
    //   StartTime: "Aadhaar Validation",
    //   EndTime: "QR code Generation",
    //   Candidate: 35,
    //   AverageTAT: "",
    //   BaseLineTAT: 6,
    //   BenchmarkTAT: 5,
    //   Deviation: "",
    // },
  ];
  const columns = [
    {
      name: "stage",
      label: "Stage",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => <strong>{value}</strong> // Make the stage values bold
      },
    },
    {
      name: "startTime",
      label: "Start Time",
      options: {
        filter: false,
        sort: true,
       
      },
    },
    {
      name: "endTime",
      label: "End Time",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "count",
      label: "Candidate Count",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "average",
      label: "Average TAT (Actual)",
      options: {
        filter: true,
        sort: true,
        customHeadRender: ({ index, ...column }) => {
          return (
            <>
              <th key={index} colSpan={4} style={{ textAlign: "center" }}>
                TAT In MINUTES
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <th style={{ border: "0px" }}>Average TAT(Actual)</th>
                  <th style={{ border: "0px", marginLeft: "100px" }}>
                    BaseLine TAT
                  </th>
                  <th style={{ border: "0px", marginLeft: "100px" }}>
                    {" "}
                    Benchmark TAT
                  </th>
                  <th style={{ border: "0px", marginLeft: "10px" }}>
                    Deviation(Actual vs Benchmark)
                  </th>
                </div>
              </th>
            </>
          );
        },
      },
    },
    {
      name: "baseline",
      label: "Baseline TAT",
      options: {
        filter: true,
        sort: true,
        customHeadRender: ({ index, ...column }) => {
          return null;
        },
      },
    },
    {
      name: "benchmark",
      label: "Benchmark TAT",
      options: {
        filter: true,
        sort: true,
        customHeadRender: ({ index, ...column }) => {
          return null;
        },
      },
    },
    {
      name: "difference",
      label: "Deviation(Actual vs Benchmark)",
      options: {
        filter: true,
        sort: true,
        customHeadRender: ({ index, ...column }) => {
          return null;
        },
      },
    },
  ];

  const dashboardOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: dashboardCount,
    rowsPerPage: dashboardPageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    onChangeRowsPerPage: (num) => {
      //   setLimit(num);
      //   setNxtPgInfo("");
      //   setPrevPgInfo("");
      //   setIsPrevOrNext("");
    },
    onSearchChange: (searchText) => {
      if (searchText !== null) {
        //setDashboardSearchText(searchText);
      } else {
        //setDashboardSearchText("");
      }
    },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setDashboardSortColumn(sortColumn);
        await setDashboardSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setDashboardSortColumn(sortColumn);
        await setDashboardSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      setDashboardStart(page * dashboardPageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };
  return (
    <div className="mb-3 pt-3" id="candidata-page1">
      <DynamicGrid
        // data={data.map((row) => Object.values(row))}
        data={dashboard}
        columns={columns}
        options={dashboardOptions}
      />
    </div>
  );
}

export default Reportss;
