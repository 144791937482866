import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

const useUserContext: any = create(
  persist(
    devtools((set) => ({
      userDetails: null,
      addUserDetails: (data) => set({ userDetails: data }),
      updateUserDetails: (data) => (set) => ({}),
      deleteUserDetails: () => set({ userDetails: null }),
      currentRoleId: null,
      updateCurrentRoleId: (data) => set({ currentRoleId: data }),
      interfacePermissionRecord: null,
      updateInterfacePermissionRecord: (data) =>
        set({ interfacePermissionRecord: data }),
      sectionInterfacePermissionRecord: null,
      updateSectionInterfacePermissionRecord: (data) =>
        set({ sectionInterfacePermissionRecord: data }),
      proxyUserDetails: null,
      updateProxyUserDetails: (data) => set({ proxyUserDetails: data }),
      proxyUserRoleDetails: null,
      updateProxyUserRoleDetails: (data) => set({ proxyUserRoleDetails: data }),
      proxyUserRoleOptions: [],
      updateProxyUserRoleOptions: (data) => set({ proxyUserRoleOptions: data }),
    })),
    { name: "userDetails" }
  )
);

export default useUserContext;
