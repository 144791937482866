import React, { useContext, useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useUserContext from "../../../Helpers/ZustandStore/UserContextStore";
import InputForm from "../../../Components/InputForm/InputForm";
import DateForm from "../../../Components/DateForm/DateForm";
import SelectForm from "../../../Components/SelectForm/SelectForm";
import DynamicGrid from "../../../Components/DynamicGrid/DynamicGrid";
import { LoaderContext } from "../../../Helpers/Context/Context";
import { APICall } from "../../../Helpers/APICalls";
import "./FeedbackForm.css";
import {
  getCompetencyCategoryData,
  getDropdowns,
  getEvaluatorFeedbackByMrfId,
  insertIntoEvaluatorFeedback,
} from "../../../Helpers/APIEndPoints/EndPoints";
import { Rating, Tooltip } from "@mui/material";
import { Modal, Table } from "react-bootstrap";
import uuid from "react-uuid";
import notify from "../../../Helpers/ToastNotification";

const FeedbackForm = (props) => {
  let { userDetails, currentRoleId }: any = useUserContext();

  const { state } = useLocation();

  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [formErrors, setFormErrors] = React.useState<any>({});
  const [isDisable, setIsDisable] = useState(true);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [typeofActivityOptions, setTypeofActivityOptions] = useState([]);
  const [evaluatorsOptions, setevaluatorsOptions] = useState([]);

  //compentency options
  const [competencyCategoryData, setCompetencyCategoryData] = useState([]);

  const [isShow, setIsShow] = useState(false);

  const [TId, setTId] = useState(state?.TId || 0);

  const [mainArr, setMainArr] = useState([]);
  const [originalMainArr, setOriginalMainArr] = useState([]);
  const [dashboardGridColumns, setDashboardGridColumns] = useState([]);

  const [responsiblePersonArr, setResponsiblePersonsArr] = useState([]);
  const [isRecruiter, setIsRecruiter] = useState(false);

  const [attributesData, setAttributesData] = React.useState<any>({
    candidateId: "",
    candidateName: "",
    stage: "",
    typeofActivity: null,
    evaluators: null,
    evaluationDate: new Date(),
    competencyCategory: null,
    competency: null,
    rating: 0,
    remarks: "",
  });

  const [currentStage, setCurrentStage] = useState(0);
  const [typeOfActivity, setTypeOfActivity] = useState(0);

  const [cwiDecision, setCWIDecision] = useState(
    props?.feedbackData?.cwiDecision || ""
  );
  const [disableForm, setDisableForm] = useState(false);

  const navigate = useNavigate();

  const [isEvaluator, setIsEvaluator] = useState(false);
  const [isOpenModalPopup, setIsOpenModalPopup] = useState(false);

  const [evaluatorRemarks, setEvaluatorRemarks] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await getDropdownsDataForTypeofActivityApiCall("SARACT", "");
      await getCompetencyCategoryDataApiCall();

      //
      const responsiblePerson = props?.feedbackData?.responsiblePersons || [];
      setResponsiblePersonsArr(responsiblePerson);
      const IsView = props?.isView;
      setTypeOfActivity(props?.feedbackData?.typeofActivity?.value);
      setCurrentStage(props?.feedbackData?.stage);

      setIsShow(!IsView);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const { feedbackData } = props;
    if (!feedbackData) return;

    let { feedbackObj, evaluators, responsiblePerson, isRecruiter } =
      feedbackData;

    evaluators = evaluators?.length > 0 ? evaluators : [];
    feedbackObj = feedbackObj?.length > 0 ? feedbackObj : [];

    const checkIfEvaluator =
      (evaluators?.some((i) => i?.value == userDetails?.Id) || false) &&
      state?.isEvaluator;

    setAttributesData((prev) => {
      return {
        ...prev,
        candidateId: feedbackData?.candidateId,
        candidateName: feedbackData?.candidateName,
        stage: feedbackData?.stage,
        typeofActivity: feedbackData?.typeofActivity,
        evaluators: evaluators,
        evaluationDate: new Date(),
        candidateWorkflowTaskId: feedbackData?.candidateWorkflowTaskId,
      };
    });

    setIsEvaluator(checkIfEvaluator);
    setIsRecruiter(isRecruiter);

    if (!checkIfEvaluator) {
      addResponsiblePersonColumns(evaluators);

      const updateMainArr = mainArr.map((i, j) => {
        let sum = 0;
        let avgRating = 0;
        let totalCount = 0;
        let obj = {};
        const evaluatorMessage = {};

        if (evaluators?.length > 0) {
          evaluators?.forEach((responsiblePersonObj, index) => {
            const ratingName = `responsiblePerson_${responsiblePersonObj.value}`;
            const findObject = feedbackObj.find(
              (feedback) =>
                feedback.competencyCategoryId === i.categoryId &&
                i.competencyId === feedback.competencyId &&
                responsiblePersonObj.value === feedback.evaluatorId
            );
            let rating: any = " - ";
            if (
              findObject &&
              findObject.rating !== null &&
              findObject.rating !== undefined
            ) {
              rating = Number(findObject?.rating);
              sum += Number(findObject?.rating);
              obj[responsiblePersonObj.value] = 0;

              // add remarks
              if (findObject.remarks) {
                let prevEval = [...evaluatorRemarks];

                prevEval.push({
                  eName: responsiblePersonObj.label,
                  remarks: findObject.remarks || "",
                });
                evaluatorMessage[responsiblePersonObj.value] = {
                  eName: responsiblePersonObj.label,
                  remarks: findObject.remarks || "",
                };
                prevEval = prevEval.filter(
                  (item, index) => prevEval.indexOf(item) === index
                );
              }
            }

            i[ratingName] = rating;
          });
        }

        let arr = Object.keys(evaluatorMessage).map((key) => {
          return evaluatorMessage[key];
        });
        setEvaluatorRemarks(arr);
        totalCount = Object.keys(obj).length;
        //const totalCount = evaluators?.length || 0;
        if (totalCount !== 0) avgRating = sum / totalCount;
        i.AverageRating = totalCount !== 0 ? avgRating : " - ";

        return i;
      });

      if (updateMainArr?.length > 0) setMainArr(updateMainArr);
    } else {
      if (feedbackObj) {
        const arr = feedbackObj.filter((i) => i.evaluatorId === userDetails.Id);

        if (arr?.length > 0) {
          setDisableForm(true);

          // add remark
          setAttributesData({
            ...attributesData,
            remarks: arr[0].remarks || "",
          });

          const updatedMainArr = mainArr.map((i) => {
            const findObject = arr.find(
              (j) =>
                j.competencyCategoryId === i.categoryId &&
                i.competencyId === j.competencyId
            );

            if (findObject) {
              i.rating = findObject.rating;
            }
            return i;
          });

          if (updatedMainArr?.length > 0) setMainArr(updatedMainArr);
          setMainArr(updatedMainArr);
        } else {
          setDisableForm(false);
          const updatedMainArr = mainArr.map((i) => {
            i.rating = 0;
            return i;
          });
          setMainArr(updatedMainArr);
        }
      }
    }
  }, [props.modal]);

  const [isFeedbackAvailable, setIsFeedbackAvailable] = useState(false);

  useEffect(() => {
    if (mainArr.length > 0) {
      setIsFeedbackAvailable(
        mainArr.every(
          (data) =>
            data.hasOwnProperty("AverageRating") && !isNaN(data?.AverageRating)
        )
      );
    }
  }, [mainArr]);

  const addResponsiblePersonColumns = (evaluators = []) => {
    const newColumns =
      evaluators?.flatMap((responsiblePersonObj) => {
        const label = responsiblePersonObj.label || "";

        const ratingName = `responsiblePerson_${responsiblePersonObj.value}`;

        const nameColumn = {
          name: ratingName,
          label,
          options: {
            filter: false,
            sort: false,
            sortDescFirst: true,
            display: isShow,
            setCellProps: () => ({
              style: { textAlign: "center" },
            }),
            setCellHeaderProps: () => ({
              style: { textAlign: "center" },
            }),
          },
        };

        return [nameColumn];
      }) || [];

    const updatedColumns = [...gridColumns, ...newColumns, action];
    setDashboardGridColumns(updatedColumns);
  };

  useEffect(() => {
    if (!props.modal) {
      setEvaluatorRemarks([]);
    }
  }, [props.modal]);

  const getCompetencyCategoryDataApiCall = async () => {
    const response = await APICall(getCompetencyCategoryData, "POST", {
      TId: TId,
    });

    if (response?.data && response?.data?.length > 0) {
      setCompetencyCategoryData(response?.data);

      //1. set data for main arr
      const compentencyArr = response?.data;
      let temp = [];
      for (const i of compentencyArr) {
        let object = {};

        Object.keys(responsiblePersonArr).map((key, index) => {
          let value = responsiblePersonArr[key]?.label || "";
          object[`responsiblePerson${index + 1}`] = value;
        });

        object["categoryId"] = i?.competencyCategoryId || 0;
        object["categoryName"] = i?.competencyCategoryName || "";
        object["competencyId"] = i?.competencyId || 0;
        object["competencyName"] = i?.competencyName || "";
        object["rating"] = 0;
        object["id"] =
          i?.id != null && i?.id != undefined && i?.id != 0 ? i?.id : uuid();

        temp.push(object);
      }
      if (temp?.length > 0) setMainArr(temp);

      setOriginalMainArr(temp);
    } else {
      setCompetencyCategoryData([]);
    }
  };

  const getDropdownsDataForEvaluatorsAPICall = async (AC, PID) => {
    await showLoader();
    const dropdownData = await APICall(getDropdowns, "POST", {
      AC,
      PID,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    let evaluatorArray = [];

    if (
      dropdownData.data !== null &&
      dropdownData.data !== undefined &&
      dropdownData.data.length > 0
    ) {
      await hideLoader();
      evaluatorArray = dropdownData.data;

      return evaluatorArray;
    } else {
      await hideLoader();

      return evaluatorArray;
    }
  };

  const getDropdownsDataForTypeofActivityApiCall = async (AC, PID) => {
    await showLoader();
    const dropdownData = await APICall(getDropdowns, "POST", {
      AC,
      PID,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    if (
      dropdownData.data !== null &&
      dropdownData.data !== undefined &&
      dropdownData.data.length > 0
    ) {
      await hideLoader();
      setTypeofActivityOptions(dropdownData.data);
    } else {
      await hideLoader();
      setTypeofActivityOptions([]);
    }
  };

  const action = {
    name: "AverageRating",
    label: "Average Rating",
    options: {
      filter: false,
      sort: false,
      setCellProps: () => ({
        style: { textAlign: "center" },
      }),
      setCellHeaderProps: () => ({
        style: { textAlign: "center" },
      }),
    },
  };

  const onChange = (key, value) => {
    setAttributesData({ ...attributesData, [key]: value });
  };

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: mainArr?.length,
    rowsPerPage: pageSize,
    page: page,
    serverSide: false,
    onChangePage: async (page) => {
      setPage(page);
    },
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",
    onSearchChange: (searchText) => {},
    onColumnSortChange: async (sortColumn, sortDirection) => {},
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const gridColumns = [
    {
      name: "Id",
      label: "Id",
      options: {
        display: false,
      },
    },

    {
      name: "categoryName",
      label: "Category",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },
    {
      name: "competencyName",
      label: "Competency",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },
  ];

  const submitFunction = async () => {
    try {
      const noRating = mainArr.some((data) => data.rating == 0);
      if (noRating) {
        notify(1, "Fill all rating");
        return null;
      }

      let arr = mainArr.map((i) => {
        return {
          ...i,
          id: !Number.isNaN(Number(i?.id)) ? Number(i?.id) : 0,
          stage: !Number.isNaN(Number(attributesData?.stage))
            ? Number(attributesData?.stage)
            : 0,
          ActivityId: typeOfActivity,
          CompetencyCategoryId: i?.categoryId,
          CompetencyId: i?.competencyId,
          candidateId: !Number.isNaN(Number(state.CandidateId))
            ? Number(state.CandidateId)
            : 0,
          Remarks:
            attributesData.remarks !== "" ? attributesData.remarks : null,
        };
      });

      const obj = {
        TId: TId,
        candidateId: !Number.isNaN(Number(state.CandidateId))
          ? Number(state.CandidateId)
          : 0,

        UserId: userDetails.Id,
        CreatedBy: userDetails.Id,
        RoleId: currentRoleId?.value,
        WorkflowTaskId: state?.Id,
        Details: arr,
        CandidateWorkflowTaskId: attributesData?.candidateWorkflowTaskId || 0,
        MRFId: state?.TId,
      };
      showLoader();
      const response = await APICall(insertIntoEvaluatorFeedback, "POST", obj);
      if (response?.status == 0) {
        notify(0, "Record Saved Successfully.");
        navigate("/home");
      } else {
        notify(1, "Something went wrong.");
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <Modal
        show={props.modal}
        onHide={() => props.setModal(false)}
        dialogClassName="modal-80w"
        backdrop="static"
        keyboard={false}
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">Feedback</div>
        </div>
        <Modal.Body
          style={{ maxHeight: "calc(100vh - 150px)", overflowY: "auto" }}
        >
          <div className="SectionSubmit mb-4 clearfix"></div>
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">Candidate ID</label>
                  <InputForm
                    className="form-control"
                    placeholder={"Candidate ID"}
                    isDisabled={isDisable}
                    textArea={false}
                    value={attributesData?.candidateId || 0}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      onChange("candidateId", value);
                    }}
                  />
                  <p style={{ color: "red" }}>{formErrors["candidateId"]}</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">Candidate Name</label>
                  <InputForm
                    className="form-control"
                    placeholder={"Candidate Name"}
                    isDisabled={isDisable}
                    textArea={false}
                    value={attributesData?.candidateName || ""}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      onChange("candidateName", value);
                    }}
                  />
                  <p style={{ color: "red" }}>{formErrors["candidateName"]}</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">Stage</label>
                  <InputForm
                    className="form-control"
                    placeholder={"Stage"}
                    isDisabled={isDisable}
                    textArea={false}
                    value={attributesData?.stage}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      onChange("stage", value);
                    }}
                  />
                  <p style={{ color: "red" }}>{formErrors["stage"]}</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">Type of Activity</label>
                  <SelectForm
                    isClearable
                    isSearchable
                    options={typeofActivityOptions}
                    placeholder={"Type of Activity"}
                    isDisabled={isDisable}
                    value={attributesData?.typeofActivity}
                    onChange={(val: any) => {
                      onChange("typeofActivity", val);
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  <p style={{ color: "red" }}>{formErrors["typeofActivity"]}</p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="dropdown mb-1">
                  <label className="col-form-label">Evaluator(s)</label>
                  <SelectForm
                    isClearable
                    isSearchable
                    options={evaluatorsOptions}
                    placeholder={"Evaluators"}
                    isDisabled={isDisable}
                    value={props?.feedbackData?.evaluators || null}
                    onChange={(val: any) => {
                      onChange("evaluators", val);
                    }}
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  <p style={{ color: "red" }}>{formErrors["evaluators"]}</p>
                  <div className="dropdown-content">
                    {props?.feedbackData?.evaluators?.length > 1 && (
                      <>
                        {props?.feedbackData?.evaluators?.map((i) => (
                          <p>{i?.label || ""}</p>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
              {isEvaluator && (
                <div className="col-lg-3 col-sm-3 col-xs-4">
                  <div className="mb-1">
                    <label className="col-form-label">Remarks</label>
                    <InputForm
                      className="form-control"
                      placeholder={"Remarks"}
                      isDisabled={disableForm ? true : !isShow}
                      textArea={false}
                      value={attributesData?.remarks || ""}
                      onChange={(val: any) => {
                        let value = val.target.value;
                        onChange("remarks", value);
                      }}
                    />
                    <p style={{ color: "red" }}>{formErrors["remarks"]}</p>
                  </div>
                </div>
              )}
              <div className="col-lg-3 col-sm-3 col-xs-4">
                {cwiDecision === "PendingWithResponsiblePerson" && (
                  <div className="mb-3">
                    <label className="col-form-label">Evaluation Date</label>
                    <DateForm
                      isDisabled={disableForm ? true : !isShow}
                      value={attributesData.evaluationDate}
                      onChange={(val: any) => {
                        onChange("evaluationDate", val);
                      }}
                    />
                    <p style={{ color: "red" }}>
                      {formErrors["evaluationDate"]}
                    </p>
                  </div>
                )}
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4"></div>
              <div className="col-lg-3 col-sm-3 col-xs-4"></div>
            </div>

            {isEvaluator ? (
              <>
                <div className="row mt-3">
                  <div className="col-12">
                    <Table striped bordered>
                      <thead>
                        <tr>
                          <th className="text-dark">Competency Category</th>
                          <th className="text-dark">Competency</th>
                          <th className="text-dark">Rating</th>
                        </tr>
                      </thead>
                      <tbody hidden={!isShow}>
                        {mainArr?.length > 0 &&
                          mainArr?.map((eachAttribute, index) => (
                            <tr key={`${index}`}>
                              <td>
                                <InputForm
                                  className="form-control"
                                  placeholder={"Competency Category"}
                                  isDisabled={true}
                                  textArea={false}
                                  value={eachAttribute?.categoryName}
                                  onChange={(val: any) => {}}
                                />
                                <p style={{ color: "red" }}>
                                  {formErrors["competencyCategory"]}
                                </p>
                              </td>
                              <td>
                                <InputForm
                                  className="form-control"
                                  placeholder={"Competency"}
                                  isDisabled={true}
                                  textArea={false}
                                  value={eachAttribute?.competencyName}
                                  onChange={(val: any) => {}}
                                />
                                <p style={{ color: "red" }}>
                                  {formErrors["competency"]}
                                </p>
                              </td>
                              <td>
                                <Rating
                                  name="simple-controlled"
                                  value={eachAttribute?.rating}
                                  disabled={disableForm ? true : !isShow}
                                  onChange={(event, newValue) => {
                                    setMainArr((prevArray: any) => {
                                      return prevArray.map((obj) =>
                                        obj.id === eachAttribute?.id
                                          ? { ...obj, rating: newValue }
                                          : obj
                                      );
                                    });
                                  }}
                                />
                                <p style={{ color: "red" }}>
                                  {formErrors["rating"]}
                                </p>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </>
            ) : (
              <>
                {isFeedbackAvailable ? (
                  <>
                    <div className="row mt-2">
                      <div className="col-lg-12 p-0 mb-3">
                        <DynamicGrid
                          data={mainArr}
                          columns={dashboardGridColumns}
                          options={options}
                        />
                      </div>
                    </div>
                    {evaluatorRemarks.length > 0 && (
                      <div className="col-lg-6 p-0">
                        <Table striped bordered size="sm">
                          <thead>
                            <tr>
                              <th className="text-dark">Evaluator</th>
                              <th className="text-dark">Remarks</th>
                            </tr>
                          </thead>
                          <tbody>
                            {evaluatorRemarks.map((evaluator) => (
                              <tr>
                                <td>{evaluator.eName}</td>
                                <td>
                                  {evaluator.remarks ? evaluator.remarks : "NA"}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    )}
                  </>
                ) : (
                  <h5>No feedback available, check later</h5>
                )}
              </>
            )}
          </div>
          <div className="mb-3 pt-3"></div>
        </Modal.Body>
        <Modal.Footer>
          {isEvaluator && (
            <button
              onClick={submitFunction}
              disabled={disableForm ? disableForm : !isShow}
              className="btn btn-filter-submit float-right ml-2"
            >
              <i className="fa-solid fa-check"></i> Submit
            </button>
          )}

          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              props.setModal(false);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FeedbackForm;
