import React from "react";

function Biometrics() {
  return (
    <div>
      {" "}
      <iframe
        src="/biometrics/MorFinEnrollClientServiceTest.htm"
        title="My HTML Page"
        className="iframeClass"
        style={{ width: "100%", marginTop: "4%", height: "500px" }}></iframe>
    </div>
  );
}

export default Biometrics;
