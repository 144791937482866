const prod = {
  url: "",
};
const dev = {
  url: "",
};

export const { url } = process.env.NODE_ENV === "development" ? dev : prod;

//Progression APIs

export const getMedibuddyIdAvailable = `${url}/api/Progression/GetMedibuddyIdAvailable`;
export const mediBuddyApiCallAfterRevoke = `${url}/api/Progression/MediBuddyApiCallAfterRevoke`;
export const testingForPostman = `${url}/api/Progression/TestingForPostman`;
export const getApproverHistoryData = `${url}/api/Progression/GetApproverHistoryData`;
export const updateRelationAndManagerData = `${url}/api/Progression/UpdateRelationAndManagerData`;
export const approverChangeDataForSeparation = `${url}/api/Progression/ApproverChangeDataForSeparation`;
export const geInitiatorAndRoleIdOnWorkflowTaskId = `${url}/api/Progression/GeInitiatorAndRoleIdOnWorkflowTaskId`;
export const getVacancyStatusAndToBeHiredStatusOfPos = `${url}/api/Progression/GetVacancyStatusAndToBeHiredStatusOfPos`;
export const checkIfRevokableOrNot = `${url}/api/Progression/CheckIfRevokableOrNot`;
export const entryUpdateInActionHistory = `${url}/api/Progression/EntryUpdateInActionHistory`;
export const updatePosIncmMapOnRevoke = `${url}/api/Progression/UpdatePosIncmMapOnRevoke`;
export const anyProgressionRevoke = `${url}/api/Progression/AnyProgressionRevoke`;
export const checkIfEntryInAuditLog = `${url}/api/Progression/CheckIfEntryInAuditLog`;
export const checkIfRevokeIsActive = `${url}/api/Progression/CheckIfRevokeIsActive`;
export const checkIfSeparationIsActive = `${url}/api/Progression/CheckIfSeparationIsActive`;
export const getEssMssEditSections = `${url}/api/Progression/GetEssMssEditSections`;
export const updateEventAndReasonInWorkline = `${url}/api/Progression/UpdateEventAndReasonInWorkline`;
export const getRoleDetailsProgression = `${url}/api/Progression/GetRoleDetailsProgression`;
export const getStatusOfProgression = `${url}/api/Progression/GetStatusOfProgression`;
export const changeIsRevokedStatus = `${url}/api/Progression/ChangeIsRevokedStatus`;
export const changeIsRevokedStatusForEResignation = `${url}/api/Progression/ChangeIsRevokedStatusForEResignation`;
export const updateAttachmentDetailsInTable = `${url}/api/Progression/UpdateAttachmentDetailsInTable`;
export const uploadSupportingDocForProgressionOnSendback = `${url}/api/SharepointOnlineDocumentUpload/ProgressionDocumentUploadForSendback`;
export const deleteSupportingDocForProgression = `${url}/api/SharepointOnlineDocumentUpload/ProgressionDocumentDelete`;
export const uploadSupportingDocForProgression = `${url}/api/SharepointOnlineDocumentUpload/ProgressionDocumentUpload`;
export const downloadSupportingDocForProgression = `${url}/api/SharepointOnlineDocumentUpload/GetProgressionSupportingDocument`;
export const getEmployeeCtNewHireStatus = `${url}/api/Progression/GetEmployeeCtNewHireStatus`;
export const checkForRequestExists = `${url}/api/Progression/CheckForRequestExists`;
export const updateSyncStatusOfRevoke = `${url}/api/Progression/UpdateSyncStatusOfRevoke`;
export const revokeProgressionAfterApproved = `${url}/api/Progression/RevokeProgressionAfterApproved`;
export const getProgressionById = `${url}/api/Progression/GetProgressDetailsById`;
export const getApproverAndProgressionDetailsByWorkflowTaskId = `${url}/api/Progression/GetApproverAndProgressionDetailsByWorkflowTaskId`;
export const handleApprovalForProgressionModel = `${url}/api/Progression/HandleApprovalForProgressionModel`;
export const handleApprovalForPositionModel = `${url}/api/Progression/HandleApprovalForPositionModel`;
export const getProgressionDataBasedOnId = `${url}/api/Progression/GetProgressionDataBasedOnId`;
export const submitProgressionRevoke = `${url}/api/Progression/SubmitProgressionRevoke`;
export const createTaskForApprovers = `${url}/api/Progression/CreateTaskProgressionSendback`;
export const updateProgressionOnSave = `${url}/api/Progression/UpdateProgressionNewJSONBYId`;
export const getApproverAndProgressionDetailsByWorkflowTaskIdV2 = `${url}/api/Progression/GetApproverAndProgressionDetailsByWorkflowTaskIdV2`;
export const getSectionsBasedOnTabId = `${url}/api/Progression/GetSectionsBasedOnTabId`;
export const getAllRoles = `${url}/api/Progression/GetAllRoles`;
export const getRelationsForEmployee = `${url}/api/Progression/GetEmployeeRelationship`;
export const getDependentDataOfPos = `${url}/api/Progression/GetDependentDataOfPos`;
export const getReporteesForEmployee = `${url}/api/Progression/GetReporteesForEmployee`;
export const getRepMgMapForEmployee = `${url}/api/Progression/GetRepMgMapForWrkflIns`;
export const getEmpRlMapForEmployee = `${url}/api/Progression/GetEmpRlMapForProgression`;
export const getEmpRlBasedOnId = `${url}/api/Progression/GetEmpRlDetailsOnId`;
export const updateRepMgMapNewJson = `${url}/api/Progression/UpdateRpMgMapNewJSON`;
export const updateEmpRlMapNewJson = `${url}/api/Progression/UpdateEmpRlMapNewJSON`;
export const getUsermasterIdBasedOnEid = `${url}/api/Progression/GetUserMasterIdBasedOnEid`;
export const getDdForProgression = `${url}/api/Progression/GetDropdownForProgression`;
export const getCommonDdForProgression = `${url}/api/Progression/GetCommonDropdownForProgression`;
export const getSearchDdForProgression = `${url}/api/Progression/GetSearchDropDownForProgression`;
export const getSearchDdForProgressionCLM = `${url}/api/Progression/GetSearchDropDownForProgressionCLM`;
export const getEmpForRevoke = `${url}/api/Progression/EmpSearchForRevoke`;
export const getWorkflowData = `${url}/api/Progression/GetWorkflowDetails`;
export const getOptForApproval = `${url}/api/Progression/GetAppOptForOtherRoleType`;
export const getAttrForProgression = `${url}/api/Progression/GetAttributeForProgression`;
export const postDataForProgression = `${url}/api/Progression/InsertIntoProgression`;

//MRF StagesandResponsibility
export const getSectionDetails = url + "/api/Interface/GetSectionDetails";
export const getSectionDetailsV2 = url + "/api/Interface/GetSectionDetailsV2";
export const getDropdowns = `${url}/api/Commondropdowns/GetDropDownValueDetails`;
export const GetEmployeeBasedOnIdorCode = `${url}/api/Commondropdowns/GetEmployeeBasedOnIdorCode`;
export const getDropdownsOUFilters = `${url}/api/Commondropdowns/GetDropDownValueDetailsForOUFilters`;
export const getSearchableDropdowns = `${url}/api/Commondropdowns/GetSearchDropDownValueDetails`;
export const getSearchableDdMrf = `${url}/api/Commondropdowns/GetSearchDropDownValueMRF`;
export const getParenPBasedonIncm = `${url}/api/Commondropdowns/GetParenPosBasedOnIncumbent`;
export const getJobRelationDropDown = `${url}/api/CommonDropdowns/GetDropdownForEmployeeJobRelationship`;
export const GetPositionDetailsByEmployeeId = `${url}/api/CommonDropdowns/GetPositionDetailsByEmployeeId`;
export const CheckMandatoryFieldsAreFilledInPosition = `${url}/api/MRF/CheckMandatoryFieldsAreFilledInPosition`;
export const getAccountValidation =
  url + "api/AttributesValues/GetAadharBankAccountValidation";
export const InsertUpdateTaskChecklistData =
  url + "api/TaskChecklist/InsertUpdateTaskChecklistData";

export const GetTaskChecklistData =
  url + "api/TaskChecklist/gettaskchecklistdetails";

export const CheckIfTaskExist = url + "api/TaskChecklist/CheckIfTaskExist";

export const GetTaskChecklistCountryData =
  url + "api/TaskChecklist/GetTaskChecklistCountryData";

export const GetTaskChecklistLEData =
  url + "api/TaskChecklist/GetTaskChecklistLEData";

export const GetTaskChecklistPLOCData =
  url + "api/TaskChecklist/GetTaskChecklistPLOCData";

export const GetNationalIDDuplicateValidation =
  url + "api/AttributesValues/GetNationalIDDuplicateValidation";

export const GetNationalIDBlacklistValidation =
  url + "api/AttributesValues/GetNationalIDBlacklistValidation";

export const getTabsData = url + "/api/Tabs/GetTabsDetails";
export const documentUpload =
  url + "/api/SharepointOnlineDocumentUpload/SharepointDocumentUpload";
export const UploadProfile =
  url + "/api/SharepointOnlineDocumentUpload/ProfilePicUploadToSharepoint";
export const getProfilePicImg =
  url + "/api/SharepointOnlineDocumentUpload/GetProfilePicture";
export const getProfilePicForOnboarding =
  url + "/api/SharepointOnlineDocumentUpload/GetProfilePictureOnBoarding";

export const getDocuments =
  url + "/api/SharepointOnlineDocumentUpload/GetDocuments";

export const viewDocument =
  url + "/api/SharepointOnlineDocumentUpload/GetDocumentView";
export const deleteDocument =
  url + "/api/SharepointOnlineDocumentUpload/DeleteSharepointDocument";

export const GetConfigMasterData =
  url + "/api/SharepointOnlineDocumentUpload/GetConfigMasterData";
export const downloadDocument =
  url + "/api/SharepointOnlineDocumentUpload/DownloadDocument";
export const getAttributeValueDetails =
  url + "api/AttributesValues/GetAttributeValueDetails";
export const getAttributeValueDetailsV2 =
  url + "api/AttributesValues/GetAttributeValueDetailsV2";
export const getPositionDetailsForMRF =
  url + "api/MRScreeningQuestion/GetAttributeValuesForMrf";

export const getEmployeesMappedToOuData = url + "api/OrganizationUnit/GetEmployeesMappedToOuData"
export const getOUProgressionDashboard = 
url + "api/OrganizationUnit/GetOUProgressionDashboard"

export const UpdateOuProgressionIsActive = 
url + "api/OrganizationUnit/UpdateOuProgressIsActive"

export const GetOUProgressionMappingData =
url + "api/OrganizationUnit/GetOUProgressionMappingData"
export const GetMappingDataForSingleLevelOu = 
url + "api/OrganizationUnit/GetMappingDataForSingleLevelOu"

export const getOUAuditLogs =
  url + "api/OrganizationUnit/GetAuditLogsForOU";

export const getOUMappingAuditLogs =
  url + "api/OrganizationUnit/GetAuditLogsForOUMapping";
  
export const getOnelevelOUMappingDetails =
  url + "api/OrganizationUnit/GetOneLevelOUMappingDetails";

  export const getOUMappingProgressionData =
  url + "api/OrganizationUnit/GetOuMappingProgressionData";

export const getOUMappingDetails =
  url + "api/OrganizationUnit/GetOUMappingDetails";

export const InsertUpdateOUMappings =
  url + "api/OrganizationUnit/InsertUpdateOUMappings";

export const getOuProgressionData =
  url + "api/OrganizationUnit/GetOuProgressionData";
export const getOuMappingProgressionData =
  url + "api/OrganizationUnit/GetOuMappingProgressionData";

export const deleteOuProgressionData =
  url + "api/OrganizationUnit/DeleteOuProgressionData";
export const deleteOuMappingProgressionData =
  url + "api/OrganizationUnit/DeleteOuMappingProgressionData";

export const getGlobalValues = url + "api/AttributesValues/GetGlobalValues";
export const getTabHeaderDetails =
  url + "/api/AttributesValues/GetTabHeaderDetails";
export const getOUCodes = url + "api/OrganizationUnit/CheckOUCodeExist";
export const getOUDetails = url + "api/OrganizationUnit/GetOUDetails";
export const getOUMappingProgressionDetails = url + "api/OrganizationUnit/GetMultiLevelProgressionOUDetails";

export const getOUCombination = url + "api/OrganizationUnit/GetOUCombination";

export const getMultiLevelProgressionOUDetails = url + "api/OrganizationUnit/GetOUDetails";

export const postAttributeValueDetails =
  url + "/api/Attribute/PostAttributeDetails";
export const postAttributeValueDetailsV2 =
  url + "/api/Attribute/PostAttributeDetailsV2";
export const postAttributeForExcel =
  url + "/api/Attribute/PostAttributeForExcel";
export const postMRScreeningQuestion =
  url + "/api/MRScreeningQuestion/PostScreeningData";

export const getMRScreeningQuestion =
  url + "/api/MRScreeningQuestion/GetScreeningData";

export const deleteManpowerRequisitionScreeningQuestion =
  url + "/api/MRScreeningQuestion/DeleteManpowerRequisitionScreeningQuestion";

//Dashboard Display Data
export const getDashboardGridData =
  url + "/api/Dashboard/GetEmployeeDashboardData";
export const getDashboardContractLabourDashboardGridData =
  url + "/api/Dashboard/GetEmployeeDashboardDataCLM";

export const getEmployeeHistoryData =
  url + "/api/Dashboard/GetEmployeeHistoryData";
export const updateEmployeeStatus = url + "/api/Dashboard/UpdateEmployeeStatus";

export const InsertUpdateOrganizationUnit =
  url + "/api/OrganizationUnit/InsertUpdateOrganizationUnitData";

export const getCommonDashboardMyRequestsGridData =
  url + "/api/Dashboard/GetCommonDashBoardUserRequests";

export const getUserActionsLinks = url + "/api/Dashboard/GetUserActionLinks";

export const getPositionDashboardData =
  url + "/api/Dashboard/GetPositionDashboardData";

export const getJobDashboardData = url + "/api/Dashboard/GetJobDashboardData";

export const getAgencyDashboardData =
  url + "/api/Dashboard/GetAgencyDashboardData";

export const getMrfDashboardData = url + "/api/Dashboard/GetMrfDashboardData";

export const getAgencyCandidateDashboardData =
  url + "/api/Dashboard/GetAgencyCandidateDashboardData";

export const getOnBoardingDashboardData =
  url + "/api/Dashboard/GetOnBoardingDashboardData";

//Recruiter Grid Data

export const getRecruiterDashboardData =
  url + "/api/Dashboard/GetRecruiterDashboardData";

export const getCandidateDashboardDataForApprove =
  url + "/api/Dashboard/GetCandidateDashboardDataForApprove";

export const getInterviewQuestionDashboardData =
  url + "/api/Dashboard/GetInterviewQuestionDashboardData";

//Anonymous Candidate APIs
export const getAnonymousCandidateDashboardData =
  url + "/api/Dashboard/GetAnonymousCandidateDashboardData";
export const getEmployeedetailsBasedOnCandidateId =
  url + "/api/OnBoarding/GetEmployeedetailsBasedOnCandidateId";

//DocumentsGridData
export const getDocumentGridData = url + "/api/Dashboard/GetDocumentsGridData";
export const getOrganizationUnitGridData =
  url + "/api/Dashboard/GetOrganizationUnitGridData";
export const GetModuleDropdown = `${url}/api/CommonDropdowns/GetModuleDropdown`;

//CandidateStatusGrid
export const getStatusGridData = url + "/api/Dashboard/GetStatusCandidateGrid";
//Parameter
export const getDropdownsData = `${url}/api/Parameter/getFilterDropdownsforParameter`;
export const getParameterDashboardData = `${url}/api/Parameter/getParameterDashboardData`;
export const getParameterExportToExcel = `${url}/api/Parameter/getParameterExportToExcel`;

export const insertUpdateParametertDetails = `${url}/api/Parameter/InsertupdateParameterData`;
export const getParameterDetailsById = `${url}/api/Parameter/getParameterDetailsById`;

//Project
export const getGenericDropdownsData = `${url}/api/Project/getGenericDropdowns`;
export const getProjectDashboardData = `${url}/api/Project/getProjectDashboardData`;
export const getProjectGridDataExport = `${url}/api/Project/getProjectExportToExcel`;
export const InsertUpdateProjectData = `${url}/api/Project/InsertupdateProjectData`;
export const getProjectDetailsById = `${url}/api/Project/getProjectDetailsById`;
export const getProjectUsers = `${url}/api/Project/getUserListByRole`;
export const deleteProjectUserMapping = `${url}/api/Project/deleteProjectUserMapping`;
export const insertProjectUserMapping = `${url}/api/Project/insertProjectUserMapping`;
export const getProjectUserMapedById = `${url}/api/Project/getProjectUserMappingDetailsById`;
export const getADUsers = `${url}/api/Project/GetAzureADUser`;
export const InsertUserInMaster = `${url}/api/Project/insertIntoUserMaster`;
export const CheckUserExist = `${url}/api/Project/CheckIfUserExistsInMaster`;
export const CheckUserRoleExist = `${url}/api/Project/CheckIfUserRoleExistsInMaster`;
export const InsertUserRoleInMaster = `${url}/api/Project/insertIntoUserRoleMaster`;
export const GetProjectJobDetailsDataById = `${url}/api/Project/GetProjectJobDetailsDataById`;

//Proxy user

export const getProxyUserDropdownData =
  url + "/api/User/GetProxyUserDropdownData";

export const getUserRoleMappingDetails =
  url + "/api/User/GetUserRoleMappingDetails";

//Dashboard
export const getDashboardGridDataExport =
  url + "/api/Dashboard/DashboardExportToExcel";
export const getAllGenericMasterData =
  url + "/api/Generic/GetAllGenericMasterData";
export const getAllStatusMasterData =
  url + "/api/Status/GetAllStatusMasterData";
export const getRequestDetailsById = url + "/api/Request/GetRequestDetailsById";
export const InsertUpdateRequestDetails =
  url + "/api/Request/InsertUpdateRequestDetails";
export const getViewDetailsData = url + "/api/ViewDetails/GetViewDetails";
export const getControlsData = url + "/api/Controls/GetControls";
export const getTenantFramework =
  url + "/api/TenantFramework/GetTenantFramework";
export const getInvoiceGridData = url + "/api/Invoice/getInvoiceData";
export const getTenantDetails = url + "/api/Common/GetTenantDetails";
export const uploadInvoiceDocument = url + "/api/Invoice/uploadInvoiceDocument";
export const tenant1Localhost = "https://localhost:5003";
export const tenant2Localhost = "https://localhost:5002";
export const getFilterData = url + "/api/FilterData/GetFilterData";
export const getSidebarData = url + "/api/Module/GetModuleData";
export const getUserDetails = url + "/api/User/getUserData";

export const getRoleDetailsByUserId = url + "/api/User/getRoleDetailsByUserId";
export const GetTaskChecklistDashboardData =
  url + "/api/Dashboard/GetTaskChecklistDashboardData";

export const CancelRequests = url + "/api/Dashboard/CancelRequests";
export const ReRouteRequests = url + "/api/Dashboard/ReRouteRequests";
export const GetAimedRequestsCount =
  url + "/api/Dashboard/GetAimedRequestsCount";

export const getPermissionRecordRoleMappingByRoleId =
  url + "/api/User/GetPermissionRecordRoleMappingByRoleId";
export const getSectionPermissionRecordRoleMappingByRoleId =
  url + "/api/User/GetSectionsPermissionRecordRoleMappingByRoleId";

export const updateUserMasterByUserIdandRoleId =
  url + "/api/User/UpdateUserMasterByUserIdandRoleId";

export const invoiceSubmit = url + "/api/Invoice/InsertUpdateInvoiceData";

export const getInvoiceDataById = url + "/api/Invoice/getInvoiceDataById";

export const getInvoiceDocTypes = url + "/api/Invoice/getInvoiceDocTypes";

export const getTabDetails = url + "/api/Tabs/GetTabs";

export const getRolesDetails = url + "/api/Roles/GetRoles";

export const getEntityFilter = url + "/api/Roles/GetEntity";

export const getVendorFilter = url + "/api/Roles/GetVendor";
export const getQueryGridData = url + "/api/Query/GetQueryGridData";
export const getUserList = url + "/api/User/getUserList";
export const getInvoiceHistoryById = url + "/api/Invoice/GetInvoiceHistoryById";
export const getQueryDataById = url + "/api/Query/GetQueryDataById";
export const getClosedQueryById = url + "/api/Query/GetClosedQueryById";
export const insertUpdateQuery = url + "/api/Query/InsertUpdateQuery";
export const getQueryDocumentById = url + "/api/Query/GetQueryDocumentById";
export const getDataFromInvoiceExtraction =
  url + "/api/Invoice/ExtractInvoiceData";
export const trainInvoice = url + "/api/Invoice/TrainInvoiceData";
export const getPoGridData = url + "/api/PurchaseOrder/getPurchaseOrderData";

export const getPoFormData = url + "/api/PurchaseOrder/getPoFormData";

export const loginUrl = url + "/api/Login/Authenticate";
export const OTPUrl = url + "/api/Login/OTPCheck";
export const logoutUserById = url + "/api/Login/LogoutUserById";
export const GetRolesURL = url + `/api/Login/GetRoles`;
export const AddLoginLogoutHistory =
  url + `/api/Login/AddProxyLoginLogoutHistory`;
export const UpdateLogoutHistoryForProxy =
  url + `/api/Login/UpdateLogoutHistoryForProxy`;

export const GetInvoiceTypeDashboardData =
  url + "/api/InvoiceType/GetDashboardData";

export const GetInvoiceTypeOptions =
  url + "/api/InvoiceType/GetInvoiceTypeOptions";

export const InvoiceTypExportToExcel =
  url + "/api/InvoiceType/GetExportToExcel";

export const GetAdminsOptions = url + "/api/InvoiceType/GetAdminsOptions";

export const InsertUpdateGenericOUMapping =
  url + "/api/InvoiceType/InsertUpdateGenericMasterWithOUMapping";

export const GetOUMappingDetailsById =
  url + "/api/InvoiceType/GetOUMappingDetailsById";

export const GetConfigValueByConfigKey =
  url + "/api/InvoiceType/GetConfigValueByConfigKey";

// structure type
export const GetStructureTypeGridData = url + "/api/Structure/GetGridData";
export const GetStructureTypeGridDataExport =
  url + "/api/Structure/GetGridDataExport";
export const InsertUpdateStructureType =
  url + "/api/Structure/InsertUpdateStructureData";
export const InsertUpdateStructureDetailsData =
  url + "/api/Structure/InsertUpdateStructureDetailsData";
export const GetStructureDataById = url + "/api/Structure/GetStructureDataById";
export const GetStructureDetailsDataById =
  url + "/api/Structure/GetStructureDetailsDataById";

// structure type

export const CommonDropdownData = url + "/api/CommonDropdown/GetDropdownData";
export const getTAInputData = url + "/api/CommonDropdown/GetTAInputData";
export const GetHRManagers = url + "/api/MRF/GetHRManagers";
//user Master

export const getRoleDropdowndata = `${url}/api/User/getRoleDropdownData`;
export const InsertupdateUserData = `${url}/api/User/InsertupdateUserData`;
export const getUserDashboardData = `${url}/api/User/getUserDashboardData`;
export const getUserExportToExcel = `${url}/api/User/getUserExportToExcel`;
export const getUserDetailsById = `${url}/api/User/getUserDetailsById`;
export const getUserContextBySSOURL = `${url}/api/Login/getUserContextBySSO`;
export const getCandidateGridDetails = `${url}api/Dashboard/GetCandidateDashboardData`;

export const candidateSendActivation =
  url + "/api/Attribute/CandidateSendActivation";

//Candidate Declartion
export const getGetDeclartionQuestionForAgency = `${url}/api/MRScreeningQuestion/GetDeclartionQuestionsForAgencyCandidate`;
export const insertIntoCandidateDeclartion = `${url}/api/MRScreeningQuestion/InsertIntoCandidateDeclartionAnswerMapping`;
export const checkCandidateMRFQuestionMappingEndpoint = `${url}/api/MRScreeningQuestion/CheckCandidateMRFQuestionMapping`;
export const insertUpdateMRFApproverMapping = `${url}/api/MRF/InsertUpdateMRFApproverMapping`;
export const candidateSubmitapplication = `${url}/api/MRScreeningQuestion/CandidateSubmitApplication`;
//MRF StagesandResponsibility
//export const insertIntoStagesandResponsibility = `${url}/api/MRF/InsertIntoStagesandResponsibility`;
export const getApproverDetailsForProccessByMRFId = `${url}/api/MRF/GetApproverDetailsForProccessByMRFId`;
export const getRecruiterDropdown = `${url}/api/MRF/GetRecruiterDropdown`;
export const getOrganizationUnitsDataByType = `${url}/api/MRF/GetOrganizationUnitsDataByType`;

export const insertIntoMRFApprovalDetails = `${url}/api/MRF/InsertIntoMRFApprovalDetails`;
export const IntiateApprovalWorkflow = `${url}/api/MRF/IntiateApprovalWorkflow`;
export const getRecruiterAgencyData = `${url}/api/MRF/GetRecruiterAgencyData`;
export const getPostingOptions = `${url}/api/MRF/GetPostingOptions`;
export const insertIntoMRFAgencyMapping = `${url}/api/MRF/InsertIntoMRFAgencyMapping`;
export const getMRFAgencyMappingByMRFId = `${url}/api/MRF/GetMRFAgencyMappingByMRFId`;
export const GetParentPositionForMrfWorkflow = `${url}/api/MRF/GetParentPositionForMrfWorkflow`;
export const insertIntoStagesandResponsibility = `${url}/api/MRF/InsertIntoStagesandResponsibility`;

export const insertIntoEvaluatorFeedback = `${url}/api/MRF/InsertIntoEvaluatorFeedback`;

export const getCompetencyCategoryData = `${url}/api/MRF/GetCompetencyCategoryData`;

export const getDecisionMasterDDByStageandMrfId = `${url}/api/MRF/GetDecisionMasterDDByStageandMrfId`;

export const getTALeadApproversForWorkflow = `${url}/api/MRF/GetTALeadApproversForWorkflow`;

export const getEvaluatorFeedbackByMrfId = `${url}/api/MRF/GetEvaluatorFeedbackByMrfId`;

export const getStaticStagesandResponsibilityData = `${url}/api/MRF/GetStaticStagesandResponsibilityData`;
export const getStagesResponsiblityByMRFId = `${url}/api/MRF/GetStagesResponsiblityByMRFId`;

export const getMRFApproverByMRFId = `${url}/api/MRF/GetMRFApproversByMRFId`;
export const CandidatWhenAcceptOrRejectOfferAcceptance = `${url}/api/MRF/CandidatWhenAcceptOrRejectOfferAcceptance`;

export const getAllModuleOrgUnitMaster = `${url}/api/Tabs/GetModuleDetails`;
export const getAllTabsOrgUnitMaster = `${url}/api/Tabs/GetAllTabsDetails`;
export const getSecationsAsPerTabId = `${url}/api/Tabs/GetSectionDetails`;
export const getAttributesAsPerSections = `${url}/api/Tabs/GetAttributeNameForExcel`;

export const getStagesData = `${url}/api/MRF/GetStagesData`;
export const getCandidateWorkflowInstanceByCandidateId = `${url}/api/MRF/GetCandidateWorkflowInstanceByCandidateId`;

export const getCandidateDetailsById = `${url}/api/MRF/GetCandidateDetailsById`;

export const getManpowerRequisitionDetailsById = `${url}/api/MRF/GetManpowerRequisitionDetailsById`;

export const getInternalJobPostingDataById = `${url}/api/MRF/GetInternalJobPostingDataById`;

export const insertintoCandidateDetailsData = `${url}/api/MRF/InsertintoCandidateDetailsData`;

export const updateCandidateWorkflowTasksAndInstance = `${url}/api/MRF/UpdateCandidateWorkflowTasksAndInstance`;
export const postDataBulkInsert = `${url}/api/SharepointOnlineDocumentUpload/DataBulkInsert`;
export const postOUBulkInsert = `${url}/api/SharepointOnlineDocumentUpload/OUBulkInsert`;

export const getAuditLogsV2 = `${url}/api/Audit/GetAuditLogsV2`;
export const getAuditLogsV2ForEC = `${url}/api/Audit/GetAuditLogsV2ForEC`;

export const getUsersForSearchableDropdown = `${url}/api/CommonDropdowns/GetUsersForSearchableDropdown`;
//export const getAllMasterTypeId = `${url}/api/OrganizationUnit/GetMasters`;
export const getMRFJob = `${url}/api/MRF/GerMRFJob`;

export const CheckIfMRFIsAlreadyInProgressForPosition = `${url}/api/MRF/CheckIfMRFIsAlreadyInProgressForPosition`;

export const getEvaluatorFeedbackForCurrentStage = `${url}/api/MRF/GetEvaluatorFeedbackForCurrentStage`;

//Report Master
export const getReportNames = `${url}/api/ReportMaster/GetReportNames`;
export const getReportExcelData = `${url}/api/ReportMaster/GetReportData`;

//JWT Generation
export const getJWTGenerated = `${url}/api/JWTGeneration/GetJWTToken`;
//New hire
export const checkHiringLimit = `${url}/api/NewHire/CheckHiringLimit`;

export const checkIfApproversArePresentNewHire = `${url}/api/NewHire/CheckIfApproversArePresentNewHire`;

export const updateEntryInActionHistoryForNewHire = `${url}/api/NewHire/UpdateEntryInActionHistoryForNewHire`;

export const getEmployeeDetailsById = `${url}/api/NewHire/GetEmployeeDetailsById`;

export const enterRecordInActionHistory = `${url}/api/NewHire/EnterRecordInActionHistory`;

export const updateEmployeeDetailsData = `${url}/api/NewHire/UpdateEmployeeDetailsData`;

export const GetEmployeeDetailsForBlueCollarDocGeneration = `${url}/api/NewHire/GetEmployeeDetailsForBlueCollarDocGeneration`;

export const GetEmployeeDetailsForCLMDocGeneration = `${url}/api/NewHire/GetEmployeeDetailsForCLMDocGeneration`;

export const GetEmployeeClassBasedOnTid = `${url}/api/NewHire/GetEmployeeClassBasedOnTid`;

export const newHireSendBackApprovalFlow = `${url}/api/NewHire/NewHireSendBackApprovalFlow`;

export const getWorkflowTypeData = `${url}/api/NewHire/GetWorkflowTypeData`;

export const getWorkflowSubTypeDataByWorkflowTypeId = `${url}/api/NewHire/GetWorkflowSubTypeDataByWorkflowTypeId`;

export const getEventAndEventResonDataByTId = `${url}/api/NewHire/GetEventAndEventResonDataByTId`;

export const getApproverDetailsForHireByWorkflowTaskId = `${url}/api/NewHire/GetApproverForHireByWorkflowTaskId`;

export const postApprovalForHire = `${url}/api/NewHire/PostApprovalForHire`;

export const getEmployeeDatForProfile = `${url}/api/NewHire/GetEmployeeDatForProfile`;

export const GetEmployeeDatForMyProfile = `${url}/api/NewHire/GetEmployeeDatForMyProfile`;

export const getWorkflowTasksDataById = `${url}/api/NewHire/GetWorkflowTasksDataById`;

export const GetCompanyCode = url + "api/AttributesValues/GetCompanyCode";

export const GetLOPHourlyRate = url + "api/AttributesValues/GetLOPHourlyRate";

//Permission Master Pages
export const getAllModuleUserRole = `${url}/api/Master/GetModuleDetails`;
export const getAllRoleMaster = `${url}/api/Master/GetRoleDetails`;
export const getAllUserMaster = `${url}/api/Master/GetUserDetails`;
export const insertUserRoleDetails = `${url}/api/Master/InsertUserRoleDetails`;
export const interfaceDetails = `${url}/api/Master/GetInterfaceDetails`;
export const getAllTabsBasedOnInterface = `${url}/api/Master/GetTabsDetails`;
export const getAllSectionBasedOnTab = `${url}/api/Master/GetSectionPermissionDetailsById`;
export const MRFDraftRequest = `${url}/api/MRF/MRFDraftRequest`;
export const CancelMRFWorkflow = `${url}/api/MRF/CancelMRFWorkflow`;
export const GetPositionExcelData = `${url}/api/Dashboard/GetPositionExcelData`;
export const insertUpdatePermisiion = `${url}/api/Master/InsertUpdatePermissions`;
export const getEditUserRoleId = `${url}/api/Master/EditUserRole`;
export const getPermissionDashboardDetails = `${url}/api/Master/GetUserPermissionDashboardDetails`;
export const getUserPermissoinExcelDetails = `${url}/api/Master/GetUserPermissionExcelDetails`;
export const insertRoleDetails = `${url}/api/Master/InsertRoleDetails`;
export const getAllDocumentMaster = `${url}/api/Master/GetDocumentNamesWithCode`;
//UserRoleDashboard
export const UserRolePermissionDashboard = `${url}/api/Master/GetUserRoleDashboardDetails`;
export const getUserPermissionExcelData = `${url}/api/Master/GetExportToExcelUserRoleData`;
export const getUserPermissionExcelDetailsForAllUsers = `${url}/api/Master/GetUserPermissionExcelDetailsForAllUsers`;
export const deleteUserRoleMapping = `${url}/api/Master/DeleteUserRoleMapping`;
//Workflow Dashboard
export const getWorkFlowDashboardDetails = `${url}/api/WorkFlowMaster/GetWorkFlowDashboardDetails`;

//Workflow Form
export const getWorkFlowTypes = `${url}/api/WorkFlowMaster/GetWorkFlowTypes`;
export const getWorkflowTypeReason = `${url}/api/WorkFlowMaster/GetWorkflowTypeReasonList`;
export const getModulesList = `${url}/api/WorkFlowMaster/GetModulesList`;
export const getRolesList = `${url}/api/WorkFlowMaster/GetRolesList`;
export const insertWorkFlowRecord = `${url}/api/WorkFlowMaster/InsertWorkFlowRecord`;
export const GetWorkflowDetailsById = `${url}/api/WorkFlowMaster/GetWorkflowDetailsById`;
export const getStepsForWorkFlowList = `${url}/api/WorkFlowMaster/GetStepsForWorkFlowList`;
export const insertWorkflowStepsDetails = `${url}/api/WorkFlowMaster/InsertWorkFlowSetpsRecord`;
export const getWorkFlowExcelList = `${url}/api/WorkFlowMaster/GetWorkFlowExcelList`;
export const insertWFEmailDetails = `${url}/api/WorkFlowMaster/InsertWorkFlowNotifications`;
export const getWorkFlowEmailList = `${url}/api/WorkFlowMaster/GetWorkFlowNotificationDetailsByStepId`;
export const getEmailTempeleteList = `${url}/api/WorkFlowMaster/GetemailTempeleteList`;

//Email template Dashboard
export const getEmailTEmpleteDashboardDetails = `${url}/api/EmailTemplete/GetEmailTEmpleteDashboardDetails`;
export const getEmailTEmpleteExcelDetails = `${url}/api/EmailTemplete/GetEmailTEmpleteExcelDetails`;
export const getEmailTempleteDetailsById = `${url}/api/EmailTemplete/GetEmailTempleteDetailsById`;
//Email Template Form
export const insetEmailTemplate = `${url}/api/EmailTemplete/InsertUpdateEmailTemplate`;

//doc
export const uploadSectionDocForProgression = `${url}/api/SharepointOnlineDocumentUpload/ProgressionSectionDocumentsUpload`;
export const updateSectionDocInProgression = `${url}/api/Progression/UpdateSectionDocumentInProgression`;
export const downloadSectionDocForProgression = `${url}/api/SharepointOnlineDocumentUpload/GetSectionDocumentForProgression`;

//progression section validation server side
export const validateSections = `${url}/api/Progression/SectionValidations`;
export const getLOPForLRProgression = `${url}/api/Progression/GetLOPForLRProgression`;
export const candidateWhenSubmitOnlineApplication = `${url}/api/MRF/CandidateWhenSubmitOnlineApplication`;
export const SubmitForStagesAndResponsiblity = `${url}/api/MRF/SubmitForStagesAndResponsiblity`;

//Onboarding
export const getRequestIdFromCandidateTaskId = `${url}api/OnBoarding/GetRequestIdFromCandidateTaskId`;
export const checkMandatorySections = `${url}/api/OnBoarding/CheckMandatorySections`;
export const updateCandidateTasks = `${url}/api/OnBoarding/UpdateCandidateTasks`;
export const updateWorkflowTasksById = `${url}/api/OnBoarding/UpdateWorkflowTaksStatusOnWorkflowTaskId`;
export const GetDocUploadTasks = `${url}/api/OnBoarding/GetDocUploadTasks`;
export const UpdateGeneratedDocId = `${url}/api/OnBoarding/UpdateGeneratedDocId`;
export const GetEmployeeDetailsForDocGeneration = `${url}/api/OnBoarding/GetEmployeeDetailsForDocGeneration`;

export const getOnboardingCheckListDetails = `${url}/api/OnBoarding/GetOnboardingCheckListDetails`;
export const getOnBoardingPermission = `${url}/api/OnBoarding/GetOnBoardingPermission`;
export const getCandidateOnboardingCheckListDetails = `${url}/api/CandidateOnBoarding/GetCandidateOnboardingCheckListDetails`;
export const createTaskChecklistWorkFlow = `${url}/api/OnBoarding/createTaskChecklistWorkFlow`;
export const getOnBoardingApprovalResponsiblePerson = `${url}/api/OnBoarding/GetOnBoardingApprovalResponsiblePerson`;
export const getCandidateOnBoardingApprovalResponsiblePerson = `${url}/api/CandidateOnBoarding/GetCandidateOnBoardingApprovalResponsiblePerson`;
export const UpdateOnBoardingWorkFlowStatus = `${url}/api/OnBoarding/updateWorkFlowTaskStatus`;
export const UpdateCandidateOnBoardingWorkFlowStatus = `${url}/api/CandidateOnBoarding/updateCandidateWorkFlowTaskStatus`;
//Generic Master
export const getGenericDashboard = `${url}/api/GenericMaster/GetGenericMasterDashboardData`;
export const getMasterTypeDD = `${url}/api/GenericMaster/GetMasterTypeDropDownValueDetails`;
export const getGenericParentDDd = `${url}/api/GenericMaster/GetParentDropDownValueDetails`;
export const SaveGenericMasterData = `${url}/api/GenericMaster/InsertUpdateIntoGenericMaster`;
export const GetGenericMasterData = `${url}/api/GenericMaster/GetGenericMasterData`;
export const PostForParentMasterMap = `${url}/api/GenericMaster/InsertUpdateIntoPicklistAndMasterProg`;
export const checkGenericMasterExists = `${url}/api/GenericMaster/CheckGenericMasterExists`;
export const insertGenericMasterProgData = `${url}/api/GenericMaster/InsertGenericMasterProgData`;
export const getPicklistProgressionData = `${url}/api/GenericMaster/GetPicklistProgressionData`;
export const deletePicklistrogressionData = `${url}/api/GenericMaster/DeletePicklistrogressionData`;
export const getAuditLogsForPicklist = `${url}/api/GenericMaster/GetAuditLogsForPicklist`;
export const getSectionDetailsForPicklist = `${url}/api/GenericMaster/GetSectionDetailsForPicklist`;
export const postPicklistBulkInsert = `${url}/api/SharepointOnlineDocumentUpload/PostPicklistBulkInsert`;
// Organization chart
export const getUserHirerarchEmployee = `${url}/api/OrgHierarchy/GetUserHierarchyEmployeeByEmloyeeId`;
export const getUserHirerarchManagerEmployee = `${url}/api/OrgHierarchy/GetUserHierarchyManagerByEmloyeeId`;
export const getUserHirerarchEmployeePosition = `${url}/api/OrgHierarchy/GetUserHierarchyEmployeeByPosition`;
export const getUserHirerarchManagerPosition = `${url}/api/OrgHierarchy/GetUserHierarchyManagerByPosition`;
export const getUserHirerarchReportEmployeePosition = `${url}/api/OrgHierarchy/GetUserHierarchyReporteesOfEmployeeByPosition`;
export const getReportEmployeePosition = `${url}/api/OrgHierarchy/GetReporteesOfEmployeeByPosition`;
export const getUserHierarchyEmployeeChildrens = `${url}/api/OrgHierarchy/GetUserHierarchyChildrensOfEmployeeByEmloyeeId`;
export const getUserEmployeeSearchableData = `${url}/api/OrgHierarchy/GetEmployeeSearchableData`;
export const getUserPositionEmployeeDetails = `${url}/api/OrgHierarchy/GetEmployeePositionData`;
export const getUserPositionManagerDetails = `${url}/api/OrgHierarchy/GetMangersPositionData`;
export const getUserPositionReporteeDetails = `${url}/api/OrgHierarchy/GetReporteePoistionData`;

//audit
export const InsertAuditLog = `${url}/api/Audit/InsertIntoAuditLogsV2`;
//newHire

export const UpdateEventIntoNewHire = `${url}/api/NewHire/UpdateNewHireEventDetails`;

export const GetCandidateDetailsByUserId = `${url}/api/MRF/GetCandidateDetailsByUserId`;
export const CheckScreeningQuestionsById = `${url}/api/MRF/CheckScreeningQuestionsById`;

//session
export const CheckSessionTimer = `${url}/api/Login/CheckSessionTime`;

export const checkIfStagesCompleted = `${url}/api/MRF/CheckIfStagesCompleted`;

export const updateNegoDetailsById = `${url}/api/MRF/UpdateCandidateWorkflowTasksByid`;
export const updateFileDetailsById = `${url}/api/MRF/UpdateCandidateWorkflowTasksForFile`;
export const getRecruiterRemarkById = `${url}/api/MRF/GetRecruiterRemarkById`;
export const checkCandidateDeclartionFormIsSubmitted = `${url}/api/MRF/CheckCandidateDeclartionFormIsSubmitted`;
export const getCurrentWFStage = `${url}/api/MRF/GetCurrentWFStage`;
export const candidateAadharValidation = `${url}/api/MRF/CandidateAadharValidation`;
export const CheckAdharCardAndEmailIdAlreadyExistsForMRF = `${url}/api/MRF/CheckAdharCardAndEmailIdAlreadyExistsForMRF`;
export const insertUpdatePermissions = `${url}/api/Delegation/InsertUpdateDelegationDetails`;
export const delegationRequests = `${url}/api/Delegation/GetDelegateDashboardDetails`;
export const delegationSearchableDropdown = `${url}/api/Delegation/GetSearchDropDownForDelegation`;
export const udpateCandidateExistingResume = `${url}/api/MRF/UpdateCandidateExistingResume`;
export const getIJPCandidateDetails = `${url}/api/MRF/GetIJPCandidateDetails`;
export const GetJobIdBasedOnMRFId = `${url}/api/MRF/GetJobIdBasedOnMRFId`;
export const getWorkFlowIdBasedOnTaskIdandUserId = `${url}/api/Onboarding/GetWorkFlowIdBasedOnTaskIdandUserId`;

// genrate document
export const GetConfigValueByKey = `${url}api/MRF/GetConfigValueByKey`;
export const GetCandidateDetails = `${url}api/MRF/GetCandidateDetails`;
//export const genrateDocument = "https://localhost:44363/GenerateDocument";
export const insertRequestId = `${url}api/MRF/InsertRequestId`;
//export const Status = "https://localhost:44363/Status";
export const effectiveDateUpdate = `${url}api/NewHire/UpdateAllSectionsEffectiveDate`;

export const OfferLetterGeneratedDocDounload =
  url + "/api/SharepointOnlineDocumentUpload/OfferLetterSharePointDownload";

export const CheckEmployeeAlreadyAppliedForMRF =
  url + "/api/MRF/CheckEmployeeAlreadyAppliedForMRF";
// sequence
export const getSequenceConfigDetails = `${url}/api/ManageSequence/GetSequenceConfigDetails`;
export const getSequenceCountries = `${url}/api/ManageSequence/GetSequenceCountries`;
export const getSequencedLegalEntities = `${url}/api/ManageSequence/GetSequencedLegalEntities`;
export const getPositionSequenceConfigDetails = `${url}/api/ManageSequence/GetPositionSequenceConfigDetails`;
export const getSequencedEmployeeClass = `${url}/api/ManageSequence/GetSequencedEmployeeClass`;
export const getSequencedSequenceNumber = `${url}/api/ManageSequence/GetSequencedSequenceNumber`;
export const getLastSeqNoBySeqNo = `${url}/api/ManageSequence/GetLastSeqNoBySeqNo`;
export const insertUpdateSequenceConfigDetails = `${url}/api/ManageSequence/InsertUpdateSequenceConfigDetails`;
export const insertSequenceNumberDetails = `${url}/api/ManageSequence/InsertSequenceNumberDetails`;
export const getManagedSequencedDetailsById = `${url}/api/ManageSequence/GetManagedSequencedDetailsById`;
export const getManagedSequencedHistory = `${url}/api/ManageSequence/GetManagedSequencedHistory`;
export const insertUpdatePositionSequenceConfigDetails = `${url}/api/ManageSequence/InsertUpdatePositionSequenceConfigDetails`;
export const getPositionSequencedSequenceNumberForDD = `${url}/api/ManageSequence/GetPositionSequencedSequenceNumberForDD`;
export const getPosLastSeqNoBySeqNo = `${url}/api/ManageSequence/GetPosLastSeqNoBySeqNo`;
export const getPositionSequencedHistory = `${url}/api/ManageSequence/GetPositionSequencedHistory`;
export const getPositionSequencedDetailsById = `${url}/api/ManageSequence/GetPositionSequencedDetailsById`;
export const getAprrove = `${url}/api/Dashboard/UpdateBulkApprovalRecords`;
//position
export const getAuditLogsV2ForPosition = `${url}/api/Audit/GetAuditLogsV2ForPosition`;
export const getPositionIncumbentGridData = `${url}/api/Dashboard/GetPositionIncumbentByPositionId`;
export const GetPositionActionHistory = `${url}/api/Dashboard/GetPositionActionHistoryData`;

export const checkIfEntryInAuditLogForPosition = `${url}/api/Progression/CheckIfEntryInAuditLogForPosition`;
export const requestRevokeForPosition = `${url}/api/Progression/RequestRevokeForPosition`;

//DocumentDelete
export const documentDeleteById = `${url}/api/DocumentDelete/DocumentDeleteById`;
export const GetCandidateReferenceDetailsByCandidateId = `${url}/api/MRF/GetCandidateReferenceDetailsByCandidateId`;
export const InsertIntoCandidateReferenceDetails = `${url}/api/MRF/InsertIntoCandidateReferenceDetails`;
export const CheckCandidateReferenceExistsOrNot = `${url}/api/MRF/CheckCandidateReferenceExistsOrNot`;

export const getAimedRequestsExcel = `${url}/api/Dashboard/GetAimedRequestsExcel`;
export const getConsentTemplates = `${url}/api/EmployeeConsentAction/GetEmployeeConsentData`;
export const updateTemplateData = `${url}/api/EmployeeConsentAction/UpdateEmployeeConsentActionTaken`;
export const getEmployeeConesetDocument = `${url}/api/EmployeeConsentAction/GetEmployeeConesetDocument`;
//EmployeeConcent
export const insertUpdateTemplateDetailsEmployeeConsent = `${url}/api/EmployeeConsent/TemplateInsertUpdate`;
export const employeeConsentSharepointDocumentUpload = `${url}/api/EmployeeConsent/EmployeeConsentSharepointDocumentUpload`;
export const getPayrollLocationDropdown = `${url}/api/EmployeeConsent/GetPayrollLocationDropdown`;
export const getTemplateDashboard = `${url}/api/EmployeeConsent/GetTemplateDashboard`;
export const getEmployeeConsentDashboardData = `${url}/api/EmployeeConsent/GetEmployeeConsentDashboardData`;
export const getTemplateEditDataById = `${url}/api/EmployeeConsent/GetTemplateEditDataById`;
export const downloadDocumentEmployeeConsent = `${url}/api/EmployeeConsent/DownloadDocument`;
export const getSearchDropDownForEmpConsent = `${url}/api/EmployeeConsent/GetSearchDropDownForEmpConsent`;
export const deleteTemplateConsent = `${url}/api/EmployeeConsent/DeleteTemplateConsent`;
export const deleteAnEmployeeConsent = `${url}/api/EmployeeConsent/DeleteAnEmployeeConsent`;
export const employeeExportToExcelDashboard = `${url}/api/EmployeeConsent/EmployeeExportToExcelDashboard`;
export const employeeExportToExcelEmployeeConsentDashboardData = `${url}/api/EmployeeConsent/EmployeeExportToExcelEmployeeConsentDashboardData`;
export const consentemployeeConsentHistoryData = `${url}/api/EmployeeConsent/ConsentemployeeConsentHistoryData`;
export const deleteALLEmployeeConsent = `${url}/api/EmployeeConsent/DeleteALLEmployeeConsent`;
// export const getAimedRequestsExcel = `${url}/api/Dashboard/GetAimedRequestsExcel`;
//export const getAimedRequestsExcel = `${url}/api/Dashboard/GetAimedRequestsExcel`;
export const GetDropdownBasedOnMasterTypeMRF = `${url}/api/MRF/GetDropdownBasedOnMasterTypeMRF`;
export const GetActivityOptions = `${url}/api/MRF/GetActivityOptions`;
export const GetEvaluatorsFeedbackForRecruiter = `${url}/api/MRF/GetEvaluatorsFeedbackForRecruiter`;
export const GetEvaluatorsForStage = `${url}/api/MRF/GetEvaluatorsForStage`;
export const GetCandidateOfferAcceptanceDetailsById = `${url}/api/MRF/GetCandidateOfferAcceptanceDetailsById`;
export const getEmployeeWithManager = `${url}/api/PeopleStructure/GetEmployeeDetailsByEId`;
export const getEmployeeChildrens = `${url}/api/PeopleStructure/GetEmployeeChildrensByEId`;
export const getProfilePicture = `${url}/api/PeopleStructure/GetProfilePicture`;
export const getEmployeeGridData = `${url}/api/PeopleStructure/GetEmployeeGridDataByPositId`;
export const getEmployeeStructureDetails = `${url}/api/PeopleStructure/GetSearchDropdownForEmployeeNoPeopleStructure`;

// Onb Candidate New
export const GetFillOnbTaskDetails = `${url}/api/CandidateOnboarding/GetFillOnbTaskDetailsForReDirection`;
export const UpdateDocumentCollectionTaskStatusCompleted = `${url}/api/OnBoarding/UpdateCandidateDocumentCollectionTaskStatus`;
export const GetPositionVacantStatusByMRFId = `${url}/api/MRF/GetPositionVacantStatusByMRFId`;
export const UpdateMRFStatus = `${url}/api/MRF/UpdateMRFStatus`;
export const GetTALeadAndRecruiterIdByMRFId = `${url}/api/MRF/GetTALeadAndRecruiterIdByMRFId`;
export const CheckStatusWhenMRFTryToReopen = `${url}/api/MRF/CheckStatusWhenMRFTryToReopen`;
export const GetLegalEntityDetails = `${url}/api/OnBoarding/GetLegalEntityDetails`;

// Profile Criticality Percentage calculator
export const GetEPProfilePercentageById = `${url}/api/ProfileCriticality/GetEPProfilePercentageById`;
export const DownloadProfileCompletionReport = `${url}/api/ProfileCriticality/DownloadProfileCompletionReport`;
export const GetCriticalDataDashboard = `${url}/api/ProfileCriticality/GetCriticalDataDashboard`;
export const GetCriticalDataDashboardDropdowns = `${url}/api/ProfileCriticality/GetCriticalDataDashboardDropdowns`;
export const GetCriticalDataHistory = `${url}/api/ProfileCriticality/GetCriticalDataHistory`;
export const GetCriticalDataFormDropdowns = `${url}/api/ProfileCriticality/GetCriticalDataFormDropdowns`;
export const GetCriticalDataAttributes = `${url}/api/ProfileCriticality/GetCriticalDataAttributes`;
export const GetCriticalDataAttributesPrimaryValues = `${url}/api/ProfileCriticality/GetCriticalDataAttributesPrimaryValues`;
export const GetCriticalDataById = `${url}/api/ProfileCriticality/GetCriticalDataById`;
export const InsertUpdateCriticalData = `${url}/api/ProfileCriticality/InsertUpdateCriticalData`;
export const GetLegalEntityDetailsCandidate = `${url}/api/CandidateOnboarding/GetLegalEntityDetailsCandidate`;

// Print Preview Functionality
export const getpreviewTabsSectionsList = `${url}/api/DocumentPrintPreview/GetTabsSectionsList`;
export const getReplaceBonafiedData = `${url}/api/DocumentPrintPreview/GetReplaceBonafiedData`;
export const insertIntoAttachmentDetails = `${url}/api/DocumentPrintPreview/InsertIntoAttachmentDetails`;

//MRF DELETE INTERFACE
export const DeleteMRFById = `${url}/api/MRF/DeleteMRFById`;
export const GetPerimissionRecordForMRFDashboard = `${url}/api/MRF/GetPerimissionRecordForMRFDashboard`;

//MRF ADMIN ACCESS
export const GetTabsForMRFAdminInterfaceLink = `${url}/api/MRF/GetTabsForMRFAdminInterface`;

//MRF GET CANDIDATE OFFER LETTER
export const GetCandidateOfferLetterByCandidateId = `${url}/api/MRF/GetCandidateOfferLetterByCandidateId`;

export const GetAadhaarOtp = `${url}/api/RCMBAssociateDetails/ValidateAadharDetails`;

export const GetAadhaarDetails = `${url}/api/RCMBAssociateDetails/FetchAadharDetails`;
export const InsertPreBookingRecruitmentDetails = `${url}/api/RCMBAssociateDetails/InsertPreBookingRecruitmentDetails`;
export const UpdateReferenceNoForPreBooking = `${url}/api/RCMBAssociateDetails/UpdateReferenceNoForPreBooking`;

export const GetMRFApproversMappingByMRFIdURL = `${url}/api/MRF/GetMRFApproversMappingByMRFId`;
export const GetAadharDetailsOfPreBooking = `${url}/api/RCMBAssociateDetails/GetAadharDetailsOfPreBooking`;

// LEFT THUMB IMPRESSION OF BLUE COLLAR
export const AssociateLeftThumb = `${url}/api/sharepointOnlineDocumentUpload/GetRCMBBiometrics`;

//RCM
export const CheckUserAvailablityForMeeting = `${url}/api/MRF/CheckUserAvailablityForMeeting`;
export const CancelMeetingByMeetingId = `${url}/api/MRF/CancelMeetingByMeetingId`;
export const ScheduleMeetingCandidateAndEvaluaotrs = `${url}/api/MRF/ScheduleMeeting`;
export const GetRecruitersByMRFId = `${url}/api/MRF/GetRecruitersByMRFId`;

// download document requests
export const InsertDocumentRequestDetails = `${url}/api/DownloadDocumentRequest/InsertDocumentRequestDetails`;
export const FetchGeneratedDocumentFromSharepoint = `${url}/api/SharepointOnlineDocumentUpload/FetchGeneratedDocumentFromDocumentLibraryById`;
export const insertIntoBonafiedRequests = `${url}/api/DocumentPrintPreview/InsertIntoBonafiedRequests`;

//CLM
export const GetPinDashboardData = `/api/Dashboard/GetPinDashboardData`;
export const InsertUpdatePinBoard = `/api/Dashboard/InsertUpdatePinBoard`;
export const InsertUpdateFormBoard = `/api/Dashboard/InsertUpdateFormBoard`;
export const GetFormBoardDashboardData = `/api/Dashboard/GetFormBoardDashboardData`;
export const GetPerceptionDashboardData = `/api/Dashboard/GetPerceptionDashboardData`;
export const InsertUpdatePerception = `/api/Dashboard/InsertUpdatePerception`;
export const GetCandidateOperationMappingDashboardData = `/api/Dashboard/GetCandidateOperationMappingDashboardData`;
export const InsertUpdateCandidateOperationMapping = `/api/Dashboard/InsertUpdateCandidateOperationMapping`;
export const GetDropDownValueDetails = `api/CommonDropdowns/GetDropDownValueDetails`;
export const GetSchemeCreationDashboardData = `/api/Dashboard/GetSchemeCreationDashboardData`;
export const InsertUpdateSchemeCreationMapping = `/api/Dashboard/InsertUpdateSchemeCreationMapping`;
export const GetOperationBasedOnDivisionMappingData = `/api/Dashboard/GetOperationBasedOnDivisionMappingData`;

//NAUKRI INTEGRATION
export const GetJobBoardOptions = `${url}/api/MRF/GetJobBoardOptions`;
export const GetNaukriDropdownOption = `${url}/api/MRF/GetNaukriDropdownOption`;
export const GetInfoLabelForAttributeOfNaukri = `${url}/api/MRF/GetInfoLabelForAttributeOfNaukri`;
export const GetJobPostingDetailsByMRFIdAndType = `${url}/api/MRF/GetJobPostingDetailsByMRFIdAndType`;
export const InsertUpdateNaukriPostingDetails = `${url}/api/MRF/InsertUpdateNaukriPostingDetails`;
export const GetMRFNaukriPostedStatus = `${url}/api/MRF/GetMRFNaukriPostedStatus`;
export const GetSavedNaukriEducationDetails = `${url}/api/MRF/GetSavedNaukriEducationDetails`;
export const GenericMasterDataByParentId = `${url}/api/MRF/GenericMasterDataByParentId`;
export const MRFDepublishNaukriJob = `${url}/api/MRF/MRFDepublishNaukriJob`;
export const MRFRepublishNaukriJob = `${url}/api/MRF/MRFRepublishNaukriJob`;

export const getBonafiedDocumentsList = `${url}/api/DocumentPrintPreview/GetBonafideDocumentsList`;

// View Word as PDF
export const WordtoPdfBase64 = `${url}/api/SharepointOnlineDocumentUpload/WordtoPdfBase64`;
// Addeding new routes for the progression picklist dashboard. Raj Potdar, Date : 19-06-2024
export const GetProgressionGenericMasterDashboardData = `${url}/api/GenericMaster/GetProgressionGenericMasterDashboardData`;
export const GetProgressionGenericMasterData = `${url}/api/GenericMaster/GetProgressionGenericMasterData`;
export const UpdateProgressionGenericMasterData = `${url}/api/GenericMaster/UpdateProgressionGenericMasterData`;

export const GetCandidateDeclartionForm = `${url}/api/MRF/GetCandidateDeclartionForm`;
export const IntitiateCandidateWorkflow = `${url}/api/MRF/IntitiateCandidateWorkflow`;
export const ConvertDocToPdf = `${url}/api/MRF/ConvertDocToPdf`;
export const DeleteAttachment = `${url}/api/MRF/DeleteAttachment`;
export const SaveCandidateWhenRecruiterEdit = `${url}/api/MRF/SaveCandidateWhenRecruiterEdit`;
export const GetCandidateDetailsByMRFIDAndCandidateId = `${url}/api/MRF/GetCandidateDetailsByMRFIDAndCandidateId`;
export const UploadSalaryNegoDataByCandidate = `${url}/api/MRF/UploadSalaryNegoDataByCandidate`;
export const GetSavedSalaryNegoDocument = `${url}/api/MRF/GetSavedSalaryNegoDocument`;
export const GetLinkedinOptions = `${url}/api/MRF/GetLinkedinOptions`;
// JOB POSTING FETCH MRF ID AND JOBID
export const getMRFIdandJobDataBasedOnMrfNo = `${url}/api/MRF/GetMRFIdandJobDataBasedOnMrfNo`;
export const CheckJobPostingOnLinkdin = `${url}/api/MRF/CheckJobPostingOnLinkdin`;
