import React, {
  useState,
  useEffect,
  useContext,
  useReducer,
  useRef,
  RefObject,
} from "react";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { useNavigate } from "react-router-dom";
import BaseModal from "../../Components/BaseModel/BaseModel";
import FormStructure from "../../Components/FromStructure/FormStructure";
import SelectForm from "../../Components/SelectForm/SelectForm";
import "./CommonDashboard.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Collapse from "react-bootstrap/Collapse";
import { APICall } from "../../Helpers/APICalls";
import ExcelJS from "exceljs";
import {
  getDashboardGridData,
  getDropdowns,
  getDropdownsOUFilters,
  getTabsData,
  getCommonDashboardMyRequestsGridData,
  getUsersForSearchableDropdown,
  getWorkflowData,
  getEmployeeCtNewHireStatus,
  getWorkFlowIdBasedOnTaskIdandUserId,
  getEmployeedetailsBasedOnCandidateId,
  CancelRequests,
  ReRouteRequests,
  getAprrove,
  GetAimedRequestsCount,
  getEmployeeHistoryData,
  getAimedRequestsExcel
} from "../../Helpers/APIEndPoints/EndPoints";
import InputForm from "../../Components/InputForm/InputForm";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  Tabs,
  Checkbox,
} from "@mui/material";
import { LoaderContext } from "../../Helpers/Context/Context";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import notify from "../../Helpers/ToastNotification";
import DateForm from "../../Components/DateForm/DateForm";
import moment from "moment";
import { Button, Modal, OverlayTrigger, Popover, Form } from "react-bootstrap";
import { useDebounce } from "../../utils/useDebounce";
import { isEmpty } from "../../utils/isEmpty";
import { Margin } from "@mui/icons-material";

const hideCheckboxArr = [
  "OnBoarding",
  "OnBoardingCheckList",
  "OnBoardingApprovalDashboard",
  "ManpowerRequisition",
];

const CommonDashboard = (props) => {
  // const inputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [isExportToExcel, setExportToExcel] = useState(false);
  const [gridData, setGridData] = useState(null);
  const [showFormModal, setShowFormModal] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const pageSizeOptions = [10, 20, 50];
  const [showError, setShowError] = useState(false);

  const { showLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  let { userDetails, interfacePermissionRecord, currentRoleId }: any =
    useUserContext();
  const [showPopover, setShowPopover] = useState(false);
  const [ddValue, setDdValue] = useState({});
  const [status, setStatus] = useState<any>([]);
  const [process, setProcess] = useState<any>([]);
  const [recordStatus, setRecordStatus] = useState<any>([]);
  const [business, setBusiness] = useState<any>([]);
  const [recordID, setRecordID] = useState<any>([]);
  const [pendingWithUserName, setPendingWithUserName] = useState<any>([]);
  const [recordDate, setRecordDate] = useState<any>([]);
  const [rowData2, setRowData2] = useState([]);
  const [count2, setCount2] = useState(0);
  const [page2, setPage2] = useState(0);
  const [storeTableMeta,setstoreTableMeta] = useState("");
  const [sortDirection2, setSortDirection2] = useState("");
  const [sortColumn2, setSortColumn2] = useState("");
  const [filterValue, setFilterValue] = useState({});
  const [cancelRequestRemark, setCancelRequestRemark] = useState("");
  const [reRouteRequestRemark, setReRouteRequestRemark] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [activetabIndex, setActivetabIndex] = useState<number>(0);
  const [sectionValuesArray, setSectionValuesArray] = useState<any>([]);
  const [approverToValue, setApproverToValue] = useState<any>([]);
  const [approverFromValue, setApproverFromValue] = useState<any>([]);
  const [reRouteFromApprovers, setReRouteFromApprovers] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [hidePopover, setHidePopover] = useState(false);
  const [reRouteAllRequests, setReRouteAllRequests] = useState(false);
  const [aimedRequestsCount, setAimedRequestsCount] = useState("0");
  const [activetab, setActivetab] = useState<string>("My Request");
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [tabs, setTabs] = useState<any>([
    "My Request",
    "My Action",
    "All Request",
  ]);

  const [dropdownOptions, setDropdownOptions] = useState({});
  const [approveRowData, setApproveRowData] = useState<any>([]);
  //Dashboard States
  // const pageSize = 10;
  const [pageSize, setPageSize] = useState(10);
  const [updatedpageSize, setupdatedpageSize] = useState(6);
  const [count, setCount] = useState(0);
  const [start, setStart] = useState(0);
  const [sortDirection, setSortDirection] = useState("desc");
  const [sortColumn, setSortColumn] = useState("CreatedDate");
  const [searchText, setSearchText] = useState("");
  const [rowData, setRowData] = useState([]);
  const [rowDataCount, setrowDataCount] = useState(0);
  const [reload, setReload] = useState(false);
  const [initialState, setInitialState] = useState({});
  const [infoState, setInfoState] = useState<any>({});

  const debouncedValue = useDebounce(filterValue["recordTitle"]);
  console.log("rowData::::",rowData);
  console.log("approveRowData::::",approveRowData);
  
  useEffect(() => {
    if (
      interfacePermissionRecord?.some(
        (e) => e.interfaceName === "AllOverRequests"
      )
    ) {
      if (!tabs.some((x) => x === "Manage Transactions")) {
        tabs.push("Manage Transactions");
      }
    } else {
      if (tabs.some((x) => x === "Manage Transactions")) {
        tabs.pop();
      }
    }

    getInterfaceDetails();
  }, [interfacePermissionRecord]);

  const getDropdownsData = async (
    AC,
    PID = null,
    cb,
    SearchText = "",
    FilterString = ""
  ) => {
    try {
      showLoader();

      const dropdownData = await APICall(getDropdowns, "POST", {
        AC,
        PID,
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
        SearchText,
        FilterString,
      });

      const data = dropdownData.data || [];

      return data;
    } catch (error) {
      console.error("Error in getDropdownsData:", error);
      return []; // Return an empty array in case of an error
    } finally {
      hideLoader();
    }
  };

  const getDropdownsOUFilter = async (AC) => {
    try {
      showLoader();

      const dropdownData = await APICall(getDropdownsOUFilters, "POST", {
        AC,
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      });

      const data = dropdownData.data || [];

      return data;
    } catch (error) {
      console.error("Error in getDropdownsOUFilter:", error);
      return []; // Return an empty array in case of an error
    } finally {
      hideLoader();
    }
  };

  const dashboardDataAPICall = async (action: string) => {
    const GetDashboardData = await APICall(
      getCommonDashboardMyRequestsGridData,
      "POST",
      {
        TabName: action,
        Start: isExportToExcel ? 0 : start,
        pageSize: isExportToExcel ? 0 : pageSize,
        SearchText: isExportToExcel ? "" : filterValue["recordTitle"] || "",
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
        SortColumn: isExportToExcel ? 0 : sortColumn,
        SortOrder: isExportToExcel ? "asc" : sortDirection,
        commaSeparatedProcessId: filterValue["process"]
          ? filterValue["process"]?.map((s) => s.value)?.join(",")
          : "",
        commaSeparatedRecordStatusId: filterValue["recordStatus"]
          ? filterValue["recordStatus"]?.map((s) => s.label)?.join(",")
          : "",
        commaSeparatedStatusId: filterValue["status"]
          ? filterValue["status"]?.label
          : // filterValue["status"]?.map((s) => s.label)?.join(",")
            "",
        commaSeparatedRecordIds: filterValue["recordID"]
          ? filterValue["recordID"]?.map((s) => s.value)?.join(",")
          : "",

        commaSeparatedPendingWithUserNameId:
          filterValue["pendingWithUserName"] != null &&
          Object.keys(filterValue["pendingWithUserName"]).length > 0
            ? filterValue["pendingWithUserName"]?.value
            : "",

        commaSeparatedBusinessId: filterValue["BIS"]
          ? filterValue["BIS"]?.map((s) => s.value)?.join(",")
          : "",
        commaSeparatedRecordDates: filterValue["recordDate"] || null,

        fromDate: filterValue["fromDate"] || null,
        toDate: filterValue["toDate"] || null,

        commaSeparatedApproverId: filterValue["approver"]
          ? filterValue["approver"]?.map((s) => s.value)?.join(",")
          : "",

        commaSeparatedInitiatorId: filterValue["initiator"]
          ? filterValue["initiator"]?.map((s) => s.value)?.join(",")
          : "",

        commaSeparatedEmployeeId: filterValue["employee"]
          ? filterValue["employee"]?.map((s) => s.value)?.join(",")
          : "",

        commaSeparatedSBUIds: filterValue["SBU"]
          ? filterValue["SBU"]?.map((s) => s.value)?.join(",")
          : "",
        commaSeparatedLENIds: filterValue["LEN"]
          ? filterValue["LEN"]?.map((s) => s.value)?.join(",")
          : "",
        commaSeparatedCUSBISIds: filterValue["CUSBIS"]
          ? filterValue["CUSBIS"]?.map((s) => s.value)?.join(",")
          : "",
        commaSeparatedPLOCIds: filterValue["PLOC"]
          ? filterValue["PLOC"]?.map((s) => s.value)?.join(",")
          : "",
        transactionId: filterValue["transactionId"] || null,
      }
    );

    return GetDashboardData;
  };

  const getInterfaceDetails = async () => {
    try {
      showLoader();
      const gridDataResponse = await APICall(
        "/api/Interface/GetInterfaceDetailsForGrid",
        "POST",
        {
          MN: "EmployeeCentral",
          IN: "Common_Dashboard_Test",
          TN: "CommonDashboardTest", //CHANGE---------------------------------------------------------------
        }
      );

      if (activetab === "Manage Transactions") {
        gridDataResponse.data.d.fa[0].filAN = "initiator";
        gridDataResponse.data.d.fa[0].filn = "Initiator";
        gridDataResponse.data.d.fa[0].filt = "searchabledropdown";
        gridDataResponse.data.d.fa[1].filAN = "process";
        gridDataResponse.data.d.fa[1].filn = "Process";
        gridDataResponse.data.d.fa[1].filt = "multidropdown";

        const faFromDate = {
          filAN: "fromDate",
          filn: "From Date",
          filt: "dateTime",
        };
        let faToDate = {
          filAN: "toDate",
          filn: "To Date",
          filt: "dateTime",
        };
        if (!gridDataResponse.data.d.fa.find((x) => x.filAN === "fromDate")) {
          gridDataResponse.data.d.fa.push(faFromDate);
        }

        if (!gridDataResponse.data.d.fa.find((x) => x.filAN === "toDate")) {
          gridDataResponse.data.d.fa.push(faToDate);
        }

        gridDataResponse.data.d.afa[0].filAN = "status";
        gridDataResponse.data.d.afa[0].filn = "Status";
        gridDataResponse.data.d.afa[0].filt = "dropdown";
        gridDataResponse.data.d.afa[1].filAN = "employee";
        gridDataResponse.data.d.afa[1].filn = "Employee";
        gridDataResponse.data.d.afa[1].filt = "searchabledropdown";
        gridDataResponse.data.d.afa[2].filAN = "approver";
        gridDataResponse.data.d.afa[2].filn = "Approver";
        gridDataResponse.data.d.afa[2].filt = "searchabledropdown";

        let faBU = {
          filAN: "BIS",
          filn: "Business",
          filt: "multidropdown",
        };

        if (!gridDataResponse.data.d.afa.find((x) => x.filAN === "business")) {
          gridDataResponse.data.d.afa.push(faBU);
        }

        let faSBU = {
          filAN: "SBU",
          filn: "SBU",
          filt: "multidropdown",
        };

        if (!gridDataResponse.data.d.afa.find((x) => x.filAN === "sbu")) {
          gridDataResponse.data.d.afa.push(faSBU);
        }

        let faLEN = {
          filAN: "LEN",
          filn: "Legal Entity",
          filt: "multidropdown",
        };

        if (
          !gridDataResponse.data.d.afa.find((x) => x.filAN === "legalEntity")
        ) {
          gridDataResponse.data.d.afa.push(faLEN);
        }

        let faCUSBIS = {
          filAN: "CUSBIS",
          filn: "Custom Business",
          filt: "multidropdown",
        };

        if (
          !gridDataResponse.data.d.afa.find((x) => x.filAN === "customBusiness")
        ) {
          gridDataResponse.data.d.afa.push(faCUSBIS);
        }

        let faPLOC = {
          filAN: "PLOC",
          filn: "Payroll Location",
          filt: "multidropdown",
        };

        if (!gridDataResponse.data.d.afa.find((x) => x.filAN === "ploc")) {
          gridDataResponse.data.d.afa.push(faPLOC);
        }

        let faTransactionID = {
          filAN: "transactionId",
          filn: "Transaction ID",
          filt: "text",
        };

        if (
          !gridDataResponse.data.d.afa.find((x) => x.filAN === "transactionId")
        ) {
          gridDataResponse.data.d.afa.push(faTransactionID);
        }
      } else {
        if (gridDataResponse && gridDataResponse.data) {
          gridDataResponse.data.d.fa[0].filAN = "process";
          gridDataResponse.data.d.fa[0].filn = "Process";
          gridDataResponse.data.d.fa[0].filt = "multidropdown";
          gridDataResponse.data.d.fa[1].filAN = "recordTitle";
          gridDataResponse.data.d.fa[1].filn = "Search by Record Title";
          gridDataResponse.data.d.fa[1].filt = "text";

          gridDataResponse.data.d.afa[0].filAN = "recordID";
          gridDataResponse.data.d.afa[0].filn = "Record ID";
          gridDataResponse.data.d.afa[0].filt = "multidropdown";
          gridDataResponse.data.d.afa[1].filAN = "recordStatus";
          gridDataResponse.data.d.afa[1].filn = "Record Status";
          gridDataResponse.data.d.afa[1].filt = "multidropdown";
          gridDataResponse.data.d.afa[2].filAN = "pendingWithUserName";
          gridDataResponse.data.d.afa[2].filn = "Pending With";
          gridDataResponse.data.d.afa[2].filt = "searchabledropdown";

          let fromDateindex = gridDataResponse.data.d.fa.findIndex(
            (x) => x.filAN === "fromDate"
          );
          if (fromDateindex !== -1) {
            gridDataResponse.data.d.fa.splice(fromDateindex, 1);
          }

          let toDateindex = gridDataResponse.data.d.fa.findIndex(
            (x) => x.filAN === "toDate"
          );
          if (toDateindex !== -1) {
            gridDataResponse.data.d.fa.splice(toDateindex, 1);
          }

          let businessindex = gridDataResponse.data.d.afa.findIndex(
            (x) => x.filAN === "business"
          );
          if (businessindex > 0) {
            gridDataResponse.data.d.afa.splice(businessindex, 1);
          }
        }
      }

      const obj = {};
      const tempDropdownOptions = {};

      const processFilter = async (cFilter) => {
        if (
          cFilter.filt === "multidropdown" ||
          cFilter.filt === "searchabledropdown" ||
          cFilter.filt === "dropdown"
        ) {
          obj[cFilter.filAN] = [];
          let options = [];
          if (
            cFilter.filAN === "BIS" ||
            cFilter.filAN === "SBU" ||
            cFilter.filAN === "LEN" ||
            cFilter.filAN === "CUSBIS" ||
            cFilter.filAN === "PLOC"
          ) {
            options = await getDropdownsOUFilter(cFilter.filAN);
          } else {
            options = await getDropdownsData(cFilter.filAN, null, null);
          }
          if (cFilter.filt === "multidropdown" || cFilter.filt === "dropdown") {
            tempDropdownOptions[cFilter.filAN] = options;
          }
        } else if (cFilter.filt === "dateTime") {
          obj[cFilter.filAN] = null;
        } else {
          obj[cFilter.filAN] = null;
        }
      };

      if (gridDataResponse?.data?.d?.fa !== null) {
        await Promise.all(gridDataResponse.data.d.fa.map(processFilter));
      }

      if (gridDataResponse?.data?.d?.afa !== null) {
        await Promise.all(gridDataResponse.data.d.afa.map(processFilter));
      }

      setFilterValue(obj);

      const updatedStatus = tempDropdownOptions?.["status"] || [];
      const filteredStatus = updatedStatus.filter(
        (val) => val.value !== "InProgess"
      );
      const newDropdownOptions =
        activetab === "Manage Transactions"
          ? { ...tempDropdownOptions, status: filteredStatus }
          : tempDropdownOptions;
      setDropdownOptions(newDropdownOptions);
      setInitialState(obj);
      setGridData(gridDataResponse);
      await getGridStructureTwo();
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (gridData?.data?.d?.s[0]?.columnData.length > 0) {
      (async () => await getGridStructureTwo())();
    }
  }, [
    start,
    sortDirection,
    reload,
    pageSize,
    // activetab,
    debouncedValue,
    // isExportToExcel,
  ]);

  useEffect(() => {
    if (gridData?.data?.d?.s[0]?.columnData.length > 0) {
      (async () => await getInterfaceDetails())();
      setSelectedIds([]);
      setPageSize(10);
      setrowDataCount(0);
    }
  }, [activetab, isExportToExcel]);

  const onSubmitFilter = () => {
    let isNull = Object.values(filterValue).every(
      (value) => value === null || (Array.isArray(value) && value.length === 0)
    );
    if (isNull) {
      notify(1, "Select atleast one filter");
    } else {
      getGridStructureTwo();
    }
  };

  const resetFilters = () => {
    setFilterValue(initialState);
    setStart(0);
    setReload(!reload);
    setSortColumn("CreatedDate");
    setSortDirection("desc");
  };

  const getGridStructureTwo = async () => {
    showLoader();
    let action = "";
    if (activetab == "My Action") {
      action = "MyActions";
    } else if (activetab == "My Request") {
      action = "MyRequests";
    } else if (activetab == "All Request") {
      action = "AllActions";
    } else {
      action = "AllOverRequests";
    }

    let obj = {};

    if (open) {
      obj = {
        TabName: action,
        Start: start,
        pageSize: pageSize,
        SearchText: filterValue["recordTitle"] || "",
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
        SortColumn: sortColumn,
        SortOrder: sortDirection,
        commaSeparatedProcessId: filterValue["process"]
          ? filterValue["process"]?.map((s) => s.value)?.join(",")
          : "",
        commaSeparatedRecordStatusId: filterValue["recordStatus"]
          ? filterValue["recordStatus"]?.map((s) => s.label)?.join(",")
          : "",

        commaSeparatedStatusId: filterValue["status"]
          ? filterValue["status"]?.label
          : // filterValue["status"]?.map((s) => s.label)?.join(",")
            "",

        commaSeparatedRecordIds: filterValue["recordID"]
          ? filterValue["recordID"]?.map((s) => s.value)?.join(",")
          : "",

        commaSeparatedPendingWithUserNameId:
          filterValue["pendingWithUserName"]?.value || "",

        commaSeparatedBISIds: filterValue["business"]
          ? filterValue["business"]?.map((s) => s.value)?.join(",")
          : "",

        commaSeparatedApproverId:
          filterValue["approver"] != null &&
          Object.keys(filterValue["approver"]).length > 0
            ? filterValue["approver"]?.value
            : null,

        commaSeparatedInitiatorId: filterValue["initiator"]
          ? filterValue["initiator"]?.map((s) => s.value)?.join(",")
          : "",

        commaSeparatedEmployeeId:
          filterValue["employee"] != null &&
          Object.keys(filterValue["employee"]).length > 0
            ? filterValue["employee"]?.value
            : null,

        fromDate: filterValue["fromDate"] || null,
        toDate: filterValue["toDate"] || null,
        commaSeparatedBusinessId: filterValue["BIS"]
          ? filterValue["BIS"]?.map((s) => s.value)?.join(",")
          : "",
      };
    } else {
      obj = {
        TabName: action,
        Start: start,
        pageSize: pageSize,
        SortColumn: sortColumn,
        SortOrder: sortDirection,
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
        SearchText: filterValue["recordTitle"] || "",
        commaSeparatedProcessId: filterValue["process"]
          ? filterValue["process"]?.map((s) => s.value)?.join(",")
          : "",
        commaSeparatedBusinessId: filterValue["BIS"]
          ? filterValue["BIS"]?.map((s) => s.value)?.join(",")
          : "",

        commaSeparatedRecordStatusId: "",
        commaSeparatedStatusId: "",
        commaSeparatedRecordIds: "",
        commaSeparatedPendingWithUserNameId: "",
        commaSeparatedRecordDates: "",
        commaSeparatedApproverId: "",
        commaSeparatedEmployeeId: "",
      };
    }

    const responseData = await dashboardDataAPICall(action);

    if (isExportToExcel) {
      //const responseData = await dashboardDataAPICall("AllOverRequests");

      if (
        (!responseData && !responseData.data) ||
        responseData?.data?.length === 0
      ) {
        notify(1, "There is No data.");
        hideLoader();
        return;
      }

      const convertCamelCaseToWords = (camelCaseString) => {
        return camelCaseString
          .replace(/([a-z])([A-Z])/g, "$1 $2")
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      };

      let filteredHeaders = [
        "RecordId",
        "Process",
        "Activity",
        "Status",
        "BusinessUnit",
        "PendingWithUserName",
        "Initiator",
        "EffectiveDate",
        "InitiatedOn",
        "CompletedOn",
      ];

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Manage Transactions");

      const headerRow = worksheet.addRow(
        filteredHeaders.map((header) => convertCamelCaseToWords(header))
      );
      headerRow.font = { bold: true };

      responseData.data.forEach((row) => {
        const dataRow = filteredHeaders.map(
          (header) => row[header.toLowerCase()] || ""
        );
        worksheet.addRow(dataRow);
      });

      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = `Manage Transactions.xlsx`;
        a.click();

        URL.revokeObjectURL(url);
      });
      setExportToExcel(false);
    } else {
      if (responseData?.data !== null && responseData?.data?.length > 0) {
        //console.log("Data: ", GetDashboardData.data);
        //"2023-11-06T13:15:47.36"
        const arr = responseData.data.map((item) => {
          const dateRS = moment.utc(item?.recordDate).local();
          const formattedDateRS = moment(dateRS).isValid()
            ? moment(dateRS).format("DD MMM YYYY hh:mm A")
            : null;
          item.recordDate = formattedDateRS;

          if (item.approvedOn) {
            const dateAo = moment.utc(item?.approvedOn).local();
            const formattedDateAO = moment(dateAo).format(
              "DD MMM YYYY hh:mm A"
            );
            item.approvedOn = formattedDateAO;
          }

          return item;
        });

        setCount(responseData?.data[0]?.totalCount);
        const filteredArray = responseData?.data?.filter(
          (obj) => !hideCheckboxArr.includes(obj.moduleProcessName)
        );
        setrowDataCount(filteredArray?.length || 0);
        setRowData(arr);
      } else {
        setRowData([]);
        setrowDataCount(0);
        setCount(0);
      }
    }
    hideLoader();
  };

  const view = (value, tableMeta) => {
    const url = value;

    window.open(url);
  };

  const edit = (value, tableMeta) => {
    // const TId = tableMeta.rowData[12];
    // const WorkflowSystemName = tableMeta.rowData[13];
    const TId = Number(tableMeta.tableData[tableMeta.rowIndex].tid);
    const WorkflowSystemName =
      tableMeta.tableData[tableMeta.rowIndex].systemName;
    const CT = tableMeta.tableData[tableMeta.rowIndex].country;

    if (WorkflowSystemName === "MRFAprroval") {
      navigate("/mrf", {
        state: { TId, WorkflowSystemName, Tab: "ManpowerProcess" },
      });
    } else {
      navigate("/");
    }
  };

  const newHireSendback = (value, tableMeta) => {
    //tableMeta.tableData[tableMeta.rowIndex].id;
    // const TId = tableMeta.rowData[12];
    // const WorkflowSystemName = tableMeta.rowData[13];
    // const NewHireStatus = tableMeta.rowData[14];
    // const WorkflowTaskId = tableMeta.rowData[0];

    const WorkflowTaskId = tableMeta.tableData[tableMeta.rowIndex].id;
    const TId = Number(tableMeta.tableData[tableMeta.rowIndex].tid);
    const NewHireStatus = tableMeta.tableData[tableMeta.rowIndex].newHireStatus;
    const CT = tableMeta.tableData[tableMeta.rowIndex].country;
    const WorkflowSystemName =
      tableMeta.tableData[tableMeta.rowIndex].systemName;


      if(tableMeta.tableData[tableMeta.rowIndex].employeeType==="Employee"){
        navigate("/profile", {
          state: { TId, WorkflowTaskId, NewHireStatus, CT },
        });}
        else{
          navigate("/clmprofile", {
            state: { TId, WorkflowTaskId, NewHireStatus, CT },
          });}


    // navigate("/profile", {
    //   state: { TId, WorkflowTaskId, NewHireStatus, CT },
    // });
  };

  const essMssSendback = async (value, EmpIdForEss, tableMeta) => {
    let NewHireStatus = "Approved";
    let CT = "CODECNTRY1";
    const WorkflowTaskId = 0;
    const respForEss = await APICall(getEmployeeCtNewHireStatus, "POST", {
      Id: EmpIdForEss,
    });
    if (respForEss.data != null) {
      NewHireStatus = respForEss.data.newHireStatus;
      CT = respForEss.data.country;
    }
    const TId = EmpIdForEss;
    const SendbackForEss = true;
    const SectionForEss = tableMeta.tableData[tableMeta.rowIndex].essSectionId;

    if(tableMeta.tableData[tableMeta.rowIndex].employeeType==="Employee"){
      navigate("/profile", {
        state: { TId, WorkflowTaskId, NewHireStatus, CT,SendbackForEss, SectionForEss },
      });}
      else{
        navigate("/clmprofile", {
          state: { TId, WorkflowTaskId, NewHireStatus, CT,SendbackForEss, SectionForEss },
        });}
    // navigate("/profile", {
    //   state: {
    //     TId,
    //     WorkflowTaskId,
    //     NewHireStatus,
    //     CT,
    //     SendbackForEss,
    //     SectionForEss,
    //   },
    // });
  };

  const handleOnChange = async (DDName, event) => {
    if (DDName === "ReRouteApproverTo") {
      // const approverToValue = event && event.value;
      setApproverToValue(event);
    } else if (DDName === "ReRouteApproverFrom") {
      setApproverFromValue(event);
      setReRouteAllRequests(false);
    }
  };

  const newHireApproved = (value, tableMeta) => {
    // const TId = tableMeta.rowData[12];
    // const WorkflowSystemName = tableMeta.rowData[13];
    // const NewHireStatus = tableMeta.rowData[14];
    // const WorkflowTaskId = tableMeta.rowData[0];

    const WorkflowTaskId = tableMeta.tableData[tableMeta.rowIndex].id;
    const TId = Number(tableMeta.tableData[tableMeta.rowIndex].tid);
    const NewHireStatus = tableMeta.tableData[tableMeta.rowIndex].newHireStatus;
    const CT = tableMeta.tableData[tableMeta.rowIndex].country;
    const WorkflowSystemName =
      tableMeta.tableData[tableMeta.rowIndex].systemName;
    const isViewForNewHire = true;
    if(tableMeta.tableData[tableMeta.rowIndex].employeeType==="Employee"){
      navigate("/profile", {
        state: { TId, WorkflowTaskId, NewHireStatus, CT,isViewForNewHire },
      });}
      else{
        navigate("/clmprofile", {
          state: { TId, WorkflowTaskId, NewHireStatus, CT,isViewForNewHire },
        });}

    // navigate("/profile", {
    //   state: { TId, WorkflowTaskId, NewHireStatus, CT,isViewForNewHire },
    // });
  };

  //Select all checkbox
  const handleSelectAll = (e) => {
    // const allChecked = e.target.checked;
    const { checked } = e.target || {};
    const allUpdatedData = rowData?.map((item) => ({
      ...item,
      selected: hideCheckboxArr.includes(item.moduleProcessName)
        ? false
        : checked,
      remark: '',
    }));
    setRowData(allUpdatedData);

    // enable when all checkbox checked
    const allUpdatedSelectedIds = allUpdatedData
      .filter((item) => item.selected)
      .map((item) => item.id);

    setSelectedIds(allUpdatedSelectedIds);
  };

  const handleCheckboxChange = (e, dashdata, value) => {
    const clickedID = dashdata[1];

    const updatedData = rowData?.map((finalitem) => {
      if (finalitem.id === clickedID) {
        return {
          ...finalitem,
          selected: value,
          remark: '',
        };
      } else {
        return finalitem;
      }
    });

    //console.log("updatedData", updatedData);
    // enable when single checkbox checked
    const updatedSelectedIds = updatedData
      .filter((item) => item.selected)
      .map((item) => item.id);
    setSelectedIds(updatedSelectedIds);
    setRowData(updatedData);
  };

  const handleApproval = async () => {
    setShowFormModal(true);
    const rowsToDisplay = rowData.filter((item) => item.selected);
    console.log('rowsToDisplay::::',rowsToDisplay);
    setApproveRowData(rowsToDisplay);
  };

  const handleClose = () => {
    setShowFormModal(false);
    setShowError(false);

    // if (inputRef.current) {
    //   inputRef.current.value = "";
    // }
    setCheckBoxAllRemark(false);
    const updatedData = rowData?.map((finalitem) => {
      return {
        ...finalitem,
        remark: '',
      };
    });
    setRowData(updatedData);
  };

  // Submit Approve
  const submitApproval = async () => {
    // if (!inputRef.current?.value || inputRef.current?.value?.trim() === "") {
    //   // Show error and prevent submission
    //   setShowError(true);
    //   return;
    // }

    // const updatedRowData = rowData?.map((item) => ({
    // 	...item,
    // 	BulkApprovalRemarks: inputRef?.current?.value || '',
    // 	IsBulkApproved: item.selected || false,
    // 	CreatedBy: userDetails.Id,
    // 	ActionByUserId: userDetails.Id,
    // 	ActionByRoleId: currentRoleId?.value,
    // }));

    const filteredRowData = rowData.filter((val) => val.selected);
    const updatedRowData = filteredRowData.map((val) => ({
      ...val,
      // BulkApprovalRemarks: inputRef?.current?.value || "",
      BulkApprovalRemarks: val?.remark || "",
      IsBulkApproved: val.selected || false,
      CreatedBy: userDetails.Id,
      ActionByUserId: userDetails.Id,
      ActionByRoleId: currentRoleId?.value,
    }));

    console.log("updatedRowData::", updatedRowData);
    try {
      const response = await APICall(getAprrove, "POST", {
        // finalData: updatedRowData,
        // WorkflowTaskId: 1,
        // DecisionId: 1,
        // Remarks: remark,
        // UserId: userDetails?.Id,
        // UserRoleId: currentRoleId?.value,
        // IsAcknowled: true,
        finalData: updatedRowData,
      });
      setShowError(false);
      // if (inputRef.current) {
      //   inputRef.current.value = "";
      // }
      if (response.data === 1) {
        notify(0, `${response.message}.`);
        hideLoader();
      } else {
        notify(0, `${response.message}.`);
        hideLoader();
      }
    } catch (error) {
      console.error("API call error:", error);
      notify(1, "Error occurred while processing your request.");
      hideLoader();
    } finally {
      handleClose();
      getGridStructureTwo();
      setSelectedIds([]);
      setrowDataCount(0);
    }
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setStart(0);
    setSelectedIds([]);
    setrowDataCount(0);
  };

  const exportToExcel = async () => {
    showLoader();
    setExportToExcel(true);
    hideLoader();
  };

  const cancelEmployeeRequests = async () => {
    if (cancelRequestRemark?.trim() === "") {
      notify(1, 'Remarks are mandatory');
      return;
    };

    const WorkflowInstanceId = infoState.tableData[infoState.rowIndex].id;
    const TransactionId = infoState.tableData[infoState.rowIndex].rid;
    const RequestId = infoState.tableData[infoState.rowIndex].employeeId;
    const Event = infoState.tableData[infoState.rowIndex].event;
    const EventReason = infoState.tableData[infoState.rowIndex].eventReason;

    let PositionId = null;
    if (
      infoState.tableData[infoState.rowIndex].moduleProcessName ===
      "PositionManagement"
    ) {
      PositionId = infoState.tableData[infoState.rowIndex].tid;
    }

    try {
      showLoader();

      const response = await APICall(CancelRequests, "POST", {
        WorkflowInstanceId: WorkflowInstanceId,
        UserId: userDetails?.Id,
        Remark: cancelRequestRemark,
        TransactionId: TransactionId,
        RequestId: RequestId,
        PositionId: PositionId,
        Event: Event,
        EventReason: EventReason,
      });

      const res = response.data || [];

      if (response.message == "Ok") {
        notify(0, "Request Cancelled Successfully");
        setShowCancelModal(false);
        setShowPopover(false);
        setInfoState({})
        setSelectedId(undefined);
        await getGridStructureTwo();
      } else {
        notify(1, "Some error occurred. Please try again");
      }
    } catch (error) {
      console.error("Error in cancelRequests:", error);
      return []; // Return an empty array in case of an error
    } finally {
      hideLoader();
    }
  };

  const ReRouteEmployeeRequests = async () => {
    if (reRouteRequestRemark?.trim() === "") {
      notify(1, 'Remarks are mandatory');
      return;
    };

    const WorkflowInstanceId = infoState.tableData[infoState.rowIndex].id;
    const TransactionId = infoState.tableData[infoState.rowIndex].rid;
    const RequestId = infoState.tableData[infoState.rowIndex].employeeId;
    const Event = infoState.tableData[infoState.rowIndex].event;
    const EventReason = infoState.tableData[infoState.rowIndex].eventReason;

    let PositionId = null;
    if (
      infoState.tableData[infoState.rowIndex].moduleProcessName ===
      "PositionManagement"
    ) {
      PositionId = infoState.tableData[infoState.rowIndex].tid;
    }

    if (isEmpty(approverFromValue)) {
      notify(1, "Please select Approver");
    } else {
      if (isEmpty(approverToValue)) {
        notify(1, "Please select Employee");
      } else {
        try {
          showLoader();

          const response = await APICall(ReRouteRequests, "POST", {
            WorkflowInstanceId: WorkflowInstanceId,
            approverFromValue: approverFromValue?.value,
            UserId: userDetails?.Id,
            approverToValue: approverToValue?.value,
            reRouteAllRequests: reRouteAllRequests ? 1 : 0,
            Remark: reRouteRequestRemark,
            TransactionId: TransactionId,
            RequestId: RequestId,
            PositionId: PositionId,
            Event: Event,
            EventReason: EventReason,
          });

          if (response.message == "Ok") {
            notify(0, "Request Re-routed Successfully");
            setShowModal(false);
            setShowPopover(false);
            setInfoState({})
            setSelectedId(undefined);
            await getGridStructureTwo();
          } else {
            notify(1, "Some error occurred. Please try again");
          }
        } catch (error) {
          console.error("Error in cancelRequests:", error);
          return []; // Return an empty array in case of an error
        } finally {
          hideLoader();
        }
      }
    }
  };

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    // page: start,
    rowsPerPage: pageSize,
    serverSide: true,
    rowsPerPageOptions: pageSizeOptions,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        setSortColumn(sortColumn);
        setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        setSortColumn(sortColumn);
        setSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      setStart(page * pageSize);
      if (activetab === "My Action") {
        setSelectedIds([]);
        setrowDataCount(0);
      }
      // setStart(page);
    },
    onChangeRowsPerPage: (newPageSize) => handlePageSizeChange(newPageSize), // Handle pagesize change
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const handleCancelRequest = () => {
    setShowCancelModal(false);
    setCancelRequestRemark("");
    setShowPopover(false);
    setInfoState({})
  };

  // const cancelPopover = (
  //   <Popover>
  //     <Modal
  //       show={showCancelModal}
  //       size="lg"
  //       aria-labelledby="contained-modal-title-vcenter"
  //       centered
  //       backdrop="static"
  //       keyboard={false}>
  //       <Modal.Body>
  //         <div>
  //           <div className="row">
  //             <div className="col-md-12">
  //               <div>
  //                 <label className="col-form-label" htmlFor="dropdown25">
  //                 Are you sure you want to cancel the Permenantly cancel this request?
  //                 </label>
  //               </div>
  //             </div>
  //           </div>
  //           <div className="row">
  //             <div className="col-md-12">
  //               <div className="form-group">
  //                 <label className="col-form-label" htmlFor="dropdown23">
  //                   Remarks <sup>*</sup>
  //                 </label>
  //                 <InputForm
  //                   id="dropdown23"
  //                   value={cancelRequestRemark}
  //                   textArea={true}
  //                   placeholder="Cancel Reason/Remarks"
  //                   onChange={(event) => {
  //                     setCancelRequestRemark(event.target.value);
  //                   }}
  //                   isDisabled={false}
  //                 />
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </Modal.Body>
  //       <Modal.Footer>
  //         <Button onClick={handleCancelRequest}>Cancel</Button>
  //         <Button
  //           onClick={cancelEmployeeRequests}>
  //           OK
  //         </Button>
  //       </Modal.Footer>
  //     </Modal>
  //   </Popover>
  // );

  const handleCancel = () => {
    setShowModal(false);
    setReRouteRequestRemark("");
    setApproverToValue(null);
    setApproverFromValue(null);
    setShowPopover(false);
    setInfoState({});
    setReRouteAllRequests(false);
  };

  const handleCheckBoxChange = async (event) => {
    setReRouteAllRequests(event.target.checked);
    if(event.target.checked) {
      try {
        showLoader();
  
        const response = await APICall(GetAimedRequestsCount, "POST", {
          approverFromValue: approverFromValue?.value,
        });
  
        if (response.message == "Ok") {
          //notify(0, "Request Re-routed Successfully");
          setAimedRequestsCount(response.data.aimedRequestsCount);
        } else {
          notify(1, "Some error occurred. Please try again");
        }
      } catch (error) {
        console.error("Error in cancelRequests:", error);
        return []; // Return an empty array in case of an error
      } finally {
        hideLoader();
      }
    }
  };

  const handlePendingRequestsExcel = async () => {
    try {
      showLoader();

      const response = await APICall(getAimedRequestsExcel, "POST", {
        approverFromValue: approverFromValue?.value,
      });

      if (response.message == "Ok") {
        const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response?.data || ''}`;
        const fileName = `reroute requests.xlsx`;

        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.target = '_blank';
        downloadLink.download = fileName;
        downloadLink.click();

      } else {
        notify(1, "Some error occurred. Please try again");
      }
    } catch (error) {
      console.error("Error in cancelRequests:", error);
      return []; // Return an empty array in case of an error
    } finally {
      hideLoader();
    }
  }

  // const reRoutePopover = (
  //   <Popover>
  //     <Modal
  //       show={showModal}
  //       size="lg"
  //       aria-labelledby="contained-modal-title-vcenter"
  //       centered
  //       backdrop="static"
  //       keyboard={false}>
  //       <Modal.Body>
  //         <div>
  //           <div className="row">
  //             <div className="col-md-4">
  //               <label className="col-form-label">
  //                 Record ID:{" "}
  //                 {infoState && infoState.rowData ? infoState.rowData[1] : ""}
  //               </label>
  //             </div>
  //             <div className="col-md-8">
  //               <label className="col-form-label">
  //                 Activity:{" "}
  //                 {infoState && infoState.rowData ? infoState.rowData[3] : ""}
  //               </label>
  //             </div>
  //           </div>
  //           <div className="row">
  //             <div className="col-md-6">
  //               <div className="form-group">
  //                 <label className="col-form-label" htmlFor="dropdown24">
  //                   Approval Pending With:<sup>*</sup>
  //                 </label>

  //                 <SelectForm
  //                   isClearable
  //                   options={reRouteFromApprovers}
  //                   placeholder={"Select Approver"}
  //                   isDisabled={false}
  //                   onChange={(event) => {
  //                     handleOnChange("ReRouteApproverFrom", event);
  //                   }}
  //                   isMulti={false}
  //                   noIndicator={false}
  //                   noSeparator={false}
  //                   value={approverFromValue}
  //                 />
  //                 <div className="text-danger">{}</div>
  //               </div>
  //             </div>
  //             <div className="col-md-6">
  //               <div className="form-group">
  //                 <label className="col-form-label" htmlFor="dropdown24">
  //                   Re-route To:<sup>*</sup>
  //                 </label>
  //                 <SelectForm
  //                   async
  //                   isClearable
  //                   value={approverToValue}
  //                   placeholder="Select Employee"
  //                   options={(searchString, cb) =>
  //                     handleFetchUsers(
  //                       searchString,
  //                       cb,
  //                       "ReRouteApproverTo",
  //                       null
  //                     )
  //                   }
  //                   noIndicator={false}
  //                   noSeparator={false}
  //                   onChange={(event) => {
  //                     handleOnChange("ReRouteApproverTo", event);
  //                   }}
  //                 />
  //                 <div className="text-danger">{}</div>
  //               </div>
  //             </div>
  //           </div>
  //           <div className="row">
  //             <div className="col-md-12">
  //               <div>
  //                 <input
  //                   type="checkbox"
  //                   id="chk25"
  //                   className="mr-2"
  //                   disabled = {isEmpty(approverFromValue)}
  //                   checked={reRouteAllRequests}
  //                   onChange={(event) => {
  //                     handleCheckBoxChange(event);
  //                   }}
  //                 />
  //                 <label className="col-form-label" htmlFor="dropdown25">
  //                   Would you like to redirect all pending requests from the selected users?
  //                 </label>
  //               </div>
  //             </div>
  //           </div>
  //           {reRouteAllRequests && (
  //             <div className="row">
  //               <div className="col-md-8">
  //                 <div>
  //                   <label className="col-form-label" htmlFor="dropdown25">
  //                     Total {aimedRequestsCount} requests will be impacted.
  //                   </label>
  //                 </div>
  //               </div>
  //               <div className="col-md-4">
  //                 <Button onClick={handlePendingRequestsExcel}>
  //                   <i className="fa fa-download mr-2"></i> 
  //                   Download Excel</Button>{' '}
  //               </div>
  //             </div>
  //           )}
  //           <div className="row">
  //             <div className="col-md-12">
  //               <div className="form-group">
  //                 <label className="col-form-label" htmlFor="dropdown23">
  //                   Remarks <sup>*</sup>
  //                 </label>
  //                 <InputForm
  //                   id="dropdown23"
  //                   value={reRouteRequestRemark}
  //                   textArea={true}
  //                   placeholder="Re-route Reason/Remarks"
  //                   onChange={(event) => {
  //                     setReRouteRequestRemark(event.target.value);
  //                   }}
  //                   isDisabled={false}
  //                 />
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </Modal.Body>
  //       <Modal.Footer>
  //         <Button onClick={handleCancel}>Cancel</Button>
  //         <Button
  //           onClick={async () => {
  //             await ReRouteEmployeeRequests();
  //           }}>
  //           OK
  //         </Button>
  //       </Modal.Footer>
  //     </Modal>
  //   </Popover>
  // );

  const popover = (
    <Popover>
      <Popover.Body>
        {interfacePermissionRecord?.some(
          (e) => e.interfaceName === "CancelRequests"
        ) && (
              <div>
                <i className="fa fa-times mr-2"></i>
                <a
                  className="ft-16"
                  style={{
                    textDecoration: "none",
                  }}
                  onClick={() => {
                    setShowCancelModal(true);
                    setShowPopover(false);
                    // setHidePopover(true);
                  }}>
                  Cancel
                </a>
              </div>
        )}
        {interfacePermissionRecord?.some(
          (e) => e.interfaceName === "ReRouteRequests"
        ) && (
              <div>
                <i className="fa fa-exchange mr-2"></i>
                <a
                  className="ft-16"
                  style={{
                    textDecoration: "none",
                  }}
                  onClick={async () => {
                    const dataforpendingapp = await getDropdownsData(
                      "ReRouteApproverFrom",
                      infoState.tableData[infoState.rowIndex]
                        .pendingWithUserId || "",
                      null
                    );
                    setReRouteFromApprovers(dataforpendingapp);
                    setApproverFromValue(
                      dataforpendingapp?.length > 1
                        ? null
                        : dataforpendingapp?.[0]
                    );
                    setShowModal(true);
                    setShowPopover(false);
                    // setHidePopover(true);
                  }}>
                  Re-route
                </a>
              </div>
        )}
      </Popover.Body>
    </Popover>
  );

  const getHistoryData = async (tableMeta) => {
    showLoader();
    setstoreTableMeta(tableMeta);
    setRowData2([]);
    showLoader();
console.log('tableMeta',tableMeta);
    const TransactionId = tableMeta.tableData[tableMeta.rowIndex].rid;
    const RequestId = tableMeta.tableData[tableMeta.rowIndex].employeeId;
    const PositionId = tableMeta.tableData[tableMeta.rowIndex].positionId;
    console.log(tableMeta);
    try {
      const GetDashboardDataTwo = await APICall(
        getEmployeeHistoryData,
        "POST",
        {
          EId: RequestId,
          TransId: TransactionId,
          sortColumn: sortColumn2,
          sortOrder: sortDirection2,
          pageNumber: page2,
          PageSize: updatedpageSize,
          PositionId: PositionId,
        }
      );

      if (GetDashboardDataTwo.data && GetDashboardDataTwo.data.length > 0) {
        GetDashboardDataTwo.data.map((item) => {
          let modifiedDateUTC = item?.modifiedDate;
          const dateRS = moment.utc(modifiedDateUTC).local();
          const formattedDateRS = moment(dateRS).format("DD MMM YYYY");
          item.modifiedDate = formattedDateRS;

          if(item.section !== 'Position'){
            const dateAo = moment.utc(modifiedDateUTC).local();
            const formattedDateAO = moment(dateAo).format("hh:mm A");
            item.modifiedTime = formattedDateAO;
          }

          const dateED = moment.utc(item?.effectiveDate).local();
          const formattedDateED =  
            moment(dateED, "DD MMM YYYY").isValid() 
              ? item.section === 'Position'
                ? moment(item?.effectiveDate).format("DD MMM YYYY hh:mm:ss A") 
                : moment(dateED).format("DD MMM YYYY") 
              : '-';
          item.effectiveDate = formattedDateED;
        });

        setRowData2(GetDashboardDataTwo.data);
        setCount2(GetDashboardDataTwo.data[0].count);
      } else {
        setRowData2([]);
        //setRowInx(0);
      }
    } catch (error) {
      console.error("Error fetching grid data:", error);
      hideLoader();
       setShowHistoryModal(false);
       setstoreTableMeta("");
      setRowData2([]);
    }
    hideLoader();

    //setShowFormModal(true);
  };

  const redirectForView = (value, tableMeta) => {
    const workflowTaskId =
      tableMeta.tableData[tableMeta.rowIndex].workflowTaskId;
    const taskStatus = tableMeta.tableData[tableMeta.rowIndex].status;
    const TId = Number(tableMeta.tableData[tableMeta.rowIndex].tid);
    const WorkflowInstanceStatus =
      tableMeta.tableData[tableMeta.rowIndex].status;
    const WorkflowSystemName =
      tableMeta.tableData[tableMeta.rowIndex].systemName;
    const CandidateId = tableMeta.tableData[tableMeta.rowIndex].candidateId;
    const ModuleProcessName =
      tableMeta.tableData[tableMeta.rowIndex].moduleProcessName;
    const isEvaluator = tableMeta.tableData[tableMeta.rowIndex].isEvaluator;

    if (ModuleProcessName === "Progression" && tableMeta.tableData[tableMeta.rowIndex].employeeType==="Employee") {
      return (
        <EditOrView type="view" url="/requisitionview" id={workflowTaskId} />
      );
    }
    else if (ModuleProcessName === "Progression" && tableMeta.tableData[tableMeta.rowIndex].employeeType!=="Employee") {
      return (
        <EditOrView type="view" url="/requisitionviewclm" id={workflowTaskId} />
      );
    }
    else if (ModuleProcessName === "EssMss" && tableMeta.tableData[tableMeta.rowIndex].employeeType==="Employee") {
      return (
        <EditOrView type="view" url="/requisitionview" id={workflowTaskId} />
      );
    } 
    else if (ModuleProcessName === "EssMss" && tableMeta.tableData[tableMeta.rowIndex].employeeType!=="Employee") {
      return (
        <EditOrView type="view" url="/requisitionviewclm" id={workflowTaskId} />
      );
    } 

    else if (ModuleProcessName === "EmployeeCentral") {
      return (
        <Tooltip title="view">
          <i
            style={{ marginRight: "0px", cursor: "pointer" }}
            onClick={() => {
              newHireApproved(value, tableMeta);
            }}
            className="fas fa-eye"></i>
        </Tooltip>
      );
    } else if (ModuleProcessName === "PositionManagement") {
      return (
        <EditOrView
          type="view"
          url="/positionManagement"
          id={workflowTaskId}
          TId={TId}
          action="view"
        />
      );
    } else if (ModuleProcessName === "ManpowerRequisition") {
      if (WorkflowSystemName === "CandidateApproval") {
        const commonProps = {
          type: "view",
          url: "/mrf",
          id: workflowTaskId,
          TId: TId,
          sendback: false,
          Tab: "ManpowerCandidates",
          CandidateId: CandidateId,
          isEvaluator,
        };

        return <EditOrView {...commonProps} />;
      } else {
        const commonProps = {
          type: taskStatus === 2 ? "view" : "edit",
          url: "/mrf",
          id: workflowTaskId,
          TId: TId,
          sendback: false,
          Tab: "ManpowerProcess",
        };

        return <EditOrView {...commonProps} />;
      }
    }
    // else if (ModuleProcessName == "OnBoarding") {
    //     if (WorkflowSystemName === "OnBoardingShowNoShow") {
    //       const commonProps = {
    //         type: taskStatus === 2 ? "view" : "edit",
    //         url: "/mrf",
    //         id: workflowTaskId,
    //         TId: TId,
    //         sendback: false,
    //         Tab: "ManpowerCandidates",
    //         CandidateId: CandidateId,
    //       };

    //       return <EditOrView {...commonProps} />;
    //     } else {
    //       if (seqNo == "1") {
    //         return (
    //           <EditOrView
    //             type="edit"
    //             url="/OnBoardingCheckList"
    //             id={workflowTaskId}
    //           />
    //         );
    //       } else if (seqNo == "2") {
    //         if (Task?.trim() === "Review Candidate Data") {
    //           return (
    //             <EditOrView
    //               type="edit"
    //               url="/CandidateFormReview"
    //               id={TId}
    //               workflowTaskId={workflowTaskId}
    //               outcome={Outcome}
    //             />
    //           );
    //         } else {
    //           return (
    //             <EditOrView
    //               type="edit"
    //               url="/OnBoardingCheckList/ApprovalDashboard"
    //               id={workflowTaskId}
    //             />
    //             //  <EditOrView {...commonProps} />
    //           );
    //         }
    //       }
    //     }
    // }
    else {
      return (
        <Tooltip title="View">
          <i
            style={{ marginRight: "0px", cursor: "pointer" }}
            onClick={() => {
              edit(value, tableMeta);
            }}
            className="fas fa-eye"></i>
        </Tooltip>
      );
    }
  };

  const columns2 = [
    { name: "id", label: "id", options: { display: false } }, // 0
    {
      name: "modifiedDate", //1
      label: "Modified Date",
        options: { display: true, sort: false },
    },
    {
      name: "modifiedTime", //2
      label: "Modified Time",
        options: { display: true, sort: false },
    },
    {
      name: "event", //3
      label: "Event",
      options: { display: true, sort: false },
    },
      { name: "eventReason", label: "Event Reason", options: { display: true, sort: false } }, //4
    {
      name: "section", //5
      label: "Sections",
      options: { display: true, sort: false },
    },
      { name: "status", label: "Status", options: { display: true, sort: false } }, //6
    {
      name: "modifiedByUser", //7
      label: "Modified By",
      options: { display: true, sort: false },
      },
      {
          name: "effectiveDate",
          label: "Event Effective Date",
          options: { display: true, sort: false },
      },//new field added
    {
      name: "remarks", //8
      label: "Remarks",
      options: { display: true, sort: false },
    },
    // {
    //   name: "oldApprover",
    //   label: "Old Approver",
    //   options: { display: true },
    // }, //8
    // {
    //   name: "newApprover", //9
    //   label: "New Approver",
    //   options: { display: true, sort: false },
    // },

      { name: "rowIndex", label: "roxInx", options: { display: false, sort: false } }, //10
  ];

  const options2 = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count2,
    rowsPerPage: updatedpageSize,
    //page: page2,
    serverSide: true,
    onChangePage: async (page) => {
      setPage2(page);
    },
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",
    onColumnSortChange: async (sortColumn2, sortDirection2) => {
      setPage2(0);
      if (sortDirection2 === "asc") {
        await setSortColumn2(sortColumn2);
        await setSortDirection2(sortDirection2);
      }
      if (sortDirection === "desc") {
        await setSortColumn2(sortColumn2);
        await setSortDirection2(sortDirection2);
      }
    },
  };

  let columnsWithCustomRender: any = "";

  if (activetab === "Manage Transactions") {
    {console.log('gridColums', gridData?.data?.d?.s[1]?.columnData)}
    columnsWithCustomRender = gridData?.data?.d?.s[1]?.columnData.map(
      (column) => {
        if (column.name === "action") {
          return {
            ...column,
            options: {
              ...column.options,
              customBodyRender: (value, tableMeta, updateValue) => {
                const workflowTaskId =
                  tableMeta.tableData[tableMeta.rowIndex].workflowTaskId;
                const taskStatus =
                  tableMeta.tableData[tableMeta.rowIndex].status;
                const TId = Number(tableMeta.tableData[tableMeta.rowIndex].tid);
                const WorkflowInstanceStatus =
                  tableMeta.tableData[tableMeta.rowIndex].status;
                const WorkflowSystemName =
                  tableMeta.tableData[tableMeta.rowIndex].systemName;
                const CandidateId =
                  tableMeta.tableData[tableMeta.rowIndex].candidateId;
                const ModuleProcessName =
                  tableMeta.tableData[tableMeta.rowIndex].moduleProcessName;
                const isEvaluator =
                  tableMeta.tableData[tableMeta.rowIndex].isEvaluator;

                const commonPropsCandidateApproval = {
                  type: taskStatus === 2 ? "view" : "edit",
                  url: "/mrf",
                  id: workflowTaskId,
                  TId: TId,
                  sendback: false,
                  Tab: "ManpowerCandidates",
                  CandidateId: CandidateId,
                  isEvaluator,
                };

                const commonPropsElseManpower = {
                  type: taskStatus === 2 ? "view" : "edit",
                  url: "/mrf",
                  id: workflowTaskId,
                  TId: TId,
                  sendback: false,
                  Tab: "ManpowerProcess",
                };

                return (
                  <>
                    <div
                      className="centerStyles flex-justify-start"
                      style={{ padding: "0 8px 0 0" }}>
                      <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                        {(ModuleProcessName === "Progression" ||
                        ModuleProcessName === "EssMss") && tableMeta.tableData[tableMeta.rowIndex].employeeType==="Employee" ? (
                          <>
                            <EditOrView
                              type="view"
                              url="/requisitionview"
                              id={workflowTaskId}
                            />
                            <div
                              className="centerStyles flex-justify-start"
                              style={{ padding: "0 8px 0 0" }}>
                              <Tooltip title="History">
                                <i
                                  style={{
                                    marginRight: "0px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setShowHistoryModal(true);
                                    getHistoryData(tableMeta);
                                  }}
                                  className="fas fa-history"></i>
                              </Tooltip>
                            </div>
                            <Modal
                             className="customized-history-modal-modified"
                              show={showHistoryModal}
                              onHide={() => setShowHistoryModal(false)}
                              //size="xl"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              backdrop="static"
                              keyboard={false}
                              fullscreen="md-down"
                              scrollable={true}>
                              <Modal.Body>
                                <div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <DynamicGrid
                                        data={rowData2}
                                        columns={columns2}
                                        options={options2}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  className="btn customButtonCancel"
                                  style={{ marginLeft: 5 }}
                                  onClick={() => {
                                     setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                  }}>
                                  Close
                                </button>
                              </Modal.Footer>
                            </Modal>
                          </>
                        )
                        : (ModuleProcessName === "Progression" ||
                        ModuleProcessName === "EssMss") && tableMeta.tableData[tableMeta.rowIndex].employeeType!=="Employee" ? (
                          <>
                            <EditOrView
                              type="view"
                              url="/requisitionviewclm"
                              id={workflowTaskId}
                            />
                            <div
                              className="centerStyles flex-justify-start"
                              style={{ padding: "0 8px 0 0" }}>
                              <Tooltip title="History">
                                <i
                                  style={{
                                    marginRight: "0px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setShowHistoryModal(true);
                                    getHistoryData(tableMeta);
                                  }}
                                  className="fas fa-history"></i>
                              </Tooltip>
                            </div>
                            <Modal
                             className="customized-history-modal-modified"
                              show={showHistoryModal}
                              onHide={() => setShowHistoryModal(false)}
                              //size="xl"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              backdrop="static"
                              keyboard={false}
                              fullscreen="md-down"
                              scrollable={true}>
                              <Modal.Body>
                                <div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <DynamicGrid
                                        data={rowData2}
                                        columns={columns2}
                                        options={options2}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  className="btn customButtonCancel"
                                  style={{ marginLeft: 5 }}
                                  onClick={() => {
                                     setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                  }}>
                                  Close
                                </button>
                              </Modal.Footer>
                            </Modal>
                          </>
                        )
                        
                        : ModuleProcessName === "EmployeeCentral" ? (
                          <>
                            <div
                              className="centerStyles flex-justify-start"
                              style={{ padding: "0 8px 0 0" }}>
                              <Tooltip title="View">
                                <i
                                  style={{
                                    // marginRight: "4px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    newHireApproved(value, tableMeta);
                                  }}
                                  className="fas fa-eye"></i>
                              </Tooltip>
                            </div>
                            <div
                              className="centerStyles flex-justify-start"
                              style={{ padding: "0 8px 0 0" }}>
                              <Tooltip title="History">
                                <i
                                  style={{
                                    marginRight: "0px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setShowHistoryModal(true);
                                    getHistoryData(tableMeta);
                                  }}
                                  className="fas fa-history"></i>
                              </Tooltip>
                            </div>
                            <Modal
                              className="customized-history-modal-modified"
                              show={showHistoryModal}
                              onHide={() => setShowHistoryModal(false)}
                              //size="xl"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              backdrop="static"
                              keyboard={false}
                              fullscreen="md-down"
                              scrollable={true}>
                              <Modal.Body>
                                <div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <DynamicGrid
                                        data={rowData2}
                                        columns={columns2}
                                        options={options2}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  className="btn customButtonCancel"
                                  style={{ marginLeft: 5 }}
                                  onClick={() => {
                                     setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                  }}>
                                  Close
                                </button>
                              </Modal.Footer>
                            </Modal>
                          </>
                        ) : ModuleProcessName === "PositionManagement" ? (
                          <>
                            <EditOrView
                              type="view"
                              url="/positionManagement"
                              id={workflowTaskId}
                              TId={TId}
                              action="view"
                            />
                            <div
                              className="centerStyles flex-justify-start"
                              style={{ padding: "0 8px 0 0" }}>
                              <Tooltip title="History">
                                <i
                                  style={{
                                    marginRight: "0px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setShowHistoryModal(true);
                                    getHistoryData(tableMeta);
                                  }}
                                  className="fas fa-history"></i>
                              </Tooltip>
                            </div>
                            <Modal
                            className="customized-history-modal-modified"
                              show={showHistoryModal}
                              onHide={() => setShowHistoryModal(false)}
                              //size="xl"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              backdrop="static"
                              keyboard={false}
                              fullscreen="md-down"
                              scrollable={true}>
                              <Modal.Body>
                                <div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <DynamicGrid
                                        data={rowData2}
                                        columns={columns2}
                                        options={options2}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  className="btn customButtonCancel"
                                  style={{ marginLeft: 5 }}
                                  onClick={() => {
                                     setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                  }}>
                                  Close
                                </button>
                              </Modal.Footer>
                            </Modal>
                          </>
                        ) : ModuleProcessName === "ManpowerRequisition" &&
                          WorkflowSystemName === "CandidateApproval" ? (
                          <EditOrView {...commonPropsCandidateApproval} />
                        ) : ModuleProcessName === "ManpowerRequisition" ? (
                          <EditOrView {...commonPropsElseManpower} />
                        ) : (
                          <>
                            <div
                              className="centerStyles flex-justify-start"
                              style={{ padding: "0 8px 0 0" }}>
                              <Tooltip title="View">
                                <i
                                  style={{
                                    marginRight: "0px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    edit(value, tableMeta);
                                  }}
                                  className="fas fa-eye"></i>
                              </Tooltip>
                            </div>
                            <div
                              className="centerStyles flex-justify-start"
                              style={{ padding: "0 8px 0 0" }}>
                              <Tooltip title="History">
                                <i
                                  style={{
                                    marginRight: "0px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setShowHistoryModal(true);
                                    getHistoryData(tableMeta);
                                  }}
                                  className="fas fa-history"></i>
                              </Tooltip>
                            </div>
                            <Modal
                            className="customized-history-modal-modified"
                              show={showHistoryModal}
                              onHide={() => setShowHistoryModal(false)}
                              //size="xl"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              backdrop="static"
                              keyboard={false}
                              fullscreen="md-down"
                              scrollable={true}>
                              <Modal.Body>
                                <div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <DynamicGrid
                                        data={rowData2}
                                        columns={columns2}
                                        options={options2}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  className="btn customButtonCancel"
                                  style={{ marginLeft: 5 }}
                                  onClick={() => {
                                     setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                  }}>
                                  Close
                                </button>
                              </Modal.Footer>
                            </Modal>
                          </>
                        )}
                        {(tableMeta.rowData[4] === "InProgress" ||
                          tableMeta.rowData[4] === "Inprogress" ||
                          tableMeta.rowData[4] === "InProgess" ||
                          tableMeta.rowData[4] === "Pending") && !hideCheckboxArr.includes(tableMeta?.tableData?.[tableMeta.rowIndex]?.moduleProcessName) 
                          && (
                          <>
                            <div
                              className="centerStyles flex-justify-start"
                              style={{ padding: "0 8px 0 0" }}>
                              <OverlayTrigger
                                trigger="click"
                                // delay={{ show: 250, hide: 800 }}
                                placement="bottom"
                                rootClose
                                overlay={
                                  selectedId === tableMeta?.rowData?.[0] ? (
                                    popover
                                  ) : (
                                    <></>
                                  )
                                }
                                onEnter={() => setInfoState(tableMeta)}
                                // onExited={() => setInfoState({})}
                                show={showPopover}
                                >
                                <Tooltip title="Manage Transaction">
                                 {interfacePermissionRecord?.some(
                                    (e) => e.interfaceName === "CancelRequests"
                                  ) || interfacePermissionRecord?.some(
                                    (e) => e.interfaceName === "ReRouteRequests"
                                  ) ? 
                                  <i
                                    style={{
                                      marginRight: "0px",
                                      cursor: "pointer",
                                      fontSize: "20px",
                                    }}
                                    onClick={() => {
                                      setShowPopover(!showPopover);
                                      setInfoState(showPopover && {})
                                      // setHidePopover(true);
                                      setSelectedId(tableMeta?.rowData?.[0]);
                                    }}
                                    className="fas fa-ellipsis-h"></i> 
                                    : null}
                                </Tooltip>
                              </OverlayTrigger>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                );
              },
            },
          };
        }
        return column;
      }
    );
  } else {
    const originalColumns = gridData?.data?.d?.s[0]?.columnData;
    columnsWithCustomRender = [
      {
        name: "selected",
        label: (
          <>
            <p>Select All</p>
            <div>
              <input
                checked={
                  selectedIds?.length > 0 &&
                  (selectedIds || []).length === rowDataCount
                }
                type="checkbox"
                onChange={(e) => handleSelectAll(e)}
              />
            </div>
          </>
        ),
        options: {
          display: activetab === "My Action" && rowData?.length > 0,
          filter: false,
          sort: false,
          sortDescFirst: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const moduleProcessName =
              tableMeta.tableData[tableMeta.rowIndex].moduleProcessName;
            return (
              <input
                type="checkbox"
                checked={value}
                disabled={hideCheckboxArr.includes(moduleProcessName)}
                onChange={(e) =>
                  handleCheckboxChange(e, tableMeta.rowData, !value)
                }
              />
            );
          },
        },
      },
      ...(originalColumns || []).map((column) => {
        if (column.name === "action") {
          return {
            ...column,
            options: {
              ...column.options,
              customBodyRender: (value, tableMeta, updateValue) => {
                const workflowTaskId =
                  tableMeta.tableData[tableMeta.rowIndex].id;
                const taskStatus =
                  tableMeta.tableData[tableMeta.rowIndex].taskStatus;
                const process = tableMeta.tableData[tableMeta.rowIndex].process;
                const seqNo = tableMeta.tableData[tableMeta.rowIndex].seqNo;
                const TId = Number(tableMeta.tableData[tableMeta.rowIndex].tid);
                const WorkflowInstanceStatus =
                  tableMeta.tableData[tableMeta.rowIndex].recordStatus;
                const WorkflowSystemName =
                  tableMeta.tableData[tableMeta.rowIndex].systemName;
                const newHireStatus =
                  tableMeta.tableData[tableMeta.rowIndex].newHireStatus;
                const RoleName =
                  tableMeta.tableData[tableMeta.rowIndex].roleSystemName;
                const CandidateId =
                  tableMeta.tableData[tableMeta.rowIndex].candidateId;
                const ModuleProcessName =
                  tableMeta.tableData[tableMeta.rowIndex].moduleProcessName;
                const EmployeeIdForEss =
                  tableMeta.tableData[tableMeta.rowIndex].employeeIdForEss;
                const isEvaluator =
                  tableMeta.tableData[tableMeta.rowIndex].isEvaluator;
                const Outcome = tableMeta.tableData[tableMeta.rowIndex].outcome;
                const Task = tableMeta.tableData[tableMeta.rowIndex].task;
                const Sendback =
                  String(WorkflowInstanceStatus).toLocaleLowerCase().trim() ===
                  "sendback";
                  //console.log("newHireStatus", rowData);
                const commonProps = {
                  type: taskStatus === 2 ? "view" : "edit",
                  url: "/mrf",
                  id: workflowTaskId,
                  TId: TId,
                  sendback: Sendback,
                  Tab: "ManpowerCandidates",
                  CandidateId: CandidateId,
                };
        //console.log("newHireStatus",newHireStatus);
                if (ModuleProcessName === "Progression") {
                  if (activetab == "My Request") {
                    if (seqNo == 1) {
                      if (taskStatus == 1) {
                        return (
                          <>
                            <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                            {tableMeta.tableData[tableMeta.rowIndex].employeeType==="Employee" ?
                             <EditOrView
                             type="edit"
                             url="/sendback"
                             id={workflowTaskId}
                           />
                            :
                            <EditOrView
                            type="edit"
                            url="/sendbackclm"
                            id={workflowTaskId}
                          />} 
                              <Tooltip title="History">
                                <i
                                  style={{
                                    marginRight: "0px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setShowHistoryModal(true);
                                    getHistoryData(tableMeta);
                                  }}
                                  className="fas fa-history"></i>
                              </Tooltip>
                              <Modal
                              className="customized-history-modal-modified"
                                show={showHistoryModal}
                                onHide={() => setShowHistoryModal(false)}
                                //size="xl"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                backdrop="static"
                                keyboard={false}
                                fullscreen="md-down"
                                scrollable={true}>
                                <Modal.Body>
                                  <div>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <DynamicGrid
                                          data={rowData2}
                                          columns={columns2}
                                          options={options2}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Modal.Body>
                                <Modal.Footer>
                                  <button
                                    className="btn customButtonCancel"
                                    style={{ marginLeft: 5 }}
                                    onClick={() => {
                                       setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                    }}>
                                    Close
                                  </button>
                                </Modal.Footer>
                              </Modal>
                            </div>
                          </>
                        );
                      } else {
                        return (
                          <>
                            <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                            {tableMeta.tableData[tableMeta.rowIndex].employeeType==="Employee" ?
                              (
                              <EditOrView
                                type="view"
                                url="/requisitionview"
                                id={workflowTaskId}
                              />) : (
                                <EditOrView
                                type="view"
                                url="/requisitionviewclm"
                                id={workflowTaskId}/>
                              )}
                              <Tooltip title="History">
                                <i
                                  style={{
                                    marginRight: "0px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setShowHistoryModal(true);
                                    getHistoryData(tableMeta);
                                  }}
                                  className="fas fa-history"></i>
                              </Tooltip>
                              <Modal
                              className="customized-history-modal-modified"
                                show={showHistoryModal}
                                onHide={() => setShowHistoryModal(false)}
                                //size="xl"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                backdrop="static"
                                keyboard={false}
                                fullscreen="md-down"
                                scrollable={true}>
                                <Modal.Body>
                                  <div>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <DynamicGrid
                                          data={rowData2}
                                          columns={columns2}
                                          options={options2}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Modal.Body>
                                <Modal.Footer>
                                  <button
                                    className="btn customButtonCancel"
                                    style={{ marginLeft: 5 }}
                                    onClick={() => {
                                       setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                    }}>
                                    Close
                                  </button>
                                </Modal.Footer>
                              </Modal>
                            </div>
                          </>
                        );
                      }
                    }
                  } else if (activetab == "My Action") {
                    if (seqNo == 1) {
                      return (
                        <>
                          <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                           {tableMeta.tableData[tableMeta.rowIndex].employeeType==="Employee" ? 
                             <EditOrView
                             type="edit"
                             url="/sendback"
                             id={workflowTaskId}
                           />
                           :
                           <EditOrView
                           type="edit"
                           url="/sendbackclm"
                           id={workflowTaskId}
                         />
                          }
                            <Tooltip title="History">
                              <i
                                style={{
                                  marginRight: "0px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setShowHistoryModal(true);
                                  getHistoryData(tableMeta);
                                }}
                                className="fas fa-history"></i>
                            </Tooltip>
                            <Modal
                            className="customized-history-modal-modified"
                              show={showHistoryModal}
                              onHide={() => setShowHistoryModal(false)}
                              //size="xl"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              backdrop="static"
                              keyboard={false}
                              fullscreen="md-down"
                              scrollable={true}>
                              <Modal.Body>
                                <div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <DynamicGrid
                                        data={rowData2}
                                        columns={columns2}
                                        options={options2}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  className="btn customButtonCancel"
                                  style={{ marginLeft: 5 }}
                                  onClick={() => {
                                     setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                  }}>
                                  Close
                                </button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </>
                      );
                    } else {
                      debugger
                      return (
                        <>
                          <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                            
                          {tableMeta.tableData[tableMeta.rowIndex].employeeType==="Employee" ?
                              (
                                <EditOrView
                                type="edit"
                                url="/reviewerSection"
                                id={workflowTaskId}
                              />) : (
                                <EditOrView
                                type="edit"
                                url="/reviewerSectionclm"
                                id={workflowTaskId}/>
                              )}

                            <Tooltip title="History">
                              <i
                                style={{
                                  marginRight: "0px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setShowHistoryModal(true);
                                  getHistoryData(tableMeta);
                                }}
                                className="fas fa-history"></i>
                            </Tooltip>
                            <Modal
                            className="customized-history-modal-modified"
                              show={showHistoryModal}
                              onHide={() => setShowHistoryModal(false)}
                              //size="xl"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              backdrop="static"
                              keyboard={false}
                              fullscreen="md-down"
                              scrollable={true}>
                              <Modal.Body>
                                <div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <DynamicGrid
                                        data={rowData2}
                                        columns={columns2}
                                        options={options2}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  className="btn customButtonCancel"
                                  style={{ marginLeft: 5 }}
                                  onClick={() => {
                                     setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                  }}>
                                  Close
                                </button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </>
                      );
                    }
                  } else {
                    if (seqNo == 1) {
                      if (taskStatus == 1) {
                        return (
                          <>
                            <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                            {tableMeta.tableData[tableMeta.rowIndex].employeeType==="Employee" ?
                            <EditOrView
                            type="edit"
                            url="/sendback"
                            id={workflowTaskId}
                          />
                            :
                            <EditOrView
                                type="edit"
                                url="/sendbackclm"
                                id={workflowTaskId}
                              />
                            }
                              
                              <Tooltip title="History">
                                <i
                                  style={{
                                    marginRight: "0px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setShowHistoryModal(true);
                                    getHistoryData(tableMeta);
                                  }}
                                  className="fas fa-history"></i>
                              </Tooltip>
                              <Modal
                              className="customized-history-modal-modified"
                                show={showHistoryModal}
                                onHide={() => setShowHistoryModal(false)}
                                //size="xl"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                backdrop="static"
                                keyboard={false}
                                fullscreen="md-down"
                                scrollable={true}>
                                <Modal.Body>
                                  <div>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <DynamicGrid
                                          data={rowData2}
                                          columns={columns2}
                                          options={options2}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Modal.Body>
                                <Modal.Footer>
                                  <button
                                    className="btn customButtonCancel"
                                    style={{ marginLeft: 5 }}
                                    onClick={() => {
                                       setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                    }}>
                                    Close
                                  </button>
                                </Modal.Footer>
                              </Modal>
                            </div>
                          </>
                        );
                      } else {
                        return (
                          <>
                            <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                            {tableMeta.tableData[tableMeta.rowIndex].employeeType==="Employee" ?
                              (
                              <EditOrView
                                type="view"
                                url="/requisitionview"
                                id={workflowTaskId}
                              />) : (
                                <EditOrView
                                type="view"
                                url="/requisitionviewclm"
                                id={workflowTaskId}/>
                              )}
                              <Tooltip title="History">
                                <i
                                  style={{
                                    marginRight: "0px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setShowHistoryModal(true);
                                    getHistoryData(tableMeta);
                                  }}
                                  className="fas fa-history"></i>
                              </Tooltip>
                              <Modal
                              className="customized-history-modal-modified"
                                show={showHistoryModal}
                                onHide={() => setShowHistoryModal(false)}
                                //size="xl"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                backdrop="static"
                                keyboard={false}
                                fullscreen="md-down"
                                scrollable={true}>
                                <Modal.Body>
                                  <div>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <DynamicGrid
                                          data={rowData2}
                                          columns={columns2}
                                          options={options2}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Modal.Body>
                                <Modal.Footer>
                                  <button
                                    className="btn customButtonCancel"
                                    style={{ marginLeft: 5 }}
                                    onClick={() => {
                                       setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                    }}>
                                    Close
                                  </button>
                                </Modal.Footer>
                              </Modal>
                            </div>
                          </>
                        );
                      }
                    } else {
                      debugger
                      return (
                        <>
                          <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                           
                          {tableMeta.tableData[tableMeta.rowIndex].employeeType==="Employee" ?
                              (
                                <EditOrView
                                type={taskStatus == 1 ? "edit" : "view"}
                                url="/reviewerSection"
                                id={workflowTaskId}
                              />) : (
                                <EditOrView
                                type={taskStatus == 1 ? "edit" : "view"}
                                url="/reviewerSectionclm"
                                id={workflowTaskId}/>
                              )}
                           
                            <Tooltip title="History">
                              <i
                                style={{
                                  marginRight: "0px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setShowHistoryModal(true);
                                  getHistoryData(tableMeta);
                                }}
                                className="fas fa-history"></i>
                            </Tooltip>
                            <Modal
                            className="customized-history-modal-modified"
                              show={showHistoryModal}
                              onHide={() => setShowHistoryModal(false)}
                              //size="xl"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              backdrop="static"
                              keyboard={false}
                              fullscreen="md-down"
                              scrollable={true}>
                              <Modal.Body>
                                <div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <DynamicGrid
                                        data={rowData2}
                                        columns={columns2}
                                        options={options2}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  className="btn customButtonCancel"
                                  style={{ marginLeft: 5 }}
                                  onClick={() => {
                                     setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                  }}>
                                  Close
                                </button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </>
                      );
                    }
                  }
                } else if (ModuleProcessName === "EssMss") {
                  if (activetab == "My Request") {
                    if (seqNo == 1) {
                      if (taskStatus == 1) {
                        return (
                          <>
                            <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                              <div
                                className="centerStyles flex-justify-start"
                                style={{ padding: "0 8px 0 0" }}>
                                <Tooltip title="edit">
                                  <i
                                    style={{
                                      marginRight: "0px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      essMssSendback(
                                        value,
                                        EmployeeIdForEss,
                                        tableMeta
                                      );
                                    }}
                                    className="fas fa-edit"></i>
                                </Tooltip>
                              </div>
                              <div
                                className="centerStyles flex-justify-start"
                                style={{ padding: "0 8px 0 0" }}>
                                <Tooltip title="History">
                                  <i
                                    style={{
                                      marginRight: "0px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setShowHistoryModal(true);
                                      getHistoryData(tableMeta);
                                    }}
                                    className="fas fa-history"></i>
                                </Tooltip>
                              </div>
                              <Modal
                              className="customized-history-modal-modified"
                                show={showHistoryModal}
                                onHide={() => setShowHistoryModal(false)}
                               // size="xl"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                backdrop="static"
                                keyboard={false}
                                fullscreen="md-down"
                                scrollable={true}>
                                <Modal.Body>
                                  <div>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <DynamicGrid
                                          data={rowData2}
                                          columns={columns2}
                                          options={options2}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Modal.Body>
                                <Modal.Footer>
                                  <button
                                    className="btn customButtonCancel"
                                    style={{ marginLeft: 5 }}
                                    onClick={() => {
                                       setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                    }}>
                                    Close
                                  </button>
                                </Modal.Footer>
                              </Modal>
                            </div>
                          </>
                        );
                      } else {
                        return (
                          <>
                            <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                            {tableMeta.tableData[tableMeta.rowIndex].employeeType==="Employee" ?
                              (
                              <EditOrView
                                type="view"
                                url="/requisitionview"
                                id={workflowTaskId}
                              />) : (
                                <EditOrView
                                type="view"
                                url="/requisitionviewclm"
                                id={workflowTaskId}/>
                              )}
                              <Tooltip title="History">
                                <i
                                  style={{
                                    marginRight: "0px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setShowHistoryModal(true);
                                    getHistoryData(tableMeta);
                                  }}
                                  className="fas fa-history"></i>
                              </Tooltip>
                              <Modal
                              className="customized-history-modal-modified"
                                show={showHistoryModal}
                                onHide={() => setShowHistoryModal(false)}
                                //size="xl"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                backdrop="static"
                                keyboard={false}
                                fullscreen="md-down"
                                scrollable={true}>
                                <Modal.Body>
                                  <div>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <DynamicGrid
                                          data={rowData2}
                                          columns={columns2}
                                          options={options2}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Modal.Body>
                                <Modal.Footer>
                                  <button
                                    className="btn customButtonCancel"
                                    style={{ marginLeft: 5 }}
                                    onClick={() => {
                                       setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                    }}>
                                    Close
                                  </button>
                                </Modal.Footer>
                              </Modal>
                            </div>
                          </>
                        );
                      }
                    }
                  } else if (activetab == "My Action") {
                    if (seqNo == 1) {
                      return (
                        <>
                          <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                            <div
                              className="centerStyles flex-justify-start"
                              style={{ padding: "0 8px 0 0" }}>
                              <Tooltip title="edit">
                                <i
                                  style={{
                                    marginRight: "0px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    essMssSendback(
                                      value,
                                      EmployeeIdForEss,
                                      tableMeta
                                    );
                                  }}
                                  className="fas fa-edit"></i>
                              </Tooltip>
                            </div>
                            <div
                              className="centerStyles flex-justify-start"
                              style={{ padding: "0 8px 0 0" }}>
                              <Tooltip title="History">
                                <i
                                  style={{
                                    marginRight: "0px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setShowHistoryModal(true);
                                    getHistoryData(tableMeta);
                                  }}
                                  className="fas fa-history"></i>
                              </Tooltip>
                            </div>
                            <Modal
                            className="customized-history-modal-modified"
                              show={showHistoryModal}
                              onHide={() => setShowHistoryModal(false)}
                              //size="xl"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              backdrop="static"
                              keyboard={false}
                              fullscreen="md-down"
                              scrollable={true}>
                              <Modal.Body>
                                <div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <DynamicGrid
                                        data={rowData2}
                                        columns={columns2}
                                        options={options2}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  className="btn customButtonCancel"
                                  style={{ marginLeft: 5 }}
                                  onClick={() => {
                                     setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                  }}>
                                  Close
                                </button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </>
                      );
                    } else {
                      debugger
                      return (
                        <>
                          <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                          {tableMeta.tableData[tableMeta.rowIndex].employeeType==="Employee" ?
                              (
                                <EditOrView
                                type="edit"
                                url="/reviewerSection"
                                id={workflowTaskId}
                              />) : (
                                <EditOrView
                                type="edit"
                                url="/reviewerSectionclm"
                                id={workflowTaskId}/>
                              )}
                            <Tooltip title="History">
                              <i
                                style={{
                                  marginRight: "0px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setShowHistoryModal(true);
                                  getHistoryData(tableMeta);
                                }}
                                className="fas fa-history"></i>
                            </Tooltip>
                            <Modal
                            className="customized-history-modal-modified"
                              show={showHistoryModal}
                              onHide={() => setShowHistoryModal(false)}
                              //size="xl"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              backdrop="static"
                              keyboard={false}
                              fullscreen="md-down"
                              scrollable={true}>
                              <Modal.Body>
                                <div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <DynamicGrid
                                        data={rowData2}
                                        columns={columns2}
                                        options={options2}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  className="btn customButtonCancel"
                                  style={{ marginLeft: 5 }}
                                  onClick={() => {
                                     setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                  }}>
                                  Close
                                </button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </>
                      );
                    }
                  } else {
                    if (seqNo == 1) {
                      if (taskStatus == 1) {
                        return (
                          <>
                            <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                              <div
                                className="centerStyles flex-justify-start"
                                style={{ padding: "0 8px 0 0" }}>
                                <Tooltip title="edit">
                                  <i
                                    style={{
                                      marginRight: "0px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      essMssSendback(
                                        value,
                                        EmployeeIdForEss,
                                        tableMeta
                                      );
                                    }}
                                    className="fas fa-edit"></i>
                                </Tooltip>
                              </div>
                              <div
                                className="centerStyles flex-justify-start"
                                style={{ padding: "0 8px 0 0" }}>
                                <Tooltip title="History">
                                  <i
                                    style={{
                                      marginRight: "0px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setShowHistoryModal(true);
                                      getHistoryData(tableMeta);
                                    }}
                                    className="fas fa-history"></i>
                                </Tooltip>
                              </div>
                              <Modal
                              className="customized-history-modal-modified"
                                show={showHistoryModal}
                                onHide={() => setShowHistoryModal(false)}
                                //size="xl"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                backdrop="static"
                                keyboard={false}
                                fullscreen="md-down"
                                scrollable={true}>
                                <Modal.Body>
                                  <div>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <DynamicGrid
                                          data={rowData2}
                                          columns={columns2}
                                          options={options2}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Modal.Body>
                                <Modal.Footer>
                                  <button
                                    className="btn customButtonCancel"
                                    style={{ marginLeft: 5 }}
                                    onClick={() => {
                                       setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                    }}>
                                    Close
                                  </button>
                                </Modal.Footer>
                              </Modal>
                            </div>
                          </>
                        );
                      } else {
                        return (
                          <>
                            <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                            {tableMeta.tableData[tableMeta.rowIndex].employeeType==="Employee" ?
                              (
                              <EditOrView
                                type="view"
                                url="/requisitionview"
                                id={workflowTaskId}
                              />) : (
                                <EditOrView
                                type="view"
                                url="/requisitionviewclm"
                                id={workflowTaskId}/>
                              )}
                              <Tooltip title="History">
                                <i
                                  style={{
                                    marginRight: "0px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setShowHistoryModal(true);
                                    getHistoryData(tableMeta);
                                  }}
                                  className="fas fa-history"></i>
                              </Tooltip>
                              <Modal
                              className="customized-history-modal-modified"
                                show={showHistoryModal}
                                onHide={() => setShowHistoryModal(false)}
                                //size="xl"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                backdrop="static"
                                keyboard={false}
                                fullscreen="md-down"
                                scrollable={true}>
                                <Modal.Body>
                                  <div>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <DynamicGrid
                                          data={rowData2}
                                          columns={columns2}
                                          options={options2}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Modal.Body>
                                <Modal.Footer>
                                  <button
                                    className="btn customButtonCancel"
                                    style={{ marginLeft: 5 }}
                                    onClick={() => {
                                       setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                    }}>
                                    Close
                                  </button>
                                </Modal.Footer>
                              </Modal>
                            </div>
                          </>
                        );
                      }
                    } else {
                      debugger
                      return (
                        <>
                          <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                            
                          {tableMeta.tableData[tableMeta.rowIndex].employeeType==="Employee" ?
                              (
                                <EditOrView
                                type={taskStatus == 1 ? "edit" : "view"}
                                url="/reviewerSection"
                                id={workflowTaskId}
                              />) : (
                                <EditOrView
                                type={taskStatus == 1 ? "edit" : "view"}
                                url="/reviewerSectionclm"
                                id={workflowTaskId}/>
                              )}
                            
                            
                            <Tooltip title="History">
                              <i
                                style={{
                                  marginRight: "0px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setShowHistoryModal(true);
                                  getHistoryData(tableMeta);
                                }}
                                className="fas fa-history"></i>
                            </Tooltip>
                            <Modal
                            className="customized-history-modal-modified"
                              show={showHistoryModal}
                              onHide={() => setShowHistoryModal(false)}
                              //size="xl"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              backdrop="static"
                              keyboard={false}
                              fullscreen="md-down"
                              scrollable={true}>
                              <Modal.Body>
                                <div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <DynamicGrid
                                        data={rowData2}
                                        columns={columns2}
                                        options={options2}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  className="btn customButtonCancel"
                                  style={{ marginLeft: 5 }}
                                  onClick={() => {
                                     setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                  }}>
                                  Close
                                </button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </>
                      );
                    }
                  }
                } else if (ModuleProcessName === "EmployeeCentral") {
                    if (activetab === "My Request") {
                        //("newHireStatus::", newHireStatus);
                    if (newHireStatus === "Sendback") {
                      // return (
                      //   <>
                      //     <div className="row">
                      //       <div
                      //         className="centerStyles flex-justify-start"
                      //         style={{ padding: "0 8px 0 0" }}>
                      //         <Tooltip title="edit">
                      //           <i
                      //             style={{
                      //               // marginRight: "5px",
                      //               cursor: "pointer",
                      //             }}
                      //             onClick={() => {
                      //               newHireSendback(value, tableMeta);
                      //             }}
                      //             className="fas fa-edit"></i>
                      //         </Tooltip>
                      //       </div>
                      //       <div
                      //         className="centerStyles flex-justify-start"
                      //         style={{ padding: "0 8px 0 0" }}>
                      //         <Tooltip title="History">
                      //           <i
                      //             style={{
                      //               marginRight: "0px",
                      //               cursor: "pointer",
                      //             }}
                      //             onClick={() => {
                      //               setShowHistoryModal(true);
                      //               getHistoryData(tableMeta);
                      //             }}
                      //             className="fas fa-history"></i>
                      //         </Tooltip>
                      //       </div>
                      //       <Modal
                      //       className="customized-history-modal-modified"
                      //         show={showHistoryModal}
                      //         onHide={() => setShowHistoryModal(false)}
                      //         //size="xl"
                      //         aria-labelledby="contained-modal-title-vcenter"
                      //         centered
                      //         backdrop="static"
                      //         keyboard={false}
                      //         fullscreen="md-down"
                      //         scrollable={true}>
                      //         <Modal.Body>
                      //           <div>
                      //             <div className="row">
                      //               <div className="col-md-12">
                      //                 <DynamicGrid
                      //                   data={rowData2}
                      //                   columns={columns2}
                      //                   options={options2}
                      //                 />
                      //               </div>
                      //             </div>
                      //           </div>
                      //         </Modal.Body>
                      //         <Modal.Footer>
                      //           <button
                      //             className="btn customButtonCancel"
                      //             style={{ marginLeft: 5 }}
                      //             onClick={() => {
                      //                setShowHistoryModal(false);
                      //               setstoreTableMeta("");
                      //             }}>
                      //             Close
                      //           </button>
                      //         </Modal.Footer>
                      //       </Modal>
                      //     </div>
                      //   </>
                      // );
                      return (
                        <>
                          <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                            <div
                              className="centerStyles flex-justify-start"
                              style={{ padding: "0 8px 0 0" }}>
                              <Tooltip title="view">
                                <i
                                  style={{
                                    // marginRight: "5px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    // navigate("/newhireapproval", {
                                    //   state: workflowTaskId,
                                    // });
                                    newHireApproved(value, tableMeta);
                                  }}
                                  className="fas fa-eye"></i>
                              </Tooltip>
                            </div>
                            <div
                              className="centerStyles flex-justify-start"
                              style={{ padding: "0 8px 0 0" }}>
                              <Tooltip title="History">
                                <i
                                  style={{
                                    marginRight: "0px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setShowHistoryModal(true);
                                    getHistoryData(tableMeta);
                                  }}
                                  className="fas fa-history"></i>
                              </Tooltip>
                            </div>
                            <Modal
                            className="customized-history-modal-modified"
                              show={showHistoryModal}
                              onHide={() => setShowHistoryModal(false)}
                              //size="xl"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              backdrop="static"
                              keyboard={false}
                              fullscreen="md-down"
                              scrollable={true}>
                              <Modal.Body>
                                <div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <DynamicGrid
                                        data={rowData2}
                                        columns={columns2}
                                        options={options2}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  className="btn customButtonCancel"
                                  style={{ marginLeft: 5 }}
                                  onClick={() => {
                                     setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                  }}>
                                  Close
                                </button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </>
                      );
                    } else if (newHireStatus == "Approved") {
                      return (
                        <>
                          <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                            <div
                              className="centerStyles flex-justify-start"
                              style={{ padding: "0 8px 0 0" }}>
                              <Tooltip title="view">
                                <i
                                  style={{
                                    // marginRight: "5px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    newHireApproved(value, tableMeta);
                                  }}
                                  className="fas fa-eye mr-2"></i>
                              </Tooltip>
                            </div>
                            <div
                              className="centerStyles flex-justify-start"
                              style={{ padding: "0 8px 0 0" }}>
                              <Tooltip title="History">
                                <i
                                  style={{
                                    marginRight: "0px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setShowHistoryModal(true);
                                    getHistoryData(tableMeta);
                                  }}
                                  className="fas fa-history"></i>
                              </Tooltip>
                            </div>
                            <Modal
                            className="customized-history-modal-modified"
                              show={showHistoryModal}
                              onHide={() => setShowHistoryModal(false)}
                              //size="xl"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              backdrop="static"
                              keyboard={false}
                              fullscreen="md-down"
                              scrollable={true}>
                              <Modal.Body>
                                <div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <DynamicGrid
                                        data={rowData2}
                                        columns={columns2}
                                        options={options2}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  className="btn customButtonCancel"
                                  style={{ marginLeft: 5 }}
                                  onClick={() => {
                                     setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                  }}>
                                  Close
                                </button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </>
                      );
                    } else if (newHireStatus == "Pending") {
                      return (
                        <>
                          <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                            <div
                              className="centerStyles flex-justify-start"
                              style={{ padding: "0 8px 0 0" }}>
                              <Tooltip title="view">
                                <i
                                  style={{
                                    // marginRight: "5px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    // navigate("/newhireapproval", {
                                    //   state: workflowTaskId,
                                    // });
                                    newHireApproved(value, tableMeta);
                                  }}
                                  className="fas fa-eye"></i>
                              </Tooltip>
                            </div>
                            <div
                              className="centerStyles flex-justify-start"
                              style={{ padding: "0 8px 0 0" }}>
                              <Tooltip title="History">
                                <i
                                  style={{
                                    marginRight: "0px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setShowHistoryModal(true);
                                    getHistoryData(tableMeta);
                                  }}
                                  className="fas fa-history"></i>
                              </Tooltip>
                            </div>
                            <Modal
                            className="customized-history-modal-modified"
                              show={showHistoryModal}
                              onHide={() => setShowHistoryModal(false)}
                              //size="xl"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              backdrop="static"
                              keyboard={false}
                              fullscreen="md-down"
                              scrollable={true}>
                              <Modal.Body>
                                <div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <DynamicGrid
                                        data={rowData2}
                                        columns={columns2}
                                        options={options2}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  className="btn customButtonCancel"
                                  style={{ marginLeft: 5 }}
                                  onClick={() => {
                                     setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                  }}>
                                  Close
                                </button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </>
                      );
                    }
                  } else if (activetab == "My Action") {
                    if (newHireStatus == "Sendback") {
                      return (
                        <>
                          <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                            <div
                              className="centerStyles flex-justify-start"
                              style={{ padding: "0 8px 0 0" }}>
                              <Tooltip title="edit">
                                <i
                                  style={{
                                    // marginRight: "5px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    newHireSendback(value, tableMeta);
                                  }}
                                  className="fas fa-edit"></i>
                              </Tooltip>
                            </div>
                            <div
                              className="centerStyles flex-justify-start"
                              style={{ padding: "0 8px 0 0" }}>
                              <Tooltip title="History">
                                <i
                                  style={{
                                    marginRight: "0px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setShowHistoryModal(true);
                                    getHistoryData(tableMeta);
                                  }}
                                  className="fas fa-history"></i>
                              </Tooltip>
                            </div>
                            <Modal
                            className="customized-history-modal-modified"
                              show={showHistoryModal}
                              onHide={() => setShowHistoryModal(false)}
                              //size="xl"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              backdrop="static"
                              keyboard={false}
                              fullscreen="md-down"
                              scrollable={true}>
                              <Modal.Body>
                                <div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <DynamicGrid
                                        data={rowData2}
                                        columns={columns2}
                                        options={options2}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  className="btn customButtonCancel"
                                  style={{ marginLeft: 5 }}
                                  onClick={() => {
                                     setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                  }}>
                                  Close
                                </button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </>
                      );
                    } else if (newHireStatus == "Approved") {
                      return (
                        <>
                          <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                            <div
                              className="centerStyles flex-justify-start"
                              style={{ padding: "0 8px 0 0" }}>
                              <Tooltip title="view">
                                <i
                                  style={{
                                    // marginRight: "5px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    newHireApproved(value, tableMeta);
                                  }}
                                  className="fas fa-eye"></i>
                              </Tooltip>
                            </div>
                            <Tooltip title="History">
                              <i
                                style={{
                                  marginRight: "0px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setShowHistoryModal(true);
                                  getHistoryData(tableMeta);
                                }}
                                className="fas fa-history"></i>
                            </Tooltip>
                            <Modal
                            className="customized-history-modal-modified"
                              show={showHistoryModal}
                              onHide={() => setShowHistoryModal(false)}
                              //size="xl"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              backdrop="static"
                              keyboard={false}
                              fullscreen="md-down"
                              scrollable={true}>
                              <Modal.Body>
                                <div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <DynamicGrid
                                        data={rowData2}
                                        columns={columns2}
                                        options={options2}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  className="btn customButtonCancel"
                                  style={{ marginLeft: 5 }}
                                  onClick={() => {
                                     setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                  }}>
                                  Close
                                </button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </>
                      );
                    } else if (newHireStatus == "Pending") {
                      return (
                        <>
                          <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                            <div
                              className="centerStyles flex-justify-start"
                              style={{ padding: "0 8px 0 0" }}>
                              <Tooltip title="edit">
                                <i
                                  style={{
                                    // marginRight: "5px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    if(tableMeta.tableData[tableMeta.rowIndex].employeeType==="Employee"){
                                    navigate("/newhireapproval", {
                                      state: workflowTaskId,
                                    });}
                                    else{
                                      navigate("/newHireApprovalclm", {
                                        state: workflowTaskId,
                                      });}
                                  }}
                                  className="fas fa-edit"></i>
                              </Tooltip>
                            </div>
                            <div
                              className="centerStyles flex-justify-start"
                              style={{ padding: "0 8px 0 0" }}>
                              <Tooltip title="History">
                                <i
                                  style={{
                                    marginRight: "0px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setShowHistoryModal(true);
                                    getHistoryData(tableMeta);
                                  }}
                                  className="fas fa-history"></i>
                              </Tooltip>
                            </div>
                            <Modal
                            className="customized-history-modal-modified"
                              show={showHistoryModal}
                              onHide={() => setShowHistoryModal(false)}
                              //size="xl"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              backdrop="static"
                              keyboard={false}
                              fullscreen="md-down"
                              scrollable={true}>
                              <Modal.Body>
                                <div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <DynamicGrid
                                        data={rowData2}
                                        columns={columns2}
                                        options={options2}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  className="btn customButtonCancel"
                                  style={{ marginLeft: 5 }}
                                  onClick={() => {
                                     setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                  }}>
                                  Close
                                </button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </>
                      );
                    }
                  } else {
                    if (newHireStatus == "Sendback") {
                      return (
                        <>
                          <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                            <div
                              className="centerStyles flex-justify-start"
                              style={{ padding: "0 8px 0 0" }}>
                              <Tooltip title="edit">
                                <i
                                  style={{
                                    // marginRight: "5px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    newHireSendback(value, tableMeta);
                                  }}
                                  className="fas fa-edit"></i>
                              </Tooltip>
                            </div>
                            <div
                              className="centerStyles flex-justify-start"
                              style={{ padding: "0 8px 0 0" }}>
                              <Tooltip title="History">
                                <i
                                  style={{
                                    marginRight: "0px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setShowHistoryModal(true);
                                    getHistoryData(tableMeta);
                                  }}
                                  className="fas fa-history"></i>
                              </Tooltip>
                            </div>
                            <Modal
                            className="customized-history-modal-modified"
                              show={showHistoryModal}
                              onHide={() => setShowHistoryModal(false)}
                              //size="xl"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              backdrop="static"
                              keyboard={false}
                              fullscreen="md-down"
                              scrollable={true}>
                              <Modal.Body>
                                <div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <DynamicGrid
                                        data={rowData2}
                                        columns={columns2}
                                        options={options2}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  className="btn customButtonCancel"
                                  style={{ marginLeft: 5 }}
                                  onClick={() => {
                                     setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                  }}>
                                  Close
                                </button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </>
                      );
                    } else if (newHireStatus == "Approved") {
                      return (
                        <>
                          <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                            <div
                              className="centerStyles flex-justify-start"
                              style={{ padding: "0 8px 0 0" }}>
                              <Tooltip title="view">
                                <i
                                  style={{
                                    // marginRight: "5px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    newHireApproved(value, tableMeta);
                                  }}
                                  className="fas fa-eye"></i>
                              </Tooltip>
                            </div>
                            <Tooltip title="History">
                              <i
                                style={{
                                  marginRight: "0px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setShowHistoryModal(true);
                                  getHistoryData(tableMeta);
                                }}
                                className="fas fa-history"></i>
                            </Tooltip>
                            <Modal
                            className="customized-history-modal-modified"
                              show={showHistoryModal}
                              onHide={() => setShowHistoryModal(false)}
                              //size="xl"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              backdrop="static"
                              keyboard={false}
                              fullscreen="md-down"
                              scrollable={true}>
                              <Modal.Body>
                                <div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <DynamicGrid
                                        data={rowData2}
                                        columns={columns2}
                                        options={options2}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  className="btn customButtonCancel"
                                  style={{ marginLeft: 5 }}
                                  onClick={() => {
                                     setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                  }}>
                                  Close
                                </button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </>
                      );
                    } else if (newHireStatus == "Pending") {
                      return (
                        <>
                          <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                            <div
                              className="centerStyles flex-justify-start"
                              style={{ padding: "0 8px 0 0" }}>
                              <Tooltip title="edit">
                                <i
                                  style={{
                                    // marginRight: "5px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    if(tableMeta.tableData[tableMeta.rowIndex].employeeType==="Employee"){
                                    navigate("/newhireapproval", {
                                      state: workflowTaskId,
                                    });}
                                    else{
                                      navigate("/newHireApprovalclm", {
                                        state: workflowTaskId,
                                      });}
                                  }}
                                  className="fas fa-edit"></i>
                              </Tooltip>
                            </div>
                            <div
                              className="centerStyles flex-justify-start"
                              style={{ padding: "0 8px 0 0" }}>
                              <Tooltip title="History">
                                <i
                                  style={{
                                    marginRight: "0px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setShowHistoryModal(true);
                                    getHistoryData(tableMeta);
                                  }}
                                  className="fas fa-history"></i>
                              </Tooltip>
                            </div>
                            <Modal
                            className="customized-history-modal-modified"
                              show={showHistoryModal}
                              onHide={() => setShowHistoryModal(false)}
                              //size="xl"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              backdrop="static"
                              keyboard={false}
                              fullscreen="md-down"
                              scrollable={true}>
                              <Modal.Body>
                                <div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <DynamicGrid
                                        data={rowData2}
                                        columns={columns2}
                                        options={options2}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  className="btn customButtonCancel"
                                  style={{ marginLeft: 5 }}
                                  onClick={() => {
                                     setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                  }}>
                                  Close
                                </button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </>
                      );
                    }
                  }
                } else if (ModuleProcessName === "PositionManagement") {
                  if (activetab == "My Request") {
                    if (seqNo == 1) {
                      if (taskStatus == 1) {
                        return (
                          <>
                            <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                              <EditOrView
                                type="edit"
                                url="/positionManagement" //sendback
                                id={workflowTaskId}
                                TId={TId}
                                action="sendback"
                              />
                              <Tooltip title="History">
                                <i
                                  style={{
                                    marginRight: "0px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setShowHistoryModal(true);
                                    getHistoryData(tableMeta);
                                  }}
                                  className="fas fa-history"></i>
                              </Tooltip>
                              <Modal
                              className="customized-history-modal-modified"
                                show={showHistoryModal}
                                onHide={() => setShowHistoryModal(false)}
                                //size="xl"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                backdrop="static"
                                keyboard={false}
                                fullscreen="md-down"
                                scrollable={true}>
                                <Modal.Body>
                                  <div>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <DynamicGrid
                                          data={rowData2}
                                          columns={columns2}
                                          options={options2}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Modal.Body>
                                <Modal.Footer>
                                  <button
                                    className="btn customButtonCancel"
                                    style={{ marginLeft: 5 }}
                                    onClick={() => {
                                       setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                    }}>
                                    Close
                                  </button>
                                </Modal.Footer>
                              </Modal>
                            </div>
                          </>
                        );
                      } else {
                        return (
                          <>
                            <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                              <EditOrView
                                type="view"
                                url="/positionManagement"
                                id={workflowTaskId}
                                TId={TId}
                                action="view"
                              />
                              <Tooltip title="History">
                                <i
                                  style={{
                                    marginRight: "0px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setShowHistoryModal(true);
                                    getHistoryData(tableMeta);
                                  }}
                                  className="fas fa-history"></i>
                              </Tooltip>
                              <Modal
                              className="customized-history-modal-modified"
                                show={showHistoryModal}
                                onHide={() => setShowHistoryModal(false)}
                                //size="xl"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                backdrop="static"
                                keyboard={false}
                                fullscreen="md-down"
                                scrollable={true}>
                                <Modal.Body>
                                  <div>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <DynamicGrid
                                          data={rowData2}
                                          columns={columns2}
                                          options={options2}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Modal.Body>
                                <Modal.Footer>
                                  <button
                                    className="btn customButtonCancel"
                                    style={{ marginLeft: 5 }}
                                    onClick={() => {
                                       setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                    }}>
                                    Close
                                  </button>
                                </Modal.Footer>
                              </Modal>
                            </div>
                          </>
                        );
                      }
                    }
                  } else if (activetab == "My Action") {
                    const Sendback = seqNo == 1;
                    const commonProps = {
                      type: "edit",
                      url: "/positionManagement",
                      id: workflowTaskId,
                      TId: TId,
                      action: "sendback",
                      sendback: Sendback,
                    };
                    if (seqNo == 1) {
                      return (
                        // <EditOrView
                        //   type="edit"
                        //   url="/positionManagement" //sendback
                        //   id={workflowTaskId}
                        //   action="sendback"
                        //   TId={TId}
                        // />
                        <>
                          <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                            <EditOrView {...commonProps} />
                            <Tooltip title="History">
                              <i
                                style={{
                                  marginRight: "0px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setShowHistoryModal(true);
                                  getHistoryData(tableMeta);
                                }}
                                className="fas fa-history"></i>
                            </Tooltip>
                            <Modal
                            className="customized-history-modal-modified"
                              show={showHistoryModal}
                              onHide={() => setShowHistoryModal(false)}
                              //size="xl"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              backdrop="static"
                              keyboard={false}
                              fullscreen="md-down"
                              scrollable={true}>
                              <Modal.Body>
                                <div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <DynamicGrid
                                        data={rowData2}
                                        columns={columns2}
                                        options={options2}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  className="btn customButtonCancel"
                                  style={{ marginLeft: 5 }}
                                  onClick={() => {
                                     setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                  }}>
                                  Close
                                </button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                            <EditOrView
                              type="edit"
                              url="/positionManagement" //reviewer
                              id={workflowTaskId}
                              TId={TId}
                              action="reviewer"
                            />
                            <Tooltip title="History">
                              <i
                                style={{
                                  marginRight: "0px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setShowHistoryModal(true);
                                  getHistoryData(tableMeta);
                                }}
                                className="fas fa-history"></i>
                            </Tooltip>
                            <Modal
                            className="customized-history-modal-modified"
                              show={showHistoryModal}
                              onHide={() => setShowHistoryModal(false)}
                              //size="xl"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              backdrop="static"
                              keyboard={false}
                              fullscreen="md-down"
                              scrollable={true}>
                              <Modal.Body>
                                <div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <DynamicGrid
                                        data={rowData2}
                                        columns={columns2}
                                        options={options2}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  className="btn customButtonCancel"
                                  style={{ marginLeft: 5 }}
                                  onClick={() => {
                                     setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                  }}>
                                  Close
                                </button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </>
                      );
                    }
                  } else {
                    if (seqNo == 1) {
                      const Sendback = seqNo == 1;
                      const commonProps = {
                        type: "edit",
                        url: "/positionManagement",
                        id: workflowTaskId,
                        TId: TId,
                        action: "sendback",
                        sendback: Sendback,
                      };
                      if (taskStatus == 1) {
                        return (
                          // <EditOrView
                          //   type="edit"
                          //   url="/positionManagement" //sendback
                          //   id={workflowTaskId}
                          //   action="sendback"
                          // />
                          <>
                            <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                              <EditOrView {...commonProps} />
                              <Tooltip title="History">
                                <i
                                  style={{
                                    marginRight: "0px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setShowHistoryModal(true);
                                    getHistoryData(tableMeta);
                                  }}
                                  className="fas fa-history"></i>
                              </Tooltip>
                              <Modal
                              className="customized-history-modal-modified"
                                show={showHistoryModal}
                                onHide={() => setShowHistoryModal(false)}
                               // size="xl"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                backdrop="static"
                                keyboard={false}
                                fullscreen="md-down"
                                scrollable={true}>
                                <Modal.Body>
                                  <div>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <DynamicGrid
                                          data={rowData2}
                                          columns={columns2}
                                          options={options2}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Modal.Body>
                                <Modal.Footer>
                                  <button
                                    className="btn customButtonCancel"
                                    style={{ marginLeft: 5 }}
                                    onClick={() => {
                                       setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                    }}>
                                    Close
                                  </button>
                                </Modal.Footer>
                              </Modal>
                            </div>
                          </>
                        );
                      } else {
                        return (
                          <>
                            <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                              <EditOrView
                                type="view"
                                url="/positionManagement" //view
                                id={workflowTaskId}
                                TId={TId}
                                action="view"
                              />
                              <Tooltip title="History">
                                <i
                                  style={{
                                    marginRight: "0px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setShowHistoryModal(true);
                                    getHistoryData(tableMeta);
                                  }}
                                  className="fas fa-history"></i>
                              </Tooltip>
                              <Modal
                              className="customized-history-modal-modified"
                                show={showHistoryModal}
                                onHide={() => setShowHistoryModal(false)}
                                //size="xl"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                backdrop="static"
                                keyboard={false}
                                fullscreen="md-down"
                                scrollable={true}>
                                <Modal.Body>
                                  <div>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <DynamicGrid
                                          data={rowData2}
                                          columns={columns2}
                                          options={options2}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </Modal.Body>
                                <Modal.Footer>
                                  <button
                                    className="btn customButtonCancel"
                                    style={{ marginLeft: 5 }}
                                    onClick={() => {
                                       setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                    }}>
                                    Close
                                  </button>
                                </Modal.Footer>
                              </Modal>
                            </div>
                          </>
                        );
                      }
                    } else {
                      return (
                        <>
                          <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                            <EditOrView
                              type={taskStatus == 1 ? "edit" : "view"}
                              url="/positionManagement"
                              id={workflowTaskId}
                              TId={TId}
                            />
                            <Tooltip title="History">
                              <i
                                style={{
                                  marginRight: "0px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setShowHistoryModal(true);
                                  getHistoryData(tableMeta);
                                }}
                                className="fas fa-history"></i>
                            </Tooltip>
                            <Modal
                            className="customized-history-modal-modified"
                              show={showHistoryModal}
                              onHide={() => setShowHistoryModal(false)}
                              //size="xl"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              backdrop="static"
                              keyboard={false}
                              fullscreen="md-down"
                              scrollable={true}>
                              <Modal.Body>
                                <div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <DynamicGrid
                                        data={rowData2}
                                        columns={columns2}
                                        options={options2}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  className="btn customButtonCancel"
                                  style={{ marginLeft: 5 }}
                                  onClick={() => {
                                     setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                  }}>
                                  Close
                                </button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </>
                      );
                    }
                  }
                } else if (ModuleProcessName === "ManpowerRequisition") {
                  const Sendback =
                    String(WorkflowInstanceStatus)
                      .toLocaleLowerCase()
                      .trim() === "sendback";

                  if (WorkflowSystemName === "CandidateApproval") {
                    const commonProps = {
                      type: taskStatus === 2 ? "view" : "edit",
                      url: "/mrf",
                      id: workflowTaskId,
                      TId: TId,
                      sendback: Sendback,
                      Tab: "ManpowerCandidates",
                      CandidateId: CandidateId,
                      isEvaluator,
                    };

                    return (
                      <>
                        <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                          <EditOrView {...commonProps} />
                        </div>
                      </>
                    );
                  } else {
                    const commonProps = {
                      type: taskStatus === 2 ? "view" : "edit",
                      url: "/mrf",
                      id: workflowTaskId,
                      TId: TId,
                      sendback: Sendback,
                      Tab: "ManpowerProcess",
                    };

                    return <EditOrView {...commonProps} />;
                  }
                } else if (ModuleProcessName === "OnBoarding") {
                  const Sendback =
                    String(WorkflowInstanceStatus)
                      .toLocaleLowerCase()
                      .trim() === "pending";

                  if (activetab == "My Action") {
                    // console.log(workflowTaskId);
                    // console.log("SEQ::", seqNo);
                    if (WorkflowSystemName === "OnBoardingShowNoShow") {
                      const commonProps = {
                        type: taskStatus === 2 ? "view" : "edit",
                        url: "/mrf",
                        id: workflowTaskId,
                        TId: TId,
                        sendback: Sendback,
                        Tab: "ManpowerCandidates",
                        CandidateId: CandidateId,
                      };

                      return;
                      // <>
                      //   <div className="row">
                      //     <EditOrView {...commonProps} />
                      //     <Tooltip title="History">
                      //       <i
                      //         style={{
                      //           marginRight: "0px",
                      //           cursor: "pointer",
                      //         }}
                      //         onClick={() => {
                      //           setShowHistoryModal(true);
                      //           getHistoryData(tableMeta);
                      //         }}
                      //         className="fas fa-history"></i>
                      //     </Tooltip>
                      //     <Modal
                      //       show={showHistoryModal}
                      //       onHide={() => setShowHistoryModal(false)}
                      //       size="xl"
                      //       aria-labelledby="contained-modal-title-vcenter"
                      //       centered
                      //       backdrop="static"
                      //       keyboard={false}
                      //       fullscreen="md-down"
                      //       scrollable={true}>
                      //       <Modal.Body>
                      //         <div>
                      //           <div className="row">
                      //             <div className="col-md-12">
                      //               <DynamicGrid
                      //                 data={rowData2}
                      //                 columns={columns2}
                      //                 options={options2}
                      //               />
                      //             </div>
                      //           </div>
                      //         </div>
                      //       </Modal.Body>
                      //       <Modal.Footer>
                      //         <button
                      //           className="btn customButtonCancel"
                      //           style={{ marginLeft: 5 }}
                      //           onClick={() => {
                      //             setShowHistoryModal(false);
                      //           }}>
                      //           Close
                      //         </button>
                      //       </Modal.Footer>
                      //     </Modal>
                      //   </div>
                      // </>;
                    } else {
                      if (seqNo == "1") {
                        return (
                          <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                            <EditOrView
                              type="edit"
                              url="/OnBoardingCheckList"
                              id={workflowTaskId}
                            />
                            <Tooltip title="History">
                              <i
                                style={{
                                  marginRight: "0px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setShowHistoryModal(true);
                                  getHistoryData(tableMeta);
                                }}
                                className="fas fa-history"></i>
                            </Tooltip>
                            <Modal
                            className="customized-history-modal-modified"
                              show={showHistoryModal}
                              onHide={() => setShowHistoryModal(false)}
                              //size="xl"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              backdrop="static"
                              keyboard={false}
                              fullscreen="md-down"
                              scrollable={true}>
                              <Modal.Body>
                                <div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <DynamicGrid
                                        data={rowData2}
                                        columns={columns2}
                                        options={options2}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  className="btn customButtonCancel"
                                  style={{ marginLeft: 5 }}
                                  onClick={() => {
                                     setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                  }}>
                                  Close
                                </button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        );
                      } else if (seqNo == "2") {
                        if (Task?.trim() === "Review Candidate Data") {
                          return (
                            <>
                              <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                                <EditOrView
                                  type="edit"
                                  url="/CandidateFormReview"
                                  id={TId}
                                  workflowTaskId={workflowTaskId}
                                  outcome={Outcome}
                                />
                                <Tooltip title="History">
                                  <i
                                    style={{
                                      marginRight: "0px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setShowHistoryModal(true);
                                      getHistoryData(tableMeta);
                                    }}
                                    className="fas fa-history"></i>
                                </Tooltip>
                                <Modal
                                className="customized-history-modal-modified"
                                  show={showHistoryModal}
                                  onHide={() => setShowHistoryModal(false)}
                                 // size="xl"
                                  aria-labelledby="contained-modal-title-vcenter"
                                  centered
                                  backdrop="static"
                                  keyboard={false}
                                  fullscreen="md-down"
                                  scrollable={true}>
                                  <Modal.Body>
                                    <div>
                                      <div className="row">
                                        <div className="col-md-12">
                                          <DynamicGrid
                                            data={rowData2}
                                            columns={columns2}
                                            options={options2}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <button
                                      className="btn customButtonCancel"
                                      style={{ marginLeft: 5 }}
                                      onClick={() => {
                                         setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                      }}>
                                      Close
                                    </button>
                                  </Modal.Footer>
                                </Modal>
                              </div>
                            </>
                          );
                        } else {
                          return (
                            <>
                              <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                                <EditOrView
                                  type="edit"
                                  url="/OnBoardingCheckList/ApprovalDashboard"
                                  id={workflowTaskId}
                                />
                                <Tooltip title="History">
                                  <i
                                    style={{
                                      marginRight: "0px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setShowHistoryModal(true);
                                      getHistoryData(tableMeta);
                                    }}
                                    className="fas fa-history"></i>
                                </Tooltip>
                                <Modal
                                className="customized-history-modal-modified"
                                  show={showHistoryModal}
                                  onHide={() => setShowHistoryModal(false)}
                                 // size="xl"
                                  aria-labelledby="contained-modal-title-vcenter"
                                  centered
                                  backdrop="static"
                                  keyboard={false}
                                  fullscreen="md-down"
                                  scrollable={true}>
                                  <Modal.Body>
                                    <div>
                                      <div className="row">
                                        <div className="col-md-12">
                                          <DynamicGrid
                                            data={rowData2}
                                            columns={columns2}
                                            options={options2}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <button
                                      className="btn customButtonCancel"
                                      style={{ marginLeft: 5 }}
                                      onClick={() => {
                                         setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                      }}>
                                      Close
                                    </button>
                                  </Modal.Footer>
                                </Modal>
                              </div>
                            </>
                            //  <EditOrView {...commonProps} />
                          );
                        }
                      }
                    }
                  }
                } else if (ModuleProcessName === "Associates") {
                  if (activetab === "My Request"){
                     if(taskStatus === 1){
                      return (
                        <>
                          <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                            <div
                              className="centerStyles flex-justify-start"
                              style={{ padding: "0 8px 0 0" }}>
                              <Tooltip title="edit">
                                <i
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    navigate("/AppointmentScreen", {
                                      // state: {TId},
                                      state: {TId,workflowTaskId},
                                    });
                                  }}
                                  className="fas fa-edit"></i>
                              </Tooltip>
                            </div>
                            <div
                              className="centerStyles flex-justify-start"
                              style={{ padding: "0 8px 0 0" }}>
                              <Tooltip title="History">
                                <i
                                  style={{
                                    marginRight: "0px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setShowHistoryModal(true);
                                    getHistoryData(tableMeta);
                                  }}
                                  className="fas fa-history"></i>
                              </Tooltip>
                            </div>
                            <Modal
                            className="customized-history-modal-modified"
                              show={showHistoryModal}
                              onHide={() => setShowHistoryModal(false)}
                              //size="xl"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              backdrop="static"
                              keyboard={false}
                              fullscreen="md-down"
                              scrollable={true}>
                              <Modal.Body>
                                <div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <DynamicGrid
                                        data={rowData2}
                                        columns={columns2}
                                        options={options2}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  className="btn customButtonCancel"
                                  style={{ marginLeft: 5 }}
                                  onClick={() => {
                                     setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                  }}>
                                  Close
                                </button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </>
                      );
                     }
                     else if(taskStatus === 2){
                      return (
                        <>
                          <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                            <div
                              className="centerStyles flex-justify-start"
                              style={{ padding: "0 8px 0 0" }}>
                              <Tooltip title="view">
                                <i
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    navigate("/AppointmentScreen", {
                                      //state: {TId},
                                      state: {TId,workflowTaskId},
                                    });
                                  }}
                                  className="fas fa-eye"></i>
                              </Tooltip>
                            </div>
                            <div
                              className="centerStyles flex-justify-start"
                              style={{ padding: "0 8px 0 0" }}>
                              <Tooltip title="History">
                                <i
                                  style={{
                                    marginRight: "0px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setShowHistoryModal(true);
                                    getHistoryData(tableMeta);
                                  }}
                                  className="fas fa-history"></i>
                              </Tooltip>
                            </div>
                            <Modal
                            className="customized-history-modal-modified"
                              show={showHistoryModal}
                              onHide={() => setShowHistoryModal(false)}
                              //size="xl"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              backdrop="static"
                              keyboard={false}
                              fullscreen="md-down"
                              scrollable={true}>
                              <Modal.Body>
                                <div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <DynamicGrid
                                        data={rowData2}
                                        columns={columns2}
                                        options={options2}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  className="btn customButtonCancel"
                                  style={{ marginLeft: 5 }}
                                  onClick={() => {
                                     setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                  }}>
                                  Close
                                </button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </>
                      );
                     }
                  } else if (activetab === "My Action"){
                    if(taskStatus === 1 && !(Outcome==="BackToInitiator")){
                      return (
                        <>
                          <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                            <div
                              className="centerStyles flex-justify-start"
                              style={{ padding: "0 8px 0 0" }}>
                              <Tooltip title="edit">
                                <i
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    navigate("/AppointmentApprovalScreen", {
                                      state: {TId,workflowTaskId},
                                    });
                                  }}
                                  className="fas fa-edit"></i>
                              </Tooltip>
                            </div>
                            <div
                              className="centerStyles flex-justify-start"
                              style={{ padding: "0 8px 0 0" }}>
                              <Tooltip title="History">
                                <i
                                  style={{
                                    marginRight: "0px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setShowHistoryModal(true);
                                    getHistoryData(tableMeta);
                                  }}
                                  className="fas fa-history"></i>
                              </Tooltip>
                            </div>
                            <Modal
                            className="customized-history-modal-modified"
                              show={showHistoryModal}
                              onHide={() => setShowHistoryModal(false)}
                              //size="xl"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                              backdrop="static"
                              keyboard={false}
                              fullscreen="md-down"
                              scrollable={true}>
                              <Modal.Body>
                                <div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <DynamicGrid
                                        data={rowData2}
                                        columns={columns2}
                                        options={options2}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <button
                                  className="btn customButtonCancel"
                                  style={{ marginLeft: 5 }}
                                  onClick={() => {
                                     setShowHistoryModal(false);
                                    setstoreTableMeta("");
                                  }}>
                                  Close
                                </button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </>
                      );
                     }
                     else if(taskStatus === 1 && Outcome==="BackToInitoator" ){
                      return (
                        <>
                        </>
                      );
                     }
                   } 
                }
                
                
                
                else {
                  return (
                    <>
                      <div className="row" style={{ flexWrap: "nowrap", marginRight : '6px', marginLeft : 0 }}>
                        <div
                          className="centerStyles flex-justify-start"
                          style={{ padding: "0 8px 0 0" }}>
                          <Tooltip title="View">
                            <i
                              style={{ marginRight: "0px", cursor: "pointer" }}
                              onClick={() => {
                                edit(value, tableMeta);
                              }}
                              className="fas fa-eye"></i>
                          </Tooltip>
                        </div>
                        <div
                          className="centerStyles flex-justify-start"
                          style={{ padding: "0 8px 0 0" }}>
                          <Tooltip title="History">
                            <i
                              style={{
                                marginRight: "0px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setShowHistoryModal(true);
                                getHistoryData(tableMeta);
                              }}
                              className="fas fa-history"></i>
                          </Tooltip>
                        </div>
                        <Modal
                        className="customized-history-modal-modified"
                          show={showHistoryModal}
                          onHide={() => setShowHistoryModal(false)}
                          //size="xl"
                          aria-labelledby="contained-modal-title-vcenter"
                          centered
                          backdrop="static"
                          keyboard={false}
                          fullscreen="md-down"
                          scrollable={true}>
                          <Modal.Body>
                            <div>
                              <div className="row">
                                <div className="col-md-12">
                                  <DynamicGrid
                                    data={rowData2}
                                    columns={columns2}
                                    options={options2}
                                  />
                                </div>
                              </div>
                            </div>
                          </Modal.Body>
                          <Modal.Footer>
                            <button
                              className="btn customButtonCancel"
                              style={{ marginLeft: 5 }}
                              onClick={() => {
                                 setShowHistoryModal(false);
                                    setstoreTableMeta("");
                              }}>
                              Close
                            </button>
                          </Modal.Footer>
                        </Modal>
                      </div>
                    </>
                  );
                }
              },
            },
          };
        } else if (column.name == "pendingWithUserName") {
          if (column?.options?.display && activetab == "My Action") {
            column.options.display = false;
          } else {
            column.options.display = true;
          }
        }
        return column;
      }),
    ];
  }

  const redirectToCandidateReview = async (
    CandidateUserId,
    WorkFlowTask,
    SOutcome
  ) => {
    const resp = await APICall(getEmployeedetailsBasedOnCandidateId, "POST", {
      CandidateId: CandidateUserId,
    });

    const TId = resp.data.tId;
    const NewHireStatus = resp.data.newHireStatus;
    const CT = resp.data.ct;
    const WorkFlowTaskId = WorkFlowTask;
    const StatusOutcome = SOutcome;
    const IsEdit =true ;

    navigate("/CandidateFormReview", {
      state: {
        TId,
        NewHireStatus,
        CT,
        CandidateUserId,
        WorkFlowTaskId,
        StatusOutcome,
        IsEdit,
      },
    });
  };
  const EditOrView = ({
    type = "view",
    url = "",
    id,
    action = "",
    TId = 0,
    sendback = false,
    Tab = "",
    CandidateId = 0,
    isEvaluator = false,
    workflowTaskId = 0,
    outcome = "",
  }) => {
    const state = {
      Id: id,
      type,
      action,
      TId,
      tab: Tab,
      Tab: Tab,
      CandidateId,
      sendback: false,
      isEvaluator,
      isWorkflowTask: true,
    };
    return (
      <div
        className="centerStyles flex-justify-start"
        style={{ padding: "0 8px 0 0" }}>
        <Tooltip title={type === "view" ? "View" : "Edit"}>
          <i
            style={{ marginRight: "0px", cursor: "pointer" }}
            onClick={() => {
              if (url === "/positionManagement" || url === "/mrf") {
                state.type = type === "view" ? "view" : "edit";
                state.sendback = type === "view" ? false : sendback;
                navigate(url, { state });
              } else if (url === "/CandidateFormReview") {
                redirectToCandidateReview(id, workflowTaskId, outcome);
              } else {
                navigate(url, { state: id });
              }
            }}
            className={`fas fa-${type === "view" ? "eye" : "edit"}`}></i>
        </Tooltip>
      </div>
    );
  };

  const columns = [
    {
      name: "id",
      label: "sr no.",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "isEvaluator",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "taskStatus",
      label: "taskStatus",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "seqNo",
      label: "seqNo",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "outcome",
      label: "outcome",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "recordID",
      label: "Record ID",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "process",
      label: "Process",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "recordTitle",
      label: "Activity",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "recordStatus",
      label: "Status",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "pendingWithUserName",
      label: "Pending With",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "initiator",
      label: "Initiator",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "recordDate",
      label: "Submitteeed Date",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "approvedOn",
      label: "Approved On",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "pendingSince",
      label: "Pending Since",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "tid",
      label: "TID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "systemName",
      label: "System Name",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "newHireStatus",
      label: "New Hire Status",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "roleSystemName",
      label: "RoleSystemName",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "candidateId",
      label: "candidateId",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "country",
      label: "Country",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "remarks", 
      label: "Remarks",
      options: { filter: false, display: false, sort: false },
    },
    {
      name: "",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log(tableMeta);
          // const currentRow = tableMeta.rowData;
          // const workflowTaskId = currentRow[0];
          // const taskStatus = currentRow[1];
          // const process = currentRow[2];
          // const seqNo = currentRow[3];
          // const TId = Number(currentRow[12]);
          // const WorkflowInstanceStatus = currentRow[9];
          // const WorkflowSystemName = currentRow[13];
          // const newHireStatus = tableMeta.rowData[14];
          // const RoleName = currentRow[15];
          // const CandidateId = currentRow[16];
          const workflowTaskId = tableMeta.tableData[tableMeta.rowIndex].id;
          const taskStatus = tableMeta.tableData[tableMeta.rowIndex].taskStatus;
          const process = tableMeta.tableData[tableMeta.rowIndex].process;
          const seqNo = tableMeta.tableData[tableMeta.rowIndex].seqNo;
          const TId = Number(tableMeta.tableData[tableMeta.rowIndex].tid);
          const WorkflowInstanceStatus =
            tableMeta.tableData[tableMeta.rowIndex].recordStatus;
          const WorkflowSystemName =
            tableMeta.tableData[tableMeta.rowIndex].systemName;
          const newHireStatus =
            tableMeta.tableData[tableMeta.rowIndex].newHireStatus;
          const RoleName =
            tableMeta.tableData[tableMeta.rowIndex].roleSystemName;
          const CandidateId =
            tableMeta.tableData[tableMeta.rowIndex].candidateId;
          const ModuleProcessName =
            tableMeta.tableData[tableMeta.rowIndex].moduleProcessName;
          const EmployeeIdForEss =
            tableMeta.tableData[tableMeta.rowIndex].employeeIdForEss;
          const isEvaluator =
            tableMeta.tableData[tableMeta.rowIndex].isEvaluator;

          const Sendback =
            String(WorkflowInstanceStatus).toLocaleLowerCase().trim() ===
            "sendback";

          const commonProps = {
            type: taskStatus === 2 ? "view" : "edit",
            url: "/mrf",
            id: workflowTaskId,
            TId: TId,
            sendback: Sendback,
            Tab: "ManpowerCandidates",
            CandidateId: CandidateId,
          };

          if (ModuleProcessName === "Progression") {
            if (activetab == "My Request") {
              if (seqNo == 1) {
                if (taskStatus == 1) {
                  return (
                    tableMeta.tableData[tableMeta.rowIndex].employeeType==="Employee" ? (
                    <EditOrView
                      type="edit"
                      url="/sendback"
                      id={workflowTaskId}
                    />)
                    :(
                    <EditOrView
                    type="edit"
                    url="/sendbackclm"
                    id={workflowTaskId}
                  />)
                  );
                } else {
                  return (tableMeta.tableData[tableMeta.rowIndex].employeeType==="Employee" ?
                  (
                  <EditOrView
                    type="view"
                    url="/requisitionview"
                    id={workflowTaskId}
                  />) : (
                    <EditOrView
                    type="view"
                    url="/requisitionviewclm"
                    id={workflowTaskId}/>
                  ))
                }
              }
            } else if (activetab == "My Action") {
              if (seqNo == 1) {
                return (
                  tableMeta.tableData[tableMeta.rowIndex].employeeType==="Employee" ?
                  (<EditOrView type="edit" url="/sendback" id={workflowTaskId} />)  :
                  (<EditOrView type="edit" url="/sendbackclm" id={workflowTaskId} />)
                );
              } else {
                debugger
                return (
                  tableMeta.tableData[tableMeta.rowIndex].employeeType==="Employee" ?
                  (
                    <EditOrView
                    type="edit"
                    url="/reviewerSection"
                    id={workflowTaskId}
                  />) : (
                    <EditOrView
                    type="edit"
                    url="/reviewerSectionclm"
                    id={workflowTaskId}/>
                  )
                );
              }
            } else {
              if (seqNo == 1) {
                if (taskStatus == 1) {
                  return (
                    tableMeta.tableData[tableMeta.rowIndex].employeeType==="Employee" ?
                    (<EditOrView  type="edit"  url="/sendback"  id={workflowTaskId} />):
                    (<EditOrView  type="edit"  url="/sendbackclm"  id={workflowTaskId} />)
                  
                  );
                } else {
                  return (tableMeta.tableData[tableMeta.rowIndex].employeeType==="Employee" ?
                  (
                  <EditOrView
                    type="view"
                    url="/requisitionview"
                    id={workflowTaskId}
                  />) : (
                    <EditOrView
                    type="view"
                    url="/requisitionviewclm"
                    id={workflowTaskId}/>
                  ))
                }
              } else {
                debugger
                return (
                  tableMeta.tableData[tableMeta.rowIndex].employeeType==="Employee" ?
                  (
                    <EditOrView
                    type={taskStatus == 1 ? "edit" : "view"}
                    url="/reviewerSection"
                    id={workflowTaskId}
                  />) : (
                    <EditOrView
                    type={taskStatus == 1 ? "edit" : "view"}
                    url="/reviewerSectionclm"
                    id={workflowTaskId}/>
                  )
                
                );
              }
            }
          } else if (ModuleProcessName === "EssMss") {
            if (activetab == "My Request") {
              if (seqNo == 1) {
                if (taskStatus == 1) {
                  return (
                    <div
                      className="centerStyles flex-justify-start"
                      style={{ padding: "0 8px 0 0" }}>
                      <Tooltip title="edit">
                        <i
                          style={{ marginRight: "0px", cursor: "pointer" }}
                          onClick={() => {
                            essMssSendback(value, EmployeeIdForEss, tableMeta);
                          }}
                          className="fas fa-edit"></i>
                      </Tooltip>
                    </div>
                  );
                } else {
                  return (tableMeta.tableData[tableMeta.rowIndex].employeeType==="Employee" ?
                  (
                  <EditOrView
                    type="view"
                    url="/requisitionview"
                    id={workflowTaskId}
                  />) : (
                    <EditOrView
                    type="view"
                    url="/requisitionviewclm"
                    id={workflowTaskId}/>
                  ))
                }
              }
            } else if (activetab == "My Action") {
              if (seqNo == 1) {
                return (
                  <div
                    className="centerStyles flex-justify-start"
                    style={{ padding: "0 8px 0 0" }}>
                    <Tooltip title="edit">
                      <i
                        style={{ marginRight: "0px", cursor: "pointer" }}
                        onClick={() => {
                          essMssSendback(value, EmployeeIdForEss, tableMeta);
                        }}
                        className="fas fa-edit"></i>
                    </Tooltip>
                  </div>
                );
              } else {
                debugger
                return (
                  tableMeta.tableData[tableMeta.rowIndex].employeeType==="Employee" ?
                              (
                                <EditOrView
                                type="edit"
                                url="/reviewerSection"
                                id={workflowTaskId}
                              />) : (
                                <EditOrView
                                type="edit"
                                url="/reviewerSectionclm"
                                id={workflowTaskId}/>
                              )
                );
              }
            } else {
              if (seqNo == 1) {
                if (taskStatus == 1) {
                  return (
                    <div
                      className="centerStyles flex-justify-start"
                      style={{ padding: "0 8px 0 0" }}>
                      <Tooltip title="edit">
                        <i
                          style={{ marginRight: "0px", cursor: "pointer" }}
                          onClick={() => {
                            essMssSendback(value, EmployeeIdForEss, tableMeta);
                          }}
                          className="fas fa-edit"></i>
                      </Tooltip>
                    </div>
                  );
                } else {
                  return (tableMeta.tableData[tableMeta.rowIndex].employeeType==="Employee" ?
                  (
                  <EditOrView
                    type="view"
                    url="/requisitionview"
                    id={workflowTaskId}
                  />) : (
                    <EditOrView
                    type="view"
                    url="/requisitionviewclm"
                    id={workflowTaskId}/>
                  ))
                }
              } else {
                debugger
                return (
                  tableMeta.tableData[tableMeta.rowIndex].employeeType==="Employee" ?
                              (
                                <EditOrView
                                type={taskStatus == 1 ? "edit" : "view"}
                                url="/reviewerSection"
                                id={workflowTaskId}
                              />) : (
                                <EditOrView
                                type={taskStatus == 1 ? "edit" : "view"}
                                url="/reviewerSectionclm"
                                id={workflowTaskId}/>
                              )

                );
              }
            }
          } else if (ModuleProcessName === "EmployeeCentral") {
            if (activetab == "My Request") {
              if (newHireStatus == "Sendback") {
                return (
                  <div
                    className="centerStyles flex-justify-start"
                    style={{ padding: "0 8px 0 0" }}>
                    <Tooltip title="edit">
                      <i
                        style={{ marginRight: "0px", cursor: "pointer" }}
                        onClick={() => {
                          newHireSendback(value, tableMeta);
                        }}
                        className="fas fa-edit"></i>
                    </Tooltip>
                  </div>
                );
              } else if (newHireStatus == "Approved") {
                return (
                  <div
                    className="centerStyles flex-justify-start"
                    style={{ padding: "0 8px 0 0" }}>
                    <Tooltip title="view">
                      <i
                        style={{ marginRight: "0px", cursor: "pointer" }}
                        onClick={() => {
                          newHireApproved(value, tableMeta);
                        }}
                        className="fas fa-eye"></i>
                    </Tooltip>
                  </div>
                );
              } else if (newHireStatus == "Pending") {
                return (
                  <div
                    className="centerStyles flex-justify-start"
                    style={{ padding: "0 8px 0 0" }}>
                    <Tooltip title="view">
                      <i
                        style={{ marginRight: "0px", cursor: "pointer" }}
                        onClick={() => {
                          // navigate("/newhireapproval", {
                          //   state: workflowTaskId,
                          // });
                          newHireApproved(value, tableMeta);
                        }}
                        className="fas fa-eye"></i>
                    </Tooltip>
                  </div>
                );
              }
              // if (seqNo == 1) {
              //   if (taskStatus == 1) {
              //     if (newHireStatus == "Sendback") {
              //       return (
              //         <Tooltip title="edit">
              //           <i
              //             style={{ marginRight: "0px", cursor: "pointer" }}
              //             onClick={() => {
              //               newHireSendback(value, tableMeta);
              //             }}
              //             className="fas fa-edit"></i>
              //         </Tooltip>
              //       );
              //     }
              //   } else if (taskStatus == 2) {
              //     if (newHireStatus == "Approved") {
              //       return (
              //         <Tooltip title="view">
              //           <i
              //             style={{ marginRight: "0px", cursor: "pointer" }}
              //             onClick={() => {
              //               newHireApproved(value, tableMeta);
              //             }}
              //             className="fas fa-eye"></i>
              //         </Tooltip>
              //       );
              //     }
              //   }
              // } else if (taskStatus == 2) {
              //   if (newHireStatus == "Approved") {
              //     return (
              //       <Tooltip title="view">
              //         <i
              //           style={{ marginRight: "0px", cursor: "pointer" }}
              //           onClick={() => {
              //             newHireApproved(value, tableMeta);
              //           }}
              //           className="fas fa-eye"></i>
              //       </Tooltip>
              //     );
              //   }
              // }
              // if (taskStatus == 1 && seqNo > 1) {
              //   if (newHireStatus == "Pending") {
              //     return (
              //       <Tooltip title="edit">
              //         <i
              //           style={{ marginRight: "0px", cursor: "pointer" }}
              //           onClick={() => {
              //             navigate("/newhireapproval", {
              //               state: workflowTaskId,
              //             });
              //           }}
              //           className="fas fa-edit"></i>
              //       </Tooltip>
              //     );
              //   }
              // }
            } else if (activetab == "My Action") {
              // if (taskStatus == 1 && seqNo > 1) {
              //   if (newHireStatus == "Pending") {
              //     return (
              //       <Tooltip title="edit">
              //         <i
              //           style={{ marginRight: "0px", cursor: "pointer" }}
              //           onClick={() => {
              //             navigate("/newhireapproval", {
              //               state: workflowTaskId,
              //             });
              //           }}
              //           className="fas fa-edit"></i>
              //       </Tooltip>
              //     );
              //   }
              // }
              if (newHireStatus == "Sendback") {
                return (
                  <div
                    className="centerStyles flex-justify-start"
                    style={{ padding: "0 8px 0 0" }}>
                    <Tooltip title="edit">
                      <i
                        style={{ marginRight: "0px", cursor: "pointer" }}
                        onClick={() => {
                          newHireSendback(value, tableMeta);
                        }}
                        className="fas fa-edit"></i>
                    </Tooltip>
                  </div>
                );
              } else if (newHireStatus == "Approved") {
                return (
                  <div
                    className="centerStyles flex-justify-start"
                    style={{ padding: "0 8px 0 0" }}>
                    <Tooltip title="view">
                      <i
                        style={{ marginRight: "0px", cursor: "pointer" }}
                        onClick={() => {
                          newHireApproved(value, tableMeta);
                        }}
                        className="fas fa-eye"></i>
                    </Tooltip>
                  </div>
                );
              } else if (newHireStatus == "Pending") {
                return (
                  <div
                    className="centerStyles flex-justify-start"
                    style={{ padding: "0 8px 0 0" }}>
                    <Tooltip title="edit">
                      <i
                        style={{ marginRight: "0px", cursor: "pointer" }}
                        onClick={() => {
                          if(tableMeta.tableData[tableMeta.rowIndex].employeeType==="Employee"){
                          navigate("/newhireapproval", {
                            state: workflowTaskId,
                          });}
                          else{
                            navigate("/newHireApprovalclm", {
                              state: workflowTaskId,
                            });}
                        }}
                        className="fas fa-edit"></i>
                    </Tooltip>
                  </div>
                );
              }
            } else {
              if (newHireStatus == "Sendback") {
                return (
                  <div
                    className="centerStyles flex-justify-start"
                    style={{ padding: "0 8px 0 0" }}>
                    <Tooltip title="edit">
                      <i
                        style={{ marginRight: "0px", cursor: "pointer" }}
                        onClick={() => {
                          newHireSendback(value, tableMeta);
                        }}
                        className="fas fa-edit"></i>
                    </Tooltip>
                  </div>
                );
              } else if (newHireStatus == "Approved") {
                return (
                  <div
                    className="centerStyles flex-justify-start"
                    style={{ padding: "0 8px 0 0" }}>
                    <Tooltip title="view">
                      <i
                        style={{ marginRight: "0px", cursor: "pointer" }}
                        onClick={() => {
                          newHireApproved(value, tableMeta);
                        }}
                        className="fas fa-eye"></i>
                    </Tooltip>
                  </div>
                );
              } else if (newHireStatus == "Pending") {
                return (
                  <div
                    className="centerStyles flex-justify-start"
                    style={{ padding: "0 8px 0 0" }}>
                    <Tooltip title="edit">
                      <i
                        style={{ marginRight: "0px", cursor: "pointer" }}
                        onClick={() => {
                          if(tableMeta.tableData[tableMeta.rowIndex].employeeType==="Employee"){
                          navigate("/newhireapproval", {
                            state: workflowTaskId,
                          });}
                          else{
                            navigate("/newHireApprovalclm", {
                              state: workflowTaskId,
                            });}
                        }}
                        className="fas fa-edit"></i>
                    </Tooltip>
                  </div>
                );
              }
            }
          } else if (ModuleProcessName === "PositionManagement") {
            if (activetab == "My Request") {
              if (seqNo == 1) {
                if (taskStatus == 1) {
                  return (
                    <EditOrView
                      type="edit"
                      url="/positionManagement" //sendback
                      id={workflowTaskId}
                      TId={TId}
                      action="sendback"
                    />
                  );
                } else {
                  return (
                    <EditOrView
                      type="view"
                      url="/positionManagement"
                      id={workflowTaskId}
                      TId={TId}
                      action="view"
                    />
                  );
                }
              }
            } else if (activetab == "My Action") {
              if (seqNo == 1) {
                return (
                  <EditOrView
                    type="edit"
                    url="/positionManagement" //sendback
                    id={workflowTaskId}
                    TId={TId}
                    action="sendback"
                  />
                );
              } else {
                return (
                  <EditOrView
                    type="edit"
                    url="/positionManagement" //reviewer
                    id={workflowTaskId}
                    TId={TId}
                    action="reviewer"
                  />
                );
              }
            } else {
              if (seqNo == 1) {
                if (taskStatus == 1) {
                  return (
                    <EditOrView
                      type="edit"
                      url="/positionManagement" //sendback
                      id={workflowTaskId}
                      TId={TId}
                      action="sendback"
                    />
                  );
                } else {
                  return (
                    <EditOrView
                      type="view"
                      url="/positionManagement" //view
                      id={workflowTaskId}
                      TId={TId}
                      action="view"
                    />
                  );
                }
              } else {
                return (
                  <EditOrView
                    type={taskStatus == 1 ? "edit" : "view"}
                    url="/positionManagement"
                    id={workflowTaskId}
                    TId={TId}
                  />
                );
              }
            }
          } else if (ModuleProcessName == "ManpowerRequisition") {
            const Sendback =
              String(WorkflowInstanceStatus).toLocaleLowerCase().trim() ===
              "sendback";

            if (WorkflowSystemName === "CandidateApproval") {
              const commonProps = {
                type: taskStatus === 2 ? "view" : "edit",
                url: "/mrf",
                id: workflowTaskId,
                TId: TId,
                sendback: Sendback,
                Tab: "ManpowerCandidates",
                CandidateId: CandidateId,
                isEvaluator,
              };

              return <EditOrView {...commonProps} />;
            } else {
              const commonProps = {
                type: taskStatus === 2 ? "view" : "edit",
                url: "/mrf",
                id: workflowTaskId,
                TId: TId,
                sendback: Sendback,
                Tab: "ManpowerProcess",
              };

              return <EditOrView {...commonProps} />;
            }
          } else if (ModuleProcessName == "OnBoarding") {
            const Sendback =
              String(WorkflowInstanceStatus).toLocaleLowerCase().trim() ===
              "pending";

            if (activetab == "My Action") {
              if (WorkflowSystemName === "OnBoardingShowNoShow") {
                const commonProps = {
                  type: taskStatus === 2 ? "view" : "edit",
                  url: "/mrf",
                  id: workflowTaskId,
                  TId: TId,
                  sendback: Sendback,
                  Tab: "ManpowerCandidates",
                  CandidateId: CandidateId,
                };

                return <EditOrView {...commonProps} />;
              } else {
                if (seqNo == "1") {
                  return (
                    <EditOrView
                      type="edit"
                      url="/OnBoardingCheckList"
                      id={workflowTaskId}
                    />
                  );
                } else if (seqNo == "2") {
                  return (
                    <EditOrView
                      type="edit"
                      url="/OnBoardingCheckList/ApprovalDashboard"
                      id={workflowTaskId}
                    />
                  );
                }
              }
            }
          } else {
            return (
              <div
                className="centerStyles flex-justify-start"
                style={{ padding: "0 8px 0 0" }}>
                <Tooltip title="View">
                  <i
                    style={{ marginRight: "0px", cursor: "pointer" }}
                    onClick={() => {
                      edit(value, tableMeta);
                    }}
                    className="fas fa-eye"></i>
                </Tooltip>
              </div>
            );
          }
        },
      },
    },
  ];

  const handleFetchUsers = async (event, callback, code, PID) => {
    try {
      if (event?.length > 3) {
        showLoader();
        const data = await getDropdownsData(code, PID, null, event, "");

        if (data && data?.length > 0) {
          callback(data);
        } else {
          callback([]);
        }
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  const defaultTheme = createTheme({});
  const { breakpoints } = defaultTheme;
  const tabsTheme = {
    ...defaultTheme,
    components: {
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: "0px !important",
            marginLeft: "-42px",
            [breakpoints.down("md")]: {
              minHeight: "0px !important",
              marginLeft: "0px",
            },
          },
        },
      },
    },
  };
  useEffect(()=>{
    if(storeTableMeta !== '')getHistoryData(storeTableMeta);
  },[page2]);
  const [counter, setCounter] = useState(0);
  const [checkBoxAllRemark, setCheckBoxAllRemark] = useState(false);
  console.log("counter:: ",counter);
  console.log("approveRowData.length:: ",approveRowData.length);
  return (
    <div className="container-fluid">
      <div className="d-flex align-items-center justify-content-between">
        {/* Tabs Are here */}
        <div className="col-lg-10 col-md-12 col-sm-12 ">
          <ThemeProvider theme={tabsTheme}>
            <Tabs
              value={activetabIndex}
              className="profile-tabs"
              onChange={() => {}}
              variant="scrollable"
              TabScrollButtonProps={{
                style: {
                  color: "black",
                },
              }}
              scrollButtons
              TabIndicatorProps={{
                style: { display: "none" },
              }}
              allowScrollButtonsMobile
              aria-label="scrollable tabs">
              <div className="tabBtn mt-2">
                <ul>
                  {tabs.map((tabName, index) => (
                    <li key={index}>
                      <button
                        onClick={() => {
                          setSectionValuesArray([]);
                          setStart(0); //imp
                          setActivetab(tabName);
                        }}
                        className={
                          tabName === activetab
                            ? "bttn active"
                            : "bttn border border-primary border-bottom-0"
                        }>
                        {tabName}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </Tabs>
          </ThemeProvider>
        </div>
        <div>
          <button hidden={true} className="btn btn-primary">
            <i className="fa fa-plus mr-2"></i>
            New Add
          </button>
        </div>
      </div>

      <div className="grid-wrapper grid_mob">
        <div className="row mx-auto filtBox">
          {/* Quick Filter */}
          {gridData &&
            gridData?.data?.d?.fa.map((cFilter, index) => (
              <React.Fragment key={index}>
                {cFilter.filt === "dropdown" ? (
                  <>
                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                      <label className="col-form-label text-white">
                        {cFilter.filn}
                      </label>
                      <SelectForm
                        isClearable
                        options={dropdownOptions[cFilter.filAN]}
                        placeholder={cFilter.filn}
                        isDisabled={false}
                        onChange={(event) => {
                          setFilterValue((prev) => {
                            return {
                              ...prev,
                              [cFilter.filAN]: event,
                            };
                          });
                        }}
                        isMulti={false}
                        noIndicator={false}
                        noSeparator={false}
                        value={filterValue[cFilter.filAN]}
                      />
                    </div>
                  </>
                ) : cFilter.filt === "multidropdown" ? (
                  <>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="form-group filtInp">
                        <span
                          className="mr-2 text-white"
                          style={{ float: "left" }}>
                          <label className="col-form-label">
                            {cFilter.filn}
                          </label>
                        </span>
                        <SelectForm
                          isClearable
                          options={dropdownOptions[cFilter.filAN]}
                          placeholder={cFilter.filn}
                          isDisabled={false}
                          onChange={(event) => {
                            setFilterValue((prev) => {
                              return { ...prev, [cFilter.filAN]: event };
                            });
                            setStart(0);
                            setReload(!reload); //imp
                          }}
                          isMulti={true}
                          noIndicator={false}
                          noSeparator={false}
                          value={filterValue[cFilter.filAN]}
                        />
                      </div>
                    </div>
                  </>
                ) : cFilter.filt == "searchabledropdown" ? (
                  <>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="form-group filtInp">
                        <span
                          className="mr-2 text-white"
                          style={{ float: "left" }}>
                          <label className="col-form-label">
                            {cFilter.filn}
                          </label>
                        </span>
                        <SelectForm
                          isClearable
                          async
                          options={(searchString, cb) =>
                            handleFetchUsers(
                              searchString,
                              cb,
                              cFilter.filAN,
                              null
                            )
                          }
                          placeholder={cFilter.filn}
                          isDisabled={false}
                          onChange={(event) => {
                            setFilterValue((prev) => {
                              return { ...prev, [cFilter.filAN]: event };
                            });

                            setStart(0);
                            setReload(!reload);
                          }}
                          isMulti={
                            activetab === "Manage Transactions" &&
                            cFilter.filAN === "initiator"
                              ? true
                              : false
                          }
                          noIndicator={false}
                          noSeparator={false}
                          value={filterValue[cFilter.filAN]}
                        />
                      </div>
                    </div>
                  </>
                ) : cFilter.filt === "text" ? (
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="form-group filtInp">
                      <InputForm
                        value={filterValue[cFilter.filAN] || ""}
                        placeholder={cFilter.filn}
                        isDisabled={false}
                        textArea={false}
                        onChange={(event) => {
                          setStart(0);
                          if (event) {
                            setFilterValue((prev) => {
                              return {
                                ...prev,
                                [cFilter.filAN]: event?.target.value,
                              };
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                ) : cFilter.filt === "dateTime" ? (
                  <>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div
                        className="form-group filtInp"
                        style={{ display: "flex" }}>
                        <span
                          className="mr-2 text-white"
                          style={{ float: "left", whiteSpace: "nowrap" }}>
                          <label className="col-form-label">
                            {cFilter.filn}
                          </label>
                        </span>

                        <div>
                          <DateForm
                            isDisabled={false}
                            value={filterValue[cFilter.filAN]}
                            onChange={(event) => {
                              setFilterValue((prev) => {
                                return {
                                  ...prev,
                                  [cFilter.filAN]: event,
                                };
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </React.Fragment>
            ))}
          <div className="col-lg-1 col-md-1 col-sm-12 filtrt">
            {/* Advanced Filter button */}
            <ul className=" filter-icon-wel">
              <li>
                <a
                  href="javascript:void(0);"
                  onClick={() => setOpen(!open)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                  className="filter-btn">
                  <i className="fa fa-filter"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Advanced Filter */}
      <>
        <Collapse in={open}>
          <div id="example-collapse-text grid-wrapper">
            <div className="row mx-auto myInlineStyle">
              {gridData &&
                gridData?.data?.d?.afa.map((cFilter, index) => (
                  <React.Fragment key={index}>
                    {cFilter.filt === "dropdown" ? (
                      <>
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                          <label className="col-form-label text-white">
                            {cFilter.filn}
                          </label>
                          <SelectForm
                            isClearable
                            options={dropdownOptions[cFilter.filAN]}
                            placeholder={cFilter.filn}
                            isDisabled={false}
                            onChange={(event) => {
                              setFilterValue((prev) => {
                                return {
                                  ...prev,
                                  [cFilter.filAN]: event,
                                };
                              });
                            }}
                            isMulti={false}
                            noIndicator={false}
                            noSeparator={false}
                            value={filterValue[cFilter.filAN]}
                          />
                        </div>
                      </>
                    ) : cFilter.filt === "multidropdown" ? (
                      <>
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                          <label className="col-form-label text-white">
                            {cFilter.filn}
                          </label>
                          <SelectForm
                            isClearable
                            options={dropdownOptions[cFilter.filAN]}
                            placeholder={cFilter.filn}
                            isDisabled={false}
                            onChange={(event) => {
                              setFilterValue((prev) => {
                                return {
                                  ...prev,
                                  [cFilter.filAN]: event,
                                };
                              });
                            }}
                            isMulti={true}
                            noIndicator={false}
                            noSeparator={false}
                            value={filterValue[cFilter.filAN]}
                          />
                        </div>
                      </>
                    ) : cFilter.filt == "searchabledropdown" ? (
                      <>
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                          <div className="form-group filtInp">
                            <label className="col-form-label text-white">
                              {cFilter.filn}
                            </label>
                            <SelectForm
                              isClearable
                              async
                              options={(searchString, cb) =>
                                handleFetchUsers(
                                  searchString,
                                  cb,
                                  cFilter.filAN,
                                  null
                                )
                              }
                              placeholder={cFilter.filn}
                              isDisabled={false}
                              onChange={(event) => {
                                setFilterValue((prev) => {
                                  return { ...prev, [cFilter.filAN]: event };
                                });
                              }}
                              isMulti={
                                activetab === "Manage Transactions" &&
                                (cFilter.filAN === "employee" ||
                                  cFilter.filAN === "approver")
                                  ? true
                                  : false
                              }
                              noIndicator={false}
                              noSeparator={false}
                              value={filterValue[cFilter.filAN]}
                            />
                          </div>
                        </div>
                      </>
                    ) : cFilter.filt === "dateTime" ? (
                      <>
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                          <div className="col-lg-3">
                            <label className="col-form-label text-white">
                              {cFilter.filn}
                            </label>
                          </div>

                          <div className="col-lg-9">
                            <DateForm
                              isDisabled={false}
                              value={filterValue[cFilter.filAN]}
                              onChange={(event) => {
                                setFilterValue((prev) => {
                                  return {
                                    ...prev,
                                    [cFilter.filAN]: event,
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>
                      </>
                    ) : cFilter.filt === "text" ? (
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <label className="col-form-label text-white">
                          {cFilter.filn}
                        </label>
                        <div className="form-group filtInp">
                          <InputForm
                            value={filterValue[cFilter.filAN] || ""}
                            placeholder={cFilter.filn}
                            isDisabled={false}
                            textArea={false}
                            onChange={(event) => {
                              setStart(0);
                              if (event) {
                                setFilterValue((prev) => {
                                  return {
                                    ...prev,
                                    [cFilter.filAN]: event?.target.value,
                                  };
                                });
                              }
                            }}
                          />
                        </div>
                      </div>
                    ) : null}
                  </React.Fragment>
                ))}
              <div className="col-lg-12 d-flex mt-2 justify-content-end">
                <button
                  onClick={resetFilters}
                  className="btn btn-secondary mr-2">
                  <i className="fa fa-undo" aria-hidden="true"></i> Reset{" "}
                </button>
                <button
                  className="btn btn-filter-submit"
                  onClick={onSubmitFilter}>
                  <i className="fa fa-check" aria-hidden="true"></i> Submit{" "}
                </button>
              </div>
            </div>
          </div>
        </Collapse>
      </>
      {activetab === "Manage Transactions" && (
        <>
          <div className="container-fluid mt-3">
            <button
              type="button"
              onClick={exportToExcel}
              className="btn btn-primary mr-2">
              <i className="fa fa-download mr-2"></i>
              Export To Excel
            </button>
          </div>
        </>
      )}

      {/* Grid */}
      <div className="mb-3 pt-3">
        <Modal
          show={showFormModal}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}>
          <Modal.Body>
            <p className="text-center m-2">
              {/* Are you sure you want approve selected Request IDs’? */}
              {
                checkBoxAllRemark 
                ? 'Are you sure you want approve All selected Request IDs’?' 
                : `Are you sure you want approve selected Request ID ${approveRowData?.[counter]?.recordID} - ${approveRowData?.[counter]?.recordTitle}?`}
            </p>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Add a Remarks !!"
              className="modalTextarea"
              // ref={inputRef}
              value={approveRowData?.[counter]?.remark}
              onChange={(e) => {
                if(!checkBoxAllRemark){
                  let tempAppRowData = [...approveRowData];
                  let tempAtt = tempAppRowData?.[counter];
                  tempAtt.remark = e.target.value;
                  setApproveRowData(tempAppRowData);
  
                  let tempRowData = [...rowData];
                  let tempAtt2 = tempAppRowData?.find((item)=> item.id === approveRowData?.[counter]?.id);
                  tempAtt2.remark = e.target.value;
                  setRowData(tempRowData);
                } else {
                  let tempAppRowData = [...approveRowData];
                  tempAppRowData.forEach((item)=>{
                    item.remark = e.target.value;
                  });
                  setApproveRowData(tempAppRowData);
  
                  let tempRowData = [...rowData];
                  tempRowData.forEach((item)=>{
                    item.remark = e.target.value;
                  });
                  setRowData(tempRowData);
                }
              	// setRemark(e.target.value);
              	// setShowError(false);
              }}
            />
            {showError && (
              <p className="text-danger">Please fill in the remark.</p>
            )}
            {
              counter === 0 &&
              <div>
                <input
                  type="checkbox"
                  id="chk25"
                  className="mr-2"
                  checked={checkBoxAllRemark}
                  onChange={() => {
                    setCheckBoxAllRemark(!checkBoxAllRemark);
                    const allRemark = approveRowData?.[counter]?.remark;

                    let tempAppRowData = [...approveRowData];
                    tempAppRowData.forEach((item)=>{
                      item.remark = allRemark;
                    });
                    setApproveRowData(tempAppRowData);
                    
                    let tempRowData = [...rowData];
                    tempRowData.forEach((item)=>{
                      item.remark = allRemark;
                    });
                    setRowData(tempRowData);
                  }}
                />
                <label className="col-form-label" htmlFor="dropdown25">
                  Copy this remark to All Transaction.
                </label>
              </div>
            }
            {" "}{ 
              approveRowData.length > 1 && counter > 0 && !checkBoxAllRemark &&
              <Button variant="secondary" className="approveer-btn-margin" onClick={()=>{
                setCounter(counter-1);
              }}>
                Previous Transaction
              </Button>
            }{" "}
            {" "}{
              approveRowData.length > 1 && counter < approveRowData.length-1 && !checkBoxAllRemark &&
              <Button className="approve-sucess-btn" onClick={()=>{
                setCounter(counter+1);
              }}>
                Next Transaction
              </Button>
            }{" "}
          </Modal.Body>
          <Modal.Footer>
            <Button className="approve-sucess-btn" onClick={submitApproval}>
              Approve
            </Button>{" "}
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <DynamicGrid
          data={rowData}
          columns={columnsWithCustomRender}
          options={options}
        />
        {activetab === "My Action" && (
          <div className="d-flex justify-content-lg-end align-items-center">
            <button
              onClick={() => {
                handleApproval();
              }}
              className="btn approve-sucess-btn my-2"
              disabled={selectedIds.length === 0}>
              Approve
            </button>
          </div>
        )}
      </div>
      {showModal && <Modal
        show={showModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}>
        <Modal.Body>
          <div>
            <div className="row">
              <div className="col-md-4">
                <label className="col-form-label">
                  Record ID:{" "}
                  {infoState && infoState.rowData ? infoState.rowData[1] : ""}
                </label>
              </div>
              <div className="col-md-8">
                <label className="col-form-label">
                  Activity:{" "}
                  {infoState && infoState.rowData ? infoState.rowData[3] : ""}
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown24">
                    Approval Pending With:<sup>*</sup>
                  </label>

                  <SelectForm
                    isClearable
                    options={reRouteFromApprovers}
                    placeholder={"Select Approver"}
                    isDisabled={false}
                    onChange={(event) => {
                      handleOnChange("ReRouteApproverFrom", event);
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                    value={approverFromValue}
                  />
                  <div className="text-danger">{}</div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown24">
                    Re-route To:<sup>*</sup>
                  </label>
                  <SelectForm
                    async
                    isClearable
                    value={approverToValue}
                    placeholder="Select Employee"
                    options={(searchString, cb) =>
                      handleFetchUsers(
                        searchString,
                        cb,
                        "ReRouteApproverTo",
                        null
                      )
                    }
                    noIndicator={false}
                    noSeparator={false}
                    onChange={(event) => {
                      handleOnChange("ReRouteApproverTo", event);
                    }}
                  />
                  <div className="text-danger">{}</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div>
                  <input
                    type="checkbox"
                    id="chk25"
                    className="mr-2"
                    disabled = {isEmpty(approverFromValue)}
                    checked={reRouteAllRequests}
                    onChange={(event) => {
                      handleCheckBoxChange(event);
                    }}
                  />
                  <label className="col-form-label" htmlFor="dropdown25">
                    Would you like to redirect all pending requests from the selected users?
                  </label>
                </div>
              </div>
            </div>
            {reRouteAllRequests && (
              <div className="row">
                <div className="col-md-8">
                  <div>
                    <label className="col-form-label" htmlFor="dropdown25">
                      Total {aimedRequestsCount} requests will be impacted.
                    </label>
                  </div>
                </div>
                <div className="col-md-4">
                  <Button onClick={handlePendingRequestsExcel}>
                    <i className="fa fa-download mr-2"></i> 
                    Download Excel</Button>{' '}
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown23">
                    Remarks <sup>*</sup>
                  </label>
                  <InputForm
                    id="dropdown23"
                    value={reRouteRequestRemark}
                    textArea={true}
                    placeholder="Re-route Reason/Remarks"
                    onChange={(event) => {
                      setReRouteRequestRemark(event.target.value);
                    }}
                    isDisabled={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            onClick={async () => {
              await ReRouteEmployeeRequests();
            }}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>}
      {showCancelModal && <Modal
        show={showCancelModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}>
        <Modal.Body>
          <div>
            <div className="row">
              <div className="col-md-12">
                <div>
                  <label className="col-form-label" htmlFor="dropdown25">
                  Are you sure you want to cancel the Permenantly cancel this request?
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown23">
                    Remarks <sup>*</sup>
                  </label>
                  <InputForm
                    id="dropdown23"
                    value={cancelRequestRemark}
                    textArea={true}
                    placeholder="Cancel Reason/Remarks"
                    onChange={(event) => {
                      setCancelRequestRemark(event.target.value);
                    }}
                    isDisabled={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCancelRequest}>Cancel</Button>
          <Button
            onClick={cancelEmployeeRequests}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>}
    </div>
  );
};

export default CommonDashboard;
