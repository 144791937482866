import React, { useContext, useEffect, useState } from "react";
import "./StructureType.css";
import { Button } from "react-bootstrap";
import notify from "../../Helpers/ToastNotification";
import { useNavigate } from "react-router-dom";
import { LoaderContext, getContext } from "../../Helpers/Context/Context";
import { Tooltip } from "@mui/material";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { APICall } from "../../Helpers/APICalls";
import {
  GetStructureTypeGridData,
  GetStructureTypeGridDataExport,
} from "../../Helpers/APIEndPoints/EndPoints";
import fileDownload from "js-file-download";
import axios from "axios";
import InputForm from "../../Components/InputForm/InputForm";
import AccessDenied from "../AccessDenied/AccessDenied";

const StructureType = () => {
  let navigate = useNavigate();
  const context = getContext();
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [gridData, setGridData] = useState([]);
  const [gridPageSize, setGridPageSize] = useState(10);
  const [gridStart, setGridStart] = useState(0);
  const [gridSortColumn, setGridSortColumn] = useState("");
  const [gridSortDirection, setGridSortDirection] = useState("");
  const [gridSearchText, setGridSearchText] = useState("");
  const [gridCount, setGridCount] = useState(0);
  const [page, setPage] = useState(0);

  useEffect(() => {
    (async () => {
      showLoader();
      await getGridData();
      hideLoader();
    })();
  }, [
    gridStart,
    gridPageSize,
    gridSearchText,
    gridSortColumn,
    gridSortDirection,
  ]);

  async function getGridData() {
    const { data } = await APICall(GetStructureTypeGridData, "POST", {
      Start: gridStart,
      PageSize: gridPageSize,
      SearchText: gridSearchText,
      SortColumn: gridSortColumn,
      SortDirection: gridSortDirection,
    });

    if (data !== null) {
      setGridData(data.data);
      if (data.totalCount) setGridCount(data.totalCount);
    } else {
      setGridData([]);
      setGridCount(0);
    }
  }

  const exportToExcel = async () => {
    showLoader();
    axios
      .request({
        responseType: "blob",
        method: "POST",
        url: GetStructureTypeGridDataExport,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "*",
        },
        data: {
          Start: 0,
          PageSize: gridCount,
          SearchText: gridSearchText,
          SortColumn: gridSortColumn,
          SortDirection: gridSortDirection,
        },
      })
      .then((response) => {
        fileDownload(response.data, "Strcuture Type.xlsx");
      })
      .catch((error) => {
        console.log(error.response.data);
      });
    hideLoader();
  };

  const gridColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        display: false,
        sort: false,
      },
    },
    {
      name: "name",
      label: "Structure Type",
      options: {
        filter: false,
        display: true,
        sort: true,
      },
    },

    {
      name: "isActive",
      label: "Is Active",
      options: {
        filter: false,
        display: true,
        sort: false,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "center" },
        }),
        customBodyRender: (value) => {
          if (value) {
            return <i className="fas fa-check"></i>;
          } else {
            return <i className="fas fa-times"></i>;
          }
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta) => {
          let id = tableMeta.tableData[tableMeta.rowIndex].id;
          return (
            <div className="d-flex justify-content-center">
              <Tooltip title="edit">
                <a
                  className="mr-2"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/structureTypeEdit", {
                      state: { structureId: id },
                    });
                  }}>
                  <i className="fas fa-edit"></i>
                </a>
              </Tooltip>
            </div>
          );
        },
      },
    },
  ];

  const gridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: gridCount,
    rowsPerPage: gridPageSize,
    page: page,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard", //standard | vertical | simple
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setGridSortColumn(sortColumn);
        await setGridSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setGridSortColumn(sortColumn);
        await setGridSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      await setPage(page);
      await setGridStart(page * gridPageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  useEffect(() => {
    console.log("searchText", gridSearchText.length);
    if (gridSearchText.length !== 0) {
      setGridSearchText(gridSearchText);
      setGridStart(0);
      setPage(0);
    } else {
      setGridStart(0);
      setPage(0);
    }
  }, [gridSearchText]);

  return (
    <>
      {context.loggedInAs === 1 ? (
        <>
          <div className="form-main px-3">
            <div className="page-title w-100">
              <div className="col-lg-12 p-0">
                <div className="row">
                  <div className="col-lg-4 col-md-4">
                    <h4>Structure Type</h4>
                  </div>
                  <div className="col-lg-4 col-md-2"></div>
                  <div className="col-lg-4 col-md-6">
                    <div
                      className="float-right col-sm-offset-2"
                      style={{
                        marginBottom: 10,
                      }}>
                      <Tooltip title="export to excel">
                        <button
                          className="btn btn-export"
                          onClick={() => {
                            if (gridData.length === 0) {
                              notify(1, "No data available");
                            } else {
                              exportToExcel();
                            }
                          }}>
                          <i className="fa fa-download"></i>
                        </button>
                      </Tooltip>
                      <>
                        <button
                          className="btn btn-create btn btn-primary"
                          style={{
                            marginLeft: 5,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            navigate("/structureTypeEdit", {
                              state: {
                                structureId: 0,
                              },
                            });
                          }}>
                          + New Structure Type
                        </button>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page-content row filter-row-container m-0">
            <div className="transformer-tabs col-lg-12 col-md-12">
              <div className="meeting-tabs tabs-main col-lg-12 pl-0">
                <ul className="filters">
                  <li className="row d-flex justify-content-end">
                    <div className="col-lg-3 col-sm-3 col-xs-10 p-0 mobile-view top-m searchInput ">
                      <InputForm
                        value={gridSearchText}
                        placeholder={"Search"}
                        isDisabled={false}
                        textArea={false}
                        onChange={(e) => {
                          setGridSearchText(e.target.value);
                        }}
                      />
                      <i
                        className="fa fa-search ml-2 mt-1"
                        aria-hidden="true"></i>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <DynamicGrid
              options={gridOptions}
              data={gridData}
              columns={gridColumns}
            />
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};

export default StructureType;
