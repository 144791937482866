import { create } from "zustand";

const useECStore =  create( (set)=> ({
    EmployeeClass : null,
    addEmployeeClass: (data) => set({ EmployeeClass : data }),
    showSkillsTab: {workerSkillSelected: false, nonWorkerSkillSelected: false},
    addShowSkillsTab: (data) => set({showSkillsTab : data })
    
}));

export default useECStore; 