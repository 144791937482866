import React, { useContext, useEffect, useState, useRef } from "react";
import { APICall } from "../../Helpers/APICalls";
import {
  getAllTabsOrgUnitMaster,
  getAllModuleOrgUnitMaster,
  getSecationsAsPerTabId,
  // getAttributesAsPerSections,
  postDataBulkInsert,
} from "../../Helpers/APIEndPoints/EndPoints";
import "../../Pages/BulkUpload/BulkUpload.css";
import SelectForm from "../../Components/SelectForm/SelectForm";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import * as xlsx from "xlsx";
import ExcelJS from "exceljs/dist/exceljs.min.js";
import notify from "../../Helpers/ToastNotification";
import { LoaderContext } from "../../Helpers/Context/Context";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { ExpandMore } from "@mui/icons-material";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const checkDateArr = [
  "Date of Birth",
  "Group Joining Date",
  "Business Joining Date",
  "Retirement Date",
  "Position Entry Date",
  "Event Effective Date",
  "Valid Till",
  "From Date",
  "End Date",
  "Effective Date",
  "Start Date",
  "Date of Examination",
  "Vaccination Date (Dose1)",
  "Vaccination Date (Dose2)",
  "Vaccination Date (Dose3)",
  "Challenge Start Date",
  "Challenge End Date",
  "Date",
  "Issue Date",
  "Expiration Date",
  "Nominee Start Date",
  "Nominee End Date",
  "Lease Start date",
  "Lease End Date",
];

function BulkUpload() {
  const navigate = useNavigate();
  const [moduleDropdownOptions, setModuleDropdownOptions] = useState([]);
  const [moduleDropdownValue, setModuleDropdownValue] = useState("");
  const [moduleErrorDropdown, setModuleErrorDropdown] = useState("");
  const [tabDropdownOptions, setTabDropdownOptions] = useState([]);
  const [TabDropdownValue, setTabDropdownValue] = useState("");
  const [tabErrorDropdown, setTabErrorDropdown] = useState("");
  const [sectionDropdownOptions, setSectionDropdownOptions] = useState([]);
  const [sectionDropdownValue, setSectionDropdownValue] = useState(null);
  const [sectionErrorDropdown, setSectionErrorDropdown] = useState("");
  const { userDetails }: any = useUserContext();
  const [selectedFileName, setSelectedFileName] = useState("");
  const [file2, setFile2] = useState(null);
  const [result, setResult] = useState("");
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const supportingDocRef = useRef(null);
  //fetch modules
  useEffect(() => {
    fetchModuleData();
  }, []);

  const fetchModuleData = async () => {
    try {
      const responseMod = await APICall(getAllModuleOrgUnitMaster, "POST", {});

      if (responseMod.data && responseMod.data.length > 0) {
        const firstItem = responseMod.data[0];
        setModuleDropdownOptions([firstItem]);
        setModuleDropdownValue(firstItem.value);
      } else {
        setModuleDropdownOptions([]);
        setModuleDropdownValue(null);
      }

      //console.log(responseMod.data);
    } catch (error) {
      console.error("Error fetching Module data:", error);
    }
  };

  // Fetch all tab dropdown options
  //need to fetch tab based on selected module
  const fetchTabData = async () => {
    try {
      if (!moduleDropdownValue) {
        setModuleDropdownValue(""); // Set it to the default value
        setModuleDropdownOptions([]); // Clear section options
      } else {
        const response = await APICall(getAllTabsOrgUnitMaster, "POST", {
          ModuleId: moduleDropdownValue,
        });

        const includedTabsNames = ["Personal", "Employment"];
        const filteredTabOptions = response.data.filter((option) =>
          includedTabsNames.includes(option.label)
        );

        if (filteredTabOptions.length > 0) {
          setTabDropdownOptions(filteredTabOptions);
          // setTabDropdownValue(filteredTabOptions.value);
        }
      }
    } catch (error) {
      console.error("Error fetching tab data:", error);
    }
  };

  useEffect(() => {
    fetchTabData();
  }, [moduleDropdownValue]);

  // Fetch sections based on selected tab
  useEffect(() => {
    fetchSections();
  }, [TabDropdownValue]);

  const fetchSections = async () => {
    try {
      if (!TabDropdownValue) {
        setSectionDropdownValue(null);
        setSectionDropdownOptions([]);
      } else {
        // Fetch sections based on the selected tab
        const response = await APICall(getSecationsAsPerTabId, "POST", {
          tabId: TabDropdownValue,
        });

        // Define the section names you want to include
        const includedSectionNames = [
          "Personal Information & Global Information",
          "National ID Information",
          "Bank Information",
          "Formal Education",
          "Contact Information",
          "Emergency Information",
          "Previous Employment",
          "Health Information",
          "Physically Challenged",
          "Asset Management",
          "Family in Welspun",
          "Additional Skills",
          "Geographic Mobility",
          "Work Permit info",
          "Professional Certification & Licences",
          "Work Association",
          "Nominee Details",
          "Email Information",
          "Address Information",
          "Proficiency Skills",
          "Compensation",
          "Family Members Details",
          "Personal Information",
          "Organization Information",
          "Employment Details",
        ];

        // Filter out specific items based on the includedSectionNames
        const filteredSectionOptions = response.data.filter((option) =>
          includedSectionNames.includes(option.label)
        );

        // Rename the "Personal Information & Global Information" section
        let renamedOptions = filteredSectionOptions.map((option) => {
          if (option.label === "Personal Information & Global Information") {
            return { ...option, label: "Employee Creation" };
          }
          return option;
        });

        // Added new conditions for new dropdown,Raj Potdar, Date : 25-01-2024
        if (TabDropdownValue == "1") {
          renamedOptions.push({
            value: 10130,
            label: "Personal Information",
            code: "Personal",
          });
          // renamedOptions.push({ value: 999, label: 'Organization Information', code: 'OrganisationInfoBulkUpload' })
        }
        if (TabDropdownValue == "2") {
          // renamedOptions.push({ value: 989, label: 'Personal Information', code: 'Personal' })
          renamedOptions = renamedOptions.filter(
            (f) => f.code !== "JobOrganisationInformation"
          );
          renamedOptions.push({
            value: 10131,
            label: "Organization Information",
            code: "OrganisationInfoBulkUpload",
          });
          // renamedOptions.push({ value: 41, label: 'Employment Details', code: 'EmploymentDetails' })
        }

        setSectionDropdownOptions(renamedOptions);
        setSectionDropdownValue(null);
      }
    } catch (error) {
      console.error("Error fetching sections:", error);
    }
  };

  // Handle module change
  const handleModuleChange = (selectedOption: any) => {
    showLoader();
    setModuleDropdownValue(selectedOption ? selectedOption.value : "");
    setModuleErrorDropdown("");
    // setTabErrorDropdown("");
    setSectionDropdownValue(null);
    hideLoader();
  };

  // Handle tab change
  const handleTabChange = (selectedOption: any) => {
    showLoader();
    setTabDropdownValue(selectedOption ? selectedOption.value : "");
    setSectionDropdownValue(null);
    setModuleErrorDropdown("");
    setTabErrorDropdown("");

    hideLoader();
  };

  // Handle section change
  const handleSectionChange = (selectedOption: any) => {
    showLoader();
    setSectionDropdownValue(selectedOption ? selectedOption.value : "");
    setSectionErrorDropdown("");
    hideLoader();
  };

  // Handle file2 upload
  const handleFile2Upload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      showLoader();
      const selectedFile = event.target.files[0];
      const fileType = await getFileType(selectedFile);

      if (!fileType || (fileType !== "xls" && fileType !== "xlsx")) {
        notify(1, "Please select an Excel file (xls or xlsx).");
        return;
      }
      event.target.value = "";
      setFile2(selectedFile);
    } catch (error) {
      console.error(error);
    } finally {
      setResult("");
      hideLoader();
    }
  };

  // // Get the file type based on magic numbers
  const getFileType = (file: File) => {
    const magicNumbers = {
      xls: [208, 207, 17, 224],
      xlsx: [80, 75, 3, 4],
    };

    const reader = new FileReader();

    return new Promise((resolve) => {
      reader.onload = (e) => {
        const buffer = e.target.result as ArrayBuffer;
        const view = new Uint8Array(buffer);
        for (const fileType in magicNumbers) {
          if (magicNumbers.hasOwnProperty(fileType)) {
            const magicNumber = magicNumbers[fileType];
            let isMatch = true;
            for (let i = 0; i < magicNumber.length; i++) {
              if (magicNumber[i] !== view[i]) {
                isMatch = false;
                break;
              }
            }
            if (isMatch) {
              resolve(fileType);
              return;
            }
          }
        }
        resolve(null);
      };

      // Ensure that the 'file' parameter is a Blob before reading it.
      if (file instanceof Blob) {
        reader.readAsArrayBuffer(file);
      } else {
        resolve(null);
      }
    });
  };

  const readLocalExcelFile = async () => {
    try {
      const selectedTab = tabDropdownOptions?.find(
        (option) => option.value === TabDropdownValue
      );
      const selectedSection = sectionDropdownOptions?.find(
        (option) => option.value === sectionDropdownValue
      );

      if (!selectedTab || !selectedSection) {
        console.error("Selected tab or section not found.");
        return;
      }

      const tabName = selectedTab.label; // Get the label of the selected tab
      const sectionName = selectedSection.label;
      const sanitizedSectionName = sectionName.replace(/\//g, "_");

      const specialCases = [
        { section: "JobOrganisationInformation", tab: "Employment" },
        // { section: "EmploymentDetails", tab: "Employment" },
        // Add more special cases as needed
      ];

      const isSpecialCase = specialCases.some(
        (caseItem) =>
          selectedSection.code === caseItem.section &&
          selectedTab.code === caseItem.tab
      );

      if (isSpecialCase) {
        // Read "Personal_Personal Information & Global Information.xlsx" directly
        const response = await fetch(
          "Personal_Personal Information & Global Information.xlsx"
        );
        const blob = await response.blob();

        return new Promise<Blob>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = async (event) => {
            try {
              const data = event.target.result;
              const workbook = xlsx.read(data, { type: "array" });

              const sheetName = workbook.SheetNames[0];
              const worksheet = workbook.Sheets[sheetName];
              const jsonData = xlsx.utils.sheet_to_json(worksheet, {
                header: 1,
              });

              //console.log("local Json Data:", jsonData);

              resolve(blob as Blob);
            } catch (error) {
              reject(error);
            }
          };

          reader.onerror = (error) => {
            reject(error);
          };

          reader.readAsArrayBuffer(blob);
        });
      } else {
        // Read the regular local file
        const localFilePath = `${tabName}_${sanitizedSectionName}.xlsx`;
        const response = await fetch(localFilePath);
        const blob = await response.blob();

        return new Promise<Blob>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = async (event) => {
            try {
              const data = event.target.result;
              const workbook = xlsx.read(data, { type: "array" });

              const sheetName = workbook.SheetNames[0];
              const worksheet = workbook.Sheets[sheetName];
              const jsonData = xlsx.utils.sheet_to_json(worksheet, {
                header: 1,
              });

              //console.log("local Json Data:", jsonData);

              resolve(blob as Blob);
            } catch (error) {
              reject(error);
            }
          };

          reader.onerror = (error) => {
            reject(error);
          };

          reader.readAsArrayBuffer(blob);
        });
      }
    } catch (error) {
      console.error("Error reading local Excel file:", error);
      throw error;
    }
  };
  const dateFormats = [
    "DD/MM/YY",
    "DD/MM/YYYY",
    "DD-MMM-YY",
    "DD-MMM-YYYY",
    "DD/MMM/YY",
    "DD/MMM/YYYY",
    "DD-MMM-YYYY",
    "DD-MM-YYYY",
  ];

  function convertDateFormatForSave(dateString) {
    //console.log('dateString', dateString)
    const parsedDate = moment.utc(dateString, dateFormats, true).locale("en");
    if (dateString.includes("/")) {
      return parsedDate.utc().format("DD-MMM-YYYY");
    } else if (moment(dateString, "DD-MM-YYYY", true).isValid()) {
      // If the input format is "DD-MM-YYYY", save it as "DD-MMM-YYYY"
      return parsedDate.utc().format("DD-MMM-YYYY");
    }

    return parsedDate.utc().format("DD-MMM-YYYY");
  }

  function convertExcelDateToJSDate(excelDate) {
    //console.log('excelDate', excelDate)
    const baseDate = new Date("1900-01-01T00:00:00Z");

    // Calculate the number of milliseconds from the base date
    const millisecondsSinceBaseDate = (excelDate - 2) * 24 * 60 * 60 * 1000;

    // Create a new Date object by adding milliseconds to the base date
    const jsDate = new Date(baseDate.getTime() + millisecondsSinceBaseDate);

    return jsDate;
  }

  function convertDateFormatForDisplay(dateString) {
    const parsedDate = moment.utc(dateString, dateFormats, true).locale("en");
    return parsedDate.utc().format("DD-MMM-YYYY");
  }

  // Handle file comparison
  const handleCompareFiles = async () => {
    await showLoader();
    let isValid = true;
    const mismatchedHeaders = []; // Store mismatched headers
    if (!moduleDropdownValue) {
      setModuleErrorDropdown("Please select module.");
      isValid = false;
      hideLoader();
    }
    if (!TabDropdownValue) {
      setTabErrorDropdown("Please select Tab.");
      isValid = false;
      hideLoader();
    }

    if (!sectionDropdownValue) {
      setSectionErrorDropdown("Please select Section.");
      isValid = false;
      hideLoader();
    }
    if (file2) {
      setResult(null);
    }
    const fileInput = document.getElementById("file2") as HTMLInputElement;
    if (!file2) {
      setResult("Please select a file for upload.");
      isValid = false;
      hideLoader();
    }
    // fileInput.value = "";
    if (isValid) {
      try {
        const selectedTab = tabDropdownOptions?.find(
          (option) => option.value === TabDropdownValue
        );
        const selectedSection = sectionDropdownOptions?.find(
          (option) => option.value === sectionDropdownValue
        );

        if (!selectedTab || !selectedSection) {
          console.error("Selected tab or section not found.");
          return;
        }

        const tabName = selectedTab.label;
        const sectionName = selectedSection.label;
        const sanitizedSectionName = sectionName.replace(/\//g, "_");
        const fileName = `${tabName}_${sanitizedSectionName}.xlsx`;
        // Read the local Excel file and convert it to a Blob
        const localExcelData = await readLocalExcelFile();
        const localExcelBlob = new Blob([localExcelData], {
          type: "application/octet-stream",
        });

        const reader1 = new FileReader();
        reader1.onload = async (event1) => {
          try {
            const file1Data = event1.target.result as ArrayBufferLike;

            const file2Blob = file2.slice(0, file2.size);
            const reader2 = new FileReader();
            reader2.onload = async (event2) => {
              try {
                const file2Data = event2.target.result as ArrayBufferLike;

                const userWorkbook: xlsx.WorkBook = xlsx.read(file2Data, {
                  type: "binary",
                });

                const localWorkbook = xlsx.read(file1Data, { type: "binary" });

                const localSheetName = localWorkbook.SheetNames[0];
                const userSheetName = userWorkbook.SheetNames[0];

                const localSheet = localWorkbook.Sheets[localSheetName];
                const userSheet = userWorkbook.Sheets[userSheetName];

                const isDataProvided = Array.from({ length: 9 }).some(
                  (_, rowIndex) => {
                    return !["A", "B", "C", "D", "E", "F", "G", "H", "I"].every(
                      (column) => {
                        const cellValue =
                          userSheet[
                            column + (rowIndex + 2).toString() // A2, B2, ..., A10, B10, ...
                          ];
                        return (
                          !cellValue ||
                          (!cellValue.v && cellValue.v !== 0) ||
                          !cellValue.v.toString().trim()
                        );
                      }
                    );
                  }
                );

                if (!isDataProvided) {
                  notify(1, "Template should not empty.");
                  hideLoader();
                  return;
                }

                const localHeaders = [];
                const userHeaders = [];

                // Extract headers from both sheets
                for (const cell in localSheet) {
                  if (
                    cell !== "!ref" &&
                    cell !== "!margins" &&
                    cell.endsWith("1")
                  ) {
                    localHeaders.push(localSheet[cell].v);
                  }
                }

                for (const cell in userSheet) {
                  if (
                    cell !== "!ref" &&
                    cell !== "!margins" &&
                    cell.endsWith("1")
                  ) {
                    userHeaders.push(userSheet[cell].v);
                  }
                }

                // Compare headers
                for (let i = 0; i < localHeaders.length; i++) {
                  if (localHeaders[i] !== userHeaders[i]) {
                    mismatchedHeaders.push({
                      localHeader: localHeaders[i],
                      userHeader: userHeaders[i],
                    });
                  }
                }

                // Check if user-selected file data from A2 to A10 is empty or deleted

                if (mismatchedHeaders.length === 0) {
                  ///console.log('asddes');
                  // notify(0, "Column headers match.");

                  const localSheetData = xlsx.utils.sheet_to_json(localSheet, {
                    header: 1,
                  });
                  const userSheetData = xlsx.utils.sheet_to_json(userSheet, {
                    header: 1,
                  });

                  // Create a new ExcelJS workbook
                  const workbook = new ExcelJS.Workbook();
                  const worksheet = workbook.addWorksheet("Sheet 1");

                  // Add your data to the worksheet row by row
                  const formattedUserSheetData: any[][] = userSheetData.map(
                    (row: any[], rowIndex: number) => {
                      return row.map((cell: any, columnIndex: number) => {
                        // Get the column header name based on the columnIndex
                        const columnHeader = userHeaders[columnIndex];

                        // Check if the cell contains a date string and the column header is "Event Effective Date"
                        if (
                          checkDateArr.includes(columnHeader) &&
                          moment(cell, dateFormats, true).isValid()
                        ) {
                          // For saving to the server in the desired format
                          const saveFormat = convertDateFormatForSave(cell);
                          return saveFormat;
                        } else if (
                          checkDateArr.includes(columnHeader) &&
                          cell > 0
                        ) {
                          // Excel Serial Date handling for numeric columns in "Event Effective Date" column
                          const regularDate = convertExcelDateToJSDate(cell);
                          return convertDateFormatForDisplay(regularDate);
                        }
                        // else if (
                        //   typeof cell === "number" &&
                        //   cell > 0 &&
                        //   columnHeader !== "Amount" && columnHeader !== "Amount"
                        // ) {
                        //   // Excel Serial Date handling for numeric columns (excluding "Amount" column)
                        //   const regularDate = convertExcelDateToJSDate(cell);
                        //   return convertDateFormatForDisplay(regularDate);
                        // }

                        return cell;
                      });
                    }
                  );

                  // Add your data to the worksheet row by row
                  for (const rowData of formattedUserSheetData) {
                    worksheet.addRow(rowData);
                  }

                  // Convert the workbook to binary data
                  const buffer = await workbook.xlsx.writeBuffer();

                  //console.log('buffer', buffer, formattedUserSheetData, worksheet);

                  // Convert buffer to base64
                  const base64Data = btoa(
                    new Uint8Array(buffer).reduce(
                      (data, byte) => data + String.fromCharCode(byte),
                      ""
                    )
                  );

                  const response = await APICall(postDataBulkInsert, "POST", {
                    UserId: userDetails.Id,
                    SectionId: sectionDropdownValue,
                    TabId: TabDropdownValue,
                    Data: base64Data,
                    FileName: fileName,
                  });
                  //console.log("Base64 Data after compiling:", base64Data);
                  //console.log("API response:", response);
                  if (response.message === "Success") {
                    notify(
                      0,
                      `Bulk Upload Data Successful. Your request upload id is ${response.data}.`
                    );
                    supportingDocRef.current.value = null;
                    hideLoader();
                  } else {
                    notify(
                      1,
                      `Bulk Upload Data Unsuccessful. ${response.message}`
                    );
                    hideLoader();
                  }
                } else {
                  notify(1, "The Column Headers do not Match");
                  hideLoader();
                }
              } catch (error) {
                console.log("Error reading user-selected file:", error);
                hideLoader();
              }
            };

            reader2.readAsBinaryString(file2Blob);
          } catch (error) {
            console.log("Error reading user-selected file:", error);
            hideLoader();
          }
        };

        reader1.readAsBinaryString(localExcelBlob);
      } catch (error) {
        console.log("Error comparing files:", error);
        hideLoader();
      }
    }
    setFile2(null);
  };

  // Handle template download click

  // Check additional conditions for downloading a different file
  const handleDownloadClick = async () => {
    let isValid = true;

    if (!moduleDropdownValue) {
      setModuleErrorDropdown("Please select a value for Module.");
      isValid = false;
    }

    if (!TabDropdownValue) {
      setTabErrorDropdown("Please select a value for Tab.");
      isValid = false;
    }

    if (!sectionDropdownValue) {
      setSectionErrorDropdown("Please select a value for Section.");
      isValid = false;
    }

    if (isValid) {
      try {
        const selectedTab = tabDropdownOptions?.find(
          (option) => option.value === TabDropdownValue
        );
        const selectedSection = sectionDropdownOptions?.find(
          (option) => option.value === sectionDropdownValue
        );

        if (!selectedTab || !selectedSection) {
          console.error("Selected tab or section not found.");
          return;
        }

        const tabName = selectedTab.label;
        const sectionName = selectedSection.label;
        const sanitizedSectionName = sectionName.replace(/\//g, "_");
        const fileName = `${tabName}_${sanitizedSectionName}.xlsx`;

        let fName = "";

        // Check additional conditions for downloading a different file
        // if (
        //   selectedSection.code === "JobOrganisationInformation" &&
        //   selectedTab.code === "Employment"
        // ) {
        //   fName = "Personal_Personal Information & Global Information.xlsx";
        // } else if (
        //   selectedSection.code === "EmploymentDetails" &&
        //   selectedTab.code === "Employment"
        // ) {
        //   fName = "Personal_Personal Information & Global Information.xlsx";
        // }

        // Removed the First If as a new file is being added,Raj Potdar, Date : 23-01-2024
        // Removed the this section for employment details, Raj Potdar, Date : 05-02-2024
        // if (
        //   selectedSection.code === "EmploymentDetails" &&
        //   selectedTab.code === "Employment"
        // ) {
        //   fName = "Personal_Personal Information & Global Information.xlsx";
        // }

        if (fName) {
          // Download the specified file if fName is set
          const publicURL = `${process.env.PUBLIC_URL}/${fName}`;
          const response = await fetch(publicURL);

          if (response.status === 200) {
            // File exists, so trigger the download
            const blob = await response.blob();
            const dataURI = URL.createObjectURL(blob);

            const a = document.createElement("a");
            a.href = dataURI;
            a.download = fName;
            a.target = "_blank";
            a.click();
          } else {
            console.error(`File ${fName} not found in the public folder.`);
          }
        } else {
          // Proceed with the default download logic
          const publicURL = `${process.env.PUBLIC_URL}/${fileName}`;
          const response = await fetch(publicURL);

          if (response.status === 200) {
            // File exists, so trigger the download
            const blob = await response.blob();
            const dataURI = URL.createObjectURL(blob);

            const a = document.createElement("a");
            a.href = dataURI;
            a.download = fileName;
            a.target = "_blank";
            a.click();
          } else {
            console.error(`File ${fileName} not found in the public folder.`);
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-end mt-3">
        <button
          onClick={() => navigate("/home")}
          className="btn btn-primary ml-2">
          <i className="fa-solid fa-arrow-left mr-1"></i> Back
        </button>
      </div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          style={{ background: "#3C5464" }}
          className="text-white acc_close"
          expandIcon={<ExpandMore />}
        >
          <Typography>Employee Import</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="row">
            {/* Module is here */}
            <div className="col-md-4">
              <div className="form-group mt-3">
                <label className="col-form-label" htmlFor="dropdown3">
                  Module<sup>*</sup>
                </label>
                <SelectForm
                  isSearchable
                  isMulti={false}
                  noIndicator={false}
                  noSeparator={false}
                  id="dropdown1"
                  value={
                    moduleDropdownOptions
                      ? moduleDropdownOptions?.find(
                          (option) => option.value === moduleDropdownValue
                        )
                      : null
                  }
                  placeholder="Select an option"
                  options={moduleDropdownOptions}
                  onChange={handleModuleChange}
                />
                {moduleErrorDropdown && (
                  <p className="text-danger">{moduleErrorDropdown}</p>
                )}
              </div>
            </div>

            {/* Tab is here */}
            <div className="col-md-4">
              <div className="form-group mt-3">
                <label className="col-form-label" htmlFor="dropdown3">
                  Tab<sup>*</sup>
                </label>
                <SelectForm
                  isSearchable
                  noSeparator={false}
                  isMulti={false}
                  id="dropdown2"
                  placeholder="Select an option"
                  value={
                    TabDropdownValue
                      ? tabDropdownOptions?.find(
                          (option) => option.value === TabDropdownValue
                        )
                      : null
                  }
                  options={tabDropdownOptions}
                  onChange={handleTabChange}
                />
                {tabErrorDropdown && (
                  <p className="text-danger">{tabErrorDropdown}</p>
                )}
              </div>
            </div>

            {/* Section is here */}
            <div className="col-md-4">
              <div className="form-group mt-3">
                <label className="col-form-label" htmlFor="dropdown3">
                  Section<sup>*</sup>
                </label>
                <SelectForm
                  isSearchable
                  isMulti={false}
                  noSeparator={false}
                  id="dropdown3"
                  value={
                    sectionDropdownValue
                      ? sectionDropdownOptions?.find(
                          (option) => option.value === sectionDropdownValue
                        )
                      : null
                  }
                  placeholder="Select an option"
                  options={sectionDropdownOptions}
                  onChange={handleSectionChange}
                />
                {sectionErrorDropdown && (
                  <p className="text-danger">{sectionErrorDropdown}</p>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <button
                type="button"
                onClick={handleDownloadClick}
                className="btn btn-primary"
                disabled={
                  !moduleDropdownValue ||
                  !TabDropdownValue ||
                  !sectionDropdownValue
                }
              >
                <i className="fa fa-download mr-2"></i>
                Template Download
              </button>
            </div>

            {/* <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="file2">
                  <input
                    // className="btn btn-primary"
                    type="file"
                    accept=".xlsx"
                    id="file2"
                    onChange={handleFile2Upload}
                  />
                  <sup>*</sup>
                </label>

                {result && (
                  <p
                    className={`message ${
                      result.includes("match") ? "text-success" : "text-danger"
                    }`}
                  >
                    {result}
                  </p>
                )}
              </div>
            </div> */}
            <div className="col-md-4">
              <div className="box position-relative">
                <input
                  id={"REQSUPDOC"}
                  className="form-control inputfile inputfile-6 multiple-inputfile"
                  multiple={false}
                  type="file"
                  ref={supportingDocRef}
                  // onChange={(e) => {
                  //   handleSupportingDoc(e, "REQSUPDOC");
                  // }}
                  onChange={handleFile2Upload}
                  disabled={false}
                />
                <label
                  htmlFor={"REQSUPDOC"}
                  className="form-control"
                  style={{ width: 0, border: "none" }}
                >
                  <strong
                    style={{
                      padding: "6px 16px",
                      backgroundColor: "#3c5464",
                      borderRadius: "5px",
                    }}
                  >
                    <i
                      className="fa fa-upload rotate90 mr-2"
                      aria-hidden="true"
                    ></i>
                    Upload
                  </strong>
                </label>
              </div>
              <div className="file-added-list">
                <ul className="list-unstyle">
                  {file2 && (
                    <>
                      <li className="list mt-1">
                        <div className="media">
                          <div className="media-body text-truncate">
                            <span className="view-more">{file2.name}</span>
                          </div>
                        </div>
                        <div className="media-right ml-2">
                          <i
                            className="fa fa-trash"
                            aria-hidden="true"
                            onClick={() => {
                              setFile2(null);
                              supportingDocRef.current.value = null;
                            }}
                          ></i>
                        </div>
                      </li>
                    </>
                  )}
                </ul>
              </div>
              {result && <p className="text-danger">{result}</p>}
            </div>

            <div className="col-md-3">
              <button
                type="button"
                onClick={handleCompareFiles}
                className="btn btn-primary"
              >
                Submit
              </button>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default BulkUpload;
