import samayImg from "../../Assets/Images/samay.jpg";
import worklineImg from "../../Assets/Images/workline.jpg";
import weLearnImg from "../../Assets/Images/we_learn.jpg";
import resourceManagementImg from "../../Assets/Images/resource-management.png";
import gmiImg from "../../Assets/Images/certified.jpg";
import sopImg from "../../Assets/Images/sop.png";
import eLetterImg from "../../Assets/Images/E- Letters.png";
import outlookImg from "../../Assets/Images/outlook.jpg";
import newsCenterImg from "../../Assets/Images/News Center.png";
import radioWelspunImg from "../../Assets/Images/Radio Welspun.jpeg";
import weConnectImg from "../../Assets/Images/WeConnect2.jpg";
import knowledgeImg from "../../Assets/Images/now_ledge.jpg";
import manthanImg from "../../Assets/Images/manthan.jpg";
import learningImg from "../../Assets/Images/training.png";
import happayImg from "../../Assets/Images/Happay.jpg";
import yatraImg from "../../Assets/Images/Yatra.jpg";
import welWise from "../../Assets/Images/Wise_Logo.png";
import eSign from "../../Assets/Images/E-Digital Signature.png";
import Compliance from "../../Assets/Images/COmpliance Logo.jpg";
import lrg from "../../Assets/Images/lrg_logo.png";
import ProLearn from "../../Assets/Images/prolearn_logo.svg";
import HETechTicketing from "../../Assets/Images/HRTech_Ticketing.jpg";
import HelpDeskTile from "../../Assets/Images/HelpDeskTile.jpg";
import MediBuddy from '../../Assets/Images/MediBuddy.png';
import HelpDesk_Logo_FINAL_CC from '../../Assets/Images/HelpDesk_Logo_FINAL_CC.png';

// isSso (optional), ssoKey (optional), link (optional in case of sso login), className (optional)

export const quickLinks = [
  {
    isSso: true,
    ssoKey: "Samay",
    title:
      "Time & Attendance - Employee Benefits - Onboarding Experience Feedback (555) - Off Boarding",
    img: samayImg,
    alt: "Samay",
    label: "Samay",
  },
  {
    link: "https://app819.workline.hr/",
    title:
      "Investment Declaration - Flexi Pay Benefits - Payslip - Tax slip - Form 16 - Loan & Advances",
    img: worklineImg,
    alt: "Workline",
    label: "Workline",
  },
  {
    link: "https://welearnwelspun.percipio.com",
    title: "E-Learning Platform - Skill Soft and Courser",
    img: weLearnImg,
    alt: "Welearn",
    label: "WeLearn",
  },
  {
    link: "https://welspungroup.sharepoint.com/SitePages/HRPolicyListView.aspx",
    title:
      "Policy related to employee life cycle - compensation & Benefits -  Statutory Compliance",
    img: resourceManagementImg,
    alt: "Management",
    label: "HR Policies",
  },
  {
    isSso : true,
    ssoKey : 'Compliance',
    title : 'Compliance',
    img : Compliance,
    alt : 'Compliance',
    label : 'Compliance',
    className : 'img-grid img-grid-fit'
  },
  {
    link: "https://app.greatmanagerinstitute.com/manager/login",
    title: "GMI",
    img: gmiImg,
    alt: "Gmi",
    label: "GMI",
  },
  {
    link: "https://esignhris.welspun.com/",
    title: "E-Digital Signature",
    img: eSign,
    alt: "E-Digital Signature",
    label: "E-Digital Signature",
    className: "img-grid img-grid-fit",
  },
  {
    link: "https://outlook.office.com",
    title: "Outlook - One Drive",
    img: outlookImg,
    alt: "Office",
    label: "Office 365",
  },
  {
    link: "https://www.welspun.com/news-and-media.php",
    title: "News Center",
    img: newsCenterImg,
    alt: "News Center",
    label: "News Center",
  },
  // {
  //   link: "https://welspungroup.sharepoint.com/PortalVideoGallery/Forms/Thumbnails.aspx",
  //   title: "Radio Welspun",
  //   img: radioWelspunImg,
  //   alt: "Radio Welspun",
  //   label: "Radio Welspun",
  // },
  {
    link: " https://welspungroup.sharepoint.com/sites/WelspunWorld_WeConnect",
    title: "WeConnect",
    img: weConnectImg,
    alt: "WeConnect",
    label: "WeConnect",
    className: "img-grid img-grid-fit",
  },
  {
    link: "https://adfs.welspun.com/adfs/ls/IdpInitiatedSignon.aspx/?client-request-id=2442f6a7-61f2-443a-0406-00800100008d",
    title: "Knowladge Hub",
    img: knowledgeImg,
    alt: "Coursera",
    label: "Coursera",
  },
  {
    link: "https://welspungroup.sharepoint.com/sites/InnovationPortal/SiteAssets/Home.aspx",
    title: "Manthan",
    img: manthanImg,
    alt: "Manthan",
    label: "Manthan",
  },
  {
    link: "https://expense.happay.in/login/sso?domain_name=welspun",
    title: "Happay",
    img: happayImg,
    alt: "Happay",
    label: "Happay",
    className: "img-grid img-grid-fit",
  },
  {
    link: "https://adfs.welspun.com/adfs/ls/IdpInitiatedSignon.aspx/",
    title: "Yatra",
    img: yatraImg,
    alt: "Yatra",
    label: "Yatra",
    className: "img-grid img-grid-fit",
  },
  {
    link: "https://wel-wise.com/",
    welwise: true,
    title: "WelWise",
    img: welWise,
    alt: "WelWise",
    label: "WelWise",
    className: "img-grid img-grid-fit",
  },
  {
    link: "https://app.welspun.com/samay/sf/Learning%20Resource%20Guide.pdf",
    title: "Learning Resource Guide",
    img: lrg,
    alt: "lrg",
    label: "LRG",
    className: "img-grid img-grid-fit",
  },
  {
    isSso: true,
    ssoKey: "HRTechTicketing",
    title: "HRTech Ticketing",
    // img: HETechTicketing,
    // img: HelpDeskTile,
    img: HelpDesk_Logo_FINAL_CC,
    alt: "HRTech Ticketing",
    // Changed the name as told by ankit , Raj Potdar, Date : 20-06-2024
    // label: "HRTech Help Desk",
    // label: "Ticketing  Tool",
    label: "Help Desk",
    className: "img-grid img-grid-fit",
  },
  {
    // link: "/WelPro_SOP_EP_V1.pdf",
    link: "/WelPro_SOP_EP_V2.pdf",
    title: "User Guide and Manuals - HR Processes",
    img: sopImg,
    alt: "Sop",
    label: "SOPs",
  },
  {
    isSso : true,
    ssoKey : 'Medi Buddy',
    title : 'Medi Buddy',
    img : MediBuddy,
    alt : 'Medi Buddy',
    label : 'Medi Buddy',
    className : 'img-grid img-grid-fit'
  },
];
