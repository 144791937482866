import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoaderContext } from "../../Helpers/Context/Context";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { Tooltip } from "@mui/material";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { Modal } from "react-bootstrap";
import {
  changeSequenceManageFeedbackQuestionMaster,
  delinkManageFeedbackQuestionMaster,
  getManageFeedbackQuestionMasterList,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import { APICall } from "../../Helpers/APICalls";
import notify from "../../Helpers/ToastNotification";

const FeedbackGrid = ({
  showModal,
  setShowModal,
  questionTypechange,
  setFeedbackGridCount,
  feedbackGridCount,
}) => {
  const { state } = useLocation();
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  let { userDetails, currentRoleId }: any = useUserContext();
  const [gridData, setGridData] = useState<any>([]);

  const [isDisable, setIsDisable] = useState(
    state && state?.isDisable ? true : false
  );

  const [reset, setReset] = useState(false);

  const [count, setCount] = useState(0);

  const [showQuestionModal, setShowQuestionModal] = useState(false);

  const [questionId, setQuestionId] = useState(0);

  useEffect(() => {
    if (state?.feedbackId > 0) {
      getManageFeedbackQuestionMasterListApiCall();
    }
  }, [showModal, reset]);

  const getManageFeedbackQuestionMasterListApiCall = async () => {
    showLoader();
    const FeedbackGridData = await APICall(
      getManageFeedbackQuestionMasterList,
      "POST",
      {
        FeedbackId: state?.feedbackId,
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      }
    );

    if (
      FeedbackGridData &&
      FeedbackGridData.data !== null &&
      FeedbackGridData.data.length > 0
    ) {
      setGridData(FeedbackGridData.data);
      setFeedbackGridCount(FeedbackGridData?.data?.length);
      setCount(FeedbackGridData.data[0].count);
    } else if (FeedbackGridData?.status === 1) {
      notify(1, FeedbackGridData?.message);
      setGridData([]);
      setCount(0);
    } else {
      setGridData([]);
      setCount(0);
    }
    hideLoader();
  };

  const changeSequenceManageFeedbackQuestionMasterApiCall = async (
    questionId,
    direction
  ) => {
    showLoader();
    const parameters = {
      FeedbackId: state?.feedbackId,
      Id: questionId,
      Direction: direction,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    };
    const resmonseData = await APICall(
      changeSequenceManageFeedbackQuestionMaster,
      "POST",
      parameters
    );

    if (resmonseData?.status === 0) {
      setReset(!reset);
    } else if (resmonseData?.status === 1) {
      notify(resmonseData?.status, resmonseData?.message);
    }
    hideLoader();
  };

  const gridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: count,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        // await setSortColumn(sortColumn);
        // await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        // await setSortColumn(sortColumn);
        // await setSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      //   setPage(page * PageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };
  const gridColumns = [
    {
      name: "Id",
      label: "Id",
      options: {
        display: false,
      },
    },

    {
      name: "sequence",
      label: "Sequence",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "question",
      label: "Questions",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "questionType",
      label: "Question Type",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "Id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta) => {
          let Id = tableMeta.tableData[tableMeta.rowIndex].id;

          const { rowIndex, tableData } = tableMeta;
          const sequenceNo = tableData[rowIndex].sequence;
          const totalItems = tableData.length;
          const isFirst = rowIndex === 0; // First item has rowIndex 0
          const isLast = rowIndex === totalItems - 1; // Last item has rowIndex totalItems - 1

          const upArrow = isFirst ? (
            <Tooltip title="">
              <button className="btn mr-1" disabled={true}></button>
            </Tooltip>
          ) : (
            <Tooltip title="Move up">
              <button
                className="btn"
                disabled={false}
                onClick={(e) => {
                  e.preventDefault();
                  changeSequenceManageFeedbackQuestionMasterApiCall(Id, -1);
                }}>
                <i className="fa fa-caret-up"></i>
              </button>
            </Tooltip>
          );

          const downArrow = isLast ? (
            <Tooltip title="">
              <button className="btn mr-1" disabled={true}></button>
            </Tooltip>
          ) : (
            <Tooltip title="Move down">
              <button
                className="btn"
                disabled={false}
                onClick={(e) => {
                  e.preventDefault();
                  changeSequenceManageFeedbackQuestionMasterApiCall(Id, 1);
                }}>
                <i className="fa fa-caret-down"></i>
              </button>
            </Tooltip>
          );

          return (
            // <div style={{ width: "100px" }}>

            <div className="">
              {!isDisable && (
                <>
                  <div className="">
                    {/* <div className="updw_arr"> */}
                    {upArrow}
                    {downArrow}
                    {/* </div> */}
                    <Tooltip title="Edit">
                      <a
                        className="ml-2 mr-2 mt-1"
                        style={{ fontSize: "15px" }}
                        onClick={(e) => {
                          e.preventDefault();

                          questionTypechange(true, Id);
                        }}>
                        <i className="fas fa-edit"></i>
                      </a>
                    </Tooltip>

                    <Tooltip title="Delink">
                      <a
                        className=" ml-2 mr-2 mt-1"
                        style={{ fontSize: "15px" }}
                        onClick={(e) => {
                          handleDelinkQuestionFunction(Id);
                        }}>
                        <i className="fa fa-link"></i>
                      </a>
                    </Tooltip>
                  </div>
                </>
              )}
              {isDisable && (
                <>
                  <div className="">
                    <Tooltip title="View">
                      <a
                        className="mr-2"
                        style={{ fontSize: "15px" }}
                        onClick={(e) => {
                          e.preventDefault();

                          questionTypechange(true, Id);
                        }}>
                        <i className="fas fa-eye"></i>
                      </a>
                    </Tooltip>
                  </div>
                </>
              )}
            </div>
            // </div>
          );
        },
      },
    },
  ];

  const handleDelinkQuestionFunction = async (id: any) => {
    setShowQuestionModal(true);
    setQuestionId(id);
  };
  const handleDelinkQuestionById = async () => {
    if (questionId > 0) {
      const requestParams = {
        FeedbackId: state?.feedbackId,
        Id: questionId,
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      };

      const responseData = await APICall(
        delinkManageFeedbackQuestionMaster,
        "POST",
        requestParams
      );

      if (responseData?.status === 0) {
        setShowQuestionModal(false);
        notify(responseData?.status, responseData?.message);
        setReset(!reset);
      } else if (responseData?.status === 1) {
        notify(responseData?.status, responseData?.message);
      }
    }
  };
  return (
    <>
      <div className="mb-3 pt-3">
        <DynamicGrid
          data={gridData}
          columns={gridColumns}
          options={gridOptions}
        />
      </div>
      <Modal
        show={showQuestionModal}
        onHide={() => setShowQuestionModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">Question Delink</div>
        </div>
        <Modal.Body>
          <>
            <div>
              <b>{`Do you want to Delink the question?`}</b>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => {
              handleDelinkQuestionById();
            }}>
            Submit
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={(e) => {
              e.preventDefault();
              setShowQuestionModal(false);
            }}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FeedbackGrid;
