import React, { useContext, useEffect, useState } from "react";
import { LoaderContext } from "../../Helpers/Context/Context";
import { useLocation, useNavigate } from "react-router-dom";
import { getMRFIdandJobDataBasedOnMrfNo } from "../../Helpers/APIEndPoints/EndPoints";
import { APICall } from "../../Helpers/APICalls";
import notify from "../../Helpers/ToastNotification";

const JobPosting = () => {
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [mrfno, setMrfno] = useState(null);

  useEffect(() => {
    showLoader();
    (async () => {
      const searchParams = new URLSearchParams(location.search);
      const mrfnoParam = getQueryParamCaseInsensitive(searchParams, "MRFNo");
      console.log(mrfnoParam);
      const getData = await APICall(getMRFIdandJobDataBasedOnMrfNo, "POST", {
        MRFNO: mrfnoParam,
      });

      if (getData?.data) {
        navigate("/anonymouscanidate", {
          state: {
            JobId: getData?.data?.jobId,
            IsIJP: false,
            TId: getData?.data?.mrfid,
            MRFId: getData?.data?.mrfid,
            IsAnonymousCandidate: true,
            source: "LinkedIn",
          },
        });
      } else {
        await hideLoader();
        notify(1, "Something went wrong, Please try again later.");
      }
    })();
  }, [location]);

  function getQueryParamCaseInsensitive(searchParams, param) {
    for (let [key, value] of searchParams.entries()) {
      if (key.toLowerCase() === param.toLowerCase()) {
        return value;
      }
    }
    return null;
  }

  return <></>;
};

export default JobPosting;
