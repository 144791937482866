import React, { useContext, useEffect, useRef, useState } from "react";
import "./Participation.css";
import SelectForm from "../../../Components/SelectForm/SelectForm";
import DynamicGrid from "../../../Components/DynamicGrid/DynamicGrid";
import moment from "moment";
import {
  GetCommonDropdownForScheduling,
  GetCourseScheduleParticpantDashboard,
  GetTargetLearnerInfo,
  InsertIntoLMSParticipating,
  LaunchCourse,
  UploadEmployeeToCourseParticipation,
  GetCourseById,
  ExportToExcelParticipationListForTargetLearner,
  deleteCourseScheduleParticipantsById,
  GetLaunchHistoryDashboardDataForCourse,
  DownloadExcelTemplate,
  GetLaunchValidationForCourse,
  getLMSDomainDropDownData,
  getCourseScheduleDropDownForLaunch,
  getCourseData,
  deleteAllCourseScheduleParticipants,
  GetCertificateDropDown,
  GetSignatureDropDown,
  generateUserCertificate,
} from "../../../Helpers/APIEndPoints/LMS_EndPoints";
import { APICall, APICallWithSignal } from "../../../Helpers/APICalls";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LoaderContext } from "../../../Helpers/Context/Context";

import { Tooltip } from "@mui/material";
import notify from "../../../Helpers/ToastNotification";
import useUserContext from "../../../Helpers/ZustandStore/UserContextStore";
import DateTimeForm from "../../../Components/DateTimeForm/DateTimeForm";
import { Modal } from "react-bootstrap";
import {
  base64ToArrayBuffer,
  getBase64FromFilePromise,
} from "../../../Helpers/Common";
import fileDownload from "js-file-download";

interface IOptions {
  value?: string | number | any;
  code?: string | any;
  label?: string | any;
}

interface ICourseLaunchData {
  LaunchType?: string | number | any;
  ScheduleDateTime?: string | Date | any;
  courseSchedule?: number | string | any;
  journey?: number | string | any;
  domain?: number | string | any;
  certificateTemplate?: IOptions | number | any;
  signature1?: IOptions | number | any;
  signature2?: IOptions | number | any;
}

enum EnumCourseLaunchType {
  ScheduleLaunch = 1,
  ImmedidateLaunch = 2,
}

const Participation = ({ activeTab, setActiveTab }) => {
  const { state } = useLocation();
  const [isDisable, setIsDisable] = useState(
    state && state?.isDisable ? true : false
  );
  const { showLoader, hideLoader } = useContext(LoaderContext);

  const [disableAttribute, setDisableAttribute] = useState({});
  const [courseScheduleOptions, setCourseScheduleOptions] = useState<
    IOptions[]
  >([]);
  const [launchCourseScheduleOptions, setLaunchCourseScheduleOptions] =
    useState([]);
  const [targetGroupOptions, setTargetGroupOptions] = useState<IOptions[]>([]);
  const [courseSchedule, setCourseSchedule] = useState<IOptions>({});
  const [targetGroup, setTargetGroup] = useState<IOptions>({});
  const [gridData, setGridData] = useState([]);
  const [courseId, setCourseId] = useState(0);

  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("");
  const [employeeGridData, setEmployeeGridData] = useState([]);
  const [formError, setFormError] = useState({});
  const [employeeGridPage, setEmployeeGridPage] = useState(0);
  const [employeeGridCount, setEmployeeGridCount] = useState(0);
  const [employeeGridSortColumn, setEmployeeGridSortColumn] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const [employeeGridSortDirection, setEmployeeGridSortDirection] =
    useState("");
  const [openParticiptionList, setOpenParticiptionList] = useState(false);
  const { userDetails, currentRoleId }: any = useUserContext();
  const [refresh, setRefresh] = useState(false);
  const [tempScheduleId, setTempScheduleId] = useState(0);
  const [tempEditScheduleId, setTempEditScheduleId] = useState(0);
  const [courseLaunchModal, setCourseLaunchModal] = useState(false);
  const [courseLaunchFormData, setCourseLaunchFormData] =
    useState<ICourseLaunchData>({ LaunchType: 1 });
  const [journeyOptions, setJourneyOptions] = useState([]);
  const [courseLaunchErrors, setCourseLaunchErrors] = useState({});
  const [isLaunched, setIsLaunched] = useState(false);
  const [uploadType, setUploadType] = useState(null);
  const [file, setFile] = useState(null);
  const fileRef = useRef(null);
  const [resetGrid, setResetGrid] = useState(false);

  const [launchId, setLaunchId] = useState(0);

  const [isIssueCertificate, setIsIssueCertificate] = useState(false);

  const [certificateTemplateOptions, setCertificateTemplateOptions] = useState(
    []
  );

  const [signatureOptions, setSignatureOptions] = useState([]);

  const [domainOption, setDomainOption] = useState([]);
  const [domain, setDomain] = useState(null);

  const uploadTypeOptions = [
    {
      value: 1,
      label: "Target Learner group",
      isEnabled: launchId > 0 ? false : true,
    },
    { value: 2, label: "Bulk Upload", isEnabled: true },
  ];
  const navigate = useNavigate();

  const [launchHistoryModel, setLaunchHistoryModel] = useState(false);

  const [launchHistoryData, setLaunchHistoryData] = useState([]);

  const [launchHistoryPage, setLaunchHistoryPage] = useState(0);

  const [launchHistoryPageSize, setLaunchHistoryPageSize] = useState(10);

  const [launchHistorySearchText, setLaunchHistorySearchText] = useState("");

  const [launchHistorySortColumn, setLaunchHistorySortColumn] = useState("");

  const [launchHistorySortDirection, setLaunchHistorySortDirection] =
    useState("");

  const [launchHistoryCount, setLaunchHistoryCount] = useState(0);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const [deleteId, setDeleteId] = useState(0);

  const [errorData, setErrorData] = useState([]);

  const [isScheduleRequired, setIsScheduleRequired] = useState(false);

  const [showParticipationDeletion, setShowParticipationDeletion] = useState({
    modal: false,
    courseScheduleId: 0,
  });

  useEffect(() => {
    getLaunchHistoryDashboardData();
  }, [
    courseId,
    launchHistoryModel,
    launchHistoryPage,
    launchHistoryPageSize,
    launchHistorySearchText,
    launchHistorySortColumn,
    launchHistorySortDirection,
  ]);

  useEffect(() => {
    setCourseId(state?.courseId || 0);

    const abortController = new AbortController();
    const signal = abortController.signal;
    if (courseId > 0) {
      const fetchData = async () => {
        await handleFetchCourseScheduleByCourseId(signal);
        // await getLMSDomainDropDownDataApiCall();
        await getCourseScheduleDropDownForLaunchApiCall();
      };

      fetchData();
    }

    return () => {
      abortController.abort();
    };
  }, [courseId, sortColumn, sortDirection, refresh, page]);

  const getLaunchHistoryDashboardData = async () => {
    showLoader();
    if (courseId != null && courseId != 0) {
      let requestParams = {
        PageSize: launchHistoryPageSize,
        Page: launchHistoryPage,
        SortOrder: launchHistorySortDirection,
        SortColumn: launchHistorySortColumn,
        SearchText: launchHistorySearchText,
        ProgramId: 0,
        CourseId: courseId,
      };

      const response = await APICall(
        GetLaunchHistoryDashboardDataForCourse,
        "POST",
        requestParams
      );

      if (response?.status === 0 && response?.data.length > 0) {
        setLaunchHistoryData(response?.data);
        setLaunchHistoryCount(response?.data[0]?.totalCount);
        hideLoader();
      } else {
        setLaunchHistoryData([]);
      }
    }
    hideLoader();
  };

  const getLMSDomainDropDownDataApiCall = async () => {
    const response = await APICall(getLMSDomainDropDownData, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      GetAll: true,
    });

    if (response?.status === 0 && response?.data.length > 0) {
      setDomainOption(response?.data);
    }
  };

  useEffect(() => {
    getCertificateDropDownApiCall();
    getSignatureDropDownApiCall();
  }, [isIssueCertificate]);

  const getCertificateDropDownApiCall = async () => {
    showLoader();
    const response = await APICall(GetCertificateDropDown, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    if (response?.status === 0 && response?.data.length > 0) {
      setCertificateTemplateOptions(response?.data);
    }
    hideLoader();
  };

  const getSignatureDropDownApiCall = async () => {
    showLoader();
    const response = await APICall(GetSignatureDropDown, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    if (response?.status === 0 && response?.data.length > 0) {
      setSignatureOptions(response?.data);
    }

    hideLoader();
  };

  const previewCertificate = async () => {
    if (
      !courseLaunchFormData?.courseSchedule ||
      courseLaunchFormData?.courseSchedule.length === 0 ||
      !courseLaunchFormData?.certificateTemplate?.value
    ) {
      notify(1, "Certificate Template and Course Schedule Required!");
      return null;
    }

    showLoader();
    const response = await APICall(generateUserCertificate, "POST", {
      UserId: userDetails.Id,
      CourseScheduleId: courseLaunchFormData?.courseSchedule[0].value || null,
      Signature1Id: courseLaunchFormData?.signature1?.value || null,
      Signature2Id: courseLaunchFormData?.signature2?.value || null,
      CertificateId: courseLaunchFormData?.certificateTemplate?.value || null,
      IsPreview: true,
      ProgramId: courseLaunchFormData?.journey?.value || null,
    });

    if (response.status === 0 && response.data !== null) {
      const base64 = response?.data;
      const fileName = `Certificate.pdf`;

      let arrayBuffer = await base64ToArrayBuffer(base64);
      var byteArray = new Uint8Array(arrayBuffer);

      let blob = new Blob([byteArray], {
        type: "application/pdf",
      });
      const newFile = new File([blob], "", {
        type: "application/pdf",
        lastModified: new Date().getTime(),
      });
      fileDownload(newFile, fileName);
    } else {
      notify(1, "Something went wrong try again later");
    }
    hideLoader();
  };

  async function handleSearchForAsyncDropdown(event, code, callback) {
    try {
      if (event?.length >= 3) {
        const data = await fetchDropdown(event, code);
        callback(data);
      } else {
        callback([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchDropdown(searchText = "", code = "", value = null) {
    try {
      const response = await APICall(
        GetCommonDropdownForScheduling,
        "POST",
        {
          SearchText: searchText,
          DropdownValues: value,
          Code: code,
          UserId: userDetails?.Id,
          RoleId: currentRoleId?.value,
        },
        true
      );

      if (response?.status === 0) {
        return response?.data;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  const getCourseScheduleDropDownForLaunchApiCall = async () => {
    if (courseId > 0) {
      const response = await APICall(
        getCourseScheduleDropDownForLaunch,
        "POST",
        {
          UserId: userDetails?.Id,
          RoleId: currentRoleId?.value,
          CourseIds: courseId,
        }
      );

      if (response?.status === 0 && response?.data.length > 0) {
        setLaunchCourseScheduleOptions(response?.data);
      }
    }
  };

  const launchHistoryOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: launchHistoryCount,
    rowsPerPage: launchHistoryPageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setLaunchHistorySortColumn(sortColumn);
        await setLaunchHistorySortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setLaunchHistorySortColumn(sortColumn);
        await setLaunchHistorySortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      setLaunchHistoryPage(page * launchHistoryPageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const launchHistoryGridColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        display: false,
      },
    },

    {
      name: "programTitle",
      label: "Program Title",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "courseTitle",
      label: "Course Title",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "launchFor",
      label: "Launch For",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "launchType",
      label: "Launch Type",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "launchDate",
      label: "Launch Date",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "isRelaunched",
      label: "Is Relaunched",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "createdByName",
      label: "Created By Name",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "createdOnDate",
      label: "Created On Date",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
  ];

  const errorDataColumns = [
    {
      name: "Row",
      label: "Row",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: false,
      },
    },
    {
      name: "Col",
      label: "Column",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: false,
      },
    },
    {
      name: "ColName",
      label: "Column Name",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: false,
      },
    },
    {
      name: "ErrorMessage",
      label: "Error Message",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: false,
      },
    },
  ];

  const errorDataOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    rowsPerPage: 5,
    serverSide: false,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    //standard | vertical | simple
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchJourneyOptions();
    };

    fetchData();
  }, [courseId]);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    if (courseId > 0) {
      const fetchData = async () => {
        await handleFetchTargetLearnerGroup(signal);
        await handleFetchCoursesScheduleByCourseId(signal);
        await getCourseDataApiCall();
      };

      fetchData();
    }

    return () => {
      abortController.abort();
    };
  }, [courseId, refresh]);

  const getCourseDataApiCall = async () => {
    const responseData = await APICall(getCourseData, "POST", {
      Id: state?.courseId,
      LaunchId: state?.launchHubId,
    });

    if (responseData?.status === 0) {
      setIsScheduleRequired(responseData?.data?.scheduleRequired);
    }
  };

  useEffect(() => {
    if (tempScheduleId > 0) {
      const handler = async () => {
        await handleFetchParticipantsGrid();
      };

      handler();
    }
  }, [
    employeeGridPage,
    employeeGridSortColumn,
    employeeGridSortDirection,
    tempScheduleId,
    resetGrid,
  ]);

  const DeleteCourseScheduleParticipantsByIdApiCall = async (id: any) => {
    showLoader();
    if (id > 0) {
      const response = await APICall(
        deleteCourseScheduleParticipantsById,
        "POST",
        { Id: id }
      );

      if (response?.status === 0) {
        notify(0, "Data deleted successfully");
        setShowDeleteModal(false);
        setResetGrid(!resetGrid);
      } else if (response?.status === 1) {
        notify(response?.status, response?.message);
      }
    }
    hideLoader();
  };

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    if (courseId > 0) {
      const fetchData = async () => {
        await handleFetchCoursesById(signal);
      };

      fetchData();
    }

    return () => {
      abortController.abort();
    };
  }, [courseId]);

  useEffect(() => {
    debugger;
    if (tempEditScheduleId > 0) {
      const filterData = gridData.find((i) => i?.id == tempEditScheduleId);

      const course_schedule = {
        value: tempEditScheduleId,
        label: filterData?.course_schedule,
      };
      setCourseSchedule(course_schedule);

      if (launchId > 0) {
        const arr = uploadTypeOptions.find((i) => i.value == 2);
        setUploadType(arr);
      } else {
        const targetLearnerData = targetGroupOptions.find(
          (i) => i?.value == filterData?.target_leaner_group_id
        );

        setTargetGroup(targetLearnerData);

        const fl = Number(targetLearnerData?.value) > 0 ? 1 : 2;
        const arr = uploadTypeOptions.find((i) => i.value == fl);
        setUploadType(arr);
      }
    }
  }, [tempEditScheduleId]);

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: pageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",
    onChangePage: async (page) => {
      setPage(page);
    },
  };

  const gridColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "course_schedule",
      label: "Course Schedule",
      options: {
        filter: false,
        sort: false,
        width: 200,
      },
    },
    {
      name: "target_leaner_group_id",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "target_leaner_group",
      label: "Target Learner Group",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.tableData[tableMeta.rowIndex].id;
          const isLaunch = tableMeta.tableData[tableMeta.rowIndex].isLaunch;
          const isEdit = tableMeta.tableData[tableMeta.rowIndex].isEdit;
          const launchId = tableMeta.tableData[tableMeta.rowIndex].launchId;
          const target_leaner_group_id =
            tableMeta.tableData[tableMeta.rowIndex].target_leaner_group_id;

          return (
            <div style={{ width: "100px" }}>
              <Tooltip title={"View Employee"}>
                <span
                  onClick={() => {
                    setTempScheduleId(id);
                  }}>
                  <i
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    className={`fas fa-eye`}></i>
                </span>
              </Tooltip>

              {!isDisable && launchId > 0 && isEdit && (
                <Tooltip title={"Edit Course Schedule After Launch"}>
                  <i
                    onClick={() => {
                      setLaunchId(launchId);
                      setTempEditScheduleId(id);
                    }}
                    style={{
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                    className={`fas fa-edit`}></i>
                </Tooltip>
              )}

              {!isDisable && !isLaunch && (
                <Tooltip title={"Edit Course Schedule"}>
                  <i
                    onClick={() => {
                      setLaunchId(0);
                      setTempEditScheduleId(id);
                    }}
                    style={{
                      marginRight: "10px",
                      cursor: "pointer",
                    }}
                    className={`fas fa-edit`}></i>
                </Tooltip>
              )}
              {!isDisable && !isLaunch && (
                <Tooltip title={"Delete Course Schedule"}>
                  <i
                    onClick={() => {
                      setShowParticipationDeletion({
                        modal: true,
                        courseScheduleId: id,
                      });
                    }}
                    style={{
                      marginRight: "0px",
                      cursor: "pointer",
                    }}
                    className={`fas fa-trash`}></i>
                </Tooltip>
              )}
            </div>
          );
        },
      },
    },
  ];

  const employeeGridColums = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: "employeeCode",
      label: "Employe Code",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "employeeFullName",
      label: "Full Name",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "department",
      label: "Department",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "designation",
      label: "Designation",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "groupDateOfJoining",
      label: "Group Joining Date",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "businessDateOfJoining",
      label: "Business Joining Date",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "gender",
      label: "Gender",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    // {
    //   name: "registrationStatus",
    //   label: "Status",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     display: true,
    //   },
    // },
    // {
    //   name: "participantionStatus",
    //   label: "Participation Status",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     display: true,
    //   },
    // },
    {
      name: "legalEntity",
      label: "Legal Entity",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "payrollLocation",
      label: "Payroll Location",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "employeeClass",
      label: "Employee Class",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "jobBand",
      label: "Job Band",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "jobLevel",
      label: "Job Level",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.tableData[tableMeta.rowIndex].id;
          const isLaunch = tableMeta.tableData[tableMeta.rowIndex].isLaunch;

          return (
            <>
              {" "}
              {!isDisable && !isLaunch && (
                <Tooltip title="Delete">
                  <a
                    className="mr-2"
                    style={{ fontSize: "15px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowDeleteModal(true);
                      setDeleteId(id);
                    }}>
                    <i className="fas fa-trash"></i>
                  </a>
                </Tooltip>
              )}
            </>
          );
        },
      },
    },
  ];

  const employeeGridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    page: employeeGridPage,
    count: employeeGridCount,
    rowsPerPage: pageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",
    onChangePage: async (page) => {
      setEmployeeGridPage(page);
    },
  };

  function handleOnChange(event, code) {
    switch (code) {
      case "target_learner_group":
        setTargetGroup(event);
        break;

      case "course_schedule":
        setCourseSchedule(event);
        break;

      case "upload_type":
        setUploadType(event);
        break;
      case "file":
        const files = event.target.files[0] || null;
        if (!files) {
          setFile(null);
          notify(1, "Please select a file.");
          return;
        }

        // Check if the selected file is an Excel file
        if (!files.name.endsWith(".xls") && !files.name.endsWith(".xlsx")) {
          setFile(null);
          notify(1, "Please upload an Excel file.");
          return;
        }
        setFile(files);
        break;
      default:
        break;
    }
  }

  async function handleFetchTargetLearnerGroup(signal) {
    try {
      showLoader();
      const response = await APICallWithSignal(
        GetCommonDropdownForScheduling,
        "POST",
        {
          SearchText: "",
          DropdownValues: null,
          Code: "target_learner_group",
          UserId: userDetails?.Id,
          RoleId: currentRoleId?.value,
        },
        true,
        null,
        signal
      );

      if (response?.status === 0) {
        setTargetGroupOptions(response?.data);
      } else {
        setTargetGroupOptions([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  async function handleFetchCoursesScheduleByCourseId(signal) {
    try {
      showLoader();
      const response = await APICallWithSignal(
        GetCommonDropdownForScheduling,
        "POST",
        {
          SearchText: "",
          DropdownValues: null,
          Code: "cousre_schedule_not_target_group",
          courseId,
        },
        true,
        null,
        signal
      );

      if (response?.status == 0) {
        const responseData = response?.data;
        setCourseScheduleOptions(responseData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  async function handleFetchCourseScheduleByCourseId(signal) {
    try {
      showLoader();
      const response = await APICallWithSignal(
        GetCourseScheduleParticpantDashboard,
        "POST",
        {
          courseId,
          PageNumber: page,
          SortColumn: sortColumn,
          SortOrder: sortDirection,
          SearchText: "",
          PageSize: pageSize,
          LaunchId: state?.launchHubId,
        },
        true,
        null,
        signal
      );

      if (response?.status == 0 && response?.data?.length > 0) {
        const responseData = response?.data;

        let arr = responseData?.map((res) => {
          return {
            id: res?.id,
            course_schedule: res?.name,
            target_leaner_group: res?.targetLearnerGroupName,
            target_leaner_group_id: res?.targetLearnerGroupId,
            isLaunch: res?.isLaunch,
            isEdit: res?.isEdit,
            launchId: res?.launchId,
          };
        });

        setCount(responseData[0]?.count);
        setGridData(arr);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  async function handleSave() {
    try {
      const errorObj = {};

      if (
        courseSchedule == null ||
        courseSchedule == undefined ||
        !courseSchedule?.value
      ) {
        errorObj["course_schedule"] = "Course Schedule Required.";
      }

      if (
        uploadType?.value == null ||
        uploadType?.value == undefined ||
        !uploadType?.value
      ) {
        errorObj["upload_type"] = "Upload Type Required.";
      }

      if (
        uploadType?.value == 1 &&
        (targetGroup == null || targetGroup == undefined || !targetGroup?.value)
      ) {
        errorObj["target_group"] = "Target Group Required.";
      }

      if (uploadType?.value == 2 && (file == null || file == undefined)) {
        errorObj["file"] = "File Required.";
      }

      setFormError(errorObj);

      if (Object.keys(errorObj).length !== 0) {
        return;
      }
      setDisableBtn(true);
      showLoader();

      if (uploadType?.value == 2) {
        let base64 = await getBase64FromFilePromise(file);
        base64 = String(base64).split("base64,")[1];

        const response = await APICall(
          UploadEmployeeToCourseParticipation,
          "POST",
          {
            CourseId: courseId,
            CourserScheduleId: courseSchedule?.value,
            TargetLearnerGroupId: targetGroup?.value,
            UserId: userDetails?.Id,
            RoleId: currentRoleId?.value,
            ExcelFileBase64: base64,
          }
        );

        if (response?.status == 0) {
          notify(0, "Upload Successfully");
          setPage(0);
          setRefresh((prev) => !prev);
          setTargetGroup(null);
          setCourseSchedule(null);
          setTempEditScheduleId(0);
          setFile(null);
        } else if (response?.status == 5) {
          const { data } = response;

          if (data) {
            const fileName = "InvalidEmployeeCode.xlsx";
            const base64 = data;

            let arrayBuffer = await base64ToArrayBuffer(base64);
            var byteArray = new Uint8Array(arrayBuffer);

            let blob = new Blob([byteArray], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            const newFile = new File([blob], "", {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              lastModified: new Date().getTime(),
            });

            fileDownload(newFile, fileName);

            // notify(1, response?.message);

            const excelErrorMessage = JSON.parse(response?.message);

            const errorMessage = () => {
              if (
                Array.isArray(excelErrorMessage) &&
                excelErrorMessage.length > 0
              ) {
                setErrorData(excelErrorMessage);
              } else {
                notify(1, excelErrorMessage);
              }
            };
            errorMessage();

            setShowErrorModal(true);

            setPage(0);
            setRefresh((prev) => !prev);
            setTargetGroup(null);
            setCourseSchedule(null);
            setTempEditScheduleId(0);
            setFile(null);
          } else {
            notify(1, response?.message);
          }
        } else {
          notify(1, "Something went wrong.");
          setTargetGroup(null);
          setCourseSchedule(null);
          setTempEditScheduleId(0);
        }
      } else {
        const response = await APICall(InsertIntoLMSParticipating, "POST", {
          CourseId: courseId,
          CourserScheduleId: courseSchedule?.value,
          TargetLearnerGroupId: targetGroup?.value,
          UserId: userDetails?.Id,
        });

        if (response?.status == 0) {
          notify(0, "Data saved successfully.");
          setPage(0);
          setRefresh((prev) => !prev);
          setTargetGroup(null);
          setCourseSchedule(null);
          setTempEditScheduleId(0);
          setFile(null);
        } else {
          setTargetGroup(null);
          setCourseSchedule(null);
          setTempEditScheduleId(0);
          notify(0, "Something went wrong.");
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
      setDisableBtn(false);
    }
  }

  function handleReset() {
    try {
      setTargetGroup(null);
      setCourseSchedule(null);
      setUploadType({});
      setFile(null);
      setFormError({});
    } catch (error) {}
  }

  async function fetchJourneyOptions() {
    try {
      const response = await APICall(
        GetCommonDropdownForScheduling,
        "POST",
        {
          SearchText: "",
          DropdownValues: "",
          Code: "journey_by_courseid",
          CourseId: courseId,
        },
        true
      );

      if (response?.status === 0) {
        const responseData = response?.data;
        setJourneyOptions(responseData);

        if (responseData?.length === 1) {
          setCourseLaunchFormData((prev) => {
            return {
              ...prev,
              journey: responseData[0],
            };
          });
        }
        // return response?.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  }

  async function handleFetchParticipantsGrid() {
    try {
      const response = await APICall(GetTargetLearnerInfo, "POST", {
        courseScheduleId: tempScheduleId,
        PageNumber: employeeGridPage,
        SortColumn: employeeGridSortColumn,
        SortOrder: employeeGridSortDirection,
        SearchText: "",
        PageSize: pageSize,
      });

      if (response?.status == 0) {
        setEmployeeGridData(response?.data);
        setEmployeeGridCount(response?.data[0]?.count);
      } else {
        setEmployeeGridData([]);
        setEmployeeGridCount(0);
      }
      setOpenParticiptionList(true);
    } catch (error) {
      console.error(error);
    }
  }

  function handleCloseModelForParticiptionList() {
    setOpenParticiptionList(false);
    setTempScheduleId(0);
    setEmployeeGridCount(0);
    setEmployeeGridData([]);
    setEmployeeGridPage(0);
    setEmployeeGridSortColumn("");
    setEmployeeGridSortDirection("");
  }

  function handleCloseForLaunchModel() {
    setCourseLaunchFormData({});
    setCourseLaunchModal(false);
    setCourseLaunchErrors({});
    setIsIssueCertificate(false);
    setCourseLaunchFormData((prev) => {
      return {
        journey: null,
        LaunchType: 1,
        ScheduleDateTime: null,
        courseSchedule: null,
      };
    });
  }

  const handleOpenForLaunchModel = async () => {
    try {
      showLoader();
      if (courseId != null && courseId != 0) {
        let requestParams = {
          CourseId: courseId,
          ProgramId: 0,
        };

        const response = await APICall(
          GetLaunchValidationForCourse,
          "POST",
          requestParams
        );

        if (response?.status === 1) {
          notify(response?.status, response?.message);
          hideLoader();
        } else {
          setCourseLaunchModal(true);
        }
      } else {
        notify(1, "Course id is zero");
      }
      hideLoader();
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const handleSaveForLaunchModel = async () => {
    try {
      const ErrorObj = {};

      debugger;
      if (
        courseLaunchFormData?.journey == null ||
        courseLaunchFormData?.journey == undefined
        // ||
        // courseLaunchFormData?.journey?.value == 0 ||
        // !courseLaunchFormData?.journey?.value
      ) {
        ErrorObj["journey"] = "Journey required.";
      }

      //courseSchedule

      if (
        courseLaunchFormData?.courseSchedule == null ||
        courseLaunchFormData?.courseSchedule == undefined
        // ||
        // courseLaunchFormData?.journey?.value == 0 ||
        // !courseLaunchFormData?.journey?.value
      ) {
        ErrorObj["courseSchedule"] = "Course Schedule required.";
      }

      if (
        courseLaunchFormData?.LaunchType == null ||
        courseLaunchFormData?.LaunchType == undefined ||
        !courseLaunchFormData?.LaunchType
      ) {
        ErrorObj["launch_type"] = "Launch Type Required.";
      } else {
      }

      if (
        courseLaunchFormData?.LaunchType == 2 &&
        (courseLaunchFormData?.ScheduleDateTime == null ||
          courseLaunchFormData?.ScheduleDateTime == undefined ||
          !moment(
            courseLaunchFormData?.ScheduleDateTime,
            "DD-MM-YYYY HH:mm:ss"
          ).isValid())
      ) {
        ErrorObj["schedule_datetime"] = "Schedule Date Time required.";
      }

      if (isIssueCertificate) {
        if (
          courseLaunchFormData?.certificateTemplate == null ||
          courseLaunchFormData?.certificateTemplate == undefined
        ) {
          ErrorObj["certificateTemplate"] = "Certificate Template Required.";
        }
      }

      setCourseLaunchErrors(ErrorObj);

      if (Object.keys(ErrorObj).length != 0) {
        return;
      }

      showLoader();

      if (courseLaunchFormData?.journey?.value > 0) {
        showLoader();
        if (courseId != null && courseId != 0) {
          let requestParams = {
            CourseIds: courseId,
            ProgramId: courseLaunchFormData?.journey?.value,
          };

          const response = await APICall(
            GetLaunchValidationForCourse,
            "POST",
            requestParams
          );

          if (response?.status === 1) {
            notify(response?.status, response?.message);
            hideLoader();
          } else {
            const response = await APICall(LaunchCourse, "POST", {
              courseId,
              journeyId: courseLaunchFormData?.journey?.value,
              LaunchDateTime: courseLaunchFormData?.ScheduleDateTime
                ? moment(courseLaunchFormData?.ScheduleDateTime).format(
                    "MM-DD-YYYY HH:mm"
                  )
                : null,
              userId: userDetails?.Id,
              LaunchType: courseLaunchFormData?.LaunchType,
              DomainIds:
                courseLaunchFormData?.domain
                  ?.map((event) => event.value)
                  .join(",") || null,
              CourseScheduleIds:
                courseLaunchFormData?.courseSchedule
                  ?.map((event) => event?.value)
                  .join(",") || null,
              IsIssueCertificate: isIssueCertificate,
              CertificateTemplateId:
                courseLaunchFormData?.certificateTemplate?.value,
              Signature1UserId: courseLaunchFormData?.signature1?.value || null,
              Signature2UserId: courseLaunchFormData?.signature2?.value || null,
            });

            if (response?.status == 0) {
              notify(0, "Course launched successfully.");
              handleCloseForLaunchModel();
              navigate("/CoursesDashboard");
            } else {
              console.error(response?.message);
              notify(1, "Something went wrong.");
            }
          }
        } else {
          notify(1, "Course id is zero");
        }
        hideLoader();
      } else {
        notify(1, "Journy id is zero");
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  async function handleFetchCoursesById(signal) {
    try {
      showLoader();
      const response = await APICallWithSignal(
        GetCourseById,
        "POST",
        {
          Id: courseId,
        },
        true,
        null,
        signal
      );

      if (response?.status == 0) {
        const { data } = response;

        if (data?.launched != null && data?.launched != undefined) {
          setIsLaunched(data?.launched);
        } else {
          setIsLaunched(false);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  const DownloadExcelTemplateApiCall = async () => {
    try {
      showLoader();
      const response = await APICall(DownloadExcelTemplate, "POST", {});

      if (response?.status == 0) {
        const { excelFileBase64 } = response?.data;

        if (excelFileBase64) {
          const fileName = `UploadTempate_${moment().format(
            "DD-MM-YYYY HH:mm:ss"
          )}.xlsx`;
          const base64 = excelFileBase64;

          let arrayBuffer = await base64ToArrayBuffer(base64);
          var byteArray = new Uint8Array(arrayBuffer);

          let blob = new Blob([byteArray], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          const newFile = new File([blob], "", {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            lastModified: new Date().getTime(),
          });

          fileDownload(newFile, fileName);
        }
      } else {
        notify(1, "Something went wrong, Please Try again Later.");
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  async function handleExporToExcelParticiptionList() {
    try {
      showLoader();
      const response = await APICall(
        ExportToExcelParticipationListForTargetLearner,
        "POST",
        {
          courseScheduleId: tempScheduleId,
          PageNumber: employeeGridPage,
          SortColumn: employeeGridSortColumn,
          SortOrder: employeeGridSortDirection,
          SearchText: "",
          PageSize: pageSize,
        }
      );

      if (response?.status == 0) {
        const { excelFileBase64 } = response?.data;

        if (excelFileBase64) {
          const fileName = `ParticiptionList_${moment().format(
            "DD-MM-YYYY HH:mm:ss"
          )}.xlsx`;
          const base64 = excelFileBase64;

          let arrayBuffer = await base64ToArrayBuffer(base64);
          var byteArray = new Uint8Array(arrayBuffer);

          let blob = new Blob([byteArray], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          const newFile = new File([blob], "", {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            lastModified: new Date().getTime(),
          });

          fileDownload(newFile, fileName);
        }
      } else {
        notify(1, "Something went wrong, Please Try again Later.");
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  const DeleteAllCourseScheduleParticipants = async () => {
    try {
      await showLoader();
      if (
        showParticipationDeletion.courseScheduleId > 0 &&
        showParticipationDeletion.modal
      ) {
        const res = await APICall(deleteAllCourseScheduleParticipants, "POST", {
          CourseScheduleId: showParticipationDeletion.courseScheduleId,
          UserId: userDetails?.Id,
        });
        if (res.status === 0) {
          notify(0, "Participants deleted successfully");
          setPage(0);
          setRefresh((prev) => !prev);
        } else {
          notify(1, "Something went wrong!");
          console.error(res);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      await hideLoader();
      setShowParticipationDeletion({ modal: false, courseScheduleId: 0 });
    }
  };

  return (
    <div className="container-fluid">
      <div className="d-flex align-items-center justify-content-end mb-3">
        {!isDisable && (
          <button
            onClick={() => {
              setCourseLaunchModal(true);
            }}
            className="btn btn-filter-submit mr-2 float-right">
            <i className="fa-solid fa-rocket mr-1" />
            {/* {isLaunched ? "Re Launch" : "Launch"} */}
            Launch
          </button>
        )}
        {isLaunched && (
          <button
            disabled={false}
            className="btn btn-filter-submit mr-2 float-right"
            onClick={() => {
              setLaunchHistoryModel(true);
            }}>
            <i className="fa-solid fa-history "></i> Launch History
          </button>
        )}
      </div>
      <div className="row">
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1">
            <label className="col-form-label">
              {isScheduleRequired ? "Course Schedule" : "Batch"}{" "}
            </label>{" "}
            <sup>*</sup>
            <SelectForm
              placeholder={isScheduleRequired ? "Course Schedule" : "Batch"}
              isDisabled={isDisable}
              value={courseSchedule}
              onChange={(val: any) => {
                handleOnChange(val, "course_schedule");
              }}
              options={courseScheduleOptions}
              noIndicator={false}
              noSeparator={false}
            />
            <span className="" style={{ color: "red" }}>
              {formError["course_schedule"]}
            </span>
          </div>
        </div>
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1">
            <label className="col-form-label">Type</label> <sup>*</sup>
            <SelectForm
              placeholder={"Type"}
              isDisabled={isDisable}
              value={uploadType}
              isOptionDisabled={(option) => !option?.isEnabled}
              onChange={(val: any) => {
                handleOnChange(val, "upload_type");
              }}
              options={uploadTypeOptions}
              noIndicator={false}
              noSeparator={false}
            />
            <span className="" style={{ color: "red" }}>
              {formError["upload_type"]}
            </span>
          </div>
        </div>
        {uploadType?.value == 1 && (
          <>
            <div className="col-lg-3 col-sm-3 col-xs-4">
              <div className="mb-1">
                <label className="col-form-label">Target learner group</label>{" "}
                <sup>*</sup>
                <SelectForm
                  placeholder={"Target learner group"}
                  isDisabled={isDisable}
                  value={targetGroup}
                  onChange={(val: any) => {
                    handleOnChange(val, "target_learner_group");
                  }}
                  options={targetGroupOptions}
                  noIndicator={false}
                  noSeparator={false}
                />
                <span className="" style={{ color: "red" }}>
                  {formError["target_group"]}
                </span>
              </div>
            </div>
          </>
        )}
        {uploadType?.value == 2 && (
          <>
            <div className="col-lg-3 col-sm-3 col-xs-4">
              <div className="mt-2">
                <label className="col-form-label"></label>
                <>
                  <div className="box position-relative">
                    <input
                      id="file"
                      className="form-control inputfile inputfile-6 multiple-inputfile"
                      data-multiple-caption="{count} files selected"
                      multiple={false}
                      type="file"
                      onChange={(e) => {
                        handleOnChange(e, "file");
                      }}
                      accept={".xlsx,.xls"}
                      ref={fileRef}
                      value={""}
                      disabled={isDisable}
                    />
                    <label
                      htmlFor={`file`}
                      className="form-control"
                      style={{ width: 0, border: "none" }}>
                      <strong
                        style={{
                          padding: "6px 16px",
                          backgroundColor: "#3c5464",
                          borderRadius: "5px",
                        }}>
                        <i
                          className="fa fa-upload rotate90"
                          aria-hidden="true"></i>{" "}
                        Upload
                      </strong>
                    </label>
                  </div>
                </>
                <div className="file-added-list">
                  <ul className="list-unstyle">
                    <li className="list mt-1">
                      <div className="media">
                        <div className="media-body text-truncate">
                          <span className="view-more">{file?.name}</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <span style={{ color: "red" }}>{formError["file"]}</span>
              </div>
            </div>
          </>
        )}
        {!isDisable && (
          <div className="col-lg-5 col-sm-6 col-xs-6">
            <div className="mt-2">
              <label className="col-form-label"></label>
              <div className="d-flex mt-1">
                {uploadType?.value == 2 && (
                  <button
                    className="btn btn-primary mr-2"
                    disabled={disableBtn}
                    onClick={() => DownloadExcelTemplateApiCall()}>
                    Download Template
                  </button>
                )}

                <button
                  className="btn btn-primary mr-2"
                  disabled={disableBtn}
                  onClick={handleSave}>
                  Save
                </button>
                <button className="btn btn-primary" onClick={handleReset}>
                  Reset
                </button>

                <Link
                  className="btn btn-primary"
                  style={{ marginLeft: 5 }}
                  to={"/targetlearnergroup"}
                  target="_blank">
                  <i className="fa-solid fa-plus"></i> Create Target Learner
                  Group
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="mb-3 pt-3">
        <DynamicGrid data={gridData} columns={gridColumns} options={options} />
      </div>

      {/* Modal to delete target learner */}

      <Modal
        show={showParticipationDeletion.modal}
        onHide={() =>
          setShowParticipationDeletion({ modal: false, courseScheduleId: 0 })
        }
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">{"Confirmation"}</div>
        </div>
        <Modal.Body>
          <>
            <div className="col-lg-12">
              {"Are you sure you want to delete participants this record?"}
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => DeleteAllCourseScheduleParticipants()}>
            Yes
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowParticipationDeletion({
                modal: false,
                courseScheduleId: 0,
              });
            }}>
            No
          </button>
        </Modal.Footer>
      </Modal>

      {/* Employee Grid DataTable  */}
      <Modal
        show={openParticiptionList}
        onHide={handleCloseModelForParticiptionList}
        backdrop="static"
        keyboard={false}
        size="xl"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">Participation List</div>
        </div>
        <Modal.Body>
          <>
            {employeeGridData?.length > 0 && (
              <>
                <div className="d-flex align-items-center justify-content-end mb-3">
                  <button
                    className="btn btn-success mt-3"
                    onClick={handleExporToExcelParticiptionList}>
                    Export Data{" "}
                    <i className="fa fa-file-excel-o" aria-hidden="true" />
                  </button>
                </div>
              </>
            )}

            <DynamicGrid
              data={employeeGridData}
              columns={employeeGridColums}
              options={employeeGridOptions}
            />
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={handleCloseModelForParticiptionList}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      {/* Course Launch */}
      <Modal
        show={courseLaunchModal}
        onHide={handleCloseForLaunchModel}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">Course Launch</div>
        </div>
        <Modal.Body>
          <>
            <div className="row">
              <div className="col-lg-6 col-sm-6 col-xs-6">
                <div className="mb-1">
                  <label className="col-form-label">Journey</label> <sup>*</sup>
                  <SelectForm
                    value={courseLaunchFormData?.journey}
                    placeholder={"Journey"}
                    options={journeyOptions}
                    onChange={(event) => {
                      setCourseLaunchFormData((prev) => {
                        return {
                          ...prev,
                          journey: event,
                        };
                      });
                    }}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  <span className="" style={{ color: "red" }}>
                    {courseLaunchErrors["journey"]}
                  </span>
                </div>
              </div>

              <div className="col-lg-6 col-sm-6 col-xs-6">
                <div className="mb-1">
                  <label className="col-form-label">
                    {isScheduleRequired ? "Course Schedule" : "Batch"}
                  </label>{" "}
                  <sup>*</sup>
                  <SelectForm
                    placeholder={
                      isScheduleRequired ? "Course Schedule" : "Batch"
                    }
                    isDisabled={isDisable}
                    value={courseLaunchFormData?.courseSchedule}
                    onChange={(event) => {
                      setCourseLaunchFormData((prev) => {
                        return {
                          ...prev,
                          courseSchedule: event,
                        };
                      });
                    }}
                    options={launchCourseScheduleOptions}
                    noIndicator={false}
                    noSeparator={false}
                    isMulti={true}
                  />
                  <span className="" style={{ color: "red" }}>
                    {courseLaunchErrors["courseSchedule"]}
                  </span>
                </div>
              </div>

              {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">Domain</label> <sup></sup>
                  <SelectForm
                    value={courseLaunchFormData?.domain}
                    placeholder={"Domain"}
                    options={domainOption}
                    onChange={(event) => {
                      setCourseLaunchFormData((prev) => {
                        return {
                          ...prev,
                          domain: event,
                        };
                      });
                    }}
                    noIndicator={false}
                    noSeparator={false}
                    isMulti={true}
                  />
                </div>
              </div> */}
              <div className="col-lg-6 col-sm-6 col-xs-6">
                <div className="row">
                  <div className="col-lg-6 col-sm-6 col-xs-6">
                    <div className="mb-1">
                      <label className="col-form-label"></label>
                      <div>
                        <input
                          className="ml-2 mr-1"
                          id={`Immedidate`}
                          type="radio"
                          name="Immedidate"
                          onChange={(e) => {
                            setCourseLaunchFormData((prev) => {
                              return {
                                ...prev,
                                LaunchType: e.target.value,
                                ScheduleDateTime: null,
                              };
                            });
                          }}
                          value={1}
                          checked={courseLaunchFormData?.LaunchType == 1}
                        />
                        <label htmlFor={`Immedidate`}>Immedidate Launch</label>
                      </div>
                      <span className="" style={{ color: "red" }}>
                        {courseLaunchErrors["launch_type"]}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-xs-6">
                    <div className="mb-1">
                      <label className="col-form-label"></label>
                      <div>
                        <input
                          className="ml-2 mr-1"
                          id={`Schedule`}
                          type="radio"
                          name="Schedule"
                          onChange={(e) => {
                            setCourseLaunchFormData((prev) => {
                              return {
                                ...prev,
                                LaunchType: e.target.value,
                                ScheduleDateTime: null,
                              };
                            });
                          }}
                          value={2}
                          checked={courseLaunchFormData?.LaunchType == 2}
                        />
                        <label htmlFor={`Schedule`}>Schedule Launch</label>
                      </div>
                      <span className="" style={{ color: "red" }}>
                        {courseLaunchErrors["launch_type"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {courseLaunchFormData?.LaunchType == 2 && (
                <>
                  <div className="col-lg-6 col-sm-6 col-xs-6">
                    <div className="mb-1">
                      <label className="col-form-label">
                        Schedule Date & Time
                      </label>{" "}
                      <sup>*</sup>
                      <DateTimeForm
                        value={courseLaunchFormData?.ScheduleDateTime}
                        placeholder={"Start Date"}
                        onChange={(event) => {
                          setCourseLaunchFormData((prev) => {
                            return {
                              ...prev,
                              ScheduleDateTime: event,
                            };
                          });
                        }}
                        isDisabled={false}
                        placement="right"
                      />
                      <span className="" style={{ color: "red" }}>
                        {courseLaunchErrors["schedule_datetime"]}
                      </span>
                    </div>
                  </div>
                </>
              )}

              <div className={"col-lg-6 col-sm-6 col-xs-6"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Issue Certificate
                    <sup>*</sup>
                  </label>

                  <div>
                    <input
                      className="ml-2 mr-1"
                      disabled={isDisable}
                      type="radio"
                      onChange={(e) => {
                        setIsIssueCertificate(true);
                      }}
                      checked={isIssueCertificate === true}
                    />
                    <label>Yes</label>
                    <input
                      className="ml-2 mr-1"
                      disabled={isDisable}
                      type="radio"
                      onChange={(e) => {
                        setIsIssueCertificate(false);
                      }}
                      checked={isIssueCertificate === false}
                    />
                    <label>No</label>
                  </div>
                </div>
              </div>

              {isIssueCertificate && (
                <>
                  <div className="col-lg-6 col-sm-6 col-xs-6">
                    <div className="mb-1">
                      <label className="col-form-label">
                        Certificate Template
                      </label>{" "}
                      <sup>*</sup>
                      <SelectForm
                        value={courseLaunchFormData?.certificateTemplate}
                        placeholder={"Certificate Template"}
                        options={certificateTemplateOptions}
                        onChange={(event) => {
                          setCourseLaunchFormData((prev) => {
                            return {
                              ...prev,
                              certificateTemplate: event,
                            };
                          });
                        }}
                        noIndicator={false}
                        noSeparator={false}
                      />
                      <span className="" style={{ color: "red" }}>
                        {courseLaunchErrors["certificateTemplate"]}
                      </span>
                    </div>
                  </div>

                  {/* <div className="col-lg-6 col-sm-6 col-xs-6">
                    <div className="mb-1">
                      <label className="col-form-label">Signature 1</label>{" "}
                      <SelectForm
                        value={courseLaunchFormData?.signature1}
                        placeholder={"Signature 1"}
                        options={signatureOptions}
                        onChange={(event) => {
                          setCourseLaunchFormData((prev) => {
                            return {
                              ...prev,
                              signature1: event,
                            };
                          });
                        }}
                        isClearable
                        noIndicator={false}
                        noSeparator={false}
                      />
                      <span className="" style={{ color: "red" }}>
                        {courseLaunchErrors["signature1"]}
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-6 col-sm-6 col-xs-6">
                    <div className="mb-1">
                      <label className="col-form-label">Signature 2</label>{" "}
                      <SelectForm
                        value={courseLaunchFormData?.signature2}
                        placeholder={"Signature 2"}
                        options={signatureOptions}
                        onChange={(event) => {
                          setCourseLaunchFormData((prev) => {
                            return {
                              ...prev,
                              signature2: event,
                            };
                          });
                        }}
                        isClearable
                        noIndicator={false}
                        noSeparator={false}
                      />
                      <span className="" style={{ color: "red" }}>
                        {courseLaunchErrors["signature2"]}
                      </span>
                    </div>
                  </div> */}

                  {/* <div
                    className={"col-lg-6 col-sm-6 col-xs-6 mt-4"}
                    // style={{ margin: "5px" }}
                  >
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        getSignatureDropDownApiCall();
                      }}>
                      <i className="fa-solid fa-refresh"></i> Refresh
                    </button>

                    <Link
                      className="btn btn-primary"
                      style={{ marginLeft: 5 }}
                      to={"/Signature"}
                      target="_blank">
                      <i className="fa-solid fa-plus"></i> Create Signature
                    </Link>
                  </div> */}

                  <div className="col-lg-6 col-sm-6 col-xs-6">
                    <div className="mb-1">
                      <label className="col-form-label"></label>{" "}
                      <button
                        style={{ marginTop: "32px" }}
                        className="btn btn-primary"
                        onClick={() => {
                          previewCertificate();
                        }}>
                        <i className="fa fa-file-pdf-o"></i> Preview Certificate
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={handleSaveForLaunchModel}>
            Submit
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={handleCloseForLaunchModel}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      {/* Launch History */}

      <Modal
        show={launchHistoryModel}
        onHide={() => setLaunchHistoryModel(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">Launch History</div>
        </div>
        <Modal.Body>
          <>
            <div className="mb-3 pt-3">
              <DynamicGrid
                data={launchHistoryData}
                columns={launchHistoryGridColumns}
                options={launchHistoryOptions}
              />
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={(e) => {
              e.preventDefault();
              setLaunchHistoryModel(false);
            }}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      {/* Delete Employee List Model */}
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">{"Confirmation"}</div>
        </div>
        <Modal.Body>
          <>
            <div className="col-lg-12">
              {"Are you sure you want to delete this record?"}
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() =>
              DeleteCourseScheduleParticipantsByIdApiCall(deleteId)
            }>
            Yes
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowDeleteModal(false);
            }}>
            No
          </button>
        </Modal.Footer>
      </Modal>

      {/* Validation Error */}
      <Modal
        show={showErrorModal}
        onHide={() => setShowErrorModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">Validation Error</div>
        </div>
        <Modal.Body>
          <>
            {errorData.length > 0 && (
              <div className="row my-2">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                  <DynamicGrid
                    data={errorData}
                    columns={errorDataColumns}
                    options={errorDataOptions}
                  />
                </div>
              </div>
            )}
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={(e) => {
              e.preventDefault();
              setShowErrorModal(false);
            }}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Participation;
