import React, { useState, useEffect, useContext } from "react";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { useNavigate } from "react-router-dom";
import SelectForm from "../../Components/SelectForm/SelectForm";
import "./OnBoardingDashboard.css";
import Collapse from "react-bootstrap/Collapse";

import { APICall } from "../../Helpers/APICalls";
import {
  getDashboardGridData,
  getDropdowns,
  getOnBoardingDashboardData,
} from "../../Helpers/APIEndPoints/EndPoints";
import InputForm from "../../Components/InputForm/InputForm";
import { Tooltip } from "@mui/material";
import { LoaderContext } from "../../Helpers/Context/Context";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import notify from "../../Helpers/ToastNotification";
import { OverlayTrigger, Popover } from "react-bootstrap";

const OnBoardingDashboard = (props) => {
  const [filteredData, setFilteredData] = useState([]);
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [gridData, setGridData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [PageSize, setPageSize] = useState(10);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  let { userDetails, currentRoleId }: any = useUserContext();
  const [sortDirection, setSortDirection] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [phyLoc, setPhyLoc] = useState<any>([]);
  const [bussiness, setBussiness] = useState<any>([]);
  const [sbuVal, setSbuVal] = useState<any>([]);
  const [legalEntity, setLegalEntity] = useState<any>([]);
  const [cubis, setCubis] = useState<any>([]);
  const [LENDDOption, setLENDDOptions] = useState<any>([]);
  const [PLOCDDOptions, setPLOCDDOptions] = useState<any>([]);
  const [statusDDOptions, setStatusDDOptions] = useState<any>([]);
  const [LENSelectedValue, setLENSelectedValue] = useState<any>([]);
  const [PLOCSelectedValue, setPLOCSelectedValue] = useState<any>([]);
  const [statusSelectedValue, setStatusSelectedValue] = useState<any>([]);
  const [ddValue, setDdValue] = useState({});
  const [status, setStatus] = useState<any>([]);
  let CNTRY = [
    { value: "India", code: "CODECNTRY1", label: "India" },
    { value: "United States", code: "CODECNTRY7", label: "United States" },
    { value: "United Kingdom", code: "CODECNTRY51", label: "United Kingdom" },
  ];

  useEffect(() => {
    getDDOptions();
    getOnboardingDashboardData();
  }, []);

  const getOnboardingDashboardData = async () => {
    showLoader();
    const GetDashboardData = await APICall(getOnBoardingDashboardData, "POST", {
      Page: page,
      PageSize: PageSize,
      SearchText: searchText,
      UserId: userDetails?.Id,
      LEN: LENSelectedValue?.map((s) => s.value)?.join(","),
      PLOC: PLOCSelectedValue?.map((s) => s.value)?.join(","),
      Status: statusSelectedValue?.map((s) => s.value)?.join(","),
    });
    if (
      GetDashboardData &&
      GetDashboardData.data !== null &&
      GetDashboardData.data.length > 0
    ) {
      setCount(GetDashboardData.data[0].count);
      //
      setRowData(GetDashboardData.data);
    } else {
      setRowData([]);
      setCount(0);
    }
    hideLoader();
  };

  const getDDOptions = async () => {
    let LENData = await getDropdownsData("OrgMasterData", "LegalEntity");
    setLENDDOptions(LENData);

    let PLOCData = await getDropdownsData("OrgMasterData", "PayrollLocation");
    setPLOCDDOptions(PLOCData);

    let statusData = await getDropdownsData("status", userDetails?.Id);
    setStatusDDOptions(statusData);
  };

  const getDropdownsData = async (AC, PID = null) => {
    await showLoader();
    const dropdowndataResponse = await APICall(getDropdowns, "POST", {
      AC,
      PID,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });
    await hideLoader();
    const dropdownData = dropdowndataResponse.data;
    return dropdownData;
  };

  const handleOnChange = async (filter, event) => {
    if (filter === "LEN") {
      setLENSelectedValue(event);
    } else if (filter === "PLOC") {
      setPLOCSelectedValue(event);
    } else if (filter === "Status") {
      setStatusSelectedValue(event);
    }
  };

  const handleOnclick = async (clickType) => {
    if (clickType === "reset") {
      setSearchText("");
      setLENSelectedValue([]);
      setPLOCSelectedValue([]);
      setStatusSelectedValue([]);
      getOnboardingDashboardData();
    } else if (clickType === "submit") {
      getOnboardingDashboardData();
    }
  };

  useEffect(() => {
    getOnboardingDashboardData();
  }, [page, searchText]);
  const view = (value, tableMeta) => {
    const url = value;
    // window.location.href = url;
    window.open(url);
  };

  const edit = (value, tableMeta, url) => {
    const TId = tableMeta.rowData[0];
    const NewHireStatus = tableMeta.rowData[9];
    const Country = tableMeta.rowData[10];
    // navigate(url[1].editUrl);
    navigate("/profile", {
      state: { TId: TId, COU: Country, CT: Country, NewHireStatus },
    });
  };

  const copy = (value, tableMeta) => {
    alert("copy");
  };

  const modal = (value, tableMeta) => {
    alert("modal");
  };

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: PageSize,
    page: page,
    serverSide: true,
    onChangePage: async (page) => {
      setPage(page);
    },
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",
    onColumnSortChange: async (sortColumn, sortDirection) => {
      setPage(0);
      if (sortDirection === "asc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
    },
  };
  const OnBoardingGridCols = [
    {
      name: "onBoardingNo",
      label: "OnBoardigNo",
      options: {
        display: true,
      },
    },
    {
      name: "dn",
      label: "CandidateName",
      options: {
        display: true,
      },
    },
    {
      name: "email",
      label: "E-mailId",
      options: {
        display: true,
      },
    },
    {
      name: "position",
      label: "Position",
      options: {
        display: true,
      },
    },
    {
      name: "designation",
      label: "Designation",
      options: {
        display: true,
      },
    },
    {
      name: "legalEntity",
      label: "Legal Entity",
      options: {
        display: true,
      },
    },
    {
      name: "location",
      label: "Payroll Location",
      options: {
        display: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        display: true,
      },
    },
    {
      name: "taskId",
      label: "TaskId",
      options: {
        display: false,
      },
    },
    {
      name: "",
      label: "Action",
      options: {
        display: true,
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        customBodyRender: (value: any, tableMeta: any) => {
          const Id=tableMeta.tableData[tableMeta.rowIndex].taskId;
          return(
            <Tooltip title="Edit">
            <i
              style={{ marginRight: "0px", cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
               
                navigate("/OnBoardingCheckList", { state: Id });
              
              }}
              className="fas fa-edit"
            ></i>
          </Tooltip>
          )
        },
      },
    },
  ];

  return (
    <div className="container-fluid">
      <div className="my-3">
        <button
          onClick={() => navigate("/home")}
          className="btn btn-primary ml-2">
          <i className="fa-solid fa-arrow-left mr-1"></i> Back
        </button>
      </div>
      <div className="grid-wrapper grid_mob">
        <div className="row mx-auto filtBox">
          <>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="form-group filtInp">
                <span className="mr-2 text-white" style={{ float: "left" }}>
                  <label className="col-form-label">Legal Entity</label>
                </span>
                <SelectForm
                  isClearable
                  options={LENDDOption}
                  placeholder="Legal Entity"
                  isDisabled={false}
                  onChange={(event) => {
                    handleOnChange("LEN", event);
                  }}
                  isMulti={true}
                  noIndicator={false}
                  noSeparator={false}
                  value={LENSelectedValue}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="form-group filtInp">
                <span className="mr-2 text-white" style={{ float: "left" }}>
                  <label className="col-form-label">Payroll Location</label>
                </span>
                <SelectForm
                  isClearable
                  options={PLOCDDOptions}
                  placeholder="Payroll Location"
                  isDisabled={false}
                  onChange={(event) => {
                    handleOnChange("PLOC", event);
                  }}
                  isMulti={true}
                  noIndicator={false}
                  noSeparator={false}
                  value={PLOCSelectedValue}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="form-group filtInp">
                <span className="mr-2 text-white" style={{ float: "left" }}>
                  <label className="col-form-label">Status</label>
                </span>
                <SelectForm
                  isClearable
                  options={statusDDOptions}
                  placeholder="Status"
                  isDisabled={false}
                  onChange={(event) => {
                    handleOnChange("Status", event);
                  }}
                  isMulti={true}
                  noIndicator={false}
                  noSeparator={false}
                  value={statusSelectedValue}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="form-group filtInp">
                <InputForm
                  value={searchText}
                  placeholder="Search"
                  isDisabled={false}
                  textArea={false}
                  onChange={(e) => {
                    setPage(0);
                    setSearchText(e.target.value);
                  }}
                />
              </div>
            </div>
          </>
        </div>
        <div className="col-lg-12 d-flex mt-2 justify-content-end">
          <button
            className="btn btn-secondary mr-2"
            onClick={() => {
              handleOnclick("reset");
            }}
          >
            <i className="fa fa-undo" aria-hidden="true"></i> Reset
          </button>
          <button
            className="btn btn-filter-submit"
            onClick={() => {
              handleOnclick("submit");
            }}
          >
            <i className="fa fa-check" aria-hidden="true"></i> Submit{" "}
          </button>
        </div>
      </div>
      <div className="mb-3 pt-3">
        <DynamicGrid
          data={rowData}
          columns={OnBoardingGridCols}
          options={options}
        />
      </div>
    </div>
  );
};

export default OnBoardingDashboard;
