import React, { useEffect, useState, useContext } from "react";
import useUserContext from "../../../Helpers/ZustandStore/UserContextStore";
import moment from "moment";
import {
  GetCommonDropdownForScheduling,
  InsertUpdateSessionSchedule,
  meetingActionApi,
} from "../../../Helpers/APIEndPoints/LMS_EndPoints";
import notify from "../../../Helpers/ToastNotification";
import { APICall } from "../../../Helpers/APICalls";
import AccordianCustom from "../ScheduleCourseAndSession/AccordianCustom";
import DateTimeForm from "../../../Components/DateTimeForm/DateTimeForm";
import SelectForm from "../../../Components/SelectForm/SelectForm";
import { HideImage } from "@mui/icons-material";
import { LoaderContext } from "../../../Helpers/Context/Context";
import InputForm from "../../../Components/InputForm/InputForm";
import { Tooltip } from "@mui/material";

import calenderClose from "../../../Assets/Images/calendar-regular-close.png";
import calenderRefresh from "../../../Assets/Images/calendar-regular-refresh.png";

interface IOptions {
  label?: string;
  value?: number | string;
  code?: string;
}

interface IFormData {
  id?: number | any;
  course_name?: string;
  training_days_id?: IOptions | boolean | any;
  training_days_centre?: IOptions[] | boolean | any;
  target_learner_group?: IOptions | boolean | any;
  instructor?: IOptions | boolean | any;
  co_ordinator?: IOptions | boolean | any;
  start_date?: Date | string | boolean | any;
  end_date?: Date | string | boolean | any;
  time_zone?: Date | string | boolean | any;
  hours_per_day?: number | boolean | any;
  start_datetime?: Date | string | any;
  end_datetime?: Date | string | any;
  calendarBlocking?: IOptions | boolean | any;
  guests?: IOptions | boolean | any;
  meetingDescription?: string | number | any;
  meetingId?: string | number | null;
  meetingAction?: boolean | null;
}

const SessionComponent = ({
  data,
  options,
  timeZone,
  courseScheduleData,
  isDisable = false,
  handleScheduleCopy = null,
  handleScheduleDelete = null,
  disableMeetInSession,
  setMeetControlsDisabled,
}) => {
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { userDetails, currentRoleId }: any = useUserContext();

  const [formData, setFormData] = useState<IFormData>({});
  const [disableAttribute, setDisableAttribute] = useState<any>(false);
  const [formErrors, setFormErrors] = useState<IFormData>({});
  const [btnDisable, setBtnDisable] = useState(false);
  const [hideAdditionalControls, setHideAdditionalMeetControls] =
    useState(false);

  useEffect(() => {
    setFormData((prev) => {
      return { ...prev, ...data };
    });

    if (
      data.calendarBlocking !== null &&
      data.calendarBlocking !== undefined &&
      (data.calendarBlocking.code.toLowerCase() === "online" ||
        data.calendarBlocking.code.toLowerCase() === "offline")
    ) {
      setHideAdditionalMeetControls(false);
    } else {
      setHideAdditionalMeetControls(true);
    }
  }, [data]);

  const handleOnChange = (event, code) => {
    setFormData((prev) => {
      return { ...prev, [code]: event };
    });
  };

  function validation(isTrainerMandetory) {
    try {
      const ErrorObj = {};

      if (
        formData.start_datetime == null ||
        formData.start_datetime == undefined ||
        !moment(formData.start_datetime).isValid()
      ) {
        ErrorObj["start_datetime"] = "Start Date required.";
      } else {
        delete ErrorObj["start_datetime"];
      }
      if (
        formData.end_datetime == null ||
        formData.end_datetime == undefined ||
        !moment(formData.end_datetime).isValid()
      ) {
        ErrorObj["end_datetime"] = "End Date required.";
      } else {
        delete ErrorObj["end_date"];
      }
      // if (
      //   formData.training_days_centre == null ||
      //   formData.training_days_centre == undefined ||
      //   !formData.training_days_centre?.value
      // ) {
      //   ErrorObj["training_days_centre"] = "Training center required.";
      // } else {
      //   delete ErrorObj["training_days_centre"];
      // }

      if (isTrainerMandetory) {
        if (
          formData.instructor == null ||
          formData.instructor == undefined ||
          formData.instructor?.length === 0
        ) {
          ErrorObj["instructor"] = "Instructor required.";
        } else {
          delete ErrorObj["instructor"];
        }
      } else {
        delete ErrorObj["instructor"];
      }
      if (
        formData.co_ordinator == null ||
        formData.co_ordinator == undefined ||
        formData.co_ordinator?.length == 0
      ) {
        ErrorObj["co_ordinator"] = "Co-ordinator required.";
      } else {
        delete ErrorObj["co_ordinator"];
      }

      const schedule_start_datetime = moment(formData?.start_datetime);
      const schedule_end_datetime = moment(formData?.end_datetime);

      if (
        !isDateBetween(
          schedule_start_datetime,
          moment(courseScheduleData.start_date),
          moment(courseScheduleData.end_date)
        )
      ) {
        ErrorObj["start_datetime"] =
          "Start Date must be between course start date and course end date.";
      }

      if (
        !isDateBetween(
          schedule_end_datetime,
          moment(courseScheduleData.start_date),
          moment(courseScheduleData.end_date)
        )
      ) {
        ErrorObj["end_datetime"] =
          "End Date must be between course start date and course end date.";
      }

      if (
        moment(formData?.start_datetime).isValid() &&
        moment(formData?.end_datetime).isValid()
      ) {
        if (moment(formData?.start_datetime).isAfter(formData?.end_datetime)) {
          ErrorObj["start_datetime"] =
            "Start date must be smaller than End date.";
        }
      }

      // if (
      //   !hideAdditionalControls &&
      //   (formData?.meetingDescription == null ||
      //     formData?.meetingDescription == undefined ||
      //     String(formData?.meetingDescription).trim().length == 0)
      // ) {
      //   ErrorObj["meetingDescription"] = "Meeting Description required.";
      // }
      // else
      if (
        !hideAdditionalControls &&
        formData?.meetingDescription.trim().length > 5000
      ) {
        ErrorObj["meetingDescription"] = "Maximum 5000 characters allowed";
      } else {
        delete ErrorObj["meetingDescription"];
      }

      //Start date should be smaller than end date
      setFormErrors(ErrorObj);
      return Object.keys(ErrorObj).length != 0;
    } catch (error) {
      console.error(error);
    }
  }

  function isDateBetween(dateToCheck, startDate, endDate) {
    // dateToCheck = moment(
    //   moment(dateToCheck, "DD-MM-YYYY").format("DD-MM-YYYY"),
    //   "DD-MM-YYYY"
    // );

    // startDate = moment(
    //   moment(startDate, "DD-MM-YYYY").format("DD-MM-YYYY"),
    //   "DD-MM-YYYY"
    // );
    // endDate = moment(
    //   moment(endDate, "DD-MM-YYYY").format("DD-MM-YYYY"),
    //   "DD-MM-YYYY"
    // );

    const check =
      dateToCheck.isSameOrAfter(startDate) &&
      dateToCheck.isSameOrBefore(endDate);

    return check;
  }

  async function handleSubmitAsync(event, isTrainerMandetory) {
    try {
      event.stopPropagation();

      const validationError = validation(isTrainerMandetory);

      if (validationError) return;
      setBtnDisable(true);
      const params = {
        SessionScheduleId: formData?.id,
        CourserScheduleId: data?.courserScheduleId,
        SessionId: data?.sessionId,
        TrainingCentreId: formData?.training_days_centre?.value,
        InstructorIds:
          formData?.instructor?.map((i) => i?.value)?.join(",") || "",
        CoordinatorIds:
          formData?.co_ordinator?.map((i) => i?.value)?.join(",") || "",
        StartDate: moment(formData?.start_datetime).format("MM-DD-YYYY HH:mm"),
        EndDate: moment(formData?.end_datetime).format("MM-DD-YYYY HH:mm"),
        UserId: userDetails?.id,
        CalendarBlockingId:
          formData.calendarBlocking !== undefined &&
          formData.calendarBlocking !== null
            ? formData.calendarBlocking.value
            : null,
        GuestIds:
          (formData.guests &&
            formData?.guests?.map((i) => i?.value)?.join(",")) ||
          null,
        MeetingDescription:
          formData.meetingDescription !== ""
            ? formData.meetingDescription
            : null,
      };

      showLoader();
      const response = await APICall(
        InsertUpdateSessionSchedule,
        "POST",
        params
      );

      if (response?.status == 0) {
        let id = response?.data?.id;
        setMeetControlsDisabled(response?.data?.meetControlsIsDisabled);
        setFormData((prev) => {
          return { ...prev, id };
        });
        notify(0, "Recored Saved Successfully");
      } else if (response?.status == 5) {
        notify(1, response?.message);
      } else if (response?.status == 1) {
        notify(1, response?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setBtnDisable(false);
      hideLoader();
    }
  }

  function handleClear(event) {
    event.stopPropagation();
    setFormData((prev) => {
      return {
        ...prev,
        start_datetime: null,
        end_datetime: null,
        training_days_centre: null,
        co_ordinator: null,
        instructor: null,
      };
    });

    setFormErrors({});
  }

  async function fetchDropdown(searchText = "", code = "", value = null) {
    try {
      const response = await APICall(
        GetCommonDropdownForScheduling,
        "POST",
        {
          SearchText: searchText,
          DropdownValues: value,
          Code: code,
          UserId: userDetails?.Id,
          RoleId: currentRoleId?.value,
        },
        true
      );

      if (response?.status === 0) {
        return response?.data;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  async function handleSearchForAsyncDropdown(event, code, callback) {
    try {
      if (event?.length >= 3) {
        const data = await fetchDropdown(event, code);
        callback(data);
      } else {
        callback([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const meetingAction = async () => {
    try {
      showLoader();

      if (
        formData.meetingId !== null &&
        formData.meetingId != "" &&
        formData.meetingId != -1
      ) {
        const res = await APICall(meetingActionApi, "POST", {
          Mode: "CANCEL",
          CourserScheduleId: data?.courserScheduleId,
          SessionScheduleId: formData?.id,
        });
        if (res.status === 0) {
          setFormData((prev) => ({ ...prev, meetingAction: true }));
          notify(0, "Meeting cancelation has been queued");
        } else {
          setFormData((prev) => ({ ...prev, meetingAction: false }));
          console.error(res);
          console.error(1, "Something went wrong!");
        }
      } else if (
        formData.meetingId !== null &&
        formData.meetingId != "" &&
        formData.meetingId == -1
      ) {
        const res = await APICall(meetingActionApi, "POST", {
          Mode: "REINVITE",
          CourserScheduleId: data?.courserScheduleId,
          SessionScheduleId: formData?.id,
        });
        if (res.status === 0) {
          setFormData((prev) => ({ ...prev, meetingAction: true }));
          notify(0, "Meeting re-scheduling has been queued");
        } else {
          setFormData((prev) => ({ ...prev, meetingAction: false }));
          console.error(res);
          console.error(1, "Something went wrong!");
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <AccordianCustom
      header={data?.session_title}
      submitFunc={handleSubmitAsync}
      clearFunc={handleClear}
      btnDisable={isDisable ? true : btnDisable}
      handleScheduleCopy={handleScheduleCopy}
      handleScheduleDelete={handleScheduleDelete}
      sessionScheduleId={formData.id}
      isTrainerMandetory={data?.isTrainerMandetory}
    >
      <div className="row">
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1">
            <label className="col-form-label">
              Start Date & Time <span style={{ color: "red" }}>*</span>
            </label>
            <DateTimeForm
              value={formData?.start_datetime}
              placeholder={"Start Date"}
              onChange={(event) => {
                handleOnChange(event, "start_datetime");
              }}
              isDisabled={isDisable ? true : disableAttribute}
            />
            <p style={{ color: "red" }}>{formErrors?.start_datetime}</p>
          </div>
        </div>
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1">
            <label className="col-form-label">
              End Date & Time <span style={{ color: "red" }}>*</span>
            </label>
            <DateTimeForm
              value={formData.end_datetime}
              placeholder={"End Date"}
              onChange={(event) => {
                handleOnChange(event, "end_datetime");
              }}
              isDisabled={isDisable ? true : disableAttribute}
            />
            <p style={{ color: "red" }}>{formErrors?.end_datetime}</p>
          </div>
        </div>
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1">
            <label className="col-form-label">Time Zone</label>
            <SelectForm
              placeholder={"Time Zone"}
              isDisabled={true}
              textArea={false}
              value={timeZone}
              onChange={(val: any) => {
                handleOnChange(val, "time_zone");
              }}
              options={options?.time_zone}
              noIndicator={false}
              noSeparator={false}
            />
            <p style={{ color: "red" }}>{formErrors?.time_zone}</p>
          </div>
        </div>
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1">
            <label className="col-form-label">
              Training Centre
              {/* <span style={{ color: "red" }}>*</span> */}
            </label>
            <SelectForm
              placeholder={"Training Centre"}
              isDisabled={
                isDisable ? true : disableAttribute?.training_days_centre
              }
              textArea={false}
              value={formData?.training_days_centre}
              onChange={(val: any) => {
                handleOnChange(val, "training_days_centre");
              }}
              options={options?.training_days_centre}
              noIndicator={false}
              noSeparator={false}
            />
            <p style={{ color: "red" }}>{formErrors?.training_days_centre}</p>
          </div>
        </div>
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="dropdown mb-1">
            <label className="col-form-label">
              Trainer{" "}
              {data?.isTrainerMandetory && (
                <span style={{ color: "red" }}>*</span>
              )}
            </label>
            <SelectForm
              placeholder={"Trainer"}
              isDisabled={isDisable ? true : disableAttribute?.instructor}
              textArea={false}
              value={formData?.instructor}
              onChange={(val: any) => {
                handleOnChange(val, "instructor");
              }}
              options={options?.instructor}
              isMulti={true}
              noIndicator={false}
              noSeparator={false}
            />
            <p style={{ color: "red" }}>{formErrors?.instructor}</p>
          </div>
        </div>
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="dropdown mb-1">
            <label className="col-form-label">
              Co-ordinator <span style={{ color: "red" }}>*</span>
            </label>
            <SelectForm
              placeholder={"Co-ordinator"}
              isDisabled={isDisable ? true : disableAttribute?.co_ordinator}
              textArea={false}
              value={formData?.co_ordinator || null}
              onChange={(val: any) => {
                handleOnChange(val, "co_ordinator");
              }}
              options={options?.co_ordinator || []}
              isMulti={true}
              noIndicator={false}
              noSeparator={false}
            />
            <p style={{ color: "red" }}>{formErrors?.co_ordinator}</p>
          </div>
        </div>
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="row">
            <div
              className={
                formData.meetingId
                  ? "col-lg-10 col-sm-10 col-xs-10"
                  : "col-lg-12 col-sm-12 col-xs-12"
              }
            >
              <div className="mb-1">
                <label className="col-form-label">
                  Calendar Blocking{" "}
                  {/* {!disableMeetInSession && <span style={{ color: "red" }}>*</span>} */}
                </label>
                <SelectForm
                  isClearable
                  isSearchable
                  options={options.calendarBlocking}
                  placeholder={"Select"}
                  isDisabled={isDisable ? true : disableMeetInSession}
                  value={formData?.calendarBlocking}
                  onChange={(val: any) => {
                    if (
                      val &&
                      (val.code.toLowerCase() === "online" ||
                        val.code.toLowerCase() === "offline")
                    ) {
                      setHideAdditionalMeetControls(false);
                    } else {
                      setHideAdditionalMeetControls(true);
                      setFormData((prev) => ({
                        ...prev,
                        guests: null,
                        meetingDescription: "",
                      }));
                    }
                    handleOnChange(val, "calendarBlocking");
                  }}
                  isMulti={false}
                  noIndicator={false}
                  noSeparator={false}
                />
                <p style={{ color: "red" }}>{formErrors?.calendarBlocking}</p>
              </div>
            </div>
            {formData.meetingId && (
              <div className={"col-lg-2 col-sm-2 col-xs-2"}>
                <label className="col-form-label"></label>{" "}
                <div className="mt-3">
                  <Tooltip
                    title={
                      formData.meetingId != -1
                        ? "Cancel meeting"
                        : "Re-schedule meeting"
                    }
                  >
                    <button
                      className="pngIcon"
                      disabled={formData.meetingAction}
                      onClick={() => {
                        meetingAction();
                      }}
                    >
                      {formData.meetingId != -1 ? (
                        // <i className="fa-solid fa-times"></i>
                        <img className="name_icon" src={calenderClose}></img>
                      ) : (
                        // <i className="fa-solid fa-refresh"></i>
                        <img className="name_icon" src={calenderRefresh}></img>
                      )}
                    </button>
                  </Tooltip>
                </div>
              </div>
            )}
          </div>
        </div>

        {!hideAdditionalControls && (
          <>
            <div className="col-lg-3 col-sm-3 col-xs-4">
              <div className="dropdown mb-1">
                <label className="col-form-label">Guests</label>
                <SelectForm
                  isClearable
                  isSearchable
                  async
                  options={(searchString, cb) => {
                    handleSearchForAsyncDropdown(
                      searchString,
                      "co_ordinator",
                      cb
                    );
                  }}
                  placeholder={"Type to search"}
                  isDisabled={isDisable ? true : disableMeetInSession}
                  value={formData?.guests}
                  onChange={(val: any) => {
                    handleOnChange(val, "guests");
                  }}
                  isMulti={true}
                  noIndicator={false}
                  noSeparator={false}
                />
                <p style={{ color: "red" }}>{formErrors?.guests}</p>

                <div className="dropdown-content">
                  {formData?.guests?.length > 1 && (
                    <>
                      {formData?.guests?.map((i) => (
                        <p>{i?.label || ""}</p>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-3 col-xs-4">
              <div className="mb-1">
                <label className="col-form-label">
                  Meeting Description
                  {/* <span style={{ color: "red" }}>*</span> */}
                </label>
                <InputForm
                  className="form-control"
                  placeholder={"Meeting Description"}
                  isDisabled={isDisable ? true : disableMeetInSession}
                  textArea={true}
                  rows={4}
                  value={formData?.meetingDescription}
                  onChange={(val: any) => {
                    let value = val.target.value;
                    handleOnChange(value, "meetingDescription");
                  }}
                />
                <p style={{ color: "red" }}>{formErrors.meetingDescription}</p>
              </div>
            </div>
          </>
        )}
      </div>
    </AccordianCustom>
  );
};

export default SessionComponent;
