import React, { useState, useEffect, useContext } from "react";
import SelectForm from "../../Components/SelectForm/SelectForm";
import InputForm from "../../Components/InputForm/InputForm";
import { LoaderContext, getContext } from "../../Helpers/Context/Context";
import { useNavigate, useLocation } from "react-router-dom";
import {
  GetProgramCategoryData,
  InsertUpdateProgramData,
  GetProgramDetails,
  SetThumbnailProgramDetails,
  DeleteThumbnailProgramDetails,
  getLMSDomainDropDownData,
  getUserAndRoleWiseAccess,
  GetCommonDropdownForScheduling,
  GetProgramCourseDataList,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import { APICall } from "../../Helpers/APICalls";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import notify from "../../Helpers/ToastNotification";
import defaultProfileImgPic from "../../Assets/Images/thumbnail.jpg";
import { Modal } from "react-bootstrap";
import RTE from "../../Components/RTE/RTE";
import "./program.css";
import DateTimeForm from "../../Components/DateTimeForm/DateTimeForm";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import ImageColumnComponent from "../../Components/LMS/ImageColumnComponent/ImageColumnComponent";
import { Tooltip } from "@mui/material";
const Program = () => {
  const [ProgramCode, setProgramCode] = useState("");
  const [ProgramTitle, setProgramTitle] = useState("");
  const [description, setdescription] = useState("");
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [CategoryOption, setCategoryOption] = useState<any>([]);
  const [CategoryVal, setCategoryVal] = useState<any>([]);
  const { state } = useLocation();
  const context = getContext();
  const [isActive, setIsActive] = useState(true);
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [profileImg, setProfileImg] = useState(null);
  const [profileImgToUpload, setProfileImgToUpload] = useState<any>(null);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [TId, setTId] = useState(0);
  const [isDisable, setIsDisable] = useState(state?.isDisable);

  const [domain, setDomain] = useState(null);
  const [domainOptions, setDomainOptions] = useState([]);
  const [notification, setNotification] = useState(false);
  const [toEmail, setToEmail] = useState("");
  const [effectiveDate, setEffectiveDate] = useState(new Date());
  const [remarks, setRemarks] = useState("");

  const [ccuser, setCCuser] = useState<any>(null);

  const [dashboard, setdashboard] = useState([]);
  const [dashboardPageSize, setDashboardPageSize] = useState(10);
  const [dashboardStart, setDashboardStart] = useState(0);
  const [dashboardSortColumn, setDashboardSortColumn] = useState<any>("");
  const [dashboardSortDirection, setDashboardSortDirection] = useState("");
  const [dashboardCount, setDashboardCount] = useState(0);

  const [isLaunchHub, setisLaunchHub] = useState(
    state && state?.isLaunchHub ? true : false
  );

  type FormErrors = {
    ProgramCode?: string;
    ProgramTitle?: string;
    CategoryVal?: string;
    Description?: string;
    Image?: string;
    Domain?: string;
    // Add more fields if necessary
  };

  useEffect(() => {
    (async () => {
      await getLMSDomainDropDownDataApiCall();
      await GetRoleOptions();
      await getUserAndRoleWiseAccessApiCall();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      // if (domainOptions.length > 0) {
      await LoadEditData();
      // }
    })();
  }, [domainOptions]);

  const GetRoleOptions = async () => {
    const { data } = await APICall(GetProgramCategoryData, "POST", {});

    if (data !== null && data.length > 0) {
      let role = data.map((element) => ({
        value: element.value,
        label: element.label,
      }));
      setCategoryOption(role);
    }
  };

  const getLMSDomainDropDownDataApiCall = async () => {
    const response = await APICall(getLMSDomainDropDownData, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      GetAll: state && state?.id > 0 ? true : false,
    });

    if (response?.status === 0 && response?.data.length > 0) {
      setDomainOptions(response?.data);
    }
  };

  interface userAndRoleWiseAccess {
    isView?: boolean;
    isEdit?: boolean;
    isDelete?: boolean;
  }

  const [userAndRoleWiseData, setUserAndRoleWiseData] =
    useState<userAndRoleWiseAccess>({});

  const getUserAndRoleWiseAccessApiCall = async () => {
    const response = await APICall(getUserAndRoleWiseAccess, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      InterfaceName: "Program_Form",
    });

    if (response?.status === 0) {
      setUserAndRoleWiseData(response?.data);
    } else {
      notify(response?.status, response?.message);
    }
  };

  useEffect(() => {
    (async () => {
      await GetcourseDashboard();
    })();
  }, [
    dashboardPageSize,
    dashboardStart,
    dashboardSortDirection,
    dashboardSortColumn,
  ]);

  const GetcourseDashboard = async () => {
    showLoader();
    if (state != null && state.id != 0) {
      let requestParams = {
        PageSize: dashboardPageSize,
        Page: dashboardStart,
        SortOrder: dashboardSortDirection,
        SortColumn: dashboardSortColumn,
        ProgramId: state?.id,
        CreatedBy: userDetails?.Id,
        LaunchId: state?.launchHubId,
      };

      const { data } = await APICall(
        GetProgramCourseDataList,
        "POST",
        requestParams
      );
      if (data != null) {
        setdashboard(data);
        setDashboardCount(data[0]?.count);
        setDashboardPageSize(data[0]?.count);
        hideLoader();
      } else {
        setdashboard([]);
      }
    }
    hideLoader();
  };

  const dashboardOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: dashboardCount,
    rowsPerPage: dashboardPageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    //standard | vertical | simple
    onChangeRowsPerPage: (num) => {
      //   setLimit(num);
      //   setNxtPgInfo("");
      //   setPrevPgInfo("");
      //   setIsPrevOrNext("");
    },
    onSearchChange: (searchText) => {
      if (searchText !== null) {
        //setDashboardSearchText(searchText);
      } else {
        //setDashboardSearchText("");
      }
    },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setDashboardSortColumn(sortColumn);
        await setDashboardSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setDashboardSortColumn(sortColumn);
        await setDashboardSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      setDashboardStart(page * dashboardPageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const gridColumns = [
    {
      name: "thumbnailUrl",
      label: "Thumbnail",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        customBodyRender: (value, tableMeta) => {
          return <ImageColumnComponent link={value} />;
        },
      },
    },

    {
      name: "courseCode",
      label: "Course ID",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "courseTitle",
      label: "Course Title",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "learningObjective",
      label: "Course Objective",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <span
                dangerouslySetInnerHTML={{
                  __html: value,
                }}
              ></span>
            </>
          );
        },
      },
    },
    {
      name: "launchStatus",
      label: "Launch",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "sequence",
      label: "Sequence",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta) => {
          let launchId = tableMeta.tableData[tableMeta.rowIndex].launchId;
          let courseId = tableMeta.tableData[tableMeta.rowIndex].courseId;

          return (
            <>
              {userAndRoleWiseData?.isView && (
                <>
                  <Tooltip title="View">
                    <a
                      className="mr-2"
                      style={{ fontSize: "15px" }}
                      onClick={(e) => {
                        e.preventDefault();

                        navigate("/Courses", {
                          state: {
                            courseId: courseId,
                            launchHubId: launchId,
                            isDisable: true,
                          },
                        });
                      }}
                    >
                      <i className="fas fa-eye"></i>
                    </a>
                  </Tooltip>
                </>
              )}
            </>
          );
        },
      },
    },
    // {
    //   name: "",
    //   label: "Action",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     display: true,
    //     setCellProps: () => ({
    //       style: { textAlign: "center" },
    //     }),
    //     setCellHeaderProps: () => ({
    //       style: { textAlign: "center" },
    //     }),
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       let launchId = tableMeta.tableData[tableMeta.rowIndex].launchId;
    //       let courseId = tableMeta.tableData[tableMeta.rowIndex].courseId;

    //       debugger;

    //       return (
    //         <>
    //           {/* {userAndRoleWiseData?.isView && ( */}
    // <Tooltip title="View">
    //   <a
    //     className="mr-2"
    //     style={{ fontSize: "15px" }}
    //     onClick={(e) => {
    //       e.preventDefault();

    //       navigate("/Courses", {
    //         state: {
    //           courseId: courseId,
    //           launchHubId: launchId,
    //           isDisable: true,
    //         },
    //       });
    //     }}
    //   >
    //     <i className="fas fa-eye"></i>
    //   </a>
    // </Tooltip>
    //           {/* )} */}
    //         </>
    //       );
    //       // {
    //       //   userAndRoleWiseData?.isView && (
    //       //     <>
    //       //       <Tooltip title="View">
    //       //         <a
    //       //           className="mr-2"
    //       //           style={{ fontSize: "15px" }}
    //       //           onClick={(e) => {
    //       //             e.preventDefault();

    //       //             navigate("/Courses", {
    //       //               state: {
    //       //                 courseId: courseId,
    //       //                 launchHubId: launchId,
    //       //                 isDisable: true,
    //       //               },
    //       //             });
    //       //           }}
    //       //         >
    //       //           <i className="fas fa-eye"></i>
    //       //         </a>
    //       //       </Tooltip>
    //       //     </>
    //       //   );
    //       // }
    //     },
    //   },
    // },
  ];

  const fileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleImageSelect = async (e) => {
    const file = e.target.files[0];
    let fileBase64: { Name: string; Data: string } = null;
    const base64 = (await fileToBase64(file)) as string;
    const base64WithoutPrefix = base64.split(",")[1];
    fileBase64 = { Name: file.name, Data: base64WithoutPrefix };

    const validExtensions = ["png", "jpg", "jpeg"];
    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();
      const fileSize = file.size;
      const maxFileSizeBytes = 5 * 1024 * 1024;

      // Check if the file extension is allowed
      if (
        validExtensions.includes(fileExtension) &&
        fileSize <= maxFileSizeBytes
      ) {
        // file to upload
        setProfileImgToUpload(fileBase64);
        setProfileImg(`data:image;base64,${fileBase64.Data}`);
      } else {
        notify(1, "Only file with png,jpg,jpeg allowed and size under 5MB");
        e.target.value = "";
        setProfileImgToUpload(null);
      }
    }
  };

  const handleImageUpload = async (id: any) => {
    if (profileImgToUpload) {
      showLoader();
      let reqObj = {
        file: {
          // AC: "ProfilePic",
          Name: profileImgToUpload.Name,
          Data: profileImgToUpload.Data,
        },
        PId: id,
        SectionName: "Program",
        ///ModuleName: "EmployeeCentral",
        //TabName: "Personal",
        //ModuleId: 1,
        UserId: userDetails?.Id,
        // UserId: proxyUserDetails?.EmployeeId
        //   ? proxyUserDetails?.EmployeeId
        //   : userDetails.UserId,
        ProxyUserId: proxyUserDetails === null ? null : proxyUserDetails?.value,
        Id: id,
      };

      try {
        const response = await APICall(
          SetThumbnailProgramDetails,
          "POST",
          reqObj
        );
        if (response && response.data) {
          setProfileImg(`data:image;base64,${response.data.base64}`);
          setShowProfileModal(false);
          hideLoader();
        } else {
          console.error("Image upload failed");
          hideLoader();
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        hideLoader();
      }
    } else {
      notify(1, "Please Select Image to Upload.");
    }
  };

  const handleDeleteImage = () => {
    setImageUrl(""); // Clear the image preview
    setImageFile(null); // Clear the file reference
    // Also clear the file input
  };

  //// map the data onload
  const LoadEditData = async () => {
    showLoader();
    if (state != null && state?.id != 0) {
      const { data } = await APICall(GetProgramDetails, "POST", {
        Id: state.id,
        LaunchId: state?.launchHubId,
      });
      if (data !== null) {
        setCategoryVal({
          label: data.categoryId,
          value: data.categoryCode,
          //  value: data.categoryId,
        });
        setProgramTitle(data?.programTitle);
        setProgramCode(data?.programCode);
        setdescription(data?.description);

        setToEmail(data?.toUser);

        const domainIds =
          data?.domainIds !== "" ? data?.domainIds?.split(",") : null;
        const domainArray = [];

        if (domainIds) {
          domainIds?.map((value) => {
            const domainData = domainOptions?.find(
              (event) => event?.value === Number(value)
            );
            if (domainData !== undefined) {
              domainArray.push(domainData);
            }
          });
        }

        setDomain(domainArray.length > 0 ? domainArray : null);

        if (data.thumbnailDataObject != null) {
          setProfileImgToUpload({
            Name: "Edit.jpg",
            Data: data.thumbnailDataObject,
          });
          setProfileImg(`data:image;base64,${data.thumbnailDataObject}`);
        } else {
          setProfileImg(null);
        }

        // setProfileImg(`data:image;base64,${getPhoto.data}`);
      }
    }
    hideLoader();
  };

  const validateForm = () => {
    let errors: FormErrors = {};
    let isValid = true;

    if (!ProgramTitle.trim()) {
      errors.ProgramTitle = "Journey Title is required";
      isValid = false;
    }

    if (!CategoryVal || !CategoryVal.value) {
      errors.CategoryVal = "Category is required";
      isValid = false;
    }

    // if (domain === null || domain === "") {
    //   errors.Domain = "Domain is required";
    //   isValid = false;
    // }

    if (!description.trim()) {
      errors.Description = "Description is required";
      isValid = false;
    } else if (description.length > 2500) {
      errors.Description = "Description is less than 2500 characters";
      isValid = false;
    }

    // Add additional validations as needed

    setFormErrors(errors);
    return isValid;
  };

  const deleteProfileImage = async (id: any) => {
    if (profileImg != null) {
      showLoader();
      const doc = await APICall(DeleteThumbnailProgramDetails, "POST", {
        id,
        CreatedBy: userDetails?.Id,
      });
      if (doc.message === "Ok") {
        setProfileImg(null);
        setShowProfileModal(false);
        setProfileImgToUpload(null);
        hideLoader();
      } else {
        notify(1, "File not Deleted, Something Went Wrong.");
        hideLoader();
      }
    }
  };

  const requestParamsFun = () => {
    let requestParams = {
      Id:
        state === null || state?.id === null || state?.id === undefined
          ? 0
          : state?.id,
      ProgramCode: "",
      ThumbnailUrl: null,

      ProgramTitle: ProgramTitle,
      CategoryCode: CategoryVal.value,
      Description: description,
      DomainIds:
        domain !== null ? domain?.map((event) => event.value).join(",") : null,
      IsActive: isActive,
      ToUser: toEmail || null,
      CCUserIds: ccuser?.map((event) => event.value).join(",") || null,
      EffectiveDate: effectiveDate || null,
      Remarks: remarks || null,
      CreatedBy: userDetails?.Id,
    };

    return requestParams;
  };

  const onSubmitData = async () => {
    if (!validateForm()) {
      hideLoader();
      return; // Stop the submission if there are validation errors
    }

    const requestParams = requestParamsFun();

    if (requestParams?.Id === 0) {
      InsertUpdateData();
    } else {
      setNotification(true);
    }
  };

  const InsertUpdateDataOnNotification = async () => {
    debugger;
    if (!validateForm()) {
      hideLoader();
      setNotification(false);
      return; // Stop the submission if there are validation errors
    }

    InsertUpdateData();
  };

  const InsertUpdateData = async () => {
    debugger;
    showLoader();
    const requestParams = requestParamsFun();

    const data = await APICall(InsertUpdateProgramData, "POST", requestParams);
    if (data.id != 0 && profileImg != null) {
      await handleImageUpload(data.id);
    }

    if (data.status === 0) {
      navigate("/journeyDashboard");
      notify(0, data.message);
    } else {
      notify(1, data.message);
    }

    hideLoader();
  };

  async function handleSearchForAsyncDropdown(event, code, callback) {
    try {
      if (event?.length >= 3) {
        const data = await fetchDropdown(event, code);
        callback(data);
      } else {
        callback([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchDropdown(searchText = "", code = "", value = null) {
    try {
      const response = await APICall(
        GetCommonDropdownForScheduling,
        "POST",
        {
          SearchText: searchText,
          DropdownValues: value,
          Code: code,
          UserId: userDetails?.Id,
          RoleId: currentRoleId?.value,
        },
        true
      );

      if (response?.status === 0) {
        if (response?.data) {
          return response?.data;
        } else {
          return [];
        }
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  const onResetData = () => {
    setProgramCode("");
    setProgramTitle("");
    setCategoryVal([]);
    setdescription("");
    setProfileImgToUpload(null);
    setProfileImg(null);

    setFormErrors({});
  };

  return (
    <>
      <div className="breadcrumb modified-bredcrumb">
        <span>
          <ul>
            <li>
              <a href="/home">Home</a>
            </li>
            <li>
              {" "}
              <a href="/journeyDashboard">Journey</a>
            </li>
            <li>Manage Journey</li>
          </ul>
        </span>
        <button
          onClick={() => {
            if (state && state?.from !== undefined) {
              navigate(state?.from);
            } else {
              navigate("/journeyDashboard");
            }
          }}
          disabled={false}
          className="btn btn-secondary float-right"
        >
          <i className="fa-solid fa-arrow-left"></i> Back
        </button>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 d-flex justify-content-center align-items-start">
            <div className="card Prof_img">
              <img
                src={profileImg != null ? profileImg : defaultProfileImgPic}
                className="card-img-top"
                alt="Thumbnail Image"
              />
              <a
                className="edit-profile"
                onClick={() => setShowProfileModal(true)}
              >
                <i className="fas fa-edit"></i>
              </a>
            </div>
          </div>

          <div className="col-md-9">
            <div className="row">
              <div className="col-md-4">
                <label className="col-form-label">Journey ID</label>
                <sup>*</sup>
                <InputForm
                  className="form-control"
                  placeholder="Journey ID"
                  isDisabled={true}
                  onChange={(e) => setProgramCode(e.target.value)}
                  textArea={false}
                  value={ProgramCode}
                  maxLength="255"
                />
                {formErrors.ProgramCode && (
                  <p style={{ color: "red" }}>{formErrors.ProgramCode}</p>
                )}
              </div>
              <div className="col-md-4">
                <label className="col-form-label">Journey Title</label>
                <sup>*</sup>
                <InputForm
                  className="form-control"
                  placeholder="Journey Title"
                  isDisabled={isDisable}
                  onChange={(e) => setProgramTitle(e.target.value)}
                  textArea={false}
                  value={ProgramTitle}
                  maxLength="255"
                />
                {formErrors.ProgramTitle && (
                  <p style={{ color: "red" }}>{formErrors.ProgramTitle}</p>
                )}
              </div>
              <div className="col-md-4">
                <label className="col-form-label">Category</label>
                <sup>*</sup>
                <SelectForm
                  isClearable
                  options={CategoryOption}
                  placeholder={"Select Category"}
                  isDisabled={isDisable}
                  onChange={(event) => {
                    setCategoryVal(event);
                  }}
                  isMulti={false}
                  noIndicator={false}
                  noSeparator={false}
                  value={CategoryVal}
                />
                {formErrors.CategoryVal && (
                  <p style={{ color: "red" }}>{formErrors.CategoryVal}</p>
                )}
              </div>
              <div className="col-md-8">
                <label className="col-form-label">Description</label>
                <sup>*</sup>

                <RTE
                  content={description}
                  disabled={isDisable}
                  onblur={(e) => setdescription(e)}
                />

                {formErrors.Description && (
                  <p style={{ color: "red" }}>{formErrors.Description}</p>
                )}
              </div>
              <div className="dropdown col-md-4">
                <label className="col-form-label">Domains</label>
                <SelectForm
                  isClearable
                  options={domainOptions}
                  isOptionDisabled={(option) => !option?.isEnabled}
                  placeholder={"Select Domains"}
                  isDisabled={isDisable}
                  onChange={(event) => {
                    setDomain(event);
                  }}
                  isMulti={true}
                  noIndicator={false}
                  noSeparator={false}
                  value={domain}
                />
                {formErrors.Domain && (
                  <p style={{ color: "red" }}>{formErrors.Domain}</p>
                )}
              </div>
            </div>
            {!isDisable && (
              <div className="SectionSubmit mb-4 clearfix">
                <button
                  onClick={() => {
                    onResetData();
                  }}
                  disabled={false}
                  className="btn btn-secondary float-right ml-2"
                >
                  <i className="fa-solid fa-refresh"></i> Reset
                </button>

                <button
                  onClick={() => {
                    onSubmitData();
                  }}
                  disabled={false}
                  className="btn btn-filter-submit float-right ml-2"
                >
                  <i className="fa-solid fa-check"></i> Save
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {isLaunchHub && (
        <>
          <div className="mb-3 pt-3">
            <DynamicGrid
              data={dashboard}
              columns={gridColumns}
              options={dashboardOptions}
            />
          </div>
        </>
      )}

      <Modal
        show={showProfileModal}
        onHide={() => setShowProfileModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">Upload Journey Picture!</div>
        </div>
        <Modal.Body>
          <>
            <input
              type="file"
              multiple={false}
              accept={".png,.jpg,.jpeg"}
              onChange={handleImageSelect}
            />
            <div>
              <b>Acceptable image formats:</b> .png, .jpg and .jpeg{" "}
            </div>
            <div>
              {" "}
              <b>Maximum File Size:</b> 5MB{" "}
            </div>
            <div>
              <b>Resolution:</b> 3245 X 3245{" "}
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          {profileImg != null && (
            <>
              {state != null && state.id != 0 ? (
                // Add any additional JSX you want to render when state is not null and state.id is not 0
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: 5 }}
                  onClick={() => {
                    deleteProfileImage(state.id);
                  }}
                >
                  Remove
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: 5 }}
                  onClick={() => {
                    setShowProfileModal(false);
                    setProfileImg(null);
                    setProfileImgToUpload(null);
                  }}
                >
                  Remove
                </button>
              )}
            </>
          )}

          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => setShowProfileModal(false)}
          >
            Upload
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowProfileModal(false);
              setProfileImgToUpload(null);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      {/* Notification */}

      <Modal
        show={notification}
        onHide={() => setNotification(false)}
        backdrop="static"
        keyboard={false}
        size="xl"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">
            Notification and mail will be sent to the following users
          </div>
        </div>
        <Modal.Body>
          <>
            <div className="row mx-auto ">
              <div className="col-lg-6 col-sm-6 col-xs-6">
                <div className="mb-1">
                  <label className="col-form-label">
                    To <sup>*</sup>
                  </label>
                  <InputForm
                    value={toEmail}
                    placeholder={"Enter To Email"}
                    isDisabled={true}
                    textArea={false}
                    onChange={(e) => {
                      setToEmail(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="col-lg-6 col-sm-6 col-xs-6">
                <div className="dropdown mb-1">
                  <label className="col-form-label">CC</label>
                  <SelectForm
                    isClearable
                    async
                    placeholder={"Search CC User"}
                    isDisabled={false}
                    options={(searchString, cb) => {
                      handleSearchForAsyncDropdown(
                        searchString,
                        "co_ordinator",
                        cb
                      );
                    }}
                    onChange={(val: any) => {
                      setCCuser(val);
                    }}
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                    value={ccuser}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 col-xs-6">
                <div className="mb-1">
                  <label className="col-form-label">
                    Effective Date <sup>*</sup>
                  </label>
                  <DateTimeForm
                    value={effectiveDate}
                    placeholder={"Effective Date"}
                    onChange={(event) => {
                      setEffectiveDate(event);
                    }}
                    isDisabled={true}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 col-xs-6">
                <div className="mb-1">
                  <label className="col-form-label">Remarks</label>
                  <InputForm
                    value={remarks}
                    placeholder={"Enter the remarks"}
                    isDisabled={false}
                    textArea={true}
                    onChange={(e) => {
                      setRemarks(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => {
              InsertUpdateDataOnNotification();
            }}
          >
            Execute
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setNotification(false);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Program;
