import React, { memo } from "react";
import { Card } from "react-bootstrap";
import AssessmentAnswer from "./AssessmentAnswer";

const AssessmentQuestions = memo(
  ({ selectedQuestion, getUserAnswer, canRevealAnswer }: any) => {
    const CorrectAnswer = () => {
      if (!canRevealAnswer) {
        return null;
      }
      return (
        <div className="row">
          <div className="col-lg-2">Correct Answer</div>
          <div className="col-lg-10">{selectedQuestion.ogCorrectAnswer}</div>
        </div>
      );
    };

    return (
      <>
        <div className="row my-2">
          <div className="mx-2 col-lg-12 p-2 rounded bg-light text-center d-flex justify-content-between">
            <b>{selectedQuestion.questionType} </b>
            <b>Weightage : {selectedQuestion.marks} Marks</b>
          </div>
          <div className="col-lg-12 mt-3">
            <Card>
              <Card.Header className="bg_dark_blue text-white learningcardHead">
                Q.{selectedQuestion.questionSequence}{" "}
                {selectedQuestion.question}
              </Card.Header>
              <Card.Body>
                <AssessmentAnswer
                  selectedQuestion={selectedQuestion}
                  answers={selectedQuestion.answers}
                  getUserAnswer={getUserAnswer}
                  CorrectAnswer={CorrectAnswer}
                />
              </Card.Body>
            </Card>
          </div>
        </div>
      </>
    );
  }
);

export default AssessmentQuestions;
