import React, { useContext, useEffect, useState } from "react";
import { LoaderContext } from "../../Helpers/Context/Context";
import { useNavigate } from "react-router-dom";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import {
  getDropdowns,
  getRecruiterDashboardData,
} from "../../Helpers/APIEndPoints/EndPoints";
import { APICall } from "../../Helpers/APICalls";
import { Collapse } from "react-bootstrap";
import SelectForm from "../../Components/SelectForm/SelectForm";
import InputForm from "../../Components/InputForm/InputForm";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { Tooltip } from "@mui/material";

const RecruiterDashboard = () => {
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [gridData, setGridData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [PageSize, setPageSize] = useState(10);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();

  const [legalEntity, setLegalEntity] = useState<any>([]);
  const [mrfLocation, setMrfLocation] = useState<any>([]);
  const [commonDdValue, setCommonDdValue] = useState<any>({});
  const [ddValue, setDdValue] = useState({});

  const [reset, setReset] = useState(false);

  let { userDetails, currentRoleId }: any = useUserContext();

  const getDropdownsData = async (AC, cb) => {
    await showLoader();
    const dropdownData = await APICall(getDropdowns, "POST", {
      AC,
      PID: null,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });
    if (
      dropdownData.data !== null &&
      dropdownData.data !== undefined &&
      dropdownData.data.length > 0
    ) {
      setDdValue((prev) => ({ ...prev, [AC]: dropdownData.data }));
    } else {
      setDdValue((prev) => ({ ...prev, [AC]: [] }));
    }
    //setCommonDdValue((prev)=>({...prev, [AC]:[]}));
    await hideLoader();
  };

  useEffect(() => {
    getInterfaceDetails();
  }, []);

  const getInterfaceDetails = async () => {
    showLoader();

    const gridDataResponse = await APICall(
      "/api/Interface/GetInterfaceDetailsForGrid",
      "POST",
      {
        MN: "Recruiter",
        IN: "Recruiter_Dashboard",
        TN: "Recruiter_Dashboard",
      }
    );

    if (gridDataResponse.status === 0) {
      if (gridDataResponse?.data?.d?.fa !== null) {
        for await (const cFilter of gridDataResponse?.data?.d?.fa) {
          if (cFilter.filt === "multidropdown") {
            await getDropdownsData(cFilter.filAN, null);
          }
        }
      }
      if (gridDataResponse?.data?.d?.afa !== null) {
        for await (const cFilter of gridDataResponse?.data?.d?.afa) {
          if (cFilter.filt === "multidropdown") {
            await getDropdownsData(cFilter.filAN, null);
          }
        }
      }
      setGridData(gridDataResponse);
      await getGridStructureTwo();
    }
    hideLoader();
  };

  useEffect(() => {
    if (gridData?.data?.d?.s[0]?.columnData.length > 0) {
      getGridStructureTwo();
    }
  }, [page, searchText, reset]);

  const getGridStructureTwo = async () => {
    showLoader();

    const GetDashboardData = await APICall(getRecruiterDashboardData, "POST", {
      DashboardName: "RecruiterDashboard",
      PageNumber: page,
      PageSize: PageSize,
      SearchText: searchText,
      SortColumn: "",
      SortOrder: "",
      UserID: userDetails.Id,
      RoleId: currentRoleId?.value,
      MRORLEGENTY: legalEntity.map((s) => s.value).join(","),
      MRORLOC: mrfLocation.map((s) => s.value).join(","),
    });

    if (GetDashboardData.data && GetDashboardData.data.length > 0) {
      const formattedData = GetDashboardData.data;

      setCount(formattedData[0].count);
      setRowData(formattedData);
    } else {
      setRowData([]);
      setCount(0);
    }

    hideLoader();
  };

  const edit = (value, tableMeta, url) => {
    const MrfId = tableMeta.rowData[0];
    const JobId = tableMeta.rowData[1];
    console.log(tableMeta);
    navigate("/recruiterCandidate", { state: { TId: MrfId, JobId } }); //-----------------------------------------------------------CHANGE FOR FORM
  };

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: PageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",
    onChangePage: async (page) => {
      setPage(page * PageSize);
    },
  };

  const columnsWithCustomRender = gridData?.data?.d?.s[0]?.columnData.map(
    (column) => {
      if (column.name === "action") {
        return {
          ...column,
          options: {
            ...column.options,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <>
                  <div className="centerStyles">
                    <Tooltip title="Edit">
                      <i
                        style={{ marginRight: "0px", cursor: "pointer" }}
                        onClick={() => {
                          // navigate("/gridEdit")
                          edit(value, tableMeta, column.url);
                        }}
                        className="fas fa-edit"
                      ></i>
                    </Tooltip>
                  </div>
                </>
              );
            },
          },
        };
      }
      return column;
    }
  );

  const onFilterChange = (event, type) => {
    //;
    if (type === "MRORLEGENTY") {
      setLegalEntity(event);
    }

    if (type === "MRORLOC") {
      setMrfLocation(event);
    }
  };
  const onSearchFilter = (event, type) => {
    if (type === "Search MRF") {
      setSearchText(event.target.value);
    }
  };
  const onSubmitFilter = () => {
    if (gridData?.data?.d?.s[0]?.columnData.length > 0) {
      getGridStructureTwo();
    }
  };

  const onClickReset = () => {
    setLegalEntity([]);

    setMrfLocation([]);

    setSearchText("");

    setReset(!reset);
  };

  return (
    <div className="container-fluid">
      <div className="d-flex align-items-center justify-content-end mb-3"></div>
      <div className="grid-wrapper grid_mob">
        <div className="row mx-auto filtBox">
          {gridData &&
            gridData?.data?.d?.fa.map((cFilter, index) => (
              <React.Fragment key={index}>
                {cFilter.filt === "multidropdown" ? (
                  <>
                    <div className="col-lg-3 col-md-4 col-sm-12">
                      <div className="form-group filtInp">
                        <span
                          className="mr-2 text-white"
                          style={{ float: "left" }}
                        >
                          <label className="col-form-label">
                            {cFilter.filn}
                          </label>
                        </span>
                        <SelectForm
                          isClearable
                          options={ddValue[cFilter.filAN]}
                          placeholder={cFilter.filn}
                          isDisabled={false}
                          onChange={(event) => {
                            onFilterChange(event, cFilter.filAN);
                          }}
                          value={
                            cFilter.filAN === "MRORLEGENTY"
                              ? legalEntity
                              : cFilter.filAN === "MRORLOC"
                              ? mrfLocation
                              : null
                          }
                          isMulti={true}
                          noIndicator={false}
                          noSeparator={false}
                        />
                      </div>
                    </div>
                  </>
                ) : cFilter.filt === "text" ? (
                  <div className="col-lg-3 col-md-3 col-sm-12">
                    <div className="form-group filtInp">
                      <InputForm
                        value={cFilter.filn === "Search MRF" ? searchText : ""}
                        placeholder={cFilter.filn}
                        isDisabled={false}
                        textArea={false}
                        onChange={(e) => {
                          onSearchFilter(e, cFilter.filn);
                        }}
                      />
                    </div>
                  </div>
                ) : null}
              </React.Fragment>
            ))}
          <div className="col-lg-1 col-md-1 col-sm-12 filtrt">
            <ul className=" filter-icon-wel">
              <li>
                <a
                  // href=""
                  onClick={() => setOpen(!open)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                  className="filter-btn"
                >
                  <i className="fa fa-filter"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="col-lg-12 col-md-10 col-sm-12 "></div>

      <>
        <Collapse in={open}>
          <div id="example-collapse-text grid-wrapper">
            <div className="row mx-auto myInlineStyle">
              {gridData &&
                gridData?.data?.d?.afa?.map((cFilter, index) => (
                  <>
                    {cFilter.filt === "multidropdown" ? (
                      <>
                        <div className="col-lg-3 col-md-4 col-sm-12">
                          <div className="form-group filtInp">
                            <span
                              className="mr-2 text-white"
                              style={{ float: "left" }}
                            >
                              <label className="col-form-label">
                                {cFilter.filn}
                              </label>
                            </span>
                            <SelectForm
                              isClearable
                              options={ddValue[cFilter.filAN]}
                              placeholder={cFilter.filn}
                              isDisabled={false}
                              onChange={(event) => {
                                onFilterChange(event, cFilter.filAN);
                              }}
                              isMulti={true}
                              noIndicator={false}
                              noSeparator={false}
                              value={
                                cFilter.filAN === "MRORLEGENTY"
                                  ? legalEntity
                                  : cFilter.filAN === "MRORLOC"
                                  ? mrfLocation
                                  : null
                              }
                            />
                          </div>
                        </div>
                      </>
                    ) : cFilter.filt === "text" ? (
                      <div className="col-lg-3 col-md-3 col-sm-12">
                        <div className="form-group filtInp">
                          <InputForm
                            value={
                              cFilter.filn === "Search MRF" ? searchText : ""
                            }
                            placeholder={cFilter.filn}
                            isDisabled={false}
                            textArea={false}
                            onChange={(e) => {
                              onSearchFilter(e, cFilter.filn);
                            }}
                          />
                        </div>
                      </div>
                    ) : null}
                  </>
                ))}
              <div className="col-lg-12 d-flex mt-2 justify-content-end">
                <button
                  className="btn btn-secondary mr-2"
                  onClick={onClickReset}
                >
                  <i className="fa fa-undo" aria-hidden="true"></i> Reset
                </button>
                <button
                  className="btn btn-filter-submit"
                  onClick={onSubmitFilter}
                >
                  <i className="fa fa-check" aria-hidden="true"></i> Submit{" "}
                </button>
              </div>
            </div>
          </div>
        </Collapse>
      </>

      <div className="mb-3 pt-3">
        <DynamicGrid
          data={rowData}
          columns={columnsWithCustomRender}
          options={options}
        />
      </div>
    </div>
  );
};

export default RecruiterDashboard;
