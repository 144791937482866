import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import InputForm from "../../Components/InputForm/InputForm";
import SelectForm from "../../Components/SelectForm/SelectForm";

const MyVerticallyCenteredModal = ({
  show,
  onHide,
  roleName,
  systemName,
  roleType,
  isActive,
  isProxyUserRole,
  setRoleName,
  setSystemName,
  setRoleType,
  setIsActive,
  setIsProxyUserRole,
  handleRoleSubmit,
  roleNameError,
  systemNameError,
  roleTypeError,
  setRoleNameError,
  setSystemNameError,
  setRoleTypeError,
}) => {
  const handleRoleNameChange = (e) => {
    setRoleName(e.target.value);
    setRoleNameError("");
  };

  const handleSystemNameChange = (e) => {
    if (e.target.value.includes(" ")) {
      const updatedValue = e.target.value.replace(/\s/g, "");
      setSystemName(updatedValue);
    } else {
      setSystemName(e.target.value);
    }
    setSystemNameError("");
  };

  const handleRoleTypeChange = (selectedOption) => {
    setRoleType(selectedOption);
    setRoleTypeError("");
  };

  const handleIsActiveChange = () => {
    setIsActive(!isActive);
  };

  const handleIsProxyUserRoleChange = () => {
    setIsProxyUserRole(!isProxyUserRole);
  };

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
      <div
        className="bg-secondary text-white"
        style={{
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        <div className="col-lg-12">Add Role</div>
      </div>

      <Modal.Body>
        <div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label className="col-form-label" htmlFor="dropdown23">
                  Role Name<sup>*</sup>
                </label>
                <InputForm
                  id="dropdown23"
                  value={roleName}
                  textArea={false}
                  placeholder=""
                  onChange={handleRoleNameChange}
                  isDisabled={false}
                />
                <div className="text-danger">{roleNameError}</div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className="col-form-label" htmlFor="dropdown24">
                  System Name<sup>*</sup>
                </label>
                <InputForm
                  id="dropdown24"
                  textArea={false}
                  value={systemName}
                  placeholder=""
                  onChange={handleSystemNameChange}
                  isDisabled={false}
                />
                <div className="text-danger">{systemNameError}</div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className="col-form-label" htmlFor="dropdown24">
                  Role Type<sup>*</sup>
                </label>
                <SelectForm
                  value={roleType}
                  placeholder="Select Role Type"
                  options={[
                    { value: 1, label: "Global" },
                    { value: 2, label: "Entity" },
                    { value: 3, label: "Function" },
                  ]}
                  onChange={handleRoleTypeChange}
                />
                <div className="text-danger">{roleTypeError}</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group d-flex flex-column-reverse justify-content-start align-items-start">
                <label className="col-form-label mr-2" htmlFor="dropdown25">
                  Is Active
                </label>
                <input
                  type="checkbox"
                  id="dropdown25"
                  checked={isActive}
                  onChange={handleIsActiveChange}
                  disabled={true}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group d-flex flex-column-reverse justify-content-start align-items-start">
                <label className="col-form-label mr-2" htmlFor="dropdown26">
                  Is ProxyUser Role
                </label>
                <input
                  type="checkbox"
                  id="dropdown25"
                  checked={isProxyUserRole}
                  onChange={handleIsProxyUserRoleChange}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group"></div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleRoleSubmit}>Submit</Button>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MyVerticallyCenteredModal;
