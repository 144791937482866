import React, { useContext, useEffect, useState } from "react";
import { LoaderContext } from "../../Helpers/Context/Context";
import {
  getDropdowns,
  getMRScreeningQuestion,
  postAttributeValueDetailsV2,
  postMRScreeningQuestion,
  getSearchableDropdowns,
} from "../../Helpers/APIEndPoints/EndPoints";
import { APICall } from "../../Helpers/APICalls";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import notify from "../../Helpers/ToastNotification";
import moment from "moment";
import { ExpandMore } from "@mui/icons-material";
import DynamicGrid from "../DynamicGrid/DynamicGrid";
import uuid from "react-uuid";
import SelectForm from "../SelectForm/SelectForm";
import InputForm from "../InputForm/InputForm";
import DateForm from "../DateForm/DateForm";
import { useLocation } from "react-router-dom";
import useMRFStore from "../../Helpers/ZustandStore/MRFStore";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";

const OneTimePayment = ({
  TId,
  setTId,
  formData,
  setFormData,
  section: ogSection,
  sectionValuesArray,
  sectionIndex,
}) => {
  const [hasValue, setHasValue] = useState(
    sectionValuesArray.filter((es) => es.sectionSystemName == ogSection.SN) ||
      null
  );
  const [section, setSection] = useState(ogSection);
  const [formErrors, setFormErrors] = useState<any>({});
  const [optionsObj, setOptionsObj] = useState<any>({});
  const [gridData, setGridData] = useState([]);
  const [displayGrid, setDisplayGrid] = useState([]);
  const [gridColumns, setGridColumns] = useState([]);
  const [ogAttributeSet, setOgAttributeSet] = useState({});
  const [ogFilledAttributeSet, setOgFilledAttributeSet] = useState([]);
  const [currentGridId, setCurrentGridId] = useState(null);
  const [deleteGridId, setDeleteGridId] = useState(null);
  const [showAttr, setshowAttr] = useState(section?.ISEDIT ? false : true);
  const [viewOnly, setViewOnly] = useState(false);

  // value Object for section
  const [attributesData, setAttributesData] = useState<any>({});

  const [attributeSetData, setAttributeSetData] = useState<any>({});

  const [attributeSetCode, setAttributeSetCode] = useState(null);
  const [isOnce, setIsOnce] = useState(true);
  const [isValidateOnce, setIsValidateOnce] = useState(true);

  const [attributeValidation, setAttributeValidation] = useState<any>({});
  const [attributeSetValidation, setAttributeSetValidation] = useState<any>({});

  const [dataFilled, setDataFilled] = useState(false);

  const [sectionHasDocuments, setSectionHasDocuments] = useState(false);

  const { showLoader, hideLoader } = useContext(LoaderContext);

  const { userDetails, currentRoleId }: any = useUserContext();
  const [PageSize, setPageSize] = useState(3);
  const [page, setPage] = useState(0);

  const [fillDDValuesForGrid, setFillDDValuesForGrid] = useState([]);
  const [dAutomapAttribute, setdAutomapAttribute] = useState([]);
  const [modalHeader, setModalHeader] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationPromise, setConfirmationPromise] = useState(null);
  const [ShowAttrSet, setShowAttrSet] = useState(false);
  const [isSameAsEmergency, setIsSameAsEmergency] = useState(false);
  const [isSameAsPermanent, setIsSameAsPermanent] = useState(false);
  const [isSameAsPresent, setIsSameAsPresent] = useState(false);
  const [multiDropdown, setMultiDropdown] = useState(true);
  const [newGridIdCreated, setNewGridIdCreated] = useState(null);
  const [accordion, setAccordion] = useState(sectionIndex === 0 ? true : false);
  const [InstallmentErrors, setInstallmenterrors] = useState([]);
  const [InstallmentGrid,setInstallmentGrid] = useState(false);
  const [InstallmentNo,setInstallmentNo] = useState(0);
  
  const InstallmentgridData = {
    PayComponent:{},
    DueDate:"",
    PayOutAmount:"",
    Currency:{}
  };
  const InstallmentError = {
    PayComponent:"",
    DueDate:"",
    PayOutAmount:"",
    Currency:""
  };
  const [InstallmentData,setInstallmentData] = useState([]);


  useEffect(()=>{

    if(InstallmentNo > 0){
      const demo =[];
      const error = [];
      for (let index = 0; index < InstallmentNo; index++) {
        demo.push(InstallmentgridData);
      }
      setInstallmentData(demo);
      //setInstallmenterrors (error);
    }
  },[InstallmentNo])

  
  const getDropdownsData = async (AC, PID) => {
    await showLoader();
    const dropdownData = await APICall(getDropdowns, "POST", {
      AC,
      PID,
    });

    if (
      dropdownData.data !== null &&
      dropdownData.data !== undefined &&
      dropdownData.data.length > 0
    ) {
      await hideLoader();
      return dropdownData.data;
    } else {
      await hideLoader();
      return [];
    }
  };
  const searchableDDValuesSet = (event, AC) => {
    let DD = event.DD;
    if (DD.length > 0) {
      let newAttributeSetData = { ...attributeSetData };
      let dropdownOptions = { ...optionsObj };

      DD.forEach((attribute) => {
        const Code = attribute.Key;

        // check DT

        if (attribute.DT === "DropdownSingle") {
          const options = dropdownOptions[Code];

          if (options !== undefined && options.length > 0) {
            const option = options.find(
              (attri) => attri.value == attribute.Value
            );

            newAttributeSetData = {
              ...newAttributeSetData,
              [Code]: option === undefined ? null : option,
            };
          }
        } else {
          //check all DT
          newAttributeSetData = {
            ...newAttributeSetData,
            [Code]: attribute.Value,
          };
        }
      });
      newAttributeSetData[AC] = event; //imp

      setAttributeSetData(newAttributeSetData);
    }
  };
  const searchableDDValues = (event, AC) => {
    let DD = event.DD;
    if (DD.length > 0) {
      let newAttributeData = { ...attributesData };
      let dropdownOptions = { ...optionsObj };

      DD.forEach((attribute) => {
        const Code = attribute.Key;

        // check DT

        if (
          attribute.DT === "DropdownSingle" ||
          attribute.DT === "DropdownMultiple"
        ) {
          const options = dropdownOptions[Code];

          if (options !== undefined && options.length > 0) {
            const option = options.find(
              (attri) => attri.value == attribute.Value
            );

            newAttributeData = {
              ...newAttributeData,
              [Code]: option === undefined ? null : option,
            };
          }
        } else {
          //check all DT
          newAttributeData = {
            ...newAttributeData,
            [Code]: attribute.Value,
          };
        }
      });
      newAttributeData[AC] = event; //imp

      setAttributesData(newAttributeData);
    }
  };

  const getSearchableDropdownAPI = async (
    searchString,
    AC,
    cb,
    valueForDropdown,
    type,
    gridId
  ) => {
    if (
      searchString === "" &&
      cb === null &&
      valueForDropdown !== "" &&
      AC !== ""
    ) {
      const response = await APICall(getSearchableDropdowns, "POST", {
        searchString,
        AC,
        valueForDropdown,
      });

      if (response.data !== null && response.data.length > 0) {
        if (response.data.length === 1) {
          if (gridId !== 0) {
            let ddObj = {
              gridId,
              AC,
              DDResponse: response.data[0],
            };
            setFillDDValuesForGrid((prev) => [...prev, ddObj]);
          } else {
            return response.data[0];
            //fillSearchableDDValuesOnEdit(response.data[0], AC);
          }
        }
      }
    } else if (searchString.length > 1) {
      const response = await APICall(getSearchableDropdowns, "POST", {
        searchString,
        AC,
      });
      if (response.data !== null && response.data.length > 0) {
        cb(response.data);
        setOptionsObj((prev) => ({ ...prev, [AC]: response.data }));
      } else {
        cb([]);
        setOptionsObj((prev) => ({ ...prev, [AC]: [] }));
      }
    }
  };
  useEffect(() => {
    //add attribute in attributesData
    //  ;
    let attributes = {};
    let attributeSet = {};
    const gridColumnsArr = [];
    let dropdownOptionsObj = {};
    const sectionHasDocuments = [];
    section?.Attribute?.forEach(async (eachAttribute) => {
      if (eachAttribute.AT === "Attribute") {
        //change value according to dt

        setAttributesData((prev) => ({
          ...prev,
          [eachAttribute.AC]:
            eachAttribute.DT === "DropdownSingle" ||
            eachAttribute.DT === "Date" ||
            eachAttribute.DT === "SearchableDropdownSingle" ||
            eachAttribute.DT === "Document" ||
            eachAttribute.DT === "DropdownMultiple"
              ? null
              : eachAttribute.DT === "Text" || eachAttribute.DT === "Number"
              ? ""
              : eachAttribute.DT === "Checkbox"
              ? false
              : "",
        }));

        attributes = {
          ...attributes,
          [eachAttribute.AC]:
            eachAttribute.DT === "DropdownSingle" ||
            eachAttribute.DT === "Date" ||
            eachAttribute.DT === "SearchableDropdownSingle" ||
            eachAttribute.DT === "Document" ||
            eachAttribute.DT === "DropdownMultiple"
              ? null
              : eachAttribute.DT === "Text" || eachAttribute.DT === "Number"
              ? ""
              : eachAttribute.DT === "Checkbox"
              ? false
              : "",
        };

        //for document
        if (eachAttribute.DT === "Document") {
          setSectionHasDocuments(true);
          sectionHasDocuments.push(eachAttribute.AC);
        }

        //attributeValidation
        setAttributeValidation((prev) => ({
          ...prev,
          [eachAttribute.AC]: JSON.parse(eachAttribute.V),
        }));

        setAttributeValidation((prev) => ({
          ...prev,
          [eachAttribute.AC]: {
            ...prev[eachAttribute.AC],
            DD: eachAttribute.DD !== null ? JSON.parse(eachAttribute.DD) : null,
            isDisable: false,
          },
        }));

        if (eachAttribute.DT === "DropdownSingle") {
          // api call for each dropdown option

          // const options = await getDropdownsData(eachAttribute.AC, null);

          dropdownOptionsObj = {
            ...dropdownOptionsObj,
            [eachAttribute.AC]: eachAttribute.OPT,
          };
          setOptionsObj((prev) => ({
            ...prev,
            [eachAttribute.AC]: eachAttribute.OPT,
          }));
          /*Hardcode to set Default Value.*/
          if (JSON.parse(eachAttribute.AD).DF !== undefined) {
            let filtredOPT = eachAttribute.OPT?.filter((o) => {
              if (o.label.toLowerCase() === JSON.parse(eachAttribute.AD).DF) {
                return o;
              }
            });
            if (filtredOPT.length > 0) {
              setAttributesData((prev) => ({
                ...prev,
                [eachAttribute.AC]: filtredOPT[0],
              }));
            }
          }
          /*Hardcode to set Default Value.*/
        } else if (eachAttribute.DT === "SearchableDropdownSingle") {
          dropdownOptionsObj = {
            ...dropdownOptionsObj,
            [eachAttribute.AC]: "SearchableDropdownSingle",
          };

          setOptionsObj((prev) => ({
            ...prev,
            [eachAttribute.AC]: "SearchableDropdownSingle",
          }));
        }
      } else if (eachAttribute.AT === "AttributeSet") {
        setAttributeSetCode(eachAttribute.AC);

        // Id for grid
        gridColumnsArr.push({
          name: "Id",
          label: "Id",
          options: { display: false },
        });

        eachAttribute.Attribute.forEach(async (subAttribute) => {
          //for document
          if (subAttribute.DT === "Document") {
            setSectionHasDocuments(true);
            sectionHasDocuments.push(subAttribute.AC);
          }

          let display = JSON.parse(subAttribute.AD);
          display = display?.ISOG?.toLowerCase() === "true" ? true : false;

          //grid columns
          gridColumnsArr.push({
            name: subAttribute.AC,
            label: subAttribute.AN,
            options: { sort: false, display },
          });
          //attributeSetValidation
          setAttributeSetValidation((prev) => ({
            ...prev,
            [subAttribute.AC]: JSON.parse(subAttribute.V),
          }));

          setAttributeSetValidation((prev) => ({
            ...prev,
            [subAttribute.AC]: {
              ...prev[subAttribute.AC],
              DD: subAttribute.DD !== null ? JSON.parse(subAttribute.DD) : null,
              isDisable: false,
            },
          }));

          //change value according to dt

          setAttributeSetData((prev) => ({
            ...prev,
            [subAttribute.AC]:
              subAttribute.DT === "DropdownSingle" ||
              subAttribute.DT === "Date" ||
              subAttribute.DT === "MonthYear" ||
              subAttribute.DT === "SearchableDropdownSingle" ||
              subAttribute.DT === "Document"
                ? null
                : subAttribute.DT === "Text" || subAttribute.DT === "Number"
                ? ""
                : subAttribute.DT === "Checkbox"
                ? false
                : "",
          }));

          attributeSet = {
            ...attributeSet,
            [subAttribute.AC]:
              subAttribute.DT === "DropdownSingle" ||
              subAttribute.DT === "Date" ||
              subAttribute.DT === "MonthYear" ||
              subAttribute.DT === "SearchableDropdownSingle" ||
              subAttribute.DT === "Document" ||
              subAttribute.DT === "DropdownMultiple"
                ? null
                : subAttribute.DT === "Text" || subAttribute.DT === "Number"
                ? ""
                : subAttribute.DT === "Checkbox"
                ? false
                : "",
          };

          if (
            subAttribute.DT === "DropdownSingle" ||
            subAttribute.DT === "DropdownMultiple"
          ) {
            // const options = await getDropdownsData(subAttribute.AC, null);

            dropdownOptionsObj = {
              ...dropdownOptionsObj,
              [subAttribute.AC]: subAttribute.OPT,
            };
            setOptionsObj((prev) => ({
              ...prev,
              [subAttribute.AC]: subAttribute.OPT,
            }));

            /*Hardcode to set Default Value.*/
            if (JSON.parse(subAttribute.AD).DF !== undefined) {
              let filtredOPT = subAttribute.OPT?.filter((o) => {
                if (o.label.toLowerCase() === JSON.parse(subAttribute.AD).DF) {
                  return o;
                }
              });
              if (filtredOPT.length > 0) {
                setAttributeSetData((prev) => ({
                  ...prev,
                  [subAttribute.AC]: filtredOPT[0],
                }));

                attributeSet = {
                  ...attributeSet,
                  [subAttribute.AC]: filtredOPT[0],
                };
              }
            }
            /*Hardcode to set Default Value.*/
          } else if (subAttribute.DT === "SearchableDropdownSingle") {
            dropdownOptionsObj = {
              ...dropdownOptionsObj,
              [subAttribute.AC]: "SearchableDropdownSingle",
            };

            setOptionsObj((prev) => ({
              ...prev,
              [subAttribute.AC]: "SearchableDropdownSingle",
            }));
          }
        });
      }
    });
    setOgAttributeSet(attributeSet);
    setGridColumns((prev) => [...prev, ...gridColumnsArr, action]);
    const fillData = async () => {
      await showLoader();
      if (isOnce && TId !== 0) {
        //fetch attributes/set data

        //check if not first time then call api
        let attributesRes = null;
        if (hasValue.length > 0 && hasValue[0].attributes.length > 0) {
          attributesRes = hasValue[0].attributes;
          // handle data

          if (attributesRes !== null && attributesRes.length > 0) {
            let newAttributeData: any = { ...attributes };
            //loop for setting flat attributes on edit
            for (const eachAttribute of attributesRes) {
              if (eachAttribute.type === "Attribute") {
                let { key, value: valueForField } = eachAttribute;

                if (
                  dropdownOptionsObj[key] !== undefined &&
                  dropdownOptionsObj[key] !== "SearchableDropdownSingle"
                ) {
                  //for filling normal dropdown on edit
                  const options = dropdownOptionsObj[key];

                  if (options !== undefined && options?.length > 0) {
                    const option = options?.find(
                      (attri) => attri.value == valueForField
                    );

                    newAttributeData = {
                      ...newAttributeData,
                      [key]: option === undefined ? null : option,
                    };
                  }
                } else if (
                  dropdownOptionsObj[key] !== undefined &&
                  dropdownOptionsObj[key] === "SearchableDropdownSingle"
                ) {
                  //for filling searchable dropdown on edit
                  if (valueForField !== undefined) {
                    const DDResponse = await getSearchableDropdownAPI(
                      "",
                      key,
                      null,
                      valueForField,
                      "attribute",
                      0
                    );
                    newAttributeData = {
                      ...newAttributeData,
                      [key]:
                        DDResponse === undefined
                          ? null
                          : {
                              label: DDResponse.label,
                              value: DDResponse.value,
                            },
                    };
                  }
                } else {
                  //filling other data

                  valueForField =
                    typeof valueForField === "string" &&
                    valueForField.toLowerCase() === "true"
                      ? true
                      : typeof valueForField === "string" &&
                        valueForField.toLowerCase() === "false"
                      ? false
                      : valueForField !== null && valueForField !== undefined
                      ? valueForField.toString()
                      : valueForField;

                  // format date

                  const dateString = valueForField;
                  const allowedFormat = [
                    "DD-MM-YYYY hh.mm.ss A",
                    "DD/MM/YYYY hh.mm.ss A",
                  ];

                  const isValidDate = moment(
                    dateString,
                    allowedFormat,
                    true
                  ).isValid();

                  const isValidBeforeDate = moment(
                    dateString,
                    "DD-MMM-YYYY",
                    true
                  ).isValid();
                  if (isValidBeforeDate && dateString === "01-Jan-1900") {
                    valueForField = null;
                  }

                  if (
                    isValidDate &&
                    dateString !== "01-01-0001 12.00.00 AM" &&
                    dateString !== "1/1/0001 12:00:00 AM"
                  ) {
                    const parsedDate = moment(valueForField, allowedFormat[0]);
                    const formattedDate = parsedDate.format("DD-MMM-YYYY");
                    valueForField = formattedDate;
                  } else if (
                    dateString === "01-01-0001 12.00.00 AM" ||
                    dateString === "1/1/0001 12:00:00 AM"
                  ) {
                    valueForField = null;
                  }

                  newAttributeData = {
                    ...newAttributeData,
                    [key]: valueForField,
                  };
                }
              }
            }
            setAttributesData(newAttributeData);

            //loop for setting set attributes on edit
            for (const eachAttribute of attributesRes) {
              if (eachAttribute.type === "AttributeSet") {
                //AttributesSet

                if (
                  Object.keys(attributeSet).length > 0 &&
                  eachAttribute.details !== null &&
                  eachAttribute.details.length > 0
                ) {
                  setAttributeSetCode(eachAttribute.key);

                 

                  const details = eachAttribute.details;

                  if (details.length > 0) {
                    let newAttributeSetData = { ...attributeSet };
                    let newAttributeSetDataForGrid = { ...attributeSet };
                    let dropdownOptions = { ...dropdownOptionsObj };

                    const filledData = [];
                    const gridData = [];
                    details.forEach(async (detail) => {
                      //add isActive for all details
                      detail.IsActive = true;

                      Object.entries(detail).forEach(async (attribute) => {
                        const [Code, Value]: any = attribute;

                        if (
                          Object.keys(dropdownOptions).find(
                            (option) =>
                              option.toLowerCase() === Code.toLowerCase()
                          ) &&
                          dropdownOptions[Code] !== "SearchableDropdownSingle"
                        ) {
                          const options = dropdownOptions[Code];
                          if (options !== undefined && options.length > 0) {
                            const option = options.find(
                              (attri) => attri.value == Value
                            );

                            newAttributeSetDataForGrid = {
                              ...newAttributeSetDataForGrid,
                              [Code]:
                                option === undefined ? null : option?.label,
                            };

                            newAttributeSetData = {
                              ...newAttributeSetData,
                              [Code]: option === undefined ? null : option,
                            };
                          }
                        } else if (
                          Object.keys(dropdownOptions).find(
                            (option) =>
                              option.toLowerCase() === Code.toLowerCase()
                          ) &&
                          dropdownOptions[Code] === "SearchableDropdownSingle"
                        ) {
                          if (Value !== undefined) {
                            await getSearchableDropdownAPI(
                              "",
                              Code,
                              null,
                              Value,
                              "attributeSet",
                              detail.Id
                            );
                          }
                        } else {
                          //check type later when actual data

                          let valueForGrid =
                            typeof Value === "string" &&
                            Value.toLowerCase() === "true"
                              ? true
                              : typeof Value === "string" &&
                                Value.toLowerCase() === "false"
                              ? false
                              : Value !== null
                              ? Value.toString()
                              : Value;

                          // format date

                          const dateString = Value;
                          const allowedFormatGrid = [
                            "DD-MM-YYYY hh.mm.ss A",
                            "DD/MM/YYYY hh.mm.ss A",
                            "DD-MMM-YYYY",
                          ];

                          const isValidDateGrid = moment(
                            dateString,
                            allowedFormatGrid,
                            true
                          ).isValid();
                          if (
                            isValidDateGrid &&
                            dateString !== "01-01-0001 12.00.00 AM" &&
                            dateString !== "1/1/0001 12:00:00 AM" &&
                            dateString !== "01-Jan-0001" &&
                            dateString !== "01-Jan-1900"
                          ) {
                            // const parsedDate = moment(Value, allowedFormat[0]);
                            // const formattedDate =
                            //   parsedDate.format("DD-MM-YYYY");
                            // valueForGrid = formattedDate;
                            const formattedDate = moment(
                              Value,
                              "DD-MMM-YYYY"
                            ).format("DD/MM/YYYY");
                            valueForGrid = formattedDate;
                          } else if (
                            dateString === "01-01-0001 12.00.00 AM" ||
                            dateString === "1/1/0001 12:00:00 AM" ||
                            dateString === "01-Jan-0001" ||
                            dateString === "01-Jan-1900"
                          ) {
                            valueForGrid = null;
                          }

                          const allowedFormatField = [
                            "DD-MM-YYYY hh.mm.ss A",
                            "DD/MM/YYYY hh.mm.ss A",
                          ];

                          const isValidDateField = moment(
                            dateString,
                            allowedFormatField,
                            true
                          ).isValid();
                          let valueForField =
                            typeof Value === "string" &&
                            Value.toLowerCase() === "true"
                              ? true
                              : typeof Value === "string" &&
                                Value.toLowerCase() === "false"
                              ? false
                              : typeof Value === "boolean"
                              ? Value
                              : Value !== null
                              ? Value.toString()
                              : Value;

                          if (
                            isValidDateField &&
                            dateString !== "01-01-0001 12.00.00 AM" &&
                            dateString !== "1/1/0001 12:00:00 AM"
                          ) {
                            const parsedDate = moment(
                              Value,
                              allowedFormatField[0]
                            );
                            const formattedDate =
                              parsedDate.format("DD-MMM-YYYY");
                            valueForField = formattedDate;
                          } else if (
                            dateString === "01-01-0001 12.00.00 AM" ||
                            dateString === "1/1/0001 12:00:00 AM"
                          ) {
                            valueForField = null;
                          }
                          const isValidBeforeDate = moment(
                            dateString,
                            "DD-MMM-YYYY",
                            true
                          ).isValid();
                          if (
                            isValidBeforeDate &&
                            (dateString === "01-Jan-1900" ||
                              dateString === "01-Jan-0001")
                          ) {
                            valueForField = null;
                          }

                          newAttributeSetDataForGrid = {
                            ...newAttributeSetDataForGrid,
                            [Code]: valueForGrid,
                          };

                          newAttributeSetData = {
                            ...newAttributeSetData,
                            [Code]: valueForField,
                          };
                        }
                      });
                      gridData.push(newAttributeSetDataForGrid);
                      filledData.push(newAttributeSetData);
                    });

                    setDisplayGrid(gridData);

                    setGridData(gridData);
                    setOgFilledAttributeSet(filledData);
                  }
                  // setIsOnce(false);
                }
              }
            }
          }
        }
        setIsOnce(false);
        // })();
      }
      await hideLoader();
    };

    const fillTimer = setTimeout(() => {
      clearTimeout(fillTimer);
      fillData();
    }, 2000);

    //save in state
    return () => {
      clearTimeout(fillTimer);
    };
  }, []);

  useEffect(() => {
    // ;
    if (
      attributesData !== undefined &&
      Object.keys(attributesData).length > 0 &&
      attributeValidation !== undefined &&
      Object.keys(attributeValidation).length > 0
    ) {
      if (
        (isValidateOnce && isOnce === false && TId !== 0) ||
        (isValidateOnce && isOnce === true && TId === 0)
      ) {
        let attributeValues = { ...attributesData }; // for checking values
        let attributeValObj = { ...attributeValidation };
        Object.keys(attributeValObj).forEach(async (attribute) => {
          if (
            attributeValObj[attribute].DD !== null &&
            attributeValObj[attribute].DD.length > 0
          ) {
            let ad = attributeValObj[attribute].DD;

            ad.forEach(async (record) => {
              //   CCode: "POLENDT",
              // PValue: "false",
              // Condition: "equalTo",
              // Flag: "disable",
              // PDT: "checkbox",
              // CDT: "checkbox",
              let CCode = record.CCode;
              let PValue = record.PValue;
              let Condition = record.Condition;
              let Flag = record.Flag;
              let PDT = record.PDT;
              let CDT = record.CDT;

              if (PDT.toLowerCase() === "checkbox") {
                if (
                  attributeValues[attribute].toString().toLowerCase() ===
                    PValue.toLowerCase() ||
                  attributeValues[attribute].toString().toLowerCase() === ""
                ) {
                  if (Flag === "disable") {
                    attributeValObj[CCode].isDisable = true;
                    attributeValObj[CCode].ISM = "false";
                  }
                } else if (
                  attributeValues[attribute].toString().toLowerCase() !==
                  PValue.toLowerCase()
                ) {
                  if (Flag === "disable") {
                    attributeValObj[CCode].isDisable = false;
                    attributeValObj[CCode].ISM = "true";
                  }
                }
              } else if (PDT === "DropdownSingle") {
               
                if (
                  attributeValues[attribute] !== null &&
                  attributeValues[attribute].label.toString().toLowerCase() ===
                    PValue.toLowerCase()
                ) {
                  //check condition
                  if (Flag === "disable") {
                    attributeValObj[CCode].isDisable = true;
                    attributeValObj[CCode].ISM = "false";
                  }
                } else {
                  if (attributeValObj[CCode] !== undefined) {
                    attributeValObj[CCode].isDisable = false;
                    attributeValObj[CCode].ISM = "true";
                  }
                }
              } else if (PDT === "Document") {
              }
            });
          }
        });
        setAttributeValidation((prev) => ({ ...prev, ...attributeValObj }));
        setIsValidateOnce(false);
      }
    }
  }, [attributesData, isOnce]);

  const action = {
    name: "Id",
    label: "Action",
    options: {
      filter: false,
      sort: false,
      display: true,
      setCellProps: () => ({
        style: { textAlign: "center" },
      }),
      setCellHeaderProps: () => ({
        style: { textAlign: "center" },
      }),
      customBodyRender: (value, tableMeta) => {
        let Id = tableMeta.tableData[tableMeta.rowIndex].Id;

        return (
          <div className="d-flex justify-content-center">
            <Tooltip title="edit">
              <a
                className="mr-2"
                onClick={(e) => {
                  e.preventDefault();
                  setFormErrors({});
                  setCurrentGridId(Id);
                }}>
                <i className="fas fa-edit"></i>
              </a>
            </Tooltip>
            <Tooltip title="delete">
              <a
                className="mr-2"
                onClick={(e) => {
                  e.preventDefault();
                  setFormErrors({});
                  setDeleteGridId(Id);
                }}>
                <i className="fa fa-trash" aria-hidden="true"></i>
              </a>
            </Tooltip>
          </div>
        );
      },
    },
  };

  useEffect(() => {
    // ;
    if (currentGridId) {
      let attributeSetData = ogFilledAttributeSet.find(
        (record) => record.Id === currentGridId
      );
      setAttributeSetData(attributeSetData);
    }
  }, [currentGridId]);

  useEffect(() => {
    //  ;
    if (deleteGridId && currentGridId === null) {
      //to disable grid delete button when edit is active

      setGridData((prev) => {
        const newState = prev.map((record) => {
          if (record.Id === deleteGridId) {
            return {
              ...record,
              IsActive: false,
            };
          }
          return record;
        });
        return newState;
      });

      setOgFilledAttributeSet((prev) => {
        const newState = prev.map((record) => {
          if (record.Id === deleteGridId) {
            
            return {
              ...record,
              IsActive: false,
            };
          }
          return record;
        });
        return newState;
      });

      setDisplayGrid((prev) =>
        prev.filter((record) => record.Id !== deleteGridId)
      );
    }
    setDeleteGridId(null);
  }, [deleteGridId]);

  const dependentValidations = (
    targetValue,
    targetAttributeCode,
    attributeType
  ) => {
    //  ;
    if (attributeType === "Attribute") {
      let attributeValues = { ...attributesData }; // for checking values
      let attributeValObj = { ...attributeValidation };

      if (
        attributeValObj[targetAttributeCode].DD !== null &&
        attributeValObj[targetAttributeCode].DD.length > 0
      ) {
        let ad = attributeValObj[targetAttributeCode].DD;

        ad.forEach(async (record) => {
          let CCode = record.CCode;
          let PValue = record.PValue;
          let Condition = record.Condition;
          let Flag = record.Flag;
          let PDT = record.PDT;
          let CDT = record.CDT;

          if (PDT === "DropdownSingle") {
            if (
              targetValue &&
              targetValue.label.toString().toLowerCase() ===
                PValue.toLowerCase()
            ) {
              //check condition
              if (Flag === "disable") {
                attributeValObj[CCode].isDisable = true;
                attributeValObj[CCode].ISM = "false";
              }
            } else {
              attributeValObj[CCode].isDisable = false;
              attributeValObj[CCode].ISM ="true";
            }
          } else if (PDT.toLowerCase() === "checkbox") {
            if (targetValue.toString().toLowerCase() === PValue.toLowerCase()) {
              //check condition
              if (Flag === "disable") {
                attributeValObj[CCode].isDisable = true;
                attributeValObj[CCode].ISM = "false";
              }
            } else {
              attributeValObj[CCode].isDisable = false;
              attributeValObj[CCode].ISM = "true";
            }
          }

          //clear values based to CDT
          attributeValues[CCode] =
            CDT === "DropdownSingle" ||
            CDT === "Date" ||
            CDT === "SearchableDropdownSingle"
              ? null
              : CDT === "Text" || CDT === "Number"
              ? ""
              : CDT === "Checkbox"
              ? false
              : "";
        });

        attributeValues[targetAttributeCode] = targetValue; //imp
        setFormErrors({});
        setAttributeValidation((prev) => ({ ...prev, ...attributeValObj }));
        setAttributesData((prev) => ({
          ...prev,
          ...attributeValues,
        }));
      }
    } else {
      let attributeValues = { ...attributeSetData }; // for checking values
      let attributeValObj = { ...attributeSetValidation };

      if (
        attributeValObj[targetAttributeCode].DD !== null &&
        attributeValObj[targetAttributeCode].DD.length > 0
      ) {
        let ad = attributeValObj[targetAttributeCode].DD;

        ad.forEach(async (record) => {
          let CCode = record.CCode;
          let PValue = record.PValue;
          let Condition = record.Condition;
          let Flag = record.Flag;
          let PDT = record.PDT;
          let CDT = record.CDT;

          if (PDT === "DropdownSingle") {
            if (
              targetValue &&
              targetValue.label.toString().toLowerCase() ===
                PValue.toLowerCase()
            ) {
              //check condition
              if (Flag === "disable") {
                attributeValObj[CCode].isDisable = true;
                attributeValObj[CCode].ISM = "false";
              }
            } else {
              attributeValObj[CCode].isDisable = false;
              attributeValObj[CCode].ISM = "true";
            }
          } else if (PDT.toLowerCase() === "checkbox") {
            if (targetValue.toString().toLowerCase() === PValue.toLowerCase()) {
              //check condition
              if (Flag === "disable") {
                attributeValObj[CCode].isDisable = true;
                attributeValObj[CCode].ISM = "false";
              }
            } else {
              attributeValObj[CCode].isDisable = false;
              attributeValObj[CCode].ISM = "true";
            }
          }

          //clear values based to CDT
          attributeValues[CCode] =
            CDT === "DropdownSingle" ||
            CDT === "Date" ||
            CDT === "SearchableDropdownSingle"
              ? null
              : CDT === "Text" || CDT === "Number"
              ? ""
              : CDT === "Checkbox"
              ? false
              : "";
        });

        attributeValues[targetAttributeCode] = targetValue; //imp
        setFormErrors({});
        setAttributeSetValidation((prev) => ({ ...prev, ...attributeValObj }));
        setAttributeSetData((prev) => ({
          ...prev,
          ...attributeValues,
        }));
      }
    }
  };

  const gridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    // count: gridData.length,
    rowsPerPage: gridData.length,
    // page: page,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",

    //standard | vertical | simple
    onChangeRowsPerPage: (num) => {},

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
      }
      if (sortDirection === "desc") {
      }
    },
    onChangePage: async (page) => {},
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const isValidEmail = (email, emailPattern) => {
    const regex = new RegExp(emailPattern);
    return regex.test(email);
  };

  const validateCurrentMonth = (EnteredDate) => {
    let monthsDiff;
    let yearsDiff;

    const endDate = moment();
    const startDate = moment(EnteredDate);

    monthsDiff = endDate.diff(startDate, "months");

    yearsDiff = endDate.diff(startDate, "years");

    return { monthsDiff, yearsDiff };
  };

  const validateData = (Attribute) => {
    let errorObj = {};
    let isError = false;
    Attribute.forEach(async (subAttribute) => {
      if (subAttribute.AT === "Attribute") {
        // const validation = JSON.parse(subAttribute.V);
        const validation = attributeSetValidation[subAttribute.AC];

        if (validation.ISM.toLowerCase() === "true") {
          if (subAttribute.DT === "Text") {
            if (attributeSetData[subAttribute.AC].trim() === "") {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else if (
              attributeSetData[subAttribute.AC]?.length > validation.MaxC
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: `Max ${validation.MaxC} characters allowed`,
              };
            } else if (validation.PTN != undefined) {
              if (
                isValidEmail(
                  attributeSetData[subAttribute.AC],
                  validation.PTN
                ) != true
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: `Incorrect format.`,
                };
              }
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          } else if (
            subAttribute.DT === "DropdownSingle" ||
            subAttribute.DT === "SearchableDropdownSingle" ||
            subAttribute.DT === "DropdownMultiple"
          ) {
            if (attributeSetData[subAttribute.AC] === null) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          } else if (subAttribute.DT === "Date") {
            if (attributeSetData[subAttribute.AC] === null) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else if (
              moment(
                validation.MaxV === "CurrentDate" &&
                  attributeSetData[subAttribute.AC]
              ).isAfter(new Date())
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Only till current date allowed",
              };
            } else if (
              validation.MaxV === "CurrentMonth" &&
              attributeSetData[subAttribute.AC] != ""
            ) {
              let validatingValues = validateCurrentMonth(
                attributeSetData[subAttribute.AC]
              );
              if (
                validatingValues.monthsDiff != 0 ||
                validatingValues.yearsDiff != 0
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Only date from current month allowed",
                };
              }
            } else if (
              moment(
                validation.MinV === "CurrentDate" &&
                  attributeSetData[subAttribute.AC]
              ).isBefore()
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Only after current date allowed",
              };
            } else if (
              validation.MinV !== undefined &&
              validation.MinV !== "CurrentDate" &&
              validation.MinV !== ""
            ) {
              let parentValue = attributeSetData[validation.MinV];
              if (
                parentValue !== null &&
                parentValue !== "" &&
                attributeSetData[subAttribute.AC] !== null &&
                attributeSetData[subAttribute.AC] !== "" &&
                moment(attributeSetData[subAttribute.AC]).isBefore(parentValue)
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Past date not allowed",
                };
              } else if (
                (parentValue === null || parentValue === "") &&
                attributeSetData[subAttribute.AC] !== null &&
                attributeSetData[subAttribute.AC] !== ""
              ) {
                errorObj = {
                  ...errorObj,
                  [validation.MinV]: "Required",
                  [subAttribute.AC]: "",
                };
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          } else if (subAttribute.DT === "Number") {
            //change with regex
            if (attributeSetData[subAttribute.AC].trim() === "") {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else if (
              // isNaN(
              //   attributeSetData[subAttribute.AC]
              // )
              !/^([0-9]+|0)$/.test(attributeSetData[subAttribute.AC])
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Only numbers are allowed",
              };
            }    else if (
              Number(attributeSetData[subAttribute.AC]) > validation.MaxV
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: `Max ${validation.MaxV} allowed`,
              };
            } else if (
              Number(attributeSetData[subAttribute.AC]) < validation.MinV
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: `Min ${validation.MinV} required`,
              };
            }
            //If Numeric values has pattern
            else if (validation.PTN != undefined) {
              if (
                isValidEmail(
                  attributeSetData[subAttribute.AC],
                  validation.PTN
                ) != true
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: `Invalid Data`,
                };
              }
            }
            else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          } 
          //else if (subAttribute.DT === "Document") {
          //   const fileCount = selectedFilesForSet.reduce((total, item) => {
          //     if (
          //       item.AC === subAttribute.AC &&
          //       ((currentGridId && currentGridId === item.Id) ||
          //         item.Id === 0 ||
          //         item.Id !== 0)
          //     ) {
          //       return total + 1;
          //     }
          //     return total;
          //   }, 0);

          //   if (selectedFilesForSet.length === 0 || fileCount === 0) {
          //     errorObj = {
          //       ...errorObj,
          //       [subAttribute.AC]: "Required",
          //     };
          //   } else {
          //     errorObj = {
          //       ...errorObj,
          //       [subAttribute.AC]: "",
          //     };
          //   }
          // }
        } else {
          // for num and date

          if (subAttribute.DT === "Number") {
            if (attributeSetData[subAttribute.AC] !== "") {
              //change with regex
              if (
                // isNaN(
                //   attributeSetData[
                //     subAttribute.AC
                //   ]
                // )
                !/^([0-9]+|0)$/.test(attributeSetData[subAttribute.AC])
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Only numbers are allowed",
                };
              } else if (
                Number(attributeSetData[subAttribute.AC]) > validation.MaxV
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: `Max ${validation.MaxV} allowed`,
                };
              } else if (
                Number(attributeSetData[subAttribute.AC]) < validation.MinV
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: `Min ${validation.MinV} required`,
                };
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          } else if (subAttribute.DT === "Date") {
            if (attributeSetData[subAttribute.AC] !== null) {
              if (
                moment(
                  validation.MaxV === "CurrentDate" &&
                    attributeSetData[subAttribute.AC]
                ).isAfter(new Date())
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Only till current date allowed",
                };
              } else if (
                moment(
                  validation.MinV === "CurrentDate" &&
                    attributeSetData[subAttribute.AC]
                ).isBefore()
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Only after current date allowed",
                };
              } else if (
                validation.MinV !== undefined &&
                validation.MinV !== "CurrentDate" &&
                validation.MinV !== ""
              ) {
                let parentValue = attributeSetData[validation.MinV];
                if (
                  parentValue !== null &&
                  parentValue !== "" &&
                  attributeSetData[subAttribute.AC] !== null &&
                  attributeSetData[subAttribute.AC] !== "" &&
                  moment(attributeSetData[subAttribute.AC]).isBefore(
                    parentValue
                  )
                ) {
                  errorObj = {
                    ...errorObj,
                    [subAttribute.AC]: "Past date not allowed",
                  };
                } else if (
                  (parentValue === null || parentValue === "") &&
                  attributeSetData[subAttribute.AC] !== null &&
                  attributeSetData[subAttribute.AC] !== ""
                ) {
                  errorObj = {
                    ...errorObj,
                    [validation.MinV]: "Required",
                    [subAttribute.AC]: "",
                  };
                } else {
                  errorObj = {
                    ...errorObj,
                    [subAttribute.AC]: "",
                  };
                }
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          }
        }
      }
    });

    const isEmpty = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      notify(1, `${section.SDN} - Please check validation errors!`);
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };
  const ValidateInstallments = () => {
    let errors = [];
    let isError = false;
    for (let index = 0; index < InstallmentData.length; index++) {
      let errorObj = {};
      Object.keys(InstallmentgridData).map((ob)=>{
        if((ob === "PayComponent" || ob === "Currency") && InstallmentData[index][ob] === null || InstallmentData[index][ob] === undefined || Object.keys(InstallmentData[index][ob]).length === 0 ){
          errorObj = {
            ...errorObj,
            [ob]: "Required",
          };
        }
        else if(ob === "DueDate" || ob === "PayOutAmount"){
          if(InstallmentData[index][ob] === null || InstallmentData[index][ob] === undefined || InstallmentData[index][ob] === ""){
            errorObj = {
              ...errorObj,
              [ob]: "Required",
            };
          }
          else if(ob === "DueDate" && moment(InstallmentData[index]["DueDate"]).isBefore()){
            errorObj = {
              ...errorObj,
              [ob]: "Only after current date allowed.",
            };
          }
          else if (ob === "PayOutAmount"){
            if(!/^([0-9]+|0)$/.test(InstallmentData[index].PayOutAmount)){
              errorObj = {
                ...errorObj,
                [ob]: "Numbers Are Allowed Only.",
              };
            }
          }
        }
        
      })
      if(Object.keys(errorObj).length != 0 ){
          errors.push(errorObj);
      }
    }
    if(errors.length > 0){
      setInstallmenterrors(errors);
      isError = true;
    }
    else{
      setInstallmenterrors([]);
      isError = false;
    }
    return isError;
  };
  
  let { setSectionHide }: any = useMRFStore();

  return (
    <div className="col-lg-12">
      {section?.Attribute?.length > 0 ? (
        <Accordion
          elevation={0}
          className="mb-3"
          expanded={accordion}
          onChange={() => setAccordion((prev) => !prev)}>
          <AccordionSummary
            id="profile-accordion"
            style={{ background: "#3C5464" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}>
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <p style={{ fontSize: "16px" }}>{section.SDN}</p>
              </div>
              <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12">
                <Tooltip title="Submit">
                  <button
                    className="btn"
                    disabled={currentGridId !== null ? true : false || showAttr}
                    onClick={async (e) => {
                      e.stopPropagation();

                      if (TId === 0 && section.SN !== "Basic") {
                        notify(1, "Save Basic Data First");
                      } else {
                        let errorObj = {};
                        section?.Attribute?.map((eachAttribute) => {
                          if (eachAttribute.AT === "Attribute") {
                            const validation =
                              attributeValidation[eachAttribute.AC];

                            if (validation.ISM.toLowerCase() === "true") {
                              if (eachAttribute.DT === "Text") {
                                if (
                                  attributesData[eachAttribute.AC].trim() === ""
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "Required",
                                  };
                                } else if (validation.PTN != undefined) {
                                  if (
                                    isValidEmail(
                                      attributesData[eachAttribute.AC],
                                      validation.PTN
                                    ) != true
                                  )
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: `Incorrect format.`,
                                    };
                                } else if (
                                  attributesData[eachAttribute.AC]?.length >
                                  validation.MaxC
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: `Max ${validation.MaxC} characters allowed`,
                                  };
                                } else {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "",
                                  };
                                }
                              } else if (
                                eachAttribute.DT === "DropdownSingle" ||
                                eachAttribute.DT ===
                                  "SearchableDropdownSingle" ||
                                eachAttribute.DT === "DropdownMultiple"
                              ) {
                                if (attributesData[eachAttribute.AC] === null) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "Required",
                                  };
                                } else {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "",
                                  };
                                }
                              } else if (eachAttribute.DT === "Date") {
                                if (attributesData[eachAttribute.AC] === null) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "Required",
                                  };
                                } else if (
                                  validation.MaxV === "CurrentDate" &&
                                  moment(
                                    attributesData[eachAttribute.AC]
                                  ).isAfter(moment())
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]:
                                      "Only till current date allowed",
                                  };
                                } else if (
                                  moment(
                                    validation.MinV === "CurrentDate" &&
                                      attributesData[eachAttribute.AC]
                                  ).isBefore()
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]:
                                      "Only after current date allowed",
                                  };
                                } else if (
                                  validation.MinV !== undefined &&
                                  validation.MinV !== "CurrentDate" &&
                                  validation.MinV !== ""
                                ) {
                                  let parentValue =
                                    attributesData[validation.MinV];
                                  if (
                                    parentValue !== null &&
                                    parentValue !== "" &&
                                    attributesData[eachAttribute.AC] !== null &&
                                    attributesData[eachAttribute.AC] !== "" &&
                                    moment(
                                      attributesData[eachAttribute.AC]
                                    ).isBefore(parentValue)
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]:
                                        "Past date not allowed",
                                    };
                                  } else if (
                                    parentValue !== null &&
                                    parentValue !== "" &&
                                    attributesData[eachAttribute.AC] !== null &&
                                    attributesData[eachAttribute.AC] !== ""
                                  ) {
                                    if (validation.MinV === "DOB") {
                                      const diffInYear = moment(
                                        attributesData[eachAttribute.AC]
                                      ).diff(moment(parentValue), "years");
                                      if (diffInYear < 16) {
                                        errorObj = {
                                          ...errorObj,
                                          [eachAttribute.AC]:
                                            "Past date not allowed",
                                        };
                                      }
                                    }
                                  } else if (
                                    (parentValue === null ||
                                      parentValue === "") &&
                                    attributesData[eachAttribute.AC] !== null &&
                                    attributesData[eachAttribute.AC] !== ""
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [validation.MinV]: "Required",
                                      [eachAttribute.AC]: "",
                                    };
                                  } else {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "",
                                    };
                                  }
                                } else if (
                                  validation.MaxV !== undefined &&
                                  validation.MaxV !== "CurrentDate" &&
                                  validation.MaxV !== ""
                                ) {
                                  let parentValue =
                                    attributesData[validation.MinV];
                                  if (
                                    parentValue !== null &&
                                    parentValue !== "" &&
                                    attributesData[eachAttribute.AC] !== null &&
                                    attributesData[eachAttribute.AC] !== "" &&
                                    moment(
                                      attributesData[eachAttribute.AC]
                                    ).isAfter(parentValue)
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]:
                                        "Future Date not Allowed.",
                                    };
                                  } else if (
                                    (parentValue === null ||
                                      parentValue === "") &&
                                    attributesData[eachAttribute.AC] !== null &&
                                    attributesData[eachAttribute.AC] !== ""
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [validation.MinV]: "Required",
                                      [eachAttribute.AC]: "",
                                    };
                                  } else {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "",
                                    };
                                  }
                                } else {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "",
                                  };
                                }
                              } else if (eachAttribute.DT === "Number") {
                                if (
                                  !/^([0-9]+|0)$/.test(
                                    attributesData[eachAttribute.AC]
                                  )
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]:
                                      "Only numbers are allowed",
                                  };
                                } else if (
                                  Number(attributesData[eachAttribute.AC]) >
                                  Number(validation.MaxV)
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: `Max ${validation.MaxV} allowed`,
                                  };
                                } else if (
                                  Number(attributesData[eachAttribute.AC]) <
                                  Number(validation.MinV)
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: `Min ${validation.MinV} required`,
                                  };
                                } else {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "",
                                  };
                                }
                              } 
                              // else if (eachAttribute.DT === "Document") {
                              //   const fileCount = selectedFilesForFlat.reduce(
                              //     (total, item) => {
                              //       if (item.AC === eachAttribute.AC) {
                              //         return total + 1;
                              //       }
                              //       return total;
                              //     },
                              //     0
                              //   );

                              //   if (
                              //     selectedFilesForFlat.length === 0 ||
                              //     fileCount === 0
                              //   ) {
                              //     errorObj = {
                              //       ...errorObj,
                              //       [eachAttribute.AC]: "Required",
                              //     };
                              //   } else {
                              //     errorObj = {
                              //       ...errorObj,
                              //       [eachAttribute.AC]: "",
                              //     };
                              //   }
                              // }
                            } else {
                              // for num and date
                              if (eachAttribute.DT === "Text") {
                                if (validation.PTN != undefined) {
                                  if (
                                    isValidEmail(
                                      attributesData[eachAttribute.AC],
                                      validation.PTN
                                    ) != true
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: `Incorrect format.`,
                                    };
                                  } else {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: ``,
                                    };
                                  }
                                }
                              }
                              if (eachAttribute.DT === "Number") {
                                if (attributesData[eachAttribute.AC] !== "") {
                                  //change with regex
                                  if (
                                    // isNaN(attributesData[eachAttribute.AC])
                                    !/^([0-9]+|0)$/.test(
                                      attributesData[eachAttribute.AC]
                                    )
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]:
                                        "Only numbers are allowed",
                                    };
                                  } else if (
                                    Number(attributesData[eachAttribute.AC]) >
                                    validation.MaxV
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: `Max ${validation.MaxV} allowed`,
                                    };
                                  } else if (
                                    Number(attributesData[eachAttribute.AC]) <
                                    validation.MinV
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: `Min ${validation.MinV} required`,
                                    };
                                  } else {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "",
                                    };
                                  }
                                } else {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "",
                                  };
                                }
                              } else if (eachAttribute.DT === "Date") {
                                if (attributesData[eachAttribute.AC] !== null) {
                                  if (
                                    moment(
                                      validation.MaxV === "CurrentDate" &&
                                        attributesData[eachAttribute.AC]
                                    ).isAfter(new Date())
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]:
                                        "Only till current date allowed",
                                    };
                                  } else if (
                                    moment(
                                      validation.MinV === "CurrentDate" &&
                                        attributesData[eachAttribute.AC]
                                    ).isBefore()
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]:
                                        "Only after current date allowed",
                                    };
                                  } else if (
                                    validation.MinV !== undefined &&
                                    validation.MinV !== "CurrentDate" &&
                                    validation.MinV !== ""
                                  ) {
                                    let parentValue =
                                      attributesData[validation.MinV];
                                    if (
                                      parentValue !== null &&
                                      parentValue !== "" &&
                                      attributesData[eachAttribute.AC] !==
                                        null &&
                                      attributesData[eachAttribute.AC] !== "" &&
                                      moment(
                                        attributesData[eachAttribute.AC]
                                      ).isBefore(parentValue)
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]:
                                          "Past date not allowed",
                                      };
                                    } else if (
                                      (parentValue === null ||
                                        parentValue === "") &&
                                      attributesData[eachAttribute.AC] !==
                                        null &&
                                      attributesData[eachAttribute.AC] !== ""
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [validation.MinV]: "Required",
                                        [eachAttribute.AC]: "",
                                      };
                                    } else {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: "",
                                      };
                                    }
                                  } else {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "",
                                    };
                                  }
                                } else {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "",
                                  };
                                }
                              }
                            }
                          } else {
                            //submit attributeSet
                          }
                        });

                        const isEmpty = Object.values(errorObj).every(
                          (s) => s === ""
                        );

                        if (!isEmpty) {
                          setFormErrors((err) => ({
                            ...err,
                            ...errorObj,
                          }));
                        } else {
                          setFormErrors({});
                        }

                        if (isEmpty && attributeSetCode === null) {
                          await showLoader();
                          // post data attribute
                          const postAttributes = [];

                          Object.entries(attributesData).forEach(
                            async (attributeData) => {
                              const [Code, Value]: any[] = attributeData;

                              let attribute = {
                                Type: "Attribute",
                                Code,
                                Value:
                                  typeof Value === "object"
                                    ? Value?.value.toString() || null
                                    : typeof Value === "string" ||
                                      typeof Value === "boolean"
                                    ? Value
                                    : null,
                              };
                              postAttributes.push(attribute);
                            }
                          );

                          let postObj = {
                            MN: "EmployeeCentral",
                            IN: "EC_Form",
                            TN: formData.tn,
                            SN: section.SN,
                            TId: TId || 0,
                            UserId: userDetails.Id,
                            UserEmailId: userDetails.EmailId,
                            UserName: userDetails.FirstName,
                            RoleId: currentRoleId?.value,
                            Attributes: postAttributes,
                            //COU: COU,
                          };
                          const postRes = await APICall(
                            postAttributeValueDetailsV2,
                            "POST",
                            postObj
                          );

                          if (
                            postRes.data !== null &&
                            section.SN === "Basic" &&
                            postRes.data.id !== undefined
                          ) {
                            setTId(postRes.data.id);
                          }
                          

                          // if (
                          //   selectedFilesForFlat.length > 0 &&
                          //   sectionHasDocuments
                          // ) {
                          //   let newFiles = selectedFilesForFlat.filter((sf) => {
                          //     return sf.DocId === 0;
                          //   });
                          //   try {
                          //     const response = await APICall(
                          //       documentUpload,
                          //       "POST",
                          //       {
                          //         ModuleName: "EmployeeCentral",
                          //         files: newFiles,
                          //         SectionName: "Basic",
                          //         TabName: "Personal",
                          //         TId: postRes.data?.id ? postRes.data.id : TId,
                          //       }
                          //     );

                          //     if (
                          //       response.data === null ||
                          //       response.data === undefined
                          //     ) {
                          //       notify(1, "Failed to upload documents.");
                          //     } else {
                          //       setSelectedFilesForFlat([
                          //         ...selectedFilesForFlat,
                          //         response.data.files,
                          //       ]);
                          //     }
                          //   } catch (error) {
                          //     throw new Error(
                          //       "Error uploading documents: " + error.message
                          //     );
                          //   }
                          // }

                          notify(postRes.status, postRes.message);
                          await hideLoader();
                          
                        } else if (!isEmpty && attributeSetCode === null) {
                          //notify on flat attribute
                          notify(
                            1,
                            `${section.SDN} - Please check validation errors!`
                          );
                        } else if (
                          isEmpty &&
                          attributeSetCode !== null &&
                          Object.keys(attributesData).length === 0
                        ) {
                          await showLoader();
                          // post data attributeSet

                          let details = [...ogFilledAttributeSet];

                          const finalDetails = [];
                          details.forEach(async (attributes) => {
                            let attributeObj = {};
                            Object.keys(attributes).forEach(
                              async (attribute) => {
                                attributeObj = {
                                  ...attributeObj,
                                  [attribute]:
                                    typeof attributes[attribute] === "object"
                                      ? attributes[
                                          attribute
                                        ]?.value.toString() || null
                                      : typeof attributes[attribute] ===
                                          "string" ||
                                        typeof attributes[attribute] ===
                                          "boolean"
                                      ? attributes[attribute]
                                      : null,
                                };

                                if (
                                  attribute === "Id" &&
                                  isNaN(Number(attributes[attribute]))
                                ) {
                                  attributeObj = {
                                    ...attributeObj,
                                    [attribute]: 0,
                                  };
                                }
                              }
                            );
                            finalDetails.push(attributeObj);
                          });

                          let postObj = {
                            MN: "EmployeeCentral",
                            IN: "EC_Form",
                            TN: formData.tn,
                            SN: section.SN,
                            //COU: COU,
                            TId: TId || 0,
                            UserId: userDetails.Id,
                            UserEmailId: userDetails.EmailId,
                            UserName: userDetails.FirstName,
                            RoleId: currentRoleId?.value,
                            Attributes: [
                              {
                                Code: attributeSetCode,
                                Value: "",
                                Type: "AttributeSet",
                                Details: finalDetails,
                              },
                            ],
                          };

                          const postRes = await APICall(
                            postAttributeValueDetailsV2,
                            "POST",
                            postObj
                          );
                          // if (
                          //   selectedFilesForSet.length > 0 &&
                          //   sectionHasDocuments
                          // ) {
                          //   try {
                          //     if (postRes.data?.length > 0) {
                          //       selectedFilesForSet.forEach((f, index) => {
                          //         f.Id = postRes.data[index];
                          //       });
                          //     }
                          //     let newFiles = selectedFilesForSet.filter(
                          //       (sf) => {
                          //         return sf.DocId === 0;
                          //       }
                          //     );
                          //     const response = await APICall(
                          //       documentUpload,
                          //       "POST",
                          //       {
                          //         ModuleName: "EmployeeCentral",
                          //         files: newFiles,
                          //         SectionName: "Basic",
                          //         TabName: "Personal",
                          //         TId: postRes.data?.id ? postRes.data.id : TId,
                          //       }
                          //     );

                          //     if (
                          //       response.data === null ||
                          //       response.data === undefined
                          //     ) {
                          //       notify(1, "Failed to upload documents.");
                          //     } else {
                          //       setSelectedFilesForSet(response.data.files);
                          //     }
                          //   } catch (error) {
                          //     throw new Error(
                          //       "Error uploading documents: " + error.message
                          //     );
                          //   }
                          // }
                          await hideLoader();
                          notify(postRes.status, postRes.message);
                        } else if (
                          isEmpty &&
                          attributeSetCode !== null &&
                          Object.keys(attributesData).length > 0
                        ) {
                          await showLoader();
                          // post data attribute
                          const postAttributes = [];
                          const finalPostAttr = [];
                          Object.entries(attributesData).forEach(
                            async (attributeData) => {
                              const [Code, Value]: any[] = attributeData;

                              let attribute = {
                                Type: "Attribute",
                                Code,
                                Value:
                                  typeof Value === "object"
                                    ? Value?.value.toString() || null
                                    : typeof Value === "string" ||
                                      typeof Value === "boolean"
                                    ? Value
                                    : null,
                                Details: null,
                              };
                              postAttributes.push(attribute);
                            }
                          );
                          finalPostAttr.push(postAttributes);
                          let details = [...ogFilledAttributeSet];

                          const finalDetails = [];
                          details.forEach(async (attributes) => {
                            let attributeObj = {};
                            Object.keys(attributes).forEach(
                              async (attribute) => {
                                attributeObj = {
                                  ...attributeObj,
                                  [attribute]:
                                    typeof attributes[attribute] === "object"
                                      ? attributes[
                                          attribute
                                        ]?.value.toString() || null
                                      : typeof attributes[attribute] ===
                                          "string" ||
                                        typeof attributes[attribute] ===
                                          "boolean"
                                      ? attributes[attribute]
                                      : null,
                                };

                                if (
                                  attribute === "Id" &&
                                  isNaN(Number(attributes[attribute]))
                                ) {
                                  attributeObj = {
                                    ...attributeObj,
                                    [attribute]: 0,
                                  };
                                }
                              }
                            );
                            finalDetails.push(attributeObj);
                          });
                          postAttributes.push({
                            Code: attributeSetCode,
                            Value: "",
                            Type: "AttributeSet",
                            Details: finalDetails,
                          });
                          let postObjflat = {
                            MN: "EmployeeCentral",
                            IN: "EC_Form",
                            TN: formData.tn,
                            SN: section.SN,
                            TId: TId || 0,
                            //COU: COU,
                            UserId: userDetails.Id,
                            UserEmailId: userDetails.EmailId,
                            UserName: userDetails.FirstName,
                            RoleId: currentRoleId?.value,
                            Attributes: postAttributes,
                          };

                          const postResflat = await APICall(
                            postAttributeValueDetailsV2,
                            "POST",
                            postObjflat
                          );

                          notify(postResflat.status, postResflat.message);
                          setShowAttrSet(false);
                          await hideLoader();
                        }
                      }
                    }}>
                    <i className="fa fa-save"></i>
                  </button>
                </Tooltip>
              </div>
            </div>
          </AccordionSummary>

          <AccordionDetails className="">
            <div className="row">
              {section?.Attribute?.length > 0 &&
                section?.Attribute?.map((eachAttribute, index) => (
                  <React.Fragment key={`${index}-${eachAttribute.AC}`}>
                    {eachAttribute.AT === "Attribute" ? (
                      <>
                        {eachAttribute.DT === "DropdownSingle" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }>
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <SelectForm
                                  isClearable
                                  isSearchable
                                  options={
                                    optionsObj[eachAttribute.AC]
                                      ? optionsObj[eachAttribute.AC]
                                      : []
                                  }
                                  placeholder={eachAttribute.AN}
                                  isDisabled={
                                    attributeValidation[eachAttribute.AC]
                                      ?.isDisable || showAttr
                                  }
                                  value={
                                    attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : null
                                  }
                                  onChange={async (event) => {
                                    await setAttributesData((prev) => ({
                                      ...prev,
                                      [eachAttribute.AC]: event,
                                    }));
                                    if(eachAttribute.AC === "DEMOPY"){
                                      if(event != null &&  event.label === "Lumsum"){
                                        setInstallmentNo(1)
                                        //setInstallmentGrid(true);
                                      }
                                      else{
                                        //setInstallmentGrid(false);
                                        setInstallmentNo(0)
                                      }
                                    }
                                    if(eachAttribute.AC === "DEINSTALLMENTS"){
                                      if(event != null){
                                        setInstallmentNo(Number(event.label))
                                      }
                                      else{
                                        //setInstallmentGrid(false);
                                        setInstallmentNo(0)
                                      }
                                    }
                                    if (
                                      eachAttribute.CC !== null &&
                                      eachAttribute.CC !== ""
                                    ) {
                                      const CC = eachAttribute.CC.split(",");

                                      CC.forEach(async (childDropdown) => {
                                        setAttributesData((prev) => ({
                                          ...prev,
                                          [childDropdown]: null,
                                        }));

                          
                                        
                                      });
                                    }
                                    
                                    dependentValidations(
                                      event,
                                      eachAttribute.AC,
                                      "Attribute"
                                    );
                                  }}
                                  isMulti={false}
                                  noIndicator={false}
                                  noSeparator={false}
                                />
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "Text" ||
                          eachAttribute.DT === "Number" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }>
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <InputForm
                                  className="form-control"
                                  placeholder={eachAttribute.AN}
                                  isDisabled={
                                    attributeValidation[eachAttribute.AC]
                                      ?.isDisable ||
                                    showAttr 
                                      
                                  }
                                  textArea={false}
                                  value={
                                    attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : ""
                                  }
                                  onChange={(e) => {
                                    setAttributesData((prev) => ({
                                      ...prev,
                                      [eachAttribute.AC]: e.target.value,
                                    }));

                                    dependentValidations(
                                      e.target.value,
                                      eachAttribute.AC,
                                      "Attribute"
                                    );
                                  }}
                                  maxLength="255"
                                />
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "Date" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }>
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <DateForm
                                  isDisabled={
                                    attributeValidation[eachAttribute.AC]
                                      ?.isDisable ||
                                    showAttr 
                                  }
                                  value={
                                      attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : null
                                  }
                                  onChange={(date) => {
                                    setAttributesData((prev) => ({
                                      ...prev,
                                      [eachAttribute.AC]:
                                        date != null
                                          ? moment(date).format("DD-MMM-YYYY")
                                          : null,
                                    }));

                                    dependentValidations(
                                      moment(date).format("DD-MMM-YYYY"),
                                      eachAttribute.AC,
                                      "Attribute"
                                    );

                                    //hard code to calculate age on DOB
                                    if (eachAttribute.AC === "DOB" && date) {
                                      const ageInYears = moment().diff(
                                        date,
                                        "years"
                                      );

                                      setAttributesData((prev) => ({
                                        ...prev,
                                        AGE: ageInYears.toString(),
                                      }));
                                    }
                                  }}
                                />
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "Checkbox" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }>
                              <div className="mb-1">
                                <label
                                  htmlFor={eachAttribute.AC}
                                  className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <div>
                                  <input
                                    disabled={
                                      attributeValidation[eachAttribute.AC]
                                        ?.isDisable || showAttr
                                    }
                                    type="checkbox"
                                    onChange={(e) => {
                                      setAttributesData((prev) => ({
                                        ...prev,
                                        [eachAttribute.AC]: e.target.checked,
                                      }));

                                      dependentValidations(
                                        e.target.checked,
                                        eachAttribute.AC,
                                        "Attribute"
                                      );
                                    }}
                                    id={eachAttribute.AC}
                                    checked={
                                      attributesData[eachAttribute.AC]
                                        ? attributesData[eachAttribute.AC]
                                        : false
                                    }
                                  />
                                </div>
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "Textarea" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }>
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <InputForm
                                  className="form-control"
                                  placeholder={eachAttribute.AN}
                                  isDisabled={
                                    attributeValidation[eachAttribute.AC]
                                      ?.isDisable || showAttr
                                  }
                                  textArea={true}
                                  value={
                                    attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : ""
                                  }
                                  onChange={(e) => {
                                    setAttributesData((prev) => ({
                                      ...prev,
                                      [eachAttribute.AC]: e.target.value,
                                    }));

                                    dependentValidations(
                                      e.target.value,
                                      eachAttribute.AC,
                                      "Attribute"
                                    );
                                  }}
                                  maxLength="255"
                                />
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                         ) 
                         //: eachAttribute.DT === "Document" ? (
                        //   <>
                        //     <div
                        //       className={
                        //         JSON.parse(
                        //           eachAttribute.AD
                        //         ).IFR.toLowerCase() === "true"
                        //           ? "col-lg-12 col-sm-12 col-xs-12 "
                        //           : "col-lg-3 col-sm-3 col-xs-4"
                        //       }>
                        //       <div className="mb-1">
                        //         <label className="col-form-label">
                        //           {eachAttribute.AN}
                        //           {attributeValidation[
                        //             eachAttribute.AC
                        //           ]?.ISM.toLowerCase() === "true" && (
                        //             <sup>*</sup>
                        //           )}
                        //         </label>

                        //         <div className="box position-relative">
                        //           <input
                        //             id={`files-${sectionIndex}`}
                        //             className="form-control inputfile inputfile-6 multiple-inputfile"
                        //             data-multiple-caption="{count} files selected"
                        //             multiple={
                        //               JSON.parse(eachAttribute.V).FC > 1
                        //                 ? true
                        //                 : false
                        //             }
                        //             accept={JSON.parse(eachAttribute.V).FX}
                        //             type="file"
                        //             onChange={(e) => {
                        //               handleFileChangeForFlat(e, eachAttribute);
                        //             }}
                        //             value={""}
                        //           />
                        //           <label
                        //             htmlFor={`files-${sectionIndex}`}
                        //             className="form-control"
                        //             style={{ width: 0, border: "none" }}>
                        //             <strong
                        //               style={{
                        //                 padding: "6px 16px",
                        //                 backgroundColor: "#3c5464",
                        //                 borderRadius: "5px",
                        //               }}>
                        //               <i
                        //                 className="fa fa-upload rotate90"
                        //                 aria-hidden="true"></i>
                        //               {"  "}
                        //               Upload
                        //             </strong>{" "}
                        //           </label>
                        //         </div>
                        //         {formErrors[eachAttribute.AC] && (
                        //           <p style={{ color: "red" }}>
                        //             {formErrors[eachAttribute.AC]}
                        //           </p>
                        //         )}
                        //         <div className="file-added-list">
                        //           <ul className="list-unstyle">
                        //             {selectedFilesForFlat &&
                        //               selectedFilesForFlat.length > 0 &&
                        //               selectedFilesForFlat.map(
                        //                 (fileAttribute, attributeIndex) => (
                        //                   <>
                        //                     {fileAttribute.AC ===
                        //                       eachAttribute.AC && (
                        //                       <li
                        //                         className="list mt-1"
                        //                         key={attributeIndex}>
                        //                         <div className="media">
                        //                           <div className="media-body text-truncate">
                        //                             <span className="view-more">
                        //                               {fileAttribute.Name}
                        //                             </span>
                        //                           </div>
                        //                           {/*Here code for view same div of trash should be used. ISDOCVIEW/ISDOCDOWN*/}
                        //                           {/* {
                        //                             // section?.ISDOCVIEW &&
                        //                             fileAttribute.DocId !==
                        //                               0 && (
                        //                               <div className="media-right ml-2">
                        //                                 <i
                        //                                   className="fa fa-eye"
                        //                                   aria-hidden="true"
                        //                                   onClick={() =>
                        //                                     handleViewForFlat(
                        //                                       fileAttribute,
                        //                                       attributeIndex
                        //                                     )
                        //                                   }></i>
                        //                               </div>
                        //                             )
                        //                           } */}
                        //                           {
                        //                             //  section?.ISDOCDOWN &&
                        //                             <div className="media-right ml-2">
                        //                               <i
                        //                                 className="fa-solid fa-download"
                        //                                 aria-hidden="true"
                        //                                 onClick={() =>
                        //                                   handleDownloadFileFlat(
                        //                                     fileAttribute,
                        //                                     attributeIndex
                        //                                   )
                        //                                 }></i>
                        //                             </div>
                        //                           }
                        //                           {section?.ISEDIT &&
                        //                             fileAttribute.DocId !==
                        //                               0 && (
                        //                               <div className="media-right ml-2">
                        //                                 <i
                        //                                   className="fa fa-trash"
                        //                                   aria-hidden="true"
                        //                                   onClick={() =>
                        //                                     handleDeleteFileForFlat(
                        //                                       fileAttribute,
                        //                                       attributeIndex
                        //                                     )
                        //                                   }></i>
                        //                               </div>
                        //                             )}
                        //                           0 && (
                        //                           <div className="media-right ml-2">
                        //                             <i
                        //                               className="fa fa-trash"
                        //                               aria-hidden="true"
                        //                               onClick={() =>
                        //                                 handleDeleteFileForFlat(
                        //                                   fileAttribute,
                        //                                   attributeIndex
                        //                                 )
                        //                               }></i>
                        //                           </div>
                        //                           )
                        //                         </div>
                        //                       </li>
                        //                     )}
                        //                   </>
                        //                 )
                        //               )}
                        //           </ul>
                        //         </div>
                        //       </div>
                        //     </div>
                        //   </>
                        // ) 
                        : eachAttribute.DT === "SearchableDropdownSingle" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                )?.IFR?.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }>
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM?.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <SelectForm
                                  isClearable
                                  isSearchable
                                  async
                                  options={(searchString, cb) =>
                                    getSearchableDropdownAPI(
                                      searchString,
                                      eachAttribute.AC,
                                      cb,
                                      "",
                                      "attribute",
                                      0
                                    )
                                  }
                                  placeholder={eachAttribute.AN}
                                  isDisabled={
                                    attributeValidation[eachAttribute.AC]
                                      ?.isDisable ||
                                    !section.ISEDIT 
                                  }
                                  value={
                                    attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : null
                                  }
                                  onChange={async (event) => {
                                    setAttributesData((prev) => ({
                                      ...prev,
                                      [eachAttribute.AC]: event,
                                    }));

                                    if (
                                      eachAttribute.CC !== null &&
                                      eachAttribute.CC !== ""
                                    ) {
                                      const CC = eachAttribute.CC.split(",");

                                      CC.forEach(async (childDropdown) => {
                                        setAttributesData((prev) => ({
                                          ...prev,
                                          [childDropdown]: null,
                                        }));

                                        const options = await getDropdownsData(
                                          childDropdown,
                                          event ? event.value : ""
                                        );

                                        setOptionsObj((prev) => ({
                                          ...prev,
                                          [childDropdown]: options,
                                        }));
                                      });
                                    }

                                    dependentValidations(
                                      event,
                                      eachAttribute.AC,
                                      "Attribute"
                                    );

                                    //searchable DD
                                    if (event) {
                                      searchableDDValues(
                                        event,
                                        eachAttribute.AC
                                      );
                                    }
                                  }}
                                  isMulti={false}
                                  noIndicator={false}
                                  noSeparator={false}
                                />
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "DocumentLink" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }>
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                </label>

                                <ul className="list-unstyle">
                                  <li className="list mt-1">
                                    <a
                                      className="link-primary"
                                      href={require(`./${
                                        JSON.parse(eachAttribute.AD)?.FileName
                                      }`)}
                                      download={`${
                                        JSON.parse(eachAttribute.AD)?.FileName
                                      }`}
                                      //target="_blank"
                                    >
                                      <p className="policylinks">
                                        {JSON.parse(eachAttribute.AD)?.URLN}
                                      </p>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        
                      </>
                      
                    ) : eachAttribute.AT === "AttributeSet" &&
                      eachAttribute.DT === "Custom" ? (
                      <div className="col-lg-12">
                        {section?.ISEDIT && (
                          <div className="col-lg-12 mt-2 mb-3 d-flex justify-content-end">
                            <Tooltip title="Add">
                              <button
                                id={"addRec"}
                                className="btn btn-primary"
                                onClick={() => {
                                  if (ShowAttrSet) {
                                    setAttributeSetData((prev) => ({
                                      ...prev,
                                      ...ogAttributeSet,
                                    }));
                                    setCurrentGridId(null);
                                    setViewOnly(false);
                                  }
                                  setShowAttrSet(!ShowAttrSet);
                                }}>
                                <i className={"fa fa-plus"}></i> Add
                              </button>
                            </Tooltip>
                          </div>
                        )}
                        {ShowAttrSet && (
                          <div className="row">
                            {eachAttribute.Attribute.length > 0 &&
                              eachAttribute.Attribute.map(
                                (subAttribute, index) => (
                                  <React.Fragment
                                    key={`${index}-${subAttribute.AC}`}>
                                    {subAttribute.DT === "DropdownSingle" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }>
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {attributeSetValidation[
                                                subAttribute.AC
                                              ]?.ISM.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <SelectForm
                                              isClearable
                                              isSearchable
                                              options={
                                                optionsObj[subAttribute.AC]
                                                  ? optionsObj[subAttribute.AC]
                                                  : []
                                              }
                                             
                                              placeholder={subAttribute.AN}
                                              isDisabled={
                                                showAttr ||
                                                viewOnly ||
                                                attributeSetValidation[
                                                  subAttribute.AC
                                                ]?.isDisable
                                              }
                                              value={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : null
                                              }
                                              onChange={async (event) => {
                                                setAttributeSetData((prev) => ({
                                                  ...prev,
                                                  [subAttribute.AC]: event,
                                                }));
                                                if(subAttribute.AC === "DEMOPY"){
                                                if(event != null &&  event.label === "Installments"){
                                                  setInstallmentGrid(true);
                                                }
                                                else{
                                                  setInstallmentGrid(false);
                                                }
                                              }
                                              if(subAttribute.AC === "DEINSTALLMENTS"){
                                                if(event != null){
                                                  setInstallmentNo(Number(event.label))
                                                }
                                                else{
                                                  setInstallmentGrid(false);
                                                  setInstallmentNo(0)
                                                }
                                              }
                                                if (
                                                  subAttribute.CC !== null &&
                                                  subAttribute.CC !== ""
                                                ) {
                                                  const CC =
                                                    subAttribute.CC.split(",");

                                                  CC.forEach(
                                                    async (childDropdown) => {
                                                      setAttributeSetData(
                                                        (prev) => ({
                                                          ...prev,
                                                          [childDropdown]: null,
                                                        })
                                                      );

                                                      const options =
                                                        await getDropdownsData(
                                                          childDropdown,
                                                          event
                                                            ? event.value
                                                            : ""
                                                        );

                                                      setOptionsObj((prev) => ({
                                                        ...prev,
                                                        [childDropdown]:
                                                          options,
                                                      }));
                                                    }
                                                  );
                                                }
                                                dependentValidations(
                                                  event,
                                                  subAttribute.AC,
                                                  "Attributeset"
                                                );
                                              }}
                                              isMulti={false}
                                              noIndicator={false}
                                              noSeparator={false}
                                            />
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT === "Text" ||
                                      subAttribute.DT === "Number" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }>
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {attributeSetValidation[
                                                subAttribute.AC
                                              ]?.ISM.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <InputForm
                                              className="form-control"
                                              placeholder={subAttribute.AN}
                                              isDisabled={
                                                showAttr ||
                                                viewOnly ||
                                                attributeSetValidation[
                                                      subAttribute.AC
                                                    ]?.isDisable
                                              }
                                              textArea={false}
                                              value={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : ""
                                              }
                                              onChange={(e) => {
                                                setAttributeSetData((prev) => ({
                                                  ...prev,
                                                  [subAttribute.AC]:
                                                    subAttribute.AC === "EA"
                                                      ? e.target.value.toLowerCase()
                                                      : e.target.value,
                                                }));
                                              }}
                                              maxLength="255"
                                            />
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT === "MonthYear" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }>
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {attributeSetValidation[
                                                subAttribute.AC
                                              ]?.ISM.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <DateForm
                                              isDisabled={
                                                showAttr ||
                                                viewOnly ||
                                                attributeSetValidation[
                                                  subAttribute.AC
                                                ]?.isDisable
                                              }
                                              views={["month", "year"]}
                                              format="MMM/yyyy"
                                              value={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : null
                                              }
                                              onChange={(date) => {
                                                setAttributeSetData((prev) => ({
                                                  ...prev,
                                                  [subAttribute.AC]:
                                                    date != null
                                                      ? moment(date).format(
                                                          "DD-MMM-YYYY"
                                                        )
                                                      : null,
                                                }));

                                              
                                              }}
                                            />
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT === "Date" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }>
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {/* {JSON.parse(
                                              subAttribute.V
                                            ).ISM.toLowerCase() === "true" && (
                                              <sup>*</sup>
                                            )} */}
                                              {attributeSetValidation[
                                                subAttribute.AC
                                              ]?.ISM.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <DateForm
                                              isDisabled={
                                                showAttr ||
                                                viewOnly ||
                                                attributeSetValidation[
                                                      subAttribute.AC
                                                    ]?.isDisable
                                              }
                                              value={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : null
                                              }
                                              onChange={(date) => {
                                                setAttributeSetData((prev) => ({
                                                  ...prev,
                                                  [subAttribute.AC]:
                                                    date != null
                                                      ? moment(date).format(
                                                          "DD-MMM-YYYY"
                                                        )
                                                      : null,
                                                }));

                                               
                                                
                                              }}
                                            />
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT === "Checkbox" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }>
                                          <div className="mb-1">
                                            <label
                                              htmlFor={subAttribute.AC}
                                              className="col-form-label">
                                              {subAttribute.AN}
                                              {attributeSetValidation[
                                                subAttribute.AC
                                              ]?.ISM.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <div>
                                              <input
                                                disabled={
                                                  showAttr ||
                                                  viewOnly ||
                                                  attributeSetValidation[
                                                    subAttribute.AC
                                                  ]?.isDisable
                                                }
                                                type="checkbox"
                                                onChange={(e) => {
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      [subAttribute.AC]:
                                                        e.target.checked,
                                                    })
                                                  );
                                                }}
                                                id={subAttribute.AC}
                                                checked={
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ]
                                                    ? attributeSetData[
                                                        subAttribute.AC
                                                      ]
                                                    : false
                                                }
                                              />
                                            </div>
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) 
                                    // : subAttribute.DT === "Document" ? (
                                    //   <>
                                    //     <div
                                    //       className={
                                    //         JSON.parse(
                                    //           subAttribute.AD
                                    //         ).IFR.toLowerCase() === "true"
                                    //           ? "col-lg-12 col-sm-12 col-xs-12 "
                                    //           : "col-lg-3 col-sm-3 col-xs-4"
                                    //       }>
                                    //       <div className="mb-1">
                                    //         <label className="col-form-label">
                                    //           {subAttribute.AN}
                                    //           {attributeSetValidation[
                                    //             subAttribute.AC
                                    //           ]?.ISM.toLowerCase() ===
                                    //             "true" && <sup>*</sup>}
                                    //         </label>
                                    //         <div className="box position-relative">
                                    //           <input
                                    //             id={`files-${sectionIndex}`}
                                    //             disabled={
                                    //               showAttr ||
                                    //               viewOnly ||
                                    //               attributeSetValidation[
                                    //                 subAttribute.AC
                                    //               ]?.isDisable
                                    //             }
                                    //             className="form-control inputfile inputfile-6 multiple-inputfile"
                                    //             data-multiple-caption="{count} files selected"
                                    //             multiple={
                                    //               JSON.parse(subAttribute.V)
                                    //                 .FC > 1
                                    //                 ? true
                                    //                 : false
                                    //             }
                                    //             accept={
                                    //               JSON.parse(subAttribute.V).FX
                                    //             }
                                    //             type="file"
                                    //             onChange={(e) => {
                                    //               handleFileChangeForSet(
                                    //                 e,
                                    //                 subAttribute
                                    //               );
                                    //             }}
                                    //             value={""}
                                    //           />
                                    //           <label
                                    //             htmlFor={`files-${sectionIndex}`}
                                    //             className="form-control"
                                    //             style={{
                                    //               width: 0,
                                    //               border: "none",
                                    //             }}>
                                    //             <strong
                                    //               style={{
                                    //                 padding: "6px 16px",
                                    //                 backgroundColor: "#3c5464",
                                    //                 borderRadius: "5px",
                                    //               }}>
                                    //               <i
                                    //                 className="fa fa-upload rotate90"
                                    //                 aria-hidden="true"></i>
                                    //               {"  "}
                                    //               Upload
                                    //             </strong>{" "}
                                    //           </label>
                                    //         </div>
                                    //         {formErrors[subAttribute.AC] && (
                                    //           <p style={{ color: "red" }}>
                                    //             {formErrors[subAttribute.AC]}
                                    //           </p>
                                    //         )}
                                    //         <div className="file-added-list">
                                    //           <ul className="list-unstyle">
                                    //             {selectedFilesForSet &&
                                    //               selectedFilesForSet.length >
                                    //                 0 &&
                                    //               selectedFilesForSet.map(
                                    //                 (
                                    //                   fileAttribute,
                                    //                   attributeIndex
                                    //                 ) => (
                                    //                   <>
                                    //                     {fileAttribute.AC ===
                                    //                       subAttribute.AC &&
                                    //                       (fileAttribute.Id ===
                                    //                         0 ||
                                    //                         fileAttribute.Id ==
                                    //                           currentGridId) && (
                                    //                         <li
                                    //                           className="list mt-1"
                                    //                           key={
                                    //                             attributeIndex
                                    //                           }>
                                    //                           <div className="media">
                                    //                             <div className="media-body text-truncate">
                                    //                               <span className="view-more">
                                    //                                 {
                                    //                                   fileAttribute.Name
                                    //                                 }
                                    //                               </span>
                                    //                             </div>
                                    //                             {/*Here code for view same div of trash should be used. ISDOCVIEW/ISDOCDOWN*/}
                                    //                             {/* {
                                    //                               // section?.ISDOCVIEW &&
                                    //                               fileAttribute.DocId !==
                                    //                                 0 && (
                                    //                                 <div className="media-right ml-2">
                                    //                                   <i
                                    //                                     className="fa fa-eye"
                                    //                                     aria-hidden="true"
                                    //                                     onClick={() =>
                                    //                                       handleViewForFlat(
                                    //                                         fileAttribute,
                                    //                                         attributeIndex
                                    //                                       )
                                    //                                     }></i>
                                    //                                 </div>
                                    //                               )
                                    //                             } */}

                                    //                             {
                                    //                               // section?.ISDOCDOWN &&
                                    //                               <div className="media-right ml-2">
                                    //                                 <i
                                    //                                   className="fa-solid fa-download"
                                    //                                   aria-hidden="true"
                                    //                                   onClick={() =>
                                    //                                     handleDownloadFileFlat(
                                    //                                       fileAttribute,
                                    //                                       attributeIndex
                                    //                                     )
                                    //                                   }></i>
                                    //                               </div>
                                    //                             }
                                    //                             {section?.ISEDIT &&
                                    //                               viewOnly ===
                                    //                                 false &&
                                    //                               fileAttribute.DocId !==
                                    //                                 0 && (
                                    //                                 <div className="media-right ml-2">
                                    //                                   <i
                                    //                                     className="fa fa-trash"
                                    //                                     aria-hidden="true"
                                    //                                     onClick={() =>
                                    //                                       handleDeleteFileForSet(
                                    //                                         fileAttribute,
                                    //                                         attributeIndex
                                    //                                       )
                                    //                                     }></i>
                                    //                                 </div>
                                    //                               )}
                                    //                           </div>
                                    //                         </li>
                                    //                       )}
                                    //                   </>
                                    //                 )
                                    //               )}
                                    //           </ul>
                                    //         </div>
                                    //       </div>
                                    //     </div>
                                    //   </>
                                    // ) 
                                    : subAttribute.DT ===
                                      "SearchableDropdownSingle" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }>
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {attributeSetValidation[
                                                subAttribute.AC
                                              ]?.ISM.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <SelectForm
                                              isClearable
                                              isSearchable
                                              async
                                              options={(searchString, cb) =>
                                                getSearchableDropdownAPI(
                                                  searchString,
                                                  subAttribute.AC,
                                                  cb,
                                                  "",
                                                  "attributeSet",
                                                  0
                                                )
                                              }
                                              placeholder={subAttribute.AN}
                                              isDisabled={
                                                !section.ISEDIT ||
                                                viewOnly ||
                                                attributeSetValidation[
                                                  eachAttribute.AC
                                                ]?.isDisable
                                              }
                                              value={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : null
                                              }
                                              onChange={(event) => {
                                                setAttributeSetData((prev) => ({
                                                  ...prev,
                                                  [subAttribute.AC]: event,
                                                }));

                                                if (
                                                  subAttribute.CC !== null &&
                                                  subAttribute.CC !== ""
                                                ) {
                                                  const CC =
                                                    subAttribute.CC.split(",");

                                                  CC.forEach(
                                                    async (childDropdown) => {
                                                      setAttributeSetData(
                                                        (prev) => ({
                                                          ...prev,
                                                          [childDropdown]: null,
                                                        })
                                                      );

                                                      const options =
                                                        await getDropdownsData(
                                                          childDropdown,
                                                          event
                                                            ? event.value
                                                            : ""
                                                        );

                                                      setOptionsObj((prev) => ({
                                                        ...prev,
                                                        [childDropdown]:
                                                          options,
                                                      }));
                                                    }
                                                  );
                                                }

                                                // dependentValidations(
                                                //   event,
                                                //   subAttribute.AC
                                                // );

                                                //searchable DD
                                                if (event) {
                                                  searchableDDValuesSet(
                                                    event,
                                                    subAttribute.AC
                                                  );
                                                }
                                              }}
                                              isMulti={false}
                                              noIndicator={false}
                                              noSeparator={false}
                                            />
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT ===
                                      "DropdownMultiple" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }>
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {JSON.parse(
                                                subAttribute.V
                                              ).ISM.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <SelectForm
                                              isClearable
                                              isSearchable
                                              options={
                                                optionsObj[subAttribute.AC]
                                                  ? optionsObj[subAttribute.AC]
                                                  : []
                                              }
                                              isMulti={multiDropdown}
                                              placeholder={subAttribute.AN}
                                              isDisabled={
                                                !section.ISEDIT || viewOnly
                                              }
                                              value={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : null
                                              }
                                              onChange={(event) => {
                                                setAttributeSetData((prev) => ({
                                                  ...prev,
                                                  [subAttribute.AC]: event,
                                                }));

                                                if (
                                                  event !== null &&
                                                  subAttribute.CC !== null &&
                                                  subAttribute.CC !== ""
                                                ) {
                                                  const CC =
                                                    subAttribute.CC.split(",");

                                                  CC.forEach(
                                                    async (childDropdown) => {
                                                      setAttributeSetData(
                                                        (prev) => ({
                                                          ...prev,
                                                          [childDropdown]: null,
                                                        })
                                                      );

                                                      const options =
                                                        await getDropdownsData(
                                                          childDropdown,
                                                          event.value
                                                        );

                                                      setOptionsObj((prev) => ({
                                                        ...prev,
                                                        [childDropdown]:
                                                          options,
                                                      }));
                                                    }
                                                  );
                                                }
                                              }}
                                              noIndicator={false}
                                              noSeparator={false}
                                            />
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT ===
                                      "DropdownMultiple" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }>
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {JSON.parse(
                                                subAttribute.V
                                              ).ISM.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <SelectForm
                                              isClearable
                                              isSearchable
                                              options={
                                                optionsObj[subAttribute.AC]
                                                  ? optionsObj[subAttribute.AC]
                                                  : []
                                              }
                                              isMulti={multiDropdown}
                                              placeholder={subAttribute.AN}
                                              isDisabled={
                                                !section.ISEDIT || viewOnly
                                              }
                                              value={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : null
                                              }
                                              onChange={(event) => {
                                                setAttributeSetData((prev) => ({
                                                  ...prev,
                                                  [subAttribute.AC]: event,
                                                }));
                                                if (
                                                  event !== null &&
                                                  subAttribute.CC !== null &&
                                                  subAttribute.CC !== ""
                                                ) {
                                                  const CC =
                                                    subAttribute.CC.split(",");

                                                  CC.forEach(
                                                    async (childDropdown) => {
                                                      setAttributeSetData(
                                                        (prev) => ({
                                                          ...prev,
                                                          [childDropdown]: null,
                                                        })
                                                      );

                                                      const options =
                                                        await getDropdownsData(
                                                          childDropdown,
                                                          event.value
                                                        );

                                                      setOptionsObj((prev) => ({
                                                        ...prev,
                                                        [childDropdown]:
                                                          options,
                                                      }));
                                                    }
                                                  );
                                                }
                                              }}
                                              noIndicator={false}
                                              noSeparator={false}
                                            />
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT === "DocumentLink" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }>
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                            </label>
                                            <ul className="list-unstyle">
                                              <li className="list mt-1">
                                                <a
                                                  className="link-primary"
                                                  href={
                                                    JSON.parse(subAttribute.AD)
                                                      ?.URLV
                                                  }
                                                  target="_blank">
                                                  {
                                                    JSON.parse(subAttribute.AD)
                                                      ?.URLN
                                                  }
                                                </a>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        
                                      </>
                                    )}
                                   
                                  </React.Fragment>
                                )
                              )}
                              <>
                              {InstallmentNo > 0 &&
                                    InstallmentData.length> 0 && InstallmentData.map((el,index)=>
                                    (<>
                                      <div className="col-lg-3 col-sm-3 col-xs-4">
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              Pay Component
                                              <sup>*</sup>
                                            </label>

                                            <SelectForm
                                              isClearable
                                              isSearchable
                                              key={`DEPAYCOMP${index+1}`}
                                              options={
                                                optionsObj["DEPYCMNT"]
                                                  ? optionsObj["DEPYCMNT"]
                                                  : []
                                              }
                                             
                                              placeholder={"Pay Component"}
                                              isDisabled={false}
                                              value={
                                                 Object.keys(InstallmentData[index].PayComponent).length > 0
                                                  ? InstallmentData[index].PayComponent
                                                  : null
                                              }
                                              onChange={async (event) => {
                                                setInstallmentData(prevData => prevData.map((item, idx) => {
                                                  if (idx === index) {
                                                    return {
                                                      ...item,
                                                      PayComponent: event
                                                    };
                                                  }
                                                  return item;
                                                }));
                                              }}
                                              
                                                
                                              isMulti={false}
                                              noIndicator={false}
                                              noSeparator={false}
                                            />
                                            {InstallmentErrors.length> 0 ? InstallmentErrors[index]["PayComponent"] && (
                                              <p style={{ color: "red" }}>
                                                {InstallmentErrors[index]["PayComponent"]}
                                              </p>
                                            ):""}
                                          </div>
                                          </div>
                                          <div className="col-lg-3 col-sm-3 col-xs-4">
                                            <div className="mb-1">
                                              <label className="col-form-label">
                                                Due Date
                                                <sup>*</sup>
                                              </label>

                                            <DateForm
                                              isDisabled={false}
                                              value={
                                                InstallmentData[index].DueDate
                                                  ? InstallmentData[index]?.DueDate
                                                  : null
                                              }
                                              onChange={(date) => {
                                                setInstallmentData(prevData => prevData.map((item, idx) => {
                                                  if (idx === index) {
                                                    return {
                                                      ...item,
                                                      DueDate: date
                                                    };
                                                  }
                                                  return item;
                                                }));
                                              }}
                                            />
                                            {InstallmentErrors.length> 0 ?InstallmentErrors[index]["DueDate"] && (
                                              <p style={{ color: "red" }}>
                                                {InstallmentErrors[index]["DueDate"]}
                                              </p>
                                            ):""}
                                          </div>                                          
                                        </div>
                                        <div className="col-lg-3 col-sm-3 col-xs-4">
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                                Pay Out Amount
                                               <sup>*</sup>
                                            </label>

                                            <InputForm
                                              className="form-control"
                                              placeholder={"Pay Out Amount"}
                                              isDisabled={false}
                                              textArea={false}
                                              value={
                                                InstallmentData[index].PayOutAmount
                                                  ?  InstallmentData[index]?.PayOutAmount
                                                  : ""
                                              }
                                              onChange={(e) => {
                                                setInstallmentData(prevData => prevData.map((item, idx) => {
                                                  if (idx === index) {
                                                    return {
                                                      ...item,
                                                      PayOutAmount: e.target.value
                                                    };
                                                  }
                                                  return item;
                                                }));
                                              }}
                                              maxLength="255"
                                            />
                                            {InstallmentErrors.length> 0 ?InstallmentErrors[index]["PayOutAmount"] && (
                                              <p style={{ color: "red" }}>
                                                {InstallmentErrors[index]["PayOutAmount"]}
                                              </p>
                                            ):""}
                                          </div>
                                        </div>
                                        <div className="col-lg-3 col-sm-3 col-xs-4">
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              Currency
                                              <sup>*</sup>
                                            </label>

                                            <SelectForm
                                              isClearable
                                              isSearchable
                                              options={
                                                optionsObj["DECURNCY"]
                                                  ? optionsObj["DECURNCY"]
                                                  : []
                                              }
                                             
                                              placeholder={"Currency"}
                                              isDisabled={false}
                                              value={
                                                Object.keys(InstallmentData[index].Currency).length >0 
                                                  ? InstallmentData[index].Currency
                                                  : null
                                              }
                                              onChange={async (event) => {
                                                setInstallmentData(prevData => prevData.map((item, idx) => {
                                                  if (idx === index) {
                                                    return {
                                                      ...item,
                                                      Currency: event
                                                    };
                                                  }
                                                  return item;
                                                }));
                                              }}
                                              
                                                
                                              isMulti={false}
                                              noIndicator={false}
                                              noSeparator={false}
                                            />
                                            {InstallmentErrors.length> 0 ?InstallmentErrors[index]["Currency"] && (
                                              <p style={{ color: "red" }}>
                                                {InstallmentErrors[index]["Currency"]}
                                              </p>
                                            ):""}
                                          </div>
                                          </div>
                                    </>))}
                              </>
                              
                                    
                                    
                              
                          </div>
                        )}
                        
                        <div className="row">
                          {ShowAttrSet && (
                            <div className="mb-2">
                              <button
                                className="btn btn-secondary ml-3"
                                onClick={() => {
                                  if (ShowAttrSet) {
                                    setAttributeSetData((prev) => ({
                                      ...prev,
                                      ...ogAttributeSet,
                                    }));
                                    setFormErrors({});
                                    setCurrentGridId(null);
                                  }
                                }}>
                                Cancel
                              </button>
                              {section?.ISEDIT && viewOnly === false && (
                                <button
                                  id={currentGridId === null ? "0" : "1"}
                                  className="btn btn-primary ml-2"
                                  disabled={!section.ISEDIT}
                                  onClick={async (e) => {
                                    const targetId = e.currentTarget.id;

                                    if (targetId === "0") {
                                      //insert
                                        const uniqueId = uuid();

                                        //validation for empty data before adding in grid
                                        //
                                        const isError = validateData(
                                          eachAttribute.Attribute
                                        );
                                        if(attributeSetData["DEMOPY"].label.toLowerCase() ==="installments"){
                                          const isInstallmentsFilled = ValidateInstallments();
                                        }

                                        
                                        //}
                                        if (
                                          !isError 
                                        ) {
                                          //add record in grid
                                          setOgFilledAttributeSet((prev) => [
                                            ...prev,
                                            {
                                              ...attributeSetData,
                                              Id: uniqueId,
                                              IsActive: true,
                                            },
                                          ]);

                                          let gridData = {};

                                          eachAttribute.Attribute.forEach(
                                            async (subAttribute) => {
                                              if (
                                                typeof attributeSetData[
                                                  subAttribute.AC
                                                ] === "object"
                                              ) {
                                                gridData = {
                                                  ...gridData,
                                                  [subAttribute.AC]:
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ]?.label || null, //for date and dropdown
                                                };
                                              } else if (
                                                typeof attributeSetData[
                                                  subAttribute.AC
                                                ] === "boolean"
                                              ) {
                                                gridData = {
                                                  ...gridData,
                                                  [subAttribute.AC]:
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ] === true
                                                      ? "true"
                                                      : "false",
                                                };
                                              } else {
                                                gridData = {
                                                  ...gridData,
                                                  [subAttribute.AC]:
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ],
                                                };
                                              }
                                            }
                                          );

                                          //reset
                                          setAttributeSetData((prev) => ({
                                            ...prev,
                                            ...ogAttributeSet,
                                          }));

                                          setFormErrors({});

                                          setNewGridIdCreated(uniqueId); //send uniqueId for document

                                          setGridData((prev) => [
                                            ...prev,
                                            {
                                              Id: uniqueId,
                                              ...gridData,
                                              IsActive: true,
                                            },
                                          ]);

                                          setDisplayGrid((prev) => [
                                            ...prev,
                                            {
                                              Id: uniqueId,
                                              ...gridData,
                                              IsActive: true,
                                            },
                                          ]);
                                        }
                                        //}
                                      
                                    } else {
                                      //update

                                      //validation for empty data before updating in grid
                                      const isError = validateData(
                                        eachAttribute.Attribute
                                      );
                                      if (!isError) {
                                        
                                        

                                        if (
                                          !isError 
                                        ) {
                                          //update
                                         
                                          setOgFilledAttributeSet((prev) => {
                                            const newState = prev.map(
                                              (record) => {
                                                if (
                                                  record.Id === currentGridId
                                                ) {
                                                  return {
                                                    ...attributeSetData,
                                                  };
                                                }
                                                return record;
                                              }
                                            );
                                            return newState;
                                          });

                                          let gridData = {};

                                          eachAttribute.Attribute.forEach(
                                            async (subAttribute) => {
                                              if (
                                                typeof attributeSetData[
                                                  subAttribute.AC
                                                ] === "object"
                                              ) {
                                                gridData = {
                                                  ...gridData,
                                                  [subAttribute.AC]:
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ]?.label || null, //for date and dropdown
                                                };
                                              } else if (
                                                typeof attributeSetData[
                                                  subAttribute.AC
                                                ] === "boolean"
                                              ) {
                                                gridData = {
                                                  ...gridData,
                                                  [subAttribute.AC]:
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ] === true
                                                      ? "true"
                                                      : "false",
                                                };
                                              } else if (
                                                typeof attributeSetData[
                                                  subAttribute.AC
                                                ] === "string" &&
                                                moment(
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ],
                                                  "DD-MMM-YYYY",
                                                  true
                                                ).isValid()
                                              ) {
                                                const formattedDate = moment(
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ],
                                                  "DD-MMM-YYYY"
                                                ).format("DD/MM/YYYY");

                                                gridData = {
                                                  ...gridData,
                                                  [subAttribute.AC]:
                                                    formattedDate,
                                                };
                                              } else {
                                                gridData = {
                                                  ...gridData,
                                                  [subAttribute.AC]:
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ],
                                                };
                                              }
                                            }
                                          );

                                          //reset
                                          setAttributeSetData((prev) => ({
                                            ...prev,
                                            ...ogAttributeSet,
                                          }));

                                          setFormErrors({});

                                          setGridData((prev) => {
                                            const newState = prev.map(
                                              (record) => {
                                                if (
                                                  record.Id === currentGridId
                                                ) {
                                                  return {
                                                    Id: currentGridId,
                                                    ...gridData,
                                                  };
                                                }
                                                return record;
                                              }
                                            );
                                            return newState;
                                          });

                                          setDisplayGrid((prev) => {
                                            const newState = prev.map(
                                              (record) => {
                                                if (
                                                  record.Id === currentGridId
                                                ) {
                                                  return {
                                                    Id: currentGridId,
                                                    ...gridData,
                                                  };
                                                }
                                                return record;
                                              }
                                            );
                                            return newState;
                                          });

                                          setCurrentGridId(null);
                                        }
                                      }
                                    }
                                  }}>
                                  {currentGridId === null
                                    ? "Save"
                                    : "Update Record"}
                                </button>
                              )}
                            </div>
                          )}

                          <div className="col-lg-12 p-0 mb-3">
                            <DynamicGrid
                              options={gridOptions}
                              data={displayGrid}
                              columns={gridColumns}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </React.Fragment>
                ))}
                </div>
                <>
                <div className="row">
                              {InstallmentNo > 0 &&
                                    InstallmentData.length> 0 && InstallmentData.map((el,index)=>
                                    (<>
                                      <div className="col-lg-3 col-sm-3 col-xs-4">
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              Pay Component
                                              <sup>*</sup>
                                            </label>

                                            <SelectForm
                                              isClearable
                                              isSearchable
                                              key={`DEPAYCOMP${index+1}`}
                                              options={
                                                optionsObj["DEPYCMNT"]
                                                  ? optionsObj["DEPYCMNT"]
                                                  : []
                                              }
                                             
                                              placeholder={"Pay Component"}
                                              isDisabled={false}
                                              value={
                                                 Object.keys(InstallmentData[index].PayComponent).length > 0
                                                  ? InstallmentData[index].PayComponent
                                                  : null
                                              }
                                              onChange={async (event) => {
                                                setInstallmentData(prevData => prevData.map((item, idx) => {
                                                  if (idx === index) {
                                                    return {
                                                      ...item,
                                                      PayComponent: event
                                                    };
                                                  }
                                                  return item;
                                                }));
                                              }}
                                              
                                                
                                              isMulti={false}
                                              noIndicator={false}
                                              noSeparator={false}
                                            />
                                            {InstallmentErrors.length> 0 ? InstallmentErrors[index]["PayComponent"] && (
                                              <p style={{ color: "red" }}>
                                                {InstallmentErrors[index]["PayComponent"]}
                                              </p>
                                            ):""}
                                          </div>
                                          </div>
                                          <div className="col-lg-3 col-sm-3 col-xs-4">
                                            <div className="mb-1">
                                              <label className="col-form-label">
                                                Due Date
                                                <sup>*</sup>
                                              </label>

                                            <DateForm
                                              isDisabled={false}
                                              value={
                                                InstallmentData[index].DueDate
                                                  ? InstallmentData[index]?.DueDate
                                                  : null
                                              }
                                              onChange={(date) => {
                                                setInstallmentData(prevData => prevData.map((item, idx) => {
                                                  if (idx === index) {
                                                    return {
                                                      ...item,
                                                      DueDate: date
                                                    };
                                                  }
                                                  return item;
                                                }));
                                              }}
                                            />
                                            {InstallmentErrors.length> 0 ?InstallmentErrors[index]["DueDate"] && (
                                              <p style={{ color: "red" }}>
                                                {InstallmentErrors[index]["DueDate"]}
                                              </p>
                                            ):""}
                                          </div>                                          
                                        </div>
                                        <div className="col-lg-3 col-sm-3 col-xs-4">
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                                Pay Out Amount
                                               <sup>*</sup>
                                            </label>

                                            <InputForm
                                              className="form-control"
                                              placeholder={"Pay Out Amount"}
                                              isDisabled={false}
                                              textArea={false}
                                              value={
                                                InstallmentData[index].PayOutAmount
                                                  ?  InstallmentData[index]?.PayOutAmount
                                                  : ""
                                              }
                                              onChange={(e) => {
                                                setInstallmentData(prevData => prevData.map((item, idx) => {
                                                  if (idx === index) {
                                                    return {
                                                      ...item,
                                                      PayOutAmount: e.target.value
                                                    };
                                                  }
                                                  return item;
                                                }));
                                              }}
                                              maxLength="255"
                                            />
                                            {InstallmentErrors.length> 0 ?InstallmentErrors[index]["PayOutAmount"] && (
                                              <p style={{ color: "red" }}>
                                                {InstallmentErrors[index]["PayOutAmount"]}
                                              </p>
                                            ):""}
                                          </div>
                                        </div>
                                        <div className="col-lg-3 col-sm-3 col-xs-4">
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              Currency
                                              <sup>*</sup>
                                            </label>

                                            <SelectForm
                                              isClearable
                                              isSearchable
                                              options={
                                                optionsObj["DECURNCY"]
                                                  ? optionsObj["DECURNCY"]
                                                  : []
                                              }
                                             
                                              placeholder={"Currency"}
                                              isDisabled={false}
                                              value={
                                                Object.keys(InstallmentData[index].Currency).length >0 
                                                  ? InstallmentData[index].Currency
                                                  : null
                                              }
                                              onChange={async (event) => {
                                                setInstallmentData(prevData => prevData.map((item, idx) => {
                                                  if (idx === index) {
                                                    return {
                                                      ...item,
                                                      Currency: event
                                                    };
                                                  }
                                                  return item;
                                                }));
                                              }}
                                              
                                                
                                              isMulti={false}
                                              noIndicator={false}
                                              noSeparator={false}
                                            />
                                            {InstallmentErrors.length> 0 ?InstallmentErrors[index]["Currency"] && (
                                              <p style={{ color: "red" }}>
                                                {InstallmentErrors[index]["Currency"]}
                                              </p>
                                            ):""}
                                          </div>
                                          </div>
                                    </>))}
                                    </div>
                              </>
            
          </AccordionDetails>
        </Accordion>
      ) : null}
      
    </div>
  );
};

export default OneTimePayment;
