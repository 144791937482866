import React from "react";
import SelectForm from "../../Components/SelectForm/SelectForm";
import {
  CommonDropdownData,
  getGenericDropdownsData,
  getProjectDashboardData,
  getProjectGridDataExport,
} from "../../Helpers/APIEndPoints/EndPoints";
import { APICall } from "../../Helpers/APICalls";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import InputForm from "../../Components/InputForm/InputForm";
import notify from "../../Helpers/ToastNotification";
import axios from "axios";
import fileDownload from "js-file-download";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { LoaderContext, getContext } from "../../Helpers/Context/Context";
import { Collapse } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const LOP = () => {
  const context = getContext();
  const { showLoader, hideLoader } = useContext(LoaderContext);
  let navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState([]);
  const [dashboardPageSize, setDashboardPageSize] = useState(10);
  const [dashboardStart, setDashboardStart] = useState(0);
  const [dashboardSortColumn, setDashboardSortColumn] = useState<any>("");
  const [dashboardSortDirection, setDashboardSortDirection] = useState("");
  const [dashboardCount, setDashboardCount] = useState(0);
  const [statusOptions, setStatusOptions] = useState([]);
  const [statusVal, setStatusVal] = useState<any>([]);
  const [modeofContractOptions, setModeofContractOptions] = useState([]);
  const [modeofContractVal, setModeofContractVal] = useState<any>([]);
  const [searchText, setSearchText] = useState<any>("");
  const [page, setPage] = useState(0);
  const [projectOptions, setProjectOptions] = useState([]);
  const [selectedProject, setSelectedProject] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const gridColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        display: false,
      },
    },
    {
      name: "name",
      label: "Project",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        customBodyRender: (value, tableMeta) => {
          let id = tableMeta.tableData[tableMeta.rowIndex].id;
          return (
            <Link to={"/ProjectEdit"} state={{ projectId: id }}>
              {value}
            </Link>
          );
        },
      },
    },
    {
      name: "jvPartner",
      label: "JV Partner",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "clientName",
      label: "Client Name",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "projectLocation",
      label: "Location",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "contractMode",
      label: "Mode",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },
    {
      name: "contractValue",
      label: "Contract Value",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "scopeOfWork",
      label: "Scope of Work",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
        display: false,
      },
    },

    {
      name: "startDateContractural",
      label: "Contract Period",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },
    {
      name: "startDateActual",
      label: "Actual Period",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },
    {
      name: "detailEngineeringConsultant",
      label: "Detail Engineering Consultant",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
        display: false,
      },
    },
    {
      name: "proofConsultant",
      label: "Proof Consultant",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
        display: false,
      },
    },
    {
      name: "saftyConsultant",
      label: "Safty Consultant",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
        display: false,
      },
    },
    {
      name: "authorityEngineer",
      label: "Authority Engineer",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
        display: false,
      },
    },
    {
      name: "genralConsultant",
      label: "Genral Consultant",
      options: {
        filter: false,
        sort: false,
        display: false,
        sortDescFirst: true,
      },
    },
    {
      name: "independentProofChecker",
      label: "Independent Proof Checker",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
        display: false,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },
    {
      name: "isActive",
      label: "Active",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta) => {
          if (value) {
            return <i className="fas fa-check"></i>;
          } else {
            return <i className="fas fa-times"></i>;
          }
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta) => {
          let id = tableMeta.tableData[tableMeta.rowIndex].id;
          return (
            <div className="d-flex justify-content-center">
              <Tooltip title="view">
                <a
                  className="mr-2"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/ProjectEdit", {
                      state: { projectId: id, readOnly: true },
                    });
                  }}>
                  <i className="fa fa-eye"></i>
                </a>
              </Tooltip>
            </div>
          );
        },
      },
    },
  ];

  const GetProjectStatus = async () => {
    const response = await APICall(getGenericDropdownsData, "POST", {
      MasterType: 1,
    });
    if (response.data !== null && response.data.length > 0) {
      let status = response.data.map((element) => ({
        value: element.id,
        label: element.name,
      }));
      setStatusOptions(status);
    } else {
      setStatusOptions([]);
    }
  };

  const GetContractModeOptions = async () => {
    const response = await APICall(getGenericDropdownsData, "POST", {
      MasterType: 2,
    });
    if (response.data !== null && response.data.length > 0) {
      let mode = response.data.map((element) => ({
        value: element.id,
        label: element.name,
      }));
      setModeofContractOptions(mode);
    } else {
      setModeofContractOptions([]);
    }
  };

  const getProjectOptions = async () => {
    const res = await APICall(CommonDropdownData, "POST", {
      dropdownName: "project",
      UserId: context.userId,
      RoleId: context.loggedInAs,
    });

    if (res.data !== null) {
      setProjectOptions(res.data);
    } else {
      setProjectOptions([]);
    }
  };

  useEffect(() => {
    (async () => {
      showLoader();
      await GetProjectStatus();
      await GetContractModeOptions();
      await getProjectOptions();

      hideLoader();
    })();
  }, []);

  const MapArr = (arr) => {
    let s = [];
    if (arr[0] === null || arr.length < 0) {
      return null;
    }
    arr.map((i) => s.push(i.value));
    return s;
  };

  let mode = MapArr(modeofContractVal);
  let status = MapArr(statusVal);

  const LoadDashboard = async () => {
    setDashboardData([]);
    setDashboardCount(0);
    let requestParams = {
      UserId: context.userId,
      RoleId: context.loggedInAs,
      pageSize: dashboardPageSize,
      pageStart: dashboardStart,
      SortOrder: dashboardSortDirection,
      SortColumn: dashboardSortColumn,
      SearchText: searchText,
      ContractMode:
        modeofContractVal.length > 0
          ? mode.toString()
          : modeofContractVal.value,
      Status: statusVal.length > 0 ? status.toString() : statusVal.value,
      projectIds:
        selectedProject.length > 0
          ? selectedProject.map((ele) => ele.value).join(",")
          : "",
      fromDate: fromDate ?? fromDate,
      toDate: toDate ?? toDate,
    };
    const { data } = await APICall(
      getProjectDashboardData,
      "POST",
      requestParams
    );
    if (data !== null && data.length > 0) {
      await data.forEach((ele: any) => {
        ele.startDateContractural !== null
          ? (ele.startDateContractural = moment(
              ele.startDateContractural
            ).format("DD-MMM-yyyy"))
          : (ele.startDateContractural = "");
        ele.endDateContractural !== null
          ? (ele.endDateContractural = moment(ele.endDateContractural).format(
              "DD-MMM-yyyy"
            ))
          : (ele.endDateContractural = "");
        ele.startDateActual !== null
          ? (ele.startDateActual = moment(ele.startDateActual).format(
              "DD-MMM-yyyy"
            ))
          : (ele.startDateActual = "");
      });
      await setDashboardData(data);
      setDashboardCount(data[0].totalCount);
    }
  };

  useEffect(() => {
    LoadDashboard();
  }, [
    dashboardStart,
    dashboardSortColumn,
    dashboardSortDirection,
    dashboardCount,
    searchText,
    modeofContractVal,
    statusVal,
    selectedProject,
    fromDate,
    toDate,
  ]);

  const selectOnChange = (event, type) => {
    if (type === "status") {
      setStatusVal(event);
    }
    if (type === "modeofContract") {
      setModeofContractVal(event);
    }
    if (type === "project") {
      setSelectedProject(event);
    }
  };

  const exportToExcelFunction = async () => {
    const params = {
      SearchText: searchText,
      ContractMode:
        modeofContractVal.length > 0
          ? mode.toString()
          : modeofContractVal.value,
      Status: statusVal.length > 0 ? status.toString() : statusVal.value,
      UserId: context.userId,
      RoleId: context.loggedInAs,
      projectIds:
        selectedProject.length > 0
          ? selectedProject.map((ele) => ele.value).join(",")
          : "",
      fromDate: fromDate ?? fromDate,
      toDate: toDate ?? toDate,
    };
    axios
      .request({
        responseType: "blob",
        method: "POST",
        url: getProjectGridDataExport,
        data: params,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "*",
        },
      })
      .then((response) => {
        fileDownload(
          response.data,
          `ListOfProjects` +
            `${moment(new Date()).format(moment.HTML5_FMT.DATE)}` +
            `.xlsx`
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const dashboardOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: dashboardCount,
    rowsPerPage: dashboardPageSize,
    page: page,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    //standard | vertical | simple
    onChangeRowsPerPage: (num) => {
      //   setLimit(num);
      //   setNxtPgInfo("");
      //   setPrevPgInfo("");
      //   setIsPrevOrNext("");
    },
    onSearchChange: (searchText) => {
      if (searchText !== null) {
        setSearchText(searchText);
      } else {
        setSearchText("");
      }
    },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setDashboardSortColumn(sortColumn);
        await setDashboardSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setDashboardSortColumn(sortColumn);
        await setDashboardSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      await setPage(page);
      await setDashboardStart(page * dashboardPageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  useEffect(() => {
    if (searchText.length !== 0) {
      setSearchText(searchText);
      setDashboardStart(0);
      setPage(0);
    } else {
      setDashboardStart(0);
      setPage(0);
    }
  }, [searchText]);

  useEffect(() => {
    if (isFilterOpen === false) {
      setSelectedProject([]);
      setFromDate(null);
      setToDate(null);
    }
  }, [isFilterOpen]);

  return (
    <>
      <div className="form-main px-3">
        <div className="page-title w-100">
          <div className="col-lg-12 p-0">
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <h4>List of Projects</h4>
              </div>
              <div className="col-lg-4 col-md-2"></div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="float-right col-sm-offset-2"
                  style={{
                    marginBottom: 10,
                  }}>
                  <Tooltip title="export to excel">
                    <button
                      className="btn btn-export"
                      onClick={() => {
                        if (dashboardData.length === 0) {
                          notify(1, "No data available");
                        } else {
                          exportToExcelFunction();
                        }
                      }}>
                      <i className="fa fa-download"></i>
                    </button>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-content row filter-row-container m-0">
        <div className="transformer-tabs col-lg-12 col-md-12">
          <div className="meeting-tabs tabs-main col-lg-12 pl-0">
            <ul className="filters">
              <li className="row d-flex">
                <div className="col-lg-3 col-sm-3 col-xs-12 p-0 ml-3 mobile-view top-m">
                  <div className="form-group">
                    <span>
                      <label className="col-form-label">Project</label>
                    </span>
                    <SelectForm
                      options={projectOptions}
                      placeholder="Project"
                      isDisabled={false}
                      value={selectedProject}
                      onChange={(event) => {
                        selectOnChange(event, "project");
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                    />
                  </div>
                </div>

                <div className="col-lg-2 col-sm-3 col-xs-12 p-0 ml-3 mobile-view top-m">
                  <div className="form-group">
                    <span>
                      <label className="col-form-label">Period (From)</label>
                    </span>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        disabled={false}
                        value={fromDate}
                        className="w-100 bg-white"
                        onChange={(e) => {
                          setFromDate(moment(e).format("YYYY-MM-DD"));
                        }}
                        inputFormat="dd-MMM-yyyy"
                        renderInput={(params) => (
                          <TextField size="small" name="fromDate" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                <div className="col-lg-2 col-sm-3 col-xs-12 p-0 ml-3 mobile-view top-m">
                  <div className="form-group">
                    <span>
                      <label className="col-form-label">Period (To)</label>
                    </span>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        disabled={false}
                        value={toDate}
                        className="w-100 bg-white"
                        onChange={(e) => {
                          setToDate(moment(e).format("YYYY-MM-DD"));
                        }}
                        inputFormat="dd-MMM-yyyy"
                        renderInput={(params) => (
                          <TextField size="small" name="toDate" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>

                <div className="align-self-end col-lg-3 col-sm-3 col-xs-10 p-0 ml-2 mobile-view top-m  searchInput ">
                  <InputForm
                    value={searchText}
                    placeholder={"Search"}
                    isDisabled={false}
                    textArea={false}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                  />
                  <i className="fa fa-search ml-2 mt-1" aria-hidden="true"></i>
                </div>

                <ul
                  className="align-self-end ml-2 float-right btn btn-export"
                  onClick={() => setIsFilterOpen((prev) => !prev)}>
                  <li>
                    <i className="fa fa-filter"></i>
                  </li>
                </ul>

                {/* 
                <div className="ml-3">
                  <button className="btn btn-save">Apply</button>
                  <button className="btn btn-reset">reset</button>
                </div> */}
              </li>
            </ul>
          </div>
          <Collapse in={isFilterOpen}>
            <div className="mt-2">
              <div className="row">
                <div className="col-lg-3 col-sm-3 col-xs-12 p-0 ml-3 mobile-view top-m">
                  <div className="form-group">
                    <span>
                      <label className="col-form-label">Status</label>
                    </span>
                    <SelectForm
                      options={statusOptions}
                      placeholder="Status"
                      isDisabled={false}
                      value={statusVal}
                      onChange={(event) => {
                        selectOnChange(event, "status");
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3 col-xs-12 p-0 ml-3 mobile-view top-m">
                  <div className="form-group">
                    <span>
                      <label className="col-form-label">Mode of Contract</label>
                    </span>
                    <SelectForm
                      options={modeofContractOptions}
                      placeholder="Mode of Contract"
                      isDisabled={false}
                      value={modeofContractVal}
                      onChange={(event) => {
                        selectOnChange(event, "modeofContract");
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      </div>

      <div>
        {dashboardData && dashboardData.length >= 0 && (
          <DynamicGrid
            options={dashboardOptions}
            data={dashboardData}
            columns={gridColumns}
          />
        )}
      </div>
    </>
  );
};

export default LOP;
