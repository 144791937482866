import React, { useContext, useEffect, useState } from "react";
import "../Progression/ChangeLog.css";
import { getAuditLogsForPicklist } from "../../Helpers/APIEndPoints/EndPoints";
import { APICall } from "../../Helpers/APICalls";
import moment from "moment";
import { Table } from "react-bootstrap";
import { LoaderContext } from "../../Helpers/Context/Context";

// Get the local timezone offset in minutes
const localOffsetMinutes = moment().utcOffset();

function GenericMasterHistory({ TId }) {
  const [auditLogsObject, setAuditLogsObject] = useState<any>({});
  const [rhsData, setRhsData] = useState<any>([]);
  const [tableData, setTableData] = useState([]);
  const [eventCode, setEventCode] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const [noChange, setNoChange] = useState(false);
  const [transactionId, setTransactionId] = useState<any>(null);
  const { showLoader, hideLoader } = useContext(LoaderContext);

  useEffect(() => {
    GetLogData();
  }, []);

  const GetLogData = async () => {
    showLoader();
    const auditLogsRes = await APICall(
        getAuditLogsForPicklist,
        "POST", 
        { Id: TId, }
    );

    if (auditLogsRes?.data?.length > 0) {
      let responseData = auditLogsRes?.data;

      // Group by transactionId
      const dataByTransaction = {};
      responseData.forEach((item) => {
        const transactionId = item.transactionId;
        if (!dataByTransaction[transactionId]) {
          dataByTransaction[transactionId] = [];
        }
        dataByTransaction[transactionId].push(item);
      });

      Object.keys(dataByTransaction).map((transactionid)=>{
        dataByTransaction[transactionid].sort((a, b) => Number(a.sequenceNoOfAtt) - Number(b.sequenceNoOfAtt));
      });
      
      // Group by recordId within each transaction group as an array of arrays
      const sortedTransactions = Object.keys(dataByTransaction)
        // .sort((a, b) => Number(b) - Number(a))
        .sort((a, b) =>
          a === null ? 1 : b === null ? -1 : Number(b) - Number(a)
        ) // Sort null transactionId records last
        .map((transactionId) => {
          const transactionGroup = dataByTransaction[transactionId];
          const recordsArray = [];

          const recordsMap = {};
          transactionGroup.forEach((item) => {
            const recordId = item.recordId;
            if (!recordsMap[recordId]) {
              recordsMap[recordId] = [];
            }
            recordsMap[recordId].push(item);
          });

          for (const recordId in recordsMap) {
            if (recordsMap.hasOwnProperty(recordId)) {
              recordsArray.push(recordsMap[recordId]);
            }
          }

          return {
            transactionId,
            records: recordsArray,
          };
        });
      setTransactionId(sortedTransactions[0].transactionId ? parseInt(sortedTransactions[0].transactionId.trim()) : null);
      setTableData(sortedTransactions[0].records);
      setRhsData(sortedTransactions[0].records[0]);
      setAuditLogsObject(sortedTransactions);
    } else {
      setAuditLogsObject([]);
    }
    hideLoader();
  };

  const selectLog = async (log, index) => {
    setActiveTab(index);
    if (eventCode === 'Separation'){
      setRhsData(log);
    }
    else{
      setRhsData(log[0]);
    }
  };
  return (
    <div>
      {auditLogsObject.length > 0 ? (
        <div className="d-flex justify-content-between">
          {/* <div className="lhs-outer-box row"> */}
          <div
            style={{
              height: "350px",
              overflowY: "auto",
              overflowX: "hidden",
              width: "35%",
            }}
            className="d-flex flex-column">
            {auditLogsObject.map((type, index) => (
              <div
                key={index}
                className={index == activeTab ? "lhs-box bg-clr" : "lhs-box"}
                onClick={() => {
                  setTransactionId(type.transactionId ? parseInt(type.transactionId.trim()) : null);
                  selectLog(type.records, index);
                }}>
                <Log auditLogsType={type.records[0]} />
              </div>
            ))}
          </div>

          <div className="rhs-box" id="rhs-box">
            {rhsData?.map((eachLog, index) => {
              return (
                <React.Fragment key={index}>
                  {index == 0 && (
                    <>
                      <div className="row">
                        <div className={"col-lg-6 col-sm-6"}>
                          <span className="text-bold">Change Date:</span>{" "}
                          {moment
                            .utc(eachLog?.createdDate)
                            .utcOffset(localOffsetMinutes)
                            .format("DD-MMM-YYYY HH:mm:ss")}
                        </div>{" "}
                        <div className={"col-lg-6 col-sm-6"}>
                          <span className="text-bold">Effective Date:</span>{" "}
                          {moment(eachLog?.effectiveDate).format("DD-MMM-YYYY")}
                        </div>
                      </div>

                      <div className="row">
                        <div className={"col-lg-6 col-sm-6"}>
                          <span className="text-bold">Changed By:</span>{" "}
                          {eachLog?.userName}
                        </div>{" "}
                        <div className={"col-lg-6 col-sm-6"}>
                          <span className="text-bold">Remarks:</span>{" "}
                          {eachLog?.remarks}
                        </div>{" "}
                      </div>
                      <br />
                      <br />
                    </>
                  )}
                </React.Fragment>
              );
            })}

            {tableData?.map((eachLog) => (
              <Table striped bordered hover>
                {eachLog &&
                  eachLog.length > 0 &&
                  eachLog.map((log, index) => {
                    return (
                      <>
                        {index == 0 && (
                          <thead>
                            <tr>
                              <th className="text-dark">Field</th>
                              <th className="text-dark">Old Value</th>
                              <th className="text-dark">New Value</th>
                            </tr>
                          </thead>
                        )}
                        <tbody>
                          <tr>
                            <td>{log?.attribute}</td>
                            <td>{log?.oldAttributeValue}</td>
                            <td>{log?.newAttributeValue}</td>
                          </tr>
                        </tbody>
                      </>
                    );
                  })}
              </Table>
            ))}
          </div>
        </div>
      ) : (
        <h5>No History Found!</h5>
      )}
    </div>
  );
}

const Log = ({ auditLogsType }) => {
  return (
    <>
      <span>
        <span className="text-bold">Status:</span> {auditLogsType[0].progStatus}
      </span>
      <br />
      <span>
        <span className="text-bold">Changed Date:</span>{" "}
        {moment
          .utc(auditLogsType[0].createdDate)
          .utcOffset(localOffsetMinutes)
          .format("DD-MMM-YYYY HH:mm:ss")}
      </span>
      <br />
      <span>
        <span className="text-bold">Changed By:</span>{" "}
        {auditLogsType[0].userName}
      </span>
      <br />
    </>
  );
};
export default GenericMasterHistory;
