import { create } from "zustand";

const useDocumentStore = create((set) => ({
  documents: [],
  insertDocument: (data) =>
    set((state) => ({ documents: [...state.documents, ...data] })),
  updateDocument: (data) => set((state) => ({ documents: data })),
}));

export default useDocumentStore;
