import { Spinner } from "react-bootstrap";
import "./Loader.css";
import Loading from "../../Assets/Images/loader.gif";

const Loader = () => {
  return (
    <>
      <div className="spinner">
        {/* <Spinner animation="border" variant="secondary" /> */}
        <img src={Loading} alt="loading..." />
      </div>
    </>
  );
};

export default Loader;
