import React, { useContext, useEffect, useRef, useState } from "react";
import { LoaderContext } from "../../Helpers/Context/Context";
import {
  downloadSupportingDocForProgression,
  getDropdowns,
  getWorkflowData,
} from "../../Helpers/APIEndPoints/EndPoints";
import { APICall } from "../../Helpers/APICalls";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  useStepContext,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  geInitiatorAndRoleIdOnWorkflowTaskId,
  updateEventAndReasonInWorkline,
  changeIsRevokedStatusForEResignation,
  submitProgressionRevoke,
  getProgressionDataBasedOnId,
  getEmpRlBasedOnId,
  getAllRoles,
  getEmpRlMapForEmployee,
  getReporteesForEmployee,
  getRepMgMapForEmployee,
  updateProgressionOnSave,
  createTaskForApprovers,
  getApproverAndProgressionDetailsByWorkflowTaskId,
  getDdForProgression,
  getSearchDdForProgression,
  getOptForApproval,
  getAttrForProgression,
  postDataForProgression,
  getSectionDetailsV2,
  getGlobalValues,
  viewDocument,
  downloadDocument,
  deleteDocument,
  getDocuments,
  getSearchableDropdowns,
  getParenPBasedonIncm,
  postAttributeValueDetailsV2,
  getAttributeValueDetailsV2,
  documentUpload,
  getAccountValidation,
} from "../../Helpers/APIEndPoints/EndPoints";
import { NationalIdCardType } from "../../Components/FromStructure/NationalIdCardTypeRegex";
import notify from "../../Helpers/ToastNotification";
import moment from "moment";
import { ExpandMore } from "@mui/icons-material";
import { Modal } from "react-bootstrap";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import uuid from "react-uuid";
import SelectForm from "../../Components/SelectForm/SelectForm";
import InputForm from "../../Components/InputForm/InputForm";
import DateForm from "../../Components/DateForm/DateForm";
import { useLocation } from "react-router-dom";

import SectionCompontentProgressionView from "./SectionCompontentProgressionView";
import ChangeLog from "./ChangeLog";
import "./Request.css";
import useDocumentStore from "../../Helpers/ZustandStore/DocumentForProgressionStore";

const ViewRequisitionDetatils = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  let FlatDocRef = useRef(null);
  const [workflowTaskId, setWorkflowTaskId] = useState(state ? state : 0);
  const [accordionReqDet, setAccordionReqDet] = useState(true);
  const [accordionWrkfl, setAccordionWrkfl] = useState(false);
  const [accordianEvent, setAccordianEvent] = useState(false);
  const [changeLogAccordian, setChangeLogAccordian] = useState(false);
  const [formErrors, setFormErrors] = useState<any>({});
  const [dynamicformErrors, setDynamicformErrors] = useState<any>({});
  const [optionsObj, setOptionsObj] = useState<any>({});
  const [TId, setTId] = useState(0);
  const [modalIsOpen, setModal] = useState(false);
  const [disableSubmitBtn, setdisableSubmitBtn] = useState(true);
  let { userDetails, currentRoleId }: any = useUserContext();
  const FullName = `${userDetails.FirstName} ${userDetails.LastName}`;
  const [inputValues, setInputValues] = useState({});
  const [approvers, setApprovers] = useState<any>([]);
  const [approverOpt, setapproverOpt] = useState<any>({});
  const [allApprovers, setAllApprovers] = useState<any>({});
  const [dynamicAccordians, setDynamicAccordian] = useState<any>({});
  const [btnHide, setBtnHide] = useState(true);
  const [sectionTabHide, setSectionTabHide] = useState(true);
  const [sectionValuesArray, setSectionValuesArray] = useState<any>([]);
  const [formData, setFormData] = useState<any>({});
  const [finalformData, setFinalFormData] = useState<any>({});
  const [globalValues, setGlobalValues] = useState([]);
  const [submitValidation, setSubmitValidation] = useState(0);
  const [isdisable, setIsdisable] = useState(true);
  const [WorkflowId, setWorkflowId] = useState(0);
  const [workflowData, setWorkflowData] = useState<any>({});

  const [enableOnChange, setEnableOnChange] = useState(true);
  const [getData, setGetData] = useState([]);
  const [submitFunc, setSubmitFunc] = useState(false);

  const [mapTypeVal, setMapTypeVal] = useState(0);
  const [repMgMap, setRepMgMap] = useState<any>([]);
  const [reportees, setReportees] = useState<any>([]);
  const [directReportee, setDirectReportee] = useState<any>(null);
  const [managerForReportee, setManagerForReportee] = useState<any>(null);
  const [reporteeManagerMap, setReporteeManagerMap] = useState<any>([]);
  const [managerDisable, setManagerDisable] = useState(false);

  const [rlMapTypeVal, setRlMapTypeVal] = useState(0);
  const [relReportees, setRelReportees] = useState<any>([]);
  const [empRlMap, setEmpRlMap] = useState<any>([]);
  const [directReporteeRel, setDirectReporteeRel] = useState<any>(null);
  const [relationForReportee, setRelationForReportee] = useState<any>(null);
  const [userRelationMap, setUserRelationMap] = useState<any>([]);
  const [optForRoles, setOptForRoles] = useState<any>([]);

  const [oldJsonForSections, setOldJsonForSections] = useState<any>({});
  const [newJsonForSections, setNewJsonForSections] = useState<any>({});
  const [allSectionsData, setAllSectionsData] = useState({});

  // value Object for section
  const [attributesData, setAttributesData] = useState<any>({});
  const [isOnce, setIsOnce] = useState(true);
  const [isValidateOnce, setIsValidateOnce] = useState(true);
  const [attributeValidation, setAttributeValidation] = useState<any>({});
  const [dataFilled, setDataFilled] = useState(false);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [progressionId, setProgressionId] = useState(0); 
  const [initiatorIdOnWflTaskId, setInitiatorIdOnWflTaskId] = useState(null); 
  const [initiatorRoleIdOnWflTaskId, setInitiatorRoleIdOnWflTaskId] = useState(null); 
  const [skillcategoryHide, setSkillCategoryHide] = useState(true);
  const [sectionIdForEss, setSectionIDforEss] = useState<any>(null);

  const [selectedFilesForFlatProg, setSelectedFilesForFlatProg] = useState([]);

  const { documents, insertDocument, updateDocument }: any = useDocumentStore();
  const parseJSON = (jsonString) => {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      return null;
    }
  };

  const typeOptions = [
    { value: 1, label: "Transfer all to upper position.", code: "TATUP" },
    { value: 2, label: "Transfer all to other manager.", code: "TATOM" },
    { value: 3, label: "Individual transfer to other manager.", code: "ITTOM" },
  ];
  const typeOptionsRel = [
    { value: 1, label: "Transfer all to upper position.", code: "RLTATUP" },
    { value: 2, label: "Transfer all to other manager.", code: "RLTATOM" },
    {
      value: 3,
      label: "Individual transfer to other manager.",
      code: "RLITTOM",
    },
    { value: 4, label: "Terminate all Relations.", code: "RLTAR" },
  ];

  const getProgDocuments = async () => {
    const progDocuments = await APICall(getDocuments, "POST", {
      isProgressionFlat : true,
      ProgressionId : progressionId
    });
    if(progDocuments.status === 0){
      console.log('setSelectedFilesForFlat', progDocuments);
      setSelectedFilesForFlatProg(progDocuments?.data?.files);
    }
  }

  useEffect(() => {
    getProgDocuments();
  }, [progressionId])
  
  useEffect(() => {
    if (
      attributesData["REQTYPOUPD"] !== null &&
      attributesData["REQTYPOUPD"] !== undefined &&
      attributesData["REQRSNOUP"] !== null &&
      attributesData["REQRSNOUP"] !== undefined &&
      attributesData["REQEMPLID"] !== null &&
      attributesData["REQEMPLID"] !== undefined
    ) {
      (async () => {
        const workflowRes = await APICall(getWorkflowData, "POST", {
          WorkflowTypeId: attributesData["REQTYPOUPD"].value,
          WorkflowSubTypeId: attributesData["REQRSNOUP"].value,
          EmployeeId: attributesData["REQEMPLID"].value,
          // InitiatorId: userDetails.Id
          InitiatorId: workflowData.InitiatorId,
          // InitiatorRoleId: currentRoleId?.value,
          InitiatorRoleId: workflowData.InitiatorRoleId,          
          CountryId: attributesData["REQCNTRY"] ? attributesData["REQCNTRY"].value : null,
          PayrollLocationId: attributesData["REQPLOCN"]
            ? attributesData["REQPLOCN"]?.value
            : null,
          SectionId: sectionIdForEss ? sectionIdForEss : null,
        });

        if (workflowRes.data.length > 0) {
          setApprovers(workflowRes.data);
          let temp = workflowRes.data;
          if (temp.length == 1) {
            setBtnHide(false);
          }
          if (temp.length > 1) {
            temp.map((eachapprover, index) => {
              index > 0 &&
                setapproverOpt((prev) => ({
                  ...prev,
                  [`${"Approver"} ${index}`]: eachapprover.approvers,
                }));
              setAllApprovers((prev) => ({
                ...prev,
                [`${"Approver"} ${index}`]: eachapprover.approvers,
              }));

              if (
                eachapprover.approvers !== null &&
                eachapprover.approvers.length > 0
              ) {
                setBtnHide(false);
              }
            });
          }

          setWorkflowId(workflowRes.data[0].workFlowId);
          const attrData = await APICall(getAttrForProgression, "POST", {
            WorkFlowId: workflowRes.data[0].workFlowId,
            COU: attributesData["REQEMPLID"].cou,
            userid: userDetails.Id,
            roleid: currentRoleId?.value,
            IsNewHire: true,
            IsProg: true,
            IsViewMode: true,
            EmployeeId: parseInt(attributesData["REQEMPLID"].value),
          });

          const NewJson = parseJSON(workflowData.RequestNewJson) || [];
          if (attrData.data !== null && attrData.data.length > 0) {
            attrData.data.map(async (eachAttr) => {
              if (eachAttr.attributeType == "Attribute") {
                if (eachAttr.attributeDatatype === "DropdownSingle") {
                  const resultObj = NewJson.find((obj) =>
                    obj.hasOwnProperty(eachAttr.attributeCode)
                  );

                  if (resultObj) {
                    const valForDD = eachAttr.options.find(
                      (item) => item.value === resultObj[eachAttr.attributeCode]
                    );
                    eachAttr.valueOnDd = valForDD || null;

                    //Customization for Promotion (India)
                    if (
                      attributesData?.["REQCNTRY"]?.code == "CODECNTRY1" &&
                      attributesData?.["REQTYPOUPD"]?.code == "Promotion" &&
                      eachAttr.attributeCode == "EC" &&
                      valForDD &&
                      valForDD?.code == "Worker"
                    ) {
                      setSkillCategoryHide(false);
                    } else if (
                      attributesData?.["REQCNTRY"]?.code == "CODECNTRY1" &&
                      attributesData?.["REQTYPOUPD"]?.code == "Promotion" &&
                      eachAttr.attributeCode == "EC" &&
                      valForDD &&
                      valForDD?.code != "Worker"
                    )  {
                      setSkillCategoryHide(true);
                    }
                    //----------------------------------------------------------

                  } else {
                    const valForDD = eachAttr.options.find(
                      (item) => item.value === eachAttr.value
                    );
                    eachAttr.valueOnDd = valForDD || null;

                    //Customization for Promotion (India)
                    if (
                      attributesData?.["REQCNTRY"]?.code == "CODECNTRY1" &&
                      attributesData?.["REQTYPOUPD"]?.code == "Promotion" &&
                      eachAttr.attributeCode == "EC" &&
                      valForDD &&
                      valForDD?.code == "Worker"
                    ) {
                      setSkillCategoryHide(false);
                    } else if (
                      attributesData?.["REQCNTRY"]?.code == "CODECNTRY1" &&
                      attributesData?.["REQTYPOUPD"]?.code == "Promotion" &&
                      eachAttr.attributeCode == "EC" &&
                      valForDD &&
                      valForDD?.code != "Worker"
                    )  {
                      setSkillCategoryHide(true);
                    }
                    //----------------------------------------------------------
                  }

                  //set extra attributes when demotion
                  if (attributesData["REQTYPOUPD"].code == "Demotion") {
                    for (const attribute of NewJson) {
                      if (eachAttr.attributeCode === attribute.Code) {
                        // console.log(attribute.Main[0]);
                        eachAttr.valueOnDd = attribute.Main[0][attribute.Code];
                      }
                    }
                  }
                } else if (
                  eachAttr.attributeDatatype === "Text" ||
                  eachAttr.attributeDatatype === "Number"
                ) {
                  const resultObj = NewJson.find((obj) =>
                    obj.hasOwnProperty(eachAttr.attributeCode)
                  );
                  eachAttr.value =
                    resultObj[eachAttr.attributeCode] != null &&
                    resultObj[eachAttr.attributeCode] != undefined
                      ? resultObj[eachAttr.attributeCode]
                      : "";
                  eachAttr.valueOnDd =
                    eachAttr.value != null && eachAttr.value != undefined
                      ? eachAttr.value
                      : "";
                } else if (eachAttr.attributeDatatype === "Date") {
                  const resultObj = NewJson.find((obj) =>
                    obj.hasOwnProperty(eachAttr.attributeCode)
                  );

                  const parsedDateNew = moment(
                    resultObj[eachAttr.attributeCode],
                    "YYYY-MM-DD"
                  );

                  const formattedDateNew = parsedDateNew.format("DD-MMM-YYYY");
                  eachAttr.value = formattedDateNew || null;
                  eachAttr.valueOnDd = formattedDateNew || null;
                } else if (
                  eachAttr.attributeDatatype === "SearchableDropdownSingle"
                ) {
                  const resultObj = NewJson.find((obj) =>
                    obj.hasOwnProperty(eachAttr.attributeCode)
                  );

                  const obj = await getSearchableDropdownAPI(
                    "",
                    eachAttr.attributeCode,
                    null,
                    resultObj[eachAttr.attributeCode],
                    "attribute",
                    0
                  );
                  eachAttr.valueOnDd = obj;
                }
              }
              if (eachAttr.attributeType == "AttributeSet") {
                let tabcode = "";
                if (eachAttr.attributeCode == "SA_CompensationSet") {
                  tabcode = "Employment";
                } else if (eachAttr.attributeCode == "SA_DisciplinaryActions") {
                  tabcode = "Employment";
                } else if (eachAttr.attributeCode == "SA_OneTimePaymentDeductions") {
                  tabcode = "Employment";
                }
                setSectionValuesArray([]); //imp

                (async () => {
                  await showLoader();
                  const section = await APICall(getSectionDetailsV2, "POST", {
                    MN: "EmployeeCentral",
                    IN: "EC_Form",
                    TN: tabcode,
                    UID: userDetails.Id,
                    TID: parseInt(attributesData["REQEMPLID"].value),
                    RoleId: currentRoleId?.value,
                    //COU:TId==0?COU:userDetails.COU,
                    COU: userDetails.COU,
                    //COU: attributesData["REQCNTRY"] ? attributesData["REQCNTRY"].code : "",
                    IsNewHire: true,
                  });

                  // console.log("Sections: ", section);
                  if (
                    section.data !== null &&
                    section.data.t !== undefined &&
                    section.data.t.tn === tabcode
                  ) {
                    let setFinalSections = [];
                    if (eachAttr.attributeCode == "SA_CompensationSet") {
                      const sect = section.data.t.S?.find(
                        (item) => item.SN == "Compensation"
                      );
                      setFinalSections.push(sect);

                      setFinalFormData(setFinalSections);
                    } else if (
                      eachAttr.attributeCode == "SA_DisciplinaryActions"
                    ) {
                      const sect = section.data.t.S?.find(
                        (item) => item.SN == "DisciplinaryActions"
                      );
                      // console.log(sect);
                      setFinalSections.push(sect);
                      setFinalFormData(setFinalSections);
                    } else if (
                      eachAttr.attributeCode == "SA_OneTimePaymentDeductions"
                    ) {
                      const sect = section.data.t.S?.find(
                        (item) => item.SN == "OneTimePaymentDeductions"
                      );
                      // console.log(sect);
                      setFinalSections.push(sect);
                      setFinalFormData(setFinalSections);
                    }
                    //
                    let result = {};
                    NewJson.forEach((item) => {
                      const sectionId = item.sectionId;
                      const code = item.Code;
                      const attribute = item.Main[0];

                      if (!result[sectionId]) {
                        result[sectionId] = {};
                      }

                      if (item.IsAttributeSet) {
                        if (!result[sectionId][code]) {
                          result[sectionId][code] = [];
                        }
                        result[sectionId][code].push(attribute);
                      } else {
                        result[sectionId][code] = attribute[code];
                      }
                    });
                    setAllSectionsData(result);
                    //
                  } else {
                    setFinalFormData([]);
                  }
                  await hideLoader();
                })();
                (async () => {
                  showLoader();
                  let bodyObj = {
                    mn: "EmployeeCentral",
                    in: "EC_Form",
                    tn: tabcode,
                    SN: "Basic",
                    TID: parseInt(attributesData["REQEMPLID"].value),
                    ISH: "true",
                    UserId: userDetails?.Id,
                    RoleId: currentRoleId?.value,
                    COU: userDetails.COU,
                    //COU: attributesData["REQCNTRY"] ? attributesData["REQCNTRY"].code : "",
                  };

                  const response = await APICall(
                    getAttributeValueDetailsV2,
                    "POST",
                    bodyObj
                  );
                  if (
                    response.data.sections &&
                    response.data.sections.length > 0
                  ) {
                    setSectionValuesArray(response.data.sections);
                  } else {
                    setSectionValuesArray([]);
                  }
                  hideLoader();
                })();
                if (attributesData["REQEMPLID"] != null) {
                  (async () => {
                    await showLoader();
                    const globalValues = await APICall(
                      getGlobalValues,
                      "POST",
                      {
                        TID: attributesData["REQEMPLID"].value,
                      }
                    );

                    if (
                      globalValues.data !== null &&
                      globalValues.data.length > 0
                    ) {
                      setGlobalValues(globalValues.data);
                    }
                    await hideLoader();
                  })();
                }
              }
            });
            if (attrData.data.length > 1) {
              setDynamicAccordian(attrData.data);
            } else if (
              attrData.data.length == 1 &&
              attrData.data[0].attributeType == "Attribute"
            ) {
              setDynamicAccordian(attrData.data);
            }
          }
        }
      })();
    }
  }, [
    attributesData["REQTYPOUPD"],
    attributesData["REQRSNOUP"],
    attributesData["REQEMPLID"],
  ]);

  const getDdData = async (AC, PID, RoleId = null) => {
    await showLoader();
    const GenId = RoleId || currentRoleId?.value; //Role Id of the User
    const dropdownData = await APICall(getDdForProgression, "POST", {
      AC,
      PID,
      GenId,
      UserId: userDetails?.Id,
      TId: AC==="REQSECT" ? 2 : null,
    });

    if (
      dropdownData.data !== null &&
      dropdownData.data !== undefined &&
      dropdownData.data.length > 0
    ) {
      await hideLoader();
      return dropdownData.data;
    } else {
      await hideLoader();
      return [];
    }
  };

  const getSearchableDropdownAPI = async (
    searchString,
    AC,
    cb,
    valueForDropdown,
    type,
    gridId
  ) => {
    if (
      searchString === "" &&
      cb === null &&
      valueForDropdown !== "" &&
      AC !== ""
    ) {
      const response = await APICall(getSearchDdForProgression, "POST", {
        searchString,
        AC,
        valueForDropdown,
      });

      if (response.data !== null && response.data.length > 0) {
        if (response.data.length === 1) {
          return response.data[0];
        }
      }
    } else if (searchString.length > 1) {
      const response = await APICall(getSearchDdForProgression, "POST", {
        searchString,
        AC,
      });
      // console.log(response);
      if (response.data !== null && response.data.length > 0) {
        cb(response.data);
        setOptionsObj((prev) => ({ ...prev, ["REQEMPLID"]: response.data }));
      } else {
        cb([]);
        setOptionsObj((prev) => ({ ...prev, ["REQEMPLID"]: [] }));
      }
    }
  };

  //CALL A USE-EFFECT TO GET DATA FROM DATABASE
  useEffect(() => {
    setAttributesData((prev) => ({
      ...prev,
      ["REQCNTRY"]: null,
      ["REQTYPOUPD"]: null,
      ["REQRSNOUP"]: null,
      ["REQTABS"]: null,
      ["REQSECT"]: null,
      ["REQEMPLID"]: null,
      ["REQEMPLNM"]: "",
      ["REQEFCTVDT"]: null,
      ["REQSUPDOC"]: "",
      ["REQDREMK"]: "",
      ["WRKFLINID"]: userDetails.Id,
      ["WRKFLINNM"]: FullName,
    }));
    setAttributeValidation((prev) => ({
      ["REQEFCTVDT"]: "Please Select a date",
    }));
    (async () => {
      const forRoles = await APICall(getAllRoles, "POST", {});
      setOptForRoles(forRoles.data);
      
      //api call to get initiator id and his/her role id
      const initIdandRoleIdOnTaskId = await APICall(geInitiatorAndRoleIdOnWorkflowTaskId, "POST", {
        Id: workflowTaskId,
      });
      setInitiatorIdOnWflTaskId(()=> initIdandRoleIdOnTaskId.data.userid);
      setInitiatorRoleIdOnWflTaskId(() => initIdandRoleIdOnTaskId.data.roleid);
      const roleIdForMgTransaction = initIdandRoleIdOnTaskId.data.roleid;
      //api call to get initiator id and his/her role id

      const optForCt = await getDdData("REQCNTRY", "", roleIdForMgTransaction);
      const optForPayroll = await getDdData("REQPLOCN", "", roleIdForMgTransaction);
      const optForType = await getDdData("REQTYPOUPD", "", roleIdForMgTransaction);
      const optForSubType = await getDdData("REQRSNOUP", "", roleIdForMgTransaction);
      const optForTab = await getDdData("REQTABS", "", roleIdForMgTransaction);
      //const optForSect = await getDdData("REQSECT", "");
      setOptionsObj((prev) => ({
        ...prev,
        ["REQCNTRY"]: optForCt,
        ["REQPLOCN"]: optForPayroll,
        ["REQTYPOUPD"]: optForType,
        ["REQRSNOUP"]: optForSubType,
        ["REQTABS"]: optForTab,
        ["REQSECT"]: [],
      }));
      await GetDataOnWorkflowTaskId(
        optForType,
        optForSubType,
        optForCt,
        optForPayroll,
        roleIdForMgTransaction
      );
    })();
  }, []);

  useEffect(() => {
    if (mapTypeVal != null && repMgMap != null && repMgMap.length > 0) {
      (async () => {
        setDirectReportee(typeOptions.find((item) => item.value == mapTypeVal));
        if (mapTypeVal == 1 || mapTypeVal == 2) {
          const mgId = await getSearchableDropdownAPI(
            "",
            "MG",
            null,
            repMgMap[0].ManagerId,
            "attribute",
            0
          );
          setManagerForReportee(mgId);
        }
        if (mapTypeVal == 3) {
          let repMg = [];
          repMgMap.map(async (eachRep) => {
            let Reportee = await getSearchableDropdownAPI(
              "",
              "MG",
              null,
              eachRep.EmpId,
              "attribute",
              0
            );
            const Manager = await getSearchableDropdownAPI(
              "",
              "MG",
              null,
              eachRep.ManagerId,
              "attribute",
              0
            );
            Reportee.mg = Manager;
            repMg.push(Reportee);
          });
          setReporteeManagerMap(repMg);
        }
      })();
    }
  }, [mapTypeVal, repMgMap]);

  useEffect(() => {
    if (rlMapTypeVal != null && empRlMap != null && empRlMap.length > 0) {
      (async () => {
        setDirectReporteeRel(
          typeOptionsRel.find((item) => item.value == rlMapTypeVal)
        );
        if (rlMapTypeVal == 1 || rlMapTypeVal == 2) {
          const relId = await getSearchableDropdownAPI(
            "",
            "RELNME",
            null,
            empRlMap[0].RelationId,
            "attribute",
            0
          );
          setRelationForReportee(relId);
        }
        if (rlMapTypeVal == 3) {
          let empRl = [];
          empRlMap.map(async (eachRep) => {
            // Send Id and Get Details
            const RelData = await APICall(getEmpRlBasedOnId, "POST", {
              Id: eachRep.Id,
            });

            const userid = await getSearchableDropdownAPI(
              "",
              "MG",
              null,
              RelData.data.userid,
              "attribute",
              0
            );
            const relationtype = optForRoles.find(
              (item) => item.value == RelData.data.relationtype
            );
            const relationid = await getSearchableDropdownAPI(
              "",
              "RELNME",
              null,
              eachRep.RelationId,
              "attribute",
              0
            );

            const RelDataNew = {
              id: eachRep.Id,
              userid: userid,
              relationtype: relationtype,
              relationid: relationid,
            };
            empRl.push(RelDataNew);
          });
          setUserRelationMap(empRl);
        }
      })();
    }
  }, [rlMapTypeVal, empRlMap]);

  useEffect(() => {
    if (
      attributesData["REQTYPOUPD"] != null &&
      attributesData["REQRSNOUP"] != null
    ) {
      if (
        attributesData["REQTYPOUPD"].code == "DataChange" &&
        attributesData["REQRSNOUP"].code == "DTACHG"
      ) {
        setSectionTabHide(false);
      }
    } else {
      setSectionTabHide(true);
    }
  }, [attributesData["REQTYPOUPD"], attributesData["REQRSNOUP"]]);

  // Getting dynamic sections
  useEffect(() => {
    if (
      attributesData["REQEMPLID"] != null &&
      attributesData["REQTABS"] != null
    ) {
      setSectionValuesArray([]); //imp
      (async () => {
        await showLoader();
        const section = await APICall(getSectionDetailsV2, "POST", {
          MN: "EmployeeCentral",
          IN: "EC_Form",
          TN: attributesData["REQTABS"].code,
          UID: userDetails.Id,
          TID: parseInt(attributesData["REQEMPLID"].value),
          //RoleId: userDetails?.UserRoles[0]?.RoleId,
          RoleId: currentRoleId?.value, 
          COU: userDetails.COU,
          //COU: attributesData["REQCNTRY"] ? attributesData["REQCNTRY"].code : "",
          IsNewHire: true,
        });

        if (
          section.data !== null &&
          section.data.t !== undefined &&
          section.data.t.tn === attributesData["REQTABS"].code
        ) {
          setFormData(section.data.t);
        } else {
          setFormData({});
        }

        await hideLoader();
      })();
      (async () => {
        showLoader();
        let bodyObj = {
          mn: "EmployeeCentral",
          in: "EC_Form",
          tn: attributesData["REQTABS"].code,
          SN: "Basic",
          TID: parseInt(attributesData["REQEMPLID"].value),
          ISH: "true",
          UserId: userDetails?.Id,
          //RoleId: userDetails?.UserRoles[0]?.RoleId,
          RoleId: currentRoleId?.value,
          COU: userDetails.COU,
          //COU: attributesData["REQCNTRY"] ? attributesData["REQCNTRY"].code : "",
        };

        const response = await APICall(
          getAttributeValueDetailsV2,
          "POST",
          bodyObj
        );

        if (response.data.sections && response.data.sections.length > 0) {
          setSectionValuesArray(response.data.sections);
        } else {
          setSectionValuesArray([]);
        }
        hideLoader();
      })();
    }
    if (attributesData["REQEMPLID"] != null) {
      (async () => {
        await showLoader();
        const globalValues = await APICall(getGlobalValues, "POST", {
          TID: attributesData["REQEMPLID"].value,
        });

        if (globalValues.data !== null && globalValues.data.length > 0) {
          setGlobalValues(globalValues.data);
        }
        await hideLoader();
      })();
    }
  }, [attributesData["REQEMPLID"], attributesData["REQTABS"]]);

  useEffect(() => {
    if (
      attributesData["REQEMPLID"] != null &&
      attributesData["REQTABS"] != null &&
      attributesData["REQSECT"] != null &&
      formData != null
    ) {
      (async () => {
        await showLoader();

        let setFinalSections = [];
        let sectArr = [];
        attributesData["REQSECT"]?.map((eachSect) => {
          sectArr.push(eachSect?.value);
        });

        sectArr.map((eachId) => {
          const settingVal = formData?.S?.find((item) => item?.SId == eachId);
          setFinalSections.push(settingVal);
        });

        if (finalformData != null || finalformData != undefined) {
          setFinalFormData(setFinalSections);
        }
        await hideLoader();
      })();
    }
  }, [formData, attributesData["REQSECT"]]);

  const fileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleDownloadFileFlat = async (fileAttribute, fileIndex) => {
    showLoader();
    if (
      fileAttribute.DocId != undefined &&
      fileAttribute.DocId != null &&
      fileAttribute.DocId != 0
    ) {
      const doc = await APICall(downloadSupportingDocForProgression, "POST", {
        AC: fileAttribute.Data,
      });
      if (doc && doc.data !== null) {
        downloadBase64File(doc.data, fileAttribute.Name);
        hideLoader();
      } else {
        notify(1, "Something Went Wrong.");
        hideLoader();
      }
    } else {
      downloadBase64File(fileAttribute.Data, fileAttribute.Name);
      hideLoader();
    }
  };
  function downloadBase64File(base64String: string, fileName: string) {
    const mimeType = inferMimeType(base64String);
    const blob = base64toBlob(base64String, mimeType);

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  }
  function inferMimeType(base64String: string): string {
    const dataUrl = `data:application/octet-stream;base64,${base64String}`;
    const typeInfo = /^data:(.*?);/.exec(dataUrl);

    if (typeInfo && typeInfo[1]) {
      return typeInfo[1];
    }

    return "application/octet-stream";
  }
  function base64toBlob(base64String: string, mimeType: string): Blob {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], { type: mimeType });
  }

  const [selectedFilesForFlat, setSelectedFilesForFlat] = useState<
    {
      AC: string;
      Name: string;
      Data: string;
      IsValidFile: boolean;
      DocId: Number;
    }[]
  >([]);

  const handleFileChangeForFlat = async (e, attribute) => {
    const { FS, FX, FC } = JSON.parse(attribute.V);

    const files = Array.prototype.slice.call(e.target.files);

    const isValid = true;
    if (isValid) {
      if (files && files.length > 0) {
        const base64Array: { Name: string; Data: string }[] = [];
        for (let i = 0; i < files.length; i++) {
          const base64 = (await fileToBase64(files[i])) as string;
          const base64WithoutPrefix = base64.split(",")[1];
          base64Array.push({
            Name: files[i].name,
            Data: base64WithoutPrefix,
          });
        }

        //check if AC present and file count matched

        const fileCount = selectedFilesForFlat.reduce((total, item) => {
          if (item.AC === attribute.AC) {
            return total + 1;
          }
          return total;
        }, 0);

        if (fileCount == FC) {
          notify(1, `Only ${FC} files allowed!`);
        } else {
          base64Array.forEach((file) => {
            setSelectedFilesForFlat((prevSelectedFiles) => [
              ...prevSelectedFiles,
              {
                AC: attribute.AC,
                Name: file.Name,
                Data: file.Data,
                IsValidFile: false,
                DocId: 0,
              },
            ]);
          });
        }
      }
    } else {
      e.target.value = "";
    }
  };

  const handleSubmitButton = async (event) => {
    event.preventDefault();
    setFormErrors({});
    setDynamicformErrors({});
    //check validation before json stringify
    let isValidDynamic = true;
    if (dynamicAccordians.length > 0) {
      isValidDynamic = await checkDynamicAccValidation();
    }

    if (isValidDynamic) {
      const stringifiedJson = await jsonStringify();
      const SaveData = await APICall(updateProgressionOnSave, "POST", {
        Id: workflowData.ProgressionId,
        RequestNewJson: stringifiedJson,
        CreatedBy: attributesData["WRKFLINID"],
      });

      const TaskCreated = await APICall(createTaskForApprovers, "POST", {
        WorkflowInstanceId: workflowData.WorkflowInstanceId,
        RequestId: workflowData.RequestId,
        InitiatorId: attributesData["WRKFLINID"],
        ProgressionId: workflowData.ProgressionId,
        NewJsonReq: stringifiedJson,
      });
      if (TaskCreated != null && TaskCreated.data != null) {
        if (TaskCreated.data.Created != 0) {
          notify(TaskCreated.status, TaskCreated.message);
          navigate("/home");
        } else {
          notify(1, "Task Creation Failed");
        }
      }
    } else {
      console.log("NOT VALID");
      await hideLoader();
      notify(1, "Please Fill required Data.");
    }
  };

  const GetDataOnWorkflowTaskId = async (
    optForType,
    optForSubType,
    optForCt,
    optForPayroll,
    roleIdForMgTransaction
  ) => {
    try {
      showLoader();
      const Id = workflowTaskId;

      const Workflowdata = await APICall(
        getApproverAndProgressionDetailsByWorkflowTaskId,
        "POST",
        { Id }
      );

      if (
        Workflowdata?.data === null ||
        Workflowdata?.data == undefined ||
        optionsObj == null ||
        !Workflowdata?.data ||
        !Workflowdata?.data ||
        !optionsObj
      ) {
        console.error("No WFL");
        return;
      }

      //add supporting doc
      if (
        Workflowdata.data.supportingDoc !== "" &&
        Workflowdata.data.supportingDoc !== null
      ) {
        let supportingDoc = JSON.parse(Workflowdata.data.supportingDoc);

        supportingDoc = supportingDoc.map((doc) => ({
          ...doc,
          IsValidFile: true,
        }));

        setSelectedFilesForFlat(supportingDoc);
      }

      //add section doc for data change
      if (
        Workflowdata.data.sectionDoc !== "" &&
        Workflowdata.data.sectionDoc !== null
      ) {
        let sectionDoc = JSON.parse(Workflowdata.data.sectionDoc);
        sectionDoc = sectionDoc.map((doc) =>
          doc.IsActive ? { ...doc, IsValidFile: true } : doc
        );
        updateDocument(sectionDoc);
      } else {
        updateDocument([]);
      }

      setWorkflowData(Workflowdata?.data);

      //getting employee data
      const empobj = await getSearchableDropdownAPI(
        "",
        "IOPPOS",
        null,
        Workflowdata.data.EmployeeId,
        "attribute",
        0
      );

      //filter selected option
      const wflType = optForType.find(
        (eachOpt) => eachOpt.value == Workflowdata.data.WorkflowTypeId
      );
      let wflSubType = optForSubType.find(
        (eachOpt) => eachOpt.value == Workflowdata.data.WorkflowSubTypeId
      );
      const cntry = optForCt.find(
        (eachOpt) => eachOpt.value == Workflowdata.data.ProgressionCountryId
      );
      const payroll = optForPayroll.find(
        (eachOpt) =>
          eachOpt.value == Workflowdata.data.ProgressionPayrollLocationId
      );

      //for Transfer Event
      const newLabel = Workflowdata?.data.reasonNewLabel;
      if (wflSubType?.code == "Transfer" && newLabel){
        wflSubType.label = newLabel;
      }
      //------------------

      // setting employee code
      setTId(empobj?.value);

      const empName = Workflowdata.data.EmployeeName;
      const effDate = Workflowdata.data.EffectiveDate;
      const rem = Workflowdata.data.ProgressionRemarks
        ? Workflowdata.data.ProgressionRemarks
        : "";
      const iniId = Workflowdata.data.InitiatorId; //initiator id
      const iniName = Workflowdata.data.InitiatorName; //initiator name
      const RequestNewJson = JSON.parse(Workflowdata?.data?.RequestNewJson);

      let tabValue = null;
      let sectionValue = null;
      if (wflType?.code == "DataChange" && wflSubType?.code == "DTACHG") {
        const TabSectionMapping = JSON.parse(
          Workflowdata.data?.TabSectionMappingJson
        );

        if (Array.isArray(RequestNewJson)) {
          const result = {};
          // debugger;
          RequestNewJson.forEach((item) => {
            const sectionId = item.sectionId;
            const code = item.Code;
            const attribute = item.Attribute[0];
            const attributeValue = item.AttributeValue[0];
            const Main = item.Main[0];

            if (!result[sectionId]) {
              result[sectionId] = {};
            }

            if (item.IsAttributeSet) {
              if (!result[sectionId][code]) {
                result[sectionId][code] = [];
              }
              result[sectionId][code].push(Main);
            } else {
              result[sectionId][code] = Main[code];
            }
          });

          setAllSectionsData(result);
        }

        const tempSectionData = TabSectionMapping?.section || [];
        const tempTabData = TabSectionMapping?.tab || [];
        const optForTab = await getDdData("REQTABS", "", roleIdForMgTransaction);

        const tabSet = optForTab?.find(
          (item) => item?.code == tempTabData?.code
        );

        tabValue = tabSet;

        const optForSect = await getDdData("REQSECT", tabSet.value, roleIdForMgTransaction);

        const filteredArray = optForSect.filter((obj1) =>
          tempSectionData.some((obj2) => obj2?.value === obj1?.value)
        );

        sectionValue = filteredArray;

        setOptionsObj((prev) => ({
          ...prev,
          ["REQTABS"]: optForTab,
          ["REQSECT"]: optForSect,
        }));
        const secIdForEss = Workflowdata.data.WorkflowSectionId ? Workflowdata.data.WorkflowSectionId : null;
        setSectionIDforEss(secIdForEss);
      }

      setAttributesData((prev) => ({
        ...prev,
        ["REQCNTRY"]: cntry,
        ["REQPLOCN"]: payroll,
        ["REQTYPOUPD"]: wflType,
        ["REQRSNOUP"]: wflSubType,
        ["REQTABS"]: tabValue,
        ["REQSECT"]: sectionValue,
        ["REQEMPLID"]: empobj,
        ["REQEMPLNM"]: empName,
        ["REQEFCTVDT"]: effDate,
        ["REQSUPDOC"]: "",
        ["REQDREMK"]: rem,
        ["WRKFLINID"]: iniId,
        ["WRKFLINNM"]: iniName,
      }));

      //Customisation For Resignation
      const ProgressionID = Workflowdata.data.ProgressionId;
      setProgressionId(ProgressionID);

      if (wflType.code == "Separation" || wflType.code == "Termination") {
        const RepMgData = await APICall(getRepMgMapForEmployee, "POST", {
          ProgressionID,
        });

        setMapTypeVal(RepMgData.data.id);
        setRepMgMap(parseJSON(RepMgData.data.value));

        const EmpRlData = await APICall(getEmpRlMapForEmployee, "POST", {
          ProgressionID,
        });

        setRlMapTypeVal(EmpRlData.data.id);
        setEmpRlMap(parseJSON(EmpRlData.data.value));
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  const jsonStringify = async () => {
    let newJsonObj = [];
    Object.keys(dynamicAccordians).length > 0 &&
      dynamicAccordians.map((eachAttr) => {
        if (eachAttr.attributeDatatype == "DropdownSingle") {
          const tempNewObj = {
            [eachAttr.attributeCode]:
              eachAttr.valueOnDd == undefined || eachAttr.valueOnDd == null
                ? ""
                : parseInt(eachAttr.valueOnDd.value),
          };
          newJsonObj.push(tempNewObj);
        }
        if (eachAttr.attributeDatatype == "Text") {
          const tempNewObj = {
            [eachAttr.attributeCode]:
              eachAttr.valueOnDd == undefined || eachAttr.valueOnDd == null
                ? ""
                : eachAttr.valueOnDd,
          };
          newJsonObj.push(tempNewObj);
        }
        if (eachAttr.attributeDatatype == "Number") {
          const tempNewObj = {
            [eachAttr.attributeCode]:
              eachAttr.valueOnDd == undefined || eachAttr.valueOnDd == null
                ? ""
                : parseInt(eachAttr.valueOnDd),
          };
          newJsonObj.push(tempNewObj);
        }
        if (eachAttr.attributeDatatype == "Date") {
          const parsedDateOld = moment(eachAttr.valueOnDd, "DD-MMM-YYYY");
          const formattedDateOld = parsedDateOld.format("YYYY-MM-DD");
          const tempNewObj = {
            [eachAttr.attributeCode]:
              eachAttr.valueOnDd == undefined ||
              eachAttr.valueOnDd == null ||
              formattedDateOld == "Invalid date"
                ? ""
                : formattedDateOld,
          };
          newJsonObj.push(tempNewObj);
        }
        if (eachAttr.attributeDatatype == "SearchableDropdownSingle") {
          const tempNewObj = {
            [eachAttr.attributeCode]:
              eachAttr.valueOnDd == undefined || eachAttr.valueOnDd == null
                ? ""
                : parseInt(eachAttr.valueOnDd.value),
          };
          newJsonObj.push(tempNewObj);
        }
      });

    const newJson = JSON.stringify(newJsonObj);

    return newJson;
  };

  const handleSaveButton = async (event) => {
    event.preventDefault();

    setFormErrors({});
    setDynamicformErrors({});

    let isValidDynamic = true;
    if (dynamicAccordians.length > 0) {
      isValidDynamic = await checkDynamicAccValidation();
    }

    if (isValidDynamic) {
      const stringifiedJson = await jsonStringify();

      const SaveData = await APICall(updateProgressionOnSave, "POST", {
        Id: workflowData.ProgressionId,
        RequestNewJson: stringifiedJson,
        CreatedBy: attributesData["WRKFLINID"],
      });

      if (SaveData != null && SaveData.data != null) {
        if (SaveData.data.Created != 0) {
          notify(SaveData.status, "Draft Saved");
          //navigate("/home");
        }
      }
    } else {
      console.log("NOT VALID");
      await hideLoader();
      notify(1, "Please Fill required Data.");
    }
  };

  const checkValidation = async () => {
    let formCount = 0;
    if (attributesData["REQEFCTVDT"] == null) {
      formCount += 1;
      setFormErrors((prev) => ({
        ...prev,
        ["REQEFCTVDT"]: attributeValidation["REQEFCTVDT"],
      }));
    }
    return formCount != 0 ? false : true;
  };

  const checkDynamicAccValidation = async () => {
    let formCount = 0;
    dynamicAccordians.map((eachAttr) => {
      if (eachAttr.attributeDatatype == "DropdownSingle") {
        if (eachAttr.valueOnDd == null || eachAttr.valueOnDd == undefined) {
          formCount += 1;
          setDynamicformErrors((prev) => ({
            ...prev,
            [eachAttr.attributeCode]: "Mandatory",
          }));
        }
      } else if (
        eachAttr.attributeDatatype == "Text" ||
        eachAttr.attributeDatatype == "Number"
      ) {
        if (
          eachAttr.valueOnDd == null ||
          eachAttr.valueOnDd == undefined ||
          eachAttr.valueOnDd == ""
        ) {
          formCount += 1;
          setDynamicformErrors((prev) => ({
            ...prev,
            [eachAttr.attributeCode]: "Mandatory",
          }));
        }
      } else if (eachAttr.attributeDatatype == "Date") {
        if (
          eachAttr.valueOnDd == null ||
          eachAttr.valueOnDd == undefined ||
          eachAttr.valueOnDd == ""
        ) {
          formCount += 1;
          setDynamicformErrors((prev) => ({
            ...prev,
            [eachAttr.attributeCode]: "Mandatory",
          }));
        }
      } else if (eachAttr.attributeDatatype == "SearchableDropdownSingle") {
        if (
          eachAttr.valueOnDd == null ||
          eachAttr.valueOnDd == undefined ||
          eachAttr.valueOnDd == ""
        ) {
          formCount += 1;
          setDynamicformErrors((prev) => ({
            ...prev,
            [eachAttr.attributeCode]: "Mandatory",
          }));
        }
      }
    });
    return formCount != 0 ? false : true;
  };

  useEffect(() => {
    console.log("PROGression Id: ", progressionId);
  }, [progressionId]);

  //REVOKE Functionality
  const [revokeButtonHide, setRevokeButtonHide] = useState(true);
  const [revokeButtonDisabled, setRevokeButtonDisabled] = useState(true);
  const [progressionDetails, setProgressionDetails] = useState<any>(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(()=>{
    if( 
      attributesData["REQTYPOUPD"] != null && attributesData["REQTYPOUPD"] != undefined 
      && attributesData["REQEMPLID"] != null && attributesData["REQEMPLID"] != undefined 
      && progressionId != null && progressionId != undefined 
    ){
      (async () => {
        if (attributesData["REQTYPOUPD"].code == 'Separation'){
          const progressiondetails = await APICall(
            getProgressionDataBasedOnId,
            "POST",
            { Id: progressionId }
          );
          const progDet = progressiondetails.data;
          setProgressionDetails(progDet);
          setRevokeButtonHide(false);
          // console.log('Progression Data:::',progDet);

          if(userDetails?.Id !== initiatorIdOnWflTaskId){
            setRevokeButtonDisabled(true);
          } else if((progDet.approvedStatus==null || progDet.approvedStatus==false) 
            && (progDet.workflowInstanceStatus == 'InProgress' || progDet.workflowInstanceStatus == 'Inprogress')){
            setRevokeButtonDisabled(false);
          }else{
            setRevokeButtonDisabled(true);
          }
        } 
      })();
    }
  }, [attributesData["REQTYPOUPD"], attributesData["REQEMPLID"], progressionId]);

  const closeConfirmationModal = async (confirmation) => {
    setShowConfirmationModal(false);
    if(confirmation === true){
      await handleRevokeFunction();
    }
  };
  const handleRevokeFunction = async()=>{
    let oldValues = JSON.parse(progressionDetails.oldJsonForRevoke);
    let postObj = {};
    postObj = {
      ...postObj,
      'Id' : attributesData["REQEMPLID"]?.value,
      'ProgressionId' : progressionId,
    }
    oldValues.map((obj)=>{
      const item = Object.keys(obj)[0];
      if(item == 'NPIM'){
        postObj = {
          ...postObj,
          [item] : obj[item]
        }
      } else { 
        postObj = {
          ...postObj,
          [item] : null
        }
      }
    })

    let oldRepMgMap = JSON.parse(progressionDetails.reporteeManagerJson);
    let RpMgArray = [];
    if(oldRepMgMap.length>0){
      oldRepMgMap.map((obj)=>{
        const item = Object.keys(obj);
        const addtojson = {
          [item[0]] : obj[item[0]],
          [item[1]] : progressionDetails.empId,
        }
        RpMgArray.push(addtojson);
      });
    }
    
    let oldEmpRlMap = JSON.parse(progressionDetails.employeeRelationJson);
    let EmpRlArray = [];
    if(oldEmpRlMap.length>0){
      oldEmpRlMap.map((obj)=>{
        const item = Object.keys(obj);
        const addtojson = {
          [item[0]] : obj[item[0]],
          [item[1]] : progressionDetails.progCreatedById,
        }
        EmpRlArray.push(addtojson);
      });
    }
    
    postObj = {
      ...postObj,
      'RpMgArray' : RpMgArray,
      'EmpRlArray' : EmpRlArray,
    }

    // console.log('Post Object::::', postObj);
    const progressiondetails = await APICall(submitProgressionRevoke, "POST", postObj);

    const revokedetails = await APICall(changeIsRevokedStatusForEResignation, "POST", { Id : progressionId });
    const worklineResp = await APICall(updateEventAndReasonInWorkline, "POST", {
      Eid: parseInt(attributesData["REQEMPLID"].value),
      WorkFlowTypeId: attributesData["REQTYPOUPD"].value,
      WorkFlowSubTypeId: attributesData["REQRSNOUP"].value,
      CreatedBy: attributesData["WRKFLINID"],
      WorkFlowEffectiveDate: moment(attributesData["REQEFCTVDT"]).format('YYYY-MM-DD'),
    });
    notify(0, "Resignation Revoked!");
    navigate("/home");
  };

  return (
    <>
      <div className="my-3 clearfix d-flex justify-content-end mr-3">
        {/* Yawar to be commented from here*/}
        <button
          onClick={() => navigate("/home")}
          className="btn btn-primary ml-2">
          <i className="fa-solid fa-arrow-left mr-1"></i> Back
        </button>
        <button
          onClick={(event) => setShowConfirmationModal(true)}
          // disabled={revokeButtonDisabled}
          className="btn btn-darkGreen-success ml-2"
        hidden={revokeButtonHide ? revokeButtonHide : revokeButtonDisabled}
        >
          <i className="fa-solid fa-multiply"></i> Withdraw
        </button>
        {/* Till Here  */}

        {/* <button
          onClick={(event) => handleSubmitButton(event)}
          disabled={true}
          className="btn btn-filter-submit float-right ml-2">
          <i className="fa-solid fa-check"></i> Submit
        </button> */}
      </div>
      <div className="col-lg-12">
        <Accordion
          elevation={0}
          className="mb-3"
          expanded={accordionReqDet}
          onChange={() => setAccordionReqDet((prev) => !prev)}>
          <AccordionSummary
            id="profile-accordion"
            style={{ background: "#3C5464" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}>
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <p style={{ fontSize: "16px" }}>Request Details</p>
              </div>
              <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
            </div>
          </AccordionSummary>

          <AccordionDetails className="page_heading">
            <div className="row">
              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Effective From Date
                    <sup>*</sup>
                  </label>
                  <DateForm
                    isDisabled={true}
                    value={
                      attributesData["REQEFCTVDT"]
                        ? attributesData["REQEFCTVDT"]
                        : null
                    }
                    onChange={(date) => {
                      setAttributesData((prev) => ({
                        ...prev,
                        ["REQEFCTVDT"]: moment(date).format("DD-MMM-YYYY"),
                      }));
                    }}
                  />
                </div>
                {formErrors["REQEFCTVDT"] && (
                  <p style={{ color: "red" }}>{formErrors["REQEFCTVDT"]}</p>
                )}
              </div>

              <div className={"col-lg-3 col-sm-3 col-xs-4"}
                hidden={attributesData["REQTYPOUPD"]?.code == 'Separation' 
                || attributesData["REQTYPOUPD"]?.code == 'ResignationWithdraw' ? true : false}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Country
                    <sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={true}
                    value={
                      attributesData["REQCNTRY"]
                        ? attributesData["REQCNTRY"]
                        : null
                    }
                    placeholder={"Country"}
                    options={
                      optionsObj["REQCNTRY"] ? optionsObj["REQCNTRY"] : []
                    }
                    onChange={async (e) => {}}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
              </div>

              <div
                className={"col-lg-3 col-sm-3 col-xs-4"}
                hidden={
                  attributesData["REQCNTRY"] &&
                  attributesData["REQCNTRY"].code == "CODECNTRY7"
                    ? false
                    : true
                }>
                <div className="mb-1">
                  <label className="col-form-label">
                    Payroll Location
                    {attributesData["REQCNTRY"] &&
                      attributesData["REQCNTRY"].code == "CODECNTRY7" && (
                        <sup>*</sup>
                      )}
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={true}
                    value={
                      attributesData["REQPLOCN"]
                        ? attributesData["REQPLOCN"]
                        : null
                    }
                    placeholder={"Payroll Location"}
                    options={[]}
                    onChange={async (e) => {}}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
                {formErrors["REQPLOCN"] && (
                  <p style={{ color: "red" }}>{formErrors["REQPLOCN"]}</p>
                )}
              </div>

              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Event
                    <sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={true}
                    value={
                      attributesData["REQTYPOUPD"]
                        ? attributesData["REQTYPOUPD"]
                        : null
                    }
                    placeholder={"Type Of Update"}
                    options={
                      optionsObj["REQTYPOUPD"] ? optionsObj["REQTYPOUPD"] : []
                    }
                    onChange={async (e) => {
                      setAttributesData((prev) => ({
                        ...prev,
                        ["REQTYPOUPD"]: e,
                        ["REQRSNOUP"]: null,
                        ["REQTABS"]: null,
                        ["REQSECT"]: null,
                      }));
                      setFinalFormData({});
                      if (e) {
                        const optForReason = await getDdData(
                          "REQRSNOUP",
                          e.code
                        );
                        setOptionsObj((prev) => ({
                          ...prev,
                          ["REQRSNOUP"]: optForReason,
                        }));
                        setapproverOpt({});
                        setApprovers([]);
                        setBtnHide(true);
                        setDynamicAccordian({});
                        setDynamicformErrors({});
                      } else {
                        setOptionsObj((prev) => ({
                          ...prev,
                          ["REQRSNOUP"]: [],
                        }));
                        setapproverOpt({});
                        setApprovers([]);
                        setBtnHide(true);
                        setDynamicAccordian({});
                        setDynamicformErrors({});
                      }
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
                {formErrors["REQTYPOUPD"] && (
                  <p style={{ color: "red" }}>{formErrors["REQTYPOUPD"]}</p>
                )}
              </div>

              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Event Reason
                    <sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={true}
                    value={
                      attributesData["REQRSNOUP"]
                        ? attributesData["REQRSNOUP"]
                        : null
                    }
                    placeholder={"Reason Of Update"}
                    options={
                      optionsObj["REQRSNOUP"] ? optionsObj["REQRSNOUP"] : []
                    }
                    onChange={(e) => {
                      setAttributesData((prev) => ({
                        ...prev,
                        ["REQRSNOUP"]: e,
                        ["REQTABS"]: null,
                        ["REQSECT"]: null,
                      }));
                      setApprovers([]);
                      setapproverOpt({});
                      setBtnHide(true);
                      setDynamicAccordian({});
                      setDynamicformErrors({});
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
                {formErrors["REQRSNOUP"] && (
                  <p style={{ color: "red" }}>{formErrors["REQRSNOUP"]}</p>
                )}
              </div>

              <div
                className={"col-lg-3 col-sm-3 col-xs-4"}
                hidden={sectionTabHide}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Tab
                    <sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={true}
                    value={
                      attributesData["REQTABS"]
                        ? attributesData["REQTABS"]
                        : null
                    }
                    placeholder={"Tab"}
                    options={optionsObj["REQTABS"] ? optionsObj["REQTABS"] : []}
                    onChange={async (e) => {
                      setAttributesData((prev) => ({
                        ...prev,
                        ["REQTABS"]: e,
                        ["REQSECT"]: null,
                      }));
                      if (e) {
                        const optForSect = await getDdData("REQSECT", e.value);
                        setOptionsObj((prev) => ({
                          ...prev,
                          ["REQSECT"]: optForSect,
                        }));
                      } else {
                        setOptionsObj((prev) => ({
                          ...prev,
                          ["REQSECT"]: [],
                        }));
                      }
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
              </div>
              <div
                className={"col-lg-3 col-sm-3 col-xs-4"}
                hidden={sectionTabHide}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Section
                    <sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={true}
                    value={
                      attributesData["REQSECT"]
                        ? attributesData["REQSECT"]
                        : null
                    }
                    placeholder={"Section"}
                    options={optionsObj["REQSECT"] ? optionsObj["REQSECT"] : []}
                    onChange={(e) => {
                      setAttributesData((prev) => ({
                        ...prev,
                        ["REQSECT"]: e,
                      }));
                    }}
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
              </div>

              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Employee ID
                    <sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={true}
                    value={
                      attributesData["REQEMPLID"]
                        ? attributesData["REQEMPLID"]
                        : null
                    }
                    placeholder={"Employee ID"}
                    async
                    options={(searchString, cb) =>
                      getSearchableDropdownAPI(
                        searchString,
                        "IOPPOS",
                        cb,
                        "",
                        "attribute",
                        0
                      )
                    }
                    onChange={(e) => {
                      if (e) {
                        setAttributesData((prev) => ({
                          ...prev,
                          ["REQEMPLID"]: e,
                          ["REQEMPLNM"]: e.label,
                        }));
                        setTId(parseInt(e.value));
                        setApprovers([]);
                        setapproverOpt({});
                        setBtnHide(true);
                        setDynamicAccordian({});
                        setDynamicformErrors({});
                      } else {
                        setAttributesData((prev) => ({
                          ...prev,
                          ["REQEMPLID"]: e,
                          ["REQEMPLNM"]: "",
                        }));
                        setApprovers([]);
                        setapproverOpt({});
                        setBtnHide(true);
                        setDynamicAccordian({});
                        setDynamicformErrors({});
                      }
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
                {formErrors["REQEMPLID"] && (
                  <p style={{ color: "red" }}>{formErrors["REQEMPLID"]}</p>
                )}
              </div>
              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Employee Name
                    <sup>*</sup>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Employee Name"}
                    isDisabled={true}
                    textArea={false}
                    value={
                      attributesData["REQEMPLNM"]
                        ? attributesData["REQEMPLNM"]
                        : ""
                    }
                    onChange={(e) => {
                      setAttributesData((prev) => ({
                        ...prev,
                        ["REQEMPLNM"]: e.target.value,
                      }));
                    }}
                  />
                </div>
                {formErrors["REQEMPLNM"] && (
                  <p style={{ color: "red" }}>{formErrors["REQEMPLNM"]}</p>
                )}
              </div>
              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">Supporting Documents</label>

                  <div className="box position-relative">
                    <input
                      id={`files`}
                      disabled
                      className="form-control inputfile inputfile-6 multiple-inputfile"
                      data-multiple-caption="{count} files selected"
                      type="file"
                      onChange={(e) => {}}
                      value={""}
                    />
                    <label
                      htmlFor={`files`}
                      className="form-control"
                      style={{ width: 0, border: "none" }}>
                      <strong
                        style={{
                          padding: "6px 16px",
                          backgroundColor: "#3c5464",
                          borderRadius: "5px",
                        }}>
                        <i
                          className="fa fa-upload rotate90"
                          aria-hidden="true"></i>
                        {"  "}
                        Upload
                      </strong>{" "}
                    </label>
                  </div>

                  <div className="file-added-list">
                    <ul className="list-unstyle">
                      {selectedFilesForFlat &&
                        selectedFilesForFlat.length > 0 &&
                        selectedFilesForFlat.map(
                          (fileAttribute, attributeIndex) => (
                            <>
                              <li className="list mt-1" key={attributeIndex}>
                                <div className="media">
                                  <div className="media-body text-truncate">
                                    <span className="view-more">
                                      {fileAttribute.Name}
                                    </span>
                                  </div>

                                  <div className="media-right ml-2">
                                    <i
                                      className="fa-solid fa-download"
                                      aria-hidden="true"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleDownloadFileFlat(
                                          fileAttribute,
                                          attributeIndex
                                        )
                                      }></i>
                                  </div>
                                </div>
                              </li>
                            </>
                          )
                        )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className={"col-lg-6 col-sm-12 mobile-view top-m"}>
                <div className="mb-1">
                  <label className="col-form-label">Remarks</label>
                  <InputForm
                    className="form-control"
                    placeholder={"Remarks, if any."}
                    isDisabled={true}
                    textArea={true}
                    value={
                      attributesData["REQDREMK"]
                        ? attributesData["REQDREMK"]
                        : ""
                    }
                    onChange={(e) => {
                      e.preventDefault();
                      setAttributesData((prev) => ({
                        ...prev,
                        ["REQDREMK"]: e.target.value,
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        {progressionId > 0 && (
          <Accordion
            elevation={0}
            className="mb-3"
            expanded={changeLogAccordian}
            onChange={() => setChangeLogAccordian((prev) => !prev)}>
            <AccordionSummary
              id="profile-accordion"
              style={{ background: "#3C5464" }}
              className="text-white acc_close"
              expandIcon={<ExpandMore />}>
              <div className="row d-flex align-items-center acc-heading">
                <div className="col-lg-10 col-md-10 col-sm-12">
                  <p style={{ fontSize: "16px" }}>Change Log</p>
                </div>
                <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
              </div>
            </AccordionSummary>

            <AccordionDetails className="page_heading">
              <div className="">
                <ChangeLog TId={progressionId} />
              </div>
            </AccordionDetails>
          </Accordion>
        )}

        {dynamicAccordians !== null && dynamicAccordians.length > 0 && (
          <Accordion
            elevation={0}
            className="mb-3"
            expanded={accordianEvent}
            onChange={() => setAccordianEvent((prev) => !prev)}>
            <AccordionSummary
              id="profile-accordion"
              style={{ background: "#3C5464" }}
              className="text-white acc_close"
              expandIcon={<ExpandMore />}>
              <div className="row d-flex align-items-center acc-heading">
                <div className="col-lg-10 col-md-10 col-sm-12">
                  <p style={{ fontSize: "16px" }}>
                    {attributesData["REQTYPOUPD"]
                      ? attributesData["REQTYPOUPD"].label
                      : "Event"}
                  </p>
                </div>
                <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
              </div>
            </AccordionSummary>

            <AccordionDetails className="page_heading">
              <div className="row">
                {dynamicAccordians !== null &&
                  dynamicAccordians.length > 0 &&
                  dynamicAccordians.map((attribute, key) => (
                    <React.Fragment key={key}>
                      {attribute.attributeDatatype === "DropdownSingle" ? (
                        <div className="col-lg-3 col-sm-3 col-xs-4"
                          hidden={
                            attributesData["REQCNTRY"]?.code == "CODECNTRY1" &&
                            attributesData["REQTYPOUPD"]?.code == "Promotion" &&
                            attribute.attributeCode == "SC"
                            ? skillcategoryHide
                            : false
                          }
                        >
                          <div className="mb-1">
                            <label className="col-form-label">
                              {attribute.attributeName}
                            </label>
                            {
                            	attribute.attributeCode != "WOA" &&
                                attribute.attributeCode != "WOAR" && 
                                attribute.attributeCode != 'SCN' &&
                                attribute.attributeCode != 'SSCN' &&
                                attribute.attributeCode != 'SSSCN' && 
                            	(<sup>*</sup>)
                            }
                            <SelectForm
                              isSearchable
                              isDisabled={true}
                              value={attribute.valueOnDd}
                              options={attribute.options}
                              placeholder={attribute.attributeName}
                              onChange={(e) => {
                                if (e) {
                                  let tempAcc = [...dynamicAccordians];

                                  let tempAtt = tempAcc.find(
                                    (item) =>
                                      item.attributeCode ==
                                      attribute.attributeCode
                                  );

                                  tempAtt.valueOnDd = e;
                                  setDynamicAccordian(tempAcc);
                                } else {
                                  notify(1, "Cannot Clear the Field");
                                }
                              }}
                              isMulti={false}
                              noIndicator={false}
                              noSeparator={false}
                            />
                          </div>
                          {dynamicformErrors[attribute.attributeCode] && (
                            <p style={{ color: "red" }}>
                              {dynamicformErrors[attribute.attributeCode]}
                            </p>
                          )}
                        </div>
                      ) : attribute.attributeDatatype === "Text" ||
                        attribute.attributeDatatype === "Number" ? (
                        <div className="col-lg-3 col-sm-3 col-xs-4">
                          <div className="mb-1">
                            <label className="col-form-label">
                              {attribute.attributeName}
                            </label>
                            <sup>*</sup>
                            <InputForm
                              className="form-control"
                              isDisabled={true}
                              value={attribute.valueOnDd}
                              placeholder={attribute.attributeName}
                              textArea={false}
                              onChange={(e) => {
                                let tempAcc = [...dynamicAccordians];

                                let tempAtt = tempAcc.find(
                                  (item) =>
                                    item.attributeCode ==
                                    attribute.attributeCode
                                );

                                tempAtt.valueOnDd = e.target.value;
                                setDynamicAccordian(tempAcc);
                              }}
                            />
                          </div>
                        </div>
                      ) : attribute.attributeDatatype === "Date" ? (
                        <div className="col-lg-3 col-sm-3 col-xs-4">
                          <div className="mb-1">
                            <label className="col-form-label">
                              {attribute.attributeName}
                            </label>
                            <sup>*</sup>
                            <DateForm
                              isDisabled={true}
                              value={attribute.valueOnDd}
                              onChange={(date) => {
                                let tempAcc = [...dynamicAccordians];

                                let tempAtt = tempAcc.find(
                                  (item) =>
                                    item.attributeCode ==
                                    attribute.attributeCode
                                );

                                tempAtt.valueOnDd =
                                  date != null
                                    ? moment(date).format("DD-MMM-YYYY")
                                    : null;
                                setDynamicAccordian(tempAcc);
                              }}
                            />
                          </div>
                        </div>
                      ) : attribute.attributeDatatype ===
                        "SearchableDropdownSingle" ? (
                        <div className="col-lg-3 col-sm-3 col-xs-4">
                          <div className="mb-1">
                            <label className="col-form-label">
                              {attribute.attributeName}
                            </label>
                            <sup>*</sup>
                            <SelectForm
                              isClearable
                              isSearchable
                              async
                              isDisabled={true}
                              options={(searchString, cb) =>
                                getSearchableDropdownAPI(
                                  searchString,
                                  attribute.attributeCode,
                                  cb,
                                  "",
                                  "attribute",
                                  0
                                )
                              }
                              placeholder={attribute.attributeName}
                              value={
                                attribute.valueOnDd ? attribute.valueOnDd : null
                              }
                              onChange={(e) => {
                                if (e) {
                                  let tempAcc = [...dynamicAccordians];

                                  let tempAtt = tempAcc.find(
                                    (item) =>
                                      item.attributeCode ==
                                      attribute.attributeCode
                                  );

                                  tempAtt.valueOnDd = e;
                                  setDynamicAccordian(tempAcc);
                                } else {
                                  notify(1, "Cannot Clear the Field");
                                }
                              }}
                              isMulti={false}
                              noIndicator={false}
                              noSeparator={false}
                            />
                            {dynamicformErrors[attribute.attributeCode] && (
                              <p style={{ color: "red" }}>
                                {dynamicformErrors[attribute.attributeCode]}
                              </p>
                            )}
                          </div>
                        </div>
                      ) : attribute.attributeDatatype === "Document" ? (
                        <>
                          <div
                            className={"col-lg-3 col-sm-3 col-xs-4"}
                          >
                            <div className="mb-1">
                              <label className="col-form-label">
                                {attribute.attributeName}
                                  <sup>*</sup>
                              </label>

                              <div className="box position-relative">
                                <input
                                  id={`files-${key}`}
                                  className="form-control inputfile inputfile-6 multiple-inputfile"
                                  data-multiple-caption="{count} files selected"
                                  multiple={true}
                                  ref={FlatDocRef}
                                  disabled={true}
                                  accept={JSON.parse(attribute.attributeValidations).FX}
                                  type="file"
                                  onChange={(e) => {
                                    handleFileChangeForFlat(e, attribute);
                                  }}
                                  value={""}
                                />
                                <label
                                  htmlFor={`files-${key}`}
                                  className="form-control"
                                  style={{ width: 0, border: "none" }}
                                >
                                  <strong
                                    style={{
                                      padding: "6px 16px",
                                      backgroundColor: "#3c5464",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <i
                                      className="fa fa-upload rotate90"
                                      aria-hidden="true"
                                    ></i>
                                    {"  "}
                                    Upload
                                  </strong>{" "}
                                </label>
                              </div>
                              {formErrors[attribute.attributeCode] && (
                                <p style={{ color: "red" }}>
                                  {formErrors[attribute.attributeCode]}
                                </p>
                              )}
                              <div className="file-added-list">
                                <ul className="list-unstyle">
                                  {selectedFilesForFlatProg &&
                                    selectedFilesForFlatProg.length > 0 &&
                                    selectedFilesForFlatProg.map(
                                      (fileAttribute, attributeIndex) => (
                                        <>
                                          {fileAttribute?.AC ===
                                            attribute.attributeCode && (
                                            <li
                                              className="list mt-1"
                                              key={attributeIndex}
                                            >
                                              <div className="media">
                                                <div className="media-body text-truncate">
                                                  <span className="view-more">
                                                    {fileAttribute.Name}
                                                  </span>
                                                </div>
                                                {/*Here code for view same div of trash should be used. ISDOCVIEW/ISDOCDOWN*/}
                                                {/* {
                                                  // section?.ISDOCVIEW &&
                                                  fileAttribute.DocId !==
                                                    0 && (
                                                    <div className="media-right ml-2">
                                                      <i
                                                        className="fa fa-eye"
                                                        aria-hidden="true"
                                                        onClick={() =>
                                                          handleViewForFlat(
                                                            fileAttribute,
                                                            attributeIndex
                                                          )
                                                        }></i>
                                                    </div>
                                                  )
                                                } */}
                                                {
                                                  //  section?.ISDOCDOWN &&
                                                  <div className="media-right ml-2">
                                                    <i
                                                      className="fa-solid fa-download"
                                                      aria-hidden="true"
                                                      onClick={() =>
                                                        handleDownloadFileFlat(
                                                          fileAttribute,
                                                          attributeIndex
                                                        )
                                                      }
                                                    ></i>
                                                  </div>
                                                }
                                                {/* <div className="media-right ml-2">
                                                      <i
                                                        className="fa fa-trash"
                                                        aria-hidden="true"
                                                        onClick={() =>
                                                          handleDeleteFileForFlat(
                                                            fileAttribute,
                                                            attributeIndex
                                                          )
                                                        }
                                                      ></i>
                                                  </div> */}
                                              </div>
                                            </li>
                                          )}
                                        </>
                                      )
                                    )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </React.Fragment>
                  ))}
              </div>

              {attributesData["REQTYPOUPD"] != null &&
                attributesData["REQRSNOUP"] != null &&
                (attributesData["REQTYPOUPD"].code == "Separation" ||
                  attributesData["REQTYPOUPD"].code == "Termination") && (
                  <>
                    <div
                      className="row"
                      hidden={repMgMap.length > 0 ? false : true}>
                      <div className="col-lg-3 col-sm-3 col-xs-4">
                        <div className="mb-1">
                          <label className="col-form-label">
                            {"TRANSFER DIRECT REPORTEE"}
                          </label>
                          <sup>*</sup>
                          <SelectForm
                            isSearchable
                            isClearable
                            isDisabled={true}
                            value={directReportee ? directReportee : null}
                            options={typeOptions}
                            placeholder={"Select a Value"}
                            onChange={() => {}}
                            isMulti={false}
                            noIndicator={false}
                            noSeparator={false}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-3 col-xs-4">
                        <div className="mb-1">
                          <label className="col-form-label">
                            {"Manager Name"}
                          </label>
                          <sup>*</sup>
                          <SelectForm
                            isClearable
                            isSearchable
                            isDisabled={true}
                            options={[]}
                            placeholder={"Select a Manager"}
                            value={managerForReportee}
                            onChange={() => {}}
                            isMulti={false}
                            noIndicator={false}
                            noSeparator={false}
                          />
                        </div>
                      </div>
                    </div>
                    {reporteeManagerMap != null &&
                      reporteeManagerMap.length > 0 && (
                        <div className="row">
                          {reporteeManagerMap.map((eachRep, key) => (
                            <React.Fragment key={key}>
                              <div className="col-lg-3 col-sm-3 col-xs-4">
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    {"Reportee Name"}
                                  </label>
                                  <sup>*</sup>
                                  <SelectForm
                                    isClearable
                                    isSearchable
                                    isDisabled={true}
                                    options={[]}
                                    placeholder={"Select a Manager"}
                                    value={eachRep}
                                    onChange={(e) => {}}
                                    isMulti={false}
                                    noIndicator={false}
                                    noSeparator={false}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-3 col-sm-3 col-xs-4">
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    {"Manager Name"}
                                  </label>
                                  <sup>*</sup>
                                  <SelectForm
                                    isClearable
                                    isSearchable
                                    isDisabled={true}
                                    async
                                    options={(searchString, cb) =>
                                      getSearchableDropdownAPI(
                                        searchString,
                                        "MG",
                                        cb,
                                        "",
                                        "attribute",
                                        0
                                      )
                                    }
                                    placeholder={"Select a Manager"}
                                    value={eachRep.mg ? eachRep.mg : null}
                                    onChange={(e) => {
                                      let tempAcc = [...reporteeManagerMap];
                                      let tempAtt = tempAcc.find(
                                        (item) => item.value == eachRep.value
                                      );
                                      tempAtt.mg = e;
                                      setReporteeManagerMap(tempAcc);
                                    }}
                                    isMulti={false}
                                    noIndicator={false}
                                    noSeparator={false}
                                  />
                                </div>
                              </div>
                            </React.Fragment>
                          ))}
                        </div>
                      )}

                    {/* Job Relation */}
                    <div
                      className="row"
                      hidden={empRlMap.length > 0 ? false : true}>
                      <div className="col-lg-3 col-sm-3 col-xs-4">
                        <div className="mb-1">
                          <label className="col-form-label">
                            {"Transfer Job Relationships"}
                          </label>
                          <sup>*</sup>
                          <SelectForm
                            isSearchable
                            isClearable
                            isDisabled={true}
                            value={directReporteeRel ? directReporteeRel : null}
                            options={typeOptionsRel}
                            placeholder={"Select a Value"}
                            onChange={() => {}}
                            isMulti={false}
                            noIndicator={false}
                            noSeparator={false}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-3 col-xs-4">
                        <div className="mb-1">
                          <label className="col-form-label">
                            {"Search Users"}
                          </label>
                          <sup>*</sup>
                          <SelectForm
                            isClearable
                            isSearchable
                            isDisabled={true}
                            options={[]}
                            placeholder={"Select a User"}
                            value={relationForReportee}
                            onChange={() => {}}
                            isMulti={false}
                            noIndicator={false}
                            noSeparator={false}
                          />
                        </div>
                      </div>
                    </div>

                    {userRelationMap != null && userRelationMap.length > 0 && (
                      <div className="row">
                        {userRelationMap.map((eachRep) => (
                          <>
                            <div className="col-lg-3 col-sm-3 col-xs-4">
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {"Reportee Name"}
                                </label>
                                <sup>*</sup>
                                <SelectForm
                                  isClearable
                                  isSearchable
                                  isDisabled={true}
                                  options={[]}
                                  placeholder={"Select a Reportee"}
                                  value={eachRep.userid}
                                  onChange={(e) => {}}
                                  isMulti={false}
                                  noIndicator={false}
                                  noSeparator={false}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 col-sm-3 col-xs-4">
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {"Realtion Type"}
                                </label>
                                <sup>*</sup>
                                <SelectForm
                                  isClearable
                                  isSearchable
                                  isDisabled={true}
                                  async
                                  options={() => {}}
                                  placeholder={"Select a Type"}
                                  value={
                                    eachRep.relationtype
                                      ? eachRep.relationtype
                                      : null
                                  }
                                  onChange={() => {}}
                                  isMulti={false}
                                  noIndicator={false}
                                  noSeparator={false}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3 col-sm-3 col-xs-4">
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {"Manager Name"}
                                </label>
                                <sup>*</sup>
                                <SelectForm
                                  isClearable
                                  isSearchable
                                  isDisabled={true}
                                  async
                                  options={() => {}}
                                  placeholder={"Select a Manager"}
                                  value={
                                    eachRep.relationid
                                      ? eachRep.relationid
                                      : null
                                  }
                                  onChange={() => {}}
                                  isMulti={false}
                                  noIndicator={false}
                                  noSeparator={false}
                                />
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    )}
                  </>
                )}
            </AccordionDetails>
          </Accordion>
        )}

        {((finalformData != null &&
          finalformData.length > 0 &&
          attributesData["REQEMPLID"] != null &&
          sectionValuesArray.length > 0) ||
          (finalformData != null &&
            finalformData.length > 0 &&
            attributesData["REQEMPLID"] != null &&
            sectionValuesArray.length > 0 &&
            submitFunc)) &&
          finalformData.map((eachSection, index) => (
            <React.Fragment key={`${index}-${eachSection?.SN}`}>
              <SectionCompontentProgressionView
                formData={finalformData}
                setFormData={setFinalFormData}
                section={eachSection}
                sectionIndex={index}
                TId={TId}
                setTId={setTId}
                sectionValuesArray={sectionValuesArray}
                SetSectionValuesArray={setSectionValuesArray}
                Globalv={globalValues}
                COU={userDetails.COU}
                submitFunc={submitFunc}
                setGetData={setGetData}
                allSectionData={allSectionsData}
                payrolldd={attributesData["REQPLOCN"]}
              />
            </React.Fragment>
          ))}

        <Accordion
          elevation={0}
          className="mb-3"
          expanded={accordionWrkfl}
          onChange={() => setAccordionWrkfl((prev) => !prev)}>
          <AccordionSummary
            id="profile-accordion"
            style={{ background: "#3C5464" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}>
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <p style={{ fontSize: "16px" }}>Workflow</p>
              </div>
              <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
            </div>
          </AccordionSummary>

          <AccordionDetails className="page_heading">
            <div className="row">
              {approvers && approvers.length >= 1 && (
                <div className="col-lg-3 col-sm-3 col-xs-4">
                  <div className="mb-1">
                    <label className="col-form-label">Initiator</label>
                    <sup>*</sup>
                    <InputForm
                      value={attributesData["WRKFLINNM"]}
                      placeholder={"Initiator"}
                      isDisabled={true}
                      textArea={false}
                      onChange={() => {}}
                    />
                  </div>
                </div>
              )}
              {/* For Approver noOfApprover */}
              {approvers &&
                approvers.length > 1 &&
                approvers.map(
                  (eachApprover, index) =>
                    index > 0 && (
                      <React.Fragment key={index}>
                        <div className="col-lg-3 col-sm-3 col-xs-4">
                          <div className="dropdown mb-1">
                            <label className="col-form-label">
                              {eachApprover?.roleName 
                              ? eachApprover?.roleName 
                              : `${"Approver"} ${index}`}
                            </label>
                            <sup>*</sup>
                            <SelectForm
                              isClearable
                              isSearchable
                              isDisabled={true}
                              value={allApprovers[`${"Approver"} ${index}`]}
                              options={approverOpt[`${"Approver"} ${index}`]}
                              placeholder={`No Employee Found`}
                              onChange={(e) => {
                                alert("Change");
                              }}
                              isMulti={true}
                              noIndicator={false}
                              noSeparator={false}
                            />
                            <div className="dropdown-content">
                              {approverOpt[`${"Approver"} ${index}`] != null &&
                                approverOpt[`${"Approver"} ${index}`].length >
                                  0 &&
                                approverOpt[`${"Approver"} ${index}`].map(
                                  (eachApp) => <p>{eachApp.label}</p>
                                )}
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    )
                )}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>

      <Modal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">Confirm!</div>
        </div>
        <Modal.Body>
          <h5>{"Do you want to Revoke your Resignation?"}</h5>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-success"
            style={{ marginLeft: 5 }}
            onClick={() => {
              closeConfirmationModal(true);
            }}>
            Yes
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              closeConfirmationModal(false);
            }}>
            No
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewRequisitionDetatils;
