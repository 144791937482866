import React, { useContext, useEffect, useRef, useState } from "react";
import { LoaderContext } from "../../Helpers/Context/Context";
import { APICall } from "../../Helpers/APICalls";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  entryUpdateInActionHistory,
  getUsermasterIdBasedOnEid,
  getAllRoles,
  getWorkflowData,
  getDdForProgression,
  getSearchDdForProgression,
  getAttrForProgression,
  postDataForProgression,
  getReporteesForEmployee,
  getRelationsForEmployee,
  checkIfSeparationIsActive,
  uploadSupportingDocForProgression,
} from "../../Helpers/APIEndPoints/EndPoints";
import notify from "../../Helpers/ToastNotification";
import moment from "moment";
import { ExpandMore } from "@mui/icons-material";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import SelectForm from "../../Components/SelectForm/SelectForm";
import InputForm from "../../Components/InputForm/InputForm";
import DateForm from "../../Components/DateForm/DateForm";
import { useLocation } from "react-router-dom";
import "./Request.css";

const ProgResignation = () => {
  const navigate = useNavigate();
  const [accordionReqDet, setAccordionReqDet] = useState(true);
  const [accordionWrkfl, setAccordionWrkfl] = useState(false);
  const [accordianEvent, setAccordianEvent] = useState(false);
  const [formErrors, setFormErrors] = useState<any>({});
  const [dynamicformErrors, setDynamicformErrors] = useState<any>({});
  const [optionsObj, setOptionsObj] = useState<any>({});
  const [TId, setTId] = useState(0);
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  const FullName = `${userDetails.FirstName} ${userDetails.LastName}`;
  const [approvers, setApprovers] = useState<any>([]);
  const [approverOpt, setapproverOpt] = useState<any>({});
  const [allApprovers, setAllApprovers] = useState<any>({});
  const [dynamicAccordians, setDynamicAccordian] = useState<any>([]);
  const [btnHide, setBtnHide] = useState(true);
  const [sectionTabHide, setSectionTabHide] = useState(true);
  const [formData, setFormData] = useState<any>({});
  const [finalformData, setFinalFormData] = useState<any>({});
  const [empIsDisable, setEmpIsDisable] = useState(true);
  const [effdtNpsdDisable, setEffNpsdDisable] = useState(
    currentRoleId.value == 7 ? true : false
  );
  const [isOnce, setIsonce] = useState(false);
  console.log(currentRoleId);
  const [submitFunc, setSubmitFunc] = useState(false);

  const [directReportee, setDirectReportee] = useState<any>(null);
  const [managerForReportee, setManagerForReportee] = useState<any>(null);
  const [reportees, setReportees] = useState<any>([]);
  const [reporteeManagerMap, setReporteeManagerMap] = useState<any>([]);
  const [managerDisable, setManagerDisable] = useState(false);

  const [optForRoles, setOptForRoles] = useState<any>([]);
  const [directReporteeRel, setDirectReporteeRel] = useState<any>(null);
  const [relationForReportee, setRelationForReportee] = useState<any>(null);
  const [relReportees, setRelReportees] = useState<any>([]);
  const [userRelationMap, setUserRelationMap] = useState<any>([]);
  const [relationDisable, setRelationDisable] = useState(false);

  // value Object for section
  const [attributesData, setAttributesData] = useState<any>({});
  const [attributeValidation, setAttributeValidation] = useState<any>({});
  const { showLoader, hideLoader } = useContext(LoaderContext);

  const [btnDisable, setBtnDisable] = useState(false);
  const [grpJoiningDate, setGrpJoiningDate] = useState<any>(null);
  console.log('gjdatestring::::',grpJoiningDate);

  useEffect(() => {
    if (
      attributesData["REQTYPOUPD"] !== null &&
      attributesData["REQTYPOUPD"] !== undefined &&
      attributesData["REQRSNOUP"] !== null &&
      attributesData["REQRSNOUP"] !== undefined &&
      attributesData["REQEMPLID"] !== null &&
      attributesData["REQEMPLID"] !== undefined
    ) {
      (async () => {
        await showLoader();
        const workflowRes = await APICall(getWorkflowData, "POST", {
          WorkflowTypeId: attributesData["REQTYPOUPD"].value,
          WorkflowSubTypeId: attributesData["REQRSNOUP"].value,
          EmployeeId: attributesData["REQEMPLID"].value,
          InitiatorId: userDetails.Id,
          InitiatorRoleId: currentRoleId?.value,
        });
        if (workflowRes.data.length > 0) {
          setApprovers(workflowRes.data);
          let temp = workflowRes.data;
          let approvercount = 0;
          if (temp.length == 1) {
            setBtnHide(false);
          }
          if (temp.length > 1) {
            temp.map((eachapprover, index) => {
              if (index > 0) {
                if (eachapprover.approvers == null) {
                  approvercount = approvercount + 1;
                }
                setapproverOpt((prev) => ({
                  ...prev,
                  [`${"Approver"} ${index}`]: eachapprover.approvers,
                }));
              }
              setAllApprovers((prev) => ({
                ...prev,
                [`${"Approver"} ${index}`]: eachapprover.approvers,
              }));
              if (
                eachapprover.approvers !== null &&
                eachapprover.approvers.length > 0
              ) {
                setBtnHide(false);
              }
            });

            if (temp.length - 1 == approvercount) {
              notify(1, "No Approvers are there for the workflow!");
              navigate("/home");
            }
          }

          const attrData = await APICall(getAttrForProgression, "POST", {
            WorkFlowId: workflowRes.data[0].workFlowId,
            COU: attributesData["REQEMPLID"].cou,
            userid: userDetails.Id,
            roleid: currentRoleId?.value,
            IsNewHire: true,
            IsProg: true,
            EmployeeId: parseInt(attributesData["REQEMPLID"].value),
          });

          if (attrData.data !== null && attrData.data.length > 0) {
            attrData.data.map(async (eachAttr) => {
              if (eachAttr.attributeDatatype === "DropdownSingle") {
                if (
                  eachAttr.attributeCode == "ST" &&
                  attributesData["REQRSNOUP"].code == "Resignation"
                ) {
                  const valForDD = eachAttr.options.find(
                    (item) => item.code == "CODESEPRTNTYP13"
                  );
                  if (valForDD !== undefined || valForDD !== null) {
                    eachAttr.valueOnDd = valForDD;
                  } else {
                    eachAttr.valueOnDd = null;
                  }
                } else {
                  eachAttr.valueOnDd = null;
                }
              } else if (
                eachAttr.attributeDatatype === "Text" ||
                eachAttr.attributeDatatype === "Number"
              ) {
                eachAttr.valueOnDd = eachAttr.value ? eachAttr.value : "";
              } else if (eachAttr.attributeDatatype === "Date") {
                if (
                  (eachAttr.attributeCode == "DOR" ||
                    eachAttr.attributeCode == "NPSD") &&
                  attributesData["REQRSNOUP"].code == "Resignation"
                ) {
                  const currDate = moment().format("DD-MMM-YYYY");
                  eachAttr.valueOnDd = currDate;
                  eachAttr.value = null;
                } else if (
                  (eachAttr.attributeCode == "NPEDE" ||
                    eachAttr.attributeCode == "LWD") &&
                  attributesData["REQRSNOUP"].code == "Resignation"
                ) {
                  eachAttr.valueOnDd = null;
                  eachAttr.value = null;
                } else {
                  eachAttr.valueOnDd = eachAttr.value
                    ? eachAttr.value.toString()
                    : "";
                  let valueForField = eachAttr.value
                    ? eachAttr.value.toString()
                    : "";
                  const dateString = valueForField;
                  const allowedFormat = ["YYYY-MM-DD"];

                  const isValidDate = moment(
                    dateString,
                    allowedFormat,
                    true
                  ).isValid();
                  if (
                    isValidDate &&
                    dateString !== "0001-01-01" &&
                    dateString !== "0001/01/01"
                  ) {
                    const parsedDate = moment(valueForField, allowedFormat[0]);
                    const formattedDate = parsedDate.format("DD-MMM-YYYY");
                    eachAttr.valueOnDd =
                      eachAttr.attributeCode == "LWD"
                        ? null
                        : formattedDate
                        ? formattedDate
                        : null;
                    eachAttr.value =
                      eachAttr.attributeCode == "LWD"
                        ? null
                        : formattedDate
                        ? formattedDate
                        : null;
                  } else if (
                    dateString === "0001-01-01" ||
                    dateString === "0001/01/01"
                  ) {
                    eachAttr.valueOnDd = null;
                    eachAttr.value = null;
                  }
                }
              } else if (
                eachAttr.attributeDatatype === "SearchableDropdownSingle"
              ) {
                eachAttr.valueOnDd = null;
                if (
                  eachAttr.value != null &&
                  eachAttr.value != undefined &&
                  eachAttr.value != ""
                ) {
                  const obj = await getSearchableDropdownAPI(
                    "",
                    eachAttr.attributeCode,
                    null,
                    eachAttr.value,
                    "attribute",
                    0
                  );

                  eachAttr.valueOnDd = obj;
                }
              }
            });
            setDynamicAccordian(attrData.data);
          }
        }
        await hideLoader();
      })();
    }
  }, [
    attributesData["REQTYPOUPD"],
    attributesData["REQRSNOUP"],
    attributesData["REQEMPLID"],
  ]);
  useEffect(() => {
    if (!isOnce) {
      if (
        dynamicAccordians != null &&
        dynamicAccordians != undefined &&
        dynamicAccordians.length > 0
      ) {
        const npim = dynamicAccordians.find(
          (item) => item.attributeCode == "NPIM"
        );
        const npsd = dynamicAccordians.find(
          (item) => item.attributeCode == "NPSD"
        );

        if (
          npsd != null &&
          npsd != undefined &&
          npim != null &&
          npim != undefined
        ) {
          const currDate = moment();
          let tempAcc = [...dynamicAccordians];
          let tempAtt = tempAcc.find((item) => item.attributeCode == "NPEDE");
          tempAtt.valueOnDd =
            currDate != null
              ? moment(GetNPEndDate(currDate)).format("DD-MMM-YYYY")
              : null;
          setDynamicAccordian(tempAcc);
          setIsonce(true);
        }
      }
    }
  }, [dynamicAccordians]);

  console.log("dynamic acc", dynamicAccordians);

  const getDdData = async (AC, PID) => {
    await showLoader();
    const GenId = currentRoleId?.value; //Role Id of the User
    const dropdownData = await APICall(getDdForProgression, "POST", {
      AC,
      PID,
      UserId: proxyUserDetails?.value
        ? proxyUserDetails?.value
        : userDetails?.Id,
      GenId,
    });

    if (
      dropdownData.data !== null &&
      dropdownData.data !== undefined &&
      dropdownData.data.length > 0
    ) {
      await hideLoader();
      return dropdownData.data;
    } else {
      await hideLoader();
      return [];
    }
  };

  const getSearchableDropdownAPI = async (
    searchString,
    AC,
    cb,
    valueForDropdown,
    type,
    gridId
  ) => {
    if (
      searchString === "" &&
      cb === null &&
      valueForDropdown !== "" &&
      AC !== ""
    ) {
      const response = await APICall(getSearchDdForProgression, "POST", {
        searchString,
        AC,
        valueForDropdown,
      });

      if (response.data !== null && response.data.length > 0) {
        if (response.data.length === 1) {
          return response.data[0];
        }
      }
    } else if (searchString.length > 1) {
      const response = await APICall(getSearchDdForProgression, "POST", {
        searchString,
        AC,
        InitiatorId: parseInt(userDetails.Id),
        InitiatorRoleId: currentRoleId?.value,
      });
      if (response.data !== null && response.data.length > 0) {
        cb(response.data);
        setOptionsObj((prev) => ({ ...prev, ["REQEMPLID"]: response.data }));
      } else {
        cb([]);
        setOptionsObj((prev) => ({ ...prev, ["REQEMPLID"]: [] }));
      }
    }
  };

  //CALL A USE-EFFECT TO GET DATA FROM DATABASE
  useEffect(() => {
    setAttributeValidation((prev) => ({
      ["REQEFCTVDT"]: "Please Select a date",
      ["REQDREMK"]: "Remarks are Mandatory.",
    }));
    (async () => {
      const optForType = await getDdData("REQTYPOUPD", "");
      const forRoles = await APICall(getAllRoles, "POST", {});
      setOptForRoles(forRoles.data);

      const sepObj = optForType.find((item) => item.code == "Separation");
      const optForSubType = await getDdData("REQRSNOUP", sepObj.code);

      setOptionsObj((prev) => ({
        ...prev,
        ["REQTYPOUPD"]: optForType,
        ["REQRSNOUP"]: optForSubType,
      }));

      const currDate = moment().format("DD-MMM-YYYY");

      setAttributesData((prev) => ({
        ...prev,
        ["REQTYPOUPD"]: sepObj,
        ["REQRSNOUP"]: null,
        ["REQEMPLID"]: null,
        ["REQEMPLNM"]: "",
        ["REQEFCTVDT"]: currDate,
        ["REQSUPDOC"]: "",
        ["REQDREMK"]: "",
        ["WRKFLINID"]: userDetails.Id,
        ["WRKFLINNM"]: FullName,
      }));
    })();
  }, []);

  const fileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const supportingDocRef = useRef(null);
  const [proSupportingDoc, setProSupportingDoc] = useState<
    {
      AC: string;
      Name: string;
      Data: string;
      IsValidFile: boolean;
      DocId: Number;
    }[]
  >([]);

  const handleFileValidation = (files) => {
    const [FS, FX, FC] = [
      5,
      ".docx,.doc,.pdf,.png,.jpg,.jpeg,.txt,.ppt,.pptx,.xlsx",
      1,
    ];

    // Split the validExtensionsString into an array
    const validExtensions = FX.split(",");

    let isValid = [];

    // validate file count
    if (files.length > FC) {
      notify(1, `Only ${FC} files allowed!`);
      return false;
    }
    // check extension of each file
    files.forEach((file) => {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();

      // Check if the file extension is allowed
      if (!validExtensions.includes("." + fileExtension)) {
        isValid.push(false);
      } else {
        isValid.push(true); //valid
      }
    });

    let returnValue = isValid.some((v) => v === false);

    if (returnValue) {
      notify(1, `Please upload file within this ${FX} extentsion`);
      return false;
    }

    files.forEach((file) => {
      const fileSize = file.size;
      const maxFileSizeBytes = FS * 1024 * 1024;
      if (fileSize <= maxFileSizeBytes) {
        isValid.push(true); //valid
      } else {
        isValid.push(false);
      }
    });

    returnValue = isValid.some((v) => v === false);

    if (returnValue) {
      notify(1, `File size exceeds ${FS}MB. Please select a smaller file.`);
      return false;
    } else {
      return true;
    }
  };

  const handleSupportingDoc = async (e, AC) => {
    const [FS, FX, FC] = [
      "5",
      ".docx,.doc,.pdf,.png,.jpg,.jpeg,.txt,.ppt,.pptx,.xlsx",
      1,
    ];

    const files = Array.prototype.slice.call(e.target.files);

    const isValid = handleFileValidation(files);
    if (isValid) {
      if (files && files.length > 0) {
        const base64Array: { Name: string; Data: string }[] = [];
        for (let i = 0; i < files.length; i++) {
          const base64 = (await fileToBase64(files[i])) as string;
          const base64WithoutPrefix = base64.split(",")[1];
          base64Array.push({
            Name: files[i].name,
            Data: base64WithoutPrefix,
          });
        }

        //check if AC present and file count matched

        const fileCount = proSupportingDoc.reduce((total, item) => {
          if (item.AC === AC) {
            return total + 1;
          }
          return total;
        }, 0);

        if (fileCount == FC) {
          notify(1, `Only ${FC} files allowed!`);
        } else {
          base64Array.forEach((file) => {
            setProSupportingDoc((prevSelectedFiles) => [
              ...prevSelectedFiles,
              {
                AC: AC,
                Name: file.Name,
                Data: file.Data,
                IsValidFile: false,
                DocId: 0,
              },
            ]);
          });
        }
      }
    } else {
      e.target.value = null;
    }
  };

  ///////////////////////////////////////////////////////////////////////////
  const handleSubmitButton = async (event) => {
    event.preventDefault();
    // alert("SUBMIT");
    setBtnDisable(true);
    setDynamicformErrors({});
    if (
      attributesData["REQTYPOUPD"].code == "DataChange" &&
      attributesData["REQRSNOUP"].code == "DTACHG"
    ) {
      setSubmitFunc(true);
    } else {
      // do normal submit
      setFormErrors({});
      setDynamicformErrors({});
      await showLoader();
      let isvalid = await checkValidation();
      let isValidDynamic = true;
      if (dynamicAccordians.length > 0) {
        isValidDynamic = await checkDynamicAccValidation();
      }

      // manager map
      let repMgMap = [];
      if (
        directReportee != null &&
        (directReportee.code == "TATUP" || directReportee.code == "TATOM")
      ) {
        if (reporteeManagerMap != null && reporteeManagerMap.length > 0) {
          reporteeManagerMap.map((eachRep) => {
            const tempObj = {
              EmpId: eachRep.value,
              ManagerId: managerForReportee.value,
            };
            repMgMap.push(tempObj);
          });
        }
      }
      if (directReportee != null && directReportee.code == "ITTOM") {
        if (reporteeManagerMap != null && reporteeManagerMap.length > 0) {
          reporteeManagerMap.map((eachRep) => {
            const tempObj = {
              EmpId: eachRep.value,
              ManagerId: eachRep.mg.value,
            };
            repMgMap.push(tempObj);
          });
        }
      }
      const repMgMapJson = JSON.stringify(repMgMap);
      console.log("Reporting Manager new:", repMgMap);
      console.log("Reporting Manager new JSON:", repMgMapJson);

      // relationship map
      let userRlMap = [];
      if (
        directReporteeRel != null &&
        (directReporteeRel.code == "RLTATUP" ||
          directReporteeRel.code == "RLTATOM")
      ) {
        if (userRelationMap != null && userRelationMap.length > 0) {
          userRelationMap.map((eachRep) => {
            const tempObj = {
              Id: eachRep.id,
              RelationId: relationForReportee.value,
            };
            userRlMap.push(tempObj);
          });
        }
      }
      if (directReporteeRel != null && directReporteeRel.code == "RLITTOM") {
        if (userRelationMap != null && userRelationMap.length > 0) {
          userRelationMap.map((eachRep) => {
            const tempObj = {
              Id: eachRep.id,
              RelationId: eachRep.repRel.value,
            };
            userRlMap.push(tempObj);
          });
        }
      }
      if (directReporteeRel != null && directReporteeRel.code == "RLTAR") {
        if (userRelationMap != null && userRelationMap.length > 0) {
          userRelationMap.map((eachRep) => {
            const tempObj = {
              Id: eachRep.id,
              RelationId: 0,
            };
            userRlMap.push(tempObj);
          });
        }
      }
      const userRlMapJson = JSON.stringify(userRlMap);
      console.log("User Relation new:", userRlMap);
      console.log("User Relation new JSON:", userRlMapJson);

      await hideLoader();
      if (isvalid && isValidDynamic) {
        const isSave = false;
        let oldJsonObj = [];
        let newJsonObj = [];
        let oldForAuditLog = [];
        let newForAuditLog = [];

        Object.keys(dynamicAccordians).length > 0 &&
          dynamicAccordians.map(async (eachAttr) => {
            if (eachAttr.attributeDatatype == "DropdownSingle") {
              const tempOldObj = {
                [eachAttr.attributeCode]:
                  eachAttr.value == null || eachAttr.value == undefined
                    ? ""
                    : parseInt(eachAttr.value),
              };
              oldJsonObj.push(tempOldObj);
              const opObj = eachAttr.options?.find(
                (item) => item.value == eachAttr.value
              );
              const tempOldAuditObj = {
                sectionId: eachAttr.attributeSectionId,
                IsAttributeSet: false,
                Code: eachAttr.attributeCode,
                Attribute: [
                  {
                    [eachAttr.attributeCode]:
                      opObj == null || opObj == undefined ? "" : opObj.label,
                  },
                ],
              };
              oldForAuditLog.push(tempOldAuditObj);
              const tempNewObj = {
                [eachAttr.attributeCode]:
                  eachAttr.valueOnDd == undefined || eachAttr.valueOnDd == null
                    ? ""
                    : parseInt(eachAttr.valueOnDd.value),
              };
              newJsonObj.push(tempNewObj);
              const tempNewAuditObj = {
                sectionId: eachAttr.attributeSectionId,
                IsAttributeSet: false,
                Code: eachAttr.attributeCode,
                Attribute: [
                  {
                    [eachAttr.attributeCode]:
                      eachAttr.valueOnDd == undefined ||
                      eachAttr.valueOnDd == null
                        ? ""
                        : eachAttr.valueOnDd.label,
                  },
                ],
              };
              newForAuditLog.push(tempNewAuditObj);
            }
            if (eachAttr.attributeDatatype == "Text") {
              const tempOldObj = {
                [eachAttr.attributeCode]:
                  eachAttr.value == null || eachAttr.value == undefined
                    ? ""
                    : eachAttr.value,
              };
              oldJsonObj.push(tempOldObj);
              const tempOldAuditObj = {
                sectionId: eachAttr.attributeSectionId,
                IsAttributeSet: false,
                Code: eachAttr.attributeCode,
                Attribute: [
                  {
                    [eachAttr.attributeCode]:
                      eachAttr.value == null || eachAttr.value == undefined
                        ? ""
                        : eachAttr.value,
                  },
                ],
              };
              oldForAuditLog.push(tempOldAuditObj);
              const tempNewObj = {
                [eachAttr.attributeCode]:
                  eachAttr.valueOnDd == undefined || eachAttr.valueOnDd == null
                    ? ""
                    : eachAttr.valueOnDd,
              };
              newJsonObj.push(tempNewObj);
              const tempNewAuditObj = {
                sectionId: eachAttr.attributeSectionId,
                IsAttributeSet: false,
                Code: eachAttr.attributeCode,
                Attribute: [
                  {
                    [eachAttr.attributeCode]:
                      eachAttr.valueOnDd == undefined ||
                      eachAttr.valueOnDd == null
                        ? ""
                        : eachAttr.valueOnDd,
                  },
                ],
              };
              newForAuditLog.push(tempNewAuditObj);
            }
            if (eachAttr.attributeDatatype == "Number") {
              const tempOldObj = {
                [eachAttr.attributeCode]:
                  eachAttr.value == null || eachAttr.value == undefined
                    ? ""
                    : parseInt(eachAttr.value),
              };
              oldJsonObj.push(tempOldObj);
              const tempOldAuditObj = {
                sectionId: eachAttr.attributeSectionId,
                IsAttributeSet: false,
                Code: eachAttr.attributeCode,
                Attribute: [
                  {
                    [eachAttr.attributeCode]:
                      eachAttr.value == null || eachAttr.value == undefined
                        ? ""
                        : parseInt(eachAttr.value),
                  },
                ],
              };
              oldForAuditLog.push(tempOldAuditObj);
              const tempNewObj = {
                [eachAttr.attributeCode]:
                  eachAttr.valueOnDd == undefined || eachAttr.valueOnDd == null
                    ? ""
                    : parseInt(eachAttr.valueOnDd),
              };
              newJsonObj.push(tempNewObj);
              const tempNewAuditObj = {
                sectionId: eachAttr.attributeSectionId,
                IsAttributeSet: false,
                Code: eachAttr.attributeCode,
                Attribute: [
                  {
                    [eachAttr.attributeCode]:
                      eachAttr.valueOnDd == undefined ||
                      eachAttr.valueOnDd == null
                        ? ""
                        : parseInt(eachAttr.valueOnDd),
                  },
                ],
              };
              newForAuditLog.push(tempNewAuditObj);
            }
            if (eachAttr.attributeDatatype == "Date") {
              const parsedDateOld = moment(eachAttr.value, "DD-MMM-YYYY");
              const formattedDateOld = parsedDateOld.format("YYYY-MM-DD");
              const tempOldObj = {
                [eachAttr.attributeCode]:
                  eachAttr.value == null ||
                  eachAttr.value == undefined ||
                  formattedDateOld == "Invalid date"
                    ? ""
                    : formattedDateOld,
              };
              oldJsonObj.push(tempOldObj);
              const tempOldAuditObj = {
                sectionId: eachAttr.attributeSectionId,
                IsAttributeSet: false,
                Code: eachAttr.attributeCode,
                Attribute: [
                  {
                    [eachAttr.attributeCode]:
                      eachAttr.value == null ||
                      eachAttr.value == undefined ||
                      formattedDateOld == "Invalid date"
                        ? ""
                        : formattedDateOld,
                  },
                ],
              };
              oldForAuditLog.push(tempOldAuditObj);

              const parsedDateNew = moment(eachAttr.valueOnDd, "DD-MMM-YYYY");
              const formattedDateNew = parsedDateNew.format("YYYY-MM-DD");
              const tempNewObj = {
                [eachAttr.attributeCode]:
                  eachAttr.valueOnDd == undefined ||
                  eachAttr.valueOnDd == null ||
                  formattedDateNew == "Invalid date"
                    ? ""
                    : formattedDateNew,
              };
              newJsonObj.push(tempNewObj);
              const tempNewAuditObj = {
                sectionId: eachAttr.attributeSectionId,
                IsAttributeSet: false,
                Code: eachAttr.attributeCode,
                Attribute: [
                  {
                    [eachAttr.attributeCode]:
                      eachAttr.valueOnDd == undefined ||
                      eachAttr.valueOnDd == null ||
                      formattedDateNew == "Invalid date"
                        ? ""
                        : formattedDateNew,
                  },
                ],
              };
              newForAuditLog.push(tempNewAuditObj);
            }
            if (eachAttr.attributeDatatype == "SearchableDropdownSingle") {
              const tempOldObj = {
                [eachAttr.attributeCode]:
                  eachAttr.value == null || eachAttr.value == undefined
                    ? ""
                    : parseInt(eachAttr.value),
              };
              oldJsonObj.push(tempOldObj);
              const tempNewObj = {
                [eachAttr.attributeCode]:
                  eachAttr.valueOnDd == undefined || eachAttr.valueOnDd == null
                    ? ""
                    : parseInt(eachAttr.valueOnDd.value),
              };
              newJsonObj.push(tempNewObj);

              if (
                eachAttr.value != null &&
                eachAttr.value != undefined &&
                eachAttr.value != ""
              ) {
                const obj = await getSearchableDropdownAPI(
                  "",
                  eachAttr.attributeCode,
                  null,
                  eachAttr.value,
                  "attribute",
                  0
                );
                const tempOldAuditObj = {
                  sectionId: eachAttr.attributeSectionId,
                  IsAttributeSet: false,
                  Code: eachAttr.attributeCode,
                  Attribute: [
                    {
                      [eachAttr.attributeCode]:
                        obj.data[0] == null || obj.data[0] == undefined
                          ? ""
                          : obj.data[0].label,
                    },
                  ],
                };
                oldForAuditLog.push(tempOldAuditObj);
              }

              const tempNewAuditObj = {
                sectionId: eachAttr.attributeSectionId,
                IsAttributeSet: false,
                Code: eachAttr.attributeCode,
                Attribute: [
                  {
                    [eachAttr.attributeCode]:
                      eachAttr.valueOnDd == undefined ||
                      eachAttr.valueOnDd == null
                        ? ""
                        : parseInt(eachAttr.valueOnDd.label),
                  },
                ],
              };
              newForAuditLog.push(tempNewAuditObj);
            }
          });

        //debugger;
        const oldJson = JSON.stringify(oldJsonObj);
        const newJson = JSON.stringify(newJsonObj);

        const oldAuditJson = JSON.stringify(oldForAuditLog);
        const newAuditJson = JSON.stringify(newForAuditLog);

        let customName = attributesData["REQRSNOUP"].label;

        const finalObj = {
          WorkflowId: approvers[0].workFlowId,
          WorkflowTypeId: attributesData["REQTYPOUPD"].value,
          WorkflowSubTypeId: attributesData["REQRSNOUP"].value,
          EmployeeId: parseInt(attributesData["REQEMPLID"].value),
          EffectiveDate: attributesData["REQEFCTVDT"],
          Remarks: attributesData["REQDREMK"],
          Attachment: attributesData["REQSUPDOC"],
          BIS: attributesData["REQEMPLID"].bis,
          SBU: attributesData["REQEMPLID"].sbu,
          LEN: attributesData["REQEMPLID"].len,
          DV: attributesData["REQEMPLID"].dv,
          RequestOldJson: oldJson,
          RequestNewJson: newJson,
          AuditLogOldJson: oldAuditJson,
          AuditLogNewJson: newAuditJson,
          RpMgMapType: directReportee ? directReportee.value : null,
          RpMgMap: repMgMapJson,
          JobRlMapType: directReporteeRel ? directReporteeRel.value : null,
          JobRlMap: userRlMapJson,
          InitiatorId: parseInt(userDetails.Id),
          InitiatorRoleId: currentRoleId?.value,
          UserId: userDetails.Id,
          UserName: userDetails.FirstName + " " + userDetails.LastName,
          UserEmail: userDetails.EmailId,
          isSave: isSave,
          ReasonNewLabel: customName ? customName : null,
          ProxyCreatedBy: proxyUserDetails == null ? null : userDetails.UserId,
        };
        console.log(finalObj);

        // check if separeation is active for employee
        let checkSeparationIsActive = false;

        if (attributesData["REQTYPOUPD"].code == "Separation") {
          const sepRes = await APICall(checkIfSeparationIsActive, "POST", {
            EmployeeId: parseInt(attributesData["REQEMPLID"].value),
            roleid: currentRoleId?.value,
          });

          if (sepRes.status === 0 && sepRes.data === true) {
            checkSeparationIsActive = true;
          } else if (sepRes.status === 0 && sepRes.data === false) {
            checkSeparationIsActive = false;
          } else {
            hideLoader();
            checkSeparationIsActive = true;
            notify(1, "Check separation exists failed!");
            return null;
          }
        }

        if (checkSeparationIsActive) {
          hideLoader();
          notify(1, "Separation for selected Employee already exists!");
          return null;
        }

        const postRes = await APICall(postDataForProgression, "POST", finalObj);
        await hideLoader();

        if (postRes.data !== null) {
          //Entry in Action History
          const actHistObj = await APICall(entryUpdateInActionHistory, "POST", {
            Status: `${attributesData["REQTYPOUPD"]?.label} Initiated.`,
            Remarks: attributesData["REQDREMK"],
            ProgId: postRes.data.progressionId,
            CreatedBy: userDetails.Id,
          });
          //---
          if (proSupportingDoc.length > 0) {
            const obj = {
              TId: parseInt(attributesData["REQEMPLID"].value),
              files: proSupportingDoc.map((doc) => {
                return { ...doc, Id: postRes.data.progressionId };
              }),
            };

            const docRes = await APICall(
              uploadSupportingDocForProgression,
              "POST",
              obj
            );

            notify(postRes.status, postRes.message);
            navigate("/home");
          } else {
            notify(postRes.status, postRes.message);
            navigate("/home");
          }
        } else {
          setBtnDisable(false);
          notify(1, "Insertion Fail.");
        }
      } else {
        setBtnDisable(false);
        console.log("NOT VALID");
        await hideLoader();
        notify(1, "Please Fill required Data.");
      }
    }
  };
  console.log("UD", userDetails, proxyUserDetails);

  const checkValidation = async () => {
    let formCount = 0;
    if (
      attributesData["REQDREMK"] == null ||
      attributesData["REQDREMK"] == ""
    ) {
      formCount += 1;
      setFormErrors((prev) => ({
        ...prev,
        ["REQDREMK"]: attributeValidation["REQDREMK"],
      }));
    }
    if (attributesData["REQEFCTVDT"] == null) {
      formCount += 1;
      setFormErrors((prev) => ({
        ...prev,
        ["REQEFCTVDT"]: attributeValidation["REQEFCTVDT"],
      }));
    } else if (
      attributesData["REQRSNOUP"].code == "ORG_BU_REQ" &&
      attributesData["REQEFCTVDT"]
    ) {
      //validation
      //SET DATE AS MENTIONED
      const parsedDate = moment(attributesData["REQEFCTVDT"]);
      const mon = parsedDate.month() === 6;
      const day = parsedDate.date() === 1;

      if (!day || !mon) {
        formCount += 1;
        setFormErrors((prev) => ({
          ...prev,
          ["REQEFCTVDT"]: "Please select date of 01-July",
        }));
      }
    }
    return formCount != 0 ? false : true;
  };

  const checkDynamicAccValidation = async () => {
    let formCount = 0;
    dynamicAccordians.map((eachAttr) => {
      if (eachAttr.attributeDatatype == "DropdownSingle" 
        && eachAttr.attributeCode !== "OTR"
        && eachAttr.attributeCode !== "WOA"
      ) {
        if (eachAttr.valueOnDd == null || eachAttr.valueOnDd == undefined) {
          formCount += 1;
          setDynamicformErrors((prev) => ({
            ...prev,
            [eachAttr.attributeCode]: "Mandatory",
          }));
        }
      } else if (
        (eachAttr.attributeDatatype == "Text" ||
          eachAttr.attributeDatatype == "Number")
        && eachAttr.attributeCode !== "WOAR"
      ) {
        if (eachAttr.valueOnDd == null || eachAttr.valueOnDd == undefined) {
          formCount += 1;
          setDynamicformErrors((prev) => ({
            ...prev,
            [eachAttr.attributeCode]: "Mandatory",
          }));
        }
      } else if (eachAttr.attributeDatatype == "Date"
        && eachAttr.attributeCode !== "ETD"
      ) {
        if (
          eachAttr.valueOnDd == null ||
          eachAttr.valueOnDd == undefined ||
          eachAttr.valueOnDd == ""
        ) {
          formCount += 1;
          setDynamicformErrors((prev) => ({
            ...prev,
            [eachAttr.attributeCode]: "Mandatory",
          }));
        } 
        
        if (
          eachAttr.attributeCode == "DOR" || eachAttr.attributeCode == "LWD"
        ){
          const parsedDate = moment(eachAttr.valueOnDd);
          const parsedGroupJoiningDate = moment(grpJoiningDate);
  
          const dateYear= parsedDate.year();
          const dateMonth= parsedDate.month();
          const dateDay = parsedDate.date();

          const gjdYear= parsedGroupJoiningDate.year();
          const gjdMonth= parsedGroupJoiningDate.month();
          const gjdDay = parsedGroupJoiningDate.date();
  
          if( eachAttr.attributeCode === "DOR"
          && (
            (dateYear < gjdYear) 
            || (dateYear == gjdYear && dateMonth < gjdMonth) 
            || (dateYear == gjdYear && dateMonth == gjdMonth && dateDay < gjdDay)
          )
          ) {
            formCount += 1;
            setDynamicformErrors((prev) => ({
              ...prev,
              [eachAttr?.attributeCode]: eachAttr?.attributeName + " cannot be less than Group Joining Date which is: " + grpJoiningDate.toString(),
            }));
          } else if (eachAttr.attributeCode === "LWD"
            && (
              (dateYear < gjdYear) 
              || (dateYear == gjdYear && dateMonth < gjdMonth) 
              || (dateYear == gjdYear && dateMonth == gjdMonth && dateDay < gjdDay)
            )
          ) {
            formCount += 1;
            setDynamicformErrors((prev) => ({
              ...prev,
              [eachAttr?.attributeCode]: eachAttr?.attributeName + " cannot be less than Group Joining Date which is: " + grpJoiningDate.toString(),
            }));
          }
          if (eachAttr.attributeCode == "LWD") {
            const Dor = dynamicAccordians.find(
              (item) => item.attributeCode == "DOR"
            );
            const effectiveDt = moment(Dor.valueOnDd);
            const parsedDate = moment(eachAttr.valueOnDd);

            if (parsedDate.year() < effectiveDt.year()) {
              formCount += 1;
              setDynamicformErrors((prev) => ({
                ...prev,
                [eachAttr.attributeCode]:
                  "Please select date after Date of Resignation",
              }));
            } else if (parsedDate.year() == effectiveDt.year()) {
              if (
                parsedDate.month() < effectiveDt.month() ||
                (parsedDate.month() == effectiveDt.month() &&
                  parsedDate.date() < effectiveDt.date())
              ) {
                formCount += 1;
                setDynamicformErrors((prev) => ({
                  ...prev,
                  [eachAttr.attributeCode]:
                    "Please select date after Date of Resignation",
                }));
              }
            }
          }
        }
      } else if (eachAttr.attributeDatatype == "SearchableDropdownSingle") {
        if (
          eachAttr.valueOnDd == null ||
          eachAttr.valueOnDd == undefined ||
          eachAttr.valueOnDd == ""
        ) {
          formCount += 1;
          setDynamicformErrors((prev) => ({
            ...prev,
            [eachAttr.attributeCode]: "Mandatory",
          }));
        }
      }
    });
    return formCount != 0 ? false : true;
  };

  const GetNPEndDate = (date) => {
    if (dynamicAccordians.length > 0) {
      // let NPSD = dynamicAccordians.filter((dc)=>{
      //   return dc.attributeCode === "NPSD"
      // })[0].valueOnDd
      let NPSD = date;
      let NPIM = dynamicAccordians.filter((dc) => {
        return dc.attributeCode === "NPIM";
      })[0].valueOnDd;

      if (NPSD != undefined && NPSD != null && NPSD != "") {
        if (NPIM != undefined && NPIM != null && NPIM != "") {
          const startDateNP = moment(NPSD);
          let NPDays = Number(NPIM) * 30.44;
          let EndDateNP = startDateNP.add(NPDays, "d");
          GetShortDays("NPEDE", EndDateNP);
          return EndDateNP;
        } else {
          return "";
        }
      } else {
        return "";
      }
    }
  };
  const GetShortDays = (attribute, date) => {
    if (dynamicAccordians.length > 0) {
      let NPEDE = "";
      let LWD = "";
      // if(attribute === "NPEDE"){
      //    NPEDE = date
      // }
      // else{
      NPEDE = dynamicAccordians.filter((dc) => {
        return dc.attributeCode === "NPEDE";
      })[0].valueOnDd;
      //}
      //   if(attribute === "LWD"){
      //     LWD = date
      //  }
      //  else{
      LWD = dynamicAccordians.filter((dc) => {
        return dc.attributeCode === "LWD";
      })[0].valueOnDd;
      //}
      if (
        LWD != undefined &&
        LWD != null &&
        NPEDE != undefined &&
        NPEDE != null
      ) {
        let daysDiff;
        if (moment(LWD) <= moment(NPEDE)) {
          daysDiff = moment(NPEDE).diff(moment(LWD), "days");
        } else {
          daysDiff = 0;
        }
        // let tempAcc = [...dynamicAccordians];
        //  let tempAtt = tempAcc.find(
        //                         (item) =>
        //                           item.attributeCode ==
        //                           "NPSDE"
        //                       );
        //                       tempAtt.valueOnDd = daysDiff;
        //                       setDynamicAccordian(tempAcc);
        return daysDiff > 0 ? daysDiff - 1 : daysDiff;
      } else {
        return "";
      }
    }
  };

  const typeOptions = [
    { value: 1, label: "Transfer all to upper position.", code: "TATUP" },
    { value: 2, label: "Transfer all to other manager.", code: "TATOM" },
    { value: 3, label: "Individual transfer to other manager.", code: "ITTOM" },
  ];
  const typeOptionsRel = [
    { value: 1, label: "Transfer all to upper position.", code: "RLTATUP" },
    { value: 2, label: "Transfer all to other manager.", code: "RLTATOM" },
    {
      value: 3,
      label: "Individual transfer to other manager.",
      code: "RLITTOM",
    },
    { value: 4, label: "Terminate all Relations.", code: "RLTAR" },
  ];

  useEffect(() => {
    if (
      attributesData["REQEMPLID"] != null ||
      attributesData["REQEMPLID"] != undefined
    ) {
      (async () => {
        // For Reportee
        const valueForDropdown = attributesData["REQEMPLID"].value;
        const ReporteeObj = await APICall(getReporteesForEmployee, "POST", {
          valueForDropdown,
        });

        if (
          ReporteeObj.data !== null &&
          ReporteeObj.data !== undefined &&
          ReporteeObj.data.length > 0
        ) {
          await hideLoader();
          setReportees(ReporteeObj.data);
        } else {
          await hideLoader();
          setReportees([]);
        }

        //For Job Relationship
        const RelationObj = await APICall(getRelationsForEmployee, "POST", {
          valueForDropdown,
        });
        if (
          RelationObj.data !== null &&
          RelationObj.data !== undefined &&
          RelationObj.data.length > 0
        ) {
          await hideLoader();
          setRelReportees(RelationObj.data);
        } else {
          await hideLoader();
          setRelReportees([]);
        }
      })();
    }
  }, [attributesData["REQEMPLID"]]);

  useEffect(() => {
    let repMg = [];
    reportees.map((eachRep) => {
      const tempObj = {
        value: eachRep.value,
        label: eachRep.label,
        mg: null,
      };
      repMg.push(tempObj);
    });
    setReporteeManagerMap(repMg);
  }, [reportees]);

  useEffect(() => {
    let repRl = [];
    relReportees.map(async (eachRep) => {
      const user = await getSearchableDropdownAPI(
        "",
        "MG",
        null,
        eachRep.userid,
        "attribute",
        0
      );
      const role = optForRoles.find(
        (item) => item.value == eachRep.relationtype
      );
      //const relation = await getSearchableDropdownAPI("", "MG", null, eachRep.relationId, "attribute", 0);
      const tempObj = {
        id: eachRep.id,
        repName: user,
        repType: role,
        //repRel: relation,
        repRel: null,
      };
      repRl.push(tempObj);
    });
    setUserRelationMap(repRl);
  }, [relReportees]);

  //directReporteeRel.code == "RLITTOM" ? false : true
  const [mappingHide, setMappingHide] = useState(true);
  useEffect(() => {
    if (directReporteeRel != null && directReporteeRel != undefined) {
      directReporteeRel.code == "RLITTOM"
        ? setMappingHide(false)
        : setMappingHide(true);
    }
  }, [directReporteeRel]);

  return (
    <>
      <div className="my-3 clearfix d-flex justify-content-end">
        <button
          onClick={() => navigate("/home")}
          className="btn btn-filter-submit ml-2">
          <i className="fa-solid fa-arrow-left"></i> Back
        </button>
        <button
          onClick={(event) => handleSubmitButton(event)}
          disabled={
            attributesData["REQTYPOUPD"] == null ||
            attributesData["REQRSNOUP"] == null ||
            attributesData["REQEMPLID"] == null
              ? true
              : btnDisable
          }
          className="btn btn-filter-submit ml-2 mr-3">
          <i className="fa-solid fa-check"></i> Submit
        </button>
      </div>
      <div className="col-lg-12">
        <Accordion
          elevation={0}
          className="mb-3"
          expanded={accordionReqDet}
          onChange={() => setAccordionReqDet((prev) => !prev)}>
          <AccordionSummary
            id="profile-accordion"
            style={{ background: "#3C5464" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}>
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <p style={{ fontSize: "16px" }}>Request Details</p>
              </div>
              <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
            </div>
          </AccordionSummary>

          <AccordionDetails className="page_heading">
            <div className="row">
              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Effective From Date
                    <sup>*</sup>
                  </label>
                  <DateForm
                    isDisabled={effdtNpsdDisable}
                    value={
                      attributesData["REQEFCTVDT"]
                        ? attributesData["REQEFCTVDT"]
                        : null
                    }
                    onChange={(date) => {
                      setAttributesData((prev) => ({
                        ...prev,
                        ["REQEFCTVDT"]: moment(date).format("DD-MMM-YYYY"),
                      }));
                    }}
                  />
                </div>
                {formErrors["REQEFCTVDT"] && (
                  <p style={{ color: "red" }}>{formErrors["REQEFCTVDT"]}</p>
                )}
              </div>
              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Event
                    <sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={true}
                    value={
                      attributesData["REQTYPOUPD"]
                        ? attributesData["REQTYPOUPD"]
                        : null
                    }
                    placeholder={"Type Of Update"}
                    options={
                      optionsObj["REQTYPOUPD"] ? optionsObj["REQTYPOUPD"] : []
                    }
                    onChange={async (e) => {
                      if (attributesData["REQTYPOUPD"] != null) {
                        if (attributesData["REQTYPOUPD"].code == "Separation") {
                          setAttributesData((prev) => ({
                            ...prev,
                            ["REQTYPOUPD"]: e,
                            ["REQRSNOUP"]: null,
                            //["REQTABS"]: null,
                            ["REQSECT"]: null,
                            ["REQEMPLID"]: null,
                            ["REQEMPLNM"]: "",
                          }));
                        } else {
                          setAttributesData((prev) => ({
                            ...prev,
                            ["REQTYPOUPD"]: e,
                            ["REQRSNOUP"]: null,
                            //["REQTABS"]: null,
                            ["REQSECT"]: null,
                          }));
                        }
                      } else {
                        setAttributesData((prev) => ({
                          ...prev,
                          ["REQTYPOUPD"]: e,
                          ["REQRSNOUP"]: null,
                          //["REQTABS"]: null,
                          ["REQSECT"]: null,
                        }));
                      }
                      //setEmpIsDisable(false);
                      setFinalFormData({});
                      if (e) {
                        const optForReason = await getDdData(
                          "REQRSNOUP",
                          e.code
                        );
                        setOptionsObj((prev) => ({
                          ...prev,
                          ["REQRSNOUP"]: optForReason,
                        }));
                        setapproverOpt({});
                        setApprovers([]);
                        setBtnHide(true);
                        setFormErrors({});
                        setDynamicAccordian({});
                        setDynamicformErrors({});
                      } else {
                        setOptionsObj((prev) => ({
                          ...prev,
                          ["REQRSNOUP"]: [],
                        }));
                        setapproverOpt({});
                        setApprovers([]);
                        setBtnHide(true);
                        setFormErrors({});
                        setDynamicAccordian({});
                        setDynamicformErrors({});
                      }
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
                {formErrors["REQTYPOUPD"] && (
                  <p style={{ color: "red" }}>{formErrors["REQTYPOUPD"]}</p>
                )}
              </div>
              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Event Reason
                    <sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={false}
                    value={
                      attributesData["REQRSNOUP"]
                        ? attributesData["REQRSNOUP"]
                        : null
                    }
                    placeholder={"Reason Of Update"}
                    options={
                      optionsObj["REQRSNOUP"] ? optionsObj["REQRSNOUP"] : []
                    }
                    onChange={async (e) => {
                      if (e) {
                        if (
                          attributesData["REQTYPOUPD"].code == "Separation" &&
                          e.code == "Resignation"
                        ) {
                          //setEmpIsDisable(true);
                          const empid = proxyUserDetails
                            ? proxyUserDetails.EmployeeId
                            : userDetails.EmployeeId;
                          const obj = await getSearchableDropdownAPI(
                            "",
                            "IOPPOS",
                            null,
                            empid,
                            "attribute",
                            0
                          );

                          //Group Joining Date set------------------------------
                          const gjdatestring = obj?.gjd
                          ? obj?.gjd.toString()
                          : null;

                          setGrpJoiningDate(gjdatestring);
                          //////////////////////////////////////////////////////

                          if (obj !== undefined) {
                            setAttributesData((prev) => ({
                              ...prev,
                              ["REQRSNOUP"]: e,
                              ["REQSECT"]: null,
                              ["REQEMPLID"]: obj,
                              ["REQEMPLNM"]: obj.label,
                            }));
                          } else {
                            setAttributesData((prev) => ({
                              ...prev,
                              ["REQRSNOUP"]: e,
                              ["REQSECT"]: null,
                              ["REQEMPLID"]: null,
                              ["REQEMPLNM"]: "",
                            }));
                          }
                        } else {
                          //setEmpIsDisable(false);
                          setAttributesData((prev) => ({
                            ...prev,
                            ["REQRSNOUP"]: e,
                            //["REQTABS"]: null,
                            ["REQSECT"]: null,
                          }));
                        }
                      } else {
                        setAttributesData((prev) => ({
                          ...prev,
                          ["REQRSNOUP"]: e,
                          //["REQTABS"]: null,
                          ["REQSECT"]: null,
                          ["REQEMPLID"]: null,
                          ["REQEMPLNM"]: "",
                        }));
                        setIsonce(false);
                      }
                      setApprovers([]);
                      setapproverOpt({});
                      setBtnHide(true);
                      setDynamicAccordian({});
                      setDynamicformErrors({});
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
                {formErrors["REQRSNOUP"] && (
                  <p style={{ color: "red" }}>{formErrors["REQRSNOUP"]}</p>
                )}
              </div>

              <div
                className={"col-lg-3 col-sm-3 col-xs-4"}
                hidden={sectionTabHide}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Tab
                    <sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={true}
                    value={
                      attributesData["REQTABS"]
                        ? attributesData["REQTABS"]
                        : null
                    }
                    placeholder={"Tab"}
                    options={optionsObj["REQTABS"] ? optionsObj["REQTABS"] : []}
                    onChange={async (e) => {
                      setAttributesData((prev) => ({
                        ...prev,
                        ["REQTABS"]: e,
                        ["REQSECT"]: null,
                      }));
                      if (e) {
                        const optForSect = await getDdData("REQSECT", e.value);
                        setOptionsObj((prev) => ({
                          ...prev,
                          ["REQSECT"]: optForSect,
                        }));
                      } else {
                        setOptionsObj((prev) => ({
                          ...prev,
                          ["REQSECT"]: [],
                        }));
                      }
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
                {/* {formErrors["REQRSNOUP"] && (
                    <p style={{ color: "red" }}>{formErrors["REQRSNOUP"]}</p>
                  )} */}
              </div>
              <div
                className={"col-lg-3 col-sm-3 col-xs-4"}
                hidden={sectionTabHide}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Section
                    <sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={false}
                    value={
                      attributesData["REQSECT"]
                        ? attributesData["REQSECT"]
                        : null
                    }
                    placeholder={"Section"}
                    options={optionsObj["REQSECT"] ? optionsObj["REQSECT"] : []}
                    onChange={(e) => {
                      setAttributesData((prev) => ({
                        ...prev,
                        ["REQSECT"]: e,
                      }));
                    }}
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
                {/* {formErrors["REQRSNOUP"] && (
                    <p style={{ color: "red" }}>{formErrors["REQRSNOUP"]}</p>
                  )} */}
              </div>

              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Employee ID
                    <sup>*</sup>
                  </label>
                  {/* searchable item  */}
                  <SelectForm
                    isClearable
                    isSearchable
                    isDisabled={empIsDisable}
                    value={
                      attributesData["REQEMPLID"]
                        ? attributesData["REQEMPLID"]
                        : null
                    }
                    placeholder={"Employee ID"}
                    async
                    options={(searchString, cb) =>
                      getSearchableDropdownAPI(
                        searchString,
                        "IOPPOS",
                        cb,
                        "",
                        "attribute",
                        0
                      )
                    }
                    onChange={(e) => {
                      if (e) {
                        setAttributesData((prev) => ({
                          ...prev,
                          ["REQEMPLID"]: e,
                          ["REQEMPLNM"]: e.label,
                        }));
                        setTId(parseInt(e.value));
                        setApprovers([]);
                        setapproverOpt({});
                        setBtnHide(true);
                        setDynamicAccordian({});
                        setDynamicformErrors({});
                      } else {
                        setAttributesData((prev) => ({
                          ...prev,
                          ["REQEMPLID"]: e,
                          ["REQEMPLNM"]: "",
                        }));
                        setApprovers([]);
                        setapproverOpt({});
                        setBtnHide(true);
                        setDynamicAccordian({});
                        setDynamicformErrors({});
                      }
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
                {formErrors["REQEMPLID"] && (
                  <p style={{ color: "red" }}>{formErrors["REQEMPLID"]}</p>
                )}
              </div>
              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    Employee Name
                    <sup>*</sup>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Employee Name"}
                    isDisabled={true}
                    textArea={false}
                    value={
                      attributesData["REQEMPLNM"]
                        ? attributesData["REQEMPLNM"]
                        : ""
                    }
                    onChange={(e) => {
                      setAttributesData((prev) => ({
                        ...prev,
                        ["REQEMPLNM"]: e.target.value,
                      }));
                    }}
                  />
                </div>
                {formErrors["REQEMPLNM"] && (
                  <p style={{ color: "red" }}>{formErrors["REQEMPLNM"]}</p>
                )}
              </div>
              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">Supporting Documents</label>

                  <div className="box position-relative">
                    <input
                      id={"REQSUPDOC"}
                      className="form-control inputfile inputfile-6 multiple-inputfile"
                      multiple={false}
                      type="file"
                      ref={supportingDocRef}
                      onChange={(e) => {
                        handleSupportingDoc(e, "REQSUPDOC");
                      }}
                      accept={
                        ".docx,.doc,.pdf,.png,.jpg,.jpeg,.txt,.ppt,.pptx,.xlsx"
                      }
                      disabled={false}
                    />
                    <label
                      htmlFor={"REQSUPDOC"}
                      className="form-control"
                      style={{ width: 0, border: "none" }}>
                      <strong
                        style={{
                          padding: "6px 16px",
                          backgroundColor: "#3c5464",
                          borderRadius: "5px",
                        }}>
                        <i
                          className="fa fa-upload rotate90"
                          aria-hidden="true"></i>
                        {"  "}
                        Upload
                      </strong>{" "}
                    </label>
                  </div>
                  <div className="file-added-list">
                    <ul className="list-unstyle">
                      {proSupportingDoc &&
                        proSupportingDoc.length > 0 &&
                        proSupportingDoc.map(
                          (fileAttribute, attributeIndex) => (
                            <>
                              <li className="list mt-1" key={attributeIndex}>
                                <div className="media">
                                  <div className="media-body text-truncate">
                                    <span className="view-more">
                                      {fileAttribute.Name}
                                    </span>
                                  </div>

                                  <div className="media-right ml-2">
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                      onClick={() => {
                                        setProSupportingDoc([]);
                                        supportingDocRef.current.value = null;
                                      }}></i>
                                  </div>
                                </div>
                              </li>
                            </>
                          )
                        )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className={"col-lg-6 col-sm-12 mobile-view top-m"}>
                <div className="mb-1">
                  <label className="col-form-label">Remarks</label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control"
                    placeholder={"Remarks, if any."}
                    isDisabled={false}
                    textArea={true}
                    value={
                      attributesData["REQDREMK"]
                        ? attributesData["REQDREMK"]
                        : ""
                    }
                    onChange={(e) => {
                      e.preventDefault();
                      setAttributesData((prev) => ({
                        ...prev,
                        ["REQDREMK"]: e.target.value,
                      }));
                    }}
                  />
                </div>
                {formErrors["REQDREMK"] && (
                  <p style={{ color: "red" }}>{formErrors["REQDREMK"]}</p>
                )}
              </div>
            </div>
            {/* <div className="row">
                
                
              </div> */}
          </AccordionDetails>
        </Accordion>

        {dynamicAccordians !== null && dynamicAccordians.length > 0 && (
          <Accordion
            elevation={0}
            className="mb-3"
            expanded={accordianEvent}
            onChange={() => setAccordianEvent((prev) => !prev)}>
            <AccordionSummary
              id="profile-accordion"
              style={{ background: "#3C5464" }}
              className="text-white acc_close"
              expandIcon={<ExpandMore />}>
              <div className="row d-flex align-items-center acc-heading">
                <div className="col-lg-10 col-md-10 col-sm-12">
                  <p style={{ fontSize: "16px" }}>
                    {attributesData["REQTYPOUPD"]
                      ? attributesData["REQTYPOUPD"].label
                      : "Event"}
                  </p>
                </div>
                <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
              </div>
            </AccordionSummary>

            <AccordionDetails className="page_heading">
              <>
                {/* Row for Dynamic Attributes */}
                <div className="row">
                  {dynamicAccordians !== null &&
                    dynamicAccordians.length > 0 &&
                    dynamicAccordians.map((attribute) =>
                      attribute.attributeDatatype === "DropdownSingle" ? (
                        <div className="col-lg-3 col-sm-3 col-xs-4"
                          hidden = {
                            attribute.attributeCode === "OTR"
                              || attribute.attributeCode === "WOA"
                              || attribute.attributeCode === "WOAR"
                              || attribute.attributeCode === "ETD"
                            ? true : false
                          }
                        >
                          <div className="mb-1">
                            <label className="col-form-label">
                              {attribute.attributeName}
                            </label>
                            <sup>*</sup>
                            <SelectForm
                              isSearchable
                              isDisabled={
                                attribute.attributeCode == "ST" ? true : false
                              }
                              value={
                                attribute.valueOnDd ? attribute.valueOnDd : null
                              }
                              options={attribute.options}
                              placeholder={attribute.attributeName}
                              onChange={(e) => {
                                if (e) {
                                  let tempAcc = [...dynamicAccordians];

                                  let tempAtt = tempAcc.find(
                                    (item) =>
                                      item.attributeCode ==
                                      attribute.attributeCode
                                  );

                                  tempAtt.valueOnDd = e;
                                  setDynamicAccordian(tempAcc);
                                } else {
                                  notify(1, "Cannot Clear the Field");
                                }
                              }}
                              isMulti={false}
                              noIndicator={false}
                              noSeparator={false}
                            />
                            {dynamicformErrors[attribute.attributeCode] && (
                              <p style={{ color: "red" }}>
                                {dynamicformErrors[attribute.attributeCode]}
                              </p>
                            )}
                          </div>
                        </div>
                      ) : attribute.attributeDatatype === "Date" ? (
                        <div className="col-lg-3 col-sm-3 col-xs-4"
                          hidden = {
                            attribute.attributeCode === "OTR"
                              || attribute.attributeCode === "WOA"
                              || attribute.attributeCode === "WOAR"
                              || attribute.attributeCode === "ETD"
                            ? true : false
                          }
                        >
                          <div className="mb-1">
                            <label className="col-form-label">
                              {attribute.attributeName}
                            </label>
                            <sup>*</sup>
                            <DateForm
                              isDisabled={
                                attribute.attributeCode == "DOR" ||
                                attribute.attributeCode === "NPEDE" ||
                                attribute.attributeCode === "NPSD"
                                  ? true
                                  : false
                              }
                              value={
                                attribute.valueOnDd ? attribute.valueOnDd : null
                              }
                              onChange={(date) => {
                                let tempAcc = [...dynamicAccordians];

                                let tempAtt = tempAcc.find(
                                  (item) =>
                                    item.attributeCode ==
                                    attribute.attributeCode
                                );
                                tempAtt.valueOnDd =
                                  date != null
                                    ? moment(date).format("DD-MMM-YYYY")
                                    : null;
                                // if((attribute.attributeCode ===
                                //   "NPSD" && date != null)){
                                //     //GetNPEndDate(date);
                                //     let tempAtt = tempAcc.find(
                                //       (item) =>
                                //         item.attributeCode ==
                                //         "NPEDE"
                                //     );
                                //     tempAtt.valueOnDd =
                                //     date != null
                                //       ? moment(GetNPEndDate(date)).format(
                                //           "DD-MMM-YYYY"
                                //         )
                                //       : null;
                                //   }

                                if (
                                  attribute.attributeCode === "LWD" &&
                                  date != null
                                ) {
                                  let tempAtt = tempAcc.find(
                                    (item) => item.attributeCode == "NPSDE"
                                  );
                                  tempAtt.valueOnDd = GetShortDays(
                                    attribute.attributeCode,
                                    date
                                  );
                                
                                  const tempDate = moment(date);
                                  const newDate = tempDate.add(1, "days");
                                  let tempAtt5 = tempAcc.find(
                                    (item) => item.attributeCode == "ETD"
                                  );
                                  
                                  tempAtt5.value =
                                    moment(newDate).format("DD-MMM-YYYY");
                                  tempAtt5.valueOnDd =
                                    moment(newDate).format("DD-MMM-YYYY");
                                }
                                setDynamicAccordian(tempAcc);
                              }}
                            />
                            {dynamicformErrors[attribute.attributeCode] && (
                              <p style={{ color: "red" }}>
                                {dynamicformErrors[attribute.attributeCode]}
                              </p>
                            )}
                          </div>
                        </div>
                      ) : attribute.attributeDatatype === "Text" ||
                        attribute.attributeDatatype === "Number" ? (
                        <div className="col-lg-3 col-sm-3 col-xs-4"
                          hidden = {
                            attribute.attributeCode === "OTR"
                              || attribute.attributeCode === "WOA"
                              || attribute.attributeCode === "WOAR"
                              || attribute.attributeCode === "ETD"
                            ? true : false
                          }
                        >
                          <div className="mb-1">
                            <label className="col-form-label">
                              {attribute.attributeName}
                            </label>
                            <sup>*</sup>
                            <InputForm
                              className="form-control"
                              isDisabled={
                                attribute.attributeCode === "NPIM" ||
                                attribute.attributeCode === "NPSDE"
                                  ? true
                                  : false
                              }
                              value={
                                // attribute.attributeCode === "NPSDE"
                                // ? GetShortDays("","")
                                //    :
                                attribute.valueOnDd
                              }
                              placeholder={attribute.attributeName}
                              textArea={false}
                              onChange={(e) => {
                                let tempAcc = [...dynamicAccordians];
                                let tempAtt = tempAcc.find(
                                  (item) =>
                                    item.attributeCode ==
                                    attribute.attributeCode
                                );
                                tempAtt.valueOnDd = e.target.value;
                                setDynamicAccordian(tempAcc);
                              }}
                            />
                            {dynamicformErrors[attribute.attributeCode] && (
                              <p style={{ color: "red" }}>
                                {dynamicformErrors[attribute.attributeCode]}
                              </p>
                            )}
                          </div>
                        </div>
                      ) : attribute.attributeDatatype ===
                        "SearchableDropdownSingle" ? (
                        <div className="col-lg-3 col-sm-3 col-xs-4">
                          <div className="mb-1">
                            <label className="col-form-label">
                              {attribute.attributeName}
                            </label>
                            <sup>*</sup>
                            <SelectForm
                              isClearable
                              isSearchable
                              async
                              options={(searchString, cb) =>
                                getSearchableDropdownAPI(
                                  searchString,
                                  attribute.attributeCode,
                                  cb,
                                  "",
                                  "attribute",
                                  0
                                )
                              }
                              placeholder={attribute.attributeName}
                              value={
                                attribute.valueOnDd ? attribute.valueOnDd : null
                              }
                              onChange={(e) => {
                                if (e) {
                                  let tempAcc = [...dynamicAccordians];
                                  let tempAtt = tempAcc.find(
                                    (item) =>
                                      item.attributeCode ==
                                      attribute.attributeCode
                                  );
                                  tempAtt.valueOnDd = e;
                                  setDynamicAccordian(tempAcc);
                                } else {
                                  notify(1, "Cannot Clear the Field");
                                }
                              }}
                              isMulti={false}
                              noIndicator={false}
                              noSeparator={false}
                            />
                            {dynamicformErrors[attribute.attributeCode] && (
                              <p style={{ color: "red" }}>
                                {dynamicformErrors[attribute.attributeCode]}
                              </p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )
                    )}
                </div>

                {/* Row for Hardcoded Values of Manager Transfer */}
                <div className="row">
                  {dynamicAccordians !== null &&
                    dynamicAccordians.length > 0 && (
                      <>
                        <div
                          className="col-lg-3 col-sm-3 col-xs-4"
                          hidden={reportees.length > 0 ? false : true}>
                          <div className="mb-1">
                            <label className="col-form-label">
                              {"TRANSFER DIRECT REPORTEE"}
                            </label>
                            <sup>*</sup>
                            <SelectForm
                              isSearchable
                              isClearable
                              isDisabled={false}
                              value={directReportee ? directReportee : null}
                              options={typeOptions}
                              placeholder={"Select a Value"}
                              onChange={async (e) => {
                                setDirectReportee(e);
                                if (e) {
                                  if (e.code == "TATUP") {
                                    if (
                                      attributesData["REQEMPLID"].mg != null
                                    ) {
                                      const mgObj =
                                        await getSearchableDropdownAPI(
                                          "",
                                          "MG",
                                          null,
                                          attributesData["REQEMPLID"].mg,
                                          "attribute",
                                          0
                                        );
                                      setManagerForReportee(mgObj);
                                    }
                                    setManagerDisable(true); //Set Upper position manager
                                  } else if (e.code == "ITTOM") {
                                    //managerForReportee
                                    setManagerForReportee(null);
                                    setManagerDisable(true);
                                  } else {
                                    setManagerForReportee(null);
                                    setManagerDisable(false);
                                  }
                                } else {
                                  setManagerForReportee(null);
                                  setManagerDisable(false);
                                }
                              }}
                              isMulti={false}
                              noIndicator={false}
                              noSeparator={false}
                            />
                            {/* {dynamicformErrors[attribute.attributeCode] && (
                            <p style={{ color: "red" }}>
                              {dynamicformErrors[attribute.attributeCode]}
                            </p>
                          )} */}
                          </div>
                        </div>
                        <div
                          className="col-lg-3 col-sm-3 col-xs-4"
                          hidden={reportees.length > 0 ? false : true}>
                          <div className="mb-1">
                            <label className="col-form-label">
                              {"Manager Name"}
                            </label>
                            <sup>*</sup>
                            <SelectForm
                              isClearable
                              isSearchable
                              isDisabled={managerDisable}
                              async
                              options={(searchString, cb) =>
                                getSearchableDropdownAPI(
                                  searchString,
                                  "MG",
                                  cb,
                                  "",
                                  "attribute",
                                  0
                                )
                              }
                              placeholder={"Select a Manager"}
                              value={managerForReportee}
                              onChange={(e) => {
                                setManagerForReportee(e);
                              }}
                              isMulti={false}
                              noIndicator={false}
                              noSeparator={false}
                            />
                          </div>
                        </div>
                      </>
                    )}
                </div>

                {/* Attributes for Individual Setting of Managers */}
                {directReportee != null &&
                  reporteeManagerMap != null &&
                  reporteeManagerMap.length > 0 && (
                    <div
                      className="row"
                      hidden={directReportee.code == "ITTOM" ? false : true}>
                      {reporteeManagerMap.map((eachRep) => (
                        <>
                          <div className="col-lg-3 col-sm-3 col-xs-4">
                            <div className="mb-1">
                              <label className="col-form-label">
                                {"Reportee Name"}
                              </label>
                              <sup>*</sup>
                              <SelectForm
                                isClearable
                                isSearchable
                                isDisabled={true}
                                options={[]}
                                placeholder={"Select a Manager"}
                                value={eachRep}
                                onChange={(e) => {}}
                                isMulti={false}
                                noIndicator={false}
                                noSeparator={false}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3 col-sm-3 col-xs-4">
                            <div className="mb-1">
                              <label className="col-form-label">
                                {"Manager Name"}
                              </label>
                              <sup>*</sup>
                              <SelectForm
                                isClearable
                                isSearchable
                                isDisabled={false}
                                async
                                options={(searchString, cb) =>
                                  getSearchableDropdownAPI(
                                    searchString,
                                    "MG",
                                    cb,
                                    "",
                                    "attribute",
                                    0
                                  )
                                }
                                placeholder={"Select a Manager"}
                                value={eachRep.mg ? eachRep.mg : null}
                                onChange={(e) => {
                                  let tempAcc = [...reporteeManagerMap];
                                  let tempAtt = tempAcc.find(
                                    (item) => item.value == eachRep.value
                                  );
                                  tempAtt.mg = e;
                                  setReporteeManagerMap(tempAcc);
                                }}
                                isMulti={false}
                                noIndicator={false}
                                noSeparator={false}
                              />
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  )}

                {/* Row for Hardcoded Values of Job Relation Transfer */}
                <div className="row">
                  {dynamicAccordians !== null &&
                    dynamicAccordians.length > 0 && (
                      <>
                        <div
                          className="col-lg-3 col-sm-3 col-xs-4"
                          hidden={relReportees.length > 0 ? false : true}>
                          <div className="mb-1">
                            <label className="col-form-label">
                              {"Transfer Job Relationships"}
                            </label>
                            <sup>*</sup>
                            <SelectForm
                              isSearchable
                              isClearable
                              isDisabled={false}
                              value={
                                directReporteeRel ? directReporteeRel : null
                              }
                              options={typeOptionsRel}
                              placeholder={"Select a Value"}
                              onChange={async (e) => {
                                setDirectReporteeRel(e);
                                if (e) {
                                  if (e.code == "RLTATUP") {
                                    if (
                                      attributesData["REQEMPLID"].mg != null
                                    ) {
                                      // code for get usermaster id using employeeid
                                      const umid = await APICall(
                                        getUsermasterIdBasedOnEid,
                                        "POST",
                                        {
                                          userid:
                                            attributesData["REQEMPLID"].mg,
                                        }
                                      );
                                      console.log(umid);
                                      const mgObj =
                                        await getSearchableDropdownAPI(
                                          "",
                                          "RELNME",
                                          null,
                                          umid.data.relationId,
                                          "attribute",
                                          0
                                        );
                                      setRelationForReportee(mgObj);
                                    }
                                    setRelationDisable(true); //Set Upper position manager
                                  } else if (e.code == "RLITTOM") {
                                    //relationForReportee
                                    setRelationForReportee(null);
                                    setRelationDisable(true);
                                  } else if (e.code == "RLTAR") {
                                    //noRelationForReportee
                                    setRelationForReportee(null);
                                    setRelationDisable(true);
                                  } else {
                                    setRelationForReportee(null);
                                    setRelationDisable(false);
                                  }
                                } else {
                                  setRelationForReportee(null);
                                  setRelationDisable(false);
                                }
                              }}
                              isMulti={false}
                              noIndicator={false}
                              noSeparator={false}
                            />
                          </div>
                        </div>
                        <div
                          className="col-lg-3 col-sm-3 col-xs-4"
                          hidden={relReportees.length > 0 ? false : true}>
                          <div className="mb-1">
                            <label className="col-form-label">
                              {"Search Users"}
                            </label>
                            <sup>*</sup>
                            <SelectForm
                              isClearable
                              isSearchable
                              isDisabled={relationDisable}
                              async
                              options={(searchString, cb) =>
                                getSearchableDropdownAPI(
                                  searchString,
                                  "RELNME", //Change this with "RELNME" once kiran sir's update is done
                                  cb,
                                  "",
                                  "attribute",
                                  0
                                )
                              }
                              placeholder={"Select a Manager"}
                              value={relationForReportee}
                              onChange={(e) => {
                                setRelationForReportee(e);
                              }}
                              isMulti={false}
                              noIndicator={false}
                              noSeparator={false}
                            />
                          </div>
                        </div>
                      </>
                    )}
                </div>
                {/* Attributes for Individual Job Relationship */}
                {directReporteeRel != null &&
                  !mappingHide &&
                  userRelationMap != null &&
                  userRelationMap.length > 0 &&
                  userRelationMap.map((eachRep) => (
                    <div className="row">
                      {/* reportee name  */}
                      <div className="col-lg-3 col-sm-3 col-xs-4">
                        <div className="mb-1">
                          <label className="col-form-label">
                            {"Reportee Name"}
                          </label>
                          <sup>*</sup>
                          <SelectForm
                            isClearable
                            isSearchable
                            isDisabled={true}
                            options={[]}
                            placeholder={"Select a Reportee"}
                            value={eachRep.repName}
                            onChange={(e) => {}}
                            isMulti={false}
                            noIndicator={false}
                            noSeparator={false}
                          />
                        </div>
                      </div>
                      {/* relationship type */}
                      <div className="col-lg-3 col-sm-3 col-xs-4">
                        <div className="mb-1">
                          <label className="col-form-label">
                            {"Relationship Type"}
                          </label>
                          <sup>*</sup>
                          <SelectForm
                            isClearable
                            isSearchable
                            isDisabled={true}
                            options={optForRoles}
                            placeholder={"Select Relationship Type"}
                            value={eachRep.repType}
                            onChange={(e) => {}}
                            isMulti={false}
                            noIndicator={false}
                            noSeparator={false}
                          />
                        </div>
                      </div>
                      {/* user name */}
                      <div className="col-lg-3 col-sm-3 col-xs-4">
                        <div className="mb-1">
                          <label className="col-form-label">
                            {"Search User"}
                          </label>
                          <sup>*</sup>
                          <SelectForm
                            isClearable
                            isSearchable
                            isDisabled={false}
                            async
                            options={(searchString, cb) =>
                              getSearchableDropdownAPI(
                                searchString,
                                "RELNME", //Change this with "RELNME" once kiran sir's update is done
                                cb,
                                "",
                                "attribute",
                                0
                              )
                            }
                            placeholder={"Select a User"}
                            value={eachRep.repRel ? eachRep.repRel : null}
                            onChange={(e) => {
                              let tempAcc = [...userRelationMap];
                              let tempAtt = tempAcc.find(
                                (item) => item.id == eachRep.id
                              );
                              tempAtt.repRel = e;
                              setUserRelationMap(tempAcc);
                            }}
                            isMulti={false}
                            noIndicator={false}
                            noSeparator={false}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
              </>
            </AccordionDetails>
          </Accordion>
        )}

        <Accordion
          elevation={0}
          className="mb-3"
          expanded={accordionWrkfl}
          onChange={() => setAccordionWrkfl((prev) => !prev)}>
          <AccordionSummary
            id="profile-accordion"
            style={{ background: "#3C5464" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}>
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <p style={{ fontSize: "16px" }}>Workflow</p>
              </div>
              <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
            </div>
          </AccordionSummary>

          <AccordionDetails className="page_heading">
            <div className="row">
              {approvers && approvers.length >= 1 && (
                <div className="col-lg-3 col-sm-3 col-xs-4">
                  <div className="mb-1">
                    <label className="col-form-label">Initiator</label>
                    <sup>*</sup>
                    <InputForm
                      value={
                        proxyUserDetails == null
                          ? attributesData["WRKFLINNM"]
                          : `${proxyUserDetails.FirstName} ${proxyUserDetails.LastName}`
                      }
                      placeholder={"Initiator"}
                      isDisabled={true}
                      textArea={false}
                      onChange={""}
                    />
                  </div>
                </div>
              )}
              {/* For Approver noOfApprover */}
              {approvers &&
                approvers.length > 1 &&
                approvers.map(
                  (eachApprover, index) =>
                    index > 0 && (
                      <>
                        <div className="col-lg-3 col-sm-3 col-xs-4">
                          <div className="dropdown mb-1">
                            <label className="col-form-label">
                              {eachApprover?.roleName 
                              ? eachApprover?.roleName 
                              : `${"Approver"} ${index}`}
                            </label>
                            <sup>*</sup>
                            <SelectForm
                              isClearable
                              isSearchable
                              isDisabled={true}
                              value={allApprovers[`${"Approver"} ${index}`]}
                              options={approverOpt[`${"Approver"} ${index}`]}
                              placeholder={`No Employee Found`}
                              onChange={(e) => {
                                alert("Change");
                              }}
                              isMulti={true}
                              noIndicator={false}
                              noSeparator={false}
                            />
                            <div className="dropdown-content">
                              {approverOpt[`${"Approver"} ${index}`] != null &&
                                approverOpt[`${"Approver"} ${index}`].length >
                                  0 &&
                                approverOpt[`${"Approver"} ${index}`].map(
                                  (eachApp) => <p>{eachApp.label}</p>
                                )}
                            </div>
                          </div>
                        </div>
                      </>
                    )
                )}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};

export default ProgResignation;
