import React, { useContext, useEffect, useState } from "react";
import "../../Pages/Progression/ChangeLog.css";
import { getAuditLogsV2ForPosition, getApproverHistoryData } from "../../Helpers/APIEndPoints/EndPoints";
import { APICall } from "../../Helpers/APICalls";
import moment from "moment";
import { Table } from "react-bootstrap";
import { LoaderContext } from "../../Helpers/Context/Context";

// Get the local timezone offset in minutes
const localOffsetMinutes = moment().utcOffset();

function ViewHistoryPosition({ TId, sectionId }) {
  const [auditLogsObject, setAuditLogsObject] = useState<any>([]);
  const [rhsData, setRhsData] = useState<any>([]);
  const [tableData, setTableData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [approverHistoryObj, setapproverHistoryObj] = useState<any>([]);
  const [toggleApproverHist, setToggleApproverHist] = useState(false);
  const [transactionId, setTransactionId] = useState<any>(null);

  useEffect(() => {
    GetLogData();
  }, []);

  const GetLogData = async () => {
    showLoader();
    const auditLogsRes = await APICall(getAuditLogsV2ForPosition, "POST", {
      TransactionId: TId,
      sectionId,
    });
    if (auditLogsRes?.data?.length > 0) {
      let responseData = auditLogsRes?.data;

      // Group by transactionId
      const dataByTransaction = {};
      responseData.forEach((item) => {
        const transactionId = item.pId;
        if (!dataByTransaction[transactionId]) {
          dataByTransaction[transactionId] = [];
        }
        dataByTransaction[transactionId].push(item);
      });

      Object.keys(dataByTransaction).map((transactionid)=>{
        dataByTransaction[transactionid].sort((a, b) => Number(a.sequenceNoOfAtt) - Number(b.sequenceNoOfAtt));
      });
      
      // Group by recordId within each transaction group as an array of arrays
      const sortedTransactions = Object.keys(dataByTransaction)
        // .sort((a, b) => Number(b) - Number(a))
        .sort((a, b) =>
          a === null ? 1 : b === null ? -1 : Number(b) - Number(a)
        ) // Sort null transactionId records last
        .map((transactionId) => {
          const transactionGroup = dataByTransaction[transactionId];
          const recordsArray = [];

          const recordsMap = {};
          transactionGroup.forEach((item) => {
            const recordId = item.recordId;
            if (!recordsMap[recordId]) {
              recordsMap[recordId] = [];
            }
            recordsMap[recordId].push(item);
          });

          for (const recordId in recordsMap) {
            if (recordsMap.hasOwnProperty(recordId)) {
              recordsArray.push(recordsMap[recordId]);
            }
          }

          return {
            transactionId,
            records: recordsArray,
          };
        });

      setTransactionId(sortedTransactions[0].transactionId ? parseInt(sortedTransactions[0].transactionId.trim()) : null);
      setTableData(sortedTransactions[0].records);
      setRhsData(sortedTransactions[0].records[0]);
      setAuditLogsObject(sortedTransactions);
    } else {
      setAuditLogsObject([]);
    }
    hideLoader();
  };

  useEffect(() => {
    setapproverHistoryObj([]);
    setToggleApproverHist(false);
  }, [transactionId]);

  const GetApproverHistoryData = async () => {
    showLoader();
    if (!toggleApproverHist){
      const audiapproverHistRes = await APICall(getApproverHistoryData, "POST", {
        // ProgressionId: transactionId,
        RequestId: TId,
        Code: "PR00",
      });
      if (audiapproverHistRes?.data?.length > 0){
        setapproverHistoryObj(audiapproverHistRes?.data);
      } else {
        setapproverHistoryObj([]);
      }
      setToggleApproverHist(!toggleApproverHist);
    } else {
      setapproverHistoryObj([]);
      setToggleApproverHist(!toggleApproverHist);
    }
    hideLoader();
  }

  // useEffect(() => {
  //   console.log(tableData);
  //   console.log(rhsData);
  //   console.log(auditLogsObject);
  // }, [rhsData, auditLogsObject, tableData]);

  const selectLog = async (log, index) => {
    setActiveTab(index);
    setRhsData(log[0]);
    setTableData(log);
  };
  console.log('rhsData::::',rhsData);

  return (
    <div>
      {auditLogsObject.length > 0 ? (
        <div className="d-flex justify-content-between">
          {/* <div className="lhs-outer-box row"> */}
          <div
            style={{
              height: "350px",
              overflowY: "auto",
              overflowX: "hidden",
              width: "35%",
            }}
            className="d-flex flex-column">
            {auditLogsObject.map((type, index) => (
              <div
                key={index}
                className={index == activeTab ? "lhs-box bg-clr" : "lhs-box"}
                onClick={() => {
                  setTransactionId(type.transactionId ? parseInt(type.transactionId.trim()) : null);
                  selectLog(type.records, index);
                }}>
                <Log auditLogsType={type.records[0]} />
              </div>
            ))}
          </div>

          <div className="rhs-box" id="rhs-box">
            {rhsData?.map((eachLog, index) => {
              return (
                <React.Fragment key={index}>
                  {index == 0 && (
                    <>
                      {/* <div className="row">
                        <div className={"col-lg-6 col-sm-6"}>
                          <span className="text-bold">Event Name:</span>{" "}
                          {eachLog?.eventName}
                        </div>{" "}
                        <div className={"col-lg-6 col-sm-6"}>
                          <span className="text-bold">Event Reason:</span>{" "}
                          {eachLog?.eventReasonName}
                        </div>{" "}
                      </div> */}

                      <div className="row">
                        <div className={"col-lg-6 col-sm-6"}>
                          <span className="text-bold">Effective From Date:</span>{" "}
                          {moment(eachLog?.effectiveDate).format("DD-MMM-YYYY")}
                        </div>{" "}
                        <div className={"col-lg-6 col-sm-6"}>
                          <span className="text-bold">Changed On Date:</span>{" "}
                          {moment
                            .utc(eachLog?.createdDate)
                            .utcOffset(localOffsetMinutes)
                            .format("DD-MMM-YYYY HH:mm:ss")}
                        </div>
                        <div className={"col-lg-6 col-sm-6"}>
                          <span className="text-bold">Changed By:</span>{" "}
                          {eachLog?.userName}
                        </div>
                        {
                          eachLog?.remarks && 
                          <div className={"col-lg-6 col-sm-6"}>
                            <span className="text-bold">Remarks:</span>{" "}
                            {eachLog?.remarks}
                          </div>
                        }
                        
                      </div>

                      {/* Approver History Starts*/}
                      <>
                        <button 
                          className="btn btn-approver-hist float-right ml-2"
                          onClick={(e)=>{GetApproverHistoryData()}}>
                            See Approver History
                        </button>
                        <Table striped bordered hover>
                        {
                          approverHistoryObj &&
                          approverHistoryObj.length > 0 &&
                          approverHistoryObj.map((log, index) => {
                            return (
                              <>
                                {index == 0 && (
                                  <thead>
                                    <tr>
                                      <th className="text-dark">Approver Role</th>
                                      <th className="text-dark">Approver Name</th>
                                      <th className="text-dark">Approver Decision</th>
                                      <th className="text-dark">Approver Remarks</th>
                                      <th className="text-dark">Approving Date</th>
                                    </tr>
                                  </thead>
                                )}
                                <tbody>
                                  <tr>
                                    <td>{log?.approverrole}</td>
                                    <td>{log?.approvername}</td>
                                    <td>{log?.approverdecision}</td>
                                    <td>{log?.approverremarks}</td>
                                    <td>
                                      {log?.approvingtime 
                                        ? moment
                                            .utc(log?.approvingtime)
                                            .utcOffset(localOffsetMinutes)
                                            .format("DD-MMM-YYYY HH:mm:ss")
                                        : ""}
                                    </td>
                                  </tr>
                                </tbody>
                              </>
                            );
                          })
                        }
                        </Table>
                      </>
                      {/* Approver History Ends*/}

                      {/* <div className="row">
                        <div className={"col-lg-6 col-sm-6"}>
                          <span className="text-bold">Remarks:</span>{" "}
                          {eachLog?.remarks}
                        </div>{" "}
                      </div> */}
                      <br />
                      <br />
                    </>
                  )}
                </React.Fragment>
              );
            })}

            {tableData?.map((eachLog) => (
              <Table striped bordered hover>
                {eachLog &&
                  eachLog.length > 0 &&
                  eachLog.map((log, index) => {
                    return (
                      <>
                        {index == 0 && (
                          <thead>
                            <tr>
                              <th className="text-dark">Field</th>
                              <th className="text-dark">Old Value</th>
                              <th className="text-dark">New Value</th>
                            </tr>
                          </thead>
                        )}
                        <tbody>
                          <tr>
                            <td>{log?.attribute}</td>
                            <td>{log?.oldAttributeValue}</td>
                            <td>{log?.newAttributeValue}</td>
                          </tr>
                        </tbody>
                      </>
                    );
                  })}
              </Table>
            ))}
          </div>
        </div>
      ) : (
        <h5>No History Found!</h5>
      )}
    </div>
  );
}

const Log = ({ auditLogsType }) => {
  return (
    <>
      {/* <span>
        <span className="text-bold">Event:</span> {auditLogsType[0].eventName}
      </span>
      <br />
      <span>
        <span className="text-bold">Status:</span> {auditLogsType[0].progStatus}
      </span>
      <br /> */}
      <span>
        <span className="text-bold">Changed Date:</span>{" "}
        {moment
          .utc(auditLogsType[0].createdDate)
          .utcOffset(localOffsetMinutes)
          .format("DD-MMM-YYYY HH:mm:ss")}
      </span>
      <br />
      <span>
        <span className="text-bold">Changed By:</span>{" "}
        {auditLogsType[0].userName}
      </span>
      <br />
    </>
  );
};
export default ViewHistoryPosition;
