import React, { useContext, useEffect, useState } from "react";
import DynamicGrid from "../../../Components/DynamicGrid/DynamicGrid";
import { LoaderContext } from "../../../Helpers/Context/Context";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import useUserContext from "../../../Helpers/ZustandStore/UserContextStore";
import { APICall } from "../../../Helpers/APICalls";
import {
  getDropdowns,
  getInterviewQuestionDashboardData,
  getManpowerRequisitionDetailsById,
} from "../../../Helpers/APIEndPoints/EndPoints";

const InterviewQuestion = () => {
  const { state } = useLocation();
  let { userDetails, currentRoleId }: any = useUserContext();
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();

  const [TId, setTId] = useState(state?.TId || 0);

  const [count, setCount] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [pageSize, setPageSize] = useState(10);

  const [start, setStart] = useState(0);
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  const [jobQuestions, setJobQuestions] = useState("");

  useEffect(() => {
    (async () => {
      showLoader();
      const response = await APICall(
        getManpowerRequisitionDetailsById,
        "POST",
        {
          MRFId: state?.TId,
        }
      );

      if (response?.status === 0) {
        const responseData = response?.data;

        if (responseData.jobQuestions != "") {
          setJobQuestions(responseData.jobQuestions);
        }
      }

      hideLoader();

      // await getDashboardData();
    })();
  }, [start, sortColumn, sortDirection, searchText]);

  const getDashboardData = async () => {
    showLoader();
    let requestParams = {
      PageNumber: start,
      PageSize: pageSize,
      SearchText: searchText,
      SortColumn: sortColumn,
      SortOrder: sortDirection,
      MrfId: TId,
    };

    const res = await APICall(
      getInterviewQuestionDashboardData,
      "POST",
      requestParams
    );

    if (res?.data && res?.data?.length > 0) {
      setRowData(res.data);
      setCount(res.count);
    } else {
      setRowData([]);
      setCount(0);
    }
    hideLoader();
  };

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: pageSize,
    page: start,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",
    onSearchChange: (searchText) => {
      if (searchText !== null) {
        setSearchText(searchText);
      } else {
        setSearchText("");
      }
    },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      setStart(page * pageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const gridColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        display: false,
      },
    },
    {
      name: "id",
      label: "Sr#",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },
    {
      name: "areaofEvaluation",
      label: "Area of Evaluation",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },
    {
      name: "question",
      label: "Question",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },
    {
      name: "desiredAnswer",
      label: "Desired Answer",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },
    {
      name: "mustAnswerCorrectly",
      label: "Must Answer Correctly",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: true,
      },
    },
  ];

  return (
    <>
      <div className="mb-3 pt-3">
        {/* <DynamicGrid data={rowData} columns={gridColumns} options={options} /> */}
        {jobQuestions != "" ? (
          <>
            <div className={"col-lg-12 col-sm-12 col-xs-12"}>
              <div className="mb-1">
                <label className="col-form-label">Job Questions</label>
                <div
                  dangerouslySetInnerHTML={{
                    __html: jobQuestions,
                  }}
                />
              </div>
            </div>
          </>
        ) : (
          <h4>No questions found</h4>
        )}
      </div>
    </>
  );
};

export default InterviewQuestion;
