import React, { useContext, useEffect, useRef, useState } from "react";
import { apiResp, cityData, stateData } from "./formData";
import { Document, Page } from "react-pdf";
import ButtonForm from "../ButtonForm/ButtonForm";
import { useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tabs,
  Tooltip,
} from "@mui/material";
import { Button, Modal } from "react-bootstrap";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { ExpandMore } from "@mui/icons-material";
import SelectForm from "../SelectForm/SelectForm";
import InputForm from "../InputForm/InputForm";
import DynamicGrid from "../DynamicGrid/DynamicGrid";
import uuid from "react-uuid";
import DateForm from "../DateForm/DateForm";
import moment from "moment";
import "./FormStructure.css";
import defaultProfileImgPic from "../../Assets/Images/profile.png";
import { APICall, Encrypt } from "../../Helpers/APICalls";
import { LoaderContext } from "../../Helpers/Context/Context";
import OneTimePayment from "./OneTimePayment";
import {
  GetNationalIDBlacklistValidation,
  checkMandatorySections,
  updateCandidateTasks,
  checkForRequestExists,
  getAttributeValueDetails,
  getAttributeValueDetailsV2,
  getDropdowns,
  getJobRelationDropDown,
  getSearchableDropdowns,
  getSectionDetails,
  getSectionDetailsV2,
  getTabsData,
  postAttributeValueDetails,
  postAttributeValueDetailsV2,
  getAccountValidation,
  getGlobalValues,
  GetCompanyCode,
  UploadProfile,
  getProfilePicImg,
  getParenPBasedonIncm,
  getDocuments,
  viewDocument,
  downloadDocument,
  deleteDocument,
  documentUpload,
  getDocumentGridData,
  getEmployeeDetailsById,
  updateEmployeeDetailsData,
  getWorkflowTypeData,
  getWorkflowSubTypeDataByWorkflowTypeId,
  getEventAndEventResonDataByTId,
  newHireSendBackApprovalFlow,
  getWorkflowTasksDataById,
  GetModuleDropdown,
  GetNationalIDDuplicateValidation,
  GetLOPHourlyRate,
  InsertAuditLog,
  GetConfigValueByKey,
  GetDocUploadTasks,
  UpdateGeneratedDocId,
  UpdateEventIntoNewHire,
  GetEmployeeDetailsForDocGeneration,
  GetLegalEntityDetailsCandidate,
} from "../../Helpers/APIEndPoints/EndPoints";
import notify from "../../Helpers/ToastNotification";
import { useLocation } from "react-router-dom";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { NationalIdCardType } from "./NationalIdCardTypeRegex";
import useECStore from "../../Helpers/ZustandStore/ECStore";
import BaseModal from "../BaseModel/BaseModel";
import { documentsAC } from "./DocumentAC";
import { EventArray, EventReasonArray } from "../../Pages/NewHire/Event";
import async from "react-select/dist/declarations/src/async/index";
import EmployeeProgression from "../../Pages/Progression/EmployeeProgression";
import EmployeeSendback from "../../Pages/Progression/EmployeeSendback";
import ViewHistory from "./ViewHistory";
import DocumentModal from "../DocumentModal/DocumentModal";
// import GetStartedPdf from "../../Assets/Document/Get_Started_With_Smallpdf";

const CandidateFormSectionComponent = ({
  TId,
  setTId,
  formData,
  setFormData,
  activetab,
  setActivetab,
  section: ogSection,
  sectionIndex,
  sectionValuesArray,
  SetSectionValuesArray,
  Globalv,
  COU,
  isNewHire,
  Country,
  setGridSortDirection,
  setShowProgressionModal,
  triggerEventError,
  newHireEvent,
  newHireEventReason,
  newHireEffectiveDate,
  setShowEventError,
  ishrmanagernotify,
  //   setCurrentSectionIdForProgression,
  //   setTaskIdForSendback,
  //   setShowSendbackModal,
  //   sendbackForEss,
  //   sectionForEss,
  //   viewSectionHistory,
  candidateEdit,
  changeWflTaskSts,
  setChangeWflTaskSts,
  candidateWorkflowTaskId,
  //flagForNoticePeriods
  //setFlagForNoticePeriods,
}) => {
  const [hasValue, setHasValue] = useState(
    sectionValuesArray.filter((es) => es.sectionSystemName == ogSection.SN) ||
      null
  );
  const [globalEC, setGlobalEC] = useState(Globalv);
  const [section, setSection] = useState(ogSection);
  const [accordion, setAccordion] = useState(sectionIndex === 0 ? true : false);
  const [formErrors, setFormErrors] = useState<any>({});
  const [optionsObj, setOptionsObj] = useState<any>({});
  const [gridData, setGridData] = useState([]);
  const [displayGrid, setDisplayGrid] = useState([]);
  const [gridColumns, setGridColumns] = useState([]);
  const [ogAttributeSet, setOgAttributeSet] = useState({});
  const [ogFilledAttributeSet, setOgFilledAttributeSet] = useState([]);
  const [currentGridId, setCurrentGridId] = useState(null);
  const [deleteGridId, setDeleteGridId] = useState(null);
  const [showAttr, setshowAttr] = useState(
    !candidateEdit ? true : section?.ISEDIT ? false : true
  );
  const [viewOnly, setViewOnly] = useState(false);

  // audit log state
  const [oldAuditLog, setOldAuditLog] = useState([]);
  const [newAuditLog, setNewAuditLog] = useState([]);
  const [postAuditLog, setPostAuditLog] = useState(false);

  // reloadEmployment on organisation unit save
  const [reloadEmployment, setReloadEmployment] = useState(false);

  //new hire event state
  const [postEventData, setPostEventData] = useState(false);

  // value Object for section
  const [attributesData, setAttributesData] = useState<any>({});

  const [attributeSetData, setAttributeSetData] = useState<any>({});

  const [attributeSetCode, setAttributeSetCode] = useState(null);
  const [isOnce, setIsOnce] = useState(true);
  const [isValidateOnce, setIsValidateOnce] = useState(true);

  const [attributeValidation, setAttributeValidation] = useState<any>({});
  const [attributeSetValidation, setAttributeSetValidation] = useState<any>({});

  const [dataFilled, setDataFilled] = useState(false);

  const [sectionHasDocuments, setSectionHasDocuments] = useState(false);

  const { showLoader, hideLoader } = useContext(LoaderContext);

  const { userDetails, currentRoleId, proxyUserDetails }: any =
    useUserContext();

  const [PageSize, setPageSize] = useState(3);
  const [page, setPage] = useState(0);

  const [fillDDValuesForGrid, setFillDDValuesForGrid] = useState([]);
  const [dAutomapAttribute, setdAutomapAttribute] = useState([]);
  const [modalHeader, setModalHeader] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  let validAccount = useRef(false);
  let FlatDocRef = useRef(null);
  let SetDocRef = useRef(null);
  const [confirmationPromise, setConfirmationPromise] = useState(null);
  const [ShowAttrSet, setShowAttrSet] = useState(false);
  const [isSameAsEmergency, setIsSameAsEmergency] = useState(false);
  const [isSameAsPermanent, setIsSameAsPermanent] = useState(false);
  const [isSameAsPresent, setIsSameAsPresent] = useState(false);
  const [multiDropdown, setMultiDropdown] = useState(true);
  const getDropdownsData = async (AC, PID) => {
    await showLoader();
    const dropdownData = await APICall(getDropdowns, "POST", {
      AC,
      PID,
      UserId: proxyUserDetails?.EmployeeId
        ? proxyUserDetails?.EmployeeId
        : userDetails?.Id,
      RoleId: currentRoleId?.value,
      RoleTypeId: 3,
      IsNewHire: isNewHire,
    });

    if (
      dropdownData.data !== null &&
      dropdownData.data !== undefined &&
      dropdownData.data.length > 0
    ) {
      await hideLoader();
      return dropdownData.data;
    } else {
      await hideLoader();
      return [];
    }
  };

  // const getJobRelationDD = async (TId, event) => {
  //   await showLoader();
  //   const dropdownData = await APICall(getJobRelationDropDown, "POST", {
  //     RoleId: event.value,
  //     TID: TId,
  //   });
  //   if (
  //     dropdownData.data !== null &&
  //     dropdownData.data !== undefined &&
  //     dropdownData.data.length > 0
  //   ) {
  //     await hideLoader();
  //     return dropdownData.data;
  //   } else {
  //     await hideLoader();
  //     return [];
  //   }
  // };
  //Document code start
  //Base64-false
  //URL =true
  const [selectedFilesForFlat, setSelectedFilesForFlat] = useState<
    {
      AC: string;
      Name: string;
      Data: string;
      IsValidFile: boolean;
      DocId: Number;
      Type: string;
      Context: string;
      DCVALUE: string;
      DVTILL: string;
      DSTATUS: string;
      DPSINCE: string;
      DPWITH: string;
    }[]
  >([]);

  const [selectedFilesForSet, setSelectedFilesForSet] = useState<
    {
      AC: string;
      Id: string | number;
      Name: string;
      Data: string;
      IsValidFile: boolean;
      DocId: Number;
      Type: string;
      Context: string;
      DCVALUE: string;
      DVTILL: string;
      DSTATUS: string;
      DPSINCE: string;
      DPWITH: string;
    }[]
  >([]);

  const fileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileValidation = (files, attribute) => {
    const { FS, FX, FC } = JSON.parse(attribute.V);
    // Split the validExtensionsString into an array
    const validExtensions = FX.split(",");

    let isValid = [];

    // validate file count
    if (files.length > FC) {
      notify(1, `Only ${FC} files allowed!`);
      return false;
    }
    // check extension of each file
    files.forEach((file) => {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();

      // Check if the file extension is allowed
      if (!validExtensions.includes("." + fileExtension)) {
        isValid.push(false);
      } else {
        isValid.push(true); //valid
      }
    });

    let returnValue = isValid.some((v) => v === false);

    if (returnValue) {
      notify(1, `Please upload file within this ${FX} extentsion`);
      return false;
    }

    files.forEach((file) => {
      const fileSize = file.size;
      const maxFileSizeBytes = FS * 1024 * 1024;
      if (fileSize <= maxFileSizeBytes) {
        isValid.push(true); //valid
      } else {
        isValid.push(false);
      }
    });

    returnValue = isValid.some((v) => v === false);

    if (returnValue) {
      notify(1, `File size exceeds ${FS}MB. Please select a smaller file.`);
      return false;
    } else {
      return true;
    }
  };

  const handleFileChangeForFlat = async (e, attribute) => {
    const { FS, FX, FC } = JSON.parse(attribute.V);

    const files = Array.prototype.slice.call(e.target.files);

    const isValid = handleFileValidation(files, attribute);
    if (isValid) {
      if (files && files.length > 0) {
        const base64Array: { Name: string; Data: string }[] = [];
        for (let i = 0; i < files.length; i++) {
          const base64 = (await fileToBase64(files[i])) as string;
          const base64WithoutPrefix = base64.split(",")[1];
          base64Array.push({ Name: files[i].name, Data: base64WithoutPrefix });
        }

        //check if AC present and file count matched

        const fileCount = selectedFilesForFlat.reduce((total, item) => {
          if (item.AC === attribute.AC) {
            return total + 1;
          }
          return total;
        }, 0);

        if (fileCount == FC) {
          notify(1, `Only ${FC} files allowed!`);
        } else {
          // let type =
          //   JSON.parse(attribute.AD).CON === "National ID Information"
          //     ? attributeSetData["IDCATY"]?.label
          //     : JSON.parse(attribute.AD).TYP
          //     ? JSON.parse(attribute.AD).TYP
          //     : "";
          base64Array.forEach((file) => {
            setSelectedFilesForFlat((prevSelectedFiles) => [
              ...prevSelectedFiles,
              {
                AC: attribute.AC, //==="CONFDOC"?attributesData["DOCTYPE"]?.code :attribute.AC,
                Name: file.Name,
                Data: file.Data,
                IsValidFile: false,
                DocId: 0,
                Type:
                  attribute.AC === "CONFDOC"
                    ? attributesData["DOCTYPE"]?.label
                    : JSON.parse(attribute.AD).TYP
                    ? JSON.parse(attribute.AD).TYP
                    : "",
                Context:
                  attribute.AC === "CONFDOC"
                    ? attributesData["CNTXT"]
                    : JSON.parse(attribute.AD).CON
                    ? JSON.parse(attribute.AD).CON
                    : "",
                DCVALUE: "",
                DVTILL: "",
                DSTATUS: "Verified",
                DPSINCE: "",
                DPWITH: "",
              },
            ]);
          });
        }
      }
    } else {
      e.target.value = null;
    }
  };

  const handleFileChangeForSet = async (e, subAttribute) => {
    const { FS, FX, FC } = JSON.parse(subAttribute.V);

    const files = Array.prototype.slice.call(e.target.files);

    const isValid = handleFileValidation(files, subAttribute);
    if (isValid) {
      if (files && files.length > 0) {
        const base64Array: { Name: string; Data: string }[] = [];
        for (let i = 0; i < files.length; i++) {
          const base64 = (await fileToBase64(files[i])) as string;
          const base64WithoutPrefix = base64.split(",")[1];
          base64Array.push({ Name: files[i].name, Data: base64WithoutPrefix });
        }

        //check if AC present and file count matched

        const fileCount = selectedFilesForSet.reduce((total, item) => {
          if (
            item.AC === subAttribute.AC &&
            ((currentGridId && currentGridId == item.Id) || item.Id == 0)
          ) {
            return total + 1;
          }
          return total;
        }, 0);

        if (fileCount == FC) {
          notify(1, `Only ${FC} files allowed!`);
        } else {
          // let type =
          //   JSON.parse(subAttribute.AD).CON === "National ID Information"
          //     ? attributeSetData["IDCATY"]?.label
          //     : JSON.parse(subAttribute.AD).TYP
          //     ? JSON.parse(subAttribute.AD).TYP
          //     : "";
          base64Array.forEach((file) => {
            setSelectedFilesForSet((prevSelectedFiles) => [
              ...prevSelectedFiles,
              {
                AC: subAttribute.AC,
                Name: file.Name,
                Data: file.Data,
                IsValidFile: false,
                Id: currentGridId ?? 0,
                DocId: 0,
                Type: JSON.parse(subAttribute.AD).TYP
                  ? JSON.parse(subAttribute.AD).TYP
                  : "",
                Context: JSON.parse(subAttribute.AD).CON
                  ? JSON.parse(subAttribute.AD).CON
                  : "",
                DCVALUE:
                  JSON.parse(subAttribute.AD).CON === "KYC"
                    ? attributeSetData["IDCATY"]
                      ? attributeSetData["IDCATY"].label
                      : ""
                    : JSON.parse(subAttribute.AD).CON === "Bank"
                    ? attributeSetData["BNKACCNM"]
                      ? attributeSetData["BNKACCNM"]
                      : ""
                    : JSON.parse(subAttribute.AD).CON === "Work Permit"
                    ? attributeSetData["WPIDOCTYP"]
                      ? attributeSetData["WPIDOCTYP"].label
                      : ""
                    : JSON.parse(subAttribute.AD).CON ===
                      "Performance Assessment"
                    ? attributeSetData["COSTNM"]
                      ? attributeSetData["COSTNM"]
                      : ""
                    : JSON.parse(subAttribute.AD).CON === "Training"
                    ? attributeSetData["CONA"]
                      ? attributeSetData["CONA"]
                      : ""
                    : JSON.parse(subAttribute.AD).CON === "Rewards"
                    ? attributeSetData["AWRDTYP"]
                      ? attributeSetData["AWRDTYP"].label
                      : ""
                    : JSON.parse(subAttribute.AD).CON === "Service Bond"
                    ? attributeSetData["SBOSTDT"] && attributeSetData["SBOENDT"]
                      ? `${attributeSetData["SBOSTDT"]} - ${attributeSetData["SBOENDT"]}`
                      : ""
                    : JSON.parse(subAttribute.AD).CON === "Address"
                    ? attributeSetData["PA"]
                      ? currentGridId === null &&
                        typeof attributeSetData["PA"] == "object" &&
                        Array.isArray(attributeSetData["PA"]) == true
                        ? attributeSetData["PA"].map((a) => a.label).toString()
                        : attributeSetData["PA"].label
                      : ""
                    : JSON.parse(subAttribute.AD).CON === "Education"
                    ? JSON.parse(subAttribute.AD).TYP === "Degree"
                      ? attributeSetData["QULIFCN"]
                        ? attributeSetData["QULIFCN"].label
                        : ""
                      : JSON.parse(subAttribute.AD).TYP === "Certification"
                      ? attributeSetData["CL"]
                        ? attributeSetData["CL"]
                        : ""
                      : ""
                    : JSON.parse(subAttribute.AD).CON === "Family"
                    ? JSON.parse(subAttribute.AD).TYP === "Family Member"
                      ? attributeSetData["FNE"] && attributeSetData["LNE"]
                        ? `${attributeSetData["FNE"]} ${attributeSetData["LNE"]}`
                        : ""
                      : JSON.parse(subAttribute.AD).TYP === "Nominee"
                      ? attributeSetData["TYOFNOM"]
                        ? attributeSetData["TYOFNOM"].label
                        : ""
                      : ""
                    : "",
                DVTILL: "",
                DSTATUS: "Verified",
                DPSINCE: "",
                DPWITH: "",
              },
            ]);
          });
        }
      }
    } else {
      e.target.value = null;
    }
  };

  const handleViewForFlat = async (fileAttribute, fileIndex) => {
    showLoader();
    if (
      fileAttribute.DocId != undefined &&
      fileAttribute.DocId != null &&
      fileAttribute.DocId != 0
    ) {
      const doc = await APICall(viewDocument, "POST", {
        TId,
        AC: fileAttribute.AC,
        DocId: fileAttribute.DocId,
      });

      if (doc && doc.data !== null) {
        //window.open(doc.data,'_blank');
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = doc.data;
        link.click();
      }
      hideLoader();
    } else {
      notify(1, "File Not Available");
    }
  };

  const handleDownloadFileFlat = async (fileAttribute, fileIndex) => {
    showLoader();
    if (
      fileAttribute.DocId != undefined &&
      fileAttribute.DocId != null &&
      fileAttribute.DocId != 0
    ) {
      const doc = await APICall(downloadDocument, "POST", {
        TId,
        AC: fileAttribute.AC,
        DocId: fileAttribute.DocId,
      });
      if (doc && doc.data !== null) {
        downloadBase64File(doc.data, fileAttribute.Name);
        hideLoader();
      } else {
        notify(1, "Something Went Wrong.");
        hideLoader();
      }
    } else {
      downloadBase64File(fileAttribute.Data, fileAttribute.Name);
      hideLoader();
    }
  };
  function downloadBase64File(base64String: string, fileName: string) {
    const mimeType = inferMimeType(base64String);
    const blob = base64toBlob(base64String, mimeType);

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  }
  function inferMimeType(base64String: string): string {
    const dataUrl = `data:application/octet-stream;base64,${base64String}`;
    const typeInfo = /^data:(.*?);/.exec(dataUrl);

    if (typeInfo && typeInfo[1]) {
      return typeInfo[1];
    }

    return "application/octet-stream";
  }
  function base64toBlob(base64String: string, mimeType: string): Blob {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], { type: mimeType });
  }
  const handleDeleteFileForFlat = async (fileAttribute, fileIndex) => {
    if (
      fileAttribute.DocId != undefined &&
      fileAttribute.DocId != null &&
      fileAttribute.DocId != 0
    ) {
      showLoader();
      const doc = await APICall(deleteDocument, "POST", {
        TId,
        AC: fileAttribute.AC,
        DocId: fileAttribute.DocId,
        UserId: proxyUserDetails?.EmployeeId
          ? proxyUserDetails?.EmployeeId
          : userDetails.UserId,
      });
      if (doc.message === "Success") {
        FlatDocRef.current.value = null;
        let remainingDocs = selectedFilesForFlat.filter(function (obj) {
          return obj.DocId !== fileAttribute.DocId;
        });
        setSelectedFilesForFlat(remainingDocs);
        hideLoader();
      } else {
        notify(1, "File not Deleted, Something Went Wrong.");
        hideLoader();
      }
    } else {
      FlatDocRef.current.value = null;
      setSelectedFilesForFlat((prevSelectedFiles) => {
        return prevSelectedFiles.filter(
          (item, attIndex) => fileIndex !== attIndex
        );
      });
    }
  };

  const handleDeleteFileForSet = async (fileAttribute, fileIndex) => {
    if (
      fileAttribute.DocId != undefined &&
      fileAttribute.DocId != null &&
      fileAttribute.DocId != 0
    ) {
      showLoader();
      const doc = await APICall(deleteDocument, "POST", {
        TId,
        AC: fileAttribute.AC,
        DocId: fileAttribute.DocId,
        UserId: proxyUserDetails?.EmployeeId
          ? proxyUserDetails?.EmployeeId
          : userDetails.UserId,
      });
      if (doc.message === "Success") {
        SetDocRef.current.value = null;
        let remainingDocs = selectedFilesForSet.filter(function (obj) {
          return obj.DocId !== fileAttribute.DocId;
        });
        setSelectedFilesForSet(remainingDocs);
        hideLoader();
      } else {
        notify(1, "File not Deleted, Something Went Wrong.");
        hideLoader();
      }
    } else {
      SetDocRef.current.value = null;
      setSelectedFilesForSet((prevSelectedFiles) => {
        return prevSelectedFiles.filter(
          (item, attIndex) => fileIndex !== attIndex
        );
      });
    }
  };

  // const uploadDocumentsAndGetURL = async () => {
  //   try {
  //     const response = await APICall(documentUpload, "POST", {
  //       MN: "EmployeeCentral",
  //       Files: [],
  //       SN: "Basic",
  //       TN: "Personal",
  //     });

  //     if (!response.ok) {
  //       throw new Error("Failed to upload documents");
  //     }

  //     const data = await response.json();
  //     const documentURLs = data.data.map((document) => ({
  //       name: document.internalName,
  //       url: document.internalValue,
  //     }));

  //     return documentURLs; //setattributedata  ---- documentURLs.stringfy karyche
  //   } catch (error) {
  //     throw new Error("Error uploading documents: " + error.message);
  //   }
  // };

  const [newGridIdCreated, setNewGridIdCreated] = useState(null);

  useEffect(() => {
    if (selectedFilesForSet.length > 0) {
      setSelectedFilesForSet((prevSelectedFiles) => {
        // Create a copy of the previous state with the updated Id values
        const updatedSelectedFiles = prevSelectedFiles.map((item, index) => {
          if (item.Id === undefined || item.Id === 0) {
            // If Id is undefined or 0, assign a new value
            return { ...item, Id: newGridIdCreated };
          } else {
            return item; // Keep existing Id values
          }
        });

        return updatedSelectedFiles;
      });
    }
  }, [newGridIdCreated]);

  //Document code end

  useEffect(() => {
    //add attribute in attributesData
    //  ;
    let attributes = {};
    let attributeSet = {};
    const gridColumnsArr = [];
    let dropdownOptionsObj = {};
    const sectionHasDocuments = [];
    section?.Attribute?.forEach(async (eachAttribute) => {
      if (eachAttribute.AT === "Attribute") {
        //change value according to dt

        setAttributesData((prev) => ({
          ...prev,
          [eachAttribute.AC]:
            eachAttribute.DT === "DropdownSingle" ||
            eachAttribute.DT === "Date" ||
            eachAttribute.DT === "SearchableDropdownSingle" ||
            eachAttribute.DT === "Document" ||
            eachAttribute.DT === "DropdownMultiple"
              ? null
              : eachAttribute.DT === "Text" || eachAttribute.DT === "Number"
              ? ""
              : eachAttribute.DT === "Checkbox"
              ? false
              : "",
        }));

        attributes = {
          ...attributes,
          [eachAttribute.AC]:
            eachAttribute.DT === "DropdownSingle" ||
            eachAttribute.DT === "Date" ||
            eachAttribute.DT === "SearchableDropdownSingle" ||
            eachAttribute.DT === "Document" ||
            eachAttribute.DT === "DropdownMultiple"
              ? null
              : eachAttribute.DT === "Text" || eachAttribute.DT === "Number"
              ? ""
              : eachAttribute.DT === "Checkbox"
              ? false
              : "",
        };

        //for document
        if (eachAttribute.DT === "Document") {
          setSectionHasDocuments(true);
          sectionHasDocuments.push(eachAttribute.AC);
        }

        //attributeValidation
        setAttributeValidation((prev) => ({
          ...prev,
          [eachAttribute.AC]: JSON.parse(eachAttribute.V),
        }));

        // FORM 11EPFO ON LOAD SET DISSABLED

        if (
          eachAttribute.AC == "CDUAAN" ||
          eachAttribute.AC == "CDPFAN" ||
          eachAttribute.AC == "CDDOJNEXM" ||
          eachAttribute.AC == "CDESTBNM" ||
          eachAttribute.AC == "CDSCCNONEXM" ||
          eachAttribute.AC == "CDPPONO" ||
          eachAttribute.AC == "CDNPCDNEXM" ||
          eachAttribute.AC == "CDTNNM" ||
          eachAttribute.AC == "CDUAN" ||
          eachAttribute.AC == "CDEPSACCNO" ||
          eachAttribute.AC == "CDDOJEXMT" ||
          eachAttribute.AC == "CDDOEEXMT" ||
          eachAttribute.AC == "CDSSCNOEXMT" ||
          eachAttribute.AC == "CDNPCDEXMT"
        ) {
          setAttributeValidation((prev) => ({
            ...prev,
            [eachAttribute.AC]: {
              ...prev[eachAttribute.AC],
              DD:
                eachAttribute.DD !== null ? JSON.parse(eachAttribute.DD) : null,
              isDisable: true,
            },
          }));
        } else {
          setAttributeValidation((prev) => ({
            ...prev,
            [eachAttribute.AC]: {
              ...prev[eachAttribute.AC],
              DD:
                eachAttribute.DD !== null ? JSON.parse(eachAttribute.DD) : null,
              isDisable: false,
            },
          }));
        }
        // FORM 11EPFO ON LOAD SET DISSABLED

        if (
          eachAttribute.DT === "DropdownSingle" ||
          eachAttribute.DT === "DropdownMultiple"
        ) {
          // api call for each dropdown option

          // const options = await getDropdownsData(eachAttribute.AC, null);

          dropdownOptionsObj = {
            ...dropdownOptionsObj,
            [eachAttribute.AC]: eachAttribute.OPT,
          };
          setOptionsObj((prev) => ({
            ...prev,
            [eachAttribute.AC]: eachAttribute.OPT,
          }));
          /*Hardcode to set Default Value.*/
          if (JSON.parse(eachAttribute.AD).DF !== undefined) {
            let filtredOPT = eachAttribute.OPT?.filter((o) => {
              if (o.label.toLowerCase() === JSON.parse(eachAttribute.AD).DF) {
                return o;
              }
            });
            if (filtredOPT.length > 0) {
              setAttributesData((prev) => ({
                ...prev,
                [eachAttribute.AC]: filtredOPT[0],
              }));
            }
          }
          /*Hardcode to set Default Value.*/
          if (eachAttribute.AC == "CDAMP") {
            setAttributeValidation((prev) => ({
              ...prev,
              [eachAttribute.AC]: {
                ...prev[eachAttribute.AC],
                DD:
                  eachAttribute.DD !== null
                    ? JSON.parse(eachAttribute.DD)
                    : null,
                isDisable: true,
              },
            }));
          } else {
            setAttributeValidation((prev) => ({
              ...prev,
              [eachAttribute.AC]: {
                ...prev[eachAttribute.AC],
                DD:
                  eachAttribute.DD !== null
                    ? JSON.parse(eachAttribute.DD)
                    : null,
                isDisable: false,
              },
            }));
          }

          // if (eachAttribute.AC === "COMMEMAIL"){
          //   setAttributesData((prev) => ({
          //     [eachAttribute.AC]: eachAttribute.OPT,
          //   }));
          // }
          /* Set Country In Organization Information during create */
          if (eachAttribute.AC === "CT") {
            let filtredOPT;
            // if(TId !== 0){
            //   filtredOPT = eachAttribute.OPT?.filter((o) => {
            //     if (o.value === Number(COU)) {
            //       return o;
            //     }
            //   });
            // }
            // else{
            filtredOPT = eachAttribute.OPT?.filter((o) => {
              if (o.code.toLowerCase() === COU.toLowerCase()) {
                return o;
              }
            });
            //}
            if (filtredOPT.length > 0) {
              setAttributesData((prev) => ({
                ...prev,
                [eachAttribute.AC]: filtredOPT[0],
              }));
            }
          }
        } else if (eachAttribute.DT === "SearchableDropdownSingle") {
          dropdownOptionsObj = {
            ...dropdownOptionsObj,
            [eachAttribute.AC]: "SearchableDropdownSingle",
          };

          setOptionsObj((prev) => ({
            ...prev,
            [eachAttribute.AC]: "SearchableDropdownSingle",
          }));
        }
      } else if (eachAttribute.AT === "AttributeSet") {
        setAttributeSetCode(eachAttribute.AC);

        // Id for grid
        gridColumnsArr.push({
          name: "Id",
          label: "Id",
          options: { display: false },
        });

        eachAttribute.Attribute.forEach(async (subAttribute) => {
          //for document
          if (subAttribute.DT === "Document") {
            setSectionHasDocuments(true);
            sectionHasDocuments.push(subAttribute.AC);
          }

          let display = JSON.parse(subAttribute.AD);
          display = display?.ISOG?.toLowerCase() === "true" ? true : false;

          //grid columns
          gridColumnsArr.push({
            name: subAttribute.AC,
            label: subAttribute.AN,
            options: { sort: false, display },
          });
          //attributeSetValidation
          setAttributeSetValidation((prev) => ({
            ...prev,
            [subAttribute.AC]: JSON.parse(
              subAttribute.AC === "ATTP" &&
                ((globalEC[0] != undefined &&
                  globalEC[0].ecLabel === "Staff") ||
                  (globalEC[0] != undefined &&
                    globalEC[0].ecLabel === "Retainer"))
                ? '{"ISM": "true","FS": "5","FX": ".docx,.doc,.pdf,.png,.jpg,.jpeg,.txt,.ppt,.pptx","FC": "1"}'
                : subAttribute.AC === "BNKATTCH" &&
                  ((globalEC[0] != undefined &&
                    globalEC[0].ecLabel === "Staff") ||
                    (globalEC[0] != undefined &&
                      globalEC[0].ecLabel === "Retainer"))
                ? '{"ISM": "true","FS": "5","FX": ".docx,.doc,.pdf,.png,.jpg,.jpeg,.txt,.ppt,.pptx","FC": "1"}'
                : // :subAttribute.AC === "ACT" && attributeSetData[subAttribute.AC].label!==undefined && attributeSetData[subAttribute.AC].label==="Car/vehicle"
                  // ?'{"ISM": "true"}'
                  subAttribute.V
            ),
          }));

          setAttributeSetValidation((prev) => ({
            ...prev,
            [subAttribute.AC]: {
              ...prev[subAttribute.AC],
              DD: subAttribute.DD !== null ? JSON.parse(subAttribute.DD) : null,
              isDisable: false,
            },
          }));

          //change value according to dt

          setAttributeSetData((prev) => ({
            ...prev,
            [subAttribute.AC]:
              subAttribute.DT === "DropdownSingle" ||
              subAttribute.DT === "Date" ||
              subAttribute.DT === "MonthYear" ||
              subAttribute.DT === "SearchableDropdownSingle" ||
              subAttribute.DT === "Document"
                ? null
                : subAttribute.DT === "Text" || subAttribute.DT === "Number"
                ? ""
                : subAttribute.DT === "Checkbox"
                ? false
                : "",
          }));

          attributeSet = {
            ...attributeSet,
            [subAttribute.AC]:
              subAttribute.DT === "DropdownSingle" ||
              subAttribute.DT === "Date" ||
              subAttribute.DT === "MonthYear" ||
              subAttribute.DT === "SearchableDropdownSingle" ||
              subAttribute.DT === "Document" ||
              subAttribute.DT === "DropdownMultiple"
                ? null
                : subAttribute.DT === "Text" || subAttribute.DT === "Number"
                ? ""
                : subAttribute.DT === "Checkbox"
                ? false
                : "",
          };

          if (
            subAttribute.DT === "DropdownSingle" ||
            subAttribute.DT === "DropdownMultiple"
          ) {
            // const options = await getDropdownsData(subAttribute.AC, null);

            dropdownOptionsObj = {
              ...dropdownOptionsObj,
              [subAttribute.AC]: subAttribute.OPT,
            };

            if (
              subAttribute.AC === "STSE" &&
              section.SN === "FamilyMemberDetails"
            ) {
              if (subAttribute.OPT?.length > 0) {
                setAttributeSetData((prev) => ({
                  ...prev,
                  [subAttribute.AC]: subAttribute.OPT?.filter(
                    (x) => x.label.toLowerCase() === "living"
                  )[0],
                }));

                attributeSet = {
                  ...attributeSet,
                  [subAttribute.AC]: subAttribute.OPT?.filter(
                    (x) => x.label.toLowerCase() === "living"
                  )[0],
                };
              }
            }

            if (
              subAttribute.AC === "PVSEMP" &&
              section.SN === "PreviousEmployment"
            ) {
              if (subAttribute.OPT?.length > 0) {
                setAttributeSetData((prev) => ({
                  ...prev,
                  [subAttribute.AC]: subAttribute.OPT?.filter(
                    (x) => x.label.toLowerCase() === "living"
                  )[0],
                }));

                attributeSet = {
                  ...attributeSet,
                  [subAttribute.AC]: subAttribute.OPT?.filter(
                    (x) => x.label.toLowerCase() === "living"
                  )[0],
                };
              }
            }

            setOptionsObj((prev) => ({
              ...prev,
              [subAttribute.AC]: subAttribute.OPT,
            }));

            /*Hardcode to set Default Value.*/
            if (JSON.parse(subAttribute.AD).DF !== undefined) {
              let filtredOPT = subAttribute.OPT?.filter((o) => {
                if (o.label.toLowerCase() === JSON.parse(subAttribute.AD).DF) {
                  return o;
                }
              });
              if (filtredOPT.length > 0) {
                setAttributeSetData((prev) => ({
                  ...prev,
                  [subAttribute.AC]: filtredOPT[0],
                }));

                attributeSet = {
                  ...attributeSet,
                  [subAttribute.AC]: filtredOPT[0],
                };
              }
            }
            /*Hardcode to set Default Value.*/
          } else if (subAttribute.DT === "SearchableDropdownSingle") {
            dropdownOptionsObj = {
              ...dropdownOptionsObj,
              [subAttribute.AC]: "SearchableDropdownSingle",
            };

            setOptionsObj((prev) => ({
              ...prev,
              [subAttribute.AC]: "SearchableDropdownSingle",
            }));
          }
        });
      }
    });

    // setAttributesData((prev) => ({
    //   ...prev,
    //   ...attributes,
    // }));

    // setAttributeSetData((prev) => ({
    //   ...prev,
    //   ...attributeSet,
    // }));

    setOgAttributeSet(attributeSet);
    setGridColumns((prev) => [...prev, ...gridColumnsArr, action]);

    // setOptionsObj((prev) => ({ ...prev, ...dropdownOptionsObj }));

    //audit
    const dataForAuditLog = [];
    if (Object.keys(attributes).length > 0) {
      Object.entries(attributes).forEach((data) => {
        const [key, value]: any[] = data;

        const auditLogObj = {
          sectionId: section.SId.toString(),
          IsAttributeSet: false,
          Code: key,
          Attribute: [
            {
              [key]: value?.label || value,
            },
          ],
        };
        dataForAuditLog.push(auditLogObj);
      });
    }

    setOldAuditLog((prev) => [...prev, ...dataForAuditLog]);

    const fillData = async () => {
      await showLoader();
      if (isOnce && TId !== 0) {
        //fetch attributes/set data

        //check if not first time then call api
        let attributesRes = null;
        // let bodyObj = {
        //   mn: "EmployeeCentral",
        //   in: "EC_Form",
        //   tn: formData.tn,
        //   SN: section.SN,
        //   TID: TId,
        //   ISH: "false",
        // };
        // (async () => {
        //   const response = await APICall(
        //     getAttributeValueDetails,
        //     "POST",
        //     bodyObj
        //   );

        if (hasValue.length > 0 && hasValue[0].attributes.length > 0) {
          attributesRes = hasValue[0].attributes;
          // handle data

          if (attributesRes !== null && attributesRes.length > 0) {
            let newAttributeData: any = { ...attributes };
            if (sectionHasDocuments.length > 0) {
              // getDocuments for Flat
              for (const AC of sectionHasDocuments) {
                if (newAttributeData.hasOwnProperty(AC)) {
                  await showLoader();
                  const getDocument = await APICall(getDocuments, "POST", {
                    TId,
                    AC: AC,
                  });

                  if (getDocument && getDocument.data !== null) {
                    setSelectedFilesForFlat(getDocument.data.files);
                  } else {
                    setSelectedFilesForFlat([]);
                  }
                  await hideLoader();
                }
              }
            }
            //loop for setting flat attributes on edit
            for (const eachAttribute of attributesRes) {
              if (eachAttribute.type === "Attribute") {
                let { key, value: valueForField } = eachAttribute;

                if (
                  dropdownOptionsObj[key] !== undefined &&
                  dropdownOptionsObj[key] !== "SearchableDropdownSingle"
                ) {
                  //for filling normal dropdown on edit
                  const options = dropdownOptionsObj[key];

                  if (options !== undefined && options?.length > 0) {
                    const option = options?.find(
                      (attri) => attri.value == valueForField
                    );

                    newAttributeData = {
                      ...newAttributeData,
                      [key]: option === undefined ? null : option,
                    };
                    // FORM 11EPFO ON LOAD SET DISSABLED
                    if (key === "CDAMPF") {
                      if (option != null && option?.label === "Yes") {
                        setAttributeValidation((prev) => ({
                          ...prev,
                          ["CDAMP"]: {
                            ...prev["CDAMP"],
                            ISM: "true",
                            isDisable: false,
                          },
                          ["CDUAAN"]: {
                            ...prev["CDUAAN"],
                            ISM: "true",
                            isDisable: false,
                          },
                          ["CDPFAN"]: {
                            ...prev["CDPFAN"],
                            ISM: "true",
                            isDisable: false,
                          },
                          ["CDDOJNEXM"]: {
                            ...prev["CDDOJNEXM"],
                            ISM: "true",
                            isDisable: false,
                          },
                          ["CDESTBNM"]: {
                            ...prev["CDESTBNM"],
                            ISM: "true",
                            isDisable: false,
                          },
                          ["CDSCCNONEXM"]: {
                            ...prev["CDSCCNONEXM"],
                            isDisable: false,
                          },
                          ["CDPPONO"]: {
                            ...prev["CDPPONO"],
                            isDisable: false,
                          },
                          ["CDNPCDNEXM"]: {
                            ...prev["CDNPCDNEXM"],
                            isDisable: false,
                          },
                          ["CDTNNM"]: {
                            ...prev["CDTNNM"],
                            isDisable: false,
                          },
                          ["CDUAN"]: {
                            ...prev["CDUAN"],
                            isDisable: false,
                          },
                          ["CDEPSACCNO"]: {
                            ...prev["CDEPSACCNO"],
                            isDisable: false,
                          },
                          ["CDDOJEXMT"]: {
                            ...prev["CDDOJEXMT"],
                            isDisable: false,
                          },
                          ["CDDOEEXMT"]: {
                            ...prev["CDDOEEXMT"],
                            isDisable: false,
                          },
                          ["CDSSCNOEXMT"]: {
                            ...prev["CDSSCNOEXMT"],
                            isDisable: false,
                          },
                          ["CDNPCDEXMT"]: {
                            ...prev["CDESTBNM"],
                            isDisable: false,
                          },
                        }));
                      }
                    }
                    // FORM 11EPFO ON LOAD SET DISSABLED

                    if (key === "COMMPHN" || key === "COMMEMAIL") {
                      if (
                        attributesRes.find((item) => item.key === "COMMPHN")
                          .value !== undefined &&
                        attributesRes.find((item) => item.key === "COMMPHN")
                          .value !== null &&
                        attributesRes.find((item) => item.key === "COMMEMAIL")
                          .value !== null &&
                        attributesRes.find((item) => item.key === "COMMEMAIL")
                          .value !== undefined
                      ) {
                        newAttributeData = {
                          ...newAttributeData,
                          ["MODOFCOMM"]: dropdownOptionsObj["MODOFCOMM"],
                        };
                      } else if (
                        key === "COMMEMAIL" &&
                        valueForField !== null &&
                        valueForField !== undefined
                      ) {
                        newAttributeData = {
                          ...newAttributeData,
                          ["MODOFCOMM"]: dropdownOptionsObj["MODOFCOMM"].find(
                            (otp) => otp.code === "Email"
                          ),
                        };
                      } else if (
                        key === "COMMPHN" &&
                        valueForField !== null &&
                        valueForField !== undefined
                      ) {
                        newAttributeData = {
                          ...newAttributeData,
                          ["MODOFCOMM"]: dropdownOptionsObj["MODOFCOMM"].find(
                            (otp) => otp.code === "Phone"
                          ),
                        };
                      }
                    }
                  }
                } else if (
                  dropdownOptionsObj[key] !== undefined &&
                  dropdownOptionsObj[key] === "SearchableDropdownSingle"
                ) {
                  //for filling searchable dropdown on edit
                  if (valueForField !== undefined) {
                    const DDResponse = await getSearchableDropdownAPI(
                      "",
                      key,
                      null,
                      valueForField,
                      "attribute",
                      0
                    );
                    newAttributeData = {
                      ...newAttributeData,
                      [key]:
                        DDResponse === undefined
                          ? null
                          : {
                              label: DDResponse.label,
                              value: DDResponse.value,
                            },
                    };
                  }
                } else {
                  //filling other data

                  valueForField =
                    typeof valueForField === "string" &&
                    valueForField.toLowerCase() === "true"
                      ? true
                      : typeof valueForField === "string" &&
                        valueForField.toLowerCase() === "false"
                      ? false
                      : valueForField !== null && valueForField !== undefined
                      ? valueForField.toString()
                      : valueForField;

                  // format date

                  const dateString = valueForField;
                  const allowedFormat = [
                    "DD-MM-YYYY hh.mm.ss A",
                    "DD/MM/YYYY hh.mm.ss A",
                  ];

                  const isValidDate = moment(
                    dateString,
                    allowedFormat,
                    true
                  ).isValid();

                  const isValidBeforeDate = moment(
                    dateString,
                    "DD-MMM-YYYY",
                    true
                  ).isValid();
                  if (isValidBeforeDate && dateString === "01-Jan-1900") {
                    valueForField = null;
                  }

                  if (
                    isValidDate &&
                    dateString !== "01-01-0001 12.00.00 AM" &&
                    dateString !== "1/1/0001 12:00:00 AM"
                  ) {
                    const parsedDate = moment(valueForField, allowedFormat[0]);
                    const formattedDate = parsedDate.format("DD-MMM-YYYY");
                    valueForField = formattedDate;
                  } else if (
                    dateString === "01-01-0001 12.00.00 AM" ||
                    dateString === "1/1/0001 12:00:00 AM"
                  ) {
                    valueForField = null;
                  }

                  newAttributeData = {
                    ...newAttributeData,
                    [key]: valueForField,
                  };

                  //hard code to fill display name on load
                  if (section.SN === "Basic") {
                    let name =
                      newAttributeData.MN !== null &&
                      newAttributeData.MN !== undefined &&
                      newAttributeData.MN !== ""
                        ? `${newAttributeData.FN} ${newAttributeData.MN} ${newAttributeData.LN}`.trim()
                        : `${newAttributeData.FN} ${newAttributeData.LN}`.trim();
                    newAttributeData = {
                      ...newAttributeData,
                      ["DN"]: `${name}`.trim(),
                    };

                    if (
                      newAttributeData.DOB != null ||
                      newAttributeData.DOB != undefined ||
                      newAttributeData.DOB != ""
                    ) {
                      const ageInYears = moment().diff(
                        moment(newAttributeData.DOB).format(),
                        "years"
                      );

                      newAttributeData = {
                        ...newAttributeData,
                        ["AGE"]: ageInYears,
                      };
                    }
                  }
                }
              }
            }
            setAttributesData(newAttributeData);

            //audit
            const dataForAuditLog = [];
            if (Object.keys(newAttributeData).length > 0) {
              Object.entries(newAttributeData).forEach((data) => {
                const [key, value]: any[] = data;

                const auditLogObj = {
                  sectionId: section.SId.toString(),
                  IsAttributeSet: false,
                  Code: key,
                  Attribute: [
                    {
                      [key]: value?.label || value,
                    },
                  ],
                };
                dataForAuditLog.push(auditLogObj);
              });
            }
            //audit old data for flat
            setOldAuditLog((prev) => [...dataForAuditLog]);

            //loop for setting set attributes on edit
            for (const eachAttribute of attributesRes) {
              if (eachAttribute.type === "AttributeSet") {
                //AttributesSet

                if (
                  Object.keys(attributeSet).length > 0 &&
                  eachAttribute.details !== null &&
                  eachAttribute.details.length > 0
                ) {
                  const setCode = eachAttribute.key;
                  setAttributeSetCode(eachAttribute.key);

                  if (sectionHasDocuments.length > 0) {
                    // getDocuments for Set
                    for (const AC of sectionHasDocuments) {
                      if (attributeSet.hasOwnProperty(AC)) {
                        await showLoader();
                        const getDocument = await APICall(
                          getDocuments,
                          "POST",
                          {
                            TId,
                            AC: AC,
                          }
                        );

                        if (getDocument && getDocument.data !== null) {
                          setSelectedFilesForSet(getDocument.data.files);
                        } else {
                          setSelectedFilesForSet([]);
                        }
                        await hideLoader();
                      }
                    }
                  }

                  const details = eachAttribute.details;

                  if (details.length > 0) {
                    let newAttributeSetData = { ...attributeSet };
                    let newAttributeSetDataForGrid = { ...attributeSet };
                    let dropdownOptions = { ...dropdownOptionsObj };

                    const filledData = [];
                    const gridData = [];
                    details.forEach(async (detail) => {
                      //add isActive for all details
                      detail.IsActive = true;

                      Object.entries(detail).forEach(async (attribute) => {
                        const [Code, Value]: any = attribute;

                        if (
                          Object.keys(dropdownOptions).find(
                            (option) =>
                              option.toLowerCase() === Code.toLowerCase()
                          ) &&
                          dropdownOptions[Code] !== "SearchableDropdownSingle"
                        ) {
                          const options = dropdownOptions[Code];
                          if (options !== undefined && options.length > 0) {
                            const option = options.find(
                              (attri) => attri.value == Value
                            );

                            newAttributeSetDataForGrid = {
                              ...newAttributeSetDataForGrid,
                              [Code]:
                                option === undefined ? null : option?.label,
                            };

                            newAttributeSetData = {
                              ...newAttributeSetData,
                              [Code]: option === undefined ? null : option,
                            };
                          }
                        } else if (
                          Object.keys(dropdownOptions).find(
                            (option) =>
                              option.toLowerCase() === Code.toLowerCase()
                          ) &&
                          dropdownOptions[Code] === "SearchableDropdownSingle"
                        ) {
                          if (Value !== undefined) {
                            await getSearchableDropdownAPI(
                              "",
                              Code,
                              null,
                              Value,
                              "attributeSet",
                              detail.Id
                            );
                          }
                        } else {
                          //check type later when actual data

                          let valueForGrid =
                            typeof Value === "string" &&
                            Value.toLowerCase() === "true"
                              ? true
                              : typeof Value === "string" &&
                                Value.toLowerCase() === "false"
                              ? false
                              : Value !== null
                              ? Value.toString()
                              : Value;

                          // format date

                          const dateString = Value;
                          const allowedFormatGrid = [
                            "DD-MM-YYYY hh.mm.ss A",
                            "DD/MM/YYYY hh.mm.ss A",
                            "DD-MMM-YYYY",
                          ];

                          const isValidDateGrid = moment(
                            dateString,
                            allowedFormatGrid,
                            true
                          ).isValid();
                          if (
                            isValidDateGrid &&
                            dateString !== "01-01-0001 12.00.00 AM" &&
                            dateString !== "1/1/0001 12:00:00 AM" &&
                            dateString !== "01-Jan-0001" &&
                            dateString !== "01-Jan-1900"
                          ) {
                            // const parsedDate = moment(Value, allowedFormat[0]);
                            // const formattedDate =
                            //   parsedDate.format("DD-MM-YYYY");
                            // valueForGrid = formattedDate;
                            const formattedDate = moment(
                              Value,
                              "DD-MMM-YYYY"
                            ).format("DD/MM/YYYY");
                            valueForGrid = formattedDate;
                          } else if (
                            dateString === "01-01-0001 12.00.00 AM" ||
                            dateString === "1/1/0001 12:00:00 AM" ||
                            dateString === "01-Jan-0001" ||
                            dateString === "01-Jan-1900"
                          ) {
                            valueForGrid = null;
                          }

                          const allowedFormatField = [
                            "DD-MM-YYYY hh.mm.ss A",
                            "DD/MM/YYYY hh.mm.ss A",
                          ];

                          const isValidDateField = moment(
                            dateString,
                            allowedFormatField,
                            true
                          ).isValid();
                          let valueForField =
                            typeof Value === "string" &&
                            Value.toLowerCase() === "true"
                              ? true
                              : typeof Value === "string" &&
                                Value.toLowerCase() === "false"
                              ? false
                              : typeof Value === "boolean"
                              ? Value
                              : Value !== null
                              ? Value.toString()
                              : Value;

                          if (
                            isValidDateField &&
                            dateString !== "01-01-0001 12.00.00 AM" &&
                            dateString !== "1/1/0001 12:00:00 AM"
                          ) {
                            const parsedDate = moment(
                              Value,
                              allowedFormatField[0]
                            );
                            const formattedDate =
                              parsedDate.format("DD-MMM-YYYY");
                            valueForField = formattedDate;
                          } else if (
                            dateString === "01-01-0001 12.00.00 AM" ||
                            dateString === "1/1/0001 12:00:00 AM"
                          ) {
                            valueForField = null;
                          }
                          const isValidBeforeDate = moment(
                            dateString,
                            "DD-MMM-YYYY",
                            true
                          ).isValid();
                          if (
                            isValidBeforeDate &&
                            (dateString === "01-Jan-1900" ||
                              dateString === "01-Jan-0001")
                          ) {
                            valueForField = null;
                          }

                          newAttributeSetDataForGrid = {
                            ...newAttributeSetDataForGrid,
                            [Code]: valueForGrid,
                          };

                          newAttributeSetData = {
                            ...newAttributeSetData,
                            [Code]: valueForField,
                          };
                        }
                      });
                      gridData.push(newAttributeSetDataForGrid);
                      filledData.push(newAttributeSetData);
                    });

                    //audit
                    const oldAuditLog = [];

                    for (const attribute of filledData) {
                      const shadowObj = { ...attribute };
                      const auditObj = transformDynamicKey(shadowObj);

                      const obj = {
                        IsAttributeSet: true,
                        Code: setCode,
                        RecordId: attribute.Id,
                        sectionId: section.SId.toString(),
                        Attribute: [
                          {
                            ...auditObj,
                          },
                        ],
                      };

                      oldAuditLog.push(obj);
                    }

                    setOldAuditLog((prev) => [...prev, ...oldAuditLog]);

                    setDisplayGrid(gridData);
                    setGridData(gridData);
                    setOgFilledAttributeSet(filledData);
                  }
                  // setIsOnce(false);
                }
              }
            }
          }
        }
        setIsOnce(false);
        // })();
      }
      await hideLoader();
    };

    const fillTimer = setTimeout(() => {
      clearTimeout(fillTimer);
      fillData();
    }, 2000);

    //save in state
    return () => {
      clearTimeout(fillTimer);
    };
  }, []);

  const transformDynamicKey = (object) => {
    for (const key in object) {
      if (
        object[key] &&
        typeof object[key] === "object" &&
        object[key].hasOwnProperty("label")
      ) {
        object[key] = object[key].label;
      }
    }
    return object;
  };

  // useEffect(() => {}, [attributesData, attributeSetData, optionsObj]);

  const checkSameAddressType = (event, checkboxName) => {
    // Sanket Method for checking same address type
    if (checkboxName === "emergency") {
      setIsSameAsEmergency(!isSameAsEmergency);
    } else if (checkboxName === "permanent") {
      setIsSameAsPermanent(!isSameAsPermanent);
    } else if (checkboxName === "present") {
      setIsSameAsPresent(!isSameAsPresent);
    }
  };
  useEffect(() => {
    // ;
    if (
      attributesData !== undefined &&
      Object.keys(attributesData).length > 0 &&
      attributeValidation !== undefined &&
      Object.keys(attributeValidation).length > 0
    ) {
      if (
        (isValidateOnce && isOnce === false && TId !== 0) ||
        (isValidateOnce && isOnce === true && TId === 0)
      ) {
        let attributeValues = { ...attributesData }; // for checking values
        let attributeValObj = { ...attributeValidation };
        Object.keys(attributeValObj).forEach(async (attribute) => {
          if (
            attributeValObj[attribute].DD !== null &&
            attributeValObj[attribute].DD.length > 0
          ) {
            let ad = attributeValObj[attribute].DD;

            ad.forEach(async (record) => {
              //   CCode: "POLENDT",
              // PValue: "false",
              // Condition: "equalTo",
              // Flag: "disable",
              // PDT: "checkbox",
              // CDT: "checkbox",
              let CCode = record.CCode;
              let PValue = record.PValue;
              let Condition = record.Condition;
              let Flag = record.Flag;
              let PDT = record.PDT;
              let CDT = record.CDT;

              if (PDT.toLowerCase() === "checkbox") {
                if (
                  attributeValues[attribute].toString().toLowerCase() ===
                    PValue.toLowerCase() ||
                  attributeValues[attribute].toString().toLowerCase() === ""
                ) {
                  if (Flag === "disable") {
                    attributeValObj[CCode].isDisable = true;
                    attributeValObj[CCode].ISM = "false";
                  }
                } else if (
                  attributeValues[attribute].toString().toLowerCase() !==
                  PValue.toLowerCase()
                ) {
                  if (Flag === "disable") {
                    attributeValObj[CCode].isDisable = false;
                    attributeValObj[CCode].ISM = "true";
                  }
                }
              } else if (PDT === "DropdownSingle") {
                if (attribute === "MARITLST") {
                  if (attributeValues[attribute]?.label === "Married") {
                    attributeValObj["ProofBD"].ISM = "false";
                  }
                }
                if (
                  attributeValues[attribute] !== null &&
                  attributeValues[attribute].label.toString().toLowerCase() ===
                    PValue.toLowerCase()
                ) {
                  //check condition
                  if (Flag === "disable") {
                    attributeValObj[CCode].isDisable = true;
                    attributeValObj[CCode].ISM = "false";
                  }
                } else {
                  if (attributeValObj[CCode] !== undefined) {
                    attributeValObj[CCode].isDisable = false;
                    attributeValObj[CCode].ISM =
                      attributeValues[attribute]?.label === "Married"
                        ? "true"
                        : CCode === "MARITLSTS"
                        ? "false"
                        : attributeValues[attribute]?.label === "Worker"
                        ? "true"
                        : CCode === "SC"
                        ? "false"
                        : "true";
                  }
                }
              } else if (PDT === "Document") {
              }
            });
          }
          //else {
          //   if (attribute === "Gen") {
          //     if (attributeValues[attribute]?.label === "Female") {
          //       attributeValObj["MAN"].isDisable = false;
          //       attributeValObj["MAN"].ISM = "true";
          //     }
          //   }
          // }
        });
        setAttributeValidation((prev) => ({ ...prev, ...attributeValObj }));
        setIsValidateOnce(false);
      }
    }
  }, [attributesData, isOnce]);

  const action = {
    name: "Id",
    label: "Action",
    options: {
      filter: false,
      sort: false,
      display: true,
      setCellProps: () => ({
        style: { textAlign: "center" },
      }),
      setCellHeaderProps: () => ({
        style: { textAlign: "center" },
      }),
      customBodyRender: (value, tableMeta) => {
        let Id = tableMeta.tableData[tableMeta.rowIndex].Id;

        return (
          <div className="d-flex justify-content-center">
            {section?.ISView && (
              <Tooltip title="view">
                <a
                  className="mr-2"
                  onClick={(e) => {
                    e.preventDefault();
                    setFormErrors({});
                    setMultiDropdown(false);
                    setShowAttrSet(true);
                    setViewOnly(true);
                    setCurrentGridId(Id);
                  }}
                >
                  <i className="fa-solid fa-eye"></i>
                </a>
              </Tooltip>
            )}
            {candidateEdit && section?.ISEDIT && (
              <Tooltip title="edit">
                <a
                  className="mr-2"
                  onClick={(e) => {
                    e.preventDefault();
                    setFormErrors({});
                    setMultiDropdown(false);
                    setShowAttrSet(true);
                    setViewOnly(false);
                    setCurrentGridId(Id);
                  }}
                >
                  <i className="fas fa-edit"></i>
                </a>
              </Tooltip>
            )}
            {
              //section?.ISDEL &&
              candidateEdit && (
                <Tooltip title="delete">
                  <a
                    className="mr-2"
                    onClick={(e) => {
                      e.preventDefault();
                      setFormErrors({});
                      setDeleteGridId(Id);
                    }}
                  >
                    <i className="fa fa-trash" aria-hidden="true"></i>
                  </a>
                </Tooltip>
              )
            }
          </div>
        );
      },
    },
  };

  useEffect(() => {
    // ;
    if (currentGridId) {
      let attributeSetData = ogFilledAttributeSet.find(
        (record) => record.Id === currentGridId
      );
      // When Full n Final is Selected set witness mandatory n Enabled - NomineeDet --on Editing
      if (
        attributeSetData.TYOFNOM != undefined &&
        attributeSetData.TYOFNOM != null
      ) {
        let attributeSetValObj = {
          ...attributeSetValidation,
        };
        if (attributeSetData.TYOFNOM.label === "Full & Final") {
          attributeSetValObj.NOM_Witness1Name.ISM = "true";
          attributeSetValObj.NOM_Witness1Address.ISM = "true";
          attributeSetValObj.NOM_Witness2Name.ISM = "true";
          attributeSetValObj.NOM_Witness2Address.ISM = "true";

          attributeSetValObj.NOM_Witness1Name.isDisable = false;
          attributeSetValObj.NOM_Witness1Address.isDisable = false;
          attributeSetValObj.NOM_Witness2Name.isDisable = false;
          attributeSetValObj.NOM_Witness2Address.isDisable = false;
        } else {
          attributeSetValObj.NOM_Witness1Name.ISM = "false";
          attributeSetValObj.NOM_Witness1Address.ISM = "false";
          attributeSetValObj.NOM_Witness2Name.ISM = "false";
          attributeSetValObj.NOM_Witness2Address.ISM = "false";

          attributeSetValObj.NOM_Witness1Name.isDisable = true;
          attributeSetValObj.NOM_Witness1Address.isDisable = true;
          attributeSetValObj.NOM_Witness2Name.isDisable = true;
          attributeSetValObj.NOM_Witness2Address.isDisable = true;
        }
        setAttributeSetValidation((prev) => ({
          ...prev,
          ...attributeSetValObj,
        }));
      }
      // Set Mandatory false if selected Freshers -- 12-02-2024 -Ankita
      if (
        attributeSetData.PVSEMP != undefined &&
        attributeSetData.PVSEMP != null
      ) {
        let attributeSetValObj = {
          ...attributeSetValidation,
        };
        if (attributeSetData.PVSEMP.label === "Fresher") {
          attributeSetValObj.PRVEMPLYATT.ISM = "false";
        } else {
          attributeSetValObj.PRVEMPLYATT.ISM = "true";
        }
      }

      if (attributeSetData.PA != undefined && attributeSetData.PA != null) {
        if (
          attributeSetData.PA.label.toLowerCase() === "emergency" ||
          attributeSetData.PA.label.toLowerCase() === "permanent"
        ) {
          setOptionsObj((prev) => ({
            ...prev,
            ["ADRTYP"]: [],
          }));

          let attributeSetValObj = {
            ...attributeSetValidation,
          };
          attributeSetValObj.ADRTYP.ISM = "false";
          if (attributeSetData.PA.label.toLowerCase() === "permanent") {
            attributeSetValObj.ADRTYP.isDisable = "true";
          }
          setAttributeSetValidation((prev) => ({
            ...prev,
            ...attributeSetValObj,
          }));
        }
      }
      if (
        attributeSetData.IDCATY != undefined &&
        attributeSetData.IDCATY != null
      ) {
        let attributeSetValObj = {
          ...attributeSetValidation,
        };
        if (
          attributeSetData.IDCATY.label.toLowerCase() === "aadhar card" ||
          attributeSetData.IDCATY.label.toLowerCase() === "pan card"
        ) {
          if (
            attributeSetData.IDCATY.label.toLowerCase() === "aadhar card" ||
            attributeSetData.IDCATY.label.toLowerCase() === "pan card"
          ) {
            attributeSetValObj.NTNLATTCH.ISM = "true";
          } else {
            attributeSetValObj.NTNLATTCH.ISM = "false";
          }
        } else {
          attributeSetValObj.NTNLATTCH.ISM = "false";
        }
      }
      if (
        attributeSetData.PVSEMP != undefined &&
        attributeSetData.PVSEMP != null
      ) {
        dependentValidations(attributeSetData.PVSEMP, "PVSEMP", "Attributeset");
      }
      setAttributeSetData(attributeSetData);
      handleEffectiveDatePrefill();
    }
  }, [currentGridId]);

  useEffect(() => {
    //  ;
    if (deleteGridId && currentGridId === null) {
      //to disable grid delete button when edit is active

      setGridData((prev) => {
        return prev
          .map((record) => {
            if (record.Id === deleteGridId) {
              // Check if Id is a string/uuid, remove the entry from state
              if (typeof record.Id === "string" && isNaN(record.Id)) {
                return null; // Return null to filter this entry out
              }
              // Update the value for numeric Id
              return { ...record, IsActive: false };
            } else {
              return record;
            }
          })
          .filter((record) => record !== null); // Filter out entries with null (for uuid Ids)
      });

      setOgFilledAttributeSet((prev) => {
        return prev
          .map((record) => {
            if (record.Id === deleteGridId) {
              if (
                record.PCE?.label === "Fixed Pay" ||
                record.PCE?.label === "Variable Pay"
              ) {
                setAttributesData((prev) => ({
                  ...prev,
                  ASA: attributesData["ASA"]
                    ? (
                        parseInt(attributesData["ASA"]) - parseInt(record.AMT)
                      ).toString()
                    : "0",
                }));
              } else if (record.PCE?.label === "Base Pay") {
                setAttributesData((prev) => ({
                  ...prev,
                  ASA: "0",
                }));
              } else if (record.PCE?.label === "Stipend") {
                setAttributesData((prev) => ({
                  ...prev,
                  ASA: "0",
                }));
              }

              if (typeof record.Id === "string" && isNaN(record.Id)) {
                return null; // Return null to filter this entry out
              }
              return {
                ...record,
                IsActive: false,
              };
            } else {
              return record;
            }
          })
          .filter((record) => record !== null);
      });

      setDisplayGrid((prev) =>
        prev.filter((record) => record.Id !== deleteGridId)
      );

      //delete documents for that grid record
      let fileToDelete = selectedFilesForSet.filter((sf) => {
        return sf.Id === deleteGridId;
      });
      if (fileToDelete.length > 0) {
        handleDeleteFileForSet(fileToDelete[0], 0);
      }
      // setSelectedFilesForSet((prevSelectedFiles) => {
      //   return prevSelectedFiles.filter((item) => item.Id !== deleteGridId);
      // });
    }
    setDeleteGridId(null);
  }, [deleteGridId]);

  const gridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    // count: gridData.length,
    rowsPerPage: PageSize,
    page: page,
    serverSide: false,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",

    //standard | vertical | simple
    onChangeRowsPerPage: (num) => {
      //   setLimit(num);
      //   setNxtPgInfo("");
      //   setPrevPgInfo("");
      //   setIsPrevOrNext("");
    },
    // onSearchChange: (searchText) => {
    //   if (searchText !== null) {
    //     setSearchText(searchText);
    //   } else {
    //     setSearchText("");
    //   }
    // },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        // await setDashboardSortColumn(sortColumn);
        // await setDashboardSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        // await setDashboardSortColumn(sortColumn);
        // await setDashboardSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      // await setPage(page);
      // await setDashboardStart(page * dashboardPageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const dependentValidations = (
    targetValue,
    targetAttributeCode,
    attributeType
  ) => {
    //  ;
    if (attributeType === "Attribute") {
      let attributeValues = { ...attributesData }; // for checking values
      let attributeValObj = { ...attributeValidation };

      if (
        attributeValObj[targetAttributeCode].DD !== null &&
        attributeValObj[targetAttributeCode].DD.length > 0
      ) {
        let ad = attributeValObj[targetAttributeCode].DD;

        ad.forEach(async (record) => {
          let CCode = record.CCode;
          let PValue = record.PValue;
          let Condition = record.Condition;
          let Flag = record.Flag;
          let PDT = record.PDT;
          let CDT = record.CDT;

          if (PDT === "DropdownSingle") {
            if (
              targetValue &&
              targetValue.label.toString().toLowerCase() ===
                PValue.toLowerCase()
            ) {
              //check condition
              if (Flag === "disable") {
                attributeValObj[CCode].isDisable = true;
                attributeValObj[CCode].ISM = "false";
              }
            } else {
              attributeValObj[CCode].isDisable = false;
              attributeValObj[CCode].ISM =
                targetValue?.label === "Married"
                  ? "true"
                  : CCode === "MARITLSTS"
                  ? "false"
                  : targetValue?.label === "Worker"
                  ? "true"
                  : CCode === "SC"
                  ? "false"
                  : CCode === "VACXDT"
                  ? "false"
                  : CCode === "VACXDT2"
                  ? "false"
                  : CCode === "VACXDT3"
                  ? "false"
                  : "true";
            }
          } else if (PDT.toLowerCase() === "checkbox") {
            if (targetValue.toString().toLowerCase() === PValue.toLowerCase()) {
              //check condition
              if (Flag === "disable") {
                attributeValObj[CCode].isDisable = true;
                attributeValObj[CCode].ISM = "false";
              }
            } else {
              attributeValObj[CCode].isDisable = false;
              attributeValObj[CCode].ISM = "true";
            }
          }

          //clear values based to CDT
          attributeValues[CCode] =
            CDT === "DropdownSingle" ||
            CDT === "DropdownMultiple" ||
            CDT === "Date" ||
            CDT === "SearchableDropdownSingle"
              ? null
              : CDT === "Text" || CDT === "Number"
              ? ""
              : CDT === "Checkbox"
              ? false
              : "";
        });

        attributeValues[targetAttributeCode] = targetValue; //imp
        setFormErrors({});
        setAttributeValidation((prev) => ({ ...prev, ...attributeValObj }));
        setAttributesData((prev) => ({
          ...prev,
          ...attributeValues,
        }));
      }
    } else {
      let attributeValues = { ...attributeSetData }; // for checking values
      let attributeValObj = { ...attributeSetValidation };

      if (
        attributeValObj[targetAttributeCode].DD !== null &&
        attributeValObj[targetAttributeCode].DD.length > 0
      ) {
        let ad = attributeValObj[targetAttributeCode].DD;

        ad.forEach(async (record) => {
          let CCode = record.CCode;
          let PValue = record.PValue;
          let Condition = record.Condition;
          let Flag = record.Flag;
          let PDT = record.PDT;
          let CDT = record.CDT;

          if (PDT === "DropdownSingle") {
            if (
              targetValue &&
              targetValue.label.toString().toLowerCase() ===
                PValue.toLowerCase()
            ) {
              //check condition
              if (Flag === "disable") {
                attributeValObj[CCode].isDisable = true;
                attributeValObj[CCode].ISM = "false";
              }
            } else {
              attributeValObj[CCode].isDisable = false;
              attributeValObj[CCode].ISM =
                targetValue?.label === "Married"
                  ? "true"
                  : CCode === "MARITLSTS"
                  ? "false"
                  : targetValue?.label === "Worker"
                  ? "true"
                  : CCode === "SC"
                  ? "false"
                  : CCode === "VACXDT"
                  ? "false"
                  : CCode === "VACXDT2"
                  ? "false"
                  : CCode === "VACXDT3"
                  ? "false"
                  : "true";
            }
          } else if (PDT.toLowerCase() === "checkbox") {
            if (targetValue.toString().toLowerCase() === PValue.toLowerCase()) {
              //check condition
              if (Flag === "disable") {
                attributeValObj[CCode].isDisable = true;
                attributeValObj[CCode].ISM = "false";
              }
            } else {
              attributeValObj[CCode].isDisable = false;
              attributeValObj[CCode].ISM = "true";
            }
          }

          //clear values based to CDT
          attributeValues[CCode] =
            CDT === "DropdownSingle" ||
            CDT === "DropdownMultiple" ||
            CDT === "Date" ||
            CDT === "SearchableDropdownSingle"
              ? null
              : CDT === "Text" || CDT === "Number"
              ? ""
              : CDT === "Checkbox"
              ? false
              : "";
        });

        attributeValues[targetAttributeCode] = targetValue; //imp
        setFormErrors({});
        setAttributeSetValidation((prev) => ({ ...prev, ...attributeValObj }));
        setAttributeSetData((prev) => ({
          ...prev,
          ...attributeValues,
        }));
      }
    }
  };
  const isValidEmail = (email, emailPattern) => {
    const regex = new RegExp(emailPattern);
    return regex.test(email);
  };

  const validateData = (Attribute) => {
    let errorObj = {};
    let isError = false;
    Attribute.forEach(async (subAttribute) => {
      if (subAttribute.AT === "Attribute") {
        // const validation = JSON.parse(subAttribute.V);
        const validation = attributeSetValidation[subAttribute.AC];

        if (validation.ISM.toLowerCase() === "true") {
          if (subAttribute.DT === "Text") {
            if (attributeSetData[subAttribute.AC].trim() === "") {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else if (
              attributeSetData[subAttribute.AC]?.length > validation.MaxC
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: `Max ${validation.MaxC} characters allowed`,
              };
            } else if (validation.PTN != undefined) {
              if (
                isValidEmail(
                  attributeSetData[subAttribute.AC],
                  validation.PTN
                ) != true
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: `Incorrect format.`,
                };
              }
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }

            //Validate business email welspun.com

            if (
              subAttribute.AC === "EA" &&
              attributeSetData[subAttribute.AC] !== ""
            ) {
              if (
                attributeSetData["ETP"] !== null &&
                attributeSetData["ETP"].label.toLowerCase() === "business"
              ) {
                if (
                  !/^[a-z0-9](\.?[a-z0-9._]){4,}@welspun\.com$/.test(
                    attributeSetData["EA"].toLowerCase()
                  ) &&
                  !/^[a-z0-9](\.?[a-z0-9._]){4,}@welspunusa\.com$/.test(
                    attributeSetData["EA"].toLowerCase()
                  ) &&
                  !/^[a-z0-9](\.?[a-z0-9._]){4,}@christy\.co\.in$/.test(
                    attributeSetData["EA"].toLowerCase()
                  ) &&
                  !/^[a-z0-9](\.?[a-z0-9._]){4,}@welspun\.co\.uk$/.test(
                    attributeSetData["EA"].toLowerCase()
                  )
                ) {
                  errorObj = {
                    ...errorObj,
                    [subAttribute.AC]: `Invalid Email`,
                  };
                }
              } else if (
                attributeSetData["ETP"] !== null &&
                attributeSetData["ETP"].label.toLowerCase() === "personal"
              ) {
                if (
                  !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
                    attributeSetData["EA"].toLowerCase()
                  )
                ) {
                  errorObj = {
                    ...errorObj,
                    [subAttribute.AC]: `Invalid Email`,
                  };
                } else if (
                  /^[a-z0-9](\.?[a-z0-9._]){4,}@welspun\.com$/.test(
                    attributeSetData["EA"].toLowerCase()
                  )
                ) {
                  errorObj = {
                    ...errorObj,
                    [subAttribute.AC]: `Invalid domain @welspun.com for personal email.`,
                  };
                }
              }
            }

            //hard code to validate personalId based on selected IdCardType

            if (
              subAttribute.AC === "PERIDNM" &&
              attributeSetData[subAttribute.AC] !== ""
            ) {
              if (attributeSetData["IDCATY"] !== null) {
                switch (attributeSetData["IDCATY"].label.toLowerCase()) {
                  case "aadhar card":
                    if (
                      !NationalIdCardType.AADHAR.test(
                        attributeSetData[subAttribute.AC]
                      )
                    ) {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "Invalid Aadhar",
                      };
                    } else {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "",
                      };
                    }

                    break;
                  case "pan card":
                    if (
                      !NationalIdCardType.PAN.test(
                        attributeSetData[subAttribute.AC]
                      )
                    ) {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "Invalid PAN",
                      };
                    } else {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "",
                      };
                    }

                    break;
                  case "driving license":
                    if (
                      !NationalIdCardType.DRIVINGLICENCE.test(
                        attributeSetData[subAttribute.AC]
                      )
                    ) {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "Invalid Driving license",
                      };
                    } else {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "",
                      };
                    }

                    break;
                  case "voter id":
                    if (
                      !NationalIdCardType.VOTERID.test(
                        attributeSetData[subAttribute.AC]
                      )
                    ) {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "Invalid voter Id",
                      };
                    } else {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "",
                      };
                    }
                    break;
                  case "esic number":
                    if (
                      !NationalIdCardType.ESIC.test(
                        attributeSetData[subAttribute.AC]
                      )
                    ) {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "Invalid ESIC Number",
                      };
                    } else {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "",
                      };
                    }
                    break;
                  case "passport":
                    if (
                      !NationalIdCardType.PASSPORT.test(
                        attributeSetData[subAttribute.AC]
                      )
                    ) {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "Invalid Passport",
                      };
                    } else {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "",
                      };
                    }
                    break;
                  case "pran":
                    if (
                      !NationalIdCardType.PRAN.test(
                        attributeSetData[subAttribute.AC]
                      )
                    ) {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "Invalid PRAN",
                      };
                    } else {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "",
                      };
                    }
                    break;
                  case "provident fund":
                    if (
                      !NationalIdCardType.PF.test(
                        attributeSetData[subAttribute.AC]
                      )
                    ) {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "Invalid Provident fund",
                      };
                    } else {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "",
                      };
                    }
                    break;
                  case "uan":
                    if (
                      !NationalIdCardType.UAN.test(
                        attributeSetData[subAttribute.AC]
                      )
                    ) {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "Invalid UAN",
                      };
                    } else {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "",
                      };
                    }
                    break;
                  case "national insurance number":
                    if (
                      !NationalIdCardType.NATIONALINSURANCENUMBER.test(
                        attributeSetData[subAttribute.AC]
                      )
                    ) {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "Invalid National Insurance Number",
                      };
                    } else {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "",
                      };
                    }

                    break;
                  default:
                    break;
                }
              }
            }
          } else if (
            subAttribute.DT === "DropdownSingle" ||
            subAttribute.DT === "SearchableDropdownSingle" ||
            subAttribute.DT === "DropdownMultiple"
          ) {
            if (attributeSetData[subAttribute.AC] === null) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          } else if (subAttribute.DT === "Date") {
            if (attributeSetData[subAttribute.AC] === null) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else if (subAttribute.AC === "DOBE") {
              const ageYears = moment().diff(
                moment(attributeSetData["DOBE"]),
                "years"
              );
              switch (attributeSetData["RLP"].label.toLowerCase()) {
                case "father":
                case "mother":
                  if (ageYears < 35) {
                    errorObj = {
                      ...errorObj,
                      [subAttribute.AC]:
                        "Father/Mother's Age Should be Minimum 35 Years.",
                    };
                  } else {
                    errorObj = {
                      ...errorObj,
                      [subAttribute.AC]: "",
                    };
                  }

                  break;
                case "spouse":
                  if (ageYears < 18) {
                    errorObj = {
                      ...errorObj,
                      [subAttribute.AC]:
                        "Spouse Age Should be Minimum 18 Years.",
                    };
                  } else {
                    errorObj = {
                      ...errorObj,
                      [subAttribute.AC]: "",
                    };
                  }

                  break;
                default:
                  break;
              }
            } else if (
              moment(
                validation.MaxV === "CurrentDate" &&
                  attributeSetData[subAttribute.AC]
              ).isAfter(new Date())
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Only till current date allowed",
              };
            } else if (
              validation.MaxV === "CurrentMonth" &&
              subAttribute.AN != "Effective Date" &&
              attributeSetData[subAttribute.AC] != ""
            ) {
              let validatingValues = validateCurrentMonth(
                attributeSetData[subAttribute.AC]
              );
              if (
                validatingValues.monthsDiff != 0 ||
                validatingValues.yearsDiff != 0
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Only date from current month allowed",
                };
              }
            } else if (
              moment(
                validation.MinV === "CurrentDate" &&
                  attributeSetData[subAttribute.AC]
              ).isBefore()
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Only after current date allowed",
              };
            } else if (
              validation.MinV !== undefined &&
              validation.MinV !== "CurrentDate" &&
              validation.MinV !== ""
            ) {
              let parentValue = attributeSetData[validation.MinV];
              if (
                parentValue !== null &&
                parentValue !== "" &&
                attributeSetData[subAttribute.AC] !== null &&
                attributeSetData[subAttribute.AC] !== "" &&
                moment(attributeSetData[subAttribute.AC]).isBefore(parentValue)
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Past date not allowed",
                };
              } else if (
                (parentValue === null || parentValue === "") &&
                attributeSetData[subAttribute.AC] !== null &&
                attributeSetData[subAttribute.AC] !== ""
              ) {
                errorObj = {
                  ...errorObj,
                  [validation.MinV]: "Required",
                  [subAttribute.AC]: "",
                };
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          } else if (subAttribute.DT === "Number") {
            //change with regex
            if (attributeSetData[subAttribute.AC].trim() === "") {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else if (
              // isNaN(
              //   attributeSetData[subAttribute.AC]
              // )
              !/^([0-9]+|0)$/.test(attributeSetData[subAttribute.AC])
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Only numbers are allowed",
              };
            } else if (subAttribute.AC === "AC") {
              if (!/^[0-9]{0,5}$/.test(attributeSetData["AC"])) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Please enter a 5-digit area code.",
                };
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }
            } else if (subAttribute.AC === "OFFEX") {
              if (!/^[0-9]{0,6}$/.test(attributeSetData["OFFEX"])) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Please enter a 6-digit extension code.",
                };
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }
            } else if (subAttribute.AC === "PN") {
              switch (attributeSetData["PTP"]?.label.toLowerCase()) {
                case "office extension":
                  if (!/^[0-9]{10}$/.test(attributeSetData[subAttribute.AC])) {
                    errorObj = {
                      ...errorObj,
                      [subAttribute.AC]: "Only numbers are allowed",
                    };
                  }
                  break;
                case "mobile":
                  if (!/^[0-9]{10}$/.test(attributeSetData[subAttribute.AC])) {
                    errorObj = {
                      ...errorObj,
                      [subAttribute.AC]:
                        "Please enter a 10-digit numeric mobile number.",
                    };
                  }
                  break;
                case "office telephone":
                  if (!/^[0-9]{8}$/.test(attributeSetData[subAttribute.AC])) {
                    errorObj = {
                      ...errorObj,
                      [subAttribute.AC]:
                        "Please enter a 8-digit numeric phone number.",
                    };
                  }
                  break;
              }
            } else if (
              Number(attributeSetData[subAttribute.AC]) > validation.MaxV
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: `Max ${validation.MaxV} allowed`,
              };
            } else if (
              Number(attributeSetData[subAttribute.AC]) < validation.MinV
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: `Min ${validation.MinV} required`,
              };
            }
            //If Numeric values has pattern
            else if (validation.PTN != undefined) {
              if (
                isValidEmail(
                  attributeSetData[subAttribute.AC],
                  validation.PTN
                ) != true
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: `Invalid Data`,
                };
              }
            }
            // else if (subAttribute.AC === "PN") {
            //
            //   switch (attributeSetData["PTP"].label.toLowerCase()) {
            //     case "office extension":
            //       if (
            //         attributeSetData["PN"].length > 6 ||
            //         attributeSetData["PN"].length < 4
            //       ) {
            //         errorObj = {
            //           ...errorObj,
            //           [subAttribute.AC]: "Ext no. should be 4-6 Digits",
            //         };
            //       } else {
            //         errorObj = {
            //           ...errorObj,
            //           [subAttribute.AC]: "",
            //         };
            //       }

            //       break;
            //     case "office telephone":
            //       if (attributeSetData["PN"].length > 7) {
            //         errorObj = {
            //           ...errorObj,
            //           [subAttribute.AC]: " Max 7 Digit is Accepted.",
            //         };
            //       } else {
            //         errorObj = {
            //           ...errorObj,
            //           [subAttribute.AC]: "",
            //         };
            //       }

            //       break;
            //     default:
            //       break;
            //   }
            // }
            else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          } else if (subAttribute.DT === "Document") {
            const fileCount = selectedFilesForSet.reduce((total, item) => {
              if (
                item.AC === subAttribute.AC &&
                ((currentGridId && currentGridId == item.Id) || item.Id === 0)
              ) {
                return total + 1;
              } else if (
                item.AC === subAttribute.AC &&
                currentGridId === null &&
                item.Id !== 0
              ) {
                return total;
              } else if (
                item.AC === subAttribute.AC &&
                currentGridId !== null &&
                item.Id !== 0
              ) {
                return total;
              }
              return total;
            }, 0);

            if (selectedFilesForSet.length === 0 || fileCount === 0) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          }
        } else {
          // for num and date

          if (subAttribute.DT === "Number") {
            if (attributeSetData[subAttribute.AC] !== "") {
              //change with regex
              if (
                // isNaN(
                //   attributeSetData[
                //     subAttribute.AC
                //   ]
                // )
                !/^([0-9]+|0)$/.test(attributeSetData[subAttribute.AC])
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Only numbers are allowed",
                };
              } else if (
                Number(attributeSetData[subAttribute.AC]) > validation.MaxV
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: `Max ${validation.MaxV} allowed`,
                };
              } else if (
                Number(attributeSetData[subAttribute.AC]) < validation.MinV
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: `Min ${validation.MinV} required`,
                };
              } else if (subAttribute.AC === "AC") {
                if (!/^\d{7,10}$/.test(attributeSetData[subAttribute.AC])) {
                  errorObj = {
                    ...errorObj,
                    [subAttribute.AC]: `Invalid Number,7-10 Digit is Accepted.`,
                  };
                }
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          } else if (subAttribute.DT === "Date") {
            if (attributeSetData[subAttribute.AC] !== null) {
              if (
                moment(
                  validation.MaxV === "CurrentDate" &&
                    attributeSetData[subAttribute.AC]
                ).isAfter(new Date())
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Only till current date allowed",
                };
              } else if (
                moment(
                  validation.MinV === "CurrentDate" &&
                    attributeSetData[subAttribute.AC]
                ).isBefore()
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Only after current date allowed",
                };
              } else if (
                validation.MinV !== undefined &&
                validation.MinV !== "CurrentDate" &&
                validation.MinV !== ""
              ) {
                let parentValue = attributeSetData[validation.MinV];
                if (
                  parentValue !== null &&
                  parentValue !== "" &&
                  attributeSetData[subAttribute.AC] !== null &&
                  attributeSetData[subAttribute.AC] !== "" &&
                  moment(attributeSetData[subAttribute.AC]).isBefore(
                    parentValue
                  )
                ) {
                  errorObj = {
                    ...errorObj,
                    [subAttribute.AC]: "Past date not allowed",
                  };
                } else if (
                  (parentValue === null || parentValue === "") &&
                  attributeSetData[subAttribute.AC] !== null &&
                  attributeSetData[subAttribute.AC] !== ""
                ) {
                  errorObj = {
                    ...errorObj,
                    [validation.MinV]: "Required",
                    [subAttribute.AC]: "",
                  };
                } else {
                  errorObj = {
                    ...errorObj,
                    [subAttribute.AC]: "",
                  };
                }
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          }
        }
      }
    });

    const isEmpty = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      notify(1, `${section.SDN} - Please check validation errors!`);
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  const getSearchableDropdownAPI = async (
    searchString,
    AC,
    cb,
    valueForDropdown,
    type,
    gridId
  ) => {
    if (
      searchString === "" &&
      cb === null &&
      valueForDropdown !== "" &&
      AC !== ""
    ) {
      const response = await APICall(getSearchableDropdowns, "POST", {
        searchString,
        AC,
        valueForDropdown,
      });

      if (response.data !== null && response.data.length > 0) {
        if (response.data.length === 1) {
          if (gridId !== 0) {
            let ddObj = {
              gridId,
              AC,
              DDResponse: response.data[0],
            };
            setFillDDValuesForGrid((prev) => [...prev, ddObj]);
          } else {
            return response.data[0];
            //fillSearchableDDValuesOnEdit(response.data[0], AC);
          }
        }
      }
    } else if (searchString.length > 1) {
      const response = await APICall(getSearchableDropdowns, "POST", {
        searchString,
        AC,
      });
      if (response.data !== null && response.data.length > 0) {
        cb(response.data);
        setOptionsObj((prev) => ({ ...prev, [AC]: response.data }));
      } else {
        cb([]);
        setOptionsObj((prev) => ({ ...prev, [AC]: [] }));
      }
    }
  };

  useEffect(() => {
    //  ;
    if (
      fillDDValuesForGrid.length > 0 &&
      fillDDValuesForGrid.length === gridData.length
    ) {
      fillDDValuesForGrid.forEach((ddValues) => {
        setGridData((current) =>
          current.map((obj) => {
            if (obj.Id == ddValues.gridId) {
              return { ...obj, [ddValues.AC]: ddValues.DDResponse.label };
            }

            return obj;
          })
        );

        setDisplayGrid((current) =>
          current.map((obj) => {
            if (obj.Id == ddValues.gridId) {
              return { ...obj, [ddValues.AC]: ddValues.DDResponse.label };
            }

            return obj;
          })
        );

        setOgFilledAttributeSet((current) =>
          current.map((obj) => {
            if (obj.Id == ddValues.gridId) {
              return {
                ...obj,
                [ddValues.AC]: {
                  label: ddValues.DDResponse.label,
                  value: ddValues.DDResponse.value,
                },
              };
            }

            return obj;
          })
        );
      });
      setFillDDValuesForGrid([]);
    }
    if (gridData.length > 0) {
      let isbank = false;
      let amount = 0;
      gridData.map((gd) => {
        if (gd.hasOwnProperty("PCE") && gd.IsActive != false) {
          isbank = true;
          if (gd.PCE === "Fixed Pay" || gd.PCE === "Variable Pay") {
            if (gd.AMT) {
              amount += parseInt(gd.AMT);
            }
          } else if (gd.PCE === "Base Pay") {
            amount = gd.AMT;
          } else if (gd.PCE === "Stipend") {
            amount = gd.AMT;
          }
        } else {
          isbank = false;
        }
        if (gd.hasOwnProperty("PVSEMP") && gd.IsActive != false) {
          let filOpt = optionsObj["PVSEMP"]?.filter((op) => {
            return op.label === gd.PVSEMP;
          });
          setOgAttributeSet((prev) => ({
            ...prev,
            ["PVSEMP"]: filOpt[0],
          }));
          setAttributeSetData((prev) => ({
            ...prev,
            PVSEMP: filOpt[0],
          }));
        }
      });
      if (isbank) {
        setAttributesData((prev) => ({
          ...prev,
          ASA: amount.toString(),
        }));
      }
    }
  }, [fillDDValuesForGrid, gridData]);

  //Searchable Dropdown for Attributes
  const searchableDDValues = async (event, AC) => {
    let DD = event.DD;
    if (DD.length > 0) {
      let newAttributeData = { ...attributesData };
      let dropdownOptions = { ...optionsObj };

      // DD.forEach(async(attribute) => {
      //   const Code = attribute.Key;

      //   // check DT

      //   if (
      //     attribute.DT === "DropdownSingle" ||
      //     attribute.DT === "DropdownMultiple"
      //   ) {
      //     const options = dropdownOptions[Code];

      //     if (options !== undefined && options.length > 0) {
      //       const option = options.find(
      //         (attri) => attri.value == attribute.Value
      //       );

      //       newAttributeData = {
      //         ...newAttributeData,
      //         [Code]: option === undefined ? null : option,
      //       };
      //     }
      //   }
      //   else if ( attribute.DT === "SearchableDropdownSingle" ){
      //     const DDResponse = await getSearchableDropdownAPI(
      //       "",
      //       Code,
      //                 null,
      //                 attribute.Value,
      //                 "attribute",
      //                 0
      //     )
      //     newAttributeData = {
      //       ...newAttributeData,
      //       [Code]:
      //         DDResponse === undefined
      //           ? null
      //           : {
      //               label: DDResponse.label,
      //               value: DDResponse.value,
      //             },
      //     };
      //   }
      //    else {
      //     //check all DT
      //     newAttributeData = {
      //       ...newAttributeData,
      //       [Code]: attribute.Value,
      //     };
      //   }
      // });
      for (const attribute of DD) {
        const Code = attribute.Key;

        if (
          attribute.DT === "DropdownSingle" ||
          attribute.DT === "DropdownMultiple"
        ) {
          const options = dropdownOptions[Code];

          if (options !== undefined && options.length > 0) {
            const option = options.find(
              (attri) => attri.value == attribute.Value
            );

            newAttributeData = {
              ...newAttributeData,
              [Code]: option === undefined ? null : option,
            };
          }
          if (Code === "LEN") {
            debugger;
            if (Country === "CODECNTRY7" || COU === "CODECNTRY7") {
              let loprate = await getLOPHourlyRate(
                Number(attribute.Value),
                Number(DD.find((item) => item.Key === "EC").Value),
                Number(DD.find((item) => item.Key === "JL").Value)
              );
              newAttributeData = {
                ...newAttributeData,
                ["LOPHR"]: loprate,
              };
            }

            let CMCode = await getCompanyCode(
              Number(DD.find((item) => item.Key === "SBU").Value),
              Number(attribute.Value)
            );
            newAttributeData = {
              ...newAttributeData,
              ["CCD"]: CMCode,
            };
          }
        } else if (attribute.DT === "SearchableDropdownSingle") {
          const DDResponse = await getSearchableDropdownAPI(
            "",
            Code,
            null,
            attribute.Value,
            "attribute",
            0
          );
          debugger;
          newAttributeData = {
            ...newAttributeData,
            [Code]:
              DDResponse === undefined
                ? null
                : {
                    label: DDResponse.label,
                    value: DDResponse.value,
                  },
          };
        } else {
          newAttributeData = {
            ...newAttributeData,
            [Code]: attribute.Value,
          };
        }
      }

      newAttributeData[AC] = event; //imp

      setAttributesData(newAttributeData);
    }
  };

  // //Auto map
  // const AutoMapDateOfResign = async ( event
  //   ) => {
  //     if(attributesData["SD"]?.value ){
  //       let dAutomapAttribute = [{
  //         Key:"SD",
  //         Value:attributesData["SD"].value
  //       }];
  //       ;
  //           const response = await APICall(getAutoMappings, "POST", {
  //             AC:"DOR",
  //             DDATR:dAutomapAttribute,
  //             TId: attributesData["Id"]?.value
  //          });
  //           if(response.data != null){
  //             setBautoMapped(true);
  //             setAttributesData((prev) => ({
  //               ...prev,
  //                 ["SD"]: response.data,
  //             }));
  //             //setTan(response.data["tan"]);
  //           }
  //       }
  //     };

  //Select date value for only this month
  const validateCurrentMonth = (EnteredDate) => {
    let monthsDiff;
    let yearsDiff;

    const endDate = moment();
    const startDate = moment(EnteredDate);

    monthsDiff = endDate.diff(startDate, "months");

    yearsDiff = endDate.diff(startDate, "years");

    return { monthsDiff, yearsDiff };
  };

  //Get LOP - Hourly Rate on change
  const getLOPHourlyRate = async (LE, EC, JL) => {
    if (
      LE != undefined &&
      LE != null &&
      EC != undefined &&
      EC != null &&
      JL != undefined &&
      JL != null
    ) {
      let response = await APICall(GetLOPHourlyRate, "POST", {
        LEN: LE,
        EC: EC,
        JL: JL,
      });
      if (response.data !== null && response.data != undefined) {
        return await response.data.lophr;
      } else {
        return null;
      }
    }
  };

  /*get company code from legal entity */

  const getCompanyCode = async (SBU, LEN) => {
    //let code;
    if (SBU != undefined && SBU != null && LEN != undefined && LEN != null) {
      let response = await APICall(GetCompanyCode, "POST", {
        SBU: SBU,
        LEN: LEN,
      });
      if (response.data !== null && response.data != undefined) {
        //code = value.code;
        // await setAttributesData((prev) => ({
        //   ...prev,
        //   //LEN: LEN,
        //   ["CCD"]: response.data.ccd,
        // }));
        return await response.data.ccd.toString();
      } else {
        return null;
      }
    }
  };
  const GetNPEndDate = (NPSD, NPIM) => {
    if (NPSD != undefined && NPSD != null) {
      if (NPIM != undefined && NPIM != null) {
        const startDateNP = moment(NPSD);
        let NPDays = Number(NPIM) * 30.44;
        let EndDateNP = startDateNP.add(NPDays, "d");
        return EndDateNP;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };
  const GetShortDays = (NPEDE, LWD) => {
    if (
      LWD != undefined &&
      LWD != null &&
      NPEDE != undefined &&
      NPEDE != null
    ) {
      let daysDiff;
      if (moment(LWD) < moment(NPEDE)) {
        daysDiff = moment(NPEDE).diff(moment(LWD), "days");
      } else {
        daysDiff = 0;
      }
      return daysDiff;
    } else {
      return "";
    }
  };
  const dateyearmonthdiff = (date) => {
    if (date != undefined && date != null) {
      const endDate = moment();
      const startDate = moment(date);
      const yearsDiff = endDate.diff(startDate, "years");
      startDate.add(yearsDiff, "years"); // Adjust the start date by the years difference
      const monthsDiff = endDate.diff(startDate, "months");
      startDate.add(monthsDiff, "months"); // Adjust the start date by the months difference
      const daysDiff = endDate.diff(startDate, "days");
      return `${yearsDiff} years, ${monthsDiff} months, ${daysDiff} days`;
    } else {
      return "";
    }
  };
  //Searchable Dropdown for Attributes Set
  const searchableDDValuesSet = (event, AC) => {
    let DD = event.DD;
    if (DD.length > 0) {
      let newAttributeSetData = { ...attributeSetData };
      let dropdownOptions = { ...optionsObj };

      DD.forEach((attribute) => {
        const Code = attribute.Key;

        // check DT

        if (attribute.DT === "DropdownSingle") {
          const options = dropdownOptions[Code];

          if (options !== undefined && options.length > 0) {
            const option = options.find(
              (attri) => attri.value == attribute.Value
            );

            newAttributeSetData = {
              ...newAttributeSetData,
              [Code]: option === undefined ? null : option,
            };
          }
        } else {
          //check all DT
          newAttributeSetData = {
            ...newAttributeSetData,
            [Code]: attribute.Value,
          };
        }
      });
      newAttributeSetData[AC] = event; //imp

      setAttributeSetData(newAttributeSetData);
    }
  };

  const fillSearchableDDValuesOnEdit = (DDResponse, AC) => {
    setAttributesData((prev) => ({
      ...prev,
      [AC]: { label: DDResponse.label, value: DDResponse.value },
    }));
  };

  const [addressType, setAddressType] = useState<any>({});

  useEffect(() => {
    // ;
    if (Object.keys(addressType).length === 2) {
      if (addressType.address === "present" && addressType.type === "lease") {
        let attributeSetValObj = { ...attributeSetValidation };

        attributeSetValObj.LSD.ISM = "true";
        attributeSetValObj.LED.ISM = "true";

        setAttributeSetValidation((prev) => ({
          ...prev,
          ...attributeSetValObj,
        }));
      } else {
        let attributeSetValObj = { ...attributeSetValidation };

        attributeSetValObj.LSD.ISM = "false";
        attributeSetValObj.LED.ISM = "false";

        setAttributeSetValidation((prev) => ({
          ...prev,
          ...attributeSetValObj,
        }));
      }
    }
  }, [addressType]);

  const validateBankAccAdhar = async () => {
    return new Promise(async (resolve, reject) => {
      const response = await APICall(getAccountValidation, "POST", {
        BNKACCNM: attributeSetData["BNKACCNM"],
      });

      if (
        response.data !== null &&
        response.data != undefined &&
        response.data.length > 0
      ) {
        let filterAccount = response.data.filter((res) => {
          if (
            res.empActive === false &&
            res.peridnm === (globalEC[0] != undefined && globalEC[0].aadhar)
          ) {
            return res;
          }
        });
        if (filterAccount && filterAccount.length > 0) {
          setModalHeader(
            "The Same Account Number is already existing for Inactive Employee ID ( " +
              filterAccount[0].employeeCode +
              "-" +
              filterAccount[0].fn +
              " ). Do you want to Continue?"
          );
          setShowConfirmationModal(true);
          setConfirmationPromise({ resolve, reject });
        } else {
          let filternumber = response.data.filter((res) => {
            if (
              res.bnkaccnm === attributeSetData["BNKACCNM"] &&
              res.eid != TId
            ) {
              return res;
            }
          });
          if (filternumber && filternumber.length > 0) {
            notify(
              1,
              "The Same Account Number already exist with" +
                (filternumber[0].empActive === true
                  ? " Active "
                  : " Inactive ") +
                " Employee ID ( " +
                filternumber[0].employeeCode +
                "-" +
                filternumber[0].fn +
                " )."
            );
            validAccount.current = false;
            resolve(true);
          } else {
            validAccount.current = true;
            resolve(true);
          }
        }
      } else {
        validAccount.current = true;
        resolve(true);
      }
    });
  };
  const validateNationalID = async () => {
    return new Promise(async (resolve, reject) => {
      const response = await APICall(GetNationalIDDuplicateValidation, "POST", {
        PERIDNM: attributeSetData["PERIDNM"],
        EID: TId,
      });
      debugger;
      if (
        response.data !== null &&
        response.data != undefined &&
        response.data.length > 0
      ) {
        let CheckIfEmpInActive = response.data.filter((res) => {
          if (res.empActive === false && res.EID != TId) {
            return res;
          }
        });
        if (
          CheckIfEmpInActive &&
          CheckIfEmpInActive.length === response.data.length
        ) {
          //when employee is inactive
          setModalHeader(
            "The Same Account Number already exist for Inactive Employee ID ( " +
              (CheckIfEmpInActive[0].employeeCode === null
                ? ""
                : `${CheckIfEmpInActive[0].employeeCode}`) +
              "-" +
              CheckIfEmpInActive[0].fn +
              " ). Do you want to Continue?"
          );
          setShowConfirmationModal(true);
          setConfirmationPromise({ resolve, reject });
        } else {
          // when employee is active
          //check if employee is active but record is inactive
          let checkRecordInActive = response.data.filter((res) => {
            if (res.isActive === false && res.EID != TId) {
              return res;
            }
          });
          if (
            checkRecordInActive &&
            checkRecordInActive.length === response.data.length
          ) {
            //Employee Is active but record is not in use
            validAccount.current = true;
            resolve(true);
          } else {
            //Employee Is active and  record is in use
            notify(
              1,
              "The Same Account Number already exist with Active" +
                " Employee ID ( " +
                (response.data[0].employeeCode === null
                  ? ""
                  : `${response.data[0].employeeCode}`) +
                "-" +
                response.data[0].fn +
                " )."
            );
            validAccount.current = false;
            resolve(true);
          }
        }
      } else {
        // New value is added
        validAccount.current = true;
        resolve(true);
      }
    });
  };
  const closeConfirmationModal = async (confirmation) => {
    if (confirmationPromise) {
      if (confirmation) {
        validAccount.current = true;
      } else {
        validAccount.current = false;
      }

      setShowConfirmationModal(false);
    }
  };

  const getPPonIncm = async (AC, GenId) => {
    const response = await APICall(getParenPBasedonIncm, "POST", {
      AC,
      GenId,
    });
    if (response.data !== null) {
      return response.data;
    }
  };
  useEffect(() => {
    if (confirmationPromise) {
      if (validAccount.current) {
        confirmationPromise.resolve(true);
      } else {
        confirmationPromise.reject(false); // Reject the promise
      }
    }
    setConfirmationPromise(null);
  }, [validAccount.current]);

  const getDocumentForAC = async (AC) => {
    // let documents = JSON.parse("[]");
    // documents = documents.forEach((document) => {
    //   document.AC = AC;
    //   document.IsValidFile = true;
    // });
    // setSelectedFilesForSet(documents);
  };

  useEffect(() => {
    if (postAuditLog) {
      let oldAudit = [...oldAuditLog];
      let newAudit = [...newAuditLog];

      if (attributeSetCode && ogFilledAttributeSet.length > 0) {
        const newAuditLog = [];

        for (const attribute of ogFilledAttributeSet) {
          const shadowObj = { ...attribute };
          const auditObj = transformDynamicKey(shadowObj);

          const obj = {
            IsAttributeSet: true,
            Code: attributeSetCode,
            RecordId: attribute.Id,
            sectionId: section.SId.toString(),
            Attribute: [
              {
                ...auditObj,
              },
            ],
          };

          newAuditLog.push(obj);
        }

        newAudit = [...newAudit, ...newAuditLog];

        const shadowOgAttrSetObj = { ...ogAttributeSet };
        const auditOgAttrSetObj = transformDynamicKey(shadowOgAttrSetObj);

        newAudit.forEach((newObj) => {
          if (newObj.hasOwnProperty("RecordId")) {
            const matchingObjIndex = oldAudit.findIndex(
              (obj1) => obj1.RecordId == newObj.RecordId
            );

            if (matchingObjIndex !== -1) {
            } else {
              // If not present, add the object to oldAudit arr
              let oldObj = JSON.parse(JSON.stringify(newObj));
              let mergedObj = oldObj.Attribute[0];
              oldObj.Attribute[0] = {
                ...mergedObj,
                // ...ogAttributeSet,
                ...auditOgAttrSetObj,
                Id: 0,
                IsActive: false,
              };

              oldAudit.push({
                ...oldObj,
              });
            }
          }
        });
      }

      (async () => {
        const auditRes = await APICall(InsertAuditLog, "POST", {
          UserId: proxyUserDetails?.EmployeeId
            ? proxyUserDetails?.EmployeeId
            : userDetails.UserId,
          UserEmail: userDetails.EmailId,
          UserName: `${userDetails.FirstName} ${userDetails.LastName}`,
          OldValue: JSON.stringify(oldAudit),
          NewValue: JSON.stringify(newAudit),
          EId: TId,
        });
        setOldAuditLog(newAudit);
        setPostAuditLog(false);
      })();
    }
  }, [postAuditLog]);

  //   useEffect(() => {
  //     if (postEventData) {
  //       (async () => {
  //         const auditRes = await APICall(UpdateEventIntoNewHire, "POST", {
  //           NewHireEventId: newHireEvent.value,
  //           NewHireEventReasonId: newHireEventReason.value,
  //           NewHireEffectiveDate:
  //             moment(newHireEffectiveDate).format("DD-MMM-YYYY"),
  //           TId: TId,
  //         });

  //         setPostEventData(false);
  //       })();
  //     }
  //   }, [postEventData]);

  const handleEffectiveDatePrefill = () => {
    section?.Attribute?.forEach((eachAttribute) => {
      eachAttribute.Attribute.forEach((val) => {
        // console.log("valAttr", val);
        if (
          val.AT === "Attribute" &&
          val.DT === "Date" &&
          val?.AN === "Effective Date"
        ) {
          // console.log("valFinal", val);
          setAttributeSetData((prev) => ({
            ...prev,
            [val.AC]: newHireEffectiveDate
              ? moment(newHireEffectiveDate).format("DD-MMM-YYYY")
              : null,
          }));
        }
      });
    });
  };

  useEffect(() => {
    handleEffectiveDatePrefill();
  }, [newHireEffectiveDate]);

  useEffect(() => {
    if (reloadEmployment) {
      SetSectionValuesArray([]); //imp
      (async () => {
        await showLoader();
        let bodyObj = {
          mn: "EmployeeCentral",
          in: "EC_Form",
          tn: activetab,
          SN: "Basic",
          TID: TId,
          ISH: "true",
          UserId: proxyUserDetails?.EmployeeId
            ? proxyUserDetails?.EmployeeId
            : userDetails?.Id,
          RoleId: currentRoleId?.value,
          COU: COU,
        };

        const response = await APICall(
          getAttributeValueDetailsV2,
          "POST",
          bodyObj
        );

        if (
          (await response.data.sections) &&
          (await response?.data?.sections?.length) > 0
        ) {
          // await setActivetab("Employment");
          // await setFlagForNoticePeriods(
          //   !flagForNoticePeriods
          // );
          await SetSectionValuesArray(() => [...response.data.sections]);
          //SetSectionValuesArray([]);
          await hideLoader();
        } else {
          SetSectionValuesArray([]);
          hideLoader();
        }
        setReloadEmployment(false);
      })();
    }
  }, [reloadEmployment]);

  return (
    <div className="col-lg-12">
      {section?.Attribute?.length > 0 ? (
        <Accordion
          elevation={0}
          className="mb-3"
          expanded={accordion}
          onChange={() => setAccordion((prev) => !prev)}
        >
          <AccordionSummary
            id="profile-accordion"
            style={{ background: "#3C5464" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}
          >
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <p style={{ fontSize: "16px" }}>{section.SDN}</p>
              </div>
              <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12">
                {/* <Tooltip title="clear">
                  <button
                    className="btn ml-lg-0 ml-md-0 ml-1"
                    disabled={showAttr}
                    onClick={(e) => {
                      e.stopPropagation();
                      //clear data

                      section?.Attribute?.forEach(async (eachAttribute) => {
                        if (eachAttribute.AT === "Attribute") {
                          //change value according to dt

                          setAttributesData((prev) => ({
                            ...prev,
                            [eachAttribute.AC]:
                              eachAttribute.DT === "DropdownSingle" ||
                              eachAttribute.DT === "Date" ||
                              eachAttribute.DT === "DropdownSingle"
                                ? null
                                : eachAttribute.DT === "Text" ||
                                  eachAttribute.DT === "Number"
                                ? ""
                                : eachAttribute.DT === "Checkbox"
                                ? false
                                : "",
                          }));
                        } else if (eachAttribute.AT === "AttributeSet") {
                          setAttributeSetData(ogAttributeSet);
                        }
                      });

                      //clear error
                      setFormErrors({});
                    }}>
                    <i className="fas fa-times-circle"></i>
                  </button>
                </Tooltip> */}
                {/* {
                  sendbackForEss && currentRoleId?.value == 7 && sectionForEss != section?.SId 
                  ? <></>
                  : currentRoleId?.value == 7 &&
                  sectionsForProgEss.includes(section?.SN) ?
                  <Tooltip title="Edit">
                    <button
                      className="btn"
                      onClick={async(e) => {
                        //Check if a progression for section exists on section.SId, TId and bring RequestNewJson
                        const reponseOfCheck = await APICall (checkForRequestExists, "POST", {
                          Id: userDetails?.Id, 
                          SectionId: section.SId,
                          EmployeeId: TId,
                        });

                        if (reponseOfCheck.data!=null){
                          if (reponseOfCheck.data.isSendback == false){
                            notify(1, 'Please note that there is a change that you have Submitted and it is under Approval.');
                            setShowProgressionModal(false);
                            setShowSendbackModal(false);
                          } else {
                            setShowProgressionModal(false);
                            setTaskIdForSendback(reponseOfCheck.data.taskId);
                            setShowSendbackModal(true);
                          }
                        } else {
                          setCurrentSectionIdForProgression(section.SId);
                          setShowProgressionModal(true);
                          setShowSendbackModal(false)
                        }
                      }}>
                      <i className="fas fa-edit"></i>
                    </button>
                  </Tooltip>
                  : <></>
                }
                <Tooltip title="History">
                  <button
                    className="btn"
                    disabled={TId == 0 ? true : false}
                    onClick={(e) => {
                      e.stopPropagation();
                      viewSectionHistory({
                        TId,
                        sectionDetails: section,
                      });
                    }}>
                    <i className="fas fa-history"></i>
                  </button>
                </Tooltip> */}
                <Tooltip title="Submit">
                  <button
                    className="btn"
                    disabled={currentGridId !== null ? true : false || showAttr}
                    onClick={async (e) => {
                      e.stopPropagation();

                      if (triggerEventError) {
                        console.error("Event data problem");
                        setShowEventError(true);
                        return;
                      }

                      if (TId === 0 && section.SN !== "Basic") {
                        notify(1, "Save Basic Data First");
                      } else {
                        let errorObj = {};
                        section?.Attribute?.map((eachAttribute) => {
                          if (eachAttribute.AT === "Attribute") {
                            const validation =
                              attributeValidation[eachAttribute.AC];

                            if (validation.ISM.toLowerCase() === "true") {
                              if (eachAttribute.DT === "Text") {
                                if (
                                  attributesData[eachAttribute.AC].trim() === ""
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "Required",
                                  };
                                } else if (validation.PTN != undefined) {
                                  if (
                                    isValidEmail(
                                      attributesData[eachAttribute.AC],
                                      validation.PTN
                                    ) != true
                                  )
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: `Incorrect format.`,
                                    };
                                } else if (
                                  attributesData[eachAttribute.AC]?.length >
                                  validation.MaxC
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: `Max ${validation.MaxC} characters allowed`,
                                  };
                                } else {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "",
                                  };
                                }
                              } else if (
                                eachAttribute.DT === "DropdownSingle" ||
                                eachAttribute.DT ===
                                  "SearchableDropdownSingle" ||
                                eachAttribute.DT === "DropdownMultiple"
                              ) {
                                if (attributesData[eachAttribute.AC] === null) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "Required",
                                  };
                                } else {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "",
                                  };
                                }
                              } else if (eachAttribute.DT === "Date") {
                                let FutureMonth = moment().add(1, "M");
                                if (attributesData[eachAttribute.AC] === null) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "Required",
                                  };
                                } else if (
                                  validation.MaxV === "CurrentDate" &&
                                  moment(
                                    attributesData[eachAttribute.AC]
                                  ).isAfter(moment())
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]:
                                      "Only till current date allowed",
                                  };
                                } else if (
                                  validation.MaxV === "CurrentDateNextMonth" &&
                                  moment(
                                    attributesData[eachAttribute.AC]
                                  ).isAfter(FutureMonth)
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]:
                                      "Only till current date of next month allowed",
                                  };
                                } else if (
                                  moment(
                                    validation.MinV === "CurrentDate" &&
                                      attributesData[eachAttribute.AC]
                                  ).isBefore()
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]:
                                      "Only after current date allowed",
                                  };
                                } else if (
                                  validation.MinV !== undefined &&
                                  validation.MinV !== "CurrentDate" &&
                                  validation.MinV !== ""
                                ) {
                                  let parentValue =
                                    attributesData[validation.MinV];
                                  if (
                                    parentValue !== null &&
                                    parentValue !== "" &&
                                    attributesData[eachAttribute.AC] !== null &&
                                    attributesData[eachAttribute.AC] !== "" &&
                                    moment(
                                      attributesData[eachAttribute.AC]
                                    ).isBefore(parentValue)
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]:
                                        "Past date not allowed",
                                    };
                                  } else if (
                                    parentValue !== null &&
                                    parentValue !== "" &&
                                    attributesData[eachAttribute.AC] !== null &&
                                    attributesData[eachAttribute.AC] !== ""
                                  ) {
                                    if (validation.MinV === "DOB") {
                                      const diffInYear = moment(
                                        attributesData[eachAttribute.AC]
                                      ).diff(moment(parentValue), "years");
                                      if (diffInYear < 16) {
                                        errorObj = {
                                          ...errorObj,
                                          [eachAttribute.AC]:
                                            "Past date not allowed",
                                        };
                                      }
                                    }
                                  } else if (
                                    (parentValue === null ||
                                      parentValue === "") &&
                                    attributesData[eachAttribute.AC] !== null &&
                                    attributesData[eachAttribute.AC] !== ""
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [validation.MinV]: "Required",
                                      [eachAttribute.AC]: "",
                                    };
                                  } else {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "",
                                    };
                                  }
                                } else if (
                                  validation.MaxV !== undefined &&
                                  validation.MaxV !== "CurrentDate" &&
                                  validation.MaxV !== "CurrentDateNextMonth" &&
                                  validation.MaxV !== ""
                                ) {
                                  let parentValue =
                                    attributesData[validation.MinV];
                                  if (
                                    parentValue !== null &&
                                    parentValue !== "" &&
                                    attributesData[eachAttribute.AC] !== null &&
                                    attributesData[eachAttribute.AC] !== "" &&
                                    moment(
                                      attributesData[eachAttribute.AC]
                                    ).isAfter(parentValue)
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]:
                                        "Future Date not Allowed.",
                                    };
                                  } else if (
                                    (parentValue === null ||
                                      parentValue === "") &&
                                    attributesData[eachAttribute.AC] !== null &&
                                    attributesData[eachAttribute.AC] !== ""
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [validation.MinV]: "Required",
                                      [eachAttribute.AC]: "",
                                    };
                                  } else {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "",
                                    };
                                  }
                                } else {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "",
                                  };
                                }
                              } else if (eachAttribute.DT === "Number") {
                                //change with regex

                                // if (
                                //   attributesData[eachAttribute.AC].trim() === ""
                                // ) {
                                //   errorObj = {
                                //     ...errorObj,
                                //     [eachAttribute.AC]: "Required",
                                //   };
                                // } else
                                if (
                                  // isNaN(attributesData[eachAttribute.AC])
                                  !/^([0-9]+|0)$/.test(
                                    attributesData[eachAttribute.AC]
                                  )
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]:
                                      "Only numbers are allowed",
                                  };
                                } else if (
                                  Number(attributesData[eachAttribute.AC]) >
                                  Number(validation.MaxV)
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: `Max ${validation.MaxV} allowed`,
                                  };
                                } else if (
                                  Number(attributesData[eachAttribute.AC]) <
                                  Number(validation.MinV)
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: `Min ${validation.MinV} required`,
                                  };
                                } else {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "",
                                  };
                                }
                              } else if (eachAttribute.DT === "Document") {
                                const fileCount = selectedFilesForFlat.reduce(
                                  (total, item) => {
                                    if (item.AC === eachAttribute.AC) {
                                      return total + 1;
                                    }
                                    return total;
                                  },
                                  0
                                );

                                if (
                                  selectedFilesForFlat.length === 0 ||
                                  fileCount === 0
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "Required",
                                  };
                                } else {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "",
                                  };
                                }
                              }
                            } else {
                              // for num and date
                              if (eachAttribute.DT === "Text") {
                                if (validation.PTN != undefined) {
                                  if (
                                    isValidEmail(
                                      attributesData[eachAttribute.AC],
                                      validation.PTN
                                    ) != true
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: `Incorrect format.`,
                                    };
                                  } else {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: ``,
                                    };
                                  }
                                }
                              }
                              if (eachAttribute.DT === "Number") {
                                if (attributesData[eachAttribute.AC] !== "") {
                                  //change with regex
                                  if (
                                    // isNaN(attributesData[eachAttribute.AC])
                                    !/^([0-9]+|0)$/.test(
                                      attributesData[eachAttribute.AC]
                                    )
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]:
                                        "Only numbers are allowed",
                                    };
                                  } else if (
                                    Number(attributesData[eachAttribute.AC]) >
                                    validation.MaxV
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: `Max ${validation.MaxV} allowed`,
                                    };
                                  } else if (
                                    Number(attributesData[eachAttribute.AC]) <
                                    validation.MinV
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: `Min ${validation.MinV} required`,
                                    };
                                  } else {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "",
                                    };
                                  }
                                } else {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "",
                                  };
                                }
                              } else if (eachAttribute.DT === "Date") {
                                if (attributesData[eachAttribute.AC] !== null) {
                                  if (
                                    moment(
                                      validation.MaxV === "CurrentDate" &&
                                        attributesData[eachAttribute.AC]
                                    ).isAfter(new Date())
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]:
                                        "Only till current date allowed",
                                    };
                                  } else if (
                                    moment(
                                      validation.MinV === "CurrentDate" &&
                                        attributesData[eachAttribute.AC]
                                    ).isBefore()
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]:
                                        "Only after current date allowed",
                                    };
                                  } else if (
                                    validation.MinV !== undefined &&
                                    validation.MinV !== "CurrentDate" &&
                                    validation.MinV !== ""
                                  ) {
                                    let parentValue =
                                      attributesData[validation.MinV];
                                    if (
                                      parentValue !== null &&
                                      parentValue !== "" &&
                                      attributesData[eachAttribute.AC] !==
                                        null &&
                                      attributesData[eachAttribute.AC] !== "" &&
                                      moment(
                                        attributesData[eachAttribute.AC]
                                      ).isBefore(parentValue)
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]:
                                          "Past date not allowed",
                                      };
                                    } else if (
                                      (parentValue === null ||
                                        parentValue === "") &&
                                      attributesData[eachAttribute.AC] !==
                                        null &&
                                      attributesData[eachAttribute.AC] !== ""
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [validation.MinV]: "Required",
                                        [eachAttribute.AC]: "",
                                      };
                                    } else {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: "",
                                      };
                                    }
                                  } else {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "",
                                    };
                                  }
                                } else {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "",
                                  };
                                }
                              }
                            }
                          } else {
                            //submit attributeSet
                          }
                        });

                        const isEmpty = Object.values(errorObj).every(
                          (s) => s === ""
                        );

                        if (!isEmpty) {
                          setFormErrors((err) => ({
                            ...err,
                            ...errorObj,
                          }));
                        } else {
                          setFormErrors({});
                        }

                        if (isEmpty && attributeSetCode === null) {
                          await showLoader();
                          // post data attribute
                          const postAttributes = [];

                          Object.entries(attributesData).forEach(
                            async (attributeData) => {
                              const [Code, Value]: any[] = attributeData;

                              let attribute = {
                                Type: "Attribute",
                                Code,
                                Value:
                                  (typeof Value === "object" &&
                                    Array.isArray(Value)) === true
                                    ? Value.map((item) => item.value).join(
                                        ","
                                      ) || null
                                    : typeof Value === "object"
                                    ? Value?.value.toString() || null
                                    : typeof Value === "string"
                                    ? Value.trim()
                                    : typeof Value === "boolean"
                                    ? Value
                                    : null,
                              };
                              postAttributes.push(attribute);
                            }
                          );

                          let postObj = {
                            MN: "EmployeeCentral",
                            IN: "EC_Form",
                            TN: formData.tn,
                            SN: section.SN,
                            TId: TId || 0,
                            UserId: proxyUserDetails?.EmployeeId
                              ? proxyUserDetails?.EmployeeId
                              : userDetails.UserId,
                            UserEmailId: userDetails.EmailId,
                            UserName: userDetails.FirstName,
                            RoleId: currentRoleId?.value,
                            Attributes: postAttributes,
                            COU: COU,
                            ProxyUserId:
                              proxyUserDetails === null
                                ? null
                                : proxyUserDetails?.value,
                          };
                          let postRescopy;
                          if (section.SN !== "Document") {
                            const postRes = await APICall(
                              postAttributeValueDetailsV2,
                              "POST",
                              postObj
                            );
                            postRescopy = postRes;
                            if (
                              postRes.data !== null &&
                              section.SN === "Basic" &&
                              postRes.data.id !== undefined
                            ) {
                              setTId(postRes.data.id);
                            }

                            //audit
                            const dataForAuditLog = [];
                            if (Object.keys(attributesData).length > 0) {
                              Object.entries(attributesData).forEach((data) => {
                                const [key, value]: any[] = data;

                                const auditLogObj = {
                                  sectionId: section.SId.toString(),
                                  IsAttributeSet: false,
                                  Code: key,
                                  Attribute: [
                                    {
                                      [key]: value?.label || value,
                                    },
                                  ],
                                };
                                dataForAuditLog.push(auditLogObj);
                              });
                            }

                            setNewAuditLog((prev) => [...dataForAuditLog]);

                            notify(postRes.status, postRes.message);

                            //post audit log & newHire event
                            if (postRescopy.status === 0) {
                              setPostAuditLog(true);
                              setPostEventData(true);
                              if (changeWflTaskSts) {
                                const taskUpd = await APICall(
                                  updateCandidateTasks,
                                  "POST",
                                  {
                                    CandidateWorkflowTaskId:
                                      candidateWorkflowTaskId,
                                    Decision: "ToInProgress",
                                  }
                                );
                                if (taskUpd.status == 0) {
                                  console.log(
                                    taskUpd.status,
                                    "Task Updated to In Progress!"
                                  );
                                  setChangeWflTaskSts(false);
                                } else {
                                  console.log(
                                    taskUpd.status,
                                    "Task NOT Updated to In Progress!"
                                  );
                                  setChangeWflTaskSts(true);
                                }
                              }
                            }
                          }

                          if (
                            selectedFilesForFlat.length > 0 &&
                            sectionHasDocuments
                          ) {
                            let newFiles = selectedFilesForFlat.filter((sf) => {
                              return sf.DocId === 0;
                            });
                            let temp = [];
                            newFiles.map((item) => {
                              let obj = item;
                              if (item.AC === "CONFDOC") {
                                let ACval = optionsObj["DOCTYPE"].find((fx) => {
                                  return fx.label === item.Type;
                                }).code;
                                obj = { ...item, AC: ACval };
                              }
                              temp.push(obj);
                            });

                            newFiles = temp;
                            try {
                              const response = await APICall(
                                documentUpload,
                                "POST",
                                {
                                  ModuleName: "EmployeeCentral",
                                  files: newFiles,
                                  SectionName: "Basic",
                                  TabName: "Personal",
                                  TId: postRescopy?.data?.id
                                    ? postRescopy.data.id
                                    : TId,
                                  ModuleId: 1,
                                  UserId: proxyUserDetails?.EmployeeId
                                    ? proxyUserDetails?.EmployeeId
                                    : userDetails.UserId,
                                  ProxyUserId:
                                    proxyUserDetails === null
                                      ? null
                                      : proxyUserDetails?.value,
                                }
                              );

                              if (
                                response.data === null ||
                                response.data === undefined
                              ) {
                                notify(1, "Failed to upload documents.");
                              } else {
                                let FilterFilesCONFDOCDel =
                                  selectedFilesForFlat.filter(
                                    (item) => item.AC !== "CONFDOC"
                                  );
                                setSelectedFilesForFlat([
                                  ...FilterFilesCONFDOCDel,
                                  response.data.files,
                                ]);

                                setAttributesData((attributes) => ({
                                  ...attributes,
                                  ["CNTXT"]: "",
                                  ["DOCTYPE"]: null,
                                }));

                                setGridSortDirection("desc");
                              }
                            } catch (error) {
                              throw new Error(
                                "Error uploading documents: " + error.message
                              );
                            }
                          }
                          if (section.SN === "Document") {
                            notify(0, "Data Saved Successfully.");
                          }

                          await hideLoader();
                          //setShowAttrSet(false);
                          if (section.SN === "Basic") {
                            let filteredObj = postAttributes.filter((pa) => {
                              if (pa.Code === "MARITLST") {
                                return pa;
                              }
                            });
                            if (filteredObj[0].label === "Married") {
                              notify(
                                2,
                                "Please fill out spouse details in Family Member Details Section."
                              );
                            }
                          }
                          // if ( BId !=0 && (section.SN === "PreviousEmployment_NewHire" ||section.SN === "PreviousEmployment" ) ){
                          //   setReloadEmployment(true);
                          //   setReloadEmployementCount(reloadEmployementCount + 1);
                          //   // This reload is done due to Submit on Nominee to fill Form11 epfo details .
                          // }

                          if (section.SN === "JobOrganisationInformation") {
                            if (ishrmanagernotify) {
                              notify(
                                2,
                                "Please mantain HR manager in Work association portlet."
                              );
                            }
                            setReloadEmployment(true);
                          }
                        } else if (!isEmpty && attributeSetCode === null) {
                          //notify on flat attribute
                          notify(
                            1,
                            `${section.SDN} - Please check validation errors!`
                          );
                        } else if (
                          isEmpty &&
                          attributeSetCode !== null &&
                          Object.keys(attributesData).length === 0
                        ) {
                          await showLoader();
                          // post data attributeSet
                          // for (let key in attributeSetValidation) {
                          //   if (attributeSetValidation[key].ISM == "true"
                          //       && attributeSetValidation[key].FX == undefined) {
                          //     // Find elements from array 'a' where 'key' exists
                          //     let filtered = ogFilledAttributeSet.filter(item =>
                          //       (item.hasOwnProperty(key) && (item[key] == null || item[key] =="")));
                          //     //result = result.concat(filtered);
                          //     if (filtered.length > 0  ){
                          //       notify(
                          //         1,
                          //         "Please fill all the mandatory fields."
                          //       );
                          //       await hideLoader();
                          //       return;
                          //     }
                          //   }
                          // }

                          //Generic Validation for Grid Mandatory
                          let ogFilledAttributeSetActiveOnly =
                            ogFilledAttributeSet.filter(
                              (i) => i.IsActive == true
                            );

                          for (let key in attributeSetValidation) {
                            if (
                              attributeSetValidation[key].FX != undefined &&
                              section.SN == "NationalIDInformation"
                            ) {
                              let adharinog =
                                ogFilledAttributeSetActiveOnly.filter(
                                  (itm) => itm.IDCATY.code == "IDCardType1"
                                ); //Find Aadhar card
                              let AdharFile = selectedFilesForSet.filter((fi) =>
                                ogFilledAttributeSetActiveOnly.some(
                                  (item) =>
                                    fi.Id == item.Id &&
                                    fi.AC == key &&
                                    item.IDCATY.code == "IDCardType1"
                                )
                              );
                              if (
                                adharinog.length > 0 &&
                                AdharFile.length == 0
                              ) {
                                notify(
                                  1,
                                  `Please fill out all the mandatory fields for ${section.SDN}.` //For other Fields
                                );
                                await hideLoader();
                                return;
                              }
                              let paninog =
                                ogFilledAttributeSetActiveOnly.filter(
                                  (itm) => itm.IDCATY.code == "CODEIDCTY1"
                                ); //Find PAN card
                              let PanFile = selectedFilesForSet.filter((fi) =>
                                ogFilledAttributeSetActiveOnly.some(
                                  (item) =>
                                    fi.Id == item.Id &&
                                    fi.AC == key &&
                                    item.IDCATY.code == "CODEIDCTY1"
                                )
                              );

                              if (paninog.length > 0 && PanFile.length == 0) {
                                notify(
                                  1,
                                  `Please fill out all the mandatory fields for ${section.SDN}.` //For other Fields
                                );
                                await hideLoader();
                                return;
                              }
                            } else {
                              if (
                                (key == "NOM_Witness1Name" ||
                                  key == "NOM_Witness1Address" ||
                                  key == "NOM_Witness2Name" ||
                                  key == "NOM_Witness2Address") &&
                                section.SN == "NomineeDetails"
                              ) {
                                let fnfmandatory =
                                  ogFilledAttributeSetActiveOnly.filter(
                                    (itm) =>
                                      itm.TYOFNOM.label === "Full & Final" &&
                                      itm.hasOwnProperty(key) &&
                                      (itm[key] == null || itm[key] == "")
                                  );
                                if (fnfmandatory.length > 0) {
                                  notify(
                                    1,
                                    `Please fill out all the mandatory fields for ${section.SDN}.` //For other Fields
                                  );
                                  await hideLoader();
                                  return;
                                }
                              } else if (
                                attributeSetValidation[key].ISM == "true"
                              ) {
                                if (
                                  attributeSetValidation[key].FX == undefined
                                ) {
                                  let filtered =
                                    ogFilledAttributeSetActiveOnly.filter(
                                      (item) =>
                                        item.hasOwnProperty(key) &&
                                        (item[key] == null || item[key] == "")
                                    );
                                  //result = result.concat(filtered);
                                  if (filtered.length > 0) {
                                    notify(
                                      1,
                                      `Please fill out all the mandatory fields for ${section.SDN}.` //For other Fields
                                    );
                                    await hideLoader();
                                    return;
                                  }
                                } else {
                                  // let DovFilter = selectedFilesForSet.filter(item =>
                                  //   (item.AC == key && ));
                                  // if(DovFilter.length != ogFilledAttributeSet.length ){
                                  //   notify(
                                  //     1,
                                  //     "Please fill all the mandatory fields." //For Documents
                                  //   );
                                  //   await hideLoader();
                                  //   return;
                                  // }

                                  let RecordIdMatches =
                                    ogFilledAttributeSetActiveOnly.every(
                                      (obja) => {
                                        const idsa = String(obja.Id);
                                        return selectedFilesForSet.find(
                                          (objb) => String(objb.Id) === idsa
                                        );
                                      }
                                    );

                                  if (!RecordIdMatches) {
                                    notify(
                                      1,
                                      `Please fill out all the mandatory fields for ${section.SDN}.`
                                    ); // For Attachments other than National Id
                                    await hideLoader();
                                    return;
                                  }

                                }
                              }
                            }
                          }
                          //Generic Validation for Grid Mandatory

                          //Address Validation -- All 3 address types selected --
                          if (section.SN === "AddressInformation") {
                            let ActiveAddr = ogFilledAttributeSet.filter(
                              (og) => {
                                return og.IsActive === true;
                              }
                            );
                            if (ActiveAddr.length > 0) {
                              if (ActiveAddr.length != 3) {
                                await hideLoader();
                                notify(
                                  1,
                                  `Please save record for all the Address Type.`
                                );
                                return;
                              }
                            }
                          }
                          //Address Validation -- All 3 address types selected --
                          //Contact information Validation for atleast 1 primary key
                          if (section.SN === "ContactInformation") {
                            if (ogFilledAttributeSet.length > 0) {
                              let filteredContacts =
                                ogFilledAttributeSet.filter((og) => {
                                  return (
                                    og.IP2?.code === "CODEISPMRY2" &&
                                    og.IsActive === true
                                  );
                                });
                              if (filteredContacts.length == 0) {
                                notify(
                                  1,
                                  "Please select atleast 1 primary contact."
                                );
                                await hideLoader();
                                return;
                              }
                            } else {
                              notify(
                                1,
                                "Please select atleast 1 primary contact."
                              );
                              await hideLoader();
                              return;
                            }
                          }

                          //Formal Education to have 2 entries
                          // if (section.SN === "FormalEducation") {
                          //   let ActiveData = ogFilledAttributeSet.filter(
                          //     (og) => {
                          //       return og.IsActive === true;
                          //     }
                          //   );
                          //   if (ActiveData.length > 0) {
                          //     let filtereddata = ogFilledAttributeSet.filter(
                          //       (og) => {
                          //         return (
                          //           (og.QULIFCN?.label === "10th" ||
                          //             og.QULIFCN?.label === "SSC") &&
                          //           og.IsActive === true
                          //         );
                          //       }
                          //     );

                          //     if (filtereddata.length == 0) {
                          //       await hideLoader();
                          //       notify(1, "Please Enter your 10th details.");
                          //       return;
                          //     }

                          //     if (
                          //       ogFilledAttributeSet.length <= 1 &&
                          //       filtereddata.length != 0
                          //     ) {
                          //       await hideLoader();
                          //       notify(
                          //         1,
                          //         "Please Enter your Graduation Or Diploma details."
                          //       );
                          //       return;
                          //     }
                          //   }
                          // }
                          // Nominee Details section to have these 4 "Full and Final",Gratuity ,GTLI ,GPA , compulsory
                          if (section.SN === "NomineeDetails") {
                            let ActiveData = ogFilledAttributeSet.filter(
                              (og) => {
                                return og.IsActive === true;
                              }
                            );
                            let HasGPA, HasGTLI, HasGrat, HasFnF;
                            if (ActiveData.length > 0) {
                              HasGPA = ogFilledAttributeSet.filter((og) => {
                                return (
                                  og.TYOFNOM?.label ===
                                    "Group Personal Accident" &&
                                  og.IsActive === true
                                );
                              });

                              HasGTLI = ogFilledAttributeSet.filter((og) => {
                                return (
                                  og.TYOFNOM?.label ===
                                    "Group Term Life Insurance" &&
                                  og.IsActive === true
                                );
                              });

                              HasGrat = ogFilledAttributeSet.filter((og) => {
                                return (
                                  og.TYOFNOM?.label === "Gratuity" &&
                                  og.IsActive === true
                                );
                              });
                              HasFnF = ogFilledAttributeSet.filter((og) => {
                                return (
                                  og.TYOFNOM?.label === "Full & Final" &&
                                  og.IsActive === true
                                );
                              });

                              if (
                                HasGPA.length == 0 ||
                                HasFnF.length == 0 ||
                                HasGTLI.length == 0 ||
                                HasGrat.length == 0
                              ) {
                                let Message =
                                  HasFnF.length == 0 ? "Full & Final" : "";
                                Message +=
                                  HasFnF.length == 0 && HasGrat.length == 0
                                    ? ", "
                                    : HasFnF.length == 0 && HasGPA.length == 0
                                    ? ", "
                                    : HasFnF.length == 0 && HasGTLI.length == 0
                                    ? ", "
                                    : "";
                                Message +=
                                  HasGrat.length == 0 ? "Gratuity" : "";
                                Message +=
                                  HasGPA.length == 0 && HasGrat.length == 0
                                    ? ", "
                                    : HasGTLI.length == 0 && HasGrat.length == 0
                                    ? ", "
                                    : "";
                                Message +=
                                  HasGPA.length == 0
                                    ? "Group Personal Accident"
                                    : "";
                                Message +=
                                  HasGPA.length == 0 && HasGTLI.length == 0
                                    ? ", "
                                    : "";
                                Message +=
                                  HasGTLI.length == 0
                                    ? "Group Term Life Insurance "
                                    : "";
                                Message += ` Nomination types are mandatory. Please fill the following details.`;
                                await hideLoader();
                                notify(1, Message);
                                return;
                              }
                            }
                          }
                          // Nominee Details Validation Ends

                          let details = [...ogFilledAttributeSet];

                          const finalDetails = [];
                          details.forEach(async (attributes) => {
                            let attributeObj = {};
                            Object.keys(attributes).forEach(
                              async (attribute) => {
                                attributeObj = {
                                  ...attributeObj,
                                  [attribute]:
                                    typeof attributes[attribute] === "object"
                                      ? attributes[
                                          attribute
                                        ]?.value.toString() || null
                                      : typeof attributes[attribute] ===
                                          "string" ||
                                        typeof attributes[attribute] ===
                                          "boolean" ||
                                        typeof attributes[attribute] ===
                                          "number"
                                      ? attributes[attribute]
                                      : null,
                                };
                                if (
                                  attribute === "Id" &&
                                  isNaN(Number(attributes[attribute]))
                                ) {
                                  attributeObj = {
                                    ...attributeObj,
                                    [attribute]: 0,
                                  };
                                } else if (
                                  attribute === "Id" &&
                                  !isNaN(Number(attributes[attribute]))
                                ) {
                                  attributeObj = {
                                    ...attributeObj,
                                    [attribute]: attributes[attribute],
                                  };
                                }
                              }
                            );
                            finalDetails.push(attributeObj);
                          });

                          let postObj = {
                            MN: "EmployeeCentral",
                            IN: "EC_Form",
                            TN: formData.tn,
                            SN: section.SN,
                            COU: COU,
                            TId: TId || 0,
                            UserId: proxyUserDetails?.EmployeeId
                              ? proxyUserDetails?.EmployeeId
                              : userDetails.UserId,
                            UserEmailId: userDetails.EmailId,
                            UserName: userDetails.FirstName,
                            RoleId: currentRoleId?.value,
                            ProxyUserId:
                              proxyUserDetails === null
                                ? null
                                : proxyUserDetails?.value,
                            Attributes: [
                              {
                                Code: attributeSetCode,
                                Value: "",
                                Type: "AttributeSet",
                                Details: finalDetails,
                              },
                            ],
                          };

                          const postRes = await APICall(
                            postAttributeValueDetailsV2,
                            "POST",
                            postObj
                          );
                          const fileIndexMapping = {};
                          if (attributeSetCode && postRes.status == 0) {
                            //update Id returned from Set
                            //note: check all set sp to return Id after insert,
                            //then can remove null check

                            ogFilledAttributeSet.forEach((val, index) => {
                              fileIndexMapping[String(val?.Id)] = String(
                                postRes.data[index]
                              );
                            });

                            setOgFilledAttributeSet((prevOgFilled) => {
                              return prevOgFilled.map((og, rowIndex) => ({
                                ...og,
                                Id:
                                  postRes.data === null
                                    ? 0
                                    : postRes.data[rowIndex],
                              }));
                            });

                            let trackIds = [];

                            setGridData((prevGrid) => {
                              return prevGrid.map((og, rowIndex) => {
                                trackIds = [
                                  ...trackIds,
                                  {
                                    prevId: og.Id,
                                    newId:
                                      postRes.data === null
                                        ? 0
                                        : postRes.data[rowIndex],
                                  },
                                ];

                                return {
                                  ...og,
                                  Id:
                                    postRes.data === null
                                      ? 0
                                      : postRes.data[rowIndex],
                                };
                              });
                            });
                            setDisplayGrid((prevDisplay) => {
                              return prevDisplay.map((og, rowIndex) => {
                                const matchedPrevId = trackIds.find(
                                  (track) => track.prevId == og.Id
                                );
                                return {
                                  ...og,
                                  Id: matchedPrevId.newId,
                                };
                              });
                            });
                          }

                          if (
                            selectedFilesForSet.length > 0 &&
                            sectionHasDocuments
                          ) {
                            try {
                              if (postRes.data?.length > 0) {
                                selectedFilesForSet.forEach((f, index) => {
                                  f.Id = fileIndexMapping[f.Id];
                                });
                              }
                              let newFiles = selectedFilesForSet.filter(
                                (sf) => {
                                  return sf.DocId === 0;
                                }
                              );
                              const response = await APICall(
                                documentUpload,
                                "POST",
                                {
                                  ModuleName: "EmployeeCentral",
                                  files: newFiles,
                                  SectionName: "Basic",
                                  TabName: "Personal",
                                  TId: postRes.data?.id ? postRes.data.id : TId,
                                  ModuleId: 1,
                                  UserId: proxyUserDetails?.EmployeeId
                                    ? proxyUserDetails?.EmployeeId
                                    : userDetails.UserId,
                                  ProxyUserId:
                                    proxyUserDetails === null
                                      ? null
                                      : proxyUserDetails?.value,
                                }
                              );

                              if (
                                response.data === null ||
                                response.data === undefined
                              ) {
                                notify(1, "Failed to upload documents.");
                              } else {
                                let docFromApi = response.data.files;
                                setSelectedFilesForSet((prevFiles) => {
                                  return prevFiles.map((doc) => {
                                    docFromApi = docFromApi.filter(
                                      (file) => file.Id === doc.Id
                                    );
                                    if (doc.Id === docFromApi.Id) {
                                      return {
                                        ...doc,
                                        Data: docFromApi.Data,
                                        DocId: docFromApi.DocId,
                                      };
                                    } else {
                                      return doc;
                                    }
                                  });
                                });
                              }
                              if (section.SN === "PreviousEmployment") {
                                setReloadEmployment(true);
                              }
                            } catch (error) {
                              throw new Error(
                                "Error uploading documents: " + error.message
                              );
                            }
                          }

                          //post audit log & newHire event
                          if (postRes.status === 0) {
                            setPostAuditLog(true);
                            setPostEventData(true);
                            if (changeWflTaskSts) {
                              const taskUpd = await APICall(
                                updateCandidateTasks,
                                "POST",
                                {
                                  CandidateWorkflowTaskId:
                                    candidateWorkflowTaskId,
                                  Decision: "ToInProgress",
                                }
                              );
                              if (taskUpd.status == 0) {
                                console.log(
                                  taskUpd.status,
                                  "Task Updated to In Progress!"
                                );
                                setChangeWflTaskSts(false);
                              } else {
                                console.log(
                                  taskUpd.status,
                                  "Task NOT Updated to In Progress!"
                                );
                                setChangeWflTaskSts(true);
                              }
                            }
                          }

                          await hideLoader();
                          notify(postRes.status, postRes.message);
                        } else if (
                          isEmpty &&
                          attributeSetCode !== null &&
                          Object.keys(attributesData).length > 0
                        ) {
                          await showLoader();
                          // post data attribute
                          const postAttributes = [];
                          const finalPostAttr = [];
                          Object.entries(attributesData).forEach(
                            async (attributeData) => {
                              const [Code, Value]: any[] = attributeData;

                              let attribute = {
                                Type: "Attribute",
                                Code,
                                Value:
                                  typeof Value === "object"
                                    ? Value?.value.toString() || null
                                    : typeof Value === "string"
                                    ? Value.trim()
                                    : typeof Value === "boolean"
                                    ? Value
                                    : null,
                                Details: null,
                              };
                              postAttributes.push(attribute);
                            }
                          );
                          finalPostAttr.push(postAttributes);
                          let details = [...ogFilledAttributeSet];

                          const finalDetails = [];
                          details.forEach(async (attributes) => {
                            let attributeObj = {};
                            Object.keys(attributes).forEach(
                              async (attribute) => {
                                attributeObj = {
                                  ...attributeObj,
                                  [attribute]:
                                    typeof attributes[attribute] === "object"
                                      ? attributes[
                                          attribute
                                        ]?.value.toString() || null
                                      : typeof attributes[attribute] ===
                                          "string" ||
                                        typeof attributes[attribute] ===
                                          "boolean" ||
                                        typeof attributes[attribute] ===
                                          "number"
                                      ? attributes[attribute]
                                      : null,
                                };

                                if (
                                  attribute === "Id" &&
                                  isNaN(Number(attributes[attribute]))
                                ) {
                                  attributeObj = {
                                    ...attributeObj,
                                    [attribute]: 0,
                                  };
                                } else if (
                                  attribute === "Id" &&
                                  !isNaN(Number(attributes[attribute]))
                                ) {
                                  attributeObj = {
                                    ...attributeObj,
                                    [attribute]: attributes[attribute],
                                  };
                                }
                              }
                            );
                            finalDetails.push(attributeObj);
                          });
                          postAttributes.push({
                            Code: attributeSetCode,
                            Value: "",
                            Type: "AttributeSet",
                            Details: finalDetails,
                          });
                          let postObjflat = {
                            MN: "EmployeeCentral",
                            IN: "EC_Form",
                            TN: formData.tn,
                            SN: section.SN,
                            TId: TId || 0,
                            COU: COU,
                            UserId: proxyUserDetails?.EmployeeId
                              ? proxyUserDetails?.EmployeeId
                              : userDetails.UserId,
                            UserEmailId: userDetails.EmailId,
                            UserName: userDetails.FirstName,
                            RoleId: currentRoleId?.value,
                            Attributes: postAttributes,
                            ProxyUserId:
                              proxyUserDetails === null
                                ? null
                                : proxyUserDetails?.value,
                          };

                          const postResflat = await APICall(
                            postAttributeValueDetailsV2,
                            "POST",
                            postObjflat
                          );

                          if (attributeSetCode && postResflat.status == 0) {
                            //update Id returned from Set
                            //note: check all set sp to return Id after insert,
                            //then can remove null check
                            setOgFilledAttributeSet((prevOgFilled) => {
                              return prevOgFilled.map((og, rowIndex) => ({
                                ...og,
                                Id:
                                  postResflat.data === null
                                    ? 0
                                    : postResflat.data[rowIndex],
                              }));
                            });

                            let trackIds = [];

                            setGridData((prevGrid) => {
                              return prevGrid.map((og, rowIndex) => {
                                trackIds = [
                                  ...trackIds,
                                  {
                                    prevId: og.Id,
                                    newId:
                                      postResflat.data === null
                                        ? 0
                                        : postResflat.data[rowIndex],
                                  },
                                ];

                                return {
                                  ...og,
                                  Id:
                                    postResflat.data === null
                                      ? 0
                                      : postResflat.data[rowIndex],
                                };
                              });
                            });
                            setDisplayGrid((prevDisplay) => {
                              return prevDisplay.map((og, rowIndex) => {
                                const matchedPrevId = trackIds.find(
                                  (track) => track.prevId == og.Id
                                );
                                return {
                                  ...og,
                                  Id: matchedPrevId.newId,
                                };
                              });
                            });
                          }

                          //audit
                          const dataForAuditLog = [];
                          if (Object.keys(attributesData).length > 0) {
                            Object.entries(attributesData).forEach((data) => {
                              const [key, value]: any[] = data;

                              const auditLogObj = {
                                sectionId: section.SId.toString(),
                                IsAttributeSet: false,
                                Code: key,
                                Attribute: [
                                  {
                                    [key]: value?.label || value,
                                  },
                                ],
                              };
                              dataForAuditLog.push(auditLogObj);
                            });
                          }

                          setNewAuditLog((prev) => [...dataForAuditLog]);

                          notify(postResflat.status, postResflat.message);

                          //post audit log & newHire event
                          if (postResflat.status === 0) {
                            setPostAuditLog(true);
                            setPostEventData(true);
                            if (changeWflTaskSts) {
                              const taskUpd = await APICall(
                                updateCandidateTasks,
                                "POST",
                                {
                                  CandidateWorkflowTaskId:
                                    candidateWorkflowTaskId,
                                  Decision: "ToInProgress",
                                }
                              );
                              if (taskUpd.status == 0) {
                                console.log(
                                  taskUpd.status,
                                  "Task Updated to In Progress!"
                                );
                                setChangeWflTaskSts(false);
                              } else {
                                console.log(
                                  taskUpd.status,
                                  "Task NOT Updated to In Progress!"
                                );
                                setChangeWflTaskSts(true);
                              }
                            }
                          }

                          setShowAttrSet(false);
                          await hideLoader();
                        }
                      }
                    }}
                  >
                    <i className="fa fa-save"></i>
                  </button>
                </Tooltip>
              </div>
            </div>
          </AccordionSummary>

          <AccordionDetails className="">
            <div className="row">
              {section?.Attribute?.length > 0 &&
                section?.Attribute?.map((eachAttribute, index) => (
                  <React.Fragment key={`${index}-${eachAttribute.AC}`}>
                    {eachAttribute.AT === "Attribute" ? (
                      <>
                        {eachAttribute.DT === "DropdownSingle" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }
                            >
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <SelectForm
                                  isClearable
                                  isSearchable
                                  options={
                                    optionsObj[eachAttribute.AC]
                                      ? optionsObj[eachAttribute.AC]
                                      : []
                                  }
                                  placeholder={eachAttribute.AN}
                                  isDisabled={
                                    attributeValidation[eachAttribute.AC]
                                      ?.isDisable ||
                                    showAttr ||
                                    eachAttribute.AC === "CT"
                                      ? true
                                      : false
                                  }
                                  value={
                                    attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : null
                                  }
                                  onChange={async (event) => {
                                    // const tempLophr = attributesData.find((item) => item.AC == "LOPHR");
                                    let lophr =
                                      (Country === "CODECNTRY7" ||
                                        COU === "CODECNTRY7") &&
                                      attributesData["LOPHR"] != "" &&
                                      attributesData["LOPHR"] != null &&
                                      attributesData["LOPHR"] != undefined
                                        ? attributesData["LOPHR"]
                                        : "0";
                                    //On change of Job level change LOP - Hourly Rate (this is in USA only)
                                    if (
                                      event &&
                                      (Country === "CODECNTRY7" ||
                                        COU === "CODECNTRY7")
                                    ) {
                                      if (
                                        eachAttribute.AC === "JL" &&
                                        attributesData["LEN"] !== undefined &&
                                        attributesData["LEN"] !== null &&
                                        attributesData["EC"] !== undefined &&
                                        attributesData["EC"] !== null
                                      ) {
                                        lophr = await getLOPHourlyRate(
                                          attributesData["LEN"].value,
                                          attributesData["EC"].value,
                                          event.value
                                        );
                                        // await setAttributesData((prev) => ({
                                        //   ...prev,
                                        //   ["LOPHR"]: lophr,
                                        // }));
                                      }
                                      if (
                                        eachAttribute.AC === "EC" &&
                                        attributesData["LEN"] !== undefined &&
                                        attributesData["LEN"] !== null &&
                                        attributesData["JL"] !== undefined &&
                                        attributesData["JL"] !== null
                                      ) {
                                        lophr = await getLOPHourlyRate(
                                          attributesData["LEN"].value,
                                          event.value,
                                          attributesData["JL"].value
                                        );
                                        // await setAttributesData((prev) => ({
                                        //   ...prev,
                                        //   ["LOPHR"]:  lophr,
                                        // }));
                                      }
                                      if (
                                        eachAttribute.AC === "LEN" &&
                                        attributesData["JL"] !== null &&
                                        attributesData["JL"] !== undefined &&
                                        attributesData["EC"] !== null &&
                                        attributesData["EC"] !== undefined
                                      ) {
                                        lophr = await getLOPHourlyRate(
                                          event.value,
                                          attributesData["EC"].value,
                                          attributesData["JL"].value
                                        );
                                        // await setAttributesData((prev) => ({
                                        //   ...prev,
                                        //   ["LOPHR"]:  lophr,
                                        // }));
                                      }
                                    }
                                    lophr =
                                      lophr != null ? lophr.toString() : "0";
                                    console.log(lophr);
                                    if (
                                      Country === "CODECNTRY7" ||
                                      COU === "CODECNTRY7"
                                    ) {
                                      await setAttributesData((prev) => ({
                                        ...prev,
                                        [eachAttribute.AC]: event,
                                        ["LOPHR"]: lophr,
                                      }));
                                    } else {
                                      await setAttributesData((prev) => ({
                                        ...prev,
                                        [eachAttribute.AC]: event,
                                      }));
                                    }

                                    //Form 11 EPFO DISSABLE AND MANDITORY CONDITION --
                                    if (eachAttribute.AC === "CDAMPF") {
                                      let attributeValObj = {
                                        ...attributeValidation,
                                      };

                                      if (
                                        event != null &&
                                        event?.label === "No"
                                        //&& attributesData["CDAMP"]?.label === "No"
                                      ) {
                                        attributeValObj.CDAMP.ISM = "false";
                                        attributeValObj.CDUAAN.ISM = "false";
                                        attributeValObj.CDPFAN.ISM = "false";
                                        attributeValObj.CDDOJNEXM.ISM = "false";
                                        attributeValObj.CDESTBNM.ISM = "false";

                                        attributeValObj.CDAMP.isDisable = true;
                                        attributeValObj.CDUAAN.isDisable = true;
                                        attributeValObj.CDPFAN.isDisable = true;
                                        attributeValObj.CDDOJNEXM.isDisable =
                                          true;
                                        attributeValObj.CDESTBNM.isDisable =
                                          true;

                                        attributeValObj.CDSCCNONEXM.isDisable =
                                          true;
                                        attributeValObj.CDPPONO.isDisable =
                                          true;
                                        attributeValObj.CDNPCDNEXM.isDisable =
                                          true;
                                        attributeValObj.CDTNNM.isDisable = true;
                                        attributeValObj.CDUAN.isDisable = true;
                                        attributeValObj.CDEPSACCNO.isDisable =
                                          true;
                                        attributeValObj.CDDOJEXMT.isDisable =
                                          true;
                                        attributeValObj.CDDOEEXMT.isDisable =
                                          true;
                                        attributeValObj.CDSSCNOEXMT.isDisable =
                                          true;
                                        attributeValObj.CDNPCDEXMT.isDisable =
                                          true;

                                        await setAttributesData((prev) => ({
                                          ...prev,
                                          ["CDAMP"]:
                                            optionsObj["CDAMP"].length > 0
                                              ? optionsObj["CDAMP"].filter(
                                                  (x) => x.label === "No"
                                                )[0]
                                              : {},
                                          ["CDPFAN"]: "",
                                          ["CDUAAN"]: "",

                                          ["CDSCCNONEXM"]: "",
                                          ["CDPPONO"]: "",
                                          ["CDNPCDNEXM"]: "",
                                          ["CDTNNM"]: "",
                                          ["CDUAN"]: "",
                                          ["CDEPSACCNO"]: "",
                                          ["CDDOJEXMT"]: "",
                                          ["CDDOEEXMT"]: "",
                                          ["CDSSCNOEXMT"]: "",
                                          ["CDNPCDEXMT"]: "",
                                        }));
                                      } else {
                                        attributeValObj.CDAMP.ISM = "true";
                                        attributeValObj.CDUAAN.ISM = "true";
                                        attributeValObj.CDPFAN.ISM = "true";
                                        attributeValObj.CDDOJNEXM.ISM = "true";
                                        attributeValObj.CDESTBNM.ISM = "true";

                                        attributeValObj.CDAMP.isDisable = false;
                                        attributeValObj.CDUAAN.isDisable =
                                          false;
                                        attributeValObj.CDPFAN.isDisable =
                                          false;
                                        attributeValObj.CDDOJNEXM.isDisable =
                                          false;
                                        attributeValObj.CDESTBNM.isDisable =
                                          false;

                                        attributeValObj.CDSCCNONEXM.isDisable =
                                          false;
                                        attributeValObj.CDPPONO.isDisable =
                                          false;
                                        attributeValObj.CDNPCDNEXM.isDisable =
                                          false;
                                        attributeValObj.CDTNNM.isDisable =
                                          false;
                                        attributeValObj.CDUAN.isDisable = false;
                                        attributeValObj.CDEPSACCNO.isDisable =
                                          false;
                                        attributeValObj.CDDOJEXMT.isDisable =
                                          false;
                                        attributeValObj.CDDOEEXMT.isDisable =
                                          false;
                                        attributeValObj.CDSSCNOEXMT.isDisable =
                                          false;
                                        attributeValObj.CDNPCDEXMT.isDisable =
                                          false;
                                      }
                                      setAttributeValidation((prev) => ({
                                        ...prev,
                                        ...attributeValObj,
                                      }));
                                    }

                                    if (eachAttribute.AC === "LEN" && event) {
                                      let ccd = await getCompanyCode(
                                        attributesData["SBU"].value,
                                        event.value
                                      );
                                      await setAttributesData((prev) => ({
                                        ...prev,
                                        ["CCD"]: ccd,
                                      }));
                                    }
                                    if (
                                      eachAttribute.AC === "SD" &&
                                      event != null
                                    ) {
                                      await setAttributesData((prev) => ({
                                        ...prev,
                                        ["PT"]: event.label,
                                      }));
                                    }

                                    if (
                                      event !== null &&
                                      eachAttribute.AC === "DOCTYPE"
                                    ) {
                                      setAttributesData((prev) => ({
                                        ...prev,
                                        ["CNTXT"]: JSON.parse(
                                          event.additionalDetails
                                        ).CON,
                                      }));
                                      if (selectedFilesForFlat.length > 0) {
                                        let temp = [];
                                        selectedFilesForFlat.map((item) => {
                                          let obj = item;
                                          if (item.AC === "CONFDOC") {
                                            obj = {
                                              ...item,
                                              Type: event.label,
                                              Context: JSON.parse(
                                                event.additionalDetails
                                              ).CON,
                                            };
                                          }
                                          temp.push(obj);
                                        });
                                        setSelectedFilesForFlat(temp);
                                      }
                                    } else {
                                      setAttributesData((prev) => ({
                                        ...prev,
                                        ["CNTXT"]: "",
                                      }));
                                    }

                                    if (
                                      eachAttribute.CC !== null &&
                                      eachAttribute.CC !== ""
                                    ) {
                                      const CC = eachAttribute.CC.split(",");

                                      CC.forEach(async (childDropdown) => {
                                        // hard code to get CUSBIS dropdown data
                                        let PId = "";
                                        if (
                                          (eachAttribute.AC === "BIS" ||
                                            eachAttribute.AC === "SBU" ||
                                            eachAttribute.AC === "LEN") &&
                                          childDropdown === "CUSBIS"
                                        ) {
                                          let BIS =
                                            eachAttribute.AC === "BIS"
                                              ? event
                                                ? event.value.toString()
                                                : null
                                              : attributesData["BIS"] !== null
                                              ? attributesData[
                                                  "BIS"
                                                ].value.toString()
                                              : null;
                                          let SBU =
                                            eachAttribute.AC === "SBU"
                                              ? event
                                                ? event.value.toString()
                                                : null
                                              : attributesData["SBU"] !== null
                                              ? attributesData[
                                                  "SBU"
                                                ].value.toString()
                                              : null;
                                          let LEN =
                                            eachAttribute.AC === "LEN"
                                              ? event
                                                ? event.value.toString()
                                                : null
                                              : attributesData["LEN"] !== null
                                              ? attributesData[
                                                  "LEN"
                                                ].value.toString()
                                              : null;
                                          if (BIS && SBU && LEN) {
                                            PId = `${BIS},${SBU},${LEN}`;
                                          }
                                        }
                                        //-------------------------------

                                        if (
                                          (eachAttribute.AC === "EC" ||
                                            eachAttribute.AC === "LEN") &&
                                          childDropdown === "JB"
                                        ) {
                                          let LEN =
                                            eachAttribute.AC === "LEN"
                                              ? event
                                                ? event.value.toString()
                                                : null
                                              : attributesData["LEN"] !== null
                                              ? attributesData[
                                                  "LEN"
                                                ].value.toString()
                                              : null;
                                          let BIS =
                                            eachAttribute.AC === "EC"
                                              ? event
                                                ? event.value.toString()
                                                : null
                                              : attributesData["EC"] !== null
                                              ? attributesData[
                                                  "EC"
                                                ].value.toString()
                                              : null;

                                          if (BIS && LEN) {
                                            PId = `${LEN},${BIS}`;
                                          }
                                        }

                                        if (
                                          eachAttribute.AC === "LEN" &&
                                          childDropdown === "PLOC"
                                        ) {
                                          let LEN =
                                            eachAttribute.AC === "LEN"
                                              ? event
                                                ? event.value.toString()
                                                : null
                                              : attributesData["LEN"] !== null
                                              ? attributesData[
                                                  "LEN"
                                                ].value.toString()
                                              : null;

                                          if (LEN) {
                                            PId = `${LEN}`;
                                          }
                                        }
                                        setAttributesData((prev) => ({
                                          ...prev,
                                          [childDropdown]: null,
                                        }));

                                        const options = await getDropdownsData(
                                          childDropdown,
                                          (event &&
                                            childDropdown === "CUSBIS") ||
                                            (event && childDropdown === "JB") ||
                                            (event && childDropdown === "PLOC")
                                            ? PId
                                            : event
                                            ? event.value
                                            : ""
                                        );

                                        setOptionsObj((prev) => ({
                                          ...prev,
                                          [childDropdown]: options,
                                        }));
                                      });
                                    }
                                    // if (eachAttribute.AC === "Gen") {
                                    //   let attributeValObj = {
                                    //     ...attributeValidation,
                                    //   };
                                    //   if (
                                    //     event != null &&
                                    //     event.label === "Female"
                                    //   ) {
                                    //     attributeValObj.MAN.ISM = "true";
                                    //   } else {
                                    //     attributeValObj.MAN.ISM = "false";
                                    //   }
                                    //   setAttributeSetValidation((prev) => ({
                                    //     ...prev,
                                    //     ...attributeValObj,
                                    //   }));
                                    // }

                                    // if (eachAttribute.AC === "MARITLST") {
                                    //   let attributeValObj = {
                                    //     ...attributeValidation,
                                    //   };
                                    //   if (
                                    //     event != null &&
                                    //     event.label === "Married"
                                    //   ) {
                                    //     attributeValObj.ProofBD.ISM = "true";
                                    //     attributeValObj.MAN.ISM = "true";
                                    //   } else {
                                    //     attributeValObj.ProofBD.ISM = "false";
                                    //     attributeValObj.MAN.ISM = "false";
                                    //   }
                                    //   setAttributeSetValidation((prev) => ({
                                    //     ...prev,
                                    //     ...attributeValObj,
                                    //   }));
                                    // }
                                    dependentValidations(
                                      event,
                                      eachAttribute.AC,
                                      "Attribute"
                                    );
                                  }}
                                  isMulti={false}
                                  noIndicator={false}
                                  noSeparator={false}
                                />
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "Text" ||
                          eachAttribute.DT === "Number" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }
                            >
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <InputForm
                                  className="form-control"
                                  placeholder={eachAttribute.AN}
                                  isDisabled={
                                    attributeValidation[eachAttribute.AC]
                                      ?.isDisable ||
                                    showAttr ||
                                    eachAttribute.AC === "AGE" ||
                                    eachAttribute.AC === "DN" ||
                                    eachAttribute.AC === "TIPOS" ||
                                    eachAttribute.AC === "TAN" ||
                                    //eachAttribute.AC === "PEIM" ||
                                    eachAttribute.AC === "CCD" ||
                                    eachAttribute.AC === "NPD" ||
                                    eachAttribute.AC === "NPIM" ||
                                    eachAttribute.AC === "NPSDE" ||
                                    eachAttribute.AC === "OCDC" ||
                                    eachAttribute.AC === "JT" ||
                                    eachAttribute.AC === "PID" ||
                                    eachAttribute.AC === "LOPHR" ||
                                    //eachAttribute.AC === "LID" ||
                                    eachAttribute.AC === "CNTXT" ||
                                    eachAttribute.AC === "CDESTBNM" ||
                                    eachAttribute.AC === "CDDOJNEXM" ||
                                    eachAttribute.AC === "CDDOENEXM" ||
                                    eachAttribute.AC === "CDCMPADDR" ||
                                    eachAttribute.AC === "CDTKTNUM" ||
                                    eachAttribute.AC === "ASA"
                                      ? true
                                      : eachAttribute.AC === "MAN"
                                      ? attributesData["Gen"]?.label ===
                                          "Female" &&
                                        attributesData["MARITLST"]?.label ===
                                          "Married"
                                        ? false
                                        : true
                                      : false
                                  }
                                  textArea={false}
                                  value={
                                    eachAttribute.AC === "TIPOS"
                                      ? dateyearmonthdiff(
                                          attributesData["POSED"]
                                        )
                                      : eachAttribute.AC === "NPD" &&
                                        (attributesData["NPD"] === null ||
                                          attributesData["NPD"] === undefined)
                                      ? "0"
                                      : attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : ""
                                  }
                                  onChange={(e) => {
                                    setAttributesData((prev) => ({
                                      ...prev,
                                      [eachAttribute.AC]: e.target.value,
                                    }));

                                    dependentValidations(
                                      e.target.value,
                                      eachAttribute.AC,
                                      "Attribute"
                                    );

                                    //hard code to fill display name
                                    if (section.SN === "Basic") {
                                      setAttributesData((prev) => ({
                                        ...prev,
                                        ["DN"]:
                                          prev.MN !== null &&
                                          prev.MN !== undefined &&
                                          prev.MN !== ""
                                            ? `${prev.FN.trim()} ${prev.MN.trim()} ${prev.LN.trim()}`.trim()
                                            : `${prev.FN.trim()} ${prev.LN.trim()}`.trim(),
                                      }));
                                    }
                                  }}
                                  maxLength="255"
                                />
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "Date" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }
                            >
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <DateForm
                                  isDisabled={
                                    attributeValidation[eachAttribute.AC]
                                      ?.isDisable ||
                                    showAttr ||
                                    eachAttribute.AC === "POSED" ||
                                    eachAttribute.AC === "RTD" ||
                                    eachAttribute.AC === "NPEDE" ||
                                    eachAttribute.AC === "PEDE"
                                      ? true
                                      : false
                                  }
                                  value={
                                    attributesData[eachAttribute.AC] === "NPEDE"
                                      ? GetNPEndDate(
                                          attributesData["NPSD"],
                                          attributesData["NPIM"]
                                        )
                                      : attributesData[eachAttribute.AC] ===
                                        "NPSDE"
                                      ? GetShortDays(
                                          attributesData["NPEDE"],
                                          attributesData["LWD"]
                                        )
                                      : attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : null
                                  }
                                  onChange={(date) => {
                                    setAttributesData((prev) => ({
                                      ...prev,
                                      [eachAttribute.AC]:
                                        date != null
                                          ? moment(date).format("DD-MMM-YYYY")
                                          : null,
                                    }));

                                    dependentValidations(
                                      moment(date).format("DD-MMM-YYYY"),
                                      eachAttribute.AC,
                                      "Attribute"
                                    );

                                    //hard code to calculate age on DOB
                                    if (eachAttribute.AC === "DOB" && date) {
                                      const ageInYears = moment().diff(
                                        date,
                                        "years"
                                      );

                                      setAttributesData((prev) => ({
                                        ...prev,
                                        AGE: ageInYears.toString(),
                                      }));
                                    }
                                  }}
                                />
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "Checkbox" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }
                            >
                              <div className="mb-1">
                                <label
                                  htmlFor={eachAttribute.AC}
                                  className="col-form-label"
                                >
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <div>
                                  <input
                                    disabled={
                                      attributeValidation[eachAttribute.AC]
                                        ?.isDisable || showAttr
                                    }
                                    type="checkbox"
                                    onChange={(e) => {
                                      setAttributesData((prev) => ({
                                        ...prev,
                                        [eachAttribute.AC]: e.target.checked,
                                      }));

                                      dependentValidations(
                                        e.target.checked,
                                        eachAttribute.AC,
                                        "Attribute"
                                      );
                                    }}
                                    id={eachAttribute.AC}
                                    checked={
                                      attributesData[eachAttribute.AC]
                                        ? attributesData[eachAttribute.AC]
                                        : false
                                    }
                                  />
                                </div>
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "Textarea" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }
                            >
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <InputForm
                                  className="form-control"
                                  placeholder={eachAttribute.AN}
                                  isDisabled={
                                    attributeValidation[eachAttribute.AC]
                                      ?.isDisable || showAttr
                                  }
                                  textArea={true}
                                  value={
                                    attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : ""
                                  }
                                  onChange={(e) => {
                                    setAttributesData((prev) => ({
                                      ...prev,
                                      [eachAttribute.AC]: e.target.value,
                                    }));

                                    dependentValidations(
                                      e.target.value,
                                      eachAttribute.AC,
                                      "Attribute"
                                    );
                                  }}
                                  maxLength="255"
                                />
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "Document" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }
                            >
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <div className="box position-relative">
                                  <input
                                    id={`files-${sectionIndex}`}
                                    className="form-control inputfile inputfile-6 multiple-inputfile"
                                    data-multiple-caption="{count} files selected"
                                    multiple={
                                      JSON.parse(eachAttribute.V).FC > 1
                                        ? true
                                        : false
                                    }
                                    ref={FlatDocRef}
                                    accept={JSON.parse(eachAttribute.V).FX}
                                    type="file"
                                    onChange={(e) => {
                                      handleFileChangeForFlat(e, eachAttribute);
                                    }}
                                    value={""}
                                  />
                                  <label
                                    htmlFor={`files-${sectionIndex}`}
                                    className="form-control"
                                    style={{ width: 0, border: "none" }}
                                  >
                                    <strong
                                      style={{
                                        padding: "6px 16px",
                                        backgroundColor: "#3c5464",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      <i
                                        className="fa fa-upload rotate90"
                                        aria-hidden="true"
                                      ></i>
                                      {"  "}
                                      Upload
                                    </strong>{" "}
                                  </label>
                                </div>
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                                <div className="file-added-list">
                                  <ul className="list-unstyle">
                                    {selectedFilesForFlat &&
                                      selectedFilesForFlat.length > 0 &&
                                      selectedFilesForFlat.map(
                                        (fileAttribute, attributeIndex) => (
                                          <>
                                            {fileAttribute.AC ===
                                              eachAttribute.AC && (
                                              <li
                                                className="list mt-1"
                                                key={attributeIndex}
                                              >
                                                <div className="media">
                                                  <div className="media-body text-truncate">
                                                    <span className="view-more">
                                                      {fileAttribute.Name}
                                                    </span>
                                                  </div>
                                                  {/*Here code for view same div of trash should be used. ISDOCVIEW/ISDOCDOWN*/}
                                                  {/* {
                                                    // section?.ISDOCVIEW &&
                                                    fileAttribute.DocId !==
                                                      0 && (
                                                      <div className="media-right ml-2">
                                                        <i
                                                          className="fa fa-eye"
                                                          aria-hidden="true"
                                                          onClick={() =>
                                                            handleViewForFlat(
                                                              fileAttribute,
                                                              attributeIndex
                                                            )
                                                          }></i>
                                                      </div>
                                                    )
                                                  } */}
                                                  {
                                                    //  section?.ISDOCDOWN &&
                                                    <div className="media-right ml-2">
                                                      <i
                                                        className="fa-solid fa-download"
                                                        aria-hidden="true"
                                                        onClick={() =>
                                                          handleDownloadFileFlat(
                                                            fileAttribute,
                                                            attributeIndex
                                                          )
                                                        }
                                                      ></i>
                                                    </div>
                                                  }
                                                  {section?.ISEDIT &&
                                                    fileAttribute.DocId !==
                                                      0 && (
                                                      <div className="media-right ml-2">
                                                        <i
                                                          className="fa fa-trash"
                                                          aria-hidden="true"
                                                          onClick={() =>
                                                            handleDeleteFileForFlat(
                                                              fileAttribute,
                                                              attributeIndex
                                                            )
                                                          }
                                                        ></i>
                                                      </div>
                                                    )}
                                                </div>
                                              </li>
                                            )}
                                          </>
                                        )
                                      )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "SearchableDropdownSingle" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                )?.IFR?.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }
                            >
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM?.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <SelectForm
                                  isClearable
                                  isSearchable
                                  async
                                  options={(searchString, cb) =>
                                    getSearchableDropdownAPI(
                                      searchString,
                                      eachAttribute.AC,
                                      cb,
                                      "",
                                      "attribute",
                                      0
                                    )
                                  }
                                  placeholder={eachAttribute.AN}
                                  isDisabled={
                                    attributeValidation[eachAttribute.AC]
                                      ?.isDisable ||
                                    !section.ISEDIT ||
                                    eachAttribute.AC === "IOPPOS"
                                  }
                                  value={
                                    attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : null
                                  }
                                  onChange={async (event) => {
                                    setAttributesData((prev) => ({
                                      ...prev,
                                      [eachAttribute.AC]: event,
                                    }));

                                    if (
                                      eachAttribute.CC !== null &&
                                      eachAttribute.CC !== ""
                                    ) {
                                      const CC = eachAttribute.CC.split(",");

                                      CC.forEach(async (childDropdown) => {
                                        setAttributesData((prev) => ({
                                          ...prev,
                                          [childDropdown]: null,
                                        }));

                                        const options = await getDropdownsData(
                                          childDropdown,
                                          event ? event.value : ""
                                        );

                                        setOptionsObj((prev) => ({
                                          ...prev,
                                          [childDropdown]: options,
                                        }));
                                      });
                                    }

                                    dependentValidations(
                                      event,
                                      eachAttribute.AC,
                                      "Attribute"
                                    );

                                    //searchable DD
                                    if (event) {
                                      searchableDDValues(
                                        event,
                                        eachAttribute.AC
                                      );
                                    }

                                    if (
                                      eachAttribute.AC === "POS" &&
                                      event !== null
                                    ) {
                                      const ddEvent = await getPPonIncm(
                                        eachAttribute.AC,
                                        event.value
                                      );
                                      if (ddEvent !== undefined) {
                                        if (
                                          ddEvent.value !== null &&
                                          ddEvent.label !== null
                                        ) {
                                          const ac = "IOPPOS";
                                          const temp = {
                                            value: ddEvent.value,
                                            label: ddEvent.label,
                                          };
                                          setAttributesData((prev) => ({
                                            ...prev,
                                            POSED:
                                              moment().format("DD-MMM-YYYY"),
                                            TIPOS: "0 years, 0 months, 0 days",
                                            [ac]: temp,
                                          }));
                                        }
                                      } else {
                                        setAttributesData((prev) => ({
                                          ...prev,
                                          POSED: moment().format("DD-MMM-YYYY"),
                                          TIPOS: "0 years, 0 months, 0 days",
                                        }));
                                      }
                                    }
                                    if (
                                      eachAttribute.AC === "POS" &&
                                      event === null
                                    ) {
                                      const ac = "IOPPOS";
                                      setAttributesData((prev) => ({
                                        ...prev,
                                        [ac]: event,
                                      }));
                                    }

                                    if (
                                      eachAttribute.AC === "JC" &&
                                      event === null
                                    ) {
                                      setAttributesData((prev) => ({
                                        ...prev,
                                        ["JT"]: "",
                                      }));
                                    }

                                    //Hardcode to set PosEntry date and Time in Position.
                                    // if (eachAttribute.AC === "POS" && event) {
                                    //   setAttributesData((prev) => ({
                                    //     ...prev,
                                    //     POSED: moment().format("DD-MMM-YYYY"),
                                    //     TIPOS: "0 years, 0 months, 0 days",
                                    //   }));
                                    // }
                                  }}
                                  isMulti={false}
                                  noIndicator={false}
                                  noSeparator={false}
                                />
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "DropdownMultiple" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }
                            >
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {JSON.parse(
                                    eachAttribute.V
                                  ).ISM.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <SelectForm
                                  isClearable
                                  isSearchable
                                  options={
                                    optionsObj[eachAttribute.AC]
                                      ? optionsObj[eachAttribute.AC]
                                      : []
                                  }
                                  isMulti={multiDropdown}
                                  placeholder={eachAttribute.AN}
                                  isDisabled={
                                    attributeValidation[eachAttribute.AC]
                                      ?.isDisable ||
                                    !section.ISEDIT ||
                                    viewOnly
                                  }
                                  value={
                                    attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : null
                                  }
                                  onChange={(event) => {
                                    let attributeValObj = {
                                      ...attributeValidation,
                                    };
                                    setAttributesData((prev) => ({
                                      ...prev,
                                      [eachAttribute.AC]: event,
                                    }));
                                    if (eachAttribute.AC === "MODOFCOMM") {
                                      if (
                                        event !== null &&
                                        event.find(
                                          (item) => item.code === "Phone"
                                        ) !== undefined &&
                                        event.find(
                                          (item) => item.code === "Email"
                                        ) !== undefined
                                      ) {
                                        attributeValObj.COMMPHN.ISM = "true";
                                        attributeValObj.COMMPHN.isDisable =
                                          false;
                                        attributeValObj.COMMEMAIL.isDisable =
                                          false;
                                        attributeValObj.COMMEMAIL.ISM = "true";
                                      } else if (
                                        event !== null &&
                                        event.find(
                                          (item) => item.code === "Phone"
                                        ) !== undefined
                                      ) {
                                        attributeValObj.COMMPHN.ISM = "true";
                                        attributeValObj.COMMPHN.isDisable =
                                          false;
                                        attributeValObj.COMMEMAIL.isDisable =
                                          true;
                                        attributeValObj.COMMEMAIL.ISM = "false";

                                        setAttributesData((prev) => ({
                                          ...prev,
                                          ["COMMEMAIL"]: null,
                                        }));
                                      } else if (
                                        event !== null &&
                                        event.find(
                                          (item) => item.code === "Email"
                                        ) !== undefined
                                      ) {
                                        attributeValObj.COMMPHN.ISM = "false";
                                        attributeValObj.COMMPHN.isDisable =
                                          true;
                                        attributeValObj.COMMEMAIL.isDisable =
                                          false;
                                        attributeValObj.COMMEMAIL.ISM = "true";
                                        setAttributesData((prev) => ({
                                          ...prev,
                                          ["COMMPHN"]: null,
                                        }));
                                      } else {
                                        attributeValObj.COMMPHN.ISM = "true";
                                        attributeValObj.COMMPHN.isDisable =
                                          false;
                                        attributeValObj.COMMEMAIL.isDisable =
                                          false;
                                        attributeValObj.COMMEMAIL.ISM = "true";
                                        setAttributesData((prev) => ({
                                          ...prev,
                                          ["COMMPHN"]: null,
                                          ["COMMEMAIL"]: null,
                                        }));
                                      }
                                      setAttributeValidation((prev) => ({
                                        ...prev,
                                        ...attributeValObj,
                                      }));
                                    }
                                    if (
                                      event !== null &&
                                      eachAttribute.CC !== null &&
                                      eachAttribute.CC !== ""
                                    ) {
                                      const CC = eachAttribute.CC.split(",");

                                      CC.forEach(async (childDropdown) => {
                                        setAttributesData((prev) => ({
                                          ...prev,
                                          [childDropdown]: null,
                                        }));

                                        const options = await getDropdownsData(
                                          childDropdown,
                                          event.value
                                        );

                                        setOptionsObj((prev) => ({
                                          ...prev,
                                          [childDropdown]: options,
                                        }));
                                      });
                                    }
                                  }}
                                  noIndicator={false}
                                  noSeparator={false}
                                />
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "DocumentLink" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }
                            >
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                </label>

                                <ul className="list-unstyle">
                                  <li className="list mt-1">
                                    <a
                                      className="link-primary"
                                      href={require(`./${
                                        JSON.parse(eachAttribute.AD)?.FileName
                                      }`)}
                                      download={`${
                                        JSON.parse(eachAttribute.AD)?.FileName
                                      }`}
                                      //target="_blank"
                                    >
                                      <p className="policylinks">
                                        {JSON.parse(eachAttribute.AD)?.URLN}
                                      </p>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : eachAttribute.AT === "AttributeSet" &&
                      eachAttribute.DT === "Custom" ? (
                      <div className="col-lg-12">
                        {section?.ISEDIT && (
                          <div className="col-lg-12 mt-2 mb-3 d-flex justify-content-end">
                            <Tooltip title="Add">
                              <button
                                id={"addRec"}
                                className="btn btn-primary"
                                onClick={() => {
                                  if (ShowAttrSet) {
                                    setAttributeSetData((prev) => ({
                                      ...prev,
                                      ...ogAttributeSet,
                                    }));
                                    setCurrentGridId(null);

                                    setViewOnly(false);
                                  }
                                  handleEffectiveDatePrefill();
                                  setShowAttrSet(!ShowAttrSet);
                                }}
                              >
                                <i className={"fa fa-plus"}></i> Add
                              </button>
                            </Tooltip>
                          </div>
                        )}
                        {ShowAttrSet && (
                          <div className="row">
                            {eachAttribute.Attribute.length > 0 &&
                              eachAttribute.Attribute.map(
                                (subAttribute, index) => (
                                  <React.Fragment
                                    key={`${index}-${subAttribute.AC}`}
                                  >
                                    {subAttribute.DT === "DropdownSingle" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {attributeSetValidation[
                                                subAttribute.AC
                                              ]?.ISM.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <SelectForm
                                              isClearable
                                              isSearchable
                                              options={
                                                optionsObj[subAttribute.AC]
                                                  ? optionsObj[subAttribute.AC]
                                                  : []
                                              }
                                              {...(subAttribute.AC ===
                                                "STSE" && {
                                                filterOption: (option) =>
                                                  currentGridId === null &&
                                                  subAttribute.AC === "STSE"
                                                    ? option.label !== "Demise"
                                                    : option,
                                              })}
                                              {...(subAttribute.AC ===
                                                "RELSHTYP" && {
                                                filterOption: (option) =>
                                                  currentGridId === null &&
                                                  subAttribute.AC === "RELSHTYP"
                                                    ? option.label !==
                                                      "Employee"
                                                    : option,
                                              })}
                                              {...(subAttribute.AC ===
                                                "PCE" && {
                                                filterOption: (option) =>
                                                  Country === "CODECNTRY1" ||
                                                  COU === "CODECNTRY1"
                                                    ? option.label !==
                                                      "Base Pay"
                                                    : option.label !==
                                                        "Fixed Pay" &&
                                                      option.label !==
                                                        "Variable Pay",
                                              })}
                                              // {...(subAttribute.AC ==="PCE" &&  (Country==="CODECNTRY7" || COU==="CODECNTRY7")?
                                              //   {
                                              //     filterOption: (option) =>
                                              //        option.label !== "Fixed Pay" && option.label !=="Variable Pay"
                                              //   }
                                              // : {
                                              //     filterOption: (option) =>
                                              //       option.label !== "Base Pay"

                                              //   })}
                                              placeholder={subAttribute.AN}
                                              isDisabled={
                                                showAttr ||
                                                viewOnly ||
                                                (subAttribute.AC ===
                                                  "STATDESE" &&
                                                  (currentGridId === "0" ||
                                                    currentGridId === null)) ||
                                                (subAttribute.AC === "PVSEMP" &&
                                                  ogFilledAttributeSet.some(
                                                    (og) =>
                                                      (og.PVSEMP != null ||
                                                        og.PVSEMP !=
                                                          undefined) &&
                                                      !isNaN(og.Id) &&
                                                      og.IsActive
                                                  )) ||
                                                attributeSetValidation[
                                                  subAttribute.AC
                                                ]?.isDisable
                                              }
                                              value={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : null
                                              }
                                              onChange={async (event) => {
                                                setAttributeSetData((prev) => ({
                                                  ...prev,
                                                  [subAttribute.AC]: event,
                                                }));
                                                // if (
                                                //   event !== null &&
                                                //   subAttribute.AC === "RELSHTYP"
                                                // ) {
                                                //   const option =
                                                //     await getJobRelationDD(
                                                //       TId,
                                                //       event
                                                //     );
                                                //   setOptionsObj((prev) => ({
                                                //     ...prev,
                                                //     ["RELNME"]: option,
                                                //   }));
                                                // }

                                                // Set Mandatory false if selected Freshers -- 12-02-2024 -Ankita
                                                if (
                                                  event != null &&
                                                  subAttribute.AC === "PVSEMP"
                                                ) {
                                                  let attributeSetValObj = {
                                                    ...attributeSetValidation,
                                                  };
                                                  if (
                                                    event.label === "Fresher"
                                                  ) {
                                                    attributeSetValObj.PRVEMPLYATT.ISM =
                                                      "false";
                                                  } else {
                                                    attributeSetValObj.PRVEMPLYATT.ISM =
                                                      "true";
                                                  }

                                                  setAttributeSetValidation(
                                                    (prev) => ({
                                                      ...prev,
                                                      ...attributeSetValObj,
                                                    })
                                                  );
                                                }
                                                // When Full n Final is Selected set witness mandatory n Enabled - NomineeDet
                                                if (
                                                  subAttribute.AC === "TYOFNOM"
                                                ) {
                                                  let attributeSetValObj = {
                                                    ...attributeSetValidation,
                                                  };
                                                  if (
                                                    event != null &&
                                                    event?.label ===
                                                      "Full & Final"
                                                  ) {
                                                    attributeSetValObj.NOM_Witness1Name.ISM =
                                                      "true";
                                                    attributeSetValObj.NOM_Witness1Address.ISM =
                                                      "true";
                                                    attributeSetValObj.NOM_Witness2Name.ISM =
                                                      "true";
                                                    attributeSetValObj.NOM_Witness2Address.ISM =
                                                      "true";
                                                    attributeSetValObj.NOM_Witness1Name.isDisable =
                                                      false;
                                                    attributeSetValObj.NOM_Witness1Address.isDisable =
                                                      false;
                                                    attributeSetValObj.NOM_Witness2Name.isDisable =
                                                      false;
                                                    attributeSetValObj.NOM_Witness2Address.isDisable =
                                                      false;
                                                  } else {
                                                    attributeSetValObj.NOM_Witness1Name.ISM =
                                                      "false";
                                                    attributeSetValObj.NOM_Witness1Address.ISM =
                                                      "false";
                                                    attributeSetValObj.NOM_Witness2Name.ISM =
                                                      "false";
                                                    attributeSetValObj.NOM_Witness2Address.ISM =
                                                      "false";
                                                    attributeSetValObj.NOM_Witness1Name.isDisable =
                                                      true;
                                                    attributeSetValObj.NOM_Witness1Address.isDisable =
                                                      true;
                                                    attributeSetValObj.NOM_Witness2Name.isDisable =
                                                      true;
                                                    attributeSetValObj.NOM_Witness2Address.isDisable =
                                                      true;

                                                    await setAttributeSetData(
                                                      (prev) => ({
                                                        ...prev,
                                                        ["NOM_Witness1Name"]:
                                                          "",
                                                        ["NOM_Witness1Address"]:
                                                          "",
                                                        ["NOM_Witness2Name"]:
                                                          "",
                                                        ["NOM_Witness2Address"]:
                                                          "",
                                                      })
                                                    );
                                                  }

                                                  setAttributeSetValidation(
                                                    (prev) => ({
                                                      ...prev,
                                                      ...attributeSetValObj,
                                                    })
                                                  );
                                                }
                                                //Mandatory Attachmant for PAN AND AADHAR
                                                if (
                                                  subAttribute.AC === "IDCATY"
                                                ) {
                                                  let attributeSetValObj = {
                                                    ...attributeSetValidation,
                                                  };
                                                  if (event != null) {
                                                    if (
                                                      event.label ===
                                                        "Aadhar Card" ||
                                                      event.label === "PAN Card"
                                                    ) {
                                                      attributeSetValObj.NTNLATTCH.ISM =
                                                        "true";
                                                    } else {
                                                      attributeSetValObj.NTNLATTCH.ISM =
                                                        "false";
                                                    }
                                                  } else {
                                                    attributeSetValObj.NTNLATTCH.ISM =
                                                      "false";
                                                  }
                                                }
                                                if (subAttribute.AC === "ACT") {
                                                  let attributeSetValObj = {
                                                    ...attributeSetValidation,
                                                  };
                                                  if (
                                                    event != null &&
                                                    event.label ===
                                                      "Car/Vehicle"
                                                  ) {
                                                    attributeSetValObj.ASMODLNUM.ISM =
                                                      "true";
                                                  } else {
                                                    attributeSetValObj.ASMODLNUM.ISM =
                                                      "false";
                                                  }
                                                  setAttributeSetValidation(
                                                    (prev) => ({
                                                      ...prev,
                                                      ...attributeSetValObj,
                                                    })
                                                  );
                                                }
                                                if (subAttribute.AC === "PTP") {
                                                  let attributeSetValObj = {
                                                    ...attributeSetValidation,
                                                  };
                                                  if (
                                                    event != null &&
                                                    event.label ===
                                                      "Office Telephone"
                                                  ) {
                                                    attributeSetValObj.AC.ISM =
                                                      "true";
                                                    attributeSetValObj.OFFEX.ISM =
                                                      "true";
                                                  } else {
                                                    attributeSetValObj.AC.ISM =
                                                      "false";
                                                    attributeSetValObj.OFFEX.ISM =
                                                      "false";
                                                  }
                                                  setAttributeSetValidation(
                                                    (prev) => ({
                                                      ...prev,
                                                      ...attributeSetValObj,
                                                    })
                                                  );
                                                }
                                                if (
                                                  subAttribute.CC !== null &&
                                                  subAttribute.CC !== ""
                                                ) {
                                                  const CC =
                                                    subAttribute.CC.split(",");

                                                  CC.forEach(
                                                    async (childDropdown) => {
                                                      setAttributeSetData(
                                                        (prev) => ({
                                                          ...prev,
                                                          [childDropdown]: null,
                                                        })
                                                      );

                                                      const options =
                                                        await getDropdownsData(
                                                          childDropdown,
                                                          event
                                                            ? event.value
                                                            : ""
                                                        );

                                                      setOptionsObj((prev) => ({
                                                        ...prev,
                                                        [childDropdown]:
                                                          options,
                                                      }));
                                                    }
                                                  );
                                                }

                                                //hard code to enable valild till on change
                                                //maybe add this in DD later for attributeSet
                                                if (
                                                  event &&
                                                  (event.label.toLowerCase() ===
                                                    "driving license" ||
                                                    event.label.toLowerCase() ===
                                                      "passport")
                                                ) {
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      NVALTIL: null,
                                                    })
                                                  );
                                                }

                                                //hard code for address information
                                                if (
                                                  event &&
                                                  (subAttribute.AC === "PA" ||
                                                    subAttribute.AC ===
                                                      "ADRTYP")
                                                ) {
                                                  if (
                                                    subAttribute.AC === "PA"
                                                  ) {
                                                    setAddressType((prev) => ({
                                                      ...prev,
                                                      address:
                                                        event.label.toLowerCase(),
                                                    }));
                                                  } else {
                                                    setAddressType((prev) => ({
                                                      ...prev,
                                                      type: event.label.toLowerCase(),
                                                    }));
                                                  }
                                                }

                                                if (subAttribute.AC === "PA") {
                                                  if (
                                                    event != null &&
                                                    event != undefined
                                                  ) {
                                                    if (
                                                      event.label.toLowerCase() ===
                                                        "emergency" ||
                                                      event.label.toLowerCase() ===
                                                        "permanent"
                                                    ) {
                                                      setOptionsObj((prev) => ({
                                                        ...prev,
                                                        ["ADRTYP"]: [],
                                                      }));

                                                      let attributeSetValObj = {
                                                        ...attributeSetValidation,
                                                      };
                                                      attributeSetValObj.ADRTYP.ISM =
                                                        "false";
                                                      setAttributeSetValidation(
                                                        (prev) => ({
                                                          ...prev,
                                                          ...attributeSetValObj,
                                                        })
                                                      );
                                                      setAttributeSetData(
                                                        (prev) => ({
                                                          ...prev,
                                                          ["ADRTYP"]: null,
                                                        })
                                                      );
                                                    } else {
                                                      const adrTOPT =
                                                        eachAttribute.Attribute.find(
                                                          (item) =>
                                                            item.AC === "ADRTYP"
                                                        );
                                                      if (adrTOPT) {
                                                        setOptionsObj(
                                                          (prev) => ({
                                                            ...prev,
                                                            ["ADRTYP"]:
                                                              adrTOPT.OPT,
                                                          })
                                                        );
                                                      }
                                                      let attributeSetValObj = {
                                                        ...attributeSetValidation,
                                                      };
                                                      attributeSetValObj.ADRTYP.ISM =
                                                        "true";
                                                      setAttributeSetValidation(
                                                        (prev) => ({
                                                          ...prev,
                                                          ...attributeSetValObj,
                                                        })
                                                      );
                                                    }
                                                  } else {
                                                    const adrTOPT =
                                                      eachAttribute.Attribute.find(
                                                        (item) =>
                                                          item.AC === "ADRTYP"
                                                      );
                                                    if (adrTOPT) {
                                                      setOptionsObj((prev) => ({
                                                        ...prev,
                                                        ["ADRTYP"]: adrTOPT.OPT,
                                                      }));
                                                    }
                                                    setAttributeSetData(
                                                      (prev) => ({
                                                        ...prev,
                                                        ["ADRTYP"]: null,
                                                      })
                                                    );
                                                  }
                                                }
                                                dependentValidations(
                                                  event,
                                                  subAttribute.AC,
                                                  "Attributeset"
                                                );
                                              }}
                                              isMulti={false}
                                              noIndicator={false}
                                              noSeparator={false}
                                            />
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT === "Text" ||
                                      subAttribute.DT === "Number" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {attributeSetValidation[
                                                subAttribute.AC
                                              ]?.ISM.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <InputForm
                                              className="form-control"
                                              placeholder={subAttribute.AN}
                                              isDisabled={
                                                showAttr ||
                                                viewOnly ||
                                                subAttribute.AC === "RELNNM" ||
                                                subAttribute.AC === "DURGCRC" ||
                                                subAttribute.AC === "WLSPEXP" ||
                                                subAttribute.AC === "PLEN" ||
                                                subAttribute.AC === "RELNCOMP"
                                                  ? true
                                                  : subAttribute.AC === "AC"
                                                  ? attributeSetData["PTP"]
                                                      ?.label ===
                                                    "Office Telephone"
                                                    ? false
                                                    : true
                                                  : subAttribute.AC === "OFFEX"
                                                  ? attributeSetData["PTP"]
                                                      ?.label ===
                                                    "Office Telephone"
                                                    ? false
                                                    : true
                                                  : attributeSetValidation[
                                                      subAttribute.AC
                                                    ]?.isDisable
                                              }
                                              textArea={false}
                                              value={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : ""
                                              }
                                              onChange={(e) => {
                                                setAttributeSetData((prev) => ({
                                                  ...prev,
                                                  [subAttribute.AC]:
                                                    subAttribute.AC === "EA"
                                                      ? e.target.value.toLowerCase()
                                                      : e.target.value,
                                                }));
                                              }}
                                              maxLength="255"
                                            />
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT === "MonthYear" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {attributeSetValidation[
                                                subAttribute.AC
                                              ]?.ISM.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <DateForm
                                              isDisabled={
                                                showAttr ||
                                                viewOnly ||
                                                attributeSetValidation[
                                                  subAttribute.AC
                                                ]?.isDisable
                                              }
                                              views={["month", "year"]}
                                              format="MMM/yyyy"
                                              value={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : null
                                              }
                                              onChange={(date) => {
                                                setAttributeSetData((prev) => ({
                                                  ...prev,
                                                  [subAttribute.AC]:
                                                    date != null
                                                      ? moment(date).format(
                                                          "DD-MMM-YYYY"
                                                        )
                                                      : null,
                                                }));

                                                //hard code to calculate duration in formal education
                                                if (
                                                  date &&
                                                  (subAttribute.AC ===
                                                    "FRMDTFE" ||
                                                    subAttribute.AC ===
                                                      "ENDDTED")
                                                ) {
                                                  const endDate = moment(
                                                    subAttribute.AC ===
                                                      "ENDDTED"
                                                      ? date
                                                      : attributeSetData[
                                                          "ENDDTED"
                                                        ] !== null
                                                      ? attributeSetData[
                                                          "ENDDTED"
                                                        ]
                                                      : date
                                                  );

                                                  const fromDate = moment(
                                                    subAttribute.AC ===
                                                      "FRMDTFE"
                                                      ? date
                                                      : attributeSetData[
                                                          "FRMDTFE"
                                                        ] !== null
                                                      ? attributeSetData[
                                                          "FRMDTFE"
                                                        ]
                                                      : date
                                                  );

                                                  const duration =
                                                    moment.duration(
                                                      endDate.diff(fromDate)
                                                    );

                                                  const durationInYears =
                                                    duration.years();

                                                  const durationInMonths =
                                                    duration.months();

                                                  const durationInDays =
                                                    duration.days();

                                                  if (
                                                    isNaN(durationInYears) ||
                                                    durationInYears < 0 ||
                                                    isNaN(durationInMonths) ||
                                                    durationInMonths < 0 ||
                                                    isNaN(durationInDays) ||
                                                    durationInDays < 0
                                                  ) {
                                                    setAttributeSetData(
                                                      (prev) => ({
                                                        ...prev,
                                                        ["DURGCRC"]: "",
                                                      })
                                                    );
                                                  } else {
                                                    setAttributeSetData(
                                                      (prev) => ({
                                                        ...prev,
                                                        ["DURGCRC"]: `${durationInYears} years ${durationInMonths} months ${durationInDays} days`,
                                                      })
                                                    );
                                                  }
                                                }
                                              }}
                                            />
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT === "Date" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {/* {JSON.parse(
                                              subAttribute.V
                                            ).ISM.toLowerCase() === "true" && (
                                              <sup>*</sup>
                                            )} */}
                                              {attributeSetValidation[
                                                subAttribute.AC
                                              ]?.ISM.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <DateForm
                                              isDisabled={
                                                showAttr ||
                                                viewOnly ||
                                                subAttribute.AC === "NVALTIL"
                                                  ? attributeSetData?.IDCATY !==
                                                      null &&
                                                    (attributeSetData?.IDCATY?.label.toLowerCase() ===
                                                      "driving license" ||
                                                      attributeSetData?.IDCATY?.label.toLowerCase() ===
                                                        "passport")
                                                    ? false
                                                    : true
                                                  : subAttribute.AC === "LSD" ||
                                                    subAttribute.AC === "LED"
                                                  ? attributeSetData?.ADRTYP !=
                                                      null &&
                                                    attributeSetData?.ADRTYP?.label.toLowerCase() ===
                                                      "lease"
                                                    ? false
                                                    : true
                                                  : subAttribute?.AN ===
                                                    "Effective Date"
                                                  ? true
                                                  : attributeSetValidation[
                                                      subAttribute.AC
                                                    ]?.isDisable
                                              }
                                              value={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : null
                                              }
                                              onChange={(date) => {
                                                setAttributeSetData((prev) => ({
                                                  ...prev,
                                                  [subAttribute.AC]:
                                                    date != null
                                                      ? moment(date).format(
                                                          "DD-MMM-YYYY"
                                                        )
                                                      : null,
                                                }));

                                                //hard code to calculate duration in Additional Skills
                                                if (
                                                  date &&
                                                  (subAttribute.AC === "STDT" ||
                                                    subAttribute.AC === "ENDDT")
                                                ) {
                                                  const endDate = moment(
                                                    subAttribute.AC === "ENDDT"
                                                      ? date
                                                      : attributeSetData[
                                                          "ENDDT"
                                                        ] !== null
                                                      ? attributeSetData[
                                                          "ENDDT"
                                                        ]
                                                      : date
                                                  );

                                                  const fromDate = moment(
                                                    subAttribute.AC === "STDT"
                                                      ? date
                                                      : attributeSetData[
                                                          "STDT"
                                                        ] !== null
                                                      ? attributeSetData["STDT"]
                                                      : date
                                                  );

                                                  const duration =
                                                    moment.duration(
                                                      endDate.diff(fromDate)
                                                    );

                                                  const durationInYears =
                                                    duration.years();

                                                  const durationInMonths =
                                                    duration.months();

                                                  const durationInDays =
                                                    duration.days();

                                                  if (
                                                    isNaN(durationInYears) ||
                                                    durationInYears < 0 ||
                                                    isNaN(durationInMonths) ||
                                                    durationInMonths < 0 ||
                                                    isNaN(durationInDays) ||
                                                    durationInDays < 0
                                                  ) {
                                                    setAttributeSetData(
                                                      (prev) => ({
                                                        ...prev,
                                                        ["WLSPEXP"]: "",
                                                      })
                                                    );
                                                  } else {
                                                    setAttributeSetData(
                                                      (prev) => ({
                                                        ...prev,
                                                        ["WLSPEXP"]: `${durationInYears} years ${durationInMonths} months ${durationInDays} days`,
                                                      })
                                                    );
                                                  }
                                                }
                                                /*Hardcode to set learning duration */
                                                if (
                                                  date &&
                                                  (subAttribute.AC === "DTEC" ||
                                                    subAttribute.AC === "DTES")
                                                ) {
                                                  const endDate = moment(
                                                    subAttribute.AC === "DTEC"
                                                      ? date
                                                      : attributeSetData[
                                                          "DTEC"
                                                        ] !== null
                                                      ? attributeSetData["DTEC"]
                                                      : date
                                                  );

                                                  const fromDate = moment(
                                                    subAttribute.AC === "DTES"
                                                      ? date
                                                      : attributeSetData[
                                                          "DTES"
                                                        ] !== null
                                                      ? attributeSetData["DTES"]
                                                      : date
                                                  );

                                                  const duration =
                                                    moment.duration(
                                                      endDate.diff(fromDate)
                                                    );

                                                  const durationInYears =
                                                    duration.years();

                                                  const durationInMonths =
                                                    duration.months();

                                                  const durationInDays =
                                                    duration.days();

                                                  if (
                                                    isNaN(durationInYears) ||
                                                    durationInYears < 0 ||
                                                    isNaN(durationInMonths) ||
                                                    durationInMonths < 0 ||
                                                    isNaN(durationInDays) ||
                                                    durationInDays < 0
                                                  ) {
                                                    setAttributeSetData(
                                                      (prev) => ({
                                                        ...prev,
                                                        ["PLEN"]: "",
                                                      })
                                                    );
                                                  } else {
                                                    setAttributeSetData(
                                                      (prev) => ({
                                                        ...prev,
                                                        ["PLEN"]: `${durationInYears} years ${durationInMonths} months ${durationInDays} days`,
                                                      })
                                                    );
                                                  }
                                                }
                                                /*Hardcode to set learning duration */
                                              }}
                                            />
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT === "Checkbox" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label
                                              htmlFor={subAttribute.AC}
                                              className="col-form-label"
                                            >
                                              {subAttribute.AN}
                                              {attributeSetValidation[
                                                subAttribute.AC
                                              ]?.ISM.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <div>
                                              <input
                                                disabled={
                                                  showAttr ||
                                                  viewOnly ||
                                                  attributeSetValidation[
                                                    subAttribute.AC
                                                  ]?.isDisable
                                                }
                                                type="checkbox"
                                                onChange={(e) => {
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      [subAttribute.AC]:
                                                        e.target.checked,
                                                    })
                                                  );
                                                }}
                                                id={subAttribute.AC}
                                                checked={
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ]
                                                    ? attributeSetData[
                                                        subAttribute.AC
                                                      ]
                                                    : false
                                                }
                                              />
                                            </div>
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT === "Document" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {attributeSetValidation[
                                                subAttribute.AC
                                              ]?.ISM.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>
                                            <div className="box position-relative">
                                              <input
                                                id={`files-${sectionIndex}`}
                                                disabled={
                                                  showAttr ||
                                                  viewOnly ||
                                                  attributeSetValidation[
                                                    subAttribute.AC
                                                  ]?.isDisable
                                                }
                                                ref={SetDocRef}
                                                className="form-control inputfile inputfile-6 multiple-inputfile"
                                                data-multiple-caption="{count} files selected"
                                                multiple={
                                                  JSON.parse(subAttribute.V)
                                                    .FC > 1
                                                    ? true
                                                    : false
                                                }
                                                accept={
                                                  JSON.parse(subAttribute.V).FX
                                                }
                                                type="file"
                                                onChange={(e) => {
                                                  handleFileChangeForSet(
                                                    e,
                                                    subAttribute
                                                  );
                                                }}
                                                value={""}
                                              />
                                              <label
                                                htmlFor={`files-${sectionIndex}`}
                                                className="form-control"
                                                style={{
                                                  width: 0,
                                                  border: "none",
                                                }}
                                              >
                                                <strong
                                                  style={{
                                                    padding: "6px 16px",
                                                    backgroundColor: "#3c5464",
                                                    borderRadius: "5px",
                                                  }}
                                                >
                                                  <i
                                                    className="fa fa-upload rotate90"
                                                    aria-hidden="true"
                                                  ></i>
                                                  {"  "}
                                                  Upload
                                                </strong>{" "}
                                              </label>
                                            </div>
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                            <div className="file-added-list">
                                              <ul className="list-unstyle">
                                                {selectedFilesForSet &&
                                                  selectedFilesForSet.length >
                                                    0 &&
                                                  selectedFilesForSet.map(
                                                    (
                                                      fileAttribute,
                                                      attributeIndex
                                                    ) => (
                                                      <>
                                                        {fileAttribute.AC ===
                                                          subAttribute.AC &&
                                                          (fileAttribute.Id ===
                                                            0 ||
                                                            fileAttribute.Id ==
                                                              currentGridId) && (
                                                            <li
                                                              className="list mt-1"
                                                              key={
                                                                attributeIndex
                                                              }
                                                            >
                                                              <div className="media">
                                                                <div className="media-body text-truncate">
                                                                  <span className="view-more">
                                                                    {
                                                                      fileAttribute.Name
                                                                    }
                                                                  </span>
                                                                </div>
                                                                {/*Here code for view same div of trash should be used. ISDOCVIEW/ISDOCDOWN*/}
                                                                {/* {
                                                                  // section?.ISDOCVIEW &&
                                                                  fileAttribute.DocId !==
                                                                    0 && (
                                                                    <div className="media-right ml-2">
                                                                      <i
                                                                        className="fa fa-eye"
                                                                        aria-hidden="true"
                                                                        onClick={() =>
                                                                          handleViewForFlat(
                                                                            fileAttribute,
                                                                            attributeIndex
                                                                          )
                                                                        }></i>
                                                                    </div>
                                                                  )
                                                                } */}

                                                                {
                                                                  // section?.ISDOCDOWN &&
                                                                  <div className="media-right ml-2">
                                                                    <i
                                                                      className="fa-solid fa-download"
                                                                      aria-hidden="true"
                                                                      onClick={() =>
                                                                        handleDownloadFileFlat(
                                                                          fileAttribute,
                                                                          attributeIndex
                                                                        )
                                                                      }
                                                                    ></i>
                                                                  </div>
                                                                }
                                                                {section?.ISEDIT &&
                                                                  viewOnly ===
                                                                    false &&
                                                                  fileAttribute.DocId !==
                                                                    0 && (
                                                                    <div className="media-right ml-2">
                                                                      <i
                                                                        className="fa fa-trash"
                                                                        aria-hidden="true"
                                                                        onClick={() =>
                                                                          handleDeleteFileForSet(
                                                                            fileAttribute,
                                                                            attributeIndex
                                                                          )
                                                                        }
                                                                      ></i>
                                                                    </div>
                                                                  )}
                                                              </div>
                                                            </li>
                                                          )}
                                                      </>
                                                    )
                                                  )}
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT ===
                                      "SearchableDropdownSingle" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {attributeSetValidation[
                                                subAttribute.AC
                                              ]?.ISM.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <SelectForm
                                              isClearable
                                              isSearchable
                                              async
                                              options={(searchString, cb) =>
                                                getSearchableDropdownAPI(
                                                  searchString,
                                                  subAttribute.AC,
                                                  cb,
                                                  "",
                                                  "attributeSet",
                                                  0
                                                )
                                              }
                                              placeholder={subAttribute.AN}
                                              isDisabled={
                                                !section.ISEDIT ||
                                                viewOnly ||
                                                attributeSetValidation[
                                                  eachAttribute.AC
                                                ]?.isDisable
                                              }
                                              value={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : null
                                              }
                                              onChange={(event) => {
                                                setAttributeSetData((prev) => ({
                                                  ...prev,
                                                  [subAttribute.AC]: event,
                                                }));

                                                if (
                                                  subAttribute.CC !== null &&
                                                  subAttribute.CC !== ""
                                                ) {
                                                  const CC =
                                                    subAttribute.CC.split(",");

                                                  CC.forEach(
                                                    async (childDropdown) => {
                                                      setAttributeSetData(
                                                        (prev) => ({
                                                          ...prev,
                                                          [childDropdown]: null,
                                                        })
                                                      );

                                                      const options =
                                                        await getDropdownsData(
                                                          childDropdown,
                                                          event
                                                            ? event.value
                                                            : ""
                                                        );

                                                      setOptionsObj((prev) => ({
                                                        ...prev,
                                                        [childDropdown]:
                                                          options,
                                                      }));
                                                    }
                                                  );
                                                }

                                                // dependentValidations(
                                                //   event,
                                                //   subAttribute.AC
                                                // );

                                                //searchable DD
                                                if (event) {
                                                  searchableDDValuesSet(
                                                    event,
                                                    subAttribute.AC
                                                  );
                                                }
                                              }}
                                              isMulti={false}
                                              noIndicator={false}
                                              noSeparator={false}
                                            />
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT ===
                                      "DropdownMultiple" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {JSON.parse(
                                                subAttribute.V
                                              ).ISM.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <SelectForm
                                              isClearable
                                              isSearchable
                                              options={
                                                optionsObj[subAttribute.AC]
                                                  ? optionsObj[subAttribute.AC]
                                                  : []
                                              }
                                              isMulti={multiDropdown}
                                              placeholder={subAttribute.AN}
                                              isDisabled={
                                                !section.ISEDIT || viewOnly
                                              }
                                              value={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : null
                                              }
                                              onChange={(event) => {
                                                setAttributeSetData((prev) => ({
                                                  ...prev,
                                                  [subAttribute.AC]: event,
                                                }));
                                                let attributeSetValObj = {
                                                  ...attributeSetValidation,
                                                };
                                                if (
                                                  subAttribute.AC === "TYOFNOM"
                                                ) {
                                                  if (
                                                    (typeof attributeSetData[
                                                      "TYOFNOM"
                                                    ] === "object" &&
                                                      Array.isArray(
                                                        attributeSetData[
                                                          "TYOFNOM"
                                                        ]
                                                      ) === true &&
                                                      event.some(
                                                        (obj) =>
                                                          obj.label ===
                                                          "Full & Final"
                                                      )) ||
                                                    event.label ===
                                                      "Full & Final"
                                                  ) {
                                                    attributeSetValObj.NOM_Witness1Name.ISM =
                                                      "true";
                                                    attributeSetValObj.NOM_Witness1Address.ISM =
                                                      "true";
                                                    attributeSetValObj.NOM_Witness2Name.ISM =
                                                      "true";
                                                    attributeSetValObj.NOM_Witness2Address.ISM =
                                                      "true";
                                                    attributeSetValObj.NOM_Witness1Name.isDisable =
                                                      false;
                                                    attributeSetValObj.NOM_Witness1Address.isDisable =
                                                      false;
                                                    attributeSetValObj.NOM_Witness2Name.isDisable =
                                                      false;
                                                    attributeSetValObj.NOM_Witness2Address.isDisable =
                                                      false;
                                                  } else {
                                                    attributeSetValObj.NOM_Witness1Name.ISM =
                                                      "false";
                                                    attributeSetValObj.NOM_Witness1Address.ISM =
                                                      "false";
                                                    attributeSetValObj.NOM_Witness2Name.ISM =
                                                      "false";
                                                    attributeSetValObj.NOM_Witness2Address.ISM =
                                                      "false";
                                                    attributeSetValObj.NOM_Witness1Name.isDisable =
                                                      true;
                                                    attributeSetValObj.NOM_Witness1Address.isDisable =
                                                      true;
                                                    attributeSetValObj.NOM_Witness2Name.isDisable =
                                                      true;
                                                    attributeSetValObj.NOM_Witness2Address.isDisable =
                                                      true;

                                                    setAttributeSetData(
                                                      (prev) => ({
                                                        ...prev,
                                                        ["NOM_Witness1Name"]:
                                                          "",
                                                        ["NOM_Witness1Address"]:
                                                          "",
                                                        ["NOM_Witness2Name"]:
                                                          "",
                                                        ["NOM_Witness2Address"]:
                                                          "",
                                                      })
                                                    );
                                                  }
                                                }
                                                setAttributeSetValidation(
                                                  (prev) => ({
                                                    ...prev,
                                                    ...attributeSetValObj,
                                                  })
                                                );
                                                if (
                                                  event !== null &&
                                                  subAttribute.CC !== null &&
                                                  subAttribute.CC !== ""
                                                ) {
                                                  const CC =
                                                    subAttribute.CC.split(",");

                                                  CC.forEach(
                                                    async (childDropdown) => {
                                                      setAttributeSetData(
                                                        (prev) => ({
                                                          ...prev,
                                                          [childDropdown]: null,
                                                        })
                                                      );

                                                      const options =
                                                        await getDropdownsData(
                                                          childDropdown,
                                                          event.value
                                                        );

                                                      setOptionsObj((prev) => ({
                                                        ...prev,
                                                        [childDropdown]:
                                                          options,
                                                      }));
                                                    }
                                                  );
                                                }
                                              }}
                                              noIndicator={false}
                                              noSeparator={false}
                                            />
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : //  : subAttribute.DT ===
                                    //   "DropdownMultiple" ? (
                                    //   <>
                                    //     <div
                                    //       className={
                                    //         JSON.parse(
                                    //           subAttribute.AD
                                    //         ).IFR.toLowerCase() === "true"
                                    //           ? "col-lg-12 col-sm-12 col-xs-12 "
                                    //           : "col-lg-3 col-sm-3 col-xs-4"
                                    //       }>
                                    //       <div className="mb-1">
                                    //         <label className="col-form-label">
                                    //           {subAttribute.AN}
                                    //           {JSON.parse(
                                    //             subAttribute.V
                                    //           ).ISM.toLowerCase() ===
                                    //             "true" && <sup>*</sup>}
                                    //         </label>

                                    //         <SelectForm
                                    //           isClearable
                                    //           isSearchable
                                    //           options={
                                    //             optionsObj[subAttribute.AC]
                                    //               ? optionsObj[subAttribute.AC]
                                    //               : []
                                    //           }
                                    //           isMulti={multiDropdown}
                                    //           placeholder={subAttribute.AN}
                                    //           isDisabled={
                                    //             !section.ISEDIT || viewOnly
                                    //           }
                                    //           value={
                                    //             attributeSetData[
                                    //               subAttribute.AC
                                    //             ]
                                    //               ? attributeSetData[
                                    //                   subAttribute.AC
                                    //                 ]
                                    //               : null
                                    //           }
                                    //           onChange={(event) => {
                                    //             setAttributeSetData((prev) => ({
                                    //               ...prev,
                                    //               [subAttribute.AC]: event,
                                    //             }));
                                    //             if (
                                    //               event !== null &&
                                    //               subAttribute.CC !== null &&
                                    //               subAttribute.CC !== ""
                                    //             ) {
                                    //               const CC =
                                    //                 subAttribute.CC.split(",");

                                    //               CC.forEach(
                                    //                 async (childDropdown) => {
                                    //                   setAttributeSetData(
                                    //                     (prev) => ({
                                    //                       ...prev,
                                    //                       [childDropdown]: null,
                                    //                     })
                                    //                   );

                                    //                   const options =
                                    //                     await getDropdownsData(
                                    //                       childDropdown,
                                    //                       event.value
                                    //                     );

                                    //                   setOptionsObj((prev) => ({
                                    //                     ...prev,
                                    //                     [childDropdown]:
                                    //                       options,
                                    //                   }));
                                    //                 }
                                    //               );
                                    //             }
                                    //           }}
                                    //           noIndicator={false}
                                    //           noSeparator={false}
                                    //         />
                                    //         {formErrors[subAttribute.AC] && (
                                    //           <p style={{ color: "red" }}>
                                    //             {formErrors[subAttribute.AC]}
                                    //           </p>
                                    //         )}
                                    //       </div>
                                    //     </div>
                                    //   </>
                                    // )
                                    subAttribute.DT === "DocumentLink" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                            </label>
                                            <ul className="list-unstyle">
                                              <li className="list mt-1">
                                                <a
                                                  className="link-primary"
                                                  href={
                                                    JSON.parse(subAttribute.AD)
                                                      ?.URLV
                                                  }
                                                  target="_blank"
                                                >
                                                  {
                                                    JSON.parse(subAttribute.AD)
                                                      ?.URLN
                                                  }
                                                </a>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </React.Fragment>
                                )
                              )}
                          </div>
                        )}
                        {/*Added Custom logic for Address Type for Address information - Sanket*/}

                        {section?.SN === "AddressInformation" &&
                          ShowAttrSet && (
                            <div className="col-lg-12 mt-2 mb-3 d-flex justify-content-end">
                              {/* <> {console.log(addressType)}</>
                              <div>
                                <label
                                  htmlFor={"Same for Emergency"}
                                  className="col-form-label">
                                  Same for Emergency
                                </label>
                                <input
                                  disabled={
                                    attributeValidation[eachAttribute.AC]
                                      ?.isDisable || showAttr
                                  }
                                  type="checkbox"
                                  onChange={(event) =>
                                    checkSameAddressType(event, "emergency")
                                  }
                                  id={eachAttribute.AC}
                                  checked={isSameAsEmergency}
                                />
                              </div>
                              <div>
                                <label
                                  htmlFor={"Same for Permanent"}
                                  className="col-form-label">
                                  Same for Permanent
                                </label>
                                <input
                                  type="checkbox"
                                  onChange={(event) =>
                                    checkSameAddressType(event, "Permanent")
                                  }
                                  id={eachAttribute.AC}
                                  checked={isSameAsPermanent}
                                />
                              </div>
                              <div>
                                <label
                                  htmlFor={"Same for Present"}
                                  className="col-form-label">
                                  Same for Present
                                </label>
                                <input
                                  type="checkbox"
                                  onChange={(event) =>
                                    checkSameAddressType(event, "Present")
                                  }
                                  id={eachAttribute.AC}
                                  checked={isSameAsPresent}
                                />
                              </div> */}
                            </div>
                          )}
                        <div className="row">
                          {ShowAttrSet && (
                            <div className="mb-2">
                              <button
                                className="btn btn-secondary ml-3"
                                onClick={() => {
                                  if (ShowAttrSet) {
                                    setAttributeSetData((prev) => ({
                                      ...prev,
                                      ...ogAttributeSet,
                                    }));
                                    setFormErrors({});
                                    setCurrentGridId(null);
                                    handleEffectiveDatePrefill();
                                  }
                                }}
                              >
                                Cancel
                              </button>
                              {section?.ISEDIT && viewOnly === false && (
                                <button
                                  id={currentGridId === null ? "0" : "1"}
                                  className="btn btn-primary ml-2"
                                  disabled={!section.ISEDIT}
                                  onClick={async (e) => {
                                    const targetId = e.currentTarget.id;

                                    if (targetId === "0") {
                                      //insert
                                      if (
                                        section.SN === "NomineeDetails" &&
                                        typeof attributeSetData["TYOFNOM"] ===
                                          "object" &&
                                        Array.isArray(
                                          attributeSetData["TYOFNOM"]
                                        ) === true
                                      ) {
                                        if (section.SN === "NomineeDetails") {
                                          const isError = validateData(
                                            eachAttribute.Attribute
                                          );
                                          if (!isError) {
                                            for (
                                              let i = 0;
                                              i <
                                              attributeSetData["TYOFNOM"]
                                                .length; //NOMSHAR
                                              i++
                                            ) {
                                              // Other Saving  Validations Here

                                              let filteredTyp =
                                                ogFilledAttributeSet.filter(
                                                  (og) => {
                                                    return (
                                                      og.TYOFNOM?.label ===
                                                        attributeSetData[
                                                          "TYOFNOM"
                                                        ][i]?.label &&
                                                      attributeSetData[
                                                        "TYOFNOM"
                                                      ][i]?.label ==
                                                        "Full & Final" &&
                                                      og.IsActive === true
                                                      //&& og.Id != currentGridId
                                                    );
                                                  }
                                                );

                                              if (filteredTyp.length > 0) {
                                                notify(
                                                  1,
                                                  "Nominee Type Full & Final already exists, Can't Insert Another."
                                                );
                                                // setGridSaveBtnDisable(false);
                                                return;
                                              }
                                            }

                                            let finalSetAdddr = [];
                                            attributeSetData[`TYOFNOM`].map(
                                              (value, indx) => {
                                                const uniqueId = uuid();

                                                //validation for empty data before adding in grid
                                                let gridData = {};
                                                let ogf = {};

                                                eachAttribute.Attribute.forEach(
                                                  async (subAttribute) => {
                                                    if (
                                                      typeof attributeSetData[
                                                        subAttribute.AC
                                                      ] === "object" &&
                                                      Array.isArray(
                                                        attributeSetData[
                                                          subAttribute.AC
                                                        ]
                                                      ) === true
                                                    ) {
                                                      gridData = {
                                                        ...gridData,
                                                        [subAttribute.AC]:
                                                          value.label
                                                            ? value.label
                                                            : null, //for MultiSelect Dropdownn
                                                      };
                                                      ogf = {
                                                        ...ogf,
                                                        [subAttribute.AC]:
                                                          value,
                                                      };

                                                      if (
                                                        value.label !=
                                                        "Full & Final"
                                                      ) {
                                                        gridData = {
                                                          ...gridData,
                                                          ["NOM_Witness1Name"]:
                                                            "",
                                                          ["NOM_Witness1Address"]:
                                                            "",
                                                          ["NOM_Witness2Name"]:
                                                            "",
                                                          ["NOM_Witness2Address"]:
                                                            "", //for date and dropdown
                                                        };
                                                        ogf = {
                                                          ...ogf,
                                                          ["NOM_Witness1Name"]:
                                                            "",
                                                          ["NOM_Witness1Address"]:
                                                            "",
                                                          ["NOM_Witness2Name"]:
                                                            "",
                                                          ["NOM_Witness2Address"]:
                                                            "",
                                                        };
                                                      }
                                                    } else if (
                                                      typeof attributeSetData[
                                                        subAttribute.AC
                                                      ] === "object"
                                                    ) {
                                                      gridData = {
                                                        ...gridData,
                                                        [subAttribute.AC]:
                                                          attributeSetData[
                                                            subAttribute.AC
                                                          ]?.label || null, //for date and dropdown
                                                      };
                                                      ogf = {
                                                        ...ogf,
                                                        [subAttribute.AC]:
                                                          attributeSetData[
                                                            subAttribute.AC
                                                          ],
                                                      };
                                                      if (
                                                        value.label !=
                                                        "Full & Final"
                                                      ) {
                                                        gridData = {
                                                          ...gridData,
                                                          ["NOM_Witness1Name"]:
                                                            "",
                                                          ["NOM_Witness1Address"]:
                                                            "",
                                                          ["NOM_Witness2Name"]:
                                                            "",
                                                          ["NOM_Witness2Address"]:
                                                            "", //for date and dropdown
                                                        };
                                                        ogf = {
                                                          ...ogf,
                                                          ["NOM_Witness1Name"]:
                                                            "",
                                                          ["NOM_Witness1Address"]:
                                                            "",
                                                          ["NOM_Witness2Name"]:
                                                            "",
                                                          ["NOM_Witness2Address"]:
                                                            "",
                                                        };
                                                      }
                                                    } else if (
                                                      typeof attributeSetData[
                                                        subAttribute.AC
                                                      ] === "boolean"
                                                    ) {
                                                      gridData = {
                                                        ...gridData,
                                                        [subAttribute.AC]:
                                                          attributeSetData[
                                                            subAttribute.AC
                                                          ] === true
                                                            ? "true"
                                                            : "false",
                                                      };
                                                      if (
                                                        value.label !=
                                                        "Full & Final"
                                                      ) {
                                                        gridData = {
                                                          ...gridData,
                                                          ["NOM_Witness1Name"]:
                                                            "",
                                                          ["NOM_Witness1Address"]:
                                                            "",
                                                          ["NOM_Witness2Name"]:
                                                            "",
                                                          ["NOM_Witness2Address"]:
                                                            "", //for date and dropdown
                                                        };
                                                        ogf = {
                                                          ...ogf,
                                                          ["NOM_Witness1Name"]:
                                                            "",
                                                          ["NOM_Witness1Address"]:
                                                            "",
                                                          ["NOM_Witness2Name"]:
                                                            "",
                                                          ["NOM_Witness2Address"]:
                                                            "",
                                                        };
                                                      }
                                                    } else {
                                                      gridData = {
                                                        ...gridData,
                                                        [subAttribute.AC]:
                                                          attributeSetData[
                                                            subAttribute.AC
                                                          ],
                                                      };
                                                      ogf = {
                                                        ...ogf,
                                                        [subAttribute.AC]:
                                                          attributeSetData[
                                                            subAttribute.AC
                                                          ],
                                                      };
                                                      if (
                                                        value.label !=
                                                        "Full & Final"
                                                      ) {
                                                        gridData = {
                                                          ...gridData,
                                                          ["NOM_Witness1Name"]:
                                                            "",
                                                          ["NOM_Witness1Address"]:
                                                            "",
                                                          ["NOM_Witness2Name"]:
                                                            "",
                                                          ["NOM_Witness2Address"]:
                                                            "", //for date and dropdown
                                                        };
                                                        ogf = {
                                                          ...ogf,
                                                          ["NOM_Witness1Name"]:
                                                            "",
                                                          ["NOM_Witness1Address"]:
                                                            "",
                                                          ["NOM_Witness2Name"]:
                                                            "",
                                                          ["NOM_Witness2Address"]:
                                                            "",
                                                        };
                                                      }
                                                    }
                                                  }
                                                );

                                                setOgFilledAttributeSet(
                                                  (prev) => [
                                                    ...prev,
                                                    {
                                                      ...ogf,
                                                      Id: uniqueId,
                                                      IsActive: true,
                                                    },
                                                  ]
                                                );
                                                //reset
                                                setAttributeSetData((prev) => ({
                                                  ...prev,
                                                  ...ogAttributeSet,
                                                }));

                                                setFormErrors({});

                                                setGridData((prev) => [
                                                  ...prev,
                                                  {
                                                    Id: uniqueId,
                                                    ...gridData,
                                                    IsActive: true,
                                                  },
                                                ]);

                                                setDisplayGrid((prev) => [
                                                  ...prev,
                                                  {
                                                    Id: uniqueId,
                                                    ...gridData,
                                                    IsActive: true,
                                                  },
                                                ]);

                                                if (
                                                  selectedFilesForSet.length > 0
                                                ) {
                                                  let setfiles = [];
                                                  let SelectFileswithoutId =
                                                    selectedFilesForSet.filter(
                                                      (ite) =>
                                                        ite.Id == 0 ||
                                                        ite.Id == ""
                                                    );
                                                  setfiles = JSON.parse(
                                                    JSON.stringify(
                                                      SelectFileswithoutId
                                                    )
                                                  );

                                                  setfiles.forEach((obj) => {
                                                    obj.Id = uniqueId;
                                                    obj.DCVALUE = value?.label;
                                                  });

                                                  setfiles.map((pbj) => {
                                                    finalSetAdddr.push(pbj);
                                                  });
                                                }
                                              }
                                            );

                                            let remaining =
                                              selectedFilesForSet.filter(
                                                (ite) =>
                                                  ite.Id != 0 && ite.Id != ""
                                              );
                                            setSelectedFilesForSet(
                                              (prevFiles) => [
                                                ...remaining,
                                                ...finalSetAdddr,
                                              ]
                                            );
                                          }
                                        }
                                      } else if (
                                        typeof attributeSetData["PA"] ===
                                          "object" &&
                                        Array.isArray(
                                          attributeSetData["PA"]
                                        ) === true
                                      ) {
                                        if (
                                          section.SN === "AddressInformation"
                                        ) {
                                          let key = "PA";
                                          const isError = validateData(
                                            eachAttribute.Attribute
                                          );
                                          if (!isError) {
                                            if (key !== "") {
                                              for (
                                                let i = 0;
                                                i <
                                                attributeSetData["PA"].length;
                                                i++
                                              ) {
                                                //
                                                let attributed =
                                                  attributeSetData["PA"][i];
                                                let filteredEmail =
                                                  ogFilledAttributeSet.filter(
                                                    (og) => {
                                                      return (
                                                        og.PA?.label ===
                                                          attributed.label &&
                                                        og.IsActive === true
                                                      );
                                                    }
                                                  );
                                                //if (!isError) {

                                                //           }
                                                //);
                                                if (filteredEmail.length > 0) {
                                                  notify(
                                                    1,
                                                    attributed.label +
                                                      " Address Type Already Exist, Can't Insert Another."
                                                  );
                                                  return;
                                                }
                                              }
                                              let finalSetAdddr = [];
                                              attributeSetData[`${key}`].map(
                                                (value, indx) => {
                                                  const uniqueId = uuid();

                                                  //validation for empty data before adding in grid
                                                  let isError = false;
                                                  // if (indx === 0) {
                                                  //   isError = validateData(
                                                  //     eachAttribute.Attribute
                                                  //   );
                                                  // }

                                                  if (!isError) {
                                                    //add record in grid
                                                    // if()

                                                    let gridData = {};
                                                    let ogf = {};
                                                    eachAttribute.Attribute.forEach(
                                                      async (subAttribute) => {
                                                        if (
                                                          typeof attributeSetData[
                                                            subAttribute.AC
                                                          ] === "object" &&
                                                          Array.isArray(
                                                            attributeSetData[
                                                              subAttribute.AC
                                                            ]
                                                          ) === true
                                                        ) {
                                                          gridData = {
                                                            ...gridData,
                                                            [subAttribute.AC]:
                                                              value.label
                                                                ? value.label
                                                                : null, //for MultiSelect Dropdownn
                                                          };
                                                          ogf = {
                                                            ...ogf,
                                                            [subAttribute.AC]:
                                                              value,
                                                          };
                                                          if (
                                                            value.label ===
                                                            "Permanent"
                                                          ) {
                                                            gridData = {
                                                              ...gridData,
                                                              ["ADRTYP"]: null, //for date and dropdown
                                                            };
                                                            ogf = {
                                                              ...ogf,
                                                              ["ADRTYP"]: null,
                                                              ["LSD"]: null,
                                                              ["LED"]: null,
                                                            };
                                                          }
                                                        } else if (
                                                          typeof attributeSetData[
                                                            subAttribute.AC
                                                          ] === "object"
                                                        ) {
                                                          gridData = {
                                                            ...gridData,
                                                            [subAttribute.AC]:
                                                              attributeSetData[
                                                                subAttribute.AC
                                                              ]?.label || null, //for date and dropdown
                                                          };
                                                          ogf = {
                                                            ...ogf,
                                                            [subAttribute.AC]:
                                                              attributeSetData[
                                                                subAttribute.AC
                                                              ],
                                                          };
                                                          if (
                                                            value.label ===
                                                            "Permanent"
                                                          ) {
                                                            gridData = {
                                                              ...gridData,
                                                              ["ADRTYP"]: null, //for date and dropdown
                                                            };
                                                            ogf = {
                                                              ...ogf,
                                                              ["ADRTYP"]: null,
                                                              ["LSD"]: null,
                                                              ["LED"]: null,
                                                            };
                                                          }
                                                        } else if (
                                                          typeof attributeSetData[
                                                            subAttribute.AC
                                                          ] === "boolean"
                                                        ) {
                                                          gridData = {
                                                            ...gridData,
                                                            [subAttribute.AC]:
                                                              attributeSetData[
                                                                subAttribute.AC
                                                              ] === true
                                                                ? "true"
                                                                : "false",
                                                          };
                                                          ogf = {
                                                            ...ogf,
                                                            ["ADRTYP"]: null,
                                                            ["LSD"]: null,
                                                            ["LED"]: null,
                                                          };
                                                        } else {
                                                          gridData = {
                                                            ...gridData,
                                                            [subAttribute.AC]:
                                                              attributeSetData[
                                                                subAttribute.AC
                                                              ],
                                                          };
                                                          ogf = {
                                                            ...ogf,
                                                            [subAttribute.AC]:
                                                              attributeSetData[
                                                                subAttribute.AC
                                                              ],
                                                          };
                                                          if (
                                                            value.label ===
                                                            "Permanent"
                                                          ) {
                                                            gridData = {
                                                              ...gridData,
                                                              ["ADRTYP"]: null, //for date and dropdown
                                                            };
                                                            ogf = {
                                                              ...ogf,
                                                              ["ADRTYP"]: null,
                                                              ["LSD"]: null,
                                                              ["LED"]: null,
                                                            };
                                                          }
                                                        }
                                                      }
                                                    );
                                                    // if(subAttribute.AC===""){
                                                    //   attributeSetData[subAttribute.AC]
                                                    // }

                                                    setOgFilledAttributeSet(
                                                      (prev) => [
                                                        ...prev,
                                                        {
                                                          ...ogf,
                                                          Id: uniqueId,
                                                          IsActive: true,
                                                        },
                                                      ]
                                                    );
                                                    //reset
                                                    setAttributeSetData(
                                                      (prev) => ({
                                                        ...prev,
                                                        ...ogAttributeSet,
                                                      })
                                                    );

                                                    setFormErrors({});

                                                    setGridData((prev) => [
                                                      ...prev,
                                                      {
                                                        Id: uniqueId,
                                                        ...gridData,
                                                        IsActive: true,
                                                      },
                                                    ]);

                                                    setDisplayGrid((prev) => [
                                                      ...prev,
                                                      {
                                                        Id: uniqueId,
                                                        ...gridData,
                                                        IsActive: true,
                                                      },
                                                    ]);

                                                    if (
                                                      selectedFilesForSet.length >
                                                      0
                                                    ) {
                                                      let setfiles = [];
                                                      let SelectFileswithoutId =
                                                        selectedFilesForSet.filter(
                                                          (ite) =>
                                                            ite.Id == 0 ||
                                                            ite.Id == ""
                                                        );
                                                      setfiles = JSON.parse(
                                                        JSON.stringify(
                                                          SelectFileswithoutId
                                                        )
                                                      );

                                                      setfiles.forEach(
                                                        (obj) => {
                                                          obj.Id = uniqueId;
                                                          obj.DCVALUE =
                                                            value?.label;
                                                        }
                                                      );

                                                      setfiles.map((pbj) => {
                                                        finalSetAdddr.push(pbj);
                                                      });
                                                    }
                                                  }
                                                }
                                              );
                                              let remaining =
                                                selectedFilesForSet.filter(
                                                  (ite) =>
                                                    ite.Id != 0 && ite.Id != ""
                                                );
                                              setSelectedFilesForSet(
                                                (prevFiles) => [
                                                  ...remaining,
                                                  ...finalSetAdddr,
                                                ]
                                              );
                                            }
                                          }
                                        }
                                      } else {
                                        const uniqueId = uuid();

                                        //validation for empty data before adding in grid
                                        //
                                        const isError = validateData(
                                          eachAttribute.Attribute
                                        );
                                        if (section.SN === "BankInformation") {
                                          //
                                          if (!isError) {
                                            await validateBankAccAdhar();
                                          }
                                        }
                                        if (
                                          section.SN === "NationalIDInformation"
                                        ) {
                                          if (!isError) {
                                            //national bank check for blacklisted employee
                                            const nationalBankCheck =
                                              await APICall(
                                                GetNationalIDBlacklistValidation,
                                                "POST",
                                                {
                                                  PERIDNM:
                                                    attributeSetData["PERIDNM"],
                                                  EID: TId,
                                                }
                                              );
                                            if (
                                              nationalBankCheck &&
                                              nationalBankCheck?.data &&
                                              nationalBankCheck?.data?.length >
                                                0
                                            ) {
                                              notify(
                                                1,
                                                `The entered National ID No.: ${
                                                  attributeSetData["PERIDNM"]
                                                } belongs to ${
                                                  nationalBankCheck?.data?.[0]
                                                    ?.employeeCode
                                                } - ${
                                                  nationalBankCheck?.data?.[0]
                                                    ?.fn
                                                }. This individual is blacklisted with Welspun for Rehire Employement. ${
                                                  nationalBankCheck?.data?.[0]
                                                    ?.hrmanagername
                                                    ? "Please contact the HR Manager: " +
                                                      nationalBankCheck
                                                        ?.data?.[0]
                                                        ?.hrmanagername +
                                                      "."
                                                    : ""
                                                }`
                                              );
                                              return null;
                                            }
                                            await validateNationalID();
                                          }
                                        }
                                        // if (
                                        //   !isError &&
                                        //   validAccount.current === true &&
                                        //   section.SN === "BankInformation"
                                        // ) {
                                        //   //add record in grid
                                        //   setOgFilledAttributeSet((prev) => [
                                        //     ...prev,
                                        //     {
                                        //       ...attributeSetData,
                                        //       Id: uniqueId,
                                        //       IsActive: true,
                                        //     },
                                        //   ]);

                                        // Confirmation Vlidation for Duplicate ID Card entry for inactive user or in active entry
                                        if (
                                          !isError &&
                                          validAccount.current === true &&
                                          section.SN === "NationalIDInformation"
                                        ) {
                                          //Check Same Type eg. 2 entries of PAN Card
                                          let duplicateTDType = [];
                                          duplicateTDType =
                                            ogFilledAttributeSet.filter(
                                              (og) => {
                                                return (
                                                  og.IDCATY?.label ===
                                                    attributeSetData["IDCATY"]
                                                      ?.label &&
                                                  og.IsActive === true &&
                                                  og.Id != currentGridId
                                                );
                                              }
                                            );
                                          if (duplicateTDType.length > 0) {
                                            notify(
                                              1,
                                              `${attributeSetData["IDCATY"].label} ID card Type Already Exist, Can't Insert Another.`
                                            );
                                            return;
                                          }
                                          //add record in grid
                                          setOgFilledAttributeSet((prev) => [
                                            ...prev,
                                            {
                                              ...attributeSetData,
                                              Id: uniqueId,
                                              IsActive: true,
                                            },
                                          ]);

                                          let gridData = {};

                                          eachAttribute.Attribute.forEach(
                                            async (subAttribute) => {
                                              if (
                                                typeof attributeSetData[
                                                  subAttribute.AC
                                                ] === "object"
                                              ) {
                                                gridData = {
                                                  ...gridData,
                                                  [subAttribute.AC]:
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ]?.label || null, //for date and dropdown
                                                };
                                              } else if (
                                                typeof attributeSetData[
                                                  subAttribute.AC
                                                ] === "boolean"
                                              ) {
                                                gridData = {
                                                  ...gridData,
                                                  [subAttribute.AC]:
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ] === true
                                                      ? "true"
                                                      : "false",
                                                };
                                              } else {
                                                gridData = {
                                                  ...gridData,
                                                  [subAttribute.AC]:
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ],
                                                };
                                              }
                                            }
                                          );

                                          //reset
                                          setAttributeSetData((prev) => ({
                                            ...prev,
                                            ...ogAttributeSet,
                                          }));

                                          setFormErrors({});

                                          setNewGridIdCreated(uniqueId); //send uniqueId for document

                                          setGridData((prev) => [
                                            ...prev,
                                            {
                                              Id: uniqueId,
                                              ...gridData,
                                              IsActive: true,
                                            },
                                          ]);

                                          setDisplayGrid((prev) => [
                                            ...prev,
                                            {
                                              Id: uniqueId,
                                              ...gridData,
                                              IsActive: true,
                                            },
                                          ]);
                                        }

                                        if (
                                          !isError &&
                                          validAccount.current === true &&
                                          section.SN === "BankInformation"
                                        ) {
                                          //add record in grid
                                          setOgFilledAttributeSet((prev) => [
                                            ...prev,
                                            {
                                              ...attributeSetData,
                                              Id: uniqueId,
                                              IsActive: true,
                                            },
                                          ]);

                                          let gridData = {};

                                          eachAttribute.Attribute.forEach(
                                            async (subAttribute) => {
                                              if (
                                                typeof attributeSetData[
                                                  subAttribute.AC
                                                ] === "object"
                                              ) {
                                                gridData = {
                                                  ...gridData,
                                                  [subAttribute.AC]:
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ]?.label || null, //for date and dropdown
                                                };
                                              } else if (
                                                typeof attributeSetData[
                                                  subAttribute.AC
                                                ] === "boolean"
                                              ) {
                                                gridData = {
                                                  ...gridData,
                                                  [subAttribute.AC]:
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ] === true
                                                      ? "true"
                                                      : "false",
                                                };
                                              } else {
                                                gridData = {
                                                  ...gridData,
                                                  [subAttribute.AC]:
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ],
                                                };
                                              }
                                            }
                                          );

                                          //reset
                                          setAttributeSetData((prev) => ({
                                            ...prev,
                                            ...ogAttributeSet,
                                          }));

                                          setFormErrors({});

                                          setNewGridIdCreated(uniqueId); //send uniqueId for document

                                          setGridData((prev) => [
                                            ...prev,
                                            {
                                              Id: uniqueId,
                                              ...gridData,
                                              IsActive: true,
                                            },
                                          ]);

                                          setDisplayGrid((prev) => [
                                            ...prev,
                                            {
                                              Id: uniqueId,
                                              ...gridData,
                                              IsActive: true,
                                            },
                                          ]);
                                        }
                                        //}
                                        if (
                                          !isError &&
                                          section.SN != "BankInformation" &&
                                          section.SN != "NationalIDInformation"
                                        ) {
                                          //add record in grid
                                          //Formal Education Duplicate Entry
                                          // if (
                                          //   section.SN === "FormalEducation"
                                          // ) {
                                          //   let Duplicate = [];
                                          //   Duplicate =
                                          //     ogFilledAttributeSet.filter(
                                          //       (og) => {
                                          //         return (
                                          //           og.QULIFCN?.label ===
                                          //             attributeSetData[
                                          //               "QULIFCN"
                                          //             ].label &&
                                          //           og.IsActive === true &&
                                          //           og.Id != currentGridId
                                          //         );
                                          //       }
                                          //     );
                                          //   if (Duplicate.length > 0) {
                                          //     notify(
                                          //       1,
                                          //       `Qualification ${attributeSetData["QULIFCN"].label} Already Exist, Can't Insert Another.`
                                          //     );
                                          //     return;
                                          //   }
                                          // }
                                          // Nomination Details Vlidation
                                          // Nomination type 'Full & Final' to have only one Record of 100% share
                                          if (section.SN === "NomineeDetails") {
                                            let filteredData;
                                            if (
                                              attributeSetData["TYOFNOM"]
                                                .label === "Full & Final"
                                            ) {
                                              //Check if % share is 100
                                              if (
                                                attributeSetData["NOMSHAR"] !=
                                                "100"
                                              ) {
                                                notify(
                                                  1,
                                                  `Nomination type 'Full & Final' should have share as 100.`
                                                );
                                                return;
                                              }

                                              // check if record already exist
                                              filteredData =
                                                ogFilledAttributeSet.filter(
                                                  (og) => {
                                                    return (
                                                      og.TYOFNOM?.label ===
                                                        attributeSetData[
                                                          "TYOFNOM"
                                                        ].label &&
                                                      og.IsActive === true &&
                                                      og.Id !== currentGridId
                                                    );
                                                  }
                                                );

                                              if (filteredData.length > 0) {
                                                notify(
                                                  1,
                                                  `${attributeSetData["TYOFNOM"].label} already exists, can't insert another.`
                                                );
                                                return;
                                              }
                                            }
                                          }
                                          // Nomination Details Vlidation Ends
                                          if (
                                            !isError &&
                                            section.SN === "Email Information"
                                          ) {
                                            let filteredEmail = [];
                                            if (
                                              attributeSetData["ETP"].label ===
                                              "Business"
                                            ) {
                                              filteredEmail =
                                                ogFilledAttributeSet.filter(
                                                  (og) => {
                                                    return (
                                                      og.ETP?.label ===
                                                        attributeSetData["ETP"]
                                                          .label &&
                                                      og.IsActive === true
                                                    );
                                                  }
                                                );
                                            } else {
                                              filteredEmail =
                                                ogFilledAttributeSet.filter(
                                                  (og) => {
                                                    return (
                                                      og.ETP?.label ===
                                                        attributeSetData["ETP"]
                                                          .label &&
                                                      og.EA ===
                                                        attributeSetData[
                                                          "EA"
                                                        ] &&
                                                      og.IsActive === true
                                                    );
                                                  }
                                                );
                                            }

                                            if (filteredEmail.length > 0) {
                                              notify(
                                                1,
                                                attributeSetData["ETP"].label +
                                                  " Email Details Already Exist, Can't Insert Another."
                                              );
                                              return;
                                            }
                                          }
                                          /*Hardcode to if Eligible for stipend then not to able to add FP or VP*/
                                          if (
                                            !isError &&
                                            section.SN === "Compensation"
                                          ) {
                                            let Duplicate = [];
                                            Duplicate =
                                              ogFilledAttributeSet.filter(
                                                (og) => {
                                                  return (
                                                    og.PCE?.label ===
                                                      attributeSetData["PCE"]
                                                        .label &&
                                                    og.IsActive === true
                                                  );
                                                }
                                              );
                                            if (Duplicate.length > 0) {
                                              notify(
                                                1,
                                                `${attributeSetData["PCE"].label} Already Exist, Can't Insert Another.`
                                              );
                                              return;
                                            }
                                            debugger;
                                            let SelectedPayComponent = [];
                                            ogFilledAttributeSet.map((og) => {
                                              if (
                                                og.IsActive === true &&
                                                og.PCE?.label !== "CCA"
                                              ) {
                                                SelectedPayComponent.push(
                                                  og.PCE?.label
                                                );
                                              }
                                            });

                                            if (
                                              SelectedPayComponent.length > 0
                                            ) {
                                              if (
                                                SelectedPayComponent.includes(
                                                  "Stipend"
                                                )
                                              ) {
                                                if (
                                                  attributeSetData["PCE"]
                                                    .label != "Stipend"
                                                ) {
                                                  notify(
                                                    1,
                                                    `You are already have entry for stipend so can not have ${
                                                      Country ===
                                                        "CODECNTRY1" ||
                                                      COU === "CODECNTRY1"
                                                        ? "fixed Pay or variable pay"
                                                        : "Base Pay"
                                                    } as pay component.`
                                                  );
                                                  return;
                                                }
                                              } else {
                                                debugger;
                                                if (
                                                  attributeSetData["PCE"]
                                                    .label === "Stipend"
                                                ) {
                                                  notify(
                                                    1,
                                                    `You are already have entry for ${
                                                      Country ===
                                                        "CODECNTRY1" ||
                                                      COU === "CODECNTRY1"
                                                        ? "fixed Pay or variable pay"
                                                        : "Base Pay"
                                                    } so can not have stipend as pay component.`
                                                  );
                                                  return;
                                                }
                                              }
                                              if (
                                                SelectedPayComponent.length ===
                                                  0 &&
                                                ogFilledAttributeSet.length > 0
                                              ) {
                                                notify(
                                                  1,
                                                  `You are already have entry for ${
                                                    Country === "CODECNTRY1" ||
                                                    COU === "CODECNTRY1"
                                                      ? "fixed Pay or variable pay"
                                                      : "Base Pay"
                                                  } so can not have stipend as pay component.`
                                                );
                                                return;
                                              }
                                            }
                                            ogFilledAttributeSet.map((og) => {
                                              if (
                                                og.IsActive === true &&
                                                og.PCE?.label !== "CCA"
                                              ) {
                                                SelectedPayComponent.push(
                                                  og.PCE?.label
                                                );
                                              }
                                            });

                                            if (
                                              SelectedPayComponent.length > 0
                                            ) {
                                              if (
                                                SelectedPayComponent.includes(
                                                  "Stipend"
                                                )
                                              ) {
                                                if (
                                                  attributeSetData["PCE"]
                                                    .label != "Stipend"
                                                ) {
                                                  notify(
                                                    1,
                                                    `You are already have entry for stipend so can not have ${
                                                      Country ===
                                                        "CODECNTRY1" ||
                                                      COU === "CODECNTRY1"
                                                        ? "fixed Pay or variable pay"
                                                        : "Base Pay"
                                                    } as pay component.`
                                                  );
                                                  return;
                                                }
                                              } else {
                                                if (
                                                  attributeSetData["PCE"]
                                                    .label === "Stipend"
                                                ) {
                                                  notify(
                                                    1,
                                                    `You are already have entry for ${
                                                      Country ===
                                                        "CODECNTRY1" ||
                                                      COU === "CODECNTRY1"
                                                        ? "fixed Pay or variable pay"
                                                        : "Base Pay"
                                                    } so can not have stipend as pay component.`
                                                  );
                                                  return;
                                                }
                                              }
                                              if (
                                                SelectedPayComponent.length ===
                                                  0 &&
                                                ogFilledAttributeSet.length > 0
                                              ) {
                                                notify(
                                                  1,
                                                  `You are already have entry for ${
                                                    Country === "CODECNTRY1" ||
                                                    COU === "CODECNTRY1"
                                                      ? "fixed Pay or variable pay"
                                                      : "Base Pay"
                                                  } so can not have stipend as pay component.`
                                                );
                                                return;
                                              }
                                            }
                                          }
                                          /*Hardcode to if Eligible for stipend then not to able to add FP or VP*/

                                          /*Harcode For Contact Information check From Here*/
                                          if (
                                            section.SN === "ContactInformation"
                                          ) {
                                            let filteredContacts =
                                              ogFilledAttributeSet.filter(
                                                (og) => {
                                                  return (
                                                    og.IP2?.code ===
                                                      attributeSetData.IP2
                                                        .code &&
                                                    og.IP2?.code ===
                                                      "CODEISPMRY2" &&
                                                    og.IsActive === true
                                                  );
                                                }
                                              );
                                            if (filteredContacts.length > 0) {
                                              notify(
                                                1,
                                                "Primary Contact Already Exist, Can't Insert Another."
                                              );
                                              return;
                                            }
                                          }
                                          /*Harcode For Contact Information check Till Here*/

                                          if (
                                            section.SN === "AddressInformation"
                                          ) {
                                          }
                                          setOgFilledAttributeSet((prev) => [
                                            ...prev,
                                            {
                                              ...attributeSetData,
                                              Id: uniqueId,
                                              IsActive: true,
                                            },
                                          ]);

                                          let gridData = {};

                                          eachAttribute.Attribute.forEach(
                                            async (subAttribute) => {
                                              if (
                                                typeof attributeSetData[
                                                  subAttribute.AC
                                                ] === "object"
                                              ) {
                                                gridData = {
                                                  ...gridData,
                                                  [subAttribute.AC]:
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ]?.label || null, //for date and dropdown
                                                };
                                              } else if (
                                                typeof attributeSetData[
                                                  subAttribute.AC
                                                ] === "boolean"
                                              ) {
                                                gridData = {
                                                  ...gridData,
                                                  [subAttribute.AC]:
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ] === true
                                                      ? "true"
                                                      : "false",
                                                };
                                              } else {
                                                gridData = {
                                                  ...gridData,
                                                  [subAttribute.AC]:
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ],
                                                };
                                              }
                                            }
                                          );

                                          //reset
                                          setAttributeSetData((prev) => ({
                                            ...prev,
                                            ...ogAttributeSet,
                                          }));

                                          setFormErrors({});

                                          setNewGridIdCreated(uniqueId); //send uniqueId for document

                                          setGridData((prev) => [
                                            ...prev,
                                            {
                                              Id: uniqueId,
                                              ...gridData,
                                              IsActive: true,
                                            },
                                          ]);

                                          setDisplayGrid((prev) => [
                                            ...prev,
                                            {
                                              Id: uniqueId,
                                              ...gridData,
                                              IsActive: true,
                                            },
                                          ]);
                                        }
                                        //}
                                      }
                                    } else {
                                      //update

                                      //validation for empty data before updating in grid
                                      const isError = validateData(
                                        eachAttribute.Attribute
                                      );
                                      if (!isError) {
                                        if (section.SN === "BankInformation") {
                                          if (!isError) {
                                            await validateBankAccAdhar();
                                          }
                                        }
                                        if (
                                          section.SN === "NationalIDInformation"
                                        ) {
                                          if (!isError) {
                                            //national bank check for blacklisted employee
                                            const nationalBankCheck =
                                              await APICall(
                                                GetNationalIDBlacklistValidation,
                                                "POST",
                                                {
                                                  PERIDNM:
                                                    attributeSetData["PERIDNM"],
                                                  EID: TId,
                                                }
                                              );
                                            if (
                                              nationalBankCheck &&
                                              nationalBankCheck?.data &&
                                              nationalBankCheck?.data?.length >
                                                0
                                            ) {
                                              notify(
                                                1,
                                                `The entered National ID No.: ${
                                                  attributeSetData["PERIDNM"]
                                                } belongs to ${
                                                  nationalBankCheck?.data?.[0]
                                                    ?.employeeCode
                                                } - ${
                                                  nationalBankCheck?.data?.[0]
                                                    ?.fn
                                                }. This individual is blacklisted with Welspun for Rehire Employement. ${
                                                  nationalBankCheck?.data?.[0]
                                                    ?.hrmanagername
                                                    ? "Please contact the HR Manager: " +
                                                      nationalBankCheck
                                                        ?.data?.[0]
                                                        ?.hrmanagername +
                                                      "."
                                                    : ""
                                                }`
                                              );
                                              return null;
                                            }
                                            await validateNationalID();
                                          }
                                        }
                                        if (
                                          !isError &&
                                          validAccount.current === true &&
                                          section.SN === "NationalIDInformation"
                                        ) {
                                          //Check Same Type eg. 2 entries of PAN Card
                                          debugger;
                                          let duplicateTDType = [];
                                          duplicateTDType =
                                            ogFilledAttributeSet.filter(
                                              (og) => {
                                                return (
                                                  og.IDCATY?.label ===
                                                    attributeSetData["IDCATY"]
                                                      ?.label &&
                                                  og.IsActive === true &&
                                                  og.Id !== currentGridId
                                                );
                                              }
                                            );
                                          if (duplicateTDType.length > 0) {
                                            notify(
                                              1,
                                              `${attributeSetData["IDCATY"].label} ID card Type Already Exist, Can't Insert Another.`
                                            );
                                            return;
                                          }
                                          //update record
                                          setOgFilledAttributeSet((prev) => {
                                            const newState = prev.map(
                                              (record) => {
                                                if (
                                                  record.Id === currentGridId
                                                ) {
                                                  return {
                                                    ...attributeSetData,
                                                  };
                                                }
                                                return record;
                                              }
                                            );
                                            return newState;
                                          });

                                          let gridData = {};

                                          eachAttribute.Attribute.forEach(
                                            async (subAttribute) => {
                                              if (
                                                typeof attributeSetData[
                                                  subAttribute.AC
                                                ] === "object"
                                              ) {
                                                gridData = {
                                                  ...gridData,
                                                  [subAttribute.AC]:
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ]?.label || null, //for date and dropdown
                                                };
                                              } else if (
                                                typeof attributeSetData[
                                                  subAttribute.AC
                                                ] === "boolean"
                                              ) {
                                                gridData = {
                                                  ...gridData,
                                                  [subAttribute.AC]:
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ] === true
                                                      ? "true"
                                                      : "false",
                                                };
                                              } else {
                                                gridData = {
                                                  ...gridData,
                                                  [subAttribute.AC]:
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ],
                                                };
                                              }
                                            }
                                          );

                                          //reset
                                          setAttributeSetData((prev) => ({
                                            ...prev,
                                            ...ogAttributeSet,
                                          }));

                                          setFormErrors({});

                                          setGridData((prev) => {
                                            const newState = prev.map(
                                              (record) => {
                                                if (
                                                  record.Id === currentGridId
                                                ) {
                                                  return {
                                                    Id: currentGridId,
                                                    ...gridData,
                                                  };
                                                }
                                                return record;
                                              }
                                            );
                                            return newState;
                                          });

                                          setDisplayGrid((prev) => {
                                            const newState = prev.map(
                                              (record) => {
                                                if (
                                                  record.Id === currentGridId
                                                ) {
                                                  return {
                                                    Id: currentGridId,
                                                    ...gridData,
                                                  };
                                                }
                                                return record;
                                              }
                                            );
                                            return newState;
                                          });

                                          setCurrentGridId(null);
                                        }

                                        if (
                                          !isError &&
                                          validAccount.current === true &&
                                          section.SN === "BankInformation"
                                        ) {
                                          setOgFilledAttributeSet((prev) => {
                                            const newState = prev.map(
                                              (record) => {
                                                if (
                                                  record.Id === currentGridId
                                                ) {
                                                  return {
                                                    ...attributeSetData,
                                                  };
                                                }
                                                return record;
                                              }
                                            );
                                            return newState;
                                          });

                                          let gridData = {};

                                          eachAttribute.Attribute.forEach(
                                            async (subAttribute) => {
                                              if (
                                                typeof attributeSetData[
                                                  subAttribute.AC
                                                ] === "object"
                                              ) {
                                                gridData = {
                                                  ...gridData,
                                                  [subAttribute.AC]:
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ]?.label || null, //for date and dropdown
                                                };
                                              } else if (
                                                typeof attributeSetData[
                                                  subAttribute.AC
                                                ] === "boolean"
                                              ) {
                                                gridData = {
                                                  ...gridData,
                                                  [subAttribute.AC]:
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ] === true
                                                      ? "true"
                                                      : "false",
                                                };
                                              } else {
                                                gridData = {
                                                  ...gridData,
                                                  [subAttribute.AC]:
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ],
                                                };
                                              }
                                            }
                                          );

                                          //reset
                                          setAttributeSetData((prev) => ({
                                            ...prev,
                                            ...ogAttributeSet,
                                          }));

                                          setFormErrors({});

                                          setGridData((prev) => {
                                            const newState = prev.map(
                                              (record) => {
                                                if (
                                                  record.Id === currentGridId
                                                ) {
                                                  return {
                                                    Id: currentGridId,
                                                    ...gridData,
                                                  };
                                                }
                                                return record;
                                              }
                                            );
                                            return newState;
                                          });

                                          setDisplayGrid((prev) => {
                                            const newState = prev.map(
                                              (record) => {
                                                if (
                                                  record.Id === currentGridId
                                                ) {
                                                  return {
                                                    Id: currentGridId,
                                                    ...gridData,
                                                  };
                                                }
                                                return record;
                                              }
                                            );
                                            return newState;
                                          });

                                          setCurrentGridId(null);
                                        }

                                        if (
                                          !isError &&
                                          section.SN != "BankInformation" &&
                                          section.SN != "NationalIDInformation"
                                        ) {
                                          //update

                                          //Address Validation On update
                                          if (
                                            section.SN === "AddressInformation"
                                          ) {
                                            let Duplicate = [];
                                            Duplicate =
                                              ogFilledAttributeSet.filter(
                                                (og) => {
                                                  return (
                                                    og.PA?.label ===
                                                      attributeSetData["PA"]
                                                        .label &&
                                                    og.IsActive === true &&
                                                    og.Id != currentGridId
                                                  );
                                                }
                                              );
                                            if (Duplicate.length > 0) {
                                              notify(
                                                1,
                                                `Address type ${attributeSetData["PA"].label} Already Exist, Can't Insert Another.`
                                              );
                                              return;
                                            }
                                          }
                                          //Address Validation On update

                                          //Formal Education Duplicate Entry
                                          // if (
                                          //   section.SN === "FormalEducation"
                                          // ) {
                                          //   let Duplicate = [];
                                          //   Duplicate =
                                          //     ogFilledAttributeSet.filter(
                                          //       (og) => {
                                          //         return (
                                          //           og.QULIFCN?.label ===
                                          //             attributeSetData[
                                          //               "QULIFCN"
                                          //             ].label &&
                                          //           og.IsActive === true &&
                                          //           og.Id != currentGridId
                                          //         );
                                          //       }
                                          //     );
                                          //   if (Duplicate.length > 0) {
                                          //     notify(
                                          //       1,
                                          //       `Qualification ${attributeSetData["QULIFCN"].label} Already Exist, Can't Insert Another.`
                                          //     );
                                          //     return;
                                          //   }
                                          // }
                                          // Nomination Details Vlidation
                                          // Nomination type 'Full & Final' to have only one Record of 100% share
                                          if (section.SN === "NomineeDetails") {
                                            let filteredData;
                                            if (
                                              attributeSetData["TYOFNOM"]
                                                .label === "Full & Final"
                                            ) {
                                              //Check if % share is 100
                                              if (
                                                attributeSetData["NOMSHAR"] !=
                                                "100"
                                              ) {
                                                notify(
                                                  1,
                                                  `Nomination type 'Full & Final' should have share as 100.`
                                                );
                                                return;
                                              }

                                              // check if record already exist
                                              filteredData =
                                                ogFilledAttributeSet.filter(
                                                  (og) => {
                                                    return (
                                                      og.TYOFNOM?.label ===
                                                        attributeSetData[
                                                          "TYOFNOM"
                                                        ].label &&
                                                      og.IsActive === true &&
                                                      og.Id !== currentGridId
                                                    );
                                                  }
                                                );

                                              if (filteredData.length > 0) {
                                                notify(
                                                  1,
                                                  `${attributeSetData["TYOFNOM"].label} already exists, can't insert another.`
                                                );
                                                return;
                                              }
                                            }
                                          }
                                          // Nomination Details Vlidation End
                                          if (
                                            section.SN === "Email Information"
                                          ) {
                                            let filteredEmail;
                                            if (
                                              attributeSetData["ETP"].label ===
                                              "Business"
                                            ) {
                                              filteredEmail =
                                                ogFilledAttributeSet.filter(
                                                  (og) => {
                                                    return (
                                                      og.ETP?.label ===
                                                        attributeSetData["ETP"]
                                                          .label &&
                                                      og.IsActive === true &&
                                                      og.Id !==
                                                        attributeSetData.Id
                                                    );
                                                  }
                                                );
                                            } else {
                                              filteredEmail =
                                                ogFilledAttributeSet.filter(
                                                  (og) => {
                                                    return (
                                                      og.ETP?.label ===
                                                        attributeSetData["ETP"]
                                                          .label &&
                                                      og.EA ===
                                                        attributeSetData[
                                                          "EA"
                                                        ] &&
                                                      og.IsActive === true &&
                                                      og.Id !==
                                                        attributeSetData.Id
                                                    );
                                                  }
                                                );
                                            }
                                            if (filteredEmail.length > 0) {
                                              notify(
                                                1,
                                                attributeSetData["ETP"].label +
                                                  " Email Type Already Exist, Can't Insert Another."
                                              );
                                              return;
                                            }
                                          }
                                          /*Harcode For Contact Information check From Here*/
                                          if (
                                            section.SN === "ContactInformation"
                                          ) {
                                            let filteredContacts =
                                              ogFilledAttributeSet.filter(
                                                (og) => {
                                                  return (
                                                    og.IP2?.code ===
                                                      attributeSetData.IP2
                                                        ?.code &&
                                                    attributeSetData.IP2
                                                      ?.code ===
                                                      "CODEISPMRY2" &&
                                                    og.IsActive === true &&
                                                    og.Id != attributeSetData.Id
                                                  );
                                                }
                                              );
                                            if (filteredContacts.length > 0) {
                                              notify(
                                                1,
                                                "Primary Contact Already Exist, Can't Insert Another."
                                              );
                                              return;
                                            }
                                          }
                                          /*Harcode For Contact Information check Till Here*/
                                          setOgFilledAttributeSet((prev) => {
                                            const newState = prev.map(
                                              (record) => {
                                                if (
                                                  record.Id === currentGridId
                                                ) {
                                                  return {
                                                    ...attributeSetData,
                                                  };
                                                }
                                                return record;
                                              }
                                            );
                                            return newState;
                                          });

                                          let gridData = {};

                                          eachAttribute.Attribute.forEach(
                                            async (subAttribute) => {
                                              if (
                                                typeof attributeSetData[
                                                  subAttribute.AC
                                                ] === "object"
                                              ) {
                                                gridData = {
                                                  ...gridData,
                                                  [subAttribute.AC]:
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ]?.label || null, //for date and dropdown
                                                };
                                              } else if (
                                                typeof attributeSetData[
                                                  subAttribute.AC
                                                ] === "boolean"
                                              ) {
                                                gridData = {
                                                  ...gridData,
                                                  [subAttribute.AC]:
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ] === true
                                                      ? "true"
                                                      : "false",
                                                };
                                              } else if (
                                                typeof attributeSetData[
                                                  subAttribute.AC
                                                ] === "string" &&
                                                moment(
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ],
                                                  "DD-MMM-YYYY",
                                                  true
                                                ).isValid()
                                              ) {
                                                const formattedDate = moment(
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ],
                                                  "DD-MMM-YYYY"
                                                ).format("DD/MM/YYYY");

                                                gridData = {
                                                  ...gridData,
                                                  [subAttribute.AC]:
                                                    formattedDate,
                                                };
                                              } else {
                                                gridData = {
                                                  ...gridData,
                                                  [subAttribute.AC]:
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ],
                                                };
                                              }
                                            }
                                          );

                                          //reset
                                          setAttributeSetData((prev) => ({
                                            ...prev,
                                            ...ogAttributeSet,
                                          }));

                                          setFormErrors({});

                                          setGridData((prev) => {
                                            const newState = prev.map(
                                              (record) => {
                                                if (
                                                  record.Id === currentGridId
                                                ) {
                                                  return {
                                                    Id: currentGridId,
                                                    ...gridData,
                                                  };
                                                }
                                                return record;
                                              }
                                            );
                                            return newState;
                                          });

                                          setDisplayGrid((prev) => {
                                            const newState = prev.map(
                                              (record) => {
                                                if (
                                                  record.Id === currentGridId
                                                ) {
                                                  return {
                                                    Id: currentGridId,
                                                    ...gridData,
                                                  };
                                                }
                                                return record;
                                              }
                                            );
                                            return newState;
                                          });

                                          setCurrentGridId(null);
                                          //handleEffectiveDatePrefill();
                                        }
                                      }
                                    }
                                    handleEffectiveDatePrefill();
                                  }}
                                >
                                  {currentGridId === null
                                    ? "Save"
                                    : "Update Record"}
                                </button>
                              )}
                            </div>
                          )}

                          <div className="col-lg-12 p-0 mb-3">
                            <DynamicGrid
                              options={gridOptions}
                              data={displayGrid}
                              columns={gridColumns}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </React.Fragment>
                ))}
            </div>
          </AccordionDetails>
        </Accordion>
      ) : null}
      <Modal
        show={showConfirmationModal}
        onHide={() => closeConfirmationModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">Confirm!</div>
        </div>
        <Modal.Body>
          <h5>{modalHeader}</h5>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-success"
            style={{ marginLeft: 5 }}
            onClick={() => closeConfirmationModal(true)}
          >
            Yes
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => closeConfirmationModal(false)}
          >
            No
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const CandidateForm = () => {
  const [formData, setFormData] = useState<any>({});
  const { state } = useLocation();
  const [tabs, setTabs] = useState([]);
  const [globalValues, setGlobalValues] = useState([]);
  const [activetab, setActivetab] = useState<string>(
    state !== null && state?.ActiveTab === "Documents" ? "Documents" : null
  );
  const [currentSectionIdForProgression, setCurrentSectionIdForProgression] =
    useState<any>(null);
  const [taskIdForSendback, setTaskIdForSendback] = useState<any>(0);
  const [activetabIndex, setActivetabIndex] = useState<number>(0);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [TId, setTId] = useState((state !== null && state.TId) || 0);

  const [sendbackForEss, setSendbackForEss] = useState(
    (state !== null && state.SendbackForEss) || false
  );
  const [sectionForEss, setsectionForEss] = useState(
    (state !== null && state.SectionForEss) || 0
  );
  const [candidateWorkflowTaskId, setCandidateWorkflowTaskId] = useState(
    (state !== null && state.CandidateWorkflowTaskId) || 0
  );
  const [candidateEdit, setCandidateEdit] = useState(
    (state !== null && state.isEdit) || false
  );
  const [changeWflTaskSts, setChangeWflTaskSts] = useState(
    state !== null && state?.DecisionSystemName == "NotStarted" ? true : false
  );

  //console.log("State::::", state);
  // const [TId, setTId] = useState(36);
  const [COU, setCOU] = useState((state !== null && state.COU) || "");
  const [country, setCountry] = useState((state !== null && state.CT) || "");
  const [headerValues, setHeaderValues] = useState<any>(null);
  const [LEheaderValues, setLEHeaderValues] = useState<any>(null);
  const [sectionValuesArray, setSectionValuesArray] = useState<any>([]);
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  const navigate = useNavigate();

  const [profileImgToUpload, setProfileImgToUpload] = useState<any>(null);

  const [showProfileModal, setShowProfileModal] = useState(false);

  const [profileImg, setProfileImg] = useState(null);
  const [Gridcount, setGridcount] = useState(0);
  const [Gridpage, setGridpage] = useState(0);
  const [GridsortDirection, setGridSortDirection] = useState("");
  const [GridsortColumn, setGridsortColumn] = useState("");
  const [DocGridData, setDocGridData] = useState<any>([]);
  const [GridPageSize, setGridPageSize] = useState(10);
  const [GridsearchText, setGridSearchText] = useState("");

  const [event, setEvent] = useState(null);
  const [eventReason, setEventReason] = useState(null);
  const [effectiveDate, setEffectiveDate] = useState(null);

  const [eventOption, setEventOption] = useState([]);
  const [eventReasonOption, setEventReasonOption] = useState([]);

  const [moduleOption, setModuleOption] = useState([]);
  const [module, setModule] = useState([]);
  //const [flagForNoticePeriods, setFlagForNoticePeriods] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  // const [hideShow, setHideShow] = useState(false);

  // const [taskStatus, setTaskStatus] = useState("Pending");

  const [saveButtonDisable, setsaveButtonDisable] = useState(
    state !== null && state?.NewHireStatus === "Sendback" ? true : false
  );

  const [hideShow, setHideShow] = useState(
    state !== null && state?.NewHireStatus === "Approved"
      ? //|| state?.ActiveTab === "Documents"
        true
      : false
  );

  const [showDocSection, setShowDocSection] = useState(false);
  const [taskStatus, setTaskStatus] = useState(
    state !== null && state?.NewHireStatus ? state?.NewHireStatus : "Pending"
  );

  const [isNewHire, setIsNewHire] = useState(
    taskStatus === "Sendback" || taskStatus === "Pending" ? true : false
  );
  const [workflowSubmitDissable, setWorkflowSubmitDissable] = useState(false);

  const [candidateSubmitDissable, setCandidateSubmitDissable] = useState(
    state !== null && state?.isEdit == false ? true : false
  );
  const [candidateSubmitAgain, setCandidateSubmitAgain] = useState(false);
  const [submitAgainPromise, setSubmitAgainPromise] = useState(null);

  const [workflowTaskId, setWorkflowTaskId] = useState(
    state !== null && state?.WorkflowTaskId > 0 ? state?.WorkflowTaskId : 0
  );

  const [previousConversation, setPreviousConversation] = useState("");

  const [showProgressionModal, setShowProgressionModal] = useState(false);
  const [showSendbackModal, setShowSendbackModal] = useState(false);

  const [showSectionHistoryModal, setShowSectionHistoryModal] = useState(false);

  const [currentSectionId, setCurrentSectionId] = useState(0);

  const [ishrmanagernotify, setIsHrManagerNotify] = useState(false);
  const [showDocumentModal, setShowDocumentModal] = useState(false);

  const handleCloseModal = () => {
    setShowDocumentModal(false);
  };

  const closeSubmitAgainModal = async (confirmation) => {
    if (submitAgainPromise) {
      if (confirmation) {
        submitAgainPromise.resolve(true);
      } else {
        submitAgainPromise.resolve(false);
      }

      setCandidateSubmitAgain(false);
      setSubmitAgainPromise(null);
    }
  };

  const DownloadButton = async () => {
    const url = require("../../Assets/Document/Get_Started_With_Smallpdf.pdf");
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Get_Started_With_Smallpdf.pdf"); // Specify the file name to be downloaded
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const viewSectionHistory = (data) => {
    setShowSectionHistoryModal(true);
    setCurrentSectionId(data.sectionDetails.SId);
  };

  const DocGridCols = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    // {
    //   name: "rowNum",
    //   label: "Sr No.",
    //   options: {
    //     display: true,
    //     sort: false,
    //   },
    // },
    {
      name: "moduleName",
      label: "Module",
      options: {
        display: true,
        sort: true,
        filter: false,
        sortDescFirst: false,
      },
    },
    {
      name: "fileName",
      label: "File Name",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: false,
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: false,
      },
    },
    {
      name: "context",
      label: "Context",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: false,
      },
    },
    {
      name: "dcvalue",
      label: "Identifier",
      options: {
        filter: false,
        sort: true,
      },
    },

    {
      name: "createdDateUtc",
      label: "Uploaded On",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <span>{moment(value).format("DD-MM-YYYY")}</span>
            </>
          );
        },
      },
    },
    {
      name: "createdByName",
      label: "Uploaded By",
      options: {
        filter: false,
        sort: false,
      },
    },
    // {
    //   name: "dvtill",
    //   label: "Valid Till",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     sortDescFirst: false,
    //     customBodyRender: (value, tableMeta) => {
    //       return (
    //         <>
    //           {value != null && (
    //             <span>{moment(value).format("DD-MM-YYYY")}</span>
    //           )}
    //         </>
    //       );
    //     },
    //   },
    // },
    // {
    //   name: "dstatus",
    //   label: "Document Status",
    //   options: {
    //     filter: false,
    //     sort: false,
    //   },
    // },
    // {
    //   name: "dpsince",
    //   label: "Pending Since",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     customBodyRender: (value, tableMeta) => {
    //       return (
    //         <>
    //           <span>-</span>
    //         </>
    //       );
    //     },
    //   },
    // },
    // {
    //   name: "dpwith",
    //   label: "pending With",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (value, tableMeta) => {
    //       return (
    //         <>
    //           <span>-</span>
    //         </>
    //       );
    //     },
    //   },
    // },
    {
      name: "attributeCode",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <a
                onClick={() => {
                  handleDownloadFileFlat(
                    value,
                    tableMeta.rowData[0],
                    tableMeta.rowData[2]
                  );
                }}
                style={{ marginLeft: "30px" }}
              >
                <i className="fa-solid fa-download" aria-hidden="true"></i>
              </a>
              {/* <a onClick={() => {}} style={{ marginLeft: "30px" }}>
                <i className="fa fa-pencil" aria-hidden="true"></i>
              </a> */}
            </>
          );
        },
      },
    },
  ];

  const getDocConfigUrl = async () => {
    const response = await APICall(GetConfigValueByKey, "POST", {
      Key: "WelspunDMSAPILink",
    });

    if (response?.status === 0) {
      return response.data;
    } else {
      return null;
    }
  };

  const gridOptions2 = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: Gridcount,
    rowsPerPage: GridPageSize,
    page: Gridpage,
    serverSide: true,
    onChangePage: async (page) => {
      setGridpage(page * GridPageSize);
    },
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",
    onColumnSortChange: async (sortColumn, sortDirection) => {
      setGridpage(0);
      if (sortDirection === "asc") {
        await setGridsortColumn(sortColumn);
        await setGridSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setGridsortColumn(sortColumn);
        await setGridSortDirection(sortDirection);
      }
    },
  };

  const fileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleDownloadFileFlat = async (AC, DocId, fileName) => {
    showLoader();
    if (DocId != undefined && DocId != null && DocId != 0) {
      const doc = await APICall(downloadDocument, "POST", {
        TId,
        AC: AC,
        DocId: DocId,
      });
      if (doc && doc.data !== null) {
        downloadBase64File(doc.data, fileName);
        hideLoader();
      } else {
        notify(1, "Something Went Wrong.");
        hideLoader();
      }
    }
  };
  function downloadBase64File(base64String: string, fileName: string) {
    const mimeType = inferMimeType(base64String);
    const blob = base64toBlob(base64String, mimeType);

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  }
  function inferMimeType(base64String: string): string {
    const dataUrl = `data:application/octet-stream;base64,${base64String}`;
    const typeInfo = /^data:(.*?);/.exec(dataUrl);

    if (typeInfo && typeInfo[1]) {
      return typeInfo[1];
    }

    return "application/octet-stream";
  }
  function base64toBlob(base64String: string, mimeType: string): Blob {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], { type: mimeType });
  }
  const getDocumentsGridData = async () => {
    let moduleId = [];
    module.map((item) => {
      moduleId.push(item.value);
    });
    showLoader();
    setDocGridData([]); //imp
    let bodyObj = {
      PageNumber: Gridpage,
      PageSize: GridPageSize,
      SearchText: GridsearchText,
      ModuleId: moduleId.toString(),
      UserId: proxyUserDetails?.EmployeeId
        ? proxyUserDetails?.EmployeeId
        : userDetails?.Id,
      //RoleId: userDetails?.UserRoles[0]?.RoleId,
      SortColumn: GridsortColumn,
      SortOrder: GridsortDirection,
      EID: TId,
      //COU: COU,
    };

    const response = await APICall(getDocumentGridData, "POST", bodyObj);
    if (response.data !== null && response.data.length > 0) {
      setDocGridData(response.data);
      setGridcount(response.data[0].count);
    } else {
      setDocGridData([]);
      setGridcount(0);
    }

    hideLoader();
  };

  useEffect(() => {
    //if (Gridcount != 0)
    getDocumentsGridData();
  }, [Gridpage, GridsortDirection, GridsortColumn, GridsearchText, module]);

  const handleImageSelect = async (e) => {
    const file = e.target.files[0];
    let fileBase64: { Name: string; Data: string } = null;
    const base64 = (await fileToBase64(file)) as string;
    const base64WithoutPrefix = base64.split(",")[1];
    fileBase64 = { Name: file.name, Data: base64WithoutPrefix };

    const validExtensions = ["png", "jpg", "jpeg"];
    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();
      const fileSize = file.size;
      const maxFileSizeBytes = 5 * 1024 * 1024;

      // Check if the file extension is allowed
      if (
        validExtensions.includes(fileExtension) &&
        fileSize <= maxFileSizeBytes
      ) {
        // file to upload
        setProfileImgToUpload(fileBase64);
        //setProfileImg(`data:image;base64,${fileBase64.Data}`);
      } else {
        notify(1, "Only file with png,jpg,jpeg allowed and size under 5MB");
        e.target.value = "";
        setProfileImgToUpload(null);
      }
    }
  };
  //Api to delete Profile Image
  const deleteProfileImage = async () => {
    if (profileImg != null) {
      showLoader();
      const doc = await APICall(deleteDocument, "POST", {
        TId,
        AC: "ProfilePic",
        DocId: "0",
        UserId: proxyUserDetails?.EmployeeId
          ? proxyUserDetails?.EmployeeId
          : userDetails.UserId,
      });
      if (doc.message === "Success") {
        setProfileImg(null);
        setShowProfileModal(false);
        setProfileImgToUpload(null);
        hideLoader();
      } else {
        notify(1, "File not Deleted, Something Went Wrong.");
        hideLoader();
      }
    }
  };

  // API call to upload the image
  const handleImageUpload = async () => {
    if (profileImgToUpload) {
      showLoader();
      let reqObj = {
        file: {
          AC: "ProfilePic",
          Name: profileImgToUpload.Name,
          Data: profileImgToUpload.Data,
        },
        TId,
        SectionName: "Basic",
        ModuleName: "EmployeeCentral",
        TabName: "Personal",
        ModuleId: 1,
        UserId: proxyUserDetails?.EmployeeId
          ? proxyUserDetails?.EmployeeId
          : userDetails.UserId,
        ProxyUserId: proxyUserDetails === null ? null : proxyUserDetails?.value,
      };

      try {
        const response = await APICall(UploadProfile, "POST", reqObj);
        if (response && response.data) {
          setProfileImg(`data:image;base64,${response.data.base64}`);
          setShowProfileModal(false);
          hideLoader();
        } else {
          console.error("Image upload failed");
          hideLoader();
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        hideLoader();
      }
    } else {
      notify(1, "Please Select Image to Upload.");
    }
  };

  //Tab api here
  useEffect(() => {
    if (state.TId === 0 && TId === 0) {
    }
  }, []);

  useEffect(() => {
    (async () => {
      await showLoader();
      let tabs = await APICall(getTabsData, "POST", {
        MN: "EmployeeCentral",
        IN: "EC_Form",
        Country: TId > 0 ? country : COU,
      });

      if (tabs.data !== null && tabs.data.length > 0) {
        if (!hideShow) {
          tabs.data = tabs.data?.filter(
            (item) => item.TDN === "Personal" //|| item.TDN === "Employment"
          );
        }

        setTabs(tabs.data);
        if (state.ActiveTab != null && state.ActiveTab != undefined) {
          setActivetab(state.ActiveTab);
        } else {
          setActivetab(tabs.data[0].TN);
        }
      } else {
        console.log("No tabs found!", tabs);
        setTabs([]);
      }
      await hideLoader();
    })();
  }, []);

  //getImage API
  useEffect(() => {
    if (
      activetab !== null &&
      (activetab === "Personal" || activetab === "Documnets")
    ) {
      (async () => {
        await showLoader();
        const getPhoto = await APICall(getProfilePicImg, "POST", {
          TId,
          AC: "ProfilePic",
        });

        if (getPhoto && getPhoto.data !== null) {
          setProfileImg(`data:image;base64,${getPhoto.data}`);
        }
        await hideLoader();
      })();
    }
  }, [activetab]);

  //section api here
  useEffect(() => {
    //get section
    if (activetab !== null) {
      setSectionValuesArray([]); //imp
      (async () => {
        await showLoader();
        const section = await APICall(getSectionDetailsV2, "POST", {
          MN: "EmployeeCentral",
          IN: "EC_Form",
          TN: activetab,
          UID: proxyUserDetails?.EmployeeId
            ? proxyUserDetails?.value
            : userDetails.Id,
          TID: TId,
          RoleId: currentRoleId?.value,
          //COU:TId==0?COU:userDetails.COU,
          COU: COU,
          IsNewHire: isNewHire,
          CandidateForm: true,
        });

        if (
          section.data !== null &&
          section.data.t !== undefined &&
          section.data.t.tn === activetab
        ) {
          if (!hideShow && section.data?.t?.S.length > 0) {
            let sectionArray = section.data?.t?.S?.filter(
              (item) => item.ISEDIT === true && item.ISView === true
              // (item) => item.ISEDIT === true || item.ISView === true
            );
            section.data.t.S = sectionArray;
          }
          setFormData(section.data.t);
        } else {
          setFormData({});
        }

        await hideLoader();
      })();
    }
  }, [activetab]);

  //check this
  useEffect(() => {
    if (
      activetab &&
      // state !== null &&
      TId > 0
      // &&
      // activetab != "Documents"
    ) {
      setSectionValuesArray([]); //imp
      (async () => {
        showLoader();
        let bodyObj = {
          mn: "EmployeeCentral",
          in: "EC_Form",
          tn: activetab === "Documents" ? "Personal" : activetab,
          SN: "Basic",
          TID: TId,
          ISH: "true",
          UserId: proxyUserDetails?.EmployeeId
            ? proxyUserDetails?.EmployeeId
            : userDetails?.Id,
          RoleId: currentRoleId?.value,
          COU: COU,
        };

        const response = await APICall(
          getAttributeValueDetailsV2,
          "POST",
          bodyObj
        );

        const LEDetails = await APICall(
          GetLegalEntityDetailsCandidate,
          "POST",
          { CID: state.CandidateWorkflowTaskId }
        );

        if (LEDetails?.data) {
          setLEHeaderValues(LEDetails.data[0]);
        } else {
          setLEHeaderValues(null);
        }

        if (response.data !== null && response.data.headerValues !== null) {
          setHeaderValues(response.data.headerValues);
        } else {
          setHeaderValues(null);
        }
        if (activetab === "Documents") {
          if (response.data.sections && response.data.sections.length > 0) {
            setSectionValuesArray(response.data.sections);
          } else {
            setSectionValuesArray([]);
          }
          (async () => {
            await getDocumentsGridData();
          })();
        } else {
          if (response.data.sections && response.data.sections.length > 0) {
            let respValue = response.data.sections;

            respValue.map((e) => {
              if (
                e.sectionSystemName === "JobRelationships" &&
                e.attributes[0].details.length > 0
              ) {
                let respdetailsArray = e.attributes[0].details;
                const findHr = respdetailsArray.find(
                  (att) => att.RELSHTYP == "19"
                );

                if (findHr === undefined) {
                  setIsHrManagerNotify(true);
                } else {
                  setIsHrManagerNotify(false);
                }
              } else if (
                e.sectionSystemName === "JobRelationships" &&
                e.attributes[0].details.length == 0
              ) {
                setIsHrManagerNotify(true);
              }
            });
            setSectionValuesArray(response.data.sections);
          } else {
            setSectionValuesArray([]);
          }
        }

        hideLoader();
      })();
    }
    // if (activetab === "Documents") {
    //   (async () => {
    //     await getDocumentsGridData();
    //   })();
    // }
    if (state.TId > 0) {
      (async () => {
        await showLoader();
        const globalValues = await APICall(getGlobalValues, "POST", {
          TID: state.TId,
        });

        if (globalValues.data !== null && globalValues.data.length > 0) {
          setGlobalValues(globalValues.data);
        }
        await hideLoader();
      })();
    }
  }, [activetab, TId]);

  const defaultTheme = createTheme({});
  const { breakpoints } = defaultTheme;

  const tabsTheme = {
    ...defaultTheme,
    components: {
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: "0px !important",
            marginLeft: "-42px",
            [breakpoints.down("md")]: {
              minHeight: "0px !important",
              marginLeft: "0px",
            },
          },
        },
      },
    },
  };

  useEffect(() => {
    (async () => await getWorkflowTypeDataAPICall())();
    (async () => await getEventAndEventResonDataByTIdAPICall())();
    (async () => await getWorkflowTasksDataByIdAPICall())();
    (async () => await getModuleFilterDropdownApiCall())();
  }, []);

  const getEventAndEventResonDataByTIdAPICall = async () => {
    if (TId !== null && TId > 0) {
      const response = await APICall(getEventAndEventResonDataByTId, "POST", {
        TId: TId,
      });

      if (response?.data) {
        const data = response?.data;

        if (data.newHireEventId > 0 && data.newHireEventReasonId > 0) {
          await getWorkflowSubTypeDataByWorkflowTypeIdAPICall(
            data.newHireEventId
          );

          const eventObj = {
            value: data.newHireEventId,
            label: data.newHireEventName,
          };

          const eventResonObj = {
            value: data.newHireEventReasonId,
            label: data.newHireEventReasonName,
          };

          setEvent(eventObj);
          setEventReason(eventResonObj);
          setEffectiveDate(data.newHireEffectiveDate);
        }
      }
    }
  };

  //No use for this function here, for now(30/11/2023)
  const onChangeFunction = (event) => {
    setEvent(event);

    setEventReason(null);

    getWorkflowSubTypeDataByWorkflowTypeIdAPICall(event?.value);
  };

  const getWorkflowSubTypeDataByWorkflowTypeIdAPICall = async (
    workflowTypeId
  ) => {
    const response = await APICall(
      getWorkflowSubTypeDataByWorkflowTypeId,
      "POST",
      {
        WorkflowTypeId: workflowTypeId,
      }
    );

    if (response.data && response.data.length > 0) {
      setEventReasonOption(response.data);
    } else {
      setEventReasonOption([]);
    }
  };

  const getWorkflowTypeDataAPICall = async () => {
    const response = await APICall(getWorkflowTypeData, "POST", {});

    if (response.data && response.data.length > 0) {
      setEventOption(response.data);
    } else {
      setEventOption([]);
    }
  };
  // There is a module filter in document tab
  const getModuleFilterDropdownApiCall = async () => {
    const response = await APICall(GetModuleDropdown, "POST", {});

    if (response.data && response.data.length > 0) {
      setModuleOption(response.data);
    } else {
      setModuleOption([]);
    }
  };

  const handleSubmitCandidateButton = async () => {
    try {
      setCandidateSubmitDissable(true);
      let SubmitA = true;

      if (state?.DecisionSystemName == "Completed") {
        SubmitA = await new Promise((resolve, reject) => {
          setCandidateSubmitAgain(true);
          setSubmitAgainPromise({ resolve, reject });
        });
      }
      if (profileImg?.length > 0) {
        if (SubmitA) {
          await showLoader();
          const response = await APICall(checkMandatorySections, "POST", {
            TId: TId,
            COU: country,
          });

          if (response?.status == 0) {
            const respComp = await APICall(updateCandidateTasks, "POST", {
              CandidateWorkflowTaskId: candidateWorkflowTaskId,
              Decision: "ToCompleted",
            });
            if (respComp.status == 0) {
              console.log(respComp.status, "Task Updated to Complete!");
            } else {
              console.log(respComp.status, "Task NOT Updated to Complete!");
            }

            const resp = await APICall(GetDocUploadTasks, "POST", {
              CandidateWorkflowTaskId: candidateWorkflowTaskId,
            });

            const responseURL = await APICall(GetConfigValueByKey, "POST", {
              Key: "WelspunDMSAPILink",
            });

            let DMSURL = responseURL.data;
            let taskArray = resp.data;

            const userDocDetailsResp = await APICall(
              GetEmployeeDetailsForDocGeneration,
              "POST",
              {
                Id: userDetails?.Id,
                candidateWorkflowTaskId: candidateWorkflowTaskId,
              }
            );

            for (let i = 0; i < taskArray.length; i++) {
              let docTypeCode = "";
              if (taskArray[i].task === "Joining Report") {
                docTypeCode = "JOIN_REPORT";
              } else if (taskArray[i].task === "Consent on Biometric System ") {
                docTypeCode = "BIOMETRIC";
              } else if (
                taskArray[i].task === "Group Term Life Insurance Form "
              ) {
                docTypeCode = "TERM_INS";
              } else if (
                taskArray[i].task === "Group Personal Accident  Nomination "
              ) {
                docTypeCode = "POLICY_NOMI";
              } else if (taskArray[i].task === "Email ID Creation ") {
                docTypeCode = "EMAIL_FORM";
              } else if (taskArray[i].task === "Wage Declaration Form ") {
                docTypeCode = "WAGE_FORM";
              } else if (taskArray[i].task === "Form 11 (EPFO) ") {
                docTypeCode = "EPFO_FORM";
              } else if (taskArray[i].task === "Form F (Gratuity) ") {
                docTypeCode = "GRATUITY_FORM";
              } else if (
                taskArray[i].task === "POSH - Prevention of Sexual Harrassment "
              ) {
                docTypeCode = "FORM_POSH";
              } else if (taskArray[i].task === "Application for Employment") {
                docTypeCode = "APPLICATION_FORM";
              }

              let docAttrBody = [];
              docAttrBody = await getBodyAttributesForDocGeneration(
                userDocDetailsResp,
                docTypeCode
              );

              console.log(docTypeCode + ":" + docAttrBody);

              const urlDocGenerated = await APICall(
                `${DMSURL}/GenerateEmployeeDocument`,
                "POST",
                {
                  documenttypecode: docTypeCode,
                  attributes: docAttrBody,
                }
              );
              if (urlDocGenerated.status === 200) {
                let DocId = urlDocGenerated.data;
                if (urlDocGenerated.data) {
                  const updateDocId = await APICall(
                    UpdateGeneratedDocId,
                    "POST",
                    {
                      CandidateWorkflowTaskId:
                        taskArray[i].candidateWorkflowTaskId,
                      DocId: DocId,
                    }
                  );
                }
              }
            }

            notify(response?.status, "Your Response has been Recorded!");
            navigate("/home");
          } else {
            notify(response?.status, response?.message);
            setCandidateSubmitDissable(false);
          }
        } else {
          setCandidateSubmitDissable(false);
        }
      } else {
        notify(1, `Please upload profile photo.`);
        setCandidateSubmitDissable(false);
      }

      await hideLoader();
    } catch {
      await hideLoader();
      //notify(1, "Catch Error");
      setCandidateSubmitDissable(false);
    }
  };

  const getBodyAttributesForDocGeneration = async (
    userDocDetailsResp,
    DocType
  ) => {
    let bodyAttributes = [];

    let userDocDetails = userDocDetailsResp ? userDocDetailsResp.data : null;
    console.log(userDocDetails);

    //Provident Fund -- CODETYPONMNTN1
    //Gratuity -- CODETYPONMNTN2
    //Group Personal Accident -- CODETYPONMNTN3
    //Superannuation -- CODETYPONMNTN4
    //Group Term Life Insurance -- CODETYPONMNTN5
    //General -- CODETYPONMNTN6
    //ESIC -- CODETYPONMNTN7
    //Full & Final -- CODETYPONMNTN8
    let EmployeeNomineesList = [];
    EmployeeNomineesList = userDocDetails.nomineesList;
    let EmployeeNationalIdList = [];
    EmployeeNationalIdList = userDocDetails.nationalIdsList;

    if (userDocDetails) {
      if (DocType === "JOIN_REPORT") {
        let preExp = userDocDetails.previousEmploymentList;
        let yearDiff = 0;
        let lastCompany = preExp.length > 0 ? preExp[0] : [];
        if (preExp.length > 0) {
          for (let i = 0; i < preExp.length; i++) {
            // let fd = moment(preExp[i].fromDate);
            let td = moment(preExp[i].toDate);
            // yearDiff += td.diff(fd, "years");

            if (moment(lastCompany.toDate) < td) {
              lastCompany = preExp[i];
            }
          }
        }

        //"Joining Report"
        bodyAttributes = [
          {
            firstname: userDocDetails.firstName,
            lastname: userDocDetails.lastName,
            middlename: userDocDetails.middleName,
            bdoj: userDocDetails.bdoj,
            bdojbold: userDocDetails.bdoj,
            payrolllocation: userDocDetails.payrollLocation,
            presentaddress: userDocDetails.presentAddress,
            permenentaddress: "",
            phonenumber: userDocDetails.phoneNumber,
            permanentphone: "",
            dateofbirth: userDocDetails.dob,
            educationalqualification: userDocDetails.educationalQualification,
            maritalstatus: userDocDetails.maritalStatus,
            dateofmarriage: userDocDetails.maritalStatusSince,
            bloodgroup: userDocDetails.bloodGroup,
            previousexperience: userDocDetails.experienceYears || null,
            jobrelatedexperience: userDocDetails.experienceYears || null,
            nameoflastcompany: lastCompany.employerName || "",
            addressoflastcompany: lastCompany.employerAddress || "",
            positionheld: userDocDetails.positionHeld,
            previouspfaccountno: userDocDetails.previousPFAccountNo,
            emergencycontactno: userDocDetails.emergencyContactNo,
            date: moment().format("DD-MM-YYYY"),
            employeecode: userDocDetails.employeeCode,
            nameofcompany: userDocDetails.leWithoutCode,
            designation: userDocDetails.designation,
            department: userDocDetails.department,
            location: userDocDetails.postingLocation,
            locationbold: userDocDetails.postingLocation,
            legalentity: userDocDetails.leWithoutCode,
            dateofjoining: userDocDetails.bdoj,
            signername: userDocDetails.signerName,
          },
        ];
      } else if (DocType === "BIOMETRIC" || DocType === "FORM_POSH") {
        //Consent on Biometric System
        //POSH - Prevention of Sexual Harrassment
        bodyAttributes = [
          {
            firstname: userDetails.FirstName,
            lastname: userDetails.LastName,
            middlename: userDetails.MiddleName ? userDetails.MiddleName : "",
            currentdate: moment().format("DD-MM-YYYY"),
            date: moment().format("DD-MM-YYYY"),
            signername: `${userDetails.FirstName} ${
              userDetails.MiddleName ? userDetails.MiddleName : ""
            } ${userDetails.LastName}`,
          },
        ];
      } else if (DocType === "POLICY_NOMI" || DocType === "TERM_INS") {
        //Group Personal Accident  Nomination
        //Group Term Life Insurance Form

        let Nominees = [];
        for (let i = 0; i < EmployeeNomineesList.length; i++) {
          if (
            DocType === "POLICY_NOMI" &&
            EmployeeNomineesList[i].nomineeTypeCode === "CODETYPONMNTN3"
          ) {
            Nominees.push(EmployeeNomineesList[i]);
          } else if (
            DocType === "TERM_INS" &&
            EmployeeNomineesList[i].nomineeTypeCode === "CODETYPONMNTN5"
          ) {
            Nominees.push(EmployeeNomineesList[i]);
          }
        }
        let LEN = "";
        if (DocType === "POLICY_NOMI") {
          LEN = userDocDetails.leWithoutCode;
        } else if (DocType === "TERM_INS") {
          LEN = userDocDetails.legalEntity;
        }

        bodyAttributes = [
          {
            firstname: userDocDetails.firstName,
            lastname: userDocDetails.lastName,
            middlename: userDocDetails.middleName,
            currentdate: moment().format("DD-MM-YYYY"),
            signername: userDocDetails.signerName,
            date: moment().format("DD-MM-YYYY"),
            company: userDocDetails.business,
            employeecode: userDocDetails.employeeCode,
            designation: userDocDetails.designation,
            legalentity: LEN,
            function: userDocDetails.function,
            payrolllocation: userDocDetails.payrollLocation,
            offeredctc: userDocDetails.offeredCTC.toString(),
            department: userDocDetails.department,
            nominees: Nominees,
          },
        ];
      } else if (DocType === "WAGE_FORM") {
        //Wage Declaration Form

        let Nominees = [];
        for (let i = 0; i < EmployeeNomineesList.length; i++) {
          if (EmployeeNomineesList[i].nomineeTypeCode === "CODETYPONMNTN8") {
            Nominees.push(EmployeeNomineesList[i]);
          }
        }

        bodyAttributes = [
          {
            firstname: userDocDetails.firstName,
            lastname: userDocDetails.lastName,
            signername: userDocDetails.signerName,
            date: moment().format("DD-MM-YYYY"),
            nomineename: Nominees && Nominees[0].nameOfNominee,
            nomineenamebold: Nominees && Nominees[0].nameOfNominee,
            nomineerelation: Nominees && Nominees[0].relationship,
            nomineerelationbold: Nominees && Nominees[0].relationship,
            nomineeaddress: Nominees && Nominees[0].nomineeAddress,
            nomineeaddressbold: Nominees && Nominees[0].nomineeAddress,
            day: moment().format("D"),
            month: moment().format("MMMM"),
            year: moment().format("YYYY"),
            physicallocation: userDocDetails.postingLocation,
            time: moment().format("HH:mm:ss"),
            witness1name: Nominees[0].witnessNameOne,
            witness1address: Nominees[0].witnessAddressOne,
            witness2name: Nominees[0].witnessNameTwo,
            witness2address: Nominees[0].witnessAddressTwo,
          },
        ];
      } else if (DocType === "EPFO_FORM") {
        //Form 11 (EPFO)

        let Nominees = [];
        for (let i = 0; i < EmployeeNomineesList.length; i++) {
          if (
            DocType === "EPFO_FORM" &&
            EmployeeNomineesList[i].nomineeTypeCode === "CODETYPONMNTN3"
          ) {
            Nominees.push(EmployeeNomineesList[i]);
          }
        }

        let aadharNumber = "";
        let panNo = "";
        for (let i = 0; i < EmployeeNationalIdList.length; i++) {
          if (EmployeeNationalIdList[i].idCode === "IDCardType1") {
            aadharNumber = EmployeeNationalIdList[i].idNumber;
          } else if (EmployeeNationalIdList[i].idCode === "CODEIDCTY1") {
            panNo = EmployeeNationalIdList[i].idNumber;
          }
        }

        bodyAttributes = [
          {
            employeename:
              userDocDetails.firstName + " " + userDocDetails.lastName,
            signername:
              userDocDetails.firstName + " " + userDocDetails.lastName,
            fathername: userDocDetails.fatherName,
            dateofbirth: userDocDetails.dob,
            emailid: userDocDetails.emailId,
            gender: userDocDetails.gender,
            maritalstatus: userDocDetails.maritalStatus,
            phonenumber: userDocDetails.phoneNumber,
            dateofjoining: userDocDetails.bdoj,
            bankaccountnumber: userDocDetails.bankAccountNumber,
            ifsccode: userDocDetails.bankIFSCCode,
            aadharnumber: aadharNumber,
            panno: panNo,
            alreadymemberofpf: userDocDetails.alreadyMemberOfPF,
            alreadymemberofpension: userDocDetails.alreadyMemberOfPension,
            internationalworker: userDocDetails.internationalWorker,
            countryoforigin: userDocDetails.countryOfOrigin,
            passportnumber: userDocDetails.passportNumber,
            passportvalidity: userDocDetails.passportValidTill,
            pfaccountnumber: userDocDetails.previousPFAccountNo,
            place: userDocDetails.postingLocation,
            previousemploymentdetailsunexempted: [
              {
                establishmentname: userDocDetails.establishmentName,
                universalaccountno: userDocDetails.universalAccountNo,
                pfaccountnumber: userDocDetails.previousPFAccountNo,
                dateofjoining: userDocDetails.dateOfJoiningNEXT,
                dateofexit: userDocDetails.dateOfExitNEXT,
                schemecertificateno: userDocDetails.schemeCertificateNoNEXT,
                pponumber: userDocDetails.ppoNumber,
                ncpdays: userDocDetails.ncpDaysNEXT,
              },
            ],
            previousemploymentdetailsexemptedtrusts: [
              {
                trustnameaddress: userDocDetails.trustNameAddress,
                uan: userDocDetails.uan,
                epsaccountnumber: userDocDetails.epsAccountNumber,
                dateofjoining: userDocDetails.dateOfJoiningEXT,
                dateofexit: userDocDetails.dateOfExitEXT,
                schemecertificateno: userDocDetails.schemeCertificateNoEXT,
                ncpdays: userDocDetails.ncpDaysEXT,
              },
            ],
            date: moment().format("DD-MM-YYYY"),
          },
        ];
      } else if (DocType === "GRATUITY_FORM") {
        //Form F (Gratuity)

        let Nominees = [];
        for (let i = 0; i < EmployeeNomineesList.length; i++) {
          if (
            DocType === "GRATUITY_FORM" &&
            EmployeeNomineesList[i].nomineeTypeCode === "CODETYPONMNTN2"
          ) {
            Nominees.push(EmployeeNomineesList[i]);
          }
        }
        bodyAttributes = [
          {
            companyaddress:
              userDocDetails.leWithoutCode +
              ", " +
              userDocDetails.payrollLocation,
            employeename:
              userDocDetails.firstName + " " + userDocDetails.lastName,
            nominees: Nominees,
            gender: userDocDetails.gender,
            religion: userDocDetails.religion,
            maritalstatus: userDocDetails.maritalStatus,
            department: userDocDetails.department,
            ticketno: userDocDetails.employeeCode,
            dateofappointment: userDocDetails.bdoj,
            permenentaddress: userDocDetails.permanentAddress,
            addressvillage: userDocDetails.addressVillage,
            addressthana: userDocDetails.addressThana,
            addresssubdivision: userDocDetails.addressSubDivision,
            addresspostoffice: userDocDetails.addressPostOffice,
            addressdistrict: userDocDetails.addressDistrict,
            addressstate: userDocDetails.addressState,
            signername: userDocDetails.signerName,
            place: userDocDetails.postingLocation,
            date: moment().format("DD-MM-YYYY"),
            witness1name: userDocDetails.witnessOne,
            witness2name: userDocDetails.witnessTwo,
            currentdate: moment().format("DD-MM-YYYY"),
          },
        ];
      } else if (DocType === "EMAIL_FORM") {
        bodyAttributes = [
          {
            firstname: userDocDetails.firstName,
            lastname: userDocDetails.lastName,
            signername: userDocDetails.signerName,
            date: moment().format("DD-MM-YYYY"),
            company: userDocDetails.leWithoutCode,
            employeecode: userDocDetails.employeeCode,
            designation: userDocDetails.designation,
            department: userDocDetails.department,
            bdoj: userDocDetails.bdoj,
            phonenumber: userDocDetails.phoneNumber,
            phoneoffice: userDocDetails.officePhone,
            phoneresidence: userDocDetails.phoneNumber,
            hodname: userDocDetails.hiringManager,
            nameofhr: userDocDetails.hrManager,
            initials: userDocDetails.initials,
            location: userDocDetails.postingLocation,
          },
        ];
      } else if (DocType === "APPLICATION_FORM") {
        let ageYears = moment().diff(
          moment(userDocDetails.dateOfBirthEmployee),
          "years"
        );
        let months = moment()
          .subtract(ageYears, "years")
          .diff(userDocDetails.dateOfBirthEmployee, "months");
        let referrersList = userDocDetails.referrerList;
        bodyAttributes = [
          {
            //photo :profileImg !=null ? profileImg.split(',')[1] : null ,
            photo: userDocDetails.photo,
            standarddesignation: userDocDetails.designation,
            firstname: userDocDetails.firstName,
            lastname: userDocDetails.lastName,
            presentaddress: userDocDetails.presentAddress,
            permanentaddresspin: userDocDetails.permanentAddressPIN,
            presentaddresspin: userDocDetails.presentAddressPIN,
            permanentaddress: userDocDetails.permanentAddress,
            permanentphone: userDocDetails.phoneNumber,
            presentphone: userDocDetails.phoneNumber,
            middlename: userDocDetails.middleName,
            phonenumber: userDocDetails.phoneNumber,
            emailid: userDocDetails.emailId,
            dateofbirth: userDocDetails.dob,
            mothertougue: userDocDetails.motherTongue,
            nationality: userDocDetails.nationality,
            fathername: userDocDetails.fatherName,
            maritalstatus: userDocDetails.maritalStatus,
            age: ageYears.toString(),
            month: months.toString(),
            year: ageYears.toString(),
            religion: userDocDetails.religion,
            familymembers: userDocDetails.familyMembersList,
            welspunreference:
              userDocDetails.referenceList.length > 0 ? "YES" : "NO",
            referencename:
              userDocDetails.referenceList.length > 0
                ? userDocDetails.referenceList[0].name
                : "",
            referencerelation:
              userDocDetails.referenceList.length > 0
                ? userDocDetails.referenceList[0].relation
                : "",
            referenceposition:
              userDocDetails.referenceList.length > 0
                ? userDocDetails.referenceList[0].position
                : "",
            referencedepartment:
              userDocDetails.referenceList.length > 0
                ? userDocDetails.referenceList[0].department
                : "",
            alreadyinterviewed: userDocDetails.alreadyInterviewed, // candidate details
            interviewdetails: userDocDetails.interviewDetails, // candidate details
            prefoeciencyskills: userDocDetails.languageSkillsList,
            height: userDocDetails.height,
            weight: userDocDetails.weight === "0" ? "" : userDocDetails.weight,
            previouscontract: userDocDetails.previousContract, // candidate details
            welspuncontract: userDocDetails.welspunContract, // candidate details
            legalpreceedings: userDocDetails.legalPrecedings, // have you been arrested
            ref1name:
              referrersList && referrersList[0] ? referrersList[0].name : "",
            ref1companyname:
              referrersList && referrersList[0]
                ? referrersList[0].companyName
                : "",
            ref1position:
              referrersList && referrersList[0]
                ? referrersList[0].position
                : "",
            ref1phonenumber:
              referrersList && referrersList[0] ? referrersList[0].phone : "",
            ref2name:
              referrersList && referrersList[1] ? referrersList[1].name : "",
            ref2companyname:
              referrersList && referrersList[1]
                ? referrersList[1].companyName
                : "",
            ref2position:
              referrersList && referrersList[1]
                ? referrersList[1].position
                : "",
            ref2phonenumber:
              referrersList && referrersList[1] ? referrersList[1].phone : "",
            ref3name:
              referrersList && referrersList[2] ? referrersList[2].name : "",
            ref3companyname:
              referrersList && referrersList[2]
                ? referrersList[2].companyName
                : "",
            ref3position:
              referrersList && referrersList[2]
                ? referrersList[2].position
                : "",
            ref3phonenumber:
              referrersList && referrersList[2] ? referrersList[2].phone : "",
            qualification: userDocDetails.educationList,
            previousemployment: userDocDetails.previousEmploymentList,
            place: userDocDetails.postingLocation,
            signername: userDocDetails.signerName,
            date: moment().format("DD-MM-YYYY"),
            domicile: userDocDetails.domicile,
            placeofbirth: userDocDetails.placeOfBirth,
          },
        ];
      }
    }

    return bodyAttributes;
  };

  const [triggerEventError, setTriggerEventError] = useState(false);

  const [showEventError, setShowEventError] = useState(false);

  //   useEffect(() => {
  //     if (showEventError) {
  //       let errorObj = {};

  //       setFormErrors({});

  //       if (!event || Object.keys(event).length <= 0) {
  //         errorObj["error_Event"] = "Required";
  //       } else {
  //         delete errorObj["error_Event"];
  //       }

  //       if (!eventReason || Object.keys(eventReason).length <= 0) {
  //         errorObj["error_EventReasion"] = "Required";
  //       } else {
  //         delete errorObj["error_EventReasion"];
  //       }

  //       if (!effectiveDate) {
  //         errorObj["error_EffectiveDate"] = "Required";
  //       } else {
  //         delete errorObj["error_EffectiveDate"];
  //       }

  //       const isEmpty = Object.values(errorObj).every(
  //         (s) => s === null || s === "" || s === undefined
  //       );

  //       if (Object.keys(errorObj).length > 0 && !isEmpty) {
  //         setFormErrors((err) => ({
  //           ...err,
  //           ...errorObj,
  //         }));
  //       }
  //     }
  //   }, [showEventError]);

  //   useEffect(() => {
  //     if (event && eventReason && effectiveDate) {
  //       setTriggerEventError(false);
  //     } else {
  //       setTriggerEventError(true);
  //     }
  //   }, [event, eventReason, effectiveDate]);

  const getWorkflowTasksDataByIdAPICall = async () => {
    if (workflowTaskId > 0) {
      const response = await APICall(getWorkflowTasksDataById, "POST", {
        WorkflowTaskId: workflowTaskId,
      });

      if (response.data && response.data?.previousConversation) {
        const parseJSON = (jsonString) => {
          try {
            return JSON.parse(jsonString);
          } catch (error) {
            return null;
          }
        };

        const parsePreviousConversation = (previousConversation) => {
          let conversation = "";
          const remarksArray = parseJSON(previousConversation);

          if (remarksArray) {
            remarksArray.map((item) => {
              const Date = item.ModifiedDateUtc
                ? moment.utc(item.ModifiedDateUtc).local().format("DD-MMM-YYYY")
                : "";
              const ApproverName = item.UserName || "";
              const RoleName = item.RoleName || "";
              const Remarks = item.Remarks || "";

              if (Remarks?.trim().length > 0) {
                conversation += `${Date} ${ApproverName} ${RoleName}\n`;
                conversation += `\t\t${Remarks}\n\n`;
              }
            });
          }
          return conversation;
        };

        const previousConversation = parsePreviousConversation(
          response.data?.previousConversation
        );

        if (previousConversation) {
          setPreviousConversation(previousConversation);
        }
      }
    }
  };

  //console.log("Conditions::::", activetab, formData, state, sectionValuesArray);

  return (
    <>
      <div className="container-fluid">
        <div
          className="row prof_bg mb-3 border-bottom"
          style={{ backgroundColor: headerValues ? "#00abe6" : "white" }}
        >
          <div className="container-fluid">
            {headerValues && (
              <div className="row botpad">
                <div className="col-lg-2 col-md-2 col-sm-12 pers_prof">
                  <div className="prof_img">
                    {/* {headerValues && <img src={profileImg} />} */}
                    <img
                      src={
                        profileImg != null ? profileImg : defaultProfileImgPic
                      }
                      alt="Profile"
                    />
                  </div>

                  <a
                    className="edit-profile"
                    onClick={() => setShowProfileModal(true)}
                  >
                    <i className="fas fa-edit"></i>
                  </a>
                </div>

                <div className="col-lg-10 col-md-10 col-sm-12 profData">
                  <div className="name">
                    <h4>
                      {headerValues &&
                        `${headerValues?.FN} ${headerValues?.LN}`}
                    </h4>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12 profDetail">
                      <ul>
                        <li>
                          <Tooltip title="Employee Code">
                            <span className="header">
                              <i className="fa-regular fa-address-card"></i>{" "}
                              {headerValues && <>{headerValues?.Id}</>}
                            </span>
                          </Tooltip>
                        </li>
                        <li>
                          <Tooltip title="Designation">
                            <span className="header">
                              <i className="fa-solid fa-user"></i>{" "}
                              {LEheaderValues &&
                              LEheaderValues?.designation !== null &&
                              LEheaderValues?.designation !== undefined ? (
                                <>{LEheaderValues?.designation}</>
                              ) : (
                                <>{state?.designation}</>
                              )}
                            </span>
                          </Tooltip>
                        </li>
                        <li>
                          <Tooltip title="Legal Entity">
                            <span className="header">
                              <i className="fa-solid fa-briefcase"></i>{" "}
                              {LEheaderValues &&
                              LEheaderValues?.legalEntity !== null &&
                              LEheaderValues?.legalEntity !== undefined ? (
                                <>{LEheaderValues?.legalEntity}</>
                              ) : (
                                <>{state.legalEntity}</>
                              )}
                            </span>
                          </Tooltip>
                        </li>
                        <li>
                          <Tooltip title="Physical Location">
                            <span className="header">
                              <i className="fa-solid fa-location-dot"></i>{" "}
                              {headerValues && headerValues?.PHLOC !== null && (
                                <>{headerValues?.PHLOC}</>
                              )}
                            </span>
                          </Tooltip>
                        </li>
                        <li>
                          <Tooltip title="Function">
                            <span className="header">
                              <i className="fa-solid fa-briefcase"></i>{" "}
                              {headerValues && headerValues?.FUNC !== null && (
                                <>{headerValues?.FUNC}</>
                              )}
                            </span>
                          </Tooltip>
                        </li>
                        <li>
                          <Tooltip title="Job Title">
                            <span className="header">
                              <i className="fa-solid fa-briefcase"></i>{" "}
                              {LEheaderValues &&
                              LEheaderValues?.jobTitle !== null &&
                              LEheaderValues?.jobTitle !== undefined ? (
                                <>{LEheaderValues?.jobTitle}</>
                              ) : (
                                <>{state?.jobTitle}</>
                              )}
                            </span>
                          </Tooltip>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 profDetail">
                      <ul>
                        <li>
                          <Tooltip title="Phone Number">
                            <span className="header">
                              <i className="fa-solid fa-phone"></i>{" "}
                              {headerValues && headerValues.PN != "" && (
                                <>{headerValues.PN}</>
                              )}
                            </span>
                          </Tooltip>
                        </li>
                        <li>
                          <Tooltip title="Business Email">
                            <span className="header">
                              <i className="fa-solid fa-envelope"></i>{" "}
                              {headerValues && headerValues.EA != "" && (
                                <>{headerValues.EA}</>
                              )}
                            </span>
                          </Tooltip>
                        </li>
                        <li>
                          <Tooltip title="Manager">
                            <span className="header">
                              <i className="fas fa-network-wired"></i>{" "}
                              {headerValues && headerValues?.MG !== "" && (
                                <>{headerValues?.MG}</>
                              )}
                            </span>
                          </Tooltip>
                        </li>
                        <li>
                          <Tooltip title="Department">
                            <span className="header">
                              <i className="fa-solid fa-briefcase"></i>{" "}
                              {headerValues && headerValues?.DPT !== "" && (
                                <>{headerValues?.DPT}</>
                              )}
                            </span>
                          </Tooltip>
                        </li>
                        <li>
                          <Tooltip title="Status">
                            <span className="header">
                              <i className="fas fa-tag"></i>
                              {headerValues && headerValues?.IsActive ? (
                                headerValues.NewHireStatus ? (
                                  headerValues.NewHireStatus === "Draft" ? (
                                    <>{headerValues.NewHireStatus}</>
                                  ) : (
                                    <> Active</>
                                  )
                                ) : (
                                  headerValues &&
                                  headerValues?.IsActive === false && (
                                    <> InActive</>
                                  )
                                )
                              ) : null}
                            </span>
                          </Tooltip>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              {headerValues && (
                <div className="col-lg-2 col-md-12 col-sm-12 "></div>
              )}

              <div
                className={
                  headerValues
                    ? "col-lg-10 col-md-12 col-sm-12 "
                    : "col-lg-12 col-md-12 col-sm-12"
                }
              >
                <ThemeProvider theme={tabsTheme}>
                  <Tabs
                    value={activetabIndex}
                    className="profile-tabs"
                    onChange={() => {}}
                    variant="scrollable"
                    TabScrollButtonProps={{
                      style: {
                        color: "black",
                      },
                    }}
                    scrollButtons
                    TabIndicatorProps={{
                      style: { display: "none" },
                    }}
                    allowScrollButtonsMobile
                    aria-label="scrollable tabs"
                  >
                    <div className="tabBtn">
                      <ul>
                        {tabs.length > 0 &&
                          tabs.map((eachTab, index) => (
                            <li key={`${index}-${eachTab.TN}`}>
                              <button
                                onClick={() => {
                                  setSectionValuesArray([]); //imp
                                  setActivetab(eachTab.TN);
                                }}
                                className={
                                  eachTab.TN === activetab
                                    ? "bttn active"
                                    : "bttn border border-primary border-bottom-0"
                                }
                              >
                                {eachTab.TDN}
                              </button>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </Tabs>
                </ThemeProvider>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={showProfileModal}
          onHide={() => setShowProfileModal(false)}
          backdrop="static"
          keyboard={false}
          size="sm"
          centered
        >
          <div
            className="bg-secondary text-white"
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <div className="col-lg-12">Upload Profile Picture!</div>
          </div>
          <Modal.Body>
            <>
              <input
                type="file"
                multiple={false}
                accept={".png,.jpg,.jpeg"}
                onChange={handleImageSelect}
              />
              <div>
                <b>Acceptable image formats:</b> .png, .jpg and .jpeg{" "}
              </div>
              <div>
                {" "}
                <b>Maximum File Size:</b> 5MB{" "}
              </div>
              <div>
                <b>Resolution:</b> 3245 X 3245{" "}
              </div>
            </>
          </Modal.Body>
          <Modal.Footer>
            {profileImg != null && (
              <button
                className="btn btn-primary"
                style={{ marginLeft: 5 }}
                onClick={() => deleteProfileImage()}
              >
                Remove
              </button>
            )}
            <button
              className="btn btn-primary"
              style={{ marginLeft: 5 }}
              onClick={() => handleImageUpload()}
            >
              Upload
            </button>
            <button
              className="btn btn-cancel"
              style={{ marginLeft: 5 }}
              onClick={() => {
                setShowProfileModal(false);
                setProfileImgToUpload(null);
              }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        {/* <div className="SectionSubmit mb-4 clearfix">
          <button
            onClick={() => handleSubmitButton()}
            disabled={false}
            className="btn btn-filter-submit float-right ml-2"
          >
            <i className="fa-solid fa-check"></i> Submit
          </button>
        </div> */}

        <Modal
          show={showProgressionModal}
          onHide={() => setShowProgressionModal(false)}
          dialogClassName="modal-90w"
          backdrop="static"
          keyboard={false}
          centered
        >
          <div
            className="bg-secondary text-white"
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <div className="col-lg-12">Progression</div>
          </div>
          <Modal.Body>
            <EmployeeProgression
              SectionId={currentSectionIdForProgression}
              EId={TId}
              Country={country}
              ActiveTab={activetab}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-danger"
              style={{ marginLeft: 5 }}
              onClick={() => {
                setShowProgressionModal(false);
              }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showSendbackModal}
          onHide={() => setShowSendbackModal(false)}
          dialogClassName="modal-90w"
          backdrop="static"
          keyboard={false}
          centered
        >
          <div
            className="bg-secondary text-white"
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <div className="col-lg-12">Progression Sendback</div>
          </div>
          <Modal.Body>
            <EmployeeSendback TaskId={taskIdForSendback} />
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-danger"
              style={{ marginLeft: 5 }}
              onClick={() => {
                setShowSendbackModal(false);
              }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        {/* section history modal */}
        <Modal
          show={showSectionHistoryModal}
          onHide={() => setShowSectionHistoryModal(false)}
          dialogClassName="modal-90w"
          backdrop="static"
          keyboard={false}
          centered
        >
          <div
            className="bg-secondary text-white"
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <div className="col-lg-12">History</div>
          </div>
          <Modal.Body>
            <ViewHistory TId={TId} sectionId={currentSectionId} />
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-cancel"
              style={{ marginLeft: 5 }}
              onClick={() => {
                setShowSectionHistoryModal(false);
              }}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>

        {/* Submit Again modal */}
        <Modal
          show={candidateSubmitAgain}
          onHide={() => closeSubmitAgainModal(false)}
          backdrop="static"
          keyboard={false}
          size="sm"
          centered
        >
          <div
            className="bg-secondary text-white"
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <div className="col-lg-12">Confirm!</div>
          </div>
          <Modal.Body>
            <h5>
              Are you sure you want to submit the edited details? If Yes, your
              all documents will be over-written as per edited details. You can
              download and preview updated documents.
            </h5>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-success"
              id="Blk"
              style={{ marginLeft: 5, color: "black" }}
              onClick={() => closeSubmitAgainModal(true)}
            >
              Yes
            </button>
            <button
              className="btn btn-cancel"
              style={{ marginLeft: 5 }}
              onClick={() => closeSubmitAgainModal(false)}
            >
              No
            </button>
          </Modal.Footer>
        </Modal>

        <div className="SectionSubmit mb-4 clearfix">
          <a
          // href="../Assets/Document/Get_Started_With_Smallpdf.pdf"
          // download="Get_Started_With_Smallpdf.pdf"
          >
            <button
              disabled={candidateSubmitDissable}
              className="btn btn-filter-submit float-right ml-2"
              onClick={() => {
                setShowDocumentModal(true);
              }}
            >
              Guidelines Document
            </button>
          </a>
          <div>
            {showDocumentModal && (
              <DocumentModal
                showDownloadOption={true}
                documentUrl={require("../../Assets/Document/ONB Process Manual_Candidate.pdf")}
                onClose={handleCloseModal}
                title="Guidelines"
                onDownload={DownloadButton}
              />
            )}
          </div>
          {!hideShow && (
            <>
              {/* <button
              onClick={() => handleSubmitButton()}
              disabled={workflowSubmitDissable}
              className="btn btn-filter-submit float-right ml-2">
              <i className="fa-solid fa-check"></i> Submit
            </button> */}
              <button
                onClick={() => handleSubmitCandidateButton()}
                disabled={candidateSubmitDissable}
                //hidden ={state.DecisionSystemName === "Completed" && state?.isEdit=== false}
                className="btn btn-filter-submit float-right ml-2"
              >
                <i className="fa-solid fa-check"></i> Submit
              </button>
            </>
          )}
          {activetab !== null &&
            activetab === "Documents" &&
            formData.S !== undefined &&
            formData.S.length > 0 &&
            formData.S[0].ISEDIT === true && (
              <button
                onClick={() => {
                  setShowDocSection(!showDocSection);
                }}
                disabled={workflowSubmitDissable}
                className="btn btn-filter-submit float-right ml-2"
              >
                <i className="fa fa-plus"></i> Add Document
              </button>
            )}
          <button
            onClick={() => {
              navigate(-1);
            }}
            disabled={false}
            className="btn btn-secondary float-right"
          >
            <i className="fa-solid fa-arrow-left"></i> Back
          </button>
        </div>

        {/* {!hideShow && (
          <>
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
                <div className="form-group">
                  <label className="col-form-label">Event</label>
                  <sup>*</sup>
                  <SelectForm
                    isClearable
                    isSearchable
                    options={eventOption}
                    placeholder="Select Event"
                    isDisabled={saveButtonDisable}
                    value={event}
                    onChange={(event) => {
                      onChangeFunction(event);
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  <span style={{ color: "red" }}>
                    {formErrors["error_Event"]}
                  </span>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
                <div className="form-group">
                  <label className="col-form-label">Event Reason</label>
                  <sup>*</sup>
                  <SelectForm
                    isClearable
                    isSearchable
                    options={eventReasonOption}
                    placeholder="Select Event Reason"
                    isDisabled={saveButtonDisable}
                    value={eventReason}
                    onChange={(event) => {
                      setEventReason(event);
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  <span style={{ color: "red" }}>
                    {formErrors["error_EventReasion"]}
                  </span>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
                <div className="form-group">
                  <label className="col-form-label">Effective Date</label>
                  <sup>*</sup>
                  <DateForm
                    isDisabled={saveButtonDisable}
                    value={effectiveDate}
                    onChange={(date) => {
                      if (date != null) {
                        setEffectiveDate(moment(date).format("DD-MMM-YYYY"));
                      }
                    }}
                  />
                  <span style={{ color: "red" }}>
                    {formErrors["error_EffectiveDate"]}
                  </span>
                </div>
              </div>
              {previousConversation && (
                <div
                  className={"col-lg-6 col-md-6 col-sm-12 mobile-view top-m"}>
                  <div className="mb-1">
                    <label className="col-form-label">
                      Previous Conversation
                    </label>
                    <InputForm
                      className="form-control"
                      placeholder={"Previous Conversation"}
                      isDisabled={true}
                      textArea={true}
                      value={previousConversation}
                      onChange={(e) => {}}
                      rows={7}
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        )} */}

        {/* section row */}
        {activetab != "Documents" ? (
          <div className="row accordion_grid">
            {formData.S !== undefined &&
              formData.S.length > 0 &&
              ((((state !== null && state.TId === 0) || state === null) &&
                sectionValuesArray.length === 0) ||
                (state !== null &&
                  state.TId !== 0 &&
                  sectionValuesArray.length > 0) ||
                (sectionValuesArray.length > 0 &&
                  TId != 0 &&
                  state.TId === 0)) &&
              formData.S.map((eachSection, index) => (
                <React.Fragment key={`${index}-${eachSection.SN}`}>
                  <>
                    {" "}
                    {/* {
                      eachSection.SN === "OneTimePaymentDeductions"?
                      <>
                        <OneTimePayment
                           formData={formData}
                           setFormData={setFormData}
                           section={eachSection}
                           TId={TId}
                           setTId={setTId}
                           sectionValuesArray={sectionValuesArray}
                           sectionIndex={index}
                        />
                      </>
                      :
                      <> */}
                    {index >= 0 && (
                      <CandidateFormSectionComponent
                        formData={formData}
                        setFormData={setFormData}
                        section={eachSection}
                        sectionIndex={index}
                        TId={TId}
                        setTId={setTId}
                        activetab={activetab}
                        setActivetab={setActivetab}
                        sectionValuesArray={sectionValuesArray}
                        SetSectionValuesArray={setSectionValuesArray}
                        Globalv={globalValues}
                        COU={COU}
                        isNewHire={isNewHire}
                        Country={country}
                        setGridSortDirection={setGridSortDirection}
                        setShowProgressionModal={setShowProgressionModal}
                        triggerEventError={triggerEventError}
                        newHireEvent={event}
                        newHireEventReason={eventReason}
                        newHireEffectiveDate={effectiveDate}
                        setShowEventError={setShowEventError}
                        ishrmanagernotify={ishrmanagernotify}
                        // setCurrentSectionIdForProgression = {setCurrentSectionIdForProgression}
                        // setTaskIdForSendback = {setTaskIdForSendback}
                        // setShowSendbackModal = {setShowSendbackModal}
                        // sendbackForEss = {sendbackForEss}
                        // sectionForEss = {sectionForEss}
                        // viewSectionHistory={viewSectionHistory}
                        candidateEdit={candidateEdit}
                        changeWflTaskSts={changeWflTaskSts}
                        setChangeWflTaskSts={setChangeWflTaskSts}
                        candidateWorkflowTaskId={candidateWorkflowTaskId}
                        //flagForNoticePeriods={flagForNoticePeriods}
                        //setFlagForNoticePeriods={setFlagForNoticePeriods}
                      />
                    )}
                    {/* </>
                    } */}
                  </>
                </React.Fragment>
              ))}
          </div>
        ) : (
          <>
            {showDocSection && (
              <div className="row accordion_grid">
                {formData.S !== undefined &&
                  formData.S.length > 0 &&
                  ((((state !== null && state.TId === 0) || state === null) &&
                    sectionValuesArray.length === 0) ||
                    (state !== null &&
                      state.TId !== 0 &&
                      sectionValuesArray.length > 0) ||
                    (sectionValuesArray.length > 0 &&
                      TId != 0 &&
                      state.TId === 0)) &&
                  formData.S.map((eachSection, index) => (
                    <React.Fragment key={`${index}-${eachSection.SN}`}>
                      <>
                        {" "}
                        {/* {
                      eachSection.SN === "OneTimePaymentDeductions"?
                      <>
                        <OneTimePayment
                           formData={formData}
                           setFormData={setFormData}
                           section={eachSection}
                           TId={TId}
                           setTId={setTId}
                           sectionValuesArray={sectionValuesArray}
                           sectionIndex={index}
                        />
                      </>
                      :
                      <> */}
                        {index >= 0 && (
                          <CandidateFormSectionComponent
                            formData={formData}
                            setFormData={setFormData}
                            section={eachSection}
                            sectionIndex={index}
                            TId={TId}
                            setTId={setTId}
                            activetab={activetab}
                            setActivetab={setActivetab}
                            sectionValuesArray={sectionValuesArray}
                            SetSectionValuesArray={setSectionValuesArray}
                            Globalv={globalValues}
                            COU={COU}
                            isNewHire={isNewHire}
                            Country={country}
                            setGridSortDirection={setGridSortDirection}
                            setShowProgressionModal={setShowProgressionModal}
                            triggerEventError={triggerEventError}
                            newHireEvent={event}
                            newHireEventReason={eventReason}
                            newHireEffectiveDate={effectiveDate}
                            setShowEventError={setShowEventError}
                            ishrmanagernotify={ishrmanagernotify}
                            // setCurrentSectionIdForProgression = {setCurrentSectionIdForProgression}
                            // setTaskIdForSendback = {setTaskIdForSendback}
                            // setShowSendbackModal = {setShowSendbackModal}
                            // sendbackForEss = {sendbackForEss}
                            // sectionForEss = {sectionForEss}
                            // viewSectionHistory={viewSectionHistory}
                            candidateEdit={candidateEdit}
                            changeWflTaskSts={changeWflTaskSts}
                            setChangeWflTaskSts={setChangeWflTaskSts}
                            candidateWorkflowTaskId={candidateWorkflowTaskId}
                            //flagForNoticePeriods={flagForNoticePeriods}
                            //setFlagForNoticePeriods={setFlagForNoticePeriods}
                          />
                        )}
                        {/* </>
                    } */}
                      </>
                    </React.Fragment>
                  ))}
              </div>
            )}
            <div className="grid-wrapper grid_mob">
              <div className="row mx-auto filtBox">
                <div className="col-lg-3 col-md-6 col-sm-12">
                  <div className="form-group filtInp">
                    <span className="mr-2 text-white" style={{ float: "left" }}>
                      <label className="col-form-label">Module</label>
                    </span>
                    <SelectForm
                      isClearable
                      options={moduleOption}
                      placeholder={"Module"}
                      isDisabled={false}
                      onChange={(event) => {
                        setModule(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={module}
                    />
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12">
                  <div className="form-group filtInp">
                    <InputForm
                      value={GridsearchText}
                      placeholder="Search"
                      isDisabled={false}
                      textArea={false}
                      onChange={(e) => {
                        setGridpage(0);
                        setGridSearchText(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3 pt-3">
              <DynamicGrid
                data={DocGridData}
                columns={DocGridCols}
                options={gridOptions2}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default CandidateForm;
//CandidateForm
