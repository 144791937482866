import React, {
    useState,
    useEffect,
    useContext,
    useReducer,
    useRef,
    RefObject,
} from "react";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { useDebounce } from "../../utils/useDebounce";
import { base64ToArrayBuffer } from "../../Helpers/Common";
import { APICall } from "../../Helpers/APICalls";
import { LoaderContext } from "../../Helpers/Context/Context";
import { GetProgressionGenericMasterDashboardData } from "../../Helpers/APIEndPoints/EndPoints";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import InputForm from "../../Components/InputForm/InputForm";


const ProgressionGenericMater = () => {
    const { state } = useLocation();
    const [open, setOpen] = useState(false);
    const [count, setCount] = useState(0);
    const navigate = useNavigate();
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const [masterType, setMasterType] = useState<any>(state?.masterType || []);
    const [statusGridData, setStatusGridData] = useState({
        count: 0,
        start: 0,
        pageSize: 10,
        sortColumn: "",
        sortOrder: "",
        data: [],
    });
    const [ProgressionGenericSearch, setProgressionGenericSearch] = useState("");

    const progressiongenericdashboarddata = async () => {
        showLoader();
        debugger;
        try {
            const apiresponsedata = await APICall(GetProgressionGenericMasterDashboardData, "POST", {
                DashboardName: "GenericMaster",
                PageNumber: statusGridData.start,
                PageSize: statusGridData.pageSize,
                SortColumn: statusGridData.sortColumn,
                SortOrder: statusGridData.sortOrder,
                SearchText: ProgressionGenericSearch,
                MasterTypeIds: masterType.map((s) => s.value).join(","),
            });
            if (apiresponsedata?.status === 0 && apiresponsedata?.data) {
                debugger;
                setCount(apiresponsedata.data[0].count)
                setStatusGridData((prevState) => ({
                    ...prevState,
                    count:
                        apiresponsedata.data && apiresponsedata.data.length > 0
                            ? apiresponsedata.data[0].totalCount
                            : 0,
                    data: apiresponsedata.data || [],
                }));
            } else {
                return null;
            }

        } catch (error) {
            console.log("error", error)
        } finally {
            hideLoader();
        }
    }

    const debouncedValue = useDebounce(ProgressionGenericSearch);

    useEffect(() => {
        progressiongenericdashboarddata();
        // getcreatedbydata();
        // getactiontakendata();
        setOpen(false);
    }, [statusGridData.start, debouncedValue]);

    // For edit.
    const edit = (value, tableMeta) => {
        debugger;
        const Id = tableMeta.rowData[0];
        navigate("/ProgressionGenericMasterUpdate", {
            state: { Id },
        });
    };

    const progressiongenericoptions = {
        count: count,
        showEmptyDataSourceMessage: true,
        selectableRows: "none",
        rowsPerPage: statusGridData.pageSize,
        rowsPerPageOptions: [],
        page: statusGridData.start,
        onChangePage: async (page) => {
            setStatusGridData((prev) => {
                return { ...prev, start: page };
            });
        },
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        search: false,
        responsive: "vertical",
        serverSide: true,
    };

    const progressiongenericColumns = [
        {
            name: "id",
            label: "Sr No.",
            options: {
                filter: true,
                sort: false,
                display: false,
            },
        },
        {
            name: "effectiveDate",
            label: "Effective Date",
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: "masterTypeName",
            label: "Master Type Name",
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: "genericMasterCode",
            label: "Generic Master Code",
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: "genericMasterName",
            label: "Generic Master Name",
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: "lastModifiedBy",
            label: "Last Modified By",
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: "syncStatus",
            label: "Sync Status",
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: "activeStatus",
            label: "Active Status",
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: "action",
            label: "Action",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <div className="centerStyles">
                                <Tooltip title="Edit">
                                    <i
                                        style={{ marginRight: "0px", cursor: "pointer" }}
                                        onClick={() => {
                                            // navigate("/gridEdit")
                                            edit(value, tableMeta);
                                        }}
                                        className="fas fa-edit"
                                    ></i>
                                </Tooltip>
                            </div>
                        </>
                    );
                },
            }
        }
    ]

    const handleChanges = (event) => {
        setStatusGridData((prev) => {
            return { ...prev, start: 0 };
        });
        setProgressionGenericSearch(event)
    };



    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center my-3">
                <button
                    onClick={() => navigate("/home")}
                    className="btn btn-primary">
                    <i className="fa-solid fa-arrow-left mr-1"></i> Back
                </button>
            </div>
            <div className="d-flex align-items-center justify-content-end"></div>
            <div className="grid-wrapper grid_mob">
                <div className="row mx-auto filtBox">
                    <div className="col-lg-3 col-md-6 col-sm-12 ">
                        <div className="form-group filtInp">
                            <InputForm
                                value={ProgressionGenericSearch}
                                placeholder={"Search Template"}
                                isDisabled={false}
                                textArea={false}
                                onChange={(event) =>
                                    handleChanges(event.target.value)
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-3 pt-3">
                <DynamicGrid
                    options={progressiongenericoptions}
                    data={statusGridData.data || []}
                    columns={progressiongenericColumns}
                />
            </div>
        </div>
    )
}

export default ProgressionGenericMater

