import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { LoaderContext } from "../../Helpers/Context/Context";
import { useLocation, useNavigate } from "react-router-dom";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import InputForm from "../../Components/InputForm/InputForm";
import SelectForm from "../../Components/SelectForm/SelectForm";
import notify from "../../Helpers/ToastNotification";
import FeedbackGrid from "./FeedbackGrid";
import { APICall } from "../../Helpers/APICalls";
import {
  getAssessmentDropdownList,
  getFeedbackDropdownList,
  getLMSDomainDropDownData,
  getManageFeedbacks,
  saveManageFeedbacks,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import Question from "./Question";

interface IOptions {
  label?: string;
  value?: number | string;
  code?: string;
}

interface IFormData {
  feedbeckId?: string | any;
  feedbackForm?: string | any;
  description?: string | any;
  domain?: IOptions[] | string | any;
  feedbackType?: IOptions | any;
  sequenceType?: IOptions | any;
}
const FeedbackEdit = () => {
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isDisable, setIsDisable] = useState(
    state && state?.isDisable ? true : false
  );
  let { userDetails, currentRoleId }: any = useUserContext();
  const [formData, setFormData] = useState<IFormData>({});
  const [formErrors, setFormErrors] = useState<IFormData>({});
  const [formOptions, setFormOptions] = useState<IFormData>({});
  const [showModal, setShowModal] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [questionId, setQuestionId] = useState(0);
  const [questionType, setQuestionType] = useState(false);
  const [feedbackGridCount, setFeedbackGridCount] = useState(0);

  useEffect(() => {
    const responseData = async () => {
      //LMS_FEEDBACKTYPE
      // const res = await APICall(getFeedbackDropdownList, "POST", {
      //   Mode: "FEEDBACK_TYPE",
      // });
      // if (res.data && res.data.length > 0) {
      //   setFormOptions((prev) => {
      //     return {
      //       ...prev,
      //       feedbackType: res.data,
      //     };
      //   });
      // }

      //LMS_FEEDBACKSEQTYPE
      const res1 = await APICall(getFeedbackDropdownList, "POST", {
        Mode: "SEQUENCE_TYPE",
      });
      if (res1.data && res1.data.length > 0) {
        setFormOptions((prev) => {
          return {
            ...prev,
            sequenceType: res1.data,
          };
        });
      }
    };

    showLoader();

    responseData();
    getLMSDomainDropDownDataApiCall();

    hideLoader();
  }, []);

  const getLMSDomainDropDownDataApiCall = async () => {
    const response = await APICall(getLMSDomainDropDownData, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      GetAll: state && state?.feedbackId > 0 ? true : false,
    });

    if (response?.status === 0 && response?.data.length > 0) {
      setFormOptions((prev) => {
        return {
          ...prev,
          domain: response?.data,
        };
      });
    }
  };

  const formOnChange = (e, type) => {
    switch (type) {
      case "feedbackForm":
        setFormData({
          ...formData,
          feedbackForm: e.target.value,
        });
        break;

      case "description":
        setFormData({
          ...formData,
          description: e.target.value,
        });
        break;

      case "domain":
        setFormData({
          ...formData,
          domain: e,
        });
        break;

      // case "feedbackType":
      //   setFormData({
      //     ...formData,
      //     feedbackType: e,
      //   });
      //   break;

      case "sequenceType":
        setFormData({
          ...formData,
          sequenceType: e,
        });
        break;

      default:
        break;
    }
  };

  const validateForm = () => {
    let errorObj = {};
    let isError = false;
    setFormErrors({});

    if (
      formData?.feedbackForm === "" ||
      formData?.feedbackForm === null ||
      formData?.feedbackForm === undefined
    ) {
      errorObj = {
        ...errorObj,
        ["feedbackForm"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["feedbackForm"]: "",
      };
    }

    // if (
    //   formData?.description === "" ||
    //   formData?.description === null ||
    //   formData?.description === undefined
    // ) {
    //   errorObj = {
    //     ...errorObj,
    //     ["description"]: "Required",
    //   };
    // } else {
    //   errorObj = {
    //     ...errorObj,
    //     ["description"]: "",
    //   };
    // }

    if (
      formData?.domain?.length === 0 ||
      formData?.domain === null ||
      formData?.domain === undefined
    ) {
      errorObj = {
        ...errorObj,
        ["domain"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["domain"]: "",
      };
    }

    // if (
    //   formData?.feedbackType === null ||
    //   formData?.feedbackType === undefined
    // ) {
    //   errorObj = {
    //     ...errorObj,
    //     ["feedbackType"]: "Required",
    //   };
    // } else {
    //   errorObj = {
    //     ...errorObj,
    //     ["feedbackType"]: "",
    //   };
    // }

    if (
      formData?.sequenceType === null ||
      formData?.sequenceType === undefined
    ) {
      errorObj = {
        ...errorObj,
        ["sequenceType"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["sequenceType"]: "",
      };
    }

    const isEmpty = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  const requestParamsFun = (publishStatus) => {
    const requestParams = {
      Id:
        state === null ||
        state?.feedbackId === null ||
        state?.feedbackId === undefined
          ? 0
          : state?.feedbackId,
      FeedbackName: formData?.feedbackForm,
      FeedbackDesc: formData?.description,
      // FeedbackTypeId: formData?.feedbackType?.value,
      SequenceTypeId: formData?.sequenceType?.value,
      IsPublished: publishStatus,
      DomainIds: formData?.domain
        ? formData?.domain?.map((j) => j.value).join(",")
        : null,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    };

    return requestParams;
  };

  const handleSubmitFunction = async (publishStatus) => {
    showLoader();

    if (validateForm()) {
      hideLoader();
      return;
    }

    // const requestParams = requestParamsFun();

    // if (requestParams?.Id === 0) {
    //  await insertUpdateData();
    // } else {
    //   await insertUpdateData();
    // }

    await insertUpdateData(publishStatus);

    hideLoader();
  };

  const handlePublishedFunction = async (publishStatus) => {
    showLoader();

    if (validateForm()) {
      hideLoader();
      return;
    }

    await insertUpdateData(publishStatus);

    hideLoader();
  };

  const insertUpdateData = async (publishStatus) => {
    showLoader();

    const requestParams = requestParamsFun(publishStatus);

    const response = await APICall(saveManageFeedbacks, "POST", requestParams);

    if (response?.status === 0) {
      const newFeedbacktId = response?.data?.id;
      notify(response?.status, response?.message);

      if (publishStatus) {
        navigate("/feedbackDashboard");
      } else {
        navigate("/feedbackEdit", { state: { feedbackId: newFeedbacktId } });
      }
    } else if (response?.status === 1) {
      notify(response?.status, response?.message);
    }

    hideLoader();
  };

  //getManageFeedbacks

  useEffect(() => {
    if (
      state?.feedbackId > 0 &&
      // formOptions?.feedbackType?.length > 0 &&
      formOptions?.sequenceType?.length > 0 &&
      formOptions?.domain?.length > 0
    ) {
      getManageFeedbackApiCall();
    }
  }, [
    // formOptions?.feedbackType,
    formOptions?.sequenceType,
    formOptions?.domain,
  ]);

  const getManageFeedbackApiCall = async () => {
    showLoader();
    if (state?.feedbackId > 0) {
      try {
        const responseData = await APICall(getManageFeedbacks, "POST", {
          Id: state?.feedbackId,
        });

        if (responseData?.status === 0) {
          setIsPublished(responseData?.data?.isPublished);

          const sequenceTypeObj = formOptions?.sequenceType?.find(
            (s) => s.value === parseInt(responseData?.data?.sequenceTypeId)
          );

          const domainIds = responseData?.data?.domainIds?.split(",") || null;

          const domainArray = [];

          if (domainIds && formOptions?.domain?.length > 0) {
            domainIds?.map((item) => {
              const domainData = formOptions?.domain?.find(
                (i) => i.value === parseInt(item)
              );
              domainArray.push(domainData);
            });
          }

          setFormData((env) => {
            return {
              ...env,
              feedbeckId: responseData?.data?.feedbackCode,
              feedbackForm: responseData?.data?.feedbackName,
              description: responseData?.data?.feedbackDesc,
              // feedbackType: feedbackTypeObj,
              sequenceType: sequenceTypeObj,
              domain: domainArray.length > 0 ? domainArray : null,
            };
          });
        } else if (responseData?.status === 1) {
          notify(responseData?.status, responseData?.message);
        }
      } catch (error) {
        console.error(error);
      }
    }
    hideLoader();
  };

  const resetFunction = () => {
    setFormData((env) => {
      return {
        ...env,
        feedbackForm: "",
        description: "",
        // feedbackType: null,
        sequenceType: null,
        domain: null,
      };
    });

    setFormErrors({});
  };

  const formOnChangeForQuestion = (type) => {
    if (type === "newQuestion") {
      setQuestionType(true);
    } else if (type === "existingQuestion") {
      setQuestionType(false);
    }
  };

  const questionTypechange = (questionType, questionId) => {
    if (questionType) {
      setShowModal(true);
      formOnChangeForQuestion("newQuestion");
      setQuestionId(questionId);
    } else {
      formOnChangeForQuestion("existingQuestion");
    }
  };

  return (
    <>
      <div className="breadcrumb modified-bredcrumb">
        <span>
          <ul>
            <li>
              <a href="/home">Home</a>
            </li>
            <li>
              {" "}
              <a href="/feedbackDashboard">Feedback</a>
            </li>
            <li>Manage Feedback</li>
          </ul>
        </span>
        <button
          onClick={() => {
            navigate("/feedbackDashboard");
          }}
          disabled={false}
          className="btn btn-secondary float-right">
          <i className="fa-solid fa-arrow-left"></i> Back
        </button>
      </div>

      <div className="container-fluid">
        <div className="row">
          {/* <div className="col-md-12">
                <div className="row"> */}
          <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="mb-1">
              <label className="col-form-label">
                Feedback ID
                <sup>*</sup>
              </label>

              <InputForm
                className="form-control"
                placeholder={"Feedback ID"}
                isDisabled={true}
                textArea={false}
                value={formData.feedbeckId}
                onChange={(e) => {}}
                maxLength="255"
              />
              {formErrors["feedbeckId"] && (
                <p style={{ color: "red" }}>{formErrors["feedbeckId"]}</p>
              )}
            </div>
          </div>
          <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="mb-1">
              <label className="col-form-label">
                Feedback Form
                <sup>*</sup>
              </label>

              <InputForm
                className="form-control"
                placeholder={"Feedback Form"}
                isDisabled={isDisable}
                textArea={false}
                value={formData?.feedbackForm}
                onChange={(e) => {
                  formOnChange(e, "feedbackForm");
                }}
                maxLength="255"
              />
              {formErrors["feedbackForm"] && (
                <p style={{ color: "red" }}>{formErrors["feedbackForm"]}</p>
              )}
            </div>
          </div>

          <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="mb-1">
              <label className="col-form-label">Description</label>

              <InputForm
                className="form-control"
                placeholder={"Description"}
                isDisabled={isDisable}
                textArea={true}
                value={formData?.description}
                onChange={(e) => {
                  formOnChange(e, "description");
                }}
                maxLength="500"
              />
              {formErrors["description"] && (
                <p style={{ color: "red" }}>{formErrors["description"]}</p>
              )}
            </div>
          </div>

          <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="dropdown mb-1">
              <label className="col-form-label">Domains</label> <sup>*</sup>
              <SelectForm
                isClearable
                isSearchable
                options={formOptions?.domain}
                isOptionDisabled={(option) => !option?.isEnabled}
                placeholder={"Domains"}
                isDisabled={isDisable}
                value={formData?.domain}
                onChange={(e) => {
                  formOnChange(e, "domain");
                }}
                isMulti={true}
                noIndicator={false}
                noSeparator={false}
              />
              {formErrors["domain"] && (
                <p style={{ color: "red" }}>{formErrors["domain"]}</p>
              )}
            </div>
          </div>

          {/* <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="mb-1">
              <label className="col-form-label">
                Feedback Type
                <sup>*</sup>
              </label>

              <SelectForm
                isClearable
                isSearchable
                options={formOptions?.feedbackType}
                placeholder={"Feedback Type"}
                isDisabled={isDisable}
                value={formData?.feedbackType}
                onChange={async (e) => {
                  formOnChange(e, "feedbackType");
                }}
                isMulti={false}
                noIndicator={false}
                noSeparator={false}
              />
              {formErrors["feedbackType"] && (
                <p style={{ color: "red" }}>{formErrors["feedbackType"]}</p>
              )}
            </div>
          </div> */}

          <div className={"col-lg-3 col-sm-4 col-xs-4"}>
            <div className="mb-1">
              <label className="col-form-label">
                Sequence Type
                <sup>*</sup>
              </label>

              <SelectForm
                isClearable
                isSearchable
                options={formOptions?.sequenceType}
                placeholder={"Sequence Type"}
                isDisabled={isDisable}
                value={formData?.sequenceType}
                onChange={async (e) => {
                  formOnChange(e, "sequenceType");
                }}
                isMulti={false}
                noIndicator={false}
                noSeparator={false}
              />
              {formErrors["sequenceType"] && (
                <p style={{ color: "red" }}>{formErrors["sequenceType"]}</p>
              )}
            </div>
          </div>

          {state?.feedbackId > 0 && (
            <>
              <div className="col-lg-2 col-md-2 col-sm-12 mb-3">
                <label className="col-form-label">&nbsp;</label>
                {!isDisable && (
                  <>
                    <div>
                      <button
                        className="btn btn-filter-submit"
                        onClick={() => {
                          setShowModal(true);
                          setQuestionType(false);
                          setQuestionId(0);
                        }}>
                        <i className="fa fa-plus mr-2"></i>
                        Add Questions
                      </button>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>

        {state?.feedbackId > 0 && (
          <>
            <div>
              <FeedbackGrid
                showModal={showModal}
                setShowModal={setShowModal}
                questionTypechange={questionTypechange}
                setFeedbackGridCount={setFeedbackGridCount}
                feedbackGridCount={feedbackGridCount}
              />
            </div>
          </>
        )}

        <br />
        <div className="SectionSubmit mb-4 clearfix">
          {!isDisable && (
            <>
              {state?.feedbackId > 0 &&
                feedbackGridCount > 0 &&
                !isPublished && (
                  <>
                    {" "}
                    <button
                      onClick={() => {
                        handlePublishedFunction(true);
                      }}
                      disabled={false}
                      className="btn btn-filter-submit float-right ml-2">
                      <i className="fa-solid fa-check"></i> Publish
                    </button>
                  </>
                )}
            </>
          )}

          {state?.feedbackId > 0 && feedbackGridCount > 0 && (
            <>
              <button
                onClick={(e) => {
                  // handleSubmitFunction();
                  //feedbackPreview
                  e.preventDefault();
                  navigate("/feedbackPreview", {
                    state: {
                      feedbackId: state?.feedbackId,
                      preview: true,
                      pending: true,
                    },
                  });
                }}
                disabled={false}
                className="btn btn-filter-submit float-right ml-2">
                <i className="fas fa-eye"></i> Preview
              </button>
            </>
          )}

          {!isDisable && (
            <>
              <button
                onClick={() => {
                  resetFunction();
                }}
                disabled={false}
                className="btn btn-secondary float-right ml-2">
                <i className="fa-solid fa-refresh"></i> Reset
              </button>
              <button
                onClick={() => {
                  handleSubmitFunction(false);
                }}
                disabled={false}
                className="btn btn-filter-submit float-right ml-2">
                <i className="fa-solid fa-check"></i> Save
              </button>
            </>
          )}
        </div>
        {/* )} */}
      </div>
      {/* </div>
          </div> */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">{`Manage Questions`}</div>
        </div>
        <Modal.Body>
          <>
            <div className="row">
              <Question
                showModal={showModal}
                setShowModal={setShowModal}
                formOnChangeForQuestion={formOnChangeForQuestion}
                questionType={questionType}
                setQuestionType={setQuestionType}
                questionId={questionId}
                setQuestionId={setQuestionId}
                questionTypechange={questionTypechange}
              />
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          {/* <button
                className="btn btn-primary"
                style={{ marginLeft: 5 }}
                onClick={() => activateandDeactivateFunction(id, isActive)}
              >
                Submit
              </button> */}
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowModal(false);
            }}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FeedbackEdit;
