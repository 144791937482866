import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { APICall } from "../../Helpers/APICalls";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { decryptUsingAES256 } from "../../Helpers/Cryptography/CryptoJs";
import {
  GetRolesURL,
  getUserContextBySSOURL,
  testingForPostman,
} from "../../Helpers/APIEndPoints/EndPoints";
import notify from "../../Helpers/ToastNotification";
import InputForm from "../../Components/InputForm/InputForm";
function CopyOfSSo() {
  const navigate = useNavigate();
  const [text, setText] = useState('')
//   const location = useLocation();
//   const passedData = location.state && location.state.data;
//   var url = new URL(passedData);
//   var encryloginId = url.searchParams.get("Uid");
//   encryloginId = encryloginId.replaceAll(" ", "+");
//   const loginId = decryptUsingAES256(encryloginId);
//   const { addUserDetails }: any = useUserContext();

//   useEffect(() => {
//     getUserContextBySSO();
//   }, []);

  const GetRolesAsync = async () => {
    try {
      let arr = [];
      const response = await APICall(GetRolesURL, "POST", {});

      if (response?.status == 0 && response?.data?.length > 0) {
        const data = response?.data;

        arr = data;
      } else {
      }

      return arr;
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const getUserContextBySSO = async () => {
    debugger;
    try{
    const URLData = await APICall(testingForPostman, "POST", {
        value: text,
    });
    
    const passedData = 'https://welpro.welspun.com/api/SSO/' + URLData.data;
    notify(0, passedData);
    var url = new URL(passedData);
    var encryloginId = url.searchParams.get("Uid");
    encryloginId = encryloginId.replaceAll(" ", "+");
    const loginId = decryptUsingAES256(encryloginId);

    let lId = "";
    lId = loginId.LoginId; 
    const GetData = await APICall(getUserContextBySSOURL, "POST", {
      LoginId: lId,
    });
    if (GetData.data !== null && GetData.data?.valid !== false) {
      const userData = decryptUsingAES256(GetData.data); //decrypt data
      const Roles = await GetRolesAsync();
      userData["Roles"] = Roles;

    //   navigate("/home");
    } else if (GetData.data !== null && GetData.data?.active === false) {
      notify(1, "User Not Found!");
    //   navigate("/");
    }
}
catch{
    notify(1, 'Error');
}
  };
  return <div>
    <InputForm
      className="form-control"
      isDisabled={false}
      value={text}
      placeholder={'Enter EmployeeCode'}
      textArea={false}
      onChange={(e) => {
        setText(e.target.value);
      }}
    />
    <button
      onClick={() => {
        getUserContextBySSO();
      }}
      className="btn btn-filter-submit ml-2">
      <i className="fa-solid mr-1"></i> Submit
    </button>
  </div>;
}

export default CopyOfSSo;
