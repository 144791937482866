import React, { useContext, useEffect, useState } from "react";
import SelectForm from "../../Components/SelectForm/SelectForm";
import InputForm from "../../Components/InputForm/InputForm";
import { APICall } from "../../Helpers/APICalls";
import { LoaderContext } from "../../Helpers/Context/Context";
import notify from "../../Helpers/ToastNotification";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  GetFormBoardDashboardData,
  InsertUpdateFormBoard,
} from "../../Helpers/APIEndPoints/EndPoints";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { Collapse, Tooltip } from "@mui/material";
import { event } from "jquery";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";

const FormBoard = () => {
  //   const [MinPointsOption, setMinPointsOption] = useState([]);
  const [minPoints, setMinPoints] = useState<any>("");
  //   const [MaxPointsOption, setMaxPointsOption] = useState([]);
  const [maxPoints, setMaxPoints] = useState<any>("");
  const [Score, setScore] = useState("");
  const [Grade, setGrade] = useState("");
  const [] = useState("");
  const [formErrors, setFormErrors] = useState<any>({});
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const Navigate = useNavigate();
  let formErrorObj: any = {};
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [PageSize, setPageSize] = useState(10);
  const [sortDirection, setSortDirection] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [searchText, setSearchText] = useState("");
  const [start, setStart] = useState(0);
  // const [editData, setEditData] = useState<any>(null);
  let { userDetails, currentRoleId }: any = useUserContext();
  const [selectedId, setSelectedId] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [dataLength, setDataLength] = useState(0);

  const [isActive, setIsActive] = useState(true);

  const columns = [
    {
      name: "id",
      label: "Id",
      options: { display: false },
    },
    {
      name: "formboardMin",
      label: "Min Points",
      options: { display: true, sort: true },
    },
    {
      name: "formboardMax",
      label: "Max Points",
      options: { display: true, sort: true },
    },
    {
      name: "score",
      label: "Score",
      options: { display: true, sort: true, filter: false },
    },
    {
      name: "grade",
      label: "Grade",
      options: { display: true, sort: true, filter: false },
    },

    {
      name: "isActive",
      label: "Status",
      options: {
        display: true,
        sort: true,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return value ? "Active" : "InActive";
        },
      },
    },

    {
      name: "action",
      label: "Action",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          let isActive = tableMeta.tableData[tableMeta.rowIndex].isActive;

          return (
            <>
              <Tooltip title="Edit">
                <i
                  style={{ marginRight: "0px", cursor: "pointer" }}
                  onClick={() => {
                    handleEdit(tableMeta.rowData, isActive);
                  }}
                  className="fas fa-edit"
                ></i>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: PageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",
    onChangePage: async (page) => {
      setPage(page);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      setPage(page);
      if (sortDirection === "asc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
    },
  };

  const handleEdit = (rowData, isActive) => {
    setSelectedId(rowData[0]);
    setMinPoints(rowData[1]);
    setMaxPoints(rowData[2]);
    setScore(rowData[3]);
    setGrade(rowData[4]);
    setIsEditMode(true);
    setIsActive(isActive);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      Formboard_Max_points_isEmpty: "",
      Formboard_Min_points_isEmpty: "",
      Score_isEmpty: "",
      Grade_isEmpty: "",
      Formboard_MinGreaterThanMax: "",
      Formboard_Min_Points_OutOfRange: "",
      Formboard_Max_Points_OutOfRange: "",
    }));
  };

  const handleReset = () => {
    setSelectedId("");
    setMinPoints("");
    setMaxPoints("");
    setScore("");
    setGrade("");
    setIsEditMode(false);
    getGridStructure();
    setDataLength(0);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      FormBoard_Max_points_isEmpty: "",
      Formboard_Min_points_isEmpty: "",
      Score_isEmpty: "",
      Grade_isEmpty: "",
      Formboard_MinGreaterThanMax: "",
      Formboard_Min_Points_OutOfRange: "",
      Formboard_Max_Points_OutOfRange: "",
    }));
  };

  const CheckValidation = () => {
    let objError: any = {};
    let error = false;
    setFormErrors({});

    if (Score === "" || Score === undefined || Score === null) {
      error = true;
      objError["Score_isEmpty"] = "Score is required";
    }
    if (minPoints === "" || minPoints === undefined || minPoints === null) {
      error = true;
      objError["Formboard_Min_points_isEmpty"] =
        "Please select Formboard Min points";
    } else if (parseInt(minPoints) < 1 || parseInt(minPoints) > 300) {
      error = true;
      objError["Formboard_Min_Points_OutOfRange"] =
        "Min points must be between 1 to 300";
    }
    if (maxPoints === "" || maxPoints === undefined || maxPoints === null) {
      error = true;
      objError["Formboard_Max_points_isEmpty"] =
        "Please select Formboard Max points";
    } else if (parseInt(maxPoints) < 1 || parseInt(maxPoints) > 10000) {
      error = true;
      objError["Formboard_Max_Points_OutOfRange"] =
        "Max points must be between 1 to 10000";
    }
    if (Grade === "" || Grade === undefined || Grade === null) {
      error = true;
      objError["Grade_isEmpty"] = "Grade is required";
    }
    if (
      minPoints !== "" &&
      maxPoints !== "" &&
      parseInt(minPoints) > parseInt(maxPoints)
    ) {
      error = true;
      objError["Formboard_MinGreaterThanMax"] =
        "Min points cannot be greater than Max points.";
    }

    // if (dataLength > 0) {
    //   error = true;
    //   objError["Formboard_Point_overlaped"] = "FormBoard Point overlaped";
    //   setDataLength(0);
    // }
    setFormErrors(objError);
    return error;
  };

  const handleOnChangeMax = (event) => {
    const value = event.target.value;
    if (
      /^\d*$/.test(value)
      // &&
      // (value === "" || (parseInt(value) >= 1 && parseInt(value) <= 10000))
    ) {
      setMaxPoints(value);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        Formboard_Max_points_isEmpty: "",
        Formboard_Max_Points_OutOfRange: "",
        Formboard_MinGreaterThanMax: "",
      }));
    }
  };

  const handleOnChangeMin = (event) => {
    const value = event.target.value;
    if (
      /^\d*$/.test(value)
      // &&
      // (value === "" || (parseInt(value) >= 1 && parseInt(value) <= 300))
    ) {
      setMinPoints(value);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        Formboard_Min_points_isEmpty: "",
        Formboard_Point_overlaped: "",
        Formboard_Min_Points_OutOfRange: "",
        Formboard_MinGreaterThanMax: "",
      }));
    }
  };

  const handleOnChangeScore = (event) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      setScore(value);
      setFormErrors((prevErrors) => ({ ...prevErrors, Score_isEmpty: "" }));
    }
  };

  const handleOnChangeGrade = (event) => {
    const value = event.target.value.toUpperCase();
    const validInput = /^[A-Z+-]*$/.test(value);
    if (validInput) {
      setGrade(value);
      setFormErrors((prevErrors) => ({ ...prevErrors, Grade_isEmpty: "" }));
    }
  };

  const getGridStructure = async () => {
    showLoader();
    try {
      const GetDashboardData = await APICall(
        GetFormBoardDashboardData,
        "POST",
        {
          PageNumber: page,
          PageSize: PageSize,
          SortColumn: sortColumn,
          SortOrder: sortDirection,
          SearchText: searchText,
          FormboardMin: 0,
          FormboardMax: 0,
          Score: 0,
          Grade: "",
        }
      );

      if (GetDashboardData.data && GetDashboardData.data.length > 0) {
        setRowData(GetDashboardData.data);
        setCount(GetDashboardData.totalCount);
        hideLoader();
      } else {
        setRowData([]);
        setCount(0);
        hideLoader();
      }
    } catch (error) {
      console.error("Error fetching grid data:", error);
      hideLoader();
    }
    hideLoader();
  };

  const getGridStructure2 = async () => {
    const body = {
      PageNumber: page,
      PageSize: PageSize,
      SortColumn: sortColumn,
      SortOrder: sortDirection,
      SearchText: searchText,
      FormboardMin: Number(maxPoints),
      FormboardMax: Number(minPoints),
      Score: 0,
      Grade: "",
    };
    showLoader();
    const data = await APICall(GetFormBoardDashboardData, "POST", body);
    hideLoader();
    if (data?.data?.length > 0) {
      setDataLength(data.data.length);
      console.log(data.data.length);
      return data.data.length;
    } else {
      setDataLength(0);
    }
  };

  useEffect(() => {
    if (minPoints !== "" && maxPoints !== "") {
      getGridStructure2();
    }
  }, [minPoints, maxPoints]);

  useEffect(() => {
    showLoader();
    getGridStructure();
    hideLoader();
  }, [page, PageSize, start, sortDirection, sortColumn]);

  const InsertUpdateActivityDetails = async () => {
    showLoader();
    let IsInValid = await CheckValidation();

    let requestParams = {
      Id: Number(selectedId),
      FormboardMin: Number(minPoints),
      FormboardMax: Number(maxPoints),
      Score: Number(Score),
      Grade: Grade,
      IsActive: isActive,
      ModifiedBy: userDetails.Id,
    };

    if (IsInValid == false) {
      const GetDashboardData = await APICall(
        InsertUpdateFormBoard,
        "POST",
        requestParams
      );

      if (GetDashboardData.status === 0) {
        notify(0, "Data is updated.");
        setSelectedId("");
        setMinPoints("");
        setMaxPoints("");
        setScore("");
        setGrade("");
        setIsEditMode(false);
        setDataLength(0);
        getGridStructure();
        hideLoader();
      } else {
        notify(1, "Data is not updated.");
        getGridStructure();
        setDataLength(0);
        hideLoader();
      }
    }
    hideLoader();
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-3 col-sm-3 col-xs-4">
              <div className="mb-1">
                <label className="col-form-label">
                  Formboard Min points<sup>*</sup>
                </label>
                <InputForm
                  isDisabled={false}
                  textArea={false}
                  value={minPoints}
                  placeholder={"Range 1 - 300"}
                  onChange={handleOnChangeMin}
                />
                <p style={{ color: "red" }}>
                  {formErrors["Formboard_Min_points_isEmpty"]}
                  {formErrors.Formboard_Point_overlaped}
                  {formErrors.Formboard_MinGreaterThanMax && (
                    <div className="error-message">
                      {formErrors.Formboard_MinGreaterThanMax}
                    </div>
                  )}
                  {formErrors.Formboard_Min_Points_OutOfRange && (
                    <div className="error-message">
                      {formErrors.Formboard_Min_Points_OutOfRange}
                    </div>
                  )}
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-sm-3 col-xs-4">
              <div className="mb-1">
                <label className="col-form-label">
                  Formboard Max points<sup>*</sup>
                </label>
                <InputForm
                  value={maxPoints}
                  isDisabled={false}
                  placeholder={"Range 1 - 10,000"}
                  onChange={handleOnChangeMax}
                  textArea={false}
                />
                <p style={{ color: "red" }}>
                  {formErrors["Formboard_Max_points_isEmpty"]}
                  {formErrors.Formboard_Max_Points_OutOfRange && (
                    <div className="error-message">
                      {formErrors.Formboard_Max_Points_OutOfRange}
                    </div>
                  )}
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-sm-3 col-xs-4">
              <div className="mb-1">
                <label className="col-form-label">
                  Score<sup>*</sup>
                </label>
                <InputForm
                  value={Score}
                  placeholder={"Score"}
                  isDisabled={false}
                  onChange={handleOnChangeScore}
                  textArea={false}
                />
                <p style={{ color: "red" }}>{formErrors["Score_isEmpty"]}</p>
              </div>
            </div>

            <div className="col-lg-3 col-sm-3 col-xs-4">
              <div className="mb-1">
                <label className="col-form-label">
                  Grade<sup>*</sup>
                </label>
                <InputForm
                  value={Grade}
                  placeholder={"Grade"}
                  isDisabled={false}
                  onChange={handleOnChangeGrade}
                  textArea={false}
                />
                <p style={{ color: "red" }}>{formErrors["Grade_isEmpty"]}</p>
              </div>
            </div>

            <div className="col-lg-3 col-sm-3 col-xs-4">
              <div className="mb-1">
                <label className="col-form-label">
                  Status<sup>*</sup>
                </label>
                <div>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={isActive}
                    style={{ marginLeft: "0px" }}
                    onChange={(e) => setIsActive(e.target.checked)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 col-sm-12 col-xs-12 mt-3">
          <div style={{ textAlign: "end" }}>
            <button
              onClick={handleReset}
              disabled={false}
              className="btn btn-secondary float-right ml-2"
            >
              <i className="fa fa-undo"></i> Reset
            </button>

            <Button
              className="btn btn-filter-submit float-right btnBlue"
              onClick={InsertUpdateActivityDetails}
            >
              <i className="fa-solid fa-check"></i>{" "}
              {isEditMode ? "Update" : "Submit"}
            </Button>
          </div>
        </div>
      </div>

      <div className="row">
        <div className=" col-lg-12 mb-3 pt-3 px-0">
          <DynamicGrid data={rowData} columns={columns} options={options} />
        </div>
      </div>
    </div>
  );
};

export default FormBoard;
