import React, { useState, useEffect, useContext } from "react";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TextField from "@mui/material/TextField";
import SelectForm from "../SelectForm/SelectForm";
import InputForm from "../InputForm/InputForm";
import { LoaderContext } from "../../Helpers/Context/Context";
import { APICall } from "../../Helpers/APICalls";
import { getDropdowns } from "../../Helpers/APIEndPoints/EndPoints";
import {
  GetOUBasedPositionDropdownData,
  GetPositionDetailsByPositionId,
  InsertAssociatesOrganizationInformation,
  RCBMGetDropdownData,
  UpdateRehireFlag,
  GetRehiredCandidateDetails
} from "../../Helpers/APIEndPoints/AssociateEndPoints";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { position } from "html2canvas/dist/types/css/property-descriptors/position";
import notify from "../../Helpers/ToastNotification";

//const OrganizationInformation = ({ isDisabled, value, onChange, ...rest }) => {

const OrganizationInformation = ({
  positionScanned,
  formData,
  enableOUSection,
  associateId,
  submitData,
  onSubmitData,
  onGetPositionData,
  isRehire = 0,
  previousEmpId =0,

  ...rest
}) => {
  const [CategoryOption, setCategoryOption] = useState([
    { label: "On roll Experience", value: 1 },
    { label: "On roll Fresher", value: 2 },
    { label: "On roll Rehire", value: 3 },
    { label: "Contract Worker", value: 4 },
    { label: "Scheme Trainee", value: 5 },
    { label: "Scheme Apprentice", value: 6 },
    { label: "Experience Apprentice", value: 7 },
  ]);
  const [Category, setCategory] = useState<any>(null);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [ddValue, setDdValue] = useState({});
  const [filterValues, setFiltervalues] = useState({});
  const [positionDetails, setPositionDetails] = useState({});
  const [workAreaOption, setWorkAreaOption] = useState<any>([]);
  const [workArea, setWorkArea] = useState<any>(null);
  const [skillCategorys, setSkillCategorys] = useState(null);
  const [skillCategoryOptions, setSkillCategoryOption] = useState<any>([]);
  const [positionIdOptions, setPositionIdOption] = useState<any>([]);
  const [showPositionDetails, setShowPositionDetails] = useState(false);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [positionText, setPositionText] = useState<any>({ name: "", id: 0 });
  const [contractorsName, setContractorsName] = useState<any>({
    name: "",
    id: 0,
  });
  const [filterContractorsName, SetFilterContractorsName] = useState<any>("");
  const [schemeName, setSchemeName] = useState<any>({ name: "", id: 0 });
  const [institutionName, setInstitutionName] = useState<any>({
    name: "",
    id: 0,
  });
  const [business, setBusiness] = useState<any>({ name: "", id: 0 });
  const [sbu, setSBU] = useState<any>({ name: "", id: 0 });
  const [division, setDivision] = useState<any>({ name: "", id: 0 });
  const [subdivision, setsubDivision] = useState<any>({ name: "", id: 0 });
  const [Function, setFunction] = useState<any>(null);
  const [Department, setDepartment] = useState<any>({ name: "", id: 0 });
  const [Section, setSection] = useState<any>({ name: "", id: 0 });
  const [subSection, setSubSection] = useState<any>({ name: "", id: 0 });
  const [empClass, setEmpClass] = useState<any>({ name: "", id: 0 });
  const [JobLevel, setJobLevel] = useState<any>({ name: "", id: 0 });
  const [jobBand, setJobBand] = useState<any>({ name: "", id: 0 });
  const [designation, setDesignation] = useState<any>({ name: "", id: 0 });
  const [jobCode, setJobCode] = useState<any>({ name: "", id: 0 });
  const [skillCategory, setSkillCategory] = useState<any>({ name: "", id: 0 });
  const [reportingManager, setReportingManager] = useState<any>({
    name: "",
    id: 0,
  });
  const [payrolllocation, setPayRolllocation] = useState<string>("");
  const [LegalEntity, setLegalEntity] = useState<any>({ name: "", id: 0 });
  const [showContractorField, setShowContractorsField] = useState(false);
  const [showSchemeFields, setShowSchemeFields] = useState(false);
  const [enableFields, setEnableFields] = useState(false);
  const [enablePositionOUFields, setEnablePositionOUFields] = useState(false);
  const [categoryvalue, setCategoryValue] = useState<any>(null);
  const [previousEmpClass, setPreviousEmpClass] = useState<string>("");

  let {
    userDetails,
    proxyUserDetails,
    interfacePermissionRecord,
    currentRoleId,
  }: any = useUserContext();
  type FormErrors = {
    category?: string;
    business?: string;
    sbu?: string;
    legalEntity?: string;
    payrollLocation?: string;
    department?: string;
    positionId?: string;
  };

  useEffect(() => {
    getDropdownsData("POSOUBU", null, null);
  }, []);

  const getDropdownsData = async (AC, PID = null, cb) => {
    let dropdownData;
    await showLoader();
    dropdownData = await APICall(getDropdowns, "POST", {
      AC,
      PID,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    if (
      dropdownData.data !== null &&
      dropdownData.data !== undefined &&
      dropdownData.data.length > 0
    ) {
      setDdValue((prev) => ({ ...prev, [AC]: dropdownData.data }));
    } else {
      setDdValue((prev) => ({ ...prev, [AC]: [] }));
    }

    await hideLoader();
  };

  useEffect(() => {
    if (submitData) {
      submitOrganisationData();
    }
  }, [submitData]);

  useEffect(() => {
    if (positionScanned) {
      getPositionDetailsById();
      setShowPositionDetails(true);
      setEnableFields(false);
      setEnablePositionOUFields(false);
    } else {
      getDropdownsData("POSOUBU", null, null);
    }
  }, [positionScanned, formData?.position_Id]);

  useEffect(() => {
    if (formData?.categoryId === 5 || formData?.categoryId === 6) {
      if (positionScanned) {
        setEnableFields(false);
        setEnablePositionOUFields(false);
        getPositionDetailsById();
      } else {
        if (formData?.operation !== null && formData?.division !== null) {
          setEnableFields(false);
          setEnablePositionOUFields(true);
        } else {
          setEnableFields(true);
          setEnablePositionOUFields(false);
        }
      }
    } else {
      setEnableFields(enableOUSection);
      setEnablePositionOUFields(false);
    }
    setFiltervalues({
      CAT: formData?.categoryId,
    });
  }, [enableOUSection]);

  useEffect(() => {
    if (skillCategoryOptions.length > 0) {
      setSkillCategorys(skillCategoryOptions[0]);
    }
  }, [skillCategoryOptions]);

  useEffect(() => {
    if (positionDetails["designationId"] != 0) {
      SkillCategoryFun(filterValues["POS"]);
    }
  }, [positionDetails && positionDetails["designationId"]]);

  useEffect(() => {
    if (workAreaOption.length > 0) {
      setWorkArea(workAreaOption[0]);
    }
  }, [workAreaOption]);

  const SkillCategoryFun = async (id: any) => {
    // showLoader();

    const response = await APICall(RCBMGetDropdownData, "POST", {
      dropdownName: "SkillCategory",
      Id: id,
    });

    if (Array.isArray(response?.data)) {
      // Assuming response.data is an array
      const mappedOptions = response.data.map((item) => ({
        label: item.skillCategory, // Adjust property names based on your actual data structure
        value: item.skillCategoryId, // Adjust property names based on your actual data structure
      }));

      // You might want to set the state with the entire array or just a specific element based on your use case
      // If you want to set the state with the entire array
      setSkillCategoryOption(mappedOptions);

      // Or if you want to set the state with a specific element
      // setSkillCategoryOption(mappedOptions[0]);  // Assuming you want the first element
    } else {
      // Handle the case where response.data is not an array
      console.error("response.data is not an array");
      // You may want to set a default value or handle it appropriately
    }
    // hideLoader();
  };

  async function fetchDropdown(searchText = "", code = "", value = null) {
    try {
      const response = await APICall(
        GetOUBasedPositionDropdownData,
        "POST",
        {
          SearchText: searchText,
          Business: filterValues["BIS"]?.toString(),
          SBU: filterValues["SBU"]?.toString(),
          LegalEntity: filterValues["LEN"]?.toString(),
          PayrollLocation: filterValues["PLOC"]?.toString(),
          Department: filterValues["DEPT"]?.toString(),
          CategoryId: filterValues["CAT"]?.toString() || formData?.categoryId,
        },
        true
      );

      if (response?.status === 0) {
        if (response?.data) {
          setPositionIdOption(response.data);
          return response?.data;
        } else {
          return [];
        }
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  async function handleSearchForAsyncDropdown(event, code, callback) {
    try {
      if (event?.length >= 3) {
        const data = await fetchDropdown(event, code);
        callback(data);
      } else {
        callback([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getOUDDValues("POSOUSBU");
    getOUDDValues("POSOUDEPT");
  }, [filterValues["BIS"]]);

  useEffect(() => {
    getOUDDValues("POSOULEN");
    getOUDDValues("POSOUDEPT");
  }, [filterValues["SBU"]]);

  useEffect(() => {
    getOUDDValues("POSOUPLOC");
    getOUDDValues("POSOUDEPT");
  }, [filterValues["LEN"]]);

  useEffect(() => {
    getOUDDValues("POSOUDEPT");
  }, [filterValues["PLOC"]]);

  const getOUDDValues = (OUType) => {
    let BIS = filterValues["BIS"] ? filterValues["BIS"]?.toString() : "0";
    let SBU = filterValues["SBU"] ? filterValues["SBU"]?.toString() : "0";
    let LEN = filterValues["LEN"] ? filterValues["LEN"]?.toString() : "0";
    let PLOC = filterValues["PLOC"] ? filterValues["PLOC"]?.toString() : "0";
    let PID = BIS + ":" + SBU + ":" + LEN + ":" + PLOC;
    getDropdownsData(OUType, PID, null);
  };

  const getFieldsValidated = async () => {
    let errors: FormErrors = {};
    if (!filterValues["CAT"]) {
      errors.category = "please select category";
    }
    if (!filterValues["BIS"]) {
      errors.business = "please select business";
    }
    if (!filterValues["SBU"]) {
      errors.sbu = "please select sbu";
    }
    if (!filterValues["LEN"]) {
      errors.legalEntity = "please select legal entity";
    }
    if (!filterValues["PLOC"]) {
      errors.payrollLocation = "please select payroll location";
    }
    if (!filterValues["DEPT"]) {
      errors.department = "please select department";
    }
    if (!filterValues["POS"]) {
      errors.positionId = "please select position";
    }

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      setFormErrors(errors);
      return false;
    }
  };

  useEffect(() => {
    const getconName = async () => {
      if (filterValues["POS"]) {
        let isValid = false;
        let PId = null;
        if (positionScanned) {
          isValid = true;
          PId = formData?.position_Id?.value;
        } else {
          isValid = await getFieldsValidated();
          PId = filterValues["POS"];
        }

        if (isValid) {
          const response = await APICall(
            GetPositionDetailsByPositionId,
            "POST",
            {
              PositionId: PId,
            }
          );
          if (response?.status === 0) {
            SetFilterContractorsName(response?.data?.contractorsName);
          }
        }
      }
    };
    getconName();
  }, [filterValues["POS"]]);

  const getPositionDetailsById = async () => {
    showLoader();

    let isValid = false;
    let PId = null;
    if (positionScanned) {
      isValid = true;
      PId = formData?.position_Id?.value;
    } else {
      isValid = await getFieldsValidated();
      PId = filterValues["POS"];
    }

    if (isValid) {
      const response = await APICall(GetPositionDetailsByPositionId, "POST", {
        PositionId: PId,
      });
      if (response?.status === 0) {
        if (onGetPositionData) {
          onGetPositionData();
        }

        setShowPositionDetails(true);
        setPositionDetails(response.data);
        setPositionText({
          name: response?.data?.positionText,
          id: response?.data?.positionId,
        });

        setBusiness({
          name: response?.data?.business,
          id: response?.data?.businessId,
        });

        setSBU({
          name: response?.data?.sbu,
          id: response?.data?.sbuId,
        });

        setDivision({
          name: response?.data?.division,
          id: response?.data?.divisionId,
        });

        setsubDivision({
          name: response?.data?.subdivision,
          id: response?.data?.subdivisionId,
        });

        setFunction({
          name: response?.data?.function,
          id: response?.data?.functionId,
        });

        setDepartment({
          name: response?.data?.department,
          id: response?.data?.departmentId,
        });

        setSection({
          name: response?.data?.sectionName,
          id: response?.data?.sectionId,
        });

        setSubSection({
          name: response?.data?.subSection,
          id: response?.data?.subSectionId,
        });

        setEmpClass({
          name: response?.data?.employeeClass,
          id: response?.data?.employeeClassId,
          code: response?.data?.employeeClassCode,
        });

        setJobLevel({
          name: response?.data?.jobLevel,
          id: response?.data?.jobLevelId,
        });

        setJobBand({
          name: response?.data?.jobBand,
          id: response?.data?.jobBandId,
        });

        setDesignation({
          name: response?.data?.designation,
          id: response?.data?.designationId,
        });

        setJobCode({
          name: response?.data?.jobCode,
          id: response?.data?.jobCodeId,
        });

        setSkillCategory({
          name: response?.data?.jobTitles,
          id: response?.data?.jobTitleId,
        });

        setReportingManager({
          name: response?.data?.reportingManager,
          id: response?.data?.reportingManagerId,
        });

        setPayRolllocation(response?.data?.payrollLocation || "");

        setLegalEntity({
          name: response?.data?.legalEntity,
          id: response?.data?.legalEntityId,
        });

        setContractorsName({
          name: response?.data?.contractorsName,
          id: response?.data?.contractorsId,
        });

        let EmpClsCode = response?.data?.employeeClassCode;
        if (response?.data?.employeeClassCode && EmpClsCode === "CLM") {
          setShowContractorsField(true);
          setShowSchemeFields(false);
        } else if (
          response?.data?.employeeClassCode &&
          (EmpClsCode === "SchemeTrainee" || EmpClsCode === "SchemeApprentice")
        ) {
          setShowContractorsField(false);
          setShowSchemeFields(true);
        }

        WorkAreafun(response?.data?.positionId);

        if (positionScanned) {
          setFiltervalues((prev) => ({
            ...prev,
            ["BIS"]: response.data.businessId,
            ["SBU"]: response.data.sbuId,
            ["LEN"]: response?.data?.legalEntityId,
            ["PLOC"]: response?.data?.payrollLocationId,
            ["DEPT"]: response?.data?.departmentId,
            ["CAT"]: formData?.categoryId,
            ["POS"]: PId,
          }));
          fetchDropdown(formData?.position_Id?.label);
        }
        hideLoader();
      } else {
        hideLoader();
        notify(1, "Something went wrong!");
      }
    } else {
      hideLoader();
    }
  };

  const validateFormorg = () => {
    let isValid = true;
    let errors: FormErrors = {};

    if (!positionDetails["positionText"]) {
      errors.positionId = "please select Position Id";
      isValid = false;
    }

    // if (!Category) {
    //   errors.category = "Please select category";
    //   isValid = false;
    // }

    if (!workArea || !skillCategorys) {
      notify(1, "Work Area or Skill Category can not be empty");
      isValid = false;
    }

    // Add additional validations as needed
    setFormErrors(errors);
    return isValid;
  };

  const updateRehire = async () => {
    const response = await APICall(UpdateRehireFlag, "POST", {
      AssociateId: associateId,
      IsRehire: isRehire,
    });
    if ((response.status = 0)) {
      console.log("IsRehire updated");
    }
  };

  const submitOrganisationData = async () => {
    if (!validateFormorg()) {
      return; // Stop the submission if there are validation errors
    }

    let requestParams = {
      AssociatesDetailsId: associateId,
      PositionId: filterValues["POS"],
      BusinessId: business?.id,
      SBUId: sbu?.id,
      CategoryId: filterValues["CAT"],
      DivisionId: division?.id,
      FunctionId: Function?.id,
      DepartmentId: Department?.id,
      SectionId: Section?.id,
      SubSectionId: subSection?.id,
      EmployeeClassId: empClass?.id,
      DesignationId: designation?.id,
      JobCodeId: jobCode?.id,
      JobTitleId: skillCategory?.id === undefined ? 0 : skillCategory?.id,
      JobBandId: jobBand?.id,
      JobLevelId: JobLevel?.id,
      ReportingManagerId: reportingManager?.id,
      SkillCategoryId: skillCategorys?.value || 0,
      LegalEntityId: LegalEntity?.id || 0,
      WorkAreaId: workArea?.value || 0,
      IsActive: true,
      CreatedBy: userDetails?.Id,
    };
    //debugger;
    const response = await APICall(
      InsertAssociatesOrganizationInformation,
      "POST",
      requestParams
    );

    if (response.status === 0) {
      notify(
        0,
        `The data for Candidate ID ${associateId} has been successfully submitted.`
      );

      if (
        (categoryvalue == 1 || categoryvalue == 2 || categoryvalue == 3) &&
        isRehire == 1 && String(previousEmpClass).toLowerCase() == "worker"
      ) {
 
        updateRehire();
      }

      onSubmitData({
        status: "success",
        employeeClassCode: empClass?.code,
        categoryId: filterValues["CAT"],
        legalEntityName: LegalEntity?.name,
        designation: designation?.name,
        department: Department?.name,
      });
    } else {
      onSubmitData({
        status: "error",
      });
      notify(1, "Data not inserted");
    }
  };

  const WorkAreafun = async (id: any) => {
    const response = await APICall(RCBMGetDropdownData, "POST", {
      dropdownName: "PositionWorkArea",
      PositionId: id,
    });
    if (response?.data != null) {
      console.log(response?.data, "wrk");
      if (Array.isArray(response?.data)) {
        // Assuming response.data is an array
        const mappedOptions = response.data.map((item) => ({
          label: item.workAreaName, // Adjust property names based on your actual data structure
          value: item.workAreaId, // Adjust property names based on your actual data structure
        }));

        // You might want to set the state with the entire array or just a specific element based on your use case
        // If you want to set the state with the entire array
        setWorkAreaOption(mappedOptions);

        // Or if you want to set the state with a specific element
        // setSkillCategoryOption(mappedOptions[0]);  // Assuming you want the first element
      } else {
        // Handle the case where response.data is not an array
        console.error("response.data is not an array");
        // You may want to set a default value or handle it appropriately
      }
    }
  };

  useEffect(() => {
    console.log(previousEmpId +" previousEmpId1")
    if(previousEmpId){
  
      const getRehiredEmployeeData = async () => {

    
        let getRehiredEmployeeDetails = await APICall(GetRehiredCandidateDetails, "POST", {
          PreviousEmployeeId: previousEmpId
        });
  
        if (getRehiredEmployeeDetails && getRehiredEmployeeDetails.status === 0) {
            console.log(getRehiredEmployeeDetails);
        
          setPreviousEmpClass(getRehiredEmployeeDetails?.data?.employeeClass);
        
        
        
        }
     
      
      }
      getRehiredEmployeeData();
    }
      
    
   
  }, [previousEmpId])
 
  return (
    <div>
      <div className="row">
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1">
            <label className="col-form-label">
              Select Category <span style={{ color: "red" }}>*</span>
            </label>
            <SelectForm
              options={CategoryOption}
              placeholder={"Select Category"}
              onChange={(event: any) => {
                setFiltervalues((prev) => ({
                  ...prev,
                  ["CAT"]: event ? event.value : "",
                }));
                setCategoryValue(event.value);
                if (event.value !== 5 && event.value !== 6) {
                  if (formData?.currentStageId === 13) {
                    setEnablePositionOUFields(false);
                  } else {
                    setEnablePositionOUFields(true);
                  }
                } else {
                  if (formData?.currentStageId === 13) {
                    setEnablePositionOUFields(true);
                  } else {
                    setEnablePositionOUFields(false);
                  }
                  onSubmitData({
                    status: "skip",
                    categoryId: event.value,
                  });
                }
              }}
              isMulti={false}
              noIndicator={false}
              noSeparator={false}
              value={CategoryOption?.find(
                (x) => x.value === filterValues["CAT"]
              )}
              isDisabled={!enableFields}
            />
          </div>
          {formErrors.category && (
            <p style={{ color: "red" }}>{formErrors.category}</p>
          )}
        </div>
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1">
            <label className="col-form-label">
              Select Business <span style={{ color: "red" }}>*</span>
            </label>
            <SelectForm
              options={ddValue["POSOUBU"]}
              placeholder={"Select Business"}
              onChange={(event: any) => {
                setFiltervalues((prev) => ({
                  ...prev,
                  ["BIS"]: event ? event.value : "",
                }));
              }}
              isMulti={false}
              noIndicator={false}
              noSeparator={false}
              value={ddValue["POSOUBU"]?.find(
                (x) => x.value === filterValues["BIS"]
              )}
              isDisabled={!enablePositionOUFields}
            />
          </div>
          {formErrors.business && (
            <p style={{ color: "red" }}>{formErrors.business}</p>
          )}
        </div>
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1">
            <label className="col-form-label">
              Select SBU <span style={{ color: "red" }}>*</span>
            </label>
            <SelectForm
              options={ddValue["POSOUSBU"]}
              placeholder={"Select SBU"}
              onChange={(event: any) => {
                setFiltervalues((prev) => ({
                  ...prev,
                  ["SBU"]: event ? event.value : "",
                }));
              }}
              isMulti={false}
              noIndicator={false}
              noSeparator={false}
              value={ddValue["POSOUSBU"]?.find(
                (x) => x.value === filterValues["SBU"]
              )}
              isDisabled={!enablePositionOUFields}
            />
          </div>
          {formErrors.sbu && <p style={{ color: "red" }}>{formErrors.sbu}</p>}
        </div>
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1">
            <label className="col-form-label">
              Select Legal Entity Name <span style={{ color: "red" }}>*</span>
            </label>
            <SelectForm
              options={ddValue["POSOULEN"]}
              placeholder={"Select Legal Entity Name"}
              onChange={(event: any) => {
                setFiltervalues((prev) => ({
                  ...prev,
                  ["LEN"]: event ? event.value : "",
                }));
              }}
              isMulti={false}
              noIndicator={false}
              noSeparator={false}
              value={ddValue["POSOULEN"]?.find(
                (x) => x.value === filterValues["LEN"]
              )}
              isDisabled={!enablePositionOUFields}
            />
          </div>
          {formErrors.legalEntity && (
            <p style={{ color: "red" }}>{formErrors.legalEntity}</p>
          )}
        </div>
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1">
            <label className="col-form-label">
              Select Payroll Location <span style={{ color: "red" }}>*</span>
            </label>
            <SelectForm
              options={ddValue["POSOUPLOC"]}
              placeholder={"Select Payroll Location"}
              onChange={(event: any) => {
                setFiltervalues((prev) => ({
                  ...prev,
                  ["PLOC"]: event ? event.value : "",
                }));
              }}
              isMulti={false}
              noIndicator={false}
              noSeparator={false}
              value={ddValue["POSOUPLOC"]?.find(
                (x) => x.value === filterValues["PLOC"]
              )}
              isDisabled={!enablePositionOUFields}
            />
          </div>
          {formErrors.payrollLocation && (
            <p style={{ color: "red" }}>{formErrors.payrollLocation}</p>
          )}
        </div>
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1">
            <label className="col-form-label">
              Select Department <span style={{ color: "red" }}>*</span>
            </label>
            <SelectForm
              options={ddValue["POSOUDEPT"]}
              placeholder={"Select Department"}
              onChange={(event: any) => {
                setFiltervalues((prev) => ({
                  ...prev,
                  ["DEPT"]: event ? event.value : "",
                }));
              }}
              isMulti={false}
              noIndicator={false}
              noSeparator={false}
              value={ddValue["POSOUDEPT"]?.find(
                (x) => x.value === filterValues["DEPT"]
              )}
              isDisabled={!enablePositionOUFields}
            />
          </div>
          {formErrors.department && (
            <p style={{ color: "red" }}>{formErrors.department}</p>
          )}
        </div>
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1">
            <label className="col-form-label">Contractor's Name</label>
            <InputForm
              className="form-control"
              placeholder={"Contractor's Name"}
              isDisabled={true}
              textArea={false}
              value={filterContractorsName}
              onChange={(val: any) => {}}
            />
          </div>
        </div>
        <div className="col-lg-3 col-sm-3 col-xs-4">
          <div className="mb-1">
            <label className="col-form-label">
              Select Position ID <span style={{ color: "red" }}>*</span>
            </label>
            <SelectForm
              isClearable
              async
              options={(searchString, cb) => {
                handleSearchForAsyncDropdown(searchString, "position_Id", cb);
              }}
              placeholder={"Select Position Id"}
              onChange={(event: any) => {
                setFiltervalues((prev) => ({
                  ...prev,
                  ["POS"]: event ? event.value : "",
                }));
              }}
              isMulti={false}
              noIndicator={false}
              noSeparator={false}
              value={positionIdOptions?.find(
                (x) => x.value === filterValues["POS"]
              )}
              isDisabled={!enablePositionOUFields}
            />
          </div>
          {formErrors.positionId && (
            <p style={{ color: "red" }}>{formErrors.positionId}</p>
          )}
        </div>
      </div>

      <div className="SectionSubmit clearfix">
        <button
          onClick={() => {
            getPositionDetailsById();
          }}
          disabled={!enablePositionOUFields}
          className="btn btn-filter-submit float-right mt-2"
        >
          Fetch OU Details
        </button>
      </div>

      {showPositionDetails && (
        <div className="row">
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">Position Text</label>
              <InputForm
                className="form-control"
                placeholder={"Position Text"}
                isDisabled={true}
                textArea={false}
                value={positionDetails["positionText"]}
                onChange={(val: any) => {
                  let value = val.target.value;
                  //onChange(value, "course_name");
                }}
              />
            </div>
          </div>
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Business <span style={{ color: "red" }}></span>
              </label>
              <InputForm
                className="form-control"
                placeholder={"Business"}
                isDisabled={true}
                textArea={false}
                value={positionDetails["business"]}
                onChange={(val: any) => {
                  let value = val.target.value;
                  //onChange(value, "course_name");
                }}
              />
            </div>
          </div>
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                SBU <span style={{ color: "red" }}></span>
              </label>
              <InputForm
                className="form-control"
                placeholder={"SBU"}
                isDisabled={true}
                textArea={false}
                value={positionDetails["sbu"]}
                onChange={(val: any) => {
                  let value = val.target.value;
                  //onChange(value, "course_name");
                }}
              />
            </div>
          </div>
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Legal Entity Name <span style={{ color: "red" }}></span>
              </label>
              <InputForm
                className="form-control"
                placeholder={"Legal entity"}
                isDisabled={true}
                textArea={false}
                value={positionDetails["legalEntity"]}
                onChange={(event) => {
                  // let value = val.target.value;
                  //setLegalEntity(event);
                }}
              />
            </div>
          </div>
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Division <span style={{ color: "red" }}></span>
              </label>
              <InputForm
                className="form-control"
                placeholder={"Division"}
                isDisabled={true}
                textArea={false}
                value={positionDetails["division"]}
                onChange={(val: any) => {
                  let value = val.target.value;
                  //onChange(value, "course_name");
                }}
              />
            </div>
          </div>
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Sub-Division <span style={{ color: "red" }}></span>
              </label>
              <InputForm
                className="form-control"
                placeholder={"Sub-Division"}
                isDisabled={true}
                textArea={false}
                value={positionDetails["subdivision"]}
                onChange={(val: any) => {
                  let value = val.target.value;
                  //onChange(value, "course_name");
                }}
              />
            </div>
          </div>
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Function <span style={{ color: "red" }}></span>
              </label>
              <InputForm
                className="form-control"
                placeholder={"Function"}
                isDisabled={true}
                textArea={false}
                value={positionDetails["function"]}
                onChange={(val: any) => {
                  let value = val.target.value;
                  //onChange(value, "course_name");
                }}
              />
            </div>
          </div>
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Department <span style={{ color: "red" }}></span>
              </label>
              <InputForm
                className="form-control"
                placeholder={"Department"}
                isDisabled={true}
                textArea={false}
                value={positionDetails["department"]}
                onChange={(val: any) => {
                  let value = val.target.value;
                  //onChange(value, "course_name");
                }}
              />
            </div>
          </div>
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Section <span style={{ color: "red" }}></span>
              </label>
              <InputForm
                className="form-control"
                placeholder={"Section"}
                isDisabled={true}
                textArea={false}
                value={positionDetails["sectionName"]}
                onChange={(val: any) => {
                  let value = val.target.value;
                  //onChange(value, "course_name");
                }}
              />
            </div>
          </div>
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Sub Section <span style={{ color: "red" }}></span>
              </label>
              <InputForm
                className="form-control"
                placeholder={" Sub Section"}
                isDisabled={true}
                textArea={false}
                value={positionDetails["subSection"]}
                onChange={(val: any) => {
                  let value = val.target.value;
                  //onChange(value, "course_name");
                }}
              />
            </div>
          </div>

          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Emp class <span style={{ color: "red" }}></span>
              </label>
              <InputForm
                className="form-control"
                placeholder={"Emp Class"}
                isDisabled={true}
                textArea={false}
                value={positionDetails["employeeClass"]}
                onChange={(val: any) => {
                  let value = val.target.value;
                  //onChange(value, "course_name");
                }}
              />
            </div>
          </div>
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Job level <span style={{ color: "red" }}></span>
              </label>
              <InputForm
                className="form-control"
                placeholder={"Job Level"}
                isDisabled={true}
                textArea={false}
                value={positionDetails["jobLevel"]}
                onChange={(val: any) => {
                  let value = val.target.value;
                  //onChange(value, "course_name");
                }}
              />
            </div>
          </div>

          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Job Band <span style={{ color: "red" }}></span>
              </label>
              <InputForm
                className="form-control"
                placeholder={"Job Band"}
                isDisabled={true}
                textArea={false}
                value={positionDetails["jobBand"]}
                onChange={(val: any) => {
                  let value = val.target.value;
                  //onChange(value, "course_name");
                }}
              />
            </div>
          </div>
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Designation <span style={{ color: "red" }}></span>
              </label>
              <InputForm
                className="form-control"
                placeholder={"Designation"}
                isDisabled={true}
                textArea={false}
                value={positionDetails["designation"]}
                onChange={(val: any) => {
                  let value = val.target.value;
                  //onChange(value, "course_name");
                }}
              />
            </div>
          </div>

          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Job Code <span style={{ color: "red" }}></span>
              </label>
              <InputForm
                className="form-control"
                placeholder={"Job Code"}
                isDisabled={true}
                textArea={false}
                value={positionDetails["jobCode"]}
                onChange={(val: any) => {
                  let value = val.target.value;
                  //onChange(value, "course_name");
                }}
              />
            </div>
          </div>
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Job Title <span style={{ color: "red" }}></span>
              </label>
              <InputForm
                className="form-control"
                placeholder={"Job Title"}
                isDisabled={true}
                textArea={false}
                value={positionDetails["jobTitles"]}
                onChange={(val: any) => {
                  let value = val.target.value;
                  //onChange(value, "course_name");
                }}
              />
            </div>
          </div>

          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Reporting Manager <span style={{ color: "red" }}></span>
              </label>
              <InputForm
                className="form-control"
                placeholder={"Reporting Manager"}
                isDisabled={true}
                textArea={false}
                value={positionDetails["reportingManager"]}
                onChange={(val: any) => {
                  let value = val.target.value;
                  //onChange(value, "course_name");
                }}
              />
            </div>
          </div>

          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Skill Category <span style={{ color: "red" }}></span>
              </label>
              <SelectForm
                options={skillCategoryOptions}
                placeholder={"Select Category"}
                value={skillCategorys}
                onChange={(event) => {
                  //setSkillCategorys(event);
                  // onChange(event, "journey_by_courseid");
                }}
                isDisabled={true}
                noIndicator={false}
                noSeparator={false}
              />
            </div>
          </div>

          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Work Area <span style={{ color: "red" }}></span>
              </label>
              <SelectForm
                value={workArea}
                placeholder={"Select Work Area"}
                options={workAreaOption}
                onChange={(event) => {
                  //setWorkArea(event);
                  // onChange(event, "journey_by_courseid");
                }}
                isDisabled={true}
                noIndicator={false}
                noSeparator={false}
              />
            </div>
          </div>

          {/* {showContractorField && (
                <div className="col-lg-3 col-sm-3 col-xs-4">
                <label className="col-form-label">Contractor's name</label>
                <SelectForm
                  value={{'Id': 0, 'label': positionDetails['contractorsName']}}
                  placeholder={"Select Contractor's Name"}
                  options={[]}
                  onChange={(event) => {
                    //setCategory(event);
                    // onChange(event, "journey_by_courseid");
                  }}
                  isDisabled={true}
                  noIndicator={false}
                  noSeparator={false}
                />
              </div>
              )} */}

          {showSchemeFields && (
            <>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <label className="col-form-label">Scheme Name</label>
                {/* <sup>*</sup> */}
                <SelectForm
                  value={{ Id: 0, label: positionDetails["schemeName"] }}
                  placeholder={"Scheme Name"}
                  options={[]}
                  onChange={(event) => {
                    //setCategory(event);
                    // onChange(event, "journey_by_courseid");
                  }}
                  isDisabled={true}
                  noIndicator={false}
                  noSeparator={false}
                />
                {/* {formErrors.category && (
                  <p style={{ color: "red" }}>{formErrors.category}</p>
                )} */}
              </div>

              {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                <label className="col-form-label">Institute Name</label>
                <sup>*</sup>
                <InputForm
                    className="form-control"
                    placeholder={"Institute Name"}
                    isDisabled={true}
                    textArea={false}
                    value={''}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                {formErrors.category && (
                  <p style={{ color: "red" }}>{formErrors.category}</p>
                )} 
              </div>   */}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default OrganizationInformation;
