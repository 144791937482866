import { IconContainerProps, Rating, styled } from "@mui/material";
import React, { useState } from "react";
import { Stack } from "react-bootstrap";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import InputForm from "../../Components/InputForm/InputForm";
import { useLocation } from "react-router-dom";

const SingleAnswer = ({
  isDisable,
  Index,
  questionData,
  handleAnswerOnChange,
}) => {
  let answerArray = [];

  if (questionData?.answers !== null) {
    answerArray = JSON.parse(questionData?.answers);
  }

  const [rating, setRating] = useState(
    questionData?.rating !== null ? Number(questionData?.rating) : 0
  );
  const handleFormChange = (e, rating) => {
    setRating(Number(rating));
    handleAnswerOnChange(questionData?.questionId, rating);
  };

  return (
    <div className="row">
      <div className={"border rounded my-2 col-lg-6 col-sm-4 col-xs-4"}>
        <label className="col-form-label">{`${Index}. ${questionData?.question}`}</label>{" "}
        <div>
          {answerArray.map((ans, aIndex) => (
            <>
              <input
                className="ml-1 mr-1"
                disabled={isDisable}
                type="radio"
                id={ans.AnswerId}
                name="radios"
                checked={rating === Number(ans.Rating) ? true : false}
                onChange={(e) => {
                  handleFormChange(e, ans.Rating);
                }}
              />
              <label className="form-check-label" htmlFor={ans.AnswerId}>
                {ans.Answer}
              </label>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

const FreeText = ({ isDisable, Index, questionData, handleAnswerOnChange }) => {
  const [freeText, setFreeText] = useState(
    questionData?.rating !== null ? questionData?.rating : ""
  );

  const handleFormChange = (e) => {
    setFreeText(e.target.value);
    handleAnswerOnChange(questionData?.questionId, e.target.value);
  };
  return (
    <div className="row">
      <div className={"border rounded my-2 col-lg-6 col-sm-4 col-xs-4"}>
        <div className="mb-1">
          <label className="col-form-label">{`${Index}. ${questionData?.question}`}</label>{" "}
          <InputForm
            className="form-control"
            placeholder={"Free Text"}
            isDisabled={isDisable}
            textArea={false}
            value={freeText}
            onChange={(e) => handleFormChange(e)}
            maxLength="500"
          />
        </div>
      </div>
    </div>
  );
};

const SmileyFace = ({
  isDisable,
  Index,
  questionData,
  handleAnswerOnChange,
}) => {
  const [rating, setRating] = useState(
    questionData?.rating !== null ? Number(questionData?.rating) : 0
  );

  const StyledRating = styled(Rating)(({ theme }) => ({
    "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
      color: theme.palette.action.disabled,
    },
  }));

  const customIcons: {
    [index: string]: {
      icon: React.ReactElement;
      label: string;
    };
  } = {
    1: {
      icon: <SentimentVeryDissatisfiedIcon color="error" />,
      label: "Very Dissatisfied",
    },
    2: {
      icon: <SentimentDissatisfiedIcon color="error" />,
      label: "Dissatisfied",
    },
    3: {
      icon: <SentimentSatisfiedIcon color="warning" />,
      label: "Neutral",
    },
    4: {
      icon: <SentimentSatisfiedAltIcon color="success" />,
      label: "Satisfied",
    },
    5: {
      icon: <SentimentVerySatisfiedIcon color="success" />,
      label: "Very Satisfied",
    },
  };

  function IconContainer(props: IconContainerProps) {
    const { value, ...other } = props;

    return <span {...other}>{customIcons[value].icon}</span>;
  }

  const handleFormChange = (newValue) => {
    setRating(newValue);

    handleAnswerOnChange(questionData?.questionId, newValue);
  };

  return (
    <div className="row">
      <div className={"border rounded my-2 col-lg-6 col-sm-4 col-xs-4"}>
        <div className="mb-1">
          <label className="col-form-label">{`${Index}. ${questionData?.question}`}</label>{" "}
          <Stack>
            <StyledRating
              name="highlight-selected-only"
              defaultValue={rating}
              IconContainerComponent={IconContainer}
              getLabelText={(value: number) => customIcons[value].label}
              //   highlightSelectedOnly
              onChange={(event, newValue) => {
                handleFormChange(newValue);
              }}
              disabled={isDisable}
            />
          </Stack>
        </div>
      </div>
    </div>
  );
};

const StarScale = ({
  isDisable,
  Index,
  questionData,
  handleAnswerOnChange,
}) => {
  const [rating, setRating] = useState(
    questionData?.rating !== null ? Number(questionData?.rating) : 0
  );

  const handleFormChange = (newValue) => {
    setRating(newValue);

    handleAnswerOnChange(questionData?.questionId, newValue);
  };

  return (
    <div className="row">
      <div className={"border rounded my-2 col-lg-6 col-sm-4 col-xs-4"}>
        <div className="mb-1">
          <label className="col-form-label">{`${Index}. ${questionData?.question}`}</label>{" "}
          <Stack>
            <Rating
              name="size-large"
              value={rating}
              onChange={(event, newValue) => {
                handleFormChange(newValue);
              }}
              disabled={isDisable}
              size="large"
            />
          </Stack>
        </div>
      </div>
    </div>
  );
};

const LikertScale = ({
  isDisable,
  Index,
  questionData,
  handleAnswerOnChange,
}) => {
  const [rating, setRating] = useState(
    questionData?.rating !== null ? Number(questionData?.rating) : 0
  );

  // if (questionData?.rating !== null) {
  //   const ratingData = Number(questionData?.rating);

  //   setRating(ratingData);
  // }

  const handleFormChange = (type, rating) => {
    handleAnswerOnChange(questionData?.questionId, rating);
    switch (type) {
      case "StronglyDisagree":
        setRating(rating);
        break;
      case "Disagree":
        setRating(rating);
        break;
      case "NeigherAgreenorDisagree":
        setRating(rating);
        break;
      case "Agree":
        setRating(rating);
        break;
      case "StronglyAgree":
        setRating(rating);
        break;
      default:
        break;
    }
  };
  return (
    <div className="row">
      <div className={"border rounded my-2 col-lg-6 col-sm-4 col-xs-4"}>
        <div className="mb-1">
          <label className="col-form-label">{`${Index}. ${questionData?.question}`}</label>{" "}
          <div>
            <input
              className="ml-1 mr-1"
              disabled={isDisable}
              type="radio"
              onChange={(e) => {
                handleFormChange("StronglyDisagree", 1);
              }}
              checked={rating === 1}
            />
            <label htmlFor={`True`}>Strongly disagree</label>
            <input
              className="ml-2 mr-1"
              disabled={isDisable}
              type="radio"
              onChange={(e) => {
                handleFormChange("Disagree", 2);
              }}
              checked={rating === 2}
            />
            <label htmlFor={`False`}>Disagree</label>
            <input
              className="ml-2 mr-1"
              disabled={isDisable}
              type="radio"
              onChange={(e) => {
                handleFormChange("NeigherAgreenorDisagree", 3);
              }}
              checked={rating === 3}
            />
            <label htmlFor={`False`}>Neither agree nor disagree</label>
            <input
              className="ml-2 mr-1"
              disabled={isDisable}
              type="radio"
              onChange={(e) => {
                handleFormChange("Agree", 4);
              }}
              checked={rating === 4}
            />
            <label htmlFor={`False`}>Agree</label>
            <input
              className="ml-1 mr-1"
              disabled={isDisable}
              type="radio"
              onChange={(e) => {
                handleFormChange("StronglyAgree", 5);
              }}
              checked={rating === 5}
            />
            <label htmlFor={`False`}>Strongly agree</label>
          </div>
        </div>
      </div>
    </div>
  );
};

const FeedbackQuestions = ({
  Index,
  questionData,
  setNewFeedbackData,
  newFeedbackData,
}) => {
  const { state } = useLocation();
  const [isDisable, setIsDisable] = useState(
    state && state?.pending ? true : false
  );
  const handleAnswerOnChange = (questionId, data) => {
    setNewFeedbackData((prev) => {
      return prev.map((val) => {
        if (val.questionId === questionId) {
          return {
            ...val,
            rating: data,
          };
        } else {
          return val;
        }
      });
    });
  };

  switch (questionData?.questionCode) {
    case "SINGLE":
      return (
        <SingleAnswer
          isDisable={isDisable}
          Index={Index}
          questionData={questionData}
          handleAnswerOnChange={handleAnswerOnChange}
        />
      );
    case "TEXT":
      return (
        <FreeText
          isDisable={isDisable}
          Index={Index}
          questionData={questionData}
          handleAnswerOnChange={handleAnswerOnChange}
        />
      );
    case "SMILEY":
      return (
        <SmileyFace
          isDisable={isDisable}
          Index={Index}
          questionData={questionData}
          handleAnswerOnChange={handleAnswerOnChange}
        />
      );
    case "STAR":
      return (
        <StarScale
          isDisable={isDisable}
          Index={Index}
          questionData={questionData}
          handleAnswerOnChange={handleAnswerOnChange}
        />
      );
    case "LIKE":
      return (
        <LikertScale
          isDisable={isDisable}
          Index={Index}
          questionData={questionData}
          handleAnswerOnChange={handleAnswerOnChange}
        />
      );
    default:
      return <>No Data!</>;
  }
};

export default FeedbackQuestions;
