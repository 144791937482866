import "./Home.css";
import React, { useContext, useState, useEffect } from "react";
import CommonDashboard from "./../CommonDashboard/CommonDashboard";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { useNavigate } from "react-router-dom";
import defaultProfileImgPic from "../../Assets/Images/user.jpg";
import { Tooltip } from "@mui/material";
import {
  getMedibuddyIdAvailable,
  getEmployeeDatForProfile,
  GetEmployeeDatForMyProfile,
  getProfilePicImg,
  getUserActionsLinks,
  getJWTGenerated,
  GetConfigMasterData,
} from "../../Helpers/APIEndPoints/EndPoints";
import notify from "../../Helpers/ToastNotification";
import { APICall } from "../../Helpers/APICalls";
import async from "react-select/dist/declarations/src/async/index";
import SSORedirection from "../../Helpers/SSO/SSORedirection";
import { quickLinks } from "./quickLinks";
// import ProLearn from "../../Assets/Images/ProLearn_Logo.jpg";
import ProLearn from "../../Assets/Images/ProLearn_logo_edit.jpg";
import { LoaderContext } from "../../Helpers/Context/Context";
import IJPHeaderIcon from "../../Assets/Images/we_rise_logo.png";
import WorkAssociateImg from "../../Assets/Images/PHOTO-2024-04-25-19-36-08.jpg";
const Home = () => {
  let { userDetails, proxyUserDetails, currentRoleId }: any = useUserContext();
  const navigate = useNavigate();
  const [profileImg, setProfileImg] = useState(null);
  const [actionLinksData, setActionLinksData] = useState(null);
  const [newHireStstus, setNewHireStstus] = useState(null);
  const [country, setCountry] = useState("");
  const { showLoader, hideLoader } = useContext(LoaderContext);

  const user = proxyUserDetails != null ? proxyUserDetails : userDetails;

  useEffect(() => {
    if (!userDetails) {
      window.location.href = `${window.location.origin}`;
    } else if (userDetails?.IsAgency) {
      navigate("/AgencyCandidateDashboard");
    } else {
      const Roles = userDetails?.Roles || [];

      const Candidate = Roles.find(
        (i) => i?.name?.toLocaleLowerCase()?.trim() == "candidate"
      );
      
      const IsCandidate = currentRoleId?.value == Candidate?.id;
      
      if (IsCandidate) {
        console.log("1");
        navigate("/anonymouscanidate");
      }
      
      const Contractor = Roles.find(
        (i) => i?.name?.toLocaleLowerCase()?.trim() == "contractor"
      );
      const IsContractor = currentRoleId?.value == Contractor?.id;

      if (IsContractor) {
        navigate("/ContractLabourdashboard");
      }
    }
    // if (userDetails?.IsCandidate) {
    //   navigate("/anonymouscanidate");
    // } else if (userDetails?.IsAgency) {
    //   navigate("/AgencyCandidateDashboard");
    // }
    getUserActionsLinksData();
    getEmployeeDatForProfileAPICall();
  }, [currentRoleId?.value]);

  const getUserActionsLinksData = async () => {
    const actionLinksResponse = await APICall(getUserActionsLinks, "POST", {});
    setActionLinksData(actionLinksResponse.data);
  };

  const getEmployeeDatForProfileAPICall = async () => {
    let EmployeeId;
    if (proxyUserDetails) {
      EmployeeId = proxyUserDetails?.value;
    } else {
      EmployeeId = userDetails?.Id;
    }

    if (EmployeeId > 0) {
      const response = await APICall(GetEmployeeDatForMyProfile, "POST", {
        TId: EmployeeId,
      });

      if (response.data) {
        setNewHireStstus(response.data?.newHireStatus);
        setCountry(response.data?.ct);
      }
    }
  };

  //getImage API
  useEffect(() => {
    (async () => {
      const getPhoto = await APICall(getProfilePicImg, "POST", {
        TId: userDetails.EmployeeId,
        AC: "ProfilePic",
      });

      if (getPhoto && getPhoto.data !== null) {
        setProfileImg(`data:image;base64,${getPhoto.data}`);
      }
    })();
  }, []);

  const handleSopClick = () => {
    const anchor = document.createElement("a");
    // anchor.href = "/WelPro_SOP_EP_V1.pdf";
    anchor.href = "/WelPro_SOP_EP_V2.pdf";
    anchor.target = "_blank";
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const getConfigData = async (masterKey) => {
    const response = await APICall(GetConfigMasterData, "POST", {});
    let masterData = [];
    if (response?.status === 0) {
      masterData = response.data;
      let value = masterData?.find(x => x.key === masterKey)?.value;
      return value;
    } else {
      return null;
    }
  };

  const onClickFunc = async (val) => {
    if (val.isSso) {
      if(val.label === "Medi Buddy"){
        //Start: Added medibuddy check 26-07-2024
        await showLoader();
        const getMedibuddyDataResp = await APICall(
          getMedibuddyIdAvailable, 
          "POST", 
          { EmployeeId : user?.EmployeeId }
        );

        await hideLoader();
        if (getMedibuddyDataResp.status !== 0)
        {
          notify(getMedibuddyDataResp.status, getMedibuddyDataResp.message);
          return null;
        }
        else if (getMedibuddyDataResp.status === 0 
          && getMedibuddyDataResp.data?.isMediBuddyIdAvailable == 'false')
        {
          notify(1, "You are not registered with Mediuddy.");
          return null;
        }
        //End: Added medibuddy check 26-07-2024
        await showLoader();
        let JWTSecret = await getConfigData('MeddyBuddyClientSecret');
        const payload = {
          Id: userDetails?.Id, 
          Secret: JWTSecret
        }

        const getJWTresponse = await APICall(getJWTGenerated, "POST", payload)
        await hideLoader();
        if(getJWTresponse.message === "Ok"){
          let token = getJWTresponse.data;
          let redirectionLink = await getConfigData('MeddyBuddyRedirectionLink');
          let url = redirectionLink.replace('<EntityId>', '11197446').replace('<JWTToken>', encodeURIComponent(token));
          window.open(url, "_blank");
        }
         
      } else{
        return SSORedirection(user, val.ssoKey);
      }
    }

    if (val.welwise) {
      const { EmailId } = user || {};
      let url = "https://wel-wise.com/login?report_name=HR Dashboard";
      if (EmailId) {
        url += `&email_id=${EmailId}`;
      }
      window.open(url, "_blank");
      return;
    }

    return;
  };

  return (
    <>
      <div className="mb-3">
        <div className="home-head">Home</div>
        <div>
          <img
            className="banner-img"
            alt="banner"
            //src={require("../../Assets/Images/banner.jpg")}
            src={require("../../Assets/Images/Intranet banner 1920pxl-01.jpg")}></img>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8">
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <div className="home_grid pt-4 p-3">
                  <a
                    onClick={(event) => {
                      if (newHireStstus && newHireStstus === "Approved") {
                        navigate("/profile", {
                          state: {
                            TId: proxyUserDetails?.EmployeeId
                              ? proxyUserDetails?.EmployeeId
                              : userDetails?.EmployeeId
                              ? userDetails?.EmployeeId
                              : 0,
                            NewHireStatus: newHireStstus,
                            CT: country,
                          },
                        });
                      }
                    }}>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center">
                      <div className="grid_icon">
                        <img
                          src={
                            profileImg != null
                              ? profileImg
                              : defaultProfileImgPic
                          }
                          alt="Profile"
                          className="home-img-center d-block"
                        />
                      </div>
                    </div>
                  </a>

                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 img-header p-0">
                    {" "}
                    My Profile{" "}
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center mb-1 p-0">
                    {/* <span className="percentage">65%</span>
                                <span className="perc-text">Complete</span> */}
                  </div>
                </div>
              </div>

              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <div className="home_grid pt-4  p-3">
                  <a
                    onClick={(event) => {
                      if (newHireStstus && newHireStstus === "Approved") {
                        navigate("/profile", {
                          state: {
                            TId: proxyUserDetails?.EmployeeId
                              ? proxyUserDetails?.EmployeeId
                              : userDetails?.EmployeeId
                              ? userDetails?.EmployeeId
                              : 0,
                            ActiveTab: "Documents",
                            CT: country,
                            NewHireStatus: newHireStstus,
                          },
                        });
                      }
                    }}>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center">
                      <div className="grid_icon">
                        <i className="fa fa-file"></i>
                      </div>
                    </div>
                  </a>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 img-header p-0">
                    My Documents{" "}
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center mb-1 p-0">
                    {/* <span className="percentage">8</span>
                                <span className="perc-text">Complete</span> */}
                  </div>
                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <a
                  onClick={() => {
                    navigate("/careers", {
                      state: { IJP: true },
                    });
                  }}
                  target="_self"
                  rel="noreferrer">
                  <div className="quicklinks_grid">
                    <div className="imgText">
                      <img
                        className="img-grid"
                        style={{
                          objectFit: "contain",
                        }}
                        src={IJPHeaderIcon}></img>
                      <p className="text-grid" style={{ visibility: "hidden" }}>
                        We Rise
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="home_grid row link-box">
              <div className="col-lg-5 p-0">
                <img
                  alt=""
                  className="handbk-img"
                  src={require("../../Assets/Images/culture_handbook.jpg")}></img>
              </div>
              <div className="col-lg-2 p-1 m-auto">
                <a
                  href="https://app.welspun.com/samay/sf/Welspun%20Culture%20HandbookNew%20Final%202.pdf"
                  target="_blank"
                  rel="noreferrer">
                  <button className="btn btn-link w-100">Link</button>
                </a>
              </div>
              <div className="col-lg-5 m-auto">
                <img
                  alt=""
                  className="height-150 qr-codeimg"
                  src={require("../../Assets/Images/QR.jpg")}></img>
              </div>
              {/* <div className="p-2">
                {actionLinksData &&
                  actionLinksData.map((Data, index) => (
                    <>
                      <div className="py-1 d-flex align-items-center">
                        <i className="fa-solid fa-square-arrow-up-right"></i>
                        <a href={Data.url} target="_blank">
                          {Data.name}
                        </a>
                      </div>
                    </>
                  ))}
                <div className="text-right my-1">
                  <button className="btn btn-action">5 more actions</button>
                </div>
              </div> */}
            </div>
          </div>

          <div className="col-lg-8">
            <div className="row">
              <div
                className="col-xl-3 col-lg-3 col-md-6 col-sm-12 my-team"
                onClick={(e) => {
                  navigate("/OrganizationChart");
                }}>
                <div className="home_grid pt-4  p-3">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center">
                    <div className="grid_icon">
                      <i className="fa fa-sitemap"></i>
                    </div>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 img-header  p-0">
                    <a>
                      {" "}
                      My Team {/*<p className="text-danger">Coming Soon</p>*/}
                    </a>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center mb-1 p-0">
                    {/* <span className="percentage">3</span>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center mb-1 p-0"> */}
                    {/* <span className="percentage">3</span>
                                <span className="perc-text">Complete</span> */}
                  </div>
                </div>
              </div>
              <div
                className="col-xl-3 col-lg-2 col-md-6 col-sm-12 my-team"
                onClick={() => {
                  navigate("/org-structure");
                }}>
                <div className="home_grid pt-4  p-3">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center">
                    <div className="grid_icon">
                      <i className="fa fa-sitemap"></i>
                    </div>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 img-header  p-0">
                    <a>
                      {" "}
                      Org Chart {/*<p className="text-danger">Coming Soon</p>*/}
                    </a>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center mb-1 p-0">
                    {/* <span className="percentage">3</span>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center mb-1 p-0"> */}
                    {/* <span className="percentage">3</span>
                                <span className="perc-text">Complete</span> */}
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <a
                  onClick={() => navigate("/LMSHome")}
                  target="_self"
                  rel="noreferrer">
                  <div className="quicklinks_grid">
                    <div className="imgText">
                      <img
                        className="img-grid"
                        style={{
                          objectFit: "contain",
                        }}
                        src={ProLearn}></img>
                      <p className="text-grid">My ProLearn</p>
                    </div>
                  </div>
                </a>
              </div>
              {/* </div>
                </div>
              </div> */}

              <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <a
                  //href="https://app.welspun.com/ProPerform"
                  onClick={() => SSORedirection(user, "Pro")}
                  target="_blank"
                  rel="noreferrer">
                  <div className="quicklinks_grid">
                    <div className="imgText">
                      <img
                        className="img-grid"
                        src={require("../../Assets/Images/pro_perform.jpg")}></img>
                      <p className="text-grid">Pro Perform</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="home_grid row link-box">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 p-0">
                <a
                  onClick={() => {
                    navigate("/Bluecollar");
                  }}
                  target="_self"
                  rel="noreferrer">
                  <div className="">
                    <div className="imgText">
                      <img
                        className="img-grid"
                        style={{
                          objectFit: "fill",
                          height: "173px",
                        }}
                        src={WorkAssociateImg}></img>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="sub-header"> Quick Links </div>
        <div className="row quick_link">
          {quickLinks.map((val) => (
            <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12">
              <a
                onClick={() => onClickFunc(val)}
                href={val.link}
                target="_blank"
                rel="noreferrer">
                <div
                  className="quicklinks_grid"
                  data-toggle="tooltip"
                  title={val.title}>
                  <div className="imgText">
                    <img
                      className={val.className || "img-grid"}
                      src={val.img}
                      alt={val.alt}
                    />
                    <p className="text-grid">{val.label}</p>
                    {/* {val.isComingSoon && (
                        <p className="text-danger">Coming Soon</p>
                      )} */}
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
      <CommonDashboard />
    </>
  );
};

export default Home;
