import React, { useContext, useEffect, useState } from "react";
import { LoaderContext } from "../../Helpers/Context/Context";
import { useNavigate } from "react-router-dom";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import notify from "../../Helpers/ToastNotification";
import InputForm from "../../Components/InputForm/InputForm";
import { Collapse } from "react-bootstrap";
import SelectForm from "../../Components/SelectForm/SelectForm";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { APICall } from "../../Helpers/APICalls";
import {
  GetCatalogueEmployeeDropdown,
  getHistoricDasboardFilterDropDown,
  getHistoricDataDasboardDetails,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";

const HistoricData = () => {
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  let { userDetails, currentRoleId }: any = useUserContext();
  const [sortDirection, setSortDirection] = useState("");
  const [sortColumn, setSortColumn] = useState("");

  const [reSet, setreSet] = useState(false);

  const [entityType, setEntityType] = useState(null);
  const [entityTypeOptions, setEntityTypeOptions] = useState([]);

  const [entityTitle, setEntityTitle] = useState(null);
  const [entityTitleOptions, setEntityTitleOptions] = useState([]);

  const [courseType, setCourseType] = useState(null);
  const [courseTypeOptions, setCourseTypeOptions] = useState([]);

  const [selectedUserId, setSelectedUserId] = useState(null);

  const getSearchableDropdownAPI = async (searchString, cb) => {
    if (searchString.length > 1) {
      const response = await APICall(GetCatalogueEmployeeDropdown, "POST", {
        searchString,
        UserId: 0,
      });
      if (response.data !== null && response.data.length > 0) {
        cb(response.data);
      } else {
        cb([]);
      }
    }
  };

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (
      currentRoleId?.code == "LMSAdmin" ||
      currentRoleId.label.toLowerCase() === "lm admin" ||
      currentRoleId.label.toLowerCase() === "lm super admin" ||
      currentRoleId?.code == "LMSSuperAdmin" ||
      currentRoleId?.code == "SystemAdmin" ||
      currentRoleId.label.toLowerCase() === "system admin"
    ) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, []);

  useEffect(() => {
    const responseData = async () => {
      const objSessionId = {
        mode: "COURSETYPE",
        userId: userDetails?.Id,
        roleId: currentRoleId?.value,
      };
      const res = await APICall(
        getHistoricDasboardFilterDropDown,
        "POST",
        objSessionId
      );
      if (res.data && res.data.length > 0) {
        setCourseTypeOptions(res.data);
      }
      const objSessionTitle = {
        mode: "ENTITYTYPE",
        userId: userDetails?.Id,
        roleId: currentRoleId?.value,
      };
      const res1 = await APICall(
        getHistoricDasboardFilterDropDown,
        "POST",
        objSessionTitle
      );
      if (res1.data && res1.data.length > 0) {
        setEntityTypeOptions(res1.data);
      }
      const objCreatedBy = {
        mode: "ENTITYTITLE",
        userId: userDetails?.Id,
        roleId: currentRoleId?.value,
      };
      // const res2 = await APICall(
      //   getHistoricDasboardFilterDropDown,
      //   "POST",
      //   objCreatedBy
      // );
      // if (res2.data && res2.data.length > 0) {
      //   setEntityTitleOptions(res2.data);
      // }
    };
    responseData();
  }, []);

  useEffect(() => {
    getHistoricDataDasboardDetailsAPICall();
  }, [page, pageSize, sortColumn, sortDirection, searchText, reSet]);

  const getHistoricDataDasboardDetailsAPICall = async () => {
    showLoader();
    const GetDashboardData = await APICall(
      getHistoricDataDasboardDetails,
      "POST",
      {
        Page: page,
        PageSize: pageSize,
        SortColumn: sortColumn,
        SortOrder: sortDirection,
        SearchText: searchText,
        CourseTypeIds: courseType?.map((s) => s.value).join(","),
        EntityTypeIds: entityType?.map((s) => s.value).join(","),
        EntityTitleIds: entityTitle?.map((s) => s.value).join(","),
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
        SelectedUserId: selectedUserId !== null ? selectedUserId.value : 0,
      }
    );
    if (
      GetDashboardData &&
      GetDashboardData.data !== null &&
      GetDashboardData.data.length > 0
    ) {
      setCount(GetDashboardData.data[0].totalCount);

      setRowData(GetDashboardData.data);
    } else {
      setRowData([]);
      setCount(0);
    }
    hideLoader();
  };

  //   const activateandDeactivateFunction = async (id: any, isActive: any) => {
  //     if (remarks === "" || remarks === null) {
  //       notify(1, "Please enter remarks");
  //     } else {
  //       showLoader();
  //       const { data } = await APICall(deactivateSessionDetailById, "POST", {
  //         Id: id,
  //         IsActive: isActive,
  //         Remarks: remarks,
  //         CreatedBy: userDetails?.Id,
  //       });

  //       setShowModal(false);
  //       setRemarks("");
  //       if (data != null) {
  //         if (!isActive) {
  //           notify(0, "Session has been successfully Activated");
  //         } else {
  //           notify(0, "Session has been successfully Deactivated");
  //         }
  //         setreSet(!reSet);
  //         hideLoader();
  //       } else {
  //         notify(1, "Something went wrong");
  //       }
  //       hideLoader();
  //     }
  //   };

  //   const showActivateandDeactivateModal = async (id: any, isActive: any) => {
  //     setShowModal(true);
  //     setId(id);
  //     if (isActive) {
  //       setModalTitle("Do you want to Inactive the Session?");
  //     } else {
  //       setModalTitle("Do you want to active the Session?");
  //     }
  //     setIsActive(isActive);
  //   };

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: pageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      setPage(page * pageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const gridColumns = [
    {
      name: "Id",
      label: "Id",
      options: {
        display: false,
      },
    },

    {
      name: "employeeCode",
      label: "Employee Code",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "fullName",
      label: "FullName",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "courseType",
      label: "Course Type",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "entityType",
      label: "Entity Type",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "entityTittle",
      label: "Entity Tittle",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "completionStatus",
      label: "Completion Status",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "completionDate",
      label: "Completion Date",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "totalHours",
      label: "Total Hours",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "manHours",
      label: "Man Hours",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
  ];

  const onSubmitFilter = () => {
    if (
      courseType?.length === 0 &&
      entityType?.length === 0 &&
      entityTitle?.length === 0
    ) {
      notify(1, "Select atleast one filter");
    } else {
      setPage(0);
      getHistoricDataDasboardDetailsAPICall();
    }
  };

  const resetFilters = () => {
    setSearchText("");
    setCourseType([]);
    setEntityType([]);
    setEntityTitle([]);
    setSelectedUserId(null);
    setreSet(!reSet);
  };
  return (
    <>
      <div className="breadcrumb modified-bredcrumb">
        <span>
          <ul>
            <li>
              <a href="/LMSHome">Home</a>
            </li>

            <li>Historic Data</li>
          </ul>
        </span>
      </div>
      <div className="container-fluid">
        <div className="grid-wrapper grid_mob">
          <div className="row mx-auto filtBox">
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="form-group filtInp">
                <InputForm
                  value={searchText}
                  placeholder={"Search Keywords"}
                  isDisabled={false}
                  textArea={false}
                  onChange={(e) => {
                    setPage(0);
                    setSearchText(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-12 filtrt">
              <ul className=" filter-icon-wel">
                <li>
                  <a
                    href="javascript:void(0);"
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                    className="filter-btn">
                    <i className="fa fa-filter"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <>
          <Collapse in={open}>
            <div id="example-collapse-text grid-wrapper">
              <div className="row mx-auto myInlineStyle">
                <>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">
                      Course Type
                    </label>
                    <SelectForm
                      isClearable
                      options={courseTypeOptions}
                      placeholder={"Course Type"}
                      isDisabled={false}
                      onChange={(event) => {
                        setCourseType(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={courseType}
                    />
                  </div>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">
                      Entity Type
                    </label>
                    <SelectForm
                      isClearable
                      options={entityTypeOptions}
                      placeholder={"Entity Type"}
                      isDisabled={false}
                      onChange={(event) => {
                        setEntityType(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={entityType}
                    />
                  </div>
                  {isAdmin && (
                    <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                      <label className="col-form-label text-white">
                        Search Employee
                      </label>
                      <SelectForm
                        isClearable
                        isSearchable
                        async
                        options={(searchString, cb) =>
                          getSearchableDropdownAPI(searchString, cb)
                        }
                        placeholder={"Search Employee"}
                        isDisabled={false}
                        value={selectedUserId}
                        onChange={async (event) => {
                          setSelectedUserId(event);
                        }}
                        isMulti={false}
                        noIndicator={false}
                        noSeparator={false}
                      />
                    </div>
                  )}

                  {/* <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">
                      Entity Title
                    </label>
                    <SelectForm
                      isClearable
                      options={entityTitleOptions}
                      placeholder={"Entity Title"}
                      isDisabled={false}
                      onChange={(event) => {
                        setEntityTitle(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={entityTitle}
                    />
                  </div> */}
                </>
                <div className="col-lg-12 d-flex mt-2 justify-content-end">
                  <button
                    onClick={() => resetFilters()}
                    className="btn btn-secondary mr-2">
                    <i className="fa fa-undo" aria-hidden="true"></i> Reset{" "}
                  </button>
                  <button
                    className="btn btn-filter-submit"
                    onClick={() => onSubmitFilter()}>
                    <i className="fa fa-search" aria-hidden="true"></i> Search{" "}
                  </button>
                </div>
              </div>
            </div>
          </Collapse>
        </>
        {}
        <div className="mb-3 pt-3">
          <DynamicGrid data={rowData} columns={gridColumns} options={options} />
        </div>
      </div>
      {/* <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">{`${modalTitle}`}</div>
        </div>
        <Modal.Body>
          <>
            <div className="row">
              
              <div className="col-md-8">
                <label className="col-form-label">Remarks</label>
                <sup>*</sup>
                <InputForm
                  value={remarks}
                  placeholder={"Remarks"}
                  isDisabled={false}
                  textArea={true}
                  onChange={(e) => {
                    setRemarks(e.target.value);
                  }}
                />
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => activateandDeactivateFunction(id, IsActive)}
          >
            Submit
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowModal(false);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};

export default HistoricData;
