import React, { useContext, useEffect, useState } from "react";
import { APICall } from "../../Helpers/APICalls";
import {
  getAllRoleMaster,
  getAllUserMaster,
  insertUserRoleDetails,
  interfaceDetails,
  getAllTabsBasedOnInterface,
  getAllSectionBasedOnTab,
  getEditUserRoleId,
  getDropdowns,
  insertUpdatePermisiion,
  getPermissionDashboardDetails,
  getUserPermissoinExcelDetails,
  insertRoleDetails,
  getAllDocumentMaster,
} from "../../Helpers/APIEndPoints/EndPoints";
import ExcelJS from "exceljs";
import SelectForm from "../../Components/SelectForm/SelectForm";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import notify from "../../Helpers/ToastNotification";
import { LoaderContext } from "../../Helpers/Context/Context";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { ExpandMore } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import InputForm from "../../Components/InputForm/InputForm";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import MyVerticallyCenteredModal from "./MyVerticallyCenteredModal";
const UserRoleMaster = () => {
  const [InterfaceDropdownOptions, setInterfaceDropdownOptions] = useState([]);
  const [InterfaceDropdownValue, setInterfaceDropdownValue] = useState(null);
  const [InterfaceErrorDropdown, setInterfaceErrorDropdown] = useState("");
  const [interfaceIdState, setInterfaceIdState] = useState(null);

  const [tabDropdownOptions, setTabDropdownOptions] = useState([]);
  const [TabDropdownValue, setTabDropdownValue] = useState(null);
  const [tabErrorDropdown, setTabErrorDropdown] = useState("");
  const [tabIdState, setTabIdState] = useState(null);

  const [isDomain, setIsDomain] = useState(false);

  const [sectionDropdownOptions, setSectionDropdownOptions] = useState([]);
  const [sectionDropdownValue, setSectionDropdownValue] = useState(null);
  const [sectionErrorDropdown, setSectionErrorDropdown] = useState("");

  const [roleDropdownOptions, setRoleDropdownOptions] = useState([]);
  const [roleDropdownValue, setRoleDropdownValue] = useState(null);
  const [roleErrorDropdown, setRoleErrorDropdown] = useState("");

  const [userDropdownOptions, setUserDropdownOptions] = useState([]);
  const [userDropdownValue, setUserDropdownValue] = useState(null);
  const [userErrorDropdown, setUserErrorDropdown] = useState("");

  const [CountryDropdownOptions, setCountryDropdownOptions] = useState([]);
  const [CountryDropdownValue, setCountryDropdownValue] = useState(null);

  const [BusinessDropdownOptions, setBusinessDropdownOptions] = useState([]);
  const [BusinessDropdownValue, setBusinessDropdownValue] = useState([]);

  const [LegalEntityDropdownOptions, setLegalEntityDropdownOptions] = useState(
    []
  );
  const [LegalEntityDropdownValue, setLegalEntityDropdownValue] =
    useState(null);

  const [SBUDropdownOptions, setSBUDropdownOptions] = useState([]);
  const [SBUDropdownValue, setSBUDropdownValue] = useState(null);

  const [DivisionDropdownOptions, setDivisionDropdownOptions] = useState([]);
  const [DivisionDropdownValue, setDivisionDropdownValue] = useState(null);

  const [DepartmentDropdownOptions, setDepartmentDropdownOptions] = useState(
    []
  );
  const [DepartmentDropdownValue, setDepartmentDropdownValue] = useState(null);

  const [SectionDropdownOptions2, setSectionDropdownOptions2] = useState([]);
  const [SectionDropdownValue2, setSectionDropdownValue2] = useState(null);

  const [SubSectionDropdownOptions, setSubSectionDropdownOptions] = useState(
    []
  );
  const [SubSectionDropdownValue, setSubSectionDropdownValue] = useState(null);

  const [SubSubSectionnDropdownOptions, setSubSubSectionnDropdownOptions] =
    useState([]);
  const [SubSubSectionnDropdownValue, setSubSubSectionnDropdownValue] =
    useState(null);

  const [EmployeeClassDropdownOptions, setEmployeeClassDropdownOptions] =
    useState([]);
  const [EmployeeClassDropdownValue, setEmployeeClassDropdownValue] =
    useState(null);

  const [FunctionDropdownOptions, setFunctionDropdownOptions] = useState([]);
  const [FunctionDropdownValue, setFunctionDropdownValue] = useState(null);

  const [phLocDropdownOptions, setphLocDropdownOptions] = useState([]);
  const [phLocDropdownValue, setphLocDropdownValue] = useState(null);
  // const [phLocDropdownValue, setphLocDropdownValue] = useState([]);

  const [PayLocDropdownOptions, setPayLocDropdownOptions] = useState([]);
  const [PayLocDropdownValue, setPayLocDropdownValue] = useState(null);

  const [JobBandDropdownOptions, setJobBandDropdownOptions] = useState([]);
  const [JobBandDropdownValue, setJobBandDropdownValue] = useState(null);

  const [JobLevelDropdownOptions, setJobLevelDropdownOptions] = useState([]);
  const [JobLevelDropdownValue, setJobLevelDropdownValue] = useState(null);

  const [CustomBusinessDropdownOptions, setCustomBusinessDropdownOptions] =
    useState([]);
  const [CustomBusinessDropdownValue, setCustomBusinessDropdownValue] =
    useState(null);

  const [
    StandardDesignationDropdownOptions,
    setStandardDesignationDropdownOptions,
  ] = useState([]);
  const [
    StandardDesignationDropdownValue,
    setStandardDesignationDropdownValue,
  ] = useState(null);

  const [DocumentDropdownOptions, setDocumentDropdownOptions] = useState([]);
  const [DocumentDropdownValue, setDocumentDropdownValue] = useState(null);

  useEffect(() => {
    // console.log("v", FunctionDropdownOptions);
  }, [FunctionDropdownOptions]);

  const [CostCenterDropdownOptions, setCostCenterDropdownOptions] = useState(
    []
  );
  const [CostCenterDropdownValue, setCostCenterDropdownValue] = useState(null);
  const [checkboxState, setCheckboxState] = useState(false);
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [rowData, setRowData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("");
  const [panal1, setPanal1] = useState(true);
  const [panal2, setPanal2] = useState(true);
  const [panal3, setPanal3] = useState(true);
  const navigate = useNavigate();
  // popup box
  const [roleName, setRoleName] = useState("");
  const [systemName, setSystemName] = useState("");
  const [roleType, setRoleType] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const [isProxyUserRole, setIsProxyUserRole] = useState(false);
  const [roleNameError, setRoleNameError] = useState("");
  const [systemNameError, setSystemNameError] = useState("");
  const [roleTypeError, setRoleTypeError] = useState("");

  const [modalShow, setModalShow] = React.useState(false);
  const { userDetails, currentRoleId }: any = useUserContext();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [displayAccordion, setDisplayAccordion] = useState(false);
  const [dropDown1, setDropDown1] = useState(true);
  const { state } = useLocation();
  const [UId, setUId] = useState(state !== null && state.UId ? state.UId : 0);
  const [UIdLabel, setUIdLabel] = useState(
    state !== null && state.UIdLabel ? state.UIdLabel : 0
  );
  const [PermissionRowData, setPermissionRowData] = useState([]);
  const [Percount, setPerCount] = useState(0);
  const [Perpage, setPerPage] = useState(0);
  const [PerSortColumn, setPerSortColumn] = useState("");
  const [PerSortDirection, setPerSortDirection] = useState("");
  const [searchText, setSearchText] = useState("");
  const [PageSize, setPageSize] = useState(10);
  const columns = [
    { name: "ids", label: "Id", options: { display: false } },
    {
      name: "label",
      label: "Section / Interface Name",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: false,
      },
    },
    {
      name: "selectAllEdit",
      label: (
        <div className="endStyle">
          <input type="checkbox" onChange={(e) => handleSelectAllEdit(e)} />
        </div>
      ),
      options: {
        filter: false,
        sort: false,
        sortDescFirst: false,
        customBodyRender: () => null,
      },
    },
    {
      name: "isEdit",
      label: "Edit",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div className="centerStyles">
                <input
                  type="checkbox"
                  checked={value}
                  onChange={(e) =>
                    handleCheckboxChange(e, tableMeta.rowData, !value, "isEdit")
                  }
                />
              </div>
            </>
          );
        },
      },
    },
    {
      name: "selectAllView",
      label: (
        <div className="endStyle">
          <input type="checkbox" onChange={(e) => handleSelectAllView(e)} />
        </div>
      ),
      options: {
        filter: false,
        sort: false,
        sortDescFirst: false,
        customBodyRender: () => null,
      },
    },
    {
      name: "isView",
      label: "View",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div className="centerStyles">
                <input
                  type="checkbox"
                  checked={value}
                  onChange={(e) =>
                    handleCheckboxChange(
                      e,
                      tableMeta.rowData,

                      !value,
                      "isView"
                    )
                  }
                />
              </div>
            </>
          );
        },
      },
    },
    {
      name: "selectAllDelete",
      label: (
        <div className="endStyle">
          <input type="checkbox" onChange={(e) => handleSelectAllDelete(e)} />
        </div>
      ),
      options: {
        filter: false,
        sort: false,
        sortDescFirst: false,
        customBodyRender: () => null,
      },
    },
    {
      name: "isDelete",
      label: "Delete",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div className="centerStyles">
                <input
                  type="checkbox"
                  checked={value}
                  onChange={(e) =>
                    handleCheckboxChange(
                      e,
                      tableMeta.rowData,
                      !value,
                      "isDelete"
                    )
                  }
                />
              </div>
            </>
          );
        },
      },
    },
    {
      name: "selectAllHistory",
      label: (
        <div className="endStyle">
          <input type="checkbox" onChange={(e) => handleSelectAllHistory(e)} />
        </div>
      ),
      options: {
        filter: false,
        sort: false,
        sortDescFirst: false,
        customBodyRender: () => null,
      },
    },
    {
      name: "isHistory",
      label: "History",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div className="centerStyles">
                <input
                  type="checkbox"
                  checked={value}
                  onChange={(e) =>
                    handleCheckboxChange(
                      e,
                      tableMeta.rowData,
                      !value,
                      "isHistory"
                    )
                  }
                />
              </div>
            </>
          );
        },
      },
    },
  ];

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: count,
    page: page,
    serverSide: true,
    onChangePage: async (page) => {
      setPage(page);
    },
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",
    onColumnSortChange: async (sortColumn, sortDirection) => {
      setPage(0);
      if (sortDirection === "asc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
    },
  };

  useEffect(() => {
    fetchData("");
  }, [userDropdownValue]);

  const fetchData = async (SearchQuery: string) => {
    await fetchUserData(SearchQuery);
    fetchRoleData();
    fetchDocumentData();
    fetchInterfaceData();
    BusinessOptionValue();
    SBUOptionValue();
    LEOptionValue();
    CTOptionValue();
    DivisionOtionValue();
    DepartmentOptionValue();
    FunctionOptionValue();
    SectionOptionValue();
    ECOptionValue();
    JobBandOptionValue();
    JobLevelOptionValue();
    SDOptionValue();
    PhlocOptionvalue();
    PLocOptionValue();
    CountryOptionValue();
  };

  useEffect(() => {
    // fetchTabData();
    setSectionDropdownValue(null);
  }, [InterfaceDropdownValue]);

  useEffect(() => {}, [checkboxState]);

  useEffect(() => {
    if (dropDown1) {
      getUserId();
    }
  }, [
    userDropdownOptions,
    roleDropdownOptions,
    BusinessDropdownOptions,
    SBUDropdownOptions,
    LegalEntityDropdownOptions,
    DivisionDropdownOptions,
    phLocDropdownOptions,
    CountryDropdownOptions,
    PayLocDropdownOptions,
    StandardDesignationDropdownOptions,
    EmployeeClassDropdownOptions,
    JobBandDropdownOptions,
    JobLevelDropdownOptions,
    CustomBusinessDropdownOptions,
  ]);

  useEffect(() => {
    getGridPermission();
  }, [userDropdownValue, roleDropdownValue]);

  useEffect(() => {
    getGridPermission();
  }, [Perpage, searchText, PerSortDirection, PerSortColumn]);

  console.log("Add label", state.UIdLabel);

  // From main Dashboard Edit fetch data
  const getUserId = async () => {
    showLoader();
    if (
      state !== null &&
      state?.UId > 0 &&
      state?.UIdLabel &&
      userDropdownOptions?.length > 0 &&
      roleDropdownOptions.length > 0 &&
      BusinessDropdownOptions.length > 0 &&
      SBUDropdownOptions.length > 0 &&
      LegalEntityDropdownOptions.length > 0
    ) {
      const GetEditUserRoleResp = await APICall(getEditUserRoleId, "POST", {
        Id: state?.UId,
      });

      let userId = GetEditUserRoleResp.data?.userId;
      let userFN = GetEditUserRoleResp.data?.firstName;
      let userLN = GetEditUserRoleResp.data?.lastName;
      let userLabel = `${userFN} ${userLN}`;
      userDropdownOptions.push({ value: userId, label: UIdLabel });
      // let userObj = userDropdownOptions.find(
      //   (item) => item.label === userLabel
      // );

      let userObj = userDropdownOptions.find((item) => item.value === userId);

      setIsDomain(GetEditUserRoleResp.data?.isDomain);
      setUserDropdownValue(userObj);
      setIsFormSubmitted(true);
      setPanal2(true);
      setDisplayAccordion(true);
      let roleId = GetEditUserRoleResp.data?.roleId;
      let roleObj = roleDropdownOptions.find((item) => item.value === roleId);
      setRoleDropdownValue(roleObj);

      let RoleTypeId = GetEditUserRoleResp.data?.roleTypeId;
      if (RoleTypeId === 2) {
        setDisplayDropdown(true);
      } else {
        setDisplayDropdown(false);
      }

      let ouDetails = GetEditUserRoleResp.data?.ouDetails;

      if (ouDetails) {
        //From dashboard Business
        const respBisOp = ouDetails.bis;
        const selectedBisLabels = [];
        respBisOp?.map((value) => {
          const Bis = BusinessDropdownOptions.find(
            (option) => option.value == value
          );
          if (Bis !== undefined) {
            selectedBisLabels.push(Bis);
          }
        });
        setBusinessDropdownValue(selectedBisLabels);

        //From dashboard SBU
        const respSBUOp = ouDetails.sbu;
        const selectedSBULabels = [];
        respSBUOp?.map((value) => {
          const SBU = SBUDropdownOptions.find(
            (options) => options.value == value
          );
          if (SBU !== undefined) {
            selectedSBULabels.push(SBU);
          }
        });
        setSBUDropdownValue(selectedSBULabels);

        //From dashboard Legal Entity
        const resplenOp = ouDetails.len;
        const selectedlenLabels = [];
        resplenOp?.map((value) => {
          const Len = LegalEntityDropdownOptions.find(
            (options) => options.value == value
          );
          if (Len !== undefined) {
            selectedlenLabels.push(Len);
          }
        });
        setLegalEntityDropdownValue(selectedlenLabels);

        //From dashboard Division
        const respDvOp = ouDetails.dv;
        const selectedDvLabels = [];
        respDvOp?.map((value) => {
          const DV = DivisionDropdownOptions.find(
            (options) => options.value == value
          );
          if (DV !== undefined) {
            selectedDvLabels.push(DV);
          }
        });
        setDivisionDropdownValue(selectedDvLabels);

        //From dashboard Custom business
        let BIS = respBisOp;
        let SBU = respSBUOp;
        let LEN = resplenOp;

        let PId = `${BIS},${SBU},${LEN}`;
        const cubisData = await getDDData("CUSBIS", PId);
        setCustomBusinessDropdownOptions(cubisData);
        const respCubisOp = ouDetails.cusbis;
        const selectedCub = [];
        respCubisOp?.map((value) => {
          const CuBis = cubisData.find((options) => options.value == value);
          if (CuBis !== undefined) {
            selectedCub.push(CuBis);
          }
        });
        console.log(selectedCub.map(({ value, label }) => ({ value, label })));

        setCustomBusinessDropdownValue(selectedCub);

        //From dashboard Function
        const respFUNisOp = ouDetails.func;
        const selectedFunLabels = [];
        respFUNisOp?.map((value) => {
          const Fun = FunctionDropdownOptions.find(
            (options) => options.value == value
          );
          if (Fun !== undefined) {
            selectedFunLabels.push(Fun);
          }
        });
        setFunctionDropdownValue(selectedFunLabels);

        //From dashboard Department
        const respDeptOp = ouDetails.dpt;
        const selectedDptLabels = [];
        respDeptOp?.map((value) => {
          const Dpt = DepartmentDropdownOptions.find(
            (options) => options.value == value
          );
          if (Dpt !== undefined) {
            selectedDptLabels.push(Dpt);
          }
        });
        setDepartmentDropdownValue(selectedDptLabels);

        //From dashboard section
        const respScnOp = ouDetails.scn;
        const selectedScnLabels = [];
        respScnOp?.map((value) => {
          const Scn = SectionDropdownOptions2.find(
            (options) => options.value == value
          );
          if (Scn !== undefined) {
            selectedScnLabels.push(Scn);
          }
        });
        setSectionDropdownValue2(selectedScnLabels);

        //From dashboard Employee Class
        const respECOp = ouDetails.ec;
        const selectedECLabels = [];
        respECOp?.map((value) => {
          const EC = EmployeeClassDropdownOptions.find(
            (option) => option.value === value
          );
          if (EC !== undefined) {
            selectedECLabels.push(EC);
          }
        });
        setEmployeeClassDropdownValue(selectedECLabels);

        //From dashboard Job Band
        // let LEN = resplenOp;  //LEN Already declare
        let EC = respECOp;
        let PID = `${LEN},${EC}`;

        const JBData = await getDDData("JB", PID);
        setJobBandDropdownOptions(JBData);
        const respJBOp = ouDetails.jb;
        const selectedJBLabels = [];
        respJBOp?.map((value) => {
          const JB = JBData.find((option) => option.value == value);
          if (JB !== undefined) {
            selectedJBLabels.push(JB);
          }
        });
        setJobBandDropdownValue(selectedJBLabels);

        //From dashboard Job Level
        const respJLOp = ouDetails.jl;
        const selectedJLLabels = [];
        respJLOp?.map((value) => {
          const JL = JobLevelDropdownOptions.find(
            (option) => option.value == value
          );
          if (JL !== undefined) {
            selectedJLLabels.push(JL);
          }
        });
        setJobLevelDropdownValue(selectedJLLabels);

        //From dashboard Standard Designation
        const respSDOp = ouDetails.sd;
        const selectedSDLabels = [];
        respSDOp?.map((value) => {
          const SD = StandardDesignationDropdownOptions.find(
            (option) => option.value == value
          );
          if (SD !== undefined) {
            selectedSDLabels.push(SD);
          }
        });
        setStandardDesignationDropdownValue(selectedSDLabels);

        //From dashboard Phyisical Location
        const respPhlocOp = ouDetails.phloc;
        const selectedPhlocLabels = [];
        respPhlocOp?.map((value) => {
          const phloc = phLocDropdownOptions.find(
            (option) => option.value === value
          );
          if (phloc !== undefined) {
            selectedPhlocLabels.push(phloc);
          }
        });
        setphLocDropdownValue(selectedPhlocLabels);

        //From dashboard payroll Location
        const respPlocOp = ouDetails.ploc;
        const foundPloc = [];
        respPlocOp?.map((value) => {
          const Ploc = PayLocDropdownOptions.find(
            (option) => option.value == value
          );
          if (Ploc !== undefined) {
            foundPloc.push(Ploc);
          }
        });
        setPayLocDropdownValue(foundPloc);

        //From dashboard country
        const respCouOp = ouDetails.cou;
        const foundCous = [];
        respCouOp?.map((value) => {
          const cou = CountryDropdownOptions.find(
            (option) => option.value == value
          );
          if (cou !== undefined) {
            foundCous.push(cou);
          }
        });
        setCountryDropdownValue(foundCous);
      }

      let docDetail = GetEditUserRoleResp.data?.documents;
      if (docDetail?.length > 0) {
        setDocumentDropdownValue(docDetail);
      }
      setDropDown1(false);
      hideLoader();
    }
    hideLoader();
  };

  const handleInterfaceChange = async (selectedOption: any) => {
    showLoader();
    try {
      setRowData([]);
      setInterfaceDropdownValue(selectedOption);
      const interfaceLabel = selectedOption?.label;
      const interfaceId = selectedOption?.value;
      setInterfaceIdState(interfaceLabel);
      await getGridStructureTwo(interfaceId, 0);
      setInterfaceIdState(interfaceId);
      fetchTabData(interfaceId);
      // setInterfaceErrorDropdown("");
      setSectionDropdownValue(null);
      setTabDropdownValue(null);
      setTabDropdownOptions([]);
    } catch (error) {
      console.error("Error handling interface change:", error);
    } finally {
      hideLoader();
    }
  };

  const fetchTabData = async (interfaceId: any) => {
    try {
      const response = await APICall(getAllTabsBasedOnInterface, "POST", {
        InterfaceId: interfaceId,
      });

      if (response.data.length > 0) {
        setTabDropdownOptions(response.data);
        setRowData([]);
      } else {
        setSectionDropdownOptions(InterfaceDropdownValue);
        setSectionDropdownOptions([]);
      }
    } catch (error) {
      console.error("Error fetching tab data:", error);
    }
  };

  //Call api for all Dropdown
  const getDDData = async (AC, PID) => {
    const dropdowndataResponse = await APICall(getDropdowns, "POST", {
      AC,
      PID,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });
    const dropdownData = dropdowndataResponse.data;

    return dropdownData;
  };

  const getGridStructureTwo = async (interfaceIdState, tabId) => {
    showLoader();
    try {
      const GetDashboardData = await APICall(getAllSectionBasedOnTab, "POST", {
        InterfaceId: interfaceIdState,
        TabId: tabId !== undefined && tabId !== null ? tabId : 0,
        UserId: userDropdownValue.value,
        RoleId: roleDropdownValue.value,
      });

      if (
        (GetDashboardData.data !== null && GetDashboardData.data.length > 0) ||
        GetDashboardData.data.length == 0
      ) {
        const newData = GetDashboardData.data?.map((item) => ({
          //InterfaceName: InterfaceDropdownValue.label,
          ids: item.sectionId + "_" + item.tabId + "_" + item.interfaceId,
          label: item.label,
          isEdit: item.isEdit,
          isView: item.isView,
          isDelete: item.isDelete,
          isHistory: item.isHistory,
          TabId: item.tabId,
          SectionId: item.sectionId,
          InterfaceId: item.interfaceId,
        }));
        setRowData(newData);
      } else {
        setRowData([]);
      }
    } catch (error) {
      console.error("Error fetching grid data:", error);
      hideLoader();
    }
    hideLoader();
  };

  const handleCheckboxChange = async (e, dashdata, item, property) => {
    let temp1 = dashdata;
    let currentItem = item;

    let finalData = rowData;

    finalData = finalData?.map((finalitem) => {
      if (finalitem.ids === dashdata[0]) {
        return {
          ...finalitem,
          [property]:
            property === "isEdit"
              ? !finalitem.isEdit
              : property === "isView"
              ? e.target.checked
              : property === "isDelete"
              ? e.target.checked
              : property === "isHistory"
              ? e.target.checked
              : finalitem[property],
        };
      } else {
        return finalitem;
      }
    });

    temp1 = temp1?.map((e) => (e?.id === currentItem ? currentItem : e));
    setRowData(finalData);
    console.log("finalData", finalData);
  };

  const handleSelectAllEdit = (e) => {
    const allChecked = e.target.checked;
    const updatedData = rowData?.map((item) => ({
      ...item,
      isEdit: allChecked,
    }));

    setRowData(updatedData);
  };

  const handleSelectAllView = (e) => {
    const allChecked = e.target.checked;
    const updatedData = rowData?.map((item) => ({
      ...item,
      isView: allChecked,
    }));

    setRowData(updatedData);
  };

  const handleSelectAllDelete = (e) => {
    const allChecked = e.target.checked;
    const updatedData = rowData?.map((item) => ({
      ...item,
      isDelete: allChecked,
    }));

    setRowData(updatedData);
  };

  const handleSelectAllHistory = (e) => {
    const allChecked = e.target.checked;
    const updatedData = rowData?.map((item) => ({
      ...item,
      isHistory: allChecked,
    }));

    setRowData(updatedData);
  };

  const handleTabChange = async (selectedOption: any) => {
    showLoader();
    try {
      const tabId = selectedOption.value;
      setTabDropdownValue(selectedOption);
      await getGridStructureTwo(interfaceIdState, tabId);
      setInterfaceErrorDropdown("");
      setTabIdState(selectedOption.value);
    } catch (error) {
      console.error("Error handling tab change:", error);
    } finally {
      hideLoader();
    }
  };

  const fetchRoleData = async () => {
    try {
      const response = await APICall(getAllRoleMaster, "POST", {});
      let removeContractor = response?.data.filter((item)=>item.code !=="Contractor")
      setRoleDropdownOptions(removeContractor);
    } catch (error) {
      console.error("Error fetching Role data:", error);
    }
  };

  const fetchDocumentData = async () => {
    try {
      const response = await APICall(getAllDocumentMaster, "POST", {});
      setDocumentDropdownOptions(response.data);
    } catch (error) {
      console.error("Error fetching Role data:", error);
    }
  };

  const fetchInterfaceData = async () => {
    try {
      const response = await APICall(interfaceDetails, "POST", {});
      setInterfaceDropdownOptions(response.data);
      //console.log(response.data);
    } catch (error) {
      console.error("Error fetching Role data:", error);
    }
  };

  const fetchUserData = async (SearchQuery: string) => {
    try {
      const response = await APICall(getAllUserMaster, "POST", {
        SearchQuery: SearchQuery || "",
      });
      setUserDropdownOptions(response.data);
      //console.log(response.data);
    } catch (error) {
      console.error("Error fetching Role data:", error);
    }
  };

  // Handle Document Change
  const handleDocumentChange = (selectedOption: any) => {
    showLoader();
    setDocumentDropdownValue(selectedOption);
    hideLoader();
  };

  // Handle Role change
  const handleRoleChange = (selectedOption: any) => {
    showLoader();

    if (selectedOption && selectedOption.roleId === 2) {
      setDisplayDropdown(true);
    } else {
      setDisplayDropdown(false);
    }
    setRoleDropdownValue(selectedOption);
    setRoleErrorDropdown("");
    hideLoader();
  };

  //Handle User change
  const handleUserChange = async (selectedOption: any, cb) => {
    if (selectedOption.length >= 3) {
      showLoader();
      setUserErrorDropdown("");
      const response = await APICall(getAllUserMaster, "POST", {
        SearchQuery: selectedOption || "",
      });
      //setUserDropdownOptions(response.data);
      // let optionsData = await fetchData(selectedOption);
      await cb(response.data);
    } else {
      setUserDropdownOptions([]);
    }

    hideLoader();
  };

  const handleUserChangeValue = (selectedOption: any) => {
    setUserDropdownValue(selectedOption);
  };

  const handleSubmit = async () => {
    if (userDropdownValue && roleDropdownValue) {
      const response = await APICall(insertUserRoleDetails, "POST", {
        UserId: userDropdownValue?.value,
        RoleId: roleDropdownValue?.value,
        CreatedBy: userDetails.Id,
        SectionId: sectionDropdownValue,
        IsDomain: isDomain,
        documents: DocumentDropdownValue?.map((e) => e.value),
        OUDetails: {
          BIS: BusinessDropdownValue
            ? [...BusinessDropdownValue.map((e) => e.value)]
            : [],
          SBU: SBUDropdownValue
            ? [...SBUDropdownValue.map((e) => e.value)]
            : [],
          LEN: LegalEntityDropdownValue
            ? [...LegalEntityDropdownValue.map((e) => e.value)]
            : [],
          DV: DivisionDropdownValue
            ? [...DivisionDropdownValue.map((e) => e.value)]
            : [],
          COU: CountryDropdownValue
            ? [...CountryDropdownValue?.map((e) => e.value)]
            : [],
          PHLOC: phLocDropdownValue
            ? [...phLocDropdownValue?.map((e) => e.value)]
            : [],
          CUSBIS: CustomBusinessDropdownValue
            ? [...CustomBusinessDropdownValue?.map((e) => e.value)]
            : [],
          DPT: DepartmentDropdownValue
            ? [...DepartmentDropdownValue.map((e) => e.value)]
            : [],
          SCN: SectionDropdownValue2
            ? [...SectionDropdownValue2?.map((e) => e.value)]
            : [],
          EC: EmployeeClassDropdownValue
            ? [...EmployeeClassDropdownValue.map((e) => e.value)]
            : [],
          JB: JobBandDropdownValue
            ? [...JobBandDropdownValue.map((e) => e.value)]
            : [],
          JL: JobLevelDropdownValue
            ? [...JobLevelDropdownValue.map((e) => e.value)]
            : [],
          SD: StandardDesignationDropdownValue
            ? [...StandardDesignationDropdownValue.map((e) => e.value)]
            : [],
          PLOC: PayLocDropdownValue
            ? [...PayLocDropdownValue?.map((e) => e.value)]
            : [],
          FUNC: FunctionDropdownValue
            ? [...FunctionDropdownValue.map((e) => e.value)]
            : [],
        },
      });
      // console.log("API response:", response);
      if (response.data === 1) {
        notify(0, `${response.message}.`);
        setDisplayDropdown(true);
        setDisplayAccordion(true);
        setIsFormSubmitted(true);
        setPanal2(true);
        setPanal1(true);
      } else {
        notify(0, `${response.message}`);
        setDisplayDropdown(true);
        setDisplayAccordion(true);
        setIsFormSubmitted(true);
        setPanal2(true);
      }
    } else {
      setDisplayDropdown(false);
      setDisplayAccordion(false);
      setIsFormSubmitted(false);
      notify(1, "Please select user and role");
    }
  };

  //Handle On change start
  const handleOnChange = async (DDName, e) => {
    if (DDName === "BIS") {
      setBusinessDropdownValue(e);
      setSBUDropdownValue(null);
      // setSubSectionDropdownOptions([]);
      const commaSeparatedBISIds =
        e && e.length > 0 && e?.map((e) => e.value).join(",");
      if (commaSeparatedBISIds) {
        let sbuData = await getDDData("SBU", commaSeparatedBISIds);
        setSBUDropdownOptions(sbuData);
      }
    }

    if (DDName === "SBU") {
      setSBUDropdownValue(e);
      setLegalEntityDropdownValue(null);
      const commaSeparatedSBUIds =
        e && e.length > 0 && e?.map((e) => e.value).join(",");
      if (commaSeparatedSBUIds) {
        let LENData = await getDDData("LEN", commaSeparatedSBUIds);
        setLegalEntityDropdownOptions(LENData);
      }
    }

    if (DDName === "LEN") {
      let PID = "";
      setLegalEntityDropdownValue(e);
      setDivisionDropdownValue(null);
      setEmployeeClassDropdownValue(null);
      setphLocDropdownValue([]);
      let BIS = BusinessDropdownValue?.map((e) => e.value).join(",");
      let SBU = SBUDropdownValue?.map((e) => e.value).join(",");
      let LEN = e?.map((e) => e.value).join(",");

      let PId = `${BIS}-${SBU}-${LEN}`;
      if (PId) {
        const cubisData = await getDDData("CUSBIS", PID);
        setCustomBusinessDropdownOptions(cubisData);
      }
      const commaSeparatedLENIds =
        e && e.length > 0 && e?.map((e) => e.value).join(",");
      if (commaSeparatedLENIds) {
        let DVData = await getDDData("DV", commaSeparatedLENIds);
        setDivisionDropdownOptions(DVData);
      }
      if (commaSeparatedLENIds) {
        let ECData = await getDDData("EC", commaSeparatedLENIds);
        setEmployeeClassDropdownOptions(ECData);
      }

      if (commaSeparatedLENIds) {
        let PayLocData = await getDDData("PLOC", commaSeparatedLENIds);
        setPayLocDropdownOptions(PayLocData);
      }
    }

    if (DDName === "DV") {
      let PID = "";
      setDivisionDropdownValue(e);
      setFunctionDropdownValue(null);
      const commaSeparatedDVIds =
        e && e.length > 0 && e?.map((e) => e.value).join(",");
      if (commaSeparatedDVIds) {
        let FUNCData = await getDDData("FUNC", commaSeparatedDVIds);
        setFunctionDropdownOptions(FUNCData);
      }
    }

    if (DDName === "FUNC") {
      let PID = "";
      setFunctionDropdownValue(e);
      setDepartmentDropdownValue(null);
      const commaSeparatedFUNCIds =
        e && e.length > 0 && e?.map((e) => e.value).join(",");
      if (commaSeparatedFUNCIds) {
        let DeptData = await getDDData("DPT", commaSeparatedFUNCIds);
        setDepartmentDropdownOptions(DeptData);
      }
    }

    if (DDName === "EC") {
      setEmployeeClassDropdownValue(e);
      setJobBandDropdownValue(null);
      let LEN = LegalEntityDropdownValue?.value;
      let EC = e?.value;
      let PID = `${LEN},${EC}`;

      if (PID) {
        const JBData = await getDDData("JB", PID);
        setJobBandDropdownOptions(JBData);
      }
    }

    if (DDName === "DPT") {
      let PID = "";
      setDepartmentDropdownValue(e);
      setSectionDropdownValue2(null);
      const commaSeparatedDPTIds =
        e && e.length > 0 && e?.map((e) => e.value).join(",");
      if (commaSeparatedDPTIds) {
        let SecData = await getDDData("SEC", commaSeparatedDPTIds);
        // setSectionDropdownValue2(SecData);
        setSectionDropdownOptions2(SecData);
      }
    }

    if (DDName === "SEC") {
      let PID = "";
      setSectionDropdownValue2(e);
    }

    //depend on EC and LE
    if (DDName === "JB") {
      let PID = "";
      setJobBandDropdownValue(e);
      setJobLevelDropdownValue(null);
      const commaSeparatedJBIds =
        e && e.length > 0 && e?.map((e) => e.value).join(",");
      if (commaSeparatedJBIds) {
        let JLData = await getDDData("JL", commaSeparatedJBIds);
        setJobLevelDropdownOptions(JLData);
      }
    }

    if (DDName === "JL") {
      let PID = "";
      setJobLevelDropdownValue(e);
      setStandardDesignationDropdownValue(null);
      const commaSeparatedJLIds =
        e && e.length > 0 && e?.map((e) => e.value).join(",");
      if (commaSeparatedJLIds) {
        let SDData = await getDDData("SD", commaSeparatedJLIds);
        setStandardDesignationDropdownOptions(SDData);
      }
    }

    if (DDName === "SD") {
      let PID = "";
      setStandardDesignationDropdownValue(e);
    }

    if (DDName === "CUSBIS") {
      setCustomBusinessDropdownValue(e);
    }

    if (DDName === "PLOC") {
      let PID = "";
      setPayLocDropdownValue(e);
      // const commaSeparatedPLOCIds =
      //   e && e.length > 0 && e?.map((e) => e.value).join(",");
      // if (commaSeparatedPLOCIds) {
      //   let PLOCData = await getDDData("LEN", commaSeparatedPLOCIds);
      //   setPayLocDropdownOptions(PLOCData);
      // }
    }

    if (DDName === "COU") {
      let PID = "";
      setCountryDropdownValue(e);
    }

    if (DDName === "PHLOC") {
      let PID = "";
      setphLocDropdownValue(e);
    }
  };
  //Handle On change end

  const BusinessOptionValue = async () => {
    const OptionValue = await getDDData("BISP", "");
    setBusinessDropdownOptions(OptionValue);
  };

  const SBUOptionValue = async () => {
    const OptionValue = await getDDData("SBUP", "");
    setSBUDropdownOptions(OptionValue);
  };

  const LEOptionValue = async () => {
    const OptionValue = await getDDData("LENP", "");
    setLegalEntityDropdownOptions(OptionValue);
  };

  const DivisionOtionValue = async () => {
    const optionValue = await getDDData("DV", "");
    setDivisionDropdownOptions(optionValue);
  };

  const FunctionOptionValue = async () => {
    const OptionValue = await getDDData("FUNC", "");
    setFunctionDropdownOptions(OptionValue);
  };

  const DepartmentOptionValue = async () => {
    const OptionValue = await getDDData("DPT", "");
    setDepartmentDropdownOptions(OptionValue);
  };

  const SectionOptionValue = async () => {
    const OptionValue = await getDDData("SCN", "");
    setSectionDropdownOptions2(OptionValue);
  };

  const ECOptionValue = async () => {
    const OptionValue = await getDDData("EC", "");
    setEmployeeClassDropdownOptions(OptionValue);
  };

  const CTOptionValue = async () => {
    const OptionValue = await getDDData("COU", "");
    const filteredOptions = OptionValue.filter((option) =>
      // Added a new Country. Raj Potdar, Date : 12-03-2024
      ["India", "United Kingdom", "United States", "Saudi Arabia"].includes(
        option.label
      )
    );

    setCountryDropdownOptions(filteredOptions);
  };

  const JobBandOptionValue = async () => {
    const OptionValue = await getDDData("JB", "");
    setJobBandDropdownOptions(OptionValue);
  };

  const SDOptionValue = async () => {
    const OptionValue = await getDDData("SD", "");
    setStandardDesignationDropdownOptions(OptionValue);
  };

  const PhlocOptionvalue = async () => {
    const OptionValue = await getDDData("PHLOC", "");
    setphLocDropdownOptions(OptionValue);
  };

  const JobLevelOptionValue = async () => {
    const OptionValue = await getDDData("JL", "");
    setJobLevelDropdownOptions(OptionValue);
  };

  const PLocOptionValue = async () => {
    const OptionValue = await getDDData("PLOC", "");
    setPayLocDropdownOptions(OptionValue);
  };

  const CountryOptionValue = async () => {
    const OptionValue = await getDDData("COU", "");
    const filteredOptions = OptionValue.filter((option) =>
      // Added a new country , Raj Potdar,Date : 12-03-2024
      ["India", "United Kingdom", "United States", "Saudi Arabia"].includes(
        option.label
      )
    );

    setCountryDropdownOptions(filteredOptions);
  };

  const handlePermissionSubmit = async (interfaceIdState, tabIdState) => {
    showLoader();
    const updatedRowData = rowData?.map((item) => ({
      ...item,
      CreatedBy: userDetails.Id,
      // TabId: tabIdState !== null ? tabIdState : 0,
      interfaceIdState: interfaceIdState,
    }));
    const response = await APICall(insertUpdatePermisiion, "POST", {
      UserId: userDropdownValue?.value,
      RoleId: roleDropdownValue?.value,
      finalData: updatedRowData,
    });
    if (response.data == 1) {
      notify(0, `${response.message}.`);
      await getGridPermission();
      hideLoader();
    } else {
      notify(1, `${response.message}.`);
      hideLoader();
    }
    hideLoader();
  };

  // Permission Dashboard
  const Permissioncolumns = [
    {
      name: "permissionName",
      label: "Permission Name",
      options: {
        filter: true,
        sort: true,
        sortDescFirst: false,
      },
    },
    {
      name: "roleName",
      label: "Role Name",
      options: {
        filter: true,
        sort: true,
        sortDescFirst: false,
      },
    },
    {
      name: "interfaceName",
      label: "Interface Name",
      options: {
        filter: true,
        sort: true,
        sortDescFirst: false,
      },
    },
    {
      name: "tabName",
      label: "Tab Name",
      options: {
        filter: true,
        sort: true,
        sortDescFirst: false,
      },
    },
    {
      name: "sectionName",
      label: "Section Name",
      options: {
        filter: true,
        sort: true,
        sortDescFirst: false,
      },
    },
  ];

  const PermissionOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: Percount,
    rowsPerPage: PageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",
    onChangePage: async (Perpage) => {
      setPerPage(Perpage);
    },
    onColumnSortChange: async (PerSortColumn, PerSortDirection) => {
      setPerPage(Perpage);
      if (PerSortDirection === "asc") {
        await setPerSortColumn(PerSortColumn);
        await setPerSortDirection(PerSortDirection);
      }
      if (PerSortDirection === "desc") {
        await setPerSortColumn(PerSortColumn);
        await setPerSortDirection(PerSortDirection);
      }
    },
  };

  const onSearchFilter = (event, type) => {
    setSearchText(event.target.value);
  };

  const getGridPermission = async () => {
    showLoader();
    try {
      const PermissionResp = await APICall(
        getPermissionDashboardDetails,
        "POST",
        {
          UserId: userDropdownValue?.value,
          RoleId: roleDropdownValue?.value,
          PageNumber: Perpage,
          PageSize: PageSize,
          SearchText: searchText,
          SortColumn: PerSortColumn,
          SortOrder: PerSortDirection,
        }
      );

      if (PermissionResp.data && PermissionResp.data.length > 0) {
        showLoader();
        setPermissionRowData(PermissionResp.data);
        setPerCount(PermissionResp.data[0].count);
        hideLoader();
      } else {
        setPermissionRowData([]);
      }
    } catch (error) {
      console.error("Error fetching grid data:", error);
      hideLoader();
    }
    hideLoader();
  };

  // excel for Permission Dashboard
  const toTitleCase = (str) => {
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  };

  const exportToExcel = async () => {
    showLoader();
    const response = await APICall(getUserPermissoinExcelDetails, "POST", {
      UserId: userDropdownValue?.value,
      RoleId: roleDropdownValue?.value,
      SearchText: searchText,
    });

    const responseData = response.data;

    if (!responseData || responseData.length === 0) {
      console.error("No data to export.");
      hideLoader();
      return;
    }

    const columnsToInclude = [
      "userName",
      "roleName",
      "permissionName",
      "interfaceName",
      "tabName",
      "sectionName",
      "moduleName",
      "isActive",
    ];

    const headers = columnsToInclude;
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Report");

    const formattedHeaders = headers.map((header) =>
      toTitleCase(header.replace(/([a-z])([A-Z])/g, "$1 $2"))
    );

    worksheet.addRow(formattedHeaders);
    worksheet.getRow(1).font = { bold: true };
    responseData.forEach((row) => {
      const dataRow = headers.map((header) => {
        if (header === "isActive") {
          return row[header] ? "Yes" : "No";
        }
        return row[header] || "";
      });

      worksheet.addRow(dataRow);
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "Report_User_Permission.xlsx";
      a.click();

      URL.revokeObjectURL(url);
      hideLoader();
    });

    hideLoader();
  };

  // excel for Permission Dashboard end
  const handleRoleSubmit = async () => {
    try {
      if (!roleName) {
        setRoleNameError("Role Name is mandatory");
      } else {
        setRoleNameError("");
      }

      if (!systemName) {
        setSystemNameError("System Name is mandatory");
      } else {
        setSystemNameError("");
      }

      if (!roleType) {
        setRoleTypeError("Role Type is mandatory");
      } else {
        setRoleTypeError("");
      }

      // If any validation error, return without submitting
      if (!roleName || !systemName || !roleType) {
        return;
      }

      const response = await APICall(insertRoleDetails, "POST", {
        RoleName: roleName,
        SystemName: systemName,
        RoleTypeId: roleType.value,
        IsActive: isActive,
        IsProxyUser: isProxyUserRole,
        CreatedBy: userDetails.Id,
      });
      if (response.data === false) {
        notify(0, `${response.message}.`);
        fetchRoleData();
      } else {
        notify(1, `${response.message}.`);
        fetchRoleData();
      }
    } catch (error) {
      console.error("Unexpected error:", error);
    }
    fetchRoleData();
    setRoleName("");
    setSystemName("");
    setRoleType(null);
    setIsActive(false);
    setIsProxyUserRole(false);
  };

  return (
    <>
      <div className="container-fluid">
        <button
          onClick={() => {
            navigate("/UserRoleDashboard ");
          }}
          className="btn btn-primary my-2"
        >
          <i className="fa fa-left-long mr-2"></i>
          Back
        </button>
        <Accordion
          className="mb-3"
          expanded={panal1}
          onChange={() => setPanal1((prev) => !prev)}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            style={{ background: "#3C5464" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}
          >
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                {" "}
                <Typography>User And OU Mapping</Typography>
              </div>
              <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12">
                <Tooltip title="Submit">
                  <button className="btn" onClick={handleSubmit}>
                    <i className="fa fa-save"></i>
                  </button>
                </Tooltip>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row">
              {/* User is here */}
              <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown1">
                    User <sup>*</sup>
                  </label>
                  <SelectForm
                    async
                    isClearable
                    isSearchable
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                    id="dropdown1"
                    value={userDropdownValue}
                    placeholder="Select an option"
                    options={(e, callback) => handleUserChange(e, callback)}
                    //   options={userDropdownOptions}
                    onChange={handleUserChangeValue}
                    isDisabled={isFormSubmitted}
                  />
                  {userErrorDropdown && (
                    <p className="text-danger">{userErrorDropdown}</p>
                  )}
                </div>
              </div>
              {/* Role is here */}
              <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown2">
                    Role<sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                    id="dropdown2"
                    value={roleDropdownValue}
                    placeholder="Select an option"
                    options={roleDropdownOptions}
                    onChange={handleRoleChange}
                    isDisabled={isFormSubmitted}
                  />
                  {roleErrorDropdown && (
                    <p className="text-danger">{roleErrorDropdown}</p>
                  )}
                </div>
              </div>

              <div className="col-md-3">
                <div className="row">
                  {/* Add Role Modal */}
                  <div className="col-md-6">
                    <div style={{ marginTop: "32px" }}>
                      <Button
                        className="btn btn-primary"
                        onClick={() => setModalShow(true)}
                      >
                        <i className="fa fa-plus"></i>
                      </Button>

                      <MyVerticallyCenteredModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        roleName={roleName}
                        setRoleName={setRoleName}
                        roleNameError={roleNameError}
                        setRoleNameError={setRoleNameError}
                        systemName={systemName}
                        setSystemName={setSystemName}
                        systemNameError={systemNameError}
                        setSystemNameError={setSystemNameError}
                        isProxyUserRole={isProxyUserRole}
                        setIsProxyUserRole={setIsProxyUserRole}
                        roleType={roleType}
                        setRoleType={setRoleType}
                        roleTypeError={roleTypeError}
                        setRoleTypeError={setRoleTypeError}
                        setIsActive={setIsActive}
                        handleRoleSubmit={handleRoleSubmit}
                        isActive={isActive}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-xs-4">
                    <div className="mb-1">
                      <label className="col-form-label">
                        Is Domain<sup>*</sup>
                      </label>
                      <div>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={isDomain}
                          style={{ marginLeft: "0px" }}
                          onChange={(e) => setIsDomain(e.target.checked)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Document is here */}
              <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown23">
                    Document
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                    id="dropdown23"
                    value={DocumentDropdownValue}
                    placeholder="Select an option"
                    options={DocumentDropdownOptions}
                    onChange={handleDocumentChange}
                  />
                  {/* {DocumentErrorDropdown && (
                    <p className="text-danger">{DocumentErrorDropdown}</p>
                  )} */}
                </div>
              </div>
            </div>
            {displayDropdown && (
              <>
                <div className="row">
                  {/* Business */}
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="col-form-label" htmlFor="dropdown3">
                        Business
                      </label>
                      <SelectForm
                        isClearable
                        isSearchable
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                        id="dropdown3"
                        value={BusinessDropdownValue}
                        placeholder="Select an option"
                        options={BusinessDropdownOptions}
                        onChange={(e) => {
                          handleOnChange("BIS", e);
                        }}
                      />
                    </div>
                  </div>

                  {/* SBU */}
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="col-form-label" htmlFor="dropdown4">
                        SBU
                      </label>
                      <SelectForm
                        isClearable
                        isSearchable
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                        id="dropdown4"
                        value={SBUDropdownValue}
                        placeholder="Select an option"
                        options={SBUDropdownOptions}
                        onChange={(e) => {
                          handleOnChange("SBU", e);
                        }}
                      />
                    </div>
                  </div>

                  {/* Legal Entity */}
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="col-form-label" htmlFor="dropdown5">
                        Legal Entity
                      </label>
                      <SelectForm
                        isClearable
                        isSearchable
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                        id="dropdown5"
                        value={LegalEntityDropdownValue}
                        placeholder="Select an option"
                        options={LegalEntityDropdownOptions}
                        onChange={(e) => {
                          handleOnChange("LEN", e);
                        }}
                      />
                    </div>
                  </div>

                  {/* Division */}
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="col-form-label" htmlFor="dropdown6">
                        Division
                      </label>
                      <SelectForm
                        isClearable
                        isSearchable
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                        id="dropdown6"
                        value={DivisionDropdownValue}
                        placeholder="Select an option"
                        options={DivisionDropdownOptions}
                        onChange={(e) => {
                          handleOnChange("DV", e);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            {displayDropdown && (
              <>
                <div className="row">
                  {/* Function */}
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="col-form-label" htmlFor="dropdown7">
                        Function
                      </label>
                      <SelectForm
                        isClearable
                        isSearchable
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                        id="dropdown7"
                        value={FunctionDropdownValue}
                        placeholder="Select an option"
                        options={FunctionDropdownOptions}
                        onChange={(e) => {
                          handleOnChange("FUNC", e);
                        }}
                      />
                    </div>
                  </div>

                  {/* Department */}
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="col-form-label" htmlFor="dropdown8">
                        Department
                      </label>
                      <SelectForm
                        isClearable
                        isSearchable
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                        id="dropdown8"
                        value={DepartmentDropdownValue}
                        placeholder="Select an option"
                        options={DepartmentDropdownOptions}
                        onChange={(e) => {
                          handleOnChange("DPT", e);
                        }}
                      />
                    </div>
                  </div>

                  {/* Section */}
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="col-form-label" htmlFor="dropdown9">
                        Section
                      </label>
                      <SelectForm
                        isClearable
                        isSearchable
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                        id="dropdown9"
                        value={SectionDropdownValue2}
                        placeholder="Select an option"
                        options={SectionDropdownOptions2}
                        onChange={(e) => {
                          handleOnChange("SEC", e);
                        }}
                      />
                    </div>
                  </div>

                  {/* EmployeeClass */}
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="col-form-label" htmlFor="dropdown10">
                        Employee Class
                      </label>
                      <SelectForm
                        isClearable
                        isSearchable
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                        id="dropdown10"
                        value={EmployeeClassDropdownValue}
                        placeholder="Select an option"
                        options={EmployeeClassDropdownOptions}
                        onChange={(e) => {
                          handleOnChange("EC", e);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  {/* JobBand */}
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="col-form-label" htmlFor="dropdown11">
                        Job Band
                      </label>
                      <SelectForm
                        isClearable
                        isSearchable
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                        id="dropdown11"
                        value={JobBandDropdownValue}
                        placeholder="Select an option"
                        options={JobBandDropdownOptions}
                        onChange={(e) => {
                          handleOnChange("JB", e);
                        }}
                      />
                    </div>
                  </div>

                  {/* JobLevel */}
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="col-form-label" htmlFor="dropdown12">
                        Job Level
                      </label>
                      <SelectForm
                        isClearable
                        isSearchable
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                        id="dropdown12"
                        value={JobLevelDropdownValue}
                        placeholder="Select an option"
                        options={JobLevelDropdownOptions}
                        onChange={(e) => {
                          handleOnChange("JL", e);
                        }}
                      />
                    </div>
                  </div>

                  {/* StandardDesignation */}
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="col-form-label" htmlFor="dropdown13">
                        Standard Designation
                      </label>
                      <SelectForm
                        isClearable
                        isSearchable
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                        id="dropdown13"
                        value={StandardDesignationDropdownValue}
                        placeholder="Select an option"
                        options={StandardDesignationDropdownOptions}
                        onChange={(e) => {
                          handleOnChange("SD", e);
                        }}
                      />
                    </div>
                  </div>

                  {/* CustomBusiness */}
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="col-form-label" htmlFor="dropdown14">
                        Custom Business
                      </label>
                      <SelectForm
                        isClearable
                        isSearchable
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                        id="dropdown14"
                        value={CustomBusinessDropdownValue}
                        placeholder="Select an option"
                        options={CustomBusinessDropdownOptions}
                        onChange={(e) => {
                          handleOnChange("CUSBIS", e);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  {/*  Physical Location */}
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="col-form-label" htmlFor="dropdown15">
                        Physical Location
                      </label>
                      <SelectForm
                        isClearable
                        isSearchable
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                        id="dropdown15"
                        value={phLocDropdownValue}
                        placeholder="Select an option"
                        options={phLocDropdownOptions}
                        onChange={(e) => {
                          handleOnChange("PHLOC", e);
                        }}
                      />
                    </div>
                  </div>

                  {/* Payroll Location */}
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="col-form-label" htmlFor="dropdown16">
                        Payroll Location
                      </label>
                      <SelectForm
                        isClearable
                        isSearchable
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                        id="dropdown16"
                        value={PayLocDropdownValue}
                        placeholder="Select an option"
                        options={PayLocDropdownOptions}
                        onChange={(e) => {
                          handleOnChange("PLOC", e);
                        }}
                      />
                    </div>
                  </div>

                  {/* Country */}
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="col-form-label" htmlFor="dropdown17">
                        Country
                      </label>
                      <SelectForm
                        isClearable
                        isSearchable
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                        id="dropdown17"
                        value={CountryDropdownValue}
                        placeholder="Select an option"
                        options={CountryDropdownOptions}
                        onChange={(e) => {
                          handleOnChange("COU", e);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  {/* SubSection */}
                  {/* <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown18">
                    SubSection
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                    id="dropdown18"
                    value={
                      SubSectionDropdownOptions
                        ? SubSectionDropdownOptions?.find(
                            (option) => option.value === SubSectionDropdownValue
                          )
                        : null
                    }
                    placeholder="Select an option"
                    options={SubSectionDropdownOptions}
                    onChange={handleSubSectionChange}
                  />
                  {SubSectionErrorDropdown && (
                    <p className="text-danger">{SubSectionErrorDropdown}</p>
                  )}
                </div>
              </div> */}
                  {/* SubSubSection */}
                  {/* <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown19">
                    Sub Sub-Section
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                    id="dropdown19"
                    value={
                      SubSubSectionnDropdownOptions
                        ? SubSubSectionnDropdownOptions?.find(
                            (option) =>
                              option.value === SubSubSectionnDropdownValue
                          )
                        : null
                    }
                    placeholder="Select an option"
                    options={SubSubSectionnDropdownOptions}
                    onChange={handleSubSubSectionChange}
                  />
                  {SubSubSectionnErrorDropdown && (
                    <p className="text-danger">{SubSubSectionnErrorDropdown}</p>
                  )}
                </div>
              </div> */}
                  {/* CostCenter */}
                  {/* <div className="col-md-3">
                <div className="form-group">
                  <label className="col-form-label" htmlFor="dropdown20">
                    Job Band
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                    id="dropdown20"
                    value={
                      CostCenterDropdownOptions
                        ? CostCenterDropdownOptions?.find(
                            (option) => option.value === CostCenterDropdownValue
                          )
                        : null
                    }
                    placeholder="Select an option"
                    options={CostCenterDropdownOptions}
                    onChange={handleCostCenterChange}
                  />
                  {CostCenterErrorDropdown && (
                    <p className="text-danger">{CostCenterErrorDropdown}</p>
                  )}
                </div>
              </div> */}
                </div>
              </>
            )}
          </AccordionDetails>
        </Accordion>

        {displayAccordion && (
          <>
            <Accordion
              className="mb-3"
              expanded={panal2}
              onChange={() => setPanal2((prev) => !prev)}
            >
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
                style={{ background: "#3C5464" }}
                className="text-white acc_close"
                expandIcon={<ExpandMore />}
              >
                <div className="row d-flex align-items-center acc-heading">
                  <div className="col-lg-10 col-md-10 col-sm-12">
                    <Typography>Permission Mapping</Typography>
                  </div>
                  <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12">
                    <Tooltip title="Submit">
                      <button
                        className="btn"
                        onClick={() => {
                          handlePermissionSubmit(interfaceIdState, tabIdState);
                        }}
                      >
                        <i className="fa fa-save"></i>
                      </button>
                    </Tooltip>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="row">
                  {/* Interface is here */}
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="col-form-label" htmlFor="dropdown21">
                        Interface<sup>*</sup>
                      </label>
                      <SelectForm
                        isClearable
                        isSearchable
                        isMulti={false}
                        noIndicator={false}
                        noSeparator={false}
                        id="dropdown21"
                        value={InterfaceDropdownValue}
                        placeholder="Select an option"
                        options={InterfaceDropdownOptions}
                        onChange={handleInterfaceChange}
                      />
                      {InterfaceErrorDropdown && (
                        <p className="text-danger">{InterfaceErrorDropdown}</p>
                      )}
                    </div>
                  </div>
                  {/* TAB is here */}
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="col-form-label" htmlFor="dropdown22">
                        Tab
                      </label>
                      <SelectForm
                        isClearable
                        isSearchable
                        isMulti={false}
                        noIndicator={false}
                        noSeparator={false}
                        id="dropdown22"
                        value={TabDropdownValue}
                        placeholder="Select an option"
                        options={tabDropdownOptions}
                        onChange={(event) => handleTabChange(event)}
                      />
                      {tabErrorDropdown && (
                        <p className="text-danger">{tabErrorDropdown}</p>
                      )}
                    </div>
                  </div>
                </div>
                <DynamicGrid
                  data={rowData}
                  columns={columns}
                  options={options}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={panal3}
              onChange={() => setPanal3((prev) => !prev)}
            >
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
                style={{ background: "#3C5464" }}
                className="text-white acc_close"
                expandIcon={<ExpandMore />}
              >
                <div className="row d-flex align-items-center acc-heading">
                  <div className="col-lg-10 col-md-10 col-sm-12">
                    <Typography>Show Permissions</Typography>
                  </div>
                  <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12">
                    <Tooltip title="Export To Excel">
                      <button
                        type="button"
                        onClick={exportToExcel}
                        className="btn btn-primary mr-2"
                      >
                        <i className="fa fa-download mr-2"></i>
                      </button>
                    </Tooltip>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="row">
                  <div className="col-12">
                    <div className="grid-wrapper grid_mob">
                      <div className="row mx-auto filtBox">
                        <>
                          <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="form-group filtInp">
                              <InputForm
                                value={searchText}
                                placeholder={"Search"}
                                isDisabled={false}
                                textArea={false}
                                onChange={onSearchFilter}
                              />
                            </div>
                          </div>
                        </>
                      </div>
                    </div>
                    <DynamicGrid
                      data={PermissionRowData}
                      columns={Permissioncolumns}
                      options={PermissionOptions}
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </div>
    </>
  );
};

export default UserRoleMaster;
