import React, { useContext, useRef, useState, useEffect } from "react";
import InputForm from "../../Components/InputForm/InputForm";
import SelectForm from "../../Components/SelectForm/SelectForm";
import defaultProfileImgPic from "../../Assets/Images/adhars.jpg";
import defaultProfileImgPic1 from "../../Assets/Images/dr_sign.png";
import defaultProfileImgPic2 from "../../Assets/Images/cadidates_sign.png";
import defaultProfileImgPic3 from "../../Assets/Images/thumb.png";
import defaultProfileImgPic4 from "../../Assets/Images/default+_pic.jpg";
import AccordianCustom1 from "../DocumentScreen/AccordianCustom1";
import { APICall } from "../../Helpers/APICalls";
import {
  GetPositionDropdownData,
  GetPositionDetailsByPositionId,
  GetDropdownData,
  InsertUpdateAssociateDetails,
  InsertUpdateTokenIssuanceDetails,
  GetAssociateDetailsById,
  UploadSignatureAndSetDetails,
  GetAssociatesDocument,
  GenerateBlueColarDocument,
  Statuswithfile,
  GetSectionByWorkArea,
  GetAssociatesFingerprint,
  GetAssociateDropdownData,
  GetWorkflowStages,
  InsertUpdateCandidateWorkflowInstanceTasks,
  GetStageNameById,
  isOrganisationInfo,
} from "../../Helpers/APIEndPoints/AssociateEndPoints";
import { GetConfigValueByKey } from "../../Helpers/APIEndPoints/EndPoints";
import { Decipher } from "crypto";
import notify from "../../Helpers/ToastNotification";
import { useNavigate } from "react-router-dom";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { set } from "date-fns/fp";
import { Button, Modal } from "react-bootstrap";
import SignatureCanvas from "react-signature-canvas";
import QR from "../QRComponent/QR";
import { error } from "console";
import { HideImageOutlined } from "@mui/icons-material";
import { LoaderContext } from "../../Helpers/Context/Context";
import "./MedicalScreen.css";
import moment from "moment";
import OrganizationInformation from "../../Components/OrganizationInformation/OrganizationInformation";

interface CandidateDetails {
  name?: string;
  mobileNumber?: string;
  category?: string | number | null;
  skillCategory?: string | number | null;
  workArea?: string | number | null;
}

const MedicalScreen = () => {
  const [positionText, setPositionText] = useState<any>({ name: "", id: 0 });
  const [business, setBusiness] = useState<any>({ name: "", id: 0 });
  const [sbu, setSBU] = useState<any>({ name: "", id: 0 });
  const [division, setDivision] = useState<any>({ name: "", id: 0 });
  const [subdivision, setsubDivision] = useState<any>({ name: "", id: 0 });
  const [Function, setFunction] = useState<any>(null);
  const [Department, setDepartment] = useState<any>({ name: "", id: 0 });
  const [Section, setSection] = useState<any>({ name: "", id: 0 });
  const [subSection, setSubSection] = useState<any>({ name: "", id: 0 });
  const [empClass, setEmpClass] = useState<any>({ name: "", id: 0 });
  const [JobLevel, setJobLevel] = useState<any>({ name: "", id: 0 });
  const [jobBand, setJobBand] = useState<any>({ name: "", id: 0 });
  const [designation, setDesignation] = useState<any>({ name: "", id: 0 });
  const [jobCode, setJobCode] = useState<any>({ name: "", id: 0 });
  const [skillCategory, setSkillCategory] = useState<any>({ name: "", id: 0 });
  const [reportingManager, setReportingManager] = useState<any>({
    name: "",
    id: 0,
  });
  const [LegalEntity, setLegalEntity] = useState<any>({ name: "", id: 0 });
  const [isaccordiandisable, setIsAccordiandisable] = useState<any>(true);
  const [isVisionfieldisable, setIsVisionfieldisable] = useState<any>(true);
  const [isDistictfieldisable, setIsDistictfieldisable] = useState<any>(true);
  const [formData, setFormData] = useState<IFormData>({});
  const [NearVisionSpecOption, setNearVisionSpecOption] = useState([]);
  const [LeftNearVisionSpec, setLeftNearVisionSpec] = useState(null);
  const [RightNearVisionSpec, setRightNearVisionSpec] = useState(null);
  const [NearVisionWSpecOption, setNearVisionWSpecOption] = useState([]);
  const [SpecsOptions, setSpecsOption] = useState([
    { label: "With Specs", value: 1 },
    { label: "Without Specs", value: 2 },
  ]);

  const [SpecsValue, setSpecsValue] = useState<any>(null);
  const [DistantSpecsOptions, setdistantsSpecsOption] = useState([
    { label: "With Specs", value: 1 },
    { label: "Without Specs", value: 2 },
  ]);
  const [DistantSpecsValue, setDistantSpecsValue] = useState<any>(null);
  const [LeftNearVisionWSpec, setLeftNearVisionWSpec] = useState(null);
  const [RightNearVisionWSpec, setRightNearVisionWSpec] = useState(null);
  const [DistantVisionSpecOption, setDistantVisionSpecOption] = useState([]);
  const [LeftDistantVisionSpec, setLeftDistantVisionSpec] = useState(null);
  const [RightDistantVisionSpec, setRightDistantVisionSpec] = useState(null);
  const [DistantVisionWSpecOption, setDistantVisionWSpecOption] = useState([]);
  const [LeftDistantVisionWSpec, setLeftDistantVisionWSpec] = useState(null);
  const [RightDistantVisionWSpec, setRightDistantVisionWSpec] = useState(null);

  const [RightNearVision, setRightNearVision] = useState(null);
  const [RightDistantVision, setRightDistantVision] = useState(null);
  const [LeftDistantVision, setLeftDistantVision] = useState(null);
  const [LeftNearVision, setLeftNearVision] = useState(null);

  const [ColorVisionOption, setColorVisionOption] = useState([]);
  const [LeftColorVision, setLeftColorVision] = useState(null);
  const [RightColorVision, setRightColorVision] = useState(null);
  const [AudiometryOption, setAudiometryOption] = useState([]);
  const [Audiometry, setAudiometry] = useState(null);
  const [SpirometryOption, setSpirometryOption] = useState([]);
  const [Spirometry, setSpirometry] = useState(null);
  const [DecisionOption, setDecisionOption] = useState([
    { label: "Rejected", value: 3 },
    { label: "Shortlisted", value: 4 },
  ]);
  const [decision, setDecision] = useState<any>(null);

  const [Pulserate, setPulserate] = useState<any>(null);
  const [BloodPressure, setBloodPressure] = useState<any>(null);
  const [SystolicBloodPressure, setSystolicBloodPressure] = useState<any>(null);
  const [DiastolicBloodPressure, setDiastolicBloodPressure] =
    useState<any>(null);
  const [IdentificationMark, setIdentificationMark] = useState<any>(null);
  const [Height, setHeight] = useState<any>(null);
  const [Weight, setWeight] = useState<any>(null);
  const [BloodGroupOptions, setBloodGroupOptions] = useState<any>([]);
  const [BloodGroup, setBloodGroup] = useState<any>(null);
  const [Remark, setRemark] = useState<any>(null);
  const [isActive, setisActive] = useState<any>(true);
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  const [CandidateOption, setCandidateOption] = useState([
    { label: "No", value: 1 },
    { label: "Yes", value: 2 },
  ]);
  const [Asthma, setAsthma] = useState<any>(null);
  const [BPType, setBPType] = useState<any>(null);
  const [HearingDisability, setHearingDisability] = useState<any>(null);
  const [Diabetes, setDiabetes] = useState<any>(null);
  const [Hernia, setHernia] = useState<any>(null);
  const [HeartProblem, setHeartProblem] = useState<any>(null);
  const [NervousDisorder, setNervousDisorder] = useState<any>(null);
  const [BackProblem, setBackProblem] = useState<any>(null);
  const [VisualProblem, setVisualProblem] = useState<any>(null);
  const [JointProblem, setJointProblem] = useState<any>(null);
  const [Epilepsy, setEpilepsy] = useState<any>(null);
  const [StomachProblem, setStomachProblem] = useState<any>(null);
  const [LiverProblem, setLiverProblem] = useState<any>(null);
  const [Anaemia, setAnaemia] = useState<any>(null);
  const [Phobia, setPhobia] = useState<any>(null);
  const [DrugsAlcohol, setDrugsAlcohol] = useState<any>(null);
  const [Allergies, setAllergies] = useState<any>(null);
  const [MobilityProblem, setMobilityProblem] = useState<any>(null);
  const [WhiteFinger, setWhiteFinger] = useState<any>(null);
  const [Disability, setDisability] = useState<any>(null);
  const [RejectionReasonOption, setRejectionReasonOption] = useState<any>([]);
  const [RejectionReason, setRejectionReason] = useState<any>([]);
  const [Rejection, setRejection] = useState<any>(null);
  const [DecisionRemark, setDecisionRemark] = useState<any>(null);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [fielddisable, setffielddisable] = useState<any>(false);
  // const [ClinicalOption, setClinicalOption] = useState([
  //   { label: "No Abnormality Detected", value: 1 },
  //   { label: "Abnormality Detected", value: 2 },
  // ]);
  const [ClinicalOption, setClinicalOption] = useState([]);
  const [MouthDisorder, setMouthDisorder] = useState<any>(null);
  const [RespiratorySystem, setRespiratorySystem] = useState<any>(null);
  const [Cardio, setCardio] = useState<any>(null);
  const [Abdomen, setAbdomen] = useState<any>(null);
  const [CNS, setCNS] = useState<any>(null);
  const [PathologicalOption, setPathologicalOption] = useState([]);
  const [PathologicalTest, setPathologicalTest] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const sigCanvas = useRef<any>();
  const sigCanvas1 = useRef<any>();
  const [startScan, setStartScan] = useState<boolean>(false);
  const [scanData, setScanData] = useState(null);
  const [positionDisable, setPositionDisable] = useState(false);
  const [signatureimage, setsigatureimage] = useState(null);
  const [thumbimage, setthumbimage] = useState(null);
  const [doctorbase64, setDoctorbase64] = useState<any>(null);
  const [showcandidatemodel, setShowcandidatemodel] = useState<any>(null);
  const [signatuecandidate, setSignatureCandidate] = useState<any>(null);
  const [candidatebase64, setcandidatebase64] = useState<any>(null);
  const [isDisabled, setIsDisabled] = useState<any>(null);
  const [docURL, setDocURL] = useState<any>(null);
  const [genderName, setGenderName] = useState<any>(null);
  const [age, setAge] = useState<any>(null);
  const [SectionData, setSectionData] = useState<any>(null);
  const [IsProcessing, setIsProcessing] = useState<any>(null);
  const [currentStageName, setCurrentStageName] = useState(null);
  const [currentStageId, setCurrentStageId] = useState(null);
  const [showcurrentstage, setShowCurrentStage] = useState<any>(false);
  const [candidateimage, setCandidateimage] = useState(null);
  const [payRoll, setPayRoll] = useState<string>("");
  const [modalForGunScanner, setModalForGunScanner] = useState<boolean>(false);
  const [barcode, setBarcode] = useState<string>("");

  const [candidateDetails, setCandidateDetails] = useState<CandidateDetails>(
    {}
  );
  const [dob, setDOB] = useState<string>("");
  const [showWaitText, setShowWaitText] = useState(false);
  const [documentGenerated, setDocumentGenerated] = useState(false);
  const [consentGiven, setConsentGiven] = useState(false);
  const [isDoctorSignatureSubmit, setIsDoctorSignatureSubmit] = useState(false);
  const [isCandidateSignature, setIsCandidateSignature] = useState(false);
  const [QRCode, setQRCode] = useState<any>(null);
  const [Intime, setIntime] = useState<string>();
  const [GenralExaminationSaved, setGenralExaminationSaved] = useState(false);
  const [AudiometrySaved, setAudiometrySaved] = useState(false);
  const [PathologySaved, setPathologySaved] = useState(false);
  const [ClinicalExaminationSaved, setClinicalExaminationSaved] =
    useState(false);
  const navigate = useNavigate();
  const [tokenId, settokenId] = useState<any>(null);
  const [categoryondrop, setCategoryondrop] = useState<any>(null);
  const [skillCategorydrop, setskillCategorydrop] = useState<any>(null);
  //let savedSections = [];
  const [positionbutton, setpositionbutton] = useState(false);

  const [savedSections, setsavedSections] = useState<any>([]);

  const [tokenData, setTokenData] = useState<ITokenData>({});
  const [IsOrganisationunitpopup, setisOrganisationunitpopup] =
    useState<any>(false);
  interface ITokenData {
    candidate_Id?: IOptions | boolean | any;
  }

  let formErrorObj = {};
  type FormErrors = {
    Rejection?: string;
    decision?: string;
    BloodGroup?: string;
    Height?: string;
    Weight?: string;
    NearvisionSpecRight?: string;
    NearvisionWithoutSpecRight?: string;
    DistantvisionwithspecRight?: string;
    DistantvisionwithoutspecRight?: string;
    ColorVisionRight?: string;
    NearvisionSpecLeft?: string;
    NearvisionWithoutSpecLeft?: string;
    DistantvisionwithspecLeft?: string;
    DistantvisionwithoutspecLeft?: string;
    ColorVisionLeft?: string;
  };

  interface IOptions {
    label?: string;
    value?: number | string;
    code?: string;
  }
  interface IFormData {
    position_Id?: IOptions | boolean | any;
    categoryId?: any;
  }

  useEffect(() => {
    const helper = async () => {
      await Promise.all([
        GetNearVision(),
        GetDistantVision(),
        GetColorVision(),
        GetAudiometry(),
        GetSpirometry(),
        GetBloodGroup(),
        GetRejectionReason(),
        GetClinicalExamination(),
        GetPathologicalTest(),
      ]);
    };

    helper();
    getDocConfigUrl();
  }, []);

  useEffect(() => {
    if (formData?.position_Id) {
      getPositionbyId();
      setpositionbutton(true);
      //GetSectionByWorkAreaId();
    } else {
      setIsAccordiandisable(true);
    }
  }, [formData?.position_Id]);

  useEffect(() => {
    const fetchStages = async () => {
      // if ((scanData || tokenData) && currentStageId != 3) {
      //   let getWorkFlowStages = await APICall(GetStageNameById, "POST", {
      //     CurrentStagesId: currentStageId,
      //   });

      //   let currentStageName = "";
      //   if (getWorkFlowStages && getWorkFlowStages.status === 0) {
      //     if (getWorkFlowStages.data) {
      //       currentStageName = getWorkFlowStages.data.candidateCurrentstage;
      //       setCurrentStageName(currentStageName);
      //       setShowCurrentStage(true);
      //     }
      //   }
      // }

      if ((scanData || tokenData) && currentStageId != 3) {
        console.log(currentStageId, "current Test");
        let getWorkFlowStages = await APICall(GetStageNameById, "POST", {
          CurrentStagesId: currentStageId,
        });

        let currentStageName = "";
        if (getWorkFlowStages && getWorkFlowStages.status === 0) {
          if (getWorkFlowStages.data) {
            currentStageName = getWorkFlowStages.data.candidateCurrentstage;
            setCurrentStageName(currentStageName);
            setShowCurrentStage(true);
          }
        }
      } else if (
        (scanData || tokenData) &&
        currentStageId == 3 &&
        currentStageId != null
      ) {
        console.log(currentStageId, "orgcurrentstage");
        let isOrganisationInf = await isOrganisationunit(scanData.CandidateId);
        console.log(isOrganisationInf, "isExist");
        if (!isOrganisationInf) {
          console.log(isOrganisationInf, "isExist1");
          setisOrganisationunitpopup(true);
          setIsDisabled(true);
          setIsAccordiandisable(true);
        } else {
          setisOrganisationunitpopup(false);
          setIsDisabled(false);
          setIsAccordiandisable(false);
          // if (currentId != 4) {
          //   let getWorkFlowStages = await APICall(GetStageNameById, "POST", {
          //     CurrentStagesId: currentId,
          //   });

          //   let currentStageName = "";
          //   if (getWorkFlowStages && getWorkFlowStages.status === 0) {
          //     if (getWorkFlowStages.data) {
          //       currentStageName = getWorkFlowStages.data.candidateCurrentstage;
          //       setCurrentStageName(currentStageName);
          //       setShowCurrentStage(true);
          //     }
          //   }
          // }
        }
      }
    };

    fetchStages();
  }, [currentStageId, tokenData?.candidate_Id, scanData?.CandidateId, tokenId]);

  const { showLoader, hideLoader } = useContext(LoaderContext);

  // useEffect(() => {
  //   if (BloodGroupOptions.lengthsetBloodGroup > 0) {
  //     (BloodGroupOptions[0]);
  //   }
  // }, [BloodGroupOptions]);

  useEffect(() => {
    if (NearVisionSpecOption.length > 0) {
      setLeftNearVisionSpec(NearVisionSpecOption[0]);
      setRightNearVisionSpec(NearVisionSpecOption[0]);
    }
  }, [NearVisionSpecOption]);

  useEffect(() => {
    if (NearVisionWSpecOption.length > 0) {
      setLeftNearVisionWSpec(NearVisionWSpecOption[0]);
      setRightNearVisionWSpec(NearVisionWSpecOption[0]);
    }
  }, [NearVisionWSpecOption]);

  useEffect(() => {
    if (DistantVisionSpecOption.length > 0) {
      setLeftDistantVisionSpec(DistantVisionSpecOption[0]);
      setRightDistantVisionSpec(DistantVisionSpecOption[0]);
    }
  }, [DistantVisionSpecOption]);

  useEffect(() => {
    if (DistantVisionWSpecOption.length > 0) {
      setLeftDistantVisionWSpec(DistantVisionWSpecOption[0]);
      setRightDistantVisionWSpec(DistantVisionWSpecOption[0]);
    }
  }, [DistantVisionWSpecOption]);

  useEffect(() => {
    if (ColorVisionOption.length > 0) {
      setLeftColorVision(ColorVisionOption[0]);
      setRightColorVision(ColorVisionOption[0]);
    }
  }, [ColorVisionOption]);

  useEffect(() => {
    if (CandidateOption.length > 0) {
      setAsthma(CandidateOption[0]);
      setBPType(CandidateOption[0]);
      setHearingDisability(CandidateOption[0]);
      setDiabetes(CandidateOption[0]);
      setHernia(CandidateOption[0]);
      setHeartProblem(CandidateOption[0]);
      setNervousDisorder(CandidateOption[0]);
      setBackProblem(CandidateOption[0]);
      setVisualProblem(CandidateOption[0]);
      setJointProblem(CandidateOption[0]);
      setEpilepsy(CandidateOption[0]);
      setStomachProblem(CandidateOption[0]);
      setLiverProblem(CandidateOption[0]);
      setAnaemia(CandidateOption[0]);
      setPhobia(CandidateOption[0]);
      setDrugsAlcohol(CandidateOption[0]);
      setAllergies(CandidateOption[0]);
      setMobilityProblem(CandidateOption[0]);
      setWhiteFinger(CandidateOption[0]);
      setDisability(CandidateOption[0]);
    }
  }, [CandidateOption]);

  useEffect(() => {
    if (ClinicalOption.length > 0) {
      setMouthDisorder(ClinicalOption[0]);
      setRespiratorySystem(ClinicalOption[0]);
      setCardio(ClinicalOption[0]);
      setAbdomen(ClinicalOption[0]);
      setCNS(ClinicalOption[0]);
    }
  }, [ClinicalOption]);

  useEffect(() => {
    // if (decision?.value == "3") {
    //   setffielddisable(false);
    // } else {
    //   setffielddisable(true);
    // }
    if (decision?.value == "3") {
      if (RejectionReason) {
        let res = RejectionReason.filter((a) => a.label != "Fit");
        setRejectionReasonOption(res);
        setRejection(res[0]);
      }
    } else if (decision?.value == "4") {
      let res = RejectionReason.filter((a) => a.label == "Fit");
      setRejectionReasonOption(res);
      setRejection(res[0]);
    } else {
      //setRejectionReasonOption([]);
    }
  }, [decision]);

  useEffect(() => {
    const handleKeyDown = async (evt: any) => {
      try {
        if (evt.code === "Enter") {
          if (barcode) await handleBarcode(barcode);
          return;
        }
        if (evt.key !== "Shift") setBarcode((prev) => prev + evt.key);
      } catch (error) {
        hideLoader();
      }
    };
    if (modalForGunScanner) {
      showLoader();
      document.addEventListener("keydown", handleKeyDown);
      hideLoader();
    }
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [barcode, modalForGunScanner]);

  const getPositionbyId = async () => {
    try {
      showLoader();
      const response = await APICall(GetPositionDetailsByPositionId, "POST", {
        PositionId: formData?.position_Id.value,
      });
      if (response?.status === 0) {
        console.log(response?.data, "Sanket");
        //setIsAccordiandisable(false);
        setPositionText({
          name: response?.data?.positionText,
          id: response?.data?.positionId,
        });

        setBusiness({
          name: response?.data?.business,
          id: response?.data?.businessId,
        });

        setSBU({
          name: response?.data?.sbu,
          id: response?.data?.sbuId,
        });

        setDivision({
          name: response?.data?.division,
          id: response?.data?.divisionId,
        });

        setsubDivision({
          name: response?.data?.subdivision,
          id: response?.data?.subdivisionId,
        });

        setFunction({
          name: response?.data?.function,
          id: response?.data?.functionId,
        });

        setDepartment({
          name: response?.data?.department,
          id: response?.data?.departmentId,
        });

        setSection({
          name: response?.data?.sectionName,
          id: response?.data?.sectionId,
        });

        setSubSection({
          name: response?.data?.subSection,
          id: response?.data?.subSectionId,
        });

        setEmpClass({
          name: response?.data?.employeeClass,
          id: response?.data?.employeeClassId,
          code: response?.data?.employeeClassCode,
        });

        setJobLevel({
          name: response?.data?.jobLevel,
          id: response?.data?.jobLevelId,
        });

        setJobBand({
          name: response?.data?.jobBand,
          id: response?.data?.jobBandId,
        });

        setDesignation({
          name: response?.data?.designation,
          id: response?.data?.designationId,
        });

        setJobCode({
          name: response?.data?.jobCode,
          id: response?.data?.jobCodeId,
        });

        setSkillCategory({
          name: response?.data?.jobTitles,
          id: response?.data?.jobTitleId,
        });

        setReportingManager({
          name: response?.data?.reportingManager,
          id: response?.data?.reportingManagerId,
        });

        setLegalEntity({
          name: response?.data?.legalEntity,
          id: response?.data?.legalEntityId,
        });

        setPayRoll(response?.data?.payrollLocation || "");
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const GetNearVision = async () => {
    try {
      showLoader();
      const { data } = await APICall(GetDropdownData, "POST", {
        dropdownName: "Nearvision",
      });

      if (data !== null && data.length > 0) {
        let drpOptions = data.map((element) => ({
          value: element.value,
          label: element.label,
        }));
        console.log(drpOptions, "drpOptions");
        setNearVisionSpecOption(drpOptions);
        setNearVisionWSpecOption(drpOptions);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  const GetDistantVision = async () => {
    try {
      showLoader();
      const { data } = await APICall(GetDropdownData, "POST", {
        dropdownName: "Distantvision",
      });

      if (data !== null && data.length > 0) {
        let drpOptions = data.map((element) => ({
          value: element.value,
          label: element.label,
        }));
        setDistantVisionSpecOption(drpOptions);
        setDistantVisionWSpecOption(drpOptions);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const GetColorVision = async () => {
    try {
      showLoader();
      const { data } = await APICall(GetDropdownData, "POST", {
        dropdownName: "Colourvision",
      });

      if (data !== null && data.length > 0) {
        let drpOptions = data.map((element) => ({
          value: element.value,
          label: element.label,
        }));
        setColorVisionOption(drpOptions);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  const GetAudiometry = async () => {
    try {
      showLoader();
      const { data } = await APICall(GetDropdownData, "POST", {
        dropdownName: "Audiometry",
      });

      if (data !== null && data.length > 0) {
        let drpOptions = data.map((element) => ({
          value: element.value,
          label: element.label,
        }));
        setAudiometryOption(drpOptions);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  const GetSpirometry = async () => {
    try {
      showLoader();
      const { data } = await APICall(GetDropdownData, "POST", {
        dropdownName: "Spirometry",
      });

      if (data !== null && data.length > 0) {
        let drpOptions = data.map((element) => ({
          value: element.value,
          label: element.label,
        }));
        setSpirometryOption(drpOptions);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  const GetBloodGroup = async () => {
    try {
      showLoader();
      const { data } = await APICall(GetDropdownData, "POST", {
        dropdownName: "BloodGroup",
      });

      if (data !== null && data.length > 0) {
        let drpOptions = data.map((element) => ({
          value: element.value,
          label: element.label,
        }));
        setBloodGroupOptions(drpOptions);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  const GetRejectionReason = async () => {
    try {
      showLoader();
      const { data } = await APICall(GetDropdownData, "POST", {
        dropdownName: "Medical",
      });

      if (data !== null && data.length > 0) {
        let reason = data.map((element) => ({
          value: element.value,
          label: element.label,
        }));
        console.log(reason, "reason");
        //setRejectionReasonOption(reason);
        setRejectionReason(reason);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  const GetClinicalExamination = async () => {
    try {
      showLoader();
      const { data } = await APICall(GetDropdownData, "POST", {
        dropdownName: "ClinicalExamination",
      });

      if (data !== null && data.length > 0) {
        let reason = data.map((element) => ({
          value: element.value,
          label: element.label,
        }));
        setClinicalOption(reason);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  const GetPathologicalTest = async () => {
    try {
      showLoader();
      const { data } = await APICall(GetDropdownData, "POST", {
        dropdownName: "PathologicalTest",
      });

      if (data !== null && data.length > 0) {
        let reason = data.map((element) => ({
          value: element.value,
          label: element.label,
        }));
        setPathologicalOption(reason);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const submitGeneralExamination = async (event) => {
    let isValid = true;
    let errors: FormErrors = {};

    try {
      event.stopPropagation();

      if (!BloodGroup?.value) {
        //notify(1, "Event required.");
        errors.BloodGroup = "Blood Group is Required";
        isValid = false;
      }
      if (Height === null || Height === 0) {
        errors.Height = "Height is Required";
        isValid = false;
      }

      if (Weight === null || Weight === 0) {
        errors.Weight = "Weight is Required";
        isValid = false;
      }

      if (SpecsValue == 1) {
        if (!RightNearVisionSpec?.value) {
          errors.NearvisionSpecRight = "Near Vision Spec Required";
          isValid = false;
        }
      } else {
        if (!RightNearVisionWSpec?.value) {
          errors.NearvisionWithoutSpecRight =
            "Near Vision Without Spec Required";
          isValid = false;
        }
      }
      if (DistantSpecsValue == 1) {
        if (!RightDistantVisionSpec?.value) {
          errors.DistantvisionwithspecRight =
            "Distant Vision With Spec Required";
          isValid = false;
        }
      } else {
        if (!RightDistantVisionWSpec?.value) {
          errors.DistantvisionwithoutspecRight =
            "Distant Vision Without Spec Required";
          isValid = false;
        }
      }

      if (!RightColorVision?.value) {
        errors.ColorVisionRight = "Right Eye Color Vision is Required";
        isValid = false;
      }
      if (!LeftColorVision?.value) {
        errors.ColorVisionLeft = "lEFT Eye Color Vision is Required";
        isValid = false;
      }
      if (SpecsValue == 1) {
        if (!LeftNearVisionSpec?.value) {
          errors.NearvisionSpecLeft = "Near Vision Spec Required";
          isValid = false;
        }
      } else {
        if (!LeftNearVisionWSpec?.value) {
          errors.NearvisionWithoutSpecLeft =
            "Near Vision  Without Spec Required";
          isValid = false;
        }
      }
      if (DistantSpecsValue) {
        if (!LeftDistantVisionSpec?.value) {
          errors.DistantvisionwithspecLeft =
            "Distant Vision With Spec Required";
          isValid = false;
        }
      } else {
        if (!LeftDistantVisionWSpec?.value) {
          errors.DistantvisionwithspecLeft =
            "Distant Vision Without Spec Required";
          isValid = false;
        }
      }

      let requestParams = {
        Id: scanData?.CandidateId || tokenId,
        Section: "GeneralExamination",
        PulseRate: Pulserate,
        DiastolicBloodPressure: DiastolicBloodPressure,
        SystolicBloodPressure: SystolicBloodPressure,
        Height: Height,
        Weight: Weight,
        BloodGroup: BloodGroup?.value,
        // RightNearVisionwithSpecs: RightNearVisionSpec?.value,
        // RightNearVisionWithoutSpecs: RightNearVisionWSpec?.value,
        // RightDistantVisionWithSpecs: RightDistantVisionSpec?.value,
        // RightDistantVisionWithoutSpecs: RightDistantVisionWSpec?.value,
        RightNearVisionWithoutSpecs:
          SpecsValue?.value === 1 ? null : RightNearVision?.value,
        RightNearVisionwithSpecs:
          SpecsValue?.value === 1 ? RightNearVision?.value : null,
        RightDistantVisionWithSpecs:
          DistantSpecsValue?.value === 1 ? RightDistantVision?.value : null,
        RightDistantVisionWithoutSpecs:
          DistantSpecsValue?.value === 1 ? null : RightDistantVision?.value,
        IsDWSpec: DistantSpecsValue?.value,
        IsNWSPec: SpecsValue?.value,

        RightColorVision: RightColorVision?.value,
        // LeftNearVisionwithSpecs: LeftNearVisionSpec?.value,
        // LeftNearVisionWithoutSpecs: LeftNearVisionWSpec?.value,
        // LeftDistantVisionWithSpecs: LeftDistantVisionSpec?.value,
        // LeftDistantVisionWithoutSpecs: LeftDistantVisionWSpec?.value,
        LeftNearVisionwithSpecs:
          SpecsValue?.value === 1 ? null : LeftNearVision?.value,
        LeftNearVisionWithoutSpecs:
          SpecsValue?.value === 1 ? LeftNearVision?.value : null,
        LeftDistantVisionWithSpecs:
          DistantSpecsValue?.value === 1 ? LeftDistantVision?.value : null,
        LeftDistantVisionWithoutSpecs:
          DistantSpecsValue?.value === 1 ? null : LeftDistantVision?.value,
        LeftColorVision: LeftColorVision?.value,
        IsActive: isActive,
        CreatedBy: userDetails?.Id,
        InTime: Intime,
      };

      if (isValid) {
        showLoader();
        const response = await APICall(
          InsertUpdateAssociateDetails,
          "POST",
          requestParams
        );

        if (response.status === 0) {
          notify(
            0,
            `The data for Candidate ID ${scanData?.CandidateId || tokenId} has been successfully submitted.`
          );
          setGenralExaminationSaved(true);
          if (!savedSections.includes("General Examination")) {
            saveSection("General Examination");
          }

          if (genderName === "Male" && Number(Weight) < 40) {
            setRejection((prev) => RejectionReason[2]);
            setDecision(DecisionOption[0]);
          }

          if (genderName === "Female" && Number(Weight) < 35) {
            setRejection((prev) => RejectionReason[2]);
            setDecision(DecisionOption[0]);
          }

          if (
            RightNearVisionSpec?.label !== "N8" &&
            RightNearVisionSpec?.label !== "N6"
          ) {
            setRejection((prev) => RejectionReason[2]);
            setDecision(DecisionOption[0]);
          }

          if (
            RightNearVisionWSpec?.label !== "N8" &&
            RightNearVisionWSpec?.label !== "N6"
          ) {
            setRejection((prev) => RejectionReason[2]);
            setDecision(DecisionOption[0]);
          }
          if (
            LeftNearVisionSpec?.label !== "N8" &&
            LeftNearVisionSpec?.label !== "N6"
          ) {
            setRejection((prev) => RejectionReason[2]);
            setDecision(DecisionOption[0]);
          }

          if (
            LeftNearVisionWSpec?.label !== "N8" &&
            LeftNearVisionWSpec?.label !== "N6"
          ) {
            setRejection((prev) => RejectionReason[2]);
            setDecision(DecisionOption[0]);
          }
          if (
            RightDistantVisionSpec?.label !== "6/6" &&
            RightDistantVisionSpec?.label !== "6/9"
          ) {
            setRejection((prev) => RejectionReason[2]);
            setDecision(DecisionOption[0]);
          }

          if (
            RightDistantVisionWSpec?.label !== "6/6" &&
            LeftNearVisionWSpec?.value !== "6/9"
          ) {
            setRejection((prev) => RejectionReason[2]);
            setDecision(DecisionOption[0]);
          }
          if (
            LeftDistantVisionSpec?.label !== "6/6" &&
            LeftDistantVisionSpec?.label !== "6/9"
          ) {
            setRejection((prev) => RejectionReason[2]);
            setDecision(DecisionOption[0]);
          }

          if (
            LeftDistantVisionWSpec?.label !== "6/6" &&
            LeftDistantVisionWSpec?.value !== "6/9"
          ) {
            setRejection((prev) => RejectionReason[2]);
            setDecision(DecisionOption[0]);
          }

          if (Number(BloodPressure) < 100 || Number(BloodPressure) > 140) {
            setRejection((prev) => RejectionReason[2]);
            setDecision(DecisionOption[0]);
          }

          if (Number(BloodPressure) < 60 || Number(BloodPressure) > 95) {
            setRejection((prev) => RejectionReason[2]);
            setDecision(DecisionOption[0]);
          }
          if (
            genderName === "Male" &&
            (Number(Height) < 145 || Number(Height) > 185)
          ) {
            setRejection((prev) => RejectionReason[1]);
            setDecision(DecisionOption[0]);
          }

          if (
            genderName === "Female" &&
            (Number(Height) < 140 || Number(Height) > 170)
          ) {
            setRejection((prev) => RejectionReason[1]);
            setDecision(DecisionOption[0]);
          }
        } else {
          notify(1, "Data not inserted");
        }
      } else {
        setFormErrors(errors);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  const submitAudiometrySpirometry = async (event) => {
    try {
      event.stopPropagation();
      let requestParams = {
        Id: scanData?.CandidateId || tokenId,
        Section: "AudiometryAndSpirometry",
        Audiometry: Audiometry?.value,
        Spirometry: Spirometry?.value,
        InTime: Intime,
        IsActive: isActive,
        CreatedBy: userDetails?.Id,
      };
      showLoader();
      const response = await APICall(
        InsertUpdateAssociateDetails,
        "POST",
        requestParams
      );

      if (response.status === 0) {
        setAudiometrySaved(true);
        notify(
          0,
          `The data for Candidate ID ${scanData?.CandidateId || tokenId} has been successfully submitted. `
        );
        // ("Audiometry & Spirometry Examination")
        if (!savedSections.includes("Audiometry & Spirometry Examination")) {
          saveSection("Audiometry & Spirometry Examination");
        }
      } else {
        notify(1, "Data not inserted");
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const submitCadidateDeclaration = async (event) => {
    try {
      event.stopPropagation();
      let requestParams = {
        Id: scanData?.CandidateId || tokenId,
        Section: "CandidateDeclaration",
        AsthmaId: Asthma?.value,
        BloodPressureId: BPType?.value,
        HearingDisabilityId: HearingDisability?.value,
        DiabetesId: Diabetes?.value,
        HerniaId: Hernia?.value,
        HeartProblemsId: HeartProblem?.value,
        NervousDisorderId: NervousDisorder?.value,
        BackDiscProblemId: BackProblem?.value,
        VisualProblemId: VisualProblem?.value,
        JoinTProblemId: JointProblem?.value,
        EpilepsyORBlackoutsId: Epilepsy?.value,
        StomachDisordersId: StomachProblem?.value,
        LiverDisordersId: LiverProblem?.value,
        AnaemiaId: Anaemia?.value,
        PhobiaId: Phobia?.value,
        DrugAlcoholAddictionId: DrugsAlcohol?.value,
        AllergiesId: Allergies?.value,
        MobilityProblemsId: MobilityProblem?.value,
        WhiteFingerId: WhiteFinger?.value,
        DisabilityHandicapId: Disability?.value,

        IsActive: isActive,
        CreatedBy: userDetails?.Id,
      };

      showLoader();
      const response = await APICall(
        InsertUpdateAssociateDetails,
        "POST",
        requestParams
      );

      if (response.status === 0) {
        notify(0, "Data Inserted Successfully");
      } else {
        notify(1, "Data not inserted");
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const submitClinicalExamination = async (event) => {
    try {
      event.stopPropagation();
      let requestParams = {
        Id: scanData?.CandidateId || tokenId,
        Section: "ClinicalExamination",
        MouthDisorders: MouthDisorder?.value,
        RespiratoryIssue: RespiratorySystem?.value,
        CardiovascularIssue: Cardio?.value,
        GITAbdomenIssue: Abdomen?.value,
        CNS: CNS?.value,
        AdditionalRemarks: Remark,
        IdentificationMark: IdentificationMark,
        InTime: Intime,
        IsActive: isActive,
        CreatedBy: userDetails?.Id,
      };
      showLoader();
      const response = await APICall(
        InsertUpdateAssociateDetails,
        "POST",
        requestParams
      );

      if (response.status === 0) {
        setClinicalExaminationSaved(true);
        notify(
          0,
          `The data for Candidate ID ${scanData?.CandidateId || tokenId} has been successfully submitted. `
        );
        if (!savedSections.includes("Clinical Examination")) {
          saveSection("Clinical Examination");
        }
      } else {
        notify(1, "Data not inserted");
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  const submitPathologicalTest = async (event) => {
    try {
      event.stopPropagation();
      let requestParams = {
        Id: scanData?.CandidateId || tokenId,
        Section: "Pathology",

        PathologicalTests: PathologicalTest.map((event) => event.value).join(
          ","
        ),
        InTime: Intime,
        IsActive: isActive,
        CreatedBy: userDetails?.Id,
      };
      showLoader();
      const response = await APICall(
        InsertUpdateAssociateDetails,
        "POST",
        requestParams
      );

      if (response.status === 0) {
        notify(
          0,
          `The data for Candidate ID ${scanData?.CandidateId || tokenId} has been successfully submitted. `
        );
        setPathologySaved(true);
        if (!savedSections.includes("Pathology Examination")) {
          saveSection("Pathology Examination");
        }
      } else {
        notify(1, "Data not inserted");
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const saveSection = (sectionName) => {
    // Save the section...

    // Update the savedSections array
    setsavedSections((prevSavedSections) => [
      ...prevSavedSections,
      sectionName,
    ]);
  };

  const areAllSectionsSaved = () => {
    return savedSections.length === SectionData.length;
  };

  const submitAdditionalremark = async (event) => {
    try {
      showLoader();
      event.stopPropagation();
      let requestParams = {
        Id: scanData?.CandidateId,
        Section: "AdditionalRemarks",
        AdditionalRemarks: Remark,
        IsActive: isActive,
        CreatedBy: userDetails?.Id,
      };
      const response = await APICall(
        InsertUpdateAssociateDetails,
        "POST",
        requestParams
      );

      if (response.status === 0) {
        notify(0, "Data Inserted Successfully");
      } else {
        notify(1, "Data not inserted");
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  const QRCodeExist = async (id: any) => {
    const response = await APICall(GetAssociatesDocument, "POST", {
      AssociatesDetailsId: id,
      DocumentTypeId: 7,
    });
    console.log(response?.data);
    if (response.data !== null) {
      setQRCode(response.data?.documentDataObject);
    }
  };

  const submitDecision = async () => {
    let errors: FormErrors = {};
    let isValid = true;

    // if (
    //   !GenralExaminationSaved ||
    //   !AudiometrySaved ||
    //   !ClinicalExaminationSaved
    // ) {
    //   notify(1, "Please save all the sections");
    //   return;
    // }

    if (!areAllSectionsSaved()) {
      notify(1, "Please save all the sections");
      return;
    }

    if (!consentGiven) {
      notify(1, "Please check the consent checkbox");
      // hideLoader();
      return;
    }

    if (!ValidateDocSignature()) {
      notify(1, "Doctor Signature is mandatory");
      // hideLoader();
      return;
    }

    if (!ValidateCandSignature()) {
      notify(1, "Candidate Signature is Mandatory");
      return;
    }

    if (!isDoctorSignatureSubmit) {
      notify(1, "Please Save Doctor's Signature");
      return;
    }

    if (!isCandidateSignature) {
      notify(1, "Please Save Candidate's Signature");
    }

    if (decision !== null) {
      // if (decision.value == "3") {
      if (Rejection == null || Rejection?.value === "") {
        errors.Rejection = "Reason is required";
        isValid = false;
      }
      // }
    }

    if (decision === null) {
      errors.decision = "Decision is required";
      isValid = false;
    }

    if (isValid) {
      let stageId = null;

      let getWorkFlowStages = await APICall(GetWorkflowStages, "POST", {
        employeeClass: empClass.code,
        CategoryId: formData?.categoryId,
      });

      if (getWorkFlowStages && getWorkFlowStages.status === 0) {
        if (getWorkFlowStages.data && getWorkFlowStages.data.length > 0) {
          let stages = getWorkFlowStages.data;
          let currentStage = stages.find((x) => x.id === currentStageId);
          if (currentStage) {
            let nextStageId = stages.find(
              (x) => x.sequence > currentStage.sequence
            );
            if (nextStageId) {
              stageId = nextStageId.id;
            }
          }
        }
      }

      let requestParams = {
        AssociateId: scanData?.CandidateId || tokenId,
        StageId: decision.value == "3" ? currentStageId : stageId,
        WorkflowStageId: 2,
        Decision: decision?.value,
        RejectionReason: Rejection?.value, // RejectionReason,
        Remarks: DecisionRemark,
        IsActive: isActive,
        CreatedBy: userDetails?.Id,
        IsProcessing: 0,
      };
      const response = await APICall(
        InsertUpdateTokenIssuanceDetails,
        "POST",
        requestParams
      );

      if (response.status === 0) {
        if (decision?.value === 4) {
          let currentStageId = 0;
          let stages = getWorkFlowStages.data;
          let currentStage = stages.find((x) => x.id === stageId);
          if (currentStage) {
            currentStageId = currentStage.id;
            let nextStageId = stages.find(
              (x) => x.sequence > currentStage.sequence
            );
            if (nextStageId) {
              stageId = nextStageId.id;
            }
          }

          const insertUpdateCandidateWorkflowInstanceTasks = await APICall(
            InsertUpdateCandidateWorkflowInstanceTasks,
            "POST",
            {
              Id: scanData?.CandidateId || tokenId,
              CurrentStagesId: currentStageId,
              NextStageId: stageId,
              Decision: decision?.value,
              CategoryId: formData?.categoryId,
              CreatedBy: userDetails?.Id || proxyUserDetails?.value
            }
          );
        }

        notify(
          0,
          `The data for Candidate ID ${scanData?.CandidateId || tokenId} has been successfully submitted. You can proceed for next candidate`
        );
        window.location.reload();
        setIsDisabled(true);
      } else {
        notify(1, "Data not inserted");
      }
    } else {
      setFormErrors(errors);
    }
  };

  async function fetchDropdown(searchText = "", code = "", value = null) {
    try {
      const response = await APICall(
        GetPositionDropdownData,
        "POST",
        {
          SearchText: searchText,
        },
        true
      );

      if (response?.status === 0) {
        if (response?.data) {
          return response?.data;
        } else {
          return [];
        }
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  async function handleSearchForAsyncDropdown(event, code, callback) {
    try {
      if (event?.length >= 3) {
        const data = await fetchDropdown(event, code);
        callback(data);
      } else {
        callback([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handleBarcode(scannedBarcode: any) {
    const object = JSON.parse(scannedBarcode);
    console.log(object);
    setScanData(object || null);
    setBarcode("");
    setModalForGunScanner(false);
    let isOrganisationInf = await isOrganisationunit(object?.CandidateId);

    await handleScanData(scannedBarcode);

    hideLoader();
  }

  function onChange(event, code) {
    try {
      setFormData((prev) => {
        return { ...prev, [code]: event };
      });
    } catch (error) {
    } finally {
    }
  }

  const isOrganisationunit = async (AssociateId: any) => {
    try {
      const { data } = await APICall(isOrganisationInfo, "POST", {
        TokenId: AssociateId,
        UserId: userDetails.Id,
      });

      if (data !== null) {
        console.log(data, "Organisationunitdata");
        return data.isExist;
      }
    } catch (error) {
      // Handle any errors that occur during the API call
      console.error("Error during API call:", error);
      return false; // or another appropriate default value
    }
  };
  const handleCaptureClick = () => {
    setShowModal(true);
  };

  const handleCaptureClickCan = () => {
    setShowcandidatemodel(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalCLoseCandidate = () => {
    setShowcandidatemodel(false);
  };

  const handleCaptureConfirm = () => {
    const signatureDataURL = sigCanvas.current.toDataURL("image/png");
    console.log(signatureDataURL);
    const base64ImageData = signatureDataURL.split(",")[1];

    console.log(base64ImageData, "base64");
    setsigatureimage(signatureDataURL);
    setDoctorbase64(base64ImageData);
    setShowModal(false);

    //uploadDoctorSignature(base64ImageData);
  };

  const handleCaptureCandidate = () => {
    const signatureDataURLcan = sigCanvas1.current.toDataURL("image/png");
    console.log(signatureDataURLcan);
    const base64ImageDatacan = signatureDataURLcan.split(",")[1];
    console.log(base64ImageDatacan, "base64");
    setSignatureCandidate(signatureDataURLcan);
    setcandidatebase64(base64ImageDatacan);
    setShowcandidatemodel(false);
  };

  const uploadDoctorSignature = async () => {
    showLoader();

    if (!ValidateDocSignature()) {
      notify(1, "Doctor Signature is mandatory");
      hideLoader();
      return;
    }

    let reqparams = {
      SectionName: "DoctorSignatute",
      AssociatesDetailsId:
        scanData?.CandidateId || tokenData?.candidate_Id?.label,

      DocumentTypeId: 5,

      StagesId: 3,

      file: {
        name: "dummy_image.jpg",
        Data: doctorbase64,
      },
      IsActive: 1,
      CreatedBy: 1,
    };

    const response = await APICall(
      UploadSignatureAndSetDetails,
      "POST",
      reqparams
    );

    if (response.status === 0) {
      notify(0, "Doctor signature upload Successfully");
      setIsDoctorSignatureSubmit(true);
    } else {
      notify(1, "Data not inserted");
    }
    hideLoader();
  };

  const ValidateDocSignature = () => {
    let isValid = true;
    let errors: FormErrors = {};

    if (doctorbase64 == null) {
      isValid = false;
    }

    //setFormErrors(errors);
    return isValid;
  };

  const ValidateCandSignature = () => {
    let isValid = true;
    let errors: FormErrors = {};

    if (candidatebase64 == null) {
      isValid = false;
    }

    //setFormErrors(errors);
    return isValid;
  };
  const uploadDoctorSignaturecandidate = async () => {
    showLoader();

    if (!ValidateCandSignature()) {
      notify(1, "Candidate Signature is mandatory");
      hideLoader();
      return;
    }
    let reqparams = {
      SectionName: "CandidateSignature",

      AssociatesDetailsId:
        scanData?.CandidateId || tokenData?.candidate_Id?.label,

      DocumentTypeId: 6,

      StagesId: 3,

      file: {
        name: "dummycandidate_image.jpg",
        Data: candidatebase64,
      },
      IsActive: 1,
      CreatedBy: 1,
    };

    const response = await APICall(
      UploadSignatureAndSetDetails,
      "POST",
      reqparams
    );

    if (response.status === 0) {
      notify(0, "Candidate signature  uploaded Successfully");
      setIsCandidateSignature(true);
    } else {
      notify(1, "Candidate signature not inserted");
    }
    hideLoader();
  };

  const handleClearSignature = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
    }
  };

  const handleClearSignatureCandidate = () => {
    if (sigCanvas1.current) {
      sigCanvas1.current.clear();
    }
  };

  const handleScanData = async (data) => {
    try {
      setsavedSections([]);
      const response = JSON.parse(data);
      let isOrganisationInf = await isOrganisationunit(response?.CandidateId);
      // if (!isOrganisationInf) {
      //   setisOrganisationunitpopup(true);
      // } else {
      //   setisOrganisationunitpopup(false);
      //  setStartScan(false);
      if (Object.keys(response)?.length > 0 && response["CandidateId"]) {
        showLoader();
        const otherData = await APICall(GetAssociateDetailsById, "POST", {
          Id: response?.CandidateId,
          WorkflowStageId: 2,
          CreatedBy: userDetails?.Id,
        });

        console.log(otherData);
        if (otherData.data.isProcessing === true) {
          setIsProcessing(0);
        }

        const { status, data, message } = otherData;

        if (status == 0) {
          const currentDate = new Date();
          setGenralExaminationSaved(otherData.data.generalExamination);
          if (otherData.data.generalExamination) {
            if (!savedSections.includes("General Examination")) {
              saveSection("General Examination");
            }
          }
          setPathologySaved(otherData.data.pathologicalTest);
          if (otherData.data.pathologicalTest) {
            if (!savedSections.includes("Pathology Examination")) {
              saveSection("Pathology Examination");
            }
          }
          setClinicalExaminationSaved(otherData.data.clinicalExamination);
          if (otherData.data.clinicalExamination) {
            if (!savedSections.includes("Clinical Examination")) {
              saveSection("Clinical Examination");
            }
          }
          setAudiometrySaved(otherData.data.audiometrySpirometry);
          if (otherData.data.audiometrySpirometry) {
            if (
              !savedSections.includes("Audiometry & Spirometry Examination")
            ) {
              saveSection("Audiometry & Spirometry Examination");
            }
          }
          const year = currentDate.getFullYear();
          const month = (currentDate.getMonth() + 1)
            .toString()
            .padStart(2, "0");
          const day = currentDate.getDate().toString().padStart(2, "0");
          const hours = currentDate.getHours().toString().padStart(2, "0");
          const minutes = currentDate.getMinutes().toString().padStart(2, "0");
          const seconds = currentDate.getSeconds().toString().padStart(2, "0");

          const formattedInTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

          console.log(formattedInTime); // Output: Current local date and time in YYYY-MM-DD HH:MM:SS format

          setIntime(formattedInTime);
          response["Category"] = data?.category || "";
          response["SkillCategory"] = data?.skillCategory || "";
          response["WorkArea"] = data?.workAreaName || "";

          setCandidateDetails((prev) => ({
            ...prev,
            category: data?.category || "",
            skillCategory: data?.skillCategory || "",
            workArea: data?.workAreaName || "",
            mobileNumber: data?.mobileNumber || "",
            name: data?.name || "",
          }));

          if (data?.currentStagesId !== 3) {
            setIsDisabled(true);
            setIsAccordiandisable(true);
            setAudiometry(null);
            //setSpirometry(null);
          } else {
            setIsDisabled(false);
            setIsAccordiandisable(false);
          }

          if (
            data?.currentStagesId == 4 ||
            data?.currentStagesId == 5 ||
            data?.currentStagesId == 6
          ) {
            setConsentGiven(true);
          }

          if (data) {
            setCandidateGeneralExaminationData(data);
            setCandidateAudiometeryData(data);
            setCandidateDecisionWhenScan(data);
            setPathologicalTestsData(data);
            setClinicalExaminationData(data);
            setRemark(data?.additionalRemarks || "");
            GetSectionByWorkAreaId(data?.workAreaId);
            setCurrentStageId(data?.currentStagesId);
          }

          console.log(data?.positionTId, "tid");
          console.log(data?.positionId, "sanket");
          if (data?.positionId && data?.positionTId) {
            setFormData((prev) => ({
              ...prev,
              position_Id: {
                value: data?.positionTId,
                label: data?.positionId,
              },
              categoryId: data?.categoryId,
              currentStageId: data?.currentStagesId,
            }));
            setPositionDisable(true);
          }
          setskillCategorydrop(data?.profileSkillCategory);
          QRCodeExist(response?.CandidateId);
          doctorsign(response?.CandidateId);
          candidatesign(response?.CandidateId);
          candidateProfile(response?.CandidateId);
          Fingerprint(response?.CandidateId);

          setScanData(response);
          setStartScan(false);
        } else {
          console.error(message);
        }
      }
      //}
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const handleScanData1 = async (id: any) => {
    try {
      // const response = JSON.parse(data);
      setsavedSections([]);
      showLoader();
      const otherData = await APICall(GetAssociateDetailsById, "POST", {
        Id: id,
        WorkflowStageId: 2,
        CreatedBy: userDetails?.Id,
      });

      settokenId(id);
      console.log(otherData, "otherData");
      if (otherData.data.isProcessing === true) {
        setIsProcessing(0);
      }

      const { status, data, message } = otherData;

      if (status == 0) {
        const currentDate = new Date();
        setGenralExaminationSaved(otherData.data.generalExamination);
        if (otherData.data.generalExamination) {
          if (!savedSections.includes("General Examination")) {
            saveSection("General Examination");
          }
        }
        setPathologySaved(otherData.data.pathologicalTest);
        if (otherData.data.pathologicalTest) {
          if (!savedSections.includes("Pathology Examination")) {
            saveSection("Pathology Examination");
          }
        }
        setClinicalExaminationSaved(otherData.data.clinicalExamination);
        if (otherData.data.clinicalExamination) {
          if (!savedSections.includes("Clinical Examination")) {
            saveSection("Clinical Examination");
          }
        }
        setAudiometrySaved(otherData.data.audiometrySpirometry);
        if (otherData.data.audiometrySpirometry) {
          if (!savedSections.includes("Audiometry & Spirometry Examination")) {
            saveSection("Audiometry & Spirometry Examination");
          }
        }
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
        const day = currentDate.getDate().toString().padStart(2, "0");

        const hours = currentDate.getHours().toString().padStart(2, "0");
        const minutes = currentDate.getMinutes().toString().padStart(2, "0");
        const seconds = currentDate.getSeconds().toString().padStart(2, "0");

        const formattedInTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

        console.log(formattedInTime); // Output: Current local date and time in YYYY-MM-DD HH:MM:SS format

        setIntime(formattedInTime);
        // response["Category"] = data?.category || "";
        // response["SkillCategory"] = data?.skillCategory || "";
        // response["WorkArea"] = data?.workAreaName || "";
        setCategoryondrop(otherData?.data.category);
        setskillCategorydrop(otherData?.data?.profileSkillCategory);

        setCandidateDetails((prev) => ({
          ...prev,
          category: data?.category || "",
          skillCategory: data?.skillCategory || "",
          workArea: data?.workAreaName || "",
          mobileNumber: data?.mobileNumber || "",
          name: data?.name || "",
        }));

        if (data?.currentStagesId !== 3) {
          setIsDisabled(true);
          setIsAccordiandisable(true);
          setAudiometry(null);
          //setSpirometry(null);
        } else {
          setIsDisabled(false);
          setIsAccordiandisable(false);
        }

        if (
          data?.currentStagesId == 4 ||
          data?.currentStagesId == 5 ||
          data?.currentStagesId == 6
        ) {
          setConsentGiven(true);
        }

        if (data) {
          setCandidateGeneralExaminationData(data);
          setCandidateAudiometeryData(data);
          setCandidateDecisionWhenScan(data);
          setPathologicalTestsData(data);
          setClinicalExaminationData(data);
          setRemark(data?.additionalRemarks || "");
          GetSectionByWorkAreaId(data?.workAreaId);
          setCurrentStageId(data?.currentStagesId);
        }

        console.log(data?.positionTId, "tid");
        console.log(data?.positionId, "sanket");
        if (data?.positionId && data?.positionTId) {
          setFormData((prev) => ({
            ...prev,
            position_Id: {
              value: data?.positionTId,
              label: data?.positionId,
            },
            categoryId: data?.categoryId,
            currentStageId: data?.currentStagesId,
          }));
          setPositionDisable(true);
        }

        QRCodeExist(id);
        doctorsign(id);
        candidatesign(id);
        candidateProfile(id);
        Fingerprint(id);

        // setScanData(response);
        setStartScan(false);
      } else {
        console.error(message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const doctorsign = async (id: any) => {
    const response = await APICall(GetAssociatesDocument, "POST", {
      AssociatesDetailsId: id,
      DocumentTypeId: 5,
    });

    if (response.data !== null) {
      console.log(response.data, "console");
      setsigatureimage(response?.data?.documentDataObject);
      if (response?.data?.documentDataObject) {
        setIsDoctorSignatureSubmit(true);
      } else {
        setIsDoctorSignatureSubmit(false);
      }
      let signbase64 = response?.data?.documentDataObject?.split(",")[1];
      console.log(signbase64, "saas");
      setDoctorbase64(signbase64);
    }
  };

  const Fingerprint = async (id: any) => {
    const response = await APICall(GetAssociatesFingerprint, "POST", {
      AssociatesDetailsId: id,
      FpPosition: 6,
      FpHand: 2,
    });

    if (response.data !== null) {
      console.log(response.data, "console");
      setthumbimage(response?.data?.documentDataObject);
      let signbase64 = response?.data?.documentDataObject?.split(",")[1];
    }
  };

  const candidatesign = async (id: any) => {
    const response = await APICall(GetAssociatesDocument, "POST", {
      AssociatesDetailsId: id,
      DocumentTypeId: 6,
    });

    if (response?.data?.documentDataObject) {
      setIsCandidateSignature(true);
    } else {
      setIsCandidateSignature(false);
    }
    if (response.data !== null) {
      console.log(response.data, "console");
      setSignatureCandidate(response?.data?.documentDataObject);

      let signbase64can = response?.data?.documentDataObject?.split(",")[1];
      console.log(signbase64can, "saas");
      setcandidatebase64(signbase64can);
    }
  };
  const candidateProfile = async (id: any) => {
    const response = await APICall(GetAssociatesDocument, "POST", {
      AssociatesDetailsId: id,
      DocumentTypeId: 8,
    });

    if (response.data !== null) {
      console.log(response.data, "console");
      setCandidateimage(response?.data?.documentDataObject);
    }
  };
  /**
   * Setting candidate data when the qr code is scanned and data is already filled
   */
  function setCandidateDecisionWhenScan(data) {
    try {
      if (data?.asthmaId) {
        const asthmaId = CandidateOption.find(
          (item) => item.value == data?.asthmaId
        );
        setAsthma(asthmaId);
      }

      if (data?.bloodPressureId) {
        const bloodPressureId = CandidateOption?.find(
          (item) => item.value == data?.bloodPressureId
        );
        setBPType(bloodPressureId);
      }

      if (data?.hearingDisabilityId) {
        const hearingDisabilityId = CandidateOption?.find(
          (item) => item.value == data.hearingDisabilityId
        );
        setHearingDisability(hearingDisabilityId);
      }

      if (data?.diabetesId) {
        const diabetesId = CandidateOption?.find(
          (item) => item.value == data.diabetesId
        );
        setDiabetes(diabetesId);
      }
      debugger;
      if (data?.herniaId) {
        const herniaId = CandidateOption?.find(
          (item) => item.value == data.herniaId
        );
        setHernia(herniaId);
      }

      if (data?.heartProblemsId) {
        const heartProblemsId = CandidateOption?.find(
          (item) => item.value == data.heartProblemsId
        );
        setHeartProblem(heartProblemsId);
      }

      if (data?.nervousDisorderId) {
        const nervousDisorderId = CandidateOption?.find(
          (item) => item.value == data.nervousDisorderId
        );
        setNervousDisorder(nervousDisorderId);
      }

      if (data?.backDiscProblemId) {
        const backDiscProblemId = CandidateOption?.find(
          (item) => item.value == data.backDiscProblemId
        );
        setBackProblem(backDiscProblemId);
      }
      if (data?.visualProblemId) {
        const visualProblemId = CandidateOption?.find(
          (item) => item.value == data.visualProblemId
        );
        setVisualProblem(visualProblemId);
      }

      if (data?.joinTProblemId) {
        const joinTProblemId = CandidateOption?.find(
          (item) => item.value == data.joinTProblemId
        );
        setJointProblem(joinTProblemId);
      }
      if (data?.epilepsyORBlackoutsId) {
        const epilepsyORBlackoutsId = CandidateOption?.find(
          (item) => item.value == data.epilepsyORBlackoutsId
        );
        setEpilepsy(epilepsyORBlackoutsId);
      }

      if (data?.stomachDisordersId) {
        const stomachDisordersId = CandidateOption?.find(
          (item) => item.value == data.stomachDisordersId
        );
        setStomachProblem(stomachDisordersId);
      }

      if (data?.liverDisordersId) {
        const liverDisordersId = CandidateOption?.find(
          (item) => item.value == data.liverDisordersId
        );
        setLiverProblem(liverDisordersId);
      }
      if (data?.anaemiaId) {
        const anaemiaId = CandidateOption?.find(
          (item) => item.value == data.anaemiaId
        );
        setAnaemia(anaemiaId);
      }
      if (data?.phobiaId) {
        const phobiaId = CandidateOption?.find(
          (item) => item.value == data.phobiaId
        );
        setPhobia(phobiaId);
      }
      if (data?.drugAlcoholAddictionId) {
        const drugAlcoholAddictionId = CandidateOption?.find(
          (item) => item.value == data.drugAlcoholAddictionId
        );
        setDrugsAlcohol(drugAlcoholAddictionId);
      }

      if (data?.allergiesId) {
        const allergiesId = CandidateOption?.find(
          (item) => item.value == data.allergiesId
        );
        setAllergies(allergiesId);
      }

      if (data?.mobilityProblemsId) {
        const mobilityProblemsId = CandidateOption?.find(
          (item) => item.value == data.mobilityProblemsId
        );
        setMobilityProblem(mobilityProblemsId);
      }

      if (data?.whiteFingerId) {
        const whiteFingerId = CandidateOption?.find(
          (item) => item.value == data.whiteFingerId
        );
        setWhiteFinger(whiteFingerId);
      }
      if (data?.disabilityHandicapId) {
        const disabilityHandicapId = CandidateOption?.find(
          (item) => item.value == data.disabilityHandicapId
        );
        setDisability(disabilityHandicapId);
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Setting candiate general examination data when qr code is scanned and
   * data is already stored.
   */
  function setCandidateGeneralExaminationData(data) {
    try {
      setPulserate(data?.pulseRate || 0);
      console.log(data?.bloodPressure);
      setBloodPressure(data?.bloodPressure || 0);
      setSystolicBloodPressure(data?.systolicBloodPressure || 0);
      setDiastolicBloodPressure(data?.diastolicBloodPressure || 0);
      setHeight(data?.height || 0);
      setWeight(data?.weight || 0);
      setGenderName(data?.genderName || "");
      setAge(data?.age || "");
      setDOB(data?.birthDate || "");
      let rightNearVisionwithSpecsFind;
      let rightNearVisionWithoutSpecs;
      let rightDistantVisionWithSpecs;
      let rightDistantVisionWithoutSpecs;
      let leftNearVisionwithSpecs;
      let leftNearVisionWithoutSpecs;
      let leftDistantVisionWithSpecs;
      let leftDistantVisionWithoutSpecs;
      let isNWSPec;
      let isDWSpec;
      if (data?.bloodGroup) {
        const bloodGroupFind = BloodGroupOptions?.find(
          (item) => item?.value == data?.bloodGroup
        );
        if (bloodGroupFind) setBloodGroup(bloodGroupFind);
      }

      if (data?.rightNearVisionwithSpecs) {
        rightNearVisionwithSpecsFind = NearVisionSpecOption?.find(
          (item) => item?.value == data?.rightNearVisionwithSpecs
        );

        if (rightNearVisionwithSpecsFind)
          setRightNearVision(rightNearVisionwithSpecsFind);
        console.log(rightNearVisionwithSpecsFind, "right");
      }

      if (data?.rightNearVisionWithoutSpecs) {
        rightNearVisionWithoutSpecs = NearVisionWSpecOption?.find(
          (item) => item?.value == data?.rightNearVisionWithoutSpecs
        );
        setRightNearVision(rightNearVisionWithoutSpecs);
      }

      if (data?.rightDistantVisionWithSpecs) {
        rightDistantVisionWithSpecs = DistantVisionSpecOption.find(
          (item) => item?.value == data?.rightDistantVisionWithSpecs
        );
        setRightDistantVision(rightDistantVisionWithSpecs);
      }

      if (data?.rightDistantVisionWithoutSpecs) {
        rightDistantVisionWithoutSpecs = DistantVisionWSpecOption?.find(
          (item) => item?.value == data?.rightDistantVisionWithoutSpecs
        );

        setRightDistantVision(rightDistantVisionWithoutSpecs);
      }

      if (data?.rightColorVision) {
        const rightColorVision = ColorVisionOption?.find(
          (item) => item?.value == data?.rightColorVision
        );
        setRightColorVision(rightColorVision);
      }

      if (data?.leftNearVisionwithSpecs) {
        leftNearVisionwithSpecs = NearVisionSpecOption.find(
          (item) => item.value == data?.leftNearVisionwithSpecs
        );
        setLeftNearVision(leftNearVisionwithSpecs);
      }

      if (data?.leftNearVisionWithoutSpecs) {
        leftNearVisionWithoutSpecs = NearVisionSpecOption?.find(
          (item) => item?.value == data?.leftNearVisionWithoutSpecs
        );
        setLeftNearVision(leftNearVisionWithoutSpecs);
      }

      if (data?.leftDistantVisionWithSpecs) {
        leftDistantVisionWithSpecs = DistantVisionSpecOption?.find(
          (item) => item.value == data?.leftDistantVisionWithSpecs
        );
        setLeftDistantVision(leftDistantVisionWithSpecs);
      }

      if (data?.leftDistantVisionWithoutSpecs) {
        leftDistantVisionWithoutSpecs = DistantVisionWSpecOption?.find(
          (item) => item?.value == data?.leftDistantVisionWithoutSpecs
        );

        setLeftDistantVision(leftDistantVisionWithoutSpecs);
      }

      if (data?.isNWSPec) {
        isNWSPec = SpecsOptions?.find((item) => item?.value == data?.isNWSPec);
        setSpecsValue(isNWSPec);
      }
      if (data?.isDWSpec) {
        isDWSpec = DistantSpecsOptions?.find(
          (item) => item?.value == data?.isDWSpec
        );
        setDistantSpecsValue(isDWSpec);
      }

      //  setLeftNearVision(leftNearVisionwithSpecs || leftNearVisionWithoutSpecs)

      if (rightNearVisionwithSpecsFind && rightNearVisionWithoutSpecs) {
        if (
          rightNearVisionwithSpecsFind?.label.trim() !== "N8" &&
          rightNearVisionwithSpecsFind?.label.trim() !== "N6"
        ) {
          setRejection((prev) => RejectionReason[2]);
          setDecision(DecisionOption[0]);
        }

        if (
          rightNearVisionWithoutSpecs?.label.trim() !== "N8" &&
          rightNearVisionWithoutSpecs?.label.trim() !== "N6"
        ) {
          setRejection((prev) => RejectionReason[2]);
          setDecision(DecisionOption[0]);
        }
      }

      if (rightDistantVisionWithSpecs && rightDistantVisionWithoutSpecs) {
        if (
          rightDistantVisionWithSpecs?.label.trim() !== "6/6" &&
          rightDistantVisionWithSpecs?.label.trim() !== "6/9"
        ) {
          setRejection((prev) => RejectionReason[2]);
          setDecision(DecisionOption[0]);
        }

        if (
          rightDistantVisionWithoutSpecs?.label.trim() !== "6/6" &&
          rightDistantVisionWithoutSpecs?.label.trim() !== "6/9"
        ) {
          setRejection((prev) => RejectionReason[2]);
          setDecision(DecisionOption[0]);
        }
      }

      if (leftNearVisionwithSpecs && leftNearVisionWithoutSpecs) {
        if (
          leftNearVisionwithSpecs?.label.trim() !== "N8" &&
          leftNearVisionwithSpecs?.label.trim() !== "N6"
        ) {
          setRejection((prev) => RejectionReason[2]);
          setDecision(DecisionOption[0]);
        }

        if (
          leftNearVisionWithoutSpecs?.label.trim() !== "N8" &&
          leftNearVisionWithoutSpecs?.label.trim() !== "N6"
        ) {
          setRejection((prev) => RejectionReason[2]);
          setDecision(DecisionOption[0]);
        }
      }

      if (leftDistantVisionWithSpecs && leftDistantVisionWithoutSpecs) {
        if (
          leftDistantVisionWithSpecs?.label.trim() !== "6/6" &&
          leftDistantVisionWithSpecs?.label.trim() !== "6/9"
        ) {
          setRejection((prev) => RejectionReason[2]);
          setDecision(DecisionOption[0]);
        }

        if (
          leftDistantVisionWithoutSpecs?.label.trim() !== "6/6" &&
          leftDistantVisionWithoutSpecs?.label.trim() !== "6/9"
        ) {
          setRejection((prev) => RejectionReason[2]);
          setDecision(DecisionOption[0]);
        }
      }

      if (data?.genderName == "Male" && Number(data?.weight) < 40) {
        setRejection((prev) => RejectionReason[2]);
        setDecision(DecisionOption[0]);
      }

      if (data?.genderName === "Female" && Number(data?.weight) < 35) {
        setRejection((prev) => RejectionReason[2]);
        setDecision(DecisionOption[0]);
      }

      // if (
      //   Number(data?.bloodPressure) < 100 ||
      //   Number(data?.bloodPressure) > 140
      // ) {
      //   setRejection((prev) => RejectionReason[2]);
      //   setDecision(DecisionOption[0]);
      // }

      // if (
      //   Number(data?.bloodPressure) < 60 ||
      //   Number(data?.bloodPressure) > 95
      // ) {
      //   setRejection((prev) => RejectionReason[2]);
      //   setDecision(DecisionOption[0]);
      // }

      if (
        data?.genderName == "Male" &&
        (Number(data?.height) < 145 || Number(data?.height) > 185)
      ) {
        setRejection((prev) => RejectionReason[1]);
        setDecision(DecisionOption[0]);
      }

      if (
        data?.genderName == "Female" &&
        (Number(data?.height) < 140 || Number(data?.height) > 170)
      ) {
        setRejection((prev) => RejectionReason[1]);
        setDecision(DecisionOption[0]);
      }

      if (data?.leftColorVision) {
        const leftColorVision = ColorVisionOption.find(
          (item) => item.value == data?.leftColorVision
        );

        setLeftColorVision(leftColorVision);
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   *  Setting candiate Audiometry data
   */
  function setCandidateAudiometeryData(data) {
    try {
      if (data?.audiometry) {
        const audiometry = AudiometryOption?.find(
          (item) => item?.value == data?.audiometry
        );
        setAudiometry(audiometry);
      }

      if (data?.spirometry) {
        const spirometry = SpirometryOption.find(
          (item) => item.value == data?.spirometry
        );
        setSpirometry(spirometry);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function setPathologicalTestsData(data) {
    try {
      if (String(data?.pathologicalTests).trim().length > 0) {
        const value = data?.pathologicalTests?.split(",") || [];

        const pathologicalTests = PathologicalOption.filter((item) =>
          value.includes(item.value.toString())
        );
        setPathologicalTest(pathologicalTests);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const getLabelForStage = (stageID) => {
    switch (stageID) {
      case 1:
        return "Candidate Authentication";
      case 2:
        return "Document Screen";
      case 3:
        return "Medical  Screen";
      case 4:
        return "Skill Test  Screen";
      case 5:
        return "UAN  Screen";
      case 6:
        return "Appointment  Screen";
      // Add more cases for additional stages
      default:
        return "EP";
    }
  };
  function setClinicalExaminationData(data) {
    try {
      if (data?.mouthDisorders) {
        const mouthDisorders = ClinicalOption?.find(
          (item) => item.value == data?.mouthDisorders
        );
        setMouthDisorder(mouthDisorders);
      }

      if (data?.respiratoryIssue) {
        const respiratoryIssue = ClinicalOption?.find(
          (item) => item.value == data?.respiratoryIssue
        );
        setRespiratorySystem(respiratoryIssue);
      }

      if (data?.respiratoryIssue) {
        const respiratoryIssue = ClinicalOption?.find(
          (item) => item.value == data?.respiratoryIssue
        );
        setRespiratorySystem(respiratoryIssue);
      }
      if (data?.cardiovascularIssue) {
        const cardiovascularIssue = ClinicalOption?.find(
          (item) => item.value == data?.cardiovascularIssue
        );
        setCardio(cardiovascularIssue);
      }
      if (data?.gitAbdomenIssue) {
        const gitAbdomenIssue = ClinicalOption?.find(
          (item) => item.value == data?.gitAbdomenIssue
        );
        setAbdomen(gitAbdomenIssue);
      }

      if (data?.cns) {
        const cns = ClinicalOption?.find((item) => item.value == data?.cns);
        setCNS(cns);
      }
    } catch (error) {
      console.error(error);
    }
  }
  const calculateAgeFromDOB = (birthDate) => {
    // Parse the birthDate string into a Date object
    var dob = new Date(birthDate);

    // Get the current date
    var currentDate = new Date();

    // Calculate the difference in years
    var age = currentDate.getFullYear() - dob.getFullYear();

    // Adjust the age if the current date has not passed the birth month yet
    if (
      currentDate.getMonth() < dob.getMonth() ||
      (currentDate.getMonth() === dob.getMonth() &&
        currentDate.getDate() < dob.getDate())
    ) {
      age--;
    }

    return age;
  };

  const handleDocumentClick = async () => {
    showLoader();
    setShowWaitText(true);
    let ageVal = calculateAgeFromDOB(dob);
    const currentDate = moment().format("D MMMM YYYY");
    const dateString = currentDate.toString();
    const bloodpressue =
      "Systolic Blood Pressure:  " +
      String(SystolicBloodPressure || 0) +
      " Diastolic Blood Pressure: " +
      String(DiastolicBloodPressure || 0);
    let reqparams = {
      documenttypecode: "PEMEDEX",
      attributes: [
        {
          candidateid: scanData?.CandidateId.toString() || tokenId.toString(),
          candidatename: candidateDetails?.name,
          candidateage: ageVal.toString(), //"35",
          candidatesex: genderName, //"Male",
          candidateworkarea: scanData?.WorkArea, //"Area 5",
          candidatepulserate: Pulserate.toString(), //"69",
          candidatebloodpressure: bloodpressue, //"115/77",
          candidateheight: Height.toString(), // "172",
          candidateweight: Weight.toString(), //"78.50",
          // rtnearvisionwithspecs: RightNearVisionSpec?.label.toString(), // "1",
          // ltnearvisionwithspecs: LeftNearVisionSpec?.label.toString(), // "1",
          // rtnearvisionwithoutspecs: RightNearVisionWSpec?.label.toString(), // "2",
          // ltnearvisionwithoutspecs: LeftNearVisionWSpec?.label.toString(), // "2",
          // rtdistvisionwithspecs: RightDistantVisionSpec?.label.toString(), // "1",
          // ltdistvisionwithspecs: LeftDistantVisionSpec?.label.toString(), // "1",
          // rtdistvisionwithoutspecs: RightDistantVisionWSpec?.label.toString(), // "2",
          // ltdistvisionwithoutspecs: LeftDistantVisionWSpec?.label.toString(), // "2",
          rtnearvisionwithspecs:
            SpecsValue?.value === 1 ? RightNearVision?.label.toString() : null,
          rtnearvisionwithoutspecs:
            SpecsValue?.value === 1 ? null : RightNearVision?.label.toString(),
          rtdistvisionwithspecs:
            DistantSpecsValue?.value === 1
              ? RightDistantVision?.label.toString()
              : null,
          rtdistvisionwithoutspecs:
            DistantSpecsValue?.value === 1
              ? null
              : RightDistantVision?.label.toString(),

          ltnearvisionwithspecs:
            SpecsValue?.value === 1 ? LeftNearVision?.label.toString() : null,
          ltnearvisionwithoutspecs:
            SpecsValue?.value === 1 ? null : LeftNearVision?.label.toString(),
          ltdistvisionwithspecs:
            DistantSpecsValue?.value === 1
              ? LeftDistantVision?.label.toString()
              : null,
          ltdistvisionwithoutspecs:
            DistantSpecsValue?.value === 1
              ? null
              : LeftDistantVision?.label.toString(),

          colorvisionrt: RightColorVision?.label.toString(), //"1",
          colorvisionlt: LeftColorVision?.label.toString(), //"1",
          candidateaudiometry:
            Audiometry == null ? "" : Audiometry?.label.toString(), //"8000",
          candidatespirometry:
            Spirometry == null ? "" : Spirometry?.label.toString(), //"84",
          candidatebloodgroup: BloodGroup?.label.toString(), //"B+",
          candidatepathologicaltest:
            PathologicalTest == null
              ? ""
              : PathologicalTest.map((event) => event.label).join(","), // null,
          candidatemouthdisorders: MouthDisorder?.label.toString(), //null,
          candidaterespiratorysystem: RespiratorySystem?.label.toString(), // null,
          candidatecardiovascularsystem: Cardio?.label.toString(), // null,
          candidateabdomen: Abdomen?.label.toString(), // null,
          candidatecns: CNS?.label.toString(), // null,
          candidateDisability: Disability?.label.toString(), // null,
          candidatetestresult: "Fit",
          medicalsignaturephoto: doctorbase64 || null,
          employeesignaturephoto: candidatebase64 || null,
          medicalOfficer: "" || null,
          // examinationDate: "16 March 2024",
          examinationDate: dateString,
          photo:
            candidateimage == null
              ? null
              : candidateimage.replace("data:image/png;base64,", ""),
        },
      ],
    };

    if (!areAllSectionsSaved()) {
      notify(1, "Please save all the sections");
      hideLoader();
    } else {
      const response = await APICall(
        // GenerateBlueColarDocument,
        `${docURL}/GenerateBlueColarDocument`,
        "POST",
        reqparams
      );

      console.log(reqparams);
      if (response.status === 200) {
        setDocumentGenerated(true);
        getDocumentById(response.data);
      } else {
        notify(1, "Data not found");
        hideLoader();
      }
    }

    // hideLoader();
  };

  const getDocumentById = async (data: any) => {
    // showLoader();
    let reqparams = {
      requestid: data,
      doctype: "signedpdf",
    };
    const response = await APICall(
      // Statuswithfile,
      `${docURL}/Statuswithfile`,
      "POST",
      reqparams
    );

    if (response.status === 200) {
      //setDocURL(response.data)
      openBase64FileInNewTab(response.data, "MedicalDocument.pdf");
      setShowWaitText(false);
      hideLoader();
    } else if (response.status === 404) {
      getDocumentById(data);
    } else {
      notify(1, "Data not found");
      hideLoader();
    }
  };

  function openBase64FileInNewTab(base64String, fileName) {
    const mimeType = inferMimeType(fileName);
    const blob = base64toBlob(base64String, mimeType);

    const url = URL.createObjectURL(blob);

    // Open the URL in a new tab
    const newTab = window.open(url, "_blank");
    if (newTab) {
      newTab.focus();
    } else {
      console.error(
        "Opening a new tab was blocked by the browser. You can try allowing pop-ups for this site."
      );
    }

    // Revoke the Object URL when it's no longer needed
    URL.revokeObjectURL(url);
  }
  function base64toBlob(base64String, mimeType) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  }

  const getDocConfigUrl = async () => {
    const response = await APICall(GetConfigValueByKey, "POST", {
      Key: "WelspunDMSAPILink",
    });

    if (response?.status === 0) {
      setDocURL(response.data);
      return response.data;
    } else {
      return null;
    }
  };

  function inferMimeType(fileName) {
    const extension = fileName.split(".").pop().toLowerCase();

    switch (extension) {
      case "pdf":
        return "application/pdf";
      case "docx":
        return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      case "txt":
        return "text/plain";
      default:
        return "application/octet-stream"; // Default to binary if the type is unknown
    }
  }

  const GetSectionByWorkAreaId = async (workAreaId) => {
    try {
      showLoader();
      const response = await APICall(GetSectionByWorkArea, "POST", {
        //PositionId: formData?.position_Id.value,
        WorkAreaId: workAreaId,
      });
      if (response?.status === 0) {
        console.log(response?.data, "Section");
        setSectionData(response?.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  function handleConsentChange(event) {
    setConsentGiven(event.target.checked);
  }

  async function handleSearchForCandidateIdDropdown(event, code, callback) {
    try {
      if (event?.length >= 1) {
        const data = await fetchCandidateDropdown(event, code);
        callback(data);
      } else {
        callback([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchCandidateDropdown(
    searchText = "",
    code = "",
    value = null
  ) {
    try {
      const response = await APICall(
        GetAssociateDropdownData,
        "POST",
        {
          SearchText: searchText,
          userId: userDetails.Id,
        },
        true
      );

      if (response?.status === 0) {
        if (response?.data) {
          return response?.data;
        } else {
          return [];
        }
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }
  function onTokenChange(event, code) {
    try {
      candidateProfile(event.id);
      setTokenData((prev) => {
        return { ...prev, [code]: event };
      });
    } catch (error) {
    } finally {
    }
  }
  return (
    <>
      <div className="form-main">
        <div className="page-title w-100">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <h4>
                  {" "}
                  <i
                    className="fa-solid fa-home pr-1"
                    style={{
                      color: "white",
                      cursor: "pointer",
                      fontSize: "13px",
                      verticalAlign: "middle",
                    }}
                    onClick={() => {
                      navigate("/Bluecollar");
                    }}
                  ></i>
                  Medical Screen
                </h4>
              </div>
              <div className="offset-lg-6 "></div>
              <div
                className="col-lg-2 d-flex align-items-center justify-content-end"
                style={{ width: "200px" }}
              >
                <SelectForm
                  isClearable
                  async
                  options={(searchString, cb) => {
                    handleSearchForCandidateIdDropdown(
                      searchString,
                      "candidateId",
                      cb
                    );
                  }}
                  placeholder={"Select candidate Id"}
                  onChange={(val: any) => {
                    if (val !== null) {
                      // Check if the selected value is not null
                      onTokenChange(val, "candidate_Id");
                      handleScanData1(val.id);
                    } else {
                      setTokenData((prev) => {
                        return { ...prev, ["candidate_Id"]: null };
                      });
                    }
                  }}
                  isMulti={false}
                  noIndicator={false}
                  noSeparator={false}
                  value={tokenData?.candidate_Id}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-2 " id="blue-collar-pages">
        <div className="row">
          <div className="col-md-4 d-flex justify-content-center align-items-start">
            <div className="p-3 text-center">
              {startScan ? (
                <QR startScanOps={startScan} onScan={handleScanData} />
              ) : (
                <img
                  src={QRCode || defaultProfileImgPic}
                  className="card-img-top"
                  alt="Thumbnail Image"
                />
              )}
              <div className="SectionSubmit clearfix mt-2">
                <button
                  onClick={() => {
                    setModalForGunScanner(true);
                  }}
                  disabled={false}
                  className="btn btn-primary mr-2"
                >
                  {<i className="fa-solid fa-qrcode mr-2"></i>}
                  QR-Scan gun
                </button>
                <button
                  onClick={() => {
                    //handleUpload();
                    setStartScan((prev) => !prev);
                  }}
                  disabled={false}
                  className="btn btn-primary ml-2 "
                >
                  <i className="fas fa-camera mr-2"></i> QR-Camera
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-3 d-flex justify-content-center align-items-start">
            <div className="p-3 text-center">
              <img
                src={candidateimage ? candidateimage : defaultProfileImgPic4}
                className="card-img-top"
                alt="Thumbnail Image"
              />
            </div>
          </div>

          <div className="col-md-5">
            <div className="row">
              <div className="col-md-6 mb-1">
                <label className="col-form-label">Token Id</label>
                <sup></sup>
                <InputForm
                  className="form-control"
                  placeholder="Token Id"
                  isDisabled={true}
                  onChange={""}
                  textArea={false}
                  value={scanData?.CandidateId || tokenId || ""}
                  //  value={"Mallesh Fakkirappa Dollin"}
                  maxLength="255"
                />
              </div>
              <div className="col-md-6 mb-1">
                <label className="col-form-label">Name</label>
                <sup></sup>
                <InputForm
                  className="form-control"
                  placeholder="Name"
                  isDisabled={true}
                  onChange={""}
                  textArea={false}
                  value={candidateDetails?.name}
                  maxLength="255"
                />
              </div>

              <div className="col-md-6 mb-1">
                <label className="col-form-label">Mobile Number</label>
                <sup></sup>
                <InputForm
                  className="form-control"
                  placeholder="Mobile Number"
                  isDisabled={true}
                  onChange={""}
                  textArea={false}
                  value={candidateDetails?.mobileNumber}
                  maxLength="255"
                />
              </div>

              <div className="col-md-6 mb-1">
                <label className="col-form-label">Category</label>
                <sup></sup>
                <InputForm
                  className="form-control"
                  placeholder="Category"
                  isDisabled={true}
                  onChange={""}
                  textArea={false}
                  value={scanData?.Category || categoryondrop || ""}
                  maxLength="255"
                />
              </div>

              <div className="col-md-6 mb-1">
                <label className="col-form-label">Skill Category</label>
                <sup></sup>
                <InputForm
                  className="form-control"
                  placeholder="Category"
                  isDisabled={true}
                  onChange={""}
                  textArea={false}
                  value={scanData?.SkillCategory || skillCategorydrop || ""}
                  maxLength="255"
                />
              </div>

              <div className="col-md-6 mb-1">
                <label className="col-form-label">Work Area</label>
                <sup></sup>
                <InputForm
                  className="form-control"
                  placeholder="Work Area"
                  isDisabled={true}
                  onChange={""}
                  textArea={false}
                  value={scanData?.WorkArea || ""}
                  maxLength="255"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12 mt-2">
          {/* {SectionData != null &&
            SectionData.filter((x) => x.section == "Organisation Information")
              .length > 0 && (
              <> */}
          <AccordianCustom1
            header={"Organization Information"}
            submitFunc={() => {}}
            //clearFunc={""}
            btnDisable={true}
          >
            <OrganizationInformation
              positionScanned={positionbutton}
              formData={formData}
              enableOUSection={false}
              onSubmitData={false}
              associateId={0}
              submitData={false}
              onGetPositionData={false}
            ></OrganizationInformation>

            {/* <div className="row">
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Position ID <span style={{ color: "red" }}>*</span>
                  </label>
                  <SelectForm
                    isClearable
                    async
                    options={(searchString, cb) => {
                      handleSearchForAsyncDropdown(
                        searchString,
                        "position_Id",
                        cb
                      );
                    }}
                    placeholder={"Select Position Id"}
                    onChange={(val: any) => {
                      onChange(val, "position_Id");
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                    value={formData?.position_Id}
                    isDisabled={positionDisable}
                  />
                </div>
              </div>

              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Position Text <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Position Text"}
                    isDisabled={true}
                    textArea={false}
                    value={positionText?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Business <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Business"}
                    isDisabled={true}
                    textArea={false}
                    value={business?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div>

              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    SBU <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"SBU"}
                    isDisabled={true}
                    textArea={false}
                    value={sbu?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Legal Entity <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Legal entity"}
                    isDisabled={true}
                    textArea={false}
                    value={LegalEntity?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Division <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Division"}
                    isDisabled={true}
                    textArea={false}
                    value={division?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Sub-Division <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Sub-Division"}
                    isDisabled={true}
                    textArea={false}
                    value={subdivision?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div>

              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Function <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Function"}
                    isDisabled={true}
                    textArea={false}
                    value={Function?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div>

              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Department <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Department"}
                    isDisabled={true}
                    textArea={false}
                    value={Department?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div>

              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Section <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Section"}
                    isDisabled={true}
                    textArea={false}
                    value={Section?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div>

              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Sub Section <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={" Sub Section"}
                    isDisabled={true}
                    textArea={false}
                    value={subSection?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div>

              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Emp class <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Emp Class"}
                    isDisabled={true}
                    textArea={false}
                    value={empClass?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div>

              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Job level <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Job Level"}
                    isDisabled={true}
                    textArea={false}
                    value={JobLevel?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div>

              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Job Band <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Job Band"}
                    isDisabled={true}
                    textArea={false}
                    value={jobBand?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div>

              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Designation <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Designation"}
                    isDisabled={true}
                    textArea={false}
                    value={designation?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div>

              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Job Code <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Job Code"}
                    isDisabled={true}
                    textArea={false}
                    value={jobCode?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div>

              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Job Title <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Job Title"}
                    isDisabled={true}
                    textArea={false}
                    value={skillCategory?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div>

              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Reporting Manager <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Reporting Manager"}
                    isDisabled={true}
                    textArea={false}
                    value={reportingManager?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div>

              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Skill Category <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Skill Category"}
                    isDisabled={true}
                    textArea={false}
                    value={scanData?.SkillCategory || ""}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div>

              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Work Area <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Work Area"}
                    isDisabled={true}
                    textArea={false}
                    value={scanData?.WorkArea || ""}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Payroll Location <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Payroll Location"}
                    isDisabled={true}
                    textArea={false}
                    value={payRoll || ""}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div>
              {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Leagal Entity <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Leagal entity"}
                    isDisabled={true}
                    textArea={false}
                    value={LegalEntity?.name}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div> }
            </div> */}
          </AccordianCustom1>
          {/* </>
            )} */}
          {SectionData != null &&
            SectionData.filter((x) => x.section == "General Examination")
              .length > 0 && (
              <>
                <AccordianCustom1
                  header={
                    <div
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <span style={{ marginRight: "8px" }}>
                        General Examination
                      </span>
                      <i
                        className="fas fa-check"
                        style={{
                          color: GenralExaminationSaved ? "green" : "orange",
                          fontSize: "24px",
                          position: "absolute",
                          top: 0,
                          right: -1030,
                        }}
                      ></i>
                    </div>
                  }
                  submitFunc={submitGeneralExamination}
                  //clearFunc={""}
                  btnDisable={isaccordiandisable ? true : false}
                >
                  <div className="row">
                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Pulse rate (BPM)<span style={{ color: "red" }}></span>
                        </label>
                        <div className="d-flex align-items-center">
                          <InputForm
                            className="form-control"
                            placeholder={"Pulse Rate"}
                            isDisabled={false}
                            textArea={false}
                            value={Pulserate}
                            onChange={(val: any) => {
                              let value = val.target.value;
                              setPulserate(value);
                              //onChange(value, "course_name");
                            }}
                          />
                          <span className="ml-2">BPM</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Blood Pressure (Systolic){" "}
                          <span style={{ color: "red" }}></span>
                        </label>
                        <div className="d-flex align-items-center">
                          <InputForm
                            className="form-control"
                            placeholder={"Systolic Blood Pressure"}
                            isDisabled={false}
                            textArea={false}
                            value={SystolicBloodPressure}
                            onChange={(val: any) => {
                              let value = val.target.value;
                              setSystolicBloodPressure(value);
                              //onChange(value, "course_name");
                            }}
                          />
                          <span className="ml-2">mmHg</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Blood Pressure (Diastolic){" "}
                          <span style={{ color: "red" }}></span>
                        </label>
                        <div className="d-flex align-items-center">
                          <InputForm
                            className="form-control"
                            placeholder={"Diastolic Blood Pressure"}
                            isDisabled={false}
                            textArea={false}
                            value={DiastolicBloodPressure}
                            onChange={(val: any) => {
                              let value = val.target.value;
                              setDiastolicBloodPressure(value);
                              //onChange(value, "course_name");
                            }}
                          />
                          <span className="ml-2">mmHg</span>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Blood Pressure <span style={{ color: "red" }}></span>
                        </label>
                        <div className="d-flex align-items-center">
                          <InputForm
                            className="form-control"
                            placeholder={"Blood Pressure"}
                            isDisabled={false}
                            textArea={false}
                            value={BloodPressure}
                            onChange={(val: any) => {
                              let value = val.target.value;
                              setBloodPressure(value);
                              //onChange(value, "course_name");
                            }}
                          />
                          <span className="ml-2">mmHg</span>
                        </div>
                      </div>
                    </div> */}

                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Height(cms) <span style={{ color: "red" }}></span>
                        </label>
                        <div className="d-flex align-items-center">
                          <InputForm
                            className="form-control"
                            placeholder={"Height"}
                            isDisabled={false}
                            textArea={false}
                            value={Height}
                            onChange={(val: any) => {
                              let value = val.target.value;
                              setHeight(value);
                              //onChange(value, "course_name");
                            }}
                          />
                          <span className="ml-2">Cm</span>
                        </div>
                        {formErrors.Height && (
                          <p style={{ color: "red" }}>{formErrors.Height}</p>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Weight(Kgs) <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="d-flex align-items-center">
                          <InputForm
                            className="form-control"
                            placeholder={"Weight"}
                            isDisabled={false}
                            textArea={false}
                            value={Weight}
                            onChange={(val: any) => {
                              let value = val.target.value;
                              setWeight(value);
                            }}
                          />
                          <span className="ml-2">Kg</span>
                        </div>
                        {formErrors.Weight && (
                          <p style={{ color: "red" }}>{formErrors.Weight}</p>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Blood Group <span style={{ color: "red" }}>*</span>
                        </label>
                        <SelectForm
                          isClearable
                          options={BloodGroupOptions}
                          placeholder={"Select blood group"}
                          isDisabled={false}
                          onChange={(e) => {
                            setBloodGroup(e);
                            //setJobRole(e)
                            // if(e){
                            // getAssociateDetails(e.value)
                            // }
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={BloodGroup}
                        />
                        {formErrors.BloodGroup && (
                          <p style={{ color: "red" }}>
                            {formErrors.BloodGroup}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Vision<span style={{ color: "red" }}></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label"></label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Specs<span style={{ color: "red" }}></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          RT Eye<span style={{ color: "red" }}></span>
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          LT Eye<span style={{ color: "red" }}></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mt-3">
                        <label className="col-form-label">
                          Near vision
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        {/* <SelectForm
                          isClearable
                          options={NearVisionSpecOption}
                          placeholder={"Select Near Vision"}
                          isDisabled={false}
                          onChange={(e) => {
                            setRightNearVisionSpec(e);
                            //setJobRole(e)
                            // if(e){
                            // getAssociateDetails(e.value)
                            // }
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={RightNearVisionSpec}
                        /> */}
                        {formErrors.NearvisionSpecRight && (
                          <p style={{ color: "red" }}>
                            {formErrors.NearvisionSpecRight}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          {/* Near vision without Spec(acceptable N/6) */}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </label>
                        <SelectForm
                          isClearable
                          options={SpecsOptions}
                          placeholder={"Select Near Spec"}
                          isDisabled={false}
                          onChange={(e) => {
                            setSpecsValue(e);
                            setIsVisionfieldisable(false);
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={SpecsValue}
                        />
                        {formErrors.NearvisionWithoutSpecRight && (
                          <p style={{ color: "red" }}>
                            {formErrors.NearvisionWithoutSpecRight}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          {/* Distant vision with Spec(acceptable 6/9) */}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </label>
                        <SelectForm
                          isClearable
                          options={NearVisionSpecOption}
                          placeholder={"Select Right Eye  Near Vision"}
                          isDisabled={isVisionfieldisable}
                          onChange={(e) => {
                            setRightNearVision(e);
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={RightNearVision}
                        />
                        {formErrors.DistantvisionwithspecRight && (
                          <p style={{ color: "red" }}>
                            {formErrors.DistantvisionwithspecRight}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          {/* Distant vision without Spec(accept 6/9) */}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </label>
                        <SelectForm
                          isClearable
                          options={NearVisionSpecOption}
                          placeholder={"Select Left Eye Near Vision"}
                          isDisabled={isVisionfieldisable}
                          onChange={(e) => {
                            setLeftNearVision(e);
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={LeftNearVision}
                        />
                        {formErrors.DistantvisionwithoutspecRight && (
                          <p style={{ color: "red" }}>
                            {formErrors.DistantvisionwithoutspecRight}
                          </p>
                        )}
                      </div>
                    </div>

                    {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Color Vision<span style={{ color: "red" }}>*</span>
                        </label>
                        <SelectForm
                          isClearable
                          options={ColorVisionOption}
                          placeholder={"Select Color Vision"}
                          isDisabled={false}
                          onChange={(e) => {
                            //setJobRole(e)
                            setRightColorVision(e);
                            // if(e){
                            // getAssociateDetails(e.value)
                            // }
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={RightColorVision}
                        />
                        {formErrors.ColorVisionRight && (
                          <p style={{ color: "red" }}>
                            {formErrors.ColorVisionRight}
                          </p>
                        )}
                      </div>
                    </div> */}
                  </div>

                  {/* <div className="row">
                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Left<span style={{ color: "red" }}></span>
                        </label>
                      </div>
                    </div>
                  </div> */}

                  <div className="row">
                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mt-3">
                        <label className="col-form-label">
                          {/* Near vision Spec(acceptable N/6) */}
                          Distant Vision
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        {/* <SelectForm
                          isClearable
                          options={NearVisionSpecOption}
                          placeholder={"Select Near Vision"}
                          isDisabled={false}
                          onChange={(e) => {
                            setLeftNearVisionSpec(e);
                            //setJobRole(e)
                            // if(e){
                            // getAssociateDetails(e.value)
                            // }
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={LeftNearVisionSpec}
                        /> */}
                        {formErrors.NearvisionSpecLeft && (
                          <p style={{ color: "red" }}>
                            {formErrors.NearvisionSpecLeft}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          {/* Near vision without Spec(acceptable N/6)
                          <span style={{ color: "red" }}>*</span> */}
                        </label>
                        <SelectForm
                          isClearable
                          options={DistantSpecsOptions}
                          placeholder={"Select Distant Spec"}
                          isDisabled={false}
                          onChange={(e) => {
                            setDistantSpecsValue(e);
                            setIsDistictfieldisable(false);
                            //setLeftNearVisionWSpec(e);
                            //setJobRole(e)
                            // if(e){
                            // getAssociateDetails(e.value)
                            // }
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={DistantSpecsValue}
                        />
                        {formErrors.NearvisionWithoutSpecLeft && (
                          <p style={{ color: "red" }}>
                            {formErrors.NearvisionWithoutSpecLeft}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          {/* Distant vision with Spec(acceptable 6/9)
                          <span style={{ color: "red" }}>*</span> */}
                        </label>
                        <SelectForm
                          isClearable
                          options={DistantVisionSpecOption}
                          placeholder={"Select Right Eye Distinct Vision"}
                          isDisabled={isDistictfieldisable}
                          onChange={(e) => {
                            setRightDistantVision(e);
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={RightDistantVision}
                        />
                        {formErrors.DistantvisionwithspecLeft && (
                          <p style={{ color: "red" }}>
                            {formErrors.DistantvisionwithspecLeft}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          {/* Distant vision without Spec(accept 6/9)
                          <span style={{ color: "red" }}>*</span> */}
                        </label>
                        <SelectForm
                          isClearable
                          options={DistantVisionWSpecOption}
                          placeholder={"Select Left Eye Distant Vision"}
                          isDisabled={isDistictfieldisable}
                          onChange={(e) => {
                            setLeftDistantVision(e);
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={LeftDistantVision}
                        />
                        {formErrors.DistantvisionwithoutspecLeft && (
                          <p style={{ color: "red" }}>
                            {formErrors.DistantvisionwithoutspecLeft}
                          </p>
                        )}
                      </div>
                    </div>

                    {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Color Vision<span style={{ color: "red" }}>*</span>
                        </label>
                        <SelectForm
                          isClearable
                          options={ColorVisionOption}
                          placeholder={"Select Color Vision"}
                          isDisabled={false}
                          onChange={(e) => {
                            setLeftColorVision(e);
                            //setJobRole(e)
                            // if(e){
                            // getAssociateDetails(e.value)
                            // }
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={LeftColorVision}
                        />
                        {formErrors.ColorVisionLeft && (
                          <p style={{ color: "red" }}>
                            {formErrors.ColorVisionLeft}
                          </p>
                        )}
                      </div>
                    </div> */}
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Right Eye Color Vision
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <SelectForm
                          isClearable
                          options={ColorVisionOption}
                          placeholder={"Select Color Vision"}
                          isDisabled={false}
                          onChange={(e) => {
                            //setJobRole(e)
                            setRightColorVision(e);
                            // if(e){
                            // getAssociateDetails(e.value)
                            // }
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={RightColorVision}
                        />
                        {formErrors.ColorVisionRight && (
                          <p style={{ color: "red" }}>
                            {formErrors.ColorVisionRight}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Left Eye Color Vision
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <SelectForm
                          isClearable
                          options={ColorVisionOption}
                          placeholder={"Select Color Vision"}
                          isDisabled={false}
                          onChange={(e) => {
                            setLeftColorVision(e);
                            //setJobRole(e)
                            // if(e){
                            // getAssociateDetails(e.value)
                            // }
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={LeftColorVision}
                        />
                        {formErrors.ColorVisionLeft && (
                          <p style={{ color: "red" }}>
                            {formErrors.ColorVisionLeft}
                          </p>
                        )}
                      </div>
                    </div>

                    {/* <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Color Vision<span style={{ color: "red" }}>*</span>
                        </label>
                        <SelectForm
                          isClearable
                          options={ColorVisionOption}
                          placeholder={"Select Color Vision"}
                          isDisabled={false}
                          onChange={(e) => {
                            setLeftColorVision(e);
                            //setJobRole(e)
                            // if(e){
                            // getAssociateDetails(e.value)
                            // }
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={LeftColorVision}
                        />
                        {formErrors.ColorVisionLeft && (
                          <p style={{ color: "red" }}>
                            {formErrors.ColorVisionLeft}
                          </p>
                        )}
                      </div>
                    </div> */}
                  </div>
                </AccordianCustom1>
              </>
            )}
          {SectionData != null &&
            SectionData.filter(
              (x) => x.section == "Audiometry & Spirometry Examination"
            ).length > 0 && (
              <>
                <AccordianCustom1
                  // header={"Audiometry & Spirometry Examination"}
                  header={
                    <div
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <span style={{ marginRight: "8px" }}>
                        Audiometry & Spirometry Examination
                      </span>
                      <i
                        className="fas fa-check"
                        style={{
                          color: AudiometrySaved ? "green" : "orange",
                          fontSize: "24px",
                          position: "absolute",
                          top: 0,
                          right: -900,
                        }}
                      ></i>
                    </div>
                  }
                  submitFunc={submitAudiometrySpirometry}
                  //clearFunc={""}
                  //btnDisable={!Audiometry || !Spirometry ? true : false}
                  btnDisable={isaccordiandisable}
                >
                  <div className="row">
                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Audiometry <span style={{ color: "red" }}></span>
                        </label>
                        <SelectForm
                          isClearable
                          options={AudiometryOption}
                          placeholder={"Select Audiometry"}
                          isDisabled={false}
                          onChange={(e) => {
                            setAudiometry(e);
                            //setJobRole(e)
                            // if(e){
                            // getAssociateDetails(e.value)
                            // }
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={Audiometry}
                        />
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Spirometry <span style={{ color: "red" }}></span>
                        </label>
                        <SelectForm
                          isClearable
                          options={SpirometryOption}
                          placeholder={"Select Spirometry"}
                          isDisabled={false}
                          onChange={(e) => {
                            setSpirometry(e);
                            //setJobRole(e)
                            // if(e){
                            // getAssociateDetails(e.value)
                            // }
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={Spirometry}
                        />
                      </div>
                    </div>
                  </div>
                </AccordianCustom1>
              </>
            )}
          {/* {SectionData != null &&
            SectionData.filter((x) => x.section == "Candidate Declaration")
              .length > 0 && (
              <>
                <AccordianCustom1
                  header={"Candidate Declaration"}
                  submitFunc={submitCadidateDeclaration}
                  //clearFunc={""}
                  btnDisable={isaccordiandisable ? true : false}
                >
                  <div className="row">
                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Asthma or shortness of breath{" "}
                          <span style={{ color: "red" }}></span>
                        </label>
                        <SelectForm
                          isClearable
                          options={CandidateOption}
                          placeholder={"Select Asthma"}
                          isDisabled={false}
                          onChange={(e) => {
                            setAsthma(e);
                            //setJobRole(e)
                            // if(e){
                            // getAssociateDetails(e.value)
                            // }
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={Asthma}
                        />
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          High / low blood pressure{" "}
                          <span style={{ color: "red" }}></span>
                        </label>
                        <SelectForm
                          isClearable
                          options={CandidateOption}
                          placeholder={"Select blood presure"}
                          isDisabled={false}
                          onChange={(e) => {
                            setBPType(e);
                            //setJobRole(e)
                            // if(e){
                            // getAssociateDetails(e.value)
                            // }
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={BPType}
                        />
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Any hearing disability{" "}
                          <span style={{ color: "red" }}></span>
                        </label>
                        <SelectForm
                          isClearable
                          options={CandidateOption}
                          placeholder={"Select Any hearing disability"}
                          isDisabled={false}
                          onChange={(e) => {
                            setHearingDisability(e);
                            //setJobRole(e)
                            // if(e){
                            // getAssociateDetails(e.value)
                            // }
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={HearingDisability}
                        />
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Diabetes(insulin dependent){" "}
                          <span style={{ color: "red" }}></span>
                        </label>
                        <SelectForm
                          isClearable
                          options={CandidateOption}
                          placeholder={"Select Any diabetes"}
                          isDisabled={false}
                          onChange={(e) => {
                            setDiabetes(e);
                            //setJobRole(e)
                            // if(e){
                            // getAssociateDetails(e.value)
                            // }
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={Diabetes}
                        />
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Hernia <span style={{ color: "red" }}></span>
                        </label>
                        <SelectForm
                          isClearable
                          options={CandidateOption}
                          placeholder={"Select hernia"}
                          isDisabled={false}
                          onChange={(e) => {
                            setHernia(e);
                            //setJobRole(e)
                            // if(e){
                            // getAssociateDetails(e.value)
                            // }
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={Hernia}
                        />
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Heart related Problem{" "}
                          <span style={{ color: "red" }}></span>
                        </label>
                        <SelectForm
                          isClearable
                          options={CandidateOption}
                          placeholder={"Select Heart related Problem"}
                          isDisabled={false}
                          onChange={(e) => {
                            setHeartProblem(e);
                            //setJobRole(e)
                            // if(e){
                            // getAssociateDetails(e.value)
                            // }
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={HeartProblem}
                        />
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Nervous disorder{" "}
                          <span style={{ color: "red" }}></span>
                        </label>
                        <SelectForm
                          isClearable
                          options={CandidateOption}
                          placeholder={"Select any nervous disorder"}
                          isDisabled={false}
                          onChange={(e) => {
                            setNervousDisorder(e);
                            //setJobRole(e)
                            // if(e){
                            // getAssociateDetails(e.value)
                            // }
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={NervousDisorder}
                        />
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Back or disc related problem{" "}
                          <span style={{ color: "red" }}></span>
                        </label>
                        <SelectForm
                          isClearable
                          options={CandidateOption}
                          placeholder={
                            "Select Any Back or disc related problem"
                          }
                          isDisabled={false}
                          onChange={(e) => {
                            setBackProblem(e);
                            //setJobRole(e)
                            // if(e){
                            // getAssociateDetails(e.value)
                            // }
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={BackProblem}
                        />
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          visual Problem <span style={{ color: "red" }}></span>
                        </label>
                        <SelectForm
                          isClearable
                          options={CandidateOption}
                          placeholder={"Select Any visual problem"}
                          isDisabled={false}
                          onChange={(e) => {
                            setVisualProblem(e);
                            //setJobRole(e)
                            // if(e){
                            // getAssociateDetails(e.value)
                            // }
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={VisualProblem}
                        />
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Joint problems <span style={{ color: "red" }}></span>
                        </label>
                        <SelectForm
                          isClearable
                          options={CandidateOption}
                          placeholder={"Select joint problem"}
                          isDisabled={false}
                          onChange={(e) => {
                            setJointProblem(e);
                            //setJobRole(e)
                            // if(e){
                            // getAssociateDetails(e.value)
                            // }
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={JointProblem}
                        />
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Epilepsy or blackout{" "}
                          <span style={{ color: "red" }}></span>
                        </label>
                        <SelectForm
                          isClearable
                          options={CandidateOption}
                          placeholder={"Select Epilepsy or blackout"}
                          isDisabled={false}
                          onChange={(e) => {
                            setEpilepsy(e);
                            //setJobRole(e)
                            // if(e){
                            // getAssociateDetails(e.value)
                            // }
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={Epilepsy}
                        />
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Stomach disorder{" "}
                          <span style={{ color: "red" }}></span>
                        </label>
                        <SelectForm
                          isClearable
                          options={CandidateOption}
                          placeholder={"Select stomach disorder"}
                          isDisabled={false}
                          onChange={(e) => {
                            setStomachProblem(e);
                            //setJobRole(e)
                            // if(e){
                            // getAssociateDetails(e.value)
                            // }
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={StomachProblem}
                        />
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Liver disorder <span style={{ color: "red" }}></span>
                        </label>
                        <SelectForm
                          isClearable
                          options={CandidateOption}
                          placeholder={"Select Liver disorder"}
                          isDisabled={false}
                          onChange={(e) => {
                            setLiverProblem(e);
                            //setJobRole(e)
                            // if(e){
                            // getAssociateDetails(e.value)
                            // }
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={LiverProblem}
                        />
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Anaemia <span style={{ color: "red" }}></span>
                        </label>
                        <SelectForm
                          isClearable
                          options={CandidateOption}
                          placeholder={"Select Anaemia"}
                          isDisabled={false}
                          onChange={(e) => {
                            setAnaemia(e);
                            //setJobRole(e)
                            // if(e){
                            // getAssociateDetails(e.value)
                            // }
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={Anaemia}
                        />
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Phobia <span style={{ color: "red" }}></span>
                        </label>
                        <SelectForm
                          isClearable
                          options={CandidateOption}
                          placeholder={"Select Phobia"}
                          isDisabled={false}
                          onChange={(e) => {
                            setPhobia(e);
                            //setJobRole(e)
                            // if(e){
                            // getAssociateDetails(e.value)
                            // }
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={Phobia}
                        />
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Drugs / alcohol <span style={{ color: "red" }}></span>
                        </label>
                        <SelectForm
                          isClearable
                          options={CandidateOption}
                          placeholder={"Select Drugs or alcohol"}
                          isDisabled={false}
                          onChange={(e) => {
                            setDrugsAlcohol(e);
                            //setJobRole(e)
                            // if(e){
                            // getAssociateDetails(e.value)
                            // }
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={DrugsAlcohol}
                        />
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Allergies (please Specify){" "}
                          <span style={{ color: "red" }}></span>
                        </label>
                        <SelectForm
                          isClearable
                          options={CandidateOption}
                          placeholder={"Select Phobia"}
                          isDisabled={false}
                          onChange={(e) => {
                            setAllergies(e);
                            //setJobRole(e)
                            // if(e){
                            // getAssociateDetails(e.value)
                            // }
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={Allergies}
                        />
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Mobility problems{" "}
                          <span style={{ color: "red" }}></span>
                        </label>
                        <SelectForm
                          isClearable
                          options={CandidateOption}
                          placeholder={"Mobility problems"}
                          isDisabled={false}
                          onChange={(e) => {
                            setMobilityProblem(e);
                            //setJobRole(e)
                            // if(e){
                            // getAssociateDetails(e.value)
                            // }
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={MobilityProblem}
                        />
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          White finger <span style={{ color: "red" }}></span>
                        </label>
                        <SelectForm
                          isClearable
                          options={CandidateOption}
                          placeholder={"White finger"}
                          isDisabled={false}
                          onChange={(e) => {
                            setWhiteFinger(e);
                            //setJobRole(e)
                            // if(e){
                            // getAssociateDetails(e.value)
                            // }
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={WhiteFinger}
                        />
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-xs-4">
                      <div className="mb-1">
                        <label className="col-form-label">
                          Disability/Handicap of any kind{" "}
                          <span style={{ color: "red" }}></span>
                        </label>
                        <SelectForm
                          isClearable
                          options={CandidateOption}
                          placeholder={"Disability/Handup of any kind"}
                          isDisabled={false}
                          onChange={(e) => {
                            setDisability(e);
                            //setJobRole(e)
                            // if(e){
                            // getAssociateDetails(e.value)
                            // }
                          }}
                          isMulti={false}
                          noIndicator={false}
                          noSeparator={false}
                          value={Disability}
                        />
                      </div>
                    </div>
                  </div>
                </AccordianCustom1>
              </>
            )} */}
          {SectionData != null &&
            SectionData.filter((x) => x.section == "Pathology Examination")
              .length > 0 && (
              <>
                <AccordianCustom1
                  header={
                    <div
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <span style={{ marginRight: "8px" }}>
                        Pathology Examination
                      </span>
                      <i
                        className="fas fa-check"
                        style={{
                          color: PathologySaved ? "green" : "orange",
                          fontSize: "24px",
                          position: "absolute",
                          top: 0,
                          right: -1010,
                        }}
                      ></i>
                    </div>
                  }
                  submitFunc={submitPathologicalTest}
                  //clearFunc={""}
                  btnDisable={isaccordiandisable ? true : false}
                >
                  <>
                    <div className="row">
                      <div className="col-lg-3 col-sm-3 col-xs-4">
                        <div className="mb-1">
                          <label className="col-form-label">
                            Pathological test{" "}
                            <span style={{ color: "red" }}></span>
                          </label>
                          <SelectForm
                            isClearable
                            options={PathologicalOption}
                            placeholder={"Select tests"}
                            isDisabled={false}
                            onChange={(e) => {
                              setPathologicalTest(e);
                              //setJobRole(e)
                              // if(e){
                              // getAssociateDetails(e.value)
                              // }
                            }}
                            isMulti={true}
                            noIndicator={false}
                            noSeparator={false}
                            value={PathologicalTest}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                </AccordianCustom1>
              </>
            )}
          {SectionData != null &&
            SectionData.filter((x) => x.section == "Clinical Examination")
              .length > 0 && (
              <>
                <AccordianCustom1
                  // header={"Clinical Examination"}
                  header={
                    <div
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <span style={{ marginRight: "8px" }}>
                        Clinical Examination
                      </span>
                      <i
                        className="fas fa-check"
                        style={{
                          color: ClinicalExaminationSaved ? "green" : "orange",
                          fontSize: "24px",
                          position: "absolute",
                          top: 0,
                          right: -1030,
                        }}
                      ></i>
                    </div>
                  }
                  submitFunc={submitClinicalExamination}
                  //clearFunc={""}
                  btnDisable={isaccordiandisable ? true : false}
                >
                  <>
                    <div className="row">
                      <div className="col-lg-3 col-sm-3 col-xs-4">
                        <div className="mb-1">
                          <label className="col-form-label">
                            Mouth Disorders +ENT
                            <span style={{ color: "red" }}></span>
                          </label>
                          <SelectForm
                            isClearable
                            options={ClinicalOption}
                            placeholder={"Select mouth disorder"}
                            isDisabled={false}
                            onChange={(e) => {
                              setMouthDisorder(e);
                              //setJobRole(e)
                              // if(e){
                              // getAssociateDetails(e.value)
                              // }
                            }}
                            isMulti={false}
                            noIndicator={false}
                            noSeparator={false}
                            value={MouthDisorder}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-3 col-xs-4">
                        <div className="mb-1">
                          <label className="col-form-label">
                            Respiratory System{" "}
                            <span style={{ color: "red" }}></span>
                          </label>
                          <SelectForm
                            isClearable
                            options={ClinicalOption}
                            placeholder={"Select respiratory system"}
                            isDisabled={false}
                            onChange={(e) => {
                              setRespiratorySystem(e);
                              //setJobRole(e)
                              // if(e){
                              // getAssociateDetails(e.value)
                              // }
                            }}
                            isMulti={false}
                            noIndicator={false}
                            noSeparator={false}
                            value={RespiratorySystem}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-3 col-xs-4">
                        <div className="mb-1">
                          <label className="col-form-label">
                            Cardio-Vascular System{" "}
                            <span style={{ color: "red" }}></span>
                          </label>
                          <SelectForm
                            isClearable
                            options={ClinicalOption}
                            placeholder={"Select cardio-vascular system"}
                            isDisabled={false}
                            onChange={(e) => {
                              setCardio(e);
                              //setJobRole(e)
                              // if(e){
                              // getAssociateDetails(e.value)
                              // }
                            }}
                            isMulti={false}
                            noIndicator={false}
                            noSeparator={false}
                            value={Cardio}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-3 col-xs-4">
                        <div className="mb-1">
                          <label className="col-form-label">
                            G.I.T. / Abdomen{" "}
                            <span style={{ color: "red" }}></span>
                          </label>
                          <SelectForm
                            isClearable
                            options={ClinicalOption}
                            placeholder={"Select G.I.T./ Abdomen problem"}
                            isDisabled={false}
                            onChange={(e) => {
                              setAbdomen(e);
                              //setJobRole(e)
                              // if(e){
                              // getAssociateDetails(e.value)
                              // }
                            }}
                            isMulti={false}
                            noIndicator={false}
                            noSeparator={false}
                            value={Abdomen}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-3 col-xs-4">
                        <div className="mb-1">
                          <label className="col-form-label">
                            C.N.S. <span style={{ color: "red" }}></span>
                          </label>
                          <SelectForm
                            isClearable
                            options={ClinicalOption}
                            placeholder={"Select C.N.S."}
                            isDisabled={false}
                            onChange={(e) => {
                              setCNS(e);
                              //setJobRole(e)
                              // if(e){
                              // getAssociateDetails(e.value)
                              // }
                            }}
                            isMulti={false}
                            noIndicator={false}
                            noSeparator={false}
                            value={CNS}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-6 col-xs-4">
                        <div className="mb-1">
                          <label className="col-form-label">
                            Identification Mark{" "}
                            <span style={{ color: "red" }}></span>
                          </label>
                          <InputForm
                            className="form-control"
                            placeholder={"Identification Remark"}
                            isDisabled={false}
                            textArea={true}
                            value={IdentificationMark}
                            onChange={(val: any) => {
                              let value = val.target.value;
                              setIdentificationMark(value);
                              //onChange(value, "course_name");
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-6 col-xs-4">
                        <div className="mb-1">
                          <label className="col-form-label">
                            Additional Observation{" "}
                            <span style={{ color: "red" }}></span>
                          </label>
                          <InputForm
                            className="form-control"
                            placeholder={"Remark"}
                            isDisabled={false}
                            textArea={true}
                            value={Remark}
                            onChange={(val: any) => {
                              let value = val.target.value;
                              setRemark(value);
                              //onChange(value, "course_name");
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                </AccordianCustom1>
              </>
            )}
          {/* {SectionData != null &&
            SectionData.filter((x) => x.section == "Additional Remark").length >
              0 && (
              <> */}
          {/* <AccordianCustom1
            header={"Additional Observation"}
            submitFunc={submitAdditionalremark}
            //clearFunc={""}
            btnDisable={isaccordiandisable ? true : false}
          >
            <div className="row">
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Remark <span style={{ color: "red" }}></span>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Remark"}
                    isDisabled={false}
                    textArea={true}
                    value={Remark}
                    onChange={(val: any) => {
                      let value = val.target.value;
                      setRemark(value);
                      //onChange(value, "course_name");
                    }}
                  />
                </div>
              </div>
            </div>
          </AccordianCustom1>  */}
          {/* </>
            )} */}
          {/* {SectionData != null &&
            SectionData.filter((x) => x.section == "Upload Signature").length >
              0 && (
              <> */}
          {/* <AccordianCustom1
            header={"Pre-Employment Form"}
            submitFunc={""}
            //clearFunc={""}
            btnDisable={isDisabled}
            hideBtn={true}
          >
            <div className={"col-lg-12 col-sm-12 col-xs-12"}>
              <div className="row ml-1">
                <button
                  className="docLink btn btn-filter-submit mr-2"
                  onClick={handleDocumentClick}
                >
                  View Pre-Employment Medical Form & Candidate Declaration
                  <i
                    className="fa fa-info-circle mt-2 ml-2"
                    aria-hidden="true"
                  ></i>
                </button>
              </div>

              {showWaitText && <p>Wait till the document is generated...</p>}

              <div className="ml-1">
                <input
                  type="checkbox"
                  id="consentCheckbox"
                  disabled={!documentGenerated}
                  checked={consentGiven}
                  onChange={handleConsentChange}
                  className="mt-1"
                />
                <label htmlFor="consentCheckbox" className="ml-2">
                  I hereby consent to undergo the necessary medical examinations
                  and procedures required for the pre-employment medical
                  assessment.
                </label>
              </div>
            </div>
          </AccordianCustom1> */}
          <AccordianCustom1
            header={"Upload Doctor/Candidate Signature"}
            submitFunc={""}
            //clearFunc={""}
            btnDisable={isaccordiandisable ? true : false}
            hideBtn={true}
          >
            <div className="row">
              <div className="col-md-4 d-flex justify-content-center align-items-start">
                <div className="text-center">
                  <div className="mb-2">
                    <label className="col-form-label">
                      Doctor's Signature <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <img
                    src={
                      signatureimage ? signatureimage : defaultProfileImgPic1
                    }
                    className="card-img-top"
                    alt="Thumbnail Image"
                  />

                  <div className="SectionSubmit clearfix mt-3">
                    <button
                      onClick={() => {
                        setShowModal(true);
                      }}
                      disabled={false}
                      className="btn btn-primary
                  "
                    >
                      <i className="fa-solid fa-camera mr-2"></i> Capture
                    </button>

                    <button
                      onClick={() => {
                        // handleUpload1();
                        uploadDoctorSignature();
                      }}
                      disabled={isDisabled}
                      className="btn btn-filter-submit ml-2"
                    >
                      <i className="fas fa-save mr-2"></i> Save
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-md-4 d-flex justify-content-center align-items-start">
                <div className="text-center">
                  <div className="mb-2">
                    <label className="col-form-label">
                      Candidate's Signature{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <img
                    src={
                      signatuecandidate
                        ? signatuecandidate
                        : defaultProfileImgPic2
                    }
                    className="card-img-top"
                    alt="Thumbnail Image"
                  />
                  <div className="SectionSubmit clearfix mt-3">
                    <button
                      onClick={() => {
                        // onResetData();
                        setShowcandidatemodel(true);
                      }}
                      disabled={false}
                      className="btn btn-primary
                    "
                    >
                      <i className="fa-solid fa-camera mr-2"></i> Capture
                    </button>

                    <button
                      onClick={() => {
                        // handleUpload1();
                        uploadDoctorSignaturecandidate();
                      }}
                      disabled={isDisabled}
                      className="btn btn-filter-submit ml-2"
                    >
                      <i className="fas fa-save mr-2"></i> Save
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-md-4 d-flex justify-content-center align-items-start">
                <div className="text-center">
                  <div className="mb-2 mt-2">
                    <label className="col-form-label">
                      <span style={{ color: "red" }}></span>
                    </label>
                  </div>
                  <img
                    src={thumbimage ? thumbimage : defaultProfileImgPic3}
                    className="card-img-top"
                    alt="Thumbnail Image"
                  />
                  <div className="SectionSubmit clearfix mt-3">
                    <label className="col-form-label">
                      <i className="fas fa-fingerprint"></i> Candidate's Right
                      Thumb
                    </label>

                    {/* <button
                      onClick={() => {
                        // handleUpload1();
                      }}
                      disabled={false}
                      className="btn btn-filter-submit ml-2"
                    >
                      <i className="fas fa-save mr-2"></i> Save
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </AccordianCustom1>
          {/* </>
            )} */}
          {/* {SectionData != null &&
            SectionData.filter((x) => x.section == "Decision").length > 0 && (
              <> */}
          <AccordianCustom1
            header={"Pre-Employment Form"}
            submitFunc={""}
            //clearFunc={""}
            btnDisable={isDisabled}
            hideBtn={true}
          >
            <div className={"col-lg-12 col-sm-12 col-xs-12"}>
              <div className="row ml-1">
                <button
                  className="docLink btn btn-filter-submit mr-2"
                  onClick={handleDocumentClick}
                >
                  View Pre-Employment Medical Form & Candidate Declaration
                  <i
                    className="fa fa-info-circle mt-2 ml-2"
                    aria-hidden="true"
                  ></i>
                </button>
              </div>

              {showWaitText && <p>Wait till the document is generated...</p>}

              <div className="ml-1">
                <input
                  type="checkbox"
                  id="consentCheckbox"
                  disabled={!documentGenerated}
                  checked={consentGiven}
                  onChange={handleConsentChange}
                  className="mt-1"
                />
                <label htmlFor="consentCheckbox" className="ml-2">
                  I hereby consent to undergo the necessary medical examinations
                  and procedures required for the pre-employment medical
                  assessment.
                </label>
              </div>
            </div>
          </AccordianCustom1>

          <AccordianCustom1
            header={"Decision"}
            submitFunc={submitDecision}
            //clearFunc={""}
            btnDisable={isDisabled}
            hideBtn={true}
          >
            <div className="row">
              <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                <label className="col-form-label">Decision</label>
                <sup>*</sup>
                <SelectForm
                  isClearable
                  options={DecisionOption}
                  placeholder={"Select Decision"}
                  isDisabled={isDisabled}
                  onChange={(e) => {
                    setDecision(e);
                    setRejection(null);
                  }}
                  isMulti={false}
                  noIndicator={false}
                  noSeparator={false}
                  value={decision}
                />
                {formErrors.decision && (
                  <p style={{ color: "red" }}>{formErrors.decision}</p>
                )}
              </div>

              <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                <label className="col-form-label">Reason</label>
                <sup>*</sup>
                <SelectForm
                  isClearable
                  options={RejectionReasonOption}
                  placeholder={"Select Reason "}
                  isDisabled={fielddisable}
                  onChange={(e) => {
                    setRejection(e);
                    // setRejection(e);
                  }}
                  isMulti={false}
                  noIndicator={false}
                  noSeparator={false}
                  value={Rejection}
                />
                {formErrors.Rejection && (
                  <p style={{ color: "red" }}>{formErrors.Rejection}</p>
                )}
              </div>

              <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                <label className="col-form-label">Remark</label>

                <InputForm
                  className="form-control"
                  placeholder="Enter Remark"
                  isDisabled={isDisabled}
                  onChange={(e) => {
                    setDecisionRemark(e.target.value);
                  }}
                  textArea={true}
                  value={DecisionRemark}
                  maxLength="255"
                />
              </div>

              {/* <div className={"col-lg-4 col-sm-4 col-xs-4"}>
                <a className="docLink" href="#" onClick={handleDocumentClick}>
                  View Pre-Employment Medical Form & Candidate Declaration
                </a>
              </div> */}
            </div>
            {/* <div className="SectionSubmit mb-4 clearfix">
                <button
                  onClick={() => {
                   // onResetData();
                  }}
                  disabled={false}
                  className="btn btn-filter-submit float-right ml-2 mt-5
                  "
                >
                  <i className=""></i> Submit
                </button>

                <button
                  onClick={() => {
                   // handleUpload1();
                  }}
                  disabled={false}
                  className="btn btn-primary float-right ml-2 mt-5"
                >
                  <i className=""></i> Cancel
                </button>
              </div> */}

            <div className="SectionSubmit clearfix">
              <button
                onClick={submitDecision}
                disabled={isDisabled}
                className="btn btn-filter-submit float-right mt-2"
              >
                <i className="fa-solid fa-check mr-2"></i> Proceed
              </button>
            </div>
          </AccordianCustom1>

          {/* </>
            )} */}
        </div>
        <Modal
          show={showModal}
          onHide={handleModalClose}
          centered
          id="captureDoctorSignatureModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Capture Doctor Signature</Modal.Title>
            <button type="button" className="btn close " aria-label="Close">
              x
            </button>
          </Modal.Header>
          <Modal.Body>
            <SignatureCanvas
              ref={sigCanvas}
              canvasProps={{
                className: "signature-canvas",
                width: 200,
                height: 200,
              }}
            />
            <div className="text-center mt-2">
              <Button variant="primary" onClick={handleClearSignature}>
                <i className="fa-solid fa-signature mr-2"></i> Clear Signature
              </Button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
              <i className="fa fa-close mr-2"></i>Cancel
            </Button>
            <Button variant="primary" onClick={handleCaptureConfirm}>
              <i className="fa fa-check mr-2"></i>Confirm
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showcurrentstage}
          onHide={() => {
            setShowCurrentStage(false);
          }}
          backdrop="static"
          keyboard={false}
          size="sm"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Current Stage</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>{`You currently belong to ${currentStageName}`}</h4>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        <Modal
          show={showcandidatemodel}
          onHide={handleModalClose}
          centered
          id="captureCandidateSignatureModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Capture Candidate Signature</Modal.Title>
            <button type="button" className="btn close " aria-label="Close">
              x
            </button>
          </Modal.Header>
          <Modal.Body>
            <SignatureCanvas
              ref={sigCanvas1}
              canvasProps={{
                className: "signature-canvas",
                width: 200,
                height: 200,
              }}
            />
            <div className="text-center mt-2">
              <Button variant="primary" onClick={handleClearSignatureCandidate}>
                <i className="fa-solid fa-signature mr-2"></i> Clear Signature
              </Button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalCLoseCandidate}>
              <i className="fa fa-close mr-2"></i>Cancel
            </Button>
            <Button variant="primary" onClick={handleCaptureCandidate}>
              <i className="fa fa-check mr-2"></i>Confirm
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={modalForGunScanner}
          onHide={() => {
            setModalForGunScanner(false);
          }}
          backdrop="static"
          keyboard={false}
          size="sm"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Scan data</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>{`Scan Data using scanner gun.`}</h4>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        <Modal
          show={IsOrganisationunitpopup}
          onHide={() => {
            setisOrganisationunitpopup(false);
          }}
          backdrop="static"
          keyboard={false}
          size="sm"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Organisation Unit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>{`Candidate does not belong to Organisation Unit.`}</h4>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default MedicalScreen;
