import React, { useState, useEffect, useContext } from "react";
import SelectForm from "../../Components/SelectForm/SelectForm";
import { APICall, APICallWithSignal } from "../../Helpers/APICalls";
import { Collapse, Tooltip } from "@mui/material";
import { LoaderContext } from "../../Helpers/Context/Context";
import notify from "../../Helpers/ToastNotification";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import {
  GetTrainerDasboardFilter,
  TrainerDashboardList,
  TrainerDasboardActionsById,
  DeleteTrainerById,
  getUserAndRoleWiseAccess,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";

import ImageColumnComponent from "../../Components/LMS/ImageColumnComponent/ImageColumnComponent";
import ImageProfile from "../../Components/LMS/ImageProfile/ImageProfile";
import { useNavigate } from "react-router-dom";
import { Modal, ModalTitle } from "react-bootstrap";
import InputForm from "../../Components/InputForm/InputForm";

const TrainerMaster = () => {
  const [open, setOpen] = useState<any>(false);
  const [TrainerID, setTrainerID] = useState<any>([]);
  const [TrainerOption, setTrainerOption] = useState<any>([]);
  const navigate = useNavigate();
  const [RoleID, setRoleID] = useState<any>([]);
  const [RoleTypeOption, setRoleTypeOption] = useState<any>([]);
  const [TrainerNameID, setTrainerNameID] = useState<any>([]);
  const [TrainerNameOption, setTrainerNameOption] = useState<any>([]);
  const [DepartmentID, setDepartnentID] = useState<any>([]);
  const [DepartmentOption, setDepartmentOPtion] = useState<any>([]);
  const [statusOptions, setStatusOptions] = useState([
    { value: 1, label: "Active" },
    { value: 0, label: "Inactive" },
  ]);
  const [page, setPage] = useState(0);
  const [status, setStatus] = useState(null);
  const [modalTitle, setModalTitle] = useState<any>("");
  const [AgencyOption, setAgencyOption] = useState([]);
  const [AgencyID, setAgencyID] = useState([]);
  const [AgencyLocationOption, setAgencyLocationOption] = useState([]);
  const [AgencyLocationID, setAgencyLOcationID] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);
  const [dashboardPageSize, setDashboardPageSize] = useState(10);
  const [dashboardStart, setDashboardStart] = useState(0);
  const [dashboardSortColumn, setDashboardSortColumn] = useState<any>("");
  const [dashboardSortDirection, setDashboardSortDirection] = useState("");
  const [dashboardCount, setDashboardCount] = useState(0);
  const [searchText, setSearchText] = useState<any>("");
  let { userDetails, currentRoleId }: any = useUserContext();
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [showModal, setShowModal] = useState(false);
  const [showdeleteModal, setshowdeleteModal] = useState(false);
  const [Id, setId] = useState<any>("");
  const [IsActive, setIsActive] = useState<any>(false);
  const [remarks, setRemarks] = useState("");

  const onClickReset = async () => {
    setTrainerID([]);
    setRoleID([]);
    setTrainerNameID([]);
    setDepartnentID([]);
    setAgencyID([]);
    setAgencyLOcationID([]);
    setStatus(null);
    await LoadDashboard();
  };

  interface userAndRoleWiseAccess {
    isView?: boolean;
    isEdit?: boolean;
    isDelete?: boolean;
  }

  const [userAndRoleWiseData, setUserAndRoleWiseData] =
    useState<userAndRoleWiseAccess>({ isEdit: true, isView: true });

  const getUserAndRoleWiseAccessApiCall = async () => {
    const response = await APICall(getUserAndRoleWiseAccess, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      InterfaceName: "Trainer_Dashboard",
    });

    if (response?.status === 0) {
      setUserAndRoleWiseData(response?.data);
    } else {
      notify(response?.status, response?.message);
    }
  };

  useEffect(() => {
    (async () => {
      await GetTrainerId();
      await GetRoleType();
      await GetTrainerName();
      await GetDepartment();
      await GetAgency();
      await GetAgencyLocation();
      getUserAndRoleWiseAccessApiCall();
    })();
  }, []);

  useEffect(() => {
    LoadDashboard();
  }, [searchText, dashboardStart, dashboardSortColumn, dashboardSortDirection]);

  const GetTrainerId = async () => {
    let UserId;

    const { data } = await APICall(GetTrainerDasboardFilter, "POST", {
      Mode: "TRAINERTYPE",
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    if (data !== null && data.length > 0) {
      let role = data.map((element) => ({
        value: element.value,
        label: element.label,
      }));
      setTrainerOption(role);
    }
  };

  const GetRoleType = async () => {
    let UserId;

    const { data } = await APICall(GetTrainerDasboardFilter, "POST", {
      Mode: "ROLETYPE",
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    if (data !== null && data.length > 0) {
      let role = data.map((element) => ({
        value: element.value,
        label: element.label,
      }));
      setRoleTypeOption(role);
    }
  };

  const GetTrainerName = async () => {
    let UserId;

    const { data } = await APICall(GetTrainerDasboardFilter, "POST", {
      Mode: "TRAINERNAME",
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    if (data !== null && data.length > 0) {
      let role = data.map((element) => ({
        value: element.value,
        label: element.label,
      }));
      setTrainerNameOption(role);
    }
  };

  const GetDepartment = async () => {
    let UserId;

    const { data } = await APICall(GetTrainerDasboardFilter, "POST", {
      Mode: "DEPARTEMENT",
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    if (data !== null && data.length > 0) {
      let role = data.map((element) => ({
        value: element.value,
        label: element.label,
      }));
      setDepartmentOPtion(role);
    }
  };

  const GetAgency = async () => {
    let UserId;

    const { data } = await APICall(GetTrainerDasboardFilter, "POST", {
      Mode: "AGENCY",
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    if (data !== null && data.length > 0) {
      let role = data.map((element) => ({
        value: element.value,
        label: element.label,
      }));
      setAgencyOption(role);
    }
  };

  const GetAgencyLocation = async () => {
    let UserId;

    const { data } = await APICall(GetTrainerDasboardFilter, "POST", {
      Mode: "AGENCYLOCATION",
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    if (data !== null && data.length > 0) {
      let role = data.map((element) => ({
        value: element.value,
        label: element.label,
      }));
      setAgencyLocationOption(role);
    }
  };

  const MapArr = (arr) => {
    let s = [];
    if (arr[0] === null || arr.length < 0) {
      return "";
    }
    arr.map((i) => s.push(i.value));
    return s;
  };
  let TrainerIDs = MapArr(TrainerID);
  let DepartmentsIDs = MapArr(DepartmentID);
  let RoleIDs = MapArr(RoleID);
  let TrainerNameIDs = MapArr(TrainerNameID);
  let AgencyIDs = MapArr(AgencyID);
  let AgencyLocationIDs = MapArr(AgencyLocationID);
  let A;
  const LoadDashboard = async () => {
    showLoader();
    await setDashboardData([]);
    await setDashboardCount(0);

    let requestParams = {
      PageSize: dashboardPageSize,
      Page: dashboardStart,
      SortOrder: dashboardSortDirection,
      SortColumn: dashboardSortColumn,
      SearchText: searchText,
      TrainerType: "",
      RoleType: "",
      Departement: "",
      TrainerName: "",
      TrainerIsActive: null,
      Agency: "",
      AgencyLocation: "",
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    };
    //const { data } = await APICall(ProgramDashboardList, "POST", requestParams);
    const { data } = await APICall(TrainerDashboardList, "POST", requestParams);
    debugger;
    if (data !== null && data.length > 0) {
      console.log(data, "data");
      await setDashboardData(data);
      setDashboardCount(data[0].count);
      hideLoader();
    } else {
      await setDashboardData([]);
      //await setPage(0)
    }
    hideLoader();
  };

  const onSubmitFilter = async () => {
    if (
      TrainerID.length === 0 &&
      DepartmentID.length === 0 &&
      RoleID.length === 0 &&
      TrainerNameID.length === 0 &&
      status === null &&
      AgencyID.length === 0 &&
      AgencyLocationID.length === 0
    ) {
      notify(1, "Please select at least one option from the dropdowns.");
    } else {
      showLoader();
      let requestParams = {
        PageSize: dashboardPageSize,
        Page: dashboardStart,
        SortOrder: dashboardSortDirection,
        SortColumn: dashboardSortColumn,
        SearchText: searchText,
        TrainerType: TrainerID.length > 0 ? TrainerIDs.toString() : "",
        RoleType: RoleID.length > 0 ? RoleIDs.toString() : "",
        Departement: DepartmentID.length > 0 ? DepartmentsIDs.toString() : "",
        TrainerName: TrainerNameID.length > 0 ? TrainerNameIDs.toString() : "",
        TrainerIsActive: status?.value,
        Agency: AgencyID.length > 0 ? AgencyIDs.toString() : "",
        AgencyLocation:
          AgencyLocationID.length > 0 ? AgencyLocationIDs.toString() : "",
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      };
      const { data } = await APICall(
        TrainerDashboardList,
        "POST",
        requestParams
      );
      if (data !== null && data.length > 0) {
        await setDashboardData(data);
        setDashboardCount(data[0].count);
      } else {
        setDashboardData([]);
      }
    }
    hideLoader();
  };

  const gridColumns = [
    {
      name: "thumbnailUrl",
      label: "Thumbnail",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: false,
        customBodyRender: (value, tableMeta) => {
          let Thumbnail =
            tableMeta.tableData[tableMeta.rowIndex].thumbnailDataObject;
          //setProfileImg(`data:image;base64,${Thumbnail}`);
          return <ImageProfile link={value} />;
        },
      },
    },
    {
      name: "trainerType",
      label: "Trainer Type",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "roleType",
      label: "Role Type",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "trainerName",
      label: "Trainer Name",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "departement",
      label: "Department",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta) => {
          let id = tableMeta.tableData[tableMeta.rowIndex].id;
          let isActive = tableMeta.tableData[tableMeta.rowIndex].isActive;

          let color = isActive ? "green" : "red";
          let activate = isActive ? "Active" : "Inactive";

          return (
            <div style={{ width: "100px" }}>
              {userAndRoleWiseData?.isEdit && (
                <>
                  <Tooltip title="Edit">
                    <a
                      className="mr-2"
                      style={{ fontSize: "15px" }}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/Trainers", {
                          state: { id, isDisable: false },
                        });
                      }}>
                      <i className="fas fa-edit"></i>
                    </a>
                  </Tooltip>

                  {/* <Tooltip title="Delete">
                        <a
                          className="mr-2"
                          style={{ fontSize: "15px" }}
                          onClick={(e) => {
                            e.preventDefault();
                            RemoveTrainerbyId(id)
                          }}
                        >
                          <i className="fas fa-trash"></i>
                        </a>
                      </Tooltip> */}

                  <Tooltip title={activate}>
                    <a
                      aria-disabled
                      className="mr-2"
                      style={{
                        fontSize: "15px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        DeleteUserIdModal(id, isActive);
                      }}>
                      <i
                        className="fa-solid fa-circle"
                        style={{ color: color }}></i>
                    </a>
                  </Tooltip>
                </>
              )}
              {!userAndRoleWiseData?.isEdit && userAndRoleWiseData?.isView && (
                <Tooltip title="View">
                  <a
                    className="mr-2"
                    style={{ fontSize: "15px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/Trainers", {
                        state: { id, isDisable: true },
                      });
                    }}>
                    <i className="fas fa-eye"></i>
                  </a>
                </Tooltip>
              )}
            </div>
          );
        },
      },
    },
  ];

  const dashboardOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: dashboardCount,
    page: page,
    rowsPerPage: dashboardPageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    //standard | vertical | simple
    onChangeRowsPerPage: (num) => {
      //   setLimit(num);
      //   setNxtPgInfo("");
      //   setPrevPgInfo("");
      //   setIsPrevOrNext("");
    },
    onSearchChange: (searchText) => {
      if (searchText !== null) {
        //setDashboardSearchText(searchText);
      } else {
        //setDashboardSearchText("");
      }
    },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setDashboardSortColumn(sortColumn);
        await setDashboardSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setDashboardSortColumn(sortColumn);
        await setDashboardSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      setPage(page);
      setDashboardStart(page * dashboardPageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const RemoveTrainerbyId = (id: any) => {
    setId(id);
    setshowdeleteModal(true);
    setModalTitle("Do you want to delete the Trainer ?");
  };

  const DeletedtrainerbyId = async (id: any) => {
    if (remarks === "" || remarks === null) {
      notify(1, "Please enter remarks");
    } else {
      showLoader();
      const { data } = await APICall(DeleteTrainerById, "POST", {
        Id: id,
        // IsActive: isActive,
        Remarks: remarks,
        CreatedBy: userDetails?.Id,
      });
      setshowdeleteModal(false);
      setRemarks("");
      if (data != null) {
        notify(0, "Trainer deleted successfully");
        await LoadDashboard();
      }
      hideLoader();
    }
  };

  const DeleteUserIdModal = async (id: any, isActive: any) => {
    setShowModal(true);
    console.log(id, "Id");
    setId(id);
    if (isActive) {
      setModalTitle("Do you want to Inactivate it?");
    } else {
      setModalTitle("Do you want to Activate it?");
    }
    setIsActive(isActive);
  };

  const DeleteuserbyId = async (id: any, isActive: any) => {
    if (remarks === "" || remarks === null) {
      notify(1, "Please enter remarks");
    } else {
      showLoader();
      const { data } = await APICall(TrainerDasboardActionsById, "POST", {
        Id: id,
        IsActive: isActive,
        Remarks: remarks,
        CreatedBy: userDetails?.Id,
      });

      setShowModal(false);
      setRemarks("");
      if (data != null) {
        if (!isActive) {
          notify(0, "Trainer Activated successfully");
        } else {
          notify(0, "Trainer Deactivated successfully");
        }
        await LoadDashboard();
        hideLoader();
      } else {
        notify(1, "Something went wrong");
      }
    }
  };

  const addNewOU = async () => {
    navigate("/Trainers");
  };

  return (
    <>
      <div className="breadcrumb modified-bredcrumb">
        <span>
          <ul>
            <li>
              <a href="/home">Home</a>
            </li>
            <li>Trainer Master</li>
          </ul>
        </span>
        {userAndRoleWiseData?.isEdit && (
          <button
            disabled={false}
            onClick={addNewOU}
            className="btn btn-primary mt-3 mr-2">
            <i className="fa fa-plus mr-2"></i>
            Create Trainer
          </button>
        )}
      </div>

      <div className="container-fluid">
        <div className="grid-wrapper grid_mob">
          <div className="row mx-auto filtBox">
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="form-group filtInp">
                <InputForm
                  value={searchText}
                  placeholder={"Search Keyword"}
                  isDisabled={false}
                  textArea={false}
                  onChange={(e) => {
                    //setPage(0);
                    setDashboardStart(0);
                    setPage(0);
                    setSearchText(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-12 filtrt">
              <ul className=" filter-icon-wel">
                <li>
                  <a
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                    className="filter-btn">
                    <i className="fa fa-filter"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <>
          <Collapse in={open}>
            <div id="example-collapse-text grid-wrapper">
              <div className="row mx-auto myInlineStyle">
                <>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <div className="form-group filtInp">
                      <label className="col-form-label text-white">
                        Trainer Type
                      </label>
                      <SelectForm
                        isClearable
                        options={TrainerOption}
                        placeholder={"Select Trainer Type"}
                        isDisabled={false}
                        onChange={(event) => {
                          setTrainerID(event);
                        }}
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                        value={TrainerID}
                      />
                    </div>
                  </div>

                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <div className="form-group filtInp">
                      <label className="col-form-label text-white">
                        Role Type
                      </label>
                      <SelectForm
                        isClearable
                        options={RoleTypeOption}
                        placeholder={"Select Role Type"}
                        isDisabled={false}
                        onChange={(event) => {
                          setRoleID(event);
                        }}
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                        value={RoleID}
                      />
                    </div>
                  </div>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <div className="form-group filtInp">
                      <label className="col-form-label text-white">
                        Trainer Name
                      </label>
                      <SelectForm
                        isClearable
                        options={TrainerNameOption}
                        placeholder={"Select Trainer Names"}
                        isDisabled={false}
                        onChange={(event) => {
                          setTrainerNameID(event);
                        }}
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                        value={TrainerNameID}
                      />
                    </div>
                  </div>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <div className="form-group filtInp">
                      <label className="col-form-label text-white">
                        Department
                      </label>
                      <SelectForm
                        isClearable
                        options={DepartmentOption}
                        placeholder={"Select Department"}
                        isDisabled={false}
                        onChange={(event) => {
                          setDepartnentID(event);
                        }}
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                        value={DepartmentID}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <div className="form-group filtInp">
                      <label className="col-form-label text-white">
                        Status
                      </label>
                      <SelectForm
                        isClearable
                        options={statusOptions}
                        placeholder={"Select Status"}
                        isDisabled={false}
                        onChange={(event) => {
                          setStatus(event);
                        }}
                        isMulti={false}
                        noIndicator={false}
                        noSeparator={false}
                        value={status}
                      />
                    </div>
                  </div>

                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <div className="form-group filtInp">
                      <label className="col-form-label text-white">
                        Agency
                      </label>
                      <SelectForm
                        isClearable
                        options={AgencyOption}
                        placeholder={"Select Agency"}
                        isDisabled={false}
                        onChange={(event) => {
                          setAgencyID(event);
                        }}
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                        value={AgencyID}
                      />
                    </div>
                  </div>

                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <div className="form-group filtInp">
                      <label className="col-form-label text-white">
                        Agency Location
                      </label>
                      <SelectForm
                        isClearable
                        options={AgencyLocationOption}
                        placeholder={"Select Agency Location"}
                        isDisabled={false}
                        onChange={(event) => {
                          setAgencyLOcationID(event);
                        }}
                        isMulti={true}
                        noIndicator={false}
                        noSeparator={false}
                        value={AgencyLocationID}
                      />
                    </div>
                  </div>
                </>

                <div className="col-lg-12 d-flex mt-2 justify-content-end">
                  <button
                    className="btn btn-secondary mr-2"
                    onClick={onClickReset}>
                    <i className="fa fa-undo" aria-hidden="true"></i> Reset
                  </button>
                  <button
                    className="btn btn-filter-submit"
                    onClick={() => {
                      setPage(0);
                      setDashboardStart(0);
                      onSubmitFilter();
                    }}>
                    <i className="fa fa-search" aria-hidden="true"></i> Search{" "}
                  </button>
                </div>
              </div>
            </div>
          </Collapse>
        </>
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          backdrop="static"
          keyboard={false}
          size="sm"
          centered>
          <div
            className="bg-secondary text-white"
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
            }}>
            <div className="col-lg-12">{`${modalTitle}`}</div>
          </div>
          <Modal.Body>
            <>
              <div className="row">
                <div className="col-md-8">
                  <label className="col-form-label">Remarks</label>
                  <sup>*</sup>
                  <InputForm
                    value={remarks}
                    placeholder={"Remarks"}
                    isDisabled={false}
                    textArea={true}
                    onChange={(e) => {
                      setRemarks(e.target.value);
                    }}
                  />
                </div>
              </div>
            </>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary"
              style={{ marginLeft: 5 }}
              onClick={() => DeleteuserbyId(Id, IsActive)}>
              Submit
            </button>
            <button
              className="btn btn-cancel"
              style={{ marginLeft: 5 }}
              onClick={() => {
                setShowModal(false);
              }}>
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showdeleteModal}
          onHide={() => setshowdeleteModal(false)}
          backdrop="static"
          keyboard={false}
          size="sm"
          centered>
          <div
            className="bg-secondary text-white"
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
            }}>
            <div className="col-lg-12">{`${modalTitle}`}</div>
          </div>
          <Modal.Body>
            <>
              <div className="row">
                {/* <div className="col-md-12">
                  <b>{`${modalTitle}`}</b>
                </div> */}
                <div className="col-md-8">
                  <label className="col-form-label">Remarks</label>
                  <sup>*</sup>
                  <InputForm
                    value={remarks}
                    placeholder={"Remarks"}
                    isDisabled={false}
                    textArea={true}
                    onChange={(e) => {
                      setRemarks(e.target.value);
                    }}
                  />
                </div>
              </div>
            </>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary"
              style={{ marginLeft: 5 }}
              onClick={() => DeletedtrainerbyId(Id)}>
              Submit
            </button>
            <button
              className="btn btn-cancel"
              style={{ marginLeft: 5 }}
              onClick={() => {
                setshowdeleteModal(false);
              }}>
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        <div className="mb-3 pt-3">
          <DynamicGrid
            data={dashboardData}
            columns={gridColumns}
            options={dashboardOptions}
          />
        </div>
      </div>
    </>
  );
};

export default TrainerMaster;
