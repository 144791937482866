import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { LoaderContext } from "../../Helpers/Context/Context";
import { Tooltip } from "@mui/material";
import { APICall } from "../../Helpers/APICalls";
import {
  DeleteManageContractorComplianceDetails,
  GetContractorComplianceDasboardDetails,
  GetContractorDetailsDropDown,
  GetManageContractorComplianceDetails,
  ManageContractorComplianceDetails,
  SetDocumentURLDetails,
} from "../../Helpers/APIEndPoints/AssociateEndPoints";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import AccordianCustom1 from "../DocumentScreen/AccordianCustom1";
import SelectForm from "../../Components/SelectForm/SelectForm";
import DateForm from "../../Components/DateForm/DateForm";
import InputForm from "../../Components/InputForm/InputForm";
import notify from "../../Helpers/ToastNotification";
import moment from "moment";
import { getContentUrlDetails } from "../../Helpers/APIEndPoints/LMS_EndPoints";
import { isEmpty } from "../../utils/isEmpty";
import { color } from "html2canvas/dist/types/css/types/color";
import { Modal } from "react-bootstrap";

interface IOptions {
  value?: number | string;
  label?: string;
  code?: string;
}

interface IFormData {
  documentTypeId?: IOptions | string | any;
  effectiveDate?: string | Number | any;
  registrationNumber?: Number | string | any;
  issueDate?: string | Number | any;
  expiryDate?: string | Number | any;
  renewalDate?: string | Number | any;
  noOfWorkersCovered?: string | Number | any;
  documentStatusId?: IOptions | Number | any;
}

const ContractorCompliance = () => {
  const { state } = useLocation();

  const navigate = useNavigate();
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  const { showLoader, hideLoader } = useContext(LoaderContext);

  const [formErrors, setFormErrors] = useState<IFormData>({});

  const [formOptions, setFormOptions] = useState<IFormData>({});

  const [formData, setFormData] = useState<IFormData>({});

  const [contractorId, setContractorId] = useState(
    (state && state?.contractorId) || 0
  );

  const [id, setId] = useState(0);

  const [showModal, setShowModal] = useState(false);

  const [IsDisableLicenseStrength, setIsDisableLicenseStrength] =
    useState(false);

  const [count, setCount] = useState(0);
  const [gridData, setGridData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sortDirection, setSortDirection] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [searchText, setSearchText] = useState("");

  const [reSet, setreSet] = useState(false);

  const [file, setFile] = useState(null);
  const fileRef = useRef(null);

  const [fileName, setFileName] = useState(null);

  const maxLicenseStrengthArray = ["LABOURLICENSE", "WCPOLICY"];

  useEffect(() => {
    const responseData = async () => {
      const parameters = {
        Mode: "DocumentType",
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      };
      const res = await APICall(
        GetContractorDetailsDropDown,
        "POST",
        parameters
      );

      if (res?.status === 0 && res?.data?.length > 0) {
        setFormOptions((env) => {
          return {
            ...env,
            documentTypeId: res?.data,
          };
        });
      }

      parameters.Mode = "DocumentStatus";

      const res1 = await APICall(
        GetContractorDetailsDropDown,
        "POST",
        parameters
      );

      if (res1?.status === 0 && res1?.data?.length > 0) {
        setFormOptions((env) => {
          return {
            ...env,
            documentStatusId: res1?.data,
          };
        });
      }
    };

    showLoader();

    responseData();

    hideLoader();
  }, []);

  const gridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: pageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      setPageNumber(page * pageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };
  const gridColumns = [
    {
      name: "Id",
      label: "Id",
      options: {
        display: false,
      },
    },

    {
      name: "documentType",
      label: "Document Type",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "registrationNumber",
      label: "Policy / Registration Number",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "newIssueDate",
      label: "Issue Date",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "newExpiryDate",
      label: "Expiry Date",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "noOfWorkersCovered",
      label: "Count",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "documentStatus",
      label: "Document Status",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta) => {
          let id = tableMeta.tableData[tableMeta.rowIndex].id;
          let documentUrl = tableMeta.tableData[tableMeta.rowIndex].documentUrl;
          let documentName =
            tableMeta.tableData[tableMeta.rowIndex].documentName;

          return (
            <div style={{ width: "100px" }}>
              <>
                <Tooltip title="Edit">
                  <a
                    className="mr-2"
                    style={{ fontSize: "15px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      getManageContractorComplianceDetailsApiCall(id);
                    }}
                  >
                    <i className="fas fa-edit"></i>
                  </a>
                </Tooltip>
                <Tooltip title="Delete">
                  <a
                    className="mr-2"
                    style={{ fontSize: "15px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setId(id);
                      setShowModal(true);
                    }}
                  >
                    <i className="fas fa-trash"></i>
                  </a>
                </Tooltip>
                {documentUrl !== null && documentName !== null && (
                  <Tooltip title="Download Document">
                    <a
                      className="mr-2"
                      style={{ fontSize: "15px" }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleDocumentDownload(documentUrl, documentName);
                      }}
                    >
                      <i className="fas fa-download"></i>
                    </a>
                  </Tooltip>
                )}
              </>
            </div>
          );
        },
      },
    },
  ];

  useEffect(() => {
    GetContractorComplianceDasboardDetailsAPICall();
  }, [pageNumber, pageSize, sortColumn, sortDirection, searchText, reSet]);

  const GetContractorComplianceDasboardDetailsAPICall = async () => {
    try {
      showLoader();

      if (contractorId > 0) {
        const requestParams = {
          PageNumber: pageNumber,
          PageSize: pageSize,
          SortColumn: sortColumn,
          SortOrder: sortDirection,
          SearchText: searchText,
          UserId: userDetails?.Id,
          RoleId: currentRoleId?.value,
          ContractorId: contractorId,
        };

        const responseData = await APICall(
          GetContractorComplianceDasboardDetails,
          "POST",
          requestParams
        );

        if (
          responseData &&
          responseData.data !== null &&
          responseData.data.length > 0
        ) {
          setGridData(responseData.data);
          setCount(responseData.data[0].totalCount);
        } else {
          setGridData([]);
          setCount(0);
        }
      } else {
        notify(1, "Contractor id is null");
      }
    } catch (error) {
      console.error("GetContractorUserDasboardDetails", error);
    } finally {
      hideLoader();
    }
  };

  const formOnChange = (e, type) => {
    switch (type) {
      case "documentTypeId":
        if (maxLicenseStrengthArray.includes(e?.code)) {
          setIsDisableLicenseStrength(true);
          setFormData({
            ...formData,
            documentTypeId: e,
            noOfWorkersCovered: 0,
          });
        } else {
          setIsDisableLicenseStrength(false);
          setFormData({
            ...formData,
            documentTypeId: e,
            noOfWorkersCovered: 0,
          });
        }

        break;

      case "effectiveDate":
        setFormData({
          ...formData,
          effectiveDate: e,
        });
        break;

      case "registrationNumber":
        setFormData({
          ...formData,
          registrationNumber: e.target.value,
        });
        break;

      case "issueDate":
        setFormData({
          ...formData,
          issueDate: e,
          effectiveDate: e,
        });
        break;

      case "expiryDate":
        setFormData({
          ...formData,
          expiryDate: e,
        });
        break;

      case "renewalDate":
        setFormData({
          ...formData,
          renewalDate: e,
        });
        break;

      case "noOfWorkersCovered":
        setFormData({
          ...formData,
          noOfWorkersCovered: e.target.value,
        });
        break;

      case "documentStatusId":
        setFormData({
          ...formData,
          documentStatusId: e,
        });
        break;

      default:
        break;
    }
  };

  const validateContractorComplianceSection = () => {
    let errorObj = {};
    let isError = false;

    setFormErrors({});

    if (formData?.issueDate === null || formData?.issueDate === undefined) {
      errorObj = {
        ...errorObj,
        ["issueDate"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["issueDate"]: "",
      };
    }

    if (formData?.expiryDate === null || formData?.expiryDate === undefined) {
      errorObj = {
        ...errorObj,
        ["expiryDate"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["expiryDate"]: "",
      };
    }

    if (
      formData?.issueDate !== undefined &&
      !formData?.expiryDate !== undefined
    ) {
      if (moment(formData?.issueDate).isAfter(formData?.expiryDate)) {
        errorObj = {
          ...errorObj,
          ["issueDate"]: "Issue date must be smaller than expiry date.",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["issueDate"]: "",
        };
      }
    }

    if (isEmpty(formData?.registrationNumber)) {
      errorObj = {
        ...errorObj,
        ["registrationNumber"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["registrationNumber"]: "",
      };
    }

    if (isEmpty(formData?.documentTypeId)) {
      errorObj = {
        ...errorObj,
        ["documentTypeId"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["documentTypeId"]: "",
      };
    }

    if (IsDisableLicenseStrength) {
      if (isEmpty(formData?.noOfWorkersCovered)) {
        errorObj = {
          ...errorObj,
          ["noOfWorkersCovered"]: "Required",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["noOfWorkersCovered"]: "",
        };
      }
    }

    const isEmptyObj = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmptyObj) {
      isError = true;
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  const handleContractorComplianceSection = async () => {
    try {
      showLoader();
      if (validateContractorComplianceSection()) {
        hideLoader();
        return;
      }

      const requestParams = {
        Id: id,
        DocumentTypeId: formData?.documentTypeId?.value,
        RegistrationNumber: formData?.registrationNumber,
        EffectiveDate: moment(formData?.effectiveDate).format("MM-DD-YYYY"),
        IssueDate: moment(formData?.issueDate).format("MM-DD-YYYY"),
        ExpiryDate: moment(formData?.expiryDate).format("MM-DD-YYYY"),
        RenewalDate:
          formData?.renewalDate === undefined || formData?.renewalDate === null
            ? null
            : moment(formData?.renewalDate).format("MM-DD-YYYY"),
        NoOfWorkersCovered: formData?.noOfWorkersCovered,
        DocumentStatusId: formData?.documentStatusId?.value,
        ContractorId: contractorId,
        CreatedBy: userDetails?.Id,
      };

      const response = await APICall(
        ManageContractorComplianceDetails,
        "POST",
        requestParams
      );

      if (response?.status === 0) {
        notify(response?.status, response?.message);

        await handleUploadDocument(response?.data?.id);
        setId(0);
        handleResetSection();
        setreSet(!reSet);
      } else if (response?.status === 1) {
        notify(response?.status, response?.message);
      }
    } catch (error) {
      console.error("ManageContractorComplianceDetails", error);
    } finally {
      hideLoader();
    }
  };

  const deletetManageContractorComplianceDetailsApiCall = async (id) => {
    try {
      showLoader();

      if (id > 0) {
        const response = await APICall(
          DeleteManageContractorComplianceDetails,
          "POST",
          {
            Id: id,
          }
        );

        if (response?.status === 0) {
          setShowModal(false);
          setreSet(!reSet);
          notify(response?.status, "Record deleted sucessfully");
        } else if (response?.status === 1) {
          notify(response?.status, response?.message);
        }
      } else {
        notify(1, "Id is null");
      }
    } catch (error) {
      console.error("DeleteManageContractorComplianceDetails", error);
    } finally {
      hideLoader();
    }
  };

  const getManageContractorComplianceDetailsApiCall = async (id) => {
    try {
      showLoader();

      setId(id);

      const response = await APICall(
        GetManageContractorComplianceDetails,
        "POST",
        {
          Id: id,
        }
      );

      if (response?.status === 0) {
        const contractorUserdata = response?.data;

        const documentTypeObj = formOptions?.documentTypeId?.find(
          (val) => val?.value === contractorUserdata?.documentTypeId
        );

        const documentStatusObj = formOptions?.documentStatusId?.find(
          (val) => val?.value === contractorUserdata?.documentStatusId
        );

        setFileName(contractorUserdata?.documentName);

        if (maxLicenseStrengthArray.includes(documentTypeObj?.code)) {
          setIsDisableLicenseStrength(true);
        } else {
          setIsDisableLicenseStrength(false);
        }

        setFormData((env) => {
          return {
            documentTypeId: documentTypeObj,
            effectiveDate: contractorUserdata?.effectiveDate,
            registrationNumber: contractorUserdata?.registrationNumber,
            issueDate: contractorUserdata?.issueDate,
            expiryDate: contractorUserdata?.expiryDate,
            renewalDate: contractorUserdata?.renewalDate,
            noOfWorkersCovered: contractorUserdata?.noOfWorkersCovered,
            documentStatusId: documentStatusObj,
          };
        });
      } else if (response?.status === 1) {
        notify(response?.status, response?.message);
      }
    } catch (error) {
      console.error("GetManageContractorComplianceDetails", error);
    } finally {
      hideLoader();
    }
  };

  const handleResetSection = () => {
    setFormErrors({});

    setId(0);

    setFile(null);

    setFileName(null);

    setIsDisableLicenseStrength(false);

    setFormData({
      documentTypeId: null,
      effectiveDate: null,
      registrationNumber: "",
      issueDate: null,
      expiryDate: null,
      renewalDate: null,
      noOfWorkersCovered: "",
      documentStatusId: null,
    });
  };

  const fileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileChange = async (e) => {
    showLoader();
    const file = e.target.files[0];
    let fileBase64: { Name: string; Data: string } = null;
    const base64 = (await fileToBase64(file)) as string;
    const base64WithoutPrefix = base64.split(",")[1];
    fileBase64 = { Name: file.name, Data: base64WithoutPrefix };

    //".docx,.doc,.pdf,.png,.jpg,.jpeg,.txt,.ppt,.pptx,.xlsx"
    let validExtensions = ["pdf", "doc", "docx", "jpg", "png"];

    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();
      const fileSize = file.size;
      const maxFileSizeBytes = 5 * 1024 * 1024;

      // Check if the file extension is allowed
      if (
        validExtensions.includes(fileExtension) &&
        fileSize <= maxFileSizeBytes
      ) {
        // file to upload
        setFile(fileBase64);
        setFileName(fileName);
        // setProfileImg(`data:image;base64,${fileBase64.Data}`);
      } else {
        notify(
          1,
          "Only file with pdf,doc,docx,jpg,png allowed and size under 5MB"
        );

        setFile(null);
      }
    }

    hideLoader();
  };

  const handleUploadDocument = async (id: any) => {
    showLoader();

    if (file !== null) {
      // Check file size

      const response = await APICall(SetDocumentURLDetails, "POST", {
        SectionName: "ContractorCompliance",
        AssociatesDetailsId: id,
        IsContractorCompliance: true,
        file: {
          Name: file?.Name,
          Data: file?.Data,
        },
        CreatedBy: userDetails?.Id,
      });

      if (response?.status == 0) {
        notify(0, "Document Saved Successfully");
        setFile(null);
      } else {
        notify(1, "Something went wrong");
      }
    }
    // else {
    //   notify(1, "Please select document");
    // }
    hideLoader();
  };

  const handleDocumentDownload = async (documentUrl, documentName) => {
    try {
      showLoader();
      const getContentUrl = await APICall(getContentUrlDetails, "POST", {
        ContentUrl: documentUrl,
      });

      const data = getContentUrl?.data?.contentUrlDataObject;
      const name = documentName;

      await downloadBase64File(data, name);
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  function downloadBase64File(base64String: string, fileName: string) {
    const mimeType = inferMimeType(base64String);
    const blob = base64toBlob(base64String, mimeType);

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  }
  function inferMimeType(base64String: string): string {
    const dataUrl = `data:application/octet-stream;base64,${base64String}`;
    const typeInfo = /^data:(.*?);/.exec(dataUrl);

    if (typeInfo && typeInfo[1]) {
      return typeInfo[1];
    }

    return "application/octet-stream";
  }
  function base64toBlob(base64String: string, mimeType: string): Blob {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], { type: mimeType });
  }

  return (
    <>
      {/* <div className="form-main">
        <div className="page-title w-100">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <h4>
                  {" "}
                  <i
                    className="fa-solid fa-home pr-1"
                    style={{
                      color: "white",
                      cursor: "pointer",
                      fontSize: "13px",
                      verticalAlign: "middle",
                    }}
                    onClick={() => {
                      navigate("/Bluecollar");
                    }}
                  ></i>
                  Contractor Compliance
                </h4>
              </div>
              <div className="offset-lg-6 "></div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="breadcrumb modified-bredcrumb">
        <h4>Contractor Compliance</h4>
        <button
          onClick={() => {
            navigate("/ContractorComplianceDashboard");
          }}
          disabled={false}
          className="btn btn-secondary float-right"
        >
          <i className="fa-solid fa-arrow-left"></i> Back
        </button>
      </div>

      <div className="container-fluid mt-2" id="blue-collar-pages">
        <div className="col-lg-12 mt-2">
          <AccordianCustom1
            header={`Add Compliance Document for : ${
              state && state?.contractorName
            }`}
            submitFunc={""}
            btnDisable={false}
            hideBtn={true}
          >
            <div className="row">
              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">Document Type</label>
                  <sup>*</sup>

                  <SelectForm
                    isClearable
                    isSearchable
                    options={formOptions?.documentTypeId}
                    placeholder={"Document Type"}
                    isDisabled={false}
                    value={formData?.documentTypeId}
                    onChange={async (e) => {
                      formOnChange(e, "documentTypeId");
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  {formErrors["documentTypeId"] && (
                    <p style={{ color: "red" }}>
                      {formErrors["documentTypeId"]}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">Effective Date</label>
                  <DateForm
                    placeholder={"Effective Date"}
                    isDisabled={true}
                    value={formData?.effectiveDate || null}
                    onChange={(e: any) => {
                      formOnChange(e, "effectiveDate");
                    }}
                  />
                  {formErrors["effectiveDate"] && (
                    <p style={{ color: "red" }}>
                      {formErrors["effectiveDate"]}
                    </p>
                  )}
                </div>
              </div>

              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">Registration Number</label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control"
                    placeholder={"Registration Number"}
                    isDisabled={false}
                    textArea={false}
                    value={formData?.registrationNumber}
                    onChange={(e: any) => {
                      formOnChange(e, "registrationNumber");
                    }}
                  />
                  {formErrors["registrationNumber"] && (
                    <p style={{ color: "red" }}>
                      {formErrors["registrationNumber"]}
                    </p>
                  )}
                </div>
              </div>

              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">Issue Date</label>
                  <sup>*</sup>
                  <DateForm
                    placeholder={"Issue Date"}
                    isDisabled={false}
                    value={formData?.issueDate || null}
                    onChange={(e: any) => {
                      formOnChange(e, "issueDate");
                    }}
                  />
                  {formErrors["issueDate"] && (
                    <p style={{ color: "red" }}>{formErrors["issueDate"]}</p>
                  )}
                </div>
              </div>

              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">Expiry Date</label>
                  <sup>*</sup>
                  <DateForm
                    placeholder={"Expiry Date"}
                    isDisabled={false}
                    value={formData?.expiryDate || null}
                    onChange={(e: any) => {
                      formOnChange(e, "expiryDate");
                    }}
                  />
                  {formErrors["expiryDate"] && (
                    <p style={{ color: "red" }}>{formErrors["expiryDate"]}</p>
                  )}
                </div>
              </div>

              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">Renewal Date</label>
                  <DateForm
                    placeholder={"Renewal Date"}
                    isDisabled={false}
                    value={formData?.renewalDate || null}
                    onChange={(e: any) => {
                      formOnChange(e, "renewalDate");
                    }}
                  />
                  {formErrors["renewalDate"] && (
                    <p style={{ color: "red" }}>{formErrors["renewalDate"]}</p>
                  )}
                </div>
              </div>

              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    {/* No. of Workers Covered */}
                    Max License Strength
                    {IsDisableLicenseStrength ? <sup>*</sup> : ""}
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Count"}
                    isDisabled={!IsDisableLicenseStrength}
                    textArea={false}
                    value={formData?.noOfWorkersCovered}
                    onChange={(e: any) => {
                      formOnChange(e, "noOfWorkersCovered");
                    }}
                  />
                  {formErrors["noOfWorkersCovered"] && (
                    <p style={{ color: "red" }}>
                      {formErrors["noOfWorkersCovered"]}
                    </p>
                  )}
                </div>
              </div>

              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">Document Status</label>
                  <SelectForm
                    isClearable
                    isSearchable
                    options={formOptions?.documentStatusId}
                    placeholder={"Document Status"}
                    isDisabled={false}
                    value={formData?.documentStatusId}
                    onChange={async (e) => {
                      formOnChange(e, "documentStatusId");
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  {formErrors["documentStatusId"] && (
                    <p style={{ color: "red" }}>
                      {formErrors["documentStatusId"]}
                    </p>
                  )}
                </div>
              </div>

              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mt-2">
                  <label className="col-form-label">Upload Document</label>
                  <>
                    <div className="box position-relative">
                      <input
                        id="file"
                        className="form-control inputfile inputfile-6 multiple-inputfile"
                        data-multiple-caption="{count} files selected"
                        multiple={false}
                        type="file"
                        onChange={(e) => {
                          handleFileChange(e);
                        }}
                        accept={".pdf,.doc,.docx,.jpg,.png"}
                        ref={fileRef}
                        value={""}
                        disabled={false}
                      />
                      <label
                        htmlFor={`file`}
                        className="form-control"
                        style={{ width: 0, border: "none" }}
                      >
                        <strong
                          style={{
                            padding: "6px 16px",
                            backgroundColor: "#3c5464",
                            borderRadius: "5px",
                          }}
                        >
                          <i
                            className="fa fa-upload rotate90"
                            aria-hidden="true"
                          ></i>{" "}
                          Upload
                        </strong>
                      </label>
                    </div>
                    <span style={{ color: "red" }}>
                      {
                        "Note : Only file with pdf,doc,docx,jpg,png allowed and size under 5MB"
                      }
                    </span>
                  </>
                  <div className="file-added-list">
                    <ul className="list-unstyle">
                      <li className="list mt-1">
                        <div className="media">
                          <div className="media-body text-truncate">
                            <span className="view-more">{fileName}</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <span style={{ color: "red" }}>{formErrors["file"]}</span>
                </div>
              </div>
            </div>
            <div className="SectionSubmit mb-2 clearfix">
              <button
                onClick={() => {
                  handleContractorComplianceSection();
                }}
                disabled={false}
                className="btn btn-filter-submit float-right ml-2 mt-4"
              >
                <i className="fa fa-save"></i> Save
              </button>

              <button
                onClick={() => {
                  handleResetSection();
                }}
                disabled={false}
                className="btn btn-secondary float-right ml-2 mt-4"
              >
                <i className="fa-solid fa-refresh"></i> Reset
              </button>
            </div>
          </AccordianCustom1>
        </div>
        <div className="mb-3 pt-3">
          <DynamicGrid
            data={gridData}
            columns={gridColumns}
            options={gridOptions}
          />
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">{`Confirmation Message`}</div>
        </div>
        <Modal.Body>
          <>
            <span>{`Are you sure you want to delete the record?`}</span>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => {
              deletetManageContractorComplianceDetailsApiCall(id);
            }}
          >
            Submit
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowModal(false);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ContractorCompliance;
