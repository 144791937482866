import React, { useContext, useEffect, useState } from "react";
import { APICall } from "../../Helpers/APICalls";
import {
  GetProgramScheduleDetails,
  GetSessionContent,
  GetThumbnailDetails,
  getCourseScheduleDetails,
  createRegistrationRequest,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import { LoaderContext } from "../../Helpers/Context/Context";
import { useLocation, useNavigate } from "react-router-dom";
import { ExpandMore } from "@mui/icons-material";
import defaultProfileImgPic from "../../Assets/Images/thumbnail.jpg";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import ImageColumnComponent from "../../Components/LMS/ImageColumnComponent/ImageColumnComponent";
import { Badge, Modal, Table } from "react-bootstrap";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import notify from "../../Helpers/ToastNotification";
import "./journRegs.css";
import LMSDynamicGrid from "../../Components/LMSDynamicGrid/LMSDynamicGrid";

const SessionAccordion = (props) => {
  const [accordion, setAccordion] = useState(false);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { state } = useLocation();

  const [profileImg, setProfileImg] = useState(null);

  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();

  const [contents, setContents] = useState([]);

  const getContent = async (sessionId) => {
    showLoader();
    const res = await APICall(GetSessionContent, "POST", {
      SessionId: sessionId,
      Mode: "LISTCONTENTADDED",
      CreatedBy: userDetails.Id,
      LaunchId: state.launchId,
    });

    if (res.status === 0 && res.data.length > 0) {
      const contentData = res.data;

      for (let index = 0; index < contentData.length; index++) {
        const content = contentData[index];

        content.thumbnailUrl =
          content.thumbnailUrl !== null
            ? await getThumbnail(content.thumbnailUrl)
            : null;
      }

      setContents(contentData);
    }

    hideLoader();
  };

  const getThumbnail = async (thumbnailUrl) => {
    const res = await APICall(GetThumbnailDetails, "POST", {
      ThumbnailUrl: thumbnailUrl,
    });

    if (res.status === 0) {
      return `data:image;base64,${res.data.thumbnailDataObject}`;
    }
  };

  useEffect(() => {
    if (accordion) {
      getContent(props.currentSession.sessionId);

      if (props.currentSession.thumbnailUrl) {
        (async () => {
          const res = await getThumbnail(props.currentSession.thumbnailUrl);

          setProfileImg(res);
        })();
      }
    }
  }, [accordion]);

  const [currentSessionGridData, setCurrentSessionGridData] = useState([]);

  const sessionColumns = [
    {
      name: "sessionId",
      label: "Session ID",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "sessionTitle",
      label: "Session Title",
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: "sessionType",
      label: "Session Type",
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: "duration",
      label: "Duration",
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: "sessionObjective",
      label: "Session Objective",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <span
              dangerouslySetInnerHTML={{
                __html: value,
              }}></span>
          );
        },
      },
    },
  ];

  useEffect(() => {
    if (props.currentSession) {
      const gridData = [
        {
          sessionId: props.currentSession.sessionCode,
          sessionTitle: props.currentSession.sessionTitle,
          sessionType: props.currentSession.sessionType,
          duration: `${
            props.currentSession.totalDurationHours ?? "00"
          }  hrs : ${props.currentSession.totalDurationMinutes ?? "00"} min`,
          sessionObjective: props.currentSession.sessionObjective,
        },
      ];

      setCurrentSessionGridData(gridData);
    }
  }, [props.currentSession]);

  return (
    <Accordion
      elevation={0}
      className="mb-3"
      expanded={accordion}
      onChange={() => setAccordion((prev) => !prev)}>
      <AccordionSummary
        id="profile-accordion"
        style={{ background: "#3C5464" }}
        className="text-white acc_close"
        expandIcon={<ExpandMore />}>
        <div className="row d-flex align-items-center acc-heading">
          <div className="col-lg-10 col-md-10 col-sm-12">
            <p style={{ fontSize: "16px" }}>
              {props.currentSession.sessionTitle} -{" "}
              {/* {props.currentSession.sessionCode} -{" "} */}
              {moment(props.currentSession.sessionStartDate).format(
                "DD-MMM-YYYY hh:mm"
              )}{" "}
              -{" "}
              {moment(props.currentSession.sessionEndDate).year() === 9999
                ? "N/A"
                : moment(props.currentSession.sessionEndDate).format(
                    "DD-MMM-YYYY hh:mm"
                  )}
            </p>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails className="">
        <div className="row">
          <div className="col-lg-2">
            <div className="card">
              <img
                src={profileImg != null ? profileImg : defaultProfileImgPic}
                className="card-img-top"
                alt="Thumbnail Image"
              />
            </div>
          </div>
          <div className="col-lg-10 fnt_we">
            <div className="row">
              <div className="col-lg-12 p-0">
                {/* <div className="col-lg-4">
                <p>
                  <b className="wid_obj">
                    <div className="lable_width ">Session ID</div> :
                  </b>{" "}
                  {props.currentSession.sessionCode}
                </p>
              </div>
              <div className="col-lg-4">
                <p>
                  <b className="wid_obj">
                    <div className="lable_width ">Session Title</div> :
                  </b>{" "}
                  {props.currentSession.sessionTitle}
                </p>
              </div>
              <div className="col-lg-4">
                <p>
                  <b className="wid_obj">
                    <div className="lable_width ">Session Type</div> :
                  </b>{" "}
                  {props.currentSession.sessionType}
                </p>
              </div>
              <div className="col-lg-4">
                <p>
                  <b className="wid_obj">
                    <div className="lable_width "> Duration</div> :
                  </b>{" "}
                  {props.currentSession.totalDurationHours ?? "00"} hrs :{" "}
                  {props.currentSession.totalDurationMinutes ?? "00"} min
                </p>
              </div>
              <div className="col-lg-8 col-md-6 col-sm-4">
                <p>
                  <b className="wid_obj">
                    {" "}
                    <div className="lable_width"> Session Objective </div> :
                  </b>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: props.currentSession.sessionObjective,
                    }}></span>
                </p>
              </div> */}
                <LMSDynamicGrid
                  data={currentSessionGridData}
                  columns={sessionColumns}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            {contents.length > 0 ? (
              <>
                <p className="mt-3 mb-3" style={{ color: "#2C2B7C" }}>
                  <b
                    className="rounded px-3 py-2"
                    style={{ backgroundColor: "#e7e7e7" }}>
                    Content
                  </b>
                </p>
                {/* <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th className="text-dark">Thumbnail</th>
                      <th className="text-dark">Title</th>
                      <th className="text-dark">Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contents.map((content) => (
                      <tr>
                        <td>
                          <div style={{ maxWidth: "100px" }} className="card">
                            <img
                              src={
                                content?.thumbnailUrl != null
                                  ? content?.thumbnailUrl
                                  : defaultProfileImgPic
                              }
                              className="card-img-top"
                              alt="Thumbnail Image"
                            />
                          </div>
                        </td>
                        <td>{content?.contentTitle}</td>
                        <td>{content?.contentType}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table> */}
                <div className="row">
                  <div className="col-lg-12 p-0">
                    <ContentGrid contents={contents} />
                  </div>
                </div>
              </>
            ) : (
              <h4>No content available</h4>
            )}
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const ContentGrid = ({ contents }) => {
  const { state } = useLocation();

  const [contentGridData, setContentGridData] = useState<any>(contents);

  const contentColumns = [
    {
      name: "thumbnailUrl",
      label: "Thumbnail",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={{ maxWidth: "100px" }} className="card">
              <img
                src={value != null ? value : defaultProfileImgPic}
                className="card-img-top"
                alt="Thumbnail Image"
              />
            </div>
          );
        },
      },
    },
    {
      name: "contentTitle",
      label: "Title",
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: "contentType",
      label: "Type",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  return <LMSDynamicGrid data={contentGridData} columns={contentColumns} />;
};

const CourseAccordion = ({
  courseData,
  setJourneyScheduleData,
  setCurrentCourseScheduleId,
  journeyScheduleData,
  index,
}) => {
  const { state } = useLocation();

  const [courseGridData, setCourseGridData] = useState([]);

  useEffect(() => {
    if (courseData) {
      let gridData = [
        {
          courseId: courseData.courseCode,
          courseTitle: courseData.courseTitle,

          startDate: moment(courseData.courseStartDate).format("DD-MMM-YYYY"),
          endDate:
            moment(courseData.courseEndDate).year() === 9999
              ? "N/A"
              : moment(courseData.courseEndDate).format("DD-MMM-YYYY"),

          trainingCenter: courseData.trainingCenter,
          trainerName: courseData.trainerName,
          coordinatorName: courseData.coordinatorName,
          courseDescription: courseData.courseDescription,
        },
      ];

      setCourseGridData(gridData);
    }
  }, [courseData]);

  const courseColumns = [
    {
      name: "courseId",
      label: "Course ID",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "courseTitle",
      label: "Course Title",
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: "startDate",
      label: "Start Date",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "endDate",
      label: "End Date",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "trainingCenter",
      label: "Training Center",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "trainerName",
      label: "Trainer",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "coordinatorName",
      label: "Co-ordinator",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "courseDescription",
      label: "Course Objective",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <span
              dangerouslySetInnerHTML={{
                __html: value,
              }}></span>
          );
        },
      },
    },
  ];

  return (
    <>
      <div className="col-lg-12">
        <Accordion elevation={0} className="mb-3">
          <AccordionSummary
            id="profile-accordion"
            style={{ background: "#3C5464" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}>
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <p style={{ fontSize: "16px" }}>
                  <Tooltip
                    title={
                      courseData.validationMessage !== ""
                        ? courseData.validationMessage
                        : ""
                    }>
                    <span>
                      <input
                        disabled={
                          state && state.isManager
                            ? courseData.canAssign && courseData.enableRegister
                              ? false
                              : true
                            : courseData.enableRegister &&
                              courseData.canRegister
                            ? false
                            : true
                        }
                        type="checkbox"
                        onChange={(e) => {
                          e.stopPropagation();
                          setJourneyScheduleData((prevArray) => {
                            return prevArray.map((item, i) => {
                              if (i === index) {
                                return {
                                  ...item,
                                  checked: e.target.checked,
                                };
                              }
                              return item;
                            });
                          });
                        }}
                        checked={journeyScheduleData[index].checked}
                      />
                    </span>
                  </Tooltip>

                  <span>
                    {"  "}
                    {courseData.courseTitle}
                    {/* - {courseData.courseCode} */}
                  </span>
                </p>
              </div>
              <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12">
                <Tooltip title="View Sessions">
                  <button
                    className="btn"
                    disabled={false}
                    onClick={(e) => {
                      e.stopPropagation();
                      setCurrentCourseScheduleId(courseData.courseScheduleId);
                    }}>
                    <i className="fas fa-eye"></i>
                  </button>
                </Tooltip>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails className="">
            <div className="row">
              <div className="col-lg-2">
                <div className="card">
                  <ImageColumnComponent
                    link={courseData.courseThumbnail}
                    width="auto"
                  />
                </div>
              </div>
              <div className="col-lg-10">
                <div className="row fnt_we">
                  <div className="col-lg-12 p-0">
                    {/* <div className="col-lg-6">
                    <p>
                      <b className="wid_obj">
                        <div className="lable_width ">Course ID</div> :
                      </b>{" "}
                      {courseData.courseCode}
                    </p>
                  </div>
                  <div className="col-lg-6">
                    <p>
                      <b className="wid_obj">
                        <div className="lable_width ">Course Title</div> :
                      </b>{" "}
                      {courseData.courseTitle}
                    </p>
                  </div>

                  <div className="col-lg-6">
                    <b className="wid_obj">
                      <div className="lable_width ">Start Date</div> :
                    </b>{" "}
                    {moment(courseData.courseStartDate).format("DD-MMM-YYYY")}
                  </div>

                  <div className="col-lg-6">
                    <b className="wid_obj">
                      <div className="lable_width ">End Date</div> :
                    </b>{" "}
                    {moment(courseData.courseEndDate).year() === 9999
                      ? "N/A"
                      : moment(courseData.courseEndDate).format("DD-MMM-YYYY")}
                  </div>

                  <div className="col-lg-6">
                    <b className="wid_obj">
                      <div className="lable_width ">Training Center</div> :
                    </b>{" "}
                    {courseData.trainingCenter}
                  </div>

                  <div className="col-lg-6">
                    <b className="wid_obj">
                      <div className="lable_width ">Trainer</div> :
                    </b>{" "}
                    {courseData.trainerName}
                  </div>

                  <div className="col-lg-6">
                    <b className="wid_obj">
                      <div className="lable_width ">Co-ordinator</div> :
                    </b>{" "}
                    {courseData.coordinatorName}
                  </div>
                  <div className="col-lg-12">
                    <p>
                      <b className="wid_obj">
                        {" "}
                        <div className="lable_width">Course Objective</div> :
                      </b>{" "}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: courseData.courseDescription,
                        }}></span>
                    </p>
                  </div> */}
                    <LMSDynamicGrid
                      data={courseGridData}
                      columns={courseColumns}
                    />
                  </div>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};

const JourneyRegistration = () => {
  const navigate = useNavigate();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  const [accordion, setAccordion] = useState(true);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { state } = useLocation();
  const [journeyScheduleData, setJourneyScheduleData] = useState([]);
  const [currentCourseScheduleId, setCurrentCourseScheduleId] = useState(null);
  const [sessionsArr, setSessionsArr] = useState([]);

  const getJourneyScheduleDetails = async () => {
    debugger;
    const res = await APICall(GetProgramScheduleDetails, "POST", {
      ProgramId: state.programId,
      LaunchId: state.launchId,
      IsManager: state.isManager,
      UserId: state.isManager ? state.userId : userDetails.Id,
    });

    debugger;

    if (res.status === 0 && res.data !== null && res.data.length > 0) {
      let data = res.data.map((ele) => ({ ...ele, checked: false }));

      setJourneyScheduleData(data);
    } else {
      console.log(res);
    }
  };

  useEffect(() => {
    getJourneyScheduleDetails();
  }, []);

  const getCourseScheduleData = async (currentCourseScheduleId) => {
    debugger;
    const res = await APICall(getCourseScheduleDetails, "POST", {
      CourseScheduleId: currentCourseScheduleId,
      LaunchId: state.launchId,
    });

    debugger;

    if (res.status === 0 && res.data !== null && res.data.length > 0) {
      setSessionsArr(res.data);
    }
  };

  useEffect(() => {
    debugger;
    if (currentCourseScheduleId) {
      getCourseScheduleData(currentCourseScheduleId);
    }
  }, [currentCourseScheduleId]);

  const createRequest = async () => {
    debugger;
    if (journeyScheduleData.some((data) => data.checked === true)) {
      const requests = [];
      for (let index = 0; index < journeyScheduleData.length; index++) {
        showLoader();
        debugger;
        const data = journeyScheduleData[index];

        if (data.checked) {
          // requests.push(
          //   new Promise((resolve, reject) => {
          //     const res = APICall(createRegistrationRequest, "POST", {
          //       UserId: state.isManager ? state.userId : userDetails.Id,
          //       RequestType: "IN",
          //       CourseScheduleId: data.courseScheduleId,
          //       IsManager: state.isManager,
          //       CreatedBy: userDetails.Id,
          //     });

          //     res.then((response) => response).then((data) => resolve(data));
          //   })
          // );

          const res = await APICall(createRegistrationRequest, "POST", {
            UserId: state.isManager ? state.userId : userDetails.Id,
            RequestType: "IN",
            CourseScheduleId: data.courseScheduleId,
            IsManager: state.isManager,
            CreatedBy: userDetails.Id,
          });
          debugger;
        }
      }
      hideLoader();
      navigate("/Catalogue");
    } else {
      notify(1, "Please select at least one course");
    }
  };

  const [sessionMainAcc, setSessionMainAcc] = useState(true);

  const [journeyGridData, setJourneyGridData] = useState([]);

  const journeyColumns = [
    {
      name: "programCode",
      label: "Journey ID",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "programTitle",
      label: "Journey Title",
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: "programDescription",
      label: "Journey Description",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <span
              dangerouslySetInnerHTML={{
                __html: value,
              }}></span>
          );
        },
      },
    },
  ];

  useEffect(() => {
    if (journeyScheduleData.length > 0) {
      const gridData = [
        {
          programCode: journeyScheduleData[0].programCode,
          programTitle: journeyScheduleData[0].programTitle,
          programDescription: journeyScheduleData[0].programDescription,
        },
      ];
      setJourneyGridData(gridData);
    }
  }, [journeyScheduleData]);

  return (
    <div className="container-fluid mt-3 mb-5">
      <div className="row d-flex justify-content-end">
        <button
          onClick={() => {
            navigate(-1);
          }}
          disabled={false}
          className="btn btn-secondary mr-3">
          <i className="fa-solid fa-arrow-left"></i> Back
        </button>
      </div>
      <div className="row mt-2">
        <div className="col-lg-12">
          {journeyScheduleData && journeyScheduleData.length > 0 && (
            <Accordion
              elevation={0}
              className="mb-3"
              expanded={accordion}
              onChange={() => setAccordion((prev) => !prev)}>
              <AccordionSummary
                id="profile-accordion"
                style={{ background: "#3C5464" }}
                className="text-white acc_close"
                expandIcon={<ExpandMore />}>
                <div className="row d-flex align-items-center acc-heading">
                  <div className="col-lg-10 col-md-10 col-sm-12">
                    <p style={{ fontSize: "16px" }}>
                      {journeyScheduleData[0].programTitle}
                      {/* -{" "}
                      {journeyScheduleData[0].programCode} */}
                    </p>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails className="">
                <div className="row">
                  <div className="col-lg-2">
                    <div className="card">
                      <ImageColumnComponent
                        link={journeyScheduleData[0].programThumbnail}
                        width="auto"
                      />
                    </div>
                  </div>
                  <div className="col-lg-10">
                    <div className="row">
                      <div className="col-lg-12 p-0">
                        {/* <div className="col-lg-6">
                        <p>
                          <b className="wid_obj">
                            <div className="lable_width ">Journey ID</div> :
                          </b>{" "}
                          {journeyScheduleData[0].programCode}
                        </p>
                      </div>
                      <div className="col-lg-6">
                        <p>
                          <b className="wid_obj">
                            <div className="lable_width ">Journey Title</div> :
                          </b>{" "}
                          {journeyScheduleData[0].programTitle}
                        </p>
                      </div>
                      <div className="col-lg-12">
                        <p>
                          <b className="wid_learn">
                            <div className=" lable_width wid_learn">
                              Journey Description
                            </div>{" "}
                            :
                          </b>{" "}
                          <span
                            dangerouslySetInnerHTML={{
                              __html: journeyScheduleData[0].programDescription,
                            }}></span>
                        </p>
                      </div> */}
                        <LMSDynamicGrid
                          data={journeyGridData}
                          columns={journeyColumns}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          )}
        </div>
      </div>

      <div className="row mt-2">
        {journeyScheduleData &&
          journeyScheduleData.length > 0 &&
          journeyScheduleData.map((courseData, index) => (
            <CourseAccordion
              courseData={courseData}
              setCurrentCourseScheduleId={setCurrentCourseScheduleId}
              setJourneyScheduleData={setJourneyScheduleData}
              journeyScheduleData={journeyScheduleData}
              index={index}
            />
          ))}
      </div>

      {state && state?.action === "view" ? (
        <>
          <hr />
          <h5 className="text-right text-danger">
            Sorry registration has closed
          </h5>
        </>
      ) : (
        <>
          <div className="row d-flex justify-content-end">
            <button
              onClick={() => {
                setShowConfirmationModal(true);
              }}
              disabled={false}
              className="btn btn-secondary mr-3">
              {state && state.isManager ? "Assign" : "Send for approval"}
            </button>
          </div>
        </>
      )}

      <div className="row mt-3">
        <div className="col-lg-12">
          {sessionsArr.length > 0 && (
            <>
              <Accordion
                elevation={0}
                className="mb-3"
                expanded={sessionMainAcc}
                onChange={() => setSessionMainAcc((prev) => !prev)}>
                <AccordionSummary
                  id="profile-accordion"
                  style={{ background: "#3C5464" }}
                  className="text-white acc_close"
                  expandIcon={<ExpandMore />}>
                  <div className="row d-flex align-items-center acc-heading">
                    <div className="col-lg-10 col-md-10 col-sm-12">
                      <p style={{ fontSize: "16px" }}>Session</p>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails className="page_heading">
                  {sessionsArr.map((currentSession, sessionIndex) => (
                    <SessionAccordion
                      currentSession={currentSession}
                      sessionIndex={sessionIndex}
                      sessionsArr={sessionsArr}
                      setSessionsArr={setSessionsArr}
                    />
                  ))}
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </div>
      </div>
      <Modal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">Confirmation!</div>
        </div>
        <Modal.Body>
          <p>Are you sure you want to send request for approval?</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowConfirmationModal(false);
              createRequest();
            }}>
            Yes
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowConfirmationModal(false);
            }}>
            No
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default JourneyRegistration;
