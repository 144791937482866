import React from "react";

import { components } from "react-select";

import Select from "react-select";

import AsyncSelect from "react-select/async";
import { SelectFormMultiHover } from "./SelectFormMultiHover";
import uuid from "react-uuid";
import { isEmpty } from "../../Pages/AnonymousCandidate/Components/ResumeSection";

// color style func for react select
// color style func for react select
const reactSelectTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: "dimgrey",
  },
});

// height of react select
const reactSelectStyle = {
  valueContainer: (provided, state) => ({
    ...provided,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    flexWrap: "nowrap",
  }),
  control: (base) => ({
    ...base,
    height: 34,
    minHeight: 34,
    boxShadow: "none",
    // marginBottom: 5,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    paddingTop: 2,
    paddingBottom: 2,
  }),
  input: (base) => ({
    ...base,
    height: 34,
    minHeight: 34,
    paddingTop: 2,
    paddingBottom: 2,
    margin: -5,
    marginLeft: 3,
  }),
  clearIndicator: (base) => ({
    ...base,
    paddingTop: 2,
    paddingBottom: 2,
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

// N items selected custom
const ValueContainer = ({ children, ...props }: any) => {
  let [values, input] = children;
  if (Array.isArray(values)) {
    if (values.length === 1) {
      values = values[0].props.data.label;
    } else if (!isEmpty(props?.selectProps?.pluralPlaceHolder)) {
      const plural = values.length === 1 ? "" : "s";
      values = `${values.length} ${
        props?.selectProps?.pluralPlaceHolder ?? "Not Found"
      }${plural} selected`;
    } else {
      const plural = values.length === 1 ? "" : "s";
      values = `${values.length} item${plural} selected`;
    }
  }
  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  );
};

const InputOption = (props) => {
  return props.isMulti === true ? (
    <components.Option {...props}>
      <input type="checkbox" checked={props.isSelected} onChange={() => null} />{" "}
      <label>{props.label}</label>
    </components.Option>
  ) : (
    <components.Option {...props}>
      <label>{props.label}</label>
    </components.Option>
  );
};

const SelectForm = ({
  id = "",
  label = "",
  value,
  placeholder,
  options,
  isMulti = false,
  isSearchable = true,
  isClearable = false,
  async = false,
  maxWidth = "20",
  noIndicator = true,
  noSeparator = true,
  hideSelectedOptions = false,
  isDisabled = false,
  defaultValue = [] || {},
  cacheOptions = false,
  onChange,
  ...rest
}) => {
  return (
    <>
      {async ? (
        <>
          <AsyncSelect
            id="disabled_text"
            placeholder={placeholder}
            isMulti={isMulti}
            value={value}
            menuPortalTarget={document.body}
            theme={reactSelectTheme}
            loadOptions={options}
            styles={reactSelectStyle}
            components={
              noIndicator
                ? {
                    ValueContainer,
                    DropdownIndicator: null,
                    Option: InputOption,
                  }
                : noSeparator
                ? {
                    ValueContainer,
                    IndicatorSeparator: null,
                    Option: InputOption,
                  }
                : {
                    ValueContainer,
                    Option: InputOption,
                  }
            }
            isSearchable={isSearchable}
            isDisabled={isDisabled}
            cacheOptions={cacheOptions}
            maxMenuHeight={200}
            isClearable={isClearable}
            closeMenuOnSelect={isMulti ? false : true}
            hideSelectedOptions={hideSelectedOptions}
            defaultValue={defaultValue}
            onChange={onChange}
            {...rest}
          />

          {isMulti && value && value.length > 1 && (
            <SelectFormMultiHover data={value} />
          )}
        </>
      ) : (
        <>
          <Select
            id={uuid().toString()}
            options={options}
            isMulti={isMulti}
            placeholder={placeholder}
            value={value}
            menuPortalTarget={document.body}
            theme={reactSelectTheme}
            styles={reactSelectStyle}
            components={
              noIndicator
                ? {
                    ValueContainer,
                    DropdownIndicator: null,
                    Option: InputOption,
                  }
                : noSeparator
                ? {
                    ValueContainer,
                    IndicatorSeparator: null,
                    Option: InputOption,
                  }
                : {
                    ValueContainer,
                    Option: InputOption,
                  }
            }
            isSearchable={isSearchable}
            isDisabled={isDisabled}
            maxMenuHeight={200}
            isClearable={isClearable}
            closeMenuOnSelect={isMulti ? false : true}
            hideSelectedOptions={hideSelectedOptions}
            defaultValue={defaultValue}
            onChange={onChange}
            {...rest}
          />
          {isMulti && value && value.length > 1 && (
            <SelectFormMultiHover data={value} />
          )}
        </>
      )}
    </>
  );
};

export default SelectForm;
