import React, { useState, useEffect, useContext } from "react";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { useNavigate } from "react-router-dom";
import SelectForm from "../../Components/SelectForm/SelectForm";
import Collapse from "react-bootstrap/Collapse";
import { APICall } from "../../Helpers/APICalls";
import {
  getContentDasboardFilterData,
  contentDashboardList,
  DeactivateProgramDetailById,
  deactivateContentDetailById,
  getContentUrlDetails,
  GetThumbnailDetails,
  getUserAndRoleWiseAccess,
  GetConfigDataCallForScromProject,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import InputForm from "../../Components/InputForm/InputForm";
import { Tooltip } from "@mui/material";
import { LoaderContext } from "../../Helpers/Context/Context";
import notify from "../../Helpers/ToastNotification";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { Modal } from "react-bootstrap";
import ImageColumnComponent from "../../Components/LMS/ImageColumnComponent/ImageColumnComponent";
import "./content.css";
import "./audi_def.jpg";
import PdfViewer from "../../Components/PdfViewer/PdfViewer";
import ReactPlayer from "react-player";
import axios from "axios";

const Content = () => {
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [PageSize, setPageSize] = useState(10);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  const [sortDirection, setSortDirection] = useState("");
  const [sortColumn, setSortColumn] = useState("");

  const [reSet, setreSet] = useState(false);

  const [contentId, setContentId] = useState([]);
  const [contentIdOptions, setContentIdOptions] = useState([]);
  const [contentTitle, setContentTitle] = useState([]);
  const [contentTitleOptions, setContentTitleOptions] = useState([]);
  const [contentType, setContentType] = useState([]);
  const [contentTypeOptions, setContentTypeOptions] = useState([]);
  const [createdBy, setCreatedBy] = useState([]);
  const [createdByOptions, setCreatedByOptions] = useState([]);

  const [status, setStatus] = useState(null);

  const [remarks, setRemarks] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [id, setId] = useState(null);
  const [IsActive, setIsActive] = useState(false);

  const [contentName, setContentName] = useState("defaultImage");

  const [contentUrl, setContentUrl] = useState("");

  const [thumbnailDataObject, setThumbnailDataObject] = useState("");

  const [SCROMUserId, setSCROMUserId] = useState(null);
  const [SCROMPassword, setSCROMPassword] = useState(null);
  const [SCROMAPIUrl, setSCROMAPIUrl] = useState(null);

  const [showScromPlayerModel, setShowScromPlayerModel] = useState(false);

  const [statusOptions, setStatusOptions] = useState([
    { value: 1, label: "Active" },
    { value: 0, label: "Inactive" },
  ]);

  const contentObj = {
    Scrome: "SCROME",
    Audio: "AUDIO",
    Pdf: "PDF",
    Link: "LINK",
    VideoLink: "VIDEOLINK",
    MP4: "MP4",
  };

  interface userAndRoleWiseAccess {
    isView?: boolean;
    isEdit?: boolean;
    isDelete?: boolean;
  }

  const [userAndRoleWiseData, setUserAndRoleWiseData] =
    useState<userAndRoleWiseAccess>({});

  const getUserAndRoleWiseAccessApiCall = async () => {
    const response = await APICall(getUserAndRoleWiseAccess, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      InterfaceName: "Content_Dashboard",
    });

    if (response?.status === 0) {
      setUserAndRoleWiseData(response?.data);
    } else {
      notify(response?.status, response?.message);
    }
  };

  useEffect(() => {
    (async () => {
      await GetConfigDataCallForScromProjectApiCall();
    })();
  }, []);

  const GetConfigDataCallForScromProjectApiCall = async () => {
    try {
      showLoader();
      const response = await APICall(GetConfigDataCallForScromProject, "POST", {
        UserId: userDetails?.Id,
        RoleID: currentRoleId?.value,
      });

      if (response.status === 0) {
        setSCROMUserId(response?.data?.scromUserId);
        setSCROMPassword(response?.data?.scromPassword);
        setSCROMAPIUrl(response?.data?.scromapiUrl);
      } else {
        hideLoader();
      }
    } catch (error) {
      console.error("Error :", error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    const responseData = async () => {
      const objContentId = {
        mode: "CONTENTCODE",
        userId: userDetails?.Id,
        roleId: currentRoleId?.value,
      };
      const res = await APICall(
        getContentDasboardFilterData,
        "POST",
        objContentId
      );
      if (res.data && res.data.length > 0) {
        setContentIdOptions(res.data);
      }

      const objContentTitle = {
        mode: "CONTENTTITLE",
        userId: userDetails?.Id,
        roleId: currentRoleId?.value,
      };
      const res1 = await APICall(
        getContentDasboardFilterData,
        "POST",
        objContentTitle
      );
      if (res1.data && res1.data.length > 0) {
        setContentTitleOptions(res1.data);
      }

      const objCreatedBy = {
        mode: "CREATEDBY",
        userId: userDetails?.Id,
        roleId: currentRoleId?.value,
      };
      const res2 = await APICall(
        getContentDasboardFilterData,
        "POST",
        objCreatedBy
      );
      if (res2.data && res2.data.length > 0) {
        setCreatedByOptions(res2.data);
      }

      const objContentType = {
        mode: "CONTENTTYPE",
        userId: userDetails?.Id,
        roleId: currentRoleId?.value,
      };
      const res3 = await APICall(
        getContentDasboardFilterData,
        "POST",
        objContentType
      );
      if (res3.data && res3.data.length > 0) {
        setContentTypeOptions(res3.data);
      }
    };

    responseData();
    getUserAndRoleWiseAccessApiCall();
  }, []);

  useEffect(() => {
    getContentDasboardDetailsAPICall();
  }, [page, PageSize, sortColumn, sortDirection, searchText, reSet]);

  const getContentDasboardDetailsAPICall = async () => {
    showLoader();
    const GetDashboardData = await APICall(contentDashboardList, "POST", {
      Page: page,
      PageSize: PageSize,
      SortColumn: sortColumn,
      SortOrder: sortDirection,
      SearchText: searchText,
      ContentCode: contentId?.map((s) => s.value).join(","),
      ContentTitle: contentTitle?.map((s) => s.value).join(","),
      ContentCreatedBy: createdBy?.map((s) => s.value).join(","),
      ContentType: contentType?.map((s) => s.value).join(","),
      ContentIsActive: status?.value,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });
    if (
      GetDashboardData &&
      GetDashboardData.data !== null &&
      GetDashboardData.data.length > 0
    ) {
      setRowData(GetDashboardData.data);
      setCount(GetDashboardData.data[0].count);
    } else {
      setRowData([]);
      setCount(0);
    }
    hideLoader();
  };

  const activateandDeactivateFunction = async (id: any, isActive: any) => {
    if (remarks === "" || remarks === null) {
      notify(1, "Please enter remarks");
    } else {
      showLoader();
      const { data } = await APICall(deactivateContentDetailById, "POST", {
        Id: id,
        IsActive: isActive,
        Remarks: remarks,
        CreatedBy: userDetails?.Id,
      });

      setShowModal(false);
      setRemarks("");
      if (data != null) {
        if (!isActive) {
          notify(0, "Content has been successfully Activated");
        } else {
          notify(0, "Content has been successfully Deactivated");
        }
        setreSet(!reSet);
        hideLoader();
      } else {
        notify(1, "Something went wrong");
      }
      hideLoader();
    }
  };

  const showActivateandDeactivateModal = async (id: any, isActive: any) => {
    setShowModal(true);
    setId(id);
    if (isActive) {
      setModalTitle("Do you want to Inactive the Content?");
    } else {
      setModalTitle("Do you want to active the Content?");
    }
    setIsActive(isActive);
  };

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: PageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      setPage(page * PageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };
  const gridColumns = [
    {
      name: "Id",
      label: "Id",
      options: {
        display: false,
      },
    },

    {
      name: "thumbnailUrl",
      label: "Thumbnail",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: false,
        customBodyRender: (value, tableMeta) => {
          return <ImageColumnComponent link={value} />;
        },
      },
    },
    {
      name: "contentCode",
      label: "Content ID",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "contentTitle",
      label: "Content Title",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "contentType",
      label: "Content Type",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "displayUrl",
      label: "Content",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta) => {
          let id = tableMeta.tableData[tableMeta.rowIndex].id;
          let isActive = tableMeta.tableData[tableMeta.rowIndex].isActive;
          let contentUrl = tableMeta.tableData[tableMeta.rowIndex].contentUrl;
          let contentName = tableMeta.tableData[tableMeta.rowIndex].contentName;
          let contentTypeCode =
            tableMeta.tableData[tableMeta.rowIndex].contentTypeCode;
          let color = isActive ? "green" : "red";
          let activate = isActive ? "Active" : "Inactive";
          let thumbnailUrl =
            tableMeta.tableData[tableMeta.rowIndex].thumbnailUrl;

          let isError = tableMeta.tableData[tableMeta.rowIndex].isError;
          let errorMessage =
            tableMeta.tableData[tableMeta.rowIndex].errorMessage;

          let scromCourseId =
            tableMeta.tableData[tableMeta.rowIndex].scromCourseId;

          return (
            <div style={{ width: "100px" }}>
              {userAndRoleWiseData?.isView &&
                contentUrl &&
                (contentTypeCode === contentObj.Scrome ? (
                  <Tooltip title={isError ? errorMessage : "Content View"}>
                    <a
                      className="mr-2"
                      style={{ fontSize: "15px" }}
                      onClick={(e) => {
                        e.preventDefault();
                        if (!isError) {
                          setContentName(contentTypeCode);

                          OpenScrominNewTab(scromCourseId);
                        }
                      }}
                    >
                      <i className="fas fa-eye"></i>
                    </a>
                  </Tooltip>
                ) : (
                  <Tooltip title={isError ? errorMessage : "Content View"}>
                    <a
                      className="mr-2"
                      style={{ fontSize: "15px" }}
                      onClick={(e) => {
                        e.preventDefault();
                        if (!isError) {
                          showContentInModel(
                            contentUrl,
                            contentTypeCode,
                            thumbnailUrl
                          );
                        }
                      }}
                    >
                      <i className="fas fa-eye"></i>
                    </a>
                  </Tooltip>
                ))}

              {userAndRoleWiseData?.isEdit && (
                <>
                  <Tooltip title={isError ? errorMessage : "Edit"}>
                    <a
                      className="mr-2"
                      style={{ fontSize: "15px" }}
                      onClick={(e) => {
                        e.preventDefault();
                        if (!isError) {
                          navigate("/Contentedit", {
                            state: { contentId: value, isDisable: false },
                          });
                        }
                      }}
                    >
                      <i className="fas fa-edit"></i>
                    </a>
                  </Tooltip>
                  <Tooltip title={isError ? errorMessage : activate}>
                    <a
                      className="mr-2"
                      style={{ fontSize: "15px" }}
                      onClick={() => {
                        if (!isError) {
                          showActivateandDeactivateModal(id, isActive);
                        }
                      }}
                    >
                      <i
                        className="fa-solid fa-circle"
                        style={{ color: color }}
                      ></i>
                    </a>
                  </Tooltip>
                </>
              )}

              {!userAndRoleWiseData?.isEdit && userAndRoleWiseData?.isView && (
                <Tooltip title={isError ? errorMessage : "View"}>
                  <a
                    className="mr-2"
                    style={{ fontSize: "15px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!isError) {
                        navigate("/Contentedit", {
                          state: { contentId: value, isDisable: true },
                        });
                      }
                    }}
                  >
                    <i className="fas fa-eye"></i>
                  </a>
                </Tooltip>
              )}
            </div>
          );
        },
      },
    },
  ];

  const getJWTokenApiCall = async () => {
    try {
      let headersList = {
        Accept: "*/*",
        // "User-Agent": "Thunder Client (https://www.thunderclient.com)",
        "Content-Type": "application/json",
      };

      let bodyContent = JSON.stringify({
        Name: SCROMUserId,
        password: SCROMPassword,
      });

      let reqOptions = {
        url: `${SCROMAPIUrl}/APILogin/GenerateJWToken`,
        method: "POST",
        headers: headersList,
        data: bodyContent,
      };

      let response = await axios.request(reqOptions);

      let { Data, Status, Message } = response.data;
      if (Status === 0 && (Data?.Token !== null || Data?.Token !== "")) {
        return Data?.Token;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error : ", error);
      return null;
    }
  };

  const OpenScrominNewTab = async (scromCourseId) => {
    try {
      showLoader();
      const userAuthToken = await getJWTokenApiCall();

      if (userAuthToken === null) {
        notify(1, "AuthToken is null");
      } else {
        try {
          let formData = new FormData();

          formData.append("ScromCourseId", scromCourseId);
          formData.append("CourseScheduleId", "1");
          formData.append(
            "EmailId",
            proxyUserDetails === null
              ? userDetails?.EmailId
              : proxyUserDetails?.EmailId
          );
          formData.append("UserId", userDetails?.Id);
          formData.append(
            "EmployeeCode",
            proxyUserDetails === null
              ? userDetails?.EmployeeCode
              : proxyUserDetails?.EmployeeId
          );

          let headersList = {
            Accept: "*/*",
            // "User-Agent": "Thunder Client (https://www.thunderclient.com)",
            Authorization: "Bearer " + userAuthToken,
          };

          let bodyContent = formData;

          let reqOptions = {
            url: `${SCROMAPIUrl}/UserMaster/AddCourseToUserAndGetUrl`,
            method: "POST",
            headers: headersList,
            data: bodyContent,
          };

          let response = await axios.request(reqOptions);

          let { Data, Status, Message } = response.data;
          if (Status === 0) {
            const url = Data?.Url;

            setShowScromPlayerModel(true);
            setContentUrl(url);

            // window.open(url, "_blank");
          } else {
            notify(1, Message);
          }
        } catch (error) {
          console.error("Error : ", error);
        } finally {
          hideLoader();
        }
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  const showContentInModel = async (
    contentUrl,
    contentTypeCode,
    thumbnailUrl
  ) => {
    showLoader();
    // if (contentTypeCode === contentObj?.Scrome) {
    //   if (contentUrl) {
    //     setContentUrl(contentUrl);
    //     setContentName(contentTypeCode);
    //     setShowDocumentModal(true);
    //   }
    // } else
    if (
      contentTypeCode === contentObj?.Pdf ||
      contentTypeCode === contentObj?.Audio
      // ||contentTypeCode === contentObj?.MP4
    ) {
      if (contentUrl) {
        const getContentUrl = await APICall(getContentUrlDetails, "POST", {
          ContentUrl: contentUrl,
        });

        if (getContentUrl?.status === 0) {
          const data = getContentUrl?.data?.contentUrlDataObject;
          const name = getContentUrl?.data?.fileName;

          if (data) {
            setContentUrl(data);
            setContentName(contentTypeCode);
            setShowDocumentModal(true);
          }
        } else if (getContentUrl?.status === 1) {
          notify(getContentUrl?.status, getContentUrl?.message);
        }
      }

      if (thumbnailUrl) {
        const getThumbnailData = await APICall(GetThumbnailDetails, "POST", {
          ThumbnailUrl: thumbnailUrl,
        });

        if (getThumbnailData && getThumbnailData.data !== null) {
          setThumbnailDataObject(
            `data:image;base64,${getThumbnailData?.data?.thumbnailDataObject}`
          );
        } else {
          setThumbnailDataObject(null);
        }
      }
    } else if (contentTypeCode === contentObj?.MP4) {
      setContentUrl(contentUrl);
      setContentName(contentTypeCode);
      setShowDocumentModal(true);
    } else if (contentTypeCode === contentObj?.Link) {
      setContentUrl(contentUrl);
      setContentName(contentTypeCode);
      setShowDocumentModal(true);
    } else if (contentTypeCode === contentObj?.VideoLink) {
      setContentUrl(contentUrl);
      setContentName(contentTypeCode);
      setShowDocumentModal(true);
    }
    hideLoader();
  };

  const onSubmitFilter = () => {
    if (
      contentId.length === 0 &&
      contentTitle.length === 0 &&
      createdBy.length === 0 &&
      contentType.length === 0 &&
      status === null
    ) {
      notify(
        1,
        "Please select at least one filter option from the grid view to perform the search action."
      );
    } else {
      setPage(0);
      getContentDasboardDetailsAPICall();
    }
  };

  const resetFilters = () => {
    setPage(0);
    setSearchText("");
    setContentId([]);
    setContentTitle([]);
    setContentType([]);
    setCreatedBy([]);
    setStatus(null);

    setreSet(!reSet);
  };
  return (
    <>
      <div className="breadcrumb modified-bredcrumb">
        <span>
          <ul>
            <li>
              <a href="/home">Home</a>
            </li>
            <li>Content</li>
          </ul>
        </span>
        {userAndRoleWiseData?.isEdit && (
          <button
            onClick={() => {
              navigate("/Contentedit", { state: { contentId: 0 } });
            }}
            className="btn btn-primary mt-3"
          >
            <i className="fa fa-plus mr-2"></i>
            Create Content
          </button>
        )}
      </div>
      <div className="container-fluid">
        <div className="grid-wrapper grid_mob">
          <div className="row mx-auto filtBox">
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="form-group filtInp">
                <InputForm
                  value={searchText}
                  placeholder={"Search Keywords"}
                  isDisabled={false}
                  textArea={false}
                  onChange={(e) => {
                    setPage(0);
                    setSearchText(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-12 filtrt">
              <ul className=" filter-icon-wel">
                <li>
                  <a
                    href="javascript:void(0);"
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                    className="filter-btn"
                  >
                    <i className="fa fa-filter"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <>
          <Collapse in={open}>
            <div id="example-collapse-text grid-wrapper">
              <div className="row mx-auto myInlineStyle">
                <>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">
                      Content ID
                    </label>
                    <SelectForm
                      isClearable
                      options={contentIdOptions}
                      placeholder={"Content ID"}
                      isDisabled={false}
                      onChange={(event) => {
                        setContentId(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={contentId}
                    />
                  </div>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">
                      Content Title
                    </label>
                    <SelectForm
                      isClearable
                      options={contentTitleOptions}
                      placeholder={"Content Title"}
                      isDisabled={false}
                      onChange={(event) => {
                        setContentTitle(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={contentTitle}
                    />
                  </div>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">
                      Created By
                    </label>
                    <SelectForm
                      isClearable
                      options={createdByOptions}
                      placeholder={"Created By"}
                      isDisabled={false}
                      onChange={(event) => {
                        setCreatedBy(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={createdBy}
                    />
                  </div>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">
                      Content Type
                    </label>
                    <SelectForm
                      isClearable
                      options={contentTypeOptions}
                      placeholder={"Content Type"}
                      isDisabled={false}
                      onChange={(event) => {
                        setContentType(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={contentType}
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">Status</label>
                    <SelectForm
                      isClearable
                      options={statusOptions}
                      placeholder={"Status"}
                      isDisabled={false}
                      onChange={(event) => {
                        setStatus(event);
                      }}
                      isMulti={false}
                      noIndicator={false}
                      noSeparator={false}
                      value={status}
                    />
                  </div>
                </>
                <div className="col-lg-12 d-flex mt-2 justify-content-end">
                  <button
                    onClick={() => resetFilters()}
                    className="btn btn-secondary mr-2"
                  >
                    <i className="fa fa-undo" aria-hidden="true"></i> Reset{" "}
                  </button>
                  <button
                    className="btn btn-filter-submit"
                    onClick={() => onSubmitFilter()}
                  >
                    <i className="fa fa-search" aria-hidden="true"></i> Search{" "}
                  </button>
                </div>
              </div>
            </div>
          </Collapse>
        </>

        <div className="mb-3 pt-3">
          <DynamicGrid data={rowData} columns={gridColumns} options={options} />
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">{`${modalTitle}`}</div>
        </div>
        <Modal.Body>
          <>
            <div className="row">
              {/* <div className="col-md-12">
                <b>{`${modalTitle}`}</b>
              </div> */}
              <div className="col-md-8">
                <label className="col-form-label">Remarks</label>
                <sup>*</sup>
                <InputForm
                  value={remarks}
                  placeholder={"Remarks"}
                  isDisabled={false}
                  textArea={true}
                  onChange={(e) => {
                    setRemarks(e.target.value);
                  }}
                />
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => activateandDeactivateFunction(id, IsActive)}
          >
            Submit
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowModal(false);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      {/* Document Model*/}
      <Modal
        show={showDocumentModal}
        // dialogClassName="modal-90w"
        onHide={() => setShowDocumentModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          {/* <div className="col-lg-12">{`${modalTitle}`}</div> */}
        </div>
        <Modal.Body>
          <>
            <div className="container-fluid">
              <div className="row">
                <div className={"col-lg-12 text-center"}>
                  {/* {contentName === contentObj?.Scrome && (
                <div className="cont_img">
                  <iframe
                    // src={process.env.PUBLIC_URL + contentUrl}
                    src={contentUrl}
                    width="100%"
                    // height={resize ? "120vh" : "auto"}
                    style={{ height: "100vh" }}
                  ></iframe>
                </div>
              )} */}

                  {/* <div className="vid">
              <video src={``} controls></video>
            </div> */}

                  {/* {contentName === "defaultImage" && (
                  <div className="cont_img">
                    <img src={require("./assets/thumbnail.jpg")} />
                  </div>
                )} */}
                  {contentName === contentObj?.Pdf && (
                    <div className="pdf">
                      <PdfViewer contentUrl={contentUrl} />
                    </div>
                  )}

                  {contentName === contentObj?.Audio && (
                    <>
                      {thumbnailDataObject ? (
                        <div className="audio_img">
                          <img src={thumbnailDataObject} />
                        </div>
                      ) : (
                        <div className="audio_img">
                          <img src={require("./audi_def.jpg")} />
                        </div>
                      )}

                      <audio controls>
                        <source src={`data:audio/wav;base64,${contentUrl}`} />
                      </audio>

                      {/* <ReactPlayer
                      // className="react-player"
                      // url="https://www.youtube.com/watch?v=KwAP4RyGFCI"
                      // url={contentUrl}
                      url={`data:audio/wav;base64,${contentUrl}`}
                      controls
                      // playbackRate={2}
                      // config={{
                      //   youtube: {
                      //     playerVars: { showinfo: 1 },
                      //   },
                      // }}
                      // width="866px"
                      // height="504px"
                      onDuration={(e) => console.log("Duration", e)}
                      onProgress={(e) => console.log("Progress", e)}
                    /> */}
                    </>
                  )}

                  {contentName === contentObj?.Link && (
                    <div className="">
                      <iframe
                        src={`${contentUrl}`}
                        // src={`https://www.w3schools.com/html/html_iframe.asp`}
                        width="100%"
                        height="auto"
                        style={{ height: "100vh" }}
                      ></iframe>
                      {/* <Iframe
                      className=""
                      url="https://welprouat.welspun.com/home"
                      // url={contentUrl}
                      width="866px"
                      height="504px"
                      // display="block"
                      // position="relative"
                    />
                    <iframe
                      src="https://www.w3schools.com/html/html_iframe.asp"
                      name="iframe_a"
                      height="300px"
                      width="100%"
                      title="Iframe Example"
                    ></iframe> */}
                    </div>
                  )}
                  {contentName === contentObj?.VideoLink && (
                    <div className="player-wrapper">
                      {/* <video width="320" height="240" controls>
                      <source
                        src="D:\Santosh\Demo\newMp4Video.mp4"
                        type="video/mp4"
                      />
                    </video> */}
                      <ReactPlayer
                        className="react-player"
                        // url="https://www.youtube.com/watch?v=KwAP4RyGFCI"
                        url={contentUrl}
                        controls
                        // playbackRate={2}
                        config={{
                          youtube: {
                            playerVars: { showinfo: 1 },
                          },
                        }}
                        width="866px"
                        height="504px"
                        onDuration={(e) => console.log("Duration", e)}
                        onProgress={(e) => console.log("Progress", e)}
                      />
                    </div>
                  )}

                  {contentName === contentObj?.MP4 && (
                    // <>
                    //   <video controls>
                    //     <source src={`${contentUrl}`} />
                    //   </video>
                    // </>
                    <div className="player-wrapper">
                      <ReactPlayer
                        className="react-player"
                        url={contentUrl}
                        controls
                        config={{
                          vimeo: {
                            playerOptions: {
                              vimeo_logo: false,
                              dnt: true,
                              title: false,
                              byline: false,
                              portrait: false,
                              autoplay: false,
                              badge: false,
                              share: false,
                              like: false,
                              watchlater: false,
                            },
                          },
                        }}
                        width="866px"
                        height="504px"
                        onDuration={(e) => console.log("Duration", e)}
                        onProgress={(e) => console.log("Progress", e)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          {/* <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => activateandDeactivateFunction(id, IsActive)}
          >
            Submit
          </button> */}
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowDocumentModal(false);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      {/* Scrom Player Model*/}
      <Modal
        show={showScromPlayerModel}
        dialogClassName="modal-scrom"
        onHide={() => setShowScromPlayerModel(false)}
        backdrop="static"
        keyboard={false}
        // size="xl"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            height: "5vh",
          }}
        >
          <div className="row">
            {/* <div className="col-lg-6">{`SCROM`}</div> */}
            <div className="pr-4 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-12 col-md-12 col-sm-12">
              <Tooltip title="">
                <button
                  className="btn"
                  disabled={false}
                  onClick={() => {
                    setShowScromPlayerModel(false);
                  }}
                >
                  <i className="fa fa-close"></i>
                </button>
              </Tooltip>
            </div>
                      
          </div>
        </div>
        <Modal.Body>
          <>
            <div className="scrom-modal-body">
              {contentName === contentObj?.Scrome && (
                <div className="">
                  <iframe
                    // src={process.env.PUBLIC_URL + contentUrl}
                    src={contentUrl}
                    width="100%"
                    // height={resize ? "120vh" : "auto"}
                    style={{ height: "100vh", border: "none" }}
                  ></iframe>
                </div>
              )}
            </div>
          </>
        </Modal.Body>
        {/* <Modal.Footer>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowScromPlayerModel(false);
            }}
          >
            Cancel
          </button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default Content;
