import React, { useContext, useEffect, useState } from "react";
import "../Progression/ChangeLog.css";
import { getOUAuditLogs, getOUMappingAuditLogs } from "../../Helpers/APIEndPoints/EndPoints";
import { APICall } from "../../Helpers/APICalls";
import moment from "moment";
import { Table } from "react-bootstrap";
import { LoaderContext } from "../../Helpers/Context/Context";

// Get the local timezone offset in minutes
const localOffsetMinutes = moment().utcOffset();

function ViewHistoryOu({ OuId, OuCode }) {
  const [auditLogsObject, setAuditLogsObject] = useState<any>([]);
  const [rhsData, setRhsData] = useState<any>([]);
  const [tableData, setTableData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [transactionId, setTransactionId] = useState<any>(null);
  const [approverHistoryObj, setapproverHistoryObj] = useState<any>([]);
  const [toggleApproverHist, setToggleApproverHist] = useState(false);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const ouMappingCodes = ["M1","M2","M3","M4","M5","M6","M7","M8","M9","M10",
    "M11","M12","M13","M14","M15","M16","M17","M18","M19","M20","M21","M22"
    ,"M23","M24","M25","M26"];

  useEffect(() => {
    GetLogData();
  }, []);
  
  useEffect(() => {
    setapproverHistoryObj([]);
    setToggleApproverHist(false);
  }, [transactionId]);

  const GetLogData = async () => {
    showLoader();
    const auditLogsRes = await APICall(
        ouMappingCodes.includes(OuCode) ? getOUMappingAuditLogs : getOUAuditLogs, 
        "POST", 
        { OuId: OuId, OuCode: OuCode, }
    );

    if (auditLogsRes?.data?.length > 0) {
      let responseData = auditLogsRes?.data;

      // Group by transactionId
      const dataByTransaction = {};
      const dataWithCreatedDateMapping = {};
      responseData.forEach((item) => {
        const transactionId = item.transactionId;
        const createdDateField = moment(item.createdDate);
        if (!dataByTransaction[transactionId]) {
          dataByTransaction[transactionId] = [];
          dataWithCreatedDateMapping[transactionId] = createdDateField;
        }
        dataByTransaction[transactionId].push(item);
      });

      Object.keys(dataByTransaction).map((transactionid)=>{
        dataByTransaction[transactionid].sort((a, b) => Number(a.sequenceNoOfAtt) - Number(b.sequenceNoOfAtt));
      });
      
      // Group by recordId within each transaction group as an array of arrays
      const sortedTransactions = Object.keys(dataByTransaction)
        // .sort((a, b) => Number(b) - Number(a))
        .sort((a, b) =>
          a === null ? 1 : b === null ? -1 
          // : Number(b) - Number(a)
          : dataWithCreatedDateMapping[b] - dataWithCreatedDateMapping[a]
        ) // Sort null transactionId records last
        .map((transactionId) => {
          const transactionGroup = dataByTransaction[transactionId];
          const recordsArray = [];

          const recordsMap = {};
          transactionGroup.forEach((item) => {
            const recordId = item.recordId;
            if (!recordsMap[recordId]) {
              recordsMap[recordId] = [];
            }
            recordsMap[recordId].push(item);
          });

          for (const recordId in recordsMap) {
            if (recordsMap.hasOwnProperty(recordId)) {
              recordsArray.push(recordsMap[recordId]);
            }
          }

          return {
            transactionId,
            records: recordsArray,
          };
        });
      setTransactionId(sortedTransactions[0].transactionId ? parseInt(sortedTransactions[0].transactionId.trim()) : null);
      setTableData(sortedTransactions[0].records);
      setRhsData(sortedTransactions[0].records[0]);
      setAuditLogsObject(sortedTransactions);
    } else {
      setAuditLogsObject([]);
    }
    hideLoader();
  };

  // useEffect(() => {
  //   console.log(tableData);
  //   console.log(rhsData);
  //   console.log(auditLogsObject);
  // }, [rhsData, auditLogsObject, tableData]);

  const selectLog = async (log, index) => {
    setActiveTab(index);
    setRhsData(log[0]);
    setTableData(log);
  };

  return (
    <div>
      {auditLogsObject.length > 0 ? (
        <div className="d-flex justify-content-between">
          {/* <div className="lhs-outer-box row"> */}
          <div
            style={{
              height: "350px",
              overflowY: "auto",
              overflowX: "hidden",
              width: "35%",
            }}
            className="d-flex flex-column">
            {auditLogsObject.map((type, index) => (
              <div
                key={index}
                className={index == activeTab ? "lhs-box bg-clr" : "lhs-box"}
                onClick={() => {
                  setTransactionId(type.transactionId ? parseInt(type.transactionId.trim()) : null);
                  selectLog(type.records, index);
                }}>
                <Log auditLogsType={type.records[0]} />
              </div>
            ))}
          </div>

          <div className="rhs-box" id="rhs-box">
            {rhsData?.map((eachLog, index) => {
              return (
                <React.Fragment key={index}>
                  {index == 0 && (
                    <>
                      <div className="row">
                        <div className={"col-lg-6 col-sm-6"}>
                          <span className="text-bold">Change Date:</span>{" "}
                          {moment
                            .utc(eachLog?.createdDate)
                            .utcOffset(localOffsetMinutes)
                            .format("DD-MMM-YYYY HH:mm:ss")}
                        </div>{" "}
                        <div className={"col-lg-6 col-sm-6"}>
                          <span className="text-bold">Effective Date:</span>{" "}
                          {moment(eachLog?.effectiveDate).format("DD-MMM-YYYY")}
                        </div>
                      </div>

                      <div className="row">
                        <div className={"col-lg-6 col-sm-6"}>
                          <span className="text-bold">Changed By:</span>{" "}
                          {eachLog?.userName}
                        </div>{" "}
                        <div className={"col-lg-6 col-sm-6"}>
                          <span className="text-bold">Remarks:</span>{" "}
                          {eachLog?.remarks}
                        </div>{" "}
                      </div>
                      {/* Approver History Starts*/}
                      <>
                        {/* <button 
                          style={{margin:'5px'}}
                          className="btn bg-clr float-right ml-2"
                          onClick={(e)=>{GetApproverHistoryData()}}>
                            See Approver History
                        </button> */}
                        <Table striped bordered hover>
                        {
                          approverHistoryObj &&
                          approverHistoryObj.length > 0 &&
                          approverHistoryObj.map((log, index) => {
                            return (
                              <>
                                {index == 0 && (
                                  <thead>
                                    <tr>
                                      <th className="text-dark">Approver Role</th>
                                      <th className="text-dark">Approver Name</th>
                                      <th className="text-dark">Approver Decision</th>
                                      <th className="text-dark">Approver Remarks</th>
                                      <th className="text-dark">Approving Date</th>
                                    </tr>
                                  </thead>
                                )}
                                <tbody>
                                  <tr>
                                    <td>{log?.approverrole}</td>
                                    <td>{log?.approvername}</td>
                                    <td>{log?.approverdecision}</td>
                                    <td>{log?.approverremarks}</td>
                                    <td>
                                      {log?.approvingtime 
                                        ? moment
                                            .utc(log?.approvingtime)
                                            .utcOffset(localOffsetMinutes)
                                            .format("DD-MMM-YYYY HH:mm:ss")
                                        : ""}
                                    </td>
                                  </tr>
                                </tbody>
                              </>
                            );
                          })
                        }
                        </Table>
                      </>
                      {/* Approver History Ends*/}

                      <br />
                      <br />
                    </>
                  )}
                </React.Fragment>
              );
            })}

            {tableData?.map((eachLog) => (
              <Table striped bordered hover>
                {eachLog &&
                  eachLog.length > 0 &&
                  eachLog.map((log, index) => {
                    return (
                      <>
                        {index == 0 && (
                          <thead>
                            <tr>
                              <th className="text-dark">Field</th>
                              <th className="text-dark">Old Value</th>
                              <th className="text-dark">New Value</th>
                            </tr>
                          </thead>
                        )}
                        <tbody>
                          <tr>
                            <td>{log?.attribute}</td>
                            <td>{log?.oldAttributeValue}</td>
                            <td>{log?.newAttributeValue}</td>
                          </tr>
                        </tbody>
                      </>
                    );
                  })}
              </Table>
            ))}
          </div>
        </div>
      ) : (
        <h5>No History Found!</h5>
      )}
    </div>
  );
}

const Log = ({ auditLogsType }) => {
  return (
    <>
      <span>
        <span className="text-bold">Status:</span> {auditLogsType[0].progStatus}
      </span>
      <br />
      <span>
        <span className="text-bold">Changed Date:</span>{" "}
        {moment
          .utc(auditLogsType[0].createdDate)
          .utcOffset(localOffsetMinutes)
          .format("DD-MMM-YYYY HH:mm:ss")}
      </span>
      <br />
      <span>
        <span className="text-bold">Changed By:</span>{" "}
        {auditLogsType[0].userName}
      </span>
      <br />
    </>
  );
};
export default ViewHistoryOu;
