import React, { forwardRef, useImperativeHandle, useRef } from "react";

// Define the ref interface
export interface QRCodePrintRef {
  printQRCode: () => void;
}

const QRCodePrint = forwardRef<
  QRCodePrintRef,
  { qrCodeData: string; tokenId: string; name : string; category : number,LegalEntity: string, designation : string, department: string}
>(({ qrCodeData, tokenId,name,category,LegalEntity, designation, department }, ref) => {
  const imageRef = useRef<HTMLImageElement>(null);

  useImperativeHandle(ref, () => ({
    // printQRCode: () => {
    //   if (imageRef.current) {
    //     const printWindow = window.open("", "_blank");
    //     if (printWindow) {
    //       printWindow.document.write(
    //         "<html><head><title>QR Code Print</title></head><body>"
    //       );
    //       printWindow.document.write(
    //         '<div style="text-align:center;">' +
    //         '<img src="' + imageRef.current.src + '" style="width: 100%; height: auto; display: block; margin: 0 auto;" />' +
    //         `<p style="font-size: 18px; margin-top: 10px;">Token ID: ${tokenId}</p>` +
    //         `<p style="font-size: 18px; margin-top: 10px;">${name}</p>` +
    //         `<p style="font-size: 18px; margin-top: 10px;">Category: ${category === 1 ? 'Experience' : category === 2 ? 'Fresher' : category === 3 ? 'Rehire' : ''}</p>` +
    //         `<p style="font-size: 18px; margin-top: 10px;">Entity:  ${LegalEntity}</p>` +
    //     '</div>'
        
    //       );
    //       printWindow.document.write("</body></html>");
    //       printWindow.document.close();
    //       printWindow.print();
    //     }
    //   }
    // },
    // printQRCode: () => {
    //   if (imageRef.current) {
    //     const printWindow = window.open("", "_blank");
    //     if (printWindow) {
    //       printWindow.document.write(
    //         `<html><head><title>QR Code Print</title></head><body>`
    //       );
    //       printWindow.document.write(
    //         `<table width="390px" cellpadding="0" cellspacing="0" ><tr><td>` +
    //         `<img src="${imageRef.current.src}"  style="width:180px; height: 180px;" />` +
    //         `</td><td><p class="text-center font-weight-bold" style="text-align: Left; padding-top: 5px; font-size: 16px; font-weight: bold">${tokenId}</p>` +
    //         `<br><br>${name}` +
    //         `<br><br>${category === 1 ? 'Experience' : category === 2 ? 'Fresher' : category === 3 ? 'Rehire' : ''}` +
    //         `<br><br>${LegalEntity}` +
    //         `</p></td></tr></table>`
    //       );
    //       printWindow.document.write("</body></html>");
    //       printWindow.document.close();
    //       printWindow.print();
    //     }
    //   }
    // },
    
    printQRCode: () => {
      if (imageRef.current) {
        const printWindow = window.open("", "_blank");
        if (printWindow) {
          printWindow.document.write(
            `<html><head><title>QR Code Print</title></head><body>`
          );
          printWindow.document.write(
            `<table width="220px" border="0" cellpadding="0" cellspacing="0" >
              <tr>
                <td style="text-align: center;">
                  <img src="${imageRef.current.src}" style="width:130px; height: 130px;" />
                </td>
              </tr>
              <tr>
                <td style="text-align: center;">
                  <p style="margin: 6px 0 2px 0; padding: 0 0 0 0; font-size: 14px; font-weight: bold">${tokenId}</p>
                  <p style="margin: 2px 0 2px 0; padding: 0 0 0 0; font-size: 14px; font-weight: bold">${name}</p>
                  <p style="margin: 2px 0 2px 0; padding: 0 0 0 0; font-size: 14px; font-weight: bold">${category === 1 ? "On roll Experience" : category === 2 ? "On roll Fresher" : category === 3 ? "On roll Rehire" : category === 4 ? "Contract Worker" : category === 5 ? "Scheme Trainee" : category === 6 ? "Scheme Apprentice" : category === 7 ? "Experience Apprentice" : ''}</p>
                  ${LegalEntity && (
                  `<p style="margin: 2px 0 2px 0; padding: 0 0 0 0; font-size: 14px; font-weight: bold;">${LegalEntity}</p>`
                  )}
                  ${designation && (
                    `<p style="margin: 2px 0 2px 0; padding: 0 0 0 0; font-size: 14px; font-weight: bold;">${designation}</p>`
                  )}
                  ${department && (
                      `<p style="margin: 2px 0 2px 0; padding: 0 0 0 0; font-size: 14px; font-weight: bold;">${department}</p>`
                  )}
                </td>
              </tr>
            </table>`
          );
          printWindow.document.write("</body></html>");
          printWindow.document.close();
          printWindow.print();
        }
      }
    },
    
  }));

  return (
    <>
      <img
        ref={imageRef}
        src={qrCodeData}
        alt="QR Code"
        style={{ display: "none" }}
      />

      {/* <p className="text-center font-weight-bold">{`Token ID - 124`}</p> */}
    </>
  );
});

export default QRCodePrint;
