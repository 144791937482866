import React, { useState, useEffect, useContext } from "react";
import SelectForm from "../../Components/SelectForm/SelectForm";
import { APICall } from "../../Helpers/APICalls";
import { LoaderContext } from "../../Helpers/Context/Context";
import {
  // GetCriticalDataFormDropdowns,
  // GetCriticalDataAttributes,
  // GetCriticalDataAttributesPrimaryValues,
  // GetCriticalDataById,
  // InsertUpdateCriticalData,
  UpdateOuProgressionIsActive,
  GetOUProgressionMappingData,
  getDropdowns,
} from "../../Helpers/APIEndPoints/EndPoints";
import { isEmpty } from "../../utils/isEmpty";
import notify from "../../Helpers/ToastNotification";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import InputForm from "../../Components/InputForm/InputForm";
function OrganizationUnitProgressionEdit({ouformdata,setOuformdata, formtype, setformtype, id }) {

  const { showLoader, hideLoader } = useContext(LoaderContext);
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  //let employeeId = proxyUserDetails ? proxyUserDetails.value : userDetails?.Id;

    const [ddValue, setDdValue] = useState({});
// const [ouProgressionFormData,setOProgressionFormData] = useState({});
const [masterTypeId, setMasterTypeId] = useState("")
const [formShowData,setformShowData] = useState({});

const[ouProgressionDataMasterType,setOuProgressionDataMasterType] = useState("");

const [mappingMasterType , setMappingMasterType]= useState("");
const [isMappingAvailable , setMappingAvailable]= useState(false);

  const getMasterTypeId  = (masterTypeId) => {
    if (masterTypeId === 'SBU') {
      getMappingValue('Business');
  } else if (masterTypeId === 'StandardDesignation') {
      getMappingValue('JobLevel');
  } else if (masterTypeId === 'LegalEntity') {
      getMappingValue('SBU');
  } else if (masterTypeId === 'Division') {
      getMappingValue('LegalEntity');
  } else if (masterTypeId === 'Function') {
      getMappingValue('Division');
  } else if (masterTypeId === 'Department') {
      getMappingValue('Function');
  } else if (masterTypeId === 'Section') {
      getMappingValue('Department');
  } else if (masterTypeId === 'SubSection') {
      getMappingValue('Section');
  } else if (masterTypeId === 'SubSubSection') {
      getMappingValue('SubSection');
  } else if (masterTypeId === 'EmployeeClass') {
      getMappingValue('LegalEntity');
  } else if (masterTypeId === 'JobLevel') {
      getMappingValue('JobBand');
  } else if (masterTypeId === 'Designation') {
      getMappingValue('JobLevel');
  } else if (masterTypeId === 'CostCenter') {
      getMappingValue('Department');
  } else if (masterTypeId === 'PayrollLocation') {
      getMappingValue('LegalEntity');
  // } else if (MasterId === 'M10') {
  //     getMappingValue('LegalEntity');
  //     getMappingValue('EmployeeClass');
  //     getMappingValue('JobBand');
  // } else if (MasterId === 'M3') {
  //     getMappingValue('CustomBusiness');
  //     getMappingValue('LegalEntity');
  //     getMappingValue('Business');
  //     getMappingValue('SBU');
  // } else if (MasterId === 'M16') {
  //     getMappingValue('CustomBusiness');
  //     getMappingValue('LegalEntity');
  //     getMappingValue('Business');
  //     getMappingValue('SBU');
  //     getMappingValue('JobLevel');
  // } else if (MasterId === 'M17') {
  //     getMappingValue('LegalEntity');
  //     getMappingValue('PayrollLocation');
  // } else if (MasterId === 'M18') {
  //     getMappingValue('SBU');
  //     getMappingValue('LegalEntity');
  // } else if (MasterId === 'M19') {
  //     getMappingValue('LegalEntity');
  //     getMappingValue('CustomBusiness');
  //     getMappingValue('JobLevel');
  //     getMappingValue('StandardDesignation');
  // } else if (MasterId === 'M20') {
  //     getMappingValue('LegalEntity');
  //     getMappingValue('EmployeeClass');
  //     getMappingValue('JobLevel');
  // } else if (MasterId === 'M21') {
  //     getMappingValue('WorkArea');
  //     getMappingValue('LegalEntity');
  //     getMappingValue('Business');
  //     getMappingValue('SBU');
  //     getMappingValue('PayrollLocation');
  // } else if (MasterId === 'M22') {
  //     getMappingValue('WorkArea');
  //     getMappingValue('WorkAreaMasterStage');
  // } else if (MasterId === 'M23') {
  //     getMappingValue('StandardDesignation');
  //     getMappingValue('SkillCategory');
  // } else if (MasterId === 'M24') {
  //     getMappingValue('Business');
  //     getMappingValue('SBU');
  //     getMappingValue('LegalEntity');
  //     getMappingValue('PayrollLocation');
  //     getMappingValue('StandardDesignation');
  //     getMappingValue('SkillCategory');
  //     getMappingValue('RCMBProficiency');
  // } else if (MasterId === 'M25') {
      //getMappingValue('StandardDesignation');
  
      console.log(ouProgressionDataMasterType);
  } else {
      setMappingAvailable(false);
  }
  }

const getMappingValue = async (masterType) => {
  debugger
  await getDropdownsData('OUMappingValues', masterType, null);
}

const getDropdownsData = async (AC, PID = null, cb) => {
  debugger;
  await showLoader();
  const dropdownData = await APICall(getDropdowns, "POST", {
    AC,
    PID,
    UserId: userDetails?.Id,
    RoleId: currentRoleId?.value,
  });
  if (
    dropdownData.data !== null &&
    dropdownData.data !== undefined &&
    dropdownData.data.length > 0 
  ) {
    // if(PID === 'LegalEntity'){
    //   setLegalEntityDdValue(dropdownData.data);
    // } else if (PID === 'EmployeeClass'){
    //   setEmployeeClassDdValue(dropdownData.data);
    // } else if (PID === 'Business'){
    //   setBusinessDdValue(dropdownData.data);
    // } else if (PID === 'SBU'){
    //   setSBUDdValue(dropdownData.data);
    // } else if (PID === 'CustomBusiness'){
    //   setCUSBISDdValue(dropdownData.data);
    // } else if (PID === 'JobBand'){
    //   setJobBandDdValue(dropdownData.data);
    // } else if (PID === 'JobLevel') {
    //   setJobLevelDdValue(dropdownData.data);
    // } else if(PID === 'PayrollLocation') {
    //   setPLOCDdValue(dropdownData.data);
    // } else if(PID === 'StandardDesignation'){
    //   setSDDdValue(dropdownData.data);
    // } else if(PID === 'WorkArea'){
    //   setWorkAreaDdValue(dropdownData.data);
    // } else if(PID === 'WorkAreaMasterStage'){
    //   setWorkAreaStageDdValue(dropdownData.data);
    // } else if(PID === 'SkillCategory'){
    //   setSkillCategoryDdValue(dropdownData.data);
    // } else if(PID === 'RCMBProficiency'){
    //   setProficiencyDdValue(dropdownData.data);
    // }
    setDdValue((prev) => ({ ...prev, [AC]: dropdownData.data }));
  } else {
    setDdValue((prev) => ({ ...prev, [AC]: [] }));
  }
  await hideLoader();
};

  const [criticalFormFilterDropdown, setCriticalFormFilterDropdown] = useState({
    country: null,
    countryoption: null,
    tab: null,
    taboption: null,
    section: null,
    sectionoption: null,
    fieldname: null,
    fieldnameoption: null,
    fieldvalue: null,
    fieldvalueoption: null,
    criticality: null,
    criticalityoption: [
      { label: "High", value: 0 },
      { label: "Medium", value: 1 },
      { label: "Low", value: 2 },
    ],
    status: null,
    // statusoption: [
    //   { label: "Active", value: 1 },
    //   { label: "In Active", value: 0 },
    // ],
  });
  const [formErrors, setFormErrors] = useState<any>({});
  const handlereset = () => {
    setCriticalFormFilterDropdown((prev) => ({
      ...prev,
      country: null,
      tab: null,
      section: null,
      fieldname: null,
      fieldvalue: null,
      criticality: null,
      status: null,
    }));
  };
  const handleValidation = () => {
    let errorCount = 0;
    if (
      isEmpty(ouformdata.Status) 
    ) {
      setFormErrors((prev) => ({
        ...prev,
        status: "Required",
      }));
      errorCount += 1;
    }
    return errorCount > 0 ? true : false;
  };

  const removeNullValueFromObject = (obj) =>{
    debugger;

    if(obj.isMultiLevelOu)
      {
         debugger;
        let result = {};
        let extranct = ["id","createdDateUtc","status","isSync","effectiveDate","name","oucode","isMultiLevelOu"];
        for(const property in obj){
          if(!extranct.includes(property) && !isEmpty(obj[property] )){
              result[property]= obj[property]
          }
        }
        setformShowData(result);
      }
    else
    {
      let result = {};
      let extranct = ["id","createdDateUtc","status","isSync","effectiveDate","name","oucode","ouType","masterTypeId","mappingValues"];

      for(const property in obj){
        if(!extranct.includes(property) && !isEmpty(obj[property] )){
          let outerObject = JSON.parse(obj[property]);

            for(const innerProperty in outerObject)
            {
                let mainObj = JSON.parse(outerObject[innerProperty]);

                result[property] = mainObj;
            }
    
    // Dynamically handle the inner object
    // result[property] = [outerObject];
         // result[property]= innerObject
            
        }
      }
      console.log("himanshu",result)
      setformShowData(result);
    }
  }
  
  const toTitleCase = (str) => {
    return str
      .replace(/([A-Z])/g, ' $1') // Add a space before all capital letters
      .replace(/^./, function(match) { return match.toUpperCase(); }) // Capitalize the first letter
      .trim(); // Remove any leading or trailing spaces
  }
  const getOuMappingData = async () =>{
    debugger
    showLoader();
    try {
      const responseOuMappingData = await APICall(GetOUProgressionMappingData, "POST", {
        Id: id,
        OuType:ouformdata?.MasterTypeId?.label,
        IsMultiLevelOu: ouformdata?.IsMultiplevelOu?.label,
      });

      setOuProgressionDataMasterType(responseOuMappingData.MasterTypeId);

      const { data, status: statuscritical, message } = responseOuMappingData || {};
      if(!isEmpty(data[0])){
        getMasterTypeId(data[0]?.masterTypeId);
        //setOProgressionFormData(data[0]);
        removeNullValueFromObject(data[0]);
        console.log(responseOuMappingData);
      }
      if (statuscritical === 1) {
        notify(1, message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error in GetOUProgressionMappingData", error);
    } finally {
      hideLoader();
    }
  }
  const handleSubmit = async () => {
    showLoader();
    try {
      setFormErrors({});
      const isErrors = handleValidation();
      if (isErrors) {
        hideLoader();
        notify(1,"Error by mayur")
        return;
      }
     
      const criticaldataform = await APICall(UpdateOuProgressionIsActive, "POST", {
        
        Id: id,
        Status:ouformdata?.Status?.value,
        IsMultiLevelOu: ouformdata?.IsMultiplevelOu?.label,
        UserId : userDetails?.Id,
        //CreatedBy: employeeId,
      });
      const { data, status: statuscritical, message } = criticaldataform || {};
      if (statuscritical === 1) {
        notify(1, message || "Something went wrong");
      } else {
        handlereset();
        setformtype("");
        notify(0, `Form Submitted  Successfully`);
      }
    } catch (error) {
      console.error("Error in insert update critical form data", error);
    } finally {
      hideLoader();
    }
  };
  // const getFormDropdownAPI = async (type,sectionid=null) => {
  //   showLoader();
  //   try {
  //     const dropdowndashboardData = await APICall(
  //       GetCriticalDataFormDropdowns,
  //       "POST",
  //       {
  //         Type: type,
  //         TabId:`${type==="Section"?sectionid:0}`
  //       }
  //     );
  //     const { data } = dropdowndashboardData || {};
  //     if ((data || []).length > 0) {
  //       if (type === "Country") {
  //         setCriticalFormFilterDropdown((prev) => ({
  //           ...prev,
  //           countryoption: data,
  //         }));
  //       } else if (type === "Section") {
  //         setCriticalFormFilterDropdown((prev) => ({
  //           ...prev,
  //           sectionoption: data,
  //         }));
  //       } else if (type === "Tab") {
  //         setCriticalFormFilterDropdown((prev) => ({
  //           ...prev,
  //           taboption: data,
  //         }));
  //       }
  //     } else {
  //       return null;
  //     }
  //   } catch (error) {
  //     console.error("Error in fetching Dropdown data", error);
  //   } finally {
  //     hideLoader();
  //   }
  // };
  // const getFieldnameDropdownAPI = async (id) => {
  //   try {
  //     const dropdowndashboardData = await APICall(
  //       GetCriticalDataAttributes,
  //       "POST",
  //       {
  //         Type: id,
  //       }
  //     );
  //     const { data } = dropdowndashboardData || {};
  //     if ((data || []).length > 0) {
  //       setCriticalFormFilterDropdown((prev) => ({
  //         ...prev,
  //         fieldnameoption: data,
  //       }));
  //     } else {
  //       return null;
  //     }
  //   } catch (error) {
  //     console.error("Error in fetching Dropdown data", error);
  //   } finally {
  //     hideLoader();
  //   }
  // };
  // const getFieldtypeDropdownAPI = async (type) => {
  //   try {
  //     const dropdowndashboardData = await APICall(
  //       GetCriticalDataAttributesPrimaryValues,
  //       "POST",
  //       {
  //         Type: type,
  //       }
  //     );
  //     const { data } = dropdowndashboardData || {};
  //     if ((data || []).length > 0) {
  //       setCriticalFormFilterDropdown((prev) => ({
  //         ...prev,
  //         fieldvalueoption: data,
  //       }));
  //     } else {
  //       setCriticalFormFilterDropdown((prev) => ({
  //         ...prev,
  //         fieldvalueoption: null,
  //       }));
  //     }
  //   } catch (error) {
  //     console.error("Error in fetching Dropdown data", error);
  //   } finally {
  //     hideLoader();
  //   }
  // };
   useEffect(() => {
    getOuMappingData();
   }, []);
  //  useEffect(()=>{
  //   removeNullValueFromObject(ouProgressionFormData)
  //  },[ouProgressionFormData]
  // )

  // useEffect(() => {
  //   if (formtype === "critical_edit" || formtype === "critical_view") {
  //     getFieldnameDropdownAPI(criticalFormFilterDropdown?.section?.value);
  //   }
  // }, [criticalFormFilterDropdown.section]);

  // useEffect(() => {
  //   if (criticalFormFilterDropdown?.tab?.value) {
  //     getFormDropdownAPI("Section",criticalFormFilterDropdown?.tab?.value);
  //   }
  // }, [criticalFormFilterDropdown.tab]);

  if(ouformdata?.IsMultiplevelOu?.label)
  {
    debugger
    return (
      <div className="px-3">
        <div className="row px-3 my-3">
          {/* <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                OU Name<span className="modified-asterick"></span>
              </label>
              <div className="">
                <SelectForm
                  isClearable
                  options={criticalFormFilterDropdown["countryoption"] || []}
                  placeholder={"OU NAME"}
                  isDisabled={true}
                  onChange={(event) => {
                    setCriticalFormFilterDropdown((prev) => {
                      return { ...prev, ["country"]: event };
                    });
                  }}
                  isMulti={false }
                  noIndicator={false}
                  noSeparator={false}
                  value={ouformdata.OUName}
                />
              </div>
            </div>
          </div> */}
           <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Effective Date<span className="modified-asterick"></span>
              </label>
              <div className="">
                <SelectForm
                  isClearable
                  options={criticalFormFilterDropdown["countryoption"] || []}
                  placeholder={"Effective Date"}
                  isDisabled={true}
                  onChange={(event) => {
                    setCriticalFormFilterDropdown((prev) => {
                      return { ...prev, ["country"]: event };
                    });
                  }}
                  isMulti={false }
                  noIndicator={false}
                  noSeparator={false}
                  value={ouformdata.EffectiveDate}
                />
                {formErrors["country"] && (
                  <p style={{ color: "red" }}>{formErrors["country"]}</p>
                )}
              </div>
            </div>
          </div>
  
          {!isEmpty(formShowData) && (
    <>
      {Object.entries(formShowData).map(([key,value], index) => (
        <div key={index} className="col-lg-3 col-sm-3 col-xs-4">
        <div className="mb-1">
          <label className="col-form-label">
            {toTitleCase(key)}<span className="modified-asterick"></span>
          </label>
          <div className="">
          <InputForm
          className="form-control"
          placeholder="Nothing"
          isDisabled={true}       
          onChange={(e) =>{}}
          textArea={false}
          value={value}
          maxLength="255"
          />
          </div>
        </div>
      </div>
      ))}
    </>
  )}
          {/* <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                OU Code<span className="modified-asterick"></span>
              </label>
              <div className="">
                <SelectForm
                  isClearable
                  options={criticalFormFilterDropdown["countryoption"] || []}
                  placeholder={"OU Code"}
                  isDisabled={true}
                  onChange={(event) => {
                    setCriticalFormFilterDropdown((prev) => {
                      return { ...prev, ["country"]: event };
                    });
                  }}
                  isMulti={false }
                  noIndicator={false}
                  noSeparator={false}
                  value={ouformdata.OUCode}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                OU Type<span className="modified-asterick"></span>
              </label>
              <div className="">
                <SelectForm
                  isClearable
                  options={criticalFormFilterDropdown["countryoption"] || []}
                  placeholder={"OU Code"}
                  isDisabled={true}
                  onChange={(event) => {
                    setCriticalFormFilterDropdown((prev) => {
                      return { ...prev, ["country"]: event };
                    });
                  }}
                  isMulti={false }
                  noIndicator={false}
                  noSeparator={false}
                  value={ouformdata.OUType}
                />
              </div>
            </div>
          </div> */}
          {/* <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Effective Date<span className="modified-asterick"></span>
              </label>
              <div className="">
                <SelectForm
                  isClearable
                  options={criticalFormFilterDropdown["countryoption"] || []}
                  placeholder={"Effective Date"}
                  isDisabled={true}
                  onChange={(event) => {
                    setCriticalFormFilterDropdown((prev) => {
                      return { ...prev, ["country"]: event };
                    });
                  }}
                  isMulti={false }
                  noIndicator={false}
                  noSeparator={false}
                  value={criticalFormFilterDropdown["country"] || ""}
                />
                {formErrors["country"] && (
                  <p style={{ color: "red" }}>{formErrors["country"]}</p>
                )}
              </div>
            </div>
          </div> */}
          {/* <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Is Sync<span className="modified-asterick"></span>
              </label>
              <div className="">
                <SelectForm
                  isClearable
                  options={criticalFormFilterDropdown["countryoption"] || []}
                  placeholder={"Is Sync"}
                  isDisabled={true}
                  onChange={(event) => {
                    setCriticalFormFilterDropdown((prev) => {
                      return { ...prev, ["country"]: event };
                    });
                  }}
                  isMulti={false }
                  noIndicator={false}
                  noSeparator={false}
                  value={ouformdata.IsSync}
                />
              </div>
            </div>
          </div>
  
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Effective Date<span className="modified-asterick"></span>
              </label>
              <div className="">
                <SelectForm
                  isClearable
                  options={criticalFormFilterDropdown["statusoption"] || []}
                  placeholder={"Effective Date"}
                  isDisabled={ true }
                  onChange={(event) => {
                    setCriticalFormFilterDropdown((prev) => {
                      return { ...prev, ["status"]: event };
                    });
                  }}
                  isMulti={false}
                  noIndicator={false}
                  noSeparator={false}
                  value={ouformdata.EffectiveDate}
                />
               
              </div>
            </div>
          </div>*/}
         
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Is Sync<span className="modified-asterick"></span>
              </label>
              <div className="">
                <SelectForm
                  isClearable
                  options={criticalFormFilterDropdown["countryoption"] || []}
                  placeholder={"Is Sync"}
                  isDisabled={true}
                  onChange={(event) => {
                    setCriticalFormFilterDropdown((prev) => {
                      return { ...prev, ["country"]: event };
                    });
                  }}
                  isMulti={false }
                  noIndicator={false}
                  noSeparator={false}
                  value={ouformdata.IsSync}
                />
              </div>
            </div>
          </div>
  
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Status <span className="modified-asterick"></span>
              </label>
              <div className="">
                <SelectForm
                  isClearable
                  options={[{label:"Active",value:1},
                    {label:"InActive",value:0}
                  ]}
                  placeholder={"Status"}
                  isDisabled={ false }
                  onChange={(event) => {
                    setOuformdata((prev) => {
                      return { ...prev, Status: event };
                    });
                  }}
                  isMulti={false}
                  noIndicator={false}
                  noSeparator={false}
                  value={ouformdata.Status}
                />
                {formErrors["status"] && (
                  <p style={{ color: "red" }}>{formErrors["status"]}</p>
                )}
              </div>
            </div>
          </div> 
  
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1 ">
              <label className="col-form-label"> </label>
              <div className="">
                <button
                  type="button"
                  disabled={formtype === "critical_view" ? true : false}
                  className="btn btn-secondary mt-3 ml-3"
                  onClick={handlereset}
                >
                  <i className="fa fa-undo" aria-hidden="true"></i> Reset{" "}
                </button>{" "}
                <button
                  type="button"
                  disabled={formtype === "critical_view" ? true : false}
                  className="btn btn-primary mt-3 ml-2"
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  else
  {
    debugger
    return (
      <div className="px-3">
        <div className="row px-3 my-3">
          
           <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Effective Date<span className="modified-asterick"></span>
              </label>
              <div className="">
                <SelectForm
                  isClearable
                  options={criticalFormFilterDropdown["countryoption"] || []}
                  placeholder={"Effective Date"}
                  isDisabled={true}
                  onChange={(event) => {
                    setCriticalFormFilterDropdown((prev) => {
                      return { ...prev, ["country"]: event };
                    });
                  }}
                  isMulti={false }
                  noIndicator={false}
                  noSeparator={false}
                  value={ouformdata.EffectiveDate}
                />
                {formErrors["country"] && (
                  <p style={{ color: "red" }}>{formErrors["country"]}</p>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                OU Name<span className="modified-asterick"></span>
              </label>
              <div className="">
                <SelectForm
                  isClearable
                  options={criticalFormFilterDropdown["countryoption"] || []}
                  placeholder={"OU NAME"}
                  isDisabled={true}
                  onChange={(event) => {
                    setCriticalFormFilterDropdown((prev) => {
                      return { ...prev, ["country"]: event };
                    });
                  }}
                  isMulti={false }
                  noIndicator={false}
                  noSeparator={false}
                  value={ouformdata.OUName}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                OU Code<span className="modified-asterick"></span>
              </label>
              <div className="">
                <SelectForm
                  isClearable
                  options={criticalFormFilterDropdown["countryoption"] || []}
                  placeholder={"OU Code"}
                  isDisabled={true}
                  onChange={(event) => {
                    setCriticalFormFilterDropdown((prev) => {
                      return { ...prev, ["country"]: event };
                    });
                  }}
                  isMulti={false }
                  noIndicator={false}
                  noSeparator={false}
                  value={ouformdata.OUCode}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                OU Type<span className="modified-asterick"></span>
              </label>
              <div className="">
                <SelectForm
                  isClearable
                  options={criticalFormFilterDropdown["countryoption"] || []}
                  placeholder={"OU Code"}
                  isDisabled={true}
                  onChange={(event) => {
                    setCriticalFormFilterDropdown((prev) => {
                      return { ...prev, ["country"]: event };
                    });
                  }}
                  isMulti={false }
                  noIndicator={false}
                  noSeparator={false}
                  value={ouformdata.OUType}
                />
              </div>
            </div>
          </div>
  
          {!isEmpty(formShowData) && (
          <>
            {Object.entries(formShowData).map(([key,value], index) => (
              <div key={index} className="col-lg-3 col-sm-3 col-xs-4">
              <div className="mb-1">
                <label className="col-form-label">
                  {toTitleCase(key)}<span className="modified-asterick"></span>
                </label>
                <div className="">


                <SelectForm
                  isClearable
                  options={ddValue['OUMappingValues']}
                  placeholder={"OU Code"}
                  isDisabled={false}
                  onChange={(e) => {
                  }}
                  // isOptionDisabled={true}
                  isMulti={true}
                  noIndicator={false}
                  noSeparator={false}
                  value={value}
                />
              </div>

                </div>
              </div>
            // </div>
            ))}
          </>
          )}
          
          {/* <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Effective Date<span className="modified-asterick"></span>
              </label>
              <div className="">
                <SelectForm
                  isClearable
                  options={criticalFormFilterDropdown["countryoption"] || []}
                  placeholder={"Effective Date"}
                  isDisabled={true}
                  onChange={(event) => {
                    setCriticalFormFilterDropdown((prev) => {
                      return { ...prev, ["country"]: event };
                    });
                  }}
                  isMulti={false }
                  noIndicator={false}
                  noSeparator={false}
                  value={criticalFormFilterDropdown["country"] || ""}
                />
                {formErrors["country"] && (
                  <p style={{ color: "red" }}>{formErrors["country"]}</p>
                )}
              </div>
            </div>
          </div> */}
          {/* <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Is Sync<span className="modified-asterick"></span>
              </label>
              <div className="">
                <SelectForm
                  isClearable
                  options={criticalFormFilterDropdown["countryoption"] || []}
                  placeholder={"Is Sync"}
                  isDisabled={true}
                  onChange={(event) => {
                    setCriticalFormFilterDropdown((prev) => {
                      return { ...prev, ["country"]: event };
                    });
                  }}
                  isMulti={false }
                  noIndicator={false}
                  noSeparator={false}
                  value={ouformdata.IsSync}
                />
              </div>
            </div>
          </div>
  
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Effective Date<span className="modified-asterick"></span>
              </label>
              <div className="">
                <SelectForm
                  isClearable
                  options={criticalFormFilterDropdown["statusoption"] || []}
                  placeholder={"Effective Date"}
                  isDisabled={ true }
                  onChange={(event) => {
                    setCriticalFormFilterDropdown((prev) => {
                      return { ...prev, ["status"]: event };
                    });
                  }}
                  isMulti={false}
                  noIndicator={false}
                  noSeparator={false}
                  value={ouformdata.EffectiveDate}
                />
               
              </div>
            </div>
          </div>*/}
         
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Is Sync<span className="modified-asterick"></span>
              </label>
              <div className="">
                <SelectForm
                  isClearable
                  options={criticalFormFilterDropdown["countryoption"] || []}
                  placeholder={"Is Sync"}
                  isDisabled={true}
                  onChange={(event) => {
                    setCriticalFormFilterDropdown((prev) => {
                      return { ...prev, ["country"]: event };
                    });
                  }}
                  isMulti={false }
                  noIndicator={false}
                  noSeparator={false}
                  value={ouformdata.IsSync}
                />
              </div>
            </div>
          </div>
  
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1">
              <label className="col-form-label">
                Status <span className="modified-asterick"></span>
              </label>
              <div className="">
                <SelectForm
                  isClearable
                  options={[{label:"Active",value:1},
                    {label:"InActive",value:0}
                  ]}
                  placeholder={"Status"}
                  isDisabled={ false }
                  onChange={(event) => {
                    setOuformdata((prev) => {
                      return { ...prev, Status: event };
                    });
                  }}
                  isMulti={false}
                  noIndicator={false}
                  noSeparator={false}
                  value={ouformdata.Status}
                />
                {formErrors["status"] && (
                  <p style={{ color: "red" }}>{formErrors["status"]}</p>
                )}
              </div>
            </div>
          </div> 
  
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mb-1 ">
              <label className="col-form-label"> </label>
              <div className="">
                <button
                  type="button"
                  disabled={formtype === "critical_view" ? true : false}
                  className="btn btn-secondary mt-3 ml-3"
                  onClick={handlereset}
                >
                  <i className="fa fa-undo" aria-hidden="true"></i> Reset{" "}
                </button>{" "}
                <button
                  type="button"
                  disabled={formtype === "critical_view" ? true : false}
                  className="btn btn-primary mt-3 ml-2"
                  onClick={handleSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default OrganizationUnitProgressionEdit;
