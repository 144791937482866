import React, { useEffect, useState } from "react";
import "./StructureType.css";
import InputForm from "../../Components/InputForm/InputForm";
import { APICall } from "../../Helpers/APICalls";
import { CommonDropdownData } from "../../Helpers/APIEndPoints/EndPoints";
import SelectForm from "../../Components/SelectForm/SelectForm";
import { Modal } from "react-bootstrap";
import ParameterEdit from "../Parameter/ParameterEdit";

const ParameterDetail = ({
  parameter,
  structureData,
  setStructureData,
  stIndex,
  acIndex,
  paIndex,
}) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationModalReturnValue, setConfirmationModalReturnValue] =
    useState(false);
  const [showParameterModal, setShowParameterModal] = useState(false);
  const [parameterReturnValue, setParameterReturnValue] = useState(false);

  const closeConfirmationModal = (confirmation) => {
    if (confirmation) {
      setConfirmationModalReturnValue(confirmation);
    }
    setShowConfirmationModal(false);
  };

  const closeParameterModal = (confirmation) => {
    if (confirmation) {
      setParameterReturnValue(confirmation);
    }
    setShowParameterModal(false);
  };

  useEffect(() => {
    if (confirmationModalReturnValue) {
      let newArr = [...structureData];

      if (
        newArr[stIndex].activityDetails[acIndex].hasOwnProperty(
          "parameterDetails"
        )
      ) {
        newArr[stIndex].activityDetails[acIndex].parameterDetails = newArr[
          stIndex
        ].activityDetails[acIndex].parameterDetails.filter(
          (parameter, px) => px !== paIndex
        );

        setStructureData(newArr);
      }
    }
    return () => {
      setShowConfirmationModal(false);
      setConfirmationModalReturnValue(false);
    };
  }, [confirmationModalReturnValue]);

  const [parameterOptions, setParameterOptions] = useState([]);
  const [UOMOptions, setUOMOptions] = useState([]);

  const getParameterDropdown = async () => {
    const res = await APICall(CommonDropdownData, "POST", {
      dropdownName: "parameter",
    });

    if (res.data !== null) {
      setParameterOptions(res.data);

      if (parameter.paId !== "") {
        let name = res.data.find((pa) => pa.value === parameter.paId);

        let newArr = [...structureData];
        newArr[stIndex].activityDetails[acIndex].parameterDetails[
          paIndex
        ].name = name;

        if (parameter.uomValue !== null || parameter.uomValue !== "") {
          newArr[stIndex].activityDetails[acIndex].parameterDetails[
            paIndex
          ].uom = { value: parameter.uomValue, label: parameter.uomValue };
        }

        setStructureData(newArr);
      }
    } else {
      setParameterOptions([]);
    }
  };

  useEffect(() => {
    getParameterDropdown();
  }, []);

  useEffect(() => {
    if (parameterReturnValue) {
      getParameterDropdown();
      setShowParameterModal(false);
    }
    setParameterReturnValue(false);
  }, [parameterReturnValue]);

  useEffect(() => {
    let newArr = [...structureData];
    let uomOptions =
      newArr[stIndex].activityDetails[acIndex].parameterDetails[paIndex].name;

    if (uomOptions !== null) {
      if (uomOptions.uom !== null && uomOptions.uom !== "") {
        uomOptions = uomOptions.uom.split(",");
        uomOptions = uomOptions.map((uom) => ({ label: uom, value: uom }));
        setUOMOptions(uomOptions);
      } else {
        setUOMOptions([]);
      }
    }
  }, [structureData]);

  return (
    <>
      <div className="container-fluid">
        <div
          style={{ padding: 15 }}
          className="row align-items-center justify-content-around">
          <div className="col-lg-2 col-md-2 col-sx-2">
            <div className="form-group">
              <label className="col-form-label">Sequence</label>
              <sup>*</sup>
              <InputForm
                className="form-control form-control-lg"
                placeholder={""}
                name="paSeqNo"
                isDisabled={false}
                textArea={false}
                value={parameter.paSeqNo}
                onChange={(e) => {
                  let newArr = [...structureData];
                  newArr[stIndex].activityDetails[acIndex].parameterDetails[
                    paIndex
                  ][e.target.name] = e.target.value;
                  setStructureData(newArr);
                }}
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-2 col-sx-2">
            <div className="form-group">
              <label className="col-form-label">Parameter</label>
              <sup>*</sup>
              <SelectForm
                value={parameter.name}
                onChange={(event) => {
                  let newArr = [...structureData];

                  newArr[stIndex].activityDetails[acIndex].parameterDetails[
                    paIndex
                  ].uom = null;
                  newArr[stIndex].activityDetails[acIndex].parameterDetails[
                    paIndex
                  ].uomValue = null;
                  newArr[stIndex].activityDetails[acIndex].parameterDetails[
                    paIndex
                  ].name = event;
                  newArr[stIndex].activityDetails[acIndex].parameterDetails[
                    paIndex
                  ].paId = event.value;

                  setStructureData(newArr);
                }}
                placeholder={""}
                options={parameterOptions}
                isDisabled={false}
              />
            </div>
          </div>
          {/* {UOMOptions.length > 0 && ( */}
          <div className="col-lg-3 col-md-2 col-sx-2">
            <div className="form-group">
              <label className="col-form-label">Unit of Measure</label>
              {UOMOptions.length === 0 ? null : <sup>*</sup>}
              <SelectForm
                value={parameter.uom}
                onChange={(event) => {
                  let newArr = [...structureData];
                  newArr[stIndex].activityDetails[acIndex].parameterDetails[
                    paIndex
                  ].uom = event;
                  newArr[stIndex].activityDetails[acIndex].parameterDetails[
                    paIndex
                  ].uomValue = event.value;
                  setStructureData(newArr);
                }}
                placeholder={""}
                options={UOMOptions}
                isDisabled={UOMOptions.length === 0 ? true : false}
              />
            </div>
          </div>
          {/* )} */}
          <div className="col-lg-4 col-md-2 col-sx-2">
            <button
              className="btn btn-danger"
              style={{ marginLeft: 5 }}
              onClick={(e) => setShowConfirmationModal(true)}>
              <i className="fa fa-trash"></i> Delete
            </button>
            {structureData[stIndex].activityDetails[acIndex].parameterDetails
              .length -
              1 ===
              paIndex && (
              <button
                className="btn btn-info"
                style={{ marginLeft: 5 }}
                onClick={(e) => setShowParameterModal(true)}>
                <i className="fa fa-plus"></i> Add
              </button>
            )}
          </div>
        </div>
      </div>

      <Modal
        show={showConfirmationModal}
        onHide={() => closeConfirmationModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">Delete Parameter</div>
        </div>
        <Modal.Body>
          <h5>Are you sure?</h5>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-success"
            style={{ marginLeft: 5 }}
            onClick={() => closeConfirmationModal(true)}>
            Yes
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => closeConfirmationModal(false)}>
            No
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showParameterModal}
        onHide={() => closeParameterModal(false)}
        backdrop="static"
        keyboard={false}
        size="xl"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">Add New Parameter</div>
        </div>
        <Modal.Body>
          <ParameterEdit setParameterReturnValue={setParameterReturnValue} />
        </Modal.Body>
        {/* <Modal.Footer>
          <button
            className="btn btn-success"
            style={{ marginLeft: 5 }}
            onClick={() => closeConfirmationModal(true)}>
            Yes
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => closeParameterModal(false)}>
            Cancel
          </button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default ParameterDetail;
