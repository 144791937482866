import React, { useContext, useEffect, useState } from "react";
import "./Departmentcandidate.css";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { GridColDef, GridColumnGroupingModel } from "mui-datatables";
import {
  DownloadCandidateLogsheetExcel,
  GetDepartmentDropdownData,
  GetAssociateDepartmentRecrutment,
  DownloadDepartmentExcel,
  GetCategoryOptions
} from "../../Helpers/APIEndPoints/AssociateEndPoints";
import Collapse from "react-bootstrap/Collapse";
import { APICall } from "../../Helpers/APICalls";
import SelectForm from "../../Components/SelectForm/SelectForm";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { base64ToArrayBuffer } from "../../Helpers/Common";
import fileDownload from "js-file-download";
import notify from "../../Helpers/ToastNotification";
import { LoaderContext } from "../../Helpers/Context/Context";
import { useNavigate } from "react-router-dom";
import DateForm from "../../Components/DateForm/DateForm";
import moment from "moment";
import { getDropdowns } from "../../Helpers/APIEndPoints/EndPoints";
import {
  getUserRoleMappingData,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import { isEmpty } from "../../utils/isEmpty";
function Departmentcandidate() {
  const [dashboard, setdashboard] = useState([]);
  const [dashboardPageSize, setDashboardPageSize] = useState(10);
  const [dashboardStart, setDashboardStart] = useState(0);
  const [dashboardSortColumn, setDashboardSortColumn] = useState<any>("");
  const [dashboardSortDirection, setDashboardSortDirection] = useState("");
  const [dashboardCount, setDashboardCount] = useState(0);
  const [categoryOptions, setCategoryOptions] = useState<any>([]);
  const [category, setCategory] = useState<any>(null);
  const [options, setOptions] = useState<IFormData>({});
  const [formData, setFormData] = useState<IFormData>({
    id: 0,
    dob: new Date(),
    todate: new Date(),
    categoryId: 0,
    business: null,
    sbu: null,
    legalEntity: null,
    location: null,
  });

  const [isRefresh, setisRefresh] = useState(false);
  let { userDetails,currentRoleId }: any = useUserContext();
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [locationOption, setlocationOption] = useState<any>([
    {
      value: 5614,
      label: "Anjar",
    },
  ]);
  const [location, setlocation] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  interface IOptions {
    label?: string;
    value?: number | string;
    code?: string;
  }
  interface IFormData {
    dob?: Date | string | boolean | any;
    todate?: Date | string | boolean | any;
    position_Id?: IOptions | boolean | any;
    categoryId?: IOptions | boolean | any;
    business?: IOptions[] | string | any;
    sbu?: IOptions[] | string | any;
    legalEntity?: IOptions[] | string | any;
    location?: IOptions[] | string | any;
  }
  interface IFormData {
    id?: IOptions | boolean | any;
    label?: IOptions | boolean | any;
  }

  interface IOptions {
    label?: string;
    value?: number | string;
    code?: string;
  }

  useEffect(() => {
    (async () => {
      await GetCategoryOptionsAsync();
      await getCandidateStatus(true);
    })();
  }, [dashboardStart, dashboardSortColumn, dashboardSortDirection, isRefresh]);

  const getCandidateStatus = async (validateflag: any) => {
    try {
      showLoader();
      const response = await APICall(GetAssociateDepartmentRecrutment, "POST", {
        TokenId: formData?.id?.id || 0,
        FromDate: formData?.dob
          ? moment(formData.dob).format("YYYY-MM-DD")
          : null,
        ToDate: formData?.todate
          ? moment(formData.todate).format("YYYY-MM-DD")
          : null,
       CategoryId:formData?.categoryId?.value|| 0,
       Business: !isEmpty(formData?.business?.value) ? formData?.business?.value : 0,
       SBU: !isEmpty(formData?.sbu?.value) ? formData?.sbu?.value : 0,
       LegalEntity: !isEmpty(formData?.legalEntity?.value) ? formData?.legalEntity?.value : 0,
       PayrollLocation : !isEmpty(formData?.location?.value) ? formData?.location?.value : 0,
        PageNumber: dashboardStart,
        PageSize: dashboardPageSize,
        SortColumn: dashboardSortColumn,
        SortOrder: dashboardSortColumn,
        IsExcel: false,
      });

      if (response?.status === 0 && response?.data.length > 0) {
        console.log(response?.data, "Associate");
        setdashboard(response?.data);
        setDashboardCount(response?.data[0].count);
      } else {
        setdashboard([]);
        setDashboardCount(0);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const columns = [
    {
      name: "rowNum",
      label: "Sr No.",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "createdDateUtc",
      label: "Date",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return moment(value).format("DD-MM-YYYY");
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "department",
      label: "Department",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "designation",
      label: "Designation",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "category",
      label: "Category",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  const dashboardOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: dashboardCount,
    rowsPerPage: dashboardPageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    onchangeRowsPerPage: (num) => {
      //   setLimit(num);
      //   setNxtPgInfo("");
      //   setPrevPgInfo("");
      //   setIsPrevOrNext("");
    },
    onSearchChange: (searchText) => {
      if (searchText !== null) {
        //setDashboardSearchText(searchText);
      } else {
        //setDashboardSearchText("");
      }
    },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setDashboardSortColumn(sortColumn);
        await setDashboardSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setDashboardSortColumn(sortColumn);
        await setDashboardSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      setDashboardStart(page * dashboardPageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  async function handleSearchForCandidateIdDropdown(event, code, callback) {
    try {
      if (event?.length >= 1) {
        const data = await fetchCandidateDropdown(event, code);
        callback(data);
      } else {
        callback([]);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function GetCategoryOptionsAsync() {
    try {
      showLoader();
      const response = await APICall(GetCategoryOptions, "POST", {});
      if (response?.status == 0) {
        setCategoryOptions(response?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  }
  async function fetchCandidateDropdown(
    searchText = "",
    code = "",
    value = null
  ) {
    try {
      const response = await APICall(
        GetDepartmentDropdownData,
        "POST",
        {
          SearchText: searchText,
          userId: userDetails.Id,
          // CurrentStagesId: 5,
        },
        true
      );

      if (response?.status === 0) {
        if (response?.data) {
          return response?.data;
        } else {
          return [];
        }
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  function onChange(event, code) {
    try {
      setFormData((prev) => {
        return { ...prev, [code]: event };
      });
    } catch (error) {
    } finally {
    }
  }
  const getDDData = async (AC, PID) => {
    const dropdowndataResponse = await APICall(getDropdowns, "POST", {
      AC,
      PID,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    const dropdownData = dropdowndataResponse.data;

    return dropdownData;
  };
  const formOnChange = async (event, DDName) => {
    showLoader();

    if (DDName === "business") {
      setFormData((env) => {
        return {
          ...env,
          business: event,
          sbu: null,
          legalEntity: null,
          location: null,
        };
      });

      if (!isEmpty(event?.value)) {
        let sbuData = await getDDData("SBU", event?.value);
        setOptions((env) => {
          return {
            ...env,
            sbu: sbuData,
          };
        });
      }
    }

    if (DDName === "sbu") {
      setFormData((env) => {
        return {
          ...env,
          sbu: event,
          legalEntity: null,
          location: null,
        };
      });

      if (!isEmpty(event?.value)) {
        let LENData = await getDDData("LEN", event?.value);
        // let userLenCodes = ['13000005', '13000011'];
        // let CustomizedLegalEntity = LENData.filter(item=>{
        //   if(userLenCodes.includes(item?.code)){
        //     return item
        //   }
        // })
        setOptions((env) => {
          return {
            ...env,
            legalEntity: LENData,
          };
        });
      }
    }

    if (DDName === "legalEntity") {
      if (!isEmpty(event?.value)) {
        let PLOCData = await getDDData("PLOC", event?.value);
        setOptions((env) => {
          return {
            ...env,
            location: PLOCData,
          };
        });
      }
      setFormData((env) => {
        return {
          ...env,
          legalEntity: event,
          location:null,         
        };
      });
    }
    if (DDName === "payrollLocation") {
      setFormData((env) => {
        return {
          ...env,
          location:event,
        };
      });
    }
    hideLoader();
  };
  const getAllDropdownData = async (userRoleMappingData) => {
    if (userRoleMappingData) {
      let businessOptions = await getDDData("BISP", "");
      if (userRoleMappingData?.BIS.length > 0) {
        businessOptions = businessOptions?.filter((option) => {
          let foundOption = userRoleMappingData?.BIS?.find(
            (env) => env === option?.value
          );
          return foundOption !== undefined; 
         })
      }

     

      

      setOptions((env) => {
        return {
          ...env,
          business: businessOptions,
        };
      });
    }
    else{
      let businessOptions = await getDDData("BISP", "");
      setOptions((env) => {
        return {
          ...env,
          business: businessOptions,
        };
      });
    }
  };
  const getUserRoleMappingDataApiCall = async () => {
    debugger;
    const response = await APICall(getUserRoleMappingData, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    debugger;

    if (response?.status === 0) {
      let domainOUDetails = response?.data?.ouDetails;
      if (domainOUDetails !== null) {
        return JSON.parse(domainOUDetails);
      } else {
        return null;
      }
    }
  };
  const ExporttoExcel = async () => {
    try {
      showLoader();
      const response = await APICall(DownloadDepartmentExcel, "POST", {
        TokenId: formData?.id?.id,
        FromDate: formData?.dob
          ? moment(formData.dob).format("YYYY-MM-DD")
          : null,
        ToDate: formData?.todate
          ? moment(formData.todate).format("YYYY-MM-DD")
          : null,
        CategoryId:formData?.categoryId?.value|| 0,
        Business: !isEmpty(formData?.business?.value) ? formData?.business?.value : 0,
        SBU: !isEmpty(formData?.sbu?.value) ? formData?.sbu?.value : 0,
        LegalEntity: !isEmpty(formData?.legalEntity?.value) ? formData?.legalEntity?.value : 0,
        PayrollLocation : !isEmpty(location?.value) ? location?.value : 0,
        PageNumber: dashboardStart,
        PageSize: dashboardPageSize,
        SortColumn: dashboardSortColumn,
        SortOrder: dashboardSortColumn,
        IsExcel: true,
      });

      if (response?.status == 0) {
        const { base64 } = response?.data;
        const fileName = `DepartmentWiseReport.xlsx`;

        let arrayBuffer = await base64ToArrayBuffer(base64);
        var byteArray = new Uint8Array(arrayBuffer);

        let blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const newFile = new File([blob], "", {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          lastModified: new Date().getTime(),
        });

        fileDownload(newFile, fileName);
        notify(0, "File Downloaded Successfully.");
      } else if (response?.status == 5) {
        notify(1, response?.message);
      } else {
        console.error("handleDownloadTemplete", response?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  useEffect(() => {
    setlocation(locationOption[0]);
    (async () => {
      const userRoleMappingData = await getUserRoleMappingDataApiCall();

      await getAllDropdownData(userRoleMappingData);
    })();
  }, []);
  return (
    <>
      <div className="form-main">
        <div className="page-title w-100">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <div className="row">
                  <i
                    className="fa-solid fa-home mt-3 mr-3 ml-3"
                    style={{ color: "white", cursor: "pointer" }}
                    onClick={() => {
                      navigate("/ReportingDashboard");
                    }}
                  ></i>
                  <h4>Department wise candidate report</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row grid-wrapper d-flex align-items-center mt-3" style={{ marginLeft: '15px', marginRight: '15px' }}>
      <div className="d-flex align-items-center mb-1" style={{width:"100%"}}>
          <div className="p-0 mr-2 col-lg-2">
            {" "}
            {/* Added flex-grow-1 class for the label and select to take remaining space */}
            <label className="col-form-label text-white">From Date</label>
            <sup>*</sup>
            <DateForm
              placeholder={"From Date"}
              isDisabled={false}
              value={formData?.dob || null}
              onChange={(val: any) => {
                onChange(moment(val).format("MM-DD-YYYY"), "dob");
              }}
            />
          </div>

          <div className="p-0 mr-2 col-lg-2">
            {" "}
            {/* Added flex-grow-1 class for the label and select to take remaining space */}
            <label className="col-form-label text-white">To Date</label>
            <sup>*</sup>
            <DateForm
              placeholder={"To Date"}
              isDisabled={false}
              value={formData?.todate || null}
              onChange={(val: any) => {
                onChange(moment(val).format("MM-DD-YYYY"), "todate");
              }}
            />
          </div>

          <div className="p-0 mr-2 col-lg-2">
            <label className="col-form-label text-white">
              Department <span style={{ color: "red" }}></span>
            </label>
            <SelectForm
              isClearable
              async
              options={(searchString, cb) => {
                handleSearchForCandidateIdDropdown(
                  searchString,
                  "candidateId",
                  cb
                );
              }}
              placeholder={"Select department"}
              onChange={(val: any) => {
                onChange(val, "id");
                // setCandidateToken(val.id);
                // setIsFilterApplied(true);
              }}
              isMulti={false}
              noIndicator={false}
              noSeparator={false}
              value={formData?.id}
            />
          </div>
          <div
                className="p-0 mr-2 col-lg-2"
              >
                <label className="col-form-label text-white">Category</label>
                <sup>*</sup>
                <SelectForm
                  placeholder="Select Category"
                  isDisabled={false}
                  options={categoryOptions}
                  onChange={(val: any) => {
                    onChange(val, "categoryId");
                    // setCandidateToken(val.id);
                    // setIsFilterApplied(true);
                  }}
                  textArea={false}
                  value={formData?.categoryId}
                  noIndicator={false}
                  noSeparator={false}
                />
                
              </div>
          <button
            className="btn btn-filter-submit  mr-2"
            type={"button"}
            onClick={getCandidateStatus}
            style={{ display: "flex", marginLeft: "3px", marginTop: "35px" }}
          >
            <i className="fa-solid fa-magnifying-glass mr-2"></i> Search
          </button>

          <button
            className="btn btn-filter-submit  mr-2"
            type={"button"}
            onClick={() => {
              setFormData({ id: 0, dob: new Date(), todate: new Date(),categoryId: 0,
                business: null,
                sbu: null,
                legalEntity: null,
                location: null,
              });
                setOpen(false);
              setisRefresh((prev) => !prev);
            }}
            style={{ display: "flex", marginLeft: "3px", marginTop: "35px" }}
          >
            Reset
          </button>

          <button
            className="btn btn-filter-submit"
            type={"button"}
            onClick={ExporttoExcel}
            style={{ display: "flex", marginLeft: "3px", marginTop: "35px" }}
          >
            Export to Excel
          </button>
          <div className="col-lg-1 col-md-1 col-sm-12" style={{marginTop: "30px",display: "flex",justifyContent:"center" }}>
            <ul className=" filter-icon-wel">
              <li>
                <a
                  href="javascript:void(0);"
                  onClick={() => setOpen(!open)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                  className="filter-btn"
                >
                  <i className="fa fa-filter"></i>
                </a>
              </li>
            </ul>
          </div>
         
        </div>
           
          </div>
          <div className="col-lg-12 col-md-10 col-sm-12 ">
         <Collapse in={open}>
          <div id="example-collapse-text grid-wrapper">
            <div className="row mx-auto myInlineStyle px-2">
            <div className="p-0 mr-2 col-lg-2">
            <div className="mb-1">
            <label className="col-form-label text-white">
              Business
            </label>
                <SelectForm
                isClearable
                isSearchable
                  value={formData?.business}
                  placeholder={"Select Business"}
                  options={options?.business}
                  isOptionDisabled={(option) => option.isDisable}
                  onChange={async (e) => {
                    formOnChange(e, "business");
                  }}
                  isDisabled={false}
                  noIndicator={false}
                  noSeparator={false}
                />
                </div>
              </div>
              <div className="p-0 mr-2 col-lg-2">
              <div className="mb-1">
            <label className="col-form-label text-white">
              SBU
            </label>

            <SelectForm
            isClearable
                isSearchable
                value={formData?.sbu}
                placeholder={"Select SBU"}
                options={options?.sbu}
                isOptionDisabled={(option) => option.isDisable}
                onChange={async (e) => {
                  formOnChange(e, "sbu");
                }}
                isDisabled={false}
                noIndicator={false}
                noSeparator={false}
              />
              </div>
            </div>
            <div className="p-0 mr-2 col-lg-2">
            <div className="mb-1">
            <label className="col-form-label text-white">
              Legal Entity
            </label>
                <SelectForm
                isClearable
                isSearchable
                  value={formData?.legalEntity}
                  placeholder={"Select Legal Entity"}
                  options={options?.legalEntity}
                  onChange={async (e) => {
                    formOnChange(e, "legalEntity");
                  }}
                  isDisabled={false}
                  noIndicator={false}
                  noSeparator={false}
                />
                </div>
              </div>
               <div className="p-0 mr-2 col-lg-2">
                <div className="mb-1">
            <label className="col-form-label text-white">
            Location
            </label>
            <SelectForm
                isClearable
                isSearchable
                  value={formData?.location}
                  placeholder={"Select Location"}
                  options={options?.location}
                  onChange={async (e) => {
                    formOnChange(e, "payrollLocation");
                  }}
                  isDisabled={false}
                  noIndicator={false}
                  noSeparator={false}
                />
              </div>
             </div> 
            </div>
          </div>
        </Collapse>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12 mt-2" id="candidata-page1">
        <DynamicGrid
          // data={data.map((row) => Object.values(row))}
          data={dashboard}
          columns={columns}
          options={dashboardOptions}
        />
      </div>
    </>
  );
}

export default Departmentcandidate;
