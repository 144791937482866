import React, { useState, useEffect, useContext, useRef } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Tabs,
    Tooltip,
    Typography,
  } from "@mui/material";
import { APICall } from "../../Helpers/APICalls";
import { 
    getDropdowns,
    postPicklistBulkInsert
} from "../../Helpers/APIEndPoints/EndPoints";
import { LoaderContext } from "../../Helpers/Context/Context";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import notify from "../../Helpers/ToastNotification";
import * as xlsx from "xlsx";
import ExcelJS from "exceljs/dist/exceljs.min.js";
import { useLocation, useNavigate } from "react-router-dom";
import SelectForm from "../../Components/SelectForm/SelectForm";
import moment from "moment";

const PicklistBulkUpload = () => {
  let { userDetails, currentRoleId }: any = useUserContext();
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  
  const ouMappingCodes =[
    {value: 'PKL1', label: 'Picklist Data Bulk Upload', code: 'PicklistDataBulkUpload'},
  ];
  
  const [ddValue, setDdValue] = useState({'PicklistDataType':ouMappingCodes});
  const [pklTypeSelected, setPklTypeSelected] = useState(ddValue?.PicklistDataType?.[0]);
  const [pklTypeError, setpklTypeError] = useState(null);
  const supportingDocRef = useRef(null);
  const [file2, setFile2] = useState(null);
  const [result, setResult] = useState("");

  const checkDateArr = [
    "Effective Date",
  ];

  const dateFormats = [
    "DD/MM/YY",
    "DD/MM/YYYY",
    "DD-MMM-YY",
    "DD-MMM-YYYY",
    "DD/MMM/YY",
    "DD/MMM/YYYY",
    "DD-MMM-YYYY",
    "DD-MM-YYYY",
    "YYYY-MM-DD",
    "YYYY/MM/DD",
  ];

//   const getDropdownsData = async (AC, PID = null, cb) => {
//       await showLoader();
//       const dropdownData = await APICall(getDropdowns, "POST", {
//         AC,
//         PID,
//         UserId: userDetails?.Id,
//         RoleId: currentRoleId?.value,
//       });
//       if (
//         dropdownData.data !== null &&
//         dropdownData.data !== undefined &&
//         dropdownData.data.length > 0 
//       ) {
//         if(AC==='OUTypes'){
//           const ddValues = [];
//           ddValues.push({value: 'OrgBulkUpload', label: 'Organization Unit Data Insert', code: 'OrgBulkUpload'});
//           const filteredVals = dropdownData.data.filter((item)=> ouMappingCodes.includes(item.code));
//           filteredVals.map((item)=>{
//             ddValues.push(item);
//           });
//           setDdValue((prev) => ({ ...prev, [AC]: ddValues }));
//         }
//         else {
//           setDdValue((prev) => ({ ...prev, [AC]: dropdownData.data }));
//         }
//       } else {
//         setDdValue((prev) => ({ ...prev, [AC]: [] }));
//       }
//       await hideLoader();
//   };

//   const getAllData = async() => {
//     try {
//       await getDropdownsData('OUTypes', null, null);
//     } catch (error) {
//       console.error("Error fetching All Data in getAllData function: ", error);
//     }
//   };

  useEffect(() => {  
    // getAllData(); 
  },[]);

  const handleDownloadClick = async () => {
    let isValid = true;
    
    if (!pklTypeSelected) {
        setpklTypeError("Please select a value for Type.");
        isValid = false;
    }

    if (isValid) {
      try {
        const pklName = pklTypeSelected.label;
        const fileName = `${pklName}.xlsx`;

        let fName = "";
        if (fName) {
          // Download the specified file if fName is set
          const publicURL = `${process.env.PUBLIC_URL}/${fName}`;
          const response = await fetch(publicURL);
          
          if (response.status === 200) {
            // File exists, so trigger the download
            const blob = await response.blob();
            const dataURI = URL.createObjectURL(blob);
            
            const a = document.createElement("a");
            a.href = dataURI;
            a.download = fName;
            a.target = "_blank";
            a.click();
          } else {
            console.error(`File ${fName} not found in the public folder.`);
          }
        } else {
          // Proceed with the default download logic
          const publicURL = `${process.env.PUBLIC_URL}/${fileName}`;
          const response = await fetch(publicURL);
          
          if (response.status === 200) {
            // File exists, so trigger the download
            const blob = await response.blob();
            const dataURI = URL.createObjectURL(blob);
            
            const a = document.createElement("a");
            a.href = dataURI;
            a.download = fileName;
            a.target = "_blank";
            a.click();
          } else {
            console.error(`File ${fileName} not found in the public folder.`);
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  // Handle file2 upload
  const handleFile2Upload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      showLoader();
      const selectedFile = event.target.files[0];
      const fileType = await getFileType(selectedFile);

      if (!fileType || (fileType !== "xls" && fileType !== "xlsx")) {
        notify(1, "Please select an Excel file (xls or xlsx).");
        return;
      }
      event.target.value = "";
      setFile2(selectedFile);
    } catch (error) {
      console.error(error);
    } finally {
      setResult("");
      hideLoader();
    }
  };

  // Get the file type based on magic numbers
  const getFileType = (file: File) => {
    const magicNumbers = {
      xls: [208, 207, 17, 224],
      xlsx: [80, 75, 3, 4],
    };

    const reader = new FileReader();

    return new Promise((resolve) => {
      reader.onload = (e) => {
        const buffer = e.target.result as ArrayBuffer;
        const view = new Uint8Array(buffer);
        for (const fileType in magicNumbers) {
          if (magicNumbers.hasOwnProperty(fileType)) {
            const magicNumber = magicNumbers[fileType];
            let isMatch = true;
            for (let i = 0; i < magicNumber.length; i++) {
              if (magicNumber[i] !== view[i]) {
                isMatch = false;
                break;
              }
            }
            if (isMatch) {
              resolve(fileType);
              return;
            }
          }
        }
        resolve(null);
      };

      // Ensure that the 'file' parameter is a Blob before reading it.
      if (file instanceof Blob) {
        reader.readAsArrayBuffer(file);
      } else {
        resolve(null);
      }
    });
  };

  function convertDateFormatForSave(dateString) {
    //console.log('dateString', dateString)
    const parsedDate = moment.utc(dateString, dateFormats, true).locale("en");
    if (dateString.includes("/")) {
      return parsedDate.utc().format("DD-MMM-YYYY");
    } else if (moment(dateString, "DD-MM-YYYY", true).isValid()) {
      // If the input format is "DD-MM-YYYY", save it as "DD-MMM-YYYY"
      return parsedDate.utc().format("DD-MMM-YYYY");
    }
    return parsedDate.utc().format("DD-MMM-YYYY");
  }

  function convertExcelDateToJSDate(excelDate) {
    //console.log('excelDate', excelDate)
    const baseDate = new Date("1900-01-01T00:00:00Z");
    // Calculate the number of milliseconds from the base date
    const millisecondsSinceBaseDate = (excelDate - 2) * 24 * 60 * 60 * 1000;
    // Create a new Date object by adding milliseconds to the base date
    const jsDate = new Date(baseDate.getTime() + millisecondsSinceBaseDate);
    return jsDate;
  }

  function convertDateFormatForDisplay(dateString) {
    const parsedDate = moment.utc(dateString, dateFormats, true).locale("en");
    return parsedDate.utc().format("DD-MMM-YYYY");
  }

  // Handle file comparison
  const handleCompareFiles = async () => {
    await showLoader();
    let isValid = true;
    const mismatchedHeaders = []; // Store mismatched headers
    
    if (!pklTypeSelected) {
      setpklTypeError("Please select a Picklist Type.");
      isValid = false;
      hideLoader();
    }

    if (file2) {
        setResult(null);
    }
    const fileInput = document.getElementById("file2") as HTMLInputElement;
    if (!file2) {
        setResult("Please select a file for upload.");
        isValid = false;
        hideLoader();
    }
    // fileInput.value = "";
    if (isValid) {
      try {
        const pklName = pklTypeSelected.label; // Get the label of the selected tab
        const fileName = `${pklName}.xlsx`;
        // Read the local Excel file and convert it to a Blob
        const localExcelData = await readLocalExcelFile();
        const localExcelBlob = new Blob([localExcelData], {
          type: "application/octet-stream",
        });
      
        const reader1 = new FileReader();
        reader1.onload = async (event1) => {
          try {
            const file1Data = event1.target.result as ArrayBufferLike;
            
            const file2Blob = file2.slice(0, file2.size);
            const reader2 = new FileReader();
            reader2.onload = async (event2) => {
              try {
                const file2Data = event2.target.result as ArrayBufferLike;
                
                const userWorkbook: xlsx.WorkBook = xlsx.read(file2Data, {
                  type: "binary",
                });
                  
                const localWorkbook = xlsx.read(file1Data, { type: "binary" });
              
                const localSheetName = localWorkbook.SheetNames[0];
                const userSheetName = userWorkbook.SheetNames[0];
              
                const localSheet = localWorkbook.Sheets[localSheetName];
                const userSheet = userWorkbook.Sheets[userSheetName];
                  
                const isDataProvided = Array.from({ length: 9 }).some(
                  (_, rowIndex) => {
                    return !["A", "B", "C", "D", "E", "F", "G", "H", "I"].every(
                      (column) => {
                        const cellValue =
                          userSheet[
                            column + (rowIndex + 2).toString() // A2, B2, ..., A10, B10, ...
                          ];
                        return (
                          !cellValue ||
                          (!cellValue.v && cellValue.v !== 0) ||
                          !cellValue.v.toString().trim()
                        );
                      }
                    );
                  }
                );
                
                if (!isDataProvided) {
                  notify(1, "Template should not empty.");
                  hideLoader();
                  return;
                }
                  
                const localHeaders = [];
                const userHeaders = [];
              
                // Extract headers from both sheets
                for (const cell in localSheet) {
                  if (
                    cell !== "!ref" &&
                    cell !== "!margins" &&
                    cell.endsWith("1")
                  ) {
                    localHeaders.push(localSheet[cell].v);
                  }
                }
              
                for (const cell in userSheet) {
                  if (
                    cell !== "!ref" &&
                    cell !== "!margins" &&
                    cell.endsWith("1")
                  ) {
                    userHeaders.push(userSheet[cell].v);
                  }
                }
                  
                // Compare headers
                for (let i = 0; i < localHeaders.length; i++) {
                  if (localHeaders[i] !== userHeaders[i]) {
                    mismatchedHeaders.push({
                      localHeader: localHeaders[i],
                      userHeader: userHeaders[i],
                    });
                  }
                }
              
                // Check if user-selected file data from A2 to A10 is empty or deleted
                if (mismatchedHeaders.length === 0) {
                  // notify(0, "Column headers match.");
                  const localSheetData = xlsx.utils.sheet_to_json(localSheet, {
                    header: 1,
                  });
                  const userSheetData = xlsx.utils.sheet_to_json(userSheet, {
                    header: 1,
                  });
                    
                  // Create a new ExcelJS workbook
                  const workbook = new ExcelJS.Workbook();
                  const worksheet = workbook.addWorksheet("Sheet 1");
                    
                  // Add your data to the worksheet row by row
                  const formattedUserSheetData: any[][] = userSheetData.map(
                    (row: any[], rowIndex: number) => {
                      return row.map((cell: any, columnIndex: number) => {
                        // Get the column header name based on the columnIndex
                        const columnHeader = userHeaders[columnIndex];
                        
                        // Check if the cell contains a date string and the column header is "Event Effective Date"
                        if (
                          checkDateArr.includes(columnHeader) &&
                          moment(cell, dateFormats, true).isValid()
                        ) {
                          // For saving to the server in the desired format
                          const saveFormat = convertDateFormatForSave(cell);
                          return saveFormat;
                        } else if (
                          checkDateArr.includes(columnHeader) &&
                          cell > 0
                        ) {
                          // Excel Serial Date handling for numeric columns in "Event Effective Date" column
                          const regularDate = convertExcelDateToJSDate(cell);
                          return convertDateFormatForDisplay(regularDate);
                        }
                        
                        return cell;
                      });
                    }
                  );
                  
                  // Add your data to the worksheet row by row
                  for (const rowData of formattedUserSheetData) {
                    worksheet.addRow(rowData);
                  }
                    
                  // Convert the workbook to binary data
                  const buffer = await workbook.xlsx.writeBuffer();
                    
                  //console.log('buffer', buffer, formattedUserSheetData, worksheet);
                    
                  // Convert buffer to base64
                  const base64Data = btoa(
                    new Uint8Array(buffer).reduce(
                      (data, byte) => data + String.fromCharCode(byte),
                      ""
                    )
                  );
                  
                  const response = await APICall(postPicklistBulkInsert, "POST", {
                    UserId: userDetails.Id,
                    PicklistTypeCode: pklTypeSelected.code,
                    Data: base64Data,
                    FileName: fileName,
                  });
              
                  if (response.message === "Success") {
                    notify(
                      0,
                      `Bulk Upload Data Successful. Your request upload id is ${response.data}.`
                    );
                    supportingDocRef.current.value = null;
                    hideLoader();
                    navigate("/GenericMaster");
                  } else {
                    notify(
                      1,
                      `Bulk Upload Data Unsuccessful. ${response.message}`
                    );
                    hideLoader();
                  }
                } else {
                  notify(1, "The Column Headers do not Match");
                  hideLoader();
                }
              } catch (error) {
                console.log("Error reading user-selected file:", error);
                hideLoader();
              }
            };
              
            reader2.readAsBinaryString(file2Blob);
          } catch (error) {
            console.log("Error reading user-selected file:", error);
            hideLoader();
          }
        };
        reader1.readAsBinaryString(localExcelBlob);
      } catch (error) {
        console.log("Error comparing files:", error);
        hideLoader();
      }
    }
    setFile2(null);
  };

  const readLocalExcelFile = async () => {
    try {
        
      const pklName = pklTypeSelected.label; // Get the label of the selected tab
      // Read the regular local file
      const localFilePath = `${pklName}.xlsx`;
      const response = await fetch(localFilePath);
      const blob = await response.blob(); 
      return new Promise<Blob>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async (event) => {
          try {
            const data = event.target.result;
            const workbook = xlsx.read(data, { type: "array" });  
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = xlsx.utils.sheet_to_json(worksheet, {
              header: 1,
            });   
            //console.log("local Json Data:", jsonData);  
            resolve(blob as Blob);
          } catch (error) {
            reject(error);
          }
        };    
        reader.onerror = (error) => {
          reject(error);
        };    
        reader.readAsArrayBuffer(blob);
      });
    } catch (error) {
      console.error("Error reading local Excel file:", error);
      throw error;
    }
  };

  return (<>
    <div className="container-fluid">
      <Accordion
        className="mb-3"
        expanded={true}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          style={{ background: "#3C5464" }}
          className="text-white acc_close"
        >
          <div className="row d-flex align-items-center acc-heading">
            <div className="col-lg-10 col-md-10 col-sm-12">
              {"Picklist Data Bulk Upload"}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {/* Submit and Back Button */}
          <div className="row">
            <div className="col-md-12">
              <button
                type="button"
                onClick={
                    // ()=>{}
                  handleCompareFiles
                }
                className="btn btn-primary float-right"
              >
                Submit
              </button>
              <button
                onClick={() => {
                  navigate(-1);
                }}
                disabled={false}
                style={{marginRight:"5px"}}
                className="btn btn-secondary float-right"
              >
                <i className="fa-solid fa-arrow-left"></i> Back
              </button>
            </div>
          </div>
          <div className="row"
            style={{
              backgroundColor:"#dce6ff",
              margin: 1,
              marginTop: 15,
              borderRadius: 4,
              boxShadow: "0 3px 5px #ccc",
            }}
          >
            <div className="col-md-3">
              <div className="form-group">
                <label className="col-form-label" htmlFor="dropdown1">
                  Select a Picklist Type <sup>*</sup>
                </label>
                <SelectForm
                  isClearable
                  isSearchable
                  isMulti={false}
                  noIndicator={false}
                  noSeparator={false}
                  id="picklistDd"
                  value={pklTypeSelected}
                  placeholder="Select an option"
                  options={ddValue['PicklistDataType'] || []}
                  onChange={(e)=>{
                    setPklTypeSelected(e);
                  }}
                  isDisabled={true}
                />
                {pklTypeError && (
                  <p className="text-danger">{pklTypeError}</p>
                )}
              </div>
            </div>
            
            {/* Template Download */}
            <div className="col-md-2">
              <button
                type="button"
                onClick={handleDownloadClick}
                className="btn btn-primary"
                disabled={
                  pklTypeSelected == null 
                  && pklTypeSelected == undefined
                  ? true
                  : false
                }
                style={{
                  marginTop:"34px"
                }}
              >
                <i className="fa fa-download mr-2"></i>
                Template Download
              </button>
            </div>
            
            {/* Data Upload */}
            <div className="col-md-3">
              <div className="box position-relative">
                <input
                  id={"REQSUPDOC"}
                  className="form-control inputfile inputfile-6 multiple-inputfile"
                  multiple={false}
                  type="file"
                  ref={supportingDocRef}
                  onChange={handleFile2Upload}
                  disabled={false}
                />
                <label
                  htmlFor={"REQSUPDOC"}
                  className="form-control"
                  style={{ width: 0, border: "none" }}
                >
                  <strong
                    style={{
                      padding: "6px 16px",
                      backgroundColor: "#3c5464",
                      borderRadius: "5px",
                      marginTop: "34px"
                    }}
                  >
                    <i
                        className="fa fa-upload rotate90 mr-2"
                        aria-hidden="true"
                    ></i>
                    Upload
                  </strong>
                </label>
              </div>
              <div className="file-added-list"
                style={{
                  marginTop: "36px"
                }}
              >
                <ul className="list-unstyle">
                  {file2 && (
                    <>
                      <li className="list mt-1">
                        <div className="media">
                          <div className="media-body text-truncate">
                            <span className="view-more">{file2.name}</span>
                          </div>
                        {/* </div>
                        <div className="media-right ml-2"> */}
                          <i
                            className="fa fa-trash"
                            aria-hidden="true"
                            onClick={() => {
                              setFile2(null);
                              supportingDocRef.current.value = null;
                            }}
                          ></i>
                        </div>
                      </li>
                    </>
                  )}
                </ul>
              </div>
              {result && <p className="text-danger">{result}</p>}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  </>)
}

export default PicklistBulkUpload;