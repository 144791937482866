import React, { useState, useEffect, useContext } from "react";
import SelectForm from "../../Components/SelectForm/SelectForm";
import { Tooltip } from "@mui/material";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import InputForm from "../../Components/InputForm/InputForm";

const Trainer = () => {
  return (
    <>
      <div className="col-lg-12 col-sm-12 col-xs-12">
        <div className="row">
          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="dropdown mb-1">
              <label className="col-form-label">
                Journey<sup>*</sup>
              </label>
              <SelectForm
                isClearable
                options={[]}
                placeholder={"Target Learner Group Name"}
                isDisabled={false}
                onChange={(event) => {
                  // setJourney(event)
                }}
                isMulti={true}
                noIndicator={false}
                noSeparator={false}
                value={""}
              />
            </div>
          </div>

          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mt-3">
              <label className="col-form-label "></label>
              <button
                disabled={true}
                // onClick={}
                className="btn btn-primary mt-3 mr-2">
                <i className="fa fa-plus mr-2"></i>
                New
              </button>
            </div>
          </div>

          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mt-3">
              <label className="col-form-label "></label>
              <input
                disabled={false}
                id={`registrationByManager_2`}
                type="radio"
                name="registrationByManager"
                onChange={() => {}}
                //checked={launchType === 'scheduled'}
              />
              <label htmlFor={`registrationByManager_2`}>Condition</label>
            </div>
          </div>

          <div className="col-lg-3 col-sm-3 col-xs-4">
            <div className="mt-3">
              <label className="col-form-label "></label>
              <input
                disabled={false}
                id={`registrationByManager_2`}
                type="radio"
                name="registrationByManager"
                onChange={() => {}}
                //checked={launchType === 'scheduled'}
              />
              <label htmlFor={`registrationByManager_2`}>Employee List</label>
            </div>
          </div>
        </div>

        <div className="row">
          <div className={"col-lg-3 col-sm-3 col-xs-4"}>
            <div className="mb-1">
              <label className="col-form-label"> Documents</label>
            </div>
            <InputForm
              className="form-control"
              placeholder="upload a file"
              isDisabled={false}
              onChange={(e) => {}}
              textArea={false}
              value={""}
              maxLength="255"
            />
          </div>
          <div className={"col-lg-3 col-sm-3 col-xs-4"}>
            <div className="mb-4"></div>
            <div className="box position-relative">
              <input
                id={"REQSUPDOC"}
                className="form-control inputfile inputfile-6 multiple-inputfile"
                multiple={true}
                type="file"
                //   onChange={(e) => {
                //     ///handleSupportingDoc(e, "REQSUPDOC");
                //     handleFileChange(e);
                //   }}
                accept={".docx,.doc,.pdf,.png,.jpg,.jpeg,.txt,.ppt,.pptx,.xlsx"}
                disabled={false}
              />
              <label
                htmlFor={"REQSUPDOC"}
                className="form-control"
                style={{ width: 0, border: "none" }}>
                <strong
                  style={{
                    padding: "6px 16px",
                    backgroundColor: "#3c5464",
                    borderRadius: "5px",
                  }}>
                  <i className="fa fa-upload rotate90" aria-hidden="true"></i>
                  {"  "}
                  Upload
                </strong>{" "}
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Trainer;
