import React, { useContext, useEffect, useState } from "react";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { GetCandidateQueueData } from "../../Helpers/APIEndPoints/AssociateEndPoints";
import { APICall } from "../../Helpers/APICalls";
import { LoaderContext } from "../../Helpers/Context/Context";
import { useNavigate } from "react-router-dom";

const CandidateQueue = () => {
  const [dashboard, setdashboard] = useState([]);
  const [dashboardPageSize, setDashboardPageSize] = useState(10);
  const [dashboardStart, setDashboardStart] = useState(0);
  const [dashboardSortColumn, setDashboardSortColumn] = useState<any>("");
  const [dashboardSortDirection, setDashboardSortDirection] = useState("");
  const [dashboardCount, setDashboardCount] = useState(0);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate();

  // useEffect(() => {
  //   GetCandidateQueuing();
  // }, []);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     GetCandidateQueuing();
  //   }, 20000); // 20 seconds in milliseconds

  //   return () => clearInterval(intervalId); // Cleanup function to clear the interval on unmount
  // }, []);

  useEffect(() => {
    // Fetch data initially on component mount
    GetCandidateQueuing();

    // Setup interval to fetch data every 20 seconds
    const intervalId = setInterval(() => {
      GetCandidateQueuing();
    }, 60000);

    // Cleanup function to clear the interval on unmount
    return () => clearInterval(intervalId);
  }, []);
  const GetCandidateQueuing = async () => {
    showLoader();
    const response = await APICall(GetCandidateQueueData, "POST", {});

    console.log(response?.data, "CandidateQueuing");

    if (response?.data != null) {
      setdashboard(response?.data);
    }
    hideLoader();
  };

  const gridColumns = [
    {
      name: "counter",
      label: "Counter",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    // {
    //   name: "document",
    //   label: "Candidate Authentication",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     sortDescFirst: true,
    //   },
    // },
    {
      name: "medical",
      label: "Medical Check",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "skillTest",
      label: "Skill Test",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    // {
    //   name: "uan",
    //   label: "UAN",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     sortDescFirst: true,
    //   },
    // },

    {
      name: "offerGeneration",
      label: "Offer Generation",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
  ];

  const dashboardOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: dashboardCount,
    rowsPerPage: dashboardPageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    onChangeRowsPerPage: (num) => {
      //   setLimit(num);
      //   setNxtPgInfo("");
      //   setPrevPgInfo("");
      //   setIsPrevOrNext("");
    },
    onSearchChange: (searchText) => {
      if (searchText !== null) {
        //setDashboardSearchText(searchText);
      } else {
        //setDashboardSearchText("");
      }
    },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setDashboardSortColumn(sortColumn);
        await setDashboardSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setDashboardSortColumn(sortColumn);
        await setDashboardSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      setDashboardStart(page * dashboardPageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };
  return (
    <>
      <div className="form-main">
        <div className="page-title w-100">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <div className="row">
                  <i
                    className="fa-solid fa-home mt-3 mr-3 ml-3"
                    style={{ color: "white", cursor: "pointer" }}
                    onClick={() => {
                      navigate("/Bluecollar");
                    }}
                  ></i>
                  <h4>Candidate Queue</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-3 pt-3" id="candidata-page">
        <DynamicGrid
          data={dashboard}
          columns={gridColumns}
          options={dashboardOptions}
        />
      </div>
    </>
  );
};

export default CandidateQueue;
