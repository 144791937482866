import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoaderContext } from "../../Helpers/Context/Context";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { APICall } from "../../Helpers/APICalls";
import {
  generateUserCertificate,
  getCertificateDasboardFilter,
  getCertificateDasboardList,
  getLMSDomainDropDownData,
  getUserAndRoleWiseAccess,
  getUserCertificateDashboardData,
  getUserCertificateFile,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import notify from "../../Helpers/ToastNotification";
import { Collapse, Tooltip } from "@mui/material";
import InputForm from "../../Components/InputForm/InputForm";
import SelectForm from "../../Components/SelectForm/SelectForm";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import defaultProfileImgPic from "../../Assets/Images/thumbnail.jpg";
import { Card, Modal } from "react-bootstrap";
import fileDownload from "js-file-download";
import { base64ToArrayBuffer } from "../../Helpers/Common";
import ImageColumnComponent from "../../Components/LMS/ImageColumnComponent/ImageColumnComponent";

const UserCertificateDashboard = () => {
  const { state } = useLocation();
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  let { userDetails, currentRoleId }: any = useUserContext();

  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [PageSize, setPageSize] = useState(10);

  const [sortDirection, setSortDirection] = useState("");
  const [sortColumn, setSortColumn] = useState("");

  const [reSet, setreSet] = useState(false);

  const [certificateId, setCertificateId] = useState([]);
  const [certificateIdoptions, setCertificateIdoptions] = useState([]);

  const [certificateName, setCertificateName] = useState([]);
  const [certificateNameoptions, setCertificateNameoptions] = useState([]);

  const [createdBy, setCreatedBy] = useState([]);
  const [createdByOptions, setCreatedByOptions] = useState([]);

  const [domain, setDomain] = useState([]);
  const [domainOptions, setDomainOptions] = useState([]);

  const [status, setStatus] = useState(null);
  const [statusOptions, setStatusOptions] = useState([]);

  const [remarks, setRemarks] = useState("");

  const [showModal, setShowModal] = useState(false);

  const [modalTitle, setModalTitle] = useState("");

  const [isActive, setIsActive] = useState(false);
  const [id, setId] = useState(null);

  const [refresh, setRefresh] = useState(false);

  interface userAndRoleWiseAccess {
    isView?: boolean;
    isEdit?: boolean;
    isDelete?: boolean;
  }

  const [userAndRoleWiseData, setUserAndRoleWiseData] =
    useState<userAndRoleWiseAccess>({});

  const [UserCertificateData, setUserCertificateData] = useState([]);

  const getUserCertificateData = async () => {
    const response = await APICall(getUserCertificateDashboardData, "POST", {
      UserId: userDetails?.Id,
    });

    if (
      response?.status === 0 &&
      response.data !== null &&
      response.data.length > 0
    ) {
      setUserCertificateData(response.data);
    } else if (
      response?.status === 0 &&
      response.data !== null &&
      response.data.length === 0
    ) {
      setUserCertificateData([]);
      notify(1, "You have no certificates");
    } else {
      setUserCertificateData([]);
      notify(response?.status, response?.message);
      console.log("err", response);
    }
  };

  useEffect(() => {
    getUserCertificateData();
  }, [refresh]);

  const getUserAndRoleWiseAccessApiCall = async () => {
    const response = await APICall(getUserAndRoleWiseAccess, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      InterfaceName: "Courses_Dashboard",
    });

    if (response?.status === 0) {
      setUserAndRoleWiseData(response?.data);
    } else {
      notify(response?.status, response?.message);
    }
  };

  // useEffect(() => {
  //   const responseData = async () => {
  //     const objCertificateId = {
  //       mode: "CERTIFICATECODE",
  //       userId: userDetails?.Id,
  //       roleId: currentRoleId?.value,
  //     };
  //     const res = await APICall(
  //       getCertificateDasboardFilter,
  //       "POST",
  //       objCertificateId
  //     );
  //     if (res.data && res.data.length > 0) {
  //       setCertificateIdoptions(res.data);
  //     }

  //     const objCertificateName = {
  //       mode: "CERTIFICATENAME",
  //       userId: userDetails?.Id,
  //       roleId: currentRoleId?.value,
  //     };
  //     const res1 = await APICall(
  //       getCertificateDasboardFilter,
  //       "POST",
  //       objCertificateName
  //     );
  //     if (res1.data && res1.data.length > 0) {
  //       setCertificateNameoptions(res1.data);
  //     }

  //     const objCreatedBy = {
  //       mode: "CREATEDBY",
  //       userId: userDetails?.Id,
  //       roleId: currentRoleId?.value,
  //     };
  //     const res2 = await APICall(
  //       getCertificateDasboardFilter,
  //       "POST",
  //       objCreatedBy
  //     );
  //     if (res2.data && res2.data.length > 0) {
  //       setCreatedByOptions(res2.data);
  //     }

  //     const objStatus = {
  //       mode: "ACTIVE",
  //       userId: userDetails?.Id,
  //       roleId: currentRoleId?.value,
  //     };
  //     const res4 = await APICall(
  //       getCertificateDasboardFilter,
  //       "POST",
  //       objStatus
  //     );
  //     if (res4.data && res4.data.length > 0) {
  //       setStatusOptions(res4.data);
  //     }
  //   };

  //   showLoader();

  //   responseData();
  //   getLMSDomainDropDownDataApiCall();
  //   getUserAndRoleWiseAccessApiCall();

  //   hideLoader();
  // }, []);

  const getLMSDomainDropDownDataApiCall = async () => {
    const response = await APICall(getLMSDomainDropDownData, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      GetAll: true,
    });

    if (response?.status === 0 && response?.data.length > 0) {
      setDomainOptions(response?.data);
    }
  };

  // useEffect(() => {
  //   getCertificateDasboardDetailsAPICall();
  // }, [page, PageSize, sortColumn, sortDirection, searchText, reSet]);

  const getCertificateDasboardDetailsAPICall = async () => {
    showLoader();

    try {
      const parameters = {
        PageNumber: page,
        PageSize: PageSize,
        SortColumn: sortColumn,
        SortOrder: sortDirection,
        SearchText: searchText,
        CertificateCodeIds: certificateId?.map((s) => s.value).join(","),
        CertificateNameIds: certificateName?.map((s) => s.value).join(","),
        CreatedByIds: createdBy?.map((s) => s.value).join(","),
        IsStatus: status?.value,
        // DomainIds: domain?.map((s) => s.value).join(","),
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      };
      const GetDashboardData = await APICall(
        getCertificateDasboardList,
        "POST",
        parameters
      );
      if (
        GetDashboardData &&
        GetDashboardData.data !== null &&
        GetDashboardData.data.length > 0
      ) {
        setRowData(GetDashboardData.data);
        setCount(GetDashboardData.data[0].count);
      } else if (GetDashboardData?.status === 1) {
        notify(1, GetDashboardData?.message);
        setRowData([]);
        setCount(0);
      } else {
        setRowData([]);
        setCount(0);
      }
    } catch (error) {
      console.error(error);
    }

    hideLoader();
  };

  const activateandDeactivateFunction = async (id: any, isActive: any) => {
    if (remarks === "" || remarks === null) {
      notify(1, "Please enter remarks");
    } else {
      showLoader();
      // const { data } = await APICall(deactivateContentDetailById, "POST", {
      //   Id: id,
      //   IsActive: isActive,
      //   Remarks: remarks,
      //   CreatedBy: userDetails?.Id,
      // });

      // setShowModal(false);
      // setRemarks("");
      // if (data != null) {
      //   if (!isActive) {
      //     notify(0, "Assessment has been successfully Activated");
      //   } else {
      //     notify(0, "Assessment has been successfully Deactivated");
      //   }
      //   setreSet(!reSet);
      //   hideLoader();
      // } else {
      //   notify(1, "Something went wrong");
      // }
      hideLoader();
    }
  };

  const showActivateandDeactivateModal = async (id: any, isActive: any) => {
    setShowModal(true);
    setId(id);
    setIsActive(isActive);
    if (isActive) {
      setModalTitle("Do you want to Inactive the Assessment?");
    } else {
      setModalTitle("Do you want to active the Assessment?");
    }
  };

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: PageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      setPage(page * PageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };
  const gridColumns = [
    {
      name: "Id",
      label: "Id",
      options: {
        display: false,
      },
    },

    {
      name: "certificateCode",
      label: "Certificate ID",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "certificateName",
      label: "Certificate Name",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta) => {
          let id = tableMeta.tableData[tableMeta.rowIndex].id;
          let isActive = tableMeta.tableData[tableMeta.rowIndex].isActive;

          let color = isActive ? "green" : "red";
          let activate = isActive ? "Active" : "Inactive";

          return (
            <div style={{ width: "100px" }}>
              {userAndRoleWiseData?.isEdit && (
                <>
                  <Tooltip title="Edit">
                    <a
                      className="mr-2"
                      style={{ fontSize: "15px" }}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/certificateEdit", {
                          state: { certificateId: value, isDisable: false },
                        });
                      }}>
                      <i className="fas fa-edit"></i>
                    </a>
                  </Tooltip>
                  <Tooltip title={activate}>
                    <a
                      className="mr-2"
                      style={{ fontSize: "15px" }}
                      onClick={() => {
                        showActivateandDeactivateModal(id, isActive);
                      }}>
                      <i
                        className="fa-solid fa-circle"
                        style={{ color: color }}></i>
                    </a>
                  </Tooltip>
                </>
              )}

              {!userAndRoleWiseData?.isEdit && userAndRoleWiseData?.isView && (
                <Tooltip title="View">
                  <a
                    className="mr-2"
                    style={{ fontSize: "15px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/certificateEdit", {
                        state: { certificateId: value, isDisable: true },
                      });
                    }}>
                    <i className="fas fa-eye"></i>
                  </a>
                </Tooltip>
              )}
            </div>
          );
        },
      },
    },
  ];

  const onSubmitFilter = () => {
    if (
      certificateId?.length === 0 &&
      certificateName?.length === 0 &&
      createdBy?.length === 0 &&
      //   domain?.length === 0 &&
      status === null
    ) {
      notify(
        1,
        "Please select at least one filter option from the grid view to perform the search action."
      );
    } else {
      setPage(0);
      setreSet(!reSet);
      // getAssessmentDasboardDetailsAPICall();
    }
  };

  const resetFilters = () => {
    setPage(0);
    setSearchText("");
    setCertificateId([]);
    setCertificateName([]);
    setCreatedBy([]);
    // setDomain([]);
    setStatus(null);

    setreSet(!reSet);
  };

  const getUserCertificate = async (data) => {
    showLoader();
    const response = await APICall(
      data.filePath !== null && data.filePath !== ""
        ? getUserCertificateFile
        : generateUserCertificate,
      "POST",
      {
        UserId: userDetails.Id,
        CourseScheduleId: data.courseScheduleId,
        ThumbnailUrl: data.filePath,
        IsPreview: false,
      }
    );

    if (response.status === 0 && response.data !== null) {
      const base64 = response?.data;
      const fileName = `Certificate.pdf`;

      let arrayBuffer = await base64ToArrayBuffer(base64);
      var byteArray = new Uint8Array(arrayBuffer);

      let blob = new Blob([byteArray], {
        type: "application/pdf",
      });
      const newFile = new File([blob], "", {
        type: "application/pdf",
        lastModified: new Date().getTime(),
      });
      fileDownload(newFile, fileName);
    } else {
      notify(1, "Something went wrong try again later");
    }
    hideLoader();

    if (data.filePath === null || data.filePath === "") {
      setRefresh((prev) => !prev);
    }
  };

  return (
    <>
      <div className="breadcrumb modified-bredcrumb">
        <span>
          <ul>
            <li>
              <a href="/LMSHome">Home</a>
            </li>
            <li>User Certificate</li>
          </ul>
        </span>
        <button
          onClick={() => {
            navigate("/LMSHome");
          }}
          disabled={false}
          className="btn btn-secondary ml-2 float-right">
          <i className="fa-solid fa-arrow-left"></i> Back
        </button>{" "}
      </div>
      <div className="container-fluid">
        {/* <div className="grid-wrapper grid_mob">
          <div className="row mx-auto filtBox">
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="form-group filtInp">
                <InputForm
                  value={searchText}
                  placeholder={"Search Keywords"}
                  isDisabled={false}
                  textArea={false}
                  onChange={(e) => {
                    setPage(0);
                    setSearchText(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-12 filtrt">
              <ul className=" filter-icon-wel">
                <li>
                  <a
                    href="javascript:void(0);"
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                    className="filter-btn">
                    <i className="fa fa-filter"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <>
          <Collapse in={open}>
            <div id="example-collapse-text grid-wrapper">
              <div className="row mx-auto myInlineStyle">
                <>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">
                      Certificate ID
                    </label>
                    <SelectForm
                      isClearable
                      options={certificateIdoptions}
                      placeholder={"Certificate ID"}
                      isDisabled={false}
                      onChange={(event) => {
                        setCertificateId(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={certificateId}
                    />
                  </div>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">
                      Certificate Name
                    </label>
                    <SelectForm
                      isClearable
                      options={certificateNameoptions}
                      placeholder={"Certificate Name"}
                      isDisabled={false}
                      onChange={(event) => {
                        setCertificateName(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={certificateName}
                    />
                  </div>

                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">
                      Created By
                    </label>
                    <SelectForm
                      isClearable
                      options={createdByOptions}
                      placeholder={"Created By"}
                      isDisabled={false}
                      onChange={(event) => {
                        setCreatedBy(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={createdBy}
                    />
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">Status</label>
                    <SelectForm
                      isClearable
                      options={statusOptions}
                      placeholder={"Status"}
                      isDisabled={false}
                      onChange={(event) => {
                        setStatus(event);
                      }}
                      isMulti={false}
                      noIndicator={false}
                      noSeparator={false}
                      value={status}
                    />
                  </div>
               
                </>
                <div className="col-lg-12 d-flex mt-2 justify-content-end">
                  <button
                    onClick={() => resetFilters()}
                    className="btn btn-secondary mr-2">
                    <i className="fa fa-undo" aria-hidden="true"></i> Reset{" "}
                  </button>
                  <button
                    className="btn btn-filter-submit"
                    onClick={() => onSubmitFilter()}>
                    <i className="fa fa-search" aria-hidden="true"></i> Search{" "}
                  </button>
                </div>
              </div>
            </div>
          </Collapse>
        </> */}

        <div className="container-fluid">
          <div className="row">
            {UserCertificateData.length > 0 &&
              UserCertificateData.map((certificate) => (
                <div
                  key={certificate.courseScheduleId}
                  className="col-lg-3 col-md-4 col-xs-4 my-2">
                  <Card style={{ width: "18rem" }}>
                    <ImageColumnComponent
                      link={certificate.thumbnailUrl}
                      width="auto"
                    />
                    <Card.Body>
                      <h6>
                        <i
                          className="fa fa-graduation-cap text-primary"
                          aria-hidden="true"></i>{" "}
                        {certificate.course}
                      </h6>
                      <div>
                        <p>
                          <i
                            className="fa fa-calendar-o text-primary"
                            aria-hidden="true"></i>{" "}
                          <b>Start Date:</b> {certificate.startDate}
                        </p>
                        <p>
                          <i
                            className="fa fa-calendar-o text-primary"
                            aria-hidden="true"></i>{" "}
                          <b>End Date:</b> {certificate.endDate}
                        </p>
                      </div>
                      <Tooltip title="Download">
                        <button
                          className="mt-2 btn btn-filter-submit"
                          onClick={() => getUserCertificate(certificate)}>
                          Download Certificate
                        </button>
                      </Tooltip>
                    </Card.Body>
                  </Card>
                </div>
              ))}
          </div>
          {/* <DynamicGrid data={rowData} columns={gridColumns} options={options} /> */}
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">{`${modalTitle}`}</div>
        </div>
        <Modal.Body>
          <>
            <div className="row">
              <div className="col-md-8">
                <label className="col-form-label">Remarks</label>
                <sup>*</sup>
                <InputForm
                  value={remarks}
                  placeholder={"Remarks"}
                  isDisabled={false}
                  textArea={true}
                  onChange={(e) => {
                    setRemarks(e.target.value);
                  }}
                />
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => activateandDeactivateFunction(id, isActive)}>
            Submit
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowModal(false);
            }}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserCertificateDashboard;
