import React from "react";

const Footer = (props) => {
  const currentYear = new Date().getFullYear();
  const nextYear = currentYear + 1;
  return (
    <>
      <footer className="footer pr-2">
        <div className="row">
          <div className="col-lg-12 text-center">
            {/* <p>{props.footerData.text}© </p> */}
            <p>© Copyright {currentYear} Welspun World, All Rights Reserved.</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
