import React from "react";
import "./AccessDenied.css";
import { Container } from "react-bootstrap";
export default function AccessDenied() {
  return (
    <Container>
      <div className="access-body">
        <i
          className="fa fa-lock"
          style={{ fontSize: 200, color: "lightgrey" }}></i>
        <h2 className="denied-h2">
          <strong>Access denied</strong>
        </h2>
        <h4>You don't have permission to access this page.</h4>
      </div>
    </Container>
  );
}
