import React, { useState, useEffect, useContext } from "react";
import { APICall } from "../../Helpers/APICalls";
import { LoaderContext } from "../../Helpers/Context/Context";
import "./FormStructure.css"; 
import { getSectionDetailsV2,getAttributeValueDetailsV2 } from "../../Helpers/APIEndPoints/EndPoints";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import SectionComponentPrintAndPreview from "./SectionComponentPrintAndPreview";

const PrintPreviewSectionComponent = ({ printPreviewValueForApiCall, printValueSubmit,printChangesAccordion,allSectionsLoaded,setAllSectionsLoaded,}) => {
  const [filteredsectionApiValue, setFilteredsectionApiValue] = useState([]);
  const [filterdtabApiValue, setFilterdtabApiValue] = useState([]);
  let { userDetails, proxyUserDetails, currentRoleId }: any = useUserContext();
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [sectionApiValue, setSectionApiValue] = useState([]);
  const [tabApiValue, setTabApiValue] = useState([]);
  const { tabsValue, sectionValue } = printValueSubmit;
  const { TId, COU, isNewHire,setTId,globalValues,effectiveDate } = printPreviewValueForApiCall;
  const [apiCallsCompleted, setApiCallsCompleted] = useState(false);
  const getSectionDetails = async (tn) => {
    showLoader();
    try {
      const response = await APICall(getSectionDetailsV2, "POST", {
        MN: "EmployeeCentral",
        IN: "EC_Form",
        TN: tn,
        UID: proxyUserDetails?.value ? proxyUserDetails.value : userDetails?.UserId,
        TID: TId,
        RoleId: currentRoleId?.value ? currentRoleId.value : null,
        COU: COU,
        IsNewHire: isNewHire,
      });

      if (response?.status === 0) {
        if (response?.data?.t?.S) {
          setSectionApiValue((prev) => [...prev, ...response.data.t.S]);
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error in Section Api Call:", error);
      return null;
    } finally {
      hideLoader();
    }
  };

  const getAttributeDetails = async (tn) => {
    showLoader();
    try {
      const response = await APICall(getAttributeValueDetailsV2, "POST", {
        mn: "EmployeeCentral",
        in: "EC_Form",
        tn: tn,
        SN: "Basic",
        TID: TId,
        ISH: "true",
        UserId: proxyUserDetails?.value ? proxyUserDetails.value : userDetails?.UserId,
        RoleId: currentRoleId?.value ? currentRoleId.value : null,
        COU: COU,
      });

      if (response?.status === 0) {
        if (response?.data?.sections) {
          setTabApiValue((prev) => [...prev, ...response.data.sections]);
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error in Attribute Api Call:", error);
      return null;
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      showLoader();
      try {
       
        for (const tab of tabsValue) {
          await Promise.all([getSectionDetails(tab.tabSystemName), getAttributeDetails(tab.tabSystemName)]);
        }
        setApiCallsCompleted(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      finally{
        hideLoader();
      }
    };
    fetchData();
  }, [tabsValue]); 

  useEffect(() => {
    showLoader();
    try{
    if (apiCallsCompleted) {
      debugger;
      const filteredSectionApiValues = [];
      const filteredTabApiValues = [];
      sectionValue.forEach((section) => {
        const filteredSections = sectionApiValue.filter((apiSection) => apiSection.SId === section.value);
        filteredSectionApiValues.push(...filteredSections);
        const filterdTabs = tabApiValue.filter((apiTabs) => apiTabs.sectionSystemName === section.sectionSystemName);
        filteredTabApiValues.push(...filterdTabs);
      });
      setFilteredsectionApiValue([...filteredSectionApiValues]);
      setFilterdtabApiValue([...filteredTabApiValues]);
      //console.log("length",filteredSectionApiValues.length,sectionValue.length,sectionValue)
      if (filteredSectionApiValues.length <= sectionValue.length) {
        setAllSectionsLoaded(true);
      }
    }
  }
  catch(error){
    console.error("Error in Filtering Data",error)
    return null
  }
  finally{
    hideLoader();
  }
  }, [apiCallsCompleted, sectionValue, sectionApiValue, tabApiValue]);
  // if (isLoading) {
  //   return <Loader />;
  // }
  if (!allSectionsLoaded) {
    return null;
  }
  
  return (
    <>
    <div className="page-break-customized"/>
       {filteredsectionApiValue.map((eachSection, index) => (
              <React.Fragment key={`${index}-${eachSection.SN}`}>
                <SectionComponentPrintAndPreview
                  key={`${index}-${eachSection.SN}_Sec`}
                  formData={filteredsectionApiValue}
                  setFormData={setFilteredsectionApiValue}
                  section={eachSection}
                  sectionIndex={index}
                  TId={TId}
                  setTId={setTId}
                  sectionValuesArray={filterdtabApiValue}
                  SetSectionValuesArray={setFilterdtabApiValue}
                  Globalv={globalValues}
                  COU={COU}
                  submitFunc={false}
                  setGetData={[]}
                  payrolldd={{}}
                  countrydd={{}}
                  enableForTextileWorkerWfl = {false}
                  effectiveDate = {effectiveDate}
                  printChangesAccordion = {printChangesAccordion}
                />
              </React.Fragment>
            ))}
    </>
  );
};

export default PrintPreviewSectionComponent;
