import React, { useContext, useEffect, useState, createContext } from "react";
import { Avatar, Breadcrumbs, Tabs, Tooltip } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import "../MRF/MRF.css";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DoneIcon from "@mui/icons-material/Done";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import profileImg from "../../Assets/Images/profile.jpeg";
import { APICall } from "../../Helpers/APICalls";
import { LoaderContext } from "../../Helpers/Context/Context";
import {
  getAttributeValueDetailsV2,
  getSectionDetailsV2,
  getTabsData,
  getTabHeaderDetails,
  getPositionDetailsForMRF,
  getCurrentWFStage,
  GetJobIdBasedOnMRFId,
  CheckScreeningQuestionsById,
  GetRolesURL,
  GetPositionVacantStatusByMRFId,
  UpdateMRFStatus,
  GetTALeadAndRecruiterIdByMRFId,
  CheckStatusWhenMRFTryToReopen,
} from "../../Helpers/APIEndPoints/EndPoints";
import { useLocation, useNavigate  } from "react-router-dom";
import ScreeningQuestion from "../MRF/Components/ScreeningQuestion";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import StagesAndResponsibility from "../MRF/Components/StagesAndResponsibility";
import Stages from "../MRF/Components/Stages";
import { statusGridColumns, tabsTheme } from "../MRF/MRFHelper";
import ApproverSection from "../MRF/Components/ApproverSection";
import SectionComponent from "../MRF/Components/MRFSectionComponent";
import WorkflowSection from "../MRF/Components/WorkflowSectionComponent";
import SelectForm from "../../Components/SelectForm/SelectForm";
import notify from "../../Helpers/ToastNotification";

// loader context
// @ts-ignore
import { MRFContext } from "../MRF/MRF";
import CandidateDashboard from "../Recruiter/CandidateDashboard";

const MRFAdmin_MRF = () => {
  const { state } = useLocation();
  const [formData, setFormData] = useState<any>({});
  const [tabs, setTabs] = useState([]);
  const [activetab, setActivetab] = useState<string>(null);
  const [activetabIndex, setActivetabIndex] = useState<number>(0);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [TId, setTId] = useState((state !== null && state.TId) || 0);
  const [Type, setType] = useState((state !== null && state.Type) || false);
  const [COU, setCOU] = useState((state !== null && state.COU) || "");
  const [headerValues, setHeaderValues] = useState<any>(null);
  const [sectionValuesArray, setSectionValuesArray] = useState<any>([]);
  let { userDetails, currentRoleId }: any = useUserContext();
  const navigate = useNavigate();
  const [accordion, setAccordion] = useState(true);
  const [statusGridData, setStatusGridData] = useState({
    count: 0,
    start: 0,
    pageSize: 10,
    searchText: "",
    sortColumn: "",
    sortDirection: "",
    data: [],
  });
  const [attributeDataForPos, setAttributeDataForPos] = useState<any>({});
  const [hideSectionAndAttribute, setHideSectionAndAttribute] = useState(true);
  const [positionIdDetails, setPositionIdDetails] = useState(null);

  const [positionCountryIdDetails, setPositionCountryDetails] = useState(null);
  const [disableAttribute, setDisableAttribute] = useState(false);
  const [sendback, setSendback] = useState(false);
  const [jobId, setJobId] = useState(0);
  const [isScreeningSubmitted, setIsScreeningSubmitted] = useState(false);
  const [workflowApprovers, setWorkflowApprovers] = useState([]);
  const [showStagesAndResponsbility, setShowStagesAndResponsbility] =
    useState(false);
  const [showMRFStatus, setShowMRFStatus] = useState(false);
  const [mrfStatuDisable, setMRFStatusDisable] = useState(false);
  const [mrfStatusValue, setMRFStatusValue] = useState(null);
  const [mrfStatusOptions, setMRFStatusOptions] = useState([]);
  const [currentWFStage, setCurrentWFStage] = useState(0);
  const [roles, setRoles] = useState([]);
  const [reset, setReset] = useState(false);
  const [isMRFOpen, setIsMRFOpen] = useState(false);

  enum RolesSystemName {
    SystemAdmin = "systemadmin",
    Recruiter = "recruiter",
    TALead = "talead",
  }

  useEffect(() => {
    if (state?.type == "edit" && state?.sendback == true) {
      setSendback(true);
      setDisableAttribute(true);
    } else {
      setSendback(false);
    }

    return () => {};
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      debugger;
      if (TId !== 0) {
        setHideSectionAndAttribute(false);
      } else {
        setHideSectionAndAttribute(true);
      }
      await GetJobIdBasedOnMRFIdAsync();
      await GetTabDataAsync();
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (activetab !== null) {
      const fetchData = async () => {
        if (TId !== 0 && headerValues === null) {
          setSectionValuesArray([]);
          setFormData({});
          await GetTabHeaderDetailsAsync();
        }
        if (TId !== 0) {
          if (activetab == "JobDescription" || activetab == "Job_MRF_Admin") {
            setHideSectionAndAttribute(false);
          }
          await GetJobIdBasedOnMRFIdAsync();
        }

        await GetAttributeValueDetailsAsync();
        await GetSectionAsync();
      };

      fetchData();
    }
  }, [activetab]);

  useEffect(() => {
    const fetchData = async () => {
      if (TId !== 0) {
        await GetTabHeaderDetailsAsync();
      }

      const currentWFStage = await APICall(getCurrentWFStage, "POST", {
        MRFId: TId,
      });

      if (currentWFStage.data !== null) {
        // mrfId is actualt current stage returned from sp
        setCurrentWFStage(currentWFStage.data.MRFId);
      }
    };
    fetchData();
  }, [TId, reset]);

  useEffect(() => {
    const fetchData = async () => {
      if (
        positionIdDetails &&
        Object.keys(positionIdDetails).length > 0 &&
        positionCountryIdDetails &&
        Object.keys(positionCountryIdDetails).length > 0
      ) {
        if (
          activetab != null &&
          (activetab == "Requisition" || activetab == "Requisition_MRF_Admin")
        ) {
          await GetSectionAsync();

          if (TId != 0) {
            await GetAttributeValueDetailsAsync();
          }

          if (positionIdDetails && positionIdDetails?.value) {
            await getPositionDetails();
          }
        }
        debugger;
        setHideSectionAndAttribute(false);
      } else {
        setHideSectionAndAttribute(true);
      }
    };

    fetchData();
  }, [positionIdDetails, positionCountryIdDetails, TId]);

  useEffect(() => {
    const helperFunc = async () => {
      if (TId > 0) {
        const [showStatus, isVacant] = await Promise.all([
          getRolesAndSetStatus(),
          handleGetPositionisVacantOrNot(),
        ]);

        setMRFStatusDisable(!isVacant);
        setShowMRFStatus(showStatus === true);
        const { options, value } = getOptionsBasedOnCurrentStatus(
          headerValues?.mrfStatus
        );
        setMRFStatusValue(value);
        setMRFStatusOptions(options);
      }
    };

    helperFunc();
  }, [TId, headerValues?.mrfStatus]);

  async function CheckIfUserIsRecruiterOrTALead() {
    let isValidUserToChangeStatus = false;
    try {
      showLoader();
      const response = await APICall(GetTALeadAndRecruiterIdByMRFId, "POST", {
        MRFId: TId,
      });

      if (response?.status == 0) {
        const { recruiterUserId, taLeadUserId } = response?.data;

        if (userDetails?.Id == taLeadUserId) {
          isValidUserToChangeStatus = true;
        } else {
          isValidUserToChangeStatus = String(recruiterUserId)
            .split(",")
            .some((i) => i == userDetails.Id);
        }
      }
    } catch (error) {
    } finally {
      hideLoader();
      return isValidUserToChangeStatus;
    }
  }

  const statusGridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: statusGridData.count,
    rowsPerPage: statusGridData.pageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    //standard | vertical | simple
    onChangeRowsPerPage: (num) => {},
    onSearchChange: (searchText) => {
      if (searchText !== null) {
        setStatusGridData((prev) => {
          return { ...prev, searchText: searchText };
        });
      } else {
        setStatusGridData((prev) => {
          return { ...prev, searchText: "" };
        });
      }
    },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setStatusGridData((prev) => {
          return {
            ...prev,
            sortColumn: sortColumn,
            sortDirection: sortDirection,
          };
        });
      }
      if (sortDirection === "desc") {
        await setStatusGridData((prev) => {
          return {
            ...prev,
            sortColumn: sortColumn,
            sortDirection: sortDirection,
          };
        });
      }
    },
    onChangePage: async (page) => {
      setStatusGridData((prev) => {
        return { ...prev, start: page * prev.pageSize };
      });
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const getOptionsBasedOnCurrentStatus = (headerValue = "") => {
    try {
      headerValue = String(headerValue).toLocaleLowerCase().trim();

      let options = [
        { label: "Hold", value: "Hold", disabled: false },
        { label: "Closed", value: "Closed", disabled: false },
        { label: "Re-open", value: "Re-open", disabled: true },
        { label: "Open", value: "Open", disabled: true },
        {
          label: "Pending Approval",
          value: "Pending Approval",
          disabled: true,
        },
        { label: "Draft", value: "Draft", disabled: true },
      ];

      let value = null;

      switch (headerValue) {
        case "open":
          options = options.map((option) => {
            if (option.value === "Hold" || option.value === "Closed") {
              option["disabled"] = false;
            } else {
              option["disabled"] = true;
            }
            return option;
          });

          value = options.find((option) => option.value === "Open");
          break;

        case "closed":
        case "terminated":
          options = options.map((option) => {
            if (
              option.value === "Re-open" ||
              option.value === "Hold" ||
              option.value === "Closed"
            ) {
              option["disabled"] = false;
            } else {
              option["disabled"] = true;
            }
            return option;
          });

          value = options.find((option) => option.value === "Closed");
          break;

        case "pending approval":
          options = options.map((option) => {
            if (
              option.value === "Closed" ||
              option.value === "Hold" ||
              option.value === "Pending Approval"
            ) {
              option["disabled"] = false;
            } else {
              option["disabled"] = true;
            }
            return option;
          });

          value = options.find((option) => option.value === "Pending Approval");
          break;

        case "hold":
          options = options.map((option) => {
            if (
              option.value === "Closed" ||
              option.value === "Hold" ||
              option.value === "Re-open"
            ) {
              option["disabled"] = false;
            } else {
              option["disabled"] = true;
            }
            return option;
          });

          value = options.find((option) => option.value === "Hold");
          break;

        case "draft":
          options = options.map((option) => {
            if (
              option.value === "Closed" ||
              option.value === "Hold" ||
              option.value === "Draft"
            ) {
              option["disabled"] = false;
            } else {
              option["disabled"] = true;
            }
            return option;
          });

          value = options.find((option) => option.value === "Draft");
          break;

        default:
          break;
      }

      return { options, value };
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetPositionisVacantOrNot = async () => {
    let isVacant = false;
    try {
      showLoader();
      const { status, data, message } = await APICall(
        GetPositionVacantStatusByMRFId,
        "POST",
        {
          Id: TId,
        }
      );

      if (status == 0) {
        isVacant = data?.code === "CODEVACNSTS1";
      } else {
        console.error(message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
    return isVacant;
  };

  const GetTabDataAsync = async () => {
    try {
      showLoader();
      const tabs = await APICall(getTabsData, "POST", {
        MN: "ManpowerRequisition",
        IN: "MRF_Admin",
      });

      if (tabs.data !== null && tabs.data.length > 0) {
        if (state?.showOnlyJD == true) {
          const filterTab = tabs?.data?.filter((i) => i?.TN == "Job_MRF_Admin");
          setActivetab(filterTab[0].TN);
          setTabs(filterTab);
        } else {
          setActivetab(state?.Tab || tabs.data[0].TN);

          setTabs(tabs.data);
          if (currentRoleId?.value != 9 && state?.Tab != "ManpowerCandidates") {
            let tempTabs = tabs?.data?.filter(
              (i) => i?.TN != "ManpowerCandidates"
            );
            if (tempTabs.length === 3) {
              // to rearrange tabs
              const tabToMove = tempTabs.splice(1, 1)[0];
              tempTabs.push(tabToMove);
            }
            setTabs(tempTabs);
          } else {
            let tempTabs = tabs.data;
            if (tempTabs.length === 4) {
              // to rearrange tabs
              const tabToMove = tempTabs.splice(1, 1)[0];
              tempTabs.splice(-1, 0, tabToMove);
            }
            setTabs(tempTabs);
          }

          if (state?.isRecruiterDashboard) {
            let obj = {
              tid: 0,
              id: 0,
              TDN: "Candidates",
              TN: "Candidates",
              TSN: 0,
              isActive: true,
              createdDateUtc: "0001-01-01T00:00:00",
              createdBy: 0,
              modifiedDateUtc: null,
              modifiedBy: null,
              proxyUserId: null,
            };
            setTabs((prev) => [
              ...prev?.filter((i) => i?.TN != "MRF_Admin_Process"),
              obj,
            ]);

            
            if (state?.Tab == "NewTab") {
              setTabs((prev) => [
                ...prev,
                {
                  TDN: "Application",
                  TN: "NewTab",
                  TSN: 1,
                  createdBy: 0,
                  createdDateUtc: "0001-01-01T00:00:00",
                  id: 0,
                  isActive: true,
                  modifiedBy: null,
                  modifiedDateUtc: null,
                  proxyUserId: null,
                  tid: 0,
                },
              ]);
            }
          }
        }
      } else {
        setTabs([]);
      }
    } catch (error) {
      console.error("Get Tab Data Error: ", error);
    } finally {
      hideLoader();
    }
  };

  const GetJobIdBasedOnMRFIdAsync = async () => {
    try {
      showLoader();

      const response = await APICall(GetJobIdBasedOnMRFId, "POST", {
        MRFId: TId,
      });

      if (response?.status == 0) {
        let JobId = response?.data;
        setJobId(JobId);
        return JobId;
      }
      return 0;
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  const GetTabHeaderDetailsAsync = async () => {
    try {
      showLoader();
      const tabHeader = await APICall(getTabHeaderDetails, "POST", {
        MN: "ManpowerRequisition",
        IN: "",
        Id: TId,
      });
      setHeaderValues(tabHeader.data || null);

      setIsMRFOpen(
        String(tabHeader?.data?.mrfStatus).toLocaleLowerCase().trim() === "open"
      );
    } catch (error) {
      console.error("get headers details: ", error);
    } finally {
      hideLoader();
    }
  };

  const GetSectionAsync = async () => {
    try {
      showLoader();
      const section = await APICall(getSectionDetailsV2, "POST", {
        MN: "ManpowerRequisition",
        IN: "MRF_Admin",
        TN: activetab,
        UID: userDetails.Id,
        TID: null,
        RoleId: currentRoleId?.value,
        IsMRF: true,
      });

      if (
        section.data !== null &&
        section.data.t !== undefined &&
        section.data.t.tn === activetab
      ) {
        setFormData(section.data.t);
      } else {
        setFormData({});
      }
    } catch (error) {
      console.error("get sections details: ", error);
    } finally {
      hideLoader();
    }
  };

  const GetAttributeValueDetailsAsync = async () => {
    try {
      let bodyObj = {
        mn: "ManpowerRequisition",
        in: "MRF_Admin",
        tn: activetab,
        SN: "RequisitionDetails_MRF_Admin",
        TID: TId,
        ISH: "true",
        COU: COU,
      };

      showLoader();
      const response = await APICall(
        getAttributeValueDetailsV2,
        "POST",
        bodyObj
      );

      if (
        response.data &&
        response.data.sections &&
        response.data.sections.length > 0
      ) {
        setSectionValuesArray(response.data.sections);
      } else {
        setSectionValuesArray([]);
      }
    } catch (error) {
      console.error("Attribute value details async: ", error);
    } finally {
      hideLoader();
    }
  };

  const getPositionDetails = async () => {
    try {
      if (positionIdDetails?.value) {
        let bodyObj = {
          val: TId,
          PositionId: positionIdDetails?.value,
        };
        showLoader();
        const response = await APICall(
          getPositionDetailsForMRF,
          "POST",
          bodyObj
        );
        if (response?.status == 0 && response?.data?.length > 0) {
          let responseData = response?.data;
          let JobId = responseData?.find((k) => k.key == "MRJOBID")?.value || 0;
          JobId = Number(JobId);
          setJobId(JobId);
          setAttributeDataForPos(response.data);
        }
      }
    } catch (error) {
      console.error("get postion data: ", error);
    } finally {
      hideLoader();
    }
  };

  function handleChangeForMRFStatus(e) {
    try {
      setMRFStatusValue(e);
    } catch (error) {}
  }

  async function handleSubmitForMRFStatus(event) {
    try {
      if (mrfStatusValue?.value == "Re-open") {
        showLoader();
        const response = await APICall(CheckStatusWhenMRFTryToReopen, "POST", {
          Id: TId,
        });

        if (response?.status == 0) {
          if (response?.data == true) {
            notify(
              1,
              "You cannot re-open this MRF, another MRF is already in progress or in hold."
            );
            hideLoader();
            return;
          }
        }
      }

      setMRFStatusDisable(true);
      showLoader();
      const response = await APICall(UpdateMRFStatus, "POST", {
        MRFId: TId,
        Status: mrfStatusValue?.value,
      });

      if (response?.status == 0) {
        notify(0, "Status updated sucessfully.");
        setReset((prev) => !prev);
      } else {
        notify(1, "Something went wrong.");
        console.error(response?.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
      setMRFStatusDisable(false);
    }
  }

  const getRolesAndSetStatus = async () => {
    try {
      showLoader();
      let arr = [];

      const response = await APICall(GetRolesURL, "POST", {});

      if (response?.status == 0 && response?.data?.length > 0) {
        const isValidUser = await CheckIfUserIsRecruiterOrTALead();

        const data = response?.data;
        let showStatus = false;

        const systemAdmin = data?.find(
          (role) =>
            String(role?.name).trim().toLocaleLowerCase() ===
            RolesSystemName.SystemAdmin
        )?.id;

        if (currentRoleId?.value == systemAdmin) {
          showStatus = true;
        } else {
          showStatus = isValidUser;
        }

        setRoles(data);
        return showStatus;
      } else {
        setRoles([]);
        return false;
      }

      return arr;
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (activetab != "NewTab") {
      setTabs((prev) => {
        return prev.filter((i) => i?.TN != "NewTab");
      });
      delete state["Tab"];
      delete state["isRecruiterCandidate"];
    }
  }, [activetab]);;

  const mrfStatus = [
    <div className="mx-2 my-3 d-flex flex-column">
      <p>Requisition</p>
      <p className="mx-auto">
        <Avatar sx={{ bgcolor: "white", width: 24, height: 24 }}>
          {currentWFStage === 1 ? (
            <HourglassBottomIcon fontSize="small" color="warning" />
          ) : (
            <DoneIcon fontSize="small" color="success" />
          )}
        </Avatar>
      </p>
    </div>,
    <div className="mx-2 my-3 d-flex flex-column">
      <p>Approval</p>
      <p className="mx-auto">
        <Avatar
          sx={{
            bgcolor: `${
              currentWFStage === 2 || currentWFStage > 2 ? "white" : "secondary"
            }`,
            width: 24,
            height: 24,
          }}
        >
          {currentWFStage === 2 ? (
            <HourglassBottomIcon fontSize="small" color="warning" />
          ) : currentWFStage > 2 ? (
            <DoneIcon fontSize="small" color="success" />
          ) : (
            " "
          )}
        </Avatar>
      </p>
    </div>,
    <div className="mx-2 my-3 d-flex flex-column">
      <p>Posting</p>
      <p className="mx-auto">
        <Avatar
          sx={{
            bgcolor: `${
              currentWFStage === 3 || currentWFStage > 3 ? "white" : "secondary"
            }`,
            width: 24,
            height: 24,
          }}
        >
          {currentWFStage === 3 ? (
            <HourglassBottomIcon fontSize="small" color="warning" />
          ) : currentWFStage > 3 ? (
            <DoneIcon fontSize="small" color="success" />
          ) : (
            " "
          )}
        </Avatar>
      </p>
    </div>,
    <div className="mx-2 my-3 d-flex flex-column">
      <p>Sourcing</p>
      <p className="mx-auto">
        <Avatar
          sx={{
            bgcolor: `${
              currentWFStage === 4 || currentWFStage > 4 ? "white" : "secondary"
            }`,
            width: 24,
            height: 24,
          }}
        >
          {currentWFStage === 4 ? (
            <HourglassBottomIcon fontSize="small" color="warning" />
          ) : currentWFStage > 4 ? (
            <DoneIcon fontSize="small" color="success" />
          ) : (
            " "
          )}
        </Avatar>
      </p>
    </div>,
    <div className="mx-2 my-3 d-flex flex-column">
      <p>Evaluation</p>
      <p className="mx-auto">
        <Avatar
          sx={{
            bgcolor: `${
              currentWFStage === 5 || currentWFStage > 5 ? "white" : "secondary"
            }`,
            width: 24,
            height: 24,
          }}
        >
          {currentWFStage === 5 ? (
            <HourglassBottomIcon fontSize="small" color="warning" />
          ) : currentWFStage > 5 ? (
            <DoneIcon fontSize="small" color="success" />
          ) : (
            " "
          )}
        </Avatar>
      </p>
    </div>,
    <div className="mx-2 my-3 d-flex flex-column">
      <p>Offer</p>
      <p className="mx-auto">
        <Avatar
          sx={{
            bgcolor: `${
              currentWFStage === 6 || currentWFStage > 6 ? "white" : "secondary"
            }`,
            width: 24,
            height: 24,
          }}
        >
          {currentWFStage === 6 ? (
            <HourglassBottomIcon fontSize="small" color="warning" />
          ) : currentWFStage > 6 ? (
            <DoneIcon fontSize="small" color="success" />
          ) : (
            " "
          )}
        </Avatar>
      </p>
    </div>,
    <div className="mx-2 my-3 d-flex flex-column">
      <p>Joining</p>
      <p className="mx-auto">
        <Avatar
          sx={{
            bgcolor: `${
              currentWFStage === 7 || currentWFStage > 7 ? "white" : "secondary"
            }`,
            width: 24,
            height: 24,
          }}
        >
          {currentWFStage === 7 ? (
            <HourglassBottomIcon fontSize="small" color="warning" />
          ) : currentWFStage > 7 ? (
            <DoneIcon fontSize="small" color="success" />
          ) : (
            " "
          )}
        </Avatar>
      </p>
    </div>,
  ];

  useEffect(() => {
    if (TId !== 0) {
      (async () => {
        const res = await APICall(CheckScreeningQuestionsById, "POST", {
          MRFId: TId,
        });

        if (res.status === 0 && res.data) {
          setIsScreeningSubmitted(true);
        } else {
          setIsScreeningSubmitted(false);
        }
      })();
    } else {
      setIsScreeningSubmitted(false);
    }
  }, [TId]);

  return (
    <MRFContext.Provider
      value={{
        sendback,
        setSendback,
        showStagesAndResponsbility,
        setShowStagesAndResponsbility,
        setWorkflowApprovers,
        workflowApprovers,
      }}
    >
      <>
        <div className="container-fluid">
          <div className="row prof_bg mb-3 border-bottom">
            <div className="container-fluid">
              {headerValues && (
                <div className="row my-2">
                  <div className="col-lg-3">
                    <h4>Manpower Requisition</h4>
                    <p>
                      {headerValues && `MRF #: ${headerValues?.mrF_NO}`}
                      {headerValues &&
                        headerValues?.mrfCreatedDate !== null && (
                          <Tooltip title="MRF Creation Date">
                            <span>, {headerValues?.mrfCreatedDate}</span>
                          </Tooltip>
                        )}
                    </p>
                    <p>
                      <Tooltip title="Job Title">
                        <span>
                          {headerValues && headerValues?.jobtitle !== null && (
                            <>{headerValues?.jobtitle}</>
                          )}
                        </span>
                      </Tooltip>
                    </p>
                    <p>
                      <Tooltip title="Legal Entity">
                        <span>
                          {headerValues &&
                            headerValues?.legalEntity !== null && (
                              <>{headerValues?.legalEntity}</>
                            )}
                        </span>
                      </Tooltip>
                    </p>

                    <p>
                      <Tooltip title="Designation">
                        <span>
                          {headerValues &&
                            headerValues?.designation !== null && (
                              <>{headerValues?.designation}</>
                            )}
                        </span>
                      </Tooltip>
                    </p>

                    <p>
                      <Tooltip title="Department">
                        <span>
                          {headerValues &&
                            headerValues?.department !== null && (
                              <>{headerValues?.department}</>
                            )}
                        </span>
                      </Tooltip>
                    </p>

                    <p>
                      <Tooltip title="Physical Location">
                        <span>
                          {headerValues &&
                            headerValues?.locationName !== null && (
                              <>{headerValues?.locationName}</>
                            )}
                        </span>
                      </Tooltip>{" "}
                      |{" "}
                      <Tooltip title="Function">
                        <span>
                          {headerValues &&
                            headerValues?.functionName !== null && (
                              <>{headerValues?.functionName}</>
                            )}
                        </span>
                      </Tooltip>
                    </p>
                    <p>
                      <Tooltip title="MRF Status">
                        <span>
                          {headerValues && headerValues?.mrfStatus !== null && (
                            <> {headerValues?.mrfStatus}</>
                          )}
                        </span>
                      </Tooltip>
                      <Tooltip title="TAT Time Lapse">
                        <span>
                          {headerValues &&
                            headerValues?.tat_time !== null &&
                            headerValues?.tat_time !== 0 && (
                              <>
                                {headerValues?.mrfStatus && " | "}
                                {headerValues?.tat_time}{" "}
                                {headerValues?.tat_time > 1 ? "days" : "day"}
                              </>
                            )}
                        </span>
                      </Tooltip>
                    </p>
                  </div>
                  <div className="col-lg-9">
                    <Breadcrumbs
                      separator={<NavigateNextIcon fontSize="small" />}
                      aria-label="breadcrumb"
                    >
                      {mrfStatus}
                    </Breadcrumbs>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-lg-2 col-md-12 col-sm-12 "></div>
                <div className="col-lg-10 col-md-12 col-sm-12 ">
                  <ThemeProvider theme={tabsTheme}>
                    <Tabs
                      value={activetabIndex}
                      className="profile-tabs"
                      onChange={() => {}}
                      variant="scrollable"
                      TabScrollButtonProps={{
                        style: {
                          color: "white",
                        },
                      }}
                      scrollButtons
                      TabIndicatorProps={{
                        style: { display: "none" },
                      }}
                      allowScrollButtonsMobile
                      aria-label="scrollable tabs"
                    >
                      <div className="tabBtn">
                        <ul>
                          {tabs.length > 0 &&
                            tabs.map((eachTab, index) =>
                              eachTab.TN != "" ? (
                                <li key={`${index}-${eachTab.TN}`}>
                                  <button
                                    onClick={() => {
                                      setActivetab(eachTab.TN);
                                    }}
                                    className={
                                      eachTab.TN === activetab
                                        ? "bttn active"
                                        : "bttn"
                                    }
                                  >
                                    {eachTab.TDN}
                                  </button>
                                </li>
                              ) : (
                                <li key={`${index}-${eachTab.TN}`}>
                                  <button
                                    onClick={() => setActivetab(eachTab.TN)}
                                    hidden={false}
                                    className={
                                      eachTab.TN === activetab
                                        ? "bttn active"
                                        : "bttn"
                                    }
                                  >
                                    {eachTab.TDN}
                                  </button>
                                </li>
                              )
                            )}
                        </ul>
                      </div>
                    </Tabs>
                  </ThemeProvider>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 d-flex">
              {showMRFStatus && activetab === "Requisition" && (
                <>
                  <span>MRF Status</span>
                  <div className="col-lg-4">
                    <SelectForm
                      value={mrfStatusValue}
                      placeholder={"MRF Status"}
                      options={mrfStatusOptions}
                      onChange={handleChangeForMRFStatus}
                      isDisabled={mrfStatuDisable}
                      isOptionDisabled={(option) => option.disabled}
                    />
                  </div>
                  <div className="">
                    <button
                      disabled={mrfStatuDisable}
                      className="btn btn-primary"
                      onClick={handleSubmitForMRFStatus}
                    >
                      Save status
                    </button>
                  </div>
                </>
              )}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 mb-3">
              <div className="text-right my-1">
                <button
                  onClick={() => {
                    navigate(-1);
                  }}
                  disabled={false}
                  className="btn btn-secondary float-right"
                >
                  <i className="fa-solid fa-arrow-left"></i> Back
                </button>
              </div>
            </div>
          </div>

          {/* section row */}
          <div className="row accordion_grid">
            <React.Fragment>
              {String(activetab).trim().toLowerCase() ===
                "anonymouscanidatestatus" && (
                <React.Fragment>
                  <div className="col-lg-12">
                    <DynamicGrid
                      options={statusGridOptions}
                      data={statusGridData.data}
                      columns={statusGridColumns}
                    />
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>

            <>
              {activetab == "MRF_Admin_Process" && (
                <div className="col-lg-12">
                  <ApproverSection />
                </div>
              )}
            </>
            <>
              {activetab == "Candidates" && (
                <>
                  <CandidateDashboard
                    setTabs={setTabs}
                    setActiveTab={setActivetab}
                    hideAction={true}
                    isMRFAdmin={true}
                  />
                </>
              )}
            </>
            {formData.S !== undefined &&
              formData?.S?.length > 0 &&
              formData.S.map((eachSection, index) => {
                const lastIndex = formData?.S.length - 1;

                return (
                  <React.Fragment key={`${index}-${eachSection.SN}`}>
                    {eachSection.SN === "ScreeningQuestion_MRF_Admin" &&
                    !hideSectionAndAttribute ? (
                      <>
                        <ScreeningQuestion
                          formData={formData}
                          setFormData={setFormData}
                          section={eachSection}
                          TId={TId}
                          setTId={setTId}
                          sectionValuesArray={sectionValuesArray}
                          hideSectionAndAttribute={hideSectionAndAttribute}
                          disableAttribute={true}
                          setIsScreeningSubmitted={setIsScreeningSubmitted}
                        />
                      </>
                    ) : eachSection.SN === "ApprovalSection" ? (
                      <>
                        <ApproverSection />
                      </>
                    ) : eachSection.SN === "StagesandResponsibility" ? (
                      <>
                        {showStagesAndResponsbility && (
                          <StagesAndResponsibility
                            TId={TId}
                            IsMRFOpen={isMRFOpen}
                          />
                        )}
                      </>
                    ) : eachSection.SN === "MRFStages" ? (
                      <Stages type={state != null ? state.type : null} />
                    ) : (
                      <SectionComponent
                        formData={formData}
                        section={eachSection}
                        TId={TId}
                        setTId={setTId}
                        sectionValuesArray={sectionValuesArray}
                        attrDataForPosition={attributeDataForPos}
                        activetab={activetab}
                        index={index}
                        lastIndex={lastIndex}
                        COU={COU}
                        hideSectionAndAttribute={hideSectionAndAttribute}
                        setHideSectionAndAttribute={setHideSectionAndAttribute}
                        positionIdDetails={positionIdDetails}
                        setPositionIdDetails={setPositionIdDetails}
                        positionCountryIdDetails={positionCountryIdDetails}
                        setPositionCountryDetails={setPositionCountryDetails}
                        disableAttribute={true}
                        setDisableAttribute={setDisableAttribute}
                        setAttributeDataForPos={setAttributeDataForPos}
                        setJobId={setJobId}
                        isScreeningSubmitted={true}
                        IsMRFAdmin={true}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            <>{activetab === "NewTab" && <Stages isMRFAdmin={true} />}</>
          </div>
        </div>
      </>
    </MRFContext.Provider>
  );
};
export default MRFAdmin_MRF;
