import React, { useState, useEffect, useContext } from "react";
import SelectForm from "../../Components/SelectForm/SelectForm";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { APICall } from "../../Helpers/APICalls";
import {
  GetSessionContent,
  GetSessionDropdown,
  GetThumbnailDetails,
  InsertUpdateCourseSession,
  ManageCourseSessions,
  getSessionDropdownByUserId,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import { Link, useLocation, useNavigate } from "react-router-dom";
import InputForm from "../../Components/InputForm/InputForm";
import { Table } from "react-bootstrap";
import { LoaderContext } from "../../Helpers/Context/Context";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import defaultProfileImgPic from "../../Assets/Images/thumbnail.jpg";
import notify from "../../Helpers/ToastNotification";
import ImageColumnComponent from "../../Components/LMS/ImageColumnComponent/ImageColumnComponent";
import LMSDynamicGrid from "../../Components/LMSDynamicGrid/LMSDynamicGrid";

const SessionAccordion = (props) => {
  const [accordion, setAccordion] = useState(false);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { state } = useLocation();

  const [isDisable, setIsDisable] = useState(
    state && state?.isDisable ? true : false
  );

  const [profileImg, setProfileImg] = useState(null);

  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();

  async function manageSession(mode, sessionId, sessionsIds, direction) {
    const sessionData = await APICall(InsertUpdateCourseSession, "POST", {
      Mode: mode,
      CourseId: state?.courseId,
      SessionId: sessionId,
      SessionIds: sessionsIds,
      Direction: direction,
      LaunchId: state?.launchHubId,
      CreatedBy: userDetails.Id,
    });

    if (sessionData.status === 0) {
      props.setRefresh((prev) => !prev);
    }
  }

  const moveItemUp = (index, currentSession) => {
    if (index > 0) {
      manageSession("CHANGE", currentSession.id, null, -1);
    }
  };

  const moveItemDown = (index, currentSession) => {
    if (index < props.sessionsArr.length - 1) {
      manageSession("CHANGE", currentSession.id, null, 1);
    }
  };

  const [contents, setContents] = useState([]);

  const getContent = async (sessionId) => {
    showLoader();
    const res = await APICall(GetSessionContent, "POST", {
      SessionId: sessionId,
      Mode: "LISTCONTENTADDED",
      LaunchId: state?.launchHubId,
      CreatedBy: userDetails.Id,
    });

    if (res.status === 0 && res.data.length > 0) {
      const contentData = res.data;
      setContents(contentData);
    } else {
      setContents([]);
    }

    hideLoader();
  };

  const getThumbnail = async (thumbnailUrl) => {
    const res = await APICall(GetThumbnailDetails, "POST", {
      ThumbnailUrl: thumbnailUrl,
    });

    if (res.status === 0) {
      return `data:image;base64,${res.data.thumbnailDataObject}`;
    }
  };

  useEffect(() => {
    if (accordion) {
      getContent(props.currentSession.id);

      // if (props.currentSession.thumbnailUrl) {
      //   (async () => {
      //     const res = await getThumbnail(props.currentSession.thumbnailUrl);

      //     setProfileImg(res);
      //   })();
      // }
    }
  }, [accordion]);

  const [currentSessionGridData, setCurrentSessionGridData] = useState([]);

  const sessionColumns = [
    {
      name: "sessionId",
      label: "Session ID",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "sessionTitle",
      label: "Session Title",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "sessionType",
      label: "Session Type",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "duration",
      label: "Duration",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "sessionObjective",
      label: "Session Objective",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <span
              dangerouslySetInnerHTML={{
                __html: value,
              }}></span>
          );
        },
      },
    },
  ];

  useEffect(() => {
    if (props.currentSession) {
      const gridData = [
        {
          sessionId: props.currentSession.sessionCode,
          sessionTitle: props.currentSession.sessionTitle,
          sessionType: props.currentSession.sessionType,
          duration: props?.currentSession?.totalDuration,
          sessionObjective: props.currentSession.sessionObjective,
        },
      ];

      setCurrentSessionGridData(gridData);
    }
  }, [props.currentSession]);

  return (
    <Accordion
      elevation={0}
      className="mb-3"
      expanded={accordion}
      onChange={() => setAccordion((prev) => !prev)}>
      <AccordionSummary
        id="profile-accordion"
        style={{ background: "#3C5464" }}
        className="text-white acc_close"
        expandIcon={<ExpandMore />}>
        <div className="row d-flex align-items-center acc-heading">
          <div className="col-lg-10 col-md-10 col-sm-12">
            <p style={{ fontSize: "16px" }}>
              {props.currentSession.sessionTitle} {" - "}{" "}
              {props.currentSession.sessionCode}
            </p>
          </div>
          {!isDisable && (
            <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12">
              <Tooltip title="Edit">
                <Link
                  style={{ marginTop: "7px" }}
                  className="btn"
                  to={`/SessionEdit?sessionId=${props.currentSession.id}`}
                  target="_blank">
                  <i className="fas fa-pencil text-white"></i>
                </Link>
              </Tooltip>
              <Tooltip title="Remove">
                <button
                  className="btn"
                  disabled={false}
                  onClick={(e) => {
                    e.stopPropagation();
                    manageSession(
                      "REMOVE",
                      props.currentSession.id,
                      null,
                      null
                    );
                  }}>
                  <i className="fas fa-trash"></i>
                </button>
              </Tooltip>
              <Tooltip title="Sequence">
                <button
                  className="btn"
                  disabled={false}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}>
                  <InputForm
                    style={{ width: "35px" }}
                    className="form-control"
                    placeholder={""}
                    isDisabled={true}
                    textArea={false}
                    value={props.currentSession.sequence}
                    onChange={(e) => {}}
                    maxLength="255"
                  />
                </button>
              </Tooltip>
              <Tooltip title="Move up">
                <button
                  className="btn"
                  disabled={props.sessionIndex > 0 ? false : true}
                  onClick={(e) => {
                    e.stopPropagation();
                    moveItemUp(props.sessionIndex, props.currentSession);
                  }}>
                  <i className="fa fa-caret-up"></i>
                </button>
              </Tooltip>

              <Tooltip title="Move down">
                <button
                  className="btn"
                  disabled={
                    props.sessionIndex !== props.sessionsArr.length - 1
                      ? false
                      : true
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    moveItemDown(props.sessionIndex, props.currentSession);
                  }}>
                  <i className="fa fa-caret-down"></i>
                </button>
              </Tooltip>
            </div>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails className="">
        <div className="row">
          <div className="col-lg-2">
            <div className="card">
              <ImageColumnComponent
                link={props.currentSession.thumbnailUrl}
                width="auto"
              />
            </div>
          </div>
          <div className="col-lg-10 p-0">
            {/* <div className="col-lg-4">
                <p>
                  <b className="wid_obj">
                    {" "}
                    <div className="lable_width ">Session ID</div> :{" "}
                  </b>
                  {props.currentSession.sessionCode}
                </p>
              </div>
              <div className="col-lg-4">
                <p>
                  <b className="wid_obj">
                    {" "}
                    <div className="lable_width ">Session Title</div> :{" "}
                  </b>
                  {props.currentSession.sessionTitle}
                </p>
              </div>
              <div className="col-lg-4">
                <p>
                  <b className="wid_obj">
                    {" "}
                    <div className="lable_width ">Session Type</div> :{" "}
                  </b>
                  {props.currentSession.sessionType}
                </p>
              </div>
              <div className="col-lg-4">
                <p>
                  <b className="wid_obj">
                    {" "}
                    <div className="lable_width ">Duration</div> :{" "}
                  </b>
                  {props?.currentSession?.totalDuration}
                  
                </p>
              </div>
              <div className="col-lg-8 col-md-6 col-sm-4 ">
                <p>
                  <b className="wid_obj">
                    {" "}
                    <div className="lable_width ">
                      {" "}
                      Session Objective
                    </div> :{" "}
                  </b>
                  {
                    <span
                      dangerouslySetInnerHTML={{
                        __html: props.currentSession.sessionObjective,
                      }}></span>
                  }
                </p>
              </div> */}
            <LMSDynamicGrid
              data={currentSessionGridData}
              columns={sessionColumns}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            {contents.length > 0 ? (
              <>
                <p className="mt-4 mb-4" style={{ color: "#2C2B7C" }}>
                  <b
                    className="rounded px-3 py-2"
                    style={{ backgroundColor: "#e7e7e7" }}>
                    Content
                  </b>
                </p>
                {/* <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th className="text-dark">Thumbnail</th>
                      <th className="text-dark">Title</th>
                      <th className="text-dark">Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contents.map((content) => (
                      <tr>
                        <td>
                          <div style={{ maxWidth: "100px" }} className="card">
                            <ImageColumnComponent
                              link={content?.thumbnailUrl}
                              width="auto"
                            />
                          </div>
                        </td>
                        <td>{content?.contentTitle}</td>
                        <td>{content?.contentType}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table> */}
                <div className="row">
                  <div className="col-lg-12 p-0">
                    <ContentGrid contents={contents} />
                  </div>
                </div>
              </>
            ) : (
              <h4>No content available</h4>
            )}
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const ContentGrid = ({ contents }) => {
  const { state } = useLocation();

  const [contentGridData, setContentGridData] = useState<any>(contents);

  const contentColumns = [
    {
      name: "thumbnailUrl",
      label: "Thumbnail",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <div style={{ maxWidth: "100px" }} className="card">
              <ImageColumnComponent link={value} width="auto" />
            </div>
          );
        },
      },
    },
    {
      name: "contentTitle",
      label: "Title",
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: "contentType",
      label: "Type",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  return <LMSDynamicGrid data={contentGridData} columns={contentColumns} />;
};

const CourseSession = ({ activeTab, setActiveTab }) => {
  const { state } = useLocation();
  const [isDisable, setIsDisable] = useState(
    state && state?.isDisable ? true : false
  );
  const navigate = useNavigate();
  const [sessionOptions, setSessionOptions] = useState([]);
  const [selectedSession, setSelectedSession] = useState([]);
  const [sessionsArr, setSessionsArr] = useState([]);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [viewAll, setviewAll] = useState(false);
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();

  const getSessionDropdown = async () => {
    showLoader();

    if (viewAll) {
      const res = await APICall(GetSessionDropdown, "POST", {
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      });

      if (res?.status === 0 && res?.data?.length > 0) {
        setSessionOptions(res.data);
      } else {
        setSessionOptions([]);
      }
    } else {
      const res = await APICall(getSessionDropdownByUserId, "POST", {
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      });

      if (res?.status === 0 && res?.data?.length > 0) {
        setSessionOptions(res.data);
      } else {
        setviewAll(true); //get all values in dropdown if empty for user
        setSessionOptions([]);
      }
    }
    hideLoader();
  };

  useEffect(() => {
    getSessionDropdown();
  }, [viewAll]);

  const [refresh, setRefresh] = useState(false);

  const manageSession = async (mode, sessionId, sessionsIds, direction) => {
    showLoader();
    debugger;
    const sessionData = await APICall(ManageCourseSessions, "POST", {
      Mode: mode,
      CourseId: state?.courseId,
      SessionId: null,
      SessionIds: null,
      Direction: null,
      LaunchId: state?.launchHubId,
      CreatedBy: userDetails.Id,
    });

    if (sessionData.status === 0 && sessionData.data.length > 0) {
      setSessionsArr(sessionData.data);
    } else {
      setSessionsArr([]);
    }
    hideLoader();
  };

  useEffect(() => {
    // if (state.courseId !== null && state.courseId > 0) {
    manageSession("LISTSESSION", null, null, null);
    // }
  }, [state?.courseId, refresh]);

  const addNewSession = async (mode, sessionIds) => {
    const sessionData = await APICall(InsertUpdateCourseSession, "POST", {
      Mode: mode,
      CourseId: state?.courseId,
      SessionId: null,
      SessionIds: sessionIds,
      Direction: null,
      CreatedBy: userDetails.Id,
    });

    if (sessionData.status === 0) {
      setRefresh((prev) => !prev);
    }
  };

  return (
    <div className="container-fluid h-25">
      <div className="row">
        <div className={"col-lg-3 col-sm-3 col-xs-4"}>
          <div className="dropdown mb-1">
            <label className="col-form-label">
              Select Session
              <sup>*</sup>
            </label>

            <SelectForm
              isClearable
              isSearchable
              options={sessionOptions}
              placeholder={"Select Session"}
              isDisabled={isDisable}
              value={selectedSession}
              onChange={(event) => {
                setSelectedSession(event);
              }}
              isMulti={true}
              noIndicator={false}
              noSeparator={false}
            />
            {/* {formErrors["journey"] && (
              <p style={{ color: "red" }}>{formErrors["journey"]}</p>
            )} */}
          </div>
        </div>
        {!isDisable && (
          <div
            className={"col-lg-6 col-sm-6 col-xs-6"}
            style={{ margin: "10px" }}>
            <div className="mb-1">
              <div className="mt-4">
                {!viewAll && (
                  <button
                    className="btn btn-primary"
                    style={{ marginLeft: 5 }}
                    onClick={() => {
                      setviewAll(true);
                    }}>
                    <i className="fa-solid fa-eye"></i> View All
                  </button>
                )}

                <button
                  className="btn btn-primary"
                  style={{ marginLeft: 5 }}
                  onClick={() => {
                    getSessionDropdown();
                    manageSession("LISTSESSION", null, null, null);
                  }}>
                  <i className="fa-solid fa-refresh"></i> Refresh
                </button>
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: 5 }}
                  onClick={() => {
                    if (
                      selectedSession !== null &&
                      selectedSession.length > 0
                    ) {
                      const newSessionArr = [];
                      const checkSessionArray = [];
                      selectedSession.forEach((session) => {
                        const isSessionNotAdded = sessionsArr.find(
                          (s) => s.id == session.value
                        );
                        if (isSessionNotAdded === undefined) {
                          newSessionArr.push({
                            sessionId: session.value,
                            sessionName: session.label,
                          });
                        } else {
                          checkSessionArray.push({
                            sessionId: session.value,
                            sessionName: session.label,
                          });
                        }
                      });

                      if (newSessionArr.length > 0) {
                        const newSessionIds = newSessionArr
                          .map((s) => s.sessionId)
                          .join(",");

                        addNewSession("ADD", newSessionIds);
                      }

                      if (checkSessionArray?.length > 0) {
                        const sessionExist = checkSessionArray
                          .map((s) => s.sessionName)
                          .join(",");

                        notify(1, `${sessionExist} already exist`);
                      }

                      setSelectedSession([]);
                    } else {
                      notify(1, "Please select session");
                    }
                  }}>
                  <i className="fa-solid fa-plus"></i> Add Session
                </button>
                <Link
                  className="btn btn-primary"
                  style={{ marginLeft: 5 }}
                  to={"/SessionEdit"}
                  target="_blank">
                  <i className="fa-solid fa-plus"></i> Create Session
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="row mt-1">
        <div className="col-lg-12 col-sm-12 col-xs-12">
          {sessionsArr.length > 0 &&
            sessionsArr.map((currentSession, sessionIndex) => (
              <SessionAccordion
                currentSession={currentSession}
                sessionIndex={sessionIndex}
                sessionsArr={sessionsArr}
                setSessionsArr={setSessionsArr}
                setRefresh={setRefresh}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default CourseSession;
