import { TextField } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React from "react";

const DateTimeComponent = ({ value, disabled, onChange, placeholder = "" }) => {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          value={value}
          disabled={disabled}
          onChange={onChange}
          disablePast={false}
          inputFormat="dd/MM/yyyy HH:mm:ss" // Display time in AM/PM format
          disableMaskedInput
          className="w-100 bg-white date_icon"
          InputProps={{
            sx: {
              "&.Mui-disabled": {
                backgroundColor: "#e9ecef",
                borderColor: "#e9ecef",
                outline: "none",
                opacity: "1",
              },
            },
          }}
          renderInput={(params) => (
            <TextField
              size="small"
              name=""
              autoComplete="off"
              id="date-input"
              sx={{
                svg: {
                  color: "#00ABE6",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    fontFamily: "Segoe UI",
                    boxShadow: "none",
                    outline: "none",
                  },
                  "&:hover fieldset": {
                    borderColor: "hsl(0, 0%, 70%)",
                    boxShadow: "none",
                    outline: "none",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "hsl(0, 0%, 70%)",
                    boxShadow: "none",
                    outline: "none",
                  },
                },
              }}
              {...params}
            />
          )}
        />
      </LocalizationProvider>
    </>
  );
};

export default DateTimeComponent;
