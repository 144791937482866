import React, { useState, useRef, useCallback } from "react";
import Webcam from "react-webcam";

interface WebcamCaptureProps {
  openCamera: boolean; // Prop to determine if the camera should be open
  onCapture: (image: string | null) => void; // Callback function to handle captured image
}

function WebcamCapture({ openCamera, onCapture }: WebcamCaptureProps) {
  const [capturedImage, setCapturedImage] = useState<string | null>(null);
  const webcamRef = useRef(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
    onCapture(imageSrc); // Call onCapture callback with the captured image
  }, [webcamRef, onCapture]);

  // If openCamera prop is false, return null to prevent rendering the component
  if (!openCamera) return null;

  return (
    <>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        style={{ width: "100%", height: "auto" }}
      />
      <button onClick={capture}>Capture</button>
    </>
  );
}

export default WebcamCapture;
