import React, { useContext, useEffect, useState } from "react";
import { LoaderContext } from "../../Helpers/Context/Context";
import { useLocation, useNavigate } from "react-router-dom";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import {
  IconContainerProps,
  Rating,
  Stack,
  Tooltip,
  styled,
} from "@mui/material";
import SelectForm from "../../Components/SelectForm/SelectForm";
import InputForm from "../../Components/InputForm/InputForm";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import { APICall } from "../../Helpers/APICalls";
import {
  getAssessmentDashboardDropdownList,
  getFeedbackDashboardDropdownList,
  getFeedbackDropdownList,
  getLMSDomainDropDownData,
  getManageFeedbackQuestionMaster,
  saveManageFeedbackExistingQuestionMaster,
  saveManageFeedbackQuestionMaster,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import uuid from "react-uuid";
import notify from "../../Helpers/ToastNotification";

interface IOptions {
  label?: string;
  value?: number | string;
  code?: string;
}

interface IFormData {
  question?: string | any;
  questionType?: IOptions | any;
  domain?: IOptions[] | string | any;
}

const Question = ({
  showModal,
  setShowModal,
  formOnChangeForQuestion,
  questionType,
  setQuestionType,
  questionId,
  setQuestionId,
  questionTypechange,
}) => {
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { state } = useLocation();
  const navigate = useNavigate();
  let { userDetails, currentRoleId }: any = useUserContext();
  const [formData, setFormData] = useState<IFormData>({});
  const [formErrors, setFormErrors] = useState<IFormData>({});
  const [formOptions, setFormOptions] = useState<IFormData>({});
  const [isDisable, setIsDisable] = useState(
    state && state?.isDisable ? true : false
  );

  const [gridData, setGridData] = useState<any>([]);

  const [count, setCount] = useState(0);
  const [matchvaluesCount, setMatchvaluesCount] = useState(0);

  const [currentGridId, setCurrentGridId] = useState(null);
  const [deleteGridId, setDeleteGridId] = useState(null);

  const [questions, setQuestions] = useState(null);
  const [questionsOptions, setQuestionsOptions] = useState([]);

  const [answer, setAnswer] = useState("");

  const [isSkippable, setIsSkippable] = useState(false);

  const [answerErrors, setAnswerErrors] = useState({});

  const questionTypeObj = {
    SingleSelection: "SINGLE",
    FreeText: "TEXT",
    SmileyFace: "SMILEY",
    StarScale: "STAR",
    LikertScale: "LIKE",
  };

  useEffect(() => {
    const responseData = async () => {
      //LMS_FEEDBACKQUESTIONTYPE
      const res = await APICall(getFeedbackDropdownList, "POST", {
        Mode: "QUESTION_TYPE",
      });

      if (res.data && res.data.length > 0) {
        setFormOptions((prev) => {
          return {
            ...prev,
            questionType: res.data,
          };
        });
      }

      const objQuestions = {
        mode: "QUESTIONS",
        feedbackId: state?.feedbackId,
        userId: userDetails?.Id,
        roleId: currentRoleId?.value,
      };
      const res4 = await APICall(
        getFeedbackDashboardDropdownList,
        "POST",
        objQuestions
      );
      if (res4.data && res4.data.length > 0) {
        setQuestionsOptions(res4.data);
      }
    };

    showLoader();

    responseData();
    getLMSDomainDropDownDataApiCall();

    hideLoader();
  }, []);

  const getLMSDomainDropDownDataApiCall = async () => {
    const response = await APICall(getLMSDomainDropDownData, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      GetAll: state && state?.feedbackId > 0 ? true : false,
    });

    if (response?.status === 0 && response?.data.length > 0) {
      setFormOptions((prev) => {
        return {
          ...prev,
          domain: response?.data,
        };
      });
    }
  };

  const formOnChange = (e, type) => {
    switch (type) {
      case "question":
        setFormData({
          ...formData,
          question: e.target.value,
        });
        break;

      case "questionType":
        setFormData({
          ...formData,
          questionType: e,
        });

        // if (e.code === questionTypeObj.MultipleSelection) {
        //   setIsSingleSelection(false);
        // }
        break;

      case "domain":
        setFormData({
          ...formData,
          domain: e,
        });
        break;

      default:
        break;
    }
  };

  const gridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: count,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        // await setSortColumn(sortColumn);
        // await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        // await setSortColumn(sortColumn);
        // await setSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      //   setPage(page * PageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };
  const gridColumns = [
    {
      name: "id",
      label: "Id",
      options: {
        display: false,
      },
    },

    {
      name: "answer",
      label: "Answer",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "rating",
      label: "Rating",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "center" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta) => {
          let id = tableMeta.tableData[tableMeta.rowIndex].id;

          return (
            <div className="d-flex justify-content-center">
              {!isDisable && (
                <>
                  <div className="">
                    <Tooltip title="delete">
                      <a
                        className="mr-2"
                        onClick={(e) => {
                          e.preventDefault();
                          setDeleteGridId(id);
                        }}>
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </a>
                    </Tooltip>
                  </div>
                </>
              )}
            </div>
          );
        },
      },
    },
  ];

  useEffect(() => {
    if (deleteGridId && currentGridId === null) {
      //to  delete data from grid

      let ratingNo = 0;

      const gridDataObj = gridData.find((record) => record.id !== deleteGridId);

      const gridDataArray = gridData.filter(
        (record) => record.id !== deleteGridId
      );

      const gridNewDataArray = gridDataArray.map((prev) => {
        if (prev.sequence > gridDataObj.sequence) {
          ratingNo = prev.rating - 1;
        } else {
          ratingNo = prev.rating;
        }

        return {
          ...prev,
          id: prev.id,
          answer: prev.answer,
          rating: ratingNo,
        };
      });

      setGridData(gridNewDataArray);
    }
    setDeleteGridId(null);
  }, [deleteGridId]);

  const answerValidate = () => {
    let errorObj = {};
    let isError = false;

    if (answer === null || answer === "") {
      errorObj = {
        ...errorObj,
        ["answer"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["answer"]: "",
      };
    }

    const isEmpty = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      setAnswerErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  const addAnswerFunction = () => {
    showLoader();

    if (answerValidate()) {
      hideLoader();
      return;
    }
    const uniqueId = uuid();
    let ratingNo = 0;

    if (gridData?.length > 0) {
      let maxValue = Math.max.apply(
        null,
        gridData.map(function (o) {
          return o.rating;
        })
      );

      ratingNo = maxValue + 1;

      const isAnwerDuplicate = [];
      gridData.map((prevAns) => {
        if (prevAns.answer.trim() === answer.trim()) {
          isAnwerDuplicate.push(prevAns);
        }
      });

      if (isAnwerDuplicate.length > 0) {
        notify(1, "Answer cannot be same!");
        hideLoader();
        return null;
      }
    } else {
      ratingNo = 1;
    }

    let gridFormData = {
      answer: answer,
      rating: ratingNo,
    };

    setGridData((prev) => [
      ...prev,
      {
        id: uniqueId,
        ...gridFormData,
        IsActive: true,
      },
    ]);

    setAnswer("");

    hideLoader();
  };

  const requestParamsFun = () => {
    let answerData = "";

    if (formData?.questionType?.code === questionTypeObj.SingleSelection) {
      answerData = JSON.stringify(gridData);
    } else {
      answerData = "";
    }

    const requestParams = {
      Id: questionId,
      FeedbackId:
        state === null ||
        state?.feedbackId === null ||
        state?.feedbackId === undefined
          ? 0
          : state?.feedbackId,
      Question: formData?.question,
      QuestionTypeId: formData?.questionType?.value,
      IsSkippable: isSkippable,
      DomainIds: formData?.domain
        ? formData?.domain?.map((j) => j.value).join(",")
        : null,
      Answers: answerData,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    };

    return requestParams;
  };

  const validateForm = () => {
    let errorObj = {};
    let isError = false;
    setFormErrors({});

    if (
      formData?.question === "" ||
      formData?.question === null ||
      formData?.question === undefined
    ) {
      errorObj = {
        ...errorObj,
        ["question"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["question"]: "",
      };
    }

    if (
      formData?.questionType === null ||
      formData?.questionType === undefined
    ) {
      errorObj = {
        ...errorObj,
        ["questionType"]: "Required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["questionType"]: "",
      };
    }

    // if (
    //   formData?.domain?.length === 0 ||
    //   formData?.domain === null ||
    //   formData?.domain === undefined
    // ) {
    //   errorObj = {
    //     ...errorObj,
    //     ["domain"]: "Required",
    //   };
    // } else {
    //   errorObj = {
    //     ...errorObj,
    //     ["domain"]: "",
    //   };
    // }

    if (formData?.questionType?.code === questionTypeObj.SingleSelection) {
      if (gridData?.length === 0) {
        errorObj = {
          ...errorObj,
          ["answerGrid"]: "Required",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["answerGrid"]: "",
        };
      }
    }

    const isEmpty = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  const handleSubmitFunction = async () => {
    showLoader();

    if (validateForm()) {
      hideLoader();
      return;
    }

    // const requestParams = requestParamsFun();

    // if (requestParams?.Id === 0) {
    //  await insertUpdateData();
    // } else {
    //   await insertUpdateData();
    // }

    const requestParams = requestParamsFun();

    const response = await APICall(
      saveManageFeedbackQuestionMaster,
      "POST",
      requestParams
    );

    if (response?.status === 0) {
      setShowModal(false);
      notify(response?.status, "Question save sucessfully.");
    } else if (response?.status === 1) {
      notify(response?.status, response?.message);
    }

    hideLoader();
  };

  useEffect(() => {
    if (
      questionId > 0 &&
      formOptions?.questionType?.length > 0 &&
      formOptions?.domain?.length > 0
    ) {
      getManageFeedbackQuestionMasterApiCall(questionId);
    }
  }, [questionId, formOptions?.questionType, formOptions?.domain]);

  const getManageFeedbackQuestionMasterApiCall = async (questionId) => {
    showLoader();
    const requestParams = {
      FeedbackId: state?.feedbackId,
      Id: questionId,
    };

    const responseData = await APICall(
      getManageFeedbackQuestionMaster,
      "POST",
      requestParams
    );

    if (responseData?.status === 0) {
      const questionTypeDataObj = formOptions?.questionType?.find(
        (s) => s.value === parseInt(responseData?.data?.questionTypeID)
      );

      setIsSkippable(responseData?.data?.isSkippable);

      const domainIds = responseData?.data?.domainIds?.split(",") || null;

      const domainArray = [];

      if (domainIds && formOptions?.domain?.length > 0) {
        domainIds?.map((item) => {
          const domainData = formOptions?.domain?.find(
            (i) => i.value === parseInt(item)
          );
          domainArray.push(domainData);
        });
      }

      if (
        responseData?.data?.answers !== null &&
        responseData?.data?.answers !== undefined
      ) {
        if (questionTypeDataObj?.code === questionTypeObj.SingleSelection) {
          const answerGridData = JSON.parse(responseData?.data?.answers);

          const answerGridArray = answerGridData?.map((prev) => {
            return {
              ...prev,
              id: prev.id,
              answer: prev.answer,
              rating: prev.rating,
            };
          });

          setGridData(answerGridArray);
        } else if (questionTypeDataObj?.code === questionTypeObj?.SmileyFace) {
        }
      }

      setFormData((env) => {
        return {
          ...env,
          question: responseData?.data?.question,
          questionType: questionTypeDataObj,
          domain: domainArray.length > 0 ? domainArray : null,
        };
      });
    } else if (responseData?.status === 1) {
      notify(responseData?.status, responseData?.message);
    }
    hideLoader();
  };

  const saveManageFeedbackExistingQuestionMasterApiCall = async () => {
    showLoader();

    if (questions?.length > 0) {
      const requestParams = {
        FeedbackId: state?.feedbackId,
        QuestionIds: questions?.map((s) => s.value).join(",") || null,
      };

      const response = await APICall(
        saveManageFeedbackExistingQuestionMaster,
        "POST",
        requestParams
      );

      if (response?.status === 0) {
        setShowModal(false);
        notify(response?.status, "Question save sucessfully.");
      } else if (response?.status === 1) {
        notify(response?.status, response?.message);
      }
    } else {
      notify(1, "Please select questions");
    }

    hideLoader();
  };

  const handleResetFunction = () => {
    setFormData((env) => {
      return {
        ...env,
        question: "",
        questionType: null,
        domain: null,
      };
    });

    setGridData([]);
  };

  const StyledRating = styled(Rating)(({ theme }) => ({
    "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
      color: theme.palette.action.disabled,
    },
  }));

  const customIcons: {
    [index: string]: {
      icon: React.ReactElement;
      label: string;
    };
  } = {
    1: {
      icon: <SentimentVeryDissatisfiedIcon color="error" />,
      label: "Very Dissatisfied",
    },
    2: {
      icon: <SentimentDissatisfiedIcon color="error" />,
      label: "Dissatisfied",
    },
    3: {
      icon: <SentimentSatisfiedIcon color="warning" />,
      label: "Neutral",
    },
    4: {
      icon: <SentimentSatisfiedAltIcon color="success" />,
      label: "Satisfied",
    },
    5: {
      icon: <SentimentVerySatisfiedIcon color="success" />,
      label: "Very Satisfied",
    },
  };

  function IconContainer(props: IconContainerProps) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
  }
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className={"col-lg-4 col-sm-4 col-xs-4"}>
            <div className="mb-1">
              <div>
                <input
                  className="ml-2 mr-1"
                  disabled={false || isDisable}
                  id={`newQuestion`}
                  type="radio"
                  name="newQuestion"
                  onChange={(e) => {
                    handleResetFunction();
                    formOnChangeForQuestion("newQuestion");
                  }}
                  checked={questionType === true}
                />
                <label htmlFor={`newQuestion`}>New</label>
                <input
                  className="ml-2 mr-1"
                  disabled={false || isDisable}
                  id={`existingQuestion`}
                  name="existingQuestion"
                  type="radio"
                  onChange={(e) => {
                    formOnChangeForQuestion("existingQuestion");
                  }}
                  checked={questionType === false}
                />
                <label htmlFor={`existingQuestion`}>Existing</label>
              </div>
            </div>
          </div>
        </div>
        {!questionType && (
          <>
            <div className="row">
              <div className={"col-lg-6 col-sm-6 col-xs-6"}>
                <div className="dropdown mb-1">
                  <label className="col-form-label">Questions</label>{" "}
                  <sup>*</sup>
                  <SelectForm
                    isClearable
                    isSearchable
                    options={questionsOptions}
                    placeholder={"Questions"}
                    isDisabled={isDisable}
                    value={questions}
                    onChange={(e) => {
                      setQuestions(e);
                    }}
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                  />
                </div>
              </div>
            </div>
            <div className="SectionSubmit mb-4 clearfix">
              {!isDisable && (
                <>
                  <button
                    onClick={() => {
                      saveManageFeedbackExistingQuestionMasterApiCall();
                    }}
                    disabled={false}
                    className="btn btn-filter-submit float-right ml-2">
                    <i className="fa-solid fa-check"></i> Save
                  </button>

                  <button
                    onClick={() => {
                      setQuestions(null);
                    }}
                    disabled={false}
                    className="btn btn-secondary float-right ml-2">
                    <i className="fa-solid fa-refresh"></i> Reset
                  </button>
                </>
              )}
            </div>
          </>
        )}

        {questionType && (
          <>
            <div className="row">
              <div className={"col-lg-6 col-sm-4 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">Question</label>{" "}
                  <sup>*</sup>
                  <InputForm
                    className="form-control"
                    placeholder={"Question"}
                    isDisabled={isDisable}
                    textArea={false}
                    value={formData?.question}
                    onChange={(e) => {
                      formOnChange(e, "question");
                    }}
                    maxLength="250"
                  />
                  {formErrors["question"] && (
                    <p style={{ color: "red" }}>{formErrors["question"]}</p>
                  )}
                </div>
              </div>
              <div className={"col-lg-6 col-sm-4 col-xs-4"}>
                <div className="dropdown mb-1">
                  <label className="col-form-label">Questions Type</label>{" "}
                  <sup>*</sup>
                  <SelectForm
                    isClearable
                    isSearchable
                    options={formOptions?.questionType}
                    placeholder={"Questions Type"}
                    isDisabled={isDisable}
                    value={formData?.questionType}
                    onChange={(e) => {
                      formOnChange(e, "questionType");
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  {formErrors["questionType"] && (
                    <p style={{ color: "red" }}>{formErrors["questionType"]}</p>
                  )}
                </div>
              </div>
              <div className={"col-lg-6 col-sm-4 col-xs-4"}>
                <div className="dropdown mb-1">
                  <label className="col-form-label">Domains</label>
                  <SelectForm
                    isClearable
                    isSearchable
                    options={formOptions?.domain}
                    placeholder={"Domains"}
                    isDisabled={isDisable}
                    value={formData?.domain}
                    onChange={(e) => {
                      formOnChange(e, "domain");
                    }}
                    isMulti={true}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  {formErrors["domain"] && (
                    <p style={{ color: "red" }}>{formErrors["domain"]}</p>
                  )}
                </div>
              </div>

              <div className={"col-lg-6 col-sm-6 col-xs-6"}>
                <div className="mb-1">
                  <label className="col-form-label">Skippable</label>

                  <div>
                    <input
                      className="ml-2 mr-1"
                      disabled={isDisable}
                      type="radio"
                      onChange={(e) => {
                        setIsSkippable(true);
                      }}
                      checked={isSkippable === true}
                    />
                    <label htmlFor={`True`}>Yes</label>
                    <input
                      className="ml-2 mr-1"
                      disabled={isDisable}
                      type="radio"
                      onChange={(e) => {
                        setIsSkippable(false);
                      }}
                      checked={isSkippable === false}
                    />
                    <label htmlFor={`False`}>No</label>
                  </div>
                </div>
              </div>

              {formData?.questionType?.code === questionTypeObj.SmileyFace && (
                <>
                  <div className={"col-lg-6 col-sm-4 col-xs-4"}>
                    <div className="mb-1">
                      <label className="col-form-label">Rating</label>
                      <Stack spacing={1}>
                        <StyledRating
                          name="highlight-selected-only"
                          defaultValue={5}
                          IconContainerComponent={IconContainer}
                          getLabelText={(value: number) =>
                            customIcons[value].label
                          }
                          //   highlightSelectedOnly
                        />
                      </Stack>
                    </div>
                  </div>
                </>
              )}

              {formData?.questionType?.code === questionTypeObj.StarScale && (
                <>
                  <div className={"col-lg-6 col-sm-4 col-xs-4"}>
                    <div className="mb-1">
                      <label className="col-form-label">Rating</label>{" "}
                      <Stack spacing={1}>
                        <Rating
                          name="size-large"
                          defaultValue={5}
                          size="large"
                        />
                      </Stack>
                    </div>
                  </div>
                </>
              )}

              {formData?.questionType?.code === questionTypeObj.LikertScale && (
                <>
                  <div className={"col-lg-6 col-sm-4 col-xs-4"}>
                    <div className="mb-1">
                      <label className="col-form-label">Rating</label>

                      <div>
                        <input
                          className="ml-1 mr-1"
                          disabled={isDisable}
                          type="radio"
                          onChange={(e) => {
                            // setIsCorrectAnswer(true);
                          }}
                          checked={true}
                        />
                        <label htmlFor={`True`}>Strongly disagree</label>
                        <input
                          className="ml-2 mr-1"
                          disabled={isDisable}
                          type="radio"
                          onChange={(e) => {
                            // setIsCorrectAnswer(false);
                          }}
                          checked={false}
                        />
                        <label htmlFor={`False`}>Disagree</label>
                        <input
                          className="ml-2 mr-1"
                          disabled={isDisable}
                          type="radio"
                          onChange={(e) => {
                            // setIsCorrectAnswer(false);
                          }}
                          checked={false}
                        />
                        <label htmlFor={`False`}>
                          Neither agree nor disagree
                        </label>
                        <input
                          className="ml-2 mr-1"
                          disabled={isDisable}
                          type="radio"
                          onChange={(e) => {
                            // setIsCorrectAnswer(false);
                          }}
                          checked={false}
                        />
                        <label htmlFor={`False`}>Agree</label>
                        <input
                          className="ml-1 mr-1"
                          disabled={isDisable}
                          type="radio"
                          onChange={(e) => {
                            // setIsCorrectAnswer(false);
                          }}
                          checked={false}
                        />
                        <label htmlFor={`False`}>Strongly agree</label>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            {formData?.questionType?.code ===
              questionTypeObj.SingleSelection && (
              <>
                {" "}
                <div className="row">
                  <div className={"col-lg-6 col-sm-4 col-xs-4"}>
                    <div className="mb-1">
                      <label className="col-form-label">Answer</label>{" "}
                      <sup>*</sup>
                      <InputForm
                        className="form-control"
                        placeholder={"Answer"}
                        isDisabled={isDisable}
                        textArea={true}
                        value={answer}
                        onChange={(e) => {
                          setAnswer(e.target.value);
                        }}
                        maxLength="250"
                      />
                      {answerErrors["answer"] && (
                        <p style={{ color: "red" }}>{answerErrors["answer"]}</p>
                      )}
                    </div>
                  </div>
                  <div className={"col-lg-6 col-sm-4 col-xs-4"}>
                    <div className="row">
                      <div className={"col-lg-6 col-sm-4 col-xs-4"}></div>
                      <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                        <label className="col-form-label">&nbsp;</label>
                        {!isDisable && (
                          <div>
                            <button
                              className="btn btn-filter-submit float-right ml-2"
                              onClick={() => {
                                addAnswerFunction();
                              }}>
                              <i className="fa fa-plus mr-2"></i>
                              Add Answer
                            </button>
                          </div>
                        )}

                        <span className="" style={{ color: "red" }}></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-3 pt-3">
                  <DynamicGrid
                    data={gridData}
                    columns={gridColumns}
                    options={gridOptions}
                  />
                  {formErrors["answerGrid"] && (
                    <p className="mb-3 pt-3" style={{ color: "red" }}>
                      {formErrors["answerGrid"]}
                    </p>
                  )}
                </div>
              </>
            )}

            {formData?.questionType?.code === questionTypeObj.FreeText && (
              <>
                {/* <div className="row">
                  <div className={"col-lg-6 col-sm-6 col-xs-6"}>
                    <div className="mb-1">
                      <label className="col-form-label">Correct Answer</label>

                      <div>
                        <input
                          className="ml-2 mr-1"
                          disabled={isDisable}
                          type="radio"
                          onChange={(e) => {
                            setIsCorrectAnswer(true);
                          }}
                          checked={isCorrectAnswer === true}
                        />
                        <label htmlFor={`True`}>True</label>
                        <input
                          className="ml-2 mr-1"
                          disabled={isDisable}
                          type="radio"
                          onChange={(e) => {
                            setIsCorrectAnswer(false);
                          }}
                          checked={isCorrectAnswer === false}
                        />
                        <label htmlFor={`False`}>False</label>
                      </div>
                    </div>
                  </div>
                </div> */}
              </>
            )}

            <br />
            <div className="SectionSubmit mb-4 clearfix">
              {!isDisable && (
                <>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmitFunction();
                    }}
                    disabled={false}
                    className="btn btn-filter-submit float-right ml-2">
                    <i className="fa-solid fa-check"></i> Save
                  </button>

                  <button
                    onClick={() => {
                      handleResetFunction();
                    }}
                    disabled={false}
                    className="btn btn-secondary float-right ml-2">
                    <i className="fa-solid fa-refresh"></i> Reset
                  </button>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Question;
