import React, { useContext, useEffect, useState } from "react";
import { LoaderContext } from "../../Helpers/Context/Context";
import { useLocation, useNavigate } from "react-router-dom";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { Card } from "react-bootstrap";
import { APICall } from "../../Helpers/APICalls";
import {
  getFeedbackPreviewDataById,
  saveUserFeedback,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import FeedbackQuestions from "./FeedbackQuestions";
import notify from "../../Helpers/ToastNotification";

const FeedbackPreview = () => {
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isPreview, setIsPreview] = useState(
    state && state?.preview ? true : false
  );

  const [isPanding, setIsPanding] = useState(
    state && state?.pending ? true : false
  );
  let { userDetails, currentRoleId }: any = useUserContext();

  const [feedbackData, setFeedbackData] = useState([]);

  const [newFeedbackData, setNewFeedbackData] = useState<any>([]);

  useEffect(() => {
    showLoader();
    getFeedbackPreviewDataByIdApiCall();
    hideLoader();
  }, []);

  const getFeedbackPreviewDataByIdApiCall = async () => {
    const res = await APICall(getFeedbackPreviewDataById, "POST", {
      FeedbackId: state?.feedbackId,
      Preview: state?.preview,
      IsPending: state?.pending,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      UserFeedbackId: state?.userFeedbackId || 0,
    });
    if (res?.data?.length > 0 && res.status === 0) {
      setFeedbackData(res.data);
      setNewFeedbackData(res.data);
    } else {
      setFeedbackData([]);
      setNewFeedbackData([]);
    }
  };

  const handleSubmitForm = async () => {
    const skippedQuestion = [];
    newFeedbackData.map((feed, index) => {
      if (!feed.isSkippable && feed.rating == null) {
        skippedQuestion.push(index + 1);
      }
    });

    if (skippedQuestion.length > 0) {
      notify(1, `Question ${skippedQuestion.join(",")} are not skippable!`);
      return null;
    }

    showLoader();

    if (newFeedbackData.length > 0) {
      const feedbackDataParse = JSON.stringify(newFeedbackData);
      const res = await APICall(saveUserFeedback, "POST", {
        FeedbackId: state?.feedbackId,
        CourseScheduleId: state?.courseScheduleId,
        FeedbackData: feedbackDataParse,
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
        UserFeedbackId: state?.userFeedbackId,
      });

      if (res.status === 0) {
        notify(res.status, "Feedback Save sucessfully.");
        navigate("/userFeedback");
      } else {
        notify(res.status, res.message);
      }
    }
    hideLoader();
  };

  return (
    <>
      <div className="breadcrumb modified-bredcrumb">
        <span>
          <ul>
            <li>
              <a href="/home">Home</a>
            </li>
            <li>
              {" "}
              <a href="/userFeedback">Feedback</a>
            </li>
            <li>Manage Feedback</li>
          </ul>
        </span>
        <button
          onClick={() => {
            navigate(-1);
          }}
          disabled={false}
          className="btn btn-secondary float-right">
          <i className="fa-solid fa-arrow-left"></i> Back
        </button>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-12 col-sm-12 col-xs-12">
                <Card>
                  <Card.Header className="bg_dark_blue text-white learningcardHead">
                    Employee Feedback
                  </Card.Header>
                  <Card.Body>
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-lg-4">
                          <b className="wid_obj">
                            <div className="lable_width">Feedback Name</div>:
                          </b>{" "}
                          {feedbackData[0]?.feedbackName}
                        </div>
                        {state && !state.preview && (
                          <div className="col-lg-4">
                            <b className="wid_obj">
                              <div className="lable_width">Feedback Date</div>:
                            </b>
                            {"  "}
                            {feedbackData[0]?.feedbackDate}
                          </div>
                        )}
                      </div>

                      {feedbackData && feedbackData.length > 0 && (
                        <>
                          {feedbackData.map((questionData, Index) => (
                            <FeedbackQuestions
                              Index={Index + 1}
                              questionData={questionData}
                              newFeedbackData={newFeedbackData}
                              setNewFeedbackData={setNewFeedbackData}
                            />
                          ))}
                        </>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </div>

              <div className="col-lg-12 my-2 d-flex justify-content-end">
                {!isPanding && (
                  <>
                    <button
                      className="btn btn-primary"
                      style={{ marginLeft: 5 }}
                      disabled={state?.preview || false}
                      onClick={() => {
                        handleSubmitForm();
                      }}>
                      Submit
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeedbackPreview;
