import React, { useContext, useRef, useState, useEffect } from "react";
import QR from "../QRComponent/QR";
import InputForm from "../../Components/InputForm/InputForm";
import AccordianCustom1 from "../DocumentScreen/AccordianCustom1";
import SelectForm from "../../Components/SelectForm/SelectForm";
import {
  GetPositionDropdownData,
  InsertUpdateAssociateDetails,
  GetDropdownData,
  InsertUpdateTokenIssuanceDetails,
  GetPositionDetailsByPositionId,
  RCBMGetDropdownData,
  GetSkillTestData,
  GetAssociateDetailsById,
  GetSkillTestDataById,
  InsertUpdateAssociatesSkillTest,
  GetStandardMonthlyWage,
  GetAssociatesDocument,
  GetDocumentCheckListByJobId,
  GetEventReasonOptions,
  GetEventOptions,
  UpdateAssociateEventDetails,
  MoveCandidateToEmployeee,
  GetStandardRehireWage,
  GetActiveAppraisalCycle,
  GetSkillAmount,
  GetAssociateDropdownData,
  GetWorkflowStages,
  GetStageNameById,
  GetQRValidDatetimeByToken1,
  GetManageWernerSkillTest,
  CreateSendBackRequest,
  GetSendBackWorkflowDetails,
  GetHistoryDetailBasedOnId,
  RejectApprovalRequest,
  CompleteApprovalRequest,
} from "../../Helpers/APIEndPoints/AssociateEndPoints";
import { GetConfigValueByKey,getApproverDetailsForHireByWorkflowTaskId } from "../../Helpers/APIEndPoints/EndPoints";
import { Tooltip } from "@mui/material";
import { APICall } from "../../Helpers/APICalls";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import notify from "../../Helpers/ToastNotification";
import { LoaderContext } from "../../Helpers/Context/Context";
import { ExitStatus } from "typescript";
import defaultProfileImgPic from "../../Assets/Images/adhars.jpg";
import defaultProfileImgPic1 from "../../Assets/Images/default+_pic.jpg";
import moment from "moment";
import { Modal } from "react-bootstrap";
import DateForm from "../../Components/DateForm/DateForm";
import { useNavigate } from "react-router-dom";
import OrganizationInformation from "../../Components/OrganizationInformation/OrganizationInformation";
import { Table } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { isEmpty } from "../../utils/isEmpty";
import Candidate from "../CandidateStatus/CandidateStatus";

const AppointmentApproval = () => {
  const { state } = useLocation();
  const { TId: Candidate,workflowTaskId:WorkflowTaskId} = state || {}
  const [startScan, setStartScan] = useState(false);
  const [scanData, setScanData] = useState(null);
  const [ApprovalWorkflowTaskId] = useState(!isEmpty(WorkflowTaskId) ? WorkflowTaskId : null);
  const [scanData1, setScanData1] = useState(!isEmpty(Candidate) ? { CandidateId: Candidate } : null);
  const [isaccordiandisable, setIsAccordiandisable] = useState<any>(true);
  const [positionText, setPositionText] = useState<any>({ name: "", id: 0 });
  const [formData, setFormData] = useState<IFormData>({});
  const [business, setBusiness] = useState<any>({ name: "", id: 0 });
  const [sbu, setSBU] = useState<any>({ name: "", id: 0 });
  const [division, setDivision] = useState<any>({ name: "", id: 0 });
  const [subdivision, setsubDivision] = useState<any>({ name: "", id: 0 });
  const [Function, setFunction] = useState<any>(null);
  const [Department, setDepartment] = useState<any>({ name: "", id: 0 });
  const [Section, setSection] = useState<any>({ name: "", id: 0 });
  const [subSection, setSubSection] = useState<any>({ name: "", id: 0 });
  const [empClass, setEmpClass] = useState<any>({ name: "", id: 0 });
  const [JobLevel, setJobLevel] = useState<any>({ name: "", id: 0 });
  const [jobBand, setJobBand] = useState<any>({ name: "", id: 0 });
  const [designation, setDesignation] = useState<any>({ name: "", id: 0 });
  const [jobCode, setJobCode] = useState<any>({ name: "", id: 0 });
  const [skillCategory, setSkillCategory] = useState<any>({ name: "", id: 0 });
  const [reportingManager, setReportingManager] = useState<any>({
    name: "",
    id: 0,
  });
  const [Category, setCategory] = useState<any>(null);
  const [skillCategoryOptions, setSkillCategoryOption] = useState<any>([]);
  const [skillCategorys, setSkillCategorys] = useState(null);
  const [workAreaOption, setWorkAreaOption] = useState<any>([]);
  const [workArea, setWorkArea] = useState<any>(null);
  const [isRefresh, setIsRefresh] = useState<any>(false);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [CategoryOption, setCategoryOption] = useState([
    { label: "On roll Experience", value: 1 },
    { label: "On roll Fresher", value: 2 },
    { label: "On roll Rehire", value: 3 },
    { label: "Contract Worker", value: 4 },
    { label: "Scheme Trainee", value: 5 },
    { label: "Scheme Apprentice", value: 6 },
    { label: "Experience Apprentice", value: 7 },
  ]);
  const [DecisionOption, setDecisionOption] = useState([
    // { label: "Draft", value: 1 },
    // { label: "Issue Token", value: 2 },
    { label: "Rejected", value: 3 },
    { label: "Shortlisted", value: 4 },
  ]);
  const [decisionOptionApproval,setDecisionOptionApproval]=useState([]);
  const [decision, setDecision] = useState<any>(null);
  const [RejectionReasonOption, setRejectionReasonOption] = useState<any>([]);
  const [Rejection, setRejection] = useState<any>(null);
  const [fielddisable, setfielddisable] = useState<any>(true);
  const [StandardWage, setStandardWage] = useState<any>(null);
  const [OfferedMonthlyWage, setOfferedMonthlyWage] = useState<any>(null);
  const [WCLOfferedMonthlyWage, setWCLOfferedMonthlyWage] = useState<any>(null);
  const [OfferedAnnualWage, setOfferedAnnualWage] = useState<any>(null);
  const [WCLOfferedAnnualWage, setWCLOfferedAnnualWage] = useState<any>(null);
  const [hike, setHike] = useState<any>(null);
  const [Justification, setJustification] = useState<any>(null);
  const [isActive, setisActive] = useState<any>(true);
  const [isCandidateBenched, setIsCandidateBenched] = useState<any>(false);
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  const [skillCategoryName, setskillCategoryName] = useState<any>(null);
  const [candidateimage, setCandidateimage] = useState(null);
  const [docURL, setDocURL] = useState<any>(null);
  const [Address1, setAddress1] = useState<any>(null);
  const [Address2, setAddress2] = useState<any>(null);
  const [Address3, setAddress3] = useState<any>(null);
  const [payrollLocation, setPayRolllocation] = useState<any>(null);
  const [modalForGunScanner, setModalForGunScanner] = useState<boolean>(false);
  const [barcode, setBarcode] = useState<string>("");
  const [candidateDetails, setCandidateDetails] = useState<CandidateDetails>(
    {}
  );
  const [avgPercentage, setAvgPercentage] = useState<any>(null);
  const [avgProficiency, setAvgProficiency] = useState<any>(null);
  const [avgRating, setAvgRating] = useState<any>(null);
  const [avgStatus, setAvgStatus] = useState<any>(null);
  const [dashboard, setDashboard] = useState([]);
  const [LegalEntity, setLegalEntity] = useState<any>({ name: "", id: 0 });

  const [eventOptions, setEventOptions] = useState([]);
  const [eventReasonOptions, setEventReasonOption] = useState([]);
  const [effectiveDate, setEffectiveDate] = useState(null);
  const [eventHire, setEventHire] = useState<any>(null);
  const [eventHireReason, setEventHireReason] = useState<any>(null);
  const [eventSectionErrors, setEventSectionErrors] = useState<any>({});
  const [QRCode, setQRCode] = useState<any>(null);
  const [currentStageName, setCurrentStageName] = useState(null);
  const [showcurrentstage, setShowCurrentStage] = useState<any>(false);
  const [isProceedDisable, setisProceedDisable] = useState<any>(false);
  const [isBenchingDisable, setisBenchingDisable] = useState<any>(false);
  const [isTrainee, setIsTrainee] = useState<any>(false);
  const [isDisableAccordian, setIsDisableAccordian] = useState<any>(false);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [formErrorsApproval,setFormErrorsApproval]= useState<FormErrorsApproval>({});
  const navigate = useNavigate();
  const [showModal1, setShowModal1] = useState(false);
  const [incrementAmount, setIncrementAmount] = useState<any>(0);
  const [tokenData, setTokenData] = useState<ITokenData>(!isEmpty(Candidate) ? {candidate_Id:{label:Candidate,value:Candidate}}:{});
  const [showhidebutton, setshowhidebutton] = useState(false);
  const [currentId, setcurrentId] = useState<any>(null);
  const [newPreviousConversation,setNewPreviousConversation] = useState([]);
  interface ITokenData {
    candidate_Id?: IOptions | boolean | any;
  }
  const [positionbutton, setpositionbutton] = useState(false);
  const [showWageSection, setShowWageSection] = useState(true);
  const [showWCLSection, setShowWCLSection] = useState(false);
  const [showWarnerTestSection, setShowWarnerTestSection] = useState(false);
  const [isClmFlow, setIsClmFlow] = useState(false);
  const [warnerTestDetails, setWarnerTestDetails] = useState<WarnerTestDetails>(
    {}
  );
  const [WCLDetails, setWCLDetails] = useState<WCLDetails>(
    {}
  );
  const [decisionApproval,setDecisionApproval] = useState(null);
  const [remarkApproval,setRemarkApproval] = useState(null);
  const [workflowInstanceId, setWorkflowInstanceId] = useState<any>(null);

  type FormErrors = {
    Rejection?: string;
    Event?: string;
    EventReason?: string;
    EffectiveDate?: string;
  };
  type FormErrorsApproval = {
    ApprovalDecision?: string;
    Remarks?: string;
  }
  interface CandidateDetails {
    name?: string;
    mobileNumber?: string;
    category?: string | number | null;
    skillCategory?: string | number | null;
    workArea?: string | number | null;
    isRehire?: number | null;
    resignDate?: Date | null;
  }

  interface IFormData {
    position_Id?: IOptions | boolean | any;
    categoryId?: number | any;
  }

  interface WarnerTestDetails {
    pinBoard?: number | any;
    formBoard?: number | any;
    perception?: number | any;
    Total?: number | any;
    division?: string | any;
    operation?: string | any;
  }

  interface WCLDetails {
    min?: number | any;
    med?: number | any;
    max?: number | any;
  }

  interface IOptions {
    label?: string;
    value?: number | string;
    code?: string;
  }

  const getAssociateDetails = async (id: any) => {
    try {
      showLoader();
      const response = await APICall(GetDocumentCheckListByJobId, "POST", {
        JobId: id,
        AssociatesDetailsId: id,
      });

      if (response?.status === 0 && response?.data.length > 0) {
        console.log(response?.data, "Associate");
        setDashboard(response?.data);
      } else {
        setDashboard([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    const helper = async () => {
      await GetEventOptionsAsync();
    };
    helper();
    return () => {};
  }, []);

  useEffect(() => {
    (async () => {
      setDecision(DecisionOption[1]);
      await GetRejectionReason();
      getDocConfigUrl();
      getworkflowDetails();
      getworkflowtaskid();
    })();
  }, []);

  useEffect(() => {
    if(WCLOfferedAnnualWage){
      setWCLOfferedMonthlyWage(Math.ceil(WCLOfferedAnnualWage / 12));
    }
  }, [WCLOfferedAnnualWage])

  useEffect(() => {
    const helper = async () => {
      if (scanData?.QRCodeExpiry) {
        let isAfterTargetDate = isTimeAfterTargetDate(scanData?.QRCodeExpiry);

        if (isAfterTargetDate) {
          setShowModal1(true);
        } else {
          setShowModal1(false);
          const response = await APICall(GetAssociateDetailsById, "POST", {
            Id: scanData.CandidateId,
            WorkflowStageId: 12,
            CreatedBy: userDetails?.Id,
          });

          let candidateDetails = null;
          if (response) {
            await LoadUserData(response);
            candidateDetails = response.data;
          }

          if (candidateDetails) {
            // 1: If candidate is getting rehired, wage calculation logic is different
            if (candidateDetails.isRehire) {
              let resignDate = moment(candidateDetails.resignDate);

              const currentDate = moment();
              const modifiedDate = currentDate.subtract(365, "days");

              //1.1: candidate has resigned before 365 days.
              if (modifiedDate > resignDate) {
                await getStandardWage();
              } else {
                // 1.2: candidate has resigned in last 365 days.
                await getRehireStandardWage(
                  resignDate,
                  candidateDetails?.skillCategory
                );
              }
            } else {
              //2: Candidate is New to system
              await getStandardWage();
            }
          }
          candidateProfile(scanData.CandidateId);
          await getAssociateDetails(scanData?.CandidateId);
          // await getSkillTestDetails(skillCategory?.id ,scanData.CandidateId);
        }
      }
    };
    helper();
  }, [scanData, isRefresh]);

  useEffect(() => {
    const helper1 = async () => {
      if (scanData1) {
        let QRvalidatedate = await GetQRValidDatetimeByToken(
          scanData1.CandidateId
        );
        let isAfterTargetDate = isTimeAfterTargetDate(QRvalidatedate);
        console.log(QRvalidatedate, "Qrvalidatedate");

        if (isAfterTargetDate) {
          setShowModal1(true);
        } else {
          setShowModal1(false);
          const response = await APICall(GetAssociateDetailsById, "POST", {
            Id: scanData1.CandidateId,
            WorkflowStageId: 12,
            CreatedBy: userDetails?.Id,
          });

          let candidateDetails = null;
          if (response) {
            await LoadUserData1(response);
            candidateDetails = response.data;
          }

          if (candidateDetails) {
            // 1: If candidate is getting rehired, wage calculation logic is different
            if (candidateDetails.isRehire) {
              let resignDate = moment(candidateDetails.resignDate);

              const currentDate = moment();
              const modifiedDate = currentDate.subtract(365, "days");

              //1.1: candidate has resigned before 365 days.
              if (modifiedDate > resignDate) {
                await getStandardWage();
              } else {
                // 1.2: candidate has resigned in last 365 days.
                await getRehireStandardWage(
                  resignDate,
                  candidateDetails?.skillCategory
                );
              }
            } else {
              //2: Candidate is New to system
              await getStandardWage();
            }
          }
          candidateProfile(scanData1.CandidateId);
          await getAssociateDetails(scanData1?.CandidateId);
          // await getSkillTestDetails(skillCategory?.id ,scanData.CandidateId);
        }
      }
    };
    helper1();
  }, [scanData1]);

  useEffect(() => {
    const helperFunction = async () => {
      if (formData?.position_Id && formData?.position_Id !== null) {
        await getPositionbyId();
        setpositionbutton(true);
      } else {
        setIsAccordiandisable(true);
      }
    };
    helperFunction();
  }, [formData]);

  useEffect(() => {
    if (decision?.value == "3") {
      if (currentId === 6) {
        setfielddisable(false);
      } else {
        setfielddisable(true);
      }
      setisProceedDisable(true);
      setshowhidebutton(true);
      setIsDisableAccordian(true);
    } else {
      if (currentId === 6) {
        setfielddisable(true);
        setisProceedDisable(false);
        setshowhidebutton(false);
        setisBenchingDisable(false);
        setIsDisableAccordian(false);
      } else {
        setIsDisableAccordian(true);
        setfielddisable(true);
        setisProceedDisable(true);
        setisBenchingDisable(true);
      }
    }
  }, [decision]);

  useEffect(() => {
    let CTC = OfferedMonthlyWage * 12;
    setOfferedAnnualWage(CTC);

    let hike = (OfferedMonthlyWage / StandardWage - 1) * 100;

    if (
      hike !== null &&
      hike !== undefined &&
      StandardWage !== null &&
      StandardWage !== undefined
    ) {
      setHike(hike.toFixed(2));
    } else {
      setHike(0);
    }
  }, [OfferedMonthlyWage]);

  useEffect(() => {
    const handleKeyDown = async (evt: any) => {
      try {
        if (evt.code === "Enter") {
          if (barcode) await handleBarcode(barcode);
          return;
        }
        if (evt.key !== "Shift") setBarcode((prev) => prev + evt.key);
      } catch (error) {
        hideLoader();
      }
    };
    if (modalForGunScanner) {
      showLoader();
      document.addEventListener("keydown", handleKeyDown);
      hideLoader();
    }
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [barcode, modalForGunScanner]);


  async function handleBarcode(scannedBarcode: any) {
    const object = JSON.parse(scannedBarcode);
    console.log(object);
    setScanData(object || null);
    setBarcode("");
    setModalForGunScanner(false);
    await handleScanData(scannedBarcode);
    hideLoader();
  }

  function isTimeAfterTargetDate(targetDate) {
    // Convert the given date string to a JavaScript Date object
    const targetDateTime = new Date(targetDate);

    // Get the current date and time
    const currentDate = new Date();

    // Check if the current date and time are after the target date and time
    return currentDate > targetDateTime;
  }

  const handleScanData = (data) => {
    console.log(data);
    setScanData(JSON.parse(data));
    const response = JSON.parse(data);
    candidateProfile(response?.CandidateId);
    setStartScan(false);
  };

  const GetQRValidDatetimeByToken = async (id) => {
    try {
      const response = await APICall(GetQRValidDatetimeByToken1, "POST", {
        TokenId: id,
      });

      const qrValidDatetimeString = response?.data?.qrValidDatetime;

      if (!qrValidDatetimeString) {
        throw new Error("QR valid datetime not found in response");
      }

      const qrValidDatetime = new Date(qrValidDatetimeString);
      const formattedDatetime = qrValidDatetime.toISOString().slice(0, 19);

      console.log(formattedDatetime, "Response");

      return formattedDatetime;
    } catch (error) {
      console.error("Error fetching QR valid datetime:", error);
      throw error; // Propagate the error further if needed
    }
  };

  const candidateProfile = async (id: any) => {
    const response = await APICall(GetAssociatesDocument, "POST", {
      AssociatesDetailsId: id,
      DocumentTypeId: 8,
    });

    if (response.data !== null) {
      console.log(response.data, "console");
      setCandidateimage(response?.data?.documentDataObject);
    }
  };

  async function handleSearchForAsyncDropdown(event, code, callback) {
    try {
      if (event?.length >= 3) {
        const data = await fetchDropdown(event, code);
        callback(data);
      } else {
        callback([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchDropdown(searchText = "", code = "", value = null) {
    try {
      const response = await APICall(
        GetPositionDropdownData,
        "POST",
        {
          SearchText: searchText,
          userId: userDetails.Id,
        },
        true
      );

      if (response?.status === 0) {
        if (response?.data) {
          return response?.data;
        } else {
          return [];
        }
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  function onChange(event, code) {
    try {
      setFormData((prev) => {
        return { ...prev, [code]: event };
      });
    } catch (error) {
    } finally {
    }
  }
  const LoadUserData = async (candidateDetails: any) => {
    showLoader();

    console.log(candidateDetails, "user");
    if (candidateDetails.data != null) {
      const { data } = candidateDetails;
      if (data) {
        setCandidateDetails((prev) => ({
          ...prev,
          category: data?.category || "",
          skillCategory: data?.skillCategory || "",
          workArea: data?.workAreaName || "",
          mobileNumber: data?.mobileNumber || "",
          name: data?.name || "",
          isRehire: data?.isRehire || 0,
          resignDate: data?.resignDate || 0,
        }));

        const tempEvent = eventOptions?.find(
          (i) => i?.value == data?.newHireEventId
        );

        if (tempEvent) {
          setEventHire(tempEvent);
        } else {
          const defaultEvent = eventOptions?.find((i) => i?.code === "Hire");
          setEventHire(defaultEvent);
        }

        let tempEventReasonOptions = await GetEventReasonOptionsAsync(
          data?.newHireEventId
        );

        if (tempEventReasonOptions) {
          const tempEventReason = tempEventReasonOptions?.find(
            (i) => i?.value == data?.newHireEventReasonId
          );

          if (tempEventReason) {
            setEventHireReason(tempEventReason);
            setisProceedDisable(false);
          } else {
            const defaultEventReason = tempEventReasonOptions?.find(
              (i) => i?.code === "REP"
            );
            setEventHireReason(defaultEventReason);
          }
        }

        if (data?.newHireEffectiveDate) {
          let date = moment(data?.newHireEffectiveDate, "DD-MM-YYYY");

          if (moment(date).isValid()) {
            setEffectiveDate(date);
          } else {
            setEffectiveDate(moment(moment(), "DD-MM-YYYY"));
          }
        }

        QRCodeExist(scanData.candidateId);
      }

      if (candidateDetails.data.positionId !== null) {
        const position_Ids = await fetchDropdown(
          candidateDetails?.data?.positionId,
          "co_ordinator"
        );
        console.log(position_Ids, "position_ID");
        const position = position_Ids[0];

        setFormData((prev) => {
          return {
            ...prev,
            position_Id: position,
            categoryId: candidateDetails?.data?.categoryId,
            currentStageId: candidateDetails?.data?.currentStagesId,
          };
        });
        setAddress1(candidateDetails?.data?.address1);
        setAddress2(candidateDetails?.data?.address2);
        setAddress3(candidateDetails?.data?.address3);
        WorkAreafun(candidateDetails?.data?.positionId);
        // setJobId(response?.data?.jobId)
        if (candidateDetails?.data?.decision !== 3) {
          getSkillTestDetails(
            candidateDetails?.data?.jobId,
            scanData.CandidateId
          );

          await getWarnerTestDetails(scanData.CandidateId);
        }
        setskillCategoryName(candidateDetails?.data?.skillCategory);
      }
      setcurrentId(candidateDetails?.data?.currentStagesId);

      setWCLOfferedAnnualWage(candidateDetails?.data?.approvalWageAmount);

      // const filteredOptions = CategoryOption.filter(
      //   (option) => option.value === response?.data?.categoryId
      // );
      // setCategory(filteredOptions[0]);

      if (candidateDetails?.data?.currentStagesId != 6) {
        setIsDisableAccordian(true);
        setisProceedDisable(true);
        setisBenchingDisable(true);
      } else {
        if (
          candidateDetails?.data?.currentStagesId == 6 &&
          candidateDetails?.data?.decision == 5
        ) {
          setIsCandidateBenched(true);
          setisBenchingDisable(true);
          setIsDisableAccordian(false);
          setisProceedDisable(false);
        }
        if (
          candidateDetails?.data?.currentStagesId == 6 &&
          candidateDetails?.data?.decision == 3
        ) {
          setIsDisableAccordian(true);
          setisProceedDisable(true);
        }
        if (
          candidateDetails?.data?.currentStagesId == 6 &&
          candidateDetails?.data?.decision == 4
        ) {
          setisBenchingDisable(false);
          setIsDisableAccordian(false);
          setisProceedDisable(false);
        }
      }

      setCategory(candidateDetails?.data?.category);
      let decisionValue = DecisionOption.find(
        (x) => x.value === candidateDetails?.data?.decision
      );
      if (decisionValue) {
        setDecision(decisionValue);
      }
    }
    hideLoader();
  };

  useEffect(() => {
    const fetchStages = async () => {
      if ((scanData || scanData1) && currentId != 6) {
        let getWorkFlowStages = await APICall(GetStageNameById, "POST", {
          CurrentStagesId: currentId,
        });

        let currentStageName = "";
        if (getWorkFlowStages && getWorkFlowStages.status === 0) {
          if (getWorkFlowStages.data) {
            currentStageName = getWorkFlowStages.data.candidateCurrentstage;
            setCurrentStageName(currentStageName);
            setShowCurrentStage(true);
          }
        }
      }
    };

    fetchStages();
  }, [currentId, scanData?.CandidateId, tokenData?.candidate_Id, scanData1?.CandidateId]);

  const LoadUserData1 = async (candidateDetails: any) => {
    showLoader();

    console.log(candidateDetails, "user");
    if (candidateDetails.data != null) {
      const { data } = candidateDetails;
      if (data) {
        setCandidateDetails((prev) => ({
          ...prev,
          category: data?.category || "",
          skillCategory: data?.skillCategory || "",
          workArea: data?.workAreaName || "",
          mobileNumber: data?.mobileNumber || "",
          name: data?.name || "",
          isRehire: data?.isRehire || 0,
          resignDate: data?.resignDate || 0,
        }));

        const tempEvent = eventOptions?.find(
          (i) => i?.value == data?.newHireEventId
        );

        const tempEventReason = eventReasonOptions?.find(
          (i) => i?.value == data?.newHireEventReasonId
        );

        if (tempEvent) setEventHire(tempEvent);
        if (tempEventReason) {
          setEventHireReason(tempEventReason);
          setisProceedDisable(false);
        }

        if (data?.newHireEffectiveDate) {
          let date = moment(data?.newHireEffectiveDate, "DD-MM-YYYY");

          if (moment(date).isValid()) {
            setEffectiveDate(date);
          } else {
            setEffectiveDate(moment(moment(), "DD-MM-YYYY"));
          }
        }

        QRCodeExist(scanData1?.CandidateId);
      }

      if (candidateDetails.data.positionId !== null) {
        const position_Ids = await fetchDropdown(
          candidateDetails?.data?.positionId,
          "co_ordinator"
        );
        console.log(position_Ids, "position_ID");
        const position = position_Ids[0];

        setFormData((prev) => {
          return {
            ...prev,
            position_Id: position,
            categoryId: candidateDetails?.data?.categoryId,
            currentStageId: candidateDetails?.data?.currentStagesId,
          };
        });
        setAddress1(candidateDetails?.data?.address1);
        setAddress2(candidateDetails?.data?.address2);
        setAddress3(candidateDetails?.data?.address3);
        WorkAreafun(candidateDetails?.data?.positionId);
        // setJobId(response?.data?.jobId)
        getSkillTestDetails(
          candidateDetails?.data?.jobId,
          scanData1.CandidateId
        );
        await getWarnerTestDetails(scanData1.CandidateId);
        setskillCategoryName(candidateDetails?.data?.skillCategory);
      }
      setcurrentId(candidateDetails?.data?.currentStagesId);
      setWCLOfferedAnnualWage(candidateDetails?.data?.approvalWageAmount);

      // const filteredOptions = CategoryOption.filter(
      //   (option) => option.value === response?.data?.categoryId
      // );
      // setCategory(filteredOptions[0]);

      if (candidateDetails?.data?.currentStagesId != 6) {
        setIsDisableAccordian(true);
        setisProceedDisable(true);
        setisBenchingDisable(true);
      } else {
        if (
          candidateDetails?.data?.currentStagesId === 6 &&
          candidateDetails?.data?.decision === 5
        ) {
          setIsCandidateBenched(true);
          setisBenchingDisable(true);
          setIsDisableAccordian(false);
          setisProceedDisable(false);
        }
        if (
          candidateDetails?.data?.currentStagesId == 6 &&
          candidateDetails?.data?.decision == 3
        ) {
          setIsDisableAccordian(true);
          setisProceedDisable(true);
        }
        if (
          candidateDetails?.data?.currentStagesId == 6 &&
          candidateDetails?.data?.decision == 4
        ) {
          setisBenchingDisable(false);
          setIsDisableAccordian(false);
          setisProceedDisable(false);
        }
      }

      setCategory(candidateDetails?.data?.category);
    }
    hideLoader();
  };

  const getLabelForStage = (stageID) => {
    switch (stageID) {
      case 1:
        return "Candidate Authentication";
      case 2:
        return "Document Screen";
      case 3:
        return "Medical  Screen";
      case 4:
        return "Skill Test  Screen";
      case 5:
        return "UAN  Screen";
      case 6:
        return "Appointment  Screen";
      case 13:
        return "Warner Test";
      // Add more cases for additional stages
      default:
        return "EP";
    }
  };
  const QRCodeExist = async (id: any) => {
    const response = await APICall(GetAssociatesDocument, "POST", {
      AssociatesDetailsId: id,
      DocumentTypeId: 7,
    });
    console.log(response?.data);
    if (response.data !== null) {
      setQRCode(response.data?.documentDataObject);
    }
  };
  const WorkAreafun = async (id: any) => {
    debugger;
    const response = await APICall(RCBMGetDropdownData, "POST", {
      dropdownName: "PositionWorkArea",
      PositionId: id,
    });
    // dropdownName : 'PositionWorkArea'
    //PositionId: positionId
    if (response?.data != null) {
      console.log(response?.data, "wrk");
      if (Array.isArray(response?.data)) {
        // Assuming response.data is an array
        const mappedOptions = response.data.map((item) => ({
          label: item.workAreaName, // Adjust property names based on your actual data structure
          value: item.workAreaId, // Adjust property names based on your actual data structure
        }));
        setWorkArea(response?.data[0]?.workAreaName);

        setWorkAreaOption(mappedOptions);
        //setWorkArea(workArea[0]);

        // setSkillCategoryOption(mappedOptions[0]);  // Assuming you want the first element
      } else {
        console.error("response.data is not an array");
      }
    }
  };

  const handleModalClose1 = () => {
    // Close the modal when needed
    setShowModal1(false);
  };
  const getPositionbyId = async () => {
    const response = await APICall(GetPositionDetailsByPositionId, "POST", {
      PositionId: formData?.position_Id.value,
    });
    if (response?.status === 0) {
      console.log(response?.data, "Sanket");
      setIsAccordiandisable(false);
      setPositionText({
        name: response?.data?.positionText,
        id: response?.data?.positionId,
      });

      setBusiness({
        name: response?.data?.business,
        id: response?.data?.businessId,
      });

      setSBU({
        name: response?.data?.sbu,
        id: response?.data?.sbuId,
      });

      setDivision({
        name: response?.data?.division,
        id: response?.data?.divisionId,
      });

      setsubDivision({
        name: response?.data?.subdivision,
        id: response?.data?.subdivisionId,
      });

      setFunction({
        name: response?.data?.function,
        id: response?.data?.functionId,
      });

      setDepartment({
        name: response?.data?.department,
        id: response?.data?.departmentId,
      });

      setSection({
        name: response?.data?.sectionName,
        id: response?.data?.sectionId,
      });

      setSubSection({
        name: response?.data?.subSection,
        id: response?.data?.subSectionId,
      });

      setEmpClass({
        name: response?.data?.employeeClass,
        id: response?.data?.employeeClassId,
        code: response?.data?.employeeClassCode,
      });

      setJobLevel({
        name: response?.data?.jobLevel,
        id: response?.data?.jobLevelId,
      });

      setJobBand({
        name: response?.data?.jobBand,
        id: response?.data?.jobBandId,
      });

      setDesignation({
        name: response?.data?.designation,
        id: response?.data?.designationId,
      });

      setJobCode({
        name: response?.data?.jobCode,
        id: response?.data?.jobCodeId,
      });

      setSkillCategory({
        name: response?.data?.jobTitles,
        id: response?.data?.jobTitleId,
      });

      setReportingManager({
        name: response?.data?.reportingManager,
        id: response?.data?.reportingManagerId,
      });
      setLegalEntity({
        name: response?.data?.legalEntity,
        id: response?.data?.legalEntityId,
      });
      WorkAreafun(response?.data?.positionCode);
      setPayRolllocation(response?.data?.payrollLocation || "");
      if (response?.data?.employeeClassCode === "CLM") {
        setShowWarnerTestSection(false);
        setShowWageSection(false);
        setIsClmFlow(true);
      }

      if (
        response?.data?.employeeClassCode === "SchemeTrainee" ||
        response?.data?.employeeClassCode === "SchemeApprentice"
      ) {
        setShowWarnerTestSection(true);
        setShowWageSection(true);
        setIsClmFlow(true);
      }
      if (response?.data?.employeeClassCode === "ExperienceApprentice") {
        setShowWarnerTestSection(false);
        setShowWageSection(true);
        setIsClmFlow(true);
      }
    }
  };
  const today = new Date(); // Get today's date
  const yesterday = new Date(today); // Create a new date object with today's date
  let yest1 = yesterday.setDate(today.getDate() - 2);
  const GetRejectionReason = async () => {
    const { data } = await APICall(GetDropdownData, "POST", {
      dropdownName: "Appointment",
    });

    if (data !== null && data.length > 0) {
      let reason = data.map((element) => ({
        value: element.value,
        label: element.label,
      }));
      setRejectionReasonOption(reason);
    }
  };

  const getRehireStandardWage = async (resignDate, skillCategory) => {
    const { data } = await APICall(GetStandardRehireWage, "POST", {
      Id: scanData?.CandidateId || scanData1.CandidateId,
    });

    if (data !== null) {
      // Get active appraisal cycle
      const appraisalCycleData = await APICall(
        GetActiveAppraisalCycle,
        "POST",
        {}
      );

      if (appraisalCycleData.status === 0) {
        if (appraisalCycleData.data.length > 0) {
          let appraisalCycle = appraisalCycleData.data[0];
          let fromDate = appraisalCycle.fromDate;
          let toDate = appraisalCycle.toDate;

          let skillAmount = 0;

          //1: Resign Date is between current active cycle.
          if (moment(fromDate) < resignDate && resignDate < moment(toDate)) {
            setStandardWage(Math.round(data.standardWage / 12));
            setOfferedMonthlyWage(Math.round(data.standardWage / 12));
          } else {
            //2: Resign Date is not between current active cycle. (Candidate has joined again but the appraisal cycle is passed.)
            // extra amount based on the skill category of candidate will be added.
            const skillAmountData = await APICall(GetSkillAmount, "POST", {
              skillCategory: skillCategory,
            });

            if (
              skillAmountData.status === 0 &&
              skillAmountData.data.length > 0
            ) {
              const currentDate = moment();
              let skill = skillAmountData.data.find(
                (x) => moment(x.effectiveDate) < currentDate
              );

              if (skill) {
                skillAmount = skill.wage;
                setIncrementAmount(skillAmount);
              }
            }

            setStandardWage(Math.round(data.standardWage / 12) + skillAmount);
            setOfferedMonthlyWage(
              Math.round(data.standardWage / 12) + skillAmount
            );
          }
        }
      }
    }
  };

  const getStandardWage = async () => {
    const { data } = await APICall(GetStandardMonthlyWage, "POST", {
      Id: scanData?.CandidateId || scanData1.CandidateId,
      //Id: 209,
    });

    if (data !== null) {

      if(data.minimunAmount && data.medianAmount && data.maximumAmount){
        setShowWCLSection(true);
        setWCLDetails({
          min: data.minimunAmount,
          med: data.medianAmount,
          max: data.maximumAmount
        })
      } else{
        // setStandardWage(data.standardWage);
      setStandardWage((prev) => data.standardWage);
      setOfferedMonthlyWage((prev) => data.standardWage);
      }

    }
  };

  const submitWageFinalisation = async (isCandidateBenched: boolean) => {
    submitDecision(isCandidateBenched);
  };

  const submitWage = async (isCandidateBenched: boolean) => {
    try {
      showLoader();
      let requestParams = {
        Id: scanData?.CandidateId || scanData1?.CandidateId,
        Section: "WageFinalization",
        MonthlyWage: OfferedMonthlyWage,
        WageJustification: Justification,
        IsActive: isActive,
        CreatedBy: userDetails?.Id,
      };
      const response = await APICall(
        InsertUpdateAssociateDetails,
        "POST",
        requestParams
      );

      if (response.status === 0) {
        if (isCandidateBenched) {
          window.location.reload();
          notify(0, "Candidate Benched Successfully");
          setisBenchingDisable(true);
          setIsDisableAccordian(true);
          setisProceedDisable(true);
        } else {
          await MoveCandidatetoEmloyee();
        }
        // notify(0, "Data Inserted Successfully");
      } else {
        notify(1, "Data not inserted");
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  const submitApprovalDecision = async () => {

    let errors: FormErrorsApproval = {};
    let valid = true;
    if(isEmpty(decisionApproval)){
      errors.ApprovalDecision ="Please Provide Decision";
      valid = false;
    }
    if(isEmpty(remarkApproval)){
      errors.Remarks = "Please Provide Remrk";
      valid = false;
    }
    if(valid){
      if(decisionApproval?.value === 1){

        // Do after approval things
        let sendBackResponse = await APICall(CompleteApprovalRequest, "POST", {
          WorkflowTaskId: ApprovalWorkflowTaskId,
          CreatedBy: userDetails?.Id,
          CurrentRoleId: currentRoleId?.value,
          ApprovalRemark: remarkApproval
        });
        
        if(sendBackResponse && sendBackResponse.status === 0){
          // convert candidate to employee
          await MoveCandidatetoEmloyee();
          navigate("/home");
        } else{
          notify(1, 'Something went wrong');
        }

      } else if(decisionApproval?.value === 2){

        let sendBackResponse = await APICall(RejectApprovalRequest, "POST", {
          WorkflowTaskId: ApprovalWorkflowTaskId,
          CreatedBy: userDetails?.Id,
          CurrentRoleId: currentRoleId?.value,
          ApprovalRemark: remarkApproval
        });
  
        if(sendBackResponse && sendBackResponse.status === 0){
          notify(0, "Candidate Rejected!");
          navigate("/home");
        } else{
          notify(1, 'Something went wrong');
        }

      } else if (decisionApproval?.value === 3){
        
        let sendBackResponse = await APICall(CreateSendBackRequest, "POST", {
          WorkflowTaskId: ApprovalWorkflowTaskId,
          CreatedBy: userDetails?.Id,
          CurrentRoleId: currentRoleId?.value,
          ApprovalRemark: remarkApproval
        });
  
        if(sendBackResponse && sendBackResponse.status === 0){
          notify(0, "Request Sendback Successfully!");
          navigate("/home");
        } else{
          notify(1, 'Something went wrong');
        }
      }
    }
     else{
      setFormErrorsApproval(errors);
     }
  }

  const submitDecision = async (isCandidateBenched: boolean) => {
    let errors: FormErrors = {};
    let isValid = true;
    if (decision.value == "3") {
      if (!Rejection?.value) {
        errors.Rejection = "Reason of rejection is required";
        isValid = false;
      }
      if (isCandidateBenched) {
        notify(1, "Cannot Bench Rejected Candidate!");
      }
    }

    if (!isCandidateBenched && empClass.code !== "CLM") {
      if (!StandardWage || !OfferedMonthlyWage) {
        notify(1, "Cannot Move Candidate to EP, Please add wages");
      }
    }

    if (decision.value == "4") {
      if (!eventHire?.value) {
        //notify(1, "Event required.");
        errors.Event = "Event is Required";
        isValid = false;
      }
      if (!eventHireReason?.value) {
        errors.EventReason = "Event Reason is Required";
        isValid = false;
      }

      if (!moment(effectiveDate).isValid()) {
        //notify(1, "Invalid effective date.");
        errors.EffectiveDate = "Effective Date is Required";
        isValid = false;
      }
    }

    if (isValid) {
      let stageId = null;

      let getWorkFlowStages = await APICall(GetWorkflowStages, "POST", {
        employeeClass: empClass.code,
        CategoryId: formData?.categoryId,
      });

      if (getWorkFlowStages && getWorkFlowStages.status === 0) {
        if (getWorkFlowStages.data && getWorkFlowStages.data.length > 0) {
          let stages = getWorkFlowStages.data;
          let currentStage = stages.find((x) => x.id === currentId);
          if (currentStage) {
            let nextStageId = stages.find(
              (x) => x.sequence > currentStage.sequence
            );
            if (nextStageId) {
              stageId = nextStageId.id;
            }
          }
        }
      }

      let requestParams = {
        AssociateId: scanData?.CandidateId || scanData1?.CandidateId,
        StageId:
          isCandidateBenched || decision.value == "3" ? currentId : stageId,
        WorkflowStageId: 12,
        Decision: isCandidateBenched ? 5 : decision?.value,
        RejectionReason: Rejection?.value, // RejectionReason,
        IsActive: isActive,
        CreatedBy: userDetails?.Id,
      };
      const response = await APICall(
        InsertUpdateTokenIssuanceDetails,
        "POST",
        requestParams
      );

      if (response.status === 0) {
        if (isCandidateBenched || decision.value == "4") {
          submitWage(isCandidateBenched);
        } else if (decision.value == "3") {
          notify(
            1,
            `Candidate with Token ID ${
              scanData?.CandidateId || scanData1?.CandidateId
            } has been rejected`
          );
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        } else {
          notify(
            0,
            `The data for Candidate ID ${scanData?.CandidateId} has been successfully submitted. You can proceed for next candidate`
          );
          //MoveCandidatetoEmloyee();
        }
      } else {
        notify(1, "Data not inserted");
      }
    } else {
      setFormErrors(errors);
    }
  };

  const MoveCandidatetoEmloyee = async () => {
    showLoader();
    let requestParams = {
      AssociateId: scanData?.CandidateId || scanData1?.CandidateId,
      CurrentDate: new Date(),
      UserId: userDetails?.Id,
      isClmWorkflow: isClmFlow,
    };

    const response = await APICall(
      MoveCandidateToEmployeee,
      "POST",
      requestParams
    );
    if (response.status == 0) {
      notify(
        0,
        `The data for Candidate ID ${scanData?.CandidateId || scanData1?.CandidateId} has been successfully submitted. You can proceed for next candidate`
      );
      navigate("/home");
      hideLoader();
    } else if (response.status == 5) {
      notify(0, "Data  inserted partially");
      navigate("/home");
      hideLoader();
    } else {
      notify(1, "Data not inserted Successfully");
      //window.location.reload();
      hideLoader();
    }
  };

  const handleDocumentClick = async () => {
    showLoader();
    const currentDate = moment().format("DD-MM-YYYY");
    let reqparams = {
      documenttypecode: "APOINTLTR",
      attributes: [
        {
          initials: "Mr",
          cdemployeename: candidateDetails?.name || "",
          datebold: currentDate, // "04.12.2023",
          addrline1: Address1, // "Vill-Mahamudapur",
          addrline2: Address2, //"PO-Mahmoodpur,Tal. -Ghazipur",
          addrline3: Address3, // "Dist. -Ghazipur,Uttar Pradesh - 233303",
          grpdatejoiningbold: currentDate, // "04.12.2023",
          stddesignation: designation?.name, // "Operator",
          empdivision: workArea,
          ctc: OfferedMonthlyWage == null ? "" : OfferedMonthlyWage.toString(),
          signername: candidateDetails?.name || "",
        },
      ],
    };

    const response = await APICall(
      //"https://localhost:7190/GenerateBlueColarDocument",
      `${docURL}/GenerateBlueColarDocument`,
      "POST",
      reqparams
    );

    if (response.status === 200) {
      getDocumentById(response.data);
    } else {
      notify(1, "Data not found");
      hideLoader();
    }
    // hideLoader();
  };

  const getDocumentById = async (data: any) => {
    //showLoader();
    let reqparams = {
      requestid: data,
      doctype: "signedpdf",
    };
    const response = await APICall(
      //"https://localhost:7190/Statuswithfile",
      `${docURL}/Statuswithfile`,
      "POST",
      reqparams
    );

    if (response.status === 200) {
      //setDocURL(response.data)
      openBase64FileInNewTab(response.data, "AppointmentLetter.pdf");
      hideLoader();
    } else if (response.status === 404) {
      getDocumentById(data);
    } else {
      notify(1, "Data not found");
      hideLoader();
    }
  };

  function openBase64FileInNewTab(
    base64String,
    fileName,
    isDownload: any = false
  ) {
    const mimeType = inferMimeType(fileName);
    const blob = base64toBlob(base64String, mimeType);

    const url = URL.createObjectURL(blob);

    // Open the URL in a new tab

    if (isDownload) {
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;

      // Simulate a click on the link to trigger the download
      link.click();
    } else {
      const newTab = window.open(url, "_blank");
      if (newTab) {
        newTab.focus();
      } else {
        console.error(
          "Opening a new tab was blocked by the browser. You can try allowing pop-ups for this site."
        );
      }
    }

    // Revoke the Object URL when it's no longer needed
    URL.revokeObjectURL(url);
  }

  function base64toBlob(base64String, mimeType) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  }

  const getDocConfigUrl = async () => {
    const response = await APICall(GetConfigValueByKey, "POST", {
      Key: "WelspunDMSAPILink",
    });

    if (response?.status === 0) {
      setDocURL(response.data);
      return response.data;
    } else {
      return null;
    }
  };
  
  const getworkflowDetails = async () => {
    const response = await APICall(
      GetSendBackWorkflowDetails,
      "POST",
      {
        CandidateToken: Candidate || 0,
      }
    );
  
    if(response && response.status === 0){
      if(response.data){
        setWorkflowInstanceId(response.data.workflowInstanceId);
      }
    }
  }

const getworkflowtaskid = async () => {
  const responseData = await APICall(getApproverDetailsForHireByWorkflowTaskId, "POST", {
    Id: WorkflowTaskId,
  });

    if(!isEmpty(responseData?.data?.AdditionalDetails)){
      const additionalDetails =
      JSON.parse(responseData?.data?.AdditionalDetails) || [];
      setDecisionOptionApproval(additionalDetails)
    }
    
    const responseDataHistory = await APICall(GetHistoryDetailBasedOnId, "POST", {
      WorkflowTaskId:WorkflowTaskId || 0,
      WorkflowInstanceId:workflowInstanceId || 0,
    });
     if(responseDataHistory && responseDataHistory.data && responseDataHistory.data.length > 0){
      setNewPreviousConversation(responseDataHistory?.data);
     }
};
   
  function inferMimeType(fileName) {
    const extension = fileName.split(".").pop().toLowerCase();

    switch (extension) {
      case "pdf":
        return "application/pdf";
      case "docx":
        return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      case "txt":
        return "text/plain";
      default:
        return "application/octet-stream"; // Default to binary if the type is unknown
    }
  }

  const getSkillTestDetails = async (jobId: any, candidateId: any) => {
    try {
      //showLoader();
      const response = await APICall(GetSkillTestData, "POST", {
        JobId: jobId,
        AssociateId: candidateId,
      });

      showLoader();
      if (response?.status === 0 && response?.data.length > 0) {
        console.log(response?.data, "Associate");
        //setdashboard(response?.data);
        //setDashboardCount(response?.data[0].count);
        setIsTrainee(response?.data[0].isTrainee);
        calculateOverallData(response?.data[0].avgPercentage);
      } else {
        //setdashboard([]);
        //setDashboardCount(0);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const getWarnerTestDetails = async (candidateId: number) => {
    try {
      const response = await APICall(GetManageWernerSkillTest, "POST", {
        TokenId: candidateId,
      });

      if (response?.status === 0 && response?.data) {
        const WernerSkillTesData = response?.data;

        const totalPoint =
          WernerSkillTesData?.evaluationScorePinBoard +
          WernerSkillTesData?.evaluationScoreFormBoard +
          WernerSkillTesData?.evaluationScorePerception;

        setWarnerTestDetails((prev) => {
          return {
            ...prev,
            pinBoard: WernerSkillTesData?.evaluationScorePinBoard,
            formBoard: WernerSkillTesData?.evaluationScoreFormBoard,
            perception: WernerSkillTesData?.evaluationScorePerception,
            Total: totalPoint,
            division: WernerSkillTesData?.divisionName,
            operation: WernerSkillTesData?.operationName,
          };
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };

  const calculateOverallData = (avgPer: any) => {
    // showLoader();
    // let data=dashboard
    // if(data!=null){
    //   let avgPer=data[0].avgPercentage;
    setAvgPercentage(avgPer);

    if (avgPer >= 90) {
      setAvgProficiency("Master");
      setAvgRating(4);
      setAvgStatus("Pass");
      // setDecision(1);
      setDecision(DecisionOption[1]);
    } else if (avgPer >= 75 && avgPer < 90) {
      setAvgProficiency("Expert");
      setAvgRating(3);
      setAvgStatus("Pass");
      // setDecision(1);
      setDecision(DecisionOption[1]);
    } else if (avgPer >= 60 && avgPer < 75) {
      setAvgProficiency("Beginner");
      setAvgRating(2);
      setAvgStatus("Failed");
      setDecision(DecisionOption[0]);
    } else if (avgPer >= 0 && avgPer < 59) {
      setAvgProficiency("Learner");
      setAvgRating(1);
      setAvgStatus("Failed");
      setDecision(DecisionOption[0]);
    } else {
      setAvgProficiency("");
      setAvgRating("");
      setAvgStatus("");
    }
  };

  async function GetEventOptionsAsync() {
    try {
      showLoader();
      const { data, status, message } = await APICall(
        GetEventOptions,
        "POST",
        {}
      );

      if (status == 0 && data?.length > 0) {
        setEventOptions(data);
        if (data?.length == 1) await GetEventReasonOptionsAsync(data[0]?.value);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  async function handleSearchForCandidateIdDropdown(event, code, callback) {
    try {
      if (event?.length >= 1) {
        const data = await fetchCandidateDropdown(event, code);
        callback(data);
      } else {
        callback([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchCandidateDropdown(
    searchText = "",
    code = "",
    value = null
  ) {
    try {
      const response = await APICall(
        GetAssociateDropdownData,
        "POST",
        {
          SearchText: searchText,
          userId: userDetails.Id,
        },
        true
      );

      if (response?.status === 0) {
        if (response?.data) {
          return response?.data;
        } else {
          return [];
        }
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  async function GetEventReasonOptionsAsync(id = 0) {
    try {
      showLoader();
      setEventHireReason(null);
      const { data, status, message } = await APICall(
        GetEventReasonOptions,
        "POST",
        { Id: id }
      );

      if (status == 0 && data?.length > 0) {
        setEventReasonOption(data);
        return data;
      } else {
        setEventReasonOption([]);
        return [];
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  function onTokenChange(event, code) {
    try {
      candidateProfile(event.id);
      setTokenData((prev) => {
        return { ...prev, [code]: event };
      });
    } catch (error) {
    } finally {
    }
  }

  async function UpdateAssociateEventDetailsAsync(event) {
    try {
      showLoader();
      event?.stopPropagation();

      // if (!scanData?.Id) {
      //   return;
      // }

      const effect = effectiveDate;

      const p = moment(effectiveDate, "DD-MM-YYYY").toISOString();

      if (!eventHire?.value) {
        notify(1, "Event required.");
        return;
      }
      if (!eventHireReason?.value) {
        notify(1, "Event reason required.");
        return;
      }

      if (!moment(effectiveDate).isValid()) {
        notify(1, "Invalid effective date.");
        return;
      }

      let l = moment(effectiveDate).format("DD-MM-YYYY").toString();
      const { data, status, message } = await APICall(
        UpdateAssociateEventDetails,
        "POST",
        {
          Id: scanData?.CandidateId || 1104247,
          NewHireEventId: eventHire?.value || 0,
          NewHireEventReasonId: eventHireReason?.value || 0,
          NewHireEffectiveDate: effectiveDate,
          CreatedBy: userDetails?.Id || 0,
        }
      );

      if (status == 0) {
        setisProceedDisable(false);
        notify(
          0,
          `The data for Candidate ID ${scanData?.CandidateId} has been successfully submitted.`
        );
      } else {
        notify(1, "Something went wrong");
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  }

  const dashboardOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    onChangeRowsPerPage: (num) => {
      //   setLimit(num);
      //   setNxtPgInfo("");
      //   setPrevPgInfo("");
      //   setIsPrevOrNext("");
    },
    onSearchChange: (searchText) => {
      if (searchText !== null) {
        //setDashboardSearchText(searchText);
      } else {
        //setDashboardSearchText("");
      }
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const gridColumns = [
    {
      name: "id",
      label: "Sr.no",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "requirement",
      label: "Requirement",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "importances",
      label: "Importance",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "documentName",
      label: "Document Required",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "",
      label: "Document",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
        setCellProps: () => ({
          style: { textAlign: "justify" },
        }),
        // setCellHeaderProps: () => ({
        //   style: { textAlign: "center" },
        // }),
        customBodyRender: (value, tableMeta) => {
          let documentList =
            tableMeta.tableData[tableMeta.rowIndex].documentList; // Assuming 'documentList' is at index 15
          console.log(documentList, "document");
          if (documentList && documentList.length > 0) {
            return (
              <>
                {documentList.map((doc, index) => (
                  <React.Fragment key={index}>
                    <a
                      //href="#"
                      onClick={() =>
                        openBase64FileInNewTab(
                          doc.documentDataObject,
                          doc.documentName,
                          true
                        )
                      }
                      target="_blank"
                      // rel="noopener noreferrer"
                      style={{ color: "black", textDecoration: "none" }}
                      onMouseOver={(e) =>
                        (e.currentTarget.style.color = "blue")
                      }
                      onMouseOut={(e) =>
                        (e.currentTarget.style.color = "black")
                      }
                    >
                      {doc.documentName}
                    </a>
                    {index < documentList.length - 1 && ", "}
                  </React.Fragment>
                ))}
              </>
            );
          } else {
            return <span>No documents</span>;
          }
        },
      },
    },
  ];
  const handleApprovalSumnit = () =>{
    debugger;
    let errors: FormErrorsApproval = {};
    let valid = true;
    if(isEmpty(decisionApproval)){
      errors.ApprovalDecision ="Please Provide Decision";
      valid = false;
    }
    if(isEmpty(remarkApproval)){
      errors.Remarks = "Please Provide Remrk";
      valid = false;
    }
    if(valid){
    
    }
     else{
      setFormErrorsApproval(errors);
     }
    } 

  return (
    <>
      <div className="form-main">
        <div className="page-title w-100">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <h4>
                  {" "}
                  <i
                    className="fa-solid fa-home pr-1"
                    style={{
                      color: "white",
                      cursor: "pointer",
                      fontSize: "13px",
                      verticalAlign: "middle",
                    }}
                    onClick={() => {
                      navigate("/home");
                    }}
                  ></i>
                  Appointment Approval
                </h4>
              </div>
              <div className="offset-lg-6 "></div>
              <div
                className="col-lg-2 d-flex align-items-center justify-content-end"
                style={{ width: "200px" }}
              >
                <SelectForm
                  isClearable
                  async
                  options={(searchString, cb) => {
                    handleSearchForCandidateIdDropdown(
                      searchString,
                      "candidateId",
                      cb
                    );
                  }}
                  placeholder={"Select candidate Id"}
                  onChange={(val: any) => {
                    if (val !== null) {
                      // Check if the selected value is not null
                      onTokenChange(val, "candidate_Id");
                      setScanData1({ CandidateId: val.id });
                      // LoadUserData(val.id);
                    } else {
                      setTokenData((prev) => {
                        return { ...prev, ["candidate_Id"]: null };
                      });
                    }
                  }}
                  isMulti={false}
                  noIndicator={false}
                  noSeparator={false}
                  value={tokenData?.candidate_Id}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-2" id="blue-collar-pages">
        <div className="row">
          <div className="col-md-4 d-flex justify-content-center align-items-start">
            <div className="p-3 text-center">
              {startScan ? (
                <QR startScanOps={startScan} onScan={handleScanData} />
              ) : (
                <img
                  src={QRCode || defaultProfileImgPic}
                  className="card-img-top"
                  alt="Thumbnail Image"
                />
              )}

              <div className="SectionSubmit clearfix mt-2">
                {/* <button
                  onClick={() => {
                    //onResetData();
                  }}
                  disabled={false}
                  className="btn btn-secondary float-right ml-2"
                >
                  <i className="fa-solid fa-refresh"></i> Reset
                </button> */}

                <button
                  onClick={() => {
                    setModalForGunScanner(true);
                  }}
                  disabled={false}
                  className="btn btn-primary mr-2"
                >
                  {<i className="fa-solid fa-qrcode mr-2"></i>}
                  QR-Scan gun
                </button>
                <button
                  onClick={() => {
                    setStartScan(!startScan);
                  }}
                  disabled={false}
                  className="btn btn-primary ml-2 "
                >
                  <i className="fas fa-camera mr-2"></i> QR-Camera
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-3 d-flex justify-content-center align-items-start">
            <div className="p-3 text-center">
              <img
                src={candidateimage ? candidateimage : defaultProfileImgPic1}
                className="card-img-top"
                alt="Thumbnail Image"
              />
            </div>
          </div>
          <div className="col-md-5">
            <div className="row">
              <div className="col-md-6 mb-1">
                <label className="col-form-label">Token Id</label>
                <sup></sup>
                <InputForm
                  className="form-control"
                  placeholder="Token Id"
                  isDisabled={true}
                  onChange={""}
                  textArea={false}
                  value={scanData?.CandidateId || scanData1?.CandidateId || ""}
                  //  value={"Mallesh Fakkirappa Dollin"}
                  maxLength="255"
                />
              </div>
              <div className="col-md-6">
                <label className="col-form-label">Name</label>
                <sup></sup>
                <InputForm
                  className="form-control"
                  placeholder="Name"
                  isDisabled={true}
                  onChange={""}
                  textArea={false}
                  value={candidateDetails?.name || ""}
                  //  value={"Mallesh Fakkirappa Dollin"}
                  maxLength="255"
                />
              </div>

              <div className="col-md-6">
                <label className="col-form-label">Mobile Number</label>
                <sup></sup>
                <InputForm
                  className="form-control"
                  placeholder="Mobile Number"
                  isDisabled={true}
                  onChange={""}
                  textArea={false}
                  value={candidateDetails?.mobileNumber || ""}
                  //  value={"Mallesh Fakkirappa Dollin"}
                  maxLength="255"
                />
              </div>

              <div className="col-md-6">
                <label className="col-form-label">Category</label>
                <sup></sup>
                <InputForm
                  className="form-control"
                  placeholder="Category"
                  isDisabled={true}
                  onChange={""}
                  textArea={false}
                  value={Category}
                  //  value={"Mallesh Fakkirappa Dollin"}
                  maxLength="255"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12 mt-3">
          <AccordianCustom1
            header={"Organization Information"}
            submitFunc={""}
            //clearFunc={""}
            btnDisable={true}
          >
            <OrganizationInformation
              positionScanned={positionbutton}
              formData={formData}
              enableOUSection={false}
              onSubmitData={false}
              associateId={0}
              submitData={false}
              onGetPositionData={false}
            ></OrganizationInformation>
          </AccordianCustom1>

          {showWarnerTestSection && (
            <>
              <AccordianCustom1
                header={"Warner Test"}
                submitFunc={""}
                //clearFunc={""}
                btnDisable={true}
              >
                <div className="row">
                  <div className="col-lg-3 col-sm-3 col-xs-4">
                    <div className="mb-1">
                      <label className="col-form-label">
                        Pin Board <span style={{ color: "red" }}></span>
                      </label>
                      <InputForm
                        className="form-control"
                        placeholder={"Pin Board"}
                        isDisabled={true}
                        textArea={false}
                        value={warnerTestDetails?.pinBoard}
                        onChange={(val: any) => {
                          let value = val.target.value;
                          //onChange(value, "course_name");
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-3 col-xs-4">
                    <div className="mb-1">
                      <label className="col-form-label">
                        Form Board <span style={{ color: "red" }}></span>
                      </label>
                      <InputForm
                        className="form-control"
                        placeholder={"Form Board"}
                        isDisabled={true}
                        textArea={false}
                        value={warnerTestDetails?.formBoard}
                        onChange={(val: any) => {
                          let value = val.target.value;
                          //onChange(value, "course_name");
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-3 col-xs-4">
                    <div className="mb-1">
                      <label className="col-form-label">
                        Perception <span style={{ color: "red" }}></span>
                      </label>
                      <InputForm
                        className="form-control"
                        placeholder={"Perception"}
                        isDisabled={true}
                        textArea={false}
                        value={warnerTestDetails?.perception}
                        onChange={(val: any) => {
                          let value = val.target.value;
                          //onChange(value, "course_name");
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-3 col-xs-4">
                    <div className="mb-1">
                      <label className="col-form-label">
                        Total <span style={{ color: "red" }}></span>
                      </label>
                      <InputForm
                        className="form-control"
                        placeholder={"Total"}
                        isDisabled={true}
                        textArea={false}
                        value={warnerTestDetails?.Total}
                        onChange={(val: any) => {
                          let value = val.target.value;
                          //onChange(value, "course_name");
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-3 col-xs-4">
                    <div className="mb-1">
                      <label className="col-form-label">
                        Division <span style={{ color: "red" }}></span>
                      </label>
                      <InputForm
                        className="form-control"
                        placeholder={"Division"}
                        isDisabled={true}
                        textArea={false}
                        value={warnerTestDetails?.division}
                        onChange={(val: any) => {
                          let value = val.target.value;
                          //onChange(value, "course_name");
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-3 col-xs-4">
                    <div className="mb-1">
                      <label className="col-form-label">
                        Operation <span style={{ color: "red" }}></span>
                      </label>
                      <InputForm
                        className="form-control"
                        placeholder={"Operation"}
                        isDisabled={true}
                        textArea={false}
                        value={warnerTestDetails?.operation}
                        onChange={(val: any) => {
                          let value = val.target.value;
                          //onChange(value, "course_name");
                        }}
                      />
                    </div>
                  </div>
                </div>
              </AccordianCustom1>
            </>
          )}

          {!showWarnerTestSection && (
            <>
              <AccordianCustom1
                header={"Skill Test"}
                submitFunc={""}
                //clearFunc={""}
                btnDisable={true}
              >
                <div className="row">
                  <div className="col-lg-3 col-sm-3 col-xs-4">
                    <div className="mb-1">
                      <label className="col-form-label">
                        Overall Average Percentage{" "}
                        <span style={{ color: "red" }}></span>
                      </label>
                      <InputForm
                        className="form-control"
                        placeholder={"Percentage"}
                        isDisabled={true}
                        textArea={false}
                        value={avgPercentage}
                        onChange={(val: any) => {
                          let value = val.target.value;
                          //onChange(value, "course_name");
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-3 col-xs-4">
                    <div className="mb-1">
                      <label className="col-form-label">
                        Overall Proficiency{" "}
                        <span style={{ color: "red" }}></span>
                      </label>
                      <InputForm
                        className="form-control"
                        placeholder={"Proficiency"}
                        isDisabled={true}
                        textArea={false}
                        value={avgProficiency}
                        onChange={(val: any) => {
                          let value = val.target.value;
                          //onChange(value, "course_name");
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-3 col-xs-4">
                    <div className="mb-1">
                      <label className="col-form-label">
                        Overall Rating <span style={{ color: "red" }}></span>
                      </label>
                      <InputForm
                        className="form-control"
                        placeholder={"Rating"}
                        isDisabled={true}
                        textArea={false}
                        value={avgRating}
                        onChange={(val: any) => {
                          let value = val.target.value;
                          //onChange(value, "course_name");
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-3 col-xs-4">
                    <div className="mb-1">
                      <label className="col-form-label">
                        Overall Status <span style={{ color: "red" }}></span>
                      </label>
                      <InputForm
                        className="form-control"
                        placeholder={"Status"}
                        isDisabled={true}
                        textArea={false}
                        value={avgStatus}
                        onChange={(val: any) => {
                          let value = val.target.value;
                          //onChange(value, "course_name");
                        }}
                      />
                    </div>
                  </div>
                  {/* 
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Consider as Trainee? <span style={{ color: "red" }}></span>
                  </label>
                  <div className="row">
                    <div>
                      <input
                        className="ml-2 mr-1"
                        disabled={true}
                        id={`descision_1`}
                        type="radio"
                        name="descision"
                        // onChange={() => handleCandidateTypeChange(1)}
                        checked={isTrainee == true}
                      />
                      <label htmlFor={`descision_1`}>Yes</label>
                    </div>
                    <div>
                      <input
                        className="ml-2 mr-1"
                        disabled={true}
                        id={`descision_2`}
                        type="radio"
                        name="descision"
                        // onChange={() => handleCandidateTypeChange(0)}
                        checked={isTrainee == false}
                      />
                      <label htmlFor={`descision_2`}>No</label>
                    </div>
                  </div>
                </div>
              </div> */}
                </div>
              </AccordianCustom1>
            </>
          )}

          <AccordianCustom1
            header={"Event"}
            submitFunc={UpdateAssociateEventDetailsAsync}
            //clearFunc={""}
            btnDisable={isDisableAccordian}
          >
            <div className="row">
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Event <span style={{ color: "red" }}>*</span>
                  </label>
                  <SelectForm
                    isClearable
                    options={eventOptions}
                    placeholder={"Event"}
                    onChange={async (val: any) => {
                      setEventHire(val);
                      await GetEventReasonOptionsAsync(val?.value || 0);
                    }}
                    isMulti={false}
                    isDisabled={true}
                    noIndicator={false}
                    noSeparator={false}
                    value={eventHire}
                  />
                  {formErrors.Event && (
                    <p style={{ color: "red" }}>{formErrors.Event}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Event Reason<span style={{ color: "red" }}>*</span>
                  </label>
                  <SelectForm
                    isClearable
                    options={eventReasonOptions}
                    placeholder={"Event Reason"}
                    onChange={(val: any) => {
                      setEventHireReason(val);
                    }}
                    isMulti={false}
                    isDisabled={true}
                    noIndicator={false}
                    noSeparator={false}
                    value={eventHireReason}
                  />
                  {formErrors.EventReason && (
                    <p style={{ color: "red" }}>{formErrors.EventReason}</p>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Effective Date<span style={{ color: "red" }}>*</span>
                  </label>
                  <DateForm
                    isDisabled={true}
                    value={effectiveDate}
                    onChange={(date) => {
                      setEffectiveDate(date);
                    }}
                    minDate={yest1}
                  />
                  {formErrors.EffectiveDate && (
                    <p style={{ color: "red" }}>{formErrors.EffectiveDate}</p>
                  )}
                </div>
              </div>
            </div>
          </AccordianCustom1>

          {showWageSection && (
            <>
              <AccordianCustom1
                header={"Wage Finalisation"}
                submitFunc={() => {
                  submitWageFinalisation(false);
                }}
                //clearFunc={""}
                btnDisable={false}
                hideBtn={true} // Adding a prop to hide the button
              >
                <div className="row">
                  <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                    <label className="col-form-label">Decision</label>
                    <sup></sup>
                    <SelectForm
                      isClearable
                      options={DecisionOption.filter((x) => x.value != 5)}
                      placeholder={"Select Decision"}
                      isDisabled={true}
                      onChange={(e) => {
                        setDecision(e);
                      }}
                      // onChange={(val: any) => {
                      //   onChange(val, "decision");
                      // }}
                      isMulti={false}
                      noIndicator={false}
                      noSeparator={false}
                      value={decision}
                    />
                  </div>
                  <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                    <label className="col-form-label">
                      Reason of Rejection
                    </label>
                    <sup></sup>
                    <SelectForm
                      isClearable
                      options={RejectionReasonOption}
                      placeholder={"Reason of Rejection"}
                      isDisabled={true}
                      onChange={(e) => {
                        setRejection(e);
                      }}
                      isMulti={false}
                      noIndicator={false}
                      noSeparator={false}
                      value={Rejection}
                    />
                    {formErrors.Rejection && (
                      <p style={{ color: "red" }}>{formErrors.Rejection}</p>
                    )}
                  </div>

                  {showWCLSection && decision?.value == "4" && (
                    <>
                      <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                        <label className="col-form-label">
                        Min Annual Wage
                        </label>

                        <InputForm
                          className="form-control"
                          placeholder="Min Annual Wage"
                          isDisabled={true}
                          onChange={(e) => {
                            //setRemark(e.target.value);\
                            setStandardWage(e.target.value);
                          }}
                          textArea={false}
                          value={WCLDetails?.min}
                          maxLength="255"
                        />
                      </div>
                      <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                        <label className="col-form-label">
                        Median Annual Wage
                        </label>

                        <InputForm
                          className="form-control"
                          placeholder="Median Annual Wage"
                          isDisabled={true}
                          onChange={(e) => {
                            //setRemark(e.target.value);\
                            setStandardWage(e.target.value);
                          }}
                          textArea={false}
                          value={WCLDetails?.med}
                          maxLength="255"
                        />
                      </div>
                      <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                        <label className="col-form-label">
                        Max Annual Wage
                        </label>

                        <InputForm
                          className="form-control"
                          placeholder="Max Annual Wage"
                          isDisabled={true}
                          onChange={(e) => {
                            //setRemark(e.target.value);\
                            setStandardWage(e.target.value);
                          }}
                          textArea={false}
                          value={WCLDetails?.max}
                          maxLength="255"
                        />
                      </div>
                      <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                        <label className="col-form-label">
                          Offered Annual Wage
                        </label>

                        <InputForm
                          className="form-control"
                          placeholder="Offered Annual Wage"
                          isDisabled={true}
                          onChange={(e) => {
                            setWCLOfferedAnnualWage(e.target.value);
                          }}
                          textArea={false}
                          value={WCLOfferedAnnualWage}
                          maxLength="255"
                        />
                      </div>
                      <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                        <label className="col-form-label">
                        Monthly Wage
                        </label>

                        <InputForm
                          className="form-control"
                          placeholder="Monthly Wage"
                          isDisabled={true}
                          onChange={(e) => {
                            //setRemark(e.target.value);\
                          }}
                          textArea={false}
                          value={WCLOfferedMonthlyWage}
                          maxLength="255"
                        />
                      </div>
                    </>
                  )}

                  {!showWCLSection && decision?.value == "4" && (
                    <>
                      <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                        <label className="col-form-label">
                          Standard Gross Wage per month (Rs)
                        </label>

                        <InputForm
                          className="form-control"
                          placeholder="Standard Gross Wage"
                          isDisabled={true}
                          onChange={(e) => {
                            //setRemark(e.target.value);\
                            setStandardWage(e.target.value);
                          }}
                          textArea={false}
                          value={StandardWage}
                          maxLength="255"
                        />
                      </div>

                      <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                        <label className="col-form-label">
                          Offered Gross Wage per month (Rs.)
                        </label>

                        <InputForm
                          className="form-control"
                          placeholder="Enter Offered Gross Wage"
                          isDisabled={true}
                          onChange={(e) => {
                            setOfferedMonthlyWage(e.target.value);
                          }}
                          textArea={false}
                          value={OfferedMonthlyWage}
                          maxLength="255"
                        />
                      </div>

                      <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                        <label className="col-form-label">
                          Offered Gross wage - Annual (Rs)
                        </label>

                        <InputForm
                          className="form-control"
                          placeholder="Offered Annual wage"
                          isDisabled={true}
                          onChange={(e) => {
                            //setRemark(e.target.value);
                          }}
                          textArea={false}
                          value={OfferedAnnualWage}
                          maxLength="255"
                        />
                      </div>

                      <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                        <label className="col-form-label">
                          Percentage Difference (+/-){" "}
                        </label>

                        <InputForm
                          className="form-control"
                          placeholder="Hike"
                          isDisabled={true}
                          onChange={(e) => {
                            //setRemark(e.target.value);
                          }}
                          textArea={false}
                          value={hike}
                          maxLength="255"
                        />
                      </div>

                      <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                        <label className="col-form-label">
                          Increment Amount{" "}
                        </label>

                        <InputForm
                          className="form-control"
                          placeholder="Increment Amount"
                          isDisabled={true}
                          onChange={(e) => {}}
                          textArea={false}
                          value={incrementAmount}
                          maxLength="255"
                        />
                      </div>

                      {/* <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                    <label className="col-form-label">
                      Justification (If different from Standard)
                    </label>

                    <InputForm
                      className="form-control"
                      placeholder="Enter Justification"
                      isDisabled={false}
                      onChange={(e) => {
                        setJustification(e.target.value);
                      }}
                      textArea={true}
                      value={Justification}
                      maxLength="255"
                    />
                  </div> */}
                    </>
                  )}
                </div>
              </AccordianCustom1>
            </>
          )}

          {/* <AccordianCustom1
            header={"Offer Letter"}
            submitFunc={""}
            //clearFunc={""}
            btnDisable={false}
            hideBtn={true}
          >
            <div className={"col-lg-4 col-sm-4 col-xs-4"}>
              <a
                className="docLink"
                href="#"
                onClick={handleDocumentClick}
                style={{ color: "blue" }}
              >
                Download Candidate name Offer Letter
              </a>
            </div>
          </AccordianCustom1> */}
          <AccordianCustom1
            header={"Document Checklist"}
            submitFunc={""}
            //clearFunc={""}
            btnDisable={false}
            hideBtn={true}
          >
            <div className="pt-3">
              <DynamicGrid
                data={dashboard}
                columns={gridColumns}
                options={dashboardOptions}
              />
            </div>
            <div className="SectionSubmit clearfix">
              <button
                onClick={() => {
                  submitWageFinalisation(false);
                }}
                disabled={isProceedDisable}
                className="btn btn-filter-submit float-right mt-2"
              >
                <i className="fa-solid fa-check mr-2"></i>{" "}
                {isClmFlow ? "Proceed To CLM" : "Proceed to EP"}
              </button>
              {!fielddisable && (
                <button
                  onClick={() => {
                    submitWageFinalisation(false);
                  }}
                  disabled={false}
                  className="btn btn-filter-submit float-right mt-2 mr-2"
                >
                  {" "}
                  Submit
                </button>
              )}
              <button
                onClick={() => {
                  submitWageFinalisation(true);
                }}
                disabled={isBenchingDisable}
                className="btn btn-filter-submit float-right mt-2 mr-2"
              >
                {" "}
                Bench Candidate
              </button>
            </div>
          </AccordianCustom1>

          <AccordianCustom1
            header={"Approver"}
            submitFunc={""}
            //clearFunc={""}
            btnDisable={false}
            hideBtn={true}
          >
            <div className="pt-3">
                
            <div className="row">
            <div className={"col-lg-3 col-sm-3 col-xs-4"}>
              <div className="mb-1">
                <label className="col-form-label">
                  Decision
                  <sup>*</sup>
                </label>
                <SelectForm
                  isClearable
                  isSearchable
                  isDisabled={false}
                  value={decisionApproval}
                  placeholder={"Decision"}
                  options={decisionOptionApproval}
                  onChange={(event) => setDecisionApproval(event)}
                  isMulti={false}
                  noIndicator={false}
                  noSeparator={false}
                />
              </div>
              {formErrorsApproval.ApprovalDecision && (
                <p style={{ color: "red" }}>{formErrorsApproval.ApprovalDecision}</p>
              )}
            </div>
            <div className={"col-lg-6 col-sm-6 col-xs-12"}>
              <div className="mb-1">
                <label className="col-form-label">
                  Remarks
                  <sup>*</sup>
                  </label>
                <InputForm
                  className="form-control"
                  placeholder={"Remarks"}
                  isDisabled={false}
                  textArea={true}
                  value={remarkApproval}
                  onChange={(event) => setRemarkApproval(event.target.value)}
                  
                  rows={4}
                />
              </div>
              {formErrorsApproval.Remarks && (
                <p style={{ color: "red" }}>{formErrorsApproval.Remarks}</p>
              )}
            </div>
            <div className="col-lg-3 col-sm-12 col-xs-12 d-flex justify-content-end align-items-end">
            <button
                  onClick={(event) => {
                    submitApprovalDecision();
                  }}
                  disabled={false}
                  className="btn btn-filter-submit float-right mt-2 mr-2"
                >
                  {" "}
                  Submit
            </button>   
                 </div>
          </div>
          <div className="row mt-3">
            <div className={"col-lg-12 col-md-12 col-sm-12 mobile-view top-m"}>
              <div className="mb-1">
              <div>

        <Table striped bordered hover>
          {newPreviousConversation?.map((eachApp, index) => (
            <>
              {index == 0 && (
                <thead>
                  <tr>
                    <th className="text-dark col-lg-2 col-md-2 col-sm-2 mobile-view top-m">Date-Time</th>
                    <th className="text-dark col-lg-2 col-md-2 col-sm-2 mobile-view top-m">User Name</th>
                    <th className="text-dark col-lg-2 col-md-2 col-sm-2 mobile-view top-m">Role</th>
                    <th className="text-dark col-lg-2 col-md-2 col-sm-2 mobile-view top-m">Action</th>
                    <th className="text-dark col-lg-6 col-md-6 col-sm-6 mobile-view top-m">Remarks</th>
                  </tr>
                </thead>
              )}
              <tbody>
                <tr>
                <td>{eachApp?.dateTime}</td>
                  <td>{eachApp?.name}</td>
                  <td>{eachApp?.role}</td>
                  <td>{eachApp?.action}</td>
                  <td>{eachApp?.remarks}</td>
                </tr>
              </tbody>
            </>
          ))}
        </Table>
    </div>
                
              </div>
            </div>
          </div>
            </div>
           
          </AccordianCustom1>
        </div>
      </div>

      <Modal
        show={isCandidateBenched}
        onHide={() => {
          setIsCandidateBenched(false);
        }}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Candidate Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>This Candidate is Already Benched!</h4>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        show={modalForGunScanner}
        onHide={() => {
          setModalForGunScanner(false);
        }}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Scan data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>{`Scan Data using scanner gun.`}</h4>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        show={showcurrentstage}
        onHide={() => {
          setShowCurrentStage(false);
        }}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Current Stage</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>{`You currently belong to ${currentStageName}`}</h4>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        show={showModal1}
        onHide={handleModalClose1}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Invalid QR</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>
            The Scan QR entry is more than 48 hours old and cannot be accepted.
            New candidate QR will need to be generated
          </h4>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default AppointmentApproval;
