import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import { APICall } from "../../Helpers/APICalls";
import { LoaderContext } from "../../Helpers/Context/Context";
import SelectForm from "../../Components/SelectForm/SelectForm";
import "./FormStructure.css";
import { getpreviewTabsSectionsList } from "../../Helpers/APIEndPoints/EndPoints";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { isEmpty } from "../../utils/isEmpty";
import notify from "../../Helpers/ToastNotification";
function GenerateModal({
  generatemodal,
  setGeneratemodal,
  setPrintValueSubmit,
  setPrintConfirmation,
  setPrintConfirmationTab,
}) {
  let { userDetails, proxyUserDetails, currentRoleId }: any = useUserContext();
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [tabsValue, setTabsValue] = useState(null);
  const [sectionValue, setSectionValue] = useState(null);
  const [formError, setFormError] = useState<any>({});
  const [optionData, setOptionData] = useState(null);
  const [optionSection, setOptionSection] = useState(null);
  const isDisabledButton =
    isEmpty(tabsValue) && isEmpty(sectionValue) ? true : false;
  const handleModalClose = () => {
    function customizedAsync(){
    setTabsValue(null);
    setSectionValue(null);
    setFormError({});
    setOptionData(null);
    setOptionSection(null);
    setGeneratemodal(false);}
    showLoader();
      const timer = setTimeout(() => {
        hideLoader();
        customizedAsync();
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
  };

  const handleModalSubmit = () => {
    function customizedAsync(){
    let errorObj = {};
    let isError = false;
    setFormError({});
    const fieldsToCheck = [
      { value: tabsValue, errorKey: "error_tabsValue" },
      { value: sectionValue, errorKey: "error_sectionValue" },
    ];
    fieldsToCheck.forEach(({ value, errorKey }) => {
      if (!value || value.length === 0) {
        errorObj[errorKey] = "Required";
        isError = true;
      } else if (value.length > 20) {
        errorObj[errorKey] = "Maximum 20 Sub-Sections Allowed.";
        isError = true;
      } else {
        delete errorObj[errorKey];
      }
    });
    const isEmpty = Object.values(errorObj).every(
      (s) => s === null || s === "" || s === undefined
    );
    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      setFormError((err) => ({
        ...err,
        ...errorObj,
      }));
      if (isError) {
        let errorMessage = [];
        if (errorObj["error_tabsValue"]) errorMessage.push("Tabs");
        if (errorObj["error_sectionValue"]) errorMessage.push("Section");
        if (errorMessage.length > 0) {
          notify(1, `Please fill ${errorMessage.join(",")} Properly.`);
        }
      }
    } else {
      setPrintValueSubmit({
        tabsValue: tabsValue,
        sectionValue: sectionValue,
      });
      handleModalClose();
      setPrintConfirmation(true);
      setPrintConfirmationTab("sectionPrint");
    }
  }
  showLoader();
      const timer = setTimeout(() => {
        hideLoader();
        customizedAsync();
      }, 100);

      return () => {
        clearTimeout(timer);
      };
  };
  const handleChanges = (event, fieldname) => {
    if (fieldname === "Tabs") {
      setTabsValue(event);
      const sectionOption = (tabvalue) => {
        const filteredOptions = [];
        tabvalue.forEach((tab) => {
          const optionsForTab = optionData?.sections?.filter(
            (option) => option.tabId === tab.value
          );
          if (optionsForTab.length > 0) {
            filteredOptions.push(...optionsForTab);
          }
        });
        setOptionSection(filteredOptions);
      };
      sectionOption(event);
    }
    if (fieldname === "Section") {
      setSectionValue(event);
    }
  };

  const getTabsOptionValue = async () => {
    showLoader();
    try {
      const response = await APICall(getpreviewTabsSectionsList, "POST", {
        RoleId: currentRoleId?.value ? currentRoleId.value : null,
        UserId: proxyUserDetails?.value
          ? proxyUserDetails.value
          : userDetails?.UserId,
      });
      if (response?.status === 0) {
        if (response?.data) {
          setOptionData(response?.data);
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error in Employee Searchable Dropdown:", error);
      return null;
    } finally {
      hideLoader();
    }
  };
  useEffect(() => {
    if (generatemodal) {
      getTabsOptionValue();
    }
  }, [generatemodal]);
  return (
    <Modal
      show={generatemodal}
      onHide={handleModalClose}
      backdrop="static"
      keyboard={false}
      size="lg"
      centered
    >
      <div
        className="bg-secondary text-white"
        style={{
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        <div className="col-lg-12"> Do you want to Generate Employee Profile</div>
      </div>
      <Modal.Body>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="mb-1">
              <label className="col-form-label">Section Tab.</label>
              <sup>*</sup>
              <div className="">
                <SelectForm
                  isClearable
                  options={optionData?.tabs}
                  placeholder={"Tabs"}
                  onChange={(event) => handleChanges(event, "Tabs")}
                  isMulti={true}
                  noIndicator={false}
                  noSeparator={false}
                  value={tabsValue}
                />
                <span style={{ color: "red" }}>
                  {formError["error_tabsValue"]}
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="mb-1">
              <label className="col-form-label">Sub-Section Tab.</label>
              <sup>*</sup>
              <div className="">
                <SelectForm
                  isClearable
                  options={optionSection}
                  placeholder={"Section"}
                  onChange={(event) => handleChanges(event, "Section")}
                  isMulti={true}
                  noIndicator={false}
                  noSeparator={false}
                  value={sectionValue}
                  isDisabled={isEmpty(optionSection)}
                />
                <span style={{ color: "red" }}>
                  {formError["error_sectionValue"]}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary ml-3"
          onClick={handleModalClose}
        >
          Close
        </button>
        <button
          type="button"
          disabled={isDisabledButton}
          className="btn btn-primary ml-3"
          onClick={handleModalSubmit}
        >
          Submit
        </button>
      </Modal.Footer>
    </Modal>
  );
}
export default GenerateModal;
