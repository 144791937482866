import React, { useState, useEffect, useContext } from "react";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { useNavigate } from "react-router-dom";
import "./UserDashboard.css";
import { APICall } from "../../Helpers/APICalls";
import {
  UserRolePermissionDashboard,
  getUserPermissionExcelData,
  getUserPermissionExcelDetailsForAllUsers,
  deleteUserRoleMapping,
} from "../../Helpers/APIEndPoints/EndPoints";
import InputForm from "../../Components/InputForm/InputForm";
import { Tooltip } from "@mui/material";
import { LoaderContext } from "../../Helpers/Context/Context";
import ExcelJS from "exceljs";
import { Button, Modal } from "react-bootstrap";
import notify from "../../Helpers/ToastNotification";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { useDebounce } from "../../utils/useDebounce";
const UserRoleDashboard = () => {
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const debouncedValue = useDebounce(searchText);
  const [PageSize, setPageSize] = useState(10);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  const [sortDirection, setSortDirection] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [show, setShow] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const { userDetails, currentRoleId }: any = useUserContext();
  useEffect(() => {
    getGridStructureTwo();
  }, [page, debouncedValue, sortDirection, sortColumn]);

  // Export Excel with search
  const toTitleCase = (str) => {
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  };

  const exportToExcelUserPermission = async () => {
    const response = await APICall(getUserPermissionExcelData, "POST", {
      SearchText: searchText,
    });

    const responseData = response.data;

    if (!responseData || responseData.length === 0) {
      console.error("No data to export.");
      return;
    }

    const columnsToInclude = [
      "userName",
      "roleName",
      "business",
      "sbu",
      "legalEntity",
      "division",
      "country",
      "physicalLocation",
      "customBusiness",
      "function",
      "department",
      "section",
      "employeeClass",
      "jobBand",
      "jobLabel",
      "standardDesignation",
      "payrollLocation",
      "isActive",
    ];

    const headers = Object.keys(responseData[0])?.filter((header) =>
      columnsToInclude.includes(header)
    );
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Report");

    //Space in width
    const columnWidths = headers.map((header) => {
      const maxContentLength = Math.max(
        ...responseData.map((row) =>
          row[header] ? String(row[header]).length : 0
        )
      );
      return Math.min(50, Math.max(10, maxContentLength * 1.5));
    });

    // Set column widths
    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width;
    });

    const formattedHeaders = headers.map((header) =>
      toTitleCase(header.replace(/([a-z])([A-Z])/g, "$1 $2"))
    );
    const indexOfIsActive = formattedHeaders.indexOf("Is Active");
    if (indexOfIsActive !== -1) {
      formattedHeaders[indexOfIsActive] = "Status";
    }
    worksheet.addRow(formattedHeaders);
    worksheet.getRow(1).font = { bold: true };
    responseData.font = { bold: true };
    responseData.forEach((row) => {
      const dataRow = headers.map((header) => {
        if (header === "isActive") {
          return row[header] ? "Yes" : "No";
        }
        return row[header] || "";
      });

      worksheet.addRow(dataRow);
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "Report User Permission.xlsx";
      a.click();

      URL.revokeObjectURL(url);
    });
  };

  const exportToExcelUserAccess = async () => {
    const response = await APICall(
      getUserPermissionExcelDetailsForAllUsers,
      "POST",
      {
        SearchText: searchText,
      }
    );

    const responseData = response.data;

    if (!responseData || responseData.length === 0) {
      console.error("No data to export.");
      return;
    }

    const columnsToInclude = [
      "userName",
      "roleName",
      "permissionName",
      "interfaceName",
      "tabName",
      "sectionName",
      "moduleName",
      "isActive",
    ];

    const headers = Object.keys(responseData[0])?.filter((header) =>
      columnsToInclude.includes(header)
    );
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Report");
    //Space in width
    const columnWidths = headers.map((header) => {
      const maxContentLength = Math.max(
        ...responseData.map((row) =>
          row[header] ? String(row[header]).length : 0
        )
      );
      return Math.min(50, Math.max(10, maxContentLength * 1.5));
    });

    // Set column widths
    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width;
    });

    const formattedHeaders = headers.map((header) =>
      toTitleCase(header.replace(/([a-z])([A-Z])/g, "$1 $2"))
    );

    // const indexOfIsActive = formattedHeaders.indexOf("Is Active");
    // if (indexOfIsActive !== -1) {
    //   formattedHeaders[indexOfIsActive] = "Status";
    // }

    worksheet.addRow(formattedHeaders);
    worksheet.getRow(1).font = { bold: true };
    responseData.font = { bold: true };
    responseData.forEach((row) => {
      const dataRow = headers.map((header) => {
        if (header === "isActive") {
          return row[header] ? "Yes" : "No";
        }
        return row[header] || "";
      });

      worksheet.addRow(dataRow);
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "Report User Access.xlsx";
      a.click();

      URL.revokeObjectURL(url);
    });
  };

  const getGridStructureTwo = async () => {
    showLoader();
    try {
      const GetDashboardData = await APICall(
        UserRolePermissionDashboard,
        "POST",
        {
          PageNumber: page,
          PageSize: PageSize,
          SearchText: searchText,
          SortColumn: sortColumn,
          SortOrder: sortDirection,
        }
      );

      if (GetDashboardData.data && GetDashboardData.data.length > 0) {
        setRowData(GetDashboardData.data);
        setCount(GetDashboardData.data[0].count);
      } else {
        setRowData([]);
      }
    } catch (error) {
      console.error("Error fetching grid data:", error);
      hideLoader();
    }
    hideLoader();
  };

  const edit = (value, tableMeta) => {
    debugger;
    const UId = tableMeta.rowData[0];
    const UIdLabel = tableMeta.rowData[1];
    navigate("/UserRole", { state: { UId, UIdLabel } });
  };

  //Delete code start
  const handleShow = (itemId) => {
    setShow(true);
    setSelectedItemId(itemId);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedItemId(null);
  };

  const handleDelete = async () => {
    try {
      const response = await APICall(deleteUserRoleMapping, "POST", {
        UserRoleMappingId: selectedItemId,
        CreatedBy: userDetails.Id,
      });

      if (response.status === 0) {
        notify(0, `${response.message}.`);
        console.log("Item deleted successfully");
      } else {
        notify(1, "Error deleting record");
      }
      handleClose();
    } catch (error) {
      console.error("Error deleting item:", error);
    }
    handleClose();
  };
  //Delete code end

  const onSearchFilter = (event, type) => {
    setSearchText(event.target.value);
  };

  const columns = [
    {
      name: "id",
      label: "Id",
      options: { display: false },
    },
    {
      name: "userName",
      label: "User Name",
      options: { display: true, sort: true },
    },
    {
      name: "userId",
      label: "User Id",
      options: { display: false, sort: false },
    },
    {
      name: "roleName",
      label: "Role Name",
      options: { display: true, sort: true },
    },
    {
      name: "roleId",
      label: "Role Id",
      options: { display: false },
    },
    {
      name: "isActive",
      label: "Active Status",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          if (value) {
            return <i className="fas fa-check"></i>;
          } else {
            return <i className="fas fa-times"></i>;
          }
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <div className="editDelete">
                <Tooltip title="Edit">
                  <i
                    style={{ marginRight: "0px", cursor: "pointer" }}
                    onClick={() => {
                      edit(value, tableMeta);
                    }}
                    className="fas fa-edit"
                  ></i>
                </Tooltip>

                <Tooltip title="Delete">
                  <i
                    style={{ marginRight: "0px", cursor: "pointer" }}
                    onClick={() => {
                      handleShow(tableMeta.rowData[0]);
                    }}
                    className="fas fa-trash"
                  ></i>
                </Tooltip>
              </div>
            </>
          );
        },
      },
    },
  ];

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: PageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",
    onChangePage: async (page) => {
      setPage(page * PageSize);
    },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      setPage(page);
      if (sortDirection === "asc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
    },
  };

  return (
    <>
      <div className="container-fluid">
        {/* <Accordion
          className="mb-3"
          expanded={panal1}
          onChange={() => setPanal1((prev) => !prev)}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            style={{ background: "#3C5464" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}
          >
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                {" "}
                <Typography>User And OU Mapping</Typography>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails> */}
        <div className="col-lg-12 col-md-10 col-sm-12 "></div>
        <div className="mb-3 pt-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <button
              onClick={() => navigate("/home")}
              className="btn btn-primary">
              <i className="fa-solid fa-arrow-left mr-1"></i> Back
            </button>
            <div className="d-flex align-items-center justify-content-end">
              <button
                onClick={() => {
                  navigate("/UserRole", { state: { UId: 0 } });
                }}
                className="btn btn-primary mr-2"
              >
                <i className="fa fa-plus mr-2"></i>
                Add New User
              </button>
              <button
                type="button"
                onClick={exportToExcelUserPermission}
                className="btn btn-primary mr-2"
              >
                <i className="fa fa-download mr-2"></i>
                Export To Excel User Permission
              </button>
              {/* This is for all record */}
              <button
                type="button"
                onClick={exportToExcelUserAccess}
                className="btn btn-primary mr-2"
              >
                <i className="fa fa-download mr-2"></i>
                Export To Excel User Access
              </button>
            </div>
          </div>
          <div className="grid-wrapper grid_mob">
            <div className="row mx-auto filtBox">
              <>
                <div className="col-lg-3 col-md-6 col-sm-12  ">
                  <div className="form-group filtInp d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn">
                    <InputForm
                      value={searchText}
                      placeholder={"Search User Title"}
                      isDisabled={false}
                      textArea={false}
                      onChange={onSearchFilter}
                    />
                    {/* <Tooltip title="Export To Excel">
                      <button
                        type="button"
                        onClick={exportToExcel}
                        className="btn btn-primary mr-2"
                      >
                        <i className="fa fa-download mr-2"></i>
                      </button>
                    </Tooltip> */}
                  </div>
                </div>
              </>
            </div>
          </div>
          <div className="mb-3 pt-3">
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <div
                className="bg-secondary text-white"
                style={{
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                <div className="col-lg-12">Confirm Delete</div>
              </div>
              <Modal.Body>Are you sure you want to delete?</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="danger" onClick={handleDelete}>
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
            <DynamicGrid data={rowData} columns={columns} options={options} />
          </div>
        </div>
        {/* </AccordionDetails>
        </Accordion> */}
      </div>
    </>
  );
};

export default UserRoleDashboard;
