export const fromData = {
  moduleName: "Employee",
  moduleId: 1,
  tabName: "Personal",
  tabId: 1,
  sections: [
    {
      name: "Basic",
      sectionId: 1,
      isAttributeSet: false,
      attributes: [
        {
          name: "Title",
          code: "T",
          sequence: 1,
          dataType: "dropdown",
          dataSoruce: "title",
          options: [],
          childCascade: ["G"],
          validation: { isMandatory: true },
        },
        {
          name: "Firstname",
          code: "FN",
          sequence: 2,
          dataType: "text",
          validation: { min: 3, max: 10, isMandatory: true },
        },
        {
          name: "Middlename",
          code: "MN",
          sequence: 3,
          dataType: "text",
          validation: { min: 3, max: 10, isMandatory: true },
        },
        {
          name: "Lastname",
          code: "LN",
          sequence: 4,
          dataType: "text",
          validation: { min: 3, max: 10, isMandatory: true },
        },
        {
          name: "Display Name",
          code: "DN",
          sequence: 5,
          dataType: "text",
          validation: { min: 3, max: 10, isMandatory: true },
        },
        {
          name: "Initials",
          code: "I",
          sequence: 7,
          dataType: "text",
          validation: { min: 3, max: 10, isMandatory: true },
        },
        {
          name: "Gender",
          code: "G",
          sequence: 8,
          dataType: "dropdown",
          dataSoruce: "gender",
          options: [],
          childCascade: [],
          validation: { isMandatory: true },
        },
        {
          name: "Birthname",
          code: "BN",
          sequence: 9,
          dataType: "text",
          validation: { min: 3, max: 10, isMandatory: false },
        },
        {
          name: "Maiden Name",
          code: "MAN",
          sequence: 10,
          dataType: "text",
          validation: { min: 3, max: 10, isMandatory: false },
        },
        { attributeSet: null },
      ],
    },
    {
      name: "Family",
      sectionId: 2,
      isAttributeSet: false,
      attributes: [
        {
          name: "Reationship",
          code: "R",
          sequence: 1,
          dataType: "dropdown",
          dataSoruce: "realtion",
          options: [],
          childCascade: [],
          validation: { isMandatory: true },
        },
        {
          name: "Title",
          code: "FT",
          sequence: 2,
          dataType: "text",
          validation: { min: 3, max: 10, isMandatory: true },
        },
        {
          name: "Name",
          code: "MN",
          sequence: 3,
          dataType: "text",
          validation: { min: 3, max: 10, isMandatory: true },
        },
        {
          name: "Age(Yr)",
          code: "LN",
          sequence: 4,
          dataType: "text",
          validation: { min: 3, max: 10, isMandatory: true },
        },
        {
          name: "Relationship Since",
          code: "DN",
          sequence: 5,
          dataType: "text",
          validation: { min: 3, max: 10, isMandatory: false },
        },
        {
          name: "Is Dependent",
          code: "I",
          sequence: 7,
          dataType: "checkbox",
          validation: { isMandatory: true },
        },
        {
          name: "Nominee Percentage",
          code: "G",
          sequence: 8,
          dataType: "text",
          validation: { min: 3, max: 10, isMandatory: false },
        },
        {
          name: "Welspun Company",
          code: "BN",
          sequence: 9,
          dataType: "dropdown",
          dataSoruce: "company",
          options: [],
          childCascade: [],
          validation: { isMandatory: true },
        },
        {
          name: "Welspun Emp Id",
          code: "MN",
          sequence: 10,
          dataType: "text",
          validation: { min: 3, max: 10, isMandatory: true },
        },
        {
          attributeSet: [
            {
              name: "Name",
              code: "name",
              sequence: 1,
              dataType: "text",
              validation: { min: 3, max: 10, isMandatory: true },
            },
            {
              name: "Number",
              code: "number",
              sequence: 2,
              dataType: "text",
              validation: { min: 3, max: 10, isMandatory: true },
            },
          ],
        },
      ],
    },
  ],
};

export const apiResp = {
  mn: "EmployeeCentral",
  in: "FORM",
  t: [
    {
      tn: "Personal",
      tdn: "Personal",
      tsn: "1",
      S: [
        {
          SN: "Personal",
          SDN: "Personal",
          SId: "1",
          SNO: "1",
          Attributes: [
            {
              AN: "Title",
              AID: "1",
              AC: "SA_Title",
              ASN: "1",
              AT: "Attribute",
              DT: "Dropdown",
              CC: ["SA_SubTitle"],
              ADSMT: "Title",
              V: {
                ISM: true,
                MinC: "10",
                MaxC: "20",
                Ptn: "regexvalue",
              },
            },
            {
              AN: "SubTitle",
              AID: "1",
              AC: "SA_SubTitle",
              ASN: "1",
              AT: "Attribute",
              DT: "Dropdown",
              CC: [],
              ADSMT: "SubTitle",
              V: {
                ISM: true,
                MinC: "10",
                MaxC: "20",
                Ptn: "regexvalue",
              },
            },
            {
              AN: "Name",
              AID: "2",
              AC: "SA_Name",
              AT: "Attribute",
              DT: "Text",
              ASN: "2",
              V: {
                ISM: true,
                MinC: "10",
                MaxC: "20",
                Ptn: "regexvalue",
              },
            },
            {
              AN: "From Date",
              AID: "1",
              AC: "SA_FromDate",
              ASN: "1",
              AT: "Attribute",
              DT: "Date",
              CC: [],
              ADSMT: "FromDate",
              V: {
                ISM: true,
                MinC: "10",
                MaxC: "20",
                Ptn: "regexvalue",
              },
            },
            {
              AN: "isActive",
              AID: "1",
              AC: "SA_isActive",
              ASN: "1",
              AT: "Attribute",
              DT: "Checkbox",
              CC: [],
              ADSMT: "isActive",
              V: {
                ISM: true,
                MinC: "10",
                MaxC: "20",
                Ptn: "regexvalue",
              },
            },
            {
              AN: "Relationship",
              AID: "3",
              AC: "SA_Relationship",
              ASN: "3",
              AT: "AttributeSet",
              DT: "AttributeSet",
              Attributes: [
                {
                  AN: "Relation Name",
                  AID: "5",
                  AC: "SA_RelationName",
                  ASN: "1",
                  AT: "Attribute",
                  DT: "Text",
                  V: {
                    ISM: true,
                    MinC: "10",
                    MaxC: "20",
                    Ptn: "regexvalue",
                  },
                },
                {
                  AN: "RelationType",
                  AID: "4",
                  AC: "SA_RelationType",
                  ASN: "2",
                  AT: "Attribute",
                  DT: "Dropdown",
                  CC: [],
                  ADSMT: "Title",
                  V: {
                    ISM: true,
                    MinC: "10",
                    MaxC: "20",
                    Ptn: "regexvalue",
                  },
                },
              ],
            },
            {
              AN: "Address",
              AID: "2",
              AC: "SA_Address",
              AT: "Attribute",
              DT: "Text",
              ASN: "4",
              V: {
                ISM: true,
                MinC: "10",
                MaxC: "20",
                Ptn: "regexvalue",
              },
            },
          ],
        },
        {
          SN: "Family",
          SDN: "Family",
          SId: "2",
          SNO: "2",
          Attributes: [],
        },
      ],
    },
    {
      tn: "Employment",
      tdn: "Employment",
      tsn: "2",
      S: [],
    },
  ],
};

export const dataRes = {
  mn: "EmployeeCentral",
  in: "FORM",
  tn: "Personal",
  SN: "",
  Attributes: [
    {
      Key: "SA_Title",
      Value: "1",
      AT: "Attribute",
    },
    {
      Key: "SA_Name",
      Value: "Aniruddha",
      AT: "Attribute",
    },
    {
      Key: "SA_Relationship",
      Value: "",
      AT: "AttributeSet",
      Details: [
        {
          SA_RelationName: "",
          SA_RelationType: "",
        },
        {
          SA_RelationName: "",
          SA_RelationType: "",
        },
      ],
    },
  ],
};

export const tabsData = [
  { id: 1, name: "Personal" },
  { id: 2, name: "Employment" },
  { id: 3, name: "Performance" },
  { id: 4, name: "Learning" },
];

export const stateData = [
  { label: "New York", value: "101" },
  { label: "Virginia ", value: "102" },
  { label: "Tasmania ", value: "105" },
];

export const cityData = [
  { label: "Albany", StateId: "101", value: 201 },
  { label: "Beacon ", StateId: "101", value: 202 },
  { label: "Emporia", StateId: "102", value: 206 },
  { label: "Hampton ", StateId: "102", value: 205 },
  { label: "Hobart", StateId: "105", value: 213 },
  { label: "Launceston ", StateId: "105", value: 214 },
];
