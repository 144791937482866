import React, { useContext, useEffect, useRef, useState } from "react";
// import { apiResp, cityData, stateData } from "./formData";
import ButtonForm from "../../Components/ButtonForm/ButtonForm";
import { Tabs, Tooltip } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import SelectForm from "../../Components/SelectForm/SelectForm";
import InputForm from "../../Components/InputForm/InputForm";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import moment from "moment";
import "./AnonymousCandidate.css";
import profileImg from "../../Assets/Images/profile.jpeg";
import { APICall } from "../../Helpers/APICalls";
import { LoaderContext } from "../../Helpers/Context/Context";
import defaultProfileImgPic from "../../Assets/Images/profile.png";
import {
  getEmployeedetailsBasedOnCandidateId,
  getAttributeValueDetailsV2,
  getSectionDetailsV2,
  getTabsData,
  checkCandidateMRFQuestionMappingEndpoint,
  getTabHeaderDetails,
  getStatusGridData,
  getMRFJob,
  candidateSubmitapplication,
  getDecisionMasterDDByStageandMrfId,
  CandidatWhenAcceptOrRejectOfferAcceptance,
  GetCandidateDetailsByUserId,
  checkCandidateDeclartionFormIsSubmitted,
  GetConfigValueByKey,
  CheckEmployeeAlreadyAppliedForMRF,
  CheckCandidateReferenceExistsOrNot,
  documentUpload,
  getRecruiterRemarkById,
  GetCandidateOfferAcceptanceDetailsById,
  UpdateDocumentCollectionTaskStatusCompleted,
  getProfilePicForOnboarding,
  CheckJobPostingOnLinkdin,
} from "../../Helpers/APIEndPoints/EndPoints";
import { useLocation, useNavigate } from "react-router-dom";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { SectionComponent } from "./SectionComponent";
import { DeclartionFormSection } from "./DeclartionFormSection";
import notify from "../../Helpers/ToastNotification";
import { Modal } from "react-bootstrap";
import InternalJobPostion from "./InternalJobPostion";
import ReferenceCheck from "./ReferenceCheck";
import DeclartionFormV2 from "./Components/DeclartionForm";
import ResumeSection from "./Components/ResumeSection";
import CandidateForm from "./Components/CandidateForm";
import SalaryNegotiation from "./Components/SalaryNegotiation";
interface IDashboardColumns {
  Id?: any;
  Activity?: string;
  Date?: string;
  DecisionSystemName?: string;
  WorkflowTaskId?: number;
  DueDate?: string;
  ActualDate?: string;
  Status?: string;
  Feedback?: string;
  ScheduledDate?: string;
  StaticStageSystemName?: string;
}

const AnonymousCandidate = ({ setShowHeader }) => {
  const [formData, setFormData] = useState<any>({});
  const [tabs, setTabs] = useState([]);
  const navigate = useNavigate();
  const [activetab, setActivetab] = useState<string>(null);
  const [activetabIndex, setActivetabIndex] = useState<number>(0);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { state } = useLocation();
  const [TId, setTId] = useState(state?.IsAnonymousCandidate ? state.TId : 0);
  const [JobId, setJobId] = useState(
    state?.IsAnonymousCandidate ? state.JobId : 0
  );
  const [headerValues, setHeaderValues] = useState<any>(null);
  const [mrfStatus, setMRFStatus] = useState<any>("");
  const [documentBase64, setDocumentBase64] = useState<any>(null);
  const [OfferLetterDoc, setOfferLetterDoc] = useState<
    {
      AC: string;
      Name: string;
      Data: string;
      IsValidFile: boolean;
      DocId: Number;
      Type: string;
      Context: string;
    }[]
  >([]);
  const [sectionValuesArray, setSectionValuesArray] = useState<any>([]);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [CId, setCid] = useState<any>(0);
  const [isDisableApplication, setIsDisableApplication] = useState(false);
  const [isShowAllSection, setIsShowAllSection] = useState(
    state?.IsAnonymousCandidate ? false : true
  );
  const { userDetails, currentRoleId }: any = useUserContext();
  const [statusGridData, setStatusGridData] = useState({
    count: 0,
    start: 0,
    pageSize: 0,
    searchText: "",
    sortColumn: "",
    sortDirection: "",
    data: [],
  });

  const [disableModalSubmitBtn, setDisableModalSubmitBtn] = useState(false);

  const [DMSURL, setDMSURL] = useState(null);

  const [accordion, setAccordion] = useState(true);
  const [JobOption, setJobOption] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [workflowTaskId, setWorkflowTaskId] = useState(0);
  const [getData, setGetData] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showOfferAccModal, setShowOfferAccModal] = useState(false);
  const [DecisionOptions, setDecisionOptions] = useState([]);
  const [decision, setDecision] = useState(null);
  const [remark, setRemark] = useState("");
  const [isAnonymousCandidate, setIsAnonymousCandidate] = useState(
    state?.IsAnonymousCandidate ? true : false
  );
  const [dashboardData, setDashboardData] = useState<IDashboardColumns[]>([]);
  const [dashboardData1, setDashboardData1] = useState<IDashboardColumns[]>([]);
  const [disableBeforeDeclaration, setDisableBeforeDeclaration] =
    useState(true);
  const [rejectCandidate, setRejectCandidate] = useState(false);
  const [editAllOnBoarding, setEditAllOnBoarding] = useState(false);
  const [isSourceIJP, setIsSourceIJP] = useState(false);
  const [employeeId, setEmployeeId] = useState(0);
  const [offerDocId, setOfferDocId] = useState(0);
  const [employeeAlreadyApplied, setEmployeeAlreadyApplied] =
    useState<boolean>(false);
  const [hideShowApplicationTab, setHideShowApplicationTab] = useState(false);
  const [formErrorsOfferAcc, setformErrorsOfferAcc] = useState<any>({});
  const [recruiterRemarks, setRecuiterRemark] = useState("");
  const [disableOfferLetterModal, setDisableOfferLetterModal] = useState(false);
  const [onbFinalSubmit, setOnbFinalSubmit] = useState(false);
  const [reloadFinal, setReloadFinal] = useState(false);
  const [taskAllCompleted, setTaskAllCompleted] = useState([]);
  const [hasPendingTasks, setHasPendingTasks] = useState([]);
  const [showSalaryNego, setShowSalaryNego] = useState(false);
  const [cwtId, setCwtId] = useState(0);
  const [profileImg, setProfileImg] = useState(null);
  const [isJobPostingDoneOnLinkdin, setJobPostingDoneOnLinkdin] =
    useState(false);

  useEffect(() => {
    const helper = async () => {
      await GetStatusGridDataAsync();
    };
    helper();
  }, [reloadFinal]);

  useEffect(() => {
    const helper = async () => {
      if (state?.IsIJP) {
        await CheckEmployeeAlreadyAppliedForMRFAsync();
      } else {
        setShowHeader(false);
      }
      await Promise.all([getCandidateIdbyUserId(), getDocConfigUrl()]);
    };
    helper();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      await GetRecruiterRemarks();
      await CheckJobPostingOnLinkdinAsync();
    };
    fetch();
  }, [TId]);

  useEffect(() => {
    const helper = async () => {
      await getTabsDataAsync();
      await GetMRFStatus();
    };
    helper();
  }, [hideShowApplicationTab, isAnonymousCandidate]);

  const GetMRFStatus = async () => {
    try {
      showLoader();
      const tabHeader = await APICall(getTabHeaderDetails, "POST", {
        MN: "ManpowerRequisition",
        IN: "",
        Id: TId,
      });
      setMRFStatus(tabHeader?.data?.mrfStatus || "");
    } catch (error) {
      console.error("Error in MRFStatus: ", error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (activetab != null) {
        if (TId !== 0) {
          await getHeaderValuesAndOfferLetterDetails();
        }

        if (activetab === "AgencyJobDescription") {
          await Promise.all([
            getAgencyJobDescriptionAsync(),
            sectionAPICallAsync(),
          ]);
        } else if (activetab === "anonymouscanidatestatus") {
          await Promise.all([GetMrfJobOption(), GetStatusGridDataAsync()]);
          setIsDisableApplication(false);
        }
      }
    };
    fetchData();
  }, [activetab, TId, JobId]);

  useEffect(() => {}, [isFormFilled]);

  useEffect(() => {
    if (userDetails?.Id > 0) {
      (async () => {
        await showLoader();
        const getPhoto = await APICall(getProfilePicForOnboarding, "POST", {
          TId: userDetails?.Id,
          AC: "ProfilePic",
        });

        if (getPhoto && getPhoto.data !== null) {
          setProfileImg(`data:image;base64,${getPhoto.data}`);
        }
        await hideLoader();
      })();
    }
  }, [TId]);

  const statusGridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: statusGridData.count,
    rowsPerPage: 15,
    serverSide: false,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",
    page: statusGridData.start,
    //standard | vertical | simple
    onChangeRowsPerPage: (num) => {},
    onSearchChange: (searchText) => {
      if (searchText !== null) {
        setStatusGridData((prev) => {
          return { ...prev, searchText: searchText };
        });
      } else {
        setStatusGridData((prev) => {
          return { ...prev, searchText: "" };
        });
      }
    },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setStatusGridData((prev) => {
          return {
            ...prev,
            sortColumn: sortColumn,
            sortDirection: sortDirection,
          };
        });
      }
      if (sortDirection === "desc") {
        await setStatusGridData((prev) => {
          return {
            ...prev,
            sortColumn: sortColumn,
            sortDirection: sortDirection,
          };
        });
      }
    },
    onChangePage: async (page) => {
      setStatusGridData((prev) => {
        return { ...prev, start: page * 15 };
      });
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const applyFunction = () => {
    if (employeeAlreadyApplied) {
      setIsAnonymousCandidate(false);
      setFormData([]);
    } else {
      setHideShowApplicationTab(true);
    }
  };

  const CheckJobPostingOnLinkdinAsync = async () => {
    try {
      const { status, data, message } = await APICall(
        CheckJobPostingOnLinkdin,
        "POST",
        {
          Id: TId,
        }
      );

      if (status == 0) {
        setJobPostingDoneOnLinkdin(data);
      } else {
        setJobPostingDoneOnLinkdin(false);
      }
    } catch (error) {}
  };

  async function getTabsDataAsync() {
    try {
      showLoader();
      const tabs = await APICall(getTabsData, "POST", {
        MN: "AgencyCandidate",
        IN: "AgencyCandidate_Form",
      });

      if (tabs.data !== null && tabs.data.length > 0) {
        if (isAnonymousCandidate) {
          if (!hideShowApplicationTab) {
            tabs.data = tabs.data?.filter(
              (item) => item.TN !== "AgencyCandidateApplicationForm"
            );
          }
          setTabs(tabs.data);

          if (hideShowApplicationTab) {
            setActivetab("AgencyCandidateApplicationForm");
          } else {
            setActivetab(tabs.data[0].TN);
          }
        } else {
          setTabs(tabs.data);
          setActivetab("anonymouscanidatestatus");
        }
      } else {
        console.log("No tabs found!", tabs);
        setTabs([]);
      }
    } catch (error) {
      console.error("getTabsDataAsync error: ", error);
    } finally {
      hideLoader();
    }
  }

  /* Common API function */
  const sectionAPICallAsync = async () => {
    try {
      showLoader();
      const section = await APICall(getSectionDetailsV2, "POST", {
        MN: "AgencyCandidate",
        IN: "AgencyCandidate_Form",
        TN: activetab,
        UID: userDetails?.Id,
        RoleId: currentRoleId?.value,
        IsAnonymousCandidate: employeeAlreadyApplied
          ? state?.IsAnonymousCandidate
          : isAnonymousCandidate,
      });

      if (
        section.data !== null &&
        section.data.t !== undefined &&
        section.data.t.tn === activetab
      ) {
        if (!state?.IsIJP || !isSourceIJP) {
          section.data.t.S = section.data.t.S.filter(
            (e) => e.SN !== "OrganisationDetails"
          );
        }
        setFormData(section.data.t);
      } else {
        setFormData({});
      }
    } catch (error) {
      console.error("Section error: ", error);
    } finally {
      hideLoader();
    }
  };

  const getCandidateIdbyUserId = async () => {
    try {
      showLoader();
      const response = await APICall(GetCandidateDetailsByUserId, "POST", {
        Id: userDetails?.Id,
      });

      if (response?.status == 0 && userDetails?.Id) {
        const responseData = response?.data;
        setCid(responseData?.id || 0);
        if (state?.IsIJP) {
          setIsSourceIJP(
            String(responseData?.source).toLocaleLowerCase().trim() === "ijp"
          );
        }
        setEmployeeId(responseData?.employeeId || 0);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  async function CheckEmployeeAlreadyAppliedForMRFAsync() {
    try {
      showLoader();
      const response = await APICall(
        CheckEmployeeAlreadyAppliedForMRF,
        "POST",
        {
          UserId: userDetails?.Id,
          MRFId: state?.TId,
        }
      );

      if (Number(response?.status) === 0) {
        const { data } = response;
        if (data?.isActive) {
          setEmployeeAlreadyApplied(true);
        } else {
          setEmployeeAlreadyApplied(false);
        }
      } else {
        console.error(response?.message);
        setEmployeeAlreadyApplied(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  const getAgencyJobDescriptionAsync = async () => {
    try {
      let bodyObj = {
        mn: "AgencyCandidate",
        in: "AgencyCandidate_Form",
        tn: activetab,
        SN: "AgencyJobDescription",
        TID: TId,
        ISH: "true",
      };
      showLoader();
      const response = await APICall(
        getAttributeValueDetailsV2,
        "POST",
        bodyObj
      );

      if (response.data.sections && response.data.sections.length > 0) {
        setSectionValuesArray(response.data.sections);
      } else {
        setSectionValuesArray([]);
      }
    } catch (error) {
      console.error("getAgencyJobDescriptionAsync: ", error);
    } finally {
      hideLoader();
    }
  };

  const getAgencyCandidateApplicationForm = async () => {
    try {
      let bodyObj = {
        mn: "AgencyCandidate",
        in: "AgencyCandidate_Form",
        tn: activetab,
        SN: "agencycandidateapplicationform",
        TID: CId,
        ISH: "true",
      };
      showLoader();
      const response = await APICall(
        getAttributeValueDetailsV2,
        "POST",
        bodyObj
      );

      if (response?.data?.sections && response?.data?.sections.length > 0) {
        setSectionValuesArray(response.data.sections);
      } else {
        setSectionValuesArray([]);
      }
    } catch (error) {
      console.error("AgencyCandidateApplicationForm: ", error);
    } finally {
      hideLoader();
    }
  };

  const getDocConfigUrl = async () => {
    const response = await APICall(GetConfigValueByKey, "POST", {
      Key: "WelspunDMSAPILink",
    });
    if (response?.status === 0) {
      setDMSURL(response.data);
    } else {
      setDMSURL(null);
    }
  };

  const getHeaderValuesAndOfferLetterDetails = async () => {
    try {
      await showLoader();
      const tabHeader = await APICall(getTabHeaderDetails, "POST", {
        MN: "AnonymousCandidate",
        IN: "",
        Id: TId, //mrf if
        CandidateId: CId, //candidate id
      });

      setHeaderValues(tabHeader.data || null);
      // API CALL TO GET STATUS WITH FILE
      setOfferDocId(tabHeader.data?.offerDocRequestId);

      // if(tabHeader.data?.offerDocRequestId != undefined
      //   && tabHeader.data?.offerDocRequestId != null )
      //   {
      //   const resGenrateDocument = await APICall(
      //     `${DMSURL}/StatusWithFile`,
      //     "POST",
      //     {
      //       requestid: tabHeader.data?.offerDocRequestId,
      //       doctype: "finalpdf",
      //     }
      //   );

      //   if (resGenrateDocument.status == "200") {
      //     setDocumentBase64(resGenrateDocument.data);
      //   }
      // }
    } catch (error) {
      console.error("get headers details: ", error);
    } finally {
      await hideLoader();
    }
  };

  const checkCandidateMRFQuestionMapping = async () => {
    try {
      const response = await APICall(
        checkCandidateMRFQuestionMappingEndpoint,
        "POST",
        {
          ManpowerRequisitionScreeningQuestionId: TId,
          CandidateUserId: userDetails.Id,
        }
      );
      let isEmpty = false;
      if (response && response.status == 0) {
        if (response?.data?.length > 0) {
          for (const item of response?.data) {
            if (!item?.MRScreeningQuestionAnswerMappingId) {
              isEmpty = true;
            }
          }
          setIsFormFilled(!isEmpty);
        } else {
          setIsFormFilled(false);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  async function handleFetchGetCandidateOfferAcceptanceDetailsById(Id = 0) {
    try {
      showLoader();
      const response = await APICall(
        GetCandidateOfferAcceptanceDetailsById,
        "POST",
        {
          Id: Id,
        }
      );
      if (response?.status == 0) {
        const { DecisionId, Remarks } = response?.data;
        setRemark(Remarks || "");
        const decision = DecisionOptions?.find((i) => i?.value == DecisionId);
        setDecision(decision || null);
        return { DecisionId, Remarks };
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  const GetStatusGridDataAsync = async () => {
    try {
      let bodyObj = {
        Id: TId,
        UserId: userDetails.Id,
        PageSize: 1,
      };
      showLoader();
      const response = await APICall(getStatusGridData, "POST", bodyObj);

      if (response && response.data != null) {
        let arr: IDashboardColumns[] = [];

        arr = response?.data?.map((i) => {
          let obj = {
            Id: i?.id,
            Activity: i.activity,
            WorkflowTaskId: i?.workflowTaskId,
            DueDate: i?.dueDate,
            ActualDate: i?.actualDate,
            Status: i?.status,
            StaticStageSystemName: i?.staticStageSystemName,
            DecisionSystemName: i?.decisionSystemName,
            Date: i?.createdDateUtc,
            StageId: i?.stageId,
            IsOnBoarding: i?.isOnboarding,
            ScheduledDate: moment(i?.ScheduledDate).isValid()
              ? moment(i?.scheduledDate).format("DD-MM-YYYY HH:mm:ss")
              : "",
            ModifiedDateUtc: i?.modifiedDateUtc ?? null,
          };

          return obj;
        });

        let firstTable = [];
        let secondTable = [];

        const MRFTasksArray = [
          "ResumeEvalution",
          "Recommendation",
          "RecommedationReview",
          "FirstInterview",
          "SecondInterview",
          "FinalInterview",
          "Negotiation",
          "BackgroundVerification",
          "ReferenceCheck",
          "PsychometricTest",
          "OfferApproval",
          "OfferLetterReview",
          "OfferAcceptance",
          "Joining",
        ];
        for (let i = 0; i < arr.length; i++) {
          if (MRFTasksArray.some((x) => x === arr[i].StaticStageSystemName)) {
            firstTable.push(arr[i]);
          } else {
            secondTable.push(arr[i]);
          }
        }
        setDashboardData(firstTable);
        setDashboardData1(secondTable);
        setTaskAllCompleted(
          secondTable.filter((x) => x.DecisionSystemName == "Completed")
        );
        setOnbFinalSubmit(
          secondTable.filter((x) => x.DecisionSystemName == "Completed")
            .length == secondTable.length
            ? true
            : false
        );
        setHasPendingTasks(
          secondTable.filter((x) => x.DecisionSystemName == "NotStarted")
        );
      } else {
        setStatusGridData((prev) => {
          return { ...prev, data: [] };
        });

        setDashboardData([]);
        setDashboardData1([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  async function viewOfferLetterHelper(stageId, Id) {
    try {
      showLoader();
      await getDecisionMasterDDByStageandMrfIdAPICall(stageId, true, Id);
      const tabHeader = await APICall(getTabHeaderDetails, "POST", {
        MN: "AnonymousCandidate",
        IN: "",
        Id: TId, //mrf if
        CandidateId: CId, //candidate id
      });

      if (
        tabHeader.data?.offerDocRequestId != undefined &&
        tabHeader.data?.offerDocRequestId != null
      ) {
        const resGenrateDocument = await APICall(
          `${DMSURL}/StatusWithFile`,
          "POST",
          {
            requestid: tabHeader.data?.offerDocRequestId,
            doctype: "finalpdf",
          }
        );

        if (resGenrateDocument.status == "200") {
          setDocumentBase64(resGenrateDocument.data);
        }
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  }

  const statusGridColumns = [
    {
      name: "Id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "WorkflowTaskId",
      label: "WorkflowTaskId",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "StaticStageSystemName",
      label: "StaticStageSystemName",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "DecisionSystemName",
      label: "DecisionSystemName",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "Date",
      label: "Date",
      options: {
        filter: false,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              {value != null && (
                <span>{moment(value).format("DD-MM-YYYY")}</span>
              )}
            </>
          );
        },
      },
    },
    {
      name: "Activity",
      label: "Activity",
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: "Status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: "DueDate",
      label: "Due date",
      options: {
        filter: false,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta) => {
          const date = moment(value).isValid()
            ? moment(value).format("DD-MM-YYYY HH:mm:ss")
            : "";

          return <>{value != null && <span>{date}</span>}</>;
        },
      },
    },
    {
      name: "ActualDate",
      label: "ActualDate",
      options: {
        filter: false,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              {value != null && (
                <span>{moment(value).format("DD-MM-YYYY")}</span>
              )}
            </>
          );
        },
      },
    },
    {
      name: "ModifiedDateUtc",
      label: "Completed date",
      options: {
        filter: false,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta) => {
          let output = "";

          if (moment(value).isValid()) {
            const localTime = moment.utc(value).local();
            const formattedLocalTime = localTime.format(
              "DD-MM-YYYY HH:mm:ss A"
            );
            output = formattedLocalTime;
          }
          return (
            <>
              <span>{output}</span>
            </>
          );
        },
      },
    },
    {
      name: "",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta) => {
          // console.log('Table Meta::::',tableMeta);
          const Id = tableMeta.tableData[tableMeta.rowIndex].Id;
          const StaticStageSystemName =
            tableMeta.tableData[tableMeta.rowIndex].StaticStageSystemName;
          const DecisionSystemName =
            tableMeta.tableData[tableMeta.rowIndex].DecisionSystemName;
          const WorkflowTaskId =
            tableMeta.tableData[tableMeta.rowIndex].WorkflowTaskId;
          const IsOnboarding =
            tableMeta.tableData[tableMeta.rowIndex].IsOnBoarding;
          const Activity = tableMeta.tableData[tableMeta.rowIndex].Activity;
          const StageName = tableMeta.rowData[2];
          const StageStatus = tableMeta.rowData[3];

          let isTaskCompleted =
            String(DecisionSystemName)?.toLocaleLowerCase().trim() ==
              "notstarted" ||
            String(DecisionSystemName)?.toLocaleLowerCase().trim() ==
              "inprogress" ||
            String(DecisionSystemName)?.toLocaleLowerCase().trim() ==
              "pendingwithcandidate" ||
            String(DecisionSystemName)?.toLocaleLowerCase().trim() ==
              "initiated" ||
            String(DecisionSystemName)?.toLocaleLowerCase().trim() == "verified"
              ? false
              : true;

          const stageId = Number(tableMeta.rowData[7]);
          return (
            <>
              {String(StaticStageSystemName)?.toLocaleLowerCase().trim() ===
              "negotiation" ? (
                <>
                  {String(DecisionSystemName)?.toLocaleLowerCase().trim() ==
                  "initiated" ? (
                    <>
                      <div className="centerStyles">
                        <i
                          style={{ marginRight: "0px", cursor: "pointer" }}
                          onClick={() => {
                            setIsDisableApplication(false);
                            setActivetab(tabs[3].TN);
                            setShowSalaryNego(true);
                            setCwtId(Id);
                          }}
                          className="fa fa-pencil"
                        ></i>
                        <Tooltip
                          title={
                            isTaskCompleted ? "Completed" : "To be completed"
                          }
                        >
                          <i
                            style={{
                              fontSize: "small",
                              marginRight: "0px",
                              //cursor: "pointer",
                              color: isTaskCompleted ? "green" : "red",
                            }}
                            className="fas fa-circle mx-2"
                          ></i>
                        </Tooltip>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="centerStyles">
                        <i
                          style={{ marginRight: "0px", cursor: "pointer" }}
                          onClick={() => {
                            setIsDisableApplication(true);
                            setActivetab(tabs[3].TN);
                            setShowSalaryNego(true);
                            setCwtId(Id);
                          }}
                          className="fas fa-eye"
                        ></i>
                        <Tooltip
                          title={
                            isTaskCompleted ? "Completed" : "To be completed"
                          }
                        >
                          <i
                            style={{
                              fontSize: "small",
                              marginRight: "0px",
                              //cursor: "pointer",
                              color: isTaskCompleted ? "green" : "red",
                            }}
                            className="fas fa-circle mx-2"
                          ></i>
                        </Tooltip>
                      </div>
                    </>
                  )}
                </>
              ) : String(StaticStageSystemName)?.toLocaleLowerCase().trim() ===
                "offeracceptance" ? (
                <>
                  {String(DecisionSystemName)?.toLocaleLowerCase().trim() ===
                  "inprogress" ? (
                    <>
                      <div className="centerStyles">
                        <i
                          style={{ marginRight: "0px", cursor: "pointer" }}
                          onClick={async () => {
                            const [tabHeader, ,] = await Promise.all([
                              APICall(getTabHeaderDetails, "POST", {
                                MN: "AnonymousCandidate",
                                IN: "",
                                Id: TId, //mrf if
                                CandidateId: CId, //candidate id
                              }),
                              getDecisionMasterDDByStageandMrfIdAPICall(
                                stageId
                              ),
                              GetRecruiterRemarks(Id),
                            ]);

                            // const tabHeader2 = await APICall(
                            //   getTabHeaderDetails,
                            //   "POST",
                            //   {
                            //     MN: "AnonymousCandidate",
                            //     IN: "",
                            //     Id: TId, //mrf if
                            //     CandidateId: CId, //candidate id
                            //   }
                            // );
                            if (
                              tabHeader.data?.offerDocRequestId != undefined &&
                              tabHeader.data?.offerDocRequestId != null
                            ) {
                              showLoader();
                              const resGenrateDocument = await APICall(
                                `${DMSURL}/StatusWithFile`,
                                "POST",
                                {
                                  requestid: tabHeader.data?.offerDocRequestId,
                                  doctype: "finalpdf",
                                }
                              );

                              if (resGenrateDocument.status == "200") {
                                setDocumentBase64(resGenrateDocument.data);
                              }
                              hideLoader();
                            }

                            setShowOfferAccModal(true);
                          }}
                          className="fa fa-pencil"
                        ></i>
                        <Tooltip
                          title={
                            isTaskCompleted ? "Completed" : "To be completed"
                          }
                        >
                          <i
                            style={{
                              fontSize: "small",
                              marginRight: "0px",
                              //cursor: "pointer",
                              color: isTaskCompleted ? "green" : "red",
                            }}
                            className="fas fa-circle mx-2"
                          ></i>
                        </Tooltip>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="centerStyles">
                        <i
                          style={{ marginRight: "0px", cursor: "pointer" }}
                          onClick={async () => {
                            await Promise.all([
                              viewOfferLetterHelper(stageId, Id),
                              GetRecruiterRemarks(Id),
                            ]);
                            // await viewOfferLetterHelper(stageId, Id);
                            // await GetRecruiterRemarks(Id);
                            setShowOfferAccModal(true);
                            setDisableOfferLetterModal(true);
                          }}
                          className="fas fa-eye"
                        ></i>
                        <Tooltip
                          title={
                            isTaskCompleted ? "Completed" : "To be completed"
                          }
                        >
                          <i
                            style={{
                              fontSize: "small",
                              marginRight: "0px",
                              //cursor: "pointer",
                              color: isTaskCompleted ? "green" : "red",
                            }}
                            className="fas fa-circle mx-2"
                          ></i>
                        </Tooltip>
                      </div>
                    </>
                  )}
                </>
              ) : //For Candidate Onboarding
              IsOnboarding &&
                String(StaticStageSystemName)?.toLocaleLowerCase().trim() ===
                  "datacollection" &&
                String(Activity)?.toLocaleLowerCase().trim() ===
                  "fill onboarding details" ? (
                <>
                  {hasPendingTasks.length > 0 ||
                  taskAllCompleted.length != dashboardData1.length ? ( // This condition helps to keep this task edited
                    <>
                      {String(DecisionSystemName)?.toLocaleLowerCase().trim() ==
                        "notstarted" ||
                      String(DecisionSystemName)?.toLocaleLowerCase().trim() ==
                        "inprogress"
                        ? setEditAllOnBoarding(false)
                        : setEditAllOnBoarding(true)}
                      <div className="centerStyles">
                        <i
                          style={{ marginRight: "0px", cursor: "pointer" }}
                          onClick={() => {
                            redirectToCandidateEc(value, tableMeta, true);
                          }}
                          className="fa fa-pencil"
                        ></i>
                        <Tooltip
                          title={
                            isTaskCompleted ? "Completed" : "To be completed"
                          }
                        >
                          <i
                            style={{
                              fontSize: "small",
                              marginRight: "0px",
                              //cursor: "pointer",
                              color: isTaskCompleted ? "green" : "red",
                            }}
                            className="fas fa-circle mx-2"
                          ></i>
                        </Tooltip>
                      </div>
                    </>
                  ) : (
                    <>
                      {setEditAllOnBoarding(true)}
                      <div className="centerStyles">
                        <i
                          style={{ marginRight: "0px", cursor: "pointer" }}
                          onClick={() => {
                            redirectToCandidateEc(value, tableMeta, false);
                          }}
                          className="fas fa-eye"
                        ></i>
                        <Tooltip
                          title={
                            isTaskCompleted ? "Completed" : "To be completed"
                          }
                        >
                          <i
                            style={{
                              fontSize: "small",
                              marginRight: "0px",
                              //cursor: "pointer",
                              color: isTaskCompleted ? "green" : "red",
                            }}
                            className="fas fa-circle mx-2"
                          ></i>
                        </Tooltip>
                      </div>
                    </>
                  )}
                </>
              ) : //DocumentCollection
              IsOnboarding &&
                String(StaticStageSystemName)?.toLocaleLowerCase().trim() ===
                  "documentcollection" ? (
                <>
                  {(String(DecisionSystemName)?.toLocaleLowerCase().trim() ==
                    "notstarted" ||
                    String(DecisionSystemName)?.toLocaleLowerCase().trim() ==
                      "verified") &&
                  editAllOnBoarding ? (
                    <>
                      <div className="centerStyles">
                        <i
                          style={{ marginRight: "0px", cursor: "pointer" }}
                          onClick={() => {
                            redirectToCandidateDocUpload(
                              value,
                              tableMeta,
                              true
                            );
                          }}
                          className="fa fa-pencil"
                        ></i>
                        <Tooltip
                          title={
                            isTaskCompleted ? "Completed" : "To be completed"
                          }
                        >
                          <i
                            style={{
                              fontSize: "small",
                              marginRight: "0px",
                              //cursor: "pointer",
                              color: isTaskCompleted ? "green" : "red",
                            }}
                            className="fas fa-circle mx-2"
                          ></i>
                        </Tooltip>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="centerStyles">
                        <i
                          style={{ marginRight: "0px", cursor: "pointer" }}
                          onClick={() => {
                            redirectToCandidateDocUpload(
                              true,
                              tableMeta,
                              false
                            );
                          }}
                          className="fas fa-eye"
                        ></i>
                        <Tooltip
                          title={
                            isTaskCompleted ? "Completed" : "To be completed"
                          }
                        >
                          <i
                            style={{
                              fontSize: "small",
                              marginRight: "0px",
                              //cursor: "pointer",
                              color: isTaskCompleted ? "green" : "red",
                            }}
                            className="fas fa-circle mx-2"
                          ></i>
                        </Tooltip>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="centerStyles">
                    <i
                      style={{ marginRight: "0px", cursor: "pointer" }}
                      onClick={() => {
                        setIsShowAllSection(true);
                        setIsDisableApplication(true);
                        setActivetab(tabs[3].TN);
                      }}
                      className="fas fa-eye"
                    ></i>
                    <Tooltip
                      title={isTaskCompleted ? "Completed" : "To be completed"}
                    >
                      <i
                        style={{
                          fontSize: "small",
                          marginRight: "0px",
                          //cursor: "pointer",
                          color: isTaskCompleted ? "green" : "red",
                        }}
                        className="fas fa-circle mx-2"
                      ></i>
                    </Tooltip>
                  </div>
                </>
              )}
            </>
          );
        },
      },
    },
  ];

  const GetMrfJobOption = async () => {
    let bodyObj = {
      CandidateId: userDetails?.Id || 0,
      MRFId: state?.MRFId || 0,
      IsAnonymousCandidate: isAnonymousCandidate,
    };

    const response = await APICall(getMRFJob, "POST", bodyObj);

    if (response && response.data != null) {
      const responseData = response?.data;
      setJobOption(response.data);
      if (response.data.length === 1) {
        setSelectedJob(response.data[0]);
        setTId(response.data[0].mrfId);
        setJobId(response.data[0].value);
      }
      if (state?.IsAnonymousCandidate) {
        const filterObj = responseData?.find(
          (opt) => opt?.mrfId == state?.MRFId
        );
        setSelectedJob(filterObj);
        setTId(state?.MRFId);
        setJobId(responseData?.value);
      }
    } else {
      setJobOption([]);
    }
  };

  async function checkCandidateReferenceAddedOrNotAsync() {
    try {
      let IsExists = true;
      showLoader();
      const response = await APICall(
        CheckCandidateReferenceExistsOrNot,
        "POST",
        {
          CandidateId: CId,
        }
      );

      if (response?.status == 0) {
        IsExists = response?.data?.isExists == true;
      } else {
        console.error(response?.message);
      }
      return IsExists;
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  const handleSubmitApplicationForm = async () => {
    if (!state?.IsIJP && !isSourceIJP) {
      const isReferenceCheckAdded =
        await checkCandidateReferenceAddedOrNotAsync();

      if (!isReferenceCheckAdded) {
        notify(1, "Reference required.");
        return;
      }
    }

    if (getData.length > 0) {
      let arr = formData.S?.filter((i) => i?.SN != "ReferenceCheck");
      if (getData.length === arr.length || isSourceIJP || state?.IsIJP) {
        const response = await APICall(
          checkCandidateDeclartionFormIsSubmitted,
          "POST",
          {
            MRFId: TId || 0,
            UserId: userDetails.Id || 0,
          }
        );

        if (
          (response?.status == 0 && response?.data == true) ||
          isSourceIJP ||
          state.IsIJP
        ) {
          setShowConfirmationModal(true);
        } else {
          notify(
            1,
            "Please Fill Declartion form Before Submitting Your Application."
          );
          return;
        }
      } else {
        setShowConfirmationModal(false);
        let emptySections = [];
        let arr = formData.S?.filter((i) => i?.SN != "ReferenceCheck");
        for (let i = 0; i < arr.length; i++) {
          if (!getData.find((x) => x.section === arr[i].SN)) {
            emptySections.push(formData.S[i].SDN);
          }
        }
        notify(
          1,
          `Please fill ${emptySections.join(
            " ,"
          )} sections before submitting your application`
        );
      }
    } else {
      setShowConfirmationModal(false);

      let emptySections = [];
      let arr = formData.S?.filter((i) => i?.SN != "ReferenceCheck");

      for (let i = 0; i < arr.length; i++) {
        if (!getData.find((x) => x.section === arr[i].SN)) {
          emptySections.push(formData.S[i].SDN);
        }
      }

      notify(
        1,
        `Please fill ${emptySections.join(
          " ,"
        )} sections before submitting your application`
      );
    }
  };

  const checkValidation = () => {
    let formCount = 0;
    if (remark == null || remark == "" || remark == undefined) {
      formCount += 1;
      setformErrorsOfferAcc((prev) => ({
        ...prev,
        ["Remarks"]: "Remarks are Mandatory",
      }));
    }

    if (decision == null || decision == undefined) {
      formCount += 1;
      setformErrorsOfferAcc((prev) => ({
        ...prev,
        ["Decision"]: "Decision is Mandatory.",
      }));
    }
    return formCount != 0 ? false : true;
  };

  const closeConfirmationModal = async (confirmation) => {
    if (confirmation) {
      setIsDisableApplication(true);
      setDisableModalSubmitBtn(true);
      const response = await APICall(candidateSubmitapplication, "POST", {
        CreatedBy: userDetails.Id || 0,
        MRFId: TId || 0,
        WorkflowTaskId: workflowTaskId || 0,
        RoleId: currentRoleId?.value,
      });
      if (response?.status == 0) {
        notify(0, "Application submitted successfully.");
        if (rejectCandidate) {
          // api to reject candidate
        }

        navigate("/home");
      } else if (response?.status == 1) {
        notify(1, "Something went wrong, Please try again later.");
      }
      setDisableModalSubmitBtn(false);
    } else {
      setIsDisableApplication(false);
    }
    setShowConfirmationModal(false);
  };

  const closeOfferAccModal = async (confirmation) => {
    setformErrorsOfferAcc({});

    if (confirmation) {
      const isValid = checkValidation();
      if (!isValid) {
        setShowOfferAccModal(true);
        return;
      }

      const response = await APICall(
        CandidatWhenAcceptOrRejectOfferAcceptance,
        "POST",
        {
          CandidateId: userDetails.Id || 0,
          WorkflowTaskId: workflowTaskId || 0,
          DecisionId: Number(decision?.value),
          Remarks: remark,
          MRFId: TId || 0,
          UserId: userDetails.Id || 0,
        }
      );
      if (response?.status == 0) {
        let filename = headerValues?.candidateName
          .replace(" " || "  ", "_")
          .replace(`'` || "<" || ">" || '"' || "/" || `?` || `*`, "");
        let docobj = [
          {
            AC: "OFFLTRDOC",
            Name: `${filename}-OfferLetter.pdf`,
            Data: documentBase64,
            IsValidFile: true,
            DocId: 0,
            Type: "Offer Letter",
            Context: "Offer Letter",
          },
        ];
        let obj = {
          TId: headerValues.candidateId,
          files: docobj,
          ModuleName: "ManpowerRequisition",
          ModuleId: 5,
          UserId: userDetails.Id,
          RId: TId,
        };
        showLoader();
        APICall(documentUpload, "POST", obj);
        hideLoader();
        await GetStatusGridDataAsync();
        notify(0, "Data Saved Successfully.");
      } else if (response?.status == 1) {
        notify(1, "Something went wrong, Please try again later.");
      }
    }
    setShowOfferAccModal(false);
  };

  const getDecisionMasterDDByStageandMrfIdAPICall = async (
    stage,
    isView = false,
    Id = 0
  ) => {
    showLoader();
    const response = await APICall(getDecisionMasterDDByStageandMrfId, "POST", {
      TId: TId,
      Stage: stage,
    });

    if (response.data && response.data.length > 0) {
      const options = response?.data;
      setDecisionOptions(response.data);
      if (isView) {
        const responseCWT = await APICall(
          GetCandidateOfferAcceptanceDetailsById,
          "POST",
          {
            Id: Id,
          }
        );
        if (responseCWT?.status == 0) {
          const { DecisionId, Remarks } = responseCWT?.data;
          const decision = options?.find((i) => i?.value == DecisionId);
          setDecision(decision || null);
          setRemark(Remarks || "");
          return { DecisionId, Remarks };
        }
      }
    } else {
      setDecisionOptions([]);
    }

    hideLoader();
  };

  const redirectToCandidateEc = async (value, tableMeta, isEdit) => {
    // console.log("UD::::", userDetails);
    const candidateUserId = userDetails.Id;
    const resp = await APICall(getEmployeedetailsBasedOnCandidateId, "POST", {
      CandidateId: candidateUserId,
    });

    const Id = tableMeta.tableData[tableMeta.rowIndex].Id;
    const DecisionSystemName =
      tableMeta.tableData[tableMeta.rowIndex].DecisionSystemName;

    const TId = resp.data.tId;
    const NewHireStatus = resp.data.newHireStatus;
    const CT = resp.data.ct;
    const CandidateWorkflowTaskId = Id;

    navigate("/candidateform", {
      state: {
        TId,
        CandidateWorkflowTaskId,
        NewHireStatus,
        CT,
        isEdit,
        DecisionSystemName,
      },
    });
  };

  const redirectToCandidateDocUpload = async (value, tableMeta, isEdit) => {
    const Id = tableMeta.tableData[tableMeta.rowIndex].Id;

    navigate("/CandidateOnboardingApprovalDashboard", {
      state: { Id, EID: userDetails.EmployeeId, IsTaskCompleted: value },
    });
  };

  const GetRecruiterRemarks = async (Id = 0) => {
    try {
      const res = await APICall(getRecruiterRemarkById, "POST", {
        CandidateId: userDetails ? userDetails.Id : 0,
        MRFId: TId || 0,
        SystemName: "OfferLetterReview",
        CandidateWorkflowTaskId: Id,
      });

      if (res.status === 0 && res.data !== null) {
        setRecuiterRemark(res.data.feedback);
      }
    } catch (error) {}
  };

  const CompleteDocumentCollectionTask = async () => {
    //CId --Candidate Id
    //TId --Mrf Id
    try {
      showLoader();
      setOnbFinalSubmit(true);
      const res = await APICall(
        UpdateDocumentCollectionTaskStatusCompleted,
        "POST",
        {
          CandidateId: CId,
          MRFId: TId,
        }
      );

      if (res.status === 0 && res.data !== null) {
        console.log("Final Submit for Document Collection Successful!");
        //navigate("/anonymouscanidate");
        //setReloadFinal(true);
        notify(res.status, res.message);
        //window.location.reload();
      } else {
        notify(res.status, res.message);
      }
      await hideLoader();
    } catch (error) {
      console.error("Final Submit for Document Collection Failed!");
      setOnbFinalSubmit(false);
      await hideLoader();
    }
  };

  function handleBack() {
    if (isAnonymousCandidate) {
      navigate(-1);
    } else if (
      !isAnonymousCandidate &&
      activetab !== "anonymouscanidatestatus"
    ) {
      navigate("/anonymouscanidate");
      setActivetab("anonymouscanidatestatus");
      setIsDisableApplication(false);
    }
  }

  return (
    <>
      <div className="container-fluid">
        <div
          className="row prof_bg mb-3 border-bottom"
          style={{ backgroundColor: headerValues ? "#00abe6" : "white" }}
        >
          <div className="container-fluid">
            <HeaderComponent
              headerValues={headerValues}
              profileImg={profileImg}
              hideShowApplicationTab={Number(CId) > 0}
            />
            <div className="row">
              <div
                className={
                  headerValues
                    ? "col-lg-10 col-md-12 col-sm-12 "
                    : "col-lg-12 col-md-12 col-sm-12"
                }
              >
                <TabComponent
                  activetabIndex={activetabIndex}
                  activetab={activetab}
                  setActivetab={setActivetab}
                  tabs={tabs}
                  isAnonymousCandidate={isAnonymousCandidate}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
            <div className="text-right my-1">
              {!hideShowApplicationTab && isAnonymousCandidate && (
                <button
                  onClick={() => applyFunction()}
                  disabled={
                    String(mrfStatus).trim().toLocaleLowerCase() === "open"
                      ? false
                      : true
                  }
                  className="btn btn-filter-submit float-right ml-2"
                >
                  {employeeAlreadyApplied ? (
                    <>
                      {" "}
                      <i className="fas fa-eye"></i> View Application
                    </>
                  ) : (
                    <>
                      <i className="fa-solid fa-plus"></i>Application
                    </>
                  )}
                </button>
              )}
              <button
                onClick={handleBack}
                disabled={false}
                className="btn btn-secondary float-right"
              >
                <i className="fa-solid fa-arrow-left"></i> Back
              </button>
            </div>
          </div>
        </div>

        {/* section row */}
        <div className="row">
          <React.Fragment>
            {String(activetab).trim().toLowerCase() ===
            "anonymouscanidatestatus" ? (
              <React.Fragment>
                <JobSection
                  JobOption={JobOption}
                  isDisableApplication={isDisableApplication}
                  selectedJob={selectedJob}
                  setSelectedJob={setSelectedJob}
                  setTId={setTId}
                  setJobId={setJobId}
                />
                <MRFSection
                  statusGridOptions={statusGridOptions}
                  dashboardData={dashboardData}
                  statusGridColumns={statusGridColumns}
                />
                <OnBoardingSection
                  statusGridOptions={statusGridOptions}
                  dashboardData1={dashboardData1}
                  statusGridColumns={statusGridColumns}
                />
              </React.Fragment>
            ) : String(activetab).trim().toLowerCase() ===
              "agencyjobdescription" ? (
              <>
                <JobDescription
                  formData={formData}
                  setFormData={setFormData}
                  sectionValuesArray={sectionValuesArray}
                  TId={TId}
                  CId={CId}
                  setCid={setCid}
                  setTId={setTId}
                  activetab={activetab}
                  isShowAllSection={isShowAllSection}
                  isDisableApplication={isDisableApplication}
                  workflowTaskId={workflowTaskId}
                  setWorkflowTaskId={setWorkflowTaskId}
                  setGetData={setGetData}
                  IsAnonymousCandidate={false}
                  disableBeforeDeclaration={false}
                  rejectCandidate={rejectCandidate}
                  setRejectCandidate={setRejectCandidate}
                  isJobPostingDoneOnLinkdin={isJobPostingDoneOnLinkdin}
                />
              </>
            ) : (
              <>
                {String(activetab).toLowerCase().trim() ==
                  "agencycandidateapplicationform" && (
                  <div className="col-lg-12">
                    {showSalaryNego ? (
                      <>
                        <SalaryNegotiation
                          MRFId={TId}
                          CandidateId={CId}
                          key={activetab}
                          disabled={isDisableApplication}
                          CandidateWorkflowTaskId={cwtId}
                          setCwtId={setCwtId}
                        />
                      </>
                    ) : (
                      <>
                        <CandidateForm
                          MRFId={TId}
                          CandidateId={CId}
                          key={activetab}
                          isIJP={state?.IsIJP || isSourceIJP}
                          isDisableApplication={isDisableApplication}
                          source={
                            state?.source == "LinkedIn"
                              ? "LinkedIn"
                              : state?.IsIJP || isSourceIJP
                              ? "IJP"
                              : "Website"
                          }
                        />
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          </React.Fragment>

          {/* {String(activetab).toLowerCase().trim() ===
            "agencycandidateapplicationform" &&
            isAnonymous+Candidate === false && (
              <div className="col-lg-12">
                <div className="float-right col-sm-offset-2">
                  <button
                    type="button"
                    className="btn btn-create btn btn-primary"
                    onClick={handleSubmitApplicationForm}
                    disabled={disableBeforeDeclaration || isDisableApplication}
                  >
                    Submit
                  </button>
                </div>
              </div>
            )} */}
          {/* {state?.IsIJP &&
            !employeeAlreadyApplied &&
            String(activetab).toLowerCase().trim() ===
              "agencycandidateapplicationform" && (
              <InternalJobPostion
                IsIJP={state?.IsIJP}
                disableBeforeDeclaration={disableBeforeDeclaration}
              />
            )} */}
        </div>
        <div
          className="d-flex justify-content-end mt-3"
          style={{ marginRight: "2rem" }}
          hidden={dashboardData1.length > 0 ? false : true}
        >
          <div>
            <button
              style={{ marginLeft: 5 }}
              className="btn btn-filter-submit"
              hidden={dashboardData1.length > 0 ? false : true}
              disabled={
                hasPendingTasks.length > 0
                  ? true
                  : onbFinalSubmit == true
                  ? true
                  : false
              }
              onClick={() => {
                CompleteDocumentCollectionTask();
                setReloadFinal(true);
              }}
            >
              <i className="fa fa-check" aria-hidden="true"></i> Submit{" "}
            </button>
          </div>
        </div>
      </div>
      <Modal
        show={showConfirmationModal}
        onHide={() => closeConfirmationModal(false)}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">Confirm!</div>
        </div>
        <Modal.Body>
          <h5>Do you want to submit application?</h5>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn"
            style={{
              marginLeft: 5,
              color: "black !important",
              borderColor: "#ACACAC",
            }}
            disabled={disableModalSubmitBtn}
            onClick={() => closeConfirmationModal(true)}
          >
            Yes
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => closeConfirmationModal(false)}
          >
            No
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showOfferAccModal}
        onHide={() => {
          closeConfirmationModal(false);
          setDisableOfferLetterModal(false);
        }}
        backdrop="static"
        keyboard={false}
        size="xl"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">Offer Acceptance Letter</div>
        </div>
        <Modal.Body>
          <div className="SectionSubmit mb-4 clearfix"></div>
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label mr-4">Offer Letter</label>
                  <span
                    style={{ color: "blue", cursor: "pointer" }}
                    onClick={() => {
                      if (documentBase64 !== null) {
                        let filename = headerValues.candidateName
                          .replace(" " || "  ", "_")
                          .replace(
                            `'` || "<" || ">" || '"' || "/" || `?` || `*`,
                            ""
                          );
                        downloadBase64File(
                          documentBase64,
                          `${filename}-OfferLetter.pdf`
                        );
                      } else {
                        notify(
                          1,
                          "Offer letter is not generated, Please wait for sometime."
                        );
                      }
                    }}
                  >
                    OfferLetter
                  </span>
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Decision<sup>*</sup>
                  </label>
                  <SelectForm
                    isClearable
                    isSearchable
                    options={DecisionOptions}
                    placeholder={"Decision"}
                    isDisabled={disableOfferLetterModal}
                    value={decision}
                    onChange={(event: any) => {
                      setDecision(event);
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                  />
                  <p style={{ color: "red" }}>
                    {formErrorsOfferAcc["Decision"]}
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">
                    Remarks<sup>*</sup>
                  </label>
                  <InputForm
                    className="form-control"
                    placeholder={"Remarks"}
                    isDisabled={disableOfferLetterModal}
                    textArea={true}
                    value={remark}
                    onChange={(event: any) => {
                      setRemark(event.target.value);
                    }}
                  />
                  <p style={{ color: "red" }}>
                    {formErrorsOfferAcc["Remarks"]}
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-4">
                <div className="mb-1">
                  <label className="col-form-label">Recruiter remarks</label>
                  <InputForm
                    className="form-control"
                    placeholder={"Recruiter remarks"}
                    isDisabled={true}
                    textArea={true}
                    value={recruiterRemarks}
                    onChange={(event: any) => {}}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mb-3 pt-3"></div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn"
            style={{
              marginLeft: 5,
              color: "black !important",
              borderColor: "#acacac",
            }}
            disabled={disableOfferLetterModal}
            onClick={() => {
              closeOfferAccModal(true);
              setRecuiterRemark("");
            }}
          >
            Submit
          </button>
          <button
            className="btn btn-cancel"
            style={{
              marginLeft: 5,
              color: "black !important",
              borderColor: "#ACACAC",
            }}
            onClick={() => {
              closeOfferAccModal(false);
              setDisableOfferLetterModal(false);
              setRemark("");
              setRecuiterRemark("");
              setDecision(null);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AnonymousCandidate;

const HeaderComponent = ({ headerValues, profileImg = null, hideShowApplicationTab }) => {
  return (
    <>
      {headerValues && (
        <div className="row botpad">
          <div className="col-lg-2 col-md-2 col-sm-12">
            <div className="prof_img">
              {/* {headerValues && <img src={profileImg} />} */}
              <img
                src={profileImg != null && hideShowApplicationTab ? profileImg : defaultProfileImgPic}
                alt="Profile"
              />
            </div>
          </div>

          <div className="col-lg-10 col-md-10 col-sm-12 profData">
            <div className="name">
              <h4>
                {headerValues && (
                  <Tooltip title="Job Title">
                    <span>{`${headerValues?.mrjbttl}`}</span>
                  </Tooltip>
                )}
              </h4>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12 profDetail">
                <ul>
                  <li>
                    <p>
                      {headerValues && (
                        <Tooltip title="MRF Number">
                          <span>{`MRF #: ${headerValues?.mrF_NO}`}</span>
                        </Tooltip>
                      )}
                    </p>
                  </li>

                  <li>
                    {headerValues && headerValues?.legalEntity !== null && (
                      <Tooltip title="Legal Entity">
                        {headerValues.legalEntity}
                      </Tooltip>
                    )}
                  </li>
                  <li>
                    {headerValues && headerValues?.locationName !== null && (
                      <>
                        <i className="fa-solid fa-location-dot"></i>{" "}
                        <Tooltip title="Posting City">
                          {headerValues?.locationName}
                        </Tooltip>{" "}
                        {headerValues?.functionName !== null && (
                          <Tooltip title="Function">
                            <span>{`| ${headerValues?.functionName}`}</span>
                          </Tooltip>
                        )}
                      </>
                    )}
                  </li>
                  <li>
                    {headerValues && headerValues?.department !== null && (
                      <>
                        <i className="fa-solid fa-briefcase"></i>{" "}
                        <Tooltip title="Department">
                          {headerValues?.department}
                        </Tooltip>{" "}
                        <Tooltip title="Designation">
                          {headerValues?.designation !== null && (
                            <span>{`| ${headerValues?.designation}`}</span>
                          )}
                        </Tooltip>
                      </>
                    )}
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 profDetail">
                <ul>
                  <li>
                    <Tooltip title="CandidateName">
                      <span className="header">
                        <i className="fa-regular fa-address-card"></i>{" "}
                        {headerValues && headerValues.candidateName
                          ? headerValues.candidateName
                          : ""}
                      </span>
                    </Tooltip>
                  </li>
                  <li>
                    <Tooltip title="CandidateId">
                      <span className="header">
                        <i className="fa-regular fa-address-card"></i>{" "}
                        {headerValues && headerValues.candidateId != "" && (
                          <>{headerValues.candidateId}</>
                        )}
                      </span>
                    </Tooltip>
                  </li>
                  <li>
                    <Tooltip title="Phone Number">
                      <span className="header">
                        <i className="fa-solid fa-phone"></i>{" "}
                        {headerValues && headerValues.candidatePhone != "" && (
                          <>{headerValues.candidatePhone}</>
                        )}
                      </span>
                    </Tooltip>
                  </li>
                  <li>
                    <Tooltip title="Personal Email">
                      <span className="header">
                        <i className="fa-solid fa-envelope"></i>{" "}
                        {headerValues && headerValues.candidateEmail != "" && (
                          <>{headerValues.candidateEmail}</>
                        )}
                      </span>
                    </Tooltip>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const TabComponent = ({
  activetabIndex,
  tabs,
  isAnonymousCandidate,
  activetab,
  setActivetab,
}) => {
  const defaultTheme = createTheme({});
  const { breakpoints } = defaultTheme;

  const tabsTheme = {
    ...defaultTheme,
    components: {
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: "0px !important",
            marginLeft: "-42px",
            [breakpoints.down("md")]: {
              minHeight: "0px !important",
              marginLeft: "0px",
            },
          },
        },
      },
    },
  };

  return (
    <>
      <ThemeProvider theme={tabsTheme}>
        <Tabs
          value={activetabIndex}
          className="profile-tabs"
          onChange={() => {}}
          variant="scrollable"
          TabScrollButtonProps={{
            style: {
              color: "white",
            },
          }}
          scrollButtons
          TabIndicatorProps={{
            style: { display: "none" },
          }}
          allowScrollButtonsMobile
          aria-label="scrollable tabs"
        >
          <div className="tabBtn">
            <ul>
              {isAnonymousCandidate === false && (
                <li>
                  <button
                    onClick={() => setActivetab("anonymouscanidatestatus")}
                    className={
                      "anonymouscanidatestatus" === activetab
                        ? "bttn active"
                        : "bttn border border-primary border-bottom-0"
                    }
                  >
                    Status
                  </button>
                </li>
              )}
              {tabs.length > 0 &&
                tabs.map((eachTab, index) => {
                  if (
                    String(eachTab.TN).trim().toLowerCase() !=
                      "agencyjobapplication" &&
                    String(eachTab.TN).trim().toLowerCase() !=
                      "agencycandidates"
                  ) {
                    return (
                      <li key={`${index}-${eachTab.TN}`}>
                        <button
                          onClick={() => {
                            setActivetab(eachTab.TN);
                            //setIsShowAllSection(true);
                          }}
                          disabled={
                            eachTab.TN === "AgencyCandidateApplicationForm"
                              ? true
                              : false
                          }
                          className={
                            eachTab.TN === activetab
                              ? "bttn active"
                              : "bttn border border-primary border-bottom-0"
                          }
                        >
                          {eachTab.TDN}
                        </button>
                      </li>
                    );
                  }
                })}
            </ul>
          </div>
        </Tabs>
      </ThemeProvider>
    </>
  );
};

function downloadBase64File(base64String: string, fileName: string) {
  const mimeType = inferMimeType(base64String);
  const blob = base64toBlob(base64String, mimeType);
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  URL.revokeObjectURL(url);
}

function inferMimeType(base64String: string): string {
  const dataUrl = `data:application/octet-stream;base64,${base64String}`;
  const typeInfo = /^data:(.*?);/.exec(dataUrl);

  if (typeInfo && typeInfo[1]) {
    return typeInfo[1];
  }

  return "application/octet-stream";
}
function base64toBlob(base64String: string, mimeType: string): Blob {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);

  return new Blob([byteArray], { type: mimeType });
}

interface JobSectionProps {
  JobOption: any[];
  isDisableApplication: boolean;
  selectedJob: any;
  setSelectedJob: (job: any) => void;
  setTId: (id: number) => void;
  setJobId: (id: number) => void;
}

const JobSection: React.FC<JobSectionProps> = ({
  JobOption,
  isDisableApplication,
  selectedJob,
  setSelectedJob,
  setTId,
  setJobId,
}) => (
  <div className="col-lg-3 col-md-4 col-sm-12">
    <label className="col-form-label">Job</label>
    <SelectForm
      isClearable
      isSearchable
      options={JobOption}
      placeholder="Job"
      isDisabled={isDisableApplication}
      value={selectedJob}
      getOptionValue={(option) => option.mrfId}
      onChange={(event) => {
        if (event != null) {
          setSelectedJob(event);
          setTId(event.mrfId);
          setJobId(event.value);
        }
      }}
      isMulti={false}
      noIndicator={false}
      noSeparator={false}
    />
  </div>
);

interface MRFSectionProps {
  statusGridOptions: any;
  dashboardData: any[];
  statusGridColumns: any[];
}

const MRFSection: React.FC<MRFSectionProps> = ({
  statusGridOptions,
  dashboardData,
  statusGridColumns,
}) => (
  <div className="col-lg-12 mb-3 pt-3">
    <label className="col-form-label px-3">MRF</label>
    <DynamicGrid
      options={statusGridOptions}
      data={dashboardData}
      columns={statusGridColumns}
    />
  </div>
);

interface OnBoardingSectionProps {
  statusGridOptions: any;
  dashboardData1: any[];
  statusGridColumns: any[];
}

const OnBoardingSection: React.FC<OnBoardingSectionProps> = ({
  statusGridOptions,
  dashboardData1,
  statusGridColumns,
}) => (
  <>
    <div className="col-lg-12 mb-3 pt-3" hidden={dashboardData1.length === 0}>
      <label className="col-form-label px-3">On Boarding</label>
    </div>
    <div className="col-lg-12 mb-3 pt-3" hidden={dashboardData1.length === 0}>
      <DynamicGrid
        options={statusGridOptions}
        data={dashboardData1}
        columns={statusGridColumns}
      />
    </div>
  </>
);

const JobDescription = ({
  formData,
  sectionValuesArray,
  setFormData,
  TId,
  CId,
  setCid,
  setTId,
  activetab,
  isShowAllSection,
  isDisableApplication,
  workflowTaskId,
  setWorkflowTaskId,
  setGetData,
  IsAnonymousCandidate,
  disableBeforeDeclaration,
  rejectCandidate,
  setRejectCandidate,
  isJobPostingDoneOnLinkdin,
}) => {
  return (
    <>
      {formData.S !== undefined &&
        formData.S.length > 0 &&
        sectionValuesArray?.length > 0 &&
        formData.S.map((eachSection, index) => {
          return (
            <React.Fragment key={`${index}-${eachSection.SN}`}>
              <SectionComponent
                formData={formData}
                setFormData={setFormData}
                section={eachSection}
                TId={TId}
                CId={CId}
                setCid={setCid}
                setTId={setTId}
                sectionValuesArray={sectionValuesArray}
                activetab={activetab}
                index={index}
                lastIndex={0}
                showAllSection={isShowAllSection}
                isDisableApplication={isDisableApplication}
                isIJP={false}
                workflowTaskId={workflowTaskId}
                setWorkflowTaskId={setWorkflowTaskId}
                setGetData={setGetData}
                sectionIndex={index}
                IsAnonymousCandidate={IsAnonymousCandidate}
                disableBeforeDeclaration={disableBeforeDeclaration}
                setDisableBeforeDeclaration={true}
                rejectCandidate={rejectCandidate}
                setRejectCandidate={setRejectCandidate}
                employeeId={0}
                isJobPostingDoneOnLinkdin={isJobPostingDoneOnLinkdin}
              />
              {/* )} */}
            </React.Fragment>
          );
        })}
    </>
  );
};
