import React, { useContext, useEffect, useState } from "react";
import { apiResp, cityData, stateData } from "./formData";
import ButtonForm from "../ButtonForm/ButtonForm";
import { useNavigate } from "react-router-dom";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tabs,
  Tooltip,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { ExpandMore, Label } from "@mui/icons-material";
import SelectForm from "../SelectForm/SelectForm";
import InputForm from "../InputForm/InputForm";
import DynamicGrid from "../DynamicGrid/DynamicGrid";
import uuid from "react-uuid";
import DateForm from "../DateForm/DateForm";
import moment from "moment";
import "./FormStructure.css";
import profileImg from "../../Assets/Images/Icon awesome-briefcase.png";
import { APICall } from "../../Helpers/APICalls";
import { LoaderContext } from "../../Helpers/Context/Context";
import {
  getAttributeValueDetailsV2,
  getDropdowns,
  getSectionDetails,
  getSectionDetailsV2,
  getTabsData,
  getSearchableDropdowns,
  postAttributeValueDetailsV2,
} from "../../Helpers/APIEndPoints/EndPoints";
import notify from "../../Helpers/ToastNotification";
import { useLocation } from "react-router-dom";
import RTE from "../../Components/RTE/RTE";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import useECStore from "../../Helpers/ZustandStore/ECStore";

const SectionComponent = ({
  TId,
  setTId,
  formData,
  setFormData,
  section: ogSection,
  sectionIndex,
  sectionValuesArray,
  COU,
}) => {
  const [hasValue, setHasValue] = useState(
    sectionValuesArray.filter((es) => es.sectionSystemName == ogSection.SN) ||
      null
  );
  const [section, setSection] = useState(ogSection);
  const [accordion, setAccordion] = useState(sectionIndex === 0 ? true : false);
  const [formErrors, setFormErrors] = useState<any>({});
  const [optionsObj, setOptionsObj] = useState<any>({});
  const [gridData, setGridData] = useState([]);
  const [PageSize, setPageSize] = useState(3);
  const [page, setPage] = useState(0);
  const [displayGrid, setDisplayGrid] = useState([]);
  const [gridColumns, setGridColumns] = useState([]);
  const [ogAttributeSet, setOgAttributeSet] = useState({});
  const [ogFilledAttributeSet, setOgFilledAttributeSet] = useState([]);
  const [currentGridId, setCurrentGridId] = useState(null);
  const [deleteGridId, setDeleteGridId] = useState(null);
  const [ShowAttrSet, setShowAttrSet] = useState(false);
  const [viewOnly, setViewOnly] = useState(false);
  // value Object for section
  const [attributesData, setAttributesData] = useState<any>({});

  const [attributeSetData, setAttributeSetData] = useState<any>({});

  const [attributeSetCode, setAttributeSetCode] = useState(null);
  const [isOnce, setIsOnce] = useState(true);
  const [isValidateOnce, setIsValidateOnce] = useState(true);
  const { userDetails, currentRoleId }: any = useUserContext();
  const [attributeValidation, setAttributeValidation] = useState<any>({});
  const [attributeSetValidation, setAttributeSetValidation] = useState<any>({});

  const [dataFilled, setDataFilled] = useState(false);
  const [multiDropdown, setMultiDropdown] = useState(true);

  const { showLoader, hideLoader } = useContext(LoaderContext);

  const [fillDDValuesForGrid, setFillDDValuesForGrid] = useState([]);
  const [showSkillsTabValues, setSkillsTabValues] = useState(['Worker', 'Associate']);
  const showSkillsTab = useECStore((data: any) => data.showSkillsTab);
  const setShowSkillsTab = useECStore((data: any) => data.addShowSkillsTab);

  const getDropdownsData = async (AC, PID) => {
    await showLoader();
    const dropdownData = await APICall(getDropdowns, "POST", {
      AC,
      PID,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    if (
      dropdownData.data !== null &&
      dropdownData.data !== undefined &&
      dropdownData.data.length > 0
    ) {
      await hideLoader();
      return dropdownData.data;
    } else {
      await hideLoader();
      return [];
    }
  };

  const [fileDemo, setFileDemo] = useState(null);
  useEffect(() => {
    //add attribute in attributesData

    let attributes = {};
    let attributeSet = {};
    const gridColumnsArr = [];
    let dropdownOptionsObj = {};

    section?.Attribute?.forEach(async (eachAttribute) => {
      if (eachAttribute.AT === "Attribute") {
        //change value according to dt
        setAttributesData((prev) => ({
          ...prev,
          [eachAttribute.AC]:
            eachAttribute.DT === "DropdownSingle" ||
            eachAttribute.DT === "Date" ||
            eachAttribute.DT === "SearchableDropdownSingle" ||
            eachAttribute.DT === "DropdownMultiple"
              ? null
              : eachAttribute.DT === "Text" ||
                eachAttribute.DT === "Number" ||
                eachAttribute.DT === "RichText"
              ? ""
              : eachAttribute.DT === "Checkbox"
              ? false
              : "",
        }));

        attributes = {
          ...attributes,
          [eachAttribute.AC]:
            eachAttribute.DT === "DropdownSingle" ||
            eachAttribute.DT === "Date" ||
            eachAttribute.DT === "SearchableDropdownSingle" ||
            eachAttribute.DT === "DropdownMultiple"
              ? null
              : eachAttribute.DT === "Text" ||
                eachAttribute.DT === "Number" ||
                eachAttribute.DT === "RichText"
              ? ""
              : eachAttribute.DT === "Checkbox"
              ? false
              : "",
        };

        //attributeValidation
        setAttributeValidation((prev) => ({
          ...prev,
          [eachAttribute.AC]: JSON.parse(eachAttribute.V),
        }));

        setAttributeValidation((prev) => ({
          ...prev,
          [eachAttribute.AC]: {
            ...prev[eachAttribute.AC],
            DD: eachAttribute.DD !== null ? JSON.parse(eachAttribute.DD) : null,
            isDisable: false,
          },
        }));

        if (
          eachAttribute.DT === "DropdownSingle" ||
          eachAttribute.DT === "DropdownMultiple"
        ) {
          // api call for each dropdown option

          // const options = await getDropdownsData(eachAttribute.AC, null);

          dropdownOptionsObj = {
            ...dropdownOptionsObj,
            [eachAttribute.AC]: eachAttribute.OPT,
          };
          if (eachAttribute.AC === "JPSTAS" && section.SN === "Job") {
            if (eachAttribute.OPT.length > 0) {
              setAttributesData((prev) => ({
                ...prev,
                [eachAttribute.AC]: eachAttribute.OPT.filter(
                  (x) => x.label.toLowerCase() === "draft"
                )[0],
              }));

              attributes = {
                ...attributes,
                [eachAttribute.AC]: eachAttribute.OPT.filter(
                  (x) => x.label.toLowerCase() === "draft"
                )[0],
              };
            }
          }

          setOptionsObj((prev) => ({
            ...prev,
            [eachAttribute.AC]: eachAttribute.OPT,
          }));
        } else if (eachAttribute.DT === "SearchableDropdownSingle") {
          dropdownOptionsObj = {
            ...dropdownOptionsObj,
            [eachAttribute.AC]: "SearchableDropdownSingle",
          };

          setOptionsObj((prev) => ({
            ...prev,
            [eachAttribute.AC]: "SearchableDropdownSingle",
          }));
        }
      } else if (eachAttribute.AT === "AttributeSet") {
        setAttributeSetCode(eachAttribute.AC);

        // Id for grid
        gridColumnsArr.push({
          name: "Id",
          label: "Id",
          options: { display: false },
        });

        eachAttribute.Attribute.forEach(async (subAttribute) => {
          let display = JSON.parse(subAttribute.AD);
          display = display.ISOG.toLowerCase() === "true" ? true : false;

          //grid columns
          gridColumnsArr.push({
            name: subAttribute.AC,
            label: subAttribute.AN,
            options: { sort: false, display },
          });

          //attributeSetValidation
          setAttributeSetValidation((prev) => ({
            ...prev,
            [subAttribute.AC]: JSON.parse(subAttribute.V),
          }));

          //change value according to dt

          setAttributeSetData((prev) => ({
            ...prev,
            [subAttribute.AC]:
              subAttribute.DT === "DropdownSingle" ||
              subAttribute.DT === "Date" ||
              subAttribute.DT === "SearchableDropdownSingle" ||
              subAttribute.DT === "DropdownMultiple"
                ? null
                : subAttribute.DT === "Text" ||
                  subAttribute.DT === "Number" ||
                  eachAttribute.DT === "RichText"
                ? ""
                : subAttribute.DT === "Checkbox"
                ? false
                : "",
          }));

          attributeSet = {
            ...attributeSet,
            [subAttribute.AC]:
              subAttribute.DT === "DropdownSingle" ||
              subAttribute.DT === "Date" ||
              subAttribute.DT === "SearchableDropdownSingle" ||
              subAttribute.DT === "DropdownMultiple"
                ? null
                : subAttribute.DT === "Text" ||
                  subAttribute.DT === "Number" ||
                  eachAttribute.DT === "RichText"
                ? ""
                : subAttribute.DT === "Checkbox"
                ? false
                : "",
          };

          if (
            subAttribute.DT === "DropdownSingle" ||
            subAttribute.DT === "DropdownMultiple"
          ) {
            // const options = await getDropdownsData(subAttribute.AC, null);
            dropdownOptionsObj = {
              ...dropdownOptionsObj,
              [subAttribute.AC]: subAttribute.OPT,
            };
            //console.log('SubAttr: ', subAttribute.AC, 'SubAttr Opt: ', subAttribute.OPT);
            //console.log('DD: ', dropdownOptionsObj);
            setOptionsObj((prev) => ({
              ...prev,
              [subAttribute.AC]: subAttribute.OPT,
            }));
          } else if (subAttribute.DT === "SearchableDropdownSingle") {
            dropdownOptionsObj = {
              ...dropdownOptionsObj,
              [subAttribute.AC]: "SearchableDropdownSingle",
            };

            setOptionsObj((prev) => ({
              ...prev,
              [subAttribute.AC]: "SearchableDropdownSingle",
            }));
          }
        });
      }
    });

    // setAttributesData((prev) => ({
    //   ...prev,
    //   ...attributes,
    // }));

    // setAttributeSetData((prev) => ({
    //   ...prev,
    //   ...attributeSet,
    // }));

    setOgAttributeSet(attributeSet);
    setGridColumns((prev) => [...prev, ...gridColumnsArr, action]);

    // setOptionsObj((prev) => ({ ...prev, ...dropdownOptionsObj }));

    const fillData = async () => {
      await showLoader();
      if (isOnce && TId !== 0) {
        //fetch attributes/set data

        //check if not first time then call api
        let attributesRes = null;
        // let bodyObj = {
        //   mn: "JobProfile",
        //   in: "JobProfile_Form",
        //   tn: formData.tn,
        //   SN: section.SN,
        //   TID: TId,
        //   ISH: "false",
        // };

        // const response = await APICall(
        //   getAttributeValueDetailsV2,
        //   "POST",
        //   bodyObj
        // );
        console.log("Section: ", section.SN);
        if (hasValue.length > 0 && hasValue[0].attributes.length > 0) {
          attributesRes = hasValue[0].attributes;
          // handle data

          if (attributesRes !== null && attributesRes.length > 0) {
            attributesRes.forEach(async (eachAttributeRes) => {
              if (eachAttributeRes.type === "Attribute") {
                //Attributes

                if (
                  Object.keys(attributes).length > 0 &&
                  eachAttributeRes.details === null
                ) {
                  let newAttributeData = { ...attributes };
                  let dropdownOptions = { ...dropdownOptionsObj };

                  Object.keys(newAttributeData).forEach(async (attribute) => {
                    if (
                      Object.keys(dropdownOptions).find(
                        (option) =>
                          option.toLowerCase() === attribute.toLowerCase()
                      ) &&
                      dropdownOptions[attribute] !== "SearchableDropdownSingle"
                    ) {
                      const valueForDropdown = attributesRes.find(
                        (attri) => attri.key === attribute
                      )?.value;
                      if (
                        attribute === "JBRELINDS" ||
                        attribute === "JBEMPCON" || 
                        attribute === 'JBEMPCLS'
                      ) {
                        const options = dropdownOptions[attribute];
                        if (options !== undefined && options.length > 0) {
                          const arr = valueForDropdown?.split(",");
                          let arrayShow = [];
                          if(arr){
                            arr.map((vals) => {
                              const option = options.find(
                                (attri) => attri.value == vals
                              );
                              if (option !== undefined || option !== null) {
                                arrayShow.push(option);
                              }
                            });
                          }

                          newAttributeData = {
                            ...newAttributeData,
                            [attribute]:
                              arrayShow.length > 0 ? arrayShow : null,
                          };
                        }
                      } else {
                        console.log(valueForDropdown, "=>valueForDropdown");

                        const options = dropdownOptions[attribute];
                        //console.log(options);
                        if (options !== undefined && options.length > 0) {
                          const option = options.find(
                            (attri) => attri.value == valueForDropdown
                          );
                          //console.log(option);

                          newAttributeData = {
                            ...newAttributeData,
                            [attribute]: option === undefined ? null : option,
                          };
                        }
                      }
                    } else if (
                      Object.keys(dropdownOptions).find(
                        (option) =>
                          option.toLowerCase() === attribute.toLowerCase()
                      ) &&
                      dropdownOptions[attribute] === "SearchableDropdownSingle"
                    ) {
                      const valueForDropdown = attributesRes.find(
                        (attri) => attri.key === attribute
                      )?.value;
                      //console.log(valueForDropdown);
                      await getSearchableDropdownAPI(
                        "",
                        attribute,
                        null,
                        valueForDropdown,
                        "attribute",
                        0
                      );
                    } else {
                      let valueForField = attributesRes.find(
                        (attri) => attri.key === attribute
                      )?.value;
                      //check type later when actual data

                      valueForField =
                        typeof valueForField === "string" &&
                        valueForField.toLowerCase() === "true"
                          ? true
                          : typeof valueForField === "string" &&
                            valueForField.toLowerCase() === "false"
                          ? false
                          : valueForField !== null
                          ? valueForField?.toString()
                          : valueForField;

                      // format date

                      const dateString = valueForField;
                      const allowedFormat = [
                        "DD-MM-YYYY hh.mm.ss A",
                        "DD/MM/YYYY hh.mm.ss A",
                      ];

                      const isValidDate = moment(
                        dateString,
                        allowedFormat,
                        true
                      ).isValid();
                      if (
                        isValidDate &&
                        dateString !== "01-01-0001 12.00.00 AM" &&
                        dateString !== "1/1/0001 12:00:00 AM"
                      ) {
                        const parsedDate = moment(
                          valueForField,
                          allowedFormat[0]
                        );
                        const formattedDate = parsedDate.format("DD-MMM-YYYY");
                        valueForField = formattedDate;
                      } else if (
                        dateString === "01-01-0001 12.00.00 AM" ||
                        dateString === "1/1/0001 12:00:00 AM"
                      ) {
                        valueForField = null;
                      }

                      newAttributeData = {
                        ...newAttributeData,
                        [attribute]: valueForField,
                      };
                    }
                  });

                  setAttributesData(newAttributeData);
                }
                // setIsOnce(false);
              } else if (eachAttributeRes.type === "AttributeSet") {
                //AttributesSet

                if (
                  Object.keys(attributeSet).length > 0 &&
                  eachAttributeRes.details !== null &&
                  eachAttributeRes.details.length > 0
                ) {
                  setAttributeSetCode(eachAttributeRes.key);

                  const details = eachAttributeRes.details;
                  //console.log('Details ', details)

                  if (details.length > 0) {
                    let newAttributeSetData = { ...attributeSet };
                    let newAttributeSetDataForGrid = { ...attributeSet };
                    let dropdownOptions = { ...dropdownOptionsObj };

                    const filledData = [];
                    const gridData = [];
                    details.forEach(async (detail) => {
                      detail.IsActive = true;

                      Object.entries(detail).forEach(async (attribute) => {
                        const [Code, Value]: any = attribute;
                        if (
                          Object.keys(dropdownOptions).find(
                            (option) =>
                              option.toLowerCase() === Code.toLowerCase()
                          ) &&
                          dropdownOptions[Code] !== "SearchableDropdownSingle"
                        ) {
                          if (Code === "JRCCOPCY") {
                            const options = dropdownOptions[Code];
                            if (options !== undefined && options.length > 0) {
                              const arr = Value.split(",");
                              let arrayShow = [];
                              arr.map((vals) => {
                                const option = options.find(
                                  (attri) => attri.value == vals
                                );
                                if (option !== undefined || option !== null) {
                                  arrayShow.push(option);
                                }
                              });

                              newAttributeSetDataForGrid = {
                                ...newAttributeSetDataForGrid,
                                [Code]:
                                  arrayShow.length > 0
                                    ? arrayShow
                                        .map((item) => item.label)
                                        .join(", ")
                                    : null,
                              };

                              newAttributeSetData = {
                                ...newAttributeSetData,
                                [Code]: arrayShow.length > 0 ? arrayShow : null,
                              };
                            }
                          } else {
                            const options = dropdownOptions[Code];
                            if (options !== undefined && options.length > 0) {
                              const option = options.find(
                                (attri) => attri.value == Value
                              );

                              newAttributeSetDataForGrid = {
                                ...newAttributeSetDataForGrid,
                                [Code]:
                                  option === undefined ? null : option?.label,
                              };

                              newAttributeSetData = {
                                ...newAttributeSetData,
                                [Code]: option === undefined ? null : option,
                              };
                            }
                          }
                        }
                        // else if (
                        //   Object.keys(dropdownOptions).find(
                        //     (option) =>
                        //       option.toLowerCase() === Code.toLowerCase()
                        //   ) &&
                        //   dropdownOptions[Code] === "DropdownMultiple"
                        else if (
                          Object.keys(dropdownOptions).find(
                            (option) =>
                              option.toLowerCase() === Code.toLowerCase()
                          ) &&
                          dropdownOptions[Code] === "SearchableDropdownSingle"
                        ) {
                          await getSearchableDropdownAPI(
                            "",
                            Code,
                            null,
                            Value,
                            "attributeSet",
                            detail.Id
                          );
                        } else {
                          //check type later when actual data

                          let valueForGrid =
                            typeof Value === "string" &&
                            Value.toLowerCase() === "true"
                              ? true
                              : typeof Value === "string" &&
                                Value.toLowerCase() === "false"
                              ? false
                              : Value !== null
                              ? Value.toString()
                              : Value;

                          // format date

                          const dateString = Value;
                          const allowedFormat = [
                            "DD-MM-YYYY hh.mm.ss A",
                            "DD/MM/YYYY hh.mm.ss A",
                          ];

                          const isValidDate = moment(
                            dateString,
                            allowedFormat,
                            true
                          ).isValid();
                          if (
                            isValidDate &&
                            dateString !== "01-01-0001 12.00.00 AM" &&
                            dateString !== "1/1/0001 12:00:00 AM"
                          ) {
                            const parsedDate = moment(Value, allowedFormat[0]);
                            const formattedDate =
                              parsedDate.format("DD-MMM-YYYY");
                            valueForGrid = formattedDate;
                          } else if (
                            dateString === "01-01-0001 12.00.00 AM" ||
                            dateString === "1/1/0001 12:00:00 AM"
                          ) {
                            valueForGrid = null;
                          }

                          let valueForField =
                            typeof Value === "string" &&
                            Value.toLowerCase() === "true"
                              ? true
                              : typeof Value === "string" &&
                                Value.toLowerCase() === "false"
                              ? false
                              : typeof Value === "boolean"
                              ? Value
                              : Value !== null
                              ? Value.toString()
                              : Value;

                          if (
                            isValidDate &&
                            dateString !== "01-01-0001 12.00.00 AM" &&
                            dateString !== "1/1/0001 12:00:00 AM"
                          ) {
                            const parsedDate = moment(Value, allowedFormat[0]);
                            const formattedDate =
                              parsedDate.format("DD-MMM-YYYY");
                            valueForField = formattedDate;
                          } else if (
                            dateString === "01-01-0001 12.00.00 AM" ||
                            dateString === "1/1/0001 12:00:00 AM"
                          ) {
                            valueForField = null;
                          }

                          newAttributeSetDataForGrid = {
                            ...newAttributeSetDataForGrid,
                            [Code]: valueForGrid,
                          };

                          newAttributeSetData = {
                            ...newAttributeSetData,
                            [Code]: valueForField,
                          };
                        }
                      });
                      gridData.push(newAttributeSetDataForGrid);
                      filledData.push(newAttributeSetData);
                    });

                    // console.log(filledData);
                    // console.log(gridData);
                    setDisplayGrid(gridData);
                    setGridData(gridData);
                    setOgFilledAttributeSet(filledData);
                  }
                  // setIsOnce(false);
                }
              }
            });
          }
        }
        setIsOnce(false);
      }
      await hideLoader();
    };

    // const fillTimer = setTimeout(() => {
    //   clearTimeout(fillTimer);
    fillData();
    // }, 2000);

    //save in state
    // return () => {
    //   clearTimeout(fillTimer);
    // };
  }, []);

  // console.log(attributeSetValidation);

  // console.log(gridColumns);

  // console.log(attributesData);

  // console.log(attributeSetData);

  // console.log(optionsObj);

  // console.log(ogFilledAttributeSet);
  // console.log(gridData);

  // useEffect(() => {}, [attributesData, attributeSetData, optionsObj]);

  useEffect(() => {
    if (
      attributesData !== undefined &&
      Object.keys(attributesData).length > 0 &&
      attributeValidation !== undefined &&
      Object.keys(attributeValidation).length > 0
    ) {
      if (
        (isValidateOnce && isOnce === false && TId !== 0) ||
        (isValidateOnce && isOnce === true && TId === 0)
      ) {
        // console.log(attributeValidation);

        let attributeValues = { ...attributesData }; // for checking values
        let attributeValObj = { ...attributeValidation };
        Object.keys(attributeValObj).forEach(async (attribute) => {
          if (
            attributeValObj[attribute].DD !== null &&
            attributeValObj[attribute].DD.length > 0
          ) {
            let ad = attributeValObj[attribute].DD;

            ad.forEach(async (record) => {
              //   CCode: "POLENDT",
              // PValue: "false",
              // Condition: "equalTo",
              // Flag: "disable",
              // PDT: "checkbox",
              // CDT: "checkbox",
              let CCode = record.CCode;
              let PValue = record.PValue;
              let Condition = record.Condition;
              let Flag = record.Flag;
              let PDT = record.PDT;
              let CDT = record.CDT;

              if (
                attributeValues[attribute].toString().toLowerCase() ===
                  PValue.toLowerCase() ||
                attributeValues[attribute].toString().toLowerCase() === ""
              ) {
                if (Flag === "disable") {
                  attributeValObj[CCode].isDisable = true;
                  attributeValObj[CCode].ISM = "false";
                }
              } else if (
                attributeValues[attribute].toString().toLowerCase() !==
                PValue.toLowerCase()
              ) {
                if (Flag === "disable") {
                  attributeValObj[CCode].isDisable = false;
                  attributeValObj[CCode].ISM = "true";
                }
              }
            });
          }
        });
        setAttributeValidation((prev) => ({ ...prev, ...attributeValObj }));
        setIsValidateOnce(false);
      }
    }
  }, [attributesData, isOnce]);

  useEffect(() => {
    if(attributesData && attributesData['JBEMPCLS'] && attributesData['JBEMPCLS'].length > 0){
      //let isWorkerSkillSelected = false;
      let workerSkills = [];
      let nonWorkerSkills = [];
      attributesData['JBEMPCLS'].forEach(item => {
        if(showSkillsTabValues.some(x => x === item.code)){
          workerSkills.push(item);
        } else{
          nonWorkerSkills.push(item);
        }
      });

      if(workerSkills.length > 0 && nonWorkerSkills.length > 0){
        // worker and non worker skills selected
        // show both the sections
        setShowSkillsTab({workerSkillSelected: true, nonWorkerSkillSelected: true});
      } else if(workerSkills.length > 0){
        // show only worker skills sections
        setShowSkillsTab({workerSkillSelected: true, nonWorkerSkillSelected: false});
      } else if(nonWorkerSkills.length > 0){
        // show only non worker skills sections
        setShowSkillsTab({workerSkillSelected: false, nonWorkerSkillSelected: true});
      }
    }
  }, [attributesData['JBEMPCLS']])

  const action = {
    name: "Id",
    label: "Action",
    options: {
      filter: false,
      sort: false,
      display: true,
      setCellProps: () => ({
        style: { textAlign: "center" },
      }),
      setCellHeaderProps: () => ({
        style: { textAlign: "center" },
      }),
      customBodyRender: (value, tableMeta) => {
        let Id = tableMeta.tableData[tableMeta.rowIndex].Id;

        return (
          <div className="d-flex justify-content-center">
            {section?.ISView && (
              <Tooltip title="view">
                <a
                  className="mr-2"
                  onClick={(e) => {
                    e.preventDefault();
                    setFormErrors({});
                    setMultiDropdown(false);
                    setShowAttrSet(true);
                    setViewOnly(true);
                    setCurrentGridId(Id);
                  }}
                >
                  <i className="fa-solid fa-eye"></i>
                </a>
              </Tooltip>
            )}
            {section.ISEDIT && (
              <Tooltip title="edit">
                <a
                  className="mr-2"
                  onClick={(e) => {
                    e.preventDefault();
                    setFormErrors({});
                    setMultiDropdown(false);
                    setShowAttrSet(true);
                    setViewOnly(false);
                    setCurrentGridId(Id);
                  }}
                >
                  <i className="fas fa-edit"></i>
                </a>
              </Tooltip>
            )}
            {section.ISDEL && (
              <Tooltip title="delete">
                <a
                  className="mr-2"
                  onClick={(e) => {
                    e.preventDefault();
                    setFormErrors({});
                    setDeleteGridId(Id);
                  }}
                >
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </a>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  };

  useEffect(() => {
    if (currentGridId) {
      let attributeSetData = ogFilledAttributeSet.find(
        (record) => record.Id === currentGridId
      );

      setAttributeSetData(attributeSetData);
    }
  }, [currentGridId]);

  useEffect(() => {
    if (deleteGridId && currentGridId === null) {
      //to disable grid delete button when edit is active
      setGridData((prev) => {
        const newState = prev.map((record) => {
          if (record.Id === deleteGridId) {
            return {
              ...record,
              IsActive: false,
            };
          }
          return record;
        });
        return newState;
      });

      setOgFilledAttributeSet((prev) => {
        const newState = prev.map((record) => {
          if (record.Id === deleteGridId) {
            return {
              ...record,
              IsActive: false,
            };
          }
          return record;
        });
        return newState;
      });

      setDisplayGrid((prev) =>
        prev.filter((record) => record.Id !== deleteGridId)
      );
    }
    setDeleteGridId(null);
  }, [deleteGridId]);

  const gridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    // count: gridData.length,
    rowsPerPage: PageSize,
    page: page,
    serverSide: false,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",

    //standard | vertical | simple
    onChangeRowsPerPage: (num) => {
      //   setLimit(num);
      //   setNxtPgInfo("");
      //   setPrevPgInfo("");
      //   setIsPrevOrNext("");
    },
    // onSearchChange: (searchText) => {
    //   if (searchText !== null) {
    //     setSearchText(searchText);
    //   } else {
    //     setSearchText("");
    //   }
    // },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      //setPage(0);
      if (sortDirection === "asc") {
        // await setDashboardSortColumn(sortColumn);
        // await setDashboardSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        // await setDashboardSortColumn(sortColumn);
        // await setDashboardSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      await setPage(page);
      // await setDashboardStart(page * dashboardPageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const dependentValidations = (targetValue, targetAttributeCode) => {
    let attributeValues = { ...attributesData }; // for checking values
    let attributeValObj = { ...attributeValidation };

    if (
      attributeValObj[targetAttributeCode].DD !== null &&
      attributeValObj[targetAttributeCode].DD.length > 0
    ) {
      let ad = attributeValObj[targetAttributeCode].DD;

      ad.forEach(async (record) => {
        let CCode = record.CCode;
        let PValue = record.PValue;
        let Condition = record.Condition;
        let Flag = record.Flag;
        let PDT = record.PDT;
        let CDT = record.CDT;

        if (targetValue.toString().toLowerCase() === PValue.toLowerCase()) {
          //check condition
          if (Flag === "disable") {
            attributeValObj[CCode].isDisable = true;
            attributeValObj[CCode].ISM = "false";
          }
        } else {
          attributeValObj[CCode].isDisable = false;
          attributeValObj[CCode].ISM = "true";
        }

        //clear values based to CDT
        attributeValues[CCode] =
          CDT === "DropdownSingle" ||
          CDT === "Date" ||
          CDT === "SearchableDropdownSingle"
            ? null
            : CDT === "Text" || CDT === "Number"
            ? ""
            : CDT === "Checkbox"
            ? false
            : "";
      });

      attributeValues[targetAttributeCode] = targetValue; //imp
      setFormErrors({});
      setAttributeValidation((prev) => ({ ...prev, ...attributeValObj }));
      setAttributesData((prev) => ({
        ...prev,
        ...attributeValues,
      }));
    }
  };

  const validateData = (Attribute, sectionName) => {
    let errorObj = {};
    let isError = false;
    Attribute.forEach(async (subAttribute) => {
      if (subAttribute.AT === "Attribute") {
        const validation = JSON.parse(subAttribute.V);

        if (validation.ISM.toLowerCase() === "true") {
          if (subAttribute.DT === "Text") {
            if (attributeSetData[subAttribute.AC] === "") {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else if (
              attributeSetData[subAttribute.AC]?.length > validation.MaxC
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: `Max ${validation.MaxC} characters allowed`,
              };
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          } else if (
            subAttribute.DT === "DropdownSingle" ||
            subAttribute.DT === "SearchableDropdownSingle"
          ) {
            if (attributeSetData[subAttribute.AC] === null) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          } else if (subAttribute.DT === "Date") {
            if (attributeSetData[subAttribute.AC] === null) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else if (
              moment(
                validation.MaxV === "CurrentDate" &&
                  attributeSetData[subAttribute.AC]
              ).isAfter(new Date())
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Only till current date allowed",
              };
            } else if (
              moment(
                validation.MinV === "CurrentDate" &&
                  attributeSetData[subAttribute.AC]
              ).isBefore()
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Only after current date allowed",
              };
            } else if (
              validation.MinV !== undefined &&
              validation.MinV !== "CurrentDate" &&
              validation.MinV !== ""
            ) {
              let parentValue = attributeSetData[validation.MinV];
              if (
                parentValue !== null &&
                parentValue !== "" &&
                attributeSetData[subAttribute.AC] !== null &&
                attributeSetData[subAttribute.AC] !== "" &&
                moment(attributeSetData[subAttribute.AC]).isBefore(parentValue)
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Past date not allowed",
                };
              } else if (
                (parentValue === null || parentValue === "") &&
                attributeSetData[subAttribute.AC] !== null &&
                attributeSetData[subAttribute.AC] !== ""
              ) {
                errorObj = {
                  ...errorObj,
                  [validation.MinV]: "Required",
                };
              }
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          } else if (subAttribute.DT === "Number") {
            //change with regex
            if (attributeSetData[subAttribute.AC] === "") {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else if (
              // isNaN(
              //   attributeSetData[subAttribute.AC]
              // )
              !/^([0-9]+|0)$/.test(attributeSetData[subAttribute.AC])
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Only numbers are allowed",
              };
            } else if (
              Number(attributeSetData[subAttribute.AC]) > validation.MaxV
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: `Max ${validation.MaxV} allowed`,
              };
            } else if (
              Number(attributeSetData[subAttribute.AC]) < validation.MinV
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: `Min ${validation.MinV} required`,
              };
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          }
        } else {
          // for num and date

          if (subAttribute.DT === "Number") {
            if (attributeSetData[subAttribute.AC] !== "") {
              //change with regex
              if (
                // isNaN(
                //   attributeSetData[
                //     subAttribute.AC
                //   ]
                // )
                !/^([0-9]+|0)$/.test(attributeSetData[subAttribute.AC])
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Only numbers are allowed",
                };
              } else if (
                Number(attributeSetData[subAttribute.AC]) > validation.MaxV
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: `Max ${validation.MaxV} allowed`,
                };
              } else if (
                Number(attributeSetData[subAttribute.AC]) < validation.MinV
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: `Min ${validation.MinV} required`,
                };
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }
            }
          } else if (subAttribute.DT === "Date") {
            if (attributeSetData[subAttribute.AC] !== null) {
              if (
                moment(
                  validation.MaxV === "CurrentDate" &&
                    attributeSetData[subAttribute.AC]
                ).isAfter(new Date())
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Only till current date allowed",
                };
              } else if (
                moment(
                  validation.MinV === "CurrentDate" &&
                    attributeSetData[subAttribute.AC]
                ).isBefore()
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Only after current date allowed",
                };
              } else if (
                validation.MinV !== undefined &&
                validation.MinV !== "CurrentDate" &&
                validation.MinV !== ""
              ) {
                let parentValue = attributeSetData[validation.MinV];
                if (
                  parentValue !== null &&
                  parentValue !== "" &&
                  attributeSetData[subAttribute.AC] !== null &&
                  attributeSetData[subAttribute.AC] !== "" &&
                  moment(attributeSetData[subAttribute.AC]).isBefore(
                    parentValue
                  )
                ) {
                  errorObj = {
                    ...errorObj,
                    [subAttribute.AC]: "Past date not allowed",
                  };
                } else if (
                  (parentValue === null || parentValue === "") &&
                  attributeSetData[subAttribute.AC] !== null &&
                  attributeSetData[subAttribute.AC] !== ""
                ) {
                  errorObj = {
                    ...errorObj,
                    [validation.MinV]: "Required",
                  };
                }
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }
            }
          }
        }
      }
    });

    const isEmpty = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0) {
      if(isEmpty){
        if(sectionName === 'JobSkills'){
          let MasterMinValue = attributeSetData['JBPRMIN'];
          let MasterMaxValue = attributeSetData['JBPRMAX'];
          let ExpertMinValue = attributeSetData['JBPREMIN'];
          let ExpertMaxValue = attributeSetData['JBPREMAX'];
          let BeginnerMinValue = attributeSetData['JBPRBMIN'];
          let BeginnerMaxValue = attributeSetData['JBPRBMAX'];
          let LearnerMinValue = attributeSetData['JBPRLMIN'];
          let LearnerMaxValue = attributeSetData['JBPRLMAX'];
    
          let masterMinMaxRange = {min:MasterMinValue , max: MasterMaxValue};
            let expertMinMaxRange = {min: ExpertMinValue, max: ExpertMaxValue};
            let beginnerMinMaxRange = {min: BeginnerMinValue, max: BeginnerMaxValue};
            let learnerMinMaxRange = {min:LearnerMinValue, max: LearnerMaxValue};
    
            let ranges = [masterMinMaxRange, expertMinMaxRange, beginnerMinMaxRange, learnerMinMaxRange];
          
            const parsedRanges: [number, number][] = ranges.map(range => {
              const min = parseInt(range.min);
              const max = range.max === '' ? Infinity : parseInt(range.max);
              return [min, max];
          });
      
          // Sort ranges based on maximum values
          const sortedRanges = parsedRanges.slice().sort((a, b) => a[1] - b[1]);
      
          // Check for overlapping ranges
          for (let i = 1; i < sortedRanges.length; i++) {
              if (sortedRanges[i][0] <= sortedRanges[i - 1][1]) {
                isError = true;
                notify(1, "Ranges are not valid");
                  return isError; 
              } else if(sortedRanges[i][0] === sortedRanges[i][1]){
                isError = true;
                notify(1, "Min and Max values can not be the same.");
                  return isError; 
              }
          } 
          isError = false; 
        }
        return isError;
        
      } else{
        isError = true;
        notify(1, `${section.SDN} - Please check validation errors!`);
        setFormErrors((err) => ({
        ...err,
        ...errorObj,
        }));
        return isError;
      }
    }
  };

  const getSearchableDropdownAPI = async (
    searchString,
    AC,
    cb,
    valueForDropdown,
    type,
    gridId
  ) => {
    if (
      searchString === "" &&
      cb === null &&
      valueForDropdown !== "" &&
      AC !== ""
    ) {
      const response = await APICall(getSearchableDropdowns, "POST", {
        searchString,
        AC,
        valueForDropdown,
      });
      if (response.data !== null && response.data.length > 0) {
        if (response.data.length === 1) {
          if (gridId !== 0) {
            let ddObj = {
              gridId,
              AC,
              DDResponse: response.data[0],
            };
            setFillDDValuesForGrid((prev) => [...prev, ddObj]);
          } else {
            fillSearchableDDValuesOnEdit(response.data[0], AC);
          }
        }
      }
    } else if (searchString.length > 1) {
      const response = await APICall(getSearchableDropdowns, "POST", {
        searchString,
        AC,
      });
      if (response.data !== null && response.data.length > 0) {
        cb(response.data);
        setOptionsObj((prev) => ({ ...prev, [AC]: response.data }));
      } else {
        cb([]);
        setOptionsObj((prev) => ({ ...prev, [AC]: [] }));
      }
    }
  };

  useEffect(() => {
    if (
      fillDDValuesForGrid.length > 0 &&
      fillDDValuesForGrid.length === gridData.length
    ) {
      fillDDValuesForGrid.forEach((ddValues) => {
        setGridData((current) =>
          current.map((obj) => {
            if (obj.Id == ddValues.gridId) {
              return { ...obj, [ddValues.AC]: ddValues.DDResponse.label };
            }

            return obj;
          })
        );

        setDisplayGrid((current) =>
          current.map((obj) => {
            if (obj.Id == ddValues.gridId) {
              return { ...obj, [ddValues.AC]: ddValues.DDResponse.label };
            }

            return obj;
          })
        );

        setOgFilledAttributeSet((current) =>
          current.map((obj) => {
            if (obj.Id == ddValues.gridId) {
              return {
                ...obj,
                [ddValues.AC]: {
                  label: ddValues.DDResponse.label,
                  value: ddValues.DDResponse.value,
                },
              };
            }

            return obj;
          })
        );
      });
      setFillDDValuesForGrid([]);
    }
  }, [fillDDValuesForGrid, gridData]);

  //Searchable Dropdown for Attributes
  const searchableDDValues = (event, AC) => {
    let DD = event.DD;
    if (DD.length > 0) {
      let newAttributeData = { ...attributesData };
      let dropdownOptions = { ...optionsObj };

      DD.forEach((attribute) => {
        const Code = attribute.Key;

        // check DT

        if (
          attribute.DT === "DropdownSingle" ||
          attribute.DT === "DropdownMultiple"
        ) {
          const options = dropdownOptions[Code];

          if (options !== undefined && options.length > 0) {
            const option = options.find(
              (attri) => attri.value == attribute.Value
            );

            newAttributeData = {
              ...newAttributeData,
              [Code]: option === undefined ? null : option,
            };
          }
        } else {
          //check all DT
          newAttributeData = {
            ...newAttributeData,
            [Code]: attribute.Value,
          };
        }
      });
      newAttributeData[AC] = event; //imp

      setAttributesData(newAttributeData);
    }
  };

  //Searchable Dropdown for Attributes Set
  const searchableDDValuesSet = (event, AC) => {
    let DD = event.DD;
    if (DD.length > 0) {
      let newAttributeSetData = { ...attributeSetData };
      let dropdownOptions = { ...optionsObj };

      DD.forEach((attribute) => {
        const Code = attribute.Key;

        // check DT

        if (
          attribute.DT === "DropdownSingle" ||
          attribute.DT === "DropdownMultiple"
        ) {
          const options = dropdownOptions[Code];

          if (options !== undefined && options.length > 0) {
            const option = options.find(
              (attri) => attri.value == attribute.Value
            );

            newAttributeSetData = {
              ...newAttributeSetData,
              [Code]: option === undefined ? null : option,
            };
          }
        } else {
          //check all DT
          newAttributeSetData = {
            ...newAttributeSetData,
            [Code]: attribute.Value,
          };
        }
      });
      newAttributeSetData[AC] = event; //imp

      setAttributeSetData(newAttributeSetData);
    }
  };

  const fillSearchableDDValuesOnEdit = (DDResponse, AC) => {
    setAttributesData((prev) => ({
      ...prev,
      [AC]: { label: DDResponse.label, value: DDResponse.value },
    }));
  };

  return (
    <div className="col-lg-12">
      {section?.Attribute?.length > 0 && (
        <Accordion
          elevation={0}
          className="mb-3"
          expanded={accordion}
          onChange={() => setAccordion((prev) => !prev)}
        >
          <AccordionSummary
            id="profile-accordion"
            style={{ background: "#3C5464" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}
          >
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <p style={{ fontSize: "16px" }}>{section.SDN}</p>
              </div>
              <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12">
                {/* <Tooltip title="clear">
                  <button
                    className="btn ml-lg-0 ml-md-0 ml-1"
                    disabled={!section.ISEDIT}
                    onClick={(e) => {
                      e.stopPropagation();
                      //clear data

                      section.Attribute.forEach(async (eachAttribute) => {
                        if (eachAttribute.AT === "Attribute") {
                          //change value according to dt

                          setAttributesData((prev) => ({
                            ...prev,
                            [eachAttribute.AC]:
                              eachAttribute.DT === "DropdownSingle" ||
                              eachAttribute.DT === "Date" ||
                              eachAttribute.DT === "DropdownSingle"
                                ? null
                                : eachAttribute.DT === "Text" ||
                                  eachAttribute.DT === "Number"
                                ? ""
                                : eachAttribute.DT === "Checkbox"
                                ? false
                                : "",
                          }));
                        } else if (eachAttribute.AT === "AttributeSet") {
                          setAttributeSetData(ogAttributeSet);
                        }
                      });

                      //clear error
                      setFormErrors({});
                    }}>
                    <i className="fas fa-times-circle"></i>
                  </button>
                </Tooltip> */}
                <Tooltip title="Submit">
                  <button
                    className="btn"
                    disabled={
                      currentGridId !== null ? true : false || !section.ISEDIT
                    }
                    onClick={async (e) => {
                      e.stopPropagation();

                      if (TId === 0 && section.SN !== "Job") {
                        notify(1, "Save Job Data First");
                      } else {
                        let errorObj = {};
                        section.Attribute.map((eachAttribute) => {
                          if (eachAttribute.AT === "Attribute") {
                            const validation =
                              attributeValidation[eachAttribute.AC];

                            if (validation.ISM.toLowerCase() === "true") {
                              if (eachAttribute.DT === "Text") {
                                if (attributesData[eachAttribute.AC] === "") {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "Required",
                                  };
                                } else if (
                                  attributesData[eachAttribute.AC]?.length >
                                  validation.MaxC
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: `Max ${validation.MaxC} characters allowed`,
                                  };
                                } else {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "",
                                  };
                                }
                              } else if (
                                eachAttribute.DT === "DropdownSingle" ||
                                eachAttribute.DT === "SearchableDropdownSingle"
                              ) {
                                if (attributesData[eachAttribute.AC] === null) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "Required",
                                  };
                                } else {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "",
                                  };
                                }
                              } else if (eachAttribute.DT === "Date") {
                                if (attributesData[eachAttribute.AC] === null) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "Required",
                                  };
                                } else if (
                                  moment(
                                    validation.MaxV === "CurrentDate" &&
                                      attributesData[eachAttribute.AC]
                                  ).isAfter(new Date())
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]:
                                      "Only till current date allowed",
                                  };
                                } else if (
                                  moment(
                                    validation.MinV === "CurrentDate" &&
                                      attributesData[eachAttribute.AC]
                                  ).isBefore()
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]:
                                      "Only after current date allowed",
                                  };
                                } else if (
                                  validation.MinV !== undefined &&
                                  validation.MinV !== "CurrentDate" &&
                                  validation.MinV !== ""
                                ) {
                                  let parentValue =
                                    attributesData[validation.MinV];
                                  if (
                                    parentValue !== null &&
                                    parentValue !== "" &&
                                    attributesData[eachAttribute.AC] !== null &&
                                    attributesData[eachAttribute.AC] !== "" &&
                                    moment(
                                      attributesData[eachAttribute.AC]
                                    ).isBefore(parentValue)
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]:
                                        "Past date not allowed",
                                    };
                                  } else if (
                                    (parentValue === null ||
                                      parentValue === "") &&
                                    attributesData[eachAttribute.AC] !== null &&
                                    attributesData[eachAttribute.AC] !== ""
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [validation.MinV]: "Required",
                                    };
                                  }
                                } else {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "",
                                  };
                                }
                              } else if (eachAttribute.DT === "Number") {
                                //change with regex

                                if (attributesData[eachAttribute.AC] === "") {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "Required",
                                  };
                                } else if (
                                  // isNaN(attributesData[eachAttribute.AC])
                                  !/^([0-9]+|0)$/.test(
                                    attributesData[eachAttribute.AC]
                                  )
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]:
                                      "Only numbers are allowed",
                                  };
                                } else if (
                                  Number(attributesData[eachAttribute.AC]) >
                                  validation.MaxV
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: `Max ${validation.MaxV} allowed`,
                                  };
                                } else if (
                                  Number(attributesData[eachAttribute.AC]) <
                                  validation.MinV
                                ) {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: `Min ${validation.MinV} required`,
                                  };
                                } else {
                                  errorObj = {
                                    ...errorObj,
                                    [eachAttribute.AC]: "",
                                  };
                                }
                              }
                            } else {
                              // for num and date

                              if (eachAttribute.DT === "Number") {
                                if (attributesData[eachAttribute.AC] !== "") {
                                  //change with regex
                                  if (
                                    // isNaN(attributesData[eachAttribute.AC])
                                    !/^([0-9]+|0)$/.test(
                                      attributesData[eachAttribute.AC]
                                    )
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]:
                                        "Only numbers are allowed",
                                    };
                                  } else if (
                                    Number(attributesData[eachAttribute.AC]) >
                                    validation.MaxV
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: `Max ${validation.MaxV} allowed`,
                                    };
                                  } else if (
                                    Number(attributesData[eachAttribute.AC]) <
                                    validation.MinV
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: `Min ${validation.MinV} required`,
                                    };
                                  } else {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "",
                                    };
                                  }
                                }
                              } else if (eachAttribute.DT === "Date") {
                                if (attributesData[eachAttribute.AC] !== null) {
                                  if (
                                    moment(
                                      validation.MaxV === "CurrentDate" &&
                                        attributesData[eachAttribute.AC]
                                    ).isAfter(new Date())
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]:
                                        "Only till current date allowed",
                                    };
                                  } else if (
                                    moment(
                                      validation.MinV === "CurrentDate" &&
                                        attributesData[eachAttribute.AC]
                                    ).isBefore()
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]:
                                        "Only after current date allowed",
                                    };
                                  } else if (
                                    validation.MinV !== undefined &&
                                    validation.MinV !== "CurrentDate" &&
                                    validation.MinV !== ""
                                  ) {
                                    let parentValue =
                                      attributesData[validation.MinV];
                                    if (
                                      parentValue !== null &&
                                      parentValue !== "" &&
                                      attributesData[eachAttribute.AC] !==
                                        null &&
                                      attributesData[eachAttribute.AC] !== "" &&
                                      moment(
                                        attributesData[eachAttribute.AC]
                                      ).isBefore(parentValue)
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]:
                                          "Past date not allowed",
                                      };
                                    } else if (
                                      (parentValue === null ||
                                        parentValue === "") &&
                                      attributesData[eachAttribute.AC] !==
                                        null &&
                                      attributesData[eachAttribute.AC] !== ""
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [validation.MinV]: "Required",
                                      };
                                    }
                                  } else {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "",
                                    };
                                  }
                                }
                              }
                            }
                          } else {
                            //submit attributeSet
                          }
                        });

                        const isEmpty = Object.values(errorObj).every(
                          (s) => s === ""
                        );

                        if (isEmpty && attributeSetCode === null) {
                          await showLoader();
                          // post data attribute
                          const postAttributes = [];

                          Object.entries(attributesData).forEach(
                            async (attributeData) => {
                              const [Code, Value]: any[] = attributeData;

                              let attribute = {
                                Type: "Attribute",
                                Code,
                                Value:
                                  (typeof Value === "object" &&
                                    Array.isArray(Value)) === true
                                    ? Value.map((item) => item.value).join(
                                        ","
                                      ) || null
                                    : typeof Value === "object"
                                    ? Value?.value.toString() || null
                                    : typeof Value === "string" ||
                                      typeof Value === "boolean"
                                    ? Value
                                    : null,
                              };
                              postAttributes.push(attribute);
                            }
                          );
                          let postObj = {
                            MN: "JobProfile",
                            IN: "JobProfile_Form",
                            TN: formData.tn,
                            SN: section.SN,
                            TId: TId || 0,
                            UserId: userDetails.Id,
                            UserEmailId: userDetails.EmailId,
                            UserName: userDetails.FirstName,
                            RoleId: currentRoleId?.value,
                            Attributes: postAttributes,
                            COU: COU,
                          };

                          const postRes = await APICall(
                            postAttributeValueDetailsV2,
                            "POST",
                            postObj
                          );

                          console.log("postres:", postRes);

                          if (
                            postRes.data !== null &&
                            section.SN === "Job" &&
                            postRes.data.id !== undefined
                          ) {
                            setTId(postRes.data.id);
                          }

                          await hideLoader();
                          notify(postRes.status, postRes.message);
                          setShowAttrSet(false);
                        } else if (!isEmpty && attributeSetCode === null) {
                          notify(
                            1,
                            `${section.SDN} - Please check validation errors!`
                          );
                        } else if (
                          isEmpty &&
                          attributeSetCode !== null &&
                          Object.keys(attributesData).length === 0
                        ) {
                          await showLoader();
                          // post data attributeSet
                          let details = [...ogFilledAttributeSet];

                          const finalDetails = [];
                          details.forEach(async (attributes) => {
                            let attributeObj = {};
                            Object.keys(attributes).forEach(
                              async (attribute) => {
                                console.log(
                                  "Attributes:",
                                  attribute,
                                  "Attributes val: ",
                                  attributes[attribute]
                                );
                                attributeObj = {
                                  ...attributeObj,
                                  [attribute]:
                                    (typeof attributes[attribute] ===
                                      "object" &&
                                      Array.isArray(attributes[attribute])) ===
                                    true
                                      ? attributes[attribute]
                                          .map((item) => item.value)
                                          .join(",") || null
                                      : typeof attributes[attribute] ===
                                        "object"
                                      ? attributes[
                                          attribute
                                        ]?.value.toString() || null
                                      : typeof attributes[attribute] ===
                                          "string" ||
                                        typeof attributes[attribute] ===
                                          "boolean"
                                      ? attributes[attribute]
                                      : null,
                                };

                                if (
                                  attribute === "Id" &&
                                  isNaN(Number(attributes[attribute]))
                                ) {
                                  attributeObj = {
                                    ...attributeObj,
                                    [attribute]: 0,
                                  };
                                }
                              }
                            );
                            finalDetails.push(attributeObj);
                          });

                          let postObj = {
                            MN: "JobProfile",
                            IN: "JobProfile_Form",
                            TN: formData.tn,
                            SN: section.SN,
                            TId: TId || 0,
                            COU: COU,
                            UserId: userDetails.Id,
                            UserEmailId: userDetails.EmailId,
                            UserName: userDetails.FirstName,
                            RoleId: currentRoleId?.value,
                            Attributes: [
                              {
                                Code: attributeSetCode,
                                Value: "",
                                Type: "AttributeSet",
                                Details: finalDetails,
                              },
                            ],
                          };
                          const postRes = await APICall(
                            postAttributeValueDetailsV2,
                            "POST",
                            postObj
                          );

                          console.log("postRes2:", postRes);
                          setShowAttrSet(false);
                          await hideLoader();
                          notify(postRes.status, postRes.message);
                        }

                        setFormErrors((err) => ({
                          ...err,
                          ...errorObj,
                        }));
                      }
                    }}
                  >
                    <i className="fa fa-save"></i>
                  </button>
                </Tooltip>
              </div>
            </div>
          </AccordionSummary>

          <AccordionDetails className="page_heading">
            <div className="row">
              {section.Attribute.length > 0 &&
                section.Attribute.map((eachAttribute, index) => (
                  <React.Fragment key={`${index}-${eachAttribute.AC}`}>
                    {eachAttribute.AT === "Attribute" ? (
                      <>
                        {eachAttribute.DT === "DropdownSingle" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }
                            >
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <SelectForm
                                  isClearable
                                  isSearchable
                                  options={
                                    optionsObj[eachAttribute.AC]
                                      ? optionsObj[eachAttribute.AC]
                                      : []
                                  }
                                  {...(eachAttribute.AC === "JPSTAS" && {
                                    filterOption: (option) =>
                                      TId === 0 && eachAttribute.AC === "JPSTAS"
                                        ? option.label !== "Inactive"
                                        : option,
                                  })}
                                  placeholder={eachAttribute.AN}
                                  isDisabled={
                                    attributeValidation[eachAttribute.AC]
                                      ?.isDisable || !section.ISEDIT
                                  }
                                  value={
                                    attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : null
                                  }
                                  onChange={(event) => {
                                    setAttributesData((prev) => ({
                                      ...prev,
                                      [eachAttribute.AC]: event,
                                    }));

                                    if (
                                      event !== null &&
                                      eachAttribute.CC !== null &&
                                      eachAttribute.CC !== ""
                                    ) {
                                      const CC = eachAttribute.CC.split(",");

                                      CC.forEach(async (childDropdown) => {
                                        setAttributesData((prev) => ({
                                          ...prev,
                                          [childDropdown]: null,
                                        }));

                                        const options = await getDropdownsData(
                                          childDropdown,
                                          event.value
                                        );

                                        setOptionsObj((prev) => ({
                                          ...prev,
                                          [childDropdown]: options,
                                        }));
                                      });
                                    }

                                    dependentValidations(
                                      event,
                                      eachAttribute.AC
                                    );
                                  }}
                                  isMulti={false}
                                  noIndicator={false}
                                  noSeparator={false}
                                />
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "DropdownMultiple" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }
                            >
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <SelectForm
                                  isClearable
                                  isSearchable
                                  isMulti={true}
                                  options={
                                    optionsObj[eachAttribute.AC]
                                      ? optionsObj[eachAttribute.AC]
                                      : []
                                  }
                                  placeholder={eachAttribute.AN}
                                  isDisabled={
                                    attributeValidation[eachAttribute.AC]
                                      ?.isDisable || !section.ISEDIT
                                  }
                                  value={
                                    attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : null
                                  }
                                  onChange={(event) => {
                                    setAttributesData((prev) => ({
                                      ...prev,
                                      [eachAttribute.AC]: event,
                                    }));

                                    if (
                                      event !== null &&
                                      eachAttribute.CC !== null &&
                                      eachAttribute.CC !== ""
                                    ) {
                                      const CC = eachAttribute.CC.split(",");

                                      CC.forEach(async (childDropdown) => {
                                        setAttributesData((prev) => ({
                                          ...prev,
                                          [childDropdown]: null,
                                        }));

                                        const options = await getDropdownsData(
                                          childDropdown,
                                          event.value
                                        );

                                        setOptionsObj((prev) => ({
                                          ...prev,
                                          [childDropdown]: options,
                                        }));
                                      });
                                    }

                                    dependentValidations(
                                      event,
                                      eachAttribute.AC
                                    );
                                  }}
                                  noIndicator={false}
                                  noSeparator={false}
                                />
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "Text" ||
                          eachAttribute.DT === "Number" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }
                            >
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <InputForm
                                  className="form-control"
                                  placeholder={eachAttribute.AN}
                                  isDisabled={
                                    attributeValidation[eachAttribute.AC]
                                      ?.isDisable ||
                                    !section.ISEDIT ||
                                    (eachAttribute.AC === "JPJOCD" &&
                                      attributesData["JPJOCD"] !== null &&
                                      attributesData["JPJOCD"] !== undefined &&
                                      attributesData["JPJOCD"] !== "" &&
                                      TId !== 0) ||
                                    (eachAttribute.AC === "JPJOTTL" &&
                                      attributesData["JPJOTTL"] !== null &&
                                      attributesData["JPJOTTL"] !== undefined &&
                                      attributesData["JPJOTTL"] !== "" &&
                                      TId !== 0)
                                      ? true
                                      : false
                                  }
                                  textArea={false}
                                  value={
                                    attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : ""
                                  }
                                  onChange={(e) => {
                                    setAttributesData((prev) => ({
                                      ...prev,
                                      [eachAttribute.AC]: e.target.value,
                                    }));

                                    dependentValidations(
                                      e.target.value,
                                      eachAttribute.AC
                                    );
                                  }}
                                  maxLength="255"
                                />
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "Date" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }
                            >
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <DateForm
                                  isDisabled={
                                    attributeValidation[eachAttribute.AC]
                                      ?.isDisable || !section.ISEDIT
                                  }
                                  value={
                                    attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : null
                                  }
                                  onChange={(date) => {
                                    setAttributesData((prev) => ({
                                      ...prev,
                                      [eachAttribute.AC]:
                                        moment(date).format("DD-MMM-YYYY"),
                                    }));

                                    dependentValidations(
                                      moment(date).format("DD-MMM-YYYY"),
                                      eachAttribute.AC
                                    );
                                  }}
                                />
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "Checkbox" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }
                            >
                              <div className="mb-1">
                                <label
                                  htmlFor={eachAttribute.AC}
                                  className="col-form-label"
                                >
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <div>
                                  <input
                                    disabled={
                                      attributeValidation[eachAttribute.AC]
                                        ?.isDisable || !section.ISEDIT
                                    }
                                    type="checkbox"
                                    onChange={(e) => {
                                      setAttributesData((prev) => ({
                                        ...prev,
                                        [eachAttribute.AC]: e.target.checked,
                                      }));

                                      dependentValidations(
                                        e.target.checked,
                                        eachAttribute.AC
                                      );
                                    }}
                                    id={eachAttribute.AC}
                                    checked={
                                      attributesData[eachAttribute.AC]
                                        ? attributesData[eachAttribute.AC]
                                        : false
                                    }
                                  />
                                </div>
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "Textarea" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }
                            >
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <InputForm
                                  className="form-control"
                                  placeholder={eachAttribute.AN}
                                  isDisabled={
                                    attributeValidation[eachAttribute.AC]
                                      ?.isDisable || !section.ISEDIT
                                  }
                                  textArea={true}
                                  value={
                                    attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : ""
                                  }
                                  onChange={(e) => {
                                    setAttributesData((prev) => ({
                                      ...prev,
                                      [eachAttribute.AC]: e.target.value,
                                    }));

                                    dependentValidations(
                                      e.target.value,
                                      eachAttribute.AC
                                    );
                                  }}
                                  maxLength="255"
                                />
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "Document1" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }
                            >
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <div className="box position-relative">
                                  <input
                                    id="files-8"
                                    className="form-control inputfile inputfile-6 multiple-inputfile"
                                    data-multiple-caption="{count} files selected"
                                    multiple
                                    type="file"
                                  />
                                  <label
                                    htmlFor="files-8"
                                    className="form-control"
                                  >
                                    <span></span>{" "}
                                    <strong>
                                      <i
                                        className="fa fa-paperclip rotate90"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Browse
                                    </strong>{" "}
                                  </label>
                                </div>
                                <div className="file-added-list">
                                  <ul className="list-unstyle">
                                    <li className="list mt-1">
                                      <div className="media">
                                        <div className="media-body text-truncate">
                                          <a
                                            href="javascript:void(0);"
                                            className="view-more"
                                          >
                                            Archiv1_b.webp
                                          </a>
                                        </div>
                                        <div className="media-right ml-2">
                                          <i
                                            className="fa fa-trash"
                                            aria-hidden="true"
                                          ></i>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="list mt-1">
                                      <div className="media">
                                        <div className="media-body text-truncate">
                                          <a
                                            href="javascript:void(0);"
                                            className="view-more"
                                          >
                                            04.png
                                          </a>
                                        </div>
                                        <div className="media-right ml-2">
                                          <i
                                            className="fa fa-trash"
                                            aria-hidden="true"
                                          ></i>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "RichText" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                ).IFR.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }
                            >
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>
                                <RTE
                                  content={
                                    attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : ""
                                  }
                                  disabled={!section.ISEDIT}
                                  onblur={(e) => {
                                    setAttributesData((prev) => ({
                                      ...prev,
                                      [eachAttribute.AC]: e,
                                    }));

                                    dependentValidations(
                                      e.target?.value,
                                      eachAttribute.AC
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        ) : eachAttribute.DT === "SearchableDropdownSingle" ? (
                          <>
                            <div
                              className={
                                JSON.parse(
                                  eachAttribute.AD
                                )?.IFR?.toLowerCase() === "true"
                                  ? "col-lg-12 col-sm-12 col-xs-12 "
                                  : "col-lg-3 col-sm-3 col-xs-4"
                              }
                            >
                              <div className="mb-1">
                                <label className="col-form-label">
                                  {eachAttribute.AN}
                                  {attributeValidation[
                                    eachAttribute.AC
                                  ]?.ISM?.toLowerCase() === "true" && (
                                    <sup>*</sup>
                                  )}
                                </label>

                                <SelectForm
                                  isClearable
                                  isSearchable
                                  async
                                  options={(searchString, cb) =>
                                    getSearchableDropdownAPI(
                                      searchString,
                                      eachAttribute.AC,
                                      cb,
                                      "",
                                      "attribute",
                                      0
                                    )
                                  }
                                  placeholder={eachAttribute.AN}
                                  isDisabled={
                                    attributeValidation[eachAttribute.AC]
                                      ?.isDisable || !section.ISEDIT
                                  }
                                  value={
                                    attributesData[eachAttribute.AC]
                                      ? attributesData[eachAttribute.AC]
                                      : null
                                  }
                                  onChange={(event) => {
                                    setAttributesData((prev) => ({
                                      ...prev,
                                      [eachAttribute.AC]: event,
                                    }));

                                    if (
                                      event !== null &&
                                      eachAttribute.CC !== null &&
                                      eachAttribute.CC !== ""
                                    ) {
                                      const CC = eachAttribute.CC.split(",");

                                      CC.forEach(async (childDropdown) => {
                                        setAttributesData((prev) => ({
                                          ...prev,
                                          [childDropdown]: null,
                                        }));

                                        const options = await getDropdownsData(
                                          childDropdown,
                                          event.value
                                        );

                                        setOptionsObj((prev) => ({
                                          ...prev,
                                          [childDropdown]: options,
                                        }));
                                      });
                                    }

                                    dependentValidations(
                                      event,
                                      eachAttribute.AC
                                    );

                                    //searchable DD
                                    if (event) {
                                      searchableDDValues(
                                        event,
                                        eachAttribute.AC
                                      );
                                    }
                                  }}
                                  isMulti={false}
                                  noIndicator={false}
                                  noSeparator={false}
                                />
                                {formErrors[eachAttribute.AC] && (
                                  <p style={{ color: "red" }}>
                                    {formErrors[eachAttribute.AC]}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : eachAttribute.AT === "AttributeSet" &&
                      eachAttribute.DT === "Custom" ? (
                      <div className="col-lg-12">
                        {section?.ISEDIT && (
                          <div className="col-lg-12 mt-2 mb-3 d-flex justify-content-end">
                            <Tooltip title="Add">
                              <button
                                id={"addRec"}
                                className="btn btn-primary"
                                onClick={() => {
                                  setShowAttrSet(!ShowAttrSet);
                                  setMultiDropdown(true);
                                }}
                              >
                                <i className={"fa fa-plus"}></i> Add
                              </button>
                            </Tooltip>
                          </div>
                        )}

                        {ShowAttrSet && (
                          <div className="row">
                            {eachAttribute.Attribute.length > 0 &&
                              eachAttribute.Attribute.map(
                                (subAttribute, index) => (
                                  <React.Fragment
                                    key={`${index}-${subAttribute.AC}`}
                                  >
                                    {subAttribute.DT === "DropdownSingle" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {JSON.parse(
                                                subAttribute.V
                                              ).ISM.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <SelectForm
                                              isClearable
                                              isSearchable
                                              options={
                                                optionsObj[subAttribute.AC]
                                                  ? optionsObj[subAttribute.AC]
                                                  : []
                                              }
                                              placeholder={subAttribute.AN}
                                              isDisabled={
                                                !section.ISEDIT ||  viewOnly ||
                                                subAttribute.AC === "JRPRFWTG"
                                                  ? true
                                                  : false
                                              }
                                              value={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : null
                                              }
                                              onChange={(event) => {
                                                setAttributeSetData((prev) => ({
                                                  ...prev,
                                                  [subAttribute.AC]: event,
                                                }));

                                                if (
                                                  event !== null &&
                                                  subAttribute.CC !== null &&
                                                  subAttribute.CC !== ""
                                                ) {
                                                  const CC =
                                                    subAttribute.CC.split(",");

                                                  CC.forEach(
                                                    async (childDropdown) => {
                                                      setAttributeSetData(
                                                        (prev) => ({
                                                          ...prev,
                                                          [childDropdown]: null,
                                                        })
                                                      );

                                                      const options =
                                                        await getDropdownsData(
                                                          childDropdown,
                                                          event.value
                                                        );

                                                      setOptionsObj((prev) => ({
                                                        ...prev,
                                                        [childDropdown]:
                                                          options,
                                                      }));

                                                      if (
                                                        subAttribute.AC ===
                                                          "JRCPROF" &&
                                                        event !== null
                                                      ) {
                                                        setAttributeSetData(
                                                          (prev) => ({
                                                            ...prev,
                                                            ["JRPRFWTG"]:
                                                              options[0],
                                                          })
                                                        );
                                                      }
                                                    }
                                                  );
                                                }
                                              }}
                                              isMulti={false}
                                              noIndicator={false}
                                              noSeparator={false}
                                            />
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT === "Text" ||
                                      subAttribute.DT === "Number" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {JSON.parse(
                                                subAttribute.V
                                              ).ISM.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <InputForm
                                              className="form-control"
                                              placeholder={subAttribute.AN}
                                              isDisabled={!section.ISEDIT || viewOnly }
                                              textArea={false}
                                              value={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : ""
                                              }
                                              onChange={(e) => {
                                                setAttributeSetData((prev) => ({
                                                  ...prev,
                                                  [subAttribute.AC]:
                                                    e.target.value,
                                                }));
                                              }}
                                              maxLength="255"
                                            />
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT === "Date" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {JSON.parse(
                                                subAttribute.V
                                              ).ISM.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <DateForm
                                              isDisabled={!section.ISEDIT || viewOnly }
                                              value={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : null
                                              }
                                              onChange={(date) => {
                                                setAttributeSetData((prev) => ({
                                                  ...prev,
                                                  [subAttribute.AC]:
                                                    moment(date).format(
                                                      "DD-MMM-YYYY"
                                                    ),
                                                }));
                                              }}
                                            />
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT === "Checkbox" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label
                                              htmlFor={subAttribute.AC}
                                              className="col-form-label"
                                            >
                                              {subAttribute.AN}
                                              {JSON.parse(
                                                subAttribute.V
                                              ).ISM.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <div>
                                              <input
                                                disabled={!section.ISEDIT  || viewOnly }
                                                type="checkbox"
                                                onChange={(e) => {
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      [subAttribute.AC]:
                                                        e.target.checked,
                                                    })
                                                  );
                                                }}
                                                id={subAttribute.AC}
                                                checked={
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ]
                                                    ? attributeSetData[
                                                        subAttribute.AC
                                                      ]
                                                    : false
                                                }
                                              />
                                            </div>
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT === "RichText" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {attributeValidation[
                                                subAttribute.AC
                                              ]?.ISM.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>
                                            <RTE
                                              content={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : ""
                                              }
                                              disabled={!section.ISEDIT || viewOnly }
                                              onblur={(e) => {
                                                setAttributeSetData((prev) => ({
                                                  ...prev,
                                                  [subAttribute.AC]: e,
                                                }));
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT ===
                                      "SearchableDropdownSingle" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {JSON.parse(
                                                subAttribute.V
                                              ).ISM.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <SelectForm
                                              isClearable
                                              isSearchable
                                              async
                                              options={(searchString, cb) =>
                                                getSearchableDropdownAPI(
                                                  searchString,
                                                  subAttribute.AC,
                                                  cb,
                                                  "",
                                                  "attributeSet",
                                                  0
                                                )
                                              }
                                              placeholder={subAttribute.AN}
                                              isDisabled={!section.ISEDIT || viewOnly }
                                              value={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : null
                                              }
                                              onChange={(event) => {
                                                setAttributeSetData((prev) => ({
                                                  ...prev,
                                                  [subAttribute.AC]: event,
                                                }));

                                                if (
                                                  event !== null &&
                                                  subAttribute.CC !== null &&
                                                  subAttribute.CC !== ""
                                                ) {
                                                  const CC =
                                                    subAttribute.CC.split(",");

                                                  CC.forEach(
                                                    async (childDropdown) => {
                                                      setAttributeSetData(
                                                        (prev) => ({
                                                          ...prev,
                                                          [childDropdown]: null,
                                                        })
                                                      );

                                                      const options =
                                                        await getDropdownsData(
                                                          childDropdown,
                                                          event.value
                                                        );

                                                      setOptionsObj((prev) => ({
                                                        ...prev,
                                                        [childDropdown]:
                                                          options,
                                                      }));
                                                    }
                                                  );
                                                }

                                                // dependentValidations(
                                                //   event,
                                                //   subAttribute.AC
                                                // );

                                                //searchable DD
                                                if (event) {
                                                  searchableDDValuesSet(
                                                    event,
                                                    subAttribute.AC
                                                  );
                                                }
                                              }}
                                              isMulti={false}
                                              noIndicator={false}
                                              noSeparator={false}
                                            />
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : subAttribute.DT ===
                                      "DropdownMultiple" ? (
                                      <>
                                        <div
                                          className={
                                            JSON.parse(
                                              subAttribute.AD
                                            ).IFR.toLowerCase() === "true"
                                              ? "col-lg-12 col-sm-12 col-xs-12 "
                                              : "col-lg-3 col-sm-3 col-xs-4"
                                          }
                                        >
                                          <div className="mb-1">
                                            <label className="col-form-label">
                                              {subAttribute.AN}
                                              {JSON.parse(
                                                subAttribute.V
                                              ).ISM.toLowerCase() ===
                                                "true" && <sup>*</sup>}
                                            </label>

                                            <SelectForm
                                              isClearable
                                              isSearchable
                                              options={
                                                optionsObj[subAttribute.AC]
                                                  ? optionsObj[subAttribute.AC]
                                                  : []
                                              }
                                              isMulti={multiDropdown}
                                              placeholder={subAttribute.AN}
                                              isDisabled={!section.ISEDIT || viewOnly }
                                              value={
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                                  ? attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  : null
                                              }
                                              onChange={(event) => {
                                                setAttributeSetData((prev) => ({
                                                  ...prev,
                                                  [subAttribute.AC]: event,
                                                }));

                                                if (
                                                  event !== null &&
                                                  subAttribute.CC !== null &&
                                                  subAttribute.CC !== ""
                                                ) {
                                                  const CC =
                                                    subAttribute.CC.split(",");

                                                  CC.forEach(
                                                    async (childDropdown) => {
                                                      setAttributeSetData(
                                                        (prev) => ({
                                                          ...prev,
                                                          [childDropdown]: null,
                                                        })
                                                      );

                                                      const options =
                                                        await getDropdownsData(
                                                          childDropdown,
                                                          event.value
                                                        );

                                                      setOptionsObj((prev) => ({
                                                        ...prev,
                                                        [childDropdown]:
                                                          options,
                                                      }));
                                                    }
                                                  );
                                                }
                                              }}
                                              noIndicator={false}
                                              noSeparator={false}
                                            />
                                            {formErrors[subAttribute.AC] && (
                                              <p style={{ color: "red" }}>
                                                {formErrors[subAttribute.AC]}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </React.Fragment>
                                )
                              )}
                          </div>
                        )}

                        <div className="row">
                          {ShowAttrSet && (
                            <div className="mb-2">
                              <button className="btn btn-secondary ml-3"
                               onClick={() => {
                                if (ShowAttrSet) {
                                  setAttributeSetData((prev) => ({
                                    ...prev,
                                    ...ogAttributeSet,
                                  }));
                                  setFormErrors({})
                                  setCurrentGridId(null);
                                }
                              }}
                              >
                                Cancel
                              </button>
                              {section?.ISEDIT && (<button
                                id={currentGridId === null ? "0" : "1"}
                                className="btn btn-primary ml-2"
                                disabled={!section.ISEDIT}
                                onClick={(e) => {
                                  const targetId = e.currentTarget.id;
                                  if (targetId === "0") {
                                    //insert
                                    if (
                                      (typeof attributeSetData["JOBRSKL"] ===
                                        "object" &&
                                        Array.isArray(
                                          attributeSetData["JOBRSKL"]
                                        ) === true) ||
                                      (typeof attributeSetData["JRCCOPCY"] ===
                                        "object" &&
                                        Array.isArray(
                                          attributeSetData["JRCCOPCY"]
                                        ) === true) || 
                                        (typeof attributeSetData["JBSKL"] ===
                                        "object" &&
                                        Array.isArray(
                                          attributeSetData["JBSKL"]
                                        ) === true)
                                    ) {
                                      let key = "";
                                      if (
                                        section.SN ===
                                        "JobRequirementCompetency"
                                      ) {
                                        key = "JRCCOPCY";
                                      } else if (
                                        section.SN === "JobRequirementSkills"
                                      ) {
                                        key = "JOBRSKL";
                                      } else if(
                                        section.SN === "JobSkills"
                                      ){
                                        key = "JBSKL";
                                      }
                                      const isError = validateData(
                                        eachAttribute.Attribute, section.SN
                                      );
                                      if (!isError) {
                                      if (key !== "") {
                                        attributeSetData[`${key}`].map(
                                          (value, indx) => {
                                            const uniqueId = uuid();
                                            console.log(value,"value");
                                            //validation for empty data before adding in grid
                                            let isError = false;
                                            // if (indx === 0) {
                                            //   isError = validateData(
                                            //     eachAttribute.Attribute
                                            //   );
                                            // }

                                            if (!isError) {
                                              //add record in grid

                                              let gridData = {};
                                              let ogf = {};
                                              eachAttribute.Attribute.forEach(
                                                async (subAttribute) => {
                                                  console.log(
                                                    subAttribute,
                                                    subAttribute
                                                  );
                                                  console.log(
                                                    "Map1: ",
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                  );
                                                  if (
                                                    typeof attributeSetData[
                                                      subAttribute.AC
                                                    ] === "object" &&
                                                    Array.isArray(
                                                      attributeSetData[
                                                        subAttribute.AC
                                                      ]
                                                    ) === true
                                                  ) {
                                                    //let gridTemp = attributeSetData[subAttribute.AC].map(item => item.label).join(', ');
                                                    gridData = {
                                                      ...gridData,
                                                      [subAttribute.AC]:
                                                        value.label
                                                          ? value.label
                                                          : null, //for MultiSelect Dropdownn
                                                    };
                                                    ogf = {
                                                      ...ogf,
                                                      [subAttribute.AC]: value,
                                                    };
                                                  } else if (
                                                    typeof attributeSetData[
                                                      subAttribute.AC
                                                    ] === "object"
                                                  ) {
                                                    gridData = {
                                                      ...gridData,
                                                      [subAttribute.AC]:
                                                        attributeSetData[
                                                          subAttribute.AC
                                                        ]?.label || null, //for date and dropdown
                                                    };
                                                    ogf = {
                                                      ...ogf,
                                                      [subAttribute.AC]:
                                                        attributeSetData[
                                                          subAttribute.AC
                                                        ],
                                                    };
                                                  } else if (
                                                    typeof attributeSetData[
                                                      subAttribute.AC
                                                    ] === "boolean"
                                                  ) {
                                                    gridData = {
                                                      ...gridData,
                                                      [subAttribute.AC]:
                                                        attributeSetData[
                                                          subAttribute.AC
                                                        ] === true
                                                          ? "true"
                                                          : "false",
                                                    };
                                                    ogf = {
                                                      ...ogf,
                                                      [subAttribute.AC]:
                                                        attributeSetData[
                                                          subAttribute.AC
                                                        ],
                                                    };
                                                  } else {
                                                    gridData = {
                                                      ...gridData,
                                                      [subAttribute.AC]:
                                                        attributeSetData[
                                                          subAttribute.AC
                                                        ],
                                                    };
                                                    ogf = {
                                                      ...ogf,
                                                      [subAttribute.AC]:
                                                        attributeSetData[
                                                          subAttribute.AC
                                                        ],
                                                    };
                                                  }
                                                }
                                              );
                                              setOgFilledAttributeSet(
                                                (prev) => [
                                                  ...prev,
                                                  {
                                                    ...ogf,
                                                    Id: uniqueId,
                                                    IsActive: true,
                                                  },
                                                ]
                                              );
                                              //reset
                                              setAttributeSetData((prev) => ({
                                                ...prev,
                                                ...ogAttributeSet,
                                              }));
                                              setFormErrors({});

                                              setGridData((prev) => [
                                                ...prev,
                                                {
                                                  Id: uniqueId,
                                                  ...gridData,
                                                  IsActive: true,
                                                },
                                              ]);

                                              setDisplayGrid((prev) => [
                                                ...prev,
                                                {
                                                  Id: uniqueId,
                                                  ...gridData,
                                                  IsActive: true,
                                                },
                                              ]);
                                            }
                                          }
                                        );
                                      }}
                                    } else {
                                      //Dropdown single select setting data into grid
                                      const uniqueId = uuid();

                                      //validation for empty data before adding in grid

                                      const isError = validateData(
                                        eachAttribute.Attribute, section.SN
                                      );

                                      if (!isError) {
                                        //add record in grid

                                        setOgFilledAttributeSet((prev) => [
                                          ...prev,
                                          {
                                            ...attributeSetData,
                                            Id: uniqueId,
                                            IsActive: true,
                                          },
                                        ]);

                                        let gridData = {};

                                        eachAttribute.Attribute.forEach(
                                          async (subAttribute) => {
                                            console.log(
                                              subAttribute,
                                              subAttribute
                                            );
                                            console.log(
                                              "Map1: ",
                                              attributeSetData[subAttribute.AC]
                                            );
                                            if (
                                              typeof attributeSetData[
                                                subAttribute.AC
                                              ] === "object" &&
                                              Array.isArray(
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]
                                              ) === true
                                            ) {
                                              let gridTemp = attributeSetData[
                                                subAttribute.AC
                                              ]
                                                .map((item) => item.label)
                                                .join(", ");
                                              gridData = {
                                                ...gridData,
                                                [subAttribute.AC]: gridTemp
                                                  ? gridTemp
                                                  : null, //for MultiSelect Dropdownn
                                              };
                                            } else if (
                                              typeof attributeSetData[
                                                subAttribute.AC
                                              ] === "object"
                                            ) {
                                              gridData = {
                                                ...gridData,
                                                [subAttribute.AC]:
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ]?.label || null, //for date and dropdown
                                              };
                                            } else if (
                                              typeof attributeSetData[
                                                subAttribute.AC
                                              ] === "boolean"
                                            ) {
                                              gridData = {
                                                ...gridData,
                                                [subAttribute.AC]:
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ] === true
                                                    ? "true"
                                                    : "false",
                                              };
                                            } else {
                                              gridData = {
                                                ...gridData,
                                                [subAttribute.AC]:
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ],
                                              };
                                            }
                                          }
                                        );

                                        //reset
                                        setAttributeSetData((prev) => ({
                                          ...prev,
                                          ...ogAttributeSet,
                                        }));
                                        setFormErrors({});

                                        setGridData((prev) => [
                                          ...prev,
                                          {
                                            Id: uniqueId,
                                            ...gridData,
                                            IsActive: true,
                                          },
                                        ]);

                                        setDisplayGrid((prev) => [
                                          ...prev,
                                          {
                                            Id: uniqueId,
                                            ...gridData,
                                            IsActive: true,
                                          },
                                        ]);
                                      }
                                    }
                                  } else {
                                    //update

                                    //validation for empty data before updating in grid

                                    const isError = validateData(
                                      eachAttribute.Attribute,section.SN
                                    );

                                    if (!isError) {
                                      //update

                                      setOgFilledAttributeSet((prev) => {
                                        const newState = prev.map((record) => {
                                          if (record.Id === currentGridId) {
                                            return { ...attributeSetData };
                                          }
                                          return record;
                                        });
                                        return newState;
                                      });

                                      let gridData = {};

                                      eachAttribute.Attribute.forEach(
                                        async (subAttribute) => {
                                          // console.log('Map1: ',attributeSetData[subAttribute.AC], 'Type:', typeof attributeSetData[subAttribute.AC])
                                          // if(typeof attributeSetData[
                                          //   subAttribute.AC
                                          // ] === "object" && Array.isArray(attributeSetData[subAttribute.AC]) === true){
                                          //   let gridTemp = attributeSetData[subAttribute.AC].map(item => item.label).join(', ');
                                          //   gridData = {
                                          //     ...gridData,
                                          //     [subAttribute.AC]: gridTemp ? gridTemp : null, //for MultiSelect Dropdownn
                                          //   };
                                          // } else
                                          if (
                                            typeof attributeSetData[
                                              subAttribute.AC
                                            ] === "object"
                                          ) {
                                            gridData = {
                                              ...gridData,
                                              [subAttribute.AC]:
                                                attributeSetData[
                                                  subAttribute.AC
                                                ]?.label || null, //for date and dropdown
                                            };
                                          } else if (
                                            typeof attributeSetData[
                                              subAttribute.AC
                                            ] === "boolean"
                                          ) {
                                            gridData = {
                                              ...gridData,
                                              [subAttribute.AC]:
                                                attributeSetData[
                                                  subAttribute.AC
                                                ] === true
                                                  ? "true"
                                                  : "false",
                                            };
                                          } else {
                                            gridData = {
                                              ...gridData,
                                              [subAttribute.AC]:
                                                attributeSetData[
                                                  subAttribute.AC
                                                ],
                                            };
                                          }
                                        }
                                      );

                                      //reset
                                      setAttributeSetData((prev) => ({
                                        ...prev,
                                        ...ogAttributeSet,
                                      }));

                                      setFormErrors({});

                                      setGridData((prev) => {
                                        const newState = prev.map((record) => {
                                          if (record.Id === currentGridId) {
                                            return {
                                              Id: currentGridId,
                                              ...gridData,
                                            };
                                          }
                                          return record;
                                        });
                                        return newState;
                                      });

                                      setDisplayGrid((prev) => {
                                        const newState = prev.map((record) => {
                                          if (record.Id === currentGridId) {
                                            return {
                                              Id: currentGridId,
                                              ...gridData,
                                            };
                                          }
                                          return record;
                                        });
                                        return newState;
                                      });

                                      setCurrentGridId(null);
                                    }
                                  }
                                }}
                              >
                                <i
                                  className={
                                    currentGridId === null
                                      ? "fa fa-plus"
                                      : "fas fa-edit"
                                  }
                                ></i>{" "}
                                {currentGridId === null
                                  ? "Save"
                                  : "Update Record"}
                              </button>)}
                            </div>
                          )}
                          {/* {section?.ISEDIT && (
                            <div className="col-lg-12 mt-2 mb-3 d-flex justify-content-end">
                              <button
                                id={"addRec"}
                                className="btn btn-primary"
                                onClick={() => {
                                  setShowAttrSet(!ShowAttrSet);
                                  setMultiDropdown(true);
                                }}>
                                <i className={"fa fa-plus"}></i> Add
                              </button>
                            </div>
                          )} */}

                          <div className="col-lg-12 p-0 mb-3">
                            <DynamicGrid
                              options={gridOptions}
                              data={displayGrid}
                              columns={gridColumns}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </React.Fragment>
                ))}
            </div>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
};

const JobProfileForm = () => {
  const [formData, setFormData] = useState<any>({});
  const [tabs, setTabs] = useState([]);
  const [activetab, setActivetab] = useState<string>(null);
  const [activetabIndex, setActivetabIndex] = useState<number>(0);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { state } = useLocation();
  const [TId, setTId] = useState((state !== null && state.TId) || 0);
  //const [TId, setTId] = useState(1);
  const [headerValues, setHeaderValues] = useState<any>(null);
  const [sectionValuesArray, setSectionValuesArray] = useState<any>([]);
  const [COU, setCOU] = useState((state !== null && state.COU) || "");
  let { userDetails, currentRoleId }: any = useUserContext();
  const navigate = useNavigate();
  const showSkillsTab = useECStore((data: any) => data.showSkillsTab);
  //const setShowSkillsTab = useECStore((data: any) => data.addShowSkillsTab);

  useEffect(() => {
    (async () => {
      showLoader();
      const tabs = await APICall(getTabsData, "POST", {
        MN: "JobProfile",
        IN: "JobProfile_Form",
      });
      if (tabs.data !== null && tabs.data.length > 0) {
        setTabs(tabs.data);
        setActivetab(tabs.data[0].TN);
      } else {
        console.log("No tabs found!", tabs);
        setTabs([]);
      }
      hideLoader();
    })();
  }, []);

  //tab api here
  useEffect(() => {
    //get section
    if (activetab !== null) {
      (async () => {
        showLoader();
        const section = await APICall(getSectionDetailsV2, "POST", {
          MN: "JobProfile",
          IN: "JobProfile_Form",
          TN: activetab,
          UID: userDetails.Id,
          TID: TId,
          RoleId: currentRoleId?.value,
          COU: COU,
        });

        if (
          section.data !== null &&
          section.data.t !== undefined &&
          section.data.t.tn === activetab
        ) {
          let hideSections = [];

          if(!showSkillsTab.nonWorkerSkillSelected && showSkillsTab.workerSkillSelected){
            hideSections = ['JobRequirementEducationCertification', 'JobRequirementSkills',
             'JobRequirementCompetency', 'InterviewQuestions', 'AdditionalDetails'];
            
            hideSections.forEach(Section => {
              let sectionIndex = section.data.t.S.findIndex(x => x.SN === Section);
              section.data.t.S.splice(sectionIndex, 1);
            }); 
            
          } else if (showSkillsTab.nonWorkerSkillSelected && !showSkillsTab.workerSkillSelected) {
            let sectionIndex = section.data.t.S.findIndex(x => x.SN === 'JobSkills');
            section.data.t.S.splice(sectionIndex, 1);
          }
          setFormData(section.data.t);
        } else {
          setFormData({});
        }

        hideLoader();
      })();

      if (TId !== 0 && headerValues === null) {
        (async () => {
          let bodyObj = {
            mn: "JobProfile",
            in: "JobProfile_Form",
            tn: "JobProfile",
            SN: "Job",
            TID: TId,
            ISH: "true",
            UserId: userDetails?.Id,
            RoleId: currentRoleId?.value,
            COU: COU,
          };

          const response = await APICall(
            getAttributeValueDetailsV2,
            "POST",
            bodyObj
          );

          if (response.data !== null && response.data.headerValues !== null) {
            setHeaderValues(response.data.headerValues);
          } else {
            setHeaderValues(null);
          }
          if (response.data.sections && response.data.sections.length > 0) {
            setSectionValuesArray(response.data.sections);
          } else {
            setSectionValuesArray([]);
          }
        })();
      }
    }
  }, [activetab]);

  useEffect(() => {
    if (activetab !== null) {
      (async () => {
        showLoader();
        const section = await APICall(getSectionDetailsV2, "POST", {
          MN: "JobProfile",
          IN: "JobProfile_Form",
          TN: activetab,
          UID: userDetails.Id,
          TID: TId,
          RoleId: currentRoleId?.value,
          COU: COU,
        });

        if (
          section.data !== null &&
          section.data.t !== undefined &&
          section.data.t.tn === activetab
        ) {
          
          let hideSections = [];

          if(!showSkillsTab.nonWorkerSkillSelected && showSkillsTab.workerSkillSelected){
            hideSections = ['JobRequirementEducationCertification', 'JobRequirementSkills',
             'JobRequirementCompetency', 'InterviewQuestions', 'AdditionalDetails'];
            
            hideSections.forEach(Section => {
              let sectionIndex = section.data.t.S.findIndex(x => x.SN === Section);
              section.data.t.S.splice(sectionIndex, 1);
            }); 
            
          } else if (showSkillsTab.nonWorkerSkillSelected && !showSkillsTab.workerSkillSelected) {
            let sectionIndex = section.data.t.S.findIndex(x => x.SN === 'JobSkills');
            section.data.t.S.splice(sectionIndex, 1);
          }
          setFormData(section.data.t);
        } else {
          setFormData({});
        }

        hideLoader();
      })();

    }
  }, [showSkillsTab]);

  const defaultTheme = createTheme({});
  const { breakpoints } = defaultTheme;

  const tabsTheme = {
    ...defaultTheme,
    components: {
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: "0px !important",
            marginLeft: "-42px",
            [breakpoints.down("md")]: {
              minHeight: "0px !important",
              marginLeft: "0px",
            },
          },
        },
      },
    },
  };

  return (
    <>
      <div className="container-fluid">
        <div
          className="row prof_bg mb-3 border-bottom"
          style={{ backgroundColor: headerValues ? "#00abe6" : "white" }}
        >
          <div className="container-fluid">
            {headerValues && (
              <div className="row botpad">
                <div className="col-lg-2 col-md-2 col-sm-12">
                  <div className="prof_img">
                    {headerValues && (
                      <img className="job_head_img" src={profileImg} />
                    )}
                  </div>
                </div>
                <div className="col-lg-10 col-md-10 col-sm-12 profData">
                  <div className="name">
                    <h4>{headerValues && `${headerValues?.JPJOTTL}`}</h4>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12 profDetail">
                      <ul>
                        <li>
                          <a>
                            <i className="fa-regular fa-address-card"></i>{" "}
                            {headerValues && headerValues?.JPJOCD != "" && (
                              <>{headerValues?.JPJOCD}</>
                            )}
                          </a>
                        </li>
                        <li>
                          <a>
                            <i className="fa-solid fa-user"></i>{" "}
                            {headerValues && headerValues?.JBFMFN !== null && (
                              <>{headerValues?.JBFMFN}</>
                            )}
                          </a>
                        </li>
                        <li>
                          <a>
                            <i className="fa-solid fa-briefcase"></i>{" "}
                            {headerValues && headerValues?.JBEXPS != null && (
                              <>{headerValues.JBEXPS}</>
                            )}
                          </a>
                        </li>
                        <li>
                          <a>
                            <i className="fas fa-tag"></i>{" "}
                            {headerValues && headerValues?.JPSTAS != null && (
                              <>{headerValues.JPSTAS}</>
                            )}
                          </a>
                        </li>
                      </ul>
                    </div>
                    {/* Remove this div */}
                    {/* <div className="col-lg-4 col-md-4 col-sm-12 profDetail">
                      <ul>
                        <li>
                          <a>
                            {headerValues && headerValues?.JBEXPS !== "" && (
                              <>{headerValues?.JBEXPS}</>
                            )}
                          </a>
                        </li>
                        <li>
                          <a>
                            {headerValues && headerValues?.JPSTAS != null && (
                              <>
                                <i className="fas fa-tag"></i>{" "}
                                {headerValues.JPSTAS}
                              </>
                            )}
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-lg-2 col-md-12 col-sm-12 "></div>
              <div className="col-lg-10 col-md-12 col-sm-12 ">
                <ThemeProvider theme={tabsTheme}>
                  <Tabs
                    value={activetabIndex}
                    className="profile-tabs"
                    onChange={() => {}}
                    variant="scrollable"
                    TabScrollButtonProps={{
                      style: {
                        color: "white",
                      },
                    }}
                    scrollButtons
                    TabIndicatorProps={{
                      style: { display: "none" },
                    }}
                    allowScrollButtonsMobile
                    aria-label="scrollable tabs"
                  >
                    <div className="tabBtn">
                      <ul>
                        {tabs.length > 0 &&
                          tabs.map((eachTab, index) => (
                            <li key={`${index}-${eachTab.TN}`}>
                              <button
                                onClick={() => setActivetab(eachTab.TN)}
                                className={
                                  eachTab.TN === activetab
                                    ? "bttn active"
                                    : "bttn border border-primary border-bottom-0"
                                }
                              >
                                {eachTab.TDN}
                              </button>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </Tabs>
                </ThemeProvider>
              </div>
            </div>
          </div>
        </div>
        {/* Back Button */}
        {/* <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
          <div className="text-right my-1">
            <button className="btn btn-action"
            onClick={()=>{navigate(-1)}}>Back
          </button>
          </div>  
          </div>
        </div> */}
        
        <div className="SectionSubmit mb-4 clearfix">

          <button
            onClick={() => {
              navigate(-1);
            }}
            disabled={false}
            className="btn btn-secondary float-right"
          >
            <i className="fa-solid fa-arrow-left"></i> Back
          </button>
        </div>
        {/* section row */}
        <div className="row">
          {formData.S !== undefined &&
            formData.S.length > 0 &&
            ((((state !== null && state.TId === 0) || state === null) &&
              sectionValuesArray.length === 0) ||
              (state !== null &&
                state.TId !== 0 &&
                sectionValuesArray.length > 0)) &&
            formData.S.map((eachSection, index) =>
              (TId === 0 || TId !== 0) && index === 0 ? (
                <React.Fragment key={`${index}-${eachSection.SN}`}>
                  <SectionComponent
                    formData={formData}
                    setFormData={setFormData}
                    section={eachSection}
                    sectionIndex={index}
                    TId={TId}
                    setTId={setTId}
                    sectionValuesArray={sectionValuesArray}
                    COU={COU}
                    // showLoader={showLoader}
                    // hideLoader={hideLoader}
                  />
                </React.Fragment>
              ) : TId !== 0 && index > 0 ? (
                <React.Fragment key={`${index}-${eachSection.SN}`}>
                  <SectionComponent
                    formData={formData}
                    setFormData={setFormData}
                    section={eachSection}
                    sectionIndex={index}
                    TId={TId}
                    setTId={setTId}
                    sectionValuesArray={sectionValuesArray}
                    COU={COU}
                    // showLoader={showLoader}
                    // hideLoader={hideLoader}
                  />
                </React.Fragment>
              ) : null
            )}
        </div>
      </div>
    </>
  );
};

export default JobProfileForm;
