import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import NewHireInitiate from "./NewHireInitiate";
import { LoaderContext } from "../../Helpers/Context/Context";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { APICall } from "../../Helpers/APICalls";
import defaultProfileImgPic from "../../Assets/Images/profile.png";
import {
  UploadProfile,
  deleteDocument,
  documentUpload,
  downloadDocument,
  getAccountValidation,
  getAttributeValueDetailsV2,
  getDocumentGridData,
  getDocuments,
  getDropdowns,
  getEmployeeDetailsById,
  getGlobalValues,
  getParenPBasedonIncm,
  getProfilePicImg,
  getSearchableDropdowns,
  getSectionDetailsV2,
  getTabsData,
  postAttributeValueDetailsV2,
  viewDocument,
} from "../../Helpers/APIEndPoints/EndPoints";
import notify from "../../Helpers/ToastNotification";
import moment from "moment";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tabs,
  ThemeProvider,
  Tooltip,
  createTheme,
} from "@mui/material";
import { NationalIdCardType } from "../../Components/FromStructure/NationalIdCardTypeRegex";
import { ExpandMore } from "@mui/icons-material";
import SelectForm from "../../Components/SelectForm/SelectForm";
import InputForm from "../../Components/InputForm/InputForm";
import DateForm from "../../Components/DateForm/DateForm";
import uuid from "react-uuid";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { Modal } from "react-bootstrap";
import async from "react-select/dist/declarations/src/async/index";

const SectionComponent = ({
  TId,
  setTId,
  formData,
  setFormData,
  section: ogSection,
  sectionIndex,
  sectionValuesArray,
  SetSectionValuesArray,
  Globalv,
  COU,
}) => {
  const [hasValue, setHasValue] = useState(
    sectionValuesArray.filter((es) => es.sectionSystemName == ogSection.SN) ||
      null
  );
  const [globalEC, setGlobalEC] = useState(Globalv);
  const [section, setSection] = useState(ogSection);
  const [accordion, setAccordion] = useState(sectionIndex === 0 ? true : false);
  const [formErrors, setFormErrors] = useState<any>({});
  const [optionsObj, setOptionsObj] = useState<any>({});
  const [gridData, setGridData] = useState([]);
  const [displayGrid, setDisplayGrid] = useState([]);
  const [gridColumns, setGridColumns] = useState([]);
  const [ogAttributeSet, setOgAttributeSet] = useState({});
  const [ogFilledAttributeSet, setOgFilledAttributeSet] = useState([]);
  const [currentGridId, setCurrentGridId] = useState(null);
  const [deleteGridId, setDeleteGridId] = useState(null);
  const [showAttr, setshowAttr] = useState(section?.ISEDIT ? false : true);

  // value Object for section
  const [attributesData, setAttributesData] = useState<any>({});

  const [attributeSetData, setAttributeSetData] = useState<any>({});

  const [attributeSetCode, setAttributeSetCode] = useState(null);
  const [isOnce, setIsOnce] = useState(true);
  const [isValidateOnce, setIsValidateOnce] = useState(true);

  const [attributeValidation, setAttributeValidation] = useState<any>({});
  const [attributeSetValidation, setAttributeSetValidation] = useState<any>({});

  const [dataFilled, setDataFilled] = useState(false);

  const [sectionHasDocuments, setSectionHasDocuments] = useState(false);

  const { showLoader, hideLoader } = useContext(LoaderContext);

  const { userDetails, currentRoleId }: any = useUserContext();
  const [PageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);

  const [fillDDValuesForGrid, setFillDDValuesForGrid] = useState([]);
  const [dAutomapAttribute, setdAutomapAttribute] = useState([]);
  const [modalHeader, setModalHeader] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  let validAccount = useRef(false);
  const [confirmationPromise, setConfirmationPromise] = useState(null);
  const [ShowAttrSet, setShowAttrSet] = useState(false);
  const [isSameAsEmergency, setIsSameAsEmergency] = useState(false);
  const [isSameAsPermanent, setIsSameAsPermanent] = useState(false);
  const [isSameAsPresent, setIsSameAsPresent] = useState(false);
  const [multiDropdown, setMultiDropdown] = useState(true);

  const [resion, setResion] = useState(null);
  const getDropdownsData = async (AC, PID) => {
    await showLoader();
    const dropdownData = await APICall(getDropdowns, "POST", {
      AC,
      PID,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      RoleTypeId: 3,
    });

    if (
      dropdownData.data !== null &&
      dropdownData.data !== undefined &&
      dropdownData.data.length > 0
    ) {
      await hideLoader();
      return dropdownData.data;
    } else {
      await hideLoader();
      return [];
    }
  };

  // const getJobRelationDD = async (TId, event) => {
  //   await showLoader();
  //   const dropdownData = await APICall(getJobRelationDropDown, "POST", {
  //     RoleId: event.value,
  //     TID: TId,
  //   });
  //   if (
  //     dropdownData.data !== null &&
  //     dropdownData.data !== undefined &&
  //     dropdownData.data.length > 0
  //   ) {
  //     debugger
  //     await hideLoader();
  //     return dropdownData.data;
  //   } else {
  //     await hideLoader();
  //     return [];
  //   }
  // };
  //Document code start
  //Base64-false
  //URL =true
  const [selectedFilesForFlat, setSelectedFilesForFlat] = useState<
    {
      AC: string;
      Name: string;
      Data: string;
      IsValidFile: boolean;
      DocId: Number;
      Type: string;
      Context: string;
      DCVALUE: string;
      DVTILL: string;
      DSTATUS: string;
      DPSINCE: string;
      DPWITH: string;
    }[]
  >([]);

  const [selectedFilesForSet, setSelectedFilesForSet] = useState<
    {
      AC: string;
      Id: string | number;
      Name: string;
      Data: string;
      IsValidFile: boolean;
      DocId: Number;
      Type: string;
      Context: string;
      DCVALUE: string;
      DVTILL: string;
      DSTATUS: string;
      DPSINCE: string;
      DPWITH: string;
    }[]
  >([]);

  const fileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileValidation = (files, attribute) => {
    const { FS, FX, FC } = JSON.parse(attribute.V);

    // Split the validExtensionsString into an array
    const validExtensions = FX.split(",");

    let isValid = [];

    // validate file count
    if (files.length > FC) {
      notify(1, `Only ${FC} files allowed!`);
      return false;
    }
    // check extension of each file
    files.forEach((file) => {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();

      // Check if the file extension is allowed
      if (!validExtensions.includes("." + fileExtension)) {
        isValid.push(false);
      } else {
        isValid.push(true); //valid
      }
    });

    let returnValue = isValid.some((v) => v === false);

    if (returnValue) {
      notify(1, `Please upload file within this ${FX} extentsion`);
      return false;
    }

    files.forEach((file) => {
      const fileSize = file.size;
      const maxFileSizeBytes = FS * 1024 * 1024;
      if (fileSize <= maxFileSizeBytes) {
        isValid.push(true); //valid
      } else {
        isValid.push(false);
      }
    });

    returnValue = isValid.some((v) => v === false);

    if (returnValue) {
      notify(1, `File size exceeds ${FS}MB. Please select a smaller file.`);
      return false;
    } else {
      return true;
    }
  };

  const handleFileChangeForFlat = async (e, attribute) => {
    const { FS, FX, FC } = JSON.parse(attribute.V);

    const files = Array.prototype.slice.call(e.target.files);

    const isValid = handleFileValidation(files, attribute);
    if (isValid) {
      if (files && files.length > 0) {
        const base64Array: { Name: string; Data: string }[] = [];
        for (let i = 0; i < files.length; i++) {
          const base64 = (await fileToBase64(files[i])) as string;
          const base64WithoutPrefix = base64.split(",")[1];
          base64Array.push({ Name: files[i].name, Data: base64WithoutPrefix });
        }

        //check if AC present and file count matched

        const fileCount = selectedFilesForFlat.reduce((total, item) => {
          if (item.AC === attribute.AC) {
            return total + 1;
          }
          return total;
        }, 0);

        if (fileCount == FC) {
          notify(1, `Only ${FC} files allowed!`);
        } else {
          let type =
            JSON.parse(attribute.AD).CON === "National ID Information"
              ? attributeSetData["IDCATY"]?.label
              : JSON.parse(attribute.AD).TYP
              ? JSON.parse(attribute.AD).TYP
              : "";
          base64Array.forEach((file) => {
            setSelectedFilesForFlat((prevSelectedFiles) => [
              ...prevSelectedFiles,
              {
                AC: attribute.AC,
                Name: file.Name,
                Data: file.Data,
                IsValidFile: false,
                DocId: 0,
                Type: type,
                Context: JSON.parse(attribute.AD).CON
                  ? JSON.parse(attribute.AD).CON
                  : "",
                DCVALUE: file.Name,
                DVTILL: "",
                DSTATUS: "Verified",
                DPSINCE: "",
                DPWITH: "",
              },
            ]);
          });
        }
      }
    } else {
      e.target.value = "";
    }
  };

  //console.log(TId, "Converted file Base 64", selectedFilesForFlat);

  const handleFileChangeForSet = async (e, subAttribute) => {
    const { FS, FX, FC } = JSON.parse(subAttribute.V);

    const files = Array.prototype.slice.call(e.target.files);

    const isValid = handleFileValidation(files, subAttribute);
    if (isValid) {
      if (files && files.length > 0) {
        const base64Array: { Name: string; Data: string }[] = [];
        for (let i = 0; i < files.length; i++) {
          const base64 = (await fileToBase64(files[i])) as string;
          const base64WithoutPrefix = base64.split(",")[1];
          base64Array.push({ Name: files[i].name, Data: base64WithoutPrefix });
        }

        //check if AC present and file count matched

        const fileCount = selectedFilesForSet.reduce((total, item) => {
          if (
            item.AC === subAttribute.AC &&
            ((currentGridId && currentGridId === item.Id) || item.Id === 0)
          ) {
            return total + 1;
          }
          return total;
        }, 0);

        if (fileCount == FC) {
          notify(1, `Only ${FC} files allowed!`);
        } else {
          let type =
            JSON.parse(subAttribute.AD).CON === "National ID Information"
              ? attributeSetData["IDCATY"]?.label
              : JSON.parse(subAttribute.AD).TYP
              ? JSON.parse(subAttribute.AD).TYP
              : "";
          base64Array.forEach((file) => {
            setSelectedFilesForSet((prevSelectedFiles) => [
              ...prevSelectedFiles,
              {
                AC: subAttribute.AC,
                Name: file.Name,
                Data: file.Data,
                IsValidFile: false,
                Id: currentGridId ?? 0,
                DocId: 0,
                Type: type,
                Context: JSON.parse(subAttribute.AD).CON
                  ? JSON.parse(subAttribute.AD).CON
                  : "",
                DCVALUE:
                  JSON.parse(subAttribute.AD).CON === "National ID Information"
                    ? attributeSetData["PERIDNM"]
                    : file.Name,
                DVTILL:
                  JSON.parse(subAttribute.AD).CON === "National ID Information"
                    ? attributeSetData["NVALTIL"]
                    : "",
                DSTATUS: "Verified",
                DPSINCE: "",
                DPWITH: "",
              },
            ]);
          });
        }
      }
    } else {
      e.target.value = "";
    }
  };

  //console.log("setFills", selectedFilesForSet);

  const handleViewForFlat = async (fileAttribute, fileIndex) => {
    showLoader();
    if (
      fileAttribute.DocId != undefined &&
      fileAttribute.DocId != null &&
      fileAttribute.DocId != 0
    ) {
      const doc = await APICall(viewDocument, "POST", {
        TId,
        AC: fileAttribute.AC,
        DocId: fileAttribute.DocId,
      });

      if (doc && doc.data !== null) {
        //window.open(doc.data,'_blank');
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = doc.data;
        link.click();
      }
      hideLoader();
    } else {
      notify(1, "File Not Available");
    }
  };

  const handleDownloadFileFlat = async (fileAttribute, fileIndex) => {
    showLoader();
    if (
      fileAttribute.DocId != undefined &&
      fileAttribute.DocId != null &&
      fileAttribute.DocId != 0
    ) {
      const doc = await APICall(downloadDocument, "POST", {
        TId,
        AC: fileAttribute.AC,
        DocId: fileAttribute.DocId,
      });
      if (doc && doc.data !== null) {
        downloadBase64File(doc.data, fileAttribute.Name);
        hideLoader();
      } else {
        notify(1, "Something Went Wrong.");
        hideLoader();
      }
    } else {
      downloadBase64File(fileAttribute.Data, fileAttribute.Name);
      hideLoader();
    }
  };
  function downloadBase64File(base64String: string, fileName: string) {
    const mimeType = inferMimeType(base64String);
    const blob = base64toBlob(base64String, mimeType);

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  }
  function inferMimeType(base64String: string): string {
    const dataUrl = `data:application/octet-stream;base64,${base64String}`;
    const typeInfo = /^data:(.*?);/.exec(dataUrl);

    if (typeInfo && typeInfo[1]) {
      return typeInfo[1];
    }

    return "application/octet-stream";
  }
  function base64toBlob(base64String: string, mimeType: string): Blob {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], { type: mimeType });
  }
  const handleDeleteFileForFlat = async (fileAttribute, fileIndex) => {
    if (
      fileAttribute.DocId != undefined &&
      fileAttribute.DocId != null &&
      fileAttribute.DocId != 0
    ) {
      showLoader();
      const doc = await APICall(deleteDocument, "POST", {
        TId,
        AC: fileAttribute.AC,
        DocId: fileAttribute.DocId,
      });
      if (doc.message === "Success") {
        let remainingDocs = selectedFilesForFlat.filter(function (obj) {
          return obj.DocId !== fileAttribute.DocId;
        });
        setSelectedFilesForFlat(remainingDocs);
        hideLoader();
      } else {
        notify(1, "File not Deleted, Something Went Wrong.");
        hideLoader();
      }
    } else {
      setSelectedFilesForFlat((prevSelectedFiles) => {
        return prevSelectedFiles.filter(
          (item, attIndex) => fileIndex !== attIndex
        );
      });
    }
  };

  const handleDeleteFileForSet = async (fileAttribute, fileIndex) => {
    if (
      fileAttribute.DocId != undefined &&
      fileAttribute.DocId != null &&
      fileAttribute.DocId != 0
    ) {
      showLoader();
      const doc = await APICall(deleteDocument, "POST", {
        TId,
        AC: fileAttribute.AC,
        DocId: fileAttribute.DocId,
      });
      if (doc.message === "Success") {
        let remainingDocs = selectedFilesForSet.filter(function (obj) {
          return obj.DocId !== fileAttribute.DocId;
        });
        setSelectedFilesForSet(remainingDocs);
        hideLoader();
      } else {
        notify(1, "File not Deleted, Something Went Wrong.");
        hideLoader();
      }
    } else {
      setSelectedFilesForSet((prevSelectedFiles) => {
        return prevSelectedFiles.filter(
          (item, attIndex) => fileIndex !== attIndex
        );
      });
    }
  };

  // const uploadDocumentsAndGetURL = async () => {
  //   try {
  //     const response = await APICall(documentUpload, "POST", {
  //       MN: "EmployeeCentral",
  //       Files: [],
  //       SN: "Basic",
  //       TN: "Personal",
  //     });

  //     if (!response.ok) {
  //       throw new Error("Failed to upload documents");
  //     }

  //     const data = await response.json();
  //     const documentURLs = data.data.map((document) => ({
  //       name: document.internalName,
  //       url: document.internalValue,
  //     }));

  //     return documentURLs; //setattributedata  ---- documentURLs.stringfy karyche
  //   } catch (error) {
  //     throw new Error("Error uploading documents: " + error.message);
  //   }
  // };

  const [newGridIdCreated, setNewGridIdCreated] = useState(null);

  useEffect(() => {
    if (selectedFilesForSet.length > 0) {
      setSelectedFilesForSet((prevSelectedFiles) => {
        // Create a copy of the previous state with the updated Id values
        const updatedSelectedFiles = prevSelectedFiles.map((item, index) => {
          if (item.Id === undefined || item.Id === 0) {
            // If Id is undefined or 0, assign a new value
            return { ...item, Id: newGridIdCreated };
          } else {
            return item; // Keep existing Id values
          }
        });

        return updatedSelectedFiles;
      });
    }
  }, [newGridIdCreated]);

  //Document code end

  useEffect(() => {
    //add attribute in attributesData
    //  ;
    let attributes = {};
    let attributeSet = {};
    const gridColumnsArr = [];
    let dropdownOptionsObj = {};
    const sectionHasDocuments = [];
    section?.Attribute?.forEach(async (eachAttribute) => {
      console.log(eachAttribute, "eachAttribute");
      if (eachAttribute.AT === "Attribute") {
        //change value according to dt

        setAttributesData((prev) => ({
          ...prev,
          [eachAttribute.AC]:
            eachAttribute.DT === "DropdownSingle" ||
            eachAttribute.DT === "Date" ||
            eachAttribute.DT === "SearchableDropdownSingle" ||
            eachAttribute.DT === "Document" ||
            eachAttribute.DT === "DropdownMultiple"
              ? null
              : eachAttribute.DT === "Text" || eachAttribute.DT === "Number"
              ? ""
              : eachAttribute.DT === "Checkbox"
              ? false
              : "",
        }));

        attributes = {
          ...attributes,
          [eachAttribute.AC]:
            eachAttribute.DT === "DropdownSingle" ||
            eachAttribute.DT === "Date" ||
            eachAttribute.DT === "SearchableDropdownSingle" ||
            eachAttribute.DT === "Document" ||
            eachAttribute.DT === "DropdownMultiple"
              ? null
              : eachAttribute.DT === "Text" || eachAttribute.DT === "Number"
              ? ""
              : eachAttribute.DT === "Checkbox"
              ? false
              : "",
        };

        //for document
        if (eachAttribute.DT === "Document") {
          setSectionHasDocuments(true);
          sectionHasDocuments.push(eachAttribute.AC);
        }

        //attributeValidation
        setAttributeValidation((prev) => ({
          ...prev,
          [eachAttribute.AC]: JSON.parse(eachAttribute.V),
        }));

        setAttributeValidation((prev) => ({
          ...prev,
          [eachAttribute.AC]: {
            ...prev[eachAttribute.AC],
            DD: eachAttribute.DD !== null ? JSON.parse(eachAttribute.DD) : null,
            isDisable: false,
          },
        }));

        if (eachAttribute.DT === "DropdownSingle") {
          // api call for each dropdown option

          // const options = await getDropdownsData(eachAttribute.AC, null);

          dropdownOptionsObj = {
            ...dropdownOptionsObj,
            [eachAttribute.AC]: eachAttribute.OPT,
          };
          setOptionsObj((prev) => ({
            ...prev,
            [eachAttribute.AC]: eachAttribute.OPT,
          }));
          /*Hardcode to set Default Value.*/
          if (JSON.parse(eachAttribute.AD).DF !== undefined) {
            let filtredOPT = eachAttribute.OPT?.filter((o) => {
              if (o.label.toLowerCase() === JSON.parse(eachAttribute.AD).DF) {
                return o;
              }
            });
            if (filtredOPT.length > 0) {
              setAttributesData((prev) => ({
                ...prev,
                [eachAttribute.AC]: filtredOPT[0],
              }));
            }
          }
          /*Hardcode to set Default Value.*/
        } else if (eachAttribute.DT === "SearchableDropdownSingle") {
          dropdownOptionsObj = {
            ...dropdownOptionsObj,
            [eachAttribute.AC]: "SearchableDropdownSingle",
          };

          setOptionsObj((prev) => ({
            ...prev,
            [eachAttribute.AC]: "SearchableDropdownSingle",
          }));
        }
      } else if (eachAttribute.AT === "AttributeSet") {
        setAttributeSetCode(eachAttribute.AC);

        // Id for grid
        gridColumnsArr.push({
          name: "Id",
          label: "Id",
          options: { display: false },
        });

        eachAttribute.Attribute.forEach(async (subAttribute) => {
          console.log(subAttribute, "=>subAttribute");
          //for document
          if (subAttribute.DT === "Document") {
            setSectionHasDocuments(true);
            sectionHasDocuments.push(subAttribute.AC);
          }

          let display = JSON.parse(subAttribute.AD);
          display = display?.ISOG?.toLowerCase() === "true" ? true : false;

          //grid columns
          gridColumnsArr.push({
            name: subAttribute.AC,
            label: subAttribute.AN,
            options: { sort: false, display },
          });
          //attributeSetValidation
          setAttributeSetValidation((prev) => ({
            ...prev,
            [subAttribute.AC]: JSON.parse(
              subAttribute.AC === "ATTP" &&
                ((globalEC[0] != undefined &&
                  globalEC[0].ecLabel === "Staff") ||
                  (globalEC[0] != undefined &&
                    globalEC[0].ecLabel === "Retainer"))
                ? '{"ISM": "true","FS": "5","FX": ".docx,.doc,.pdf,.png,.jpg,.jpeg,.txt,.ppt,.pptx","FC": "1"}'
                : subAttribute.AC === "BNKATTCH" &&
                  ((globalEC[0] != undefined &&
                    globalEC[0].ecLabel === "Staff") ||
                    (globalEC[0] != undefined &&
                      globalEC[0].ecLabel === "Retainer"))
                ? '{"ISM": "true","FS": "5","FX": ".docx,.doc,.pdf,.png,.jpg,.jpeg,.txt,.ppt,.pptx","FC": "1"}'
                : // :subAttribute.AC === "ACT" && attributeSetData[subAttribute.AC].label!==undefined && attributeSetData[subAttribute.AC].label==="Car/vehicle"
                  // ?'{"ISM": "true"}'
                  subAttribute.V
            ),
          }));

          setAttributeSetValidation((prev) => ({
            ...prev,
            [subAttribute.AC]: {
              ...prev[subAttribute.AC],
              DD: subAttribute.DD !== null ? JSON.parse(subAttribute.DD) : null,
              isDisable: false,
            },
          }));

          //change value according to dt

          setAttributeSetData((prev) => ({
            ...prev,
            [subAttribute.AC]:
              subAttribute.DT === "DropdownSingle" ||
              subAttribute.DT === "Date" ||
              subAttribute.DT === "SearchableDropdownSingle" ||
              subAttribute.DT === "Document"
                ? null
                : subAttribute.DT === "Text" || subAttribute.DT === "Number"
                ? ""
                : subAttribute.DT === "Checkbox"
                ? false
                : "",
          }));

          attributeSet = {
            ...attributeSet,
            [subAttribute.AC]:
              subAttribute.DT === "DropdownSingle" ||
              subAttribute.DT === "Date" ||
              subAttribute.DT === "SearchableDropdownSingle" ||
              subAttribute.DT === "Document" ||
              subAttribute.DT === "DropdownMultiple"
                ? null
                : subAttribute.DT === "Text" || subAttribute.DT === "Number"
                ? ""
                : subAttribute.DT === "Checkbox"
                ? false
                : "",
          };

          if (
            subAttribute.DT === "DropdownSingle" ||
            subAttribute.DT === "DropdownMultiple"
          ) {
            // const options = await getDropdownsData(subAttribute.AC, null);

            dropdownOptionsObj = {
              ...dropdownOptionsObj,
              [subAttribute.AC]: subAttribute.OPT,
            };

            if (
              subAttribute.AC === "STSE" &&
              section.SN === "FamilyMemberDetails"
            ) {
              if (subAttribute.OPT?.length > 0) {
                setAttributeSetData((prev) => ({
                  ...prev,
                  [subAttribute.AC]: subAttribute.OPT?.filter(
                    (x) => x.label.toLowerCase() === "living"
                  )[0],
                }));

                attributeSet = {
                  ...attributeSet,
                  [subAttribute.AC]: subAttribute.OPT?.filter(
                    (x) => x.label.toLowerCase() === "living"
                  )[0],
                };
              }
            }

            if (
              subAttribute.AC === "PVSEMP" &&
              section.SN === "PreviousEmployment"
            ) {
              if (subAttribute.OPT?.length > 0) {
                setAttributeSetData((prev) => ({
                  ...prev,
                  [subAttribute.AC]: subAttribute.OPT?.filter(
                    (x) => x.label.toLowerCase() === "living"
                  )[0],
                }));

                attributeSet = {
                  ...attributeSet,
                  [subAttribute.AC]: subAttribute.OPT?.filter(
                    (x) => x.label.toLowerCase() === "living"
                  )[0],
                };
              }
            }

            setOptionsObj((prev) => ({
              ...prev,
              [subAttribute.AC]: subAttribute.OPT,
            }));

            /*Hardcode to set Default Value.*/
            if (JSON.parse(subAttribute.AD).DF !== undefined) {
              let filtredOPT = subAttribute.OPT?.filter((o) => {
                if (o.label.toLowerCase() === JSON.parse(subAttribute.AD).DF) {
                  return o;
                }
              });
              if (filtredOPT.length > 0) {
                setAttributeSetData((prev) => ({
                  ...prev,
                  [subAttribute.AC]: filtredOPT[0],
                }));

                attributeSet = {
                  ...attributeSet,
                  [subAttribute.AC]: filtredOPT[0],
                };
                //console.log(attributeSet);
              }
            }
            /*Hardcode to set Default Value.*/
          } else if (subAttribute.DT === "SearchableDropdownSingle") {
            dropdownOptionsObj = {
              ...dropdownOptionsObj,
              [subAttribute.AC]: "SearchableDropdownSingle",
            };

            setOptionsObj((prev) => ({
              ...prev,
              [subAttribute.AC]: "SearchableDropdownSingle",
            }));
          }
        });
      }
    });

    // setAttributesData((prev) => ({
    //   ...prev,
    //   ...attributes,
    // }));

    // setAttributeSetData((prev) => ({
    //   ...prev,
    //   ...attributeSet,
    // }));

    setOgAttributeSet(attributeSet);
    setGridColumns((prev) => [...prev, ...gridColumnsArr, action]);

    // setOptionsObj((prev) => ({ ...prev, ...dropdownOptionsObj }));

    const fillData = async () => {
      await showLoader();
      if (isOnce && TId !== 0) {
        //fetch attributes/set data

        //check if not first time then call api
        let attributesRes = null;
        // let bodyObj = {
        //   mn: "EmployeeCentral",
        //   in: "EC_Form",
        //   tn: formData.tn,
        //   SN: section.SN,
        //   TID: TId,
        //   ISH: "false",
        // };
        // (async () => {
        //   const response = await APICall(
        //     getAttributeValueDetails,
        //     "POST",
        //     bodyObj
        //   );

        if (hasValue.length > 0 && hasValue[0].attributes.length > 0) {
          attributesRes = hasValue[0].attributes;
          // handle data

          if (attributesRes !== null && attributesRes.length > 0) {
            let newAttributeData: any = { ...attributes };
            if (sectionHasDocuments.length > 0) {
              // getDocuments for Flat
              for (const AC of sectionHasDocuments) {
                if (newAttributeData.hasOwnProperty(AC)) {
                  await showLoader();
                  const getDocument = await APICall(getDocuments, "POST", {
                    TId,
                    AC: AC,
                  });

                  if (getDocument && getDocument.data !== null) {
                    setSelectedFilesForFlat(getDocument.data.files);
                  } else {
                    setSelectedFilesForFlat([]);
                  }
                  await hideLoader();
                }
              }
            }
            //loop for setting flat attributes on edit
            for (const eachAttribute of attributesRes) {
              if (eachAttribute.type === "Attribute") {
                let { key, value: valueForField } = eachAttribute;

                if (
                  dropdownOptionsObj[key] !== undefined &&
                  dropdownOptionsObj[key] !== "SearchableDropdownSingle"
                ) {
                  //for filling normal dropdown on edit
                  const options = dropdownOptionsObj[key];

                  if (options !== undefined && options?.length > 0) {
                    const option = options?.find(
                      (attri) => attri.value == valueForField
                    );

                    newAttributeData = {
                      ...newAttributeData,
                      [key]: option === undefined ? null : option,
                    };
                  }
                } else if (
                  dropdownOptionsObj[key] !== undefined &&
                  dropdownOptionsObj[key] === "SearchableDropdownSingle"
                ) {
                  //for filling searchable dropdown on edit
                  if (valueForField !== undefined) {
                    const DDResponse = await getSearchableDropdownAPI(
                      "",
                      key,
                      null,
                      valueForField,
                      "attribute",
                      0
                    );
                    newAttributeData = {
                      ...newAttributeData,
                      [key]:
                        DDResponse === undefined
                          ? null
                          : {
                              label: DDResponse.label,
                              value: DDResponse.value,
                            },
                    };
                  }
                } else {
                  //filling other data

                  valueForField =
                    typeof valueForField === "string" &&
                    valueForField.toLowerCase() === "true"
                      ? true
                      : typeof valueForField === "string" &&
                        valueForField.toLowerCase() === "false"
                      ? false
                      : valueForField !== null && valueForField !== undefined
                      ? valueForField.toString()
                      : valueForField;

                  // format date

                  const dateString = valueForField;
                  const allowedFormat = [
                    "DD-MM-YYYY hh.mm.ss A",
                    "DD/MM/YYYY hh.mm.ss A",
                  ];

                  const isValidDate = moment(
                    dateString,
                    allowedFormat,
                    true
                  ).isValid();

                  const isValidBeforeDate = moment(
                    dateString,
                    "DD-MMM-YYYY",
                    true
                  ).isValid();
                  if (isValidBeforeDate && dateString === "01-Jan-1900") {
                    valueForField = null;
                  }

                  if (
                    isValidDate &&
                    dateString !== "01-01-0001 12.00.00 AM" &&
                    dateString !== "1/1/0001 12:00:00 AM"
                  ) {
                    const parsedDate = moment(valueForField, allowedFormat[0]);
                    const formattedDate = parsedDate.format("DD-MMM-YYYY");
                    valueForField = formattedDate;
                  } else if (
                    dateString === "01-01-0001 12.00.00 AM" ||
                    dateString === "1/1/0001 12:00:00 AM"
                  ) {
                    valueForField = null;
                  }

                  newAttributeData = {
                    ...newAttributeData,
                    [key]: valueForField,
                  };

                  //hard code to fill display name on load
                  if (section.SN === "Basic") {
                    let name =
                      newAttributeData.MN !== null ||
                      newAttributeData.MN !== undefined ||
                      newAttributeData.MN !== ""
                        ? `${newAttributeData.FN} ${newAttributeData.MN} ${newAttributeData.LN}`.trim()
                        : `${newAttributeData.FN} ${newAttributeData.LN}`.trim();
                    newAttributeData = {
                      ...newAttributeData,
                      ["DN"]: `${name}`.trim(),
                    };
                  }
                }
              }
            }
            setAttributesData(newAttributeData);

            //loop for setting set attributes on edit
            for (const eachAttribute of attributesRes) {
              if (eachAttribute.type === "AttributeSet") {
                //AttributesSet

                if (
                  Object.keys(attributeSet).length > 0 &&
                  eachAttribute.details !== null &&
                  eachAttribute.details.length > 0
                ) {
                  setAttributeSetCode(eachAttribute.key);

                  if (sectionHasDocuments.length > 0) {
                    // getDocuments for Set
                    for (const AC of sectionHasDocuments) {
                      if (attributeSet.hasOwnProperty(AC)) {
                        await showLoader();
                        const getDocument = await APICall(
                          getDocuments,
                          "POST",
                          {
                            TId,
                            AC: AC,
                          }
                        );

                        if (getDocument && getDocument.data !== null) {
                          setSelectedFilesForSet(getDocument.data.files);
                        } else {
                          setSelectedFilesForSet([]);
                        }
                        await hideLoader();
                      }
                    }
                  }

                  const details = eachAttribute.details;

                  if (details.length > 0) {
                    let newAttributeSetData = { ...attributeSet };
                    let newAttributeSetDataForGrid = { ...attributeSet };
                    let dropdownOptions = { ...dropdownOptionsObj };

                    const filledData = [];
                    const gridData = [];
                    details.forEach(async (detail) => {
                      console.log(details, "details");
                      //add isActive for all details
                      detail.IsActive = true;

                      Object.entries(detail).forEach(async (attribute) => {
                        const [Code, Value]: any = attribute;

                        if (
                          Object.keys(dropdownOptions).find(
                            (option) =>
                              option.toLowerCase() === Code.toLowerCase()
                          ) &&
                          dropdownOptions[Code] !== "SearchableDropdownSingle"
                        ) {
                          //debugger;
                          const options = dropdownOptions[Code];
                          if (options !== undefined && options.length > 0) {
                            const option = options.find(
                              (attri) => attri.value == Value
                            );

                            newAttributeSetDataForGrid = {
                              ...newAttributeSetDataForGrid,
                              [Code]:
                                option === undefined ? null : option?.label,
                            };

                            newAttributeSetData = {
                              ...newAttributeSetData,
                              [Code]: option === undefined ? null : option,
                            };
                          }
                        } else if (
                          Object.keys(dropdownOptions).find(
                            (option) =>
                              option.toLowerCase() === Code.toLowerCase()
                          ) &&
                          dropdownOptions[Code] === "SearchableDropdownSingle"
                        ) {
                          if (Value !== undefined) {
                            await getSearchableDropdownAPI(
                              "",
                              Code,
                              null,
                              Value,
                              "attributeSet",
                              detail.Id
                            );
                          }
                        } else {
                          //check type later when actual data

                          let valueForGrid =
                            typeof Value === "string" &&
                            Value.toLowerCase() === "true"
                              ? true
                              : typeof Value === "string" &&
                                Value.toLowerCase() === "false"
                              ? false
                              : Value !== null
                              ? Value.toString()
                              : Value;

                          // format date

                          const dateString = Value;
                          const allowedFormatGrid = [
                            "DD-MM-YYYY hh.mm.ss A",
                            "DD/MM/YYYY hh.mm.ss A",
                            "DD-MMM-YYYY",
                          ];

                          const isValidDateGrid = moment(
                            dateString,
                            allowedFormatGrid,
                            true
                          ).isValid();
                          //console.log('Value of Date:',Value, isValidDateGrid)
                          if (
                            isValidDateGrid &&
                            dateString !== "01-01-0001 12.00.00 AM" &&
                            dateString !== "1/1/0001 12:00:00 AM" &&
                            dateString !== "01-Jan-0001" &&
                            dateString !== "01-Jan-1900"
                          ) {
                            // const parsedDate = moment(Value, allowedFormat[0]);
                            // console.log('Parse Date Format: ', parsedDate);
                            // const formattedDate =
                            //   parsedDate.format("DD-MM-YYYY");
                            // valueForGrid = formattedDate;
                            const formattedDate = moment(
                              Value,
                              "DD-MMM-YYYY"
                            ).format("DD/MM/YYYY");
                            //console.log('Formatted Date: ', formattedDate);
                            valueForGrid = formattedDate;
                          } else if (
                            dateString === "01-01-0001 12.00.00 AM" ||
                            dateString === "1/1/0001 12:00:00 AM" ||
                            dateString === "01-Jan-0001" ||
                            dateString === "01-Jan-1900"
                          ) {
                            valueForGrid = null;
                          }

                          const allowedFormatField = [
                            "DD-MM-YYYY hh.mm.ss A",
                            "DD/MM/YYYY hh.mm.ss A",
                          ];

                          const isValidDateField = moment(
                            dateString,
                            allowedFormatField,
                            true
                          ).isValid();
                          let valueForField =
                            typeof Value === "string" &&
                            Value.toLowerCase() === "true"
                              ? true
                              : typeof Value === "string" &&
                                Value.toLowerCase() === "false"
                              ? false
                              : typeof Value === "boolean"
                              ? Value
                              : Value !== null
                              ? Value.toString()
                              : Value;

                          if (
                            isValidDateField &&
                            dateString !== "01-01-0001 12.00.00 AM" &&
                            dateString !== "1/1/0001 12:00:00 AM"
                          ) {
                            const parsedDate = moment(
                              Value,
                              allowedFormatField[0]
                            );
                            const formattedDate =
                              parsedDate.format("DD-MMM-YYYY");
                            valueForField = formattedDate;
                          } else if (
                            dateString === "01-01-0001 12.00.00 AM" ||
                            dateString === "1/1/0001 12:00:00 AM"
                          ) {
                            valueForField = null;
                          }
                          const isValidBeforeDate = moment(
                            dateString,
                            "DD-MMM-YYYY",
                            true
                          ).isValid();
                          if (
                            isValidBeforeDate &&
                            (dateString === "01-Jan-1900" ||
                              dateString === "01-Jan-0001")
                          ) {
                            valueForField = null;
                          }

                          newAttributeSetDataForGrid = {
                            ...newAttributeSetDataForGrid,
                            [Code]: valueForGrid,
                          };

                          newAttributeSetData = {
                            ...newAttributeSetData,
                            [Code]: valueForField,
                          };
                        }
                      });
                      gridData.push(newAttributeSetDataForGrid);
                      filledData.push(newAttributeSetData);
                    });

                    // console.log(filledData);
                    // console.log(gridData);
                    setDisplayGrid(gridData);
                    setGridData(gridData);
                    setOgFilledAttributeSet(filledData);
                  }
                  // setIsOnce(false);
                }
              }
            }
          }
        }
        setIsOnce(false);
        // })();
      }
      await hideLoader();
    };

    const fillTimer = setTimeout(() => {
      clearTimeout(fillTimer);
      fillData();
    }, 2000);

    //save in state
    return () => {
      clearTimeout(fillTimer);
    };
  }, []);

  // console.log(attributeSetValidation);

  // console.log(gridColumns);

  // console.log(attributesData);

  // console.log(attributeSetData);

  // console.log(optionsObj);

  // console.log(ogFilledAttributeSet);
  // console.log(gridData);

  // useEffect(() => {}, [attributesData, attributeSetData, optionsObj]);

  const checkSameAddressType = (event, checkboxName) => {
    // Sanket Method for checking same address type
    if (checkboxName === "emergency") {
      setIsSameAsEmergency(!isSameAsEmergency);
    } else if (checkboxName === "permanent") {
      setIsSameAsPermanent(!isSameAsPermanent);
    } else if (checkboxName === "present") {
      setIsSameAsPresent(!isSameAsPresent);
    }
  };
  useEffect(() => {
    // ;
    if (
      attributesData !== undefined &&
      Object.keys(attributesData).length > 0 &&
      attributeValidation !== undefined &&
      Object.keys(attributeValidation).length > 0
    ) {
      if (
        (isValidateOnce && isOnce === false && TId !== 0) ||
        (isValidateOnce && isOnce === true && TId === 0)
      ) {
        // console.log(attributeValidation);

        let attributeValues = { ...attributesData }; // for checking values
        let attributeValObj = { ...attributeValidation };
        Object.keys(attributeValObj).forEach(async (attribute) => {
          if (
            attributeValObj[attribute].DD !== null &&
            attributeValObj[attribute].DD.length > 0
          ) {
            let ad = attributeValObj[attribute].DD;

            ad.forEach(async (record) => {
              //   CCode: "POLENDT",
              // PValue: "false",
              // Condition: "equalTo",
              // Flag: "disable",
              // PDT: "checkbox",
              // CDT: "checkbox",
              let CCode = record.CCode;
              let PValue = record.PValue;
              let Condition = record.Condition;
              let Flag = record.Flag;
              let PDT = record.PDT;
              let CDT = record.CDT;

              if (PDT.toLowerCase() === "checkbox") {
                if (
                  attributeValues[attribute].toString().toLowerCase() ===
                    PValue.toLowerCase() ||
                  attributeValues[attribute].toString().toLowerCase() === ""
                ) {
                  if (Flag === "disable") {
                    attributeValObj[CCode].isDisable = true;
                    attributeValObj[CCode].ISM = "false";
                  }
                } else if (
                  attributeValues[attribute].toString().toLowerCase() !==
                  PValue.toLowerCase()
                ) {
                  if (Flag === "disable") {
                    attributeValObj[CCode].isDisable = false;
                    attributeValObj[CCode].ISM = "true";
                  }
                }
              } else if (PDT === "DropdownSingle") {
                if (attribute === "MARITLST") {
                  if (attributeValues[attribute]?.label === "Married") {
                    attributeValObj["ProofBD"].ISM = "false";
                  }
                }
                if (
                  attributeValues[attribute] !== null &&
                  attributeValues[attribute].label.toString().toLowerCase() ===
                    PValue.toLowerCase()
                ) {
                  //check condition
                  if (Flag === "disable") {
                    attributeValObj[CCode].isDisable = true;
                    attributeValObj[CCode].ISM = "false";
                  }
                } else {
                  //console.log(PDT, CCode, attributeValObj);
                  if (attributeValObj[CCode] !== undefined) {
                    attributeValObj[CCode].isDisable = false;
                    attributeValObj[CCode].ISM =
                      attributeValues[attribute]?.label === "Married"
                        ? "true"
                        : CCode === "MARITLSTS"
                        ? "false"
                        : attributeValues[attribute]?.label === "Worker"
                        ? "true"
                        : CCode === "SC"
                        ? "false"
                        : "true";
                  }
                }
              } else if (PDT === "Document") {
              }
            });
          }
          //else {
          //   if (attribute === "Gen") {
          //     if (attributeValues[attribute]?.label === "Female") {
          //       attributeValObj["MAN"].isDisable = false;
          //       attributeValObj["MAN"].ISM = "true";
          //     }
          //   }
          // }
        });
        setAttributeValidation((prev) => ({ ...prev, ...attributeValObj }));
        setIsValidateOnce(false);
      }
    }
  }, [attributesData, isOnce]);

  const action = {
    name: "Id",
    label: "Action",
    options: {
      filter: false,
      sort: false,
      display: true,
      setCellProps: () => ({
        style: { textAlign: "center" },
      }),
      setCellHeaderProps: () => ({
        style: { textAlign: "center" },
      }),
      customBodyRender: (value, tableMeta) => {
        let Id = tableMeta.tableData[tableMeta.rowIndex].Id;

        return (
          <div className="d-flex justify-content-center">
            {section?.ISEDIT && (
              <Tooltip title="edit">
                <a
                  className="mr-2"
                  onClick={(e) => {
                    e.preventDefault();
                    setFormErrors({});
                    setMultiDropdown(false);
                    setShowAttrSet(true);
                    setCurrentGridId(Id);
                  }}
                >
                  <i className="fas fa-edit"></i>
                </a>
              </Tooltip>
            )}
            {section?.ISDEL && (
              <Tooltip title="delete">
                <a
                  className="mr-2"
                  onClick={(e) => {
                    e.preventDefault();
                    setFormErrors({});
                    setDeleteGridId(Id);
                  }}
                >
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </a>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  };

  useEffect(() => {
    // ;
    if (currentGridId) {
      let attributeSetData = ogFilledAttributeSet.find(
        (record) => record.Id === currentGridId
      );
      if (attributeSetData.PA != undefined && attributeSetData.PA != null) {
        if (
          attributeSetData.PA.label.toLowerCase() === "emergency" ||
          attributeSetData.PA.label.toLowerCase() === "permanent"
        ) {
          setOptionsObj((prev) => ({
            ...prev,
            ["ADRTYP"]: [],
          }));

          let attributeSetValObj = {
            ...attributeSetValidation,
          };
          attributeSetValObj.ADRTYP.ISM = "false";
          setAttributeSetValidation((prev) => ({
            ...prev,
            ...attributeSetValObj,
          }));
        }
      }
      if (
        attributeSetData.PVSEMP != undefined &&
        attributeSetData.PVSEMP != null
      ) {
        dependentValidations(attributeSetData.PVSEMP, "PVSEMP", "Attributeset");
      }
      setAttributeSetData(attributeSetData);
    }
  }, [currentGridId]);

  useEffect(() => {
    //  ;
    if (deleteGridId && currentGridId === null) {
      //to disable grid delete button when edit is active
      setGridData((prev) => {
        const newState = prev.map((record) => {
          if (record.Id === deleteGridId) {
            return {
              ...record,
              IsActive: false,
            };
          }
          return record;
        });
        return newState;
      });

      setOgFilledAttributeSet((prev) => {
        const newState = prev.map((record) => {
          if (record.Id === deleteGridId) {
            if (
              record.PCE?.label === "Fixed Pay" ||
              record.PCE?.label === "Variable Pay"
            ) {
              setAttributesData((prev) => ({
                ...prev,
                ASA: attributesData["ASA"]
                  ? (
                      parseInt(attributesData["ASA"]) - parseInt(record.AMT)
                    ).toString()
                  : "0",
              }));
            } else if (record.PCE?.label === "Stipend") {
              setAttributesData((prev) => ({
                ...prev,
                ASA: "0",
              }));
            }
            return {
              ...record,
              IsActive: false,
            };
          }
          return record;
        });
        return newState;
      });

      setDisplayGrid((prev) =>
        prev.filter((record) => record.Id !== deleteGridId)
      );

      //delete documents for that grid record
      let fileToDelete = selectedFilesForSet.filter((sf) => {
        return sf.Id === deleteGridId;
      });
      if (fileToDelete.length > 0) {
        handleDeleteFileForSet(fileToDelete[0], 0);
      }
      // setSelectedFilesForSet((prevSelectedFiles) => {
      //   return prevSelectedFiles.filter((item) => item.Id !== deleteGridId);
      // });
    }
    setDeleteGridId(null);
  }, [deleteGridId]);

  const gridOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    // count: gridData.length,
    rowsPerPage: PageSize,
    page: page,
    serverSide: false,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",

    //standard | vertical | simple
    onChangeRowsPerPage: (num) => {
      //   setLimit(num);
      //   setNxtPgInfo("");
      //   setPrevPgInfo("");
      //   setIsPrevOrNext("");
    },
    // onSearchChange: (searchText) => {
    //   if (searchText !== null) {
    //     setSearchText(searchText);
    //   } else {
    //     setSearchText("");
    //   }
    // },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        // await setDashboardSortColumn(sortColumn);
        // await setDashboardSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        // await setDashboardSortColumn(sortColumn);
        // await setDashboardSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      // await setPage(page);
      // await setDashboardStart(page * dashboardPageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const dependentValidations = (
    targetValue,
    targetAttributeCode,
    attributeType
  ) => {
    //  ;
    if (attributeType === "Attribute") {
      let attributeValues = { ...attributesData }; // for checking values
      let attributeValObj = { ...attributeValidation };

      if (
        attributeValObj[targetAttributeCode].DD !== null &&
        attributeValObj[targetAttributeCode].DD.length > 0
      ) {
        let ad = attributeValObj[targetAttributeCode].DD;

        ad.forEach(async (record) => {
          let CCode = record.CCode;
          let PValue = record.PValue;
          let Condition = record.Condition;
          let Flag = record.Flag;
          let PDT = record.PDT;
          let CDT = record.CDT;

          if (PDT === "DropdownSingle") {
            if (
              targetValue &&
              targetValue.label.toString().toLowerCase() ===
                PValue.toLowerCase()
            ) {
              //check condition
              if (Flag === "disable") {
                attributeValObj[CCode].isDisable = true;
                attributeValObj[CCode].ISM = "false";
              }
            } else {
              attributeValObj[CCode].isDisable = false;
              attributeValObj[CCode].ISM =
                targetValue?.label === "Married"
                  ? "true"
                  : CCode === "MARITLSTS"
                  ? "false"
                  : targetValue?.label === "Worker"
                  ? "true"
                  : CCode === "SC"
                  ? "false"
                  : CCode === "VACXDT"
                  ? "false"
                  : CCode === "VACXDT2"
                  ? "false"
                  : CCode === "VACXDT3"
                  ? "false"
                  : "true";
            }
          } else if (PDT.toLowerCase() === "checkbox") {
            if (targetValue.toString().toLowerCase() === PValue.toLowerCase()) {
              //check condition
              if (Flag === "disable") {
                attributeValObj[CCode].isDisable = true;
                attributeValObj[CCode].ISM = "false";
              }
            } else {
              attributeValObj[CCode].isDisable = false;
              attributeValObj[CCode].ISM = "true";
            }
          }

          //clear values based to CDT
          attributeValues[CCode] =
            CDT === "DropdownSingle" ||
            CDT === "Date" ||
            CDT === "SearchableDropdownSingle"
              ? null
              : CDT === "Text" || CDT === "Number"
              ? ""
              : CDT === "Checkbox"
              ? false
              : "";
        });

        attributeValues[targetAttributeCode] = targetValue; //imp
        setFormErrors({});
        setAttributeValidation((prev) => ({ ...prev, ...attributeValObj }));
        setAttributesData((prev) => ({
          ...prev,
          ...attributeValues,
        }));
      }
    } else {
      let attributeValues = { ...attributeSetData }; // for checking values
      let attributeValObj = { ...attributeSetValidation };

      if (
        attributeValObj[targetAttributeCode].DD !== null &&
        attributeValObj[targetAttributeCode].DD.length > 0
      ) {
        let ad = attributeValObj[targetAttributeCode].DD;

        ad.forEach(async (record) => {
          let CCode = record.CCode;
          let PValue = record.PValue;
          let Condition = record.Condition;
          let Flag = record.Flag;
          let PDT = record.PDT;
          let CDT = record.CDT;

          if (PDT === "DropdownSingle") {
            if (
              targetValue &&
              targetValue.label.toString().toLowerCase() ===
                PValue.toLowerCase()
            ) {
              //check condition
              if (Flag === "disable") {
                attributeValObj[CCode].isDisable = true;
                attributeValObj[CCode].ISM = "false";
              }
            } else {
              attributeValObj[CCode].isDisable = false;
              attributeValObj[CCode].ISM =
                targetValue?.label === "Married"
                  ? "true"
                  : CCode === "MARITLSTS"
                  ? "false"
                  : targetValue?.label === "Worker"
                  ? "true"
                  : CCode === "SC"
                  ? "false"
                  : CCode === "VACXDT"
                  ? "false"
                  : CCode === "VACXDT2"
                  ? "false"
                  : CCode === "VACXDT3"
                  ? "false"
                  : "true";
            }
          } else if (PDT.toLowerCase() === "checkbox") {
            if (targetValue.toString().toLowerCase() === PValue.toLowerCase()) {
              //check condition
              if (Flag === "disable") {
                attributeValObj[CCode].isDisable = true;
                attributeValObj[CCode].ISM = "false";
              }
            } else {
              attributeValObj[CCode].isDisable = false;
              attributeValObj[CCode].ISM = "true";
            }
          }

          //clear values based to CDT
          attributeValues[CCode] =
            CDT === "DropdownSingle" ||
            CDT === "Date" ||
            CDT === "SearchableDropdownSingle"
              ? null
              : CDT === "Text" || CDT === "Number"
              ? ""
              : CDT === "Checkbox"
              ? false
              : "";
        });

        attributeValues[targetAttributeCode] = targetValue; //imp
        setFormErrors({});
        setAttributeSetValidation((prev) => ({ ...prev, ...attributeValObj }));
        setAttributeSetData((prev) => ({
          ...prev,
          ...attributeValues,
        }));
      }
    }
  };
  const isValidEmail = (email, emailPattern) => {
    const regex = new RegExp(emailPattern);
    return regex.test(email);
  };

  const validateData = (Attribute) => {
    let errorObj = {};
    let isError = false;
    Attribute.forEach(async (subAttribute) => {
      if (subAttribute.AT === "Attribute") {
        // const validation = JSON.parse(subAttribute.V);
        const validation = attributeSetValidation[subAttribute.AC];

        if (validation.ISM.toLowerCase() === "true") {
          if (subAttribute.DT === "Text") {
            if (attributeSetData[subAttribute.AC].trim() === "") {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else if (
              attributeSetData[subAttribute.AC]?.length > validation.MaxC
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: `Max ${validation.MaxC} characters allowed`,
              };
            } else if (validation.PTN != undefined) {
              if (
                isValidEmail(
                  attributeSetData[subAttribute.AC],
                  validation.PTN
                ) != true
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: `Incorrect format.`,
                };
              }
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }

            //Validate business email welspun.com

            if (
              subAttribute.AC === "EA" &&
              attributeSetData[subAttribute.AC] !== ""
            ) {
              if (
                attributeSetData["ETP"] !== null &&
                attributeSetData["ETP"].label.toLowerCase() === "business"
              ) {
                if (
                  !/^[a-z0-9](\.?[a-z0-9._]){4,}@welspun\.com$/.test(
                    attributeSetData["EA"].toLowerCase()
                  )
                ) {
                  errorObj = {
                    ...errorObj,
                    [subAttribute.AC]: `Invalid Email`,
                  };
                }
              } else if (
                attributeSetData["ETP"] !== null &&
                attributeSetData["ETP"].label.toLowerCase() === "personal"
              ) {
                if (
                  !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
                    attributeSetData["EA"].toLowerCase()
                  )
                ) {
                  errorObj = {
                    ...errorObj,
                    [subAttribute.AC]: `Invalid Email`,
                  };
                } else if (
                  /^[a-z0-9](\.?[a-z0-9._]){4,}@welspun\.com$/.test(
                    attributeSetData["EA"].toLowerCase()
                  )
                ) {
                  errorObj = {
                    ...errorObj,
                    [subAttribute.AC]: `Invalid domain @welspun.com for personal email.`,
                  };
                }
              }
            }

            //hard code to validate personalId based on selected IdCardType

            if (
              subAttribute.AC === "PERIDNM" &&
              attributeSetData[subAttribute.AC] !== ""
            ) {
              if (attributeSetData["IDCATY"] !== null) {
                switch (attributeSetData["IDCATY"].label.toLowerCase()) {
                  case "aadhar card":
                    if (
                      !NationalIdCardType.AADHAR.test(
                        attributeSetData[subAttribute.AC]
                      )
                    ) {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "Invalid Aadhar",
                      };
                    } else {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "",
                      };
                    }

                    break;
                  case "pan card":
                    if (
                      !NationalIdCardType.PAN.test(
                        attributeSetData[subAttribute.AC]
                      )
                    ) {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "Invalid PAN",
                      };
                    } else {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "",
                      };
                    }

                    break;
                  case "driving license":
                    if (
                      !NationalIdCardType.DRIVINGLICENCE.test(
                        attributeSetData[subAttribute.AC]
                      )
                    ) {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "Invalid Driving license",
                      };
                    } else {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "",
                      };
                    }

                    break;
                  case "voter id":
                    if (
                      !NationalIdCardType.VOTERID.test(
                        attributeSetData[subAttribute.AC]
                      )
                    ) {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "Invalid voter Id",
                      };
                    } else {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "",
                      };
                    }
                    break;
                  case "esic number":
                    if (
                      !NationalIdCardType.ESIC.test(
                        attributeSetData[subAttribute.AC]
                      )
                    ) {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "Invalid ESIC Number",
                      };
                    } else {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "",
                      };
                    }
                    break;
                  case "passport":
                    if (
                      !NationalIdCardType.PASSPORT.test(
                        attributeSetData[subAttribute.AC]
                      )
                    ) {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "Invalid Passport",
                      };
                    } else {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "",
                      };
                    }
                    break;
                  case "pran":
                    if (
                      !NationalIdCardType.PRAN.test(
                        attributeSetData[subAttribute.AC]
                      )
                    ) {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "Invalid PRAN",
                      };
                    } else {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "",
                      };
                    }
                    break;
                  case "provident fund":
                    if (
                      !NationalIdCardType.PF.test(
                        attributeSetData[subAttribute.AC]
                      )
                    ) {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "Invalid Provident fund",
                      };
                    } else {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "",
                      };
                    }
                    break;
                  case "uan":
                    if (
                      !NationalIdCardType.UAN.test(
                        attributeSetData[subAttribute.AC]
                      )
                    ) {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "Invalid UAN",
                      };
                    } else {
                      errorObj = {
                        ...errorObj,
                        [subAttribute.AC]: "",
                      };
                    }
                    break;

                  default:
                    break;
                }
              }
            }
          } else if (
            subAttribute.DT === "DropdownSingle" ||
            subAttribute.DT === "SearchableDropdownSingle" ||
            subAttribute.DT === "DropdownMultiple"
          ) {
            if (attributeSetData[subAttribute.AC] === null) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          } else if (subAttribute.DT === "Date") {
            if (attributeSetData[subAttribute.AC] === null) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else if (subAttribute.AC === "DOBE") {
              const ageYears = moment().diff(
                moment(attributeSetData["DOBE"]),
                "years"
              );
              switch (attributeSetData["RLP"].label.toLowerCase()) {
                case "father":
                case "mother":
                  if (ageYears < 35) {
                    errorObj = {
                      ...errorObj,
                      [subAttribute.AC]:
                        "Father/Mother's Age Should be Minimum 35 Years.",
                    };
                  } else {
                    errorObj = {
                      ...errorObj,
                      [subAttribute.AC]: "",
                    };
                  }

                  break;
                case "spouse":
                  if (ageYears < 18) {
                    errorObj = {
                      ...errorObj,
                      [subAttribute.AC]:
                        "Spouse Age Should be Minimum 18 Years.",
                    };
                  } else {
                    errorObj = {
                      ...errorObj,
                      [subAttribute.AC]: "",
                    };
                  }

                  break;
                default:
                  break;
              }
            } else if (
              moment(
                validation.MaxV === "CurrentDate" &&
                  attributeSetData[subAttribute.AC]
              ).isAfter(new Date())
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Only till current date allowed",
              };
            } else if (
              validation.MaxV === "CurrentMonth" &&
              attributeSetData[subAttribute.AC] != ""
            ) {
              let validatingValues = validateCurrentMonth(
                attributeSetData[subAttribute.AC]
              );
              if (
                validatingValues.monthsDiff != 0 ||
                validatingValues.yearsDiff != 0
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Only date from current month allowed",
                };
              }
            } else if (
              moment(
                validation.MinV === "CurrentDate" &&
                  attributeSetData[subAttribute.AC]
              ).isBefore()
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Only after current date allowed",
              };
            } else if (
              validation.MinV !== undefined &&
              validation.MinV !== "CurrentDate" &&
              validation.MinV !== ""
            ) {
              let parentValue = attributeSetData[validation.MinV];
              if (
                parentValue !== null &&
                parentValue !== "" &&
                attributeSetData[subAttribute.AC] !== null &&
                attributeSetData[subAttribute.AC] !== "" &&
                moment(attributeSetData[subAttribute.AC]).isBefore(parentValue)
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Past date not allowed",
                };
              } else if (
                (parentValue === null || parentValue === "") &&
                attributeSetData[subAttribute.AC] !== null &&
                attributeSetData[subAttribute.AC] !== ""
              ) {
                errorObj = {
                  ...errorObj,
                  [validation.MinV]: "Required",
                  [subAttribute.AC]: "",
                };
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          } else if (subAttribute.DT === "Number") {
            //change with regex
            if (attributeSetData[subAttribute.AC].trim() === "") {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else if (
              // isNaN(
              //   attributeSetData[subAttribute.AC]
              // )
              !/^([0-9]+|0)$/.test(attributeSetData[subAttribute.AC])
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Only numbers are allowed",
              };
            } else if (subAttribute.AC === "AC") {
              if (!/^[0-9]{0,5}$/.test(attributeSetData["AC"])) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Please enter a 5-digit area code.",
                };
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }
            } else if (subAttribute.AC === "OFFEX") {
              if (!/^[0-9]{0,6}$/.test(attributeSetData["OFFEX"])) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Please enter a 6-digit extension code.",
                };
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }
            } else if (subAttribute.AC === "PN") {
              switch (attributeSetData["PTP"]?.label.toLowerCase()) {
                case "office extension":
                  if (!/^[0-9]{10}$/.test(attributeSetData[subAttribute.AC])) {
                    errorObj = {
                      ...errorObj,
                      [subAttribute.AC]: "Only numbers are allowed",
                    };
                  }
                  break;
                case "mobile":
                  if (!/^[0-9]{10}$/.test(attributeSetData[subAttribute.AC])) {
                    errorObj = {
                      ...errorObj,
                      [subAttribute.AC]:
                        "Please enter a 10-digit numeric mobile number.",
                    };
                  }
                  break;
                case "office telephone":
                  if (!/^[0-9]{8}$/.test(attributeSetData[subAttribute.AC])) {
                    errorObj = {
                      ...errorObj,
                      [subAttribute.AC]:
                        "Please enter a 8-digit numeric phone number.",
                    };
                  }
                  break;
              }
            } else if (
              Number(attributeSetData[subAttribute.AC]) > validation.MaxV
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: `Max ${validation.MaxV} allowed`,
              };
            } else if (
              Number(attributeSetData[subAttribute.AC]) < validation.MinV
            ) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: `Min ${validation.MinV} required`,
              };
            }
            //If Numeric values has pattern
            else if (validation.PTN != undefined) {
              if (
                isValidEmail(
                  attributeSetData[subAttribute.AC],
                  validation.PTN
                ) != true
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: `Invalid Data`,
                };
              }
            }
            // else if (subAttribute.AC === "PN") {
            //   debugger;
            //   switch (attributeSetData["PTP"].label.toLowerCase()) {
            //     case "office extension":
            //       if (
            //         attributeSetData["PN"].length > 6 ||
            //         attributeSetData["PN"].length < 4
            //       ) {
            //         errorObj = {
            //           ...errorObj,
            //           [subAttribute.AC]: "Ext no. should be 4-6 Digits",
            //         };
            //       } else {
            //         errorObj = {
            //           ...errorObj,
            //           [subAttribute.AC]: "",
            //         };
            //       }

            //       break;
            //     case "office telephone":
            //       if (attributeSetData["PN"].length > 7) {
            //         errorObj = {
            //           ...errorObj,
            //           [subAttribute.AC]: " Max 7 Digit is Accepted.",
            //         };
            //       } else {
            //         errorObj = {
            //           ...errorObj,
            //           [subAttribute.AC]: "",
            //         };
            //       }

            //       break;
            //     default:
            //       break;
            //   }
            // }
            else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          } else if (subAttribute.DT === "Document") {
            const fileCount = selectedFilesForSet.reduce((total, item) => {
              if (
                item.AC === subAttribute.AC &&
                ((currentGridId && currentGridId === item.Id) ||
                  item.Id === 0 ||
                  item.Id !== 0)
              ) {
                return total + 1;
              }
              return total;
            }, 0);

            if (selectedFilesForSet.length === 0 || fileCount === 0) {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "Required",
              };
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          }
        } else {
          // for num and date

          if (subAttribute.DT === "Number") {
            if (attributeSetData[subAttribute.AC] !== "") {
              //change with regex
              if (
                // isNaN(
                //   attributeSetData[
                //     subAttribute.AC
                //   ]
                // )
                !/^([0-9]+|0)$/.test(attributeSetData[subAttribute.AC])
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Only numbers are allowed",
                };
              } else if (
                Number(attributeSetData[subAttribute.AC]) > validation.MaxV
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: `Max ${validation.MaxV} allowed`,
                };
              } else if (
                Number(attributeSetData[subAttribute.AC]) < validation.MinV
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: `Min ${validation.MinV} required`,
                };
              } else if (subAttribute.AC === "AC") {
                if (!/^\d{7,10}$/.test(attributeSetData[subAttribute.AC])) {
                  errorObj = {
                    ...errorObj,
                    [subAttribute.AC]: `Invalid Number,7-10 Digit is Accepted.`,
                  };
                }
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          } else if (subAttribute.DT === "Date") {
            if (attributeSetData[subAttribute.AC] !== null) {
              if (
                moment(
                  validation.MaxV === "CurrentDate" &&
                    attributeSetData[subAttribute.AC]
                ).isAfter(new Date())
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Only till current date allowed",
                };
              } else if (
                moment(
                  validation.MinV === "CurrentDate" &&
                    attributeSetData[subAttribute.AC]
                ).isBefore()
              ) {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "Only after current date allowed",
                };
              } else if (
                validation.MinV !== undefined &&
                validation.MinV !== "CurrentDate" &&
                validation.MinV !== ""
              ) {
                let parentValue = attributeSetData[validation.MinV];
                if (
                  parentValue !== null &&
                  parentValue !== "" &&
                  attributeSetData[subAttribute.AC] !== null &&
                  attributeSetData[subAttribute.AC] !== "" &&
                  moment(attributeSetData[subAttribute.AC]).isBefore(
                    parentValue
                  )
                ) {
                  errorObj = {
                    ...errorObj,
                    [subAttribute.AC]: "Past date not allowed",
                  };
                } else if (
                  (parentValue === null || parentValue === "") &&
                  attributeSetData[subAttribute.AC] !== null &&
                  attributeSetData[subAttribute.AC] !== ""
                ) {
                  errorObj = {
                    ...errorObj,
                    [validation.MinV]: "Required",
                    [subAttribute.AC]: "",
                  };
                } else {
                  errorObj = {
                    ...errorObj,
                    [subAttribute.AC]: "",
                  };
                }
              } else {
                errorObj = {
                  ...errorObj,
                  [subAttribute.AC]: "",
                };
              }
            } else {
              errorObj = {
                ...errorObj,
                [subAttribute.AC]: "",
              };
            }
          }
        }
      }
    });

    const isEmpty = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      notify(1, `${section.SDN} - Please check validation errors!`);
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  const getSearchableDropdownAPI = async (
    searchString,
    AC,
    cb,
    valueForDropdown,
    type,
    gridId
  ) => {
    if (
      searchString === "" &&
      cb === null &&
      valueForDropdown !== "" &&
      AC !== ""
    ) {
      const response = await APICall(getSearchableDropdowns, "POST", {
        searchString,
        AC,
        valueForDropdown,
      });

      //console.log(searchString, AC, cb, valueForDropdown, type, gridId);

      if (response.data !== null && response.data.length > 0) {
        if (response.data.length === 1) {
          if (gridId !== 0) {
            let ddObj = {
              gridId,
              AC,
              DDResponse: response.data[0],
            };
            setFillDDValuesForGrid((prev) => [...prev, ddObj]);
          } else {
            return response.data[0];
            //fillSearchableDDValuesOnEdit(response.data[0], AC);
          }
        }
      }
    } else if (searchString.length > 1) {
      const response = await APICall(getSearchableDropdowns, "POST", {
        searchString,
        AC,
      });
      if (response.data !== null && response.data.length > 0) {
        cb(response.data);
        setOptionsObj((prev) => ({ ...prev, [AC]: response.data }));
      } else {
        cb([]);
        setOptionsObj((prev) => ({ ...prev, [AC]: [] }));
      }
    }
  };

  useEffect(() => {
    //  ;
    if (
      fillDDValuesForGrid.length > 0 &&
      fillDDValuesForGrid.length === gridData.length
    ) {
      fillDDValuesForGrid.forEach((ddValues) => {
        setGridData((current) =>
          current.map((obj) => {
            if (obj.Id == ddValues.gridId) {
              return { ...obj, [ddValues.AC]: ddValues.DDResponse.label };
            }

            return obj;
          })
        );

        setDisplayGrid((current) =>
          current.map((obj) => {
            if (obj.Id == ddValues.gridId) {
              return { ...obj, [ddValues.AC]: ddValues.DDResponse.label };
            }

            return obj;
          })
        );

        setOgFilledAttributeSet((current) =>
          current.map((obj) => {
            if (obj.Id == ddValues.gridId) {
              return {
                ...obj,
                [ddValues.AC]: {
                  label: ddValues.DDResponse.label,
                  value: ddValues.DDResponse.value,
                },
              };
            }

            return obj;
          })
        );
      });
      setFillDDValuesForGrid([]);
    }
    if (gridData.length > 0) {
      let isbank = false;
      let amount = 0;
      gridData.map((gd) => {
        if (gd.hasOwnProperty("PCE") && gd.IsActive != false) {
          isbank = true;
          if (gd.PCE === "Fixed Pay" || gd.PCE === "Variable Pay") {
            amount += parseInt(gd.AMT);
          } else if (gd.PCE === "Stipend") {
            amount = gd.AMT;
          }
        } else {
          isbank = false;
        }
        if (gd.hasOwnProperty("PVSEMP") && gd.IsActive != false) {
          let filOpt = optionsObj["PVSEMP"]?.filter((op) => {
            return op.label === gd.PVSEMP;
          });
          setOgAttributeSet((prev) => ({
            ...prev,
            ["PVSEMP"]: filOpt[0],
          }));
          setAttributeSetData((prev) => ({
            ...prev,
            PVSEMP: filOpt[0],
          }));
        }
      });
      if (isbank) {
        setAttributesData((prev) => ({
          ...prev,
          ASA: amount.toString(),
        }));
      }
    }
  }, [fillDDValuesForGrid, gridData]);

  //Searchable Dropdown for Attributes
  const searchableDDValues = (event, AC) => {
    let DD = event.DD;
    if (DD.length > 0) {
      let newAttributeData = { ...attributesData };
      let dropdownOptions = { ...optionsObj };

      DD.forEach((attribute) => {
        const Code = attribute.Key;

        // check DT

        if (
          attribute.DT === "DropdownSingle" ||
          attribute.DT === "DropdownMultiple"
        ) {
          const options = dropdownOptions[Code];

          if (options !== undefined && options.length > 0) {
            const option = options.find(
              (attri) => attri.value == attribute.Value
            );

            newAttributeData = {
              ...newAttributeData,
              [Code]: option === undefined ? null : option,
            };
          }
        } else {
          //check all DT
          newAttributeData = {
            ...newAttributeData,
            [Code]: attribute.Value,
          };
        }
      });
      newAttributeData[AC] = event; //imp

      setAttributesData(newAttributeData);
    }
  };

  // //Auto map
  // const AutoMapDateOfResign = async ( event
  //   ) => {
  //     if(attributesData["SD"]?.value ){
  //       let dAutomapAttribute = [{
  //         Key:"SD",
  //         Value:attributesData["SD"].value
  //       }];
  //       ;
  //           const response = await APICall(getAutoMappings, "POST", {
  //             AC:"DOR",
  //             DDATR:dAutomapAttribute,
  //             TId: attributesData["Id"]?.value
  //          });
  //           if(response.data != null){
  //             setBautoMapped(true);
  //             setAttributesData((prev) => ({
  //               ...prev,
  //                 ["SD"]: response.data,
  //             }));
  //             //setTan(response.data["tan"]);
  //           }
  //       }
  //     };

  //Select date value for only this month
  const validateCurrentMonth = (EnteredDate) => {
    let monthsDiff;
    let yearsDiff;

    const endDate = moment();
    const startDate = moment(EnteredDate);

    monthsDiff = endDate.diff(startDate, "months");

    yearsDiff = endDate.diff(startDate, "years");

    return { monthsDiff, yearsDiff };
  };

  /*get company code from legal entity */

  const getCompanyCode = (value) => {
    let code;
    if (value != undefined && value != null) {
      code = value.code;
      setAttributesData((prev) => ({
        ...prev,
        LEN: value,
        ["CCD"]: code,
      }));
    } else {
      code = "";
    }
    return code;
  };
  const GetNPEndDate = (NPSD, NPIM) => {
    if (NPSD != undefined && NPSD != null) {
      if (NPIM != undefined && NPIM != null) {
        const startDateNP = moment(NPSD);
        let NPDays = Number(NPIM) * 30.44;
        let EndDateNP = startDateNP.add(NPDays, "d");
        return EndDateNP;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };
  const GetShortDays = (NPEDE, LWD) => {
    if (
      LWD != undefined &&
      LWD != null &&
      NPEDE != undefined &&
      NPEDE != null
    ) {
      let daysDiff;
      if (moment(LWD) < moment(NPEDE)) {
        daysDiff = moment(NPEDE).diff(moment(LWD), "days");
      } else {
        daysDiff = 0;
      }
      return daysDiff;
    } else {
      return "";
    }
  };
  const dateyearmonthdiff = (date) => {
    if (date != undefined && date != null) {
      const endDate = moment();
      const startDate = moment(date);
      const yearsDiff = endDate.diff(startDate, "years");
      startDate.add(yearsDiff, "years"); // Adjust the start date by the years difference
      const monthsDiff = endDate.diff(startDate, "months");
      startDate.add(monthsDiff, "months"); // Adjust the start date by the months difference
      const daysDiff = endDate.diff(startDate, "days");
      return `${yearsDiff} years, ${monthsDiff} months, ${daysDiff} days`;
    } else {
      return "";
    }
  };
  //Searchable Dropdown for Attributes Set
  const searchableDDValuesSet = (event, AC) => {
    let DD = event.DD;
    if (DD.length > 0) {
      let newAttributeSetData = { ...attributeSetData };
      let dropdownOptions = { ...optionsObj };

      DD.forEach((attribute) => {
        const Code = attribute.Key;

        // check DT

        if (attribute.DT === "DropdownSingle") {
          const options = dropdownOptions[Code];

          if (options !== undefined && options.length > 0) {
            const option = options.find(
              (attri) => attri.value == attribute.Value
            );

            newAttributeSetData = {
              ...newAttributeSetData,
              [Code]: option === undefined ? null : option,
            };
          }
        } else {
          //check all DT
          newAttributeSetData = {
            ...newAttributeSetData,
            [Code]: attribute.Value,
          };
        }
      });
      newAttributeSetData[AC] = event; //imp

      setAttributeSetData(newAttributeSetData);
    }
  };

  const fillSearchableDDValuesOnEdit = (DDResponse, AC) => {
    setAttributesData((prev) => ({
      ...prev,
      [AC]: { label: DDResponse.label, value: DDResponse.value },
    }));
  };

  const [addressType, setAddressType] = useState<any>({});

  useEffect(() => {
    // ;
    if (Object.keys(addressType).length === 2) {
      if (addressType.address === "present" && addressType.type === "lease") {
        let attributeSetValObj = { ...attributeSetValidation };

        attributeSetValObj.LSD.ISM = "true";
        attributeSetValObj.LED.ISM = "true";

        setAttributeSetValidation((prev) => ({
          ...prev,
          ...attributeSetValObj,
        }));
      } else {
        let attributeSetValObj = { ...attributeSetValidation };

        attributeSetValObj.LSD.ISM = "false";
        attributeSetValObj.LED.ISM = "false";

        setAttributeSetValidation((prev) => ({
          ...prev,
          ...attributeSetValObj,
        }));
      }
    }
  }, [addressType]);

  const validateBankAccAdhar = async () => {
    return new Promise(async (resolve, reject) => {
      const response = await APICall(getAccountValidation, "POST", {
        BNKACCNM: attributeSetData["BNKACCNM"],
      });

      if (
        response.data !== null &&
        response.data != undefined &&
        response.data.length > 0
      ) {
        let filterAccount = response.data.filter((res) => {
          if (
            res.empActive === false &&
            res.peridnm === (globalEC[0] != undefined && globalEC[0].aadhar)
          ) {
            return res;
          }
        });
        if (filterAccount && filterAccount.length > 0) {
          setModalHeader(
            "The Same Account Number is already existing for Inactive Employee ID ( " +
              filterAccount[0].employeeCode +
              "-" +
              filterAccount[0].fn +
              " ). Do you want to Continue?"
          );
          setShowConfirmationModal(true);
          setConfirmationPromise({ resolve, reject });
        } else {
          let filternumber = response.data.filter((res) => {
            if (
              res.bnkaccnm === attributeSetData["BNKACCNM"] &&
              res.eid != TId
            ) {
              return res;
            }
          });
          if (filternumber && filternumber.length > 0) {
            notify(
              1,
              "The Same Account Number already exist with" +
                (filternumber[0].empActive === true
                  ? " Active "
                  : " Inactive ") +
                " Employee ID ( " +
                filternumber[0].employeeCode +
                "-" +
                filternumber[0].fn +
                " )."
            );
            validAccount.current = false;
            resolve(true);
          } else {
            validAccount.current = true;
            resolve(true);
          }
        }
      } else {
        validAccount.current = true;
        resolve(true);
      }
    });
  };

  const closeConfirmationModal = async (confirmation) => {
    if (confirmationPromise) {
      if (confirmation) {
        validAccount.current = true;
      } else {
        validAccount.current = false;
      }

      setShowConfirmationModal(false);
    }
  };

  const getPPonIncm = async (AC, GenId) => {
    const response = await APICall(getParenPBasedonIncm, "POST", {
      AC,
      GenId,
    });
    if (response.data !== null) {
      return response.data;
    }
  };
  useEffect(() => {
    if (confirmationPromise) {
      if (validAccount.current) {
        confirmationPromise.resolve(true);
      } else {
        confirmationPromise.reject(false); // Reject the promise
      }
    }
    setConfirmationPromise(null);
  }, [validAccount.current]);

  const getDocumentForAC = async (AC) => {
    // console.log("ok", AC);
    // let documents = JSON.parse("[]");
    // documents = documents.forEach((document) => {
    //   document.AC = AC;
    //   document.IsValidFile = true;
    // });
    // // console.log(documents);
    // setSelectedFilesForSet(documents);
  };

  return (
    <>
      <div className="col-lg-12">
        {section?.Attribute?.length > 0 ? (
          <Accordion
            elevation={0}
            className="mb-3"
            expanded={accordion}
            onChange={() => setAccordion((prev) => !prev)}
          >
            <AccordionSummary
              id="profile-accordion"
              style={{ background: "#3C5464" }}
              className="text-white acc_close"
              expandIcon={<ExpandMore />}
            >
              <div className="row d-flex align-items-center acc-heading">
                <div className="col-lg-10 col-md-10 col-sm-12">
                  <p style={{ fontSize: "16px" }}>{section.SDN}</p>
                </div>
                <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12">
                  {/* <Tooltip title="clear">
                    <button
                      className="btn ml-lg-0 ml-md-0 ml-1"
                      disabled={showAttr}
                      onClick={(e) => {
                        e.stopPropagation();
                        //clear data
  
                        section?.Attribute?.forEach(async (eachAttribute) => {
                          if (eachAttribute.AT === "Attribute") {
                            //change value according to dt
  
                            setAttributesData((prev) => ({
                              ...prev,
                              [eachAttribute.AC]:
                                eachAttribute.DT === "DropdownSingle" ||
                                eachAttribute.DT === "Date" ||
                                eachAttribute.DT === "DropdownSingle"
                                  ? null
                                  : eachAttribute.DT === "Text" ||
                                    eachAttribute.DT === "Number"
                                  ? ""
                                  : eachAttribute.DT === "Checkbox"
                                  ? false
                                  : "",
                            }));
                          } else if (eachAttribute.AT === "AttributeSet") {
                            setAttributeSetData(ogAttributeSet);
                          }
                        });
  
                        //clear error
                        setFormErrors({});
                      }}>
                      <i className="fas fa-times-circle"></i>
                    </button>
                  </Tooltip> */}
                  <Tooltip title="Submit">
                    <button
                      className="btn"
                      disabled={
                        currentGridId !== null ? true : false || showAttr
                      }
                      onClick={async (e) => {
                        e.stopPropagation();

                        if (TId === 0 && section.SN !== "Basic") {
                          notify(1, "Save Basic Data First");
                        } else {
                          let errorObj = {};
                          section?.Attribute?.map((eachAttribute) => {
                            if (eachAttribute.AT === "Attribute") {
                              const validation =
                                attributeValidation[eachAttribute.AC];

                              if (validation.ISM.toLowerCase() === "true") {
                                if (eachAttribute.DT === "Text") {
                                  if (
                                    attributesData[eachAttribute.AC].trim() ===
                                    ""
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "Required",
                                    };
                                  } else if (validation.PTN != undefined) {
                                    if (
                                      isValidEmail(
                                        attributesData[eachAttribute.AC],
                                        validation.PTN
                                      ) != true
                                    )
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: `Incorrect format.`,
                                      };
                                  } else if (
                                    attributesData[eachAttribute.AC]?.length >
                                    validation.MaxC
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: `Max ${validation.MaxC} characters allowed`,
                                    };
                                  } else {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "",
                                    };
                                  }
                                } else if (
                                  eachAttribute.DT === "DropdownSingle" ||
                                  eachAttribute.DT ===
                                    "SearchableDropdownSingle" ||
                                  eachAttribute.DT === "DropdownMultiple"
                                ) {
                                  if (
                                    attributesData[eachAttribute.AC] === null
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "Required",
                                    };
                                  } else {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "",
                                    };
                                  }
                                } else if (eachAttribute.DT === "Date") {
                                  if (
                                    attributesData[eachAttribute.AC] === null
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "Required",
                                    };
                                  } else if (
                                    validation.MaxV === "CurrentDate" &&
                                    moment(
                                      attributesData[eachAttribute.AC]
                                    ).isAfter(moment())
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]:
                                        "Only till current date allowed",
                                    };
                                  } else if (
                                    moment(
                                      validation.MinV === "CurrentDate" &&
                                        attributesData[eachAttribute.AC]
                                    ).isBefore()
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]:
                                        "Only after current date allowed",
                                    };
                                  } else if (
                                    validation.MinV !== undefined &&
                                    validation.MinV !== "CurrentDate" &&
                                    validation.MinV !== ""
                                  ) {
                                    let parentValue =
                                      attributesData[validation.MinV];
                                    if (
                                      parentValue !== null &&
                                      parentValue !== "" &&
                                      attributesData[eachAttribute.AC] !==
                                        null &&
                                      attributesData[eachAttribute.AC] !== "" &&
                                      moment(
                                        attributesData[eachAttribute.AC]
                                      ).isBefore(parentValue)
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]:
                                          "Past date not allowed",
                                      };
                                    } else if (
                                      parentValue !== null &&
                                      parentValue !== "" &&
                                      attributesData[eachAttribute.AC] !==
                                        null &&
                                      attributesData[eachAttribute.AC] !== ""
                                    ) {
                                      if (validation.MinV === "DOB") {
                                        const diffInYear = moment(
                                          attributesData[eachAttribute.AC]
                                        ).diff(moment(parentValue), "years");
                                        if (diffInYear < 16) {
                                          errorObj = {
                                            ...errorObj,
                                            [eachAttribute.AC]:
                                              "Past date not allowed",
                                          };
                                        }
                                      }
                                    } else if (
                                      (parentValue === null ||
                                        parentValue === "") &&
                                      attributesData[eachAttribute.AC] !==
                                        null &&
                                      attributesData[eachAttribute.AC] !== ""
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [validation.MinV]: "Required",
                                        [eachAttribute.AC]: "",
                                      };
                                    } else {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: "",
                                      };
                                    }
                                  } else if (
                                    validation.MaxV !== undefined &&
                                    validation.MaxV !== "CurrentDate" &&
                                    validation.MaxV !== ""
                                  ) {
                                    let parentValue =
                                      attributesData[validation.MinV];
                                    if (
                                      parentValue !== null &&
                                      parentValue !== "" &&
                                      attributesData[eachAttribute.AC] !==
                                        null &&
                                      attributesData[eachAttribute.AC] !== "" &&
                                      moment(
                                        attributesData[eachAttribute.AC]
                                      ).isAfter(parentValue)
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]:
                                          "Future Date not Allowed.",
                                      };
                                    } else if (
                                      (parentValue === null ||
                                        parentValue === "") &&
                                      attributesData[eachAttribute.AC] !==
                                        null &&
                                      attributesData[eachAttribute.AC] !== ""
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [validation.MinV]: "Required",
                                        [eachAttribute.AC]: "",
                                      };
                                    } else {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: "",
                                      };
                                    }
                                  } else {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "",
                                    };
                                  }
                                } else if (eachAttribute.DT === "Number") {
                                  //change with regex

                                  // if (
                                  //   attributesData[eachAttribute.AC].trim() === ""
                                  // ) {
                                  //   errorObj = {
                                  //     ...errorObj,
                                  //     [eachAttribute.AC]: "Required",
                                  //   };
                                  // } else
                                  if (
                                    // isNaN(attributesData[eachAttribute.AC])
                                    !/^([0-9]+|0)$/.test(
                                      attributesData[eachAttribute.AC]
                                    )
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]:
                                        "Only numbers are allowed",
                                    };
                                  } else if (
                                    Number(attributesData[eachAttribute.AC]) >
                                    Number(validation.MaxV)
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: `Max ${validation.MaxV} allowed`,
                                    };
                                  } else if (
                                    Number(attributesData[eachAttribute.AC]) <
                                    Number(validation.MinV)
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: `Min ${validation.MinV} required`,
                                    };
                                  } else {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "",
                                    };
                                  }
                                } else if (eachAttribute.DT === "Document") {
                                  const fileCount = selectedFilesForFlat.reduce(
                                    (total, item) => {
                                      if (item.AC === eachAttribute.AC) {
                                        return total + 1;
                                      }
                                      return total;
                                    },
                                    0
                                  );

                                  if (
                                    selectedFilesForFlat.length === 0 ||
                                    fileCount === 0
                                  ) {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "Required",
                                    };
                                  } else {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "",
                                    };
                                  }
                                }
                              } else {
                                // for num and date
                                if (eachAttribute.DT === "Text") {
                                  if (validation.PTN != undefined) {
                                    if (
                                      isValidEmail(
                                        attributesData[eachAttribute.AC],
                                        validation.PTN
                                      ) != true
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: `Incorrect format.`,
                                      };
                                    } else {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: ``,
                                      };
                                    }
                                  }
                                }
                                if (eachAttribute.DT === "Number") {
                                  if (attributesData[eachAttribute.AC] !== "") {
                                    //change with regex
                                    if (
                                      // isNaN(attributesData[eachAttribute.AC])
                                      !/^([0-9]+|0)$/.test(
                                        attributesData[eachAttribute.AC]
                                      )
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]:
                                          "Only numbers are allowed",
                                      };
                                    } else if (
                                      Number(attributesData[eachAttribute.AC]) >
                                      validation.MaxV
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: `Max ${validation.MaxV} allowed`,
                                      };
                                    } else if (
                                      Number(attributesData[eachAttribute.AC]) <
                                      validation.MinV
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: `Min ${validation.MinV} required`,
                                      };
                                    } else {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: "",
                                      };
                                    }
                                  } else {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "",
                                    };
                                  }
                                } else if (eachAttribute.DT === "Date") {
                                  if (
                                    attributesData[eachAttribute.AC] !== null
                                  ) {
                                    if (
                                      moment(
                                        validation.MaxV === "CurrentDate" &&
                                          attributesData[eachAttribute.AC]
                                      ).isAfter(new Date())
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]:
                                          "Only till current date allowed",
                                      };
                                    } else if (
                                      moment(
                                        validation.MinV === "CurrentDate" &&
                                          attributesData[eachAttribute.AC]
                                      ).isBefore()
                                    ) {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]:
                                          "Only after current date allowed",
                                      };
                                    } else if (
                                      validation.MinV !== undefined &&
                                      validation.MinV !== "CurrentDate" &&
                                      validation.MinV !== ""
                                    ) {
                                      let parentValue =
                                        attributesData[validation.MinV];
                                      if (
                                        parentValue !== null &&
                                        parentValue !== "" &&
                                        attributesData[eachAttribute.AC] !==
                                          null &&
                                        attributesData[eachAttribute.AC] !==
                                          "" &&
                                        moment(
                                          attributesData[eachAttribute.AC]
                                        ).isBefore(parentValue)
                                      ) {
                                        errorObj = {
                                          ...errorObj,
                                          [eachAttribute.AC]:
                                            "Past date not allowed",
                                        };
                                      } else if (
                                        (parentValue === null ||
                                          parentValue === "") &&
                                        attributesData[eachAttribute.AC] !==
                                          null &&
                                        attributesData[eachAttribute.AC] !== ""
                                      ) {
                                        errorObj = {
                                          ...errorObj,
                                          [validation.MinV]: "Required",
                                          [eachAttribute.AC]: "",
                                        };
                                      } else {
                                        errorObj = {
                                          ...errorObj,
                                          [eachAttribute.AC]: "",
                                        };
                                      }
                                    } else {
                                      errorObj = {
                                        ...errorObj,
                                        [eachAttribute.AC]: "",
                                      };
                                    }
                                  } else {
                                    errorObj = {
                                      ...errorObj,
                                      [eachAttribute.AC]: "",
                                    };
                                  }
                                }
                              }
                            } else {
                              //submit attributeSet
                            }
                          });

                          const isEmpty = Object.values(errorObj).every(
                            (s) => s === ""
                          );

                          if (!isEmpty) {
                            setFormErrors((err) => ({
                              ...err,
                              ...errorObj,
                            }));
                          } else {
                            setFormErrors({});
                          }

                          if (isEmpty && attributeSetCode === null) {
                            await showLoader();
                            // post data attribute
                            const postAttributes = [];

                            Object.entries(attributesData).forEach(
                              async (attributeData) => {
                                const [Code, Value]: any[] = attributeData;

                                let attribute = {
                                  Type: "Attribute",
                                  Code,
                                  Value:
                                    typeof Value === "object"
                                      ? Value?.value.toString() || null
                                      : typeof Value === "string" ||
                                        typeof Value === "boolean"
                                      ? Value
                                      : null,
                                };
                                postAttributes.push(attribute);
                              }
                            );

                            let postObj = {
                              MN: "EmployeeCentral",
                              IN: "NewHire_Form",
                              TN: formData.tn,
                              SN: section.SN,
                              TId: TId || 0,
                              UserId: userDetails.Id,
                              UserEmailId: userDetails.EmailId,
                              UserName: userDetails.FirstName,
                              RoleId: currentRoleId?.value,
                              Attributes: postAttributes,
                              COU: COU,
                            };
                            // sanket

                            // if(section.SN==="EmploymentDetails"){
                            //
                            //   addEmployeeClass(postAttributes)
                            // }

                            const postRes = await APICall(
                              postAttributeValueDetailsV2,
                              "POST",
                              postObj
                            );

                            if (
                              postRes.data !== null &&
                              section.SN === "Basic" &&
                              postRes.data.id !== undefined
                            ) {
                              setTId(postRes.data.id);
                            }
                            if (section.SN === "JobOrganisationInformation") {
                              SetSectionValuesArray([]); //imp
                              (async () => {
                                let bodyObj = {
                                  mn: "EmployeeCentral",
                                  in: "NewHire_Form",
                                  tn: "Employment",
                                  SN: "Basic",
                                  TID: TId,
                                  ISH: "true",
                                };

                                const response = await APICall(
                                  getAttributeValueDetailsV2,
                                  "POST",
                                  bodyObj
                                );

                                if (
                                  response.data.sections &&
                                  response.data.sections.length > 0
                                ) {
                                  SetSectionValuesArray(response.data.sections);
                                } else {
                                  SetSectionValuesArray([]);
                                }
                              })();
                            }

                            if (
                              selectedFilesForFlat.length > 0 &&
                              sectionHasDocuments
                            ) {
                              let newFiles = selectedFilesForFlat.filter(
                                (sf) => {
                                  return sf.DocId === 0;
                                }
                              );
                              try {
                                const response = await APICall(
                                  documentUpload,
                                  "POST",
                                  {
                                    ModuleName: "EmployeeCentral",
                                    files: newFiles,
                                    SectionName: "Basic",
                                    TabName: "Personal",
                                    TId: postRes.data?.id
                                      ? postRes.data.id
                                      : TId,
                                  }
                                );

                                if (
                                  response.data === null ||
                                  response.data === undefined
                                ) {
                                  notify(1, "Failed to upload documents.");
                                } else {
                                  setSelectedFilesForFlat([
                                    ...selectedFilesForFlat,
                                    response.data.files,
                                  ]);
                                }
                              } catch (error) {
                                throw new Error(
                                  "Error uploading documents: " + error.message
                                );
                              }
                            }

                            notify(postRes.status, postRes.message);
                            await hideLoader();
                            setShowAttrSet(false);
                            if (section.SN === "Basic") {
                              let filteredObj = postAttributes.filter((pa) => {
                                if (pa.Code === "MARITLST") {
                                  return pa;
                                }
                              });
                              if (filteredObj[0].label === "Married") {
                                notify(
                                  2,
                                  "Please fill out spouse details in Family Member Details Section."
                                );
                              }
                            }
                            if (section.SN === "JobOrganisationInformation") {
                              notify(
                                2,
                                "Please mantain HR manager in Work association portlet."
                              );
                            }
                          } else if (!isEmpty && attributeSetCode === null) {
                            //notify on flat attribute
                            notify(
                              1,
                              `${section.SDN} - Please check validation errors!`
                            );
                          } else if (
                            isEmpty &&
                            attributeSetCode !== null &&
                            Object.keys(attributesData).length === 0
                          ) {
                            await showLoader();

                            //Contact information Validation for atleast 1 primary key
                          if(section.SN === 'ContactInformation'){
                            if(ogFilledAttributeSet.length>0){
                              let filteredContacts =
                              ogFilledAttributeSet.filter(
                                (og) => {
                                  return (
                                    og.IP2?.code === 'CODEISPMRY2' &&
                                    og.IsActive === true
                                  );
                                }
                              );
                              if (filteredContacts.length == 0) {
                                notify(
                                  1,
                                  "Please select atleast 1 primary contact."
                                );
                                await hideLoader();
                                return;
                              }
                            } else {
                              notify(
                                1,
                                "Please select atleast 1 primary contact."
                              );
                              await hideLoader();
                              return;
                            }
                          }
                            // post data attributeSet

                            let details = [...ogFilledAttributeSet];

                            const finalDetails = [];
                            details.forEach(async (attributes) => {
                              let attributeObj = {};
                              Object.keys(attributes).forEach(
                                async (attribute) => {
                                  attributeObj = {
                                    ...attributeObj,
                                    [attribute]:
                                      typeof attributes[attribute] === "object"
                                        ? attributes[
                                            attribute
                                          ]?.value.toString() || null
                                        : typeof attributes[attribute] ===
                                            "string" ||
                                          typeof attributes[attribute] ===
                                            "boolean"
                                        ? attributes[attribute]
                                        : null,
                                  };

                                  if (
                                    attribute === "Id" &&
                                    isNaN(Number(attributes[attribute]))
                                  ) {
                                    attributeObj = {
                                      ...attributeObj,
                                      [attribute]: 0,
                                    };
                                  }
                                }
                              );
                              finalDetails.push(attributeObj);
                            });

                            let postObj = {
                              MN: "EmployeeCentral",
                              IN: "NewHire_Form",
                              TN: formData.tn,
                              SN: section.SN,
                              COU: COU,
                              TId: TId || 0,
                              UserId: userDetails.Id,
                              UserEmailId: userDetails.EmailId,
                              UserName: userDetails.FirstName,
                              RoleId: currentRoleId?.value,
                              Attributes: [
                                {
                                  Code: attributeSetCode,
                                  Value: "",
                                  Type: "AttributeSet",
                                  Details: finalDetails,
                                },
                              ],
                            };

                            const postRes = await APICall(
                              postAttributeValueDetailsV2,
                              "POST",
                              postObj
                            );
                            if (
                              selectedFilesForSet.length > 0 &&
                              sectionHasDocuments
                            ) {
                              try {
                                if (postRes.data?.length > 0) {
                                  selectedFilesForSet.forEach((f, index) => {
                                    f.Id = postRes.data[index];
                                  });
                                }
                                let newFiles = selectedFilesForSet.filter(
                                  (sf) => {
                                    return sf.DocId === 0;
                                  }
                                );
                                const response = await APICall(
                                  documentUpload,
                                  "POST",
                                  {
                                    ModuleName: "EmployeeCentral",
                                    files: newFiles,
                                    SectionName: "Basic",
                                    TabName: "Personal",
                                    TId: postRes.data?.id
                                      ? postRes.data.id
                                      : TId,
                                  }
                                );

                                if (
                                  response.data === null ||
                                  response.data === undefined
                                ) {
                                  notify(1, "Failed to upload documents.");
                                } else {
                                  setSelectedFilesForSet(response.data.files);
                                }
                              } catch (error) {
                                throw new Error(
                                  "Error uploading documents: " + error.message
                                );
                              }
                            }
                            await hideLoader();
                            notify(postRes.status, postRes.message);
                          } else if (
                            isEmpty &&
                            attributeSetCode !== null &&
                            Object.keys(attributesData).length > 0
                          ) {
                            await showLoader();
                            // post data attribute
                            const postAttributes = [];
                            const finalPostAttr = [];
                            Object.entries(attributesData).forEach(
                              async (attributeData) => {
                                const [Code, Value]: any[] = attributeData;

                                let attribute = {
                                  Type: "Attribute",
                                  Code,
                                  Value:
                                    typeof Value === "object"
                                      ? Value?.value.toString() || null
                                      : typeof Value === "string" ||
                                        typeof Value === "boolean"
                                      ? Value
                                      : null,
                                  Details: null,
                                };
                                postAttributes.push(attribute);
                              }
                            );
                            finalPostAttr.push(postAttributes);
                            let details = [...ogFilledAttributeSet];

                            const finalDetails = [];
                            details.forEach(async (attributes) => {
                              let attributeObj = {};
                              Object.keys(attributes).forEach(
                                async (attribute) => {
                                  attributeObj = {
                                    ...attributeObj,
                                    [attribute]:
                                      typeof attributes[attribute] === "object"
                                        ? attributes[
                                            attribute
                                          ]?.value.toString() || null
                                        : typeof attributes[attribute] ===
                                            "string" ||
                                          typeof attributes[attribute] ===
                                            "boolean"
                                        ? attributes[attribute]
                                        : null,
                                  };

                                  if (
                                    attribute === "Id" &&
                                    isNaN(Number(attributes[attribute]))
                                  ) {
                                    attributeObj = {
                                      ...attributeObj,
                                      [attribute]: 0,
                                    };
                                  }
                                }
                              );
                              finalDetails.push(attributeObj);
                            });
                            postAttributes.push({
                              Code: attributeSetCode,
                              Value: "",
                              Type: "AttributeSet",
                              Details: finalDetails,
                            });
                            let postObjflat = {
                              MN: "EmployeeCentral",
                              IN: "NewHire_Form",
                              TN: formData.tn,
                              SN: section.SN,
                              TId: TId || 0,
                              COU: COU,
                              UserId: userDetails.Id,
                              UserEmailId: userDetails.EmailId,
                              UserName: userDetails.FirstName,
                              RoleId: currentRoleId?.value,
                              Attributes: postAttributes,
                            };

                            const postResflat = await APICall(
                              postAttributeValueDetailsV2,
                              "POST",
                              postObjflat
                            );

                            notify(postResflat.status, postResflat.message);
                            setShowAttrSet(false);
                            await hideLoader();
                          }
                        }
                      }}
                    >
                      <i className="fa fa-save"></i>
                    </button>
                  </Tooltip>
                </div>
              </div>
            </AccordionSummary>

            <AccordionDetails className="">
              <div className="row">
                {section?.Attribute?.length > 0 &&
                  section?.Attribute?.map((eachAttribute, index) => (
                    <React.Fragment key={`${index}-${eachAttribute.AC}`}>
                      {eachAttribute.AT === "Attribute" ? (
                        <>
                          {eachAttribute.DT === "DropdownSingle" ? (
                            <>
                              <div
                                className={
                                  JSON.parse(
                                    eachAttribute.AD
                                  ).IFR.toLowerCase() === "true"
                                    ? "col-lg-12 col-sm-12 col-xs-12 "
                                    : "col-lg-3 col-sm-3 col-xs-4"
                                }
                              >
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    {eachAttribute.AN}
                                    {attributeValidation[
                                      eachAttribute.AC
                                    ]?.ISM.toLowerCase() === "true" && (
                                      <sup>*</sup>
                                    )}
                                  </label>

                                  <SelectForm
                                    isClearable
                                    isSearchable
                                    options={
                                      optionsObj[eachAttribute.AC]
                                        ? optionsObj[eachAttribute.AC]
                                        : []
                                    }
                                    placeholder={eachAttribute.AN}
                                    isDisabled={
                                      attributeValidation[eachAttribute.AC]
                                        ?.isDisable || showAttr
                                    }
                                    value={
                                      attributesData[eachAttribute.AC]
                                        ? attributesData[eachAttribute.AC]
                                        : null
                                    }
                                    onChange={async (event) => {
                                      await setAttributesData((prev) => ({
                                        ...prev,
                                        [eachAttribute.AC]: event,
                                      }));

                                      if (eachAttribute.AC === "LEN" && event) {
                                        getCompanyCode(event);
                                      }

                                      if (
                                        eachAttribute.CC !== null &&
                                        eachAttribute.CC !== ""
                                      ) {
                                        const CC = eachAttribute.CC.split(",");

                                        CC.forEach(async (childDropdown) => {
                                          // hard code to get CUSBIS dropdown data
                                          let PId = "";
                                          if (
                                            (eachAttribute.AC === "BIS" ||
                                              eachAttribute.AC === "SBU" ||
                                              eachAttribute.AC === "LEN") &&
                                            childDropdown === "CUSBIS"
                                          ) {
                                            let BIS =
                                              eachAttribute.AC === "BIS"
                                                ? event
                                                  ? event.value.toString()
                                                  : null
                                                : attributesData["BIS"] !== null
                                                ? attributesData[
                                                    "BIS"
                                                  ].value.toString()
                                                : null;
                                            let SBU =
                                              eachAttribute.AC === "SBU"
                                                ? event
                                                  ? event.value.toString()
                                                  : null
                                                : attributesData["SBU"] !== null
                                                ? attributesData[
                                                    "SBU"
                                                  ].value.toString()
                                                : null;
                                            let LEN =
                                              eachAttribute.AC === "LEN"
                                                ? event
                                                  ? event.value.toString()
                                                  : null
                                                : attributesData["LEN"] !== null
                                                ? attributesData[
                                                    "LEN"
                                                  ].value.toString()
                                                : null;
                                            if (BIS && SBU && LEN) {
                                              PId = `${BIS},${SBU},${LEN}`;
                                            }
                                          }
                                          //-------------------------------

                                          if (
                                            (eachAttribute.AC === "EC" ||
                                              eachAttribute.AC === "LEN") &&
                                            childDropdown === "JB"
                                          ) {
                                            let LEN =
                                              eachAttribute.AC === "LEN"
                                                ? event
                                                  ? event.value.toString()
                                                  : null
                                                : attributesData["LEN"] !== null
                                                ? attributesData[
                                                    "LEN"
                                                  ].value.toString()
                                                : null;
                                            let BIS =
                                              eachAttribute.AC === "EC"
                                                ? event
                                                  ? event.value.toString()
                                                  : null
                                                : attributesData["EC"] !== null
                                                ? attributesData[
                                                    "EC"
                                                  ].value.toString()
                                                : null;

                                            if (BIS && LEN) {
                                              PId = `${LEN},${BIS}`;
                                            }
                                          }
                                          setAttributesData((prev) => ({
                                            ...prev,
                                            [childDropdown]: null,
                                          }));

                                          const options =
                                            await getDropdownsData(
                                              childDropdown,
                                              (event &&
                                                childDropdown === "CUSBIS") ||
                                                (event &&
                                                  childDropdown === "JB")
                                                ? PId
                                                : event
                                                ? event.value
                                                : ""
                                            );

                                          setOptionsObj((prev) => ({
                                            ...prev,
                                            [childDropdown]: options,
                                          }));
                                        });
                                      }
                                      // if (eachAttribute.AC === "Gen") {
                                      //   let attributeValObj = {
                                      //     ...attributeValidation,
                                      //   };
                                      //   if (
                                      //     event != null &&
                                      //     event.label === "Female"
                                      //   ) {
                                      //     attributeValObj.MAN.ISM = "true";
                                      //   } else {
                                      //     attributeValObj.MAN.ISM = "false";
                                      //   }
                                      //   setAttributeSetValidation((prev) => ({
                                      //     ...prev,
                                      //     ...attributeValObj,
                                      //   }));
                                      // }

                                      // if (eachAttribute.AC === "MARITLST") {
                                      //   let attributeValObj = {
                                      //     ...attributeValidation,
                                      //   };
                                      //   if (
                                      //     event != null &&
                                      //     event.label === "Married"
                                      //   ) {
                                      //     attributeValObj.ProofBD.ISM = "true";
                                      //     attributeValObj.MAN.ISM = "true";
                                      //   } else {
                                      //     attributeValObj.ProofBD.ISM = "false";
                                      //     attributeValObj.MAN.ISM = "false";
                                      //   }
                                      //   setAttributeSetValidation((prev) => ({
                                      //     ...prev,
                                      //     ...attributeValObj,
                                      //   }));
                                      // }
                                      dependentValidations(
                                        event,
                                        eachAttribute.AC,
                                        "Attribute"
                                      );
                                    }}
                                    isMulti={false}
                                    noIndicator={false}
                                    noSeparator={false}
                                  />
                                  {formErrors[eachAttribute.AC] && (
                                    <p style={{ color: "red" }}>
                                      {formErrors[eachAttribute.AC]}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : eachAttribute.DT === "Text" ||
                            eachAttribute.DT === "Number" ? (
                            <>
                              <div
                                className={
                                  JSON.parse(
                                    eachAttribute.AD
                                  ).IFR.toLowerCase() === "true"
                                    ? "col-lg-12 col-sm-12 col-xs-12 "
                                    : "col-lg-3 col-sm-3 col-xs-4"
                                }
                              >
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    {eachAttribute.AN}
                                    {attributeValidation[
                                      eachAttribute.AC
                                    ]?.ISM.toLowerCase() === "true" && (
                                      <sup>*</sup>
                                    )}
                                  </label>

                                  <InputForm
                                    className="form-control"
                                    placeholder={eachAttribute.AN}
                                    isDisabled={
                                      attributeValidation[eachAttribute.AC]
                                        ?.isDisable ||
                                      showAttr ||
                                      eachAttribute.AC === "AGE" ||
                                      eachAttribute.AC === "DN" ||
                                      eachAttribute.AC === "TIPOS" ||
                                      eachAttribute.AC === "TAN" ||
                                      eachAttribute.AC === "PEIM" ||
                                      eachAttribute.AC === "CCD" ||
                                      eachAttribute.AC === "NPD" ||
                                      eachAttribute.AC === "NPIM" ||
                                      eachAttribute.AC === "NPSDE" ||
                                      eachAttribute.AC === "OCDC" ||
                                      eachAttribute.AC === "JT" ||
                                      eachAttribute.AC === "ASA"
                                        ? true
                                        : eachAttribute.AC === "MAN"
                                        ? attributesData["Gen"]?.label ===
                                            "Female" &&
                                          attributesData["MARITLST"]?.label ===
                                            "Married"
                                          ? false
                                          : true
                                        : false
                                    }
                                    textArea={false}
                                    value={
                                      eachAttribute.AC === "TIPOS"
                                        ? dateyearmonthdiff(
                                            attributesData["POSED"]
                                          )
                                        : eachAttribute.AC === "NPD" &&
                                          (attributesData["NPD"] === null ||
                                            attributesData["NPD"] === undefined)
                                        ? "0"
                                        : attributesData[eachAttribute.AC]
                                        ? attributesData[eachAttribute.AC]
                                        : ""
                                    }
                                    onChange={(e) => {
                                      setAttributesData((prev) => ({
                                        ...prev,
                                        [eachAttribute.AC]: e.target.value,
                                      }));

                                      dependentValidations(
                                        e.target.value,
                                        eachAttribute.AC,
                                        "Attribute"
                                      );

                                      //hard code to fill display name
                                      if (section.SN === "Basic") {
                                        setAttributesData((prev) => ({
                                          ...prev,
                                          ["DN"]:
                                            `${prev.FN} ${prev.MN} ${prev.LN}`.trim(),
                                        }));
                                      }
                                    }}
                                    maxLength="255"
                                  />
                                  {formErrors[eachAttribute.AC] && (
                                    <p style={{ color: "red" }}>
                                      {formErrors[eachAttribute.AC]}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : eachAttribute.DT === "Date" ? (
                            <>
                              <div
                                className={
                                  JSON.parse(
                                    eachAttribute.AD
                                  ).IFR.toLowerCase() === "true"
                                    ? "col-lg-12 col-sm-12 col-xs-12 "
                                    : "col-lg-3 col-sm-3 col-xs-4"
                                }
                              >
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    {eachAttribute.AN}
                                    {attributeValidation[
                                      eachAttribute.AC
                                    ]?.ISM.toLowerCase() === "true" && (
                                      <sup>*</sup>
                                    )}
                                  </label>

                                  <DateForm
                                    isDisabled={
                                      attributeValidation[eachAttribute.AC]
                                        ?.isDisable ||
                                      showAttr ||
                                      eachAttribute.AC === "POSED" ||
                                      eachAttribute.AC === "RTD" ||
                                      eachAttribute.AC === "NPEDE" ||
                                      eachAttribute.AC === "PEDE"
                                        ? true
                                        : false
                                    }
                                    value={
                                      attributesData[eachAttribute.AC] ===
                                      "NPEDE"
                                        ? GetNPEndDate(
                                            attributesData["NPSD"],
                                            attributesData["NPIM"]
                                          )
                                        : attributesData[eachAttribute.AC] ===
                                          "NPSDE"
                                        ? GetShortDays(
                                            attributesData["NPEDE"],
                                            attributesData["LWD"]
                                          )
                                        : attributesData[eachAttribute.AC]
                                        ? attributesData[eachAttribute.AC]
                                        : null
                                    }
                                    onChange={(date) => {
                                      setAttributesData((prev) => ({
                                        ...prev,
                                        [eachAttribute.AC]:
                                          date != null
                                            ? moment(date).format("DD-MMM-YYYY")
                                            : null,
                                      }));

                                      dependentValidations(
                                        moment(date).format("DD-MMM-YYYY"),
                                        eachAttribute.AC,
                                        "Attribute"
                                      );

                                      //hard code to calculate age on DOB
                                      if (eachAttribute.AC === "DOB" && date) {
                                        const ageInYears = moment().diff(
                                          date,
                                          "years"
                                        );

                                        setAttributesData((prev) => ({
                                          ...prev,
                                          AGE: ageInYears.toString(),
                                        }));
                                      }
                                    }}
                                  />
                                  {formErrors[eachAttribute.AC] && (
                                    <p style={{ color: "red" }}>
                                      {formErrors[eachAttribute.AC]}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : eachAttribute.DT === "Checkbox" ? (
                            <>
                              <div
                                className={
                                  JSON.parse(
                                    eachAttribute.AD
                                  ).IFR.toLowerCase() === "true"
                                    ? "col-lg-12 col-sm-12 col-xs-12 "
                                    : "col-lg-3 col-sm-3 col-xs-4"
                                }
                              >
                                <div className="mb-1">
                                  <label
                                    htmlFor={eachAttribute.AC}
                                    className="col-form-label"
                                  >
                                    {eachAttribute.AN}
                                    {attributeValidation[
                                      eachAttribute.AC
                                    ]?.ISM.toLowerCase() === "true" && (
                                      <sup>*</sup>
                                    )}
                                  </label>

                                  <div>
                                    <input
                                      disabled={
                                        attributeValidation[eachAttribute.AC]
                                          ?.isDisable || showAttr
                                      }
                                      type="checkbox"
                                      onChange={(e) => {
                                        setAttributesData((prev) => ({
                                          ...prev,
                                          [eachAttribute.AC]: e.target.checked,
                                        }));

                                        dependentValidations(
                                          e.target.checked,
                                          eachAttribute.AC,
                                          "Attribute"
                                        );
                                      }}
                                      id={eachAttribute.AC}
                                      checked={
                                        attributesData[eachAttribute.AC]
                                          ? attributesData[eachAttribute.AC]
                                          : false
                                      }
                                    />
                                  </div>
                                  {formErrors[eachAttribute.AC] && (
                                    <p style={{ color: "red" }}>
                                      {formErrors[eachAttribute.AC]}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : eachAttribute.DT === "Textarea" ? (
                            <>
                              <div
                                className={
                                  JSON.parse(
                                    eachAttribute.AD
                                  ).IFR.toLowerCase() === "true"
                                    ? "col-lg-12 col-sm-12 col-xs-12 "
                                    : "col-lg-3 col-sm-3 col-xs-4"
                                }
                              >
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    {eachAttribute.AN}
                                    {attributeValidation[
                                      eachAttribute.AC
                                    ]?.ISM.toLowerCase() === "true" && (
                                      <sup>*</sup>
                                    )}
                                  </label>

                                  <InputForm
                                    className="form-control"
                                    placeholder={eachAttribute.AN}
                                    isDisabled={
                                      attributeValidation[eachAttribute.AC]
                                        ?.isDisable || showAttr
                                    }
                                    textArea={true}
                                    value={
                                      attributesData[eachAttribute.AC]
                                        ? attributesData[eachAttribute.AC]
                                        : ""
                                    }
                                    onChange={(e) => {
                                      setAttributesData((prev) => ({
                                        ...prev,
                                        [eachAttribute.AC]: e.target.value,
                                      }));

                                      dependentValidations(
                                        e.target.value,
                                        eachAttribute.AC,
                                        "Attribute"
                                      );
                                    }}
                                    maxLength="255"
                                  />
                                  {formErrors[eachAttribute.AC] && (
                                    <p style={{ color: "red" }}>
                                      {formErrors[eachAttribute.AC]}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : eachAttribute.DT === "Document" ? (
                            <>
                              <div
                                className={
                                  JSON.parse(
                                    eachAttribute.AD
                                  ).IFR.toLowerCase() === "true"
                                    ? "col-lg-12 col-sm-12 col-xs-12 "
                                    : "col-lg-3 col-sm-3 col-xs-4"
                                }
                              >
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    {eachAttribute.AN}
                                    {attributeValidation[
                                      eachAttribute.AC
                                    ]?.ISM.toLowerCase() === "true" && (
                                      <sup>*</sup>
                                    )}
                                  </label>

                                  <div className="box position-relative">
                                    <input
                                      id={`files-${sectionIndex}`}
                                      className="form-control inputfile inputfile-6 multiple-inputfile"
                                      data-multiple-caption="{count} files selected"
                                      multiple={
                                        JSON.parse(eachAttribute.V).FC > 1
                                          ? true
                                          : false
                                      }
                                      accept={JSON.parse(eachAttribute.V).FX}
                                      type="file"
                                      onChange={(e) => {
                                        handleFileChangeForFlat(
                                          e,
                                          eachAttribute
                                        );
                                      }}
                                      value={""}
                                    />
                                    <label
                                      htmlFor={`files-${sectionIndex}`}
                                      className="form-control"
                                      style={{ width: 0, border: "none" }}
                                    >
                                      <strong
                                        style={{
                                          padding: "6px 16px",
                                          backgroundColor: "#3c5464",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <i
                                          className="fa fa-upload rotate90"
                                          aria-hidden="true"
                                        ></i>
                                        {"  "}
                                        Upload
                                      </strong>{" "}
                                    </label>
                                  </div>
                                  {formErrors[eachAttribute.AC] && (
                                    <p style={{ color: "red" }}>
                                      {formErrors[eachAttribute.AC]}
                                    </p>
                                  )}
                                  <div className="file-added-list">
                                    <ul className="list-unstyle">
                                      {selectedFilesForFlat &&
                                        selectedFilesForFlat.length > 0 &&
                                        selectedFilesForFlat.map(
                                          (fileAttribute, attributeIndex) => (
                                            <>
                                              {fileAttribute.AC ===
                                                eachAttribute.AC && (
                                                <li
                                                  className="list mt-1"
                                                  key={attributeIndex}
                                                >
                                                  <div className="media">
                                                    <div className="media-body text-truncate">
                                                      <span className="view-more">
                                                        {fileAttribute.Name}
                                                      </span>
                                                    </div>
                                                    {/*Here code for view same div of trash should be used. ISDOCVIEW/ISDOCDOWN*/}
                                                    {/* {
                                                      // section?.ISDOCVIEW &&
                                                      fileAttribute.DocId !==
                                                        0 && (
                                                        <div className="media-right ml-2">
                                                          <i
                                                            className="fa fa-eye"
                                                            aria-hidden="true"
                                                            onClick={() =>
                                                              handleViewForFlat(
                                                                fileAttribute,
                                                                attributeIndex
                                                              )
                                                            }></i>
                                                        </div>
                                                      )
                                                    } */}

                                                    {
                                                      //  section?.ISDOCDOWN &&
                                                      <div className="media-right ml-2">
                                                        <i
                                                          className="fa-solid fa-download"
                                                          aria-hidden="true"
                                                          onClick={() =>
                                                            handleDownloadFileFlat(
                                                              fileAttribute,
                                                              attributeIndex
                                                            )
                                                          }
                                                        ></i>
                                                      </div>
                                                    }

                                                    {(section?.ISEDIT ||
                                                      fileAttribute.DocId !==
                                                        0) && (
                                                      <div className="media-right ml-2">
                                                        <i
                                                          className="fa fa-trash"
                                                          aria-hidden="true"
                                                          onClick={() =>
                                                            handleDeleteFileForFlat(
                                                              fileAttribute,
                                                              attributeIndex
                                                            )
                                                          }
                                                        ></i>
                                                      </div>
                                                    )}
                                                  </div>
                                                </li>
                                              )}
                                            </>
                                          )
                                        )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : eachAttribute.DT ===
                            "SearchableDropdownSingle" ? (
                            <>
                              <div
                                className={
                                  JSON.parse(
                                    eachAttribute.AD
                                  )?.IFR?.toLowerCase() === "true"
                                    ? "col-lg-12 col-sm-12 col-xs-12 "
                                    : "col-lg-3 col-sm-3 col-xs-4"
                                }
                              >
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    {eachAttribute.AN}
                                    {attributeValidation[
                                      eachAttribute.AC
                                    ]?.ISM?.toLowerCase() === "true" && (
                                      <sup>*</sup>
                                    )}
                                  </label>

                                  <SelectForm
                                    isClearable
                                    isSearchable
                                    async
                                    options={(searchString, cb) =>
                                      getSearchableDropdownAPI(
                                        searchString,
                                        eachAttribute.AC,
                                        cb,
                                        "",
                                        "attribute",
                                        0
                                      )
                                    }
                                    placeholder={eachAttribute.AN}
                                    isDisabled={
                                      attributeValidation[eachAttribute.AC]
                                        ?.isDisable ||
                                      !section.ISEDIT ||
                                      eachAttribute.AC === "IOPPOS"
                                    }
                                    value={
                                      attributesData[eachAttribute.AC]
                                        ? attributesData[eachAttribute.AC]
                                        : null
                                    }
                                    onChange={async (event) => {
                                      setAttributesData((prev) => ({
                                        ...prev,
                                        [eachAttribute.AC]: event,
                                      }));

                                      if (
                                        eachAttribute.CC !== null &&
                                        eachAttribute.CC !== ""
                                      ) {
                                        const CC = eachAttribute.CC.split(",");

                                        CC.forEach(async (childDropdown) => {
                                          setAttributesData((prev) => ({
                                            ...prev,
                                            [childDropdown]: null,
                                          }));

                                          const options =
                                            await getDropdownsData(
                                              childDropdown,
                                              event ? event.value : ""
                                            );

                                          setOptionsObj((prev) => ({
                                            ...prev,
                                            [childDropdown]: options,
                                          }));
                                        });
                                      }

                                      dependentValidations(
                                        event,
                                        eachAttribute.AC,
                                        "Attribute"
                                      );

                                      //searchable DD
                                      if (event) {
                                        searchableDDValues(
                                          event,
                                          eachAttribute.AC
                                        );
                                      }

                                      if (
                                        eachAttribute.AC === "POS" &&
                                        event !== null
                                      ) {
                                        //console.log(event);

                                        const ddEvent = await getPPonIncm(
                                          eachAttribute.AC,
                                          event.value
                                        );
                                        //console.log(ddEvent);
                                        if (ddEvent !== undefined) {
                                          if (
                                            ddEvent.value !== null &&
                                            ddEvent.label !== null
                                          ) {
                                            const ac = "IOPPOS";
                                            //console.log('Inside if');
                                            //console.log(ac);
                                            const temp = {
                                              value: ddEvent.value,
                                              label: ddEvent.label,
                                            };
                                            //console.log(temp);
                                            setAttributesData((prev) => ({
                                              ...prev,
                                              POSED:
                                                moment().format("DD-MMM-YYYY"),
                                              TIPOS:
                                                "0 years, 0 months, 0 days",
                                              [ac]: temp,
                                            }));
                                          }
                                        } else {
                                          setAttributesData((prev) => ({
                                            ...prev,
                                            POSED:
                                              moment().format("DD-MMM-YYYY"),
                                            TIPOS: "0 years, 0 months, 0 days",
                                          }));
                                        }
                                        //console.log(attributesData);
                                      }
                                      if (
                                        eachAttribute.AC === "POS" &&
                                        event === null
                                      ) {
                                        const ac = "IOPPOS";
                                        console.log("HERE");
                                        setAttributesData((prev) => ({
                                          ...prev,
                                          [ac]: event,
                                        }));
                                      }

                                      //Hardcode to set PosEntry date and Time in Position.
                                      // if (eachAttribute.AC === "POS" && event) {
                                      //   setAttributesData((prev) => ({
                                      //     ...prev,
                                      //     POSED: moment().format("DD-MMM-YYYY"),
                                      //     TIPOS: "0 years, 0 months, 0 days",
                                      //   }));
                                      // }
                                    }}
                                    isMulti={false}
                                    noIndicator={false}
                                    noSeparator={false}
                                  />
                                  {formErrors[eachAttribute.AC] && (
                                    <p style={{ color: "red" }}>
                                      {formErrors[eachAttribute.AC]}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : eachAttribute.DT === "DocumentLink" ? (
                            <>
                              <div
                                className={
                                  JSON.parse(
                                    eachAttribute.AD
                                  ).IFR.toLowerCase() === "true"
                                    ? "col-lg-12 col-sm-12 col-xs-12 "
                                    : "col-lg-3 col-sm-3 col-xs-4"
                                }
                              >
                                <div className="mb-1">
                                  <label className="col-form-label">
                                    {eachAttribute.AN}
                                  </label>

                                  <ul className="list-unstyle">
                                    <li className="list mt-1">
                                      <a
                                        className="link-primary"
                                        href={
                                          JSON.parse(eachAttribute.AD)?.URLV
                                        }
                                        target="_blank"
                                      >
                                        {JSON.parse(eachAttribute.AD)?.URLN}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : eachAttribute.AT === "AttributeSet" &&
                        eachAttribute.DT === "Custom" ? (
                        <div className="col-lg-12">
                          {section?.ISEDIT && (
                            <div className="col-lg-12 mt-2 mb-3 d-flex justify-content-end">
                              <Tooltip title="Add">
                                <button
                                  id={"addRec"}
                                  className="btn btn-primary"
                                  onClick={() => {
                                    if (ShowAttrSet) {
                                      setAttributeSetData((prev) => ({
                                        ...prev,
                                        ...ogAttributeSet,
                                      }));
                                      setCurrentGridId(null);
                                      //setSelectedFilesForSet([]);
                                    }
                                    setShowAttrSet(!ShowAttrSet);
                                  }}
                                >
                                  <i className={"fa fa-plus"}></i> Add
                                </button>
                              </Tooltip>
                            </div>
                          )}
                          {ShowAttrSet && (
                            <div className="row">
                              {eachAttribute.Attribute.length > 0 &&
                                eachAttribute.Attribute.map(
                                  (subAttribute, index) => (
                                    <React.Fragment
                                      key={`${index}-${subAttribute.AC}`}
                                    >
                                      {subAttribute.DT === "DropdownSingle" ? (
                                        <>
                                          <div
                                            className={
                                              JSON.parse(
                                                subAttribute.AD
                                              ).IFR.toLowerCase() === "true"
                                                ? "col-lg-12 col-sm-12 col-xs-12 "
                                                : "col-lg-3 col-sm-3 col-xs-4"
                                            }
                                          >
                                            <div className="mb-1">
                                              <label className="col-form-label">
                                                {subAttribute.AN}
                                                {attributeSetValidation[
                                                  subAttribute.AC
                                                ]?.ISM.toLowerCase() ===
                                                  "true" && <sup>*</sup>}
                                              </label>

                                              <SelectForm
                                                isClearable
                                                isSearchable
                                                options={
                                                  optionsObj[subAttribute.AC]
                                                    ? optionsObj[
                                                        subAttribute.AC
                                                      ]
                                                    : []
                                                }
                                                {...(subAttribute.AC ===
                                                  "STSE" && {
                                                  filterOption: (option) =>
                                                    currentGridId === null &&
                                                    subAttribute.AC === "STSE"
                                                      ? option.label !==
                                                        "Demise"
                                                      : option,
                                                })}
                                                placeholder={subAttribute.AN}
                                                isDisabled={
                                                  showAttr ||
                                                  (subAttribute.AC ===
                                                    "STATDESE" &&
                                                    (currentGridId === "0" ||
                                                      currentGridId ===
                                                        null)) ||
                                                  (subAttribute.AC ===
                                                    "PVSEMP" &&
                                                    ogFilledAttributeSet.some(
                                                      (og) =>
                                                        (og.PVSEMP != null ||
                                                          og.PVSEMP !=
                                                            undefined) &&
                                                        !isNaN(og.Id) &&
                                                        og.IsActive
                                                    )) ||
                                                  attributeSetValidation[
                                                    subAttribute.AC
                                                  ]?.isDisable
                                                }
                                                value={
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ]
                                                    ? attributeSetData[
                                                        subAttribute.AC
                                                      ]
                                                    : null
                                                }
                                                onChange={async (event) => {
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      [subAttribute.AC]: event,
                                                    })
                                                  );
                                                  // if (
                                                  //   event !== null &&
                                                  //   subAttribute.AC === "RELSHTYP"
                                                  // ) {
                                                  //   const option =
                                                  //     await getJobRelationDD(
                                                  //       TId,
                                                  //       event
                                                  //     );
                                                  //     console.log(option);
                                                  //   setOptionsObj((prev) => ({
                                                  //     ...prev,
                                                  //     ["RELNME"]: option,
                                                  //   }));
                                                  // }
                                                  if (
                                                    subAttribute.AC === "ACT"
                                                  ) {
                                                    let attributeSetValObj = {
                                                      ...attributeSetValidation,
                                                    };
                                                    if (
                                                      event != null &&
                                                      event.label ===
                                                        "Car/Vehicle"
                                                    ) {
                                                      attributeSetValObj.ASMODLNUM.ISM =
                                                        "true";
                                                    } else {
                                                      attributeSetValObj.ASMODLNUM.ISM =
                                                        "false";
                                                    }
                                                    setAttributeSetValidation(
                                                      (prev) => ({
                                                        ...prev,
                                                        ...attributeSetValObj,
                                                      })
                                                    );
                                                  }
                                                  if (
                                                    subAttribute.AC === "PTP"
                                                  ) {
                                                    debugger;
                                                    let attributeSetValObj = {
                                                      ...attributeSetValidation,
                                                    };
                                                    if (
                                                      event != null &&
                                                      event.label ===
                                                        "Office Telephone"
                                                    ) {
                                                      attributeSetValObj.AC.ISM =
                                                        "true";
                                                      attributeSetValObj.OFFEX.ISM =
                                                        "true";
                                                    } else {
                                                      attributeSetValObj.AC.ISM =
                                                        "false";
                                                      attributeSetValObj.OFFEX.ISM =
                                                        "false";
                                                    }
                                                    setAttributeSetValidation(
                                                      (prev) => ({
                                                        ...prev,
                                                        ...attributeSetValObj,
                                                      })
                                                    );
                                                  }
                                                  if (
                                                    subAttribute.CC !== null &&
                                                    subAttribute.CC !== ""
                                                  ) {
                                                    const CC =
                                                      subAttribute.CC.split(
                                                        ","
                                                      );

                                                    CC.forEach(
                                                      async (childDropdown) => {
                                                        setAttributeSetData(
                                                          (prev) => ({
                                                            ...prev,
                                                            [childDropdown]:
                                                              null,
                                                          })
                                                        );

                                                        const options =
                                                          await getDropdownsData(
                                                            childDropdown,
                                                            event
                                                              ? event.value
                                                              : ""
                                                          );

                                                        setOptionsObj(
                                                          (prev) => ({
                                                            ...prev,
                                                            [childDropdown]:
                                                              options,
                                                          })
                                                        );
                                                      }
                                                    );
                                                  }

                                                  //hard code to enable valild till on change
                                                  //maybe add this in DD later for attributeSet
                                                  if (
                                                    event &&
                                                    (event.label.toLowerCase() ===
                                                      "driving license" ||
                                                      event.label.toLowerCase() ===
                                                        "passport")
                                                  ) {
                                                    setAttributeSetData(
                                                      (prev) => ({
                                                        ...prev,
                                                        NVALTIL: null,
                                                      })
                                                    );
                                                  }

                                                  //hard code for address information
                                                  if (
                                                    event &&
                                                    (subAttribute.AC === "PA" ||
                                                      subAttribute.AC ===
                                                        "ADRTYP")
                                                  ) {
                                                    if (
                                                      subAttribute.AC === "PA"
                                                    ) {
                                                      setAddressType(
                                                        (prev) => ({
                                                          ...prev,
                                                          address:
                                                            event.label.toLowerCase(),
                                                        })
                                                      );
                                                    } else {
                                                      setAddressType(
                                                        (prev) => ({
                                                          ...prev,
                                                          type: event.label.toLowerCase(),
                                                        })
                                                      );
                                                    }
                                                  }

                                                  if (
                                                    subAttribute.AC === "PA"
                                                  ) {
                                                    if (
                                                      event != null &&
                                                      event != undefined
                                                    ) {
                                                      if (
                                                        event.label.toLowerCase() ===
                                                          "emergency" ||
                                                        event.label.toLowerCase() ===
                                                          "permanent"
                                                      ) {
                                                        setOptionsObj(
                                                          (prev) => ({
                                                            ...prev,
                                                            ["ADRTYP"]: [],
                                                          })
                                                        );

                                                        let attributeSetValObj =
                                                          {
                                                            ...attributeSetValidation,
                                                          };
                                                        attributeSetValObj.ADRTYP.ISM =
                                                          "false";
                                                        setAttributeSetValidation(
                                                          (prev) => ({
                                                            ...prev,
                                                            ...attributeSetValObj,
                                                          })
                                                        );
                                                        setAttributeSetData(
                                                          (prev) => ({
                                                            ...prev,
                                                            ["ADRTYP"]: null,
                                                          })
                                                        );
                                                      } else {
                                                        const adrTOPT =
                                                          eachAttribute.Attribute.find(
                                                            (item) =>
                                                              item.AC ===
                                                              "ADRTYP"
                                                          );
                                                        if (adrTOPT) {
                                                          setOptionsObj(
                                                            (prev) => ({
                                                              ...prev,
                                                              ["ADRTYP"]:
                                                                adrTOPT.OPT,
                                                            })
                                                          );
                                                        }
                                                        let attributeSetValObj =
                                                          {
                                                            ...attributeSetValidation,
                                                          };
                                                        attributeSetValObj.ADRTYP.ISM =
                                                          "true";
                                                        setAttributeSetValidation(
                                                          (prev) => ({
                                                            ...prev,
                                                            ...attributeSetValObj,
                                                          })
                                                        );
                                                      }
                                                    } else {
                                                      const adrTOPT =
                                                        eachAttribute.Attribute.find(
                                                          (item) =>
                                                            item.AC === "ADRTYP"
                                                        );
                                                      if (adrTOPT) {
                                                        setOptionsObj(
                                                          (prev) => ({
                                                            ...prev,
                                                            ["ADRTYP"]:
                                                              adrTOPT.OPT,
                                                          })
                                                        );
                                                      }
                                                      setAttributeSetData(
                                                        (prev) => ({
                                                          ...prev,
                                                          ["ADRTYP"]: null,
                                                        })
                                                      );
                                                    }
                                                  }
                                                  dependentValidations(
                                                    event,
                                                    subAttribute.AC,
                                                    "Attributeset"
                                                  );
                                                }}
                                                isMulti={false}
                                                noIndicator={false}
                                                noSeparator={false}
                                              />
                                              {formErrors[subAttribute.AC] && (
                                                <p style={{ color: "red" }}>
                                                  {formErrors[subAttribute.AC]}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ) : subAttribute.DT === "Text" ||
                                        subAttribute.DT === "Number" ? (
                                        <>
                                          <div
                                            className={
                                              JSON.parse(
                                                subAttribute.AD
                                              ).IFR.toLowerCase() === "true"
                                                ? "col-lg-12 col-sm-12 col-xs-12 "
                                                : "col-lg-3 col-sm-3 col-xs-4"
                                            }
                                          >
                                            <div className="mb-1">
                                              <label className="col-form-label">
                                                {subAttribute.AN}
                                                {attributeSetValidation[
                                                  subAttribute.AC
                                                ]?.ISM.toLowerCase() ===
                                                  "true" && <sup>*</sup>}
                                              </label>

                                              <InputForm
                                                className="form-control"
                                                placeholder={subAttribute.AN}
                                                isDisabled={
                                                  showAttr ||
                                                  subAttribute.AC ===
                                                    "RELNNM" ||
                                                  subAttribute.AC ===
                                                    "DURGCRC" ||
                                                  subAttribute.AC === "PLEN" ||
                                                  subAttribute.AC === "RELNCOMP"
                                                    ? true
                                                    : subAttribute.AC === "AC"
                                                    ? attributeSetData["PTP"]
                                                        ?.label ===
                                                      "Office Telephone"
                                                      ? false
                                                      : true
                                                    : subAttribute.AC ===
                                                      "OFFEX"
                                                    ? attributeSetData["PTP"]
                                                        ?.label ===
                                                      "Office Telephone"
                                                      ? false
                                                      : true
                                                    : attributeSetValidation[
                                                        subAttribute.AC
                                                      ]?.isDisable
                                                }
                                                textArea={false}
                                                value={
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ]
                                                    ? attributeSetData[
                                                        subAttribute.AC
                                                      ]
                                                    : ""
                                                }
                                                onChange={(e) => {
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      [subAttribute.AC]:
                                                        subAttribute.AC === "EA"
                                                          ? e.target.value.toLowerCase()
                                                          : e.target.value,
                                                    })
                                                  );
                                                }}
                                                maxLength="255"
                                              />
                                              {formErrors[subAttribute.AC] && (
                                                <p style={{ color: "red" }}>
                                                  {formErrors[subAttribute.AC]}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ) : subAttribute.DT === "Date" ? (
                                        <>
                                          <div
                                            className={
                                              JSON.parse(
                                                subAttribute.AD
                                              ).IFR.toLowerCase() === "true"
                                                ? "col-lg-12 col-sm-12 col-xs-12 "
                                                : "col-lg-3 col-sm-3 col-xs-4"
                                            }
                                          >
                                            <div className="mb-1">
                                              <label className="col-form-label">
                                                {subAttribute.AN}
                                                {/* {JSON.parse(
                                                subAttribute.V
                                              ).ISM.toLowerCase() === "true" && (
                                                <sup>*</sup>
                                              )} */}
                                                {attributeSetValidation[
                                                  subAttribute.AC
                                                ]?.ISM.toLowerCase() ===
                                                  "true" && <sup>*</sup>}
                                              </label>

                                              <DateForm
                                                isDisabled={
                                                  showAttr ||
                                                  subAttribute.AC === "NVALTIL"
                                                    ? attributeSetData?.IDCATY !==
                                                        null &&
                                                      (attributeSetData?.IDCATY?.label.toLowerCase() ===
                                                        "driving license" ||
                                                        attributeSetData?.IDCATY?.label.toLowerCase() ===
                                                          "passport")
                                                      ? false
                                                      : true
                                                    : subAttribute.AC ===
                                                        "LSD" ||
                                                      subAttribute.AC === "LED"
                                                    ? attributeSetData?.ADRTYP !=
                                                        null &&
                                                      attributeSetData?.ADRTYP?.label.toLowerCase() ===
                                                        "lease"
                                                      ? false
                                                      : true
                                                    : attributeSetValidation[
                                                        subAttribute.AC
                                                      ]?.isDisable
                                                }
                                                value={
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ]
                                                    ? attributeSetData[
                                                        subAttribute.AC
                                                      ]
                                                    : null
                                                }
                                                onChange={(date) => {
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      [subAttribute.AC]:
                                                        date != null
                                                          ? moment(date).format(
                                                              "DD-MMM-YYYY"
                                                            )
                                                          : null,
                                                    })
                                                  );

                                                  //hard code to calculate duration in formal education
                                                  if (
                                                    date &&
                                                    (subAttribute.AC ===
                                                      "FRMDTFE" ||
                                                      subAttribute.AC ===
                                                        "ENDDTED")
                                                  ) {
                                                    const endDate = moment(
                                                      subAttribute.AC ===
                                                        "ENDDTED"
                                                        ? date
                                                        : attributeSetData[
                                                            "ENDDTED"
                                                          ] !== null
                                                        ? attributeSetData[
                                                            "ENDDTED"
                                                          ]
                                                        : date
                                                    );

                                                    const fromDate = moment(
                                                      subAttribute.AC ===
                                                        "FRMDTFE"
                                                        ? date
                                                        : attributeSetData[
                                                            "FRMDTFE"
                                                          ] !== null
                                                        ? attributeSetData[
                                                            "FRMDTFE"
                                                          ]
                                                        : date
                                                    );

                                                    const duration =
                                                      moment.duration(
                                                        endDate.diff(fromDate)
                                                      );

                                                    const durationInYears =
                                                      duration.years();

                                                    const durationInMonths =
                                                      duration.months();

                                                    const durationInDays =
                                                      duration.days();

                                                    if (
                                                      isNaN(durationInYears) ||
                                                      durationInYears < 0 ||
                                                      isNaN(durationInMonths) ||
                                                      durationInMonths < 0 ||
                                                      isNaN(durationInDays) ||
                                                      durationInDays < 0
                                                    ) {
                                                      setAttributeSetData(
                                                        (prev) => ({
                                                          ...prev,
                                                          ["DURGCRC"]: "",
                                                        })
                                                      );
                                                    } else {
                                                      setAttributeSetData(
                                                        (prev) => ({
                                                          ...prev,
                                                          ["DURGCRC"]: `${durationInYears} years ${durationInMonths} months ${durationInDays} days`,
                                                        })
                                                      );
                                                    }
                                                  }
                                                  /*Hardcode to set learning duration */
                                                  if (
                                                    date &&
                                                    (subAttribute.AC ===
                                                      "DTEC" ||
                                                      subAttribute.AC ===
                                                        "DTES")
                                                  ) {
                                                    const endDate = moment(
                                                      subAttribute.AC === "DTEC"
                                                        ? date
                                                        : attributeSetData[
                                                            "DTEC"
                                                          ] !== null
                                                        ? attributeSetData[
                                                            "DTEC"
                                                          ]
                                                        : date
                                                    );

                                                    const fromDate = moment(
                                                      subAttribute.AC === "DTES"
                                                        ? date
                                                        : attributeSetData[
                                                            "DTES"
                                                          ] !== null
                                                        ? attributeSetData[
                                                            "DTES"
                                                          ]
                                                        : date
                                                    );

                                                    const duration =
                                                      moment.duration(
                                                        endDate.diff(fromDate)
                                                      );

                                                    const durationInYears =
                                                      duration.years();

                                                    const durationInMonths =
                                                      duration.months();

                                                    const durationInDays =
                                                      duration.days();

                                                    if (
                                                      isNaN(durationInYears) ||
                                                      durationInYears < 0 ||
                                                      isNaN(durationInMonths) ||
                                                      durationInMonths < 0 ||
                                                      isNaN(durationInDays) ||
                                                      durationInDays < 0
                                                    ) {
                                                      setAttributeSetData(
                                                        (prev) => ({
                                                          ...prev,
                                                          ["PLEN"]: "",
                                                        })
                                                      );
                                                    } else {
                                                      setAttributeSetData(
                                                        (prev) => ({
                                                          ...prev,
                                                          ["PLEN"]: `${durationInYears} years ${durationInMonths} months ${durationInDays} days`,
                                                        })
                                                      );
                                                    }
                                                  }
                                                  /*Hardcode to set learning duration */
                                                }}
                                              />
                                              {formErrors[subAttribute.AC] && (
                                                <p style={{ color: "red" }}>
                                                  {formErrors[subAttribute.AC]}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ) : subAttribute.DT === "Checkbox" ? (
                                        <>
                                          <div
                                            className={
                                              JSON.parse(
                                                subAttribute.AD
                                              ).IFR.toLowerCase() === "true"
                                                ? "col-lg-12 col-sm-12 col-xs-12 "
                                                : "col-lg-3 col-sm-3 col-xs-4"
                                            }
                                          >
                                            <div className="mb-1">
                                              <label
                                                htmlFor={subAttribute.AC}
                                                className="col-form-label"
                                              >
                                                {subAttribute.AN}
                                                {attributeSetValidation[
                                                  subAttribute.AC
                                                ]?.ISM.toLowerCase() ===
                                                  "true" && <sup>*</sup>}
                                              </label>

                                              <div>
                                                <input
                                                  disabled={
                                                    showAttr ||
                                                    attributeSetValidation[
                                                      subAttribute.AC
                                                    ]?.isDisable
                                                  }
                                                  type="checkbox"
                                                  onChange={(e) => {
                                                    setAttributeSetData(
                                                      (prev) => ({
                                                        ...prev,
                                                        [subAttribute.AC]:
                                                          e.target.checked,
                                                      })
                                                    );
                                                  }}
                                                  id={subAttribute.AC}
                                                  checked={
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ]
                                                      ? attributeSetData[
                                                          subAttribute.AC
                                                        ]
                                                      : false
                                                  }
                                                />
                                              </div>
                                              {formErrors[subAttribute.AC] && (
                                                <p style={{ color: "red" }}>
                                                  {formErrors[subAttribute.AC]}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ) : subAttribute.DT === "Document" ? (
                                        <>
                                          <div
                                            className={
                                              JSON.parse(
                                                subAttribute.AD
                                              ).IFR.toLowerCase() === "true"
                                                ? "col-lg-12 col-sm-12 col-xs-12 "
                                                : "col-lg-3 col-sm-3 col-xs-4"
                                            }
                                          >
                                            <div className="mb-1">
                                              <label className="col-form-label">
                                                {subAttribute.AN}
                                                {attributeSetValidation[
                                                  subAttribute.AC
                                                ]?.ISM.toLowerCase() ===
                                                  "true" && <sup>*</sup>}
                                              </label>
                                              <div className="box position-relative">
                                                <input
                                                  id={`files-${sectionIndex}`}
                                                  disabled={
                                                    showAttr ||
                                                    attributeSetValidation[
                                                      subAttribute.AC
                                                    ]?.isDisable
                                                  }
                                                  className="form-control inputfile inputfile-6 multiple-inputfile"
                                                  data-multiple-caption="{count} files selected"
                                                  multiple={
                                                    JSON.parse(subAttribute.V)
                                                      .FC > 1
                                                      ? true
                                                      : false
                                                  }
                                                  accept={
                                                    JSON.parse(subAttribute.V)
                                                      .FX
                                                  }
                                                  type="file"
                                                  onChange={(e) => {
                                                    handleFileChangeForSet(
                                                      e,
                                                      subAttribute
                                                    );
                                                  }}
                                                  value={""}
                                                />
                                                <label
                                                  htmlFor={`files-${sectionIndex}`}
                                                  className="form-control"
                                                  style={{
                                                    width: 0,
                                                    border: "none",
                                                  }}
                                                >
                                                  <strong
                                                    style={{
                                                      padding: "6px 16px",
                                                      backgroundColor:
                                                        "#3c5464",
                                                      borderRadius: "5px",
                                                    }}
                                                  >
                                                    <i
                                                      className="fa fa-upload rotate90"
                                                      aria-hidden="true"
                                                    ></i>
                                                    {"  "}
                                                    Upload
                                                  </strong>{" "}
                                                </label>
                                              </div>
                                              {formErrors[subAttribute.AC] && (
                                                <p style={{ color: "red" }}>
                                                  {formErrors[subAttribute.AC]}
                                                </p>
                                              )}
                                              <div className="file-added-list">
                                                <ul className="list-unstyle">
                                                  {selectedFilesForSet &&
                                                    selectedFilesForSet.length >
                                                      0 &&
                                                    selectedFilesForSet.map(
                                                      (
                                                        fileAttribute,
                                                        attributeIndex
                                                      ) => (
                                                        <>
                                                          {fileAttribute.AC ===
                                                            subAttribute.AC &&
                                                            (fileAttribute.Id ===
                                                              0 ||
                                                              fileAttribute.Id ==
                                                                currentGridId) && (
                                                              <li
                                                                className="list mt-1"
                                                                key={
                                                                  attributeIndex
                                                                }
                                                              >
                                                                <div className="media">
                                                                  <div className="media-body text-truncate">
                                                                    <span className="view-more">
                                                                      {
                                                                        fileAttribute.Name
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                  {/*Here code for view same div of trash should be used. ISDOCVIEW/ISDOCDOWN*/}
                                                                  {/* {
                                                                    // section?.ISDOCVIEW &&
                                                                    fileAttribute.DocId !==
                                                                      0 && (
                                                                      <div className="media-right ml-2">
                                                                        <i
                                                                          className="fa fa-eye"
                                                                          aria-hidden="true"
                                                                          onClick={() =>
                                                                            handleViewForFlat(
                                                                              fileAttribute,
                                                                              attributeIndex
                                                                            )
                                                                          }></i>
                                                                      </div>
                                                                    )
                                                                  } */}

                                                                  {
                                                                    // section?.ISDOCDOWN &&
                                                                    <div className="media-right ml-2">
                                                                      <i
                                                                        className="fa-solid fa-download"
                                                                        aria-hidden="true"
                                                                        onClick={() =>
                                                                          handleDownloadFileFlat(
                                                                            fileAttribute,
                                                                            attributeIndex
                                                                          )
                                                                        }
                                                                      ></i>
                                                                    </div>
                                                                  }
                                                                  {(section?.ISEDIT ||
                                                                    fileAttribute.DocId !==
                                                                      0) && (
                                                                    <div className="media-right ml-2">
                                                                      <i
                                                                        className="fa fa-trash"
                                                                        aria-hidden="true"
                                                                        onClick={() =>
                                                                          handleDeleteFileForSet(
                                                                            fileAttribute,
                                                                            attributeIndex
                                                                          )
                                                                        }
                                                                      ></i>
                                                                    </div>
                                                                  )}
                                                                </div>
                                                              </li>
                                                            )}
                                                        </>
                                                      )
                                                    )}
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : subAttribute.DT ===
                                        "SearchableDropdownSingle" ? (
                                        <>
                                          <div
                                            className={
                                              JSON.parse(
                                                subAttribute.AD
                                              ).IFR.toLowerCase() === "true"
                                                ? "col-lg-12 col-sm-12 col-xs-12 "
                                                : "col-lg-3 col-sm-3 col-xs-4"
                                            }
                                          >
                                            <div className="mb-1">
                                              <label className="col-form-label">
                                                {subAttribute.AN}
                                                {attributeSetValidation[
                                                  subAttribute.AC
                                                ]?.ISM.toLowerCase() ===
                                                  "true" && <sup>*</sup>}
                                              </label>

                                              <SelectForm
                                                isClearable
                                                isSearchable
                                                async
                                                options={(searchString, cb) =>
                                                  getSearchableDropdownAPI(
                                                    searchString,
                                                    subAttribute.AC,
                                                    cb,
                                                    "",
                                                    "attributeSet",
                                                    0
                                                  )
                                                }
                                                placeholder={subAttribute.AN}
                                                isDisabled={
                                                  !section.ISEDIT ||
                                                  attributeSetValidation[
                                                    eachAttribute.AC
                                                  ]?.isDisable
                                                }
                                                value={
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ]
                                                    ? attributeSetData[
                                                        subAttribute.AC
                                                      ]
                                                    : null
                                                }
                                                onChange={(event) => {
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      [subAttribute.AC]: event,
                                                    })
                                                  );

                                                  if (
                                                    subAttribute.CC !== null &&
                                                    subAttribute.CC !== ""
                                                  ) {
                                                    const CC =
                                                      subAttribute.CC.split(
                                                        ","
                                                      );

                                                    CC.forEach(
                                                      async (childDropdown) => {
                                                        setAttributeSetData(
                                                          (prev) => ({
                                                            ...prev,
                                                            [childDropdown]:
                                                              null,
                                                          })
                                                        );

                                                        const options =
                                                          await getDropdownsData(
                                                            childDropdown,
                                                            event
                                                              ? event.value
                                                              : ""
                                                          );

                                                        setOptionsObj(
                                                          (prev) => ({
                                                            ...prev,
                                                            [childDropdown]:
                                                              options,
                                                          })
                                                        );
                                                      }
                                                    );
                                                  }

                                                  // dependentValidations(
                                                  //   event,
                                                  //   subAttribute.AC
                                                  // );

                                                  //searchable DD
                                                  if (event) {
                                                    searchableDDValuesSet(
                                                      event,
                                                      subAttribute.AC
                                                    );
                                                  }
                                                }}
                                                isMulti={false}
                                                noIndicator={false}
                                                noSeparator={false}
                                              />
                                              {formErrors[subAttribute.AC] && (
                                                <p style={{ color: "red" }}>
                                                  {formErrors[subAttribute.AC]}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ) : subAttribute.DT ===
                                        "DropdownMultiple" ? (
                                        <>
                                          <div
                                            className={
                                              JSON.parse(
                                                subAttribute.AD
                                              ).IFR.toLowerCase() === "true"
                                                ? "col-lg-12 col-sm-12 col-xs-12 "
                                                : "col-lg-3 col-sm-3 col-xs-4"
                                            }
                                          >
                                            <div className="mb-1">
                                              <label className="col-form-label">
                                                {subAttribute.AN}
                                                {JSON.parse(
                                                  subAttribute.V
                                                ).ISM.toLowerCase() ===
                                                  "true" && <sup>*</sup>}
                                              </label>

                                              <SelectForm
                                                isClearable
                                                isSearchable
                                                options={
                                                  optionsObj[subAttribute.AC]
                                                    ? optionsObj[
                                                        subAttribute.AC
                                                      ]
                                                    : []
                                                }
                                                isMulti={multiDropdown}
                                                placeholder={subAttribute.AN}
                                                isDisabled={!section.ISEDIT}
                                                value={
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ]
                                                    ? attributeSetData[
                                                        subAttribute.AC
                                                      ]
                                                    : null
                                                }
                                                onChange={(event) => {
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      [subAttribute.AC]: event,
                                                    })
                                                  );

                                                  if (
                                                    event !== null &&
                                                    subAttribute.CC !== null &&
                                                    subAttribute.CC !== ""
                                                  ) {
                                                    const CC =
                                                      subAttribute.CC.split(
                                                        ","
                                                      );

                                                    CC.forEach(
                                                      async (childDropdown) => {
                                                        setAttributeSetData(
                                                          (prev) => ({
                                                            ...prev,
                                                            [childDropdown]:
                                                              null,
                                                          })
                                                        );

                                                        const options =
                                                          await getDropdownsData(
                                                            childDropdown,
                                                            event.value
                                                          );

                                                        setOptionsObj(
                                                          (prev) => ({
                                                            ...prev,
                                                            [childDropdown]:
                                                              options,
                                                          })
                                                        );
                                                      }
                                                    );
                                                  }
                                                }}
                                                noIndicator={false}
                                                noSeparator={false}
                                              />
                                              {formErrors[subAttribute.AC] && (
                                                <p style={{ color: "red" }}>
                                                  {formErrors[subAttribute.AC]}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ) : subAttribute.DT ===
                                        "DropdownMultiple" ? (
                                        <>
                                          <div
                                            className={
                                              JSON.parse(
                                                subAttribute.AD
                                              ).IFR.toLowerCase() === "true"
                                                ? "col-lg-12 col-sm-12 col-xs-12 "
                                                : "col-lg-3 col-sm-3 col-xs-4"
                                            }
                                          >
                                            <div className="mb-1">
                                              <label className="col-form-label">
                                                {subAttribute.AN}
                                                {JSON.parse(
                                                  subAttribute.V
                                                ).ISM.toLowerCase() ===
                                                  "true" && <sup>*</sup>}
                                              </label>

                                              <SelectForm
                                                isClearable
                                                isSearchable
                                                options={
                                                  optionsObj[subAttribute.AC]
                                                    ? optionsObj[
                                                        subAttribute.AC
                                                      ]
                                                    : []
                                                }
                                                isMulti={multiDropdown}
                                                placeholder={subAttribute.AN}
                                                isDisabled={!section.ISEDIT}
                                                value={
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ]
                                                    ? attributeSetData[
                                                        subAttribute.AC
                                                      ]
                                                    : null
                                                }
                                                onChange={(event) => {
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      [subAttribute.AC]: event,
                                                    })
                                                  );
                                                  if (
                                                    event !== null &&
                                                    subAttribute.CC !== null &&
                                                    subAttribute.CC !== ""
                                                  ) {
                                                    const CC =
                                                      subAttribute.CC.split(
                                                        ","
                                                      );

                                                    CC.forEach(
                                                      async (childDropdown) => {
                                                        setAttributeSetData(
                                                          (prev) => ({
                                                            ...prev,
                                                            [childDropdown]:
                                                              null,
                                                          })
                                                        );

                                                        const options =
                                                          await getDropdownsData(
                                                            childDropdown,
                                                            event.value
                                                          );

                                                        setOptionsObj(
                                                          (prev) => ({
                                                            ...prev,
                                                            [childDropdown]:
                                                              options,
                                                          })
                                                        );
                                                      }
                                                    );
                                                  }
                                                }}
                                                noIndicator={false}
                                                noSeparator={false}
                                              />
                                              {formErrors[subAttribute.AC] && (
                                                <p style={{ color: "red" }}>
                                                  {formErrors[subAttribute.AC]}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ) : subAttribute.DT === "DocumentLink" ? (
                                        <>
                                          <div
                                            className={
                                              JSON.parse(
                                                subAttribute.AD
                                              ).IFR.toLowerCase() === "true"
                                                ? "col-lg-12 col-sm-12 col-xs-12 "
                                                : "col-lg-3 col-sm-3 col-xs-4"
                                            }
                                          >
                                            <div className="mb-1">
                                              <label className="col-form-label">
                                                {subAttribute.AN}
                                              </label>
                                              <ul className="list-unstyle">
                                                <li className="list mt-1">
                                                  <a
                                                    className="link-primary"
                                                    href={
                                                      JSON.parse(
                                                        subAttribute.AD
                                                      )?.URLV
                                                    }
                                                    target="_blank"
                                                  >
                                                    {
                                                      JSON.parse(
                                                        subAttribute.AD
                                                      )?.URLN
                                                    }
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </React.Fragment>
                                  )
                                )}
                            </div>
                          )}
                          {/*Added Custom logic for Address Type for Address information - Sanket*/}

                          {section?.SN === "AddressInformation" &&
                            ShowAttrSet && (
                              <div className="col-lg-12 mt-2 mb-3 d-flex justify-content-end">
                                {/* <> {console.log(addressType)}</>
                                <div>
                                  <label
                                    htmlFor={"Same for Emergency"}
                                    className="col-form-label">
                                    Same for Emergency
                                  </label>
                                  <input
                                    disabled={
                                      attributeValidation[eachAttribute.AC]
                                        ?.isDisable || showAttr
                                    }
                                    type="checkbox"
                                    onChange={(event) =>
                                      checkSameAddressType(event, "emergency")
                                    }
                                    id={eachAttribute.AC}
                                    checked={isSameAsEmergency}
                                  />
                                </div>
                                <div>
                                  <label
                                    htmlFor={"Same for Permanent"}
                                    className="col-form-label">
                                    Same for Permanent
                                  </label>
                                  <input
                                    type="checkbox"
                                    onChange={(event) =>
                                      checkSameAddressType(event, "Permanent")
                                    }
                                    id={eachAttribute.AC}
                                    checked={isSameAsPermanent}
                                  />
                                </div>
                                <div>
                                  <label
                                    htmlFor={"Same for Present"}
                                    className="col-form-label">
                                    Same for Present
                                  </label>
                                  <input
                                    type="checkbox"
                                    onChange={(event) =>
                                      checkSameAddressType(event, "Present")
                                    }
                                    id={eachAttribute.AC}
                                    checked={isSameAsPresent}
                                  />
                                </div> */}
                              </div>
                            )}
                          <div className="row">
                            {ShowAttrSet && (
                              <div className="col-lg-12 mt-2 mb-3">
                                {section?.ISEDIT && (
                                  <button
                                    id={currentGridId === null ? "0" : "1"}
                                    className="btn btn-primary"
                                    onClick={async (e) => {
                                      const targetId = e.currentTarget.id;

                                      if (targetId === "0") {
                                        //insert

                                        if (
                                          section.SN === "AddressInformation"
                                        ) {
                                          let key = "PA";

                                          if (key !== "") {
                                            for (
                                              let i = 0;
                                              i < attributeSetData["PA"].length;
                                              i++
                                            ) {
                                              //debugger;
                                              let attributed =
                                                attributeSetData["PA"][i];
                                              let filteredEmail =
                                                ogFilledAttributeSet.filter(
                                                  (og) => {
                                                    return (
                                                      og.PA?.label ===
                                                        attributed.label &&
                                                      og.IsActive === true
                                                    );
                                                  }
                                                );
                                              //if (!isError) {

                                              //           }
                                              //);
                                              if (filteredEmail.length > 0) {
                                                notify(
                                                  1,
                                                  attributed.label +
                                                    " Address Type Already Exist, Can't Insert Another."
                                                );
                                                return;
                                              }
                                            }

                                            attributeSetData[`${key}`].map(
                                              (value, indx) => {
                                                const uniqueId = uuid();

                                                //validation for empty data before adding in grid
                                                let isError = false;
                                                if (indx === 0) {
                                                  isError = validateData(
                                                    eachAttribute.Attribute
                                                  );
                                                }

                                                if (!isError) {
                                                  //add record in grid

                                                  let gridData = {};
                                                  let ogf = {};
                                                  eachAttribute.Attribute.forEach(
                                                    async (subAttribute) => {
                                                      console.log(
                                                        subAttribute,
                                                        subAttribute
                                                      );

                                                      if (
                                                        typeof attributeSetData[
                                                          subAttribute.AC
                                                        ] === "object" &&
                                                        Array.isArray(
                                                          attributeSetData[
                                                            subAttribute.AC
                                                          ]
                                                        ) === true
                                                      ) {
                                                        //let gridTemp = attributeSetData[subAttribute.AC].map(item => item.label).join(', ');
                                                        gridData = {
                                                          ...gridData,
                                                          [subAttribute.AC]:
                                                            value.label
                                                              ? value.label
                                                              : null, //for MultiSelect Dropdownn
                                                        };
                                                        ogf = {
                                                          ...ogf,
                                                          [subAttribute.AC]:
                                                            value,
                                                        };
                                                      } else if (
                                                        typeof attributeSetData[
                                                          subAttribute.AC
                                                        ] === "object"
                                                      ) {
                                                        gridData = {
                                                          ...gridData,
                                                          [subAttribute.AC]:
                                                            attributeSetData[
                                                              subAttribute.AC
                                                            ]?.label || null, //for date and dropdown
                                                        };
                                                        ogf = {
                                                          ...ogf,
                                                          [subAttribute.AC]:
                                                            attributeSetData[
                                                              subAttribute.AC
                                                            ],
                                                        };
                                                      } else if (
                                                        typeof attributeSetData[
                                                          subAttribute.AC
                                                        ] === "boolean"
                                                      ) {
                                                        gridData = {
                                                          ...gridData,
                                                          [subAttribute.AC]:
                                                            attributeSetData[
                                                              subAttribute.AC
                                                            ] === true
                                                              ? "true"
                                                              : "false",
                                                        };
                                                        ogf = {
                                                          ...ogf,
                                                          [subAttribute.AC]:
                                                            attributeSetData[
                                                              subAttribute.AC
                                                            ],
                                                        };
                                                      } else {
                                                        gridData = {
                                                          ...gridData,
                                                          [subAttribute.AC]:
                                                            attributeSetData[
                                                              subAttribute.AC
                                                            ],
                                                        };
                                                        ogf = {
                                                          ...ogf,
                                                          [subAttribute.AC]:
                                                            attributeSetData[
                                                              subAttribute.AC
                                                            ],
                                                        };
                                                      }
                                                    }
                                                  );
                                                  setOgFilledAttributeSet(
                                                    (prev) => [
                                                      ...prev,
                                                      {
                                                        ...ogf,
                                                        Id: uniqueId,
                                                        IsActive: true,
                                                      },
                                                    ]
                                                  );
                                                  //reset
                                                  setAttributeSetData(
                                                    (prev) => ({
                                                      ...prev,
                                                      ...ogAttributeSet,
                                                    })
                                                  );

                                                  setFormErrors({});

                                                  setGridData((prev) => [
                                                    ...prev,
                                                    {
                                                      Id: uniqueId,
                                                      ...gridData,
                                                      IsActive: true,
                                                    },
                                                  ]);

                                                  setDisplayGrid((prev) => [
                                                    ...prev,
                                                    {
                                                      Id: uniqueId,
                                                      ...gridData,
                                                      IsActive: true,
                                                    },
                                                  ]);
                                                }
                                              }
                                            );
                                          }
                                        } else {
                                          const uniqueId = uuid();

                                          //validation for empty data before adding in grid
                                          //debugger;
                                          const isError = validateData(
                                            eachAttribute.Attribute
                                          );
                                          if (
                                            section.SN === "BankInformation"
                                          ) {
                                            //debugger;
                                            if (!isError) {
                                              await validateBankAccAdhar();
                                            }
                                          }

                                          // if (
                                          //   !isError &&
                                          //   validAccount.current === true &&
                                          //   section.SN === "BankInformation"
                                          // ) {
                                          //   //add record in grid
                                          //   setOgFilledAttributeSet((prev) => [
                                          //     ...prev,
                                          //     {
                                          //       ...attributeSetData,
                                          //       Id: uniqueId,
                                          //       IsActive: true,
                                          //     },
                                          //   ]);

                                          if (
                                            !isError &&
                                            validAccount.current === true &&
                                            section.SN === "BankInformation"
                                          ) {
                                            //add record in grid
                                            setOgFilledAttributeSet((prev) => [
                                              ...prev,
                                              {
                                                ...attributeSetData,
                                                Id: uniqueId,
                                                IsActive: true,
                                              },
                                            ]);

                                            let gridData = {};

                                            eachAttribute.Attribute.forEach(
                                              async (subAttribute) => {
                                                if (
                                                  typeof attributeSetData[
                                                    subAttribute.AC
                                                  ] === "object"
                                                ) {
                                                  gridData = {
                                                    ...gridData,
                                                    [subAttribute.AC]:
                                                      attributeSetData[
                                                        subAttribute.AC
                                                      ]?.label || null, //for date and dropdown
                                                  };
                                                } else if (
                                                  typeof attributeSetData[
                                                    subAttribute.AC
                                                  ] === "boolean"
                                                ) {
                                                  gridData = {
                                                    ...gridData,
                                                    [subAttribute.AC]:
                                                      attributeSetData[
                                                        subAttribute.AC
                                                      ] === true
                                                        ? "true"
                                                        : "false",
                                                  };
                                                } else {
                                                  gridData = {
                                                    ...gridData,
                                                    [subAttribute.AC]:
                                                      attributeSetData[
                                                        subAttribute.AC
                                                      ],
                                                  };
                                                }
                                              }
                                            );

                                            //reset
                                            setAttributeSetData((prev) => ({
                                              ...prev,
                                              ...ogAttributeSet,
                                            }));

                                            setFormErrors({});

                                            setNewGridIdCreated(uniqueId); //send uniqueId for document

                                            setGridData((prev) => [
                                              ...prev,
                                              {
                                                Id: uniqueId,
                                                ...gridData,
                                                IsActive: true,
                                              },
                                            ]);

                                            setDisplayGrid((prev) => [
                                              ...prev,
                                              {
                                                Id: uniqueId,
                                                ...gridData,
                                                IsActive: true,
                                              },
                                            ]);
                                          }
                                          //}
                                          if (
                                            !isError &&
                                            section.SN != "BankInformation"
                                          ) {
                                            //add record in grid

                                            if (
                                              !isError &&
                                              section.SN === "Email Information"
                                            ) {
                                              debugger;
                                              let filteredEmail = [];
                                              if (
                                                attributeSetData["ETP"]
                                                  .label === "Business"
                                              ) {
                                                filteredEmail =
                                                  ogFilledAttributeSet.filter(
                                                    (og) => {
                                                      return (
                                                        og.ETP?.label ===
                                                          attributeSetData[
                                                            "ETP"
                                                          ].label &&
                                                        og.IsActive === true
                                                      );
                                                    }
                                                  );
                                              } else {
                                                filteredEmail =
                                                  ogFilledAttributeSet.filter(
                                                    (og) => {
                                                      return (
                                                        og.ETP?.label ===
                                                          attributeSetData[
                                                            "ETP"
                                                          ].label &&
                                                        og.EA ===
                                                          attributeSetData[
                                                            "EA"
                                                          ] &&
                                                        og.IsActive === true
                                                      );
                                                    }
                                                  );
                                              }

                                              if (filteredEmail.length > 0) {
                                                notify(
                                                  1,
                                                  attributeSetData["ETP"]
                                                    .label +
                                                    " Email Details Already Exist, Can't Insert Another."
                                                );
                                                return;
                                              }
                                            }

                                            /*Harcode For Contact Information check From Here*/
                                            if (section.SN === "ContactInformation"){
                                              let filteredContacts =
                                                ogFilledAttributeSet.filter(
                                                  (og) => {
                                                    return (
                                                      og.IP2?.code === 'CODEISPMRY2' &&
                                                      og.IsActive === true
                                                    );
                                                  }
                                                );
                                              if (filteredContacts.length > 0) {
                                                notify(
                                                  1,
                                                  "Primary Contact Already Exist, Can't Insert Another."
                                                );
                                                return;
                                              }
                                            }
                                            /*Harcode For Contact Information check Till Here*/

                                            if (
                                              section.SN ===
                                              "AddressInformation"
                                            ) {
                                            }
                                            setOgFilledAttributeSet((prev) => [
                                              ...prev,
                                              {
                                                ...attributeSetData,
                                                Id: uniqueId,
                                                IsActive: true,
                                              },
                                            ]);

                                            let gridData = {};

                                            eachAttribute.Attribute.forEach(
                                              async (subAttribute) => {
                                                if (
                                                  typeof attributeSetData[
                                                    subAttribute.AC
                                                  ] === "object"
                                                ) {
                                                  gridData = {
                                                    ...gridData,
                                                    [subAttribute.AC]:
                                                      attributeSetData[
                                                        subAttribute.AC
                                                      ]?.label || null, //for date and dropdown
                                                  };
                                                } else if (
                                                  typeof attributeSetData[
                                                    subAttribute.AC
                                                  ] === "boolean"
                                                ) {
                                                  gridData = {
                                                    ...gridData,
                                                    [subAttribute.AC]:
                                                      attributeSetData[
                                                        subAttribute.AC
                                                      ] === true
                                                        ? "true"
                                                        : "false",
                                                  };
                                                } else {
                                                  gridData = {
                                                    ...gridData,
                                                    [subAttribute.AC]:
                                                      attributeSetData[
                                                        subAttribute.AC
                                                      ],
                                                  };
                                                }
                                              }
                                            );

                                            //reset
                                            setAttributeSetData((prev) => ({
                                              ...prev,
                                              ...ogAttributeSet,
                                            }));

                                            setFormErrors({});

                                            setNewGridIdCreated(uniqueId); //send uniqueId for document

                                            setGridData((prev) => [
                                              ...prev,
                                              {
                                                Id: uniqueId,
                                                ...gridData,
                                                IsActive: true,
                                              },
                                            ]);

                                            setDisplayGrid((prev) => [
                                              ...prev,
                                              {
                                                Id: uniqueId,
                                                ...gridData,
                                                IsActive: true,
                                              },
                                            ]);
                                          }
                                          //}
                                        }
                                      } else {
                                        //update

                                        //validation for empty data before updating in grid
                                        const isError = validateData(
                                          eachAttribute.Attribute
                                        );
                                        if (!isError) {
                                          if (
                                            section.SN === "BankInformation"
                                          ) {
                                            if (!isError) {
                                              await validateBankAccAdhar();
                                            }
                                          }
                                          if (
                                            !isError &&
                                            validAccount.current === true &&
                                            section.SN === "BankInformation"
                                          ) {
                                            setOgFilledAttributeSet((prev) => {
                                              const newState = prev.map(
                                                (record) => {
                                                  if (
                                                    record.Id === currentGridId
                                                  ) {
                                                    return {
                                                      ...attributeSetData,
                                                    };
                                                  }
                                                  return record;
                                                }
                                              );
                                              return newState;
                                            });

                                            let gridData = {};

                                            eachAttribute.Attribute.forEach(
                                              async (subAttribute) => {
                                                if (
                                                  typeof attributeSetData[
                                                    subAttribute.AC
                                                  ] === "object"
                                                ) {
                                                  gridData = {
                                                    ...gridData,
                                                    [subAttribute.AC]:
                                                      attributeSetData[
                                                        subAttribute.AC
                                                      ]?.label || null, //for date and dropdown
                                                  };
                                                } else if (
                                                  typeof attributeSetData[
                                                    subAttribute.AC
                                                  ] === "boolean"
                                                ) {
                                                  gridData = {
                                                    ...gridData,
                                                    [subAttribute.AC]:
                                                      attributeSetData[
                                                        subAttribute.AC
                                                      ] === true
                                                        ? "true"
                                                        : "false",
                                                  };
                                                } else {
                                                  gridData = {
                                                    ...gridData,
                                                    [subAttribute.AC]:
                                                      attributeSetData[
                                                        subAttribute.AC
                                                      ],
                                                  };
                                                }
                                              }
                                            );

                                            //reset
                                            setAttributeSetData((prev) => ({
                                              ...prev,
                                              ...ogAttributeSet,
                                            }));

                                            setFormErrors({});

                                            setGridData((prev) => {
                                              const newState = prev.map(
                                                (record) => {
                                                  if (
                                                    record.Id === currentGridId
                                                  ) {
                                                    return {
                                                      Id: currentGridId,
                                                      ...gridData,
                                                    };
                                                  }
                                                  return record;
                                                }
                                              );
                                              return newState;
                                            });

                                            setDisplayGrid((prev) => {
                                              const newState = prev.map(
                                                (record) => {
                                                  if (
                                                    record.Id === currentGridId
                                                  ) {
                                                    return {
                                                      Id: currentGridId,
                                                      ...gridData,
                                                    };
                                                  }
                                                  return record;
                                                }
                                              );
                                              return newState;
                                            });

                                            setCurrentGridId(null);
                                          }

                                          if (
                                            !isError &&
                                            section.SN != "BankInformation"
                                          ) {
                                            //update
                                            if (
                                              section.SN === "Email Information"
                                            ) {
                                              let filteredEmail;
                                              if (
                                                attributeSetData["ETP"]
                                                  .label === "Business"
                                              ) {
                                                let filteredEmail =
                                                  ogFilledAttributeSet.filter(
                                                    (og) => {
                                                      return (
                                                        og.ETP?.label ===
                                                          attributeSetData[
                                                            "ETP"
                                                          ].label &&
                                                        og.IsActive === true
                                                      );
                                                    }
                                                  );
                                              } else {
                                                filteredEmail =
                                                  ogFilledAttributeSet.filter(
                                                    (og) => {
                                                      return (
                                                        og.ETP?.label ===
                                                          attributeSetData[
                                                            "ETP"
                                                          ].label &&
                                                        og.EA ===
                                                          attributeSetData[
                                                            "EA"
                                                          ] &&
                                                        og.IsActive === true
                                                      );
                                                    }
                                                  );
                                              }
                                              if (filteredEmail.length > 0) {
                                                notify(
                                                  1,
                                                  attributeSetData["ETP"]
                                                    .label +
                                                    " Email Type Already Exist, Can't Insert Another."
                                                );
                                                return;
                                              }
                                            }

                                            /*Harcode For Contact Information check From Here*/
                                            if (section.SN === "ContactInformation"){
                                              let filteredContacts =
                                                ogFilledAttributeSet.filter(
                                                  (og) => {
                                                    return (
                                                      og.IP2?.code === 'CODEISPMRY2' &&
                                                      og.IsActive === true
                                                    );
                                                  }
                                                );
                                              if (filteredContacts.length > 0) {
                                                notify(
                                                  1,
                                                  "Primary Contact Already Exist, Can't Insert Another."
                                                );
                                                return;
                                              }
                                            }
                                            /*Harcode For Contact Information check Till Here*/

                                            setOgFilledAttributeSet((prev) => {
                                              const newState = prev.map(
                                                (record) => {
                                                  if (
                                                    record.Id === currentGridId
                                                  ) {
                                                    return {
                                                      ...attributeSetData,
                                                    };
                                                  }
                                                  return record;
                                                }
                                              );
                                              return newState;
                                            });

                                            let gridData = {};

                                            eachAttribute.Attribute.forEach(
                                              async (subAttribute) => {
                                                console.log(
                                                  'Sub Attr "X": ',
                                                  subAttribute,
                                                  attributeSetData[
                                                    subAttribute.AC
                                                  ]
                                                );

                                                console.log(
                                                  typeof attributeSetData[
                                                    subAttribute.AC
                                                  ]
                                                );
                                                if (
                                                  typeof attributeSetData[
                                                    subAttribute.AC
                                                  ] === "object"
                                                ) {
                                                  gridData = {
                                                    ...gridData,
                                                    [subAttribute.AC]:
                                                      attributeSetData[
                                                        subAttribute.AC
                                                      ]?.label || null, //for date and dropdown
                                                  };
                                                } else if (
                                                  typeof attributeSetData[
                                                    subAttribute.AC
                                                  ] === "boolean"
                                                ) {
                                                  gridData = {
                                                    ...gridData,
                                                    [subAttribute.AC]:
                                                      attributeSetData[
                                                        subAttribute.AC
                                                      ] === true
                                                        ? "true"
                                                        : "false",
                                                  };
                                                } else if (
                                                  typeof attributeSetData[
                                                    subAttribute.AC
                                                  ] === "string" &&
                                                  moment(
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ],
                                                    "DD-MMM-YYYY",
                                                    true
                                                  ).isValid()
                                                ) {
                                                  const formattedDate = moment(
                                                    attributeSetData[
                                                      subAttribute.AC
                                                    ],
                                                    "DD-MMM-YYYY"
                                                  ).format("DD/MM/YYYY");
                                                  console.log(
                                                    "Formatted Date: ",
                                                    formattedDate
                                                  );
                                                  gridData = {
                                                    ...gridData,
                                                    [subAttribute.AC]:
                                                      formattedDate,
                                                  };
                                                } else {
                                                  gridData = {
                                                    ...gridData,
                                                    [subAttribute.AC]:
                                                      attributeSetData[
                                                        subAttribute.AC
                                                      ],
                                                  };
                                                }
                                              }
                                            );

                                            //reset
                                            setAttributeSetData((prev) => ({
                                              ...prev,
                                              ...ogAttributeSet,
                                            }));

                                            setFormErrors({});

                                            setGridData((prev) => {
                                              const newState = prev.map(
                                                (record) => {
                                                  if (
                                                    record.Id === currentGridId
                                                  ) {
                                                    return {
                                                      Id: currentGridId,
                                                      ...gridData,
                                                    };
                                                  }
                                                  return record;
                                                }
                                              );
                                              return newState;
                                            });

                                            setDisplayGrid((prev) => {
                                              const newState = prev.map(
                                                (record) => {
                                                  if (
                                                    record.Id === currentGridId
                                                  ) {
                                                    return {
                                                      Id: currentGridId,
                                                      ...gridData,
                                                    };
                                                  }
                                                  return record;
                                                }
                                              );
                                              return newState;
                                            });

                                            setCurrentGridId(null);
                                          }
                                        }
                                      }
                                    }}
                                  >
                                    {currentGridId === null
                                      ? "Save"
                                      : "Update Record"}
                                  </button>
                                )}
                              </div>
                            )}

                            <div className="col-lg-12 p-0 mb-3">
                              <DynamicGrid
                                options={gridOptions}
                                data={displayGrid}
                                columns={gridColumns}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </React.Fragment>
                  ))}
              </div>
            </AccordionDetails>
          </Accordion>
        ) : null}
        <Modal
          show={showConfirmationModal}
          onHide={() => closeConfirmationModal(false)}
          backdrop="static"
          keyboard={false}
          size="sm"
          centered
        >
          <div
            className="bg-secondary text-white"
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <div className="col-lg-12">Confirm!</div>
          </div>
          <Modal.Body>
            <h5>{modalHeader}</h5>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-success"
              style={{ marginLeft: 5 }}
              onClick={() => closeConfirmationModal(true)}
            >
              Yes
            </button>
            <button
              className="btn btn-cancel"
              style={{ marginLeft: 5 }}
              onClick={() => closeConfirmationModal(false)}
            >
              No
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

const NewHire = () => {
  const [formData, setFormData] = useState<any>({});
  const [tabs, setTabs] = useState([]);
  const [globalValues, setGlobalValues] = useState([]);
  const [activetab, setActivetab] = useState<string>(null);
  const [activetabIndex, setActivetabIndex] = useState<number>(0);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const { state } = useLocation();
  console.log("state NewHire", state);

  const [TId, setTId] = useState((state !== null && state.TId) || 0);
  const [COU, setCOU] = useState((state !== null && state.COU) || "");
  const [headerValues, setHeaderValues] = useState<any>(null);
  const [sectionValuesArray, setSectionValuesArray] = useState<any>([]);
  let { userDetails, currentRoleId }: any = useUserContext();

  const [profileImgToUpload, setProfileImgToUpload] = useState<any>(null);

  const [showProfileModal, setShowProfileModal] = useState(false);

  const [profileImg, setProfileImg] = useState(null);
  const [Gridcount, setGridcount] = useState(0);
  const [Gridpage, setGridpage] = useState(0);
  const [GridsortDirection, setGridSortDirection] = useState("");
  const [GridsortColumn, setGridsortColumn] = useState("");
  const [DocGridData, setDocGridData] = useState<any>([]);
  const [GridPageSize, setGridPageSize] = useState(3);
  const [GridsearchText, setGridSearchText] = useState("");

  const [event, setEvent] = useState(null);
  const [eventReason, setEventReason] = useState(null);

  const [formErrors, setFormErrors] = useState({});

  const DocGridCols = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: "rowNum",
      label: "Sr No.",
      options: {
        display: true,
        sort: false,
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: false,
      },
    },
    {
      name: "context",
      label: "Context",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "dcvalue",
      label: "Context Value",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "createdDateUtc",
      label: "Uploaded On",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <span>{moment(value).format("DD-MM-YYYY")}</span>
            </>
          );
        },
      },
    },
    {
      name: "dvtill",
      label: "Valid Till",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              {value != null && (
                <span>{moment(value).format("DD-MM-YYYY")}</span>
              )}
            </>
          );
        },
      },
    },
    {
      name: "dstatus",
      label: "Document Status",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "dpsince",
      label: "Pending Since",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <span>-</span>
            </>
          );
        },
      },
    },
    {
      name: "dpwith",
      label: "pending With",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <span>-</span>
            </>
          );
        },
      },
    },
    {
      name: "",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <a onClick={() => {}} style={{ marginLeft: "30px" }}>
                <i className="fa fa-eye" aria-hidden="true"></i>
              </a>
              <a onClick={() => {}} style={{ marginLeft: "30px" }}>
                <i className="fa fa-pencil" aria-hidden="true"></i>
              </a>
            </>
          );
        },
      },
    },
  ];

  const gridOptions2 = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: Gridcount,
    rowsPerPage: GridPageSize,
    page: Gridpage,
    serverSide: true,
    onChangePage: async (page) => {
      setGridpage(page);
    },
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "vertical",
    onColumnSortChange: async (sortColumn, sortDirection) => {
      setGridpage(0);
      if (sortDirection === "asc") {
        await setGridsortColumn(sortColumn);
        await setGridSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setGridsortColumn(sortColumn);
        await setGridSortDirection(sortDirection);
      }
    },
  };

  const fileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const getDocumentsGridData = async () => {
    showLoader();
    setDocGridData([]); //imp
    let bodyObj = {
      PageNumber: Gridpage,
      PageSize: GridPageSize,
      SearchText: GridsearchText,
      //UserId: userDetails?.Id,
      //RoleId: userDetails?.UserRoles[0]?.RoleId,
      SortColumn: GridsortColumn,
      SortOrder: GridsortDirection,
      EID: TId,
      //COU: COU,
    };

    const response = await APICall(getDocumentGridData, "POST", bodyObj);

    if (response.data !== null && response.data.length > 0) {
      setDocGridData(response.data);
      setGridcount(response.data[0].count);
    } else {
      setDocGridData([]);
      setGridcount(0);
    }

    hideLoader();
  };

  useEffect(() => {
    if (Gridcount != 0) getDocumentsGridData();
  }, [Gridpage, GridsortDirection, GridsortColumn, GridsearchText]);

  const handleImageSelect = async (e) => {
    const file = e.target.files[0];
    let fileBase64: { Name: string; Data: string } = null;
    const base64 = (await fileToBase64(file)) as string;
    const base64WithoutPrefix = base64.split(",")[1];
    fileBase64 = { Name: file.name, Data: base64WithoutPrefix };

    const validExtensions = ["png", "jpg", "jpeg"];
    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();
      const fileSize = file.size;
      const maxFileSizeBytes = 5 * 1024 * 1024;

      // Check if the file extension is allowed
      if (
        validExtensions.includes(fileExtension) &&
        fileSize <= maxFileSizeBytes
      ) {
        // file to upload
        setProfileImgToUpload(fileBase64);
        //setProfileImg(`data:image;base64,${fileBase64.Data}`);
      } else {
        notify(1, "Only file with png,jpg,jpeg allowed and size under 5MB");
        e.target.value = "";
        setProfileImgToUpload(null);
      }
    }
  };
  //Api to delete Profile Image
  const deleteProfileImage = async () => {
    if (profileImg != null) {
      showLoader();
      const doc = await APICall(deleteDocument, "POST", {
        TId,
        AC: "ProfilePic",
        DocId: "0",
      });
      if (doc.message === "Success") {
        setProfileImg(null);
        setShowProfileModal(false);
        setProfileImgToUpload(null);
        hideLoader();
      } else {
        notify(1, "File not Deleted, Something Went Wrong.");
        hideLoader();
      }
    }
  };

  // API call to upload the image
  const handleImageUpload = async () => {
    if (profileImgToUpload) {
      showLoader();
      let reqObj = {
        file: {
          AC: "ProfilePic",
          Name: profileImgToUpload.Name,
          Data: profileImgToUpload.Data,
        },
        TId,
        SectionName: "Basic",
        ModuleName: "EmployeeCentral",
        TabName: "Personal",
      };

      try {
        const response = await APICall(UploadProfile, "POST", reqObj);
        if (response && response.data) {
          setProfileImg(`data:image;base64,${response.data.base64}`);
          setShowProfileModal(false);
          hideLoader();
        } else {
          console.error("Image upload failed");
          hideLoader();
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        hideLoader();
      }
    } else {
      notify(1, "Please Select Image to Upload.");
    }
  };

  //Tab api here

  useEffect(() => {
    (async () => {
      await showLoader();
      let tabs = await APICall(getTabsData, "POST", {
        MN: "EmployeeCentral",
        IN: "NewHire_Form",
      });

      debugger;

      if (tabs.data !== null && tabs.data.length > 0) {
        tabs.data = tabs.data?.filter(
          (item) => item.TDN === "Personal" || item.TDN === "Employment"
        );

        setTabs(tabs.data);
        if (state != null) {
          if (state.ActiveTab != null && state.ActiveTab != undefined) {
            setActivetab(state.ActiveTab);
          } else {
            setActivetab(tabs.data[0].TN);
          }
        } else {
          setActivetab(tabs.data[0].TN);
        }
      } else {
        console.log("No tabs found!", tabs);
        setTabs([]);
      }
      await hideLoader();
    })();
  }, []);

  //getImage API
  useEffect(() => {
    if (
      activetab !== null &&
      (activetab === "Personal" || activetab === "Documnets")
    ) {
      (async () => {
        await showLoader();
        const getPhoto = await APICall(getProfilePicImg, "POST", {
          TId,
          AC: "ProfilePic",
        });

        if (getPhoto && getPhoto.data !== null) {
          setProfileImg(`data:image;base64,${getPhoto.data}`);
        }
        await hideLoader();
      })();
    }
  }, [activetab]);

  //section api here
  useEffect(() => {
    //get section
    if (activetab !== null) {
      setSectionValuesArray([]); //imp
      (async () => {
        await showLoader();

        const section = await APICall(getSectionDetailsV2, "POST", {
          MN: "EmployeeCentral",
          IN: "NewHire_Form",
          TN: activetab,
          UID: userDetails.Id,
          TID: TId,
          RoleId: currentRoleId?.value,
          //COU:TId==0?COU:userDetails.COU,
          COU: COU,
        });

        if (
          section.data !== null &&
          section.data.t !== undefined &&
          section.data.t.tn === activetab
        ) {
          setFormData(section.data.t);
        } else {
          setFormData({});
        }

        await hideLoader();
      })();
    }
  }, [activetab]);

  useEffect(() => {
    if (
      activetab &&
      state !== null &&
      state.TId > 0
      // &&
      // activetab != "Documents"
    ) {
      setSectionValuesArray([]); //imp
      (async () => {
        showLoader();
        let bodyObj = {
          mn: "EmployeeCentral",
          in: "NewHire_Form",
          tn: activetab,
          SN: "Basic",
          TID: TId,
          ISH: "true",
          UserId: userDetails?.Id,
          RoleId: currentRoleId?.value,
          COU: COU,
        };

        const response = await APICall(
          getAttributeValueDetailsV2,
          "POST",
          bodyObj
        );

        if (response.data !== null && response.data.headerValues !== null) {
          setHeaderValues(response.data.headerValues);
        } else {
          setHeaderValues(null);
        }
        if (activetab === "Documents") {
          (async () => {
            await getDocumentsGridData();
          })();
        } else {
          if (response.data.sections && response.data.sections.length > 0) {
            setSectionValuesArray(response.data.sections);
          } else {
            setSectionValuesArray([]);
          }
        }

        hideLoader();
      })();
    }
    // if (activetab === "Documents") {
    //   (async () => {
    //     await getDocumentsGridData();
    //   })();
    // }
    if (state !== null && state.TId > 0) {
      (async () => {
        await showLoader();
        const globalValues = await APICall(getGlobalValues, "POST", {
          TID: state.TId,
        });

        if (globalValues.data !== null && globalValues.data.length > 0) {
          setGlobalValues(globalValues.data);
        }
        await hideLoader();
      })();
    }
  }, [activetab]);

  const defaultTheme = createTheme({});
  const { breakpoints } = defaultTheme;

  const tabsTheme = {
    ...defaultTheme,
    components: {
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: "0px !important",
            marginLeft: "-42px",
            [breakpoints.down("md")]: {
              minHeight: "0px !important",
              marginLeft: "0px",
            },
          },
        },
      },
    },
  };

  const EventArray = [{ value: 1, label: "Hire" }];

  const EventReasonArray = [
    { value: 1, label: "New Position (NEW_NPS)" },
    { value: 2, label: "Business Expansion (HIR_BE)" },
    { value: 3, label: "Replacement (HIR_REP)" },
    { value: 4, label: "Transfer / Acquisition - (TRANS_HIRE)" },
    { value: 5, label: "Rehire (REHIR)" },
  ];

  const handleSubmitButton = async () => {
    debugger;

    let errorObj = {};
    let isError = false;

    setFormErrors({});

    if (!event || Object.keys(event).length <= 0) {
      errorObj["error_Event"] = "Required";
    } else {
      delete errorObj["error_Event"];
    }

    if (!eventReason || Object.keys(eventReason).length <= 0) {
      errorObj["error_EventReasion"] = "Required";
    } else {
      delete errorObj["error_EventReasion"];
    }

    const isEmpty = Object.values(errorObj).every(
      (s) => s === null || s === "" || s === undefined
    );

    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    if (!isError) {
      debugger;
      const response = await APICall(getEmployeeDetailsById, "POST", {
        TId: TId,
      });

      debugger;

      if (response?.status == 1) {
        notify(response?.status, response?.message);
      } else {
      }
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div
          className="row prof_bg mb-3 border-bottom"
          style={{ backgroundColor: headerValues ? "#00abe6" : "white" }}
        >
          <div className="container-fluid">
            {headerValues && (
              <div className="row botpad">
                <div className="col-lg-2 col-md-2 col-sm-12 pers_prof">
                  <div className="prof_img">
                    {/* {headerValues && <img src={profileImg} />} */}
                    <img
                      src={
                        profileImg != null ? profileImg : defaultProfileImgPic
                      }
                      alt="Profile"
                    />
                  </div>

                  <a
                    className="edit-profile"
                    onClick={() => setShowProfileModal(true)}
                  >
                    <i className="fas fa-edit"></i>
                  </a>
                </div>

                <div className="col-lg-10 col-md-10 col-sm-12 profData">
                  <div className="name">
                    <h4>
                      {headerValues &&
                        `${headerValues?.FN} ${headerValues?.LN}`}
                    </h4>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12 profDetail">
                      <ul>
                        <li>
                          <Tooltip title="Employee Code">
                            <span className="header">
                              <i className="fa-regular fa-address-card"></i>{" "}
                              {headerValues && <>{headerValues?.Id}</>}
                            </span>
                          </Tooltip>
                        </li>
                        <li>
                          <Tooltip title="Designation">
                            <span className="header">
                              <i className="fa-solid fa-user"></i>{" "}
                              {headerValues && headerValues?.SD !== null && (
                                <>{headerValues?.SD}</>
                              )}
                            </span>
                          </Tooltip>
                        </li>
                        <li>
                          <Tooltip title="Legal Entity">
                            <span className="header">
                              <i className="fa-solid fa-briefcase"></i>{" "}
                              {headerValues && headerValues?.LEN !== null && (
                                <>{headerValues?.LEN}</>
                              )}
                            </span>
                          </Tooltip>
                        </li>
                        <li>
                          <Tooltip title="Physical Location">
                            <span className="header">
                              <i className="fa-solid fa-location-dot"></i>{" "}
                              {headerValues && headerValues?.PHLOC !== null && (
                                <>{headerValues?.PHLOC}</>
                              )}
                            </span>
                          </Tooltip>
                        </li>
                        <li>
                          <Tooltip title="Function">
                            <span className="header">
                              <i className="fa-solid fa-briefcase"></i>{" "}
                              {headerValues && headerValues?.FUNC !== null && (
                                <>{headerValues?.FUNC}</>
                              )}
                            </span>
                          </Tooltip>
                        </li>
                        <li>
                          <Tooltip title="Job Title">
                            <span className="header">
                              <i className="fa-solid fa-briefcase"></i>{" "}
                              {headerValues && headerValues?.JT !== null && (
                                <>{headerValues?.JT}</>
                              )}
                            </span>
                          </Tooltip>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 profDetail">
                      <ul>
                        <li>
                          <Tooltip title="Phone Number">
                            <span className="header">
                              <i className="fa-solid fa-phone"></i>{" "}
                              {headerValues && headerValues.PN != "" && (
                                <>{headerValues.PN}</>
                              )}
                            </span>
                          </Tooltip>
                        </li>
                        <li>
                          <Tooltip title="Business Email">
                            <span className="header">
                              <i className="fa-solid fa-envelope"></i>{" "}
                              {headerValues && headerValues.EA != "" && (
                                <>{headerValues.EA}</>
                              )}
                            </span>
                          </Tooltip>
                        </li>
                        <li>
                          <Tooltip title="Manager">
                            <span className="header">
                              <i className="fas fa-network-wired"></i>{" "}
                              {headerValues && headerValues?.MG !== "" && (
                                <>{headerValues?.MG}</>
                              )}
                            </span>
                          </Tooltip>
                        </li>
                        <li>
                          <Tooltip title="Department">
                            <span className="header">
                              <i className="fa-solid fa-briefcase"></i>{" "}
                              {headerValues && headerValues?.DPT !== "" && (
                                <>{headerValues?.DPT}</>
                              )}
                            </span>
                          </Tooltip>
                        </li>
                        <li>
                          <Tooltip title="Status">
                            <span className="header">
                              <i className="fas fa-tag"></i>
                              {headerValues && headerValues?.IsActive ? (
                                <> Active</>
                              ) : (
                                headerValues &&
                                headerValues?.IsActive === false && (
                                  <> InActive</>
                                )
                              )}
                            </span>
                          </Tooltip>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              {headerValues && (
                <div className="col-lg-2 col-md-12 col-sm-12 "></div>
              )}

              <div
                className={
                  headerValues
                    ? "col-lg-10 col-md-12 col-sm-12 "
                    : "col-lg-12 col-md-12 col-sm-12"
                }
              >
                <ThemeProvider theme={tabsTheme}>
                  <Tabs
                    value={activetabIndex}
                    className="profile-tabs"
                    onChange={() => {}}
                    variant="scrollable"
                    TabScrollButtonProps={{
                      style: {
                        color: "black",
                      },
                    }}
                    scrollButtons
                    TabIndicatorProps={{
                      style: { display: "none" },
                    }}
                    allowScrollButtonsMobile
                    aria-label="scrollable tabs"
                  >
                    <div className="tabBtn">
                      <ul>
                        {tabs.length > 0 &&
                          tabs.map((eachTab, index) => (
                            <li key={`${index}-${eachTab.TN}`}>
                              <button
                                onClick={() => {
                                  setSectionValuesArray([]); //imp
                                  setActivetab(eachTab.TN);
                                }}
                                className={
                                  eachTab.TN === activetab
                                    ? "bttn active"
                                    : "bttn border border-primary border-bottom-0"
                                }
                              >
                                {eachTab.TDN}
                              </button>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </Tabs>
                </ThemeProvider>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={showProfileModal}
          onHide={() => setShowProfileModal(false)}
          backdrop="static"
          keyboard={false}
          size="sm"
          centered
        >
          <div
            className="bg-secondary text-white"
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <div className="col-lg-12">Upload Profile Picture!</div>
          </div>
          <Modal.Body>
            <>
              <input
                type="file"
                multiple={false}
                accept={".png,.jpg,.jpeg"}
                onChange={handleImageSelect}
              />
              <div>
                <b>Acceptable image formats:</b> .png, .jpg and .jpeg{" "}
              </div>
              <div>
                {" "}
                <b>Maximum File Size:</b> 5MB{" "}
              </div>
              <div>
                <b>Resolution:</b> 3245 X 3245{" "}
              </div>
            </>
          </Modal.Body>
          <Modal.Footer>
            {profileImg != null && (
              <button
                className="btn btn-primary"
                style={{ marginLeft: 5 }}
                onClick={() => deleteProfileImage()}
              >
                Remove
              </button>
            )}
            <button
              className="btn btn-primary"
              style={{ marginLeft: 5 }}
              onClick={() => handleImageUpload()}
            >
              Upload
            </button>
            <button
              className="btn btn-cancel"
              style={{ marginLeft: 5 }}
              onClick={() => {
                setShowProfileModal(false);
                setProfileImgToUpload(null);
              }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
            <div className="form-group">
              <label className="col-form-label">Event</label>
              <sup>*</sup>
              <SelectForm
                isClearable
                isSearchable
                options={EventArray}
                placeholder="Select Event"
                isDisabled={false}
                value={event}
                onChange={(event) => {
                  setEvent(event);
                }}
                isMulti={false}
                noIndicator={false}
                noSeparator={false}
              />
              <span style={{ color: "red" }}>{formErrors["error_Event"]}</span>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 mobile-view top-m">
            <div className="form-group">
              <label className="col-form-label">Event Reasion</label>
              <sup>*</sup>
              <SelectForm
                isClearable
                isSearchable
                options={EventReasonArray}
                placeholder="Select Event Reasion"
                isDisabled={false}
                value={eventReason}
                onChange={(event) => {
                  setEventReason(event);
                }}
                isMulti={false}
                noIndicator={false}
                noSeparator={false}
              />
              <span style={{ color: "red" }}>
                {formErrors["error_EventReasion"]}
              </span>
            </div>
          </div>
        </div>
        <div className="SectionSubmit mb-4 clearfix">
          <button
            onClick={() => handleSubmitButton()}
            disabled={false}
            className="btn btn-filter-submit float-right ml-2"
          >
            <i className="fa-solid fa-check"></i> Submit
          </button>
        </div>

        {/* section row */}
        {activetab != "Documents" ? (
          <div className="row accordion_grid">
            {formData.S !== undefined &&
              formData.S.length > 0 &&
              ((((state !== null && state.TId === 0) || state === null) &&
                sectionValuesArray.length === 0) ||
                (state !== null &&
                  state.TId !== 0 &&
                  sectionValuesArray.length > 0)) &&
              formData.S.map((eachSection, index) => (
                <React.Fragment key={`${index}-${eachSection.SN}`}>
                  <>
                    {" "}
                    {index >= 0 && (
                      <SectionComponent
                        formData={formData}
                        setFormData={setFormData}
                        section={eachSection}
                        sectionIndex={index}
                        TId={TId}
                        setTId={setTId}
                        sectionValuesArray={sectionValuesArray}
                        SetSectionValuesArray={setSectionValuesArray}
                        Globalv={globalValues}
                        COU={COU}
                      />
                    )}
                  </>
                </React.Fragment>
              ))}
          </div>
        ) : (
          <>
            <div className="grid-wrapper grid_mob">
              <div className="row mx-auto filtBox">
                <div className="col-lg-3 col-md-3 col-sm-12">
                  <div className="form-group filtInp">
                    <InputForm
                      value={GridsearchText}
                      placeholder="Search"
                      isDisabled={false}
                      textArea={false}
                      onChange={(e) => {
                        setGridpage(0);
                        setGridSearchText(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3 pt-3">
              <DynamicGrid
                data={DocGridData}
                columns={DocGridCols}
                options={gridOptions2}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default NewHire;
