import React, { useContext, useEffect, useState } from "react";
import { Accordion, AccordionSummary } from "@mui/material";
import { ExpandMore, TempleHinduTwoTone } from "@mui/icons-material";
import SelectForm from "../../../Components/SelectForm/SelectForm";
import InputForm from "../../../Components/InputForm/InputForm";
import uuid from "react-uuid";
import { APICall } from "../../../Helpers/APICalls";
import { LoaderContext } from "../../../Helpers/Context/Context";
import {
  getTAInputData,
  GetParentPositionForMrfWorkflow,
  insertUpdateMRFApproverMapping,
  getMRFApproverByMRFId,
  getTALeadApproversForWorkflow,
  MRFDraftRequest,
  CancelMRFWorkflow,
  getApproverDetailsForProccessByMRFId,
  GetHRManagers,
} from "../../../Helpers/APIEndPoints/EndPoints";
import notify from "../../../Helpers/ToastNotification";
import { useNavigate } from "react-router-dom";
import useUserContext from "../../../Helpers/ZustandStore/UserContextStore";
// import { style } from "../MRFHelper";
// import { MRFContext } from "../MRF";
import { Modal } from "react-bootstrap";
import moment from "moment";

const RWorkflowSection = ({
  TId,
  setAccordion,
  accordion,
  positionIdDetails,
  hideSectionAndAttribute,
  setDisableAttribute,
  disableAttribute,
  isScreeningSubmitted,
}) => {
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  const FullName =
    proxyUserDetails === null
      ? `${userDetails.FirstName} ${userDetails.LastName}`
      : `${proxyUserDetails.FirstName} ${proxyUserDetails.LastName}`;
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const [ogApprovers, setOgApprovers] = useState([]);
  const [isSendback, setIsSendback] = useState(false);
  const [disableProccedBtn, setDisableProceedBtn] = useState(false);
  const [accordionRemarks, setAccordionRemarks] = useState(true);
  const [remarks, setRemarks] = useState("");
  const [previousConversation, setPreviousConversation] = useState("");

  // const { sendback, setSendback, setWorkflowApprovers, workflowApprovers } =
  //   useContext(MRFContext);
  const [adHocModal, setAdHocModal] = useState(false);
  const [currentAdHocApprover, setCurrentAdHocApprover] = useState(null);
  const [adHocChange, setAdHocChange] = useState(false);
  const [initiatorName, setInitiatorName] = useState("");

  let navigate = useNavigate();

  const decisionArray = [
    { value: 1, label: "Approved" },
    { value: 2, label: "Rejected" },
    { value: 3, label: "Sendback" },
  ];

  enum WorkflowTaskStatus {
    Pending = 1,
    Completed = 2,
  }

  useEffect(() => {
    if (TId == 0) {
      setInitiatorName(FullName);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await GetAllApprovers();
      await handleFetchMRFApproverByMRFId();
      // if (sendback) {
      //   await FetchSendBackRemarks();
      // }
    };
    setIsSendback(false);
    fetchData();
  }, [positionIdDetails]);

  // useEffect(() => {
  //   setWorkflowApprovers(ogApprovers);
  // }, [ogApprovers]);

  const GetAllApprovers = async () => {
    try {
      showLoader();
      const [res1] = await Promise.all([
        APICall(GetParentPositionForMrfWorkflow, "POST", {
          PositionId: positionIdDetails?.value,
        }),
      ]);

      const arr = [];

      if (res1.status === 0 && res1.data) {
        arr.push(
          {
            ...res1.data,
            sequenceNo: 1,
            disabled: false,
            id: uuid(),
            IsAdhocApprover: false,
          },
          {
            sequenceNo: 2,
            disabled: false,
            id: uuid(),
            IsAdhocApprover: false,
          }
        );
      } else {
        arr.push(
          {
            sequenceNo: 1,
            disabled: false,
            id: uuid(),
            IsAdhocApprover: false,
          },
          {
            sequenceNo: 2,
            disabled: false,
            id: uuid(),
            IsAdhocApprover: false,
          }
        );
      }

      setOgApprovers(arr);
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  enum DecisionEnum {
    Approved = 1,
    Rejected = 2,
    Sendback = 3,
    Initiated = 4,
    ReInitiated = 5,
  }
  const mapDataItem = (item) => {
    let IsDisabled = false;

    if (item.ApproverId != userDetails.Id) {
      IsDisabled = true;
    } else if (item.WorkflowTaskStatus == WorkflowTaskStatus.Completed) {
      IsDisabled = true;
    } else {
      IsDisabled = false;
    }

    // const Recruiter =
    //   item.RecruiterId && item.RecruiterName?.trim().length > 0
    //     ? {
    //         value: item.RecruiterId,
    //         label: item.RecruiterName,
    //       }
    //     : null;

    const DecisionObj = item.Decision
      ? decisionArray.find((dec) => dec.value === item.Decision)
      : null;

    const PreviousConversionJson = item.PreviousConversionJson;

    let str = "";
    if (
      item.PreviousConversionJson &&
      item.PreviousConversionJson?.length > 0
    ) {
      const previousConversionArr = JSON.parse(item.PreviousConversionJson);

      if (previousConversionArr && previousConversionArr?.length > 0) {
        previousConversionArr.map((i) => {
          const Date = i.CreatedDateUtc
            ? moment.utc(i.CreatedDateUtc).local().format("DD-MMM-YYYY")
            : "";
          const ApproverName = i.UserName || "";
          const RoleName = i.RoleName || "";
          const Remarks = i.Remarks || "";

          if (Remarks?.trim()?.length != 0) {
            str += `${Date} ${ApproverName} ${RoleName}\n`;
            str += `\t\t${Remarks}\n\n`;
          }
        });
      }
    }

    let RecruiterSelectedOption = [];
    if (
      item.RecruiterSelectedOption &&
      item.RecruiterSelectedOption?.length > 0
    ) {
      const recruiterSelectedOption = JSON.parse(item.RecruiterSelectedOption);
      RecruiterSelectedOption = recruiterSelectedOption;
    }

    return {
      Id: item.Id,
      IntiatorId: item.IntiatorId,
      IntiatorName: item.IntiatorName,
      ApproverId: item.ApproverId,
      ApproverName: item.ApproverName,
      RoleId: item.RoleId,
      RoleName: item.RoleName,
      SequenceNo: item.SequenceNo,
      DecisionId: item.Decision,
      RecruiterId: item.RecruiterId || null,
      RecruiterName: item.RecruiterName || "",
      Remarks: item.Remarks || "",
      Accordian: true,
      Decision: DecisionObj,
      Recruiter: RecruiterSelectedOption,
      Date: item.Date
        ? moment
            .utc(item.Date, "YYYY-MM-DD HH:mm:ss.SSS")
            .local()
            .format("DD-MMM-YYYY")
        : moment.utc().local().format("DD-MMM-YYYY"),
      IsDisabled: IsDisabled,
      WorkflowId: item.WorkflowId,
      WorkflowTaskId: item.WorkflowTaskId,
      RequestId: item.RequestId,
      LastSequence: item.LastSequence,
      MRFApproverDetailsId: item.MRFApproverDetailsId,
      WorkflowTaskStatus: item.WorkflowTaskStatus,
      PreviousRemarks: str,
      PostingFromDate: item.PostingFromDate,
      PostingTillDate: item.PostingTillDate,
      RecruiterSelectedOption,
    };
  };

  const FetchSendBackRemarks = async () => {
    try {
      showLoader();
      const response = await APICall(
        getApproverDetailsForProccessByMRFId,
        "POST",
        {
          MRFId: TId,
        }
      );

      if (response?.status == 0 && response?.data) {
        const responseData = response?.data;
        const mappedData = response.data.map((item) => mapDataItem(item));
        //check if sendback then show prev conversation

        if (mappedData.length > 0) {
          let prevCon = [...mappedData];
          prevCon = prevCon
            .map((item) => {
              if (
                item.IntiatorId == userDetails.Id &&
                (item.DecisionId == DecisionEnum.Sendback ||
                  item.PreviousRemarks !== "")
              ) {
                return item;
              } else {
                return null;
              }
            })
            .filter((s) => s !== null);

          const Ids = prevCon.map((data) => data.Id);

          const maxId = Math.max(...Ids);
          let previousConversion =
            prevCon?.find((i) => i.Id == maxId)?.PreviousRemarks || "";

          setPreviousConversation(previousConversion);
        }
      }

      hideLoader();
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const handleFetchHRManagers = async (e, cb) => {
    try {
      if (e?.length > 3) {
        let filterString = "";
        if (ogApprovers && ogApprovers.length > 0) {
          let selectedUsersId = ogApprovers
            .filter((i) => i.value != null && i.value != e?.value)
            .map((i) => i.value);

          filterString = selectedUsersId.join(",");
        }
        showLoader();
        const response = await APICall(GetHRManagers, "POST", {
          textInput: e,
          FilterString: filterString,
        });

        if (response?.data?.length > 0) {
          cb(response?.data);
        } else {
          throw new Error("API request failed.");
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const handleInputChangeForApp = async (e, cb, IsAdhocApprover) => {
    try {
      if (e?.length > 3) {
        let filterString = "";
        if (ogApprovers && ogApprovers.length > 0) {
          let selectedUsersId = ogApprovers
            .filter((i) => i.value != null && i.value != e?.value)
            .map((i) => i.value);

          filterString = selectedUsersId.join(",");
        }
        showLoader();
        const response = await APICall(getTAInputData, "POST", {
          textInput: e,
          FilterString: filterString,
          IsAdhocApprover: IsAdhocApprover,
        });

        if (response?.data?.length > 0) {
          cb(response?.data);
        } else {
          throw new Error("API request failed.");
        }
      }
    } catch (error) {
      console.error("Error fetching API data:", error);
    } finally {
      await hideLoader();
    }
  };

  const handleAddApprover = () => {
    let newId = uuid();

    let newObject = {
      value: 0,
      label: "",
      disabled: false,
      sequenceNo: 2,
      id: newId,
      IsAdhocApprover: true,
    };

    let tempOgApprovers = ogApprovers;
    tempOgApprovers.push(newObject);
    tempOgApprovers = tempOgApprovers.map((i) => {
      if (i.sequenceNo == 2 && i.id != newId) {
        i.sequenceNo = tempOgApprovers.length;
      }
      return i;
    });

    tempOgApprovers = tempOgApprovers.sort(
      (a, b) => Number(a?.sequenceNo) - Number(b?.sequenceNo)
    );
    setOgApprovers(tempOgApprovers);
  };

  const handleFetchMRFApproverByMRFId = async (flag = false) => {
    try {
      await showLoader();
      const response = await APICall(getMRFApproverByMRFId, "POST", {
        MRFId: TId,
      });

      if (response?.status == 0 && response?.data?.length > 0) {
        debugger;
        const initiatorName = response?.data[0]?.IntiatorName;
        if (TId > 0) {
          setInitiatorName(initiatorName || "");
        }
        let arr = response?.data?.map((item, index) => {
          return {
            label: item.ApproverName,
            value: item.ApproverId,
            roleId: item.ApproverRoleId,
            Id: item.Id,
            disabled: true,
            sequenceNo: item.SequenceNo,
            id: item.Id,
            IsAdhocApprover:
              index === 0 || response?.data?.length - 1 === index
                ? false
                : true,
          };
        });

        let isDraft = response?.data?.some((item) => item.isDraft == true);

        setOgApprovers(arr);

        if (false) {
          if (flag) {
            setDisableSubmitBtn(true);
          } else {
            setDisableSubmitBtn(false);
          }
        } else {
          if (isDraft) {
            setDisableSubmitBtn(false);
            setDisableAttribute(false);
          } else {
            setDisableSubmitBtn(true);
            setDisableAttribute(true);
          }
        }
      } else {
        setInitiatorName(FullName);
      }
    } catch (error) {
      console.info(error);
      setInitiatorName(FullName);
    } finally {
      await hideLoader();
    }
  };

  const handleChangeApprovers = (selectedOption, approver) => {
    if (approver.IsAdhocApprover) {
      setAdHocChange(true);
    }

    setOgApprovers((prevItems) =>
      prevItems.map((item) =>
        item?.id === approver?.id && selectedOption
          ? { ...item, ...selectedOption, id: item.id }
          : item?.id === approver?.id && selectedOption === null
          ? { ...item, ...selectedOption, id: item.id, label: "", value: 0 }
          : item
      )
    );
  };

  useEffect(() => {
    if (ogApprovers.length > 0 && adHocChange) {
      const isAdhocApprover = ogApprovers.find(
        (approver) =>
          approver.IsAdhocApprover &&
          approver.value !== 0 &&
          approver.label !== ""
      );

      if (isAdhocApprover !== undefined) {
        setCurrentAdHocApprover(isAdhocApprover);
        setAdHocModal(true);
        setAdHocChange(false);
      }
    }
  }, [ogApprovers, adHocChange]);

  const removeAdHocApprover = () => {
    if (ogApprovers.length > 0) {
      const isAdhocApprover = ogApprovers.find(
        (approver) =>
          approver.IsAdhocApprover &&
          approver.value !== 0 &&
          approver.label !== ""
      );

      if (isAdhocApprover !== undefined) {
        setOgApprovers((prevItems) =>
          prevItems.filter((approver) => approver.IsAdhocApprover !== true)
        );
      }
    }
  };

  const handleMRFDraft = async () => {
    try {
      if (TId == 0) {
        notify(1, "Save Requisition Details Data First");
        return;
      }

      let error = false;

      ogApprovers.map((i) => {
        if (!i.value) {
          error = true;
          return;
        }
      });

      if (error || ogApprovers.length == 0) {
        notify(1, "Please provide approver.");
        return;
      }

      let arr = ogApprovers.map((element, index) => {
        let obj1 = {
          MRFId: TId,
          InitiatorId: userDetails.Id,
          ApproverId: element.value,
          RoleId: element?.roleId,
          SequenceNo: element.sequenceNo,
          InitiatorRole: currentRoleId?.value,
          IsSendback: isSendback,
          Remarks: remarks,
        };
        return obj1;
      });

      setDisableSubmitBtn(true);
      const response = await APICall(MRFDraftRequest, "POST", {
        UserId: userDetails.Id,
        MRFId: TId,
        RoleId: currentRoleId?.value,
        Approvers: arr,
      });

      if (response?.status == 0) {
        notify(0, "Record Saved Successfully.");
        navigate("/home");
      } else {
        notify(1, "Something went wrong.");
      }
    } catch (error) {}
  };

  const handleCancelMRFWorkflow = async () => {
    try {
      if (TId == 0) {
        navigate("/home");
        return;
      }
      setDisableSubmitBtn(true);
      const response = await APICall(CancelMRFWorkflow, "POST", {
        UserId: userDetails.Id,
        MRFId: TId,
        RoleId: currentRoleId?.value,
      });

      if (response?.status == 0) {
        notify(0, "Record Saved Successfully.");
        navigate("/home");
      } else {
        notify(0, "Something went wrong.");
      }
    } catch (error) {}
  };

  const handleProceedSubmit = async () => {
    try {
      if (TId === 0) {
        notify(1, "Save Requisition Details Data First");
      } else {
        let error = false;

        ogApprovers.map((i) => {
          if (!i.value) {
            error = true;
            return;
          }
        });

        if (error || ogApprovers.length == 0) {
          notify(1, "Please provide approver.");
          return;
        }

        showLoader();
        let arr = ogApprovers.map((element, index) => {
          let obj1 = {
            MRFId: TId,
            InitiatorId: userDetails.Id,
            ApproverId: element.value,
            RoleId: element?.roleId,
            SequenceNo: element.sequenceNo,
            InitiatorRole: currentRoleId?.value,
            IsSendback: isSendback,
            Remarks: remarks,
            Decision: DecisionEnum.Initiated,
            ApproverType: index === 0 ? "HiringManager" : null,
          };
          return obj1;
        });
        setDisableProceedBtn(true);
        const response = await APICall(
          insertUpdateMRFApproverMapping,
          "POST",
          arr
        ).finally(() => {
          setDisableProceedBtn(false);
        });

        if (response?.status == 0) {
          setIsOpen(false);
          // if (false) {
          //   setSendback(false);
          // }
          await handleFetchMRFApproverByMRFId(true);
          navigate("/home");
          notify(0, "Record Saved Successfully.");
        } else if (response?.status == 1) {
          notify(1, response?.message);
        }
      }
      return 0;
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
      setDisableProceedBtn(false);
    }
  };

  return (
    <React.Fragment>
      <div>
        <Accordion
          hidden={hideSectionAndAttribute}
          elevation={0}
          className="mb-3"
          expanded={accordion}
          onChange={() => setAccordion((prev) => !prev)}
        >
          <AccordionSummary
            id="profile-accordion"
            style={{ background: "#3C5464" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}
          >
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <p style={{ fontSize: "16px" }}>Workflow</p>
              </div>
              <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
            </div>
          </AccordionSummary>

          <AccordionSummary>
            <div className="Approvel">
              <div className="row acc-heading">
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <label className="col-form-label">
                    Initiator <span style={{ color: "red" }}>*</span>
                  </label>
                  <InputForm
                    value={initiatorName}
                    placeholder={"Initiator"}
                    isDisabled={true}
                    textArea={false}
                    label={"Initiator"}
                    onChange={() => {}}
                  />
                </div>

                {ogApprovers.map((approver, index) => {
                  return (
                    <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
                      <label className="col-form-label">
                        {index == 0
                          ? "Hiring Manager"
                          : index == ogApprovers?.length - 1
                          ? "TA Head"
                          : "Ad Hoc Approver"}
                        <span style={{ color: "red" }}>*</span>
                      </label>

                      <SelectForm
                        id=""
                        label=""
                        value={approver}
                        onChange={(selectedOption) => {
                          handleChangeApprovers(selectedOption, approver);
                        }}
                        placeholder={`Approver ${index + 1}`}
                        options={(e, cb) => {
                          index == 0
                            ? handleFetchHRManagers(e, cb)
                            : handleInputChangeForApp(
                                e,
                                cb,
                                approver?.IsAdhocApprover
                              );
                        }}
                        isMulti={false}
                        isSearchable={true}
                        isClearable={true}
                        async={true}
                        isDisabled={approver.disabled}
                      />

                      {index != 0 && index != ogApprovers?.length - 1 && (
                        <div className="col-lg-1" hidden={approver.disabled}>
                          <span
                            onClick={() => {
                              if (approver.disabled) return;
                              let tempOgApprovers = [...ogApprovers];
                              tempOgApprovers = tempOgApprovers.filter(
                                (i) => i?.IsAdhocApprover == false
                              );
                              setOgApprovers(tempOgApprovers);
                            }}
                          >
                            <i className="fa fa-trash" aria-hidden="true"></i>
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>

              <div className="row">
                <div className="col-lg-3">
                  {ogApprovers?.length < 3 && (
                    <button
                      className="mt-3 btn btn-primary"
                      onClick={handleAddApprover}
                      disabled={true}
                    >
                      <i className="fa fa-plus"></i>
                      &nbsp; Add Approver
                    </button>
                  )}
                </div>
              </div>
            </div>
          </AccordionSummary>
        </Accordion>

        {/* <Accordion
          hidden={!sendback}
          elevation={0}
          className="mb-3"
          expanded={accordionRemarks}
          onChange={() => setAccordionRemarks((prev) => !prev)}
        >
          <AccordionSummary
            id="profile-accordion"
            style={{ background: "#3C5464" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}
          >
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <p style={{ fontSize: "16px" }}>Initiator Remarks</p>
              </div>
              <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
            </div>
          </AccordionSummary>

          <AccordionSummary>
            <div className="Approvel">
              <div className="row acc-heading">
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <label className="col-form-label">Remarks</label>
                  <InputForm
                    value={remarks}
                    placeholder={"Remarks"}
                    isDisabled={!sendback}
                    textArea={true}
                    rows={4}
                    label={"Remarks"}
                    onChange={(event) => setRemarks(event?.target?.value)}
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <label className="col-form-label">
                    Previous Conversation
                  </label>
                  <InputForm
                    value={previousConversation}
                    placeholder={"Previous Conversation"}
                    isDisabled={true}
                    textArea={true}
                    rows={7}
                    onChange={() => {}}
                  />
                </div>
              </div>
            </div>
          </AccordionSummary>
        </Accordion> */}
      </div>

      {false && (
        <div className="SectionSubmit mb-4 clearfix">
          <button
            onClick={() => {
              if (!isScreeningSubmitted) {
                notify(1, "Save Screening Questions");
                return null;
              }
              setIsOpen(true);
            }}
            disabled={disableSubmitBtn}
            className="btn btn-filter-submit float-right ml-2"
          >
            <i className="fa-solid fa-check"></i> Submit
          </button>
          <button
            onClick={handleMRFDraft}
            disabled={true}
            className="btn btn-primary float-right ml-2"
          >
            <i className="fa-solid fa-floppy-disk"></i> Save
          </button>
          <button
            onClick={handleCancelMRFWorkflow}
            disabled={disableSubmitBtn}
            className="btn btn-secondary float-right"
          >
            <i className="fa-solid fa-times"></i> Cancel
          </button>
        </div>
      )}

      <Modal
        show={modalIsOpen}
        onHide={() => setIsOpen(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">Confirm!</div>
        </div>
        <Modal.Body>
          <h5>Are you sure you want to submit?</h5>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn"
            style={{
              marginLeft: 5,
              color: "black !important",
              borderColor: "#ACACAC",
            }}
            onClick={() => {
              setIsOpen(false);
              handleProceedSubmit();
            }}
          >
            Yes
          </button>
          <button
            className="btn btn-cancel"
            style={{
              marginLeft: 5,
              color: "black !important",
              borderColor: "#ACACAC",
            }}
            onClick={() => setIsOpen(false)}
          >
            No
          </button>
        </Modal.Footer>
      </Modal>

      {/* <Modal open={modalIsOpen}>
        <Box sx={style}>
          <h4
            onClick={() => {
              setIsOpen(false);
            }}
            className="CloseBtn float-right"
          >
            <i className="fa-regular fa-circle-xmark"></i>
          </h4>
          <div className="ModalText"></div>

          <button
            onClick={() => {
              handleProceedSubmit();
            }}
            className="ProceedBtn float-right ml-lg-0 ml-md-0 ml-1"
            disabled={disableProccedBtn}
          >
            <i className="fa-solid fa-check"></i> Proceed
          </button>
         
        </Box>
      </Modal> */}

      {/* adhoc confirmation Modal */}
      <Modal
        show={adHocModal}
        onHide={() => setAdHocModal(false)}
        backdrop="static"
        size="sm"
        keyboard={false}
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-6">
            Do you want to add this Adhoc approver?
          </div>
        </div>
        <Modal.Body>
          {currentAdHocApprover && (
            <div>
              <p>
                Employee: <span>{currentAdHocApprover.label}</span>
              </p>
              <p>
                Designation: <span>{currentAdHocApprover.designation}</span>
              </p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setAdHocModal(false);
            }}
          >
            Yes
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setAdHocModal(false);
              removeAdHocApprover();
            }}
          >
            No
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default RWorkflowSection;
