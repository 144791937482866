import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import defaultProfileImgPic from "../../Assets/Images/profile.jpeg";
import defaultProfileImgPic from "../../Assets/Images/thumbnail.jpg";
import SelectForm from "../../Components/SelectForm/SelectForm";
import InputForm from "../../Components/InputForm/InputForm";
import { APICall } from "../../Helpers/APICalls";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import { Modal } from "react-bootstrap";
import notify from "../../Helpers/ToastNotification";
import { LoaderContext, getContext } from "../../Helpers/Context/Context";
import "./TrainerMaster.css";
import {
  GetCommonDropdownForScheduling,
  GetTrainerDasboardFilter,
  GetLegalEntityPhysicalLocationbyEmployeeId,
  InsertUpdateTrainerData,
  GetThumbnailDetails,
  SetThumbnailDetails,
  GetTrainerDetails,
  DeleteThumbnailUrl,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import { error } from "console";
import { NationalIdCardType } from "../../Components/FromStructure/NationalIdCardTypeRegex";

const Trainers = () => {
  useEffect(() => {
    (async () => {
      await GetRoleType();
      await GetAgency();
      await onLoadEditData();
    })();
  }, []);

  const onLoadEditData = async () => {
    showLoader();
    if (state != null && state.id != 0) {
      console.log(state.id, "StateId");

      const { data } = await APICall(GetTrainerDetails, "POST", {
        Id: state.id,
      });

      console.log(data);
      settrainerGroup(data?.trainerType);
      setSpecialisedIn(data?.specialisation);
      setBio(data?.bio);

      const Trainer = await fetchDropdown("", "co_ordinator", data?.userId);

      const train = Trainer[0];

      setFormData((prev) => {
        return { ...prev, co_ordinator: train };
      });

      setRoleType({
        label: data?.roleType,
        value: data?.roleType,
        //  value: data.categoryId,
      });

      setAgencys({
        label: data?.agency,
        value: data?.agencyID,
      });

      setEmailId(data?.emailId);

      setMobile(data?.mobile);

      setAddress(data?.agencyAddress);

      setName(data?.trainerName);
      if (data?.thumbnailDataObject != null) {
        setProfileImgToUpload({
          Name: "Edit.jpg",
          Data: data?.thumbnailDataObject,
        });
        setProfileImg(`data:image;base64,${data?.thumbnailDataObject}`);
      } else {
        setProfileImg(null);
      }

      setisDisabledCheckBox(true);
      hideLoader();
    }
    hideLoader();
  };

  interface IOptions {
    label?: string;
    value?: number | string;
    code?: string;
  }
  interface IFormData {
    co_ordinator?: IOptions | boolean | any;
  }

  const navigate = useNavigate();
  const [profileImg, setProfileImg] = useState(null);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [EmployeeOption, setEmployeeOption] = useState([]);
  const [EmployeeID, setEmployeeID] = useState([]);
  const [RoleType, setRoleType] = useState<any>([]);
  const [RoleTpeOption, setRoleOption] = useState([]);
  const [SpecialisedIn, setSpecialisedIn] = useState("");
  const [Bio, setBio] = useState("");
  const [Agency, setAgency] = useState("");
  const [AgencyLocation, setAgencyLocation] = useState("");
  const [Name, setName] = useState("");
  const [Agencys, setAgencys] = useState<any>([]);
  const [AgencyOption, setAgencyOption] = useState([]);
  const [EmailId, setEmailId] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Address, setAddress] = useState("");
  const [trinerGroup, settrainerGroup] = useState("Internal");
  const [formData, setFormData] = useState<IFormData>({});
  let { userDetails, currentRoleId }: any = useUserContext();
  //const [profileImg, setProfileImg] = useState(null);
  const [profileImgToUpload, setProfileImgToUpload] = useState<any>(null);
  const { state } = useLocation();

  const [isDisable, setIsDisable] = useState(
    state && state?.isDisable ? true : false
  );
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const [isDisabledCheckedBox, setisDisabledCheckBox] = useState<any>(false);
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  type FormErrors = {
    Employee?: string;
    Roletype?: string;
    SpecilialisedIn?: string;
    Bio?: string;
    AgencyName?: string;
    AgencyLocation?: string;
    Agency?: string;
    EmailId?: string;
    Mobile?: string;
    Address?: string;
    Name?: string;
    // Add more fields if necessary
  };
  useEffect(() => {
    if (formData?.co_ordinator?.value != undefined) {
      GetAgencyonEmployee();
    } else {
      setAgencyLocation("");
      setAgency("");
      setName("");
      setAgencys([]);
      setEmailId("");
      setMobile("");
      setAddress("");
    }
  }, [formData]);

  const GetRoleType = async () => {
    let UserId;
    const { data } = await APICall(GetTrainerDasboardFilter, "POST", {
      Mode: "ROLETYPE",
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    if (data !== null && data.length > 0) {
      let role = data.map((element) => ({
        value: element.value,
        label: element.label,
      }));
      console.log(role, "RoleType");
      setRoleOption(role);
      const foundItem = data.find((item) => item.value === "Trainer");
      setRoleType(foundItem);
    }
  };

  const GetAgency = async () => {
    let UserId;

    const { data } = await APICall(GetTrainerDasboardFilter, "POST", {
      Mode: "AGENCY",
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    if (data !== null && data.length > 0) {
      let role = data.map((element) => ({
        value: element.value,
        label: element.label,
      }));
      setAgencyOption(role);
    }
  };

  const GetAgencyonEmployee = async () => {
    let UserId;

    const { data } = await APICall(
      GetLegalEntityPhysicalLocationbyEmployeeId,
      "POST",
      {
        EmployeeId: formData?.co_ordinator?.value,
      }
    );

    if (data != null) {
      // setAgency(data[0].agencyLocation)
      setAgency(data[0].agency);
      setAgencyLocation(data[0].agencyLocation);
    }
    console.log(data);
  };

  const validateForm = () => {
    let errors: FormErrors = {};
    let isValid = true;

    if (trinerGroup === "Internal") {
      if (!formData.co_ordinator) {
        errors.Employee = "Employee is required";
        isValid = false;
      }
      if (!RoleType || !RoleType?.value) {
        errors.Roletype = "RoleType is required";
        isValid = false;
      }

      if (!SpecialisedIn.trim()) {
        errors.SpecilialisedIn = "Specialised In is required";
        isValid = false;
      }

      if (!Bio.trim()) {
        errors.Bio = "Bio is required";
        isValid = false;
      }
    } else {
      if (!Name.trim()) {
        errors.Name = "Name is required";
        isValid = false;
      }
      //  if(!Agencys || Agencys.value){
      //   errors.Agency = "Agency is Required"
      //   isValid = false;
      //  }
      if (!RoleType || !RoleType?.value) {
        errors.Roletype = "RoleType is required";
        isValid = false;
      }

      if (!SpecialisedIn.trim()) {
        errors.SpecilialisedIn = "Specialised In is required";
        isValid = false;
      }

      if (!Bio.trim()) {
        errors.Bio = "Bio is required";
        isValid = false;
      }

      // const emailRegex = NationalIdCardType?.EMAILID;

      if (!EmailId.trim()) {
        errors.EmailId = "Email Id is Required";
        isValid = false;
      } else if (!NationalIdCardType?.EMAILID.test(EmailId)) {
        errors.EmailId = "Invalid Email Id";
        isValid = false;
      }

      if (!Mobile.trim()) {
        errors.Mobile = "Mobile Number is Required";
        isValid = false;
      } else if (!NationalIdCardType?.MOBILENO.test(Mobile)) {
        errors.Mobile = "Invalid Mobile No";
        isValid = false;
      }

      if (!Address.trim()) {
        errors.Address = "Address is Required";
        isValid = false;
      }
    }

    // Add additional validations as needed

    setFormErrors(errors);
    return isValid;
  };

  const onSubmitData = async () => {
    if (trinerGroup === "Internal") {
      if (!validateForm()) {
        return; // Stop the submission if there are validation errors
      }
      console.log(formData?.co_ordinator?.value);
      let requestParams = {
        Id: state === null ? 0 : state.id,
        Mode: "SAVE",
        TrainerType: trinerGroup,
        RoleType: RoleType.value,
        UserId: formData?.co_ordinator?.value,
        TrainerName: "",
        AgencyID: null,
        ThumbnailUrl: null,
        AgencyAddress: "",
        EmailId: "",
        Mobile: "",
        Specialisation: SpecialisedIn,
        Bio: Bio,
        IsActive: true,
        CreatedBy: userDetails?.Id,
      };

      //console.log(requestParams);
      const data = await APICall(
        InsertUpdateTrainerData,
        "POST",
        requestParams
      );
      // console.log(response)
      if (data.id != 0 && profileImg != null) {
        await handleImageUpload(data.id);
      }
      if (data != null) {
        console.log(data, "data is Created");
        if (data.status === 0) {
          notify(0, data.message);
          navigate("/TrainerMaster");
        } else {
          notify(1, data.message);
        }
      } else {
        notify(1, "Something went wrong");
      }
    }

    if (trinerGroup === "External") {
      if (!validateForm()) {
        return; // Stop the submission if there are validation errors
      }
      let requestParams = {
        Id: state === null ? 0 : state.id,
        Mode: "SAVE",
        TrainerType: trinerGroup,
        RoleType: RoleType.value,
        UserId: null,
        TrainerName: Name,
        AgencyID: Agencys?.value,
        ThumbnailUrl: null,
        AgencyAddress: Address,
        EmailId: EmailId,
        Mobile: Mobile,
        Specialisation: SpecialisedIn,
        Bio: Bio,
        IsActive: true,
        CreatedBy: userDetails?.Id,
      };

      const data = await APICall(
        InsertUpdateTrainerData,
        "POST",
        requestParams
      );
      //console.log(response);
      if (data.id != 0 && profileImg != null) {
        await handleImageUpload(data.id);
      }
      if (data != null) {
        console.log(data, "Trainer is Created");
        if (data.status === 0) {
          notify(0, data.message);
          navigate("/TrainerMaster");
        } else {
          notify(1, data.message);
        }
      } else {
        notify(1, "Something went wrong");
      }
    }
  };

  const onResetData = () => {
    setFormData((prev) => {
      return { ...prev, co_ordinator: "" };
    });
    // setRoleType([]);
    setSpecialisedIn("");
    setBio("");
    setAgency("");
    setAgencyLocation("");
    setName("");
    setAgencys([]);
    setEmailId("");
    setMobile("");
    setAddress("");
  };

  const handleImageUpload = async (id: any) => {
    if (profileImgToUpload) {
      showLoader();
      let reqObj = {
        file: {
          // AC: "ProfilePic",
          Name: profileImgToUpload.Name,
          Data: profileImgToUpload.Data,
        },
        PId: id,
        SectionName: "Trainer",
        UserId: userDetails?.UserId,
        ///ModuleName: "EmployeeCentral",
        //TabName: "Personal",
        //ModuleId: 1,
        // ProxyUserId: proxyUserDetails === null ? null : proxyUserDetails?.value,
        // Id: id,
      };

      try {
        const response = await APICall(SetThumbnailDetails, "POST", reqObj);
        if (response && response.data) {
          setProfileImg(`data:image;base64,${response.data.base64}`);
          setShowProfileModal(false);
          hideLoader();
        } else {
          console.error("Image upload failed");
          notify(1, "Image upload failed.");
          hideLoader();
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        hideLoader();
      }
    }
    // else {
    //   notify(1, "Please Select Image to Upload.");
    // }
  };

  const EmptyField = () => {
    setSpecialisedIn("");
    setBio("");
    //setRoleType([])
    setName("");
    setEmailId("");
    setMobile("");
    setAddress("");
  };

  const onInternalExternalTrainerList = async (event) => {
    if (event === "Internal") {
      settrainerGroup(event);
      EmptyField();
      formErrors.Roletype = "";
      formErrors.SpecilialisedIn = "";
      formErrors.Bio = "";
    } else if (event === "External") {
      settrainerGroup(event);
      EmptyField();
      formErrors.Roletype = "";
      formErrors.SpecilialisedIn = "";
      formErrors.Bio = "";
      formErrors.Name = "";
      formErrors.EmailId = "";
      formErrors.Mobile = "";
      formErrors.Address = "";
    }
  };

  const fileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleImageSelect = async (e) => {
    const file = e.target.files[0];
    let fileBase64: { Name: string; Data: string } = null;
    const base64 = (await fileToBase64(file)) as string;
    const base64WithoutPrefix = base64.split(",")[1];
    fileBase64 = { Name: file.name, Data: base64WithoutPrefix };

    const validExtensions = ["png", "jpg", "jpeg"];
    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();
      const fileSize = file.size;
      const maxFileSizeBytes = 5 * 1024 * 1024;

      // Check if the file extension is allowed
      if (
        validExtensions.includes(fileExtension) &&
        fileSize <= maxFileSizeBytes
      ) {
        // file to upload
        setProfileImgToUpload(fileBase64);
        setProfileImg(`data:image;base64,${fileBase64.Data}`);
      } else {
        notify(1, "Only file with png,jpg,jpeg allowed and size under 5MB");
        e.target.value = "";
        setProfileImgToUpload(null);
      }
    }
  };

  async function handleSearchForAsyncDropdown(event, code, callback) {
    try {
      if (event?.length >= 3) {
        const data = await fetchDropdown(event, code);
        callback(data);
      } else {
        callback([]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function onChange(event, code) {
    try {
      setFormData((prev) => {
        return { ...prev, [code]: event };
      });
    } catch (error) {
    } finally {
    }
  }

  async function fetchDropdown(searchText = "", code = "", value = null) {
    try {
      const response = await APICall(
        GetCommonDropdownForScheduling,
        "POST",
        {
          SearchText: searchText,
          DropdownValues: value,
          Code: code,
          UserId: userDetails?.Id,
          RoleId: currentRoleId?.value,
        },
        true
      );

      if (response?.status === 0) {
        if (response?.data) {
          return response?.data;
        } else {
          return [];
        }
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  const deleteProfileImage = async (id: any) => {
    if (profileImg != null) {
      showLoader();
      const doc = await APICall(DeleteThumbnailUrl, "POST", {
        id,
        CreatedBy: userDetails?.Id,
      });

      console.log(doc, "doc");
      if (doc.status === 0) {
        setProfileImg(null);
        setShowProfileModal(false);
        setProfileImgToUpload(null);
        hideLoader();
      } else {
        notify(1, "File not Deleted, Something Went Wrong.");
        hideLoader();
      }
    }
  };

  return (
    <>
      <div className="breadcrumb modified-bredcrumb">
        <span>
          <ul>
            <li>
              <a href="/home">Home</a>
            </li>
            <li>
              {" "}
              <a href="/TrainerMaster">Trainer Master</a>
            </li>
            <li>Manager Trainer</li>
          </ul>
        </span>
        <button
          onClick={() => {
            navigate("/TrainerMaster");
          }}
          disabled={false}
          className="btn btn-secondary float-right">
          <i className="fa-solid fa-arrow-left"></i> Back
        </button>
      </div>
      <div className="container-fluid ">
        <div className="row">
          {/* <div className="col-md-3 d-flex justify-content-center align-items-start pers_prof">
            <div className="prof_img">
              <img
                src={profileImg != null ? profileImg : defaultProfileImgPic}
                className="card-img-top"
                alt="Thumbnail Image"
              />
              {!isDisable && (
                <a
                  className="edit-profile"
                  onClick={() => setShowProfileModal(true)}
                >
                  <i className="fas fa-edit"></i>
                </a>
              )}
            </div>
          </div> */}
          <div className="col-md-3 d-flex justify-content-center align-items-start">
            <div className="card Prof_img">
              <img
                src={profileImg != null ? profileImg : defaultProfileImgPic}
                className="card-img-top"
                alt="Thumbnail Image"
              />
              {!isDisable && (
                <a
                  className="edit-profile"
                  onClick={() => setShowProfileModal(true)}>
                  <i className="fas fa-edit"></i>
                </a>
              )}
            </div>
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-lg-2 col-md-2 col-sm-12 mb-3">
                <label className="col-form-label">Trainer</label>

                <div>
                  <input
                    className="ml-2 mr-1"
                    disabled={isDisabledCheckedBox}
                    id={`trainerGroup_1`}
                    type="radio"
                    name="targetGroup"
                    onChange={() => onInternalExternalTrainerList("Internal")}
                    checked={trinerGroup === "Internal"}
                  />
                  <label htmlFor={`targetGroup_1`}>Internal</label>
                </div>
              </div>

              <div className="col-lg-2 col-md-2 col-sm-12 mb-3">
                <br />

                <div className="mt-3">
                  <input
                    className="ml-2 mr-1"
                    disabled={isDisabledCheckedBox}
                    id={`trainerGroup_1`}
                    type="radio"
                    name="targetGroup"
                    onChange={() => onInternalExternalTrainerList("External")}
                    checked={trinerGroup === "External"}
                  />
                  <label htmlFor={`targetGroup_1`}>External</label>
                </div>
              </div>

              {trinerGroup == "Internal" && (
                <div className="col-md-4">
                  <label className="col-form-label">Employee</label>
                  <sup>*</sup>
                  <SelectForm
                    isClearable
                    async
                    options={(searchString, cb) => {
                      handleSearchForAsyncDropdown(
                        searchString,
                        "co_ordinator",
                        cb
                      );
                    }}
                    placeholder={"Search Employee"}
                    onChange={(val: any) => {
                      onChange(val, "co_ordinator");
                    }}
                    isMulti={false}
                    noIndicator={false}
                    noSeparator={false}
                    value={formData?.co_ordinator}
                  />
                  {formErrors.Employee && (
                    <p style={{ color: "red" }}>{formErrors.Employee}</p>
                  )}
                </div>
              )}

              {trinerGroup === "External" && (
                <div className="col-md-4">
                  <label className="col-form-label">Name</label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control"
                    placeholder="Please enter the Name"
                    isDisabled={isDisable}
                    onChange={(e) => setName(e.target.value)}
                    textArea={false}
                    value={Name}
                    maxLength="255"
                  />
                  {formErrors.Name && (
                    <p style={{ color: "red" }}>{formErrors.Name}</p>
                  )}
                </div>
              )}

              <div className="col-md-4">
                <label className="col-form-label">Role Type</label>
                <sup>*</sup>
                <SelectForm
                  isClearable
                  options={RoleTpeOption}
                  placeholder={"Select Role Type"}
                  onChange={(event) => {
                    setRoleType(event);
                  }}
                  isMulti={false}
                  noIndicator={false}
                  noSeparator={false}
                  value={RoleType}
                  isDisabled={true}
                />
                {formErrors.Roletype && (
                  <p style={{ color: "red" }}>{formErrors.Roletype}</p>
                )}
              </div>

              <div className="col-md-4">
                <label className="col-form-label">Specialised In</label>
                <sup>*</sup>
                <InputForm
                  className="form-control"
                  placeholder="Specialised In"
                  isDisabled={isDisable}
                  onChange={(e) => setSpecialisedIn(e.target.value)}
                  textArea={false}
                  value={SpecialisedIn}
                  maxLength="255"
                />
                {formErrors.SpecilialisedIn && (
                  <p style={{ color: "red" }}>{formErrors.SpecilialisedIn}</p>
                )}
              </div>

              <div className="col-md-4">
                <label className="col-form-label">Bio</label>
                <sup>*</sup>
                <InputForm
                  className="form-control"
                  placeholder="Please Enter the Bio"
                  isDisabled={isDisable}
                  onChange={(e) => setBio(e.target.value)}
                  textArea={true}
                  value={Bio}
                  maxLength="255"
                />
                {formErrors.Bio && (
                  <p style={{ color: "red" }}>{formErrors.Bio}</p>
                )}
              </div>

              {trinerGroup === "Internal" && (
                <>
                  <div className="col-md-4">
                    <label className="col-form-label">Agency Name</label>
                    <sup></sup>
                    <InputForm
                      className="form-control"
                      placeholder="Please Enter Agency Name"
                      isDisabled={true}
                      onChange={(e) => setAgency(e.target.value)}
                      textArea={false}
                      value={Agency}
                      maxLength="255"
                    />
                    {formErrors.AgencyName && (
                      <p style={{ color: "red" }}>{formErrors.AgencyName}</p>
                    )}
                  </div>

                  <div className="col-md-4">
                    <label className="col-form-label">Agency Location</label>
                    <sup></sup>
                    <InputForm
                      className="form-control"
                      placeholder="Please Enter Agency Location"
                      isDisabled={true}
                      onChange={(e) => setAgencyLocation(e.target.value)}
                      textArea={false}
                      value={AgencyLocation}
                      maxLength="255"
                    />
                    {formErrors.AgencyLocation && (
                      <p style={{ color: "red" }}>
                        {formErrors.AgencyLocation}
                      </p>
                    )}
                  </div>
                </>
              )}

              {trinerGroup === "External" && (
                <>
                  <div className="col-md-4">
                    <label className="col-form-label">Agency</label>
                    <sup></sup>
                    <SelectForm
                      isClearable
                      isDisabled={isDisable}
                      options={AgencyOption}
                      placeholder={"Select Agency"}
                      onChange={(event) => {
                        setAgencys(event);
                      }}
                      isMulti={false}
                      noIndicator={false}
                      noSeparator={false}
                      value={Agencys}
                    />
                    {formErrors.Agency && (
                      <p style={{ color: "red" }}>{formErrors.Agency}</p>
                    )}
                  </div>

                  <div className="col-md-4">
                    <label className="col-form-label">Email ID</label>
                    <sup>*</sup>
                    <InputForm
                      className="form-control"
                      placeholder="Please Enter Email ID"
                      isDisabled={isDisable}
                      onChange={(e) => setEmailId(e.target.value)}
                      textArea={false}
                      value={EmailId}
                      maxLength="255"
                    />
                    {formErrors.EmailId && (
                      <p style={{ color: "red" }}>{formErrors.EmailId}</p>
                    )}
                  </div>

                  <div className="col-md-4">
                    <label className="col-form-label">Mobile No</label>
                    <sup>*</sup>
                    <InputForm
                      className="form-control"
                      placeholder="Please Enter Mobile No"
                      isDisabled={isDisable}
                      onChange={(e) =>
                        setMobile(e.target.value.replace(/[^0-9]/g, ""))
                      }
                      textArea={false}
                      value={Mobile}
                      maxLength="15"
                    />
                    {formErrors.Mobile && (
                      <p style={{ color: "red" }}>{formErrors.Mobile}</p>
                    )}
                  </div>

                  <div className="col-md-4">
                    <label className="col-form-label">Address</label>
                    <sup>*</sup>
                    <InputForm
                      className="form-control"
                      placeholder="Please Enter Address"
                      isDisabled={isDisable}
                      onChange={(e) => setAddress(e.target.value)}
                      textArea={true}
                      value={Address}
                      maxLength="255"
                    />
                    {formErrors.Address && (
                      <p style={{ color: "red" }}>{formErrors.Address}</p>
                    )}
                  </div>
                </>
              )}
            </div>
            {!isDisable && (
              <div className="SectionSubmit mb-4 mt-4 clearfix">
                <button
                  onClick={() => {
                    onResetData();
                  }}
                  disabled={false}
                  className="btn btn-secondary float-right ml-2">
                  <i className="fa-solid fa-refresh"></i> Reset
                </button>

                <button
                  onClick={() => {
                    onSubmitData();
                  }}
                  disabled={false}
                  className="btn btn-filter-submit float-right ml-2">
                  <i className="fa-solid fa-check"></i> Save
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        show={showProfileModal}
        onHide={() => setShowProfileModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered>
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}>
          <div className="col-lg-12">Upload Picture!</div>
        </div>
        <Modal.Body>
          <>
            <input
              type="file"
              multiple={false}
              accept={".png,.jpg,.jpeg"}
              onChange={handleImageSelect}
            />
            <div>
              <b>Acceptable image formats:</b> .png, .jpg and .jpeg{" "}
            </div>
            <div>
              {" "}
              <b>Maximum File Size:</b> 5MB{" "}
            </div>
            <div>
              <b>Resolution:</b> 3245 X 3245{" "}
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          {profileImg != null && (
            <>
              {state != null && state.id != 0 ? (
                // Add any additional JSX you want to render when state is not null and state.id is not 0
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: 5 }}
                  onClick={() => {
                    deleteProfileImage(state.id);
                  }}>
                  Remove
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  style={{ marginLeft: 5 }}
                  onClick={() => {
                    setShowProfileModal(false);
                    setProfileImg(null);
                    setProfileImgToUpload(null);
                  }}>
                  Remove
                </button>
              )}
            </>
          )}

          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => setShowProfileModal(false)}>
            Upload
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowProfileModal(false);
            }}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Trainers;
