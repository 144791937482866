import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tabs,
  Tooltip,
} from "@mui/material";
import {
  DownloadExcelTemplateParticipationCourse,
  GetCourseScheduleDataList,
  GetCourseScheduleParticipantsList,
  GetCourseScheduleParticipantsListExport,
  UpdateParticipantsStatusList,
  UploadCourseScheduleParticipants,
  UploadExcelForCourseScheduleParticipantsStatus,
  getDropdownDataForParticipants,
  getParticipantsAttendance,
  getParticipantsAttendancePrint,
  getUserAndRoleWiseAccess,
  saveBulkSessionScheduleAttendance,
  GetCourseScheduleAttendance,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import { ExpandMore, HideImageOutlined } from "@mui/icons-material";
import SelectForm from "../../Components/SelectForm/SelectForm";

import InputForm from "../../Components/InputForm/InputForm";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Pages/Catalogue/Catalogue.css";
import Slider from "react-slick";
import { APICall } from "../../Helpers/APICalls";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import { LoaderContext } from "../../Helpers/Context/Context";
import ExcelJS from "exceljs";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import notify from "../../Helpers/ToastNotification";
import * as xlsx from "xlsx";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import moment from "moment";
import {
  base64ToArrayBuffer,
  getBase64FromFilePromise,
} from "../../Helpers/Common";
import fileDownload from "js-file-download";
import { Modal } from "react-bootstrap";
import DateForm from "../../Components/DateForm/DateForm";
import ImageColumnComponent from "../../Components/LMS/ImageColumnComponent/ImageColumnComponent";
import SessionScheduleGrid from "./SessionScheduleGrid";
import { json } from "stream/consumers";
import { set } from "date-fns";
import parse from "html-react-parser";
import { useReactToPrint } from "react-to-print";
import ComponentToPrint from "./ComponentToPrint";
import Switch from "@mui/material/Switch";
import CourseScheduleGrid from "./CourseScheduleGrid";
import LMSDynamicGrid from "../../Components/LMSDynamicGrid/LMSDynamicGrid";
// import "./Participant.css";
import "./customStyle.css";
const AccordianCustom = ({ children, header }) => {
  const [accordion, setAccordion] = useState(false);

  return (
    <Accordion
      elevation={0}
      className="mb-3"
      expanded={accordion}
      onChange={() => setAccordion((prev) => !prev)}
    >
      <AccordionSummary
        id="profile-accordion"
        style={{ background: "#3C5464" }}
        className="text-white acc_close"
        expandIcon={<ExpandMore />}
      >
        <div className="row d-flex align-items-center acc-heading">
          {header}
        </div>
      </AccordionSummary>

      <AccordionDetails className="">{children}</AccordionDetails>
    </Accordion>
  );
};

const AccordianCustom2 = ({
  courseScheduleData,
  checkCourseScheduleCheckboxes,
  courseData,
  setCourseData,
  checkSessionScheduleCheckboxes,
}) => {
  const [showSessionSchedule, setShowSessionSchedule] = useState(true);
  const [showCourseSchedule, setshowCourseSchedule] = useState(false);
  const [courseDataAttendance, setcourseDataAttendance] = useState([]);
  const [statusGridData, setStatusGridData] = useState([]);
  const [dashboardPageSize, setDashboardPageSize] = useState(10);
  const [dashboardStart, setDashboardStart] = useState(0);
  const [dashboardCount, setDashboardCount] = useState(0);
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();

  const gridColumns1 = [
    {
      name: "rowNum",
      label: "Sr.no",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "participantionStatus",
      label: "Participation Status",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "pendingAssessment",
      label: "Pending Assessment",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "pendingFeedback",
      label: "Pending Feedback",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "employeeCode",
      label: "Employee Code",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "fullName",
      label: "Full Name",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "sbu",
      label: "SBU",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "legalEntity",
      label: "Legal Entity",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "department",
      label: "Department",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "groupDateOfJoining",
      label: "Group Date Of Joining",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "jobBand",
      label: "Job Band",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "jobLevel",
      label: "JobLevel",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "employeeClass",
      label: "Employee Class",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "payrollLocation",
      label: "Payroll Location",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    // {
    //   name: "department",
    //   label: "Department",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     sortDescFirst: true,
    //   },
    // },
    // {
    //   name: "designation",
    //   label: "Designation",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     sortDescFirst: true,
    //   },
    // },
    // {
    //   name: "dateOdJoining",
    //   label: "Date of joining",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     sortDescFirst: true,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       // Convert the value to a JavaScript Date object
    //       const date = new Date(value);

    //       // Check if the date is valid before formatting
    //       if (!isNaN(date.getTime())) {
    //         // Format the date as dd-mm-yyyy
    //         const formattedDate = `${date
    //           .getDate()
    //           .toString()
    //           .padStart(2, "0")}/${(date.getMonth() + 1)
    //           .toString()
    //           .padStart(2, "0")}/${date.getFullYear()}`;

    //         return formattedDate;
    //       } else {
    //         return value; // Return the original value if date conversion fails
    //       }
    //     },
    //   },
    // },

    // {
    //   name: "gender",
    //   label: "Gender",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     sortDescFirst: true,
    //   },
    // },

    // {
    //   name: "payrollLocation",
    //   label: "PayrollLocation",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     sortDescFirst: true,
    //   },
    // },
    // {
    //   name: "employeeClass",
    //   label: "Employee Class",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     sortDescFirst: true,
    //   },
    // },
    // {
    //   name: "jobBand",
    //   label: "Job Bands",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     sortDescFirst: true,
    //   },
    // },
    // {
    //   name: "jobLevel",
    //   label: "Job Level",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     sortDescFirst: true,
    //   },
    // },
    // {
    //   name: "status",
    //   label: "Status",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     sortDescFirst: true,
    //   },
    // },
    // {
    //   name: "participantionStatus",
    //   label: "Participation Status",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     sortDescFirst: true,
    //   },
    // },
  ];

  const dashboardOptions1 = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: dashboardCount,
    rowsPerPage: dashboardPageSize,
    serverSide: false,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",
    onSearchChange: (searchText) => {
      if (searchText !== null) {
        //setDashboardSearchText(searchText);
      } else {
        //setDashboardSearchText("");
      }
    },
    // onColumnSortChange: async (sortColumn, sortDirection) => {
    //   if (sortDirection === "asc") {
    //     await setDashboardSortColumn(sortColumn);
    //     await setDashboardSortDirection(sortDirection);
    //   }
    //   if (sortDirection === "desc") {
    //     await setDashboardSortColumn(sortColumn);
    //     await setDashboardSortDirection(sortDirection);
    //   }
    // },
    // onChangePage: async (page) => {
    //   setDashboardStart(page * dashboardPageSize);
    // },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const handleChange = async (event, type, CourseScheduleId) => {
    setshowCourseSchedule(!showCourseSchedule);
    setShowSessionSchedule(!showSessionSchedule);
    let requestParams = {
      CourseScheduleId: CourseScheduleId,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    };
    const response = await APICall(
      GetCourseScheduleAttendance,
      "POST",
      requestParams
    );
    console.log("response= ", response);
    //setcourseDataAttendance(response?.data);
    // setStatusGridData((prev)=>{
    //   ...prev(data:response?.data)
    // })

    if (response.status === 0 && response.data.length > 0) {
      setStatusGridData(response?.data);
      setDashboardCount(response.data.length);
    } else {
      setStatusGridData([]);
      setDashboardCount(0);
    }

    // console.log("type=",type);
    // console.log("event=",event);
    // if(type === "course"){
    //   setshowCourseSchedule(!showCourseSchedule);
    //   setShowSessionSchedule(!showSessionSchedule);

    //   // setshowCourseSchedule(true);
    //   // setShowSessionSchedule(false)
    // }else{
    //   setshowCourseSchedule(!showCourseSchedule);
    //   setShowSessionSchedule(!showSessionSchedule)
    //   // setshowCourseSchedule(false);
    //   // setShowSessionSchedule(true);
    // }
  };

  return (
    <Accordion expanded elevation={0} className="mb-3">
      <AccordionSummary
        id="profile-accordion"
        style={{ background: "#3C5464" }}
        className="text-white acc_close"
        expandIcon={<ExpandMore />}
      >
        <div className="row d-flex align-items-center acc-heading">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <p style={{ fontSize: "16px" }}>
              <Tooltip title={""}>
                <span>
                  <input
                    disabled={showCourseSchedule === true ? true : false}
                    type="checkbox"
                    onChange={(e) => {
                      e.stopPropagation();
                      checkCourseScheduleCheckboxes(
                        e.target.checked,
                        courseScheduleData
                      );
                    }}
                    checked={courseScheduleData?.CourseScheduleChecked}
                  />
                </span>
              </Tooltip>

              <span>
                {"  "}
                {courseScheduleData?.CourseScheduleName}
              </span>

              <span className="ml-5">Course Sechedule</span>
              <Switch
                onChange={(event) =>
                  handleChange(
                    event,
                    "course",
                    courseScheduleData?.CourseScheduleId
                  )
                }
                checked={showCourseSchedule}
              />
              <span className="ml-5">Session Sechedule</span>
              <Switch
                onChange={(event) =>
                  handleChange(
                    event,
                    "session",
                    courseScheduleData?.CourseScheduleId
                  )
                }
                checked={showSessionSchedule}
              />
            </p>
          </div>
        </div>
      </AccordionSummary>
      {showSessionSchedule === true ? (
        <AccordionDetails className="">
          <div className="row"></div>
          <div className="row mt-2 ml-3">
            {courseScheduleData?.SS &&
              courseScheduleData?.SS.length > 0 &&
              courseScheduleData?.SS.map((sessionScheduleData, index) => (
                <>
                  <div className="col-lg-12">
                    <SessionScheduleGrid
                      sessionScheduleData={sessionScheduleData}
                      checkSessionScheduleCheckboxes={
                        checkSessionScheduleCheckboxes
                      }
                      courseData={courseData}
                      setCourseData={setCourseData}
                    />
                  </div>
                </>
              ))}
          </div>
        </AccordionDetails>
      ) : (
        <div className="mx-3 pt-4 pb-4">
          <DynamicGrid
            data={statusGridData}
            columns={gridColumns1}
            options={dashboardOptions1}
          />
        </div>
      )}
    </Accordion>
  );
};

const CourseAccordionData = ({ courseData }) => {
  const [courseGridData, setCourseGridData] = useState([]);

  const courseColumns = [
    {
      name: "courseCode",
      label: "Course ID",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "courseTitle",
      label: "Course Title",
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: "learningObjective",
      label: "Course Objective",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <span
              dangerouslySetInnerHTML={{
                __html: value,
              }}
            ></span>
          );
        },
      },
    },
  ];

  useEffect(() => {
    if (courseData) {
      const gridData = [
        {
          courseCode: courseData.CourseCode,
          courseTitle: courseData.CourseTitle,
          learningObjective: courseData.LearningObjective,
        },
      ];

      setCourseGridData(gridData);
    }
  }, [courseData]);

  return <LMSDynamicGrid data={courseGridData} columns={courseColumns} />;
};

const Participant = () => {
  const [CourseSchedule, setCouseSchedule] = useState<any>([]);
  const [CourseScheduleOption, setCourseScheduleOption] = useState([]);
  const [sessionScheduleOption, setSessionScheduleOption] = useState([]);
  const [dashboard, setdashboard] = useState([]);
  const [dashboardPageSize, setDashboardPageSize] = useState(10);
  const [dashboardStart, setDashboardStart] = useState(0);
  const [dashboardSortColumn, setDashboardSortColumn] = useState<any>("");
  const [dashboardSortDirection, setDashboardSortDirection] = useState("");
  const [dashboardCount, setDashboardCount] = useState(0);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  let { userDetails, currentRoleId, proxyUserDetails }: any = useUserContext();
  const [excelBlobs, setExcelBlobs] = useState([]);
  const [fileName, setfileName] = useState("");
  const [isDisabled, setIsDisabled] = useState<any>(true);
  const [reset, setReset] = useState<any>(false);
  const [errorData, setErrorData] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const [showPrintModel, setShowPrintModel] = useState(false);

  const [courseOptions, setCourseOptions] = useState([]);

  const [sessionOptions, setSessionOptions] = useState([]);

  const [courseData, setCourseData] = useState<any>([]);

  //const [courseDataAttendance, setcourseDataAttendance] = useState([]);

  const uploadFileRef = useRef(null);

  const [formErrors, setFormErrors] = useState<any>({});
  const [showSessionSchedule, setShowSessionSchedule] = useState(true);
  const [showCourseSchedule, setshowCourseSchedule] = useState(false);

  const [attributeDataObj, setAttributeDataObj] = useState<any>({
    course: null,
    session: null,
    courseSchedule: null,
    sessionSchedule: null,
    startDate: null,
    endDate: null,
  });

  const [file, setFile] = useState(null);

  let [isreload, setisreload] = useState<any>(false);
  const [showSubmitButton, setshowSubmitButton] = useState(false);

  interface userAndRoleWiseAccess {
    isView?: boolean;
    isEdit?: boolean;
    isDelete?: boolean;
  }

  const [printAttendanceData, setPrintAttendanceData] = useState<any>("");

  // const componentRef = useRef(null);
  // const handlePrintData = useReactToPrint({
  //   // copyStyles: true,
  //   content: () => componentRef.current,
  // });

  const handlePrintData = (divId) => {
    try {
      let printContents = document.getElementById(divId).innerHTML;
      let tempContainers = document.createElement("div");
      tempContainers.innerHTML = printContents;
      tempContainers.classList.add("print-container");

      document.title = `Attendence_Sheet`;

      document.body.appendChild(tempContainers);
      const bodyElements = document.body.children;
      for (let i = 0; i < bodyElements.length; i++) {
        if (bodyElements[i] !== tempContainers) {
          bodyElements[i].classList.add("hide-on-print");
        }
      }
      window.print();
      for (let i = 0; i < bodyElements.length; i++) {
        if (bodyElements[i] !== tempContainers) {
          bodyElements[i].classList.remove("hide-on-print");
        }
      }
      tempContainers.remove();
      document.title = "WelPro";

      setShowPrintModel(false);
    } catch (error) {
      console.error("Printing error:", error);
    }
  };

  const [userAndRoleWiseData, setUserAndRoleWiseData] =
    useState<userAndRoleWiseAccess>({});

  const getUserAndRoleWiseAccessApiCall = async () => {
    showLoader();
    const response = await APICall(getUserAndRoleWiseAccess, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      InterfaceName: "Participants_Dashboard",
    });

    if (response?.status === 0) {
      setUserAndRoleWiseData(response?.data);
    } else {
      notify(response?.status, response?.message);
    }
    hideLoader();
  };

  useEffect(() => {
    getUserAndRoleWiseAccessApiCall();
    getDropdownDataForCourseParticipantsApiCall();
  }, []);

  useEffect(() => {
    (async () => {
      await GetScheduledData();
    })();
  }, [isreload]);

  const getDropdownDataForCourseParticipantsApiCall = async () => {
    showLoader();
    const response = await APICall(getDropdownDataForParticipants, "POST", {
      Mode: "COURSE",
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    if (response?.status === 0) {
      setCourseOptions(response?.data);
    }
    hideLoader();
  };

  const getDropdownDataForSessionParticipantsApiCall = async (courseId) => {
    showLoader();
    const response = await APICall(getDropdownDataForParticipants, "POST", {
      Mode: "SESSION",
      CourseId: courseId,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    if (response?.status === 0) {
      setSessionOptions(response?.data);

      const sessionIds = response?.data?.map((val) => val?.value).join(",");
      await getDropdownDataForCourseScheduleParticipantsApiCall(
        courseId,
        sessionIds
      );

      await getDropdownDataForSessionScheduleParticipantsApiCall(
        courseId,
        sessionIds
      );
    }
    hideLoader();
  };

  const getDropdownDataForCourseScheduleParticipantsApiCall = async (
    courseId,
    sessionIds
  ) => {
    showLoader();
    const response = await APICall(getDropdownDataForParticipants, "POST", {
      Mode: "COURSE_SCHEDULE",
      CourseId: courseId,
      SessionIds: sessionIds,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    if (response?.status === 0) {
      setCourseScheduleOption(response?.data);
    }
    hideLoader();
  };

  const getDropdownDataForSessionScheduleParticipantsApiCall = async (
    courseId,
    sessionIds
  ) => {
    showLoader();
    const response = await APICall(getDropdownDataForParticipants, "POST", {
      Mode: "SESSION_SCHEDULE",
      CourseId: courseId,
      SessionIds: sessionIds,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });

    if (response?.status === 0) {
      setSessionScheduleOption(response?.data);
    }
    hideLoader();
  };

  const getParticipantsAttendanceApiCall = async () => {
    showLoader();

    const isError = validateForm();

    if (!isError) {
      setCourseData([]);
      const response = await APICall(getParticipantsAttendance, "POST", {
        CourseId: attributeDataObj?.course?.value,
        SessionIds:
          attributeDataObj?.session?.map((val) => val?.value).join(",") || null,
        CourseScheduleIds:
          attributeDataObj?.courseSchedule
            ?.map((val) => val?.value)
            .join(",") || null,
        SessionScheduleIds:
          attributeDataObj?.sessionSchedule
            ?.map((val) => val?.value)
            .join(",") || null,
        StartDate:
          attributeDataObj?.startDate !== null
            ? moment(attributeDataObj?.startDate).format("DD-MMM-yyyy")
            : null,
        EndDate:
          attributeDataObj?.endDate !== null
            ? moment(attributeDataObj?.endDate).format("DD-MMM-yyyy")
            : null,
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      });

      if (response?.status === 0) {
        const participantsData = JSON.parse(response?.data?.participantsData);

        if (participantsData?.length > 0) {
          setshowSubmitButton(true);
        }

        setCourseData(participantsData);
      } else if (response?.status === 1) {
        notify(response?.status, response?.message);
      }
    }

    hideLoader();
  };

  const getParticipantsAttendancePrintApiCall = async () => {
    showLoader();

    setPrintAttendanceData("");

    const isError = validateForm();

    if (!isError) {
      const response = await APICall(getParticipantsAttendancePrint, "POST", {
        CourseId: attributeDataObj?.course?.value,
        SessionIds:
          attributeDataObj?.session?.map((val) => val?.value).join(",") || null,
        CourseScheduleIds:
          attributeDataObj?.courseSchedule
            ?.map((val) => val?.value)
            .join(",") || null,
        SessionScheduleIds:
          attributeDataObj?.sessionSchedule
            ?.map((val) => val?.value)
            .join(",") || null,
        StartDate:
          attributeDataObj?.startDate !== null
            ? moment(attributeDataObj?.startDate).format("DD-MMM-yyyy")
            : null,
        EndDate:
          attributeDataObj?.endDate !== null
            ? moment(attributeDataObj?.endDate).format("DD-MMM-yyyy")
            : null,
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
      });

      if (response?.status === 0) {
        setShowPrintModel(true);

        const printAttendanceDate = response?.data?.printAttendance;

        if (printAttendanceDate !== null) {
          const printAttendanceTable = parse(printAttendanceDate);

          setPrintAttendanceData(printAttendanceTable);
        }
      } else if (response?.status === 1) {
        notify(response?.status, response?.message);
      }
    }

    hideLoader();
  };

  const GetScheduledData = async () => {
    showLoader();
    const { data } = await APICall(GetCourseScheduleDataList, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });
    if (data !== null && data.length > 0) {
      let role = data.map((element) => ({
        value: element.value,
        label: element.label,
      }));
      // setCourseScheduleOption(role);
    }
    hideLoader();
  };

  const toTitleCase = (str) => {
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  };

  const handleChange = async (event, type, CourseScheduleId) => {
    setshowCourseSchedule(!showCourseSchedule);
    setShowSessionSchedule(!showSessionSchedule);

    // console.log("type=",type);
    // console.log("event=",event);
    // if(type === "course"){
    //   setshowCourseSchedule(!showCourseSchedule);
    //   setShowSessionSchedule(!showSessionSchedule);
    //   // const response = await APICall("","POST",  {CourseScheduleId :CourseScheduleId ,  UserId: userDetails?.Id, RoleId: currentRoleId?.value,});

    //   // setshowCourseSchedule(true);
    //   // setShowSessionSchedule(false)
    // }else{
    //   setshowCourseSchedule(!showCourseSchedule);
    //   setShowSessionSchedule(!showSessionSchedule)
    //   // setshowCourseSchedule(false);
    //   // setShowSessionSchedule(true);
    // }
  };

  const formOnChange = (e, type) => {
    switch (type) {
      case "course":
        setAttributeDataObj({
          ...attributeDataObj,
          course: e,
          session: null,
          courseSchedule: null,
          sessionSchedule: null,
        });

        setSessionOptions([]);
        setCourseScheduleOption([]);
        setSessionScheduleOption([]);

        getDropdownDataForSessionParticipantsApiCall(e?.value);
        break;

      case "session":
        setAttributeDataObj({
          ...attributeDataObj,
          session: e,
          courseSchedule: null,
        });

        setCourseScheduleOption([]);

        const courseId = attributeDataObj?.course?.value;
        const sessionIds = e?.map((val) => val?.value).join(",");
        getDropdownDataForCourseScheduleParticipantsApiCall(
          courseId,
          sessionIds
        );

        getDropdownDataForSessionScheduleParticipantsApiCall(
          courseId,
          sessionIds
        );

        break;

      case "courseSchedule":
        setAttributeDataObj({
          ...attributeDataObj,
          courseSchedule: e,
        });
        break;

      case "sessionSchedule":
        setAttributeDataObj({
          ...attributeDataObj,
          sessionSchedule: e,
        });
        break;

      case "startDate":
        setAttributeDataObj({
          ...attributeDataObj,
          startDate: e,
        });
        break;

      case "endDate":
        setAttributeDataObj({
          ...attributeDataObj,
          endDate: e,
        });
        break;

      default:
        break;
    }
  };

  const handleDashboardData = async (e) => {
    showLoader();
    let CourseId1 = "";
    if (e != null) {
      CourseId1 = e.value;
    } else {
      CourseId1 = CourseSchedule.value;
    }

    let CourseId = CourseId1;
    let requesparams = {
      PageSize: dashboardPageSize,
      Page: dashboardStart,
      CourseScheduleId: CourseId,
    };
    const { data } = await APICall(
      GetCourseScheduleParticipantsList,
      "POST",
      requesparams
    );

    if (data != null) {
      setdashboard(data);
      setIsDisabled(data.length > 0 ? false : true);

      hideLoader();
    } else {
      setdashboard([]);
      setIsDisabled(true);
    }

    hideLoader();
  };

  const exporttoexcel = async () => {
    showLoader();
    const response = await APICall(
      GetCourseScheduleParticipantsListExport,
      "POST",
      { CourseScheduleId: CourseSchedule.value }
    );
    // console.log(response.data, "export to excel");

    // const responseData = response.data;

    // if (!responseData || responseData.length === 0) {
    //   console.error("No data to export.");
    //   notify(1, "No data to export.");
    //   hideLoader();
    //   return;
    // }

    if (response?.status == 0) {
      const { excelFileBase64 } = response?.data;

      if (excelFileBase64) {
        const fileName = `ParticipantList_${moment().format(
          "DD-MM-YYYY HH:mm:ss"
        )}.xlsx`;
        const base64 = excelFileBase64;

        let arrayBuffer = await base64ToArrayBuffer(base64);
        var byteArray = new Uint8Array(arrayBuffer);

        let blob = new Blob([byteArray], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const newFile = new File([blob], "", {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          lastModified: new Date().getTime(),
        });

        fileDownload(newFile, fileName);
      }
    } else {
      notify(1, "Something went wrong, Please Try again Later.");
    }

    // const columnsToInclude = [
    //   "employeeCode",
    //   "firstName",
    //   "middleName",
    //   "lastName",
    //   "gender",
    //   "business",
    //   "sbu",
    //   "legalEntity",
    //   "payrollLocation",
    //   "physicalLocation",
    //   "empFunction",
    //   "division",
    //   "department",
    //   "customBusiness",
    //   "section",
    //   "subSection",
    //   "subSubSection",
    //   "jobBand",
    //   "jobLevel",
    //   "employeeClass",
    //   "designation",
    //   "groupDateOfJoining",
    //   "employeeDateOfJoining",
    //   "jobCode",
    //   "jobTitle",
    //   "keyTalent",
    //   "clientFacingTitle",
    //   "criticalPosition",
    //   "hrManager",
    // ];
    // const headers = Object.keys(responseData[0])?.filter((header) =>
    //   columnsToInclude.includes(header)
    // );
    // const workbook = new ExcelJS.Workbook();
    // const worksheet = workbook.addWorksheet("Participant");

    // const columnWidths = headers.map((header) => {
    //   const maxContentLength = Math.max(
    //     ...responseData.map((row) =>
    //       row[header] ? String(row[header]).length : 0
    //     )
    //   );
    //   return Math.min(50, Math.max(10, maxContentLength * 1.5));
    // });

    // columnWidths.forEach((width, index) => {
    //   worksheet.getColumn(index + 1).width = width;
    // });

    // const formattedHeaders = headers.map((header) =>
    //   toTitleCase(header.replace(/([a-z])([A-Z])/g, "$1 $2"))
    // );

    // worksheet.addRow(formattedHeaders);
    // worksheet.getRow(1).font = { bold: true };
    // responseData.font = { bold: true };
    // responseData.forEach((row) => {
    //   const dataRow = headers.map((header) => {
    //     if (header === "dateOdJoining") {
    //       const dateOdJoining = row[header];
    //       const formattedDate = formatDate(dateOdJoining);
    //       return formattedDate;
    //     }
    //     return row[header] || "";
    //   });

    //   worksheet.addRow(dataRow);
    // });

    // workbook.xlsx.writeBuffer().then((buffer) => {
    //   const blob = new Blob([buffer], {
    //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //   });
    //   const url = URL.createObjectURL(blob);

    //   const a = document.createElement("a");
    //   a.href = url;
    //   a.download = "Participant.xlsx";
    //   a.click();

    //   URL.revokeObjectURL(url);
    // });
    hideLoader();
  };

  function formatDate(dateString) {
    const date = new Date(dateString);

    if (!isNaN(date.getTime())) {
      return `${date.getDate().toString().padStart(2, "0")}/${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}/${date.getFullYear()}`;
    } else {
      return dateString; // Return the original value if date conversion fails
    }
  }
  const dashboardOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: dashboardCount,
    rowsPerPage: dashboardPageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    //standard | vertical | simple
    onChangeRowsPerPage: (num) => {
      //   setLimit(num);
      //   setNxtPgInfo("");
      //   setPrevPgInfo("");
      //   setIsPrevOrNext("");
    },
    onSearchChange: (searchText) => {
      if (searchText !== null) {
        //setDashboardSearchText(searchText);
      } else {
        //setDashboardSearchText("");
      }
    },
    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setDashboardSortColumn(sortColumn);
        await setDashboardSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setDashboardSortColumn(sortColumn);
        await setDashboardSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      setDashboardStart(page * dashboardPageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const errorDataColumns = [
    {
      name: "Row",
      label: "Row",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: false,
      },
    },
    {
      name: "Col",
      label: "Column",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: false,
      },
    },
    {
      name: "ErrorMessage",
      label: "Error Message",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: false,
      },
    },
  ];

  const errorDataOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    rowsPerPage: 5,
    serverSide: false,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    //standard | vertical | simple
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const handleButtonClick = async (value: any, id: any) => {
    const { data } = await APICall(UpdateParticipantsStatusList, "POST", {
      CourseScheduleParticipanteId: id,
      ParticipantionStatus: value,
      CreatedBy: userDetails?.Id,
    });
    if (data != null) {
      notify(0, data.errorMessage);
      handleDashboardData(null);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      const newExcelBlobs = [];

      if (files && files.length > 0) {
        const newExcelBlobs = [];

        for (let i = 0; i < files.length; i++) {
          const reader = new FileReader();
          const file = files[i];

          reader.onload = (event) => {
            setExcelBlobs((prevExcelBlobs) => {
              const updatedBlobs = [
                ...prevExcelBlobs,
                {
                  file,
                  arrayBuffer: event.target.result,
                },
              ];

              // If all files are processed, set the state
              if (updatedBlobs.length > 0) {
                setfileName(updatedBlobs[0].file.name);
              }

              return updatedBlobs;
            });
          };

          reader.readAsArrayBuffer(file);
        }
      }
    }
  };

  const handleOnChange = (event) => {
    const files = event.target.files[0] || null;
    if (!files) {
      setFile(null);
      notify(1, "Please select a file.");
      return;
    } else {
      // Check if the selected file is an Excel file
      if (!files.name.endsWith(".xls") && !files.name.endsWith(".xlsx")) {
        setFile(null);
        notify(1, "Please upload an Excel file.");
        return;
      } else {
        setfileName(files.name);
        setFile(files);
      }
    }
  };

  const handleUpload = async () => {
    showLoader();

    // if (CourseSchedule === null || CourseSchedule.length === 0) {
    //   notify(1, "Please Select the ScheduledCourse");
    // } else {
    if (file !== null) {
      let base64 = await getBase64FromFilePromise(file);
      base64 = String(base64).split("base64,")[1];

      const response = await APICall(UploadCourseScheduleParticipants, "POST", {
        CourseScheduleId: CourseSchedule?.value,
        SectionName: "Course Schedule Participant Status Doc",
        Id: CourseSchedule?.value,
        UserId: userDetails?.Id,
        RoleId: currentRoleId?.value,
        ExcelFileBase64: base64,
      });

      if (response?.status == 0) {
        // notify(0, "Upload Successfully");
        notify(0, "Excel uploaded successfully");
        setReset(!reset);
        handleDashboardData(CourseSchedule?.value);
        setFile(null);
        setfileName("");

        uploadFileRef.current.value = null;

        // await getParticipantsAttendanceApiCall();
      } else if (response?.status == 5) {
        const { data } = response;

        if (data) {
          const fileName = "InvalidCourseScheduleParticipants.xlsx";
          const base64 = data;

          let arrayBuffer = await base64ToArrayBuffer(base64);
          var byteArray = new Uint8Array(arrayBuffer);

          let blob = new Blob([byteArray], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          const newFile = new File([blob], "", {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            lastModified: new Date().getTime(),
          });

          fileDownload(newFile, fileName);

          const excelErrorMessage = JSON.parse(response?.message);

          const errorMessage = () => {
            if (
              Array.isArray(excelErrorMessage) &&
              excelErrorMessage.length > 0
            ) {
              setErrorData(excelErrorMessage);
            } else {
              notify(1, excelErrorMessage);
            }
          };
          errorMessage();

          setShowErrorModal(true);

          onResetData();
        } else {
          notify(1, response?.message);
        }
      } else {
        notify(1, "Something went wrong.");
        setReset(!reset);
        onResetData();
      }
      // }
      //UploadCourseScheduleParticipants
      // excelBlobs.forEach(async (excelBlobData) => {
      //   try {
      //     const workbook: xlsx.WorkBook = xlsx.read(excelBlobData.arrayBuffer, {
      //       type: "array",
      //       raw: true,
      //     });
      //     // Example: Extracting data from the first sheet
      //     const sheetName = workbook.SheetNames[0];
      //     const sheet = workbook.Sheets[sheetName];
      //     const sheetData = xlsx.utils.sheet_to_json(sheet, { header: 1 });
      //     console.log(`Sheet Data for ${excelBlobData.file.name}:`, sheetData);
      //     // Example: Creating a new ExcelJS workbook and worksheet
      //     const newWorkbook = new ExcelJS.Workbook();
      //     const newWorksheet = newWorkbook.addWorksheet("NewSheet");
      //     // Example: Adding data to the new worksheet
      //     for (const rowData of sheetData as any) {
      //       // Ensure that numeric values are treated as numbers
      //       const numericRowData = rowData.map((value) =>
      //         typeof value === "string" ? value : parseFloat(value)
      //       );
      //       console.log(numericRowData, "rows");
      //       newWorksheet.addRow(numericRowData);
      //     }
      //     // Example: Convert the new workbook to binary data
      //     const newBuffer = await newWorkbook.xlsx.writeBuffer();
      //     console.log("buffer", newBuffer);
      //     const base64Data = btoa(
      //       new Uint8Array(newBuffer).reduce(
      //         (data, byte) => data + String.fromCharCode(byte),
      //         ""
      //       )
      //     );
      //     console.log(base64Data, "base64");
      //     let reqObj = {
      //       file: {
      //         // AC: "ProfilePic",
      //         Name: "Participant.xlsx",
      //         Data: base64Data,
      //       },
      //       CourseScheduleId: CourseSchedule?.value,
      //       SectionName: "Course Schedule Participant Status Doc",
      //       UserId: 1,
      //       ProxyUserId: null,
      //       Id: CourseSchedule?.value,
      //     };
      //     const response = await APICall(
      //       UploadExcelForCourseScheduleParticipantsStatus,
      //       "POST",
      //       reqObj
      //     );
      //     if (response.message === "Success") {
      //       notify(0, "Excel uploaded successfully");
      //       // supportingDocRef.current.value = null;
      //       setExcelBlobs([]);
      //       setReset(!reset);
      //       handleDashboardData(CourseSchedule?.value);
      //       //setfileName("")
      //     } else {
      //       notify(1, response.message);
      //       // supportingDocRef.current.value = null;
      //       setExcelBlobs([]);
      //       //setfileName("")
      //     }
      //   } catch (error) {
      //     console.error(
      //       `Error reading or processing Excel file ${excelBlobData.file.name}:`,
      //       error
      //     );
      //   }
      // });
    } else {
      notify(1, "Please Select file");
    }
    hideLoader();
  };

  const onResetData = () => {
    uploadFileRef.current.value = null;
    setFile(null);
    setfileName("");
  };

  const gridColumns = [
    {
      name: "participantionStatus",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          const { rowIndex, tableData } = tableMeta;
          const sequenceNo = tableData[rowIndex].sequence;
          let courseScheduleParticipanteId =
            tableData[rowIndex].courseScheduleParticipanteId;
          let invertedvalue = value === "Present" ? "Absent" : "Present";
          return (
            <div>
              {userAndRoleWiseData?.isEdit && (
                <button
                  onClick={() =>
                    handleButtonClick(
                      invertedvalue,
                      courseScheduleParticipanteId
                    )
                  }
                  style={{
                    backgroundColor:
                      invertedvalue === "Absent" ? "red" : "green",
                    color: "white",
                    border: "none",
                    padding: "5px 10px",
                    borderRadius: "5px",
                    cursor: "pointer",
                    whiteSpace: "nowrap",
                  }}
                >
                  {value === "Present" ? "Mark as Absent" : "Mark as Present"}
                </button>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "rowNum",
      label: "Sr.no",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "participantionStatus",
      label: "Participation Status",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "employeeCode",
      label: "Employee Code",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    {
      name: "fullName",
      label: "Full Name",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "sbu",
      label: "SBU",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "legalEntity",
      label: "Legal Entity",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "department",
      label: "Department",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "groupDateOfJoining",
      label: "Group Date Of Joining",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "jobBand",
      label: "Job Band",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "jobLevel",
      label: "JobLevel",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "employeeClass",
      label: "Employee Class",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "payrollLocation",
      label: "Payroll Location",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },

    // {
    //   name: "department",
    //   label: "Department",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     sortDescFirst: true,
    //   },
    // },
    // {
    //   name: "designation",
    //   label: "Designation",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     sortDescFirst: true,
    //   },
    // },
    // {
    //   name: "dateOdJoining",
    //   label: "Date of joining",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     sortDescFirst: true,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       // Convert the value to a JavaScript Date object
    //       const date = new Date(value);

    //       // Check if the date is valid before formatting
    //       if (!isNaN(date.getTime())) {
    //         // Format the date as dd-mm-yyyy
    //         const formattedDate = `${date
    //           .getDate()
    //           .toString()
    //           .padStart(2, "0")}/${(date.getMonth() + 1)
    //           .toString()
    //           .padStart(2, "0")}/${date.getFullYear()}`;

    //         return formattedDate;
    //       } else {
    //         return value; // Return the original value if date conversion fails
    //       }
    //     },
    //   },
    // },

    // {
    //   name: "gender",
    //   label: "Gender",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     sortDescFirst: true,
    //   },
    // },

    // {
    //   name: "payrollLocation",
    //   label: "PayrollLocation",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     sortDescFirst: true,
    //   },
    // },
    // {
    //   name: "employeeClass",
    //   label: "Employee Class",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     sortDescFirst: true,
    //   },
    // },
    // {
    //   name: "jobBand",
    //   label: "Job Bands",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     sortDescFirst: true,
    //   },
    // },
    // {
    //   name: "jobLevel",
    //   label: "Job Level",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     sortDescFirst: true,
    //   },
    // },
    // {
    //   name: "status",
    //   label: "Status",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     sortDescFirst: true,
    //   },
    // },
    // {
    //   name: "participantionStatus",
    //   label: "Participation Status",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     sortDescFirst: true,
    //   },
    // },
  ];

  var settings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      { breakpoint: 768, settings: { slidesToShow: 3, slidesToScroll: 1 } },
      { breakpoint: 600, settings: { slidesToShow: 2, slidesToScroll: 1 } },
      { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
  };

  const DownloadExcelTemplateApiCall = async () => {
    try {
      showLoader();
      if (attributeDataObj?.course && attributeDataObj?.course?.value > 0) {
        const parameters = {
          CourseId: attributeDataObj?.course?.value,
          SessionIds:
            attributeDataObj?.session?.map((val) => val?.value).join(",") ||
            null,
          CourseScheduleIds:
            attributeDataObj?.courseSchedule
              ?.map((val) => val?.value)
              .join(",") || null,
          StartDate:
            attributeDataObj?.startDate !== null
              ? moment(attributeDataObj?.startDate).format("DD-MMM-yyyy")
              : null,
          EndDate:
            attributeDataObj?.endDate !== null
              ? moment(attributeDataObj?.endDate).format("DD-MMM-yyyy")
              : null,
          UserId: userDetails?.Id,
          RoleId: currentRoleId?.value,
        };
        // const response = await APICall(getParticipantsAttendance, "POST", parameters);
        const response = await APICall(
          DownloadExcelTemplateParticipationCourse,
          "POST",
          parameters
        );

        if (response?.status == 0) {
          const { excelFileBase64 } = response?.data;

          if (excelFileBase64) {
            const fileName = `UploadAttendanceSheet_${moment().format(
              "DD-MM-YYYY HH:mm:ss"
            )}.xlsx`;
            const base64 = excelFileBase64;

            let arrayBuffer = await base64ToArrayBuffer(base64);
            var byteArray = new Uint8Array(arrayBuffer);

            let blob = new Blob([byteArray], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            const newFile = new File([blob], "", {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              lastModified: new Date().getTime(),
            });

            fileDownload(newFile, fileName);
          }
        } else {
          notify(1, "Something went wrong, Please Try again Later.");
        }
      } else {
        notify(1, "Please select Course");
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const checkCourseCheckboxes = (isChecked, courseData) => {
    setCourseData((prev) => {
      return prev.map((course) => {
        const updatedCourse = {
          ...course,
          CourseChecked: isChecked,
          CS: course?.CS?.map((courseSchedule) => {
            const updatedCourseSchedule = {
              ...courseSchedule,
              CourseScheduleChecked: isChecked,
              SS: courseSchedule?.SS?.map((sessionSchedule) => {
                return {
                  ...sessionSchedule,
                  SessionScheduleChecked: isChecked,
                };
              }),
            };
            return updatedCourseSchedule;
          }),
        };
        return updatedCourse;
      });
    });
  };

  const checkCourseScheduleCheckboxes = (isChecked, courseScheduleData) => {
    setCourseData((prev) => {
      return prev.map((course) => {
        const updatedCourse = {
          ...course,
          CS: course?.CS?.map((courseSchedule) => {
            if (
              courseSchedule?.CourseScheduleId ===
              courseScheduleData?.CourseScheduleId
            ) {
              return {
                ...courseSchedule,
                CourseScheduleChecked: isChecked,
                SS: courseSchedule?.SS?.map((sessionSchedule) => ({
                  ...sessionSchedule,
                  SessionScheduleChecked: isChecked,
                  participants: [],
                })),
              };
            }
            return courseSchedule;
          }),
        };
        return updatedCourse;
      });
    });
  };

  const checkSessionScheduleCheckboxes = (isChecked, sessionScheduleData) => {
    setCourseData((prev) => {
      return prev.map((course) => {
        const updatedCourse = {
          ...course,
          CS: course?.CS?.map((courseSchedule) => {
            const updatedCourseSchedule = {
              ...courseSchedule,
              SS: courseSchedule?.SS?.map((sessionSchedule) => {
                if (
                  sessionScheduleData?.SessionScheduleId ===
                  sessionSchedule?.SessionScheduleId
                ) {
                  return {
                    ...sessionSchedule,
                    SessionScheduleChecked: isChecked,
                  };
                }
                // Return unchanged session schedule if no match
                return sessionSchedule;
              }),
            };
            return updatedCourseSchedule;
          }),
        };
        return updatedCourse;
      });
    });
  };

  const validateForm = () => {
    let errorObj = {};
    let isError = false;

    setFormErrors({});

    if (attributeDataObj.course === "" || attributeDataObj.course === null) {
      errorObj = {
        ...errorObj,
        ["course"]: "Course is required",
      };
    } else {
      errorObj = {
        ...errorObj,
        ["course"]: "",
      };
    }

    //Checking Start date is greather End Date
    if (
      attributeDataObj.startDate !== undefined &&
      attributeDataObj.startDate !== null &&
      attributeDataObj.endDate !== undefined &&
      attributeDataObj.endDate !== null
    ) {
      if (
        moment(attributeDataObj.startDate).isAfter(attributeDataObj.endDate)
      ) {
        errorObj = {
          ...errorObj,
          ["startDate"]: "Start date must be smaller than End date.",
        };
      } else {
        errorObj = {
          ...errorObj,
          ["startDate"]: "",
        };
      }
    } else {
      errorObj = {
        ...errorObj,
        ["startDate"]: "",
      };
    }

    const isEmpty = Object.values(errorObj).every((s) => s === "");

    if (Object.keys(errorObj).length > 0 && !isEmpty) {
      isError = true;
      setFormErrors((err) => ({
        ...err,
        ...errorObj,
      }));
    }

    return isError;
  };

  const handleSubmit = async () => {
    showLoader();

    const isError = validateForm();

    if (isError) return;

    const response = await APICall(saveBulkSessionScheduleAttendance, "POST", {
      ParticipantsData: JSON.stringify(courseData),
      UserId: userDetails?.Id,
    });

    if (response?.status === 0) {
      getParticipantsAttendanceApiCall();
      notify(0, "Bulk Attendance status updated sucessfully");
    } else if (response?.status === 1) {
      notify(response?.status, response?.message);
    }
    hideLoader();
  };

  const handlePrint = () => {};

  return (
    <>
      <div className="breadcrumb modified-bredcrumb">
        <span>
          <ul>
            <li>
              <a href="/home">Home</a>
            </li>

            <li>Participant Course</li>
          </ul>
        </span>
      </div>

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
            <div className="form-group filtInp">
              <label className="col-form-label ">Course</label> <sup>*</sup>
              <SelectForm
                isClearable
                options={courseOptions}
                placeholder={"Select Course"}
                isDisabled={false}
                onChange={(event) => {
                  formOnChange(event, "course");
                }}
                isMulti={false}
                noIndicator={false}
                noSeparator={false}
                value={attributeDataObj.course}
              />
              {formErrors["course"] && (
                <p style={{ color: "red" }}>{formErrors["course"]}</p>
              )}
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
            <div className="form-group filtInp">
              <label className="col-form-label ">Session</label>
              <SelectForm
                isClearable
                options={sessionOptions}
                placeholder={"Select Session"}
                isDisabled={false}
                onChange={(event) => {
                  formOnChange(event, "session");
                }}
                isMulti={true}
                noIndicator={false}
                noSeparator={false}
                value={attributeDataObj.session}
              />
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
            <div className="form-group filtInp">
              <label className="col-form-label ">Course Schedule</label>
              <SelectForm
                isClearable
                options={CourseScheduleOption}
                placeholder={"Select Course Schedule "}
                isDisabled={false}
                onChange={(event) => {
                  formOnChange(event, "courseSchedule");
                  // handleDashboardData(event);
                }}
                isMulti={true}
                noIndicator={false}
                noSeparator={false}
                value={attributeDataObj.courseSchedule}
              />
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
            <div className="form-group filtInp">
              <label className="col-form-label ">Session Schedule</label>
              <SelectForm
                isClearable
                options={sessionScheduleOption}
                placeholder={"Select Session Schedule "}
                isDisabled={false}
                onChange={(event) => {
                  formOnChange(event, "sessionSchedule");
                  // handleDashboardData(event);
                }}
                isMulti={true}
                noIndicator={false}
                noSeparator={false}
                value={attributeDataObj.sessionSchedule}
              />
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
            <div className="form-group filtInp">
              <label className="col-form-label ">Start Date</label>
              <DateForm
                placeholder={"Start Date"}
                isDisabled={false}
                value={attributeDataObj.startDate}
                onChange={(event: any) => {
                  formOnChange(event, "startDate");
                }}
                maxDate={new Date()}
              />
              {formErrors["startDate"] && (
                <p style={{ color: "red" }}>{formErrors["startDate"]}</p>
              )}
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
            <div className="form-group filtInp">
              <label className="col-form-label ">End Date</label>
              <DateForm
                placeholder={"End Date"}
                isDisabled={false}
                value={attributeDataObj.endDate}
                onChange={(event: any) => {
                  formOnChange(event, "endDate");
                }}
                maxDate={new Date()}
              />
              {formErrors["endDate"] && (
                <p style={{ color: "red" }}>{formErrors["endDate"]}</p>
              )}
            </div>
          </div>
        </div>
        <div className="SectionSubmit mb-4 clearfix">
          <button
            onClick={() => {
              getParticipantsAttendancePrintApiCall();
            }}
            disabled={false}
            className="btn btn-secondary float-right ml-2"
          >
            <i className="fa-solid fa-print"></i> Print
          </button>

          <button
            onClick={() => {
              getParticipantsAttendanceApiCall();
            }}
            disabled={false}
            className="btn btn-filter-submit float-right ml-2"
          >
            <i className="fa-solid fa-search"></i> Search
          </button>
        </div>
        <div className="row mt-2">
          {courseData &&
            courseData.length > 0 &&
            courseData.map((courseData, index) => (
              <>
                <div className="col-lg-12">
                  <Accordion expanded elevation={0} className="mb-3">
                    <AccordionSummary
                      id="profile-accordion"
                      style={{ background: "#3C5464" }}
                      className="text-white acc_close"
                      expandIcon={<ExpandMore />}
                    >
                      <div className="row d-flex align-items-center acc-heading">
                        <div className="col-lg-10 col-md-10 col-sm-12">
                          <p style={{ fontSize: "16px" }}>
                            <Tooltip title={""}>
                              <span>
                                <input
                                  disabled={
                                    showCourseSchedule === true ? true : false
                                  }
                                  type="checkbox"
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    //setcourseIsChecked(e.target.checked);
                                    checkCourseCheckboxes(
                                      e.target.checked,
                                      courseData
                                    );
                                  }}
                                  checked={courseData?.CourseChecked}
                                />
                              </span>
                            </Tooltip>

                            <span>
                              {"  "}
                              {courseData?.CourseTitle}
                              {/* - {courseData.courseCode} */}
                            </span>
                          </p>
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails className="">
                      <div className="row">
                        <div className="col-lg-2">
                          <div className="card">
                            <ImageColumnComponent
                              link={courseData.ThumbnailUrl}
                              width="auto"
                            />
                          </div>
                        </div>
                        <div className="col-lg-10">
                          <div className="row fnt_we">
                            {/* <div className="col-lg-6">
                              <p>
                                <b className="wid_obj">
                                  <div className="lable_width ">Course ID</div>{" "}
                                  :
                                </b>{" "}
                                {courseData.CourseCode}
                              </p>
                            </div>
                            <div className="col-lg-6">
                              <p>
                                <b className="wid_obj">
                                  <div className="lable_width ">
                                    Course Title
                                  </div>{" "}
                                  :
                                </b>{" "}
                                {courseData.CourseTitle}
                              </p>
                            </div>

                            <div className="col-lg-12">
                              <p>
                                <b className="wid_obj">
                                  {" "}
                                  <div className="lable_width">
                                    Course Objective
                                  </div>{" "}
                                  :
                                </b>{" "}
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: courseData.LearningObjective,
                                  }}></span>
                              </p>
                            </div> */}
                            <div className="col-lg-12 p-0">
                              <CourseAccordionData courseData={courseData} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        {courseData?.CS &&
                          courseData?.CS.length > 0 &&
                          courseData?.CS.map((courseScheduleData, index) => (
                            <>
                              <div className="col-lg-12" key={index}>
                                <AccordianCustom2
                                  courseScheduleData={courseScheduleData}
                                  checkCourseScheduleCheckboxes={
                                    checkCourseScheduleCheckboxes
                                  }
                                  courseData={courseData}
                                  setCourseData={setCourseData}
                                  checkSessionScheduleCheckboxes={
                                    checkSessionScheduleCheckboxes
                                  }
                                />
                                {/* <Accordion elevation={0} className="mb-3" >
                                  <AccordionSummary
                                    id="profile-accordion"
                                    style={{ background: "#3C5464" }}
                                    className="text-white acc_close"
                                    expandIcon={<ExpandMore />}>
                                    <div className="row d-flex align-items-center acc-heading">
                                      <div className="col-lg-10 col-md-10 col-sm-12">
                                        <p style={{ fontSize: "16px" }}>
                                          <Tooltip title={""}>
                                            <span>
                                              <input
                                                disabled={showCourseSchedule === true ? true : false}
                                                type="checkbox"
                                                onChange={(e) => {
                                                  e.stopPropagation();
                                                  checkCourseScheduleCheckboxes(
                                                    e.target.checked,
                                                    courseScheduleData
                                                  );
                                                }}
                                                checked={
                                                  courseScheduleData?.CourseScheduleChecked
                                                }
                                              />
                                            </span>
                                          </Tooltip>

                                          <span>
                                            {"  "}
                                            {
                                              courseScheduleData?.CourseScheduleName
                                            }
                                          </span>

                                          <div className="float-right">
                                            <span>Course Sechedule</span>
                                            <Switch  onChange={(event)=>handleChange(event,"course",courseScheduleData?.CourseScheduleId)} checked={showCourseSchedule} />  
                                            <span className="ml-5">Session Sechedule</span>
                                            <Switch  onChange={(event)=>handleChange(event,"session","")} checked={showSessionSchedule} />  
                                                             
                                        </div>
                                        </p>
                                        
                                      </div>
                                    </div>
                                  </AccordionSummary>
                                  {showSessionSchedule === true ?                                   
                                  (<AccordionDetails className="">
                                    <div className="row"></div>
                                    <div className="row mt-2 ml-3">
                                      {courseScheduleData?.SS &&
                                        courseScheduleData?.SS.length > 0 &&
                                        courseScheduleData?.SS.map(
                                          (sessionScheduleData, index) => (
                                            <>
                                              <div className="col-lg-12">
                                                <SessionScheduleGrid
                                                  sessionScheduleData={
                                                    sessionScheduleData
                                                  }
                                                  checkSessionScheduleCheckboxes={
                                                    checkSessionScheduleCheckboxes
                                                  }
                                                  courseData={courseData}
                                                  setCourseData={setCourseData}
                                                />
                                              </div>
                                            </>
                                          )
                                        )}
                                    </div>
                                  </AccordionDetails>) :(
                                    <div className="row mt-2 ml-3">
                                    {courseScheduleData?.SS &&
                                      courseScheduleData?.SS.length > 0 &&
                                      courseScheduleData?.SS.map(
                                        (sessionScheduleData, index) => (
                                          <>
                                            <div className="col-lg-12" key={index}>
                                              <CourseScheduleGrid
                                                sessionScheduleData={
                                                  sessionScheduleData
                                                }
                                                checkSessionScheduleCheckboxes={
                                                  checkSessionScheduleCheckboxes
                                                }
                                                courseData={courseData}
                                                setCourseData={setCourseData}
                                              />
                                            </div>
                                          </>
                                        )
                                      )}
                                  </div>
                                  )
                                  }

                                </Accordion> */}
                              </div>
                            </>
                          ))}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </>
            ))}
        </div>

        {showSubmitButton && (
          <div className="SectionSubmit mb-4 clearfix">
            <button
              onClick={() => {
                handleSubmit();
              }}
              disabled={false}
              className="btn btn-filter-submit float-right ml-2"
            >
              <i className="fa-solid fa-check"></i> Submit
            </button>
          </div>
        )}
      </div>

      <div className="col-lg-12 mt-3">
        {/* <Accordion className="mb-3">
          <AccordionSummary
            id=""
            style={{ background: "#3C5464" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}
          >
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <p style={{ fontSize: "16px" }}>Participant List</p>
              </div>
              <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
            </div>
          </AccordionSummary>

          <AccordionDetails className="page_heading">
            <div className="mb-3 pt-3">
              <DynamicGrid
                data={dashboard}
                columns={gridColumns}
                options={dashboardOptions}
              />
            </div>

            <div className="d-flex align-items-center justify-content-end mb-3">
              <button
                className="btn btn-primary mt-3"
                onClick={exporttoexcel}
                disabled={isDisabled}
              >
                Export to excel
              </button>
            </div>
          </AccordionDetails>
        </Accordion> */}
        {/* {userAndRoleWiseData?.isEdit && ( */}
        {/* Will use later */}
        <Accordion className="mb-3">
          <AccordionSummary
            id=""
            style={{ background: "#3C5464" }}
            className="text-white acc_close"
            expandIcon={<ExpandMore />}
          >
            <div className="row d-flex align-items-center acc-heading">
              <div className="col-lg-10 col-md-10 col-sm-12">
                <p style={{ fontSize: "16px" }}>Bulk Attendance</p>
              </div>
              <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12"></div>
            </div>
          </AccordionSummary>

          <AccordionDetails className="page_heading">
            <div className="row">
              <div className={"col-lg-3 col-sm-3 col-xs-4"}>
                <div className="mb-1">
                  <label className="col-form-label">
                    {" "}
                    Manage Attendance Upload
                  </label>
                </div>
                {/* <InputForm
                  className="form-control"
                  placeholder="upload a file"
                  isDisabled={true}
                  onChange={(e) => {
                    // setfileName(e.target.value);
                  }}
                  textArea={false}
                  value={fileName}
                  maxLength="255"
                /> */}
                <span>{fileName}</span>
              </div>
              <div className={"col-lg-5 col-md-6 col-sm-6 col-xs-6 btnHeight"}>
                <div className="mb-1">
                  <label className="col-form-label mt-3"> </label>
                </div>
                <div
                  className="box position-relative"
                  style={{ width: "100px" }}
                >
                  <input
                    id={"REQSUPDOC"}
                    className="form-control inputfile inputfile-6 multiple-inputfile"
                    multiple={false}
                    type="file"
                    onChange={(e) => {
                      handleOnChange(e);
                    }}
                    ref={uploadFileRef}
                    accept={".xlsx"}
                    disabled={false}
                  />
                  <label
                    htmlFor={"REQSUPDOC"}
                    className="form-control"
                    style={{ width: 0, border: "none" }}
                  >
                    <strong
                      style={{
                        padding: "6px 16px",
                        backgroundColor: "#3c5464",
                        borderRadius: "5px",
                      }}
                    >
                      <i
                        className="fa fa-upload rotate90"
                        aria-hidden="true"
                      ></i>
                      {"  "}
                      Upload
                    </strong>{" "}
                  </label>
                </div>
                <div className="d-flex mt-2 manage">
                  <button
                    className="btn btn-primary mr-2"
                    disabled={false}
                    onClick={() => DownloadExcelTemplateApiCall()}
                  >
                    <i className="fa-solid fa-download"></i> Manage Download
                    Attendance
                  </button>
                </div>
              </div>
              <div className="col-lg-3 col-sm-3 col-xs-3">
                <div className="mt-2">
                  <label className="col-form-label"></label>
                </div>
              </div>
            </div>
            <div className="SectionSubmit mb-4 clearfix">
              <button
                onClick={() => {
                  onResetData();
                }}
                disabled={false}
                className="btn btn-secondary float-right ml-2"
              >
                <i className="fa-solid fa-refresh"></i> Reset
              </button>

              <button
                onClick={() => {
                  handleUpload();
                }}
                disabled={false}
                className="btn btn-filter-submit float-right ml-2"
              >
                <i className="fa-solid fa-check"></i> Execute
              </button>
            </div>
          </AccordionDetails>
        </Accordion>
        {/* Will use later */}
        {/* )} */}
      </div>
      {/* Validation Error */}
      <Modal
        show={showErrorModal}
        onHide={() => setShowErrorModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">Validation Error</div>
        </div>
        <Modal.Body>
          <>
            {errorData.length > 0 && (
              <div className="row my-2">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                  <DynamicGrid
                    data={errorData}
                    columns={errorDataColumns}
                    options={errorDataOptions}
                  />
                </div>
              </div>
            )}
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={(e) => {
              e.preventDefault();
              setShowErrorModal(false);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      {/* Print Model */}
      <Modal
        show={showPrintModel}
        onHide={() => setShowPrintModel(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">Print Attendance</div>
        </div>
        <Modal.Body>
          <>
            <div
              className="row my-2"
              style={{
                height: "500px",
                maxHeight: "500px",
                overflowY: "scroll",
              }}
            >
              <div
                className="col-lg-12 col-sm-12 col-xs-12"
                id="printAttendanceData"
              >
                {printAttendanceData}
                {/* <ComponentToPrint
                  ref={componentRef}
                  attendanceData={printAttendanceData}
                /> */}
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <div className="SectionSubmit mb-4 clearfix">
            <button
              onClick={() => {
                handlePrintData("printAttendanceData");
              }}
              disabled={false}
              className="btn btn-filter-submit float-right ml-2"
            >
              <i className="fa-solid fa-print"></i> Print
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                setShowPrintModel(false);
              }}
              disabled={false}
              className="btn btn-secondary float-right ml-2"
            >
              <i className="fa-solid fa-close"></i> Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Participant;
