import React, { useState, useEffect, useContext, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// import "./CoursesDashboard.css";
import Collapse from "react-bootstrap/Collapse";

import { APICall, APICallWithSignal } from "../../Helpers/APICalls";
import {
  DownloadExcelCourseTemplate,
  UploadCourseTemplate,
  deactivateCourseDetailById,
  getCourseDasboardDetails,
  getCourseDashboardFilterDropDownList,
  getLMSDomainDropDownData,
  getUserAndRoleWiseAccess,
  holdCourseDetails,
} from "../../Helpers/APIEndPoints/LMS_EndPoints";
import { Form } from "react-bootstrap";
import { Tooltip } from "@mui/material";
import { LoaderContext } from "../../Helpers/Context/Context";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import notify from "../../Helpers/ToastNotification";
import { OverlayTrigger, Popover } from "react-bootstrap";
import DynamicGrid from "../../Components/DynamicGrid/DynamicGrid";
import InputForm from "../../Components/InputForm/InputForm";
import SelectForm from "../../Components/SelectForm/SelectForm";
import ImageColumnComponent from "../../Components/LMS/ImageColumnComponent/ImageColumnComponent";
import {
  base64ToArrayBuffer,
  getBase64FromFilePromise,
} from "../../Helpers/Common";
import fileDownload from "js-file-download";
import moment from "moment";

const CoursesDashboard = () => {
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const navigate = useNavigate();
  let { userDetails, currentRoleId }: any = useUserContext();
  const [sortDirection, setSortDirection] = useState("");
  const [sortColumn, setSortColumn] = useState("");

  const [reSet, setreSet] = useState(false);

  const [courseId, setCourseId] = useState([]);
  const [courseIdOptions, setCourseIdOptions] = useState([]);

  const [courseTitle, setCourseTitle] = useState([]);
  const [courseTitleOptions, setCourseTitleOptions] = useState([]);

  const [createdBy, setCreatedBy] = useState([]);
  const [createdByOptions, setCreatedByOptions] = useState([]);

  const [courseType, setCourseType] = useState([]);
  const [courseTypeOptions, setCourseTypeOptions] = useState([]);

  const [status, setStatus] = useState(null);

  const [remarks, setRemarks] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [holdModel, setHoldModel] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [id, setId] = useState(null);
  const [IsActive, setIsActive] = useState(false);
  const [isHold, setIsHold] = useState(false);

  const [domain, setDomain] = useState(null);
  const [domainOptions, setDomainOptions] = useState([]);

  const [statusOptions, setStatusOptions] = useState([
    { value: 1, label: "Active" },
    { value: 0, label: "Inactive" },
  ]);

  useEffect(() => {
    const responseData = async () => {
      const objCourseId = {
        mode: "COURSECODE",
        userId: userDetails?.Id,
        roleId: currentRoleId?.value,
      };
      const res = await APICall(
        getCourseDashboardFilterDropDownList,
        "POST",
        objCourseId
      );
      if (res.data && res.data.length > 0) {
        setCourseIdOptions(res.data);
      }

      const objCourseTitle = {
        mode: "COURSETITLE",
        userId: userDetails?.Id,
        roleId: currentRoleId?.value,
      };
      const res1 = await APICall(
        getCourseDashboardFilterDropDownList,
        "POST",
        objCourseTitle
      );
      if (res1.data && res1.data.length > 0) {
        setCourseTitleOptions(res1.data);
      }

      const objCreatedBy = {
        mode: "CREATEDBY",
        userId: userDetails?.Id,
        roleId: currentRoleId?.value,
      };
      const res2 = await APICall(
        getCourseDashboardFilterDropDownList,
        "POST",
        objCreatedBy
      );
      if (res2.data && res2.data.length > 0) {
        setCreatedByOptions(res2.data);
      }

      const objCourseType = {
        mode: "COURSECATEGORY",
        userId: userDetails?.Id,
        roleId: currentRoleId?.value,
      };
      const res3 = await APICall(
        getCourseDashboardFilterDropDownList,
        "POST",
        objCourseType
      );
      if (res3.data && res3.data.length > 0) {
        setCourseTypeOptions(res3.data);
      }
    };

    responseData();
    getLMSDomainDropDownDataApiCall();
    getUserAndRoleWiseAccessApiCall();
  }, []);

  useEffect(() => {
    getCourseDasboardDetailsAPICall();
  }, [page, sortColumn, sortDirection, searchText, reSet]);

  const getCourseDasboardDetailsAPICall = async () => {
    showLoader();
    const GetDashboardData = await APICall(getCourseDasboardDetails, "POST", {
      Offset: page,
      PageSize: pageSize,
      SortColumn: sortColumn,
      SortOrder: sortDirection,
      SearchText: searchText,
      CourseCode: courseId?.map((s) => s.value).join(","),
      CourseTitle: courseTitle?.map((s) => s.value).join(","),
      CourseCreatedBy: createdBy?.map((s) => s.value).join(","),
      CourseCategory: courseType?.map((s) => s.value).join(","),
      DomainIds: domain?.map((s) => s.value).join(","),
      CourseIsactive: status?.value,
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
    });
    if (
      GetDashboardData &&
      GetDashboardData.data !== null &&
      GetDashboardData.data.length > 0
    ) {
      setCount(GetDashboardData.data[0].count);

      setRowData(GetDashboardData.data);
    } else {
      setRowData([]);
      setCount(0);
    }
    hideLoader();
  };

  const getLMSDomainDropDownDataApiCall = async () => {
    const response = await APICall(getLMSDomainDropDownData, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      GetAll: true,
    });

    if (response?.status === 0 && response?.data.length > 0) {
      setDomainOptions(response?.data);
    }
  };

  interface userAndRoleWiseAccess {
    isView?: boolean;
    isEdit?: boolean;
    isDelete?: boolean;
  }

  const [userAndRoleWiseData, setUserAndRoleWiseData] =
    useState<userAndRoleWiseAccess>({});

  const getUserAndRoleWiseAccessApiCall = async () => {
    const response = await APICall(getUserAndRoleWiseAccess, "POST", {
      UserId: userDetails?.Id,
      RoleId: currentRoleId?.value,
      InterfaceName: "Courses_Dashboard",
    });

    if (response?.status === 0) {
      setUserAndRoleWiseData(response?.data);
    } else {
      notify(response?.status, response?.message);
    }
  };

  const activateandDeactivateFunction = async (id: any, isActive: any) => {
    if (remarks === "" || remarks === null) {
      notify(1, "Please enter remarks");
    } else {
      showLoader();
      const { data } = await APICall(deactivateCourseDetailById, "POST", {
        Id: id,
        IsActive: isActive,
        Remarks: remarks,
        CreatedBy: userDetails?.Id,
      });

      setShowModal(false);
      setRemarks("");
      if (data != null) {
        if (!isActive) {
          notify(0, "Course has been successfully Activated");
        } else {
          notify(0, "Course has been successfully Deactivated");
        }
        setreSet(!reSet);
        hideLoader();
      } else {
        notify(1, "Something went wrong");
      }
      hideLoader();
    }
  };

  const holdAndReleaseFunction = async (id: any, isHold: any) => {
    if (remarks === "" || remarks === null) {
      notify(1, "Please enter remarks");
    } else {
      showLoader();
      const { data } = await APICall(holdCourseDetails, "POST", {
        Id: id,
        Hold: isHold,
        Remarks: remarks,
        CreatedBy: userDetails?.Id,
      });

      setHoldModel(false);
      setRemarks("");
      if (data != null) {
        if (!isHold) {
          notify(0, "Course released Successfully");
        } else {
          notify(0, "Course hold Successfully");
        }
        setreSet(!reSet);
        hideLoader();
      } else {
        notify(1, "Something went wrong");
      }
      hideLoader();
    }
  };

  const holduserbyIdmodal = async (id: any, isHold: any) => {
    setHoldModel(true);
    if (!isHold) {
      setModalTitle("Do you want to release the Course?");
    } else {
      setModalTitle("Do you want to hold the Course?");
    }
    setId(id);
    setIsHold(isHold);
  };

  const showActivateandDeactivateModal = async (id: any, isActive: any) => {
    setShowModal(true);
    setId(id);
    if (isActive) {
      setModalTitle("Do you want to Inactive the Course?");
    } else {
      setModalTitle("Do you want to active the Course?");
    }
    setIsActive(isActive);
  };

  const options = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    count: count,
    rowsPerPage: pageSize,
    serverSide: true,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    onColumnSortChange: async (sortColumn, sortDirection) => {
      if (sortDirection === "asc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
      if (sortDirection === "desc") {
        await setSortColumn(sortColumn);
        await setSortDirection(sortDirection);
      }
    },
    onChangePage: async (page) => {
      setPage(page * pageSize);
    },
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  const gridColumns = [
    {
      name: "Id",
      label: "Id",
      options: {
        display: false,
      },
    },
    {
      name: "thumbnailUrl",
      label: "Thumbnail",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: false,
        customBodyRender: (value, tableMeta) => {
          return <ImageColumnComponent link={value} />;
        },
      },
    },
    {
      name: "courseCode",
      label: "Course ID",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "courseTitle",
      label: "Course Title",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "courseCategory",
      label: "Course Category",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "launchStatus",
      label: "Launch",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "domainName",
      label: "Domain",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "createdByName",
      label: "Created By",
      options: {
        filter: false,
        sort: true,
        sortDescFirst: true,
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        display: true,
        setCellProps: () => ({
          style: { textAlign: "left" },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta) => {
          let id = tableMeta.tableData[tableMeta.rowIndex].id;
          let isActive = tableMeta.tableData[tableMeta.rowIndex].isActive;
          let color = isActive ? "green" : "red";
          let activate = isActive ? "Active" : "Inactive";

          let hold = tableMeta.tableData[tableMeta.rowIndex].hold;
          let hold1 = hold ? "fa fa-play" : "fa fa-pause";
          let color1 = hold ? "green" : "red";
          let status = hold ? "Release" : "Hold";
          let launched = tableMeta.tableData[tableMeta.rowIndex].launched;

          return (
            <div style={{ width: "100px" }}>
              {userAndRoleWiseData?.isEdit && (
                <>
                  <Tooltip title="Edit">
                    <a
                      className="mr-2"
                      style={{ fontSize: "15px" }}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/Courses", {
                          state: { courseId: value, isDisable: false },
                        });
                      }}
                    >
                      <i className="fas fa-edit"></i>
                    </a>
                  </Tooltip>
                  <Tooltip title={activate}>
                    <a
                      className="mr-2"
                      style={{ fontSize: "15px" }}
                      onClick={() => {
                        showActivateandDeactivateModal(id, isActive);
                      }}
                    >
                      <i
                        className="fa-solid fa-circle"
                        style={{ color: color }}
                      ></i>
                    </a>
                  </Tooltip>
                  {/* {launched && (
                    <Tooltip title={status}>
                      <a
                        className="mr-2"
                        style={{ fontSize: "15px" }}
                        onClick={() => {
                          holduserbyIdmodal(id, !hold);
                        }}
                      >
                        <i className={hold1} style={{ color: color1 }}></i>
                      </a>
                    </Tooltip>
                  )} */}
                </>
              )}

              {!userAndRoleWiseData?.isEdit && userAndRoleWiseData?.isView && (
                <Tooltip title="View">
                  <a
                    className="mr-2"
                    style={{ fontSize: "15px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/Courses", {
                        state: { courseId: value, isDisable: true },
                      });
                    }}
                  >
                    <i className="fas fa-eye"></i>
                  </a>
                </Tooltip>
              )}
            </div>
          );
        },
      },
    },
  ];

  const onSubmitFilter = () => {
    if (
      courseId.length === 0 &&
      courseTitle.length === 0 &&
      createdBy.length === 0 &&
      courseType.length === 0 &&
      status === null &&
      domain === null
    ) {
      notify(
        1,
        "Please select at least one filter option  to perform the search action."
      );
    } else {
      setPage(0);
      setreSet(!reSet);
    }
  };

  const resetFilters = () => {
    setSearchText("");
    setCourseId([]);
    setCourseTitle([]);
    setCourseType([]);
    setCreatedBy([]);
    setDomain([]);

    setStatus(null);

    setPage(0);

    setreSet(!reSet);
  };

  // bulk upload
  const [bulkUploadModal, setBulkUploadModal] = useState(false);

  const [file, setFile] = useState(null);
  const fileRef = useRef(null);
  const [isValidated, setIsValidated] = useState(false);
  const [showFileError, setShowFileError] = useState(false);
  const [errorData, setErrorData] = useState([]);

  function handleOnChange(event, code) {
    switch (code) {
      case "file":
        const files = event.target.files[0] || null;
        if (!files) {
          fileRef.current.value = null;
          setErrorData([]);
          setFile(null);
          notify(1, "Please select a file.");
          setIsValidated(false);
          return;
        }

        // Check if the selected file is an Excel file
        if (!files.name.endsWith(".xls") && !files.name.endsWith(".xlsx")) {
          fileRef.current.value = null;
          setErrorData([]);
          setFile(null);
          notify(1, "Please upload an Excel file.");
          setIsValidated(false);
          return;
        }
        setFile(files);
        setIsValidated(false);
        setErrorData([]);
        break;
      default:
        break;
    }
  }

  async function handleSave(validate) {
    try {
      showLoader();

      if (file) {
        let base64 = await getBase64FromFilePromise(file);
        base64 = String(base64).split("base64,")[1];

        const response = await APICall(UploadCourseTemplate, "POST", {
          CreatedBy: userDetails?.Id,
          ValidationOnly: validate,
          ExcelFileBase64: base64,
        });

        if (response?.status == 0) {
          notify(
            0,
            validate ? "Validated Successfully" : "Uploaded Successfully"
          );
          setIsValidated(true);
          if (!validate) {
            setFile(null);
            fileRef.current.value = null;
            setErrorData([]);
            setBulkUploadModal(false);
          }
        } else if (response?.status == 5) {
          const { data } = response;

          if (data) {
            const fileName = "InvalidCourseTempate_.xlsx";
            const base64 = data;

            let arrayBuffer = await base64ToArrayBuffer(base64);
            var byteArray = new Uint8Array(arrayBuffer);

            let blob = new Blob([byteArray], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            const newFile = new File([blob], "", {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              lastModified: new Date().getTime(),
            });

            fileDownload(newFile, fileName);

            const excelErrorMessage = JSON.parse(response?.message);

            const errorMessage = () => {
              if (
                Array.isArray(excelErrorMessage) &&
                excelErrorMessage.length > 0
              ) {
                setErrorData(excelErrorMessage);
              } else {
                return excelErrorMessage;
              }
            };

            notify(1, errorMessage());

            setFile(null);

            fileRef.current.value = null;
          } else {
            setFile(null);
            notify(1, response?.message);
            fileRef.current.value = null;
            setErrorData([]);
          }
          setIsValidated(false);
        } else {
          notify(1, "Something went wrong.");
          fileRef.current.value = null;
          setErrorData([]);
          setFile(null);
          setIsValidated(false);
        }
      } else {
        notify(1, "Please upload an Excel file.");
        setIsValidated(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  }

  const DownloadExcelTemplateApiCall = async () => {
    try {
      showLoader();
      const response = await APICall(DownloadExcelCourseTemplate, "POST", {});

      if (response?.status == 0) {
        const { excelFileBase64 } = response?.data;

        if (excelFileBase64) {
          const fileName = `CourseTempate_${moment().format(
            "DD-MM-YYYY HH:mm:ss"
          )}.xlsx`;
          const base64 = excelFileBase64;

          let arrayBuffer = await base64ToArrayBuffer(base64);
          var byteArray = new Uint8Array(arrayBuffer);

          let blob = new Blob([byteArray], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          const newFile = new File([blob], "", {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            lastModified: new Date().getTime(),
          });

          fileDownload(newFile, fileName);
        }
      } else {
        notify(1, "Something went wrong, Please Try again Later.");
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const errorDataColumns = [
    {
      name: "Row",
      label: "Row",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: false,
      },
    },
    {
      name: "Column",
      label: "Column",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: false,
      },
    },
    {
      name: "ErrorMessage",
      label: "Error Message",
      options: {
        filter: false,
        sort: false,
        sortDescFirst: false,
      },
    },
  ];

  const errorDataOptions = {
    showEmptyDataSourceMessage: true,
    selectableRows: "none",
    rowsPerPage: 5,
    serverSide: false,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    search: false,
    responsive: "standard",

    //standard | vertical | simple
    textLabels: {
      body: {
        noMatch: "No data found",
      },
    },
  };

  return (
    <>
      <div className="breadcrumb modified-bredcrumb">
        <span>
          <ul>
            <li>
              <a href="/home">Home</a>
            </li>
            <li>Courses</li>
          </ul>
        </span>
        {userAndRoleWiseData?.isEdit && (
          <div>
            <button
              onClick={() => {
                navigate("/Courses", { state: { courseId: 0 } });
              }}
              className="btn btn-primary mt-3"
            >
              <i className="fa fa-plus mr-2"></i>
              Create Course
            </button>
            <button
              onClick={() => setBulkUploadModal(true)}
              className="btn btn-primary ml-2 mt-3"
            >
              Bulk Upload
            </button>
          </div>
        )}
      </div>
      <div className="container-fluid">
        <div className="grid-wrapper grid_mob">
          <div className="row mx-auto filtBox">
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div className="form-group filtInp">
                <InputForm
                  value={searchText}
                  placeholder={"Search Keywords"}
                  isDisabled={false}
                  textArea={false}
                  onChange={(e) => {
                    setPage(0);
                    setSearchText(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-12 filtrt">
              <ul className=" filter-icon-wel">
                <li>
                  <a
                    href="javascript:void(0);"
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open}
                    className="filter-btn"
                  >
                    <i className="fa fa-filter"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <>
          <Collapse in={open}>
            <div id="example-collapse-text grid-wrapper">
              <div className="row mx-auto myInlineStyle">
                <>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">
                      Course ID
                    </label>
                    <SelectForm
                      isClearable
                      options={courseIdOptions}
                      placeholder={"Course ID"}
                      isDisabled={false}
                      onChange={(event) => {
                        setCourseId(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={courseId}
                    />
                  </div>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">
                      Course Title
                    </label>
                    <SelectForm
                      isClearable
                      options={courseTitleOptions}
                      placeholder={"Course Title"}
                      isDisabled={false}
                      onChange={(event) => {
                        setCourseTitle(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={courseTitle}
                    />
                  </div>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">
                      Created By
                    </label>
                    <SelectForm
                      isClearable
                      options={createdByOptions}
                      placeholder={"Created By"}
                      isDisabled={false}
                      onChange={(event) => {
                        setCreatedBy(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={createdBy}
                    />
                  </div>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">
                      Course Type
                    </label>
                    <SelectForm
                      isClearable
                      options={courseTypeOptions}
                      placeholder={"Course Type"}
                      isDisabled={false}
                      onChange={(event) => {
                        setCourseType(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={courseType}
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">Status</label>
                    <SelectForm
                      isClearable
                      options={statusOptions}
                      placeholder={"Status"}
                      isDisabled={false}
                      onChange={(event) => {
                        setStatus(event);
                      }}
                      isMulti={false}
                      noIndicator={false}
                      noSeparator={false}
                      value={status}
                    />
                  </div>
                  <div className="dropdown col-lg-3 col-md-6 col-sm-12 mb-3">
                    <label className="col-form-label text-white">Domain</label>
                    <SelectForm
                      isClearable
                      options={domainOptions}
                      placeholder={" Domain"}
                      isDisabled={false}
                      onChange={(event) => {
                        setDomain(event);
                      }}
                      isMulti={true}
                      noIndicator={false}
                      noSeparator={false}
                      value={domain}
                    />
                  </div>
                </>
                <div className="col-lg-12 d-flex mt-2 justify-content-end">
                  <button
                    onClick={() => resetFilters()}
                    className="btn btn-secondary mr-2"
                  >
                    <i className="fa fa-undo" aria-hidden="true"></i> Reset{" "}
                  </button>
                  <button
                    className="btn btn-filter-submit"
                    onClick={() => onSubmitFilter()}
                  >
                    <i className="fa fa-search" aria-hidden="true"></i> Search{" "}
                  </button>
                </div>
              </div>
            </div>
          </Collapse>
        </>

        <div className="mb-3 pt-3">
          <DynamicGrid data={rowData} columns={gridColumns} options={options} />
        </div>
      </div>

      <Modal
        show={bulkUploadModal}
        onHide={() => setBulkUploadModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">Bulk Upload</div>
        </div>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-4 col-sm-4 col-xs-6">
              <button
                className="btn btn-primary mt-2"
                disabled={false}
                onClick={() => DownloadExcelTemplateApiCall()}
              >
                Download Course Template
              </button>
            </div>

            <div className="col-lg-3 col-sm-3 col-xs-4">
              <div className="mt-2">
                <div className="box position-relative">
                  <input
                    id="file"
                    className="form-control inputfile inputfile-6 multiple-inputfile"
                    data-multiple-caption="{count} files selected"
                    multiple={false}
                    type="file"
                    onChange={(e) => {
                      handleOnChange(e, "file");
                    }}
                    accept={".xlsx,.xls"}
                    ref={fileRef}
                    value={""}
                  />
                  <label
                    htmlFor={`file`}
                    className="form-control"
                    style={{ width: 0, border: "none" }}
                  >
                    <strong
                      style={{
                        padding: "6px 16px",
                        backgroundColor: "#3c5464",
                        borderRadius: "5px",
                      }}
                    >
                      <i
                        className="fa fa-upload rotate90"
                        aria-hidden="true"
                      ></i>{" "}
                      Upload
                    </strong>
                  </label>
                </div>

                <div className="file-added-list">
                  <ul className="list-unstyle">
                    <li className="list mt-1">
                      <div className="media">
                        <div className="media-body text-truncate">
                          <span className="view-more">{file?.name}</span>
                        </div>
                        {file !== null && (
                          <p>
                            <a
                              onClick={() => {
                                setFile(null);
                                setIsValidated(false);
                                fileRef.current.value = null;
                                setErrorData([]);
                              }}
                            >
                              <i className="fa fa-trash" aria-hidden="true"></i>
                            </a>
                          </p>
                        )}
                      </div>
                    </li>
                  </ul>
                </div>
                {showFileError && (
                  <span style={{ color: "red" }}>Required</span>
                )}
              </div>
            </div>
          </div>

          {errorData.length > 0 && (
            <div className="row my-2">
              <div className="col-lg-12 col-sm-12 col-xs-12">
                <DynamicGrid
                  data={errorData}
                  columns={errorDataColumns}
                  options={errorDataOptions}
                />
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => {
              handleSave(true);
            }}
          >
            Validate
          </button>
          <button
            disabled={!isValidated}
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => {
              handleSave(false);
            }}
          >
            Submit
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setFile(null);
              setIsValidated(false);
              fileRef.current.value = null;
              setErrorData([]);
              setBulkUploadModal(false);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">{`${modalTitle}`}</div>
        </div>
        <Modal.Body>
          <>
            <div className="row">
              {/* <div className="col-md-12">
                <b>{`${modalTitle}`}</b>
              </div> */}
              <div className="col-md-8">
                <label className="col-form-label">Remarks</label>
                <sup>*</sup>
                <InputForm
                  value={remarks}
                  placeholder={"Remarks"}
                  isDisabled={false}
                  textArea={true}
                  onChange={(e) => {
                    setRemarks(e.target.value);
                  }}
                />
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => activateandDeactivateFunction(id, IsActive)}
          >
            Submit
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowModal(false);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={holdModel}
        onHide={() => setHoldModel(false)}
        backdrop="static"
        keyboard={false}
        size="sm"
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">{`${modalTitle}`}</div>
        </div>
        <Modal.Body>
          <>
            <div className="row">
              <div className="col-md-8">
                <label className="col-form-label">Remarks</label>
                <sup>*</sup>
                <InputForm
                  value={remarks}
                  placeholder={"Remarks"}
                  isDisabled={false}
                  textArea={true}
                  onChange={(e) => {
                    setRemarks(e.target.value);
                  }}
                />
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            style={{ marginLeft: 5 }}
            onClick={() => holdAndReleaseFunction(id, isHold)}
          >
            Submit
          </button>
          <button
            className="btn btn-cancel"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setHoldModel(false);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CoursesDashboard;
