import React from 'react';
import { useState, useEffect } from "react";
import { APICallWithSignal } from "../../../Helpers/APICalls";
import LazyLoad from "react-lazyload";
import defaultProfileImgPic from "../../../Assets/Images/profile.jpeg";
import { Spinner } from "react-bootstrap";
import { GetThumbnailFromSharepointUsingPath } from "../../../Helpers/APIEndPoints/LMS_EndPoints";

const ImageProfile = ({ link, width = "100px" }) => {
    const [base64Image, setBase64Image] = useState(null);
    const [isError, setIsError] = useState(false);
  
    useEffect(() => {
      const abortController = new AbortController();
      const signal = abortController.signal;
  
      const fetchData = async () => {
        if (link) {
          await fetchImage(signal);
        }
      };
  
      fetchData();
  
      return () => {
        abortController.abort();
      };
    }, [link]);
  
    const fetchImage = async (signal) => {
      try {
        // Make your API call to convert the link to base64
        const response = await APICallWithSignal(
          GetThumbnailFromSharepointUsingPath,
          "POST",
          { ThumbnailUrl: link },
          true,
          null,
          signal
        );
  
        if (response?.status == 0) {
          setBase64Image(response?.data);
          setIsError(false);
        } else {
          setIsError(true);
        }
      } catch (error) {
        console.error("Error fetching image data:", error);
      }
    };
  
    return (
      <LazyLoad height={200} offset={100}>
        {isError ? (
          <>
            <span>Failed to Load Image</span>
          </>
        ) : base64Image || !link ? (
          <>
            <div className="card" style={{ width: width }}>
              <img
                src={
                  !link
                    ? defaultProfileImgPic
                    : `data:image/png;base64,${base64Image}`
                }
                className="card-img-top"
                alt="Thumbnail Image"
              />
            </div>
          </>
        ) : (
          <div
            className="card"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Spinner
              className="card-img-top"
              animation="border"
              variant="secondary"
            />
          </div>
        )}
      </LazyLoad>
    );
}

export default ImageProfile