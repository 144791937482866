import React, { useContext, useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import InputForm from "../../Components/InputForm/InputForm";
import { APICall } from "../../Helpers/APICalls";
import { LoaderContext } from "../../Helpers/Context/Context";
import {
  getGetDeclartionQuestionForAgency,
  insertIntoCandidateDeclartion,
} from "../../Helpers/APIEndPoints/EndPoints";
import notify from "../../Helpers/ToastNotification";
import { useLocation } from "react-router-dom";
import useUserContext from "../../Helpers/ZustandStore/UserContextStore";
import useMrfAnonymousCandidateDeclarationStore from "../../Helpers/ZustandStore/MrfAnonymousCandidateDeclarationStore";
import { Modal } from "react-bootstrap";

export const DeclartionFormSection = ({
  setAccordion,
  accordion,
  reload,
  setReload,
  isDisableApplication,
  TId,
  WorkflowTaskId,
  setWorkflowTaskId,
  setShowSection,
  isAnonymousCandidate,
  setDisableBeforeDeclaration,
  setRejectCandidate,
  isIJP,
  isSection,
}) => {
  const [declarationQuestions, setDeclartionQuestions] = useState<any>([]);
  const [declarationQuestionsAnswers, setDeclartionQuestionsAnswers] =
    useState<any>({});
  const [declarationQuestionError, setDeclartionQuestionError] = useState<any>(
    {}
  );
  const [confirmCheckboxValue, setConfirmCheckboxValue] = useState(false);
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [disableDeclartionForm, setDisableDeclartionForm] = useState(false);

  const { showLoader, hideLoader } = useContext(LoaderContext);
  let { userDetails, currentRoleId }: any = useUserContext();
  const { state } = useLocation();
  const { declaration, addDeclaration, addQuesiton }: any =
    useMrfAnonymousCandidateDeclarationStore();

  // no data isDisableApplication

  useEffect(() => {
    (async () => {
      if (!isIJP) {
        setDisableDeclartionForm(isDisableApplication);
        await handleFetchDeclartionQuestions();
      } else {
        if (isSection) {
          if (!isDisableApplication) {
            setDisableBeforeDeclaration(false);
          }
          setDisableDeclartionForm(true);
          setDisabledSubmitBtn(true);
        }
      }
    })();
    return () => {
      //clear declaration
      addDeclaration(null);
      addQuesiton(null);
    };
  }, []);

  const handleFetchDeclartionQuestions = async () => {
    try {
      showLoader();
      const { data } = await APICall(
        getGetDeclartionQuestionForAgency,
        "POST",
        { Id: TId, CandidateUserId: userDetails ? userDetails.Id : 0 }
      );
      if (data && data?.length > 0) {
        let grouped = {};
        data.map((item) => {
          const id = item.Id;
          if (!grouped[id]) {
            grouped[id] = {
              ...item,
              RadioOptions: [],
            };
          }
          grouped[id].RadioOptions.push({
            SQANS: item.SQANS,
            AnswerId: item.AnswerId,
            SQADDTXTB: String(item.SQADDTXTB).toLowerCase() == "true",
            SQCODANS: item.SQCODANS,
          });
        });

        let result: any = Object.values(grouped);

        result.sort((a, b) => {
          const aValue = a.SQQUESEQ;
          const bValue = b.SQQUESEQ;

          return aValue.localeCompare(bValue);
        });

        setDeclartionQuestions(result);

        let temp = {};
        for (const item of result) {
          temp[item.Id] = {
            questionId: item.Id,
            answerId: null,
            answerType: null,
            textValue: "",
            showTxtLabel: false,
            questionType: item.SQQUESType,
            MRScreeningQuestionAnswerMappingId:
              item.MRScreeningQuestionAnswerMappingId,
          };
          if (
            item?.MRScreeningQuestionAnswerMappingId != null &&
            item?.MRScreeningQuestionAnswerMappingId != undefined &&
            item?.MRScreeningQuestionAnswerMappingId != 0
          ) {
            temp[item.Id].answerId = item.MRScreeningQuestionAnswerMappingId;
            let x = item.RadioOptions.find(
              (k) => k.AnswerId == temp[item.Id].answerId
            );

            if (x.SQADDTXTB == true) {
              temp[item.Id].showTxtLabel = true;
              temp[item.Id].textValue = item.SQTXTLBLAnswer;
            }

            setDisableDeclartionForm(true);
            setDisabledSubmitBtn(true);
            setDisableBeforeDeclaration(false);
          } else {
            if (!disableDeclartionForm) {
              setDisableDeclartionForm(false);
            }
          }
        }

        setDeclartionQuestionsAnswers(temp);
      } else {
        setConfirmCheckboxValue(true);
        setDisableDeclartionForm(true);
        setDisabledSubmitBtn(true);
        setDisableBeforeDeclaration(false);
      }
      hideLoader();
    } catch (error) {
      console.error(error);
      hideLoader();
    }
  };

  const handleSubmitDeclartionForm = async () => {
    let errorObj = {};
    Object.values(declarationQuestionsAnswers).forEach((obj) => {
      if (!obj["answerId"]) {
        errorObj[`error_${obj["questionId"]}`] = true;
      }
    });

    if (Object.keys(errorObj).length > 0 && !isIJP) {
      notify(1, "Answer all the question");
      return;
    }

    if (disableDeclartionForm) return;

    if (disabledSubmitBtn) return;

    if (!confirmCheckboxValue) {
      setErrorMessage(
        "Please check the box to confirm the accuracy of your answers."
      );
      return;
    }
    setErrorMessage("");

    let arr = [];
    Object.keys(declarationQuestionsAnswers).forEach((key) => {
      const value = declarationQuestionsAnswers[key];
      arr.push({
        CandidateUserId: userDetails ? userDetails.Id : 0,
        MRScreeningQuestionAnswerMappingId: value.answerId,
        ManpowerRequisitionScreeningQuestionId: value.questionId,
        SQTXTLBLAnswer: value.textValue,
        CreatedBy: userDetails ? userDetails.Id : 0,
      });
    });

    // should disable candidate
    let rejectCandidate = false;
    if (Object.keys(declarationQuestionsAnswers).length > 0) {
      Object.values(declarationQuestionsAnswers).some((ans: any) => {
        if (
          ans.questionType === "ExceptionRestricted" &&
          ans.answerType === false
        ) {
          setRejectCandidate(true);
          rejectCandidate = true;
        }
      });
    }

    if (isAnonymousCandidate || isIJP) {
      addDeclaration(arr);
      addQuesiton(declarationQuestions);
      setDisableDeclartionForm(true);
      setDisabledSubmitBtn(true);
      notify(0, "Data saved successfully.");
      // enable submit button
      setDisableBeforeDeclaration(false);
      return null;
    }

    const response = await APICall(insertIntoCandidateDeclartion, "POST", {
      CandidateDeclartionAnswerMappingList: arr,
      CreatedBy: userDetails.Id || 0,
      MRFId: TId || 0,
      WorkflowTaskId: WorkflowTaskId || 0,
    });

    if (response?.status == 0) {
      setWorkflowTaskId(0);
      await handleFetchDeclartionQuestions();
      setReload(!reload);
      setShowSection(true);
      setDisabledSubmitBtn(true);
      notify(0, "Data saved successfully.");
      // enable submit button
      setDisableBeforeDeclaration(false);
      if (rejectCandidate) {
        // reject candidate
        // api to reject candidate
      }
    } else if (response?.status == 1) {
      notify(1, "Something went wrong, Please try again later.");
      setShowSection(false);
    }
  };

  const handleFetchDeclartionQuestionsEvent = (
    event,
    showTxtLabel,
    answerType,
    Id
  ) => {
    let { name, value } = event.target;

    let errorObj = { ...declarationQuestionError };

    Object.values(declarationQuestionsAnswers).forEach((obj) => {
      if (!obj["answerId"]) {
        errorObj[`error_${obj["questionId"]}`] = true;
      }
    });

    let tempObj = errorObj;
    delete tempObj[`error_${Id}`];

    let filterObj = declarationQuestionsAnswers[Id];

    if (filterObj.questionType === "ExceptionWarning" && answerType != true) {
      notify(1, "Exception Warning");
    }

    // if (filterObj.questionType == "ExceptionRestricted" && answerType != true) {
    //   notify(1, "Exception Restricted");
    //   tempObj[Id] = "ExceptionRestricted";
    // } else {
    //   delete tempObj[Id];
    // }

    if (Object.keys(tempObj).length === 0) {
      setDisabledSubmitBtn(false);
    } else {
      setDisabledSubmitBtn(true);
    }

    setDeclartionQuestionError(tempObj);
    setDeclartionQuestionsAnswers((prev: any) => {
      return {
        ...prev,
        [name]: {
          ...prev[name],
          answerId: value,
          showTxtLabel,
          textValue: "",
          answerType,
        },
      };
    });
  };

  const [showDeclareModal, setShowDeclareModal] = useState(false);

  return (
    <React.Fragment>
      <Accordion
        elevation={0}
        className="mb-3"
        expanded={accordion}
        onChange={() => setAccordion((prev) => !prev)}
      >
        <AccordionSummary
          id="profile-accordion"
          style={{ background: "#3C5464" }}
          className="text-white acc_close"
          expandIcon={<ExpandMore />}
        >
          <div className="row d-flex align-items-center acc-heading">
            <div className="col-lg-10 col-md-10 col-sm-12">
              <p style={{ fontSize: "16px" }}>Declaration</p>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="row">
            <>
              {!isIJP &&
                declarationQuestions.map((item, index) => {
                  return (
                    <div className="col-lg-12 my-1" key={index}>
                      <div className="form-group">
                        <label className="form-label font-weight-bold">
                          {item.SQQUES}
                        </label>

                        {item.RadioOptions.map((radioObj, key_2) => (
                          <React.Fragment key={key_2}>
                            <div className="form-check my-2 ml-4">
                              <input
                                name={item.Id}
                                value={radioObj.AnswerId}
                                className="form-check-input"
                                type="radio"
                                id={`${item.Id}${key_2}`}
                                checked={
                                  declarationQuestionsAnswers[item.Id]
                                    ?.answerId == radioObj.AnswerId
                                }
                                onChange={(event) =>
                                  handleFetchDeclartionQuestionsEvent(
                                    event,
                                    radioObj.SQADDTXTB,
                                    radioObj.SQCODANS,
                                    item.Id
                                  )
                                }
                                disabled={disableDeclartionForm}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`${item.Id}${key_2}`}
                              >
                                {radioObj.SQANS}
                              </label>
                            </div>
                          </React.Fragment>
                        ))}

                        {declarationQuestionsAnswers[item.Id]?.showTxtLabel && (
                          <div className="form-group col-lg-4">
                            <label className="form-label ml-2 font-weight-bold">
                              {item.SQTXTLBL}
                            </label>
                            <InputForm
                              className="form-control ml-1"
                              placeholder={""}
                              isDisabled={disableDeclartionForm}
                              textArea={false}
                              value={
                                declarationQuestionsAnswers[item.Id]?.textValue
                              }
                              name={item.Id}
                              onChange={(e) => {
                                const { value, name } = e.target;
                                setDeclartionQuestionsAnswers((prev: any) => {
                                  return {
                                    ...prev,
                                    [name]: {
                                      ...prev[name],
                                      textValue: e.target.value,
                                    },
                                  };
                                });
                              }}
                              maxLength="255"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      disableDeclartionForm
                        ? disableDeclartionForm
                        : confirmCheckboxValue
                    }
                    id="confirmCheckboxValue"
                    onChange={(e) => {
                      setConfirmCheckboxValue(e.target.checked);
                      setErrorMessage("");
                    }}
                    disabled={disableDeclartionForm}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="confirmCheckboxValue"
                  >
                    {isIJP
                      ? "I have read & understood the WeRise guidelines & qualifying all the required parameters."
                      : `I confirm the answer provided above are correct and accurate
                    to the best of my knowledge.`}
                    <sup>*</sup>
                  </label>
                </div>
                <span style={{ color: "red" }} className="form-check-label">
                  {errorMessage}
                </span>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="float-right col-sm-offset-2">
                <button
                  type="button"
                  disabled={disabledSubmitBtn}
                  className="btn btn-create btn btn-primary"
                  onClick={(e) => {
                    // handleSubmitDeclartionForm(e);
                    setShowDeclareModal(true);
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      <Modal
        show={showDeclareModal}
        onHide={() => setShowDeclareModal(false)}
        size="lg"
        backdrop="static"
        keyboard={false}
        centered
      >
        <div
          className="bg-secondary text-white"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <div className="col-lg-12">Confirm</div>
        </div>
        <Modal.Body>
          <div className="col-lg-6">
            <p>Do you want to submit?</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            disabled={disabledSubmitBtn}
            style={{ marginLeft: 5 }}
            onClick={() => {
              handleSubmitDeclartionForm();
              setShowDeclareModal(false);
            }}
          >
            Yes
          </button>
          <button
            className="btn btn-danger"
            style={{ marginLeft: 5 }}
            onClick={() => {
              setShowDeclareModal(false);
            }}
          >
            No
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
