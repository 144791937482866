import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  TextField,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

const AccordianCustom = ({
  header,
  clearFunc,
  submitFunc,
  btnDisable = false,
  children,
  hideBtn = false,
  canRestore = false,
  handleScheduleRestore = null,
  handleScheduleCopy = null,
  handleScheduleDelete = null,
  sessionScheduleId = null,
  isTrainerMandetory = false,
}) => {
  const [accordion, setAccordion] = useState<boolean>(true);

  return (
    <Accordion
      elevation={0}
      className="mb-3"
      expanded={accordion}
      onChange={() => {
        setAccordion((prev) => !prev);
      }}>
      <AccordionSummary
        id="profile-accordion"
        style={{ background: "#3C5464" }}
        className="text-white acc_close"
        expandIcon={<ExpandMore />}>
        <div className="row d-flex align-items-center acc-heading">
          <div className="col-lg-10 col-md-10 col-sm-12">
            <p style={{ fontSize: "16px" }}>{header}</p>
          </div>
          <div className="p-0 d-flex justify-content-lg-end justify-content-md-end justify-content-sm-end acc_btn col-lg-2 col-md-2 col-sm-12">
            {sessionScheduleId > 0 && handleScheduleCopy && (
              <Tooltip title="Add copy">
                <button
                  disabled={btnDisable ? true : false}
                  className="btn ml-lg-0 ml-md-0 ml-1"
                  onClick={(e) => handleScheduleCopy(e, sessionScheduleId)}>
                  <i className="fa fa-clone" aria-hidden="true"></i>
                </button>
              </Tooltip>
            )}
            {sessionScheduleId > 0 && handleScheduleDelete && (
              <Tooltip title="Delete">
                <button
                  disabled={btnDisable ? true : false}
                  className="btn ml-lg-0 ml-md-0 ml-1"
                  onClick={(e) => handleScheduleDelete(e, sessionScheduleId)}>
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </button>
              </Tooltip>
            )}
            {!hideBtn && (
              <>
                <Tooltip title="Clear">
                  <button
                    disabled={btnDisable ? true : false}
                    className="btn ml-lg-0 ml-md-0 ml-1"
                    onClick={clearFunc}>
                    <i className="fas fa-times-circle"></i>
                  </button>
                </Tooltip>
                <Tooltip title="Submit">
                  <button
                    className="btn"
                    disabled={btnDisable}
                    onClick={(e) => submitFunc(e, isTrainerMandetory)}>
                    <i className="fa fa-save"></i>
                  </button>
                </Tooltip>
              </>
            )}
            {canRestore && handleScheduleRestore && (
              <Tooltip title="Restore">
                <button
                  disabled={btnDisable ? true : false}
                  className="btn ml-lg-0 ml-md-0 ml-1"
                  onClick={handleScheduleRestore}>
                  <i className="fa fa-reply" aria-hidden="true"></i>
                </button>
              </Tooltip>
            )}
          </div>
        </div>
      </AccordionSummary>

      <AccordionDetails className="page_heading">{children}</AccordionDetails>
    </Accordion>
  );
};

export default AccordianCustom;
