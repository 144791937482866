import React, { useContext, useEffect, useState } from "react";
import "./StructureType.css";
import { useLocation, useNavigate } from "react-router-dom";
import { LoaderContext, getContext } from "../../Helpers/Context/Context";
import notify from "../../Helpers/ToastNotification";
import { APICall } from "../../Helpers/APICalls";
import {
  GetStructureDataById,
  GetStructureDetailsDataById,
  InsertUpdateStructureDetailsData,
  InsertUpdateStructureType,
} from "../../Helpers/APIEndPoints/EndPoints";
import InputForm from "../../Components/InputForm/InputForm";
import moment from "moment";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import StructureDetail from "./StructureDetail";
import AccessDenied from "../AccessDenied/AccessDenied";

const StructureTypeEdit = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const context = getContext();
  const [structureId, setStructureId] = useState(
    state !== null ? state.structureId : 0
  );
  const [structureType, setStructureType] = useState("");
  const [isActive, setIsActive] = useState(true);
  const { showLoader, hideLoader } = useContext(LoaderContext);
  const newStructure = {
    strIndex: 0,
    strSeqNo: "",
    name: "",
  };

  const [structureData, setStructureData] = useState([]);

  // useEffect(() => {
  //   console.log(structureData);
  // }, [structureData]);

  const sortData = async (structureData) => {
    let data = structureData;

    data.forEach((structure) => {
      if (
        structure.hasOwnProperty("activityDetails") &&
        structure.activityDetails.length > 0
      ) {
        structure.activityDetails.forEach((activity) => {
          if (
            activity.hasOwnProperty("parameterDetails") &&
            activity.parameterDetails.length > 0
          ) {
            activity.parameterDetails.sort((a, b) => a.paSeqNo - b.paSeqNo);
          }
        });
        structure.activityDetails.sort((a, b) =>
          a.acSeqNo > b.acSeqNo ? 1 : -1
        );
      }
    });
    data.sort((a, b) => (a.strSeqNo > b.strSeqNo ? 1 : -1));
  };

  useEffect(() => {
    if (state !== null && state.structureId !== 0) {
      (async () => {
        showLoader();
        const { data } = await APICall(GetStructureDataById, "POST", {
          id: state.structureId,
        });
        if (data !== null) {
          setStructureType(data[0].name);
          setIsActive(data[0].isActive);
        }
        hideLoader();
      })();
    }
  }, []);

  const insertUpdateStructure = async () => {
    showLoader();
    const response = await APICall(InsertUpdateStructureType, "POST", {
      Id: structureId, //state.structureId,
      structureType,
      isActive,
      userId: context.userId,
      date: moment(new Date()).format("yyyy-MM-DD HH:mm:ss"),
      structureDetailsData: structureData,
    });

    if (response.status !== 0) {
      notify(1, "Someting went wrong!");
    } else {
      if (state.structureId == 0 && structureId === 0) {
        setStructureId(response.data.id);
        notify(0, "Inserted successfully, you can add structure now!");
      } else if (state.structureId == 0 && structureId > 0) {
        notify(0, "Inserted successfully!");
        navigate(-1);
      } else {
        notify(0, "Updated successfully!");
        navigate(-1);
      }
    }
    hideLoader();
  };
  async function onClickFunction(e, name) {
    if (name === "Cancel") {
      navigate("/StructureType");
    }

    if (name === "Reset") {
      if (state.structureId == 0) {
        setIsActive(true);
        setStructureType("");
      }
    }

    if (name === "Submit") {
      if (structureType === "") {
        notify(1, "Fill required fields");
      } else {
        if (structureData.length > 0) {
          const isDataValid = await checkValidation(structureData);

          if (isDataValid) {
            sortData(structureData);

            let isSequenceValid = false;

            let data = structureData.map((ele) => ele.strSeqNo);

            isSequenceValid = checkSequence(data);

            if (isSequenceValid) {
              for (let i = 0; i < structureData.length; i++) {
                if (
                  structureData[i].hasOwnProperty("activityDetails") &&
                  structureData[i].activityDetails.length > 0
                ) {
                  let data = structureData[i].activityDetails.map(
                    (ele) => ele.acSeqNo
                  );
                  isSequenceValid = checkSequence(data);

                  if (isSequenceValid === false) {
                    break;
                  }

                  for (
                    let j = 0;
                    j < structureData[i].activityDetails.length;
                    j++
                  ) {
                    if (
                      structureData[i].activityDetails[j].hasOwnProperty(
                        "parameterDetails"
                      ) &&
                      structureData[i].activityDetails[j].parameterDetails
                        .length > 0
                    ) {
                      let data = structureData[i].activityDetails[
                        j
                      ].parameterDetails.map((ele) => ele.paSeqNo);
                      isSequenceValid = checkSequence(data);
                      if (isSequenceValid === false) {
                        break;
                      }
                    }
                  }
                }
              }
            }

            if (isSequenceValid) {
              await insertUpdateStructure();
            } else {
              notify(1, "Invalid sequence!");
            }
          } else {
            notify(1, "Invalid input!");
          }
        } else {
          await insertUpdateStructure();
        }
      }
    }
  }

  const getStructureDetails = async () => {
    showLoader();
    const res = await APICall(GetStructureDetailsDataById, "POST", {
      StructureId: structureId,
    });

    if (res.data !== null && res.data.length > 0) {
      setStructureData(res.data);
      sortData(res.data);
    } else {
      setStructureData([]);
    }
    hideLoader();
  };

  useEffect(() => {
    getStructureDetails();
  }, []);

  const handleOnChange = (event, name) => {
    if (name === "structureType") {
      setStructureType(event.target.value);
    } else {
      setIsActive(event.target.checked);
    }
  };

  const checkValidation = async (structureData) => {
    const data = [...structureData];
    const regex = /^[1-9]\d*(?:\.[1-9]\d*)?(?:\.[1-9]\d*)?$/;

    let isValid = true;

    for (let i = 0; i < data.length; i++) {
      const structure = data[i];

      if (structure.name === "" || !regex.test(structure.strSeqNo)) {
        isValid = false;
        break;
      }
      if (
        structure.hasOwnProperty("activityDetails") &&
        structure.activityDetails.length > 0
      ) {
        for (let j = 0; j < structure.activityDetails.length; j++) {
          const activity = structure.activityDetails[j];

          if (activity.name === "" || !regex.test(activity.acSeqNo)) {
            isValid = false;
            break;
          }
          if (
            activity.hasOwnProperty("parameterDetails") &&
            activity.parameterDetails.length > 0
          ) {
            for (let k = 0; k < activity.parameterDetails.length; k++) {
              const parameter = activity.parameterDetails[k];

              if (!regex.test(parameter.paSeqNo)) {
                isValid = false;
                break;
              }

              if (
                parameter.name === null ||
                (parameter.name.isUOMApplicable === false &&
                  parameter.uom === null) // This is isUOMNotApplicable
              ) {
                isValid = false;
                break;
              }
            }
          }
        }
      }
    }
    return isValid;
  };

  const checkSequence = (value) => {
    let data = value;

    // first number check
    if (Number(data[0]) !== 1) {
      return false;
    }

    // const sortedData = value.sort((a, b) => (a > b ? 1 : -1));

    const removeCommonPrefix = (a, b) => {
      const minLen = Math.min(a.length, b.length);

      let i = 0;
      while (i < minLen && a[i] === b[i]) ++i;

      return [a.substr(0, i), a.substr(i), b.substr(i)];
    };

    const FindError = (data) => {
      for (let i = 1; i < data.length; ++i) {
        const [prevValue, value] = [data[i - 1], data[i]];

        if (value === prevValue) return `Duplicate ${value}`;

        const [prefix, prevSuffix, suffix] = removeCommonPrefix(
          prevValue,
          value
        );

        if (prevSuffix === "") {
          if (suffix !== ".1") return `Expected ${prevValue}.1, got ${value}`;
        } else if (suffix !== String(parseInt(prevSuffix) + 1)) {
          return `Expected ${prefix}${parseInt(prevSuffix) + 1}, got ${value}`;
        }
      }
      return null; // no error
    };

    const error = FindError(data);
    console.log(error || "No error");

    return error === null; // Does the sequence pass the check without any errors?
  };

  return (
    <>
      {context.loggedInAs === 1 ? (
        <>
          <div className="form-main px-3">
            <div className="page-title w-100">
              <div className="col-lg-12 p-0">
                <div className="row">
                  <div className="col-lg-4 col-md-4">
                    <h4>Structure Type</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 px-3 py-2 page_heading">
            <div className="row align-items-center">
              <div className="col-lg-4 col-sm-3 col-xs-4 ">
                <div className="form-group">
                  <label className="col-form-label">Structure Type</label>
                  <sup>*</sup>
                  <InputForm
                    className="form-control form-control-lg"
                    placeholder={""}
                    isDisabled={structureId > 0}
                    textArea={false}
                    value={structureType}
                    onChange={(e) => {
                      handleOnChange(e, "structureType");
                    }}
                  />

                  {/* <p style={{ color: "red" }}>
                {formErrors["invoiceType_isEmpty"]}
              </p> */}
                </div>
              </div>

              <div className="col-lg-4 col-sm-3 col-xs-4 ">
                <div className="form-group">
                  <label htmlFor="isActive" className="col-form-label">
                    Active Status
                  </label>
                  <sup>*</sup>
                  <div className="custom-checkbox">
                    <input
                      disabled={state === null ? true : state.structureId == 0}
                      type="checkbox"
                      onChange={(e) => {
                        handleOnChange(e, "isActive");
                      }}
                      id="isActive"
                      name="isActive"
                      checked={isActive}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4">
                <div className="float-right">
                  {/* {structureData.length === 0 && state.structureId == 0 && ( */}
                  <button
                    className="btn btn-success"
                    style={{ marginLeft: 5 }}
                    disabled={
                      state === null &&
                      structureId > 0 &&
                      structureData.length === 0
                        ? true
                        : state?.structureId === 0 &&
                          structureId > 0 &&
                          structureData.length === 0
                    }
                    onClick={(e) => onClickFunction(e, "Submit")}>
                    <i className="fa fa-save"></i> Submit
                  </button>
                  {/* )} */}

                  {/* {state.structureId === 0 && (
                <button
                  className="btn btn-info"
                  style={{ marginLeft: 5 }}
                  onClick={(e) => onClickFunction(e, "Reset")}>
                  Reset
                </button>
              )} */}
                  <button
                    className="btn btn-cancel"
                    style={{ marginLeft: 5 }}
                    onClick={(e) => onClickFunction(e, "Cancel")}>
                    <i className="fa fa-times" aria-hidden="true"></i> Cancel
                  </button>
                  <button
                    className="btn btn-info"
                    style={{ marginLeft: 5 }}
                    disabled={structureId > 0 ? false : true}
                    onClick={(e) =>
                      setStructureData([
                        ...structureData,
                        { ...newStructure, structureId: structureId },
                      ])
                    }>
                    <i className="fa fa-sitemap"></i> Add Structure
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="mx-3 px-3 mt-2">
            {structureData.length > 0 &&
              structureData.map((structure, stIndex) => (
                <React.Fragment key={stIndex}>
                  <StructureDetail
                    structureData={structureData}
                    setStructureData={setStructureData}
                    structure={structure}
                    stIndex={stIndex}
                  />
                </React.Fragment>
              ))}
          </div>
          {/* {structureData.length > 0 && (
        <div className="row mx-3 my-3 p-2 justify-content-end">
          <button
            className="btn btn-success"
            style={{ marginLeft: 5 }}
            onClick={async (e) => {
              const isDataValid = await checkValidation(structureData);

              if (isDataValid) {
                sortData(structureData);

                let isSequenceValid = false;

                for (let i = 0; i < structureData.length; i++) {
                  if (
                    structureData[i].hasOwnProperty("activityDetails") &&
                    structureData[i].activityDetails.length > 0
                  ) {
                    for (
                      let j = 0;
                      j < structureData[i].activityDetails.length;
                      j++
                    ) {
                      if (
                        structureData[i].activityDetails[j].hasOwnProperty(
                          "parameterDetails"
                        ) &&
                        structureData[i].activityDetails[j].parameterDetails
                          .length > 0
                      ) {
                        let data = structureData[i].activityDetails[
                          j
                        ].parameterDetails.map((ele) => ele.paSeqNo);
                        isSequenceValid = checkSequence(data);
                        if (isSequenceValid === false) {
                          break;
                        }
                      }
                    }
                    if (isSequenceValid) {
                      let data = structureData[i].activityDetails.map(
                        (ele) => ele.acSeqNo
                      );
                      isSequenceValid = checkSequence(data);
                    }
                    if (isSequenceValid === false) {
                      break;
                    }
                  }
                }
                if (isSequenceValid) {
                  let data = structureData.map((ele) => ele.strSeqNo);
                  isSequenceValid = checkSequence(data);
                }

                if (isSequenceValid) {
                  // const res = await APICall(
                  //   InsertUpdateStructureDetailsData,
                  //   "POST",
                  //   {
                  //     structureDetailsData: structureData,
                  //     id: structureId,
                  //     isActive,
                  //     userId: context.userId,
                  //     date: moment(new Date()).format("yyyy-MM-DD HH:mm:ss"),
                  //   }
                  // );
                  // if (res.data !== null) {
                  //   if (state.structureId === 0) {
                  //     notify(0, "Structure inserted successfully!");
                  //   } else {
                  //     notify(0, "Structure updated successfully!");
                  //   }
                  //   navigate(-1);
                  // } else {
                  //   notify(1, "Something went wrong!");
                  // }
                } else {
                  notify(1, "Invalid sequence!");
                }
              } else {
                notify(1, "Invalid input!");
              }
            }}>
            <i className="fa fa-save"></i> Submit
          </button>
        </div>
      )} */}
        </>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};

export default StructureTypeEdit;
